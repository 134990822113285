import React, { Component } from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Select } from "antd";
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth } from "../../services/helper/panelSizeHelper";
import Panel from "@/components/Panel/Panel";
import { parseWireBondProFile, parseXmlFile } from "../../services/helper/parserXmlFile";
import EditableTable from "../EditableTable";
import WireBondHelpPanel from "./WireBondHelpPanel";
import { getPackageWireBondProfile } from "../../services/api/Design/design";
import { getWirBondTableData } from "../../services/helper/wireBondHelper";
import "./index.css";

const { Option } = Select;

const columns = [{
  title: "Name",
  dataIndex: "name",
  key: "name"
},
{
  title: "Geometry",
  dataIndex: "geometry",
  key: "geometry"
},
{
  title: "Diameter",
  dataIndex: "diameter",
  key: "diameter"
},
{
  title: "Material",
  dataIndex: "material",
  key: "material"
}]
class WireBondPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: "",
      profileInfo: [],
      data: [],
      helpVisible: false,
      oldSelectFile: ""
    }
    this.dialogRoot = document.getElementById('root');

    columns[1].render = (text, record) => {
      if (!record.point || !Object.keys(record.point).length) {
        return null;
      }
      return <div className="wire-bond-geometry">
        <span>H1 = {record.point.hl}</span>
        <span>Alpha = {record.point.vl}</span>
      </div>
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 600)
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getData()
  };

  getData = async () => {
    const { designId } = this.props;
    if (!designId) {
      return;
    }
    const selectedFile = await getPackageWireBondProfile(designId);
    this.setState({
      selectedFile,
      profileInfo: [],
      data: [],
      oldSelectFile: selectedFile
    }, () => {
      this.getFileContent(selectedFile)
    })
  }


  closeModal = () => {
    const { selectedFile, oldSelectFile } = this.state;
    const { designId } = this.props;
    this.props.saveWireBondProfile(selectedFile, designId, oldSelectFile)
    this.props.closePanel();
  }

  getFileContent = async (id) => {
    if (!id) {
      return;
    }
    const fileContent = await this.props.getLibraryFileContent(id)
    if (!fileContent) {
      return;
    }
    const xmlDoc = parseXmlFile(fileContent);
    const profileInfo = parseWireBondProFile(xmlDoc);
    const data = getWirBondTableData(profileInfo);
    this.setState({
      profileInfo: profileInfo || [],
      data
    })
  }

  changeSelectedFile = (key) => {
    this.setState({
      selectedFile: key,
      profileInfo: [],
      data: []
    }, () => {
      this.getFileContent(key)
    })
  }

  getSelectionComponent = ({
    title,
    value,
    type,
    options = []
  }) => {
    return <div className="top-sus-wire-bond-item">
      <span>{title}</span>
      <Select
        value={value}
        className="aurora-select"
        onChange={(key) => this.changeSelectedFile(key, type)}
        popupClassName="aurora-select-dropdown"
        allowClear
      >
        {options.map(item =>
        (<Option
          key={item.id}
          value={item.id}>
          {item.name}
        </Option>))}
      </Select>
    </div>
  }

  getTable = () => {
    const { data } = this.state;
    return data && data.length ? <EditableTable
      rowKey={(record) => record.name}
      columns={columns}
      size="small"
      dataSource={data || []}
      className='space-10'
    /> : null
  }

  openHelp = (e) => {
    e && e.stopPropagation();
    this.setState({
      helpVisible: true
    })
  }

  closeHelp = () => {
    this.setState({
      helpVisible: false
    })
  }

  render() {
    const { maxWidth, selectedFile, helpVisible } = this.state;
    const { wireBondProfileList } = this.props;
    const content = (
      <Panel
        className='top-sus-wire-bond-panel'
        title={<div className='top-sus-wire-bond-title'>
          <span>Wire Bond Profile</span>
          <QuestionCircleOutlined
            title="Help"
            className="wire-bond-help-icon"
            onClick={(e) => this.openHelp(e)} />
        </div>
        }
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 600 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
      >
        <div className='top-sus-wire-bond-content'>
          {this.getSelectionComponent({
            title: "File",
            value: selectedFile,
            type: "wireBondProfile",
            options: wireBondProfileList || []
          })}
          {this.getTable()}
          {helpVisible && <WireBondHelpPanel closeHelp={this.closeHelp} />}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default WireBondPanel;
