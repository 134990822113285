import { connect } from 'react-redux';
import {
  selectLayer,
  selectChange,
  openPanel,
  closePanel,
  updateLocation,
  cancelSelectedAll,
  changeDisplaySelected,
  updatePCBRefreshStatus
} from '../store/Andes_v2/actionCreators';
import LayoutExplorer from '../components/explorer';
import SearchBox from './SearchBox';
import LayerTab from './LayerTab';
import ComponentTable from './DesignInfo/ComponentTable';
import NetTable from './DesignInfo/NetTable';
import Stackup from '../components/Stackup_v1';
import higherOrderComponent from '../components/explorerHOC';
import {
  saveData,
  updateShowZonesStatus,
  changeStackupZonePanelShow,
  updateSelectedZones
} from '../components/Stackup_v1/store/actionCreators';
import PadStackInfo from './DesignInfo/PadStackInfo';
import { ANDES_V2 } from '../../../constants/pageType';
import PCBDownload from '../components/pcbDownload';
import StackupPanel from '../../../components/StackupPanel';
import { getNewStackupList } from '../../../services/Andes_v2/helper/stackupUIHelper';
import { updateStackupStatusList } from '../../Andes_v2/store/project/action';

const mapState = (state) => {
  const explorer = state.AndesV2Reducer.explorer;
  const channel = state.AndesV2Reducer.channel;
  const { project: { stackupStatusList, selectedKeys } } = state.AndesV2Reducer;
  const PanelStatus = state.PanelStatus;
  const {
    panelType,
    colorBy,
    simulation,
    selections
  } = explorer;
  const { defaultLeft, defaultTop } = PanelStatus
  const { tabVisible } = state.MonitorInfoReducer;
  const { hybridStatus, channelInfo: { designId } } = channel;
  const { stackupObj } = state.StackupReducer;
  return {
    panelType, colorBy,
    simulation, defaultLeft, defaultTop,
    tabVisible, selections, hybridStatus,
    stackupObj,
    pageType: ANDES_V2,
    stackupListProps: { selectedKeys, channelDesignId: designId },
    stackupList: stackupStatusList
  }
}


const mapDispatch = (dispatch) => ({
  updateLocation(location, designID) {
    dispatch(updateLocation(location, designID))
  },
  select(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  selectLayer(checkedList, designID) {
    dispatch(selectLayer(checkedList, designID));
  },
  openPanel(type, designID) {
    dispatch(openPanel(type, designID))
  },
  closePanel(designID) {
    dispatch(closePanel(designID))
  },
  cancelSelectedAll(designID) {
    dispatch(cancelSelectedAll(designID))
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  updatePCBRefreshStatus(status, designID) {
    dispatch(updatePCBRefreshStatus(status, designID))
  },
  setStackupData({ data, materialList, stackups, zones, unit, designID, bends }) {
    dispatch(saveData({ data, materialList, stackups, zones, unit, designID, bends }));
  },
  _updateShowZonesStatus(show, designID) {
    dispatch(updateShowZonesStatus(show, designID))
  },
  _changeStackupZonePanelShow(show, designID) {
    dispatch(changeStackupZonePanelShow(show, designID))
  },
  _updateSelectedZones(selectedZones, designID) {
    dispatch(updateSelectedZones(selectedZones, designID))
  },
  updateStackupList(stackupList) {
    dispatch(updateStackupStatusList(stackupList))
  },
})


const LayoutExplorerHOC = higherOrderComponent({ LayoutExplorer, SearchBox, LayerTab, ComponentTable, NetTable, Stackup, StackupPanel, getNewStackupList, PadStackInfo, product: ANDES_V2, PCBDownload });

export default connect(mapState, mapDispatch)(LayoutExplorerHOC);