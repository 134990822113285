import React, { Fragment } from 'react';
import ResultTopMenu from '@/components/ResultTopMenu';
import { CASCADE } from '../../../../constants/pageType';

const menuList = [{
  name: 'Pin',
  key: 'pin'
}, {
  name: 'Via',
  key: 'via'
}, {
  name: 'Download xls',
  key: 'download'
}]

function TopMenu({
  selectedKey,
  changeResultType,
  downloadIRResult,
  layoutLeftWidth,
  leftWidth
}) {
  return (
    <Fragment>
      <ResultTopMenu
        selectedKey={selectedKey}
        changeResultType={changeResultType}
        menu={menuList}
        backSetup={{
          name: 'Setup',
          key: 'setup'
        }}
        downloadClick={downloadIRResult}
        layoutLeftWidth={layoutLeftWidth}
        leftWidth={leftWidth}
        product={CASCADE}
      >
      </ResultTopMenu>
    </Fragment>
  )
}

export default TopMenu