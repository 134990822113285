import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import SeaSimConfigPanel from '../SeasimConfigPanel';
import { saveChannelConfig, updateComplianceConfig } from '../store/channel/action';

class SeaSim extends PureComponent {

  render() {
    const { config, channelId, components, signals, selectedSignals, isEndToEndChildren } = this.props;

    return <SeaSimConfigPanel
      id={channelId}
      config={config}
      components={components}
      signals={signals}
      selectedSignals={selectedSignals}
      isEndToEndChildren={isEndToEndChildren}
      saveConfig={this.props._saveChannelConfig}
      closePanel={this.props.closePanel}
      updateConfig={this.props._updateComplianceConfig}
    />
  }

}

const mapState = (state) => {
  const { AndesV2Reducer: { channel: { channelId, channelInfo = {} } } } = state;
  const config = channelInfo.config ? channelInfo.config : {};
  const { components = [], signals = [], selectedSignals = [] } = channelInfo.content || {};
  return {
    channelId,
    config,
    components,
    signals,
    selectedSignals
  }
};


const mapDispatch = (dispatch) => ({
  _saveChannelConfig(simulateId) {
    dispatch(saveChannelConfig(simulateId))
  },
  _updateComplianceConfig(configObj) {
    dispatch(updateComplianceConfig(configObj))
  }
})

export default connect(mapState, mapDispatch)(SeaSim)