import React from 'react';
import { Table } from 'antd';
import { strDelimited } from '@/services/helper/split'
import designConstructor from '../../../../services/helper/designConstructor';

const delayColumns = [{
  title: 'Signal',
  dataIndex: 'netName',
  key: 'netName',
  width: "25%",
}, {
  title: 'Component / Pin'/* 'Driver Component / Pin' */,
  dataIndex: 'driver',
  key: 'driver',
  width: "25%",
  render: (text) => (
    <span>
      {text['name'] + ' - ' + text['pin']}
    </span>
  ),
}, {
  title: 'Component / Pin'/* 'Receiver Component / Pin' */,
  dataIndex: 'receiver',
  key: 'receiver',
  width: "25%",
  render: (text) => (
    <span>
      {text['name'] + ' - ' + text['pin']}
    </span>
  ),
}/* , {
  title: 'Skew(ps)',
  dataIndex: 'skew',
  key: 'skew',
  width: "20%",
} */];

const delayColumn = [
  {
    title: 'Delay(ps)',
    dataIndex: 'delay',
    key: 'delay',
    width: "25%",
  }
];

function sortDelayData(data, currentInterfaces) {
  let newData = [...data];
  newData.sort((d1, d2) => { return d1.netName > d2.netName ? -1 : 1 });
  newData.sort((d1, d2) => {
    return strDelimited(d1.receiver.name, '::', { returnIndex: 1 }) > strDelimited(d2.receiver.name, '::', { returnIndex: 1 }) ? -1 : 1;
  });
  newData.sort((d1, d2) => {
    return strDelimited(d1.driver.name, '::', { returnIndex: 1 }) > strDelimited(d2.driver.name, '::', { returnIndex: 1 }) ? 1 : -1;
  });
  const comps = getCompsByInterfaces(currentInterfaces);
  if (comps && comps.length) {
    newData = newData.filter(item => {
      return comps.includes(`${item.driver.name}::${item.driver.pin}`) && comps.includes(`${item.receiver.name}::${item.receiver.pin}`)
    })
  }
  return newData
}

function getCompsByInterfaces(currentInterfaces) {
  if (!currentInterfaces || !currentInterfaces.length) return [];

  let comps = [];
  for (let info of currentInterfaces) {
    const isPreLayout = designConstructor.isPreLayout(info.PCBID);
    if (isPreLayout) {
      comps.push(...info.content.components.map(item => item.pins.map(pin => `${info.pcb}::${item.name}::${pin.pin}`)).flat(2));
      continue;
    }
    if (!info.content || !info.content.port_setups || !info.content.port_setups.length) {
      continue;
    }

    const currComps = info.content.port_setups.map(item => {
      if (item.positive && item.positive.component) {
        return `${item.positive.component}::${item.positive.pin}`
      }
      return null
    }).filter(item => !!item);
    comps.push(...currComps.map(item => `${info.pcb}::${item}`))
  }
  return comps;
}

const DelayComp = (props) => {
  const { delayData, key, name, column } = props;
  const columns = column && column.length ? [...delayColumns, ...column] : [...delayColumns, ...delayColumn];
  return <div className='sierra-result-content-box' key={key}>
    {name && <div style={{ marginBottom: 8 }}>
      <label className='font-bold' style={{ marginRight: 14 }}>Name:</label>
      <label>{name}</label>
    </div>}
    <Table
      columns={columns}
      dataSource={delayData}
      pagination={false}
      className='slerra-delay-table'
      scroll={(delayData && delayData.length && delayData.length > 10) ? { y: 380 } : {}}
      size="small"
      rowKey={record => record.netName + record.driver.name + record.receiver.name}
    />
  </div>
}

export {
  sortDelayData,
  delayColumn
}

export default DelayComp;