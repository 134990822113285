import {
  MY_LIBRARY,
  LIBRARY,
  PROJECTS,
  LIBRARY_OPEN,
  SYS_LIBRARY,
  SYS_DECAP,
  TRASH,
  CUSTOM_LIBRARY
} from '@/constants/treeConstants';
import {
  PMIC_PART,
  VRM,
  DECAP,
  DECAP_TOUCHSTONE,
  DECAP_SPICE,
  DECAP_RLC,
  PIN_MAP,
  DRIVER,
  WIRE_BOND_XML,
  CPM_SPICE,
  CUSTOM,
  CUSTOM_TOUCHSTONE,
  CUSTOM_SPICE,
  EXTRACTION_OPTIONS,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  POWER_SWITCH_PART,
  POWERSI_OPTIONS,
  POWERDC_OPTIONS
} from '@/constants/libraryConstants';

export function treeItem({ name, key, dataType, ...restProps }) {
  this.name = name;
  this.key = key;
  this.dataType = dataType;
  this.children = [];
  Object.keys(restProps).map(key => this[key] = restProps[key])
};

function getDefaultTreeItems() {
  return [
    {
      name: 'System Library',
      key: SYS_LIBRARY,
      dataType: SYS_LIBRARY,
      nodeClass: 'expand-icon-large',
      children: [{
        name: 'Decap',
        key: SYS_DECAP,
        dataType: SYS_LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: [{
          name: 'Generic',
          key: 'Generic',
          dataType: SYS_LIBRARY,
          children: []
        }, {
          name: 'Vendor',
          key: 'Vendor',
          dataType: SYS_LIBRARY,
          children: []
        }]
      }]
    }, {
      name: 'Library',
      key: LIBRARY,
      dataType: MY_LIBRARY,
      nodeClass: 'expand-icon-large',
      children: [
        // {
        //   name: "VRM",
        //   key: VRM,
        //   dataType: LIBRARY,
        //   children: []
        // },
        {
          name: "Decap",
          key: DECAP,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: DECAP_TOUCHSTONE },
            { name: 'SPICE', key: DECAP_SPICE },
            { name: 'RLC', key: DECAP_RLC }
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        },
        {
          name: "Custom Model",
          key: CUSTOM,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: CUSTOM_TOUCHSTONE },
            { name: 'SPICE', key: CUSTOM_SPICE },
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        },
        {
          name: "CPM",
          key: CPM_SPICE,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        },
        {
          name: "Wire Bond Profile",
          key: WIRE_BOND_XML,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        },
        {
          name: "Driver",
          key: DRIVER,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        },
        // {
        //   name: "PMIC Pin Map",
        //   key: PIN_MAP,
        //   dataType: LIBRARY,
        //   children: []
        // },
        {
          name: "PMIC",
          key: PMIC_PART,
          dataType: PMIC_PART,
          nodeClass: 'expand-icon-middle',
        },
        {
          name: "Power Switch",
          key: POWER_SWITCH_PART,
          dataType: POWER_SWITCH_PART,
          nodeClass: 'expand-icon-middle',
        },
        {
          name: 'Extraction Options',
          key: EXTRACTION_OPTIONS,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'HFSS', key: HFSS_OPTIONS },
            { name: "SIwave", key: SIWAVE_OPTIONS },
            { name: 'PowerSI', key: POWERSI_OPTIONS },
            { name: "PowerDC", key: POWERDC_OPTIONS }
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }

        // {
        //   name: "Package",
        //   key: LIB_PKG,
        //   dataType: LIBRARY_OPEN,
        //   children: [
        //     { name: 'AC', key: PKG_AC },
        //     { name: 'DC', key: PKG_DC },
        //   ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        // }
        /* {
          name: "Decap Model Map",
          key: DECAP_MODEL_MAP,
          dataType: LIBRARY,
          children: []
        }, */
      ]
    },
    {
      name: 'Project',
      key: PROJECTS,
      dataType: PROJECTS,
      nodeClass: 'expand-icon-large',
      children: []
    },
    {
      name: 'Trash',
      key: TRASH,
      nodeClass: 'expand-icon-large',
      dataType: TRASH,
      children: []
    }]
}

export const defaultTreeItems = getDefaultTreeItems();