import React, { PureComponent, Fragment } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { isMac } from '@/services/api/userAgent';
import '../searchBox.css';

const NET = 'nets', COMP = 'comps';
class SearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      result: undefined,
      keyCode: null,
      nets: [],
      comps: [],
      finish: false,
      start: ''
    }
    this.ctrlKey = isMac() ? 91 : 17;
    this.shiftKey = 16;
    this.enterKey = 13;
    this.count = 0;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.KeyDown, true);
    document.addEventListener('keyup', this.KeyUp, true);

    if (!document.activeElement.id) {
      const { input } = this.inputRef;
      input.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.KeyDown, true);
    document.removeEventListener('keyup', this.KeyUp, true);
  }

  KeyDown = (e) => {
    if ([this.ctrlKey, this.shiftKey].includes(e.keyCode)) {
      this.setState({
        keyCode: e.keyCode
      })
    }
  }

  KeyUp = (e) => {
    const { keyCode } = this.state;
    const finish = e.keyCode === keyCode ? true : false;

    if (e.keyCode === 13) {
      this.showSelect()
    }

    this.setState({
      keyCode: null,
      finish
    });
  }

  changeValue = (e) => {
    const value = e.target.value;
    const result = value ? this.props.onSearch(value) : undefined;
    this.setState({
      value: value,
      result,
      nets: [],
      comps: [],
      finish: false,
      start: ''
    });
  }

  search = () => {
    const value = this.state.value;
    if (value) {
      this.setState({
        result: this.props.onSearch(value)
      });
    }
  }

  selectList = (list, name) => {
    return list.includes(name) ? list.filter(item => item !== name) : [...list, name]
  }

  ctrlSelect = (key, name, isNets) => {
    const { nets, comps } = this.state;
    let list = [];
    if (isNets) {
      list = this.selectList(nets, name);
    } else {
      list = this.selectList(comps, name);
    }
    this.setState({
      [key]: list
    })
  }

  shiftSelect = (key, name, isNets) => {
    const { nets, comps, result, start } = this.state;
    const include = isNets ? nets.includes(start) : comps.includes(start);
    if (!start || !include) {
      const newNets = isNets ? [name] : [], newComps = isNets ? [] : [name];
      this.setState({
        start: name,
        nets: newNets,
        comps: newComps
      })
    } else if (start === name) {
      this.setState({
        start: '',
        nets: [],
        comps: [],
      })
    } else {
      const { nets: netList, components } = result;
      let list = isNets ? netList : components;
      const indexStart = list.indexOf(start), indexEnd = list.indexOf(name);
      list = indexStart > indexEnd ? list.slice(indexEnd, indexStart + 1) : list.slice(indexStart, indexEnd + 1);
      this.setState({
        [key]: list
      })
    }
  }

  onSelect = (name, key) => {
    const { keyCode } = this.state;
    const isNets = key === NET ? true : false;
    if (keyCode && keyCode === this.shiftKey) {
      this.shiftSelect(key, name, isNets);
    } else {
      this.ctrlSelect(key, name, isNets);
    }
  }

  showSelect = () => {
    const { nets, comps, result, value } = this.state;
    if (nets.length === 0 && comps.length === 0 && result) {
      const r_nets = result.nets.map(net => net.toUpperCase());
      const r_comps = result.components.map(comp => comp.toUpperCase());
      const _value = value.toUpperCase();
      if (r_nets.includes(_value)) {
        this.props.onSelect({ nets: [result.nets[r_nets.findIndex(net => net === _value)]] });
      } else if (r_comps.includes(_value)) {
        this.props.onSelect({ comps: [result.components[r_comps.findIndex(net => net === _value)]] });
      }
    } else if (nets.length > 0 || comps.length > 0) {
      this.props.onSelect({ nets, comps });
    }
    this.setState({
      result: undefined,
      nets: [],
      comps: [],
      finish: false,
      start: '',
      value: ''
    })
  }

  clickJudge = (e, key) => {
    this.count += 1;
    const name = e.target.innerText;
    setTimeout(() => {
      if (this.count === 1) {
        this.onSelect(name, key);
      } else if (this.count === 2) {
        this.showSelect(name)
      }
      this.count = 0;
    }, 200);
  }

  renderResult = () => {
    const { value, result, nets, comps } = this.state;
    let resultList;
    if (!value || !result) {
      return;
    }
    const { nets: netList, components } = result;
    resultList = (netList.length === 0 && components.length === 0) ? (
      <div className="result-title">No Result</div>
    ) : (
      <Fragment>
        <div style={{ maxHeight: '315px', overflow: 'auto' }}>
          {netList.length > 0 && <div className="result-title" onClick={this.toogle}>Nets</div>}
          {netList.length > 0 && (<ul className="result-list">
            {netList.map((net, i) => <li
              key={i}
              onClick={(e) => this.clickJudge(e, NET)}
              className={nets.includes(net) ? 'search-select-background' : ''}>
              {net}
            </li>)}
          </ul>)}
          {components.length > 0 && <div className="result-title">Components</div>}
          {components.length > 0 && (<ul className="result-list">
            {components.map((comp, i) => <li
              key={i}
              onClick={(e) => this.clickJudge(e, COMP)}
              className={comps.includes(comp) ? 'search-select-background' : ''}>
              {comp}
            </li>)}
          </ul>)}
        </div>
        <div className='search-select-show' onClick={() => this.showSelect()}>OK</div>
      </Fragment>
    )

    return (
      <div className="search-result">
        {resultList}
      </div>
    )
  }

  render() {
    const { className } = this.props;
    const { value } = this.state;
    const { search, renderResult } = this;
    return (
      <div className={`search-box ${className}`}>
        <Input
          placeholder="Net, Component"
          addonAfter={<SearchOutlined className="input-search-button" onClick={search} />}
          allowClear
          id="search-box-input"
          value={value}
          onChange={this.changeValue}
          onPressEnter={search}
          ref={(input) => { this.inputRef = input; }}
          autoComplete='off'
        />
        {renderResult()}
      </div>
    );
  }
}

export default SearchBox;