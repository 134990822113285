import { select, event } from 'd3-selection';
import getIndex from '../../../../helper/insertionSearch';
import MarkNode from './markNode';

class MarkLine {
  constructor(props) {
    this.id = props.id;
    this.prevX = props.prevX;
    this.nodes = new Map();
    this.lineRootRoot = null;
    this.removeNode = this.removeNode.bind(this);
    this.delMarkLine = props.delMarkLine;
    this.getYValue = props.getYValue;
    this.getXValue = props.getXValue;
    this.targetX = props.targetX;
    this.xValue = props.xValue;

    this.x = props.x;
    this.padding = props.padding;
    this.height = props.height;
  }

  drawMarkLine(root) {
    const pdTop = this.padding.top;
    this.lineRoot = root.append('g')
      .attr('id', 'l-' + this.id)
      .on('mouseleave', () => {
        select(`#hover-` + this.id).remove();
      });

    this.lineRoot.append('line')
      .attr('x1', this.x)
      .attr('x2', this.x)
      .attr('y1', pdTop)
      .attr('y2', this.height + pdTop)
      .attr('stroke', '#f99090')
      .attr('stroke-width', 1)
      .on('mouseenter', () => this.markLineMouseenter())


    this.lineRoot.append('rect')
      .attr('class', 'curve-mark-rect')
      .attr('x', this.x - 16)
      .attr('y', pdTop - 20)
      .attr('fill', '#e9e9e900')
      .style('width', '50px')
      .on('click', function () {
        event && event.stopPropagation();
      })
      .on('mouseenter', () => this.markLineMouseenter());

    this.lineRoot.append('text')
      .text(`${this.xValue}`)
      .attr('x', this.x - 14)
      .attr('y', pdTop - 4)
      .attr('stroke-width', 1)
      .style('font-size', '12px')
      .style('font-weight', 500);
  }

  markLineMouseenter() {
    const pdTop = this.padding.top;
    let lineHoverBg = this.lineRoot.append('g')
      .attr('id', `hover-` + this.id)
      .on('mouseleave', () => {
        select(`#hover-` + this.id).remove();
      });

    lineHoverBg.append('rect')
      .attr('class', 'curve-mark-rect')
      .attr('x', this.x - 16)
      .attr('y', pdTop - 20)
      .attr('fill', '#ffffff')
      .style('width', this.xValue.length > 5 ? '56px' : '50px')
      .on('click', function () {

      });

    lineHoverBg.append('text')
      .text(`${this.xValue}`)
      .attr('class', 'mark-line-text')
      .attr('x', this.x - 14)
      .attr('y', pdTop - 4)
      .attr('stroke-width', 1)
      .style('font-size', '12px')
      .style('font-weight', 500);

    //curve mark del icon
    let textX = this.x + 24;
    if (this.xValue.length === 6) {
      textX = this.x + 30;
    } else if (this.xValue.length > 6) {
      textX = this.x + 34;
    }
    lineHoverBg.append('text')
      .text(`X`)
      .attr('class', 'curve-mark-close')
      .attr('x', textX)
      .attr('y', pdTop - 4)
      .on('click', this.removeMarkLine);
  }

  removeMarkLine = () => {
    this.lineRoot.remove();
    this.delMarkLine(this.id);
    event && event && event.stopPropagation();
  }

  drawMarkNode(curves) {
    for (let curve of curves) {
      const color = curve.color;
      let xCurves = curve.x, yCurves = curve.y;
      const i = getIndex(xCurves, this.targetX, 0, xCurves.length - 1);

      //If no point is found, exit this loop
      if (isNaN(i)) {
        continue;
      }
      const { yValue, ym, targetY } = this.findCoordinate(i, xCurves, yCurves);
      const markNode = new MarkNode({
        hashId: curve.hashId,
        prevX: this.prevX,
        ym,
        yValue,
        color,
        x: this.x,
        targetX: this.targetX,
        targetY: targetY,
        getYValue: this.getYValue,
        getXValue: this.getXValue
      });
      this.nodes.set(curve.hashId, markNode);
      markNode.drawNode(this.lineRoot, this.removeNode)
    }
  }

  findCoordinate(i, xCurves, yCurves) {
    let pointX = null, pointY = null, prevPointX = null, prevPointY = null, targetY = null, yValue = null;
    pointX = xCurves[i]; pointY = yCurves[i]; targetY = yCurves[i];
    if (i > 0 && i < xCurves.length - 1) {
      if (this.targetX === pointX) {
        targetY = yCurves[i];
      } else if (pointX > this.targetX) {
        prevPointX = xCurves[i - 1];
        prevPointY = yCurves[i - 1]
      } else if (pointX < this.targetX) {
        prevPointX = xCurves[i];
        prevPointY = yCurves[i];
        pointX = xCurves[i + 1];
        pointY = yCurves[i + 1]
      }
    } else if (i === 0 && xCurves.length >= 2) {
      if (this.targetX === pointX) {
        targetY = yCurves[i];
      } else if (pointX < this.targetX) {
        prevPointX = xCurves[i];
        prevPointY = yCurves[i];
        pointX = xCurves[i + 1];
        pointY = yCurves[i + 1]
      }
    } else if (i === xCurves.length - 1 && xCurves.length >= 2) {
      if (this.targetX === pointX) {
        targetY = yCurves[i];
      } else if (pointX > this.targetX) {
        prevPointX = xCurves[i - 1];
        prevPointY = yCurves[i - 1]
      }
    }

    //curves Only one point
    if (xCurves.length < 2) {
      targetY = yCurves[i]
    }

    let _targetY_ = [targetY].slice(0)[0];
    //InterpolateLinear
    if (xCurves.length >= 2) {
      _targetY_ = prevPointY + (pointY - prevPointY) / (pointX - prevPointX) * (this.targetX - prevPointX);
    }

    // Calculate the position based on the value of y
    let ym = this.getYValue(_targetY_);
    ym = ym + this.padding.top;
    // yValue = numberToScientific(_targetY_);
    yValue = Number(_targetY_.toPrecision(3));

    return { yValue, ym, targetY: _targetY_ }
  }

  redrawLine = ({ padding, height }) => {
    this.padding = padding;
    this.height = height;
    const x = this.getXValue(this.targetX) + padding.left;
    this.x = x;
    this.draw();
  }

  draw() {
    const pdTop = this.padding.top
    this.lineRoot.select('line')
      .attr('x1', this.x)
      .attr('x2', this.x)
      .attr('y1', pdTop)
      .attr('y2', this.height + pdTop)

    this.lineRoot.select('rect')
      .attr('x', this.x - 16)
      .attr('y', pdTop - 20)

    this.lineRoot.select('text')
      .attr('x', this.x - 14)
      .attr('y', pdTop - 4)
  }

  redrawNodes = ({ padding }) => {
    this.nodes.forEach(node => node.redrawNode({ padding }))
  }

  removeNode(hashId) {
    const node = this.nodes.get(hashId);
    if (node) {
      node.deleteNode();
      this.nodes.delete(hashId);
      if (!this.nodes.size) {
        this.removeMarkLine()
      }
    }
  }
}

export default MarkLine;