import React, { Component, Fragment } from 'react';
import { Input, message, Select, Divider, Checkbox } from 'antd';
import { numberCheck } from '../../../services/helper/dataProcess';
import './index.css';

const options = [
  { value: 'Min', label: 'Min' },
  { value: 'Max', label: 'Max' },
  { value: 'Typ', label: 'Typ' },
]

class InputOrSelect extends Component {
  constructor(props) {
    super(props);
    const { record, dataIndex, typValue } = props;
    this.state = {
      value: record[`${dataIndex}_value`] || typValue,
      isOpen: true
    }
  }

  componentDidMount() {
    this.inputRef0.focus();
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const selectEle = document.getElementsByClassName("sweep-trace-width-select-popupClassName")[0];
    const inputGroupEle = document.getElementsByClassName("sweep-trace-width-inputGroup")[0];
    // click input or select
    if (selectEle.contains(e.target) || inputGroupEle.contains(e.target)) {
      return;
    }
    this.saveValue();
  }

  onSelect = (value) => {
    const { record, dataIndex } = this.props;
    this.props.save();
    this.props.saveTraceWidth({ type: 'select', record: { ...record, [dataIndex]: value }, dataIndex })
  }

  saveValue = () => {
    const { value } = this.state;
    const { typValue, dataIndex, record } = this.props;
    this.props.save();

    if (value.some(v => !!numberCheck(v))) { // Determine if it's all numbers
      message.error("The input data must be number.");
      return;
    } else if (!(record[`${dataIndex}_value`] || typValue).every((i, index) => Number(value[index]) === Number(i))) { // Whether to modify or not
      this.props.saveTraceWidth({ type: 'input', record: { ...record, [dataIndex]: 'userDefined' }, dataIndex, inputValue: value.map(parseFloat) })
    }
  }

  changeValue = (e, index) => {
    const { value } = this.state;
    const _value = [...value];
    _value[index] = e.target.value;
    this.setState({ value: _value });
  }

  changeApplyAll = (e) => {
    const { value } = this.state;
    const { typValue, dataIndex, record } = this.props;
    // Apply the whole line
    if ((record[`${dataIndex}_value`] || typValue).every((i, index) => Number(value[index]) === Number(i))) {
      this.props.changeApplyAll({ e, record, dataIndex });
    } else { // only update applyAll
      this.props.changeApplyAll({ e, isOnlyUpdateApplyAll: true })
    }
  }

  valueDropDownRender = (menu) => {
    return <div>
      {menu}
      <Divider className='andes-v2-sweep-select-dropdown-divider' />
      <div onMouseDown={e => e.preventDefault()} onClick={(e) => e.preventDefault()} className='andes-v2-sweep-apply-all-checkbox'>
        <Checkbox onChange={this.changeApplyAll} >
          Apply to all
        </Checkbox>
      </div>
    </div>
  }

  render() {
    const { value, isOpen } = this.state;
    const { record, dataIndex } = this.props;
    return (
      <Fragment>
        <div className='sweep-trace-width-inputGroup' ref={this.inputGroupRef} size='small'>
          {value.map((v, index) => {
            return (
              <span style={{ display: 'inline-block', marginLeft: index ? '2px' : null, width: `${100 / value.length}%` }} key={`${v}_${index}`}>
                <Input
                  autoFocus
                  className='sweep-trace-width-input'
                  value={v}
                  ref={(el) => this[`inputRef${index}`] = el}
                  onChange={(e) => this.changeValue(e, index)}
                  size='small'
                />
              </span>
            )
          })}
        </div>

        <Select
          size="small"
          open={isOpen}
          className="sweep-trace-width-select"
          suffixIcon={null}
          getPopupContainer={() => document.querySelector('main.aurora-content')}
          onSelect={this.onSelect}
          value={record[dataIndex]}
          popupClassName="sweep-trace-width-select-popupClassName andes-v2-sweep-select-dropdown-menu"
          dropdownRender={(menu) => this.valueDropDownRender(menu)}
          popupMatchSelectWidth={false}
          options={options}
        />
      </Fragment>
    )
  }
}

export default InputOrSelect;