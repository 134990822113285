import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { SINGLE_PAGE_LAYOUT } from '../../../constants/layoutConstants';
import Setup from './setup';
import DecapGroup from './decapGroup';
import './index.css';

class AcPreLayout extends Component {

  render() {
    const { groupShow } = this.props;
    return <div className='cascade-pre-layout-body'>
      <Row>
        <Col span={24} className='cascade-pre-layout-setup-border'>
          <Setup />
        </Col>
      </Row>
      {
        groupShow ? <Row className='space-16'>
          <Col span={24} className='cascade-pre-layout-setup-border pre-layout-relative cascade-pre-layout-decap-group-border'>
            <DecapGroup />
          </Col>
        </Row> : null
      }
    </div>
  }

}

const mapState = (state) => {
  const { CascadeReducer: { prelayout: { groupShow } } } = state;
  return {
    groupShow
  }
}

const mapDispatch = (dispatch) => ({})

export default connect(mapState, mapDispatch)(AcPreLayout);