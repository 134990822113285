import { numberCheck } from '@/services/helper/dataProcess';
import hash from 'object-hash';
import { MIL, UM, MM, UNIT, ViaKeyArr, TraceKeyArr, TraceColumns, TraceKeyZDArr, VIA_TO_VIA_PITCH, DRILL_DIAMETER } from "./libraryConfig";
import NP from 'number-precision';
import { evaluate } from 'mathjs';
import { getScale } from '../../helper/numberHelper';
import { TRACE, VIA, EYE_MASK } from "@/constants/libraryConstants";
import { ANDES_V2 } from '../../../constants/pageType';
import { checkNameFormat } from '../../helper/nameFormatCheck';
import { CPHY_EYE_MASK } from '../../../constants/libraryConstants';

function checkLibraryName({ name, libraryList, libraryId }) {
  //check name format
  const error = checkNameFormat(name);
  if (error) {
    return { type: "name", msg: error }
  }
  //check Duplicate name 
  const index = libraryList.findIndex(item => item.name === name && item.id !== libraryId);
  if (index > -1) {
    return { type: "name", msg: "Name already exists!" };
  }
}

function checkLibraryValue({ value, key, title, product, config }) {
  let errorMsg = null;
  //VIA_TO_VIA_PITCH andes_v2 not allowed is empty
  if (!value && (key !== VIA_TO_VIA_PITCH || product === ANDES_V2)) {
    errorMsg = { type: key, msg: `${title} value can not be empty.` };
    return errorMsg;
  }

  if (value && numberCheck(value)) {
    errorMsg = { type: key, msg: `${title} value must be a number.` };
    return errorMsg;
  }

  if (value) {
    if (key === VIA_TO_VIA_PITCH) {
      const drill = config[DRILL_DIAMETER];
      if (Number(value) <= Number(drill)) {
        errorMsg = { type: key, msg: `${title} value should be greater than Drill Diameter.` };
        return errorMsg;
      }
    } else if (key === DRILL_DIAMETER) {
      const pitch = config[VIA_TO_VIA_PITCH];
      if (pitch && Number(value) >= Number(pitch)) {
        errorMsg = { type: key, msg: `${title} value should be smaller than Via-To-Via Pitch.` };
        return errorMsg;
      }
    }
  }
}

function getLibraryConfigVersion({ version, prevConfig, config }) {
  let _version = version;
  let [v1, v2, v3] = version.split(".");
  const prevHash = hash(prevConfig);
  const newHash = hash(config);
  if (prevHash!== newHash) {
    v3 = parseInt(v3) + 1;
    _version = `${v1}.${v2}.${v3}`;
  }
  return _version;
}

//TODO
function parseLibraryData(data, type) {
  let config = null;
  config = data && data.config ? { ...data.config } : null;
  return { config, type };
}

/* 1mil = 1/1000inch = 0.00254cm = 0.0254mm 
 
1mil = 25.4um
 
1um = 0.001mm
 
1mm = 39.37mil
 
1um = 0.03937mil
 
1mil = 0.0254mm
*/
const M = "m", CM = "cm";
const scaleKeys = {
  [M]: 0,
  [CM]: -2,
  [MM]: -3,
  [UM]: -6
}

function unitChange(unit, prevUnit, value) {
  let _value = value, scale = null;
  if (unit === prevUnit) {
    return value;
  }
  /*    switch (prevUnit) {
       case MIL:
         scale = unit === MM ? 0.0254 : 25.4;
         break;
       case UM:
         scale = unit === MIL ? 0.03937 : 0.001;
         break;
       case MM:
         scale = unit === MIL ? 39.37 : 1000;
         break;
       default: break;
     }
 
   if (scale) {
     _value = NP.strip(NP.times(value, scale));
   } */
  switch (prevUnit) {
    case MIL:
      let newValue = evaluate(`${value} ${prevUnit} to ${M}`).toString();
      const _newValue = newValue.split(" ")[0];
      scale = getScale(scaleKeys[unit], scaleKeys[M], 10);
      _value = NP.strip(NP.times(_newValue, scale));
      break;
    case UM:
    case MM:
      if (unit === MIL) {
        const _scale = getScale(scaleKeys[M], scaleKeys[prevUnit], 10);
        const newValue = NP.strip(NP.times(value, _scale));
        const new_Value = evaluate(`${newValue} ${M} to ${MIL}`).toString();
        _value = `${new_Value}`.split(" ")[0];
      } else {
        scale = getScale(scaleKeys[unit], scaleKeys[prevUnit], 10);
        _value = NP.strip(NP.times(value, scale));
      }
      break;
    default: break;
  }

  let index = -1;
  if (_value.toString().indexOf('.') > -1) {
    index = _value.toString().substring(_value.toString().indexOf('.') + 1);
  }
  if (_value >= 10000 || (_value > 0 && _value < 0.00001) || index.length > 5) {
    _value = NP.times(_value, 1).toFixed(5);

  }
  return _value;
}

function getLibraryListByType(type, libraryConstructor) {

  const traceLibraryList = libraryConstructor.getLibraryValues(TRACE);
  const viaLibraryList = libraryConstructor.getLibraryValues(VIA);
  const maskLibraryList = libraryConstructor.getLibraryValues(EYE_MASK);
  const cphyMaskLibraryList = libraryConstructor.getLibraryValues(CPHY_EYE_MASK);

  let libraryName = "", libraryList = [], modelName = "";
  switch (type) {
    case TRACE:
      libraryList = traceLibraryList;
      libraryName = "Trace";
      modelName = 'Trace Template';
      break;
    case VIA:
      libraryList = viaLibraryList;
      libraryName = "Via";
      modelName = "Via Template";
      break;
    case EYE_MASK:
      libraryList = [...maskLibraryList, ...cphyMaskLibraryList];
      libraryName = "Mask";
      modelName = "Mask";
      break
    default: break;
  }

  return { libraryList, libraryName, modelName }
}

function parseLibraryConfig(response, type, product) {
  //type ->  microstrip / stripline / via
  if (!response || !response.config) {
    return null;
  }

  const unit = response.config[UNIT];
  const list = type === VIA ? ViaKeyArr : [{ key: 'type', title: 'Signal Type', modify: false }, ...TraceKeyArr, ...TraceColumns, ...TraceKeyZDArr];
  let configList = [];

  for (let i = 0; i < list.length; i++) {
    const item = list[i].key, unitExist = list[i].unit, _type = list[i].type;
    if (!_type || _type === type) {
      const value = response.config[item] ? response.config[item] : "";
      if (value) {
        configList.push({
          title: list[i].title,
          value: unitExist && value ? `${value}` : value,
          unit: unitExist ? unit : "",
          modify: product === ANDES_V2 ? false : list[i].modify,
          key: list[i].key
        });
      }
    }
  }
  return configList;
}

function getGroupLibraryList(libraryConstructor) {
  const traceList = libraryConstructor.getLibraryValues(TRACE);
  const viaList = libraryConstructor.getLibraryValues(VIA);
  return [
    {
      group: "Trace",
      type: TRACE,
      children: [...traceList]
    },
    {
      group: "Via",
      type: VIA,
      children: [...viaList]
    }
  ];
}

export {
  checkLibraryName,
  checkLibraryValue,
  getLibraryConfigVersion,
  parseLibraryData,
  unitChange,
  getLibraryListByType,
  parseLibraryConfig,
  getGroupLibraryList
}