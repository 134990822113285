import CeLayerLogic from './CeLayerLogic';

var CeLayerMask = function () {
  CeLayerLogic.call(this, "SOLDER_MASK");
};

// inherits from CeLayerBase
CeLayerMask.prototype = Object.create(CeLayerLogic.prototype);
CeLayerMask.prototype.constructor = CeLayerMask;

export default CeLayerMask;