// Coarse: remove vias > 100
// Medium: remove vias > 500
// Fine: Do not remove any

export const FAST = 'fast';
export const MEDIUM = 'medium';
export const ORIGINAL = 'original';

// user default setting
export const AURORA_NGSPICE = 'Default'
export const HSPICE = 'HSPICE'
export const ANSYSDesigner = 'ANSYSDesigner'
export const BUILT_IN = 'Default'
export const ANSYS_SIWAVE = 'SIwave'
export const ANSYS_HFSS = 'HFSS'

export const AURORA = 'Aurora'
export const STANDARD = 'Standard'

// fastpi default setting
export const SWEEP = 'Sweep';
export const PARALLEL = 'Parallel'

//cascade default setting
export const DIE_PACKAGE_PCB = 'Die_package_pcb'
export const PCB_PACKAGE_DIE = 'Pcb_package_die'
export const PARTBASED = 'PartBased'
export const COMPONENTBASED = 'ComponentBased'