import { BasicComponent } from './Component';

export class IntegratedInterface {
  constructor(name = '', type = 'General') {
    this.name = name;
    this.info = {
      type: type,
      datarate: '2.5'
    };
    this.signals = []; // [Signal]
    this.components = []; // [RLCCONComponent,ICComponent]
    this.refNets = {
      power: [],
      ground: []
    }; // refNets{power:[],ground:[]}
    this.channel = {};//{channel}
  }
}

// ================== Signal structure ===================
export class Signal {
  constructor(name = '', positive = [], negative = [], dir = '') {
    this.name = name;
    this.direction = dir || '';
    this.positive = positive || [];
    this.negative = negative || [];
  }
}

export class Channel {
  constructor(type = '') {
    this.type = type; // HFSS|SIwave
  }
}

export class Component {
  constructor(component, { type, part, value }) {
    this.name = component;
    this.type = type || '';
    this.part = part || '';
    this.value = value || '';
  }
}

export class NetPins {
  constructor(net) {
    this.net = net || '';
    this.pins = [];
    this.signal = ''
  }
}
export class RLCCONComponent extends BasicComponent {
  constructor(props) {
    super(props);
    this.value = props.value || '';
  }
}

export default IntegratedInterface;
