import CeGeomTools from './CeGeomTools';
import CeVertex from './CeVertex';
import StringList from '../utility/StringList';
import StringHelper from '../utility/StringHelper';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeLocation = function () {
  this.mCW = true;
  this.mRotAngle = 0.0;
  this.mPosition = new CeVertex();
  this.mFlipOnX = false;
  this.mFlipOnY = false;
  this.mScale = 1.0;
}

CeLocation.prototype.SetData = function (x, y, rot, ccw) {
  this.mCW = !ccw;
  this.mRotAngle = rot;
  this.mPosition.SetData(x, y);
  this.mFlipOnX = false;
  this.mFlipOnY = false;
  this.mScale = 1.0;
}

CeLocation.prototype.GetPosition = function () {
  return this.mPosition;
}

CeLocation.prototype.SetPosition = function (x, y) {
  this.mPosition.mX = x;
  this.mPosition.mY = y;
}

CeLocation.prototype.equals = function (fromObj) {
  if (!(fromObj instanceof CeLocation))
    return false;

  if (this.mCW != fromObj.mCW || this.mFlipOnX != fromObj.mFlipOnX || this.mFlipOnY != fromObj.mFlipOnY ||
    this.mPosition.equals(fromObj.mPosition) == false ||
    CeGeomTools.TwoNumberEqual(this.mRotAngle, fromObj.mRotAngle) == false ||
    CeGeomTools.TwoNumberEqual(this.mScale, fromObj.mScale) == false
  )
    return false;

  return true;
}

CeLocation.prototype.SetClockwire = function (cw) {
  this.mCW = cw;
}

CeLocation.prototype.IsClockwise = function () {
  return this.mCW;
}

CeLocation.prototype.GetRotationAngle = function () {
  return this.mRotAngle;
}

CeLocation.prototype.SetRotationAngle = function (rotAngle) {
  this.mRotAngle = rotAngle;
}

CeLocation.prototype.RotateGCPolygon = function (polygon, angle) {
  var radian = angle * Math.PI / 180.0;

  if (CeGeomTools.TwoNumberEqual(radian, 0.0) == false) {
    var cosAngle = Math.cos(radian);
    var sinAngle = Math.sin(radian);
    var x, y;
    for (var i = 0; i < polygon.mNumPnts; i++) {
      x = polygon.mXs[i];
      y = polygon.mYs[i];
      polygon.mXs[i] = cosAngle * x - sinAngle * y;
      polygon.mYs[i] = sinAngle * x + cosAngle * y;
    }
  }
}

CeLocation.prototype.TransformGCPolygon = function (polygon) {
  var radian = this.mRotAngle * Math.PI / 180.0;
  if (this.IsClockwise() && CeGeomTools.TwoNumberEqual(radian, 0.0) == false)
    radian = 2.0 * Math.PI - radian;

  if (CeGeomTools.TwoNumberEqual(radian, 0.0) == true) {
    for (var i = 0; i < polygon.mNumPnts; i++) {
      if (this.mFlipOnX) polygon.mYs[i] = -polygon.mYs[i];
      if (this.mFlipOnY) polygon.mXs[i] = -polygon.mXs[i];
      polygon.mXs[i] += this.mPosition.mX;
      polygon.mYs[i] += this.mPosition.mY;
    }
    return;
  }
  var cosAngle = Math.cos(radian);
  var sinAngle = Math.sin(radian);
  var x, y;
  for (var i = 0; i < polygon.mNumPnts; i++) {
    x = polygon.mXs[i];
    y = polygon.mYs[i];
    // polygon.mXs[i] = cosAngle*x - sinAngle*y + this.mPosition.mX;
    // polygon.mYs[i] = sinAngle*x + cosAngle*y + this.mPosition.mY;

    //flip first and then rotate
    if (this.mFlipOnX) y = -y;
    if (this.mFlipOnY) x = -x;
    polygon.mXs[i] = cosAngle * x - sinAngle * y;
    polygon.mYs[i] = sinAngle * x + cosAngle * y;

    polygon.mXs[i] += this.mPosition.mX;
    polygon.mYs[i] += this.mPosition.mY;
  }
}

CeLocation.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.GetDataString();
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeLocation.prototype.ReadFromIODataNode = function (dataItem) {
  var dataVals = dataItem.GetItemValue();
  return this.mPoints.UpdateByString(dataVals);
}

CeLocation.prototype.GetDataStrings = function () {
  var dataVals = new StringList();
  dataVals.push(this.mPosition.GetDataString());
  dataVals.push(StringHelper.BoolToString(this.mCW));
  dataVals.push(this.mRotAngle);
  dataVals.push(this.mScale);
  dataVals.push(StringHelper.BoolToString(this.mFlipOnX));
  dataVals.push(StringHelper.BoolToString(this.mFlipOnY));
  return dataVals;
}

CeLocation.prototype.UpdateByString = function (dataString) {
  if (dataString.size() == 7) {
    var posVals = new StringList();
    posVals.push(dataString.get(0));
    posVals.push(dataString.get(1));
    this.mPosition.UpdateByString(posVals);
    this.mCW = StringHelper.StringToBool(dataString.get(2));
    this.mRotAngle = parseFloat(dataString.get(3));
    this.mScale = parseFloat(dataString.get(4));
    this.mFlipOnX = StringHelper.StringToBool(dataString.get(5));
    this.mFlipOnY = StringHelper.StringToBool(dataString.get(6));
  }
}

CeLocation.prototype.SetMirrorOnX = function (mirrorOnX) {
  this.mFlipOnX = mirrorOnX;
}


export default CeLocation;