function getApplyAllType({ applyAll, applyAllOuter, applyAllInner }) {

  if (applyAll) {
    return "applyAll";
  }

  if (applyAllOuter) {
    return "applyAllOuter";
  }

  if (applyAllInner) {
    return "applyAllInner";
  }
}

function getCheckApplyAll(type, { applyAll, applyAllOuter, applyAllInner }) {
  switch (type) {
    case "applyAll":
      applyAll = true;
      applyAllInner = false;
      applyAllOuter = false;
      break;
    case "applyAllInner":
      applyAll = false;
      applyAllInner = true;
      applyAllOuter = false;
      break;
    case "applyAllOuter":
      applyAll = false;
      applyAllInner = false;
      applyAllOuter = true;
      break;
    default: break;
  }
  return {
    applyAll,
    applyAllInner,
    applyAllOuter
  }
}

const defaultStackupReducer = {
  data: [],
  materialList: [],
  unit: null,
  loading: true,
  stackupColumnsStatus: false,
  stackupError: null
}

function updateStackupData(designID, stackupObj, action) {
  let _stackupObj = stackupObj ? JSON.parse(JSON.stringify(stackupObj)) : {};
  const prevStackupObj = _stackupObj[designID];

  if (!prevStackupObj) {
    _stackupObj[designID] = {
      ...defaultStackupReducer
    };
  }

  _stackupObj[designID] = {
    ..._stackupObj[designID],
    data: action.data ? action.data : _stackupObj[designID].data,
    materialList: action.materialList ? action.materialList : _stackupObj[designID].materialList,
    stackups: action.stackups ? action.stackups : _stackupObj[designID].stackups,
    zones: action.zones ? action.zones : _stackupObj[designID].zones,
    bends: action.bends ? action.bends : _stackupObj[designID].bends,
    unit: action.unit ? action.unit : _stackupObj[designID].unit,
    loading: false
  };
  return _stackupObj;
}

function updateStackupDataByType(designID, stackupObj, type, data, defaultStackupState) {
  let _stackupObj = stackupObj ? JSON.parse(JSON.stringify(stackupObj)) : {};
  const prevStackupObj = _stackupObj[designID];

  if (!prevStackupObj) {
    const _default = defaultStackupState ? defaultStackupState : defaultStackupReducer
    _stackupObj[designID] = {
      ..._default
    };
  }

  _stackupObj[designID][type] = data;
  return _stackupObj;
}

function updateOldStackupData(designID, stackupObj, action, defaultStackupState) {
  let _stackupObj = stackupObj ? JSON.parse(JSON.stringify(stackupObj)) : {};
  const prevStackupObj = _stackupObj[designID];

  if (!prevStackupObj) {
    _stackupObj[designID] = {
      ...defaultStackupState
    };
  }

  _stackupObj[designID] = {
    ..._stackupObj[designID],
    data: action.data,
    unit: action.unit ? action.unit : _stackupObj[designID].unit,
    loading: false
  };

  return _stackupObj;
}

function removeStackupData(designID, stackupObj) {
  let _stackupObj = stackupObj ? JSON.parse(JSON.stringify(stackupObj)) : {};
  _stackupObj[designID] = undefined;
  return _stackupObj;
}

export {
  getApplyAllType,
  getCheckApplyAll,
  updateStackupData,
  updateStackupDataByType,
  defaultStackupReducer,
  updateOldStackupData,
  removeStackupData
}