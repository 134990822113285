import React, { Component, Fragment } from 'react';
import { Switch, Input, Divider } from 'antd';
import { numExponentialFormat } from '@/services/helper/numberHelper';
import { fastPIValueCheck } from '@/services/helper/fastPIValueCheck';
import HPCOptions from '@/components/ExtractionOptions/HPCOptions';
import SweepOptions from './sweepOptions';
import '../index.css';
import { SIERRA } from '../../../../constants/pageType';

const PowerSIParams = [
  { key: 'pwsiDogleg', title: 'Dogleg hole smaller than', unit: 'mm' },
  { key: 'pwsiThermal', title: 'Thermal hole smaller than', unit: 'mm' },
  { key: 'pwsiHole', title: 'Other hole smaller than', unit: 'mm' },
  { key: 'pwsiViaHole', title: 'Via hole smaller than', unit: 'mm' },
  { key: 'pwsiSlenderHoleSizeThreshold', title: 'Slender hole size smaller than', unit: 'mm' },
  { key: 'pwsiSlenderHoleAreaThreshold', title: 'Slender hole area smaller than', unit: ' mm²' }
];
class PowerSIOptions extends Component {

  switchChange = (checked, type) => {
    let config = { ...this.props.extraction };
    config[type] = checked;
    if (type === "includeDC" && !checked) {
      config["exactDC"] = false;
    }
    this.props.updateExtractionConfig(config);
  }

  changeValue = (e, type) => {
    let value = e.target.value;
    const { extraction, error } = this.props;
    let config = { ...extraction };
    config[type] = value;
    if (error && error.type === type) {
      this.props.updateError(null);
    }
    this.props.updateExtractionConfig(config);
  }

  inputBlur = (e, inputType) => {
    let value = this.props.extraction[inputType];
    let config = { ...this.props.extraction };
    let error = null;
    error = fastPIValueCheck({ value, inputType, unit: null, product: SIERRA });

    if (error) {
      e.target.focus();
      this.props.updateError({ type: inputType, error });
    } else {
      let _value = value;
      if (value) {
        _value = numExponentialFormat(value);
        config[inputType] = _value;
      }
      const newError = error && error.type === inputType ? null : error;
      this.props.updateError(newError ? { type: inputType, error: newError } : null);
      this.props.updateExtractionConfig(config);
    }
  }

  onPressEnter = () => { }

  HPCRender = (type) => {
    const { extraction = {} } = this.props;
    const { numCores, corePercent } = extraction;
    return <HPCOptions
      numCores={numCores}
      corePercent={corePercent}
      type={type}
      ramDisplay={false}
      changeValue={this.changeValue}
      inputBlur={this.inputBlur}
      onPressEnter={this.onPressEnter}
    />
  }

  advancedRender = () => {
    const { extraction = {} } = this.props;
    const { bottomFloating, bottomDistance, topFloating, topDistance } = extraction;
    return <Fragment>
      <Divider />
      <div className='sierra-extraction-content'>
        <span className='sierra-extraction-title-width sierra-extraction-content-body'>Special Circuit Element Support</span>
      </div>
      <div className='sierra-extraction-content'>
        <span className={`sierra-extraction-title-width sierra-extraction-content-body sierra-extraction-sub-span`}>Add Top floating patch at a distance of</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={topFloating || false}
          onChange={(checked) => this.switchChange(checked, 'topFloating')}
        />
        {
          topFloating ?
            <Input
              className='extraction-input sierra-extraction-input-width sierra-extraction-input-advanced'
              addonAfter={'mm'}
              value={topDistance}
              onChange={(e) => this.changeValue(e, 'topDistance')}
              onBlur={(e) => this.inputBlur(e, 'topDistance')}
            /> : null
        }
      </div>
      <div className='sierra-extraction-content'>
        <span className={`sierra-extraction-title-width sierra-extraction-content-body sierra-extraction-sub-span`}>Add Bottom floating patch at a distance of</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={bottomFloating || false}
          onChange={(checked) => this.switchChange(checked, 'bottomFloating')}
        />
        {
          bottomFloating ?
            <Input
              className='extraction-input sierra-extraction-input-width sierra-extraction-input-advanced'
              addonAfter={'mm'}
              value={bottomDistance}
              onChange={(e) => this.changeValue(e, 'bottomDistance')}
              onBlur={(e) => this.inputBlur(e, 'bottomDistance')}
            /> : null
        }
      </div>
    </Fragment>
  }

  getFreqRender = () => {
    const { extraction, units, error } = this.props;
    return <Fragment>
      <Divider />
      <SweepOptions
        extraction={extraction}
        units={units}
        error={error}
        updateUnit={this.props.updateUnits}
        updateError={this.props.updateError}
        updateExtractionConfig={this.props.updateExtractionConfig}
      />
    </Fragment>
  }

  render() {
    const { error, extraction = {}, channelType, currentInterface, isMultiSetup, ifDoExtraction } = this.props;
    const { includeDC, exactDC, pwsiMaxPadSize, pwsiMinShapeSize,
      pwsiAutoSpecialVoid,/*  backdrillVias, backdrillStubSize  */ } = extraction;

    return (
      <div className='sierra-extraction-options-main'>
        <Divider />
        <div className='sierra-re-extraction sierra-powesi-re-extraction'>
          <span>Force Re-Extraction</span>
          <Switch
            size="small"
            disabled={(currentInterface || isMultiSetup) ? false : true}
            className='sierra-re-extraction-switch aurora-switch-small'
            checked={ifDoExtraction === 1 ? true : false}
            onChange={this.props.handleChange}
          />
        </div>
        {/*  <Divider />
        <div className='sierra-extraction-content'>
          <span className="sierra-extraction-content-body">Back Drill Vias</span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={backdrillVias}
            onChange={(checked) => this.switchChange(checked, "backdrillVias")}
          />
          // backdrillStubSize [1, 50] 
          <div className='extraction-content-back-drill-input'>
            <span className='extraction-content-body'>Back Drill Stub Size</span>
            <Input
              className='extraction-input extraction-input-width'
              value={backdrillStubSize}
              addonAfter={"mil"}
              onChange={(e) => this.changeValue(e, 'backdrillStubSize')}
              disabled={!backdrillVias}
              onBlur={(e) => this.inputBlur(e, 'backdrillStubSize')}
            />
          </div>
        </div> */}
        <Divider />
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-content-body'>Include DC</span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={includeDC}
            onChange={(e) => this.switchChange(e, "includeDC")}
          />
          <div className='extraction-content-sub-body'>
            <span className='extraction-content-body'>Exact DC Solver</span>
            <Switch
              size="small"
              checked={exactDC}
              className="aurora-switch-small"
              onChange={(e) => this.switchChange(e, "exactDC")}
              disabled={!includeDC}
            />
          </div>
        </div>
        {this.getFreqRender()}
        <Divider />
        {/* Shape */}
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-title-width sierra-extraction-content-body'>Pad &lt;===&gt; Shape Conversion</span>
        </div>
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-title-width sierra-extraction-content-body sierra-extraction-sub-span'>Max pad size</span>
          <span><Input
            className='extraction-input sierra-extraction-input-width'
            addonAfter={'mm'}
            value={pwsiMaxPadSize}
            onChange={(e) => this.changeValue(e, 'pwsiMaxPadSize')}
            onBlur={(e) => this.inputBlur(e, 'pwsiMaxPadSize')}
            onPressEnter={this.onPressEnter}
          />
          </span>
        </div>
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-title-width sierra-extraction-content-body sierra-extraction-sub-span'>Min shape size</span>
          <span><Input
            className='extraction-input sierra-extraction-input-width'
            addonAfter={'mm'}
            value={pwsiMinShapeSize}
            onChange={(e) => this.changeValue(e, 'pwsiMinShapeSize')}
            onBlur={(e) => this.inputBlur(e, 'pwsiMinShapeSize')}
            onPressEnter={this.onPressEnter}
          />
          </span>
        </div>
        <Divider />
        {/* Special Void Settings */}
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-title-width sierra-extraction-content-body'>Special Void Settings</span>
        </div>
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-title-width sierra-extraction-content-body sierra-extraction-sub-span'>Auto Special Void Settings</span>
          <Switch
            size="small"
            checked={pwsiAutoSpecialVoid}
            className="aurora-switch-small"
            onChange={(e) => this.switchChange(e, "pwsiAutoSpecialVoid")}
          />
        </div>
        {PowerSIParams.map(item => {
          return <div className='sierra-extraction-content' key={item.key}>
            <span className='sierra-extraction-title-width sierra-extraction-content-body sierra-extraction-sub-span'>{item.title}</span>
            <span><Input
              className='extraction-input sierra-extraction-input-width'
              addonAfter={item.unit}
              value={extraction[item.key]}
              onChange={(e) => this.changeValue(e, item.key)}
              onBlur={(e) => this.inputBlur(e, item.key)}
              disabled={pwsiAutoSpecialVoid ? true : false}
            />
            </span>
          </div>
        })}
        {this.HPCRender(channelType)}
        {this.advancedRender()}
        {this.props.passivePortContent ?
          <Fragment>
            <Divider />
            {this.props.passivePortContent()}
          </Fragment>
          : null}
        {error && <span className='sierra-extraction-option-error-msg'>Error: {error.error}</span>}
      </div>
    )
  }
}

export default PowerSIOptions;