import React, { Component } from 'react';
import TreeSelect from '../TreeSelect';
import { CloseCircleFilled } from '@ant-design/icons';
import { Select } from 'antd';
import PinRender from './pinRender';
import { getPinSpiceListWidth, getNextPinEdit } from '../../services/PinSpiceModel';
import "./index.css";

const { Option } = Select;

class PinSpiceModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signalPins: [],
      copySelectPins: [],
      pins: [],
      portsList: []
    }
  }

  componentDidMount = () => {
    const { pinList, pinInfo, getPinListBySignal, pinSpiceModel } = this.props;
    const pins = getPinListBySignal({ pinInfo, pinList, pinSpiceModel });
    const pinNames = pins.map(item => item.pin);

    this.setState({
      signalPins: pinList.filter(item => pinNames.includes(item.pin)),
      pins,
      portsList: []
    }, () => {
      this.updatePinModelsSubckt(pinSpiceModel.modelName, true);
      this.props.savePinSpiceModel(JSON.parse(JSON.stringify(pins)))
    })
  }

  componentDidUpdate = (prevProps) => {
    const { folderFileSubckts } = this.props;
    const { pins } = this.state;
    if ((!prevProps.folderFileSubckts && folderFileSubckts)
      || (prevProps.folderFileSubckts && !folderFileSubckts)
      || (prevProps.folderFileSubckts.length !== folderFileSubckts.length)) {
      const subckt = pins && pins[0] && pins[0].model ? pins[0].model.modelName : "";
      const findSubckt = folderFileSubckts.find(item => item.name === subckt) || { ports: [] };
      this.setState({
        portsList: findSubckt && findSubckt.ports ? findSubckt.ports.map(item => { return { port: item, select: false } }) : [],
      })
    }
  }

  selectFile = (file) => {
    const _pins = this.state.pins;
    const { fileList = [] } = this.props;
    const index = fileList.findIndex(item => item.id === file.id);
    if (index < 0) {
      return;
    }
    const isFolder = fileList[index].type === "folder";
    const folderName = isFolder ? fileList[index].name : "";
    _pins.forEach(item => {
      if (item.libraryId !== file.id || item.fileName !== file.name) {
        item.model = {
          libType: "SPICE",
          libraryId: file.id,
          fileName: file.name,
          folder: folderName,//folder name,
          modelName: "",
          node: this.getPinSpiceNode()
        }
      }
    })
    this.setState({
      portsList: [],
      pins: _pins
    })
    this.props.selectModelFile({ libraryId: file.id, fileName: file.name, folder: folderName });
    this.props.savePinSpiceModel(JSON.parse(JSON.stringify(_pins)))
  }

  getPinSpiceNode = () => {
    const { type, isSetVDDQVSS } = this.props;
    if (type === "tx") {
      return isSetVDDQVSS ? { in: "", out: "", vddq: "", vss: "" } : { in: "", out: "" }
    } else {
      return isSetVDDQVSS ? { out: "", vddq: "", vss: "" } : { out: "" }
    }
  }

  onChangeSubckt = (key) => {
    this.props.selectModelSubckt(key);
    this.updatePinModelsSubckt(key);
  }

  updatePinModelsSubckt = (subckt, isDefault) => {
    const _pins = this.state.pins;
    const { folderFileSubckts } = this.props;
    const findSubckt = folderFileSubckts.find(item => item.name === subckt) || { ports: [] };
    _pins.forEach(item => {
      if (!isDefault && item.model && item.model.modelName !== subckt) {
        item.model = {
          ...item.model,
          modelName: subckt,
          node: this.getPinSpiceNode()
        }
      }
    })
    this.setState({
      portsList: findSubckt && findSubckt.ports ? findSubckt.ports.map(item => { return { port: item, select: false } }) : [],
      pins: _pins
    }, () => {
      this.props.savePinSpiceModel(JSON.parse(JSON.stringify(_pins)))
    })
  }

  deleteFileSelect = (e) => {
    e && e.stopPropagation();
    const _pins = this.state.pins;

    _pins.forEach(item => {
      item.model = {
        libType: "SPICE",
        libraryId: "",
        fileName: "",
        folder: "",//folder name,
        modelName: "",
        node: this.getPinSpiceNode()
      }
    })
    this.setState({
      portsList: [],
      pins: _pins
    })
    this.props.selectModelFile({ libraryId: "", fileName: "", folder: "" });
    this.props.savePinSpiceModel(JSON.parse(JSON.stringify(_pins)))
  }

  getSelectComponents = (type) => {
    const { pinSpiceModel, fileList, folderFileSubckts } = this.props;
    return type === "file" ? <div className='pin-spice-model-file-selection'>
      <span>File</span>
      <TreeSelect
        showSearch
        labelInValue
        placeholder="File"
        size='small'
        value={{
          key: pinSpiceModel.libraryId || "",
          label: pinSpiceModel.folder ? `${pinSpiceModel.folder}::${pinSpiceModel.fileName}` : pinSpiceModel.fileName || ""
        }}
        onSelectItem={this.selectFile}
        className={`spice-netlist-model-selection aurora-select`}
        popupClassName='spice-netlist-model-dropdown'
        popupMatchSelectWidth={false}
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(e) }} /> }}
        fileList={fileList}
        showFileFolder={true}
        selected={`${pinSpiceModel.libraryId}::${pinSpiceModel.fileName}`}
        getPopupContainer={() => document.getElementById('root')}
      />
    </div> :
      <div className='pin-spice-model-file-selection'>
        <Select
          value={pinSpiceModel.modelName}//subckt
          onChange={this.onChangeSubckt}
          showSearch
          size='small'
          popupMatchSelectWidth={false}
          allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.onChangeSubckt(null)} /> }}
          className="spice-pin-model-subckt-select"
          popupClassName='pin-spice-model-select-dropdown'
          getPopupContainer={() => document.getElementById('root')}
        >
          {(folderFileSubckts || []).map((subcktItem) =>
            <Option
              key={subcktItem.name}
              value={subcktItem.name}
              title={subcktItem.name}>{subcktItem.name}</Option>)}
        </Select>
      </div>;
  }

  getPinsNodeInfo = (pins) => {
    const { type, isSetVDDQVSS } = this.props;
    return (pins || []).map(pinInfo => {
      const signalInfo = {
        signalDisplay: pinInfo.signal,
        netDisplay: pinInfo.net
      }
      const _model = pinInfo.model || { node: { in: "", out: "", vddq: "", vss: "" } };
      let info = type === "tx" ? {
        pinLData: {
          pinL: "in",
          pin: pinInfo.pin,
          pinLValue: _model.node ? _model.node.in : ""
        },
        pinRData: {
          pinR: `out ${pinInfo.pin}`,
          pinRValue: _model.node ? _model.node.out : "",
          pin: pinInfo.pin
        },
        signalInfo,
        pinName: pinInfo.pin
      } : {
        pinLData: {
          pinL: pinInfo.pin,
          pinLValue: _model.node ? _model.node.out : "",
          pin: pinInfo.pin
        },
        signalInfo,
        pinName: pinInfo.pin
      }
      if (isSetVDDQVSS) {
        info = {
          ...info,
          pinTData: {
            pinT: "vddq",
            pin: pinInfo.pin,
            pinTValue: _model.node && _model.node.vddq ? _model.node.vddq : "",
          },
          pinBData: {
            pinB: "vssq",
            pin: pinInfo.pin,
            pinBValue: _model.node && _model.node.vss ? _model.node.vss : "",
          }
        }
      }
      return info;
    })
  }

  openSelectNode = ({/*  pin, port, */ pinType, pinData }) => {
    this.setState({
      editNode: `${pinData.pin}::${pinType}`,
      searchValue: ""
    })
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: null,
      searchValue: ""
    })
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  deleteSelectNode = ({ e, pinData, pinType }) => {
    e && e.stopPropagation();
    this.updatePinPort({
      pin: pinData.pin,
      pinType,
      port: ""
    })
  }

  selectPinPort = ({ port, pin, type: pinType, pinData }) => {
    this.updatePinPort({
      pin: pinData.pin,
      pinType,
      port,
      updateEditNode: true
    })
    this.setState({
      editNode: null,
      searchValue: null
    })
  }

  updatePinPort = ({ pin, pinType, port, updateEditNode }) => {
    const { pins } = this.state;
    const { type } = this.props;
    let _pins = [...pins];
    const index = _pins.findIndex(item => item.pin === pin);
    if (index < -1) {
      return;
    }
    if (pinType === "pinT") {
      _pins.forEach(item => item.model.node.vddq = port);
    } else if (pinType === "pinB") {
      _pins.forEach(item => item.model.node.vss = port);
    } else if (type === "tx") {
      if (pinType === "pinL") {
        _pins[index].model.node.in = port;
      } else {
        _pins[index].model.node.out = port;
      }
    } else {
      _pins[index].model.node.out = port;
    }

    this.setState({
      pins: _pins,
      editNode: updateEditNode ? null : this.state.editNode,
      searchValue: updateEditNode ? null : this.state.searchValue
    }, () => {
      this.props.savePinSpiceModel(JSON.parse(JSON.stringify(_pins)))
      updateEditNode && this.updateNewEditNode(pin, pinType);
    })
  }

  updateNewEditNode = (pin, pinType) => {
    const { pins, portsList } = this.state;
    const { isSetVDDQVSS, type } = this.props;
    const selectedPorts = pins.map(item => {
      return item.model && item.model.node ? [item.model.node.in, item.model.node.out, item.model.node.vddq, item.model.node.vss] : []
    }).flat(2).filter(item => !!item);
    const filterPorts = portsList.filter(item => !selectedPorts.includes(item.port));
    if (!filterPorts || !filterPorts.length) {
      // All ports have been selected, there is no need to jump to the next one
      return;
    }

    const editNode = getNextPinEdit({ pin, pinType, pins, type, isSetVDDQVSS });
    this.setState({ editNode })
  }

  render = () => {
    const { pins, portsList, editNode, searchValue } = this.state;
    const { maxWidth, maxHeight } = this.props;
    const pinList = this.getPinsNodeInfo(pins);
    const { leftPinWidth, rightPinWidth, topPinWidth, bottomPinWidth } = getPinSpiceListWidth(pinList, "pin");
    const { leftPinWidth: leftPinValueWidth, rightPinWidth: rightPinValueWidth, topPinWidth: topPinValueWidth, bottomPinWidth: bottomPinValueWidth } = getPinSpiceListWidth(pinList, "value");
    return <div className="spice-pin-model-assign-content">
      <div className="spice-pin-model-file-selection-content">
        {this.getSelectComponents("file")}
        {this.getSelectComponents("modelName")}
      </div>

      <ul className='spice-pin-list-ul'>
        {pinList.map((itemData, index) => <PinRender
          key={itemData.pinName}
          pinName={itemData.pinName}
          editNode={editNode}
          portsList={portsList}
          searchValue={searchValue}
          pins={pins}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          leftPinWidth={leftPinWidth}
          rightPinWidth={rightPinWidth}
          leftPinValueWidth={leftPinValueWidth}
          rightPinValueWidth={rightPinValueWidth}
          {...itemData}
          openSelectNode={this.openSelectNode}
          deleteSelectNode={this.deleteSelectNode}
          selectPinPort={this.selectPinPort}
          closeNodesSelection={this.closeNodesSelection}
          searchNode={this.searchNode}
          topPinWidth={topPinWidth}
          bottomPinWidth={bottomPinWidth}
          topPinValueWidth={topPinValueWidth}
          bottomPinValueWidth={bottomPinValueWidth}
          isEnd={index === pinList.length - 1 ? true : false}
          isFirst={index === 0 ? true : false}
        />
        )}
      </ul>
    </div>
  }
}

export default PinSpiceModel;