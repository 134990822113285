import React, { Component, Fragment, createRef } from "react";
import { createPortal } from "react-dom";
import Panel from "../../../../components/Panel";
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from "../../../../services/helper/panelSizeHelper";
import { PART_IBIS_LIBRARY, PART_REPEATER_LIBRARY } from "../../../../constants/libraryConstants";
import RepeaterLibrary from "./repeaterLibrary";
import { Input, Spin, Button } from 'antd';
import {
  getPartLibraryList,
  uploadPartLibraryFile,
  getPartLibraryCheckFiles,
  editPartLibrary,
  getPartLibraryListBySearch,
  delLibraryList,
  setDefaultRepeaterModelNodes
} from "../../../../services/Sierra/library";
/* import partLibraryConstructor from "../../../../services/Sierra/library/partLibraryConstructor"; */
import IBISLibrary from "./ibisLibrary";
import DelConfirm from "../../../../components/DelConfirm";
import CheckFilesPanel from "./checkFilesPanel";
import MsgDialog from "../../../../components/MsgDialog";
import "./index.css";

class PartLibraryPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
      maxWidth: 0,
      page: 1,
      pageSize: 40,
      totalPages: 1,
      data: [],
      totalElements: 40,
      checkFiles: [],
      checkVisible: false,
      isUpdate: false,
      repeaterUpdate: false,
      prevRepeaterList: [],
      loading: false,
      openLoading: false
    }
    this.dialogRoot = document.getElementById("root");
    this.uploadRef = createRef();
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    this.resize()
    window.addEventListener('resize', this.resize);
    this.getLibraryData()
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { modelType, closePartRepeater, isUpdateFile, isDelRepeater } = this.props;

    const _isUpdateFile = isUpdateFile && isUpdateFile !== prevProps.isUpdateFile,
      isDelFile = isDelRepeater && isDelRepeater !== prevProps.isDelRepeater;
    if (_isUpdateFile) {
      //re-set default repeater model file, subckt and nodes
      const { data, checkFiles } = this.state;
      this.setState({
        openLoading: true
      })
      this.updateRepeaterModel(data, checkFiles)
      this.props.updateRepeaterFileStatus(false)
    }

    if (isDelFile) {
      this.props.updateDelRepeaterStatus(false)
      //check files
      const checkFiles = await getPartLibraryCheckFiles(modelType);
      this.setState({
        checkFiles
      })
    }

    if (prevProps.modelType !== modelType) {
      if (this.state.isUpdate) {
        this.props._updateSetupAfterClosePartLibrary(prevProps.modelType);
      }
      this.getLibraryData()
    }
    if (prevProps.closePartRepeater !== closePartRepeater && closePartRepeater) {
      console.log("Part library close")
      this.props._updateSetupAfterClosePartLibrary(modelType);
      this.props._closePartRepeaterPanel(false)
      this.props.closeLibraryPanel(modelType)
    }

    const { saving, loading } = this.state;
    if (prevState.saving && !saving && loading) {
      const isClose = this.checkUpdate(modelType);
      if (!isClose) {
        this.setState({
          loading: false
        })
        return;
      }
      if (this.state.isUpdate) {
        console.log("Part library close")
        this.props._updateSetupAfterClosePartLibrary(this.props.modelType);
      }
      this.props.closeLibraryPanel(this.props.modelType)
    }
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 700),
      maxWidth: getPanelMaxWidth(offset, 900)
    })
  }

  closeModal = () => {
    const { modelType } = this.props;
    if (this.state.saving) {
      this.setState({
        loading: true
      })
      return
    }
    const isClose = this.checkUpdate(modelType);
    if (!isClose) {
      this.setState({
        loading: false
      })
      return;
    }
    if (this.state.isUpdate) {
      console.log("Part library close")
      this.props._updateSetupAfterClosePartLibrary(modelType);
    }
    this.props.closeLibraryPanel(modelType)
  }

  checkUpdate = (modelType) => {
    if (modelType === PART_IBIS_LIBRARY
      || !this.state.isUpdate
      || !this.state.repeaterUpdate) {
      return true
    }
    const { data, prevRepeaterList } = this.state;
    const _data = JSON.parse(JSON.stringify(data)),
      _prevRepeaterList = JSON.parse(JSON.stringify(prevRepeaterList));

    const addRepeaterParts = _data.filter(item => !_prevRepeaterList.find(prev =>
      prev.partNumber === item.partNumber
      && (
        (!prev.position && !item.position)
        || (!prev.position && item.position === "0")
        || (prev.position === "0" && !item.position)
        || (prev.position === item.position))
    )).map(item => { return { partNumber: item.partNumber, position: item.position } });
    if (!addRepeaterParts.length) {
      return true;
    }
    this.props._updatePartRepeater(addRepeaterParts);
  }

  onPageChange = (page) => {
    this.setState({
      page
    }, () => {
      this.getLibraryData(page)
    })
  }

  updateStatus = (update, key = "isUpdate") => {
    this.setState({
      [key]: update
    })
  }

  getLibraryData = async (page, isUpload) => {
    const { modelType } = this.props;
    const { pageSize, prevRepeaterList } = this.state;
    this.setState({
      openLoading: true
    })
    let _page = page || 1, _pageSize = pageSize || 40, checkFiles = [];
    let libraryList = /* page ? partLibraryConstructor.get(modelType, _page) : */ null;
    let totalPages = this.state.totalPages, totalElements = this.state.totalElements, _prevRepeaterList = [...prevRepeaterList];
    if (!libraryList || !libraryList.length) {
      try {
        const libraryObj = await getPartLibraryList({ page: _page - 1, pageSize: _pageSize, type: modelType });
        checkFiles = await getPartLibraryCheckFiles(modelType);
        if (libraryObj) {
          libraryList = libraryObj.data;
          totalPages = libraryObj.totalPages || totalPages;
          totalElements = libraryObj.totalElements || totalElements;
          _pageSize = totalElements;
          if (!isUpload) {
            _prevRepeaterList = JSON.parse(JSON.stringify(libraryObj.data || []))
          }
        }
      } catch (error) {
        console.error(error);
        this.setState({
          msg: "Get library failed!",
          openLoading: false,
        })
        setTimeout(() => {
          this.setState({
            msg: ""
          })
        }, 3000)
      }
    }

    if (libraryList && libraryList.length) {
      /* partLibraryConstructor.set(modelType, _page, libraryList); */
      if (modelType === PART_REPEATER_LIBRARY) {
        //set default repeater model subckt and nodes
        await this.updateRepeaterModel(libraryList, checkFiles)
      } else {
        this.setState({
          data: libraryList,
          repeaterUpdate: false,
          openLoading: false,
          checkFiles: checkFiles || [],
        })
      }

      this.setState({
        page: _page,
        pageSize: _pageSize,
        totalPages,
        totalElements,
        prevRepeaterList: _prevRepeaterList,
      })
    } else {
      this.setState({
        openLoading: false
      })
    }
  }

  updateRepeaterModel = async (libraryList, checkFiles) => {
    let isUpdate = false;
    const { modelType } = this.props;
    if (modelType === PART_REPEATER_LIBRARY) {
      const info = await setDefaultRepeaterModelNodes(libraryList);
      libraryList = info.libraryList;
      isUpdate = info.isUpdate;
      const _checkFiles = await getPartLibraryCheckFiles(modelType);
      this.setState({
        data: libraryList,
        repeaterUpdate: isUpdate || this.state.repeaterUpdate,
        checkFiles: _checkFiles
      })
      const editedParts = info.editParts;
      if (editedParts && editedParts.length) {
        try {
          await editPartLibrary({ type: modelType, editedParts });
          const _checkFiles = await getPartLibraryCheckFiles(modelType);
          this.setState({
            checkFiles: _checkFiles,
            openLoading: false
          })
        } catch (error) {
          this.setState({
            openLoading: false,
            checkFiles
          })
        }
      } else {
        this.setState({
          openLoading: false,
          checkFiles
        })
      }
    }

    return { isUpdate, libraryList }
  }

  uploadExcel = () => {
    const ele = this.uploadRef.current;
    if (!ele) return;
    ele.click();
  }

  uploadFile = (e) => {
    this.file = e.target.files[0];
    const { data } = this.state;
    if (data.length) {
      this.setState({
        openPanel: true
      })
    } else {
      this._uploadFile("merge")
    }
    if (this.uploadRef.current && this.uploadRef.current.value) {
      this.uploadRef.current.value = '';
    }
  }

  _uploadFile = async (_uploadType) => {
    const { modelType } = this.props;
    this.setState({
      openPanel: false,
      saving: true
    })
    if (!this.file) {
      return;
    }
    const uploadType = _uploadType === "merge" ? "Add" : "Replace";
    try {
      //get api to parse file and get connections
      const checkFiles = await uploadPartLibraryFile({ file: this.file, type: modelType, uploadType });
      /*    partLibraryConstructor.clearAll(modelType); */
      this.setState({
        msg: "Upload file successfully!",
        checkFiles,
        checkVisible: checkFiles && checkFiles.length ? true : false,
        isUpdate: true,
        saving: false,
        repeaterUpdate: modelType === PART_REPEATER_LIBRARY ? true : this.state.repeaterUpdate
      });
      this.getLibraryData(1, true);
    } catch (error) {
      console.error(error);
      this.setState({
        msg: "Upload file failed!",
        checkFiles: [],
        isUpdate: true,
        saving: false
      })
    }
    setTimeout(() => {
      this.setState({
        msg: ""
      })
    }, 3000)
    if (this.uploadRef.current && this.uploadRef.current.value) {
      this.uploadRef.current.value = '';
    }
  }

  saveData = async ({ data, editedPart, checked, prevFile, index = -1, delPart }, libType) => {
    this.setState({
      data,
      saving: true
    })
    const { checkFiles } = this.state;
    let _checkFiles = [...checkFiles];
    const { modelType } = this.props;
    if (delPart && delPart.id) {
      try {
        const editLibrary = await delLibraryList([delPart.id], modelType);
        _checkFiles = checkFiles.filter(item => item !== delPart.file)
        if (!delPart.id && index > -1) {
          data[index].id = editLibrary && editLibrary.id ? editLibrary.id : null;
        }
        this.setState({
          data,
          checkFiles: _checkFiles,
          isUpdate: true
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (!editedPart || !editedPart.partNumber) {
      this.setState({
        saving: false
      })
      return;
    }

    try {
      const editLibraryList = await editPartLibrary({ type: modelType, editedParts: [editedPart] });
      const editLibrary = editLibraryList && editLibraryList.length ? editLibraryList[0] : null;
      if (!editedPart.id && index > -1) {
        data[index].id = editLibrary && editLibrary.id ? editLibrary.id : null;
        this.setState({
          data
        })
      }
      this.setState({
        isUpdate: true,
        repeaterUpdate: libType === PART_REPEATER_LIBRARY ? true : this.state.repeaterUpdate
      })
    } catch (error) {
      console.error(error)
    }
    const fileName = modelType === PART_IBIS_LIBRARY ? editedPart.file : editedPart.model;
    if (!fileName || !checked) {
      this.setState({
        saving: false
      })
      return;
    }
    const modelList = await getPartLibraryListBySearch({ partNumbers: [editedPart.partNumber], type: modelType });
    const findModel = (modelList || []).find(item => item.partNumber === editedPart.partNumber);

    _checkFiles = prevFile ? checkFiles.filter(item => item !== prevFile) : checkFiles;
    const libraryExist = findModel && findModel.libraryId;
    if (libraryExist) {
      _checkFiles = checkFiles.filter(item => item !== fileName)
    } else {
      _checkFiles.push(fileName)
    }
    this.setState({
      checkFiles: _checkFiles,
      saving: false
    })
  }

  updateMessage = (msg) => {
    this.setState({
      msg
    });
    setTimeout(() => {
      this.setState({
        msg: ""
      })
    }, 3000)
  }

  updatePrevRepeaterList = (prevRepeaterList) => {
    this.setState({
      prevRepeaterList
    })
  }

  getLibraryTable = () => {
    const { modelType, libraryTreeInfo } = this.props;
    const { pageSize, page, data, totalPages, totalElements, checkFiles, maxHeight, maxWidth, isUpdate, prevRepeaterList } = this.state;
    if (modelType === PART_REPEATER_LIBRARY) {
      return <RepeaterLibrary
        data={data}
        pageSize={pageSize}
        page={page}
        totalPages={totalPages}
        totalElements={totalElements}
        checkFiles={checkFiles}
        libraryTreeInfo={libraryTreeInfo}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        isUpdate={isUpdate}
        prevRepeaterList={prevRepeaterList}
        onPageChange={this.onPageChange}
        saveData={this.saveData}
        updateMessage={this.updateMessage}
        updateStatus={this.updateStatus}
        uploadExcelRender={this.uploadExcelRender}
        checkFileButton={this.checkFileButton}
        updatePrevRepeaterList={this.updatePrevRepeaterList}
      />
    } else if (modelType === PART_IBIS_LIBRARY) {
      return <IBISLibrary
        data={data}
        pageSize={pageSize}
        page={page}
        totalPages={totalPages}
        totalElements={totalElements}
        checkFiles={checkFiles}
        isUpdate={isUpdate}
        onPageChange={this.onPageChange}
        saveData={this.saveData}
        updateMessage={this.updateMessage}
        uploadExcelRender={this.uploadExcelRender}
        checkFileButton={this.checkFileButton}
      />
    }
  }

  uploadExcelRender = () => {
    return <Button
      type="link"
      onClick={this.uploadExcel}
      className="part-library-upload-button"
    >Upload Excel</Button>
  }

  searchRender = () => {
    const { searchKey } = this.state;
    return <div className='part-library-dialog-search'>
      <Input
        value={searchKey}
        className='aurora-input'
        onChange={(e) => this.changeSearch(e)}
        onBlur={(e) => this.searchBlur(e)}
        onPressEnter={(e) => this.searchBlur(e)}
      />
    </div>
  }


  changeSearch = (e) => {
    this.setState({
      searchKey: e.target.value
    })
  }

  searchBlur = async (e) => {
    e.target.blur && e.target.blur();
    /*   const searchKey = e.target.value;
      //get search list
      const { modelType } = this.props; */
    //const searchList await getPartLibraryListBySearch({ partNumbers:[], type: modelType })
    /*    if (!searchList || !searchList.length) {
         return;
       }
       let pageMap = new Map();
       let page = 1;
       for (let i = 0; i < searchList.length; i++) {
         if (i >= (page - 1) * 40 && i <= page * 40) {
           pageMap.set(page, [...(pageMap.get(page) || []), searchList[i]])
         }
       }
       this.setState({
         pageMap,
         page: 1,
         pageSize: 40,
         showSearch: true
       }) */
  }

  closeCheckPanel = () => {
    this.setState({
      checkVisible: false
    })
  }

  checkLibraryFiles = async () => {
    this.setState({
      checkVisible: true,
    }, async () => {
      const checkFiles = await getPartLibraryCheckFiles(this.props.modelType)
      this.setState({
        checkVisible: true,
        checkFiles: checkFiles || []
      })
    })
  }

  checkFileButton = () => {
    return <Button
      type="link"
      className="part-library-check-button"
      onClick={this.checkLibraryFiles}
    >Check Model</Button>
  }

  render = () => {
    const { modelType, updatePartRepeaterLogs } = this.props;
    const { maxHeight, maxWidth, msg, openPanel, checkVisible, checkFiles, loading, openLoading } = this.state;
    const msgClass = msg && msg.match("failed") ? "aurora-model-name-error-msg" : (msg && msg.match("success") ? "aurora-success-msg" : "aurora-model-name-error-msg");
    const content = (
      <Fragment>
        <Panel
          title={<div>
            <span>Part {this.getLibraryTitle(modelType)} Library</span>
            {/*  <Tooltip title={"Open the library files check panel"} overlayClassName="icon-tooltip">
              <Icon
                type="check-square"
                className="sierra-part-library-check-file-icon"
                onClick={this.checkLibraryFiles} />
            </Tooltip> */}

            {/* {this.uploadExcelRender()} */}
          </div>}
          className='sierra-part-library-panel sierra-panel'
          position='panel-center-top'
          minHeight={200}
          minWidth={100}
          width={getPanelWidth(maxWidth, { defaultWidth: 900 })}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onCancel={() => { this.closeModal() }}
          draggable
          zIndex={2000}
        >
          <div className="part-library-content">
            <Spin spinning={loading || openLoading} tip={loading ? `Saving...` : (openLoading ? "Loading data..." : "")}>
              {/*  {this.uploadExcelRender()} */}
              {msg ? <div className={msgClass}>{msg}</div> : null}
              {/* {this.searchRender()} */}
              {this.getLibraryTable()}
            </Spin>
          </div>
        </Panel>
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={".csv,.xls,.xlsx"}
          onChange={(e) => this.uploadFile(e)}
        />
        {
          openPanel ? <DelConfirm
            type="uploadPartLibrary"
            maskStyle={true}
            delConfirmClick={this._uploadFile}
            message={"Keep the existing setup?"}
          /> : null
        }
        {
          checkVisible ? <CheckFilesPanel
            checkFiles={checkFiles}
            modelType={modelType}
            closeCheckPanel={this.closeCheckPanel}
          /> : null
        }
        {
          updatePartRepeaterLogs ?
            <MsgDialog
              mark={true}
              className="sierra-panel"
              messageList={[updatePartRepeaterLogs]}
            />
            : null
        }
      </Fragment>
    );
    return createPortal(content, this.dialogRoot)
  }

  getLibraryTitle = (type) => {
    if (type === PART_IBIS_LIBRARY) {
      return "Buffer"
    }
    if (type === PART_REPEATER_LIBRARY) {
      return "Repeater"
    }
  }
}

export default PartLibraryPanel;