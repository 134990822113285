import VerifySetupDatabase from './verifySetupDatabase';
class PinToPinVerify extends VerifySetupDatabase {
  constructor(props) {
    super(props);
  }

  mergeInterfacesInfo(interfacesInfo, verificationName, isAddComp = false) {
    let newContent = { name: verificationName, signals: [], powerNets: [], components: [], powerComponents: [], virtualComps: [] };
    if (!interfacesInfo) {
      interfacesInfo = []
    }
    interfacesInfo.forEach(info => {
      let { content, pcb, pcbId, version } = info;
      newContent.version = version;
      if (isAddComp) {
        INCLUDES_COMPS_PRAMS.forEach(pram => { content[pram] && newContent[pram].push(...addPCB(content[pram], pcb, pcbId)) });
      } else {
        PRAMS.forEach(pram => { content[pram] && newContent[pram].push(...addPCB(content[pram], pcb, pcbId)) });
      }
    });
    if (newContent.signals && Array.isArray(newContent.signals)) {
      let _signals = [], _signalNames = [], signals = [];
      newContent.signals.forEach(signal => {
        const index = _signalNames.indexOf(signal.name);
        if (index > -1) {
          _signals[index].push(signal);
        } else {
          _signalNames.push(signal.name);
          _signals.push([signal]);
        }
      });
      _signals.forEach(item => {
        item[0].signalLength = item.length;
        signals.push(...item);
      });
      newContent.signals = signals;
    };
    return newContent;
  }

}

const PRAMS = ['signals', 'powerNets'];
const INCLUDES_COMPS_PRAMS = ['signals', 'powerNets', "components", "powerComponents", "virtualComps"];
function addPCB(prev, pcb, pcbId) {
  return prev && prev.length > 0 ? prev.map(item => ({ ...item, pcb, pcbId })) : [];
}


const PinToPinVerifySetup = new PinToPinVerify('PinToPin');
export default PinToPinVerifySetup;