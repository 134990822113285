import { unitChange } from './mathHelper';

/** 
 * Target File
 * fileType: .s1p
*/
function parseTargetFile(fileContent) {
  if (!fileContent) return [];
  let data = fileContent.match(/[^\r\n]+/g);
  data = data.map(line => line.replace(/^\s*/g, '').split(/[ \t]+/g));
  let unit = "";
  while (true) {
    const topLine = data.shift();
    if (topLine[0] === '#' || !data.length) {
      unit = topLine[1];
      break;
    }
  }

  if (!unit.includes('Hz')) return [];
  return data.map(array => {
    if (array.length < 2) {
      return null
    }
    const frequency = unitChange({ oldUnit: unit, newUnit: 'Hz', num: array[0] }).number;
    return { frequency: frequency, impedance: array[1] }
  }).filter(item => item);
}

export {
  parseTargetFile
}