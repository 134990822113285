import React, { Component, Fragment } from 'react';
import { CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Select, TreeSelect } from 'antd';
import Panel from '../../Panel';
import { createPortal } from 'react-dom';
import { getPortNumberFromFileSuffix } from '../../../services/helper/touchstoneHelper';
import SignalPinsPortSetup from './signalPinsPortSetup';
import CopySignalPinsPortSetup from './copySignalPinsPortSetup';
import {
  getCopyGroups,
  updateCopyGroupsBySelectFile,
  updateCopyGroupsBySignal,
  getChannelSelectedSignalsBySelectFile
} from '../../../services/helper/connectorHelper';
import { clearPairsPortByFile, clearPkgPinsPortBySignal, updateSelectSignalDefaultValue, getModelTypePins, getModelTitle, getSignalAndPin, getMultiPinInfo, getAutoFillNode } from '../../../services/helper/packageModelHelper';
import { getDefaultIndex } from '../../../services/helper/setDefaultName';
import { SOCKET, TOUCHSTONE } from '../../../constants/libraryConstants';
import { SortFn } from '../../../services/helper/sort';
import {
  filterUnusedModelFiles,
  copyPinsPkgPorts,
  copyPinPkgPortsBySignal,
  getPkgSelectFile,
  savePinPortsBySelectedSignals
} from '../../../services/helper/packageModelHelper';
import AutomaticPanel from '@/components/PortMatch';
import { ANDES_V2, ROCKY } from '@/constants/pageType';
import './index.css';
import { CPHY } from '../../../services/PCBHelper/constants';

const { Option, OptGroup } = Select;
const { SHOW_PARENT } = TreeSelect;
const PACKAGE = 'Package', SPICE = 'SPICE';
function getTreeData(signals) {
  return [{
    title: 'All',
    value: 'all',
    key: 'all',
    children: signals.map(name => ({ title: name, value: name, key: name }))
  }]
}

class ModelSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectFile: {},
      selectFile2: null,
      selectedSignals: [],
      copySignals: [],
      error: null,
      pinList: [],
      pairs: [],
      models: [],
      copyGroups: [], //{ modelKey: "", signalMap: [{ fromSignal: "", toSignal: "" }] },
      automaticInfo: [],
      topology: "",
      terminationSelectFile: null,
      termination: {}
    }
    this.dialogRoot = document.getElementById('root');
    this.signals = [];
    this.differentDisplay = this.props.modelType === SOCKET || (this.props.modelType === PACKAGE && this.props.product === ROCKY) ? true : false;
  }

  getPinIsSame = (info, determineInfo, type) => {
    if (!info || !determineInfo.pinRight || !determineInfo.pinRight.length || !determineInfo.pinRight[0]) {
      return true
    }
    if (type === 'pin') {
      if (determineInfo.pinRight[0].pin === info) {
        return true
      }
      return false
    }
    if ((!info.pinRight ||
      (info.pinRight && info.pinRight.length && determineInfo.pinRight && determineInfo.pinRight.length && info.pinRight[0].pin === determineInfo.pinRight[0].pin))) {
      return true
    }
    return false
  }

  componentDidMount = () => {
    const { pins,
      modelPinInfo,
      pinList,
      pairs,
      files,
      selectAllSignals,
      determinePinSame,
      serdesType,
      product,
      topology,
      termination = {}
    } = this.props;
    let _pinList = JSON.parse(JSON.stringify(pinList)),
      _files = JSON.parse(JSON.stringify(files)),
      _pairs = JSON.parse(JSON.stringify(pairs));
    if (determinePinSame) {
      let _signals = [];
      for (let pin of pins) {
        const name = getMultiPinInfo(pins, pin)
        _signals.push(name)
      }
      this.signals = [..._signals]
    } else {
      this.signals = [...new Set(pins.map(item => item.signal))];
    }

    let signalPinList = pinList.filter(item => item.signal === modelPinInfo.signal);

    if (signalPinList.length > 1 && determinePinSame) {
      signalPinList = signalPinList.filter(item => (determinePinSame && this.getPinIsSame(item, modelPinInfo)))
    }

    const { selectFile = {}, selectFile2 } = getPkgSelectFile(signalPinList, _files, serdesType, product);

    //find signals for connector model is selectFile
    const selectedSignals = getChannelSelectedSignalsBySelectFile({
      selectFile,
      selectFile2,
      pinList,
      signal: modelPinInfo.signal,
      pin: modelPinInfo.pinRight && modelPinInfo.pinRight[0] ? modelPinInfo.pinRight[0].pin : '',
      determinePinSame: determinePinSame
    });
    const selectSignal = determinePinSame ? getMultiPinInfo(pins, modelPinInfo, 'pinRight') : modelPinInfo.signal;
    let _selectedSignals = selectAllSignals ? [...this.signals] : [...new Set([selectSignal, ...selectedSignals])];

    this.setState({
      selectFile,
      copySignals: [],
      error: null,
      pairs: _pairs,
      pinList: _pinList,
      models: _files,
      copyGroups: [],
      selectFile2,
      selectedSignals: _selectedSignals,
      topology: topology,
      terminationSelectFile: termination.files && termination.files.length > 0 ? termination.files[0] : null,
      termination: JSON.parse(JSON.stringify(termination))
    });
  }

  _updatePorts = ({ pairs, pinList, type, newModel }, updateCopyPort, fromSignal) => {
    const { termination = {}, models } = this.state;
    let _pairs = JSON.parse(JSON.stringify(pairs)),
      _pinList = JSON.parse(JSON.stringify(pinList)),
      _models = JSON.parse(JSON.stringify(models)),
      _terminationFiles = JSON.parse(JSON.stringify(termination.files || []));
    if (updateCopyPort) {
      const info = this.updateCopySignalPorts({ fromSignal, _pairs: pairs, _pinList: pinList, type, newModel });
      _pairs = info._pairs;
      _pinList = info._pinList;
      _models = info.models;
      _terminationFiles = info.terminationFiles
    }
    if (type === 'pinTermination') {
      this.setState({
        termination: {
          ...termination,
          pairs: _pairs,
          files: newModel ? [..._terminationFiles, newModel] : _terminationFiles
        },
        pinList: _pinList
      })
    } else {
      this.setState({
        pairs: _pairs,
        pinList: _pinList,
        models: newModel ? [..._models, newModel] : _models
      })
    }
  }

  updateCopySignalPorts = ({ fromSignal, _pairs, _pinList, _copyGroups, type, newModel }) => {
    const { modelType, product } = this.props;
    const { copyGroups, pairs, termination = {}, models } = this.state;
    _copyGroups = _copyGroups ? _copyGroups : [...copyGroups];


    const info = copyPinPkgPortsBySignal({
      _copyGroups,
      pinList: _pinList,
      pairs: type !== 'pinTermination' ? _pairs : pairs,
      fromSignal,
      determineComp: this.props.determineComp,
      determinePinSame: this.props.determinePinSame,
      isRank: this.props.rank && this.props.rank.number ? true : false,
      terminationPairs: type === 'pinTermination' ? _pairs : termination.pairs,
      terminationFiles: type !== 'pinTermination' && newModel ? [...(termination.files || []), newModel] : termination.files,
      modelType,
      product,
      models: type !== 'pinTermination' && newModel ? [...models, newModel] : models
    });

    return {
      _pinList: info.pinList,
      _pairs: type === 'pinTermination' ? info.terminationPairs : info.pairs,
      models: info.models,
      terminationFiles: info.terminationFiles
    };
  }

  closeModal = () => {
    const { pinList, pairs, models = [], selectedSignals, copySignals, termination = {} } = this.state;
    const { pinList: propsPinList, pairs: propsPairs, determineComp, determinePinSame, termination: propsTermination = {}, modelType, product } = this.props;
    let _models = [...models], _terminationFiles = [...(termination.files || [])]
    let _copySignals = [...copySignals];
    if (_copySignals[0] && _copySignals[0] === "all") {
      _copySignals = this.signals.filter(item => !selectedSignals.includes(item));
    }
    try {
      const { _pinList, _pairs, _terminationPairs } = savePinPortsBySelectedSignals({
        pinList,
        pairs,
        propsPinList,
        propsPairs,
        selectedSignals,
        copySignals: _copySignals,
        determineComp: determineComp,
        determinePinSame: determinePinSame,
        terminationPairs: termination.pairs || [],
        propsTerminationPairs: propsTermination.pairs || []
      })

      _models.forEach(file => { delete file.portNumber });
      _models = filterUnusedModelFiles(_models, _pairs);
      if (modelType === "DIE" && product === ANDES_V2) {
        _terminationFiles.forEach(file => { delete file.portNumber });
        _terminationFiles = filterUnusedModelFiles(_terminationFiles, _terminationPairs);
      }
      this.props.savePinModels({
        pinList: _pinList,
        pairs: _pairs,
        files: JSON.parse(JSON.stringify(_models)),
        termination: {
          ...termination,
          pairs: _terminationPairs,
          files: _terminationFiles
        }
      })
    } catch (error) {
      console.error(error);
      this.props.savePinModels({}, true)
    }
  }

  addSelectFile2 = () => {
    const { models, selectFile, copyGroups, pinList, pairs } = this.state;
    let _models = [...models],
      _pinList = [...pinList],
      _pairs = [...pairs];

    let _selectFile = { ...selectFile };

    //add new modelKey
    const modelKeys = _models.map(item => item.modelKey);
    const modelKey = getDefaultIndex(_models.length, modelKeys);
    let file = {
      fileName: "",
      libraryId: "",
      type: "",
      modelKey,
      subckt: "",
      version: ""
    };

    //update pkg models file
    _models.push({ ...file });
    //clear prev file ports
    if (_selectFile && _selectFile.libraryId) {
      const info = clearPairsPortByFile({
        _pinList,
        _pairs,
        selectFile
      });
      _pinList = info._pinList;
      _pairs = info._pairs;
    }

    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file

    this.setState({
      selectFile2: { ...file, portNumber: "" },
      models: _models,
      pairs: _pairs,
      pinList: _pinList,
      copyGroups: _copyGroups
    });
  }

  deleteFile = (pinType) => {
    const { modelType, product } = this.props;
    const { pairs, models, pinList, selectedSignals, selectFile2, selectFile, copyGroups } = this.state;
    let _models = [...models],
      _pinList = [...pinList],
      _pairs = [...pairs],
      _selectFile = { ...selectFile },
      _selectFile2 = selectFile2 ? { ...selectFile2 } : null;

    let currDelFile = pinType === "negative" ? (selectFile2 ? { ...selectFile2 } : null) : { ...selectFile };

    //clear prev file ports
    if (currDelFile && currDelFile.libraryId) {
      const info = clearPairsPortByFile({
        _pinList,
        _pairs,
        selectFile: currDelFile,
        modelType,
        product
      });
      _pinList = info._pinList;
      _pairs = info._pairs;
    }

    if (pinType === "positive") {
      _selectFile = { ..._selectFile2 };
    }

    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file
    const info = updateCopyGroupsBySelectFile({
      _copyGroups,
      _selectedSignals: selectedSignals,
      selectFile: _selectFile,
      selectFile2: null,
      _connectorModels: _models
    });
    _models = info._connectorModels;
    _copyGroups = info._copyGroups;

    const updateInfo = this.updateCopySignalPorts({
      _pinList,
      _pairs,
      _copyGroups
    });
    _pinList = updateInfo._pinList;
    _pairs = updateInfo._pairs;

    this.setState({
      selectFile: {
        ..._selectFile
      },
      selectFile2: null,
      pinList: _pinList,
      pairs: _pairs,
      models: _models,
      copyGroups: _copyGroups
    })
  }

  selectModelFile = (key, pinType) => {
    const { fileList, modelType, product } = this.props;
    const { models, selectedSignals, selectFile, selectFile2, copyGroups, pinList, pairs } = this.state;
    let _models = [...models],
      _pinList = [...pinList],
      _pairs = [...pairs],
      _selectFile2 = selectFile2,
      _selectFile = { ...selectFile };

    let currSelectFile = pinType === "negative" ? (selectFile2 ? { ...selectFile2 } : null) : { ...selectFile };

    const [name, folderId] = key ? key.split("::") : [key];

    let findFile = fileList.find(item => item.name === name) || { name: "", type: "", id: "" };
    if (folderId) {
      const findFolder = fileList.find(item => item.id === folderId) || {};
      findFile = (findFolder.children || []).find(item => item.id === name) || { name: "", type: "", id: "" };
    }

    //getPortNumberFromFileSuffix
    const filePortNumber = getPortNumberFromFileSuffix(findFile.name);
    //clear prev file ports
    if (currSelectFile && currSelectFile.libraryId && findFile.id !== currSelectFile.libraryId) {
      const info = clearPairsPortByFile({
        _pinList,
        _pairs,
        selectFile: currSelectFile,
        modelType,
        product
      });
      _pinList = info._pinList;
      _pairs = info._pairs;
    }
    //add new modelKey
    const modelKeys = _models.map(item => item.modelKey);
    const modelKey = getDefaultIndex(_models.length, modelKeys);
    const fileType = this.differentDisplay ? findFile.fileType || "" : findFile.type;
    const file = {
      fileName: findFile.name,
      libraryId: findFile.id,
      type: !fileType || fileType.match("touchstone") ? TOUCHSTONE : SPICE,
      modelKey,
      subckt: "",
      version: findFile.version
    };

    //update pkg models file
    _models.push({ ...file });

    currSelectFile = {
      ...file,
      portNumber: filePortNumber
    };

    if (pinType === "negative") {
      _selectFile2 = { ...currSelectFile }
    } else {
      _selectFile = { ...currSelectFile }
    }

    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file
    const info = updateCopyGroupsBySelectFile({
      _copyGroups,
      _selectedSignals,
      selectFile: _selectFile,
      selectFile2: _selectFile2,
      _connectorModels: _models
    });

    _models = info._connectorModels;
    _copyGroups = info._copyGroups;

    const updateInfo = this.updateCopySignalPorts({
      _pinList,
      _pairs,
      _copyGroups
    });
    _pinList = updateInfo._pinList;
    _pairs = updateInfo._pairs;

    this.setState({
      selectFile: _selectFile,
      selectFile2: _selectFile2,
      selectedSignals: _selectedSignals,
      pinList: _pinList,
      pairs: _pairs,
      copyGroups: _copyGroups,
      models: _models
    }, () => {
      this.props.getFileParse([{ ...file }]);
    })
  }

  selectSignal = (key) => {
    const { selectedSignals, pinList, pairs, copyGroups, copySignals } = this.state;
    let _copySignals = [...copySignals],
      _pinList = [...pinList],
      _pairs = [...pairs];
    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups];
    _selectedSignals.push(key);
    //update copy signals
    if (_copySignals.length && _copySignals[0] !== "all") {
      _copySignals = _copySignals.filter(item => item !== key);
    }
    const allSignals = this.signals.filter(item => !_selectedSignals.includes(item));
    if (_copySignals[0] && _copySignals[0] === "all" && !allSignals.length) {
      _copySignals = [];
    }
    _copyGroups.forEach(group => {
      group.signalMap.forEach(item => {
        if (item.toSignal === key) {
          item.toSignal = "";
        }
      })
      group.signalMap.push({ fromSignal: key, toSignal: "" });
      group.signalMap = SortFn(group.signalMap, _selectedSignals, "fromSignal");
    })

    const info = clearPkgPinsPortBySignal({
      pinList: _pinList,
      pairs: _pairs,
      signal: key,
      determinePinSame: this.props.determinePinSame
    });

    _pinList = info.pinList;
    _pairs = info.pairs;

    this.setState({
      selectedSignals: _selectedSignals,
      pinList: _pinList,
      pairs: _pairs,
      copyGroups: _copyGroups,
      copySignals: _copySignals
    })
  }

  delSignal = (key) => {
    const { selectedSignals, pairs, pinList, copyGroups, copySignals } = this.state;
    let _pinList = [...pinList],
      _pairs = [...pairs];
    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups], _copySignals = [...copySignals];
    _selectedSignals = _selectedSignals.filter(item => item !== key);
    let deleteCopySignals = [];
    _copyGroups.forEach(group => {
      deleteCopySignals = [...deleteCopySignals, ...group.signalMap.filter(it => it.fromSignal === key).map(it => it.toSignal)]
      group.signalMap = group.signalMap.filter(it => it.fromSignal !== key);
      group.signalMap = SortFn(group.signalMap, _selectedSignals, "fromSignal");
    });

    _copyGroups = _copyGroups.filter(item => item.signalMap.length);

    if (_copySignals.length && _copySignals[0] === "all") {
      _copySignals = this.signals.filter(item => !selectedSignals.includes(item) && !deleteCopySignals.includes(item));
    } else {
      _copySignals = _copySignals.filter(item => !deleteCopySignals.includes(item));
    }


    const info = clearPkgPinsPortBySignal({
      pinList: _pinList,
      pairs: _pairs,
      signal: key,
      determinePinSame: this.props.determinePinSame
    });

    _pinList = info.pinList;
    _pairs = info.pairs;

    this.setState({
      selectedSignals: _selectedSignals,
      pinList: _pinList,
      pairs: _pairs,
      copyGroups: _copyGroups,
      copySignals: _copySignals
    })
  }

  updateError = (error) => {
    clearTimeout(this.timer);
    this.setState({
      error
    });
    this.timer = setTimeout(() => {
      this.setState({
        error: null
      });
    }, 3000)
  }

  onTreeChange = (keys) => {
    const { modelType, product } = this.props;
    const { selectFile, selectFile2, selectedSignals, models, pairs, pinList, terminationSelectFile, termination = {} } = this.state;
    if (!selectedSignals.length) {
      return;
    }
    let _models = [...models],
      _pinList = [...pinList],
      _pairs = [...pairs],
      _terminationPairs = [...(termination.pairs || [])],
      _terminationFiles = [...(termination.files || [])];

    let _copyGroups = [];
    let _copySignals = [];
    if (keys.length && keys[0] === "all") {
      _copySignals = this.signals.filter(item => !selectedSignals.includes(item));
    } else {
      _copySignals = [...keys];
    }

    const groupLength = Math.ceil(_copySignals.length / selectedSignals.length)

    for (let i = 0; i < groupLength; i++) {
      let copyModelKey2;
      const copyModelKey = getDefaultIndex(_models.length, _models.map(item => item.modelKey));
      _models.push({
        fileName: selectFile.fileName,
        libraryId: selectFile.libraryId,
        type: selectFile.type,
        subckt: selectFile.subckt || "",
        modelKey: copyModelKey,
        version: selectFile.version
      });
      if (selectFile2) {
        copyModelKey2 = getDefaultIndex(_models.length, _models.map(item => item.modelKey));
        _models.push({
          fileName: selectFile2.fileName,
          libraryId: selectFile2.libraryId,
          type: selectFile2.type,
          subckt: selectFile.subckt || "",
          modelKey: copyModelKey2,
          version: selectFile2.version
        });
      }

      let copyTerminationModelKey;
      if (terminationSelectFile) {
        copyTerminationModelKey = getDefaultIndex(_terminationFiles.length, _terminationFiles.map(item => item.modelKey));
        _terminationFiles.push({
          fileName: terminationSelectFile.fileName,
          libraryId: terminationSelectFile.libraryId,
          type: terminationSelectFile.type,
          subckt: terminationSelectFile.subckt || "",
          modelKey: copyTerminationModelKey,
          version: terminationSelectFile.version
        });
      }
      _copyGroups = getCopyGroups({
        selectedSignals,
        copyModelKey,
        copyModelKey2,
        _copyGroups,
        copyTerminationModelKey
      });
    }
    const { determinePinSame, determineComp, rank } = this.props;
    for (let i = 0; i < _copyGroups.length; i++) {
      for (let item of _copyGroups[i].signalMap) {
        const toSignal = _copySignals[0] ? _copySignals[0] : "";
        item.toSignal = toSignal;
        _copySignals = _copySignals.filter(it => it !== toSignal);
        const { signal, pin } = getSignalAndPin(toSignal, determinePinSame)
        const { signal: _fromSignal, pin: _fromPin } = getSignalAndPin(item.fromSignal, determinePinSame)

        const info = copyPinsPkgPorts({
          toSignal: signal,
          fromSignal: _fromSignal,
          pinList: _pinList,
          pairs: _pairs,
          copyModelKey: _copyGroups[i].modelKey,
          copyModelKey2: _copyGroups[i].modelKey2,
          determineComp: determineComp,
          models: _models,
          multiPinDetection: determinePinSame,
          toPin: pin,
          fromPin: _fromPin,
          isRank: rank && rank.number ? true : false,
          copyTerminationModelKey: _copyGroups[i].copyTerminationModelKey,
          terminationPairs: _terminationPairs,
          terminationFiles: _terminationFiles,
          modelType,
          product
        });
        _pairs = info.pairs;
        _pinList = info.pinList;
        _terminationPairs = info.terminationPairs;
        _terminationFiles = info.terminationFiles;

        if (info.newModels && info.newModels.length) {
          _models = [..._models, ...info.newModels]
        }
      }
    }

    this.setState({
      pinList: _pinList,
      pairs: _pairs,
      models: _models,
      copyGroups: _copyGroups,
      copySignals: [...keys],
      termination: {
        ...termination,
        pairs: _terminationPairs,
        files: _terminationFiles
      }
    })
  }

  changeCopySignal = (toSignal, fromSignal, copyModelKey, copyModelKey2, copyTerminationModelKey) => {
    const { modelType, product } = this.props;
    const { pinList, pairs, copyGroups, copySignals, selectedSignals, models, termination = {} } = this.state;
    let _pinList = [...pinList],
      _pairs = [...pairs],
      _copyGroups = [...copyGroups],
      _terminationPairs = [...(termination.pairs || [])],
      _terminationFiles = [...(termination.files || [])],
      _models = [...models];

    //delete pre signal
    const copyInfo = updateCopyGroupsBySignal({
      copyModelKey,
      _copyGroups,
      toSignal,
      fromSignal
    });
    const prevSignal = copyInfo.prevSignal;
    _copyGroups = copyInfo._copyGroups;

    //update copySignals
    const allSignals = this.signals.filter(item => !selectedSignals.includes(item));
    let _copySignals = copySignals[0] && copySignals[0] === "all" ? [...allSignals] : [...copySignals];
    _copySignals = _copySignals.filter(item => item !== prevSignal);

    if (!_copySignals.includes(toSignal)) {
      _copySignals.push(toSignal);
      if (JSON.stringify([...allSignals].sort()) === JSON.stringify([..._copySignals].sort())) {
        _copySignals = ["all"]
      }
    }

    const { determinePinSame, determineComp, rank } = this.props;
    const { signal, pin } = getSignalAndPin(toSignal, determinePinSame)
    const { signal: _fromSignal, pin: _fromPin } = getSignalAndPin(fromSignal, determinePinSame)

    const info = copyPinsPkgPorts({
      toSignal: signal,
      fromSignal: _fromSignal,
      pinList: _pinList,
      pairs: _pairs,
      copyModelKey,
      copyModelKey2,
      determineComp: determineComp,
      models: models,
      multiPinDetection: determinePinSame,
      toPin: pin,
      fromPin: _fromPin,
      isRank: rank && rank.number ? true : false,
      copyTerminationModelKey: copyTerminationModelKey,
      terminationPairs: _terminationPairs,
      terminationFiles: _terminationFiles,
      modelType,
      product
    });
    _pairs = info.pairs;
    _pinList = info.pinList;
    _terminationPairs = info.terminationPairs;
    _terminationFiles = info.terminationFiles;

    if (info.newModels && info.newModels.length) {
      _models = [..._models, ...info.newModels]
    }

    this.setState({
      copyGroups: _copyGroups,
      pinList: _pinList,
      pairs: _pairs,
      copySignals: _copySignals,
      models: _models,
      termination: {
        ...termination,
        pairs: _terminationPairs,
        files: _terminationFiles
      }
    })
  }

  addCopyGroups = () => {
    const { copyGroups, selectFile, selectFile2, models, selectedSignals } = this.state;
    let _copyGroups = [...copyGroups];
    let _models = [...models];

    let copyModelKey2;
    const copyModelKey = getDefaultIndex(_models.length, _models.map(item => item.modelKey));
    _models.push({
      fileName: selectFile.fileName,
      libraryId: selectFile.libraryId,
      type: selectFile.type,
      subckt: "",
      modelKey: copyModelKey,
      version: selectFile.version
    });

    if (selectFile2) {
      copyModelKey2 = getDefaultIndex(_models.length, _models.map(item => item.modelKey));
      _models.push({
        fileName: selectFile2.fileName,
        libraryId: selectFile2.libraryId,
        type: selectFile2.type,
        subckt: "",
        modelKey: copyModelKey2,
        version: selectFile2.version
      });
    }
    _copyGroups = getCopyGroups({
      selectedSignals,
      copyModelKey,
      copyModelKey2,
      _copyGroups
    });

    this.setState({
      models: _models,
      copyGroups: _copyGroups
    })
  }

  deleteCopyGroup = (group) => {
    const { pairs, pinList, copyGroups, copySignals, selectedSignals } = this.state;
    let _pinList = [...pinList],
      _pairs = [...pairs],
      _copyGroups = [...copyGroups],
      deletedSignals = [];

    //delete ports by signals
    for (let item of group.signalMap) {
      deletedSignals.push(item.toSignal);
      const info = clearPkgPinsPortBySignal({
        pinList: _pinList,
        pairs: _pairs,
        signal: item.toSignal,
        determinePinSame: this.props.determinePinSame
      });
      _pinList = info.pinList;
      _pairs = info.pairs;
    }
    _copyGroups = _copyGroups.filter(item => item.modelKey !== group.modelKey);
    //update copy signals
    let _copySignals = [...copySignals];
    if (deletedSignals.length) {
      if (_copySignals.length && _copySignals[0] === "all") {
        _copySignals = this.signals.filter(item => !selectedSignals.includes(item) && !deletedSignals.includes(item));
      } else {
        _copySignals = _copySignals.filter(item => !deletedSignals.includes(item));
      }
    }

    this.setState({
      pinList: _pinList,
      pairs: _pairs,
      copyGroups: _copyGroups,
      copySignals: _copySignals
    })
  }

  getApplySignals = (copyGroups) => {
    let applySignals = [];
    for (let item of copyGroups) {
      applySignals.push(...item.signalMap.filter(it => !!it.toSignal).map(it => it.toSignal))
    }
    return applySignals;
  }

  clearAllSignals = () => {
    const { selectedSignals, pairs, pinList, copyGroups, copySignals } = this.state;
    let _pinList = [...pinList],
      _pairs = [...pairs];
    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups], _copySignals = [...copySignals];
    _selectedSignals = [];
    let deleteCopySignals = [];
    _copyGroups.forEach(group => {
      deleteCopySignals = [...deleteCopySignals, ...group.signalMap.filter(it => selectedSignals.includes(it.fromSignal)).map(it => it.toSignal)]
      group.signalMap = group.signalMap.filter(it => !selectedSignals.includes(it.fromSignal));
      group.signalMap = SortFn(group.signalMap, _selectedSignals, "fromSignal");
    });

    _copyGroups = _copyGroups.filter(item => item.signalMap.length);

    if (_copySignals.length && _copySignals[0] === "all") {
      _copySignals = this.signals.filter(item => !selectedSignals.includes(item) && !deleteCopySignals.includes(item));
    } else {
      _copySignals = _copySignals.filter(item => !deleteCopySignals.includes(item));
    }

    selectedSignals.forEach(item => {
      const info = clearPkgPinsPortBySignal({
        pinList: _pinList,
        pairs: _pairs,
        signal: item,
        determinePinSame: this.props.determinePinSame
      });

      _pinList = info.pinList;
      _pairs = info.pairs;
    })


    this.setState({
      selectedSignals: _selectedSignals,
      pinList: _pinList,
      pairs: _pairs,
      copyGroups: _copyGroups,
      copySignals: _copySignals
    })
  }

  updateAutomaticPanelVisible = (info) => {
    this.setState({
      automaticInfo: info
    })
  }

  automaticMatchPort = (fileInfo, type, save) => {
    const { automaticInfo, selectedSignals, pairs, models, pinList, copyGroups, termination } = this.state;

    if (!fileInfo || !fileInfo.libraryId || !save) {
      // If file is not selected or not saved
      this.updateAutomaticPanelVisible(null)
      return
    }
    const { pins, portsObj, isMultiInfo, modelType, signals, product, determineComp, determinePinSame, rank, topology } = this.props;
    let _models = [...models],
      _pinList = [...pinList],
      _pairs = [...pairs];

    let portModelType = isMultiInfo ? 'moreModel' : modelType;
    //getPortNumberFromFileSuffix
    const filePortNumber = getPortNumberFromFileSuffix(fileInfo.fileName);

    const modelKeys = _models.map(item => item.modelKey);
    const modelKey = getDefaultIndex(_models.length, modelKeys);
    let file = {
      ...fileInfo,
      modelKey,
    };

    const currSelectFile = {
      ...file,
      portNumber: filePortNumber
    };

    //update pkg models file
    _models.push({ ...file });

    let direction = (product === ROCKY && modelType === 'Package')
      || (product === ANDES_V2 && (modelType === 'Package' || modelType === 'DIE') && !isMultiInfo)
      ? 'opposite' : '';
    const _currentModelInfo = updateSelectSignalDefaultValue({
      portsObj,
      selectFileId: fileInfo.libraryId,
      currentModelInfo: automaticInfo,
      pins,
      type,
      selectFileInfo: file,
      signals,
      portModelType,
      lastPairs: pairs,
      selectedSignals,
      determineComp,
      direction,
      determinePinSame,
      rank,
      isParallel: topology === 'parallel'
    })
    _pairs = _currentModelInfo.pairs;

    if (automaticInfo.modelFileKey === automaticInfo.modelType) {
      _pinList = getModelTypePins(_pairs, pins, modelType, signals, product, rank, termination)
    } else {
      _pinList = _currentModelInfo.modelPinList;
    }

    if (copyGroups && copyGroups.length) {
      const info = copyPinPkgPortsBySignal({
        _copyGroups: copyGroups,
        pinList: _pinList,
        pairs: _pairs,
        determineComp: this.props.determineComp,
        determinePinSame: this.props.determinePinSame,
        isRank: rank && rank.number ? true : false,
        terminationPairs: termination.pairs,
        terminationFiles: termination.files,
        modelType,
        product
      });
      _pinList = info.pinList;
      _pairs = info.pairs;
    }

    this.setState({
      selectFile: { ...currSelectFile },
      selectFile2: null,
      pinList: _pinList,
      pairs: _pairs,
      models: _models
    })

    this.updateAutomaticPanelVisible(null)
  }

  matchIcon = () => {
    const { isMatchPort, modelFileKey, modelType } = this.props;
    if (!isMatchPort) { return null }
    return <div>
      < span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelFileKey, modelType })}></span >
    </div >
  }

  selectTerminationModelFile = (key, changType) => {
    const { terminationList, modelType, product } = this.props;
    const { terminationSelectFile, selectedSignals, copyGroups, pinList, termination = {} } = this.state;
    const { files, pairs } = termination;
    let _pinList = [...pinList],
      _files = [...files],
      _pairs = [...pairs],
      _selectFile = { ...terminationSelectFile },
      isClearPairs = false;
    if (changType === 'subckt') {
      if (_selectFile && _selectFile.subckt && _selectFile.subckt !== key) {
        isClearPairs = true;
      }
      _selectFile.subckt = key;
      const modelIndex = _files.findIndex(item => item.modelKey === _selectFile.modelKey && item.libraryId === _selectFile.libraryId)
      if (modelIndex > -1) {
        _files[modelIndex].subckt = key;
      }
    } else {
      const [name] = key ? key.split("::") : [key];
      let findFile = terminationList.find(item => item.name === name) || { name: "", type: "", id: "" };
      const filePortNumber = getPortNumberFromFileSuffix(findFile.name);
      //add new modelKey
      const modelKeys = files.map(item => item.modelKey);
      const modelKey = getDefaultIndex(_files.length, modelKeys);
      const file = {
        fileName: findFile.name,
        libraryId: findFile.id,
        type: !findFile.type || findFile.type.match("touchstone") ? TOUCHSTONE : SPICE,
        modelKey,
        subckt: "",
        version: findFile.version
      };

      //update pkg models file
      _files.push({ ...file });
      _selectFile = {
        ...file,
        portNumber: filePortNumber
      };
      if (terminationSelectFile && terminationSelectFile.libraryId && _selectFile.id !== terminationSelectFile.libraryId) {
        isClearPairs = true;
      }
    }

    // clear prev file ports
    if (isClearPairs) {
      const info = clearPairsPortByFile({
        _pinList,
        _pairs,
        selectFile: terminationSelectFile,
        modelType,
        product
      });
      _pinList = info._pinList;
      _pairs = info._pairs;
    }

    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file
    const info = updateCopyGroupsBySelectFile({
      _copyGroups,
      _selectedSignals,
      selectFile: _selectFile,
      selectFile2: {},
      _connectorModels: _files
    });

    _files = info._connectorModels;
    _copyGroups = info._copyGroups;

    const updateInfo = this.updateCopySignalPorts({
      _pinList,
      _pairs,
      _copyGroups
    });
    _pinList = updateInfo._pinList;
    _pairs = updateInfo._pairs;

    if (changType === 'subckt') {
      const ports = this.props.updatePortsObj({ currSelectFile: { ..._selectFile } });
      if (ports && ports.length === 1) {
        const autoFillInfo = getAutoFillNode({ pinList: _pinList, pairs: _pairs, files: _files, selectFile: _selectFile, ports });
        _files = autoFillInfo._files;
        _pairs = autoFillInfo._pairs;
        _pinList = autoFillInfo._pinList;
      }
    }

    this.setState({
      terminationSelectFile: _selectFile,
      selectedSignals: _selectedSignals,
      pinList: _pinList,
      termination: {
        ...termination,
        files: _files,
        pairs: _pairs
      },
      copyGroups: _copyGroups,
    }, () => {
      if (changType !== 'subckt') {
        this.props.getFileParse([{ ..._selectFile }], false, "", true).then(subcktList => {
          if (Array.isArray(subcktList)) {
            const subcktInfo = subcktList.find(item => item.libraryId === _selectFile.libraryId);
            if (subcktInfo && subcktInfo.models.length === 1) {
              const { name } = subcktInfo.models[0];
              this.selectTerminationModelFile(name, 'subckt')
            }
          }
        });
      }
    })
  }

  terminationSelectGroup = () => {
    const { topology, terminationSelectFile, termination = {} } = this.state;
    const { terminationList = [] } = this.props;
    return <div>
      <div className="pkg-model-select-item">
        <span>Die Model Topology</span>
        <Select
          size='small'
          className="pkg-model-select"
          popupClassName="pkg-select-dropdown-menu"
          options={[{ label: "Series", value: "series" }, { label: "Parallel", value: "parallel" }]}
          value={topology}
          onChange={(value) => this.setState({ topology: value })}
          disabled={true}
        />
      </div>
      {
        topology !== 'parallel' ? <>
          <div className="pkg-model-select-item">
            <label className='model-title'>Termination</label>
          </div>
          <div>
            <div className="pkg-model-select-item">
              <span>Model Type</span>
              <Select
                size='small'
                className="pkg-model-select"
                popupClassName="pkg-select-dropdown-menu"
                options={[{ label: "Value", value: 'value' }, { label: "SPICE", value: 'spice' }]}
                value={termination.type}
                onChange={(value) => this.setState({ termination: { ...termination, type: value } })}
                disabled={true}
              />
            </div>
            {termination.type === 'spice' && this.getModelFileSelect({
              value: (terminationSelectFile || {}).fileName,
              title: "Model File",
              list: terminationList,
              selectChange: this.selectTerminationModelFile,
              type: "file",
              selectFile: terminationSelectFile,
              fileType: "termination"
            })}
          </div>
        </> : null
      }
    </div>
  }

  render() {
    const { selectFile, selectedSignals = [],
      error, pinList, pairs, selectFile2,
      copyGroups = [], copySignals = [],
      automaticInfo, topology, models,
      terminationSelectFile, termination = {}
    } = this.state;
    const { fileList, portsObj, modelType, maxWidth, maxHeight, isMultiInfo, isMatchPort,
      modelFileKey, libraryListObj, determineComp, determinePinSame, rank,
      product, serdesType, componentName, designType } = this.props;

    const applySignals = this.getApplySignals(copyGroups);
    const { leftName, rightName } = getModelTitle(modelType, componentName, serdesType, designType);
    const className = rank ? "pkg-model-select-rank-main" : "";
    const content = (
      <Fragment>
        <Panel
          className='pkg-model-select-panel'
          // title={`${modelType} model`}
          title={`${modelFileKey} model`}
          onCancel={this.closeModal}
          zIndex={2000}
          position='panel-center-left'
          draggable
          width={760}
          minHeight={220}
          minWidth={400}
          maxWidth={maxWidth + 100}
          maxHeight={maxHeight}
          overflow={true}
        >
          <div className={`pkg-model-select-main ${className}`}>
            {/* DIE */}
            {product === ANDES_V2 && modelType === "DIE" ? <div className="pkg-model-select-item">
              <label className='model-title'>DIE</label>
            </div> : null}
            {selectFile2 ? <Fragment>
              {this.getModelFileSelect({
                value: selectFile.fileName,
                title: "Model File ( + )",
                list: fileList,
                selectChange: this.selectModelFile,
                type: "file",
                pinType: "positive",
                className: "multi-pkg-model-file-content",
                selectFile
              })}
              {this.getModelFileSelect({
                value: selectFile2.fileName,
                title: "Model File ( - )",
                list: fileList,
                selectChange: this.selectModelFile,
                type: "file",
                pinType: "negative",
                className: "multi-pkg-model-file-content",
                selectFile: selectFile2
              })}
            </Fragment> :
              this.getModelFileSelect({
                value: selectFile.fileName,
                title: "Model File",
                list: fileList,
                selectChange: this.selectModelFile,
                type: "file",
                selectFile
              })}
            {/* Termination / Model Type / Model File */}
            {product === ANDES_V2 && modelType === "DIE" ? <>{this.terminationSelectGroup()}</> : null}
            <div className="pkg-model-select-content">
              {this.getModelFileSelect({
                value: selectedSignals,
                title: "Signals",
                list: this.signals,
                /*       disabled: !selectFile.fileName, */
                onSelect: this.selectSignal,
                onDeselect: this.delSignal,
                clearAll: this.clearAllSignals,
                type: "signal",
                selectType: "multiple",
                className: selectFile2 ? "multi-pkg-model-file-content" : ""
              })}
              {error ? <span className="aurora-error-msg-span">{error}</span> : null}
              {selectedSignals.length ? <div className="pkg-model-select-pins-content">
                {/* {modelType === "PCB" && !isMultiInfo ? */}
                {["PCB", "Connector", "PreLayout", "Package", "socket", "N-Port", "DIE"].includes(modelType) && !isMultiInfo ?
                  <div className="pkg-model-port-pins pkg-model-port-pins-title">
                    <div className="pkg-model-port-pins-div">{leftName}</div>
                    {modelType === "PreLayout" && <div className="pkg-model-port-pins-div">
                      <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelFileKey, modelType })}></span>
                    </div>}
                    <div className="pkg-model-port-pins-div">
                      {rightName ? <span className='pkg-model-port-pins-div-model-type'>{rightName}</span> : ""}
                      {isMatchPort && modelType !== "PreLayout" ? <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelFileKey, modelType })}></span> : null}
                    </div>
                    {modelType !== "PreLayout" && <div className={`pkg-model-port-pins-net ${product === ANDES_V2 && modelType === "DIE" && topology === 'parallel' ? "pkg-die-model-port-pins-net" : ""}`}></div>}
                  </div> : isMultiInfo ?
                    <div className='pkg-multi-model-port-pins-content'>
                      <div className="pkg-multi-model-port-pins-div">
                        <span className='pkg-multi-model-port-pins-div-model-type'>{modelType}</span>
                        {isMatchPort ? <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelFileKey, modelType })}></span> : null}
                      </div>
                    </div>
                    : null}
                {/* {this.matchIcon()} */}
                {selectedSignals.map((item, index) => {
                  const { signal, pin } = getSignalAndPin(item, determinePinSame)
                  const findPins = pinList.filter((it) => it.signal === signal && ((!it.pinRight || !it.pinRight || !pin) || (it.pinRight && it.pinRight.length && it.pinRight[0].pin === pin)));
                  return <SignalPinsPortSetup
                    key={index}
                    signalKey={index}
                    signal={item}
                    signalInfo={findPins}
                    selectFile={selectFile}
                    selectFile2={selectFile2}
                    pinList={pinList}
                    portsObj={portsObj}
                    pairs={pairs}
                    modelType={modelType}
                    _updatePorts={this._updatePorts}
                    isMultiInfo={isMultiInfo}
                    determineComp={determineComp}
                    differentDisplay={this.differentDisplay}
                    rank={rank}
                    product={product}
                    serdesType={serdesType}
                    topology={topology}
                    terminationType={termination.type}
                    terminationSelectFile={terminationSelectFile}
                    termination={termination}
                    models={models}
                  />
                })}
              </div> : null}
            </div>
            {selectedSignals.length ? <div className="pkg-model-select-content">
              {this.getModelFileSelect({
                value: copySignals,
                title: "Apply model to",
                type: "copy_signal",
                selectType: "multiple",
                selectedSignals,
                className: selectFile2 ? "multi-pkg-model-file-content" : ""
              })}
              {copyGroups.length ? <div className="pkg-model-select-pins-content">
                {copyGroups.map((group, i) => {
                  return (
                    <div key={i} className="pkg-model-select-copy-group">
                      {group.signalMap.map((item, index) => {
                        const { signal, pin } = getSignalAndPin(item.fromSignal, determinePinSame);
                        const fromSignalList = pinList.filter((it, index) => signal === it.signal && (!pin || !it.pinRight || !it.pinRight.length || (pin && it.pinRight[0].pin === pin)));
                        const { signal: toSignal, pin: toPin } = getSignalAndPin(item.toSignal, determinePinSame);
                        const toSignalList = pinList.filter((it, index) => toSignal === it.signal && (!toPin || !it.pinRight || !it.pinRight.length || (toPin && it.pinRight[0].pin === toPin)));

                        return <CopySignalPinsPortSetup
                          key={index}
                          fromSignal={item.fromSignal}
                          toSignal={item.toSignal}
                          fromSignalList={fromSignalList}
                          toSignals={this.signals.filter(item => !selectedSignals.includes(item))}
                          toSignalList={toSignalList}
                          copyModelKey={group.modelKey}
                          copyModelKey2={group.modelKey2}
                          applySignals={applySignals}
                          modelType={modelType}
                          changeCopySignal={this.changeCopySignal}
                          isMultiInfo={isMultiInfo}
                          differentDisplay={this.differentDisplay}
                          rank={rank}
                          product={product}
                          topology={topology}
                          terminationType={termination.type}
                          serdesType={serdesType}
                          copyTerminationModelKey={group.terminationModelKey}
                        />
                      })}
                      <CloseOutlined
                        title="Delete the group."
                        onClick={() => this.deleteCopyGroup(group)}
                        className="pkg-model-select-delete-group-icon" />
                    </div>
                  );
                })}
              </div> : null}
            </div> : null}
          </div>
        </Panel>
        {automaticInfo && Object.keys(automaticInfo).length && automaticInfo.modelType ? <AutomaticPanel
          automaticInfo={automaticInfo}
          closePanel={this.automaticMatchPort}
          libraryListObj={libraryListObj}
          getFileParse={this.props.getFileParse}
          // newModelFilesObj={newModelFilesObj}
          modelType={modelType}
          files={[selectFile]}
        /> : null}
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }

  selectSubcktValue = (key, pinType, fileType) => {
    if (fileType === "termination") {
      this.selectTerminationModelFile(key, "subckt");
      return;
    }
    const { modelType, product } = this.props;
    const { models, selectedSignals, selectFile, selectFile2, copyGroups, pinList, pairs } = this.state;

    let _models = [...models],
      _pinList = [...pinList],
      _pairs = [...pairs],
      _selectFile2 = selectFile2,
      _selectFile = { ...selectFile };

    let currSelectFile = pinType === "negative" ? (selectFile2 ? { ...selectFile2 } : null) : { ...selectFile };

    if (currSelectFile && currSelectFile.subckt && currSelectFile.subckt !== key) {
      const info = clearPairsPortByFile({
        _pinList,
        _pairs,
        selectFile: currSelectFile,
        modelType,
        product
      });
      _pinList = info._pinList;
      _pairs = info._pairs;
    }

    currSelectFile.subckt = key;

    const modelIndex = _models.findIndex(item => item.modelKey === currSelectFile.modelKey && item.libraryId === currSelectFile.libraryId)
    if (modelIndex > -1) {
      _models[modelIndex].subckt = key;
    }

    if (pinType === "negative") {
      _selectFile2 = { ...currSelectFile }
    } else {
      _selectFile = { ...currSelectFile }
    }

    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file
    const info = updateCopyGroupsBySelectFile({
      _copyGroups,
      _selectedSignals,
      selectFile: _selectFile,
      selectFile2: _selectFile2,
      _connectorModels: _models
    });

    _models = info._connectorModels;
    _copyGroups = info._copyGroups;

    const updateInfo = this.updateCopySignalPorts({
      _pinList,
      _pairs,
      _copyGroups
    });
    _pinList = updateInfo._pinList;
    _pairs = updateInfo._pairs;

    this.setState({
      selectFile: _selectFile,
      selectFile2: _selectFile2,
      selectedSignals: _selectedSignals,
      pinList: _pinList,
      pairs: _pairs,
      copyGroups: _copyGroups,
      models: _models
    }, () => {
      this.props.updatePortsObj({ currSelectFile })
    })
  }

  getSubcktSelect = ({ selectFile, pinType, fileType }) => {
    if (!selectFile || !selectFile.type || selectFile.type.toUpperCase() !== SPICE) { return }
    const { subcktList } = this.props;
    const currentSubckt = subcktList ? subcktList.find(it => (it.libraryId === selectFile.libraryId && it.fileName === selectFile.fileName)) : null;
    let subckts = currentSubckt ? currentSubckt.models : [];
    return <Select
      placeholder={'Subckt'}
      value={selectFile.subckt || ""}
      onChange={(key) => this.selectSubcktValue(key, pinType, fileType)}
      className={`aurora-select pkg-model-select`}
      popupClassName="pkg-select-dropdown-menu"
      popupMatchSelectWidth={false}
      allowClear={true}
      getPopupContainer={() => document.getElementById('root')}
      size='small'
    >
      {subckts.map((item) => (
        <Option
          key={item.name}
          value={item.name}
          title={item.name}
        >{item.name}</Option>
      ))}
    </Select>
  }

  getModelFileSelect = ({
    value,
    title,
    selectChange,
    type,
    list,
    selectType,
    onDeselect,
    onSelect,
    disabled,
    pinType,
    className,
    selectedSignals = [],
    clearAll = null,
    selectFile,
    fileType
  }) => {
    const { serdesType } = this.props;
    let boxClassNames = className ? [className] : [], subcktDisplay = false;
    if (selectFile && selectFile.type && selectFile.type.toUpperCase() === SPICE) {
      subcktDisplay = true;
      boxClassNames.push('pkg-model-select-subckt-item');
    }
    if (pinType) boxClassNames.push('pkg-multi-model-select');
    const treeData = type === "copy_signal" ? getTreeData(this.signals.filter(item => !selectedSignals.includes(item))) : [];
    return (
      <div className={`pkg-model-select-item ${boxClassNames.join(" ")}`}>
        <span>
          {title}
          {type === "copy_signal" ? <PlusCircleOutlined
            title="Add new Group."
            className="pkg-model-select-copy-icon"
            onClick={this.addCopyGroups} /> : null}
          {type === "file" && !pinType && !this.differentDisplay && serdesType !== CPHY ? <PlusCircleOutlined
            title="Add a new file to make positive and negative pins use different models."
            className="pkg-model-select-copy-icon"
            onClick={this.addSelectFile2} /> : null}
        </span>
        {type === "copy_signal" ? <TreeSelect
          treeData={treeData}
          value={value}
          onChange={this.onTreeChange}
          treeCheckable={true}
          showCheckedStrategy={SHOW_PARENT}
          listHeight={300}
          placeholder='Select signals'
          getPopupContainer={() => document.getElementById('root')}
          className='aurora-select pkg-model-select'
          virtual={false}
          size='small'
        /> :
          <Fragment>
            <Select
              value={value}
              placeholder={title}
              showSearch
              disabled={disabled}
              mode={selectType}
              onChange={selectChange ? (key) => selectChange(key, pinType) : null}
              onDeselect={onDeselect ? onDeselect : null}
              onSelect={onSelect ? onSelect : null}
              popupMatchSelectWidth={false}
              className="pkg-model-select"
              popupClassName="pkg-select-dropdown-menu"
              getPopupContainer={() => document.getElementById('root')}
              allowClear={{ clearIcon: type === "signal" ? <CloseCircleFilled onClick={(e) => clearAll ? clearAll(e) : null} /> : null }}
              size='small'
            >
              {list.map((item) => {
                if (item.type === "folder" && item.children && item.children.length) {
                  return <OptGroup key={item.name} label={item.name}>
                    {item.children.map(it => (<Option
                      key={`${it.id}::${item.id}`}
                      value={`${it.id}::${item.id}`}
                      title={it.name}
                      className={selectFile && selectFile.libraryId === it.id && value === it.name ? "ant-select-dropdown-menu-item-selected" : ""}
                    >{it.name}</Option>))}</OptGroup>
                }

                return type.match("signal") ?
                  <Option
                    key={item}
                    value={item}
                    title={item}
                  >{item}</Option>
                  : <Option
                    key={item.id}
                    value={item.name}
                    title={item.name}
                  >{item.name}</Option>
              })}
            </Select>
            {subcktDisplay && this.getSubcktSelect({ selectFile, pinType, clearAll, fileType })}
          </Fragment>
        }
        {pinType ? <CloseOutlined
          title="Delete the file."
          className="pkg-model-select-delete-icon"
          onClick={() => this.deleteFile(pinType)} /> : null}
      </div>
    );
  }
}

export default ModelSelect;