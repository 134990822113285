import {
  NEW_INTERFACE,
  SAVE_CONFIG,
  CREATE_INTERFACE,
  UPDATE_VIEW,
  UPDATE_CONFIG,
  ADD_SIGNAL,
  ADD_REFERENCE_NETS,
  GET_INTERFACES_LIST,
  SAVE_INTERFACE_FAILED,
  GET_INTERFACES_LIST_SUCCESS,
  SAVE_CONFIG_SUCCESS,
  GET_INTERFACE,
  GET_INTERFACE_SUCCESS,
  CHANGE_LANES,
  UPDATE_LANES,
  DELETE_INTERFACE
} from './actionTypes';

export const newInterface = () => ({
  type: NEW_INTERFACE
})

export const saveConfg = () => ({
  type: SAVE_CONFIG
})

export const createInterface = (config) => ({
  type: CREATE_INTERFACE,
  config
})

export const updateView = (view) => ({
  type: UPDATE_VIEW,
  view
})

export const updateConfig = (obj) => ({
  type: UPDATE_CONFIG,
  obj
})

export const addSignal = (signalName) => ({
  type: ADD_SIGNAL,
  signalName
})

export const addRefNets = (selectedNets = []) => ({
  type: ADD_REFERENCE_NETS,
  selectedNets
})

export const getInterfaces = () => ({
  type: GET_INTERFACES_LIST
})

export const saveInterfaceSuccess = () => ({
  type: SAVE_CONFIG_SUCCESS
})

export const saveInterfaceFailed = (msg) => ({
  type: SAVE_INTERFACE_FAILED,
  msg
})

export const getListSuccess = (interfaces) => ({
  type: GET_INTERFACES_LIST_SUCCESS,
  interfaces
})

export const getInterface = ({ name, lane_numbers }) => ({
  type: GET_INTERFACE,
  name,
  lane_numbers
})

export const getInterfaceSuccess = (data) => ({
  type: GET_INTERFACE_SUCCESS,
  data
})

export const changeLanes = (lanes) => ({
  type: CHANGE_LANES,
  lanes
})

export const updateLanes = (lanes) => ({
  type: UPDATE_LANES,
  lanes
})

export const deleteInterface = (name) => ({
  type: DELETE_INTERFACE,
  name
})