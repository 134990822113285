import Parameter from './parameters';

class DDRVixParamSpec extends Parameter {
  constructor(props) {
    super(props);
    this.description = null; // Description of the parameter
    this.pass = null; // Pass value
    this.type = null; // Type of the parameter
    this.vixPass = null; // Vix pass value
    this.voltageAmplitude = null; // Voltage amplitude
  };

  initSpec(param) {
    this.description = param.description || null;
    this.pass = param.pass || null;
    this.type = param.type || null;
    this.vixPass = param.vixPass || null;
    this.voltageAmplitude = param.voltageAmplitude || null;
    this.paramInit(param);
  }
};

export default DDRVixParamSpec;