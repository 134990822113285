import {
  MY_LIBRARY,
  LIBRARY,
  LIBRARYS,
  PROJECTS,
  TRASH,
} from '@/constants/treeConstants';

function getDefaultTreeItems() {
  return [{
    name: 'Library',
    key: 'library',
    dataType: MY_LIBRARY,
    nodeClass: 'expand-icon-large',
    children: [{
      name: 'SPIM',
      key: 'SPIM',
      type: 'folder',
      dataType: LIBRARY,
      nodeClass: 'expand-icon-middle',
      children: []
    },
    {
      name: 'VRM',
      key: 'VRM',
      dataType: LIBRARY,
      nodeClass: 'expand-icon-middle',
      children: []
    },
    {
      name: 'Decap',
      key: 'decap',
      dataType: LIBRARYS,
      nodeClass: 'expand-icon-middle',
      children: [{
        name: 'Touchstone',
        key: 'Touchstone',
        dataType: LIBRARY,
        children: []
      },
      {
        name: 'SPICE',
        key: 'SPICE',
        dataType: LIBRARY,
        children: []
      },
      {
        name: 'RLC',
        key: 'RLC',
        dataType: LIBRARY,
        children: []
      }]
    }]
  }, {
    name: 'Project',
    key: 'projects',
    nodeClass: 'expand-icon-large',
    dataType: PROJECTS,
    children: []
  }, {
    name: 'Trash',
    key: 'trash',
    nodeClass: 'expand-icon-large',
    dataType: TRASH
  }];
};

export const defaultTreeItems = getDefaultTreeItems();