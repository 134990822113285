import React, { Component, createRef } from 'react';
import { Table, Tooltip } from 'antd';
import { drawIR } from '@/services/Cascade/result/index.js';

const resultChartColumns = [{
  title: 'Key',
  dataIndex: 'key',
  key: 'key',
  width: "30%",
}, {
  title: 'Value',
  dataIndex: 'value',
  key: 'value',
  width: '500px'
}]

class ChartItem extends Component {
  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.state = {
      resultChartColumns: resultChartColumns,
      chartTableData: [],
    }

    this.state.resultChartColumns[1].render = (value, record, index) => {
      return record.key === 'Max Pins' ? <Tooltip
        title={value}
        overlayClassName='aurora-tooltip chart-content-tooltip'
        trigger='click'
      ><div className="overflew-box">{value}</div>
      </Tooltip> : <div className="overflew-box">{value}</div>
    }
  }

  componentDidMount() {
    this.screenChange();
    this._isMounted = true;
    const { verificationId, tableData, chartXType, chartYType, resultKey, title, yText, xText } = this.props;
    if (verificationId && tableData && tableData.length > 0) {
      drawIR(this.svgRef.current, tableData, chartXType, chartYType, resultKey, title, yText, xText);
      this.getTableData()
    }
  }

  componentDidUpdate(prevProps) {
    const { verificationId, tableData, chartXType, chartYType, resultKey, title, yText, xText, selectedKey } = this.props;
    if (this.props.verificationId !== prevProps.verificationId || tableData.length !== prevProps.tableData.length || prevProps.selectedKey !== selectedKey || prevProps.yText !== yText) {
      if (verificationId && tableData && tableData.length > 0) {
        setTimeout(() => {
          drawIR(this.svgRef.current, this.props.tableData, chartXType, chartYType, resultKey, title, yText, xText);
          this.getTableData()
        }, 100)
      }
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    setTimeout(() => {
      const { tableData, chartXType, chartYType, resultKey, title, yText, xText } = this.props;
      if (this.svgRef && this.svgRef.current && tableData && tableData.length > 0) {
        drawIR(this.svgRef.current, tableData, chartXType, chartYType, resultKey, title, yText, xText)
      }
    }, 50)
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.cancelable) {
      this.cancelable.cancel();
    };
  }

  getTableData = () => {
    const { tableData, resultKey, chartXType, chartYType, xText } = this.props;
    const _data = tableData.map(item => (parseFloat(item[chartXType])))
    let sum = function (x, y) {
      return x + y;
    };
    let square = function (x) { return x * x; };
    let mean = (_data.reduce(sum) / _data.length).toFixed(2)
    let deviations = _data.map(function (x) { return x - mean; });
    let stdDev = 0;
    if (_data.length > 1) {
      stdDev = (Math.sqrt(deviations.map(square).reduce(sum) / (_data.length - 1)));
    }
    let max = Math.max(..._data).toFixed(2)
    const filterMax = tableData.filter(item => item[chartXType] == max)
    let min = Math.min(..._data).toFixed(2)
    const filterMin = tableData.filter(item => item[chartXType] == min)
    let minValue = [], maxValue = [];
    if (resultKey === 'pin' || resultKey === 'bump') {
      minValue = filterMin.map(item => `${item[chartYType]} (${item.X},${item.Y})`)
      maxValue = filterMax.map(item => `${item[chartYType]} (${item.X},${item.Y})`)
    } else if (resultKey === 'via') {
      minValue = filterMin.map(item => `${item[chartYType]}`)
      maxValue = filterMax.map(item => `${item[chartYType]}`)
    }
    const chartTableData = [
      { key: 'Mean (mV)', value: mean },
      { key: 'Std Dev', value: stdDev.toFixed(2) },
      { key: 'Min (mV)', value: min },
      { key: 'Max (mV)', value: max },
    ];

    this.setState({
      chartTableData,
    })
  }

  render() {
    const { resultChartColumns, chartTableData } = this.state;
    const { svgHeight, title } = this.props
    return (
      <div className="dc-result-chart-item">
        <div className='dc-result-chart-item-flex clear'>
          <div className='dc-result-chart-title'>{title}</div>
          <div className='dc-result-chart-plot'>
            <div className="dc-result-chart-box-border">
              <svg ref={this.svgRef} style={{ height: svgHeight, width: '100%', marginTop: 30, overflow: 'visible' }}></svg>
            </div>
          </div>
          <div className='dc-result-chart-table-title'>{title} Statistics</div>
          <Table
            dataSource={chartTableData}
            columns={resultChartColumns}
            rowKey="key"
            size="small"
            pagination={false}
            className='dc-result-chart-content-table'
            showHeader={false}
          />
        </div>
      </div>
    )
  }
}

export default ChartItem