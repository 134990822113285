import React, { Component } from 'react';
import { getPCBSchematicList } from '@/services/Andes_v2/endToEndChannel';
class PCBChannelSchematic extends Component {

  render() {
    const { pcbConnections = [], CONNECTION_ID, connections = [] } = this.props;
    const pcbItemWidth = 100 * pcbConnections.length;
    const ele = document.getElementById("pcb-connections-schematic-main-id");
    let signalWidth = 80;
    if (ele) {
      const width = ele.offsetWidth;
      signalWidth = (width - pcbItemWidth) / pcbConnections.length
    }
    const pcbSchematicList = getPCBSchematicList(pcbConnections, connections);
    return <div
      className='pcb-connections-schematic-main'
      id="pcb-connections-schematic-main-id"
      style={{ padding: `0px ${signalWidth / 2}px` }}
    >
      {pcbSchematicList.map((item, index) => {
        const highLight = item.prevConnectionId === CONNECTION_ID || item.nextConnectionId === CONNECTION_ID;

        return <div
          key={index}
          className={highLight ? 'pcb-connections-schematic-item pcb-connection-schematic-title-high-light' : 'pcb-connections-schematic-item'}
        >
          <div className='pcb-connections-schematic-pcb'>
            <span className={`iconfont icon-tool-xinpian-`}></span>
            PCB {index + 1}
          </div>
          {index !== pcbConnections.length - 1 ? <div
            className={item.nextConnectionId === CONNECTION_ID ? 'pcb-connections-schematic-signal-high-light' : 'pcb-connections-schematic-signal'}
          >
            {/* first signal */}
            <div
              className='connection-pcb-signal1-line'
              style={{ width: signalWidth }}
              title={item.signals[0] || ""}
            >{item.signals[0] || ""}</div>

            {/* Ellipsis (signal length > 2 ) */}
            <div className='connection-pcb-signals-line'
            >{item.signals.length > 2 ? "..." : ""}</div>

            {/* last signal (signal length > 1 ) */}
            {item.signals.length > 1 ? <div
              className='connection-pcb-signal2-line'
              style={{ width: signalWidth }}
              title={item.signals[item.signals.length - 1]}
            >{item.signals[item.signals.length - 1]}</div> : null}
          </div> : null}
        </div>
      })
      }
    </div >
  }
}


export default PCBChannelSchematic;