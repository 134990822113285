export const RX = 'rx';
export const TX = 'tx';
export const BOOLEAN = 'Boolean';
export const INTEGER = 'Integer';
export const STRING = 'String';
export const LIST = 'List';
export const RANGE = 'Range';
export const CORNER = 'Corner';
export const TAP = 'Tap';
export const FLOAT = 'Float';
export const GAUSSIAN = "Gaussian";
export const PARAMETER_DUAL_DIRAC = "Dual-Dirac";
export const PARAMETER_DJRJ = "DjRj";