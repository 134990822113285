import { Tooltip } from "antd";
import React from "react";
import { scaleConversion } from "../../../../services/helper/numberHelper";

const RECT = 'rect', LINE = "line", TEXT = "text", POLYGON = "polygon", PATH = "path"
const defaultTextInfo = [
  {
    top: 105,
    left: 480,
    text: <>|V<sub>IDTH,MAX</sub>|</>,
    toolTip: "Differential input high threshold"
  },
  {
    top: 140,
    left: 470,
    text: <>|V<sub>IDTH,MIN</sub>|</>,
    toolTip: "Differential input low threshold"
  },
  {
    top: 310,
    left: 250,
    text: <>t<sub>UI_AVERAGE</sub></>,
    toolTip: "UI average"
  }
]

const textInfo = [
  {
    top: 255,
    left: 293,
    text: <>t<sub>EYE_RAMP</sub></>,
    toolTip: "Eye ramp time"
  },
  {
    top: 255,
    left: 379,
    text: <>t<sub>EYE_RAMP</sub></>,
    toolTip: "Eye ramp time"
  },
  {
    top: 283,
    left: 335,
    text: <>t<sub>EYE_WIDTH</sub></>,
    toolTip: "Eye width"
  }
]

const textInfo1 = [
  {
    top: 255,
    left: 230,
    text: <>t<sub>EYE_RAMP</sub></>,
    toolTip: "Eye ramp time"
  },
  {
    top: 255,
    left: 374,
    text: <>t<sub>EYE_RAMP</sub></>,
    toolTip: "Eye ramp time"
  },
  {
    top: 255,
    left: 314,
    text: <>t<sub>Mid</sub></>,
    toolTip: "Width of the horizontal mid-section of the hexagonal Eye Interior"
  },
  {
    top: 283,
    left: 300,
    text: <>t<sub>EYE_WIDTH</sub></>,
    toolTip: "Eye width"
  }
]

const defaultSvgInfo = [
  {
    type: RECT,
    params:
    {
      x: "100",
      y: "50",
      width: "500",
      height: "300",
      fill: "#fff",
      stroke: "#000",
      strokeWidth: "1"
    }
  },
  {
    type: LINE,
    params: {
      x1: "100",
      x2: "100",
      y1: "0",
      y2: "500",
      stroke: "#000",
      strokeWidth: "1"
    }
  },
  {
    type: LINE,
    params: {
      x1: "600",
      x2: "600",
      y1: "0",
      y2: "500",
      stroke: "#000",
      strokeWidth: "1"
    }
  },
  {
    type: LINE,
    params: {
      x1: "50",
      x2: "350",
      y1: "200",
      y2: "200",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "600",
      x2: "800",
      y1: "50",
      y2: "50",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "600",
      x2: "800",
      y1: "200",
      y2: "200",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "600",
      x2: "800",
      y1: "350",
      y2: "350",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: TEXT,
    params: {
      x: "30",
      y: "206",
      fontSize: "16",
      fill: "#000",
      textAnchor: "middle",
      text: "0 V"
    }
  },
  {
    type: TEXT,
    params: {
      x: "820",
      y: "206",
      fontSize: "16",
      fill: "#000",
      textAnchor: "middle",
      text: "0 V"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 630,200 L 630,160",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 620,200 L 620,240",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 750,200 L 750,60",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 730,200 L 730,340",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerEnd: "url(#triangle-end)"
    }
  },
]

const svgInfo = [
  {
    type: LINE,
    params: {
      x1: "350",
      x2: "350",
      y1: "200",
      y2: "420",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "475",
      x2: "475",
      y1: "250",
      y2: "390",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "475",
      x2: "650",
      y1: "150",
      y2: "150",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "475",
      x2: "650",
      y1: "250",
      y2: "250",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: POLYGON,
    params: {
      points: "350,200 475,150 600,200 475,250",
      fill: "#ccc",
      stroke: "#000",
      strokeWidth: "1"
    }
  },
  {
    type: TEXT,
    params: {
      x: "475",
      y: "206",
      fontSize: "20",
      fill: "#000",
      fontWeight: "700",
      textAnchor: "middle",
      text: "Eye Interior"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 360,370 L 465,370",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 485,370 L 590,370",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 360,410 L 590,410",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 110,450 L 590,450",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
]

const svgInfo1 = [
  {
    type: LINE,
    params: {
      x1: "250",
      x2: "250",
      y1: "200",
      y2: "420",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "390",
      x2: "390",
      y1: "250",
      y2: "390",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "460",
      x2: "460",
      y1: "250",
      y2: "390",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "460",
      x2: "650",
      y1: "150",
      y2: "150",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: LINE,
    params: {
      x1: "460",
      x2: "650",
      y1: "250",
      y2: "250",
      stroke: "#000",
      strokeWidth: "1",
      strokeDasharray: "5 2"
    }
  },
  {
    type: POLYGON,
    params: {
      points: "250,200 390,150 460,150 600,200 460,250 390,250",
      fill: "#ccc",
      stroke: "#000",
      strokeWidth: "1"
    }
  },
  {
    type: TEXT,
    params: {
      x: "420",
      y: "206",
      fontSize: "20",
      fill: "#000",
      fontWeight: "700",
      textAnchor: "middle",
      text: "Eye Interior"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 260,370 L 380,370",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 400,370 L 450,370",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 470,370 L 590,370",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 260,410 L 590,410",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
  {
    type: PATH,
    params: {
      d: "M 110,450 L 590,450",
      stroke: "#000",
      strokeWidth: "1",
      fill: "none",
      markerStart: "url(#triangle-start)",
      markerEnd: "url(#triangle-end)"
    }
  },
]

class CPHYEyeMaskSvg extends React.Component {
  render() {
    const { symbolRate = "" } = this.props;
    const [value, unit] = symbolRate.split(' ');
    let standardValue = 1;
    if (value && unit) {
      standardValue = Number(value) * scaleConversion("G", unit.replace("sps", ""))
    }
    return <>
      <svg width="650" height="350" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 850 500">
        <g>
          <defs>
            <marker id="triangle-end" markerUnits="strokeWidth" markerWidth="10" markerHeight="8" refX="0" refY="4"
              orient="auto">
              <path d="M 0 0 L 10 4 L 0 8 z" />
            </marker>
            <marker id="triangle-start" markerUnits="strokeWidth" markerWidth="10" markerHeight="8" refX="0" refY="4"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 4 L 0 8 z" />
            </marker>
          </defs>

          {(standardValue >= 1 ? defaultSvgInfo.concat(svgInfo) : defaultSvgInfo.concat(svgInfo1)).map((item, index) => {
            if (item.type === TEXT) {
              return <text key={`svg-${index}`} {...item.params}>{item.params.text}</text>
            }
            return <item.type key={`svg-${index}`} {...item.params} />
          })}
        </g>
      </svg>

      {(standardValue >= 1 ? defaultTextInfo.concat(textInfo) : defaultTextInfo.concat(textInfo1)).map((item, index) => {
        return <Tooltip title={item.toolTip} overlayClassName="svg-tooltip" key={`svg-${index}`}>
          <span className="svg-text" style={{ top: item.top, left: item.left }}>
            {item.text}
          </span>
        </Tooltip>
      })}
    </>
  }
}

export default CPHYEyeMaskSvg;