import { getSimulationLog, getDCRSimulationLog } from '../../api/Cascade/simulation';
import apiProcess from '../../api/utility';
import { isExistSimulationResult } from '../../api/workflow'

/**
 * download  Cascade simulation log 
 *
 * @export
 * @param {string} verificationId verificationId 
 * @returns Promise
 */

export function getCascadeSimulationLog(verificationId) {
  return new Promise((resolve, reject) => {
    getSimulationLog(verificationId).then(res => {
      resolve(res.data);
    }, error => {
      resolve('');
    })
  })
}

export function isExistResult(verificationId) {
  return apiProcess(isExistSimulationResult, { product: "cascade", verificationId });
}