import { UPDATE_MULTIPLE_LIST, GET_MULTIPLE_CHANNEL_CONTENT, UPDATE_MULTIPLE_STATUS, UPDATE_RESULT_MENU_STATUS } from './actionType';

const defaultState = {
  haveMulti: false,
  list: [],
  channelId: null,
  multiByteStatus: false,
  multipleMenuStatus: false
}

export const RockyMultipleReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_MULTIPLE_LIST:
      return {
        ...state,
        list: action.list
      }
    case GET_MULTIPLE_CHANNEL_CONTENT:
      return {
        ...state,
        channelId: action.id
      }
    case UPDATE_MULTIPLE_STATUS:
      return {
        ...state,
        multiByteStatus: action.status
      }
    case UPDATE_RESULT_MENU_STATUS:
      return {
        ...state,
        multipleMenuStatus: action.status
      }
    default: return state;
  }
}