import React, { Component, Fragment } from 'react';
import { CloseCircleFilled, CloseOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Input, Popover, Spin } from "antd";
import { strDelimited } from '@/services/helper/split';
import { getDefaultIndex } from "@/services/helper/setDefaultName";
import { SIERRA } from "../../constants/pageType";
import TreeSelect from '@/components/TreeSelect';
import NodeSelection from "@/components/pinNodeConnect/nodeSelection";
import { getPopoverPlacement } from '@/services/helper/htmlElement';
import { strFormatChange } from "@/services/helper/stringHelper";
import '@/publicCss/netlist.css';

const { Option, OptGroup } = Select;
const packageModelType = "Package";
const MultiPinSPICE = "MultiPinSPICE";
const Repeater = "Repeater";
const hasFolder = [MultiPinSPICE, Repeater, 'SPICE'];
const horizontalList = [packageModelType, Repeater];

class NetListModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFile: "",//nodes select box selected "libraryId" / "subckt::libraryId"
      searchValue: "",
      top: 50,
      placement: "top"
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const PopoverRoot = document.getElementsByClassName('spice-node-select-Popover')[0];
    const selectRoot = document.getElementsByClassName('nodes-list-file-dropdown')[0];

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || (PopoverRoot && !PopoverRoot.contains(target) && (!selectRoot || !selectRoot.contains(target)))) {
      this.closeNodesSelection();
    }
  }

  selectSpiceSubckt = (key, fileIndex) => {
    this.props._selectSpiceSubckt(key, fileIndex);
  }

  deleteFileSelect = (type, fileIndex, deleteLine) => {
    this.props._delFileSelect(type, fileIndex, deleteLine);
  }

  addNewFileSelect = (e) => {
    e.stopPropagation();
    this.props._addNewFileSelect();
  }

  _selectFile = (key, fileIndex) => {
    //key -> {key:id::fileName(id), label:"name" }, key: folder -> id::fileName , file -> id
    this.props._selectFile(key, fileIndex);
  }

  getSelectList() {
    const { fileList, files, subcktList, modelType, product } = this.props;
    const modelTitle = (!files || files.length === 0) ? "Model File" : "";
    const placeholder = modelType === packageModelType ? "Touchstone / SPICE File" : "Model File";
    return (
      <Fragment>
        {files && files.length > 0 ? files.map((file, index) => {
          const modelTitle = index === 0 ? "Model File" : "";
          if (hasFolder.includes(file.type)) {
            /* find subckt select list */
            const currentSubckt = subcktList ? subcktList.find(item => (item.libraryId === file.libraryId && item.fileName === file.fileName)) : null;
            const subckts = currentSubckt ? currentSubckt.models : [];

            let fileName = file.fileName ? file.fileName : undefined;
            if (file.folder && fileName) {
              fileName = `${file.folder}::${fileName}`;
            }
            return (
              <div className='spice-model-multi-select' key={index}>
                {/* spice file */}
                {this.getSelectComponent({
                  type: modelTitle,
                  width: "calc(50% + 45px)",
                  placeholder,
                  value: fileName ? fileName : '',
                  selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
                  list: fileList,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : fileName,
                  tree: product !== SIERRA ? false : true
                })}
                {/* subckt */}
                {this.getSelectComponent({
                  type: "Subckt",
                  width: "calc(50% - 55px)",
                  placeholder: 'Subckt',
                  value: file.subckt ? file.subckt : '',
                  selectHandler: this.selectSpiceSubckt,
                  list: subckts,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : file.subckt,
                  tree: false
                })}
                <CloseOutlined
                  title={'Delete the file'}
                  className='spice-model-delete-file-icon'
                  onClick={() => { this.deleteFileSelect('file', index, true) }} />
              </div>
            );
          } else {
            return (
              <div className='spice-model-single-select' key={index}>
                {/* touchstone file or file not select */}
                {this.getSelectComponent({
                  type: modelTitle,
                  placeholder,
                  value: file.fileName ? file.fileName : '',
                  selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
                  list: fileList,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : file.fileName,
                  tree: product !== SIERRA ? false : true
                })}
                <CloseOutlined
                  title={'Delete the file'}
                  className='spice-model-delete-file-icon'
                  onClick={() => { this.deleteFileSelect('file', index, true) }} />
              </div>
            );
          }
        }) : <div className='spice-model-single-select'>{this.getSelectComponent({
          type: modelTitle,
          placeholder,
          value: '',
          selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
          list: fileList,
          fileIndex: -1,
          fileId: '',
          tree: product !== SIERRA ? false : true
        })}</div>}
      </Fragment>
    );
  }

  getSelectForComponents({ _value, placeholder, value, selectHandler, fileIndex, type, list, tree }) {
    return tree ? <TreeSelect
      showSearch
      labelInValue
      placeholder={placeholder}
      value={_value}
      fileIndex={fileIndex}
      onSelectItem={selectHandler}
      className={`spice-netlist-model-selection ${type}_select`}
      popupClassName='spice-netlist-model-dropdown'
      popupMatchSelectWidth={false}
      allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type, fileIndex) }} /> }}
      getPopupContainer={() => document.getElementById('root')}
      fileList={list}
      showFileFolder={true}
      selected={_value.key}
    /> : <Select
      labelInValue
      placeholder={placeholder}
      value={_value}
      onChange={(item) => item && selectHandler({ label: item.label, key: item.value }, fileIndex)}
      className={`spice-netlist-model-selection ${type}_select`}
      popupClassName='spice-netlist-model-dropdown'
      popupMatchSelectWidth={false}
      allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type, fileIndex) }} /> }}
      getPopupContainer={() => document.getElementById('root')}
    >
      {list.map((item, index) => (
        item.type === 'folder' ? <OptGroup key={index} label={item.fileName}>
          {item.children.map(it => <Option
            className={value === `${item.name}::${it.name}` ? "ant-select-dropdown-menu-item-selected" : ''}
            key={`${it.name}`}
            value={`${item.id}::${it.name}`}
            title={it.name}
          >{it.name}</Option>)}
        </OptGroup> :
          <Option
            key={item.name}
            value={item.id ? item.id : item.name}
            title={item.name}
          >{item.name}</Option>
      ))}
    </Select>;
  }

  getSelectComponent({ type, width, placeholder, value, selectHandler, list, fileIndex, fileId, tree }) {
    let _value = { key: fileId, label: value };
    return (
      <div className='spice-netlist-model-select' style={{ width }}>
        {type === "Model File" ?
          <div className='spice-model-file-add-box'>
            <span>{type}</span>
            <PlusCircleOutlined
              title={'Add new file'}
              className='spice-model-add-file-icon'
              onClick={(e) => { this.addNewFileSelect(e) }} />
          </div>
          : <span className={type}>{type === 'Subckt' ? "" : type}</span>}
        {this.getSelectForComponents({ _value, placeholder, value, selectHandler, fileIndex, type, list, tree })}
      </div >
    );
  }

  openSelectNode = (pin, value, type, currentItem) => {
    const libraryId = currentItem[`${type}LibraryId`] ? currentItem[`${type}LibraryId`] : "";
    const subckt = currentItem[`${type}Subckt`] ? currentItem[`${type}Subckt`] : "";
    const fileName = currentItem[`${type}FileName`] ? currentItem[`${type}FileName`] : "";
    const { maxWidth, maxHeight } = this.props;
    const { leftPlacement, rightPlacement } = getPopoverPlacement({ cssId: pin, type, maxWidth, maxHeight })
    this.setState({
      leftPlacement,
      rightPlacement
    })

    const { files, modelType } = this.props;
    const { selectFile } = this.state;
    let _selectFile = selectFile;
    if (libraryId) {
      _selectFile = subckt ? `${libraryId}::${subckt}` : libraryId;
      //Folder fileName
      _selectFile = fileName ? `${_selectFile}::${fileName}` : _selectFile;
    } else {
      //find current select File exist
      const [id, name, _fileName] = strDelimited(_selectFile, "::");
      const find = files.find(item => id && item.libraryId === id && (!name || name === item.subckt) && (!_fileName || _fileName === item.fileName));
      if (!find) {
        _selectFile = "";
        //select first file
        //touchstone file or spice file and subckt exist

        let findFile = null;
        for (let file of files) {
          if (file && file.type === 'Touchstone' && file.libraryId) {
            findFile = file;
            break;
          }

          if (file && (hasFolder.includes(file.type)) && file.libraryId && file.subckt) {
            findFile = file;
            break;
          }
        }

        if (findFile) {
          _selectFile = findFile.subckt ? `${findFile.libraryId}::${findFile.subckt}` : findFile.libraryId;
          if (modelType !== packageModelType) {
            //Folder fileName
            _selectFile = findFile.fileName ? `${_selectFile}::${findFile.fileName}` : _selectFile;
          }
        }
      }
    }

    this.setState({
      editNode: pin,
      searchValue: value,
      selectFile: _selectFile
    })
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: null,
      searchValue: ""
    })
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  selectPinNodeInRepeater = ({ nodeItem, pinItem, pinType, selectFile }) => {
    const { pinList, pairs, modelType } = this.props;
    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : [];
    let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];;
    let modelKey = nodeItem.modelKey
    if (nodeItem.modelKey === "New") {
      const filterKeys = _pinList
        .map(item => [(item || {})['pinLModelKey'], (item || {})['pinRModelKey']])
        .flat(2)
        .filter(item => !!item);
      if (!filterKeys.length) {
        modelKey = "1"
      } else {
        modelKey = getDefaultIndex(filterKeys.length, filterKeys);
      }
    }

    const libraryId = selectFile.libraryId, subckt = selectFile.subckt, fileName = selectFile.fileName;
    //update pinList
    const index = _pinList.findIndex(item => item[pinType] === pinItem.pin);
    if (index > -1) {
      _pinList[index][`${pinType}Value`] = nodeItem.node;
      //SPICE subckt
      _pinList[index][`${pinType}Subckt`] = subckt ? subckt : "";
      _pinList[index][`${pinType}LibraryId`] = libraryId;
      _pinList[index][`${pinType}ModelKey`] = modelKey;
      if (modelType !== packageModelType) {
        _pinList[index][`${pinType}FileName`] = fileName;
      }
    }
    //update pairs
    const _index = _pairs.findIndex(item => item.pin === pinItem.pin);
    if (_index > -1) {
      //update new node
      _pairs[_index].node = nodeItem.node;
      _pairs[_index].subckt = subckt ? subckt : "";;
      _pairs[_index].libraryId = libraryId;
      _pairs[_index].modelKey = modelKey;
      if (modelType !== packageModelType) {
        _pairs[_index].fileName = fileName;
      }
    }

    this.props._selectNodes({
      pinList: _pinList,
      pairs: _pairs
    });
    this.setState({
      searchValue: nodeItem.node
    }, () => {
      //Open the next pin with no port selected
      //find next pin with no port selected
      let { nextPin, nextType } = this.findNextPin(_pinList, pinType, index);
      if (!nextPin || !nextPin[nextType]) {
        //find pins of not selected port
        const _index = _pinList.findIndex((it, _Index) => !it[`pinLValue`] || !it[`pinRValue`]);
        if (_index > -1) {
          nextPin = _pinList.find((item, i) => i === _index);
          if (!nextPin["pinLValue"]) {
            nextType = 'pinL';
          } else {
            nextType = 'pinR';
          }
        }
      }
      if (nextPin && nextPin[nextType]) {
        //open next pin of not select port
        this.scrollView(nextPin[nextType], nextPin, nextType);
      } else {
        //close nodes select panel
        this.closeNodesSelection();
      }
    })
  }

  selectPinNode = ({ port, pin, type, currentPortInfo, compPin }) => {
    //type: "pin" / "pinDie"
    const subckt = currentPortInfo.subckt, libraryId = currentPortInfo.libraryId, fileName = currentPortInfo.fileName;
    const { pinList, pairs, portsObj, modelType, modelPinType, record } = this.props;
    let _portsObj = portsObj ? JSON.parse(JSON.stringify(portsObj)) : {};
    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : [];
    let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];;
    //find current libraryId ports
    let currentFiles = portsObj[libraryId] ? portsObj[libraryId] : [];

    if (currentFiles.length === 0) {
      return;
    }

    let _ports = [];
    if (subckt) {
      //spice
      //(!fileName || item.fileName === fileName) repeater and spice has fileName field, package spice not fileName field
      const fileIndex = currentFiles.findIndex(item => item.subckt === subckt && (!fileName || item.fileName === fileName));
      _ports = currentFiles[fileIndex] && currentFiles[fileIndex].ports ? currentFiles[fileIndex].ports : [];
    } else {
      //touchstone
      _ports = currentFiles[0] && currentFiles[0].ports ? currentFiles[0].ports : [];
    }


    let prevNode = "", prevSubckt = "", prevLibraryId = "", prevFileName = "";

    //update pinList
    const index = _pinList.findIndex(item => item[type] === pin);
    if (index > -1) {
      _pinList[index][`${type}Value`] = port;
      //SPICE subckt
      _pinList[index][`${type}Subckt`] = subckt ? subckt : "";
      _pinList[index][`${type}LibraryId`] = libraryId;
      if (modelType !== packageModelType) {
        _pinList[index][`${type}FileName`] = fileName;
      }
    }
    //update pairs

    const _index = _pairs.findIndex(item => item.pin === pin);
    if (_index > -1) {
      //get prev node and subckt and libraryId
      prevNode = _pairs[_index].node;
      prevSubckt = _pairs[_index].subckt;
      prevLibraryId = _pairs[_index].libraryId;
      prevFileName = _pairs[_index].fileName;
      //update new node
      _pairs[_index].node = port;
      _pairs[_index].subckt = subckt ? subckt : "";;
      _pairs[_index].libraryId = libraryId;
      if (modelType !== packageModelType) {
        _pairs[_index].fileName = fileName;
      }
    }

    //update ports list select status
    const portIndex = _ports.findIndex(item => (item.port === port));
    if (portIndex > -1) {
      _ports[portIndex].select = true;
    }
    if (subckt) {
      const _fileIndex = currentFiles.findIndex(item => item.subckt === subckt && (!fileName || item.fileName === fileName))
      if (_fileIndex > -1) {
        currentFiles[_fileIndex].ports = _ports;
      }
    } else {
      currentFiles[0].ports = _ports;
    }
    _portsObj[libraryId] = currentFiles;

    //prev libraryId exist, update prev port select status
    if (prevLibraryId && _portsObj[prevLibraryId]) {//prev libraryId exist
      let _prevPorts = [];
      let subcktIndex = -1;
      if (subckt) {
        const subcktIndex = _portsObj[prevLibraryId].findIndex(item => item.subckt === prevSubckt && (!prevFileName || item.fileName === prevFileName));
        if (subcktIndex > -1) {
          _prevPorts = _portsObj[prevLibraryId][subcktIndex].ports;
        }
      } else {
        _prevPorts = _portsObj[prevLibraryId][0].ports;
      }
      //prev node select update
      const prevPortIndex = _prevPorts.findIndex(item => prevNode && item.port === prevNode);
      if (prevPortIndex > -1) {
        _prevPorts[prevPortIndex].select = false;
      }
      if (subckt && subcktIndex > -1) {
        _portsObj[prevLibraryId][subcktIndex].ports = _prevPorts;
      } else if (_portsObj[prevLibraryId][0]) {
        _portsObj[prevLibraryId][0].ports = _prevPorts;
      }
    }

    this.props._selectNodes({
      pinList: _pinList,
      pairs: _pairs,
      portsObj: _portsObj
    });

    this.setState({
      searchValue: port
    }, () => {
      //The in and out of the driver model cannot be set to different subckt
      const { usage } = record;
      if (modelPinType === MultiPinSPICE && usage === 'Driver' && compPin) {
        const otherPinType = type === 'pinL' ? 'pinR' : 'pinL',
          //current select node pin (eg: C3_in )
          currentPinSubckt = _pinList[index][`${type}Subckt`],
          currentPinLibraryId = _pinList[index][`${type}LibraryId`],
          currentPinFileName = _pinList[index][`${type}FileName`],

          //another pin (eg: C3_u )
          anotherPin = _pinList[index][otherPinType],
          anotherPinNode = _pinList[index][`${otherPinType}Value`],
          anotherPinSubckt = _pinList[index][`${otherPinType}Subckt`],
          anotherPinLibraryId = _pinList[index][`${otherPinType}LibraryId`],
          anotherPinFileName = _pinList[index][`${otherPinType}FileName`];
        if (anotherPin && anotherPinNode && (currentPinLibraryId !== anotherPinLibraryId || currentPinFileName !== anotherPinFileName || currentPinSubckt !== anotherPinSubckt)) {
          this.deleteSelectNode(null, _pinList[index], otherPinType);
        }
      }

      //Open the next pin with no port selected
      //find next pin with no port selected
      let { nextPin, nextType } = this.findNextPin(_pinList, type, index);

      if (horizontalList.includes(modelType)) {
        if (!nextPin || !nextPin[nextType]) {
          //find pins of not selected port
          const _index = _pinList.findIndex((it, _Index) => !it[`pinLValue`] || !it[`pinRValue`]);
          if (_index > -1) {
            nextPin = _pinList.find((item, i) => i === _index);
            if (!nextPin["pinLValue"]) {
              nextType = 'pinL';
            } else {
              nextType = 'pinR';
            }
          }
        }
      } else {
        if (!nextPin || !nextPin[nextType] || nextPin[`${nextType}Value`]) {
          //find pins of current type not selected port
          const _index = _pinList.findIndex((it, _Index) => !it[`${nextType}Value`]);
          if (_index > -1) {
            nextPin = _pinList.find((item, i) => i === _index);
          }
        }

        const _n_index = _pinList.findIndex((it, _Index) => !it[`${nextType}Value`]);
        if (!nextPin || !nextPin[nextType] || _n_index < 0) {
          //if current type pin port all selected,find another type pins
          const anotherType = nextType === "pinL" ? "pinR" : "pinL";
          const next = this.findNextPin(_pinList, anotherType, -1);
          nextPin = next.nextPin;
          nextType = anotherType;
        }
      }

      if (nextPin && nextPin[nextType]) {
        //open next pin of not select port
        this.scrollView(nextPin[nextType], nextPin, nextType);
      } else {
        //close nodes select panel
        this.closeNodesSelection();
      }
    })
  }

  findNextPin = (pinList, type, index) => {
    const anotherType = type === "pinL" ? "pinR" : "pinL";
    const { modelType } = this.props;

    const findNext = (_index) => {
      const find = pinList.find((item, i) => i === _index);
      return find;
    }

    let nextIndex = index;
    let nextPin = findNext(index);

    if (horizontalList.includes(modelType) && nextPin && nextPin[anotherType] && !nextPin[`${anotherType}Value`]) {
      //pinL find pinR, pinR find pinL
      return { nextPin, nextType: anotherType };
    }

    nextIndex = index + 1;
    nextPin = findNext(nextIndex);

    if (horizontalList.includes(modelType)) {
      while (!nextPin || (!nextPin[type] && !nextPin[anotherType])) {
        nextIndex += 1;
        nextPin = findNext(nextIndex);
        if (nextIndex >= pinList.length) {
          break;
        }
      }
      if (nextPin && nextPin.pinL && !nextPin.pinLValue) {
        return { nextPin, nextType: "pinL" };
      }

      if (nextPin && nextPin.pinR && !nextPin.pinRValue) {
        return { nextPin, nextType: "pinR" };
      }
    } else {
      while (!nextPin || !nextPin[type] || nextPin[`${type}Value`]) {
        nextIndex += 1;
        nextPin = findNext(nextIndex);
        if (nextIndex >= pinList.length) {
          break;
        }
      }
      return { nextPin, nextType: type };
    }

    return { nextPin: null, nextType: null };
  }

  scrollView = (anchorId, nextPin, type) => {
    const { modelType, scrollId } = this.props;
    let scrollElement = document.getElementById(scrollId);  // The scroll container corresponding to the id
    let anchorElement = document.getElementById(anchorId);  // Need to locate the anchor element you see
    if (scrollElement && anchorElement) {
      const offsetTop = anchorElement.offsetTop;
      const top = modelType === MultiPinSPICE ? offsetTop - 148 : offsetTop - 104;

      const offsetLeft = anchorElement.offsetLeft;
      const left = offsetLeft - 34;
      scrollElement.scrollTo({ top, left, behavior: "smooth" });
    }
    setTimeout(() => {
      this.openSelectNode(nextPin[type], nextPin[`${type}Value`], type, nextPin);
    }, 340);
  }

  getDisplayPortListInRepeater = (currentItem, type) => {
    const { pinList, subcktList } = this.props;
    const { selectFile, searchValue } = this.state;
    const anotherType = type === "pinL" ? "pinR" : "pinL";
    const value = strFormatChange(searchValue || "");
    const searchReg = new RegExp(`(${value})`, 'i');
    let modelGroup = new Map();
    // no selected or no optional file
    if (!selectFile || !subcktList) {
      return { modelGroupKeys: [], modelGroup }
    }
    const [libraryId, subckt, fileName] = strDelimited(selectFile, "::");
    // Find the selected file information
    const findModel = (subcktList || []).find(item => item.libraryId === libraryId && item.fileName === fileName);
    if (!findModel || !findModel.models || !findModel.models.length) {
      return { modelGroupKeys: [], modelGroup }
    }
    const findNodes = findModel.models.find(item => item.name === subckt);
    if (!findNodes || !findNodes.ports || !findNodes.ports.length) {
      return { modelGroupKeys: [], modelGroup }
    }

    const searchNodes = searchValue && searchReg ? findNodes.ports.filter(item => item && item.match(searchReg)) : [];
    let currentModelKey = null;
    //current pin connected port
    const node = currentItem[`${type}Value`] ? currentItem[`${type}Value`] : "";
    //current pin connected port libraryId
    const modelKey = currentItem[`${type}ModelKey`] ? currentItem[`${type}ModelKey`] : "";
    if (node) {
      modelGroup.set(modelKey, [{ ...currentItem, node, modelKey, selectPin: currentItem[`${type}`], select: true }])
      currentModelKey = modelKey;
    }
    for (let item of pinList) {
      if (item[`${type}`] !== currentItem[`${type}`]
        && !!item[`${type}Value`]
        && item[`${type}LibraryId`] === libraryId
        && item[`${type}FileName`] === fileName
        && item[`${type}Subckt`] === subckt) {
        if (modelGroup.get(item[`${type}ModelKey`])) {
          modelGroup.set(item[`${type}ModelKey`], [...modelGroup.get(item[`${type}ModelKey`]), { ...item, node: item[`${type}Value`], modelKey: item[`${type}ModelKey`], selectPin: item[`${type}`], select: true }])
        } else {
          modelGroup.set(item[`${type}ModelKey`], [{ ...item, node: item[`${type}Value`], modelKey: item[`${type}ModelKey`], selectPin: item[`${type}`], select: true }])
        }
      }

      if (item[`${anotherType}`] !== currentItem[`${type}`]
        && !!item[`${anotherType}Value`]
        && item[`${anotherType}LibraryId`] === libraryId
        && item[`${anotherType}FileName`] === fileName
        && item[`${anotherType}Subckt`] === subckt) {
        if (modelGroup.get(item[`${anotherType}ModelKey`])) {
          modelGroup.set(item[`${anotherType}ModelKey`], [...modelGroup.get(item[`${anotherType}ModelKey`]), { ...item, node: item[`${anotherType}Value`], modelKey: item[`${anotherType}ModelKey`], selectPin: item[`${anotherType}`], select: true }])
        } else {
          modelGroup.set(item[`${anotherType}ModelKey`], [{ ...item, node: item[`${anotherType}Value`], modelKey: item[`${anotherType}ModelKey`], selectPin: item[`${anotherType}`], select: true }])
        }
      }
    }

    for (let key of Array.from(modelGroup.keys())) {
      const selectedNodes = modelGroup.get(key) || [];
      const _selectedNodes = selectedNodes.map(item => item.node);
      const nodes = findNodes.ports.filter(item => !_selectedNodes.includes(item))
        .map(item => {
          return {
            node: item,
            modelKey: key
          }
        })
      if (!nodes.length && currentModelKey !== key) {
        modelGroup.delete(key);
        continue;
      }
      const sortNodes = [...nodes.filter(item => searchNodes.includes(item.node)),
      ...selectedNodes.filter(item => searchNodes.includes(item.node)),
      ...nodes.filter(item => !searchNodes.includes(item.node)),
      ...selectedNodes.filter(item => !searchNodes.includes(item.node))]
      modelGroup.set(key, [...sortNodes]);
      modelGroup.set(key, [...nodes, ...selectedNodes]);
    }

    const notSearchNodes = findNodes.ports.filter(item => !searchNodes.includes(item))
    const sortNodes = [...new Set([...searchNodes, ...notSearchNodes])];
    modelGroup.set("New", sortNodes.map(item => {
      return {
        node: item,
        modelKey: "New"
      }
    }))

    return { modelGroupKeys: Array.from(modelGroup.keys()), modelGroup };
  }

  getDisplayPortList = (currentItem, type) => {
    const { portsObj } = this.props;
    const { searchValue, selectFile } = this.state;
    if (!portsObj || Object.keys(portsObj).length === 0) {
      return [];
    }
    //current pin connected port
    const port = currentItem[`${type}Value`] ? currentItem[`${type}Value`] : "";
    //current pin connected port libraryId
    const currentLibraryId = currentItem[`${type}LibraryId`] ? currentItem[`${type}LibraryId`] : "";

    let libraryId = "", subckt = "", fileName = "";
    if (!selectFile) {
      return [];
    }
    //325t43466434::subckt1::file
    const [id, name, _fileName] = strDelimited(selectFile, "::");
    if (!id) {
      return [];
    } else {
      libraryId = id;
      subckt = name;
      fileName = _fileName;
    }
    //find current libraryId ports
    let currentFiles = portsObj[libraryId] ? portsObj[libraryId] : [];

    let value = searchValue;
    let valueList = [];
    //Add escape characters to special characters
    strDelimited(value).forEach(item => {
      if (/[^a-zA-Z0-9]/ig.test(item)) {
        valueList.push(`\\${item}`);
      } else {
        valueList.push(item);
      }
    });
    value = valueList.join("");
    let reg = new RegExp(`(${value})`, 'i');

    //find ports
    let ports = [];
    if (!subckt) {
      ports = currentFiles[0] && currentFiles[0].ports ? currentFiles[0].ports : [];
    } else {
      const findSubckt = currentFiles.find(item => item.subckt === subckt && (!fileName || fileName === item.fileName));
      ports = findSubckt && findSubckt.ports ? findSubckt.ports : [];
    }

    //sort ports
    let _portList = [], selectedList = [], currentPort = [], searchList = [], notSelectedList = [];
    ports.forEach(item => {
      if (currentLibraryId === libraryId && port && item.port === port) {
        // selected port of current pin
        currentPort.push({ ...item, libraryId, fileName });
      } else if (searchValue && (currentLibraryId !== libraryId || port !== searchValue) && item.info.match(reg)) {
        //searched ports
        searchList.push({ ...item, libraryId, fileName });
      } else if (item.select) {
        //Ports that have been selected by other pins
        selectedList.push({ ...item, libraryId, fileName });
      } else {
        //No selected ports
        notSelectedList.push({ ...item, libraryId, fileName });
      }
    });

    const _searchSelectedList = searchList.filter(item => item.select);
    const _searchNotSelectedList = searchList.filter(item => !item.select);
    const _searchList = [..._searchNotSelectedList, ..._searchSelectedList];
    //current pin select port + searched ports + notSelected ports + selected ports
    _portList = [...currentPort, ..._searchList, ...notSelectedList, ...selectedList];
    return _portList;
  }

  selectNodesFile = (key) => {
    this.setState({
      selectFile: key
    })
  }

  getSelectedFileList = () => {
    const { modelType, files } = this.props;
    let _files = [...files];
    let list = [];
    _files.forEach(item => {
      if (hasFolder.includes(item.type) && item.subckt) {
        let key = `${item.libraryId}::${item.subckt}`;
        let value = `${item.subckt}::${item.fileName}`;

        if (modelType !== packageModelType) {
          key = `${key}::${item.fileName}`;
          value = item.folder ? `${value}::${item.folder}` : value;
        }
        list.push({
          key,
          value,
        })
      } else if (modelType === packageModelType) {
        if (item.type === 'Touchstone') {
          list.push({
            key: item.libraryId,
            value: item.fileName,
          });
        }

      }
    });

    return list;
  }

  judgeNodeSelectRender = (pin, type, port, placeholder, currentItem, modelType) => {
    if (modelType === Repeater) {
      const { searchValue, selectFile } = this.state;
      const [libraryId, subckt, fileName] = strDelimited(selectFile, "::");
      const { modelGroupKeys, modelGroup } = this.getDisplayPortListInRepeater(currentItem, type);
      const selectFileList = this.getSelectedFileList();
      const displayPin = currentItem[`${type}NodeDisplay`] ? currentItem[`${type}NodeDisplay`] : pin;
      const { maxWidth, maxHeight } = this.props;
      return <NodeSelection
        pinItem={{ pin, modelKey: currentItem[`${type}ModelKey`], node: currentItem[`${type}Value`] }}
        displayPin={displayPin}
        pinType={type}
        searchValue={searchValue}
        selectFile={{
          libraryId,
          subckt,
          fileName
        }}
        modelGroupKeys={modelGroupKeys}
        modelGroup={modelGroup}
        selectFileList={selectFileList}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        closeNodesSelection={this.closeNodesSelection}
        selectNodesFile={this.selectNodesFile}
        searchNode={this.searchNode}
        selectPinNode={this.selectPinNodeInRepeater}
      />
    } else {
      return this.nodeSelectRender(pin, type, port, placeholder, currentItem);
    }
  }

  nodeSelectRender = (pin, type, port, placeholder, currentItem) => {
    const { searchValue, selectFile } = this.state;
    const _portList = this.getDisplayPortList(currentItem, type);
    const displayPin = currentItem[`${type}NodeDisplay`] ? currentItem[`${type}NodeDisplay`] : pin;
    const compPin = currentItem.compPin ? currentItem.compPin : null;
    const selectFileList = this.getSelectedFileList();
    const { maxWidth, maxHeight } = this.props;
    return (
      <div className='nodes-content' style={{ maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200, maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100 }}>
        <div className='nodes-content-header'>
          <span className='nodes-content-header-span'>Component Pin {displayPin}</span>
        </div>
        <div className='node-content-close' onClick={() => this.closeNodesSelection()}>
          <CloseOutlined className='node-content-close-icon' />
        </div>
        <div className="node-list-body-with-search">
          <div className="node-list-body-search-wrapper">
            <Select
              placeholder={`Select File/Subckt`}
              className='nodes-list-file-select'
              allowClear
              value={selectFile}
              popupClassName='nodes-list-file-dropdown'
              popupMatchSelectWidth={false}
              onChange={(key) => this.selectNodesFile(key)}
            >
              {selectFileList.map(item => (
                <Option
                  key={item.key}
                  title={item.value}
                  className='spice-nodes-content-file'
                >
                  {item.value}
                </Option>
              ))
              }
            </Select>
            <Input
              placeholder={`Search ${placeholder} `}
              allowClear
              suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='node-list-ul' style={{ maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100 }}>
              {_portList.map(item =>
                <li
                  key={item.port}
                  title={item.info}
                  className={item.port === port && item.select ? 'current-pin-selected-node-li' : (item.select ? 'node-li-selected' : '')}
                  onClick={!item.select ? () => this.selectPinNode({ port: item.port, pin, type, currentPortInfo: item, compPin }) : null}
                >
                  {item.info !== item.port ? <Fragment>{item.port}&nbsp;&nbsp;&nbsp;&nbsp;{item.info}</Fragment> : item.port}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  deleteSelectNode = (e, item, type) => {
    //type: "pinL" / "pinR"
    e && e.stopPropagation();
    const pin = item[type], prevNode = item[`${type}Value`], libraryId = item[`${type}LibraryId`], subckt = item[`${type}Subckt`], fileName = item[`${type}fileName`];
    const { pinList, pairs, portsObj, modelType } = this.props;
    let _portsObj = portsObj ? JSON.parse(JSON.stringify(portsObj)) : {};

    //update pinList
    //delete current pinL/pinR port in pinList
    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : [];
    const index = _pinList.findIndex(item => item[type] === pin);
    if (index > -1) {
      _pinList[index][`${type}Value`] = "";
      _pinList[index][`${type}Subckt`] = "";
      _pinList[index][`${type}LibraryId`] = "";
      if (modelType !== -packageModelType) {
        _pinList[index][`${type}FileName`] = "";
      }
    }

    //update pairs
    //delete current pin/pin_die node in pairs
    let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];
    const _index = _pairs.findIndex(item => item.pin === pin);
    if (_index > -1) {
      _pairs[_index].node = "";
      _pairs[_index].subckt = "";
      _pairs[_index].libraryId = "";
      if (modelType !== packageModelType) {
        _pairs[_index].fileName = "";
      }
    }

    if (libraryId && _portsObj[libraryId]) {
      let currentFiles = _portsObj[libraryId];
      let subcktIndex = -1, _ports = [];
      if (subckt) {
        //spice
        subcktIndex = currentFiles.findIndex(item => (!fileName || item.fileName === fileName) && item.subckt === subckt);
        _ports = currentFiles[subcktIndex] && currentFiles[subcktIndex].ports ? currentFiles[subcktIndex].ports : [];
      } else {
        //touchstone
        _ports = currentFiles[0] && currentFiles[0].ports ? currentFiles[0].ports : [];
      }

      //prev node select status improvement
      const prevNodeIndex = _ports.findIndex(item => prevNode && item.port === prevNode);

      if (prevNodeIndex > -1) {
        _ports[prevNodeIndex].select = false;
      }

      if (subckt && subcktIndex > -1) {
        _portsObj[libraryId][subcktIndex].ports = _ports;
      } else {
        _portsObj[libraryId][0].ports = _ports;
      }
    }

    this.props._selectNodes({
      pinList: _pinList,
      pairs: _pairs,
      portsObj: _portsObj
    });
  }

  render() {
    const { pinList, errorMsg, displayType, modelPinType, modelType, loadingNode } = this.props;
    const { editNode, leftPlacement, rightPlacement } = this.state;
    const placeholder = 'Node';
    const allPinL = pinList.filter(item => !!item.pinL);
    const allPinR = pinList.filter(item => !!item.pinR);
    let className = "spice-pin-connect-node-item";
    if (!allPinL || allPinL.length === 0 || !allPinR || allPinR.length === 0 || modelPinType === 'Stimulus') {
      className = "spice-pin-connect-node-item spice-pin-connect-node-item-center";
    }
    return (
      <div className='spice-netlist-model-content'>
        <Spin spinning={loadingNode || false}
          tip={loadingNode ? `Nodes are assigning...` : ''}
        >
          <div className='spice-netlist-model-select-content' id="spice-netlist-model-select-content-id">
            {this.getSelectList()}
            {errorMsg ? <span className="spice-model-file-error-msg">{errorMsg}</span> : null}
          </div>
          {displayType === 'model' && <div className='spice-connect-content'>
            <ul className='spice-pin-list-ul'>
              {pinList.map((item, index) =>
                <div className={className} key={index} style={{ lineHeight: "56px" }}>
                  {/* pin L connect port */}
                  {item.pinL && <Fragment><div className='spice-pin-die-value-box'>
                    {!item.displayType && editNode && editNode === item.pinL ?
                      <Popover
                        overlayClassName='spice-node-select-Popover'
                        content={this.judgeNodeSelectRender(item.pinL, "pinL", item.pinLValue, placeholder, item, modelType)}
                        title=""
                        trigger="click"
                        open={true}
                        placement={leftPlacement ? leftPlacement : "top"}
                      ><Input
                          className='spice-pin-input'
                          placeholder={placeholder}
                          value={item.pinLValue}
                          id={item.pinL}
                          onClick={() => this.openSelectNode(item.pinL, item.pinLValue, "pinL", item)}
                        /></Popover> :
                      item.pinLValue ?
                        <div className='spice-pin-input' id={item.pinL} title={item.pinLValue} onClick={() => this.openSelectNode(item.pinL, item.pinLValue, "pinL", item)}>
                          <span>{item.pinLValue}</span>
                          <CloseCircleFilled
                            className='spice-pin-node-delete-icon'
                            onClick={(e) => { this.deleteSelectNode(e, item, "pinL") }} />
                        </div>
                        : !item.displayType && <Input
                          className='spice-pin-input'
                          placeholder={placeholder}
                          value={item.pinLValue}
                          id={item.pinL}
                          onClick={() => this.openSelectNode(item.pinL, item.pinLValue, "pinL", item)}
                        />
                    }
                  </div>
                    {/* pin_L */}
                    <div className='spice-pin-die-box' title={item.pinLDisplay ? item.pinLDisplay : item.pinL}>{item.pinLDisplay ? item.pinLDisplay : item.pinL}</div>
                    {/* - */}
                    <div className='spice-pin-line'></div>
                    <div className='spice-pin-circle'></div>
                  </Fragment>}
                  {/* net box */}
                  <div
                    className={index === 0 ? 'spice-box spice-box-top' : (index === (pinList.length - 1) ? 'spice-box spice-box-bottom' : 'spice-box')}
                    title={`${item.signalDisplay}::${item.netDisplay}`}
                  >
                    <span className='spice-box-signal-text'>{item.signalDisplay}</span>
                    <span className='spice-box-net-text'>{item.netDisplay}</span>
                  </div>
                  {/* - */}
                  {item.pinR ? <Fragment><div className='spice-pin-circle'></div>
                    <div className='spice-pin-line'></div>
                    {/* pin R */}
                    {!item.displayType && <div className='spice-pin-box' title={item.pinRDisplay ? item.pinRDisplay : item.pinR}>{item.pinRDisplay ? item.pinRDisplay : item.pinR}</div>}
                    {/* pin R connect port */}
                    <div className='spice-pin-value-box'>
                      {editNode && editNode === item.pinR ? <Popover
                        overlayClassName='spice-node-select-Popover'
                        content={this.judgeNodeSelectRender(item.pinR, "pinR", item.pinRValue, placeholder, item, modelType)}
                        title=""
                        trigger="click"
                        open={true}
                        placement={rightPlacement ? rightPlacement : "top"}
                      ><Input
                          className='spice-pin-input'
                          placeholder={placeholder}
                          value={item.pinRValue}
                          id={item.pinR}
                          onClick={() => this.openSelectNode(item.pinR, item.pinRValue, "pinR", item)}
                        /></Popover>
                        : item.pinRValue ?
                          <div className='spice-pin-input' id={item.pinR} title={item.pinRValue} onClick={() => this.openSelectNode(item.pinR, item.pinRValue, "pinR", item)}>
                            <span>{item.pinRValue}</span>
                            <CloseCircleFilled
                              className='spice-pin-node-delete-icon'
                              onClick={(e) => { this.deleteSelectNode(e, item, "pinR") }} />
                          </div>
                          : <Input
                            className='spice-pin-input'
                            placeholder={placeholder}
                            id={item.pinR}
                            value={item.pinRValue}
                            onClick={() => this.openSelectNode(item.pinR, item.pinRValue, "pinR", item)}
                          />}
                    </div></Fragment> : null}
                </div>)}
            </ul>
          </div>}
        </Spin>
      </div>
    );
  }
}

export default NetListModel;