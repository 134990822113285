import { getRockyPackagePDNInfo } from "../rockyCtrl";

class PDNInfo {
  constructor() {
    this.pdnInfos = new Map(); // key - pcbId/packageId, value - pdnInfos
  }

  setInfo = (id, pdnInfo) => {
    this.pdnInfos.set(id, pdnInfo)
  }

  getPdnInfo = (id) => {
    return this.pdnInfos.get(id)
  }

  getPCBPackagePdnInfo = (id, update = false) => {
    return new Promise((resolve, reject) => {
      const info = this.pdnInfos.get(id)
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getRockyPackagePDNInfo(id).then(res => {
          if (res) {
            this.setInfo(id, { ...res })
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }
}

const PackagePCBPdnInfo = new PDNInfo();
export default PackagePCBPdnInfo;