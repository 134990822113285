import React from 'react';
import Plot2D from '../../Result/Public/waveform/Plot2D';

function Signal() {
  this.name = null; // String, name of the signal
  this.type = null; // String, signal type - 'voltage' or 'current'
};

// ------ Result data class ------
/** Raw curve data of a byte */
class WaveformData {
  constructor() {
    this.signals = []; // Array of WaveformResultHelper.Signal, list of signal description
    this.time = []; // Array of Number, time instances of the simulation
    this.curveData = []; // Array of Array of Number, values at each time instance for all signals
    this.plot = null;
  }

  getCurves() {
    return this.plot.curves;
  }

  parseRawCurve(fileContent) {
    // break the file into lines
    if (!fileContent) {
      return;
    }
    var lineBuffer = fileContent.match(/[^\r\n]+/g);
    if (!lineBuffer || (Array.isArray(lineBuffer) && !lineBuffer.length)) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
      return;
    }
    // read the header
    var numSignals = 0; // number of signals in the raw data file
    var numPoints = 0; // number of sampling points in the raw data file
    var indDataLine = 0; // starting line of the curve data
    var isReadingSignalDef = false; // indicator of the signal definition section in the header

    for (let i = 0; i < lineBuffer.length; i++) {

      if (isReadingSignalDef) {

        // get the name and type of the signal
        var words = lineBuffer[i].trim().split("\t");

        var signalItem = new Signal();
        signalItem.name = words[1];
        signalItem.type = words[2];
        this.signals.push(signalItem);

        // add a data line for the signal
        this.curveData.push([]);

        if (this.signals.length === numSignals)
          isReadingSignalDef = false;
      } else {

        words = lineBuffer[i].split(" ");
        if (words[0] === 'No.' && words[1] === 'Variables:') {
          // number of signals
          numSignals = Number(words[2]);
        } else if (words[0] === 'No.' && words[1] === 'Points:') {
          // number of points
          numPoints = Number(words[2]);
        } else if (words[0] === 'Variables:') {
          // starting of variable definitions
          isReadingSignalDef = true;
        } else if (words[0] === 'Values:') {
          // starting of curve values
          indDataLine = Number(i);
          break;
        }
      }
    } // for (var i in lineBuffer)

    // data integrity check
    if (numPoints <= 0 || numSignals <= 0 || this.signals.length !== numSignals)
      return null;
    else if (indDataLine + numSignals * numPoints >= lineBuffer.length)
      return null;

    // read the data points
    try {
      for (var iPoint = 0; iPoint < numPoints; iPoint++) {

        for (var iSignal = 0; iSignal < numSignals; iSignal++) {

          indDataLine++;
          if (iSignal === 0) {
            words = lineBuffer[indDataLine].split('\t');
            /* this.curveData[iSignal].push(Number(words[2])); */
            this.curveData[iSignal].push(Number(words[words.length - 1]));
          } else {
            this.curveData[iSignal].push(Number(lineBuffer[indDataLine]));
          }

        } // for (var iSignal = 0; iSignal < numSignals; iSignal++)
      } // for (var iPoint = 0; iPoint < numPoints; iPoint++)

      // separate the time signal
      this.time = this.curveData.shift();
      this.signals.shift();

    } catch (err) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
    }
  } // parseRawCurveFile

  /** ------ implementation of WaveformResultHelper.plotWaveform ------
  *  Draw the raw curves
  *  @param  svgElement   - the SVG element where the plot is added
  *  @param  rawCurveData - a WaveformResultHelper.WaveformData object, containing the curve data of a byte
  *  @return a WaveformResultHelper.Plot object with a handle to the plot
  */
  plotWaveform = ({ svgElement, ui = 1e-9, events }) => {
    if (this.plot === null) {
      const { PlotOptions, CurveItem } = Plot2D;
      // plot options
      const plotOptions = new PlotOptions();
      plotOptions.xUnit = 's';
      plotOptions.yUnit = 'V';
      plotOptions.grid = 'xy';
      plotOptions.zoom = 'x';
      plotOptions.xLabel = 'Time';
      plotOptions.yLabel = 'Voltage';
      plotOptions.ui = ui; //unit interval - approximate data cycle length
      this.plot = new Plot2D(svgElement, plotOptions, events, 'sierra');
      const { signals, plot, time, curveData } = this;
      // prepare the curve data
      var curves = [];
      for (let i = 0; i < signals.length; i++) {
        const signal = signals[i];
        if (signal.name) {
          let newCurve = new CurveItem();
          newCurve.x = time;
          newCurve.y = curveData[i];
          newCurve.name = signal.name;
          curves.push(newCurve);
        }
      }

      // display the curves
      plot.plotCurves(curves);
    } else {
      this.plot.redrawPlot(svgElement);
    }
  } // plotWaveform


  updataRawCurvePlot = () => {
    this.plot.updatePlot();
  }

  getCurvesData = () => {
    const { signals, time, curveData } = this;
    const { CurveItem } = Plot2D;
    // prepare the curve data
    var curves = [];
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      if (signal.name) {
        let newCurve = new CurveItem();
        newCurve.x = time;
        newCurve.y = curveData[i];
        newCurve.name = signal.name;
        curves.push(newCurve);
      }
    }

    return curves;
  }
};

export function getDataList(compInfo) {
  let list = [], show = [], portSelect = {}, openSignal = "", allPortSelect = {};
  const _compInfo = compInfo.filter(item => item.usage)
  for (let item of _compInfo) {
    const { color, component, pin, pcb, experiment, signal, usage, pkg, ndEn, curveIndex, postProcessData, designName } = item;

    let name = `${component} - ${pin}`, title = `${experiment} ${signal} ${designName} ${component}-${pin} ${usage}`;
    let id = `${experiment}@${name}-${pcb}-${usage}-${signal}`;
    let displayTitle = <div>{experiment} {signal} <span className="post-process-title-comp">{designName} {component}-{pin}</span> {usage}</div>
    if (pkg) {
      id = `${id}-pkg`;
      name = `${component} - ${pin} - Die`;
      title = `${experiment} ${signal} ${designName} ${component}-${pin}-Die ${usage}`;
      displayTitle = <div>{experiment} {signal} <span className="post-process-title-comp">{designName} {component}-{pin}-Die</span> {usage}</div>
    };
    if (ndEn) { id = `${id}-nden` };

    postProcessData.curveName = id;
    postProcessData.title = title;
    let _postProcessData = usage === 'Stimulus in' ? {} : { ...postProcessData, signal, displayTitle }
    const findRow = list.find(l => l.rowName === signal);
    const children = {
      id,
      name, usage, pkg, ndEn, pcb,
      color: { [experiment]: color },
      curveIndex: { [experiment]: curveIndex },
      postProcessData: _postProcessData
    }
    if (!findRow) {
      list.push({ rowName: signal, groupName: [experiment], children: [children] });
      allPortSelect[signal] = [id]
      if (!openSignal) {
        openSignal = signal;
        portSelect[signal] = [id]
      }
      show.push(signal)
    } else {
      const findChild = findRow.children.find(child => child.name === name && child.usage === usage && pcb === child.pcb);
      let add = true;
      if (!findChild || pkg !== findChild.pkg) {
        add = false;
      }
      if (add) {
        findChild.color[experiment] = color;
        findChild.curveIndex[experiment] = curveIndex;
      } else {
        findRow.children.push(children)
      }
      findRow.groupName = [...new Set([...findRow.groupName, experiment])];
      allPortSelect[signal].push(id)
      if (signal === openSignal) {
        portSelect[signal].push(id)
      }
    }
    item.portSelectId = id;
  }
  return { _compInfo: compInfo, dataList: { Waveform: { list, show } }, portSelect, allPortSelect }
}

export function getVerificationDataList(compInfo) {
  let list = [], show = [], portSelect = {}, openSignal = "";
  const _compInfo = compInfo.filter(item => item.usage)
  for (let item of _compInfo) {
    const { color, component, pin, pcb, signal, usage, pkg, ndEn, curveIndex, curveName, postProcessData, designName } = item;
    let name = `${component} - ${pin}`, title = `${signal} ${designName} ${component}-${pin} ${usage}`;
    let displayTitle = <div>{signal} <span className="post-process-title-comp" >{designName} {component}-{pin}</span> {usage}</div>
    if (pkg) {
      name = `${component} - ${pin} - Die`;
      title = `${signal} ${designName} ${component}-${pin}-Die ${usage}`;
      displayTitle = <div>{signal} <span className="post-process-title-comp" >{designName} {component}-{pin}-Die</span> {usage}</div>
    }
    // stimulus in do not have post process
    let _postProcessData = usage === 'Stimulus in' ? {} : { ...postProcessData, title, signal, displayTitle }
    let id = curveName;
    const findRow = list.find(l => l.rowName === signal);
    const children = {
      id,
      name, usage, pkg, ndEn, pcb,
      notShowPcbName: true,
      color,
      curveIndex,
      postProcessData: _postProcessData
    }
    if (!findRow) {
      list.push({ rowName: signal, children: [children] });
      show.push(signal);
      if (!openSignal) {
        openSignal = signal;
        portSelect[signal] = [id]
      }
    } else {
      findRow.children.push(children)
      if (signal === openSignal) {
        portSelect[signal].push(id)
      }
    }
    item.portSelectId = id;
  }

  return { _compInfo: compInfo, dataList: { Waveform: { list, show } }, portSelect }
}

export function judgePostProcessSignalType(data, config) {
  let _data = {}
  if (!data || !config) {
    return _data;
  }
  const keys = Object.keys(data)
  const { pcbs, timing } = config;
  const clockPins = timing.map(item => item.clock_pin)
  for (let key of keys) {
    const postProcessData = data[key];
    if (!postProcessData || !postProcessData.edgesInfo) {
      _data[key] = data[key];
      continue;
    }
    const { component, pcb, pin } = postProcessData.edgesInfo;

    const currentPCB = pcbs.find(item => item.name === pcb);
    if (!currentPCB) {
      _data[key] = data[key];
      continue;
    }

    const currentComponent = currentPCB.components.find(item => item.name === component);
    if (!currentComponent) {
      _data[key] = data[key];
      continue;
    }

    const currentPin = currentComponent.pins.find(item => item.name === pin);
    if (!currentPin) {
      _data[key] = data[key];
      continue;
    }

    const pinId = currentPin.id;

    _data[key] = { ...postProcessData, isCLK: clockPins.includes(pinId) }

  }
  return _data;
}

const INDEX = 'index', TIME_RISING = 'timeRising', TIME_FALLING = 'timeFalling', DOUBLE_EDGE = 'doubleEdgeFalling',
  SETUP_TIME = 'setup_time', HOLD_TIME = 'hold_time',
  OVER_SHOOT = 'overshoot', UNDER_SHOOT = 'undershoot',
  HIGH_TIME = 'high_time', LOW_TIME = 'low_time';
const basicColumns = [{
  title: 'Index',
  dataIndex: INDEX,
  key: INDEX,
  width: '9%',
}, {
  title: 'Rising Time',
  dataIndex: TIME_RISING,
  key: TIME_RISING,
  width: '13%',
  // sorter: (a, b) => a.risingTimeDifference - b.risingTimeDifference,
}, {
  title: 'Falling Time',
  dataIndex: TIME_FALLING,
  width: '13%',
  key: TIME_FALLING,
}, {
  title: 'Double Edge (V)',
  dataIndex: DOUBLE_EDGE,
  key: DOUBLE_EDGE,
  width: '13%',
}]
const notCLKColumns = [{
  title: 'Setup Time',
  dataIndex: SETUP_TIME,
  key: SETUP_TIME,
  width: '13%',
}, {
  title: 'Hold Time',
  dataIndex: HOLD_TIME,
  key: HOLD_TIME,
  width: '13%',
}]
const shootColumns = [{
  title: 'Overshoot (V)',
  dataIndex: OVER_SHOOT,
  key: OVER_SHOOT,
  width: '13%',
}, {
  title: 'Undershoot (V)',
  dataIndex: UNDER_SHOOT,
  key: UNDER_SHOOT,
  width: '13%'
}]
const highLowColumns = [{
  title: 'High Time',
  dataIndex: HIGH_TIME,
  key: HIGH_TIME,
  width: '13%',
}, {
  title: 'Low Time',
  dataIndex: LOW_TIME,
  key: LOW_TIME,
  width: '13%'
}]

export const timeUnitList = [TIME_RISING, TIME_FALLING, SETUP_TIME, HOLD_TIME, HIGH_TIME, LOW_TIME];
export const timeValueList = [SETUP_TIME, HOLD_TIME, HIGH_TIME, LOW_TIME, 'risingTimeDifference', 'fallingTimeDifference'];
export const timeArrayList = [TIME_RISING, TIME_FALLING]

export function getEdgesColumns(type, isCLK) {
  let columns = isCLK ? JSON.parse(JSON.stringify([...basicColumns, ...highLowColumns, ...shootColumns])) : JSON.parse(JSON.stringify([...basicColumns,/*  ...notCLKColumns, */ ...shootColumns]))
  const renderList = [SETUP_TIME, HOLD_TIME, HIGH_TIME, LOW_TIME], shootList = [OVER_SHOOT, UNDER_SHOOT];
  columns.forEach((item) => {
    if (type !== 'minMaxColumns' && [TIME_RISING, DOUBLE_EDGE, TIME_FALLING].includes(item.dataIndex)) {
      item.render = (value, record) => {
        if (value && value.length > 1) {
          const trueValue = getArrayValueByKey(item.dataIndex) || null;
          const fixNum = item.dataIndex === DOUBLE_EDGE ? 4 : 2;
          const _trueValue = !isNaN(Number(record[trueValue])) ? Number(record[trueValue]).toFixed(fixNum) : "";
          const minValue = !isNaN(value[0]) ? Number(value[0]).toFixed(fixNum) : "",
            maxValue = !isNaN(value[1]) ? Number(value[1]).toFixed(fixNum) : "";
          return <span>{`${trueValue && record[trueValue] ? `${_trueValue} ` : ''}`}[{minValue}, {maxValue}]</span>
        }
        return <div>-</div>
      }
    } else if (renderList.includes(item.dataIndex)) {
      item.render = (value) => {
        if (value) { return !isNaN(value) ? Number(value).toFixed(2) : "" }
        return <div>-</div>
      }
    } else if (shootList.includes(item.dataIndex)) {
      item.render = (value) => {
        if (value) {
          if (value > 1) {
            return !isNaN(value) ? Number(value).toFixed(2) : value || ""
          }
          const _value = value.toPrecision ? value.toPrecision(2) : value || ""
          return _value < 0.1 ? Number(_value).toExponential() : _value
        }
        return <div>-</div>
      }
    } else {
      item.render = (value) => {
        if (value) {
          const fixNum = item.dataIndex === DOUBLE_EDGE ? 4 : 2;
          if (type === 'minMaxColumns' && [TIME_RISING, DOUBLE_EDGE, TIME_FALLING].includes(item.dataIndex) && !isNaN(value)) {
            return Number(value).toFixed(fixNum)
          }
          return value;
        }
        return <div>-</div>
      }
    }
    return item;
  })
  return columns
}

export function getTitle(key) {
  switch (key) {
    case TIME_RISING:
      return 'Rising Time';
    case TIME_FALLING:
      return 'Falling Time';
    case DOUBLE_EDGE:
      return 'Double Edge';
    case HOLD_TIME:
      return 'Hold Time';
    case SETUP_TIME:
      return 'Setup Time';
    case OVER_SHOOT:
      return 'Overshoot';
    case UNDER_SHOOT:
      return 'Undershoot';
    case HIGH_TIME:
      return 'High Time';
    case LOW_TIME:
      return 'Low Time'
    default: return key;
  }
}

function getArrayValueByKey(key) {
  switch (key) {
    case TIME_RISING:
      return 'risingTimeDifference';
    case TIME_FALLING:
      return 'fallingTimeDifference';
    case DOUBLE_EDGE:
      return 'doubleEdgeFallingDifference';
    default: return null;
  }
}

export default WaveformData;