import { CARD_LIBRARY } from "../../../constants/libraryConstants";
import { LIBRARY_OPEN } from "../../../constants/treeConstants";
import { getLibraryArray } from "./libraryData";

const RDIMM = "rdimm", SODIMM = "sodimm", UDIMM = "udimm";

function getCardLibraryTree(cardObj) {
  let list = [];
  const cardTypes = Object.keys(cardObj || {});
  for (let cardType of cardTypes) {
    //cardType -> rdimm / sodimm / udimm
    const groupTypes = Object.keys(cardObj[cardType] || {});
    const cardItem = new cardLibraryItem({
      name: cardType.toUpperCase(),
      id: cardType,
      type: cardType,
      dataType: LIBRARY_OPEN
    })
    for (let group of groupTypes) {
      //group -> x4 / x8 / x16 ...
      const libraryList = cardObj[cardType][group] || [];
      //library file
      const libraryTreeList = getLibraryArray(CARD_LIBRARY, libraryList);

      const cardGroupItem = new cardLibraryItem({
        name: group,
        id: `${cardType}-${group}`,
        type: group,
        dataType: LIBRARY_OPEN,
        children: libraryTreeList
      })
      cardItem.children.push(cardGroupItem);
    }

    list.push(cardItem);
  }
  return list;
}

function cardLibraryItem({ name, type, id, dataType, children }) {
  this.name = name;
  this.type = type;
  this.id = id;
  this.dataType = dataType;
  this.key = this.id;
  this.nodeClass = "";
  this.children = children || [];
}

function getCardLibraryList(list) {
  let libraryList = [];
  for (let cardTypeItem of list) {
    if (!cardTypeItem.children) {
      continue;
    }
    for (let groupItem of cardTypeItem.children) {
      libraryList.push(...(groupItem.children || []))
    }
  }
  return libraryList;
}

export {
  RDIMM,
  SODIMM,
  UDIMM,
  getCardLibraryTree,
  getCardLibraryList
}