import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import { Select, Input, Button } from "antd";
import Panel from '../Panel';
import NetListModel from '../NetListModel';
import UnitAddonAfter from '../UnitAddonAfter';
import { checkRLCValue } from '@/services/helper/dataProcess';
import { splitRLCValue } from '@/services/helper/rlcValueSplit';
import { getRLCInputKey } from '@/services/helper/getRLCInputKey';
import TagsInput from '@/components/TagsInput'
import {
  RLCModelInfo,
  RLCTypeList,
  defaultRLCValue,
  PassiveModel,
  getModelTypeName
} from '@/services/RLCModel';
import '../../publicCss/style.css';
import '../../publicCss/aurora.css';
import './index.css';
import { CAP, IND, RES } from '../../constants/componentType';


const TOUCHSTONE = "touchstone", SPICE = "spice", VALUE = "value", VIRTUAL_PASSIVE = "virtualPassive";
const Option = Select.Option;

class RLCModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: new RLCModelInfo({ modelType: VALUE }),
      rlc: { r: '0', l: '0', c: '0.1' },
      unit: { r: 'Ω', l: 'n', c: 'n' },
      subcktList: [],
      pins: [],
      R_values: []
    };
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
    this.base_SingleValue = false
  }

  componentWillMount() {
    const { record, isSweepBase, passivePins = [] } = this.props;
    const { comps } = record;
    const model = record.model ? JSON.parse(JSON.stringify(record.model)) : new RLCModelInfo({ modelType: "value" });
    if (isSweepBase) {
      this.base_SingleValue = record.subType === "virtualPassive" || !Object.keys(model.value).includes('c');
      this.setState({
        R_values: [record.value]
      })
    }

    let pins = [];
    if (!passivePins || passivePins.length === 0) {
      for (const comp of comps) {
        const _pins = comp && comp.pins ? comp.pins : []
        pins = [...pins, ...(_pins.map(item => item.pin))];
      }
    } else {
      pins = passivePins
    }
    let _pins = [...(new Set(pins))];
    const valueObj = model.value;
    let rlcResult = splitRLCValue({ R: valueObj.r || "", L: valueObj.l || "", C: valueObj.c || "" });
    rlcResult = Object.assign(defaultRLCValue, rlcResult);
    this.setState({
      rlc: { r: rlcResult.R.value, l: rlcResult.L.value, c: rlcResult.C.value },
      unit: { r: rlcResult.R.unit, l: rlcResult.L.unit, c: rlcResult.C.unit },
      pins: _pins.sort(),
      model
    })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.sweepCreateStatus === true && this.props.sweepCreateStatus === false) {
      this.props.save()
    }
  }

  closeModal = () => {
    const { rlc, unit } = this.state;
    const { record, isPassiveTable } = this.props;
    let model = { ...this.state.model };
    // get model data
    let modelInfo = {};
    if (this.modelChild) {
      modelInfo = this.modelChild.getNetListModel();
    }

    let _unit = "";
    if (model.type === VALUE) {
      if (isPassiveTable && record.type !== CAP) {
        model.value = {
          r: rlc.r,
          l: rlc.l,
          c: rlc.c
        };
      } else {
        model.value = {
          r: checkRLCValue(rlc.r + (unit.r === 'Ω' || parseFloat(rlc.r) === 0 ? '' : unit.r)),
          l: checkRLCValue(rlc.l + (parseFloat(rlc.l) === 0 ? "" : unit.l)),
          c: checkRLCValue(rlc.c + (parseFloat(rlc.c) === 0 ? "" : unit.c))
        };
      }

      model = new RLCModelInfo({ modelType: VALUE, value: { ...model.value } });
    } else {
      model.passiveModel = { ...modelInfo, referenceNet: model.passiveModel.referenceNet };
      if (isPassiveTable && record.type !== CAP) {
        model.value = {
          r: rlc.r,
          l: rlc.l,
          c: rlc.c
        };
      }
    }

    if (record.type === RES && isPassiveTable) {
      _unit = unit.r === 'Ω' || parseFloat(rlc.r) === 0 ? '' : (unit.r ? unit.r.replace("Ω", "") : "")
    }
    if (record.type === IND && isPassiveTable) {
      _unit = parseFloat(rlc.l) === 0 ? "" : (unit.l ? unit.l.replace("H", "") : "")
    }
    this.props.saveRLCModel(model, this.props.record, _unit);
    this.props.save();
  }

  selectModelType = (key) => {
    this.setState({
      model: {
        ...this.state.model,
        type: key,
        passiveModel: new PassiveModel({})
      }
    }, () => {
      // reset model data
      if (this.modelChild) {
        this.modelChild.resetModel();
      }
    })
  }

  changeRLCValue(e, type, _unit) {
    const { rlc, unit } = this.state;
    let _rlc_ = { ...rlc }, _unit_ = { ...unit };
    if (e !== null) {
      _rlc_[type] = e.target.value;
    }
    if (_unit !== null) {
      _unit_[type] = _unit;
    }
    this.setState({
      rlc: _rlc_,
      unit: _unit_
    })
  }

  RLCBlur = (e, type) => {
    const rlc = { ...this.state.rlc };
    if (!this.base_SingleValue) {
      rlc[type] = checkRLCValue(e.target.value);
      this.setState({
        rlc
      })
    }
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  getRLCInputList = (type) => {
    const { rlc, unit } = this.state;
    const { record } = this.props;
    const arr = getRLCInputKey(type, this.props.product, record.subType).map(item => ({
      inputVal: rlc[item.key],
      selectVal: unit[item.key],
      placeholder: item.placeholder,
      units: item.units,
      type: item.key
    }))
    return this.getRLCInputComponent(arr);
  }

  addonAfter = (item) => {
    return UnitAddonAfter({
      unit: item.selectVal,
      changeUnit: (key) => this.changeRLCValue(null, item.type, key),
      list: item.units
    })
  }

  changeR_values = (type, list) => {
    this.setState({
      R_values: list.map(item => checkRLCValue(item.replace(/ /, '').replace(/Ω/, '')))
    })
  }

  getRLCInputComponent(arr) {
    const { R_values } = this.state
    return arr.map(item => this.base_SingleValue ?
      <TagsInput
        tagList={R_values}
        className={'rlc-value-tagsInput'}
        allowedDuplicates={true}
        changeTagList={this.changeR_values}
        key={item.type}
        addonAfter={this.addonAfter(item)}
        unit={item.selectVal}
        unitOptions={item.units}
        isAddonAfter={true}
      ></TagsInput>
      :
      <Input
        addonAfter={this.addonAfter(item)}
        key={item.type}
        style={{ marginBottom: '10px' }}
        value={item.inputVal}
        placeholder={item.placeholder}
        onChange={(e) => this.changeRLCValue(e, item.type, null)}
        onBlur={(e) => this.RLCBlur(e, item.type)}
      />
    )
  }

  selectPackageReferenceNets = (key) => {
    let model = { ...this.state.model };
    model.passiveModel = {
      ...model.passiveModel,
      referenceNet: key ? key : ""
    }
    this.setState({
      model
    })
  }

  createExpsByPassive = () => {
    const { R_values } = this.state
    const { createExpsByPassive, record, isSweepBase, updateCreateStatus } = this.props
    if (isSweepBase) {
      const multipleValues = R_values.filter(item => item !== record.value)
      createExpsByPassive(multipleValues, record)
    }
    updateCreateStatus(true)
  }

  renderDialog() {
    const { model, pins } = this.state;
    const modelType = getModelTypeName(model.type);
    const { spiceList, touchstoneList, powerNets, product, record, getFileContent, parseModelSelector, getPopupContainerId, title, sweepCreateStatus, isPassiveTable, hideRefNet } = this.props;
    const content = (
      <Panel
        className='rlc-model-panel'
        title={<div className='rlc-model-title'>{title ? title : record.part}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={500}
        minWidth={450}
        minHeight={200}
        position='panel-center'
        draggable
      >
        <div className='rlc-model-content'>
          <div className='rlc-model-row'>
            <span>Model Type</span>
            <Select
              placeholder='Type'
              value={model.type || undefined}
              onChange={this.selectModelType}
              className='rlc-model-selection'
              popupClassName='rlc-model-select-dropdown'
              showSearch
              popupMatchSelectWidth={false}
              getPopupContainer={() => document.getElementById('root')}
            >
              {RLCTypeList.map(item => <Option
                key={item.key}
                value={item.key}
                title={item.title}
                disabled={item.key !== "value" && record.subType === VIRTUAL_PASSIVE}
              >{item.title}</Option>)}
            </Select>
          </div>
          {
            model.type === VALUE && <div className='rlc-model-row'>
              <span style={{ alignSelf: 'flex-start' }}>Value</span>
              <div>
                {this.getRLCInputList(record.type)}
              </div>
            </div>
          }
          {
            (model.type === TOUCHSTONE || model.type === SPICE) && <NetListModel
              onRef={this.onRef}
              spiceList={spiceList}
              touchstoneList={touchstoneList}
              getPopupContainerId={getPopupContainerId}
              pins={pins}
              product={product}
              modelType={modelType}
              model={{ type: modelType, ...model.passiveModel }}
              getFileContent={getFileContent}
              parseModelSelector={parseModelSelector}>
            </NetListModel>
          }
          {model.type === TOUCHSTONE && !isPassiveTable && !hideRefNet ? <div className='rlc-model-row'>
            <span className='margin-top-10 display-inline-block'>Reference Net</span>
            <Select
              placeholder='Auto'
              value={model.passiveModel.referenceNet || undefined}
              onChange={this.selectPackageReferenceNets}
              className={'aurora-select margin-top-10'}
              popupClassName='aurora-select-dropdown'
              showSearch
              allowClear={true}
              popupMatchSelectWidth={false}
              getPopupContainer={() => document.getElementById('root')}
            >
              {powerNets.map(item => <Option
                key={item}
                value={item}
                title={item}
              >{item}</Option>)}
            </Select>
          </div> : null}

          {
            this.base_SingleValue && <div className='rlc-sweep-create'>
              <Button type="primary" className='rlc-sweep-create-btn' loading={sweepCreateStatus} onClick={() => this.createExpsByPassive()}>Create</Button>
            </div>
          }
        </div>
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { inputRef } = this;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {this.props.text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default RLCModel;