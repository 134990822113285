import Plot2D from "../../../Result/Public/waveform/Plot2D";

class CapacitancePlot {
  constructor() {
    this.plot = null;
  }

  getCurves() {
    if (this.plot && this.plot.curves) {
      return this.plot.curves;
    }
  }

  getCurve(curveName) {
    if (this.plot && this.plot.curves) {
      return this.plot.curves.find(item => item.name === curveName);
    }
    return null;
  }

  updateCurve = (curveName, curve) => {
    if (!this.plot) {
      return;
    }
    const index = (this.plot.curves || []).findIndex(item => item.name === curveName);
    if (index > -1) {
      this.plot.curves[index] = curve;
    }
  }

  /** 
 *  Draw the capacitance curves
 *  @param  svgElement   - the SVG element where the plot is added
 *  @param  curveData 
 *  @return a capacitance.Plot object with a handle to the plot
 */
  plotCapacitance = ({ svgElement, events, axis, id = null, padding = null }) => {
    if (this.plot === null) {
      const { PlotOptions } = Plot2D;
      // plot options
      const plotOptions = new PlotOptions();
      plotOptions.xUnit = axis.xUnit || 'Hz';
      plotOptions.yUnit = axis.yUnit || "F";
      plotOptions.ui = 1;
      plotOptions.grid = 'xy';
      plotOptions.zoom = 'x';
      plotOptions.xLabel = axis.xLabel || 'Frequency';
      plotOptions.yLabel = axis.yLabel || "Capacitance";
      this.plot = new Plot2D(svgElement, plotOptions, events, id);
      /*    const { frequencies, capacitanceData, signals } = data; */
      // prepare the curve data
      let curves = [];
      // display the curves
      this.plot.type = id;
      this.plot.scale = { x: axis.xScale || "linear", y: axis.yScale || "linear" };
      this.plot.plotCurves(curves, false, padding);
    } else {
      this.plot.reSetEvents(events)
      this.plot.redrawPlot(svgElement);
    }

    return this.plot;
  }


  updateCurvePlot = () => {
    this.plot && this.plot.updatePlot();
  }

  plotCurves = (curves = []) => {
    this.plot && this.plot.plotCurves(curves, true);
  }

  setCurve = ({ name, x, y, visible = false, color }) => {
    const { CurveItem } = Plot2D;
    const curve = new CurveItem({
      name,
      x,
      y,
      visible,
      color
    })
    curve.name = name;
    curve.x = x || [];
    curve.y = y || [];
    curve.visible = visible || false;
    curve.color = color;
    return curve;
  }

  cleanPlot = () => {
    this.plot = null;
  }
};

const capacitancePlot = new CapacitancePlot();
export default capacitancePlot;
export { CapacitancePlot }