
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import SPIMPanel from '../LibraryPanel/SPIMPanel';
import VRMPanel from '../LibraryPanel/VRMPanel';
import DecapPanel from '../LibraryPanel/DecapPanel';
import PackagePanel from '../Package';
import DecapFile from '../LibraryPanel/decapFileMsg';
import {
  createProjectPromise, deleteProjectPromise,
  createPDNPromise,
  PDNInterface, VRM, deletePDNsPromise,
  savePDNPackage, deletePackageChipPromise,
  deleteDesignInProject,
  changeProjectName
} from '@/services/PDN';
import { delLibraryList, getLibraryData } from '@/services/PDN/library';
import { saveLibraryData } from '@/services/PDN/library/libraryData';
import { checkFileName } from '@/services/helper/nameHelper';
import { changeLibraryFormat } from '@/services/helper/rlcValueFormat';
import {
  changeView, saveOpenProjectInfo, updateLibraryMenu, updateProjectMenu, projectMenu, openProject, showPdnResult,
  clearCurrentProject, updateTrashMenu, changeTreeSelected, changeViewList,
  removeCurrentPreparingPDN, delDefaultDecap, createAllPDN, afterImportLibrary, getLayoutErrorResult
} from '../store/project/action';
import { getPDNContent, expandMenu, updatePackageInfoInPDNContent, saveCurrentPDN, saveMainNets, createCopyDecap } from '../store/pdn/action';
import { openTabFooter, closeTabFooter, changeTabMenu, /* changePdn, */ changeVerification, cleanTabMonitorMsg } from '../../tabMonitor/action';
import { startPDNVerification, changeVerificationList, pdnCheckError, pdnStackupCheckError, updateLibraryDataCheck } from '../store/simulation/action';
import PDNTree from './pdnTree';
import '../pdn.css';
import {
  LIBRARY, PROJECTS, PROJECT, IntelSPIM_FILE,
  VRM_DATA, DECAP_DATA, DECAP_FILE,
  Packages, PDNs, PCB, Package, PDN,
  PDN_CREATE, TRASH,
  EXTRACTION_PCB_MODEL, CREATE_PDN, UPDATE_PDN, CREATE_UPDATE, DECAP_TOUCHSTONE
} from '../constants';
import LayoutData from '@/services/data/LayoutData';
import { cleanupTrash } from '@/services/api/v2/projectCtrl';
import { getVRMModelUpdate } from '../store/simulation/action'
import { FASTPI_SETUP_VERSION } from '../../../version';
import VRMData from '@/services/PDN/library/getVRMData';
import DecapData from '@/services/PDN/library/getDecapData';
import PowerDomainFile from '../LibraryPanel/powerDomainFile';
import { getDefaultName } from '@/services/helper/setDefaultName';
import ShowModelsPanel from '../LibraryPanel/ShowModelsPanel';
import { strDelimited } from '@/services/helper/split';
import projectDesigns from '@/services/helper/projectDesigns';
import { SPIM_UNAVAILABLE } from '@/constants/librarySPIMStatus'
import DecapTouchstoneFile from '../LibraryPanel/DecapTouchstoneFile'
import { detach } from 'redux-saga';
import { getLibraryDecapFile } from '@/services/PDN/library'
import { changeDisableStatus, closeUploadProgressModal } from './Upload/action'
import ImportExportPanel from '../../../components/ImportExport';


message.config({
  duration: 2,
  maxCount: 3,
});

class PdnSider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      modelType: null,
      libraryData: null,
      loading: false,
      defaultVRMName: null,
      defaultDecapName: null,
      repeatedName: [],
      decapReName: '',
      errorName: [],
      errorMsg: '',
      decapFileInfo: {},
      inputPDNName: '',
      autoFocus: false,
      powerdomainFileInfo: null,
      decapTouchstoneFileInfo: {},
      decapTouchstoneinfo: '',
      uploading: {
        key: '',
        status: false
      },
      importExportModelVisible: true
    }
  }

  addItem = (e, key, dataType, item) => {
    e.stopPropagation();
    switch (dataType) {
      case LIBRARY:
        this.addLibrary(key);
        break;
      case PROJECTS:
        this.closePackagePanel();
        break;
      case PDNs:
        this.addPDN(key);
        break;
      case Packages:
        this.addPackages(item);
        break;
      default: return;
    };
  }

  addPackages = (item) => {
    const { currentProjectId } = this.props;
    if (projectDesigns.getAvailableDesignsLength(currentProjectId) === 0) {
      message.info('Please upload PCB first!');
      return;
    }
    if (item.iconDisabled) {
      return;
    } else {
      this.setState({
        editVisible: true,
        modelType: 'Package',
        packagesKey: item.key
      });
    }
  }

  addLibrary = (key) => {
    let defaultDecapName = '', defaultVRMName = '';
    if (key === 'VRM') {
      const { VRMNames } = this.props;
      defaultVRMName = getDefaultName({ nameList: VRMNames, defaultKey: 'VRM' });
    } else if (key === 'decap') {
      const { DecapNames } = this.props;
      defaultDecapName = getDefaultName({ nameList: DecapNames, defaultKey: 'Decap' });
    } else if (key === 'RLC') {
      const { DecapNames } = this.props;
      const _DecapNames = DecapNames.filter(item => item.dataType === 'decap_data')
      defaultDecapName = getDefaultName({ nameList: _DecapNames, defaultKey: 'Decap' });
    }
    this.setState({
      editVisible: true,
      modelType: key,
      defaultDecapName: defaultDecapName,
      defaultVRMName: defaultVRMName,
      libraryData: null
    });
  }

  PDNNameChange = (value) => {
    this.setState({
      inputPDNName: value
    })
  }

  addPDN = (key) => {
    let { treeItems, currentProjectPDNs, currentProjectPackages, currentProjectId,
      SPIMNames } = this.props;
    const [projectId] = strDelimited(key, "-", { startSubscript: 1 });
    const ProjectList = treeItems[1].children;
    const projectIndex = ProjectList.findIndex(item => item.id === projectId);
    // If project dose not exsit PCB, can not create PDN

    if (projectDesigns.getAvailableDesignsLength(currentProjectId) === 0) {
      message.info('Please upload PCB first!');
      return;
    }

    if (!currentProjectPackages.length) {
      message.info('Please setup package first!');
      return;
    }

    const designId = projectDesigns.getAvailableDesignsFirstId(currentProjectId)

    const libraryId = currentProjectPackages[0].libraryId;
    const libraryInfo = SPIMNames.find(item => item.id === libraryId);
    if (!EXTRACTION_PCB_MODEL) {
      if (!libraryInfo) {
        message.error(`${currentProjectPackages[0].model} does not exist in the library!`);
        return;
      }
    }
    let PowerNets = libraryInfo ? (libraryInfo.children || [{ name: libraryInfo.name }]) : [];

    const existPDNs = currentProjectPDNs.map(item => item.name);
    PowerNets = PowerNets.filter(item => !existPDNs.includes(item.name));
    PowerNets = PowerNets.filter(item => item.status !== SPIM_UNAVAILABLE)
    if (libraryInfo && (!PowerNets || (PowerNets && PowerNets.length === 0))) {
      message.error('PDN: All power rails available in the SPIM have been added.');
      return;
    }

    let hasCreatedPDN = currentProjectPDNs.filter(item => item.dataType === PDN_CREATE);
    if (hasCreatedPDN && hasCreatedPDN.length > 0) {
      message.error('PDN: Please complete the selection of the created power domain first!');
      return;
    }

    if (PowerNets.length === 1
      && currentProjectPDNs.length === 1
      && PowerNets[0].name === currentProjectPDNs[0].name) {
      message.error('PDN:' + PowerNets[0].name + ' already exists!');
      return;
    }

    // children[2].children: PDN list
    const name = getDefaultName({ nameList: currentProjectPDNs, defaultKey: "PDN" });
    treeItems[1].children[projectIndex].children[2].children.push(
      {
        id: name,
        name: name,
        key: `${PDN_CREATE}-${projectId}-${designId}`,
        dataType: PDN_CREATE,
        nodeClass: 'pdn-create-node'
      }
    );
    this.props.projectMenu({ treeItems: [...treeItems] });
    let { expandedKeys } = this.props;
    const openPDNs = expandedKeys.find(item => item === `PDNs-${currentProjectId}`);
    if (!openPDNs) {
      expandedKeys.push(`PDNs-${currentProjectId}`);
    }
    this.props.expandMenu(expandedKeys);
    this.setState({
      autoFocus: true,
      inputPDNName: name
    });
  }

  changeErrorMsg = (msg) => {
    this.setState({
      errorMsg: msg
    })
  }

  closeLibraryPanel = () => {
    this.setState({
      editVisible: false
    })
  }

  checkLibraryList = (newList) => {
    // check children
    const addKey = arr => arr.map(item => ({
      ...item,
      children: !(item.dataType === "intelSPIM_file" || item.dataType === "decap_touchstone")
        ? addKey(item.children) : null
    }));
    let arr = addKey(newList.children);

    // check isDownload and value to export
    const isDownloadCheck = arr => arr.map(item => ({
      ...item,
      title: item.name,
      showTitle: item.name,
      value: item.children && item.children.length ? item.name : item.id,
      isDownload: item.children && item.children.length ? false : true,
      children: item.children && item.children.length
        ? isDownloadCheck(item.children) : null
    }))
    let _newList = isDownloadCheck(arr);
    // check file
    if (_newList.length) {
      let res = {
        title: 'All',
        value: 'All',
        key: 'All',
        typeName: "Library",
        isDownload: false,
        children: _newList,
      };
      return [res];
    } else {
      return [];
    }
  }

  openShowPanel = (showSPIMMessage, showLog = false) => {
    this.setState({
      editVisible: true,
      showSPIMMessage,
      modelType: 'showSPIMPanel',
      showLog
    })
  }

  changeUploading = (status) => {
    this.setState({
      uploading: {
        key: this.state.uploading.key,
        status
      }
    })
  }

  showImportExportModel = (visible) => {
    this.setState({
      importExportModelVisible: visible
    })
  }

  getPanel = (type) => {
    const { libraryData, loading, defaultVRMName, defaultDecapName, repeatedName, decapReName,
      errorName, errorMsg, decapFileInfo, powerdomainFileInfo, showSPIMMessage, decapTouchstoneFileInfo, decapTouchstoneinfo, showLog, importExportModelVisible } = this.state;
    const { currentProjectId, afterImportLibrary, projectList } = this.props
    const currentProjectDesigns = projectDesigns.getAvailableDesigns(currentProjectId)
    const { treeItems } = this.props;
    switch (type) {
      case 'SPIM': return <SPIMPanel
        title='SPIM'
        closeLibraryPanel={this.closeLibraryPanel}
        openShowPanel={this.openShowPanel}
        modelType={type}
      />;
      case 'VRM': return <VRMPanel
        title='VRM'
        modelType={type}
        closeModal={this.closeModal}
        libraryData={libraryData}
        loading={loading}
        changeLoading={this.changeLoading}
        defaultVRMName={defaultVRMName}
        errorMsg={errorMsg}
        changeErrorMsg={this.changeErrorMsg}
      />;
      case 'Touchstone':
      case 'SPICE':
      case "RLC": return <DecapPanel
        title='Decap'
        modelType={type}
        closeLibraryPanel={this.closeLibraryPanel}
        // TODO
        closeModal={this.closeModal}
        libraryData={libraryData}
        loading={loading}
        changeLoading={this.changeLoading}
        defaultDecapName={defaultDecapName}
        repeatedName={repeatedName}
        decapReName={decapReName}
        changeDecapName={this.changeDecapName}
        errorName={errorName}
        errorMsg={errorMsg}
        changeErrorMsg={this.changeErrorMsg}
      />;
      case 'Package':
        const { packageData } = this.state;
        return <PackagePanel
          root={this.dialogRoot}
          title='Package'
          closeModal={this.closeModal}
          currentProjectDesigns={currentProjectDesigns}
          intelSPIMList={treeItems && treeItems[0] && treeItems[0].children.length > 0 ? treeItems[0].children[0] : []}
          packageData={packageData}
        />;
      case 'decapFile': return <DecapFile
        closeModal={this.closeModal}
        decapFileInfo={decapFileInfo}
      />
      case 'showSPIMPanel':
        return <ShowModelsPanel
          closeModal={this.closeModal}
          showSPIMMessage={showSPIMMessage}
          showLog={showLog}
        />
      case 'powerdomainFile':
      case 'powerdomainTouchstone': return <PowerDomainFile
        closeModal={this.closeModal}
        powerdomainFileInfo={powerdomainFileInfo}
      />
      case 'decapTouchstone': return <DecapTouchstoneFile
        closeModal={this.closeModal}
        decapTouchstoneFileInfo={decapTouchstoneFileInfo}
        decapTouchstoneinfo={decapTouchstoneinfo}
      />
      case 'importExportLibrary':
      case 'importExportProject':
        return <ImportExportPanel
          product={PDN}
          title={type === 'importExportLibrary' ? 'Library' : 'Project'}
          closeModal={this.closeLibraryPanel}
          libraryList={this.checkLibraryList(treeItems[0])}
          clearCurrentProject={this.props.clearCurrentProject}
          importExportModelVisible={importExportModelVisible}
          projectList={projectList}
          afterImportLibrary={afterImportLibrary}
          changeViewList={changeViewList}
          closeTabFooter={closeTabFooter}
          updateProjectMenu={this.props.UpdateProjectMenu}
          changeUploading={this.changeUploading}
          showImportExportModel={this.showImportExportModel}
        />
      default: return;
    }
  }

  savePackage = async (data) => {
    this.setState({
      loading: false,
      editVisible: false,
      modelType: null,
    });
    const { currentProjectId, currentProjectPDNs, PDNID } = this.props;
    if (EXTRACTION_PCB_MODEL) {
      if (!data.chip) return;
    } else {
      if (!data.chip || !data.libraryId) return;
    }
    const param = {
      chip: data.chip,
      id: data.id,
      libraryId: data.libraryId,
      model: data.model,
      modelType: 'IntelSPIM',
      projectId: currentProjectId
    }
    const res = await savePDNPackage(param);
    const { pdn } = this.props;
    if (pdn && pdn.pdnInfo && pdn.pdnInfo.pdnContent) {
      this.props.updatePackageInfoInPDNContent(param);
    }
    const { packagesKey } = this.state;
    const { expandedKeys } = this.props;
    let keys = [...expandedKeys];
    const openPDNs = keys.find(item => item === packagesKey);
    if (!openPDNs) {
      keys.push(packagesKey);
    }
    this.props.expandMenu([...keys]);
    this.setState({
      packageData: {}
    })

    let pdnParams = {}
    if (data.createType === CREATE_UPDATE) {
      // change SPIM (update exist PDN and create new PDN)
      pdnParams = { PDNs: currentProjectPDNs, pkgInfo: res, SPIM: res.libraryId }
    } else if (data.createType === CREATE_PDN) {
      // change SPIM without exist PDN
      pdnParams = { pkgInfo: res }
    } else if (data.createType === UPDATE_PDN) {
      // change chip
      pdnParams = { PDNs: currentProjectPDNs, pkgInfo: res }
    }
    this.props.createAllPDN(pdnParams);
    this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  saveSPIM = (data) => {
    if (data.fileList && data.fileList.length > 0) {
      let file = data.fileList[0];
      //Judge the file name.
      const status = this.changeSPIMName([file.name], data.fileList);
      //Return true to save
      if (status) {
        this.saveLibraryData('SPIM', data);
      }
    } else {
      this.setState({
        editVisible: false,
        modelType: null,
        loading: false,
        errorMsg: ''
      })
    }
  }

  saveVRM = (data) => {
    if (!data.name) {
      this.setState({
        editVisible: false,
        modelType: null,
        loading: false,
        errorMsg: '',
        repeatedName: [],
      })
      return;
    }

    let newData = { ...data.VRMData };
    if (newData.key === 'create' && !newData.R && !newData.L) {
      this.setState({
        editVisible: false,
        modelType: null,
        loading: false,
        errorMsg: '',
        repeatedName: [],
      });
      return;
    }

    const { VRMNames } = this.props;
    const index = VRMNames.findIndex(item => item.name === data.name && item.id !== data.VRMData.key);
    if (typeof (index) !== 'boolean' && index > -1) {
      this.setState({
        loading: false,
        errorMsg: 'Model name already exists!'
      })
      return;
    }
    this.saveLibraryData('VRM', data);
  }

  saveDecap = (data) => {
    if (data.type === 'SPICE' || detach.type === 'Touchstone') {

      if (data.fileList && data.fileList.length > 0) {
        let fileList = data.fileList;
        const names = data.fileList.map(item => { return item.name });
        //Judge the file name.
        const status = this.changeDecapName(names, fileList);

        if (status) {
          this.saveLibraryData('decap', data);
        }
      } else {
        this.setState({
          editVisible: false,
          modelType: null,
          loading: false,
          repeatedName: [],
          errorMsg: ''
        })
        return;
      }
    } else {
      let newData = { ...data.DecapDataList };
      if (newData.key === 'create' && !newData.R && !newData.L && !newData.C) {
        this.setState({
          editVisible: false,
          modelType: null,
          loading: false,
          repeatedName: [],
          errorMsg: ''
        });
        return;
      }

      if (!data.name) {
        this.setState({
          editVisible: false,
          modelType: null,
          loading: false,
          repeatedName: [],
          errorMsg: ''
        })
        return;
      }
      const { DecapNames } = this.props;
      const index = DecapNames.findIndex(item => item.name === data.name && item.id !== data.DecapDataList.key);

      if (typeof (index) !== 'boolean' && index > -1) {
        this.setState({
          loading: false,
          errorMsg: 'Model name already exists!'
        })
        return;
      }
      this.saveLibraryData('decap', data);
    }
  }

  saveLibraryData = (type, data) => {
    const { expandedKeys } = this.props;
    let keys = [...expandedKeys];
    saveLibraryData(type, data).then(res => {
      this.props.UpdateLibraryMenu();
      if (!keys.includes(type)) {
        keys.push(type);
      }
      this.props.expandMenu([...keys]);
      if (type === 'VRM' && data.VRMData && data.VRMData.key !== 'create') {
        VRMData.removeContent(data.VRMData.key); // delete prev data
      } else if (type === 'decap' && data.DecapDataList && data.DecapDataList.key !== 'create') {
        DecapData.removeContent(data.DecapDataList.key); // delete prev data
      }
      if (type === 'VRM' || type === 'decap') {
        this.props.updateLibraryDataCheck({ dataType: type }); // update current pdn library data error check
      }
      this.setState({
        loading: false,
        editVisible: false,
        modelType: null,
        repeatedName: [],
        libraryData: null,
        errorMsg: ''
      })
    });
  }

  closeModal = (type, data) => {//type: SPIM/VRM/decap/Package
    this.setState({
      errorMsg: ''
    }, () => {
      switch (type) {
        case 'Package':
          this.savePackage(data);
          break;
        case 'SPIM':
          this.saveSPIM(data);
          break;
        case 'VRM':
          this.saveVRM(data);
          break;
        case 'decap':
          this.saveDecap(data);
          break;
        case 'decapFile':
          this.closeDecapFile();
          break;
        case 'powerdomainFile':
        case 'closeSPIMPanel':
          this.closePDNLibraryPanel()
          break;
        case 'powerdomainTouchstone':
          this.closePowerDomainFile();
          break;
        case 'decapTouchstone':
          this.closeDecapTouchstone();
          break;
        default: break;
      }
    });
  }

  changeLoading = () => {
    this.setState({
      loading: true
    })
  }

  repeatedNames = (names) => {
    let hash = {};
    for (var i in names) {
      if (hash[names[i]]) {
        return names[i];
      }
      hash[names[i]] = true;
    }
    return false;
  }

  changeDecapName = (Names, fileList) => {
    const { DecapNames } = this.props;
    let reName = [], nameSet = true;
    Names.forEach(item => {

      if (!item) {
        this.setState({
          loading: false,
          errorMsg: 'Model name is not set.'
        });
        nameSet = false;
        return;
      }

      const index = DecapNames.findIndex(i => i.name === item);
      //Whether the file has been uploaded
      if (typeof (index) !== 'boolean' && index > -1) {
        reName.push(item);
        this.setState({
          loading: false,
        });
      }
    });

    if (!nameSet) {
      return;
    }

    if (reName.length > 0) {
      this.setState({
        errorMsg: 'Model name already exists!',
        repeatedName: [...reName]
      });
      return;
    }
    // Whether the file name is duplicated.
    const rName = this.repeatedNames(Names);

    if (rName) {
      this.setState({
        decapReName: rName,
        loading: false,
        errorMsg: 'Model name cannot be repeated.'
      });
      return;
    }
    //Whether the file name is legal.
    const errorName = checkFileName(fileList);

    if (errorName && errorName.length > 0) {
      this.setState({
        loading: false,
        errorName: [...errorName],
        errorMsg: 'File names may only contain the following characters: numbers, letters, underscores, minus.'
      });
      return;
    }
    this.setState({
      errorMsg: '',
      repeatedName: [],
      errorName: [],
      decapReName: ''
    });
    return true;
  }

  changeSPIMName = (Names, fileList) => {

    if (Names.length < 1 && fileList.length < 1) {
      return;
    }
    const { SPIMNames } = this.props;

    if (!Names[0]) {
      this.setState({
        loading: false,
        errorMsg: 'Model name is not set.'
      });
      return;
    }
    ////Whether the file has been uploaded
    const index = SPIMNames.findIndex(item => item.name === Names[0]);

    if (typeof (index) !== 'boolean' && index > -1) {
      this.setState({
        loading: false,
        errorMsg: 'Model name already exists!'
      })
      return;
    }
    const errorName = checkFileName(fileList);
    //Whether the file name is legal.
    if (errorName && errorName.length > 0) {
      this.setState({
        loading: false,
        errorName: [...errorName],
        errorMsg: 'File names may only contain the following characters: numbers, letters, underscores, minus.'
      });
      return;
    }

    this.setState({
      errorMsg: '',
      errorName: []
    });
    return true;
  }


  delItem = (e, item) => {
    e.stopPropagation();
    const { dataType } = item;
    switch (dataType) {
      case PROJECT:
        this.deleteProject(item);
        break;
      case IntelSPIM_FILE:
      case VRM_DATA:
      case DECAP_DATA:
      case DECAP_FILE:
      case DECAP_TOUCHSTONE:
        this.deleteLibrary(item);
        break;
      case PDN:
        this.deletePDN(item);
        break;
      case PDNs:
        this.deletePDNs(item);
        break;
      case PCB:
        this.deletePCB(item);
        break;
      case Package:
        this.deletePackage(item);
        break;
      case TRASH:
        this.cleanupTrash();
        break;
      default: break;
    }
  }

  deletePackage = (item) => {
    const { id } = item;
    const { currentProjectId, PDNID } = this.props;
    deletePackageChipPromise([id]).then(() => {
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
      this.setState({
        packageData: {}
      });
    }, error => {
      message.error('Delete package failed! ' + error)
    })
  }

  deleteProject = (item) => {
    const { id } = item;
    let pcbId = null;
    const { currentProjectId, PDNID } = this.props;
    if (item.children && item.children.length > 0) {
      const pcbList = item.children.filter(item => item.name === 'PCB');
      if (pcbList[0].children && pcbList[0].children.length > 0) {
        pcbId = pcbList[0].children[0].id;
      }
    }
    if (pcbId) {
      LayoutData.cleanLayoutInfo(pcbId);
    }

    deleteProjectPromise([id]).then(res => {
      this.props.updateTrashMenu();
      if (currentProjectId === id) {
        this.props.UpdateProjectMenu();
        this.props.clearCurrentProject(true);
        this.props.cleanTabMonitorMsg();
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
      }
    });

    if (this.props.uploadProjectId === id) {
      this.props._changeDisableStatus(false)
      this.props._closeUploadProgressModal()
    }

  }

  deleteLibrary = (item) => {
    const { id } = item;
    let deLIds = [];
    deLIds.push(id);
    const { defaultDecap } = this.props;
    if (id === defaultDecap) {
      this.props.delDefaultDecap()
    }
    const { libraryData, decapFileInfo } = this.state;
    delLibraryList(deLIds).then(res => {
      this.props.UpdateLibraryMenu();
      if (libraryData && libraryData.id === id) {
        this.setState({
          libraryData: null,
          loading: false,
          editVisible: false,
          modelType: null,
          repeatedName: []
        })
      }
      if (decapFileInfo && decapFileInfo.id === id) {
        this.setState({
          decapFileInfo: null,
          loading: false,
          editVisible: false,
          modelType: null,
          repeatedName: []
        })
      }
    })
  }

  deletePDNs = (item) => {
    const { selectedKeys } = this.props;
    const ids = selectedKeys.map(select => select.id);
    this.deletePDNItem(ids)
  }

  deletePDN = (item) => {
    const { id } = item;
    this.deletePDNItem([id])
  };

  deletePDNItem = (ids) => {
    const { currentProjectId, PDNID, viewList } = this.props;
    deletePDNsPromise(ids).then(res => {
      /*  this.props.openProject(currentProjectId); */
      if (ids.includes(PDNID)) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });

        //Close current open pdn
        let list = viewList.filter(item => item !== 'PDN' && item !== 'result');
        this.props.changeViewList(list);

        this.props.clearCurrentProject();
        this.props.closeTabFooter();
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
      }
    });
  }

  deletePCB = (item) => {
    const { id } = item;
    const { currentProjectId, PDNID } = this.props;
    deleteDesignInProject(id).then(res => {
      // Update project menu list
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
      // Open current project
      /*  this.props.openProject(currentProjectId); */
      // Clear the right content page status & clean pcb state
      this.props.clearCurrentProject();
      //Close current open pdn and pcb
      this.props.changeViewList([])
      //close monitor box
      this.props.closeTabFooter();
    }, error => {
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
    })
  }

  editItem = (e, key, item) => {
    e.stopPropagation();
    const dataType = item.dataType;
    let modelType = null;
    switch (dataType) {
      case VRM_DATA: modelType = 'VRM'; break;
      case DECAP_DATA: modelType = 'RLC'; break;
      case Package:
        this.editPackage(key, item);
        return;
      default: return;
    }
    if (dataType === VRM_DATA || dataType === DECAP_DATA) {
      getLibraryData(item.id).then(res => {
        if (res.libraryData) {
          const data = res.libraryData;
          const libraryUnit = changeLibraryFormat(data);
          const libraryData = {
            name: res.name,
            data: libraryUnit.libraryData,
            id: res.id,
            RUnit: libraryUnit.RUnit,
            LUnit: libraryUnit.LUnit,
            CUnit: libraryUnit.CUnit,
          }
          this.setState({
            libraryData: libraryData
          })
        }

        this.setState({
          editVisible: true,
          modelType: modelType
        })
      })
    }
  }

  editPackage = (key, item) => {
    this.setState({
      editVisible: true,
      modelType: 'Package',
      packageData: {
        chip: item.chip,
        model: item.model,
        id: item.id,
        libraryId: item.libraryId
      }
    });
  }

  deleteCreateProject = (e, item) => {
    const { currentProjectId, PDNID } = this.props;
    this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
  };

  deletePDNCreate = (e, item) => {
    e.stopPropagation();
    const { currentProjectId, PDNID } = this.props;
    this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
  }

  createProject = (e, item) => {
    const { name } = item;
    const { projectList } = this.props;
    let _filter = projectList.filter(i => i.id !== item.id);
    let names = _filter.map(item => item.name);
    if (names.includes(name)) {
      message.error('Project:' + name + ' already exists!');
      return;
    }
    //clear currentProject info & explorer state
    this.props.clearCurrentProject(true);
    createProjectPromise(name).then(res => {
      if (res) {
        this.props.UpdateProjectMenu({ openProjectId: res.id });
      } else {
        this.props.UpdateProjectMenu();
      }
      this.setState({
        autoFocus: false,
        packageData: {}
      })
    }, error => {
      message.error('Create project failed! ' + error)
    });
  };

  openPCB = (designId) => {
    const { viewList, layout } = this.props;
    this.props.viewChange('PCB', { designId });
    this.props.getLayoutErrorResult(designId)
    let list = [];
    if (layout !== 3) {
      list = [...viewList, 'PCB'];
      list = new Set(list);
    } else {
      list = ['PCB'];
      this.props.closeTabFooter()
    }
    this.props.changeViewList(list);
  }

  createPDN = (value, item) => {
    const pdnName = value;
    const { currentProjectPDNs, treeSelectedKeys, viewList, layout, currentProjectId, PDNID, currentProjectPackages } = this.props;
    const libraryId = currentProjectPackages[0].libraryId;
    const _filterCurrentPDNs = currentProjectPDNs.filter(pdn => pdn.id !== item.id);
    const exsitPDN = _filterCurrentPDNs.map(item => item.name);
    const [projectId, designId] = strDelimited(item.key, "-", { startSubscript: 1 });
    if (projectId !== currentProjectId) {
      return;
    }
    if (exsitPDN.includes(pdnName)) {
      this.props.openProject(projectId);
      message.error('PDN:' + pdnName + ' already exists!');
      this.props.UpdateProjectMenu({ openProjectId: projectId, pdnId: PDNID });
      return;
    }
    let pdnContent = {}, pdnVersion;
    pdnContent = new PDNInterface(pdnName, true);
    pdnContent.VRM.push(new VRM());
    pdnVersion = FASTPI_SETUP_VERSION; // 0.2.0
    createPDNPromise({ designId, pdnContent, pdnName, projectId, pdnVersion, libraryId }).then(res => {
      this.props.UpdateProjectMenu({ openProjectId: projectId, pdnId: res.id, verificationName: res.name });
      /* this.props.openProject(projectId); */
      const { id, verificationId, name } = res;
      this.props.pdnCheckError(null);
      this.props.pdnStackupCheckError(null);
      this.props.viewChange('PDN', { verificationId, PDNID: id });
      let list = [...viewList, 'PDN'];
      list = [...new Set(list)];
      list = list.filter(item => item !== 'result');
      if (layout === 3) {
        list = ['PDN']
      }
      this.props.changeViewList(list);
      this.props.getPDNContent(id);
      this.props._getVRMModelUpdate(projectId, id);
      this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: projectId });
      this.props.changeVerification(name);
      this.props.openTabFooter();

      if (treeSelectedKeys.length > 0) {
        let newKeys = [];
        treeSelectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (key !== PDN && key !== 'result') {
            newKeys.push(item);
          }
        });
        newKeys.push(`${PDN}-${res.id}`);
        this.props.changeTreeSelected([...newKeys]);
      } else {
        this.props.changeTreeSelected([`PDN-${res.id}`])
      }
    })
  }

  openPDN = (PDNID, status) => {
    const { currentProjectPDNs, currentProjectId, viewList, layout } = this.props;
    const index = currentProjectPDNs.findIndex(item => item.id === PDNID);
    let pdnName = null, verificationId = null;
    if (typeof (index) !== 'boolean' && index > -1) {
      pdnName = currentProjectPDNs[index].name;
      verificationId = currentProjectPDNs[index].verificationId;
    }
    this.props.pdnCheckError(null);
    this.props.pdnStackupCheckError(null);
    this.props.viewChange('PDN', { verificationId, PDNID });
    let list = [...viewList, 'PDN'];
    list = [...new Set(list)];
    list = list.filter(item => item !== 'result');
    if (layout === 3) {
      list = ['PDN']
    }
    this.props.changeViewList(list);
    const { treeSelectedKeys } = this.props;
    if (status) {
      if (treeSelectedKeys.length > 0) {
        let newKeys = [];
        treeSelectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (key !== PDN && key !== 'result') {
            newKeys.push(item);
          }
        });
        newKeys.push(`${PDN}-${PDNID}`);
        this.props.changeTreeSelected([...newKeys]);
      } else {
        this.props.changeTreeSelected([`${PDN}-${PDNID}`])
      }
    }
    this.props.getPDNContent(PDNID);
    this.props._getVRMModelUpdate(currentProjectId, PDNID);
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId });
    this.props.changeVerification(pdnName);
    this.props.openTabFooter();
  }

  /* 
    changeTabFooter = () => {
      const { openTabFooter, closeTabFooter, tabVisible } = this.props;
      tabVisible ? closeTabFooter() : openTabFooter();
    } */

  showPdnResult = (id, verificationId) => {
    const { viewList, layout } = this.props;
    this.props.showPdnResult('result', { PDNID: id, verificationId: verificationId });
    let list = [...viewList, 'result'];
    list = [...new Set(list)];
    list = list.filter(item => item !== 'PDN');
    if (layout === 3) {
      list = ['result']
    }
    this.props.changeViewList(list);
    const { closeTabFooter, tabVisible, currentProjectId, currentProjectPDNs } = this.props;
    let currentPdn = currentProjectPDNs.find(item => item.verificationId === verificationId);
    if (currentPdn) {
      let pdnName = currentPdn.name;
      this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId, verificationName: pdnName });
    }

    if (tabVisible) {
      closeTabFooter();
    }
  }

  startVerification = (e) => {
    e && e.stopPropagation();
    const { selectedKeys, PDNID, treeSelectedKeys } = this.props;
    if (selectedKeys.length === 0) {
      message.error('Please select one PDN!');
      return;
    };
    if (selectedKeys.length > 1) {
      message.error('Multiple PDN simulations: Please submit one by one!');
      return;
    };
    if (selectedKeys.length === 1) {
      if (PDNID !== selectedKeys[0].id || treeSelectedKeys.includes(`result-${selectedKeys[0].id}-${selectedKeys[0].verificationId}`)) {
        this.openPDN(selectedKeys[0].id, true);
        this.props.startPDNVerification([selectedKeys[0].verificationId]);
      } else {
        //save current verification ,and do simulation
        this.props._saveCurrentPDN([selectedKeys[0].verificationId], selectedKeys[0].verificationId);
      }
    }
  }

  changePDNCheck = (e, key, pdn) => {
    e.stopPropagation();
    const { selectedKeys } = this.props;
    let keys = [...selectedKeys];
    const index = keys.findIndex(item => item.verificationId === pdn.verificationId);
    if (typeof (index) !== 'boolean' && index > -1) {
      if (e.target.checked) {
        keys.push({
          id: pdn.id,
          verificationId: pdn.verificationId
        })
      } else {
        keys.splice(index, 1)
      }
    } else {
      if (e.target.checked) {
        keys.push({
          id: pdn.id,
          verificationId: pdn.verificationId
        })
      }
    }
    this.props.changeVerificationList(keys);
  }

  cleanupTrash = () => {
    cleanupTrash('PDN').then(res => {
      this.props.updateTrashMenu();
    })
  }

  showDecapFile = (e, item) => {
    e.stopPropagation();
    this.setState({
      editVisible: true,
      modelType: 'decapFile',
      decapFileInfo: item
    });
  }

  closeDecapFile = () => {
    this.setState({
      editVisible: false,
      modelType: null
    });
  }

  closePDNLibraryPanel = () => {
    this.setState({
      editVisible: false,
      modelType: null,
    })
  }

  closePowerDomainFile = () => {
    this.setState({
      editVisible: false,
      modelType: null,
      powerdomainFileInfo: null
    });
  }

  closePackagePanel = () => {
    const { editVisible, modelType } = this.state;
    if (editVisible && modelType === "Package") {
      this.setState({
        editVisible: false,
        modelType: null,
        packageData: {}
      })
    }
  }

  showPowerDomainFile = (e, item) => {
    e.stopPropagation();
    this.setState({
      editVisible: true,
      modelType: 'powerdomainFile',
      powerdomainFileInfo: item
    });
  }

  showPowerDomainTouchstone = (e, item) => {
    e.stopPropagation();
    this.setState({
      editVisible: true,
      modelType: 'powerdomainTouchstone',
      powerdomainFileInfo: item
    });
  }

  showDecapTouchstone = (e, item) => {
    e.stopPropagation();
    getLibraryDecapFile(item.id, item.name).then((res) => {
      this.setState({
        editVisible: true,
        modelType: 'decapTouchstone',
        decapTouchstoneFileInfo: res,
        decapTouchstoneinfo: item.name
      })
    })
  }
  closeDecapTouchstone = (e, item) => {
    this.setState({
      editVisible: false,
      modelType: null,
      decapTouchstoneFileInfo: null,
      decapTouchstoneinfo: null
    })
  }

  SaveProjectName = (name, item) => {
    const { currentProjectId, PDNID, viewList } = this.props;
    changeProjectName(item.id, name).then(res => {
      if (PDNID && viewList.includes("PDN")) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID });
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
      }
    })
  }

  importExportData = (item) => {
    this.setState({
      uploading: {
        key: item.key,
        status: this.state.uploading.status
      }
    })
    let modelType = 'importExportLibrary';
    if (item.dataType === PROJECTS) {
      modelType = 'importExportProject'
    }
    this.setState({
      editVisible: true,
      modelType: modelType,
      modelName: modelType,
      updateLibrary: {}
    });
  }

  render() {
    const { editVisible, modelType, autoFocus, inputPDNName, uploading } = this.state;
    return (
      <Fragment>
        <PDNTree
          importExportData={this.importExportData}
          select={this.select}
          addItem={this.addItem}
          delItem={this.delItem}
          editItem={this.editItem}
          deleteCreateProject={this.deleteCreateProject}
          createProject={this.createProject}
          autoFocus={autoFocus}
          pkgDisabled={modelType === 'Package' ? true : false}
          openPCB={this.openPCB}
          createPDN={this.createPDN}
          openPDN={this.openPDN}
          startVerification={this.startVerification}
          showPdnResult={this.showPdnResult}
          inputPDNName={inputPDNName}
          PDNNameChange={this.PDNNameChange}
          changePDNCheck={this.changePDNCheck}
          showDecapFile={this.showDecapFile}
          deletePDNCreate={this.deletePDNCreate}
          closePackagePanel={this.closePackagePanel}
          showPowerDomainFile={this.showPowerDomainFile}
          showPowerDomainTouchstone={this.showPowerDomainTouchstone}
          SaveProjectName={this.SaveProjectName}
          showDecapTouchstone={this.showDecapTouchstone}
          openShowPanel={this.openShowPanel}
          uploading={uploading}
          showImportExportModel={this.showImportExportModel}
        />
        {editVisible && this.getPanel(modelType)}
      </Fragment>
    )
  }
};

const mapState = (state) => {
  const { PDNReducer } = state;
  const { project, pdn, simulationReducer: { selectedKeys, singleVerifyInfo }, PDNUploadReducer: { uploadProjectId } } = PDNReducer;
  const { tabVisible } = state.TabMonitorReducer;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  const { VRMNames = [], SPIMNames = [], DecapNames = [], treeItems,
    currentProjectId, currentProjectPDNs = [], currentProjectPackages = [], PDNID, treeSelectedKeys, projectList, viewList, layout, preparingPDN, isPreparing, defaultDecap } = project;
  return {
    tabVisible, defaultLeft, defaultTop,
    VRMNames,
    SPIMNames,
    DecapNames,
    treeItems,
    currentProjectId,
    currentProjectPDNs,
    currentProjectPackages,
    expandedKeys: pdn.expandedKeys,
    selectedKeys,
    PDNID,
    singleVerifyInfo,
    pdn,
    treeSelectedKeys,
    projectList,
    viewList,
    layout,
    preparingPDN,
    isPreparing,
    defaultDecap,
    uploadProjectId
  }
}

const mapDispatch = (dispatch) => ({
  afterImportLibrary() {
    dispatch(afterImportLibrary())
  },
  viewChange(view, info) {
    dispatch(changeView(view, info));
  },
  getPDNContent(PDNID) {
    dispatch(getPDNContent(PDNID));
  },
  openTabFooter() {
    dispatch(openTabFooter())
  },
  closeTabFooter() {
    dispatch(closeTabFooter())
  },
  saveOpenProjectInfo(info) {
    dispatch(saveOpenProjectInfo(info))
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId, verificationName }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId, verificationName }))
  },
  startPDNVerification(verificationIds) {
    dispatch(startPDNVerification(verificationIds))
  },
  changeVerificationList(selectedKeys) {
    dispatch(changeVerificationList(selectedKeys))
  },
  changeVerification(verificationName) {
    dispatch(changeVerification(verificationName));
  },
  UpdateLibraryMenu() {
    dispatch(updateLibraryMenu());
  },
  UpdateProjectMenu(obj) {
    dispatch(updateProjectMenu(obj));
  },
  projectMenu({ treeItems }) {
    dispatch(projectMenu({ treeItems }));
  },
  openProject(id) {
    dispatch(openProject(id));
  },
  expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  showPdnResult(view, info) {
    dispatch(showPdnResult(view, info))
  },
  clearCurrentProject(status) {
    dispatch(clearCurrentProject(status));
  },
  cleanTabMonitorMsg() {
    dispatch(cleanTabMonitorMsg());
  },
  updateTrashMenu() {
    dispatch(updateTrashMenu());
  },
  pdnCheckError(errorCheck) {
    dispatch(pdnCheckError(errorCheck))
  },
  pdnStackupCheckError(stackupCheck) {
    dispatch(pdnStackupCheckError(stackupCheck))
  },
  updatePackageInfoInPDNContent(param) {
    dispatch(updatePackageInfoInPDNContent(param));
  },
  changeTreeSelected(treeSelectedKeys) {
    dispatch(changeTreeSelected(treeSelectedKeys))
  },
  changeViewList(viewList, viewType) {
    dispatch(changeViewList(viewList, viewType))
  },
  _saveCurrentPDN(verificationIds, verificationId) {
    dispatch(saveCurrentPDN(verificationIds, verificationId))
  },
  updateLibraryDataCheck({ dataType }) {
    dispatch(updateLibraryDataCheck({ dataType }))
  },
  _getVRMModelUpdate(currentProjectId, PDNID) {
    dispatch(getVRMModelUpdate({ currentProjectId, PDNID }))
  },
  _saveMainNets(pwrNets, gndNets) {
    dispatch(saveMainNets(pwrNets, gndNets));
  },
  _createCopyDecap(pdnInfo) {
    dispatch(createCopyDecap(pdnInfo));
  },
  removePrepaer(id) {
    dispatch(removeCurrentPreparingPDN(id));
  },
  delDefaultDecap() {
    dispatch(delDefaultDecap());
  },
  createAllPDN({ PDNs, pkgInfo, SPIM }) {
    dispatch(createAllPDN({ PDNs, pkgInfo, SPIM }))
  },
  _changeDisableStatus(disable) {
    dispatch(changeDisableStatus(disable))
  },
  _closeUploadProgressModal() {
    dispatch(closeUploadProgressModal())
  },
  getLayoutErrorResult(designId) {
    dispatch(getLayoutErrorResult(designId))
  }
});

export default connect(mapState, mapDispatch)(PdnSider);