import {
  CLEAR_MULTI_SETUP_INFO,
  UPDATE_MULTI_SETUP_LOADING,
  UPDATE_MULTI_SETUP_PAGE_DATA,
  UPDATE_MULTI_SETUP_BUFFER_DATA,
  UPDATE_MULTI_SETUP_PASSIVE_DATA,
  APPLY_MULTI_BUFFER_USAGE_CHANGE,
  UPDATE_MULTI_SETUP_GROUP,
  CLEAR_BUFFER_DATA,
  UPDATE_MULTI_SETUP_SIM_ERROR_MSG,
  SAVE_COPY_STIMULUS,
  UPDATE_MULTI_SETUP_EXTRACTION,
  UPDATE_MULTI_SETUP_EXTRACTION_PORTS,
  UPDATE_PCB_LOADING_STATUS,
  UPDATE_MULTI_SETUP_EXTRACTION_STATUS,
  CHANGE_MULTI_SETUP_RESULT,
  CLEAR_PASSIVE_DATA,
  UPDATE_MULTI_SETUP_BUFFER_MODEL_LOADING,
  CLEAR_MULTI_SETUP_EXTRACTION,
  CLEAR_MULTI_SETUP_EXTRACTION_PORTS,
  UPDATE_MULTI_SETUP_EXTRACTION_PORTS_LOG,
  UPDATE_MULTI_SETUP_EXTRACTION_OPTIONS_LOG,
  UPDATE_MULTI_SETUP_EXTRACTION_APPLY,
  UPDATE_MULTI_SETUP_EXTRACTION_PCB_SELECT_STATUS
} from "./actionTypes";

const defaultState = {
  multiSetupGroup: "",
  setupData: {
    pageData: [],
    page: 1,
    pageSize: 20,
    pages: 1,
    allDataLength: 20,
    selectedInterfaces: [],
    verificationIds: [],
    loading: false,
  },
  bufferData: {
    pageData: [],
    page: 1,
    pageSize: 20,
    pages: 1,
    allDataLength: 20,
    usageApplyAll: false,
    loading: false
  },
  passiveData: {
    pageData: [],
    page: 1,
    pageSize: 20,
    pages: 1,
    allDataLength: 20,
    usageApplyAll: false,
    loading: false
  },
  simCheckLogs: [],
  checkLogGroup: "",
  extractionInfo: {},
  portsInfo: {},
  loadingPCB: false,
  isUpdateExtraction: false,
  modelAssignLoading: false,
  optionsStatus: false,
  portsStatus: false,
  extrOptionsApply: {},
  extrPortsApply: {},
  pcbSelectStatus: false
}

export const MultiInterfaceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_MULTI_SETUP_GROUP:
      return {
        ...state,
        multiSetupGroup: action.group
      }
    case UPDATE_MULTI_SETUP_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case CLEAR_MULTI_SETUP_INFO:
      return {
        ...defaultState,
        checkLogGroup: state.checkLogGroup,
        simCheckLogs: state.simCheckLogs,
        multiSetupResult: state.multiSetupResult
      }
    case UPDATE_MULTI_SETUP_PAGE_DATA:
      return {
        ...state,
        setupData: {
          ...state.setupData,
          ...action.setupData
        }
      }
    case UPDATE_MULTI_SETUP_BUFFER_DATA:
      return {
        ...state,
        bufferData: {
          ...state.bufferData,
          ...action.bufferData
        }
      }
    case UPDATE_MULTI_SETUP_PASSIVE_DATA:
      return {
        ...state,
        passiveData: {
          ...state.passiveData,
          ...action.passiveData
        }
      }
    case APPLY_MULTI_BUFFER_USAGE_CHANGE:
      return {
        ...state,
        bufferData: {
          ...state.bufferData,
          usageApplyAll: action.usageApplyAll
        }
      }
    case CLEAR_BUFFER_DATA:
      return {
        ...state,
        bufferData: {
          pageData: [],
          page: 1,
          pageSize: 20,
          pages: 1,
          allDataLength: 20,
          usageApplyAll: false,
          loading: false,
          verificationId: null
        }
      }
    case CLEAR_PASSIVE_DATA:
      return {
        ...state,
        passiveData: {
          pageData: [],
          page: 1,
          pageSize: 20,
          pages: 1,
          allDataLength: 20,
          usageApplyAll: false,
          loading: false
        }
      }
    case UPDATE_MULTI_SETUP_SIM_ERROR_MSG:
      return {
        ...state,
        simCheckLogs: action.logs,
        checkLogGroup: action.group ? action.group : state.checkLogGroup
      }
    case SAVE_COPY_STIMULUS:
      return {
        ...state,
        copyStimulus: action.copyStimulus
      }
    case UPDATE_MULTI_SETUP_EXTRACTION:
      return {
        ...state,
        extractionInfo: {
          ...state.extractionInfo,
          ...action.extractionInfo
        }
      }
    case CLEAR_MULTI_SETUP_EXTRACTION:
      return {
        ...state,
        extractionInfo: {}
      }
    case UPDATE_MULTI_SETUP_EXTRACTION_PORTS:
      return {
        ...state,
        portsInfo: {
          ...state.portsInfo,
          ...action.portsInfo
        }
      }
    case CLEAR_MULTI_SETUP_EXTRACTION_PORTS:
      return {
        ...state,
        portsInfo: {}
      }
    case UPDATE_PCB_LOADING_STATUS:
      return {
        ...state,
        loadingPCB: action.status
      }
    case UPDATE_MULTI_SETUP_EXTRACTION_STATUS:
      return {
        ...state,
        isUpdateExtraction: action.status
      }
    case CHANGE_MULTI_SETUP_RESULT:
      return {
        ...state,
        multiSetupResult: action.multiSetupResult
      }
    case UPDATE_MULTI_SETUP_BUFFER_MODEL_LOADING:
      return {
        ...state,
        modelAssignLoading: action.modelAssignLoading
      }
    case UPDATE_MULTI_SETUP_EXTRACTION_PORTS_LOG:
      return {
        ...state,
        applyPortsLog: action.logs,
        portsStatus: action.status !== undefined ? action.status : state.portsStatus
      }
    case UPDATE_MULTI_SETUP_EXTRACTION_OPTIONS_LOG:
      return {
        ...state,
        applyOptionsLog: action.logs,
        optionsStatus: action.status !== undefined ? action.status : state.optionsStatus
      }
    case UPDATE_MULTI_SETUP_EXTRACTION_APPLY:
      return {
        ...state,
        [action.applyType]: action.config
      }
    case UPDATE_MULTI_SETUP_EXTRACTION_PCB_SELECT_STATUS:
      return {
        ...state,
        pcbSelectStatus: action.status
      }
    default: return {
      ...state
    }
  }
}