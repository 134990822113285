import { getLibraryFileByPath } from '../../api/pdn/library';
import { getDecapTouchstoneCurvePromise } from '../library';
import ParameterData from '../../Result/Public/sparameter/parameterData';

function parseNpiFile(data) {
  let lineBuffer = data.match(/[^\r\n]+/g);
  if (!lineBuffer || lineBuffer.length < 2) {
    return {
      ports: [],
      freq: []
    }
  }
  let portNum = Number(lineBuffer[0].slice(5));
  let ports = [],
    frequency = [],
    endLine = portNum + 1;
  for (let i = 1; i < endLine; i++) {
    if (!lineBuffer[i]) continue;
    let words = lineBuffer[i].split(' ');
    let name = words[2];
    if (name.startsWith('Chip')) {
      name = name.slice(5, name.lastIndexOf('_'));
    } else if (name.startsWith('Vrm')) {
      name = name.slice(4, name.lastIndexOf('_')) + '(VRM)';
    }
    ports.push({
      index: Number(words[0]),
      impedance: Number(words[1]),
      name: name
    })
  }

  for (let i = portNum + 2, length = lineBuffer.length; i < length; i++) {
    frequency.push(Number(lineBuffer[i]))
  }

  return {
    ports,
    freq: frequency
  }
}
class DecapParameterData extends ParameterData {

  constructor(props) {
    super(props)
    this.id = props.name || null;
    this.name = props.name || null;
    this.ports = null;
    this.freq = null;
    this.matrix = null;
    this.libraryId = props.libraryId;
    this.fileName = null;
    this.initParseNpiFile(parseNpiFile);
    this.initGetNpiFileFn(getLibraryFileByPath);
  }

  // Overriding
  async getCurve({ col, row }) {
    return await getDecapTouchstoneCurvePromise({ libraryId: this.libraryId, cols: [col], rows: [row], path: `${this.fileName}/${this.fileName}.zam` })
  }
  /**
   * Get curve data
   *
   * @param param - s,y,z
   * @param value - am for amplitude, ​ph for phase​, re for real, im for imaginary
   * type - s/y/z + am/ph/re/im
   */
  _getCurveData = ({ row, col, param, value, fileIndex, fileName }) => {
    this.fileName = fileName;
    return this.getCurveData({ row, col, param, value, fileIndex });
  }
}

export default DecapParameterData;