import { TRACE, VIA } from "@/constants/libraryConstants";
import { LIBRARY_DATA, LIBRARY_FILE } from '@/constants/treeConstants';

const DATA_TYPES = [TRACE, VIA];
/**
 *
 *
 * @param {*} key
 * @param {*} { name, type, id } type - file|data
 */
function libraryItem(key, { name, type, id, libraryType, nodeClass, deletable = true }) {
  this.name = name;
  this.type = type;
  this.libraryType = libraryType;
  this.id = id;
  this.dataType = DATA_TYPES.includes(key) ? LIBRARY_DATA : LIBRARY_FILE;
  this.key = this.id + '-' + this.name;
  this.nodeClass = nodeClass;
  this.deletable = deletable;
}

/**
 * Get library array
 *
 * @param {String} key trace|via
 * @param {Array} dataArr [{ id: "",  name: "", type: "microstrip|stripline|via", userId: "" }]
 * @returns [libraryItem]
 */
function getLibraryArray(key, dataArr) {
  if (!Array.isArray(dataArr) || !dataArr.length) {
    return [];
  }
  const _arr = dataArr.map(item => new libraryItem(key, {
    name: item.name,
    type: key, //trace|via
    libraryType: item.type, // microstrip  / stripline / via
    id: item.id,
    nodeClass: 'tree-library-file',
    deletable: item.deletable
  }));
  return _arr;
}

export {
  getLibraryArray
}