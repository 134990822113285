import React, { Component } from 'react';
import TotalCapacitanceTable from './totalCapacitanceTable';
import { Input, Tabs, Tooltip, Spin } from "antd";
import { numberCheck, numberToScientific, unitConversion } from '../../../../../services/helper/dataProcess';
import { scaleKeys } from '../../../../../services/helper/constants';
import NP from 'number-precision';
import UnitAddonAfter from '../../../../../components/UnitAddonAfter';
import TotalCapacitanceCurve from './totalCapacitanceCurve';
import "../new.css";
import "./index.css";

const TABLE = "table", CURVE = "curve";
const Modes = [{
  name: 'Table',
  value: TABLE
}, {
  name: 'Curve',
  value: CURVE
}];
const defaultAxis = {
  ui: 1,
  xUnit: "Hz",
  yUnit: "F",
  xMin: "",
  xMax: "",
  yMin: "",
  yMax: "",
  xLabel: "Frequency",
  yLabel: "Capacitance",
  xScale: "linear",
  yScale: "linear"
}
class TotalCapacitance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequencyInput: props.frequency,
      displayMode: CURVE,
      portSelect: {},
      axis: {
        ...defaultAxis
      },
      reload: false,
      isUpdateCurve: false
    }
  }

  componentDidMount = () => {
    this.setState({
      frequencyInput: this.props.frequency
    })
  }

  componentDidUpdate = (prevProps) => {
    const { isUpdateData, frequency } = this.props;
    const _update = isUpdateData && isUpdateData !== prevProps.isUpdateData;
    if (_update) {
      this.setState({
        portSelect: {},
        axis: {
          ...defaultAxis
        },
        isUpdateCurve: true,
        reload: false,
        frequencyInput: frequency
      });
      this.props.updateStatus(false);
    }
  }

  addonAfter = (unit) => {
    return UnitAddonAfter({
      unit,
      changeUnit: (e) => this._changeFreqUnit(e),
      list: [{ key: "K", title: "KHz" }, { key: "M", title: "MHz" }, { key: "G", title: "GHz" }],
      className: "total-capacitance-unit-select"
    })
  }

  changeDisplayMode = (key) => {
    this.setState({
      displayMode: key,
      msg: "",
      reload: key === CURVE ? true : false,
      isUpdateCurve: false
    })
  }

  _changeFreqUnit = (unit) => {
    //range
    const { freqUnit } = this.props;
    const scale = unitConversion(scaleKeys[unit], scaleKeys[freqUnit]);
    const newValue = NP.strip(NP.times(this.props.frequency, scale));

    /*   const _scale = unitConversion(scaleKeys["Hz"], scaleKeys[unit]);
      const _value = NP.strip(NP.times(newValue, _scale));
      if (_value < 1e3 || _value > 1e9) {
        this.setState({
          msg: "Frequency should be between 1KHz ~ 1GHz."
        })
        this.clearMsg()
        return;
      } */
    this.props.changeFreqUnit(unit);
    this.props.updateFrequency(newValue, true);
    this.setState({
      msg: "",
      frequencyInput: newValue
    })
  }

  changeFrequency = (e) => {
    this.setState({
      frequencyInput: e.target.value,
      msg: ""
    })
  }

  frequencyUpdate = (e) => {
    const value = e.target.value;
    if (numberCheck(value)) {
      this.setState({
        frequencyInput: this.props.frequency
      })
      return
    }
    const { freqUnit, logSweepMin } = this.props;
    const min = isNaN(logSweepMin) ? 1e3 : logSweepMin;
    //range
    const scale = unitConversion(scaleKeys["Hz"], scaleKeys[freqUnit]);
    const newValue = NP.strip(NP.times(value, scale));
    if (newValue < min || newValue > 1e9) {
      const minValue = numberToScientific(min, 0, {
        1: '',
        1e3: 'K',
        1e6: 'M',
        1e9: 'G'
      })
      this.setState({
        msg: `Frequency should be between ${minValue}Hz ~ 1GHz.`,
        frequencyInput: this.props.frequency
      })
      this.clearMsg()
      return;
    }

    this.props.updateFrequency(value);
  }

  clearMsg = () => {
    setTimeout(() => {
      this.setState({
        msg: ""
      })
    }, 3000)
  }

  onKeyDown = (e) => {
    if (e.keyCode === 188) {
      this.frequencyUpdate(e);
    }
  }

  getDisplayModelMenu = () => {
    const { displayMode } = this.state;
    return <Tabs className='total-capacitance-tabs' activeKey={displayMode} onChange={(key) => this.changeDisplayMode(key)} items={Modes.map(item => ({ key: item.value, label: item.name }))} />

    /* return <DisplayMode
      displayModeChange={this.changeDisplayMode}
      displayMode={displayMode}
      Modes={Modes}
    /> */
  }

  updatePortSelect = (portSelect) => {
    this.setState({
      portSelect
    })
  }

  updateAxis = (axis) => {
    this.setState((prevState) => {
      return {
        axis: { ...prevState.axis, ...axis }
      }
    })
  }

  updateReloadStatus = (reload) => {
    this.setState({
      reload
    })
  }

  updateCurveStatus = (isUpdateCurve) => {
    this.setState({
      isUpdateCurve
    })
  }

  render() {
    const { designs, freqUnit, loading, verificationId, capacitancePlot, pathObj, isUpdateData } = this.props;
    const { frequencyInput, msg, displayMode, portSelect, axis, reload, isUpdateCurve } = this.state;

    return <div className='sierra-total-capacitance-main'>
      <div className={`sierra-total-capacitance-top-menu`}>
        {this.getDisplayModelMenu()}
      </div>
      {
        displayMode === TABLE ?
          <div className='sierra-total-capacitance-table-content'>
            <Spin spinning={loading} size='large'>
              <div className='sierra-total-capacitance-freq-content'>
                <span>Calculate capacitance @</span>
                <Tooltip
                  overlayClassName='aurora-error-msg-tooltip'
                  open={msg ? true : false}
                  title={msg ? <div className='aurora-error-msg-span'>{msg}</div> : ""}>
                  <Input
                    value={frequencyInput}
                    onChange={this.changeFrequency}
                    onBlur={this.frequencyUpdate}
                    onKeyDown={this.onKeyDown}
                    addonAfter={this.addonAfter(freqUnit)}
                    className='aurora-input'
                  />
                </Tooltip>
              </div>
              <div className='sierra-total-capacitance-table-main'>
                {(designs || []).map(item =>
                  <TotalCapacitanceTable
                    dataList={item.tableData || []}
                    itemKey={item.id}
                    title={item.name}
                    key={item.id}
                    isMulti={item.isMulti}
                    components={item.components}
                    hasSegment={item.hasSegment}
                    isUpdateData={isUpdateData}
                    updateStatus={this.props.updateStatus}
                    updateCurveStatus={this.updateCurveStatus}
                  />)}
              </div>
            </Spin>
          </div>
          : null
      }
      {
        displayMode === CURVE ?
          <TotalCapacitanceCurve
            loading={loading}
            designs={designs}
            pathObj={pathObj}
            verificationId={verificationId}
            capacitancePlot={capacitancePlot}
            portSelect={portSelect}
            axis={axis}
            isUpdateCurve={isUpdateCurve}
            reload={reload}
            updateCurveStatus={this.updateCurveStatus}
            updatePortSelect={this.updatePortSelect}
            updateAxis={this.updateAxis}
            updateReloadStatus={this.updateReloadStatus}
            updateDesignData={this.props.updateDesignData}
          />
          : null
      }
    </div>
  }
}

export default TotalCapacitance;