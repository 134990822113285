import { scaleConversion } from './numberHelper';
import NP from 'number-precision';

// 1.2, M -> 0.0000012M; 1.2M, M -> 1.2M; 1.2G, M -> 1200M
function decimalConversion(value, unit, format) {
  try {
    const match = value.match(/\D$/);
    let decimal, num;
    if (match) {
      decimal = match[0];
    }
    num = decimal ? value.slice(0, -(decimal.length)) : value;
    const scale = scaleConversion(unit, decimal);
    const newNum = NP.times(num, scale).toString()
    return format === 'num' ? newNum : newNum + unit;
  } catch (error) {
    return NaN;
  }
}

function settingUnit(param, defaultUnit) {
  let value, unit;
  // If param is a number, the number 1 is expressed as 1MHz.
  if (!isNaN(Number(param))) {
    return { value: param, unit: defaultUnit }
  } else {
    if (param.indexOf('e') > -1) {
      value = parseFloat(param).toExponential();
    } else {
      value = parseFloat(param);
    }
    unit = param.slice(-1);
    if ((unit && !isNaN(Number(unit))) || !unit) {
      // If the last character of param is still a number, set the default.
      unit = defaultUnit;
    }
    // If value is NaN
    if (isNaN(value)) {
      value = " ";
    }
    return { value, unit }
  }
}

export { decimalConversion, settingUnit };