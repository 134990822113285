import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import { CloseOutlined, PlusCircleOutlined, RetweetOutlined, SettingOutlined } from '@ant-design/icons';
import { Tooltip, Spin } from 'antd';
import {
  updateImpedanceStatus,
  reFindDomain,
  deletePowerDomain,
  saveVRM,
  updateIncludeExtended,
  addRow,
  updatePackageNets,
  saveTargetImpedance,
  savePackagePorts,
  updateDomainAfterUpdateNets,
  switchPackagePorts,
  saveBallSize
} from '../store/Impedance/action';
import { ImpPackageDomain } from '@/services/Cascade/Impedance';
import { selectChange, changeShowNameStatus, selectLayer } from '../../LayoutExplorer/store/Cascade/actionCreators';
import portSetup from '../components/portSetup';
import { NetGroupSelection } from '@/services/PCBHelper';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import BallSizePanel from '../components/ballSizePanel';
import TargetPanel from './targetPanel';
import { OVERVIEW } from '../../../services/Cascade/Impedance';
import designConstructor from '../../../services/helper/designConstructor';
import { saveStackupToServer } from '../../LayoutExplorer/components/Stackup_v1/store/actionCreators';
import { CASCADE } from '../../../constants/pageType';

const domainColumns = [
  {
    title: "Power Nets",
    dataIndex: "PowerNets",
    width: "25%"
  },
  {
    title: "Reference Nets",
    dataIndex: "ReferenceNets",
    width: "25%"
  },
  {
    title: "Die Ports",
    dataIndex: "diePorts",
    width: "25%"
  },
  {
    title: "BGA Ports",
    dataIndex: "bgaPorts",
    width: '25%'
  }
]

class PackageDomainTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      closeVRM: false,
      dieBallSize: false,
      bgaBallSize: false
    }
    this.columns = JSON.parse(JSON.stringify(domainColumns))
    this.netGroupSelection = new NetGroupSelection();
    this.tableDataList = [];
  }

  componentDidMount = () => {
    this.setDefaultColumns();
    this.getPowerDomainData(true)
  }

  componentDidUpdate = (prevProps) => {
    const { openProjectId, verificationId, status, noPCB, page } = this.props;
    const updateStatus = status && status !== prevProps.status;
    if ((openProjectId && openProjectId !== prevProps.openProjectId)
      || (verificationId && verificationId !== prevProps.verificationId)
      || updateStatus || page !== prevProps.page) {
      updateStatus && this.props.updateImpedanceStatus(false);
      this.getPowerDomainData(updateStatus ? false : true)
    }

    if (prevProps.noPCB !== noPCB) {
      this.setDefaultColumns();
    }
  }

  setDefaultColumns = () => {

    this.columns[2].title = () => {
      const toLumped = this.tableDataList.every(record => record.diePorts && record.diePorts.length);
      const { pcbId } = this.props;
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      return (
        <div>
          <span>Die Ports</span>
          {
            !isPreLayout && <Fragment>
              <Tooltip
                title={`Ball size setting.`}
                mouseLeaveDelay={0} mouseEnterDelay={0.3}
                overlayClassName='icon-tooltip'
              >
                <SettingOutlined
                  className="impedance-part-switch-icon impedance-package-ports-switch-icon"
                  onClick={(e) => this.openDieBallSize(e)} />
              </Tooltip>
              <Tooltip
                title={`Change the default setting of all die ports to ${toLumped ? 'Lumped' : 'Pin Group'}.`}
                mouseLeaveDelay={0} mouseEnterDelay={0.3}
                overlayClassName='icon-tooltip'
              >
                <RetweetOutlined
                  className="impedance-part-switch-icon impedance-package-ports-switch-icon"
                  onClick={(e) => this.changePortsSwitch(e, toLumped, 'DIE')} />
              </Tooltip>
            </Fragment>
          }
        </div>
      );
    }

    this.columns[3].title = () => {
      const toLumped = this.tableDataList.every(record => record.bgaPorts && record.bgaPorts.length);
      const { pcbId } = this.props;
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      return (
        <div>
          <span>BGA Ports</span>
          {
            !isPreLayout && <Fragment>
              <Tooltip
                title={`Ball size setting.`}
                mouseLeaveDelay={0} mouseEnterDelay={0.3}
                overlayClassName='icon-tooltip'
              >
                <SettingOutlined
                  className="impedance-part-switch-icon impedance-package-ports-switch-icon"
                  onClick={(e) => this.openBGABallSize(e)} />
              </Tooltip>
              <Tooltip
                title={`Change the default setting of all die ports to ${toLumped ? 'Lumped' : 'Pin Group'}.`}
                mouseLeaveDelay={0} mouseEnterDelay={0.3}
                overlayClassName='icon-tooltip'
              >
                <RetweetOutlined
                  className="impedance-part-switch-icon impedance-package-ports-switch-icon"
                  onClick={(e) => this.changePortsSwitch(e, toLumped, 'BGA')} />
              </Tooltip>
            </Fragment>
          }
        </div>
      );
    }

    this.columns[0].render = (text) => {
      return text ? text.join(', ') : '';
    }

    this.columns[1].render = (text) => {
      return text ? text.join(', ') : '';
    }

    this.columns[2].render = (text) => {
      return <div onClick={() => this.closePortsPanel()}>{text.length ? 'Pin Group' : 'Lumped'}</div>
    }

    this.columns[3].render = (text, record) => {
      return <div onClick={() => this.closePortsPanel()}>
        {text.length ? 'Pin Group' : 'Lumped'}
        <CloseOutlined
          className="impedance-delete-icon"
          onClick={(e) => this.deleteRow(e, record)} />
      </div>
    }

    this.columns[0].onCell = (record) => {
      const { nets, noPCB } = this.props;
      const filterNets = [];
      this.tableDataList.forEach(item => { filterNets.push(...item.PowerNets, ...item.ReferenceNets) });
      return !noPCB ? {
        record,
        edit: 'aurora-select',
        options: nets.filter(item => !filterNets.includes(item)),
        dataIndex: 'PowerNets',
        selectMode: 'multiple',
        onChange: (list, record, setFieldsValue) => this.selectNets(list, record, setFieldsValue, 'PowerNets', ['PowerNets', 'MAIN_POWER_NETS']),
        clearSelected: (value, record, setFieldsValue) => this.clearNets(value, record, setFieldsValue, 'PowerNets', ['PowerNets', 'MAIN_POWER_NETS']),
        auroraSelectBlur: (record) => this.saveNets(record),
        onFocus: () => this.select(record.PowerNets),
        loadingMsg: !nets || !nets.length ? 'Loading Nets...' : ''
      } : {
        edit: false
      }
    }

    this.columns[1].onCell = (record) => {
      const { nets, noPCB } = this.props;
      return !noPCB ? {
        record,
        edit: 'aurora-select',
        options: nets,
        dataIndex: 'ReferenceNets',
        selectMode: 'multiple',
        onChange: (list, record, setFieldsValue) => this.selectNets(list, record, setFieldsValue, 'ReferenceNets', ['ReferenceNets', 'MAIN_REF_NETS']),
        clearSelected: (value, record, setFieldsValue) => this.clearNets(value, record, setFieldsValue, 'ReferenceNets', ['ReferenceNets', 'MAIN_REF_NETS']),
        auroraSelectBlur: (record) => this.saveNets(record),
        onFocus: () => this.select(record.ReferenceNets),
        loadingMsg: !nets || !nets.length ? 'Loading Nets...' : ''
      } : {
        edit: false
      }
    }

    this.columns[2].onCell = (record) => {
      const { diePortVisible } = this.state;
      const { pcbId, customSpiceList } = this.props;
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      if (isPreLayout) {
        return {
          edit: false
        }
      }
      if (diePortVisible !== record.id) {
        return {
          edit: true,
          record: { ...record, diePorts: record.diePorts.length ? 'Pin Group' : 'Lumped' },
          dataIndex: 'diePort',
          tdClick: (e) => this.openTableCell(e, record, "diePort")
        }
      }
      const { noPCB, maxFreq } = this.props;
      const { id, diePorts = [], PowerNets, ReferenceNets, DIE } = record;
      const plocModelList = customSpiceList.filter(item => item.name.match(/.ploc$/i))
      return !noPCB ? {
        record,
        edit: true,
        customInput: portSetup,
        dataIndex: 'diePort',
        text: diePorts.length ? 'Pin Group' : 'Lumped',
        targetIC: DIE,
        pcbId,
        powerDomainId: id,
        ports: diePorts,
        maxFreq,
        savePorts: (id, ports) => this.updatePorts(id, ports, 'diePorts'),
        onPortsRef: this.onPortsRef,
        PowerNets: PowerNets,
        ReferenceNets,
        targetSetup: TargetPanel,
        plocModelList,
        plocSupport: true,
        closeColumnVisible: () => this.closeColumnVisible(record, "diePort"),
        autoMatch: true
      } : {
        edit: false
      }
    }

    this.columns[3].onCell = (record) => {
      const { bgaPortVisible } = this.state;
      const { pcbId, customSpiceList } = this.props;
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      if (isPreLayout) {
        return {
          edit: false
        }
      }
      if (bgaPortVisible !== record.id) {
        return {
          edit: true,
          record: { ...record, diePorts: record.bgaPorts.length ? 'Pin Group' : 'Lumped' },
          dataIndex: 'bgaPort',
          tdClick: (e) => this.openTableCell(e, record, "bgaPort")
        }
      }
      const { noPCB, maxFreq } = this.props;
      const { id, bgaPorts = [], PowerNets, ReferenceNets, BGA } = record;
      const plocModelList = customSpiceList.filter(item => item.name.match(/.ploc$/i))
      return !noPCB ? {
        record,
        edit: true,
        customInput: portSetup,
        dataIndex: 'bgaPort',
        text: bgaPorts.length ? 'Pin Group' : 'Lumped',
        targetIC: BGA,
        pcbId,
        powerDomainId: id,
        ports: bgaPorts,
        maxFreq,
        savePorts: (id, ports) => this.updatePorts(id, ports, 'bgaPorts'),
        onPortsRef: this.onPortsRef,
        PowerNets: PowerNets,
        ReferenceNets,
        targetSetup: TargetPanel,
        plocModelList,
        plocSupport: true,
        closeColumnVisible: () => this.closeColumnVisible(record, "bgaPort"),
        autoMatch: true
      } : {
        edit: false
      }
    }
  }

  openTableCell = (e, record, type) => {
    let visible = this.state[`${type}Visible`];

    if (visible === record.id) {
      return;
    }

    if (visible) {
      switch (type) {
        case "diePort":
        case "bgaPort":
          this.closePortsPanel();
          break;
        default: break;
      }
    }
    visible = record.id;

    this.setState({
      [`${type}Visible`]: visible
    })
  }

  getPowerDomainData = (mount = false) => {
    const { data } = this.props;
    let tableData = [];
    for (let row of data) {
      const { id, content } = row;
      const domainRow = new ImpPackageDomain({ id, ...content, getIC: true });
      tableData.push(domainRow);
    }
    if (mount) {
      this.tableDataList = tableData;
      this.props.updateImpedanceStatus(false);
    } else {
      this.tableDataList = tableData;
    }
  }

  stopClick = (e) => {
    e && e.preventDefault() && e.stopPropagation();
  }

  openDieBallSize = (e) => {
    this.stopClick(e);
    this.setState({
      dieBallSize: true
    })
  }

  openBGABallSize = (e) => {
    this.stopClick(e);
    this.setState({
      bgaBallSize: true
    })
  }

  closeBallSize = (props, isClose, type) => {
    const { pcbId } = this.props
    const { ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab } = props;
    if (isClose) {
      const stateType = type === 'DIE' ? 'dieBallSize' : 'bgaBallSize';
      this.setState({
        [stateType]: false
      })
    }
    this.props.saveBallSize(pcbId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab)
  }

  changePortsSwitch = (e, lumped, compType) => {
    this.stopClick(e)
    this.props.switchPackagePorts(lumped, compType)
  }

  addRow = (e) => {
    e && e.stopPropagation();
    const newRow = new ImpPackageDomain({ id: '' });
    this.tableDataList = [...this.tableDataList, newRow];
    this.props.addRow()
  }

  selectNets = (list, record, setFieldsValue, mainKey, keys) => {
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    const nets = {}
    for (let key of keys) {
      let netsList = record[key];
      if (list.length === 1 && netsList.find(net => net === list[0])) {
        netsList = netsList.filter(net => net !== list[0]);
      } else {
        netsList = [...new Set([...netsList, ...list])];
      }
      if (setFieldsValue && mainKey === key) {
        setFieldsValue({ [key]: netsList })
      }
      currentRow[key] = netsList;
      nets[key] = netsList;
    }
    this.tableDataList = newTableData;
    this.props.updatePackageNets(record.id, nets)
  }

  clearNets = (value, record, setFieldsValue, mainKey, keys) => {
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    const nets = {}
    for (let key of keys) {
      let netsList = record[key];
      netsList = netsList.filter(net => net !== value);
      if (setFieldsValue && mainKey === key) {
        setFieldsValue({ [key]: netsList })
      }
      currentRow[key] = netsList;
      nets[key] = netsList;
    }
    this.tableDataList = newTableData;
    this.props.updatePackageNets(record.id, nets)
  }

  saveNets = (record) => {
    this.props.updateDomainAfterUpdateNets(record.id)
  }

  deleteRow = (e, record) => {
    e.stopPropagation();
    const { id } = record;
    this.props.deletePowerDomain(id);
  }

  closeColumnVisible = (record, type) => {
    const visible = this.state[`${type}Visible`];
    this.setState({
      [`${type}Visible`]: visible === record.id ? null : visible
    })
  }

  updatePorts = (id, ports, portType) => {
    this.props.savePackagePorts(id, ports, portType);
  }

  select = (nets) => {
    const { pcbId, selectedDesignIDs } = this.props;
    if (selectedDesignIDs.includes(pcbId)) {
      this.props._selectChange({ nets }, pcbId)
      this.props._changeShowNameStatus(true, pcbId)
      setTimeout(() => {
        this.netGroupSelection.selectNetGroup({ nets: nets, isPower: true })
      }, 100);
    }
  }

  onPortsRef = (ref) => {
    this.portsRef = ref;
  }

  closePortsPanel = () => {
    this.portsRef && this.portsRef.closeModal();
  }

  render() {
    const { loading, ballSizeMap = {}, targetDie = [], bgas, pcbId } = this.props;
    const { dieBallSize, bgaBallSize } = this.state;
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color">Power Domains</span>
        {loading ? <Tooltip title={loading && 'Loading PCB...'} overlayClassName='aurora-tooltip'>
          <PlusCircleOutlined className='cascade-imp-icon cascade-imp-icon-disable' />
        </Tooltip> :
          <PlusCircleOutlined className='cascade-imp-icon' onClick={(e) => this.addRow(e)} />}
        <div className="space-10">
          <Spin spinning={loading ? true : false}>
            <EditableTable
              rowKey={(record) => record.id}
              columns={this.columns}
              size="small"
              dataSource={this.tableDataList}
              className="cascade-imp-table"
              tablePadding={true}
            />
          </Spin>
        </div>
        {
          dieBallSize &&
          <BallSizePanel
            data={ballSizeMap}
            title='Die Ball Size'
            closePanel={(props, isClose) => this.closeBallSize(props, isClose, 'DIE')}
            comps={targetDie}
            pcbId={pcbId}
            saveStackupToServer={this.props.saveStackupToServer}
            product={CASCADE}
          />
        }
        {
          bgaBallSize &&
          <BallSizePanel
            data={ballSizeMap}
            title='BGA Ball Size'
            closePanel={(props, isClose) => this.closeBallSize(props, isClose, 'BGA')}
            comps={bgas}
            pcbId={pcbId}
            saveStackupToServer={this.props.saveStackupToServer}
            product={CASCADE}
          />
        }
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, selectedKeys },
    library: { VRMList, customSpiceList },
    Impedance: { designId, verificationId, loading, targetIC, data = [], status, Config = {}, page, targetDie } } } = state
  const layout = data.find(item => item.designId === page);
  return {
    loading,
    openProjectId,
    verificationId,
    targetIC,
    page,
    data: layout ? layout.powerDomains || [] : [],
    status,
    VRMList,
    pcbId: page === OVERVIEW ? designId : page,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    maxFreq: Config ? (Config.FMAX || null) : null,
    ballSizeMap: layout ? layout.ballSizeMap : {},
    targetDie,
    bgas: layout ? layout.Bgas.map(item => item.name) : [],
    customSpiceList
  };
}

const mapDispatch = (dispatch) => ({
  updateImpedanceStatus(status) {
    dispatch(updateImpedanceStatus(status))
  },
  reFindDomain(record) {
    dispatch(reFindDomain(record))
  },
  deletePowerDomain(id) {
    dispatch(deletePowerDomain(id))
  },
  saveVRM(params, key) {
    dispatch(saveVRM(params, key))
  },
  updateIncludeExtended(checked, id) {
    dispatch(updateIncludeExtended(checked, id))
  },
  addRow() {
    dispatch(addRow())
  },
  updatePackageNets(id, nets) {
    dispatch(updatePackageNets(id, nets))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  saveTargetImpedance(id, target) {
    dispatch(saveTargetImpedance(id, target))
  },
  _changeShowNameStatus(status, designID) {
    dispatch(changeShowNameStatus(status, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID))
  },
  savePackagePorts(id, ports, portType) {
    dispatch(savePackagePorts(id, ports, portType))
  },
  updateDomainAfterUpdateNets(id) {
    dispatch(updateDomainAfterUpdateNets(id))
  },
  switchPackagePorts(lumped, compType) {
    dispatch(switchPackagePorts(lumped, compType))
  },
  saveBallSize(pcbId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab) {
    dispatch(saveBallSize(pcbId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab))
  },
  saveStackupToServer(stackupData, designID) {
    dispatch(saveStackupToServer(stackupData, designID))
  },
})

export default connect(mapState, mapDispatch)(PackageDomainTable);
