import React, { useState, useEffect } from 'react';
import './index.css';

const VirtualList = (props) => {
  const {
    data,       // options
    count,
    size,       // Number of list items rendered by the viewport
    viewSize,   // Number of lists visible in the viewable area
    rowHeight,
    renderNode, // Rendered list item DOM node 
    nodeItemCount,
    newList,
    expandKeys
  } = props;

  const [startIndex, setStartIndex] = useState(0);
  const [phantomHeight, setPhantomHeight] = useState(0); // Height of the footprint
  const [startOffset, setStartOffset] = useState(0);     // Render region offset

  // Calculate the height of the supporting scroll bar area
  useEffect(() => {
    setPhantomHeight(rowHeight * (count + nodeItemCount));
  }, [count, nodeItemCount, rowHeight])

  /**
   * Update the data and height of the display area when scrolling
   * @param {DOM.event} e 
   */

  const onScroll = (e) => {
    let scrollTop = e.target.scrollTop;
    let offset = scrollTop - (scrollTop % rowHeight);
    let index = Math.floor(scrollTop / rowHeight);
    setStartOffset(offset);
    setStartIndex(index);
  }

  const onMouseDown = (e) => {
    e.preventDefault();
    return false;
  }

  const getViewList = (newList) => {

    let viewList = [];
    for (let item of newList) {
      let childrenList = [];
      let isFlag = false; // Whether to show net
      if (expandKeys.includes(item.id)) {
        childrenList = item.children.filter((it) => it.key > startIndex - 1 && it.key < startIndex + size - 1);
        if (childrenList.length > 0) {
          if (item.key < startIndex - 1) {
            isFlag = true; // only to show pins
          }
          viewList.push({ ...item, children: childrenList, flag: isFlag })
        };
      } else if (item.key > startIndex - 1 && item.key < startIndex + size - 1) {
        viewList.push({ ...item, children: [], flag: false });
      }
    }
    return viewList;
  }
  const viewList = getViewList(newList);

  return (
    <div
      className="cascade-tree-list-container"
      style={
        (data && (count + nodeItemCount) > viewSize)
          ? { height: rowHeight * viewSize }
          : { height: rowHeight * (count + nodeItemCount) + 12 } // className="view" => padding-top:6px;
      }
      onScroll={onScroll}
      onMouseDown={onMouseDown}
    >
      <div className="tree-list-phantom" style={{ height: phantomHeight }}>
        <div className="tree-list-view" style={{ transform: `translateY(${startOffset}px)` }}>
          {
            Array.isArray(viewList) && viewList.length > 0
              ? viewList.map((item) => { return renderNode(item); })
              : <div />
          }
        </div>
      </div>
    </div>
  )
}

export default VirtualList;