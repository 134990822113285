/** 
 * @param {array} nameList: [ { name: "", ... }] / [{ [key]: "", ... }]
 * @param {string} key key nameList item name
 * @param defaultKey default name pre suffix
 * @param firstIndex nameIndex first number
*/
function getDefaultName({ nameList, defaultKey, key = "name", firstIndex, delimiter = "", endDelimiter = "", isStartZero }) {
  let defaultName = "";
  let nameIndex = firstIndex && firstIndex > 0 ? firstIndex : isStartZero ? nameList.length : nameList.length + 1;
  /*  defaultName = `${defaultKey}${nameIndex}`; */
  defaultName = delimiter ? `${defaultKey}${delimiter}${nameIndex}` : `${defaultKey}${nameIndex}`;
  if (endDelimiter) {
    defaultName = `${defaultName}${endDelimiter}`;
  }

  const names = key ? nameList.map(item => item[key]) : nameList;
  while (names.includes(defaultName)) {
    nameIndex += 1;
    defaultName = delimiter ? `${defaultKey}${delimiter}${nameIndex}` : `${defaultKey}${nameIndex}`;
    if (endDelimiter) {
      defaultName = `${defaultName}${endDelimiter}`;
    }
  }

  return defaultName;
}

function getSplitPartName({ components, partName }) {
  let defaultKey = partName;
  if (partName.indexOf('_COPY_') !== -1) {
    let index = partName.indexOf('_COPY_');
    defaultKey = `${partName.substring(0, parseInt(index + 5))}_`;
  } else {
    defaultKey = `${partName}_COPY_`;
  }
  const newPartName = getDefaultName({ nameList: components, defaultKey, key: "part", firstIndex: 1 });
  return newPartName;
}

function getInterDefaultName({ nameList, defaultKey, key = "name", number = 1, addDefault = false, delimiter = "" }) {
  const names = nameList.map(item => item[key])

  const words = defaultKey.split(/([0-9]+)$/);
  let defaultName = defaultKey;
  if (words.length) { defaultName = words[0]; }

  let name_num = number;
  let newNameList = [];
  let i = 1;
  if (addDefault && !names.find(item => item === defaultKey)) {
    newNameList.push(defaultKey)
    name_num--
  }

  if (addDefault) {
    if (newNameList.length) {
      return newNameList;
    }

    let newName = defaultKey;

    while (names.includes(newName)) {
      newName = `${defaultKey}${delimiter}${i}`;
      i++;
    }

    return [newName]
  }

  if (names.find(item => defaultName === item) || newNameList.find(item => defaultName === item)) {
    i = 2
  }

  while (name_num !== 0) {
    let newName = `${defaultName}${i}`;
    if (!names.find(item => item === newName) && !newNameList.find(item => item === newName)) {
      newNameList.push(newName);
      name_num--;
    }
    i++
  }
  return newNameList
}

/** 
 * @param {array} indexList: [ "1","2","3" ]
 * @param {number} index 
*/
function getDefaultIndex(index, indexList) {
  let _index = index.toString();
  index = parseInt(index);

  while (indexList.includes(_index)) {
    index += 1;
    _index = index.toString();
  }

  return _index;
}

export {
  getDefaultName,
  getSplitPartName,
  getDefaultIndex,
  getInterDefaultName
}