export const CHANGE_MENU = 'change_menu';
export const CHOOSE_ITEM = 'choose_item';
export const SAVE_RANGE = 'save_range';
export const UPDATE_PROGRESS = 'update_progress';
export const UPDATE_PAGE = 'update_page';
export const SELECT_PCIE_INTERFACE = 'select_pcie_interface';
export const GET_INTERFACE_CONTENT = 'get_interface_content';
export const START_SERDES_VERIFICATION = 'serdes/verification';
export const SELECT_PCIE_GROUP = 'select_pcie_group';
export const SAVE_PCIE_CONFIG = 'pcie/save_config';
export const SELECT_MANAGER = 'select_manager';
export const MANAGER_INFO = 'manager_info';
export const SELECT_INTERFACE = 'manager/select_interface';
export const MULTI_VERIFY_STATUS = 'multi_verify_status';
export const RELOAD_MANAGER = 'reload_manager';
export const CLOSE_SUMMARY = 'close_summary';
export const SINGLE_INTERFACE_VERIFY_INFO = 'single_interface_verify_info';
export const CLOSE_LOG = 'close_log';
export const SHOW_LOG = 'show_log';
export const GET_SINGLE_MONITOR = 'get_single_monitor';
export const APPEND_SINGLE_MONITOR = 'append_single_monitor_log';
export const GENERATE_REPORT = 'generate_serdes_report';
export const UPDATA_REPORT_PROGRESS = 'update_project_progress';
export const CANCEL_WORKFLOW = 'cancel_workflow';
export const CLOSE_MONITOR = 'close_monitor';
export const CLOSED_MESSAGE = 'closed_message';
export const SHOW_ERROR_INFO = 'show_error_info';
export const UPDATE_INTERFACE = 'update_interface';
export const SAVE_PROJECT_NAME = 'save_project_name';
export const ADD_MULTI_MONITOR = 'add_multi_monitor';
export const CLEAN_SINGLE_PROGRESS = 'clean_single_progress';
export const CHANGE_RESULT = 'change_result';
export const GET_PCIE_INTERFACE_INFO = 'get_pcie_interface_info';
export const UPDATE_INTERFACE_TYPE = 'update_interface_type';
export const INTERFACE_TYPE_STATUS = 'interface_type_status';
export const SHOW_MULTI_LOG = 'show_multi_log';
export const SELECT_PROJECT = 'select_project';
export const RELOAD_INTERFACE = 'reload_interface';
export const SELECT_EXPLORER = 'select_explorer';
export const SAVE_PCIE_TO_SERVER = 'save_pcie_to_server';
export const RUNNING_SHOW = 'verification/running_show';
