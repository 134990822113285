import { call, put, select, takeEvery } from "redux-saga/effects";
import { editDesignStackup } from "@/services/api/designFile";
import { METAL, stackupItemErrorCheck } from '@/services/Stackup';
import { saveData, updateStackupColumnsStatus, updateStackupErrorMsg } from "./actionCreators";
import {
  DELETE_MATERIAL_LIST, SAME_MENU_MATERIAL, SAVE_MATERIAL, SAVE_ROUGHNESS, SAVE_STACKUP_TABLE_DATA,
  SAVE_STACKUP_TO_SERVER
} from "./actionTypes";
import { defaultStackupReducer } from "../../../../../services/Stackup";
import { NEW_MATERIAL } from "../../../../../services/Stackup/Material";

function* _saveMaterialData(action) {
  const { newMaterial, applyAllType, selectMaterialName, record, designID, dataIndex } = action;
  const { StackupReducer: { stackupObj } } = yield select();
  const { materialList, data } = stackupObj[designID] || defaultStackupReducer;
  const indexStackup = record.indexStackup;
  const layerType = record.type;
  let _data = [...data], _materialList = [...materialList];
  //find stackup data index
  const index = _data.findIndex(item => item.indexStackup === indexStackup);
  //update layers material name
  _data[index][dataIndex] = newMaterial.name;
  //update material list and other same material layers
  if (selectMaterialName === NEW_MATERIAL) {
    _materialList.push({ ...newMaterial })
  } else if (!_materialList.find(item => item.name === selectMaterialName)) {
    _materialList.push({ ...newMaterial })
  } else {
    const _index = _materialList.findIndex(item => item.name === selectMaterialName);
    _materialList[_index] = { ...newMaterial };
    for (let item of _data) {
      if (item.material === selectMaterialName) {
        item.material = newMaterial.name;
      }
      if (item.fill_material === selectMaterialName) {
        item.fill_material = newMaterial.name;
      }
    }
  }

  if (applyAllType) {
    let startIndex = 0, lastIndex = _data.length - 1;
    startIndex = _data.findIndex(item => item.type === METAL);
    let typeData = _data.map(item => item.type);
    lastIndex = typeData.lastIndexOf(METAL);
    for (let i = 0; i < _data.length; i++) {
      let item = _data[i];
      if (layerType !== item.type) {
        continue;
      }
      if ((applyAllType === 'applyAllOuter' && i !== startIndex && i !== lastIndex) ||
        (applyAllType === 'applyAllInner' && (i === startIndex || i === lastIndex))) {
        continue;
      }
      _data[i][dataIndex] = newMaterial.name;
    };
  }

  yield put(saveData({ data: _data, materialList: _materialList, designID }));
  //update stackup table columns
  yield put(updateStackupColumnsStatus(true, designID));
}

function* _saveStackupData(action) {
  const { record, dataType, designID } = action;
  const { StackupReducer: { stackupObj } } = yield select();
  const { materialList, data, stackups } = stackupObj[designID] || defaultStackupReducer;
  const indexStackup = record.indexStackup;
  let _data = [...data];
  //find stackup data index
  const index = _data.findIndex(item => item.indexStackup === indexStackup);
  const error = stackupItemErrorCheck(dataType, record[dataType], { nameList: materialList, type: _data[index].type });
  if (error) {
    yield put(updateStackupErrorMsg(error, designID));
  } else {
    if (dataType === "name" && stackups && stackups.length) {
      //update layer name of stackups layers 
      const prevName = _data[index][dataType];
      for (let item of stackups) {
        const index = item.layers.findIndex(it => it === prevName);
        if (index > -1) {
          item.layers[index] = record[dataType];
        }
      }
    }
    _data[index][dataType] = record[dataType];
    yield put(saveData({ data: _data, designID }));
  }
}

function* _updateRoughness(action) {
  const { roughnessObj: { applyAllType, same_roughness, roughness, bot_roughness, side_roughness }, record, designID } = action;
  const { StackupReducer: { stackupObj } } = yield select();
  const { data } = stackupObj[designID] || defaultStackupReducer;
  const indexStackup = record.indexStackup;
  let _data = [...data];
  //find stackup data index
  const index = _data.findIndex(item => item.indexStackup === indexStackup);
  _data[index] = updateRoughnessData(_data[index], { same_roughness, roughness, bot_roughness, side_roughness });

  if (applyAllType) {
    let startIndex = 0, lastIndex = _data.length - 1;
    startIndex = _data.findIndex(item => item.type === METAL);
    let typeData = _data.map(item => item.type);
    lastIndex = typeData.lastIndexOf(METAL);
    for (let i = 0; i < _data.length; i++) {
      if ((applyAllType === 'applyAllOuter' && i !== startIndex && i !== lastIndex) ||
        (applyAllType === 'applyAllInner' && (i === startIndex || i === lastIndex))) {
        continue;
      }
      let item = _data[i];
      if (item.type === METAL) {
        _data[i] = updateRoughnessData(_data[i], { same_roughness, roughness, bot_roughness, side_roughness });
      }
    };
  }

  yield put(saveData({ data: _data, designID }));
  //update stackup table columns
  yield put(updateStackupColumnsStatus(true, designID));
}

function updateRoughnessData(layer, { same_roughness, roughness, bot_roughness, side_roughness }) {
  if (same_roughness) {
    layer.roughness = { ...roughness };
    delete layer.bot_roughness;
    delete layer.side_roughness;
  } else {
    layer = {
      ...layer,
      roughness,
      bot_roughness,
      side_roughness
    }
  }
  return layer;
}

function* _saveStackup(action) {
  const { stackupData, designID } = action;
  try {
    yield call(editDesignStackup, { designId: designID, stackup: { ...stackupData } });
  } catch (error) {
    console.error(error)
  }
}

function* updateMenuMaterial(action) {
  const { newMaterial, prevMaterialInfo: { name: prevName }, designID } = action;
  //newMaterial -> material content
  //prevMaterialInfo-> prev name,type
  const { StackupReducer: { stackupObj } } = yield select();
  const { data, materialList } = stackupObj[designID] || defaultStackupReducer;
  let _materialList = [...materialList], _data = [...data];
  if (prevName === "New Material") {
    _materialList.push({ ...newMaterial });
  } else {
    const index = _materialList.findIndex(item => item.name === prevName);
    _materialList[index] = { ...newMaterial };
  }

  if (prevName !== newMaterial.name && prevName !== "New Material") {
    for (let i = 0; i < _data.length; i++) {
      if (data[i].material === prevName) {
        data[i].material = newMaterial.name;
      }
      if (data[i].fill_material === prevName) {
        data[i].fill_material = newMaterial.name;
      }
    }
  }

  yield put(saveData({ data: _data, materialList: _materialList, designID }));
  //update stackup table columns
  yield put(updateStackupColumnsStatus(true, designID));
}

function* _deleteMaterialList(action) {
  const { materialName, designID } = action;
  const { StackupReducer: { stackupObj } } = yield select();
  const { materialList, data } = stackupObj[designID] || defaultStackupReducer;
  let _materialList = [...materialList], _data = [...data];
  //delete material
  _materialList = _materialList.filter(item => item.name !== materialName);
  //delete data material
  for (let i = 0; i < _data.length; i++) {
    if (data[i].material === materialName) {
      data[i].material = "";
    }
    if (data[i].fill_material === materialName) {
      data[i].fill_material = "";
    }
  }

  yield put(saveData({ data: _data, materialList: _materialList, designID }));
  //update stackup table columns
  yield put(updateStackupColumnsStatus(true, designID));
}

function* stackupSaga() {
  yield takeEvery(SAVE_MATERIAL, _saveMaterialData);
  yield takeEvery(SAVE_STACKUP_TABLE_DATA, _saveStackupData);
  yield takeEvery(SAVE_STACKUP_TO_SERVER, _saveStackup);
  yield takeEvery(SAVE_ROUGHNESS, _updateRoughness);
  yield takeEvery(SAME_MENU_MATERIAL, updateMenuMaterial);
  yield takeEvery(DELETE_MATERIAL_LIST, _deleteMaterialList);
}

export default stackupSaga;