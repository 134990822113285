import { sortProject } from '../api/v2/projectCtrl';
import apiProcess from "../api/utility";


/**
 * sort project
 *
 * @export
 * @param {string[]} projectIds
 * @returns promise
 */
export function sortProjectPromise(projectIds) {
  return apiProcess(sortProject, projectIds)
}