import React, { Component } from "react";
import { connect } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import Table from '@/components/EditableTable';
import {
  gerPreLayoutTableData,
  deleteSectionsInSignals,
  preLayoutSection,
  addSectionsToSignals,
  RX
} from '@/services/Andes_v2/preLayout';
import {
  selectSectionTemplate,
  editInsertionLoss,
  editGroupName,
  editSignalName,
  editNetName,
  changeLengthUnit,
  editSignalSpacing
} from '../store/preLayout/action';
import IconTip from "@/components/PreLayout/IconTip";
import { saveLibraryConfigToServer } from '../store/library/action';
import unitSelect from '@/components/PreLayout/UnitSelect';
import { GENERIC } from "../../../services/PCBHelper/constants";

class SignalTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      foldList: [],
      signalColumns: []
    }
  }

  componentDidMount = () => {
    const { signalColumns } = this.props;
    if (signalColumns && signalColumns.length) {
      this.columnsOnCell();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { signalColumns, signalColumnsStatus, designID } = this.props;
    if (signalColumns.length !== prevProps.signalColumns.length || (signalColumnsStatus && signalColumnsStatus !== prevProps.signalColumnsStatus)) {
      this.columnsOnCell();
      this.props.updateSignalColumnsStatus(false);
    }
  }

  columnsOnCell = () => {
    const { type, designID, signalColumns } = this.props;
    let _signalColumns = [...signalColumns];
    _signalColumns.forEach(item => {
      switch (item.dataIndex) {
        case "group":
          item.render = (name, record) => {
            return {
              children: record.signalLength === 1 ? <span>{name}</span>
                : <div onClick={() => this.setFold(name)} className='pre-layout-fold'>
                  <DownOutlined
                    className={this.state.foldList.includes(name) ? "pre-layout-fold-icon pre-layout-fold-icon-transform" : "pre-layout-fold-icon"} />
                  <span>{name}</span>
                </div>,
              props: {
                rowSpan: record.groupLength
              }
            };
          };
          if (type !== GENERIC) {
            break;
          }
          item.onCell = (record) => {
            const dataIndex = item.dataIndex;
            return {
              record,
              edit: true,
              dataIndex,
              handleSave: (rec) => { this.props._editGroupName(rec, record, designID) },
            }
          }
          break;
        case "signal_spacing":
          item.render = (name, record) => {
            return {
              children:
                record.signalLength > 1
                  ? <span>{name}</span> :
                  <div className="aurora-table-row-disabled"></div>,
              props: {
                rowSpan: record.groupLength
              }
            }
          };
          item.onCell = (record) => {
            const dataIndex = item.dataIndex;
            if (record.signalLength > 1) {
              return {
                record,
                edit: true,
                dataIndex,
                handleSave: (rec) => { this.props._editSignalSpacing(rec, designID) },
              }
            }
          }
          break;
        case "insertionLoss":
          item.onCell = (record) => {
            const dataIndex = item.dataIndex;
            return {
              record,
              edit: true,
              dataIndex,
              handleSave: (rec) => { this.props._editInsertionLoss(rec, dataIndex, designID) },
            }
          }
          break;
        case "name": //signal name
          if (type !== GENERIC) {
            break;
          }
          item.onCell = (record) => {
            const dataIndex = item.dataIndex;
            return {
              record,
              edit: true,
              dataIndex,
              handleSave: (rec) => { this.props._editSignalName(rec, record, designID) },
            }
          }
          break;
        case "nets_P":
        case "nets_N": //signal net
          if (type !== GENERIC) {
            break;
          }
          item.onCell = (record) => {
            const dataIndex = item.dataIndex;
            return {
              record: { ...record, net: record[dataIndex] },
              edit: true,
              dataIndex: "net",
              handleSave: (rec) => { this.props._editNetName(rec, record, dataIndex, designID) },
            }
          }
          break;
        default: break;
      }

      preLayoutSection({
        item,
        selectTemplate: (data) => this.props._selectSectionTemplate(data, this.props.designID),
        addSection: this.addSection,
        delSection: this.delSection,
        saveLibraryConfigToServer: this.props._saveLibraryConfigToServer
      });
    })

    this.setState({
      signalColumns: _signalColumns
    })
  }

  setFold = (name) => {
    let newFoldList = [...this.state.foldList];
    if (newFoldList.includes(name)) {
      newFoldList = newFoldList.filter(item => item !== name);
    } else {
      newFoldList.push(name)
    }
    this.setState({
      foldList: newFoldList
    })
  }

  addSection = (e, itemData) => {
    const key = itemData.key;
    e && e.stopPropagation();
    const { maxSectionLength, signal_groups, components } = this.props;
    const _maxSectionLength = maxSectionLength + 1;
    let _signal_groups = addSectionsToSignals(key, signal_groups, components);
    this.props.updateSignalColumns(_maxSectionLength, { maxSectionLength: _maxSectionLength, signal_groups: _signal_groups });
  }

  delSection = (e, itemData) => {
    e && e.stopPropagation();
    const { key } = itemData;//key -> section index
    const { signal_groups, maxSectionLength, components } = this.props;
    const { _signal_groups, _components } = deleteSectionsInSignals(key, signal_groups, components);
    const _maxSectionLength = maxSectionLength - 1;
    this.props.updateSignalColumns(_maxSectionLength, {
      signal_groups: _signal_groups,
      components: _components,
      maxSectionLength: _maxSectionLength
    });
  }

  getSignalDataList = () => {
    const { signal_groups, selectedGroups } = this.props;
    const { foldList } = this.state;
    let dataList = [];
    if (!signal_groups || !signal_groups.length) {
      return dataList;
    }

    dataList = gerPreLayoutTableData(signal_groups, selectedGroups, foldList);
    return dataList;
  }

  render() {
    const dataList = this.getSignalDataList();
    const { unit, maxSectionLength, designID } = this.props;
    const { signalColumns } = this.state;
    return (<div className="aurora-setup-border space-10 pre-layout-relative">
      <span className='out-title-color font-bold'>Signals</span>
      <div className="pre-layout-unit-main">
        {unitSelect({ unit, changeUnit: (unit) => this.props._changeLengthUnit(unit, designID) })}
      </div>
      <IconTip style={{ top: 10 }} />
      <Table
        rowKey={(record) => record.name}
        columns={signalColumns}
        size="small"
        dataSource={dataList.length ? dataList : []}
        className={maxSectionLength > 1 ? 'pre-layout-signals-table space-10' : 'pre-layout-signals-table space-10 no-scroll'}
        rowClassName={(record, index) => record.group === RX ? 'editable-row aurora-row-background' : 'editable-row'}
      />
    </div>
    )
  }
}

const mapDispatch = (dispatch) => ({
  _selectSectionTemplate(data, designID) {
    dispatch(selectSectionTemplate(data, designID))
  },
  _editInsertionLoss(record, dataIndex, designID) {
    dispatch(editInsertionLoss(record, dataIndex, designID))
  },
  _saveLibraryConfigToServer({ model, config, templateVersion }) {
    dispatch(saveLibraryConfigToServer({ model, config, templateVersion }))
  },
  _editGroupName(record, prevRecord, designID) {
    dispatch(editGroupName(record, prevRecord, designID))
  },
  _editSignalName(record, prevRecord, designID) {
    dispatch(editSignalName(record, prevRecord, designID))
  },
  _editNetName(record, prevRecord, dataIndex, designID) {
    dispatch(editNetName(record, prevRecord, dataIndex, designID))
  },
  _changeLengthUnit(unit, designID) {
    dispatch(changeLengthUnit(unit, designID))
  },
  _editSignalSpacing(record, designID) {
    dispatch(editSignalSpacing(record, designID))
  }
})

export default connect(null, mapDispatch)(SignalTable);