import { SCHEMATIC, RX, TX } from ".";
import { PCIE } from '../../PCBHelper/constants';
import { MIL } from "../../PreLayout/PreLayoutLibrary";

function DefaultPreLayout({
  signal_groups,
  components,
  diffPairsNumber = "",
  width = "x1",
  prelayout = SCHEMATIC,
  type = PCIE,
  selectedGroups = [TX, RX],
  netPrefix = "",
  designType
}) {
  this.components = components;
  this.signal_groups = signal_groups;
  this.prelayout = prelayout;
  this.type = type;
  this.width = width;
  this.diffPairsNumber = diffPairsNumber;
  this.unit = MIL;
  this.lib = "";
  this.models = [new PreLayoutModel()];
  this.selectedGroups = selectedGroups; // "TX" , "RX", "CLK"
  this.maxSectionLength = 1;
  this.netPrefix = netPrefix;
  this.designType = designType;
}

function PreLayoutModel() {
  this.id = "1";
  this.type = "Touchstone";
  this.path = "";
  this.frequency = preLayoutModelFreq();
}

function preLayoutModelFreq() {
  return [{
    "start": "1KHz",
    "end": "10MHz",
    "type": "log",
    "dec": "10"
  },
  {
    "start": "10MHz",
    "end": "10GHz",
    "type": "linear",
    "step": "10MHz"
  },
  {
    "start": "10GHz",
    "end": "40GHz",
    "type": "linear",
    "step": "20MHz"
  }]
}

export {
  DefaultPreLayout,
  PreLayoutModel,
  preLayoutModelFreq
}