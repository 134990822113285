const PRE_LAYOUT_DESIGN_VERSION = 1;

const DC_PLOT_ROW_HEIGHT = 120;
const DC_PLOT_WIDTH = 500;
const DC_PLOT_COMP_WIDTH = 120;
const DC_PLOT_COMP_HEIGHT = 80;
const DC_PLOT_BOTTOM = 30;

export {
  PRE_LAYOUT_DESIGN_VERSION,
  DC_PLOT_ROW_HEIGHT,
  DC_PLOT_WIDTH,
  DC_PLOT_COMP_WIDTH,
  DC_PLOT_COMP_HEIGHT,
  DC_PLOT_BOTTOM
}