import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { getComponentByName } from '../../services/PCBHelper';
import Panel from '../Panel';
import EditableTable from '../EditableTable';
import { CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Select } from 'antd';
import { TOUCHSTONE } from '../../constants/libraryConstants';
import { getPortNumberFromFileSuffix } from '../../services/helper/touchstoneHelper';
import FileContentPanel from '../LibraryUpload/FileContentPanel';
import './index.css';

const { Option } = Select;
const tableColumns = [{
  title: 'Components Pins',
  dataIndex: 'pin',
  width: '50%'
}, {
  title: 'Touchstone Ports',
  dataIndex: 'node',
  width: '50%',
}];

class CMCModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinList: [],
      files: [],
      portsObj: {}
    };
    this.dialogRoot = document.getElementById('root');

    tableColumns[0].render = (value, record) => {
      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {value}</div>
          {record.pinInfo ? <div>Pin Name: {record.pinInfo.mName}</div> : null}
        </div>}
      >
        <div className="component-cmc-model-pin">{value}</div>
      </Tooltip>
    }

    tableColumns[1].onCell = (record) => {
      const { files = [], portsObj = {}, pinList = [] } = this.state;
      const portList = files[0] && files[0].libraryId ? portsObj[files[0].libraryId] || [] : [];
      const _portList = portList.filter(item => !pinList.find(it => it.node === item.port.toString()));
      return {
        record,
        edit: "select",
        options: _portList.map(item => item.port.toString()),
        dataIndex: "node",
        allowClear: true,
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "component-cmc-model-port-select-dropdown",
        handleSave: (_record) => { this.editPort(_record) },
        handleClear: (_record) => { this.editPort(_record, true) }
      }
    }
  }

  editPort = (record, isClear) => {
    const { files = [], pinList } = this.state;
    const libraryId = files[0] && files[0].libraryId ? files[0].libraryId : "";
    let _pinList = [...pinList];
    const index = pinList.findIndex(item => item.pin === record.pin);
    if (index < 0) {
      return;
    }
    /*    const prevPortIndex = _pinList.findIndex(item => record.node && item.node === record.node && libraryId === item.libraryId);
       if (prevPortIndex > -1) {
         _pinList[prevPortIndex].node = "";
         _pinList[prevPortIndex].libraryId = "";
       } */

    _pinList[index].node = isClear ? "" : record.node;
    _pinList[index].libraryId = record.node && !isClear ? libraryId : "";

    this.setState({
      pinList: _pinList
    })
  }

  componentDidMount = () => {
    const { record = {}, channelDesignId } = this.props;
    const comps = record.comps;
    if (!comps || !comps.length) return;
    const comp = comps[0];
    const pins = comps[0].pins;
    let cmcModel = record && record.cmcModel ? JSON.parse(JSON.stringify(record.cmcModel)) : {};//{files:[], pairs:[] }

    if (!cmcModel || !cmcModel.pairs || !cmcModel.pairs.length) {
      cmcModel = { files: [], pairs: [] }
      for (let pin of pins) {
        cmcModel.pairs.push({
          pin: pin.pin,
          node: "",
          libraryId: ""
        })
      }
    }

    const pcbComp = getComponentByName(channelDesignId, comp.name);

    const mPinList = pcbComp && pcbComp.mPart && pcbComp.mPart.mPinList ? pcbComp.mPart.mPinList : [];
    let pinList = [];
    for (let pin of pins) {
      const findPort = cmcModel.pairs.find(item => item.pin === pin.pin) || {};
      pinList.push({
        ...pin,
        pinInfo: mPinList.find(item => item.mNumber === pin.pin),
        ...findPort
      })
    }
    const files = cmcModel.files ? cmcModel.files : [];
    this.setState({ pinList, files });
    this.getFileParse(files);
  }

  closeModal = () => {
    const { record } = this.props;
    const { pinList, files } = this.state;
    this.props.saveCMCModel(record.part, files, pinList);
    this.props.save();
  }

  selectFile = (key) => {
    const { touchstoneList } = this.props;
    const findFile = touchstoneList.find(item => item.name === key);
    if (!findFile) {
      return;
    }
    const { files, pinList } = this.state;
    let _pinList = [...pinList];

    if (files.length) {
      //delete prev file selected ports
      const prevFile = files[0];
      _pinList.forEach(item => {
        if (item.node && item.libraryId === prevFile.libraryId) {
          item.node = "";
          item.libraryId = ""
        }
      })
    }

    let _files = [{
      libraryId: findFile.id,
      fileName: findFile.name,
      type: TOUCHSTONE
    }];
    this.setState({
      files: _files,
      pinList: _pinList
    }, () => {
      this.getFileParse(_files)
    })
  }

  getFileParse = (files) => {
    if (!files || files.length === 0) {
      return;
    }
    //filter libraryId not exist files
    const _files = files.filter(item => !!item.libraryId);
    let _portsObj = {};
    //get files ports
    this.getFilesParseList(files).then(res => {
      if (res && Array.isArray(res)) {
        _files.forEach(file => {
          const libraryId = file.libraryId;
          let _ports = [];
          const currentFileInfo = res.find(it => it.libraryId === libraryId);
          _ports = currentFileInfo && Array.isArray(currentFileInfo.ports) ? currentFileInfo.ports : [];
          _portsObj[libraryId] = _ports;
        });
        this.setState({
          portsObj: _portsObj
        })
      }
    })
  }

  getFilesParseList = (files) => {
    const { getTouchstoneParse, } = this.props;
    const promiseList = files.map(item => {
      return getTouchstoneParse(item.libraryId, item.fileName);
    });
    return Promise.all(promiseList);
  }

  deleteFileSelect = () => {
    const { files, pinList } = this.state;
    let _pinList = [...pinList];

    if (files.length) {
      //delete prev file selected ports
      const prevFile = files[0];
      _pinList.forEach(item => {
        if (item.node && item.libraryId === prevFile.libraryId) {
          item.node = "";
          item.libraryId = ""
        }
      })
    }

    this.setState({
      files: [],
      pinList: _pinList
    })
  }

  showFileContent = (fileVisible) => {
    this.setState({
      fileVisible
    })
  }

  renderDialog = () => {
    const { pinList, files = [], fileVisible } = this.state;
    const { touchstoneList, record, getFileContent } = this.props;
    const _touchstoneList = touchstoneList.filter(item => getPortNumberFromFileSuffix(item.name) === "4")
    const content = (
      <Panel
        className='component-CMC-model-panel'
        position='panel-center-left'
        title={`${record.part} Model`}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={600}
        draggable
        minHeight={200}
      >
        <div className='component-CMC-model-content'>
          <div className="component-CMC-model-file-item">
            <span>Model File</span>
            <Select
              placeholder={"Model File"}
              value={files[0] ? files[0].fileName : ""}
              onSelect={this.selectFile}
              className={files.length ? 'aurora-select component-cmc-file-select' : "aurora-select"}
              popupClassName='aurora-select-dropdown'
              popupMatchSelectWidth={false}
              allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect() }} /> }}
              getPopupContainer={() => document.getElementById('root')}
            >
              {_touchstoneList.map(item => <Option
                key={item.name}
                value={item.name}
                title={item.name}
              >{item.name}</Option>)}
            </Select>
            {files.length ?
              <Tooltip overlayClassName="aurora-tooltip" title="Show File Content">
                <InfoCircleOutlined
                  className="component-cmc-file-content-icon"
                  onClick={() => this.showFileContent(true)} />
              </Tooltip>
              : null}
          </div>
          <EditableTable
            rowKey="pin"
            columns={tableColumns}
            size="small"
            selectType="node"
            dataSource={pinList}>
          </EditableTable>
          {fileVisible && files.length ? <FileContentPanel
            title={files[0].fileName}
            getLibraryFileContent={getFileContent}
            closeModal={() => this.showFileContent(false)}
            libraryId={files[0].libraryId}
            fileName={files[0].fileName}
          /> : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { inputRef } = this;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {this.props.text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default CMCModel;