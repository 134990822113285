import { call, put, fork, cancel, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';
import { doSimulation, doDebugVerify } from '@/services/PDN/verificationCtrl';
import { getVRMModelFile } from '@/services/PDN';
import { getWorkFlow, cancelVerificationWorkflow } from '@/services/api/v2/workflowCtrl';
import { workflowWaitingIndex, checkVerificationStatus, getMonitor } from '@/services/workflow/workflow';
import { START_PDN_VERIFICATION } from './actionTypes';
import * as taskStatus from '@/constants/workflowStatus';
import { VERIFY_SUCCESS, VERIFY_NEVER, VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import dayjs from 'dayjs';
import {
  getInterfaceWorkStatus
} from '@/services/project';
import {
  GET_SINGLE_MONITOR,
  CANCEL_WORKFLOW,
  DEBUG_VERIFY,
  GET_CURRENT_LOG,
  UPDATE_LIBRARY_DATA_CHECK,
  CLEAR_SIMULATION_INFO,
  RE_CHECK_SIMULATION_STACKUP,
  GET_VRM_MODEL,
  UPDATE_SIMULATION_MESSAGE,
  GET_CURRENT_PROFILE,
  GET_PDN_WORKFLOW
} from './actionTypes';
import {
  updateProgress,
  singleVerifyInfo,
  cleanSingleProgress,
  updateSingleMonitor,
  cleanMonitor,
  changeUploadMes,
  existResult,
  updateEndMsg,
  changeVerificationList,
  pdnCheckError,
  waitingIndexAction,
  saveCurrentLog,
  pdnStackupCheckError,
  vrmErrorInfo,
  decapErrorInfo,
  saveCurrentCheckId,
  updateStartMsg,
  getCurrentProfile,
  saveCurrentProfileLog,
  clearSimulationInfo,
  getCurrentPDNLog
} from './action';
import { getPdnErrorCheck } from '../../errorCheck/PDNErrorCheck';
import { getPdnSimulationLog } from '@/services/PDN/verificationCtrl';
import { saveVRMModelUpdate } from '../pdn/action';
import { getStackupDataByPCBId } from '@/services/data/LayoutData';
import { stackupCheck, fakeProgress } from '@/services/helper/dataProcess';
import { resProcessing } from '@/services/helper/sparameter/resProcess';
import { updateProject, updateSimLoading } from '../project/action';
import { getVRMDataIds, getDecapDataIds, getVRMDataInfo, getDecapDataInfo } from '@/services/PDN/library/libraryData';
import { getVRMErrorCheck } from '../../errorCheck/VRMCheck';
import { getDecapErrorCheck } from '../../errorCheck/decapCheck';
import VRMData from '@/services/PDN/library/getVRMData';
import DecapData from '@/services/PDN/library/getDecapData';
import monitorLog from '@/services/helper/monitorLog';
import getProfileData, { parseProfile, shouldGetProfile } from '@/services/helper/profileCtrl';
import { FASTPI } from '@/constants/pageType';
import { getPowerNetsBoundaryArr } from '@/services/helper/cutDesignHelper';
import { saveCutDesignPromise } from '@/services/PDN/PDNCtrl';
import { changeTabMenu, openTabFooter, changeVerification } from '../../../tabMonitor/action';

let monitorTask = null, workflowTask = null;
export function saveWorkflowTask(task) {
  workflowTask = task;
};

export function* cancelWorkflowTask() {
  if (workflowTask) {
    yield cancel(workflowTask);
  };
  if (monitorTask) {
    yield cancel(monitorTask);
  };
}

function* startVerification(action) {
  const { verificationIds } = action;
  // Begin verification
  if (verificationIds.length === 0) {
    yield put(updateSimLoading(false))
    return;
  };
  if (monitorTask) {
    yield cancel(monitorTask);
  };
  if (workflowTask) {
    yield cancel(workflowTask);
  }

  let verificationId = verificationIds[0];
  const { PDNReducer: { project: { currentProjectId, currentProjectPDNs, PDNID } } } = yield select();
  const index = currentProjectPDNs.findIndex(item => item.id === PDNID);
  let pdnName = null;
  if (typeof (index) !== 'boolean' && index > -1) {
    pdnName = currentProjectPDNs[index].name;
  }
  yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId }))
  yield put(changeVerification(pdnName));
  yield put(openTabFooter())
  yield put(changeUploadMes(null))
  yield put(cleanMonitor(verificationId));
  yield put(saveCurrentLog(null));
  yield put(updateEndMsg(null));
  yield put(saveCurrentCheckId(null));
  yield put(vrmErrorInfo(null));
  yield put(decapErrorInfo(null));
  yield put(updateStartMsg("==> Checking setup..."));

  let interfaceStatus = null;
  try {
    interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
  } catch (error) {
    console.error(error);
  }
  if (interfaceStatus) {
    // PDN_History_Result_Parse    result import snp file
    let verificationStatus = interfaceStatus.filter(item => item.workflowType !== 'PDN_History_Result_Parse');
    if (verificationStatus && verificationStatus.length > 0) {
      if (workflowTask) {
        yield cancel(workflowTask);
      };
      if (monitorTask) {
        yield cancel(monitorTask);
      };
      workflowTask = yield fork(verificationWorkFlow, { verificationId, interfaceStatus: verificationStatus });
      yield put(updateSimLoading(false))
      return;
    }
  }
  try {
    let pdnInfo = null, num = 1;
    // Get PDN Info
    while (!pdnInfo || (pdnInfo && Object.keys(pdnInfo).length === 0) || (pdnInfo.verificationId !== verificationId)) {
      yield delay(500);
      const { PDNReducer: { pdn } } = yield select();
      pdnInfo = pdn.pdnInfo ? pdn.pdnInfo : null;
      num += 1;
      if (num === 5) {
        break;
      }
    }
    const { PDNReducer: { pdn, project: { currentProjectPackages, SPIMNames } } } = yield select();
    const pdnContent = pdn.pdnInfo.pdnContent;

    let clipsave = '', trySave = 1;
    while (trySave <= 3) {
      clipsave = yield call(saveCutBoundary, { pdnContent, designId: pdnInfo.designId, verificationId: pdnInfo.verificationId });
      if (clipsave !== 'failed') {
        break;
      }
      trySave = trySave + 1;
      yield delay(500);
    }

    if (clipsave === 'failed') {
      yield put(updateEndMsg("[Error] Save clipping infomation failed, please check the network or PCB and try again!"));
      yield put(updateSimLoading(false))
      return;
    }

    const errorRes = yield call(libraryDataErrorCheck, { verificationId });
    if (errorRes && (errorRes.decapErrorCheck || errorRes.vrmErrorCheck)) {
      yield put(updateSimLoading(false))
      return;
    } else {
      yield put(saveCurrentCheckId(null));
      yield put(vrmErrorInfo(null));
      yield put(decapErrorInfo(null));
      VRMData.cleanContent();
      DecapData.cleanContent();
    }
    let designId = pdnInfo.designId;
    const errorCheck = getPdnErrorCheck({ pdnContent, currentProjectPackages, designId, SPIMNames });

    if (errorCheck) {
      yield put(pdnCheckError(errorCheck));
      yield put(updateSimLoading(false))
      return;
    } else {
      yield put(pdnCheckError(null));
      //check stackup data
      const stackup = yield call(getStackupDataByPCBId, designId);
      const stackupError = yield call(stackupCheck, stackup);
      if (stackupError) {
        yield put(pdnStackupCheckError({ error: stackupError, verificationId }));
        yield put(updateSimLoading(false))
        return;
      } else {
        yield put(pdnStackupCheckError(null));
      }
      yield put(updateStartMsg("==> Start simulating..."));
      // fake progress
      yield put(singleVerifyInfo({ workflowId: null, verificationId: verificationId }));
      yield delay(2000);
      // Do simulation 
      try {
        const simRes = yield call(doSimulation, verificationIds);
        yield put(updateSimLoading(false))
        if (simRes && simRes[0] && simRes[0].status === taskStatus.SUCCEED) {
          yield call(getPdnLog, { verificationId });
        }
        const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
        // PDN_History_Result_Parse    result import snp file
        let verificationStatus = interfaceStatus ? interfaceStatus.filter(item => item.workflowType !== 'PDN_History_Result_Parse') : null;
        if (verificationStatus && verificationStatus.length > 0) {
          if (workflowTask) {
            yield cancel(workflowTask);
          };
          if (monitorTask) {
            yield cancel(monitorTask);
          };
          workflowTask = yield fork(verificationWorkFlow, { verificationId, interfaceStatus: verificationStatus });
          return;
        } else {
          yield put(updateStartMsg(null));
          yield put(changeVerificationList([]));
          yield put(updateProgress({ verificationId, progress: -1 }));
          yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
          // -> update status end
          const { PDNReducer: { project: { currentProjectId } } } = yield select();
          // Update project list -> check history status
          yield put(updateProject(currentProjectId));
        };
      } catch (error) {
        yield put(updateEndMsg(`==> Do simulation failed! ` + error));
        yield put(updateSimLoading(false))
        // <- update status
        yield put(updateStartMsg(null));
        yield put(changeVerificationList([]));
        yield put(updateProgress({ verificationId, progress: -1 }));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
        // -> update status end
        const { PDNReducer: { project: { currentProjectId } } } = yield select();
        // Update project list -> check history status
        yield put(updateProject(currentProjectId));
        return;
      }
    }
  } catch (error) {
    console.error(error);
    yield put(updateSimLoading(false))
    yield delay(1000);
    yield put(updateProgress({ verificationId, progress: -1 }));
    yield put(cleanSingleProgress(verificationId));
    yield put(changeVerificationList([]));
    yield put(updateStartMsg(null));
    const promise = yield call(checkVerificationStatus, verificationId);
    let resultExist = false;
    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(existResult(resultExist));
    const { PDNReducer: { project: { currentProjectId } } } = yield select();
    yield put(updateProject(currentProjectId))
  }
}

function* saveCutBoundary(param) {
  const { pdnContent, designId, verificationId } = param;
  try {
    if (pdnContent.Config.SOLVER === "PowerSI" && pdnContent.Config.CLIP === 1) {
      const ratio = pdnContent.Config.ratio || 0.3;
      const powerNets = pdnContent.PowerNets;
      const referenceNets = pdnContent ? pdnContent.ReferenceNets : null;
      const components = pdnContent ? pdnContent.Components : []
      const data = getPowerNetsBoundaryArr({ ratio, nets: powerNets, designId, referenceNets, components })
      const info = data.toDesignClipObject();
      // PowerSI does not read duplicate data, so remove the last data
      info.x.pop();
      info.y.pop();
      yield call(saveCutDesignPromise, { content: info, verificationId });
      return '';
    }
  } catch (error) {
    console.error(error);
    return 'failed';
  }
}

function* startDebugVerify(action) {
  const { verificationId, status } = action;
  const _status = status;
  // Begin verification
  let time;
  yield put(changeUploadMes(null))
  yield put(cleanMonitor(verificationId));
  yield put(saveCurrentLog(null));
  yield put(updateEndMsg(null));
  yield put(updateStartMsg("==> Start simulating..."));
  if (workflowTask) {
    yield cancel(workflowTask);
  };
  if (monitorTask) {
    yield cancel(monitorTask);
  };
  const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
  if (interfaceStatus) {
    // PDN_History_Result_Parse    result import snp file
    let verificationStatus = interfaceStatus.filter(item => item.workflowType !== 'PDN_History_Result_Parse');
    if (verificationStatus && verificationStatus.length > 0) {
      if (workflowTask) {
        yield cancel(workflowTask);
      };
      if (monitorTask) {
        yield cancel(monitorTask);
      };
      workflowTask = yield fork(verificationWorkFlow, { verificationId, interfaceStatus: verificationStatus });
      yield put(updateSimLoading(false))
      return;
    }
  }
  try {
    let pdnInfo = null, num = 1;
    while (!pdnInfo || (pdnInfo && Object.keys(pdnInfo).length === 0) || (pdnInfo.verificationId !== verificationId)) {
      yield delay(500);
      const { PDNReducer: { pdn } } = yield select();
      pdnInfo = pdn.pdnInfo ? pdn.pdnInfo : null;
      num += 1;
      if (num === 5) {
        break;
      }
    }
    const { PDNReducer: { pdn, project: { currentProjectPackages, SPIMNames = [] } } } = yield select();
    const pdnContent = pdn.pdnInfo.pdnContent;
    let designId = pdn.pdnInfo.designId;
    const errorCheck = getPdnErrorCheck({ pdnContent, currentProjectPackages, designId, SPIMNames });

    if (errorCheck) {
      yield put(pdnCheckError(errorCheck));
      yield put(updateSimLoading(false))
      return;
    } else {
      yield put(pdnCheckError(null));
      //check stackup data
      const stackup = yield call(getStackupDataByPCBId, designId);
      const stackupError = yield call(stackupCheck, stackup);
      if (stackupError) {
        yield put(pdnStackupCheckError({ error: stackupError, verificationId }));
        yield put(updateSimLoading(false))
        return;
      } else {
        yield put(pdnStackupCheckError(null));
      }
      let response = null;
      // fake progress
      yield put(singleVerifyInfo({ workflowId: null, verificationId: verificationId }));
      yield delay(2000);
      try {
        response = yield call(doDebugVerify, { resultType: _status, verificationId });
        yield put(updateSimLoading(false))
      } catch (error) {
        yield put(updateSimLoading(false))
        yield put(updateEndMsg(`==> Do simulation failed! ` + error));
        yield put(updateStartMsg(null));
        yield put(changeVerificationList([]));
        yield put(updateProgress({ verificationId, progress: -1 }));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
        const { PDNReducer: { project: { currentProjectId } } } = yield select();
        yield put(updateProject(currentProjectId));
        return;
      }
      // Setup
      if (_status === 1) {
        yield put(updateEndMsg(`==> Do setup successfully!`));
        yield put(changeVerificationList([]));
        yield put(updateProgress({ verificationId, progress: -1 }));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
        return;
      }
      // id: workflowId
      let { id, status, progress, message, taskList } = response;
      const getProfile = shouldGetProfile(taskList);

      yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));
      // Checking simulation waiting queue
      let waitingIndex = -1;
      waitingIndex = yield call(workflowWaitingIndex, id);
      // while()
      while (waitingIndex >= 0) {
        // ==> Check whether it is in the current Monitor
        // Get opened monitor verification id
        const { TabMonitorReducer: { currentVerificationId } } = yield select();
        // Switch account or switch PDN
        if (currentVerificationId !== verificationId) {
          return;
        }

        time = new Date();
        time.setUTCSeconds(time.getUTCSeconds());
        let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
        const { PDNReducer: { simulationReducer: { waitingQueue } } } = yield select();
        let queue = [...waitingQueue];
        const n = queue.findIndex(item => item.verificationId === verificationId);
        if (n > -1) {
          queue[n].queueIndex = waitingIndex;
          queue[n].waitingTime = waitingTime;
        } else {
          queue.push({
            queueIndex: waitingIndex,
            verificationId,
            waitingTime
          })
        }
        yield put(waitingIndexAction(queue));
        yield delay(3000);
        // update waiting index
        waitingIndex = yield call(workflowWaitingIndex, id);
      };

      if (waitingIndex === -2) {//-2  simulation cancel
        yield put(cleanSingleProgress(verificationId));
        yield put(updateStartMsg(null));
        yield put(updateEndMsg("==> Simulation cancelled!"));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
        const { PDNReducer: { project } } = yield select();
        yield put(updateProject(project.currentProjectId));
        return;
      }

      if (waitingIndex === -3) {//-3 simulation complete
        yield put(cleanSingleProgress(verificationId));
        yield put(updateStartMsg(null));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
        const { PDNReducer: { project } } = yield select();
        yield put(updateProject(project.currentProjectId));
        const promise = yield call(checkVerificationStatus, verificationId);
        let resultExist = false;

        if (promise && promise.status) {
          if (promise.status === VERIFY_SUCCESS) {
            resultExist = true;
          }
        };
        if (resultExist) {
          yield put(updateEndMsg(`==> Simulation completed!`));
        } else {
          yield put(updateEndMsg(`==> Simulation failed!`));
        }
        return;
      }

      const { PDNReducer: { simulationReducer: { waitingQueue } } } = yield select();
      let queue = [...waitingQueue];
      const n = queue.findIndex(item => item.verificationId === verificationId);
      if (n > -1) {
        queue.splice(n, 1);
      }
      yield put(waitingIndexAction(queue));

      let msg = message;
      yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));
      if (progress === 0) {
        progress += 2;
        yield put(updateProgress({ verificationId, progress }));
      }
      monitorTask = yield fork(getMonitorLog, { workflowId: id, verificationId });
      let indexNum = 0;
      while (status === taskStatus.RUNNING) {
        // ==> Check whether it is in the current Monitor
        // Get opened monitor verification id
        const { TabMonitorReducer: { currentVerificationId } } = yield select();
        // Switch account or switch PDN
        if (currentVerificationId !== verificationId) {
          return;
        }
        if (getProfile && indexNum % 10 === 0) {
          yield put(getCurrentProfile(verificationId));
        }
        yield delay(3000);
        const { data: { data } } = yield call(getWorkFlow, id);
        let _progress = progress;
        if (!data) {
          status = taskStatus.FAILED;
        } else {
          status = data.status;
          msg = data.message;
          _progress = data.progress;
        }

        indexNum += 1;

        progress = fakeProgress(progress, indexNum, _progress);
        // Real progress
        yield put(updateProgress({ verificationId, progress }));
      }
      yield put(getCurrentProfile(verificationId));
      if (status === taskStatus.CANCEL) {
        yield put(cleanSingleProgress(verificationId));
      } else {
        yield put(updateProgress({ verificationId, progress }));
      }

      yield delay(2000);
      yield put(updateProgress({ verificationId, progress: -1 }));
      yield put(cleanSingleProgress(verificationId));
      yield put(updateStartMsg(null));
      yield put(changeVerificationList([]));

      const promise = yield call(checkVerificationStatus, verificationId);
      let resultExist = false;

      if (promise && promise.status) {
        if (promise.status === VERIFY_SUCCESS) {
          resultExist = true;
        }
      };
      yield put(existResult(resultExist));
      const { PDNReducer: { project: { currentProjectId } } } = yield select();
      switch (status) {
        case taskStatus.CANCEL:
          yield put(updateEndMsg("==> Simulation cancelled!"));
          yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
          yield put(updateProject(currentProjectId))
          break;
        case taskStatus.SUCCEED:
          if (resultExist) {
            msg = msg ? msg : 'Simulation completed!';
            msg = `==> ${msg}`;
            yield put(updateEndMsg(msg));
            yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
            yield put(updateProject(currentProjectId))
            break;
          } else {
            msg = msg ? msg : 'Simulation failed!';
            msg = `==> ${msg}`;
            yield put(updateEndMsg(msg));
            yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
            yield put(updateProject(currentProjectId))
            break;
          }
        case taskStatus.FAILED:
          msg = msg ? msg : 'Simulation failed!';
          msg = `==> ${msg}`;
          yield put(updateEndMsg(msg));
          yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
          yield put(updateProject(currentProjectId))
          break;
        default:
          break;
      }
      const { PDNReducer: { project } } = yield select();
      const { currentProjectPDNs } = project;
      const currentPDN = currentProjectPDNs.find(item => item.verificationId === verificationId);
      if (currentPDN) {
        let pdnId = currentPDN.id;
        // yield put(getPDNContent(pdnId));
        yield call(getVRMModelUpdate, { currentProjectId, PDNID: pdnId });
      }
    }
  } catch (error) {
    console.error(error);
    yield delay(1000);
    yield put(updateProgress({ verificationId, progress: -1 }));
    yield put(cleanSingleProgress(verificationId));
    yield put(changeVerificationList([]));
    yield put(updateStartMsg(null));
    const promise = yield call(checkVerificationStatus, verificationId);
    let resultExist = false;
    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(existResult(resultExist));
    const { PDNReducer: { project: { currentProjectId } } } = yield select();
    yield put(updateProject(currentProjectId))
  }
}

function* getMonitorLog(action) {
  const { workflowId, verificationId } = action;
  let active = true;
  let first = true;
  while (active) {
    // ==> Check whether it is in the current Monitor
    const cancelSimulation = yield call(cancelWorkflowJudge, { verificationId });
    if (cancelSimulation) {
      return;
    }
    if (!first) {
      yield delay(5000);
    }
    first = false;
    let { PDNReducer } = yield select();
    const { simulationReducer: { singleProgress } } = PDNReducer
    let progress = [];
    progress = singleProgress.filter(item => item.verificationId === verificationId);
    if (progress && progress.length > 0) {
      active = progress[0].progress > -1 && progress[0].progress <= 100;
    } else {
      active = false;
    }
    // get log
    try {
      const log = yield call(getMonitor, workflowId);
      if (log.length > 0) {
        yield put(updateSingleMonitor({ workflowId, monitor: monitorLog(log), verificationId }));
      }
    } catch (error) {
      console.error(error)
    }
  }
}

function* cancelWork(action) {
  const { verificationId } = action;
  yield call(cancelVerificationWorkflow, verificationId);
  yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
  yield put(updateEndMsg("==> Simulation cancelled!"));
}

export function* verificationWorkFlow(action) {
  const { verificationId, interfaceStatus } = action;
  if (!interfaceStatus || interfaceStatus.length === 0) {
    return;
  }
  let { id, status, progress, workflowType, taskList } = interfaceStatus[0];
  const getProfile = shouldGetProfile(taskList);
  let time;
  if (workflowType === 'PDN_History_Result_Parse') {
    return;
  }
  yield put(saveCurrentLog(null));
  yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));
  yield put(updateStartMsg("==> Start simulating..."));
  let waitingIndex = -1;
  waitingIndex = yield call(workflowWaitingIndex, id);
  // while()
  while (waitingIndex >= 0) {
    // ==> Check whether it is in the current Monitor
    const cancelSimulation = yield call(cancelWorkflowJudge, { verificationId });
    if (cancelSimulation) {
      return;
    }
    // ==> Check End

    time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    const { PDNReducer: { simulationReducer: { waitingQueue } } } = yield select();
    let queue = [...waitingQueue];
    const n = queue.findIndex(item => item.verificationId === verificationId);
    if (n > -1) {
      queue[n].queueIndex = waitingIndex;
      queue[n].waitingTime = waitingTime;
    } else {
      queue.push({
        queueIndex: waitingIndex,
        verificationId,
        waitingTime
      })
    }
    yield put(waitingIndexAction(queue));
    yield delay(5000);
    // update waiting index
    waitingIndex = yield call(workflowWaitingIndex, id);
  };

  const { PDNReducer: { project } } = yield select();
  let projectId = project.currentProjectId;
  if (waitingIndex === -2) {//-2  simulation cancel
    yield put(cleanSingleProgress(verificationId));
    yield put(updateStartMsg(null));
    yield put(updateEndMsg("==> Simulation cancelled!"));
    yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
    yield put(updateProject(projectId));
    return;
  }

  if (waitingIndex === -3) {//-3 simulation complete
    yield put(cleanSingleProgress(verificationId));
    yield put(updateStartMsg(null));
    yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
    yield put(updateProject(projectId));

    const promise = yield call(checkVerificationStatus, verificationId);
    let resultExist = false;

    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    if (resultExist) {
      yield put(updateEndMsg(`==> Simulation completed!`));
    } else {
      yield put(updateEndMsg(`==> Simulation failed!`));
    }
    return;
  }

  const { PDNReducer: { simulationReducer: { waitingQueue } } } = yield select();
  let queue = [...waitingQueue];
  const n = queue.findIndex(item => item.verificationId === verificationId);
  if (n > -1) {
    queue.splice(n, 1);
  }
  yield put(waitingIndexAction(queue));


  let pdnInfo = null, num = 1;
  while (!pdnInfo || (pdnInfo && Object.keys(pdnInfo).length === 0) || (pdnInfo.verificationId !== verificationId)) {
    if (num > 1) {
      yield delay(500);
    };
    const { PDNReducer: { pdn } } = yield select();
    pdnInfo = pdn.pdnInfo ? pdn.pdnInfo : null;
    num += 1;
    if (num === 5) {
      break;
    }
  }

  yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));
  yield put(updateStartMsg("==> Simulation running...\n==> Data processing..."));
  if (progress === 0) {
    progress += 2;
    yield put(updateProgress({ verificationId, progress }));
  };
  yield put(updateEndMsg(null));
  monitorTask = yield fork(getMonitorLog, { workflowId: id, verificationId });
  let indexNum = 0;
  while (status === taskStatus.RUNNING) {
    // ==> Check whether it is in the current Monitor
    const cancelSimulation = yield call(cancelWorkflowJudge, { verificationId });
    if (cancelSimulation) {
      return;
    }
    // ==> Check End
    if (getProfile && indexNum % 6 === 0) {
      yield put(getCurrentProfile(verificationId));
    }
    yield delay(5000);
    let _progress = progress;
    try {
      const { data: { data } } = yield call(getWorkFlow, id);
      if (!data) {
        status = taskStatus.FAILED;
      } else {
        status = data.status;
        _progress = data.progress;
      }
    } catch (error) {
      console.error(error)
    }
    indexNum += 1;

    progress = fakeProgress(progress, indexNum, _progress);
    yield put(updateProgress({ verificationId, progress }));
  }
  yield put(getCurrentProfile(verificationId));
  if (status === taskStatus.CANCEL) {
    yield put(cleanSingleProgress(verificationId));
  } else {
    yield put(updateProgress({ verificationId, progress }));
  }

  yield delay(2000);
  yield put(updateProgress({ verificationId, progress: -1 }));
  yield put(cleanSingleProgress(verificationId));
  yield put(updateStartMsg(null));
  yield put(changeVerificationList([]));
  const promise = yield call(checkVerificationStatus, verificationId);
  let resultExist = false;

  if (promise && promise.status) {
    if (promise.status === VERIFY_SUCCESS) {
      resultExist = true;
    }
  };
  yield put(existResult(resultExist));
  const { PDNReducer: { project: { currentProjectId, currentProjectPDNs } } } = yield select();
  yield put(updateProject(currentProjectId));
  yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
  yield delay(3000);
  yield call(getPdnLog, { verificationId });
  const currentPDN = currentProjectPDNs.find(item => item.verificationId === verificationId);
  // Re-extraction
  if (currentPDN) {
    let PDNID = currentPDN.id;
    // yield put(getPDNContent(PDNID));
    yield call(getVRMModelUpdate, { currentProjectId, PDNID });
  }
}

// Get VRM Model
function* getVRMModelUpdate(action) {
  const { currentProjectId, PDNID } = action;
  const data = yield call(getVRMModelFile, currentProjectId, PDNID);
  yield put(saveVRMModelUpdate(data ? resProcessing(data) : ''));
}

// Get log file
function* getPdnLog(action) {
  const { verificationId } = action;
  let _log = '', promise = undefined;
  try {
    promise = yield call(checkVerificationStatus, verificationId);
  } catch (error) {
    console.error(error);
  }
  let existLog = false;

  if (promise && promise.status) {
    if (promise.status !== VERIFY_NEVER && promise.status !== VERIFY_RUNNING && promise.status !== WAITING) {
      existLog = true;
    }
  };
  if (existLog) {
    let log = null;
    try {
      log = yield call(getPdnSimulationLog, verificationId);
      if (log && !log.includes('No simulation.')) {
        _log = log;
      }
      yield put(getCurrentProfile(verificationId));
    } catch (error) {
      console.error(error)
    }
  }
  yield put(saveCurrentLog(_log));
}

function* getProfileLog(action) {
  const { verificationId } = action;
  let profileLog = [];
  try {
    profileLog = yield call(getProfileData, verificationId);
  } catch (error) {
    console.error(error)
  }
  yield put(saveCurrentProfileLog(parseProfile(profileLog)))
}

function* libraryDataErrorCheck(action) {
  const { verificationId, dataType } = action;
  const { PDNReducer: { project, pdn: { pdnInfo }, simulationReducer: { currentCheckDataId } } } = yield select();
  const { VRMNames, DecapNames } = project;
  let currentVerificationId = project.verificationId;
  let pdnContent = pdnInfo && pdnInfo.pdnContent ? pdnInfo.pdnContent : null;
  if (verificationId) {  // After click simulate
    yield put(saveCurrentCheckId(verificationId))
  } else if (!verificationId && (currentVerificationId !== currentCheckDataId || !pdnContent)) {//After switching pdn
    yield put(saveCurrentCheckId(null));
    yield put(vrmErrorInfo(null));
    yield put(decapErrorInfo(null));
    VRMData.cleanContent();
    DecapData.cleanContent();
    return false;
  }

  if (dataType && dataType === 'VRM') { // after modified vrm data, update check
    //get vrm model ids
    const { vrmIds, vrmInfo } = getVRMDataIds(pdnContent);

    //get vrm model content
    //vrmData:  { id1 : {R: "", L: ""}, id2 : {R: "", L: ""}}
    const vrmData = yield call(getVRMDataInfo, vrmIds);
    //vrm data error check
    const vrmErrorCheck = getVRMErrorCheck(vrmData, VRMNames, vrmInfo);
    if (vrmErrorCheck) {
      yield put(vrmErrorInfo(vrmErrorCheck, currentVerificationId))
    } else {
      yield put(vrmErrorInfo(null))
    }
  } else if (dataType && dataType === 'decap') { // after modified vrm data, update check
    //get decap model ids(file libraryType is data).
    const { decapIds, decapInfo } = getDecapDataIds(pdnContent);

    //get decap model content(library type is data)
    //decapData:  { id1 : {R: "", L: "",  C: ""}, id2 : {R: "", L: "",  C: ""}}
    const decapData = yield call(getDecapDataInfo, decapIds);
    const decapErrorCheck = getDecapErrorCheck(decapData, DecapNames, decapInfo);
    if (decapErrorCheck) {
      yield put(decapErrorInfo(decapErrorCheck, currentVerificationId))
    } else {
      yield put(decapErrorInfo(null))
    }

  } else {
    //get vrm model ids
    const { vrmIds, vrmInfo } = getVRMDataIds(pdnContent);
    //get vrm model content
    //vrmData:  { id1 : {R: "", L: ""}, id2 : {R: "", L: ""}}
    const vrmData = yield call(getVRMDataInfo, vrmIds);
    //get decap model ids(file libraryType is data).
    const { decapIds, decapInfo } = getDecapDataIds(pdnContent);

    //get decap model content(library type is data)
    //decapData:  { id1 : {R: "", L: "",  C: ""}, id2 : {R: "", L: "",  C: ""}}
    const decapData = yield call(getDecapDataInfo, decapIds);

    //vrm data error check
    const vrmErrorCheck = getVRMErrorCheck(vrmData, VRMNames, vrmInfo);
    if (vrmErrorCheck) {
      yield put(vrmErrorInfo(vrmErrorCheck, currentVerificationId))
    } else {
      yield put(vrmErrorInfo(null))
    }

    const decapErrorCheck = getDecapErrorCheck(decapData, DecapNames, decapInfo);
    if (decapErrorCheck) {
      yield put(decapErrorInfo(decapErrorCheck, currentVerificationId));
    } else {
      yield put(decapErrorInfo(null))
    }

    return { vrmErrorCheck: vrmErrorCheck, decapErrorCheck: decapErrorCheck }
  }
}

function* _clearSimulationInfo(action) {
  const { verificationId } = action;
  //clear current verification simulation info (monitor, progress, monitor, pre_process)
  yield put(cleanSingleProgress(verificationId));
  yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
  yield put(cleanMonitor(verificationId));
  yield put(updateEndMsg(null));
  yield put(updateStartMsg(null));
  yield put(changeUploadMes(null));
}

function* reCheckSimulationStackup(action) {
  const { PDNReducer: { pdn } } = yield select();
  if (pdn && pdn.pdnInfo) {
    const stackup = action.data;
    const stackupError = yield call(stackupCheck, stackup);
    if (stackupError) {
      yield put(pdnStackupCheckError({ error: stackupError, verificationId: pdn.pdnInfo.verificationId }));
      return;
    } else {
      yield put(pdnStackupCheckError(null));
    }
  }
}

function* _updateCurrentSimulationMsg(action) {
  const { verificationId } = action;
  yield put(changeUploadMes(null))
  //clear monitor
  yield put(cleanMonitor(verificationId));
  //clear log
  yield put(saveCurrentLog(null));
  //clear end message
  yield put(updateEndMsg(null));
  yield put(saveCurrentCheckId(null));
  //clear error check
  yield put(vrmErrorInfo(null));
  yield put(decapErrorInfo(null));
  yield put(pdnCheckError(null));
  yield put(pdnStackupCheckError(null));
  //update start message
  yield put(updateStartMsg("Checking setup..."));
}


function* cancelWorkflowJudge({ verificationId }) {
  const { LoginReducer: { pageType } } = yield select();
  // ==> Check whether it is in the current Monitor
  // Get opened monitor verification id
  const { TabMonitorReducer: { currentVerificationId } } = yield select();
  // Switch account or switch PDN
  if (pageType !== FASTPI || currentVerificationId !== verificationId) {
    return true;
  }
}

function* _getPDNWorkflow(action) {
  const { verificationId } = action;
  let interfaceStatus = null;
  try {
    interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
  } catch (error) {
    console.error(error);
  }
  if (interfaceStatus) {
    // PDN_History_Result_Parse    result import snp file
    let verificationStatus = interfaceStatus.filter(item => item.workflowType !== 'PDN_History_Result_Parse');
    if (verificationStatus && verificationStatus.length > 0) {
      yield call(cancelWorkflowTask);
      // Get workflow
      const task = yield fork(verificationWorkFlow, { verificationId, interfaceStatus: verificationStatus });
      saveWorkflowTask(task);
      return;
    } else {
      //clear current verification simulation info (monitor, progress, monitor, pre_process)
      yield put(clearSimulationInfo(verificationId));
      yield put(getCurrentPDNLog(verificationId));
    }
  } else {
    //clear current verification simulation info (monitor, progress, monitor, pre_process)
    yield put(clearSimulationInfo(verificationId));
    yield put(getCurrentPDNLog(verificationId));
  }
  let promise = null;
  try {
    promise = yield call(checkVerificationStatus, verificationId);
  } catch (error) {
    console.error(error);
  }
  let resultExist = false;
  if (promise && promise.status) {
    if (promise.status === VERIFY_SUCCESS) {
      resultExist = true;
    };
  };
  yield put(existResult(resultExist));
}

function* simulationSaga() {
  yield takeEvery(START_PDN_VERIFICATION, startVerification);
  yield takeLatest(GET_SINGLE_MONITOR, getMonitorLog);
  yield takeEvery(CANCEL_WORKFLOW, cancelWork);
  yield takeEvery(DEBUG_VERIFY, startDebugVerify);
  yield takeLatest(GET_CURRENT_LOG, getPdnLog);
  yield takeEvery(UPDATE_LIBRARY_DATA_CHECK, libraryDataErrorCheck);
  yield takeEvery(CLEAR_SIMULATION_INFO, _clearSimulationInfo);
  yield takeLatest(RE_CHECK_SIMULATION_STACKUP, reCheckSimulationStackup);
  yield takeEvery(GET_VRM_MODEL, getVRMModelUpdate);
  yield takeEvery(UPDATE_SIMULATION_MESSAGE, _updateCurrentSimulationMsg);
  yield takeLatest(GET_CURRENT_PROFILE, getProfileLog);
  yield takeEvery(GET_PDN_WORKFLOW, _getPDNWorkflow);
}

export default simulationSaga;