import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ResultTable from '@/components/ResultTable';

class viaContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
  }

  getHeight = () => {
    const content = document.getElementById("pdn-content-main")
    if (content) {
      const height = content.offsetHeight - 250 || 500;
      return height > 500 ? height : 500;
    }
    return 500
  }
  render() {
    const { DCResultData, DCSelectedKey, IRTableData, DCResultKey, verificationId, resultPowerDomainData, tableColumns, chartXType, chartYType, svgHeight, DCResultTableLoading, rowKey, chartVisible } = this.props;
    const height = this.getHeight()
    return (
      <div className="PDN-result-DC-content">
        <Spin spinning={DCResultTableLoading}>
          <ResultTable
            tableColumns={tableColumns}
            selectedKey={DCSelectedKey}
            tableData={DCResultData}
            height={height}
            resultPowerDomainData={resultPowerDomainData}
            resultKey={DCResultKey}
            verificationId={verificationId}
            chartXType={chartXType}
            chartYType={chartYType}
            svgHeight={svgHeight}
            rowKey={rowKey}
            chartVisible={chartVisible}
          />
        </Spin>
      </div>
    )
  }
}

const mapState = (state) => {
  const { resultReducer: { DCResultKey, DCMenuList, DCSelectedKey, DCResultData = [], resultPowerDomainData, DCResultTableLoading }, simulationReducer: { existResult }, project: { verificationId, currentProjectId, PDNID } } = state.PDNReducer
  return {
    DCResultKey,
    verificationId,
    DCMenuList,
    DCSelectedKey,
    DCResultData,
    resultPowerDomainData,
    DCResultTableLoading
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(viaContent)
