import {
  COMPONENT_SETTING_UPDATE,
  RESET_SETUP,
  SET_POWER_TREE_DATA,
  UPDATE_LOADING_PAGE,
  UPDATE_ERROR,
  SELECT_PCB,
  OPEN_PIN_SELECT_PANEL,
  UPDATE_TREE_LOADING,
  DRAW_TREE,
  UPDATE_RUNNING_STATUS,
  GET_RESULTS,
  SHOW_RESULTS,
  SET_TREE_TIP,
  UPDATE_TREE_MONITOR,
  GET_SPICE_RESULTS
} from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { POWER_TREE_VERSION } from '@/version';
import { TreePlotSetting } from '../../../../services/Cascade/PowerTree';

const defaultSetup = {
  COMP_PREFIX_LIB: {
    version: '0.0.1'
  },
  powerTrees: [],
  designId: "",
  updateCompPrefix: false,
  pinSelect: null,
  treeLoading: {},
  reset: false,
  running: false,
  results: {
    leafNodePassResults: [],
    pinPairResResults: [],
    pinVoltageResults: [],
    loadTotalResResults: []
  },
  showResult: {
    pass: true,
    pairRes: true,
    voltage: true
  },
  option: {},
  extraction: {},
  treeTip: '',
  treeMonitorLogs: [],
  spiceSimulation: {},
  spiceResults: {
    leafNodePassResults: [],
    pinPairResResults: [],
    pinVoltageResults: [],
    loadTotalResResults: []
  }
}

const defaultState = {
  verificationId: null,
  error: [],
  loading: false,
  version: POWER_TREE_VERSION,
  resultExist: false,
  draw: {},
  refreshResults: false,
  setting: new TreePlotSetting(),

  ...defaultSetup
};

export const DesignTreeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_POWER_TREE_DATA:
      return {
        ...state,
        ...action.params
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case COMPONENT_SETTING_UPDATE:
      return {
        ...state,
        updateCompPrefix: action.update
      }
    case RESET_SETUP:
      return {
        ...state,
        ...defaultSetup
      }
    case UPDATE_LOADING_PAGE:
      return {
        ...state,
        loading: action.loading
      }
    case UPDATE_ERROR:
      return {
        ...state,
        error: action.error
      }
    case SELECT_PCB:
      return {
        ...state,
        designId: action.designId
      }
    case OPEN_PIN_SELECT_PANEL:
      return {
        ...state,
        pinSelect: action.id
      }
    case UPDATE_TREE_LOADING:
      const { id, tip } = action;
      return {
        ...state,
        treeLoading: id ? {
          ...state.treeLoading,
          [id]: tip
        } : {}
      }
    case DRAW_TREE:
      return {
        ...state,
        draw: {
          ...state.draw,
          [action.id]: action.bool
        }
      }
    case UPDATE_RUNNING_STATUS:
      return {
        ...state,
        running: action.status
      }
    case GET_RESULTS:
      return {
        ...state,
        results: action.results ? action.results : {
          leafNodePassResults: [],
          pinPairResResults: [],
          pinVoltageResults: [],
          loadTotalResResults: []
        }
      }
    case SHOW_RESULTS:
      return {
        ...state,
        showResult: action.show
      }
    case SET_TREE_TIP:
      return {
        ...state,
        treeTip: action.tip
      }
    case UPDATE_TREE_MONITOR:
      return {
        ...state,
        treeMonitorLogs: action.replace ? action.logs : [...state.treeMonitorLogs, ...action.logs]
      }
    case GET_SPICE_RESULTS:
      return {
        ...state,
        spiceResults: action.results ? action.results : {
          leafNodePassResults: [],
          pinPairResResults: [],
          pinVoltageResults: [],
          loadTotalResResults: []
        }
      }
    default: return state
  }
} 