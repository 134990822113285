import React, { Component, Fragment } from 'react';
import TreePlot from '@/services/Cascade/PowerTree/treePlot';
import TreeComponent from './treeComponent';
import { DESIGN_TREE, POWER_TREE, SINGLE_TREE } from '../../../../constants/treeConstants';
import debounce from '../../../../services/helper/debounceFn';
import './tree.css';

class TreeCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 800,
      gridWidth: 400,
      gridHeight: 200,
      posUpdate: false,
      columns: 6,
      selectkey: "",
      width: props.width || 1000
    }
  }

  componentDidMount() {
    this.getHeight(true);
    this.props.canvasRef && this.props.canvasRef(this);
  }

  componentDidUpdate = (prevProps) => {
    const { width, drawStatus, connections, includeInd } = this.props;
    if (width !== prevProps.width) {
      this.getHeight(false);
    }

    if (drawStatus) {
      this.props.updateDrawType(false);
      this.brush && this.brush.clear();
      this.getHeight(true);
    }

    if (connections.length !== prevProps.connections.length) {
      this.getHeight(true);
    }

    if (includeInd !== prevProps.includeInd) {
      this.getHeight(false);
    }
  }

  getHeight = (init) => {
    const { branch, width, deep, setting } = this.props;
    const { autoWidth, autoHeight, gridWidth: settingWidth, gridHeight: settingHeight } = setting;
    const minColumns = autoWidth ? Math.round(width / 280) : Math.round(width / Number(settingWidth));
    const columns = branch.length > minColumns ? branch.length : minColumns;
    const minGridWidth = autoWidth ? 200 : Number(settingWidth);
    const gridWidth = Math.round(width / columns);
    const _gridWidth = gridWidth < minGridWidth ? minGridWidth : gridWidth;
    const gridHeight = autoHeight ? Math.round(0.6 * _gridWidth) : Number(settingHeight);
    const height = gridHeight * deep;
    this.setState({
      height,
      gridWidth: _gridWidth,
      gridHeight,
      columns,
      posUpdate: true,
      width: gridWidth < minGridWidth ? columns * minGridWidth : width - 5
    }, () => {
      init ? this.initPlot() : this.resetPlot();
    })
  }

  initPlot = () => {
    const { branch, deep, groundNet, root, id, pcbIndex, connections, includeInd } = this.props;
    const { height, columns, width } = this.state;
    this.brush = new TreePlot({ id: `power-tree-canvas-${id}`, branch, width, deep, groundNet, root, height, columns, pcbIndex, connections, includeInd });
    this.draw();
  }

  resetPlot = () => {
    if (!this.brush) {
      this.initPlot();
    } else {
      const { includeInd, pcbIndex } = this.props;
      const { height, columns, width } = this.state;
      this.brush.reDraw({ width, height, columns, includeInd, pcbIndex });
      this.draw();
    }
  }

  draw = async () => {
    const { id } = this.props;
    this.updateTip('Drawing Power Tree...');
    debounce(async () => {
      this.brush.clear();
      await this.brush.draw();
      this.updateTip('');
    }, 1000, false, `power-tree-canvas-${id}`)();
  }

  updateTip = (tip) => {
    const { id } = this.props;
    this.props.updateTreeLoading(id, tip)
  }

  updatePosition = (pos) => {
    this.setState({ posUpdate: pos });
  }

  showCurrentTree = (e, { data, columnIndex }) => {
    e && e.stopPropagation();
    const { name, prevNet, prevComp } = data;
    const { branch } = this.props;
    let comps = [name], nets = [prevNet], prevComps = [prevComp];
    for (let i = columnIndex - 1; i >= 0; i--) {
      let _prevComps = [...prevComps];
      const parents = branch[i].filter(item => _prevComps.includes(item.name));
      prevComps = [];
      for (let item of parents) {
        const { name, prevComp, prevNet, anotherPrev = [] } = item;
        comps.push(name);
        if (prevNet) {
          nets.push(prevNet);
        }
        if (prevComp) {
          prevComps.push(prevComp);
        }
        if (anotherPrev.length) {
          prevComps.push(...anotherPrev)
        }
      }
    }
    this.props.showTree({ comps, nets })
  }

  changePCBKeys = (e, key) => {
    e && e.stopPropagation();
    const selectkey = this.state.selectkey;
    let _key = key;
    if (selectkey === _key) {
      _key = ""
    }
    this.setState({
      selectkey: _key
    })
  }

  getCanvas = () => {
    const { id } = this.props;
    return document.getElementById(`power-tree-layout-${id}`);
  }

  render() {
    const {
      id, branch, root, width: screenWidth, groundNet, showLayout, running, results, showResult, multiResults,
      resultDisplay, driverList, pcbIndex = [], view, rootPCBId, rootPCBKey, refreshResults, shot, includeInd,
      whatIf, whatIfData, multiSpiceResults, spiceResults, setting
    } = this.props;
    const { height, gridWidth, gridHeight, posUpdate, selectkey, width } = this.state;
    const rootColumn = branch.length ? branch[0] : [];
    let passResult = [], pairResult = [], voltageResult = [], spiceVoltageResult = [], totalResResults = [], spiceTotalResult = [];
    if (view === DESIGN_TREE || view === SINGLE_TREE) {
      const { leafNodePassResults = [], pinPairResResults = [], pinVoltageResults = [], loadTotalResResults = [] } = results;
      passResult = leafNodePassResults.filter(item => item.id === id);
      pairResult = pinPairResResults;
      voltageResult = pinVoltageResults;
      totalResResults = loadTotalResResults;
      const { pinVoltageResults: spicePinVoltage = [], loadTotalResResults: spiceResResults = [] } = spiceResults;
      spiceVoltageResult = spicePinVoltage;
      spiceTotalResult = spiceResResults
    }
    let _pcbId = rootPCBId, _pcbKey = rootPCBKey;
    let pcbShow = pcbIndex.filter(item => item.color !== '#FFFFFF');
    const compRelationShip = branch.flat().filter(item => item.broComp).map(item => [item.name, item.broComp.name, item.pcbKey])
    return <div id={`power-tree-${id}`}>
      {pcbIndex.length > 1 && <div className='cascade-power-tree-pcb-name-title'>{
        pcbIndex.map((item, index) => <span
          key={item.key}
          className={`tree-tip ${index ? '' : 'tree-forbid-tip'} ${selectkey === item.key ? 'tree-select-tip' : ''}`}
          onClick={(e) => index ? this.changePCBKeys(e, item.key) : null}
        >
          {index ? '|' : ''}
          {!index || shot ? <span className='tree-tip-forbidden-color' style={{ backgroundColor: item.color }}></span>
            : <input
              type='color'
              className={`tree-tip-color tree-tip-color-select`}
              value={item.color}
              onChange={(e) => this.props.colorChange(e, index)}
            />}
          <span style={{ backgroundColor: item.color }} className="tree-tip-name">{item.pcbName}</span>
        </span>)
      }</div>}
      <div className='cascade-power-tree-canvas' id={`power-tree-layout-${id}`} style={width > screenWidth ? { overflowX: 'auto' } : { overflowX: 'hidden' }}>
        {branch.map((column, index) => {
          const connectorDisplayList = [];
          return column.map((item, index_d) => {
            let connectorData = []
            if (view === POWER_TREE) {
              _pcbId = item.pcbId || rootPCBId;
              _pcbKey = item.pcbKey || rootPCBKey;
              const current = multiResults.find(i => _pcbKey && _pcbKey === i.pcbKey);
              if (current && current.results) {
                const { leafNodePassResults = [], pinPairResResults = [], pinVoltageResults = [], loadTotalResResults = [] } = current.results;
                passResult = leafNodePassResults.filter(i => i.id === id);
                pairResult = pinPairResResults;
                voltageResult = pinVoltageResults;
                totalResResults = loadTotalResResults;
              } else {
                passResult = [];
                pairResult = [];
                voltageResult = [];
                totalResResults = [];
              }
              const spiceCurrent = multiSpiceResults.find(i => _pcbKey && _pcbKey === i.pcbKey);
              if (spiceCurrent && spiceCurrent.results) {
                const { pinVoltageResults = [], loadTotalResResults = [] } = spiceCurrent.results;
                spiceVoltageResult = pinVoltageResults;
                spiceTotalResult = loadTotalResResults;
              } else {
                spiceVoltageResult = [];
                spiceTotalResult = [];
              }
            }
            let notDisplayBox = false;
            const _voltageResult = voltageResult.filter(v => v.component === item.name);
            const _spiceVoltageResult = spiceVoltageResult.filter(v => v.component === item.name)
            if (item.type === 'Connector') {
              const connKey = `${item.name}-${item.pcbKey}-${index}`;
              const filterConns = connectorDisplayList.filter(i => i.key === connKey);
              const findIndex = filterConns.findIndex(i => i.indexs.includes(item.deepIndex))
              if (findIndex > -1) {
                notDisplayBox = true;
              } else if (item.deepIndex && item.deepIndex > -1 && !isNaN(item.deepIndex + item.grid)) {
                connectorDisplayList.push({ key: connKey, indexs: Array.from(new Array(item.deepIndex + item.grid).keys()).slice(item.deepIndex) })
                connectorData.push(...column.filter((i, index_c) => index_c >= index_d && i.name === item.name && item.pcbKey === i.pcbKey && i.type === 'Connector'))
              }
            }
            const show = pcbShow.find(s => s.key === item.pcbKey);
            if (show) {
              pcbShow = pcbShow.filter(s => s.key !== item.pcbKey);
            }
            return !item.hidden && <TreeComponent
              key={`${index}-${index_d}`}
              gridWidth={gridWidth}
              gridHeight={gridHeight}
              columnIndex={index}
              root={root}
              prevComps={branch[index - 1 >= 0 ? index - 1 : 0]}
              broComps={branch[index]}
              nextComps={branch[index + 1] || []}
              allComps={branch}
              rootItem={rootColumn.length ? rootColumn[0] : {}}
              data={item}
              passResult={passResult}
              voltageResult={_voltageResult}
              spiceVoltageResult={_spiceVoltageResult}
              pairResult={pairResult}
              totalResResults={totalResResults}
              spiceTotalResult={spiceTotalResult}
              groundNet={groundNet}
              posUpdate={posUpdate}
              showLayout={showLayout}
              running={running}
              updatePosition={this.updatePosition}
              saveTreeItemValue={this.props.saveTreeItemValue}
              showCurrentTree={this.showCurrentTree}
              deleteICPath={this.props.deleteICPath}
              showResult={showResult}
              resultDisplay={resultDisplay}
              driverList={driverList}
              connectorData={connectorData}
              notDisplayBox={notDisplayBox}
              selectkey={selectkey}
              refreshResults={refreshResults}
              shot={shot}
              showPCB={show}
              includeInd={includeInd}
              whatIf={whatIf}
              whatIfData={whatIfData}
              saveWhatIfData={this.props.saveWhatIfData}
              setting={setting || {}}
              compRelationShip={compRelationShip}
            />
          })
        })}
        <canvas id={`power-tree-canvas-${id}`} className="power-tree-canvas" width={width} height={height} />
      </div>
    </div >
  }
}

export default TreeCanvas;