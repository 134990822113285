import http from '../http';

/**
 * Get file in rocky verification, response: file
 *
 * @export
 * @param {*} filePath {verificationSubId}/{interfaceSubId}...
 * @returns
 */
export function getFileInRockyVerification({ channelId, filePath }) {
  return http.get(`/channel/${channelId}/rocky/result?filePath=${filePath}`);
}

export function getSSNFileInRockyVerification({ channelId, filePath }) {
  return http.get(`/rocky/ssn/channel/result/SignalVerification?channelId=${channelId}&filePath=${filePath}`);
}

/**
 * Get picture file in rocky verification, response: file
 *
 * @export
 * @param {*} filePath {verificationSubId}/{interfaceSubId}...
 * @returns
 */
export function getPicInRockyVerification(channelId, filePath) {
  return http.get(`/channel/${channelId}/rocky/result?filePath=${filePath}`, {
    responseType: 'arraybuffer'
  });
}
/**
 * Get eye diagram 
 * @export
 * @param {string} channelId
 * @param {string} victimNet
 * @param {string} type  write/read
 * @param {string} fileName eye_diagram.png
 * @returns
 */
export function getEyeDiagram(channelId, victimNet, type, fileName, memory) {
  return http.get(`/rocky/ssn/channel/result/eye?channelId=${channelId}&victimNet=${victimNet}&type=${type}&fileName=${fileName}&memory=${memory}`, {
    responseType: 'arraybuffer'
  });
}

/**
 * Get eye diagram 
 * @export
 * @param {string} channelId
 * @param {string} victimNet
 * @param {string} type  write/read
 * @param {string} fileName eye_diagram.png
 * @returns
 */
export function getEyeParameter(channelId, victimNet, type, fileName, memory) {
  return http.get(`/rocky/ssn/channel/result/eye?channelId=${channelId}&victimNet=${victimNet}&type=${type}&fileName=${fileName}&memory=${memory}`);
}

export function getPTSPVerification(verificationId) {
  return http.get(`/rocky/ssn/channel/content?verificationId=${verificationId}`)
}

export function getPTSPWaveform({ channelId, victimNet, patternName, option, type, displayMode }) {
  return http.get(`/rocky/ssn/channel/result/waveform?channelId=${channelId}&victimNet=${victimNet}&patternName=${patternName}&option=${option}&type=${type}&displayMode=${displayMode}`)
}

export function getPTSWDiffSignalName({ channelId, verificationId, type }) {
  return http.get(`/rocky/ssn/channel/result/DifferentialName?channelId=${channelId}&verificationId=${verificationId}&type=${type}`)
}

export function getAMIFileInRockyVerification(signalName, channelId, type, fileName, verificationId) {
  return http.get(`/channel/${channelId}/rocky/stat-eye/result?signalName=${signalName}&type=${type}&fileName=${fileName}&verificationId=${verificationId}`);
}

export function getAMIPicInRockyVerification(signalName, channelId, type, fileName, verificationId) {
  return http.get(`/channel/${channelId}/rocky/stat-eye/result?signalName=${signalName}&type=${type}&fileName=${fileName}&verificationId=${verificationId}`, {
    responseType: 'arraybuffer'
  });
}

/**
 * Get verification content by verification id
 *
 * @export
 * @param {string} verificationId
 * @returns
 */
export function getVerificationContentByID(verificationId) {
  return http.get(`/verification/${verificationId}/rocky`);
};

export function getDimmCardRankIdById({ id, type }) {
  return http.get(`/design/rocky/dimm/card/rank?id=${id}&type=${type}`)
}

/**
 * update rocky verification info
 *
 * @export
 * @param {string} id  verificationId
 * @param {string} name
 * @param {array[object]} interfaces:[{},{}]
 * @param {num} readyForSim  0/1
 * @param {string} version  setup version
 * @param {string} designVersion
 * @param {object} config
 * @param {string} ifDoExtraction 0/1
 * @returns
 */
export function updateRockyVerificationContent({ id, name, interfaces, config, ifDoExtraction, readyForSim, version, designVersion }) {
  return http.put(`/verification/rocky`, {
    id,
    name,
    interfaces,
    config,
    ifDoExtraction,
    readyForSim,
    version,
    designVersion
  });
}

/**
 * check adjusted eye exist or not
 *
 * @export
 * @param {string} id  verificationId
 * @returns
 */
export function getRockyResultHaveTrain(verificationId) {
  return http.get(`rocky/eye-diagram/ajusted/exist?verificationId=${verificationId}`);
}


/**
 * Get whether there is package data that meets the requirements
 * 2022/11/22
 * @export
 * @param {string} channelId/verificationId  packageDesignId/designId
 * @returns
 */
export function getHavePackageVerification({ designId, verificationId, category }) {
  return http.get(`/rocky/package/channel/exist?designId=${designId}&verificationId=${verificationId}&category=${category}`)
}

/**
 * Get package extraction data
 *
 * @export
 * @param {string} designId
 * @returns
 */
export function getPackageExtraction(designId) {
  return http.get(`/design/rocky/package/extraction-config?designId=${designId}`)
}

/**
 * Update package extraction data
 *
 * @export
 * @param {string} designId  extractionConfig
 * @returns
 */
export function updatePackageExtraction({ designId, extractionConfig }) {
  return http.post(`/design/rocky/package/${designId}/extraction-config`, extractionConfig)
}

/**
 * update package channel info
 * 2022/11/22
 * @export
 * @param {string} packageVerificationList
 * @returns
 */
export function updatePackageVerification(packageVerificationList) {
  return http.post(`/rocky/package/channel/edit`, packageVerificationList)
}

/**
 * get package channel list
 * 2022/11/29
 * @export
 * @param {string} channelId  packageDesignId
 * @returns
 */
export function getPackageVerificationList(designId) {
  return http.get(`/rocky/package/channel-list?designId=${designId}`)
}

/**
 * get current package channel info
 * 2022/11/29
 * @export
 * @param {string} packageVerificationId
 * @returns
 */
export function getPackageVerificationInfo(packageVerificationId) {
  return http.get(`/rocky/package/channel?packageChannelId=${packageVerificationId}`)
}

/**
 * Get file in rocky package verification, response: file
 *
 * @export
 * @param {*} filePath {channelId}/{type}...
 * @returns
 */
export function getFileInRockyPackageVerification({ channelId, filePath, folder }) {
  if (folder) {
    return http.get(`/rocky/package/result-file?channelId=${channelId}&type=${filePath}&folder=${folder}`);
  } else {
    return http.get(`/rocky/package/result-file?channelId=${channelId}&type=${filePath}`);
  }
}

/**
 * delete package verification
 *
 * @export
 * @param {*} filePath {packageVerificationId}
 * @returns
 */
export function deletePackageVerification(channelId) {
  return http.post(`/rocky/package/channel-delete?channelId=${channelId}`);
}

/**
 * rename package verification
 *
 * @export
 * @param {*} filePath {channelId}/{type}...
 * @returns
 */
export function changePackageVerification({ name, packageChannelId }) {
  return http.post(`/rocky/package/channel/name/update?name=${name}&packageChannelId=${packageChannelId}`);
}

/**
 * rename package channel
 *
 * @export
 * @param {*} filePath  newName, oldName, packageId...
 * @returns
 */
export function renamePackageChannel({ newName, oldName, packageId }) {
  return http.post(`/rocky/package/channel/group-name/update?newName=${newName}&oldName=${oldName}&packageId=${packageId}`);
}

/**
 * Get the TDR result of the interface.
 *
 * @export
 * @param {*} channelId
 * @returns
 */
export function getTDRSetting(channelId) {
  return http.get(`/rocky/simulation/channel/result/setting`, { params: { channelId } })
}

export function generateTDR(params) {
  // return http.post(`/rocky/simulation/channel/result-parser`, params)
}

/**
 * Get the package TDR result of the interface.
 *
 * @export
 * @param {*} channelId
 * @returns
 */
export function getRockyPackageTDR(channelId, writeOrRead) {
  return http.get(`/rocky/package/tdr?channelId=${channelId}&writeOrRead=${writeOrRead}`)
}

/**
 * Get the TDR config of the interface.
 *
 * @export
 * @param {*} verificationId
 * @returns
 */
export function getTDRConfig(verificationId) {
  return http.get(`/verification/result/tdr-config?verificationId=${verificationId}`)
}

/**
 * Get the package TDR config of the interface.
 *
 * @export
 * @param {*} channelId
 * @returns
 */
export function getPackageTDRConfig(channelId) {
  return http.get(`/rocky/package/tdr-config?channelId=${channelId}`)
}

export function getPackageResultInfo(channelId) {
  return http.get(`/rocky/package/verification-json?channelId=${channelId}`)
}

export function getPackagePDN(designId) {
  return http.get(`/rocky/pdn?designId=${designId}`)
}

export function getPackagePDNInfo(id) {
  return http.get(`/rocky/pdn-detail?pdnId=${id}`)
}

export function updatePackagePDNInfo({ id, name, content }) {
  return http.post(`/rocky/pdn`, {
    id,
    name,
    content
  })
}

export function getPackagePDNResult({ pdnId, verificationId }) {
  if (verificationId) {
    return http.get(`/rocky/pdn/impedance/result?verificationId=${verificationId}`)
  } else {
    return http.get(`/rocky/pdn/impedance/result?pdnId=${pdnId}`)
  }
}

export function getPackagePDNNpiFile({ verificationId, fileName, pdnId }) {
  if (verificationId) {
    return http.get(`/rocky/pdn/npi?verificationId=${verificationId}&fileName=${fileName}`)
  }
  return http.get(`/rocky/pdn/npi?pdnId=${pdnId}&fileName=${fileName}`)
}

export function getPackagePDNCurves({ cols, rows, type, verificationId, fileName, ssnVerificationId }) {
  if (ssnVerificationId) {
    return http.post(`/rocky/pdn/curve`, {
      curveRequestDTO: { cols, rows, type }, verificationId: ssnVerificationId, fileName
    })
  } else {
    return http.post(`/rocky/pdn/curve`, {
      curveRequestDTO: { cols, rows, type }, rockyPackagePdnId: verificationId, fileName
    })
  }
}

export function getDecapEffectiveRLfileById(pdnId) {
  return http.get(`/rocky/pdn/decap/table?pdnId=${pdnId}`)
}

export function getImpedanceRelated({ pdnId, verificationId }) {
  if (verificationId) {
    return http.get(`rocky/pdn/powerground/map?verificationId=${verificationId}`)
  } else {
    return http.get(`rocky/pdn/powerground/map?pdnId=${pdnId}`)
  }
}

export function getPackagePDNJson(pdnId) {
  return http.get(`/rocky/pdn/sim/json?pdnId=${pdnId}`)
}

/* Multiple Byte */
export function createModelingInfo(verificationIds) {
  return http.post(`/interface/multiple/create`, verificationIds)
}

export function getModelingList(channelId) {
  return http.get(`/interface/multiple/list?channelId=${channelId}`)
}

export function deleteModelingInfo({ channelId, verificationIds }) {
  return http.post(`/interface/multiple/delete?channelId=${channelId}`, verificationIds)
}

// ssn
export function getSSNResultChannelContent(verificationId) {
  return http.get(`rocky/ssn/channel/content?verificationId=${verificationId}`)
}

export function getSSNResultChannelExistList(channelId) {
  return http.get(`rocky/ssn/channel/single-pattern/history?channelId=${channelId}`)
}

/**
 * Get Interface data after merging
 *
 * @export
 * @param  {string} channelId
 * @returns
 */
export function getPCBChannelContent(channelId) {
  return http.get(`/channel/${channelId}/ssn/interface`)
}

/**
 * update Interface data after merging
 *
 * @export
 * @param  {string} channelId
 * @returns
 */
export function updatePCBChannelContent(info) {
  return http.post(`/channel/ssn/interface`, { ...info })
}

/**
 * Get Interface data result after merging
 *
 * @export
 * @param  {string} channelId
 * @returns
 */
export function getPCBChannelResultContent(verificationId) {
  return http.get(`/channel/${verificationId}/ssn/interface`)
}

export function getResultConfig(verificationId) {
  return http.get(`rocky/ssn/channel/result/config?verificationId=${verificationId}`)
}

export function updateResultConfig({ verificationId, config }) {
  return http.post(`rocky/ssn/channel/result/config?verificationId=${verificationId}`, config)
}

export function getResultSetting({ verificationId, channelId }) {
  return http.get(`/rocky/ssn/channel/result/setting?verificationId=${verificationId}&channelId=${channelId}`)
}

export function generateSEVResult({ verificationId, channelId, config }) {
  return http.post(`rocky/ssn/channel/layout/pproc?verificationId=${verificationId}&channelId=${channelId}`, config)
}

export function getPreLayoutResultJson(verificationId) {
  return http.get(`rocky/ssn/channel/result/pre-layout-json?verificationId=${verificationId}`)
}

/**
 * Get the sev pcb channel/package verification TDR result of the interface.
 *
 * @export
 * @param {*} channelId
 * @returns
 */
export function getSSNChannelTDRPowerSumResult({ type, verificationId, libraryId, option }) {
  if (libraryId) {
    return http.get(`rocky/ssn/channel/result/${option}/file?type=${type}&verificationId=${verificationId}&libraryId=${libraryId}`)
  }
  return http.get(`rocky/ssn/channel/result/${option}/file?type=${type}&verificationId=${verificationId}`)
}


/* Multiple Byte */
export function createPackageMultiInterface(channelIds) {
  return http.post(`rocky/package/multiple/create`, channelIds)
}