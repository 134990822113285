class SweepConstructor {
  constructor() {
    this.sweepList = new Map(); //key - sweep id, value :{ id ,designId, channelId , name}
  }

  getSweepList = () => {
    return this.sweepList;
  }

  getSweep = (sweepId) => {
    return this.sweepList.get(sweepId);
  }

  addSweeps = (sweepList) => {
    sweepList.forEach(sweep => {
      this.sweepList.set(sweep.id, sweep);
    });
  }

  addSweep = (id, data) => {
    this.sweepList.set(id, data);
  }

  delSweep = (id) => {
    this.sweepList.delete(id);
  }

  clearCache = () => {
    this.sweepList = new Map();
  }

  getSweepValues = (channelId) => {
    const _sweepList = Array.from(this.sweepList.values());
    if (!channelId) {
      return _sweepList;
    } else {
      return _sweepList.filter(item => item.channelId === channelId)
    }
  }

  getSweepByVerificationId = (verificationId) => {
    const _sweepList = Array.from(this.sweepList.values());
    return _sweepList.find(item => item.verificationId === verificationId);
  }

  delSweepsByChannelId(channelId) {
    this.sweepList.keys().forEach((sweepId) => {
      const value = this.getSweep(sweepId);
      if (value.channelId === channelId) {
        this.delSweep(sweepId)
      }
    })
  }

  delSweepsByDesignId(designId) {
    this.sweepList.keys().forEach((sweepId) => {
      const value = this.getSweep(sweepId);
      if (value.designId === designId) {
        this.delSweep(sweepId)
      }
    })
  }
};

let sweepConstructor = new SweepConstructor();
export default sweepConstructor;