import http from '../http';
import JSZip from 'jszip';

/**
 * get BMA channel list
 * 2024/09/05
 * 
 * @export
 * @param projectId
 * @returns
 */
export function getBMAChannelList(projectId) {
  return http.get(`bma/channel/list?projectId=${projectId}`);
}

/**
 * Create BMA files list
 * 2024/09/05
 * 
 * @export
 * @param {array} files
 * @param {array[object]} libraries//?
 * @param {string}
 * @param {string} libraryFormat  file/folder/zip
 * @returns
 */
export function createBMAFiles({ projectId, files, fileInfo, format, metaData, config, parentId = "0" }) {
  let formData = new FormData();
  if (format === 'file') {
    let zip = new JSZip();
    for (let file of files) {
      zip.file(file.fileName, file.file);
    }
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 }
    }, metaData).then(blob => {
      formData.append('files', new File([blob], 'file.zip'));
      formData.append('fileInfo', JSON.stringify(fileInfo));
      formData.append('format', format);
      return http.post(`/bma/channel/input/file/upload?projectId=${projectId}&parentId=${parentId}`, formData, config, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
    });
  } else if (['zip', "folder"].includes(format)) {
    files.forEach(file => {
      formData.append('files', file.file);
    })
    formData.append('fileInfo', JSON.stringify(fileInfo));
    formData.append('format', format);
    return http.post(`bma/channel/input/file/upload?projectId=${projectId}&parentId=${parentId}`, formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }
}

/**
 * Create BMA spec files list
 * 2024/09/05
 * 
 * @export
 * @param {array} files
 * @param {array[object]} libraries//?
 * @param {string}
 * @param {string} libraryFormat  file
 * @returns
 */
export function createBMASpec({ channelId, type, files }) {
  let formData = new FormData();
  files.forEach(file => {
    formData.append('file', file.file);
  })
  return http.post(`/bma/channel/spec/upload?bmaChannelId=${channelId}&type=${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}


/**
 * get BMA channel file content
 * 2024/09/05
 * 
 * @export
 * @param channelId
 * @returns
 */
export function getBMAFilesInfo(projectId) {
  return http.get(`bma/channel/input/file/list?projectId=${projectId}`);
}

/**
 * get BMA channel data from selectFiles(Management)
 * 2024/09/05
 * 
 * @export
 * @param channelId
 * @param selectFiles
 * @param dataType  //rank_margin/lane_margin/ca_lane_margin
 * @returns
 */
export function getBMADataFromSelectFile({ projectId, bmaInputFileIds, type, page, size }) {
  return http.post(`bma/margin/table/page?page=${page}&size=${size}`, {
    projectId,
    bmaInputFileIds,
    type
  });
}

export function getDataFromSelectFiles({ projectId, bmaInputFileIds, type }) {
  return http.post(`bma/channel/input/file/content`, { projectId, bmaInputFileIds, type })
}

/**
 * delete BMA list
 * 2024/09/11
 * 
 * @export
 * @param delete file Id List
 * @returns
 */
export function deleteBMAFiles(bmaInputFileIds) {
  return http.post(`bma/channel/input/file/delete`, bmaInputFileIds)
}

/**
 * delete BMA list
 * 2024/10/18
 * 
 * @export
 * @param rename file name
 * @returns
 */
export function renameBMAFile({ name, bmaInputFileId }) {
  return http.get(`bma/channel/input/file/rename?name=${name}&bmaInputFileId=${bmaInputFileId}`)
}
/**
 * get BMA channel info
 * 2024/09/13
 * 
 * @export
 * @param get BMA channel info
 * @returns
 */
export function getBMAChannelInfo(bmaChannelId) {
  return http.get(`bma/channel/info?bmaChannelId=${bmaChannelId}`)
}

/**
 * update BMA channel info
 * 2024/09/13
 * 
 * @export
 * @param update BMA channel info
 * @param bmaChannelId
 * @param content
 * @returns
 */
export function updateBMAChannelInfo({ bmaChannelId, content }) {
  return http.post(`bma/channel/content?bmaChannelId=${bmaChannelId}`, content)
}

/**
 * get BMA channel spec info
 * 2024/09/13
 * 
 * @export
 * @param get BMA channel spec info
 * @param bmaChannelId
 * @param type
 * @returns
 */

export function getSpecContent({ bmaChannelId, type }) {
  return http.get(`bma/channel/spec/info?bmaChannelId=${bmaChannelId}&type=${type}`)
}

export function updateSpecContent({ bmaChannelId, type, content }) {
  return http.post(`bma/channel/spec/update?bmaChannelId=${bmaChannelId}&type=${type}`, content)
}

/**
 * add bma folder
 * 2024/10/13
 * 
 * @export
 * @param add bma folder
 * @param bmaChannelId
 * @param type
 * @returns
 */

export function createBMAFolder({ projectId, name, parentId = "0" }) {
  return http.get(`bma/channel/input/file/create?projectId=${projectId}&name=${name}&parentId=${parentId}`)
}


/**
 * get BMA config spec info
 * 2024/09/13
 * 
 * @export
 * @param get BMA channel spec info
 * @param bmaChannelId
 * @param type
 * @returns
 */

export function getBMALayerColorConfig({ bmaChannelId }) {
  return http.get(`bma/channel/config/layer-color?bmaChannelId=${bmaChannelId}`)
}

export function updateBMALayerColorConfig({ bmaChannelId, content }) {
  return http.post(`bma/channel/config/layer-color?bmaChannelId=${bmaChannelId}`, content)
}