import React, { Fragment, Component } from "react";
import { Select } from "antd";
import {
  INTERFACE_TYPE,
  INTERFACE_TYPE_LIST,
  seasimInputList,
  STATSIM_OPTIONS
} from "@/services/Andes_v2/seaSim/constants";

const Option = Select.Option;

class BasicOptions extends Component {

  getInterfaceType = (analysisOptions) => {
    const interfaceTypeItem = INTERFACE_TYPE_LIST.find(item => item.key === analysisOptions[INTERFACE_TYPE]);
    if (interfaceTypeItem) {
      return interfaceTypeItem.title || <span>{analysisOptions[INTERFACE_TYPE]}</span>
    }
    return <span>{analysisOptions[INTERFACE_TYPE]}</span>;
  }

  render = () => {
    const { analysisOptions,
      inputComponent,
      inputChange,
      selectTypeChange } = this.props;
    return <Fragment>
      <div className='channel-config-item'>
        <span>PCIe Protocol Type</span>
        <Select
          value={{ key: analysisOptions[INTERFACE_TYPE] || "", label: this.getInterfaceType(analysisOptions) }}
          showSearch
          onChange={option => option && selectTypeChange(option.value, INTERFACE_TYPE)}
          placeholder={"PCIe Protocol Type"}
          className='aurora-select'
          popupClassName='aurora-select-dropdown'
          labelInValue
        >
          {INTERFACE_TYPE_LIST.map(item =>
            <Option key={item.key}>{item.title}</Option>
          )}
        </Select>
      </div>
      {seasimInputList && seasimInputList.length ? seasimInputList.map((item) => {
        return <Fragment key={item.type}>
          {inputComponent({
            type: item.type,
            value: item.useStatsimOptions ? analysisOptions[STATSIM_OPTIONS][item.type] : analysisOptions[item.type],
            label: item.label,
            inputChange,
            useStatsimOptions: item.useStatsimOptions
          })}
        </Fragment>
      }) : null}
    </Fragment>
  }
}

export default BasicOptions;