import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import LibraryUpload from './libraryUpload';
import './pdnPanel.css';

class SPIMPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      loading: false,
      errorFileList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    if (!this.libraryUploadChild.getUploadingStatus()) {
      this.props.closeLibraryPanel();
    }
  }

  onRef = (ref) => {
    this.libraryUploadChild = ref;
  }

  render() {
    const { title, closeLibraryPanel, modelType, openShowPanel } = this.props;
    const content = (
      <Fragment>
        <Panel
          className='SPIM-panel'
          position='panel-center-left'
          title={title}
          zIndex={2000}
          onCancel={this.closeModal}
          width={400}
          draggable
          minHeight={180}
        >
          <LibraryUpload
            uploadTitle='Import SPIM Model'
            accept='.zip'
            modelType={modelType}
            closeLibraryPanel={closeLibraryPanel}
            openShowPanel={openShowPanel}
            onRef={this.onRef}
            extraAccept='.7z'
            allowMulti={false}
          />
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const mapState = (state) => {
  const { PDNReducer: { project } } = state;
  return {
    SPIMNames: project.SPIMNames
  }
}

export default connect(mapState, null)(SPIMPanel);