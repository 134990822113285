import React, { Component } from 'react';
import { Input } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import {
  getModelFrequency,
  getPreLayoutModelFreq,
  PreLayoutModel
} from '@/services/Andes_v2/preLayout';
import { getUnitOptions } from '@/services/helper/extractionOptionsHelper';
import UnitAddonAfter from '@/components/UnitAddonAfter';
import { valueCheck } from '../../../services/helper/valueCheck';
import { ANDES_V2 } from '../../../constants/pageType';
import { scaleConversion } from '@/services/helper/numberHelper';
import { numberCheck } from '@/services/helper/dataProcess';
import NP from 'number-precision';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import './index.css';

const LOG = "log", LINEAR = "linear", END = 'end', START = "start";
class ExtractionPanel extends Component {

  constructor(props) {
    super(props);
    const { models } = props;
    const model = models[0] || new PreLayoutModel();
    this.state = {
      model,
      frequency: getModelFrequency(model),
      error: null,
      maxHeight: 300,
      maxWidth: 830
    };
    this.dialogRoot = document.getElementById('root');
  }


  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 300),
      maxWidth: getPanelMaxWidth(offset, 830)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  unitSelect = (unit, key, type, index) => {
    const options = getUnitOptions(`${type}${key}`);
    return UnitAddonAfter({
      unit: unit,
      changeUnit: (e) => this.changeUnit(e, key, index),
      list: options
    })
  }

  changeUnit = (unit, key, index) => {
    let frequency = this.state.frequency;
    const prevUnit = frequency[index][`${key}Unit`];

    if (prevUnit === unit) {
      return;
    }

    frequency[index][`${key}Unit`] = unit;
    const scale = scaleConversion(unit, prevUnit);
    frequency[index][key] = frequency[index][key] && !numberCheck(frequency[index][key]) ? NP.times(frequency[index][key], scale).toString() : frequency[index][key];
    frequency = this.updateSameValue({ index, key, value: frequency[index][key], unit, frequency });

    this.setState({
      frequency
    })
  }

  close = (e) => {
    e.stopPropagation();
    this.props.closePanel("model", false);
    const { error } = this.state;
    if (error) {
      return;
    }
    const { model, frequency } = this.state;
    let _model = { ...model };
    _model.frequency = getPreLayoutModelFreq(frequency);
    this.props.saveModel([_model]);
  }

  changeValue = (e, key, type, index) => {
    const value = e.target.value;
    const { error, frequency } = this.state;
    let _frequency = [...frequency];

    _frequency[index][key] = value;
    _frequency = this.updateSameValue({ index, key, value: value, frequency: _frequency });
    const inputType = this.getInputType(key, type, index);
    this.setState({
      frequency: _frequency,
      error: error && error.inputType === inputType ? null : error
    })
  }

  updateSameValue({ index, key, value, unit, frequency }) {
    let _index = null, _key = null;
    switch (index) {
      case 0:
        if (key === END) {
          _index = 1;
          _key = START;
        }
        break;
      case 1:
        if (key === END) {
          _index = 2;
          _key = START;
        }

        if (key === START) {
          _index = 0;
          _key = END;
        }
        break;
      case 2:
        if (key === START) {
          _index = 1;
          _key = END;
        }
        break;
      default: break;
    }

    if (_key) {
      frequency[_index][_key] = value;
      frequency[_index][`${_key}Unit`] = unit ? unit : frequency[_index][`${_key}Unit`];
    }

    return frequency;
  }

  getInputType = (key, type, index) => {
    return index === 2 ? `${type}_${key}_2` : `${type}_${key}`;
  }

  inputBlur = (e, key, type, index) => {
    const value = e.target.value;
    let frequency = this.state.frequency;
    const unit = frequency[index][`${key}Unit`];
    const inputType = this.getInputType(key, type, index);
    const error = valueCheck({
      value,
      inputType,
      unit,
      version: ANDES_V2
    });
    if (error) {
      e.target.focus();
      this.setState({
        error: { inputType, error }
      })
    }
  }

  render() {
    const { error, frequency, maxHeight, maxWidth } = this.state;
    const height = error ? 160 : 130;
    const content = (
      <Panel
        className='andes-v2-pre-layout-panel'
        title='Extraction Options'
        zIndex={2000}
        position='panel-center'
        onCancel={this.close}
        minHeight={200}
        minWidth={200}
        width={getPanelWidth(maxWidth, { defaultWidth: 830 })}
        height={maxHeight > height ? null : height}
        maxHeight={maxHeight}
        overflow={"auto"}
        draggable
      >
        <div className='pre-layout-setting pre-layout-extraction'>
          {frequency.map((item, index) => {
            const typeTitle = this.getKey(item.type, true);
            const key = this.getKey(item.type);
            return <div className='extraction-content' key={index}>
              <span className='extraction-content-body'>{typeTitle} Sweep{index === 2 ? " 2" : ""}</span>
              {this.getInputComponent({
                type: item.type,
                value: item.start,
                style: { marginRight: 7 },
                unit: item.startUnit,
                key: START,
                index
              })}
              To
              {this.getInputComponent({
                type: item.type,
                value: item.end,
                style: { marginLeft: 6 },
                unit: item.endUnit,
                key: END,
                index
              })}

              {this.getInputComponent({
                type: item.type,
                value: item[key],
                unit: item[`${key}Unit`],
                key,
                index,
                className: 'sub-extraction-input extraction-input-item-sub'
              })}
              <span className='extraction-content-body extraction-step-width'>{item.type === LOG ? "Per Decade" : "Step"}</span>
            </div>
          })}
          {error && <span className='aurora-model-name-error-msg'>{error.error}</span>}
        </div>
      </Panel>)

    return createPortal(content, this.dialogRoot);
  }

  getKey = (type, isTitle) => {
    if (type === LOG) {
      return isTitle ? "Log" : "dec";
    }

    if (type === LINEAR) {
      return isTitle ? "Linear" : "step";
    }
  }

  getInputComponent = ({
    type,
    value,
    key,
    index,
    style,
    unit,
    className
  }) => {
    if (index === 2 && key === "step") {
      return <Input
        className='sub-extraction-input extraction-input-item-sub'
        value={'20'}
        addonAfter={'MHz'}
        disabled={true}
      />
    }
    return <Input
      value={value}
      className={className ? className : 'extraction-input extraction-input-item'}
      style={style ? style : {}}
      addonAfter={key !== "dec" ? this.unitSelect(unit, key, type, index) : null}
      onChange={(e) => this.changeValue(e, key, type, index)}
      onBlur={(e) => this.inputBlur(e, key, type, index)}
    />
  }
}

export default ExtractionPanel;