import React, { Component } from 'react'
import { connect } from 'react-redux';
import { saveSimConfig } from '../store/multiInterface/action';
import OptionPanel from '../Content/PinToPinSetting/TopBar/optionPanel';
import { toNonExponential } from '@/services/helper/numberHelper';
import { simulationConfigList } from '@/services/helper/simulationValueCheck';
import { numConversion, unitConversion, numberCheck } from '@/services/helper/dataProcess';
import { scaleKeys } from '@/services/helper/constants';
import NP from 'number-precision';
import { ALL_INTERFACES, getSimConfig } from '../../../services/Sierra/multiInterfaceSetup';
import { changeConfigFormat } from '../../../services/Sierra';
import { TreeSelect } from 'antd';

const { SHOW_PARENT } = TreeSelect;
class SetupOptionPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      verificationList: [],
      applyIds: ["All"],
      currentConfig: {},
      prevConfig: {}
    }
  }

  componentDidMount = async () => {
    const { verificationId } = this.props;
    const data = await getSimConfig(verificationId);
    this.setDefaultConfig(data.config);
    this.getVerificationList();
  }

  componentDidUpdate = async (prevProps) => {
    const { verificationId } = this.props;
    if (verificationId && verificationId !== prevProps.verificationId) {
      const data = await getSimConfig(verificationId);
      this.setDefaultConfig(data.config);
      this.getVerificationList();
    }
  }

  setDefaultConfig = (config) => {
    const prevConfig = JSON.parse(JSON.stringify(config || {}));
    let newConfig = changeConfigFormat({ ...config });
    let ngspice = {};

    if ((!newConfig.simulate) && config) {
      newConfig.simulate = 'Default';
      ngspice.simulate = 'Default';
    }

    if ((!newConfig.ngspiceMDFLM) && config) {
      newConfig.ngspiceMDFLM = '30ps';
      ngspice.ngspiceMDFLM = '30ps';
    }

    if ((!newConfig.hspiceCores) && config) {
      newConfig.hspiceCores = 2;
    }

    newConfig.clock = {
      value: newConfig.clock && newConfig.clock.value ? newConfig.clock.value : '',
      unit: newConfig.clock && newConfig.clock.unit ? newConfig.clock.unit : 'M',
    }

    newConfig.timeStep = {
      value: newConfig.timeStep && newConfig.timeStep.value ? newConfig.timeStep.value : '',
      unit: newConfig.timeStep && newConfig.timeStep.unit ? newConfig.timeStep.unit : 'n',
    }
    this.setState({
      currentConfig: newConfig,
      prevConfig
    })
  }

  getVerificationList = () => {
    const { currentProjectVerifications, verificationIds = [], verificationId, multiSetupGroup } = this.props;
    const verifications = (currentProjectVerifications || []).filter(item => verificationIds.includes(item.id) && item.id !== verificationId);
    let verificationList = [], groupKeys = [];
    if (multiSetupGroup !== ALL_INTERFACES) {
      verificationList = verifications.map(item => {
        return {
          key: item.id,
          title: item.name,
          value: item.id
        }
      })
    } else {
      groupKeys = [...new Set(verifications.map(item => item.group).filter(item => !!item))];

      const groupVerificationList = groupKeys.map(group => {
        const findV = verifications.filter(it => it.group === group);
        return {
          key: group,
          title: group,
          value: group,
          children: findV.map(item => {
            return {
              key: item.id,
              title: item.name,
              value: item.id
            }
          })
        }
      })
      const noGroupVerifications = verifications.filter(item => !item.group).map(item => {
        return {
          key: item.id,
          title: item.name,
          value: item.id
        }
      });
      verificationList = [...groupVerificationList, ...noGroupVerifications];
    }

    this.setState({
      verificationList: [{
        key: "All",
        title: "All",
        value: "All",
        children: [...verificationList]
      }],
      groupKeys,
      applyIds: ["All"],
    })
  }

  updateSimOption = (optType, value, update) => {
    const { currentConfig } = this.state;
    let Config = { ...currentConfig };

    if (update === 'value') {
      //clock timeStep cycles
      if (simulationConfigList.includes(optType)) {
        value = toNonExponential(value);
      }
      if (optType === 'cycles' || optType === 'simulate' || optType === 'ngspiceMDFLM') {
        Config[optType] = value;
      } else if (optType === 'clock') {
        Config[optType].value = value;

        if (!Config.fixTimeStep) {
          //Time Step = 1/(100F) where F is frequency in Hz.
          //unit conversion scale
          const scale = unitConversion(0, scaleKeys[Config.clock.unit]);
          //Calculate the time step according to the formula
          let newTimeStep = NP.divide(1, NP.times(value, scale, 100));
          //unit conversion
          //Convert to ps first
          const sScale = unitConversion(-4, 0);
          newTimeStep = NP.strip(NP.times(newTimeStep, sScale));

          //Keep two decimal places
          newTimeStep = NP.round(newTimeStep, 2);

          //Convert ps to current unit
          const pScale = unitConversion(scaleKeys[Config.timeStep.unit], -4);
          newTimeStep = NP.times(newTimeStep, pScale);

          Config.timeStep.value = newTimeStep;
        }
      } else if (optType === 'timeStep') {
        Config[optType].value = value;
      }
    } else if (update === 'unit') {
      let _value = Config[optType].value;
      let prevUnit = Config[optType].unit;
      //value error check
      let error = numberCheck(_value);
      if (error) {
        _value = numConversion(parseFloat(_value));
        if (isNaN(_value)) {
          if (optType === 'clock') {
            _value = 1;//unit is MHz;
            prevUnit = 'M';
          } else if (optType === 'timeStep') {
            _value = 10; //unit is ns
            prevUnit = 'n';
          }
        }
      }
      //Change value according to unit
      const scale = unitConversion(scaleKeys[value], scaleKeys[prevUnit]);
      _value = NP.times(_value, scale);
      _value = toNonExponential(_value);
      Config[optType].value = _value;
      Config[optType].unit = value;
    } else if (update === 'coupling') {
      if (optType === 'coupling') {
        Config.xTalk = value;
      }
    }

    Config.clock = {
      value: Config.clock && Config.clock.value ? Config.clock.value : '',
      unit: Config.clock && Config.clock.unit ? Config.clock.unit : 'M',
    }

    Config.timeStep = {
      value: Config.timeStep && Config.timeStep.value ? Config.timeStep.value : '',
      unit: Config.timeStep && Config.timeStep.unit ? Config.timeStep.unit : 'n',
    }

    this.setState({
      currentConfig: Config
    })
  }

  _updateCurrentConfig = (config) => {
    this.setState({
      currentConfig: config
    })
  }

  closePanel = () => {
    const { applyIds, groupKeys, currentConfig, prevConfig } = this.state;
    const { verificationId, verificationIds, currentProjectVerifications, multiSetupGroup } = this.props;
    let _applyIds = []
    if (applyIds.length && applyIds[0] === "All") {
      _applyIds = [...verificationIds];
    } else {
      if (multiSetupGroup === ALL_INTERFACES) {
        const applyGroups = applyIds.filter(item => groupKeys.includes(item));
        const verifications = (currentProjectVerifications || []).filter(item => verificationIds.includes(item.id) && item.id !== verificationId);

        const applyGroupIds = applyGroups.map(item => { return [...verifications.filter(it => it.group === item).map(it => it.id)] }).flat(2);
        const noGroupIds = applyIds.filter(item => !groupKeys.includes(item));

        _applyIds = [...applyGroupIds, ...noGroupIds];
      } else {
        _applyIds = [...applyIds]
      }
    }

    this.props._updateSimConfig(currentConfig, [...new Set([verificationId, ..._applyIds])], prevConfig);
    this.props.closePanel()
  }

  applyChange = (keys) => {
    this.setState({
      applyIds: keys
    })
  }

  applyRender = () => {
    const { applyIds, verificationList } = this.state;
    return <div className='multi-setup-simulation-apply-content multi-setup-simulation-option-apply-content'>
      <span>Apply setup to</span>
      <TreeSelect
        treeData={verificationList || []}
        value={applyIds || []}
        onChange={this.applyChange}
        placeholder='Please select'
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        className='aurora-select'
        dropdownStyle={{ maxHeight: 500 }}
        getPopupContainer={() => document.getElementById('root')}
        popupClassName='multi-setup-option-select-dropdown' />
    </div>
  }

  render() {
    const { currentConfig } = this.state;
    return <OptionPanel
      {...this.props}
      closePanel={this.closePanel}
      allowApply={true}
      currentConfig={currentConfig}
      _updateOptions={this.updateSimOption}
      _updateCurrentConfig={this._updateCurrentConfig}
      applyRender={this.applyRender}
    />
  }
}

const mapState = (state) => {
  const { SierraReducer: {
    multiInterfaceSetup: { setupData: { verificationIds }, multiSetupGroup },
    project: { currentProjectVerifications } } } = state;
  return {
    verificationIds,
    currentProjectVerifications,
    multiSetupGroup
  };
}

const mapDispatch = (dispatch) => ({
  _updateSimConfig(currentConfig, ids, prevConfig) {
    dispatch(saveSimConfig(currentConfig, ids, prevConfig))
  }
})

export default connect(mapState, mapDispatch)(SetupOptionPanel)