import {
  CHANGE_VIEW, SAVE_OPEN_PROJECT_INFO, UPDATE_LIBRARY_MENU, UPDATE_PROJECT_MENU,
  LIBRARY_MENU, OPEN_PROJECT, PROJECT_MENU, CHANGE_LAYOUT, SHOW_RESULT,
  CLEAR_CURRENT_PROJECT, UPDATE_TRASH_MENU, TRASH_MENU, CHANGE_VIEW_LIST,
  CHANGE_TREE_SELECTED_KEY, UPDATE_CURRENT_PROJECT,
  DOWNLOAD_DEBUG_MSG, DEBUG_DOWNLOAD_LOG_UPDATE, DOWNLOAD_DEBUG_MSG_CLOSE, UPDATE_DOWNLOAD_MSG,
  UPDATE_PREPARE_PDNS, REMOVE_PREPARE, GET_DEFAULT_DECAP, SET_DEFAULT_DECAP, DEL_DEFAULT_DECAP,
  CREATE_ALL_PDN, UPDATE_PDN_REPORT_CONFIG, GET_PDN_REPORT, UPDATE_PDN_REPORT_PROGRESS, UPDATE_PDN_REPORT_MESSAGE,
  UPDATE_PDN_REPORT_VISIBLE, PDN_CLEAR_REPORT_INFO,
  UPDATE_PCB_LAYOUT,
  FORCE_PREVIEW_SHOW,
  AFTER_IMPORT_LIBRARY, UPDATE_SIM_LOADING, START_LAYOUT_CHECK,
  GET_LAYOUT_ERROR_RESULT
} from './actionTypes';

export const changeView = (view, info) => ({
  type: CHANGE_VIEW,
  view,
  ...info
});

export const afterImportLibrary = () => ({
  type: AFTER_IMPORT_LIBRARY,
});

export const saveOpenProjectInfo = ({ id, name, designs, packages, pdns }) => ({
  type: SAVE_OPEN_PROJECT_INFO,
  id,
  designs,
  packages,
  name,
  pdns
});

export const updateLibraryMenu = () => ({
  type: UPDATE_LIBRARY_MENU,
});

export const updateProjectMenu = (obj) => ({
  type: UPDATE_PROJECT_MENU,
  obj
});

export const libraryMenu = ({ treeItems, SPIMNames, DecapNames, VRMNames }) => ({
  type: LIBRARY_MENU,
  treeItems,
  SPIMNames,
  DecapNames,
  VRMNames
});

export const openProject = (id) => ({
  type: OPEN_PROJECT,
  id
});

export const projectMenu = ({ treeItems, projectList }) => ({
  type: PROJECT_MENU,
  treeItems,
  projectList
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  layout
});

export const showPdnResult = (view, info) => ({
  type: SHOW_RESULT,
  view,
  ...info
});

export const clearCurrentProject = (status) => ({
  type: CLEAR_CURRENT_PROJECT,
  status
});

export const updateTrashMenu = () => ({
  type: UPDATE_TRASH_MENU
});

export const trashMenu = ({ treeItems }) => ({
  type: TRASH_MENU,
  treeItems
});

export const changeViewList = (viewList, viewType) => ({
  type: CHANGE_VIEW_LIST,
  viewList,
  viewType
});

export const changeTreeSelected = (treeSelectedKeys) => ({
  type: CHANGE_TREE_SELECTED_KEY,
  treeSelectedKeys
});

export const updateProject = (id) => ({
  type: UPDATE_CURRENT_PROJECT,
  id
});

export const downloadDebugMsg = ({ project, fileName, verificationId, downloadType }) => ({
  type: DOWNLOAD_DEBUG_MSG,
  project,
  fileName,
  verificationId,
  downloadType
});

export const debugDownloadLog = (debugDownloadMsg) => ({
  type: DEBUG_DOWNLOAD_LOG_UPDATE,
  debugDownloadMsg
});

export const closeDebugDownloadMsg = () => ({
  type: DOWNLOAD_DEBUG_MSG_CLOSE
});

export const updateDownloadMsg = (msg) => ({
  type: UPDATE_DOWNLOAD_MSG,
  msg
});

export const updatePreparePDNS = (preparingPDN) => ({
  type: UPDATE_PREPARE_PDNS,
  preparingPDN
})

export const removeCurrentPreparingPDN = (id) => ({
  type: REMOVE_PREPARE,
  id
})

export const saveDefaultDecap = (file) => ({
  type: GET_DEFAULT_DECAP,
  file
})

export const setDefaultDecap = (libraryId) => ({
  type: SET_DEFAULT_DECAP,
  libraryId
})

export const delDefaultDecap = () => ({
  type: DEL_DEFAULT_DECAP
})

export const createAllPDN = ({ PDNs, pkgInfo, SPIM }) => ({
  type: CREATE_ALL_PDN,
  PDNs,
  pkgInfo,
  SPIM
})

export const updatePDNReportConfig = (reportConfig) => ({
  type: UPDATE_PDN_REPORT_CONFIG,
  reportConfig
})

export const getPDNReport = ({ projectId, format, mime, fileName }) => ({
  type: GET_PDN_REPORT,
  projectId,
  format,
  mime,
  fileName
});

export const updatePDNProgress = (progress) => ({
  type: UPDATE_PDN_REPORT_PROGRESS,
  progress
});

export const updatePDNReoprtMessage = (message) => ({
  type: UPDATE_PDN_REPORT_MESSAGE,
  message
});

export const updatePDNReportVisible = (visible) => ({
  type: UPDATE_PDN_REPORT_VISIBLE,
  visible
})

export const clearReportInfo = () => ({
  type: PDN_CLEAR_REPORT_INFO
});

export const forcePreviewShow = (show) => ({
  type: FORCE_PREVIEW_SHOW,
  show
});

export const updatePCBLayout = (pcbLayout, prePcbLayout) => ({
  type: UPDATE_PCB_LAYOUT,
  pcbLayout,
  prePcbLayout
});

export const updateSimLoading = (loading) => ({
  type: UPDATE_SIM_LOADING,
  loading
})

export const startLayoutCheck = (designItem) => ({
  type: START_LAYOUT_CHECK,
  designItem
})

export const getLayoutErrorResult = (designId) => ({
  type: GET_LAYOUT_ERROR_RESULT,
  designId
})