import React, { Component } from 'react';
import { CameraOutlined, LoadingOutlined } from '@ant-design/icons';
import ConnectorSetup from '@/components/Connector/ConnectorSetup';
import { changeConnectorStatus, saveConnector, saveConnectionGroup } from '../../store/sierra/action';
import { getConnectionsInfo, getConnCompList, changePCBInConnector, defaultConnectionConfig, getPcbList } from '@/services/Sierra';
import { getTouchstoneParse, getLibraryConnectorFile } from '@/services/Sierra/library';
import { fileExsit } from '@/services/Sierra/library/libraryStorage';
import { connect } from 'react-redux';
import { SIERRA } from '@/constants/pageType';
import { CONNECTOR } from '../../../../constants/componentType';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { downloadFile } from '../../../../services/helper/downloadHelper';

class ConnectorTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      CONNECTION_ID: "",
      connectorSetupUpdate: false,
      connections: [],
      pcbConnections: [],
      addStatus: false,
      allConnections: [],
      shot: false
    }
  }

  componentDidMount = () => {
    const { connectorList, Interfaces } = this.props;
    this.getConnections(connectorList, Interfaces, true);
  }

  componentDidUpdate = (prevProps) => {
    const { connectorList, Interfaces, connectorStatus, verificationId } = this.props;
    if (connectorList.length !== prevProps.connectorList.length || (connectorStatus && connectorStatus !== prevProps.connectorStatus)) {
      this.props.changeConnectorStatus(false);
      const init = prevProps.connectorList.length ? false : true;
      const notUpdateOrder = verificationId !== prevProps.verificationId ? false : true;
      this.getConnections(connectorList, Interfaces, init, notUpdateOrder);
    }
  }

  getConnections = (connectorList, Interfaces, init = false, notUpdateOrder = false) => {
    const { connections, pcbConnections, updateConnector, newConnections, updatePCBIds } = getConnectionsInfo(connectorList, Interfaces, init, notUpdateOrder, this.state.connections);

    const { CONNECTION_ID } = this.state;
    const id = CONNECTION_ID ? CONNECTION_ID : connections.length ? "1" : "";
    const addStatus = this.findUnusedPcbCombinations(connections) ? true : false;
    this.setState({
      connections,
      pcbConnections,
      connectorSetupUpdate: true,
      CONNECTION_ID: id,
      addStatus,
      allConnections: newConnections
    })
    if (updateConnector && newConnections && newConnections.length) {
      this.props.saveConnectionGroup(newConnections, false, updatePCBIds)
    }
  }

  updateConnectionId = (id) => {
    this.setState({
      CONNECTION_ID: id
    })
  }

  _setupUpdateStatus = (boolValue, type) => {
    this.setState({
      [type]: boolValue
    })
  }

  _changePcbInConnector = (value, channelIndex, CONNECTION_ID, connections = []) => {
    // Operation when switching between PCBs
    // When there is a default selected PCB, there is no comp available for the selected PCB
    const { Interfaces } = this.props;
    const connIndex = connections.findIndex(conn => conn.CONNECTION_ID === CONNECTION_ID);
    let _connection = JSON.parse(JSON.stringify(connections[connIndex]));

    let channel = _connection[`channel${channelIndex}`];
    let anotherIndex = channelIndex === 1 ? 2 : 1;
    let saveConnectionList = [];

    let prevChannelInfo = {
      [`channel${channelIndex}`]: JSON.parse(JSON.stringify(_connection[`channel${channelIndex}`])),
      [`channel${anotherIndex}`]: JSON.parse(JSON.stringify(_connection[`channel${anotherIndex}`]))
    }

    channel = value ? { designId: value.key, designName: value.label } : { designId: "", designName: "" };
    _connection[`channel${channelIndex}`] = channel;
    let channelInfo = {
      [`channel${channelIndex}`]: channel,
      [`channel${anotherIndex}`]: JSON.parse(JSON.stringify(_connection[`channel${anotherIndex}`]))
    }
    const interfaceInfo = Interfaces.find(item => item.pcbId === value.key)
    const compList = interfaceInfo.content.components.filter(item => item.type === CONNECTOR && (!item.connect || !item.connect.pcbId))
    // compList   If there are unused PCBs selected, they can be selected by default
    let usedId = 0;
    _connection.connectionList.forEach(item => {
      let prevConnection = JSON.parse(JSON.stringify(item))
      item[`connector${channelIndex}`] = { component: "", pins: [], models: [] };
      if (!item[`connector${anotherIndex}`].component && compList[usedId] && compList[usedId].name) {
        // If the other is not set, add a default value
        item[`connector${channelIndex}`] = { component: compList[usedId].name, pins: [], models: [] };
        usedId++
      }
      item[`signal_connections_map`] = item[`connector${anotherIndex}`] && item[`connector${anotherIndex}`].pins
        ? item[`connector${anotherIndex}`].pins.map(pin => ({ name: pin.signal })) : [];
      let connection = JSON.parse(JSON.stringify(item))
      saveConnectionList.push({ connection: { ...channelInfo, connection }, prevConnection: { ...prevChannelInfo, connection: prevConnection } })
    })

    connections[connIndex] = _connection;
    this.setState({
      connections
    })
    this.props.saveConnectionGroup(saveConnectionList, true)
  }

  _saveConnection = (connection, { hasChannel = false, CONNECTION_ID }) => {
    const connections = this.state.connections
    const index = connections.findIndex(conn => conn.CONNECTION_ID === CONNECTION_ID);
    if (index > -1) {

      const findIndex = connections[index].connectionList.findIndex(item => item.CONNECTION_ID === connection.CONNECTION_ID);
      if (findIndex > -1) {
        const prevConnection = this.getConnectionData(connections, index, findIndex)

        if (!connection.connector1.component && !connection.connector2.component) {
          let _connections = JSON.parse(JSON.stringify(connections));
          const _connection = {
            channel1: {},
            channel2: {},
            connection: new defaultConnectionConfig()
          }
          if (_connections[index].connectionList.length < 2) {
            _connections.splice(index, 1)
          } else {
            _connections[index].connectionList.splice(findIndex, 1)
          }
          this.props.saveConnector(_connection, prevConnection);
          this.setState({
            connections: _connections
          })
          return
        }

        if (hasChannel) {
          // delete pcb
          connections[index] = connection;
        } else {
          connections[index].connectionList[findIndex] = connection;
        }
        this.setState({
          connections
        })
        const new_connection = this.getConnectionData(connections, index, findIndex)
        this.props.saveConnector(new_connection, prevConnection);
      }
    }
  }

  getConnectionData = (connections, index, connectorIndex) => {
    const currentValue = JSON.parse(JSON.stringify(connections[index]))
    const { connectionList = [], channel1 = {}, channel2 = {} } = currentValue;
    const connection = connectionList.length > connectorIndex ? connectionList[connectorIndex] : {}
    return { channel1, channel2, connection }
  }

  deleteConnector = (prevConnection, index) => {
    const { connections } = this.state;
    const findIndex = connections.findIndex(item => item.CONNECTION_ID === index)
    let _connections = JSON.parse(JSON.stringify(connections));
    let _connectionList = JSON.parse(JSON.stringify(connections[findIndex].connectionList));
    _connectionList = _connectionList.filter(item => item.CONNECTION_ID !== prevConnection.CONNECTION_ID)
    if (!_connectionList.length) {
      _connections.splice(findIndex, 1)
    } else {
      _connections[findIndex].connectionList = _connectionList
    }

    const connection = {
      channel1: {},
      channel2: {},
      connection: new defaultConnectionConfig()
    }
    this.props.saveConnector(connection, { channel1: connections[findIndex].channel1, channel2: connections[findIndex].channel2, connection: prevConnection });
    this.setState({
      connections: _connections
    })
  }

  findUnusedPcbCombinations = (connections) => {
    const { connectorList } = this.props;
    const filterConnector = connectorList.filter(item => !item.connect || !item.connect.pcbId)
    let pcbList = filterConnector.map(item => item.pcbId);
    pcbList = [...new Set(pcbList)];
    if (pcbList.length < 1) {
      return false;
    }

    let eligiblePcbs = [];
    // Eligible PCBs
    for (let i = 0; i < pcbList.length; i++) {
      for (let j = i + 1; j < pcbList.length; j++) {
        const findUsedPcb = connections.find(item => (item.channel1.designId === pcbList[i] && item.channel2.designId === pcbList[j]) || (item.channel2.designId === pcbList[i] && item.channel1.designId === pcbList[j]))
        if (!findUsedPcb) {
          eligiblePcbs = [pcbList[i], pcbList[j]]
          return eligiblePcbs
        }
      }
    }
    return false
  }

  getSingleConnection = (connectorList, _connections) => {
    let connection = new defaultConnectionConfig();
    let _channel1 = {}, _channel2 = {};
    const filterConnector = connectorList.filter(item => !item.connect || !item.connect.pcbId)

    const eligiblePcbs = this.findUnusedPcbCombinations(_connections);
    if (!eligiblePcbs) {
      // No combination found that meets the requirements
      return
    }

    for (let connectorNetInfo of filterConnector) {
      const { pcbId, name, pins, pcb } = connectorNetInfo;
      if (eligiblePcbs.includes(pcbId) && !Object.keys(connection.connector1).length) {
        _channel1 = { designId: pcbId, designName: pcb }
        const _pins = pins.map(item => { return { ...item, pin_map: '' } })
        let _signal_connections_map = pins.map(item => item.signal)
        _signal_connections_map = [...new Set(_signal_connections_map)]
        connection.connector1 = { component: name, models: [], pins: _pins }
        connection.signal_connections_map = _signal_connections_map.map(item => { return { name: item } })
        connection.CONNECTION_ID = this.state.allConnections.length + 1
      }

      if (eligiblePcbs.includes(pcbId) && Object.keys(_channel1).length && _channel1.designId !== pcbId) {
        _channel2 = { designId: pcbId, designName: pcb }
        break;
      }
    }
    return { channel1: _channel1, channel2: _channel2, connection: connection }
  }

  addConnector = () => {
    let _connections = this.state.connections
    const { connectorList } = this.props
    const { channel1, channel2, connection } = this.getSingleConnection(connectorList, _connections);
    if (!channel1 || !Object.keys(channel1).length) { return }
    _connections.push({ channel1, channel2, connectionList: [connection] })
    _connections.forEach((item, index) => {
      item.CONNECTION_ID = (index + 1).toString()
    })
    const prevConnection = {
      channel1: {},
      channel2: {},
      connection: new defaultConnectionConfig()
    }
    this.props.saveConnector({ channel1, channel2, connection }, prevConnection);
    this.setState({
      connections: _connections
    })
  }

  addPcbConnector = (CONNECTION_ID, index) => {
    // Add according to PCB
    let _connections = this.state.connections;
    const { connectorList } = this.props
    const findIndex = _connections.findIndex(conn => conn[`CONNECTION_ID`] === CONNECTION_ID);
    if (findIndex < 0) { return }
    let { channel1, channel2 } = _connections[findIndex];
    const selectValue = _connections[findIndex][`channel${index}`]
    let anotherIndex = index === 1 ? 2 : 1;
    const filterConnectorList = connectorList.filter(item => (!item.connect || !item.connect.pcbId) && item.pcbId === selectValue.designId)
    if (!filterConnectorList.length) { return }
    let connection = new defaultConnectionConfig();
    const { name, pins } = filterConnectorList[0];
    const _pins = pins.map(item => { return { ...item, pin_map: '' } })
    let _signal_connections_map = pins.map(item => item.signal)
    connection[`connector${index}`] = { component: name, models: [], pins: _pins }
    const filterAnotherConnectorList = connectorList.filter(item => (!item.connect || !item.connect.pcbId) && item.pcbId === _connections[findIndex][`channel${anotherIndex}`].designId)
    if (filterAnotherConnectorList.length === 1) {
      // Automatically add when the corresponding PCB has only one unused connector
      const { name: anotherName, pins: anotherPins } = filterAnotherConnectorList[0];
      _signal_connections_map = [...new Set([..._signal_connections_map, ...pins.map(item => item.signal)])]
      connection[`connector${anotherIndex}`] = { component: anotherName, models: [], pins: anotherPins }
    }

    connection.signal_connections_map = _signal_connections_map.map(item => { return { name: item } })
    connection.CONNECTION_ID = this.state.allConnections.length + 1

    _connections[findIndex].connectionList.push(connection)
    this.props.saveConnector({ channel1, channel2, connection }, { channel1, channel2, connection: new defaultConnectionConfig() });
    this.setState({
      connections: _connections
    })
  }


  screenShot = () => {
    this.setState({
      shot: true
    }, () => {
      setTimeout(() => {
        const { connections } = this.state;
        if (connections.length === 1) {
          const ele = document.getElementById(`connection-model-box-${connections[0].CONNECTION_ID}`);
          if (ele) {
            html2canvas(ele).then(canvas => {
              const fileName = `${connections[0].channel1.designName}-${connections[0].channel2.designName}`;
              downloadFile(canvas, fileName, "canvas");
            })
          }
          this.setState({
            shot: false
          })
          return;
        }

        let zip = new JSZip();
        try {
          const promises = connections.map((conn) => {
            const fileName = `${conn.channel1.designName}-${conn.channel2.designName}.png`;
            const ele = document.getElementById(`connection-model-box-${conn.CONNECTION_ID}`);
            return html2canvas(ele, { scale: 2 }).then((canvas) => {
              return new Promise((resolve) => {
                // Canvas to Blob (PNG)
                canvas.toBlob(function (blob) {
                  zip.file(fileName, blob);
                  resolve();
                }, 'image/png');
              });
            });
          });

          Promise.all(promises).then(() => {
            zip.generateAsync({ type: "blob" }).then((content) => {
              const link = document.createElement('a');
              link.href = URL.createObjectURL(content);
              link.download = "PCB_Connections.zip";
              link.click();
              this.setState({
                shot: false
              })
            });
          });

        } catch (error) {
          console.error(error);
          this.setState({
            shot: false
          })
        }

        /*   const ele = document.getElementById("sierra-pcb-connections-content");
          if (ele) {
            html2canvas(ele).then(canvas => {
              downloadFile(canvas, "PCB_Connections", "canvas");
            })
          } */
      }, 10)
    })
  }

  render() {
    const { connections, pcbConnections, CONNECTION_ID, connectorSetupUpdate, addStatus, shot } = this.state;
    const { currentProjectId, verificationId, spiceList, touchstoneList, Interfaces, connectorList } = this.props;
    return connectorList.length ? <div className='margin-top-20 sierra-connector-content' id="sierra-pcb-connections-content">
      {!shot ? <CameraOutlined className='camera-icon' title={"Screenshot"} onClick={this.screenShot} /> : <LoadingOutlined className='camera-icon' title={"Taking screenshot..."} />}
      <ConnectorSetup
        pcbConnections={pcbConnections}
        connections={JSON.parse(JSON.stringify(connections))}
        projectId={currentProjectId}
        endToEndChannelId={verificationId}
        connectorSetupUpdate={connectorSetupUpdate}
        CONNECTION_ID={CONNECTION_ID}
        updateConnectionId={this.updateConnectionId}
        _setupUpdateStatus={this._setupUpdateStatus}
        _saveConnection={this._saveConnection}
        connectorFileList={[...spiceList, ...touchstoneList]}
        cableFileList={[...spiceList, ...touchstoneList]}
        getTouchstoneParse={getTouchstoneParse}
        getSpiceParse={getLibraryConnectorFile}
        Interfaces={Interfaces}
        judgeFileExsit={fileExsit}
        changePCBInConnector={changePCBInConnector}
        deleteConnector={this.deleteConnector}
        addConnector={this.addConnector}
        product={SIERRA}
        getConnCompList={getConnCompList}
        getPcbList={getPcbList}
        _changePcbInConnector={this._changePcbInConnector}
        addPcbConnector={this.addPcbConnector}
        addStatus={addStatus}
        connectorList={connectorList}
      />
    </div>
      : null;
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra: { sierraInfo, connectorStatus }, project: { currentProjectId, verificationId } } } = state;
  let connectorList = [], Interfaces = [];
  if (sierraInfo) {
    connectorList = sierraInfo.connectorList ? [...sierraInfo.connectorList] : [];
    Interfaces = sierraInfo.Interfaces ? [...sierraInfo.Interfaces] : [];
  }
  return {
    connectorList,
    Interfaces,
    currentProjectId,
    verificationId,
    connectorStatus
  }
};

const mapDispatch = (dispatch) => ({
  changeConnectorStatus(status) {
    dispatch(changeConnectorStatus(status));
  },
  saveConnector(connection, prevConnection) {
    dispatch(saveConnector(connection, prevConnection));
  },
  saveConnectionGroup(connections, havePrev, updatePCBIds) {
    dispatch(saveConnectionGroup(connections, havePrev, updatePCBIds));
  }
})

export default connect(mapState, mapDispatch)(ConnectorTable);