import { PCB_TOUCHSTONE, TRACE, VIA } from "../../../constants/libraryConstants";
import { numberCheck } from "../../helper/dataProcess";
import { checkNameFormat } from "../../helper/nameFormatCheck";
import libraryConstructor from "../library/libraryConstructor";
import { getSectionLibraryError } from './preLayoutHelper';
import { getTouchstoneParse } from "@/services/Andes_v2/library";
import { getMultiFileErrorCheck } from '../../helper/connectorPkgModelCheck'
import { CPHY } from "../../PCBHelper/constants";

export async function getPreLayoutErrorCheck(preLayoutInfo = {}) {
  const { signal_groups, prelayout, preLayoutModel, components, type } = preLayoutInfo.content || {};
  let errors = [], multiFilesParseInfo = [];

  if (prelayout === 'model' && preLayoutModel && preLayoutModel.files) {
    // When the model file is deleted

    if (!preLayoutModel.files.length) {
      errors.push({
        title: "[Components]",
        boldKey: '',
        errorMsg: `PCB Model files cannot be empty.`
      })
    }
    const existFiles = preLayoutModel.files.filter(item => item.fileName && item.type);
    let checkedFileIds = [];

    let uniqueElements = new Set();
    let duplicates = new Set();

    for (let _file of existFiles) {
      if (!_file.libraryId) { continue; }
      const fileType = _file.type === 'Touchstone' ? 'touchstone' : 'spice';
      let _types = PCB_TOUCHSTONE;

      const fileExist = libraryConstructor.checkFile(_types, _file.libraryId)
      checkedFileIds.push(_file.libraryId);
      if (!fileExist || !checkedFileIds.includes(_file.libraryId)) {
        errors.push({
          title: "[Components]",
          boldKey: '',
          errorMsg: `${fileType} file ${_file.fileName} not exist.`
        })
      }
      if (fileExist) {
        // Find if file is used in port
        const findPairs = preLayoutModel.pairs.find(item => item.libraryId === _file.libraryId && item.modelKey === _file.modelKey)
        if (findPairs && uniqueElements.has(_file.libraryId)) {
          duplicates.add(_file.libraryId);
        } else if (findPairs) {
          uniqueElements.add(_file.libraryId);
        }
      }
    }
    // Find files that have been reused
    const multiFileId = Array.from(duplicates);
    for (let fileId of multiFileId) {
      const findInfo = existFiles.find(item => item.libraryId === fileId)
      const info = await getTouchstoneParse(fileId, findInfo.fileName)
      multiFilesParseInfo.push({ ...info, fileName: findInfo.fileName })
    }

    // Verify whether all ports of reused files are used
    if (preLayoutModel.pairs) {
      const multiModelFileError = getMultiFileErrorCheck({ ...preLayoutModel, modelType: "PCB" }, multiFilesParseInfo)
      if (multiModelFileError && multiModelFileError.length) {
        errors = [...errors, ...multiModelFileError]
      }
    }
  }

  for (let group of signal_groups) {
    if (!group.signal_spacing || numberCheck(group.signal_spacing)) {
      errors.push({
        title: "[Signals]",
        boldKey: group.name,
        errorMsg: "signal Spacing is should be a number."
      })
    }

    for (let signal of group.signals) {
      const nameError = checkNameFormat(signal.name, { errorType: "" });
      if (nameError) {
        errors.push({
          title: "[Signals]",
          boldKey: signal.name,
          errorMsg: nameError
        })
      }
      if (prelayout === 'Schematic') {
        const sectionErrors = getPreLayoutSectionErrorCheck(signal);
        if (sectionErrors.length) {
          errors = [...errors, ...sectionErrors]
        }
      } else if (prelayout === 'model') {
        // error check
        if (preLayoutModel && preLayoutModel.files && preLayoutModel.files.length) {
          const pins_P = [], pins_N = [], pins_A = [], pins_B = [], pins_C = []
          components.forEach((item) => {
            item.pins.forEach(item => {
              if ((signal.nets_P || []).includes(item.net)) {
                pins_P.push(item.pin)
              }
              if ((signal.nets_N || []).includes(item.net)) {
                pins_N.push(item.pin)
              }
              if ((signal.nets_A || []).includes(item.net)) {
                pins_A.push(item.pin)
              }
              if ((signal.nets_B || []).includes(item.net)) {
                pins_B.push(item.pin)
              }
              if ((signal.nets_C || []).includes(item.net)) {
                pins_C.push(item.pin)
              }
            })
          })

          const pins = type === CPHY ? [pins_A, pins_B, pins_C] : [pins_P, pins_N]
          for (const pinData of pins) {
            const modelNetsNError = getConnectorPkgErrorCheck(pinData, preLayoutModel);
            if (modelNetsNError.length) {
              errors = [...errors, ...modelNetsNError]
            }
          }
          // const modelNetsNError = getConnectorPkgErrorCheck(pins_N, preLayoutModel);
          // if (modelNetsNError.length) {
          //   errors = [...errors, ...modelNetsNError]
          // }
          // const modelNetsPError = getConnectorPkgErrorCheck(pins_P, preLayoutModel);
          // if (modelNetsPError.length) {
          //   errors = [...errors, ...modelNetsPError]
          // }
        }
      }
    }
  }

  return errors.length ? [...errors] : null;
}

function getPreLayoutSectionErrorCheck(signal) {
  let errors = [];
  const traceList = libraryConstructor.getLibraryValues(TRACE) || [],
    viaList = libraryConstructor.getLibraryValues(VIA) || [];
  for (let i = 0; i < signal.sections.length; i++) {
    const sec = signal.sections[i];
    if (!sec.type) {
      errors.push({
        title: "[Signal Section]",
        boldKey: `${signal.name} - Section${i + 1}`,
        errorMsg: "template is not set."
      })
      continue;
    }

    if ([TRACE, VIA].includes(sec.type)) {
      if (!sec.length || numberCheck(sec.length)) {
        errors.push({
          title: "[Signal Section]",
          boldKey: `${signal.name} - Section${i + 1}`,
          errorMsg: "template length is should be a number."
        })
      }

      if (!sec.template) {
        errors.push({
          title: "[Signal Section]",
          boldKey: `${signal.name} - Section${i + 1}`,
          errorMsg: "template is not set."
        })
      } else {
        const fileExistError = getSectionLibraryError(sec, traceList, viaList);
        fileExistError && errors.push({
          title: "[Signal Section]",
          boldKey: `${signal.name} - Section${i + 1}`,
          errorMsg: fileExistError.toLowerCase()
        })
      }
    } else {
      if (!sec.value) {
        errors.push({
          title: "[Signal Section]",
          boldKey: `${signal.name} - Section${i + 1}`,
          errorMsg: "template value is not set."
        })
      }
    }
  }
  return errors;
}

function getConnectorPkgErrorCheck(data, model) {
  let errors = [];
  let _disType = "pcb";
  if (!model || !model.pairs || !model.pairs.length) { return errors }
  for (let pinName of data) {
    const pinNode = model.pairs.find(item => item.pin === pinName);
    const pinDieNode = model.pairs.find(item => item.pin === pinName && pinNode.component !== item.component);
    if (!pinNode || !pinDieNode) { continue }

    if (pinNode.node && !pinDieNode.node) {
      errors.push({
        title: "[Components]",
        boldKey: pinDieNode.component,
        errorMsg: ` ${pinName} ${_disType} port is not set.`
      });
      continue;
    }

    if (!pinNode.node && pinDieNode.node) {
      errors.push({
        title: "[Components]",
        boldKey: pinNode.component,
        errorMsg: ` ${pinName} ${_disType} port is not set.`
      });
      continue;
    }

    if (pinNode.node && pinDieNode.node && (pinDieNode.libraryId !== pinNode.libraryId
      || ((pinNode.subckt || pinDieNode.subckt) && pinDieNode.subckt !== pinNode.subckt))) {
      errors.push({
        title: "[Components]",
        boldKey: "",
        errorMsg: ` ${pinNode.component} ${pinName} port and ${pinDieNode.component} ${pinName} ${_disType} port should be the same model file.`
      });
    }
  }
  return errors;
}