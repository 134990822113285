import {
  OPEN_UPLOAD_PROGRESS_MODAL,
  UPLOAD_PROGRESS_CHANGE,
  SHOW_ERROR_MSG,
  CLOSE_UPLOAD_PROGRESS_MODAL,
  TRANSLATION_RUNNING,
  TRANSLATION_FAILED,
  TRANSLATION_SUCCESS,
  GET_WORK_FLOWS,
  TRANSLATION_MONITOR,
  GET_TRANSLATION_MONITOR,
  UPLOAD_PROJECT_ID,
  ADD_MSG,
  CURRENT_UPLOAD_DESIGN_ID,
  SAVE_CURRENT_PCB_KEY,
  TRANSLATION_DEBUG,
  CANCEL_UPLOAD_WORKFLOW,
  CHANGE_DISABLE_STATUS
} from './actionType';

export const openUploadProgressModal = () => ({
  type: OPEN_UPLOAD_PROGRESS_MODAL,
})

export const changePercentAction = (uploadProgress) => ({
  type: UPLOAD_PROGRESS_CHANGE,
  uploadProgress
})

export const showErrorMsgAction = (msg) => ({
  type: SHOW_ERROR_MSG,
  msg,
})

export const closeUploadProgressModal = () => ({
  type: CLOSE_UPLOAD_PROGRESS_MODAL,
})

export const translationProgress = ({ workflowId, designId, projectID, progress }) => ({
  type: TRANSLATION_RUNNING,
  workflowId,
  designId,
  projectID,
  transProgress: progress
})

export const translationSuccess = (progress, msg) => ({
  type: TRANSLATION_SUCCESS,
  transProgress: progress,
  msg
})

export const translationFailed = (msg, progress) => ({
  type: TRANSLATION_FAILED,
  msg,
  transProgress: progress,
})

export const getWorkFlow = (workflowId, projectID, designId) => ({
  type: GET_WORK_FLOWS,
  workflowId,
  projectID,
  designId
});

export const translationMonitor = (monitor) => ({
  type: TRANSLATION_MONITOR,
  monitor
});

export const getTranslateLog = (designId) => ({
  type: GET_TRANSLATION_MONITOR,
  designId
});

export const uploadProjectID = (projectId) => ({
  type: UPLOAD_PROJECT_ID,
  projectId
});

export const addMonitorMsg = (msg) => ({
  type: ADD_MSG,
  msg
});

export const currentUploadDesignId = (designId, workflowId) => ({
  type: CURRENT_UPLOAD_DESIGN_ID,
  designId,
  workflowId
});

export const saveCurrentPCBKey = (key) => ({
  type: SAVE_CURRENT_PCB_KEY,
  key
});

export const translationDebug = (debug) => ({
  type: TRANSLATION_DEBUG,
  debug
});

export const cancelUploadWorkflow = (workflowId) => ({
  type: CANCEL_UPLOAD_WORKFLOW,
  workflowId
});

export const changeDisableStatus = (disabled) => ({
  type: CHANGE_DISABLE_STATUS,
  disabled
})
