import http from "../http";

function getMultiInterfaceSetupData({ projectId, group }) {
  return http.get(`/sierra/multi-interface?projectId=${projectId}&group=${group}`);
}

/* interfaces buffer model info */
function getMultiInterfaceSetupBufferData({ projectId, verificationId, group }) {
  if (verificationId) {
    return http.get(`/sierra/multi-interface/buffer?projectId=${projectId}&verificationId=${verificationId}`);
  }
  return http.get(`/sierra/multi-interface/buffer?projectId=${projectId}&group=${group}`);
}

function getMultiInterfaceSetupPassiveData({ projectId, verificationId, group }) {
  if (verificationId) {
    return http.get(`/sierra/multi-interface/passive?projectId=${projectId}&verificationId=${verificationId}`);
  }
  return http.get(`/sierra/multi-interface/passive?projectId=${projectId}&group=${group}`);
}

function saveMultiInterfaceSetupBufferData(updateData) {
  return http.post(`/sierra/multi-interface/buffer`, updateData);
}

function saveMultiInterfaceSetupPassiveData(updateData) {
  return http.post(`/sierra/multi-interface/passive`, updateData);
}

function getMultiSetupSimConfig(verificationId) {
  return http.get(`/sierra/multi-interface/simulation-options?verificationId=${verificationId}`)
}

function updateMultiSimConfig({ verificationIds, config }) {
  return http.post(`/sierra/multi-interface/simulation-options`, {
    verificationIds,
    config
  })
}

function getMultiSetupExtractionOptions(interfaceId) {
  return http.get(`/sierra/multi-interface/extraction-options?interfaceId=${interfaceId}`)
}

function getMultiSetupExtractionPorts(interfaceId) {
  return http.get(`/sierra/multi-interface/extraction-ports?interfaceId=${interfaceId}`)
}

function saveMultiSetupExtractionOptions(extractionInfo) {
  return http.post(`/sierra/multi-interface/extraction-options`, {
    ...extractionInfo
  })
}

function saveMultiSetupExtractionPorts(portsInfo) {
  return http.post(`/sierra/multi-interface/extraction-ports`, {
    ...portsInfo
  })
}

/**
 * get multi setup extraction options/ports apply all setup，{ extrOptionsApply: {applyIds,applyType}, extrPortsApply: {applyIds,applyType}} 
 * @param {string} interfaceId
 *  */
function getMultiSetupExtractionApplyConfig(interfaceId) {
  return http.get(`/project/interface/${interfaceId}/apply-config`)
}

/**
 * update multi setup extraction options/ports apply all setup， {applyIds,applyType}
 * @param {string} interfaceId  
 * @param {object} config { extrOptionsApply / extrPortsApply : {applyIds,applyType} }
 *  */
function updateMultiSetupExtractionApplyConfig({ interfaceId, config }) {
  return http.post(`/project/interface/${interfaceId}/apply-config`, {
    ...config
  })
}

export {
  getMultiInterfaceSetupData,
  getMultiInterfaceSetupBufferData,
  saveMultiInterfaceSetupBufferData,
  getMultiInterfaceSetupPassiveData,
  saveMultiInterfaceSetupPassiveData,
  getMultiSetupSimConfig,
  getMultiSetupExtractionOptions,
  getMultiSetupExtractionPorts,
  updateMultiSimConfig,
  saveMultiSetupExtractionOptions,
  saveMultiSetupExtractionPorts,
  getMultiSetupExtractionApplyConfig,
  updateMultiSetupExtractionApplyConfig
}