import { PROJECT_V2 } from "../../../constants/projectVersion";
import { ROCKY_CHANNEL, CARD_VERIFICATION, SSN_VERIFICATION, VERIFICATION, PACKAGE_VERIFICATION, PACKAGE_PRE_LAYOUT, PACKAGE_PDN, PCB_PDN, PCB_CHANNEL } from "../../../constants/treeConstants";
import designConstructor from "../../helper/designConstructor";
import cardChannelsConstructor from "../cardHelper";
import packageVerificationConstructor from "../PackageHelper";
import RockySSNChannelInfo from "../SSN/channelsInfo";
import RockyChannels from "./channels";
import RockyChannelVerifications from "./channelVerifications";
import RockyProjectChannels from "./projectChannels";

function getCCCVerifications({ projectId, viewList, cardDesignId, projectVersion }) {
  let channelList = [];
  // interface channels
  const channelIds = RockyProjectChannels.get(projectId);
  for (let channelId of channelIds) {
    const verifications = RockyChannelVerifications.getVerificationsByChannelId(channelId) || [];
    const channel = RockyChannels.get(channelId) || {};
    let _verificationList = verifications.filter(item => item.type !== "Rocky_PDN");
    channelList.push({
      ...channel,
      verificationList: [..._verificationList]
    })
    if (projectVersion === PROJECT_V2 && channel.id) {
      const mergeInfo = RockySSNChannelInfo.getMergeInterfaceInfo(channel.id);
      if (mergeInfo && mergeInfo.id) {
        channelList.push({
          ...channel,
          id: mergeInfo.mergeVerificationId,
          verificationId: mergeInfo.mergeVerificationId,
          name: `${channel.name} (Merge)`
        })
      }
    }
  }
  //card design and channels
  let cardList = [];
  if (projectVersion !== PROJECT_V2) {
    const cardDesigns = designConstructor.getAvailableCardDesignValues(projectId) || [];
    for (let item of cardDesigns) {
      const cardChannelIds = cardChannelsConstructor.getChannelIds(item.id) || [];
      for (let channelId of cardChannelIds) {
        const verifications = cardChannelsConstructor.getVerifications(channelId) || []
        const channel = cardChannelsConstructor.getChannel(channelId) || {}
        let _verificationList = verifications.filter(item => item.type !== "Rocky_PDN");
        cardList.push({
          ...channel,
          interfaceType: 'Card',
          designName: item.name,
          verificationList: [..._verificationList]
        })
      }
    }
  }

  //package design and channels
  let pkgList = [];
  const pkgDesigns = (designConstructor.getAvailablePackageDesignValues(projectId) || []).filter(item => item.dataType !== PACKAGE_PRE_LAYOUT);
  for (let item of pkgDesigns) {
    const pkgChannels = packageVerificationConstructor.getPackageInfo(item.id);
    if (projectVersion === PROJECT_V2) {
      //channel and pdn
      const pkgPDN = packageVerificationConstructor.getPackagePDN(item.id) || {};
      pkgList.push({
        ...pkgPDN,
        interfaceType: 'Package',
        designName: item.name
      })
      pkgChannels[0] && pkgList.push({
        ...pkgChannels[0],
        interfaceType: 'Package',
        designName: item.name
      })
    } else {
      for (let channel of pkgChannels) {
        const verList = packageVerificationConstructor.getChannelVerifications(item.id, channel.groupName) || [];
        pkgList.push({
          ...channel,
          interfaceType: 'Package',
          designName: item.name,
          id: channel.groupName,
          name: channel.groupName,
          verificationList: [...verList]
        })
      }
    }
  }

  let pcbList = []
  if (projectVersion === PROJECT_V2) {
    //pcb channel and pdn
    const pcbDesign = designConstructor.getAvailablePCBDesignInfo(projectId);
    //channel and pdn
    if (pcbDesign && pcbDesign.children && pcbDesign.children.length) {
      pcbList.push(...pcbDesign.children.map(item => {
        return {
          ...item,
          interfaceType: 'PCB',
          designName: pcbDesign.name
        }
      }))
    }
  }

  if (projectVersion === PROJECT_V2) {
    return [{
      type: ROCKY_CHANNEL,
      name: "Channel",
      subType: "Interface",
      projectVersion,
      subList: [{
        name: "Interface",
        children: [...channelList]
      },
      {
        name: "PCB",
        children: [...pcbList]
      },
      {
        name: "Package",
        children: [...pkgList]
      }]
    }]
  }
  return [{
    type: ROCKY_CHANNEL,
    name: "Channel",
    subType: "Interface",
    verificationType: "Signal Group",
    subList: [
      {
        name: "Interface",
        children: [...channelList]
      },
      pkgList.length ? {
        name: "Package",
        children: [...pkgList]
      } : null,
      cardList.length ? {
        name: "Card",
        children: [...cardList]
      } : null,
    ].filter(item => !!item)
  }]
}

function getCCCSpecifyServiceVerification(channelId, verificationId, viewList) {
  if (!verificationId) {
    return null;
  }
  const view = viewList.find(item => [VERIFICATION, CARD_VERIFICATION, SSN_VERIFICATION,
    PACKAGE_VERIFICATION, PACKAGE_PDN, PCB_PDN, PCB_CHANNEL].includes(item));
  let verification = null;
  //todo package and card
  switch (view) {
    case VERIFICATION:
      let verifications = [];
      verifications = RockyChannelVerifications.getVerificationsByChannelId(channelId) || [];
      verification = verifications.find(item => item.id === verificationId);
      break;
    case CARD_VERIFICATION:
      verifications = cardChannelsConstructor.getVerifications(channelId) || [];
      verification = verifications.find(item => item.id === verificationId);
      break;
    case SSN_VERIFICATION:
      const channel = RockyChannels.get(verificationId) || {};
      verification = {
        id: channel.id,
        name: channel.name,
        dataType: "Interface",
        verificationId: channel.verificationId
      }
      break;
    default: break;
  }

  const specifyServiceType = ROCKY_CHANNEL;
  return verification ? { ...verification, specifyServiceType } : null;
}

export {
  getCCCVerifications,
  getCCCSpecifyServiceVerification
}