import React, { Fragment, createRef } from 'react';
import { Divider, Spin, Radio } from 'antd';
import ResultLayout from '../../../../services/Result/Public/resultLayout';
import { WaveFormPlot } from '../../../../services/Andes/results/waveformPlot';
import WaveformSetting from '../../../../services/Result/Public/waveform/waveformSetting';
import ResultRightMenu from '../../../../services/Result/Public/resultRightMenu';
import ResultList, { RESULT, HISTORY } from '../../../../components/Sparameter/resultList';
import { checkResultStatus, CLEAN, GET } from '../../../../services/helper/resultStatus';
import { getWaveformResultData, getWaveformResultList, getHistoryWaveformResultData } from '../../../../services/Andes_v2/results/waveform';
import { selectedChange, cancelMove, changeCurveColor } from '../../../../services/Result/Public/sparameter/dataHelper';
import getIndex from '../../../../services/helper/insertionSearch';
import { getHistoryVerificationJson, getVerificationJson } from '../../../../services/Andes_v2/results';
import { strDelimited } from '../../../../services/helper/split';
import { rowDisplayChange } from '../../../../services/Result/Public/sparameter/resultsMenuHelper';

class WaveformResult extends React.Component {
  constructor() {
    super()
    this.svgRef = createRef();
    this.state = {
      loading: false,
      portSelect: {},
      displayMode: 'Fast', // "Fast" / "Hi-Re"
      resultsObj: {},
      historyObj: {},
      historyFiles: []
    }
    this.WaveformPlot = new WaveFormPlot();
    this.interfaceContent = null;
    this.historyContent = new Map();
  }

  getHistoryIds = () => {
    const { historyList } = this.props;
    return historyList.map(d => d.id);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getWaveform();
      this.renderResultHistory();
    }
  }

  componentDidUpdate(prevProps) {
    const { id, status } = this.props;
    if (id !== prevProps.id) {
      this.getWaveform();
      this.renderResultHistory();
    }

    const historyListIds = this.getHistoryIds();
    const preHistoryListsIds = prevProps.historyList.map(d => d.id);

    if (historyListIds.length !== preHistoryListsIds.length || historyListIds.some(id => !preHistoryListsIds.includes(id))) {
      this.renderResultHistory();
    }

    // status
    if (id === prevProps.id && prevProps.status !== status) {
      const updateStatus = checkResultStatus(prevProps.status, status);
      switch (updateStatus) {
        case GET:
          // reload result data
          this.getWaveform();
          this.renderResultHistory();
          break;
        case CLEAN:
          this.initResult();
          break;
        default: break;
      }
    }
  }

  reloadAxisCB = () => {
    this.plot2D.updatePlot();
  }

  getCurves = () => {
    return this.WaveformPlot.getCurves();
  }

  initResult = () => {
    this.setState({
      resultsObj: {},
      portSelect: {}, // TODO
    })
    this.WaveformPlot.cleanWaveformPlot();
    if (this.plot2D) {
      if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
        this.svgRef.current.removeChild(this.svgRef.current.children[0]);
      }
    }
    this.plot2D = null;
  }

  getWaveform = async (ifInitResult = true) => {
    if (ifInitResult) { this.initResult() }
    const { displayMode, portSelect, resultsObj } = this.state;
    const { id, isEndToEnd, setupInfo } = this.props;
    this.interfaceContent = !this.interfaceContent ? setupInfo || await getVerificationJson(id, isEndToEnd) : this.interfaceContent;
    if (!this.interfaceContent) return;

    const waveform = await getWaveformResultData({ id, displayMode });
    const resultObj = this.waveformDataProcess({ waveform, id });
    if (portSelect[displayMode] && portSelect[displayMode][id]) {
      portSelect[displayMode][id] = {};
    }

    if (id === this.props.id) {
      this.setState({
        resultsObj: { ...resultsObj, [displayMode]: resultObj },
        portSelect: portSelect
      }, () => {
        this.initSelect(resultObj, id);
      })
    }
  }

  renderResultHistory = async () => {
    const { displayMode } = this.state;
    const { historyList, isEndToEnd } = this.props;
    // historyList - [{ id, name, channelId }], id: historyId
    let lists = [];
    for (const history of historyList) {
      const historyJson = !this.historyContent.get(history.id) ? await getHistoryVerificationJson(history.id, isEndToEnd) : this.historyContent.get(history.id);
      if (historyJson) {
        this.historyContent.set(history.id, historyJson);
        const waveform = await getHistoryWaveformResultData({ historyId: history.id, displayMode });
        const resultObj = this.waveformDataProcess({ waveform, id: history.id, historyJson, isHistory: true });
        lists.push({ resultObj, id: history.id });
      }
    }

    let _historyObj = {};
    lists.forEach(d => {
      _historyObj[d.id] = {};
      _historyObj[d.id][displayMode] = d.resultObj;
    });
    let historyFiles = historyList.map(d => ({ id: d.id, show: false, rowName: d.name, rowId: d.id }))
    this.setState({
      historyFiles: historyFiles,
      historyObj: _historyObj
    })
  }

  waveformDataProcess = ({ waveform, id, isHistory = false, historyJson }) => {
    let axis = { yUnit: 'V', yLabel: 'Voltage' };
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis
    };
    this.plot2D = this.WaveformPlot.plotWaveform({ svgElement: this.svgRef.current, events, waveform, axis, id });
    const curves = this.getCurves().filter(d => d.id === id);

    const interfaceContent = isHistory ? historyJson : this.interfaceContent;
    const resultList = getWaveformResultList({ curves, interfaceContent })
    return resultList;
  }

  changeMouse = (time, yPrefix) => {
    const curves = this.getCurves();
    const { displayMode, resultsObj, historyObj } = this.state;
    const { id } = this.props;
    const _resultsObj = { ...resultsObj }, _historyObj = { ...historyObj };
    const unit = 'V';
    if (!curves || !curves.length) return;
    for (const curve of curves) {
      let type = '';
      if (historyObj[curve.id]) {
        type = 'history';
      } else if (curve.id === id) {
        type = 'channel';
      }
      const { listIndex, childIndex } = this.getListChildIndex(curve, this.getList(curve, type));
      if (listIndex < 0) return;
      if (curve.visible && curve.y && curve.y.length) {
        const index = getIndex(curve.x, time, 0, curve.x.length - 1);
        const value = index > -1 ? yPrefix(curve.y[index]) + unit : '';
        if (type === 'history') {
          _historyObj[curve.id][displayMode].list[listIndex].children[childIndex].value = value;
        } else if (type === 'channel') {
          _resultsObj[displayMode].list[listIndex].children[childIndex].value = value;
        }
      } else {
        if (type === 'history') {
          _historyObj[curve.id][displayMode].list[listIndex].children[childIndex].value = '';
        } else if (type === 'channel') {
          _resultsObj[displayMode].list[listIndex].children[childIndex].value = '';
        }
      }
    };

    this.setState({
      resultsObj: _resultsObj,
      historyObj: _historyObj
    })
  }

  cancelMove = () => {
    const { resultsObj, historyObj, displayMode } = this.state;
    const selectArr = this.getCurrentSelects();
    let _historyObj = { ...historyObj };
    Object.keys(historyObj).forEach(id => {
      _historyObj[id] = cancelMove({ resultsObj: _historyObj[id], displayMode: displayMode, selectArr })
    })
    this.setState({
      resultsObj: cancelMove({ resultsObj, displayMode: displayMode, selectArr }),
      historyObj: _historyObj
    })
  }

  changeAxis = (axis) => {
    this.waveformSetting.setAxis(axis);
  }

  getListChildIndex(curve, list) {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].children.length; j++) {
        if (list[i].children[j].id === curve.name) {
          return {
            listIndex: i,
            childIndex: j
          }
        }
      }
    }
    return {
      listIndex: -1,
      childIndex: -1
    }
  }

  getList = (curve, type) => {
    const { resultsObj, historyObj, displayMode } = this.state;
    if (type === 'history') {
      return historyObj[curve.id] && historyObj[curve.id][displayMode] ? (historyObj[curve.id][displayMode].list || []) : [];
    } else {
      return resultsObj[displayMode] ? (resultsObj[displayMode].list || []) : [];
    }
  }

  getCurrentSelects = () => {
    let { portSelect, displayMode } = this.state;
    if (!portSelect || !portSelect[displayMode]) {
      return [];
    }
    return Object.values(portSelect[displayMode]).reduce((a, b) => {
      return Object.values(a).concat(...Object.values(b));
    }, []);
  }

  initSelect = (resultObj, id) => {
    if (resultObj.list.length > 0) {
      const values = resultObj.list[0].children.map(d => d.id)
      this.changePort(values, resultObj.list[0].rowName, id, []);
    }
  }

  changePort = (values, key, id, prevSelects) => {
    this.updateCureves(values, key, id, prevSelects);
    this.plot2D.updatePlot();
  }

  updateCureves = (values, key, id, prevSelects) => {
    let _prevSelects = prevSelects;
    const { portSelect, displayMode } = this.state;
    try {
      let curves = this.getCurves();
      // set state
      if (!_prevSelects) {
        _prevSelects = (portSelect[displayMode] && portSelect[displayMode][id] && portSelect[displayMode][id][key]) || [];
      }
      const { unchecks, newChecks } = selectedChange({ prevSelects: _prevSelects, values })
      unchecks.forEach(un => {
        const curveIndex = curves.findIndex(d => d.name === un);
        curves[curveIndex].visible = false;
      })
      newChecks.forEach(ne => {
        const curveIndex = curves.findIndex(d => d.name === ne);
        curves[curveIndex].visible = true;
      })
      this.updatePortSelect(key, values, id)
    } catch (error) {
      console.error(error);
    }
  }

  updatePortSelect = (rowName, values, id) => {
    const { displayMode } = this.state;
    this.setState((prevState) => {
      let _portSelect = prevState.portSelect;
      if (!_portSelect[displayMode]) {
        _portSelect[displayMode] = {};
      }
      if (!_portSelect[displayMode][id]) {
        _portSelect[displayMode][id] = {};
      }
      _portSelect[displayMode][id][rowName] = values;
      return {
        portSelect: _portSelect
      }
    });
  }

  colorChange = (e, key) => {
    e.persist()
    if (e.target.value) {
      const curves = this.getCurves();
      const curveIndex = curves.findIndex(d => d.name === key);
      curves[curveIndex].color = e.target.value;
      this.reloadAxisCB()
      this.setResultObjColor(e, key);
    }
  }

  setResultObjColor = (e, key) => {
    const { resultsObj, historyObj, displayMode } = this.state;
    let _resultsObj = { ...resultsObj }, _historyObj = { ...historyObj };
    const fileId = strDelimited(key, "::")[0];

    if (this.getHistoryIds().includes(fileId)) {//history
      const { resultList } = changeCurveColor({ e, key, resultList: _historyObj[fileId], displayMode: displayMode });
      _historyObj[fileId] = resultList;
    } else {
      //result
      const { resultList } = changeCurveColor({ e, key, resultList: _resultsObj, displayMode: displayMode })
      _resultsObj = resultList;
    }
    this.setState({
      resultsObj: _resultsObj,
      historyObj: _historyObj,
    })
  }

  rowNameClick = (displayMode, value) => {
    this.setState({
      resultsObj: rowDisplayChange(this.state.resultsObj, displayMode, value)
    })
  };

  changeSelectAll = (e, group, id) => {
    const check = e.target.checked;
    if (check) {
      this.changePort(group.children.map(d => d.id), group.rowName, id)
    } else {
      this.changePort([], group.rowName, id)
    }
  }

  _historyRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.historyObj[rowId] = rowDisplayChange(prevState.historyObj[rowId], displayMode, value)
      return {
        historyObj: prevState.historyObj
      }
    })
  }

  _fileNameClick = (id) => {
    const { historyFiles } = this.state;
    const index = historyFiles.findIndex(f => f.id === id);
    this.setState((prevState) => {
      prevState.historyFiles[index].show = !prevState.historyFiles[index].show;
      return {
        historyFiles: [...prevState.historyFiles]
      }
    })
  }

  customizeTitle = (item) => {
    // pin1 - pin2
    if (item.pin1 && item.pin2) {
      return <span className='andes-customized-title'>
        <span className='driver-or-receiver'>&nbsp;{item.type}</span>
        <span className='component-pin-name'>{`${item.comp} <${item.pin1} - ${item.pin2}>`}</span>
      </span>
    }

    // pin - ctle
    if (item.ctle) {
      return <span className='andes-customized-title'>
        <span className='driver-or-receiver'>&nbsp;{item.type}</span>
        <span className='component-pin-name'>{`${item.comp} <${item.pin}> - Channel out`}</span>
      </span>
    }

    // pin
    return <span className='andes-customized-title'>
      <span className='driver-or-receiver'>&nbsp;{item.type}</span>
      <span className='component-pin-name'>{`${item.comp} <${item.pin}>`}</span>
    </span>
  }

  resultListRender = () => {
    const { portSelect, resultsObj, historyObj, historyFiles, displayMode } = this.state;
    const { id } = this.props;
    return (
      <div style={{ marginTop: "10px" }}>
        <Fragment>
          {/* Result list */}
          <ResultList
            type={RESULT}
            fileId={id}
            rowId={id}
            displayMode={displayMode}
            portSelect={(portSelect[displayMode] && portSelect[displayMode][id]) || {}}
            changePort={this.changePort}
            colorChange={this.colorChange}
            resultList={resultsObj}
            rowNameClick={this.rowNameClick}
            changeSelectAll={this.changeSelectAll}
            customizeTitle={this.customizeTitle}
          />
          {/* History list */}
          <ResultList
            type={HISTORY}
            multiFiles={true}
            displayMode={displayMode}
            portSelect={portSelect[displayMode] || {}}
            portSelectMultiFile={true}
            changePort={this.changePort}
            colorChange={this.colorChange}
            resultList={historyObj}
            rowNameClick={this._historyRowNameClick}
            changeSelectAll={this.changeSelectAll}
            files={historyFiles}
            fileNameClick={this._fileNameClick}
            customizeTitle={this.customizeTitle}
          />
        </Fragment>
      </div>
    )
  }

  displayModeChange = (e) => {
    const { resultsObj } = this.state;
    this.setState({
      displayMode: e.target.value
    }, () => {
      if (!resultsObj[e.target.value]) {
        this.getWaveform(false);
        this.renderResultHistory(false)
      }
    })
  }

  axisChangeCB = (type, { _xMin, _xMax, _yMin, _yMax }) => {
    let plot = this.plot2D;
    if (type === 'xMin' || type === 'xMax') {
      let xMax = plot.options.xMax,
        xMin = plot.options.xMin,
        start = parseFloat(_xMin) * 1e-9,
        end = parseFloat(_xMax) * 1e-9;

      if (parseFloat(start).toString() === 'NaN') {
        start = 0;
      };
      if (parseFloat(end).toString() === 'NaN') {
        end = 0;
      };

      plot.xScale
        .domain([Math.min(start, end), Math.max(start, end)])
        .range([0, plot.size.width]);

      const xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = (xrange[0] - xMin) / (xMax - xMin) * width,
        endX = (xrange[1] - xMin) / (xMax - xMin) * width;
      plot.updateRange(startX, endX);
    } else {
      // y axis
      let yStart = parseFloat(_yMin),
        yEnd = parseFloat(_yMax);

      if (parseFloat(yStart).toString() === 'NaN') {
        yStart = 0;
      };
      if (parseFloat(yEnd).toString() === 'NaN') {
        yEnd = 0;
      };

      plot.yScale
        .domain([Math.max(yStart, yEnd), Math.min(yStart, yEnd)])
        .nice()
        .range([0, plot.size.height])
        .nice();
    }
    this.reloadAxisCB();
  }

  settingRender = () => {
    const { displayMode } = this.state;
    return (
      <div>
        <Divider
          orientation="left"
          className='waveform-setting-title'
          style={{
            margin: "6px 0px"
          }}
        >Setting</Divider>
        <div className='andes-v2-waveform-setting-css'>
          <Radio.Group onChange={this.displayModeChange} value={displayMode}>
            <Radio value="Fast">Fast</Radio>
            <Radio value="Hi-Re">Hi-Re</Radio>
          </Radio.Group>
          <WaveformSetting
            onRef={(ref) => { this.waveformSetting = ref; }}
            plot={this.plot2D}
            axisChangeCB={this.axisChangeCB}
            reloadAxisCB={this.reloadAxisCB}
            currentResultKey={displayMode}
          />
        </div>
      </div >
    )
  }

  resultRight = () => {
    return (
      <ResultRightMenu
        resultList={this.resultListRender}
        setting={this.settingRender}
      />
    )
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div
          className='waveform-svg'
          style={{ width: "100%", height: "100%" }}>
          <svg ref={this.svgRef}></svg>
        </div>
      </Spin>
    )
  }

  changeWidthCB = () => {
    if (this.plot2D) {
      this.plot2D.redrawPlot(this.svgRef.current);
    }
  }

  render() {
    return (
      <ResultLayout
        resultLeft={this.resultLeft}
        resultRight={this.resultRight}
        changeWidthCB={this.changeWidthCB}
      />
    )
  }
}

export default WaveformResult;