import http from '../http';

export function getRoles(page = 0, size = 10) {
  return http.get('/admin/roles/page', {
    params: { page, size }
  });
}

export function createRole(data) {
  return http.post('/admin/roles', data);
}

export function updateRole(id, data) {
  return http.post(`/admin/roles/update?roleId=${id}`, data);
}

export function deleteRole(id) {
  return http.post(`/admin/roles/delete?roleId=${id}`);
}

export function getPermissions() {
  return http.get(`/admin/permissions`);
}

export function getUserPermissions() {
  return http.get(`/admin/permissions/current`);
}