import React, { Component } from 'react';
import Table from '../EditableTable';
import auroraDBJson from '../../services/Designs/auroraDbData';
import PinConnectionPanel from './pinConnectionPanel';
import { PIN_CONNECTION } from '../../constants/libraryConstants';
import { PIN_MAP_VERSION } from '../../version';
import './index.css';

const Columns = [{
  title: "Pin 1",
  dataIndex: 'pinsLeft',
  width: '50%'
}, {
  title: "Pin 2",
  dataIndex: 'pinsRight',
  width: '50%'
}]
const pinData = { pinsLeft: "", pinsRight: "" }
class PinConnection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 500,
      dataSource: [],
      pins: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = async () => {
    const { designList = [] } = this.props;
    if (designList[0]) {
      const designId = designList[0].designId;
      if (!auroraDBJson.checkAuroraJson(designId)) {
        const setting = await this.props.componentSetting.getSetting({ designId });
        await auroraDBJson.getAuroraJson(designId, setting);
      }
      this.setState({
        designId: designId
      }, () => {
        this.getData();
        this.getColumns();
      })
    } else {
      this.getData();
      this.getColumns();
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleHeight);
  }

  handleHeight = () => {
    const { height = 800 } = this.dialogRoot.getBoundingClientRect();
    this.setState({
      panelMaxHeight: height - 200
    });
  }

  getColumns = () => {
    Columns[0].render = (text) => {
      return <span>{text || ''}</span>
    }

    Columns[1].render = (text) => {
      return <span>{text || ''}</span>
    }

    const dataIndexs = [{ index: 0, dataIndex: 'pinsLeft' }, { index: 1, dataIndex: 'pinsRight' }];
    dataIndexs.forEach(item => {
      const { index, dataIndex } = item;
      const { partName, designList } = this.props;
      Columns[index].onCell = (record) => {
        const { pins, dataSource, designId, comp } = this.state;
        const comps = auroraDBJson.getComponentsByPartName(designId, partName);
        return {
          edit: true,
          record,
          partName,
          customInput: PinConnectionPanel,
          dataIndex,
          pins,
          dataSource,
          designList,
          designId,
          comps,
          comp,
          saveDataSource: this.saveDataSource,
          savePinMap: this.savePinMap,
          selectDesign: this.selectDesign,
          selectComp: this.selectComp
        }
      }
    })
  }

  getData = () => {
    const { partName, pinTable = [] } = this.props;
    const { designId } = this.state;
    const components = auroraDBJson.getComponents(designId) || {};
    const comps = [...components.values()];
    const component = comps.find(c => c.partName === partName);
    if (component) {
      let pinLength = component.pins.size;
      const pins = [...component.pins.values()] || []
      pinLength = (pinLength % 2 === 0 ? pinLength : pinLength + 1) / 2;
      let dataSource = [];
      for (let i = 0; i < pinLength; i++) {
        dataSource.push({ index: i, ...pinData });
      }
      pinTable.forEach((array, index) => {
        const pinsLeft = array[0] ? array[0] : '';
        const pinsRight = array[1] ? array[1] : '';
        dataSource[index] = { ...dataSource[index], pinsLeft, pinsRight }
      })
      this.setState({
        dataSource,
        comp: component.name,
        pins
      })
    }
  }

  selectDesign = async (designId) => {
    if (!auroraDBJson.checkAuroraJson(designId)) {
      await auroraDBJson.getAuroraJson(designId);
    }
    this.setState({
      designId
    }, () => {
      const { partName } = this.props;
      const comps = auroraDBJson.getComponentsByPartName(designId, partName);
      if (comps.length) {
        const { name } = comps[0];
        this.selectComp(name)
      }
    })
  }

  selectComp = async (comp) => {
    const { partName } = this.props;
    const { designId } = this.state;
    const comps = auroraDBJson.getComponentsByPartName(designId, partName);
    if (comps.length) {
      const _comp = comps.find(item => item.name === comp);
      if (_comp) {
        const { name, pins } = _comp;
        this.setState({
          comp: name,
          pins: [...pins.values()] || []
        })
      }
    }
  }

  saveDataSource = (dataSource) => {
    this.setState({
      dataSource
    })
  }

  savePinMap = async () => {
    const { libraryId, name, partName, type } = this.props;
    const { dataSource } = this.state;
    const newPinTable = JSON.parse(JSON.stringify(dataSource));

    if (!dataSource.length) {
      return;
    }

    let pinTable = []
    newPinTable.forEach(item => {
      const { pinsLeft, pinsRight } = item;
      if (pinsLeft || pinsRight) {
        const left = pinsLeft ? pinsLeft : '';
        const right = pinsRight ? pinsRight : '';
        pinTable.push([left, right]);
      }
    })

    const data = {
      id: libraryId || '',
      name,
      config: { pinTable: pinTable },
      partName: partName,
      version: PIN_MAP_VERSION,
      type: PIN_CONNECTION
    }

    try {
      await this.props.updateLibraryData({ data });
      this.props.savePinTable(libraryId, pinTable)
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { dataSource } = this.state;
    return <Table
      columns={Columns}
      dataSource={dataSource}
      rowKey={(record) => record.index}
      className="pmic-part-pin-map-content-table"
      size="small"
    />
  }
}

export default PinConnection;