import React, { Component, createRef } from 'react';
import './resultRightMenu.css';

class ResultRightMenu extends Component {
  constructor() {
    super()
    this.settingRef = createRef();
    this.state = {
      fileListBottom: 318
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.updateFileListState();
  }

  componentDidUpdate() {
    const { fileListBottom } = this.state;
    if (this.settingRef.current && fileListBottom !== this.settingRef.current.offsetHeight) {
      this.updateFileListState();
    }
  }

  updateFileListState = () => {
    if (this.settingRef.current) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }
  }

  render() {
    const { fileListBottom } = this.state;
    const { top, resultList, setting } = this.props;
    return (
      <div className='result-right-menu'>
        <div className='result-file-list' style={{ bottom: fileListBottom, overflowY: 'auto', height: `calc(100% - ${fileListBottom}px)` }}>
          {top && top()}
          {resultList()}
        </div>
        <div ref={this.settingRef} className='result-setting' style={{ maxHeight: '50%' }}>
          {setting()}
        </div>
      </div>
    )
  }

}

export default ResultRightMenu;