import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Spin } from 'antd';
import TreeTitle from '../components/Tree/treeTitle';
import TreeCanvas from '../components/Tree/treeCanvas';
import {
  updateCurrentTree,
  updatePinSelectPanelId,
  updateTreeLoading,
  updateDrawType,
  deleteTreeItem,
  delDesignTree
} from '../store/DesignTree/action';
import { selectChange, changeShowNameStatus, selectLayer } from '../../LayoutExplorer/store/Cascade/actionCreators';
import { checkConnectComp } from '@/services/Cascade/helper/setupData';
import { NetGroupSelection } from '@/services/PCBHelper';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import canvas from '@/services/LayoutCanvas';
import { DESIGN_TREE, POWER_TREE, SINGLE_TREE } from '../../../constants/treeConstants';
import html2canvas from 'html2canvas';
import { downloadFile } from '../../../services/helper/downloadHelper';
import './index.css';
import { getCascadeComponents } from '../../../services/Cascade/helper/setupData';
import debounce from '../../../services/helper/debounceFn';

class DesignTree extends Component {
  constructor(props) {
    super(props);
    const { data = {}, viewList, number } = props;
    const { id, tree = {} } = data;
    this.state = {
      id: id,
      tree: tree,
      width: 1000,
      drawStatus: false,
      showCanvas: tree.deep < 40 || number === 1 ? true : false,
      shot: false,
      applyTo: viewList.includes(POWER_TREE) ? true : false
    }
    this.netGroupSelection = new NetGroupSelection();
  }

  componentDidMount() {
    this.getCanvansWidth();
    window.addEventListener('resize', this.getCanvansWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getCanvansWidth);
  }

  componentDidUpdate = (prevProps) => {
    const { draw, data, reset, number } = this.props;
    if (data.id && draw[data.id]) {
      this.props.updateDrawType(data.id, false);
      const { showCanvas, tree = {} } = this.state;
      this.setState({
        id: data.id,
        tree: data.tree,
        drawStatus: true,
        showCanvas: tree.deep === 0 ? data.tree.deep > 40 || number > 1 ? false : true : showCanvas
      }, () => {
        this.getCanvansWidth();
      });
    }

    if (reset !== prevProps.reset) {
      this.setState({
        id: data.id,
        tree: data.tree,
        drawStatus: true
      }, () => {
        this.getCanvansWidth();
      });
    }
  }

  getCanvansWidth = () => {
    const { id } = this.state;
    const ele = document.getElementById(`power-tree-layout-${id}`);
    const offset = ele ? ele.getBoundingClientRect() : null;
    this.setState({
      width: offset && offset.width ? offset.width : 1000
    })
  }

  changeTreeName = (name) => {
    const { id } = this.state;
    this.props.updateCurrentTree('name', id, name);
    this.updateTree({ name });
  }

  updateTree = (treeItem) => {
    const { tree } = this.state;
    this.setState({
      tree: {
        ...tree,
        ...treeItem
      }
    })
  }

  saveRoot = async (name) => {
    const { designId } = this.props;
    this.props.updatePinSelectPanelId(null);
    const comp = getCascadeComponents({ pcbId: designId, name});
    if (comp) {
      const { pins, name } = comp;
      const _pins = await checkConnectComp(name, [...pins.values()], designId);
      const _comp = {
        root: name,
        pins: {
          pinList: _pins,
          select: []
        }
      }
      this.updateTree({ ..._comp });
      const { id } = this.state;
      this.props.updateCurrentTree('root', id, _comp);
    }
  }

  openPinSelect = (e) => {
    e && e.stopPropagation();
    const { id } = this.state;
    this.props.updatePinSelectPanelId(id);
  }

  selectRootPins = (target) => {
    const { id, tree } = this.state;
    const pins = { ...tree.pins };

    const targetPins = pins.pinList.filter(pin => target.includes(pin.pin));
    if (targetPins.length) {
      const net = targetPins[0].nextNet || targetPins[0].net;
      if (tree.name.match(/Power Tree \d+$/g)) {
        this.changeTreeName(net);
      }
    }

    pins.select = target;
    this.updateTree({ pins });
    this.props.updateCurrentTree('pins', id, { pins })
    this.props.updatePinSelectPanelId(null);
  }

  updateDraw = (drawStatus) => {
    this.setState({ drawStatus });
  }

  saveTreeItemValue = (treeItem, columnIndex, valueType) => {
    const { id, tree, applyTo } = this.state;
    const { branch } = tree;
    const _branch = [...branch];
    const findIndex = _branch[columnIndex].findIndex(item => item.deepIndex === treeItem.deepIndex && item.name === treeItem.name && item.pcbKey === treeItem.pcbKey);
    if (findIndex > -1) {
      _branch[columnIndex][findIndex] = treeItem;
      if (applyTo && valueType) {
        const { pcbId, prevComp, name, isGnd } = treeItem;
        const value = treeItem[valueType];
        if (value) {
          _branch.forEach(column => {
            column.forEach(item => {
              if (item.name === name && item.prevComp === prevComp && pcbId === item.pcbId && item.isGnd === isGnd) {
                item[valueType] = value
              }
            })
          })
        }
      }
      this.updateTree({ branch: _branch });
      this.props.updateCurrentTree('branch', id, { branch: _branch })
    }
  }

  showWholeTree = (e) => {
    e && e.stopPropagation();
    const { tree } = this.state;
    const { branch } = tree;
    const _branch = branch.flat(2);
    let comps = [], nets = [];
    _branch.forEach(item => {
      comps.push(item.name);
      if (item.prevNet) {
        nets.push(item.prevNet);
      }
    })
    this.showTree({ comps, nets });
  }

  showTree = ({ comps, nets }) => {
    const { designId } = this.props;
    const _nets = [...new Set(nets)], _comps = [...new Set([...comps])];
    const layout = canvas.getLayout(designId);
    let layers = layout.findCurrentLayer(comps);
    layers = [...new Set(layers)];
    this.props._selectLayer(layers, designId);
    this.props._selectChange({ nets: _nets, comps: _comps }, designId)
    this.props._changeShowNameStatus(true, designId)
    setTimeout(() => {
      this.netGroupSelection.selectNetGroup({ nets: _nets, isPower: true })
    }, 100);
  }

  deleteICPath = ({ data, columnIndex }) => {
    this.props.deleteTreeItem(this.state.id, data, columnIndex);
  }

  updateShowCanvas = () => {
    this.setState({
      showCanvas: !this.state.showCanvas
    }, () => {
      const { showCanvas } = this.state;
      if (showCanvas) {
        this.getCanvansWidth()
      }
    })
  }

  deletePowerTree = (e) => {
    e && e.stopPropagation();
    const { id } = this.state;
    this.props.delDesignTree(id);
  }

  changePCBIndexColor = (e, index) => {
    const color = e.target.value;
    const { tree, id } = this.state;
    debounce(() => {
      const { pcbIndex } = tree;
      let _pcbIndex = [...pcbIndex];
      _pcbIndex[index].color = color;
      this.props.updateCurrentTree('pcbIndex', id, { pcbIndex: _pcbIndex });
    }, 300, false, 'treePCBColor')()
  }

  translateToCanvas = (id, name) => {
    this.setState({
      showCanvas: true,
      shot: true
    }, () => {
      setTimeout(() => {
        const ele = document.getElementById(`power-tree-${id}`);
        const canvas = this.canvasRef.getCanvas();
        const config = {
          width: canvas.scrollWidth,
          windowWidth: canvas.scrollWidth + 400,
          scale: 1
        }
        html2canvas(ele, config).then(canvas => {
          downloadFile(canvas, name, 'canvas');
        })
        this.setState({
          shot: false
        })
      }, 500)
    })
  }

  changeApplyTo = (e) => {
    this.setState({
      applyTo: e.target.checked
    })
  }

  setCanvasRef = (ref) => {
    this.canvasRef = ref;
  }

  changeWhatIf = (newWhatIf) => {
    const { id, tree } = this.state;
    this.setState({
      tree: {
        ...tree, whatIf: newWhatIf
      }
    })
    this.props.updateCurrentTree('whatIf', id, newWhatIf)
  }

  saveWhatIfData = (pair, ifValue, pcbKey) => {
    const { tree, id } = this.state;
    const { whatIfData = [] } = tree;
    let _whatIfData = [...whatIfData];
    if (!ifValue) {
      _whatIfData = _whatIfData.filter(data =>
        !(data.pcbKey === pcbKey &&
          data.componentIn === pair.componentIn &&
          data.componentOut === pair.componentOut &&
          data.pinIn === pair.pinIn &&
          data.pinOut === pair.pinOut))
    } else {
      const findIndex = _whatIfData.findIndex(data =>
        data.pcbKey === pcbKey &&
        data.componentIn === pair.componentIn &&
        data.componentOut === pair.componentOut &&
        data.pinIn === pair.pinIn &&
        data.pinOut === pair.pinOut)
      if (findIndex > -1) {
        _whatIfData[findIndex].ifValue = ifValue;
      } else {
        _whatIfData.push({ ...pair, pcbKey, ifValue })
      }
    }
    this.setState({
      tree: {
        ...tree, whatIfData: _whatIfData
      }
    })
    this.props.updateCurrentTree('whatIfData', id, _whatIfData)
  }

  render() {
    const {
      loading, treeLoading, viewList, selectedIds, designId, running, DriverList,
      results, showResult, multiResults, rootPCBId, rootPCBKey, refreshResults, connectors,
      includeInd, spiceSimulation, multiSpiceResults, spiceResults, setting
    } = this.props;
    const { tree, id, width, drawStatus, showCanvas, shot, applyTo } = this.state;
    const { name, root, branch = [], deep, groundNet, pcbIndex, logs, whatIf = false, whatIfData = [] } = tree;
    const showLayout = viewList.length > 1 && selectedIds.includes(designId) ? true : false;
    const view = viewList.find(item => [POWER_TREE, DESIGN_TREE, SINGLE_TREE].includes(item));
    return <div className={`cascade-power-tree-main ${showCanvas && !branch.length ? 'cascade-power-tree-main-loading-no-canvas' : ''}`} style={{ minHeight: showCanvas ? 100 : 'unset' }}>
      <Spin spinning={loading || treeLoading[id] || shot ? true : false} tip={shot ? 'Saving screenshot...' : treeLoading[id]}>
        <div className='cascade-power-tree-name-edit' style={showCanvas ? {} : { borderBottom: '0px', paddingBottom: '0px' }}>
          <TreeTitle
            changeTreeName={this.changeTreeName}
            name={name}
            showCanvas={showCanvas}
            updateShowCanvas={this.updateShowCanvas}
            couldDel={true}
            deletePowerTree={this.deletePowerTree}
            logs={logs}
            screenShot={() => this.translateToCanvas(id, name)}
            whatIf={whatIf}
            changeWhatIf={this.changeWhatIf}
            spiceSimulation={spiceSimulation[id] || false}
          />
        </div>
        {view === POWER_TREE && <div className='cascade-power-tree-apply-to'>
          <Checkbox
            style={{ marginRight: 18 }}
            checked={applyTo}
            onChange={this.changeApplyTo}
          />
          <span>Apply value to components on different PCBs with the same name</span>
        </div>}
        {showCanvas ?
          branch.length ?
            <TreeCanvas
              id={id}
              canvasRef={this.setCanvasRef}
              root={root}
              branch={branch}
              deep={deep}
              groundNet={groundNet}
              width={width}
              drawStatus={drawStatus}
              showLayout={showLayout}
              updateTreeLoading={this.props.updateTreeLoading}
              updateDrawType={this.updateDraw}
              saveTreeItemValue={this.saveTreeItemValue}
              showTree={this.showTree}
              deleteICPath={this.deleteICPath}
              running={running}
              showResult={showResult}
              resultDisplay={showResult}
              results={results}
              spiceResults={spiceResults}
              driverList={DriverList}
              pcbIndex={pcbIndex}
              multiResults={multiResults}
              view={view}
              rootPCBId={rootPCBId}
              rootPCBKey={rootPCBKey}
              colorChange={this.changePCBIndexColor}
              refreshResults={refreshResults}
              connections={connectors}
              shot={shot}
              includeInd={includeInd}
              whatIf={whatIf}
              whatIfData={whatIfData}
              saveWhatIfData={this.saveWhatIfData}
              multiSpiceResults={multiSpiceResults}
              setting={setting}
            /> : null
          : null}
      </Spin>
    </div>
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    DesignTree: { pinSelect, treeLoading, draw, designId, reset, running, results, showResult, refreshResults, option = {}, spiceSimulation, spiceResults, setting },
    project: { viewList, selectedKeys },
    library: { DriverList },
    PowerTree: { results: multiResults, root: { pcbId: rootPCBId = "" }, connectors = [], spiceResults: multiSpiceResults }
  } } = state;
  const rootPCBKey = connectors.length ? connectors[0] && connectors[0].length ? connectors[0][0].key : '' : '';
  const { includeInd = true } = option;
  return {
    pinSelect,
    treeLoading,
    draw,
    designId,
    viewList,
    selectedIds: getSelectedDesignIDs(selectedKeys),
    reset,
    running,
    results,
    showResult,
    DriverList,
    multiResults,
    rootPCBId,
    rootPCBKey,
    refreshResults,
    connectors,
    includeInd,
    spiceSimulation,
    multiSpiceResults,
    spiceResults,
    setting
  };
}

const mapDispatch = (dispatch) => ({
  updateCurrentTree(key, id, params) {
    dispatch(updateCurrentTree(key, id, params))
  },
  updatePinSelectPanelId(id) {
    dispatch(updatePinSelectPanelId(id))
  },
  updateTreeLoading(id, tip) {
    dispatch(updateTreeLoading(id, tip));
  },
  updateDrawType(id, bool) {
    dispatch(updateDrawType(id, bool));
  },
  _changeShowNameStatus(status, designId) {
    dispatch(changeShowNameStatus(status, designId))
  },
  _selectLayer(layers, designId) {
    dispatch(selectLayer(layers, designId))
  },
  _selectChange(obj = {}, designId) {
    dispatch(selectChange(obj, designId))
  },
  deleteTreeItem(id, item, columnIndex) {
    dispatch(deleteTreeItem(id, item, columnIndex))
  },
  delDesignTree(id) {
    dispatch(delDesignTree(id))
  }
})

export default connect(mapState, mapDispatch)(DesignTree);