import React, { Component } from "react";
/* import Stackup from './pages/LayoutExplorer/components/Stackup_v1'; */
import { getOffsetLeft, getOffsetTop } from '@/services/helper/htmlElement';

class StackupPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxWidth: 800,
      maxHeight: 600,
      leftWidth: 0,
      topWidth: 0,
      designID: null
    }
    this.layoutRef = null
  }

  componentDidMount() {
    this.getStackupProps()
  }

  getStackupProps = () => {
    this.layoutRef = document.getElementById('root');

    if (this.layoutRef) {
      const maxWidth = this.layoutRef.offsetWidth;
      const maxHeight = this.layoutRef.offsetHeight;
      const offsetLeft = getOffsetLeft(this.layoutRef);
      const offsetTop = getOffsetTop(this.layoutRef);

      this.setState({
        maxWidth: parseInt(maxWidth * 0.8),
        maxHeight: parseInt(maxHeight * 0.8),
        leftWidth: offsetLeft,
        topWidth: offsetTop
      })
    }
  }

  render() {
    const { designID, designList, closeModal, pageType, Stackup } = this.props
    const currentPCB = designList.find(item => item.id === designID)
    const pcbName = currentPCB ? currentPCB.name : ''

    return (<Stackup
      {...this.props}
      {...this.state}
      pageType={pageType}
      designID={designID}
      zIndex={2000}
      title={pcbName}
      NotPCBStackup={true}
      closePanel={closeModal}
    />)
  }
}


export default StackupPanel;