import CeLocation from '../geometry/CeLocation';
import StringList from '../utility/StringList';

var FutprtPartPad = function () {
  this.mPadID = "1";
  this.mTemplateID = 0;
  this.mLocation = new CeLocation();
};

FutprtPartPad.prototype.GetID = function () {
  return this.mPadID;
}

FutprtPartPad.prototype.SetPadTemplateIDs = function (padId, templateId) {
  this.mPadID = padId;
  this.mTemplateID = templateId;
};

FutprtPartPad.prototype.SetLocation = function (loc, rotAngle) {
  this.mLocation.SetPosition(loc.mX, loc.mY);
  this.mLocation.SetRotationAngle(rotAngle);
}

FutprtPartPad.prototype.ToGCPolygon = function (padGCPolygons, futprtSymbol) {
  var padTemplate = futprtSymbol.GetPadTemplate(this.mTemplateID.toString());
  var geomHolder = padTemplate.GetGeometryList();
  for (var i = 0; i < geomHolder.mGeomList.length; i++) {
    var geomObj = geomHolder.mGeomList[i];
    if (geomObj != null) {
      var geomType = geomObj.GetGeomType();
      if (geomType !== "Circle") {
        var gcPoly = geomObj.ConvertToGCPolygon();
        if (this.mLocation != null)
          this.mLocation.TransformGCPolygon(gcPoly);
        padGCPolygons.push(gcPoly);
      } else {
        var circleObj = geomObj.Clone();
        if (this.mLocation != null)
          circleObj.Transform(this.mLocation);
        padGCPolygons.push(circleObj);
      }
    }
  }
}

FutprtPartPad.prototype.GetPadGeometry = function (padGCPolygons, futprtSymbol) {
  var padTemplate = futprtSymbol.GetPadTemplate(this.mTemplateID.toString());
  var geomHolder = padTemplate.GetGeometryList();
  for (var i = 0; i < geomHolder.mGeomList.length; i++) {
    var geomObj = geomHolder.mGeomList[i].Clone();
    if (geomObj != null) {
      if (this.mLocation != null)
        geomObj.Transform(this.mLocation);
      padGCPolygons.push(geomObj);
    }
  }
}

FutprtPartPad.prototype.WriteIntoIODataNode = function (padBlock) {
  var ids = new StringList();
  ids.push(this.mPadID);
  ids.push(this.mTemplateID);
  padBlock.AddDataItem("PadIDs", ids);
  var location = this.mLocation.GetDataStrings();
  padBlock.AddDataItem("Location", location);
}

FutprtPartPad.prototype.ReadFromIODataNode = function (padBlock) {
  var ids = padBlock.GetItemStringList("PadIDs");
  if (ids.size() >= 2) {
    this.mPadID = ids.get(0);
    this.mTemplateID = parseInt(ids.get(1), 10);
  }
  var location = padBlock.GetItemStringList("Location");
  this.mLocation.UpdateByString(location);
}

export default FutprtPartPad;