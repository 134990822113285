import http from '../http';

export function getChannelSimOptions(channelId) {
  return http.get(`/Himalayas/channel/config?channelId=${channelId}`);
}

export function updateChannelSimOptions({ channelId, options, allInterfaces }) {
  return http.post(`/Himalayas/channel/config/update`, {
    channelId,
    config: options,
    allInterfaces
  })
}

export function getIoSimContent(channelId) {
  return http.get(`Himalayas/IoSim/${channelId}/interface`)
}