// ================= Component structure =================
import { BasicCompModel } from './Model';

export class BasicComponent {
  constructor({ name = '', type = '', pins = [], pkg = '', probePins = null }) {
    this.name = name;
    this.type = type;
    this.pkg = pkg;
    // this.pinModels = pinModels;
    this.pins = pins;
    if (probePins) {
      this.probePins = probePins;
    }
    this.addModel.bind(this);
  }

  addModel({ signal, pin, net }) {
    this.pins.push(new BasicCompModel({ signal, pin, net }));
  }
}

export class RLCCONComponent extends BasicComponent {
  constructor(props) {
    super(props);
    this.value = props.value || '';
  }
}

// ================= Signal structure ================
export class Signal {
  constructor(name = '') {
    this.name = name;
    this.nets = [];
  }
}

// ================= SourceNets structure ================
export class SourceNets {
  constructor(name = '', value = '', type = 'Reference') {
    this.name = name;
    this.value = value;
    this.type = type
  }
}