import { DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE } from '@/constants/treeConstants';
class CascadeChannels {
  constructor() {
    this.channels = {
      [DCR]: new Map(), // key - projectId, value - DCRList
      [IR_EXPLORER]: new Map(), // key - projectId, value - IRList
      [IMPEDANCE]: new Map(), // key - projectId, value - ImpedanceList
      [POWER_TREE]: new Map(), // key - projectId, value - Power Tree List
      [SIGN_OFF_TEMPLATE]: new Map(), // key - projectId, value - sign-off template List
      [DESIGN_TREE]: new Map(), // key - projectId, value - Design Tree List
      [SINGLE_TREE]: new Map()
    }
  }

  setList = (type, id, list) => {
    this.channels[type].set(id, list);
  }

  getList = (type, id) => {
    const data = this.channels[type].get(id) ? this.channels[type].get(id) : []
    return data;
  }

  getVerification = (type, projectId, verificationId) => {
    const data = this.channels[type].get(projectId) ? this.channels[type].get(projectId) : []
    return data.find(item => item.id === verificationId);
  }

  getVerificationName = (type, projectId, verificationId) => {
    const data = this.getVerification(type, projectId, verificationId);
    return data ? data.name : null;
  }
}

const cascadeChannels = new CascadeChannels();
export default cascadeChannels;