import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import LibraryUpload from './libraryUpload';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { REPEATER } from '../../../constants/libraryConstants';
import './sierraPanel.css';

class LibraryUploadPanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    if (!this.libraryUploadChild.getUploadingStatus()) {
      this.props.closeLibraryPanel();
    }
  }

  onRef = (ref) => {
    this.libraryUploadChild = ref;
  }

  render() {
    const { title, uploadTitle, accept, importText, modelType, closeLibraryPanel, openRemoveModelsPanel, special, parentId, updateLibrary } = this.props;
    const content = (
      <Fragment>
        <Panel
          className='sierra-library-panel sierra-panel'
          position='panel-center-left'
          title={<div>
            <span>{title}</span>
            {modelType === REPEATER ? <Tooltip
              overlayClassName='icon-tooltip'
              title="Please put the spice files with dependencies in a folder and upload them with the zip or folder option."
            >
              <QuestionCircleOutlined className='sierra-library-help-icon' />
            </Tooltip> : null}
          </div>}
          zIndex={2000}
          onCancel={this.closeModal}
          width={400}
          draggable
          minWidth={300}
          minHeight={180}
        >
          <LibraryUpload
            uploadTitle={uploadTitle}
            importText={importText}
            accept={accept}
            special={special}
            modelType={modelType}
            parentId={parentId}
            closeLibraryPanel={closeLibraryPanel}
            openRemoveModelsPanel={openRemoveModelsPanel}
            onRef={this.onRef}
            updateLibrary={updateLibrary}
          />
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default LibraryUploadPanel;