import { HimalayasProjectSaga } from './project';
import { HimalayasLibrarySaga } from './library';
import { HimalayasSpiceCardSaga } from './spiceCardTemplate';
import { SimulationSpiceCardSaga } from './simulation/spiceCardTemplate/saga';
import { HimalayasSimulationSaga } from './simulation';
import { SimulationIoSimSaga } from './simulation/ioSim/saga';
import { HimalayasIoSimSagaSaga } from './ioSim';

export default [
  HimalayasProjectSaga,
  HimalayasLibrarySaga,
  HimalayasSpiceCardSaga,
  SimulationSpiceCardSaga,
  HimalayasSimulationSaga,
  SimulationIoSimSaga,
  HimalayasIoSimSagaSaga
]