import Viewer, { CurveItem } from '../../../Result/Public/sparameter/PlotView';
import { select } from 'd3-selection';
import { unitChange } from '../../../helper/mathHelper';
class Parameter {

  constructor(curves) {
    this.parameters = new Map();
    this.plotViewer = null;
    this.data = null;
    this.id = curves.id
  }

  importData(data) {
    const newData = [];
    for (let item of data) {
      const { frequency, impedance } = item;
      let newFrequency = [...frequency], newImpedance = [...impedance];
      if (newFrequency.length && newFrequency[0] === 0) {
        newFrequency[0] = newFrequency[1] ? newFrequency[1] / 100 : 1e-6;
      }
      newImpedance = newImpedance.map(i => (unitChange({ num: i, oldUnit: 'Ω', newUnit: 'mΩ' }).number))
      newData.push({ ...item, frequency: newFrequency, impedance: newImpedance })
    }
    this.data = newData;
  }

  createPlot(element) {
    const options = {
      background: '#fff',
    }
    this.plotViewer = new Viewer({ element, options, id: 'Cascade' + this.id });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit('Hz');
    xAxis.setLabel('Frequency');
    xAxis.setScale('log');
    let yAxis = this.plotViewer.getAxis('y');
    yAxis.setUnit('mΩ');
    yAxis.setLabel('Impedance');
    // Default y scale
    yAxis.setting.scale = "linear";
    return this.plotViewer;
  }

  // curves -[{ parameter, fileIndex, row, col, curveType }]
  showCurves() {
    return Promise.all(this.data.map(cur => {
      let curve = new CurveItem();
      curve.x = cur.frequency;
      curve.y = cur.impedance;
      curve.visible = true;
      curve.id = cur.id;
      curve.file = null;
      curve.curveType = 'target';
      return curve;
    }))
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }
}

export default Parameter;