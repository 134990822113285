import React, { Component, Fragment, createRef } from 'react';
import {
  CloseOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusSquareOutlined,
  UploadOutlined,
  DownOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';
import { Divider, Row, Col, Tooltip, message, Progress, Cascader, Checkbox } from 'antd';
import { strDelimited } from '@/services/helper/split';
import ResultCheckboxGroup from './resultCheckboxGroup';
import JSZip from 'jszip';
import { VERIFY_FAILED, VERIFY_RUNNING, VERIFY_SUCCESS, WAITING } from '../../constants/verificationStatus';
import { CPHY } from '../../services/PCBHelper/constants';
import "../../publicCss/style.css";
import "./style.css";

/**
 * resultList: { displayMode: { show: [rowName], list : [] } }
 * resultList[displayMode].list: [{ rowName, children: [] }]
 * resultList[displayMode].list[N].children: [{ id, name, color }]
 *
 */
const RESULT = 'Result', HISTORY = 'History', IMPORT = 'Import', COMPARE = 'Comparison', OPTIMIZATION = 'Optimization', SWEEPMODEL = 'SweepModel';
class ResultList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultShow: true,
      importProgress: -1,
      compareAdd: false,
      compareShow: []
    }
    this.uploadSnpRef = createRef();
  }

  resultTitleClick = () => {
    const { resultShow } = this.state;
    this.setState({
      resultShow: !resultShow
    });
  };

  getRowPortSelect = (fileId, rowName, isAll = false) => {
    const { portSelectMultiFile, portSelect, allPortSelect } = this.props;
    const _select = isAll ? allPortSelect || {} : portSelect || {};
    if (portSelectMultiFile) {
      if (_select[fileId] && _select[fileId][rowName]) {
        return _select[fileId][rowName];
      } else {
        return []
      }
    } else {
      return _select[rowName] || []
    }
  }

  isCheckAll = (group, fileId, isParallel, verticalListData) => {
    let checkedAll = false, indeterminate = false;
    const { multiFiles, channelType } = this.props;
    const _fileId = channelType === CPHY ? fileId.split('::')[0] : fileId;
    const rowPortSelect = this.getRowPortSelect(_fileId, group.rowName);

    if (multiFiles) {
      if (rowPortSelect && rowPortSelect.length > 0) {
        let currentPcbAll = [];
        rowPortSelect.forEach(item => {
          const [id] = strDelimited(item, "::");
          if (id === _fileId) {
            indeterminate = true;
            currentPcbAll.push(item);
          }
        })

        if (isParallel) {
          const { checkedAll: _checkedAll, indeterminate: _indeterminate } = this.getParallelCheckStatus({ group, rowPortSelect, multiFiles, verticalListData, checkedAll, indeterminate });
          checkedAll = _checkedAll;
          indeterminate = _indeterminate;
        }

        if (!isParallel && currentPcbAll && group.children.length && currentPcbAll.length === group.children.length) {
          checkedAll = true;
          indeterminate = false;
        }
      };
    } else {
      // Signal file
      if (rowPortSelect && rowPortSelect.length > 0) {
        indeterminate = true;
      };
      if (!isParallel && rowPortSelect && group.children && rowPortSelect.length === group.children.length) {
        checkedAll = true;
        indeterminate = false;
      };

      if (isParallel) {
        const allRowPortSelect = multiFiles ? [] : this.getRowPortSelect(_fileId, group.rowName, true);
        const { checkedAll: _checkedAll, indeterminate: _indeterminate } = this.getParallelCheckStatus({ group, rowPortSelect, allRowPortSelect, checkedAll, indeterminate });
        checkedAll = _checkedAll;
        indeterminate = _indeterminate;
      }
    }
    return { checkedAll, indeterminate };
  }

  getParallelCheckStatus = ({ group, rowPortSelect, allRowPortSelect, checkedAll, indeterminate, multiFiles, verticalListData }) => {
    let allSelectedGroups = [];

    if (group && group.groupName && group.children && rowPortSelect && rowPortSelect.length > 0) {
      if (multiFiles) {
        for (let name of group.groupName) {
          const data = verticalListData.find(item => item.name === name);
          if (!data || !data.dataList) {
            continue;
          }
          const findCurrRow = data.dataList.find(item => item.signal === group.rowName);
          if (!findCurrRow) {
            continue;
          }
          if (findCurrRow.selectPortData.length) {
            indeterminate = true;
          }
          if (findCurrRow.allPortData.length === findCurrRow.selectPortData.length) {
            allSelectedGroups.push(name)
          }
        }
      } else {
        for (let name of group.groupName) {
          const currAllPorts = allRowPortSelect.filter(item => item.split('@')[0] === name);
          const filterSelectedPorts = rowPortSelect.filter(item => currAllPorts.includes(item));

          if (filterSelectedPorts.length && filterSelectedPorts.length === currAllPorts.length) {
            allSelectedGroups.push(name);
          }
        }
      }
    }
    if (allSelectedGroups.length === group.groupName.length) {
      checkedAll = true;
      indeterminate = false;
    }
    return { checkedAll, indeterminate };
  }

  getList = (id) => {
    const { resultList = {}, displayMode, multiFiles, handleResultList } = this.props;
    let obj = {};
    if (multiFiles) {
      obj = (resultList[id] && resultList[id][displayMode]) || {};
    } else {
      obj = resultList[displayMode] || {};
    }

    if (Object.prototype.toString.call(handleResultList) === '[object Function]') {
      obj = handleResultList(obj, id);
    }
    return obj;
  }

  _rowNameClick = (rowName, rowId) => {
    const { displayMode, rowNameClick } = this.props;
    rowNameClick(displayMode, rowName, rowId);
  }

  getVerticalData = (multiRowSelectData, pcb, file) => {
    let verticalListData = [], showChecked = false;
    const data = multiRowSelectData.find(item => item.pcb === pcb);
    if (data) {
      verticalListData = data.experimentListData
      showChecked = data.showChecked
    }
    return { verticalListData, showChecked }
  }

  uploadFile = (e) => {
    e && e.stopPropagation();
    const el = this.uploadSnpRef.current;
    if (!el) return;
    el.click();
  }

  stopPropagation = (e) => {
    e && e.stopPropagation() && e.preventDefault()
  }

  showCompareAdd = (e, compareAdd) => {
    e && e.stopPropagation();
    compareAdd && this.props.getCompareList()
    this.setState({
      compareAdd
    })
  }

  onUploadSnpFile = async (e) => {
    e && e.stopPropagation();
    const files = e.target.files;

    if (!files || !files.length) {
      return;
    }

    this.setState({
      importProgress: 0
    }, async () => {
      this.uploadTime = setInterval(() => {
        let progress = this.state.importProgress;
        progress += Math.floor(Math.random() * 10 + 1);
        if (progress > 98) {
          progress = 98;
        }
        this.setState({
          importProgress: progress,
        })
      }, 1000);

      const _files = [...files];
      const isZip = _files.length === 1 && _files[0].type === 'application/zip' ? true : false;
      let zip = undefined;
      if (isZip) {
        zip = _files[0]
      } else {
        const __files = _files.filter(item => item.name.match(/(.s[0-9]+p)$/ig));
        if (!__files.length) {
          clearInterval(this.uploadTime)
          this.setState({
            importProgress: -1,
          })
          message.error('No legal sNp file found!')
          return;
        }
        zip = new JSZip();
        for (const file of __files) {
          zip.file(file.name, file);
        }
      }
      const res = await this.props.uploadImports(zip, isZip);

      if (!res) {
        message.error('Import file upload failed!');
      }

      clearInterval(this.uploadTime)
      this.setState({
        importProgress: 100
      }, () => {
        setTimeout(() => {
          this.setState({
            importProgress: -1
          })
        }, 1000)
      })
    })
  }

  selectNewTask = (value, selectOptions) => {
    const id = value[1] ? value[1] : ""
    this.props.addNewCompare(selectOptions);
    this.setState({
      compareAdd: false
    })
    if (id) {
      this.switchCompareTitle(null, id)
    }
  }

  switchCompareTitle = (e, id) => {
    e && e.stopPropagation();
    const { compareShow } = this.state;
    this.setState({
      compareShow: compareShow.includes(id) ? compareShow.filter(i => i !== id) : [...compareShow, id]
    })
  }

  getResultCheckboxGroup = (rowId, fileId, verticalListData, paramList, paramShow) => {
    const { portSelect, changePort, changeAllPorts, changeSelectAll, portSelectMultiFile = false, colorChange, customizeTitle, experimentList, designs, changeSelectSignalAll, multiFiles = false, ResultPostProcess, postProcessTitle, changePostProcessVisible, isPostProcess, currentPostProcessData, channelType, recalc, showOptResult, optList, sweepReCalc, sweepTimeShowList } = this.props;
    const { list, show } = this.getList(rowId);
    const _fileId = channelType === CPHY ? fileId.split('::')[0] : fileId;
    return <ResultCheckboxGroup
      list={paramList || list}
      show={paramShow || show}
      rowId={rowId}
      fileId={_fileId}
      portSelect={portSelectMultiFile ? (portSelect[_fileId] || {}) : portSelect}
      rowNameClick={this._rowNameClick}
      changePort={changePort}
      changeAllPorts={changeAllPorts}
      changeSelectAll={changeSelectAll}
      isCheckAll={this.isCheckAll}
      colorChange={colorChange}
      customizeTitle={customizeTitle}
      experimentList={experimentList}
      designs={designs}
      verticalListData={verticalListData}
      changeSelectSignalAll={changeSelectSignalAll}
      multiFiles={multiFiles}
      ResultPostProcess={ResultPostProcess}
      postProcessTitle={postProcessTitle}
      changePostProcessVisible={changePostProcessVisible}
      currentPostProcessData={currentPostProcessData}
      isPostProcess={isPostProcess}
      recalc={recalc}
      showOptResult={showOptResult}
      optList={optList}
      sweepReCalc={sweepReCalc}
      sweepTimeShowList={sweepTimeShowList}
    />
  }

  getCompareFileResultList = () => {
    const { compares, compareDelete } = this.props;
    const { compareShow } = this.state;
    return compares.map(compare => {
      const { verificationId, name } = compare;
      return (
        <Fragment key={verificationId}>
          <div key={verificationId} onClick={(e) => this.switchCompareTitle(e, verificationId)} className="sparameter-compare-title">
            {compareShow.includes(verificationId) ? <DownOutlined className="sparameter-file-expand-icon" /> : <RightOutlined className="sparameter-file-expand-icon" />}
            <span className='sparameter-span-file-name' title={name}>{name}</span>
            {compareDelete && <CloseOutlined
              className="sparameter-file-delete-icon"
              onClick={(e) => compareDelete(e, verificationId)} />}
          </div>
          {compareShow.includes(verificationId) && <div className="sparameter-compare-content">
            {this.getMultiFilesResultList(`${verificationId}-${COMPARE}`)}
          </div>}
        </Fragment>
      );
    });
  }

  getMultiFilesResultList = (filterType = '') => {
    // files: [{ id, show, rowName, rowId }]
    const { files = [], multiRowSelectData = [] } = this.props;
    const _files = filterType ? files.filter(f => f.type === filterType) : files;
    if (_files && _files.length) {
      return _files.map((file, fileIndex) => {
        const { pcb } = file;
        const { verticalListData, showChecked } = this.getVerticalData(multiRowSelectData, pcb, file)
        return this.successFileRender(file, fileIndex, showChecked, verticalListData)
      })
    }
  }

  getImportFileResultList = () => {
    const { files, multiRowSelectData = [] } = this.props;
    if (files && files.length) {
      return files.map((file, fileIndex) => {
        const { pcb, status } = file;
        const { verticalListData, showChecked } = this.getVerticalData(multiRowSelectData, pcb);
        switch (status) {
          case WAITING:
          case VERIFY_FAILED:
          case VERIFY_RUNNING:
            return this.unSuccessFileRender(file, fileIndex, status)
          case VERIFY_SUCCESS:
          default:
            return this.successFileRender(file, fileIndex, showChecked, verticalListData)
        }
      })
    }
  }

  successFileRender = (file, fileIndex, showChecked, verticalListData) => {
    const { fileDownload, fileNameClick, ResultRowSelectCheckbox, fileDelete, channelType, displayMode, historyPairGroup } = this.props;
    return (
      <div key={file.id}>
        <Row key={file.id} className='sparameter-result-file-name' style={fileIndex > 0 && { marginTop: 10 }}>
          <Col span={24} onClick={() => fileNameClick(file.id)}
            className={showChecked ? 'sparameter-result-file-name-checked-title' : ''}>
            <div>
              {file.show ? <DownOutlined className="sparameter-file-expand-icon" /> : <RightOutlined className="sparameter-file-expand-icon" />}
              <span className='sparameter-span-file-name' title={file.rowName}>{file.rowName}</span>
              {fileDownload && <DownloadOutlined
                className="sparameter-file-download-icon"
                onClick={(e) => fileDownload(e, file)} />}
              {fileDelete && <CloseOutlined
                className="sparameter-file-delete-icon"
                onClick={(e) => fileDelete(e, file)} />}
            </div>
          </Col>
          {showChecked && ResultRowSelectCheckbox(verticalListData)}
        </Row>
        <Row
          key={fileIndex}
          style={{ display: file.show ? 'block' : 'none' }}
          className='sparameter-result-ports-list'
        >
          {/* fileId: fileId::signal::pair */}
          {channelType === CPHY && displayMode === 'Default'
            ? this.resultListAllCPHYRender(file.id, file.rowId, historyPairGroup[file.rowId])
            : this.getResultCheckboxGroup(file.rowId, file.id, verticalListData)}
        </Row>
      </div >
    );
  }

  unSuccessFileRender = (file, fileIndex, status) => {
    const { fileDownload, fileDelete } = this.props;
    const getIcon = (_status) => {
      switch (_status) {
        case VERIFY_RUNNING:
        case WAITING:
          return (
            <Tooltip title='The current file is being parsed, please wait.' overlayClassName='aurora-tooltip'>
              <LoadingOutlined className="sparameter-file-expand-icon" />
            </Tooltip>
          );
        case VERIFY_FAILED:
          return (
            <Tooltip title='Failed to parse the current file.' overlayClassName='aurora-tooltip'>
              <ExclamationCircleOutlined className="sparameter-file-expand-icon sparameter-file-error-icon" />
            </Tooltip>
          );

        default:
          return;
      }
    }
    return (
      <div key={file.id}>
        <Row key={file.id} className='sparameter-result-file-name' style={fileIndex > 0 && { marginTop: 10 }}>
          <Col span={24}>
            <div>
              {getIcon(status)}
              <span className='sparameter-span-file-name' title={file.rowName}>{file.rowName}</span>
              {fileDownload && <DownloadOutlined
                className="sparameter-file-download-icon"
                onClick={(e) => fileDownload(e, file)} />}
              {fileDelete && <CloseOutlined
                className="sparameter-file-delete-icon"
                onClick={(e) => fileDelete(e, file)} />}
            </div>
          </Col>
        </Row>
      </div >
    );
  }

  iconRender = () => {
    const { type } = this.props;
    const { importProgress } = this.state;
    switch (type) {
      case IMPORT:
        return (
          <Fragment>
            <UploadOutlined
              className={`sparameter-file-upload-button ${importProgress > -1 ? 'sparameter-file-icon-disabled' : ''}`}
              title='Upload file'
              onClick={(e) => importProgress > -1 ? this.stopPropagation(e) : this.uploadFile(e)} />
            {importProgress > -1 && <Progress
              size={{ height: 14 }}
              strokeColor={'#1890ff'}
              percent={importProgress}
              className="result-progress-bar"
            />}
          </Fragment>
        );
      case COMPARE:
        return (
          <Fragment>
            <PlusSquareOutlined
              className={`sparameter-file-upload-button`}
              title='Add tasks to compare'
              onClick={(e) => this.showCompareAdd(e, true)} />
          </Fragment>
        );
      case RESULT:
      case HISTORY:
      default:
        return;
    }
  }

  selectNewTaskRender = () => {
    const { compartList } = this.props;
    return (
      <Fragment>
        <Cascader
          size="small"
          className="sparameter-file-compare-cascader"
          options={compartList}
          fieldNames={{ label: 'name', value: 'id' }}
          onChange={this.selectNewTask}
          showSearch={true}
        />
        <CloseOutlined
          className="sparameter-file-delete-icon sparameter-cascader-close-icon"
          onClick={(e) => this.showCompareAdd(e, false)} />
      </Fragment>
    );
  }

  isPairGroupCheckAll = (fileId, list) => {
    const { portSelect } = this.props;
    let checked = true, indeterminate = false;
    for (const item of list) {
      if (portSelect[fileId] && portSelect[fileId][item.rowName]) {
        if (portSelect[fileId][item.rowName].length < item.children.length) {
          checked = false;
        }
        if (portSelect[fileId][item.rowName].length > 0) {
          indeterminate = true;
        }
      } else {
        checked = false;
      }
    }
    if (checked) indeterminate = false;
    return { checked, indeterminate };
  }

  resultListAllCPHYRender = (rowId, fileId, pairGroup) => {
    const { getCPHYDefaultResultList } = this.props;
    if (pairGroup && pairGroup.length) {
      return pairGroup.map((pair, fileIndex) => {
        const { list, show } = getCPHYDefaultResultList(pair.rowId);
        const { checked, indeterminate } = this.isPairGroupCheckAll(fileId, list);
        return (
          <div key={pair.rowId}>
            <Row key={pair.id} className='sparameter-result-file-name' style={fileIndex > 0 && { marginTop: 10 }}>
              <Col span={24} className='sparameter-result-file-name-checked-title'>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => this.props.pairGroupSelectRowAll(e, pair.rowId)}
                  checked={checked}
                  className='sparameter-channel-port-name-checkbox'
                ></Checkbox>
                <div onClick={() => this.props.pairGroupClick(pair.rowId)} className='sparameter-channel-display-row'>
                  {pair.show ? <DownOutlined className="sparameter-file-expand-icon" /> : <RightOutlined className="sparameter-file-expand-icon" />}
                  <span className='sparameter-span-file-name' title={pair.rowName}>{pair.rowName}</span>
                </div>
              </Col>
            </Row>
            <Row
              key={fileIndex}
              style={{ display: pair.show ? 'block' : 'none' }}
              className='sparameter-result-ports-list'
            >
              {this.getResultCheckboxGroup(rowId, fileId, [], list, show)}
            </Row>
          </div >
        );
      }
      );
    }
  }

  isMultiGroupCheckAll = (fileId, list) => {
    const { portSelect } = this.props;
    let checked = true, indeterminate = false;
    for (const item of list) {
      if (portSelect && portSelect[item.rowName]) {
        if (portSelect[item.rowName].length < item.children.length) {
          checked = false;
        }
        if (portSelect[item.rowName].length > 0) {
          indeterminate = true;
        }
      } else {
        checked = false;
      }
    }
    if (checked) indeterminate = false;
    return { checked, indeterminate };
  }

  resultListMultiRender = (rowId, fileId) => {
    const { list, show } = this.getList(rowId);

    if (list && list.length) {
      return <div className='sparameter-result-file-multi-content'>
        {list.map((pair, fileIndex) => {
          const { children, rowName } = pair;
          const { checked, indeterminate } = this.isMultiGroupCheckAll(rowId, children);
          return <div key={rowName}>
            <Row key={rowName} className='sparameter-result-file-name' style={fileIndex > 0 && { marginTop: 10 }}>
              <Col span={24} className='sparameter-result-file-name-checked-title'>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => this.props.changeMultiPort(e, rowName, children, rowId)}
                  checked={checked}
                  className='sparameter-channel-port-name-checkbox'
                ></Checkbox>
                <div onClick={() => this.props.multiRowNameClick(children, rowName, rowId)} className='sparameter-channel-display-row sparameter-channel-display-multi-row'>
                  {show.includes(rowName) ? <DownOutlined className="sparameter-file-expand-icon" /> : <RightOutlined className="sparameter-file-expand-icon" />}
                  <span title={rowName}>{rowName}</span>
                </div>
              </Col>
            </Row>
            <Row
              key={fileIndex}
              style={{ display: show.includes(rowName) ? 'block' : 'none' }}
              className='sparameter-result-ports-list'
            >
              {this.getResultCheckboxGroup(rowId, fileId, [], children, show)}
            </Row>
          </div >
        })}
      </div>
    }
  }

  render() {
    const { resultShow, importProgress, compareAdd } = this.state;
    const { multiFiles = false, title, type = RESULT, fileId, rowId, experimentList, ResultRowSelectCheckbox,
      verticallyData, isPostProcess, postProcessTitle, className = '', channelType, displayMode, pairGroup, isMulti, sweepTimeShowList } = this.props;
    const isParallel = experimentList && experimentList.length ? true : false;
    let verticalListData = [];
    if (isParallel && !multiFiles) {
      verticalListData = verticallyData.experimentListData;
    }
    return (
      <div className={`sparameter-channel-result-content ${className}`}>
        <div className={isParallel ? 'sparameter-channel-display-inline-flex' : ''}>
          <Divider
            orientation="left"
            className={isParallel ? 'sparameter-channel-result-margin sparameter-channel-result-vertically-divider'
              : isPostProcess ? `sparameter-channel-result-margin sparameter-channel-result-post-divider`
                : 'sparameter-channel-result-margin'
            }
            id={importProgress > -1 && type === IMPORT ? 'sparameter-import-divider' : null}
          >
            <div className='result-import-title' onClick={this.resultTitleClick}>
              {resultShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
              <span className='sparameter-result-menu-title'>{title || type}</span>
              {this.iconRender()}
            </div>
          </Divider>
          {isParallel && experimentList.map((item, index) =>
            <Fragment key={item.id}>
              <span
                className='sparameter-result-menu-title sparameter-sweep-checkbox'
                style={index === 0 ? { marginLeft: 240 } : {}}
              >
                <Tooltip title={item.name} overlayClassName='aurora-tooltip'>{item.name}</Tooltip>
                {(sweepTimeShowList || []).includes(item.name) ? <DoubleLeftOutlined
                  onClick={() => this.props.showSweepTime(item.name, false)}
                  className='sparameter-sweep-exp-title-icon'
                  title="Hide Rising and Falling"
                />
                  : <DoubleRightOutlined onClick={() => this.props.showSweepTime(item.name, true)} className='sparameter-sweep-exp-title-icon' title="Show Rising and Falling" />}
              </span>
              {isPostProcess && postProcessTitle && (sweepTimeShowList || []).includes(item.name) ? postProcessTitle('resultList', 'sweep') : null}
            </Fragment>
          )}
          {!isParallel && isPostProcess && postProcessTitle ? postProcessTitle('resultList') : null}
        </div>
        {isParallel && !multiFiles && ResultRowSelectCheckbox(verticallyData)}
        <div className='sparameter-result-file-content' style={{ display: resultShow ? 'block' : 'none' }}>
          {type === COMPARE ? this.getCompareFileResultList()
            : type === IMPORT ? this.getImportFileResultList()
              : type === RESULT && channelType === CPHY && displayMode === 'Default' ? this.resultListAllCPHYRender(rowId, fileId, pairGroup)
                : multiFiles ? this.getMultiFilesResultList()
                  : isMulti ? this.resultListMultiRender(rowId, fileId)
                    : this.getResultCheckboxGroup(rowId, fileId, verticalListData)}
          {compareAdd && this.selectNewTaskRender()}
        </div>
        <input
          type='file'
          ref={this.uploadSnpRef}
          style={{ display: 'none' }}
          multiple={true}
          onChange={(e) => this.onUploadSnpFile(e)}
        />
      </div >
    );
  };
}

export default ResultList;
export {
  RESULT,
  HISTORY,
  IMPORT,
  COMPARE,
  OPTIMIZATION,
  SWEEPMODEL
}