import React from 'react';
import { TreeSelect } from 'antd';

const { SHOW_PARENT } = TreeSelect;

class AuroraTreeSelect extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      popupVisible: true,
      comp_focus: true,
      selectedValues: []
    }
  }


  clickHandler = (e) => {
    let current = document.getElementsByClassName('aurora-table-tree-select')
    let selectOption = document.getElementsByClassName("aurora-table-tree-select-dropdown")

    const { target } = e;
    const targetInRef = (Select, target) => {
      for (let selectRef of Select) {
        if (selectRef.contains(target)) {
          return true;
        }
      }
      return false;
    }
    if (!(current && targetInRef(current, target))
      && !(selectOption && targetInRef(selectOption, target))) {
      this.setState({
        comp_focus: false
      })
    } else {
      this.setState({
        comp_focus: true
      })
    }
  }

  componentDidMount = () => {
    const { selectedValues } = this.props;
    this.setState({
      selectedValues,
      popupVisible: true
    })

    document.addEventListener('mousedown', this.clickHandler)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { save, } = this.props
    const { selectedValues } = this.state

    if (prevState.comp_focus && !this.state.comp_focus) {
      save({ values: selectedValues })
      this.setState({
        popupVisible: false
      })
    }
  }

  componentWillUnmount = () => {
    const { save } = this.props
    const { selectedValues, comp_focus } = this.state

    if (comp_focus) {
      save({ values: selectedValues })
    }

    document.removeEventListener('mousedown', this.clickHandler)
  }


  onTreeChange = (keys) => {
    this.setState({
      selectedValues: keys
    })
  }

  render() {
    const { popupVisible, selectedValues } = this.state;
    const { treeData, className, placeholder, dropdownStyle } = this.props;
    return (
      <div className='aurora-table-tree-select' >
        <TreeSelect
          ref={this.selectRef}
          treeData={treeData || []}
          value={selectedValues || []}
          onChange={(keys) => this.onTreeChange(keys)}
          treeCheckable={true}
          showCheckedStrategy={SHOW_PARENT}
          placeholder={placeholder}
          treeDefaultExpandAll={true}
          popupClassName="aurora-table-tree-select-dropdown aurora-select-dropdown"
          className={`${className} aurora-select aurora-tree-select`}
          autoFocus
          open={popupVisible}
          getPopupContainer={() => document.getElementById('root')}
          dropdownStyle={dropdownStyle}
        />
      </div>
    )
  }

}

export default AuroraTreeSelect;