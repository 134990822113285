import React, { Component, Fragment } from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Select } from "antd";
import { getConnectionIdsByPCBList, getMaxWidthByPCBName } from '@/services/helper/connectorHelper';
import ConnectionModel from './ConnectionModel';
import { SIERRA } from '@/constants/pageType';
import "./index.css";

const { Option } = Select;
class ConnectorSetup extends Component {

  componentDidMount = () => {
    this.updateConnection();
  }

  componentDidUpdate = (prevProps) => {
    const { connections, pcbConnections, endToEndChannelId, connectorSetupUpdate, CONNECTION_ID } = this.props;
    if ((!CONNECTION_ID && connections.length)
      || endToEndChannelId !== prevProps.endToEndChannelId
      || (connectorSetupUpdate && connectorSetupUpdate !== prevProps.connectorSetupUpdate)
      || (pcbConnections.length !== prevProps.pcbConnections.length)
    ) {
      this.updateConnection();
      connectorSetupUpdate && this.props._setupUpdateStatus(false, "connectorSetupUpdate");
    }
  }

  updateConnection = async () => {
    const { connections, CONNECTION_ID, pcbConnections, product } = this.props;
    const connection = connections.find(item => item.CONNECTION_ID === CONNECTION_ID);
    if (!connection) {
      const connectionIdList = product === SIERRA ? connections.map((item, index) => (index + 1).toString()) : getConnectionIdsByPCBList(pcbConnections);
      this.props.updateConnectionId(connectionIdList.length ? connectionIdList[0] : "")
    }
    this.connectionRef && await this.connectionRef.getConnection();
  }

  saveConnection = (connection, CONNECTION_ID) => {
    this.props._saveConnection(connection, { CONNECTION_ID });
  }

  deletePCBInConnector = (value, channelIndex) => {
    const { connections, CONNECTION_ID, changePCBInConnector } = this.props;
    const connIndex = connections.findIndex(conn => conn.CONNECTION_ID === CONNECTION_ID);
    if (connIndex > -1) {
      const connection = changePCBInConnector(connections[connIndex], value, channelIndex);
      this.props._saveConnection(connection, { hasChannel: true, CONNECTION_ID, update: false });
    }
  }

  openLeft = (connectionIdList) => {
    const { CONNECTION_ID } = this.props;
    const index = connectionIdList.findIndex(item => item === CONNECTION_ID);
    this.props.updateConnectionId(connectionIdList[index - 1]);
  }

  openRight = (connectionIdList) => {
    const { CONNECTION_ID } = this.props;
    const index = connectionIdList.findIndex(item => item === CONNECTION_ID);
    this.props.updateConnectionId(connectionIdList[index + 1]);
  }

  render() {
    const { pcbConnections = [], CONNECTION_ID, product, connections } = this.props;
    let firstID = "", lastID = "";
    const connectionIdList = product === SIERRA ? connections.map((item, index) => (index + 1).toString())
      : getConnectionIdsByPCBList(pcbConnections);
    const maxLength = connectionIdList.length;
    if (connectionIdList.length) {
      firstID = connectionIdList[0];
      lastID = connectionIdList[maxLength - 1];
    }
    const style = product === SIERRA ? { marginBottom: 0 } : {};
    return (
      <div className="aurora-setup-border">
        <span className='out-title-color font-bold'>Connector Setup</span>
        <div className="connection-v1-model-content">
          {maxLength > 1 && <div
            className='change-connection-v1-icon'
            style={firstID !== CONNECTION_ID ?
              { ...style, cursor: 'pointer' } : { ...style }}
            onClick={() => { firstID !== CONNECTION_ID && this.openLeft(connectionIdList) }}
          >
            <LeftOutlined style={firstID !== CONNECTION_ID ? { color: '#1890ff' } : {}} />
          </div>}
          <div className="connection-v1-model-list">
            {this.connectionList(connectionIdList, CONNECTION_ID, this.connectionTitleList)}
          </div>
          {maxLength > 1 && <div
            className='change-connection-v1-icon'
            style={lastID !== CONNECTION_ID ? { ...style, cursor: 'pointer' } : { ...style }}
            onClick={() => { lastID !== CONNECTION_ID && this.openRight(connectionIdList) }}
          >
            <RightOutlined style={lastID !== CONNECTION_ID ? { color: '#1890ff' } : {}} />
          </div>}
        </div>
        <div>{this.connectionList(connectionIdList, CONNECTION_ID, this.connectionModel)}</div>
      </div>
    );
  }

  connectionTitleList = (CONNECTION_ID) => {
    const { connections, pcbConnections, product } = this.props;
    const connection = connections.find(item => item.CONNECTION_ID === CONNECTION_ID);
    const prevPCBIndex = pcbConnections.findIndex(item => item.nextConnectionId === CONNECTION_ID);
    const nextPCBIndex = pcbConnections.findIndex(item => item.prevConnectionId === CONNECTION_ID);
    const prevPCBTitle = prevPCBIndex > -1 ? `PCB ${parseInt(prevPCBIndex) + 1}` : 'PCB',
      nextPCBTitle = nextPCBIndex > -1 ? `PCB ${parseInt(nextPCBIndex) + 1}` : 'PCB';

    if (!connection) {
      return;
    }
    const width = product === SIERRA ? getMaxWidthByPCBName(connection) + 50 : getMaxWidthByPCBName(connection);
    return <div className="connection-v1-channel-title">
      <div style={{ width }}>
        <div className="connection-v1-title-pcb">{prevPCBTitle}</div>
        <div className='connection-v1-name-content'>
          {this.pcbTitleList(connection.channel1, 1, connection.channel2, width)}
        </div>
      </div>
      <div className="connection-v1-border"></div>
      <div style={{ width }}>
        <div className="connection-v1-title-pcb">{nextPCBTitle}</div>
        <div className='connection-v1-name-content'>
          {this.pcbTitleList(connection.channel2, 2, connection.channel1, width)}
        </div>
      </div>
    </div>
  }

  pcbTitleList = (channel, channelIndex, anotherChannel, width) => {
    const { product, Interfaces } = this.props;
    if (product === SIERRA) {
      const list = Interfaces.map(i => ({ key: i.pcbId, label: i.pcb })).filter(i => i.key !== anotherChannel.designId);
      return <Select
        value={channel.designId && channel.designName ? { key: channel.designId, label: channel.designName } : undefined}
        style={{ width: width - 10 }}
        className={`aurora-select connection-v1-select`}
        placeholder={'PCB'}
        allowClear
        showSearch
        labelInValue={true}
        popupMatchSelectWidth={false}
        popupClassName="connector-select-dropdown-menu"
        getPopupContainer={() => document.getElementById('root')}
        onChange={option => { option && this.deletePCBInConnector({ label: option.label, key: option.value }, channelIndex) }}
      >
        {list.map(item => (
          <Option key={item.key} title={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
    } else {
      return <Fragment>
        <span className='connection-v1-pcb-name'>{channel.designName}</span>
        <span className='connection-v1-channel-name'>{channel.channelName}</span>
      </Fragment>
    }
  }

  connectionList = (connectionIdList, CONNECTION_ID, children) => {
    const currentIndex = connectionIdList.findIndex(item => item === CONNECTION_ID);
    return connectionIdList.map((item, index) => {
      const style = index < currentIndex ? { marginLeft: "-100%" } : { marginLeft: "100%" }
      return <div
        key={index}
        className="connection-v1-model-main"
        style={CONNECTION_ID === item ? {} : style}
      >
        {CONNECTION_ID === item ? children(CONNECTION_ID) : null}
      </div>
    })
  }

  onRef = (ref) => {
    this.connectionRef = ref;
  }

  connectionModel = (CONNECTION_ID) => {
    const { connections, _updateConnectionModelSetup, endToEndChannelId, pcbConnections,
      connectorFileList, cableFileList, channelConstructor, getTouchstoneParse, getSpiceParse,
      channelSetupInfo, product, Interfaces, judgeFileExsit, connectorCompList, serdesType
    } = this.props;
    const connection = connections.find(item => item.CONNECTION_ID === CONNECTION_ID);

    if (!connection) {
      return;
    }
    return <ConnectionModel
      onRef={this.onRef}
      endToEndChannelId={endToEndChannelId}
      connection={connection.connection}
      saveConnection={this.saveConnection}
      channel1={connection.channel1}
      channel2={connection.channel2}
      CONNECTION_ID={CONNECTION_ID}
      connectorFileList={connectorFileList}
      cableFileList={cableFileList}
      pcbConnections={pcbConnections}
      connections={connections}
      _updateConnectionModelSetup={_updateConnectionModelSetup}
      channelConstructor={channelConstructor}
      getTouchstoneParse={getTouchstoneParse}
      channelSetupInfo={channelSetupInfo}
      Interfaces={Interfaces}
      judgeFileExsit={judgeFileExsit}
      connectorCompList={connectorCompList}
      getSpiceParse={getSpiceParse}
      product={product}
      serdesType={serdesType}
    />
  }
}

export default ConnectorSetup;
