import React from 'react'
import ResultTopMenu from '@/components/ResultTopMenu';
import { FASTPI } from '../../../constants/pageType';

function getMenu(showDC) {
  const menu = [{
    name: 'Impedance',
    key: 'channel'
  },
  {
    name: 'Decap',
    key: 'decap'
  }]

  if (showDC) {
    menu.push({
      name: 'IR Drop',
      key: 'dc'
    })
  }
  return menu
}

function TopMenu({
  selectedKey,
  changeResultType,
  showDC,
  leftWidth,
  layoutLeftWidth }) {
  return (
    <ResultTopMenu
      selectedKey={selectedKey}
      changeResultType={changeResultType}
      menu={getMenu(showDC)}
      leftWidth={leftWidth}
      layoutLeftWidth={layoutLeftWidth}
      product={FASTPI}
    >
    </ResultTopMenu>
  )
}

export default TopMenu