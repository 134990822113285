import React, { Component, createRef } from 'react';
import Panel from '@/components/Panel';
import LayoutData from '@/services/data/LayoutData';
import { Table } from 'antd';
import './index.css';

const netColumns = [{
  title: 'Net',
  dataIndex: 'name',
  width: '30%',
  sorter: (a, b) => a.name.localeCompare(b.name),
  ellipsis: true
}, {
  title: 'Comps',
  dataIndex: 'comp',
  width: '20%',
  sorter: (a, b) => {
    return a.comp - b.comp;
  },
  ellipsis: true
}, {
  title: 'Pins',
  dataIndex: 'pin',
  width: '17%',
  sorter: (a, b) => {
    return a.pin - b.pin;
  },
  ellipsis: true
}, {
  title: 'Vias',
  dataIndex: 'via',
  width: '17%',
  sorter: (a, b) => {
    return a.via - b.via;
  },
  ellipsis: true
}, {
  title: 'Layers',
  dataIndex: 'layer',
  width: '16%',
  sorter: (a, b) => {
    return a.layer - b.layer;
  },
  ellipsis: true
}];

class NetTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      netNum: '',
      loading: true,
      scrollY: 396,
      columns: netColumns,
      NETS: [],
      height: 493
    };

    netColumns[0].render = (text) => {
      const { select } = this.props;
      const nets = [text];
      const canvasObj = { nets };
      return (
        <span className="nets-name" onClick={() => select(canvasObj)} title={text}>{text}</span>
      )
    }
    this.tableRef = createRef();
  }

  componentDidMount() {
    const { designID, maxHeight } = this.props;
    const _nets = LayoutData.getNetsList(designID);
    this.setState({
      loading: false,
      netNum: _nets.length,
      NETS: _nets,
    })

    const _height = _nets.length * 38 + 110;
    this.setState({
      height: _height > maxHeight ? maxHeight : _height
    });
  }

  componentDidUpdate(prevProps) {
    const { maxHeight } = this.props;
    if (maxHeight && maxHeight !== prevProps.maxHeight) {
      const { netNum } = this.state;
      const _height = netNum * 38 + 110;
      this.setState({
        height: _height > maxHeight ? maxHeight : _height
      });
    }
  }


  getPanelHeight = (height) => {
    this.setState({ height })
  }

  render() {
    const { defaultLeft, defaultTop, leftWidth, topWidth, maxWidth, designID } = this.props;
    const { netNum, loading, columns, NETS, height } = this.state;
    const scroll = { y: height - 80, x: maxWidth < 500 ? maxWidth - 5 : 495 };
    return (
      <Panel
        title={'Nets ' + (netNum && `(${netNum})`)}
        visible
        onCancel={() => this.props.closePanel(designID)}
        width={maxWidth < 500 ? maxWidth : '500px'}
        position='panel-top-left'
        className='menu-panel aurora-menu-panel-net'
        draggable
        left={leftWidth ? leftWidth : defaultLeft}
        top={topWidth ? topWidth : defaultTop}
        height={height < 200 ? 200 : height}
        defaultLeft={50}
        minWidth={100}
        minHeight={200}
        maxWidth={maxWidth}
        getPanelHeight={this.getPanelHeight.bind(this)}
        zIndex={10}
        overflow='hidden'
        redirectDom={`aurora-PCB-layout-content-${designID}`}
      >
        <Table
          rowKey='name'
          scroll={scroll}
          loading={loading}
          columns={columns}
          pagination={false}
          size="small"
          dataSource={NETS}
          bordered
          virtual
          ref={this.tableRef}
        />
      </Panel>
    )
  }
}

export default NetTable;