import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Select } from 'antd';
import { getPanelMaxWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import projectDesigns from '@/services/helper/projectDesigns';
import { LAYOUT } from '../../../constants/designType';
import { splitArrayToArrays } from '../../../services/helper/arrayHelper';
import { AC } from '../../../constants/treeConstants';

const { Option, OptGroup } = Select;
class SwitchPkgPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 800,
      maxHeight: 1000,
      pkgId: "",
      packageList: [],
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 800),
      maxHeight: getPanelMaxHeight(offset, 1000),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getLayoutList();
  }

  closeModal = () => {
    const { pkgId } = this.state;
    if (pkgId) {
      this.props.switchPackage(pkgId)
    }
    this.props.closeSwitchPanel(false)
  }

  pkgSelect = (pkgId) => {
    this.setState({
      pkgId
    })
  }

  getLayoutList = () => {
    const { projectId, designId } = this.props;
    const packageList = projectDesigns.getAvailablePackages(projectId);
    const preLayoutList = projectDesigns.getAvailablePreLayouts(projectId).filter(design => design && design.content && (!design.content.electric || design.content.electric === AC));
    const preLayoutRule = (item) => {
      return item.type === LAYOUT ? true : false;
    }
    const { trueArray: preLayoutPCBs, falseArray: preLayoutPackages } = splitArrayToArrays({ array: preLayoutList, rule: preLayoutRule })
    this.setState({
      packageList: [...packageList, ...preLayoutPackages],
      pkgId: designId
    })
  }

  timeResize = () => {
    setTimeout(() => {
      this.resize()
    }, 1000)
  }

  pkgSelectRender = () => {
    const { pkgId, packageList } = this.state;
    return (
      <div className="cascade-add-pcb-content">
        <span className="font-bold cascade-setup-title-color-only">Package</span>
        <Select
          value={pkgId}
          onChange={this.pkgSelect}
          getPopupContainer={(() => document.getElementById('root'))}
          showSearch={true}
          className="pcb-select"
          popupClassName="aurora-select-dropdown"
        >
          {packageList.map(option => {
            return <Option key={option.name} title={option.name} value={option.id}>{option.name}</Option>
          })}
        </Select>
      </div>
    );
  }

  render() {
    const { maxHeight, maxWidth } = this.state
    const content = (
      <Fragment>
        <Panel
          className='cascade-add-pcb-panel'
          title="Switch Package"
          onCancel={this.closeModal}
          zIndex={2000}
          width={500}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          position='panel-center'
          draggable
          minHeight={300}
          minWidth={200}
          defaultTop={100}
          resizeEnd={this.timeResize}
        >
          {this.pkgSelectRender()}
        </Panel>
        <div id="cascade-add-connection-dialog"></div>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
};

export default SwitchPkgPanel;
