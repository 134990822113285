import {
  CHANGE_VIEW, SAVE_OPEN_PROJECT_INFO, LIBRARY_MENU,
  PROJECT_MENU, CHANGE_LAYOUT, CLEAR_CURRENT_PROJECT,
  TRASH_MENU, CHANGE_VIEW_LIST, CHANGE_TREE_SELECTED_KEY,
  SAVE_HISTORY_PROMPT,
  DEBUG_DOWNLOAD_LOG_UPDATE,
  DOWNLOAD_DEBUG_MSG,
  DOWNLOAD_DEBUG_MSG_CLOSE,
  UPDATE_DOWNLOAD_MSG,
  UPDATE_PREPARE_PDNS,
  GET_DEFAULT_DECAP,
  UPDATE_PDN_REPORT_CONFIG,
  UPDATE_PDN_REPORT_PROGRESS,
  UPDATE_PDN_REPORT_MESSAGE,
  UPDATE_PDN_REPORT_VISIBLE,
  FORCE_PREVIEW_SHOW,
  UPDATE_PCB_LAYOUT,
  UPDATE_SIM_LOADING
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { PCB_ONLY } from '@/constants/layoutConstants';
import { defaultTreeItems } from '../../../../services/PDN/library/defaultTreeItem';

const defaultState = {
  view: null,
  currentProjectId: null,
  treeItems: defaultTreeItems,
  reportConfig: {
    channelIds: ['0'],
    templateId: '',
    language: "english",
    verificationSelect: '',
  },
  reportVisible: false,
  reportMessage: null,
  reportProgress: 0,
  verificationId: null,
  layout: 2,
  viewList: [],
  treeSelectedKeys: [],
  currentProjectPDNs: [],
  currentProjectDesigns: [],
  currentPDNS: [],
  preparingPDN: [],
  defaultDecap: '',
  forcePreviewShow: false,
  pcbLayout: PCB_ONLY,
  simulationLoading: false
}

export const PDNProjectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
        PDNID: action.PDNID || state.PDNID,
        verificationId: action.verificationId || state.verificationId,
      };
    case SAVE_OPEN_PROJECT_INFO:
      return {
        ...state,
        currentProjectId: action.id,
        currentProjectDesigns: action.designs,
        currentProjectPackages: action.packages,
        currentProjectPDNs: action.pdns,
        currentProjectName: action.name
      }
    case LIBRARY_MENU:
      return {
        ...state,
        treeItems: [...action.treeItems],
        SPIMNames: action.SPIMNames || state.SPIMNames,
        DecapNames: action.DecapNames || state.DecapNames,
        VRMNames: action.VRMNames || state.VRMNames
      }
    case PROJECT_MENU:
      return {
        ...state,
        treeItems: action.treeItems,
        projectList: action.projectList ? action.projectList : state.projectList
      }
    case CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      }
    case CLEAR_CURRENT_PROJECT:
      if (action.status) {
        return {
          ...state,
          currentProjectId: null,
          verificationId: null,
          PDNID: null,
          view: null,
          viewList: []
        }
      } else {
        return {
          ...state,
          verificationId: null,
          PDNID: null,
        }
      }
    case TRASH_MENU:
      return {
        ...state,
        treeItems: [...action.treeItems]
      }
    case CHANGE_VIEW_LIST:
      if (action.viewList && action.viewList.length === 0) {
        return {
          ...state,
          viewList: [...action.viewList],
          view: null
        }
      } else {
        return {
          ...state,
          viewList: [...action.viewList]
        }
      }
    case CHANGE_TREE_SELECTED_KEY:
      return {
        ...state,
        treeSelectedKeys: action.treeSelectedKeys
      }
    case USER_LOGOUT:
      return {
        ...defaultState,
        treeItems: defaultTreeItems
      }
    case DEBUG_DOWNLOAD_LOG_UPDATE:
      return {
        ...state,
        debugDownloadMsg: [...action.debugDownloadMsg],
        debugDownloadMsgShow: true
      }
    case DOWNLOAD_DEBUG_MSG:
      return {
        ...state,
        debugDownloadMsgShow: true
      }
    case DOWNLOAD_DEBUG_MSG_CLOSE:
      return {
        ...state,
        debugDownloadMsgShow: false,
        debugDownloadMsg: []
      }
    case UPDATE_DOWNLOAD_MSG:
      return {
        ...state,
        debugDownloadMsg: [...action.msg]
      }
    case UPDATE_PREPARE_PDNS:
      return {
        ...state,
        preparingPDN: [...action.preparingPDN]
      }
    case GET_DEFAULT_DECAP:
      return {
        ...state,
        defaultDecap: action.file
      }
    case UPDATE_PDN_REPORT_CONFIG:
      return {
        ...state,
        reportConfig: action.reportConfig
      }
    case UPDATE_PDN_REPORT_PROGRESS:
      return {
        ...state,
        reportProgress: action.progress
      }
    case UPDATE_PDN_REPORT_MESSAGE:
      return {
        ...state,
        reportMessage: action.message
      }
    case UPDATE_PDN_REPORT_VISIBLE:
      return {
        ...state,
        reportVisible: action.visible
      }
    case FORCE_PREVIEW_SHOW:
      return {
        ...state,
        forcePreviewShow: action.show
      }
    case UPDATE_PCB_LAYOUT:
      return {
        ...state,
        pcbLayout: action.pcbLayout
      }
    case UPDATE_SIM_LOADING:
      return {
        ...state,
        simulationLoading: action.loading
      }
    default: return state
  }
};