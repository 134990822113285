const TYPE = "BMA";

export const UPDATE_PAGE_TYPE = `${TYPE}/update_channel_content_type`;
export const GET_BMA_CHANNEL_CONTENT_INFO = `${TYPE}/get_channel_content`;
export const SAVE_BMA_CHANNEL_CONTENT_INFO = `${TYPE}/save_channel_content`;
export const UPDATE_CONTENT_LOADING = `${TYPE}_update_content_loading`;
export const UPDATE_CHANNEL_CONTENT = `${TYPE}_update_channel_content`;
export const SAVE_BMA_CHANNEL_CONTENT_SERVER = `${TYPE}_save_bma_channel_content_server`;

export const UPDATE_UPLOAD_FILE_LIST = `${TYPE}/update_Files_list`;
export const UPDATE_GET_FILES_STATUS = `${TYPE}_update_get_file_status`;
export const UPDATE_BMA_CONTENT_STATUS = `${TYPE}_update_bma_content_status`;
export const UPDATE_DATA_MENU_KEY = `${TYPE}_update_data_menu_key`;

export const CLEAR_ALL_BMA_INFO = `${TYPE}_clean_bma_info`;