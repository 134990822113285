import React, { PureComponent, Fragment } from 'react';
import { VERIFICATION, RESULT, PCB, EXPERIMENTS, EXPERIMENTS_RESULT, PCB_PRE_LAYOUT, MULTI_INTERFACE_SETUP } from '@/constants/treeConstants';
import PinToPinSetting from './PinToPinSetting';
import Result from '../result';
import TopBar from './PinToPinSetting/TopBar';
import { topBarResize } from '@/services/helper/topBarResize';
import Sweeping from './Sweeping';
import ContentLayoutLR from '@/components/ContentLayout/layoutLR';
import PCBLayout from './pcbLayout';
import { PCB_LEFT_RIGHT, LEFT_RIGHT_lAYOUT } from '../../../constants/layoutConstants';
import { connect } from 'react-redux';
import MultiInterfaceSetup from '../MultiInterfaceSetup';
import './content.css';


class LayoutLR extends PureComponent {

  getLeft = (width, bottomLinePosition) => {
    const { tabVisible, viewList, leftWidth } = this.props;
    const views = viewList.filter(item => ![PCB, PCB_PRE_LAYOUT].includes(item));
    switch (views[0]) {
      case RESULT:
      case EXPERIMENTS_RESULT:
        return <Fragment>
          <div
            id='sierra-content-main'
            className='my-sierra-content'
            style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, overflow: 'hidden' }}
          >
            <Result siderLeftWidth={leftWidth} layoutLeftWidth={width} viewList={views} />
          </div>
        </Fragment>
      case VERIFICATION:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <Fragment>
          <div
            id='sierra-content-main'
            className='my-sierra-content'
            style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, top: 45 }}
          >
            <PinToPinSetting />
          </div>
        </Fragment>
      case EXPERIMENTS:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <Fragment>
          <div
            id='sierra-content-main'
            className='my-sierra-content'
            style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, top: 45 }}
          >
            <Sweeping />
          </div>
        </Fragment>
      case MULTI_INTERFACE_SETUP:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='sierra-content-main'
          className='my-sierra-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, top: 45 }}
        ><MultiInterfaceSetup />
        </div>
      default: return null;
    }
  }

  topBarResize = () => {
    topBarResize('sierra-top-bar', this.props.getTopBarWidth());
    topBarResize('sierra-pre-layout-top-bar', this.props.getTopBarWidth());
  }

  getRight = (width) => {
    const { leftWidth } = this.props;
    return <PCBLayout siderWidth={leftWidth + width} topBarResize={this.topBarResize} />
  }

  topBar = (width) => {
    return <TopBar width={width} />
  }

  _getSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PCB_PRE_LAYOUT].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) {
      return 1;
    } else if (pcbs.length > 1 && pcbLayout === PCB_LEFT_RIGHT && layout === LEFT_RIGHT_lAYOUT) {
      return 3;
    } else {
      return 2;
    }
  }

  render() {
    const splitSize = this._getSplitSize();
    return (
      <ContentLayoutLR
        {...this.props}
        leftClassName={"sierra-content-left"}
        topBarResize={this.topBarResize}
        getLeft={this.getLeft}
        getRight={this.getRight}
        topBar={this.topBar}
        splitSize={splitSize}
        lineClassName='aurora-layout-split-screen'
      />
    )
  }
};

const mapState = (state) => {
  const { SierraReducer } = state;
  const { project: { layout, selectedKeys, pcbLayout } } = SierraReducer;
  return {
    layout,
    selectedKeys,
    pcbLayout
  };
}

export default connect(mapState, null)(LayoutLR);

