import { filterNetsByNetName, getSerdesType } from "../../PCBHelper";
import { CLK, RX, TX } from "../preLayout";
import { ChannelCPHYSignals, ChannelSignals } from "./IntegratedChannel";
import { findNetListByComp } from "../../helper/componentsHelper/componentData";
import { strFormatChange, strReplaceAll } from "../../helper/stringHelper";
import { getDefaultIndex } from "../../helper/setDefaultName";
import { CPHY, HDMI, NET_TYPES, PCIE } from "../../PCBHelper/constants";
import RelateNetsGroup from "../../PCBHelper/relateNets";
import LayoutData from "../../data/LayoutData";
import { PACKAGE } from "../../../constants/designType";
import { maxPrefix } from "../../helper/split";

const HDMI_TMDS = "TMDS", TMDS_CLK = "TMDS_CLK";

class PCBNets {
  constructor() {
    this.filterNets = [];
  }

  /**
 * get related nets and components by nets connected selected component
 * @param {Array} filterNets [] nets by connected selected component
 * @param {string} designId
 *  */
  filterNetsByCompAndNetName = ({ designId, pcbNetsList, compName, type, netNamePrefix, designType, updatedCMCComponents }) => {
    const layout = LayoutData.getLayout(designId);
    const symbol = layout.mSymbolMgr;
    const _filterNets = this.getFilterPCBNetsByNetName({ netsList: pcbNetsList, compName, type, netNamePrefix, symbol, designType, designId });
    //get related nets by filterNets
    const relateNetsGroups = new RelateNetsGroup();
    relateNetsGroups.setGroups({
      netList: _filterNets,
      designId,
      type,
      updatedCMCComponents
    })
    this.filterNets = relateNetsGroups.getGroups() || [];
  }

  /**
  * filter pcb nets by net name and serde type and comp name
  * @param {array} netsList pcb net list
  * @param {string} compName component name
  * @param {string} type serdes type
  */
  getFilterPCBNetsByNetName = ({ netsList, compName, type, netNamePrefix, symbol, designType, designId }) => {
    //removed power nets and other type nets by net name
    let filterNets = filterNetsByNetName(netsList, type, netNamePrefix, symbol, designType, designId);
    //find net names of connected component
    let compReg = null;
    if (!compName) {
      compReg = new RegExp("(BGA)|(BG)|(DIE)", "ig")
    }
    const compNetNames = findNetListByComp({ compName, netsList: filterNets, filterNets: [], type: "netName", compReg, designId });
    filterNets = !compNetNames.length ? filterNets : filterNets.filter(item => compNetNames.includes(item.mName));
    //find nets by serdes type
    //if serdes type nets exist, return _filterNets ,else return filterNets
    /*  const netReg = new RegExp(type, "ig");
     const _filterNets = filterNets.filter(item => item.mName.match(netReg)); */
    return filterNets;
  }

  getFilterNets = () => {
    return this.filterNets || [];
  }
}

class SignalsIdentification {
  constructor() {
    this.signals = [];
    this.P_Nets = [];//[net, pNets, allNets, rlcComponents, targetComponents ]
    this.N_Nets = [];
  }

  /**
   * filter nets P_Nets , N_Nets, by positive / negative flags
   *  */
  filterPositiveAndNegativeNets = ({
    nets,
    type,
    advancedConfig
  }) => {
    const { P_Nets, N_Nets } = identifyPositiveAndNegativeNets({
      nets,
      type,
      advancedConfig
    });
    this.P_Nets = P_Nets;
    this.N_Nets = N_Nets;
  }

  /**
 * get PCIE group signals and update components
 * @param {string} positiveFlags: positive flag list ["P", "+"]
 * @param {string} negativeFlags: negative flag list ["N", "-"]
 * @param {Array} netNamePrefix: ["PCIE"]
 *  */
  getSignalsInfo({
    advancedConfig,
    netNamePrefix
  }) {
    const positiveFlags = advancedConfig.positiveFlags,
      negativeFlags = advancedConfig.negativeFlags,
      txFlag = advancedConfig.txFlag,
      rxFlag = advancedConfig.rxFlag;
    let signals = [];
    //reg  pReg = new RegExp(`(P)|(\\+)`, 'ig');
    // nReg = new RegExp(`(N)|(\\-)`, 'ig');
    const pReg = arrChangeStrReg(positiveFlags),
      nReg = arrChangeStrReg(negativeFlags);
    let targetNets = this.P_Nets, pNetLength = true, netType = "p", allSignalSelectedNetNames = [];
    //if n_Nets length > pNets length, target nets is N_Nets
    if (this.P_Nets.length > this.N_Nets.length) {
      targetNets = this.N_Nets;
      pNetLength = false;
      netType = "n";
    }
    for (let i = 0; i < targetNets.length; i++) {
      const item = targetNets[i];
      //find number of P nets N123
      const c_nets = item[`${netType}Nets`];
      const _CNet = c_nets && c_nets[0] ? c_nets[0] : "";
      let find = null, netObj = { nets_P: [], nets_N: [] }, otherType = "N";
      if (pNetLength) {
        netObj.nets_P = [...item.allNets];
        netObj.PNets = [...item.pNets];
        //match pReg and nReg and replace net name to "N"
        const pNetStr = strReplaceAll(strReplaceAll(_CNet, pReg, "N"), nReg, "N");
        //match pReg and replace net name pReg and nReg to "N"
        //_CNet -> pNet
        //eg: pNet -> "PCIE_TXP0", nNet -> "PCIE_TXN0",
        // replace pNet -> pNetStr ="NCIE_TXN0" , nNet -> "NCIE_TXN0"
        //eg1: pNet -> "MT7921_PCIE_RX_P", nNet -> "MT7921_PCIE_RX_M"
        // replace pNet -> pNetStr ="NT7921_NCIE_RX_N" , nNet -> "NT7921_NCIE_RX_N"
        find = this.N_Nets.find(it => !!it.nNets.find(net => net !== _CNet && strReplaceAll(strReplaceAll(net, pReg, "N"), nReg, "N") === pNetStr));
        if (find && find.nNets) {
          //filter nNets includes
          netObj.nets_P = netObj.nets_P.filter(item => !find.nNets.includes(item))
        }
      } else {
        netObj.nets_N = [...item.allNets];
        netObj.NNets = [...item.nNets];
        //match pReg and nReg and replace net name to "P"
        const nNetStr = strReplaceAll(strReplaceAll(_CNet, nReg, "P"), pReg, "P");
        //match nReg and replace net name pReg and nReg to "P"
        //eg: nNet -> "PCIE_TXN0", pNet -> "PCIE_TXP0",
        // replace nNet -> nNetStr ="PCIE_TXP0" , pNet -> "PCIE_TXP0"
        //eg1: nNet -> "MT7921_PCIE_RX_P", pNet -> "MT7921_PCIE_RX_M"
        // replace nNet -> nNetStr ="PT7921_PCIE_RX_P" , pNet -> "PT7921_PCIE_RX_P"
        find = this.P_Nets.find(it => it.pNets.find(net => net !== _CNet && strReplaceAll(strReplaceAll(net, nReg, "P"), pReg, "P") === nNetStr));
        if (find && find.pNets) {
          //filter pNets includes
          netObj.nets_N = netObj.nets_N.filter(item => !find.pNets.includes(item))
        }
        otherType = "P";
      }

      netObj[`nets_${otherType}`] = find ? [...find.allNets] : [];
      netObj[`${otherType}Nets`] = find ? [...find[`${otherType.toLowerCase()}Nets`]] : [];

      //Filter exist signal nets
      netObj.nets_P = netObj.nets_P.filter(it => !allSignalSelectedNetNames.includes(it));
      netObj.nets_N = netObj.nets_N.filter(it => !allSignalSelectedNetNames.includes(it));

      if (!netObj.nets_P.length || !netObj.nets_N.length) {
        continue;
      }
      allSignalSelectedNetNames.push(...netObj.nets_P);
      allSignalSelectedNetNames.push(...netObj.nets_N);

      //Distinguish between PNet and NNet
      //Divide nets_P and nets_N again according to the p/n flag
      netObj = updatePAndNNets({
        netObj,
        pReg,
        nReg,
        positiveFlags,
        negativeFlags
      })

      const prefixRegList = netNamePrefix.map(it => new RegExp(`${strFormatChange(it)}`, "ig"));
      let samePrefixNets = !prefixRegList.length ? [...netObj.PNets, ...netObj.NNets] : [...netObj.PNets, ...netObj.NNets].filter(item => prefixRegList.filter(it => item.match(it)).length);
      if (!samePrefixNets.length) {
        continue;
      }

      let samePrefix = maxPrefix([...samePrefixNets]);
      if (!samePrefix) {
        samePrefixNets = [...samePrefixNets.filter(item => item.match(txFlag) || item.match(rxFlag))];
        samePrefix = maxPrefix([...samePrefixNets]);
      }

      let hdmiSamePrefix = "";
      if (advancedConfig.type === HDMI) {
        const hdmiSamePrefixNets = [...netObj.PNets, ...netObj.NNets].filter(item => item.match(HDMI_TMDS));
        hdmiSamePrefix = maxPrefix([...hdmiSamePrefixNets]);
      }

      signals.push({
        ...netObj,
        samePrefix,
        hdmiSamePrefix,
        cmcComponents: targetNets[i].cmcComponents
      })
    }
    this.signals = signals;
    return signals;
  }
}

class SignalsGroups {
  constructor() {
    this.groupsObj = {};
  }

  /**
   * Groups signals by net prefix
   *  */
  getGroupsBySignalPrefix({
    signals,
    advancedConfig
  }) {
    const _signals = JSON.parse(JSON.stringify(signals));
    let regObj = { CLKReg: "CLK" };
    const netNamePrefix = advancedConfig.netNamePrefix,
      TXFlag = advancedConfig.txFlag,
      RXFlag = advancedConfig.rxFlag,
      addCLK = advancedConfig.addCLK || advancedConfig.type !== PCIE;

    const _netNamePrefix = netNamePrefix.map(item => getSerdesType(strFormatChange(item)));
    const netPrefixRegList = _netNamePrefix.map(item => new RegExp(`^${item}`, "ig"));

    const netIncludesRegList = _netNamePrefix.map(item => new RegExp(item, "ig"));

    //tx / rx reg
    regObj.TXReg = TXFlag ? new RegExp(getSerdesType(strFormatChange(TXFlag)), "ig") : null
    regObj.RXReg = RXFlag ? new RegExp(getSerdesType(strFormatChange(RXFlag)), "ig") : null;

    //find prefix net name,if net name not exist, find include net name prefix
    let targetSignals = this.getFilterNetsByNetName({ _netNamePrefix, _signals });
    let { groupList, groupsObj } = this.getGroupList(advancedConfig.type, addCLK);
    for (let i = 0; i < targetSignals.length; i++) {
      const item = targetSignals[i];
      if (!item) {
        continue;
      }

      for (let group of groupList) {
        const allNets = [...item.PNets, ...item.NNets];

        if (group !== CLK && (
          item.samePrefix.match(regObj.CLKReg)
          || allNets.filter(it => it.match(regObj.CLKReg)).length
        )) {
          continue;
        }

        if (group !== HDMI_TMDS && item.samePrefix.match(regObj[`${group}Reg`])) {
          groupsObj[group].push({ ...item, groupNets: [...allNets], index: i });
          continue;
        }

        const findGroupNets = allNets.filter(it => it.match(regObj[`${group}Reg`]));
        //find net name prefix
        const findPrefixNets = findGroupNets.filter(it => netPrefixRegList.filter(reg => it.match(reg)).length);
        if (group !== HDMI_TMDS && findPrefixNets.length) {
          groupsObj[group].push({ ...item, groupNets: [...findPrefixNets], index: i, isNetPrefix: true });
          //delete net of include net prefix 
          const { groupItem, findSignal } = this.signalExistInGroup({ groupsObj, group, index: i });

          if (groupItem && !findSignal.isNetPrefix) {
            groupsObj[groupItem] = groupsObj[groupItem].filter(item => item.index !== i);
          }

          continue;
        }

        //if signal group already exists and net prefix is advanced net prefix, continue
        const { groupItem, findSignal } = this.signalExistInGroup({ groupsObj, group, index: i });

        if (groupItem && findSignal.isNetPrefix) {
          continue;
        }

        //find includes
        const findIncludesNets = findGroupNets.filter(it => netIncludesRegList.filter(reg => it.match(reg)).length);

        if (group !== HDMI_TMDS && findIncludesNets.length) {
          groupsObj[group].push({ ...item, groupNets: [...findIncludesNets], index: i });
          continue;
        }

        //find all
        if (group !== HDMI_TMDS && findGroupNets.length) {
          groupsObj[group].push({ ...item, groupNets: [...findGroupNets], index: i });
          continue;
        }

        if (advancedConfig.type !== HDMI || group !== HDMI_TMDS) {
          continue;
        }

        if (item.hdmiSamePrefix) {
          const findDataNets = allNets.filter(it => it.match(item.hdmiSamePrefix));
          groupsObj[group].push({ ...item, groupNets: [...findDataNets], index: i });
        }
      }
    }

    if (!groupsObj.TX || !groupsObj.TX.length || !groupsObj.RX || !groupsObj.RX.length) {
      //filter CLK Signal
      targetSignals = targetSignals.filter(item => !(item.samePrefix.match(regObj.CLKReg)
        || [...item.PNets, ...item.NNets].filter(it => it.match(regObj.CLKReg)).length));
      groupsObj = this.findNetGroupByNetName({ groupsObj, targetSignals })
    }
    if (groupsObj.CLK && groupsObj.CLK.length > 2) {
      //Filter error clk signal nets -> eg: [ "PCIE_RESET_N_EP","SM_CLK_EP", ... ]
      //The matching net name contains both PCI and CLK
      const _CLKNets = groupsObj.CLK.filter(item => [...item.PNets, ...item.NNets].filter(it => it.match(/^(?=.*PCI)(?=.*CLK).*$/ig)).length);
      groupsObj.CLK = _CLKNets.length >= 2 ? _CLKNets : groupsObj.CLKNets;
    }

    this.groupsObj = groupsObj;
  }

  signalExistInGroup = ({ groupsObj, group, index }) => {
    for (let groupItem in groupsObj) {
      if (groupItem === group) {
        continue;
      }
      const findSignal = groupsObj[groupItem].find(item => item.index === index);
      if (findSignal) {
        return { groupItem, findSignal };
      };
    }
    return { groupItem: null, findSignal: {} };
  }

  getGroupList = (type, addCLK) => {
    let groupList = [], groupsObj = {};
    if (type === HDMI) {
      groupList = [HDMI_TMDS, CLK];
    } else {
      groupList = addCLK ? [TX, RX, CLK] : [TX, RX];
    }

    for (let item of groupList) {
      groupsObj[item] = [];
    };
    return { groupList, groupsObj }
  }

  /**
   * filter nets by net name prefix
   *  */
  getFilterNetsByNetName({ _netNamePrefix, _signals }) {
    if (!_netNamePrefix.length) {
      return _signals;
    }
    //include
    const netIncludesRegList = _netNamePrefix.map(item => new RegExp(item, "ig"));
    let targetSignals = _signals;
    //filter net name by net name prefix
    targetSignals = targetSignals.filter(item => [item.samePrefix, ...item.PNets, ...item.NNets].filter(it => netIncludesRegList.filter(reg => it.match(reg)).length).length);
    return targetSignals;
  }

  /**
   * get Group name and Group signals
   *  */
  findNetGroupByNetName = ({ groupsObj, targetSignals }) => {
    let allSignalPrefix = maxPrefix([...targetSignals.map(item => item.samePrefix)], "all");
    let results = [];
    for (let i = 0; i < targetSignals.length; i++) {
      const curr = targetSignals[i];
      const next = targetSignals[i + 1];
      if (!next) {
        results = this.findSamePrefix(curr, results, allSignalPrefix);
        continue;
      }
      if (i === 0) {
        //find same prefix
        const samePrefix = maxPrefix([curr.samePrefix, next.samePrefix]);

        if (samePrefix && samePrefix !== allSignalPrefix) {
          results.push({
            samePrefix,
            list: [JSON.parse(JSON.stringify(curr)), JSON.parse(JSON.stringify(next))]
          });
        } else {
          results.push({
            samePrefix: curr.samePrefix || curr.nets_P[0] || curr.nets_N[0],
            list: [JSON.parse(JSON.stringify(curr))]
          }, {
            samePrefix: next.samePrefix || next.nets_P[0] || next.nets_N[0],
            list: [JSON.parse(JSON.stringify(next))]
          });
        }
        continue;
      }
      results = this.findSamePrefix(curr, results, allSignalPrefix);
    }

    if (results.filter(item => item.list.length === 1).length === results.length) {
      groupsObj[allSignalPrefix] = [...targetSignals];
    } else {
      results.forEach(item => {
        groupsObj[item.samePrefix] = [
          ...item.list.map(it => {
            return { ...it }
          })
        ]
      })
    }
    return groupsObj;
  }

  /**
  * find same prefix for curr net and other nets
  *  */
  findSamePrefix = (curr, results, allSignalPrefix) => {
    let index = -1;
    for (let i = 0; i < results.length; i++) {
      const item = results[i];
      const samePrefix = maxPrefix([curr.samePrefix, item.samePrefix]);
      if (samePrefix && samePrefix !== allSignalPrefix) {
        item.samePrefix = samePrefix;
        item.list.push(JSON.parse(JSON.stringify(curr)));
        index = i;
      }
    }
    if (index === -1) {
      results.push({
        samePrefix: curr.samePrefix || curr.nets_P[0] || curr.nets_N[0],
        list: [JSON.parse(JSON.stringify(curr))]
      })
    }
    return results;
  }

  getSignals = () => {
    let signals = [], allSignalSelectedNetNames = [], cmcComponents = [];

    for (let group in this.groupsObj) {
      const groupSignals = this.groupsObj[group] || [];

      for (let signal of groupSignals) {
        signal.cmcComponents && cmcComponents.push(...signal.cmcComponents);
        //Filter exist signal nets
        const signal_nets_P = signal.nets_P.filter(it => !allSignalSelectedNetNames.includes(it)),
          signal_nets_N = signal.nets_N.filter(it => !allSignalSelectedNetNames.includes(it));
        if (!signal_nets_P.length || !signal_nets_N.length) {
          continue;
        }
        allSignalSelectedNetNames.push(...signal.nets_P, ...signal.nets_N);
        signals.push(new ChannelSignals({
          name: "",
          group: group,
          nets_P: [...signal.nets_P],
          nets_N: [...signal.nets_N]
        }));
      }
    }


    let _cmcComponents = [];
    for (let item of cmcComponents) {
      const findComp = _cmcComponents.find(it => it.mCompName === item.mCompName);
      if (!findComp) {
        _cmcComponents.push({ ...item });
      }
    }
    return { signals, cmcComponents: _cmcComponents };
  }
}

/**
 * get signal net number list
 * if nets_P is not find numbers, find nets_N
 * @param {object} signal {name,PNets:[],nNets:[], nets_P:[], nets_N :[] }
 *  */
function getNetNameNumberList(signal, numIndex, group, groupNumIndex, serdesType) {
  // let pNets = signal.PNets.filter(item => item.match(/[0-9]*/ig));
  // let _numIndex = numIndex;
  // if (pNets.length > 1) {
  //   const _pNets = pNets.filter(item => item.match(group));
  //   pNets = _pNets.length > 0 ? _pNets : pNets;
  //   _numIndex = groupNumIndex;
  // }
  // let numbers = pNets.length ? pNets[0].match(/[0-9]*/ig).filter(it => !!it) : [];

  // if (!numbers || !numbers[numIndex] || numbers[numIndex].length > 2) {

  //   let nNets = signal.NNets.filter(item => item.match(/[0-9]*/ig));
  //   if (nNets.length > 1) {
  //     const _nNets = nNets.filter(item => item.match(group));
  //     nNets = _nNets.length > 0 ? _nNets : nNets;
  //     _numIndex = groupNumIndex;
  //   }
  //   numbers = nNets.length ? nNets[0].match(/[0-9]*/ig).filter(it => !!it) : [];
  // }
  let _numIndex = numIndex, numbers = [];
  const keys = serdesType === CPHY ? ['ANets', 'BNets', 'CNets'] : ['PNets', 'NNets'];
  for (const eleName of keys) {
    let nets = (signal[eleName] || []).filter(item => item.match(/[0-9]*/ig));
    if (nets.length > 1) {
      const _nets = nets.filter(item => item.match(group));
      nets = _nets.length > 0 ? _nets : nets;
      _numIndex = groupNumIndex;
    }

    numbers = nets.length ? nets[0].match(/[0-9]*/ig).filter(it => !!it) : [];
    if (numbers && numbers[numIndex] && numbers[numIndex].length < 2) {
      break;
    }
  }

  return { numbers, numIndex: _numIndex, groupNumIndex };
}

function arrChangeStrReg(arr, removePCIE) {
  let str = "";
  // Sort arr by length
  const _arr = arr.sort((a, b) => b.length - a.length);
  for (let item of _arr) {
    if (!item) {
      continue;
    }
    let itemStr = strFormatChange(item);
    if (removePCIE && itemStr.toUpperCase() === "P") {
      itemStr = `P([^(CIE)]|$)`;
    }
    str = str ? `${str}|(${itemStr})` : `(${itemStr})`
  }
  return new RegExp(str, 'ig');
}

/**
 * Get signal name by group num,and net name prefix
 *  */
function getSignalNameByGroup({ signalNamePrefix, type, num, signalNum, group, designType }) {
  const _signalNamePrefix = signalNamePrefix ? signalNamePrefix : type;
  const _group = _signalNamePrefix && _signalNamePrefix.match(group) ? "" : `_${group}`;

  if (type === HDMI && group !== CLK) {
    return _signalNamePrefix.includes(HDMI_TMDS) ? `${_signalNamePrefix}${num}${_group}` : `TMDS_DATA_${signalNum}`
  }

  switch (group) {
    case TX:
    case RX:
      //PCIE_LANE0_TX
      if (type === PCIE && designType !== PACKAGE) {
        return `${type}_LANE${signalNum}_${group}`;
      } else {
        return signalNum || typeof (signalNum) === "number" ? `${_signalNamePrefix}_${signalNum}${_group}` : `${_signalNamePrefix}${_group}`;
      }
    case CLK:
      //PCIE_REF_CLK
      if (type === PCIE) {
        return `${type}_REF_${CLK}`;
      } else if (type === HDMI) {
        return `${TMDS_CLK}${num}`
      }
      return num || typeof (num) === 'number' ? `${_signalNamePrefix}_${num}` : _signalNamePrefix;
    default:
      return num || typeof (num) === 'number' ? `${_signalNamePrefix}_${num}` : _signalNamePrefix;
  }
}

function getSignalName({ type, num, group, samePrefix, designType }) {
  return getSignalNameByGroup({
    type,
    num,
    group,
    signalNum: num,
    signalNamePrefix: samePrefix || group,
    designType
  });
}

/** 
 * Find all the numbers in the net name and return the array subscripts where the numbers are not equal
 * @param {Array} targetNets [ { net, pNets:[net1,net2], ...}, ...];
 * @param {string} netType "p"/ "n"
 * @eg targetNets: [ { pNets:["PCIEG3_LN0_TX"], ...},{ pNets:["PCIEG3_LN1_TX"], ... } ], return 1;
*/
function getLaneNumberIndex(targetNets, returnIndex) {
  //targetNets -> [ { pNets:["PCIEG3_LN0_TX"], ...},{ pNets:["PCIEG3_LN1_TX"], ... } ]
  let sameNumberList = []; //[ arr1, arr2, ...] -> arr1 -> net name find numbers[0] array
  for (let Net of targetNets) {
    //find number list of P targetNets
    let numberList = Net.match(/[0-9]*/ig); //["","","3","","0",""]
    //["3","0"], ["3","1"]
    numberList = numberList ? numberList.filter(it => !!it) : [];
    for (let index = 0; index < numberList.length; index++) {
      sameNumberList[index] ? sameNumberList[index].push(numberList[index]) : sameNumberList[index] = [numberList[index]];
    }
  }

  //sameNumberList -> [ ["3","3"] , ["0,"1"] ]
  let signalNumberIndexList = [];
  for (let index = 0; index < sameNumberList.length; index++) {
    const setNumberList = [...new Set(sameNumberList[index])];
    //sameNumberList[index] -> setNumberList: ["3","3"] -> ["3"], ["0","1"] -> ["0",1]
    signalNumberIndexList.push({ index: index, length: setNumberList.length })
  }

  const signalNumbers = signalNumberIndexList.sort((a, b) => Number(b.length) - Number(a.length))
  if (!returnIndex && targetNets.length > 1 && signalNumbers.length && signalNumbers[0].length === 1) {
    return "";
  }
  return signalNumbers && signalNumbers.length ? signalNumbers[0].index : "";
}

//Judge p net and n net according to net name
function updatePAndNNets({
  netObj,
  pReg,
  nReg,
  positiveFlags,
  negativeFlags }) {

  if (netObj.nets_P.length === 1 && netObj.nets_N.length === 1) {
    const p_nets = netObj.nets_P[0].split(""), n_nets = netObj.nets_N[0].split("");
    //Divide nets_P and nets_N again according to the p/n flag
    return matchNetType({ netObj, pReg, nReg, p_nets, n_nets, positiveFlags, negativeFlags });
  } else {
    let nets = [...netObj.nets_P], type = "p", anotherType = "n", reg = pReg, anotherReg = nReg;
    if (netObj.nets_N.length < netObj.nets_P.length) {
      nets = [...netObj.nets_N];
      type = "n";
      anotherType = "p";
      reg = nReg;
      anotherReg = pReg;
    }

    for (let net of nets) {
      //find the net with the same fields except P and N flags
      //match pReg and nReg and replace net name to "N"
      const netStr = strReplaceAll(strReplaceAll(net, reg, anotherType), anotherReg, anotherType);
      //PCIe_TX0_P -> netStr: NCIe_TX0_N -> PCIe_TX0_P
      const find = netObj[`nets_${anotherType.toUpperCase()}`].find(it => strReplaceAll(strReplaceAll(it, reg, anotherType), anotherReg, anotherType) === netStr);
      //PCIe_TX0_N -> netStr: NCIe_TX0_N -> PCIe_TX0_P
      if (find) {
        let p_nets = net.split(""), n_nets = find.split("");;
        if (type === "n") {
          p_nets = find.split("");
          n_nets = net.split("");
        }
        //Divide nets_P and nets_N again according to the p/n flag
        return matchNetType({ netObj, pReg, nReg, p_nets, n_nets, positiveFlags, negativeFlags });
      }
    }
  }
  return netObj;
}

function matchNetType({ netObj, nReg, pReg, p_nets, n_nets, positiveFlags, negativeFlags }) {
  let p_arr = [], n_arr = [];
  //find difference characters
  for (let i = 0; i < p_nets.length; i++) {
    const p_character = p_nets[i], n_character = n_nets[i] || null;

    if (p_character === n_character) {
      continue;
    }
    p_arr.push({ str: p_character, index: i });
    n_arr.push({ str: n_character, index: i });
  }

  if (!p_arr.length || !n_arr.length) {
    return netObj
  }

  if (p_arr.length === 1 && n_arr.length === 1) {
    //if difference is only one character
    /* TMDS_P, TMDS_N */
    if (p_arr[0].str.match(nReg) && n_arr[0].str.match(pReg)) {
      const PNets = [...netObj.PNets], nets_P = [...netObj.nets_P];
      netObj.PNets = [...netObj.NNets];
      netObj.nets_P = [...netObj.nets_N];

      netObj.NNets = [...PNets];
      netObj.nets_N = [...nets_P];
    }
    return netObj;
  } else if (positiveFlags.find(item => item.length > 1) || negativeFlags.find(item => item.length > 1)) {
    /* If flags contains multiple characters, eg: p_flags:["P+"],n_flags:["N-"] */
    const p_flags = positiveFlags.filter(item => item.length > 1),
      n_flags = negativeFlags.filter(item => item.length > 1);
    //Find out the flag of multiple characters and re-judgment net
    const p_net = p_nets.join(), n_net = n_nets.join();
    const p_Reg = arrChangeStrReg(p_flags),
      n_Reg = arrChangeStrReg(n_flags);
    if (n_net.match(p_Reg) && p_net.match(n_Reg)) {
      const PNets = [...netObj.PNets], nets_P = [...netObj.nets_P];
      netObj.PNets = [...netObj.NNets];
      netObj.nets_P = [...netObj.nets_N];

      netObj.NNets = [...PNets];
      netObj.nets_N = [...nets_P];
      return netObj;
    }
  }

  return netObj;
}

function getSignalSamePrefix(signals) {
  const samePrefixList = [...new Set(signals.map(item => item.samePrefix))];
  let prefixObj = {};
  for (let prefix of samePrefixList) {
    prefixObj[prefix] = signals.filter(item => item.samePrefix === prefix);
  }
  return prefixObj;
}

function setSignalName({ signals, type, designType, netNamePrefix }) {
  function getNets(netObj) {
    return [...(netObj.nets_P || []), ...(netObj.nets_N || []), ...(netObj.nets_A || []), ...(netObj.nets_B || []), ...(netObj.nets_C || [])];
  }
  const groups = [...new Set(signals.map(item => item.group))];
  let newSignals = [], signalNames = [];
  const prefixRegList = netNamePrefix.map(it => new RegExp(`${strFormatChange(it)}`, "ig"));
  for (let group of groups) {

    const groupSignals = signals.filter(item => item.group === group).map(item => {
      const nets = getNets(item);
      return {
        ...item,
        samePrefix: maxPrefix(nets.filter(item => !netNamePrefix.length || prefixRegList.filter(it => item.match(it)).length))
      }
    });
    //get all group nets maxPrefix
    const groupsNets = [...groupSignals.reduce((prev, curr) => { return [...prev, ...getNets(curr)] }, [])];
    let samePrefixGroupsNets = !netNamePrefix.length ? groupsNets : groupsNets.filter(item => prefixRegList.filter(it => item.match(it)).length);

    let allSignalPrefix = maxPrefix(groupSignals.map(item => item.samePrefix), "all");

    let numIndex = -1, signalNameType = "net", prefixObj = {};
    if (allSignalPrefix) {
      numIndex = getLaneNumberIndex(samePrefixGroupsNets.filter(item => !!item.match(allSignalPrefix)))
      signalNameType = 'samePrefix';
      prefixObj[allSignalPrefix] = groupSignals;
    } else {
      //group signals by samePrefix
      prefixObj = getSignalSamePrefix(groupSignals);
    }

    let numList = [], noNumberNetList = [];

    for (let signal of groupSignals) {
      let _numIndex = numIndex;

      const samePrefixSignals = prefixObj[signal.samePrefix] || [signal];
      const samePrefixNets = [...samePrefixSignals.reduce((prev, curr) => { return [...prev, ...getNets(curr)] }, [])];
      if (_numIndex === "" || _numIndex < 0) {
        const _samePrefixNets = !netNamePrefix.length ? samePrefixNets : samePrefixNets.filter(item => prefixRegList.filter(it => item.match(it)).length);

        _numIndex = getLaneNumberIndex(_samePrefixNets.filter(item => !!item.match(signal.samePrefix)), true)
        signalNameType = 'samePrefix';
        if (!signal.samePrefix) {
          signalNameType = "net"
        }
      }
      //get net name includes numbers
      const groupNumIndex = getLaneNumberIndex(samePrefixNets.filter(item => !!item.match(group)), true)
      const signalParam = type === CPHY ? { ANets: signal.nets_A, BNets: signal.nets_B, CNets: signal.nets_C } : { PNets: signal.nets_P, NNets: signal.nets_N }
      let { numbers, numIndex: targetIndex } = getNetNameNumberList(signalParam, _numIndex, group, groupNumIndex, type);
      targetIndex = targetIndex > -1 ? targetIndex : _numIndex;

      const prefixNumbers = signal.samePrefix.match(/[0-9]*/ig).filter(it => !!it);
      let prefixNum = prefixNumbers && prefixNumbers[_numIndex] ? prefixNumbers[_numIndex] : "";

      let signalName = "", num = "";
      if (([PCIE, HDMI].includes(type)) && designType !== PACKAGE) {
        num = numbers && numbers[targetIndex] ? numbers[targetIndex] : "";
        //filter clk signal name includes wrong number
        //HDMI21_TX_CLK
        num = group === CLK && num >= 10 ? "" : num;

      } else {
        num = prefixNum ? "" : numbers && numbers[targetIndex] ? numbers[targetIndex] : "";
      }

      if (!num && !prefixNum && samePrefixSignals.length > 0 && group !== CLK) {
        //if num not exist, Finally added to the signal list
        noNumberNetList.push({
          type,
          group,
          signal
        });
        continue;
      }
      //GET signal name
      signalName = getSignalName({
        type,
        num: num ? Number(num) : "",
        group,
        signalNameType,
        samePrefix: signal.samePrefix,
        designType
      });
      //Add signals
      while (signalNames.includes(signalName)) {
        num = Number(num) + 1;
        signalName = getSignalName({
          type,
          num: num,
          group,
          signalNameType,
          samePrefix: signal.samePrefix,
          designType
        });
      }

      num && numList.push(num.toString());
      //add signal
      if (type === CPHY) {
        newSignals.push(new ChannelCPHYSignals({
          name: signalName,
          group: group,
          nets_A: [...signal.nets_A],
          nets_B: [...signal.nets_B],
          nets_C: [...signal.nets_C]
        }));
      } else {
        newSignals.push(new ChannelSignals({
          name: signalName,
          group: group,
          nets_P: [...signal.nets_P],
          nets_N: [...signal.nets_N]
        }));
      }

      signalNames.push(signalName);
    }

    //add num not exist signal to signals
    for (let item of noNumberNetList) {
      const { type, group, signal } = item;
      const _groupSignals = newSignals.filter(item => item.group === group);
      let num = getDefaultIndex(_groupSignals.length, numList);
      let signalName = getSignalName({
        type,
        num: Number(num),
        group,
        signalNameType,
        samePrefix: signal.samePrefix,
        designType
      });
      //Add signals
      while (signalNames.includes(signalName)) {
        num = Number(num) + 1;
        signalName = getSignalName({
          type,
          num: num || "",
          group,
          signalNameType,
          samePrefix: signal.samePrefix,
          designType
        });
      }
      numList.push(num.toString());
      newSignals.push({ ...signal, name: signalName });
    }
  }

  return newSignals;
}

/**
* filter nets P_Nets , N_Nets, by positive / negative flags
*  */
function identifyPositiveAndNegativeNets({
  nets,
  type,
  advancedConfig
}) {
  let P_Nets = [], N_Nets = [];
  const positiveFlags = advancedConfig.positiveFlags,
    negativeFlags = advancedConfig.negativeFlags;
  //reg  pReg = new RegExp(`(P([^(CIE)]|$))|(\\+)`, 'ig');
  // nReg = new RegExp(`(N)|(\\-)`, 'ig');
  //true -> /(P([^(CIE)]|$))|(\\+)/ig , false => /(P)|(\\+)/ig
  const pReg = arrChangeStrReg(positiveFlags, true), nReg = arrChangeStrReg(negativeFlags, true);
  let filterSerdesTypesStr = "";
  //
  for (let item of NET_TYPES) {
    filterSerdesTypesStr = filterSerdesTypesStr ? `${filterSerdesTypesStr}|(${item})` : `(${item})`;
  }
  //filterSerdesTypesStr -> (HDMI)|(PCIE)|(DP)|(MIPI) ...
  const filterReg = new RegExp(filterSerdesTypesStr, 'ig');
  for (let item of nets) {
    //find P_nets by P reg //it.replace(filterReg, "") -> remove type in net eg: HDMIRX_CH0_M -> RX_CH0_M
    const pNets = getNetsByPNFlags({ allNets: item.allNets, filterReg, flagReg: pReg });
    //find N_nets by N reg
    const nNets = getNetsByPNFlags({ allNets: item.allNets, filterReg, flagReg: nReg });
    if (pNets.length) {
      P_Nets.push({ ...item, pNets });
    }
    if (nNets.length) {
      N_Nets.push({ ...item, nNets });
    }
  }
  return { P_Nets, N_Nets }
}
function getNetsByPNFlags({ allNets, filterReg, flagReg }) {
  return allNets.filter(it => {
    const filterType = it.match(filterReg);
    if (filterType && filterType.length > 1) {
      const _filterStrList = filterType.filter(_it => !_it.match(flagReg));
      let _filterStr = "";
      for (let _item of _filterStrList) {
        _filterStr = _filterStr ? `${_filterStr}|(${_item})` : `(${_item})`;
      }
      const _filterReg = !_filterStr ? filterReg : new RegExp(_filterStr, 'ig');
      return it.replace(_filterReg, "").match(flagReg);
    }
    return it.replace(filterReg, "").match(flagReg);
  });
}

class SignalsCPHYIdentification {
  constructor() {
    this.signals = [];
    this.A_Nets = [];
    this.B_Nets = [];
    this.C_Nets = [];
  }

  /**
   * Filter out three sets of data ABC
   * @param {*} param0 
   */
  filterDifferentLineNets({ nets, type, advancedConfig }) {
    let A_Nets = [], B_Nets = [], C_Nets = [];
    const { ALineFlags, BLineFlags, CLineFlags } = advancedConfig;

    const aReg = arrChangeStrReg(ALineFlags, true), bReg = arrChangeStrReg(BLineFlags, true), cReg = arrChangeStrReg(CLineFlags, true);

    let filterSerdesTypesStr = "";
    for (let item of NET_TYPES) {
      filterSerdesTypesStr = filterSerdesTypesStr ? `${filterSerdesTypesStr}|(${item})` : `(${item})`;
    }

    const filterReg = new RegExp(filterSerdesTypesStr, 'ig');
    for (let item of nets) {
      const aNets = getNetsByPNFlags({ allNets: item.allNets, filterReg, flagReg: aReg });
      const bNets = getNetsByPNFlags({ allNets: item.allNets, filterReg, flagReg: bReg });
      const cNets = getNetsByPNFlags({ allNets: item.allNets, filterReg, flagReg: cReg });
      if (aNets.length) {
        A_Nets.push({ ...item, aNets });
      }
      if (bNets.length) {
        B_Nets.push({ ...item, bNets });
      }
      if (cNets.length) {
        C_Nets.push({ ...item, cNets });
      }
    }

    this.A_Nets = A_Nets;
    this.B_Nets = B_Nets;
    this.C_Nets = C_Nets;
  }

  getSignalsInfo({ advancedConfig, netNamePrefix }) {
    const { ALineFlags, BLineFlags, CLineFlags } = advancedConfig;
    let signals = [];

    const aReg = arrChangeStrReg(ALineFlags);
    const bReg = arrChangeStrReg(BLineFlags);
    const cReg = arrChangeStrReg(CLineFlags);
    let targetNets = this.A_Nets, netType = 'a', allSignalSelectedNetNames = [];
    const minLength = Math.min(this.A_Nets.length, this.B_Nets.length, this.C_Nets.length);

    if (minLength === this.B_Nets.length) {
      targetNets = this.B_Nets;
      netType = 'b';
    } else if (minLength === this.C_Nets.length) {
      targetNets = this.C_Nets;
      netType = 'c';
    }

    for (const item of targetNets) {
      const matchNets = item[`${netType}Nets`];
      const matchNet = matchNets && matchNets[0] ? matchNets[0] : "";
      let find1 = null, find2 = null, netObj = { nets_A: [], nets_B: [], nets_C: [] }, otherType1 = "B", otherType2 = "C";
      if (netType === 'a') {
        netObj.nets_A = [...item.allNets];
        netObj.ANets = [...item.aNets];

        const bToANetStr = strReplaceAll(strReplaceAll(matchNet, aReg, "B"), bReg, "B");
        find1 = this.B_Nets.find(it => !!it.bNets.find(net => net !== matchNet && strReplaceAll(strReplaceAll(net, aReg, "B"), bReg, "B") === bToANetStr));
        if (find1 && find1.bNets) {
          netObj.nets_A = netObj.nets_A.filter(item => !find1.bNets.includes(item));
        }
        const cToANetStr = strReplaceAll(strReplaceAll(matchNet, aReg, "C"), cReg, "C");
        find2 = this.C_Nets.find(it => !!it.cNets.find(net => net !== matchNet && strReplaceAll(strReplaceAll(net, aReg, "C"), cReg, "C") === cToANetStr));
        if (find2 && find2.cNets) {
          netObj.nets_A = netObj.nets_A.filter(item => !find2.cNets.includes(item));
        }
      } else if (netType === 'b') {
        netObj.nets_B = [...item.allNets];
        netObj.BNets = [...item.bNets];

        const aToBNetStr = strReplaceAll(strReplaceAll(matchNet, bReg, "A"), aReg, "A");
        find1 = this.A_Nets.find(it => !!it.aNets.find(net => net !== matchNet && strReplaceAll(strReplaceAll(net, bReg, "A"), aReg, "A") === aToBNetStr));
        if (find1 && find1.aNets) {
          netObj.nets_B = netObj.nets_B.filter(item => !find1.aNets.includes(item));
        }
        const cToBNetStr = strReplaceAll(strReplaceAll(matchNet, bReg, "C"), cReg, "C");
        find2 = this.C_Nets.find(it => !!it.cNets.find(net => net !== matchNet && strReplaceAll(strReplaceAll(net, bReg, "C"), cReg, "C") === cToBNetStr));
        if (find2 && find2.cNets) {
          netObj.nets_B = netObj.nets_B.filter(item => !find2.cNets.includes(item));
        }
        otherType1 = "A";
      } else if (netType === 'c') {

        netObj.nets_C = [...item.allNets];
        netObj.CNets = [...item.cNets];

        const aToCNetStr = strReplaceAll(strReplaceAll(matchNet, cReg, "A"), aReg, "A");
        find1 = this.A_Nets.find(it => !!it.aNets.find(net => net !== matchNet && strReplaceAll(strReplaceAll(net, cReg, "A"), aReg, "A") === aToCNetStr));
        if (find1 && find1.aNets) {
          netObj.nets_C = netObj.nets_C.filter(item => !find1.aNets.includes(item));
        }
        const bToCNetStr = strReplaceAll(strReplaceAll(matchNet, cReg, "B"), bReg, "B");
        find2 = this.B_Nets.find(it => !!it.bNets.find(net => net !== matchNet && strReplaceAll(strReplaceAll(net, cReg, "B"), bReg, "B") === bToCNetStr));
        if (find2 && find2.bNets) {
          netObj.nets_C = netObj.nets_C.filter(item => !find2.bNets.includes(item));
        }
        otherType1 = "A";
        otherType2 = "B";
      }

      netObj[`nets_${otherType1}`] = find1 ? [...find1.allNets] : [];
      netObj[`${otherType1}Nets`] = find1 ? [...find1[`${otherType1.toLowerCase()}Nets`]] : [];

      netObj[`nets_${otherType2}`] = find2 ? [...find2.allNets] : [];
      netObj[`${otherType2}Nets`] = find2 ? [...find2[`${otherType2.toLowerCase()}Nets`]] : [];

      netObj.nets_A = netObj.nets_A.filter(item => !allSignalSelectedNetNames.includes(item));
      netObj.nets_B = netObj.nets_B.filter(item => !allSignalSelectedNetNames.includes(item));
      netObj.nets_C = netObj.nets_C.filter(item => !allSignalSelectedNetNames.includes(item));

      if (!netObj.nets_A.length || !netObj.nets_B.length || !netObj.nets_C.length) {
        continue;
      }

      allSignalSelectedNetNames.push(...netObj.nets_A);
      allSignalSelectedNetNames.push(...netObj.nets_B);
      allSignalSelectedNetNames.push(...netObj.nets_C);

      // netObj = updateABCNets({
      //   netObj,
      //   aReg,
      //   bReg,
      //   cReg,
      //   ALineFlags,
      //   BLineFlags,
      //   CLineFlags
      // })

      // generate same prefix
      const prefixRegList = netNamePrefix.map(it => new RegExp(`${strFormatChange(it)}`, "ig"));
      let samePrefixNets = !prefixRegList.length ? [...netObj.ANets, ...netObj.BNets, ...netObj.CNets] : [...netObj.ANets, ...netObj.BNets, ...netObj.CNets].filter(item => prefixRegList.filter(it => item.match(it)).length);
      if (!samePrefixNets.length) {
        continue;
      }

      let samePrefix = maxPrefix([...samePrefixNets]);

      signals.push({
        ...netObj,
        samePrefix,
        cmcComponents: item.cmcComponents
      })
    }

    this.signals = signals;
    return signals;
  }

  getSignals() {
    let signals = [], allSignalSelectedNetNames = [], cmcComponents = [];
    for (let signal of this.signals) {
      signal.cmcComponents && cmcComponents.push(...signal.cmcComponents);
      //Filter exist signal nets
      const signal_nets_A = signal.nets_A.filter(it => !allSignalSelectedNetNames.includes(it)),
        signal_nets_B = signal.nets_B.filter(it => !allSignalSelectedNetNames.includes(it)),
        signal_nets_C = signal.nets_C.filter(it => !allSignalSelectedNetNames.includes(it));
      if (!signal_nets_A.length || !signal_nets_B.length || !signal_nets_C.length) {
        continue;
      }
      allSignalSelectedNetNames.push(...signal.nets_A, ...signal.nets_B, ...signal.nets_C);
      signals.push(new ChannelCPHYSignals({
        name: signal.samePrefix,
        group: "",
        nets_A: [...signal.nets_A],
        nets_B: [...signal.nets_B],
        nets_C: [...signal.nets_C]
      }));
    }

    let _cmcComponents = [];
    for (let item of cmcComponents) {
      const findComp = _cmcComponents.find(it => it.mCompName === item.mCompName);
      if (!findComp) {
        _cmcComponents.push({ ...item });
      }
    }

    return { signals, cmcComponents }
  }
}

function updateABCNets({ netObj, aReg, bReg, cReg, ALineFlags, BLineFlags, CLineFlags }) {
  if (netObj.nets_A.length === 1 && netObj.nets_B.length === 1 && netObj.nets_C.length === 1) {
    const a_nets = netObj.nets_A[0].split(""),
      b_nets = netObj.nets_B[0].split(""),
      c_nets = netObj.nets_B[0].split("")
    //Divide nets_P and nets_N again according to the p/n flag
    return matchABCNetType({ netObj, aReg, bReg, cReg, a_nets, b_nets, c_nets, ALineFlags, BLineFlags, CLineFlags });
  }
}

function matchABCNetType({ netObj, aReg, bReg, cReg, a_nets, b_nets, c_nets, ALineFlags, BLineFlags, CLineFlags }) {
  let a_arr = [], b_arr = [], c_arr = [];
  for (let i = 0; i < a_nets.length; i++) {
    const a_char = a_nets[i], b_char = b_nets[i], c_char = c_nets[i];
    if (a_char === b_char || a_char === c_char || b_char === c_char) {
      continue;
    }
    a_arr.push({ str: a_char, index: i });
    b_arr.push({ str: b_char, index: i });
    c_arr.push({ str: c_char, index: i });
  }

  // 
  if (!a_arr.length || !b_arr.length || !c_arr.length) {
    return netObj;
  }

  // 
  if (a_arr.length === 1 && b_arr.length === 1 && c_arr.length === 1) {
    //if difference is only one character
    let ANets = [...netObj.ANets], BNets = [...netObj.BNets], CNets = [...netObj.CNets],
      nets_A = [...netObj.nets_A], nets_B = [...netObj.nets_B], nets_C = [...netObj.nets_C],
      aToType = 'a', bToTyoe = 'b', cToType = 'c';

    if (b_arr[0].str.match(aReg)) {
      aToType = 'b';
      ANets = [...netObj.BNets];
      nets_A = [...netObj.nets_B];
    } else if (c_arr[0].str.match(aReg)) {
      aToType = 'c';
      ANets = [...netObj.CNets];
      nets_B = [...netObj.nets_C];
    }

    if (a_arr[0].str.match(bReg)) {
      bToTyoe = 'a';
      BNets = [...netObj.ANets];
      nets_B = [...netObj.nets_A];
    } else if (c_arr[0].str.match(bReg)) {
      bToTyoe = 'c';
      BNets = [...netObj.CNets];
      nets_B = [...netObj.nets_C];
    }

    if (a_arr[0].str.match(cReg)) {
      cToType = 'a';
      CNets = [...netObj.ANets];
      nets_C = [...netObj.nets_A];
    } else if (b_arr[0].str.match(cReg)) {
      cToType = 'b';
      CNets = [...netObj.BNets];
      nets_C = [...netObj.nets_B];
    }

    if (['a', 'b', 'c'].filter(item => ![aToType, bToTyoe, cToType].includes(item)).length === 0) {
      return {
        ...netObj,
        ANets,
        BNets,
        CNets,
        nets_A,
        nets_B,
        nets_C
      }
    }
    return netObj;
  }
}

const SignalElementType = {
  [CPHY]: ['nets_A', 'nets_B', 'nets_C'],
  default: ['nets_N', 'nets_P']
}

function getSignalElementType(type) {
  switch (type) {
    case CPHY:
      return SignalElementType.CPHY;
    default:
      return SignalElementType.default;
  }
}

export {
  SignalsIdentification,
  SignalsCPHYIdentification,
  PCBNets,
  SignalsGroups,
  setSignalName,
  identifyPositiveAndNegativeNets,
  getSignalElementType
}