import { getCascadePreLayout } from "./prelayoutCtrl";

class PreLayoutData {
  constructor(props) {
    this.store = new Map();
  }

  getPreLayout = async (designId) => {
    let prelayout = this.store.get(designId);
    if (!prelayout) {
      prelayout = await getCascadePreLayout(designId);
      this.savePreLayout(designId, prelayout);
    }
    return prelayout;
  }

  getLocalPreLayout = (designId) => {
    return this.store.get(designId)
  }

  savePreLayout = (designId, data) => {
    this.store.set(designId, data)
  }
}

const preLayoutData = new PreLayoutData();

export default preLayoutData;