import { select,event } from 'd3-selection';

class MarkNode {

  constructor({ hashId, prevX, ym, yValue, color, x, targetX, targetY, getYValue, getXValue }) {
    this.hashId = hashId;
    this.prevX = prevX;
    this.id = `node-${hashId}-${prevX}`;
    this.ym = ym;
    this.yValue = yValue;
    this.color = color;
    this.x = x;
    this.targetX = targetX;
    this.targetY = targetY;
    this.root = null;
    this.getYValue = getYValue;
    this.getXValue = getXValue
  }

  drawNode(root, removeNode) {
    this.root = root.append('g')
      .attr('id', 'curve-mark-node-' + this.id);
    this.root.append('circle')
      .attr('cx', this.x)
      .attr('cy', this.ym)
      .attr('r', 2)
      .attr('fill', '#ff0000')
      .attr('stroke', '#ff0000')
      .on('click', function () {
        event && event.stopPropagation();
      })

    const db = this.root.append('g')
      .attr('class', 'mark-node-box')
      .attr('x', this.x)
      .attr('y', this.ym)
      .on('click', function () {
        event && event.stopPropagation();
      })
      .on('mouseenter', () => this.drawNodeNameBox(removeNode))

    //add rect
    db.append('rect')
      .attr('class', 'curve-mark-rect')
      .attr('x', this.x)
      .attr('y', this.ym)
      .attr('fill', '#e9e9e900')
      .style('width', '30px')
      .on('click', function () {
        event && event.stopPropagation();
      });

    db.append('text')
      .text(this.yValue)
      .attr('x', this.x + 2)
      .attr('y', this.ym + 14)
      .attr('stroke-width', 1)
      .style('font-size', '11px')
      .style('font-weight', 100)
      .attr('stroke', this.color)
      .on('click', function () {
        event && event.stopPropagation();
      });

  }

  drawNodeNameBox(removeNode) {
    //Move the mouse to change curve mark rect width
    let hoverBg = this.root.select('.mark-node-box').append('g')
      .attr('id', `hover-${this.id}`)
      .attr('x', this.x)
      .attr('y', this.ym)
      .on('click', function () {
        event && event.stopPropagation();
      })
      .on('mouseleave', () => {
        select(`#hover-${this.id}`).remove();
      });

    hoverBg.append('rect')
      .attr('class', 'curve-mark-hover-rect')
      .attr('id', `hover-${this.id}`)
      .attr('x', this.x)
      .attr('y', this.ym)
      .attr('fill', '#ffffff')
      .style('width', this.yValue.length <= 5 ? '48px' : '60px')
      .on('click', function () {
        event && event.stopPropagation();
      })

    hoverBg.append('text')
      .text(`${this.yValue}`)
      .attr('class', 'mark-node-text')
      .attr('x', this.x + 2)
      .attr('y', this.ym + 14)
      .attr('stroke-width', 1)
      .style('font-size', '11px')
      .style('font-weight', 100)
      .attr('stroke', this.color)
      .on('click', function () {
        event && event.stopPropagation();
      });

    //curve mark close icon
    hoverBg.append('text')
      .text(`X`)
      .attr('class', 'curve-box-close')
      .attr('x', this.getNodeRemoveXPosition())
      .attr('y', this.ym + 14)
      .on('click', () => {
        //remove current click mark
        removeNode(this.hashId);
        event && event.stopPropagation();
      });
  }

  deleteNode() {
    this.root.remove();
  }

  getNodeRemoveXPosition() {
    let textX = this.x + 35;
    if (this.yValue.length === 6) {
      textX = this.x + 39;
    } else if (this.yValue.length > 6) {
      textX = this.x + 43;
    }
    return textX;
  }

  redrawNode = ({ padding }) => {
    const x = this.getXValue(this.targetX) + padding.left;
    const y = this.getYValue(this.targetY) + padding.top;
    this.x = x;
    this.ym = y;
    this.draw();
  }

  draw() {
    this.root.select('circle')
      .attr('cx', this.x)
      .attr('cy', this.ym)

    const markBox = this.root.select('.mark-node-box')
      .attr('x', this.x)
      .attr('y', this.ym)

    markBox.select('rect')
      .attr('x', this.x)
      .attr('y', this.ym)

    markBox.select('text')
      .attr('x', this.x + 2)
      .attr('y', this.ym + 14)
  }
}

export default MarkNode;