import { getReportTemplate } from '@/services/Template';
import { getThumbnail, getAuroraThumbnail } from '@/services/api/template'
import unzipFile from '@/services/helper/unzip';
import errorCatch from '@/services/helper/errorCatch';
import userData from '@/services/helper/data/userData';

const defaultTemplate = 'Aurora_template.pptx';
class TemplateData {
  constructor() {
    this.userId = userData.getUserId();
    this.templateList = [];
    this.reportType = "";
  }

  updateUserId(id) {
    this.userId = id;
  }

  updateReportType(type) {
    this.reportType = type;
  }

  isSameUser(type) {
    const userId = userData.getUserId();
    if (userId === this.userId && type === this.reportType) {
      return true;
    } else {
      this.updateUserId(userId);
      this.updateReportType(type)
      return false;
    }
  }

  ifGetTemplate(update, type) {
    return update || !this.templateList.length || !this.isSameUser(type)
  }

  getListPromise(type, update = false) {
    return new Promise((resolve, reject) => {
      if (this.ifGetTemplate(update, type)) {
        getReportTemplate(type).then(list => {
          this.getThumbnail(list, type).then(res => {
            this.templateList = res;
            resolve(res);
          }, error => {
            resolve([]);
          })
        }, error => {
          resolve([]);
        })
      } else {
        resolve(this.templateList);
      }
    })
  }

  async getThumbnail(list, type) {
    let thumbnailList = [];
    let templateInfo = {};
    let [err, auroraRes] = await errorCatch(getAuroraThumbnail(type));
    if (!err) {
      templateInfo = list.find(item => item.name === defaultTemplate);
      if (templateInfo) {
        templateInfo['thumbnail'] = this.getPic(auroraRes.data);
        thumbnailList.push(templateInfo);
      }
    }

    let [error, res] = await errorCatch(getThumbnail());
    if (!error) {
      let img = await unzipFile(res, 'arraybuffer');
      const _list = list.filter(item => item.name !== defaultTemplate);
      for (let templateInfo of _list) {
        const key = `${templateInfo.id}.png`;
        let src = img[key] ? this.getPic(img[key]) : undefined;
        thumbnailList.push({ ...templateInfo, thumbnail: src });
      }
    }
    return thumbnailList;
  }

  getPic(arraybuffer) {
    return 'data:image/png;base64,' + btoa(
      new Uint8Array(arraybuffer)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    )
  }

  cleanList() {
    this.templateList = [];
    this.userId = null;
  }
}

const _TemplateData = new TemplateData();
export default _TemplateData;