import axios from 'axios';
import http from '../http';

/**
 * Get Sierra user library list
 * 2019/11/12
 * @export
 * @returns
 */
export function getSierraLibraryList() {
  return http.get(`/library/sierra`);
}

/**
 * Create Sierra user library list
 * 2019/11/12
 * 
 * @export
 * @param {formData} formData
 * @returns
 */
export function createSierraLibraryList(formData, config) {
  return http.post(`/library/sierra`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

/**
 * Create sierra stimulus data
 * 2019/11/16
 * @param data {fileName,typeName,delay...}
 * @export
 * @returns
 */

export function createSierraStimulus(data, config) {
  return http.post(`/library/stimulus/sierra`, data, config);
};

/**
 * Get sierra user library parse file
 * 2019/11/16
 * 
 * @export
 * @param {String} libraryId  library id
 * @returns
 */
export function getLibraryParseData(libraryId) {
  return http.get(`/library/${libraryId}/file/parse`);
}

/**
 * delete sierra user library list
 * 2019/11/16
 * @param sierraUserLibraryIds:array [] String
 * @export
 * @returns
 */
export function delSierraLibraryList(sierraUserLibraryIds) {
  return http.delete(`/library/sierra`,
    {
      params: {
        sierraUserLibraryIds: sierraUserLibraryIds.toString()
      }
    });
}


/**
 * Get sierra stimulus data by ID
 * 2019/11/16
 * @param libraryId
 * @export
 * @returns
 */
export function getSierraStimulusData(libraryId) {
  return http.get(`/library/${libraryId}/data/sierra`);
}

/**
 * Update sierra stimulus data
 * 2019/11/16
 * @param data {fileName,typeName,delay...}
 * @param libraryId
 * @export
 * @returns
 */

export function updateSierraStimulus(libraryId, data, config) {
  return http.put(`/library/${libraryId}/stimulus/sierra`, data, config);
};

/**
 * Get sierra library file by library id
 * 2019/11/19
 * 
 * @param libraryId
 * @export
 * @returns
 */
export function getSierraLibraryFile(libraryId, fileName) {
  if (fileName) {
    return http.get(`/library/${libraryId}/file/sierra?fileName=${fileName}`);
  } else {
    return http.get(`/library/${libraryId}/file/sierra`);
  }
}

/**
 * edit sierra repeater file
 * 2024/03/07
 * 
 * @param { fileId, folderId, name, content, libraryType }
 * @export
 * @returns
 */
export function editSierraLibraryFile({ fileId, folderId, name, content, libraryType }) {
  return http.post(`/sierra/library/update`, { fileId, folderId, name, content, libraryType });
}

/** 
 * Update / Upload a SPICE file in a library folder
 * 2020/11/25
 * @param {string} productType sierra
 * @param {formData} formData {fileNames,libraryId,libraryType,file}
 * @export
 * @returns
*/
export function updateLibraryFile({ productType, formData, config }) {
  return http.post(`/user/library/${productType}/update`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

/**
 * download library
 * 2020/11/25
 * @param {array} libraryIds []
 * @param {string} productType sierra
 * @export
 * @returns
 */
export function downloadLibraryFile(libraryIds, productType) {
  return http.get(`/user/library/download?libraryIds=${libraryIds}&type=${productType}`);
}

/**
 * get sierra library Macro Modeling status
 * 
 * @param {string} libraryId
 * @export
 * @returns
 */
export function getMacroModelingStatusByLibraryId(libraryId) {
  return http.get(`/library/${libraryId}/sierra/touchstone/status`);
}

/**
 * get sierra library part IBIS/Repeater
 * 
 * @param {string} page
 * @param {string} pageSize
 * @param {string} type IBIS/REPEATER
 * @export
 * @returns
 */
export function getSierraPartLibraryList({ page, pageSize, type }) {
  return http.get(`/sierra/part-library/page?type=${type}&page=${page}&size=${pageSize}`);
}

/**
 * upload sierra library part IBIS/Repeater excel
 * 
 * @param {file} file
 * @param {string} type IBIS/REPEATER
 * @param {string} uploadType Add/Replace
 * @export
 * @returns
 */
export function uploadSierraPartLibrary({ file, type, uploadType }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  formData.append('uploadType', uploadType);
  return http.post(`/sierra/part-library/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

/**
 * search sierra library part IBIS/Repeater list
 * 
 * @param {string} type IBIS/REPEATER
 * @param {string} searchKey search str
 * @export
 * @returns
 */
export function getSierraPartLibraryListBySearch({ partNumbers, type }) {
  return http.post(`/sierra/part-library/search/${type}`, partNumbers);
}

/**
 * get sierra library part IBIS/Repeater check files
 * 
 * @param {string} type part_IBIS/part_repeater
 * @export
 * @returns
 */
export function getSierraPartLibraryCheckFiles(type) {
  return http.get(`/sierra/part-library/check/${type}`);
}

/**
 * edit part library
 * @param {string} type part_IBIS/part_repeater
 * @param {object} editedPart {id, file, component, ....}
 * @export
 * @returns Promise
 *  */
export function editSierraPartLibrary({ type, editedParts = [] }) {
  return http.post(`/sierra/part-library?type=${type}`, editedParts);
}

/**
 * auto update repeater component setting and interface setup by part  repeater library
 * @param {array} addRepeaterParts new added repeater library
 * @export
 * @returns Promise
 *  */
export function autoUpdatePartRepeaterSetting(addRepeaterParts) {
  return http.post(`sierra/part-repeater/setting/update/check`, addRepeaterParts);
}

/**
 * get  status (auto update repeater component setting and interface setup by part  repeater library)
 * @export
 * @returns Promise
 *  */
export function getPartRepeaterUpdateStatus() {
  return http.get(`sierra/part-repeater/update/log`);
}

/**
 * set default library
 * @param {object} param {libraryId, libraryType, name }
 * @export
 * @returns Promise
 *  */
export function setSierraDefaultLibrary(param) {
  return http.post(`/library/sierra/default`, {
    ...param,
    product: "Sierra"
  })
}

/**
 * get default library
 * @export
 * @returns Promise
 *  */
export function getSierraDefaultLibrary() {
  return http.get(`/library/sierra/default`)
}

/**
 * get sierra library nets filter
 * 20240805
 * @param {string} page
 * @param {string} pageSize
 * @export
 * @returns
 */
export function getSierraNetsFilterLibraryList({ page, pageSize }) {
  return http.get(`/sierra/nets/library/page?&page=${page}&size=${pageSize}`);
}

/**
 * upload sierra library nets filter
 * 20240805
 * @param {file} file
 * @param {string} uploadType Add/Replace
 * @export
 * @returns
 */
export function uploadSierraNetsFilterLibrary({ file, uploadType }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('uploadType', uploadType);
  return http.post(`/sierra/nets/library/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

/**
 * edit nets filter library
 * @param {object} editedFilters {id, filter, ....}
 * @export
 * @returns Promise
 *  */
export function editSierraNetsFilterLibrary({ editedFilter = {} }) {
  return http.post(`/sierra/net/library`, editedFilter);
}

/**
 * search and apply powers nets by keywords
 * @param {array} netLibraryList  [{id, filter, ....}]
 * @param {designId} designId
 * @param {bool} applyAll
 * @export
 * @returns Promise
 *  */
export function searchSierraPowerNetsByKeywords({ designId, netLibraryList = [], applyAll = false }) {
  return http.post(`/sierra/${designId}/power-net/reload?applyAll=${applyAll}`, netLibraryList);
}

/**
 * get  status (auto search power nets by net keywords)
 * @export
 * @returns Promise
 *  */
export function geSierraPowerNetsSearchStatus() {
  return http.get(`/sierra/power-net/update/log`);
}

/**
 * get trace template info
 * @param {string} libraryId
 * @export
 * @returns Promise
 *  */
export function getSierraTraceTemplateLibrary(libraryId) {
  return http.get(`/sierra/library/trace?id=${libraryId}`);
}

/**
 * create/update trace template info
 * @param {obj} trace
 * @export
 * @returns Promise
 *  */
export function updateSierraTraceTemplateLibrary(trace) {
  return http.post(`/sierra/library/trace`, trace);
}

/**
 * calculate trace template Z0 ZDIFF
 * @param {obj} trace
 * @export
 * @returns Promise
 *  */
export function calculateSierraTraceImpedance(trace) {
  const source = axios.CancelToken.source();
  const requestPromise = http.post('/sierra/prelayout/rlgc/task', trace, {
    cancelToken: source.token
  });
  return {
    promise: requestPromise,
    cancel: () => source.cancel('Request Cancelled!')
  };
}

/**
 * get trace template Z0 ZDIFF
 * @param {string} workflowId
 * @export
 * @returns Promise
 *  */
export function getSierraTraceImpedanceData(workflowId) {
  return http.get(`/sierra/library/trace/impedance?workflowId=${workflowId}`);
}
