import CeGeomTools from './CeGeomTools';
import CeGeometry from './CeGeometry';
import CeCircle from './CeCircle';
import CeVertex from './CeVertex';
import CeVertexArc from './CeVertexArc';
import GCPolygon from './GCPolygon';
import StringList from '../utility/StringList';
import StringHelper from '../utility/StringHelper';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeArc = function () {
  CeGeometry.call(this, "Arc");
}

// subclass extends superclass
CeArc.prototype = Object.create(CeGeometry.prototype);
CeArc.prototype.constructor = CeArc;

CeArc.prototype.SetData = function (xs, ys, xe, ye, xc, yc, ccw) {
  this.mCCW = ccw;
  this.mStart = new CeVertex();
  this.mStart.SetData(xs, ys);
  this.mEnd = new CeVertex();
  this.mEnd.SetData(xe, ye);
  this.mCenter = new CeVertex();
  this.mCenter.SetData(xc, yc);
}

CeArc.prototype.CopyFrom = function (fromObj) {
  this.mCCW = fromObj.mCCW;
  this.mStart = fromObj.mStart.Clone();
  this.mEnd = fromObj.mEnd.Clone();
  this.mCenter = fromObj.mCenter.Clone();
  CeGeometry.prototype.CopyFrom.call(this, fromObj);
}

CeArc.prototype.Clone = function () {
  var clnObj = new CeArc();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeArc.prototype.Scaling = function (scaleFactor) {
  this.mStart.Multiply(scaleFactor);
  this.mEnd.Multiply(scaleFactor);
  this.mCenter.Multiply(scaleFactor);
}

CeArc.prototype.GetDataString = function () {
  var defData = new StringList();
  defData.push(this.mStart.GetDataString());
  defData.push(this.mEnd.GetDataString());
  defData.push(this.mCenter.GetDataString());
  defData.push(StringHelper.BoolToString(this.mCCW));
  return defData;
}

CeArc.prototype.UpdateByString = function (dataVals) {
  // startX, startY, endX, endY, centerX, centerY, true(ccw)
  if (!(dataVals instanceof StringList) || dataVals.size() != 7)
    return false;

  var startVals = new StringList();
  startVals.push(dataVals.get(0));
  startVals.push(dataVals.get(1));
  this.mStart = new CeVertex();
  this.mStart.UpdateByString(startVals);

  var endVals = new StringList();
  endVals.push(dataVals.get(2));
  endVals.push(dataVals.get(3));
  this.mEnd = new CeVertex();
  this.mEnd.UpdateByString(endVals);

  var centerVals = new StringList();
  centerVals.push(dataVals.get(4));
  centerVals.push(dataVals.get(5));
  this.mCenter = new CeVertex();
  this.mCenter.UpdateByString(centerVals);

  this.mCCW = StringHelper.StringToBool(dataVals.get(6));

  return true;
}

CeArc.prototype.WriteIntoIODataNode = function () {
  return new CeIODataItem(this.GetGeomType(), this.GetDataString());
}

CeArc.prototype.ReadFromIODataNode = function (fileItem) {
  if (!(fileItem instanceof CeIODataItem))
    return false;
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeArc.prototype.equals = function (obj) {
  if (!(obj instanceof CeArc))
    return false;

  if (this.mCCW != obj.mCCW || this.mStart.equals(obj.mStart) == false ||
    this.mEnd.equals(obj.mEnd) == false ||
    this.mCenter.equals(obj.mCenter) == false)
    return false;

  return true;
}

CeArc.prototype.ConvertToGCPolygon = function () {
  var arcPnt = new CeVertexArc();
  arcPnt.SetData(this.mEnd.mX, this.mEnd.mY, this.mCenter.mX, this.mCenter.mY, this.mCCW);
  return CeGeomTools.ArcToLineSegments(this.mStart, arcPnt);
}

CeArc.prototype.ConvertToGCPolygons = function (section) {
  if (section == null) {
    var gcPoly = this.ConvertToGCPolygon();
    var polyList = [];
    polyList.push(gcPoly);
    return polyList;
  }

  var gcPoly = this.ConvertToGCPolygon();
  var polyList = [];
  polyList.push(gcPoly);
  return polyList;
}

CeArc.prototype.GetRadius = function () {
  return this.mStart.ToPointDistance(this.mCenter);
}

CeArc.prototype.GetStartAngle = function () {
  return CeGeomTools.PointToXaxisAngle(this.mStart.mX - this.mCenter.mX,
    this.mStart.mY - this.mCenter.mY);
}

CeArc.prototype.GetEndAngle = function () {

  return CeGeomTools.PointToXaxisAngle(this.mEnd.mX - this.mCenter.mX,
    this.mEnd.mY - this.mCenter.mY);
}

CeArc.prototype.GetExtent = function () {
  if (this.mCCW) {
    return this.GetEndAngle() - this.GetStartAngle();
  } else {
    return Math.PI * 2 - Math.abs((this.GetEndAngle() - this.GetStartAngle()));
  }
}


CeArc.prototype.GetCenter = function () {
  return this.mCenter;
}

CeArc.ArcToLineSegments = function (startPnt, endPnt) {
  var angle1 = CeGeomTools.PointToXaxisAngle(startPnt.mX - endPnt.mCenter.mX,
    startPnt.mY - endPnt.mCenter.mY);
  var angle2 = CeGeomTools.PointToXaxisAngle(endPnt.mX - endPnt.mCenter.mX,
    endPnt.mY - endPnt.mCenter.mY);

  var numPnt = 10;
  var diffAng = 0.0;
  if (endPnt.mCCW == true) {
    if (angle2 < angle1) {
      diffAng = 2.0 * Math.PI - (angle1 - angle2);
    } else {
      diffAng = angle2 - angle1;
    }
  } else {
    if (angle2 > angle1) {
      diffAng = -1.0 * (2.0 * Math.PI - (angle2 - angle1));
    } else {
      diffAng = -1.0 * (angle1 - angle2);
    }
  }
  var divAng = Math.PI * 10.0 / 180.0;
  numPnt = Math.ceil(Math.abs(diffAng / divAng));
  numPnt = numPnt < 5 ? 5 : numPnt;

  return this.ArcToLineSegments2(numPnt, startPnt, endPnt);
}

CeArc.ArcToLineSegments2 = function (numPnt, startPnt, endPnt) {
  var radius = endPnt.GetRadius();
  if (startPnt.OnSameLocation(endPnt)) {
    var circle = new CeCircle();
    circle.SetData(endPnt.mCenter.mX, endPnt.mCenter.mY, 2 * radius);
    return circle.ConvertToGCPolygon();
  }

  var angle1 = CeGeomTools.PointToXaxisAngle(startPnt.mX - endPnt.mCenter.mX,
    startPnt.mY - endPnt.mCenter.mY);
  var angle2 = CeGeomTools.PointToXaxisAngle(endPnt.mX - endPnt.mCenter.mX,
    endPnt.mY - endPnt.mCenter.mY);

  var diffAng = 0.0;
  var delta = 0.0;

  if (endPnt.mCCW == true) {
    if (angle2 < angle1) {
      diffAng = 2.0 * Math.PI - (angle1 - angle2);
    } else {
      diffAng = angle2 - angle1;
    }
  } else {
    if (angle2 > angle1) {
      diffAng = -1.0 * (2.0 * Math.PI - (angle2 - angle1));
    } else {
      diffAng = -1.0 * (angle1 - angle2);
    }
  }
  if (numPnt < 2)
    numPnt = 2;

  delta = diffAng / (numPnt - 1);

  var xArray = [];
  var yArray = [];

  xArray.push(startPnt.mX);
  yArray.push(startPnt.mY);

  for (var i = 1; i < numPnt - 1; i++) {
    xArray.push(Math.cos(angle1 + i * delta) * radius + endPnt.mCenter.mX);
    yArray.push(Math.sin(angle1 + i * delta) * radius + endPnt.mCenter.mY);
  }

  xArray.push(endPnt.mX);
  yArray.push(endPnt.mY);

  var gcPoly = new GCPolygon();
  gcPoly.SetData(numPnt, xArray, yArray, false);

  return gcPoly;
}

export default CeArc;