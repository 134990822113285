import { accSub } from '@/services/Cascade/helper/match';

function getVoltageDrop(IRMenuItem, resultPowerDomainData, itemArr) {
  let voltageDrop = '';
  if (IRMenuItem.key === resultPowerDomainData[0].powerNets) {
    voltageDrop = accSub(resultPowerDomainData[0].nominal, itemArr[6])
  } else if (IRMenuItem.key === resultPowerDomainData[0].gndNets) {
    voltageDrop = (itemArr[6] * 1000).toFixed(2)
  }
  return voltageDrop
}

export {
  getVoltageDrop
}