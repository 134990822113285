import React, { Component } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Row, Col, Input, Tooltip, Select } from 'antd';
import NP from 'number-precision';
import { scaleConversion } from '../../../helper/numberHelper';
import './waveformSetting.css';

class WaveformSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  scaleChangeSelectRender = (key, type, options) => {
    const { axis, scaleChange } = this.props;
    return <Select
      value={axis[key]}
      className='result-waveform-setting-select'
      onChange={scaleChange ? (value) => scaleChange(type, value) : null}
      options={options}
    />
  }
  render() {
    const { axis, changeRange, axisRangeKeyDown, axisChange, reloadAxis, getAxisTitle, showScale = false } = this.props;
    return (
      <div className='result-waveform-setting'>
        <Row className='waveform-setting-row'>
          <Col>
            <span className='axis-title'>{getAxisTitle().xTitle}</span>
            <span>({axis.xUnit})</span>
            <Tooltip placement="right" title='Reset'>
              <ReloadOutlined className='reload-btn' onClick={() => reloadAxis('x')} />
            </Tooltip>
          </Col>
        </Row>
        {showScale ? <Row className='waveform-setting-row'>
          <Col span={24}>
            <label className='input-lable waveform-scale-label'>Scale</label>
            {this.scaleChangeSelectRender('xScale', 'x', [
              { value: 'linear', label: 'Linear' },
              { value: 'log', label: 'Log' }
            ])}
          </Col>
        </Row> : null}
        <Row className='waveform-setting-row'>
          <Col span={12}>
            <label className='input-lable'>Begin</label>
            <Input
              className='setting-input'
              value={axis.xMin}
              onChange={(e) => changeRange(e, 'xMin')}
              step={0.1}
              onKeyDown={axisRangeKeyDown}
              onBlur={() => axisChange('xMin')}
            />
          </Col>
          <Col span={12}>
            <label className='input-lable'>End</label>
            <Input
              className='setting-input'
              value={axis.xMax}
              onChange={(e) => changeRange(e, 'xMax')}
              step={0.1}
              onKeyDown={axisRangeKeyDown}
              onBlur={() => axisChange('xMax')}
            />
          </Col>
        </Row>
        <Row className='waveform-setting-row'>
          <Col>
            <span className='axis-title'>{getAxisTitle().yTitle}</span>
            <span>{axis.yUnit ? `(${axis.yUnit})` : ''}</span>
            <Tooltip placement="right" title='Reset'>
              <ReloadOutlined className='reload-btn' onClick={() => reloadAxis('y')} />
            </Tooltip>
          </Col>
        </Row>
        {showScale ? <Row className='waveform-setting-row'>
          <Col span={24}>
            <label className='input-lable waveform-scale-label'>Scale</label>
            {this.scaleChangeSelectRender('yScale', 'y', [
              { value: 'linear', label: 'Linear' },
              { value: 'log', label: 'Log' }
            ])}
          </Col>
        </Row> : null}
        <Row className='waveform-setting-row'>
          <Col span={12}>
            <label className='input-lable'>Begin</label>
            <Input
              className='setting-input'
              value={axis.yMin}
              onChange={(e) => changeRange(e, 'yMin')}
              step={0.1}
              onKeyDown={axisRangeKeyDown}
              onBlur={() => axisChange('yMin')}
            />
          </Col>
          <Col span={12}>
            <label className='input-lable'>End</label>
            <Input
              className='setting-input'
              value={axis.yMax}
              onChange={(e) => changeRange(e, 'yMax')}
              step={0.1}
              onKeyDown={axisRangeKeyDown}
              onBlur={() => axisChange('yMax')}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class WaveformSettingComponent extends Component {
  constructor(props) {
    super(props)
    const { currentResultKey } = props
    this.state = {
      axis: {
        xMin: '',
        xMax: '',
        yMin: '',
        yMax: '',
        yUnit: ['MM_POWERSUM', 'SE_POWERSUM'].includes(currentResultKey) ? 'dB' : currentResultKey === 'CAPACITANCE' ? 'F' : 'V',
        xUnit: ['MM_POWERSUM', 'SE_POWERSUM', "CAPACITANCE"].includes(currentResultKey) ? 'Hz' : 'ns',
        xScale: "linear",
        yScale: "linear"
      },
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  setAxis = (axis) => {
    this.setState((prevState) => ({
      axis: { ...prevState.axis, ...axis }
    }))
  }

  getAxis = () => {
    return this.state.axis;
  }

  changeRange = (e, type) => {
    let _value = e.target.value;
    this.setState((prevState) => {
      prevState.axis[type] = _value;
      return {
        axis: prevState.axis
      }
    })
  }

  axisRangeKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  axisChange = (type) => {
    const plot = this.props.plot;
    const { xMax, xMin } = this.props.plot && this.props.plot.options ? this.props.plot.options : {};
    let _xMax, _xMin, _yMax, _yMin;
    const { axis } = this.state;
    let _axis = { ...axis };
    if (type === 'xMin' || type === 'xMax') {
      let fXMax, fXmin, minValue, maxValue;
      if (axis.xUnit.match('Hz')) {
        fXMax = isNaN(parseFloat(axis.xMax)) ? NP.divide(plot.options.xMax, scaleConversion('Hz', axis.xUnit)) : parseFloat(axis.xMax);
        fXmin = isNaN(parseFloat(axis.xMin)) ? NP.divide(plot.options.xMin, scaleConversion('Hz', axis.xUnit)) : parseFloat(axis.xMin);
        _xMax = NP.strip(fXMax);
        _xMin = NP.strip(fXmin);
        // toFix(4): Keep the four decimal points, parseFloat: Removal invalid 0
        minValue = parseFloat((xMin * scaleConversion(axis.xUnit, 'Hz')).toFixed(4));
        maxValue = parseFloat((xMax * scaleConversion(axis.xUnit, 'Hz')).toFixed(4));
      } else {
        fXMax = isNaN(parseFloat(axis.xMax)) ? NP.divide(plot.options.xMax, 1e-9) : parseFloat(axis.xMax);
        fXmin = isNaN(parseFloat(axis.xMin)) ? NP.divide(plot.options.xMin, 1e-9) : parseFloat(axis.xMin);
        _xMax = NP.strip(fXMax);
        _xMin = NP.strip(fXmin);
        minValue = parseFloat((xMin / 1e-9).toFixed(4))
        maxValue = parseFloat((xMax / 1e-9).toFixed(4))
      }

      if (_xMin < minValue) {
        _xMin = minValue
      } else if (_xMin > maxValue) {
        _xMin = maxValue
      }
      if (_xMax < minValue) {
        _xMax = minValue
      } else if (_xMax > maxValue) {
        _xMax = maxValue
      }
      _axis.xMax = _xMax;
      _axis.xMin = _xMin;
      this.setState({
        axis: _axis
      })
    } else if (type === 'yMin' || type === 'yMax') {
      let fYMax = parseFloat(axis.yMax);
      // options
      const { yMin, yMax } = plot.options;
      if (parseFloat(axis.yMax).toString() === 'NaN') {
        plot.yScale
          .domain([Math.max(yMin, yMax), Math.min(yMin, yMax)])
          .nice()
          .range([0, plot.size.height])
          .nice();
        const yRange = plot.yScale.domain();
        fYMax = parseFloat(yRange[0].toFixed(1));
      }
      const fYmin = parseFloat(axis.yMin).toString() === 'NaN' ? yMin : parseFloat(axis.yMin);
      _yMax = NP.strip(fYMax);
      _yMin = NP.strip(fYmin);
      _axis.yMax = _yMax;
      _axis.yMin = _yMin;
      this.setState({
        axis: _axis
      })
    }
    this.props.axisChangeCB(type, { _xMin, _xMax, _yMin, _yMax, xUnit: axis.xUnit, yUnit: axis.yUnit })
  }

  scaleChange = (type, scale) => {
    const { axis } = this.state;
    let _axis = { ...axis };
    _axis[`${type}Scale`] = scale;
    this.setState({
      axis: _axis
    })
    this.props.scaleChangeCB(type, scale);
  }

  reloadAxis = (type) => {
    const plot = this.props.plot;
    const { options } = plot;
    let { ui, xMin, xMax, yMin, yMax, xUnit } = options;
    let { axis } = this.state;
    if (type === 'x') {
      xMin = axis.xScale === 'log' && xMin <= 0 ? 1e-12 : xMin;
      plot.xScale
        .domain([xMin, xMax])
        .range([0, plot.size.width])
      if (xUnit === 'Hz') {
        axis.xMin = parseFloat((xMin * ui).toPrecision(12));
        axis.xMax = parseFloat((xMax * ui).toPrecision(12));
      } else {
        axis.xMin = xMin;
        axis.xMax = (xMax / ui).toFixed(2);
      }

      if (plot.product === 'cascade_transient_result') {
        axis.xMin = (xMin / ui).toFixed(3);
        axis.xMax = (xMax / ui).toFixed(3);
      }

      const xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = NP.times(NP.divide(NP.minus(xrange[0], xMin), NP.minus(xMax, xMin)), width),
        endX = NP.times(NP.divide(NP.minus(xrange[1], xMin), NP.minus(xMax, xMin)), width);
      plot.updateRange(startX, endX);

      if (plot.product === "sierra_total_capacitance") {
        const info = plot.getAxis("x", axis.xMin, axis.xMax, "Hz");
        axis.xMin = info.min;
        axis.xMax = info.max;
        axis.xUnit = info.unit;
      }
    } else {

      if (axis.yScale === 'log') {
        yMin = yMin <= 0 ? 1e-12 : yMin;
        yMax = yMax <= 0 ? 1e-12 : yMax;
        plot.yScale
          .domain([Math.max(yMin, yMax), Math.min(yMin, yMax)])
          .range([0, plot.size.height])
      } else {
        plot.yScale
          .domain([Math.max(yMin, yMax), Math.min(yMin, yMax)])
          .nice()
          .range([0, plot.size.height])
          .nice();
      }

      const yRange = plot.yScale.domain();
      axis.yMin = plot.product === 'cascade_transient_result' ? yRange[1] : yRange[1] > 0 ? 0 : yRange[1].toFixed(1);
      if (this.props.isTDR) {
        axis.yMin = yRange[1] < 0 ? 0 : yRange[1].toFixed(1);
      }
      axis.yMax = plot.product === 'cascade_transient_result' ? yRange[0] : yRange[0].toFixed(1);
      if (plot.product === "sierra_total_capacitance") {
        const info = plot.getAxis("y", yRange[0], yRange[1], "F");
        axis.yMin = isNaN(info.min) ? 0 : info.min.toFixed(1);
        axis.yMax = isNaN(info.max) ? 0 : info.max.toFixed(1);
        axis.yUnit = info.unit;
      }
    }
    this.setState({
      axis
    }, () => {
      this.props.reloadAxisCB();
    })
  }

  getAxisTitle = () => {
    const { currentResultKey } = this.props;
    const yTitles = {
      "TDR": "Y axis -- Impedance",
      "SBR": "Y axis -- Voltage",
      "MM_POWERSUM": "Y axis -- Power Sum",
      "SE_POWERSUM": "Y axis -- Power Sum",
      "CAPACITANCE": "Y axis -- Capacitance",
    };
    const xTitle = ['MM_POWERSUM', 'SE_POWERSUM', "CAPACITANCE"].includes(currentResultKey) ? 'X axis -- Frequency' : 'X axis -- Time'
    const yTitle = yTitles[currentResultKey] || 'Y axis -- Voltage';
    return { xTitle, yTitle };
  }

  render = () => {
    const { axis } = this.state;
    return (
      <WaveformSetting {...this.props}
        axis={axis}
        changeRange={this.changeRange}
        axisRangeKeyDown={this.axisRangeKeyDown}
        axisChange={this.axisChange}
        reloadAxis={this.reloadAxis}
        getAxisTitle={this.getAxisTitle}
        scaleChange={this.scaleChange}
      />
    )
  }
}

export default WaveformSettingComponent;