import {
  call,
  put,
  fork,
  take,
  cancel,
  takeEvery,
  takeLatest,
  select,
  delay
} from "redux-saga/effects";
import {
  GET_PDN_CONTENT,
  SELECT_NETS,
  SAVE_CONFIG_TO_SERVER,
  VOLTAGE_CHANGE,
  SAVE_VRM,
  SAVE_COMPONENTS,
  IGNORE_COMPONENTS,
  UPDATE_PACKAGE_INFO_IN_PDN_CONTENT,
  SAVE_CONFIG,
  FIND_VRM_MODEL,
  DefaultVRMModelInSPIM,
  SAVE_OPTIMIZATION,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS,
  CHANGE_USAGE,
  SAVE_CURRENT_PDN,
  CURRENT_PDN_DEBUG_VERIFY,
  SAVE_BGA_CONNECTION_NAME,
  UPDATE_COMP_RLC_PREFIX,
  GET_VRM_COMP,
  DECAP_REMOVED_CHANGE,
  SAVE_CURRENT_PDN_BY_SETTING,
  SAVE_PDN_CONTENT,
  UPDATE_INCLUDE_EXTENDED,
  SAVE_MAIN_NETS,
  CHECK_NETS,
  UPDATE_CHECK_NETS,
  CREATE_COPY_DECAP,
  CREATE_COPY_DECAP_END,
  AUTO_SELECT_NETS,
  AUTO_CHECK_NETS,
  UPDATE_SELECT_NETS,
  GET_NETS,
  SAVE_PACKAGE_COMPONENT,
  UPDATE_SIM_CONFIG,
  TRACE_BACK_VRM,
  UPDATE_PDNS_AFTER_REPLACE_PCB
} from "./actionTypes";
import {
  updatePDNContent,
  updatePDNInterface,
  updatePrefixDisplay,
  findVRMLoading,
  findPowerDomainLoading,
  updateCapsConnectRefNet,
} from "./action";
import { debugMonitorAction } from "../tabMonitor/action";
import { START_PDN_VERIFICATION } from "../simulation/actionTypes";
import {
  getPDNContentPromise,
  RLComponent,
  CapComponent,
  BasicComponent,
  updatePDNPromise,
  getPDNComponentsByNets,
  ExtractionOptions,
  getPowerDomainNets,
  getPowerReferenceNets,
  getReferenceComps,
  getPowerDomainRNetwork,
  SimConfig,
  getDefaultSolverByDesignVendor,
  changeDecapModelToMulit,
  deletePackageChipPromise,
  compareComponents
} from '@/services/PDN';
import { getLayerByComponent } from '@/services/PCBHelper';
import { getComponentsWithNetList, getLayoutComponents, getUsage, getComponents } from '@/services/helper/setup/setupData'
import { checkRLCValue, versionCompareSize } from '@/services/helper/dataProcess';
import { getLibraryList, getListVRMModel } from '@/services/PDN/library';
import { getLibraryDataList } from '@/services/PDN/library/libraryData';
import { libraryMenu, removeCurrentPreparingPDN, updateProjectMenu, updatePreparePDNS } from '../project/action';
import { startPDNVerification, debugVerify, updateLibraryDataCheck, updateStimulationMessage } from '../simulation/action';
import VRMData from '@/services/PDN/library/getVRMData';
import DecapData from '@/services/PDN/library/getDecapData';
import { getFastPISIWEMode, getFastPICouplingMinFreq } from '@/services/api/cookies';
import { FASTPI_SETUP_VERSION } from '@/version';
import { componentFilter, getVRMs, prefixCheckByVRM, getTraceByVRM } from '@/services/helper/componentsHelper';
import { SortFn, SortComponent } from '@/services/helper/sort';
import { resetDefaultValue } from '@/services/helper/numberHelper';
import CompRLCPrefixLib from '@/services/helper/componentsHelper/componentRLCPrefix';
import { autoFindDecap } from '@/services/PDN/MatchDecap'
import { DEFAULT_LIBRARY_ID, PDN_CREATE } from '../../constants';
import { message } from 'antd';
import { checkVerificationStatus } from '../../../../services/workflow/workflow';
import { VERIFY_RUNNING, WAITING } from '../../../../constants/verificationStatus';
import permissionData from '@/services/helper/data/permissionData';
import { getComponentNeedInfoByName } from '@/services/PCBHelper/components';
import { OptOptions } from "../../../../services/PDN/optimization";
import LayoutData from '../../../../services/data/LayoutData'
import { FASTPI } from '../../../../constants/pageType';

function* _updateChip(action) {
  const {
    PDNReducer: { project },
  } = yield select();
  const chipList = project.currentProjectPackages;
  let _Components = action.Components;
  if (chipList.length > 0) {
    const { model, libraryId, chip } = chipList[0];
    for (let comp of _Components) {
      if (comp.usage === "Chip" && comp.name !== chip) {
        comp.usage = "Ignore";
        if (comp.pkg) {
          delete comp.pkg;
        }
      }

      if (comp.name === chip) {
        comp.pkg = {
          type: "Intel_SPIM",
          name: model,
          id: libraryId,
        };
        comp.usage = "Chip";
      }
    }
  }
  return _Components;
}

function* _getVRMs(params) {
  // Chip
  const {
    PDNReducer: { pdn, project },
  } = yield select();
  let pdnInfo = params.pdnInfo ? params.pdnInfo : pdn.pdnInfo;

  let defaultVRM = params.defaultVRM ? params.defaultVRM : pdn.defaultVRM;

  const chipList =
    project.currentProjectPackages &&
    project.currentProjectPackages.map((item) => item.chip);
  const pcbId = pdnInfo.designId;

  // default
  const pdnContent = pdnInfo.pdnContent;
  const COMP_PREFIX_LIB = pdnContent.COMP_PREFIX_LIB || new CompRLCPrefixLib();
  const ReferenceNets = [...pdnContent.ReferenceNets];
  const PowerNets = [...pdnContent.PowerNets];
  const includeExtended = pdnContent.includeExtended;
  const VRM = pdnContent.VRM;
  return getVRMs({
    VRM,
    ReferenceNets,
    PowerNets,
    defaultVRM,
    pcbId,
    COMP_PREFIX_LIB,
    findExtend: includeExtended,
    chipList,
    getLayoutComponents, //fn
    getPowerComponentsByNets: getPDNComponentsByNets, //fn
    getReferenceComps,
    ...params,
  });
}

function* getPDNContent(action) {
  const { PDNID } = action;
  let response = null;
  try {
    response = yield call(getPDNContentPromise, PDNID);
  } catch (error) {
    message.error(error);
    return;
  }
  let libraryId = response.libraryId;
  if (libraryId === DEFAULT_LIBRARY_ID) {
    const {
      PDNReducer: {
        project: { currentProjectPackages, SPIMNames },
      },
    } = yield select();
    if (currentProjectPackages.length > 0) {
      let id = currentProjectPackages[0].libraryId;
      let SPIM = SPIMNames.filter((item) => item.id === id);
      let children = SPIM && SPIM[0] ? SPIM[0].children : [];
      if (children && children.length > 0) {
        children.forEach((item) => {
          if (item.name === response.name) {
            libraryId = id;
          }
        });
      }
    }
  }

  let pdnContent = response.pdnContent;
  let { Components } = pdnContent;
  //  update SPIM library id
  Components = yield call(_updateChip, { Components });

  if (!pdnContent.COMP_PREFIX_LIB) {
    pdnContent.COMP_PREFIX_LIB = new CompRLCPrefixLib();
  }

  let defaultIncludeExtended = false;

  if (
    response.pdnVersion &&
    (response.pdnVersion === "0.1a" ||
      versionCompareSize(response.pdnVersion, "0.1.16"))
  ) {
    defaultIncludeExtended = true;
  }
  //new version 0.2.1 todo change type
  if (
    response.pdnVersion === "0.1a" ||
    versionCompareSize(response.pdnVersion, "0.2.1")
  ) {
    Components = changeDecapModelToMulit(Components);
  }

  pdnContent.includeExtended =
    pdnContent && typeof pdnContent.includeExtended === "boolean"
      ? pdnContent.includeExtended
      : defaultIncludeExtended;

  if (!pdnContent.MAIN_POWER_NETS) {
    pdnContent.MAIN_POWER_NETS = [];
  }

  if (!pdnContent.MAIN_REF_NETS) {
    pdnContent.MAIN_REF_NETS = [];
  }
  // check main power nets and main referene nets
  yield put({
    type: UPDATE_CHECK_NETS,
    error: checkNetsError(
      pdnContent.MAIN_POWER_NETS,
      pdnContent.MAIN_REF_NETS,
      pdnContent.PowerNets,
      pdnContent.ReferenceNets
    ),
  });

  // add COMP_TYPE
  Components.forEach((item) => {
    if (!item.COMP_TYPE) {
      const {
        pinLength: allPinsLength,
        partNumber,
      } = getComponentNeedInfoByName({
        designID: response.designId,
        compName: item.name,
      });
      const _usage = getUsage(
        item.name,
        pdnContent.COMP_PREFIX_LIB,
        allPinsLength,
        partNumber
      );
      item.COMP_TYPE = _usage;
    }
  });
  // Sort by usage Chip, Decap, Res, Ind, Ignore.
  const sort = ["Chip", "Cap", "Res", "Ind", "Ignore"];
  Components = SortFn(Components, sort, "usage");
  // Sorting components by component name
  const _SortComponents = Components.sort(function (a, b) {
    return SortComponent(a, b);
  });
  pdnContent.Components = _SortComponents;

  if (!Array.isArray(pdnContent.VRM)) {
    pdnContent.VRM = [pdnContent.VRM];
  }

  //add VRM_COMPS
  //save VRM components name
  if (!pdnContent.VRM_COMPS) {
    const vrmComps = pdnContent.VRM.map((item) => item.VRM_COMP);
    pdnContent.VRM_COMPS = [...getVRMCOMPS(vrmComps)];
  }

  const prefixCheck = prefixCheckByVRM(pdnContent.VRM);
  yield put(updatePrefixDisplay(prefixCheck));

  pdnContent.Config = new ExtractionOptions({
    ...pdnContent.Config,
    SOLVER: getDefaultSolverByDesignVendor(response.designId) || (pdnContent.Config ? pdnContent.Config.SOLVER : null) || "SIwave"
  });
  if (!permissionData.hasAOI() && (!pdnContent.Config || pdnContent.Config.SOLVER === 'PowerSI')) {
    pdnContent.Config = new ExtractionOptions({ SOLVER: 'SIwave' });
  }

  if (!pdnContent.Optimization.cap) {
    pdnContent.Optimization.cap = "100u"; // version: 0.1.18
  }

  // get Caps connect Reference Nets
  const ReferenceNets = pdnContent.ReferenceNets;
  const COMP_PREFIX_LIB = pdnContent.COMP_PREFIX_LIB;
  let { findCaps } = getPDNComponentsByNets({
    ReferenceNets,
    PowerNets: [],
    pcbId: response.designId,
    COMP_PREFIX_LIB,
  });
  const { referenceComps } = getReferenceComps({
    ReferenceNets,
    pcbId: response.designId,
    COMP_PREFIX_LIB,
    VRM_COMPS: pdnContent.VRM_COMPS,
    findCaps,
  });
  yield put(updateCapsConnectRefNet(referenceComps));

  const PDNInfo = {
    PDNID: response.id,
    pdnContent: pdnContent,
    projectId: response.projectId,
    verificationId: response.verificationId,
    designId: response.designId,
    pdnVersion: response.pdnVersion,
    ifDoExtraction: response.ifDoExtraction,
    libraryId: libraryId,
  };

  yield put(updatePDNContent(PDNInfo));
  yield fork(autoSave);
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([
      SAVE_CONFIG_TO_SERVER,
      START_PDN_VERIFICATION,
    ]);
    let delayTime = 3000;
    if (newAction.type === SAVE_CONFIG_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, savePDNContentToServer);
    } else if (newAction.type === START_PDN_VERIFICATION) {
      delayTime = 0;
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, savePDNContentToServer);
    }
  }
}

function* debounce(time = 0, callback, data) {
  yield delay(time);
  yield call(callback, data);
}

function* savePDNContentToServer() {
  const { PDNReducer } = yield select();
  const pdn = PDNReducer.pdn;
  if (!pdn) return;
  const pdnInfo = pdn.pdnInfo;
  if (!pdnInfo) return;
  const { designId, PDNID, pdnContent, projectId, verificationId, libraryId } = pdnInfo;
  let content = setPDNContentConfig(pdnContent, designId);
  try {
    const response = yield call(updatePDNPromise, {
      designId,
      pdnContent: { ...content },
      pdnId: PDNID,
      pdnName: pdnContent.Name,
      projectId,
      verificationId,
      pdnVersion: FASTPI_SETUP_VERSION,
      libraryId,
    });
    return response;
  } catch (error) {
    console.error(error);
    return;
  }
}

function* savePDNConfigByAutoCreate(action) {
  const { pdnInfo } = action;
  if (!pdnInfo) return;
  const { designId, PDNID, pdnContent, projectId, verificationId, libraryId } = pdnInfo;
  let content = setPDNContentConfig(pdnContent, designId);
  try {
    const response = yield call(updatePDNPromise, {
      designId,
      pdnContent: { ...content },
      pdnId: PDNID,
      pdnName: pdnContent.Name,
      projectId,
      verificationId,
      pdnVersion: FASTPI_SETUP_VERSION,
      libraryId,
    });
    return response;
  } catch (error) {
    console.error(error);
    return;
  }
}

function setPDNContentConfig(content, designId) {
  // Config
  if (!content.Config) {
    content.Config = new ExtractionOptions({ SOLVER: getDefaultSolverByDesignVendor(designId) || "SIwave" });
  } else {
    let REFRIndex = content.Config.REFR
      ? content.Config.REFR.toString().indexOf("+")
      : -1;

    if (REFRIndex > -1) {
      content.Config.REFR = content.Config.REFR.toString().replace("+", "");
    }

    if (content.Config.SOLVER === "SIwave") {
      content.Config.DC = 1; //value is always 1 in version v0.1.4

      let maxIndex = content.Config.FMAX
        ? content.Config.FMAX.toString().indexOf("+")
        : -1;

      if (maxIndex > -1) {
        content.Config.FMAX = content.Config.FMAX.toString().replace("+", "");
      }

      if (content.Config.SIWE === undefined) {
        content.Config.SIWE = 0; //New fields in version v0.1.11
      }

      if (content.Config.VRTC === undefined) {
        content.Config.VRTC = 1; //New fields in version v0.1.13 => v0.1.17 update default value to 1
      }
    }

    if (content.Config.IPC === undefined) {
      content.Config.IPC = 1; //New fields in version v0.1.10 -> update default value v0.1.14
    }
  }

  if (content.Config.SOLVER === "SIwave") {
    content.Config.DCF = 1; //value is always 1 in version >= v0.1.20
    content.Config.couplingMinFreq = getFastPICouplingMinFreq();

    if (content.Config.SIWE !== getFastPISIWEMode()) {
      content.Config.SIWE = getFastPISIWEMode();
    }
  }

  // Compatible with versions before v0.1.2
  if (!content.Optimization) {
    content.Optimization = new OptOptions()
  }
  return content;
}

function* selectNets(action) {
  const { powerNets, first } = action;
  const {
    PDNReducer: { pdn },
  } = yield select();
  const pdnInfo = pdn.pdnInfo;
  const defaultVRM = pdn.defaultVRM;
  yield call(_selectNets, { powerNets, first, pdnInfo, defaultVRM });
}

function* autoSelectNets(action) {
  const { powerNets, first, pdnInfo } = action;
  const { userLibraryId } = pdnInfo;
  const defaultVRM = yield call(getDefaultVRM, { userLibraryId });
  yield call(_selectNets, { powerNets, first, pdnInfo, defaultVRM });
}

function* updateSelectNets(action) {
  const { powerNets, first, pdnInfo } = action;
  const { userLibraryId } = pdnInfo;
  const defaultVRM = yield call(getDefaultVRM, { userLibraryId });
  let part = "",
    modelList = {};
  // keep model setting
  const components = [...pdnInfo.pdnContent.Components];
  components.forEach((item) => {
    if (item.part !== part) {
      part = item.part;
      if (item.usage === "Cap") {
        modelList[part] = { models: item.models || [] };
      } else if (item.usage === "Res" || item.usage === "Ind") {
        modelList[part] = { value: item.value };
      }
    }
  });
  yield call(_selectNets, { powerNets, first, pdnInfo, modelList, defaultVRM });
}

function* getDefaultVRM(action) {
  const { userLibraryId } = action;
  let defaultVRM = {};
  if (userLibraryId) {
    defaultVRM = yield call(findVRMModel, {
      vrmId: userLibraryId,
      getVRM: true,
    });
  }
  return defaultVRM;
}

function* _selectNets(action) {
  const { powerNets, first, pdnInfo, modelList, defaultVRM } = action;
  if (!pdnInfo) return;
  const pdnContent = pdnInfo.pdnContent;
  const COMP_PREFIX_LIB = pdnContent.COMP_PREFIX_LIB || new CompRLCPrefixLib();

  let ReferenceNets = [...pdnContent.ReferenceNets];
  const preReferenceNets = [...ReferenceNets];
  let PowerNets = [...pdnContent.PowerNets];
  let MAIN_POWER_NETS = pdnContent.MAIN_POWER_NETS
    ? pdnContent.MAIN_POWER_NETS
    : [],
    MAIN_REF_NETS = pdnContent.MAIN_REF_NETS ? pdnContent.MAIN_REF_NETS : [];

  if (!powerNets || powerNets.length === 0) {
    return;
  }

  for (let item of powerNets) {
    const { powerType, nets } = item;
    if (powerType === "Reference") {
      MAIN_REF_NETS = first || !ReferenceNets.length ? nets : MAIN_REF_NETS;
      ReferenceNets = nets;
    } else if (powerType === "Power") {
      MAIN_POWER_NETS = first || !PowerNets.length ? nets : MAIN_POWER_NETS;
      PowerNets = nets;
    }
  }
  const pcbId = pdnInfo.designId;
  let { Components, findVRMComps } = getPDNComponentsByNets({
    ReferenceNets,
    PowerNets,
    pcbId,
    COMP_PREFIX_LIB,
  });
  // find caps only connect reference Nets
  const {
    PDNReducer: { pdn },
  } = yield select();
  let _findCaps = [...pdn.capsConnectRefNet];
  if (
    preReferenceNets.length !== ReferenceNets.length ||
    preReferenceNets.some((item) => !ReferenceNets.includes(item)) ||
    _findCaps.length === 0
  ) {
    let { findCaps } = getPDNComponentsByNets({
      ReferenceNets,
      PowerNets: [],
      pcbId,
      COMP_PREFIX_LIB,
    });
    _findCaps = findCaps;
  }

  // Automatically find VRMs
  // // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
  const getVRM = yield call(_getVRMs, {
    Components,
    ReferenceNets,
    PowerNets,
    findVRMComps,
    findCaps: _findCaps,
    pdnInfo,
    defaultVRM,
  });
  let vrms = getVRM && getVRM.vrms ? getVRM.vrms : [];
  const vrmComps = vrms.map((item) => item.vrmComp);

  const VRM_COMPS = [...getVRMCOMPS(vrmComps)];
  if (
    preReferenceNets.length !== ReferenceNets.length ||
    preReferenceNets.some((item) => !ReferenceNets.includes(item)) ||
    pdn.capsConnectRefNet.length === 0
  ) {
    const { referenceComps } = getReferenceComps({
      ReferenceNets,
      pcbId,
      COMP_PREFIX_LIB,
      VRM_COMPS,
      findCaps: _findCaps,
    });
    yield put(updateCapsConnectRefNet(referenceComps));
  }

  const DEBUG_MONITOR =
    getVRM && getVRM.DEBUG_MONITOR ? getVRM.DEBUG_MONITOR : "";
  const new_VRM = getVRM.VRM;
  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(new_VRM);
  const new_PowerNets = getVRM.PowerNets;
  let new_Component = getVRM.Components;
  //  update SPIM library id
  try {
    new_Component = yield call(_updateChip, { Components: new_Component });
  } catch (error) {
    console.error(`Update Component Error: ${error}`)
  }

  // auto find decap
  const {
    PDNReducer: { project },
  } = yield select();
  const { defaultDecap, DecapNames } = project;
  let matchComponents = [...new_Component];
  try {
    const Vdc = new_VRM && new_VRM.length ? new_VRM[0].voltage : 1;
    matchComponents = autoFindDecap(new_Component, defaultDecap, DecapNames, Vdc)
  } catch (error) {
    console.error(`Auto Find Decap Error: ${error}`)
  }
  // Sort by usage Chip, Decap, Res, Ind, Ignore.
  const sort = ["Chip", "Cap", "Res", "Ind", "Ignore"];
  new_Component = SortFn(matchComponents, sort, "usage");
  // Sorting components by component name
  const _SortComponents = matchComponents.sort(function (a, b) {
    return SortComponent(a, b);
  });

  //update model of domains
  if (modelList) {
    _SortComponents.forEach((item) => {
      if (modelList[item.part]) {
        if (item.usage === "Cap") {
          item.models = modelList[item.part].models || [];
        } else if (item.usage === "Res" || item.usage === "Ind") {
          item.value = modelList[item.part].value;
        }
      }
    });
  }

  let extra = {};
  if (pdnContent.packageComponent) {
    const {
      PDNReducer: {
        project: { currentProjectPackages },
      },
    } = yield select();
    const chip =
      currentProjectPackages && currentProjectPackages.length
        ? currentProjectPackages[0].chip
        : "";
    if (chip) {
      const layer = getLayerByComponent(pcbId, chip);
      const simConfig = new SimConfig(layer);
      extra.SimConfig = simConfig;
    }
  }

  //Distinguish between creating loading and opening loading
  if (yield call(isCurrentPDN, pdnInfo.PDNID)) {
    yield put(
      updatePDNInterface({
        ...extra,
        ReferenceNets,
        PowerNets: new_PowerNets,
        Components: _SortComponents,
        VRM: new_VRM,
        MAIN_POWER_NETS,
        MAIN_REF_NETS,
        VRM_COMPS,
      })
    );

    // update prefix Check prompt
    yield put(updatePrefixDisplay(prefixCheck));
    yield put({ type: SAVE_CONFIG_TO_SERVER });
  } else {
    pdnInfo.pdnContent = {
      ...pdnContent,
      ...extra,
      ReferenceNets,
      PowerNets: new_PowerNets,
      Components: _SortComponents,
      VRM: new_VRM,
      MAIN_POWER_NETS,
      MAIN_REF_NETS,
      VRM_COMPS,
    };
    yield call(savePDNConfigByAutoCreate, { pdnInfo });
  }

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }
}

function* voltageChange(action) {
  const { voltage } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: { pdnContent },
      },
      project: { defaultDecap, DecapNames }
    },
  } = yield select();
  let { VRM, Components } = pdnContent;
  const _voltage = parseFloat(voltage);
  if (Array.isArray(VRM)) {
    VRM.forEach((item) => {
      item.voltage = _voltage || _voltage === 0 ? _voltage : "";
    });
  } else {
    VRM.voltage = _voltage || _voltage === 0 ? _voltage : "";
  }
  let newComponents = Components.map(item => ({ ...item, models: [] }))
  if (_voltage || _voltage === 0) {
    newComponents = autoFindDecap(newComponents, defaultDecap, DecapNames, _voltage)
  }

  yield put(
    updatePDNInterface({
      VRM,
      Components: newComponents
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* isCurrentPDN(PDNID) {
  const {
    PDNReducer: { pdn },
  } = yield select();
  if (pdn.pdnInfo && pdn.pdnInfo.PDNID === PDNID) {
    return true;
  }
  return false;
}

function* saveVRM(action) {
  const { vrmType, data, index } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: { pdnContent },
        capsConnectRefNet,
      },
    },
  } = yield select();
  let { VRM, ReferenceNets, PowerNets } = pdnContent;
  if (index) {
    if (vrmType === "model") {
      /* VRM[index - 1].model = data; */
      VRM.forEach((item) => {
        item.model = { ...data };
      });
    } else {
      const nets = (vrmType === "powerPin" && PowerNets) || ReferenceNets;
      let { Components } = pdnContent;
      let _findComps = Components.filter((item) => data === item.name);
      if (vrmType === "groundPin") {
        _findComps = [...Components, ...capsConnectRefNet].filter((item) => data === item.name);
      }
      let vrmPins = [];
      _findComps.forEach((item) => {
        const pins = item.pins
          .filter((pin) => nets.indexOf(pin.net) > -1)
          .map((item) => item.pin);
        vrmPins.push({ comp: item.name, pins });
      });
      VRM[index - 1][vrmType] = vrmPins;
    }
  } else {
    if (vrmType === "model") {
      VRM.model = data;
    } else {
      const nets = (vrmType === "powerPin" && PowerNets) || ReferenceNets;
      let { Components } = pdnContent;
      let _findComps = Components.filter((item) => data === item.name);
      if (vrmType === "groundPin") {
        _findComps = capsConnectRefNet.filter((item) => data === item.name);
      }
      let vrmPins = [];
      _findComps.forEach((item) => {
        const pins = item.pins
          .filter((pin) => nets.indexOf(pin.net) > -1)
          .map((item) => item.pin);
        vrmPins.push({ comp: item.name, pins });
      });
      VRM[vrmType] = vrmPins;
    }
  }

  yield put(
    updatePDNInterface({
      VRM,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });

  yield delay(300);

  //vrm data error check
  if (vrmType === "model" && data && data.id) {
    VRMData.removeContent(data.id); // delete prev data
    yield put(updateLibraryDataCheck({ dataType: "VRM" })); // update current pdn library data error check
  }
}

function* saveComponents(action) {
  const { part, model, value, models } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: { pdnContent },
      },
    },
  } = yield select();
  let { Components } = pdnContent;
  Components.forEach((comp) => {
    if (comp.part === part) {
      if (comp.COMP_TYPE === "Cap") {
        comp.models = models ? [...models] : comp.models;
      } else {
        comp.model = model;
        comp.value = value;
      }
    }
  });
  yield put(updatePDNInterface({ Components }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
  yield delay(300);
  for (let model of (models || [])) {
    if (model.type !== "rlc") {
      continue
    }
    //vrm data error check
    DecapData.removeContent(model.id); // delete prev data
    yield put(updateLibraryDataCheck({ dataType: "decap" })); // update current pdn library data error check
  }
}

function* ignoreComponents(action) {
  const { components, usage, checked } = action;
  const {
    PDNReducer: {
      pdn: { pdnInfo },
    },
  } = yield select();
  let { pdnContent } = pdnInfo;
  let {
    Components,
    PowerNets,
    VRM,
    ReferenceNets,
    COMP_PREFIX_LIB,
    MAIN_POWER_NETS,
    MAIN_REF_NETS,
    includeExtended,
  } = pdnContent;
  const voltage = VRM && VRM[0] ? VRM[0].voltage : "";
  const prevComponents = JSON.parse(JSON.stringify(Components))
  Components.forEach((comp) => {
    if (components.includes(comp.name)) {
      if (usage === "Removed") {
        let compItem = prevComponents.find(
          (item) =>
            item.part === comp.part &&
            item.COMP_TYPE === comp.COMP_TYPE &&
            item.usage !== "Removed"
        );
        if (compItem) {
          comp.models = compItem.models || [];
        } else {
          comp.models = [];
        }
      }
      comp.usage = checked ? comp.COMP_TYPE : "Unused";
    }
  });
  if (usage === "Removed") {
    // re find VRM
    const pcbId = pdnInfo.designId;
    let findVRMComps = [],
      findCaps = [];
    // Automatically find VRM
    //components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
    const pwrNets =
      !includeExtended && MAIN_POWER_NETS && MAIN_POWER_NETS.length > 0
        ? MAIN_POWER_NETS
        : PowerNets;
    const gndNets =
      !includeExtended && MAIN_REF_NETS && MAIN_REF_NETS.length > 0
        ? MAIN_REF_NETS
        : ReferenceNets;
    let newComponents = Components;
    //find components,Caps,VRMComps by new pwrNets and new gndNets
    const compMainInfo = getNewComponents({
      _Components: Components,
      ReferenceNets: gndNets,
      PowerNets: pwrNets,
      pcbId,
      COMP_PREFIX_LIB,
    });
    newComponents = compMainInfo.Components;
    findVRMComps = compMainInfo.findVRMComps;
    findCaps = compMainInfo.findCaps;
    const getVRM = yield call(_getVRMs, {
      Components: newComponents,
      ReferenceNets: gndNets,
      PowerNets: pwrNets,
      findVRMComps,
      findCaps,
      findExtend: false,
    });
    const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
    VRM = getVRM.VRM;
    VRM.forEach((vrm) => (vrm.voltage = voltage));
    // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
    const prefixCheck = prefixCheckByVRM(VRM);
    Components = getVRM.Components;
    // update SPIM library id
    Components = yield call(_updateChip, { Components: Components });

    // update prefix Check prompt
    yield put(updatePrefixDisplay(prefixCheck));

    if (DEBUG_MONITOR.length > 0) {
      yield put(debugMonitorAction(DEBUG_MONITOR));
    }
  }
  yield put(
    updatePDNInterface({
      Components,
      VRM,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* updatePKGINPDN(action) {
  const { packageInfo } = action;
  const { PDNReducer } = yield select();
  const pdn = PDNReducer.pdn;
  if (!pdn) return;
  const pdnInfo = pdn.pdnInfo;
  if (!pdnInfo) return;
  let { Components } = pdnInfo.pdnContent;
  const { model, libraryId, chip } = packageInfo;

  for (let comp of Components) {
    if (comp.usage === "Chip" && comp.name !== chip) {
      comp.usage = "Ignore";
      if (comp.pkg) {
        delete comp.pkg;
      }
    }

    if (comp.name === chip) {
      comp.pkg = {
        type: "Intel_SPIM",
        name: model,
        id: libraryId,
      };
      comp.usage = "Chip";
    }
  }
  // Sort by usage Chip, Decap, Res, Ind, Ignore.
  const sort = ["Chip", "Cap", "Res", "Ind", "Ignore"];
  Components = SortFn(Components, sort, "usage");
  // Sorting components by component name
  const _SortComponents = Components.sort(function (a, b) {
    return SortComponent(a, b);
  });
  yield put(
    updatePDNInterface({
      Components: _SortComponents,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* saveConfig(action) {
  // Close Advanced panel
  let { Config, optionType } = action;
  const { PDNReducer: { pdn } } = yield select();
  if (!pdn || !pdn.pdnInfo || !pdn.pdnInfo.pdnContent) {
    return;
  }
  const content = pdn.pdnInfo.pdnContent;
  const designId = pdn.pdnInfo.designId;

  let prevConfig = { ...content.Config };
  if (Config) {
    let REFRIndex = Config.REFR.toString().indexOf("+");

    if (REFRIndex > -1) {
      Config.REFR = Config.REFR.toString().replace("+", "");
    }
    if (Config.SOLVER === "SIwave") {
      Config.DC = 1; //value is always 1 in version v0.1.4

      let maxIndex = Config.FMAX.toString().indexOf("+");

      if (maxIndex > -1) {
        Config.FMAX = Config.FMAX.toString().replace("+", "");
      }
    }
  } else {
    Config = new ExtractionOptions({ SOLVER: getDefaultSolverByDesignVendor(designId) || "SIwave" });
  }
  //Simulate value in Simulate panel
  if (optionType !== "Simulate") {
    //Simulate save
    Config.VR_FSW = prevConfig.VR_FSW;
    Config.TGT = prevConfig.TGT;
  }

  if (Config.SOLVER === "SIwave") {
    Config.DCF = 1;
    Config.SIWE = getFastPISIWEMode();
    Config.couplingMinFreq = getFastPICouplingMinFreq();
  }

  // No need for re-launch extraction key: VR_FSW(deleted)  DCF

  //value in Simulate panel donot need to reExtraction
  // VR_FSW: VRM Switching Frequency Fsw , couplingMinFreq:Intra-coupling Valid Frequency Lower Bound, TGT:target for the simulation and optimization,DCF
  // version v0.1.20

  // If the fields or values in the Object are inconsistent
  // version v0.1.4
  yield put(
    updatePDNInterface({
      Config: { ...Config },
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* findVRMModel(action) {
  const { vrmId, getVRM } = action;
  let {
    PDNReducer: {
      project: { VRMNames, treeItems },
    },
  } = yield select();
  const findVRM = VRMNames.find((item) => item.id === vrmId);
  let VRMName = "";
  if (findVRM) {
    VRMName = findVRM.name;
  } else {
    const res = yield call(getLibraryList);
    // treeItems[0] - 'library'
    let VRMNames = [];
    let newData = [...treeItems];
    const treeIndex = 0,
      VRMIndex = 1;
    newData[treeIndex].children[VRMIndex].children = [];
    if (res.VRM && res.VRM.length > 0) {
      res.VRM.forEach((item) => {
        const VRMItem = getLibraryDataList({
          item: item.libraryStructure,
          id: item.id,
          type: "VRM",
          name: item.name,
        });
        newData[treeIndex].children[VRMIndex].children.push(VRMItem);
        VRMNames.push(VRMItem);
      });
    }
    yield put(libraryMenu({ treeItems: newData, VRMNames }));
    const findVRM = VRMNames.find((item) => item.id === vrmId);
    if (findVRM) {
      VRMName = findVRM.name;
    }
  }
  const vrm = {
    name: VRMName,
    libraryId: vrmId,
  };
  if (!getVRM) {
    yield put({ type: DefaultVRMModelInSPIM, defaultVRM: vrm });
  } else {
    return vrm;
  }
}

function* saveOptimiation(action) {
  let optObject = new OptOptions(action);
  yield put(
    updatePDNInterface({
      Optimization: { ...optObject },
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* saveSplitComponents(action) {
  const { part, splitPart, comps } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: { pdnContent },
      },
    },
  } = yield select();
  let { Components } = pdnContent;
  const partList = Components.map((item) => item.part);
  let compNames = comps.map((item) => item.name);
  if (splitPart && !partList.includes(splitPart)) {
    Components.forEach((comp) => {
      if (comp.part === part && compNames.includes(comp.name)) {
        comp.part = splitPart;
      }
    });
  } else if (splitPart && partList.includes(splitPart)) {
    let currentSplit = Components.filter((item) => item.part === splitPart);
    Components.forEach((comp) => {
      if (comp.part === splitPart && compNames.includes(comp.name)) {
        comps.part = splitPart;
        if (currentSplit[0].models) {
          comps.models = currentSplit[0].models;
        } else {
          comps.model = currentSplit[0].model;
          comps.value = currentSplit[0].value;
          comps.modelName = currentSplit[0].modelName;
        }
      }
    });
  }
  yield put(
    updatePDNInterface({
      Components,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* saveMergeComponents(action) {
  const { part, partList } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: { pdnContent },
      },
    },
  } = yield select();
  let { Components } = pdnContent;
  let current = Components.filter((item) => item.part === part);
  Components.forEach((comp) => {
    if (partList.includes(comp.part)) {
      comp.part = part;
      if (comp.COMP_TYPE === "Cap" || current[0].models) {
        comp.models = current[0].models || [];
      } else {
        comp.value = current[0].value;
        comp.model = current[0].model;
      }
    }
  });
  yield put(
    updatePDNInterface({
      Components,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* changeComponentUsage(action) {
  const { chips, part, usage } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: { pdnContent },
      },
    },
  } = yield select();
  let { Components } = pdnContent;
  Components.forEach((comp) => {
    if (comp.part === part) {
      if (chips.includes(comp.name)) {
        comp.usage = usage;
        if (usage === "Cap") {
          comp.models = [];
          delete comp.model;
          delete comp.value;
        } else if (usage === "Res" || usage === "Ind") {
          comp.value = "";
          delete comp.model;
        }
      }
    }
  });
  yield put(
    updatePDNInterface({
      Components,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* saveCurrentPdnToServer(action) {
  const { verificationIds, verificationId } = action;
  //save current pdn content
  const response = yield call(savePDNContentToServer);
  if (response) {
    //check verification status
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        return;
      }
    }
    yield put(updateStimulationMessage(verificationId));
    yield put(startPDNVerification(verificationIds));
  }
}

function* saveCurrentPdnToServerBySetting(action) {
  const {
    PDNReducer: {
      project: { verificationId },
      pdn: { pdnInfo },
    },
  } = yield select();
  if (verificationId && pdnInfo && pdnInfo.pdnContent) {
    const {
      pdnContent: { Config },
    } = pdnInfo;
    if (Config) {
      const siwe = getFastPISIWEMode();
      const couplingMinFreq = getFastPICouplingMinFreq();
      if (
        Config.SOLVER === "SIwave" &&
        (siwe !== Config.SIWE || couplingMinFreq !== Config.couplingMinFreq)
      ) {
        yield put({ type: SAVE_CONFIG, Config });
      }
    }
  }
}

function* pdnDebugVerify(action) {
  const { step, verificationId } = action;
  //save current pdn content
  const response = yield call(savePDNContentToServer);
  if (response) {
    yield put(debugVerify(step, verificationId));
  }
}

function* savePGAConnectionName(action) {
  // 0.1.5
  const { name } = action;
  yield put(
    updatePDNInterface({
      BGAConnName: name,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* updatePkgComponent(action) {
  const { name } = action;
  yield put(
    updatePDNInterface({
      packageComponent: name,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* updateCompRLCPrefixList(action) {
  const { prefixList } = action;
  const {
    PDNReducer: {
      pdn: { pdnInfo },
    },
  } = yield select();
  const { pdnContent } = pdnInfo;
  let {
    Components,
    VRM,
    includeExtended,
    MAIN_POWER_NETS,
    MAIN_REF_NETS,
  } = pdnContent;
  let ReferenceNets = [...pdnContent.ReferenceNets];
  let PowerNets = [...pdnContent.PowerNets];
  const pcbId = pdnInfo.designId;

  //0.1.6 update component type prefix library.
  const COMP_PREFIX_LIB = { ...prefixList };

  // 0.1.6  update component type by custom component reference designator.
  let newComponents = [],
    exsitcomponentsName = [],
    findVRMComps = [],
    findCaps = [];
  const _Components = getComponentsWithNetList(
    [...ReferenceNets, ...PowerNets],
    pcbId,
    COMP_PREFIX_LIB
  );
  for (const comp of _Components) {
    const { name, pin, net, value, type, part } = comp;
    const _index = exsitcomponentsName.indexOf(name);
    if (_index < 0) {
      // Not exsit component
      let newComp;
      if (type === "Cap") {
        newComp = new CapComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net }],
        });
        newComp.models = [];
        let existComp = Components.find((item) => item.name === name);
        if (existComp && existComp.usage === type) {
          newComp.models = existComp.models || [];
        }

        if (existComp && existComp.usage === "Unused") {
          newComp.usage = "Unused";
          newComp.models = existComp.models || [];
        }

        if (existComp && existComp.usage === "Removed") {
          newComp.usage = "Removed";
          newComp.models = [];
        }

        findCaps.push({ ...newComp, location: comp.location });
      } else if (type === "Res" || type === "Ind") {
        let _value = checkRLCValue(value);
        if (type === "Res") {
          //resistor default value to 0 that is smaller than 10 mOhm
          _value = resetDefaultValue(_value);
        }
        newComp = new RLComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net }],
          value: _value,
        });
        let existComp = Components.find((item) => item.name === name);
        if (existComp && existComp.usage === type) {
          newComp.value = existComp.value;
        }

        if (existComp && existComp.usage === "Unused") {
          newComp.usage = "Unused";
          newComp.value = existComp.value;
        }
        findVRMComps.push({ ...newComp, location: comp.location });
      } else {
        newComp = new BasicComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net }],
        });
      }
      newComponents.push(newComp);
      // Update components name list
      exsitcomponentsName.push(name);
    } else {
      // Exsit component
      newComponents[_index].pins.push({ pin, net });
    }
  }

  // Components filter
  newComponents = componentFilter({
    Components: newComponents,
    ReferenceNets,
    PowerNets,
  });
  // Chip

  // Automatically find VRM
  // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
  const pwrNets =
    !includeExtended && MAIN_POWER_NETS && MAIN_POWER_NETS.length > 0
      ? MAIN_POWER_NETS
      : PowerNets;
  const gndNets =
    !includeExtended && MAIN_REF_NETS && MAIN_REF_NETS.length > 0
      ? MAIN_REF_NETS
      : ReferenceNets;
  let _newComponents = newComponents;
  //find components,Caps,VRMComps by new pwrNets and new gndNets
  const compMainInfo = getNewComponents({
    _Components: newComponents,
    ReferenceNets: gndNets,
    PowerNets: pwrNets,
    pcbId,
    COMP_PREFIX_LIB,
  });
  _newComponents = compMainInfo.Components;
  findVRMComps = compMainInfo.findVRMComps;
  let vrmCompInfo = getPDNComponentsByNets({
    ReferenceNets,
    PowerNets: [],
    pcbId,
    COMP_PREFIX_LIB,
  });
  findCaps = vrmCompInfo.findCaps;
  const getVRM = yield call(_getVRMs, {
    Components: _newComponents,
    ReferenceNets: gndNets,
    PowerNets: pwrNets,
    findVRMComps,
    findCaps,
    COMP_PREFIX_LIB,
  });

  //save VRM Components VRM_COMPS
  let vrms = getVRM && getVRM.vrms ? getVRM.vrms : [];
  const vrmComps = vrms.map((item) => item.vrmComp);
  const VRM_COMPS = [...getVRMCOMPS(vrmComps)];

  const { referenceComps } = getReferenceComps({
    ReferenceNets,
    pcbId,
    COMP_PREFIX_LIB,
    VRM_COMPS,
    findCaps,
  });
  yield put(updateCapsConnectRefNet(referenceComps));

  const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
  VRM = getVRM.VRM;
  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(VRM);
  // Update power nets
  PowerNets = getVRM.PowerNets;
  newComponents = getVRM.Components;

  //  update SPIM library id
  newComponents = yield call(_updateChip, { Components: newComponents });
  // Sort by usage Chip, Decap, Res, Ind, Ignore.
  const sort = ["Chip", "Cap", "Res", "Ind", "Ignore"];
  newComponents = SortFn(newComponents, sort, "usage");
  // Sorting components by component name
  const _SortComponents = newComponents.sort(function (a, b) {
    return SortComponent(a, b);
  });

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }

  yield put(
    updatePDNInterface({
      PowerNets,
      ReferenceNets,
      Components: _SortComponents,
      VRM,
      COMP_PREFIX_LIB,
      VRM_COMPS,
    })
  );
  // update prefix Check prompt
  yield put(updatePrefixDisplay(prefixCheck));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function getVRMCOMPS(vrmComps) {
  let _vrmComps = [];
  vrmComps.forEach((item) => {
    if (Array.isArray(item)) {
      _vrmComps.push(...item);
    } else {
      _vrmComps.push(item);
    }
  });
  return _vrmComps;
}

function* getVRMComponent() {
  const {
    PDNReducer: {
      pdn: { pdnInfo },
    },
  } = yield select();
  const { pdnContent } = pdnInfo;
  let {
    COMP_PREFIX_LIB,
    Components,
    VRM,
    MAIN_POWER_NETS,
    MAIN_REF_NETS,
    includeExtended,
  } = pdnContent;
  let ReferenceNets = [...pdnContent.ReferenceNets];
  let PowerNets = [...pdnContent.PowerNets];
  const pcbId = pdnInfo.designId;

  let newComponents = [],
    exsitcomponentsName = [],
    findVRMComps = [],
    findCaps = [];
  const _Components = getComponentsWithNetList(
    [...ReferenceNets, ...PowerNets],
    pcbId,
    COMP_PREFIX_LIB
  );
  for (const comp of _Components) {
    const { name, pin, net, value, type, part } = comp;
    const _index = exsitcomponentsName.indexOf(name);
    if (_index < 0) {
      // Not exsit component
      let newComp;
      if (type === "Cap") {
        newComp = new CapComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net }],
        });
        newComp.models = [];
        let existComp = Components.find(
          (item) => item.name === name && item.usage === type
        );
        if (existComp) {
          newComp.models = existComp.models || [];
        }
        findCaps.push({ ...newComp, location: comp.location });
      } else if (type === "Res" || type === "Ind") {
        let _value = checkRLCValue(value);
        if (type === "Res") {
          //resistor default value to 0 that is smaller than 10 mOhm
          _value = resetDefaultValue(_value);
        }
        newComp = new RLComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net }],
          value: _value,
        });
        let existComp = Components.find(
          (item) => item.name === name && item.usage === type
        );
        if (existComp) {
          newComp.value = existComp.value;
        }
        findVRMComps.push({ ...newComp, location: comp.location });
      } else {
        newComp = new BasicComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net }],
        });
      }
      newComponents.push(newComp);
      // Update components name list
      exsitcomponentsName.push(name);
    } else {
      // Exsit component
      newComponents[_index].pins.push({ pin, net });
    }
  }

  // Components filter
  newComponents = componentFilter({
    Components: newComponents,
    ReferenceNets,
    PowerNets,
  });
  // Automatically find VRM
  // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
  const pwrNets =
    !includeExtended && MAIN_POWER_NETS && MAIN_POWER_NETS.length > 0
      ? MAIN_POWER_NETS
      : PowerNets;
  const gndNets =
    !includeExtended && MAIN_REF_NETS && MAIN_REF_NETS.length > 0
      ? MAIN_REF_NETS
      : ReferenceNets;
  let _newComponents = newComponents;
  ///find components,Caps,VRMComps by new pwrNets and new gndNets
  const compMainInfo = getNewComponents({
    _Components: newComponents,
    ReferenceNets: gndNets,
    PowerNets: pwrNets,
    pcbId,
    COMP_PREFIX_LIB,
  });
  _newComponents = compMainInfo.Components;
  findVRMComps = compMainInfo.findVRMComps;
  findCaps = compMainInfo.findCaps;
  const getVRM = yield call(_getVRMs, {
    Components: _newComponents,
    ReferenceNets: gndNets,
    PowerNets: pwrNets,
    findVRMComps,
    findCaps,
    findExtend: false,
  });

  const DEBUG_MONITOR =
    getVRM && getVRM.DEBUG_MONITOR ? getVRM.DEBUG_MONITOR : "";
  const vrms = getVRM && getVRM.vrms ? getVRM.vrms : {};

  VRM.forEach((vrm) => {
    if (vrms && vrms.length) {
      const find = vrms.find(
        (item) => vrm.powerPin[0] && item.pwr === vrm.powerPin[0].comp
      );
      if (find) {
        vrm.VRM_COMP = find.vrmComp;
      } else {
        vrm.VRM_COMP = "";
      }
    } else {
      vrm.VRM_COMP = "";
    }
  });
  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }

  yield put(
    updatePDNInterface({
      VRM,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* changeDecapRemoved(action) {
  //setup version 0.1.9
  const { removes } = action;
  const {
    PDNReducer: {
      pdn: { pdnInfo },
    },
  } = yield select();
  let { pdnContent } = pdnInfo;
  let {
    Components,
    PowerNets,
    VRM,
    ReferenceNets,
    COMP_PREFIX_LIB,
    MAIN_POWER_NETS,
    MAIN_REF_NETS,
    includeExtended,
  } = pdnContent;
  const voltage = VRM && VRM[0] ? VRM[0].voltage : "";
  Components.forEach((comp) => {
    if (removes.includes(comp.name)) {
      comp.usage = "Removed"; //setup version 0.1.9
      comp.models = [];
    }
  });
  // re find VRM
  let findVRMComps = [],
    findCaps = [];
  const pcbId = pdnInfo.designId;
  // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
  // Automatically find VRM
  const pwrNets =
    !includeExtended && MAIN_POWER_NETS && MAIN_POWER_NETS.length > 0
      ? MAIN_POWER_NETS
      : PowerNets;
  const gndNets =
    !includeExtended && MAIN_REF_NETS && MAIN_REF_NETS.length > 0
      ? MAIN_REF_NETS
      : ReferenceNets;
  let _newComponents = Components;
  //find components,Caps,VRMComps by new pwrNets and new gndNets
  const compMainInfo = getNewComponents({
    _Components: Components,
    ReferenceNets: gndNets,
    PowerNets: pwrNets,
    pcbId,
    COMP_PREFIX_LIB,
  });
  _newComponents = compMainInfo.Components;
  findVRMComps = compMainInfo.findVRMComps;
  findCaps = compMainInfo.findCaps;
  const getVRM = yield call(_getVRMs, {
    Components: _newComponents,
    ReferenceNets: gndNets,
    PowerNets: pwrNets,
    findVRMComps,
    findCaps,
    findExtend: false,
  });
  const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
  VRM = getVRM.VRM;
  VRM.forEach((vrm) => (vrm.voltage = voltage));
  // If VRM is not recognized, we need to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(VRM);

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }

  // update prefix Check prompt
  yield put(updatePrefixDisplay(prefixCheck));
  yield put(
    updatePDNInterface({
      Components,
      VRM,
    })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* _savePDNContent(action) {
  //update current pdn content and save to server
  const { data } = action;
  yield put(updatePDNInterface(data));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* _updateIncludeExtended(action) {
  const { includeExtended } = action;
  const {
    PDNReducer: {
      pdn: { pdnInfo },
      project: { defaultDecap, DecapNames },
    },
  } = yield select();
  const { pdnContent } = pdnInfo;
  let {
    COMP_PREFIX_LIB,
    MAIN_POWER_NETS,
    MAIN_REF_NETS,
    VRM,
    PowerNets,
    ReferenceNets,
    specVRM
  } = pdnContent;

  if (specVRM && specVRM.length) {
    yield call(traceBackVRM, { vrms: specVRM });
    return;
  }

  const voltage = VRM && VRM[0] ? VRM[0].voltage : "";
  const pcbId = pdnInfo.designId;
  const PwrNets =
    !includeExtended && MAIN_POWER_NETS && MAIN_POWER_NETS.length > 0
      ? MAIN_POWER_NETS
      : PowerNets;
  const RefNets =
    !includeExtended && MAIN_REF_NETS && MAIN_REF_NETS.length > 0
      ? MAIN_REF_NETS
      : ReferenceNets;
  let _Components = pdnContent.Components;
  //find components,Caps,VRMComps by new pwrNets and new gndNets
  let { Components, findVRMComps, findCaps } = getNewComponents({
    _Components,
    ReferenceNets: RefNets,
    PowerNets: PwrNets,
    pcbId,
    COMP_PREFIX_LIB,
  });
  //get new VRM by main pwrNets and main gndNets and includeExtended
  // return;
  const getVRM = yield call(_getVRMs, {
    VRM: [],
    Components,
    ReferenceNets: RefNets,
    PowerNets: PwrNets,
    findVRMComps,
    findCaps,
    findExtend: includeExtended,
  });
  const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
  const new_VRM = getVRM.VRM;
  const new_PowerNets = getVRM.PowerNets;
  let newComponents = getVRM.Components;

  //  update SPIM library id
  try {
    newComponents = yield call(_updateChip, { Components: newComponents });
  } catch (error) {
    console.error(`Update Component Error: ${error}`)
  }

  // auto find decap
  let new_Components = [...newComponents];
  try {
    new_Components = autoFindDecap(newComponents, defaultDecap, DecapNames, voltage)
  } catch (error) {
    console.error(`Auto Find Decap Error: ${error}`)
  }

  // Sort by usage Chip, Decap, Res, Ind, Ignore.
  const sort = ["Chip", "Cap", "Res", "Ind", "Ignore"];
  new_Components = SortFn(new_Components, sort, "usage");

  // Sorting components by component name
  const _SortComponents = new_Components.sort(function (a, b) {
    return SortComponent(a, b);
  });

  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(new_VRM);
  //Add VRM model to new VRM
  const model =
    VRM && VRM.length > 0 && VRM[0].model ? VRM[0].model : { id: "", name: "" };
  new_VRM.forEach((item) => {
    item.model = { ...model };
    item.voltage = voltage;
  });

  yield put(
    updatePDNInterface({
      includeExtended,
      VRM: new_VRM,
      PowerNets: new_PowerNets,
      ReferenceNets: RefNets,
      Components: _SortComponents,
    })
  );
  // update prefix Check prompt
  yield put(updatePrefixDisplay(prefixCheck));

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }
  //update vrm table loading
  yield put(findVRMLoading(false));
  yield put(findPowerDomainLoading(false));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function getNewComponents({
  _Components,
  ReferenceNets,
  PowerNets,
  pcbId,
  COMP_PREFIX_LIB,
}) {
  let newComponents = JSON.parse(JSON.stringify(_Components));
  //find components,Caps,VRMComps by ReferenceNets and PowerNets
  let { Components, findCaps } = getPDNComponentsByNets({
    ReferenceNets,
    PowerNets,
    pcbId,
    COMP_PREFIX_LIB,
  });
  //find vrmComps,caps by new find Components and pdn content Components
  let findVRMComps = []; /* , findCaps = [] */
  newComponents.forEach((comp) => {
    //find comp in NEW Components
    const find = Components.find((item) => item.name === comp.name);

    if (find && (comp.COMP_TYPE === "Res" || comp.COMP_TYPE === "Ind")) {
      let CompsInfo = getLayoutComponents({ pcbId, COMP_PREFIX_LIB });
      const compInfo = CompsInfo[comp.name];
      let { location } = compInfo;
      findVRMComps.push({ ...comp, usage: comp.COMP_TYPE, location });
    }

    //remove cap when usage is Removed
    if (find && comp.COMP_TYPE === "Cap" && comp.usage !== "Removed") {
      /*  let CompsInfo = getLayoutComponents({ pcbId, COMP_PREFIX_LIB }); */
      /*  const compInfo = CompsInfo[comp.name]; */
      /* let { location } = compInfo; */
      // findCaps.push({ ...comp, usage: comp.COMP_TYPE, location });
    }
  });
  return { Components: newComponents, findVRMComps, findCaps };
}

function* _saveMainNetsToServer(action) {
  const { pwrNets, gndNets } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: {
          pdnContent: { PowerNets, ReferenceNets },
        },
      },
    },
  } = yield select();
  // check nets
  yield put({
    type: UPDATE_CHECK_NETS,
    error: checkNetsError(pwrNets, gndNets, PowerNets, ReferenceNets),
  });
  yield put(
    updatePDNInterface({ MAIN_POWER_NETS: pwrNets, MAIN_REF_NETS: gndNets })
  );
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

// check Power Nets and Reference Nets is error
function* checkNets(action) {
  const { pwrNets, gndNets } = action;
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: {
          pdnContent: { PowerNets, ReferenceNets },
        },
      },
    },
  } = yield select();
  yield put({
    type: UPDATE_CHECK_NETS,
    error: checkNetsError(pwrNets, gndNets, PowerNets, ReferenceNets),
  });
}

function* autoCheckNets(action) {
  const { pwrNets, gndNets, pdnInfo } = action;
  const { PowerNets, ReferenceNets } = pdnInfo;
  if (pwrNets && gndNets && PowerNets && ReferenceNets) {
    yield put({
      type: UPDATE_CHECK_NETS,
      error: checkNetsError(pwrNets, gndNets, PowerNets, ReferenceNets),
    });
  }
}

function checkNetsError(pwrNets, gndNets, PowerNets, ReferenceNets) {
  let errorResult = {};
  // check power nets number and reference nets number, if both of these empty, empty tip will be display
  if (pwrNets.length >= 4) {
    errorResult.power = pwrNets.length;
  } else if (pwrNets.length === 0 && PowerNets.length === 0) {
    errorResult.power = 0;
  }
  if (gndNets.length >= 4) {
    errorResult.reference = gndNets.length;
  } else if (gndNets.length === 0 && ReferenceNets.length === 0) {
    errorResult.reference = 0;
  }
  // check repeat nets
  for (let item of pwrNets) {
    if (gndNets.includes(item)) {
      if (!Array.isArray(errorResult.repeat)) {
        errorResult.repeat = [];
      }
      errorResult.repeat.push(item);
    }
  }
  return errorResult;
}

function* createCopyDecap(action) {
  const {
    PDNReducer: {
      project: { currentProjectPDNs },
      pdn: { pdnInfo },
    },
  } = yield select();
  let _pdnInfo = {},
    incoming = false;
  if (action.Info) {
    _pdnInfo = action.Info;
    incoming = true;
  } else {
    _pdnInfo = pdnInfo;
  }
  if (_pdnInfo && _pdnInfo.pdnContent) {
    // save: Is there a component change
    let currentComponents = _pdnInfo.pdnContent.Components,
      save = false;
    // If there is more than one PDN
    let currentPDNs = currentProjectPDNs.filter(
      (item) => item.dataType === PDN_CREATE
    );
    if (currentPDNs && currentPDNs.length > 1) {
      yield* currentPDNs
        .filter((item) => item.id !== _pdnInfo.pdnContent.id)
        .map(function* (item) {
          try {
            const response = yield call(getPDNContentPromise, item.id);
            if (
              response.pdnContent &&
              Array.isArray(response.pdnContent.Components)
            ) {
              const { Components } = response.pdnContent;
              currentComponents.forEach((item) => {
                // find a item which the part is identical
                const otherItem = Components.find(
                  (_item) => _item.part === item.part
                );
                if (
                  otherItem &&
                  otherItem.models &&
                  otherItem.models.length &&
                  otherItem.models.find(it => !!it.model.name)
                  &&
                  (!item.models || !item.models.length)
                ) {
                  item.models = JSON.parse(JSON.stringify(otherItem.models));
                  save = true;
                }
              });
            }
          } catch (error) {
            console.error(error);
          }
        });
    }
    if (save && !incoming) {
      yield put(updatePDNInterface({ Components: currentComponents }));
      yield put({ type: SAVE_CONFIG_TO_SERVER });
    } else if (save && incoming) {
      yield call(savePDNConfigByAutoCreate, { pdnInfo: _pdnInfo });
    }
  }
  yield put({ type: CREATE_COPY_DECAP_END });
}

function* getNets(action) {
  const { netList, PDN, name, selectType } = action;
  const { pdnInfo, pkgInfo } = PDN;
  const libraryId = pkgInfo ? pkgInfo.libraryId : "";
  pdnInfo.pdnContent.includeExtended = false;
  if (libraryId) {
    const chip = pkgInfo ? pkgInfo.chip : "";
    // getVRMModel

    let response = [];
    try {
      response = yield call(getListVRMModel, name, libraryId);
    } catch (error) {
      console.error(error);
    }
    if (response && response[0] && response[0].userLibraryId) {
      pdnInfo.userLibraryId = response[0].userLibraryId;
    }

    // Get ckt connections
    let data = null;
    try {
      data = yield call(getPowerDomainNets, libraryId, name);
    } catch (error) {
      console.error(error);
      yield put(removeCurrentPreparingPDN(pdnInfo.PDNID));
    }
    if (data) {
      let packageComponent = null;
      try {
        packageComponent = yield call(getPowerDomainRNetwork, libraryId, name);
      } catch (error) {
        console.error(error);
      }
      const { connections } = data;
      const { pwrNets, gndNets, BGAConnectionName } = getPowerReferenceNets(
        connections,
        netList,
        chip
      );
      pdnInfo.pdnContent.BGAConnName = BGAConnectionName;
      if (packageComponent) {
        const { connections } = packageComponent;
        const {
          BGAConnectionName: packageComponentName,
        } = getPowerReferenceNets(connections, netList, chip);
        pdnInfo.pdnContent.packageComponent = packageComponentName;
      }
      const powerNets = [
        { powerType: "Reference", nets: gndNets },
        { powerType: "Power", nets: pwrNets },
      ];
      if (selectType === "update") {
        yield call(updateSelectNets, { powerNets, first: true, pdnInfo });
      } else {
        yield call(autoSelectNets, { powerNets, first: true, pdnInfo });
      }
      yield call(autoCheckNets, { pwrNets, gndNets, pdnInfo });
    }
  }
  yield put(removeCurrentPreparingPDN(pdnInfo.PDNID));
}

function* updateSimulationConfig(action) {
  const {
    PDNReducer: {
      pdn: {
        pdnInfo: { pdnContent },
      },
    },
  } = yield select();
  const { checkObj } = action;
  const SimConfig = { ...pdnContent.SimConfig, ...checkObj };
  yield put(
    updatePDNInterface({
      SimConfig,
    })
  );

  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* traceBackVRM(action) {
  const { vrms = [] } = action;

  const { PDNReducer: { pdn: { pdnInfo: { pdnContent, designId }, defaultVRM }, project } } = yield select();
  const { COMP_PREFIX_LIB, ReferenceNets = [], includeExtended, Components, VRM, MAIN_POWER_NETS = [], MAIN_REF_NETS = [], PowerNets } = pdnContent;

  if (!vrms.length || !MAIN_POWER_NETS.length || !MAIN_REF_NETS.length) {
    console.error(`No Power Nets or Ground Nets or VRMs`);
    return;
  }

  const chipList =
    project.currentProjectPackages &&
    project.currentProjectPackages.map((item) => item.chip);
  const { defaultDecap, DecapNames } = project;
  const voltage = VRM && VRM[0] ? VRM[0].voltage || "1" : "1";

  const traceObj = {
    pcbId: designId,
    defaultVRM,
    COMP_PREFIX_LIB: COMP_PREFIX_LIB || new CompRLCPrefixLib(),
    PowerNets: MAIN_POWER_NETS.length ? MAIN_POWER_NETS : PowerNets,
    ReferenceNets: MAIN_REF_NETS.length ? MAIN_REF_NETS : ReferenceNets,
    includeExtended,
    chipList,
    specVRM: vrms,
    _Components: Components,
    getLayoutComponents,
    getPowerComponentsByNets: getPDNComponentsByNets,
    getReferenceComps,
  }

  yield put(findVRMLoading(true));
  yield put(findPowerDomainLoading(true));
  yield put(updatePDNInterface({ specVRM: vrms }));
  yield delay(1000);

  const data = getTraceByVRM(traceObj);

  const DEBUG_MONITOR = data.DEBUG_MONITOR;
  const new_VRM = data.VRM;
  const new_PowerNets = data.PowerNets;
  let newComponents = data.Components;

  //  update SPIM library id
  try {
    newComponents = yield call(_updateChip, { Components: newComponents });
  } catch (error) {
    console.error(`Update Component Error: ${error}`)
  }

  // auto find decap
  let new_Components = [...newComponents];
  try {
    new_Components = autoFindDecap(newComponents, defaultDecap, DecapNames, voltage)
  } catch (error) {
    console.error(`Auto Find Decap Error: ${error}`)
  }

  // Sort by usage Chip, Decap, Res, Ind, Ignore.
  const sort = ["Chip", "Cap", "Res", "Ind", "Ignore"];
  new_Components = SortFn(new_Components, sort, "usage");

  // Sorting components by component name
  const _SortComponents = new_Components.sort(function (a, b) {
    return SortComponent(a, b);
  });

  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(new_VRM);
  //Add VRM model to new VRM
  const model =
    VRM && VRM.length > 0 && VRM[0].model ? VRM[0].model : null;
  new_VRM.forEach((item) => {
    if(model) {
      item.model = { ...model };
    }
    item.voltage = voltage;
  });

  yield put(
    updatePDNInterface({
      VRM: new_VRM,
      PowerNets: new_PowerNets,
      ReferenceNets: ReferenceNets,
      Components: _SortComponents,
    })
  );
  // update prefix Check prompt
  yield put(updatePrefixDisplay(prefixCheck));

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }
  //update vrm table loading
  yield put(findVRMLoading(false));
  yield put(findPowerDomainLoading(false));

  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* _updatePDNsAfterReplacePCB(action) {
  const { pcbId } = action
  const { PDNReducer: { project: { currentProjectPDNs, currentProjectPackages } } } = yield select();
  const currentChip = currentProjectPackages && currentProjectPackages.length ? currentProjectPackages[0].chip : undefined
  if (!currentChip) {
    return
  }
  const componentsList = LayoutData.getComponentsList(pcbId)
  const isExit = componentsList.find(item => item.name === currentChip)
  // The loading style appears
  const prepareingIds = currentProjectPDNs.map((item) => {
    return item.id
  })
  yield put(updatePreparePDNS(prepareingIds))
  if (isExit) {
    yield* currentProjectPDNs.map(function* (item) {
      try {
        let newComponents = [],
          findVRMComps = [],
          findCaps = [];
        const pdn = yield call(getPDNContentPromise, item.id)
        if (pdn) {
          const { Components, ReferenceNets, PowerNets, COMP_PREFIX_LIB, includeExtended, MAIN_POWER_NETS, MAIN_REF_NETS, specVRM, VRM } = pdn.pdnContent
          // handleData: { Components, findVRMComps, findCaps }
          const handleData = getComponents(
            [...ReferenceNets, ...PowerNets],
            pcbId,
            COMP_PREFIX_LIB,
            FASTPI
          );
          // Components filter
          newComponents = componentFilter({
            Components: handleData.Components,
            ReferenceNets,
            PowerNets,
          });
          newComponents = yield call(_updateChip, { Components: newComponents })
          const pwrNets =
            !includeExtended && MAIN_POWER_NETS && MAIN_POWER_NETS.length > 0
              ? MAIN_POWER_NETS
              : PowerNets;
          const gndNets =
            !includeExtended && MAIN_REF_NETS && MAIN_REF_NETS.length > 0
              ? MAIN_REF_NETS
              : ReferenceNets;
          let _newComponents = newComponents
          const compMainInfo = getNewComponents({
            _Components: _newComponents,
            ReferenceNets: gndNets,
            PowerNets: pwrNets,
            pcbId,
            COMP_PREFIX_LIB
          });
          findVRMComps = compMainInfo.findVRMComps;
          findCaps = compMainInfo.findCaps;
          _newComponents = newComponents;
          let finalVRM = null;
          let finalPowerNets = null;
          let finalComponents = compareComponents(Components, newComponents)
          // If the specVRM is not null to execute the trace to get VRM
          if (specVRM && specVRM.length) {
            const defaultVRMName = VRM && VRM[0] ? VRM[0].model.name : ""
            const { libraryId } = pdn
            const traceObj = {
              pcbId,
              defaultVRM: { name: defaultVRMName, libraryId },
              COMP_PREFIX_LIB: COMP_PREFIX_LIB || new CompRLCPrefixLib(),
              PowerNets: MAIN_POWER_NETS.length ? MAIN_POWER_NETS : PowerNets,
              ReferenceNets: MAIN_REF_NETS.length ? MAIN_REF_NETS : ReferenceNets,
              includeExtended,
              chipList: [currentChip],
              specVRM,
              _Components: Components,
              getLayoutComponents,
              getPowerComponentsByNets: getPDNComponentsByNets,
              getReferenceComps,
            }
            // getVRM :{ DEBUG_MONITOR, VRM, PowerNets, Components, vrms }
            const getVRM = getTraceByVRM(traceObj);
            finalVRM = getVRM.VRM
            finalPowerNets = getVRM.PowerNets
          } else {
            // getVRM :{ DEBUG_MONITOR, VRM, PowerNets, Components, vrms }
            const getVRM = yield call(_getVRMs, {
              Components: _newComponents,
              findVRMComps,
              ReferenceNets,
              PowerNets,
              pcbId,
              findCaps,
              pdnInfo: pdn
            })
            finalVRM = getVRM.VRM
            finalPowerNets = getVRM.PowerNets
          }
          if (!finalPowerNets || finalPowerNets.length < 1) {
            finalComponents = []
            finalVRM = []
          }
          // save to server
          const { id, designId, pdnContent, projectId, verificationId, pdnVersion, libraryId } = pdn
          yield call(updatePDNPromise, {
            designId: designId,
            pdnContent: {
              ...pdnContent,
              Components: finalComponents,
              VRM: finalVRM,
              PowerNets: finalPowerNets
            },
            pdnId: id,
            pdnName: pdnContent.Name,
            projectId: projectId,
            verificationId: verificationId,
            pdnVersion: pdnVersion,
            libraryId: libraryId
          });
          yield put(removeCurrentPreparingPDN(id))
          const sort = ["Chip", "Cap", "Res", "Ind", "Ignore"];
          const new_Components = SortFn(finalComponents, sort, "usage");
          const _SortComponents = new_Components.sort(function (a, b) {
            return SortComponent(a, b);
          });
          if (yield call(isCurrentPDN, pdn.id)) {
            yield put(
              updatePDNInterface({
                ...pdnContent,
                Components: _SortComponents,
                VRM: finalVRM,
                PowerNets: finalPowerNets
              })
            )
            // yield put(updateCapsConnectRefNet(findCaps))
          }
        }
      } catch (error) {
        console.error(error);
      }
    })
  } else {
    // if old and new chip are different, delete package
    const currentPackageId = currentProjectPackages ? currentProjectPackages[0].id : undefined
    const { PDNReducer: { project: { currentProjectId, PDNID } } } = yield select();
    yield call(deletePackageChipPromise, [currentPackageId])
    yield put(updateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID }))
  }
}

function* pdnSaga() {
  yield takeLatest(SELECT_NETS, selectNets);
  yield takeLatest(CHECK_NETS, checkNets);
  yield takeLatest(GET_PDN_CONTENT, getPDNContent);
  yield takeEvery(VOLTAGE_CHANGE, voltageChange);
  yield takeEvery(SAVE_VRM, saveVRM);
  yield takeEvery(SAVE_COMPONENTS, saveComponents);
  yield takeEvery(IGNORE_COMPONENTS, ignoreComponents);
  yield takeEvery(UPDATE_PACKAGE_INFO_IN_PDN_CONTENT, updatePKGINPDN);
  yield takeEvery(SAVE_CONFIG, saveConfig);
  yield takeEvery(FIND_VRM_MODEL, findVRMModel);
  yield takeEvery(SAVE_OPTIMIZATION, saveOptimiation);
  yield takeEvery(SAVE_SPLIT_COMPONENTS, saveSplitComponents);
  yield takeEvery(SAVE_MERGE_COMPONENTS, saveMergeComponents);
  yield takeEvery(CHANGE_USAGE, changeComponentUsage);
  yield takeEvery(SAVE_CURRENT_PDN, saveCurrentPdnToServer);
  yield takeEvery(SAVE_CURRENT_PDN_BY_SETTING, saveCurrentPdnToServerBySetting);
  yield takeEvery(CURRENT_PDN_DEBUG_VERIFY, pdnDebugVerify);
  yield takeEvery(SAVE_BGA_CONNECTION_NAME, savePGAConnectionName);
  yield takeEvery(UPDATE_COMP_RLC_PREFIX, updateCompRLCPrefixList);
  yield takeLatest(GET_VRM_COMP, getVRMComponent);
  yield takeEvery(DECAP_REMOVED_CHANGE, changeDecapRemoved);
  yield takeEvery(SAVE_PDN_CONTENT, _savePDNContent);
  yield takeEvery(UPDATE_INCLUDE_EXTENDED, _updateIncludeExtended);
  yield takeEvery(SAVE_MAIN_NETS, _saveMainNetsToServer);
  yield takeEvery(CREATE_COPY_DECAP, createCopyDecap);
  yield takeEvery(AUTO_SELECT_NETS, autoSelectNets);
  yield takeEvery(AUTO_CHECK_NETS, autoCheckNets);
  yield takeEvery(UPDATE_SELECT_NETS, updateSelectNets);
  yield takeEvery(GET_NETS, getNets);
  yield takeEvery(SAVE_PACKAGE_COMPONENT, updatePkgComponent);
  yield takeEvery(UPDATE_SIM_CONFIG, updateSimulationConfig);
  yield takeEvery(TRACE_BACK_VRM, traceBackVRM)
  yield takeEvery(UPDATE_PDNS_AFTER_REPLACE_PCB, _updatePDNsAfterReplacePCB)
}

export default pdnSaga;
