import React, { Component, Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import TabFooter from '@/components/TabSwitchFooter';
import { getBodySize, updateContentHeight } from '@/services/helper/documentReSize';
import { getCCCMsg } from '../../pages/CCC/store/action';

class LayoutSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 250,
      linePosition: {
        position: "absolute",
        bottom: 250
      },
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount() {
    this.screenChange();
    const { height, linePosition } = getBodySize(this.props.pageType);
    this.setState({
      height: height,
      linePosition: { ...linePosition },
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const { height, linePosition } = getBodySize(this.props.pageType);
    this.setState({
      height: height,
      linePosition: { ...linePosition },
    })
    this.props.topBarResize && this.props.topBarResize();
  }

  changeTabFooterHeight = (height) => {
    let { bodyClientHeight } = getBodySize(this.props.pageType);
    height = updateContentHeight(height, 140);
    this.setState({
      linePosition: {
        position: "absolute",
        bottom: height + 1,
        minHeight: 20,
        maxHeight: bodyClientHeight - 140
      },
      height
    })
  }

  componentDidUpdate = (prevProps) => {
    const { tabVisible, tabSelectKeys } = this.props;
    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props._getCCCMsg();
    }
  }

  changeSize = (height) => {
    this.changeTabFooterHeight(height);
  }

  render() {
    const { linePosition, height } = this.state;
    const { id, tabVisible, tabSelectKeys, siderWidth, menuType, className = '' } = this.props;
    return (
      <Fragment>
        <div
          id={id}
          className={`my-aurora-content ${className}`}
          style={{
            bottom: tabVisible ? linePosition.bottom : 0
          }}
        >
          <Suspense fallback={null}>
            {this.props.getContent(height)}
          </Suspense>
        </div>
        {this.props.getTopMenuVisible() ? this.props.topBar() : null}
        {tabVisible && <div className='aurora-footer'>
          <TabFooter
            linePosition={linePosition}
            height={height}
          /*   changeHeight={this.changeTabFooterHeight} */
            closeTabFooter={this.props._closeTabFooter}
            changeSize={this.changeSize}
            leftWidth={siderWidth}
            changeTabMenu={this.props.changeMonitorTabMenu}
          >
            {this.props.MonitorChild(menuType, tabVisible, tabSelectKeys)}
          </TabFooter>
        </div>}
      </Fragment>
    )
  }
}

const mapDispatch = (dispatch) => ({
  _getCCCMsg() {
    dispatch(getCCCMsg())
  }
})

export default connect(null, mapDispatch)(LayoutSingle);