import { RX, TX } from ".";

/**
 * get pre layout aggressors by signal name
 *  */
function getPreLayoutAggressorsBySignal(ICComps, signal, signals) {
  if (!ICComps.length || !signal || !signals.length) {
    return []
  };

  let aggressors = [];
  const signalNameList = signal.name.split("_");
  let number = null,
    sameGroupPrevAgg = "",
    sameGroupNextAgg = "",
    otherGroupAgg = "";
  let prevNumber = 1, nextNumber = 1;
  if ([TX, RX].includes(signal.group)) {
    number = signal.name.match(/[0-9]+/);
    number = number ? number[0] : null;
    const otherGroup = signal.group === TX ? RX : (signal.group === RX ? TX : "");
    otherGroupAgg = `${otherGroup}${number}`;
  } else {
    number = signalNameList[signalNameList.length - 1].match(/[0-9]+/);
  }
  prevNumber = Number(number) - 1;
  nextNumber = Number(number) + 1;
  sameGroupPrevAgg = findNearbySignal({
    signals,
    groupName: signal.group,
    number: prevNumber,
    operation: "-"
  });
  sameGroupNextAgg = findNearbySignal({
    signals,
    groupName: signal.group,
    number: nextNumber,
    operation: "+"
  });

  for (let item of [sameGroupPrevAgg, sameGroupNextAgg, otherGroupAgg]) {
    if (item && signals.find(it => it.name === item)) {
      aggressors.push(item)
    }
  }

  return aggressors || [];
}

function findNearbySignal({ signals, groupName, number = 1, operation = "+" }) {
  const names = signals.map(item => item.name);
  number = Number(number);
  while (!names.includes(`${groupName}${Number(number)}`)) {
    if (operation === "+") {
      number += 1;
    } else {
      number -= 1;
    }

    if (number > names.length || number < 0) {
      break;
    }
  }
  const name = `${groupName}${Number(number)}`;

  return names.includes(name) ? name : "";
}

export {
  getPreLayoutAggressorsBySignal
}