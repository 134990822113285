import { getLayoutComponents } from "../PCBHelper";
import { PORT_REFERENCE_COMPONENT, PORT_REFERENCE_PIN, PORT_REFERENCE_PIN_GROUP, PORT_REFERENCE_POINT, PORT_REFERENCE_VIA } from "./portsSetup";

function getPortColumns() {
  let columns = [{
    title: 'Port Index',
    dataIndex: 'portIndex',
    key: 'portIndex',
    width: 120
  }, {
    title: 'Port Type',
    dataIndex: 'portType',
    key: 'portType',
    width: 100
  },/*{
    title: 'portGenerationUsage',
    dataIndex: 'portGenerationUsage',
    key: 'portGenerationUsage',
    width: 90
  }, */ {
    title: 'Component - Pin',
    dataIndex: 'pin',
    key: 'pin',
    width: 150
  },
  {
    title: 'Signal Net',
    dataIndex: 'net',
    key: 'net',
    width: 200
  },

  {
    title: 'Reference Pin',
    dataIndex: 'reference',
    key: 'reference'
  }/* ,
  {
    title: 'Edit',
    dataIndex: 'edit',
    key: 'edit',
    width: 50
  } */];
  return columns;
}

function getPortsTableData(port_setups) {
  let data = [], portIndex = 0;
  for (let i = 0; i < port_setups.length; i++) {
    const item = port_setups[i];
    let _portIndex = null;
    // if (item.portGenerationUsage === GENERATE_AUTO) {
    portIndex += 1;
    _portIndex = portIndex;
    // }
    data.push({
      portIndex: _portIndex,
      portGenerationUsage: item.portGenerationUsage,//[ Auto,Manual, Ignore]
      component: item.positive.component,
      pin: item.positive.pin,
      portType: item.type,
      reference: { ...item.negative },
      info: { ...item.info },
      net: item.info.net
    });
  }
  return data;
}

const
  GENERATE_AUTO = "Auto",
  GENERATE_MANUAL = "Manual",
  GENERATE_IGNORE = "Ignore",
  GAP = "gap",
  CIRCUIT = "circuit",
  PIN_GROUP = "pin_group",
  SINGLE_PIN = "pin",
  PIN_PER_REF_NET = "pinPerRefNet",
  NEARBY_PINS = "nearbyPins",
  ALL_PINS = "allPins",
  PIN = 'pin',
  WAVE = "wave",
  BALL_SIZE = "ball_size",
  BALL_HEIGHT = "ball_height",
  GAP_SIZE = "gap_size",
  BALL_MIDDLE = "ball_mid_diameter",
  CYLINDER = "Cylinder",
  SPHEROID = "Spheroid",
  BALL_TYPE_NONE = "None";
const PORT_GENERATION_USAGE_LIST = [GENERATE_AUTO, GENERATE_MANUAL, GENERATE_IGNORE],
  USE_BALL_LIST = [PIN_GROUP, CIRCUIT, GAP, WAVE],
  BALL_TYPE_NONE_LIST = [PIN_GROUP, CIRCUIT, GAP];

const REFERENCE_TYPE_LIST = [{
  key: SINGLE_PIN,
  name: "Single pin",
  /* disabled: GAP,
  disabledType: "HFSS" */
},
{
  key: PIN_PER_REF_NET,
  name: "Single pin per reference net",
  disabled: GAP,
},
{
  key: NEARBY_PINS,
  name: "Nearby pins",
  disabled: GAP,
},
{
  key: ALL_PINS,
  name: "All pins"
}];
const PORT_TYPE_LIST = [{
  key: PIN_GROUP,
  name: "Pin Group"
}, {
  key: GAP,
  name: "Gap"
}, {
  key: CIRCUIT,
  name: "Circuit"
},
{
  key: WAVE,
  name: "Wave"
}];

const NEW_WAVE_BALL_TYPE_LIST = [{
  key: BALL_TYPE_NONE,
  name: BALL_TYPE_NONE
}, {
  key: CYLINDER,
  name: CYLINDER
}, {
  key: SPHEROID,
  name: SPHEROID
}];

const WAVE_BALL_TYPE_LIST = [{
  key: CYLINDER,
  name: CYLINDER
}, {
  key: SPHEROID,
  name: SPHEROID
}];

/** 
 * @param {Object} data { type, ...}
 */
function getReferenceText(data, record, referenceNets) {
  let text = "";
  switch (data.type) {
    case PORT_REFERENCE_COMPONENT:
    case PORT_REFERENCE_PIN_GROUP:
      if (data.nets && data.nets.length) {
        text = "All pins on reference nets";
      } else {
        text = getPinsText({
          pins: data.pins,
          pinsInfo: data.PINS_INFO || null,
          record,
          referenceNets
        })
      }
      break;
    case PORT_REFERENCE_PIN:
      if (!data.pin) {
        break;
      }
      text = getPinsText({
        pins: [data.pin],
        pinsInfo: data.NET ? [{ component: data.component, net: data.NET, pin: data.pin }] : null,
        record,
        referenceNets
      });
      break;
    case PORT_REFERENCE_VIA:
      text = data.point_x || data.point_y ? `( ${data.point_x}, ${data.point_y} )` : "";
      break;
    case PORT_REFERENCE_POINT:
      text = "Point";
      break;
    default: break;
  }
  return text;
}

function getPinsText({ pins, pinsInfo, record, referenceNets }) {
  if (!pinsInfo) {
    return pins ? pins.join(", ") : "";
  }

  const multiRef = referenceNets && referenceNets.length > 1 ? true : false;
  const nets = [...new Set(pinsInfo.map(item => item.net))];
  const compNames = [...new Set(pinsInfo.map(item => item.component))];

  let text = "";
  if (compNames.length > 1 || compNames[0] !== record.component) {
    text = getCompPinsText({
      multiRef,
      compNames,
      pinsInfo,
      text
    });
  } else if (multiRef) {
    text = getNetPinsText({
      nets: nets,
      pins: pinsInfo,
      text
    });
  } else {
    text = pins ? pins.join(", ") : "";
  }
  return text;
}

function getCompPinsText({
  multiRef,
  compNames,
  pinsInfo,
  text
}) {
  if (multiRef) {
    for (let name of compNames) {
      const _pins = pinsInfo.filter(item => item.component === name);
      const _nets = [...new Set(_pins.map(item => item.net))];
      text = getNetPinsText({
        nets: _nets,
        pins: _pins,
        compName: name,
        text
      })
    }
  } else {
    const compName = compNames[0] || "";
    text = pinsInfo.length ? `${compName} (${pinsInfo.map(item => item.pin).join(", ")})` : ""
  }
  return text;
}

function getNetPinsText({
  nets,
  pins,
  compName,
  text
}) {
  if (nets.length > 1) {
    for (let net of nets) {
      const netPins = pins.filter(item => item.net === net);
      const prefix = compName ? `${compName} - ${net}` : net || "";
      const netText = netPins.length ? `${prefix} (${netPins.map(item => item.pin).join(", ")})` : "";
      text += text ? `, ${netText}` : netText;
    }
  } else {
    const prefix = compName ? `${compName} - ${nets[0]}` : nets[0] || "";
    text = pins.length ? `${prefix} (${pins.map(item => item.pin).join(", ")})` : ""
  }
  return text;
}

function getPCBComponentPinList({ layers }) {
  const CompsInfo = getLayoutComponents({ layers });

  let compList = [];
  for (let compName in CompsInfo) {
    const comp = CompsInfo[compName];
    compList.push({ ...comp });
  }
  return compList;
}

function getPortType(type) {
  const typeTitle = PORT_TYPE_LIST.find(item => item.key === type);
  return typeTitle ? typeTitle.name : type;
}

export {
  getPortsTableData,
  getPortColumns,
  PORT_GENERATION_USAGE_LIST,
  GENERATE_AUTO,
  GENERATE_MANUAL,
  GENERATE_IGNORE,
  GAP,
  CIRCUIT,
  PORT_TYPE_LIST,
  PIN_GROUP,
  REFERENCE_TYPE_LIST,
  SINGLE_PIN,
  PIN_PER_REF_NET,
  NEARBY_PINS,
  ALL_PINS,
  PIN,
  WAVE,
  BALL_SIZE,
  BALL_HEIGHT,
  GAP_SIZE,
  getReferenceText,
  getPCBComponentPinList,
  getPortType,
  WAVE_BALL_TYPE_LIST,
  BALL_MIDDLE,
  CYLINDER,
  SPHEROID,
  BALL_TYPE_NONE,
  USE_BALL_LIST,
  BALL_TYPE_NONE_LIST,
  NEW_WAVE_BALL_TYPE_LIST
}