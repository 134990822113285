import React, { Component, Fragment } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message, Divider } from 'antd';
import { updateDownloadStatus } from './action';
import { connect } from 'react-redux';
import { getInstallerReleasesPromise } from '../../services/api/installerDownload'
import './index.css';

class InstallerDownload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      installer: [],
      selectedVersion: '',
      selectedInstaller: {},
      showOldVersions: false,
    }
  }

  componentDidMount = async () => {
    const token = localStorage.getItem('token');
    const { flag, systemType, downloadType, fileName, selectedVersion, selectedType, _url } = this.props;
    let installerInformation = []
    try {
      installerInformation = await getInstallerReleasesPromise()
    } catch (error) {
      console.error(error)
    }
    if (installerInformation.length > 0) {
      const index = installerInformation.findIndex(item => item.is_latest === 'yes')
      this.setState({
        installer: installerInformation,
        selectedVersion: installerInformation[index].version,
        selectedInstaller: installerInformation[index]
      })
      if (installerInformation.length > 1) {
        this.setState({
          showOldVersions: true
        })
      }
    }
    if (flag && token !== null) {
      if (!selectedType && systemType === 'guide') {
        return window.open(_url);
      } else {
        const url = `/api/v3/installation/file/download?downloadType=${downloadType}&systemType=${systemType}&version=${selectedVersion}&access_token=${token}`
        this.getDownloadFiles(fileName, url);
      }
    }
  }

  downloadzip = ({ downloadType, _systemType, fileName, _url, type, selectedVersion }) => {
    const systemType = _systemType.toLowerCase();
    this.props.updateDownloadStatus({ flag: true, systemType, downloadType, fileName, selectedVersion, selectedType: type, _url });
    const token = localStorage.getItem('token');

    if (token === null) {
      message.warning("Please login first!");
      this.props.history.push('/login');
    } else if (systemType === 'guide') {
      const url = `/api/v3/installation/file/download?downloadType=${downloadType}&version=${selectedVersion}&access_token=${token}`
      if (type === 'download') {
        this.getDownloadFiles(fileName, url);
      } else {
        return /* window.open(_url) */;
      }
    } else {
      const url = `/api/v3/installation/file/download?downloadType=${downloadType}&version=${selectedVersion}&systemType=${systemType}&access_token=${token}`
      this.getDownloadFiles(fileName, url);
    }
  }

  getDownloadFiles = (fileName, url) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    message.success("Download successfully!");
    this.props.updateDownloadStatus({ flag: false, systemType: "", downloadType: '', fileName: '', selectedVersion: '', selectedType: '', _url: '' });
    document.body.removeChild(a);
    return;
  }

  getNoteList = (selectedInstaller) => {
    const { note } = selectedInstaller
    return (
      <div className='show-note-list'>
        <h3>Change log</h3>
        <div>
          <ul className='note-list'>
            {
              note ? note.map(item => (
                <li key={item} className='li-list'>{item}</li>
              )) : null
            }
          </ul>
        </div>
      </div>
    )
  }

  getOldVersionDownloadOption = ({ option, downloadType }) => {
    return (
      ["Windows", "Linux"].map(item => (
        <Fragment key={item}>
          <span
            className='span-content'
            onClick={() => this.downloadzip(
              {
                downloadType: downloadType === 'installer' ? 'installation' : 'installationService',
                _systemType: item.toLowerCase(),
                fileName: option[`${item.toLowerCase()}_${downloadType}_path`],
                _url: null,
                type: null,
                selectedVersion: option.version
              }
            )}
          >{item}</span>{item === 'Linux' ? null : <span>|</span>}
        </Fragment>
      ))
    )
  }

  render() {
    const { selectedInstaller, installer, selectedVersion, showOldVersions } = this.state
    return (
      <div className="load">
        <div className="download-content">
          <div className='installer'>
            <h2>
              Aurora Platform
              <div className='title-remark'>(Windows Native)</div>
            </h2>
            <div className='download-content-main'>
              <div className='download-window'>
                <div className='download-header'>
                  <img src='/lib/imgs/windows_48.svg' alt='' />
                  <div className='download-box'>
                    <Button
                      type='primary'
                      className='aurora-download-button aurora-download-version-button-single'
                    >
                      <a
                        href={'https://aurorasystem.sharepoint.com/:f:/s/ReleaseNote/EmbwEYoq11JKhRGdiwvEvrwBKdqRJu8wvVbBiHSybte7Yw'}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='a-link'
                      >
                        Download for Windows (Latest)
                      </a>
                    </Button>
                    <Button type='primary'
                      className='aurora-download-button aurora-download-version-button-second'>
                      <a
                        href={'https://aurorasystem.sharepoint.com/:f:/s/ReleaseNote/Et8yaANfBwhCiSqgit9cbIkBAK9mQw1Ejcb_APyjtU3DDA'}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='a-link'
                        style={{ fontSize: '12px'}}
                      >
                        Previous Versions
                      </a>
                    </Button>
                  </div>
                </div>
                <div className='note'>Windows 10 Pro / Enterprise (64-bit)</div>
              </div>
            </div>
          </div>
          <div className='installer'>
            <h2>
              Aurora Platform Installer
              <div className='title-remark'>(Powered by Docker)</div>
            </h2>
            <div className="download-content-main">
              <div className="download-window">
                <div className="download-header">
                  <img src='/lib/imgs/windows_48.svg' alt='' />
                  {
                    this.getDownloadButton({
                      downloadType: "installation",
                      systemType: "Windows",
                      fileName: selectedInstaller ? selectedInstaller.windows_installer_path : null,
                      selectedVersion
                    })
                  }
                </div>
                <div className='note'>Windows 10  Pro / Enterprise (64-bit)</div>
              </div>
              <div className="download-linux">
                <div className="download-header">
                  <img src='/lib/imgs/linux_48.svg' alt='' />
                  {
                    this.getDownloadButton({
                      downloadType: "installation",
                      systemType: "Linux",
                      fileName: selectedInstaller ? selectedInstaller.linux_installer_path : null,
                      selectedVersion
                    })
                  }
                </div>
                <div className='note'>Centos7 / Suse Enterprise 15 / Ubuntu 20+</div>
              </div>
            </div>
          </div>
          <div className='service installer'>
            <h2>
              Aurora Platform Service Installer
              <div className='title-remark'>(Powered by Docker)</div>
            </h2>
            <div className="download-content-main">
              <div className="download-window">
                <div className="download-header">
                  <img src='/lib/imgs/windows_48.svg' alt='' />
                  {
                    this.getDownloadButton({
                      downloadType: "installationService",
                      systemType: "Windows",
                      fileName: selectedInstaller ? selectedInstaller.windows_service_installer_path : null,
                      selectedVersion
                    })
                  }
                </div>
                <div className='note'>Windows 10  Pro / Enterprise (64-bit)</div>
              </div>
              <div className="download-linux">
                <div className="download-header">
                  <img src='/lib/imgs/linux_48.svg' alt='' />
                  {
                    this.getDownloadButton({
                      downloadType: "installationService",
                      systemType: "Linux",
                      fileName: selectedInstaller ? selectedInstaller.linux_service_installer_path : null,
                      selectedVersion
                    })
                  }
                </div>
                <div className='note'>Centos7 / Suse Enterprise 15 / Ubuntu 20+</div>
              </div>
            </div>
          </div>
          <div className="download-guide">
            {
              this.getDownloadButton({
                downloadType: "guide",
                systemType: "guide",
                fileName: selectedInstaller.doc,
                url: selectedInstaller ? selectedInstaller.doc : null,
                selectedVersion
              })
            }
          </div >
          <div className='version-note-list'>{this.getNoteList(selectedInstaller)}</div>
          {showOldVersions ?
            installer.map(item => {
              if (item.is_latest !== 'yes') {
                return (
                  <div className='show-old-version' key={item.version}>
                    <Divider />
                    <h2 className='title'>{`${item.version}`}</h2>
                    <div className='old-version installer-select'>
                      <h2>Aurora Platform Installer</h2>
                      {this.getOldVersionDownloadOption({ option: item, downloadType: 'installer' })}
                    </div>

                    <div className='old-version installer-service-select'>
                      <h2>Aurora Platform Service Installer</h2>
                      {this.getOldVersionDownloadOption({ option: item, downloadType: 'service_installer' })}
                    </div>
                    <div className='old-version installer-guide'>
                      <h2
                        className='documentation'
                        onClick={() => {
                          this.downloadzip({
                            downloadType: 'guide',
                            _systemType: 'guide',
                            fileName: item.doc,
                            _url: item.doc,
                            type: null,
                            selectedVersion: item.version
                          })
                        }}>
                        Aurora Platform Installation Documentation
                      </h2>
                      <DownloadOutlined
                        className='download-box-guide-icon'
                        onClick={() => this.downloadzip({
                          downloadType: 'guide',
                          _systemType: 'guide',
                          fileName: item.doc,
                          _url: null,
                          type: 'download',
                          selectedVersion: item.version
                        })} />
                    </div>
                    <div className='version-note-list'>{this.getNoteList(item)}</div>
                  </div>
                );
              }
              return null
            }) : null
          }
        </div>
      </div >
    );
  }

  getDownloadButton = ({ downloadType, systemType, fileName, url, type, selectedVersion }) => {
    return (
      <div className="download-box">
        <Button
          type="primary"
          onClick={() => this.downloadzip({ downloadType, _systemType: systemType, fileName, _url: url, type: null, selectedVersion })}
          className={systemType === 'guide' ? "aurora-download-button" : "aurora-download-button aurora-download-version-button"}>
          {systemType === 'guide' ? <h2>Aurora Platform Installation Documentation</h2> : <Fragment>Download for {systemType} <div>{`v${this.state.selectedVersion} (Latest)`}</div></Fragment>}
        </Button>
        <div
          className='aurora-download-icon'>
          {systemType === 'guide' && <DownloadOutlined
            className='download-box-guide-icon'
            onClick={() => this.downloadzip({ downloadType, _systemType: systemType, fileName, _url: null, type: 'download', selectedVersion })} />}
        </div>
      </div>
    );
  }
}


const mapState = (state) => {
  const { download, systemType, downloadType, fileName, selectedVersion, selectedType, _url } = state.DownloadReducer;
  return {
    flag: download,
    systemType,
    downloadType,
    fileName,
    selectedVersion,
    selectedType,
    _url
  }
}
const mapDispatch = (dispatch) => ({
  updateDownloadStatus({ flag, systemType, downloadType, fileName, selectedVersion, selectedType, _url }) {
    dispatch(updateDownloadStatus({ flag, systemType, downloadType, fileName, selectedVersion, selectedType, _url }))
  }
})

export default connect(mapState, mapDispatch)(InstallerDownload);