import { isEndToEndChildrenChannel } from "../helper/publicHelper";

class ChannelConstructor {
  constructor() {
    this.channelList = new Map(); //key - channel id, value :{ id ,name, designVersion , subId, designId .... }
  }

  getChannelList = () => {
    return this.channelList;
  }

  getChannelValues = (designId) => {
    //filter end to end children channels
    const _channelList = Array.from(this.channelList.values()).filter(item => !isEndToEndChildrenChannel(item));
    if (!designId) {
      return _channelList;
    } else {
      return _channelList.filter(item => item.designId === designId);
    }
  }

  getChannelByVerificationId(verificationId) {
    const _channelList = Array.from(this.channelList.values());
    return _channelList.find(item => item.verificationId === verificationId);
  }

  getChannel = (id) => {
    return this.channelList.get(id);
  }

  getList = (ids) => {
    return ids.map(id => this.channelList.get(id));
  }

  getChannelDesignId = (id) => {
    return this.channelList.get(id) ? this.channelList.get(id).designId : '';
  }

  addChannel = (id, data) => {
    this.channelList.set(id, data);
  }

  delChannel = (id) => {
    this.channelList.delete(id);
  }

  clearCache = () => {
    this.channelList = new Map();
  }

  deleteChannelByDesignId = (designId) => {
    let _channelList = Array.from(this.channelList.values());
    _channelList = _channelList.filter(item => item.designId === designId);
    for (let item of _channelList) {
      this.channelList.delete(item.id);
    }
  }
};

let channelConstructor = new ChannelConstructor();
export default channelConstructor;