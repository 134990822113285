import React from "react";
import { CloseOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip, TreeSelect } from "antd";
import { getFileContent, getLibraryFolderChildren } from "../../../../services/Andes_v2/library";
import { parseSpiceModelSelector } from "../../../../services/LibraryHelper/SpiceModelHelper";
const { Option } = Select;

/**
 * props:
 * @param {Object} file : {libraryId,fileName,subckt}
 * @param {Function} selectModelFile : (file) => {}
 * @param {Function} selectSubckt : (subckt) => {}
 * @param {Function} changeSpiceType : (type) => {}
 * @param {string} modelFileTitle : string
 * @param {string} spiceType : string
 * @param {Array} fileTypes : [] // ['spice','buffer_spice']
 */
class SPICEFileSelect extends React.Component {
  constructor() {
    super();
    this.state = {
      subcktList: [],
      notExist: false,
    };
  }

  componentDidMount() {
    // Determine whether components are uninstalled to prevent memory leaks
    this.isMountedFlag = true;
    const { file } = this.props;
    if (file.libraryId && file.fileName) {
      this.getSubcktList(file);
    }
  }

  componentDidUpdate(prevProps) {
    const { file } = this.props;
    if (prevProps.file && file && prevProps.file.libraryId !== file.libraryId) {
      this.getSubcktList(file);
    }
  }

  componentWillUnmount() {
    this.isMountedFlag = false;
  }

  getSubcktList = async (file) => {
    const res = await getFileContent(file.libraryId, file.fileName);
    if (!res) return
    const subcktList = parseSpiceModelSelector(res);
    if (this.isMountedFlag) {
      this.setState({ subcktList })
    }
  }

  selectModelFile = (key = "") => {
    const { fileTreeData, file: { libraryId } } = this.props;
    if (libraryId === key) return;
    // clear file
    if (!key) {
      this.props.selectModelFile({});
      return;
    }
    const file = (fileTreeData.find(item => item.value === key) || {}).fileInfo
    if (!file) return;
    const fileInfo = { libraryId: file.id, fileName: file.name, subckt: "", path: file.path };
    this.props.selectModelFile(fileInfo);
  }

  selectSubckt = (subcktName) => {
    const { subcktList } = this.state;
    const subckt = subcktList.find(item => item.name === subcktName) || {};
    this.props.selectSubckt(subckt);
  }

  onLoadData = async (treeNode) => {
    const { fileTreeData } = this.props;
    const { id, fileInfo: { fileType = "", type, rootId } } = treeNode.props;
    const isUpdate = !fileTreeData.find(item => item.pId === id);

    if (isUpdate && id && fileType.toLowerCase() === "folder") {
      const childrenRes = await getLibraryFolderChildren(id);
      if (childrenRes && childrenRes[type]) {
        const children = childrenRes[type].map(item => {
          const isFolder = item.fileType.toLowerCase() === "folder"
          return {
            id: item.id,
            pId: id,
            title: item.name,
            value: item.id,
            selectable: !isFolder,
            isLeaf: !isFolder,
            icon: isFolder ? "folder" : "file",
            fileInfo: { ...item, rootId }
          };
        });
        this.props.updateFileTreeData(children);
      }
    }
  }

  render() {
    const { modelFileTitle = "Model File", spiceType, file: { libraryId, subckt, fileName }, fileTreeData = [] } = this.props;
    const { subcktList } = this.state;
    let notExist = false;
    const file = fileTreeData.find(item => item.value === libraryId);
    if (libraryId && !file) {
      notExist = true;
    }
    return <div className='andes-v2-hspice-simulation-model-row'>
      <div className='andes-v2-hspice-simulation-model-row-item position-relative' >
        {/* File - subckt */}
        <span>
          {modelFileTitle}
          {spiceType === 'share' ? <PlusCircleOutlined
            title='Add Model File'
            className="andes-v2-hspice-simulation-icon andes-v2-hspice-simulation-rx-add-icon"
            onClick={() => this.props.changeSpiceType('only')}
          /> : null}
          {notExist ? <Tooltip
            title="The file was not found, please choose again"
            overlayClassName='aurora-tooltip'
          >
            <QuestionCircleOutlined className="andes-v2-hspice-simulation-file-not-exist-icon" />
          </Tooltip> : null}
        </span>
        <TreeSelect
          treeDataSimpleMode
          allowClear
          className={`andes-v2-hspice-simulation-model-select ${notExist ? "andes-v2-hspice-simulation-file-not-exist" : ""}`}
          popupClassName="aurora-select-dropdown andes-v2-hspice-simulation-model-select-dropdown"
          value={notExist ? fileName : libraryId}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          onChange={this.selectModelFile}
          onSelect={this.selectModelFile}
          treeData={fileTreeData}
          loadData={this.onLoadData}
          popupMatchSelectWidth={false}
          virtual={false}
        />
      </div>
      <div className='andes-v2-hspice-simulation-model-row-item'>
        <span className="andes-v2-hspice-simulation-model-subckt-label">Subckt</span>
        <Select
          value={subckt}
          onChange={(key) => this.selectSubckt(key)}
          allowClear
          className='andes-v2-hspice-simulation-model-select andes-v2-hspice-simulation-model-subckt-select'
          popupClassName="aurora-select-dropdown"
          getPopupContainer={() => document.getElementById('root')}
        >
          {subcktList.map(item => <Option
            key={item.name}
            value={item.name}
          >{item.name}</Option>)}
        </Select>
      </div>
      {spiceType === 'only' ? <CloseOutlined
        title="Delete Model File"
        className="andes-v2-hspice-simulation-icon"
        onClick={() => this.props.changeSpiceType('share')}
      /> : null}
    </div>
  }
}

export default SPICEFileSelect;