import { createVerification, getVerificationContentByTypeName, deleteVerification } from '../api/v2/verificationCtrl';
import { createInterfaceByTypeName, updateInterfaceInPCB, deleteInterfaceInPCBByTypeName } from '../api/v2/interfaceCtrl';
import checkError from '../api/checkError';
import { SUCCESS } from '../../constants/returnCode';
import { checkRLCValue } from '../helper/dataProcess';
import { ANDES_SETUP_VERSION } from '../../version';
import { BasicComponent } from './Component';
import { checkCompsType, IGNORE } from '../PCBHelper';

const AndesSetupVersion = '1.0.0';

/**
 * Get Andes project by project ID
 * 2020.01.14
 *
 * @export
 * @param {String} projectId Project ID
 * @returns Promise
 */
export function getAndesProjectContentPromise(projectId) {
  return new Promise((resolve, reject) => {

  });
};

export function createVerificationPromise({ name, projectId }) {
  return new Promise((resolve, reject) => {
    createVerification({ id: "", name, projectId, typeName: "Andes" }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  })
};

export function getVerificationContentPromise(verificationId) {
  return new Promise((resolve, reject) => {
    getVerificationContentByTypeName(verificationId, 'Andes').then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  })
}

export function createInterfacePromise({ projectId, designId, verificationId, verificationName, content, interfaceName }) {
  return new Promise((resolve, reject) => {
    createInterfaceByTypeName({
      projectId,
      designId,
      type: 'Andes',
      verificationId,
      verificationName,
      content,
      interfaceName,
      version: AndesSetupVersion,
      readyForSim: "0",
      typeName: 'Andes'
    }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  })
};

export function updateInterfacePromise({ designId, interfaceId, interfaceName, projectId, verificationId, verificationName, content, readyForSim, designVersion }) {
  return new Promise((resolve, reject) => {
    updateInterfaceInPCB({
      designId,
      interfaceId,
      interfaceName,
      projectId,
      type: 'Andes',
      verificationId,
      verificationName,
      content,
      readyForSim: readyForSim,
      version: ANDES_SETUP_VERSION,
      designVersion
    }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        reject(res);
      }
    }, error => {
      console.error(error);
      checkError(error);
      reject(error);
    })
  })
};

export function autoFilterSignalNets(netList) {
  let nets = [];
  for (let net of netList) {
    if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
      continue;
    }
    // Remove v, +v, N+V NONE, GND, via length check
    // if (net.mName.match(/^(v|\+v|NONET|GND)/i)) {
    //   continue;
    // };
    // if (net.mGeomList.length > 0) {
    //   const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
    //   const typeList = geomList.map(item => item instanceof CeLine);
    //   if (typeList.includes(true)) {
    //     continue;
    //   }
    // }
    // if (net.mViaList.length > 10) {
    //   continue;
    // }
    nets.push(net.mName)
  };
  return nets;
};

function getPinList(netName, netList) {
  if (!netName || !netList) return [];
  const netObj = netList.find(item => item.mName === netName);
  // netObj.mPinList - mLayerName, mCompName, mPinNum, mMetalLayerName
  return netObj.mPinList;
}

function getLayoutComponents(layers) {
  let compList = [];
  let layoutCompList = {};
  layers.forEach(item => {
    if (item.mComponentLayer !== null) {
      compList.push(item.mComponentLayer);
    }
  });

  compList.forEach(layer => {
    let comps = layer.mComponents;
    if (comps) {
      for (let component of comps) {
        layoutCompList[component.mName] = { comp: component };
      }
    }
  });

  //update the component information
  for (var compName in layoutCompList) {
    var compInfo = layoutCompList[compName];
    compInfo.name = compName;
    compInfo.part = compInfo.comp.mPart.mInfo.mPartName;
    compInfo.value = compInfo.comp.mPart.mInfo.mPhyProps.getValue('value');
    compInfo.pinLength = compInfo.comp.mPart.mPinList.length;

    if (compInfo.comp.mPart.mInfo.mPartType &&
      compInfo.comp.mPart.mInfo.mPartType.length > 0) {
      compInfo.type = compInfo.comp.mPart.mInfo.mPartType;
      var type = compInfo.type.toUpperCase();
      if (type === 'R' || type === 'RES' || type === 'RESISTOR') {
        compInfo.type = 'Res';
      }
      else if (type === 'C' || type === 'CAP' || type === 'CAPACITOR') {
        compInfo.type = 'Cap';
      }
      else if (type === 'L' || type === 'IND' || type === 'INDUCTOR') {
        compInfo.type = 'Ind';
      }
      else {
        compInfo.type = 'Unused';
        // compInfo.type = compInfo.comp.mPart.mInfo.mPartType;
      }
    } else {
      if (!compName.match(/^([A-Za-z]+)(([0-9]+)|-|_)/g)) {
        compInfo.type = 'Unused';
      } else {
        compInfo.type = checkComponentsType(compName);
      }
    }
    delete compInfo.comp;
  }
  return layoutCompList;
};

const CTRL = 'Controller', Device = 'Device', CON = 'Connector';
const RLCType = ['Res', 'Ind', 'Cap'];

// const test = ['C1', 'c_1', 'c_f', 'C4D9', 'R5F54', 'L576',
//   'C123P_A_1', 'RA06_20', 'RL_20', 'VR789IA_16', 'R06_02', 'SML123_01'];
// console.log(test.map(item => checkComponentsType(item)))
// TODO, check components type: component name, pin number, part name
export function checkComponentsType(compName) {
  if (toString.call(compName) !== '[object String]') {
    return "Unused";
  }
  let type = checkCompsType(compName);

  if (type === IGNORE) {
    type = "Unused";
    const name = compName.toString();
    const words = name.split(/^([A-Za-z]+)(([0-9]+)|-|_)/g);
    if (words.length < 2) {
      return type;
    }
    if (!words[1]) {
      return type;
    }
    switch (words[1].toLowerCase()) {
      case "u":
        type = CTRL;
        break;
      case "j":
      case "k":
        type = Device;
        break;
      default:
        type = 'Unused';
        break;
    };
  }
  return type;
}

export function autoFindPowerGNDNets(netList) {
  let nets = [];
  for (let net of netList) {
    if (!isPowerGND(net)) {
      continue;
    } else {
      nets.push(net.mName);
    }
  };
  return nets;
}

/**
 * Delete verifications by verificationIds
 * 2020.01.14
 *
 * @export
 * @param {Array} pdnIds
 * @returns Promise
 */
export function delVerification(verificationIds) {
  return new Promise((resolve, reject) => {
    deleteVerification(verificationIds).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
};


export function getComponentsWithNetList({ netList, pcbNetsList, layers, pcbId }) {
  let CompsInfo = null;
  const _netList = [...new Set([...netList])];
  /// TODO
  CompsInfo = getLayoutComponents(layers);
  let components = []; // [{pin,name,net,value,type}]
  _netList.forEach(netName => {
    const pinList = getPinList(netName, pcbNetsList);
    const list = pinList.map(pin => {
      const compInfo = CompsInfo[pin.mCompName];
      let _compInfo = compInfo;
      if (!compInfo) {
        _compInfo = {
          type: "Unused",
          value: "",
          part: "UNKNOW"
        }
      }
      const { type, value, part } = _compInfo;
      return {
        pin: pin.mPinNum,
        name: pin.mCompName,
        net: netName,
        value: ((type === 'Res' || type === 'Ind' || type === 'Cap') && value) || "",
        type: type,
        part
      }
    });
    components.push(...list);
  });
  return { newComponents: components, CompsInfo };
};

function RLCCONComponent(param) {
  this.value = param.value || '';
  this.name = param.name;
  this.type = param.type;
  this.pins = param.pins;
}

export function getPowerComponents({ nets, pcbNetsList, layers, pcbId }) {
  let newPowerComps = [];
  const componentsList = getComponentsWithNetList({ netList: nets, pcbNetsList, layers, pcbId });
  let existComponentsName = [];
  for (const comp of componentsList) {
    const { pin, name, net, value, type, part } = comp;
    const _index = existComponentsName.indexOf(name);
    const _check = checkComponentsType(name);
    if (_check === Device || _check === CTRL || _check === CON) continue;
    if (_index < 0) {
      // Not exist component
      let newComp;
      if (RLCType.includes(type)) {
        let _value = checkRLCValue(value);
        if (type === 'Cap') {
          _value = { r: "0", l: "0", c: _value }
        }
        newComp = new RLCCONComponent({ name, type, value: _value, pins: [{ pin, net }] });
      } else {
        newComp = new RLCCONComponent({ name, type: "Ignore", value: "", pins: [{ pin, net }] });
      }
      newComp.part = part;
      newPowerComps.push(newComp);
      // Update newPowerComps name list
      existComponentsName.push(name);
    } else {
      // Exsit component
      newPowerComps[_index].pins.push({ pin, net });
    }
  };
  let Comps = [];
  for (const comp of newPowerComps) {
    if (comp.pins.length > 1) {
      const nets = comp.pins.map(item => item.net);
      if ([...new Set(nets)].length > 1) {
        Comps.push(comp);
      };
    };
  };
  return Comps;
}

/**
 * Delete interfaces
 * 2020.01.14
 *
 * @export
 * @param {*} interfaceIds
 * @returns
 */
export function deleteInterfacePromise(interfaceIds) {
  return new Promise((resolve, reject) => {
    deleteInterfaceInPCBByTypeName(interfaceIds, 'Andes').then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        reject(res);
      }
    }, error => {
      console.error(error);
      checkError(error);
      reject(error);
    })
  })
}

export function findPowerNetsByRes(comps, pcbNetsList) {
  let _comps = JSON.parse(JSON.stringify(comps));
  const filterNoneNets = pcbNetsList.filter(item => item.mName !== 'NONET');
  _comps.forEach(item => {
    const compName = item.name;
    const filterNets = item.pins.map(item => item.net);
    const nets = _getNetsListByComp({ compName, filterNets, pcbNetsList: filterNoneNets }).filter(net => isPowerGND(net));
    nets.forEach(info => {
      const find = info.mPinList.find(item => item.mCompName === compName);
      const pin = find.mPinNum;
      const net = info.mName;
      item.pins.push({ signal: "", pin, net })
    });
  });
  return { comps: _comps };
}

function _getNetsListByComp({ compName, filterNets, pcbNetsList }) {
  const netsList = pcbNetsList.filter(item =>
    !filterNets.includes(item.mName)
    && item.mName !== 'NONET');

  let arr = [];
  for (let net of netsList) {
    const components = net.mPinList.map(item => item.mCompName);
    for (let comp of components) {
      if (comp === compName) {
        arr.push(net)
      };
    }
  };
  return arr;
}

function isPowerGND(net) {
  if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
    return false;
  };
  if (net.mName.match(/^NONE/i)) {
    return false;
  }
  // if (net.mName.match(/^(v|\+v|GND|VCC)/i)) {
  //   return true;
  // };
  // if (net.mGeomList.length > 0) {
  // const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
  // const typeList = geomList.map(item => item instanceof CeLine);
  // if (typeList.includes(true)) {
  //   return true;
  // }
  // }
  // if (net.mViaList.length > 10) {
  //   return true;
  // }
  return true;
}

/**
 * 
 * @param {Object} -deletedComps :delete components
 *                 -components :components list
 *                 -signalName :signalName
 */
export function deleteCompsConnectWithNets({ signalName, deletedComps, components }) {
  for (let delComp of deletedComps) {
    const compIndex = components.findIndex(item => item.name === delComp.name);
    components[compIndex].pins = components[compIndex].pins.filter(item => !!item.signal);
    if (compIndex > -1 && components[compIndex].pins.length === 1) {
      // Delete component
      components.splice(compIndex, 1);
    } else if (compIndex > -1) {
      const pinModelIndex = components[compIndex].pins.findIndex(item =>
        item.signal === signalName && item.pin === delComp.pin && item.net === delComp.net
      );
      // Delete pin model
      components[compIndex].pins.splice(pinModelIndex, 1);
    }
  };
  return { components };
}

/**
 * @export {object} component
 * @param {*} { type, prevType, component } - type: present type, prevType: previous type, component: previout component
 */
export function componentTypeChange({ type, prevType, component }) {
  let newComp;
  if (RLCType.includes(type)) {
    let _value = '';
    // Cap 
    if (type === 'Cap') {
      _value = {
        r: "0",
        l: "0",
        c: "0"
      }
    }
    newComp = new RLCCONComponent({ name: component.name, type, value: _value });
    newComp.pins = component.pins;
  } else if (type === CTRL || type === Device) {
    newComp = new BasicComponent({ name: component.name, type });
    newComp.pins = component.pins;
  } else if (type === 'Unused') {
    if (RLCType.includes(type)) {
      let _value = '';
      // Cap 
      if (type === 'Cap') {
        _value = {
          r: "0",
          l: "0",
          c: "0"
        }
      }
      newComp = new RLCCONComponent({ name: component.name, type, value: _value });
    } else {
      newComp = new BasicComponent({ name: component.name, type });
    }
  }
  newComp.part = component.part || '';
  newComp.pins = component.pins || [];
  return { newComp };
}

export function checkExistNets(nets, netNamelist) {
  if (netNamelist.length > 0) {
    const checkNets = nets.filter(net => netNamelist.includes(net));
    return checkNets;
  }
  return [];
}