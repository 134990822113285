import React, { PureComponent, Fragment } from 'react';
import OperationLog from './operationLog';
import { CaretRightOutlined, CaretDownOutlined, CloseOutlined, LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './style.css';

class DownloadLog extends PureComponent {
  closeModal = () => {
    this.props._closeDebugDownloadMsg();
  }

  getIconType = (find, compress) => {
    // check close loading
    if (!find) {
      return CloseOutlined;
    } else if (find && compress === 'NaN') {
      return LoadingOutlined;
    } else if (find && compress === 'false') {
      return CloseOutlined;
    } else if (find && compress === 'true') {
      return CheckOutlined;
    } else {
      return CloseOutlined;
    }
  };

  getTitle = (find, compress, name) => {
    if (!find) {
      return `${name} can not be found.`;
    } else if (find && compress === 'NaN') {
      return 'Compressing...';
    } else if (find && compress === 'false') {
      return 'Compression failed.'
    } else if (find && compress === 'true') {
      return 'Compression completed.';
    }
  }

  titleClick = (index) => {
    const { debugDownloadMsg } = this.props;
    let _msg = JSON.parse(JSON.stringify(debugDownloadMsg));
    _msg[index].show = !_msg[index].show;
    this.props._updateDownloadMsg(_msg);
  }

  getfileType = (type) => {
    const str = type && type.replace(/^\S/, s => s.toUpperCase()) || '';
    return str;
  }

  render() {
    const { debugDownloadMsg, debugDownloadMsgShow } = this.props;
    return debugDownloadMsgShow ?
      <OperationLog
        title='Download'
        closeModal={this.closeModal}
        className='download-debug-panel'
      >
        <div className='download-debug-box'>
          {debugDownloadMsg && debugDownloadMsg.length > 0 && debugDownloadMsg.map((msgObj, index) => {
            const Icon = msgObj.show ? CaretDownOutlined : CaretRightOutlined
            return (
              <div key={index}>
                <div className='download-debug-ul-title' onClick={() => this.titleClick(index)}>
                  <Tooltip placement="topLeft" title={<Fragment>
                    <div><span className='font-bold pd-10'>Project</span>{msgObj.project}</div>
                    <div><span className='font-bold pd-10'>File</span>{msgObj.fileName}</div>
                  </Fragment>}
                    overlayClassName='aurora-tooltip'
                  >
                    <span><span className='font-bold pd-10'>File</span>{msgObj.fileName}</span>
                  </Tooltip>
                  <Icon className='debug-download-title-icon' />
                </div>
                <ul key={`ul_${index}`} style={{ display: msgObj.show ? "block" : "none" }}>
                  {msgObj.data.map((m, _index) => {
                    const Icon = this.getIconType(m.find, m.compress);
                    return <Tooltip title={<Fragment>
                      <div><span className='font-bold pd-10'>{this.getfileType(m.fileType)}</span>{m.name}</div>
                      <div><span className='font-bold pd-10'>Size</span>{m.size}</div>
                      <div><span className='font-bold pd-10'>Path</span>{m.path}</div>
                      <div><span className='font-bold pd-10'>Status</span>{this.getTitle(m.find, m.compress, m.name)}</div>
                    </Fragment>}
                      overlayClassName='aurora-tooltip'
                      placement="right"
                      key={`tool_${index}_${_index}`}
                    >
                      <li key={`li_${index}_${_index}`}>
                        <div>
                          <span className='debug-download-file-name'>{m.name}</span>
                          <span className='debug-download-size-name'>{m.size}</span>
                          <Icon className='debug-download-file-icon' />
                        </div>
                      </li>
                    </Tooltip>
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </OperationLog> : null;
  }
}

export default DownloadLog;