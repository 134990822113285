import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { SwapOutlined } from '@ant-design/icons';
import { Checkbox, Radio, Button } from 'antd';
import Panel from '../../../components/Panel';
import { SPICE } from '../../../constants/libraryConstants';
import SPICEFileSelect from './components/SPICEFileSelect';
import SubcktNodeSelect from './components/SubcktNodeSelect';
import {
  handleSelectSubckt,
  getCtleNodePairList,
  getNodeSelectOption,
  getSubcktByPairInfo,
  onlyModelTitle,
  handleChangeSpiceType,
  handleSelectFile,
  handleSelectNode,
  getModelKey,
  autoMatchText,
  autoMatchNode,
  getFileTreeData
} from '../../../services/Andes_v2/simulation';

class CtleModel extends Component {
  constructor() {
    super()
    this.state = {
      applyAll: false,
      fileTreeData: [],
      spiceType: 'only',
      nodeVisible: '',
      subcktList: [],
      model: {
        type: 'spice',
        files: [],
        pairs: []
      },
      autoMatchVisible: false,
      autoMatchType: "byName",
      swapMatchType: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  async componentDidMount() {
    const { model } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside, true);
    const { files } = model;
    const spiceType = files.length > 1 ? 'only' : 'share';
    this.setState({
      model: JSON.parse(JSON.stringify(model)),
      spiceType,
      fileTreeData: await getFileTreeData([SPICE], files),
    }, () => {
      this.isMountedFlag = true;
    })
  }

  componentWillUnmount() {
    this.isMountedFlag = false;
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  async componentDidUpdate(prevProps) {
    const { libraryStatus, model } = this.props;
    if (prevProps.libraryStatus !== libraryStatus) {
      this.setState({
        fileTreeData: await getFileTreeData([SPICE], model.files)
      })
    }
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { nodeVisible } = this.state;
    const PopoverRoot = document.getElementById(nodeVisible);

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || !PopoverRoot.contains(target)) {
      this.setState({ nodeVisible: '' });
    }
  }

  closeModal = () => {
    const { model, applyAll } = this.state;
    if (this.isMountedFlag) {
      this.props.closeModal(model, applyAll);
    }
  }

  changeSpiceType = (type) => {
    const { model, subcktList: prevSubcktList } = this.state;
    const { files, pairs, subcktList } = handleChangeSpiceType(model, type, prevSubcktList);
    this.setState({ spiceType: type, model: { ...model, files, pairs }, subcktList })
  }

  selectModelFile = (file, fileIndex) => {
    const { model } = this.state;
    const { files, pairs } = handleSelectFile('ctleModel', model, file, fileIndex);
    this.setState({ model: { ...model, files, pairs } });
  }

  selectSubckt = (subckt, modelKey, file) => {
    const { model, subcktList } = this.state;
    const { files, pairs } = handleSelectSubckt('ctleModel', model, subckt, modelKey, file);
    const _file = files.find(item => item.modelKey === modelKey) || {};
    this.setState({ model: { ...model, files, pairs }, subcktList: { ...subcktList, [modelKey]: { ...subckt, ..._file } } })
  }

  selectNode = (node, pairIndex, modelKey) => {
    const { model, spiceType } = this.state;
    const pairs = handleSelectNode(model, spiceType, node, pairIndex, modelKey);
    this.setState({ model: { ...model, pairs }, nodeVisible: '' })
  }

  changeModel = (type, value) => {
    const { model, modelSearchValue } = this.state;
    this.setState({
      model: { ...model, [type]: value },
      modelSearchValue: type === 'modelName' ? "" : modelSearchValue
    })
  }

  applyChange = (e) => {
    this.setState({ applyAll: e.target.checked })
  }

  clearNodeVisible = () => {
    this.setState({ nodeVisible: "" })
  }

  setNodeVisible = async (visible, pair, pairIndex) => {
    const { subcktList, model: { files }, spiceType } = this.state;
    const _modelKey = getModelKey({ spiceType, files, modelKey: pair.modelKey, pairIndex });
    const fileInfo = pair.node ? pair : files.length > 1 ? files.find(file => file.modelKey === _modelKey) : files[0];
    if (visible && !Object.keys(subcktList[_modelKey] || {}).length && fileInfo) {
      const subcktInfo = await getSubcktByPairInfo(fileInfo);
      this.setState({ subcktList: { ...subcktList, [_modelKey]: subcktInfo } });
    }
    this.setState({ nodeVisible: visible })
  }

  getNodeOption = (spiceType, sharePairs, onlyPairs, modelKey, pairIndex) => {
    const { subcktList, model: { files } } = this.state;
    const _modelKey = getModelKey({ spiceType, files, modelKey, pairIndex });
    const pairs = spiceType === 'only' ? onlyPairs : sharePairs;
    const subckt = subcktList[_modelKey];
    return getNodeSelectOption('ctleModel', pairs, subckt)
  }

  updateFileTreeData = (children) => {
    const { fileTreeData } = this.state;
    this.setState({ fileTreeData: fileTreeData.concat(children) })
  }

  fileSelectContent = () => {
    const { model: { files }, spiceType, fileTreeData } = this.state;
    return <>
      {files.length !== 0 ? files.map((file, index) => <div key={`${file.libraryId}-${index}` || `ctle-model-${index}`}>
        <SPICEFileSelect
          fileTreeData={fileTreeData}
          selectModelFile={(file) => this.selectModelFile(file, file.modelKey || index)}
          selectSubckt={(subckt) => this.selectSubckt(subckt, file.modelKey, file)}
          file={file}
          spiceType={spiceType}
          changeSpiceType={this.changeSpiceType}
          modelFileTitle={spiceType === 'only' ? onlyModelTitle[index] : "Model File"}
          updateFileTreeData={this.updateFileTreeData}
        />
      </div>) : <SPICEFileSelect
        fileTreeData={fileTreeData}
        selectModelFile={(file) => this.selectModelFile(file, -1)}
        selectSubckt={(subckt) => this.selectSubckt(subckt, -1)}
        file={{}}
        spiceType={spiceType}
        changeSpiceType={this.changeSpiceType}
        updateFileTreeData={this.updateFileTreeData}
      />}
    </>
  }

  changeAutoMatchType = (e) => {
    this.setState({ autoMatchType: e.target.value })
  }

  closeAutoMatchModal = async (isMatch) => {
    const { signal, controller, device } = this.props;
    const { autoMatchType, subcktList, spiceType, model, swapMatchType } = this.state;
    if (isMatch && autoMatchType !== "manual") {
      const isSwap = swapMatchType.includes(autoMatchType);
      const _subcktList = subcktList;
      for (const file of model.files) {
        if (!Object.keys(subcktList[file.modelKey] || {}).length) {
          const subcktInfo = await getSubcktByPairInfo(file);
          _subcktList[file.modelKey] = subcktInfo;
        }
      }
      const pairs = autoMatchNode({ signal, controller, device, model, subcktList: _subcktList, spiceType, autoMatchType, isSwap });
      this.setState({ model: { ...this.state.model, pairs } });
    }
    this.setState({ autoMatchVisible: false })
  }

  swapMatchType = (e, key) => {
    e.stopPropagation();
    e.preventDefault();
    const { swapMatchType } = this.state;
    const isExist = swapMatchType.find(item => item === key);
    if (isExist !== undefined) {
      this.setState({ swapMatchType: swapMatchType.filter(item => item !== key) })
    } else {
      this.setState({ swapMatchType: [...swapMatchType, key] })
    }
    return;
  }

  autoMatchPanel = () => {
    const { autoMatchType, swapMatchType } = this.state;
    const content = (
      <Panel
        position='panel-center-left'
        title="Ports Match"
        zIndex={2000}
        onCancel={() => this.setState({ autoMatchVisible: false })}
        width={600}
        draggable
      >
        <div className='andes-v2-hspice-simulation-model-content'>
          {this.fileSelectContent()}
          <div className='andes-v2-hspice-simulation-model-row'>
            <span className="andes-v2-hspice-simulation-model-subckt-label">Match Type</span>
            <div className="andes-v2-hspice-simulation-right-default-width">
              <Radio.Group onChange={this.changeAutoMatchType} value={autoMatchType} className="andes-v2-hspice-simulation-model-radio-group">
                {autoMatchText.map((item, listIndex) =>
                  <Radio value={listIndex} key={`auto-match-radio-${listIndex}`}>
                    <div className="flex-box position-absolute-left-10-top-0">
                      <div className="andes-v2-hspice-simulation-vLine-left">{swapMatchType.includes(listIndex) ? item.portRText : item.portLText}</div>
                      <div className="andes-v2-hspice-simulation-vLine"></div>
                      <div className="andes-v2-hspice-simulation-vLine-right">{swapMatchType.includes(listIndex) ? item.portLText : item.portRText}</div>
                      {item.isSwap ? <SwapOutlined onClick={(e) => this.swapMatchType(e, listIndex)} /> : null}
                    </div>
                  </Radio>)}
                <Radio className='radio-text' value="byName">By Name</Radio>
                <Radio className='radio-text' value="manual">Manual</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="automatic-footer-content">
            <Button key="ok" type="primary" onClick={() => this.closeAutoMatchModal(true)}>OK</Button>
            <Button key="cancel" style={{ marginLeft: 30 }} onClick={() => this.closeAutoMatchModal()} >Cancel</Button>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { applyAll, model: { type, pairs }, spiceType, nodeVisible, autoMatchVisible } = this.state;
    const { signal, controller, device } = this.props;
    const content = (
      <Panel
        position='panel-center-left'
        title='CTLE Model'
        zIndex={2000}
        onCancel={() => this.closeModal()}
        width={600}
        draggable
        minHeight={200}
      >
        <div className='andes-v2-hspice-simulation-model-content'>
          {/* model Type */}
          {/* <div className='andes-v2-hspice-simulation-flex'>
            <span className='andes-v2-hspice-simulation-title'>Model Type</span>
            <Radio.Group onChange={(e) => this.changeModel('type', e.target.value)} value={type}>
              <Radio value={None}>None</Radio>
              <Radio value={SPICE}>SPICE</Radio>
            </Radio.Group>
          </div> */}
          {type === SPICE ? <>
            {this.fileSelectContent()}
            {/* auto  */}
            <div className='andes-v2-hspice-simulation-auto-match'>
              <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.setState({ autoMatchVisible: true })}></span>
            </div>
            {/* modal - node */}
            <div className="andes-v2-hspice-simulation-ctle-model">
              {getCtleNodePairList(pairs, signal, controller, device).map((item, index) => (<div className='andes-v2-hspice-simulation-ctle-model-item andes-v2-hspice-simulation-ctle-item' key={`ctle-model-node-${index}`}>
                <SubcktNodeSelect
                  id={`ctle-model-subckt-node-popover-${item.left.pin}-${item.left.component}`}
                  // nodeWidth={100}
                  displayPin={item.left.pin}
                  nodeList={this.getNodeOption(spiceType, pairs, [item.left, item.right], item.modelKey, index)}
                  nodeValue={item.left.node}
                  selectNode={(node) => this.selectNode(node, item.left.pairIndex, item.modelKey)}
                  visible={nodeVisible === `ctle-model-subckt-node-popover-${item.left.pin}-${item.left.component}`}
                  clickNode={() => this.setNodeVisible(`ctle-model-subckt-node-popover-${item.left.pin}-${item.left.component}`, item.left, index)}
                  closeNodelSelect={() => this.clearNodeVisible()}
                />
                <SubcktNodeSelect
                  id={`ctle-model-subckt-node-popover-${item.right.pin}-${item.right.component}`}
                  // nodeWidth={100}
                  displayPin={item.right.pin}
                  nodeList={this.getNodeOption(spiceType, pairs, [item.left, item.right], item.modelKey, index)}
                  nodeValue={item.right.node}
                  selectNode={(node) => this.selectNode(node, item.right.pairIndex, item.modelKey)}
                  visible={nodeVisible === `ctle-model-subckt-node-popover-${item.right.pin}-${item.right.component}`}
                  clickNode={() => this.setNodeVisible(
                    `ctle-model-subckt-node-popover-${item.right.pin}-${item.right.component}`,
                    item.right,
                    spiceType === 'only' ? index : 0
                  )}
                  closeNodelSelect={() => this.clearNodeVisible()}
                />
                <hr />
              </div>
              ))}
            </div>
            {<div className="ads-model-apply-all-content">
              <span>Apply setting to all Signal</span>
              <Checkbox
                checked={applyAll}
                onChange={(e) => this.applyChange(e)}
              />
            </div>}
          </> : null}
          {autoMatchVisible ? this.autoMatchPanel() : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default CtleModel;