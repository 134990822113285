import {
  ROUGHNESS_NONE,
  HAMMERSTAD_JENSEN,
  HURAY,
  LOW_LOSS,
  MEDIUM_LOSS,
  HIGH_LOSS,
  NODULE_RADIUS,
  SURFACE_RATIO
} from './index';
import { HurayRoughness, HammerstadJensenRoughness } from './integratedRoughness';

function getRoughnessByType(type) {
  switch (type) {
    case ROUGHNESS_NONE:
      return { type };
    case HAMMERSTAD_JENSEN:
      return new HammerstadJensenRoughness({ type });
    case HURAY:
      return new HurayRoughness({ type });
    default:
      return { type: ROUGHNESS_NONE };
  }
}

function updateHurayRoughnessBySetting(roughness) {
  if (!roughness) {
    return roughness;
  }
  switch (roughness.setting) {
    case LOW_LOSS:
      roughness[NODULE_RADIUS] = "0.5um";
      roughness[SURFACE_RATIO] = "1";
      break;
    case MEDIUM_LOSS:
      roughness[NODULE_RADIUS] = "0.5um";
      roughness[SURFACE_RATIO] = "3";
      break;
    case HIGH_LOSS:
      roughness[NODULE_RADIUS] = "0.5um";
      roughness[SURFACE_RATIO] = "6";
      break;
    default: break;
  }
  return roughness;
}

export {
  getRoughnessByType,
  updateHurayRoughnessBySetting
}