import { BGA, DIE } from "../../../constants/componentType";
import { PKG_SPICE, PKG_TOUCHSTONE, PASSIVE_TOUCHSTONE, PASSIVE_SPICE, CONNECTOR_TOUCHSTONE, TOUCHSTONE, PCB_TOUCHSTONE, CABLE_TOUCHSTONE, SPICE, GENERIC_TOUCHSTONE } from "../../../constants/libraryConstants";
import { ANDES_V2 } from "../../../constants/pageType";
import designConstructor from "../../helper/designConstructor";
import { getModelFileExist, getPassiveFileExist } from "../../helper/fileExistCheck";
import { SortFn } from "../../helper/sort";
import {
  CAP, CMC, CONNECTOR, DIODE, IC, IGNORE, IND, RES, SERDES_TYPES,
  RLC_TYPES, CMC_COMMENT, RLC_TYPES_LIST, checkCompsType, getComponentByName, getComponentNeedInfoByName
} from "../../PCBHelper";
import { CPHY, HDMI, PCIE } from "../../PCBHelper/constants";
import libraryConstructor from "../library/libraryConstructor";
import { getDefaultCompType } from "./channelContentHelper";
import { PACKAGE as PCB_PACKAGE } from '../../../constants/designType';
import _LayoutData from "../../data/LayoutData";
import { channelAdvancedInputList } from "../constants";

function getChannelComponents(components) {
  if (!components || !components.length) {
    return [];
  }

  let compList = [], partList = [];
  const pkgSpiceList = libraryConstructor.getLibraryValues(PKG_SPICE),
    pkgTouchstoneList = libraryConstructor.getLibraryValues(PKG_TOUCHSTONE),
    passiveSpiceList = libraryConstructor.getLibraryValues(PASSIVE_SPICE),
    passiveTouchstoneList = libraryConstructor.getLibraryValues(PASSIVE_TOUCHSTONE),
    connectorTouchstoneList = libraryConstructor.getLibraryValues(CONNECTOR_TOUCHSTONE),
    pcbTouchstoneList = libraryConstructor.getLibraryValues(PCB_TOUCHSTONE),
    cableTouchstoneList = libraryConstructor.getLibraryValues(CABLE_TOUCHSTONE),
    genericSpiceList = libraryConstructor.getLibraryValues(SPICE),
    genericTouchstoneList = libraryConstructor.getLibraryValues(GENERIC_TOUCHSTONE);

  for (let comp of components) {
    const { part, type, name, pins, model, pkg, connectorModel, pinsConnection, pcbModel, packageModel, cableModel, modelList, dieModel } = comp;
    if (partList.includes(part)) {
      //according to type and part separate comp.
      const _findIndex = compList.findIndex(item => item.part === part && item.type === type && ![...SERDES_TYPES/* , CMC, DIODE */].includes(type));
      if (_findIndex > -1) {
        compList[_findIndex].comps.push({ name, pins, type });
      } else {
        const comElement = getChannelComponentsElement({
          pkgSpiceList, pkgTouchstoneList, passiveSpiceList, passiveTouchstoneList, connectorTouchstoneList, pcbTouchstoneList, cableTouchstoneList, genericTouchstoneList, genericSpiceList,
          part, type, name, pins, model, pkg, connectorModel, pinsConnection, pcbModel, packageModel, cableModel, modelList, dieModel
        });
        compList.push(comElement);
      }
    } else {
      partList.push(part);
      const comElement = getChannelComponentsElement({
        pkgSpiceList, pkgTouchstoneList, passiveSpiceList, passiveTouchstoneList, connectorTouchstoneList, pcbTouchstoneList, cableTouchstoneList, genericTouchstoneList, genericSpiceList,
        part, type, name, pins, model, pkg, connectorModel, pinsConnection, pcbModel, packageModel, cableModel, modelList, dieModel
      });
      compList.push(comElement);
    }
  }
  const sort = [IC, CONNECTOR, DIE, BGA, CMC, DIODE, CAP, IND, RES, IGNORE];
  compList = SortFn(compList, sort, 'type');
  return compList;
}

function getChannelComponentsElement({
  pkgSpiceList, pkgTouchstoneList, passiveSpiceList, passiveTouchstoneList, connectorTouchstoneList, pcbTouchstoneList, cableTouchstoneList, genericTouchstoneList, genericSpiceList,
  part, type, name, pins, model, pkg, connectorModel, pinsConnection, pcbModel, packageModel, cableModel, modelList, dieModel
}) {
  const packageFileNotExist = pkg && pkg.type ? getModelFileExist(pkg, { spiceList: pkgSpiceList, touchstoneList: pkgTouchstoneList }) : null;
  const passiveFileNotExist = pkgTouchstoneList && ["touchstone", "spice"].includes(model.type) ? getPassiveFileExist(model, { passiveSpiceList, passiveTouchstoneList }) : null;
  const connectorFileNotExist = connectorModel && connectorModel.type ? getModelFileExist(connectorModel, { touchstoneList: connectorTouchstoneList, spiceList: genericSpiceList }) : null;
  const cmcFileNotExist = type === CMC && model && model.files ? getModelFileExist({ ...model, type: TOUCHSTONE }, { touchstoneList: passiveTouchstoneList }) : null;
  const diodeFileNotExist = type === DIODE && model && model.id ? diodeModelCheck(model, passiveTouchstoneList) : null;
  const pcbModelFileNotExist = type === BGA && pcbModel && pcbModel.files ? getModelFileExist(pcbModel, { touchstoneList: pcbTouchstoneList, spiceList: genericSpiceList }) : null;
  const genericFileNotExist = type === DIE && dieModel && dieModel.files ? getModelFileExist(dieModel, { touchstoneList: genericTouchstoneList, spiceList: genericSpiceList }) : null;

  let packageModelFileNotExist = null, cableModelFileNotExist = null, modelListConnectorFileNotExist = null;
  if (modelList && modelList.length && type === BGA) {
    // Because the modelType is the same and the files are the same, the files of one of them are taken
    const _connectorModel = modelList.find(item => item.modelType === CONNECTOR)
    modelListConnectorFileNotExist = _connectorModel && _connectorModel.files ? getModelFileExist(_connectorModel, { touchstoneList: connectorTouchstoneList, spiceList: genericSpiceList }) : null;

    const _packageModel = modelList.find(item => item.modelType === "Package")
    packageModelFileNotExist = _packageModel && _packageModel.files ? getModelFileExist(_packageModel, { spiceList: pkgSpiceList, touchstoneList: pkgTouchstoneList }) : null;

    const _cableModel = modelList.find(item => item.modelType === "Cable")
    cableModelFileNotExist = _cableModel && _cableModel.files ? getModelFileExist(_cableModel, { touchstoneList: cableTouchstoneList, spiceList: genericSpiceList }) : null;
  }

  return {
    part,
    comps: [{ name, pins, type }],
    type,
    model,
    pkg,
    connectorModel,
    packageFileNotExist,
    passiveFileNotExist,
    connectorFileNotExist,
    cmcFileNotExist,
    cmcModel: model,
    pinsConnection,
    diodeModel: model,
    diodeFileNotExist,
    pcbModel,
    packageModel,
    cableModel,
    pcbModelFileNotExist,
    packageModelFileNotExist,
    cableModelFileNotExist,
    modelListConnectorFileNotExist,
    modelList,
    genericFileNotExist,
    dieModel
  }
}

function diodeModelCheck(library, touchstoneList) {
  if (touchstoneList.find(item => item.id === library.id)) {
    return false;
  } else if (touchstoneList.find(item => item.name === library.name)) {
    return { updateText: library.name };
  }
  return { deleteText: library.name };

}

function getSignalPinColumns(components, serdesType) {
  let ICComps = components.filter(item => SERDES_TYPES.includes(item.type));
  const isFixed = ICComps.length > 5 ? "left" : null;

  // sort component
  ICComps = SortFn(ICComps, SERDES_TYPES, 'type');
  const group = {
    title: 'Group',
    dataIndex: 'group',
    key: 'group',
    width: isFixed ? 100 : "10%",
    fixed: isFixed
  };
  let signalPinsColumns = [];
  if (![HDMI, CPHY].includes(serdesType)) {
    signalPinsColumns.push(group)
  }
  signalPinsColumns = [
    ...signalPinsColumns,
    {
      title: 'checkBox',
      dataIndex: 'check',
      key: 'check',
      width: 42,
      fixed: isFixed
    }, {
      title: 'Signal',
      dataIndex: 'signal',
      key: 'signal',
      width: isFixed ? 180 : (ICComps.length ? "22%" : "30%"),
      fixed: isFixed
    }, {
      title: 'Differential Nets',
      dataIndex: 'nets',
      key: 'nets',
      width: isFixed ? 210 : (ICComps.length ? "28%" : "50%"),
      fixed: isFixed
    }];

  for (let comp of ICComps) {
    signalPinsColumns.push({
      title: `${comp.name}`,
      dataIndex: `component_${comp.name}`,
      key: comp.name,
      /*   children: [
          {
            title: 'Pin',
            dataIndex: `pin_${comp.name}`,
            key: `pin_${comp.name}`
          },
          {
            title: 'Usage',
            dataIndex: `usage_${comp.name}`,
            key: `usage_${comp.name}`
          },
          {
            title: 'Model',
            dataIndex: `model_${comp.name}`,
            key: `model_${comp.name}`
          }] */
    })
  }
  return signalPinsColumns;
}

function splitSignalsByGroup(signals) {
  let groupSignals = [];
  for (let sig of signals) {
    const index = groupSignals.findIndex(it => it.group === sig.group);
    if (index > -1) {
      groupSignals[index].signals.push(sig);
      continue;
    }

    groupSignals.push({
      group: sig.group,
      signals: [sig]
    })
  }

  return groupSignals;
}

function getSignalPinsData(components, signals, selectedSignals = [], serdesType) {
  if (!components || !signals) {
    return [];
  }
  //get ic component pins [ {pin, net, usage, model, component }]
  //  const ICCompPins = getICCompPins(components);
  const ICCompPins = components.filter(item => SERDES_TYPES.includes(item.type));
  //split signals by group
  const groupSignals = splitSignalsByGroup(signals);
  //get signal pins data list
  let signalPins = [];
  for (let index = 0; index < groupSignals.length; index++) {
    const item = groupSignals[index];
    const _itemPins = getSignalPinsList(ICCompPins, item.signals, item.group, index, selectedSignals, serdesType);
    signalPins = [...signalPins, ..._itemPins]
  }

  return signalPins;
}

function getSignalPinsList(ICCompPins, signals, group, index, selectedSignals, serdesType) {
  let signalPins = [];
  for (let sig of signals) {

    if (serdesType === CPHY) {
      const { a_pin, b_pin, c_pin } = getPinByCompNets({ ICCompPins, A_Nets: sig.nets_A, B_Nets: sig.nets_B, C_Nets: sig.nets_C, serdesType });
      signalPins.push({
        signal: sig.name,
        signalDisplay: true,
        nets: [...(sig.nets_A || [])],
        netPlaceHolder: "Line A Nets",
        netType: "Line A",
        groupLength: 0,
        groupIndex: index,
        checked: selectedSignals.includes(sig.name),
        ...a_pin
      });
      signalPins.push({
        signal: sig.name,
        signalDisplay: false,
        nets: [...(sig.nets_B || [])],
        netPlaceHolder: "Line B Nets",
        netType: "Line B",
        groupLength: 0,
        groupIndex: index,
        checked: selectedSignals.includes(sig.name),
        ...b_pin
      });
      signalPins.push({
        signal: sig.name,
        signalDisplay: false,
        nets: [...(sig.nets_C || [])],
        netPlaceHolder: "Line C Nets",
        netType: "Line C",
        groupLength: 0,
        groupIndex: index,
        checked: selectedSignals.includes(sig.name),
        ...c_pin
      });
      continue;
    }

    const { p_pin, n_pin } = getPinByCompNets({ ICCompPins, P_Nets: sig.nets_P, N_Nets: sig.nets_N });
    //add p net
    signalPins.push({
      signal: sig.name,
      signalDisplay: true,
      nets: [...sig.nets_P],
      netPlaceHolder: "Positive Nets",
      netType: "positive",
      groupLength: 0,
      groupIndex: index,
      checked: selectedSignals.includes(sig.name),
      ...p_pin
    });

    //add n net
    signalPins.push({
      signal: sig.name,
      signalDisplay: false,
      nets: [...sig.nets_N],
      netType: "negative",
      netPlaceHolder: "Negative Nets",
      groupLength: 0,
      groupIndex: index,
      ...n_pin
    });
  }
  if (signalPins[0]) {
    signalPins[0].groupLength = signalPins.length;
    signalPins[0].group = group;
  }
  return signalPins;
}

function getPinByCompNets({ ICCompPins, P_Nets = [], N_Nets = [], A_Nets = [], B_Nets = [], C_Nets = [], serdesType }) {
  let p_pin = {}, n_pin = {}, a_pin = {}, b_pin = {}, c_pin = {};

  ICCompPins.forEach(comp => {
    for (let item of comp.pins) {
      if (serdesType === CPHY) {
        if (A_Nets.includes(item.net) && !a_pin[`component_${comp.name}`]) {
          a_pin[`component_${comp.name}`] = { ...item, component: comp.name };
        }
        if (B_Nets.includes(item.net) && !b_pin[`component_${comp.name}`]) {
          b_pin[`component_${comp.name}`] = { ...item, component: comp.name };
        }
        if (C_Nets.includes(item.net) && !c_pin[`component_${comp.name}`]) {
          c_pin[`component_${comp.name}`] = { ...item, component: comp.name };
        }

        if (a_pin[`component_${comp.name}`] && b_pin[`component_${comp.name}`] && c_pin[`component_${comp.name}`]) {
          break;
        }

      } else {
        if (P_Nets.includes(item.net) && !p_pin[`component_${comp.name}`]) {
          p_pin[`component_${comp.name}`] = { ...item, component: comp.name };
        }

        if (N_Nets.includes(item.net) && !n_pin[`component_${comp.name}`]) {
          n_pin[`component_${comp.name}`] = { ...item, component: comp.name };
        }

        if (p_pin[`component_${comp.name}`] && n_pin[`component_${comp.name}`]) {
          break;
        }
      }
    }
  })
  return { p_pin, n_pin, a_pin, b_pin, c_pin }
}

function getSignalCheckedStatus(selectedSignals, signals, group) {
  let checked = false, indeterminate = false;

  if (selectedSignals.length === 0) {
    return { checked, indeterminate };
  }

  if (group) {
    const groupSignals = signals.filter(item => item.group === group);
    const notSelectedGroupSignals = groupSignals.filter(item => !selectedSignals.includes(item.name));
    if (notSelectedGroupSignals.length === 0) {
      checked = true;
      indeterminate = false;
    } else if (notSelectedGroupSignals.length < groupSignals.length) {
      checked = false;
      indeterminate = true;
    } else {
      checked = false;
      indeterminate = false;
    }
    return { checked, indeterminate };
  }

  if (signals.length === selectedSignals.length) {
    checked = true;
    indeterminate = false;
  } else {
    checked = false;
    indeterminate = true;
  }
  return { checked, indeterminate };
}

function getRowClassName(record, index, serdesType) {
  let className = "editable-row";

  if (record.groupIndex % 2 === 0 && ![HDMI, CPHY].includes(serdesType)) {
    className = className + " aurora-signal-background";
  }

  if (serdesType !== CPHY && index % 2 !== 0) {
    className = className + " aurora-record-background";
  }

  if (serdesType === CPHY && index % 3 === 1) {
    className = className + " aurora-record-background";
  }

  return className;
}

function getCompUsageList({ type, record, designId, serdesType }) {
  const comp = record.comps.length && record.comps[0] ? record.comps[0] : null;
  const designType = designConstructor.getDesign(designId).type;

  let options = [];
  switch (type) {
    case IC:
    case CONNECTOR:
    case BGA:
    case DIE:
      const pcbComp = getComponentByName(designId, comp.name);

      if ([BGA, DIE].includes(type)) {
        options.push(...[BGA, DIE].map(item => { return { key: item, value: item } }));
      } else {
        options.push(...[IC, CONNECTOR].map(item => { return { key: item, value: item } }));
      }

      const _compPinList = pcbComp && pcbComp.mPart ? pcbComp.mPart.mPinList || [] : [];
      let compType = '';
      if (designType === PCB_PACKAGE) {
        compType = getDefaultCompType(comp.name, type, [], designType);
      } else {
        compType = checkCompsType(comp.name, null, _compPinList.length, record.part, ANDES_V2);
      }
      if (compType === DIODE) {
        options.push({ key: DIODE, value: DIODE });
      }

      if (pcbComp && pcbComp.mPart.mPinList && pcbComp.mPart.mPinList.length === 4 && serdesType !== PCIE /* && ![BGA, DIE].includes(type) */) {
        options.push({ key: CMC, value: CMC_COMMENT });
      }
      break;
    case CMC:
      const icTypes = designType === PCB_PACKAGE ? [DIE, BGA] : [IC, CONNECTOR];
      options.push(...icTypes.map(item => { return { key: item, value: item } }));
      options.push({ key: CMC, value: CMC_COMMENT });
      break;
    case RES:
    case IND:
    case CAP:
      options.push(...RLC_TYPES_LIST);
      break;
    case DIODE:
      options.push({ key: DIODE, value: DIODE });
      break;
    case IGNORE:
      const _pcbComp = getComponentByName(designId, comp.name);
      const compPinList = _pcbComp && _pcbComp.mPart ? _pcbComp.mPart.mPinList || [] : [];
      let _type = IGNORE;
      if (designType === PCB_PACKAGE) {
        _type = getDefaultCompType(comp.name, type, [], designType);
        options.push(...[BGA, DIE].map(item => { return { key: item, value: item } }));
      } else {
        _type = checkCompsType(comp.name, null, compPinList.length, record.part, ANDES_V2);
        options.push(...[IC, CONNECTOR].map(item => { return { key: item, value: item } }));
      }
      if (RLC_TYPES.includes(_type)) {
        options = [...RLC_TYPES_LIST];
        break;
      }
      if (_type === DIODE) {
        options.push({ key: DIODE, value: DIODE });
        break;
      }

      if (compPinList.length === 4 && serdesType !== PCIE/*  && designType !== PCB_PACKAGE */) {
        options.push({ key: CMC, value: CMC_COMMENT });
      }
      break;
    default: break;
  }

  if (!options.find(item => item.key === DIODE)) {
    options.push({ key: DIODE, value: DIODE });
  }
  options.push({ key: IGNORE, value: IGNORE });

  return options;
}

function getSetupComponentInfo({ channelDesignId, components, signals }) {
  if (!components || !signals || !components.length) {
    return {}
  }
  let compsInfo = {};
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));

  /*  const signalNets = signals.map(item => { return [...item.nets_P, ...item.nets_N] }).flat(2) */
  for (let comp of ICComps) {
    compsInfo[comp.name] = {};
    const { pinList } = getComponentNeedInfoByName({ designID: channelDesignId, compName: comp.name });

    const CompPinsNets = _LayoutData.getCompPinsNets(channelDesignId);
    const compPinsInfo = CompPinsNets[comp.name] || {};//{[pin]:{mName:net name, ... }}
    for (let pin of pinList || []) {
      const pinNet = (compPinsInfo[pin.mNumber] || {}).mName;
      /*  if (signalNets.includes(pinNet)) { */
      compsInfo[comp.name][pin.mNumber] = pinNet;
      /*  } */
    }
  }
  return compsInfo;
}

function getICCompNetPins({ compName, record, compsInfo }) {
  const findPin = record[`component_${compName}`] || {};
  const nets = record.nets || [];
  const allPins = compsInfo[compName] || {};
  const pinNets = Object.keys(allPins).map(item => { return { pin: item, net: allPins[item] } });
  const pins = pinNets.filter(item => nets.includes(item.net))
  return {
    pins,
    selectedPin: findPin
  }
}

function getAdvancedInputListByserdesType(serdesType) {
  if (serdesType === CPHY) {
    return channelAdvancedInputList.filter(item => ['netNamePrefix', 'ALineFlags', 'BLineFlags', 'CLineFlags'].includes(item.key))
  }
  return channelAdvancedInputList.filter(item => !['ALineFlags', 'BLineFlags', 'CLineFlags'].includes(item.key));
}

export {
  getChannelComponents,
  getSignalPinColumns,
  getSignalPinsData,
  getSignalCheckedStatus,
  getRowClassName,
  getCompUsageList,
  splitSignalsByGroup,
  getICCompNetPins,
  getSetupComponentInfo,
  getAdvancedInputListByserdesType
}