import LayoutRenderer from './LayoutRenderer';

/* todo:
  change color mode
  select and deselect net
  fit view, zoom in and zoom out
*/
let layoutRenderer, layout, nets, components = [];
const cache = {};

/**
 * create and initial layerRenderer by layout db
 * @param {dom} svg dom element to be rendered
 * @param {object} layoutDB layout data object
 * @param {dom} locationSvg location dom element
 * @param {object} events zoom and mousemove event functions
 */

function initLayout(svg, layoutDB, locationSvg, events) {
  layout = layoutDB;
  layoutRenderer = new LayoutRenderer(svg, layoutDB, locationSvg, events);
  layoutRenderer.resetCanvas();
  layoutRenderer.fitView();
  layoutRenderer.addSelectionLyr();
  layout.GetLayerManager().GetAllMetalLayers().mValues.forEach(name => {
    layoutRenderer.addMetalLayer(name);
  });
  nets = []; components = [];
  const metalLyrs = layout.mLayerMgr.mMetalLayers;
  nets = layout.mNetManager.mNetList.mKeys;
  if (metalLyrs && metalLyrs.length) {
    for (let metalItem of metalLyrs) {

      if (!metalItem.mComponentLayer) {
        continue;
      }
      const layer = metalItem.mComponentLayer;

      if (!layer.mComponents || !layer.mComponents.length) {
        continue;
      }
      components = components.concat(layer.mComponents.map(function (comp) {
        return comp.mName;
      }));
    }
  }

  // init layer select
  const settings = layout.GetLayoutSettings();
  if (settings.mLayerVisibility
    && settings.mLayerVisibility.mKeys
    && settings.mLayerVisibility.mKeys.length
  ) {
    settings.mLayerVisibility.mKeys.forEach(d => {
      settings.SetLayerVisible(d, false);
    })
  }

  //Deduplication
  nets = [...new Set(nets)];
  components = [...new Set(components)];

  cache.nets = []; cache.comps = [];

  cache.nets = nets.map(function (net) {
    return net.toLowerCase();
  });
  cache.comps = components.map(function (comp) {
    return comp.toLowerCase();
  })
}

/**
 * draw shownLayers and hide hiddenLayers
 * @param {array} shownLayers the display layer names
 * @param {array} hiddenLayers the hidden layer names
 */
function updateLayers(shownLayers, hiddenLayers, showName = false) {
  const settings = layout.GetLayoutSettings();
  hiddenLayers.forEach(name => {
    settings.SetLayerVisible(name, false);
  });
  shownLayers.forEach(name => {
    settings.SetLayerVisible(name, true);
  });
  if (showName) {
    // When a layer is hidden, the name of the comp of that layer is also hidden
    layoutRenderer.changLayerCompsName(shownLayers, hiddenLayers);
  }
  layoutRenderer.updateLayerVisibility();
  layoutRenderer.updateColoring();
}

function fitView() {
  layoutRenderer.fitView();
};

function zoomIn() {
  layoutRenderer.zoom(1.272);
};

function zoomOut() {
  layoutRenderer.zoom(1 / 1.272);
};

const reg = /^[-\+]?\d+(\.\d+)?\,[-\+]?\d+(\.\d+)?$/;

/**
 * search location, nets and components by value
 * @param {string} value 
 */
function search(value) {
  const keyword = value.replace(/[( )]/g, "");
  if (keyword.match(reg) !== null) {
    const [x, y] = keyword.split(',');
    layoutRenderer.zoomToLocate(parseFloat(x), parseFloat(y));
  } else if (value) {
    const searchValue = value.toLowerCase();
    return {
      nets: searchNet(searchValue),
      components: searchComp(searchValue)
    };
  }
}

/**
 * search nets in cache by value 
 * @param {string} value 
 */
function searchNet(value, PCIeName) {
  if (value === '') {
    if (PCIeName) {
      let key = 'TXP';
      switch (PCIeName) {
        case 'Tx_P': key = 'TXP'; break;
        case 'Tx_N': key = 'TXN'; break;
        case 'Rx_P': key = 'RXP'; break;
        case 'Rx_N': key = 'RXN'; break;
        default: break;
      }
      let cacheNets = cache.nets;
      let selectNet = nets.filter(function (net, i) {
        return !(cacheNets[i].indexOf(`PCIE_${key}`.toLowerCase()) < 0);
      })
      let otherNets = nets.filter(function (net, i) {
        return (cacheNets[i].indexOf(`PCIE_${key}`.toLowerCase()) < 0);
      })
      return [...selectNet, ...otherNets];
    } else {
      return nets;
    }
  } else {
    var cacheNets = cache.nets;
    return nets ? nets.filter(function (net, i) {
      return !(cacheNets[i].indexOf(value) < 0);
    }) : []
  }
}

/**
 * serach components in cache by value 
 * @param {string} value 
 */
function searchComp(value) {
  var cacheComps = cache.comps;
  return components.filter(function (comp, i) {
    return !(cacheComps[i].indexOf(value) < 0);
  });
}

/**
 * change the color model in canvas 
 * @param {'layer' | 'net' } colorBy 
 */
function changeColorMode(colorBy) {
  layout.GetLayoutSettings().SetColorByLayer(colorBy === 'layer');
  layoutRenderer.updateColoring();
}

/**
 * get the net names connected with the specified net by RLC
 * @param {string} netName 
 */
function getRelatedNets(netName) {
  const pinList = layout.mNetManager.GetNetFromName(netName).mPinList;
  const relatedNets = [];
  const RLC = 'RLCrlc';
  const rlcComponents = []
  for (const { mLayerName, mCompName, mPinNum } of pinList) {
    if (RLC.includes(mCompName[0])) {
      rlcComponents.push(mCompName);
    }
  }
  const nets = layout.mNetManager.mNetList.mVals;
  for (const { mName, mPinList } of nets) {
    if (mName !== netName) {
      for (const { mCompName } of mPinList) {
        if (rlcComponents.includes(mCompName)) {
          relatedNets.push(mName);
          break;
        }
      }
    }
  }
  return relatedNets;
}

function getLayerColor(layer) {
  return layout.GetLayoutSettings().GetLayerColor(layer);
}

/**
 * Select component, net, pin in canvas
 * @param {array} nets - selected nets' names
 * @param {array} comps - selected components' names
 * @param {Object} pins - { [comp]: [pin], [comp1]: [pin1, pin2] }, highlight component and pin
 */
// 
function selectNetCompPin({ nets = [], comps = [], pins = {}, showName = false, layoutDB, designId }, rate) {
  let _nets = [].concat(nets), _comps = [].concat(comps), _pins = pins ? JSON.parse(JSON.stringify(pins)) : {};
  // nets
  // 1. Highlight component layer
  let opacity = true;
  if ((!_nets.length && !_comps.length && !Object.keys(pins).length) || _comps.length) {
    opacity = false
  }
  layoutRenderer.removePort();
  layoutRenderer.transparentCompLyr(opacity);
  // 2. Highlight or darken all net
  if (_nets.length || _comps.length) {
    layoutRenderer.darkenAllNets();
  } else {
    layoutRenderer.unDarkenAllNets();
  }
  // 3. Select net
  layoutRenderer.deselectWholeNet();
  layoutRenderer.highlightSelectedNets(_nets, showName, designId, layoutDB);
  // 4. Select component
  layoutRenderer.deselectComponents();
  layoutRenderer.highlightSelectedCompsPins(_comps, _pins, showName);
  // 5. Zoom
  layoutRenderer.zoomToCompNetSelectionBox({ nets: _nets, comps: _comps }, rate);
}

/**
* find components in which layers
* @param {array} names selected components' names
*/
function findCurrentLayer(names) {
  if (names.length && names.length > 0) {
    let layers = layoutRenderer.getCurrentLayer(names);
    return layers;
  } else {
    return [];
  }
}

export {
  initLayout,
  updateLayers,
  fitView,
  zoomIn,
  zoomOut,
  search,
  searchNet,
  changeColorMode,
  getRelatedNets,
  getLayerColor,
  selectNetCompPin,
  findCurrentLayer
};