import React, { Component, Fragment } from 'react';
import { Switch } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import './index.css';
import { TreeOption } from '../../../../services/Cascade/PowerTree';
import { DESIGN_TREE, SINGLE_TREE } from '../../../../constants/treeConstants';


class TreeOptionPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      maxWidth: 350,
      maxHeight: 100,
      option: {}
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 350),
      maxHeight: getPanelMaxHeight(offset, 100)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { option } = this.props;
    this.setState({
      option: new TreeOption(option)
    })
  }

  closeModal = () => {
    const { error, option } = this.state;

    if (error) {
      return;
    }

    const _option = new TreeOption(option);
    this.props.saveOption(_option)
    this.props.closeModal(false);
  }

  changeSwitch = (checked, type) => {
    this.setState({
      option: {
        ...this.state.option,
        [type]: checked
      }
    })
  }


  render() {
    const { error, maxWidth, maxHeight, option } = this.state;
    const { view, single } = this.props;
    const setting = { defaultWidth: 350 }
    const content = (
      <Panel
        className='cascade-top-option-panel cascade-top-config-panel'
        title={<div className='cascade-top-option-panel-name'>Power Tree Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        minHeight="100"
      >
        <div className='cascade-top-option-panel-main cascade-top-opt-panel-main' id="simulate-content-id">
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body'>
              Use lumped pins
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={option['lumped'] || false}
              onChange={(checked) => this.changeSwitch(checked, 'lumped')}
            />
            {([DESIGN_TREE, SINGLE_TREE].includes(view) || single) && <Fragment>
              <span className='cascade-top-option-panel-content-body'>
                Show ground path
              </span>
              <Switch
                size="small"
                className="aurora-switch-small"
                checked={option['showGnd'] || false}
                onChange={(checked) => this.changeSwitch(checked, 'showGnd')}
              />
            </Fragment>}
            <span className='cascade-top-option-panel-content-body'>
              Only trace to PMICs through input pins
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={option['useInput'] || false}
              onChange={(checked) => this.changeSwitch(checked, 'useInput')}
            />
            <span className='cascade-top-option-panel-content-body'>
              Set PMICs buck output at inductor
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={option['includeInd'] || false}
              onChange={(checked) => this.changeSwitch(checked, 'includeInd')}
            />
          </div>
          {error && <span className='cascade-top-option-panel-error-msg'>Error: {error.error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default TreeOptionPanel;