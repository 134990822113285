import React, { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';

class PreviewBtn extends Component {
  constructor() {
    super()
    this.state = {
      previewShow: false,
      previewBtnLoad: false
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  showPreview = () => {
    let show = this.state.previewShow;
    this.props.savePreviewStatus(!show);
    this.setState({
      previewShow: !show,
      previewBtnLoad: !show
    }, () => {
      setTimeout(() => {
        this.props.saveAndShow(true)
      }, 100)
    })
  }

  setPreviewShowState = (show) => {
    this.setState({
      previewShow: show
    })
  }

  setPreviewBtnLoadState = (load) => {
    this.setState({
      previewBtnLoad: load
    })
  }

  render() {
    const { disabled, disabledPreview } = this.props;
    const { previewShow, previewBtnLoad } = this.state;

    return (
      <Button
        onClick={this.showPreview}
        disabled={disabled}
        type={previewShow ? 'primary' : ''}
        className={previewShow ? 'preview-button-focus' : 'preview-button'}
        title={disabledPreview ? "No signals selected." : null}
      >{previewBtnLoad ? <LoadingOutlined /> : 'Preview'}</Button>
    );
  }
}

export default PreviewBtn;