import { connect } from 'react-redux';
import { LayerTab } from '../components';
import {
  selectLayer, changeColorBy,
  checkboxChange, selectedAll, clearOneSelection,
  cancelSelectedAll, showSelected, hideSelected,
  changeDisplaySelected,
  clearSelection
} from '../store/Andes_v2/actionCreators';
import '../index.css';

const mapState = (state) => {
  const { AndesV2Reducer: { explorer: { selections } } } = state;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  const { pageType } = state.LoginReducer
  return {
    selections,
    defaultLeft,
    defaultTop,
    pageType
  }
}

const mapDispatch = (dispatch) => ({
  selectLayer(checkedList, designID) {
    dispatch(selectLayer(checkedList, designID));
  },
  changeColorBy(value, designID) {
    dispatch(changeColorBy(value, designID))
  },
  checkboxChanged: (canvasObj) => {
    dispatch(checkboxChange(canvasObj))
  },
  clearOneSelection: (arr, designID) => {
    dispatch(clearOneSelection(arr, designID))
  },
  selectedAll: (designID) => {
    dispatch(selectedAll(designID))
  },
  cancelSelectedAll: (designID) => {
    dispatch(cancelSelectedAll(designID))
  },
  showSelected: (designID) => {
    dispatch(showSelected(designID))
  },
  hideSelected: (designID) => {
    dispatch(hideSelected(designID))
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  clearSelection(designID) {
    dispatch(clearSelection(designID))
  }
})

export default connect(mapState, mapDispatch)(LayerTab);