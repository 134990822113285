import { takeEvery, call, put, select, cancel, fork, delay } from "redux-saga/effects";
import {
  UPDATE_LIBRARY_MENU,
  OPEN_LIBRARY_FOLDER,
  GET_LIBRARY_TREE,
  AFTER_IMPORT_LIBRARY,
  GET_PART_LIBRARY,
  SAVE_LIBRARY_FILE_CONTENT,
  GET_DEFAULT_LIBRARY,
  SET_DEFAULT_LIBRARY,
  SAVE_PRE_LAYOUT_TEMPLATE
} from "./actionTypes";
import sierraLibrary from "../../../../services/Sierra/library/libraryStorage";
import {
  getLibraryArray,
  getLibraryChildrenArray,
  getLibraryIndex,
  sierraDefaultTree
} from "../../../../services/Sierra/library/libraryData";
import {
  getSPFileList,
  getLibraryMacroModelingStatus,
  getSierraLibraryFolderInfo,
  editLibraryFileContent,
  setDefaultLibrary,
  getDefaultLibrary,
  clearLibraryCache
} from '@/services/Sierra/library';
import {
  BUFFERIndex, ConnectorIndex, repeaterIndex,
  IBISIndex, SPICEIndex, vectorIndex,
  passiveIndex, packageIndex, childTouchstoneIndex,
  childSpiceIndex,  /*  customIndex */  PCBModelIndex,
  pcbSpiceIndex, TRACE_INDEX
} from '../../constants';
import {
  IBIS, SPICE, CONNECTOR_SPICE_SIERRA, CONNECTOR_TOUCHSTONE, VECTOR,
  PASSIVE_TOUCHSTONE, PASSIVE_SPICE, REPEATER,
  PKG_TOUCHSTONE, PKG_SPICE, LIBRARY_SIERRA,
  PCB_SPICE
  /*   CUSTOM_SPICE, CUSTOM_TOUCHSTONE */
} from '@/constants/libraryConstants';
import { libraryMenu } from "../project/action";
import {
  saveTouchstoneRunningList,
  setFolderCreate,
  updateLibraryTree,
  getPartLibrary,
  updateFileContent,
  updateDefaultLibrary,
  updateRepeaterFileStatus
} from './action';
import * as taskStatus from '@/constants/workflowStatus';
import { SIERRA } from "../../../../constants/pageType";
import { strDelimited } from "../../../../services/helper/split";
import { expandMenu } from "../sierra/action";
import { TRACE } from "../../../../constants/libraryConstants";
import { updateTraceTemplateLibrary } from "../../../../services/Sierra/library";
import preLayoutLibraryData from "../../../../services/Sierra/library/PreLayoutData";

let touchstoneStatusTask = null;
function* _updateLibraryList() {
  const res = yield call(sierraLibrary.getMap);
  const { SierraReducer: { project } } = yield select();
  const { treeItems } = project;
  // TODO update library
  let _treeItems = [...treeItems];

  let ibisList, spiceList, vectorList, repeaterList,
    passiveTouchstoneList, passiveSpiceList, pkgTouchstoneList, pkgSpiceList,
    connTouchstoneList, connSpiceList,/* , customTouchstoneList, customSpiceList */pcbSpiceList, traceLibraryList;
  ibisList = getLibraryArray(IBIS, res.ibis);
  const children_ibis = _treeItems[0].children[BUFFERIndex].children[IBISIndex].children;
  _treeItems[0].children[BUFFERIndex].children[IBISIndex].children = children_ibis.length === 0 ? ibisList : children_ibis;

  spiceList = getLibraryArray(SPICE, res.spice);
  const children_spice = _treeItems[0].children[BUFFERIndex].children[SPICEIndex].children;
  _treeItems[0].children[BUFFERIndex].children[SPICEIndex].children = children_spice.length === 0 ? spiceList : children_spice;
  let folderSpiceList = spiceList.filter(item => item.type === 'folder');

  connSpiceList = getLibraryArray(CONNECTOR_SPICE_SIERRA, res.connector);
  const children_connector_s = _treeItems[0].children[ConnectorIndex].children[childSpiceIndex].children;
  _treeItems[0].children[ConnectorIndex].children[childSpiceIndex].children = children_connector_s.length === 0 ? connSpiceList : children_connector_s;

  connTouchstoneList = getLibraryArray(CONNECTOR_TOUCHSTONE, res.connector_touchstone);
  const children_connector_t = _treeItems[0].children[ConnectorIndex].children[childTouchstoneIndex].children;
  _treeItems[0].children[ConnectorIndex].children[childTouchstoneIndex].children = children_connector_t.length === 0 ? connTouchstoneList : children_connector_t;

  vectorList = getLibraryArray(VECTOR, res.vector);
  const children_vector = _treeItems[0].children[vectorIndex].children;
  _treeItems[0].children[vectorIndex].children = children_vector.length === 0 ? vectorList : children_vector;

  repeaterList = getLibraryArray(REPEATER, res.repeater);
  const children_repeater = _treeItems[0].children[repeaterIndex].children;
  _treeItems[0].children[repeaterIndex].children = children_repeater.length === 0 ? repeaterList : children_repeater;
  let folderRepeaterList = repeaterList.filter(item => item.type === 'folder');

  passiveTouchstoneList = getLibraryArray(PASSIVE_TOUCHSTONE, res.passive_touchstone);
  const children_passive_t = _treeItems[0].children[passiveIndex].children[childTouchstoneIndex].children;
  _treeItems[0].children[passiveIndex].children[childTouchstoneIndex].children = children_passive_t.length === 0 ? passiveTouchstoneList : children_passive_t;

  passiveSpiceList = getLibraryArray(PASSIVE_SPICE, res.passive_spice);
  const children_passive_s = _treeItems[0].children[passiveIndex].children[childSpiceIndex].children;
  _treeItems[0].children[passiveIndex].children[childSpiceIndex].children = children_passive_s ? passiveSpiceList : children_passive_s;

  pkgTouchstoneList = getLibraryArray(PKG_TOUCHSTONE, res.pkg_touchstone);
  const children_pkg_t = _treeItems[0].children[packageIndex].children[childTouchstoneIndex].children;
  _treeItems[0].children[packageIndex].children[childTouchstoneIndex].children = children_pkg_t.length === 0 ? pkgTouchstoneList : children_pkg_t;

  pkgSpiceList = getLibraryArray(PKG_SPICE, res.pkg_spice);
  const children_pkg_s = _treeItems[0].children[packageIndex].children[childSpiceIndex].children;
  _treeItems[0].children[packageIndex].children[childSpiceIndex].children = children_pkg_s.length === 0 ? pkgSpiceList : children_pkg_s;
  //custom component library
  /*   customTouchstoneList = getLibraryArray(CUSTOM_TOUCHSTONE, res.custom_touchstone);
    const children_custom_t = _treeItems[0].children[customIndex].children[childTouchstoneIndex].children;
    _treeItems[0].children[packageIndex].children[childTouchstoneIndex].children = children_custom_t.length === 0 ? customTouchstoneList : children_custom_t;
  
    customSpiceList = getLibraryArray(CUSTOM_SPICE, res.custom_spice);
    const children_custom_s = _treeItems[0].children[customIndex].children[childSpiceIndex].children;
    _treeItems[0].children[packageIndex].children[childSpiceIndex].children = children_custom_s.length === 0 ? customSpiceList : children_custom_s;
   */

  pcbSpiceList = getLibraryArray(PCB_SPICE, res.pcb_spice);
  const children_pcb_spice = _treeItems[0].children[PCBModelIndex].children[pcbSpiceIndex].children;
  _treeItems[0].children[PCBModelIndex].children[pcbSpiceIndex].children = children_pcb_spice.length === 0 ? pcbSpiceList : children_pcb_spice;

  //update trace library list
  traceLibraryList = getLibraryArray(TRACE, res[TRACE]);
  _treeItems[0].children[TRACE_INDEX].children = traceLibraryList;

  //spice folder children
  yield* folderSpiceList.map(function* (file) {
    const folderChildren = yield call(getSPFileList, { productType: 'sierra', libraryType: 'spice', libraryId: file.id });
    const fileIndex = _treeItems[0].children[BUFFERIndex].children[SPICEIndex].children.findIndex(item => item.id === file.id);
    const fileList = getLibraryChildrenArray(SPICE, folderChildren, file.id);
    _treeItems[0].children[BUFFERIndex].children[SPICEIndex].children[fileIndex].children = [...fileList];
    spiceList[fileIndex].children = [...fileList];
  })

  //repeater folder children
  yield* folderRepeaterList.map(function* (file) {
    const folderChildren = yield call(getSPFileList, { productType: 'sierra', libraryType: 'repeater', libraryId: file.id });

    const fileList = getLibraryChildrenArray(REPEATER, folderChildren, file.id);
    const fileIndex = _treeItems[0].children[repeaterIndex].children.findIndex(item => item.id === file.id);
    _treeItems[0].children[repeaterIndex].children[fileIndex].children = [...fileList];
    repeaterList[fileIndex].children = [...fileList];
  })

  yield put(libraryMenu({ treeItems: [..._treeItems] }));
  //get default library
  yield put({ type: GET_DEFAULT_LIBRARY })
  const runningFileList_p = pkgTouchstoneList.filter(item => item.status && (item.status === taskStatus.RUNNING || item.status === taskStatus.WAITING)).map(item => ({ id: item.id, status: item.status }));
  const runningFileList_c = connTouchstoneList.filter(item => item.status && (item.status === taskStatus.RUNNING || item.status === taskStatus.WAITING)).map(item => ({ id: item.id, status: item.status }));
  const touchstoneRunningFiles = [...runningFileList_p, ...runningFileList_c];
  yield put(saveTouchstoneRunningList(touchstoneRunningFiles));

  yield put(getPartLibrary())
  if (touchstoneRunningFiles.length) {
    if (touchstoneStatusTask) {
      yield cancel(touchstoneStatusTask);
    }
    touchstoneStatusTask = yield fork(_getTouchstoneFileStatus, { touchstoneRunningList: touchstoneRunningFiles });
  }

  yield call(getLibraryTree, { typeList: LIBRARY_SIERRA });
}

function* _getDefaultLibraryFile() {
  try {
    const res = yield call(getDefaultLibrary);
    if (!res) {
      return;
    }
    yield put(updateDefaultLibrary(res));
  } catch (error) {
    console.error(error)
  }
}

function* _setDefaultLibraryFile(action) {
  const { libraryId, name, libraryType } = action;
  try {
    yield call(setDefaultLibrary, { libraryId, name, libraryType });
    yield put({ type: GET_DEFAULT_LIBRARY })
  } catch (error) {
    console.error(error)
  }
}

function* getLibraryTree(action) {
  const { typeList } = action;
  yield* typeList.map(function* (type) {
    let list = yield call(sierraLibrary.updateTree, type);
    if (list) {
      if (type === REPEATER || type === SPICE) {
        const newList = yield call(getFolderInLibrary, { type, children: list.children });
        sierraLibrary.setTree(type, newList);
      }
    }
  })
  // export library update all libraryTree
  yield put(updateLibraryTree(typeList));
}

function* getFolderInLibrary(action) {
  const { type, children } = action;
  yield* children.map(function* (item) {
    if (item.type === 'folder') {
      item.children = yield call(getFolderInLibrary, { type, children: item.children });
    } else if (item.libraryFormat === 'folder') {
      item.children = yield call(getSPFileList, { productType: 'sierra', libraryType: type, libraryId: item.id });
    }
    return item;
  })
  return children
}

function* _getTouchstoneFileStatus(action) {
  const { touchstoneRunningList } = action;
  let _touchstoneRunningList = JSON.parse(JSON.stringify(touchstoneRunningList));
  // Loading files
  while (true) {
    yield delay(8000);
    const { LoginReducer: { pageType } } = yield select();
    if (pageType !== SIERRA) {
      return;
    }
    const newList = [];
    yield* _touchstoneRunningList.map(function* (item) {
      if (item.id) {
        try {
          if (item.status === taskStatus.FAILED || item.status === taskStatus.CANCEL) {
            newList.push({ ...item })
          } else {
            const statusObj = yield call(getLibraryMacroModelingStatus, item.id);
            if (statusObj) {
              if (statusObj.status !== taskStatus.SUCCEED) {
                newList.push({ ...item, status: statusObj.status });
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
    _touchstoneRunningList = [...newList]
    yield put(saveTouchstoneRunningList(newList));
  }
}

function* openLibraryFolder(action) {
  const { libraryId, key, update, isUpdateRepeater } = action;
  const { SierraReducer: { project: { treeItems }, library } } = yield select();
  const { folderCreateInfo, touchstoneRunningList } = library;
  let newList = [...touchstoneRunningList];
  if (folderCreateInfo && folderCreateInfo.parentId === libraryId) {
    yield put(setFolderCreate(null))
  }
  // TODO update library
  let _treeItems = [...treeItems];

  // if folder has been open bofore
  if (!update && sierraLibrary.hasMap({ id: libraryId, type: key })) {
    if (isUpdateRepeater) {
      yield put(updateRepeaterFileStatus(true))
    }
    return;
  }

  const res = yield call(sierraLibrary.get, { id: libraryId, type: key, update });
  let { index } = getLibraryIndex(key);
  let list = index.length > 1 ? _treeItems[0].children[index[0]].children[index[1]].children : _treeItems[0].children[index[0]].children;
  const libraryInfo = libraryId === '-1' ? null : yield call(getSierraLibraryFolderInfo, libraryId);
  let folderList = getLibraryArray(key, res);
  yield* folderList.map(function* (item) {
    if (item.libraryType === 'folder') {
      const childRes = yield call(sierraLibrary.get, { id: item.id, type: key });
      if (childRes && childRes.length > 0) {
        let childrenList = getLibraryArray(key, childRes);
        if (key === SPICE || key === REPEATER) {
          childrenList = yield call(getFolderInLibraryFile, { childrenList, key });
        } else if (key === PKG_TOUCHSTONE || key === CONNECTOR_TOUCHSTONE) {
          childrenList.forEach(it => {
            if ([taskStatus.RUNNING, taskStatus.WAITING].includes(it.status) && !newList.find(t => t.id === it.id)) {
              newList.push({ id: it.id, status: it.status });
            }
          })
        }
        item.children = childrenList;
      }
    } else if (item.type === 'folder') {
      const folderList = yield call(getFolderInLibraryFile, { childrenList: [item], key });
      item = folderList;
    } else if (item.fileType === PKG_TOUCHSTONE || item.fileType === CONNECTOR_TOUCHSTONE) {
      if ([taskStatus.RUNNING, taskStatus.WAITING].includes(item.status) && !newList.find(t => t.id === item.id)) {
        newList.push({ id: item.id, status: item.status });
      }
    }
  });
  yield put(saveTouchstoneRunningList(newList));
  if (touchstoneStatusTask) {
    yield cancel(touchstoneStatusTask);
  }
  touchstoneStatusTask = yield fork(_getTouchstoneFileStatus, { touchstoneRunningList: newList });
  if (libraryInfo) {
    const path = libraryInfo.path.split('/');
    let newList = list;
    for (let i = 0; i < path.length - 1; i++) {
      const folderIndex = newList.findIndex(it => it.name === path[i]);
      newList = newList[folderIndex] ? newList[folderIndex].children : [];
    }
    const oldList = JSON.parse(JSON.stringify(newList)).filter(it => it.libraryType === 'folder');
    //Clear the array but keep the address unchanged
    newList.splice(0);

    //Add new children but keep the address unchanged
    folderList.forEach(folder => {
      const { id, children } = folder;
      let newFolder = { ...folder };
      const oldFolder = oldList.find(item => item.id === id);
      if (children && oldFolder) {
        newFolder.children = oldFolder.children;
      }
      newList.push(newFolder);
    })
  } else {
    list = folderList;
  }

  if (index.length > 1) {
    _treeItems[0].children[index[0]].children[index[1]].children = list;
  } else {
    _treeItems[0].children[index[0]].children = list;
  }
  yield put(libraryMenu({ treeItems: [..._treeItems] }));
  if (isUpdateRepeater) {
    yield put(updateRepeaterFileStatus(true))
  }
}

function* getFolderInLibraryFile(action) {
  const { childrenList, key } = action;
  let newList = [...childrenList];
  yield* newList.map(function* (item) {
    if (item.type === 'folder') {
      const folderChildren = yield call(getSPFileList, { productType: 'sierra', libraryType: key, libraryId: item.id });
      const fileList = getLibraryChildrenArray(REPEATER, folderChildren, item.id);
      item.children = fileList;
    }
  })
  return newList;
}

function* afterImportLibrary(action) {
  // clear the treeItem of the library
  const { SierraReducer: { project: { treeItems }, sierra: { expandedKeys } } } = yield select();
  const defaultLibraryMenu = sierraDefaultTree.find(item => item.key === 'library')
  let _treeItems = treeItems.map(item => {
    if (item.key === 'library') {
      return JSON.parse(JSON.stringify(defaultLibraryMenu))
    }
    return item
  })
  yield put(libraryMenu({ treeItems: [..._treeItems] }));

  // Filter the data in the folder file in expandedKeys
  const _expandedKeys = expandedKeys.filter(item => {
    const [_key, _id] = strDelimited(item, "-");
    if (LIBRARY_SIERRA.includes(_key) && _id) {
      return false
    }
    return item
  });
  yield put(expandMenu(_expandedKeys));

  // update new library Menu
  yield call(_updateLibraryList)
}

function* _getPartLibrary(action) {

}

function* _saveLibraryFileContent(action) {
  const { fileId, folderId, name, content, libraryType, partRepeaterVisible } = action
  yield call(editLibraryFileContent, { fileId, folderId, name, content, libraryType })
  if (!fileId) {
    yield call(getLibraryTree, { typeList: [libraryType] })
    /* if (folderId !== "-1") { */
    yield call(openLibraryFolder, { libraryId: folderId, key: libraryType, update: true, isUpdateRepeater: partRepeaterVisible && libraryType === REPEATER })
    /*   } */
  } else {
    yield put(updateFileContent(true))
    clearLibraryCache(fileId, libraryType);
  }
  if (partRepeaterVisible && libraryType === REPEATER) {
    yield put(updateRepeaterFileStatus(true))
  }
}

function* _saveTraceTemplate(action) {
  const { modelType, data } = action;
  const { id } = data;
  const { SierraReducer: { sierra: { expandedKeys } } } = yield select();
  try {
    yield call(updateTraceTemplateLibrary, data);
    yield call(getLibraryTree, { typeList: [modelType] })
    if (!id) {
      //create new template
      const res = yield call(sierraLibrary.get, { id: "-1", type: TRACE, update: true });
      const { SierraReducer: { project } } = yield select();
      const { treeItems } = project;
      // update library
      let _treeItems = [...treeItems];
      //update trace library list
      const traceLibraryList = getLibraryArray(TRACE, res);
      _treeItems[0].children[TRACE_INDEX].children = traceLibraryList;
      yield put(libraryMenu({ treeItems: [..._treeItems] }));
    }

    let keys = [...expandedKeys];
    if (!keys.includes(modelType)) {
      keys.push(modelType);
    }
    yield put(expandMenu(keys))

    preLayoutLibraryData.updateLibraryConfig(id, modelType);
  } catch (error) {
    console.error(error)
  }
}

export default function* sierraLibrarySaga() {
  yield takeEvery(UPDATE_LIBRARY_MENU, _updateLibraryList);
  yield takeEvery(OPEN_LIBRARY_FOLDER, openLibraryFolder);
  yield takeEvery(GET_LIBRARY_TREE, getLibraryTree);
  yield takeEvery(AFTER_IMPORT_LIBRARY, afterImportLibrary);
  yield takeEvery(GET_PART_LIBRARY, _getPartLibrary);
  yield takeEvery(SAVE_LIBRARY_FILE_CONTENT, _saveLibraryFileContent);
  yield takeEvery(GET_DEFAULT_LIBRARY, _getDefaultLibraryFile);
  yield takeEvery(SET_DEFAULT_LIBRARY, _setDefaultLibraryFile);
  yield takeEvery(SAVE_PRE_LAYOUT_TEMPLATE, _saveTraceTemplate);
}