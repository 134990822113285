import { call, put, takeEvery, delay, select, fork } from 'redux-saga/effects';
import {
  CREATE_PRE_LAYOUT,
  OPEN_PRE_LAYOUT,
  SAVE_PRE_LAYOUT,
  SAVE_PRE_LAYOUT_SETTING,
  UPDATE_PRE_LAYOUT_COMPONENTS,
  SAVE_PRE_LAYOUT_MODEL,
  CREATE_PRELAYOUT_INTERFACE,
  UPDATE_CANVAS_CONFIG,
  UPDATE_PRE_LAYOUT_DATA
} from './actionTypes';
import { updatePreLayoutContent, firstOpenPreLayout, updateCompTableLoading, updateSchematicLoading, updateCanvasStatus, createLoading } from './action';
import { changeTreeSelected, changeViewList, openPage, openProject, updatePreLayoutNets, updateProjectMenu } from '../project/action';
import {
  PreLayoutSetting,
  PreLayoutModel,
  PRE_LAYOUT_DESIGN_VERSION,
  getCompsByPreLayoutSetting,
  netList,
  genericSignals,
  getPreLayoutComponentsByPinNet,
  editPreLayoutComponents
} from '../../../../services/Sierra/prelayout';
import projectDesigns from '@/services/helper/projectDesigns';
import { getDefaultName, getInterDefaultName } from '../../../../services/helper/setDefaultName';
import { SIERRA_PRELAYOUT_VERSION, SIERRA_SETUP_VERSION } from '../../../../version';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import { expandMenu } from '../sierra/action';
import { EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP, PCB, PCBS, PCB_PRE_LAYOUT, RESULT, VERIFICATION, VERIFICATIONS } from '../../../../constants/treeConstants';
import designConstructor from '../../../../services/helper/designConstructor';
import { saveSierraPreLayout, getSierraPreLayout, createVerificationPromise, SierraInterface, createInterfacePromise } from '../../../../services/Sierra';
import { getViewListBySelectedKey, updateTreeSelectKeys } from '../../../../services/helper/filterHelper';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import { Signal } from '../../../../services/helper/IntegratedInterface';
import { CUSTOM, GENERIC, I2C, I2CI3C } from '../../../../services/PCBHelper/constants';
import _ from 'lodash';
import { autoMatchBufferModelByPart, getAllComponentParts, getBufferModelByPart } from '../sierra/saga';
import { userDefaultSettings } from '../../../../services/userDefaultSetting/userDefaultSettingCtrl';
import { MODEL, SCHEMATIC } from '../../../../services/Sierra/prelayout/prelayoutConstants';
import { updateSignalsAndCompsByGPIO, updateSignalsAndCompsBySignalNum, getSchematicPreLayoutPowerNets } from '../../../../services/Sierra/prelayout/Schematic';
import preLayoutLibraryData from '../../../../services/Sierra/library/PreLayoutData';
import { TRACE } from '../../../../constants/libraryConstants';
import { changeTabMenu, openTabFooter } from '../../../tabMonitor/action';
import { SIERRA } from '../../../../constants/pageType';

function* createPreLayout() {
  const { SierraReducer: { project: { currentProjectId, selectedKeys, viewList }, sierra: { expandedKeys } } } = yield select();

  const designs = projectDesigns.getDesigns(currentProjectId);
  const designNames = designs.filter(d => d.vendor === PRE_LAYOUT).map(d => d.name);
  const name = getDefaultName({ nameList: designNames, defaultKey: "PreLayout", key: "" });

  const setting = new PreLayoutSetting();
  const model = new PreLayoutModel();
  const data = {
    id: "",
    name,
    projectId: currentProjectId,
    version: SIERRA_PRELAYOUT_VERSION,
    designVersion: PRE_LAYOUT_DESIGN_VERSION,
    content: {
      ...setting,
      components: [],
      signals: [],
      model
    }
  }

  try {
    const res = yield call(saveSierraPreLayout, data)

    //re open project -> update design list
    yield put(openProject(currentProjectId));

    //if PCB not expand, expand PCB
    if (!expandedKeys.includes(`${PCBS}-${currentProjectId}`)) {
      const expandKeys = [...expandedKeys, `${PCBS}-${currentProjectId}`];
      yield put(expandMenu(expandKeys));
    }

    if (res) {
      const designID = res.id;
      yield put(updatePreLayoutContent({ ...data, id: designID, name }));
      designConstructor.addDesign(designID, res);
      projectDesigns.addDesign(currentProjectId, res)

      //open current pre layout
      let selectKeys = []
      if (viewList.length <= 1) {
        selectKeys = [`${PCB_PRE_LAYOUT}-${designID}`]
      } else {
        selectKeys = selectedKeys.filter(item => !item.match(PCB) && !item.match(PCB_PRE_LAYOUT));
        selectKeys = [...selectKeys, `${PCB_PRE_LAYOUT}-${designID}`];
      }

      yield put(changeTreeSelected(selectKeys));
      yield put(changeViewList(getViewListBySelectedKey(selectKeys)));

      yield put(openPage(PCB_PRE_LAYOUT, { id: designID }));
      yield delay(300)
      yield put(firstOpenPreLayout(true));
    }
  } catch (e) {
    console.error("Create PreLayout failed:" + e)
  }
}

function* openPreLayout(action) {
  const { id } = action;
  if (!id) {
    return;
  }

  try {
    yield put(updateCanvasStatus(false));
    yield put(updateSchematicLoading(true));
    const res = yield call(getSierraPreLayout, id);
    if (res) {
      const { SierraReducer: { project: { preLayoutReady } } } = yield select();
      preLayoutData.savePreLayout(id, { ...(res || {}), id });
      yield put(updatePreLayoutNets([...new Set([...preLayoutReady, id])]))
      yield put(updatePreLayoutContent({ ...res, ...res.content, id }));
      yield put(updateCanvasStatus(true));
      if (res && res.content && res.content.prelayout === SCHEMATIC) {
        yield fork(getTemplateLibraryInfo, { signals: res.content.signals })
      }
    }
  } catch (e) {
    console.error("Open PreLayout failed:" + e);
    yield put(updateSchematicLoading(false));
  }
}

export function* getTemplateLibraryInfo(action) {
  const { signals } = action;
  if (!signals || !signals.length) {
    return;
  }
  for (let secItem of signals[0].sections || []) {
    if (!secItem.template || !secItem.template.id) {
      continue;
    }
    try {
      yield call(preLayoutLibraryData.updateLibraryConfig, secItem.template.id, TRACE);
    } catch (e) {
      console.error("Get trace template library failed" + e);
    }
  }
}

function* savePreLayout(action) {
  const { params = {}, name: newName } = action;
  const { SierraReducer: { project: { currentProjectId }, prelayout } } = yield select();
  const { id, name, version, designVersion, components, signals, model, points, differentialSections, canvasConfig, ...data } = prelayout;

  const setting = new PreLayoutSetting({ ...data, ...params });
  yield put(updateCanvasStatus(false));

  const content = setting.prelayout === SCHEMATIC ? {
    ...setting,
    components: JSON.parse(JSON.stringify(params.components || components || [])),
    signals: params.signals || signals || [],
    /*  points: params.points || points || [], */
    differentialSections: params.differentialSections || differentialSections || [],
    canvasConfig: params.canvasConfig || canvasConfig || {}
  } : {
    ...setting,
    components: params.components || components,
    signals: params.signals || signals,
    model: new PreLayoutModel(params.model || model)
  }
  try {
    const info = {
      id,
      name: newName || name,
      projectId: currentProjectId,
      version: SIERRA_PRELAYOUT_VERSION,
      designVersion: designVersion,
      content
    }
    yield put(updatePreLayoutContent({ ...info, ...info.content }));
    const res = yield call(saveSierraPreLayout, info);
    if (res) {
      preLayoutData.savePreLayout(id, info);
      if (info && info.content && info.content.prelayout === SCHEMATIC) {
        yield fork(getTemplateLibraryInfo, { signals: info.content.signals })
      }
      let savedData = { ...info, ...info.content };
      if (params.model) {
        savedData.model = params.model;
      }
      yield put(updatePreLayoutContent(savedData));
      if (newName !== name) {
        //re open project -> update design list
        yield put(openProject(currentProjectId));
      }
      const { SierraReducer: { prelayout: { schematicLoading } } } = yield select();
      if (schematicLoading) {
        yield put(updateCanvasStatus(true));
      }
    }
  } catch (e) {
    console.error("Save PreLayout failed:" + e);
    yield put(updateSchematicLoading(false));
  }
}

function* savePreLayoutSetting(action) {
  const { setting, name: newName } = action;

  const { SierraReducer: { prelayout } } = yield select();
  const { name, firstOpen, components = [] } = prelayout;

  let params = { ...setting };
  const newSetting = new PreLayoutSetting(setting);
  const prevSetting = new PreLayoutSetting(prelayout);

  if (!_.isEqual(newSetting, prevSetting) || firstOpen) {
    yield put(updateCompTableLoading(true))
    yield put(updateSchematicLoading(true))

    if (newSetting.prelayout === MODEL) {
      const newComponents = getCompsByPreLayoutSetting(setting, components);
      params.components = newComponents;
      const signals = newSetting.GPIO === GENERIC ? genericSignals(newSetting.signalNumber || 2) : netList[newSetting.GPIO];
      params.signals = signals;
    } else {
      if (prevSetting.prelayout === MODEL && newSetting.prelayout === SCHEMATIC) {
        params.components = [];
        params.signals = [];
        params.differentialSections = [];
        params.canvasConfig = { width: 22, height: 16 }
      } else {
        params.components = components || [];
        params.signals = prelayout.signals || [];
        params.differentialSections = prelayout.differentialSections || [];
        params.canvasConfig = prelayout.canvasConfig || { width: 22, height: 16 }

        if (prevSetting.GPIO !== newSetting.GPIO && prelayout.signals && prelayout.signals.length) {
          const { signals, components: _components, differentialSections } = updateSignalsAndCompsByGPIO({ GPIO: newSetting.GPIO, signalNumber: newSetting.signalNumber, signals: prelayout.signals, components: prelayout.components, differentialSections: prelayout.differentialSections })
          params.components = _components || [];
          params.signals = signals || [];
          params.differentialSections = differentialSections || [];
        }

        if (prevSetting.signalNumber !== newSetting.signalNumber) {
          const { signals, components: _components, differentialSections } = updateSignalsAndCompsBySignalNum({ signalNumber: newSetting.signalNumber, GPIO: newSetting.GPIO, signals: params.signals, components: params.components, differentialSections: params.differentialSections })
          params.components = _components || [];
          params.signals = signals || [];
          params.differentialSections = differentialSections || [];
          params.canvasConfig = prelayout.canvasConfig || { width: 22, height: 16 }
        }
      }
    }

    yield call(savePreLayout, { name: newName, params });
    if (firstOpen) {
      yield put(firstOpenPreLayout(false))
    }
    yield delay(100)
    yield put(updateCompTableLoading(false))
    return;
  }

  if (name !== newName) {
    yield call(savePreLayout, { name: newName, params });
  }
}

function* savePreLayoutComponents(action) {
  const { components } = action;

  const { SierraReducer: { prelayout } } = yield select();
  const { name, components: prevComps } = prelayout;
  if (!_.isEqual(components, prevComps)) {

    yield call(savePreLayout, { name, params: { components } });
  }
}

function* _savePreData(action) {
  const { info } = action;
  const { SierraReducer: { prelayout } } = yield select();
  const { name } = prelayout;
  yield call(savePreLayout, { name, params: { ...info } });
}

function* saveModel(action) {
  const { model, components, saveToBackend } = action;

  if (saveToBackend) {
    const { SierraReducer: { prelayout: { name } } } = yield select();
    yield call(savePreLayout, { name, params: { model, components } });
    return;
  }

  yield put(updatePreLayoutContent({ model }));
}

function* createPreLayoutInterface() {
  const { SierraReducer: { prelayout: { name, components, signals, id, prelayout, GPIO },
    project: { currentProjectVerifications, currentProjectId, viewList, layout, selectedKeys, pcbLayout },
    sierra: { expandedKeys } } } = yield select();

  yield put(createLoading(true));

  const [newName] = getInterDefaultName({ nameList: currentProjectVerifications, defaultKey: name, addDefault: true, delimiter: '_' });
  try {
    const res = yield call(createVerificationPromise, { name: newName, projectId: currentProjectId, readyForSim: 0, tag: GPIO === I2CI3C ? I2C : CUSTOM });
    if (!res) return;
    const verificationId = res.id;
    yield put(updateProjectMenu({ openProjectId: currentProjectId }));

    let PreLayoutInterface = new SierraInterface();
    PreLayoutInterface.name = newName;
    PreLayoutInterface.channel.type = 'Default';

    let newSignals = [], powerNets = [];
    const allPins = components.map(item => item.pins).flat(2);
    if (prelayout === SCHEMATIC) {
      for (let signal of signals) {
        const pinNets = allPins.filter(item => item.signal === signal.name).map(item => item.net);
        let _signal = new Signal(signal.name);
        _signal.nets = [...new Set(pinNets)];
        newSignals.push(_signal)
      }
      powerNets = getSchematicPreLayoutPowerNets(signals, components);
      PreLayoutInterface.powerNets = powerNets || [];
    } else {
      for (let signal of signals) {
        const pinNets = allPins.filter(item => item.signal === signal).map(item => item.net);
        let _signal = new Signal(signal);
        _signal.nets = [...new Set(pinNets)];
        newSignals.push(_signal)
      }
    }

    PreLayoutInterface.signals = newSignals;

    let exsitcomponentsName = [], _components = [];

    for (let newSignal of newSignals) {
      const { name: signalName, nets: addNets } = newSignal;
      const componentsList = getPreLayoutComponentsByPinNet(components, addNets);
      _components = editPreLayoutComponents({ componentsList, exsitcomponentsName, components: _components, changeTypeFromSetting: false, signalName });
      exsitcomponentsName = _components.map(item => item.name);
    }
    //auto match buffer model by part library
    const partList = yield call(getAllComponentParts, { components: _components, partList: [] });
    const modelMap = yield call(getBufferModelByPart, { partList });
    const userDefaultSetting = yield call(userDefaultSettings.getSettings);
    const sierraSettings = userDefaultSetting && userDefaultSetting.sierraSettings ? userDefaultSetting.sierraSettings : null;
    const ioBufferUsage = sierraSettings && sierraSettings.ioBufferUsage ? sierraSettings.ioBufferUsage : null;
    _components = yield call(autoMatchBufferModelByPart, {
      components: _components,
      modelMap,
      ioBufferUsage
    });
    PreLayoutInterface.components = _components;

    yield call(createInterfacePromise, {
      projectId: currentProjectId,
      designId: id,
      verificationId: verificationId,
      verificationName: newName,
      content: PreLayoutInterface,
      interfaceName: newName,
      readyForSim: 0,
      version: SIERRA_SETUP_VERSION,
      settingVersion: '0.0.1'
    });
    //open pre layout interface
    let list = [...viewList, VERIFICATION];
    list = [...new Set(list)];
    list = list.filter(item => ![RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
    if (layout === 3) {
      list = [VERIFICATION]
    }
    const selecteds = updateTreeSelectKeys({
      prevSelectedKeys: [...(selectedKeys || [])],
      key: VERIFICATION,
      eventKey: `${VERIFICATION}-${verificationId}`,
      selected: true,
      layout,
      pcbLayout,
      productType: SIERRA
    });
    yield put(changeTreeSelected(selecteds));

    yield put(changeViewList(list));
    //expand interfaces
    yield put(expandMenu([...new Set([...expandedKeys, `${VERIFICATIONS}-${currentProjectId}`])]));
    yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId, verificationName: newName }));
    yield put(openTabFooter());
    yield put(openPage(VERIFICATION, { verificationId }));
  } catch (e) {
    console.error('Create PreLayout Interface failed:' + e)
  }
  yield put(createLoading(false));
}

function* saveCanvasConfig(action) {
  const { config } = action;

  const { SierraReducer: { prelayout } } = yield select();
  const { name, canvasConfig } = prelayout;
  if (!_.isEqual(canvasConfig || {}, config || {})) {
    yield call(savePreLayout, { name, params: { canvasConfig: config } });
  }
}

function* SierraPreLayoutSaga() {
  yield takeEvery(CREATE_PRE_LAYOUT, createPreLayout);
  yield takeEvery(OPEN_PRE_LAYOUT, openPreLayout);
  yield takeEvery(SAVE_PRE_LAYOUT, savePreLayout);
  yield takeEvery(SAVE_PRE_LAYOUT_SETTING, savePreLayoutSetting);
  yield takeEvery(UPDATE_PRE_LAYOUT_COMPONENTS, savePreLayoutComponents);
  yield takeEvery(SAVE_PRE_LAYOUT_MODEL, saveModel);
  yield takeEvery(CREATE_PRELAYOUT_INTERFACE, createPreLayoutInterface);
  yield takeEvery(UPDATE_CANVAS_CONFIG, saveCanvasConfig);
  yield takeEvery(UPDATE_PRE_LAYOUT_DATA, _savePreData);
}

export default SierraPreLayoutSaga;