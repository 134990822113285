import {
  getMultiInterfaceSetupData,
  getMultiInterfaceSetupBufferData,
  saveMultiInterfaceSetupBufferData,
  getMultiInterfaceSetupPassiveData,
  getMultiSetupSimConfig,
  getMultiSetupExtractionOptions,
  getMultiSetupExtractionPorts,
  updateMultiSimConfig,
  saveMultiSetupExtractionOptions,
  saveMultiSetupExtractionPorts,
  saveMultiInterfaceSetupPassiveData,
  getMultiSetupExtractionApplyConfig,
  updateMultiSetupExtractionApplyConfig
} from "../../api/sierra/multiSetup";
import apiProcess from "../../api/utility";

function getMultiSetupDataPromise(projectId, group) {
  return apiProcess(getMultiInterfaceSetupData, { projectId, group });
}

function getMultiSetupBufferDataPromise(projectId, verificationId, group) {
  return apiProcess(getMultiInterfaceSetupBufferData, { projectId, verificationId, group });
}

function getMultiSetupPassiveDataPromise(projectId, verificationId, group) {
  return apiProcess(getMultiInterfaceSetupPassiveData, { projectId, verificationId, group });
}

function saveMultiBufferDataPromise(updateData) {
  return apiProcess(saveMultiInterfaceSetupBufferData, updateData);
}

function saveMultiPassiveDataPromise(updateData) {
  return apiProcess(saveMultiInterfaceSetupPassiveData, updateData);
}

function getSimConfig(verificationId) {
  return apiProcess(getMultiSetupSimConfig, verificationId)
}

function getExtractionOptions(interfaceId) {
  return apiProcess(getMultiSetupExtractionOptions, interfaceId)
}

function getExtractionPorts(interfaceId) {
  return apiProcess(getMultiSetupExtractionPorts, interfaceId)
}

function updateMultiSetupSimConfig({ verificationIds, config }) {
  return apiProcess(updateMultiSimConfig, { verificationIds, config })
}

function saveExtractionOptions(extractionInfo) {
  return apiProcess(saveMultiSetupExtractionOptions, extractionInfo)
}

function saveExtractionPorts(portsInfo) {
  return apiProcess(saveMultiSetupExtractionPorts, portsInfo)
}

function getExtractionApplyConfig(interfaceId) {
  return apiProcess(getMultiSetupExtractionApplyConfig, interfaceId)
}

function updateExtractionApplyConfig({ interfaceId, config }) {
  return apiProcess(updateMultiSetupExtractionApplyConfig, { interfaceId, config })
}

export {
  getMultiSetupDataPromise,
  getMultiSetupBufferDataPromise,
  saveMultiBufferDataPromise,
  getMultiSetupPassiveDataPromise,
  saveMultiPassiveDataPromise,
  getSimConfig,
  getExtractionOptions,
  getExtractionPorts,
  updateMultiSetupSimConfig,
  saveExtractionOptions,
  saveExtractionPorts,
  getExtractionApplyConfig,
  updateExtractionApplyConfig
}