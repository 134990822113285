import React, { Component } from 'react';
import { SnippetsFilled, SnippetsOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import Table from '@/components/EditableTable';
import PinMapSelect from './PinSelect';
import _ from 'lodash';
import {
  getPinMapTableData,
  updatePinList,
  updateOutputPins,
  deleteCurrPinInfo,
  findSensePinByOutput,
  findGndSensePinByOutput,
  checkConnectComp,
  findInputPinByOutput
} from '../../services/Designs/pinMapHelper';
import { PIN_MAP } from '../../constants/libraryConstants';
import auroraDBJson from '../../services/Designs/auroraDbData';
import { PIN_MAP_VERSION } from '../../version';

const INPUT = 'input', OUTPUT = 'output', GROUND = 'ground', SENSE = 'sense', GND_SENSE = "gndSense", OUTPUTGND = 'outputGround', INPUTGND = 'inputGround';
const pinColumns = [{
  title: 'Output Pin',
  dataIndex: OUTPUT,
  width: '50%'
}, {
  title: 'Input Pin',
  dataIndex: INPUT,
  width: '50%'
}];
const gndColumns = [{
  title: 'Ground Pin',
  dataIndex: GROUND
}];
const driverGndColumns = [{
  title: 'Output Ground Pin',
  dataIndex: OUTPUTGND,
  width: '50%'
}, {
  title: 'Input Ground Pin',
  dataIndex: INPUTGND,
  width: '50%'
}];
const pinMapColumns = [{
  title: 'Pwr Sense Pin',
  dataIndex: SENSE,
},
{
  title: 'Gnd Sense Pin',
  dataIndex: GND_SENSE,
}]
const emptyTable = [{ index: 1, output: [], input: [], sense: [], gndSense: [], ground: [] }, { index: 'extra', output: [], input: [], sense: [], gndSense: [], ground: [] }];
const emptyDriverTable = [{ index: 1, output: [], input: [], outputGround: [], inputGround: [] }];
const tableConst = [INPUT, OUTPUT, OUTPUTGND, INPUTGND, GROUND];
const columnsPinsFilter = [INPUT, OUTPUT, OUTPUTGND, INPUTGND, SENSE, GND_SENSE, GROUND];

class PinMapTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinTable: [],
      loading: true,
      pinStore: [],
      errorMsg: '',
      designId: '',
      save: false,
      showExtra: false,
      pinComp: ''
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    this.selectDefaultDesign();
  }

  selectDefaultDesign = async () => {
    const { designList } = this.props;
    if (!designList || !designList.length) {
      this.initColumns();
      setTimeout(() => {
        this.getLibraryData();
      }, 100)
      return;
    }
    const designId = designList[0].designId;
    if (!auroraDBJson.checkAuroraJson(designId)) {
      const setting = await this.props.componentSetting.getSetting({ designId });
      await auroraDBJson.getAuroraJson(designId, setting);
    }
    this.setState({
      designId
    }, () => {
      this.initColumns();
      setTimeout(() => {
        this.getLibraryData();
      }, 100)
    })
  }

  getLibraryData = async () => {
    const { type, pinTable: propsPinTable, partName } = this.props;
    const { designId } = this.state;
    let pinTable = propsPinTable && propsPinTable.length ? [...propsPinTable] : type === 'driver' ? [...emptyDriverTable] : [...emptyTable], pinStore = [], pinComp = '';

    if (type === 'buckConverter') {
      const _comps = partName.split(' - ');
      const comps = auroraDBJson.getComponentsByNames(designId, _comps);
      for (let comp of comps) {
        const { name, pins } = comp;
        const _pinStore = await checkConnectComp(name, [...pins.values()], designId);
        pinStore.push(..._pinStore.map(p => ({ ...p, comp: name })))
      }
    } else {
      const comps = auroraDBJson.getComponentsByPartName(designId, partName);
      if (comps.length) {
        const { name, pins } = comps[0];
        pinStore = await checkConnectComp(name, [...pins.values()], designId);
        pinComp = name;
      }
    }

    if (!['driver', 'all'].includes(type) && !pinTable.find(item => item.index === 'extra')) {
      pinTable.push({ index: 'extra', output: [], input: [], sense: [], gndSense: [], ground: [] })
    }

    if (type === 'all') {
      let keepItem = pinTable.map(i => {
        const keys = Object.keys(i);
        return keys.filter(key => i[key] && i[key].length);
      }).flat(2)
      keepItem = [...new Set(keepItem)]
      if (!keepItem.length || keepItem.length === 1) {
        if (!keepItem[0] || keepItem[0] === OUTPUT || keepItem[0] === INPUT) {
          keepItem = [OUTPUT, INPUT]
        } else {
          keepItem = [OUTPUT, INPUT, ...keepItem]
        }
      }
      if (keepItem.length) {
        this.columns = this.columns.filter(item => keepItem.includes(item.dataIndex))
        this.columns.forEach(item => {
          item.width = `${100 / this.columns.length}%`
        })
      }
      const extra = pinTable.find(item => item.index === 'extra');
      if (extra) {
        const { input = [], sense = [], gndSense = [], ground = [] } = extra;
        if (!input.length && !sense.length && !gndSense.length && !ground.length) {
          pinTable = pinTable.filter(row => row.index !== 'extra')
        }
      }
    }
    //disabled -> open pin map from library tree
    const _pinTable = getPinMapTableData(pinTable, pinStore, false);
    const extraColumn = _pinTable.find(item => item.index === 'extra');
    const showExtra = extraColumn &&
      ((extraColumn.input && extraColumn.input.length) ||
        (extraColumn.sense && extraColumn.sense.length) ||
        (extraColumn.gndSense && extraColumn.gndSense.length) ||
        (extraColumn.ground && extraColumn.ground.length)) ? true : false
    this.setState({
      name: partName,
      pinTable: _pinTable,
      loading: false,
      pinStore,
      showExtra,
      pinComp
    })
  }

  initColumns = () => {
    const { disabled, type, partName, designList } = this.props;
    const columnRender = (text, record, dataIndex) => {
      if (record.index === 'extra' && dataIndex === OUTPUT) {
        return <div style={{ minHeight: 20 }}>
        </div>
      }

      const { pinTable } = this.state;
      let outputList = pinTable.map(item => item[OUTPUT] ? item[OUTPUT] : []);
      if (dataIndex === OUTPUT && !outputList.flat().length) {
        return <span className='pmic-part-click-tip-editable-cell'>Click to add pin</span>
      }

      const _text = record[dataIndex].map(item => `${item.currComp || item.comp ? `${item.currComp || item.comp}_` : ""}${item.currPinName || item.pinName}`).filter(item => !!item && item !== 'undefined');
      if (dataIndex === OUTPUT && !_text.length) {
        return <span className='pmic-part-click-tip-editable-cell'>Click to add pin</span>
      }
      return <div style={{ minHeight: 20 }}>
        {_text ? _text.sort((a, b) => a > b ? 1 : -1).join(', ') : ''}
      </div>
    }
    let columnCell = () => ({ edit: false })
    if (!disabled) {
      columnCell = (record, dataIndex) => {
        const { pinStore, pinTable, name, pinComp, designId } = this.state;
        const comps = auroraDBJson.getComponentsByPartName(designId, partName);

        if (record.index === 'extra' && dataIndex === OUTPUT) {
          return {
            edit: false
          }
        }

        const constant = tableConst.filter(t => t !== dataIndex);
        let targetPins = dataIndex === OUTPUT ? pinTable.map(item => item[OUTPUT]).flat(2).filter(item => !!item)
          : record[dataIndex] || [];
        targetPins = targetPins.map(item => `${item.currComp ? `${item.currComp}_` : ""}${item.currPinName}`).filter(item => !!item);
        const apply = dataIndex === OUTPUT ? false : true;
        let outputList = pinTable.map(item => item[OUTPUT] ? item[OUTPUT] : []);
        const recordOutputPinNames = record[OUTPUT].map(item => `${item.currComp ? `${item.currComp}_` : ""}${item.currPinName}`).filter(item => !!item);
        outputList = JSON.parse(JSON.stringify(outputList)).map(item => item.map(it => `${it.currComp ? `${it.currComp}_` : ""}${it.currPinName}`).filter(item => !!item));
        const outputs = outputList.filter(item => item.length && recordOutputPinNames && !_.isEqual(item, recordOutputPinNames));
        let filterPins = pinTable.map(item => constant.map(t => item[t]).flat(2).filter(item => !!item)).flat(2).map(it => `${it.currComp ? `${it.currComp}_` : ""}${it.currPinName}`).filter(item => !!item);
        const edit = dataIndex !== OUTPUT && !record[OUTPUT].length && record.index !== 'extra' ? false : true;
        let filterCurrRowPins = columnsPinsFilter.filter(it => it !== dataIndex).map(item => { return record[item] ? [...record[item]] : [] }).flat(2).filter(it => !!it).map(it => `${it.currComp ? `${it.currComp}_` : ""}${it.currPinName}`).filter(item => !!item);
        targetPins = [...new Set(targetPins)];
        filterPins = [...new Set(filterPins)];
        filterCurrRowPins = [...new Set(filterCurrRowPins)];

        return {
          edit: edit,
          customInput: PinMapSelect,
          record,
          dataIndex,
          targetPins,
          text: targetPins.sort((a, b) => a > b ? 1 : -1).join(', '),
          type: dataIndex,
          pmicType: type,
          comp: pinComp,
          title: name,
          comps,
          pins: pinStore.filter(pin => !filterPins.includes(`${pin.comp ? `${pin.comp}_` : ""}${pin.pinName}`) && !filterCurrRowPins.includes(`${pin.comp ? `${pin.comp}_` : ""}${pin.pinName}`)),
          selectPins: this.selectPins,
          selectComp: this.selectComp,
          selectDesign: this.selectDesign,
          designId,
          designList,
          apply,
          outputs
        }
      }
    }
    this.columns = type === 'all' ? [...pinColumns, ...gndColumns, ...pinMapColumns, ...driverGndColumns]
      : type === 'driver' ? [...pinColumns, ...driverGndColumns]
        : [...pinColumns, ...gndColumns, ...pinMapColumns]
    const inputIndex = this.columns.findIndex(item => item.dataIndex === INPUT);
    if (inputIndex > -1) {
      this.columns[inputIndex].title = () => {
        const { showExtra } = this.state;
        return (
          <span>
            Input Pins {type === 'all' ? null : <Tooltip overlayClassName='aurora-tooltip' title={`${showExtra ? 'Hide' : 'Show'} the extra row for other types of pins that do not require output pins.`}>
              {showExtra ? <SnippetsFilled className='aurora-icon-hover-color' onClick={(e) => this.changeShowExtra(e)} /> : <SnippetsOutlined onClick={(e) => this.changeShowExtra(e)} />}
            </Tooltip>}
          </span>
        );
      }
    }
    this.columns.forEach(item => {
      item.render = (text, record) => columnRender(text, record, item.dataIndex);
      item.onCell = (record) => columnCell(record, item.dataIndex);
      item.width = `${100 / this.columns.length}%`
    })

  }

  changeShowExtra = (e) => {
    e && e.stopPropagation();
    this.setState({
      showExtra: !this.state.showExtra
    })
  }

  selectDesign = async (designId) => {
    if (!auroraDBJson.checkAuroraJson(designId)) {
      const setting = await this.props.componentSetting.getSetting({ designId });
      await auroraDBJson.getAuroraJson(designId, setting);
    }
    this.setState({
      designId
    }, () => {
      const { partName } = this.props;
      const comps = auroraDBJson.getComponentsByPartName(designId, partName);
      if (comps.length) {
        const { name } = comps[0];
        this.selectComp(name)
      }
    })
  }

  selectComp = async (comp) => {
    const { partName } = this.props;
    const { designId } = this.state;
    const comps = auroraDBJson.getComponentsByPartName(designId, partName);
    if (comps.length) {
      const _comp = comps.find(item => item.name === comp);
      if (_comp) {
        const { name, pins } = _comp;
        const pinStore = await checkConnectComp(name, [...pins.values()], designId);
        const pinComp = name;
        this.setState({
          pinStore,
          pinComp
        })
      }
    }
  }

  selectPins = (target, type, record, applys) => {
    const { pinTable, pinStore } = this.state;
    const pinsInfo = pinStore.filter(pin => target.includes(`${pin.comp ? `${pin.comp}_` : ''}${pin.pin}`));
    let _pinTable = [];
    if (type === OUTPUT) {
      const sortPinByNet = {};
      const prevOutputPins = pinTable.map(item => item.output).flat(2);
      pinsInfo.forEach(pin => {
        const sortNet = pin.net;
        const pinNumber = pin.pin,
          pinName = pin.pinName,
          pinComp = pin.comp;

        const findPin = prevOutputPins.find(it => (it.pin === pinNumber || it.pinName === pinName) && (!pinComp || pinComp === it.comp));
        const pinInfo = {
          pin: findPin ? findPin.pin : pinNumber,
          pinName: findPin ? findPin.pinName : pinName,
          comp: findPin ? findPin.comp : pinComp,
          currPin: pinNumber,
          currPinName: pinName,
          currComp: pinComp
        }
        if (sortPinByNet[sortNet]) {
          sortPinByNet[sortNet].push(pinInfo);
        } else {
          sortPinByNet[sortNet] = [pinInfo];
        }
      })
      const nets = Object.keys(sortPinByNet);
      let allPins = pinStore.filter(pin => !target.includes(`${pin.comp ? `${pin.comp}_` : ''}${pin.pin}`))
      nets.forEach((net, index) => {
        const output = sortPinByNet[net];
        const find = pinTable.find(item => item.output.some(pinData => output.find(it =>
          (it.pin === pinData.currPin || it.pinName === pinData.currPinName)
          && (!it.comp || it.comp === pinData.currComp)
        )));
        const input = find ? [...(find.input || [])] : findInputPinByOutput(output, allPins);
        const sense = find ? [...(find.sense || [])] : findSensePinByOutput(output, allPins);
        const gndSense = find ? [...(find.gndSense || [])] : findGndSensePinByOutput(output, allPins);
        const ground = find ? [...(find.ground || [])] : [];
        _pinTable.push({
          index,
          output,
          input,
          sense,
          gndSense,
          ground,
          outputGround: [],
          inputGround: []
        });
      })
      //update not identify output pins
      _pinTable = updateOutputPins(_pinTable, pinTable)
      _pinTable = _pinTable.sort((a, b) => a.output[0].currPinName > b.output[0].currPinName ? 1 : -1).map((item, index) => ({ ...item, index }))

      // extra row
      const extra = pinTable.find(item => item.index === 'extra');
      if (extra) {
        _pinTable.push(extra)
      }
    } else {
      _pinTable = [...pinTable];
      const findIndex = _pinTable.findIndex(item => item.index === record.index);
      if (findIndex > -1) {
        _pinTable[findIndex][type] = updatePinList(_pinTable[findIndex][type], pinsInfo);
        if (applys.length) {
          _pinTable.forEach(row => {
            if (applys.includes(row[OUTPUT].map(item => `${item.currComp ? `${item.currComp}_` : ""}${item.currPinName}`).join(', '))) {
              row[type] = JSON.parse(JSON.stringify(_pinTable[findIndex][type]));
            }
          })
        }
      }
    }
    if (!_pinTable.length) {
      _pinTable = type === 'driver' ? [...emptyDriverTable] : [...emptyTable]
    } else if (_pinTable.length === 1 && _pinTable[0].index === 'extra') {
      _pinTable = type === 'driver' ? [...emptyDriverTable] : [{ index: 1, output: [], input: [], sense: [], gndSense: [], ground: [] }, ..._pinTable]
    }
    this.setState({
      pinTable: _pinTable
    }, () => {
      this.savePinMap()
    });
  }

  savePinMap = async () => {
    const { libraryId, name, partName, type } = this.props;
    const { pinTable } = this.state;
    const newPinTable = JSON.parse(JSON.stringify(pinTable));

    if (!newPinTable.length || (newPinTable.length === 1 && !newPinTable[0].output.length)) {
      return;
    }

    const _pinTable = deleteCurrPinInfo(newPinTable);
    const data = {
      id: libraryId || '',
      name,
      config: { pinTable: _pinTable },
      partName: partName,
      version: PIN_MAP_VERSION,
      type: PIN_MAP
    }
    try {
      await this.props.updateLibraryData({ data });
      this.props.savePinTable(libraryId, _pinTable)
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { pinTable, showExtra } = this.state;
    const _pinTable = showExtra ? pinTable : pinTable.filter(item => item.index !== 'extra');
    return <Table
      size="small"
      className="pmic-part-pin-map-content-table"
      columns={this.columns}
      rowKey={(record) => record.index}
      dataSource={_pinTable}
    />
  }
}

export default PinMapTable
