import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import Table from '@/components/EditableTable';
import ICModelPanel from '@/components/ICModelPanel';
import PinNodeConnect from '@/components/pinNodeConnect';
import { SIERRA } from '@/constants/pageType';
import { MultiPinSPICE } from '../../constants';
import {
  pinColumns,
  getSweepingPinData,
  driverReceiverModelText,
  getStimulusText,
  getPinModels
} from '@/services/Sierra';
import { getIbisModelList, getSpiceModelList, getFolderFileDetail } from '@/services/Sierra/library';
import { getPins } from '@/services/Sierra/library/IbisModelHelper';
import { judgeModelUpdate } from '../../../../services/Sierra';
import './index.css';
import '../index.css';


const CORNER = ["typ", "fast", "slow"];
const _IBIS = 'IBIS';
const chipModelUsages = ['Driver', 'Receiver'];
const PIN_TYPE = ['Driver', 'Receiver'];

class PinModelPanel extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
    this.columns = JSON.parse(JSON.stringify(pinColumns));
    this.state = {
      tempChange: [],
      modelChange: []
    }
  }

  componentDidMount = () => {
    const { modelInfo, pinsInfo } = this.props;
    this.setState({
      tempChange: pinsInfo ? pinsInfo : [],
      modelChange: modelInfo ? modelInfo : []
    })
    this.getPinColumns()
  }

  getPinColumns = () => {
    let columns = JSON.parse(JSON.stringify(pinColumns));

    columns[0].render = (name, row) => {
      return <span>{name}</span>;
    }

    columns[0].onCell = (row) => {
      let rowSpan = 0, tdClassName = "";
      if (row.signalLength && row.signalLength > 0) {
        rowSpan = row.signalLength;
        tdClassName = 'sweeping-model-exsit'
      } else if (!row.pcb) {
        rowSpan = 1;
      }
      return {
        edit: false,
        rowSpan,
        tdClassName
      }
    }

    columns[1].render = (name, record) => {
      return <span>{`${record.component} - ${record.pin}`}</span>
    }

    columns[2].onCell = (record) => {
      return this.props.rowName !== "Base" && record.exsit !== false ? {
        record,
        edit: 'select',
        options: PIN_TYPE,
        dataIndex: 'usage',
        handleSave: (row) => this.editSelect(row, 'usage'),
        tdClassName: (record.edited || []).includes('usage') ? "sweeping-table-td-font-color" : ""
      } : {
        edit: false,
        tdClassName: (record.edited || []).includes('usage') ? "sweeping-table-td-font-color" : ""
      }
    };

    columns[3].render = (text, record, index) => {
      const { component } = record;
      const { parentIndex } = this.props;
      const compModel = this.getStimulusModel(component, parentIndex);
      const _text = driverReceiverModelText(record, { compModel });
      return <span className='sierra-ic-editable-cell-value-wrap'>{_text}</span>
    };

    columns[3].onCell = (record) => {
      const { component, usage } = record;
      const { ibisList, spiceList, rowName, parentIndex, sweepCreateStatus, updateSweepCreateStatus, createExpsByModel } = this.props;
      let pins = this.getMultiSpicePins(component, usage, parentIndex);
      const compModel = this.getStimulusModel(component, parentIndex);
      const text = driverReceiverModelText(record, { compModel });
      const edit = !(record.model.libType !== 'IBIS' && rowName === "Base");
      return usage && record.exsit !== false ? {
        edit,
        multipleModels: rowName === "Base",
        record: { ...record, pinModels: JSON.parse(JSON.stringify(record.pinModels || [])) },

        // verificationId,
        componentName: component,
        compModel,
        customInput: ICModelPanel,
        className: 'sierra-ic-editable-cell-value-wrap',
        product: SIERRA,
        text,
        pins,
        dataIndex: 'model',
        displayType: "model",
        ibisList,
        spiceList,
        interfaceIndex: parentIndex > -1 ? parentIndex : 0,
        createStatus: sweepCreateStatus,
        getIbisModelList: getIbisModelList,
        getSpiceModelList: getSpiceModelList,
        updateCreateStatus: updateSweepCreateStatus,
        createExpsByModel: createExpsByModel,
        getFolderFileDetail,
        getPins,
        assignModel: this.assignModel,
        saveModelPins: () => { } /* this.saveModelPins */,
        savePowerOff: () => { }/*  this.savePowerOff */,
        saveLibraryModel: this.saveChipModel,
        tdClassName: (record.edited || []).includes('model') ? "sweeping-table-td-font-color" : ""
      } :
        {
          edit: false,
          tdClassName: (record.edited || []).includes('model') ? "sweeping-table-td-font-color" : ""
        }
    }

    columns[4].render = (text, record) => {
      if (!record.usage || record.usage === 'Receiver' || !text) return;
      let _text = null;
      if (text === MultiPinSPICE) {
        const pinNode = getStimulusText(record);
        _text = <span className='sierra-stimulus-editable-cell-value-wrap'>{pinNode}</span>
      }
      _text = <span className='sierra-stimulus-editable-cell-value-wrap'>{text}</span>
      return _text;
    }

    columns[4].onCell = (record) => {
      const type = record.usage === 'Driver' ? 'tx' : 'rx';
      const compName = record.component;
      const { vectorList, spiceList, clock, rowName, parentIndex } = this.props;
      const _pins = this.getMultiSpicePins(compName, 'Driver', parentIndex);
      const compModel = this.getStimulusModel(compName, parentIndex);
      const pinModels = getPinModels(record.pinModels);
      const pinNode = getStimulusText(record);
      return type === 'tx' && record.exsit !== false && record.model && rowName !== "Base" && (record.model.modelType || record.model.libType === MultiPinSPICE) ? {
        record,
        edit: true,
        text: pinNode,
        modelType: "Stimulus",
        pins: _pins,
        compName,
        compModel,
        product: SIERRA,
        model: record.model,
        className: 'sierra-stimulus-editable-cell-value-wrap',
        stimulusModel: {/* model */ },
        customInput: PinNodeConnect,
        pinModels: JSON.parse(JSON.stringify(record.pinModels || [])),//{type:type-seed}
        modelPins: JSON.parse(JSON.stringify(pinModels || [])),//{type,seed}
        displayType: "model",
        vectorList: vectorList,
        dataIndex: 'inputStimulus',
        libraryList: spiceList,
        getPins: getPins,
        getLibraryFile: getSpiceModelList,
        getFolderFileDetail,
        stimulus: this.getStimulus(record),
        saveModelPins: this.saveModelPins,
        savePinStimulusModel: this.savePinStimulus,
        clock,
        tdClassName: (record.edited || []).includes('stimulus') ? "sweeping-table-td-font-color" : ""
      } : {
        edit: false,
        tdClassName: (record.edited || []).includes('stimulus') ? "sweeping-table-td-font-color" : ""
      }
    }

    columns[5].render = (text, record) => {
      let _text = null
      if (chipModelUsages.includes(record.usage) && record.model && record.model.libType === _IBIS) {
        _text = <span>{text}</span>
      } else {
        _text = <span className='sierra-corner-row-disabled'></span>
      }
      return _text;
    }

    columns[5].onCell = (record) => {
      if (chipModelUsages.includes(record.usage) && record.exsit !== false && record.model && record.model.libType === _IBIS/*  && this.props.rowName !== "Base" */) {
        if (this.props.rowName === "Base") {
          return {
            record,
            edit: 'select',
            options: CORNER,
            dataIndex: "corner",
            selectMode: 'multiple',
            handleSave: (row) => this.addMoreSelect(row, 'corner', record),
            tdClassName: (record.edited || []).includes('corner') ? "sweeping-table-td-font-color" : ""
          };
        }
        return {
          record,
          edit: "select",
          options: CORNER,
          dataIndex: "corner",
          handleSave: (row) => this.editSelect(row, 'corner'),
          tdClassName: (record.edited || []).includes('corner') ? "sweeping-table-td-font-color" : ""
        };
      } else {
        return {
          edit: false,
          tdClassName: (record.edited || []).includes('corner') ? "sweeping-table-td-font-color" : ""
        }
      }
    };

    this.columns = columns;
  }

  addMoreSelect = (row, type, record) => {
    const { parentIndex } = this.props;
    const { component, pin } = row;
    const value = row[type];
    const defaultValue = record[type];
    const addRowList = value.filter(item => item !== defaultValue)
    const interfaceIndex = parentIndex > -1 ? parentIndex : 0;

    const modelsList = addRowList.map(item => {
      return { component, pinInfo: [{ corner: item, pin }] }
    })
    this.props.createExpsByModel(modelsList, interfaceIndex)
  }

  closeModal = () => {
    const { tempChange, modelChange } = this.state;
    const { experimentId, parentIndex, rowName } = this.props;
    if (rowName !== "Base") {
      this.props.updateSingleExperiment({ id: experimentId, info: tempChange, parentIndex, model: modelChange }, 'pins');
    }
    this.props.save()
  }

  assignModel = ({ record, model, deviceVcc, pinModelsInfo, applyAll, powerOff }) => {
    const { component, pin } = record;

    const newTempChange = [...this.state.tempChange];
    let comp = newTempChange.find(item => item.component === component);

    const isModelUpdate = judgeModelUpdate(record.model, model || {});

    let isPowerOffUpdate = false, isPUUpdate = false;

    const { pu, pd, pinModels: newPinModels } = pinModelsInfo;
    if (record.usage === "Driver") {
      isPowerOffUpdate = powerOff !== record.powerOff;

      const findPU = (record.pinModels || []).find(item => item.pinName === "nd_pu") || {};
      const findPD = (record.pinModels || []).find(item => item.pinName === "nd_pd") || {};
      isPUUpdate = (powerOff === "1" || model.libType === "SPICE") && (pu !== findPU.voltage || pd !== findPD.voltage);
    }

    if (!isModelUpdate && !isPowerOffUpdate && !isPUUpdate) {
      return;
    }

    const findPUIndex = (newPinModels || []).findIndex(item => item.pinName === "nd_pu");
    const findPDIndex = (newPinModels || []).findIndex(item => item.pinName === "nd_pd");
    let newPU;
    if (findPUIndex > -1) {
      newPU = newPinModels[findPUIndex].voltage;
      newPinModels[findPUIndex].voltage = (powerOff === "1" || model.libType === "SPICE") && pu ? pu : newPinModels[findPUIndex].voltage;
    }
    if (findPDIndex > -1) {
      newPinModels[findPDIndex].voltage = (powerOff === "1" || model.libType === "SPICE") && pd ? pd : newPinModels[findPDIndex].voltage;
    }
    deviceVcc = deviceVcc || newPU || pu;

    let pinMap = [];
    const allPinMap = this.getPinMap(component)
    if (applyAll) {
      pinMap = allPinMap
    } else {
      pinMap = [allPinMap.find(item => item.pin === pin)].filter(item => !!item)
    }
    if (comp && comp.pinInfo) {
      pinMap.forEach(pinItem => {
        const _pin = comp.pinInfo ? comp.pinInfo.find(p => p.pin === pinItem.pin) : null;
        if (_pin) {
          _pin['model'] = model;
          _pin['deviceVcc'] = deviceVcc;
          _pin.powerOff = powerOff;
          _pin.pinModels = this.updatePinModels(_pin, pinItem, newPinModels);
        } else {
          const pinModels = this.updatePinModels(null, pinItem, newPinModels);
          const _model = {
            pin: pinItem.pin,
            model,
            deviceVcc,
            powerOff,
            pinModels: JSON.parse(JSON.stringify(pinModels || []))
          }
          comp.pinInfo.push({ ..._model });
        }
      })
    } else {
      const pinInfo = pinMap.map(pinItem => {
        const pinModels = this.updatePinModels(null, pinItem, newPinModels);
        return { pin: pinItem.pin, model, deviceVcc, pinModels: JSON.parse(JSON.stringify(pinModels || [])), powerOff }
      })
      newTempChange.push({ component, pinInfo })
    }
    const newModelChange = [...this.state.modelChange];
    comp = newModelChange.find(item => item.component === component);
    const { libraryId, fileName, libType, version } = model;
    const files = [{ libraryId, fileName, type: libType, version }];
    if (comp && comp.modelInfo) {
      comp.modelInfo = { component, modelInfo: { files, pairs: [] } };
    } else {
      newModelChange.push({ component, modelInfo: { files, pairs: [] } })
    }
    this.setState({
      tempChange: newTempChange,
      modelChange: newModelChange
    })
  }

  updatePinModels = (_pin, pinItem, newPinModels) => {
    const usage = _pin && _pin.usage ? _pin.usage : pinItem.usage;
    let pinModels = _pin && _pin.pinModels ? _pin.pinModels : [];

    if (!pinModels.length) {
      pinModels = JSON.parse(JSON.stringify(pinItem.pinModels || []));
    }
    if (usage === "Driver" && (!pinModels || !pinModels.length)) {
      pinModels = getPins({ type: (_pin.model || {}).modelType || "I/O", usage });
    }

    const findNewPU = (newPinModels || []).find(item => item.pinName === "nd_pu");
    const findNewPD = (newPinModels || []).find(item => item.pinName === "nd_pd");

    const findPU = (pinModels || []).findIndex(item => item.pinName === 'nd_pu');
    const findPD = (pinModels || []).findIndex(item => item.pinName === 'nd_pd');
    if (findPU > -1) {
      pinModels[findPU].voltage = findNewPU && findNewPU.voltage ? findNewPU.voltage : pinModels[findPU].voltage;
    }
    if (findPD > -1) {
      pinModels[findPD].voltage = findNewPD && (findNewPD.voltage || findNewPD.voltage === 0) ? findNewPD.voltage : pinModels[findPD].voltage;
    }
    return JSON.parse(JSON.stringify(pinModels || []));
  }

  saveModelPins = ({ record, pinModels, applyAll }) => {
    const { component, pin } = record;
    const newTempChange = [...this.state.tempChange];
    const comp = newTempChange.find(item => item.component === component);
    let pinMap = []
    const allPinMap = this.getPinMap(component)
    if (applyAll) {
      pinMap = allPinMap
    } else {
      pinMap = [allPinMap.find(item => item.pin === pin)].filter(item => !!item);
    }
    if (comp && comp.pinInfo) {
      pinMap.forEach(pin => {
        const _pin = comp.pinInfo ? comp.pinInfo.find(p => p.pin === pin.pin) : null;
        if (_pin) {
          _pin['pinModels'] = JSON.parse(JSON.stringify(pinModels || []));
        } else {
          comp.pinInfo.push({ pin: pin.pin, pinModels });
        }
      })
    } else {
      newTempChange.push({ component, pinInfo: pinMap.map(pin => ({ pin: pin.pin, pinModels: JSON.parse(JSON.stringify(pinModels || [])) })) })
    }
    this.setState({
      tempChange: newTempChange
    })
  }

  savePowerOff = ({ record, powerOff, applyAll }) => {
    /* const { component, pin } = record;
    const newTempChange = [...this.state.tempChange];
    const comp = newTempChange.find(item => item.component === component);
    let pinMap = [pin]
    if (applyAll) {
      pinMap = this.getPinMap(component)
    }
    if (comp && comp.pinInfo) {
      pinMap.forEach(pin => {
        const _pin = comp.pinInfo ? comp.pinInfo.find(p => p.pin === pin.pin) : null;
        if (_pin) {
          _pin['powerOff'] = powerOff;
        } else {
          comp.pinInfo.push({ pin:pin.pin, powerOff });
        }
      })
    } else {
      newTempChange.push({ component, pinInfo: pinMap.map(pin => ({ pin:pin.pin, powerOff })) })
    }
    this.setState({
      tempChange: newTempChange
    }) */
  }

  saveChipModel = ({ files, libType, pairs, record }) => {
    const { component } = record;
    const newTempChange = [...this.state.tempChange];
    let comp = newTempChange.find(item => item.component === component);
    let pinMap = this.getPinMap(component)
    if (comp && comp.pinInfo) {
      pinMap.forEach(pin => {
        const _pin = comp.pinInfo ? comp.pinInfo.find(p => p.pin === pin.pin) : null;
        if (_pin) {
          _pin['model'] = { libType };
        } else {
          comp.pinInfo.push({ pin: pin.pin, model: { libType } });
        }
      })
    } else {
      newTempChange.push({ component, pinInfo: pinMap.map(pin => ({ pin: pin.pin, model: { libType } })) })
    }
    const newModelChange = [...this.state.modelChange];
    comp = newModelChange.find(item => item.component === component);
    if (comp && comp.modelInfo) {
      comp.modelInfo = { files, pairs };
    } else {
      newModelChange.push({ component, modelInfo: { files, pairs } })
    }
    this.setState({
      tempChange: newTempChange,
      modelChange: newModelChange
    })
  }

  getStimulus = (record) => {
    const pins = record.pinModels;
    let stimulus, pinName;
    if (!pins) {
      return { stimulus, pinName };
    }
    for (let pin of pins) {
      if (pin.stimulus) {
        pinName = pin.pinName;
        stimulus = pin.stimulus;
      }
    };
    return { stimulus, pinName }
  }

  savePinStimulus = (model, record) => {
    const { component, pinModels, usage, pin } = record;
    const { files, pairs } = model;
    const newTempChange = [...this.state.tempChange];
    const comp = newTempChange.find(item => item.component === component);
    const currentPin_in = pairs.find(item => item.pin === `${pin}_in`);
    let _pinModels = pinModels && pinModels.length ? pinModels : getPins({ type: MultiPinSPICE, usage });
    const pinIndex = _pinModels.findIndex(item => item.pinName === 'nd_in');
    _pinModels[pinIndex].type = MultiPinSPICE;
    const file = files.find(item => item.libraryId === currentPin_in.libraryId);
    _pinModels[pinIndex].stimulus = {
      ...currentPin_in,// libraryId, fileName, subckt, pin, node
      folder: file && file.folder ? file.folder : ""
    }
    if (comp && comp.pinInfo) {
      const info = comp.pinInfo.find(item => item.pin === pin);
      if (info) {
        info.pinModels = _pinModels
      } else {
        comp.pinInfo.push({ pin, pinModels: _pinModels })
      }
    } else {
      newTempChange.push({ component, pinInfo: [{ pin, pinModels: _pinModels }] })
    }
    this.setState({
      tempChange: newTempChange
    })
  }

  editSelect = (row, type) => {
    const { component, pin } = row;
    const value = row[type]
    const newTempChange = [...this.state.tempChange];
    const comp = newTempChange.find(item => item.component === component);
    const info = type === 'usage' ? value === 'Receiver' ? [{ pin, [type]: value, pinModels: [], model: {} }] :
      [{ pin, [type]: value, model: {} }] : [{ pin, [type]: value }];
    if (comp) {
      const _pin = comp.pinInfo ? comp.pinInfo.find(p => p.pin === pin) : null;
      if (_pin) {
        _pin[type] = value;
        if (type === 'usage') {
          _pin.model = {};
          if (value === 'Receiver') {
            _pin.pinModels = [];
          }
        }
      } else if (!comp.pinInfo) {
        comp.pinInfo = info;
      } else {
        comp.pinInfo.push(...info);
      }
    } else {
      newTempChange.push({
        component, pinInfo: info
      });
    }
    this.setState({
      tempChange: newTempChange
    })
  }

  getPinMap = (component) => {
    const { record: data, parentIndex } = this.props;
    const pinList = data[parentIndex > -1 ? `pins_${parentIndex}` : `pins`].find(comp => comp.component === component);
    return pinList ? pinList.pinInfo : [];
  }

  getMultiSpicePins = (component, usage, parentIndex) => {
    const { record } = this.props;
    const { tempChange } = this.state;
    let findC = record[parentIndex > -1 ? `pins_${parentIndex}` : `pins`].find(item => item.component === component);
    const findT = tempChange.find(item => item.component === component);
    if (findT && findT.pinInfo && findC && findC.pinInfo) {
      findC = JSON.parse(JSON.stringify(findC))
      findC.pinInfo.forEach(pin => {
        const newInfo = findT.pinInfo.find(item => item.pin === pin.pin);
        if (newInfo) {
          pin.usage = newInfo.usage ? newInfo.usage : pin.usage;
          pin.pinModels = newInfo.pinModels ? newInfo.pinModels : pin.pinModels;
        }
      })
    }
    return findC && findC.pinInfo ? findC.pinInfo.filter(item => item.usage === usage) : [];
  }

  getStimulusModel = (compName, parentIndex) => {
    const { record } = this.props;
    const { modelChange } = this.state;
    const model = record[parentIndex > -1 ? `model_${parentIndex}` : `model`].find(item => item.component === compName);
    const _model = modelChange ? modelChange.find(item => item.component === compName) : null;
    return _model ? _model.modelInfo : model.modelInfo;
  }

  renderDialog() {
    const { record, parentIndex, rowName } = this.props;
    const { tempChange } = this.state;
    const dataSource = getSweepingPinData(record, parentIndex, tempChange, rowName);
    const content = (
      <Panel
        className='sierra-sweeping-pin-panel sierra-panel'
        title={<span><label style={{ color: '#2d5eb2' }}>{rowName}</label> - Pin Buffer Models</span>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={1100}
        minWidth={1000}
        minHeight={200}
        position='panel-center'
        draggable
      >
        <Table
          className='sierra-pin-table sierra-sweeping-pin-table'
          rowKey={record => record.pcb
            ? (record.pcb + "_" + record.component + "_" + record.pin)
            : (record.component + "_" + record.pin)}
          columns={this.columns}
          dataSource={dataSource}
          size="small"
          rowClassName={(record) => record.exsit === false ? "sweeping-model-not-exsit" : ""}
        />
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { inputRef } = this;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {this.props.text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default PinModelPanel;