import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { newInterface } from '../../store/actionCreators';

const InterfacesFooter = (props) => {

  const { newInterface, exportInterface, uploadInterface } = props;
  return (
    <Fragment>
      {/* <Button
        type='primary'
        onClick={uploadInterface}
      >Upload</Button>
      <Button
        type='primary'
        onClick={exportInterface}
      >Export</Button> */}
      <Button
        type='primary'
        onClick={newInterface}
      >New</Button>
    </Fragment>
  )
}


const mapState = (state) => {
  return {}
}


const mapDispatch = (dispatch) => ({
  newInterface() {
    dispatch(newInterface())
  },
  uploadInterface() {
  },
  exportInterface() {

  }
})

export default connect(mapState, mapDispatch)(InterfacesFooter);