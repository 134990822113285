import {
  TOUCHSTONE_RUNNING_LIST,
  LIBRARY_TREE_NEED_UPDATE,
  LIBRARY_FOLDER_CREATE,
  UPDATE_FILE_CONTENT,
  UPDATE_DEFAULT_LIBRARY,
  UPDATE_REPEATER_FILE_STATUS
} from "./actionTypes";

const defaultState = {
  touchstoneRunningList: [],
  libraryTreeInfo: null,
  folderCreateInfo: null,
  updateFileContent: false,
  defaultBufferSpice: null,
  isUpdateRepeaterFile: false
}

export const SierraLibraryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case TOUCHSTONE_RUNNING_LIST:
      return {
        ...state,
        touchstoneRunningList: action.list
      }
    case LIBRARY_TREE_NEED_UPDATE:
      return {
        ...state,
        libraryTreeInfo: action.treeType
      }
    case LIBRARY_FOLDER_CREATE:
      return {
        ...state,
        folderCreateInfo: action.createInfo // type, parent folder path, parent folder Id
      }
    case UPDATE_FILE_CONTENT:
      return {
        ...state,
        updateFileContent: action.isUpdate
      }
    case UPDATE_DEFAULT_LIBRARY:
      return {
        ...state,
        defaultBufferSpice: action.info
      }
    case UPDATE_REPEATER_FILE_STATUS:
      return {
        ...state,
        isUpdateRepeaterFile: action.isUpdateRepeaterFile
      }
    default:
      return { ...state }
  }
}