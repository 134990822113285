import { message } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import { SUCCESS, CHECK_FAIL, NO_PERMISSION, NOT_FOUND, JWT_EXPIRED, UNKNOWN_EXCEPTION, LICENSE_CHECK } from '../../constants/returnCode';
import checkError, { INTERNAL_SERVER_ERROR, ERROR1, ERROR2 } from './checkError';
import DelConfirm from '../../components/DelConfirm';
import TransparentBox from '../../components/TransparentBox';
import { checkoutLicensePromise } from '../../services/License/licenseCtrl';
import { getLinkByPageType, getPageTypeByUrl } from "../../services/helper/data/permissionData";
import history from '../../services/history';
import debounce from '../../services/helper/debounceFn';
import axios from 'axios';
// import history from '../history';

/**
 * Resolve - data, Reject - message
 * @export
 * @param {*} fn function
 * @param {*} params
 * @returns 
 */
export default function apiProcess(fn, params, printError = false, getError = false, returnFile = false, allowCancel = false) {

  let _promise = null, cancelFn = null;
  if (allowCancel) {
    const { promise, cancel } = fn(params);
    _promise = promise;
    cancelFn = cancel;
    return {
      promise: apiProcessPromise(_promise, { printError, returnFile, getError }),
      cancel: cancelFn
    }
  } else {
    _promise = fn(params);
    return apiProcessPromise(_promise, { printError, returnFile, getError });
  }
};

function apiProcessPromise(promise, { printError, returnFile, getError }) {
  return new Promise((resolve, reject) => {
    promise.then(res => {
      if (res.data) {
        if (returnFile) {
          resolve(res.data);
        } else {
          switch (res.data.code) {
            case SUCCESS:
              resolve(res.data.data);
              break;
            case CHECK_FAIL:
            case NO_PERMISSION:
            case NOT_FOUND:
            case JWT_EXPIRED:
            case UNKNOWN_EXCEPTION:
              reject(res.data.msg || '');
              break;
            case LICENSE_CHECK:
              console.error(res.data.msg)
              reject('');
              break;
            default:
              reject(res.data.msg || '')
              break;
          }
        }
      } else {
        reject(ERROR1 + INTERNAL_SERVER_ERROR);
      }
    }, error => {
      if (axios.isCancel(error)) {
        console.error("Request Cancelled!");
        if (printError) {
          message.error(ERROR2 + "Request Cancelled!");
        }
        reject(getError ? "Request Cancelled!" : '');
      } else {
        console.error(ERROR2 + error);
        const msg = checkError(error);
        if (msg && printError) {
          message.error(ERROR2 + msg)
        } // else response.status === 401/403
        reject(getError ? msg : '');
      }
    });
  })
}

const div = document.createElement('div');
let showError = false;
function removeError() {
  ReactDOM.unmountComponentAtNode(div);
  showError = false;
}

function getMsg() {
  return `There is no available license at this time. Please try again later.`
}

function licenseTimeout() {
  return 'License inactivity time-out (idle more than 30 minutes) is detected, license is checked in.';
}

export function showLicenseErrorMsg(timeout = false) {
  if (!showError) {
    showError = true;
    const msg = timeout ? licenseTimeout() : getMsg();
    return ReactDOM.render(<DelConfirm deleteConfirm={removeError} message={msg} removeCancel={true} />, div);
  }
}

const transparentBoxDiv = document.createElement('div');
function removeTransparentDiv() {
  const element = document.getElementById('transparent-box-div');
  if (element) {
    element.removeEventListener('mousemove', userBehaviour, true);
    element.removeEventListener('mousewheel', userBehaviour, true);
    element.removeEventListener('mousedown', userBehaviour, true);
  }
  ReactDOM.unmountComponentAtNode(transparentBoxDiv);
  showBox = false;
}

export function userBehaviour() {
  debounce(async () => {
    const page = getPageTypeByUrl(history.location.pathname);
    const license = await checkoutLicensePromise(page);
    if (license) {
      history.push(getLinkByPageType(page));
    } else {
      history.push('/');
      showLicenseErrorMsg();
    }
    removeTransparentDiv();
  }, 5000, true, 'userBehaviorTimer')();
}

let showBox = false;
export function showTransparentBox() {
  if (!showBox) {
    showBox = true;
    return ReactDOM.render(<TransparentBox />, transparentBoxDiv)
  }
}