import React, { Component, Fragment } from "react";
import Panel from "@/components/Panel";
import { createPortal } from 'react-dom';
import EditableTable from "@/components/EditableTable";
import { CloseOutlined } from '@ant-design/icons';
import { Tooltip, Button } from "antd";
import { getDefaultIndex } from "../../../../services/helper/setDefaultName";

const columns = [{
  key: "input",
  dataIndex: "input",
  title: "Input",
  width: "50%"
}, {
  key: "output",
  dataIndex: "output",
  title: "Output",
  width: "50%"
}]
class PinMapConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinMap: [],
      pinList: [],
      model: {}
    }
    this.dialogRoot = document.getElementById('root');

    columns[0].render = (value, record) => {

      if (record.index === "Add") {
        return <Button
          type="primary"
          className="sierra-comp-pin-map-add-button"
          onClick={(e) => { this.addNewPinMap(e) }}
        >Add a row</Button>
      }
      const pinInfo = record.input ? record.input[0] || {} : {};

      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {pinInfo.pin}</div>
          <div>Pin Name: {pinInfo.pinName}</div>
        </div>}
      >
        <div className="sierra-components-pin-map-columns">{this.getPinMapText(pinInfo)}</div>
      </Tooltip>
    }

    columns[0].onCell = (record) => {
      if (record.index === "Add") {
        return { edit: false }
      }
      /*  const { pinList } = this.state;
       const options = pinList.map(item => item.pinName ? `${item.pin} (${item.pinName})` : item.pin); */
      const pinInfo = record.input ? record.input[0] || {} : {}
      return {
        record: { ...record, input: pinInfo.pinName ? `${pinInfo.pin} (${pinInfo.pinName})` : pinInfo.pin, inputObj: record.input },
        edit: "select",
        options: this.getPinList(),
        dataIndex: "input",
        allowClear: true,
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "sierra-components-pin-map-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "input") },
      }
    }

    columns[1].onCell = (record) => {
      if (record.index === "Add") {
        return { edit: false }
      }
      /* const { pinList } = this.state; */
      /* const options = pinList.map(item => item.pinName ? `${item.pin} (${item.pinName})` : item.pin); */
      const pinInfo = record.output ? record.output[0] || {} : {}
      return {
        record: { ...record, output: pinInfo.pinName ? `${pinInfo.pin} (${pinInfo.pinName})` : pinInfo.pin, outputObj: record.output },
        edit: "select",
        options: this.getPinList(),
        dataIndex: "output",
        getPopupContainer: document.getElementById('root'),
        allowClear: true,
        dropdownMenuClassName: "sierra-components-pin-map-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "output") },
        handleClear: (_record) => { this.editPinMap(_record, null, "output", true) }
      }
    }

    columns[1].render = (value, record) => {
      if (record.index === "Add") {
        return null
      }
      const pinInfo = record.output ? record.output[0] || {} : {};
      return (
        <Fragment>
          <Tooltip
            overlayClassName="aurora-tooltip"
            title={<div>
              <div>Pin Number: {pinInfo.pin}</div>
              <div>Pin Name: {pinInfo.pinName}</div>
            </div>}
          >
            <div className="sierra-components-pin-map-columns">{this.getPinMapText(pinInfo)}</div>
          </Tooltip>
          <CloseOutlined
            className="delete-icon"
            onClick={(e) => this.deletePinMap(e, record.index)} />
        </Fragment >
      );
    }
  }

  getPinMapText = (pinInfo) => {
    if (!pinInfo.pin) {
      return "";
    }
    return pinInfo.pinName ? `${pinInfo.pin} (${pinInfo.pinName})` : pinInfo.pin;
  }

  getPinList = () => {
    const { pinList, pinMap } = this.state;
    let list = [];
    for (let pinItem of pinList) {
      const findPin = pinMap.find(item => (item.input && item.input.find(it => it.pin === pinItem.pin))
        || (item.output && item.output.find(it => it.pin === pinItem.pin)));
      if (findPin) {
        continue;
      }
      const pinStr = pinItem.pinName ? `${pinItem.pin} (${pinItem.pinName})` : pinItem.pin;
      list.push(pinStr)
    }
    return list;
  }

  editPinMap = (record, prevRecord, type, clear) => {
    const { pinMap, model } = this.state;
    let _pinMap = JSON.parse(JSON.stringify(pinMap || [])), _model = model || {};
    const index = _pinMap.findIndex(item => item.index === record.index);
    const prevPin = _pinMap[index][type] && _pinMap[index][type][0] && _pinMap[index][type][0].pin ? _pinMap[index][type][0].pin : null;
    if (clear || !record[type]) {
      _model.pairs = this.updatePairs({
        pairs: _model.pairs || [],
        prevPin,
        newPin: ""
      })
      _pinMap[index][type] = [{ pin: "", pinName: "" }];

      this.setState({
        pinMap: _pinMap
      })
      return;
    }
    const otherType = type === 'input' ? "output" : 'input';
    const [newPin, pinName] = record[type].split(" (");
    const newPinName = pinName ? pinName.split(")")[0] : "";
    if (!newPin) {
      return;
    }

    const findPin = _pinMap.find(item =>
      (item[otherType] && item[otherType].find(it => it.pin === newPin))
      || (item[type] && item[type].find(it => it.pin === newPin)));

    let error = "";
    if (findPin) {
      error = `Pin ${record[type]} already exists`;
      this.setState({
        error
      })
      return;
    }

    _model.pairs = this.updatePairs({
      pairs: _model.pairs,
      prevPin,
      newPin: newPin
    })

    _pinMap[index][type] = [{ pin: newPin, pinName: newPinName }];

    this.setState({
      pinMap: _pinMap,
      model: { ..._model }
    })
  }

  updatePairs = ({ pairs, prevPin, newPin }) => {
    if (!pairs || !pairs.length || !prevPin) {
      return pairs;
    }
    const index = pairs.findIndex(item => prevPin && item.pin === prevPin);
    if (index < 0) {
      return pairs;
    }
    if (!newPin) {
      pairs.splice(index, 1)
    } else {
      pairs[index].pin = newPin;
    }

    return pairs;
  }

  addNewPinMap = (e) => {
    e && e.stopPropagation();
    const { pinMap } = this.state;
    let _pinMap = JSON.parse(JSON.stringify(pinMap || []));
    _pinMap.push({
      input: [],
      output: [],
      index: getDefaultIndex(_pinMap.length, _pinMap.map(item => item.index))
    })
    this.setState({
      pinMap: _pinMap
    })
  }

  deletePinMap = (e, pinMapIndex) => {
    e && e.stopPropagation();
    const { pinMap, model } = this.state;
    let _pinMap = JSON.parse(JSON.stringify(pinMap || []));
    const index = _pinMap.findIndex(item => item.index === pinMapIndex);
    const deletedPins = [_pinMap[index].input[0] || "", _pinMap[index].output[0] || ""].filter(item => !!item);
    let _model = model || {};
    if (_model.pairs && _model.pairs.length) {
      _model.pairs = _model.pairs.filter(item => !deletedPins.includes(item.pin));
    }
    _pinMap = _pinMap.filter(item => item.index !== pinMapIndex);
    this.setState({
      pinMap: _pinMap,
      model: _model
    })
  }

  savePinMap = () => {
    const { pinMap, model } = this.state;
    const _pinMap = pinMap.map(item => {
      return {
        input: item.input.map(it => it.pin),
        output: item.output.map(it => it.pin)
      }
    })
    this.props.savePinMapInfo({ part: this.props.part, pinMap: _pinMap, model, position: this.props.position })
    this.props.save();
  }

  componentDidMount = () => {
    this.setDefaultData()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.part !== this.props.part || prevProps.position !== this.props.position) {
      this.setDefaultData()
    }
  }

  setDefaultData = () => {
    const { components, pinMap, model } = this.props;
    if (!components || !components[0]) {
      this.setState({
        pinList: [],
        pinMap: [],
        model: {}
      })
      return;
    }
    const pins = components[0].pins ? [...components[0].pins.values()] : [];
    let pinList = pins.map(item => {
      return {
        pin: item.pin,
        pinName: item.pinName
      }
    });
    pinList = pinList.sort((a, b) => Number(a.pin) - Number(b.pin));
    let _pinMap = [], index = 0;
    for (let pinItem of pinMap) {
      const inputItem = (pinItem.input || []).map(item => {
        return {
          pin: item,
          pinName: (pinList.find(it => it.pin === item) || {}).pinName
        }
      }),
        outputItem = (pinItem.output || []).map(item => {
          return {
            pin: item,
            pinName: (pinList.find(it => it.pin === item) || {}).pinName
          }
        });
      index += 1;
      _pinMap.push({
        index: index.toString(),
        input: inputItem,
        output: outputItem
      })
    }
    this.setState({
      pinList,
      pinMap: _pinMap,
      model: model || {}
    })
  }

  render = () => {
    const { part, position } = this.props;
    const { pinMap } = this.state;

    const content = <Panel
      title={`Pin Map - ${part}${position ? ` - ${position}` : ""}`}
      className='sierra-comp-pin-map-panel sierra-panel'
      position='panel-center-left'
      zIndex={2000}
      onCancel={() => { this.savePinMap() }}
      width={700}
      draggable
      minHeight={200}
      maxHeight={800}
    >
      <div className="sierra-comp-pin-map-content">
        <EditableTable
          columns={columns}
          dataSource={[...(pinMap || []), { index: "Add" }]}
          className="sierra-comp-pin-map-table"
          rowKey={(record) => record.index}
        />
      </div>
    </Panel>
    return createPortal(content, this.dialogRoot)
  }
}

export default PinMapConnection;