import { getSystemLibraryData, getSystemLibrarySearch, getSystemLibraryBulkSearch } from '../api/v2/libraryCtrl';
import hash from 'object-hash';
import apiProcess from '../api/utility';

function getSystemLibraryByPage(page, vendor) {
  return apiProcess(getSystemLibraryData, { page, vendor })
}

function getSysLibBySearch(page, keyword) {
  return apiProcess(getSystemLibrarySearch, { page, keyword })
}

function getSysLibByBulkSearch(searchParams) {
  return apiProcess(getSystemLibraryBulkSearch, searchParams);
}

class SystemLibraryList {
  constructor(props) {
    this.pageCount = null;
    this.libraryList = new Map(); // key - vendor-page, value - array

    this.searchCount = null;
    this.searchValue = null;
    this.searchList = new Map(); // key - page, value - array
  }

  getPageList = async (page, vendor) => {
    const _page = `${vendor}-${page}`
    const pageData = this.libraryList.get(_page);
    if (pageData) {
      return { pageData, pageCount: this.pageCount };
    } else {
      try {
        const data = await getSystemLibraryByPage(page, vendor);
        const { librariesData = null, pageCount = null } = data
        if (!this.pageCount) {
          this.pageCount = pageCount;
        }
        this.libraryList.set(_page, librariesData);
        return { pageData: librariesData, pageCount }
      } catch (error) {
        return { pageData: [], pageCount: this.pageCount }
      }
    }
  }

  getSearchList = async (page, keyword) => {
    if (this.searchValue && hash(this.searchValue) !== hash(keyword)) {
      this.searchCount = null;
      this.searchList = new Map(); // key - page, value - array
    }
    const pageData = this.searchList.get(page);
    this.searchValue = keyword;
    if (pageData) {
      return { pageData, pageCount: this.searchCount };
    } else {
      try {
        const data = await getSysLibBySearch(page, keyword);
        const { librariesData = null, pageCount = null } = data
        if (!this.searchCount) {
          this.searchCount = pageCount;
        }
        this.searchList.set(page, librariesData);
        return { pageData: librariesData, pageCount }
      } catch (error) {
        return { pageData: [], pageCount: this.searchCount }
      }
    }
  }

}

const SystemLibList = new SystemLibraryList();
export default SystemLibList;

export {
  getSysLibBySearch,
  getSysLibByBulkSearch
}