import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Popover, message } from 'antd';
import EditableTable from '@/components/EditableTable';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import RLCModel from './rlcModel';
import {
  updateInterfaces,
  saveSplitComponents,
  saveMergeComponents,
  updateCompComponents
} from '../../store/sierra/action';
import { selectChange, selectLayer, changeDisplaySelected } from '../../../LayoutExplorer/store/Sierra/actionCreators';
import { SortFn } from '@/services/helper/sort';
import TableTag from '@/components/TableTag';
import { isMac } from '@/services/api/userAgent';
import { getSplitPartName } from '@/services/helper/setDefaultName';
import canvas from '@/services/LayoutCanvas';
import { RLC, CAP, IGNORE, IND, RES, UNUSED } from '@/constants/componentType';
import { getRLCValueByType } from '../../../../services/Sierra/setupHelper';
import { formatRLCValue } from '../../../../services/RLCModel';
import { getComponentsByPCB } from '../../../../services/Sierra';
import { getCompType } from '../../../../services/Designs/compTypeHelper';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import compTableHelper from '../../../../services/Sierra/helper/compTableHelper';
import { SIERRA } from '../../../../constants/pageType';
import '../index.css';

const powerCompsColumns = [{
  title: 'Part Name',
  dataIndex: 'part',
  width: '25%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Components',
  dataIndex: 'comps',
  width: '27%',
}, {
  title: 'Usage',
  dataIndex: 'type',
  width: '20%',
  sorter: (a, b) => a.type.localeCompare(b.type)
}, {
  title: 'Model',
  dataIndex: 'value',
  width: '28%',
}];

const RLCType = [RLC, CAP, IND, RES];

class PowerComponents extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mergePartList: [],
      mergeVisible: false,
      mergeType: ''
    }

    powerCompsColumns[0].render = (text, record) => {
      const { mergeVisible, mergePartList } = this.state;
      if (RLCType.includes(record.type) && mergePartList && mergePartList.length > 0 && mergePartList[0] === record.part) {
        return <Popover
          placement="topLeft"
          overlayClassName='merge-part-popover'
          getPopupContainer={() => document.getElementById('sierra-power-component-row')}
          content={<Fragment>
            <Tooltip title='Merge Part' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className='merge-part-popover-span' onClick={(e) => this.MergePart(e, record)}>Merge</span>
            </Tooltip>
          </Fragment>}
          open={mergeVisible}
        >
          <span onClick={(e) => this.tableOnRow(e, record)} className='cursor-pointer sierra-part-name'>{record.part}</span>
        </Popover>
      } else if (RLCType.includes(record.type)) {
        return <span className='cursor-pointer sierra-part-name' onClick={(e) => this.tableOnRow(e, record)}>{record.part}</span>
      } else {
        return <span className='sierra-part-name'>{record.part}</span>
      }
    }

    powerCompsColumns[1].onCell = (record) => {
      const { comps } = record;
      let compsName = [];
      comps.map(item => {
        if (item.type !== UNUSED) {
          compsName.push({
            name: item.name,
            checked: true
          });
        } else {
          compsName.push({
            name: item.name,
            checked: false
          });
        }
        return null;
      });

      return RLCType.includes(record.type) ? {
        record,
        compsName,
        edit: true,
        customInput: TableTag,
        dataIndex: "comps",
        popupContainer: 'sierra-content-main',
        changeCompsChecked: this.changeCompsChecked,
        //changeDecapRemove: this.changeDecapRemove,
        select: this.componentSelect,
        viewList: this.props.viewList,
        splitComps: this.splitComps,
        onClick: (e) => this.spanClick(e),
        isPCBOpened: this.isPCBOpened
      } : null
    }

    powerCompsColumns[1].render = (comps, record) => {
      const mac = isMac();
      let tdRef = document.getElementById(`td-${record.index}`);
      const height = tdRef && tdRef.offsetHeight;
      let names = [];
      const isRLC = RLCType.includes(record.type);
      comps.forEach(item => {
        if (item.type !== UNUSED || !isRLC) {
          names.push(item.name);
        } else if (!isRLC) {
          names.push(item.name);
        }
      })
      return <div
        className='sierra-table-comp' id='sierra-power-component-row' >
        <span className={!isRLC ? 'sierra-comp-usage-rlc' : ""}
          onClick={!isRLC ? () => this.componentSelect(record, names) : null}>
          {names.length > 0 ? names.join(', ') : "Unstuffed"}
        </span>
        {mac && height && height > 88 ? <div className='sierra-component-arrow'></div> : null}
      </div>
    };

    powerCompsColumns[2].onCell = (record) => {
      if (!RLCType.includes(record.COMP_TYPE)) {
        const options = this.getCompType(record.type, record.COMP_TYPE);
        return {
          record,
          edit: 'select',
          options: options,
          dataIndex: 'type',
          handleSave: this.editPowerComps
        }
      } else {
        return {
          edit: false
        }
      }
    }

    powerCompsColumns[2].render = (text, record) => {
      if (!RLCType.includes(record.COMP_TYPE)) {
        return <span>{record.type}</span>
      } else {
        return <span className="sierra-comp-usage-rlc">{record.COMP_TYPE}</span>
      }
    }

    powerCompsColumns[3].render = (value, record) => {
      if (record.type === UNUSED) {
        return;
      }
      return <span>{formatRLCValue(record.type, value, record.model)}</span>
    };

    powerCompsColumns[3].onCell = (record) => {
      if (record && RLCType.includes(record.type)) {
        if (record.type === CAP) {
          return {
            record,
            edit: true,
            customInput: RLCModel,
            dataIndex: 'value',
            saveDecapModel: this.saveDecapModel
          };
        } else {
          return {
            record,
            edit: true,
            dataIndex: 'value',
            handleSave: this.editPowerComps
          };
        }
      } else {
        return {
          edit: false
        }
      }
    };
  }

  isPCBOpened = (record) => {
    const { pcbId } = record;
    const { selectedDesignIDs } = this.props;
    return selectedDesignIDs.includes(pcbId);
  }

  componentSelect = (record, names) => {
    const { pcbId } = record;
    if (this.isPCBOpened(record)) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer(names);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [...names] }, pcbId);
      this.props.changeDisplaySelected(true, pcbId)
    }
  }

  saveDecapModel = (record, value) => {
    const { comps, pcb, pcbId } = record;
    const { Interfaces } = this.props;
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let powerComponents = [...editInterface.powerComponents];
    comps.forEach(item => {
      const compIndex = powerComponents.findIndex(comp => comp.name === item.name);
      powerComponents[compIndex].value = value;
    });
    _Interfaces[_index].content.powerComponents = [...powerComponents];
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  changeCompsChecked = (compsName, checked, record) => {
    const { part, pcb, pcbId, type } = record;
    let chips = [...compsName];
    this.props._updateCompComponents({ part, chips, checked, _type: type, pcb, pcbId })
  }

  splitComps = (compList, record) => {
    const { part, pcb, pcbId } = record;
    let partName = part;
    const comps = this.getComponents();
    const newPartName = getSplitPartName({ components: comps, partName });
    this.setState({
      splitPartName: newPartName,
      mergePartList: []
    });
    this.props.saveSplitComponents({ part, splitPart: newPartName, comps: compList, pcb, pcbId });
    setTimeout(() => {
      this.setState({
        splitPartName: null
      });
    }, 3000);
  }

  MergePart = (e, record) => {
    e.stopPropagation();
    const { mergePartList } = this.state;
    const { pcb, pcbId } = record;
    if (mergePartList.length === 0) {
      return;
    }
    let partName = mergePartList[0];
    this.props.saveMergeComponents({ part: partName, partList: mergePartList, pcb, pcbId });
    this.setState({
      mergePartName: partName,
      mergePartList: []
    });
    setTimeout(() => {
      this.setState({
        mergePartName: null
      });
    }, 3000);
  }

  tableOnRow = (e, record) => {
    e.stopPropagation();
    const { part } = record;
    const { mergePartList, mergeVisible, mergeType } = this.state;
    if (mergePartList && mergePartList.length > 0) {
      if (mergeType !== record.type) {
        message.info("Please select components of the same type to merge")
        return;
      }
    }
    let partList = [...mergePartList], visible = mergeVisible;
    if (!mergePartList.includes(part)) {
      partList.push(part);
    } else {
      partList = partList.filter(item => item !== part);
    }
    if (partList.length > 1) {
      visible = true;
    } else {
      visible = false;
    }
    this.setState({
      mergePartList: partList,
      splitPartName: null,
      mergeVisible: visible,
      mergeType: record.type
    })
  }

  getCompType = (type, _type) => {
    let options = [];
    if (RLCType.includes(type)) {
      options = [type, UNUSED];
    } else if (type === UNUSED) {
      if (RLCType.includes(_type)) {
        options = [_type, UNUSED];
      } else {
        options = [CAP, IND, RES, UNUSED];
      }
    }
    return options;
  }

  editPowerComps = (row) => {
    const { comps, pcb, pcbId, type, value } = row;
    const { Interfaces } = this.props;
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let powerComponents = [...editInterface.powerComponents];
    comps.forEach(item => {
      const compIndex = powerComponents.findIndex(comp => comp.name === item.name);
      powerComponents[compIndex].type = type;
      powerComponents[compIndex].value = getRLCValueByType({ newType: type, prevValue: value, part: powerComponents[compIndex].part });
    });
    _Interfaces[_index].content.powerComponents = [...powerComponents];
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
  }

  getComponents = () => {
    const { current, compPrefixLibInfo, compPinMap = {}, Interfaces } = this.props;
    let _components = getComponentsByPCB(Interfaces, current, "powerComponents");
    let _partComps = [];// [{part, comps, value, type}]
    _components.forEach((comp) => {
      const { part, name, pins, pcbId, pcb } = comp;
      const type = comp.type === IGNORE ? UNUSED : comp.type;

      const pinList = auroraDBJson.getCompPinList(pcbId, name);
      const allPinsLength = pinList.length;
      const passiveTable = compTableHelper.get(pcbId);
      let COMP_TYPE = type;
      const findPassive = passiveTable.find(item => item.part === part);
      if (findPassive && findPassive.usage) {
        COMP_TYPE = findPassive.usage;
      } else {
        COMP_TYPE = getCompType({ compName: name, pinLength: allPinsLength, COMP_PREFIX_LIB: compPrefixLibInfo[pcbId], partName: part, product: SIERRA, compPinMap: compPinMap[pcbId] })
        COMP_TYPE = COMP_TYPE === IGNORE ? UNUSED : COMP_TYPE;
      }

      const _findIndex = _partComps.findIndex(item => item.part === part);
      const isRLC = RLCType.includes(COMP_TYPE);
      if (isRLC) {
        if (_findIndex > -1 && _partComps[_findIndex].type === COMP_TYPE) {
          _partComps[_findIndex].comps.push({ name, pins, type, allPinsLength });
        } else {
          _partComps.push({
            part,
            comps: [{ name, pins, type, allPinsLength }],
            type: COMP_TYPE,
            COMP_TYPE,
            value: comp.value,
            model: comp.model,
            pcb,
            pcbId
          });
        }
      } else {
        if (_findIndex > -1 && RLCType.includes(type) && _partComps[_findIndex].type === type) {
          _partComps[_findIndex].comps.push({ name, pins, type, allPinsLength });
        } else {
          _partComps.push({
            part,
            comps: [{ name, pins, type, allPinsLength }],
            type,
            COMP_TYPE,
            value: comp.value,
            model: comp.model,
            pcb,
            pcbId
          });
        }
      }
    });
    const sort = [CAP, RES, IND, UNUSED];
    _partComps = SortFn(_partComps, sort, "type");
    return _partComps;
  }

  render() {
    const { below } = this.props;
    const { mergePartList, splitPartName, mergePartName } = this.state;
    let _powerComponents = this.getComponents();
    let scrollX = null, scrollY = null, scroll = {};
    if (below && _powerComponents && _powerComponents.length && _powerComponents.length > 0) {
      scrollX = 760;
    }
    if (_powerComponents && _powerComponents.length && _powerComponents.length > 9) {
      scrollY = 342;
    }
    if (scrollY && scrollX) {
      scroll = {
        x: scrollX,
        y: scrollY
      }
    }

    if (!scrollY && scrollX) {
      scroll = {
        x: scrollX
      }
    }

    if (scrollY && !scrollX) {
      scroll = {
        y: scrollY
      }
    }

    const data = _powerComponents.map((item, index) => { return { ...item, index } });
    return (
      <EditableTable
        rowKey={(record) => `${record.part}-${record.type}-${record.index || ""}`}
        columns={powerCompsColumns}
        dataSource={data}
        size="small"
        className="space-10 sierra-power-comps-table"
        scroll={scroll}
        onRow={record => {
          let rowClassName = '';
          if (record.part === splitPartName && mergePartList.length === 0 && !mergePartName) {
            rowClassName = 'sierra-component-split-row'
          } else if (mergePartList.length > 0 && mergePartList.includes(record.part) && !mergePartName) {
            rowClassName = 'sierra-component-select-row'
          } else if (mergePartName && record.part === mergePartName) {
            rowClassName = 'sierra-component-merge-row'
          }
          return RLCType.includes(record.type) && {
            className: rowClassName
          };
        }}
      />
    )
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra, project: { selectedKeys } } } = state;
  const sierraInfo = sierra.sierraInfo;
  let Interfaces = [];
  if (sierraInfo) {
    Interfaces = sierraInfo.Interfaces || [];
  }
  return {
    Interfaces,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
  }
};


const mapDispatch = (dispatch) => ({
  updateInterfaces({ Interfaces, pcb, pcbId }) {
    dispatch(updateInterfaces({ Interfaces, pcb, pcbId }));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  saveSplitComponents({ part, splitPart, comps, pcb, pcbId }) {
    dispatch(saveSplitComponents({ part, splitPart, comps, pcb, pcbId, isPower: true }))
  },
  saveMergeComponents({ part, partList, pcb, pcbId }) {
    dispatch(saveMergeComponents({ part, partList, pcb, pcbId, isPower: true }))
  },
  _updateCompComponents({ part, chips, checked, _type, pcb, pcbId }) {
    dispatch(updateCompComponents({ part, chips, checked, _type, pcb, pcbId, isPower: true }))
  }
})

export default connect(mapState, mapDispatch)(PowerComponents);