import React, { Fragment } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown } from 'antd';
import { getSignalCheckedStatus } from './channelDisplayHelper';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import { SERDES_TYPES } from '../../PCBHelper';
import { CPHY } from '../../PCBHelper/constants';
import { areArraysEqual } from '../../helper/arrayHelper';
import { BGA, IC } from '../../../constants/componentType';

function getComponentRender(record, dataIndex) {
  const findPin = record[dataIndex] || {};
  return <span>{findPin.pin}</span>;
}

const CHECK_BOX_ALL = "all";
function getSignalCheckBox({
  record,
  checkSignals,
  serdesType
}) {

  const obj = {
    children: <div className='pcb-channel-table-checkBox'>
      <Checkbox
        checked={record.checked}
        onChange={(e) => checkSignals(e, record.signal)}
      />
    </div>,
    props: {},
  }
  if (record.signalDisplay) {
    obj.props.rowSpan = serdesType === CPHY ? 3 : 2;
  } else {
    obj.props.rowSpan = 0;
  }
  return obj;
}

function getSignalTitleCheckBox({
  selectedSignals,
  signals,
  checkSignals
}) {
  const groups = [...new Set(signals.map(item => item.group))];
  const { checked, indeterminate } = getSignalCheckedStatus(selectedSignals, signals);
  return <div className='pcb-channel-table-checkBox'>
    {groups.length > 1 ? <Dropdown
      menu={{ items: checkBoxIcon({ groups, checkSignals, selectedSignals, signals }) }}
      getPopupContainer={() => document.getElementById('andes-v2-content-main')}
    >
      <Checkbox
        indeterminate={indeterminate}
        checked={checked}
        onChange={(e) => checkSignals(e, CHECK_BOX_ALL)}
      />
    </Dropdown> : <Checkbox
      indeterminate={indeterminate}
      checked={checked}
      onChange={(e) => checkSignals(e, CHECK_BOX_ALL)}
    />}
  </div>
}

function checkBoxIcon({ groups, checkSignals, selectedSignals, signals }) {
  return groups.map(item => {
    const { indeterminate, checked } = getSignalCheckedStatus(selectedSignals, signals, item);
    return {
      key: item,
      label: <Checkbox
        indeterminate={indeterminate}
        checked={checked}
        onChange={(e) => checkSignals(e, item, "group")}
      >
        {item}
      </Checkbox>
    }
  })
}

function getSignalColumnRender({
  name,
  record,
  designVendor,
  delSignal,
  signalSelect,
  serdesType
}) {
  const obj = {
    children: <Fragment>
      <span>{name}</span>
      {designVendor !== PRE_LAYOUT ?
        <CloseOutlined className='signal-delete-icon' onClick={(e) => delSignal(e, record)} /> : null}
    </Fragment>,
    props: {}
  }
  if (record.signalDisplay) {
    obj.props.rowSpan = serdesType === CPHY ? 3 : 2;
    obj.props.tdClassName = 'channel-signal-name'
    obj.props.tdClick = (e) => signalSelect(record.signal)
  } else {
    obj.props.rowSpan = 0;
  }
  return obj;
}

function checkICComp(prevComponents, components) {
  function getCompsName(comps, types) {
    return comps.filter(item => types.includes(item.type)).map(item => item.name);
  }
  const prevComps = getCompsName(prevComponents, SERDES_TYPES)
  const comps = getCompsName(components, SERDES_TYPES)

  // IC or BGA component changed
  const prevICComps = getCompsName(prevComponents, [IC])
  const ICComps = getCompsName(components, [IC])

  const prevBGAComps = getCompsName(prevComponents, [BGA])
  const BGAComs = getCompsName(components, [BGA])
  if (!areArraysEqual(prevComps, comps) || !areArraysEqual(prevICComps, ICComps) || !areArraysEqual(prevBGAComps, BGAComs)) {
    return true;
  }
  return false;
}

export {
  getComponentRender,
  getSignalCheckBox,
  CHECK_BOX_ALL,
  getSignalTitleCheckBox,
  getSignalColumnRender,
  checkICComp
}