import {
  UPDATE_ROCKY_PDN_CONTENT,
  UPDATE_ROCKY_PDN_INFO,
  UPDATE_ROCKY_PDN_IFDOEXTRACTION,
  UPDATE_ROCKY_PDN_ERROR_LIST,
  UPDATE_LOADING_PCB,
  UPDATE_COMP_PREFIX_PROMPT
} from './actionTypes';

const defaultState = {
  rockyPDNInfo: null,
  pdnErrorCheck: [],
  pcbLoading: false,
  showPrefix: false
}

export const RockyPDNReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ROCKY_PDN_CONTENT:
      return {
        ...state,
        rockyPDNInfo: {
          ...action.info
        }
      }
    case UPDATE_ROCKY_PDN_INFO:
      return {
        ...state,
        rockyPDNInfo: {
          ...state.rockyPDNInfo,
          pdnContent: {
            ...state.rockyPDNInfo.pdnContent,
            ...action.data
          }
        }
      }
    case UPDATE_ROCKY_PDN_IFDOEXTRACTION:
      return {
        ...state,
        rockyPDNInfo: {
          ...state.rockyPDNInfo,
          ifDoExtraction: action.data
        }
      }
    case UPDATE_ROCKY_PDN_ERROR_LIST:
      return {
        ...state,
        pdnErrorCheck: action.errorCheck
      }
    case UPDATE_LOADING_PCB:
      return {
        ...state,
        pcbLoading: action.pcbLoading
      }
    case UPDATE_COMP_PREFIX_PROMPT:
      return {
        ...state,
        showPrefix: action.showPrefix
      }
    default: return state
  }
};