import React, { Fragment } from 'react';
export const INTERFACE_TYPE = "type",
  PCIE_2 = "PCIe 2.0",
  PCIE_3 = "PCIe 3.0",
  PCIE_4 = "PCIe 4.0",
  PCIE_5 = "PCIe 5.0",
  SEA_SEM_DATA_RATE = "datarate",
  BER = "ber",
  PORT_TX_VPKPK = "port_tx_vpkpk",
  NUI = "nui",
  JITTER_UNIT = "jitter_unit",
  JIT_PWRJ = "jit_pwrj",
  JIT_PWDDJ = "jit_pwddj",
  JIT_HFRJ_NUI = "jit_hfrj_nui",
  JIT_LFRJ = "jit_lfrj",
  JIT_LFDDJ = "jit_lfddj",
  ADAPT_EQ = "adapt_eq",
  ADAPT_FOM = "adapt_FOM",
  ADAPT_CSPACE = "adapt_Cspace",
  ADAPT_DC = "adapt_DC",
  ADAPT_POLE = "adapt_pole",
  ADAPT_INC_XTALK = "adapt_inc_xtalk",
  STATSIM_OPTIONS = "statsim_options",
  SECOND = "Second",
  UI = "ui",
  AREA = "area",
  WIDTH = "width",
  HEIGHT = "height",
  ADAPT_PS = "adapt_PS",
  ADAPT_DE = "adapt_DE",
  USE_ADAPT_CSPACE = "use_adapt_Cspace",
  ADAPT_DC2 = "adapt_DC2",
  ADAPT_POLE2 = "adapt_pole2",
  TX_PRE_SHOOT = "tx_preshoot",
  TX_DEEMPHASIS = "tx_deemphasis",
  LEQ_DC = "leq_DC",
  LEQ_POLE = "leq_pole",
  LEQ_DC2 = "leq_DC2",
  LEQ_POLE2 = "leq_pole2",
  SPECIFY_SEARCH = "specify_search",
  LEQ_BW = "leq_bw",
  LEQ_AC = "leq_AC",
  LEQ_BW2 = "leq_bw2",
  LEQ_AC2 = "leq_AC2",
  VPT = "vpt",
  PRE_CURSORS = "pre_cursors",
  CURSOR_BLOCK = "cursor_block",
  NUI_IFFT = "nui_ifft",
  JIT_HFRJ_GROWTH = "jit_hfrj_growth",
  ADAPT_NUI = "adapt_nui",
  ADAPT_PSDE_PAIRS = "adapt_PSDE_pairs",
  CONST = "const",
  SQRT = 'sqrt',
  LINEAR = 'linear',
  USE_ADAPT_TX_PRESET = "use_adapt_TX_preset",
  ADAPT_TX_PRESET = "adapt_TX_preset",
  USE_LEQ_RESPONSE = "use_leq_response",
  LEQ_RESPONSE = "leq_response",
  LEQ_RESPONSE_ID = "leq_response_id",
  ADAPT_LEQ_INDEX1 = "adapt_leq_index1",
  ADAPT_LEQ_INDEX2 = "adapt_leq_index2",
  LEQ_RESPONSE_INDEX1 = "leq_response_index1",
  LEQ_RESPONSE_INDEX2 = "leq_response_index2",
  ADAPT_DFE_MAG = "adapt_DFE_mag",
  ADAPT_DFE_H1H0 = "adapt_DFE_h1h0",
  RXCOEFF = "rxcoeff";

export const INTERFACE_TYPE_LIST = [{
  key: PCIE_2,
  title: <Fragment>
    <span className="andes-seasim-type-title">PCIe 2.0 </span>
    (5 GT/s)
  </Fragment>
},
{
  key: PCIE_3,
  title: <Fragment>
    <span className="andes-seasim-type-title">PCIe 3.0 </span>
    (8 GT/s)
  </Fragment>
},
{
  key: PCIE_4,
  title: <Fragment>
    <span className="andes-seasim-type-title">PCIe 4.0 </span>
    (16 GT/s)
  </Fragment>
},
{
  key: PCIE_5,
  title: <Fragment>
    <span className="andes-seasim-type-title">PCIe 5.0 </span>
    (32 GT/s)
  </Fragment>
}],
  ADAPT_FOM_OPTIONS = [
    {
      key: AREA,
      title: "Area"
    },
    {
      key: WIDTH,
      title: "Width"
    },
    {
      key: HEIGHT,
      title: "Height"
    }
  ];

export const SEA_SIM_BASIC_OPTIONS = [PORT_TX_VPKPK, BER, VPT, PRE_CURSORS, CURSOR_BLOCK, NUI_IFFT, NUI],
  JITTER_OPTIONS = [
    JIT_PWRJ,
    JIT_PWDDJ,
    JIT_HFRJ_NUI,
    JIT_LFRJ,
    JIT_LFDDJ,
    NUI
  ],
  EQUALIZATION_OPTIONS = [
    ADAPT_PS,
    ADAPT_DE,
    ADAPT_CSPACE,
    ADAPT_DC,
    ADAPT_POLE,
    ADAPT_DC2,
    ADAPT_POLE2,
    TX_PRE_SHOOT,
    TX_DEEMPHASIS,
    LEQ_DC,
    LEQ_POLE,
    LEQ_DC2,
    LEQ_POLE2,
    LEQ_BW,
    LEQ_AC,
    LEQ_BW2,
    LEQ_AC2,
    ADAPT_LEQ_INDEX1,
    ADAPT_LEQ_INDEX2,
    LEQ_RESPONSE_INDEX1,
    LEQ_RESPONSE_INDEX2,
    ADAPT_DFE_MAG,
    RXCOEFF,
    ADAPT_DFE_H1H0
  ],
  ADAPT_FIRST_POLES = [
    LEQ_BW,
    LEQ_AC,
    ADAPT_DC,
    ADAPT_POLE,
  ],
  ADAPT_SECOND_POLES = [
    LEQ_BW2,
    LEQ_AC2,
    ADAPT_DC2,
    ADAPT_POLE2
  ],
  LEQ_FIRST_POLES = [
    LEQ_BW,
    LEQ_AC,
    LEQ_DC,
    LEQ_POLE
  ],
  LEQ_SECOND_POLES = [
    LEQ_BW2,
    LEQ_AC2,
    LEQ_DC2,
    LEQ_POLE2
  ];

export const seasimInputList = [{
  type: BER,
  label: "Bit Error Rate (BER)"
}, {
  type: PORT_TX_VPKPK,
  label: "Peak-to-peak Voltage (port_tx_vpkpk)"
}, {
  type: VPT,
  label: "Number of voltage bins (vpt)",
  useStatsimOptions: true
}, {
  type: PRE_CURSORS,
  label: "Number of pre-cursors for statistical eye (pre_cursors)",
  useStatsimOptions: true
}, {
  type: CURSOR_BLOCK,
  label: "Number of UI in cursor block (cursor_block)",
  useStatsimOptions: true
}, {
  type: NUI,
  label: "Number of post-cursors for statistical eye (nui)",
  useStatsimOptions: true
}, {
  type: NUI_IFFT,
  label: "Minimum number of UI used for ifft (nui_ifft)",
  useStatsimOptions: true
}]