import React, { Fragment, } from 'react';
import './index.css';

function ErrorCheck(props) {
  const { errorCheck } = props;
  return (
    <Fragment>
      {errorCheck &&
        <Fragment>
          <pre className="andes-warning-dialog">
            {errorCheck && errorCheck.error && errorCheck.error.length > 0 && errorCheck.error.map((item, index) => {
              return <Fragment key={index}>{item}</Fragment>
            })}
          </pre>
        </Fragment>
      }
    </Fragment>
  )
}

export default ErrorCheck;