import { PROJECT_ID } from '../../verificationCtrl/verificationCtrl';
import http from '../../api/http';

/**
 * Get file PinToPin project 
 * 2019.08.12
 * 
 * @param {String} path
 */
export function getFileInPinToPinProject(path) {
  return http.get(`/project/${PROJECT_ID}/PinToPin/PinToPin/file?path=${path}`);
}