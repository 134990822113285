import React, { PureComponent } from "react";
import { Input, Checkbox } from 'antd';
import {
  dielectricList,
  FREQ_DEP,
  getFreqDepOption,
  getDielectricMaterial,
  stackupItemErrorCheck,
  DIELECTRIC,
  FREQUENCY,
  FREQ_CORNER,
  djordjevicErrorCheck,
  EPS_DC
} from '../../services/Stackup';
import UnitAddonAfter from "../UnitAddonAfter";
import { numFormatConversion } from "../../services/helper/numberHelper";

class DielectricSetup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      config: typeof (props.material) === 'object' ? getDielectricMaterial(props.material) : getDielectricMaterial({})
    }
  }

  getMaterialConfig = () => {
    let _config = { ...this.state.config };
    if (_config[FREQ_DEP] !== "no") {
      _config = {
        ..._config,
        [FREQUENCY]: numFormatConversion(_config[FREQUENCY], this.getScale(_config[`${FREQUENCY}Unit`])),
        [FREQ_CORNER]: numFormatConversion(_config[FREQ_CORNER], this.getScale(_config[`${FREQ_CORNER}Unit`]))
      }
    }
    delete _config[`${FREQUENCY}Unit`];
    delete _config[`${FREQ_CORNER}Unit`];
    return _config;
  }

  getScale = (unit) => {
    switch (unit) {
      case "MHz":
        return 6;
      case "GHz":
        return 9;
      default:
        return 0;
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.updateConfig();
  }

  updateConfig = () => {
    const { material } = this.props;
    this.setState({
      config: typeof (material) === 'object' ? getDielectricMaterial(material) : getDielectricMaterial({})
    })
  }


  changeValue = (e, type) => {
    const value = e.target.value;
    const { config } = this.state;
    const { errorMsg } = this.props;
    this.setState({
      config: {
        ...config,
        [type]: value
      }
    });
    const _errorMsg = errorMsg && errorMsg.type === type ? null : errorMsg;
    this.props.updateErrorMsg(_errorMsg);
  }

  saveValue = (e, type) => {
    const value = e.target.value;
    const unit = this.state.config[`${type}Unit`];
    // Error check
    const error = stackupItemErrorCheck(type, value, { type: DIELECTRIC, unit });
    if (error) {
      e.target.focus && e.target.focus();
      this.props.updateErrorMsg({ type, error: error.error });
      return error;
    } else {
      this.checkFreqConfig();
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  checkFreqConfig = () => {
    let config = { ...this.state.config };
    const calcInfo = djordjevicErrorCheck(config);
    if (calcInfo) {
      if (calcInfo[EPS_DC]) {
        config[EPS_DC] = calcInfo[EPS_DC].toString();
        this.setState({
          config
        })
      }
      if (calcInfo.error) {
        this.props.updateErrorMsg({ type: "djordjevic", error: calcInfo.error });
      } else {
        this.props.updateErrorMsg();
      }
    }
  }

  checkChange = (e, type) => {
    const checked = e.target.checked;
    let config = { ...this.state.config };
    const { errorMsg } = this.props;
    if (type === FREQ_DEP) {
      config = checked ? getDielectricMaterial({ ...config, freq_dep: "djordjevic-sarkar" }) : getDielectricMaterial({ ...config, freq_dep: "no" });
      if (!checked && errorMsg && errorMsg.type === "djordjevic") {
        this.props.updateErrorMsg();
      }
    } else {
      config[type] = checked;
    }
    this.setState({
      config
    }, () => {
      this.checkFreqConfig();
    })
  }

  getFreqDepDisabled = (type) => {
    const { config } = this.state;
    const freq_dep = config.freq_dep;
    if (freq_dep !== "no") {
      return false;
    } else {
      return getFreqDepOption(type);
    }
  }

  render() {
    const { config = {} } = this.state;
    return dielectricList.map(item => {
      const disabled = this.getFreqDepDisabled(item.key);
      if (disabled) {
        return null;
      }
      const className = getFreqDepOption(item.key) ? "material-freq-dep-option" : "";
      if (item.subKey) {
        return <div className={`material-definition-item material-dielectric-item ${className}`} key={item.key}>
          <label className='material-edit-value-label'>{item.title}</label>
          <Checkbox
            className='material-definition-item-checkbox'
            checked={config[item.key]}
            onChange={(e) => this.checkChange(e, item.key)}
          />
          <Input
            className='aurora-input material-input material-definition-item-half'
            placeholder={config[item.subKey] ? config[item.subKey] : item.title}
            value={!config[item.key] ? "" : config[item.subKey]}
            disabled={!config[item.key]}
            onChange={(e) => this.changeValue(e, item.subKey)}
            onBlur={(e) => this.saveValue(e, item.subKey)}
            onKeyDown={(e) => this.keyDown(e)}
            addonAfter={this.addonAfter(item)}
          />
        </div>
      } else if (item.type === "checkBox") {
        return <div className='material-definition-item material-dielectric-item' key={item.key}>
          <Checkbox
            key={item.key}
            className='material-definition-item-checkbox'
            checked={config[item.key] === "no" ? false : true}
            onChange={(e) => this.checkChange(e, item.key)}
          />
          <label className='material-edit-value-label material-edit-value-label-long'>{item.title}</label>
        </div>
      } else {
        return this.getInputComponent({
          itemData: item,
          config,
          className
        })
      }
    })
  }

  getInputComponent = ({ itemData, config, className }) => {
    return <div className={`material-definition-item material-dielectric-item ${className}`} key={itemData.key}>
      <label className='material-edit-value-label'>{itemData.title}</label>
      <Input
        className='aurora-input material-input'
        placeholder={itemData.title}
        value={config[itemData.key]}
        onChange={(e) => this.changeValue(e, itemData.key)}
        onBlur={(e) => this.saveValue(e, itemData.key)}
        onKeyDown={(e) => this.keyDown(e)}
        addonAfter={this.addonAfter(itemData)}
      />
    </div>
  }

  addonAfter = (data) => {
    if (!data.unit) {
      return null;
    }

    if (!data.unitList) {
      return data.unit;
    }

    const config = this.state.config;
    const unit = config[`${data.key}Unit`];
    return UnitAddonAfter({ unit, changeUnit: (key) => this.changeUnit(key, data.key), list: data.unitList })
  }

  changeUnit = (unit, type) => {
    this.setState({
      config: {
        ...this.state.config,
        [`${type}Unit`]: unit
      }
    })
  }
}

export default DielectricSetup;