const PROJECT_TYPE = 'ANDES';
export const OPEN_UPLOAD_PROGRESS_MODAL = `${PROJECT_TYPE}/open_upload_progress_modal`;
export const UPLOAD_PROGRESS_CHANGE = `${PROJECT_TYPE}/upload_progress_changed`;
export const SHOW_ERROR_MSG = `${PROJECT_TYPE}/show_error_msg`;
export const CLOSE_UPLOAD_PROGRESS_MODAL = `${PROJECT_TYPE}/close_upload_progress_modal`;

export const TRANSLATION_SUCCESS = `${PROJECT_TYPE}/translation_success`;
export const TRANSLATION_RUNNING = `${PROJECT_TYPE}/translation_running`;
export const TRANSLATION_FAILED = `${PROJECT_TYPE}/translation_failed`;

export const GET_WORK_FLOWS = `${PROJECT_TYPE}/get_work_flows`;
export const TRANSLATION_MONITOR = `${PROJECT_TYPE}/translation_monitor`;
export const UPLOAD_PROJECT_ID = `${PROJECT_TYPE}/upload_project_id`;
export const ADD_MSG = `${PROJECT_TYPE}/add_msg`;
export const CURRENT_UPLOAD_DESIGN_ID = `${PROJECT_TYPE}/current_upload_design_Id`;
export const SAVE_CURRENT_PCB_KEY = `${PROJECT_TYPE}/save_current_pcb_key`;

export const TRANSLATION_DEBUG = `${PROJECT_TYPE}/translation_debug`;
export const CANCEL_UPLOAD_WORKFLOW = `${PROJECT_TYPE}/cancel_upload_workflow`;

export const CHANGE_DISABLE_STATUS = `${PROJECT_TYPE}/change_disable_status`;
export const GET_TRANSLATION_MONITOR = `${PROJECT_TYPE}/get_translation_monitor`;
