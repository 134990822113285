import React, { PureComponent, lazy } from 'react';
import ContentLayoutLR from '@/components/ContentLayout/layoutLR';
import { topBarResize } from '@/services/helper/topBarResize';
import {
  PCB, DCR, IR_EXPLORER, IR_EXPLORER_RESULT, IMPEDANCE,
  IMPEDANCE_RESULT, PACKAGE, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE,
  PCB_PRE_LAYOUT
} from '@/constants/treeConstants';
import TopBar from './TopBar';
import IRContent from '../IRExplorer';
import IRResultContent from '../results/IR';
import ImpedanceContent from '../Impedance';
import ImpedanceResult from '../results/Impedance';
import DesignTreeContent from '../DesignTree';
import { connect } from 'react-redux';
import { PCB_LEFT_RIGHT, LEFT_RIGHT_lAYOUT } from '../../../constants/layoutConstants';
import SignOffTemplateContent from '../SignOffTemplate';

const DCRContent = lazy(() => import("../DCR"));
const PCBLayout = lazy(() => import("./pcbLayout"));

class LayoutLR extends PureComponent {

  topBarResize = () => {
    topBarResize('cascade-top-bar', this.props.getTopBarWidth(this.props.viewList));
  }

  topBar = (width) => {
    return <TopBar width={width} openMenu={this.props.openMenu} />
  }

  getRight = (width) => {
    const { siderWidth, viewList } = this.props;
    const view = viewList.find(item => [PCB, PACKAGE, PCB_PRE_LAYOUT].includes(item));
    switch (view) {
      case PCB:
      case PACKAGE:
      case PCB_PRE_LAYOUT:
        return <PCBLayout siderWidth={siderWidth + width} />
      default: return null;
    }
  }

  getLeft = (width, bottomLinePosition) => {
    const { tabVisible, viewList, siderWidth } = this.props;
    const view = viewList.filter(item => ![PCB, PACKAGE, PCB_PRE_LAYOUT].includes(item))
    switch (view[0]) {
      case DCR:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <DCRContent />
        </div>;
      case IR_EXPLORER:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <IRContent contentWidth={width} />
        </div>;
      case IMPEDANCE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <ImpedanceContent contentWidth={width} />
        </div>;
      case IR_EXPLORER_RESULT:
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-result'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <IRResultContent leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      case IMPEDANCE_RESULT:
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-result'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <ImpedanceResult leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      case POWER_TREE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <DesignTreeContent contentWidth={width} />
        </div>
      case SIGN_OFF_TEMPLATE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <SignOffTemplateContent contentWidth={width} />
        </div>
      case DESIGN_TREE:
      case SINGLE_TREE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='cascade-content-main'
          className='my-aurora-content cascade-lr-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <DesignTreeContent contentWidth={width} />
        </div>
      default: return null;
    }
  }

  _getSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PACKAGE, PCB_PRE_LAYOUT].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) {
      return 1;
    } else if (pcbs.length > 1 && pcbLayout === PCB_LEFT_RIGHT && layout === LEFT_RIGHT_lAYOUT) {
      return 3;
    } else {
      return 2;
    }
  }

  render() {
    const splitSize = this._getSplitSize();
    return (
      <ContentLayoutLR
        {...this.props}
        leftClassName={"cascade-content-left"}
        topBarResize={this.topBarResize}
        getLeft={this.getLeft}
        getRight={this.getRight}
        topBar={this.topBar}
        splitSize={splitSize}
        lineClassName='aurora-layout-split-screen'
      />
    )
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { pcbLayout, layout, selectedKeys } } } = state;
  return {
    pcbLayout,
    layout,
    selectedKeys
  }
}

export default connect(mapState, null)(LayoutLR);