import React, { Component, Fragment } from 'react'
import TreeForm from '@/components/TreeNode';

class CascadeTreeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render = () => {
    const { treeItems, expandedKeys, onSelect, selectedKeys, renderTitle, onExpand, updateProjectMenu } = this.props
    return (
      <Fragment>
        <TreeForm
          renderTitle={renderTitle}
          treeData={treeItems}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          autoExpandParent={false}
          defaultExpandParent={false}
          updateProjectMenu={updateProjectMenu}
        />
      </Fragment>
    )
  }
}

export default CascadeTreeForm