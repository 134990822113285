import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { Button } from 'antd';
import './index.css';

class DownloadSpicePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: JSON.parse(JSON.stringify(this.props.msg))
    };
  }
  render() {
    const { log } = this.props;
    const { msg } = this.state;
    return (
      <Panel
        title="Log"
        className='match-error-log-panel'
        visible
        onCancel={() => this.props.closePanel()}
        width={800}
        position='panel-top-left'
        draggable
        height={350}
        minWidth={100}
        zIndex={10001}
        overflow='hidden'
      >
        <div className='match-error-log-content'>
          {msg && <div className={"aurora-model-name-error-msg"}>{msg}</div>}
          <div className='match-error-log-box'>
            {log && log.length ? log.map((item, index) => {
              return <div key={index}>--- {item}</div>
            }) : null}
          </div>
          <div className='match-error-log-button-content'>
            <Button className='match-error-log-button' onClick={this.props.downloadLog}>Download</Button>
          </div>
        </div>
      </Panel>
    )
  }
}

export default DownloadSpicePanel;