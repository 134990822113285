import multiSetupDataBase from "./multiInterfaceDataBase";
import multiSetupBufferDataBase, { multiSetupPassiveDataBase } from "./multiSetupBufferDataBase";
import { CAP, IND, RES, JUMPER, UNUSED, IGNORE } from '@/constants/componentType';
import compTableHelper from "../helper/compTableHelper";
import { getCompType } from "../../Designs/compTypeHelper";
import { SIERRA } from "../../../constants/pageType";

function MultiSetupTableItem(itemData) {
  this.group = itemData.group || "";
  this.interfaceName = itemData.interfaceName || "";
  this.signal = itemData.signal || "";
  this.loads = itemData.loads || "";
  this.driver = itemData.driver || [];
  this.receiver = itemData.receiver || [];
  this.passive = itemData.passive || [];
  this.pcb = itemData.pcb || [];
  this.nets = itemData.nets || [];
  this.components = itemData.components || [];
  this.result = itemData.result || "";
  this.interfaceId = itemData.interfaceId;
  this.verificationId = itemData.verificationId;
  this.pcbId = itemData.pcbId;
  this.interfaceType = itemData.interfaceType;
  this.mergeGroupLength = itemData.mergeGroupLength;
  this.mergeInterfaceLength = itemData.mergeInterfaceLength;
  this.mergeSignalLength = itemData.mergeSignalLength;
  this.pcbIds = itemData.pcbIds || [];
}

function getMultiSetupData({ data, pageSize, page }) {
  if (data) {
    const pageSize = data.length;
    multiSetupDataBase.initData(data, pageSize || 20);
  }
  const pageData = multiSetupDataBase.getPage(page || 1);
  const pages = multiSetupDataBase.getPages();
  const allDataLength = multiSetupDataBase.getAllDataLength();
  const verificationIds = multiSetupDataBase.getVerificationIds();
  let dataList = [], existGroups = [], existInterfaces = [], existSignals = [];
  for (let item of pageData || []) {

    let mergeSignalLength = 1;
    let mergeGroupLength = getTableColumnsLength(item.group, existGroups, pageData, "group"),
      { length: mergeInterfaceLength, pcbIds } = getTableColumnsLength(item.verificationId, existInterfaces, pageData, "verificationId");
    let signalList = []
    if (!existSignals.includes(`${item.verificationId}::${item.signal}`)) {
      signalList = pageData.filter(it => it.verificationId === item.verificationId && it.signal === item.signal);
      mergeSignalLength = signalList.length;
    } else {
      mergeSignalLength = 0;
    }
    existGroups.push(item.group);
    existInterfaces.push(item.verificationId);
    existSignals.push(`${item.verificationId}::${item.signal}`);
    dataList.push(new MultiSetupTableItem({
      ...item,
      mergeGroupLength,
      mergeInterfaceLength,
      mergeSignalLength,
      pcbIds
    }))
  }
  multiSetupDataBase.setPage(page || 1, dataList);
  return {
    dataList,
    page: page || 1,
    pageSize: pageData.length || pageSize || 20,
    pages,
    allDataLength,
    verificationIds
  }
}

function getTableColumnsLength(value, existList, pageData, key) {
  if (!value) {
    return 1;
  }
  let length = 1, list = [], pcbIds = [];

  if (existList.includes(value)) {
    length = 0;
  } else {
    list = pageData.filter(it => it[key] === value);
    length = list.length || 1;
    if (key === "verificationId") {
      pcbIds = list.map(item => item.pcbId);
    }
  }
  return key === "verificationId" ? { length, pcbIds } : length;
}

function MultiBufferTableItem(itemData) {
  this.pcb = itemData.pcb || "";
  this.pcbId = itemData.pcbId || "";
  this.component = itemData.component || "";
  this.part = itemData.part || "";
  this.signal = itemData.signal || "";
  this.interfaceIds = itemData.interfaceIds || [];
  this.interfaceNames = itemData.interfaceNames || "";
  this.componentModel = itemData.componentModel || {};
  this.pin = itemData.pin || "";
  this.usage = itemData.usage || "";
  this.model = itemData.model || {};
  this.pinModels = itemData.pinModels || [];
  this.powerOff = itemData.powerOff || "0";
  this.corner = itemData.corner;
  this.deviceVcc = itemData.deviceVcc;
  this.clock = itemData.clock;
  this.interfaceType = itemData.interfaceType;
  this.mergePcbLength = itemData.mergePcbLength;
  this.mergeComponentLength = itemData.mergeComponentLength;
}

function getMultiBufferData({ data, page, pageSize }) {
  if (data) {
    multiSetupBufferDataBase.initData(data, pageSize || 20);
  }
  const pageData = multiSetupBufferDataBase.getPage(page || 1);
  const pages = multiSetupBufferDataBase.getPages();
  const allDataLength = multiSetupBufferDataBase.getAllDataLength()
  let dataList = [], existPCBs = [], existComps = [];
  for (let item of pageData || []) {

    let mergePcbLength = getTableColumnsLength(item.pcb, existPCBs, pageData, "pcb");

    let compList = [], mergeComponentLength = 1;
    if (!existComps.includes(`${item.pcb}::${item.component}`)) {
      compList = pageData.filter(it => it.pcb === item.pcb && it.component === item.component);
      mergeComponentLength = compList.length;
    } else {
      mergeComponentLength = 0;
    }
    existPCBs.push(item.pcb);
    existComps.push(`${item.pcb}::${item.component}`);
    dataList.push(new MultiBufferTableItem({
      ...item,
      mergePcbLength,
      mergeComponentLength
    }))
  }
  multiSetupBufferDataBase.setPage(page || 1, dataList);
  return {
    dataList,
    page: page || 1,
    pageSize: pageSize || 20,
    pages,
    allDataLength
  }
}

function MultiPassiveTableItem(itemData, compPrefixLibInfo = {}) {
  this.pcb = itemData.pcb || "";
  this.pcbId = itemData.pcbId || "";
  this.components = itemData.components || "";
  this.part = itemData.part || "";
  this.interfaceIds = itemData.interfaceIds || [];
  this.interfaceNames = itemData.interfaceNames || "";
  this.pins = itemData.pins || "";
  this.type = itemData.type || "";
  this.model = itemData.model || {};
  this.value = itemData.value;
  this.mergePcbLength = itemData.mergePcbLength;
}

function getMultiPassiveData({ data, page, pageSize, compPrefixLibInfo }) {
  if (data) {
    multiSetupPassiveDataBase.initData(data, pageSize || 20);
  }
  const pageData = multiSetupPassiveDataBase.getPage(page || 1);
  const pages = multiSetupPassiveDataBase.getPages();
  const allDataLength = multiSetupPassiveDataBase.getAllDataLength()
  let dataList = [], existPCBs = [];
  const RLCJumperType = ['RLC', CAP, IND, RES, JUMPER];
  pageData.sort(function (a, b) {
    return a.pcb.localeCompare(b.pcb)
  })
  for (let item of pageData || []) {
    let compType = ""
    if (![UNUSED, IGNORE].includes(item.type)) {
      compType = item.type;
    } else {
      const passiveTable = compTableHelper.get(item.pcbId);
      const findPassive = passiveTable.find(it => it.part === item.part);
      if (findPassive && findPassive.usage) {
        compType = findPassive.usage;
      } else {
        compType = compPrefixLibInfo ? getCompType({
          compName: item.components[0].name,
          pinLength: 0,
          COMP_PREFIX_LIB: compPrefixLibInfo[item.pcbId],
          partName: item.part,
          product: SIERRA,
        }) : item.type;
      }
    }

    compType = compType === IGNORE ? UNUSED : compType;
    if (!RLCJumperType.includes(compType)) continue
    let mergePcbLength = getTableColumnsLength(item.pcb, existPCBs, pageData, "pcb");
    existPCBs.push(item.pcb);
    let _components = item.components.reduce((acc, current) => {
      let existingItem = acc.find(item => item.name === current.name);
      if (!existingItem) {
        acc.push(current);
      }
      return acc;
    }, []);
    dataList.push(new MultiPassiveTableItem({
      ...item,
      components: [..._components],
      type: compType,
      mergePcbLength
    }, compPrefixLibInfo))
  }
  multiSetupPassiveDataBase.setPage(page || 1, dataList);
  return {
    dataList,
    page: page || 1,
    pageSize: pageSize || 20,
    pages,
    allDataLength
  }
}

export {
  getMultiSetupData,
  getMultiBufferData,
  getMultiPassiveData,
  MultiPassiveTableItem
}