import React, { Component } from 'react'
import { connect } from 'react-redux';
import { PCB, IR_EXPLORER_RESULT, DCR, IR_EXPLORER, IMPEDANCE, IMPEDANCE_RESULT, PACKAGE, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE } from '../../../constants/treeConstants';
import ContentLayout from '@/components/ContentLayout';
import { closeTabFooter, changeTabMenu } from '../../MonitorStore/action';
import { CASCADE } from '@/constants/pageType';
import LayoutContent from './layoutContent';
import LayoutLR from './LayoutLR';
import LayoutTB from './LayoutTB';
import CascadeMonitorChild from './monitor';
import { getCCCVerifications, getCCCSpecifyServiceVerification } from '@/services/Cascade/helper/specifyHelper';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { getTopBarWidth } from '../../../services/Cascade/helper/topBarHelper';
import { DCR_TITLE, FULL_TITLE, IMPEDANCE_TITLE, IR_TITLE, TREE_TITLE, SINGLE_TITLE, TEMPLATE_TITLE } from '../../../services/Cascade/constants';

class CascadeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render = () => {
    const { layout, viewList } = this.props
    return <ContentLayout
      {...this.props}
      pageType={CASCADE}
      layout={layout}
      viewList={viewList}
      layoutSingle={this.layoutSingle}
      layoutLR={this.layoutLR}
      layoutTB={this.layoutTB}
    />
  }

  getTopMenuVisible = () => {
    const { viewList, projectId } = this.props;
    if (!projectId) {
      return false;
    }

    if (viewList && viewList.length && viewList.every(v => [PCB, PACKAGE, IR_EXPLORER_RESULT, IMPEDANCE_RESULT].includes(v))) {
      return false;
    }

    return true
  }

  _changeMonitorTabMenu = ({ selectKeys }) => {
    const { menuType, viewList, DCRVerificationId, ImpedanceVerificationId, IRVerificationId, projectId, DesignTreeVerificationId, SignOffVerificationId } = this.props;
    let currentVerificationId = undefined, verificationName = null;
    if (selectKeys.some(key => ['monitor', 'profile'].includes(key))) {
      const view = viewList.find(item => [DCR, IMPEDANCE, IR_EXPLORER, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].includes(item));
      let currentItem = null;
      switch (view) {
        case DCR:
          currentVerificationId = DCRVerificationId;
          currentItem = CascadeChannels.getList(DCR, projectId).find(item => item.id === DCRVerificationId);
          verificationName = currentItem ? currentItem.name : 'PathR';
          break;
        case IMPEDANCE:
          currentVerificationId = ImpedanceVerificationId;
          currentItem = CascadeChannels.getList(IMPEDANCE, projectId).find(item => item.id === ImpedanceVerificationId);
          verificationName = currentItem ? currentItem.name : IMPEDANCE;
          break;
        case IR_EXPLORER:
          currentVerificationId = IRVerificationId;
          currentItem = CascadeChannels.getList(IR_EXPLORER, projectId).find(item => item.id === IRVerificationId);
          verificationName = currentItem ? currentItem.name : IR_EXPLORER
          break;
        case POWER_TREE:
          currentVerificationId = DesignTreeVerificationId;
          currentItem = CascadeChannels.getList(POWER_TREE, projectId).find(item => item.id === DesignTreeVerificationId);
          verificationName = currentItem ? currentItem.name : POWER_TREE
          break;
        case DESIGN_TREE:
          currentVerificationId = DesignTreeVerificationId;
          currentItem = CascadeChannels.getList(DESIGN_TREE, projectId).find(item => item.id === DesignTreeVerificationId);
          verificationName = currentItem ? currentItem.name : DESIGN_TREE
          break;
        case SINGLE_TREE:
          currentVerificationId = DesignTreeVerificationId;
          currentItem = CascadeChannels.getList(SINGLE_TREE, projectId).find(item => item.id === DesignTreeVerificationId);
          verificationName = currentItem ? currentItem.name : SINGLE_TREE
          break;
        case SIGN_OFF_TEMPLATE:
          currentVerificationId = SignOffVerificationId;
          currentItem = CascadeChannels.getList(SIGN_OFF_TEMPLATE, projectId).find(item => item.id === SignOffVerificationId);
          verificationName = currentItem ? currentItem.name : 'sign-off template'
          break;
        default: break;
      }
    }
    let info = {
      tabSelectKeys: selectKeys,
      currentVerificationId,
      verificationName,
      menuType,
    }
    this.props._changeTabMenu(info);
  }

  getVerificationName = (name) => {
    switch (name) {
      case IMPEDANCE:
        return IMPEDANCE_TITLE;
      case IR_EXPLORER:
        return IR_TITLE;
      case DCR:
        return DCR_TITLE;
      case DESIGN_TREE:
        return FULL_TITLE;
      case POWER_TREE:
        return TREE_TITLE;
      case SINGLE_TREE:
        return SINGLE_TITLE;
      case SIGN_OFF_TEMPLATE:
        return TEMPLATE_TITLE
      default: return null;
    }
  }

  getVerificationList = (name) => {
    const { projectId, DCRVerificationId, ImpedanceVerificationId, IRVerificationId, DesignTreeVerificationId, SignOffVerificationId } = this.props;
    switch (name) {
      case IMPEDANCE_TITLE:
        return { list: CascadeChannels.getList(IMPEDANCE, projectId), id: ImpedanceVerificationId };
      case IR_TITLE:
        return { list: CascadeChannels.getList(IR_EXPLORER, projectId), id: IRVerificationId };
      case DCR_TITLE:
        return { list: CascadeChannels.getList(DCR, projectId), id: DCRVerificationId };
      case FULL_TITLE:
        return { list: CascadeChannels.getList(DESIGN_TREE, projectId), id: DesignTreeVerificationId }
      case TREE_TITLE:
        return { list: CascadeChannels.getList(POWER_TREE, projectId), id: DesignTreeVerificationId }
      case SINGLE_TITLE:
        return { list: CascadeChannels.getList(SINGLE_TREE, projectId), id: DesignTreeVerificationId }
      case TEMPLATE_TITLE:
        return { list: CascadeChannels.getList(SIGN_OFF_TEMPLATE, projectId), id: SignOffVerificationId }
      default: return { list: [], id: '' };
    }
  }


  _monitorChild = (width) => {
    const { menuType, tabVisible, tabSelectKeys, projectId, viewList, projectName } = this.props;
    const verification = viewList.map(item => this.getVerificationName(item)).filter(item => !!item);
    const _verification = verification.length ? verification[0] : null;
    const titleSelectedKeys = [{ type: 'Project', name: projectName }, { type: 'Verification', name: _verification }];
    let taskList = this.getVerificationList(_verification);
    const setupType = viewList.find(item => [SIGN_OFF_TEMPLATE, IMPEDANCE].includes(item)) || null;
    const title = setupType === SIGN_OFF_TEMPLATE ? "Template" : "Explorer";

    return CascadeMonitorChild({
      menuType,
      tabVisible,
      tabSelectKeys,
      projectId,
      getCCCVerifications: () => getCCCVerifications(taskList.list, title),
      getCurrentVerification: () => getCCCSpecifyServiceVerification(taskList.id, taskList.list),
      width,
      pageType: CASCADE,
      titleSelectedKeys,
      setupType
    })
  }

  layoutSingle = () => {
    return <LayoutContent  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={getTopBarWidth}
    />
  }

  layoutLR = () => {
    return <LayoutLR  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={getTopBarWidth}
    />
  }

  layoutTB = () => {
    return <LayoutTB  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={getTopBarWidth}
    />
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { viewList, layout, openProjectId },
    DCR: { verificationId: DCRVerificationId },
    IR: { verificationId: IRVerificationId },
    Impedance: { verificationId: ImpedanceVerificationId },
    DesignTree: { verificationId: DesignTreeVerificationId },
    SignOffTemplate: { verificationId: SignOffVerificationId }
  } } = state;
  const { monitorScreenInfo: { tabSelectKeys, menuType, currentVerificationId, projectName }, tabVisible } = state.MonitorInfoReducer;
  return {
    viewList,
    layout,
    projectId: openProjectId,
    tabSelectKeys,
    menuType,
    currentVerificationId,
    tabVisible,
    pageType: CASCADE,
    DCRVerificationId,
    IRVerificationId,
    ImpedanceVerificationId,
    projectName,
    DesignTreeVerificationId,
    SignOffVerificationId
  }
}
const mapDispatch = (dispatch) => ({
  _changeTabMenu(info) {
    dispatch(changeTabMenu(info));
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  }
})

export default connect(mapState, mapDispatch)(CascadeContent)