function isBrowser() {//Check the browser kernel--two keys are returned, name: the name of the browser kernel---version: the version number of the browser
  let _broswer = {};
  let sUserAgent = getUserAgent();
  //opera
  let isOpera = sUserAgent.indexOf("Opera") > -1;
  if (isOpera) {
    //First check if Opera is disguised
    if (navigator.appName == 'Opera') {
      //If there is no disguise, go directly to the version number
      _broswer.version = parseFloat(navigator.appVersion);
    } else {
      let reOperaVersion = new RegExp("Opera (\\d+.\\d+)");
      //Use the test method of regular expressions to test and save the version number in RegExp.$1
      reOperaVersion.test(sUserAgent);
      _broswer.version = parseFloat(RegExp['$1']);
    }
    _broswer.opera = true;
    _broswer.name = 'opera';
  }
  //Chrome
  let isChrome = sUserAgent.indexOf("Chrome") > -1;
  if (isChrome) {
    let reChorme = new RegExp("Chrome/(\\d+\\.\\d+(?:\\.\\d+\\.\\d+))?");
    reChorme.test(sUserAgent);
    _broswer.version = parseFloat(RegExp['$1']);
    _broswer.chrome = true;
    _broswer.name = 'chrome';

    //old Edge
    //"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    let isEdge = sUserAgent.indexOf("Edge") > -1;
    if (isEdge) {
      let edgeReg = new RegExp("Edge/(\\d+.\\d+)");
      edgeReg.test(sUserAgent);
      _broswer.version = parseFloat(RegExp['$1']);
      _broswer.edge = true;
      _broswer.name = 'edge';
      delete _broswer.chrome;
    }
  }
  //Exclude Chrome information, because the keyword Konqueror/Safari will appear in the user-agent string of Chrome
  let isKHTML = (sUserAgent.indexOf("KHTML") > -1
    || sUserAgent.indexOf("Konqueror") > -1 || sUserAgent
      .indexOf("AppleWebKit") > -1)
    && !isChrome;
  // safari
  if (isKHTML) {//Determine whether it is based on KHTML, and if so, continue to determine which KHTML browser it belongs to
    let isSafari = sUserAgent.indexOf("AppleWebKit") > -1;
    let isKonq = sUserAgent.indexOf("Konqueror") > -1;
    if (isSafari) {
      let reAppleWebKit = new RegExp("Version/(\\d+(?:\\.\\d*)?)");
      reAppleWebKit.test(sUserAgent);
      let fAppleWebKitVersion = parseFloat(RegExp["$1"]);
      _broswer.version = parseFloat(RegExp['$1']);
      _broswer.safari = true;
      _broswer.name = 'safari';
    } else if (isKonq) {// konqueror
      let reKong = new RegExp(
        "Konqueror/(\\d+(?:\\.\\d+(?\\.\\d)?)?)");
      reKong.test(sUserAgent);
      _broswer.version = parseFloat(RegExp['$1']);
      _broswer.konqueror = true;
      _broswer.name = 'konqueror';
    }
  }
  // msie
  // !isOpera Avoid IE disguised as Opera
  let isIE = sUserAgent.indexOf("compatible") > -1
    && sUserAgent.indexOf("MSIE") > -1 && !isOpera;
  if (isIE) {
    let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(sUserAgent);
    _broswer.version = parseFloat(RegExp['$1']);
    _broswer.msie = true;
    _broswer.name = 'msie';
  }
  // mozilla
  // Eliminate the disguise of Chrome and Konqueror/Safari
  let isMoz = sUserAgent.indexOf("Gecko") > -1 && !isChrome && !isKHTML;
  if (isMoz) {
    let reMoz = new RegExp("rv:(\\d+\\.\\d+(?:\\.\\d+)?)");
    reMoz.test(sUserAgent);
    _broswer.version = parseFloat(RegExp['$1']);
    _broswer.mozilla = true;
    _broswer.name = 'mozilla';
  }
  return _broswer;//{ name:'',version:'', "broswer":true }  eg:{ name:'chrome', version:'0.84', chrome:true }
}

function getUserAgent() {
  return navigator.userAgent;
}
// user agent - Aurora-Rocky-Puppeteer
function isChrome() {
  if (isRockyPuppeteer()) {
    return true;
  }
  const browser = isBrowser();
  if (browser && browser.name === 'chrome') {
    return true;
  } else {
    return false;
  }
}

// is Aurora-Rocky-Puppeteer
function isRockyPuppeteer() {
  if (getUserAgent().includes('Aurora-Rocky-Puppeteer')) {
    return true;
  };
  return false;
}

export { isChrome, isRockyPuppeteer };