const PDN = 'PDN'
export const GET_PDN_CONTENT = `${PDN}/get_content`;
export const UPDATE_PDN_CONTENT = `${PDN}/update_content`;
export const SELECT_NETS = `${PDN}/select_nets`;
export const UPDATE_PDN_INTERFACE = `${PDN}/update_interface`;
export const SAVE_CONFIG_TO_SERVER = `${PDN}/save_config_to_serevr`;
export const VOLTAGE_CHANGE = `${PDN}/voltage_change`;
export const SAVE_VRM = `${PDN}/save_vrm`;
export const EXPAND_MENU = `${PDN}/expand_menu`;
export const SAVE_COMPONENTS = `${PDN}/save_components`;
export const IGNORE_COMPONENTS = `${PDN}/ignore_chips`;
export const UPDATE_PACKAGE_INFO_IN_PDN_CONTENT = `${PDN}/update_package_info_in_pdn_content`;
export const SAVE_CONFIG = `${PDN}/save_config`;
export const FIND_VRM_MODEL = `${PDN}/find_vrm_model`;
export const FIND_VRM_LIST_MODEL = `${PDN}/find_vrm_list_model`;
export const DefaultVRMModelInSPIM = `${PDN}/defaultVRMModelInSPIM`;
export const SAVE_OPTIMIZATION = `${PDN}/save_optimiation`;
export const SAVE_SPLIT_COMPONENTS = `${PDN}/save_split_components`;
export const SAVE_MERGE_COMPONENTS = `${PDN}/save_merge_components`;
export const CHANGE_USAGE = `${PDN}/change_usage`;
export const UPDATE_PDN_IFDOEXTRACTION = `${PDN}/update_pdn_ifdoextraction`;
export const SAVE_CURRENT_PDN = `${PDN}/save_current_pdn`;
export const CURRENT_PDN_DEBUG_VERIFY = `${PDN}/current_pdn_debug_verify`;
export const SAVE_BGA_CONNECTION_NAME = `${PDN}/save_bga_connection_name`;
export const UPDATE_COMP_RLC_PREFIX = `${PDN}/update_comp_rlc_prefix`;
export const GET_VRM_COMP = `${PDN}/get_vrm_components`;
export const DECAP_REMOVED_CHANGE = `${PDN}/decap_removed_change`;
export const SAVE_CURRENT_PDN_BY_SETTING = `${PDN}/save_current_pdn_by_setting`;
export const PREFIX_PROMPT = `${PDN}/prefix_prompt`;
export const SAVE_PDN_CONTENT = `${PDN}/save_pdn_content`;
export const UPDATE_INCLUDE_EXTENDED = `${PDN}/update_include_extended`;
export const SAVE_MAIN_NETS = `${PDN}/save_main_nets`;
export const CHECK_NETS = `${PDN}/check_nets`;
export const UPDATE_CHECK_NETS = `${PDN}/update_check_nets`;
export const CLOSE_CHECK_NETS_MSG = `${PDN}/close_check_nets_msg`;
export const FIND_VRM_LOADING = `${PDN}/find_vrm_loading`;
export const SAVE_SELECTED_MODEL = `${PDN}/save_selected_model`;
export const CREATE_COPY_DECAP = `${PDN}/create_copy_decap`;
export const CREATE_COPY_DECAP_END = `${PDN}/create_copy_decap_end`;
export const SAVE_VRM_MODEL = `${PDN}/save_vrm_model`;
export const AUTO_SELECT_NETS = `${PDN}/auto_select_nets`;
export const UPDATE_SELECT_NETS = `${PDN}/update_select_nets`;
export const AUTO_CHECK_NETS = `${PDN}/auto_check_nets`;
export const GET_NETS = `${PDN}/get_nets`;
export const FIND_POWER_DOMAIN_LOADING = `${PDN}/find_power_domain_loading`;
export const UPDATE_CAPCONNECTREFNET = `${PDN}/update_capsConnectRefNet`;
export const SAVE_PACKAGE_COMPONENT = `${PDN}/save_package_component`;
export const UPDATE_SIM_CONFIG = `${PDN}/update_sim_config`;
export const SAVE_PREVIEW_STATUS = `${PDN}/save_preview_status`;
export const TRACE_BACK_VRM = `${PDN}/trace_back_vrm`;
export const UPDATE_PDNS_AFTER_REPLACE_PCB = `${PDN}/update_pdns_after_replace_pcb`;
