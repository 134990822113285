import NP from 'number-precision';
import { getRockyImpedanceRelated } from '../../rockyCtrl';

async function npiFileParseImpedance(data, components, pdnId, fileName, targetFile, type, portList, verificationId) {
  let lineBuffer = data.match(/[^\r\n]+/g);
  let portNum = Number(lineBuffer[0].slice(5));
  let hashId = [];
  let ports = [],
    frequency = [],
    endLine = portNum + 1;
  let powerDisc = {};
  try {
    switch (type) {
      default:
        powerDisc = await getRockyImpedanceRelated(pdnId, verificationId);
        break;
    }
  } catch (e) {
    console.error(e)
  }

  for (let i = 1; i < endLine; i++) {
    if (!lineBuffer[i]) continue;
    let words = lineBuffer[i].split(' ');//"1", "1"
    //words => ['2', '0.1', 'power1_U9_G12::VDDA_0P8_DP']
    //['2', '0.1', 'power1_VRM::VDDA_0P8_DP']
    const [compInfo, powerNetName] = words[2].split("::");//['power1_U9_G12', 'VDDA_0P8_DP']
    let [power, comp, ...pin] = compInfo.split('_'); // ['power1, 'U9', 'G12']
    const _power = powerDisc[power] ? powerDisc[power] : power;
    let name = `${_power}`;
    const powerPin = pin && pin[0] ? pin[0] : "";
    const isVRM = comp && comp === "VRM" && !pin.length ? true : false;
    if (isVRM) {
      pin = ["VRM"];
    }
    const findPort = portList.find(it => powerPin && it.powerPins && it.powerPins.includes(powerPin));
    let portName = "";
    if (findPort) {
      portName = findPort.portName || "";
    }
    //Generate 10-digit random numbers containing numbers and letters
    let str = Math.random().toString(36).slice(2, 12);
    //Determine if it already exists
    while (hashId.includes(str)) {
      str = Math.random().toString(36).slice(2, 12);
    };
    hashId.push(str);
    ports.push({
      index: Number(words[0]),
      impedance: Number(words[1]),
      name: name,
      pin: comp === 'sense' ? ['sense'] : (pin || []),
      power: _power,
      portType: comp === 'sense' ? 'sense' : 'simulation',
      comp: name,
      signal: name,
      compType: '',
      display: true,
      positivePin: '',
      negativePin: '',
      fileName,
      hashId: str,
      powerPin,
      portName,
      powerNetName
    })
  }


  for (let i = portNum + 2, length = lineBuffer.length; i < length; i++) {
    frequency.push(Number(lineBuffer[i]))
  }
  return {
    ports: ports,
    freq: frequency,
  }
}

function mergeDecapData(impedanceSetup, decapEffectiveRLfile) {
  const TxtData = decapEffectiveRLfile.match(/[^\r\n]+/g).map(item => {
    return item.split(" ")
  }).map(item => {
    //return { name: item[0], resistance: item[1], inductance: item[2] }
    const decapRL = new DecapRL({ name: item[0], resistance: item[1], inductance: item[2], inductance_10: item[3] ? item[3] : NaN, inductance_100: item[4] ? item[4] : NaN })
    decapRL.changeResistanceTomOhm()
    decapRL.changeInductanceTopH()
    return decapRL
  })
  const powerDomains = impedanceSetup.powerDomains || []
  const data = powerDomains.map(item => {
    const { PowerNets, Components } = item
    const capComps = Components.map(item => {
      const { name, part } = item
      return item.COMP_TYPE === 'Cap' ? { name, part } : null
    }).filter(item => !!item).map(item => {
      const param = TxtData.find(param => param.name === item.name)
      if (param) {
        return Object.assign(item, param)
      }
      return null
    }).filter(item => !!item);
    return {
      powerNet: PowerNets[0],
      showTable: true,
      capComps
    }
  })
  return data
}

function DecapRL({ name = '', resistance = '', inductance = '', inductance_10, inductance_100 }) {
  this.name = name
  this.resistance = Number(resistance)
  this.inductance = Number(inductance)
  this.inductance_10 = Number(inductance_10)
  this.inductance_100 = Number(inductance_100)
}

DecapRL.prototype.changeResistanceTomOhm = function () {
  if (typeof this.resistance === "number") {
    this.resistance = NP.round(NP.times(this.resistance, 1000), 2)
  }
}
DecapRL.prototype.changeInductanceTopH = function () {
  if (typeof this.inductance === "number") {
    this.inductance = NP.round(NP.times(this.inductance, 1e12), 2)
  }
  if (typeof this.inductance_10 === "number") {
    this.inductance_10 = NP.round(NP.times(this.inductance_10, 1e12), 2)
  }
  if (typeof this.inductance_100 === "number") {
    this.inductance_100 = NP.round(NP.times(this.inductance_100, 1e12), 2)
  }
}

function getImpedanceResultMaxFreq(content) {
  //FMAX
  if (!content || !content.extraction || !Object.keys(content.extraction).length) {
    return null;
  }
  return Number(content.extraction.FMAX) || null;
}

export {
  npiFileParseImpedance,
  mergeDecapData,
  getImpedanceResultMaxFreq
}