import { connect } from 'react-redux';
import {
  selectLayer,
  selectChange,
  openPanel,
  closePanel,
  updateLocation,
  cancelSelectedAll,
  changeDisplaySelected
} from '../store/Cascade/actionCreators';
import LayoutExplorer from '../components/explorer';
import SearchBox from './SearchBox';
import LayerTab from './LayerTab';
import ComponentTable from './DesignInfo/ComponentTable';
import NetTable from './DesignInfo/NetTable';
import Stackup from '../components/Stackup_v1';
import higherOrderComponent from '../components/explorerHOC';
import { CASCADE } from '../../../constants/pageType';
import { updateStackupList } from '@/pages/Cascade/store/project/action.js'
import { getNewStackupList } from '@/services/Cascade/stackup/stackupHelper.js';
import {
  saveData,
  updateShowZonesStatus,
  changeStackupZonePanelShow,
  updateSelectedZones
} from '../components/Stackup_v1/store/actionCreators';
import StackupPanel from '../../../components/StackupPanel';
import PadStackInfo from './DesignInfo/PadStackInfo';
import PCBDownload from '../components/pcbDownload';
import { startLayoutCheck } from '../../Cascade/store/project/action';
import LayoutError from '../components/DesignInfo/LayoutError';


const mapState = (state) => {
  const { CascadeReducer: {
    project: { selectedKeys, stackupList, openProjectId, layout },
    DCR: { designId: dcrDesignId },
    IR: { designId: irDesignId },
    Impedance: { designId: impDesignId },
    DesignTree: { designId: treeDesignId },
    SignOffTemplate: { designId: signOffDesignId },
    explorer: { panelType, colorBy, simulation, selections }
  },
    PanelStatus: { defaultLeft, defaultTop },
    MonitorInfoReducer: { tabVisible },
    StackupReducer: { stackupObj },
    LayoutReducer: { results, simulationDesigns }
  } = state;

  return {
    panelType, colorBy,
    simulation, defaultLeft, defaultTop,
    tabVisible, selections,
    layout,
    stackupList,
    stackupListProps: { selectedKeys, treeDesignId, dcrDesignId, irDesignId, impDesignId, signOffDesignId },
    projectId: openProjectId,
    pageType: CASCADE,
    stackupObj,
    layoutResults: results,
    simulationDesigns
  }
}

const mapDispatch = (dispatch) => ({
  updateLocation(location, designID) {
    dispatch(updateLocation(location, designID))
  },
  select(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  selectLayer(checkedList, designID) {
    dispatch(selectLayer(checkedList, designID));
  },
  openPanel(type, designID) {
    dispatch(openPanel(type, designID))
  },
  closePanel(designID) {
    dispatch(closePanel(designID))
  },
  cancelSelectedAll(designID) {
    dispatch(cancelSelectedAll(designID))
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  updateStackupList(stackupList) {
    dispatch(updateStackupList(stackupList))
  },
  setStackupData({ data, materialList, stackups, zones, unit, designID, bends }) {
    dispatch(saveData({ data, materialList, stackups, zones, unit, designID, bends }));
  },
  _updateShowZonesStatus(show, designID) {
    dispatch(updateShowZonesStatus(show, designID))
  },
  _changeStackupZonePanelShow(show, designID) {
    dispatch(changeStackupZonePanelShow(show, designID))
  },
  _updateSelectedZones(selectedZones, designID) {
    dispatch(updateSelectedZones(selectedZones, designID))
  },
  startLayoutCheck(id) {
    dispatch(startLayoutCheck({ id }))
  }
})


const LayoutExplorerHOC = higherOrderComponent({ LayoutExplorer, SearchBox, LayerTab, ComponentTable, NetTable, Stackup, StackupPanel, getNewStackupList, PadStackInfo, PCBDownload, LayoutError });

export default connect(mapState, mapDispatch)(LayoutExplorerHOC);