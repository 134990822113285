import { getFileInRockyVerification } from '../../../api/Rocky/rockyCtrl';
import { ResultData } from '../index';


function getCrosstalkData({ verificationSubId, verificationId, channelId, interfaceName, fileName, isMultiResult }) {
  let path = `${verificationSubId}/result/${interfaceName}/data/waveform/${fileName}`;

  // verificationSubid/result/multi_byte_xtalk_write.csv
  return new Promise((resolve, reject) => {
    if (isMultiResult) {
      let path = `${verificationSubId}/result/${fileName}`;
      getFileInRockyVerification({ channelId, filePath: path }).then((response) => {
        if (response && response.data) {
          const data = parseMultipleCrosstalkModelSelectorFile(response.data)
          resolve(data)
        }
        resolve([])
      }, error => {
        reject(error)
      })
    } else {
      getFileInRockyVerification({ channelId, filePath: path }).then((response) => {
        ResultData.getVerificationJsonPromise({ verificationId, channelId, verificationSubId }).then(content => {
          const data = parseCrosstalkModelSelectorFile(response.data, content);
          resolve(data);
        })
      }, error => {
        reject(error)
      })
    }
  });
}

export function parseCrosstalkModelSelectorFile(fileContent, content) {
  if (!content) {
    return []
  }
  let crosstalkData = [];
  let fileArray = fileContent.match(/[^\r\n]+/g);
  for (let i = 0, len = fileArray.length; i < len; i++) {
    let words = fileArray[i].trim().split(",");
    crosstalkData.push({ Signal: words[0], Xtalk: words[1], VMax: words[2] || null })
  }
  crosstalkData.shift()
  return crosstalkData
}

export function getMultipleCrosstalkData() {
  return new Promise((resolve, reject) => {
    const data = parseMultipleCrosstalkModelSelectorFile()
    resolve(data)
  })
}

export function parseMultipleCrosstalkModelSelectorFile(fileContent) {
  let fileArray = fileContent.match(/[^\r\n]+/g) || [];
  let crosstalkData = [], dataList = [];
  for (let i = 0, len = fileArray.length; i < len; i++) {
    let words = fileArray[i].trim().split(",");
    if (i === 0) {
      // dataList = [...words]
      dataList = words.map(item => {
        if (item.includes('_DQSP')) {
          let replaceValue = item.replace('_DQSP', '_DQS');
          return replaceValue;
        }
        return item
      })
    } else if (dataList && dataList.length) {
      let crosstalkList = [], Signal = dataList[i - 1] ? dataList[i - 1] : "";
      for (let j = 0, len = words.length; j < len; j++) {
        const word = words[j];
        const _signal = dataList[j] ? dataList[j] : "";
        if (Signal && Signal === _signal) { continue }
        crosstalkList.push({ Signal: _signal, VMax: word })
      }
      crosstalkData.push({ Signal, crosstalkList })
    }
  }
  return crosstalkData
}

export default getCrosstalkData;

