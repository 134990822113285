import NP from 'number-precision';
import { select } from 'd3-selection';
import { getTextWidth } from '../../../../helper/getTextWidth';

class TargetLine {
  constructor() {
    this.verticalLine = [];
    this.horizontalLine = [];
  }

  setValue({ plotId, verticalLine, horizontalLine, getXValue, getYValue, height, width, yMax, yMin, xMax, xMin }) {
    this.plotId = plotId || this.plotId;
    this.verticalLine = verticalLine || this.verticalLine;
    this.horizontalLine = horizontalLine || this.horizontalLine;
    this.height = height || this.height;
    this.width = width || this.width;
    this.getXValue = getXValue || this.getXValue;
    this.getYValue = getYValue || this.getYValue;
    this.xMin = typeof xMin === 'number' ? xMin : this.xMin;
    this.xMax = typeof xMax === 'number' ? xMax : this.xMax;
    this.yMin = typeof yMin === 'number' ? yMin : this.yMin;
    this.yMax = typeof yMax === 'number' ? yMax : this.yMax;
  }

  drawLines(root, drawType) {
    if (select(`#${this.plotId}-target-line`).empty()) {
      this.lineRoot = root.append('g')
        .attr('id', `${this.plotId}-target-line`);
    }

    switch (drawType) {
      case 'verticalLine':
        this.drawVerticalLine();
        break;
      case 'horizontalLine':
        this.drawHorizontalLine();
        break;
      default:
        this.drawVerticalLine();
        this.drawHorizontalLine();
    }
  }

  drawVerticalLine() {
    this.removeLine(`${this.plotId}-vertical-line`)
    const xRoot = this.lineRoot.append('g')
      .attr('id', `${this.plotId}-vertical-line`);

    for (const item of this.verticalLine) {
      const realX = NP.strip(NP.times(item, 1e9));
      const textWidth = getTextWidth(item, 12, null, 500);
      if (realX >= this.xMin && realX <= this.xMax) {
        xRoot.append("line")
          .attr('x1', this.getXValue(realX))
          .attr('y1', 0)
          .attr('x2', this.getXValue(realX))
          .attr('y2', this.height)
          .attr('stroke', '#7a4816')
          .attr('stroke-width', 1.5)
          .attr('stroke-dasharray', "3,3");
        xRoot.append('text')
          .text(item)
          .attr('x', this.getXValue(realX) - textWidth / 2)
          .attr('y', -6)
          .attr('font-size', '12px')
          .attr('font-weight', 500)
          .attr('fill', '#7a4816');
      }
    }
  }

  drawHorizontalLine() {
    this.removeLine(`${this.plotId}-horizontal-line`)
    const yRoot = this.lineRoot.append('g')
      .attr('id', `${this.plotId}-horizontal-line`);

    for (const _item of this.horizontalLine) {
      const item = Number(_item);
      if (item >= this.yMin && item <= this.yMax) {
        yRoot.append('line')
          .attr('x1', 0)
          .attr('y1', this.getYValue(item))
          .attr('x2', this.width)
          .attr('y2', this.getYValue(item))
          .attr('stroke', '#7a4816')
          .attr('stroke-width', 1.5)
          .attr('stroke-dasharray', "3,3");
        yRoot.append('text')
          .text(item)
          .attr('x', this.width + 4)
          .attr('y', this.getYValue(item) + 4)
          .attr('font-size', '12px')
          .attr('font-weight', 500)
          .attr('fill', '#7a4816')
      }
    }
  }

  removeLine(id) {
    if (id && !select(`#${id}`).empty()) {
      select(`#${id}`).remove();
    }
  }
}

export default TargetLine;