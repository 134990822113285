import {
  GET_DCR_INFO,
  UPDATE_DCR_DATA,
  SAVE_DCR_RESISTANCE_DATA,
  CHANGE_RESISTANCE_TABLE_LOADING,
  UPLOAD_DCR,
  UPLOAD_DCR_MSG,
  SAVA_VERIFICATION_ID,
  DELETE_PATHR_DATA,
  RESISTANCE_SELECTED_LIST,
  CLEAR_CURRENT_PATHR_DATA,
  UPDATE_PCB_ID,
  CHANGE_PCB,
  SAVE_EXTRACTION,
  UPDATE_WARNING_MSG
} from './actionType'

export const getDCRInfo = (verificationId) => ({
  type: GET_DCR_INFO,
  verificationId
})

export const updateDCRData = ({ resistanceData, pairs, designName, extraction, errorMessage, warningMessage }) => ({
  type: UPDATE_DCR_DATA,
  resistanceData,
  pairs,
  designName,
  extraction,
  errorMessage,
  warningMessage
})

export const saveDCRResistanceData = (dataList, pairs, designName, extraction) => ({
  type: SAVE_DCR_RESISTANCE_DATA,
  dataList,
  pairs,
  designName,
  extraction
})

export const changeResistanceTableLoading = (loading) => ({
  type: CHANGE_RESISTANCE_TABLE_LOADING,
  loading
})

export const uploadDCR = (verificationId, file, importType) => ({
  type: UPLOAD_DCR,
  verificationId,
  file,
  importType
})

export const uploadDCRErrorMsg = (msg) => ({
  type: UPLOAD_DCR_MSG,
  msg
})

export const savaVerificationId = (id) => ({
  type: SAVA_VERIFICATION_ID,
  id
})

export const deletePathRData = (idList) => ({
  type: DELETE_PATHR_DATA,
  idList
})

export const resistanceSelectedList = (selectList) => ({
  type: RESISTANCE_SELECTED_LIST,
  selectList
})

export const clearCurrentPathRData = () => ({
  type: CLEAR_CURRENT_PATHR_DATA
})

export const updatePCBId = (designId) => ({
  type: UPDATE_PCB_ID,
  designId
})

export const changePCB = (designId) => ({
  type: CHANGE_PCB,
  designId
})

export const saveExtraction = (extraction) => ({
  type: SAVE_EXTRACTION,
  extraction
})

export const updateWarningMsg = (msg) => ({
  type: UPDATE_WARNING_MSG,
  msg
})