import React, { Component, Fragment } from 'react';
import {
  CloseCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Select, Input, Dropdown, Popover, Tooltip, Switch, Collapse } from "antd";
import { ANDES_V2, ROCKY, SIERRA } from "../../constants/pageType";
import { getPinHeight, getPkgPinListWidth } from '@/services/helper/packageHelper';
import ModelSelect from './modelSelect';
import {
  getModelPinList,
  getModelPinListByCompPins,
  getModelPairsByCompPins,
  getPkgDisplayPortList,
  getNewModelSelectList,
  getNewPkgModels,
  getModelTitle,
  getCoordinateInfo,
  getPortModelKey,
  getPortIndexList
} from '../../services/helper/packageModelHelper';
import CircleTag from '../CircleTag';
import MoreModelAssign from './moreModelAssign';
import { strDelimited } from '../../services/helper/split';
import { getPopoverPlacement } from '@/services/helper/htmlElement';
import { getTextWidth } from '@/services/helper/getTextWidth';
import { getDefaultName } from '@/services/helper/setDefaultName';
import AutomaticPanel from '@/components/PortMatch';
import TreeSelect from '@/components/TreeSelect';
import { CARD } from '@/constants/treeConstants';
import { MEMORY } from '@/constants/componentType';
import { N_PORT } from '../../services/VirtualComponent';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import '@/publicCss/netlist.css';

const { Option, OptGroup } = Select;
const PCB = "PCB", PACKAGE = 'Package', CONNECTOR = 'Connector', PreLayout = 'PreLayout', EBD = 'EBD', TOUCHSTONE_SPICE = "Touchstone / SPICE";
const horizontalList = ["Package", "Connector", "Cable", PCB, PreLayout];
const PINV = 'pinValue', PINS = 'pinSubckt', PINL = 'pinLibraryId', PIN = 'pin', PINK = "pinModelKey";
const AfterRename = "after_rename";

class NetListModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelPinInfo: null,
      searchValue: "",
      selectFile: {},//nodes select box selected{libraryId, modelKey,subckt}
      editNode: null,
      editType: null,
      editTerminationNode: "",
      editMoreAssignIndex: -1,
      automaticInfo: null,
      visible: false,
      editComp: null,
      updateHeightStatus: false,
      inputVisible: {
        // pin/net/after_rename/''
        key: '',
        // selection: net/U1/U2
        renameType: '',
        renameValue: '',
        errorMsg: ''
      },
      editInputInfo: {
        pin: "",
        type: "",
        value: ""
      }
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  componentDidUpdate(prevProps) {
    const { pairs } = this.props;
    const { updateHeightStatus } = this.state;
    if (pairs.length !== prevProps.pairs.length) {
      // If the number of pairs changes, update the height
      // If the number of channels is modified and the height is displayed incorrectly when switching from the PreLayout page to the PreLayout channel page
      this.setState({
        updateHeightStatus: !updateHeightStatus
      })
    }
  }

  changeInputVisible = (inputVisible = { key: '', renameType: '', renameValue: '' }) => {
    const { modelType, product } = this.props
    if (modelType === PreLayout && product === ANDES_V2) {
      this.setState({
        inputVisible
      })
    }
  }

  changeInputValue = (e) => {
    this.setState({
      inputVisible: {
        ...this.state.inputVisible,
        renameValue: e.target.value
      }
    })
  }

  enterDown = (e) => {
    e.target.blur();
  }

  getNetOrPinInput = (itemData, nameType) => {
    const { inputVisible: { renameValue, errorMsg } } = this.state
    return <Tooltip
      className='aurora-error-tooltip'
      title={errorMsg ? errorMsg : null}
      overlayClassName='aurora-error-msg-tooltip'
      open={errorMsg ? true : false}
    >
      <Input
        className='spice-box-rename-input aurora-input'
        value={renameValue}
        onBlur={() => this.editNetOrPinName(itemData, nameType)}
        onPressEnter={e => { this.enterDown(e); this.changeInputVisible() }}
        onChange={this.changeInputValue}
        autoFocus
      />
    </Tooltip>
  }

  editNetOrPinName = (preData, nameType) => {
    const { pinType, signalGroup, signal, net, pin } = preData
    const { inputVisible: { renameValue } } = this.state
    const { designID } = this.props
    const oldName = net || pin
    if (renameValue !== oldName) {
      let errorMsg = checkNameFormat(renameValue);
      if (errorMsg) {
        this.setState({
          inputVisible: { ...this.state.inputVisible, errorMsg }
        });
        return;
      } else {
        this.changeInputVisible({ key: AfterRename, renameType: '', renameValue: '' })
        this.props.editPinOrNetName(
          {
            pinType: pinType,
            signalGroup: signalGroup,
            signal: signal,
            oldName: net || pin
          },
          renameValue,
          nameType,
          designID
        )
      }
    } else {
      this.changeInputVisible({ key: AfterRename, renameType: '', renameValue: '' })
    }
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const PopoverRoot = document.getElementsByClassName('spice-node-select-Popover')[0];
    const selectRoot = document.getElementsByClassName('nodes-list-file-dropdown')[0];

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || (PopoverRoot && !PopoverRoot.contains(target) && (!selectRoot || !selectRoot.contains(target)))) {
      this.closeNodesSelection();
    }
  }

  _selectFile = (key, modelKey, fileType) => {
    //key -> {key:id::fileName(id), label:"name" }, key: folder -> id::fileName , file -> id
    this.props._selectFile(key, modelKey, fileType);
  }

  selectSpiceSubckt = (key, modelKey, fileType) => {
    this.props._selectSpiceSubckt(key, modelKey, fileType);
  }

  deleteFileSelect = (type, modelKey, fileType, deleteLine, libraryId) => {
    this.props._delFileSelect(type, modelKey, fileType, deleteLine, libraryId);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  }

  addNewFileSelect = (e, fileType) => {
    e.stopPropagation();
    this.props._addNewFileSelect(fileType);
  }

  getSelectedFileList = (editType, getAll = false, modelInfoListIndex, isTermination) => {
    const { files: defaultFiles, newModelFilesObj, modelType, modelInfoList, termination, product } = this.props;
    const files = isTermination ? termination.files : defaultFiles;
    let _files = [], currentFiles = [];

    if ((!editType) || editType === modelType) {
      const addFiles = newModelFilesObj[modelType] && newModelFilesObj[modelType].allFiles ? newModelFilesObj[modelType].allFiles.filter(item => !files.find(it => it.libraryId === item.libraryId && it.modelKey === item.modelKey)) : [];
      _files = [...files, ...addFiles];
    } else {
      _files = newModelFilesObj[editType].allFiles || [];
    }

    if (getAll) {
      return _files;
    }

    if (editType && modelInfoListIndex > -1) {
      const { modelFileKey } = modelInfoList[modelInfoListIndex];
      currentFiles = newModelFilesObj[editType][modelFileKey];
    } else {
      currentFiles = [...files];
    }

    let list = [];

    //Filter reused files
    const idMap = new Map();
    const existFiles = _files.filter(item => !!item.libraryId);

    currentFiles.forEach(item => {
      if (item) {
        if (!idMap.has(item.libraryId)) {
          idMap.set(item.libraryId, [item.subckt])
        } else {
          idMap.set(item.libraryId, [...idMap.get(item.libraryId), item.subckt])
        }
      }
    })

    const singleFiles = existFiles.filter(item => {
      if (modelType && product === ANDES_V2) return true;
      let data = idMap.get(item.libraryId)
      if (!data || !data.length) { return true }
      data = data.filter(it => it === item.subckt)
      if (data.length === 1) { return true }
      return false;
    });

    singleFiles.forEach(item => {
      if (!list.find(it => it.libraryId === item.libraryId && ((!it.subckt && item.subckt) || (it.subckt === item.subckt)))) {
        list.push({
          libraryId: item.libraryId,
          fileName: item.fileName,
          modelKey: item.modelKey,
          subckt: item.subckt,
          type: item.type
        });
      }
    });

    return list;
  }

  selectNodesFile = (keyObj, editType) => {
    const { files } = this.props;
    if (!keyObj) {
      this.setState({
        selectFile: {}
      })
      return;
    }
    const [libraryId, modelKey, subckt] = strDelimited(keyObj.key, "::");
    if (!libraryId || !modelKey) {
      this.setState({
        selectFile: {}
      })
      return;
    }
    const fileInfo = files.find(item => item.libraryId === libraryId);
    this.setState({
      selectFile: {
        libraryId,
        fileName: fileInfo ? fileInfo.fileName : '',
        modelKey,
        editType,
        subckt,
        type: fileInfo ? fileInfo.type : ''
      }
    })
  }

  selectPinNode = ({ port, pin, type, currentPortInfo, net, editType, modelInfoListIndex, component }) => {
    const { pinList, pairs, portsObj, modelInfoList, newModelFilesObj, determineComp, termination, files, modelType, product } = this.props;
    //type: "pin" / "pinDie"
    const subckt = currentPortInfo.subckt, libraryId = currentPortInfo.libraryId,
      modelKey = getPortModelKey({
        port, modelType, product,
        modelKey: currentPortInfo.modelKey,
        files: type === 'pinTermination' ? termination.files : files,
        pairs: type === 'pinTermination' ? termination.pairs : pairs
      });

    let _modelInfoList = [...modelInfoList], _newModelFilesObj = { ...newModelFilesObj };
    let _pinList = JSON.parse(JSON.stringify(pinList || []));
    let _pairs = type === 'pinTermination' ? JSON.parse(JSON.stringify(termination.pairs || [])) : JSON.parse(JSON.stringify(pairs || []))

    if (editType) {
      const currentModelInfo = modelInfoListIndex > -1 && _modelInfoList[modelInfoListIndex] ? _modelInfoList[modelInfoListIndex] : {};
      _pinList = JSON.parse(JSON.stringify(currentModelInfo.modelPinList || []));
      _pairs = JSON.parse(JSON.stringify(currentModelInfo.pairs || []));
    }

    //find current libraryId ports
    let currentFiles = portsObj[libraryId] ? portsObj[libraryId] : [];
    if (currentFiles.length === 0) {
      return;
    }
    //update pinList
    const indexList = _pinList.map((item, i) => { if (item.net === net) { return i }; return null }).filter(item => item !== null);
    let index = -1, index_child = -1;
    for (let item of indexList) {
      if (!_pinList[item] || !_pinList[item][type]) {
        continue;
      }
      const _index_child = _pinList[item][type].findIndex(it => it.pin === pin);
      if (_index_child > -1) {
        index = item;
        index_child = _index_child;
        break;
      }
    }

    let anotherType = "", anotherPins = [];
    if (type === "pinLeft") {
      anotherType = "pinRight"
    } else if (type === "pinRight") {
      anotherType = "pinLeft"
    }
    if (index > -1 && index_child > -1) {
      _pinList[index][type][index_child][PINV] = port;
      //SPICE subckt
      _pinList[index][type][index_child][PINS] = subckt ? subckt : "";
      _pinList[index][type][index_child][PINL] = libraryId;
      _pinList[index][type][index_child][PINK] = modelKey;

      // Delete other library ports of the same pin
      if (_pinList[index][anotherType] && _pinList[index][anotherType].length) {
        _pinList[index][anotherType].forEach(item => {
          if ((item[PINL] !== libraryId || item[PINK] !== modelKey) && _pinList[index][anotherType][index_child]) {
            _pinList[index][anotherType][index_child][PINV] = "";
            //SPICE subckt
            _pinList[index][anotherType][index_child][PINS] = "";
            _pinList[index][anotherType][index_child][PINL] = "";
            _pinList[index][anotherType][index_child][PINK] = "";
            anotherPins.push(_pinList[index][anotherType][index_child].pin)
          }
        })
      }
    }
    //update pairs
    const _index = _pairs.findIndex(item => item.pin === pin && (!determineComp || (determineComp && item.component === component)));
    if (_index > -1) {
      //update new node
      _pairs[_index].node = port;
      _pairs[_index].subckt = subckt ? subckt : "";;
      _pairs[_index].libraryId = libraryId;
      _pairs[_index].modelKey = modelKey;
    }

    // Delete other library ports of the same pin
    if (anotherPins.length) {
      for (let pinItem of anotherPins) {
        const _index = _pairs.findIndex(item => item.pin === pinItem && (!determineComp || (determineComp && item.component !== component)));
        if (_index > -1) {
          //update new node
          _pairs[_index].node = "";
          _pairs[_index].subckt = "";
          _pairs[_index].libraryId = "";
          _pairs[_index].modelKey = "";
        }
      }
    }

    if (editType) {

      if (modelInfoListIndex > -1 && _modelInfoList[modelInfoListIndex]) {
        let { modelType, modelFileKey } = _modelInfoList[modelInfoListIndex];

        let _files = _newModelFilesObj[modelType] && _newModelFilesObj[modelType][modelFileKey] ? [..._newModelFilesObj[modelType][modelFileKey]] : [];
        // Check if the selected files already exist in the files
        if (!_files.find(item => item.libraryId === libraryId && item.modelKey === modelKey)) {
          let _allFiles = (editType === this.props.modelType) ? [..._newModelFilesObj[modelType].allFiles, ...this.props.files] : _newModelFilesObj[modelType].allFiles;
          const addFile = _allFiles.find(item => item.libraryId === libraryId && item.modelKey === modelKey)
          _newModelFilesObj[modelType][modelFileKey] = [..._files, addFile]
        }
        _modelInfoList[modelInfoListIndex] = {
          ..._modelInfoList[modelInfoListIndex],
          pairs: _pairs,
          modelPinList: _pinList
        }
      }

      this.props._updateModelObj({
        modelInfoList: _modelInfoList,
        newModelFilesObj: _newModelFilesObj
      })
    } else {
      const isUpdateFiles = !(type === "pinTermination" ? termination.files : files).find(item => item.modelKey === modelKey),
        updateFilesParams = { key: { key: libraryId, subckt }, modelKey, fileType: type === "pinTermination" ? "termination" : "" }
      if (type === "pinTermination") {
        this.props._changeTermination({
          pinList: _pinList,
          pairs: _pairs
        }, isUpdateFiles, updateFilesParams)
      } else {
        this.props._selectNodes({
          pinList: _pinList,
          pairs: _pairs
        }, isUpdateFiles, updateFilesParams);
      }
    }

    this.setState({
      searchValue: port
    }, () => {

      //Open the next pin with no port selected
      //find next pin with no port selected
      let { nextType, sidePin, pinInfo, component } = this.findNextPin(_pinList, type, index, editType);
      if (sidePin) {
        //open next pin of not select port
        const cssId = editType ? `${sidePin.pin}_${editType}_${nextType}` : sidePin.pin;
        this.scrollView(cssId, type === 'pinTermination' ? type : nextType, sidePin, editType, pinInfo, modelInfoListIndex, component);
      } else {
        //close nodes select panel
        this.closeNodesSelection();
      }
    })
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: null,
      editType: null,
      searchValue: {},
      editComp: null
    })
  }

  scrollView = (anchorId, type, sidePin, editType, pinInfo, modelInfoListIndex) => {
    const { scrollId } = this.props;
    let scrollElement = document.getElementById(scrollId);  // The scroll container corresponding to the id
    let anchorElement = document.getElementById(anchorId);  // Need to locate the anchor element you see
    if (scrollElement && anchorElement) {
      const offsetTop = anchorElement.offsetTop;
      const top = offsetTop - 104;

      const offsetLeft = anchorElement.offsetLeft;
      const left = offsetLeft - 34;
      scrollElement.scrollTo({ top, left, behavior: "smooth" });
    }
    setTimeout(() => {
      this.openSelectNode({
        pin: sidePin[PIN],
        value: sidePin[PINV],
        type,
        currentItem: sidePin,
        editType,
        pinInfo,
        modelInfoListIndex,
        component: sidePin.component,
        isTermination: type === "pinTermination"
      });
    }, 340);
  }

  findNextPin = (pinList, type, index) => {
    const anotherType = type === "pinLeft" ? "pinRight" : "pinLeft";
    const { modelType, topology } = this.props;

    const findNext = (_index) => {
      const find = pinList.find((item, i) => i === _index);
      return find;
    }

    const findNoValue = (sidePinList, _type) => {
      for (let _pin of sidePinList) {
        if (!_pin[PINV]) {
          return { nextPin, nextType: _type, sidePin: _pin };
        }
      }
      return null
    }

    let nextIndex = index;
    let nextPin = findNext(index);
    let _find = null;
    if ((horizontalList.includes(modelType) || (modelType === "DIE" && topology !== 'parallel')) && nextPin) {
      //pinL find pinR, pinR find pinL
      if (nextPin[type]) {
        _find = findNoValue(nextPin[type], type);
        if (_find) return { ..._find, pinInfo: nextPin };
      }
      if (nextPin[anotherType]) {
        _find = findNoValue(nextPin[anotherType], anotherType);
        if (_find) return { ..._find, pinInfo: nextPin };
      }
    }

    if (horizontalList.includes(modelType) || (modelType === "DIE" && topology !== 'parallel')) {
      while (nextPin && nextIndex <= pinList.length) {
        nextIndex += 1;
        nextPin = findNext(nextIndex);
        if (nextPin && nextPin.pinLeft) {
          _find = findNoValue(nextPin.pinLeft, 'pinLeft');
          if (_find) return { ..._find, pinInfo: nextPin };
        }

        if (nextPin && nextPin.pinRight) {
          _find = findNoValue(nextPin.pinRight, 'pinRight');
          if (_find) return { ..._find, pinInfo: nextPin };
        }
      }
    } else {
      while (nextPin && nextPin[type] && nextIndex <= pinList.length) {
        nextIndex += 1;
        nextPin = findNext(nextIndex);
        if (nextPin && nextPin[type]) {
          _find = findNoValue(nextPin[type], type);
          if (_find) return { ..._find, pinInfo: nextPin };
        }
      }
    }

    return { nextPin: null, nextType: null, sidePin: null, component: null };
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  nodeSelectRender = ({ pin, type, port, placeholder, currentItem, net, editType, modelInfoListIndex, component }) => {
    const { searchValue, selectFile = {} } = this.state;
    const { portsObj, maxWidth, maxHeight, pinList, modelInfoList, modelType, product } = this.props;
    const currentInfo = modelInfoListIndex > -1 ? modelInfoList[modelInfoListIndex] : null;
    const _pinList = editType ? currentInfo && currentInfo.modelPinList ? currentInfo.modelPinList : [] : pinList;
    const _portList = getPkgDisplayPortList({ currentItem, searchValue, portsObj, selectFile, pinList: _pinList, modelType, product });
    const displayPin = currentItem[`pinNodeDisplay`] ? currentItem[`pinNodeDisplay`] : pin;
    const selectFileList = this.getSelectedFileList(editType, false, modelInfoListIndex, type === 'pinTermination');
    return (
      <div className='nodes-content' style={{ maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200, maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100 }}>
        <div className='nodes-content-header'>
          <span className='nodes-content-header-span'>{editType ? `${editType} - ` : ""}Component Pin {displayPin}</span>
        </div>
        <div className='node-content-close' onClick={() => this.closeNodesSelection()}>
          <CloseOutlined className='node-content-close-icon' />
        </div>
        <div className="node-list-body-with-search">
          <div className="node-list-body-search-wrapper">
            <Select
              placeholder={`Select File/Subckt`}
              className='nodes-list-file-select'
              /*  allowClear */
              labelInValue
              value={{
                key: selectFile.libraryId ? selectFile.subckt ? `${selectFile.libraryId}::${selectFile.modelKey}::${selectFile.subckt}` : `${selectFile.libraryId}::${selectFile.modelKey}` : "",
                label: selectFile.subckt ? `${selectFile.fileName}::${selectFile.subckt}` : selectFile.fileName || ""
              }}
              popupClassName='nodes-list-file-dropdown'
              popupMatchSelectWidth={false}
              onChange={option => option && this.selectNodesFile({ label: option.label, key: option.value }, editType)}
            >
              {selectFileList.map(item => (
                <Option
                  key={item.subckt ? `${item.libraryId}::${item.modelKey}::${item.subckt}` : `${item.libraryId}::${item.modelKey}`}
                  title={item.subckt ? `${item.fileName}::${item.subckt}` : item.fileName || ""}
                  className='spice-nodes-content-file'
                >
                  {/* {item.fileName} */}
                  {item.subckt ? `${item.fileName}::${item.subckt}` : item.fileName || ""}
                </Option>
              ))
              }
            </Select>
            <Input
              placeholder={`Search ${placeholder} `}
              allowClear
              suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='node-list-ul' style={{ maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100 }}>
              {_portList.map(item =>
                <li
                  key={item.port}
                  title={item.info}
                  className={item.port === port && currentItem.pinLibraryId === selectFile.libraryId && item.select ? 'current-pin-selected-node-li' : (item.select ? 'node-li-selected' : '')}
                  onClick={!item.select ? () => this.selectPinNode({ port: item.port, type, pin, currentPortInfo: item, net, editType, modelInfoListIndex, component }) : null}
                >
                  {item.info !== item.port ? <Fragment>{item.port}&nbsp;&nbsp;&nbsp;&nbsp;{item.info}</Fragment> : item.port}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  deleteSelectNode = (e, item, type, net, editType, modelInfoListIndex, component) => {
    //type: "pinLeft" / "pinRight"
    e && e.stopPropagation();
    const pin = item[PIN];
    const { pinList, pairs, modelInfoList, newModelFilesObj, files: defaultFiles, determineComp, termination = {} } = this.props;
    const files = type === 'pinTermination' ? termination.files : defaultFiles;
    //update pinList
    //delete current pinL/pinR port in pinList
    let _pinList = JSON.parse(JSON.stringify(pinList || [])),
      _pairs = type === 'pinTermination' ? JSON.parse(JSON.stringify(termination.pairs || [])) : JSON.parse(JSON.stringify(pairs || [])),
      _modelInfoList = [...modelInfoList];
    if (editType) {
      const currentModelInfo = modelInfoListIndex > -1 && _modelInfoList[modelInfoListIndex] ? _modelInfoList[modelInfoListIndex] : {};
      _pinList = JSON.parse(JSON.stringify(currentModelInfo.modelPinList || []));
      _pairs = JSON.parse(JSON.stringify(currentModelInfo.pairs || []));
    }

    const indexList = _pinList.map((item, i) => { if (item.net === net) { return i }; return null }).filter(item => item !== null);
    let itemIndex = -1, index = -1;
    for (let item of indexList) {
      if (!_pinList[item] || !_pinList[item][type]) {
        continue;
      }
      const _index_child = _pinList[item][type].findIndex(it => it[PIN] === pin);
      if (_index_child > -1) {
        itemIndex = item;
        index = _index_child;
        break;
      }
    }
    if (index > -1) {
      _pinList[itemIndex][type][index][PINV] = "";
      _pinList[itemIndex][type][index][PINL] = "";
      _pinList[itemIndex][type][index][PINS] = "";
      _pinList[itemIndex][type][index][PINK] = "";
    }

    //update pairs
    //delete current pin/pin_die node in pairs
    const _index = _pairs.findIndex(item => item.pin === pin && (!determineComp || (determineComp && item.component === component)));
    if (_index > -1) {
      _pairs[_index].node = "";
      _pairs[_index].subckt = "";
      _pairs[_index].libraryId = "";
      _pairs[_index].modelKey = "";
    }

    //When the file is used multiple times, if the port used by the reused file is deleted, the reused file will be deleted;
    // let files = _modelInfoList[modelInfoListIndex]

    if (editType) {
      let _newModelFilesObj = { ...newModelFilesObj }
      if (modelInfoListIndex > -1 && _modelInfoList[modelInfoListIndex]) {
        const { modelType, modelFileKey } = _modelInfoList[modelInfoListIndex]
        let _files = newModelFilesObj[modelType] && newModelFilesObj[modelType][modelFileKey] ? newModelFilesObj[modelType][modelFileKey] : [];

        const newFiles = this.getFilterFiles(_files, _pairs, item);
        // const files = _modelInfoList[modelInfoListIndex].files.filter(item => item.libraryId === item.libraryId)
        if (newFiles.length !== _files) {
          _newModelFilesObj[modelType][modelFileKey] = newFiles;
          let _allFiles = [];
          Object.keys(_newModelFilesObj[modelType]).map(key => {
            if (key !== 'allFiles') {
              const addFiles = _newModelFilesObj[modelType][key].filter(item => !_allFiles.find(it => it.libraryId === item.libraryId && it.modelKey === item.modelKey))
              _allFiles = [..._allFiles, ...addFiles]
            }
            return key;
          })
          _newModelFilesObj[modelType].allFiles = _allFiles
        }

        _modelInfoList[modelInfoListIndex] = {
          ..._modelInfoList[modelInfoListIndex],
          pairs: _pairs,
          modelPinList: _pinList,
          files: newFiles
        }
      }

      this.props._updateModelObj({
        modelInfoList: _modelInfoList,
        newModelFilesObj: _newModelFilesObj
      })
    } else {
      const newFiles = this.getFilterFiles(files, _pairs, item);
      if (type === 'pinTermination') {
        this.props._changeTermination({
          pinList: _pinList,
          pairs: _pairs,
          files: newFiles
        })
      } else {
        this.props._selectNodes({
          pinList: _pinList,
          pairs: _pairs,
          files: newFiles
        });
      }
    }
  }

  getFilterFiles = (files, pairs, deleteInfo) => {
    const { pinLibraryId } = deleteInfo;
    const filterFiles = files.filter(item => item.libraryId === pinLibraryId);
    if (filterFiles && filterFiles.length > 1) {
      // const usedFiles = pairs
      let usedModelKey = new Set();
      pairs.forEach(item => {
        if (item.libraryId === pinLibraryId) {
          usedModelKey.add(item.modelKey)
        }
      })
      if (!usedModelKey.length) {
        usedModelKey.add(filterFiles[0].modelKey)
      }

      const usedFiles = files.filter(item => {
        if (item.libraryId === pinLibraryId && usedModelKey.has(item.modelKey)) {
          return true
        } else if (item.libraryId !== pinLibraryId) {
          return true
        }
        return false
      })
      return usedFiles
    }
    return files
  }

  openSelectNode = ({ pin, value, type, currentItem, editType, pinInfo, modelInfoListIndex, component, isTermination }) => {
    const libraryId = currentItem[PINL] ? currentItem[PINL] : "";
    const subckt = currentItem[PINS] ? currentItem[PINS] : "";
    const modelKey = currentItem[PINK] ? currentItem[PINK] : "";
    const { maxWidth, maxHeight } = this.props;
    const cssId = editType ? `${pin}_${editType}_${type}` : pin;
    const anotherType = type === "pinLeft" ? "pinRight" : "pinLeft";
    const anotherPin = pinInfo && pinInfo[anotherType] && pinInfo[anotherType][0] ? pinInfo[anotherType][0] : null;
    const { leftPlacement, rightPlacement } = getPopoverPlacement({ cssId, type, maxWidth, maxHeight })
    this.setState({
      leftPlacement,
      rightPlacement
    })

    const { selectFile } = this.state;
    let _selectFile = selectFile || {};
    const files = this.getSelectedFileList(editType, true, null, isTermination);
    //clear other model type selected file
    if (_selectFile.libraryId && _selectFile.editType !== editType) {
      _selectFile = {};
    }

    if (libraryId) {
      const file = files.find(item => item.libraryId === libraryId && item.modelKey === modelKey);
      _selectFile = { libraryId, fileName: file ? file.fileName : "", subckt, modelKey, editType, type: file ? file.type : "", };
    } else if (anotherPin && anotherPin[PINL]) {
      const file = files.find(item => item.libraryId === anotherPin[PINL] && item.modelKey === anotherPin[PINK]) || {};
      _selectFile = {
        libraryId: file.libraryId || "",
        fileName: file.fileName || "",
        subckt: anotherPin[PINS] || "",
        modelKey: file.modelKey || "",
        editType,
        type: file.type || "",
      };
    } else if (!files.length || (files.length && !files.find(item => item.libraryId === _selectFile.libraryId))) {
      // If the selected data does not have a library, clear the  selectFile data
      _selectFile = {
        libraryId: "",
        fileName: "",
        subckt: "",
        modelKey: "",
        editType,
        type: ""
      }
    }

    const _files = this.getSelectedFileList(editType, false, modelInfoListIndex, isTermination);
    const findFile = _files.find(item => item.libraryId === selectFile.libraryId && ((!item.subckt && !selectFile.subckt) || (item.subckt === selectFile.subckt)));
    if (!_selectFile.libraryId || (!findFile && (!libraryId && (!anotherPin || !anotherPin[PINL])))) {
      _selectFile = {
        libraryId: _files && _files[0] ? _files[0].libraryId : "",
        fileName: _files && _files[0] ? _files[0].fileName : "",
        subckt: _files && _files[0] ? _files[0].subckt : "",
        modelKey: _files && _files[0] ? _files[0].modelKey : "",
        editType,
        type: _files && _files[0] ? _files[0].type : "",
      };
    }

    this.setState({
      editNode: pin,
      editType: editType,
      editTerminationNode: isTermination ? pin : null,
      searchValue: value,
      selectFile: _selectFile,
      editMoreAssignIndex: modelInfoListIndex,
      editComp: component ? component : null,
    })
  }

  getSelectList(displayModelTitle, _files, fileType, disabled) {
    const { files, libraryListObj, fileList, newModelFilesObj, modelType, isPageDisplay, subcktList, pkgType, notUseMultipleFiles, product } = this.props;
    let modelSelectedFiles = _files ? _files : files;
    if (!fileType) {
      const addFiles = newModelFilesObj[modelType] && newModelFilesObj[modelType].allFiles ? newModelFilesObj[modelType].allFiles.filter(item => !files.find(it => it.libraryId === item.libraryId)) : []
      modelSelectedFiles = [...files, ...addFiles]
    }
    const title = fileType && fileType !== 'termination' ? `${fileType} File` : "Model File";
    let _title = displayModelTitle ? (fileType ? `${fileType} Model` : `${modelType} Model`) : null;
    const modelTitle = (!modelSelectedFiles || modelSelectedFiles.length === 0) ? title : "";
    const placeholder = _title || title;
    // const _models = getPkgModels(modelSelectedFiles);
    const modelObj = fileType === 'termination' ? { [modelType]: _files }
      : fileType ? newModelFilesObj[fileType]
        : newModelFilesObj && newModelFilesObj[modelType] ? { [modelType]: files, ...newModelFilesObj[modelType] }
          : { [modelType]: files };
    const models = getNewPkgModels(modelObj);
    const modelFiles = fileType ? libraryListObj[`${fileType.toLowerCase()}List`] : [...fileList];
    // const width = Object.keys(newModelFilesObj).length ? 120 : 100;
    let width = displayModelTitle ? 142 : (isPageDisplay ? 120 : 100), otherClassName = "";
    if (product === ANDES_V2 && modelType === "DIE") {
      otherClassName = " pkg-die-model-select";
      width = width + 30;
    }
    const allowClear = modelType === PreLayout ? false : true;
    return models && models.length ? models.map((item, index) => {
      let subckts = [], subcktBoxClassName = null, selectWidthClassName = null;
      if (["SPICE"].includes(item.type)) {
        const currentSubckt = subcktList ? subcktList.find(it => (it.libraryId === item.libraryId && it.fileName === item.fileName)) : null;
        subckts = currentSubckt ? currentSubckt.models : [];
        subcktBoxClassName = 'spice-subckt-model-single-select';
      }
      if (subcktBoxClassName || isPageDisplay || !item || !item.libraryId || pkgType === EBD || modelType === N_PORT) {
        selectWidthClassName = 'spice-subckt-model-single-select-width';
      }

      let fileName = item.fileName ? item.fileName : undefined;
      if (item.folder && fileName) {
        fileName = `${item.folder}::${fileName}`;
      }
      return (
        <div
          className={`spice-model-single-select ${subcktBoxClassName} ${selectWidthClassName}`}
          key={index}>
          {/* spice file */}
          {this.getSelectComponent({
            type: index === 0 ? title : "",
            placeholder,
            value: fileName || '',
            selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
            list: modelFiles || [],
            modelKey: item.modelKey,
            fileId: item.libraryId ? item.libraryId : item.fileName,
            model: item,
            fileType,
            width,
            isPageDisplay,
            notUseMultipleFiles,
            modelSelectWidth: subcktBoxClassName ? "calc(50% + 45px)" : null,
            isShowCircleTag: selectWidthClassName ? false : true,
            tree: product !== SIERRA ? false : true,
            fileIndex: index,
            title: index === 0 ? _title : "",
            disabled,
            className: `pkg-model-select-exist ${otherClassName}`,
            allowClear
          })}
          {subcktBoxClassName && this.getSelectComponent({
            type: "Subckt",
            placeholder: 'Subckt',
            value: item.subckt ? item.subckt : '',
            selectHandler: this.selectSpiceSubckt,
            list: subckts,
            modelKey: item.modelKey,
            fileType,
            fileIndex: index,
            fileId: item.libraryId ? item.libraryId : item.subckt,
            model: item,
            modelSelectWidth: subcktBoxClassName ? "calc(50% - 55px)" : null,
            isShowCircleTag: selectWidthClassName ? true : false,
            disabled,
            className: `pkg-model-select-exist ${otherClassName}`,
            allowClear
          })}
          <CloseOutlined
            title={'Delete model'}
            className='pkg-model-delete-file-icon'
            onClick={(e) => { this.deleteFileSelect(index === 0 ? title : "", item.modelKey, fileType, true, item.libraryId) }} />
        </div>
      );
    }) : <div className='spice-model-single-select'>
      {this.getSelectComponent({
        type: modelTitle,
        placeholder,
        value: '',
        selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
        list: modelFiles || [],
        fileId: '',
        modelKey: "1",
        className: `pkg-model-select-width ${otherClassName}`,
        fileType,
        width,
        isPageDisplay,
        notUseMultipleFiles,
        tree: product !== SIERRA ? false : true,
        fileIndex: 0,
        title: _title,
        disabled,
        allowClear
      })}</div>;
  }

  getSelectForComponents({ _value, placeholder, value, selectHandler, modelKey, type, list, fileType, model, tree, fileIndex, disabled, allowClear = true }) {
    const { notFoundContent } = this.props;
    const allowClearInfo = allowClear ? {
      clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type, modelKey, fileType, false, _value.key) }} />
    } : false;

    return tree ? <TreeSelect
      showSearch
      labelInValue
      placeholder={placeholder}
      value={_value}
      fileIndex={fileIndex}
      onSelectItem={(file) => disabled ? null : selectHandler(file, modelKey, fileType)}
      className={`spice-netlist-model-selection ${type}_select`}
      popupClassName='spice-netlist-model-dropdown'
      popupMatchSelectWidth={false}
      allowClear={{
        clearIcon: <CloseCircleFilled
          onClick={(e) => { this.deleteFileSelect(type, modelKey, fileType, false, _value.key) }} />
      }}
      getPopupContainer={() => document.getElementById('root')}
      fileList={list}
      showFileFolder={true}
      disabled={disabled}
      selected={_value.key}
    /> : <Select
      labelInValue
      placeholder={placeholder}
      value={_value}
      onChange={value => value && selectHandler({ label: value.label, key: value.value }, modelKey, fileType)}
      className={`pkg-model-selection ${type}_select`}
      popupClassName='spice-netlist-model-dropdown'
      popupMatchSelectWidth={false}
      allowClear={allowClearInfo}
      disabled={disabled}
      getPopupContainer={() => document.getElementById('root')}
      notFoundContent={notFoundContent || "Not Found"}
      size='small'
    >
      {list.map((item, index) => (
        item.type === 'folder' ? <OptGroup key={index} label={item.fileName || item.name}>
          {item.children.map(it => <Option
            className={value === `${item.name}::${it.name}` ? "ant-select-dropdown-menu-item-selected" : ''}
            key={`${it.name}`}
            value={`${item.id}::${it.name}`}
            title={it.name}
          >{it.name}</Option>)}
        </OptGroup> :
          <Option
            key={item.name}
            value={item.id ? item.id : item.name}
            title={item.name}
          >{item.name}</Option>
      ))}
    </Select>;
  }

  getSelectComponent = ({ type, className, width, placeholder, value, selectHandler, list, modelKey, fileId, model, fileType,
    isPageDisplay, notUseMultipleFiles, modelSelectWidth, isShowCircleTag = true, tree, fileIndex, title, disabled, allowClear }) => {
    let _value = { key: fileId, label: value };
    const findList = list.find(item => (item.id === fileId) || (item.type === "folder" && !!item.children.find(it => it.id === fileId)));
    const disabledClassName = !findList && fileId && type !== 'Subckt' ? isPageDisplay ? "pkg-model-disable-select" : "pkg-model-disable-select pkg-model-disable-panel-select" : "";
    const selectWidth = !isShowCircleTag ? width + 10 : width;
    return (
      <div className={`pkg-model-select ${className} ${disabledClassName}`} style={{ width: modelSelectWidth }}>
        {type.match(/(File)/ig) ?
          <div className='spice-model-file-add-box' style={{ width, minWidth: width }}>
            <span>{title || type}</span>
            {(!notUseMultipleFiles && this.props.modelType !== N_PORT && !disabled) &&
              <PlusCircleOutlined
                title={'Add new file'}
                className='spice-model-add-file-icon'
                onClick={(e) => { this.addNewFileSelect(e, fileType) }} />
            }
          </div>
          : <span style={{ width, minWidth: width }}>{type === 'Subckt' ? "" : type}</span>}
        <div className="pkg-model-selection-div" style={{ width: selectWidth ? `calc(100% - ${selectWidth}px` : null }}>
          {this.getSelectForComponents({ _value, placeholder, value, selectHandler, modelKey, type, list, fileType, model, tree, fileIndex, disabled, allowClear })}
          {disabledClassName && <Tooltip
            title={<Fragment>
              {`Files ${_value.label} has been deleted, the model is invalid.`}
            </Fragment>}
            overlayClassName='aurora-tooltip'
          ><QuestionCircleOutlined
              className='aurora-file-check-icon'
              onClick={(e) => { e && e.stopPropagation() }} />
          </Tooltip>}
        </div>
        {/* model circle tag */}
        {isShowCircleTag && !isPageDisplay && model && model.libraryId && model.children && model.children.length ? <CircleTag text={model.children.length} /> : null}
        {/* {model && model.libraryId && model.children && model.children.length && model.circleNum && model.circleNum.length ?
          model.circleNum.map((item, index) => {
            // return item === '0' ? <div className='circle-blank-box'></div> : <CircleTag text={item} key={index} />
            return item === '0' ? null : <CircleTag text={item} key={index} />
          })
          : null} */}
      </div >
    );
  }

  signalClick = ({ pinInfo, fileType, fileId, selectAllSignals }) => {
    // Trigger renaming does not trigger popups
    const { inputVisible: { key } } = this.state
    // after rename
    if (key === AfterRename) {
      this.changeInputVisible()
      return
    }
    // renaming
    if (key !== '') {
      return
    }
    // The selected data does not have a fileType and may be deleted
    const { pinList } = this.props;

    const findPin = fileId ? pinList.find(item =>
      item.pinLeft.find(it => it.pinLibraryId === fileId)
      || item.pinRight.find(it => it.pinLibraryId === fileId)) : null;

    const _pinInfo = pinInfo ? pinInfo : findPin || (pinList[0] ? pinList[0] : null);

    this.setState({
      modelPinInfo: _pinInfo ? JSON.parse(JSON.stringify(_pinInfo)) : null,
      selectAllSignals: !fileId ? selectAllSignals : false
    })
  }

  savePinModels = ({ pinList, pairs, files, termination }, isWrong = false) => {
    if (!isWrong) {
      this.props._saveModel({
        pinList,
        pairs,
        files,
        termination
      });
    }
    this.setState({
      modelPinInfo: null,
      selectAllSignals: false
    })
    // make sure the next model appears
    this.changeInputVisible()
  }

  getLeftPinDisplay = ({ item, editType, editNode, placeholder, leftPinWidth, leftPlacement, files, editComp, modelType, product }) => {
    const { inputVisible: { key, renameType } } = this.state
    return item.pinLeft ? item.pinLeft.map(pinL => {
      const pinLeftFileInfo = pinL && pinL.pinLibraryId ? files.find(item => item.libraryId === pinL.pinLibraryId) : {};
      return (
        <div key={pinL.pin} className='spice-total-pin-box'>
          {/* pin L connect port */}
          <div className='spice-pin-die-value-box'>
            {!item.displayType && !editType && editNode && editNode === pinL.pin && (!pinL.component || (editComp === pinL.component)) ?
              <Popover
                overlayClassName='spice-node-select-Popover'
                content={this.nodeSelectRender({
                  pin: pinL.pin,
                  type: "pinLeft",
                  port: pinL.pinValue,
                  placeholder,
                  currentItem: pinL,
                  net: item.net,
                  component: pinL.component
                })}
                title=""
                trigger="click"
                open={true}
                placement={leftPlacement ? leftPlacement : "top"}
              ><Input
                  className='spice-pin-input'
                  placeholder={placeholder}
                  value={pinL.pinValue}
                  id={pinL.pin}
                  onClick={() => this.openSelectNode({
                    pin: pinL.pin,
                    value: pinL.pinValue,
                    type: "pinLeft",
                    currentItem: pinL,
                    pinInfo: item,
                    component: pinL.component
                  })}
                /></Popover> :
              pinL.pinValue ?
                <Tooltip overlayClassName="aurora-tooltip" title={<Fragment>{pinLeftFileInfo && pinLeftFileInfo.fileName && <div>FileName:{pinLeftFileInfo.fileName}</div>}<div>Port:{pinL.pinValue}</div></Fragment>}>
                  <div className='spice-pin-input' id={pinL.pin} title={pinL.pinValue}
                    onClick={() => this.openSelectNode({
                      pin: pinL.pin,
                      value: pinL.pinValue,
                      type: "pinLeft",
                      currentItem: pinL,
                      pinInfo: item,
                      component: pinL.component
                    })}>
                    <span>{pinL.pinValue}</span>
                    <CloseCircleFilled
                      className='spice-pin-node-delete-icon'
                      onClick={(e) => { this.deleteSelectNode(e, pinL, "pinLeft", item.net, null, null, pinL.component) }} />
                  </div>
                </Tooltip>
                : !item.displayType && <Input
                  className='spice-pin-input'
                  placeholder={placeholder}
                  value={pinL.pinValue}
                  id={pinL.pin}
                  onClick={() => this.openSelectNode({
                    pin: pinL.pin,
                    value: pinL.pinValue,
                    type: "pinLeft",
                    currentItem: pinL,
                    pinInfo: item,
                    component: pinL.component
                  })}
                />
            }
          </div>
          {/* pin_L */}
          <div className='spice-pin-die-box'
            style={{ width: leftPinWidth + 2 }}
            title={pinL.pinDisplay ? pinL.pinDisplay : pinL.pin}>
            {
              key === pinL.pin && renameType === pinL.component && modelType === PreLayout && product === ANDES_V2
                ?
                this.getNetOrPinInput(pinL, pinL.component)
                :
                <span className='spice-box-display' onClick={() => this.changeInputVisible({ key: pinL.pin, renameType: pinL.component, renameValue: pinL.pinDisplay })}>
                  {pinL.pinDisplay ? pinL.pinDisplay : pinL.pin}
                </span>
            }
            {/* {pinL.pinDisplay ? pinL.pinDisplay : pinL.pin} */}
          </div>
          {/* - */}
          <div className='spice-pin-line'></div>
          <div className='spice-pin-circle'></div>
        </div>
      );
    }) : null;
  }

  getRightPinDisplay = ({ item, editType, editNode, placeholder, modelType, rightPinWidth, rightPlacement, files, editComp, product, editTerminationNode }) => {
    const { inputVisible: { key, renameType } } = this.state
    return item.pinRight ? item.pinRight.map(pinR => {
      const pinRightFileInfo = pinR && pinR.pinLibraryId ? files.find(item => item.libraryId === pinR.pinLibraryId) : {};
      return (
        <div key={pinR.pin} className='spice-total-pin-box'>
          <div className='spice-pin-circle'></div>
          <div className='spice-pin-line'></div>
          {/* pin R */}
          {modelType === PCB ? <Fragment>
            <div className='spice-pin-line' style={{ width: rightPinWidth ? rightPinWidth + 16 : 96 }}></div>
          </Fragment>
            : (!item.displayType && !(product === ANDES_V2 && modelType === "DIE") ?
              <div
                className='spice-pin-box'
                style={{ width: rightPinWidth + 2 }}
                title={pinR.pinDisplay || modelType === PCB ? pinR.pinDisplay : pinR.pin}>
                {
                  key === pinR.pin && renameType === pinR.component && modelType === PreLayout && product === ANDES_V2
                    ?
                    this.getNetOrPinInput(pinR, pinR.component)
                    :
                    <span className='spice-box-display' onClick={() => this.changeInputVisible({ key: pinR.pin, renameType: pinR.component, renameValue: pinR.pinDisplay })}>
                      {pinR.pinDisplay || modelType === PCB ? pinR.pinDisplay : pinR.pin}
                    </span>
                }
                {/* {pinR.pinDisplay || modelType === PCB ? pinR.pinDisplay : pinR.pin} */}
              </div> : null)
          }
          {/* pin R connect port */}
          <div className='spice-pin-value-box'>
            {!editTerminationNode && !editType && editNode && editNode === pinR.pin && (!pinR.component || (editComp === pinR.component)) ? <Popover
              overlayClassName='spice-node-select-Popover'
              content={this.nodeSelectRender({
                pin: pinR.pin,
                type: "pinRight",
                port: pinR.pinValue,
                placeholder,
                currentItem: pinR,
                net: item.net,
                component: pinR.component
              })}
              title=""
              trigger="click"
              open={true}
              placement={rightPlacement ? rightPlacement : "top"}
            ><Input
                className='spice-pin-input'
                placeholder={placeholder}
                value={pinR.pinValue}
                id={pinR.pin}
                onClick={() => this.openSelectNode({
                  pin: pinR.pin,
                  value: pinR.pinValue,
                  type: "pinRight",
                  currentItem: pinR,
                  pinInfo: item,
                  component: pinR.component
                })}
              /></Popover>
              : pinR.pinValue ?
                <Tooltip overlayClassName="aurora-tooltip" onClick={(e) => this.stopPropagation(e)} title={<Fragment>{pinRightFileInfo && pinRightFileInfo.fileName && <div>FileName:{pinRightFileInfo.fileName}</div>}<div>Port:{pinR.pinValue}</div></Fragment>}>
                  <div className='spice-pin-input' id={pinR.pin} title={pinR.pinValue}
                    onClick={() => this.openSelectNode({
                      pin: pinR.pin,
                      value: pinR.pinValue,
                      type: "pinRight",
                      currentItem: pinR,
                      pinInfo: item,
                      component: pinR.component
                    })}>
                    <span>{pinR.pinValue}</span>
                    <CloseCircleFilled
                      className='spice-pin-node-delete-icon'
                      onClick={(e) => { this.deleteSelectNode(e, pinR, "pinRight", item.net, null, null, pinR.component) }} />
                  </div>
                </Tooltip>
                : <Input
                  className='spice-pin-input'
                  placeholder={placeholder}
                  id={pinR.pin}
                  value={pinR.pinValue}
                  onClick={() => this.openSelectNode({
                    pin: pinR.pin,
                    value: pinR.pinValue,
                    type: "pinRight",
                    currentItem: pinR,
                    pinInfo: item,
                    component: pinR.component
                  })}
                />}
          </div>
        </div >
      );
    }) : null;
  }

  getNewFiles = (files) => {
    let new_files = [], idList = [];
    for (let fileInfo of files) {
      if (fileInfo.libraryId && !idList.includes(fileInfo.libraryId)) {
        idList.push(fileInfo.libraryId)
        new_files.push({ ...fileInfo })
      }
    }
    return new_files
  }

  // board ---> package
  // board ---> connector --> cable
  // board ---> connector --> cable ---> connector ---> pcb ---> package
  // board ---> connector --> cable ---> connector ---> pcb ---> connector ---> cable ---> connector ---> pcb ---> package

  addNewModel = (type, iconType, moreModelIndex) => {
    const { pins, signals, modelInfoList, modelType, files, isPageDisplay, newModelFilesObj } = this.props;

    let _newModelFilesObj = { ...newModelFilesObj };
    let newFiles = [];
    let _modelInfoList = [...modelInfoList];

    if (type === modelType && !_newModelFilesObj[type]) {
      newFiles = [...files]
    } else if (_newModelFilesObj[type] && _newModelFilesObj[type].allFiles.length) {
      newFiles = [..._newModelFilesObj[type].allFiles]
    } else {
      newFiles = [];
    }

    newFiles = this.getNewFiles(newFiles)

    const modelFileKey = getDefaultName({
      nameList: _newModelFilesObj[type] ? Object.keys(_newModelFilesObj[type]).filter(item => item === 'allFiles') : [],
      defaultKey: type,
      key: ''
    })

    const modelPinList = getModelPinListByCompPins(pins, signals, type);
    // const _files = _modelFilesObj[type];
    const pairs = getModelPairsByCompPins(pins);
    const newModelInfo = {
      modelPinList,
      files: newFiles,
      pairs,
      type: "Touchstone / SPICE",
      modelType: type,
      modelFileKey
    }
    _modelInfoList.splice(moreModelIndex + 1, 0, newModelInfo)

    if (_newModelFilesObj[type] && _newModelFilesObj[type].allFiles) {
      _newModelFilesObj[type][modelFileKey] = [...newFiles];
      _newModelFilesObj[type].allFiles = [..._newModelFilesObj[type].allFiles, ...newFiles]
    } else {
      _newModelFilesObj[type] = {
        allFiles: [...newFiles],
        [modelFileKey]: [...newFiles]
      }
    }

    const isClosePanel = !isPageDisplay && _modelInfoList.length === 1 ? true : false;

    this.props._updateModelObj({
      modelInfoList: _modelInfoList,
      newModelFilesObj: _newModelFilesObj
    }, isClosePanel);
    setTimeout(() => {
      this.onVisibleChange(false)
    }, 20)
  }

  deleteModelByType = (type, index, modelKey) => {
    const { modelInfoList, newModelFilesObj, modelType } = this.props;
    const filterTypeInfo = modelInfoList.filter(item => item.modelType === type);
    let _modelInfoList = [...modelInfoList];
    let _newModelFilesObj = { ...newModelFilesObj }
    if (filterTypeInfo.length < 2) {
      //If the length is only 1, clear the model when deleting
      // delete _modelFilesObj[type];
      delete _newModelFilesObj[type]
    } else if (_newModelFilesObj[type]) {
      delete _newModelFilesObj[type][modelKey]
    }

    if (type === PCB && index + 1 < modelInfoList.length) {
      //If the PCB is deleted
      //1. If there is a connector Model/tableModel in the middle connection, then delete the subsequent packageModels as well
      //2. If the previous connection is a pcbModel, the packageModel connected later will not be deleted
      let nextModel = modelInfoList[index + 1];
      const nextModelIsPackage = nextModel.modelType === PACKAGE ? true : false;

      if (nextModelIsPackage && modelType === CONNECTOR) {
        delete _newModelFilesObj[PACKAGE];
        _modelInfoList.splice(index + 1, 1)
      } else if (nextModelIsPackage && modelType === PCB && index > 0) {
        const previousModel = modelInfoList[index - 1];
        if ([CONNECTOR, "Cable"].includes(previousModel.modelType)) {
          delete _newModelFilesObj[PACKAGE];
          _modelInfoList.splice(index + 1, 1)
        }
      }
    }

    _modelInfoList.splice(index, 1)
    const isOpenPanel = _modelInfoList.length ? false : true;

    this.props._updateModelObj({
      modelInfoList: _modelInfoList,
      newModelFilesObj: _newModelFilesObj
    }, false, isOpenPanel);
  }

  addNewModelList = (modelList, iconType, moreModelIndex) => {
    return modelList.map(item => ({ key: item, label: `${item} Model` }))
  }

  moreModelRef = (ref) => {
    this.moreModel = ref;
  }

  onVisibleChange = (visible) => {
    this.setState({
      visible
    })
  }

  onDropdownClick = () => {
    this.setState({
      visible: true
    })
  }

  _getAddIcon = (index, type, isBox, height) => {
    const { modelType, modelInfoList } = this.props;
    const modelList = [PCB, CONNECTOR].includes(modelType) ? getNewModelSelectList(index, modelInfoList, modelType) : [];
    const items = this.addNewModelList(modelList, type, index)
    if (!modelList.length) { return null }
    if (isBox) {
      return (
        <Fragment>
          <div className='add-model-box' onClick={this.onDropdownClick} style={{ height }}>
            <div>
              <Dropdown menu={{ items, onClick: ({ key }) => this.addNewModel(key, type, index) }} onOpenChange={this.onVisibleChange} trigger={['click']} placement={'bottomLeft'} open={this.state.visible} overlayClassName='spice-netlist-model-menu'>
                <PlusOutlined className="plus-icon" />
              </Dropdown>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Dropdown menu={{ items, onClick: ({ key }) => this.addNewModel(key, type, index) }} trigger={['click']} overlayClassName='spice-netlist-model-menu'>
            <PlusCircleOutlined
              title="Add Model"
              className={modelList.length ? "spice-netlist-model-title-pcb-add-icon" : "spice-netlist-model-title-pcb-add-icon spice-netlist-model-title-pcb-add-icon-disabled"} />
          </Dropdown>
        </Fragment>
      );
    }
  }

  updateAutomaticPanelVisible = (info) => {
    this.setState({
      automaticInfo: info
    })
  }

  automaticMatchPort = (fileInfo, type, save) => {
    const { automaticInfo } = this.state;

    if (fileInfo.libraryId && save) {
      this.props._automaticMatchPort(fileInfo, type, automaticInfo)
    }
    this.updateAutomaticPanelVisible(null)
  }

  getSignalWidth = (pinList) => {
    let signalNameList = pinList.map(item => item.signal).sort((a, b) => { return b.length - a.length });
    const width = signalNameList.length ? getTextWidth(signalNameList[0], 12) : null;
    return width;
  }

  isShowAddIcon = (modelType, modelInfoList, isPageDisplay) => {
    let addBoxDisplayInfo = {}, isModelTypeAddDisplay = false;
    //   if (modelType === PCB) {
    if ([PCB, CONNECTOR].includes(modelType) && isPageDisplay) {
      if (!modelInfoList.length || !modelInfoList.length) {
        addBoxDisplayInfo = { index: -1, type: modelType }
      } else {
        isModelTypeAddDisplay = true;
        const length = modelInfoList.length;
        if (modelInfoList[length - 1].modelType !== "Package") {
          addBoxDisplayInfo = { index: length - 1, type: "moreModel" }
        }

      }
    }
    return { addBoxDisplayInfo, isModelTypeAddDisplay }
  }

  getHeight = () => {
    let height = 120;
    let boxContent = document.getElementsByClassName('spice-pin-list-ul')[0];

    if (boxContent) {
      const boxContentRect = boxContent.getBoundingClientRect();
      height = boxContentRect.height - 39;
    }
    return height
  }

  titleContent = (modelType, className, modelList, leftPinWidth, rightPinWidth, modelInfoList, signalWidth, height, paddingRight, isModelTypeAddDisplay, addBoxDisplayInfo, componentName, type, designType) => {
    const { leftName, rightName } = getModelTitle(modelType, componentName, type, designType);
    return (
      <div className={`${className} spice-netlist-model-title`} style={{ paddingRight }}>
        <div className="spice-netlist-model-title-div" style={{ width: 124 + leftPinWidth, paddingLeft:/*  modelType === PreLayout ? 20 : */ 0 }}>{leftName}</div>
        {modelType === PreLayout && <div className='port-icon-box'>
          <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelType: modelType, modelFileKey: modelType })}></span>
        </div>}
        <div className="spice-netlist-model-title-div spice-netList-model-width-div" style={{ marginRight: modelType === PreLayout ? 0 : 142 + rightPinWidth, width: modelType === PreLayout ? 142 + rightPinWidth : null }}>
          {rightName}
          {modelType !== PreLayout && <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelType: modelType, modelFileKey: modelType })}></span>}
          {isModelTypeAddDisplay && this._getAddIcon(-1, 'PCB')}
        </div>
        {modelInfoList && modelInfoList.map((item, index) =>
          <div key={index} className="spice-netlist-model-title-div spice-netlist-model-title-new" style={{ width: signalWidth + 200 }}>
            {item.modelType}
            <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible(item)}></span>
            <CloseOutlined
              title="Delete Model"
              onClick={(e) => this.deleteModelByType(item.modelType, index, item.modelFileKey)}
              className="spice-netlist-model-title-pcb-delete-icon" />
            {index !== modelInfoList.length - 1 && this._getAddIcon(index, 'moreModel')}
          </div>
        )}
        {addBoxDisplayInfo && Object.keys(addBoxDisplayInfo).length ? this._getAddIcon(addBoxDisplayInfo.index, addBoxDisplayInfo.type, true, height) : null}
      </div>
    );
  }

  cleanPort = () => {
    // Clear port data
    this.props._clearPinNode({}, true)
  }

  getMultiRankSetting = () => {
    const { rank } = this.props;
    return <Fragment>
      <div className="spice-model-single-select">
        <div className="spice-netlist-model-select">
          <span>Multi Rank</span>
          <Switch
            checked={rank ? true : false}
            onChange={(checked, e) => this.props.switchRankSetting(checked, e)}
            size="small"
            className="aurora-switch-small"
          />
        </div>
        {rank && <div className="spice-multi-rank-setting">
          <span className="spice-multi-rank-span">Rank Number</span>
          <Input
            value={rank.number}
            onChange={(e) => this.props.changeRankNumber(e)}
            onBlur={(e) => this.props.checkRankNumber(e)}
          />
          <span className="spice-multi-rank-span">Active</span>
          <Select
            notFoundContent="No Data"
            getPopupContainer={trigger => trigger.parentNode}
            value={rank.active}
            onSelect={this.props.changeRankActive}
            style={{ height: 22 }}
          >
            {
              rank.number && Number(rank.number) ? [...Array(Number(rank.number))].map((item, index) => {
                return <Option key={String(index + 1)}>Rank {index + 1}</Option>
              }) : null
            }
          </Select>
        </div>}
      </div>
    </Fragment>
  }

  getSelectedFileRender = (disabled) => {
    const { product, modelType,
      newModelFilesObj, interfaceType,
      record, isDisplayModelTitle } = this.props;

    const modelFileKey = Object.keys(newModelFilesObj).length ? Object.keys(newModelFilesObj).filter(item => item !== modelType) : [];
    const displayModelTitle = product === ANDES_V2 && isDisplayModelTitle
    return <Fragment>
      {this.getSelectList(displayModelTitle, null, null, disabled)}
      {product === ROCKY && interfaceType !== CARD && record.type === MEMORY && modelType === 'Package' && this.getMultiRankSetting()}
      {modelFileKey.length ? modelFileKey.map(item =>
        <Fragment key={item}>{this.getSelectList(displayModelTitle, newModelFilesObj[item].allFiles, item, disabled)}</Fragment>
      ) : null}
    </Fragment>
  }

  saveTermination = (pinInfo, net) => {
    const { editInputInfo } = this.state;
    const { pinList, termination } = this.props;
    let _pinList = JSON.parse(JSON.stringify(pinList || []));
    let _terminationPairs = JSON.parse(JSON.stringify(termination.pairs || []));

    const { pinListIndex, pinListIndexChild, pairIndex } = getPortIndexList({ pinList: _pinList, pairs: _terminationPairs, pinInfo, net, type: 'pinTermination' })

    if (pinListIndex > -1 && pinListIndexChild > -1) {
      _pinList[pinListIndex].pinTermination[pinListIndexChild] = {
        ..._pinList[pinListIndex].pinTermination[pinListIndexChild],
        pinRes: editInputInfo.value
      }
    }
    if (pairIndex !== -1) {
      _terminationPairs[pairIndex] = {
        ..._terminationPairs[pairIndex],
        r: `${editInputInfo.value} ohm`
      }
    }
    this.setState({
      editInputInfo: {
        pin: "",
        type: "",
        value: ""
      }
    }, () => {
      this.props._changeTermination({
        pinList: _pinList,
        pairs: _terminationPairs
      });
    })
  }

  terminationRender = ({ item, editType, editNode, editTerminationNode, rightPlacement, editComp, height }) => {
    const { circleData, lineData, polylineData, polygonData } = getCoordinateInfo(15, (height / 2) + 1);
    const { editInputInfo } = this.state;
    const { termination } = this.props;
    const { type, files } = termination || {};
    return <>
      {
        item.pinTermination ? item.pinTermination.map((pinT, index) => {
          const pinRightFileInfo = pinT && pinT.pinLibraryId ? files.find(item => item.libraryId === pinT.pinLibraryId) : {};
          return <div className="spice-netList-model-termination-box" style={{ height }} key={`pin-termination-${index}`}>
            {
              type === 'spice' ? <div className='spice-total-pin-box'>
                <div className='spice-pin-line'></div>
                <div className='spice-pin-value-box'>
                  {editTerminationNode && !editType && editNode && editNode === pinT.pin && (!pinT.component || (editComp === pinT.component)) ? <Popover
                    overlayClassName='spice-node-select-Popover'
                    content={this.nodeSelectRender({
                      pin: pinT.pin,
                      type: "pinTermination",
                      port: pinT.pinValue,
                      placeholder: "Node",
                      currentItem: pinT,
                      net: item.net,
                      component: pinT.component
                    })}
                    title=""
                    trigger="click"
                    open={true}
                    placement={rightPlacement ? rightPlacement : "top"}
                  ><Input
                      className='spice-pin-input'
                      placeholder="Node"
                      value={pinT.pinValue}
                      id={pinT.pin}
                      onClick={() => this.openSelectNode({
                        pin: pinT.pin,
                        value: pinT.pinValue,
                        type: "pinTermination",
                        currentItem: pinT,
                        pinInfo: item,
                        component: pinT.component,
                        isTermination: true
                      })}
                    /></Popover>
                    : pinT.pinValue ?
                      <Tooltip overlayClassName="aurora-tooltip" onClick={(e) => this.stopPropagation(e)} title={<Fragment>{pinRightFileInfo && pinRightFileInfo.fileName && <div>FileName:{pinRightFileInfo.fileName}</div>}<div>Node:{pinT.pinValue}</div></Fragment>}>
                        <div className='spice-pin-input' id={pinT.pin} title={pinT.pinValue}
                          onClick={() => this.openSelectNode({
                            pin: pinT.pin,
                            value: pinT.pinValue,
                            type: "pinTermination",
                            currentItem: pinT,
                            pinInfo: item,
                            component: pinT.component,
                            isTermination: true
                          })}>
                          <span>{pinT.pinValue}</span>
                          <CloseCircleFilled
                            className='spice-pin-node-delete-icon'
                            onClick={(e) => { this.deleteSelectNode(e, pinT, "pinTermination", item.net, null, null, pinT.component) }} />
                        </div>
                      </Tooltip>
                      : <Input
                        className='spice-pin-input'
                        placeholder="Node"
                        id={pinT.pin}
                        value={pinT.pinValue}
                        onClick={() => this.openSelectNode({
                          pin: pinT.pin,
                          value: pinT.pinValue,
                          type: "pinTermination",
                          currentItem: pinT,
                          pinInfo: item,
                          component: pinT.component,
                          isTermination: true
                        })}
                      />}
                </div>
              </div> : <>
                <svg width="170" height={height}>
                  <g>
                    <circle {...circleData} />
                    {lineData.map((line, index) => <line {...line} key={`line-${index}`} />)}
                    <polyline {...polylineData} />
                    <polygon {...polygonData} />
                  </g>
                </svg>

                <div className="model-termination-input-box">
                  <Input
                    suffix="Ω"
                    size="small"
                    value={editInputInfo.pin === pinT.pin && editInputInfo.type === 'pinTermination' ? editInputInfo.value : pinT.pinRes}
                    onFocus={() => this.setState({ editInputInfo: { pin: pinT.pin, type: "pinTermination", value: pinT.pinRes } })}
                    onChange={(e) => this.setState({ editInputInfo: { ...this.state.editInputInfo, value: e.target.value } })}
                    onBlur={() => this.saveTermination(pinT, item.net)}
                  />
                </div>
              </>
            }
          </div>
        }) : null
      }
    </>
  }

  changeTopology = (value) => {
    // When parallel is currently selected, clear the information for the node selected on the right
    const { pinList, pairs } = this.props;
    let _pinList = JSON.parse(JSON.stringify(pinList || []));
    let _pairs = JSON.parse(JSON.stringify(pairs || []));
    if (value === 'parallel') {
      const rightPins = new Set();
      _pinList.forEach(pinItem => {
        pinItem.pinRight && pinItem.pinRight.forEach(pinInfo => {
          pinInfo.pinValue = "";
          pinInfo.pinLibraryId = "";
          pinInfo.pinSubckt = "";
          pinInfo.pinModelKey = "";
          rightPins.add(pinInfo.pin);
        })
      })

      _pairs.forEach(pairItem => {
        if (rightPins.has(pairItem.pin)) {
          pairItem.node = "";
          pairItem.libraryId = "";
          pairItem.subckt = "";
          pairItem.modelKey = "";
        }
      })
      this.props._selectNodes({
        pinList: _pinList,
        pairs: _pairs
      });
    }
    this.props._changeTermination({ topology: value });
  }
  terminationSelectGroup = () => {
    const { termination = {}, topology } = this.props;
    const { type, files = [] } = termination || {};
    return <div>
      <div className="model-row-item">
        <label>Die Model Topology</label>
        <Select
          size='small'
          className='model-row-item-right'
          options={[{ label: "Series", value: "series" }, { label: "Parallel", value: "parallel" }]}
          value={topology}
          onChange={this.changeTopology}
        />
      </div>
      {topology !== 'parallel' ? <>
        <div className="model-row-item">
          <label className='model-title'>Termination</label>
        </div>
        <div>
          <div className="model-row-item">
            <label>Model Type</label>
            <Select
              size='small'
              className='model-row-item-right'
              options={[{ label: "Value", value: 'value' }, { label: "SPICE", value: 'spice' }]}
              value={type}
              onChange={(value) => this.props._changeTermination({ type: value })}
            />
          </div>
          {type === 'spice' && this.getSelectList(false, files, 'termination')}
        </div>
      </> : null}
    </div>
  }

  render() {
    const { pinList, pins, errorMsg, displayType, modelPinType, product, fileList,
      pairs, portsObj, files, modelType, maxWidth, maxHeight,
      libraryListObj, modelInfoList, newModelFilesObj, signals, notAddMultiModel, isPageDisplay, componentName,
      subcktList, pkgType, rank, hideModelList, allowManual, type, pinListClassName, designType, topology, termination = {} } = this.props;
    const { editNode, leftPlacement, rightPlacement, modelPinInfo, selectAllSignals, editType, editMoreAssignIndex, automaticInfo, editComp, inputVisible: { key, renameType }, editTerminationNode } = this.state;
    const placeholder = product === ANDES_V2 ? "Port" : 'Node';
    const allPinL = pinList.filter(item => !!item.pinL);
    const allPinR = pinList.filter(item => !!item.pinR);
    let className = "spice-pin-connect-node-item";
    if (!allPinL || allPinL.length === 0 || !allPinR || allPinR.length === 0 || modelPinType === 'Stimulus') {
      className = "spice-pin-connect-node-item spice-pin-connect-node-item-center";
    }
    const { leftPinWidth, rightPinWidth } = getPkgPinListWidth(pinList);
    let _pinList = modelInfoList && modelInfoList.length ? getModelPinList({ pinList, modelInfoList }) : pinList;
    const modelList = [PCB, CONNECTOR].includes(modelType) ? getNewModelSelectList(-1, modelInfoList, modelType) : [];
    const signalWidth = _pinList && _pinList.length && _pinList[0].modelPinList && _pinList[0].modelPinList.length ? _pinList[0].modelPinList[0].width : 30;
    const { addBoxDisplayInfo, isModelTypeAddDisplay } = this.isShowAddIcon(modelType, modelInfoList, isPageDisplay)
    const height = this.getHeight()
    const paddingRight = 10;

    const signalSelectDisableClassName = pkgType === EBD || modelType === N_PORT ? 'spice-box-disable' : '';
    const titleStyle = leftPinWidth > rightPinWidth ? { paddingLeft: leftPinWidth - rightPinWidth } : { paddingRight: rightPinWidth - leftPinWidth, paddingLeft: 0 }
    return (
      <Fragment>
        <div className='spice-netlist-model-content'>
          <div className='spice-netlist-model-select-content' id="spice-netlist-model-select-content-id">
            {hideModelList ?
              <Collapse
                defaultActiveKey={["model_files"]}
                bordered={false}
                className="spice-netlist-model-collapse"
                items={[{
                  key: "model_files",
                  label: <span>Model Files</span>,
                  styles: {
                    background: '#f5f5f5',
                    borderRadius: 4,
                    border: 0,
                    overflow: 'hidden'
                  },
                  children: this.getSelectedFileRender(true)
                }]}
              />
              :
              this.getSelectedFileRender(false)
            }
            {product === ANDES_V2 && modelType === "DIE" ? <>{this.terminationSelectGroup()}</> : null}
            {errorMsg ? <span className="spice-model-file-error-msg">{errorMsg}</span> : null}
          </div>
          {displayType === 'model' && <div className='spice-connect-content'>
            <ul className='spice-pin-list-ul'>
              {/* title content */}
              {['Package', 'socket', "N-Port"].includes(modelType) && <div className="spice-pin-connect-node-title" style={{ ...titleStyle }}>
                {pkgType === TOUCHSTONE_SPICE && <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelType: modelType, modelFileKey: modelType })}></span>}
                <DeleteOutlined
                  title="Clean Ports"
                  onClick={() => this.cleanPort({ modelType: modelType, modelFileKey: modelType })} />
              </div>}
              {product === ANDES_V2 && modelType === "DIE" ? <div className={`${className} ${pinListClassName} spice-netlist-model-title spice-netList-model-width-div-die`} style={{ height: termination.type === 'value' ? 52 : 32, paddingRight }}>
                <div className="spice-netlist-model-title-div" style={{ width: 124 + leftPinWidth, paddingLeft: 0 }}>DIE</div>
                <div className='port-icon-box'>
                  {pkgType === TOUCHSTONE_SPICE && <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelType: modelType, modelFileKey: modelType })}></span>}
                </div>
                {topology !== 'parallel' && <div className="spice-netlist-model-title-div spice-netList-model-width-div spice-netList-model-width-div-termination" style={{ width: termination.type === 'value' ? 245 : 220 }}>
                  <span className='andes-die-model-termination'>Termination</span>
                </div>}
                <DeleteOutlined
                  title="Clean Ports"
                  onClick={() => this.cleanPort({ modelType: modelType, modelFileKey: modelType })} />
              </div> : null}
              {([PCB, CONNECTOR, PreLayout].includes(modelType) && !notAddMultiModel) && this.titleContent(modelType, className, modelList, leftPinWidth, rightPinWidth, modelInfoList, signalWidth, height, paddingRight, isModelTypeAddDisplay, addBoxDisplayInfo, componentName, type, designType)}
              {_pinList.map((item, index) => {
                const pinHeight = getPinHeight(item);
                return <div className={`${className} ${pinListClassName}`} key={index} style={{ height: pinHeight, paddingRight }}>
                  <div className='spice-pin-list-li-left'>
                    {this.getLeftPinDisplay({ item, editType, editNode, placeholder, leftPlacement, leftPinWidth, files, editComp, modelType, product })}
                  </div>
                  {/* net box */}
                  <div
                    onClick={!signalSelectDisableClassName ? () => this.signalClick({ pinInfo: item }) : null}
                    className={index === 0 ? `spice-box spice-box-top ${signalSelectDisableClassName}` : (index === (pinList.length - 1) ? `spice-box spice-box-bottom ${signalSelectDisableClassName}` : `spice-box ${signalSelectDisableClassName}`)}
                    title={`${item.signalDisplay}::${item.netDisplay}`}
                  >
                    <span className='spice-box-signal-text'>{item.signalDisplay}</span>
                    {
                      key === item.net && renameType === 'net' && modelType === PreLayout && product === ANDES_V2
                        ?
                        this.getNetOrPinInput(item, 'net')
                        :
                        <span className='spice-box-net-text'>
                          <span onClick={(e) => { modelType === PreLayout && product === ANDES_V2 && e.stopPropagation(); this.changeInputVisible({ key: item.net, renameType: 'net', renameValue: item.netDisplay }) }}>
                            {item.netDisplay}
                          </span>
                        </span>
                    }
                  </div>
                  {/* - */}
                  <div>
                    {product === ANDES_V2 && modelType === "DIE" && topology === 'parallel' ? null
                      : this.getRightPinDisplay({ item, editType, editNode, placeholder, rightPlacement, modelType, rightPinWidth, files, editComp, product, editTerminationNode })}
                  </div>
                  {product === ANDES_V2 && modelType === "DIE" && topology !== 'parallel'
                    ? this.terminationRender({ item, editType, editNode, editTerminationNode, modelType, rightPinWidth, rightPlacement, editComp, product, height: pinHeight }) : null}
                  {modelInfoList && modelInfoList.length ?
                    <Fragment>
                      <div className='spice-pin-half-line'></div>
                      <MoreModelAssign
                        onRef={this.moreModelRef}
                        className={index === 0 ? "spice-box-top" : ((index === (pinList.length - 1) ? 'spice-box-bottom' : ""))}
                        modelPinList={item.modelPinList}
                        libraryListObj={libraryListObj}
                        pins={pins}
                        portsObj={portsObj}
                        maxWidth={maxWidth}
                        maxHeight={maxHeight}
                        editType={editType}
                        editNode={editNode}
                        _saveModel={this.props.saveModelByType}
                        getFileParse={this.props.getFileParse}
                        nodeSelectRender={this.nodeSelectRender}
                        openSelectNode={this.openSelectNode}
                        deleteSelectNode={this.deleteSelectNode}
                        modelInfoList={modelInfoList}
                        editMoreAssignIndex={editMoreAssignIndex}
                        newModelFilesObj={newModelFilesObj}
                        product={product}
                        signals={signals}
                        subcktList={subcktList}
                        updatePortsObj={this.props.updatePortsObj}
                        serdesType={type}
                      />
                    </Fragment>
                    : null}
                </div>
              })}
            </ul>
          </div>}
        </div>
        {modelPinInfo ? <ModelSelect
          pins={pins}
          modelPinInfo={modelPinInfo}
          selectAllSignals={selectAllSignals}
          pinList={pinList}
          fileList={fileList}
          files={files}
          pairs={pairs}
          portsObj={portsObj}
          modelType={modelType}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          savePinModels={this.savePinModels}
          getFileParse={this.props.getFileParse}
          isMultiInfo={false}
          modelFileKey={modelType}
          libraryListObj={libraryListObj}
          isMatchPort={true}
          signals={signals}
          product={product}
          determineComp={modelType === PreLayout ? true : false}
          subcktList={subcktList}
          updatePortsObj={this.props.updatePortsObj}
          determinePinSame={modelType === PACKAGE && product === ROCKY ? true : false}
          rank={rank}
          serdesType={type}
          componentName={componentName}
          designType={designType}
          topology={topology}
          termination={termination}
          terminationList={libraryListObj.terminationList}
        /> : null
        }
        {
          automaticInfo && Object.keys(automaticInfo).length && automaticInfo.modelType ? <AutomaticPanel
            automaticInfo={automaticInfo}
            closePanel={this.automaticMatchPort}
            libraryListObj={libraryListObj}
            getFileParse={this.props.getFileParse}
            newModelFilesObj={newModelFilesObj}
            modelType={modelType}
            files={files}
            subcktList={subcktList}
            allowManual={allowManual}
          /> : null
        }
      </Fragment >
    );
  }
}

export default NetListModel;