import React, { PureComponent } from "react";
import { Select, TreeSelect } from "antd";
import { DESIGN_FAILED } from '@/constants/designCategory';
import '@/publicCss/style.css';
import '@/publicCss/debug.css';
import { HIMALAYAS } from "../../constants/pageType";

const Option = Select.Option;
const OptGroup = Select.OptGroup;
const { SHOW_PARENT } = TreeSelect;

class DebugDownloadMenu extends PureComponent {

  selectClick = (e) => {
    e && e.stopPropagation();
  }

  getSelect = ({
    disabled,
    selectItem,
    selectFn,
    className,
    options = [],
    product
  }) => {
    return <div style={{ width: '100%' }} onClick={(e) => this.selectClick(e)}><Select
      disabled={disabled}
      value={selectItem ? selectItem.name : ""}
      onChange={(value) => selectFn(value)}
      className={`aurora-select aurora-debug-download-select ${className}`}
      popupClassName='aurora-debug-select-down-drop'
      popupMatchSelectWidth={false}
      getPopupContainer={() => document.getElementById('root')}>
      {options.map(item => (
        item.children && item.children.length && product === HIMALAYAS
          ? <OptGroup key={item.name} label={item.name}>
            {item.children.map(it => (<Option
              key={it.name}
              value={it.name}
              title={it.name}
            >{it.name}</Option>))}</OptGroup>
          : <Option
            className={item.category === DESIGN_FAILED && 'aurora-debug-select-failed'}
            key={item.name}
            value={item.name}
            title={item.name}
          >{item.name}</Option>
      ))}
    </Select>
    </div>
  }

  getMultipleSelect = ({
    disabled,
    selectItem = {},
    selectFn,
    className,
    options = [],
    selectAssemblyList = [],
    multiSelectedKeys = []
  }) => {
    const selectAssemblyKeys = Object.keys(selectAssemblyList);
    return <div style={{ width: '100%' }} onClick={(e) => this.selectClick(e)} className='aurora-multiple-select-box'>
      {selectAssemblyKeys && selectAssemblyKeys.map(key => {
        return multiSelectedKeys.includes(key) ?
          <TreeSelect
            key={key}
            treeData={selectAssemblyList[key] || []}
            value={selectItem[key] || []}
            onChange={(value) => selectFn(value, key)}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
            placeholder='Please select'
            getPopupContainer={() => document.getElementById('root')}
            className='aurora-debug-download-multi-select aurora-select'
            popupClassName='aurora-debug-select-down-drop'
          />
          : <Select
            key={key}
            disabled={disabled}
            value={selectItem && selectItem[key] ? selectItem[key] : ""}
            onChange={(value) => selectFn(value, key)}
            className={`aurora-select aurora-debug-download-select ${className}`}
            popupClassName='aurora-debug-select-down-drop'
            popupMatchSelectWidth={false}
            getPopupContainer={() => document.getElementById('root')}>
            {selectAssemblyList && selectAssemblyList[key] && selectAssemblyList[key].length && selectAssemblyList[key].map(item => (
              typeof (item) === "string" ? <Option
                key={item}
                value={item}
                title={item}
              >{item}</Option>
                : <Option
                  className={item.category === DESIGN_FAILED && 'aurora-debug-select-failed'}
                  key={item.name}
                  value={item.name}
                  title={item.name}
                >{item.name}</Option>
            ))}
          </Select>
      })}
    </div>
  }

  getSelectComponents = ({
    info,
    menuType,
    changeSelectInfo,
    product
  }) => {
    if (info.notSelected) {
      return null;
    }

    if (info.isMultiple) {
      return this.getMultipleSelect({
        selectItem: info.selectInfo,
        selectFn: (value, type) => changeSelectInfo(value, menuType, type),
        className: "aurora-debug-simulation-select",
        options: info.selectList || [],
        selectAssemblyList: info.selectAssemblyList || [],
        multiSelectedKeys: info.multiSelectedKeys || []
      })
    }

    return this.getSelect({
      selectItem: info.selectInfo,
      selectFn: (value) => changeSelectInfo(value, menuType),
      className: "aurora-debug-simulation-select",
      product,
      options: info.treeList && info.treeList.length ? info.treeList : (info.selectList || []),
    });
  }

  render() {
    const {
      info,
      title,
      menuType,
      changeSelectInfo,
      product
    } = this.props;
    return <div className="aurora-debug-download-menu-box">
      <div className='debug-menu-item-title'>{title}</div>
      <div className="aurora-debug-download-menu-item">
        {this.getSelectComponents({
          info,
          menuType,
          changeSelectInfo,
          product
        })}
      </div>
    </div >
  }
}

export default DebugDownloadMenu;