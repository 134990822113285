import React, { Component, createRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './index.css';


export default class ScrollableBar extends Component {
  constructor(props) {
    super(props)
    this.offset = 0;
    this.state = {
      next: false,
      prev: false,
    }
    this.navRef = createRef();
    this.navWrapRef = createRef();
    this.containerRef = createRef();
  }

  componentDidMount() {
    this.componentDidUpdate();
    // this.debouncedResize = debounce(() => {
    //   this.setNextPrev();
    //   this.scrollToActiveTab();
    // }, 200);
    window.addEventListener('resize', this.resize, true);
  }

  resize = () => {
    this.setNextPrev();
  }

  componentDidUpdate(prevProps) {
    this.setNextPrev();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize, true);
  }

  setNextPrev() {
    const navNode = this.navRef;
    const navNodeWidth = this.getScrollWidth(navNode);
    const containerWidth = this.getOffsetWidth(this.containerRef);
    const navWrapNodeWidth = this.getOffsetWidth(this.navWrapRef);
    let { offset } = this;
    const minOffset = containerWidth - navNodeWidth;
    let { next, prev } = this.state;
    if (minOffset >= 0) {
      next = false;
      this.setOffset(0, false);
      offset = 0;
    } else if (minOffset < offset) {
      next = true;
    } else {
      next = false;
      // Fix https://github.com/ant-design/ant-design/issues/8861
      // Test with container offset which is stable
      // and set the offset of the nav wrap node
      const realOffset = navWrapNodeWidth - navNodeWidth;
      this.setOffset(realOffset, false);
      offset = realOffset;
    }

    if (offset < 0) {
      prev = true;
    } else {
      prev = false;
    }

    this.setNext(next);
    this.setPrev(prev);
    return {
      next,
      prev,
    };
  }

  // scrollToActiveTab = (e) => {
  //   const activeTab = this.props.getRef('activeTab');
  //   const navWrap = this.props.getRef('navWrap');
  //   if (e && e.target !== e.currentTarget || !activeTab) {
  //     return;
  //   }

  //   // when not scrollable or enter scrollable first time, don't emit scrolling
  //   const needToSroll = this.isNextPrevShown() && this.lastNextPrevShown;
  //   this.lastNextPrevShown = this.isNextPrevShown();
  //   if (!needToSroll) {
  //     return;
  //   }

  //   const activeTabWH = this.getScrollWH(activeTab);
  //   const navWrapNodeWH = this.getOffsetWH(navWrap);
  //   let { offset } = this;
  //   const wrapOffset = this.getOffsetLT(navWrap);
  //   const activeTabOffset = this.getOffsetLT(activeTab);
  //   if (wrapOffset > activeTabOffset) {
  //     offset += (wrapOffset - activeTabOffset);
  //     this.setOffset(offset);
  //   } else if ((wrapOffset + navWrapNodeWH) < (activeTabOffset + activeTabWH)) {
  //     offset -= (activeTabOffset + activeTabWH) - (wrapOffset + navWrapNodeWH);
  //     this.setOffset(offset);
  //   }
  // }


  getOffsetWidth(ref) {
    return ref.current['offsetWidth'];
  }

  getScrollWidth(ref) {
    return ref.current['scrollWidth'];
  }

  getOffsetLeft(ref) {
    return ref.current.getBoundingClientRect()['left'];
  }

  setOffset(offset, checkNextPrev = true) {
    const target = Math.min(0, offset);
    if (this.offset !== target) {
      this.offset = target;
      this.navRef.current.style.transform = `translate3d(${target}px,0,0)`;
      if (checkNextPrev) {
        this.setNextPrev();
      }
    }
  }

  setPrev(v) {
    if (this.state.prev !== v) {
      this.setState({
        prev: v,
      });
    }
  }

  setNext(v) {
    if (this.state.next !== v) {
      this.setState({
        next: v,
      });
    }
  }

  prev = (e) => {
    const navWrapNodeWidth = this.getOffsetWidth(this.navWrapRef);
    const { offset } = this;
    this.setOffset(offset + navWrapNodeWidth);
  }

  next = (e) => {
    const navWrapNodeWidth = this.getOffsetWidth(this.navWrapRef);
    const { offset } = this;
    this.setOffset(offset - navWrapNodeWidth);
  }

  // prevTransitionEnd = (e) => {
  //   if (e.propertyName !== 'opacity') {
  //     return;
  //   }
  //   const container = this.props.getRef('container');
  //   this.scrollToActiveTab({
  //     target: container,
  //     currentTarget: container,
  //   });
  // }

  render() {
    const { next, prev } = this.state;
    const prefixCls = 'ant-tabs';
    const {
      scrollAnimated
    } = this.props;

    const showNextPrev = prev || next;

    const prevButton = (
      <span
        onClick={prev ? this.prev : null}
        unselectable="unselectable"
        className={`${prefixCls}-tab-prev` +
          (prev ? '' : ` ${prefixCls}-tab-btn-disabled`) +
          (showNextPrev ? ` ${prefixCls}-tab-arrow-show` : '')
        }
      // onTransitionEnd={this.prevTransitionEnd}
      >
        <span className={`${prefixCls}-tab-prev-icon`}>
          <LeftOutlined className={`${prefixCls}-tab-prev-icon-target`} />
        </span>
      </span>
    );

    const nextButton = (
      <span
        onClick={next ? this.next : null}
        unselectable="unselectable"
        className={`${prefixCls}-tab-next` +
          (next ? '' : ` ${prefixCls}-tab-btn-disabled`) +
          (showNextPrev ? ` ${prefixCls}-tab-arrow-show` : '')
        }
      >
        <span className={`${prefixCls}-tab-next-icon`}>
          <RightOutlined className={`${prefixCls}-tab-next-icon-target`} />
        </span>
      </span>
    );

    const navClassName = `${prefixCls}-nav`;
    const navClasses = navClassName +
      (scrollAnimated ? ` ${navClassName}-animated` : ` ${navClassName}-no-animated`);

    return (
      <div
        className={`${prefixCls}-nav-container` + (showNextPrev ? ` ${prefixCls}-nav-container-scrolling` : '')}
        key="container"
        ref={this.containerRef}
      >
        {prevButton}
        {nextButton}
        <div className={`${prefixCls}-nav-wrap`} ref={this.navWrapRef}>
          <div className={`${prefixCls}-nav-scroll`}>
            <div className={`${navClasses} nav-content`} ref={this.navRef}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
