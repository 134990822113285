import React from 'react';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearchPlus, faSearchMinus, faArrowsAlt, faDownload, faBug, faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { ANDES_V2, CASCADE, FASTPI, ROCKY } from '../../constants/pageType';

function fitView(e, layout) {
  e.stopPropagation();
  layout.fitView();
}

function zoomIn(e, layout) {
  e.stopPropagation();
  layout.zoomIn();
}

function zoomOut(e, layout) {
  e.stopPropagation();
  layout.zoomOut();
}

function menuClick(e, key, menuSelect) {
  e && e.stopPropagation()
  menuSelect({ key, domEvent: e })
}

function stopPropagation({ domEvent }) {
  domEvent && domEvent.stopPropagation()
}

function menu(menuSelect, layout, product, layoutError, layoutCheck) {
  const items = [
    {
      key: 'Menu',
      label: <button className="layBtn" title="Menu">
        <FontAwesomeIcon icon={faBars} />
      </button>,
      className: 'aurora-explorer-menu-submenu',
      children: [ROCKY, CASCADE, ANDES_V2].includes(product) ? [
        { key: 'Nets', label: 'Nets', onClick: ({ domEvent }) => menuClick(domEvent, 'Nets', menuSelect), className: 'aurora-explorer-menu-dropdown' },
        { key: 'Components', label: 'Components', onClick: ({ domEvent }) => menuClick(domEvent, 'Components', menuSelect), className: 'aurora-explorer-menu-dropdown' },
        { key: 'Stackup', label: 'Stackup', onClick: ({ domEvent }) => menuClick(domEvent, 'Stackup', menuSelect), className: 'aurora-explorer-menu-dropdown' },
        { key: 'Padstacks', label: 'Via Padstacks', onClick: ({ domEvent }) => menuClick(domEvent, 'Padstacks', menuSelect), className: 'aurora-explorer-menu-dropdown' }
      ] : [
        { key: 'Nets', label: 'Nets', onClick: ({ domEvent }) => menuClick(domEvent, 'Nets', menuSelect), className: 'aurora-explorer-menu-dropdown' },
        { key: 'Components', label: 'Components', onClick: ({ domEvent }) => menuClick(domEvent, 'Components', menuSelect), className: 'aurora-explorer-menu-dropdown' },
        { key: 'Stackup', label: 'Stackup', onClick: ({ domEvent }) => menuClick(domEvent, 'Stackup', menuSelect), className: 'aurora-explorer-menu-dropdown' },
      ]
    },
    {
      key: 'ZoomIn',
      label: <button className="layBtn" title="Zoom In" onClick={(e) => zoomIn(e, layout)}>
        <FontAwesomeIcon icon={faSearchPlus} />
      </button>,
      onClick: stopPropagation
    },
    {
      key: 'ZoomOut',
      label: <button className="layBtn" title="Zoom Out" onClick={(e) => zoomOut(e, layout)}>
        <FontAwesomeIcon icon={faSearchMinus} />
      </button>,
      onClick: stopPropagation
    },
    {
      key: 'FitView',
      label: <button className="layBtn" title="Fit View" onClick={(e) => fitView(e, layout)}>
        <FontAwesomeIcon icon={faArrowsAlt} />
      </button>,
      onClick: stopPropagation
    },
    {
      key: 'Download',
      label: <button className="layBtn" title="Download" >
        <FontAwesomeIcon icon={faDownload} onClick={(e) => menuClick(e, 'Download', menuSelect)} />
      </button>,
      onClick: stopPropagation
    }
  ]
  if ([FASTPI, CASCADE].includes(product)) {
    if (layoutError) {
      items.push({
        key: 'LayoutErrors',
        label: <button className="layBtn" title="Layout Check Report" >
          <FontAwesomeIcon icon={faBug} onClick={(e) => menuClick(e, 'LayoutErrors', menuSelect)} />
        </button>,
        onClick: stopPropagation
      })
    } else if (layoutCheck) {
      items.push({
        key: 'LayoutCheck',
        label: <button className="layBtn" title="Layout Check" >
          <FontAwesomeIcon icon={faMicrochip} onClick={(e) => layoutCheck(e)} />
        </button>,
        onClick: stopPropagation
      })
    }
  }
  return (
    <Menu
      className='aurora-explorer-menu'
      onClick={menuSelect}
      items={items}
    />
  )
}

export default menu;