import React, { Component, Fragment } from "react";
import { Input, Select } from "antd";
import { valueCheck } from '@/services/helper/valueCheck';
import { scaleConversion } from "@/services/helper/numberHelper";
import { numberCheck } from "@/services/helper/dataProcess";
import NP from 'number-precision';

const { Option } = Select;
class SweepOptions extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  inputBlur = (e, inputType) => {
    let value = this.props.extraction[inputType];
    let config = { ...this.props.extraction };
    let error = null;
    const { units } = this.props;

    let _type = inputType;
    const unit = units[`${_type}Unit`];

    if (_type === 'maxFreq') {
      if (value) {
        error = valueCheck({ value, inputType: _type, unit });
      }
    } else {
      error = valueCheck({ value, inputType: _type, unit });
    }

    if (error) {
      e.target.focus();
      this.props.updateError({ type: _type, error });
    } else {
      if (_type === 'maxFreq') {
        if (value && value < 10) {
          value = Math.ceil(value);
          config[_type] = value;
        }
      } else {
        config[_type] = value;
      }
      const prevError = this.props.error;
      if (prevError && (prevError.type === _type || prevError.type === "judge")) {
        this.props.updateError(null);
      }
      this.props.updateExtractionConfig(config);
    }
  }

  changeConfigValue = (e, type) => {
    let value = e.target.value;
    const { extraction, error } = this.props;
    let config = { ...extraction };
    config[type] = value;

    if (error && error.type === type) {
      this.props.updateError(null);
    }
    this.props.updateExtractionConfig(config);
  }

  getUnitOptions = (key) => {
    let options = [];
    switch (key) {
      case 'logSweepMin':
        options = ["Hz", "KHz"];
        break;
      case 'logSweepMax':
      case 'linearSweepMax':
        options = ["Hz", "KHz", "MHz", "GHz"];
        break;
      case 'linearSweepFreqStep':
        options = ["Hz", "KHz", "MHz"];
        break;
      case 'maxFreq':
        options = ["GHz"];
        break;
      default:
        options = ["Hz", "KHz", "MHz", "GHz"];
        break;
    }
    return options;
  }

  unitSelect = (key) => {
    const { units } = this.props;
    const options = this.getUnitOptions(key);
    const unitKey = `${key}Unit`;
    const value = units[unitKey];
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      value={value}
      style={{ width: 68 }}
      onChange={(e) => this.changeUnit(e, key, unitKey)}
    >
      {options.map(item => (
        <Option key={item} value={item}>{item}</Option>
      ))}
    </Select>
  }

  changeUnit = (key, type, typeUnit) => {
    let units = this.props.units;
    const { extraction } = this.props;
    let config = { ...extraction };

    if (units[typeUnit] === key) {
      return;
    }

    const scale = scaleConversion(key, units[typeUnit]);
    config[type] = config[type] && !numberCheck(config[type]) ? NP.times(config[type], scale).toString() : config[type];
    units[typeUnit] = key;
    this.props.updateUnit(units, "units");
    this.props.updateExtractionConfig(config);
  }

  render = () => {
    const { extraction = {} } = this.props;
    const { logSweepMin, logSweepMax, logSweepSAPD, linearSweepMax, linearSweepFreqStep, maxFreq } = extraction;
    return <Fragment>
      {/* Frequency Sweep */}
      <div className='sierra-extraction-content'>
        {/* 1K - 10M */}
        <span className='sierra-extraction-content-body'>Log Sweep</span>
        <Input
          value={logSweepMin}
          className='extraction-input sierra-extraction-input-item'
          style={{ marginRight: 7 }}
          addonAfter={this.unitSelect('logSweepMin')}
          onChange={(e) => this.changeConfigValue(e, 'logSweepMin')}
          onBlur={(e) => this.inputBlur(e, 'logSweepMin')}
        />
        To
        <Input
          value={logSweepMax}
          className='extraction-input sierra-extraction-input-item'
          style={{ marginLeft: 6 }}
          addonAfter={this.unitSelect('logSweepMax')}
          onChange={(e) => this.changeConfigValue(e, "logSweepMax")}
          onBlur={(e) => this.inputBlur(e, 'logSweepMax')}
        />
        <Input
          className='sub-extraction-input sierra-extraction-input-item-sub'
          value={logSweepSAPD}
          onChange={(e) => this.changeConfigValue(e, "logSweepSAPD")}
          onBlur={(e) => this.inputBlur(e, 'logSweepSAPD')}
        />
        <span className='sierra-extraction-content-body sierra-extraction-step-width'>Per Decade</span>
      </div>
      <div className='sierra-extraction-content'>
        <span className='sierra-extraction-content-body'>Linear Sweep</span>
        <Input
          value={logSweepMax}
          className='extraction-input sierra-extraction-input-item'
          style={{ marginRight: 7 }}
          addonAfter={this.unitSelect('logSweepMax')}
          onChange={(e) => this.changeConfigValue(e, 'logSweepMax')}
          onBlur={(e) => this.inputBlur(e, 'logSweepMax')}
        />
        To
        <Input
          value={linearSweepMax}
          className='extraction-input sierra-extraction-input-item'
          style={{ marginLeft: 6 }}
          addonAfter={this.unitSelect('linearSweepMax')}
          onChange={(e) => this.changeConfigValue(e, 'linearSweepMax')}
          onBlur={(e) => this.inputBlur(e, 'linearSweepMax')}
        />
        <Input
          className='sub-extraction-input sierra-extraction-input-item-sub'
          value={linearSweepFreqStep}
          onChange={(e) => this.changeConfigValue(e, 'linearSweepFreqStep')}
          onBlur={(e) => this.inputBlur(e, 'linearSweepFreqStep')}
          addonAfter={this.unitSelect('linearSweepFreqStep')}
        />
        <span className='sierra-extraction-content-body sierra-extraction-step-width'>Step</span>
      </div>
      <div className='sierra-extraction-content'>
        <span className='sierra-extraction-content-body'>Maximum Frequency</span>
        {/* (0-1000) */}
        <Input
          className='extraction-input sierra-extraction-maxF-input'
          value={maxFreq}
          onChange={(e) => this.changeConfigValue(e, 'maxFreq')}
          onBlur={(e) => this.inputBlur(e, 'maxFreq')}
          addonAfter={this.unitSelect('maxFreq')}
        />
      </div>
    </Fragment>;
  }

}

export default SweepOptions;
