import { CCC, ADMIN, ROOT, XANDES, ROCKY, FASTPI, SIERRA, ANDES, ROCKYSSN, ANDES_V2, ROCKY_SERVICE_OPTION, CASCADE, AOI, HIMALAYAS } from "@/constants/permissionType";
import {
  ROCKY as ROKCY_PAGE,
  FASTPI as FASTPI_PAGE,
  SIERRA as SIERRA_PAGE,
  ANDES as ANDES_PAGE,
  ANDES_V2 as ANDES_V2_PAGE,
  CASCADE as CASCADE_PAGE,
  HIMALAYAS as HIMALAYAS_PAGE
} from '@/constants/pageType';

class PermissionData {
  constructor() {
    this.permissionList = [];
    this.settings = {} // RockyEyeMask: "Standard", RockyExtraction: "SIwave", SierraExtraction: "SIwave"
  }

  saveList(list) {
    this.permissionList = list;
  }

  cleanData() {
    this.permissionList = [];
  }

  hasCCC() {
    return this.permissionList.includes(CCC);
  }

  isAdmin() {
    return this.permissionList.includes(ADMIN) || this.permissionList.includes(ROOT);
  }

  isRoot() {
    return this.permissionList.includes(ROOT);
  }

  hasXAndes() {
    return this.permissionList.includes(XANDES);
  }

  hasPDN() {
    return this.permissionList.includes(FASTPI);
  }

  hasSierra() {
    return this.permissionList.includes(SIERRA);
  }

  hasRocky() {
    return this.permissionList.includes(ROCKY);
  }

  hasAndes() {
    return this.permissionList.includes(ANDES);
  }

  hasRockySSN() {
    return this.permissionList.includes(ROCKYSSN);
  }

  hasAndesV2() {
    return this.permissionList.includes(ANDES_V2)
  }

  hasRockyServiceOption() {
    return this.permissionList.includes(ROCKY_SERVICE_OPTION)
  }

  hasCascade() {
    return this.permissionList.includes(CASCADE)
  }

  hasAOI() {
    return this.permissionList.includes(AOI)
  }

  hasHimalayas() {
    return this.permissionList.includes(HIMALAYAS)
  }

  async saveSettings(settings) {
    this.settings = settings;
  }

  async savePageType(settings) {
    this.pageSettings = settings;
  }

  updateSetting(rockySettings) {
    if (rockySettings) {
      this.settings.RockySimulator = rockySettings.simulator
      this.settings.RockyExtraction = rockySettings.extractionOption
      this.settings.portType = rockySettings.portType
    }
  }

  getEyeMask() {
    if (this.settings && this.settings.RockyEyeMask !== undefined) {
      return this.settings.RockyEyeMask;
    }
    return 'Standard';  // Standard / Aurora
  }

  getRockyExtraction() {
    if (this.settings && this.settings.RockyExtraction !== undefined) {
      return this.settings.RockyExtraction;
    }
    return 'SIwave';
  }

  getTranslationMode() {
    if (this.settings && this.settings.TranslationMode !== undefined) {
      return this.settings.TranslationMode === "true" ? true : false;
    }
    return true;
  }

  getDisplayRockyReportPDF() {
    if (this.settings && this.settings.DisplayRockyReportPDF !== undefined) {
      return this.settings.DisplayRockyReportPDF === "true" ? true : false;
    }
    return true;
  }

  getRockySimulator() {
    if (this.settings && this.settings.RockySimulator !== undefined) {
      return this.settings.RockySimulator;
    }
    return 'HSPICE';
  }

  getRockyPortType() {
    if (this.settings && this.settings.portType !== undefined) {
      return this.settings.portType;
    }
    return 'pin_group';
  }

  getPageStyle() {
    if (this.pageSettings && this.pageSettings.HomePageStyle !== undefined) {
      return this.pageSettings.HomePageStyle;
    }
    return 'Aurora';
  }
};

const permissionData = new PermissionData();

export function getPageList() {
  let list = []; // { page, title, link }
  if (permissionData.hasAndesV2()) {
    list.push({
      page: ANDES_V2_PAGE,
      title: 'ANDES',
      link: '/andes',
      nameClass: 'fastpi-name',
      titleClass: 'home-page-img-div-fastpi',
      logoClass: 'andes-logo',
      descriptionInfo: 'Multi PCB SerDes end to end channel simulation platform'
    });
  }
  if (permissionData.hasCascade()) {
    list.push({
      page: CASCADE_PAGE,
      title: 'CASCADE',
      link: '/cascade',
      nameClass: 'cascade-name',
      titleClass: 'home-page-img-div',
      logoClass: 'cascade-logo',
      descriptionInfo: 'IC-Package-PCB power integrity simulation and optimization'
    });
  }
  if (permissionData.hasPDN()) {
    list.push({
      page: FASTPI_PAGE,
      title: 'FASTPI',
      link: '/fastpi',
      nameClass: 'fastpi-name',
      titleClass: 'home-page-img-div-fastpi',
      logoClass: 'pdn-img',
      descriptionInfo: 'Fast power integrity simulation and optimization for Intel CPU platform'
    });
  }
  if (permissionData.hasRocky()) {
    list.push({
      page: ROKCY_PAGE,
      title: 'ROCKY',
      link: '/rocky',
      nameClass: 'rocky-name',
      titleClass: 'home-page-img-div',
      logoClass: 'rocky-logo',
      descriptionInfo: 'DDR/LPDDR complete interface simulation and compliance report'
    });
  }
  if (permissionData.hasSierra()) {
    list.push({
      page: SIERRA_PAGE,
      title: 'SIERRA',
      link: '/sierra',
      nameClass: 'fastpi-name',
      titleClass: 'home-page-img-div-fastpi',
      logoClass: 'sierra-img',
      descriptionInfo: 'Multi PCB GPIO (I2C, SPI, QSPI, UART, SPMI) signal integrity validation'
    });
  }
  if (permissionData.hasAndes()) {
    list.push({
      page: ANDES_PAGE,
      title: 'ANDES_V1',
      link: '/andes_v1',
      nameClass: 'andes-name',
      titleClass: 'home-page-img-div',
      logoClass: 'andes-v2-logo',
      descriptionInfo: ''
    });
  }

  if (permissionData.hasHimalayas()) {
    list.push({
      page: HIMALAYAS_PAGE,
      title: 'HIMALAYAS',
      link: '/himalayas',
      nameClass: 'himalayas-name',
      titleClass: 'home-page-img-div',
      logoClass: 'himalayas-logo',
      descriptionInfo: 'Pre-Layout Chip, Package and PCB Simulation Platform for Power Induced Signal Integrity'
    });
  }
  return list;
}

export function getPageTypeByUrl(url) {
  switch (url) {
    case '/andes':
      return ANDES_V2_PAGE;
    case '/andes_v1':
      return ANDES_PAGE;
    case '/fastpi':
      return FASTPI_PAGE;
    case '/rocky':
      return ROKCY_PAGE;
    case '/sierra':
      return SIERRA_PAGE;
    case '/project':
      return 'XANDES';
    case '/cascade':
      return CASCADE_PAGE;
    case '/himalayas':
      return HIMALAYAS_PAGE;
    default: return '';
  }
}

export function getLinkByPageType(page) {
  switch (page) {
    case ANDES_V2_PAGE:
      return '/andes';
    case ANDES_PAGE:
      return '/andes_v1';
    case FASTPI_PAGE:
      return '/fastpi';
    case ROKCY_PAGE:
      return '/rocky';
    case SIERRA_PAGE:
      return '/sierra';
    case 'XANDES':
      return '/project';
    case CASCADE_PAGE:
      return '/cascade';
    case HIMALAYAS_PAGE:
      return '/himalayas';
    default: return '/';
  }
}
export default permissionData;