import React, { Fragment, PureComponent } from "react";
import { createPortal } from "react-dom";
import Panel from '@/components/Panel';
import MaterialName from "./name";
import { Select, Checkbox, Divider } from 'antd';
import { DIELECTRIC, getApplyAllType, materialTypeList, METAL, getCheckApplyAll, FILL_MATERIAL, MetalMaterial } from "../../services/Stackup";
import MetalSetup from './metalSetup';
import DielectricSetup from './dielectricSetup';
import { MATERIAL } from "../../constants/libraryConstants";
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';
import { ANDES_V2, CASCADE, ROCKY } from "../../constants/pageType";
import { NEW_MATERIAL, COPPER, SOLDER } from "../../services/Stackup/Material";


const Option = Select.Option;
class MaterialPanel extends PureComponent {
  constructor(props) {
    super(props);
    const { record, dataIndex } = props;
    const _materialList = this.getMaterialList();
    this.state = {
      selectMaterialName: this.getSelectMaterialName(record, dataIndex, _materialList),
      errorMsg: null,
      type: record.type || METAL,
      applyAll: false,
      applyAllOuter: false,
      applyAllInner: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  getMaterialList = () => {
    const { record: { type }, dataIndex, pageType, materialList } = this.props;
    if (type === METAL && dataIndex !== FILL_MATERIAL) {
      const _materialList = [...materialList];
      for (const name of [COPPER, SOLDER]) {
        if ((pageType === ANDES_V2 || pageType === CASCADE || pageType === ROCKY) && !materialList.find(item => item.name === name)) {
          _materialList.push(name === COPPER ? { name, sigma: "58000000", miu_r: '0.999991', type: METAL } : { name, sigma: "7000000", miu_r: '1', type: METAL });
        }
      }
      return _materialList;
    }
    return materialList;
  }

  getSelectMaterialName = (record, dataIndex, materialList) => {
    if (dataIndex === FILL_MATERIAL) {
      return record.fill_material || NEW_MATERIAL
    }
    const nameIndex = materialList.findIndex(item => item.name === record.material);
    return nameIndex > -1 && record.material ? record.material : NEW_MATERIAL;
  }

  closeModal = () => {
    const { type, selectMaterialName, applyAll, applyAllOuter, applyAllInner, errorMsg } = this.state;
    if (errorMsg) {
      return;
    }
    const { record, dataIndex } = this.props;
    let name = null;
    if (this.NameChild && this.NameChild.getMaterialName) {
      name = this.NameChild.getMaterialName();
    }
    const applyAllType = record.type === METAL || dataIndex === FILL_MATERIAL ? getApplyAllType({ applyAll, applyAllOuter, applyAllInner }) : applyAll;
    const newMaterial = { ...this.getConfig(type, dataIndex), name, type: dataIndex === FILL_MATERIAL ? DIELECTRIC : type };
    this.props.closePanel({ newMaterial, applyAllType, selectMaterialName, record, dataIndex });
    this.props.save && this.props.save();
  }

  getConfig = (type, dataIndex) => {
    let materialConfig = {};
    if (type === METAL && dataIndex !== FILL_MATERIAL && this.MetalChild && this.MetalChild.getMaterialConfig) {
      materialConfig = this.MetalChild.getMaterialConfig();
    }
    if ((type === DIELECTRIC || dataIndex === FILL_MATERIAL) && this.DielectricChild && this.DielectricChild.getMaterialConfig) {
      materialConfig = this.DielectricChild.getMaterialConfig();
    }

    return materialConfig;
  }

  updateErrorMsg = (errorMsg) => {
    this.setState({
      errorMsg
    })
  }

  componentDidUpdate = (prevProps) => {
    const { record: { material, fill_material, type }, dataIndex } = this.props;
    if ((prevProps.record.material !== material && dataIndex === MATERIAL)
      || (prevProps.record.fill_material !== fill_material && dataIndex === FILL_MATERIAL)) {
      this.setState({
        selectMaterialName: dataIndex === FILL_MATERIAL ? fill_material : material,
        type
      }, () => {
        if (type === METAL && dataIndex !== FILL_MATERIAL) {
          this.MetalChild.updateConfig();
        }

        if (type === DIELECTRIC || dataIndex === FILL_MATERIAL) {
          this.DielectricChild.updateConfig();
        }
      });
    }
  }

  onNameRef = (ref) => {
    this.NameChild = ref;
  }

  onMetalRef = (ref) => {
    this.MetalChild = ref;
  }

  onDielectricRef = (ref) => {
    this.DielectricChild = ref;
  }

  changeMaterialType = (key) => {
    this.setState({
      type: key,
      selectMaterialName: NEW_MATERIAL
    })
  }

  selectMaterial = (key) => {
    this.setState({
      selectMaterialName: key
    }, () => {
      const { type } = this.state;
      const { dataIndex } = this.props;
      if (type === METAL && dataIndex !== FILL_MATERIAL) {
        this.MetalChild.updateConfig();
      }

      if (type === DIELECTRIC || dataIndex === FILL_MATERIAL) {
        this.DielectricChild.updateConfig();
      }
    });
  }

  getMaterialName = (materialList, selectMaterialName) => {
    if (selectMaterialName === NEW_MATERIAL) {
      return null;
    } else {
      const material = materialList.find(item => item.name === selectMaterialName);
      return material ? material.name : null;
    }
  }

  applyChange = (e, type) => {
    const checked = e.target.checked;
    let { applyAllOuter, applyAllInner, applyAll } = this.state;
    if (checked) {
      const applyInfo = getCheckApplyAll(type, { applyAll, applyAllOuter, applyAllInner });
      this.setState({
        applyAll: applyInfo.applyAll,
        applyAllInner: applyInfo.applyAllInner,
        applyAllOuter: applyInfo.applyAllOuter
      })
    } else {
      this.setState({
        [type]: checked
      })
    }
  }

  renderDialog() {
    const { errorMsg, type, selectMaterialName, applyAll, applyAllOuter, applyAllInner } = this.state;
    const { materialList = [], record, editType, dataIndex, pageType } = this.props;
    const _materialList = this.getMaterialList(materialList);
    const materialName = this.getMaterialName(_materialList, selectMaterialName);
    const className = type === DIELECTRIC || dataIndex === FILL_MATERIAL ? "material-dielectric-item" : "material-metal-item";
    const prevMaterial = _materialList.find(item => selectMaterialName !== NEW_MATERIAL && item.name === selectMaterialName);
    const content = (
      <Panel
        className='material-definition-panel panel-x-scroll-hidden'
        title={<div className='material-definition-title'>Material Definition - {record.name}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={500}
        position='panel-center'
        draggable
        minHeight={200}
      >
        <div className='material-definition-content'>
          {editType === "table" || editType === "ball_material" ? <div className={`material-definition-item ${className}`}>
            {/* type */}
            <label className='material-edit-value-label'>Select Material</label>
            <Select
              value={selectMaterialName}
              onChange={this.selectMaterial}
              placeholder={"Select Material"}
              className='aurora-select material-select'
              popupClassName='aurora-select-dropdown'
              // getPopupContainer={trigger => trigger.parentNode}
              getPopupContainer={() => document.getElementById('root')}
            >
              {_materialList.map(item =>
              (((item.type === type && dataIndex !== FILL_MATERIAL)
                || (dataIndex === FILL_MATERIAL && item.type === DIELECTRIC)) && <Option key={item.name}>{item.name}</Option>)
              )}
              <Option key={NEW_MATERIAL}>{NEW_MATERIAL}</Option>
            </Select>
          </div> : null}
          <MaterialName
            onRef={this.onNameRef}
            materialType={type}
            selectMaterialName={selectMaterialName}
            materialList={_materialList}
            className={className}
            materialName={materialName}
            updateErrorMsg={this.updateErrorMsg}
            errorMsg={errorMsg}
            dataIndex={dataIndex}
          />
          {editType !== "ball_material" ?
            <div className={`material-definition-item ${className}`}>
              {/* type */}
              <label className='material-edit-value-label'>Type</label>
              <Select
                value={dataIndex === FILL_MATERIAL ? "Dielectric" : type}
                onChange={this.changeMaterialType}
                placeholder={"Material Type"}
                disabled={(/* dataIndex !== FILL_MATERIAL &&  */editType === "materialMenu" && selectMaterialName === NEW_MATERIAL) ? false : true}
                className='aurora-select material-select'
                popupClassName='aurora-select-dropdown'
                getPopupContainer={trigger => trigger.parentNode}
              >
                {materialTypeList.map(item =>
                  <Option key={item}>{item}</Option>
                )}
              </Select>
            </div> : null}
          {(type === METAL && dataIndex !== FILL_MATERIAL) ? <MetalSetup
            onRef={this.onMetalRef}
            material={prevMaterial}
            errorMsg={errorMsg}
            pageType={pageType}
            updateErrorMsg={this.updateErrorMsg}
          /> : <DielectricSetup
            onRef={this.onDielectricRef}
            material={prevMaterial}
            errorMsg={errorMsg}
            updateErrorMsg={this.updateErrorMsg}
          />}
          {editType === "table" ?
            <Fragment>
              <Divider className='material-divider' />
              {this.getCheckboxItem({
                checked: applyAll,
                checkChange: this.applyChange,
                type: "applyAll",
                text: `Apply to all ${type.toLowerCase()} layers`
              })}
              {(type === METAL || dataIndex === FILL_MATERIAL) && this.getCheckboxItem({
                checked: applyAllOuter,
                checkChange: this.applyChange,
                type: "applyAllOuter",
                text: `Apply to outer metal layers`
              })}
              {(type === METAL || dataIndex === FILL_MATERIAL) && this.getCheckboxItem({
                checked: applyAllInner,
                checkChange: this.applyChange,
                type: "applyAllInner",
                text: `Apply to inner metal layers`
              })}
            </Fragment> : null}
          {errorMsg && errorMsg.error ? <span className='aurora-model-name-error-msg'>{errorMsg.error}</span> : null}
        </div>
      </Panel >)
    return createPortal(content, this.dialogRoot);
  }

  getCheckboxItem = ({ checked, checkChange, type, text }) => {
    return <div className='material-definition-item material-definition-item-apply'>
      <Checkbox
        className='material-definition-item-checkbox'
        checked={checked}
        onChange={(e) => checkChange(e, type)}
      />
      <label
        className='material-edit-value-label material-edit-value-label-long'
      >{text}</label>
    </div>
  }

  render() {
    const { inputRef } = this;
    const { text, editType } = this.props;
    return (
      <Fragment>
        {editType === "table" ? <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div> : null}
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default MaterialPanel;