import ParameterData from "./parameterData";
import { select } from 'd3-selection';
import { getParameterInfo } from '../../api/sparameterNew';
import { getResultFiles } from '../../api/pdn/PDNAPICtrl';
import { getYLabel } from '../../helper/sparameter/utility';
import { getPDNResultHistoryListPromise } from "../PDNCtrl";
import Viewer, { CurveItem } from '../../Result/Public/sparameter/PlotView';

class Parameters {
  constructor() {
    this.parameters = new Map();
    this.plotViewer = null;
    this.hashIds = []
  }

  getParameter(id) {
    return this.parameters.get(id);
  }

  // initialization paramter list
  getParametersInfo(verificationId, projectId, pdnId, time) {
    return new Promise((resolve, reject) => {
      getResultFiles(verificationId).then(res => {
        const { data } = res.data;
        // data
        if (data && data.results) {
          let files = [...data.results];
          // add optTargetZ
          const optTarget = data.results.filter(d => d.type === 'target').map(item => ({ ...item, type: 'optTarget' }));
          files.push(...optTarget);
          files.forEach(ele => {
            const id = `${ele.type}-${ele.fileName}`;

            //Generate 10-digit random numbers containing numbers and letters
            let str = Math.random().toString(36).substr(2, 12);
            //Determine if it already exists
            while (this.hashIds.includes(str)) {
              str = Math.random().toString(36).substr(2, 12);
            };
            this.hashIds.push(str);
            this.parameters.set(id, new ParameterData({
              name: ele.fileName,
              verificationId,
              projectId,
              pdnId,
              type: ele.type,
              id: id,
              hashId: str,
              time: time
            }));
          });
          resolve({ params: this.parameters, time: data.time });
        } else {
          resolve({ params: this.parameters, time: data.time });
        }
      })
    })
  }

  getSingleInfo({ verificationId, projectId, pdnId, value }) {
    const id = `${value.type}-${value.fileName}`;
    return new Promise((resolve, reject) => {
      //Generate 10-digit random numbers containing numbers and letters
      let str = Math.random().toString(36).substr(2, 12);
      //Determine if it already exists
      while (this.hashIds.includes(str)) {
        str = Math.random().toString(36).substr(2, 12);
      };
      this.hashIds.push(str);
      this.parameters.set(id, new ParameterData({
        name: value.fileName,
        verificationId,
        projectId,
        pdnId,
        type: value.type,
        id: id,
        hashId: str
      }));
      resolve(this.parameters.get(id));
    })
  }

  getHistoryList(verificationId, projectId, pdnId, historyNameList) {
    return new Promise((resolve, reject) => {
      getPDNResultHistoryListPromise(verificationId).then(data => {
        let historyList = [];
        if (Array.isArray(data)) {
          data.forEach(item => {
            historyList.push({ name: item.name, time: item.time });
            const index = historyNameList.findIndex(i => i.time === item.time);
            if (index === -1) {
              item.results.forEach(ele => {
                const id = `${item.time}-${ele.fileName}`;
                //Generate 10-digit random numbers containing numbers and letters
                let str = Math.random().toString(36).substr(2, 12);
                //Determine if it already exists
                while (this.hashIds.includes(str)) {
                  str = Math.random().toString(36).substr(2, 12);
                };
                this.hashIds.push(str);
                this.parameters.set(id, new ParameterData({
                  name: ele.fileName,
                  verificationId,
                  projectId,
                  pdnId,
                  type: ele.type,
                  time: item.time,
                  id: id,
                  hashId: str
                }));
              });
            }
          });
        }
        resolve({ params: this.parameters, historyList });
      })
    })
  }

  getInfo(id, value) {
    return new Promise((resolve, reject) => {
      getParameterInfo().then(response => {
        this.parameters.set(id, value);
      })
    })
  }

  getAxisSetting(name) {
    return this.plotViewer.getAxis(name).getSetting();
  }

  createPlot(element, setting, events) {
    this.plotViewer = new Viewer({ element, events, id: 'FastPI' });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit('Hz');
    xAxis.setLabel('Frequency');
    xAxis.setScale('log');
    let yAxis = this.plotViewer.getAxis('y');
    // TODO: Y axis unit:  zam - ohm
    yAxis.setUnit('Ω');
    yAxis.setLabel(getYLabel({ ...setting, param: 'Z' }));
    // Default y scale
    yAxis.setting.scale = "linear";
    return this.plotViewer;
  }

  showCurve({ parameter, row, col, param, value, fileIndex, curveType, fileType, time }) {
    if (!parameter || !parameter.getCurveData) return;
    return new Promise((resolve) => {
      parameter.getCurveData({ row, col, param, value, fileIndex, fileType, time, curveType }).then((response) => {
        if (response) {
          let curve = new CurveItem();
          curve.x = parameter.freq;
          curve.y = curveType === 'optTarget' ? response.curve.map(item => item * 0.9) : response.curve;
          curve.row = row;
          curve.col = col;
          curve.type = response.type;
          curve.name = parameter.name;
          curve.id = parameter.id;
          curve.color = response.color;
          curve.curveType = curveType || null;
          curve.hashId = parameter.hashId;
          resolve(curve)
        } else {
          const curve = new CurveItem();
          resolve(curve)
        }
      });
    })
  }

  removeCurve({ row, col, id, hashId }) {
    this.plotViewer.removeCurve({ row, col, id, hashId });
  }

  removeCurves(Curves) {
    if (this.plotViewer) {
      this.plotViewer.removeCurves(Curves);
    }
  }

  changeAxis(axis, { param, value, scale }) {
    if (this.plotViewer) {
      const _axis = axis.toUpperCase();
      if (_axis === 'Y') {
        let yAxis = this.plotViewer.getAxis('y');
        yAxis.setLabel(getYLabel({ value, yScale: scale }));
      }
      this.plotViewer['update' + _axis + 'Axis']({ param, value, scale });
    }
  }

  updateRange(type, axis) {
    if (axis) {
      this.plotViewer.updateAxisRange(type, axis)
    }
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }

  clearCurveMark() {
    this.plotViewer.clearCurveMark();
  }

  delSingleCurveMark(cssId, positionX) {
    this.plotViewer.delSingleCurveMark(cssId, positionX)
  }

  changeParameter({ parameters, setting, yScale, xScale }) {
    if (this.plotViewer === null) return;
    const xAxis = this.plotViewer.getAxis('x'),
      yAxis = this.plotViewer.getAxis('y');
    xAxis.setScale(xScale);
    yAxis.setScale(yScale);

    const curves = this.plotViewer.curves;
    const promises = curves.map(item => {
      const parameter = parameters.find((param) => param.id === item.id);
      return parameter.getCurveData({ row: item.row, col: item.col, param: setting.parameter, value: setting.value }).then(
        (response) => {
          item.y = response.curve;
          item.type = response.type;
        }
      )
    });
    return Promise.all(promises).then(() => {
      yAxis.setLabel(getYLabel({ value: setting.value, yScale, param: 'Z' }));
      let unit = '';
      xAxis.setUnit("Hz");

      if (setting.value === 'am') {
        if (setting.parameter === 's') {
          unit = '';
        } else {
          unit = setting.parameter === 'y' ? 'Ω⁻¹' : 'Ω';
        }
      } else {
        unit = '';
      }
      yAxis.setUnit(unit);
      this.plotViewer.updateYAxis({ param: setting.parameter, value: setting.value, scale: yScale });
    })
  }

  deleteParam(id) {
    return new Promise((resolve, reject) => {
      this.parameters.delete(id);
      resolve(this.parameters);
    })
  }

  deleteHistoryParam(id) {
    return new Promise((resolve, reject) => {
      this.parameters.delete(id);
      resolve(this.parameters);
    })
  }

  cleanParameters = () => {
    this.parameters = new Map();
    this.plotViewer = null;
    this.hashIds = []
  }
}

const parameters = new Parameters();
export default parameters;