
import { sierraSaga } from './sierra';
import { sierraSimulationSaga } from './simulation';
import { sierraProjectSaga } from './project';
import sierraDesignUploadSaga from '../SierraSider/Upload/store/sagas';
import { sierraResultSaga } from './result';
import { sierraPreLayoutSaga } from './prelayout';
import { sierraLibrarySaga } from './library';
import { sierraMultiInterfaceSaga } from './multiInterface';
import { sierraSweepSaga } from './sweep';

export default [
  sierraSaga,
  sierraSimulationSaga,
  sierraProjectSaga,
  sierraDesignUploadSaga,
  sierraResultSaga,
  sierraPreLayoutSaga,
  sierraLibrarySaga,
  sierraMultiInterfaceSaga,
  sierraSweepSaga
]
