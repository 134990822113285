import http from '../http';

// Andes
/**
 * Save interface content
 * 2019.08.05
 * @export
 * @param {string} designId design id
 * @param {string} interfaceId interface id
 * @param {string} interfaceName interface name
 * @param {string} projectId project id
 * @param {string} type interface type, PinToPin|
 * @param {string} verificationId verification id
 * @param {string} verificationName verification name
 * @param {JSON} content interface content
 * @returns XMLHttpRequest 
 */
export function updateInterfaceInPCB({ designId, interfaceId, interfaceName, projectId, type, verificationId, verificationName, content, readyForSim, version, designVersion, settingVersion, initialized }) {
  const info = {
    content,
    designId,
    interfaceId,
    interfaceName,
    projectId,
    type,
    verificationId,
    verificationName,
    readyForSim,
    version,
    settingVersion
  }
  if ([0, 1].includes(initialized)) {
    info.initialized = initialized;
  }
  return http.put(`/project/design/interface?designVersion=${designVersion}`, {
    ...info
  })
}

/**
 * Delete interfaces by interfaces id list(sierra nevada)
 *
 * @export
 * @param {Array} interfaceIds interfaces id list
 * @returns XMLHttpRequest 
 */
export function deleteInterfaceInPCBByTypeName(interfaceIds, typeName) {
  return http.delete(`/project/design/interfaces/type/${typeName}`,
    {
      params: {
        interfaceIds: interfaceIds.toString()
      }
    }
  )
};


/**
 * Create an interface in pcb for Sierra
 * 2019.11.13
 * 
 * @param {string} projectId project id
 * @param {string} designId design id
 * @param {string} type interface type, PinToPin|
 * @param {string} verificationId verification id
 * @param {string} verificationName verification name
 * @param {string} content interface content 
 * @returns XMLHttpRequest 
 */
export function createInterfaceByTypeName({ projectId, designId, type, verificationId, verificationName, content, interfaceName, readyForSim, version, typeName, settingVersion }) {
  return http.post(`/project/design/interface/type/${typeName}`, {
    designId,
    projectId,
    type,
    verificationId,
    verificationName,
    content,
    interfaceId: '',
    interfaceName,
    readyForSim,
    version,
    settingVersion
  });
}

/**
 * Create multiple groups of interfaces
 * 2022.09.27
 * 
 * @param {Array} interfacesInfo
 * @returns XMLHttpRequest  
 */
export function updateMultipleInterfaceInPCB({ verificationParams, enableFilter = true }) {
  return http.post(`/project/verification/sierra`, { verificationParams, enableFilter })
}