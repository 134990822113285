export const CHANGE_RESULT_KEY = 'SIERRA/change_result_key';
export const RESULT_CHANNEL = 'SIERRA/result_channel_list';
export const SAVE_EYE_DIAGRAM = 'SIERRA/save_eye_diagram';
export const CHANGE_EYE_DIAGRAM_PROGRESS = 'SIERRA/change_eye_diagram_progress';
export const SAVE_SELECT_SIGNALS = 'SIERRA/save_select_signals';
export const SAVE_EYE_DIAGRAM_SIGNALS = 'SIERRA/save_eye_diagram_signals';
export const SAVE_EYE_DIAGRAM_IMG = 'SIERRA/save_eye_diagram_img';
export const SAVE_CURRENT_INTERFACES = 'SIERRA/save_current_interfaces';
export const UPDATE_CURRENT_INTERFACE_INFO = 'SIERRA/update_current_interface_info';
export const UPDATE_EYE_DIAGRAM_CONFIG = 'SIERRA/update_eye_diagram_config';
export const SET_DEFAULT_EYE_DIAGRAM_CONFIG = 'SIERRA/set_default_eye_diagram_config';
export const SAVE_SIMULATION_CONFIG = 'SIERRA/save_simulation_config';
export const SAVE_EYE_UNIT_VALUE = 'SIERRA/save_eye_unit_value';
export const UPDATE_EYE_DIAGRAM_CLOCKS = 'SIERRA/update_eye_diagram_clocks';
export const RESULT_PAGE_INFO = 'SIERRA/result_page_info';
export const RECALC_MEASURE = `SIERRA/RESULT/recalc_measure`;
export const UPDATE_RECALC = `SIERRA/RESULT/update_recalc`;
export const UPDATE_SWEEP_RECALC = `SIERRA/RESULT/update_sweep_recalc`;
export const UPDATE_SWEEP_MEASUREMENT_PROGRESS = `SIERRA/RESULT/update_sweep_measurement_progress`;
