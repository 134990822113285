import { USER_LOGOUT } from '../../../Login/actionTypes';
import {
  UPDATE_CHANNEL_INFO,
  OPEN_PCB_CHANNEL,
  CLEAR_PCB_CHANNEL_INFO,
  UPDATE_CHANNEL_CONTENT,
  UPDATE_CHANNEL_REPLACE_MONITOR,
  UPDATE_CHANNEL_NAME,
  OPEN_CHANNEL_LOADING,
  UPDATE_GENERATE_PORTS_ERRORS,
  CHANGE_CHANNEL_CREATE_VISIBLE,
  UPDATE_EXIST_RESULT_STATUS,
  FORCE_PREVIEW_PANEL_SHOW,
  SAVE_PREVIEW_STATUS,
  UPDATE_COMPLIANCE_CONFIG,
  UPDATE_HYBRID_STATUS,
  UPDATE_HYBRID_INFO,
  UPDATE_MULTI_MODEL_STATUS,
  UPDATE_SIMULATION_SOLVER_STATUS,
  UPDATE_HSPICE_CONFIG_STATUS
} from './actionTypes';

const defaultState = {
  channelInfo: {
    content: {}
  },
  channelId: null,
  replaceMonitor: [],
  generatePortsErrors: null,
  channelCreateVisible: false,
  resultExist: null,
  forcePreviewShow: false,
  previewStatus: false,
  hybridStatus: false,
  hybridInfo: { hybridRegions: [], hybridLines: [], id: null },
  multiModelUpdateStatus: false,
  simulationSolverStatus: false,
  hspiceConfigStatus: false
}

export const AndesChannelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_PCB_CHANNEL:
      return {
        ...state,
        channelId: action.id
      }
    case UPDATE_CHANNEL_INFO:
      return {
        ...state,
        channelId: action.info.id || state.channelId,
        channelInfo: { ...action.info }
      }
    case CLEAR_PCB_CHANNEL_INFO:
      return {
        ...defaultState
      }
    case UPDATE_CHANNEL_CONTENT:
      return {
        ...state,
        channelInfo: {
          ...state.channelInfo,
          content: {
            ...state.channelInfo.content,
            ...action.data
          }
        }
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_CHANNEL_REPLACE_MONITOR:
      return {
        ...state,
        replaceMonitor: action.replaceMonitor
      }
    case UPDATE_CHANNEL_NAME:
      return {
        ...state,
        channelInfo: {
          ...state.channelInfo,
          name: action.name
        }
      }
    case OPEN_CHANNEL_LOADING:
      return {
        ...state,
        channelLoading: action.status
      }
    case UPDATE_GENERATE_PORTS_ERRORS:
      return {
        ...state,
        generatePortsErrors: action.errorObj
      }
    case CHANGE_CHANNEL_CREATE_VISIBLE:
      return {
        ...state,
        channelCreateVisible: action.visible
      }
    case UPDATE_EXIST_RESULT_STATUS:
      return {
        ...state,
        resultExist: action.resultExist
      }
    case FORCE_PREVIEW_PANEL_SHOW:
      return {
        ...state,
        forcePreviewShow: action.show
      }
    case SAVE_PREVIEW_STATUS:
      return {
        ...state,
        previewStatus: action.status
      }
    case UPDATE_COMPLIANCE_CONFIG:
      return {
        ...state,
        channelInfo: {
          ...state.channelInfo,
          config: {
            channels: action.channels || state.channelInfo.config.channels,
            analysis: {
              channels: action.analysisChannels || state.channelInfo.config.analysis.channels,
              options: action.analysisOptions || state.channelInfo.config.analysis.options
            }
          }
        }
      }
    case UPDATE_HYBRID_STATUS:
      return {
        ...state,
        hybridStatus: action.status
      }
    case UPDATE_HYBRID_INFO:
      return {
        ...state,
        hybridInfo: {
          hybridLines: action.hybridLines || state.hybridInfo.hybridLines,
          hybridRegions: action.hybridRegions || state.hybridInfo.hybridRegions,
          id: action.id
        }
      }
    case UPDATE_MULTI_MODEL_STATUS:
      return {
        ...state,
        multiModelUpdateStatus: action.status
      }
    case UPDATE_SIMULATION_SOLVER_STATUS:
      return {
        ...state,
        simulationSolverStatus: action.status
      }
    case UPDATE_HSPICE_CONFIG_STATUS:
      return {
        ...state,
        hspiceConfigStatus: action.status
      }

    default: return state;
  }
};