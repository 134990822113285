import { getPortByType, getSelectedPorts } from ".";
import { getPortNumberFromFileSuffix } from "../touchstoneHelper";
import { strFormatChange } from "../stringHelper";
import { getDefaultIndex } from "../setDefaultName";
import { SortFn } from "../sort";
import { getTextWidth } from "../getTextWidth";
import { getMultiPinInfo } from '../packageModelHelper/portsHelper'
import { CPHY } from "../../PCBHelper/constants";

/**
 * 
 * @param {*} modelInfo 
 * @param {*} portType 
 * @param {Array} connectorModels get model file info
 * @param {Array} fileList Check whether model file exists
 * @param {*} serdesType 
 * @returns 
 */
function getSignalSelectedFile(modelInfo, portType, connectorModels, fileList, serdesType) {
  if (!modelInfo || !modelInfo.signalInfo || !modelInfo.signalInfo.pins) {
    return {};
  }

  if (serdesType === CPHY) {
    return getCPHYSignalSelectedFile(modelInfo, portType, connectorModels, fileList);
  }

  let modelId_p, modelKey_p, modelId_n, modelKey_n,
    selectFile, selectFile2;
  if (portType === "cable") {
    const positivePinInfo = modelInfo.signalInfo.pins.find(item =>
      item.pinLType === "positive"
      && (
        (item.pinLExternalMap && item.pinLExternalMap.cableModelId)
        || (item.pinRExternalMap && item.pinRExternalMap.cableModelId)));

    const negativePinInfo = modelInfo.signalInfo.pins.find(item =>
      item.pinRType === "negative"
      && (
        (item.pinLExternalMap && item.pinLExternalMap.cableModelId)
        || (item.pinRExternalMap && item.pinRExternalMap.cableModelId)));

    if (positivePinInfo && positivePinInfo.pinLExternalMap && positivePinInfo.pinLExternalMap.cableModelId) {
      modelId_p = positivePinInfo.pinLExternalMap.cableModelId;
      modelKey_p = positivePinInfo.pinLExternalMap.cableModelKey;
    } else if (positivePinInfo && positivePinInfo.pinRExternalMap && positivePinInfo.pinRExternalMap.cableModelId) {
      modelId_p = positivePinInfo.pinRExternalMap.cableModelId;
      modelKey_p = positivePinInfo.pinRExternalMap.cableModelKey;
    }

    if (negativePinInfo && negativePinInfo.pinLExternalMap && negativePinInfo.pinLExternalMap.cableModelId) {
      modelId_n = negativePinInfo.pinLExternalMap.cableModelId;
      modelKey_n = negativePinInfo.pinLExternalMap.cableModelKey;
    } else if (negativePinInfo && negativePinInfo.pinRExternalMap && negativePinInfo.pinRExternalMap.cableModelId) {
      modelId_n = negativePinInfo.pinRExternalMap.cableModelId;
      modelKey_n = negativePinInfo.pinRExternalMap.cableModelKey;
    }
  } else {
    const positivePinInfo = modelInfo.signalInfo.pins.find(item =>
      item[`${portType}Type`] === "positive"
      && (
        item[`${portType}ModelId`]
        || (item[`${portType}ExternalMap`] && item[`${portType}ExternalMap`].modelId)
      ));
    const negativePinInfo = modelInfo.signalInfo.pins.find(item =>
      item[`${portType}Type`] === "negative"
      && (
        item[`${portType}ModelId`]
        || (item[`${portType}ExternalMap`] && item[`${portType}ExternalMap`].modelId)
      ));

    if (positivePinInfo && positivePinInfo[`${portType}ModelId`]) {
      modelId_p = positivePinInfo[`${portType}ModelId`];
      modelKey_p = positivePinInfo[`${portType}ModelKey`];
    } else if (positivePinInfo && positivePinInfo.pinRExternalMap && positivePinInfo.pinRExternalMap.cableModelId) {
      modelId_p = positivePinInfo.pinRExternalMap.cableModelId;
      modelKey_p = positivePinInfo.pinRExternalMap.cableModelKey;
    }

    if (negativePinInfo && negativePinInfo[`${portType}ModelId`]) {
      modelId_n = negativePinInfo[`${portType}ModelId`];
      modelKey_n = negativePinInfo[`${portType}ModelKey`];
    } else if (negativePinInfo && negativePinInfo.pinRExternalMap && negativePinInfo.pinRExternalMap.cableModelId) {
      modelId_n = negativePinInfo.pinRExternalMap.cableModelId;
      modelKey_n = negativePinInfo.pinRExternalMap.cableModelKey;
    }
  }

  if (modelId_p && modelKey_p && fileList.find(item => item.id === modelId_p)) {
    const findFile = connectorModels.find(item => item.libraryId === modelId_p && item.modelKey === modelKey_p) || {};
    const portNumber = getPortNumberFromFileSuffix(findFile.file);
    selectFile = {
      ...findFile,
      portNumber
    }
  }

  if (modelId_n && modelKey_n && (!modelKey_p || modelKey_p !== modelKey_n) && fileList.find(item => item.id === modelId_p)) {

    const findFile = connectorModels.find(item => item.libraryId === modelId_n && item.modelKey === modelKey_n) || {};
    const portNumber = getPortNumberFromFileSuffix(findFile.file);
    if (modelKey_p) {
      selectFile2 = {
        ...findFile,
        portNumber
      }
    } else {
      selectFile = {
        ...findFile,
        portNumber
      }
    }
  }

  return { selectFile, selectFile2 }
}

function getCPHYSignalSelectedFile(modelInfo, portType, connectorModels, fileList) {
  let selectFile = {}, selectFile2;
  if (portType === 'cable') {
    const pinInfo = modelInfo.signalInfo.pins.find(item => ((item.pinLExternalMap && item.pinLExternalMap.cableModelId) || (item.pinRExternalMap && item.pinRExternalMap.cableModelId)));
    if (pinInfo && fileList.find(item => item.id === pinInfo.pinLExternalMap.cableModelId || item.id === pinInfo.pinRExternalMap.cableModelId)) {
      const findFile = connectorModels.find(item =>
        (item.libraryId === pinInfo.pinLExternalMap.cableModelId && item.modelKey === pinInfo.pinLExternalMap.cableModelKey)
        || (item.libraryId === pinInfo.pinRExternalMap.cableModelId && item.modelKey === pinInfo.pinRExternalMap.cableModelKey)
      ) || {};
      const portNumber = getPortNumberFromFileSuffix(findFile.file);
      selectFile = {
        ...findFile,
        portNumber
      }
    }
  } else {
    const pinInfo = modelInfo.signalInfo.pins.find(item => item[`${portType}ModelId`] || (item[`${portType}ExternalMap`] && item[`${portType}ExternalMap`].modelId));
    if (pinInfo && fileList.find(item => item.id === pinInfo[`${portType}ModelId`])) {
      const findFile = connectorModels.find(item => item.libraryId === pinInfo[`${portType}ModelId`] && item.modelKey === pinInfo[`${portType}ModelKey`]) || {};
      const portNumber = getPortNumberFromFileSuffix(findFile.file);
      selectFile = {
        ...findFile,
        portNumber
      }
    }
  }

  return { selectFile, selectFile2 };
}

function getConnectorModelsByType(type, { connector1Models, connector2Models, cableModels }) {
  switch (type) {
    case "cable":
      return cableModels;
    case "pinL":
      return connector1Models;
    case "pinR":
      return connector2Models;
    default: return [];
  }
}

function getNewDisplayPortList({
  currentItem,
  type,
  searchValue,
  portsObj,
  pinList,
  selectFile = {}
}) {
  if (!portsObj || Object.keys(portsObj).length === 0) {
    return [];
  }

  const selectFileId = selectFile.libraryId,
    modelKey = selectFile.modelKey;
  //current pin connected port libraryId
  const { port, portLibraryId, portModelKey } = getPortByType(currentItem, type);

  if (!selectFileId) {
    return [];
  }
  //find current libraryId ports
  let ports = portsObj[selectFileId] ? portsObj[selectFileId] : [];

  if (selectFile.type === 'spice') {
    const current = ports.find(subckt => subckt.name === selectFile.subckt)
    ports = current ? current.ports.map(port => ({ port: port, info: port })) : [];
  }

  const selectedPorts = getSelectedPorts(type, selectFileId, pinList, modelKey);

  //Add escape characters to special characters
  const value = strFormatChange(searchValue);
  const reg = new RegExp(`(${value})`, 'i');

  //sort ports
  let _portList = [], selectedList = [], currentPort = [], searchList = [], notSelectedList = [];
  ports.forEach(item => {
    if (portLibraryId === selectFileId && port && item.port === port && portModelKey === modelKey) {
      // selected port of current pin
      currentPort.push({ ...item, libraryId: selectFileId, modelKey, select: true });
    } else if (searchValue && (portLibraryId !== selectFileId || port !== searchValue) && item.info.match(reg)) {
      //searched ports
      searchList.push({ ...item, libraryId: selectFileId, modelKey });
    } else if (selectedPorts.includes(item.port)) {
      //Ports that have been selected by other pins
      selectedList.push({ ...item, libraryId: selectFileId, modelKey, select: true });
    } else {
      //No selected ports
      notSelectedList.push({ ...item, libraryId: selectFileId, modelKey, select: false });
    }
  });

  const _searchSelectedList = searchList.filter(item => item.select);
  const _searchNotSelectedList = searchList.filter(item => !item.select);
  const _searchList = [..._searchNotSelectedList, ..._searchSelectedList];
  //current pin select port + searched ports + notSelected ports + selected ports
  _portList = [...currentPort, ..._searchList, ...notSelectedList, ...selectedList];
  return _portList;
}

function updateConnectorPorts(connector, propsConnector, savePins) {
  for (let pin of propsConnector.pins) {
    if (!savePins.includes(pin.pin)) {
      continue;
    }
    const findPin = connector.pins.find(item => item.pin === pin.pin);
    if (!findPin) {
      continue;
    }
    pin.port = findPin.port;
    pin.modelId = findPin.modelId;
    pin.modelKey = findPin.modelKey;
    const externalMap = pin.external_map || {};
    if (findPin.external_map) {
      pin.external_map = {
        ...externalMap,
        port: findPin.external_map.port,
        modelId: findPin.external_map.modelId,
        modelKey: findPin.external_map.modelKey
      };
    } else {
      pin.external_map = {
        ...externalMap,
        port: "",
        modelId: "",
        modelKey: ""
      };
    }
  }
  return propsConnector;
}

function updateConnectorModel({ connector, propsConnector, savePins, _connectorModels }) {
  propsConnector = updateConnectorPorts(connector, propsConnector, savePins);

  let models = JSON.parse(JSON.stringify(_connectorModels));
  models = deleteUnUsedConnectorModels({
    models,
    pins: propsConnector.pins
  });
  connector.models = models;
  propsConnector.models = JSON.parse(JSON.stringify(connector.models));
  return propsConnector;
}

function deleteUnUsedConnectorModels({ models, pins }) {
  let modelMap = new Map();
  for (let item of pins) {
    if (!item.port && (!item.external_map || !item.external_map.port)) {
      continue;
    }

    modelMap.set(item.modelKey, {
      id: item.modelId,
      key: item.modelKey
    });

    if (item.external_map && item.external_map.port) {
      modelMap.set(item.external_map.modelKey, {
        id: item.external_map.modelId,
        key: item.external_map.modelKey
      });
    }

  }
  models = models.filter(model => modelMap.get(model.modelKey) && modelMap.get(model.modelKey).id);
  return models;
}

function updateCableModel({
  connector1,
  connector2,
  propsConnector1,
  propsConnector2,
  cableModels,
  saveLPins,
  saveRPins
}) {

  propsConnector1 = updateConnectorCablePorts(connector1, propsConnector1, saveLPins);
  propsConnector2 = updateConnectorCablePorts(connector2, propsConnector2, saveRPins);

  cableModels = deleteUnUsedCableModels(propsConnector1, propsConnector2, cableModels);

  return { connector1: propsConnector1, connector2: propsConnector2, cableModels };
}

function deleteUnUsedCableModels(propsConnector1, propsConnector2, cableModels) {
  let modelMap = new Map();
  for (let item of propsConnector1.pins) {
    if (!item.external_map || !item.external_map.cablePort) {
      continue;
    }
    modelMap.set(item.external_map.cableModelKey, {
      id: item.external_map.cableModelId,
      key: item.external_map.cableModelKey
    });
  }
  for (let item of propsConnector2.pins) {
    if (!item.external_map || !item.external_map.cablePort) {
      continue;
    }
    modelMap.set(item.external_map.cableModelKey, {
      id: item.external_map.cableModelId,
      key: item.external_map.cableModelKey
    });
  }
  cableModels = cableModels.filter(model => modelMap.get(model.modelKey) && modelMap.get(model.modelKey).id);
  return cableModels;
}

function updateConnectorCablePorts(connector, propsConnector, savePins) {
  for (let pin of propsConnector.pins) {
    if (!savePins.includes(pin.pin)) {
      continue;
    }
    const findPin = connector.pins.find(item => item.pin === pin.pin);
    if (!findPin) {
      continue;
    }
    const externalMap = pin.external_map || {};
    if (findPin.external_map) {
      pin.external_map = {
        ...externalMap,
        cablePort: findPin.external_map.cablePort,
        cableModelId: findPin.external_map.cableModelId,
        cableModelKey: findPin.external_map.cableModelKey
      };
    } else {
      pin.external_map = {
        ...externalMap,
        cablePort: "",
        cableModelId: "",
        cableModelKey: ""
      };
    }
  }
  return propsConnector;
}

function getSelectedSignalsBySelectFile({ selectFile, selectFile2, pinList, signal, portType, signalType }) {
  if (!selectFile || !selectFile.libraryId) {
    return [];
  }
  let signals = [];
  const _pinList = pinList.filter(item => item[`${signalType}`] !== signal);
  switch (portType) {
    case "pinL":
    case "pinR":
      let selected2PortPins = [];
      const selectedPortPins = _pinList.filter(item =>
        item.pins.find(pin => (pin[`${portType}Port`]
          && pin[`${portType}ModelId`] === selectFile.libraryId
          && pin[`${portType}ModelKey`] === selectFile.modelKey)
          || (
            pin[`${portType}ExternalMap`]
            && pin[`${portType}ExternalMap`].port
            && pin[`${portType}ExternalMap`].modelId === selectFile.libraryId
            && pin[`${portType}ExternalMap`].modelKey === selectFile.modelKey
          ))
      );

      if (selectFile2) {
        selected2PortPins = _pinList.filter(item =>
          item.pins.find(pin => (pin[`${portType}Port`]
            && pin[`${portType}ModelId`] === selectFile2.libraryId
            && pin[`${portType}ModelKey`] === selectFile2.modelKey)
            || (
              pin[`${portType}ExternalMap`]
              && pin[`${portType}ExternalMap`].port
              && pin[`${portType}ExternalMap`].modelId === selectFile2.libraryId
              && pin[`${portType}ExternalMap`].modelKey === selectFile2.modelKey
            ))
        );
      }

      if (selectedPortPins.length || selected2PortPins.length) {
        signals = [...new Set([...selectedPortPins.map(item => item[`${signalType}`]), ...selected2PortPins.map(item => item[`${signalType}`])])];
      }
      break;
    case "cable":
      let selected2CablePins = [];
      const selectedPortExternalPins = _pinList.filter(item =>
        item.pins.find(pin =>
          (pin.pinLExternalMap
            && pin.pinLExternalMap.cablePort
            && pin.pinLExternalMap.cableModelId === selectFile.libraryId
            && pin.pinLExternalMap.cableModelKey === selectFile.modelKey)
          ||
          (pin.pinRExternalMap
            && pin.pinRExternalMap.cablePort
            && pin.pinRExternalMap.cableModelId === selectFile.libraryId
            && pin.pinRExternalMap.cableModelKey === selectFile.modelKey)
        ));

      if (selectFile2) {
        selected2CablePins = _pinList.filter(item =>
          item.pins.find(pin =>
            (pin.pinLExternalMap
              && pin.pinLExternalMap.cablePort
              && pin.pinLExternalMap.cableModelId === selectFile2.libraryId
              && pin.pinLExternalMap.cableModelKey === selectFile2.modelKey)
            ||
            (pin.pinRExternalMap
              && pin.pinRExternalMap.cablePort
              && pin.pinRExternalMap.cableModelId === selectFile2.libraryId
              && pin.pinRExternalMap.cableModelKey === selectFile2.modelKey)
          ));
      }
      if (selectedPortExternalPins.length || selected2CablePins.length) {
        signals = [...new Set([...selectedPortExternalPins.map(item => item[`${signalType}`]), ...new Set(selected2CablePins.map(item => item[`${signalType}`]))])];
      }
      break;
    default: break;
  }
  return signals;
}

function getPinPortInfo(type, pinInfo, fromSignal, netType) {
  switch (type) {
    case "pinL":
    case "pinR":
      let pinPortInfo = netType ? {} : pinInfo;
      if (fromSignal && fromSignal.pins) {
        pinPortInfo = fromSignal.pins.find(item => item[`${type}Type`] === netType) || {};
      }
      return {
        leftPinInfo: {
          pin: pinInfo[`${type}`],
          pinPort: pinPortInfo[`${type}Port`],
          libraryId: pinPortInfo[`${type}ModelId`],
          pinModelKey: pinPortInfo[`${type}ModelKey`],
          pinType: type
        },
        rightPinInfo: {
          pin: pinInfo[`${type}`],
          pinPort: pinPortInfo[`${type}ExternalMap`] ? pinPortInfo[`${type}ExternalMap`].port : "",
          libraryId: pinPortInfo[`${type}ExternalMap`] ? pinPortInfo[`${type}ExternalMap`].modelId : "",
          pinModelKey: pinPortInfo[`${type}ExternalMap`] ? pinPortInfo[`${type}ExternalMap`].modelKey : "",
          pinType: `${type}External`
        }
      }
    case "cable":
      let cablePortInfo = netType ? {} : pinInfo;
      if (fromSignal && fromSignal.pins) {
        cablePortInfo = fromSignal.pins.find(item => item.pinLType === netType) || {};
      }
      let info = {
        leftPinInfo: {
          pin: pinInfo.pinL,
        },
        rightPinInfo: {
          pin: pinInfo.pinR,
        }
      };
      if (cablePortInfo.pinLExternalMap) {
        info.leftPinInfo = {
          pin: pinInfo.pinL,
          pinPort: cablePortInfo.pinLExternalMap.cablePort,
          libraryId: cablePortInfo.pinLExternalMap.cableModelId,
          pinModelKey: cablePortInfo.pinLExternalMap.cableModelKey,
          pinType: `pinLExternalCable`
        }
      }

      if (cablePortInfo.pinRExternalMap) {
        info.rightPinInfo = {
          pin: pinInfo.pinR,
          pinPort: cablePortInfo.pinRExternalMap.cablePort,
          libraryId: cablePortInfo.pinRExternalMap.cableModelId,
          pinModelKey: cablePortInfo.pinRExternalMap.cableModelKey,
          pinType: `pinRExternalCable`
        }
      }
      return info;
    default: return { leftPinInfo: {}, rightPinInfo: {} };
  }
}

/**
 * clear connection ports by port type (connector,cable)
 * @param {Array} connectorPins connector component pins 
 * [{ 
 * pin,
 * port, modelId, 
 * external_map:{
 *  port, modelId,
 *  cablePort, cableModelId
 *   }
 * }]
 * @param {String} type port type "connector" / "cable"
 * */
function clearPortsInConnectorPinsByModel(connectorPins, type, modelId, modelKey, savePins) {
  connectorPins.forEach(item => {
    if (type === "connector") {
      if (item.modelId === modelId && item.modelKey === modelKey && (!savePins || savePins.includes(item.pin))) {
        item.port = "";
        item.modelId = "";
        item.modelKey = "";
      }

      if ((!savePins || savePins.includes(item.pin)) && item.external_map && item.external_map.modelId === modelId && item.external_map.modelKey === modelKey) {
        item.external_map = {
          ...item.external_map,
          port: "",
          modelId: "",
          modelKey: "",
        };
      }
    }

    if (type === "cable") {
      if ((!savePins || savePins.includes(item.pin)) && item.external_map && modelId === item.external_map.cableModelId && item.external_map.cableModelKey === modelKey) {
        item.external_map = {
          ...item.external_map,
          cablePort: "",
          cableModelId: "",
          cableModelKey: ""
        };
      }
    }
  })
  return connectorPins;
}

function clearPinsPortByFile({
  connectorInfo,
  selectFile,
  portType,
  connectorIndex,
  saveLPins,
  saveRPins }) {
  if (portType === "cable") {
    connectorInfo.connector1.pins = clearPortsInConnectorPinsByModel(connectorInfo.connector1.pins, "cable", selectFile.libraryId, selectFile.modelKey, saveLPins);
    connectorInfo.connector2.pins = clearPortsInConnectorPinsByModel(connectorInfo.connector2.pins, "cable", selectFile.libraryId, selectFile.modelKey, saveRPins);
  } else {
    //clear selected ports
    const savePins = portType === "pinL" ? saveLPins : saveRPins;
    connectorInfo[`connector${connectorIndex}`].pins = clearPortsInConnectorPinsByModel(connectorInfo[`connector${connectorIndex}`].pins, "connector", selectFile.libraryId, selectFile.modelKey, savePins);
  }
  return connectorInfo;
}

function clearPinsPortBySignal({ signal, portType, connectorInfo, signalType, pinList }) {
  const findSignal = pinList.find(item => item[signalType] === signal);
  if (!findSignal) {
    return connectorInfo;
  }
  switch (portType) {
    case "pinL":
      connectorInfo.connector1 = clearPinsPort({
        connector: connectorInfo.connector1,
        portType,
        pins: findSignal.pins
      })
      break;
    case "pinR":
      connectorInfo.connector2 = clearPinsPort({
        connector: connectorInfo.connector2,
        portType,
        pins: findSignal.pins
      })
      break;
    case "cable":
      findSignal.pins.forEach(item => {
        const pinLIndex = connectorInfo.connector1.pins.findIndex(it => it.pin === item.pinL);

        if (pinLIndex > -1) {
          const external_map = connectorInfo.connector1.pins[pinLIndex].external_map ? {
            ...connectorInfo.connector1.pins[pinLIndex].external_map,
            cablePort: "",
            cableModelId: "",
            cableModelKey: "",
          } : {}

          connectorInfo.connector1.pins[pinLIndex] = {
            ...connectorInfo.connector1.pins[pinLIndex],
            external_map: {
              ...external_map
            }
          }
        }
        const pinRIndex = connectorInfo.connector2.pins.findIndex(it => it.pin === item.pinR);

        if (pinRIndex > -1) {
          const external_map = connectorInfo.connector2.pins[pinRIndex].external_map ? {
            ...connectorInfo.connector2.pins[pinRIndex].external_map,
            cablePort: "",
            cableModelId: "",
            cableModelKey: "",
          } : {}

          connectorInfo.connector2.pins[pinRIndex] = {
            ...connectorInfo.connector2.pins[pinRIndex],
            external_map: {
              ...external_map
            }
          }
        }
      });
      break;
    default: break;
  }
  return connectorInfo;
}

function clearPinsPort({ connector, pins, portType }) {
  pins.forEach(item => {
    const pinIndex = connector.pins.findIndex(it => it.pin === item[portType]);

    if (pinIndex > -1) {
      const external_map = connector.pins[pinIndex].external_map ? {
        ...connector.pins[pinIndex].external_map,
        port: "",
        modelId: "",
        modelKey: "",
      } : {}

      connector.pins[pinIndex] = {
        ...connector.pins[pinIndex],
        port: "",
        modelId: "",
        modelKey: "",
        external_map: {
          ...external_map
        }
      }
    }
  });
  return connector;
}

function copyPinsPort({
  toSignal,
  fromSignal,
  pinList,
  connector1,
  connector2,
  signalType,
  portType,
  copyModelKey,
  copyModelKey2
}) {
  const _signal = pinList.find(item => item[`${signalType}`] === toSignal);
  const _copySignal = pinList.find(item => item[`${signalType}`] === fromSignal);
  if (!_signal || !_copySignal) {
    return {
      connector1,
      connector2
    };
  }

  switch (portType) {
    case "pinL":
      for (let pin of _signal.pins) {
        const index = connector1.pins.findIndex(item => item.pin === pin.pinL);
        if (index < 0) {
          continue;
        }
        const findPin = _copySignal.pins.find(item => item.pinLType === pin.pinLType);
        if (!findPin) {
          continue;
        }
        let modelKey = pin.pinLType === "negative" && copyModelKey2 ? copyModelKey2 : copyModelKey;
        const copyExternalMap = findPin.pinLExternalMap || {};
        const external_map = connector1.pins[index].external_map ? {
          ...connector1.pins[index].external_map
        } : {};
        connector1.pins[index] = {
          ...connector1.pins[index],
          port: findPin.pinLPort,
          modelId: findPin.pinLModelId,
          modelKey,
          external_map: {
            ...external_map,
            port: copyExternalMap.port,
            modelId: copyExternalMap.modelId,
            modelKey,
          }
        }
      }
      break;
    case "pinR":
      for (let pin of _signal.pins) {
        const index = connector2.pins.findIndex(item => item.pin === pin.pinR);
        if (index < 0) {
          continue;
        }
        const findPin = _copySignal.pins.find(item => item.pinRType === pin.pinRType);
        if (!findPin) {
          continue;
        }
        let modelKey = pin.pinLType === "negative" && copyModelKey2 ? copyModelKey2 : copyModelKey;
        const copyExternalMap = findPin.pinRExternalMap || {};
        const external_map = connector2.pins[index].external_map ? {
          ...connector2.pins[index].external_map
        } : {}
        connector2.pins[index] = {
          ...connector2.pins[index],
          port: findPin.pinRPort,
          modelId: findPin.pinRModelId,
          modelKey,
          external_map: {
            ...external_map,
            port: copyExternalMap.port,
            modelId: copyExternalMap.modelId,
            modelKey,
          }
        }
      }
      break;
    case "cable":
      for (let pin of _signal.pins) {
        const pinLIndex = connector1.pins.findIndex(item => item.pin === pin.pinL);
        if (pinLIndex < 0) {
          continue;
        }
        const findPin = _copySignal.pins.find(item => item.pinLType === pin.pinLType);
        if (!findPin) {
          continue;
        }
        let modelKey = pin.pinLType === "negative" && copyModelKey2 ? copyModelKey2 : copyModelKey;
        const copyExternalMap = findPin.pinLExternalMap || {};
        const external_map = connector1.pins[pinLIndex].external_map ? {
          ...connector1.pins[pinLIndex].external_map
        } : {}
        connector1.pins[pinLIndex] = {
          ...connector1.pins[pinLIndex],
          external_map: {
            ...external_map,
            cablePort: copyExternalMap.cablePort,
            cableModelId: copyExternalMap.cableModelId,
            cableModelKey: modelKey,
          }
        }
        const pinRIndex = connector2.pins.findIndex(item => item.pin === pin.pinR);
        if (pinRIndex < 0) {
          continue;
        }
        const findRPin = _copySignal.pins.find(item => item.pinRType === pin.pinRType);
        if (!findRPin) {
          continue;
        }
        let _modelKey = pin.pinRType === "negative" && copyModelKey2 ? copyModelKey2 : copyModelKey;
        const copyRExternalMap = findRPin.pinRExternalMap || {};
        const r_external_map = connector2.pins[pinRIndex].external_map ? {
          ...connector2.pins[pinRIndex].external_map
        } : {}
        connector2.pins[pinRIndex] = {
          ...connector2.pins[pinRIndex],
          external_map: {
            ...r_external_map,
            cablePort: copyRExternalMap.cablePort,
            cableModelId: copyRExternalMap.cableModelId,
            cableModelKey: _modelKey,
          }
        }
      }
      break;
    default: break;
  }
  return {
    connector1,
    connector2
  }
}

function copyPinPortsBySignal({
  _copyGroups,
  pinList,
  connectorInfo,
  signalType,
  portType,
  fromSignal }) {
  for (let i = 0; i < _copyGroups.length; i++) {
    for (let item of _copyGroups[i].signalMap) {
      if (fromSignal && item.fromSignal !== fromSignal) {
        continue;
      }
      const info = copyPinsPort({
        toSignal: item.toSignal,
        fromSignal: item.fromSignal,
        pinList,
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2,
        signalType,
        portType,
        copyModelKey: _copyGroups[i].modelKey,
        copyModelKey2: _copyGroups[i].modelKey2
      });
      connectorInfo.connector1 = { ...info.connector1 };
      connectorInfo.connector2 = { ...info.connector2 };
    }
  }
  return connectorInfo;
}

function updateCopyGroupsBySelectFile({
  _copyGroups,
  _selectedSignals,
  selectFile,
  selectFile2,
  _connectorModels
}) {
  const maxLength = _selectedSignals.length;
  for (let i = 0; i < _copyGroups.length; i++) {
    const group = _copyGroups[i];
    const findModelIndex = _connectorModels.findIndex(it => it.modelKey === group.modelKey);
    if (findModelIndex < 0) {
      const _modelKey = getDefaultIndex(_connectorModels.length, _connectorModels.map(it => it.modelKey));
      _connectorModels.push({
        ...selectFile,
        modelKey: _modelKey
      });
      _copyGroups[i].modelKey = _modelKey;
    } else {
      _connectorModels[findModelIndex] = {
        ...selectFile,
        modelKey: group.modelKey
      }
    }

    if (selectFile2) {
      const findModel2Index = _connectorModels.findIndex(it => it.modelKey === group.modelKey2);
      if (findModel2Index < 0) {
        const _modelKey2 = getDefaultIndex(_connectorModels.length, _connectorModels.map(it => it.modelKey));
        _connectorModels.push({
          ...selectFile2,
          modelKey: _modelKey2
        });
        _copyGroups[i].modelKey2 = _modelKey2;
      } else {
        _connectorModels[findModel2Index] = {
          ...selectFile2,
          modelKey: group.modelKey2
        }
      }
    } else {
      delete _copyGroups[i].modelKey2;
    }

    _copyGroups[i].signalMap = _copyGroups[i].signalMap.filter(item => _selectedSignals.includes(item.fromSignal));
    //sort signalMap by selectSignals
    _copyGroups[i].signalMap = SortFn(_copyGroups[i].signalMap, _selectedSignals, "fromSignal");
    if (_copyGroups[i].signalMap.length > maxLength) {
      _copyGroups[i].signalMap = _copyGroups[i].signalMap.filter((it, index) => index < _selectedSignals.length);
    }
    if (_copyGroups[i].signalMap.length < maxLength) {
      const length = maxLength - _copyGroups[i].signalMap.length;
      for (let j = 0; j < length; j++) {
        _copyGroups[i].signalMap.push({ fromSignal: "", toSignal: "" });
      }
    }
  }
  return { _copyGroups, _connectorModels };
}

function getModifiedPinsBySignals({
  copyGroups,
  selectedSignals,
  pinList,
  signalType
}) {
  const updateSignals = [...selectedSignals];
  for (let item of copyGroups) {
    const signals = item.signalMap.filter(it => !!it.toSignal).map(it => it.toSignal);
    updateSignals.push(...signals);
  }
  const filterPinList = pinList.filter(item => updateSignals.includes(item[signalType]));
  let saveLPins = [], saveRPins = [];
  filterPinList.forEach(item => saveLPins.push(...item.pins.map(it => it.pinL)));
  filterPinList.forEach(item => saveRPins.push(...item.pins.map(it => it.pinR)));
  return { saveLPins, saveRPins }
}

function updateCopyGroupsBySignal({ copyModelKey, _copyGroups, toSignal, fromSignal }) {
  let prevSignal = "";
  for (let item of _copyGroups) {
    for (let i = 0; i < item.signalMap.length; i++) {
      const it = item.signalMap[i];
      //delete toSignal of other groups equal to the currently selected toSignal
      if (item.modelKey !== copyModelKey && it.toSignal === toSignal) {
        it.toSignal = "";
        continue;
      }
      //return
      if (item.modelKey !== copyModelKey) {
        continue;
      }
      //current group and current modified signalMap
      if (it.fromSignal === fromSignal) {
        prevSignal = it.toSignal;
        it.toSignal = toSignal;
        continue;
      }
      //delete toSignal of current groups equal to the currently selected toSignal
      if (it.toSignal === toSignal) {
        it.toSignal = "";
      }
    }
  }
  return { _copyGroups, prevSignal };
}

function getPinTypeDisplay(pinType) {
  let type = "";
  if (pinType === "positive") {
    type = "+";
  } else if (pinType === "negative") {
    type = "-";
  }
  return type;
}

function getCopyGroups({
  selectedSignals,
  copyModelKey,
  copyModelKey2,
  _copyGroups = [],
  copyTerminationModelKey
}) {
  let copyGroups = [..._copyGroups];
  let copyGroupsItem = [];
  for (let i = 0; i < selectedSignals.length; i++) {
    const fromSignal = selectedSignals[i];
    copyGroupsItem.push({
      fromSignal,
      toSignal: ""
    })
  }
  copyGroups.push({ modelKey: copyModelKey, modelKey2: copyModelKey2, signalMap: copyGroupsItem, copyTerminationModelKey: copyTerminationModelKey });
  return copyGroups;
}

function getApplySignals(copyGroups) {
  let applySignals = [];
  for (let item of copyGroups) {
    applySignals.push(...item.signalMap.filter(it => !!it.toSignal).map(it => it.toSignal))
  }
  return applySignals;
}

/* get pin and net text width by net and pin name length */
function getPinNetWidth(pinList, fontSize = 12, fontWeight) {
  let leftPins = [], rightPins = [];
  for (let item of pinList) {
    for (let it of item.pins) {
      leftPins.push({ net: it.pinLNet || "", pin: it.pinL || "" });
      rightPins.push({ net: it.pinRNet || "", pin: it.pinR || "" });
    }
  }

  const leftNetList = leftPins.map(item => item.net).sort((a, b) => { return b.length - a.length });
  const leftPinList = leftPins.map(item => item.pin).sort((a, b) => { return b.length - a.length });

  const rightNetList = rightPins.map(item => item.net).sort((a, b) => { return b.length - a.length });
  const rightPinList = rightPins.map(item => item.pin).sort((a, b) => { return b.length - a.length });

  return {
    leftNetWidth: leftNetList.length ? getTextWidth(leftNetList[0], fontSize, null, fontWeight) : null,
    leftPinWidth: leftPinList.length ? getTextWidth(leftPinList[0], fontSize, null, fontWeight) : null,
    rightNetWidth: rightNetList.length ? getTextWidth(rightNetList[0], fontSize, null, fontWeight) : null,
    rightPinWidth: rightPinList.length ? getTextWidth(rightPinList[0], fontSize, null, fontWeight) : null
  }
}

function updateConnectorAllPinsPort({
  type,
  connector1,
  connector2,
  cableModels
}) {
  let _connector1 = connector1 || { pins: [] },
    _connector2 = connector2 || { pins: [] };
  switch (type) {
    case "left":
      _connector1 = clearConnectorPorts(_connector1);
      break;
    case "right":
      _connector2 = clearConnectorPorts(_connector2);
      break;
    case "cable":
      _connector1.pins.forEach(item => {
        const prevMap = item.external_map || {};
        item.external_map = {
          ...prevMap,
          cablePort: "",
          cableModelId: "",
          cableModelKey: ""
        };
      });
      _connector2.pins.forEach(item => {
        const prevMap = item.external_map || {};
        item.external_map = {
          ...prevMap,
          cablePort: "",
          cableModelId: "",
          cableModelKey: ""
        };
      });
      cableModels = deleteUnUsedCableModels(_connector1, _connector2, cableModels);
      break;
    default: break;
  }

  return {
    connector1: _connector1,
    connector2: _connector2,
    cableModels
  }
}

function clearConnectorPorts(connector = { pins: [] }) {
  connector.pins.forEach(item => {
    item.port = "";
    item.modelId = "";
    item.modelKey = "";
    const prevMap = item.external_map || {};
    item.external_map = {
      ...prevMap,
      port: "",
      modelId: "",
      modelKey: ""
    };
  });
  connector.models = [];
  return connector;
}

function getConnSignalType(modelType) {
  let _signalType = "", _type = "";
  switch (modelType) {
    case "left":
      _signalType = "channel1_signal";
      _type = "pinL";
      break;
    case "right":
      _signalType = "channel2_signal";
      _type = "pinR";
      break;
    case "cable":
      _signalType = "signal";
      _type = "cable";
      break;
    default: break;
  }
  return { _signalType, _type }
}

function getChannelSelectedSignalsBySelectFile({
  selectFile,
  selectFile2,
  pinList,
  signal,
  pin,
  determinePinSame
}) {
  let selectedSignals = [];
  for (let sigItem of pinList) {

    if (sigItem.signal === signal && (!pin || (pin && sigItem.pinRight[0].pin === pin))) {
      continue;
    }
    const findLeft = sigItem.pinLeft.find(item =>
      (selectFile && item.pinModelKey === selectFile.modelKey && selectFile.libraryId === item.pinLibraryId)
      || (selectFile2 && item.pinModelKey === selectFile2.modelKey && selectFile2.libraryId === item.pinLibraryId)
    ),
      findRight = sigItem.pinRight.find(item =>
        (selectFile && item.pinModelKey === selectFile.modelKey && selectFile.libraryId === item.pinLibraryId)
        || (selectFile2 && item.pinModelKey === selectFile2.modelKey && selectFile2.libraryId === item.pinLibraryId)
      );

    if (findLeft || findRight) {

      let signalName = sigItem.signal;
      if (determinePinSame) {
        signalName = getMultiPinInfo(pinList, sigItem, 'pinRight')
      }

      selectedSignals.push(signalName);
    }
  }
  return selectedSignals;
}

function autoMatchPort({ oldConnector, portsList, matchType, pinList = [], selectedSignals = [], signalType, modelFile, isMatchPin = false }) {
  const pins = pinList.filter(item => selectedSignals.includes(item[`${signalType}`])).map(it => it.pins).flat();
  const portIndexs = getPortIndex(matchType, portsList.length);
  if (!isMatchPin && (!pins || !pins.length || !portIndexs)) {
    return oldConnector;
  }

  const pinNames = pins.map(pin => pin.pinL || pin.pinR);
  const connectorPins = oldConnector.pins.filter(item => pinNames.includes(item.pin) || isMatchPin);
  const otherPins = oldConnector.pins.filter(item => !pinNames.includes(item.pin) && !isMatchPin);

  const { modelKey, libraryId: modelId } = modelFile;
  let { startIndex, endIndex, startAdd, endAdd } = portIndexs;
  let portNumber = 0;
  connectorPins.forEach(item => {
    item.port = portNumber < portsList.length ? (portsList[startIndex] || {}).port : "";
    portNumber++;
    item.modelId = modelId;
    item.modelKey = modelKey;
    item.external_map = {
      ...item.external_map,
      port: portNumber < portsList.length ? (portsList[endIndex] || {}).port : "",
      modelId,
      modelKey
    }
    portNumber++;

    startIndex += startAdd;
    endIndex += endAdd;
  })

  return {
    ...oldConnector,
    pins: [...connectorPins, ...otherPins]
  }
}

function getPortIndex(type, portLength) {
  switch (type) {
    case 1:
      return {
        startIndex: 0,
        endIndex: 1,
        startAdd: 2,
        endAdd: 2
      }
    case 2:
      return {
        startIndex: 0,
        endIndex: portLength / 2,
        startAdd: 1,
        endAdd: 1
      }
    case 3:
      return {
        startIndex: 0,
        endIndex: portLength - 1 === 0 ? -1 : portLength - 1,
        startAdd: 1,
        endAdd: -1
      }
    default:
      return null
  }
}

function getAutoMatchFile(connectorType, { connector1Models, connector2Models }) {
  const modelType = connectorType === "connector1" ? "pinL" : "pinR";
  const models = getConnectorModelsByType(modelType, { connector1Models, connector2Models })
  return models.length > 0 ? [models[0]] : [];
}

export {
  getSignalSelectedFile,
  getConnectorModelsByType,
  getNewDisplayPortList,
  updateConnectorModel,
  updateCableModel,
  getSelectedSignalsBySelectFile,
  getPinPortInfo,
  clearPortsInConnectorPinsByModel,
  clearPinsPortByFile,
  clearPinsPortBySignal,
  copyPinsPort,
  copyPinPortsBySignal,
  updateCopyGroupsBySelectFile,
  getModifiedPinsBySignals,
  updateCopyGroupsBySignal,
  getPinTypeDisplay,
  getCopyGroups,
  getApplySignals,
  getPinNetWidth,
  deleteUnUsedCableModels,
  updateConnectorAllPinsPort,
  getConnSignalType,
  deleteUnUsedConnectorModels,
  getChannelSelectedSignalsBySelectFile,
  autoMatchPort,
  getAutoMatchFile
}