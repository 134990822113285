import DecapParameterData from "./parameterData";
import { select } from 'd3-selection';
import Viewer, { CurveItem } from '../../Result/Public/sparameter/PlotView';
import { getParameterInfo, deleteParameter } from '../../api/sparameterNew';
import { getYLabel } from '../../helper/sparameter/utility';

class Parameters {
  constructor() {
    this.parameters = new Map();
    this.plotViewer = null;
  }

  getParameter(id) {
    return this.parameters.get(id);
  }

  // initialization paramter list
  getParametersInfo(libraryId, subckts) {
    return new Promise((resolve, reject) => {
      subckts.forEach(ele => {
        this.parameters.set(ele, new DecapParameterData({ name: ele, libraryId }));
      });
      resolve(this.parameters);
    })
  }

  getInfo(id, value) {
    return new Promise((resolve, reject) => {
      getParameterInfo().then(response => {
        this.parameters.set(id, value);
      })
    })
  }

  getAxisSetting(name) {
    return this.plotViewer.getAxis(name).getSetting();
  }

  createPlot(element, setting, events) {
    this.plotViewer = new Viewer({ element, events, product: 'decap' });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit('Hz');
    xAxis.setLabel('Frequency');
    xAxis.setScale('log');
    let yAxis = this.plotViewer.getAxis('y');
    // TODO: Y axis unit:  zam - ohm
    yAxis.setUnit('Ω');
    yAxis.setLabel(getYLabel({ ...setting, param: 'Z' }));
    // Default y scale
    yAxis.setting.scale = "linear";
    return this.plotViewer;
  }

  showCurve({ parameter, row, col, param, value, fileIndex, fileName }) {
    if (!parameter || !parameter._getCurveData) return;
    return new Promise((resolve) => {
      parameter._getCurveData({ row, col, param, value, fileIndex, fileName }).then((response) => {
        var curve = new CurveItem();
        curve.x = parameter.freq;
        curve.y = response.curve;
        curve.row = row;
        curve.col = col;
        curve.type = response.type;
        curve.name = parameter.name;
        curve.id = parameter.id;
        curve.color = response.color;
        resolve(curve)
      });
    })
  }

  removeCurve({ row, col, id }) {
    this.plotViewer.removeCurve({ row, col, id });
  }

  changeAxis(axis, { param, value, scale }) {
    if (this.plotViewer) {
      const _axis = axis.toUpperCase();
      if (_axis === 'Y') {
        let yAxis = this.plotViewer.getAxis('y');
        yAxis.setLabel(getYLabel({ value, yScale: scale }));
      }
      this.plotViewer['update' + _axis + 'Axis']({ param, value, scale });
    }
  }

  updateRange(type, axis) {
    if (axis) {
      this.plotViewer.updateAxisRange(type, axis)
    }
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }

  changeParameter({ parameters, setting, yScale, xScale }) {
    if (this.plotViewer === null) return;
    const xAxis = this.plotViewer.getAxis('x'),
      yAxis = this.plotViewer.getAxis('y');
    xAxis.setScale(xScale);
    yAxis.setScale(yScale);

    const curves = this.plotViewer.curves;
    const promises = curves.map(item => {
      const parameter = parameters.find((param) => param.id === item.id);
      return parameter._getCurveData({ row: item.row, col: item.col, param: setting.parameter, value: setting.value }).then(
        (response) => {
          item.y = response.curve;
          item.type = response.type;
        }
      )
    });
    return Promise.all(promises).then(() => {
      yAxis.setLabel(getYLabel({ value: setting.value, yScale, param: 'Z' }));
      let unit = '';
      xAxis.setUnit("Hz");

      if (setting.value === 'am') {
        if (setting.parameter === 's') {
          unit = '';
        } else {
          unit = setting.parameter === 'y' ? 'Ω⁻¹' : 'Ω';
        }
      } else {
        unit = '';
      }
      yAxis.setUnit(unit);
      this.plotViewer.updateYAxis({ param: setting.parameter, value: setting.value, scale: yScale });
    })
  }

  deleteParam(id) {
    return new Promise((resolve, reject) => {
      deleteParameter({ ids: [id] }).then(res => {
        this.parameters.delete(id);
        resolve(this.parameters);
      })
    })
  }

  cleanParameters = () => {
    this.parameters = new Map();
    this.plotViewer = null;
  }
}

const parameters = new Parameters();
export default parameters;