import React, { PureComponent } from "react";
import { Select } from "antd";
import { DESIGN_FAILED } from '@/constants/designCategory';
import { PCB_CHANNEL, EXPERIMENTS } from "@/constants/treeConstants";
import '@/publicCss/style.css';
import '@/publicCss/debug.css';
import { ANDES_V2 } from "../../constants/pageType";
import { PACKAGE } from "../../constants/designType";
import { PCB_PARSING, REPORT } from "../../services/helper/debugHelper";

const { Option, OptGroup } = Select;

class DebugMenu extends PureComponent {

  selectClick = (e) => {
    e && e.stopPropagation();
  }

  getSelect = ({
    disabled,
    selectItem,
    selectFn,
    className,
    options = [],
    product,
    menuType
  }) => {
    let optioGroup = [];
    if (product === ANDES_V2 && [PCB_PARSING, PCB_CHANNEL, REPORT].includes(menuType)) {
      let pcbList = [], packageList = [];
      for (const option of options) {
        if (option.type === PACKAGE) {
          packageList.push(option);
        } else {
          pcbList.push(option);
        }
      }
      optioGroup = [{ type: 'PCB', children: pcbList }, { type: 'PKG', children: packageList }]
    }
    return <span onClick={(e) => this.selectClick(e)}>
      <Select
        disabled={disabled}
        value={selectItem ? selectItem.id : ""}
        onChange={(value) => selectFn(value)}
        className={`aurora-select aurora-debug-select ${className}`}
        popupClassName='aurora-debug-select-down-drop'
        popupMatchSelectWidth={false}
        getPopupContainer={() => document.getElementById('root')}
        defaultActiveFirstOption={false}
      >
        {/* <Option key={'all'} value={'All'} title={`All ${debugSimulationTitle}`}>All</Option> */}
        {product === ANDES_V2 && [PCB_PARSING, PCB_CHANNEL, REPORT].includes(menuType) ? optioGroup.map(item => (
          <OptGroup key={item.type} label={item.type}>
            {item.children.map(it => (<Option key={it.name} value={it.id} title={it.name}>{it.name}</Option>))}
          </OptGroup>
        )) : options.map(item => (
          <Option
            className={item.category === DESIGN_FAILED && 'aurora-debug-select-failed'}
            key={item.name}
            value={item.id}
            title={item.name}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </span>
  }

  render() {
    const { menuType, debugMenuList, product } = this.props;
    return <div className='aurora-debug-menu'>
      {debugMenuList.map(item => {
        const disabled = menuType !== item.key;
        return <div
          key={item.key}
          className={menuType === item.key ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
          onClick={(e) => this.props.changeDebugItem(e, item.key)}
        >
          <span className='debug-menu-item-span'>{item.title}</span>
          {!disabled ? this.getSelect({
            selectItem: item.info.selectInfo,
            selectFn: (value) => this.props.changeSelectInfo(value, item.key),
            className: "aurora-debug-select-simulation-width",
            options: item.info.selectList || [],
            menuType,
            product
          }) : null}
          {!disabled && [PCB_CHANNEL, EXPERIMENTS].includes(item.key) ? this.getSelect({
            selectItem: item.info.selectChild,
            selectFn: (value) => this.props.changeSelectChild(value, item.key),
            className: "aurora-debug-select-simulation-width",
            options: item.info.selectChildren || [],
            product
          }) : null}
          {!disabled && item.key === EXPERIMENTS ? this.getSelect({
            selectItem: item.info.selectSweep,
            selectFn: (value) => this.props.changeSelectSweep(value, item.key),
            className: "aurora-debug-select-simulation-width",
            options: item.info.selectSweeps || [],
            product
          }) : null}
        </div>
      })}
    </div>
  }
}

export default DebugMenu;