import {
  OPEN_UPLOAD_PROGRESS_MODAL,
  UPLOAD_PROGRESS_CHANGE,
  SHOW_ERROR_MSG,
  CLOSE_UPLOAD_PROGRESS_MODAL,
  TRANSLATION_RUNNING,
  TRANSLATION_FAILED,
  TRANSLATION_SUCCESS,
  TRANSLATION_MONITOR,
  UPLOAD_PROJECT_ID,
  ADD_MSG,
  CURRENT_UPLOAD_DESIGN_ID,
  SAVE_CURRENT_PCB_KEY,
  TRANSLATION_DEBUG,
  CHANGE_DISABLE_STATUS,
  UPDATE_COMPRESS_PROGRESS
} from './actionType';

const defalState = ({
  visible: false,
  uploadProgress: 0,
  transProgress: -1,
  msg: null,
  endMsg: null,
  // 0 - upload,1 - translate
  progressType: null,
  monitor: null,
  uploadProjectId: null,
  uploadDesignId: null,
  currentPCBKey: null,
  debug: null,
  disabled: false,
  compressProgress: -1
})

const PDNUploadReducer = (state = defalState, action) => {
  switch (action.type) {
    case OPEN_UPLOAD_PROGRESS_MODAL:
      return {
        ...state,
        visible: true,
        progressType: 0,
        compressProgress: 0,
        monitor: null,
        msg: '',
        endMsg: '',
      }
    case UPLOAD_PROGRESS_CHANGE:
      const { uploadProgress } = action;
      return {
        ...state,
        uploadProgress,
      }
    case SHOW_ERROR_MSG:
      const { msg } = action;
      return {
        ...state,
        msg,
      }
    case CLOSE_UPLOAD_PROGRESS_MODAL:
      return {
        ...state,
        visible: false,
        uploadProgress: 0,
        transProgress: -1,
        compressProgress: -1,
        msg: null,
        progressType: null,
        monitor: null
      }
    case TRANSLATION_RUNNING:
      const { transProgress } = action;
      return {
        ...state,
        progressType: 1,
        transProgress,
      }
    case TRANSLATION_SUCCESS:
      return {
        ...state,
        visible: false,
        uploadProgress: 0,
        transProgress: -1,
        compressProgress: -1,
        progressType: null,
        endMsg: action.msg
      }
    case TRANSLATION_FAILED:
      return {
        ...state,
        endMsg: action.msg,
        visible: false,
        progressType: null,
        uploadProgress: 0,
        transProgress: -1,
        compressProgress: -1,
      }
    case TRANSLATION_MONITOR:
      return {
        ...state,
        monitor: action.monitor
      }
    case UPLOAD_PROJECT_ID:
      return {
        ...state,
        uploadProjectId: action.projectId,
      }
    case ADD_MSG:
      return {
        ...state,
        msg: state.msg && (state.msg + '\n' + action.msg) || action.msg
      }
    case CURRENT_UPLOAD_DESIGN_ID:
      return {
        ...state,
        uploadDesignId: action.designId,
        uploadWorkflowId: action.workflowId
      }
    case SAVE_CURRENT_PCB_KEY:
      return {
        ...state,
        currentPCBKey: action.key
      }
    case TRANSLATION_DEBUG:
      return {
        ...state,
        debug: action.debug
      }
    case CHANGE_DISABLE_STATUS:
      return {
        ...state,
        disabled: action.disabled
      }
    case UPDATE_COMPRESS_PROGRESS:
      return {
        ...state,
        compressProgress: action.compressProgress
      }
    default: return state
  }
}

export default PDNUploadReducer;