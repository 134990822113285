import { max, min, histogram } from 'd3-array'
import { getFileInPinToPinProject } from './apiCtrl';
import { getFileInSierraPinToPinProject } from '../../api/sierra';
import { getExperimentResultPromise } from '../projectCtrl';
import { IMPEDANCE_DAT } from '../../../pages/Sierra/constants';

let factory = {};
factory.getImpedanceData = getImpedanceData;

function getImpedanceData({ signals, interfaceId, PCBID, projectId, experimentId }) {
  return new Promise((resolve, reject) => {
    let signalList = [];
    for (let signal of signals) {
      var signalInfo = new ImpedanceResultHelper.SignalInfo();
      signalInfo.signalName = signal.name;
      signalInfo.netList = signal.nets.map(net => { return { pcb: net.pcb, net: net.net } });
      signalList.push(signalInfo);
    }
    let impedance = new ImpedanceResultHelper(signalList);
    if (experimentId) {
      impedance.getSweepImpedanceAsPromise(experimentId, PCBID).then(impedance => {
        resolve(impedance);
      }, error => {
        let impedance = new ImpedanceResultHelper.ImpedanceData();
        reject(impedance);
        console.error(error);
      })
    } else {
      impedance.getImpedanceAsPromise(interfaceId, PCBID, projectId).then(impedance => {
        resolve(impedance);
      }, error => {
        let impedance = new ImpedanceResultHelper.ImpedanceData();
        reject(impedance);
        console.error(error);
      });
    }
  })
}

/** ImpedanceResultHelper class constructor * */
function ImpedanceResultHelper(signalInfoList) {
  this.signalInfoList = signalInfoList; // Array of ImpedanceResultHelper.SignalInfo
  this.impedanceData = null;           // ImpedanceResultHelper.ImpedanceData
};

/** Signal definition */
ImpedanceResultHelper.SignalInfo = function () {
  this.signalName = null;
  this.netList = null;
  this.groupName = null;
};

/** Get the impedance result
 *  @param  stackup - a CeLayerStack object containing the layout design stackup
 *  @return a promise to the impedance result. The promise resolves to
 *          a object of ImpedanceResultHelper.ImpedanceData, or null if data is not available.
 */
ImpedanceResultHelper.prototype.getImpedanceAsPromise = getImpedanceAsPromise;
ImpedanceResultHelper.prototype.getSweepImpedanceAsPromise = getSweepImpedanceAsPromise;

// ------ Result data class ------

/** Impedance statistics for each net and each layer, as well as the raw impedance data for all traces */
ImpedanceResultHelper.ImpedanceData = function () {
  this.netStats = []; // Array of ImpedanceResultHelper.NetStatItem, impedance statistics of all nets
  this.layerStats = []; // Array of ImpedanceResultHelper.LayerStatItem, impedance statistics of all layers
  this.netLayerTraces = []; // Array of ImpedanceResultHelper.NetLayerTraceItem, raw impedance data of all traces
};

// ------ Statistical result classes ------

/** The impedance statistics for a net */
ImpedanceResultHelper.NetStatItem = function () {
  this.name = null; // String, name of the signal
  this.layoutNetName = null; // String, layout net name
  this.group = null; // String, net group in the DDR interface
  this.numTrc = null; // Number, number of traces
  this.numNoRef = null; // Number, number of traces without reference plane
  this.Z0 = null; // Number, typical impedance value
  this.minZ0 = null; // Number, minimum impedance value
  this.maxZ0 = null; // Number, maximum impedance value
  this.w = null; // Number, typical trace width
  this.length = null; // Number, total trace length
  this.layerStats = [];   // Array of ImpedanceResultHelper.LayerStatItem, impedance statistics of this net on each layer
  this.signal = null;
};

/** The impedance statistics on a layer */
ImpedanceResultHelper.LayerStatItem = function () {
  this.layer = null; // String, name of the layer
  this.indMetal = null; // Number, index of the this layer in all the metal layers
  this.Er = null; // Number, dielectric constant of the layer
  this.thickness = null; // Number, thickness of the layer
  this.numTrc = null; // Number, number of traces
  this.numNoRef = null; // Number, number of traces without reference plane
  this.Z0 = null; // Number, typical impedance value
  this.minZ0 = null; // Number, minimum impedance value
  this.maxZ0 = null; // Number, maximum impedance value
  this.w = null; // Number, typical trace width
  this.length = null; // Number, total trace length
};

// ------ Raw result classes ------

/** Raw trace impedance result data structure */
ImpedanceResultHelper.NetLayerTraceItem = function () {
  this.netName = null;  // String, net name
  this.layerTraces = [];    // Array of ImpedanceResultHelper.LayerTraceItem, one for each net
};

/** Raw trace impedance result data structure -- trace impedance list on a layer */
ImpedanceResultHelper.LayerTraceItem = function () {
  this.layerName = null;    // String, layer name
  this.traces = [];      // Array of ImpedanceResultHelper.TraceItem, one for each layer
};

/** Raw trace impedance result data structure -- trace impedance */
ImpedanceResultHelper.TraceItem = function () {
  this.ind = null; // Number, net object index in its layer
  this.Z0 = null; // Number, trace characteristics impedance
  this.lowerPlane = null; // String, lower reference plane name
  this.upperPlane = null; // String, upper reference plane name
  this.h1 = null; // Number, dielectric height above the trace
  this.h2 = null; // Number, dielectric height below the trace
  this.w = null; // Number, trace width
  this.x1 = null; // Number, x coordinate, starting point
  this.y1 = null; // Number, y coordinate, starting point
  this.x2 = null; // Number, x coordinate, ending point
  this.y2 = null; // Number, y coordinate, ending point
  this.length = null; // Number, trace length derived from the coordinates
}

// ------------------------------ Implementation Details ------------------------------

/** ------ implementation of ImpedanceResultHelper.prototpye.getImpedanceAsPromise ------
 *  Get the impedance result
 *  @param  stackup - a CeLayerStack object containing the layout design stackup
 *  @return a promise to the DDR impedance result. The promise resolves to
 *          a object of ImpedanceResultHelper.ImpedanceData, or null if data is not available.
 */
function getImpedanceAsPromise(interfaceId, designId, projectId) {
  var self = this;
  return new Promise((resolve, reject) => {
    if (self.impedanceData) {
      resolve(self.impedanceData);
    } else {
      processImpedance(self, interfaceId, designId, projectId).then(res => {
        resolve(self.impedanceData);
      }, error => {
        reject(error)
      })
    }
  });
}

function getSweepImpedanceAsPromise(experimentId, designId) {
  return new Promise((resolve, reject) => {
    if (experimentId) {
      return getExperimentResultPromise({ experimentId, option: IMPEDANCE_DAT, designId }).then(response => {
        let rawImpedanceData = parseImpedanceFile(response);
        let impedanceData = processImpedanceData(rawImpedanceData.netLayerTraces, this.signalInfoList);
        resolve(impedanceData);
      }, error => {
        reject(error);
      })
    } else {
      reject("No Experiment Id")
    }
  })
}

function processImpedance(self, interfaceId, PCBID, projectId) {
  return new Promise((resolve, reject) => {
    if (self.impedanceData) {
      resolve(self.impedanceData);
    } else {
      //Impedance - path={verification_subid}/result/data/PCB{subid}_Impedance.dat
      const pathID = `${interfaceId}/result/data`;
      const fileName = `PCB${PCBID}_Impedance.dat`;
      if (projectId) {
        getFileInSierraPinToPinProject(projectId, `${pathID}/${fileName}`).then(response => {
          var rawImpedanceData = parseImpedanceFile(response.data);
          self.impedanceData = processImpedanceData(rawImpedanceData.netLayerTraces, self.signalInfoList);
          resolve(self.impedanceData)
        }, error => {
          reject(error)
        });
      } else {
        getFileInPinToPinProject(`${pathID}/${fileName}`).then(response => {
          var rawImpedanceData = parseImpedanceFile(response.data);
          self.impedanceData = processImpedanceData(rawImpedanceData.netLayerTraces, self.signalInfoList);
          resolve(self.impedanceData)
        }, error => {
          reject(error)
        });
      }
    }
  });
}

/** Read the impedance file and convert it into a trace impedance data array
 *  @param  fileContent - content of the impedance file as a String
 *  @return an object containg: an Array of ImpedanceResultHelper.NetLayerTraceItem
 */
function parseImpedanceFile(fileContent) {

  // the file parsing code is temporary since the output format might
  // need to be adjusted to improve the efficiency
  var lineBuffer = fileContent.match(/[^\r\n]+/g);

  var netLayerTraces = [];

  var netName = null;              // String, name of a single net (block)
  var layerName = null;            // String, name of the trace or diff pair layer (block)
  var netLayerTraceItem = null;    // ImpedanceResultHelper.NetLayerTraceItem, all traces of a net in all layers
  var layerTraceItem = null;       // ImpedanceResultHelper.LayerTraceItem, all traces of a net in a single layer

  while (lineBuffer.length) {

    // read a line
    var line = lineBuffer.shift().trim();

    if (line.length == 0)
      continue;
    var words = line.match(/[^\s\t]+/g);

    if (words.length == 2) {
      // a starting line, either a new net or a new layer
      if (!netName) {

        // the first word is the net name
        netName = words[0];
        netLayerTraceItem = new ImpedanceResultHelper.NetLayerTraceItem();
        netLayerTraceItem.netName = netName;
      } // if (!netName)
      else {

        // the first word is the layer name
        layerName = words[0];
        layerTraceItem = new ImpedanceResultHelper.LayerTraceItem();
        layerTraceItem.layerName = layerName;
      } // if (!netName) ... else ...

    } // if (words.length == 2)
    else if (words.length == 1) {

      // this can only be '}'
      if (layerName) {
        // end of a layer block, add the traces on the layer to the net
        netLayerTraceItem.layerTraces.push(layerTraceItem);
        layerName = null;
      }
      else if (netName) {
        // end of a net block, add the traces of this net to net trace list
        netLayerTraces.push(netLayerTraceItem);
        netName = null;
      }

    } // if (words.length == 2) ... else if (words.length == 1) ...
    else {

      // this is a trace impedance line, example:
      // 5       39.477886       Lower Plane: NULL    h1 = 4.14   Upper Plane: Layer7     h2 = 5.9        Width = 15.748  ( 1082.677165, 777.559055 )     ( 1082.677165, 812.992126 )
      // h1 and h2 could be not in the string
      var traceItem = new ImpedanceResultHelper.TraceItem();
      traceItem.ind = Number(words[0]);                        // net object index in its layer
      traceItem.Z0 = Number(words[1]);                        // trace characteristics impedance
      traceItem.lowerPlane = words[4] == 'NULL' ? null : words[4];    // lower reference plane name
      //Deal with the situation that there is not 'h1'
      var index = 5;
      if (words[index] === "h1") {
        traceItem.h1 = Number(words[index + 2]);                      // dielectric height above the trace
        index = index + 3;
      }
      traceItem.upperPlane = words[index + 2] == 'NULL' ? null : words[index + 2];    // upper reference plane name
      index = index + 3;
      //Deal with the situation that there is not 'h1'
      if (words[index] === "h2") {
        traceItem.h2 = Number(words[index + 2]);                      // dielectric height below the trace
        index = index + 3;
      }

      traceItem.w = Number(words[index + 2]);                       // trace width
      traceItem.x1 = Number((words[index + 4] || "").split(',')[0]);         // x coordinate, starting point
      traceItem.y1 = Number(words[index + 5]);                       // y coordinate, starting point
      traceItem.x2 = Number((words[index + 8] || "").split(',')[0]);         // x coordinate, ending point
      traceItem.y2 = Number(words[index + 9])                        // y coordinate, ending point

      traceItem.length = Math.sqrt((traceItem.x1 - traceItem.x2) * (traceItem.x1 - traceItem.x2) +
        (traceItem.y1 - traceItem.y2) * (traceItem.y1 - traceItem.y2));

      layerTraceItem.traces.push(traceItem);


    } // if (words.length == 2) ... else if (word.length == 1) ... else ...

  } // while (lineBuffer.length)

  return {
    netLayerTraces: netLayerTraces,
  };
} // parseImpedanceFile

/** Help class for generating trace statistics */
function TraceStats() {
  this.traceList = []; // traces covered by at least one reference plane
  this.badTraceList = []; // traces without reference plane
}
TraceStats.prototype.addTrace = TraceStats_addTrace;
TraceStats.prototype.getStatistics = TraceStats_getStatistics;

/** TraceStats.prototype.addTrace
 *  @param traceItem - an object of ImpedanceResultHelper.TraceItem
 */
function TraceStats_addTrace(traceItem) {
  if (traceItem.lowerPlane || traceItem.upperPlane)
    this.traceList.push(traceItem);
  else
    this.badTraceList.push(traceItem);
}

/** TraceStats.prototype.getStatistics - Generate the statistics of a group of traces */
function TraceStats_getStatistics() {

  var statistics = {
    numTrc: this.traceList.length + this.badTraceList.length,  // total number of traces
    Z0: null,                                              // typical trace characteristics impedance of this layer
    minZ0: null,                                              // minimum trace characteristics impedance
    maxZ0: null,                                              // maximum trace characteristics impedance
    w: null,                                              // typical trace width
    length: null,                                              // total trace length
    numNoRef: this.badTraceList.length
  };

  if (this.traceList.length > 0) {

    statistics.minZ0 = min(this.traceList, function (trace) { return trace.Z0; });
    statistics.maxZ0 = max(this.traceList, function (trace) { return trace.Z0; });

    // find the typical impedance from histogram
    var zHist = histogram().value(function (trace) { return trace.Z0; })(this.traceList);
    var maxZ0Count = max(zHist, function (hist) { return hist.length; });
    for (var iHist in zHist)
      if (zHist[iHist].length == maxZ0Count)
        statistics.Z0 = zHist[iHist][0].Z0;

    // find the typical width from histogram
    var wHist = histogram().value(function (trace) { return trace.w; })(this.traceList);
    var maxWidCount = max(wHist, function (hist) { return hist.length; });
    for (var iHist in wHist)
      if (wHist[iHist].length == maxWidCount)
        statistics.w = wHist[iHist][0].w;

    // find the total trace length
    statistics.length = 0;
    for (var iTrace in this.traceList)
      statistics.length += this.traceList[iTrace].length;

  } // if (this.traceList.length > 0)

  return statistics;
} //TraceStats_getStatistics

/** Calculate the per-net and per-layer statistics from the raw impedance data     
 *  @param  netLayerTraces   - Array of ImpedanceResultHelper.NetLayerTraceItem
 *  @param  stackup          - a CeLayerStack object containing the layout design stackup
 *  @param  signalInfoList   - Array of ImpedanceResultHelper.SignalInfo containing singal definitions
 *  @return a ImpedanceResultHelper.ImpedanceData object with raw and statistical data
 */
function processImpedanceData(netLayerTraces, signalInfoList) {
  // initialize the results
  var netNameList = [];
  var perNetStats = [];
  var layerNameList = [];
  var perLayerStats = [];             // statistics for all nets on each layer

  for (let signal of signalInfoList) {
    signal.netList.forEach(item => {
      if (netNameList.map(item => item.net).indexOf(item.net) < 0) {
        netNameList.push({ net: item.net, signal: signal.signalName });
        perNetStats.push({
          netStat: new TraceStats(),  // statistics for this net on all layers
          layersStats: [],             // statistics for this net on each layer
        })
      }

    })
  } // for (var iNet in signalInfoList)

  function getNetIndex(name) {
    const index = netNameList.map(item => item.net).indexOf(name);
    return index;
  }

  for (let net of netLayerTraces) {
    net.layerTraces.forEach(layer => {
      if (layerNameList.indexOf(layer.layerName) < 0) {
        layerNameList.push(layer.layerName);
      }
    })
  }

  for (let iLayer of layerNameList) {

    perLayerStats.push(new TraceStats());

    for (let iNet of perNetStats) {
      iNet.layersStats.push(new TraceStats());
    }

  }

  // loop through all the nets
  for (let netLayerTraceItem of netLayerTraces) {

    var iNet = getNetIndex(netLayerTraceItem.netName);

    if (iNet < 0) continue;

    // loop through all the layers under this net
    for (let layerTraceItem of netLayerTraceItem.layerTraces) {

      var iLayer = layerNameList.indexOf(layerTraceItem.layerName);

      if (iLayer < 0)
        continue;

      // add this trace to appropriate statistics list
      for (let trace of layerTraceItem.traces) {
        perNetStats[iNet].netStat.addTrace(trace);
        perNetStats[iNet].layersStats[iLayer].addTrace(trace);
        perLayerStats[iLayer].addTrace(trace);
      }
    }
  }

  // generate the statistics table 
  var impedanceData = new ImpedanceResultHelper.ImpedanceData();
  impedanceData.netLayerTraces = netLayerTraces;

  // per net trace statistics
  perNetStats.forEach((iNet, index) => {

    // statistics of this net on all layers
    var stats = iNet.netStat.getStatistics();

    var netStatItem = new ImpedanceResultHelper.NetStatItem();
    netStatItem.name = netNameList[index].net;
    netStatItem.layoutNetName = netNameList[index].net;
    netStatItem.signal = netNameList[index].signal;
    // netStatItem.group = signalInfoList[iNet].group;
    netStatItem.numTrc = stats.numTrc;
    netStatItem.numNoRef = stats.numNoRef;
    netStatItem.Z0 = stats.Z0;
    netStatItem.minZ0 = stats.minZ0;
    netStatItem.maxZ0 = stats.maxZ0;
    netStatItem.w = stats.w;
    netStatItem.length = stats.length;

    // per-layer statistics of the net
    for (var iLayer in layerNameList) {

      stats = iNet.layersStats[iLayer].getStatistics();
      if (stats.numTrc > 0) {
        var layerStatItem = new ImpedanceResultHelper.LayerStatItem();
        layerStatItem.layer = layerNameList[iLayer];
        layerStatItem.numTrc = stats.numTrc;
        layerStatItem.numNoRef = stats.numNoRef;
        layerStatItem.Z0 = stats.Z0;
        layerStatItem.minZ0 = stats.minZ0;
        layerStatItem.maxZ0 = stats.maxZ0;
        layerStatItem.w = stats.w;
        layerStatItem.length = stats.length;

        netStatItem.layerStats.push(layerStatItem);
      } // if (stats.numTrc > 0)

    } // for (var iLayer in layerNameList)

    impedanceData.netStats.push(netStatItem);
  })

  // per-layer trace 
  for (var iLayer in layerNameList) {

    var layerStatItem = new ImpedanceResultHelper.LayerStatItem();
    layerStatItem.layer = layerNameList[iLayer]
    layerStatItem.indMetal = iLayer > -1 ? iLayer : null;
    // layerStatItem.Er = iLayer > -1 ? null : stackupLayers[iStackup].getPermittivity();
    // layerStatItem.thickness = stackupLayers[iStackup].getLayerThickness();

    // for metal layers, check the trace statistics
    if (iLayer >= 0) {

      var stats = perLayerStats[iLayer].getStatistics();
      if (stats.numTrc > 0) {
        layerStatItem.numTrc = stats.numTrc;
        layerStatItem.Z0 = stats.Z0;
        layerStatItem.minZ0 = stats.minZ0;
        layerStatItem.maxZ0 = stats.maxZ0;
        layerStatItem.w = stats.w;
        layerStatItem.length = stats.length;
        layerStatItem.numNoRef = stats.numNoRef;
      }

    } // if (iLayer >= 0)

    impedanceData.layerStats.push(layerStatItem);

  } // for (var iStackup in stackupLayers)

  return impedanceData;
} // processImpedanceData

export default factory;