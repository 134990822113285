import {
  OPEN_PAGE, SAVE_OPEN_PROJECT_INFO, UPDATE_PROJECT_MENU,
  LIBRARY_MENU, OPEN_PROJECT, PROJECT_MENU, CHANGE_LAYOUT,
  CLEAR_CURRENT_PROJECT, UPDATE_TRASH_MENU, TRASH_MENU, CHANGE_VIEW_LIST,
  SAVE_COMPONENTS_NETS_IN_PROJECT, UPDATE_PCBS_LAYOUTDB, CHANGE_TREE_SELECTED_KEY,
  SHOW_SIERRA_RESULT, AUTO_GET_VERIFICATION_LIST, UPDATE_VIEW_STATUS, REFRESH_PCB,
  UPDATE_CURRENT_VERIFICATION_STATUS, SAVE_SWEEP_NAME,
  DOWNLOAD_DEBUG_MSG, DEBUG_DOWNLOAD_LOG_UPDATE, DOWNLOAD_DEBUG_MSG_CLOSE, UPDATE_DOWNLOAD_MSG,
  UPDATE_COPY_LIST, LOAD_SWEEP_LIST, UPDATE_GENERATE_PORTS_ERRORS,
  UPDATE_PCB_LAYOUT, DELETE_PCB, UPDATE_PRELAYOUT_DATA, OPEN_NEW_PAGE, UPDATE_REPORT_INFO,
  CLEAR_REPORT_INFO,
  SAVE_REPORT_CONFIG,
  GET_REPORT_CONFIG,
  GET_REPORT,
  GET_REPORT_LOG
} from './actionTypes';

export const openPage = (view, info) => ({
  type: OPEN_PAGE,
  view,
  ...info
});

export const saveOpenProjectInfo = ({ id, name, verifications }) => ({
  type: SAVE_OPEN_PROJECT_INFO,
  id,
  name,
  verifications
});

export const updateProjectMenu = (obj) => ({
  type: UPDATE_PROJECT_MENU,
  obj
});

export const libraryMenu = ({ treeItems }) => ({
  type: LIBRARY_MENU,
  treeItems
});

export const openProject = (id) => ({
  type: OPEN_PROJECT,
  id
});

export const projectMenu = ({ treeItems, projectList, copyProjectList, verifications }) => ({
  type: PROJECT_MENU,
  treeItems,
  projectList,
  copyProjectList,
  verifications
});

export const changeLayout = (layout, singleType) => ({
  type: CHANGE_LAYOUT,
  layout,
  singleType
});

export const clearCurrentProject = (status) => ({
  type: CLEAR_CURRENT_PROJECT,
  status
});

export const updateTrashMenu = () => ({
  type: UPDATE_TRASH_MENU
});

export const trashMenu = ({ treeItems }) => ({
  type: TRASH_MENU,
  treeItems
});

export const changeViewList = (viewList, viewType) => ({
  type: CHANGE_VIEW_LIST,
  viewList,
  viewType
});

export const saveComponentsNetsInProject = (pcbIds, status) => ({
  type: SAVE_COMPONENTS_NETS_IN_PROJECT,
  pcbIds,
  status
});

export const updatePCBComponentsNets = (pcbsLayoutDB) => ({
  type: UPDATE_PCBS_LAYOUTDB,
  pcbsLayoutDB
});

export const updatePreLayoutNets = (prelayoutData) => ({
  type: UPDATE_PRELAYOUT_DATA,
  prelayoutData
})

export const changeTreeSelected = (selectedKeys) => ({
  type: CHANGE_TREE_SELECTED_KEY,
  selectedKeys
});

export const showResult = (view, info) => ({
  type: SHOW_SIERRA_RESULT,
  view,
  ...info
});

export const autoGetVerificationList = (projectId, currentSimInfo) => ({
  type: AUTO_GET_VERIFICATION_LIST,
  projectId,
  currentSimInfo
});

export const updateViewStatus = ({ view, verificationId, getStatus, verificationName }) => ({
  type: UPDATE_VIEW_STATUS,
  view,
  verificationId,
  getStatus,
  verificationName
});

export const refreshPCB = (refreshStatus) => ({
  type: REFRESH_PCB,
  refreshStatus
});

export const updateCurrentVerificationStatus = (verificationStatus) => ({
  type: UPDATE_CURRENT_VERIFICATION_STATUS,
  verificationStatus
});

export const downloadDebugMsg = ({ project, fileName, verificationId, downloadType }) => ({
  type: DOWNLOAD_DEBUG_MSG,
  project,
  fileName,
  verificationId,
  downloadType
});

export const debugDownloadLog = (debugDownloadMsg) => ({
  type: DEBUG_DOWNLOAD_LOG_UPDATE,
  debugDownloadMsg
});

export const closeDebugDownloadMsg = () => ({
  type: DOWNLOAD_DEBUG_MSG_CLOSE
});

export const updateDownloadMsg = (msg) => ({
  type: UPDATE_DOWNLOAD_MSG,
  msg
})

export const updateCopyList = (copyProjectList) => ({
  type: UPDATE_COPY_LIST,
  copyProjectList
})

export const updateGeneratePortsErrors = (errorObj) => ({
  type: UPDATE_GENERATE_PORTS_ERRORS,
  errorObj
});

export const loadSweepList = (verificationId) => ({
  type: LOAD_SWEEP_LIST,
  verificationId
})

export const saveSweepName = (name, info) => ({
  type: SAVE_SWEEP_NAME,
  name, info
})

export const updatePCBLayout = (pcbLayout, prePcbLayout) => ({
  type: UPDATE_PCB_LAYOUT,
  pcbLayout,
  prePcbLayout
});

export const deletePCB = (item) => ({
  type: DELETE_PCB,
  item
});

export const openNewPage = ({ pageType, id }) => ({
  type: OPEN_NEW_PAGE,
  pageType,
  id
});

export const updateReportInfo = (info) => ({
  type: UPDATE_REPORT_INFO,
  info
});

export const clearReportInfo = () => ({
  type: CLEAR_REPORT_INFO
});

export const saveReportConfig = (verificationIds) => ({
  type: SAVE_REPORT_CONFIG,
  verificationIds
});

export const getReportConfig = ({ projectId, verificationId }) => ({
  type: GET_REPORT_CONFIG,
  projectId,
  verificationId
});

export const getReport = ({ projectId, format, fileName }) => ({
  type: GET_REPORT,
  projectId,
  format,
  fileName
});

export const getReportLogMsg = (projectId) => ({
  type: GET_REPORT_LOG,
  projectId
});