class MultiSetupBufferDataBase {
  constructor() {
    this.AllData = [];
    this.pageData = new Map();
  }

  clear = () => {
    this.AllData = [];
    this.pageData = new Map();
  }

  getPage = (page) => {
    return this.pageData.get(page) || [];
  }

  initData = (data, pageSize) => {
    this.AllData = data;
    this.pageData = new Map();
    this.resetPage(pageSize || 20)
  }

  getData = () => {
    return this.AllData;
  }

  resetPage = (pageSize) => {
    pageSize = pageSize || 20;
    let pages = Math.ceil(this.AllData.length / pageSize);
    for (let i = 1; i <= pages; i++) {
      const currPageData = this.AllData.filter((item, index) => index >= (i - 1) * pageSize && index < i * pageSize)
      this.pageData.set(i, currPageData);
    }
    this.pages = pages;
  }

  setPage = (page, data) => {
    this.pageData.set(page, data);
  }

  getPages = () => {
    return this.pages;
  }

  getAllDataLength = () => {
    return this.AllData.length;
  }

  setData = (data) => {
    this.AllData = data;
  }
}

export const multiSetupPassiveDataBase = new MultiSetupBufferDataBase();

const multiSetupBufferDataBase = new MultiSetupBufferDataBase();
export default multiSetupBufferDataBase;