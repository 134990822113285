import { ANDES_V2, CASCADE, FASTPI } from "../../constants/pageType";

const MONITOR = "monitor", PCB = "pcb", PROFILE = "profile", DETAIL = "detail", CCC = "ccc", SERVICE = "service", LAYOUT = 'layout';
const menuList = [
  {
    name: 'Message',
    index: MONITOR,
  },
  {
    name: 'PCB',
    index: PCB,
  },
  {
    name: 'Layout',
    index: LAYOUT
  },
  {
    name: 'Profile',
    index: PROFILE,
  },
  {
    name: 'Detail',
    index: DETAIL,
  },
  {
    name: 'Service',
    index: SERVICE
  },
  {
    name: 'CCC',
    index: CCC
  }
];

function getMenuListByPageType(pageType, hasCCC) {
  //Filter ccc
  const _menuList = !hasCCC ? menuList.filter(item => ![CCC].includes(item.index)) : menuList;
  switch (pageType) {
    case ANDES_V2:
      return _menuList.filter(item => ![DETAIL, LAYOUT].includes(item.index));
    case CASCADE:
      return _menuList;
    case FASTPI:
      return _menuList.filter(item => ![PCB].includes(item.index))
    default: return _menuList.filter(item => ![PCB, LAYOUT].includes(item.index));
  }
}

export {
  getMenuListByPageType,
  menuList,
  MONITOR,
  PCB,
  PROFILE,
  DETAIL,
  CCC,
  SERVICE,
  LAYOUT
}