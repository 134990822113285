import React, { Component, createRef, Fragment } from "react";
import { createPortal } from 'react-dom';
import { Select, Input } from "antd";
import Panel from '@/components/Panel';
import Table from '../../../components/EditableTable';
import { CONNECTOR, LOAD, PMIC } from '../../../constants/componentType';
import { numberCheck } from '../../../services/helper/dataProcess';
import './index.css';

const Option = Select.Option;
const pinColumn = [{
  dataIndex: 'pin',
  key: 'pin',
  title: 'Pin',
  width: '50%'
}, {
  dataIndex: 'net',
  key: 'net',
  title: 'Net',
  width: '50%'
}]
const compType = [CONNECTOR, PMIC, LOAD]
class DcInfo extends Component {
  constructor(props) {
    super(props)
    this.dialogRoot = document.getElementById('root');

    this.state = {
      error: ''
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  titleRender = () => {
    const { drawerKey, data } = this.props;
    const { name } = data;
    switch (drawerKey) {
      case 'comp':
        return `${name} - Component Information`;
      case 'net':
        return `${name} - Net Information`;
      default:
        return ''
    }
  }

  contentRender = () => {
    const { drawerKey } = this.props;
    switch (drawerKey) {
      case 'comp':
        return this.compRender();
      case 'net':
        return this.netRender();
      default:
        return null
    }
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  checkName = (e) => {
    const newName = e.target.value;
    const error = this.props.checkName(newName);

    if (error) {
      this.setState({
        error
      })
      return;
    }

    this.setState({
      error: ''
    })

    this.props.changeInfo(newName, 'name')
  }

  checkValue = (e) => {
    const newValue = e.target.value;
    const error = numberCheck(newValue);

    if (error) {
      this.setState({
        error
      })
      return;
    }

    this.setState({
      error: ''
    })

    this.props.changeInfo(newValue, 'value')
  }

  close = () => {
    const { error } = this.state;

    if (error) {
      return;
    }

    this.props.onClose();
  }

  compRender = () => {
    const { data } = this.props;
    const { name, type, pins } = data;
    return <Fragment>
      <div className='cascade-dc-prelayout-drawer-content'>
        <span className='cascade-dc-prelayout-drawer-content-title'>Name</span>
        <Input className='cascade-dc-prelayout-drawer-content-input'
          defaultValue={name}
          onBlur={this.checkName}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='cascade-dc-prelayout-drawer-content'>
        <span className='cascade-dc-prelayout-drawer-content-title'>Type</span>
        <Select
          className='cascade-dc-prelayout-drawer-content-input'
          value={type}
          onChange={(value) => this.props.changeInfo(value, 'type')}
          getPopupContainer={() => document.getElementById(`cascade-dc-prelayout-drawer`)}
        >
          {compType.map(type => <Option key={type}>{type}</Option>)}
        </Select>
      </div>
      <div className='cascade-dc-prelayout-drawer-content'>
        <span className='cascade-dc-prelayout-drawer-content-title'>Pins</span>
        <Table
          columns={pinColumn}
          dataSource={pins}
          rowKey="pin"
          size="small"
        />
      </div>
    </Fragment>
  }

  netRender = () => {
    const { data } = this.props;
    const { name, value, components } = data;
    return <Fragment>
      <div className='cascade-dc-prelayout-drawer-content'>
        <span className='cascade-dc-prelayout-drawer-content-title'>Name</span>
        <Input className='cascade-dc-prelayout-drawer-content-input'
          defaultValue={name}
          onBlur={this.checkName}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='cascade-dc-prelayout-drawer-content'>
        <span className='cascade-dc-prelayout-drawer-content-title'>Resistance</span>
        <Input className='cascade-dc-prelayout-drawer-content-input'
          defaultValue={value}
          onBlur={this.checkValue}
          onPressEnter={this.onPressEnter}
          addonAfter={'mΩ'}
        />
      </div>
      <div className='cascade-dc-prelayout-drawer-content'>
        <span className='cascade-dc-prelayout-drawer-content-title'>Components</span>
        <Input className='cascade-dc-prelayout-drawer-content-input'
          defaultValue={components.join(', ')}
          disabled={true}
        />
      </div>
    </Fragment>
  }

  renderDialog = () => {
    const { error } = this.state;
    const content = (
      <Panel
        title={this.titleRender()}
        onCancel={this.close}
        zIndex={2000}
        position='panel-center-left'
        draggable
        minHeight={220}
        minWidth={400}
        width={500}
        maxWidth={500}
        className='cascade-dc-prelayout-drawer'
        id='cascade-dc-prelayout-drawer'
      >
        <div className="cascade-dc-prelayout-drawer-box">
          {this.contentRender()}
          {error && <span className="cascade-dc-pre-drawer-error-msg">Error: {error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    return (
      <Fragment>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default DcInfo;
