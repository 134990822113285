import http from "./http";

export function getComponentSetting({ designId }) {
  return http.get(`/my-project/component/setting?designId=${designId}`);
}

/**
 * 2022.10.31
 * @export componentSettings = { designId, componentSetting }
 * @export componentSetting = { compPrefixLib, version }
 * @returns
 */
export function updateComponentSetting(componentSettings) {
  return http.post(`/my-project/component/setting`, componentSettings)
}

export function updateDoNotStuff(componentSettings) {
  return http.post(`/my-project/component/doNotStuff`, componentSettings)
}

export function getDoNotStuff(designId) {
  return http.get(`/my-project/component/doNotStuff?designId=${designId}`)
}

export function getPartByProject(projectId) {
  return http.get(`/my-project/all/parts?projectId=${projectId}`)
}

export function getComponentTable({ designId }) {
  return http.get(`/my-project/component/table?designId=${designId}`);
}

/**
 * 2022.07.26
 * @export componentTables = { designId, componentTable }
 * @export componentTable = { table, version }
 * @returns
 */
export function updateComponentTable(componentTables) {
  return http.post(`/my-project/component/table`, componentTables)
}

export function getComponentPinMap({ designId }) {
  return http.get(`/my-project/component/map?designId=${designId}`);
}

/**
 * 2022.07.26
 * @export componentPinMap = { designId, pinMap }
 * @export pinMap = { map, version }
 * @returns
 */
export function updateComponentPinMap(componentPinMap) {
  return http.post(`/my-project/component/map`, componentPinMap)
}

export function uploadDoNotStuffFile({ designId, file, type }) {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('designId', designId);
  formData.append('type', type);
  return http.post(`/my-project/component/doNotStuff/upload`,
    formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function downloadDoNotStuffFile(designId) {
  return http.get(`/my-project/design/dns/file?designId=${designId}`)
}

/**
 * 2023/01/26
 * @export powerTables = [{ designId, nets }]
 * @export nets = [{ net, voltage }]
 * @returns
 */
export function updatePowerNetsTable(powerTables) {
  return http.post(`/my-project/power/nets`, powerTables)
}

export function getPowerNetsTable({ designId }) {
  return http.get(`/my-project/power/nets?designId=${designId}`);
}

export function getPMICtype(partNumber) {
  return http.get(`/pmic/type/part?partNumber=${partNumber}`)
}