import React, { Component, Fragment } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import projectDesigns from '@/services/helper/projectDesigns';
import { IMPEDANCE_PACKAGE, IMPEDANCE_PCB } from '../../../services/Cascade/constants';
import { LAYOUT } from '../../../constants/designType';
import { splitArrayToArrays } from '../../../services/helper/arrayHelper';
import { AC } from '../../../constants/treeConstants';

const { Option, OptGroup } = Select;

class PCBSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pcbList: [],
      packageList: []
    }
  }

  PCBSelect = (value) => {
    const { pcbList } = this.state;
    const designType = pcbList.find(item => item.id === value) ? IMPEDANCE_PCB : IMPEDANCE_PACKAGE
    this.props.selectPCB(value, designType)
  }

  componentDidMount = () => {
    const { includePkg, projectId, hasPreLayout } = this.props;
    const pcbList = projectDesigns.getAvailablePostLayouts(projectId);
    const packageList = includePkg ? projectDesigns.getAvailablePackages(projectId) : [];
    const preLayoutList = projectDesigns.getAvailablePreLayouts(projectId).filter(design => design && design.content && (!design.content.electric || design.content.electric === AC));
    const preLayoutRule = (item) => {
      return item.type === LAYOUT ? true : false;
    }
    const { trueArray: preLayoutPCBs, falseArray: preLayoutPackages } = splitArrayToArrays({ array: preLayoutList, rule: preLayoutRule })
    this.setState({
      pcbList: hasPreLayout ? [...pcbList, ...preLayoutPCBs] : pcbList,
      packageList: includePkg ? hasPreLayout ? [...packageList, ...preLayoutPackages] : packageList : []
    })
  }

  componentDidUpdate = () => {
    const { includePkg, projectId, hasPreLayout } = this.props;
    const { pcbList, packageList } = this.state;
    const _pcbList = projectDesigns.getAvailablePostLayouts(projectId);
    const _packageList = includePkg ? projectDesigns.getAvailablePackages(projectId) : [];
    const preLayoutList = projectDesigns.getAvailablePreLayouts(projectId).filter(design => design && design.content && (!design.content.electric || design.content.electric === AC));
    const preLayoutRule = (item) => {
      return item.type === LAYOUT ? true : false;
    }
    const { trueArray: preLayoutPCBs, falseArray: preLayoutPackages } = splitArrayToArrays({ array: preLayoutList, rule: preLayoutRule })
    if ((hasPreLayout ? [..._pcbList, ...preLayoutPCBs] : _pcbList).length !== pcbList.length
      || (includePkg && (hasPreLayout ? [..._packageList, ...preLayoutPackages] : _packageList).length !== packageList.length)) {
      this.setState({
        pcbList: hasPreLayout ? [..._pcbList, ...preLayoutPCBs] : _pcbList,
        packageList: includePkg ? hasPreLayout ? [..._packageList, ...preLayoutPackages] : _packageList : []
      })
    }
  }

  render() {
    const { pcbId, noPCB, designStatus, includePkg, designType, designName, disabled } = this.props;
    const { pcbList, packageList } = this.state;
    const title = designType === IMPEDANCE_PACKAGE ? 'Package' : 'PCB';
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color-only">{title}</span>
        <Select
          value={designStatus ? 'Loading PCB...' : noPCB ? designName || '' : pcbId}
          style={{ width: 240, marginLeft: 20 }}
          onChange={this.PCBSelect}
          getPopupContainer={((trigger) => trigger.parentNode || document.getElementById('root'))}
          dropdownStyle={{ zIndex: 200 }}
          showSearch={true}
          disabled={disabled || designStatus}
        >
          <OptGroup label='PCB'>
            {pcbList.map(option => {
              return <Option key={option.name} title={option.name} value={option.id}>{option.name}</Option>
            })}
          </OptGroup>
          {includePkg && packageList.length ? <OptGroup label='Package'>
            {packageList.map(option => {
              return <Option key={option.name} title={option.name} value={option.id}>{option.name}</Option>
            })}
          </OptGroup> : null}
        </Select>
        {noPCB && <Tooltip title="Can't get current PCB." overlayClassName='aurora-error-tooltip'>
          <ExclamationCircleOutlined className="cascade-refresh-icon" style={{ color: '#ff0000' }} />
        </Tooltip>}
      </Fragment>
    );
  }
}

export default PCBSelect;