import {
  getAndesV2SweepListByChannelId,
  updateAndesV2SweepConfigs,
  createAndesV2SweepConfigs,
  getAndesV2NetListByChannelId,
  getAndesV2SweepConfigs,
  createAndesV2SweepContent,
  getAndesV2ExperimentsBySweepId,
  deleteAndesV2SweepBySweepId,
  deleteAndesV2SweepContentById,
  updateAndesV2SweepContent,
  getAndesV2VariablesBySweepId,
  updateAndesV2VariablesBySweepId,
  startAndesV2SweepExtraction,
  getAndesV2ExperimentExtractionLog,
  getAndesV2SweepListByProjectId
} from "../../api/Andes_v2/sweep";
import apiProcess from "../../api/utility";

function createSweepConfigsPromise(content) {
  return apiProcess(createAndesV2SweepConfigs, content);
}

function updateSweepConfigsPromise(content) {
  return apiProcess(updateAndesV2SweepConfigs, content);
}

function getSweepListByChannelIdPromise(channelId) {
  return apiProcess(getAndesV2SweepListByChannelId, channelId);
}

function getNetListByChannelIdPromise(channelId) {
  return apiProcess(getAndesV2NetListByChannelId, channelId);
}

function getSweepConfigsPromise(sweepId) {
  return apiProcess(getAndesV2SweepConfigs, sweepId)
}

function createSweepContentPromise(content) {
  return apiProcess(createAndesV2SweepContent, content);
}

function getExperimentsBySweepIdPromise(sweepId) {
  return apiProcess(getAndesV2ExperimentsBySweepId, sweepId)
}

function getVariablesBySweepIdPromise(sweepId) {
  return apiProcess(getAndesV2VariablesBySweepId, sweepId)
}

function updateVariablesBySweepIdPromise(sweepId, content) {
  return apiProcess(updateAndesV2VariablesBySweepId, { sweepId, content })
}

function deleteSweepBySweepIdPromise(sweepId) {
  return apiProcess(deleteAndesV2SweepBySweepId, sweepId)
}

function deleteSweepContentByIdPromise(id) {
  return apiProcess(deleteAndesV2SweepContentById, id)
}

function updateSweepContentPromise(content, sweepId) {
  return apiProcess(updateAndesV2SweepContent, { content, sweepId })
}

function startSweepExtractionPromise(content) {
  return apiProcess(startAndesV2SweepExtraction, content)
}

function getExperimentExtractionLog(verificationId) {
  return new Promise((resolve, reject) => {
    getAndesV2ExperimentExtractionLog(verificationId).then(res => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
}

function getSweepListByProjectIdPromise(projectId) {
  return apiProcess(getAndesV2SweepListByProjectId, projectId)
}

export {
  updateSweepConfigsPromise,
  getSweepListByChannelIdPromise,
  createSweepConfigsPromise,
  getNetListByChannelIdPromise,
  getSweepConfigsPromise,
  createSweepContentPromise,
  getExperimentsBySweepIdPromise,
  deleteSweepBySweepIdPromise,
  deleteSweepContentByIdPromise,
  updateSweepContentPromise,
  getVariablesBySweepIdPromise,
  updateVariablesBySweepIdPromise,
  startSweepExtractionPromise,
  getExperimentExtractionLog,
  getSweepListByProjectIdPromise
}