import React, { Fragment, Component } from "react";
import { Checkbox, Select, Radio } from "antd";
import {
  ADAPT_EQ,
  ADAPT_INC_XTALK,
  STATSIM_OPTIONS,
  ADAPT_FOM,
  ADAPT_FOM_OPTIONS,
  ADAPT_CSPACE,
  USE_ADAPT_CSPACE,
  ADAPT_PS,
  ADAPT_DE,
  TX_PRE_SHOOT,
  TX_DEEMPHASIS,
  ADAPT_DC,
  ADAPT_POLE,
  ADAPT_DC2,
  ADAPT_POLE2,
  LEQ_DC,
  LEQ_POLE,
  LEQ_DC2,
  LEQ_POLE2,
  SPECIFY_SEARCH,
  LEQ_BW,
  LEQ_AC,
  LEQ_BW2,
  LEQ_AC2,
  USE_ADAPT_TX_PRESET,
  ADAPT_TX_PRESET,
  USE_LEQ_RESPONSE,
  ADAPT_LEQ_INDEX1,
  ADAPT_LEQ_INDEX2,
  ADAPT_DFE_MAG,
  ADAPT_DFE_H1H0,
  RXCOEFF
} from "@/services/Andes_v2/seaSim/constants";
import { ADAPT_NUI, ADAPT_PSDE_PAIRS, LEQ_RESPONSE_ID, LEQ_RESPONSE_INDEX1, LEQ_RESPONSE_INDEX2 } from "../../../services/Andes_v2/seaSim/constants";

const Option = Select.Option;
const RadioGroup = Radio.Group;
class EqualizationOptions extends Component {

  render = () => {
    const { analysisOptions,
      selectChange,
      checkChange, inputComponent, inputChange } = this.props;
    const adaptEQ = analysisOptions[STATSIM_OPTIONS][ADAPT_EQ];
    return <Fragment>
      <div className='channel-config-item'>
        <span>Equalizer Adaptation</span>
        <Checkbox
          checked={adaptEQ || false}
          className='channel-config-checkbox'
          onChange={(checked) => checkChange(checked, ADAPT_EQ)}
        />
      </div>
      {adaptEQ ? <div className='channel-config-eq-children'>
        <div className='channel-config-item'>
          <span>Eye Evaluation (adapt_FOM)</span>
          <Select
            value={analysisOptions[STATSIM_OPTIONS][ADAPT_FOM]}
            showSearch
            onChange={(key) => selectChange(key, ADAPT_FOM)}
            placeholder={"Eye Evaluation"}
            className='aurora-select'
            popupClassName='aurora-select-dropdown'
          >
            {ADAPT_FOM_OPTIONS.map(item =>
              <Option key={item.key}>{item.title}</Option>
            )}
          </Select>
        </div>
        <div className='channel-config-item'>
          <span>Include Crosstalk (adapt_inc_xtalk)</span>
          <Checkbox
            checked={analysisOptions[STATSIM_OPTIONS][ADAPT_INC_XTALK] || false}
            onChange={(checked) => checkChange(checked, ADAPT_INC_XTALK)}
            className='channel-config-checkbox'
          />
        </div>
        <div className='channel-config-item'>
          <span>Number of post-cursor UI for adaptation</span>
          {inputComponent({
            type: ADAPT_NUI,
            value: analysisOptions[STATSIM_OPTIONS][ADAPT_NUI],
            inputChange,
          })}
        </div>

      </div>
        : null}
      <div className='channel-config-item'>
        <span>TX FIR Equalization</span>
      </div>
      {
        adaptEQ ?
          this.getEqualizationEQSettings()
          : this.getEqualizationSettings()
      }
    </Fragment >
  }

  getEqualizationEQSettings = () => {
    const { inputComponent, analysisOptions, inputChange, tagsInputComponent, radioChange, checkChange, selectComponent, selectChange, LeqOptionList } = this.props;
    const radioValue = analysisOptions[STATSIM_OPTIONS][USE_ADAPT_CSPACE] ? ADAPT_CSPACE : analysisOptions[STATSIM_OPTIONS][USE_ADAPT_TX_PRESET] ? USE_ADAPT_TX_PRESET : SPECIFY_SEARCH;
    const useLeqResponse = analysisOptions[STATSIM_OPTIONS][USE_LEQ_RESPONSE] ? true : false;
    return <Fragment>
      <RadioGroup
        name='eqsetting'
        onChange={(e) => radioChange(e, ADAPT_CSPACE)}
        value={radioValue}
      >
        <div className='channel-config-item channel-config-sub-item'>
          <Radio name='eqsetting' value={ADAPT_CSPACE} style={{ height: 32 }}></Radio>
          <span>Search space (adapt_Cspace)</span>
          {inputComponent({
            type: ADAPT_CSPACE,
            value: analysisOptions[STATSIM_OPTIONS][ADAPT_CSPACE],
            inputChange,
            disabled: radioValue !== ADAPT_CSPACE
          })}
          <span className='channel-config-input-suffix'>dB</span>
        </div>
        <div className='channel-config-item channel-config-sub-item'>
          <Radio name='eqsetting' value={USE_ADAPT_TX_PRESET} style={{ height: 32 }}></Radio>
          <span>Preset search space (adapt_TX_preset)</span>
          {selectComponent({
            type: ADAPT_TX_PRESET,
            value: analysisOptions[STATSIM_OPTIONS][ADAPT_TX_PRESET],
            disabled: radioValue !== USE_ADAPT_TX_PRESET,
            optionList: ["P0", "P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9"],
            selectChange,
            mode: "multiple",
            selectClassName: "channel-config-sub-item-multiple-select"
          })}
        </div>
        <div className='channel-config-item channel-config-sub-item'>
          <Radio name='eqsetting' value={SPECIFY_SEARCH} style={{ height: 32 }}></Radio>
          <span className='channel-sub-item-title'>Specify search value​</span>
        </div>
      </RadioGroup>
      {tagsInputComponent({
        type: ADAPT_PS,
        value: analysisOptions[STATSIM_OPTIONS][ADAPT_PS],
        label: "Pre-shoot (adapt_PS)",
        suffix: <span className='channel-config-input-suffix'>dB</span>,
        className: 'channel-config-sub-item channel-config-sub-children-item',
        disabled: radioValue !== SPECIFY_SEARCH,
        allowedDuplicates: analysisOptions[STATSIM_OPTIONS][ADAPT_PSDE_PAIRS] || false
      })}
      {tagsInputComponent({
        type: ADAPT_DE,
        value: analysisOptions[STATSIM_OPTIONS][ADAPT_DE],
        label: "De-emphasis (adapt_DE)",
        suffix: <span className='channel-config-input-suffix'>dB</span>,
        className: 'channel-config-sub-item channel-config-sub-children-item',
        disabled: radioValue !== SPECIFY_SEARCH,
        allowedDuplicates: analysisOptions[STATSIM_OPTIONS][ADAPT_PSDE_PAIRS] || false
      })}
      <div className='channel-config-item channel-config-sub-children-item'>
        <span>Treat PS/DE lists as pairs</span>
        <Checkbox
          checked={analysisOptions[STATSIM_OPTIONS][ADAPT_PSDE_PAIRS] || false}
          onChange={(checked) => checkChange(checked, ADAPT_PSDE_PAIRS)}
          className='channel-config-checkbox'
          disabled={radioValue !== SPECIFY_SEARCH}
        />
      </div>
      <div className='channel-config-item'>
        <span className='channel-sub-item-title'>RX Linear Equalizer</span>
      </div>
      <div className='channel-config-item channel-config-sub-item'>
        <span className='channel-item-title channel-item-eq-tx-title'>Use LEQ response file</span>
        <Checkbox
          checked={useLeqResponse || false}
          className='channel-config-checkbox'
          onChange={(checked) => checkChange(checked, USE_LEQ_RESPONSE)}
        />
      </div>
      {useLeqResponse ? <Fragment>
        {selectComponent({
          type: LEQ_RESPONSE_ID,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_RESPONSE_ID],
          optionList: LeqOptionList || [],
          selectChange,
          optionType: 'file',
          label: "LEQ response file (leq_response_id)",
          className: 'channel-config-sub-item channel-config-sub-children-item channel-config-sub-leq-children-item',
          allowClear: true
        })}
        <div className='channel-config-item channel-config-item-analysis-title channel-config-item-leq-analysis-title'>
          <span>(adapt_leq_index1)​</span>
          <span>(adapt_leq_index2)</span>
        </div>
        <div className="channel-config-item channel-config-sub-children-item channel-config-sub-leq-children-item">
          <span className='channel-item-title'>LEQ response search index</span>
          {tagsInputComponent({
            type: ADAPT_LEQ_INDEX1,
            value: analysisOptions[STATSIM_OPTIONS][ADAPT_LEQ_INDEX1] || [],
            className: "channel-config-sub-leq-input",
            allowedDuplicates: true
          })}
          {tagsInputComponent({
            type: ADAPT_LEQ_INDEX2,
            value: analysisOptions[STATSIM_OPTIONS][ADAPT_LEQ_INDEX2] || [],
            className: "channel-config-sub-leq-input",
            allowedDuplicates: true
          })}
        </div>
      </Fragment> : null}
      {!useLeqResponse ?
        <Fragment>
          <div className='channel-config-item channel-config-item-analysis-title'>
            <span>Bandwidth (Hz) (leq_bw)​</span>
            <span>AC Gain (dB) (leq_AC)</span>
            <span>DC Gain (dB) (adapt_DC)​</span>
            <span>Pole (Hz) (adapt_pole)</span>
          </div>
          <div className='channel-config-item channel-config-sub-item'>
            <span className='channel-item-title channel-item-eq-tx-title'>First Pole</span>
            {inputComponent({
              type: LEQ_BW,
              value: analysisOptions[STATSIM_OPTIONS][LEQ_BW],
              inputChange,
              className: "channel-config-sub-half-input"
            })}
            {inputComponent({
              type: LEQ_AC,
              value: analysisOptions[STATSIM_OPTIONS][LEQ_AC],
              inputChange,
              className: "channel-config-sub-half-input"
            })}
            {tagsInputComponent({
              type: ADAPT_DC,
              value: analysisOptions[STATSIM_OPTIONS][ADAPT_DC] || [],
              className: "channel-config-sub-half-input"
            })}
            {tagsInputComponent({
              type: ADAPT_POLE,
              value: analysisOptions[STATSIM_OPTIONS][ADAPT_POLE] || [],
              className: "channel-config-sub-half-input"
            })}
          </div>
          <div className='channel-config-item channel-config-item-analysis-title'>
            <span>(leq_bw2)​</span>
            <span>(leq_AC2)</span>
            <span>(adapt_DC2)​</span>
            <span>(adapt_pole2)</span>
          </div>
        </Fragment>
        : <div className='channel-config-item channel-config-item-analysis-title'>
          <span>Bandwidth (Hz) (leq_bw2)​</span>
          <span>AC Gain (dB) (leq_AC2)</span>
          <span>DC Gain (dB) (leq_DC2)​</span>
          <span>Pole (Hz) (leq_pole2)</span>
        </div>}
      <div className='channel-config-item channel-config-sub-item'>
        <span className='channel-item-title channel-item-eq-tx-title'>Second Pole</span>
        {inputComponent({
          type: LEQ_BW2,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_BW2],
          inputChange,
          className: "channel-config-sub-half-input"
        })}
        {inputComponent({
          type: LEQ_AC2,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_AC2],
          inputChange,
          className: "channel-config-sub-half-input"
        })}
        {tagsInputComponent({
          type: ADAPT_DC2,
          value: analysisOptions[STATSIM_OPTIONS][ADAPT_DC2],
          className: "channel-config-sub-half-input"
        })}
        {tagsInputComponent({
          type: ADAPT_POLE2,
          value: analysisOptions[STATSIM_OPTIONS][ADAPT_POLE2],
          className: "channel-config-sub-half-input"
        })}
      </div>
      <div className='channel-config-item'>
        <span className='channel-sub-item-title'>RX Decision Feedback Equalization​</span>
      </div>
      <Fragment>
        {tagsInputComponent({
          type: ADAPT_DFE_MAG,
          value: analysisOptions[STATSIM_OPTIONS][ADAPT_DFE_MAG],
          label: "DFE taps and max magnitude (adapt_DFE_mag)",
          suffix: <span className='channel-config-input-suffix'>V</span>,
          className: 'channel-config-sub-item channel-equalization-sub-item',
          allowedDuplicates: true
        })}
        {inputComponent({
          type: ADAPT_DFE_H1H0,
          label: "Max ratio between DFE h1 and h0 (adapt_DFE_h1h0)",
          value: analysisOptions[STATSIM_OPTIONS][ADAPT_DFE_H1H0],
          inputChange,
          className: 'channel-config-sub-item channel-equalization-sub-item'
        })}
      </Fragment>
    </Fragment>
  }

  getEqualizationSettings = () => {
    //adapt_EQ false
    const { analysisOptions, inputComponent, inputChange, checkChange, selectComponent, selectChange, LeqOptionList, tagsInputComponent } = this.props;
    const useLeqResponse = analysisOptions[STATSIM_OPTIONS][USE_LEQ_RESPONSE] ? true : false;
    return <Fragment>
      {inputComponent({
        type: TX_PRE_SHOOT,
        label: "Pre-shoot (tx_preshoot)",
        value: analysisOptions[STATSIM_OPTIONS][TX_PRE_SHOOT],
        suffix: <span className='channel-config-input-suffix'>dB</span>,
        inputChange,
        className: 'channel-config-sub-item channel-equalization-sub-item'
      })}
      {inputComponent({
        type: TX_DEEMPHASIS,
        label: "De-emphasis (tx_deemphasis)",
        value: analysisOptions[STATSIM_OPTIONS][TX_DEEMPHASIS],
        suffix: <span className='channel-config-input-suffix'>dB</span>,
        inputChange,
        className: 'channel-config-sub-item channel-equalization-sub-item'
      })}
      <div className='channel-config-item'>
        <span className='channel-sub-item-title'>RX Linear Equalizer</span>
      </div>
      <div className='channel-config-item channel-config-sub-item'>
        <span className='channel-item-title channel-item-eq-tx-title'>Use LEQ response file</span>
        <Checkbox
          checked={useLeqResponse}
          className='channel-config-checkbox'
          onChange={(checked) => checkChange(checked, USE_LEQ_RESPONSE)}
        />
      </div>
      {useLeqResponse ? <Fragment>
        {selectComponent({
          type: LEQ_RESPONSE_ID,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_RESPONSE_ID],
          optionList: LeqOptionList || [],
          selectChange,
          optionType: 'file',
          label: "LEQ response file (leq_response_id)",
          className: 'channel-config-sub-item channel-config-sub-children-item channel-config-sub-leq-children-item',
          allowClear: true
        })}
        <div className='channel-config-item channel-config-item-analysis-title channel-config-item-leq-analysis-title'>
          <span>(leq_response_index1)​</span>
          <span>(leq_response_index2)</span>
        </div>
        <div className="channel-config-item channel-config-sub-children-item channel-config-sub-leq-children-item">
          <span className='channel-item-title'>LEQ response index</span>
          {inputComponent({
            type: LEQ_RESPONSE_INDEX1,
            value: analysisOptions[STATSIM_OPTIONS][LEQ_RESPONSE_INDEX1],
            inputChange,
            className: "channel-config-sub-leq-input"
          })}
          {inputComponent({
            type: LEQ_RESPONSE_INDEX2,
            value: analysisOptions[STATSIM_OPTIONS][LEQ_RESPONSE_INDEX2],
            inputChange,
            className: "channel-config-sub-leq-input"
          })}
        </div>
      </Fragment> : null}
      {!useLeqResponse ?
        <Fragment>
          <div className='channel-config-item channel-config-item-analysis-title'>
            <span>Bandwidth (Hz) (leq_bw)​</span>
            <span>AC Gain (dB) (leq_AC)</span>
            <span>DC Gain (dB) (leq_DC)​</span>
            <span>Pole (Hz) (leq_pole)</span>
          </div>
          <div className='channel-config-item channel-config-sub-item'>
            <span className='channel-item-title channel-item-eq-tx-title'>First Pole</span>
            {inputComponent({
              type: LEQ_BW,
              value: analysisOptions[STATSIM_OPTIONS][LEQ_BW],
              inputChange,
              className: "channel-config-sub-half-input"
            })}
            {inputComponent({
              type: LEQ_AC,
              value: analysisOptions[STATSIM_OPTIONS][LEQ_AC],
              inputChange,
              className: "channel-config-sub-half-input"
            })}
            {inputComponent({
              type: LEQ_DC,
              value: analysisOptions[STATSIM_OPTIONS][LEQ_DC],
              inputChange,
              className: "channel-config-sub-half-input"
            })}
            {inputComponent({
              type: LEQ_POLE,
              value: analysisOptions[STATSIM_OPTIONS][LEQ_POLE],
              inputChange,
              className: "channel-config-sub-half-input"
            })}
          </div>
          <div className='channel-config-item channel-config-item-analysis-title'>
            <span>(leq_bw2)​</span>
            <span>(leq_AC2)</span>
            <span>(leq_DC2)​</span>
            <span>(leq_pole2)</span>
          </div>
        </Fragment>
        :
        <div className='channel-config-item channel-config-item-analysis-title'>
          <span>Bandwidth (Hz) (leq_bw2)​</span>
          <span>AC Gain (dB) (leq_AC2)</span>
          <span>DC Gain (dB) (leq_DC2)​</span>
          <span>Pole (Hz) (leq_pole2)</span>
        </div>}
      <div className='channel-config-item channel-config-sub-item'>
        <span className='channel-item-title channel-item-eq-tx-title'>Second Pole</span>
        {inputComponent({
          type: LEQ_BW2,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_BW2],
          inputChange,
          className: "channel-config-sub-half-input"
        })}
        {inputComponent({
          type: LEQ_AC2,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_AC2],
          inputChange,
          className: "channel-config-sub-half-input"
        })}
        {inputComponent({
          type: LEQ_DC2,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_DC2],
          inputChange,
          className: "channel-config-sub-half-input"
        })}
        {inputComponent({
          type: LEQ_POLE2,
          value: analysisOptions[STATSIM_OPTIONS][LEQ_POLE2],
          inputChange,
          className: "channel-config-sub-half-input"
        })}
      </div>
      <div className='channel-config-item'>
        <span className='channel-sub-item-title'>RX Decision Feedback Equalization​</span>
      </div>
      {tagsInputComponent({
        type: RXCOEFF,
        label: "DFE tap values(rxcoeff)",
        value: analysisOptions[STATSIM_OPTIONS][RXCOEFF] || [],
        suffix: <span className='channel-config-input-suffix'>V</span>,
        className: 'channel-config-sub-item channel-equalization-sub-item',
        allowedDuplicates: true
      })}
    </Fragment>
  }
}

export default EqualizationOptions;