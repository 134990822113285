function SimulationOptions(props = {}) {
  const { hspiceConfig = {} } = props;
  const { hspiceLicenses = '2' } = hspiceConfig;
  this.hspiceConfig = {
    hspiceLicenses: hspiceLicenses
  }
}

export {
  SimulationOptions
}