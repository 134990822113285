import React, { Component, Fragment, createRef } from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { createPortal } from 'react-dom';
import Table from '@/components/EditableTable';
import Panel from '@/components/Panel';
import {
  ImpVRM,
  ImpVrmDisplay,
  sortReference
} from '@/services/Cascade/Impedance';
import designConstructor from '../../../services/helper/designConstructor';
import { getAllCascadeComponents } from '../../../services/Cascade/helper/setupData';

const VRMColumns = [
  {
    title: 'Eq. Pwr Pin',
    dataIndex: 'pwr',
    width: '30%',
  }, {
    title: 'Eq. Gnd Pin',
    dataIndex: 'gnd',
    width: '30%',
  }, {
    title: 'Component',
    dataIndex: 'vrmComp',
    width: '40%'
  }
]
class VRMCompPanel extends Component {

  constructor(props) {
    super(props);
    const { record = {} } = props;
    const { VRM = [] } = record;
    let newVRM = JSON.parse(JSON.stringify(VRM));
    let newVrms = VRM.map((item, index) => {
      const { VRM_COMP = "", groundPin = [], powerPin = [] } = item;
      const vrmComp = VRM_COMP,
        gnd = groundPin.length ? groundPin[0].comp : '',
        pwr = powerPin.length ? powerPin[0].comp : '',
        pwrPins = powerPin.length ? powerPin[0].pins : [],
        gndPins = groundPin.length ? groundPin[0].pins : ''
      newVRM[index].index = index + 1;
      return { vrmComp, gnd, pwr, pwrPins, index: index + 1, gndPins }
    });
    this.state = {
      vrms: newVrms,
      VRM: newVRM
    }
    this.initColumns();
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidUpdate(prevProps) {
    const { close, noPCB } = this.props;
    if (close && close !== prevProps.close) {
      this.closeModal()
    }
    if (noPCB !== prevProps.noPCB) {
      this.initColumns()
    }
  }

  initColumns = () => {

    VRMColumns[0].render = (text, record) => {
      const { pwrPins = [] } = record;
      return <div style={{ position: 'relative', minHeight: 20 }}>
        <span>{text}{pwrPins.length ? ` - ${pwrPins.join(', ')}` : ''}</span>
      </div>
    }

    VRMColumns[0].onCell = (record) => {
      const { powerPinList = [], noPCB } = this.props;
      const vrms = this.state.vrms.map(item => item.pwr);
      const { pwr, pwrPins = [] } = record;
      return !noPCB ? {
        edit: 'aurora-select',
        record,
        dataIndex: 'pwr',
        options: powerPinList.map(item => item.name).filter(item => !vrms.includes(item)),
        selectType: "pwr",
        text: `${pwr}${pwrPins.length ? ` - ${pwrPins.join(', ')}` : ''}`,
        getPopupContainer: document.getElementById("root"),
        handleSave: (record) => this.saveVRMPin(record, 'pwr')
      } : {
        edit: false
      }
    }


    VRMColumns[1].render = (text, record) => {
      const { gndPins = [] } = record;
      return <div style={{ position: 'relative', minHeight: 20 }}>
        <span>{text}{gndPins.length ? ` - ${gndPins.join(', ')}` : ''}</span>
      </div>
    }


    VRMColumns[1].onCell = (record) => {
      const { referencePinList = [], noPCB, designId = "", ReferenceNets, extraReferenceComps = [] } = this.props;
      const isPrelayout = designConstructor.isPreLayout(designId);
      const references = isPrelayout ? referencePinList : sortReference(referencePinList, designId, record.pwr, record.pwrPins, ReferenceNets);
      const { gnd, gndPins = [] } = record;
      return !noPCB ? {
        edit: 'aurora-select',
        record,
        dataIndex: 'gnd',
        options: [...references, ...extraReferenceComps],
        selectType: "gnd",
        text: `${gnd}${gndPins.length ? ` - ${gndPins.join(', ')}` : ''}`,
        getPopupContainer: document.getElementById("root"),
        handleSave: (record) => this.saveVRMPin(record, 'gnd')
      } : {
        edit: false
      }
    }

    VRMColumns[2].render = (text, record) => {
      return (
        <div style={{ position: 'relative', minHeight: 20 }}>
          <span>{text ? text.join(', ') : ''}</span>
          <CloseOutlined
            className="impedance-vrm-delete-icon"
            onClick={(e) => this.deleteRow(e, record)} />
        </div>
      );
    }
  }

  componentDidMount = () => {
    this.props.onVRMRef && this.props.onVRMRef(this);
  }

  closeModal = () => {
    this.props.save();
    const { VRM } = this.state;
    const { record } = this.props;
    this.props.saveVRMComp(
      record,
      VRM.map(vrm => {
        delete vrm.index;
        return vrm
      })
    );
    this.props.closeVRMPanel()
  }

  addRow = (e) => {
    e.stopPropagation();
    const { vrms, VRM } = this.state;
    let model = VRM.length ? VRM[0].model : { id: '', name: '' };
    let newVrm = new ImpVrmDisplay(vrms.length + 1);
    let newVRM = new ImpVRM(VRM.length + 1, model);
    this.setState({
      vrms: [...vrms, newVrm],
      VRM: [...VRM, newVRM]
    })
  }

  deleteRow = (e, record) => {
    e.stopPropagation();
    const index = record.index;
    const { vrms, VRM } = this.state;
    this.setState({
      vrms: vrms.filter(vrm => vrm.index !== index),
      VRM: VRM.filter(item => item.index !== index)
    });
  }

  saveVRMPin = (record, type) => {
    const value = record[type];
    const index = record.index;
    const { vrms, VRM } = this.state;
    const { ReferenceNets = [], PowerNets = [], detail } = this.props;
    const newVrms = JSON.parse(JSON.stringify(vrms)), newVRM = JSON.parse(JSON.stringify(VRM));
    let findIndex = newVrms.findIndex(item => item.index === index);
    if (findIndex > -1) {
      newVrms[findIndex][type] = value;
    }
    findIndex = newVRM.findIndex(item => item.index === index);
    if (findIndex > -1) {
      const { designId } = this.props;
      const current = getAllCascadeComponents({ pcbId: designId }).get(value)
      if (type === 'pwr') {
        if (current) {
          const connectPowerNets = detail.map(path => {
            const lastIndex = path.findLastIndex(item => item.name === value);
            if (lastIndex < 1) {
              return null;
            }
            const net = path[lastIndex - 1];
            if (!net) {
              return null;
            }
            return net.name;
          }).flat().filter(item => !!item);
          const currentPins = [...current.pins.values()].filter(pin => (connectPowerNets.length ? connectPowerNets : PowerNets).includes(pin.net) && !ReferenceNets.includes(pin.net)).map(p => p.pin);
          const pins = currentPins ? currentPins : [...current.pins.values()].filter(p => !ReferenceNets.includes(p.net)).map(p => p.pin).sort();
          newVRM[findIndex].powerPin = [{ comp: value, pins: pins }]
          newVrms[findIndex].pwrPins = [...pins];
        }
      } else if (type === 'gnd') {
        if (current) {
          const currentPins = [...current.pins.values()].filter(pin => ReferenceNets.includes(pin.net) && !PowerNets.includes(pin.net)).map(p => p.pin);
          const pins = currentPins.length ? currentPins : [...current.pins.values()].filter(p => !PowerNets.includes(p.net)).pins.map(p => p.pin).sort();
          newVRM[findIndex].groundPin = [{ comp: value, pins: pins }]
          newVrms[findIndex].gndPins = [...pins];
        }
      }
      if (!newVRM[findIndex].VRM_COMP) {
        newVRM[findIndex].VRM_COMP = []
      }
    }
    this.setState({
      vrms: newVrms,
      VRM: newVRM
    })
  }

  renderDialog = () => {
    const { vrms } = this.state;
    const content = (
      <Panel
        className='impedance-panel'
        id='impedance-vrm-panel'
        title={<div className='impedance-panel-title'>VR Port Setting
          <PlusCircleOutlined className='impedance-comp-add-icon' onClick={(e) => this.addRow(e)} />
        </div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={1000}
        minWidth={200}
        minHeight={200}
        position='panel-center'
        draggable
        overflow={"auto"}
      >
        <Table
          columns={VRMColumns}
          dataSource={vrms}
          size="small"
          rowKey={(record) => record.index}
          className='impedance-vrm-table cascade-imp-table'
          tablePadding={true}
        />
      </Panel>
    );
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { text } = this.props;
    return <Fragment>
      <div className="editable-cell-value-wrap" ref={this.inputRef}>
        {text}
      </div>
      {this.renderDialog()}
    </Fragment>
  }
}

export default VRMCompPanel;
