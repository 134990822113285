/*
 * NO_LOGIN = -1;           // NA
 * SUCCESS = 0;             // Success
 * CHECK_FAIL = 1;          // Verify data, print message
 * NO_PERMISSION = 2;       // Login, Permission, Share
 * JWT_EXPIRED = 3;         // NA
 * NOT_FOUND = 4;           // NA
 * UNKNOWN_EXCEPTION = -99  // Unknown exception
 */


export const NO_LOGIN = -1;     // NA
export const SUCCESS = 0;       // Success
export const CHECK_FAIL = 1;    // Verify data, print message
export const NO_PERMISSION = 2; // Login, Permission, Share
export const JWT_EXPIRED = 3;   // NA
export const NOT_FOUND = 4;     // NA
export const LICENSE_CHECK = 5; // License check error
export const API_RES_MESSAGE = 6; // SHOW message to user
export const UNKNOWN_EXCEPTION = -99;  // Unknown exception