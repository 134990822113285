import { X8, X16, CONTROLLER } from './preLayoutConfig';
import { numberCheck } from "../../helper/dataProcess";
import { unitChange } from '../../helper/mathHelper';
function preLayoutPlot({ id, width, height, controller, memorys, type, size, unit, axios, showLocation = false }) {
  this.id = id;
  this.width = width;
  this.height = height;
  this.x = axios ? axios.x : width / 3;
  this.y = axios ? axios.y : height / 2;
  this.size = memorySize({ type, size });
  this.memorys = memorys;
  this.controller = controller;
  this.unit = unit;
  this.showLocation = showLocation;
}

preLayoutPlot.prototype.drawPlot = drawPlot;
preLayoutPlot.prototype.update = update;
preLayoutPlot.prototype.memorySize = memorySize;
preLayoutPlot.prototype.updateUnit = updateUnit;
preLayoutPlot.prototype.updateSize = updateSize;
preLayoutPlot.prototype.clear = clear;

function drawPlot() {
  let canvas = document.getElementById(this.id);
  const ctx = canvas.getContext('2d');
  ctx.lineWidth = 1;
  ctx.font = "normal 12px -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Segoe UI Symbol'";
  ctx.textAlign = "center";
  ctx.textBaseline = 'middle';
  ctx.fillStyle = 'rgba(255, 255, 255, 0)';
  drawAxios(ctx, { width: this.width, height: this.height, x: this.x, y: this.y });
  drawComponent(ctx, { axios: { x: this.x, y: this.y }, controller: this.controller, memorys: this.memorys, size: this.size, showLocation: this.showLocation, unit: this.unit });
  drawUnit(ctx, this.unit, this.width);
}

function clear() {
  let canvas = document.getElementById(this.id);
  const ctx = canvas.getContext('2d');
  const width = canvas.width, height = canvas.height;
  ctx.clearRect(0, 0, width, height);
  canvas.width = width;
}

function update({ width, height, controller, memorys, axios }) {
  this.width = width ? width : this.width;
  this.height = height ? height : this.height;
  this.x = axios ? axios.x : width / 3;
  this.y = axios ? axios.y : height / 2;
  this.memorys = memorys ? memorys : this.memorys;
  this.controller = controller ? controller : this.controller;
}

function updateSize({ size, type }) {
  this.size = memorySize({ type, size });
}

function updateUnit(unit) {
  this.unit = unit;
}

function drawAxios(ctx, params) {
  const { width, height, x, y } = params;

  // draw grid line
  ctx.save();
  ctx.translate(0.5, 0.5);
  ctx.beginPath();
  ctx.strokeStyle = '#efefef';

  let rowLength = y;
  while (rowLength >= 5) {
    ctx.moveTo(0, rowLength);
    ctx.lineTo(width, rowLength);
    ctx.stroke();
    rowLength = rowLength - 25;
  }

  rowLength = y;
  while (rowLength < height) {
    ctx.moveTo(0, rowLength);
    ctx.lineTo(width, rowLength);
    ctx.stroke();
    rowLength = rowLength + 25;
  }

  let columnLength = x;
  while (columnLength >= 5) {
    ctx.moveTo(columnLength, 0);
    ctx.lineTo(columnLength, height);
    ctx.stroke();
    columnLength = columnLength - 25;
  }

  columnLength = x;
  while (columnLength < width) {
    ctx.moveTo(columnLength, 0);
    ctx.lineTo(columnLength, height);
    ctx.stroke();
    columnLength = columnLength + 25;
  }

  ctx.closePath();

  // draw axios
  ctx.strokeStyle = '#f5f5f5';
  ctx.beginPath();
  ctx.moveTo(0, y);
  ctx.lineTo(width, y);
  ctx.moveTo(x, 0);
  ctx.lineTo(x, height);
  ctx.closePath();
  ctx.stroke();

  // draw origin text
  ctx.fillStyle = '#000';
  ctx.fillText("( 0, 0 )", x + 15, y - 10);


  // draw origin
  ctx.beginPath();
  ctx.fillStyle = "#b90c0c";
  ctx.arc(x, y, 2, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.fill();
}

function getRealWidth(number, unit, type) {
  if (!number || number === '0') {
    return type === CONTROLLER ? 1000 : 0
  };
  return unit === 'mil' ? number : unitChange({ num: number, oldUnit: unit, decimals: 0 }).number;

}

function drawComponent(ctx, { axios, controller, memorys, size, showLocation, unit }) {
  const { x, y } = axios;
  const { width, height } = controller;
  const { memoryWidth, memoryHeight } = size;
  const _width = getRealWidth(width, unit, CONTROLLER) / 8, _height = getRealWidth(height, unit, CONTROLLER) / 8;
  // draw Rect
  ctx.strokeStyle = "#b90c0c";
  ctx.strokeRect(x - _width, y - _height, 2 * _width, 2 * _height);

  // draw controller name
  ctx.fillStyle = '#000';
  ctx.fillText("U1", x - _width + 20, y - _height + 20);

  if (showLocation) {
    // draw controller width
    ctx.fillText(parseInt(width), x, y - _height - 8);
    ctx.fillText(parseInt(height), x + _width + 14, y);
  }

  //draw Memory
  memorys.forEach((memory, index) => {
    const { name, locations } = memory;
    let _x = locations.x ? getRealWidth(locations.x, unit) : null, _y = locations.y ? getRealWidth(locations.y, unit) : null;
    if (numberCheck(_x)) {
      _x = 500;
    } else {
      _x = Number(_x) / 4 + x;
    }
    if (numberCheck(_y)) {
      _y = index % 2 !== 0 ? y + 35 + 60 * parseInt(index / 2) : y - 35 - 60 * parseInt(index / 2);
    } else {
      _y = y - Number(_y) / 4;
    }
    ctx.beginPath();
    // draw Rect
    ctx.strokeStyle = '#3da4fa';
    ctx.strokeRect(_x - memoryWidth / 2, _y - memoryHeight / 2, memoryWidth, memoryHeight);

    // draw point
    ctx.arc(_x, _y, 2, 0, 2 * Math.PI);
    ctx.fillStyle = "#3da4fa";
    ctx.fill()

    // draw memory name
    ctx.fillStyle = '#000';
    ctx.fillText(name, _x - memoryWidth / 2 + 25, _y - memoryHeight / 2 + 15);

    if (showLocation) {
      ctx.fillText(`(${parseInt(locations.x)}, ${parseInt(locations.y)})`, _x + 27, _y - 7);
    }

    ctx.closePath();
  })
}

function drawUnit(ctx, unit, width) {
  ctx.lineWidth = 1;
  ctx.font = "bold 12px -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Segoe UI Symbol'";
  ctx.textAlign = "center";
  ctx.textBaseline = 'middle';

  ctx.fillStyle = "#000000";
  const unitValue = unitChange({ word: `100 mil to ${unit}`, decimals: 4 });
  ctx.fillText(`1 grid : ${unitValue ? unitValue.value : '100 mil'}`, width - 60, 15);
}

function memorySize({ type, size }) {
  switch (size) {
    case X8:
      return {
        memoryWidth: 472 / 4,
        memoryHeight: 413 / 4
      }
    case X16:
      return {
        memoryWidth: 551 / 4,
        memoryHeight: 393 / 4
      }
    default:
      break;
  }
}

export function getNewPolt(newPlacement, unit) {
  const poltWidth = 750, poltHeight = 540;
  const { memorys, controller } = newPlacement;
  let maxWidth = Number(controller.width) / 2, minWidth = -(Number(controller.width) / 2),
    maxHeight = Number(controller.height) / 2, minHeight = -(Number(controller.height) / 2);
    
  memorys.forEach(memory => {
    const { x, y } = memory.locations;
    maxWidth = maxWidth > Number(x) ? maxWidth : Number(x);
    minWidth = minWidth > Number(x) ? Number(x) : minWidth;
    maxHeight = maxHeight > Number(y) ? maxHeight : Number(y);
    minHeight = minHeight > Number(y) ? Number(y) : minHeight;
  })
  maxWidth = unit ? unitChange({ num: maxWidth, oldUnit: unit, decimals: 0 }).number : maxWidth;
  maxHeight = unit ? unitChange({ num: maxHeight, oldUnit: unit, decimals: 0 }).number : maxHeight;
  minWidth = unit ? unitChange({ num: minWidth, oldUnit: unit, decimals: 0 }).number : minWidth;
  minHeight = unit ? unitChange({ num: minHeight, oldUnit: unit, decimals: 0 }).number : minHeight;
  let newWidth = (maxWidth - minWidth) / 4;
  let newHeight = (maxHeight - minHeight) / 4;

  newWidth = newWidth + 200 > poltWidth ? newWidth + 200 : poltWidth;
  newHeight = newHeight + 200 > poltHeight ? newHeight + 200 : poltHeight;
  // height * Positive and negative ratio - 
  const yAxios = maxHeight <= 0 ? newHeight / 2 + (minHeight) / 10 : (newHeight * maxHeight) / (maxHeight - minHeight) - (maxHeight + minHeight) / (newHeight / 4);
  const xAxios = minWidth < 0 ? newWidth * Math.abs(minWidth) / (maxWidth - minWidth) : newWidth / 3;
  let placement = { ...newPlacement, width: String(newWidth), height: String(newHeight), axios: { x: xAxios, y: yAxios } };
  return placement;
}

export default preLayoutPlot;