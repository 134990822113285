import React from 'react';
import EditableTable from '../EditableTable';
import './style.css';

const columns = [
  {
    title: 'Task',
    dataIndex: 'task',
    width: '20%',
  },
  {
    title: 'Real Time',
    dataIndex: 'realTime',
    width: '10%',
  },
  {
    title: 'CPU Time',
    dataIndex: 'cpuTime',
    width: '10%',
  },
  {
    title: 'Memory',
    dataIndex: 'memory',
    width: '10%',
  },
  {
    title: 'Information',
    dataIndex: 'information',
  }
]

function ProfileTable(dataList) {
  return (
    <EditableTable
      className="profile-table"
      columns={columns}
      size="small"
      dataSource={dataList}
    />
  )
}

export default ProfileTable;