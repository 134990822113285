import React from 'react';
import { Menu, Button } from 'antd';

function getMenu() {
  return [
    {
      name: 'BGA',
      key: 'pin'
    },
    {
      name: 'Via',
      key: 'via'
    }]
}

function TopMenu(selectedKey, changeResultType, downloadDCResult) {
  const DCMenuList = getMenu()
  return (
    <div className="dc-Result-Menu">
      <Menu mode="horizontal" selectedKeys={[selectedKey]} items={DCMenuList ? DCMenuList.map(item => ({ key: item.key, label: item.name, onClick: changeResultType })) : []} />
      <div className="download-dc-icon" onClick={downloadDCResult}>
        <span className='PDN-download-icon-vertical-line'></span>
        <Button
          type="primary"
          ghost
          className="dc-export-icon">Export xls</Button>
      </div>
    </div>
  )
}

export default TopMenu