import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import TargetIC from '../components/targetIC';
import PCBSelect from '../components/PCBSelect';
import DomainTable from './domain';
import ComponentsTable from './components';
import {
  changeTargetIC, refreshImpedance, updateSelectPowerNets, changePCB, needUpdateCompSetting,
  updateCompRLCPrefix, updateOpenTargetICLoading, saveAdditionalNets, updatePackage, switchTargetDie, changeImpedancePage
} from '../store/Impedance/action';
import { getNets } from '@/services/Cascade/helper/setupData';
import DelConfirm from '@/components/DelConfirm';
import projectDesigns from '@/services/helper/projectDesigns';
import componentSetting from '../../../services/Cascade/helper/compSettingHelper';
import { IMPEDANCE_PCB, IMPEDANCE_PACKAGE, TARGET_PACKAGE_INDEX, IMPEDANCE_DIE } from '../../../services/Cascade/constants';
import PkgDomainTable from './pkgDomain';
import AdditionalNets from '@/components/AdditionalNets'
import TargetDie from '../components/targetDie';
import { OVERVIEW } from '../../../services/Cascade/Impedance';
import Overview from './overview';
import CapComp from './capComp';
import { versionCompareSize } from '../../../services/helper/dataProcess';
import designConstructor from '../../../services/helper/designConstructor';
import './index.css';
import { PASSIVE, ON_DIE } from '../../../services/Cascade/constants';
import { SortFn } from '../../../services/helper/sort';

const sort = [PASSIVE, ON_DIE, null]
class ImpedanceContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadComponents: [],
      nets: [],
      width: null,
      COMP_PREFIX_LIB: undefined,
      advanced: false,
      message: ""
    }
  }

  componentDidMount() {
    const { nets } = this.state;
    const { designId, page } = this.props;
    if ((nets && nets.length === 0) && (designId || page !== OVERVIEW)) {
      this.getNetsOptions(page !== OVERVIEW ? page : designId);
    }
    window.addEventListener('resize', this.resize.bind(this));
    this.resize({ target: { innerWidth: window.innerWidth } });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { verificationId, designId, loading, updateCompPrefix, versions, page } = this.props;
    if ((verificationId && verificationId !== prevProps.verificationId)
      || (designId && designId !== prevProps.designId)
      || (!loading && loading !== prevProps.loading)
      || (page !== prevProps.page)) {
      this.getNetsOptions(page === OVERVIEW ? designId : page);
    }
    if ((verificationId && verificationId !== prevProps.verificationId)) {
      this.setState({ advanced: false })
    }

    if (updateCompPrefix) {
      let updates = [];
      for (let version of versions) {
        const { COMP_PREFIX_LIB = {}, designId } = version;
        const _version = await componentSetting.getVersion(designId);
        if (versionCompareSize(COMP_PREFIX_LIB.version, _version)) {
          const designName = designConstructor.getDesignName(designId);
          updates.push(designName)
        }
      }
      this.setState({
        message: updates.length ? `The Component Setting of the \n ${updates.join('\n')} \n has been updated. Do you want to update the Power Domains?` : ''
      })
      this.props.needUpdateCompSetting(false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  resize = (e) => {
    this.setState({
      width: e.target.innerWidth ? e.target.innerWidth - 300 : null
    })
  }

  getNetsOptions = async (designId) => {
    const COMP_PREFIX_LIB = await componentSetting.getPrefixLib(designId);
    const netsInfo = getNets(designId);
    if (netsInfo) {
      const { nets, loadComponents } = netsInfo;
      this.setState({
        nets,
        loadComponents,
        COMP_PREFIX_LIB
      })
    }
  }

  updateCompPrefixVersion = async (bool) => {
    const { designType } = this.props;
    designType === IMPEDANCE_PACKAGE ? this.props.updatePackage(bool ? bool : 'updateVersion') : this.props.updateCompRLCPrefix(bool);
    this.setState({
      message: ''
    })
  }

  changeAdvanced = () => {
    this.setState({
      advanced: !this.state.advanced
    })
  }

  changePage = (prev) => {
    const { data, page } = this.props;
    const sortData = data.filter(item => item.type !== IMPEDANCE_DIE).sort((a, b) => a.index - b.index);
    const pageIndex = sortData.findIndex(item => item.designId === page);
    if (pageIndex < 0) {
      return;
    }
    let _pageIndex = prev ? pageIndex - 1 : pageIndex + 1;
    _pageIndex = _pageIndex < 0 ? sortData.length - 1 : _pageIndex > sortData.length - 1 ? 0 : _pageIndex;
    const newPage = sortData[_pageIndex].designId;
    this.props.changeImpedancePage(newPage)
  }

  changeImpedancePage = () => {
    this.props.changeImpedancePage(OVERVIEW)
  }

  pcbRender = () => {
    const { loadComponents } = this.state;
    const { targetIC, designId, layoutDesignId, powerNetSelect, loading, openProjectId, designStatus, pcbUpdate, openTargetICLoading, designType, designName, targetDie, packageDesignId, singleLayout, page } = this.props;
    const impedanceLoadSelect = targetIC ? [{ comp: targetIC }] : []
    const noPCB = designId && !projectDesigns.getDesignExist(openProjectId, designId)
    const setting = packageDesignId ? componentSetting.getPrefixLibFromMap(packageDesignId) : componentSetting.getPrefixLibFromMap(designId);
    const mulitPCBPage = singleLayout || page === OVERVIEW ? false : true;
    let _designType = designConstructor.getDesignType(layoutDesignId);
    const isPreLayout = designConstructor.isPreLayout(packageDesignId || designId);
    const _isPreLayout = designConstructor.isPreLayout(layoutDesignId);
    if (_isPreLayout) {
      _designType = designConstructor.getPreLayoutType(layoutDesignId);
    }
    return <Row>
      <Col span={24} className='cascade-setup-border cascade-pcb-setup-content'>
        <Fragment>
          <div className='cascade-pcb-select-box'>
            <PCBSelect
              pcbId={mulitPCBPage ? page : designId}
              disabled={mulitPCBPage}
              projectId={openProjectId}
              selectPCB={this.props.changePCB}
              noPCB={noPCB}
              designStatus={designStatus}
              pcbUpdate={pcbUpdate}
              includePkg={true}
              designType={_designType}
              designName={designName}
              hasPreLayout={true}
            />
          </div>
          {
            !mulitPCBPage ? <Fragment>
              {designType === IMPEDANCE_PCB && designId && <div className='cascade-pcb-target-box'>
                <TargetIC
                  loadComponents={loadComponents}
                  loading={loading}
                  openLoading={openTargetICLoading}
                  _changeTargetIC={this.props.changeTargetIC}
                  loadSelect={impedanceLoadSelect}
                  powerNetSelect={powerNetSelect}
                  updateSelectPowerNets={this.props.updateSelectPowerNets}
                  refresh={this.props.refreshImpedance}
                  noPCB={noPCB}
                  updateOpenTargetICLoading={this.props.updateOpenTargetICLoading}
                />
              </div>}
              {((designType === IMPEDANCE_PACKAGE && designId) || packageDesignId) && <div className='cascade-pcb-target-box'>
                <TargetDie
                  loading={loading}
                  noPCB={noPCB}
                  disabled={isPreLayout}
                  die={targetDie}
                  options={setting && setting.Die ? setting.Die || [] : []}
                  dieSelect={this.props.switchTargetDie}
                />
              </div>}
            </Fragment> : null
          }
          {
            page !== OVERVIEW && <div className='cascade-pcb-target-box'>
              {this.pageButtonRender(mulitPCBPage)}
            </div>
          }
        </Fragment>
      </Col>
    </Row>
  }

  pageButtonRender = (multiPCBPage) => {
    return <Fragment>
      <Button className='cascade-change-page-button' onClick={() => this.changeImpedancePage()}>Overview</Button>
      {
        multiPCBPage && <Fragment>
          <Button className='cascade-change-page-button' onClick={() => this.changePage(true)}>Prev Object</Button>
          <Button className='cascade-change-page-button' onClick={() => this.changePage(false)}>Next Object</Button>
        </Fragment>
      }
    </Fragment>
  }

  setupRender = () => {
    const { nets, width, COMP_PREFIX_LIB } = this.state;
    const { layoutDesignId, layoutDesignType, contentWidth, openProjectId, passiveSpiceList, passiveTouchstoneList } = this.props;
    const _passiveSpiceList = SortFn(passiveSpiceList, sort, 'tag')
    const _passiveTouchstoneList = SortFn(passiveTouchstoneList, sort, 'tag')
    const noPCB = layoutDesignId && !projectDesigns.getDesignExist(openProjectId, layoutDesignId)
    return <Fragment>
      <Row className='space-16'>
        <Col span={24} className='cascade-setup-border cascade-setup-border-position'>
          {layoutDesignType === IMPEDANCE_PACKAGE ?
            <PkgDomainTable
              nets={nets}
              contentWidth={contentWidth ? contentWidth : width}
              noPCB={noPCB}
            />
            : <DomainTable
              nets={nets}
              contentWidth={contentWidth ? contentWidth : width}
              noPCB={noPCB}
              COMP_PREFIX_LIB={COMP_PREFIX_LIB}
            />}
        </Col>
      </Row>
      <Row className='space-16'>
        <Col span={24} className='cascade-setup-border'>
          <ComponentsTable
            noPCB={noPCB}
            passiveSpiceList={_passiveSpiceList}
            passiveTouchstoneList={_passiveTouchstoneList}
          />
        </Col>
      </Row>
    </Fragment>
  }

  advancedRender = () => {
    const { advanced } = this.state;
    const { layoutDesignId, layoutDesignType, additionalNets } = this.props;
    return layoutDesignType === IMPEDANCE_PACKAGE ? null : <Fragment>
      <Row className='space-16'>
        <div className='cascade-page-advanced-title'>
          {advanced ? <DownOutlined onClick={this.changeAdvanced} /> : <RightOutlined onClick={this.changeAdvanced} />}
          <span onClick={this.changeAdvanced}>Advanced</span>
        </div>
      </Row>
      {
        advanced ? <Row className='space-16'>
          <Col span={24} className='cascade-setup-border'>
            <AdditionalNets designId={layoutDesignId} additionalNets={additionalNets} saveAdditionalNets={this.props.saveAdditionalNets} />
          </Col>
        </Row> : null
      }
    </Fragment>;
  }

  overviwRender = () => {
    return <Row className='space-16'>
      <Col span={24} className='cascade-setup-border'>
        <Overview />
      </Col>
    </Row>
  }

  capCompRender = () => {
    return <Row className='space-16'>
      <Col span={24} className='cascade-setup-border'>
        <CapComp />
      </Col>
    </Row>
  }

  pageRender = () => {
    const { page } = this.props;
    if (page === OVERVIEW) {
      return <Fragment>
        {this.pcbRender()}
        {this.overviwRender()}
        {this.capCompRender()}
      </Fragment>
    } else {
      return <Fragment>
        {this.pcbRender()}
        {this.setupRender()}
        {this.advancedRender()}
      </Fragment>
    }
  }

  render() {
    const { message } = this.state;
    return <div className='cascade-impedance-content'>
      {this.pageRender()}
      <div id="impedance-content-dialog"></div>
      {message ? <DelConfirm
        type='update'
        onUpdate={() => this.updateCompPrefixVersion(true)}
        cancelUpdate={() => this.updateCompPrefixVersion(false)}
        message={message}
      /> : null}
    </div>
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, designStatus, treeItems, pcbUpdate },
    Impedance: { designId, verificationId, loading, targetIC, powerNetSelect, targetDie,
      updateCompPrefix, openTargetICLoading, designType, designName, page, data },
    library: { customSpiceList, customTouchstoneList } } } = state
  const layout = data.find(item => item.designId === page);
  let additionalNets = [], layoutDesignId = designId, layoutDesignType = designType;
  if (layout) {
    additionalNets = layout.additionalNets || [];
    layoutDesignId = layout.designId;
    layoutDesignType = layout.type
  }
  const versions = data.map(item => ({ designId: item.designId, COMP_PREFIX_LIB: item.COMP_PREFIX_LIB }))
  const singleLayout = data.length > 1 ? false : true;
  const pkg = data.find(item => item.index === TARGET_PACKAGE_INDEX)
  return {
    openProjectId,
    designId,
    verificationId,
    loading,
    targetIC,
    data,
    powerNetSelect,
    designStatus,
    treeItems,
    updateCompPrefix,
    pcbUpdate,
    openTargetICLoading,
    designType,
    designName,
    additionalNets,
    targetDie,
    page,
    layoutDesignId,
    layoutDesignType,
    singleLayout,
    versions,
    packageDesignId: designType === IMPEDANCE_PACKAGE ? null : pkg ? pkg.designId : null,
    passiveSpiceList: customSpiceList,
    passiveTouchstoneList: customTouchstoneList
  };
}

const mapDispatch = (dispatch) => ({
  changeTargetIC(value) {
    dispatch(changeTargetIC(value))
  },
  refreshImpedance(selectNets) {
    dispatch(refreshImpedance(selectNets))
  },
  updateSelectPowerNets(obj) {
    dispatch(updateSelectPowerNets(obj))
  },
  changePCB(designId, designType) {
    dispatch(changePCB(designId, designType))
  },
  updateCompRLCPrefix(update) {
    dispatch(updateCompRLCPrefix(update))
  },
  updateOpenTargetICLoading(loading) {
    dispatch(updateOpenTargetICLoading(loading))
  },
  saveAdditionalNets(nets) {
    dispatch(saveAdditionalNets(nets))
  },
  updatePackage(update) {
    dispatch(updatePackage(update))
  },
  switchTargetDie(die) {
    dispatch(switchTargetDie(die))
  },
  needUpdateCompSetting(boolean) {
    dispatch(needUpdateCompSetting(false))
  },
  changeImpedancePage(page) {
    dispatch(changeImpedancePage(page))
  }
})

export default connect(mapState, mapDispatch)(ImpedanceContent);