import { SortFn } from "../../helper/sort";
import { numberCheck } from "../../helper/dataProcess";
import { strDelimited } from "../../helper/split";
import { RX, BOOLEAN, LIST, STRING, INTEGER, RANGE, CORNER, FLOAT, TAP, GAUSSIAN, PARAMETER_DJRJ, PARAMETER_DUAL_DIRAC } from "./constants";

export function getTableAmiParameters(amiParameters = [], tableDisplay = false, modelName = '', fileInfo = {}, type = RX) {
  if (typeof amiParameters === 'object') {
    amiParameters = objectToArrayWithKeyName(amiParameters);
  }
  let dataList = [];
  let sortList = amiParameters.map(item => item.sort).sort((a, b) => Number(a) - Number(b));
  dataList = SortFn(amiParameters, sortList, "sort");
  if (!tableDisplay) {
    return dataList;
  }
  const amis = fileInfo.ibisAmi ? fileInfo.ibisAmi.amis : [];
  const models = fileInfo.ibisAmi ? fileInfo.ibisAmi.models : [];
  const findModel = models.find(item => item.name === modelName);
  const algorithmicModels = findModel ? findModel.algorithmicModels : [];
  const algorithmicModelsItem = algorithmicModels && algorithmicModels.length ? modelName.toLowerCase().includes(type) ? algorithmicModels.find(item => item.parameterFile.toLowerCase().includes(type)) : algorithmicModels[0] : {};
  const parameterName = algorithmicModelsItem ? algorithmicModelsItem.parameterFile : '';
  const findAmis = amis.find(item => item.name === parameterName);
  const ibisParameters = findAmis ? findAmis.parameters : {};
  let list = [], typeList = [];
  for (let param of dataList) {
    const findParam = ibisParameters[param.name] || {};
    let groupLength = 0;
    if (!typeList.includes(param.type)) {
      typeList.push(param.type);
      groupLength = dataList.filter(item => item.type === param.type).length;
    }

    list.push({
      value: findParam.Value,
      ...param,
      description: getDescription(findParam),
      groupLength,
      valueType: findParam.valueType,
      valueList: findParam.valueList ? findParam.valueList : [],
      disable: findParam.disable,
      valueFormat: findParam.Type,
      displayValue: findParam.displayValue
    })
  }
  return list;
}

function objectToArrayWithKeyName(obj) {
  if (!obj) return [];
  return Object.entries(obj).map(([key, value]) => ({
    name: key,
    ...value
  }));
}

function getDescription(param) {
  let des = param.Description || "";
  switch (param.valueType) {
    case CORNER:
      des += param[CORNER] ? ` Corner: ${param[CORNER]}` : "";
      break;
    case RANGE:
      des += param[RANGE] ? ` Range: ${param[RANGE]}` : "";
      break;
    default: break;

  }
  return des;
}

export function getTableAmiParametersValue(record) {
  if (record.valueFormat === STRING) {
    //`"6.0" to 6.0
    return record.value.replace(/^"|"$/g, "");
  }
  return record.value;
}

export function amiParameterValueCheck(value, range, title, record) {
  const numError = numberCheck(value);
  if (numError) {
    return `${title} ${numError} `;
  }

  if (record.valueFormat === INTEGER) {
    //is integer
    if (!/^((\d+)|(-\d+)|(0+))$/g.test(value)) {
      return `${title} value must be a integer. `
    }
  }

  if (!range || range.length < 2) {
    return null;
  }
  if (parseFloat(value) < parseFloat(range[0]) || parseFloat(value) > parseFloat(range[1])) {
    return `${title} value should be between ${range[0]} ~ ${range[1]}.`;
  }
  return null;
}

export function ibisAMIModelParamsParse(ibisAmi) {
  if (!ibisAmi || !ibisAmi.amis) {
    return;
  }

  for (let amisItem of ibisAmi.amis) {
    if (!amisItem.parameters) {
      continue;
    }
    for (let paramKey in amisItem.parameters) {
      amisItem.parameters[paramKey] = ibisAMIParameterParse(amisItem.parameters[paramKey])
    }
  }
  return ibisAmi
}


function ibisAMIParameterParse(param) {
  if (param[RANGE]) {
    //Range : "1.100 0.100 1.100"
    const rangeList = strDelimited(param[RANGE], " ");
    param.valueType = RANGE;
    param.valueList = rangeList.filter((item, index) => index > 0 && !!item);
  }

  if (param[CORNER]) {
    //Corner: "50 55 45"
    const cornerTypes = ["Typ", "Slow", "Fast"];
    param.valueType = CORNER;
    const valueList = strDelimited(param[CORNER], " ").filter(item => !!item);
    param.valueList = valueList.map((item, index) => { return { type: cornerTypes[index], value: item } })
  }

  if (param.Type === BOOLEAN) {
    param.valueType = BOOLEAN;
    param.valueList = ["True", "False"];
  }

  if (param[LIST]) {
    //List : "1 2 3 4 5"
    param.valueType = LIST;
    let valueList = [];
    if (param.Type === STRING) {
      valueList = strDelimited(param[LIST], "\" ").filter(item => !!item);
      valueList = valueList.map((item, index) => {
        if (index < valueList.length - 1) {
          return `${item}"`;
        }
        return item;
      })
    } else {
      valueList = strDelimited(param[LIST], " ").filter(item => !!item);
    }
    param.valueList = valueList;
  }

  if (param.Type === TAP) {
    param.valueType = TAP;
  }

  if (param[GAUSSIAN]) {
    //Gaussian <mean> <sigma>
    const [mean, sigma] = strDelimited(param[GAUSSIAN], " ");
    param.valueType = GAUSSIAN;
    param.mean = mean;
    param.sigma = sigma;
    param.displayValue = param[GAUSSIAN];
  }

  if (param[PARAMETER_DUAL_DIRAC]) {
    //Dual-Dirac <mean> <mean> <sigma>
    param.valueType = PARAMETER_DUAL_DIRAC;
    param.displayValue = param[PARAMETER_DUAL_DIRAC];
  }

  if (param[PARAMETER_DJRJ]) {
    //DjRj <minDj> <maxDj> <sigma>
    param.valueType = PARAMETER_DJRJ;
    param.displayValue = param[PARAMETER_DJRJ];
  }

  return param;
}