import { UPDATE_SIMULATION_REDUCER, CLEAR_SIMULATION_INFO, UPDATE_CURRENT_SIMULATION_INFO, UPDATE_CURRENT_SIMULATION_PART_INFO } from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { CLEAR_ALL_BMA_INFO } from '../channel/actionType';

const defaultState = {
};

export const BMASimulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SIMULATION_REDUCER:
      return {
        ...state,
        ...action.info
      }
    case UPDATE_CURRENT_SIMULATION_INFO:
      return {
        ...state,
        [action.verificationId]: action.info
      }
    case UPDATE_CURRENT_SIMULATION_PART_INFO:
      const _info = state[action.verificationId] ? {
        ...state[action.verificationId],
        ...action.info
      } : { ...action.info };
      return {
        ...state,
        [action.verificationId]: _info
      }
    case USER_LOGOUT:
    case CLEAR_SIMULATION_INFO:
    case CLEAR_ALL_BMA_INFO:
      return {
        ...defaultState
      }
    default: return state;
  }
}