import React, { Component } from 'react'
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import SimulationOption from '@/components/PinToPin/SimulationOption';
import { simulationValueCheck } from '@/services/helper/simulationValueCheck';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import './index.css';

class OptionPanel extends Component {
  constructor(props) {
    super(props)
    this.dialogRoot = document.getElementById('root');
    this.state = {
      error: null,
      maxHeight: 500,
      maxWidth: 500
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 500),
      maxWidth: getPanelMaxWidth(offset, 500)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  handleChange = (e, type) => {
    let value, _value = null;
    let { currentConfig } = this.props;
    const { error } = this.state;
    let config = { ...currentConfig }
    if (type === 'cycles') {
      _value = e.target.value;
      config.cycles = _value;
    } else if (type === 'simulate') {
      _value = e;
      config.simulate = _value;
      this.props._updateOptions(type, _value, 'value');
    } else if (type === 'ngspiceMDFLM') {
      config.ngspiceMDFLM = `${e.target.value}ps`;
    } else if (type === 'hspiceCores') {
      config.hspiceCores = e
    } else {
      // "clock","timeStep"
      // Enter value
      value = e.target.value;
      config[type].value = value;
    }
    const newError = error && (error.type === type || (type === 'simulate' && error.type === 'ngspiceMDFLM')) ? null : error;
    this.setState({
      error: newError
    })
    this.props._updateCurrentConfig(config);
  }

  configKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  configSave = (e, type) => {
    // TODO - refactor
    const { currentConfig } = this.props;
    const { error } = this.state;
    let value = type === 'ngspiceMDFLMSelect' ? e : e.target.value;
    let _type = type === 'ngspiceMDFLMSelect' ? 'ngspiceMDFLM' : type;
    //value error check
    const unit = type === 'clock' ? currentConfig.clock.unit : (type === 'timeStep' ? currentConfig.timeStep.unit : null);
    const _check = simulationValueCheck(_type, value, unit);
    value = type.match('ngspiceMDFLM') ? `${value}ps` : value;
    value = type === 'hspiceCores' ? Number(value) : value;
    if (_check) {
      this.setState({
        error: { type: _type, error: _check }
      });
      if (type !== 'ngspiceMDFLMSelect') {
        e.target.focus();
      }
      return;
    } else {
      if (error && error.type === _type) {
        this.setState({
          error: null
        })
      }
      this.props._updateOptions(_type, value, 'value', currentConfig.fixTimeStep);
    }
  }

  editOptionsUnit = (unit, type) => {
    this.props._updateOptions(type, unit, 'unit');
  }

  closePanel = () => {
    const { error } = this.state;
    const { currentConfig } = this.props;
    const check = simulationValueCheck('ngspiceMDFLM', currentConfig['ngspiceMDFLM'].replace('ps', ''));
    if (error) {
      return;
    } else if (check) {
      const config = { ...currentConfig, ngspiceMDFLM: '30ps' };
      this.props._updateCurrentConfig(config);
    }
    this.props.closePanel();
  }

  changeSwitch = (e, checked) => {
    e && e.stopPropagation();
    const { currentConfig } = this.props;
    const config = { ...currentConfig, fixTimeStep: checked };
    this.props._updateCurrentConfig(config);
  }

  render() {
    const { currentConfig, applyRender } = this.props;
    const { error, maxWidth, maxHeight } = this.state;
    const content = (
      <Panel
        className='sierra-top-bar-option-panel sierra-panel'
        title={<div className='sierra-top-bar-option-title'>Simulation Options</div>}
        onCancel={this.closePanel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 500 })}
        position='panel-center'
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        /*  height={maxHeight > 250 ? null : 250} */
        minHeight={200}
        overflow={"auto"}
      >
        <div className='sierra-simulation-option-content'>
          <SimulationOption
            config={currentConfig}
            handleChange={this.handleChange}
            handleKeyDown={this.configKeyDown}
            saveOption={this.configSave}
            changeOptionsUnit={this.editOptionsUnit}
            changeSwitch={this.changeSwitch}
            error={error ? error.error : null}
          />
          {applyRender ? applyRender() : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default OptionPanel