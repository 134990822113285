import React, { Component, createRef, Fragment } from "react";
import { connect } from 'react-redux';
import { uploadPCB, replacePCB } from '@/services/api/Design/design';
import {
  uploadPCBStart,
  updateUploadProgress,
  updateMsg,
  cleanUploadPCBStatus,
  translationFlow,
  updateCompressProgress,
  updateDesignName
} from './store/action';
import { LAYOUT, PACKAGE as DESIGN_PACKAGE } from '@/constants/designType';
import {
  openTabFooter,
  changeTabMenu,
  cleanTabMonitorStatus
} from '../../../MonitorStore/action';
import {
  fakeProgress,
  splitDesignFileName
} from '@/services/helper/dataProcess';
import NP from 'number-precision';
import projectConstructor from "@/services/Andes_v2/project/projectConstructor";
import { PACKAGE, PACKAGES, PCB } from "../../../../constants/treeConstants";
import { ZIP_ACCEPT_STR } from "../../../../services/PCBHelper/pcbHelper";

class DesignUpload extends Component {

  constructor(props) {
    super(props);
    this.vendor = null;
    this.designType = null;
    this.projectID = props.projectId;
    this.state = {
      acceptFormat: null
    }
    this.uploadRef = createRef();
    this.uploadDirectory = createRef();
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  uploadPCB = (vendor, format, update, pcbType) => {
    this.vendor = vendor;
    this.designType = pcbType === PACKAGES ? DESIGN_PACKAGE : LAYOUT;
    this.update = update;
    this.pcbType = pcbType === PACKAGES ? PACKAGE : PCB;
    const { projectId } = this.props;
    const findProject = projectConstructor.getProject(projectId);
    //clear monitor box info and open monitor box
    this.props._cleanTabMonitorStatus();
    let info = {
      tabSelectKeys: ['pcb'],
      menuType: 'simulation',
      currentProjectId: projectId,
      projectName: findProject ? findProject.name : null,
    }

    this.props._changeTabMenu(info);
    this.props._openTabFooter();

    if (format === 'directory') {
      const el = this.uploadDirectory.current;
      if (!el) return;
      el.click();
    } else {
      this.setState({
        acceptFormat: format
      }, () => {
        const ele = this.uploadRef.current;
        if (!ele) return;
        ele.click();
      })
    }
  }

  uploadChange = (e) => {
    const { projectId } = this.props;
    this.props._uploadPCBStart(projectId);
    const { acceptFormat } = this.state;
    const files = e.target.files;
    let progress = 2, indexNum = 0, isZip = false;
    if (files.length === 1 &&
      (['application/x-zip-compressed', 'application/zip', "application/x-compressed-tar"].includes(files[0].type)
        || acceptFormat === ZIP_ACCEPT_STR)) {
      this.props._updateCompressProgress(-1);
      this.props._updateUploadProgress(0);
      this.designName = files[0].name;
      isZip = true;
      this.props._updateMsg(`==> Start uploading ${this.pcbType}: "${files[0].name}".`);
    } else {
      const number = files[0].webkitRelativePath.indexOf('/');
      const fileName = files[0].webkitRelativePath.substring(0, number);
      this.designName = fileName ? fileName : files[0].name;
      this.props._updateMsg(`==> Start compressing ${this.pcbType}: "${this.designName}".`);
      this.compressTime = setInterval(() => {
        indexNum += 1;
        progress = fakeProgress(progress, indexNum);
        this.props._updateCompressProgress(progress)
      }, 2000);
    }
    this.props._updateDesignName(this.designName, this.pcbType);
    this.upload = false;
    this.uploadLayout(files, isZip, this.designName);
  }

  metadata = (metadata) => {
    clearInterval(this.compressTime)
    const progress = NP.strip(parseFloat(metadata.percent.toFixed(0)));
    let { compressProgress } = this.props;
    //Avoid progress updates too fast
    if ((progress - compressProgress) >= 10 || progress === 100) {
      this.props._updateCompressProgress(progress);
    }

    if (progress === 100 && !this.upload) {
      this.upload = true;
      this.props._updateMsg(`==> Finish compressing ${this.pcbType}.\n==> Start uploading ${this.pcbType}: "${this.designName}".`);
      return;
    }
  }

  uploadLayout = (files, isZip, fileName) => {
    const _name = splitDesignFileName(fileName);
    const { projectId, data } = this.props;
    if (this.update) {
      //  replace pcb
      replacePCB({
        files,
        designId: data.id,
        name: data.name,
        isZip,
        projectId,
        type: this.designType,
        vendor: this.vendor,
        metadata: this.metadata,
        config: this.config
      }).then(response => {
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
        this.props._translationFlow(response, data.id, this.pcbType);
      }, error => {
        this.props._updateMsg(`==> Replace failed.`);
        //clean upload pcb status(progress,visible,disabled)
        this.props._cleanUploadPCBStatus();
        console.error(error)
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
      })
    } else {
      uploadPCB({
        files,
        name: _name,
        isZip,
        projectId,
        type: this.designType,
        vendor: this.vendor,
        metadata: this.metadata,
        config: this.config
      }).then(response => {
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
        this.props._translationFlow(response, null, this.pcbType);
      }, error => {
        this.props._updateMsg(`==> Upload failed.`);
        //clean upload pcb status(progress,visible,disabled)
        this.props._cleanUploadPCBStatus();
        console.error(error)
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
      })
    }
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      // console.debug('onUploadProgress called with', arguments, 'Percent Completed:' + percentCompleted)
      this.props._updateUploadProgress(percentCompleted);
    }
  }

  fileClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { acceptFormat } = this.state;
    return (
      <Fragment>
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={acceptFormat}
          onChange={this.uploadChange}
          onClick={(e) => this.fileClick(e)}
          id="andes-v2-pcb-upload-file"
        />
        <input
          type='file'
          ref={this.uploadDirectory}
          style={{ display: 'none' }}
          onChange={this.uploadChange}
          webkitdirectory="true"
          directory="true"
          onClick={(e) => this.fileClick(e)}
        />
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { AndesV2UploadReducer } = state.AndesV2Reducer;
  const { compressProgress } = AndesV2UploadReducer;

  return {
    compressProgress
  }
}

const mapDispatch = (dispatch) => ({
  _cleanTabMonitorStatus() {
    dispatch(cleanTabMonitorStatus())
  },
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  _changeTabMenu(info) {
    dispatch(changeTabMenu(info))
  },
  _uploadPCBStart(projectId) {
    dispatch(uploadPCBStart(projectId))
  },
  _updateUploadProgress(progress) {
    dispatch(updateUploadProgress(progress))
  },
  _updateMsg(msg) {
    dispatch(updateMsg(msg))
  },
  _translationFlow(response, designId, pcbType) {
    dispatch(translationFlow(response, designId, pcbType))
  },
  _cleanUploadPCBStatus() {
    dispatch(cleanUploadPCBStatus())
  },
  _updateCompressProgress(progress) {
    dispatch(updateCompressProgress(progress))
  },
  _updateDesignName(designName, designType) {
    dispatch(updateDesignName(designName, designType))
  }
})
export default connect(mapState, mapDispatch)(DesignUpload);