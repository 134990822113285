import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { Divider, Radio, Input, Switch } from 'antd';
import { createPortal } from 'react-dom';
import { getPanelWidth } from '@/services/helper/panelSizeHelper';
import { TreePlotSetting } from '../../../../services/Cascade/PowerTree';
import { numberCheck } from '../../../../services/helper/dataProcess';
import './index.css';


class TreeSettingPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxWidth: 862,
      maxHeight: 810,
    }
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    this.props.closeModal(false);
    const { setting } = this.props;
    this.props.updateTreeSetting(setting, { close: true })
  }

  colorChange = (e, key) => {
    // const value = e.target.value;
  }

  changeSwitch = (checked, key, params = {}) => {
    const { setting } = this.props;
    const _setting = new TreePlotSetting({ ...setting, [key]: checked });
    this.props.updateTreeSetting(_setting, params)
  }

  inputBlur = (e, key, params = {}) => {
    const value = e.target.value;
    const { setting } = this.props;
    if (!value || numberCheck(value)) {
      return;
    }
    const _setting = new TreePlotSetting({ ...setting, [key]: value });
    this.props.updateTreeSetting(_setting, params)
  }

  changeType = (e, key, redraw) => {
    const value = e.target.value;
    const { setting } = this.props;
    const _setting = new TreePlotSetting({ ...setting, [key]: value });
    this.props.updateTreeSetting(_setting, { redraw })
  }

  onPressEnter = (e) => {
    e.target.blur()
  }

  netRender = () => {
    const { setting: { netColor, gndColor, connColor } } = this.props;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Power Net Color</span>
        <span className='cascade-tree-plot-net-color'>
          <input
            type='color'
            className="color-select"
            value={netColor || ''}
            onChange={(e) => this.colorChange(e, 'netColor')}
          />
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Ground Net Color</span>
        <span className='cascade-tree-plot-net-color'>
          <input
            type='color'
            className="color-select"
            value={gndColor || ''}
            onChange={(e) => this.colorChange(e, 'gndColor')}
          />
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Connection Color</span>
        <span className='cascade-tree-plot-net-color'>
          <input
            type='color'
            className="color-select"
            value={connColor || ''}
            onChange={(e) => this.colorChange(e, 'connColor')}
          />
        </span>
      </div>
    </Fragment>
  }

  gridRender = () => {
    const { setting: { gridWidth, gridHeight, autoWidth, autoHeight } } = this.props;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Grid Width</span>
        <span>
          <Radio.Group value={autoWidth} onChange={(e) => this.changeType(e, 'autoWidth', true)}>
            <Radio value={true}>Auto</Radio>
            <Radio value={false}>Custom</Radio>
          </Radio.Group>
          {<Input
            defaultValue={gridWidth}
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            style={{ width: 'calc(45% - 145px)' }}
            onBlur={(e) => this.inputBlur(e, 'gridWidth', { redraw: true })}
            onPressEnter={this.onPressEnter}
            disabled={autoWidth}
          />}
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Grid Height</span>
        <span>
          <Radio.Group value={autoHeight} onChange={(e) => this.changeType(e, 'autoHeight', true)}>
            <Radio value={true}>Auto</Radio>
            <Radio value={false}>Custom</Radio>
          </Radio.Group>
          {<Input
            defaultValue={gridHeight}
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            style={{ width: 'calc(45% - 145px)' }}
            onBlur={(e) => this.inputBlur(e, 'gridHeight', { redraw: true })}
            onPressEnter={this.onPressEnter}
            disabled={autoHeight}
          />}
        </span>
      </div>
    </Fragment>
  }

  sizeRender = () => {
    const { setting: { autoSize, nameSize, passSize, pairResSize, voltageSize } } = this.props;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Auto Font Size</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={autoSize}
          onChange={(checked) => this.changeSwitch(checked, 'autoSize')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Component Name and Input Value</span>
        <Input className='cascade-top-option-panel-content-input'
          defaultValue={nameSize}
          onBlur={(e) => this.inputBlur(e, 'nameSize')}
          onPressEnter={this.onPressEnter}
          disabled={autoSize}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Path Resistance</span>
        <Input className='cascade-top-option-panel-content-input'
          defaultValue={pairResSize}
          onBlur={(e) => this.inputBlur(e, 'pairResSize')}
          onPressEnter={this.onPressEnter}
          disabled={autoSize}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Net Voltage</span>
        <Input className='cascade-top-option-panel-content-input'
          defaultValue={voltageSize}
          onBlur={(e) => this.inputBlur(e, 'voltageSize')}
          onPressEnter={this.onPressEnter}
          disabled={autoSize}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Device Voltage</span>
        <Input className='cascade-top-option-panel-content-input'
          defaultValue={passSize}
          onBlur={(e) => this.inputBlur(e, 'passSize')}
          onPressEnter={this.onPressEnter}
          disabled={autoSize}
        />
      </div>
    </Fragment>
  }

  showResultRender = () => {
    const { setting: { pass, pairRes, voltage } } = this.props;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Default display Path Resistance</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pairRes}
          onChange={(checked) => this.changeSwitch(checked, 'pairRes', { result: true })}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Default display Net Voltage</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={voltage}
          onChange={(checked) => this.changeSwitch(checked, 'voltage', { result: true })}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Default display Device Voltage</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pass}
          onChange={(checked) => this.changeSwitch(checked, 'pass', { result: true })}
        />
      </div>
    </Fragment>
  }

  showRender = () => {
    const { setting: { displayRes, displayInd, displaySwitch, displayFerrite, displayDiode, displayJumper, valueDisplay, displayTransistor } } = this.props;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Input Vaule</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={valueDisplay}
          onChange={(checked) => this.changeSwitch(checked, 'valueDisplay')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Resistor</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={displayRes}
          onChange={(checked) => this.changeSwitch(checked, 'displayRes')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Power Switch</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={displaySwitch}
          onChange={(checked) => this.changeSwitch(checked, 'displaySwitch')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Inductor</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={displayInd}
          onChange={(checked) => this.changeSwitch(checked, 'displayInd')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Ferrite</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={displayFerrite}
          onChange={(checked) => this.changeSwitch(checked, 'displayFerrite')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Diode</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={displayDiode}
          onChange={(checked) => this.changeSwitch(checked, 'displayDiode')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Jumper</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={displayJumper}
          onChange={(checked) => this.changeSwitch(checked, 'displayJumper')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Display Transistor</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={displayTransistor}
          onChange={(checked) => this.changeSwitch(checked, 'displayTransistor')}
        />
      </div>
    </Fragment>
  }

  render() {
    const { maxWidth, maxHeight } = this.state;
    const content = (
      <Panel
        className='cascade-top-option-panel'
        title={<div className='cascade-top-option-panel-name'>Power Tree Custom Settings</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth)}
        position='panel-center'
        mask={false}
        draggable
        minWidth={200}
        minHeight={200}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='cascade-top-option-panel-main cascade-top-extraction-panel-main'>
          {this.gridRender()}
          <Divider />
          {this.showResultRender()}
          <Divider />
          {this.showRender()}
          <Divider />
          {this.sizeRender()}
          {/* <Divider />
          {this.netRender()} */}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default TreeSettingPanel;