import React, { Component, Fragment, createRef } from "react";
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { Input } from 'antd';
import NP from 'number-precision';

class ConstraintsPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPowerDomainInfo: this.props.PowerDomainData,
    }

    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.setState({
      offset: this.inputRef.current.getBoundingClientRect()
    })
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
  }

  closeModel = () => {
    const { PowerDomainData } = this.props;
    const { currentPowerDomainInfo } = this.state;
    if (PowerDomainData.maxVia !== currentPowerDomainInfo.maxVia || PowerDomainData.maxBgaI !== currentPowerDomainInfo.maxBgaI || PowerDomainData.maxBgaDv !== currentPowerDomainInfo.maxBgaDv) {
      this.props.updateIRData([currentPowerDomainInfo])
    }
    this.props.save()
  }

  changeValue = (e, type) => {
    e.stopPropagation();
    let _currentPowerDomainInfo = { ...this.state.currentPowerDomainInfo };
    let value = e.target.value;
    if (type === 'maxBgaDv') {
      value = (value / 100).toFixed(3).toString()
    }
    _currentPowerDomainInfo[type] = value;

    this.setState({
      currentPowerDomainInfo: _currentPowerDomainInfo
    })
  }

  renderDialog() {
    const { offset } = this.state;
    const { title, PowerDomainData } = this.props;
    if (offset === undefined) return null;
    const content = (
      <Panel
        className="IR-constraints-panel"
        title={title}
        onCancel={this.closeModel}
        zIndex={2000}
        position='panel-center'
        draggable
        width={500}
      >
        <div className="constraints-panel-content">
          <div className="content-dialog">
            <div className="content-dialog-title">Max BGA Current (A)</div>
            <Input defaultValue={PowerDomainData.maxBgaI} className="content-input" onChange={(value) => this.changeValue(value, 'maxBgaI')} />
          </div>
          <div className="content-dialog">
            <div className="content-dialog-title">Max Via Current (A)</div>
            <Input defaultValue={PowerDomainData.maxVia} className="content-input" onChange={(value) => this.changeValue(value, 'maxVia')} />
          </div>
          <div className="content-dialog">
            <div className="content-dialog-title">Max BGA dV (%)</div>
            <Input defaultValue={NP.times(PowerDomainData.maxBgaDv, 100)} className="content-input" onChange={(value) => this.changeValue(value, 'maxBgaDv')} />
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { inputRef } = this;
    const { PowerDomainData } = this.props;
    return (
      <Fragment>
        <div className="editable-cell-value-wrap" ref={inputRef}>{/* NP.times(data.libraryData.R, 1e6) */}
          <span>Ibga={(PowerDomainData.maxBgaI && (PowerDomainData.maxBgaI)) || '0'}A, Ivia={(PowerDomainData.maxVia && (PowerDomainData.maxVia)) || '0'}A, dVbga={(PowerDomainData.maxBgaDv && NP.times(PowerDomainData.maxBgaDv, 100)) || '0'}%</span>
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default ConstraintsPanel;
