import {
  PARSE_FILE_AND_CREATE_SPICE_CARD,
  DELETE_SPICE_CARD,
  OPEN_SPICE_CARD_TEMPLATE_PAGE,
  UPDATE_SPICE_CARD_INFO,
  UPDATE_SPICE_CARD_DATA,
  CLEAR_SPICE_CARD_INFO
} from './actionTypes';

export const parseFileAndCreateSpiceCard = ({ fileName, file, uploadItemData }) => ({
  type: PARSE_FILE_AND_CREATE_SPICE_CARD,
  fileName,
  file,
  uploadItemData
});

export const deleteSpiceCard = (itemData) => ({
  type: DELETE_SPICE_CARD,
  itemData
});

export const openSpiceCardTemplatePage = (id) => ({
  type: OPEN_SPICE_CARD_TEMPLATE_PAGE,
  id
});

export const updateSpiceCardData = (data) => ({
  type: UPDATE_SPICE_CARD_DATA,
  data
});

export const updateSpiceCardInfo = (data) => ({
  type: UPDATE_SPICE_CARD_INFO,
  data
});

export const clearSpiceCardInfo = () => ({
  type: CLEAR_SPICE_CARD_INFO
});