import { takeEvery, call, select, put } from "redux-saga/effects";
import { message } from 'antd';
import {
  PARSE_FILE_AND_CREATE_SPICE_CARD,
  DELETE_SPICE_CARD,
  OPEN_SPICE_CARD_TEMPLATE_PAGE
} from './actionTypes';
import { SPICE_CARD_TEMPLATE } from "../../../../constants/treeConstants";
import { getDefaultName } from "../../../../services/helper/setDefaultName";
import { strDelimited } from "../../../../services/helper/split";
import himalayasChannels from "../../../../services/Himalayas/project/channelsConstructor";
import {
  uploadSpiceCardTemplate,
  deleteSpiceCardTemplate,
  getSpiceCardContent
} from "../../../../services/Himalayas";
import { updateTree } from "../project/projectSaga";
import { updateSelectedKeys } from "../project/action";
import { updateViewList } from "../project/action";
import {
  updateSpiceCardData,
  updateSpiceCardInfo
} from "./action";
import { getVerificationWorkflow } from "../simulation/action";

function* _uploadSpiceCardTemplate(action) {
  const { fileName, file, uploadItemData } = action;
  const projectId = strDelimited(uploadItemData.key, "-", { returnIndex: 1 });
  const list = himalayasChannels.getList(SPICE_CARD_TEMPLATE, projectId);
  const nameList = list.map(item => item.name);
  const name = getDefaultName({ nameList, defaultKey: 'Sheet', key: "" });
  try {
    const res = yield call(uploadSpiceCardTemplate, { file, fileName, name, projectId })
    if (!res) {
      message.error('Upload Input ".xlsm" file failed!');
      return;
    }
    yield call(updateTree, { res, dataType: SPICE_CARD_TEMPLATE, open: true, name });
  } catch (error) {
    console.error(error);
    message.error('Upload Input ".xlsm" file failed!' + error);
  }
}

function* _delSpiceCard(action) {
  const { itemData } = action;
  try {
    const res = yield call(deleteSpiceCardTemplate, [itemData.id]);
    //update treeItems
    yield call(updateTree, { res, dataType: SPICE_CARD_TEMPLATE });
    // if current opened spice card is deleted item, update selectedKeys and viewList
    const { HimalayasReducer: { project: { selectedKeys, viewList } } } = yield select();
    const findItem = selectedKeys.find(item => {
      const [key, id] = strDelimited(item, "-");
      if (key === SPICE_CARD_TEMPLATE && id === itemData.id) {
        return true;
      }
      return false;
    });

    if (findItem) {
      const _selectedKeys = selectedKeys.filter(item => item !== `${SPICE_CARD_TEMPLATE}-${itemData.id}`);
      const _viewList = viewList.filter(item => item !== SPICE_CARD_TEMPLATE);
      yield put(updateSelectedKeys(_selectedKeys));
      yield put(updateViewList(_viewList));
    }
  } catch (error) {
    console.error(error);
    message.error(`Delete ${itemData.name} failed!` + error);
  }
}

function* _getContent(action) {
  const { id } = action;
  try {
    //get content
    const res = yield call(getSpiceCardContent, id);
    if (!res) {
      return;
    }
    yield put(updateSpiceCardData(res));
    yield put(updateSpiceCardInfo({
      verificationId: res.verificationId,
      channelId: res.channelId
    }));
    //get verification workflow
    yield put(getVerificationWorkflow({
      channelId: res.channelId,
      workType: SPICE_CARD_TEMPLATE,
      verificationId: res.verificationId
    }));
  } catch (error) {
    console.error(error);
  }
}

export default function* spiceCardSaga() {
  yield takeEvery(PARSE_FILE_AND_CREATE_SPICE_CARD, _uploadSpiceCardTemplate);
  yield takeEvery(DELETE_SPICE_CARD, _delSpiceCard);
  yield takeEvery(OPEN_SPICE_CARD_TEMPLATE_PAGE, _getContent);
}