//DEFINE FOR ROCKY RESULT
export const MERGE_PDN = "Merged PDN"
export const MERGE_SIGNAL = "Merged Signal"
export const COMBINED_PDN_AND_SIGNAL = "combined PDN and signal"
export const SINGLE_PATTERN = "Single Pattern"
export const PATTERN_SWEEP = "Pattern Sweep"
export const COMP_INFO = "compInfo"
export const WAVE_FORM_LIST = "waveFormList"
export const EXIST_COLOR = "existColor"
export const HAVE_EXPANDED_SIGNAL = "haveExpandedSignal"
export const SELECTED_COMPONENT_KEYS = "selectedComponentKeys"
export const SELECTED_PATTERN_KEYS = "selectedPatternKeys"
export const PATTERN_SWEEP_LIST = "patternSweep"
export const DIFF_SIGNAL_NAME = "diffSignalName"
export const PATTERN_INFO = "patternInfo"
export const VIX_ID_LIST = ['vix_dq', 'vix_ca'];
export const SEPARATE_PACKAGE = "Separate Package";
export const SEPARATE_PCB = "Separate PCB";
export const MERGED = "Merged";

export const PACKAGE_SEV_CHANNEL_RESULT = "package_sev_channel_result";
export const OVER_UNDER_SHOOT_KEYS = ['shoot_margin', 'value_pass', 'shoot_time', 'shoot_voltage', 'shoot_voltage_spec', 'area_margin', 'area_margin2', 'area_pass', 'area2_pass'];
export const OVER_UNDER_SHOOT_NAMES = ['Overshoot Margin', 'Overshoot Area Margin', 'Overshoot Area Margin2', 'Undershoot Margin', 'Undershoot Area Margin', 'Undershoot Area Margin2',
    'Differential Overshoot Margin', 'Differential Undershoot Margin', 'Differential Overshoot Area Margin', 'Differential Undershoot Area Margin'];
