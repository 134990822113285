import { getDimmCardRankIdPromise } from "@/services/Rocky";

class RankConstructor {
  constructor() {
    this.rankInfo = new Map(); //key : {id,type}, value : rank
  }

  getRank = (id) => {
    const rank = this.rankInfo.get(id)
    return rank || null
  }

  setRank = (id, rank) => {
    this.rankInfo.set(id, rank)
  }

  getRankValues = async ({ id, type }) => {
    if (!id || !type) {
      return null;
    }
    let rank = this.getRank(id)
    if (!rank) {
      try {
        rank = await getDimmCardRankIdPromise({ id, type })
        this.setRank(id, rank)
      } catch (error) {
        console.error(error)
      }
    }
    return rank
  }
}

let rankConstructor = new RankConstructor()
export default rankConstructor