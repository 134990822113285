import { CAP, IGNORE } from '../../PCBHelper';
import { _getVRM, getPMIC } from '../helper/setupData'
import settingStore from '../helper/compSettingHelper';
import componentDoNotStuff from '../../helper/componentsHelper/compDoNotStuff';
import compTableHelper from '@/services/Cascade/helper/compTableHelper.js'
import { strDelimited } from '../../helper/split';
import pinMapStore from '@/services/Cascade/library/pinMapHelper'
import compPinMap from '../helper/compPinMap';
import { SortFn } from '../../helper/sort';
import sleep from '../../helper/sleep';
import { PARTBASED } from '../../../constants/resolution';
import auroraDBJson from '../../Designs/auroraDbData';

function getTemplateComponents({ components, designId, componentsTableDisplay }) {
  let comps = [];
  for (let comp of components) {
    if (!comp) {
      continue;
    }
    const { name, part, pins, usage, COMP_TYPE, model, models, applySweep } = comp;
    const partNumber = auroraDBJson.getPartNumberByPartName(designId, part);
    if (componentsTableDisplay === PARTBASED) {
      const findIndex = comps.findIndex(item => item.part === part && item.usage === usage);
      if (findIndex > -1) {
        comps[findIndex].components.push({ name, pins, usage })
      } else {
        comps.push({
          model,
          models,
          applySweep,
          COMP_TYPE,
          part,
          partNumber,
          usage,
          applySweep,
          components: [{
            name,
            pins,
            usage
          }]
        })
      }
    } else {
      comps.push({
        model,
        models,
        applySweep,
        COMP_TYPE,
        part,
        partNumber,
        usage,
        components: [{
          name,
          pins,
          usage
        }]
      })
    }
  }
  comps = SortFn(comps, [CAP, IGNORE], 'usage');
  return comps;
}

async function updateOnePowerCapComponents({
  signOffTemplateInfo,
  templates
}) {

  const templateInfo = getGroupByTemplate(templates, signOffTemplateInfo.soc, signOffTemplateInfo.gnd);
  if (!templateInfo) {
    return { signOffTemplateInfo, newNoModelCompNames: [] };
  }

  let components = signOffTemplateInfo.components || [];
  const designId = signOffTemplateInfo.designId;
  let signOffTemplate = signOffTemplateInfo.signOffTemplate;
  let newNoModelCompNames = [], prevComps = [];
  const templateIndex = signOffTemplate.findIndex(item => item.groupKey === templates[0].groupKey);

  if (templateIndex > -1) {
    prevComps = signOffTemplate[templateIndex].components ? [...signOffTemplate[templateIndex].components] : [];
    signOffTemplate[templateIndex].components = [];
  }

  let { soc, powerNetName, gnd, pmicList, pmicAndNets } = templateInfo || {};

  let _setting = await settingStore.getSetting({ designId });
  let doNotStuff = await componentDoNotStuff.getDoNotStuff(designId);
  const table = await compTableHelper.getTableData(designId);
  // const allComponents = pmicList.length ? getAllCascadeComponents({ pcbId: designId }) : [];
  pmicList = [...new Set([...pmicList])];

  // for (let pmic of pmicList) {
  //   const findPMIC = allComponents.find(item => item.name === pmic) || {};
  //   const pmicPart = findPMIC.part;

  //   //add pmic part to specialized
  //   const prevPmicParts = [..._setting.compPrefixLib.specialized,
  //   ..._setting.compPrefixLib.linearRegulator,
  //   ..._setting.compPrefixLib.linearSwitch,
  //   ..._setting.compPrefixLib.switchingRegulator
  //   ]
  //   const existPmic = prevPmicParts.includes(pmicPart);
  //   const newSpecialized = pmicPart && !existPmic ? [...new Set([..._setting.compPrefixLib.specialized, pmicPart])] : [..._setting.compPrefixLib.specialized];
  //   //update setting
  //   if (settingStore.compareSetting(designId, { ..._setting.compPrefixLib, specialized: newSpecialized })) {
  //     const newVersion = versionUpdate(_setting.version);
  //     const newSetting = {
  //       designId, componentSetting: {
  //         compPrefixLib: { ..._setting.compPrefixLib, specialized: newSpecialized },
  //         version: newVersion
  //       }
  //     }
  //     settingStore.updateSetting([newSetting]);
  //     _setting = await settingStore.getSetting({ designId });
  //   }
  // }

  /* pin: 'M12', net: 'ARM_REF_CLK', pinName: 'M12', mName: 'M12' */

  let loadSelect = [{ comp: soc }];

  const pinMapSense = await pinMapStore.getPinMapSense(designId, "pinName")


  let pinMap = await compPinMap.getPinMapData(designId);
  const pinConnection = await compPinMap.getPinConnection(designId);

  let pinMapList = []
  for (let _map of pinMap) {
    let data = [];
    if (_map.libraryId) {
      try {
        const res = await pinMapStore.getPinMapLibrary(_map, designId, "pinName")
        data = res && res.config && res.config.pinTable ? [...res.config.pinTable] : [];
      } catch (error) {
        console.error("Can not find library file");
      }
    }
    pinMapList.push({ ..._map, data })
  }
  const specify = pmicAndNets.map(item => ({ name: item.pmic, nets: [item.net] }))
  let VRMInfo = _getVRM({
    designId,
    GroundNets: [gnd],
    PowerNets: [powerNetName],
    ExtendNets: [],
    COMP_PREFIX_LIB: _setting.compPrefixLib,
    loadSelect,
    connectInductance: false,
    findExtend: true,
    isAC: true,
    PMIC: getPMIC(_setting.compPrefixLib),
    powerSwitch: _setting.compPrefixLib.powerSwitch,
    doNotStuff,
    table,
    pinMapSense,
    pmicAndNets,
    pinMap: pinMapList,
    specify,
    pinConnection
  })
  //filter vrm by pmic list(user selected pmic components)
  const filterVRMPath = VRMInfo.DEBUG_MONITOR.filter(item => pmicList.includes(item[item.length - 1].name))
  // if (!pmicList.length || filterVRMPath.length) {

  const allPowerNets = filterVRMPath.filter(item => item.type === "net").map(item => item.name);
  //filter components
  const filterComponents = !pmicList.length ? VRMInfo.Components : VRMInfo.Components.filter(item => {
    item.pins = item.pins.filter(it => [...allPowerNets, gnd].includes(it.net));
    if (item.pins.length) {
      return item;
    }
    return false;
  });
  for (let comp of filterComponents) {
    if (comp.COMP_TYPE !== "Cap") {
      continue;
    }
    const index = components.findIndex(it => it.name === comp.name);
    if (index < 0) {
      const samePartComp = components.find(it => it.part === comp.part);
      if (samePartComp && samePartComp.models && samePartComp.models.length) {
        comp.models = JSON.parse(JSON.stringify(samePartComp.models));
      } else {
        newNoModelCompNames.push(comp.name);
      }
      components.push(comp);
    } else {
      const pinList = components[index].pins.map(item => item.pin);
      const _pins = comp.pins.filter(item => !pinList.includes(item.pin));
      components[index].pins.push(..._pins);
      components[index].usage = comp.usage;
    }
    if (templateIndex > -1) {
      signOffTemplate[templateIndex].components.push(...comp.pins.map(it => `${comp.name}::${it.pin}`))
    }
  }
  /*}  else {
    //todo if user selected pmic not find, show error message and delete prev components
      components = deleteComponents(prevComps, signOffTemplate, components);
    } */

  signOffTemplateInfo.components = components;
  signOffTemplateInfo.signOffTemplate = signOffTemplate;
  return { signOffTemplateInfo, newNoModelCompNames };
}

function getGroupByTemplate(templateList, soc, gnd) {
  let groupObj = { extendNets: [], pmicList: [], pmicAndNets: [] };
  const socFind = templateList.find(item => item.compType !== "PMIC") || {};
  groupObj.powerNetName = socFind.powerNetName;
  groupObj.soc = socFind.refdes || soc;
  groupObj.gnd = socFind.gndNetName || gnd;

  if (!groupObj.powerNetName || !groupObj.soc || !groupObj.gnd) {
    return null;
  }

  const pmicList = templateList.filter(item => item.compType === "PMIC");

  for (let item of pmicList) {
    groupObj.extendNets = [...new Set([...groupObj.extendNets, item.powerNetName])];
    groupObj.pmicList.push(item.refdes);
    groupObj.pmicAndNets.push({ net: item.powerNetName, pmic: item.refdes });
  }
  return groupObj;
}

async function updateTemplateItemCompsByVersion(signOffTemplateInfo, newTemplates, saveLogs) {
  const _newTemplates = newTemplates ? newTemplates : signOffTemplateInfo.signOffTemplate;

  const groupList = [...new Set(_newTemplates.map(item => item.groupKey))];
  for (let group of groupList) {
    const [powerNet, , , isPmic] = strDelimited(group, "::");
    if (isPmic) {
      continue;
    }
    if (saveLogs && powerNet) {
      saveLogs([{ type: 'normal', text: `[${powerNet}] Searching Cap of ${powerNet}.` }]);
      await sleep(100);
    }
    const templates = signOffTemplateInfo.signOffTemplate.filter(item => item.groupKey === group);
    const data = await updateOnePowerCapComponents({
      signOffTemplateInfo: signOffTemplateInfo,
      templates
    })
    signOffTemplateInfo = data.signOffTemplateInfo;
  }
  return signOffTemplateInfo;
}

function deleteComponents(delTemplateComps, templates, components) {
  if (!delTemplateComps) {
    return components;
  }
  const existTemplates = templates.filter(item => item.components && item.components.length);
  let existComponents = [];
  for (let item of existTemplates) {
    existComponents.push(...item.components);
  }
  existComponents = [...new Set(existComponents)];
  const _delTemplateComps = delTemplateComps.filter(item => !existComponents.includes(item));

  components.forEach(item => {
    item.pins = item.pins.filter(it => !_delTemplateComps.includes(`${item.name}::${it.pin}`));
  });
  components = components.filter(item => !!item.pins.length);
  return components;
}

export {
  getTemplateComponents,
  updateOnePowerCapComponents,
  updateTemplateItemCompsByVersion,
  deleteComponents
}