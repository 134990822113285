import {
  OPEN_PRE_LAYOUT,
  ADD_PRE_LAYOUT,
  UPDATE_PRE_LAYOUT,
  UPDATE_CONFIG,
  GET_PRE_LAYOUT,
  ADD_SECTION,
  LOADING_TYPE,
  UPDATE_LOACTIONS,
  FINISH_DRAW,
  CLK_SECTION_LENGTH,
  UPDATE_LAYER,
  AUTO_FILL,
  CHANGE_AUTO_FILL,
  CLEAR_PRE_LAYOUT,
  PRE_LAYOUT_INIT_STATUS,
  CHANGE_LIBRARY_FILE,
  CHANGE_UNIT,
  CHANGE_DATA_BY_UNIT,
  EXPORT_EXCEL,
  IMPORT_EXCEL,
  PRELAYOUT_LOG,
  PRELAYOUT_ERROR,
  UPDATE_MODEL_PRELAYOUT_CONTENT,
  SCHEMATIC_LOADING,
  UPDATE_COMPONENTS,
  SAVE_MODEL_PRELAYOUT_INFO,
  SAVE_PRE_LAYOUT_MODEL,
  ADD_RES_COMPONENTS,
  CHANGE_PRELAYOUT_TYPE,
  UPDATE_PRELAYOUT_PACKAGE_ID,
  SAVE_PRE_LAYOUT_COMPONENTS,
  CHANGE_INCLUDES_RES,
  RENAME_PRE_LAYOUT
} from './actionTypes';

export const openPreLayout = ({ projectId, id, preLayout, pcbName, designVersion, version, first, pcbType, verificationId, channelId, name }) => ({
  type: OPEN_PRE_LAYOUT,
  id,
  projectId,
  pcbName,
  preLayout,
  designVersion,
  version,
  first,
  pcbType,
  verificationId,
  channelId,
  name
});

export const getRockyPreLayout = ({ id, view }) => ({
  type: GET_PRE_LAYOUT,
  id,
  view
})

export const createPreLayout = ({ projectId, DDRType, pcbType }) => ({
  type: ADD_PRE_LAYOUT,
  projectId,
  DDRType,
  pcbType
})

export const updatePreLayoutContent = (content) => ({
  type: UPDATE_PRE_LAYOUT,
  content
})

export const updateConfig = (config, update, changeUnit) => ({
  type: UPDATE_CONFIG,
  config,
  update,
  changeUnit
})

export const addSection = (prevNumber) => ({
  type: ADD_SECTION,
  number: prevNumber
})

export const changeLoadingType = (loading) => ({
  type: LOADING_TYPE,
  loading
})

export const updateLocation = (locations) => ({
  type: UPDATE_LOACTIONS,
  locations
})

export const finishDraw = () => ({
  type: FINISH_DRAW,
})

export const changeCLKSectionLength = (partLength) => ({
  type: CLK_SECTION_LENGTH,
  partLength
})

export const updateLayer = (layer) => ({
  type: UPDATE_LAYER,
  layer
})

export const autoFill = () => ({
  type: AUTO_FILL
})

export const changeFillStatus = (status) => ({
  type: CHANGE_AUTO_FILL,
  status
})

export const clearPreLayout = () => ({
  type: CLEAR_PRE_LAYOUT
})

export const changePreLayoutInit = () => ({
  type: PRE_LAYOUT_INIT_STATUS
})

export const changeLibraryStatus = (status) => ({
  type: CHANGE_LIBRARY_FILE,
  status
})

export const changeLocationByUnit = (oldUnit, newUnit) => ({
  type: CHANGE_UNIT,
  oldUnit,
  newUnit
})

export const changeDataByUnit = (oldUnit, newUnit, changeComp = true) => ({
  type: CHANGE_DATA_BY_UNIT,
  oldUnit,
  newUnit,
  changeComp
})

export const exportExcel = (designId, fileName) => ({
  type: EXPORT_EXCEL,
  designId,
  fileName
})

export const importExcel = (designId, file) => ({
  type: IMPORT_EXCEL,
  designId,
  file
})

export const setPrelayoutLog = (log) => ({
  type: PRELAYOUT_LOG,
  log
})

export const setPrelayoutError = (errorMsg) => ({
  type: PRELAYOUT_ERROR,
  errorMsg
})

export const updateModelPreLayoutContent = (content, updateImmediately) => ({
  type: UPDATE_MODEL_PRELAYOUT_CONTENT,
  content,
  updateImmediately
})

export const updateSchematicLoading = (boolean) => ({
  type: SCHEMATIC_LOADING,
  boolean
})

export const updateComponents = (components) => ({
  type: UPDATE_COMPONENTS,
  components
})

export const saveModelPreLayoutInfo = (info) => ({
  type: SAVE_MODEL_PRELAYOUT_INFO,
  info
})

export const saveModel = (model, components) => ({
  type: SAVE_PRE_LAYOUT_MODEL,
  model,
  components
})

export const addResComponents = (signal) => ({
  type: ADD_RES_COMPONENTS,
  signal
})

export const changePreLayoutType = (width, memory, unit, prelayout) => ({
  type: CHANGE_PRELAYOUT_TYPE,
  width,
  memory,
  unit,
  prelayout
})

export const updatePreLayoutPackageId = (packageId) => ({
  type: UPDATE_PRELAYOUT_PACKAGE_ID,
  packageId
})

export const saveComponents = (components) => ({
  type: SAVE_PRE_LAYOUT_COMPONENTS,
  components
})

export const changeIncludeRes = (checked) => ({
  type: CHANGE_INCLUDES_RES,
  checked
});

export const renamePreLayout = (data) => ({
  type: RENAME_PRE_LAYOUT,
  data
})