import StrSplitRule from './StrSplitRule';
import StringList from './StringList';

/**
 * class StringHelper
 */
const StringHelper = {};

StringHelper.BoolToString = function (flag) {
  return flag ? "Y" : "N";
}

StringHelper.StringToBool = function (strval) {
  return strval == "Y" || strval == "true" || strval == "1";
}

StringHelper.SplitString = function (strLine, delimiter) {
  var itemList;
  if (delimiter instanceof StrSplitRule)
    itemList = this.SplitStringBySplitRule(strLine, delimiter);
  else
    itemList = this.SplitStringByDelimiter(strLine, delimiter);

  itemList.CleanEmptyItem();
  return itemList;
};

StringHelper.SplitStringByDelimiter = function (strLine, delimiter) {
  var retStrList = new StringList();
  strLine = strLine.trim();

  if (strLine.length == 0)
    return retStrList;

  if (delimiter.length == 0) {
    retStrList.add(strLine);
    return retStrList;
  }

  var regexp = new RegExp('[^"' + delimiter + ']+|"[^"]*"', "g");
  var strList = strLine.match(regexp);

  //var strList = strLine.split(delimiter);
  for (var i = 0; i < strList.length; i++) {
    if (strList[i][0] == '"')
      retStrList.add(strList[i].substring(1, strList[i].length - 1));
    else
      retStrList.add(strList[i]);
  }
  return retStrList;
};


StringHelper.SplitStringBySplitRule = function (strLine, splitRule) {
  var strList = new StringList();

  if (strLine == null) {
    return strList;
  }
  strLine = strLine.trim();

  // If line is empty
  if (strLine.length == 0) {
    return strList;
  }

  if (splitRule.mDelimiter.length == 0) {
    strList.push(strLine);
    return strList;
  }

  // if the character pairs in the rule does not exist, simply split the string
  var hasChar = false;
  if (splitRule.mReserveCh) {
    var keys = splitRule.mReserveCh.keySet();
    for (var i in keys) {
      if (strLine.indexOf(keys[i]) > -1) {
        hasChar = true;
        break;
      }
    }
  }
  if (!hasChar) {
    strList.mValues = strLine.split(splitRule.mDelimiter);
    return strList;
  }

  // do the more complicated split
  var leftRC = 0;
  var begPos = -1;
  var firstRC;
  for (var i = 0; i < strLine.length; i++) {
    var ch = strLine.charAt(i);
    var lastIsDelimiter = true;
    if (i > 0 && splitRule.IsDelimitChar(strLine.charAt(i - 1)) == false)
      lastIsDelimiter = false;

    var isReserveCh = lastIsDelimiter == true && splitRule.mReserveCh != null && splitRule.IsCharReserved(ch);
    if (isReserveCh) {
      firstRC = ch;
      leftRC++;
      var endChar = splitRule.GetReserveEndChar(ch);
      i++;
      var pos1 = i;
      var pos2 = i;
      for (; i < strLine.length; i++) {
        ch = strLine.charAt(i);
        //error: when reserve char is '"', firstRC is same as the end
        if (ch !== '\"' && ch == firstRC) {
          leftRC++; // handing embeded reserved char to locate the correct endChar
        } else if (endChar == ch && strLine.charAt(i - 1) !== '\\') {
          // Support Part name has quotation mark "xxx\""
          leftRC--;

          if (leftRC == 0) {
            pos2 = i;
            break;
          }
        }
      }
      var subItem = strLine.substring(pos1, pos2);
      subItem = subItem.trim();
      if (subItem.length != 0)
        strList.push(subItem);
      begPos = -1;
    } else if (splitRule.IsDelimitChar(ch) == true) {
      if (begPos >= 0) {
        var subItem = strLine.substring(begPos, i);
        subItem = subItem.trim();
        if (subItem.length != 0)
          strList.push(subItem);
        begPos = -1;
      }

      if (splitRule.mStringNumber > 0) {
        if (strList.size() + 1 == splitRule.mStringNumber) {
          begPos = -1;
          var subItem = strLine.substring(i + 1, strLine.length);
          subItem = subItem.trim();
          if (subItem.length != 0)
            strList.push(subItem);
          break;
        }
      }
    } else if (begPos < 0) { // sub-string starts
      begPos = i;
    }
  }

  // check the end char.
  // If it is not delimiter char, we need to push this part into string list.
  if (begPos >= 0) {
    var subItem = strLine.substring(begPos, strLine.length);
    subItem = subItem.trim();
    if (subItem.length != 0)
      strList.push(subItem);
  }
  return strList;
};

export default StringHelper;