import React, { Component, Fragment } from 'react';
import { Input, Space } from 'antd'
import { numberCheck } from '@/services/helper/dataProcess';
import './index.css'

const SpaceCompact = Space.Compact;
class RangeInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rangeInputValue: {
        Min: '',
        Max: '',
      },
      displayInput: true,
    }
  }

  componentDidMount() {
    const { rangeList } = this.props;
    if (rangeList){
      if (Number(rangeList[0] || 0) === 0 && Number(rangeList[1] || 0) === 0){
        this.setState({ rangeInputValue: { Min: '', Max: '' } })
      } else {
        this.setState({ rangeInputValue: { Min: rangeList[0], Max: rangeList[1] } });
      }
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  checkData = (rangeInputValue) => {
    let errorMsg, min = '', max = '';
    // clear data
    if (rangeInputValue.Min.length === 0 && rangeInputValue.Max.length === 0) {
      return { result: true, errorMsg, min: '', max: '' }
    }
    // check data is number
    let isNotNumber = []
    if (numberCheck(rangeInputValue.Min)) {
      isNotNumber.push('Min');
    }
    if (numberCheck(rangeInputValue.Max)) {
      isNotNumber.push('Max');
    }

    if (isNotNumber.length > 0){
      const errorType = isNotNumber.join(' and ');
      return { result: false, errorMsg: `${errorType} must be a number.`, min, max };
    }

    if (rangeInputValue.Min < rangeInputValue.Max || rangeInputValue.Min > rangeInputValue.Max) {
      min = Math.min(rangeInputValue.Min, rangeInputValue.Max);
      max = Math.max(rangeInputValue.Min, rangeInputValue.Max);
      return { result: true, errorMsg, min, max };
    } else {
      return { result: false, errorMsg: 'Min and Max cannot be equal.', min, max };
    }
  }

  // Return or click the trigger
  changeRangeList = () => {
    const { rangeInputValue } = this.state;
    const { rangeList, type } = this.props;
    const { result, errorMsg, min, max } = this.checkData(rangeInputValue, type);
    if (result) {
      this.setState({ rangeInputValue: { Min: min, Max: max } });
    } else {
      // exit：rangeList and Min = 0 and Max = 0; Returns NaN for a undefined or null
      if (rangeList) {
        if (Number(rangeList[0] || 0) === 0 && Number(rangeList[1] || 0) === 0) {
          this.setState({ rangeInputValue: { Min: '', Max: '' } })
        } else {
          this.setState({ rangeInputValue: { Min: rangeList[0], Max: rangeList[1] } });
        }
      }
    }
    this.props.changeRangeList(type, [min, max], errorMsg)
  }

  // 
  handleClickOutside = (e) => {
    const { target } = e;
    const { type } = this.props;
    const InputRootMin = document.getElementsByClassName(`range-input-${type}-min`);
    const InputRootMax = document.getElementsByClassName(`range-input-${type}-max`);
    const InputRootLink = document.getElementsByClassName(`range-input-link-input`);
    if ((InputRootMin && InputRootMin.length === 0) || (InputRootMax && InputRootMax.length === 0) || (InputRootLink && InputRootLink.length === 0)) {
      return;
    }
    if (!(InputRootMin && InputRootMin[0].contains(target)) && !(InputRootMax && InputRootMax[0].contains(target)) && !(InputRootLink && InputRootLink[0].contains(target))) {
      this.setState({
        displayInput: true
      }, () => {
        this.changeRangeList();
      })
    }
  }

  inputChange = (e, _type) => {
    e.stopPropagation && e.stopPropagation();
    const value = e.target.value;
    const _value = (value === ',' || value === ' ') ? "" : value;
    const _rangeInputValue = { ...this.state.rangeInputValue }
    _rangeInputValue[_type.split('_')[1]] = _value
    this.setState({
      rangeInputValue: { ..._rangeInputValue }
    })
  }

  onPressEnter = (e) => {
    e.stopPropagation && e.stopPropagation();
    const value = e.target.value.replace(/\s*/g, "");
    if (!value) {
      this.setState({
        displayInput: true
      })
      return;
    }
    this.changeRangeList();
    this.setState({
      displayInput: true,
    })
  }

  inputClick = () => {
    this.setState({
      displayInput: false,
    })
  }

  onKeyDown = (e) => {
    if (e.keyCode === 188) {
      this.onPressEnter(e)
    }
  }

  render = () => {
    const { type, rangeList, width } = this.props;
    const { rangeInputValue, displayInput } = this.state
    const inputType = type.split('_')[1];
    return <Fragment>
      <div
        className={`range-input-${type}-id`}
        onClick={(e) => this.inputClick(e)}
      >
        {displayInput ?
          <div className='range-input-show'>
            {(!rangeList || rangeList.length === 0 || (!Math.abs(+rangeList[0]) && !Math.abs(+rangeList[1]))) ?
              <Input className='range-input-show-noData' />
              : `[${rangeList.join(',')}]`}
          </div>
          : <SpaceCompact style={{ display: 'flex', height: 28 }}>
            <Input
              className={`range-input-${type}-min`}
              style={{ padding: 0, width: width, textAlign: 'center', borderRight: 0 }}
              placeholder='Min'
              value={rangeInputValue.Min}
              ref={(input) => { this[`${type}Ref`] = input; }}
              onChange={(e) => this.inputChange(e, `${inputType}_Min`)}
              onPressEnter={(e) => this.onPressEnter(e, `${inputType}_Min`)}
              onKeyDown={(e) => this.onKeyDown(e)}
            />
            <Input
              className="range-input-link-input"
              style={{ width: '30%', backgroundColor: '#fff' }}
              placeholder="——"
              disabled
            />
            <Input
              className={`range-input-${type}-max`}
              style={{ padding: 0, width: width, textAlign: 'center', borderLeft: 0 }}
              placeholder='Max'
              value={rangeInputValue.Max}
              ref={(input) => { this[`${type}Ref`] = input; }}
              onChange={(e) => this.inputChange(e, `${inputType}_Max`)}
              onPressEnter={(e) => this.onPressEnter(e, `${inputType}_Max`)}
              onKeyDown={(e) => this.onKeyDown(e)}
            />
          </SpaceCompact>}
      </div>
    </Fragment>
  }
}

export default RangeInput;