/**
 * Determine whether two lines intersect, plump line and net trace line
 * @param {Number} k Plumb line slope, y = kx + b
 * @param {Number} b Plumb line intercept, y = kx + b
 * @param {Number} x Plumb Line parallel to the y axis, x = n;
 * @param {Number} y Plumb Line parallel to the x axis,  y = n;
 * @param {Object} start { mX, mY } Starting point coordinates of trace line
 * @param {Object} end { mX, mY } End point coordinates of trace line
 *  */
function isTwoLinesIntersect({ k, b, x, y, start, end }) {
  if (x !== undefined) {
    //Plumb Line parallel to the y axis .
    //If the x values of both points are less than the x of the line segment,No intersection
    if ((start.mX > x && end.mX > x) || (start.mX < x && end.mX < x)) {
      return false;
    } else {
      return true;
    }
  }
  if (y !== undefined) {
    //Plumb Line parallel to the x axis .
    //If the y values of both points are less than the y of the line segment, No intersection
    if ((start.mY > y && end.mY > y) || (start.mY < y && end.mY < y)) {
      return false;
    } else {
      return true;
    }
  }

  //Substitute the two points into the vertical line equation.
  //if both are greater than 0, or both are less than 0, no intersection
  const _start = k * start.mX + b,
    _end = k * end.mX + b;
  if ((_start > start.mY && _end > end.mY) || (_start < start.mY && _end < end.mY)) {
    return false;
  }
  return true;
}

/* Calculate the two-points equation */
function calculateEquation(start, end) {
  /* Ax+By+C=0,（x1,y1）、(x2,y2)
 A=y2-y1
 B=x1-x2
 C=X2×Y1-X1×Y2 */

  if (start.mX === end.mX) {
    //Line parallel to the y axis, x = constant
    return { x: start.mX }
  }
  else if (start.mY === end.mY) {
    //Line parallel to the x axis, , y = constant
    return { y: start.mY }
  } else {
    /* 
     k=(y1-y2)/(x1-x2)
     y=kx+b
      */
    const k = (end.mY - start.mY) / (end.mX - start.mX);
    const b = end.mY - k * end.mX;
    return { k, b }
  }
}

/* Calculate the plumb line equation by two points*/
function calculatePlumbLine(start, end) {
  /* Ax+By+C=0,（x1,y1）、（x2,y2）
 A=2(x2-x1)
 B=2(y2-y1)
 C=(x1^2-x2^2)+(y1^2-y2^2)

 k=(y1-y2)/(x1-x2)
 y=kx+b
   */

  if (start.mX === end.mX) {
    // Line parallel to the y axis, x = constant1, plumb line equation is y = constant2
    return { y: (start.mY + end.mY) / 2 }
  }
  else if (start.mY === end.mY) {
    // Line parallel to the x axis, y = constant1, plumb line equation is x = constant2
    return { x: (start.mX + end.mX) / 2 }
  }

  const A = 2 * (end.mY - start.mY),
    B = 2 * (end.mX - start.mX),
    midPointX = (start.mX + end.mX) / 2,
    midPointY = (start.mY + end.mY) / 2;

  const k = -(B / A),
    b = midPointY - (midPointX * k);
  return { k, b }
}

/**
 *  Calculate the intersection of two lines 
 * @param {Number} k Plumb line slope, y = kx + b
 * @param {Number} b Plumb line intercept, y = kx + b
 * @param {Number} x Plumb Line parallel to the y axis, x = n;
 * @param {Number} y Plumb Line parallel to the x axis,  y = n;
 * @param {Object} start { mX, mY } Starting point coordinates of trace
 * @param {Object} end { mX, mY } End point coordinates of trace
 * 
 * if line parallel to the y axis, x is exist, x = constant, 
 * else if line parallel to the x axis, y is exist, y = constant,
 * else line is a diagonal line , k and b are exist.
*/
function getPoint({ k, b, x, y, start, end }) {
  //calculate line equation by two point (start, end)
  //k1, b1, x1, y1 has the same meaning as k,b,x,y
  const { k: k1, b: b1, x: x1, y: y1 } = calculateEquation(start, end);

  if (x !== undefined) {
    //Plumb line parallel to the y axis
    return getXPoint({ x, x1, y1, k1, b1, start });
  }

  if (y !== undefined) {
    //Plumb line parallel to the x axis
    return getYPoint({ y, x1, y1, k1, b1, start });
  }

  if (x1 !== undefined) {
    // line parallel to the y axis, x is constant, Substitute x into the equation of the plumb line to find y.
    const mY = k * x1 + b;
    return { mX: x1, mY }
  }

  if (y1 !== undefined) {
    //line parallel to the x axis, y is constant, Substitute y into the equation of the plumb line to find x.
    const mX = (y1 - b) / k;
    return { mX, mY: y1 }
  }

  //Both lines are slashes, 
  //Calculate the general formula A, B, C of the line according to the slope(k) and intercept(b)
  const A = k, B = -1, C = b;
  const A1 = k1, B1 = -1, C1 = b1;

  const m = A * B1 - A1 * B;

  if (m === 0) {
    //m === 0 ,no intersection.
    return null;
  } else {
    return {
      mX: (C1 * B - C * B1) / (A * B1 - A1 * B),
      mY: (C * A1 - C1 * A) / (A * B1 - A1 * B)
    }
  }
}

/* if line1 parallel to the y axis, calculate intersection of two lines */
function getXPoint({ x, x1, y1, k1, b1, start }) {

  if (x1 !== undefined) {
    //line parallel to the y axis, y = constant1
    //plumbLine parallel to the y axis,y = constant2
    //If the two lines coincide, constant1===constant2, take any point as the point of intersection,
    // otherwise there is no point of intersection
    return x === x1 ? { mX: start.mX, mY: start.mY } : null;
  }

  if (y1 !== undefined) {
    //line parallel to the x axis, y = constant1
    // plumbLine parallel to the y axis , x = constant2
    //Two lines intersect perpendicularly
    return { mX: x, mY: y1 }
  }

  // x is constant, Substitute x into the equation of the line to find y.
  const mY = k1 * x + b1;
  return { mX: x, mY }
}


/* if line1 parallel to the x axis, calculate intersection of two lines */
function getYPoint({ y, x1, y1, k1, b1, start }) {

  if (x1 !== undefined) {
    //line parallel to the y axis, x = constant1
    // plumbLine parallel to the x axis , y = constant2
    //Two lines intersect perpendicularly
    return { mX: x1, mY: y }
  }

  if (y1 !== undefined) {
    //line parallel to the x axis,  y = constant1
    //plumbLine parallel to the x axis, y = constant2
    //If the two lines coincide, take any point as the point of intersection, constant1===constant2
    // otherwise there is no point of intersection
    return y === y1 ? { mX: start.mX, mY: start.mY } : null;
  }

  //y is constant, Substitute y into the equation of the line to find x.
  const mX = (y - b1) / k1;
  return { mX, mY: y }
}

/**
 * calculate two points distance 
 * @param {Array} pointGroup1 [{ mX, mY, layerID },{ mX, mY, layerID }]
 * @param {Array} pointGroup2 [{ mX, mY, layerID },{ mX, mY, layerID }]
 * */
function calcDistance(pointGroup1, pointGroup2) {
  let distance = null;
  for (let point1 of pointGroup1) {
    for (let point2 of pointGroup2) {
      //same layer
      if (point1.layerID !== point2.layerID) {
        continue;
      }
      const _d = calcDistanceOfTwoPoints(point1, point2);
      if (distance === null || distance > _d) {
        distance = _d;
      }
    }
  }
  return distance;
}

/**
 * Calculate the distance between two points
 * @param {Object} start,  {mX,mY}
 * @param {Object} end,  {mX,mY}
 * */
function calcDistanceOfTwoPoints(start, end) {
  const a = end.mX - start.mX;
  const b = end.mY - start.mY;

  return a * a + b * b;
}

export {
  calcDistanceOfTwoPoints,
  isTwoLinesIntersect,
  calculatePlumbLine,
  calculateEquation,
  calcDistance,
  getPoint
}
