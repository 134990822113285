import React, { Component, Fragment } from 'react';
import { DownOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Switch, Input, Divider, Radio, Select, Tooltip } from 'antd';
import Panel from '@/components/Panel';
import { numExponentialFormat } from '@/services/helper/numberHelper';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { DCExtraction, SIWAVE, POWERDC } from '../../../../services/Cascade/helper/setupClass';
import { numberCheck } from '../../../../services/helper/dataProcess';
import { DCR, SIGN_OFF_TEMPLATE } from '../../../../constants/treeConstants';
import { AEDB, ODB_PP, SPD, CADENCE_BRD } from '../../../../constants/designVendor';
import TimeoutOptions from '../../../../components/ExtractionOptions/TimeoutOptions';
import './index.css'

const { Option } = Select;
const DCParams = [
  { key: 'siwMinPlaneSize', title: 'Ignore geometry smaller than​', unit: 'mm^2', range: [0, 1] },
  { key: 'siwMinVoidArea', title: 'Ignore voids smaller than', unit: 'mm^2', range: [0, 1] },
  { key: 'siwSnapVertice', title: 'Snap vertices separated by less than', unit: 'um', range: [0, 10] }
];
const meshSizeParams = [{ key: 'siwInitMeshSize', title: 'Initial Mesh Max Edge Length', unit: 'mm', range: [0, 5] }];
const contactParams = [{ key: 'siwContactRadius', title: 'Circuit element contact radius', unit: 'mm' }];
const meshViasParams = [{ key: 'siwNumViaSides', title: 'Approximate cylindrical vias using', integer: true }]
const adaptiveParams = [
  { key: 'siwMinPasses', title: 'Minimum Number of Passes', range: [1, 10], integer: true },
  { key: 'siwMaxPasses', title: 'Maximum Number of Passes', range: [1, 100], integer: true },
  { key: 'siwPercentRefinement', title: 'Local Refinement', range: [10, 50], unit: '%' },
  { key: 'siwEnergeErr', title: 'Energy Error', range: [0.01, 100], unit: '%' },
]
const otherParams = [
  { key: 'pwsiTemperature', title: 'Temperature' },
  { key: 'area', title: 'Pad area' }
]
const timeParams = [
  { key: 'licWait' },
  { key: 'timeoutHours' },
  { key: 'timeoutMinutes' },
]
class ExtractionPanel extends Component {

  constructor(props) {
    super(props);
    const extraction = new DCExtraction();
    this.state = {
      ...extraction,
      advanced: false
    }
    this.ymlLibrary = {
      [POWERDC]: '',
      [SIWAVE]: ''
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 862)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { Config, vendor } = this.props;
    let extraction = new DCExtraction(Config)
    if (vendor === SPD && Config.simulator !== POWERDC) {
      extraction = new DCExtraction({ ...Config, simulator: POWERDC })
    }
    if ([ODB_PP, AEDB].includes(vendor) && Config.simulator === POWERDC) {
      extraction = new DCExtraction({ ...Config, simulator: SIWAVE })
    }
    if (Config) {
      const { simulator, ymlLibraryId } = Config;
      if (simulator) {
        this.ymlLibrary[simulator] = ymlLibraryId;
      }
      this.setState({
        ...extraction,
      })
    }
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let _check = null;
    const { error } = this.state;

    _check = this.checkDCValue(value, inputType);
    if (_check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: _check }
      });
    } else {
      let _value = value;
      if (value) {
        _value = numExponentialFormat(value);
      }
      const newError = error && error.type === inputType ? null : error;
      this.setState({
        [inputType]: _value,
        error: newError
      });
    }
  }

  checkDCValue = (value, key) => {
    const param = [...DCParams, ...otherParams, ...adaptiveParams, ...meshSizeParams, ...contactParams, ...meshViasParams, ...timeParams].find(item => item.key === key);

    if (!param) {
      return null
    }

    if (!value || numberCheck(value)) {
      return `The value of ${param.title} needs to be number.`
    }

    if (param.integer) {
      if (!(/^-?\d+$/).test(value)) {
        return `The value of ${param.title} needs to be Integer.`
      }
    }

    const range = param.range;
    if (!range) {
      return null
    }
    if (Number(value) < range[0] || Number(value) > range[1]) {
      return `The value of ${param.title} needs to be in the range [${range.join(', ')}].`
    }
    return null
  }

  changeValue = (e, type) => {
    let value = e.target.value;
    const { error } = this.state;
    const newError = error && error.type === type ? null : error;
    this.setState({
      [type]: value,
      error: newError
    });
  }

  changeSelectValue = (value) => {
    const { simulator } = this.state;
    this.ymlLibrary[simulator] = value;
    this.setState({
      ymlLibraryId: value
    });
  }

  changeSwitch = (checked, type) => {
    let value = checked;
    const state = { [type]: value }
    if (type === 'checkAutoAddNodesOnPads') {
      state['checkAutoAddPinSize'] = false
    } else if (type === 'checkAutoAddPinSize') {
      state['checkAutoAddNodesOnPads'] = false
    }
    this.setState({
      ...state
    });
  }

  changeSelect = (key, type) => {
    this.setState({
      [type]: key
    })
  }

  showAdvanced = (e) => {
    e && e.stopPropagation();
    this.setState({
      advanced: !this.state.advanced
    })
  }

  changeAnsysType = (e) => {
    const type = e.target.value;
    const newParams = new DCExtraction({ simulator: type });
    const ymlLibraryId = this.ymlLibrary[type]
    this.setState({
      ...newParams,
      ymlLibraryId: ymlLibraryId || ""
    })
  }

  closeModal = () => {
    this.saveConfig();
    this.props.closeModal(false);
  }

  saveConfig = () => {
    const { error } = this.state;
    if (error) {
      return;
    }

    const config = new DCExtraction(this.state)

    this.props.saveConfig && this.props.saveConfig(config);
  }


  onPressEnter = (e) => {
    e.target.blur();
  }


  getOptionFile = () => {
    const { extractionSiwaveOptionsList, extractionPowerDCOptionsList } = this.props;
    const { simulator } = this.state;
    switch (simulator) {
      case SIWAVE:
        return {
          selectOptions: extractionSiwaveOptionsList,
          title: 'SIwave Option File'
        }
      case POWERDC:
        return {
          selectOptions: extractionPowerDCOptionsList,
          title: 'PowerDC Option File'
        }
      default: return null;
    }
  }

  DCRender = () => {
    const { customPlaneVoidMeshingDCSetting } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>DC custom plane void meshing setting</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={customPlaneVoidMeshingDCSetting}
          onChange={(checked) => this.changeSwitch(checked, 'customPlaneVoidMeshingDCSetting')}
        />
      </div>
      {
        customPlaneVoidMeshingDCSetting && <Fragment>
          {DCParams.map(item => {
            return <div className='cascade-top-option-panel-content' key={item.key}>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>{item.title}</span>
              <span><Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                addonAfter={item.unit}
                value={this.state[item.key]}
                onChange={(e) => this.changeValue(e, item.key)}
                onBlur={(e) => this.inputBlur(e, item.key)}
                onPressEnter={this.onPressEnter}
                disabled={false}
              />
              </span>
            </div>
          })}
        </Fragment>
      }
      {meshSizeParams.map(item => {
        return <div className='cascade-top-option-panel-content' key={item.key}>
          <span className='cascade-top-option-panel-content-body'>{item.title}</span>
          <span><Input
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            addonAfter={item.unit}
            value={this.state[item.key]}
            onChange={(e) => this.changeValue(e, item.key)}
            onBlur={(e) => this.inputBlur(e, item.key)}
            onPressEnter={this.onPressEnter}
            disabled={false}
          />
          </span>
        </div>
      })}
    </Fragment>
  }

  clipRender = () => {
    const { clipping } = this.state;
    return <div className='cascade-top-option-panel-content'>
      <span className='cascade-top-option-panel-content-body'>Clipping</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={clipping}
        onChange={(checked) => this.changeSwitch(checked, 'clipping')}
      />
    </div>
  }

  advancedRender = () => {
    const { vendor } = this.props;
    const advancedOptions = vendor === CADENCE_BRD ? [{ key: 'auroraAedb', title: 'Use Aurora generated AEDB​' }] : []
    const { advanced } = this.state;
    return advancedOptions.length ? <Fragment>
      <Divider />
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-advanced-body' onClick={this.showAdvanced}>
          {advanced ? <DownOutlined className='advanced-arrow-icon' /> : <RightOutlined className='advanced-arrow-icon' />}
          Advanced
        </span>
      </div>
      {advanced && advancedOptions.map(option => {
        const { key, title } = option
        return <div className='cascade-top-option-panel-content' key={key}>
          <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>{title}</span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={this.state[key] || false}
            onChange={(checked) => this.changeSwitch(checked, "auroraAedb")}
          />
        </div>
      })}
    </Fragment> : null;
  }

  simulatorRender = () => {
    const { simulator } = this.state;
    const { vendor } = this.props
    const isSPD = vendor === SPD, isODBPPOrAEDB = [ODB_PP, AEDB].includes(vendor)
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <Radio.Group onChange={this.changeAnsysType} value={simulator} className='extraction-radio-group'>
          <Radio
            value={SIWAVE}
            className='extraction-radio-group-item'
            disabled={isSPD}
          >
            Ansys SIwave
            {
              isSPD && <Tooltip title={'SPD cannot invoke Ansys'} overlayClassName='aurora-tooltip'>
                <QuestionCircleOutlined className='cascade-question-icon' />
              </Tooltip>
            }
          </Radio>
          <Radio
            value={POWERDC}
            className='extraction-radio-group-item'
            disabled={isODBPPOrAEDB}
          >
            Cadence PowerDC
            {
              isODBPPOrAEDB && <Tooltip title={'ODB++,AEDB cannot invoke Cadence PowerDC'} overlayClassName='aurora-tooltip'>
                <QuestionCircleOutlined className='cascade-question-icon' />
              </Tooltip>
            }
          </Radio>
        </Radio.Group>
      </div>
    </Fragment>
  }

  optionFileRender = () => {
    const { ymlLibraryId } = this.state
    const content = this.getOptionFile();
    if (!content) {
      return;
    }
    const { title, selectOptions } = content;
    return <Fragment>
      <Divider />
      <div className='cascade-top-option-panel-content'>
        <span className={'cascade-top-option-panel-content-body'}>{title}</span>
        <Select
          placeholder={'SIwave Option File'}
          value={ymlLibraryId || ''}
          onChange={(value) => { this.changeSelectValue(value) }}
          className={"aurora-select cascade-top-option-panel-content-input"}
          popupClassName='aurora-select-dropdown'
          allowClear
        >
          {selectOptions.map(item => <Option
            key={item.id}
            value={item.id}
            title={item.name}
          >{item.name}</Option>)}
        </Select>
      </div>
    </Fragment>
  }

  timeRender = () => {
    const { licWaitSwitch, licWait, licWaitUnit, timeoutSwitch, timeoutHours, timeoutMinutes } = this.state;
    return <TimeoutOptions
      licWaitSwitch={licWaitSwitch}
      licWait={licWait}
      licWaitUnit={licWaitUnit}
      timeoutSwitch={timeoutSwitch}
      timeoutHours={timeoutHours}
      timeoutMinutes={timeoutMinutes}
      changeValue={this.changeValue}
      changeSwitch={this.changeSwitch}
      inputBlur={this.inputBlur}
      changeSelect={this.changeSelect}
    />
  }

  powerDCRender = () => {
    return <Fragment>
      {this.optionFileRender()}
      <Divider />
      {this.accRender()}
      {this.temperRender()}
      {this.timeRender}
    </Fragment>
  }

  accRender = () => {
    const { pwsiAccuracyMode } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content power-dc-span'>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Accuracy Mode</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAccuracyMode}
          onChange={(checked) => this.changeSwitch(checked, 'pwsiAccuracyMode')}
        />
      </div>
      <Divider />
      {this.treatPadRender()}
      <Divider />
      {this.pinSizeRender()}
      <Divider />
      {this.viaModelRender()}
      <Divider />
      {this.meshDisplayRender()}
      <Divider />
      {this.maxEdgeRender()}
      <Divider />
      {this.meshOptionRender()}
      <Divider />
      {this.adaptiveRender()}
    </Fragment>
  }

  treatPadRender = () => {
    const { treatPadAsShape, connectObject, checkArea, area, pwsiAccuracyMode } = this.state;
    const _treatPadAsShape = pwsiAccuracyMode ? false : treatPadAsShape
    return <Fragment>
      <div className='cascade-top-option-panel-content power-dc-span' title={pwsiAccuracyMode ? 'Automatic settings in Accuracy Mode' : ""}>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Treat all pads as shapes</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={!_treatPadAsShape}
          onChange={(checked) => this.changeSwitch(!checked, 'treatPadAsShape')}
          disabled={pwsiAccuracyMode}
        />
      </div>
      <div className='cascade-top-option-panel-content power-dc-span' title={pwsiAccuracyMode ? 'Automatic settings in Accuracy Mode' : ""}>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Treat partial pads as shapes</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={_treatPadAsShape}
          onChange={(checked) => this.changeSwitch(checked, 'treatPadAsShape')}
          disabled={pwsiAccuracyMode}
        />
      </div>
      {
        _treatPadAsShape && <Fragment>
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span treate-pad-span'>Pads are connecting to VRM, SINK, Discrete and other enabled curcuits</span>
            <span><Switch
              size="small"
              className="aurora-switch-small"
              checked={connectObject}
              onChange={(checked) => this.changeSwitch(checked, 'connectObject')}
            />
            </span>
          </div>
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span treate-pad-span'>Pad area is greater than</span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={checkArea}
              onChange={(checked) => this.changeSwitch(checked, 'checkArea')}
            />
            <span><Input
              className='cascade-top-option-panel-content-input treate-pad-input cascade-option-input-addonAfter'
              addonAfter={'mm^2'}
              value={!checkArea ? '' : area}
              onChange={(e) => this.changeValue(e, 'area')}
              onBlur={(e) => this.inputBlur(e, 'area')}
              onPressEnter={this.onPressEnter}
              disabled={!checkArea}
            />
            </span>
          </div>
        </Fragment>
      }
    </Fragment>
  }

  pinSizeRender = () => {
    const { checkAutoAddPinSize, checkAutoAddNodesOnPads, nodeGapForPad, minNodeNumForPad, pwsiAccuracyMode } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content' title={pwsiAccuracyMode ? 'Automatic settings in Accuracy Mode' : ""}>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Make all VRM/Discrete/Sink pads to be equipotential</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAccuracyMode ? false : checkAutoAddPinSize}
          onChange={(checked) => this.changeSwitch(checked, 'checkAutoAddPinSize')}
          disabled={pwsiAccuracyMode}
        />
      </div>
      <div className='cascade-top-option-panel-content' title={pwsiAccuracyMode ? 'Automatic settings in Accuracy Mode' : ""}>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Auto add nodes on VRM/Discrete/Sink pads</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAccuracyMode ? true : checkAutoAddNodesOnPads}
          onChange={(checked) => this.changeSwitch(checked, 'checkAutoAddNodesOnPads')}
          disabled={pwsiAccuracyMode}
        />
      </div>
      {
        (checkAutoAddNodesOnPads || pwsiAccuracyMode) && <Fragment>
          <div className='cascade-top-option-panel-content'>
            <span className={'cascade-top-option-panel-content-body cascade-extraction-panel-sub-span power-dc-span'}>Gap between two nodes</span>
            <Input className='cascade-top-option-panel-content-input power-dc-input'
              value={nodeGapForPad}
              addonAfter={'mm'}
              onChange={(e) => this.changeValue(e, 'nodeGapForPad')}
              onBlur={(e) => this.inputBlur(e, 'nodeGapForPad')}
              onPressEnter={this.onPressEnter}
            />
          </div>
          <div className='cascade-top-option-panel-content' title={pwsiAccuracyMode ? "" : "Can only be set in Accuracy Mode"}>
            <span className={'cascade-top-option-panel-content-body cascade-extraction-panel-sub-span power-dc-span'}>Minimum nodes number</span>
            <Input className='cascade-top-option-panel-content-input power-dc-input'
              value={minNodeNumForPad}
              onChange={(e) => this.changeValue(e, 'minNodeNumForPad')}
              onBlur={(e) => this.inputBlur(e, 'minNodeNumForPad')}
              onPressEnter={this.onPressEnter}
              disabled={!pwsiAccuracyMode}
            />
          </div>
        </Fragment>
      }
    </Fragment>
  }

  viaModelRender = () => {
    const { enable3DVia, mesh3DViaSize, enableSolid, pwsiAccuracyMode } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content' title={pwsiAccuracyMode ? 'Automatic settings in Accuracy Mode' : ""}>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Enable 3D via model</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAccuracyMode ? true : enable3DVia}
          onChange={(checked) => this.changeSwitch(checked, 'enable3DVia')}
          disabled={pwsiAccuracyMode}
        />
      </div>
      {
        enable3DVia && <Fragment>
          <div className='cascade-top-option-panel-content'>
            <span className={'cascade-top-option-panel-content-body cascade-extraction-panel-sub-span power-dc-span'}>Via diameter greater than</span>
            <Input className='cascade-top-option-panel-content-input power-dc-input'
              value={mesh3DViaSize}
              addonAfter={'mm'}
              onChange={(e) => this.changeValue(e, 'mesh3DViaSize')}
              onBlur={(e) => this.inputBlur(e, 'mesh3DViaSize')}
              onPressEnter={this.onPressEnter}
            />
          </div>
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span power-dc-span'>Solid via only</span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={enableSolid}
              onChange={(checked) => this.changeSwitch(checked, 'enableSolid')}
            />
          </div>
        </Fragment>
      }
    </Fragment>
  }

  maxEdgeRender = () => {
    const { maxEdgeLength, maxEdgeCheck } = this.state;
    return <div className='cascade-top-option-panel-content'>
      <span className='cascade-top-option-panel-content-body power-dc-span'>Maximum mesh edge length</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={maxEdgeCheck}
        onChange={(checked) => this.changeSwitch(checked, 'maxEdgeCheck')}
      />
      <span><Input
        className='cascade-top-option-panel-content-input cascade-option-input-addonAfter power-dc-max-edge-input'
        addonAfter={'mm'}
        value={!maxEdgeCheck ? '' : maxEdgeLength}
        onChange={(e) => this.changeValue(e, 'maxEdgeLength')}
        onBlur={(e) => this.inputBlur(e, 'maxEdgeLength')}
        onPressEnter={this.onPressEnter}
        disabled={!maxEdgeCheck}
      />
      </span>
    </div>
  }

  meshDisplayRender = () => {
    const { meshDisplay, pwsiAccuracyMode } = this.state;
    return <div className='cascade-top-option-panel-content' title={pwsiAccuracyMode ? "" : "Can only be set in Accuracy Mode"}>
      <span className='cascade-top-option-panel-content-body power-dc-span'>Enable mesh display after simulation is completed</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={pwsiAccuracyMode ? meshDisplay : false}
        onChange={(checked) => this.changeSwitch(checked, 'meshDisplay')}
        disabled={!pwsiAccuracyMode}
      />
    </div>
  }

  meshOptionRender = () => {
    const { simplifyGeometry, coarseMesh } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Simplify the geometry to improve mesh quality</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={simplifyGeometry}
          onChange={(checked) => this.changeSwitch(checked, 'simplifyGeometry')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Coarse mesh</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={coarseMesh}
          onChange={(checked) => this.changeSwitch(checked, 'coarseMesh')}
        />
      </div>
    </Fragment>
  }

  adaptiveRender = () => {
    const { enableAdaptiveMesh, meshIterationNumber, topLargestPowerLossElements } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body power-dc-span'>Enable adaptive meshing</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={enableAdaptiveMesh}
          onChange={(checked) => this.changeSwitch(checked, 'enableAdaptiveMesh')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className={'cascade-top-option-panel-content-body power-dc-span'}>Iteration number</span>
        <Input className='cascade-top-option-panel-content-input power-dc-input'
          value={meshIterationNumber}
          onChange={(e) => this.changeValue(e, 'meshIterationNumber')}
          onBlur={(e) => this.inputBlur(e, 'meshIterationNumber')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className={'cascade-top-option-panel-content-body power-dc-span'}>Meshes with</span>
        <Input className='cascade-top-option-panel-content-input power-dc-input adaptive-dc-input'
          value={topLargestPowerLossElements}
          addonAfter={'%'}
          onChange={(e) => this.changeValue(e, 'topLargestPowerLossElements')}
          onBlur={(e) => this.inputBlur(e, 'topLargestPowerLossElements')}
          onPressEnter={this.onPressEnter}
        />
        <span className='adaptive-dc-span'>largest power loss will be refined</span>
      </div>
    </Fragment>
  }

  temperRender = () => {
    const { pwsiTemperature } = this.state;
    return <Fragment>
      <Divider />
      <div className='cascade-top-option-panel-content'>
        <span className={'cascade-top-option-panel-content-body power-dc-span'}>Temperature</span>
        <Input className='cascade-top-option-panel-content-input power-dc-input'
          value={pwsiTemperature}
          onChange={(e) => this.changeValue(e, 'pwsiTemperature')}
          onBlur={(e) => this.inputBlur(e, 'pwsiTemperature')}
          onPressEnter={this.onPressEnter}
        />
      </div>
    </Fragment>
  }

  siwaveRender = () => {
    const { view } = this.props;
    return <Fragment>
      {this.optionFileRender()}
      <Divider />
      {![SIGN_OFF_TEMPLATE, DCR].includes(view) && <Fragment>
        {this.clipRender()}
        <Divider />
      </Fragment>}
      {this.DCRender()}
      <Divider />
      {this.contactRender()}
      <Divider />
      {this.meshViasRender()}
      <Divider />
      {this.adaptiveSIRender()}
      {this.timeRender()}
    </Fragment>
  }

  contactRender = () => {
    return <Fragment>
      {
        contactParams.map(item => {
          return <div className='cascade-top-option-panel-content' key={item.key}>
            <span className='cascade-top-option-panel-content-body'>{item.title}</span>
            <span><Input
              className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
              addonAfter={item.unit}
              value={this.state[item.key]}
              onChange={(e) => this.changeValue(e, item.key)}
              onBlur={(e) => this.inputBlur(e, item.key)}
              onPressEnter={this.onPressEnter}
              disabled={false}
            />
            </span>
          </div>
        })
      }
    </Fragment>
  }

  meshViasRender = () => {
    const { siwMeshVias } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Mesh Vias</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={siwMeshVias}
          onChange={(checked) => this.changeSwitch(checked, 'siwMeshVias')}
        />
      </div>
      {
        siwMeshVias && <Fragment>
          {meshViasParams.map(item => {
            return <div className='cascade-top-option-panel-content' key={item.key}>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>{item.title}</span>
              <span><Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                addonAfter={item.unit}
                value={this.state[item.key]}
                onChange={(e) => this.changeValue(e, item.key)}
                onBlur={(e) => this.inputBlur(e, item.key)}
                onPressEnter={this.onPressEnter}
                disabled={false}
              />
              </span>
            </div>
          })}
        </Fragment>
      }
    </Fragment>
  }

  adaptiveSIRender = () => {
    const { siwAdaptiveRefinement, siwRefineVias } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Perform Adaptive Mesh Refinement</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={siwAdaptiveRefinement}
          onChange={(checked) => this.changeSwitch(checked, 'siwAdaptiveRefinement')}
        />
      </div>
      {
        siwAdaptiveRefinement && <Fragment>
          {adaptiveParams.map(item => {
            return <div className='cascade-top-option-panel-content' key={item.key}>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>{item.title}</span>
              <span><Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                addonAfter={item.unit}
                value={this.state[item.key]}
                onChange={(e) => this.changeValue(e, item.key)}
                onBlur={(e) => this.inputBlur(e, item.key)}
                onPressEnter={this.onPressEnter}
                disabled={false}
              />
              </span>
            </div>
          })}
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Refine Mesh Along Via</span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={siwRefineVias}
              onChange={(checked) => this.changeSwitch(checked, 'siwRefineVias')}
            />
          </div>

        </Fragment>
      }
    </Fragment>
  }

  render() {
    const { error, maxWidth, maxHeight, simulator } = this.state;
    const content = (
      <Panel
        className='cascade-top-option-panel'
        title={<div className='cascade-top-option-panel-name'>Extraction Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth)}
        position='panel-center'
        mask={false}
        draggable
        minWidth={200}
        minHeight={50}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='cascade-top-option-panel-main cascade-top-extraction-panel-main' style={{ minHeight: 50 }}>
          {this.simulatorRender()}
          {
            simulator === SIWAVE ? this.siwaveRender() : null
          }
          {simulator === POWERDC && this.powerDCRender()}
          {simulator === SIWAVE && this.advancedRender()}
          {error && <span className='cascade-top-option-panel-error-msg'>Error: {error.error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ExtractionPanel;