import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { DecapGroup as PrelayoutDecapGroup, Decap } from "../../../services/Cascade/prelayout";
import _ from 'lodash';
import { getDefaultName } from "../../../services/helper/setDefaultName";
import EditableTable from "../../../components/EditableTable";
import { numberCheck } from "../../../services/helper/dataProcess";
import { deleteDecapGroup, saveDecapGroup, showDecapGroup } from "../store/prelayout/action";
import PartSelect from "./partSelect";
import { DECAP_SPICE } from "../../../constants/libraryConstants";
import { getLibraryFileInfo } from "../../../services/Cascade/library";
import { parseSPModelSelector } from "../../../services/Library";
import './index.css';

const columns = [
  {
    title: 'Part',
    dataIndex: 'name',
    textWrap: 'word-break',
    ellipsis: true
  }, {
    title: 'Number',
    dataIndex: 'number',
    textWrap: 'word-break',
    ellipsis: true
  }, {
    title: 'L Layout (pH)',
    dataIndex: 'l_layout',
    textWrap: 'word-break',
    ellipsis: true
  }, {
    title: 'R Layout (mΩ)',
    dataIndex: 'r_layout',
    textWrap: 'word-break',
    ellipsis: true
  }
]
class DecapGroup extends Component {

  constructor() {
    super();
    const decapGroup = new PrelayoutDecapGroup({})
    this.state = {
      ...decapGroup
    }
  }

  componentDidMount() {
    const { decapGroup } = this.props;
    if (decapGroup) {
      const decaps = decapGroup.decaps || [];
      this.setState({
        ...decapGroup,
        decaps: this.checkDecap(decaps)
      })
    }
    this.initColumns()
  }

  componentDidUpdate = (prevProps) => {
    const { decapGroup, groupShow } = this.props;
    if (decapGroup && (groupShow !== prevProps.groupShow || !_.isEqual(decapGroup, prevProps.decapGroup))) {
      const decaps = decapGroup.decaps || [];
      this.setState({
        ...decapGroup,
        decaps: this.checkDecap(decaps)
      })
    }
  }

  checkDecap = (decaps) => {
    return decaps.map(decap => decap.id ? decap : new Decap(decap || {}))
  }

  initColumns = () => {
    columns.forEach((item, index) => {
      if (item.dataIndex === 'name') {
        item.render = (text, record) => {
          const { model } = record;
          if (model.libraryId && model.subckt) {
            return text;
          }
          return (
            <span className="cascade-prelayout-decap-error-name">
              <Tooltip overlayClassName="aurora-tooltip" title="Please set subckt!">
                {text}
                <ExclamationCircleOutlined className="cascade-prelayout-decap-error-icon" />
              </Tooltip>
            </span>
          );
        }

        item.onCell = (record) => {
          const { name, model } = record;
          const { DecapSpiceList, defaultDecap } = this.props;
          return {
            record,
            edit: true,
            customInput: PartSelect,
            dataIndex: 'name',
            text: name,
            model,
            name,
            DecapSpiceList,
            getSubcktList: this.getSubcktList,
            changeDecap: this.changeDecap,
            defaultDecap
          }
        }
      } else {
        item.onCell = (record) => {
          return {
            record,
            edit: true,
            dataIndex: item.dataIndex,
            handleSave: (newData) => this.changeData(newData, record, item.dataIndex),
          }
        }
      }
    })

    columns[columns.length - 1].render = (text, record) => {
      return (
        <div>
          <span>{text}</span>
          <CloseOutlined
            className="cascade-prelayout-decap-delete-icon "
            onClick={(e) => this.deleteRow(e, record)} />
        </div>
      );
    }

  }

  valueChange = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    })
  }

  getSubcktList = async (key) => {
    try {
      const res = await getLibraryFileInfo(key)
      const { models: subckts } = parseSPModelSelector(res);
      return subckts
    } catch (e) {
      console.error(e)
      return []
    }
  }

  addGroup = (e) => {
    e && e.stopPropagation();
    const { decaps } = this.state;
    const defaultName = getDefaultName({ nameList: decaps.map(item => item.name), defaultKey: 'Decap_' });
    const newDecap = new Decap({ name: defaultName, number: 1 })
    this.setState({
      decaps: [
        ...decaps,
        newDecap
      ]
    }, () => {
      this.saveDecapGroup()
    })
  }

  deleteRow = (e, record) => {
    e && e.stopPropagation();
    const { decaps } = this.state;
    let _decaps = decaps.filter(item => item.id !== record.id);
    this.setState({
      decaps: _decaps
    }, () => {
      this.saveDecapGroup()
    })
  }

  changeData = (newData, record, key) => {
    const { decaps } = this.state;
    let _decaps = [...decaps];
    const index = _decaps.findIndex(item => item.id === record.id);
    const error = numberCheck(newData[key])
    if (!error) {
      _decaps[index][key] = newData[key];
    }
    this.setState({
      decaps: _decaps
    }, () => {
      this.saveDecapGroup()
    })
  }

  closeDecapGroup = (e) => {
    e && e.stopPropagation();
    this.props.showDecapGroup("");
  }

  deleteDecapGroup = (e) => {
    e && e.stopPropagation();
    this.props.deleteDecapGroup();
  }

  saveDecapGroup = () => {
    const group = new PrelayoutDecapGroup({ ...this.state });
    this.props.saveDecapGroup(group);
  }

  changeDecap = (record, model) => {
    const { decaps } = this.state;
    let _decaps = [...decaps];
    const index = _decaps.findIndex(item => item.id === record.id);
    let subckt = model.subckt;
    if (subckt) {
      _decaps[index].name = subckt;
      _decaps[index].model = model;
    }
    this.setState({
      decaps: _decaps
    }, () => {
      this.saveDecapGroup()
    })
  }

  saveGroupValue = (e, key) => {
    const value = e.target.value;
    const error = numberCheck(value)
    if (!error) {
      const group = new PrelayoutDecapGroup({ ...this.state });
      this.props.saveDecapGroup(group)
    } else {
      const { decapGroup } = this.props;
      this.setState({
        [key]: decapGroup[key]
      })
    }
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  groupButttonRender = () => {
    return (
      <div className="cascade-prelayout-decap-group-buttons">
        {/* <Button size="small" className="group-button hidden-button" onClick={this.saveDecapGroup}>Save</Button>
        <Button size="small" className="group-button" onClick={this.deleteDecapGroup}>Delete</Button> */}
        <DeleteOutlined
          className="group-button close-icon"
          title="Delete Decap Groups"
          onClick={this.deleteDecapGroup} />
        <CloseOutlined
          className="group-button close-icon"
          title="Close Panel"
          onClick={this.closeDecapGroup} />
      </div>
    );
  }

  render() {
    const { name, l_group, r_group, decaps } = this.state;
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color-only">Decap Group</span>
        {this.groupButttonRender()}
        <div className="cascade-prelayout-decap-group-content">
          <div className="decap-group-item">
            <span className="decap-group-item-title">Group Name</span>
            <Input
              className='aurora-input decap-group-item-input'
              value={name}
              onChange={(e) => this.valueChange(e, 'name')}
              onBlur={this.saveDecapGroup}
              onPressEnter={this.onPressEnter}
            />
          </div>
          <div className="decap-group-item">
            <span className="decap-group-item-title">L Group</span>
            <Input
              className='aurora-input decap-group-item-input'
              value={l_group}
              addonAfter='pH'
              onChange={(e) => this.valueChange(e, 'l_group')}
              onBlur={(e) => this.saveGroupValue(e, 'l_group')}
              onPressEnter={this.onPressEnter}
            />
          </div>
          <div className="decap-group-item">
            <span className="decap-group-item-title">R Group</span>
            <Input
              className='aurora-input decap-group-item-input'
              value={r_group}
              addonAfter='mΩ'
              onChange={(e) => this.valueChange(e, 'r_group')}
              onBlur={(e) => this.saveGroupValue(e, 'r_group')}
              onPressEnter={this.onPressEnter}
            />
          </div>
          <div className="decap-group-item">
            <span className="font-bold">Decap</span>
            <PlusCircleOutlined className='cascade-imp-icon' onClick={(e) => this.addGroup(e)} />
            <EditableTable
              rowKey="name"
              columns={columns}
              size="small"
              dataSource={decaps}
              className="cascade-prelayout-decap-table space-10"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: { prelayout: { content = {}, groupShow }, library: { DecapList }, project: { defaultDecap = {} } } } = state;
  const { decapGroups } = content
  const decapGroup = decapGroups.find(item => item.name === groupShow);
  const DecapSpiceList = DecapList.filter(item => item.type === DECAP_SPICE);
  return {
    groupShow,
    decapGroup,
    DecapSpiceList,
    defaultDecap
  }
}

const mapDispatch = (dispatch) => ({
  showDecapGroup() {
    dispatch(showDecapGroup())
  },
  deleteDecapGroup() {
    dispatch(deleteDecapGroup())
  },
  saveDecapGroup(group) {
    dispatch(saveDecapGroup(group))
  }
})

export default connect(mapState, mapDispatch)(DecapGroup);