import { takeEvery, call, select, put } from "redux-saga/effects";
import {
  UPDATE_LIBRARY_MENU,
  DELETE_LIBRARY,
  SAVE_EDITED_LIBRARY,
  OPEN_LIBRARY_FOLDER
} from './actionTypes';
import {
  getLibraryList,
  getLibraryTree,
  getLibraryTreeIndex,
  HIMALAYAS_LIBRARY_INDEX,
  delLibrary,
  saveEditedLibraryPromise,
  libraryItem,
  getLibraryFolderChildren
} from "../../../../services/Himalayas";
import {
  PCB_SUBCKT,
  PVT,
  SOC_SPICE,
  SOC_WRAPPER,
  SPICE_PARAMETERS,
  SPICE_PARAMETERS_LIB
} from "../../../../constants/libraryConstants";
import { updateTreeList } from "../project/action";
import { message } from "antd";
import libraryConstructor from "../../../../services/Himalayas/library/libraryConstructor";
import { LIBRARY_FILE } from "../../../../constants/treeConstants";

function* _updateLibraryList() {
  try {
    //get library
    const res = yield call(getLibraryList);
    //res -> { [key]:[...], ... }
    if (!res) {
      return;
    }

    const { HimalayasReducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];
    let libraryListObj = {
      [SPICE_PARAMETERS]: [],
      [SPICE_PARAMETERS_LIB]: [],
      [SOC_SPICE]: [],
      [SOC_WRAPPER]: [],
      [PCB_SUBCKT]: [],
      [PVT]: []
    }

    for (let key of [SPICE_PARAMETERS, SPICE_PARAMETERS_LIB, SOC_SPICE, SOC_WRAPPER, PCB_SUBCKT, PVT]) {
      //get library tree item
      libraryListObj[key] = getLibraryTree(res[key]);
      const { libraryIndex, subLibraryIndex } = getLibraryTreeIndex(key);
      //update tree items
      if (key === SPICE_PARAMETERS) {
        _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children = [treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0], ...libraryListObj[key]];
        continue;
      }
      if (key === SPICE_PARAMETERS_LIB) {
        _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children = libraryListObj[key];
        continue;
      }
      if (libraryIndex > -1 && subLibraryIndex > -1) {
        _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children = libraryListObj[key];
      } else if (libraryIndex > -1) {
        _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children = libraryListObj[key];
      }
      libraryListObj[key].map(item => libraryConstructor.addLibrary(key, item.id, item));
    }
    yield put(updateTreeList({ treeItems: _treeItems }));
  } catch (error) {
    console.error(error);
  }
}

function* _delLibrary(action) {
  const { itemData } = action;
  try {
    yield call(delLibrary, itemData.id);
    const { HimalayasReducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];
    const { libraryIndex, subLibraryIndex } = getLibraryTreeIndex(itemData.type);
    if (!itemData.path) {
      //delete the deleted library in tree items.
      if (itemData.type === SPICE_PARAMETERS_LIB) {
        _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children.filter(item => item.id !== itemData.id);
      } else if (libraryIndex > -1 && subLibraryIndex > -1) {
        _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children.filter(item => item.id !== itemData.id);
      } else if (libraryIndex > -1) {
        _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children.filter(item => item.id !== itemData.id);
      }
    } else {
      let list = []
      if (itemData.type === SPICE_PARAMETERS_LIB) {
        list = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children;
      } else if (libraryIndex > -1 && subLibraryIndex > -1) {
        list = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children;
      }
      let pathIds = itemData.path ? itemData.path.split('/') : [];
      pathIds = pathIds.filter(item => !!item);
      let newList = list, parentId = pathIds[pathIds.length - 1];
      for (let i = 0; i < pathIds.length; i++) {
        const folderIndex = newList.findIndex(it => it.id === pathIds[i]);
        newList = newList[folderIndex] ? newList[folderIndex].children : [];
      }
      newList = newList.filter(item => item.id !== itemData.id)
      const parent = libraryConstructor.getLibrary(itemData.type, parentId);
      if (parent && parent.children && parent.children.length) {
        parent.children = parent.children.filter(item => item.id !== itemData.id);
        libraryConstructor.addLibrary(itemData.type, parent.id, parent);
      }
    }
    libraryConstructor.delLibrary(itemData.type, itemData.id);
    yield put(updateTreeList({ treeItems: _treeItems }));

  } catch (error) {
    console.error(error);
    message.error(`Delete library failed!`);
  }
}

function* _saveLibrary(action) {
  const { library } = action;
  if (!library || !library.id) {
    return;
  }
  try {
    const res = yield call(saveEditedLibraryPromise, {
      libraryId: library.id,
      type: library.type,
      vdeNum: library.vdeNum
    });
    if (!res) {
      message.error(`Edit library failed!`);
      return;
    }

    const libraryInfo = new libraryItem({
      id: res.id,
      name: res.name,
      type: res.type,
      dataType: LIBRARY_FILE,
      vdeNum: res.vdeNum,
      fileType: res.fileType,
      path: res.path
    })
    const { HimalayasReducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];
    const { libraryIndex, subLibraryIndex } = getLibraryTreeIndex(library.type);
    //save the updated library in tree items.
    if (library.type === SPICE_PARAMETERS_LIB) {
      const index = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children.filter(item => item.id === library.id);
      if (!index < 0) {
        return;
      }
      _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children[index] = { ...libraryInfo };
    } else if (libraryIndex > -1 && subLibraryIndex > -1) {
      const index = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children.findIndex(item => item.id === library.id);
      if (!index < 0) {
        return;
      }
      _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[index] = { ...libraryInfo };
    } else if (libraryIndex > -1) {
      const index = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children.findIndex(item => item.id === library.id);
      if (!index < 0) {
        return;
      }
      _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[index] = { ...libraryInfo };
    }
    libraryConstructor.addLibrary(library.type, library.id, libraryInfo);
    yield put(updateTreeList({ treeItems: _treeItems }));
  } catch (error) {
    console.error(error);
    message.error(`Edit library failed! ${error}`);
  }
}

function* _openFolder(action) {
  const { itemData, libraryType, filesInfo } = action;
  //filesInfo -> upload file/folder
  //itemData -> expand tree item
  //itemData:null, filesInfo:null, expand soc spice and spice parameters lib tree item

  if (![SOC_SPICE, SPICE_PARAMETERS_LIB].includes(libraryType)) {
    return;
  }
  let uploadFiles = filesInfo && filesInfo.length ? getLibraryTree(filesInfo) : [];
  let files = uploadFiles.length ? uploadFiles : (itemData ? [itemData] : []);

  if (filesInfo && filesInfo.length && !uploadFiles.filter(item => item.fileType === "folder").length) {
    return;
  }

  const { HimalayasReducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems];
  try {
    let list = [];
    const { libraryIndex, subLibraryIndex } = getLibraryTreeIndex(libraryType);
    if (libraryType === SPICE_PARAMETERS_LIB) {
      list = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children;
    }
    if (libraryType === SOC_SPICE) {
      list = _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children;
    }

    if (files.length) {
      uploadFiles.length && list.push(...uploadFiles)
      for (let itemData of files) {
        if (itemData.fileType !== "folder") {
          continue;
        }
        let res = [];
        let library = libraryConstructor.getLibrary(libraryType, itemData.id);
        if (!library || !library.children) {
          res = yield call(getLibraryFolderChildren, itemData.id);
          if (!res || !res[libraryType] || !res[libraryType].length) {
            return;
          }
          if (!library) {
            library = itemData;
          }
          library.children = getLibraryTree(res[libraryType]);
          libraryConstructor.addLibrary(libraryType, itemData.id, library);
        }

        for (let data of library.children) {
          if (data.fileType !== "folder") {
            continue;
          }
          try {
            let childLibrary = libraryConstructor.getLibrary(libraryType, data.id);
            let childrenRes = {};
            if (!childLibrary || !childLibrary.children) {
              childrenRes = yield call(getLibraryFolderChildren, data.id);
              if (!childrenRes || !childrenRes[libraryType] || !childrenRes[libraryType].length) {
                continue;
              }
              if (!childLibrary) {
                childLibrary = data;
              }
              data.children = getLibraryTree(childrenRes[libraryType]);
              libraryConstructor.addLibrary(libraryType, data.id, data);
            }
          } catch (error) {
            console.error(error)
          }
        }
        let pathIds = itemData.path ? itemData.path.split('/') : [];
        pathIds = pathIds.filter(item => !!item)
        let newList = list;
        for (let i = 0; i < pathIds.length; i++) {
          const folderIndex = newList.findIndex(it => it.id === pathIds[i]);
          newList = newList[folderIndex] ? newList[folderIndex].children : [];
        }
        newList = library.children;
      }
    } else {
      for (let libItem of list) {
        if (libItem.fileType !== "folder") {
          continue;
        }
        try {
          let childLibrary = libraryConstructor.getLibrary(libraryType, libItem.id);
          let childrenRes = {};
          if (!childLibrary || !childLibrary.children) {
            childrenRes = yield call(getLibraryFolderChildren, libItem.id);
            if (!childrenRes || !childrenRes[libraryType] || !childrenRes[libraryType].length) {
              continue;
            }
            if (!childLibrary) {
              childLibrary = libItem;
            }
            libItem.children = getLibraryTree(childrenRes[libraryType] || []);
            libraryConstructor.addLibrary(libraryType, libItem.id, libItem);
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
    if (libraryType === SPICE_PARAMETERS_LIB) {
      _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children[0].children = list;
    }
    if (libraryType === SOC_SPICE) {
      _treeItems[HIMALAYAS_LIBRARY_INDEX].children[libraryIndex].children[subLibraryIndex].children = list;
    }
    yield put(updateTreeList({ treeItems: _treeItems }));
  } catch (error) {
    console.error(error)
  }
}

export default function* librarySaga() {
  yield takeEvery(UPDATE_LIBRARY_MENU, _updateLibraryList);
  yield takeEvery(DELETE_LIBRARY, _delLibrary);
  yield takeEvery(SAVE_EDITED_LIBRARY, _saveLibrary);
  yield takeEvery(OPEN_LIBRARY_FOLDER, _openFolder);
}