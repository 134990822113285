import React, { Component, Fragment } from 'react';
import { CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Input, Checkbox, Radio, Tooltip } from 'antd';
import { SIERRA, ROCKY } from '../../constants/pageType';
import TreeSelect from '@/components/TreeSelect';
import SetSPNodeModel from './multiPinSPICEModel';
import UnitAddonAfter from '../UnitAddonAfter';
import { numberCheck } from '@/services/helper/dataProcess';
import { strDelimited } from '@/services/helper/split';
import { unitChange } from '@/services/helper/mathHelper';
import './ModelPin.css';

const { Option } = Select;
const Group = Radio.Group;
const MultiPinSPICE = "MultiPinSPICE", INVERTER = "INVERTER";
const SIERRA_MODEL_PIN_TYPES = ['CLK+', "CLK-", 'PRBS-SDR', 'PRBS-DDR', 'VEC', 'GND', 'VCC', MultiPinSPICE];
const ROCKY_MODEL_PIN_TYPES = ['CLK+', "CLK-", 'PRBS-SDR', 'PRBS-DDR', 'PRBS-HDR', INVERTER, 'VEC', 'GND', 'VCC'];
const PRBS_TYPES = ['PRBS-SDR', 'PRBS-DDR', 'PRBS-HDR'];
const MODEL_ND_EN = ['CLK', 'SDR', 'DDR', 'VEC'];
const OUTPUT = ['Output', 'Open_drain', 'Open_sink', 'Open_source'];
const IBIS = 'IBIS';
const CLK_TYPES = ["CLK+", "CLK-"]
const ROCKY_PATTERN_MODEL_PIN_TYPES = ['CLK+', "CLK-", ...PRBS_TYPES, 'VEC'];
const radioItem = [
  { type: 'percent', name: 'Percentage of ClkCycle', style: { marginTop: 6 } },
  { type: 'absolute', name: 'Absolute time duration', style: { marginTop: 6 } }
]
/** 
 * props 
 * Data
 *    -info : { use, modelType, modelName, deviceVcc }
 *    -pinModels: [{ stimulus: {}, pinName, type, voltage ,seed}],
 *    -stimulus,
 *    -pinName
 * 
 * function:
 *    -setVisible
 */
class ModelPin extends Component {
  constructor(props) {
    super(props);
    const { stimulus, pins } = this.props;
    const info = this.getND_IN();
    let findInput = info.findInput;
    let _pinModels = info.pinModels;
    let findEnable = info.findEnable;
    const stimulusData = stimulus ? stimulus.stimulus : "";
    let pinModel = {}, model = { files: [], pairs: [] };
    if (findInput && findInput.type === MultiPinSPICE) {
      pinModel = this.getStimulusPins(pins);
      model = pinModel.model;
    }
    const signalList = this.getSignalList();
    this.state = this.initState({ _pinModels, stimulusData, findInput, findEnable, model, pinModel, signalList });
    this.tabsOnFocus = false;
  }

  initState = ({ _pinModels, stimulusData, findInput, findEnable, model, pinModel, signalList }) => {
    const { defaultDelayValue, product } = this.props;
    let inputPinType = "", inputSeed = "", inputLength = "7", inputTaps = [7, 6], inputSlew = { type: 'percent', value: '5' },
      inputDelay = { type: 'percent', value: '10' }, inputUnit = { slew: 'ps', delay: 'ps' }, inputRelative = {}, inputDutyCycle = {};

    if (findInput) {
      const { type, seed, tabs, slew, delay, relative, dutyCycle } = findInput;
      let defaultDelayUnit = null;
      const isSierraCLK = CLK_TYPES.includes(type) && product === SIERRA;
      if (defaultDelayValue && (isSierraCLK || product === ROCKY)) {
        const defaultValue = defaultDelayValue.type === 'absolute' ? defaultDelayValue.value.replace(/[A-Za-z]/g, '') : defaultDelayValue.value
        defaultDelayUnit = defaultDelayValue.type === 'absolute' ? defaultDelayValue.value.replace(/^[0-9.]*/g, '') : "ps"
        inputDelay = { type: defaultDelayValue.type, value: defaultValue }
        const index = _pinModels.findIndex(item => item.pinName === "nd_in");
        if (index > -1 && !delay) {
          _pinModels[index].delay = defaultDelayValue;
        }
      }
      if (isSierraCLK) {
        inputDutyCycle = { type: 'percent', value: '50' }
      }
      inputPinType = type ? type : inputPinType;
      inputSeed = seed ? seed : inputSeed;
      inputTaps = tabs ? tabs : inputTaps;
      inputSlew = slew ? { type: slew.type, value: slew.value.replace(/[A-Za-z]/g, '') } : inputSlew;
      inputDelay = delay ? { type: delay.type, value: delay.value.replace(/[A-Za-z]/g, '') } : inputDelay;
      inputLength = tabs && tabs.length ? tabs[0] : inputLength;
      inputDutyCycle = dutyCycle ? { type: dutyCycle.type, value: dutyCycle.value.replace(/[A-Za-z]/g, '') } : inputDutyCycle;
      inputUnit = {
        slew: slew && slew.type === 'absolute' ? slew.value.replace(/^[0-9.]*/g, '') : 'ps',
        delay: delay && delay.type === 'absolute' ? delay.value.replace(/^[0-9.]*/g, '')
          : ((CLK_TYPES.includes(type) && product === SIERRA) || product === ROCKY) ? (defaultDelayUnit || 'ps') : "ps",
        dutyCycle: dutyCycle && dutyCycle.type === 'absolute' ? dutyCycle.value.replace(/^[0-9.]*/g, '') : 'ps',
      }
      inputRelative = relative ? `${relative.component}-${relative.signal}-${relative.pin}` : ""
    }

    let files = [], pairs = [];
    if (model) {
      files = model.files ? model.files : [];
      pairs = model.pairs ? model.pairs : [];
    }



    return {
      title: '',
      pinModels: _pinModels,
      pinName: '',
      stimulus: stimulusData,
      inputPinType,
      enableType: (findEnable && findEnable.type) || "",
      inputSeed,
      inputLength,
      inputTaps,
      inputSlew,
      inputDelay,
      inputUnit,
      inputRelative,
      inputDutyCycle,
      vector: (stimulusData && stimulusData.fileName) || "",
      files,
      pairs,
      driverPins: pinModel && pinModel.pins ? pinModel.pins : [],
      applyAll: this.props.applyAll,
      signalList,
      error: "",
      applyAllDriver: false
    }
  }

  componentDidMount = () => {
    if (this.props.onChildRef) {
      this.props.onChildRef(this);
    }
  }

  getND_IN = () => {
    const { pinModels, modelPins, compModel, model = {}, record: { pin }, getPins, product } = this.props;//modelPins:{type: type, seed:seed } , pinModels: { type: "type-seed" }
    let findInput = modelPins ? modelPins.find(item => item.pinName === 'nd_in') : null;
    let findEnable = modelPins ? modelPins.find(item => item.pinName === 'nd_en') : null;
    let _pinModels = pinModels ? JSON.parse(JSON.stringify(pinModels)) : [];

    if (product === SIERRA && (!pinModels || pinModels.length === 0)) {
      const type = model && model.libType && model.libType === MultiPinSPICE ? MultiPinSPICE : (model.modelType || "I/O");
      _pinModels = getPins ? getPins({ type, usage: 'Driver' }) : null;
      findInput = _pinModels ? _pinModels.find(item => item.pinName === 'nd_in') : null;
    }

    let currentPinNode = null;
    if (compModel && compModel.pairs && compModel.pairs.length > 0) {
      const pinIn = `${pin}_in`;
      const findPinIn = compModel.pairs.find(item => item.pin === pinIn);
      currentPinNode = findPinIn ? findPinIn.node : null;
    }

    //If the component model is MultiPinSPICE. and the in node is set, the default setting nd_in type is MultiPinSPICE
    if (findInput && !findInput.type && model && model.libType === MultiPinSPICE && currentPinNode) {
      findInput.type = MultiPinSPICE;
      const index = _pinModels.findIndex(item => item.pinName === 'nd_in');
      _pinModels[index].type = MultiPinSPICE;
    }
    return { pinModels: _pinModels, findInput, findEnable }
  }

  getSignalList = () => {
    const { record, components } = this.props;
    if (!record || !components || !components.length) return [];
    let signalList = [];
    const { component, signal } = record;
    if (component && signal) {
      const current = components.find(item => item.name === component);
      if (current && current.pins) {
        signalList = current.pins.map(item => {
          const { driver = {}, signal, pin } = item;
          const { pinModels = [] } = driver;
          const nd_in = pinModels.find(i => i.pinName === 'nd_in');
          const stimulusType = nd_in ? nd_in.type : "";
          return { component, signal, pin, stimulusType }
        });
        signalList = signalList.filter(item => item.signal !== signal && item.stimulusType !== INVERTER);
      }
    }
    return signalList;
  }

  // 1. nd_in [VEC] -> [CLK | SDR | DDR]
  //      a. delete VEC, stimulus = ""
  // 2. nd_in [VEC] -> [GND | VCC] 
  //      a. push{} b. delete VEC, stimulus = ""
  // 3. nd_in [GND | VCC] -> [CLK | SDR | DDR]
  //      a. delete { ng_en } b.if(nd_in: VEC), delete VEC
  // 4. nd_in [GND | VCC]-> [VEC]
  //      a. delete { ng_en } b.if(nd_en: VEC), delete nd_en:VEC c. create nd_in:VEC
  // 5. nd_en [VEC] -> [CLK | SDR | DDR]
  //      a. delete nd_en:VEC
  onTypeChange = (type, pinName) => {
    const { pinModels, inputSeed, inputPinType, inputTaps, inputLength, inputSlew, inputDelay, inputDutyCycle } = this.state;
    const { getPins, model, product, pins, defaultDelayValue } = this.props;
    let newModelPins = JSON.parse(JSON.stringify(pinModels));
    if (newModelPins.length < 1 && getPins) {
      const type = model.libType === MultiPinSPICE ? MultiPinSPICE : (model.modelType || "I/O");
      newModelPins = getPins({ type, usage: 'Driver' });
    }

    let newType = type, newSeed = inputSeed, newTaps = inputTaps, newLength = inputLength, newSlew = inputSlew, newDelay = inputDelay, newDutyCycle = inputDutyCycle;
    if (PRBS_TYPES.includes(type)) {
      newType = `${type}-80`;
      newSeed = 80;
      newTaps = ['7', '6'];
      newLength = '7';
    }

    const index = newModelPins.findIndex(item => item.pinName === pinName);
    if (index > -1) {
      newModelPins[index].type = newType;
      newModelPins[index].stimulus = "";
      if (PRBS_TYPES.includes(type)) {
        newModelPins[index].tabs = newTaps;
        if (product === SIERRA) {
          delete newModelPins[index].delay;
          delete newModelPins[index].slew;
          newSlew = { type: 'percent', value: '5' };
          newDelay = { type: 'percent', value: '10' };
        }
      }

      if (product === SIERRA && CLK_TYPES.includes(type)) {
        if (defaultDelayValue) {
          newModelPins[index].delay = defaultDelayValue;
        } else {
          delete newModelPins[index].delay;
        }
        delete newModelPins[index].slew;
        delete newModelPins[index].dutyCycle;
        newSlew = { type: 'percent', value: '5' };
        newDelay = defaultDelayValue ? defaultDelayValue : { type: 'percent', value: '10' };
        newDutyCycle = { type: 'percent', value: '50' };
      }
    }

    if (product === SIERRA && pinName === 'nd_in') {
      // Delete stimulus
      const ndEn = newModelPins.findIndex(item => item.pinName === 'nd_en');
      if (this.enableDisplay(type)) {
        // Add nd_en pin
        if (ndEn < 0) {
          newModelPins.push({
            pinName: "nd_en",
            stimulus: "",
            voltage: "",
            type: ""
          })
        };
      } else {
        // Delete nd_en pin
        if (ndEn > -1) {
          newModelPins.splice(ndEn, 1);
        }
      }
    }

    const findEnable = newModelPins.find(item => item.pinName === 'nd_en');
    let spiceModel = { files: [], pairs: [] }, _pins = [];
    if (type === MultiPinSPICE) {
      const _model = this.getStimulusPins(pins, true);
      _pins = _model.pins;
      spiceModel = _model.model;
    }
    this.setState({
      pinModels: newModelPins,
      inputSeed: newSeed,
      inputTaps: newTaps,
      inputLength: newLength,
      inputPinType: pinName === 'nd_in' ? type : inputPinType,
      enableType: (findEnable && findEnable.type) || "",
      stimulus: null,
      vector: null,
      files: spiceModel.files,
      pairs: spiceModel.pairs,
      driverPins: _pins,
      inputSlew: newSlew,
      inputDelay: newDelay,
      inputDutyCycle: newDutyCycle
    }, () => {
      //  this.savePinValue(newModelPins);
      this.props.setContentHeight(0);
    });
  }

  getStimulusPins = (_pins, switchType) => {
    let files = [], pairs = [], pins = [];
    const { record } = this.props;
    for (let pin of _pins) {
      let model = pin.model;
      if (switchType && pin.pin === record.pin) {
        pins.push(pin);
        pairs.push({
          libraryId: "",
          fileName: "",
          subckt: "",
          pin: `${record.pin}_in`,
          node: "",
        });
        continue;
      }
      if (pin.pinModels && pin.pinModels.length) {
        const ND_IN = pin.pinModels.find(item => item.pinName === 'nd_in');
        if (ND_IN && ND_IN.type === MultiPinSPICE) {
          pins.push(pin);
          const stimulus = ND_IN.stimulus;
          //todo
          const findF = files.find(item => item.libraryId === stimulus.libraryId && item.fileName === stimulus.fileName && item.subckt === stimulus.subckt);
          if (!findF && stimulus.libraryId) {
            files.push({
              libraryId: stimulus.libraryId,
              folder: stimulus.folder,
              fileName: stimulus.fileName,
              subckt: stimulus.subckt,
              type: MultiPinSPICE
            })
          }
          pairs.push({
            libraryId: stimulus.libraryId ? stimulus.libraryId : "",
            fileName: stimulus.fileName ? stimulus.fileName : "",
            subckt: stimulus.subckt ? stimulus.subckt : "",
            pin: stimulus.pin ? stimulus.pin : `${pin.pin}_in`,
            node: stimulus.node ? stimulus.node : "",
          })
        } else if ((!ND_IN || !ND_IN.type) && model && (model.libType !== IBIS)) {
          pins.push(pin);
          pairs.push({
            libraryId: "",
            fileName: "",
            subckt: "",
            pin: `${pin.pin}_in`,
            node: "",
          })
        }
      } else if (model && (model.libType !== IBIS)) {
        pins.push(pin);
        pairs.push({
          libraryId: "",
          fileName: "",
          subckt: "",
          pin: `${pin.pin}_in`,
          node: "",
        })
      }
    }
    return { model: { files, pairs }, pins }
  }

  enableDisplay = (type) => {
    const { model } = this.props;
    if ((type === 'GND' || type === 'VCC') && (model && model.libType === IBIS && (!model.modelType || !OUTPUT.includes(model.modelType)))) {
      return true;
    } else {
      return false;
    }
  }

  selectVector = (vec) => {
    const { vectorList } = this.props;
    const { inputPinType, pinModels } = this.state;
    const pinName = (inputPinType === 'VEC' && 'nd_in') || 'nd_en';
    let name = vec, findVec = null;
    if (vec.id) {
      name = vec.name;
      findVec = { fileName: name, id: vec.id, version: vec.version };
    } else {
      findVec = vectorList.find(item => item.name === vec);
    }
    const _stimulus = {
      fileName: findVec.fileName,
      libraryId: findVec.id,
      version: findVec.version
    }
    let _pinModels = pinModels;
    const index = _pinModels.findIndex(item => item.pinName === pinName);
    _pinModels[index].stimulus = { ..._stimulus };
    if (findVec) {
      this.setState({
        vector: name,
        stimulus: _stimulus,
        _pinModels
      })
    }
  }

  savePinValue = (newModelPins) => {
    const { record, saveModelPins, product } = this.props;
    const { applyAll, pinModels, inputPinType, inputRelative, applyAllDriver } = this.state;
    const { signal, component, net, pin, ...restProps } = record;
    let _pinModels = newModelPins ? newModelPins : pinModels;
    let index = _pinModels.findIndex(item => item.pinName === "nd_in");
    if (index > -1) {
      //_pinModels[index]:{pinName,stimulus:{}, type:"",voltage ,seed}
      delete _pinModels[index].seed;

      !PRBS_TYPES.includes(inputPinType) && delete _pinModels[index].tabs;
      if (![...PRBS_TYPES, ...CLK_TYPES].includes(inputPinType)) {
        delete _pinModels[index].delay;
        delete _pinModels[index].slew;
      }
      (!CLK_TYPES.includes(inputPinType) || product !== SIERRA) && delete _pinModels[index].dutyCycle;

      if (inputPinType === INVERTER) {
        if (!inputRelative) {
          this.setState({
            error: `Signal should be selected when stimulus type is inverter.`
          })
          return true;
        }
        const [component, signal, pin] = strDelimited(inputRelative, '-');
        _pinModels[index].relative = { component, signal, pin };
      } else {
        delete _pinModels[index].relative
      }
    }
    if (inputPinType === MultiPinSPICE && this.SubChild && this.SubChild.saveModel) {
      const _modelInfo = this.SubChild.saveModel();
      this.props.savePinStimulusModel(_modelInfo, record);
    } else {
      saveModelPins({ record: { ...restProps, component, pin, net, signal }, pinModels: _pinModels, applyAll, applyAllDriver });
    }
  }

  onRef = (ref) => {
    this.SubChild = ref;
  }

  cleanType = (e) => {
    e && e.stopPropagation();
    const { pinModels } = this.state;
    let newPinModels = pinModels;

    if (newPinModels) {
      newPinModels.forEach(item => {
        if (item.pinName === 'nd_in') {
          item.type = "";
          item.stimulus = ""
        }
      });
      newPinModels = newPinModels.filter(item => item.pinName !== 'nd_en');
    }

    this.setState({
      inputPinType: "",
      enableType: "",
      inputSeed: "",
      pinModels: newPinModels
    }, () => {
      this.props.setContentHeight(0);
    })
  }

  cleanEnable = (e) => {
    e && e.stopPropagation();
    const { pinModels } = this.state;
    let newPinModels = pinModels;

    if (newPinModels) {
      newPinModels.forEach(item => {
        if (item.pinName === 'nd_en') {
          item.type = "";
          item.stimulus = ""
        }
      })
    }

    this.setState({
      enableType: "",
      pinModels: newPinModels
    }, () => {
      this.props.setContentHeight(0);
    })
  }

  cleanVectorFile = (e) => {
    e && e.stopPropagation();
    const { pinModels } = this.state;
    let newPinModels = pinModels;

    if (newPinModels) {
      newPinModels.forEach(item => {
        if (item.pinName === 'nd_in' || item.pinName === 'nd_en') {
          item.stimulus = ""
        }
      })
    }

    this.setState({
      pinModels: newPinModels,
      stimulus: null,
      vector: null
    }, () => {
      this.props.setContentHeight(0);
    })
  }

  onApplyChange = (e, key) => {
    this.setState({
      [key]: e.target.checked
    })
  }

  onRelativeChange = (key) => {
    this.setState({
      inputRelative: key,
      error: ''
    })
  }

  selectComponents = (vector, libraryId, vectorList) => {
    const { product } = this.props;
    return product === SIERRA ? <TreeSelect
      value={vector || undefined}
      onSelectItem={this.selectVector}
      size='small'
      showSearch
      popupClassName='stimulus-setup-model-select-dropdown'
      placeholder={'Vector Model'}
      className='IC-model-type-select'
      allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanVectorFile(e)} /> }}
      fileList={vectorList}
      getPopupContainer={() => document.getElementById('root')}
      selected={libraryId}
    />
      : <Select
        placeholder={'Vector Model'}
        className='stimulus-setup-model-select'
        value={vector || undefined}
        onChange={this.selectVector}
        popupMatchSelectWidth={false}
        popupClassName='stimulus-setup-model-select-dropdown'
        size='small'
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanVectorFile(e)} /> }}
      >
        {vectorList.map(vec => <Option key={vec.name}>{vec.name}</Option>)}
      </Select>;
  }

  getCLKTypesComponents = () => {
    const { inputSlew, inputDelay, inputDutyCycle } = this.state;
    const { product } = this.props;
    return <Fragment>
      <div className='stimulus-setup-model clear'>
        {this.getSlewAndDelayComp(inputSlew, 'Slew Rate', 'slew', 'inputSlew')}
      </div>
      <div className='stimulus-setup-model clear'>
        {this.getSlewAndDelayComp(inputDelay, 'Delay', 'delay', 'inputDelay')}
      </div>
      {product === SIERRA ? <div className='stimulus-setup-model clear'>
        {this.getSlewAndDelayComp(inputDutyCycle, 'Duty Cycle', 'dutyCycle', 'inputDutyCycle')}
      </div> : null}
    </Fragment>
  }

  getPRBSComponents = () => {
    const { inputSeed, inputLength, inputTaps, inputSlew, inputDelay } = this.state;
    return <Fragment>
      <div className='stimulus-setup-model clear'>
        <div className='stimulus-setup-model-title'>Length</div>
        <Input
          placeholder="Length"
          size='small'
          value={inputLength}
          onChange={(e) => this.changeInput(e, 'inputLength')}
          onFocus={() => { this.clearError() }}
          className='stimulus-setup-model-select'
          onBlur={e => this.saveLength(e, 'nd_in')}
          onPressEnter={e => this.enterDown(e)}
        />
      </div>
      <div className='stimulus-setup-model clear'>
        <div className='stimulus-setup-model-title'>Taps</div>
        <Select
          placeholder="Taps"
          mode="multiple"
          value={inputTaps}
          onChange={(value) => this.onTabsChange(value, 'nd_in')}
          popupMatchSelectWidth={true}
          popupClassName='stimulus-setup-model-select-dropdown'
          size='small'
          className='stimulus-setup-model-select'
          tokenSeparators={[',', ' ']}
          onBlur={() => { this.selectTabsBlur('blur') }}
          onFocus={() => { this.changetabsOnFocus(); this.clearError() }}
          onDeselect={() => { this.selectTabsBlur('deselect') }}
        >
          {this.getTabsList(inputLength, inputTaps)}
        </Select>
      </div>
      <div className='stimulus-setup-model clear'>
        <div className='stimulus-setup-model-title'>Seed</div>
        <Input
          placeholder="Seed"
          size='small'
          value={inputSeed}
          onChange={(e) => this.changeInput(e, 'inputSeed')}
          className='stimulus-setup-model-select'
          onBlur={e => this.saveSeed(e, 'nd_in')}
          onFocus={() => { this.clearError() }}
          onPressEnter={e => this.saveSeed(e, 'nd_in')}
        />
      </div>
      <div className='stimulus-setup-model clear'>
        {this.getSlewAndDelayComp(inputSlew, 'Slew Rate', 'slew', 'inputSlew')}
      </div>
      <div className='stimulus-setup-model clear'>
        {this.getSlewAndDelayComp(inputDelay, 'Delay', 'delay', 'inputDelay')}
      </div>
    </Fragment>
  }

  getSlewAndDelayComp = (data, title, type, stateKey) => {
    const { clock } = this.props;
    return (
      <Fragment>
        <div className='stimulus-setup-model-title title-remove-float'>{title}</div>
        <Group
          size='small'
          name='slew'
          value={data.type}
          className='stimulus-setup-model-radio-group'
          onChange={(e) => this.changeSlewAndDelayType(e, stateKey)}
        >
          {radioItem.map(item => {
            const disabled = item.type !== data.type;
            const isDutyCycleAbsolute = type === "dutyCycle" && item.type === "absolute";
            return (
              <span className='stimulus-setup-model-radio-content' key={item.type}>
                <Radio className='stimulus-setup-model-radio' value={item.type}></Radio>
                <span className={isDutyCycleAbsolute ? 'stimulus-setup-model-radio-title-dutyCycle' : 'stimulus-setup-model-radio-title'} style={item.style}>
                  {isDutyCycleAbsolute ? `${item.name} per high level bit` : item.name}
                  {item.type === 'percent' && <Tooltip
                    title={`The value is percentage of 1 / ${clock.value}${clock.unit}Hz(ClockFrequency)`}
                    overlayClassName='aurora-tooltip stimulus-setup-tooltop'
                  >
                    <QuestionCircleOutlined className='stimulus-question-icon' onClick={(e) => e.stopPropagation()} />
                  </Tooltip>}
                </span>
                <Input
                  className={isDutyCycleAbsolute ? 'stimulus-setup-model-radio-input stimulus-setup-model-radio-duty-cycle-input' : 'stimulus-setup-model-radio-input'}
                  size='small'
                  addonAfter={this.getAddonAfter(item.type, type, disabled, stateKey, title)}
                  disabled={disabled}
                  value={!disabled ? data.value : ''}
                  onChange={(e) => this.changeSlewAndDelayValue(e, stateKey)}
                  onBlur={(e) => this.saveSlewAndDelay(stateKey, title, type, 'nd_in')}
                />
              </span>
            );
          })}
        </Group>
      </Fragment>
    );
  }

  getAddonAfter = (type, dataType, disabled, stateKey, title) => {
    const { inputUnit } = this.state;
    return type === 'percent' ? <span style={{ padding: '0px 7.5px' }}>%</span>
      : UnitAddonAfter({
        unit: inputUnit[dataType],
        disabled,
        changeUnit: (key) => this.changeInputUnit(key, dataType, stateKey, title),
        list: ["ps", "ns", "us"]
      });
  }

  changeSlewAndDelayType = (e, type) => {
    if (e && e.target && e.target.value) {
      this.setState({
        [type]: { type: e.target.value, value: '' }
      })
    }
  }

  changeInputUnit = (key, dataType, stateKey, title) => {
    this.setState({
      inputUnit: {
        ...this.state.inputUnit,
        [dataType]: key
      }
    }, () => {
      this.saveSlewAndDelay(stateKey, title, dataType, 'nd_in')
    })
  }

  changeSlewAndDelayValue = (e, key) => {
    const value = e.target.value;
    const data = this.state[key];
    this.setState({
      [key]: { ...data, value }
    })
  }

  saveSlewAndDelay = (key, title, type, pinName) => {
    const data = this.state[key];
    const { clock } = this.props;
    let unit = '', error = '';
    if (data.type === 'absolute') {
      unit = this.state.inputUnit[type];
    }
    if (numberCheck(data.value)) {
      error = `${title} must be a number.`;
    } else if (Number(data.value) <= 0) {
      error = `${title} must be greater than 0.`;
    } else if (type === 'slew') {
      if (data.type === 'percent' && data.value >= 20) {
        error = `${title} must be less than 20%.`
      } else if (data.type === 'absolute') {
        const cycle = 1 / unitChange({ num: clock.value, oldUnit: `${clock.unit}Hz`, newUnit: 'Hz', decimals: -1 }).number;
        const maxSlew = unitChange({ num: cycle * 0.2, oldUnit: 's', newUnit: unit, decimals: -1 });
        if (maxSlew.number < data.value) {
          error = `${title} must be less than ${Number(maxSlew.number.toFixed(5))}${unit}`;
        }
      }
    } else if (type === "dutyCycle" && data.type === "percent" && Number(data.value) > 100) {
      error = `${title} must be less than or equal to 100%.`;
    }

    const { pinModels } = this.state;
    let newModelPins = JSON.parse(JSON.stringify(pinModels));
    if (!error) {
      const index = newModelPins.findIndex(item => item.pinName === pinName);

      if (index > -1) {
        newModelPins[index][type] = { type: data.type, value: `${data.value}${unit}` };
      }
    }

    this.setState({
      error,
      pinModels: newModelPins,
    }, () => {
      this.props.setContentHeight(0);
    })
  }

  selectTabsBlur = (type) => {
    const { inputTaps, inputLength } = this.state;
    let len = inputTaps.length - 1;
    if (type === 'blur') {
      len = inputTaps.length;
      this.tabsOnFocus = false;
    }
    if (len < 2 && !this.tabsOnFocus) {
      this.setState({
        error: 'Please choose at least 2 taps'
      }, () => {
        this.props.setContentHeight(0);
      })
      setTimeout(() => {
        this.onTabsChange([inputLength, String(Number(inputLength - 1))], 'nd_in');
      }, 500)
    }
  }

  changetabsOnFocus = () => {
    this.tabsOnFocus = true;
  }

  clearError = () => {
    this.setState({ error: "" }, () => { this.props.setContentHeight(0) });
  }

  onTabsChange = (value, pinName) => {
    const { pinModels, inputSeed, inputPinType } = this.state;
    let newModelPins = JSON.parse(JSON.stringify(pinModels));
    const index = newModelPins.findIndex(item => item.pinName === pinName);
    const _value = value.sort((a, b) => Number(b) - Number(a));
    if (index > -1) {
      let newType = `${inputPinType}-${inputSeed}`
      newModelPins[index].type = newType;
      newModelPins[index].tabs = _value;
    }
    // this.props.savePinValue(newModelPins);
    this.setState({
      inputTaps: _value,
      pinModels: newModelPins
    })
  }

  getTabsList = (length, taps) => {
    let newTabs = [...taps];
    for (let i = 1; i <= Number(length); i++) {
      if (!taps.includes(String(i))) {
        newTabs.push(i);
      }
    }
    return newTabs.map(tap => <Option key={tap} disabled={tap === taps[0] ? true : false}>{tap}</Option>)
  }

  saveLength = (e, pinName) => {
    let length = e.target.value;
    let error = '';
    const { inputTaps, inputSeed } = this.state;
    let taps = inputTaps;
    //check is integer
    if (!/^\d+$/.test(length)) {
      length = "7";
      taps = ["7", "6"];
      error = "Length must be an integer.";
    } else {
      if (length < 7 || length > 31) {
        length = "7";
        error = "Length must be between 7-31.";
        taps = ["7", "6"]
      }
    }

    const number = Number(length);
    taps = [length, String(number - 1)];
    const seed = Number(inputSeed) > Math.pow(2, number) ? 80 : inputSeed;

    const { pinModels, inputPinType } = this.state;
    let newModelPins = JSON.parse(JSON.stringify(pinModels));
    const index = newModelPins.findIndex(item => item.pinName === pinName);

    if (index > -1) {
      newModelPins[index].type = `${inputPinType}-${seed}`;
      newModelPins[index].tabs = taps;
    }

    this.setState({
      inputLength: length,
      inputSeed: seed,
      inputTaps: taps,
      pinModels: newModelPins,
      error
    }, () => {
      this.props.setContentHeight(0);
    })
  }

  changeInput = (e, type) => {
    this.setState({
      [type]: e.target.value
    })
  }

  saveSeed = (e, pinName) => {
    let seed = e.target.value;
    const { inputLength } = this.state;
    let error = '';
    //check is integer
    if (!/^\d+$/.test(seed)) {
      seed = 80;
      error = "Seed must be an integer.";
    } else {
      const max = Math.pow(2, Number(inputLength));
      if (seed < 0) {
        seed = 80;
        error = "Seed must be greater than 0.";
      } else if (Number(seed) >= max) {
        seed = 80;
        error = max > 10000 ? `Seed must be less than 2 to the power of ${inputLength}.` : `Seed must be less than ${max}.`;
      }
    }

    const { pinModels, inputPinType } = this.state;
    let newModelPins = JSON.parse(JSON.stringify(pinModels));
    const index = newModelPins.findIndex(item => item.pinName === pinName);

    if (index > -1) {
      let newType = `${inputPinType}-${seed}`
      newModelPins[index].type = newType;
    }

    this.setState({
      inputSeed: seed,
      pinModels: newModelPins,
      error
    }, () => {
      this.props.setContentHeight(0);
    })
  }

  enterDown = (e) => {
    e.target.blur();
  }

  render() {
    const { inputPinType, vector, enableType, files, pairs, driverPins, applyAll, stimulus, error, inputRelative, signalList, applyAllDriver } = this.state;
    const { vectorList, product, libraryList, scrollId, cmd_2t_mode, isMultiSetup, isPatternLibrary } = this.props;
    const enableDisplay = this.enableDisplay(inputPinType);
    const libraryId = stimulus ? stimulus.libraryId : null;
    const MODEL_PIN_TYPES = product === SIERRA ? (isMultiSetup ? SIERRA_MODEL_PIN_TYPES.filter(item => item !== MultiPinSPICE) : SIERRA_MODEL_PIN_TYPES) : isPatternLibrary ? ROCKY_PATTERN_MODEL_PIN_TYPES : ROCKY_MODEL_PIN_TYPES;
    return (
      <div>
        <div className='stimulus-setup-model clear'>
          <div className='stimulus-setup-model-title'>Type</div>
          <Select
            placeholder="Type"
            className='stimulus-setup-model-select'
            value={inputPinType}
            onChange={(type) => this.onTypeChange(type, 'nd_in')}
            popupMatchSelectWidth={false}
            popupClassName='stimulus-setup-model-select-dropdown'
            size='small'
            allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanType(e)} /> }}
          >
            {MODEL_PIN_TYPES.map(type =>
              <Option
                key={type}
                disabled={type === 'PRBS-HDR' && (isPatternLibrary || !cmd_2t_mode)}
              >
                {type === MultiPinSPICE ? 'MultiPin SPICE' : type}
              </Option>)}
          </Select>
        </div>
        {inputPinType === MultiPinSPICE ?
          <SetSPNodeModel
            onRef={this.onRef}
            fileList={libraryList}
            {...this.props}
            pins={driverPins}
            files={files}
            pairs={pairs}
            modelPinType={"Stimulus"}
            modelType={MultiPinSPICE}
            scrollId={scrollId}
          /> :
          <Fragment>
            {/* product === SIERRA && */ CLK_TYPES.includes(inputPinType) ? this.getCLKTypesComponents() : null}
            {PRBS_TYPES.includes(inputPinType) ? this.getPRBSComponents() : null}
            {product === SIERRA && ['GND', 'VCC'].includes(inputPinType) && <div className='stimulus-setup-model clear'>
              <div className='stimulus-setup-model-title'>Enable</div>
              <Select
                disabled={!enableDisplay}
                placeholder={enableDisplay ? "Type" : ""}
                className='stimulus-setup-model-select'
                value={enableType}
                onChange={(type) => this.onTypeChange(type, 'nd_en')}
                popupMatchSelectWidth={false}
                popupClassName='stimulus-setup-model-select-dropdown'
                size='small'
                allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanEnable(e)} /> }}
              >
                {MODEL_ND_EN.map(type => <Option key={type}>{type}</Option>)}
              </Select>
            </div>}
            {inputPinType === INVERTER && <div className='stimulus-setup-model clear'>
              <div className='stimulus-setup-model-title'>Signal</div>
              <Select
                className='stimulus-setup-model-select'
                value={inputRelative}
                onChange={(key) => this.onRelativeChange(key)}
                popupMatchSelectWidth={false}
                popupClassName='stimulus-setup-model-select-dropdown'
                size='small'
                allowClear={true}
              >
                {signalList.map(item => <Option
                  key={`${item.component}-${item.signal}-${item.pin}`}
                  title={`${item.component}-${item.pin}`}>
                  {item.signal} ({item.component} - {item.pin})
                </Option>)}
              </Select>
            </div>}
            {(inputPinType === 'VEC' || enableType === 'VEC') &&
              <div className='stimulus-setup-model clear'>
                <div className='stimulus-setup-model-title'>Vector</div>
                {this.selectComponents(vector, libraryId, vectorList)}
              </div>}
            {isPatternLibrary ? null :
              <div className='stimulus-setup-model clear'>
                <div className='stimulus-apply-model-title'>Apply the stimulus to all the pins in the component</div>
                <Checkbox
                  className='stimulus-model-apply-checkbox'
                  checked={applyAll}
                  onChange={(e) => this.onApplyChange(e, "applyAll")}
                />
              </div>}
            {product === SIERRA ? <div className='stimulus-setup-model stimulus-setup-model-driver clear'>
              <div className='stimulus-apply-model-title'>Apply the stimulus to all Drivers</div>
              <Checkbox
                className='stimulus-model-apply-checkbox'
                checked={applyAllDriver}
                onChange={(e) => this.onApplyChange(e, "applyAllDriver")}
              />
            </div> : null}
            {error && <div className='spice-model-stimulus-error'>{error}</div>}
          </Fragment>}
      </div>
    )
  }
}

export default ModelPin;