const VALUE = 'value', ABSOLUTE = 'absolute';

export const viaColumns = [
  {
    title: 'Net',
    dataIndex: 'net',
    width: '100px',
    sorter: (a, b) => a.net.localeCompare(b.net),
  },
  {
    title: 'X (mm)',
    dataIndex: 'X',
    width: '100px',
    sorter: (a, b) => Number(a.X) - Number(b.X),
  },
  {
    title: 'Y (mm)',
    dataIndex: 'Y',
    width: '100px',
    sorter: (a, b) => Number(a.Y) - Number(b.Y),
  },
  {
    title: 'From Layer',
    dataIndex: 'fromLayer',
    width: '100px',
    sorter: (a, b) => a['fromLayer'].localeCompare(b['fromLayer']),
  },
  {
    title: 'To Layer',
    dataIndex: 'toLayer',
    width: '100px',
    sorter: (a, b) => a['toLayer'].localeCompare(b['toLayer']),
  },
  {
    title: 'I (A)',
    dataIndex: 'I',
    width: '100px',
    sorter: (a, b) => Number(a.I) - Number(b.I),
  },
  {
    title: 'R',
    dataIndex: 'R',
    width: '100px',
    sorter: (a, b) => Number(a.R) - Number(b.R),
  },
]

export const pinColumns = [
  {
    title: 'Component - Pin',
    dataIndex: 'components',
    width: '100px'
  },
  {
    title: 'Net',
    dataIndex: 'net',
    width: '100px',
    sorter: (a, b) => a['net'].localeCompare(b['net']),
  },
  {
    title: 'X (mm)',
    dataIndex: 'X',
    width: '100px',
    sorter: (a, b) => Number(a.X) - Number(b.X),
  },
  {
    title: 'Y (mm)',
    dataIndex: 'Y',
    width: '100px',
    sorter: (a, b) => Number(a.Y) - Number(b.Y),
  },
  {
    title: 'Voltage Drop (mV)',
    dataIndex: 'voltageDrop',
    width: '100px',
    sorter: (a, b) => Number(a.voltageDrop) - Number(b.voltageDrop),
  },
  {
    title: 'V (mV)',
    dataIndex: 'V',
    width: '100px',
    sorter: (a, b) => Number(a.V) - Number(b.V),
  },
  {
    title: 'I (A)',
    dataIndex: 'I',
    width: '100px',
    sorter: (a, b) => Number(a.I) - Number(b.I),
  }
]

export const bumpColumns = [
  {
    title: 'Bump',
    dataIndex: 'bump',
    width: '100px'
  },
  {
    title: 'Net',
    dataIndex: 'net',
    width: '100px',
    sorter: (a, b) => a['net'].localeCompare(b['net']),
  },
  {
    title: 'X (mm)',
    dataIndex: 'X',
    width: '100px',
    sorter: (a, b) => Number(a.X) - Number(b.X),
  },
  {
    title: 'Y (mm)',
    dataIndex: 'Y',
    width: '100px',
    sorter: (a, b) => Number(a.Y) - Number(b.Y),
  },
  {
    title: 'Voltage Drop (mV)',
    dataIndex: 'voltageDrop',
    width: '100px',
    sorter: (a, b) => Number(a.voltageDrop) - Number(b.voltageDrop),
  },
  {
    title: 'V (mV)',
    dataIndex: 'V',
    width: '100px',
    sorter: (a, b) => Number(a.V) - Number(b.V),
  },
  {
    title: 'I (A)',
    dataIndex: 'I',
    width: '100px',
    sorter: (a, b) => Number(a.I) - Number(b.I),
  },
]

