import {
  CREATE_PRE_LAYOUT,
  UPDATE_PRE_LAYOUT_CONTENT,
  OPEN_PRE_LAYOUT,
  SAVE_PRE_LAYOUT,
  SAVE_PRE_LAYOUT_SETTING,
  FIRST_OPEN_PRE_LAYOUT,
  COMP_TABLE_LOADING,
  UPDATE_PRE_LAYOUT_COMPONENTS,
  SCHEMATIC_LOADING,
  UPDATE_CANVAS,
  SAVE_PRE_LAYOUT_MODEL,
  CREATE_PRELAYOUT_INTERFACE,
  CREATE_LOADING,
  UPDATE_CANVAS_CONFIG,
  UPDATE_PRE_LAYOUT_DATA
} from './actionTypes';

export const createNewPreLayout = () => ({
  type: CREATE_PRE_LAYOUT
})

export const updatePreLayoutContent = (params) => ({
  type: UPDATE_PRE_LAYOUT_CONTENT,
  params
})

export const openPreLayoutPage = (id) => ({
  type: OPEN_PRE_LAYOUT,
  id
})

export const savePreLayout = (name, params) => ({
  type: SAVE_PRE_LAYOUT,
  name,
  params
})

export const savePreLayoutSetting = (name, setting) => ({
  type: SAVE_PRE_LAYOUT_SETTING,
  name,
  setting
})

export const firstOpenPreLayout = (boolean) => ({
  type: FIRST_OPEN_PRE_LAYOUT,
  boolean
})

export const updateCompTableLoading = (boolean) => ({
  type: COMP_TABLE_LOADING,
  boolean
})

export const updateComponents = (components) => ({
  type: UPDATE_PRE_LAYOUT_COMPONENTS,
  components
})

export const updateSchematicLoading = (boolean) => ({
  type: SCHEMATIC_LOADING,
  boolean
})

export const updateCanvasStatus = (boolean) => ({
  type: UPDATE_CANVAS,
  boolean
})

export const saveModel = (model, components, saveToBackend) => ({
  type: SAVE_PRE_LAYOUT_MODEL,
  model,
  saveToBackend,
  components
})

export const createPreLayoutInterface = () => ({
  type: CREATE_PRELAYOUT_INTERFACE
})

export const createLoading = (boolean) => ({
  type: CREATE_LOADING,
  boolean
});

export const updateCanvasConfig = (config) => ({
  type: UPDATE_CANVAS_CONFIG,
  config
});

export const updatePreLayoutData = (info) => ({
  type: UPDATE_PRE_LAYOUT_DATA,
  info
});