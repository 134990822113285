import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import SpiceCardTable from './SpiceCardTable';
import './index.css';

class TemplateContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
    }
  }

  render() {
    const { fileName, spiceCardTemplate, siderWidth, height, tabVisible, verificationId } = this.props;
    return <div className='himalayas-spice-card-template-content'>
      <Row>
        <Col span={24} className='aurora-setup-border'>
          <div className='himalayas-spice-card-file-box'>
            {fileName}
            {/* todo - download xlsx */}
            {/*  <Icon
                type="download"
                title="Download xlsm file"
                className="himalayas-spice-card-template-download-icon"
                onClick={this.downloadXlsm}
              /> */}
          </div>
        </Col>
      </Row>
      <Row className='space-16'>
        <Col span={24} className='aurora-setup-border'>
          <SpiceCardTable
            spiceCardTemplate={spiceCardTemplate}
            siderWidth={siderWidth}
            height={height}
            tabVisible={tabVisible}
            verificationId={verificationId}
          />
        </Col>
      </Row>
    </div>
  }
}

const mapState = (state) => {
  const { HimalayasReducer: {
    project: { openProjectId },
    spiceCard: {
      verificationId,
      data: { fileName, spiceCardTemplate }
    } },
    MonitorInfoReducer: { tabVisible } } = state;
  return {
    openProjectId,
    verificationId,
    fileName,
    spiceCardTemplate,
    tabVisible
  };
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(TemplateContent);