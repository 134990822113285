import React, { Component, Fragment, createRef } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { connect } from 'react-redux';
import { message } from 'antd';
import {
  PROJECT,
  PCB,
  DCR,
  IR_EXPLORER,
  IMPEDANCE,
  SYS_LIBRARY_FOLDER,
  PACKAGE,
  POWER_TREE,
  SIGN_OFF_TEMPLATE,
  DESIGN_TREE,
  SINGLE_TREE,
  CUSTOM_LIBRARY_FOLDER,
  SIGN_OFF_TEMPLATES,
  PCB_PRE_LAYOUT
} from '@/constants/treeConstants';
import {
  createProject,
  updateProjectMenu,
  updateSelectKeys,
  clearCurrentProject,
  updateExpand,
  openProject,
  closeProject,
  openPage,
  cancelCreateVerification,
  createVerification,
  changeRenameStatus,
  renameVerification,
  renameProject,
  updateComponentSettingVisible,
  updateRootPanelVisible,
  libraryDefault,
  copyVerification,
  renamePreLayout
} from '../../store/project/action';
import { updateSystemLibrary, updateCustomLibrary, setPMICPartVisible, updatePinMapList } from '../../store/library/action'
import { needUpdateCompSetting, updateCompRLCPrefix, updatePackage } from '../../store/Impedance/action';
import { updateIRComponentSetting, updateCompRLCPrefix as updateIRCompPrefix } from '../../store/IRExplorer/action';
import {
  closeTabFooter
} from '../../../MonitorStore/action';
import { updateTreeCompRLCPrefix } from '../../store/DesignTree/action';
import { saveRootInfo } from '../../store/PowerTree/action';
import { CASCADE } from '../../../../constants/pageType'
import CascadeTreeForm from './cascadeTreeForm'
import CreateItem from '@/components/CreateItem';
import { renderTitle, getType } from '@/services/Cascade/helper/cascadeTreeRender'
import cascadeProjects from '@/services/Cascade/DB/cascadeProject';
import { filterKeys, updateKeys, updateTreeSelectKeys } from '@/services/helper/filterHelper';
import { strDelimited } from '@/services/helper/split';
import DelConfirm from '@/components/DelConfirm';
import { getPopMessage } from '@/services/helper/popMessage';
import { DESIGN_FAILED } from '@/constants/designCategory';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import ComponentsSetting from '@/components/ComponentsSetting';
import componentSetting from '@/services/Cascade/helper/compSettingHelper';
import { parseFileAndCreateSignOffTask, updateTemplateByCompSetting } from '../../store/SignOffTemplate/action';
import compTableHelper from '@/services/Cascade/helper/compTableHelper';
import compPinMap from '@/services/Cascade/helper/compPinMap';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import PinMapPanel from '../../libraryPanel/PinMapPanel';
import { PIN_MAP } from "@/constants/libraryConstants";
import { updateLibraryMenu } from '../../store/library/action';
import libraryConstructor from '@/services/Cascade/helper/libraryConstructor';
import { getSelectedSetupIDs, getMultiPortResPart, getAllCascadeComponents } from '@/services/Cascade/helper/setupData';
import projectDesigns from '@/services/helper/projectDesigns';
import RootPCBPanel from '../../Content/TopBar/rootPCBPanel';
import designConstructor from '../../../../services/helper/designConstructor';
import { getResBlob } from '@/services/helper/downloadHelper';
import FileSaver from 'file-saver';
import { getDefaultName } from '@/services/helper/setDefaultName';
import debounce from '@/services/helper/debounceFn';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { OVERVIEW } from '../../../../services/Cascade/Impedance';
import { downloadLibrary } from '../../../../services/api/library';
import { EXCEL, EXCEL_FORMAT, PDF_FORMAT } from '../../../../constants/fileType';
import TemplateChoosePanel from '../../SignOffTemplate/TemplateChoosePanel';
import PMICPart from '../../../../components/PMICPart';
import { getCascadePinMap, updateLibraryData, deleteLibrary, updateCascadePart, getCascadePartDelete } from '../../../../services/Cascade/library';
import { POWER_SWITCH } from '../../../../constants/componentType';
import { PMIC_PART, POWER_SWITCH_PART } from '../../../../constants/libraryConstants';

class CascadeTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delConfirmVisible: false,
      settingPCB: false,
      templateType: EXCEL,
      checkedPinMap: '',
      checkbox: false
    }
    this.uploadRef = createRef();
  }

  componentDidUpdate = (prevProps) => {
    const { openProjectId } = this.props;
    if ((!openProjectId && prevProps.openProjectId) || (openProjectId !== prevProps.openProjectId)) {
      this.setState({
        settingPCB: false
      })
      this.props.updateComponentSettingVisible(false)
    }
  }

  _addItem = (e, itemData) => {
    this.spanClick(e)
    this.props.actions.addItem(itemData)
  }

  spanClick = (e) => {
    e && e.stopPropagation && e.stopPropagation();
  }

  cancelCreateProject = () => {
    const { openProjectId, expandedKeys } = this.props;
    if (expandedKeys.find(key => key.match(new RegExp(`${PROJECT}-`)))) {
      this.props._updateProjectMenu({ openProjectId });
    } else {
      this.props._updateProjectMenu();
    }
  }

  projectCreate = (item) => {
    return <CreateItem
      itemData={item}
      createNodeId="cascade-check-project-icon"
      cancelNodeId="cascade-close-project-icon"
      createConfirm={this.props._createProject}
      projectList={cascadeProjects.getProjectValues()}
      cancelCreate={this.cancelCreateProject}
    />
  }

  projectRename = (item) => {
    const [dataType, id] = item.key.split('-');
    return <CreateItem
      itemData={item}
      createNodeId={`cascade-check-project-icon-${id}`}
      cancelNodeId={`cascade-close-project-icon-${id}`}
      createConfirm={(item) => this.props.renameProject(dataType, id, item.name)}
      projectList={cascadeProjects.getProjectValues()}
      cancelCreate={() => this.renameStatusChange(null, item, false)}
    />
  }

  verificationCreate = (item) => {
    const [dataType, id] = item.key.split('-');
    const list = CascadeChannels.getList(dataType, id);
    return <CreateItem
      itemData={item}
      createNodeId="cascade-check-verification-icon"
      cancelNodeId="cascade-close-verification-icon"
      createConfirm={(item) => this.props.createVerification(dataType, id, item.name)}
      projectList={list}
      cancelCreate={() => this.props.cancelCreateVerification(dataType, id)}
    />
  }

  verificationRename = (item) => {
    const { openProjectId } = this.props;
    const [dataType, id] = item.key.split('-');
    const list = CascadeChannels.getList(dataType, openProjectId);
    return <CreateItem
      itemData={item}
      createNodeId={`cascade-check-verification-icon-${id}`}
      cancelNodeId={`cascade-close-verification-icon-${id}`}
      createConfirm={(item) => this.props.renameVerification(dataType, id, item.name)}
      projectList={list}
      cancelCreate={() => this.renameStatusChange(null, item, false)}
    />
  }

  preLayoutRename = (item) => {
    const { openProjectId } = this.props;
    const [dataType, id] = item.key.split('-');
    const currentPCBs = projectDesigns.getAvailableDesigns(openProjectId);
    return <CreateItem
      itemData={item}
      createNodeId={`cascade-check-verification-icon-${id}`}
      cancelNodeId={`cascade-close-verification-icon-${id}`}
      createConfirm={(item) => this.props.renamePreLayout(dataType, id, item.name)}
      projectList={currentPCBs}
      cancelCreate={() => this.renameStatusChange(null, item, false)}
    />
  }

  changeSimulateAll = () => {
  }
  changeSimulateCheck = () => {
  }
  startSimulation = () => {
  }

  _deleteClick = (e, item) => {
    this.spanClick(e);
    this.delConfirmInfo = { ...item }
    this.setState({
      delConfirmVisible: true
    })
  }

  updateSettingPCB = (item, e) => {
    e && e.stopPropagation();
    const { id, name } = item;
    this.setState({
      settingPCB: !item ? item : { id, name }
    })
  }

  uploadTemplate = (e, data, item) => {
    this.spanClick(e)
    const type = item.type;
    this.setState({
      templateType: type
    }, () => {
      this.uploadClick(e, item)
    })
  }

  uploadClick = (e, item) => {
    e && e.stopPropagation();
    if (item.dataType === SIGN_OFF_TEMPLATES) {
      const ele = this.uploadRef.current;
      this.uploadItem = { ...item };
      if (!ele) return;
      ele.click();
    }
  }

  uploadChange = (e) => {
    const file = e.target.files[0];
    const number = file.webkitRelativePath.indexOf('/');
    const templateType = this.state.templateType;
    let fileName = file.webkitRelativePath.substring(0, number);
    fileName = fileName ? fileName : file.name;
    //get api to parse file and create sign-off task
    this.props._parseFileAndCreateSignOffTask({ fileName, file, uploadItem: this.uploadItem, templateType });
    this.uploadRef.current.value = '';
  }

  onSelect = (selectedKeys, { selected, node }) => {
    if (!selected) return;
    const { expandedKeys, layout, pcbLayout } = this.props;
    const keys = [...expandedKeys]
    const eventKey = node.key;
    const [key, id] = strDelimited(eventKey, "-");
    if (!key) return;
    if (node.dataRef.category === DESIGN_FAILED) { return }
    const { _keys, preExpand } = updateKeys(keys, eventKey, expandedKeys);
    this.onExpand(_keys, { expanded: !preExpand, node });
    const prevSelectedKeys = this.props.selectedKeys;
    const selecteds = updateTreeSelectKeys({
      prevSelectedKeys: [...prevSelectedKeys],
      key,
      eventKey,
      selected,
      layout,
      pcbLayout,
      productType: CASCADE
    });
    selecteds.length && this.props._updateSelectKeys(selecteds)
    const preSelected = prevSelectedKeys.includes(eventKey);
    if (!preSelected && [PCB, PACKAGE, PCB_PRE_LAYOUT, DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE].includes(key)) {
      this.props._openPage({ pageType: key, id });
    }
  }

  onExpand = (keys, { expanded, node }) => {
    const [key, id] = strDelimited(node.key, "-");
    if (expanded) {
      if (key.match(new RegExp(`${PROJECT}$`))) {
        keys = this.expandProject({ keys, id })
      }
      if ([SYS_LIBRARY_FOLDER].includes(key)) {
        const { dataRef } = node;
        if (!dataRef.load) {
          this.props.updateSystemLibrary(id, dataRef.path);
        }
      }
      if ([CUSTOM_LIBRARY_FOLDER].includes(key)) {
        const { dataRef } = node;
        if (!dataRef.load) {
          this.props.updateCustomLibrary(id, dataRef.path);
        }
      }
    } else {
      if (key.match(new RegExp(`${PROJECT}$`))) {
        const { openProjectId } = this.props;
        keys = keys.filter(key => !key.includes(openProjectId));
        this.props._closeProject();
      }
    }
    this.props._updateExpand(keys);
  }

  expandProject = ({ keys, id }) => {
    const { selectedKeys, openProjectId } = this.props
    keys = filterKeys(keys, PROJECT, id);
    if (selectedKeys.length > 0) {
      this.props._updateSelectKeys([]);
    };
    this.props._clearCurrentProject();
    this.props._closeTabFooter()
    this.props._openProject(id);
    keys = id !== openProjectId ? keys.filter(key => !key.includes(openProjectId)) : keys;
    return keys;
  }

  deleteConfirm = (e, data) => {
    this.spanClick(e);
    this.props.actions.delItem(data);
    this.cancelDel();
  }

  cancelDel = (e) => {
    this.spanClick(e);
    this.delConfirmInfo = null;
    this.setState({
      delConfirmVisible: false
    })
  }

  renameStatusChange = (e, item, status) => {
    e && e.stopPropagation();
    this.props.changeRenameStatus(item, status)
  }

  copyContent = (item) => {
    const { taskCopyName, taskCopyError } = this.state;
    return (
      <Fragment>
        <Input
          value={taskCopyName}
          className='cascade-copy-name'
          onChange={(e) => this.taskCopyNameChange(e, item)}
          ref={(input) => { this.taskCopyInputRef = input; }}
          onPressEnter={(e) => this.copyTask(e, e.target.value, item)}
          onClick={(e) => this.inputClick(e)}
          addonAfter={<Fragment>
            <CloseOutlined
              className='aurora-tree-create-icon'
              onClick={(e) => this.cancelCopyTask(e)} />
            <CheckOutlined
              className='aurora-tree-create-icon mg-r-8'
              onClick={(e) => this.copyTask(e, taskCopyName, item)} />
          </Fragment>}
        />
        {taskCopyError && <span className='cascade-copy-error' onClick={(e) => this.inputClick(e)}>{taskCopyError}</span>}
      </Fragment>
    );
  }

  taskCopyVisibleChange = (e, item) => {
    e.stopPropagation();
    const { openProjectId } = this.props;
    const list = CascadeChannels.getList(item.dataType, openProjectId);
    const defaultName = getDefaultName({ nameList: list, defaultKey: `${item.name}_Copy`, firstIndex: 1 });
    this.setState({
      taskCopyId: item.id,
      taskCopyName: defaultName
    }, () => {
      if (this.taskCopyInputRef) {
        const { input } = this.taskCopyInputRef;
        input.focus();
      }
    })
  }

  taskCopyNameChange = (e, item) => {
    e.stopPropagation();
    this.setState({
      taskCopyName: e.target.value,
      taskCopyError: null
    })
  }

  copyTask = (e, name, item) => {
    e.stopPropagation();
    debounce(() => {
      const error = checkNameFormat(name);
      if (error) {
        this.setState({
          taskCopyError: error
        });
        return;
      }
      const [dataType, id] = item.key.split('-');
      const list = CascadeChannels.getList(dataType, id);
      const exsitV = list.map(item => item.name);
      if (exsitV.includes(name)) {
        this.setState({
          taskCopyError: 'Task: ' + name + ' already exists!'
        });
        return;
      }
      this.setState({
        copyLoading: id,
        taskCopyId: null,
        taskCopyError: null
      });
      this.props.copyVerification(dataType, id, name);
      this.setState({
        copyLoading: null
      })
    }, 1000, true, 'cascadeTaskCopy')()
  }

  cancelCopyTask = (e) => {
    e.stopPropagation();
    this.setState({
      taskCopyId: null,
      taskCopyName: null,
      taskCopyError: null
    })
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  importExportFolder = (e, item) => {
    e.stopPropagation();
    this.props.actions.importExportData(item)
  }

  closeSettingPCB = () => {
    const { viewList, irDesignId, signOffDesignId } = this.props;
    const { settingPCB } = this.state;
    const { id } = settingPCB;
    if (viewList.includes(IR_EXPLORER) && id === irDesignId) {
      this.props.updateIRComponentSetting(true);
    } else if (viewList.includes(IMPEDANCE)) {
      this.props.needUpdateCompSetting(true)
    } else if (viewList.includes(SIGN_OFF_TEMPLATE) && id === signOffDesignId) {
      this.props.updateTemplateByCompSetting(true)
    }
  }

  getCurrentDesign = () => {
    const { viewList, dcrDesignId, irDesignId, impDesignId, signOffDesignId, treeDesignId, root } = this.props;
    const view = viewList.find(item => [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE].includes(item));
    switch (view) {
      case DCR:
        return dcrDesignId;
      case IR_EXPLORER:
        return irDesignId;
      case IMPEDANCE:
        return impDesignId;
      case SIGN_OFF_TEMPLATE:
        return signOffDesignId;
      case DESIGN_TREE:
      case SINGLE_TREE:
        return treeDesignId;
      case POWER_TREE:
        return root.pcbId || null;
      default: return null;
    }
  }

  updateComponentSettingPage = async (update) => {
    const { viewList } = this.props;
    const designId = this.getCurrentDesign();
    const version = await componentSetting.getVersion(designId);
    const designType = designConstructor.getDesignType(designId);
    const view = viewList.find(v => [IMPEDANCE, IR_EXPLORER, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].includes(v));
    switch (view) {
      case IMPEDANCE:
        if (designType === PACKAGE) {
          this.props.updatePackage(update)
        } else {
          this.props.updateCompRLCPrefix(update)
        }
        break;
      case IR_EXPLORER:
        this.props.updateIRCompPrefix({ version }, true)
        break;
      case POWER_TREE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        this.props.updateTreeCompRLCPrefix(version);
        break;
      case SIGN_OFF_TEMPLATE:
        this.props.updateTemplateByCompSetting(true);
        break;
      default: break;
    }
  }

  updateProjectMenu = () => {
    const { openProjectId } = this.props
    this.props._updateProjectMenu({ openProjectId })
  }

  downloadSignoffTemplate = (e) => {
    e && e.stopPropagation();
    const token = localStorage.getItem('token');
    let url = `/api/v3/Cascade/template/help?access_token=${token}`;
    let fileName = 'Cascade Sign-off Template Document.zip';
    getResBlob(url).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, fileName);
      } else {
        message.error('Download failed!')
      }
    })
  }

  downloadFile = (e, itemData) => {
    this.downloadLibraryInfo = { ...itemData }
    this.setState({
      downloadProgress: 0
    })

    this.Time = setInterval(() => {
      let progress = this.state.downloadProgress;
      progress += 4;
      if (progress > 98) {
        progress = 98;
      }
      this.setState({
        downloadProgress: progress
      })
    }, 800);

    downloadLibrary({ libraryId: itemData.id, productType: CASCADE }).then(res => {
      if (res) {
        clearInterval(this.Time);
        this.setState({
          downloadProgress: 100
        })
        const libraryNameArr = itemData.name ? itemData.name.split(".") : [];
        const libraryName = libraryNameArr.length === 1 ? libraryNameArr[0] : libraryNameArr.filter((item, index) => index < libraryNameArr.length - 1).join('_');
        const fileName = libraryName || "library";
        FileSaver.saveAs(res, `${fileName}.zip`);
      } else {
        message.error(`Download ${itemData.name} failed!`)
      }
      setTimeout(() => {
        this.downloadLibraryInfo = null;
        this.setState({
          downloadProgress: -1
        });
      }, 1000)
    });
  }

  searchAction = (e, item) => {
    e && e.stopPropagation();
    const dataType = item.dataType;
    this.setState({
      partType: dataType
    }, () => {
      this.props.setPMICPartVisible(true)
    })
  }

  openPartPanel = (boolean, pmicType, checkedPinMap = false) => {
    const partType = pmicType === POWER_SWITCH ? POWER_SWITCH_PART : PMIC_PART;
    this.setState({
      partType,
      checkedPinMap,
      checkbox: true
    })
    this.props.setPMICPartVisible(boolean)
  }

  selectPinMapFile = (e, libraryId) => {
    e && e.stopPropagation && e.stopPropagation();
    this.setState({
      checkedPinMap: !e || !e.target.checked ? '' : libraryId
    })
  }

  closePMICPart = () => {
    this.setState({
      checkbox: false
    })
    this.props.setPMICPartVisible(false);
    this.props.updatePinMapList();
  }

  render = () => {
    const { treeItems, expandedKeys, selectedKeys, openProjectId, powerTrees, libraryStatus, settingVisible, rootVisible, root, copyLoadingList, templateChooseOpen, partVisible } = this.props;
    const { delConfirmVisible, settingPCB, templateType, partType, checkedPinMap, checkbox } = this.state
    const type = this.delConfirmInfo ? getType(this.delConfirmInfo.dataType) : "";
    let errorMsg = this.delConfirmInfo ? getPopMessage(type, this.delConfirmInfo.dataType, CASCADE) : "";
    const pinMapList = libraryConstructor.getLibraryValues(PIN_MAP);
    const pcbId = this.getCurrentDesign()
    const currentPCBs = projectDesigns.getAvailableDesigns(openProjectId);
    return (
      <Fragment>
        <CascadeTreeForm
          treeItems={treeItems}
          expandedKeys={expandedKeys}
          onSelect={this.onSelect}
          onExpand={this.onExpand}
          selectedKeys={selectedKeys}
          updateProjectMenu={this.updateProjectMenu}
          renderTitle={(item) => renderTitle(item, this.actions(), { ...this.props })}
        />
        {delConfirmVisible ? <DelConfirm
          data={this.delConfirmInfo}
          deleteConfirm={this.deleteConfirm}
          cancelDel={this.cancelDel}
          message={errorMsg}
        /> : null}
        {
          settingPCB ? <ComponentsSetting
            pcbId={settingPCB.id}
            closeModal={() => this.updateSettingPCB(false)}
            settingStore={componentSetting}
            tableStore={compTableHelper}
            product={CASCADE}
            pcbName={settingPCB.name}
            projectId={openProjectId}
            updateSetting={this.closeSettingPCB}
            powerTrees={powerTrees}
            pinMapStore={compPinMap}
            getPinMap={getCascadePinMap}
            setPart={updateCascadePart}
            PinMapPanel={PinMapPanel}
            doNotStuffStore={componentDoNotStuff}
            updateLibraryMenu={this.props.updateLibraryMenu}
            libraryStatus={libraryStatus}
            getMultiPortResPart={getMultiPortResPart}
            getComponents={getAllCascadeComponents}
            pinMapList={pinMapList}
            partVisible={partVisible}
            checkedPinMap={checkedPinMap}
            checkbox={checkbox}
            openPartPanel={this.openPartPanel}
          /> : null
        }
        {
          settingVisible ? <ComponentsSetting
            pcbId={pcbId}
            closeModal={() => this.props.updateComponentSettingVisible(false)}
            settingStore={componentSetting}
            tableStore={compTableHelper}
            product={CASCADE}
            designList={currentPCBs}
            projectId={openProjectId}
            updateSetting={this.updateComponentSettingPage}
            powerTrees={powerTrees}
            pinMapStore={compPinMap}
            getPinMap={getCascadePinMap}
            setPart={updateCascadePart}
            PinMapPanel={PinMapPanel}
            doNotStuffStore={componentDoNotStuff}
            updateLibraryMenu={this.props.updateLibraryMenu}
            libraryStatus={libraryStatus}
            getMultiPortResPart={getMultiPortResPart}
            getComponents={getAllCascadeComponents}
            pinMapList={pinMapList}
            partVisible={partVisible}
            checkedPinMap={checkedPinMap}
            checkbox={checkbox}
            openPartPanel={this.openPartPanel}
          /> : null
        }
        {
          rootVisible ? <RootPCBPanel
            closeModal={() => this.props.updateRootPanelVisible(false)}
            designList={currentPCBs}
            pinMapList={pinMapList}
            updateLibraryMenu={this.props.updateLibraryMenu}
            saveRootInfo={this.props.saveRootInfo}
            getCascadePinMap={getCascadePinMap}
            root={root}
            checkedPinMap={checkedPinMap}
            checkbox={checkbox}
            openPartPanel={this.openPartPanel}
          /> : null
        }
        {
          partVisible ? <PMICPart
            close={() => this.closePMICPart()}
            selectPinMapFile={this.selectPinMapFile}
            partVisible={partVisible}
            partType={partType}
            checkedPinMap={checkedPinMap}
            checkbox={checkbox}
            componentSetting={componentSetting}
            actions={{
              getPinMap: getCascadePinMap,
              updateLibraryData: updateLibraryData,
              deleteLibrary: deleteLibrary,
              deletePart: getCascadePartDelete
            }}
          /> : null
        }
        {
          templateChooseOpen ? <TemplateChoosePanel /> : null
        }
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={templateType === EXCEL ? EXCEL_FORMAT : PDF_FORMAT}
          onChange={(e) => this.uploadChange(e)}
        /* onClick={(e) => this.fileClick(e)} */
        />
      </Fragment>
    )
  }
  actions = () => {
    return {
      _addItem: this._addItem,
      _deleteClick: this._deleteClick,
      delItem: this.props.actions.delItem,
      editItem: this.props.actions.editItem,
      projectCreate: this.projectCreate,
      spanClick: this.spanClick,
      changeSimulateAll: this.changeSimulateAll,
      changeSimulateCheck: this.changeSimulateCheck,
      copyContent: this.copyContent,
      inputClick: this.inputClick,
      taskCopyVisibleChange: this.taskCopyVisibleChange,
      startSimulation: this.startSimulation,
      verificationCreate: this.verificationCreate,
      renameStatusChange: this.renameStatusChange,
      importExportFolder: this.importExportFolder,
      verificationRename: this.verificationRename,
      preLayoutRename: this.preLayoutRename,
      projectRename: this.projectRename,
      updateSettingPCB: this.updateSettingPCB,
      changeDecap: this.props.actions.changeDecap,
      uploadClick: this.uploadClick,
      searchClick: this.props.actions.searchClick,
      downloadSignoffTemplate: this.downloadSignoffTemplate,
      taskCopyId: this.state.taskCopyId,
      showImportExportModel: this.props.actions.showImportExportModel,
      downloadFile: this.downloadFile,
      uploadTemplate: this.uploadTemplate,
      searchAction: this.searchAction
    }
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { treeItems, expandedKeys, selectedKeys, openProjectId, layout, viewList, defaultDecap, pcbLayout, settingVisible, rootVisible, copyLoadingList },
    IR: { designId: irDesignId },
    Impedance: { designId: impDesignId, page },
    library: { DecapList, libraryStatus, partVisible, selectedPinMap, checkbox },
    SignOffTemplate: { designId: signOffDesignId, templatePDFPanel: { open: templateChooseOpen } },
    DesignTree: { powerTrees, designId: treeDesignId },
    DCR: { designId: dcrDesignId },
    PowerTree: { root }
  }, LayoutReducer: { simulationDesigns } } = state
  return {
    treeItems,
    expandedKeys,
    selectedKeys,
    openProjectId,
    layout,
    pcbLayout,
    viewList,
    irDesignId,
    impDesignId: page !== OVERVIEW ? page : impDesignId,
    DecapList,
    defaultDecap,
    selectedIds: getSelectedSetupIDs(selectedKeys),
    libraryStatus,
    signOffDesignId,
    powerTrees,
    settingVisible,
    dcrDesignId,
    root,
    treeDesignId,
    rootVisible,
    simulationDesigns,
    copyLoadingList,
    templateChooseOpen,
    partVisible,
    selectedPinMap,
    checkbox
  }
}
const mapDispatch = (dispatch) => ({
  _createProject(data) {
    dispatch(createProject(data))
  },
  _updateProjectMenu(data) {
    dispatch(updateProjectMenu(data))
  },
  _updateSelectKeys(selectedKeys) {
    dispatch(updateSelectKeys(selectedKeys))
  },
  _clearCurrentProject() {
    dispatch(clearCurrentProject())
  },
  _updateExpand(expandedKeys) {
    dispatch(updateExpand(expandedKeys))
  },
  _openProject(id) {
    dispatch(openProject(id))
  },
  _closeProject() {
    dispatch(closeProject())
  },
  _openPage({ pageType, id }) {
    dispatch(openPage({ pageType, id }))
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  },
  updateSystemLibrary(id, path) {
    dispatch(updateSystemLibrary(id, path))
  },
  updateCustomLibrary(id, path) {
    dispatch(updateCustomLibrary(id, path))
  },
  cancelCreateVerification(type, id) {
    dispatch(cancelCreateVerification(type, id))
  },
  createVerification(type, id, name) {
    dispatch(createVerification(type, id, name))
  },
  changeRenameStatus(data, status) {
    dispatch(changeRenameStatus(data, status))
  },
  renameVerification(dataType, id, name) {
    dispatch(renameVerification(dataType, id, name))
  },
  copyVerification(dataType, id, name) {
    dispatch(copyVerification(dataType, id, name))
  },
  renameProject(dataType, id, name) {
    dispatch(renameProject(dataType, id, name))
  },
  needUpdateCompSetting(update) {
    dispatch(needUpdateCompSetting(update))
  },
  updateIRComponentSetting(update) {
    dispatch(updateIRComponentSetting(update))
  },
  updateDefaultLibrary(libraryId, libraryType, name) {
    dispatch(libraryDefault(libraryId, libraryType, name));
  },
  delDefaultLibrary() {
    dispatch(libraryDefault())
  },
  _parseFileAndCreateSignOffTask(params) {
    dispatch(parseFileAndCreateSignOffTask(params))
  },
  updateLibraryMenu() {
    dispatch(updateLibraryMenu())
  },
  updateTemplateByCompSetting(update) {
    dispatch(updateTemplateByCompSetting(update))
  },
  updateComponentSettingVisible(boolean) {
    dispatch(updateComponentSettingVisible(boolean))
  },
  updateCompRLCPrefix(update) {
    dispatch(updateCompRLCPrefix(update))
  },
  updateIRCompPrefix(COMP_PREFIX_LIB, update) {
    dispatch(updateIRCompPrefix(COMP_PREFIX_LIB, update))
  },
  updateTreeCompRLCPrefix(version) {
    dispatch(updateTreeCompRLCPrefix(version))
  },
  saveRootInfo(root, refresh) {
    dispatch(saveRootInfo(root, refresh))
  },
  updateRootPanelVisible(boolean) {
    dispatch(updateRootPanelVisible(boolean))
  },
  updatePackage(update) {
    dispatch(updatePackage(update))
  },
  renamePreLayout(dataType, id, name) {
    dispatch(renamePreLayout(dataType, id, name))
  },
  setPMICPartVisible(visible) {
    dispatch(setPMICPartVisible(visible))
  },
  updatePinMapList() {
    dispatch(updatePinMapList())
  }
})

export default connect(mapState, mapDispatch)(CascadeTree)