import React, { Component, Fragment } from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Table from '@/components/EditableTable';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { numberCheck } from "@/services/helper/dataProcess";
import { TARGET_TABLE } from '@/services/Cascade/constants';
import { TargetNameEdit } from './TargetNameEdit';
import { unitChange } from '@/services/helper/mathHelper';
import { getImpTargetName, updateTargetFrequency } from '@/services/Cascade/Impedance';
import '../index.css';

const targetColumns = [
  {
    title: 'Frequency (MHz)',
    dataIndex: 'frequency',
    width: '50%'
  }, {
    title: 'Impedance (mΩ)',
    dataIndex: 'impedance',
    width: '50%'
  }
]


class FrequencyTable extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.initColumns();
    this.initState();
  }

  componentDidUpdate = (prevProps) => {
    const { targetIndex } = this.props;
    if (targetIndex !== prevProps.targetIndex) {
      this.initState()
    }
  }

  inputRef = (ref) => {
    this.targetNameRef = ref;
  }

  initState = () => {
    const { record, target, targetIndex, targetType, PowerNets, maxFreq } = this.props;
    const currentTarget = target[targetIndex];
    const name = getImpTargetName({
      targetName: currentTarget ? currentTarget.targetName : "",
      record,
      targetType,
      PowerNets,
    });
    const frequencyPoints = currentTarget && currentTarget.targetType === TARGET_TABLE ? currentTarget.frequencyPoints || [] : []
    this.setState({
      nameStatus: false,
      targetName: name,
      tempName: name,
      error: null,
      save: false,
      frequencyPoints: frequencyPoints.map((item, index) => ({
        impedance: unitChange({ num: item.impedance, oldUnit: 'Ω', newUnit: 'mΩ' }).number,
        frequency: unitChange({ num: item.frequency, oldUnit: 'Hz', newUnit: 'MHz' }).number,
        index
      }))
    }, () => {
      let points = this.getFrequencyPoints();
      points = updateTargetFrequency(points, maxFreq);
      this.props.savePoints(points);
    })
  }

  initColumns = () => {

    targetColumns[0].render = (text) => {
      return <div>
        <span>{text}</span>
      </div>
    }

    targetColumns[0].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'frequency',
        handleSave: (row) => this.saveValue(row, 'frequency')
      }
    }

    targetColumns[1].render = (text, record) => {
      return (
        <div>
          <span>{text}</span>
          <CloseOutlined
            className="imp-target-delete-icon"
            onClick={(e) => this.deleteRow(e, record)} />
        </div>
      );
    }

    targetColumns[1].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'impedance',
        handleSave: (row) => this.saveValue(row, 'impedance')
      }
    }
  }

  changeName = (e, status) => {
    e.stopPropagation();
    this.setState({
      nameStatus: status,
      error: null
    }, () => {
      if (status) {
        this.targetNameRef.focus();
      }
    })
  }

  saveTempName = (e) => {
    this.setState({
      tempName: e.target.value
    })
  }

  saveNewTargetName = (e) => {
    e.stopPropagation();
    const { tempName } = this.state;
    const error = checkNameFormat(tempName);
    if (error) {
      this.setState({
        error
      })
    } else {
      this.setState({
        targetName: tempName,
        nameStatus: false,
        error: null,
        save: true
      })
      const { target, targetIndex } = this.props;
      if (target[targetIndex]) {
        const targetNames = target.filter(item => item.targetName);
        let _tempName = tempName
        while (targetNames.includes(_tempName)) {
          _tempName = `${_tempName}_1`
        }
        const _target = { ...target[targetIndex], targetName: _tempName };
        this.props.updateTarget(_target, targetIndex);
      }
    }
  }

  saveValue = (row, key) => {
    const currentIndex = row.index;
    const value = row[key];
    const { frequencyPoints } = this.state;
    let _points = JSON.parse(JSON.stringify(frequencyPoints));
    _points.find(point => point.index === currentIndex)[key] = value;
    this.setState({
      frequencyPoints: _points,
      save: true
    }, () => { this.props.refreshPlot() })
  }

  addTableRow = (e) => {
    const { frequencyPoints } = this.state;
    let newIndex = frequencyPoints.length ? frequencyPoints[frequencyPoints.length - 1].index + 1 : 0;
    this.setState({
      frequencyPoints: [...frequencyPoints, { frequency: '', impedance: '', index: newIndex }],
      save: true
    }, () => { this.props.refreshPlot() })
  }

  deleteRow = (e, record) => {
    e.stopPropagation();
    const { index } = record;
    const { frequencyPoints } = this.state;
    let newPoints = frequencyPoints.filter(point => point.index !== index);
    this.setState({
      frequencyPoints: newPoints,
      save: true
    }, () => { this.props.refreshPlot() })
  }

  saveTarget = (prevType, closePanel) => {
    const { powerDomainId, targetIndex } = this.props;
    const { targetName, save } = this.state;

    let newPoints = this.getFrequencyPoints('Error');

    if (newPoints === 'Error') {
      return false;
    }
    if (newPoints.length) {
      let target = {
        frequencyPoints: JSON.parse(JSON.stringify(newPoints)),
        powerDomainId,
        targetName,
        targetType: TARGET_TABLE
      }
      if (prevType !== TARGET_TABLE || save) {
        this.props.updateTarget(target, targetIndex, closePanel);
      } else if(closePanel) {
        this.props.filterEmptyTarget()
      }
    } else {
      let target = {
        frequencyPoints: [],
        powerDomainId,
        targetName,
        targetType: TARGET_TABLE
      }
      this.props.updateTarget(target, targetIndex, closePanel);
    }
    return true;
  }

  getFrequencyPoints = (errorReturn = []) => {
    const { frequencyPoints = [] } = this.state;
    let newPoints = [];
    for (let point of frequencyPoints) {
      const { frequency, impedance } = point;
      const checkArray = [{ msg: 'Frequency must be a number.', value: frequency }, { msg: 'Impedance must be a number.', value: impedance }]
      for (let check of checkArray) {
        let error = numberCheck(check.value);
        if (error) {
          errorReturn === 'Error' && this.setState({ error: check.msg });
          return errorReturn;
        }
      }
      newPoints.push({
        impedance: unitChange({ num: impedance, oldUnit: 'mΩ', newUnit: 'Ω' }).number,
        frequency: unitChange({ num: frequency, oldUnit: 'MHz', newUnit: 'Hz' }).number
      })
    }
    return newPoints;
  }

  render() {
    const { targetName, nameStatus, error, frequencyPoints } = this.state;

    return (
      <Fragment>
        <div className='imp-target-frequency cascade-setup-border'>
          {TargetNameEdit({
            targetName,
            nameStatus,
            changeName: this.changeName,
            saveTempName: this.saveTempName,
            saveNewTargetName: this.saveNewTargetName,
            onRef: this.inputRef,
            maxWidth: '88%',
            extraDom: <PlusCircleOutlined
              title="Add table row"
              className='cascade-imp-icon'
              style={{ marginTop: -2, marginLeft: 30 }}
              onClick={(e) => this.addTableRow(e)} />
          })}
          <div className="imp-target-frequency-table">
            <Table
              rowKey={(record) => record.index}
              columns={targetColumns}
              size="small"
              dataSource={frequencyPoints}
              tablePadding={true}
            />
          </div>
        </div>
        {error && <span className='imp-target-name-error-msg'>{error}</span>}
      </Fragment>
    );
  }
}

export default FrequencyTable;