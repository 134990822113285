import http from './http';
import JSZip from 'jszip';
import axios from 'axios';
/* import unzipFile from '../helper/unzip';
import JSZipUtils from 'jszip-utils'; */
import { getResBlob } from '../helper/downloadHelper';

export function deleteLibModels({ projectId, filesName }) {
  return http.delete(`/project/${projectId}/library`,
    {
      params: {
        libFileNames: filesName.toString()
      }
    }
  )
}

// -----------------------------------------------------------
// Aurora v3 Api  2019.08.02
export function uploadLibrary({ projectId, files, fileType }) {
  let formData = new FormData();
  let zip = new JSZip();
  let fileNames = [];
  for (const file of files) {
    zip.file(file.name, file);
    fileNames.push(file.name);
  }

  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level: 1 }
  }).then(blob => {
    formData.append('file', new File([blob], 'file.zip'));
    formData.append('names', fileNames);
    formData.append('type', fileType);
    return http.post(`/project/${projectId}/library/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  })
}

/** 
 * get spice Template content
*/
export function getTemplateSpiceFile() {
  return new Promise((resolve, reject) => {
    axios.get('/io_model_template.txt').then(res => {
      resolve(res.data);
    }, error => {
      resolve(null)
    });
  });
};

/* function _downloadLibrary({ productType, libraryId }) {
  const token = localStorage.getItem('token');
  return JSZipUtils.getBinaryContent(`/api/v3/library/${productType}/download?libraryId=${libraryId}&access_token=${token}`);
} */

export function downloadLibrary({ productType, libraryId }) {
  return new Promise((resolve, reject) => {
    //zip
    const token = localStorage.getItem('token');
    getResBlob(`/api/v3/library/${productType}/download?libraryId=${libraryId}&access_token=${token}`).then(blob => {
      resolve(blob);
    }, error => {
      resolve(null);
    });
    //un zip
    /*  } else {
       _downloadLibrary({ productType, libraryId }).then(res => {
         unzipFile(res, "blob").then(response => {
           const keys = Object.keys(response);
           if (keys && keys.length) {
             resolve(response[keys[0]]);
           } else {
             resolve(null)
           }
         }, error => {
           resolve(null);
         })
       })
     } */
  })
}