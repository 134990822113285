import React from 'react';
import { Checkbox } from "antd";

const CHECK_BOX_ALL = "all";
function getInterfaceCheckBox({
  record,
  checkInterfaces,
  selectedInterfaces = []
}) {
  return <div className='interface-table-checkBox'>
    <Checkbox
      checked={selectedInterfaces.includes(record.verificationId)}
      onChange={(e) => checkInterfaces(e, record.verificationId)}
    />
  </div>
}

function getInterfaceTitleCheckBox({
  selectedInterfaces,
  checkInterfaces,
  verificationIds
}) {
  const { checked, indeterminate } = getInterfaceCheckedStatus(selectedInterfaces, verificationIds);
  return <div className='interface-table-checkBox'>
    <Checkbox
      indeterminate={indeterminate}
      checked={checked}
      onChange={(e) => checkInterfaces(e, CHECK_BOX_ALL)}
    />
  </div>
}

function getInterfaceCheckedStatus(selectedInterfaces, verificationIds) {
  let checked = false, indeterminate = false;

  if (!selectedInterfaces || selectedInterfaces.length === 0 || !verificationIds) {
    return { checked, indeterminate };
  }

  if (verificationIds.length === selectedInterfaces.length) {
    checked = true;
    indeterminate = false;
  } else {
    checked = false;
    indeterminate = true;
  }
  return { checked, indeterminate };
}

export {
  getInterfaceTitleCheckBox,
  getInterfaceCheckBox
}