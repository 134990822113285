import React, { Component, Fragment } from 'react';
import { Input, Select } from 'antd';
import { formatNumber } from '@/services/helper/numberHelper';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import './index.css';


const { Option } = Select;
const { TextArea } = Input;

const unit = ['us', 'ns', 'ps'];

const DELAY = 'delay', RISR_TIME = 'riseTime', FALL_TIME = 'fallTime', PERIOD = 'period', PATTERN = 'pattern', VOLTAGE = 'voltage';

const KeyArr = [
  { title: 'Delay', key: DELAY },
  { title: 'Rise Time', key: RISR_TIME },
  { title: 'Fall Time', key: FALL_TIME },
  { title: 'Period', key: PERIOD },
  { title: 'Pattern', key: PATTERN },
  { title: 'Voltage', key: VOLTAGE }
];

class VectorEditForm extends Component {

  constructor(props) {
    super(props);
    const { unit } = this.props;
    const defaultUnit = unit ? unit : 'ns';
    this.state = {
      vectorData: {
        delay: { value: '', unit: defaultUnit },
        riseTime: { value: '', unit: defaultUnit },
        fallTime: { value: '', unit: defaultUnit },
        period: { value: '', unit: defaultUnit },
        pattern: { value: '' },
        voltage: { value: '' },
        timeUnit: ''
      },
      defaultUnit,
      name: null,
      errorMsg: null
    }
  }

  selectAfter = (key) => {
    const { defaultUnit, vectorData } = this.state;
    let _vectorData = { ...vectorData };
    return (
      <Select dropdownStyle={{ zIndex: 100000 }} value={_vectorData[key] ? _vectorData[key].unit : defaultUnit} onChange={(unit) => this.changeUnit(unit, key)}>
        {unit.map(item =>
          <Option key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  changeUnit = (unit, key) => {
    let vectorData = { ...this.state.vectorData };
    vectorData[key].unit = unit;
    this.setState({
      vectorData,
      edit: true
    }, () => {
      this.checkData(key, true);
    })
  }

  saveValue = (e, key) => {
    let vectorData = { ...this.state.vectorData };
    vectorData[key].value = e.target.value;
    this.setState({
      vectorData,
      errorMsg: null,
      edit: true
    })
  };

  changeName = (e) => {
    this.setState({
      name: e.target.value,
      errorMsg: null,
      edit: true
    })
  }

  // When close Panel, parent component will transfer it.
  saveData() {
    let formatData = {}, editCount = 0, error = false;
    const { exist, vectorData, edit } = this.state;
    const name = this.checkData('name');
    if (!name) {
      return { action: 'stop' };
    }
    for (let item of [VOLTAGE, PATTERN, PERIOD, FALL_TIME, RISR_TIME, DELAY]) {
      const value = this.checkData(item);
      if (value) {
        formatData[item] = value;
      } else {
        error = true
      }
      if (vectorData[item].value.length > 0) {
        editCount++;
      }
    }

    //editCount ===0 ==> no data
    //edit  ==> modified
    //exist ==> create
    if (editCount === 0 || !edit || (!exist && !edit)) {
      this.setState({
        errorMsg: null
      })
      return { action: 'close' }
    } else if (editCount < 6 || error) {
      return { action: 'stop' }
    } else {
      formatData.timeUnit = '';
      return { action: 'save', formatData, name }
    }
  }

  // isUnit is if the fn is caused by change unit, it will clear errorMsg if the value is correct
  checkData = (key, isUnit) => {
    let result;
    switch (key) {
      case 'name':
        result = this.checkName();
        break;
      case PATTERN:
        result = this.checkPattern();
        break;
      case VOLTAGE:
        result = this.checkVoltage();
        break;
      default: result = this.checkOther(key);
    }
    const { errorMsg, value } = result;
    if (errorMsg) {
      let editCount = 0;
      for (let item of [VOLTAGE, PATTERN, PERIOD, FALL_TIME, RISR_TIME, DELAY]) {
        if (this.state.vectorData[item].value.length > 0) {
          editCount++;
        }
      }
      this.setState({
        errorMsg: editCount !== 0 || key === 'name' ? errorMsg : null
      })
    } else {
      if (isUnit) {
        this.setState({
          errorMsg: null
        })
      }
      return value;
    }
  }

  checkName() {
    const { name } = this.state;
    const { vectorList, id } = this.props;
    const find = vectorList.find(item => item.name === name && item.id !== id);
    const errorMsg = checkNameFormat(name);
    if (errorMsg) {
      return { errorMsg: `Vector model ${errorMsg.toLowerCase()}` };
    } else if (find) {
      return { errorMsg: 'Vector model name cannot be repeated.' };
    } else {
      return { value: name };
    }
  }

  checkPattern() {
    const { pattern: { value } } = this.state.vectorData;
    if (value) {
      const strReg = value.match(/(1|0){1,}/);
      if (strReg && strReg[0] === value) {
        return { value: strReg[0] }
      } else {
        return { errorMsg: 'Pattern value must be a binary.' };
      }
    } else {
      return { errorMsg: 'Pattern value cannot be empty.' };
    }
  }

  checkVoltage() {
    const { voltage: { value } } = this.state.vectorData;
    if (value) {
      if (/[^0-9e\.\+\-]/i.test(value)) {
        return { errorMsg: 'Voltage value must be a number.' };
      } else {
        const strReg = value.match(/([+-]?\d+\.?\d*)(e[+-]?\d+\.?\d*)?/i);
        if (strReg && strReg[0] === value) {
          let value = formatNumber(strReg[0]);
          this.setState((prevState) => {
            let _data = { ...prevState.vectorData };
            _data.voltage.value = value;
            return {
              vectorData: _data
            }
          })
          if (value >= 0) {
            return { value };
          } else {
            return { errorMsg: 'Voltage value must greater than 0.' };
          }
        } else {
          return { errorMsg: 'Voltage value must be a number.' };
        }
      }
    } else {
      return { errorMsg: 'Voltage value cannot be empty.' };
    }
  }

  checkOther(key) {
    const { value, unit } = this.state.vectorData[key];
    const title = KeyArr.find(item => item.key === key).title;
    if (value) {
      if (/[^0-9e\.\+\-]/i.test(value)) {
        return { errorMsg: `${title} value must be a number.` };
      } else {
        const strReg = value.match(/([+-]?\d+\.?\d*)(e[+-]?\d+\.?\d*)?/i);
        if (strReg && strReg[0] === value) {
          let value = formatNumber(strReg[0]);
          this.setState((prevState) => {
            let _data = { ...prevState.vectorData };
            _data[key].value = value;
            return {
              vectorData: _data
            }
          })
          if ((unit === 'ps' && value < 0.01) || (unit === 'ns' && value * 1000 < 0.01) || (unit === 'us' && value * 1000000 < 0.01)) {
            return { errorMsg: `${title} value must greater than 0.01ps.` };
          } else {
            return { value: value + unit };
          }
        } else {
          return { errorMsg: `${title} value must be a number.` };
        }
      }
    } else {
      return { errorMsg: `${title} value cannot be empty.` };
    }
  }

  componentDidMount() {
    this.getVectorData(this.props.id);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) {
      this.getVectorData(id);
    }
  }

  getVectorData(id) {
    const { defaultVectorName, name, getEditData } = this.props;
    const { defaultUnit } = this.state;
    if (id) {
      getEditData(id).then(res => {
        let exist = true, resData = {}, data = {}, formatData = {};
        if (res) {
          if (res.content && Object.keys(res.content).length > 0) {
            resData = res.content;
          } else if (Object.keys(res).length > 0) {
            resData = res;
          } else {
            exist = false;
          }
          const arr = [DELAY, RISR_TIME, FALL_TIME, PERIOD, PATTERN, VOLTAGE];
          arr.forEach(key => {
            let value = resData[key];
            if (!value) {
              data[key] = { value: '', unit: defaultUnit };
              formatData[key] = '';
            } else {
              const unitReg = value.match(/\D{1,}$/i);
              const unitResult = unitReg ? unitReg[0].toLowerCase() : null;
              if (unit.includes(unitResult)) {
                data[key] = { value: value.slice(0, -unitResult.length), unit: unitResult };
              } else {
                data[key] = { value };
              }
            }
          });
          data.timeUnit = '';
          this.setState({
            vectorData: data,
            name: res.name,
            exist
          })
        }
        if (!exist) {
          this.setState({
            name: name,
            exist
          })
        }
      });
    } else {
      this.setState({
        name: defaultVectorName,
        exist: false
      })
    }
  }

  getInputAfter = (key) => {
    if (key === PATTERN) {
      return null;
    } else if (key === VOLTAGE) {
      return 'V';
    } else {
      return this.selectAfter(key);
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  render() {
    const { name, vectorData, errorMsg } = this.state;

    return <Fragment>
      <span className='vector-edit-value-span'>Name</span>
      <Input className='vector-edit-input' placeholder='Name' value={name} onChange={(e) => this.changeName(e)} onBlur={(e) => this.checkData('name')} />
      {KeyArr.map(item => <Fragment key={item.key}>
        <span className='vector-edit-value-span'>{item.title}</span>
        {item.title === 'Pattern' ?
          <TextArea
            autoSize
            key={item.key}
            className='vector-edit-value-input'
            value={vectorData[item.key] ? vectorData[item.key].value : null}
            placeholder={item.title}
            onChange={(e) => this.saveValue(e, item.key)}
            onKeyDown={(e) => this.keyDown(e)}
            onBlur={(e) => this.checkData(item.key)}
          />
          : <Input
            key={item.key}
            className='vector-edit-value-input'
            value={vectorData[item.key] ? vectorData[item.key].value : null}
            addonAfter={this.getInputAfter(item.key)}
            placeholder={item.title}
            onChange={(e) => this.saveValue(e, item.key)}
            onKeyDown={(e) => this.keyDown(e)}
            onBlur={(e) => this.checkData(item.key)}
          />}

      </Fragment>)}
      {errorMsg && <span className='vector-edit-name-error-msg'>{errorMsg}</span>}
    </Fragment>
  }
}

export default VectorEditForm;