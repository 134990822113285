
import { USER_LOGOUT } from '../../../Login/actionTypes';
import {
  UPDATE_MULTI_SIMULATION_INFO,
  UPDATE_SIMULATION_INFO,
  UPDATE_SIMULATION_REDUCER,
  UPDATE_SIM_SELECT_KEYS
} from './actionTypes';

const defaultState = {
  endToEndSimSelectKeys: [],
  pcbChannelSimSelectKeys: [],
  designStackupErrors: null
}

export const AndesSimulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SIMULATION_REDUCER:
      return {
        ...action.simulation
      }
    case UPDATE_SIMULATION_INFO:
      return {
        ...state,
        [action.verificationId]: state[action.verificationId] ?
          {
            ...state[action.verificationId],
            [action.item]: action.info
          }
          : { [action.item]: action.info }
      }
    case UPDATE_MULTI_SIMULATION_INFO:
      return {
        ...state,
        [action.verificationId]: state[action.verificationId] ? {
          ...state[action.verificationId],
          ...action.info
        }
          : { ...action.info }
      }
    case UPDATE_SIM_SELECT_KEYS:
      return {
        ...state,
        [`${action.simType}SimSelectKeys`]: [...action.keys]
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default: return state;
  }
}