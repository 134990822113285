export const UPLOAD_SNP_FILE = 'PDN/upload_snp_file';
export const CHANGE_RESULT_UPLOAD_PROGRESS = 'PDN/change_result_upload_progress';
export const CHANGE_FILE_STATUS = 'PDN/change_file_status';
export const CHANGE_RESULT_TYPE = 'PDN/change_result_type';
export const GET_DECAP_FILE_VALUE = 'PDN/get_result_decap_file_value'
export const GET_DECAP_INFO_SUCCESS = 'PDN/get_result_decap_info_success';
export const GET_DC_VALUE = 'PDN/get_dc_value';
export const SAVE_DC_VALUE = 'PDN/save_dc_value';
export const UPDATE_DC_SELECTED_KEY = 'PDN/update_dc_selected_key';
export const GET_DC_INFO_SUCCESS = 'PDN/get_dc_info_success';
export const CHANGE_DC_RESULT_TYPE = 'PDN/change_dc_result_type';
export const UPDATE_DC_RESULT_TABLE_LOADING = 'PDN/update_dc-result-table_loading';
export const UPDATE_DC_RESULT_STATUS = 'PDN/update_dc_result_status';
export const CLEAR_DC_RESULT_DATA = 'PDN/clear_dc_result_data'