import { CLKTYPE, CUSTOM, I2CI3C, I2S, SPIQSPIeSPI, SPMI, UART, GENERIC } from "../../PCBHelper/constants";
import { COMP_REPEATER, CONNECTOR, IC, RES, TEST_POINT } from "../../../constants/componentType";

const MODEL = 'Model', SCHEMATIC = "Schematic";
const prelayoutList = [MODEL, SCHEMATIC];
const GPIOList = [I2CI3C, GENERIC];

const PRE_LAYOUT_DESIGN_VERSION = 1;

const componentList = [
  { title: 'Number of IC Components', key: 'IC' },
  { title: 'Number of Connectors', key: 'connector' },
  { title: 'Pairs of Pull Up Resistors', key: 'pullUpRes' },
  { title: 'Number of Repeaters', key: 'repeater' },
  // {title: 'Number of Test Points', key: 'testPoint'},
]

const schematicComponentList = [
/*   { title: 'Number of IC Components', key: 'IC' },
  { title: 'Number of Connectors', key: 'connector' },
  { title: 'Pairs of Pull Up Resistors', key: 'pullUpRes' } */
]

const PRE_IC = 'U', PRE_CONNECTOR = 'J', PRE_RES = 'R', PRE_REPEATER = 'UP', PRE_TEST = 'TP';
const IC_INDEX = 0, RES_INDEX = 1, TEST_INDEX = 2, REPEATER_INDEX = 3, CONNECTOR_INDEX = 4;

const netList = {
  [I2CI3C]: ['SCL', 'SDA']
}

const genericSignals = (num) => {
  let signals = [];
  for (let i = 1; i <= Number(num); i++) {
    signals.push(`Signal${i}`)
  }
  return signals
}

const SIGNAL_DISTANCE = 50, CANVAS_PADDING = 40, SIGNAL_TO_COMP = 50, COMP_HEIGHT = 100,
  COMP_PADDING = 50, RES_WIDTH = 100, SIGNAL_COMP_WIDTH = 80, REPEATER_WIDTH = 200, TEST_WIDTH = 150,
  PORT_DISTANCE = 8, GROUP_RES_DISTANCE = 20;

const IC_COLOR = "#6495ed", CONNECTOR_COLOR = "#9998ff", RES_COLOR = "#f4b183", REPEATER_COLOR = "#b15432", TEST_COLOR = "#707070";
  function getCanvasCompColor(type) {
    switch (type) {
      case RES:
        return RES_COLOR;
      case TEST_POINT:
        return TEST_COLOR;
      case COMP_REPEATER:
        return REPEATER_COLOR;
      case CONNECTOR:
        return CONNECTOR_COLOR
      case IC:
      default:
        return IC_COLOR;
    }
  }

export {
  prelayoutList,
  GPIOList,
  I2CI3C,
  MODEL,
  PRE_LAYOUT_DESIGN_VERSION,
  componentList,
  PRE_IC,
  PRE_CONNECTOR,
  PRE_RES,
  PRE_REPEATER,
  PRE_TEST,
  netList,
  IC_INDEX,
  RES_INDEX,
  TEST_INDEX,
  CONNECTOR_INDEX,
  REPEATER_INDEX,
  SIGNAL_DISTANCE,
  CANVAS_PADDING,
  SIGNAL_TO_COMP,
  COMP_HEIGHT,
  COMP_PADDING,
  RES_WIDTH,
  SIGNAL_COMP_WIDTH,
  REPEATER_WIDTH,
  TEST_WIDTH,
  PORT_DISTANCE,
  GROUP_RES_DISTANCE,
  genericSignals,
  SCHEMATIC,
  schematicComponentList,
  getCanvasCompColor,
  IC_COLOR,
  RES_COLOR,
  CONNECTOR_COLOR,
  REPEATER_COLOR,
  TEST_COLOR
}