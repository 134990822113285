import React, { Fragment } from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

function getModelFileExist(modelInfo, {
  ibisList = [],
  spiceList = [],
  touchstoneList = [],
  ebdList = [],
  model,
  packageLayoutList = []
}) {
  if (modelInfo && modelInfo.type === 'IBIS' && modelInfo.libraryId && modelInfo.component) {
    const modelIndex = ibisList.findIndex(ibis => ibis.id === modelInfo.libraryId);
    if (modelIndex < 0) {
      if (model && model.fileName) {
        const _modelIndex = ibisList.findIndex(ibis => ibis.fileName === model.fileName || (ibis.type === "folder" && ibis.children && !!ibis.children.find(it => it.name === ibis.fileName)));
        return _modelIndex > -1 ? "updated" : "deleted";
      }
      return "deleted";
    }
  } else if (["Touchstone / SPICE", "EBD", "Touchstone"].includes(modelInfo.type) && modelInfo.files && modelInfo.files.length) {
    let deleteList = [], updateList = [];
    for (let file of modelInfo.files) {
      const libraryList = file.type === "SPICE" ? spiceList
        : (file.type === "Touchstone" ? touchstoneList
          : file.type === "EBD" ? ebdList : []);
      const index = libraryList.findIndex(item => (item.id === file.libraryId) || (item.type === "folder" && item.children && !!item.children.find(it => it.id === file.libraryId)));
      if (index < 0) {
        const _index = libraryList.findIndex(item => item.name === file.fileName || (item.type === "folder" && item.children && !!item.children.find(it => it.name === file.fileName)));
        if (_index >= 0) {
          updateList.push(file.fileName);
        } else {
          deleteList.push(file.fileName);
        }
      }
    }
    deleteList = [...new Set([...deleteList])];
    updateList = [...new Set([...updateList])];
    return deleteList.length || updateList.length ? { deleteList, updateList } : false;
  } else if (modelInfo && modelInfo.type === "Layout" && modelInfo.packageLayoutInfo) {
    const { packageId } = modelInfo.packageLayoutInfo;
    const findIndex = packageLayoutList.findIndex(item => item.id === packageId);
    if (findIndex < 0) {
      return "deleted";
    }
  }
  return false;
}

function getPassiveFileExist(model, { passiveSpiceList = [], passiveTouchstoneList = [] }) {
  if (!model || !model.type) {
    return null;
  }
  const type = model.type.toUpperCase();
  if (["TOUCHSTONE", "SPICE"].includes(type) && model.passiveModel && model.passiveModel.libraryId) {
    const libraryList = type === "SPICE" ? passiveSpiceList : passiveTouchstoneList;
    const passiveModel = model.passiveModel || {};
    const modelIndex = libraryList.findIndex(it => it.id === passiveModel.libraryId);
    if (modelIndex < 0) {
      const _modelIndex = libraryList.findIndex(it => it.name === passiveModel.fileName);
      if (_modelIndex > -1) {
        return "updated";
      }
      return "deleted";
    }
  }
  return null;
}

function getModelFileText(model, { fileNotExist }) {
  const files = model.files && model.files.length ? model.files : [];
  let fileNames = [...new Set(files.map(item => item.fileName))];
  let deleteText = "", updateText = "";
  if (fileNotExist) {
    const { deleteList, updateList } = fileNotExist;
    deleteText = deleteList && deleteList.length ? deleteList.join(", ") : "";
    updateText = updateList && updateList.length ? updateList.join(", ") : "";

    fileNames = fileNames.filter(item => !deleteList.includes(item) && !updateList.includes(item));
  }
  const text = fileNames.length ? fileNames.join(", ") : "";
  return { text, deleteText, updateText };
}

function getIBISPackageText(pkg) {
  let text = "";
  if (pkg && pkg.type === 'IBIS') {
    text = pkg.component ? pkg.component : '';
    if (pkg.component && pkg.model) {
      text = `${pkg.component}::${pkg.model}`;
      if (pkg.model === 'Generic' && pkg.modelInfo) {
        text = `${pkg.component}::${pkg.model}(R:${pkg.modelInfo.R}, L:${pkg.modelInfo.L}, C:${pkg.modelInfo.C})`;
      } else if (pkg.model === 'Matrix' && pkg.modelInfo) {
        text = `${pkg.component}::${pkg.model}(${pkg.modelInfo})`;
      }
    }
  }
  return text;
}

function getCompModelText(model, record, { fileNotExist, returnNull }) {
  if (!model || !Object.keys(model).length || model.type === 'None') {
    return returnNull ? null : <span>None</span>
  } else if (model.type === 'IBIS') {
    const text = getIBISPackageText(model);
    return fileNotExist ? <div className='aurora-file-check-div'>
      {text}
      <Tooltip
        title={`File ${record.model && record.model.fileName ? record.model.fileName : ""} has been ${fileNotExist}, the model is invalid.`}
        overlayClassName='aurora-tooltip'
      ><QuestionCircleOutlined
        className='aurora-file-check-icon'
        onClick={(e) => { e && e.stopPropagation() }} />
      </Tooltip>
    </div> : <span>{text}</span>;
  } else if (model.type === "Touchstone" || model.type === "Touchstone / SPICE" || model.type === 'EBD') {
    if (!model.files || !model.files.length) {
      return null;
    }
    const { text, deleteText, updateText } = getModelFileText(model, { fileNotExist });
    const _updateText = deleteText ? `, ${updateText}` : updateText;
    return _getCompModelText({ deleteText, updateText, text, _updateText });
  } else if (model.type === "Layout") {
    if (!model.packageLayoutInfo || !model.packageLayoutInfo.name) { return null };
    return fileNotExist ? <div className='aurora-file-check-div'>
      {model.packageLayoutInfo.name}
      <Tooltip
        title={`Package ${record.packageLayoutInfo && record.packageLayoutInfo.name ? record.packageLayoutInfo.name : ""} has been ${fileNotExist}, the package is invalid.`}
        overlayClassName='aurora-tooltip'
      ><QuestionCircleOutlined
        className='aurora-file-check-icon'
        onClick={(e) => { e && e.stopPropagation() }} />
      </Tooltip>
    </div> : <span>{model.packageLayoutInfo.name}</span>;
  }
}

function _getCompModelText({ deleteText, updateText, text, _updateText }) {
  return (deleteText || updateText) ? <div className='aurora-file-check-div'>
    {text ? <span className='aurora-file-text'>{text}, </span> : null}
    <span>{deleteText ? deleteText : ""}{updateText ? `${_updateText}` : null}</span>
    <Tooltip
      title={<Fragment>
        {deleteText ? `Files ${deleteText} has been deleted, the model is invalid.` : null}
        {updateText ? ` Files ${updateText} has been updated, the model is invalid.` : null}
      </Fragment>}
      overlayClassName='aurora-tooltip'
    ><QuestionCircleOutlined
      className='aurora-file-check-icon'
      onClick={(e) => { e && e.stopPropagation() }} />
    </Tooltip>
  </div> : (text ? <span>{text}</span> : null);
}

//TODO
/* function getPackageFileExistParse(pkg, {
  getSpiceParseModels,
  getTouchstoneParsePorts,
  getIBISPackageModels,
  ibisList,
  pkgSpiceList,
  pkgTouchstoneList
}) {
  if (pkg && pkg.type === 'IBIS' && pkg.libraryId && pkg.component) {
    let modelIndex = Array.isArray(ibisList) ? ibisList.findIndex(ibis => ibis.id === pkg.libraryId) : 0;
    if (modelIndex < 0) {
      return "deleted";
    } else {
      let notExist = false;
      const res = getIBISPackageModels(pkg.libraryId);
      if (res && res.length > 0 && pkg.component) {
        let find = res.find(item => item.component === pkg.component);
        if (find) {
          if (pkg.model === 'Generic' && find.generic && find.generic.length > 0) {
            const packageModelInfo = getPackageModelInfo(res, pkg, 'Generic');
            if (packageModelInfo && pkg.modelInfo) {
              if (packageModelInfo.R !== pkg.modelInfo.R || packageModelInfo.L !== pkg.modelInfo.L || packageModelInfo.C !== pkg.modelInfo.C) {
                notExist = 'updated';
              } else {
                notExist = false;
              }
            } else {
              if (pkg.modelInfo) {
                notExist = 'updated';
              } else {
                notExist = false;
              }
            }

          } else if (pkg.model === 'Pin' && find.pinSection && find.pinSection.length > 0) {
            notExist = false;
          } else if (pkg.model === 'Matrix' && find.packageModel) {
            if (find.packageModel === pkg.modelInfo) {
              notExist = false;
            } else {
              notExist = "updated";
            }
          }

        } else {
          if (pkg.component) {
            notExist = "updated";
          }
        }
      }
      return notExist;
    }
  } else if (pkg.files && pkg.files.length) {
    let deleteList = [], updateList = [];
    pkg.files.forEach(file => {
      if (file.libraryId) {
        const fileList = file.type === 'SPICE' ? pkgSpiceList : pkgTouchstoneList;
        let modelIndex = Array.isArray(fileList) ? fileList.findIndex(item => item.id === file.libraryId) : 0;
        if (modelIndex < 0) {
          //file not exist
          deleteList.push(file.fileName);
        } else {
          const fileName = getPackageFileCheck(file, pkg, { getSpiceParseModels, getTouchstoneParsePorts });
          if (fileName) {
            //file has been updated
            updateList.push(fileName);
          }
        }
      }
    });
    deleteList = [...new Set([...deleteList])];
    updateList = [...new Set([...updateList])];
    return { deleteList, updateList };
  } else {
    return false;
  }
} */

/* function getPackageFileCheck(file, pkg, { getSpiceParseModels, getTouchstoneParsePorts }) {
  let ports = [], subcktList = null, portList = null, nodes = [];
  if (file.type === 'SPICE' && file.subckt) {
    //get subckt list
    subcktList = getSpiceParseModels({ libraryId: file.libraryId });
    //find current subckt
    const currentSubckt = Array.isArray(subcktList) ? subcktList.find(item => item.name === file.subckt) : null;
    ports = currentSubckt ? currentSubckt.ports : [];
    //filter nodes by libraryId and subckt
    nodes = Array.isArray(pkg.pairs) ? pkg.pairs.filter(item => item.node && item.libraryId === file.libraryId && item.subckt === file.subckt).map(item => item.node) : [];
  } else if (file.type === 'Touchstone') {
    //get port list
    portList = getTouchstoneParsePorts(file.libraryId);
    ports = Array.isArray(portList) ? portList.map(item => item.port) : [];
    //filter nodes by libraryId
    nodes = Array.isArray(pkg.pairs) ? pkg.pairs.filter(item => item.node && item.libraryId === file.libraryId).map(item => item.node) : [];
  }
  //filter not exist nodes
  const notExistNodes = nodes.filter(item => !ports.includes(item));
  return notExistNodes
    && notExistNodes.length > 0
    && ((file.type === 'SPICE' && subcktList) || (file.type === 'Touchstone' && portList))
    ? file.fileName : false;
} */

function getPackageModelInfo(packages, pkg, model) {
  let packageModel = null;

  if (packages && pkg && model) {
    const findComp = packages.find(item => pkg && item.component === pkg.component);

    if (findComp) {
      switch (model) {
        case 'Generic':
          packageModel = {};
          findComp.generic.forEach(item => {

            if (item.name === 'R_pkg' || item.name.match(/R/ig)) {
              packageModel.R = item.typ;
            }
            if (item.name === 'L_pkg' || item.name.match(/L/ig)) {
              packageModel.L = item.typ;
            }
            if (item.name === 'C_pkg' || item.name.match(/C/ig)) {
              packageModel.C = item.typ;
            }
          });
          break;
        case 'Pin':
          packageModel = '';
          break;
        case 'Matrix':
          packageModel = findComp.packageModel;
          break;
        default: break;
      }
    }
  }
  return packageModel;
}

function getRLCValueText(value) {
  if (!value) { return '' }
  const rText = `R = ${(value.r && value.r !== "0") ? (value.r + 'Ω') : '0Ω'}, `;
  const lText = `L = ${(value.l && value.l !== "0") ? (value.l + 'H') : '0nH'}, `;
  const cText = `C = ${(value.c && value.c !== "0") ? (value.c + 'F') : '0nF'}`;
  const text = `${rText}${lText}${cText}`;
  return text;
}

export {
  getModelFileExist,
  getPassiveFileExist,
  getModelFileText,
  getCompModelText,
  getPackageModelInfo,
  getIBISPackageText,
  _getCompModelText,
  getRLCValueText
}