import React, { Component } from 'react';
import BraftEditor from 'braft-editor';
import richTextData from '@/services/helper/data/richTextData';
import 'braft-editor/dist/index.css';
import './index.css';


const controls = [
  'undo', 'redo', 'separator',
  'font-size', 'line-height', 'letter-spacing', 'separator',
  'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
  'superscript', 'subscript', 'remove-styles', 'separator', 'text-indent', 'text-align', 'separator',
  'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
  'hr', 'separator',
  'clear', 'separator',
  'fullscreen'
] // separator is line, not a control
const empty = "<p></p>";
class TextEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editorState: BraftEditor.createEditorState(null)
    }

  }

  async componentDidMount() {
    const { onRef, product, editId, type } = this.props;
    if (onRef) {
      onRef(this);
    }

    const data = await richTextData.getData(product, type, editId);
    this.setState({
      editorState: BraftEditor.createEditorState(data)
    })
  }

  componentDidUpdate = async (prevProps) => {
    const { product, editId, type } = this.props;
    if (product !== prevProps.product || editId !== prevProps.editId) {
      const data = await richTextData.getData(product, type, editId);
      this.setState({
        editorState: BraftEditor.createEditorState(data)
      })
    }
  }

  submitContent = () => {
    const { product, editId, type } = this.props;
    const { editorState } = this.state;
    const htmlContent = editorState.toHTML() !== empty ? editorState.toHTML() : "";
    richTextData.save({ product, type, id: editId, data: htmlContent });
    this.props.updateTextEditorStatus();
  }

  handleEditorChange = (editorState) => {
    this.setState({ editorState })
  }

  render() {
    const { editorState } = this.state
    return (
      <div className="aurora-rich-text-editor">
        <BraftEditor
          value={editorState}
          onChange={this.handleEditorChange}
          onSave={this.submitContent}
          controls={controls}
          language='en'
        />
      </div>
    )

  }
}

export default TextEditor;
