import React, { PureComponent, Fragment } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Select, Input, Switch, Checkbox, message } from 'antd';
import {
  TX_MODEL_TYPES,
  RX_MODEL_TYPES
} from '../../services/LibraryHelper';
import { ROCKY, SIERRA } from '../../constants/pageType';
import TreeSelect from '@/components/TreeSelect';
import PinSpiceModel from '../PinSpiceModel';
import './index.css';

const Option = Select.Option;
const SPICE = 'SPICE'
class ModelSpice extends PureComponent {
  constructor(props) {
    super(props);
    /**
     * props 
     *   // libType - SPCIE/IBIS, modelType - 'I/O,Input,Output,Tristate'
     * origin: { enableVoltage, fileName, modelName, modelType, name, libType } // origin - Can not be modified 
     * editModel : { enableVoltage, fileName, modelName, modelType, name, libType } // editModel - Editable
     * record: { component, deviceVcc, model, pinModels, net, pin, signal, usage,... }
     * type - tx/rx
     * 
     * saveChange(model: {}, deviceVcc: "")
     */
    const { type, editModel } = props;
    if (type === 'tx') {
      this.bufferTypes = TX_MODEL_TYPES;
    } else if (type === 'rx') {
      this.bufferTypes = RX_MODEL_TYPES;
    } else {
      this.bufferTypes = [];
    }

    this.state = {
      bufferType: this.getData(editModel, 'modelType') || "",
      subckt: this.getData(editModel, 'modelName') || "",
      enableVoltage: this.getData(editModel, 'enableVoltage') || "",
      libraryId: this.getData(editModel, 'libraryId'),
      deviceVcc: props.record.deviceVcc,
      loading: false,
      files: [],
      spiceFile: this.getData(editModel, 'fileName') || "",
      folder: this.getData(editModel, 'folder'),
      subcktNames: [],
      selectCkt: [],
      folderFileSubckts: [],
      version: this.getData(editModel, 'version')
    }

    this.onChangeSubckt = this.onChangeSubckt.bind(this);
    this.onChangeEnableVoltage = this.onChangeEnableVoltage.bind(this);

    this.root = document.getElementById('root');
  }

  getData(model, pram) {
    return model && model.libType === SPICE ? model[pram] : null;
  }

  onChangeBuffer = (value) => {
    const { subcktNames } = this.state;
    this.setState({
      bufferType: value,
      subckt: '',
      enableVoltage: "",
      selectCkt: subcktNames
    }, () => {
      this.onSaveChange();
    });
  }

  onChangeEnableVoltage(enableVoltage) {
    this.setState({
      enableVoltage
    }, () => {
      this.onSaveChange();
    })
  }

  onChangeSubckt(name) {
    this.setState({
      subckt: name
    }, () => {
      this.onSaveChange();
    });
  }

  onSaveChange = () => {
    const { spiceFile, libraryId, bufferType, enableVoltage, subckt, deviceVcc, version } = this.state;
    this.props.saveChange({
      libraryId,
      fileName: spiceFile,
      modelType: bufferType,
      enableVoltage,
      modelName: subckt,
      version
    }, deviceVcc);
  }

  exsitEnableVoltage(type) {
    const _type = (type || "").toUpperCase();
    if (_type.includes('I/O') || _type.includes('IO') || _type === 'TRISTATE' || _type === '3-STATE' || _type === '3STATE') {
      return true;
    };
    return false;
  }

  handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { spiceList } = this.props;
    if (prevProps.spiceList && prevProps.spiceList.length !== spiceList.length) {
      this.setState({
        files: spiceList
      })
    }
  }

  componentDidMount = () => {
    const { spiceList, _compModel, editModel, product, onDieSpiceList } = this.props;
    const { bufferType, subckt, libraryId, spiceFile, folder } = this.state;
    const files = spiceList;
    const newState = { files };
    if (!!folder && product === ROCKY) {
      this.getFolderFileSubckts(libraryId, spiceFile);
      const findModelFolder = onDieSpiceList.find(item => item.type === "folder" && item.id === libraryId && item.name === folder);
      const findModel = findModelFolder && findModelFolder.children ? findModelFolder.children.find(item => item.fileName === spiceFile) : null;
      this.setState({
        loading: false,
        files: findModel ? [findModelFolder, ...files] : files
      })
      return;
    }

    if (!spiceFile) {
      let fileName = "", libraryId = "", folder = "", version = "";
      if (_compModel && _compModel.fileName && _compModel.libType === SPICE) {
        fileName = _compModel.fileName;
        libraryId = _compModel.libraryId;
        folder = _compModel.folder;
        version = _compModel.version;
      } else if (files.length) {
        if (product === SIERRA) {
          for (let _file of files) {
            if (_file.type !== 'folder') {
              fileName = _file.name;
              libraryId = _file.id;
              version = _file.version;
              break;
            }
          }
        } else {
          if (!libraryId) {
            const findModel = files.find(item => item.type === "file" || (item.type === "folder" && item.children && item.children.length))
            const _model = findModel.type === "folder" ? findModel.children[0] : findModel
            fileName = _model.name;
            libraryId = _model.id;
            folder = findModel.type === "folder" ? findModel.name : "";
            version = _model.version;
          }
        }
      }
      newState.spiceFile = fileName;
      newState.libraryId = libraryId;
      newState.folder = folder;
      newState.version = version;

      this.props.saveChange({
        libType: 'SPICE',
        fileName,
        libraryId,
        modelType: "",
        modelName: "",
        enableVoltage: '',
        version
      });
    } else {
      const findFile = files.find(item => item.name === spiceFile);
      if (findFile && findFile.id !== libraryId) {
        newState.libraryId = findFile.id;
        newState.version = findFile.version || newState.version;
        this.props.saveChange({
          ...editModel,
          libraryId: findFile.id,
          version: newState.version
        });
      }
    }
    this.setState(newState, () => {
      const { spiceFile, libraryId, folder } = this.state;
      if (spiceFile && libraryId) {
        if (!!folder) {
          this.getFolderFileSubckts(libraryId, spiceFile);
        } else {
          this.getSpiceModels({ fileName: spiceFile, bufferType, subckt, libraryId });
        }
      } else {
        this.setState({
          loading: false,
        })
      }
      this.props.updateBufferItemWidth(folder ? "100%" : "50%")
    })
  }

  getSpiceModels({ fileName, bufferType, subckt, libraryId }) {
    if (!libraryId) {
      this.setState({
        loading: false,
        subcktNames: [],
        selectCkt: [],
        folderFileSubckts: []
      })
      return;
    }
    const { files } = this.state;
    const findId = libraryId ? { id: libraryId } : files.find(item => item.name === fileName);
    if (findId) {
      const { getSpiceModel, type, record, product } = this.props;
      let selectCkt = [];
      getSpiceModel(type, fileName, findId.id).then(model => {
        let models = model && model.models ? model.models : [];
        selectCkt = models || [];
        const isRx = record.usage === "Receiver";
        let _bufferType = ""
        if (RX_MODEL_TYPES.includes(bufferType)) {
          _bufferType = bufferType;
        } else if (isRx && product === SIERRA) {
          _bufferType = "Input";
        }
        let _subckt = null;

        if (subckt && models && models.find(it => it.name === subckt)) {
          _subckt = subckt;
        } else if (product === SIERRA && models && models.length === 1) {
          _subckt = models[0].name;
        }

        this.setState({
          loading: false,
          subcktNames: models || [],
          selectCkt,
          bufferType: _bufferType,
          subckt: _subckt
        }, () => {
          product === SIERRA && this.onSaveChange();
        })
      }).catch(error => {
        this.setState({
          loading: false
        })
      })
    } else {
      message.error(`Not found ${fileName} file.`)
    }
  }

  onSpiceChange = (value) => {
    const { files } = this.state;
    let libraryId = '', name = value.name ? value.name : value, version = null;
    if (value.id) {
      libraryId = value.id;
      version = value.version
    } else {
      const findId = files.find(file => file.fileName === value);
      if (findId) {
        libraryId = findId.id;
        version = findId.version;
      }
    }
    const model = { fileName: name, libraryId, modelType: "", modelName: "", enableVoltage: 0 };
    this.props.saveChange(version ? { ...model, version } : model);
    this.setState({
      spiceFile: name,
      subckt: null,
      libraryId,
      bufferType: "",
      folder: "",
      version
    }, () => {
      this.getSpiceModels({ fileName: name, libraryId });
    });
  }

  handleKeyDown = (e) => {
    e.target.blur();
  }

  selectUnit = () => {
    return (
      <Select
        value={'V'}
        className='IC-select-model-input-after'
        popupClassName='IC-select-model-dropdown'
        popupMatchSelectWidth={false}
        getPopupContainer={trigger => trigger.parentNode}>
        <Option key="V">V</Option>
      </Select>
    )
  }

  cleanBufferType = (e) => {
    e.stopPropagation();
    this.setState({
      bufferType: "",
      subckt: '',
      enableVoltage: "",
      selectCkt: this.state.subcktNames
    }, () => {
      this.onSaveChange();
    });
  }

  cleanSubcktName = (e) => {
    e.stopPropagation();
    this.setState({
      subckt: ""
    }, () => {
      this.onSaveChange();
    });
  }

  cleanModelFile = (e) => {
    e.stopPropagation();
    this.props.saveChange({ fileName: "", libraryId: "", modelType: "", modelName: "", enableVoltage: 0, version: "" });
    this.setState({
      spiceFile: "",
      subckt: null,
      libraryId: "",
      bufferType: "",
      subcktNames: [],
      selectCkt: [],
      folder: "",
      folderFileSubckts: [],
      version: ""
    });
  }

  selectFile = (file) => {
    const { files } = this.state;
    const index = files.findIndex(item => item.id === file.id);
    if (index < 0) {
      return;
    }
    const isFolder = files[index].type === "folder";
    const folderName = isFolder ? files[index].name : "";
    this.selectModelFile({ libraryId: file.id, fileName: file.name, folder: folderName });
  }

  selectModelFile = ({ libraryId, fileName, folder }) => {
    this.setState({
      spiceFile: fileName,//fileName
      libraryId,
      folder,//folder name
      subckt: "",
      folderFileSubckts: []
    }, () => {
      if (folder) {
        this.getFolderFileSubckts(libraryId, fileName);
        this.props.updateBufferItemWidth("100%")
      } else {
        this.onSpiceChange(fileName)
        this.props.savePinSpiceModel(null);
        this.props.updateBufferItemWidth("50%")
      }
    })
  }

  selectModelSubckt = (key) => {
    this.setState({
      subckt: key
    })
  }

  getFolderFileSubckts = async (libraryId, fileName) => {
    const { getFolderFileDetail } = this.props;
    if (!getFolderFileDetail) {
      return;
    }

    try {
      const models = await getFolderFileDetail({ libraryId, fileName });
      this.setState({
        folderFileSubckts: models || []
      })
    } catch (error) {
      console.error(error)
      this.setState({
        folderFileSubckts: []
      })
    }
  }

  getSelectComponents = (spiceFile, libraryId, files) => {
    const { product } = this.props;
    const { folder } = this.state;
    if (product === ROCKY) {
      return (
        <TreeSelect
          showSearch
          labelInValue
          placeholder="File"
          value={{
            key: libraryId || "",
            label: folder ? `${folder}::${spiceFile}` : spiceFile || ""
          }}
          onSelectItem={this.selectFile}
          popupClassName='IC-select-model-select-dropdown'
          className='IC-model-type-select aurora-select IC-model-type-tree-select'
          popupMatchSelectWidth={false}
          allowClear={{ cleanIcon: <CloseCircleFilled onClick={(e) => { this.cleanModelFile(e) }} /> }}
          fileList={files}
          showFileFolder={true}
          selected={`${libraryId}::${spiceFile}`}
        />
      );
    } else if (product === SIERRA) {
      return (
        <TreeSelect
          value={spiceFile || undefined}
          onSelectItem={this.onSpiceChange}
          size='small'
          showSearch
          popupClassName='IC-select-model-select-dropdown'
          className='IC-model-type-select'
          allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelFile(e)} /> }}
          fileList={files}
          getPopupContainer={() => document.getElementById('root')}
          selected={libraryId}
        />
      );
    }

    return (
      <Select
        value={spiceFile}
        onChange={this.onSpiceChange}
        showSearch
        size='small'
        className='IC-model-type-select'
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelFile(e)} /> }}
        popupClassName='IC-select-model-select-dropdown'
        getPopupContainer={() => document.getElementById('root')}
      >
        {files.map((file, index) => <Option key={index} value={file.name} title={file.name}>{file.name}</Option>)}
      </Select>
    );
  }

  getSingleSpiceBuffer = () => {
    const { bufferTypes, onChangeEnableVoltage, onChangeSubckt } = this;
    const { bufferType, enableVoltage, subckt, selectCkt } = this.state;
    const { type, pu, pd, powerOff } = this.props;
    return (
      <Fragment>
        <div className='IC-select-dialog clear'>
          <div className='IC-select-model-title-item'> Buffer Type </div>
          <Select
            value={bufferType}
            onChange={this.onChangeBuffer}
            popupMatchSelectWidth={false}
            popupClassName='IC-select-model-select-dropdown'
            showSearch
            size='small'
            className='IC-model-type-select'
            allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanBufferType(e)} /> }}
            getPopupContainer={() => document.getElementById('root')}
          >
            {bufferTypes.map((type, index) => <Option key={index} value={type} title={type}>{type}</Option>)}
          </Select>
        </div>
        {this.exsitEnableVoltage(bufferType) &&
          <div className='IC-select-dialog clear'>
            <div className='IC-select-model-title-item'> Enable Voltage </div>
            <Select
              value={enableVoltage}
              onChange={onChangeEnableVoltage}
              size='small'
              popupMatchSelectWidth={false}
              className='IC-model-type-select'
              suffixIcon={null}
              allowClear={true}
              popupClassName='IC-select-model-select-dropdown'
              getPopupContainer={() => document.getElementById('root')}
            >
              <Option value="1">Active-High</Option>
              <Option value="0">Active-Low</Option>
            </Select>
          </div>}
        <div className={this.exsitEnableVoltage(bufferType) ? "IC-apply-dialog clear" : 'IC-select-dialog clear'}>
          <div className='IC-model-title-item'>Subckt Name</div>
          <Select
            value={subckt}
            onChange={onChangeSubckt}
            showSearch
            size='small'
            popupMatchSelectWidth={false}
            allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanSubcktName(e)} /> }}
            className={this.exsitEnableVoltage(bufferType) ? 'IC-select-model-name-input' : 'IC-model-type-select'}
            popupClassName='IC-select-model-select-dropdown'
            getPopupContainer={() => document.getElementById('root')}
          >
            {selectCkt.map(({ name, ports }) =>
              <Option key={name} value={name} title={name}>{name}</Option>)}
          </Select>
        </div>
        {type === 'tx' && <Fragment>
          <div className='IC-select-power-dialog clear' style={{ paddingLeft: 2 }}>
            <div className='IC-model-title-item'>Power Off</div>
            <Switch
              size="small"
              className="IC-power-off-switch"
              checked={powerOff === '1'}
              onChange={this.props.powerOffChange}
            />
          </div>
          <div className='IC-select-power-dialog clear'>
            <div className='IC-model-title-item'>V_PU</div>
            <Input
              size='small'
              className='IC-select-model-input'
              onChange={(e) => this.props.changeVoltage(e, 'pu')}
              value={pu}
              onPressEnter={e => this.handleKeyDown(e)}
              addonAfter={this.selectUnit()}
            />
          </div>
          <div className='IC-select-power-dialog clear'>
            <div className='IC-model-title-item'>V_PD</div>
            <Input
              size='small'
              onChange={(e) => this.props.changeVoltage(e, 'pd')}
              value={pd}
              className='IC-select-model-input'
              onPressEnter={e => this.handleKeyDown(e)}
              addonAfter={this.selectUnit()}
            />
          </div>
        </Fragment>}
      </Fragment>
    );
  }

  render() {
    const { spiceFile, files, libraryId, folder, subckt, folderFileSubckts } = this.state;
    const { type, applyAll, clkMemory, applyAllMemory, product, record, pinList, maxWidth, maxHeight, isSetVDDQVSS } = this.props;
    const text = product === ROCKY ? "Apply to all same-type pins" : "Apply the same model to all the pins in the component";
    const className = product === ROCKY ? "IC-apply-model-types-title" : "IC-apply-model-component-title";
    const rockyPinSpice = product === ROCKY && folder;
    return (
      <Fragment>
        {!rockyPinSpice ? <div className={product === ROCKY ? 'IC-select-dialog clear' : 'IC-select-dialog IC-multi-spice-select-dialog clear'}>
          <div className='IC-select-model-title-item'>{product === ROCKY ? 'File' : 'Model File'}
          </div>
          {this.getSelectComponents(spiceFile, libraryId, files)}
        </div> : null}
        {rockyPinSpice ?
          <PinSpiceModel
            pinInfo={{ component: record.component, pin: record.pin, net: record.net, signal: record.signal }}
            pinList={pinList}
            fileList={files}
            type={type}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            pinSpiceModel={{ libType: SPICE, libraryId, folder, fileName: spiceFile, modelName: subckt }}
            folderFileSubckts={folderFileSubckts}
            selectModelFile={this.selectModelFile}
            selectModelSubckt={this.selectModelSubckt}
            getPinListBySignal={this.props.getPinListBySignal}
            savePinSpiceModel={this.props.savePinSpiceModel}
            isSetVDDQVSS={isSetVDDQVSS}
          />
          : this.getSingleSpiceBuffer()}
        <div className='IC-apply-dialog clear'>
          <div className={`IC-apply-model-title ${className}`}>{text}</div>
          <Checkbox
            className='IC-model-apply-checkbox'
            checked={applyAll}
            onChange={(e) => this.props.onApplyChange(e)}
          />
        </div>
        {product === ROCKY && clkMemory && applyAll ?
          <div className='IC-apply-sub-dialog clear'>
            <span className='IC-sub-apply-box'></span>
            <div className='IC-apply-memory-model-title'>Apply to all same-type pins in components of the same part</div>
            <Checkbox
              className='IC-model-apply-checkbox'
              checked={applyAllMemory}
              onChange={(e) => this.props.onApplyMemoryChange(e)}
            />
          </div>
          : null}
      </Fragment>
    )
  }
}

export default ModelSpice;