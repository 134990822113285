import {
  IO_SIM,
  SPICE_CARD_TEMPLATE,
  IO_SIM_GROUPS
} from "../../../constants/treeConstants";


class HimalayasChannels {
  constructor() {
    this.channels = {
      [SPICE_CARD_TEMPLATE]: new Map(), // key - projectId, value - spice card template
      [IO_SIM]: new Map(), // key - projectId, value - io sim
      [IO_SIM_GROUPS]: new Map(), // key - projectId, value - [ spice card template:[io sim] ]
    }
  }

  setList = (type, id, list) => {
    this.channels[type].set(id, list);
  }

  getList = (type, id) => {
    const data = this.channels[type].get(id) ? this.channels[type].get(id) : []
    return data;
  }

  getInfoById = (type, projectId, id) => {
    const data = this.channels[type].get(projectId) ? this.channels[type].get(projectId) : []
    return data.find(item => item.id === id);
  }

  getInfoByVerificationId = (type, projectId, verificationId) => {
    const data = this.channels[type].get(projectId) ? this.channels[type].get(projectId) : []
    return data.find(item => item.verificationId === verificationId);
  }

  getInputChildren = (projectId, spiceCardChannelId) => {
    const groupList = this.channels[IO_SIM_GROUPS].get(projectId) || [];
    return (groupList.find(item => item.id === spiceCardChannelId) || { children: [] }).children || [];
  }
}

const himalayasChannels = new HimalayasChannels();
export default himalayasChannels;