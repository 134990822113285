import { DESIGN_SUCCESS, DESIGN_IDENTIFY_FAILED } from '@/constants/designCategory';
import { CARD, PACKAGE, PCB, PCB_PDN, PACKAGE_PRE_LAYOUT } from '../../constants/treeConstants';
import { PRE_LAYOUT } from '../../constants/designVendor';
import { LAYOUT } from '../../constants/designType';

class DesignConstructor {
  constructor() {
    this.designList = new Map(); //key - design id, value :{ id ,name, designVersion , vendor, subId, projectId .... }
  }

  getSize = () => {
    return this.designList.size;
  };

  getDesignList = () => {
    return this.designList;
  }

  getDesignValues = (projectId) => {
    const designs = Array.from(this.designList.values());
    if (!projectId) {
      return designs;
    } else {
      return designs.filter(item => item.projectId === projectId);
    }
  }

  getDesignNames = (projectId) => {
    const designs = this.getDesignValues(projectId) || [];
    return designs.map(item => item.name);
  }

  getDesign = (id) => {
    return this.designList.get(id);
  }

  getDesignName = (id) => {
    const design = this.designList.get(id);
    return design ? design.name : "";
  }

  addDesign = (id, data) => {
    this.designList.set(id, data);
  }

  addDesigns = (designs) => {
    designs.forEach(d => {
      this.designList.set(d.id, d);
    });
  }

  delDesign = (id) => {
    this.designList.delete(id);
  }

  clearCache = () => {
    this.designList = new Map();
  }

  isPreLayout = (id) => {
    const design = this.designList.get(id);
    return design && design.vendor && design.vendor === PRE_LAYOUT ? true : false;
  }

  getDesignType = (id) => {
    const design = this.designList.get(id);
    return design && design.dataType ? design.dataType.toLowerCase() : "";
  }

  getPreLayoutType = (id) => {
    const design = this.designList.get(id);
    return design && design.type === LAYOUT ? PCB.toLowerCase() : PACKAGE.toLowerCase();
  }

  getPreLayoutElectric = (id) => {
    const design = this.designList.get(id);
    return design && design.content ? design.content.electric : undefined;
  }

  getDesignVendor = (id) => {
    const design = this.designList.get(id);
    return design && design.vendor ? design.vendor : "";
  }

  getDesignMultiZone = (id) => {
    const design = this.designList.get(id);
    return design && design.multiZone ? design.multiZone : false;
  }

  getAvailableDesignsDesignValues = (projectId) => {
    return this.getDesignValues(projectId).filter(item => item.category === DESIGN_SUCCESS) || [];
  }


  getAvailableDesignsNotPreLayout = (projectId) => {
    return this.getDesignValues(projectId).filter(item => item.category === DESIGN_SUCCESS && item.vendor !== PRE_LAYOUT) || [];
  }

  getExceptFailedDesignValues = (projectId) => {
    return this.getDesignValues(projectId).filter(item => item.category === DESIGN_SUCCESS || item.category === DESIGN_IDENTIFY_FAILED) || [];
  }

  getAvailableDesignsDesignsLength = (projectId) => {
    return this.getAvailableDesignsDesignValues(projectId).length
  }

  getAvailablePackageDesignValues = (projectId) => {
    return this.getDesignValues(projectId).filter(item => item.category === DESIGN_SUCCESS && [PACKAGE, PACKAGE_PRE_LAYOUT].includes(item.dataType)) || [];
  }

  getAvailablePackageDesignFirstId = (projectId) => {
    return this.getAvailablePackageDesignValues(projectId).length ? this.getAvailablePackageDesignValues(projectId)[0].id : null;
  }

  getAvailableDesigns(id) {
    return this.getDesignValues(id).filter(item => item.category === DESIGN_SUCCESS) || []
  };

  getAvailableCardDesignValues = (projectId) => {
    return this.getDesignValues(projectId).filter(item => item.category === DESIGN_SUCCESS && item.dataType === CARD) || [];
  }

  getAvailableDesignsFirstId = (projectId) => {
    const designList = this.getAvailableDesigns(projectId);
    return designList && designList.length ? designList[0].id : null;
  }

  getAvailableFirstPCBDesignsId = (projectId) => {
    let designList = this.getAvailableDesigns(projectId);
    designList = designList.filter(item => item.dataType === PCB)
    return designList && designList.length ? designList[0].id : null;
  }

  getAvailablePCBDesignInfo = (projectId) => {
    let designList = this.getAvailableDesigns(projectId);
    designList = designList.filter(item => item.dataType === PCB)
    return designList && designList.length ? designList[0] : null;
  }

  getAvailableDesignPdnInfo = (projectId) => {
    let designList = this.getAvailableDesigns(projectId);
    designList = designList.filter(item => item.dataType === PCB);
    const currentDesign = designList && designList.length ? designList[0] : {};
    if (currentDesign && currentDesign.children && currentDesign.children.length) {
      const findPDN = currentDesign.children.find(item => item.dataType === PCB_PDN);
      if (findPDN) {
        return findPDN
      }
    }
    return {}
  }
};

let designConstructor = new DesignConstructor();
export default designConstructor;