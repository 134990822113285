import React, { Component } from "react";
import DebugContent from '@/components/DebugDownload/DebugContent';
import DebugFooter from "@/components/DebugDownload/DebugFooter";
import DebugDownloadMenu from './debugDownloadMenu';
import {
  SIMULATION_DEBUG, PCB_PARSING, getDebugCheckValues, INTERFACE_SIMULATION, PACKAGE_DEBUG,
  S_PARAMETER_DOWNLOAD, REPORT, RESULT, DOWNLOAD_RESULT, EXPERIMENT_S_PARAMETER_DOWNLOAD
} from '@/services/helper/debugHelper';
import { getResBlob, getDebugDownloadProgress } from '@/services/helper/downloadHelper';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { getDownloadFileId, getDownloadFileIdForPost, getDownloadFileSize, downloadFileByChunk } from "../../services/helper/downloadHelper";
import { ANDES_V2, HIMALAYAS, ROCKY } from "../../constants/pageType";
import './index.css'
import { BMA_CHANNEL_RESULT, MULTIPLE_CHANNEL, PACKAGE_PDN, PCB_CHANNEL, PCB_CHANNEL_RESULT, PCB_PDN } from "../../constants/treeConstants";

class DebugDownloadContentItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      info: {},
      downloadList: [],
      currentCheckValues: [],
      downloadMsgInfo: {},
      downloadProgressInfo: {},
      checkExpandKeys: [],
      downloadMenus: {}
    }
  }

  componentDidMount = () => {
    const { menuType } = this.props;
    if (menuType) {
      this.getValue();
    }
  }

  getValue = () => {
    const { info, downloadList, currentCheckValues, defaultCheckExpandKeys, downloadMenus } = this.props;
    this.setState({
      info,
      downloadList: downloadList,
      currentCheckValues: currentCheckValues,
      checkExpandKeys: defaultCheckExpandKeys || [],
      downloadMenus
    })
  }

  componentDidUpdate = (prevProps) => {
    const { menuType } = this.props;
    if (menuType !== prevProps.menuType) {
      this.getValue();
    }
  }

  changeDebugCheckList = async (values, key) => {
    const { currentCheckValues, downloadList } = this.state;
    const { downloadMenus, menuType } = this.props;
    let _values = [...values || []], _downloadList = [...downloadList];
    // 
    if (menuType === EXPERIMENT_S_PARAMETER_DOWNLOAD) {
      const checkValue = currentCheckValues.map(item => item.name);
      const addValue = _values.find(item => !checkValue.includes(item));
      const download = downloadList.find(item => item.option === addValue) || {};
      if (addValue && download.isUpdate) {
        const variablesTables = await this.props.getExperimentsBySweepIdPromise(addValue);
        _downloadList = this.props.expandChange(addValue, variablesTables);
        this.setState({ downloadList: _downloadList })
      }
    }
    if (key) {
      const allValues = Object.keys(downloadMenus || {})
        .filter(item => item !== key)
        .map(item => { return downloadMenus[item].map(it => it.option) })
        .flat(2);
      const filterValues = currentCheckValues.filter(item => allValues.includes(item.name)).map(item => item.name);
      _values = [..._values, ...filterValues];
    }
    this.setState({
      currentCheckValues: getDebugCheckValues(_values, currentCheckValues, _downloadList)
    })
  }

  changeDebugCheckChildren = (values, parent) => {
    const { currentCheckValues } = this.state;
    const { enabledChildren } = this.props;
    let _currentCheckValues = [...currentCheckValues];
    const index = currentCheckValues.findIndex(item => item.name === parent);
    if (index < 0 && !enabledChildren) {
      return;
    }
    if (index < 0) {
      _currentCheckValues.push({
        name: parent,
        children: [...values]
      })
    } else {
      _currentCheckValues[index].children = [...values];
    }

    this.setState({
      currentCheckValues: _currentCheckValues
    })
  }

  DownloadDebugClick = (e) => {
    const { currentCheckValues, downloadProgressInfo, downloadMsgInfo } = this.state;
    const { menuType } = this.props;
    const options = currentCheckValues && currentCheckValues.length > 0 ? [...currentCheckValues.map(item => item.name)] : ["empty"];
    const token = localStorage.getItem('token');
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    const targetTime = dayjs(time.toUTCString()).format('YYYYMMDD');

    this.setState({
      downloadProgressInfo: {
        ...downloadProgressInfo,
        [menuType]: -1
      },
      downloadMsgInfo: {
        ...downloadMsgInfo,
        [menuType]: ""
      }
    })

    switch (menuType) {
      case PCB_PARSING:
        this.downloadPCBParsing(options, token, targetTime);
        break;
      case SIMULATION_DEBUG:
        this.downloadSimulation(options, token, targetTime);
        break;
      case PACKAGE_DEBUG:
        this.downloadPackage(options, token, targetTime);
        break;
      case S_PARAMETER_DOWNLOAD:
        this.downloadsParameter(options, token, targetTime);
        break;
      case REPORT:
        this.reportTemplate(options, token, targetTime);
        break
      case RESULT:
        this.resultTemplate(options, token, targetTime);
        break;
      case DOWNLOAD_RESULT:
        this.downloadResult(options, token, targetTime);
        break;
      case MULTIPLE_CHANNEL:
        this.downloadMultiInfo(options, token, targetTime);
        break;
      case PCB_CHANNEL:
        this.downloadPCBChannelInfo(options, token, targetTime);
        break;
      case EXPERIMENT_S_PARAMETER_DOWNLOAD:
        this.downloadExperimentSparameterDownload(options, token, targetTime);
        break;
      case BMA_CHANNEL_RESULT:
        this.bmaChannelResultTemplate(options, token, targetTime)
        break;
      default: break;
    }
  }

  downloadExperimentSparameterDownload = (options, token, time) => {
    const { currentCheckValues } = this.state;
    const experimentIds = currentCheckValues.filter(item => item.children && item.children.length).map(item => item.children).flat(2).map(item => item)
    const url = this.props.getDownloadSimulationURL({
      experimentIds,
      token
    });
    const fileName = `Experiment_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "Experiment Touchstone", intervalTime: 1000 })
  }

  downloadPCBChannelInfo = (options, token, time) => {
    const { info } = this.state;
    if (!info || !info.selectInfo || !info.selectInfo.channelId) { return }
    const { menuType } = this.props;
    const { channelId, channelName, dataType, projectId, id } = info.selectInfo;
    const { url } = this.props.getDownloadSimulationURL({
      menuType,
      options,
      token,
      pdnId: channelId,
      projectId: projectId,
      isPdn: dataType === PCB_PDN ? true : false,
      verification: { id }
    });
    const fileName = `${channelName}_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "PCB Simulation" })
  }

  downloadMultiInfo = (options, token, time) => {
    const { info } = this.state;
    const { productType, menuType } = this.props;
    if (!info || !info.selectInfo
      ||
      ((productType === ROCKY && (!info.selectInfo.id)))) {
      return;
    }

    const { id, channelId, projectId } = info.selectInfo;
    let verification = id || null;
    if (verification === "All") { verification = 'all' }
    const { url } = this.props.getDownloadSimulationURL({
      verification: { id: verification },
      channel: { id: channelId },
      projectId: projectId,
      options,
      token,
      menuType
    });

    const fileName = `${info.selectInfo.name}_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "Multi Channel file" })
  }

  reportTemplate = (options, token, time) => {
    const { menuType, currentProjectName, product } = this.props;
    const { currentCheckValues } = this.state;
    let url = "";
    if (product === HIMALAYAS) {
      const channelIds = currentCheckValues.filter(item => item.children && item.children.length).map(item => item.children).flat(2).map(item => item)
      url = this.props.getDownloadSimulationURL({
        menuType,
        channelIds,
        token
      });
    } else {
      url = this.props.getDownloadSimulationURL({
        menuType,
        options,
        token
      });
    }
    const fileName = `${currentProjectName}_report_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "Report", intervalTime: 1000 })

  }

  resultTemplate = (options, token, time) => {
    const { menuType, currentProjectName, currentDesignId, currentVerificationId, menuKey } = this.props;
    const { url } = this.props.getDownloadSimulationURL({
      menuType,
      token,
      designId: currentDesignId,
      verification: { id: currentVerificationId },
      menuKey
    });
    const fileName = `${currentProjectName}_result_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "Result" })
  }

  bmaChannelResultTemplate = (options, token, time) => {
    const { bmaChannelId } = this.props;
    const url = this.props.getDownloadSimulationURL({
      bmaChannelId,
      token,
      simulationTypes: options
    });
    const fileName = `result_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "BMA_Channel_Result" })
  }

  downloadsParameter = (options, token, time) => {
    const { info } = this.state;
    const { productType } = this.props;
    if (!info || !info.selectInfo
      || ((productType === ROCKY && (((!info.selectInfo.dataType || info.selectInfo.dataType !== PCB_CHANNEL_RESULT) && (!info.selectInfo.ids || !info.selectInfo.ids.length)) || (info.selectInfo.dataType === PCB_CHANNEL_RESULT && !info.selectInfo.channelId)))
        || (productType !== ROCKY && !info.selectInfo.id))) {
      return;
    }
    let verificationIds = info.selectInfo.ids;
    if (productType === ROCKY && info.selectInfo.dataType === PCB_CHANNEL_RESULT) {
      verificationIds = [info.selectInfo.id];
    }

    const url = this.props.getDownloadSimulationURL({
      verificationId: info.selectInfo.id,
      verificationIds: verificationIds,
      options,
      token,
      menuType: S_PARAMETER_DOWNLOAD
    });

    const fileName = `${info.selectInfo.name}_${time}.zip`;

    this.downloadProgress({ url, fileName, messageType: "S-parameter file" })
  }

  downloadPCBParsing = (options, token, time) => {
    const { menuType } = this.props;
    const { info } = this.state;

    if (!info || !info.selectInfo || !info.selectInfo.id) {
      return;
    }
    const pcbSelected = info.selectInfo;
    const url = this.props.getDownloadSimulationURL({
      menuType,
      designId: pcbSelected.id,
      options,
      token
    });

    const fileName = `${pcbSelected.name}_${time}.zip`;

    this.downloadProgress({ url, fileName, messageType: "PCB parsing" })
  }

  downloadSimulation = (options, token, time) => {
    const { menuType } = this.props;
    const { info } = this.state;

    if (!info || !info.selectInfo || !info.selectInfo.id) {
      return;
    }
    const selectedData = info.selectInfo;
    const url = this.props.getDownloadSimulationURL({
      menuType,
      options,
      token,
      verificationId: selectedData.id
    });

    const fileName = `${selectedData.name}_${time}.zip`;

    this.downloadProgress({ url, fileName, messageType: "PDN Simulation" })
  }

  downloadPackage = (options, token, time) => {
    const { menuType } = this.props;
    const { info } = this.state;

    if (!info || !info.selectInfo || (info.selectInfo.dataType !== PACKAGE_PDN && !info.selectInfo.id && !info.selectInfo.notChannel) || ((info.selectInfo.notChannel && !info.selectInfo.channelName))) {
      return;
    }

    const selectedData = info.selectInfo;
    let verificationIds = [], pdnId = [];
    if (selectedData.id === 'all') {
      const { selectList } = info;
      const currentPackageInfo = selectList.find(item => item.id === selectedData.packageDesignId)
      for (let channelInfo of currentPackageInfo.children) {
        const { children, name, id } = channelInfo;
        if (['All'].includes(name)) { continue }
        if (name === "PDN") {
          if (selectedData.channelName === 'All') {
            pdnId.push(id)
          }
          continue
        }
        if (selectedData.channelName === 'All' || selectedData.channelName === name) {
          const verificationIdList = children && children.filter(item => item.id !== 'all').map(item => item.id)
          verificationIds.push(...verificationIdList)
        }
      }
    } else if (selectedData.dataType === PACKAGE_PDN) {
      pdnId = [selectedData.channelId]
    } else if (info.selectInfo.notChannel) {
      if (selectedData.channelName === "All") {
        const { selectList } = info;
        const currentPackageInfo = selectList.find(item => item.id === selectedData.packageDesignId)
        for (let channelInfo of currentPackageInfo.children) {
          const { id, name } = channelInfo;
          if (['All'].includes(name)) { continue }
          if (name === "PDN") {
            pdnId.push(id)
          } else {
            verificationIds.push(id)
          }
        }
      } else {
        verificationIds = [selectedData.channelId]
      }
    } else {
      verificationIds = [selectedData.id]
    }
    const { url } = this.props.getDownloadSimulationURL({
      menuType,
      options,
      token,
      verificationIds,
      pdnId,
      isPdn: selectedData.dataType === PACKAGE_PDN ? true : false
    });
    const fileName = `${selectedData.name}_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "Package Simulation" })
  }

  downloadResult = (options, token, time) => {
    const { info, currentCheckValues } = this.state;

    if (!info || !info.selectInfo || !info.selectInfo.channelIds) {
      return;
    }

    const _options = currentCheckValues.map(item => item.children).flat(2).filter(item => !!item);
    const noChildrenOptions = currentCheckValues.filter(item => !item.children).map(item => item.name);
    const { url, params } = this.props.getDownloadSimulationURL({
      channelIds: info.selectInfo.channelIds,
      options: [..._options, ...noChildrenOptions],
      token,
      menuType: DOWNLOAD_RESULT
    });

    const { currentProjectName } = this.props;
    const fileName = `${info.selectInfo.name || currentProjectName}_${time}.zip`;

    this.downloadProgress({ url, fileName, params, type: "POST", intervalTime: 1000 })
  }

  isGetDownloadFileKeys() {
    const { menuType, product } = this.props;
    const { info } = this.state;
    if ([S_PARAMETER_DOWNLOAD, DOWNLOAD_RESULT, BMA_CHANNEL_RESULT].includes(menuType)) {
      return true;
    }
    if (product === HIMALAYAS && [SIMULATION_DEBUG, REPORT].includes(menuType)) {
      return true;
    }

    if (product === ROCKY) {
      if ([SIMULATION_DEBUG, MULTIPLE_CHANNEL, S_PARAMETER_DOWNLOAD].includes(menuType)) {
        return true;
      } else if (menuType === PCB_CHANNEL && info.selectInfo.dataType !== PCB_PDN) {
        return true;
      }
    }

    if (product === ANDES_V2 && [EXPERIMENT_S_PARAMETER_DOWNLOAD].includes(menuType)) {
      return true;
    }
    return false;
  }

  downloadProgress = ({ url, type, token, params, fileName, intervalTime = 800 }) => {
    const { downloadMsgInfo, downloadProgressInfo } = this.state;
    const { menuType } = this.props;

    let progress = 0, indexNum = 0;
    this.setState({
      downloadProgressInfo: {
        ...downloadProgressInfo,
        [menuType]: 0
      },
      downloadMsgInfo: {
        ...downloadMsgInfo,
        [menuType]: ""
      },
    })

    this[`${menuType}Time`] = setInterval(() => {
      indexNum += 1;
      if (indexNum % 3 === 0) {
        progress = getDebugDownloadProgress(progress);
        this.setState({
          downloadProgressInfo: {
            ...downloadProgressInfo,
            [menuType]: progress
          },
        })
      }
    }, intervalTime);

    if (url) {
      if (type === "POST" && menuType !== DOWNLOAD_RESULT) {
        this.downloadFilePost({
          url,
          type,
          params,
          token,
          menuType,
          fileName,
          downloadProgressInfo,
          downloadMsgInfo
        })
      } else {
        try {
          if (this.isGetDownloadFileKeys()) {
            this.getDownloadFileKey({
              url,
              menuType,
              downloadMsgInfo,
              downloadProgressInfo,
              type,
              params,
              fileName
            })
            return;
          }
          const a = document.createElement('a');
          // a.download = fileName;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          clearInterval(this[`${menuType}Time`]);
          setTimeout(() => {
            this.setState({
              downloadProgressInfo: {
                ...downloadProgressInfo,
                [menuType]: 100
              },
              downloadMsgInfo: {
                ...downloadMsgInfo,
                [menuType]: 'Prepared file successfully and waiting for download!'
              }
            })
          }, 1000);
          setTimeout(() => {
            this.setState({
              downloadMsgInfo: {
                ...downloadMsgInfo,
                [menuType]: 'Download successful!'
              }
            })
          }, 8000);
        } catch (error) {
          this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType, timeout: 0 });
        }
        setTimeout(() => {
          this.setState({
            downloadProgressInfo: {
              ...downloadProgressInfo,
              [menuType]: -1
            }
          })
        }, 2000);
      }
    } else {
      this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType });
    }
  }

  downloadFailed = ({ downloadProgressInfo, downloadMsgInfo, menuType, message, timeout = 1000 }) => {
    const errorMsg = message && message.msg ? message.msg : (typeof (message) === "string" ? message : "")
    clearInterval(this[`${menuType}Time`]);
    setTimeout(() => {
      this.setState({
        downloadProgressInfo: {
          ...downloadProgressInfo,
          [menuType]: -1
        },
        downloadMsgInfo: {
          ...downloadMsgInfo,
          [menuType]: message ? `Download failed! ${errorMsg || ""}` : 'Download failed!'
        }
      })
    }, timeout);
  }

  downloadFilePost = ({
    url,
    type,
    params,
    token,
    menuType,
    fileName,
    downloadProgressInfo,
    downloadMsgInfo
  }) => {
    getResBlob(url, { type, params, token }).then(blob => {
      clearInterval(this[`${menuType}Time`]);
      if (blob) {
        FileSaver.saveAs(blob, fileName);
        this.setState({
          downloadProgressInfo: {
            ...downloadProgressInfo,
            [menuType]: 100
          },
          downloadMsgInfo: {
            ...downloadMsgInfo,
            [menuType]: 'Download successful!'
          }
        })
      } else {
        this.setState({
          downloadMsgInfo: {
            ...downloadMsgInfo,
            [menuType]: 'Download failed!'
          }
        })
      }
      setTimeout(() => {
        this.setState({
          downloadProgressInfo: {
            ...downloadProgressInfo,
            [menuType]: -1
          }
        })
      }, 1000);
    }, error => {
      this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType });
    });
  }

  getDownloadFileKey = ({
    url,
    menuType,
    downloadMsgInfo,
    downloadProgressInfo,
    type,
    params,
    fileName,
  }) => {
    this.setState({
      downloadMsgInfo: {
        ...downloadMsgInfo,
        [menuType]: 'Preparing file...'
      }
    })
    const { product } = this.props;
    const getFileKeyFn = type === "POST" ? getDownloadFileIdForPost : getDownloadFileId
    getFileKeyFn(url, params).then(async res => {
      if (res && res.status === "success") {
        let fileSize = 0;
        if (product === HIMALAYAS || menuType === MULTIPLE_CHANNEL) {
          try {
            const fileSizeInfo = await getDownloadFileSize(res.key);
            fileSize = fileSizeInfo.size;
          } catch (error) {
            fileSize = 0;
          }
        }
        if (fileSize > 1024 * 1024 * 1024) {
          downloadFileByChunk({
            fileSize,
            downloadKey: res.key,
            fileName,
            downloadMsgInfo,
            menuType,
            downloadProgressInfo,
            updateDownloadProgress: this.updateDownloadProgress,
            clearProgressInterval: this.clearProgressInterval
          })
        } else {
          clearInterval(this[`${menuType}Time`]);
          const a = document.createElement('a');
          // a.download = fileName;
          const token = localStorage.getItem('token');
          a.href = `api/v3/download/file?downloadKey=${res.key}&access_token=${token}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({
            downloadProgressInfo: {
              ...downloadProgressInfo,
              [menuType]: 100
            },
            downloadMsgInfo: {
              ...downloadMsgInfo,
              [menuType]: 'Prepared file successfully and waiting for download!'
            }
          })
          setTimeout(() => {
            this.setState({
              downloadMsgInfo: {
                ...downloadMsgInfo,
                [menuType]: 'Download successful!'
              }
            })
          }, 8000);
          setTimeout(() => {
            this.setState({
              downloadProgressInfo: {
                ...downloadProgressInfo,
                [menuType]: -1
              }
            })
          }, 2000);
        }
      } else {
        this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType, message: res.error || "", timeout: 0 });
      }

    }, error => {
      this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType, message: error, timeout: 0 });
    })
  }

  updateDownloadProgress = ({ downloadProgressInfo, downloadMsgInfo, menuType, progress, message }) => {
    if (downloadProgressInfo) {
      this.setState({
        downloadProgressInfo: {
          ...downloadProgressInfo,
          [menuType]: progress !== undefined ? progress : downloadProgressInfo[menuType]
        }
      })
    }

    if (downloadMsgInfo) {
      this.setState({
        downloadMsgInfo: {
          ...downloadMsgInfo,
          [menuType]: message !== undefined ? message : downloadMsgInfo[menuType]
        }
      })
    }
  }

  clearProgressInterval = (menuType) => {
    clearInterval(this[`${menuType}Time`]);
  }

  changeSelectInfo = async (value, title, type) => {
    const { menuType, productType, getDownloadList } = this.props;
    const { info, downloadList, downloadMenus } = this.state;
    let _info = { ...info }, _downloadList = [...downloadList], _downloadMenus = { ...(downloadMenus || {}) };
    if ([PACKAGE_DEBUG, RESULT, DOWNLOAD_RESULT, MULTIPLE_CHANNEL, PCB_CHANNEL].includes(menuType) || (productType === ROCKY && menuType === S_PARAMETER_DOWNLOAD)) {
      _info = this.props.updateSelectInfo(info, type, value, menuType)
      const isPdn = _info.selectInfo && [PACKAGE_PDN, PCB_PDN].includes(_info.selectInfo.dataType) ? true : false;
      if (menuType === DOWNLOAD_RESULT) {
        const returnInfo = await getDownloadList({ menuType, _info });
        _downloadList = returnInfo.downloadList;
        _downloadMenus = returnInfo.downloadMenus;
      } else {
        _downloadList = getDownloadList ? await this.props.getDownloadList({ menuType, isPdn }) : _downloadList;
      }
    } else {
      const currentSelectInfo = info.selectList.find(item => item.name === value) || {};
      const id = [PCB_PARSING, INTERFACE_SIMULATION].includes(menuType) ? currentSelectInfo.id : (currentSelectInfo.verificationId || currentSelectInfo.id)//cascade impedance s parameter download use id
      _info.selectInfo = { id, name: currentSelectInfo.name }
      if ([PCB_PARSING, INTERFACE_SIMULATION].includes(menuType)) {
        _downloadList = await this.props.getDownloadList({ menuType, vendor: currentSelectInfo.vendor, info: _info })
      }
    }

    this.setState({
      info: _info,
      downloadList: _downloadList,
      downloadMenus: _downloadMenus
    })
  }

  expandChange = async (e, option) => {
    e.stopPropagation();
    const { menuType } = this.props;
    const { checkExpandKeys, currentCheckValues, downloadList } = this.state;
    let _checkExpandKeys = [...checkExpandKeys], _downloadList = [...downloadList];
    if (_checkExpandKeys.includes(option)) {
      _checkExpandKeys = _checkExpandKeys.filter(item => item !== option);
    } else {
      // get experiment list
      const download = downloadList.find(item => item.option === option) || {};
      if (menuType === EXPERIMENT_S_PARAMETER_DOWNLOAD && download.isUpdate) {
        const variablesTables = await this.props.getExperimentsBySweepIdPromise(option);
        _downloadList = this.props.expandChange(option, variablesTables);
        const optionIndex = currentCheckValues.findIndex(item => item.name === option);
        if (optionIndex > -1) {
          // change children
          const findAll = downloadList.find(it => it.option === option);
          const children = findAll && findAll.children ? findAll.children.map(it => it.option) : null;
          currentCheckValues[optionIndex].children = children;
          this.setState({ currentCheckValues, downloadList: _downloadList });
        }
        this.setState({ downloadList: _downloadList });
      }
      _checkExpandKeys.push(option);
    }
    this.setState({
      checkExpandKeys: _checkExpandKeys
    })
  }

  render() {
    const { menuType, debugMenuList = [], title, productType, menuKey, product, enabledChildren } = this.props;
    const { downloadList, info, currentCheckValues, downloadMsgInfo, downloadProgressInfo, checkExpandKeys, downloadMenus } = this.state;
    const width = debugMenuList.length === 1 ? `100%` : `${100 / debugMenuList.length}% - 10px`;
    let contentBottom = product === ROCKY && downloadMsgInfo[menuType] && downloadMsgInfo[menuType].includes("Prepared") ? 100 : product === HIMALAYAS && downloadProgressInfo[menuType] > -1 ? 90 : 70;
    const menuKeys = Object.keys(downloadMenus || {});
    return <div className='aurora-debug-list-content aurora-debug-item-content' style={{ width: `calc(${width})` }}>
      <DebugDownloadMenu
        changeSelectInfo={this.changeSelectInfo}
        info={info}
        title={title}
        menuType={menuType}
        product={product}
      />
      {
        menuType === DOWNLOAD_RESULT ?
          <div className="aurora-download-flex-content"
            style={{ marginBottom: product === HIMALAYAS && downloadProgressInfo[menuType] > -1 ? 90 : 50 }}>
            {(menuKeys || []).map((key, index) => (
              <div
                key={key}
                className="aurora-download-flex-content-item"
                style={{
                  width: menuKeys.length === 1 ? "100%" : null,
                  borderRight: index % 2 !== 0 || index === menuKeys.length - 1 ? 0 : null
                }}>
                <div className="aurora-download-flex-content-item-title">{key}</div>
                <DebugContent
                  menuType={menuType}
                  enabledChildren={enabledChildren || false}
                  currentCheckValues={currentCheckValues}
                  downloadList={downloadMenus[key] || []}
                  checkExpandKeys={checkExpandKeys}
                  expandChange={this.expandChange}
                  changeDebugCheckList={(values) => this.changeDebugCheckList(values, key)}
                  changeDebugCheckChildren={this.changeDebugCheckChildren}
                  contentBottom={0}
                />
              </div>
            ))}
          </div> :
          <DebugContent
            menuType={menuType}
            enabledChildren={enabledChildren || false}
            currentCheckValues={currentCheckValues}
            downloadList={downloadList}
            checkExpandKeys={checkExpandKeys}
            expandChange={this.expandChange}
            changeDebugCheckList={this.changeDebugCheckList}
            changeDebugCheckChildren={this.changeDebugCheckChildren}
            contentBottom={contentBottom}
          />
      }
      <DebugFooter
        menuType={menuType}
        info={info}
        menuKey={menuKey}
        product={product}
        productType={productType}
        debugMenuList={debugMenuList}
        currentCheckValues={currentCheckValues}
        downloadMsgInfo={downloadMsgInfo}
        downloadProgressInfo={downloadProgressInfo}
        DownloadDebugClick={this.DownloadDebugClick}
        type={'circle'}
        width={45}
      />
    </div >
  }
}


export default DebugDownloadContentItem;