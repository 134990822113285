import React, { Component } from "react";
import DebugDownloadItem from './debugDownloadItem'
import './index.css'

class DebugDownloadPanel extends Component {

  render() {
    const { debugMenuList, getDownloadList, getDownloadSimulationURL, updateSelectInfo, type, product, getExperimentsBySweepIdPromise } = this.props;
    return (
      <div className="aurora-debug-download-content">
        {debugMenuList.map(item => {
          return <DebugDownloadItem
            key={item.menuType}
            productType={type}
            {...item}
            menuKey={item.key}
            debugMenuList={debugMenuList}
            getDownloadList={getDownloadList}
            getDownloadSimulationURL={getDownloadSimulationURL}
            updateSelectInfo={updateSelectInfo}
            product={product}
            enabledChildren={item.enabledChildren}
            defaultCheckExpandKeys={item.defaultCheckExpandKeys}
            getExperimentsBySweepIdPromise={getExperimentsBySweepIdPromise}
          />
        })}
      </div>
    )
  }
}
export default DebugDownloadPanel;