import React, { PureComponent } from 'react';
import BottomLine from '../BottomLine';
import './index.css';

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      footerWidth: '100%',
    };
  }

  render() {

    const { changeHeight, height, linePosition, children, className } = this.props;
    return (
      <div className={`footer-monitor ${className}`} style={{ height: height }}>
        <BottomLine
          position={linePosition}
          changeHeight={(height) => changeHeight(height)}
          height={height}
        />
        {children}
      </div>
    )
  }
}

export default Footer;