const ROCKY = 'rocky';
export const LOCATION_CHANGE = `${ROCKY}/location`;
export const LAYER_CHANGE = `${ROCKY}/layer_change`;
export const OPEN_PANEL = `${ROCKY}/open_panel`;
export const CLOSE_PANEL = `${ROCKY}/close_panel`;
export const COLORBY_CHANGE = `${ROCKY}/colorby_change`;
export const SELECTION_CLEAR_ONE = `${ROCKY}/selection_clear_one`;
export const SELECTION_CLEAR = `${ROCKY}/selection_clear`;
export const SELECTED_ALL = `${ROCKY}/selected_all`;
export const CANCEL_SELECTED_ALL = `${ROCKY}/cancel_selected_all`;
export const SHOW_SELECTED = `${ROCKY}/show_selected`;
export const HIDE_SELECTED = `${ROCKY}/hide_selected`;
export const SELECTION_CHECKBOX_CHANGE = `${ROCKY}/selection_checkbox_change`;
export const CLEAN_STATUS = `${ROCKY}/clean_status`;
export const CHANGE_SELECTED_DISPLAY = `${ROCKY}/change_selected_display`;
export const SELECT_CHANGE = `${ROCKY}/select_change`;
export const UPDATE_PCB_REFRESH_STATUS = `${ROCKY}/update_pcb_refresh_status`;
export const CHANGE_SHOW_NAME_STATUS = `${ROCKY}/change_show_name_status`;