import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import LibraryUpload from './libraryUpload';
import './index.css';

class LibraryUploadPanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    if (!this.libraryUploadChild.getUploadingStatus()) {
      this.props.closeLibraryPanel();
    }
  }

  onRef = (ref) => {
    this.libraryUploadChild = ref;
  }


  render() {
    const { title, libraryOptions, checkDisplay } = this.props;
    const content = (
      <Fragment>
        <Panel
          className='library-upload-panel'
          position='panel-center-left'
          title={title}
          zIndex={2000}
          onCancel={this.closeModal}
          width={((libraryOptions && libraryOptions.length) || checkDisplay) ? 560 : 400}
          draggable
          minWidth={300}
          minHeight={180}
        >
          <LibraryUpload
            {...this.props}
            onRef={this.onRef}
          />
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default LibraryUploadPanel;