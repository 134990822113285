import { calcDistanceOfTwoPoints } from '../../calcPointsDistance';
import { calcPointBetweenTwoGivenPoints, calcArc, getAbyArcAndR, getBbyArcAndR, tanA, keepTwoDecimal } from '../math';
// Line
function Line({ start, end }) {
  this.mStart = start; // Boundary
  this.mEnd = end; // Boundary
}

Line.prototype.toPoints = function (r, pointDensity) {
  let pointsList = [...this.mStart.toPoints(r, pointDensity), ...this.mEnd.toPoints(r, pointDensity)];
  const lineW = this.mStart.geom.mDiameter / 2;
  const _r = r + lineW;

  const distance = this.distance(this.mStart.geom, this.mEnd.geom);

  const pointNum = Math.ceil(distance / pointDensity);

  if (pointNum < 2) {
    return pointsList;
  }

  return pointsList.concat(this.linearInterpolation(this.mStart.geom, this.mEnd.geom, _r, pointNum))
  .map(d => [keepTwoDecimal(d[0]), keepTwoDecimal(d[1])]);
};

Line.prototype.toHybridPoints = function (r, pointDensity) {
  let pointsList = [[this.mStart.mX, this.mStart.mY]/* , [this.mEnd.mX, this.mEnd.mY] */];

  const distance = this.distance(this.mStart, this.mEnd);

  const pointNum = Math.ceil(distance / pointDensity);

  if (pointNum < 2) {
    return pointsList;
  }

  return pointsList.concat(this.linearInterpolation(this.mStart, this.mEnd, r, pointNum));
};

// Linear Interpolation
Line.prototype.linearInterpolation = function (start, end, _r, pointNum) {

  let pointsList = []

  const minX = Math.min(start.mX, end.mX);
  const maxX = Math.max(start.mX, end.mX);
  const minY = Math.min(start.mY, end.mY);
  const maxY = Math.max(start.mY, end.mY);
  if (start.mX === end.mX) {
    let len = 0, step = Math.abs(maxY - minY) / pointNum;
    for (let i = 0; i < pointNum; i++) {
      len += step;
      const y = minY + len;
      if (_r === 0) {
        pointsList.push([start.mX, y])
      } else {
        pointsList.push([start.mX - _r, y], [start.mX + _r, y])
      }
    }
  } else if (start.mY === end.mY) {
    let len = 0, step = Math.abs(maxX - minX) / pointNum;
    for (let i = 0; i < pointNum; i++) {
      len += step;
      const x = minX + len;
      if (_r === 0) {
        pointsList.push([x, start.mY], [x, start.mY])
      } else {
        pointsList.push([x, start.mY - _r], [x, start.mY + _r])
      }
    }
  } else {
    let len = 0, step = Math.abs(maxX - minX) / pointNum;
    const arc = Math.abs(calcArc(start, end));
    const a = getAbyArcAndR(arc, _r);
    const b = getBbyArcAndR(arc, _r);
    const tan = tanA(start, end);
    for (let i = 0; i < pointNum; i++) {
      len += step;
      const x = minX + len;
      const y = calcPointBetweenTwoGivenPoints(start, end, x);
      if (_r === 0) {
        pointsList.push([x, y]);
        continue;
      }
      if (tan > 0) {
        pointsList.push([x - b, y + a], [x + b, y - a])
      } else {
        pointsList.push([x + b, y + a], [x - b, y - a])
      }
    }
  }

  return pointsList;
}

Line.prototype.distance = function (start, end) {
  return Math.sqrt(calcDistanceOfTwoPoints({
    mX: start.mX,
    mY: start.mY
  }, {
    mX: end.mX,
    mY: end.mY
  }));
}

export default Line;