import http from '../http';
import JSZip from 'jszip';

/**
 * Get Cascade user library list
 * 2021.11.25
 * @export
 * @returns
 */
export function getCascadeLibraryList() {
  return http.get(`/library/Cascade/list`);
}

/**
 * DELETE Cascade library by id
 * 2021.11.25
 * @export
 * @returns
 */
export function delAndesLibrary(libraryId, productType = "Cascade") {
  return http.post(`/library/${productType}/delete`, {
    id: libraryId
  });
}

/**  
 * upload Cascade library
 * 2021.11.25
 * @param {Object} library {
  "fileType": "string",
  "libraries": [
    {
      "fileName": "string",
      "name": "string",
      "type": "string"
    }
  ],
  "typeName": "string"
}
 * @export
 * @return
*/
export function uploadCascadeLibrary({ files, fileType, typeName, config, metadata, tags }, productType = "Cascade") {
  let formData = new FormData();
  //ibis/spice/vector file
  if (fileType === 'file') {
    let zip = new JSZip();
    for (let file of files) {
      zip.file(file.fileName, file.file);
    }
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 }
    }, metadata).then(blob => {
      formData.append('file', new File([blob], 'file.zip'));
      formData.append('typeName', typeName);
      formData.append('fileType', fileType);
      formData.append('tag', tags)
      return http.post(`/library/${productType}/upload`, formData, config, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
    });
  } else if (['zip', "folder"].includes(fileType)) {
    files.forEach(file => {
      formData.append('file', file.file);
    })
    formData.append('typeName', typeName);
    formData.append('fileType', fileType);
    formData.append('tag', tags)
    return http.post(`/library/${productType}/upload`, formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }
}

/** 
 * Save library data
 * {object} data { id, name, version , type, config :{ ...} }
 * 2021.11.26
*/

export function saveCascadeLibraryData(data, productType = "Cascade") {
  return http.post(`/library/${productType}/data/update`, { ...data });
}


export function updateCascadeLibraryData({ data, config }) {
  return http.post(`/library/Cascade/data/update`, data, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};


/** 
 * Get library file data
 * 2021.11.29
*/

export function getCascadeLibraryFileInfo(libraryId) {
  return http.get(`/library/Cascade/resource?libraryId=${libraryId}`)
}

/** 
 * Get library data
 * 2021.11.29
*/

export function getCascadeLibraryDataInfo(libraryId) {
  return http.get(`/library/Cascade/detail?id=${libraryId}`)
}

export function deleteCascadeLibrary({ libraryId, partName }) {
  return http.post(`/library/Cascade/delete`, { id: libraryId, partName })
}

export function getDefaultLibrary() {
  return http.get(`/Cascade/library/default`)
}

export function updateDefaultLibrary({ libraryId, libraryType, name, product }) {
  return http.post(`/Cascade/library/default`, { libraryId, libraryType, name, product })
}

export function getLibrarySetting(projectId) {
  return http.get(`/Cascade/library/settings?projectId=${projectId}`)
}

export function updateLibrarySetting({ librarySettings, projectId }) {
  return http.post(`/Cascade/library/settings`, { librarySettings, projectId })
}

export function getPinMap() {
  return http.get(`part/library/Cascade/map`)
}

export function getPartDetail(id) {
  return http.get(`part/library/Cascade/detail?id=${id}`)
}

export function deletePart(id) {
  return http.post(`part/library/Cascade/delete`, { id })
}

export function updatePart({ designId, id, partName, type, value }) {
  return http.post(`/part/library/Cascade/update`, {
    designId,
    id,
    partName,
    type,
    value
  })
}