import React, { Fragment, lazy, Suspense, Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../services/history';
import cookie from 'react-cookies';
import { Layout } from 'antd';
import Header from '@/components/Header';
import { connect } from 'react-redux';
import { logoutAction, userLoginInfo } from '@/pages/Login/action';
import LoginPage from '@/pages/Login';
import Download from '@/pages/DownloadAurora';
import HomePage from '@/pages/Home/auroraHome';
import SierraPage from "@/pages/Sierra";
import AndesPage from "@/pages/Andes";
import PdnPage from "@/pages/PDN";
import AndesV2Page from "@/pages/Andes_v2";
import HimalayasPage from "@/pages/Himalayas";
import permissionData, { getPageList } from '@/services/helper/data/permissionData';
import userData from '@/services/helper/data/userData';
import { getUserPermissionsPromise, getSettings, getInstallerShowPromise, getPageTypePromise } from '../services/roleCtrl';
import { getUser, isAuthed } from '../services/api/auth';
import { userDefaultSettings } from '@/services/userDefaultSetting/userDefaultSettingCtrl';
import CascadePage from '@/pages/Cascade';
import 'antd/dist/reset.css'
import '../publicCss/antd.css'
const AdminPage = lazy(() => import("@/pages/Admin"));
const DesignPage = lazy(() => import("@/pages/Design"));
const UnLoginHomePage = lazy(() => import("@/pages/Home/auroraUnlogin"));
const CCCPage = lazy(() => import("@/pages/CCC/CCCPage"));
const RockyPage = lazy(() => import("@/pages/Rocky"));

function initCookie() {
  cookie.save('translationMode', 'OFF', {
    maxAge: 7 * 24 * 60 * 60,
    path: '/'
  });
}

const PrivateRoute = ({ component: Comp, authed, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authed ? (
        <Comp {...props} {...rest} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

const HomeRoute = ({ component: Comp, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Comp {...props} {...rest} />
    }
  />
);

const getComponent = (isAdmin, authed, props) => {
  return <Fragment>
    {isAdmin && <PrivateRoute authed={authed} path='/admin' component={AdminPage} />}
    {permissionData.hasXAndes() && <PrivateRoute authed={authed} path='/project' component={DesignPage} />}
    {permissionData.hasPDN() && <PrivateRoute authed={authed} path='/fastpi' component={PdnPage} />}
    {permissionData.hasSierra() && <PrivateRoute authed={authed} path='/sierra' component={SierraPage} />}
    {permissionData.hasCCC() && <PrivateRoute authed={authed} path='/ccc' component={CCCPage} />}
    {permissionData.hasAndes() && <PrivateRoute authed={authed} path='/andes_v1' component={AndesPage} />}
    {permissionData.hasAndesV2() && <PrivateRoute authed={authed} path='/andes' component={AndesV2Page} />}
    {permissionData.hasRocky() && <PrivateRoute authed={authed} path='/rocky' component={RockyPage} />}
    {permissionData.hasCascade() && <PrivateRoute authed={authed} path='/cascade' component={CascadePage} />}
    {permissionData.hasHimalayas() && <PrivateRoute authed={authed} path='/himalayas' component={HimalayasPage} />}
  </Fragment>
}

const getHomePage = (version, authed, isAdmin, pageStyle) => {
  if (!authed || isAdmin) {
    return <HomeRoute exact path='/' component={UnLoginHomePage} version={version} pageStyle={pageStyle} />
  } else {
    return <HomeRoute exact path='/' component={HomePage} version={version} pages={getPageList()} pageStyle={pageStyle} />
  }
}

class BasicRouter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false
    }
  }

  componentDidMount() {
    this.getPageType()
    if (isAuthed()) {
      this.getUserInfo();
    }
    this.getPermissions();
    this.getInstaller();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authed && this.props.authed) {
      this.getPageType()
      this.getUserInfo();
      this.getPermissions();
    }
  }

  getInstaller = async () => {
    const res = await getInstallerShowPromise();
    this.setState({
      installerVisible: res
    })
  }

  getUserInfo = () => {
    getUser().then(response => {
      const { email, username } = response
      this.props.userInfo(email, username);
      userData.setInfo(response);
      userDefaultSettings.getSettings()
    })
  }

  getPageType = () => {
    getPageTypePromise().then(res => {
      this.setState({
        pageStyle: res.HomePageStyle
      })
      permissionData.savePageType(res);
    })
  }

  getPermissions = () => {
    getUserPermissionsPromise().then(res => {
      if (res) {
        const permissions = res.map(perm => perm.name);
        permissionData.saveList(permissions);
        this.setState({
          isAdmin: permissionData.isAdmin()
        })
      }
    })
    getSettings().then(res => {
      permissionData.saveSettings(res);
      initCookie();
    })
  }

  render() {
    const { version, authed } = this.props;
    const { isAdmin, installerVisible, pageStyle } = this.state;
    const permissions = [];
    return (
      <Router history={history}>
        <Layout>
          <Header {...this.props} permissions={permissions} authed={authed} installerVisible={installerVisible} pageStyle={pageStyle} />
          <Suspense fallback={null}>
            <Switch>
              {getHomePage(version, authed, isAdmin, pageStyle)}
              <Route path='/login' component={LoginPage} />
              {installerVisible ? <Route path='/download' component={Download} /> : null}
              {getComponent(isAdmin, authed, this.props)}
            </Switch>
          </Suspense>
        </Layout>
      </Router>
    )
  }
}

const mapState = (state) => {
  const { LoginReducer: { login, userName, pageType, email } } = state;
  return {
    authed: login,
    userName,
    pageType,
    email
  }
};

const mapDispatch = (dispatch) => ({
  logout() {
    dispatch(logoutAction());
  },
  userInfo(email, userName) {
    dispatch(userLoginInfo(email, userName));
  }
})

export default connect(mapState, mapDispatch)(BasicRouter);