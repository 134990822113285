import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { CloseOutlined } from '@ant-design/icons';
import { Input, Tag, Select, Divider, Checkbox, Menu } from 'antd';
import { CASCADE, FASTPI, SIERRA } from '@/constants/pageType';
import { versionUpdate } from '@/services/helper/dataProcess';
import { getScreenTop, getScreenLeft } from '@/services/helper/htmlElement';
import PMICPinMap from './pmicPinMap';
import auroraDBJson from '../../services/Designs/auroraDbData';
import { TRANSISTOR } from '../../constants/componentType';
import _ from 'lodash'
import './index.css';

const Option = Select.Option;
const boxShadow = 'rgba(9, 128, 247, 0.14) 0px 0px 0px 2px, rgba(13, 151, 249, 0.1) 0px 0px 7px 0px';
const CUSTOM = 'Custom Reference Setting', PMIC = 'PMIC', DRIVER = 'Driver', SWITCH = 'Power Switch', MR = 'Multi Pin Resistor', REPEATER_PIN_MAP = "Repeater";
const CASCADE_KEY_LIST = [CUSTOM, PMIC, SWITCH, MR, DRIVER], SIERRA_KEY_LIST = [CUSTOM, REPEATER_PIN_MAP];
class Classification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      resValue: '',
      indValue: '',
      capValue: '',
      jumperValue: '',
      ferriteValue: '',
      diodeValue: '',
      tranValue: '',
      loadValue: '',
      connectorValue: '',
      className: null,
      COMP_PREFIX_LIB: {},
      partNames: [],
      searchPart: '',
      apply: true,
      buckOpen: false,
      transistor: [],
      showKey: CUSTOM,
      multiPort: [],
      keyList: [CUSTOM],
      animationClass: ''
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const { pcbId, product } = this.props;
    if (pcbId) {
      this.setDefaultData();
    }
    this.setState({
      keyList: product === CASCADE ? CASCADE_KEY_LIST : (product === SIERRA ? SIERRA_KEY_LIST : [CUSTOM])
    })
    this.props.onRef(this);
    document.addEventListener('click', this.buckClickListener, true)
  }

  componentDidUpdate(prevProps) {
    const { pcbId, update, isUpdateSetting } = this.props;
    if (pcbId !== prevProps.pcbId
      || (update && update !== prevProps.update)
      || (isUpdateSetting && isUpdateSetting !== prevProps.isUpdateSetting)) {
      this.setDefaultData();
      console.log("Part library update component setting")
      this.props.updateUpdateSettingStatus(false)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.buckClickListener, true)
  }

  buckClickListener = (e) => {
    const { target } = e;
    let buckRef = document.getElementById('component-PMIC-buck-content');
    let buckDropRef = document.getElementById('component-stuff-buck-content');
    let buckDorpSelect = document.getElementsByClassName('component-buck-list-select-dropdown');

    const targetInRef = (buckDorpSelect, target) => {
      for (let selectRef of buckDorpSelect) {
        if (selectRef.contains(target)) {
          return true;
        }
      }
      return false;
    }

    if (this.state.buckOpen && (
      !(buckRef && buckRef.contains(target))
      && !(buckDropRef && buckDropRef.contains(target))
      && !(buckDorpSelect && targetInRef(buckDorpSelect, target))
    )) {
      this.dragBuckClose()
    }
  }

  getBuckNewPosition = () => {
    let buckRef = document.getElementById('component-PMIC-buck-content');
    let top = 0, left = 0, width = 200;
    if (buckRef) {
      const { panelStateRef } = this.props;
      top = getScreenTop(buckRef);
      left = getScreenLeft(buckRef);
      if (panelStateRef) {
        top = top + panelStateRef.getPanelTop() + buckRef.offsetHeight - 30;
        left = left + panelStateRef.getPanelLeft();
      }
      width = buckRef.offsetWidth ? buckRef.offsetWidth : 200
    }
    return { top, left, width }
  }

  dragBuckClose = () => {
    if (this.state.buckOpen) {
      this.setState({
        buckOpen: false,
        className: null
      })
    }
  }

  setDefaultData = async () => {
    const { settingStore, pcbId, updateLibraryMenu } = this.props;
    if (pcbId) {
      const componentSetting = await settingStore.getSetting({ designId: pcbId, updateLibraryMenu });
      const compPrefixLib = componentSetting && componentSetting.compPrefixLib ? componentSetting.compPrefixLib : {}
      await this.props.setDefaultData()
      const partNames = auroraDBJson.getPartNames(pcbId);
      this.setState({
        partNames: partNames,
        COMP_PREFIX_LIB: JSON.parse(JSON.stringify(compPrefixLib)),
        multiPort: JSON.parse(JSON.stringify(compPrefixLib.multiPortRes || [])),
      }, () => {
        this.props.changeLoading(false);
        this.getBuckComponents();
      })
    }
  }

  getBuckComponents = () => {
    const { pcbId } = this.props;
    const allComponents = auroraDBJson.getComponentsByType(pcbId, TRANSISTOR);
    const transistor = allComponents.map(item => item.name);
    this.setState({
      transistor
    })
  }

  inputClick = (e, type, ref) => {
    e.stopPropagation();
    if (ref) {
      ref.focus();
    }
    this.setState({
      className: type
    })
  }

  saveNewKey = async (showKey, entry) => {
    const { product } = this.props;
    const { showKey: prevKey, } = this.state;
    //update setting
    if (product === SIERRA) {
      if (prevKey === CUSTOM) {
        await this.saveCompPrefix()
      } else if (prevKey !== CUSTOM && this.props.pinMapRef) {
        await this.props.pinMapRef.savePinMap();
      }
    } else if (product === CASCADE) {
      if (prevKey !== CUSTOM && this.props.pinMapRef) {
        await this.props.pinMapRef.savePinMap();
      }
      if (prevKey === CUSTOM) {
        this.setComponentType()
      }
    }
    this.setState({
      showKey,
      animationClass: `component-animation-classfication-${entry}`
    }, () => {
      this.props.updateComponentSettingStatus();
      setTimeout(() => {
        this.setState({
          animationClass: ''
        })
      }, 500)
    })
  }

  setComponentType = async () => {
    const { COMP_PREFIX_LIB } = this.state;
    const { settingStore, pcbId, updateLibraryMenu } = this.props;
    const componentSetting = await settingStore.getSetting({ designId: pcbId, updateLibraryMenu });
    const compPrefixLib = componentSetting && componentSetting.compPrefixLib ? JSON.parse(JSON.stringify(componentSetting.compPrefixLib)) : {};
    if (!_.isEqual(COMP_PREFIX_LIB, compPrefixLib)) {
      auroraDBJson.setComponentsType(pcbId, { compPrefixLib: COMP_PREFIX_LIB })
      this.getBuckComponents()
    }
  }

  selectMenu = ({ key }) => {
    this.saveNewKey(key, 'right')
  }

  inputChange = (e, valueType) => {
    e.stopPropagation();
    let value = e.target.value;
    if (value === ',') {
      this.setState({
        resValue: '',
        indValue: '',
        capValue: '',
        jumperValue: '',
        ferriteValue: '',
        loadValue: '',
        tranValue: '',
        diodeValue: '',
        connectorValue: '',
        error: ''
      })
      return;
    }
    this.setState({
      error: '',
      [valueType]: value
    })
  }

  onPressEnter = (e, type) => {
    e.stopPropagation();
    const { COMP_PREFIX_LIB } = this.state;
    let value = e.target.value;
    const prev = COMP_PREFIX_LIB[type] ? COMP_PREFIX_LIB[type] : []
    let prevList = [...prev]
    if (!value) {
      return;
    }

    if (value) {
      //Whether the value is legal.
      const errorWords = value.match(/[^0-9a-zA-Z_-]+/g);
      if (errorWords && errorWords.length > 0) {
        this.setState({
          error: 'Value may only contain the following characters: numbers, letters, underscores, minus.',
          resValue: '',
          indValue: '',
          capValue: '',
          loadValue: '',
          jumperValue: '',
          ferriteValue: '',
          tranValue: '',
          diodeValue: '',
          connectorValue: '',
        });
        return;
      }
    }

    if (prevList.includes(value)) {
      return;
    }
    let newList = [...prevList, value];
    let compPrefixLib = this.state.COMP_PREFIX_LIB;
    compPrefixLib[type] = newList;
    this.props.changeSave(true);
    this.setState({
      resValue: '',
      indValue: '',
      capValue: '',
      loadValue: '',
      jumperValue: '',
      ferriteValue: '',
      tranValue: '',
      diodeValue: '',
      connectorValue: '',
      error: '',
      COMP_PREFIX_LIB: compPrefixLib,
    }, () => {
      if (type === 'Transistor') {
        this.getBuckComponents()
      }
      if (type === 'Res') {
        this.getNewMultiRes()
      }
    })
  }

  handleDelete = (value, type) => {//delete item
    const { COMP_PREFIX_LIB } = this.state;
    const prev = COMP_PREFIX_LIB[type] ? COMP_PREFIX_LIB[type] : []
    let prevList = [...prev]

    if (!value) {
      return;
    }

    let newList = prevList.filter(item => item !== value);
    let compPrefixLib = this.state.COMP_PREFIX_LIB;
    compPrefixLib[type] = newList;
    this.props.changeSave(true);
    this.setState({
      COMP_PREFIX_LIB: compPrefixLib
    }, () => {
      if (type === 'Res') {
        this.getNewMultiRes()
      }
    })
  }

  getNewMultiRes = () => {
    const { getMultiPortResPart, pcbId } = this.props;
    if (getMultiPortResPart) {
      const { COMP_PREFIX_LIB } = this.state;
      const _COMP_PREFIX_LIB = { ...COMP_PREFIX_LIB };
      auroraDBJson.setComponentsType(pcbId, { compPrefixLib: _COMP_PREFIX_LIB })
      const newMultiPort = getMultiPortResPart(pcbId);
      _COMP_PREFIX_LIB.multiPortRes = [...newMultiPort];
      this.setState({
        COMP_PREFIX_LIB: _COMP_PREFIX_LIB,
        multiPort: newMultiPort
      })
    }
  }

  onKeyDown = (e, type) => {
    if (e.keyCode === 188) {
      this.onPressEnter(e, type);
    }
  }

  inputBlur = (e, type) => {
    this.setState({
      className: null
    })
    this.onPressEnter(e, type);
  }

  changeSelect = (value, type, merge = false) => {
    const { COMP_PREFIX_LIB, searchPart } = this.state;
    let error = '', list = [...value];
    if (searchPart || merge) {
      const oldPart = COMP_PREFIX_LIB[type] || [];
      const {
        linearSwitch = [],
        linearRegulator = [],
        switchingRegulator = [],
        specialized = [],
        powerSwitch = [],
        driver = [],
        Connector = [],
        Jumper = [],
        Repeater = [],
        IC = []
      } = COMP_PREFIX_LIB;
      let selected = list.filter(item => !oldPart.includes(item));
      const searchStr = new RegExp(` \\(${searchPart}\\)$`, 'ig');
      selected = selected.map(item => {
        if (item.match(searchStr)) {
          return item.replace(searchStr, '');
        }
        return item;
      }).filter(item => {
        if (linearRegulator.includes(item)) {
          error = `${item} already exists in Linear Regulator`;
          return false;
        } else if (linearSwitch.includes(item)) {
          error = `${item} already exists in Linear and Switching`;
          return false;
        } else if (switchingRegulator.includes(item)) {
          error = `${item} already exists in Switching Regulator`;
          return false;
        } else if (specialized.includes(item)) {
          error = `${item} already exists in Specialized`;
          return false;
        } else if (powerSwitch.includes(item)) {
          error = `${item} already exists in Power Switch`;
          return false;
        } else if (driver.includes(item)) {
          error = `${item} already exists in Driver`;
          return false;
        } else if (Repeater.includes(item)) {
          error = `${item} already exists in Repeater`;
          return false;
        }
        else if (Jumper.includes(item)) {
          error = `${item} already exists in Jumper`;
          return false;
        }
        else if (Connector.includes(item)) {
          error = `${item} already exists in Connector`;
          return false;
        } else if (IC.includes(item)) {
          error = `${item} already exists in IC`;
          return false;
        } else {
          // Filter Chinese
          let errorWords = /[\u4e00-\u9fa5]+/g;
          if (errorWords.test(item)) {
            return false
          }
        }
        return true
      })
      list = [...oldPart, ...selected]
    }
    this.props.changeSave(true);
    this.setState({
      COMP_PREFIX_LIB: { ...COMP_PREFIX_LIB, [type]: list },
      error
    })
    return error;
  }

  checkPartNumber = (part, type) => {
    const { COMP_PREFIX_LIB } = this.state;
    const {
      linearSwitch = [],
      linearRegulator = [],
      switchingRegulator = [],
      specialized = [],
      powerSwitch = [],
      driver = [],
      Repeater = [],
      Connector = [],
      Jumper = [],
      IC = []
    } = COMP_PREFIX_LIB;

    let error = ''
    if (linearRegulator.includes(part)) {
      error = `${part} already exists in Linear Regulator`;
    } else if (linearSwitch.includes(part)) {
      error = `${part} already exists in Linear and Switching`;
    } else if (switchingRegulator.includes(part)) {
      error = `${part} already exists in Switching Regulator`;
    } else if (specialized.includes(part)) {
      error = `${part} already exists in Specialized`;
    } else if (powerSwitch.includes(part)) {
      error = `${part} already exists in Power Switch`;
    } else if (driver.includes(part)) {
      error = `${part} already exists in Driver`;
    } else if (Repeater.includes(part)) {
      error = `${part} already exists in Repeater`;
    } else if (Connector.includes(part)) {
      error = `${part} already exists in Connector`;
    } else if (Jumper.includes(part)) {
      error = `${part} already exists in Jumper`;
    } else if (IC.includes(part)) {
      error = `${part} already exists in IC`;
    }
    return error;
  }

  onApplyChange = (e) => {
    this.setState({
      apply: e.target.checked
    })
  }

  checkClassfication = (dataSource = []) => {
    const { COMP_PREFIX_LIB } = this.state;
    const usedType = [...new Set(dataSource.map(item => item.type))]
    const _COMP_PREFIX_LIB = { ...COMP_PREFIX_LIB };
    for (let type of usedType) {
      if (type === 'buckConverter') {
        _COMP_PREFIX_LIB['discreteBuckConverter'] = dataSource.filter(item => item.type === type).map(item => item.partNumber.split(' - '))
      } else {
        _COMP_PREFIX_LIB[type] = dataSource.filter(item => item.type === type).map(item => item.partNumber)
      }
    }
    this.setState({
      COMP_PREFIX_LIB: _COMP_PREFIX_LIB
    })
  }

  updateClassification = (list, type) => {
    const { COMP_PREFIX_LIB } = this.state;
    let _COMP_PREFIX_LIB = { ...COMP_PREFIX_LIB };
    _COMP_PREFIX_LIB[type] = list;
    this.setState({
      COMP_PREFIX_LIB: _COMP_PREFIX_LIB
    })
  }


  saveCompPrefix = async (_save = false, saveModel = false, prevPcbId) => {
    const { pcbId, projectId, product, settingStore, tableStore } = this.props;
    const { COMP_PREFIX_LIB, apply } = this.state;
    const _COMP_PREFIX_LIB = { ...COMP_PREFIX_LIB };
    const _pcbId = prevPcbId || pcbId;
    let save = false;

    if (product === SIERRA) {
      const { newKeys, deleteKeys, save: _isSave } = settingStore.compareSetting(_pcbId, _COMP_PREFIX_LIB, true);
      save = _isSave;
      if (tableStore.updateTableByPrefix &&
        ((newKeys && Object.keys(newKeys).length) || (deleteKeys && Object.keys(deleteKeys).length))) {
        await tableStore.updateTableByPrefix({ pcbId, newKeys, deleteKeys, COMP_PREFIX_LIB: _COMP_PREFIX_LIB })
      }
    } else {
      save = settingStore.compareSetting(_pcbId, _COMP_PREFIX_LIB);
    }
    const version = await settingStore.getVersion(_pcbId);
    if (save || _save || saveModel) {
      let newVersion = version;
      if (save || _save) {
        newVersion = versionUpdate(version);
      }
      let componentSetting = { compPrefixLib: _COMP_PREFIX_LIB, version: newVersion };
      if (product === SIERRA) {
        componentSetting.pinMap = await settingStore.getCompPinMap(_pcbId);
      }
      const newSetting = { designId: _pcbId, componentSetting }
      this.props.settingStore.updateSetting([newSetting]);
      if (apply && product !== SIERRA) {
        await this.props.settingStore.applyAllSetting(_pcbId, projectId, _COMP_PREFIX_LIB);
      }
      return true
    }
    return false
  }

  openBuckSelect = (e, type) => {
    e && e.stopPropagation();
    this.setState({
      buckOpen: true,
      className: type
    })
  }

  deleteBuck = (index) => {
    const { COMP_PREFIX_LIB } = this.state;

    let discreteBuckConverter = COMP_PREFIX_LIB.discreteBuckConverter || [];

    discreteBuckConverter = discreteBuckConverter.filter((item, _index) => index !== _index);

    this.setState({
      COMP_PREFIX_LIB: {
        ...COMP_PREFIX_LIB,
        discreteBuckConverter
      }
    })
  }

  customArray = () => {
    const { COMP_PREFIX_LIB: { Res, Ind, Cap, Jumper, Ferrite, Diode, Transistor },
      resValue, indValue, capValue, jumperValue, ferriteValue, diodeValue, tranValue } = this.state;
    const { product = FASTPI } = this.props;
    let array = [
      { type: 'Res', data: Res, value: resValue, valueType: 'resValue', name: 'Resistor', currentRef: 'resRef', key: 'resInput' },
      { type: 'Ind', data: Ind, value: indValue, valueType: 'indValue', name: 'Inductor', currentRef: 'indRef', key: 'indInput' },
      { type: 'Cap', data: Cap, value: capValue, valueType: 'capValue', name: 'Capacitor', currentRef: 'capRef', key: 'capInput' },
    ]
    if (product === CASCADE) {
      array.push({ type: 'Jumper', data: Jumper, value: jumperValue, valueType: 'jumperValue', name: 'Jumper', currentRef: 'jumperRef', key: 'jumperInput' })
      array.push({ type: 'Ferrite', data: Ferrite, value: ferriteValue, valueType: 'ferriteValue', name: 'Ferrite', currentRef: 'ferriteRef', key: 'ferriteInput' });
      array.push({ type: 'Diode', data: Diode, value: diodeValue, valueType: 'diodeValue', name: 'Diode', currentRef: 'diodeRef', key: 'diodeInput' })
      array.push({ type: 'Transistor', data: Transistor, value: tranValue, valueType: 'tranValue', name: 'Transistor', currentRef: 'tranRef', key: 'tranInput' })
    }
    return array;
  }

  customRLCItem = () => {
    const { error, className } = this.state;
    const RLCArray = this.customArray();
    return RLCArray.map(rlcItem => {
      let { type, data = [], value, name, currentRef, key, valueType } = rlcItem;
      return <div className='component-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key={name}>
        <span className='component-RLC-title'>{name} </span>
        <div className='component-RLC-item-content' onClick={(e) => this.inputClick(e, type, this[currentRef])} style={className === type ? { borderColor: '#40a9ff', boxShadow: boxShadow } : {}}>
          <ul className='component-RLC-tags'>
            {data.map((item, index) =>
              <li className='component-RLC-item-tag' key={item}>
                <Tag
                  key={item}
                  closable={true}
                  onClose={() => this.handleDelete(item, type)}
                >
                  {item}
                </Tag>
              </li>
            )}
            <li className='component-RLC-item-input-box' key={key}>
              <Input
                className='component-RLC-item-input'
                value={value}
                style={{ width: value ? value.length * 12 : '.75em' }}
                ref={(input) => { this[currentRef] = input; }}
                onChange={(e) => this.inputChange(e, valueType)}
                onPressEnter={(e) => this.onPressEnter(e, type)}
                onBlur={(e) => this.inputBlur(e, type)}
                onKeyDown={(e) => this.onKeyDown(e, type)}
              />
            </li>
          </ul>
        </div>
      </div>
    })
  }

  customLoadItem = () => {
    const { COMP_PREFIX_LIB: { Load }, loadValue } = this.state;
    const { error, className } = this.state;
    const loadArray = [{ type: 'Load', data: Load, value: loadValue, valueType: 'loadValue', name: 'Load', currentRef: 'loadRef', key: 'loadInput' },];
    return loadArray.map(rlcItem => {
      let { type, data = [], value, name, currentRef, key, valueType } = rlcItem;
      return <div className='component-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key={name}>
        <span className='component-RLC-title'>{name} </span>
        <div className='component-RLC-item-content' onClick={(e) => this.inputClick(e, type, this[currentRef])} style={className === type ? { borderColor: '#40a9ff', boxShadow: boxShadow } : {}}>
          <ul className='component-RLC-tags'>
            {data.map((item, index) =>
              <li className='component-RLC-item-tag' key={item}>
                <Tag
                  key={item}
                  closable={true}
                  onClose={() => this.handleDelete(item, type)}
                >
                  {item}
                </Tag>
              </li>
            )}
            <li className='component-RLC-item-input-box' key={key}>
              <Input
                className='component-RLC-item-input'
                value={value}
                style={{ width: value ? value.length * 12 : '.75em' }}
                ref={(input) => { this[currentRef] = input; }}
                onChange={(e) => this.inputChange(e, valueType)}
                onPressEnter={(e) => this.onPressEnter(e, type)}
                onBlur={(e) => this.inputBlur(e, type)}
                onKeyDown={(e) => this.onKeyDown(e, type)}
              />
            </li>
          </ul>
        </div>
      </div>
    })
  }

  customPMICArray = () => {
    const { COMP_PREFIX_LIB: {
      linearSwitch = [],
      linearRegulator = [],
      switchingRegulator = [],
      specialized = [],
      discreteBuckConverter = [],
      powerSwitch = [],
      driver = [] }, partNames } = this.state;
    let PMICArray = [
      { type: 'linearRegulator', data: linearRegulator, name: 'Linear Regulator' },
      { type: 'switchingRegulator', data: switchingRegulator, name: 'Switching Regulator' },
      { type: 'linearSwitch', data: linearSwitch, name: 'Linear and Switching' },
      { type: 'specialized', data: specialized, name: 'Specialized' },
      { type: 'discreteBuckConverter', data: discreteBuckConverter, name: 'Discrete Buck Converter', render: this.discreteBuckRender }
    ]
    let _partNames = partNames.filter(part => ![
      ...linearSwitch,
      ...specialized,
      ...linearRegulator,
      ...switchingRegulator,
      ...powerSwitch,
      ...driver
    ].includes(part));
    PMICArray = PMICArray.map(item => {
      return { ...item, options: [..._partNames] }
    })
    return PMICArray;
  }

  customDriverArray = () => {
    const { COMP_PREFIX_LIB: {
      linearSwitch = [],
      linearRegulator = [],
      switchingRegulator = [],
      specialized = [],
      powerSwitch = [],
      driver = [] }, partNames } = this.state;
    let driverArray = [{ type: 'driver', data: driver, name: 'Driver' }]
    let _partNames = partNames.filter(part => ![
      ...linearSwitch,
      ...specialized,
      ...linearRegulator,
      ...switchingRegulator,
      ...powerSwitch,
      ...driver
    ].includes(part));
    driverArray = driverArray.map(item => {
      return { ...item, options: [..._partNames] }
    })
    return driverArray;
  }

  customSwitchArray = () => {
    const { COMP_PREFIX_LIB: {
      linearSwitch = [],
      linearRegulator = [],
      switchingRegulator = [],
      specialized = [],
      powerSwitch = [],
      driver = [] }, partNames } = this.state;
    let switchArray = [
      { type: 'powerSwitch', data: powerSwitch, name: 'Power Switch' },
    ]
    let _partNames = partNames.filter(part => ![
      ...linearSwitch,
      ...specialized,
      ...linearRegulator,
      ...switchingRegulator,
      ...powerSwitch,
      ...driver
    ].includes(part));
    switchArray = switchArray.map(item => {
      return { ...item, options: [..._partNames] }
    })
    return switchArray;
  }

  selectBuckComp = (value, id, index) => {
    const { COMP_PREFIX_LIB } = this.state;

    let discreteBuckConverter = COMP_PREFIX_LIB.discreteBuckConverter || [];

    if (id === 'empty') {
      discreteBuckConverter.push(index === 0 ? [value, ''] : ['', value]);
    } else {
      discreteBuckConverter[id][index] = value;
    }

    this.setState({
      COMP_PREFIX_LIB: {
        ...COMP_PREFIX_LIB,
        discreteBuckConverter
      }
    })
  }

  getUnusedTran = () => {
    const { COMP_PREFIX_LIB: { discreteBuckConverter }, transistor } = this.state;
    const used = discreteBuckConverter.flat(2);
    return transistor.filter(item => !used.includes(item))
  };

  getTransistor = () => {
    return this.state.transistor || [];
  }

  saveBuckConverter = (converter) => {
    const { COMP_PREFIX_LIB } = this.state;

    let discreteBuckConverter = COMP_PREFIX_LIB.discreteBuckConverter || [];

    this.setState({
      COMP_PREFIX_LIB: {
        ...COMP_PREFIX_LIB,
        discreteBuckConverter: [...discreteBuckConverter, converter]
      }
    })
  }

  customPMICItem = () => {
    const PMICArray = this.customPMICArray();
    return <PMICPinMap
      {...this.props.pinMapProps}
      apply={this.state.apply}
      classType={PMIC}
      optionArray={PMICArray}
      changeSelect={this.changeSelect}
      checkPartNumber={this.checkPartNumber}
      multiPort={[]}
      getTransistor={this.getTransistor}
      saveBuckConverter={this.saveBuckConverter}
      checkClassfication={this.checkClassfication}
    />
  }

  customDriverItem = () => {
    const driverArray = this.customDriverArray();
    return <PMICPinMap
      {...this.props.pinMapProps}
      apply={this.state.apply}
      classType={DRIVER}
      optionArray={driverArray}
      changeSelect={this.changeSelect}
      checkPartNumber={this.checkPartNumber}
      multiPort={[]}
      checkClassfication={this.checkClassfication}
    />
  }

  customSwitchItem = () => {
    const switchArray = this.customSwitchArray();
    return <PMICPinMap
      {...this.props.pinMapProps}
      apply={this.state.apply}
      classType={SWITCH}
      optionArray={switchArray}
      changeSelect={this.changeSelect}
      checkPartNumber={this.checkPartNumber}
      multiPort={[]}
      checkClassfication={this.checkClassfication}
    />
  }

  customMRItem = () => {
    return <PMICPinMap
      {...this.props.pinMapProps}
      apply={this.state.apply}
      classType={MR}
      multiPort={this.state.multiPort}
      COMP_PREFIX_LIB={this.state.COMP_PREFIX_LIB}
    />
  }

  discreteBuckRender = (rlcItem) => {
    const { type, data, name } = rlcItem;
    const { error, className, buckOpen } = this.state;
    const { top, left, width } = this.getBuckNewPosition();
    return (
      <div className='component-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key={name}>
        <span className='component-RLC-title'>{name} </span>
        <div
          className='component-RLC-item-content'
          id='component-PMIC-buck-content'
          style={className === type ? { borderColor: '#40a9ff', boxShadow: boxShadow } : {}}
          onClick={(e) => this.openBuckSelect(e, type)}
        >
          <ul className='component-RLC-tags' style={{ minHeight: 30 }}>
            {data.map((item, index) =>
              <li className='component-RLC-item-tag' key={item}>
                <Tag
                  key={item}
                  closable={true}
                  onClose={() => this.deleteBuck(index)}
                >
                  {item.join(' - ')}
                </Tag>
              </li>
            )}
          </ul>
          {buckOpen && createPortal(<div id="component-stuff-buck-content" style={{ top, left, width }}>
            {data.map((item, index) => (
              <div className="component-buck-list-item" key={index}>
                {[0, 1].map(_index => {
                  const transistor = this.getUnusedTran(item[_index]);
                  return <Fragment key={_index}>
                    <Select
                      className="component-buck-list-select"
                      popupClassName="component-buck-list-select-dropdown"
                      onSelect={(value) => this.selectBuckComp(value, index, _index)}
                      value={item[_index]}
                      size='small'
                      showSearch={true}
                    >
                      {transistor.map(item => <Option title={item} key={item} value={item}>{item}</Option>)}
                    </Select>
                    {_index === 0 ? <Divider className="component-buck-list-divider" /> : null}
                  </Fragment>
                })}
                <CloseOutlined
                  onClick={() => this.deleteBuck(index)}
                  className='component-buck-delete-icon' />
              </div>
            ))}
            <div className="component-buck-list-item">
              {[0, 1].map(_index => {
                const transistor = this.getUnusedTran(-1);
                return <Fragment key={_index}>
                  <Select
                    className="component-buck-list-select"
                    popupClassName="component-buck-list-select-dropdown"
                    onSelect={(value) => this.selectBuckComp(value, 'empty', _index)}
                    value={null}
                    size='small'
                    showSearch={true}
                  >
                    {transistor.map(item => <Option title={item} key={item} value={item}>{item}</Option>)}
                  </Select>
                  {_index === 0 ? <Divider className="component-buck-list-divider" /> : null}
                </Fragment>
              })}
            </div>
          </div>, this.dialogRoot)}
        </div>
      </div >
    );
  }

  connectorArray = () => {
    const { COMP_PREFIX_LIB: { Connector = [], Jumper = [], Repeater = [] }, partNames } = this.state;
    let connectorArray = [
      { type: 'Jumper', data: Jumper, name: 'Jumper' },
      { type: 'Connector', data: Connector, name: 'Connector' }
    ]
    let _partNames = partNames.filter(part => ![
      ...Jumper,
      ...Connector,
      ...Repeater
    ].includes(part));
    connectorArray = connectorArray.map(item => {
      const curParts = partNames.filter(part => item.data.includes(part));
      return { ...item, options: [...curParts, ..._partNames] }
    })
    return connectorArray;
  }

  RLCArray = () => {
    const { COMP_PREFIX_LIB: { Res = [], Ind = [], Cap = [] }, partNames } = this.state;
    let array = [
      { type: 'Res', data: Res, name: 'Resistor' },
      { type: 'Ind', data: Ind, name: 'Inductor', },
      { type: 'Cap', data: Cap, name: 'Capacitor', }
    ]
    let _partNames = partNames.filter(part => ![
      ...Res,
      ...Ind,
      ...Cap
    ].includes(part));
    array = array.map(item => {
      const curParts = partNames.filter(part => item.data.includes(part));
      return { ...item, options: [...curParts, ..._partNames] }
    })
    return array;
  }

  icArray = () => {
    const { COMP_PREFIX_LIB: { IC = [] }, partNames } = this.state;
    let ICArray = [
      { type: 'IC', data: IC, name: 'IC' }
    ]
    let _partNames = partNames.filter(part => ![
      ...IC,
    ].includes(part));
    ICArray = ICArray.map(item => {
      const curParts = partNames.filter(part => item.data.includes(part));
      return { ...item, options: [...curParts, ..._partNames] }
    })
    return ICArray;
  }

  getClassificationArray = (type) => {
    switch (type) {
      case "connector":
        return this.connectorArray();
      case "ic":
        return this.icArray();
      case "RLC":
        return this.RLCArray();
      default: return []
    }
  }

  customPrefixRender = (type) => {
    const { error } = this.state;
    const { pcbId } = this.props;
    const connectorIcArray = this.getClassificationArray(type);
    return connectorIcArray.map(rlcItem => {
      let { type, data, name, render, options } = rlcItem;
      return render ? render(rlcItem) : <div className='component-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key={name}>
        <span className='component-RLC-title'>{name}</span>
        <Select
          onChange={(value) => this.changeSelect(value, type)}
          value={data}
          // style={{ width: 'calc(100% - 40px)' }}
          dropdownStyle={{ zIndex: 100000 }}
          mode="tags"
          showSearch={true}
          className='component-PMIC-item-content component-connector-item-content'
          optionLabelProp='label'
          optionFilterProp='label'
        >
          {options.map(part => {
            const comps = auroraDBJson.getComponentsByPartName(pcbId, part).map(item => item.name)
            return <Option title={`${part} (${comps.join(', ')})`} key={part} value={part} label={`${part} (${comps.join(', ')})`}>{part} ({comps.join(', ')})</Option>
          })}
        </Select>
      </div>
    })
  }

  customRepeaterItem = () => {
    const { CompPinMap } = this.props;
    const repeaterObj = this.customRepeaterObj();
    return <CompPinMap
      {...this.props.pinMapProps}
      classType={REPEATER_PIN_MAP}
      pinMapObj={repeaterObj}
      changeSelect={this.changeSelect}
      checkPartNumber={this.checkPartNumber}
      updateClassification={this.updateClassification}
    />
  }

  productRender = () => {
    const { product } = this.props;
    switch (product) {
      case SIERRA:
        return this.sierraRender();
      case CASCADE:
        return this.cascadeRender();
      default:
        return null
    }
  }

  customRepeaterObj = () => {
    const { COMP_PREFIX_LIB: {
      Connector = [],
      Jumper = [],
      Repeater = []
    }, partNames } = this.state;
    let repeaterObj = { type: 'repeater', data: Repeater, name: 'Repeater', options: [] };
    let _partNames = partNames.filter(part => ![...Connector, ...Jumper, ...Repeater].includes(part));
    repeaterObj.options = [..._partNames]
    return repeaterObj;
  }

  sierraRender = () => {
    const { animationClass } = this.state;
    return <div className={`component-animation-classfication ${animationClass}`}>
      {this.sierraItem()}
    </div>
  }

  sierraItem = () => {
    const { showKey } = this.state;
    switch (showKey) {
      case CUSTOM:
        return <Fragment>
          <div className='component-RLC-sub-title'>Custom Reference Setting</div>
          <div className='component-RLC-sub-content'>
            {this.customPrefixRender("RLC")}
          </div>
          <div className='component-RLC-sub-content'>
            {this.customPrefixRender("connector")}
          </div>
          <div className='component-RLC-sub-content'>
            {this.customPrefixRender("ic")}
          </div>
        </Fragment>
      case REPEATER_PIN_MAP:
        return <Fragment>
          {/* <div className={`component-RLC-sub-title`}>Repeater</div> */}
          <div /* className={`component-RLC-sub-content`} */>
            {this.customRepeaterItem()}
          </div>
        </Fragment>
      default: return null;
    }
  }

  cascadeItem = () => {
    const { showKey } = this.state;
    switch (showKey) {
      case CUSTOM:
        return <Fragment>
          <div className={`component-RLC-sub-title`}>Custom Reference Setting</div>
          <div className={`component-RLC-sub-content`}>
            {this.customRLCItem()}
          </div>
          <div className={`component-RLC-sub-content`}>
            {this.customLoadItem()}
          </div>
        </Fragment>
      case PMIC:
        return <Fragment>
          <div className={`component-RLC-sub-title`}>PMIC</div>
          <div className={`component-RLC-sub-content`}>
            {this.customPMICItem()}
          </div>
        </Fragment>
      case DRIVER:
        return <Fragment>
          <div className={`component-RLC-sub-title`}>Driver</div>
          <div className={`component-RLC-sub-content`}>
            {this.customDriverItem()}
          </div>
        </Fragment>
      case SWITCH:
        return <Fragment>
          <div className={`component-RLC-sub-title`}>Power Switch</div>
          <div className={`component-RLC-sub-content`}>
            {this.customSwitchItem()}
          </div>
        </Fragment>
      case MR:
        return <Fragment>
          <div className={`component-RLC-sub-title`}>Multi Pin Resistor</div>
          <div className={`component-RLC-sub-content`}>
            {this.customMRItem()}
          </div>
        </Fragment>
      default:
        return null;
    }
  }

  cascadeRender = () => {
    const { error, apply, animationClass } = this.state;
    return <Fragment>
      <div className={`component-animation-classfication ${animationClass}`}>
        {this.cascadeItem()}
      </div>

      {error && <div className='component-RLC-item' style={{ height: '25%' }}>
        <span className='model-name-error-msg'>{error}</span>
      </div>}
      <Divider className='component-RLC-divider' />
      <div className='component-RLC-sub-content'>
        <div className='component-apply-title'>Apply setting to all PCBs with same part</div>
        <Checkbox
          checked={apply}
          onChange={(e) => this.onApplyChange(e)}
          className='component-apply-checkbox'
        />
      </div>
    </Fragment>
  }

  menuRender = () => {
    const { keyList, showKey } = this.state;
    const items = keyList.map(key => ({ key, label: key }))
    return <Menu
      onClick={this.selectMenu}
      selectedKeys={[showKey]}
      items={items}
    />
  }

  render() {
    return <div className="classification-content">
      <div className="classification-left">
        {this.menuRender()}
      </div>
      <div className="classification-right">
        {this.productRender()}
      </div>
    </div>
  }
}

export default Classification;