import React from 'react';
import { ICTypes, RLCTypes } from '../../../pages/Sierra/constants';
import {
  VERIFY_NEVER,
  VERIFY_RUNNING,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  VERIFY_CANCEL,
  WAITING,
  ERROR
} from '@/constants/verificationStatus';
import hash from 'object-hash';
import { EXPERIMENTS, EXPERIMENTS_RESULT } from '@/constants/treeConstants';
import { strDelimited } from '@/services/helper/split';
import { N_PORT } from '../../VirtualComponent';

// data
function getExperimentColumns(experiments, tableColumns) {
  let bodyColumn = [], pkg = [], passive = [];
  for (let exp of experiments) {
    const { Interfaces = [] } = exp;
    for (let [_interface, index] of Interfaces.map((item, _index) => [item, _index])) {
      pkg[index] = pkg[index] ? [...new Set([...pkg[index], ...Object.keys(getGroupInList(_interface.pkg, Interfaces.length > 1 ? index : -1, true))])]
        : [...Object.keys(getGroupInList(_interface.pkg, Interfaces.length > 1 ? index : -1, true))];
      const passiveList = [...Object.keys(getGroupInList(_interface.passive, Interfaces.length > 1 ? index : -1, true)), ...Object.keys(getGroupInList(_interface.virtualPassive || [], Interfaces.length > 1 ? index : -1, true))]
      passive[index] = passive[index] ? [...new Set([...passive[index], ...passiveList])] : passiveList;
    }
  }

  let base = experiments[0];
  if (base) {
    const { Interfaces = [] } = base;
    for (let [_interface, index] of Interfaces.map((item, _index) => [item, _index])) {
      const columnsTitle = Interfaces.length > 1 ? tableColumns[index] : tableColumns[0];
      let _bodyColumn = Interfaces.length > 1 ? getBodyColumn(index, Interfaces.length, columnsTitle) : getBodyColumn(-1, 1, columnsTitle);
      const pkgIndex = _bodyColumn.findIndex(item => item.key.includes(PKG));
      if (pkg[index] && pkg[index].length && pkgIndex > -1 && _bodyColumn[pkgIndex]) {
        if (pkgIndex > -1) {
          _bodyColumn[pkgIndex].children = pkg[index].map(item => {
            return {
              title: item.split('_')[0],
              dataIndex: item,
              key: item,
            }
          })
        }
      }
      const passiveIndex = _bodyColumn.findIndex(item => item.key.includes(PASSIVE));
      if (passive[index] && passive[index].length && passiveIndex > -1 && _bodyColumn[passiveIndex]) {
        _bodyColumn[passiveIndex].children = passive[index].map(item => {
          return {
            title: item.split('_')[0],
            dataIndex: item,
            key: item,
          }
        })
      }
      if (Interfaces.length > 1) {
        bodyColumn.push({
          title: _interface.pcb.name,
          key: _interface.pcb.id,
          children: _bodyColumn
        })
      } else {
        bodyColumn = _bodyColumn
      }
    }
  }
  return [...headerColumn, ...bodyColumn, ...footerColumn];
}

function getCompGroup(type, experiments) {
  let list = [], typeList = [type];
  if (type === PASSIVE) {
    typeList.push(VIRTUAL_PASSIVE);
  }
  for (let exp of experiments) {
    const { Interfaces = [] } = exp;
    for (let [_interface, index] of Interfaces.map((item, _index) => [item, _index])) {
      const values = typeList.map(_type => {
        const list = [...Object.values(getGroupInList(_interface[_type]))];
        if (_type !== type) {
          list.forEach(item => {
            item.subType = _type;
          })
        }
        return list;
      }).flat(2);
      list[index] = list[index] ? [...list[index], ...values] : values;
    }
  }
  return list;
}

// get table data
function getExperimentData(experiments) {
  const _experiments = experiments.filter(exp => exp.id);
  const baseExperiment = _experiments.find(exp => exp.name === "Base")
  let data = [];
  for (let exp of _experiments) {
    const { id, name, verificationId, status, Interfaces = [{}] } = exp;
    let interfaceData = {}
    if (Interfaces.length > 1) {
      for (let [_interface, index] of Interfaces.map((item, index) => [item, index])) {
        const { pcb, pkg, passive, pins, model, signals, powerNets, deviceVcc, virtualPassive = [] } = _interface;
        let currentPcb = pcb
        if (!pcb || !Object.keys(pcb)) {
          if (baseExperiment.Interfaces && baseExperiment.Interfaces.length && baseExperiment.Interfaces[index].pcb) {
            currentPcb = baseExperiment.Interfaces[index].pcb
          }
        }
        interfaceData[`pcb_${index}`] = currentPcb;
        interfaceData[`pins_${index}`] = pins;
        interfaceData[`model_${index}`] = model;
        interfaceData[`signals_${index}`] = signals;
        interfaceData[`powerNets_${index}`] = powerNets;
        interfaceData[`deviceVcc_${index}`] = deviceVcc;
        (virtualPassive || []).forEach(item => { item.subType = VIRTUAL_PASSIVE });
        interfaceData = { ...interfaceData, ...getGroupInList(pkg, index), ...getGroupInList([...(passive || []), ...(virtualPassive || [])], index) }
      }
    } else if (Interfaces[0]) {
      let { pcb = {}, pkg = [], passive = [], pins = [], model = [], signals = [], powerNets = [], deviceVcc = [], virtualPassive = [] } = Interfaces[0];
      (virtualPassive || []).forEach(item => { item.subType = VIRTUAL_PASSIVE });
      let pkgGroup = getGroupInList(pkg), passiveGroup = getGroupInList([...(passive || []), ...(virtualPassive || [])]);
      if (!pcb || !Object.keys(pcb).length) {
        if (baseExperiment.Interfaces && baseExperiment.Interfaces.length && baseExperiment.Interfaces[0].pcb) {
          pcb = baseExperiment.Interfaces[0].pcb
        }
      }
      interfaceData = { pcb, ...pkgGroup, ...passiveGroup, pins, model, signals, powerNets, deviceVcc };
    }
    data.push({ id, name, verificationId, status, ...interfaceData });
  }
  return data;
}

function getPkgData(comp, record, base, parentIndex = -1) {
  const _pkg = record[comp] ? record[comp] : base[comp] ? base[comp] : {};
  const modelKey = parentIndex > -1 ? `model_${parentIndex}` : 'model';
  const [currentCompName] = comp.split('_');
  let models = record[modelKey] && record[modelKey].length ? record[modelKey].find(model => model.component === currentCompName) : null;
  if (!models || !models.modelInfo) {
    models = base[modelKey] ? base[modelKey].find(model => model.component === currentCompName) : null;
  }
  return { pkg: _pkg.pkgInfo, model: models ? models.modelInfo : {}, part: _pkg.part, visible: true };
}

function getBaseSweepingData(Interfaces) {
  let _Interfaces = []
  for (let item of Interfaces) {
    let pkg = [], passive = [], pins = [], pcb = {}, model = [], deviceVcc = [], virtualPassive = [];
    pcb = {
      id: item.pcbId,
      subId: item.pcbSubId,
      name: item.pcb
    };
    const { components, signals, powerNets, virtualComps = [] } = item.content
    for (let comp of components) {
      if (ICTypes.includes(comp.type)) {
        pkg.push({
          component: comp.name,
          part: comp.part,
          visible: false,
          pkgInfo: JSON.parse(JSON.stringify(comp.pkg))
        })

        pins.push({
          component: comp.name,
          pinInfo: JSON.parse(JSON.stringify(comp.pins))
        })

        deviceVcc.push({
          component: comp.name,
          value: comp.deviceVcc
        })

        model.push({
          component: comp.name,
          modelInfo: { ...comp.model }
        })
      }

      if (RLCTypes.includes(comp.type)) {
        passive.push({
          component: comp.name,
          visible: false,
          ...comp
        })
      }
    }

    virtualPassive = getSweepVirtualPassive(virtualComps);

    const _Interface = new ExperimentInterface({ interfaceId: item.interfaceId, pcb, pkg, passive, pins, model, signals, powerNets, deviceVcc, virtualPassive });
    _Interfaces.push(_Interface);
  }
  return new ExperimentTemplate({ id: '', name: 'Base', Interfaces: _Interfaces });
}

function getSweepVirtualPassive(virtualComps) {
  let virtualPassive = []
  for (let comp of (virtualComps || [])) {
    if (comp.type === N_PORT) {
      continue;
    }
    virtualPassive.push({
      component: comp.name,
      visible: false,
      ...comp
    })
  }
  return virtualPassive;
}

const editType = ['usage', 'model', 'pinModels', 'corner']
function getSweepingPinData(record, index = -1, tempChange = [], rowName) {
  const pcb = index > -1 ? record[`pcb_${index}`] : record.pcb;
  const pins = index > -1 ? record[`pins_${index}`] : record.pins;
  const deviceVcc = index > -1 ? record[`deviceVcc_${index}`] : record.deviceVcc;
  let pinList = [], _signalNames = [], _pins = [];
  pins.forEach(comp => {
    const name = comp.component;
    const currentVcc = deviceVcc.find(vcc => vcc.component === name);
    let inputStimulus = "", stimulus = null;
    comp.pinInfo.forEach(pin => {
      const newInfo = tempChange.find(c => c.component === name);
      let _pin = { ...pin }, edited = [];
      let base = getStimulus(_pin);
      inputStimulus = base.inputStimulus;
      stimulus = base.stimulus;
      if (newInfo && newInfo.pinInfo) {
        const _pinInfo = newInfo.pinInfo.find(p => p.pin === pin.pin);
        if (_pinInfo) {
          if (_pinInfo) {
            editType.forEach(type => {
              if (_pinInfo[type]) {
                if (type === 'pinModels') {
                  let editStimulus = getStimulus(_pinInfo);
                  if (base.inputStimulus !== editStimulus.inputStimulus) {
                    edited.push('stimulus');
                    inputStimulus = editStimulus.inputStimulus;
                    stimulus = editStimulus.stimulus;
                  }
                } else {
                  edited.push(type)
                }
              }
            })
          }
          _pin = { ...pin, ..._pinInfo };
        }
      }
      let model = _pin.model;
      const currentTemp = tempChange.find(item => item.component === comp.component);
      const info = {
        pcb: pcb.name,
        pcbId: pcb.id,
        component: name,
        deviceVcc: currentVcc.value || "",
        ..._pin,
        exist: currentTemp ? currentTemp.exist : true,
        model: model,
        inputStimulus,
        stimulus,
        edited: rowName === 'Base' ? [] : edited
      };
      const _index = _signalNames.indexOf(pin.signal)
      if (_index > -1) {
        _pins[_index].push(info);
      } else {
        _signalNames.push(pin.signal);
        _pins.push([info]);
      }
    })
  })

  _pins.forEach(item => {
    item[0].signalLength = item.length;
    pinList.push(...item);
  });
  return pinList;
}

function getStimulus(pin) {
  let inputStimulus = "", stimulus = null;
  if (pin.pinModels && pin.pinModels.length > 0) {
    const ND_IN = pin.pinModels.find(item => item.pinName === "nd_in");
    const ND_EN = pin.pinModels.find(item => item.pinName === "nd_en");
    if (ND_EN) {
      if (ND_EN.type === 'GND' || ND_EN.type === 'VCC') {
        inputStimulus = ND_IN.type;
        stimulus = ND_IN.stimulus;
      } else {
        inputStimulus = ND_EN.type;
        stimulus = ND_EN.stimulus;
      }
    } else {
      inputStimulus = ND_IN.type.includes('PRBS') && ND_IN.tabs && ND_IN.tabs.length ? `${ND_IN.type}[${ND_IN.tabs.join(',')}]` : ND_IN.type;
      stimulus = ND_IN.stimulus;
    }
  }
  return { inputStimulus, stimulus }
}

function getGroupInList(list, index = -1, visibleOnly = false) {
  let group = {};
  if (list && list.length) {
    for (let item of list) {
      if ((visibleOnly && item.visible) || !visibleOnly) {
        group[index > -1 ? `${item.component}_${index}` : item.component] = { ...item };
      }
    }
  }
  return group;
}

function deepHash(obj, baseObj, key) {
  let _obj = JSON.parse(JSON.stringify(obj)),
    _baseObj = JSON.parse(JSON.stringify(baseObj));

  if (key === 'model') {
    _obj = { libType: obj.libType, libraryId: obj.libraryId, modelName: obj.modelName }
    _baseObj = { libType: baseObj.libType, libraryId: baseObj.libraryId, modelName: baseObj.modelName }
  }

  return hash(_obj) !== hash(_baseObj);
}

const constantKey = ['pin', 'signal', 'net']
function compairExperimentPins(component, pin, basePins) {
  const pinKey = Object.keys(pin);
  const currentPin = basePins.find(item => item.component === component).pinInfo.find(item => item.pin === pin.pin);
  let newPin = {};
  for (let key of pinKey) {
    if (!constantKey.includes(key)
      && ((typeof pin[key] === 'object' && deepHash(pin[key], currentPin[key], key))
        || (typeof pin[key] !== 'object' && pin[key] !== currentPin[key]))) {
      newPin[key] = pin[key];
    }
  }
  if (Object.keys(newPin).length > 0) {
    newPin.pin = pin.pin
    return newPin
  }
  return null;
}

const ND_IN = 'nd_in', ND_EN = 'nd_en';
function getStimulusTextInSweeping(pins, basePins) {
  let text = [];
  for (let comp of pins) {
    const baseComp = basePins.find(item => item.component === comp.component);
    if (comp && comp.exist === false) {
      continue;
    } else if (baseComp && baseComp.pinInfo && comp.pinInfo) {
      for (let pin of comp.pinInfo) {
        if (pin.pinModels && pin.pinModels.length) {
          const basePin = baseComp.pinInfo.find(p => p.pin === pin.pin);
          if (!basePin) { continue }
          let diff = false;
          const newIn = pin.pinModels.find(item => item.pinName === ND_IN);
          if (!basePin.pinModels || !basePin.pinModels.length) {
            diff = true;
          } else {
            const baseIn = basePin.pinModels.find(item => item.pinName === ND_IN);
            if (!diff && newIn.type !== baseIn.type) {
              diff = true
            }

            if (!diff && newIn.type.includes('PRBS')) {
              if (sweepingNdInDiff(newIn, baseIn, "tabs") || sweepingNdInDiff(newIn, baseIn, "slew") || sweepingNdInDiff(newIn, baseIn, "delay")) {
                diff = true;
              } else if (!newIn.tabs) {
                newIn.tabs = baseIn.tabs;
                diff = true;
              }
            }

            if (!diff && ['GND', 'VCC'].includes(newIn.type)) {
              const newEn = pin.pinModels.find(item => item.pinName === ND_EN);
              const baseEn = basePin.pinModels.find(item => item.pinName === ND_EN);
              if ((!newEn && baseEn) || (newEn && !baseEn) || newEn.type !== baseEn.type) {
                diff = true;
              }
            }

            if (!diff && newIn.type === 'VEC' && newIn.stimulus.libraryId !== baseIn.stimulus.libraryId) {
              diff = true;
            }

            if (!diff && newIn.type === "MultiPinSPICE"
              && newIn.stimulus.libraryId !== baseIn.stimulus.libraryId
              && newIn.stimulus.node !== baseIn.stimulus.node) {
              diff = true;
            }
          }
          if (diff) {
            let pinText = "";
            switch (newIn.type) {
              case 'VEC':
              case "MultiPinSPICE":
                pinText = newIn.stimulus.subckt ? newIn.stimulus.subckt : newIn.stimulus.fileName;
                break;
              case newIn.type.includes('PRBS'):
                pinText = `${newIn.type}${newIn.tabs}`;
                break;
              case 'GND':
              case 'VCC':
                const newEn = pin.pinModels.find(item => item.pinName === ND_EN);
                pinText = newEn.type;
                break;
              default:
                pinText = newIn.type;
                break;
            }
            if (!pinText) {
              pinText = "";
            }
            text.push({ comp: comp.component, pin: pin.pin, text: pinText })
          }

        }
      }
    }
  }
  return text;
}

function sweepingNdInDiff(newIn, baseIn, key) {
  if (!newIn[key] && !baseIn[key]) {
    return false;
  }
  if ((newIn[key] && !baseIn[key])
    || (!newIn[key] && baseIn[key])
    || (hash(newIn[key]) !== hash(baseIn[key]))) {
    return true;
  }
  return false;
}

function getCompExist(baseInterface, componentsList, _Interface, key) {
  const newInterface = { ..._Interface };
  let notExist = [];
  baseInterface[key].forEach(p => {
    const findComp = componentsList.find(item => item.name === p.component);
    const expComp = newInterface[key] ? newInterface[key].find(item => item.component === p.component) : null;
    if (!findComp) {
      notExist.push(p.component)
      if (expComp) {
        expComp.exist = false;
      } else {
        newInterface[key] ? newInterface[key].push({ component: p.component, exist: false })
          : newInterface[key] = [{ component: p.component, exist: false }];
      }
    } else if (expComp) {
      expComp.exist = true;
    }
  });
  return { notExist, newInterface };
}

// class experiment
function ExperimentTemplate({ id = "", name, status = 1, Interfaces = [], interfaceIds = [] }) {
  this.id = id;
  this.name = name;
  this.status = status;
  this.Interfaces = interfaceIds.length ?
    interfaceIds.map(interfaceId => new ExperimentInterface({ interfaceId })) : Interfaces;
}

/**
 * pkg:[{ component, part, visible, pkgInfo: { files, type, pairs } }],
 * pins:[{ component, pinInfo: [{ pin, pinModels, model, usage, corner }] }],
 * passive:[{ component, part, visible, model, value }],
 * pcb: { id, subId, name }
 */

function ExperimentInterface({ interfaceId, pcb = {}, pkg = [], passive = [], pins = [], model = [], signals = [], powerNets = [], deviceVcc = [], virtualPassive = [] }) {
  this.interfaceId = interfaceId;
  this.pcb = pcb;
  this.pkg = pkg;
  this.passive = passive;
  this.pins = pins;
  this.model = model;
  this.signals = signals;
  this.powerNets = powerNets;
  this.deviceVcc = deviceVcc;
  this.virtualPassive = virtualPassive;
}

const PCB = 'pcb', PKG = 'pkg', PASSIVE = 'passive', PIN = 'pins', VIRTUAL_PASSIVE = "virtualPassive", MEASUREMENT = "measurement";
// column
function getBodyColumn(index = -1, length = 1, columnsTitle = []) {
  let bodyColumn = [];
  let variableLength = 90;
  columnsTitle.forEach(key => {
    switch (key) {
      case PCB:
        variableLength = variableLength - 20
        break;
      case PKG:
        variableLength = variableLength - 20
        break;
      case PASSIVE:
        variableLength = variableLength - 15
        break;
      case PIN:
        variableLength = variableLength - 20
        break;
      case MEASUREMENT:
        variableLength = variableLength - 15
        break;
      default: break;
    }
  })
  variableLength = columnsTitle.length === 5 ? 0 : variableLength / columnsTitle.length
  columnsTitle.forEach(key => {
    switch (key) {
      case PCB:
        bodyColumn.push({
          title: 'PCB',
          dataIndex: index > -1 ? `pcb_${index}` : `pcb`,
          key: index > -1 ? `pcb_${index}` : `pcb`,
          width: `${(20 + variableLength) / length}%`
        })
        break;
      case PKG:
        bodyColumn.push({
          title: 'Package',
          dataIndex: index > -1 ? `pkg_${index}` : `pkg`,
          key: index > -1 ? `pkg_${index}` : `pkg`,
          width: `${(20 + variableLength) / length}%`
        })
        break;
      case PASSIVE:
        bodyColumn.push({
          title: 'Passive',
          dataIndex: index > -1 ? `passive_${index}` : `passive`,
          key: index > -1 ? `passive_${index}` : `passive`,
          width: `${(15 + variableLength) / length}%`
        })
        break;
      case PIN:
        bodyColumn.push({
          title: 'Pin Buffer Model',
          dataIndex: index > -1 ? `pins_${index}` : `pins`,
          key: index > -1 ? `pins_${index}` : `pins`,
          width: `${(20 + variableLength) / length}%`
        })
        break;
      case MEASUREMENT:
        bodyColumn.push({
          title: 'Measurement',
          dataIndex: index > -1 ? `measurement_${index}` : `measurement`,
          key: index > -1 ? `measurement_${index}` : `measurement`,
          width: `${(15 + variableLength) / length}%`
        })
        break;
      default: break;
    }
  })
  return bodyColumn;
}

const headerColumn = [
  {
    title: 'Checked',
    dataIndex: 'checked',
    key: 'checked',
    width: 50
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 170
  }
]

const footerColumn = [
  {
    title: 'Measurement',
    dataIndex: 'measurement',
    key: 'measurement',
    width: 120
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100
  }
]

function getExperimentStatus(status) {
  switch (status) {
    case VERIFY_RUNNING:
      return { title: 'Running', color: '#31aaf3' }
    case VERIFY_SUCCESS:
      return { title: 'Success', color: '#4dbd16' }
    case VERIFY_FAILED:
      return { title: 'Failed', color: '#b32222' }
    case VERIFY_CANCEL:
      return { title: 'Cancel', color: '#9d7ace' }
    case WAITING:
      return { title: 'Waiting', color: '#ff5500' }
    case ERROR:
      return { title: 'Error', color: '#dc143c', tooltip: 'Please check the error message in the corresponding Monitor' }
    case VERIFY_NEVER:
    default:
      return { title: '', color: '#ffffff' };
  }
}

function getExpTreeNode(exp_res = []) {
  return exp_res.map(item => {
    const { rootVerificationId, id, name } = item;
    return {
      id,
      key: EXPERIMENTS + '-' + id,
      name: name,
      dataType: EXPERIMENTS,
      verificationId: rootVerificationId,
      nodeClass: 'tree-node-experiment-name',
      icon: EXPERIMENTS,
      children: [{
        name: 'Result',
        key: EXPERIMENTS_RESULT + '-' + id /* + '-' + item.verificationId */,
        dataType: EXPERIMENTS_RESULT,
        nodeClass: 'tree-node-experiment-result-name',
      }]
    }
  })
}

function getPinChangeInfo(pin, basePin, record, parentIndex, data, stimulus) {
  let change = { comp: data.component, pin: pin.pin }
  if (pin.usage) {
    change['usage'] = basePin.usage;
  }
  if (pin.model) {
    if (pin.model.libType === "MultiPinSPICE") {
      const model = parentIndex > -1 ? record[`model_${parentIndex}`] : record[`model`];
      const findModel = model.find(comp => comp.component === data.component);
      if (findModel && findModel.modelInfo) {
        findModel.modelInfo.files.forEach(file => {
          if (file.type === "MultiPinSPICE") {
            const name = file.subckt ? `${file.fileName}::${file.subckt}` : `${file.fileName}`;
            if (name) {
              change['model'] = name;
            }
          }
        })
      }
    } else {
      change['model'] = basePin.model.modelName ? basePin.model.modelName : '';
    }
  }

  if (stimulus && stimulus.length) {
    const current = stimulus.find(item => item.comp === data.component && item.pin === pin.pin);
    if (current && current.text) {
      change['stimulus'] = current.text;
    }
  }

  if (pin.corner) {
    change['corner'] = basePin.corner;
  }
  return change
}


function getModelText(record, text, parentIndex, base, dataSource, columnKey) {
  let children = <span></span>;
  let info = [], changeList = [];
  if (text) {
    if (record.name === 'Base') {
      if (dataSource && dataSource.length && dataSource.length > 1) {
        const _dataSource = dataSource.filter(item => item.name !== 'Base')
        for (let data of _dataSource) {
          if (data[columnKey] && data[columnKey].length) {
            for (let info of data[columnKey]) {
              if (info && info.pinInfo) {
                for (let pin of info.pinInfo) {
                  const pinCurrentData = text.find(item => item.component === info.component)
                  const _basePin = (pinCurrentData && pinCurrentData.pinInfo && pinCurrentData.pinInfo.length) ? pinCurrentData.pinInfo.find(item => item.pin === pin.pin) : {}
                  if (_basePin && Object.keys(_basePin).length) {
                    const stimulus = getStimulusTextInSweeping(data[columnKey], text);
                    let _stimulus = []
                    if (stimulus && stimulus.length) {
                      _stimulus = getStimulusTextInSweeping(text, data[columnKey]);
                    }
                    const change = getPinChangeInfo(pin, _basePin, record, parentIndex, info, _stimulus)
                    changeList.push(change);
                  }
                }
              }
            }
          }
        }
      }
    } else {
      const basePins = base && base.Interfaces ? parentIndex > -1 && base.Interfaces[parentIndex] ? base.Interfaces[parentIndex][PIN] : base.Interfaces[0][PIN] : [];
      const stimulus = getStimulusTextInSweeping(text, basePins);
      for (let data of text) {
        if (data && data.exist === false) {
          info.push(`${data.component} does not exist`)
        } else if (data && data.pinInfo) {
          for (let pin of data.pinInfo) {
            const change = getPinChangeInfo(pin, pin, record, parentIndex, data, stimulus)
            changeList.push(change);
          }
        }
      }
    }

    let changeGroup = {} // According to type (usage, model, stimulus, corner)
    const params = ['usage', 'model', 'stimulus', 'corner']
    changeList.forEach(item => {
      for (let key of params) {
        changeGroup = changeGroupHandle(item, key, changeGroup)
      }
    })
    for (let key of params) {
      if (changeGroup[key]) {
        let text = showPinBufferModelText(changeGroup[key]);
        if (text) {
          info.push(text)
        }
      }
    }

    children = <div>
      {info.length ? info.map(t => <div key={t}>{t}</div>) : null}
    </div>;
  }
  return { children, info }
}

function changeGroupHandle(obj, key, changeGroup) {
  const { pin, comp } = obj;
  const param = obj[key];
  let _changeGroup = { ...changeGroup }
  if (param) {
    if (_changeGroup[key]) {
      const find = _changeGroup[key].find(i => i.comp === comp && i.value === param);
      if (find) {
        find.pins = [...new Set([...find.pins, pin])]
      } else {
        _changeGroup[key].push({ comp, value: param, pins: [pin] })
      }
    } else {
      _changeGroup[key] = [{ comp, value: param, pins: [pin] }]
    }
  }
  return _changeGroup
}

function showPinBufferModelText(group) {
  let text = [];
  for (let item of group) {
    const { comp, pins, value } = item;
    if (comp && pins && pins.length) {
      text.push(`${comp} (${pins.join(', ')}) ${value}`)
    }
  }
  return text.join(', ')
}

function getData(portList, keys) {
  let data = {};
  for (let key of keys) {
    if (portList[key] && portList[key].length) {
      for (let portData of portList[key]) {
        const name = (portData || "").match(/(\S*)@/)[1];
        if (data[name] && data[name].length) {
          data[name].push({ id: portData, signal: key })
        } else {
          data[name] = [{ id: portData, signal: key }]
        }
      }
    }
  }
  return data
}

function getSweepList(data = {}, experimentList, portSelect) {
  const keys = Object.keys(data);
  const allPortData = getData(data, keys)
  let selectPortData = getData(portSelect, keys)
  const experimentListData = experimentList.map(experiment => {
    const { name } = experiment;
    let indeterminate = false, allChecked = false;
    let _selectRowPortData = selectPortData[name] && selectPortData[name].length ? [...selectPortData[name]] : [];
    if (allPortData[name] && selectPortData[name] && selectPortData[name].length) {
      const allNameData = allPortData[name].map(item => item.id)
      _selectRowPortData = selectPortData[name].filter(item => allNameData.includes(item.id))

      if (_selectRowPortData.length) {
        indeterminate = true;
        if (_selectRowPortData.length === allPortData[name].length) {
          allChecked = true;
          indeterminate = false
        }
      }
    }

    const dataList = keys.map(key => {
      let _indeterminate = false, _allChecked = false;
      const filterAllPortPortData = (allPortData[name] && allPortData[name].length) ? allPortData[name].filter(item => item.signal === key) : [];
      const filterSelectPortPortData = _selectRowPortData.filter(item => item.signal === key);
      if (filterSelectPortPortData.length) {
        _indeterminate = true;
        if (filterAllPortPortData.length === filterSelectPortPortData.length) {
          _allChecked = true;
          _indeterminate = false
        }
      }
      return {
        indeterminate: _indeterminate,
        allChecked: _allChecked,
        signal: key,
        allPortData: filterAllPortPortData.map(item => item.id),
        selectPortData: filterSelectPortPortData.map(item => item.id)
      }
    })

    return {
      indeterminate: indeterminate,
      allChecked: allChecked,
      dataList,
      name: name
    }
  })
  const showChecked = keys.length > 1 ? true : false;
  return { experimentListData, showChecked }
}

function getMultiRowParallelData(id, selected, groupName, color, experimentList) {
  let checkList = [], indeterminate = false, allChecked = false;
  const [_name, file] = strDelimited(id, "@");
  const multiRowParallelData = experimentList.map(item => {
    const _id = `${item.name}@${file}`
    const [fileId] = strDelimited(file, "::")
    const checked = selected && selected.includes(_id);
    if (!checkList.includes(checked) && groupName.includes(item.name) && color[item.name]) {
      checkList.push(checked)
    }
    let selectList = [];
    if (selected) {
      selectList = checked ? selected.filter(item => item !== _id) : [...selected, _id];
    } else {
      selectList = checked ? [] : [_id];
    }
    return { ...item, _id, checked, selectList, fileId }
  })
  if (checkList.length > 1) {
    indeterminate = true;
  } else {
    allChecked = checkList[0] ? checkList[0] : false;
  }
  return { multiRowParallelData, indeterminate, allChecked }
}

export const DEFAULT_EXPERIMENT_COLUMNS = [PCB, PKG, PASSIVE, PIN];

export {
  getExperimentColumns,
  getBaseSweepingData,
  getExperimentData,
  getPkgData,
  getSweepingPinData,
  getCompGroup,
  getExperimentStatus,
  getExpTreeNode,
  compairExperimentPins,
  getStimulusTextInSweeping,
  getCompExist,
  getModelText,
  getSweepList,
  getMultiRowParallelData,
  getSweepVirtualPassive
}