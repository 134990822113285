export const
  WAITING = 0,
  RUNNING = 1,
  SUCCEED = 2,
  FAILED = 3,
  CANCEL = 4;

export const
  LAYOUT_NO_CHECK = 0,
  LAYOUT_RUNNING = 1,
  LAYOUT_SUCCEED = 2;