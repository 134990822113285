import { takeEvery, select, put, call } from "redux-saga/effects";
import { message } from "antd";
import {
  EXPERIMENTS_CREATE,
  EXPERIMENTS
} from '../../../../constants/treeConstants';
import {
  CREATE_EXPERIMENT,
  ADD_EXPERIMENT,
  OPEN_EXPERIMENT,
  ADD_EXPERIMENT_DATE,
  HANDLE_SIGNAL_EXPERIMENT_DATA,
  RENAME_EXPERIMENT,
  DELETE_EXPERIMENT,
  CHANGE_VARIABLES,
  HANDLE_TRACE_SETTING
} from "./actionTypes";
import {
  getIndex,
  createSweepConfigsPromise,
  getNetListByChannelIdPromise,
  generateTraceConfigs,
  ONLY,
  updateSweepConfigsPromise,
  getSweepConfigsPromise,
  getExperimentsBySweepIdPromise,
  createSweepContentPromise,
  deleteSweepContentByIdPromise,
  updateSweepContentPromise,
  getVariablesBySweepIdPromise,
  deleteSweepBySweepIdPromise,
  updateVariablesBySweepIdPromise,
  getVariableByType,
  afterChangeTraceSetting,
  PERCENTAGE
} from "../../../../services/Andes_v2/sweep";
import { PROJECTS_INDEX } from "../../../../services/Andes_v2";
import { updateTreeList, openPage, updateExpand, updateSelectKeys, updateViewList } from "../project/action";
import { getDefaultName } from "../../../../services/helper/setDefaultName";
import LayoutData from '@/services/data/LayoutData';
import TraceWidth from "../../../../services/PCBHelper/traceWidth";
import { clearSweepInfo, updateExperimentData, updateIsUpdateExperimentData, updateSweepLoading, updateTraceSetting, updateNotExistNets, updateSweepInfo, updateExperimentIds } from "./action";
import sweepConstructor from "../../../../services/Andes_v2/sweep/sweepConstructor";
import { _expandChannel } from "../channel/channelSaga";
import { getViewListBySelectedKey } from "../../../../services/helper/filterHelper";
import { getVerificationWorkflow } from "../simulation/action";
import { changeTabMenu, openTabFooter } from "../../../MonitorStore/action";
import { VERIFY_RUNNING } from "../../../../constants/verificationStatus";

function* _addExperimentInTree(action) {
  const { itemData } = action;
  const { AndesV2Reducer: { project: { treeItems, expandedKeys, openProjectId } } } = yield select();
  let _treeItems = [...treeItems];
  // If this channel is not expanded during new creation
  if (!expandedKeys.includes(itemData.key)) {
    yield call(_expandChannel, { channelId: itemData.id, designId: itemData.designId })
    yield put(updateExpand([...expandedKeys, itemData.key]));
  }

  const { projectIndex, PCBsIndex, designIndex, channelIndex } = getIndex({ treeItems, projectId: openProjectId, designId: itemData.designId, channelId: itemData.id });

  if (channelIndex > -1) {
    const currentVerification = _treeItems[PROJECTS_INDEX].children[projectIndex].children[PCBsIndex].children[designIndex].children[channelIndex];
    const dataTypes = currentVerification.children.map(item => item.dataType);
    if (dataTypes.includes(EXPERIMENTS_CREATE)) {
      message.error('There are already experiment being created.');
      return;
    }
    //set default sweep name
    const name = getDefaultName({ nameList: currentVerification.children, defaultKey: `Sweep` });
    // update treeItems
    _treeItems[PROJECTS_INDEX].children[projectIndex].children[PCBsIndex]
      .children[designIndex].children[channelIndex].children.push({
        id: name,
        name,
        key: name,
        designId: itemData.designId,
        channelId: itemData.id,
        dataType: EXPERIMENTS_CREATE,
      })
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
  }
}

function* _createNewExperiment(action) {
  const { data: { name, designId, channelId } } = action;
  const info = {
    channelId,
    designId,
    id: "",
    name,
    sweepConfig: {},
    selectedSweepConfig: []
  }
  try {
    // call the api to get the id of the new experiment
    const res = yield call(createSweepConfigsPromise, info);
    if (!res) {
      return;
    }
    // update cache
    sweepConstructor.addSweep(res.id, res)
    // expand channel, update sweep info
    yield call(_expandChannel, { channelId, designId, addSweepToCache: false })
    yield put(openPage({ pageType: EXPERIMENTS, id: res.id, firstCreate: true }));
  } catch (error) {
    console.error(error);
  }
}

function* _openExperiment(action) {
  const { id, firstCreate, simulating } = action;
  yield put(clearSweepInfo());
  yield put(updateSweepLoading(true));
  // init configs
  const sweep = sweepConstructor.getSweep(id);
  const { designId, channelId, verificationId, name } = sweep || {};
  try {
    if (firstCreate) {
      yield call(LayoutData.LoadLayoutDB, designId);
      const layoutDB = LayoutData.getLayout(designId);
      // get net list -> {string : ['string']}
      const netInfo = yield call(getNetListByChannelIdPromise, channelId);
      const keys = Object.keys(netInfo);
      const nets = []
      for (const key of keys) {
        nets.push(...netInfo[key]);
      }
      // get default width -> {net: [number]}
      const netMap = TraceWidth.getShapeByNets({ designId, nets, layoutDB });
      const traceConfigs = generateTraceConfigs({ netMap, unit: layoutDB.mUnits, type: ONLY, sweepId: id, changeWay: PERCENTAGE });
      const traceSetting = { min: "90", max: "110", type: ONLY, changeWay: PERCENTAGE };
      const sweepConfigs = {
        ...sweep,
        sweepConfig: {
          traceConfigs,
          traceSetting
        },
        selectedSweepConfig: []
      }
      // save to server
      yield call(updateSweepConfigsPromise, sweepConfigs);
      // update cache
      sweepConstructor.addSweep(id, sweepConfigs);
      // update store
      yield put(updateSweepInfo({ id, traceConfigs, traceSetting }));
    } else {
      // Get the net of the channel and check for non-existent nets
      const netInfo = yield call(getNetListByChannelIdPromise, channelId);
      const keys = Object.keys(netInfo);
      const nets = []
      for (const key of keys) {
        nets.push(...netInfo[key]);
      }
      const { sweepConfig: { traceConfigs = [], traceSetting = {} } } = yield call(getSweepConfigsPromise, id);
      // get experiments data
      const variablesTables = yield call(getExperimentsBySweepIdPromise, id);
      // Get experimentIds based on status
      const experimentIds = variablesTables.filter(item => item.status === VERIFY_RUNNING).map(it => it.id);
      yield put(updateExperimentIds(experimentIds));
      const variables = yield call(getVariablesBySweepIdPromise, id);
      const selectedNets = traceConfigs.map(item => item.net);

      const newNets = nets.filter(item => !selectedNets.includes(item));
      const delNets = selectedNets.filter(item => !nets.includes(item));
      // update traceConfigs to server
      let newTraceConfigs = [], oldTraceConfigs = [...traceConfigs];
      if (newNets && newNets.length > 0) {
        // genetate traceConfigs
        if (newNets && newNets.length > 0) {
          yield call(LayoutData.LoadLayoutDB, designId);
          const layoutDB = LayoutData.getLayout(designId);
          const netMap = TraceWidth.getShapeByNets({ designId, nets: newNets, layoutDB });
          newTraceConfigs = generateTraceConfigs({ netMap, unit: layoutDB.mUnits, type: ONLY, sweepId: id });
        }
      }
      if (delNets && delNets.length > 0) {
        oldTraceConfigs = traceConfigs.filter((item) => !delNets.includes(item.net))
      }
      yield call(updateSweepConfigsPromise, {
        ...sweep,
        sweepConfig: {
          traceConfigs: [...oldTraceConfigs, ...newTraceConfigs],
          traceSetting
        }
      });
      // find deleted nets
      const notExistNets = Array.from(new Set(variables.filter(it => !nets.includes(it.net)).map(item => item.net)));
      if (notExistNets && notExistNets.length > 0) {
        yield put(updateNotExistNets(notExistNets))
      }
      yield put(updateSweepInfo({ id, traceConfigs: [...oldTraceConfigs, ...newTraceConfigs], traceSetting, experimentData: { variablesTables, variables } }))
    }
    // open monitor
    yield put(openTabFooter());
    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: verificationId,
      verificationName: name,
      menuType: "simulation",
    }));
    if (!simulating && sweep) {
      yield put(getVerificationWorkflow({
        sweepId: id,
        workType: EXPERIMENTS,
        verificationId
      }));
    }
    yield put(updateIsUpdateExperimentData(true));
    yield put(updateSweepLoading(false));
  } catch (error) {
    console.error(error);
  }
}

function* _addExperimentData() {
  const { AndesV2Reducer: { sweep: { experimentData, sweepId } } } = yield select();
  const _variablesTables = [...experimentData.variablesTables];
  const name = getDefaultName({ nameList: _variablesTables, defaultKey: `Exp` });
  try {
    const res = yield call(createSweepContentPromise, [{ id: "", name, sweepId, params: [] }])
    if (!res) {
      return;
    }
    _variablesTables.push(...res)
    yield put(updateExperimentData({ variablesTables: _variablesTables }));
  } catch (error) {
    console.error(error);
  }
}

function* _handleSingleExperimentData(action) {
  const { params, handleType } = action;
  const { AndesV2Reducer: { sweep: { experimentData, sweepId, traceConfigs } } } = yield select();
  const { variables, variablesTables } = experimentData;
  let _variablesTables = [...variablesTables];
  const { id = '', name = '', type = '', variable = "", applyAll = false, inputValue } = params;
  const dataIndex = _variablesTables.findIndex(item => item.id === id);
  if (dataIndex < 0) {
    return;
  }
  let res = null;
  try {
    switch (handleType) {
      case 'rename':
        _variablesTables[dataIndex].name = name;
        res = yield call(updateSweepContentPromise, [{ ..._variablesTables[dataIndex] }], sweepId);
        break;
      case 'delete':
        res = yield call(deleteSweepContentByIdPromise, id);
        _variablesTables = _variablesTables.filter(item => item.id !== id);
        break;
      // switch to min/max/typ
      case 'changeValue':
        if (type === 'typ') {
          _variablesTables[dataIndex].params = applyAll ? [] : _variablesTables[dataIndex].params.filter(item => item.name !== variable)
        } else {
          // whether apply to all variables
          if (!applyAll) {
            // find the params index that you want to modify
            const paramsIndex = _variablesTables[dataIndex].params.findIndex(item => item.name === variable);
            const newParam = type === 'userDefined' ? { name: variable, type: 'userDefined', value: inputValue } : getVariableByType({ traceConfigs, variable, type });
            if (paramsIndex > -1) {
              _variablesTables[dataIndex].params[paramsIndex] = { ...newParam };
            } else {
              // old data type is 'typ'
              _variablesTables[dataIndex].params.push({ ...newParam });
            }
          } else {
            // Basic data of the variable that is modified：{name: string, type: string, value: Array}
            const variableItem = variables
              .filter(item => item.mode === 'line')
              .find(it => it.name === variable) || {}

            _variablesTables[dataIndex].params = variables
              .filter(item => item.mode === 'line')
              .map((it) => {
                const oldValue = (_variablesTables[dataIndex].params || []).find(p => p.name === it.name) || {}
                return getVariableByType({
                  traceConfigs,
                  variable: it.name,
                  type,
                  typWidth: variableItem.value,
                  inputWidth: inputValue,
                  oldWidth: oldValue.value || it.value,
                  oldValue
                });
              })
              .filter(v => !!v);
          }
        }
        res = yield call(updateSweepContentPromise, [{ ..._variablesTables[dataIndex] }], sweepId);
        break;
      default:
        break;
    }
    // delete api return null
    if (!res && handleType !== 'delete') {
      return;
    }
    yield put(updateExperimentData({ ...experimentData, variablesTables: _variablesTables }));
  } catch (error) {
    console.error(error);
  }
}

function* _renameExperiment(action) {
  const { data } = action;
  const { id, name, channelId, designId } = data;
  if (data && id && name) {
    try {
      const res = yield call(updateSweepConfigsPromise, { id, name });
      if (!res) {
        return;
      }
      sweepConstructor.addSweep(id, res);
      // update treeItems
      yield call(_expandChannel, { channelId, designId, addSweepToCache: false });
    } catch (error) {
      console.error(error);
    }
  }
}

function* _deleteExperiment(action) {
  const { data } = action;
  const { AndesV2Reducer: { project: { selectedKeys }, sweep: { sweepId } } } = yield select();
  const { id, channelId, designId } = data;
  if (data && id) {
    try {
      yield call(deleteSweepBySweepIdPromise, id);
      sweepConstructor.delSweep(id);
      yield call(_expandChannel, { channelId, designId, addSweepToCache: false });
      // update selectedKey, viewList
      if (id === sweepId) {
        //clear info
        yield put(clearSweepInfo());
        let _selectedKeys = [...selectedKeys];
        _selectedKeys = _selectedKeys.filter(d => d !== `${EXPERIMENTS}-${id}`);
        yield put(updateSelectKeys(_selectedKeys));
        yield put(updateViewList(getViewListBySelectedKey(_selectedKeys)));
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function* _changeVariables(action) {
  const { config, checked } = action;
  const { AndesV2Reducer: { sweep: { experimentData, sweepId, notExistNets } } } = yield select();
  const { variables, variablesTables } = experimentData;
  let _variables = [...variables], _variablesTables = [...variablesTables];
  // traceConfigs net -> variable
  // const config = traceConfigs.find(item => item.net = net);
  if (checked) {
    // get variable
    for (const width of config.width) {
      _variables.push({
        mode: config.mode,
        net: config.net,
        type: 'width',
        name: width.variable,
        unit: config.unit,
        value: width.values.find(item => item.type = 'typ').value
      })
    }
  } else {
    // get variables
    let variableNames = []
    if (config.width) {
      variableNames = config.width.map(item => item.variable);
    } else {
      variableNames = variables.filter(item => item.net === config.net).map(it => it.name);
      yield put(updateNotExistNets(notExistNets.filter(item => item !== config.net)));
    }
    // update params
    _variablesTables = variablesTables.map((item) => {
      return { ...item, params: item.params.filter(it => !variableNames.includes(it.name)) }
    })
    const res = yield call(updateSweepContentPromise, _variablesTables, sweepId);
    if (res) {
      _variables = _variables.filter(item => !variableNames.includes(item.name));
    }
  }

  const res = yield call(updateVariablesBySweepIdPromise, sweepId, _variables);
  if (!res) {
    return;
  }
  yield put(updateExperimentData({ variables: _variables, variablesTables: _variablesTables }));
  yield put(updateIsUpdateExperimentData(true));
}

function* _handleTraceSetting(action) {
  const { traceSetting: { min, max, type, changeWay } } = action;
  const { AndesV2Reducer: { sweep: { traceSetting, traceConfigs, experimentData, sweepId } } } = yield select();
  const changeType = [];
  yield put(updateTraceSetting({ min, max, type, changeWay }));

  if (type !== traceSetting.type) {
    changeType.push('type')
  }
  if (min !== traceSetting.min) {
    changeType.push('min')
  }
  if (max !== traceSetting.max) {
    changeType.push('max')
  }
  if (changeWay !== traceSetting.changeWay) {
    changeType.push('changeWay')
  }

  if (!changeType || changeType.length < 1) {
    return;
  }

  const { _variables, _variablesTables, _traceConfigs } = afterChangeTraceSetting({ changeType, traceConfigs, experimentData, sweepId, min, max, type, changeWay });
  const sweep = sweepConstructor.getSweep(sweepId);
  const sweepConfigs = {
    ...sweep,
    sweepConfig: {
      traceConfigs: _traceConfigs,
      traceSetting: { min, max, type, changeWay }
    }
  }
  // save to server
  try {
    yield call(updateSweepConfigsPromise, sweepConfigs);
    yield call(updateVariablesBySweepIdPromise, sweepId, _variables);
    yield call(updateSweepContentPromise, _variablesTables, sweepId);
  } catch (error) {
    console.error(error);
  }

  yield put(updateSweepInfo({ traceConfigs: _traceConfigs, experimentData: { variables: _variables, variablesTables: _variablesTables } }));
  yield put(updateIsUpdateExperimentData(true));
}

function* sweepSaga() {
  yield takeEvery(CREATE_EXPERIMENT, _createNewExperiment);
  yield takeEvery(ADD_EXPERIMENT, _addExperimentInTree);
  yield takeEvery(OPEN_EXPERIMENT, _openExperiment);
  yield takeEvery(ADD_EXPERIMENT_DATE, _addExperimentData);
  yield takeEvery(HANDLE_SIGNAL_EXPERIMENT_DATA, _handleSingleExperimentData);
  yield takeEvery(RENAME_EXPERIMENT, _renameExperiment);
  yield takeEvery(DELETE_EXPERIMENT, _deleteExperiment);
  yield takeEvery(CHANGE_VARIABLES, _changeVariables);
  yield takeEvery(HANDLE_TRACE_SETTING, _handleTraceSetting);
}

export default sweepSaga;