import { PCB, PACKAGE, DCR, IR_EXPLORER, IR_EXPLORER_RESULT, IMPEDANCE, IMPEDANCE_RESULT, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE } from '../../../constants/treeConstants';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import CascadeChannels from '../DB/cascadeChannels';
import designConstructor from '../../helper/designConstructor';


function getPCBPage({ _filterKeys, viewList, projectDesignID }) {
  let newList = [...viewList], pageType = "", id = "";
  _filterKeys = [PCB, PACKAGE];
  newList = viewList.filter(item => _filterKeys.includes(item));
  if (!newList.length) {
    if (!projectDesignID) {
      return { _filterKeys, pageType, id, newList };
    }
    pageType = PCB;
    id = projectDesignID;
  }
  return { _filterKeys, pageType, id, newList }
}

function getSetupPage({ _filterKeys, viewList, projectDesignID, projectId }) {
  let newList = [...viewList], pageType, id;
  _filterKeys = [DCR, IR_EXPLORER, IR_EXPLORER_RESULT, IMPEDANCE, IMPEDANCE_RESULT, POWER_TREE, DESIGN_TREE, SINGLE_TREE];
  newList = viewList.filter(item => _filterKeys.includes(item));
  const dcr = CascadeChannels.getList(DCR, projectId);
  const ir = CascadeChannels.getList(IR_EXPLORER, projectId);
  const impedance = CascadeChannels.getList(IMPEDANCE, projectId);
  const powertree = CascadeChannels.getList(POWER_TREE, projectId);
  const designtree = CascadeChannels.getList(DESIGN_TREE, projectId);
  const singletree = CascadeChannels.getList(SINGLE_TREE, projectId);
  if (!newList.length) {
    if (!projectDesignID) {
      return { _filterKeys, pageType, id, newList };
    }
    if (dcr.length) {
      pageType = DCR;
      id = dcr[0].id;
    } else if (ir.length) {
      pageType = IR_EXPLORER;
      id = ir[0].id;
    } else if (impedance.length) {
      pageType = IMPEDANCE;
      id = impedance[0].id;
    } else if (powertree.length) {
      pageType = POWER_TREE;
      id = powertree[0].id;
    } else if (singletree.length) {
      pageType = SINGLE_TREE;
      id = singletree[0].id;
    } else if (designtree.length) {
      pageType = DESIGN_TREE;
      id = designtree[0].id;
    }
  }
  return { _filterKeys, pageType, id, newList }
}

function getDisplayItem(item, index, detail) {
  if (item.type === 'net') {
    return item.name
  } else if (item.type === 'comp') {
    return index === detail.length - 1 || (detail[detail.length - 1].type === 'connect' && index === detail.length - 2) ?
      item.pins && item.pins.length ? `{${item.name}::${item.pins.map(p => p.pinName).join('::')}}` : `{${item.title ? item.title : item.name}}`
      : `[${item.name}]`;
  } else if (item.type === 'connect') {
    return '(Connect to other PCBs)'
  } else if (item.type === 'pcb') {
    return `(${item.name})`
  } else {
    return undefined
  }
}

function getDisplayDetail(details, extra = {}) {
  const { showFull, layouts, designId } = extra;
  let newDetailList = [];
  let maxLength = 0;
  for (let detail of details) {
    if (detail.length > maxLength) {
      maxLength = detail.length;
    }
    let _detail = Array.isArray(detail) ? JSON.parse(JSON.stringify(detail)) : [];
    if (!showFull) {
      _detail = Array.isArray(detail) ? detail.map((item, index) => {
        return getDisplayItem(item, index, detail)
      }).filter(item => !!item) : [];
      newDetailList.push(_detail);
    } else {
      let _details = getDisplayConnectDetail(_detail, layouts, designId);
      _details = _details.map(d => {
        return d.map((item, index) => {
          return getDisplayItem(item, index, d)
        }).filter(item => !!item);
      })
      newDetailList.push(..._details);
    }
  }
  return newDetailList
}

function getDisplayConnectDetail(detail, layouts, designId, addDesign) {
  if (!detail.find(item => item.type === 'connect')) {
    if (addDesign) {
      const designName = designConstructor.getDesignName(designId);
      return [[{ type: 'pcb', name: designName }, ...detail]];
    }
    return detail;
  }

  let _detail = [...detail], prevConns = [], nextConns = [], detailList = [];
  if (_detail[0].type === 'connect') {
    const connect = _detail.shift();
    const _prevConns = findCurrentConnectPath(connect, layouts, true);
    prevConns = _prevConns.map(prev => {
      const { designId: prevDesignId, monitor } = prev;
      return getDisplayConnectDetail(monitor, layouts, prevDesignId, true);
    }).flat(1)
  }

  const designName = designConstructor.getDesignName(designId);
  _detail = [{ type: 'pcb', name: designName }, ..._detail];

  if (_detail[_detail.length - 1].type === 'connect') {
    const connect = _detail.pop();
    const _nextConns = findCurrentConnectPath(connect, layouts, false);
    nextConns = _nextConns.map(prev => {
      const { designId: nextDesignId, monitor } = prev;
      return getDisplayConnectDetail(monitor, layouts, nextDesignId, true);
    }).flat(1)
  }
  detailList.push(_detail)

  if (prevConns.length) {
    detailList = detailList.map(detail => {
      const _details = []
      prevConns.forEach(prev => { _details.push([...prev, ...detail]) });
      return _details
    }).flat(1)
  }

  if (nextConns.length) {
    detailList = detailList.map(detail => {
      const _details = []
      nextConns.forEach(next => { _details.push([...detail, ...next]) });
      return _details
    }).flat(1)
  }

  return detailList
}

function findCurrentConnectPath(connect, layouts, isPrev) {
  let prevConns = [];
  for (let conn of connect.children) {
    const { designId, name, nets } = conn
    const currentLayout = layouts.find(layout => layout.designId === designId);
    if (!currentLayout) {
      continue;
    }

    for (let domain of currentLayout.powerDomains) {
      const { content: { DEBUG_MONITOR = [] } } = domain;
      for (let monitor of JSON.parse(JSON.stringify(DEBUG_MONITOR))) {
        const currentMonitor = monitor.filter(m => m.type !== 'connect');
        const compIndex = isPrev ? currentMonitor.length - 1 : 0;
        const netIndex = isPrev ? currentMonitor.length - 2 : 1;
        if (currentMonitor[compIndex].name === name && nets.includes(currentMonitor[netIndex].name)) {
          if (isPrev) {
            prevConns.push({ monitor: monitor.slice(0, -1), designId })
          } else {
            prevConns.push({ monitor: monitor.slice(1), designId })
          }
        }
      }
    }
  }

  return prevConns;
}

function getExplorerInfo(dataType) {
  let verificationIndex = -1, defaultKey = 'Task', channelType = '';
  switch (dataType) {
    case DCR:
      verificationIndex = 5;
      defaultKey = 'R_task';
      channelType = 'PathR_Explorer';
      break;
    case IR_EXPLORER:
      verificationIndex = 6;
      defaultKey = 'V_task';
      channelType = 'IR_Explorer';
      break;
    case IMPEDANCE:
      verificationIndex = 7;
      defaultKey = 'Z_task';
      channelType = 'Impedance_Explorer';
      break;
    case POWER_TREE:
      verificationIndex = 3;
      defaultKey = 'PowerTree_task';
      channelType = 'Power_Tree';
      break;
    case SIGN_OFF_TEMPLATE:
      verificationIndex = 4;
      defaultKey = 'SignOffTemplate_task';
      channelType = 'Sign_Off_Template';
      break;
    case DESIGN_TREE:
      verificationIndex = 2;
      defaultKey = 'DesignTree_task';
      channelType = 'Design_Tree';
      break;
    case SINGLE_TREE:
      verificationIndex = 3;
      defaultKey = 'PowerTree_task';
      channelType = 'Single_Power_Tree';
      break;
    default: break;
  }
  return { verificationIndex, defaultKey, channelType }
}

function getSimulationChannels(channels, lastRunningList = []) {
  let runningList = [], finishedList = [];
  for (let channel of channels) {
    const { list = [], key } = channel;
    const running = list.filter(verification => [VERIFY_RUNNING, WAITING].includes(verification.verificationStatus)).map(item => item.verificationId);
    runningList.push(...running.map(id => ({ id, key })));

    if (lastRunningList.length) {
      const lastRunning = lastRunningList.filter(item => item.key === key);
      const finish = lastRunning.filter(item => !running.includes(item.id)).map(item => item.id);
      finishedList.push(...list.filter(item => finish.includes(item.verificationId)).map(item => ({ id: item.verificationId, status: item.verificationStatus, key })))
    }
  }
  return { runningList, finishedList }
}

function getDisplaySensePorts(sensePorts) {
  let sensePortList = [];
  for (let i = 0; i < sensePorts.length; i++) {
    const sense = sensePorts[i];
    if (!sense.powerSensePort && !sense.groundSensePort) {
      sensePortList.push({
        key: i
      })
      continue;
    }
    let pwrSenseList = getDisplayDetail([sense.powerSensePort], true);
    let gndSenseList = sense.groundSensePort ? getDisplayDetail([sense.groundSensePort], true) : [];

    let pwrSense = sense.powerSensePort ? sense.powerSensePort.find(item => !!item.sense) : null

    let gndSense = sense.groundSensePort ? sense.groundSensePort.find(item => {
      return item.sense ? `[${item.name}]` : null;
    }) : null;

    sensePortList.push({
      pwrSensePorts: pwrSenseList.length ? pwrSenseList[0] : [],
      gndSensePorts: gndSenseList.length ? gndSenseList[0] : [],
      pwrSensePins: pwrSense ? [`[${pwrSense.name}]`] : [],
      gndSensePins: gndSense ? [`[${gndSense.name}]`] : [],
      senseInfo: sense,
      key: i
    })
  }
  return sensePortList;
}

export {
  getPCBPage,
  getSetupPage,
  getDisplayDetail,
  getExplorerInfo,
  getSimulationChannels,
  getDisplaySensePorts
}