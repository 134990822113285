import React, { Component, Fragment } from 'react';
import { Select, Radio } from 'antd';
import LayoutData from '@/services/data/LayoutData';
import auroraDBJson from '../../services/Designs/auroraDbData';
import './index.css';
import { ANDES_V2 } from '../../constants/pageType';

const { Option } = Select;
const NONE = 'None', ALL = 'All', SELECT = 'Select';
class AdditionalNets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      netType: NONE,
      pcbNets: []
    }
  }

  componentDidMount = () => {
    this.getAllNets()
  }

  componentDidUpdate = (prevProps) => {
    const { designId, verificationId } = this.props;
    if (designId !== prevProps.designId || (verificationId && verificationId !== prevProps.verificationId)) {
      this.getAllNets()
    }
  }

  getAllNets = () => {
    const { designId, additionalNets, signals, product } = this.props;
    if (!designId) {
      this.setState({
        netType: NONE,
        pcbNets: []
      })
      return;
    }
    let pcbNets = []
    const hasAuroraJson = auroraDBJson.checkAuroraJson(designId)
    if (hasAuroraJson) {
      const _nets = auroraDBJson.getNets(designId);
      pcbNets = [..._nets.keys()]
    } else {
      const layout = LayoutData.getLayout(designId);
      if (layout) {
        const nets = layout.mNetManager.GetAllNets().mValues;
        pcbNets = nets;
      }
    }
    const netType = additionalNets && additionalNets.length ? additionalNets.length === pcbNets.length ? ALL : SELECT : NONE;
    if (product === ANDES_V2 && signals && signals.length) {
      const signalAllNets = signals.map(item => {
        return [
          ...(item.nets_N || []),
          ...(item.nets_P || []),
          ...(item.nets_A || []),
          ...(item.nets_B || []),
          ...(item.nets_C || [])
        ]
      }
      ).flat();
      pcbNets = pcbNets.filter(net => !signalAllNets.includes(net));
    }
    this.setState({
      netType,
      pcbNets
    })
  }

  radioChange = (e) => {
    const type = e.target.value;
    this.setState({
      netType: type
    })
    if (type === ALL) {
      this.props.saveAdditionalNets(this.state.pcbNets)
    } else {
      this.props.saveAdditionalNets([])
    }
  }

  handleChange = (values) => {
    this.props.saveAdditionalNets(values);
  }

  render() {
    const { additionalNets, matchWidth = true } = this.props;
    const { netType, pcbNets } = this.state;
    return (
      <Fragment>
        <span className="font-bold additional-nets-title-color">Additional Nets</span>
        <span className="additional-nets-radio">
          <Radio.Group value={netType} onChange={(e) => this.radioChange(e)}>
            <Radio value={NONE}>None</Radio>
            <Radio value={ALL}>All</Radio>
            <Radio value={SELECT}>Specified</Radio>
          </Radio.Group>
        </span>
        <Select
          mode="multiple"
          className="additional-nets-select"
          value={netType !== SELECT ? [] : additionalNets}
          onChange={this.handleChange}
          disabled={netType !== SELECT ? true : false}
          popupMatchSelectWidth={matchWidth}
        >
          {pcbNets.map(net => <Option key={net}>{net}</Option>)}
        </Select>
      </Fragment>
    )
  }
}

export default AdditionalNets;