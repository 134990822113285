import { select, event, mouse, selectAll } from 'd3-selection';
import { zoom, zoomTransform, zoomIdentity } from 'd3-zoom';
import OutlineLayerRenderer from './OutlineLayerRenderer';
import CanvasObjectList from './CanvasObjectList';
import MetalLayerRenderer from './MetalLayerRenderer';
import CompLayerRenderer from './CompLayerRenderer';
import SelectionLyrRenderer from './SelectionLayerRenderer';
import OverlayLyrRenderer from './OverlayLyrRenderer';
import { unitChange } from '../helper/mathHelper';
import CanvasCircle from './CanvasCircle';
import CanvasPolygon from './CanvasPolygon';
import CanvasPath from './CanvasPath';
import { calculateCompNameTransform, calculateNetNameTransform, calculatePowerNetNameTransform } from '../helper/canvasHelper/canvasMath'
import { isPowerGND } from '../PCBHelper'
import _ from 'lodash';
import { getCenterPoint } from '../helper/cutDesign/hybrid/hybridExtraction';
import { getCanvasTextWidth } from '../helper/getTextWidth';
import { checkNameFormat } from '../helper/nameFormatCheck';
import { numberCheck } from '../helper/dataProcess';
import { calcParallelLine } from '../VirtualComponent/mathHelper';
import { getPointColor } from './PortsCanvas';
import { drag } from 'd3-drag';
import { VC_RLC_TYPES, VC_SHUNT_TYPES } from '../VirtualComponent';


const SELECTION_COLOR = 'magenta';
const DARKENED_COLOR = '#222222';
const VERTICAL = 'vertical', HORIZONTAL = 'horizontal', SLASH = 'slash';
const POWERNET = 'powerNet', SIGNALNET = 'signalNet';

let showLocationMark = false, isZoom = false;
/**
 * 
 * @param {dom} svgEle canvas dom element
 * @param {object} layoutDB the layout design
 * @param {dom} locationSvg location dom element
 * @param {object} events zoom and mousemove event functions
 * svg structure
 * <svg> <!-- controls drawing scaling and centering -->
 *  <g transform=...> <!-- controls view zooming and shifting -->
 *    <!-- layer groups -->
 *    <g layer=...> </g>
 *    <g layer=...> </g>
 *  </g>
 * </svg>
 */
function LayoutRenderer(svgEle, layoutDB, locationSvg, events, updateClickMousePosition, isPortSetup = false) {
  this.svgNode = svgEle;
  this.locationSvg = locationSvg;
  // root group
  this.d3Root = select(svgEle)
    .append('g')
    .attr('transform', 'translate(0, 0) scale(1, -1)')
    .attr('fill-rule', 'evenodd');

  // this.d3CoordText = d3.select(canvasElement.find('div.canvas-coord')[0]);

  select('g')
    .append('g')
    .attr('class', 'stackup-zones-box');

  this.layoutDB = layoutDB; //record the layoutdb

  /** the scaling factor when the design is fit to the canvas */
  this.fitScaling = 1.0;
  // svg viewBox
  this.viewBoxXc = 0;
  this.viewBoxYc = 0;
  this.outlineStrokeWidth = 1.0;

  /** container of the LayerRenderer */
  this.layerList = [];
  this.checkCompLayers = [];

  /** render settings */
  this.colorByLayer = null;
  this.fillMode = null;
  this.hideComponents = false;

  // TODO - consider move this section to a centralized setting management factory
  this.netInfo = []; // record the net information and controls the net colors
  this.initNetInfo();

  // select setting
  this.selectedComps = [];

  // net and comp name Info
  this.netNameInfo = [];
  this.compNameInfo = [];

  // grid based lines 
  this.verticalLines = []
  this.horizontalLines = []

  // set the zooming event handling
  const _scaleExtent = isPortSetup ? [0.05, 400] : [0.05, 120];
  this.zoomCtrl = zoom()
    /* .scaleExtent([0.05, 100]) */
    .scaleExtent(_scaleExtent)
    .on("zoom", () => {
      const { k, x, y } = event.transform;
      if (!isZoom) {
        isZoom = true;
        requestAnimationFrame(() => {
          this.d3Root.attr('transform', `translate(${x}, ${y}) scale(${k},-${k})`);
          isZoom = false;
        })
        this.updateStrokeWidth();
      }
    });

  select(this.svgNode).call(this.zoomCtrl);

  const mousemoveCallback = events.mousemove; //mousemove callback
  this.clickCallback = events.click;
  const _this = this;
  select(this.svgNode).on('mousemove', () => {
    const [x, y] = mouse(this.d3Root.node());
    mousemoveCallback(x, y);
  }).on('click', function () {
    _this.removePort();
    const [x, y] = mouse(_this.d3Root.node());
    if (updateClickMousePosition) {
      updateClickMousePosition({ x, y });
    }
    if (event.defaultPrevented) return;
    if (!event.ctrlKey && !event.shiftKey) { //&& !impedanceData.isViewing()) {
      events.click();
    }
    //remove
    selectAll(`g.port-setup-canvas-pin-g`).remove();
  });

}; // LayoutRenderer

LayoutRenderer.prototype.setCanvasType = function (type) {
  this.canvasType = type;
}

LayoutRenderer.prototype.setShowPinList = function (showPinList, onlyHiddenPins = false) {
  this.showPinList = showPinList;
  this.onlyHiddenPins = onlyHiddenPins;
}

/** Calculate the canvas size from the svg element size and the layout boundary
    The canvas size is set to be 10 times the screen size. This is used as the zoom-out
    limit so that the design won't look too small.
  */
LayoutRenderer.prototype.resizeCanvas = function () {

  if (!this.layoutDB || !this.layoutDB.GetProfile) return;
  // get the bounding box of the outline
  var designOutline = this.layoutDB.GetProfile();
  if (!designOutline) return;
  var boundingBox = designOutline.BoundingBox();

  var x = boundingBox.Left() - 0.05 * boundingBox.Width();
  var y = -(boundingBox.Top() + 0.05 * boundingBox.Height());
  var w = boundingBox.Width() * 1.1;
  var h = boundingBox.Height() * 1.1;

  select(this.svgNode)
    .attr('viewBox', x + ' ' + y + ' ' + w + ' ' + h);
  this.viewBoxXc = x + 0.5 * w;
  this.viewBoxYc = y + 0.5 * h;
  this.viewBoxWidth = w;
  this.viewBoxHeight = h;

  var viewW = this.svgNode.clientWidth;
  var viewH = this.svgNode.clientHeight;

  // calculate the scaling factor and shifting
  var ratioX = 0.9 * viewW / boundingBox.Width();
  var ratioY = 0.9 * viewH / boundingBox.Height();

  this.fitScaling = Math.min(ratioX, ratioY);

  // set the global line width
  select(this.svgNode).attr('stroke-width', 1 / this.fitScaling);
  this.updateStrokeWidth();
};


/** Reset the canvas background and layer containers .
  The canvas background is a container that is responsible for the zooming and shifting.
  Each layer corresponds to a SVG group. This is important because we need to consider
  the overlapping relationship when rendering multiple layers. Also it makes it easy to
  turn on and turn off a centain layer.
  */
LayoutRenderer.prototype.resetCanvas = function () {

  // remove the old group elements of the layers
  this.d3Root.selectAll('g').remove();

  // calculate the scaling and shifting according to the layout boundary
  this.resizeCanvas();

  // set the coloring of nets or layers
  this.colorByLayer = null; // clear the flags to force netcolor updating
  this.fillMode = null;
  this.updateColoring();

  // clear the layer list
  this.layerList = [];

  // add a layer for outline
  var outlineRenderer = new OutlineLayerRenderer(this, '__OUTLINE__');
  this.layerList.push(outlineRenderer);
  outlineRenderer.prepareData();
  outlineRenderer.redraw(); // draw the outline immediately

  // add the metal layer place holders
  var metalLayers = this.layoutDB.GetLayerManager().GetAllMetalLayers();
  for (var i = metalLayers.size() - 1; i >= 0; i--) {

    // add the metal layer itself
    var layerName = metalLayers.get(i);
    var visible = this.layoutDB.GetLayoutSettings().IsLayerVisible(layerName);
    var metalLayerRenderer = new MetalLayerRenderer(this, layerName, !visible, this.clickCallback);
    this.layerList.push(metalLayerRenderer);
  }
}; // LayoutRenderer.prototype.resetCanvas

LayoutRenderer.prototype.resetSvg = function (svgEle, locationSvg, events, updateClickMousePosition) {
  this.svgNode = svgEle;
  this.locationSvg = locationSvg;
  this.d3Root = select(svgEle)
    .append('g')
    .attr('transform', 'translate(0, 0) scale(1, -1)')
    .attr('fill-rule', 'evenodd');


  select('g')
    .append('g')
    .attr('class', 'stackup-zones-box');

  // set the zooming event handling
  this.zoomCtrl = zoom()
    .scaleExtent([0.05, 100])
    .on("zoom", () => {
      const { k, x, y } = event.transform;
      if (!isZoom) {
        isZoom = true;
        requestAnimationFrame(() => {
          this.d3Root.attr('transform', `translate(${x}, ${y}) scale(${k},-${k})`);
          isZoom = false;
        })
        this.updateStrokeWidth();
      }
    });

  select(this.svgNode).call(this.zoomCtrl);
  const mousemoveCallback = events.mousemove; //mousemove callback
  this.clickCallback = events.click;
  const _this = this;
  select(this.svgNode).on('mousemove', () => {
    const [x, y] = mouse(this.d3Root.node());
    mousemoveCallback(x, y);
  }).on('click', function () {
    _this.removePort();
    const [x, y] = mouse(_this.d3Root.node());
    if (updateClickMousePosition) {
      updateClickMousePosition({ x, y });
    }
    if (event.defaultPrevented) return;
    if (!event.ctrlKey && !event.shiftKey) { //&& !impedanceData.isViewing()) {
      events.click();
    }
    //remove
    selectAll(`g.port-setup-canvas-pin-g`).remove();
  });

}

LayoutRenderer.prototype.resetCanvasAfterSvgNodeChange = function () {
  // remove the old group elements of the layers
  this.d3Root.selectAll('g').remove();
  for (let layerItem of this.layerList) {
    layerItem.rendered = false;
    layerItem.layerElement = this.d3Root
      .append('g')
      .attr('layer', layerItem.layerName)
      .attr('hidden', null);
    // rebind this.clickCallback function
    if (layerItem.clickCallback) {
      layerItem.clickCallback = this.clickCallback;
    }
    if (layerItem.layerName === '__OUTLINE__') {
      layerItem.rendered = true;
      layerItem.redraw();
    }
  }
}
/**
 * prepare layer data by name
 */
LayoutRenderer.prototype.addMetalLayer = function (layerName) {

  for (var i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i].layerName === layerName) {

      this.layerList[i].prepareData();

      // add a component layer, if any
      var layerObj = this.layoutDB.GetLayerManager().GetMetalLayer(layerName);
      var compLayer = layerObj.GetComponentLayer();
      if (compLayer) {
        var compLayerName = compLayer.GetName();
        // component layer visibility is tied to the metal layer
        var visible = this.hideComponents ? false : this.layoutDB.GetLayoutSettings().IsLayerVisible(layerName);
        var compLayerRenderer = new CompLayerRenderer(layerObj, this, compLayerName, !visible, this.clickCallback, this.milToUnit);
        compLayerRenderer.setShowPinList(this.showPinList, this.onlyHiddenPins)
        this.layerList.push(compLayerRenderer);
        compLayerRenderer.prepareData();
      }
    }
  }

  this.checkCompLayers = this.layerList.filter(item => item instanceof CompLayerRenderer).map(layer => {
    let comps = layer.compObjList.map(item => (item.name));
    return {
      name: layer.layerName,
      compList: comps
    }
  })

}; // LayoutRenderer.prototype.addMetalLayer

// fit the size
LayoutRenderer.prototype.fitView = function () {

  // re-calculate the bounding box
  this.resizeCanvas();

  // clear the zooming shifting, todo
  // this.zoomCtrl.scaleTo(1).translate([0, 0]);
  this.d3Root.attr('transform', 'translate(0, 0) scale(1.0, -1.0)');
  this.svgNode.__zoom = new zoomIdentity.constructor(1, 0, 0);
  this.updateStrokeWidth();
};

/** Handles the zoom event
 *  @param ratio - if the x and y parameters are not passed, this is the zoom ratio relative
 *               to the previous zoom. Otherwise it is the actual scaling factor calculated
 *               by the d3 zoom event
 *  @param x - x coordinate of the d3 translate, set to undefined if using the zoom buttons
 *  @param y - y coordinate of the d3 translate, set to undefined if using the zoom buttons
 */
LayoutRenderer.prototype.zoom = function (ratio, x, y) {

  var xc, yc, scale;
  if (x === undefined || y === undefined) {

    // relative zoom, responding to the zoom button, zoom about the screen center
    // The assumption is that the center location is not changed after the zoom.
    // before zooming, the center coordinate is
    //     (1)   Xc = x * s1 + dx1
    // where Xc is the center coordinate of the SVG view box, x is actual coordinate
    // in the g element of zoom control, s1 is the previous scaling, and dx1 is the
    // previous shift.
    // From (1) we can calculate the actual coordinate as
    //     (2)   x = (Xc - dx1) / s1
    // Now after the zoom, the center coordinate remain the same, so we have
    //     (3)   Xc = x * s2 + dx2
    // where s2 and dx2 is the new scaling and shifting, respectively. The scaling
    // factor is known, so we only need to determine the shifting.
    // Substitute (2) into (3) leads to
    //     (4)   dx2 = Xc * (1 - s2 / s1) + dx1 * s2 / s1
    //
    const t = zoomTransform(this.svgNode);
    xc = this.viewBoxXc * (1 - ratio) + t.x * ratio;
    yc = this.viewBoxYc * (1 - ratio) + t.y * ratio;

    // remember this setting in the zoom control object
    // this.zoomCtrl.translate([xc, yc]);
    scale = t.k * ratio;
    // get the scale from the zoom object so that it won't be out of bound
    if (scale < 0.05) scale = 0.05;
    else if (scale > 100) scale = 100;
  } else {
    // Responding to the mouse event, the shifting and scaling are calculated by
    // d3. In this mode, the parameter 'ratio' is the actual scaling factor.
    xc = x;
    yc = y;
    scale = ratio;
  }

  this.svgNode.__zoom = new zoomIdentity.constructor(scale, xc, yc);
  this.d3Root.attr('transform', 'translate(' + xc + ' ' + yc + ') scale(' + scale + ',-' + scale + ')');
  this.updateStrokeWidth();

}; // zoom

/** Zoom to a rectangular region
 *  @param x - x coordinate of the rectangle's top left corner
 *  @param y - y coordinate of the rectangle's top left corner
 *  @param w - width of the rectangle
 *  @param h - height of the rectangle
 */
LayoutRenderer.prototype.zoomToBox = function (x, y, w, h, rate = 0.9) {
  if (w >= 0 && h >= 0) {

    // find the optimum scaling factor
    var scale = Math.min(this.viewBoxWidth / w, this.viewBoxHeight / h) * rate;

    // run through the zoom object limit check
    if (scale < 0.05) scale = 0.05;
    else if (scale > 100) scale = 100;

    // this.zoomCtrl.scale(scale);
    // scale = this.zoomCtrl.scale();

    // find the shifting so that the center point of the box is located at the view
    // center after the zoom
    //   Xc_view = xc_box * s + dx
    // where Xc_view is the view center, xc_box is the center coordinate of the box,
    // s is the scaling factor, and dx is the shift
    var dx = this.viewBoxXc - (x + 0.5 * w) * scale;
    var dy = this.viewBoxYc + (y + 0.5 * h) * scale; // the y coordinate is reverted
    // var dx = this.viewBoxXc - x * scale;
    //var dy = this.viewBoxYc + y * scale; // the y coordinate is reverted
    // this.zoomCtrl.translate([dx, dy]);
    // const t = zoomIdentity.translate(dx, dy).rescaleX();
    this.svgNode.__zoom = new zoomIdentity.constructor(scale, dx, dy);
    this.d3Root
      .transition()
      .duration(750)
      .attr('transform', `translate(${dx},${dy}) scale(${scale},-${scale})`);

    this.updateStrokeWidth();
  }
}; // LayoutRenderer.prototype.zoomToBox

LayoutRenderer.prototype.zoomToLocate = function (x, y) {
  var scale = 9,
    dx = this.viewBoxXc - x * scale,
    dy = this.viewBoxYc + y * scale;

  // this.zoomCtrl.scale(scale);
  // this.zoomCtrl.translate([dx, dy]);
  this.svgNode.__zoom = new zoomIdentity.constructor(scale, dx, dy);
  this.d3Root
    .transition()
    .duration(750)
    .attr('transform', 'translate(' + dx + ', ' + dy + ') scale(' + scale + ',-' + scale + ')');
  this.updateStrokeWidth();
  showLocationMark = true && select(this.locationSvg).style('display', 'block')
};

LayoutRenderer.prototype.updateStrokeWidth = function () {
  // outline stroke width is set to be fixed visually regardless of the zoom
  var width1 = 2 / this.fitScaling;
  var width2 = 3 / (this.fitScaling * zoomTransform(this.svgNode).k);
  this.outlineStrokeWidth = Math.min(width1, width2);

  // do not use this.fillMode for the if statement below because it might not be assigned at
  // the beginning
  if (!this.layoutDB.GetLayoutSettings().InFilledMode()) {
    // for non-filling mode, this is a global setting
    select(this.svgNode).attr('stroke-width', this.outlineStrokeWidth);
  }
  if (showLocationMark) {
    select(this.locationSvg).style('display', null);
    showLocationMark = false;
  }
  if (this.showCompNetName) {
    let size = width2 / width1;
    let signalNetSize, compSize, powerNetSize;
    if (size < 0.1) {
      signalNetSize = 0.6;
      powerNetSize = 0.15;
      compSize = 0.7;
    } else if (size < 0.15) {
      signalNetSize = 0.6;
      powerNetSize = size * 1.5;
      compSize = size * 7
    } else if (size < 0.2) {
      signalNetSize = size * 4;
      powerNetSize = size * 1.5;
      compSize = size * 7
    } else if (size < 0.31) {
      signalNetSize = size * 4;
      powerNetSize = size * 1.5;
      compSize = size * 7
    } else if (size < 0.7) {
      signalNetSize = size * 4;
      powerNetSize = size * 1.5;
      compSize = 2.2;
    } else {
      signalNetSize = 2.8;
      powerNetSize = 1.05
      compSize = 2.2;
    }

    const LayoutInfo = this
    select(this.svgNode).select('g').select('.netNameBox').selectAll('text').each(function (d) {
      const nameInfo = LayoutInfo.netNameInfo.find(it => it.netName === this.innerHTML);
      if (nameInfo) {
        const size = nameInfo.netType === SIGNALNET ? signalNetSize : powerNetSize;
        LayoutInfo.zoomCompNetName(nameInfo, size, this, 'net')
      }
    })
    select(this.svgNode).select('g').select('.compBox').selectAll('text').each(function (d) {
      const compInfo = LayoutInfo.compNameInfo.find(it => it.compName === this.innerHTML);
      if (compInfo) {
        LayoutInfo.zoomCompNetName(compInfo, compSize, this, 'comp')
      }
    })
  }
  for (var i = 0; i < this.layerList.length; i++)
    this.layerList[i].updateStrokeWidth();
};

LayoutRenderer.prototype.zoomCompNetName = function (nameInfo, size, svgData, type) {
  if (nameInfo) {
    const { xTrans, yTrans, fontSize, netType, x, y, netName } = nameInfo
    let _fontSize = Math.floor(fontSize * size * 100) / 100;
    let _xTrans = xTrans * size
    let _yTrans = yTrans * size
    let translateX = x, translateY = y;

    if (type === 'comp') {
      const { _x, _y } = calculateCompNameTransform({ ...nameInfo, xTrans: _xTrans, yTrans: _yTrans }, [])
      translateX = _x;
      translateY = _y;
    } else {
      if (netType === SIGNALNET) {
        const netData = calculateNetNameTransform([{ ...nameInfo, xTrans: _xTrans, yTrans: _yTrans }], [], 0, 0.6)
        translateX = netData.translateX;
        translateY = netData.translateY;
      } else {
        _xTrans = parseFloat(this.milToUnit(netName.length * _fontSize / 4));
        translateX = x - _xTrans / 2;
        translateY = nameInfo.translateY;
      }
    }

    const textData = select(svgData)
      .attr('transform', `translate(${translateX},${translateY}) rotate(${nameInfo.rotate}) scale(-1,1)`)
      .style('font-size', _fontSize)
      .attr('stroke-width', _fontSize / 10)
    if (type === 'net' && netType === POWERNET) {
      const boxInfo = textData.node().getBBox();
      let paddingLeft = boxInfo.width * 0.05;
      let paddingTop = boxInfo.height * 0.1;
      const _netName = netName.replace(/[^a-zA-Z0-9]/g, 'a');
      const rectData = select(`.rect-${_netName}`)
      rectData.attr("x", translateX - paddingLeft)
        .attr("y", translateY - (2.5 * paddingTop))
        .attr("width", boxInfo.width + 1.7 * paddingLeft)
        .attr("height", boxInfo.height + paddingTop)
    }
  }
}

LayoutRenderer.prototype.updateColoring = function () {

  if (!this.layoutDB) {
    return;
  }
  var plotSettings = this.layoutDB.GetLayoutSettings();
  this.colorByLayer = plotSettings.ColorByLayer();
  this.fillMode = plotSettings.InFilledMode();

  this.updateNetColors();

  // call each layer to update
  for (var i = 0, len = this.layerList.length; i < len; i++) {
    this.layerList[i].updateColoring();
  }

  // TODO - also need to re-select the canvas objects and components

};

/** refresh the display with visibility change */
LayoutRenderer.prototype.updateLayerVisibility = function () {

  for (var i = 0, len = this.layerList.length; i < len; i++) {
    this.layerList[i].updateVisibility();
  }
};

LayoutRenderer.prototype.getCurrentLayer = function (comps) {
  let layerList = [];
  let compLayer = [...this.checkCompLayers];
  compLayer.forEach(layer => {
    comps.forEach(comp => {
      if (layer.compList.includes(comp)) {
        layerList = [...layerList, layer.name];
      }
    })
  })
  return layerList;
}

/** Deselect a list of components
 *  arrCompNames - array of the names of the components to be deselected
 */
LayoutRenderer.prototype.deselectComponents = function () {
  if (!this.selectedComps.length) return;
  const arrCompNames = [].concat(this.selectedComps);
  const arrPins = { ...this.selectedPins };
  const arrConnectPins = { ...this.selectedConnectPins };
  for (var i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].setCompSelection(arrCompNames, false);
      this.layerList[i].setPinSelection(arrPins, false)
      this.layerList[i].setPinSelection(arrConnectPins, false)
    }
  }
};


//
// TODO - consider move the net coloring handling to a centralized setting manager factory

/** Get the net colors from the layout settings, and initialize the net information array */
LayoutRenderer.prototype.initNetInfo = function () {
  if (!this.layoutDB) {
    return;
  }

  var plotSettings = this.layoutDB.GetLayoutSettings();
  var netList = this.layoutDB.GetNetManager().GetNetList();

  this.netInfo = [];
  for (var iNet = 0; iNet < netList.size(); iNet++) {
    var netName = netList.getKey(iNet);

    this.netInfo.push({
      name: netName,
      visible: true, // true = displayed, false = hidden
      selected: false, // true = display in selection color, false = normal or dark color
      darkened: false, // true = display in dark color, false = normal color
      original_color: plotSettings.GetNetColor(netName),
      display_color: null, // this is passed from specific display request, such as the interface selection
      canvas: {
        // these are the attributes derived from above, and can be applied
        // to the svg elements directly by d3
        hidden: null,
        stroke: null,
        fill: null
      }
    });
  }

}; // LayoutRenderer.prototype.initNetInfo

/** determine the display color for each net */
LayoutRenderer.prototype.updateNetColors = function () {

  for (var i = 0, len = this.netInfo.length; i < len; i++) {

    var netInfo = this.netInfo[i];
    var color = this.colorByLayer ? null : netInfo.original_color;

    if (netInfo.selected) {
      netInfo.canvas.hidden = null; // selected net is always visible if the layer is visible
      color = SELECTION_COLOR; // and displayed in selection color
    } else {
      netInfo.canvas.hidden = netInfo.visible ? null : true;
      if (netInfo.display_color)
        color = netInfo.display_color; // assigned display color when all other nets are darkened
      else if (netInfo.darkened)
        color = DARKENED_COLOR;

    }

    netInfo.canvas.stroke = this.fillMode ? null : color;
    netInfo.canvas.fill = this.fillMode ? color : null;

  } // for (var i = 0, len = this.netInfo.length; i < len; i++)

}; // LayoutRenderer.prototype.updateNetColors

/** Set all nets in cark color */
LayoutRenderer.prototype.darkenAllNets = function () {
  for (var i = 0, len = this.netInfo.length; i < len; i++) {
    this.netInfo[i].darkened = true;
  }

  this.updateColoring();
};

/** Set all nets in normal color */
LayoutRenderer.prototype.unDarkenAllNets = function () {
  for (var i = 0, len = this.netInfo.length; i < len; i++) {
    this.netInfo[i].darkened = false;
    this.netInfo[i].display_color = null;
  }

  this.updateColoring();
};

LayoutRenderer.prototype.transparentCompLyr = function (opacity) {
  for (var i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      if (opacity) {
        this.layerList[i].layerElement.attr('opacity', '0.2');
      } else {
        this.layerList[i].layerElement.attr('opacity', '');
      }
    }
  }
};

LayoutRenderer.prototype.addSelectionLyr = function () {

  this.selectionLayer = new SelectionLyrRenderer(this, 'selection');

};

LayoutRenderer.prototype.addOverlayLyr = function () {

  this.overlayLayer = new OverlayLyrRenderer(this, 'overlay');

};

LayoutRenderer.prototype.deselectWholeNet = function () {
  this.selectionLayer.clear();
};

LayoutRenderer.prototype.changeViaMode = function (hasVia) {
  this.hasVia = hasVia;
  for (var i = 0, len = this.layerList.length; i < len; i++) {
    var layer = this.layerList[i];
    if (layer instanceof MetalLayerRenderer && layer.rendered) {
      layer.layerElement.html('');
      layer.rendered = false;
      if (this.layoutDB.GetLayoutSettings().IsLayerVisible(layer.layerName))
        layer.redraw();
    }
  }
  if (this.selectionLayer && this.selectionLayer.d3NetGroups) {
    this.selectionLayer.layerElement.html('');
    this.selectionLayer.redraw();
  }
  if (this.hasWidth === false) {
    this.updateStrokeWidth();
  }
}

LayoutRenderer.prototype.changeWidthMode = function (hasWidth) {
  this.hasWidth = hasWidth;
  if (hasWidth) {
    for (var i = 0, len = this.layerList.length; i < len; i++) {
      this.layerList[i].layerElement
        .selectAll('polyline')
        .attr('stroke-width', function (net) {
          return net.width;
        });

    }
  } else {
    this.updateStrokeWidth();
  }

}

LayoutRenderer.prototype.drawBoundaries = function (defaultHull, vertices) {
  this.cleanBoundaries();
  let str = "M";

  if (!Array.isArray(defaultHull)) {
    return;
  }
  // mil to unit
  const milToUnit = (num) => {
    return unitChange({
      num: parseFloat(num),
      newUnit: this.layoutDB.mUnits === 'mils' ? 'mil' : this.layoutDB.mUnits,
      decimals: 12
    }).number;
  }
  defaultHull.forEach(d => {
    str += `${d[0]},`
    str += `${d[1]}L`
  })
  str = str.slice(0, str.length - 1)
  str += 'Z'
  select(this.svgNode)
    .select('g')
    .append("g")
    .attr("class", "boundaryline")
    .append("path")
    .attr("d", str)
    .attr("stroke-width", milToUnit(2))
    .style("fill-opacity", 0.2)
    .style("fill", "#DDD")
    .style("stroke", "#BBB")

  // Debug
  // select(this.svgNode)
  //   .select('g')
  //   .append("g")
  //   .attr("class", "boundary")
  //   .selectAll("circle")
  //   .data(defaultHull)
  //   .enter().append("circle")
  //   .attr("cx", d => d[0])
  //   .attr("cy", d => d[1])
  //   .attr("r", milToUnit(2))
  //   .style("fill", "red")
}

LayoutRenderer.prototype.cleanBoundaries = function () {
  if (this.d3Root.selectAll('g.boundaryline')) {
    this.d3Root.selectAll('g.boundaryline').remove();
  }
  if (this.d3Root.selectAll('g.boundary')) {
    this.d3Root.selectAll('g.boundary').remove();
  }
  if (this.d3Root.selectAll('g.vertices')) {
    this.d3Root.selectAll('g.vertices').remove();
  }
}

LayoutRenderer.prototype.drawHybridBoundaries = function (defaultHull, centerPoint, name) {
  this.cleanHybridBoundaries(name);

  let str = "M";
  if (!Array.isArray(defaultHull)) {
    return;
  }
  defaultHull.forEach(d => {
    if (d) {
      str += `${d[0]},`
      str += `${d[1]}L`
    }
  })
  str = str.slice(0, str.length - 1)
  str += 'Z'
  select(this.svgNode)
    .select('g')
    .select('g.hybrid-boundary-regions-box')
    .append("g")
    .attr("class", `hybridBoundaryLine hybridBoundaryLine-${name}`)
    .append("path")
    .attr("d", str)
    .text(name)
    .attr("stroke-width", this.milToUnit(2))
    .style("fill-opacity", 0.2)
    .style("fill", "#DDD")
    .style("stroke", "#BBB")

  const xTranslate = this.milToUnit(68);
  select(this.svgNode)
    .select('g')
    .select('g.hybrid-boundary-regions-box')
    .append("text")
    .attr("class", `hybridBoundaryText hybridBoundaryText-${name}`)
    .text(name)
    .attr('transform', `translate(${centerPoint[0] - xTranslate},${centerPoint[1]}) rotate(-180) scale(-1,1)`)
    .style('font-size', `${this.milToUnit(68)}px`)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#fdc647')
}

LayoutRenderer.prototype.cleanHybridBoundaries = function (name) {

  if (name) {
    if (this.d3Root.selectAll(`g.hybridBoundaryLine-${name}`)) {
      this.d3Root.selectAll(`g.hybridBoundaryLine-${name}`).remove();
    }
    if (this.d3Root.selectAll(`text.hybridBoundaryText-${name}`)) {
      this.d3Root.selectAll(`text.hybridBoundaryText-${name}`).remove();
    }
    return;
  }

  if (this.d3Root.selectAll('g.hybridBoundaryLine')) {
    this.d3Root.selectAll('g.hybridBoundaryLine').remove();
  }
  if (this.d3Root.selectAll('text.hybridBoundaryText')) {
    this.d3Root.selectAll('text.hybridBoundaryText').remove();
  }
}

LayoutRenderer.prototype.drawHybridRegionBox = function () {
  if (this && select(this.svgNode)) {
    const ele = document.getElementsByClassName("hybrid-boundary-regions-box");
    if (ele && ele[0]) {
      return;
    }

    select(this.svgNode)
      .select('g')
      .append('g')
      .attr('class', 'hybrid-boundary-regions-box');
  }
}

// Highlight nets
LayoutRenderer.prototype.highlightSelectedNets = function (nets, showName, designId, layoutDB) {
  this.showCompNetName = showName;
  if (showName) {
    select(this.svgNode)
      .select('g')
      .append('g')
      .attr('class', 'selectGroupNames')
  }
  if (!nets.length) return;
  this.selectionLayer.prepareData(nets);
  this.selectionLayer.redraw(nets);
  if (showName) {
    this.showNetsName(nets, false, designId, layoutDB);
  }
}

LayoutRenderer.prototype.showSelectedCompsPins = function (pins = {}) {
  let _comps = [];
  if (Object.keys(pins).length) {
    _comps = [...new Set([...Object.keys(pins)])];
  }

  let svgNode = select(this.svgNode)
    .select("g")
    .append("g")
    .attr("class", "selectPinsNames")

  this.selectedComps = [].concat(_comps);
  this.selectedPins = { ...pins };
  if (!_comps.length) return;
  this.compNameInfo = []
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].highlightPinSelection(pins, svgNode);
    }
  }
}

LayoutRenderer.prototype.highlightSelectedCompsPins = function (comps, pins = {}, showName = false, connectPins = {}) {
  let _comps = [...comps];
  if (Object.keys(pins).length) {
    _comps = [...new Set([..._comps, ...Object.keys(pins)])];
  }

  let svgNode = select(this.svgNode)
    .select('g')
    .select('.selectGroupNames')

  const compBox = svgNode.append('g').attr('class', 'compBox')

  this.selectedComps = [].concat(_comps);
  this.selectedPins = { ...pins };
  this.selectedConnectPins = { ...connectPins };
  if (!_comps.length) return;
  this.compNameInfo = []
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].setCompSelection(_comps, true);
      if (!showName) {
        this.layerList[i].setPinSelection(pins, true);
        this.layerList[i].setPinSelection(connectPins, true, 'darkcyan');
      } else {
        const keys = Object.keys(pins);
        this.layerList[i].hightlightCompOutline(keys, true)
        const findCompInfo = this.layerList[i].compObjList.filter(item => comps.includes(item.name))
        const _pins = this.layerList[i].findPins(pins)
        const _connects = this.layerList[i].findPins(connectPins)
        // show comps Name
        this.showCompsName({ svgNode, findCompInfo, compBox, color: '#FF9800' })
        // show pins Name
        if (_pins.length) {
          this.showPort({ svgNode, pins: _pins, color: 'yellow' })
        }
        if (_connects.length) {
          this.showConnectPins({ svgNode, pins: _connects, color: 'darkcyan' })
        }
      }
    }
  }
}

LayoutRenderer.prototype.zoomToCompNetSelectionBox = function ({ nets, comps }, rate) {
  let boxes = [];
  for (let comp of comps) {
    let compElement = this.d3Root.selectAll('[comp="' + comp + '"]');
    if (!compElement.node()) continue;
    let { x, y, height, width } = compElement.node().getBBox();
    boxes.push(...[{ x, y }, { x: x + width, y: y + height }]); // value of all components{ X, Y, width, height }
  }
  if (nets.length) {
    const { x: nX, y: nY, height: nH, width: nW } = this.selectionLayer.layerElement.node().getBBox();
    boxes.push(...[{ x: nX, y: nY }, { x: nX + nW, y: nY + nH }])
  }
  const xArr = boxes.map(d => d.x), yArr = boxes.map(d => d.y);
  const xMin = Math.min(...xArr), xMax = Math.max(...xArr), yMin = Math.min(...yArr), yMax = Math.max(...yArr);
  // rate
  const width = xMax - xMin, height = yMax - yMin;
  const r = Math.min(this.viewBoxWidth / width, this.viewBoxHeight / height);
  let _rate = rate;
  if (!_rate) {
    if (r > 30) {
      _rate = 0.1;
    } else if (r > 10) {
      _rate = 0.3;
    } else if (r > 3) {
      _rate = 0.8;
    } else {
      _rate = 0.9;
    }
  }
  this.zoomToBox(xMin, yMin, width, height, _rate);
}

LayoutRenderer.prototype.highlightPortsGroups = function (portsGroups, allowRightMouseClick) {
  const _portsGroup = JSON.parse(JSON.stringify(portsGroups));

  this.removePort();
  const svgNode = select(this.svgNode)
    .select('g')
    .append('g')
    .attr('class', 'portsGroups')

  for (let group of _portsGroup) {
    const { positivePorts, negativePorts, showLine, showText } = group;
    for (let i = 0, len = this.layerList.length; i < len; i++) {
      if (this.layerList[i] instanceof CompLayerRenderer) {
        const positivePins = this.layerList[i].findPins(positivePorts);
        const negativePins = this.layerList[i].findPins(negativePorts);
        if (negativePins.length) {
          this.showPort({ svgNode, pins: negativePins, direction: 'negative', color: 'blue', posPins: positivePins, showLines: showLine, showText, allowRightMouseClick })
        }
        if (positivePins.length) {
          this.showPort({ svgNode, pins: positivePins, direction: 'positive', color: 'red', showText, allowRightMouseClick })
        }
        if (allowRightMouseClick) {
          this.layerList[i].addColorToPortPins({ ports: negativePorts, direction: "negative" });
          this.layerList[i].addColorToPortPins({ ports: positivePorts, direction: "positive" });
        }
      }
    }
  }

  this.zoomToPortsGroupsBox()
}

LayoutRenderer.prototype.highlightVias = function (viaName) {
  const viaNode = select(this.svgNode)
    .selectAll(`g[name="${viaName}"]`)
  viaNode.attr('class', 'selected-via')
}

LayoutRenderer.prototype.unHighlightVias = function (viaName) {
  const viaNode = select(this.svgNode)
    .selectAll(`g[name="${viaName}"]`)
  viaNode.classed('selected-via', false)
}

LayoutRenderer.prototype.removeHybridPoints = function (lineName) {
  if (!lineName) {
    select(this.svgNode).select('g').selectAll(`.hybrid-points`).remove();
    return;
  }

  select(this.svgNode).select('g').selectAll(`.hybrid-points-${lineName}`).remove();
  select(this.svgNode).select('g').selectAll(`.hybridPointBox-${lineName}`).remove();
  select(this.svgNode).select('g').selectAll(`.hybridPointToolTip-${lineName}`).remove();
  this.removeSelectOptions();
}

LayoutRenderer.prototype.highlightHybridPoints = function ({ points, lineName, unit, notShowSave, editingInfo, isVirtualComp = false, VC_COMP_TYPES }, events) {
  const _points = JSON.parse(JSON.stringify(points));

  this.removeHybridPoints(lineName);
  const svgNode = select(this.svgNode)
    .select('g')
    .append('g')
    .attr('class', `hybrid-points hybrid-points-${lineName}`)


  this.showPoint({ svgNode, points: _points, lineName, unit, notShowSave, editingInfo, isVirtualComp, VC_COMP_TYPES }, events)
}

LayoutRenderer.prototype.showPoint = function ({ svgNode, points, lineName, unit, notShowSave, editingInfo, isVirtualComp, VC_COMP_TYPES }, events) {
  let lineBox = svgNode.append('g').attr('class', `hybrid-points hybridPointBox-${lineName}`)
  const canvasObjList = new CanvasObjectList(this.clickCallback);
  this.showHybridLines(svgNode, points, lineName)

  for (let i = 0; i < points.length; i++) {
    const point = points[i];

    if (!point) continue;
    let pointBox = lineBox.append('g').attr('class', `hybrid-points hybridPointBox-${lineName} ${isVirtualComp ? "virtual-component-canvas" : ""}`)

    canvasObjList.addObject({ ...point });

    const r = isVirtualComp ? 8 : 14;
    pointBox
      .append('circle')
      .attr('id', `point-${lineName}-${i}`)
      .attr('cx', point.x)
      .attr('cy', point.y)
      .attr('r', this.milToUnit(r))
      .attr('fill', 'yellow')
      .attr('class', `hybridPointCircle-${lineName}-${i}`)
      .style('cursor', !events.isShowEditPanel ? "pointer" : null)
      .on('mouseenter', () => {
        pointBox.select(`circle.hybridPointCircle-${lineName}-${i}`).attr('r', this.milToUnit(22))
      })
      .on('mouseleave', () => {
        pointBox.select(`circle.hybridPointCircle-${lineName}-${i}`).attr('r', this.milToUnit(r))
      })
      .on("mousedown", () => {
        if (!events.isShowEditPanel && events.showEditPanel) {
          events.showEditPanel(lineName, "line");
        }
      });

    if (i === points.length - 1 && points.length > 1) {
      if (isVirtualComp && !events.isShowEditPanel) {
        this.removeSelectOptions();
      }
      if (isVirtualComp && events.isShowEditPanel) {
        this.showVirtualCompBox({
          point,
          svgNode,
          points,
          lineName,
          unit,
          events,
          pointBox,
          editingInfo,
          VC_COMP_TYPES
        })
      } else {
        pointBox.append('g')
          .attr("class", "pointRectBox")
          .attr('x', point.x + parseFloat(this.milToUnit(20)))
          .attr('y', point.y + parseFloat(this.milToUnit(-50)))
          .on('click', function () {
            event && event.stopPropagation();
          })

        pointBox.select('g.pointRectBox')
          .append('rect')
          .attr('x', point.x + parseFloat(this.milToUnit(20)))
          .attr('y', point.y + parseFloat(this.milToUnit(-50)))
          .attr('fill', '#ffffff')
          .style('width', this.milToUnit(!notShowSave ? 210 : 230))
          .style('height', this.milToUnit(60))
          .on('click', function () {
            event && event.stopPropagation();
          })
        //point edit button
        const xTranslate = parseFloat(this.milToUnit(30)),
          yTranslate = parseFloat(this.milToUnit(-29));

        const transform = `translate(${point.x + xTranslate},${point.y + yTranslate}) rotate(-180) scale(-1,1)`;

        if (!notShowSave) {
          pointBox.select('g.pointRectBox')
            .append('text')
            .text(`Save Boundary`)
            .attr('transform', transform)
            .style('font-size', `${this.milToUnit(28)}px`)
            .style('font-weight', 100)
            .attr('stroke-width', 1)
            .attr('fill', '#1890ff')
            .style('cursor', "pointer")
            .style('line-height', `${this.milToUnit(50)}px`)

            .on('click', () => {
              //edit current line
              events.saveHybridLine(lineName);
              event && event.stopPropagation();
            })
        } else {
          //point delete icon
          //`translate(${point.x + 7 * xTranslate},${point.y + yTranslate}) rotate(-180) scale(-1,1)`
          pointBox.select('g.pointRectBox')
            .append('text')
            .text(`Delete Boundary`)
            .attr('transform', transform)
            .style('font-size', `${this.milToUnit(28)}px`)
            .style('font-weight', 100)
            .attr('stroke-width', 1)
            .style('fill', '#1890ff')
            .style('cursor', "pointer")
            .style('line-height', `${this.milToUnit(50)}px`)
            .on('click', () => {
              events.removeHybridLine(lineName)
              event && event.stopPropagation();
            });
        }
      }

    }

    if (isVirtualComp && points.length === 1) {
      pointBox
        .on('mouseenter', () => this.hybridPointTooltip({ pointBox, lineName, point, id: i, unit }, { removeHybridPoint: events.removePoint }))
        .on('mouseleave', () => {
          select(`.hybridPointToolTip-${lineName}-${i}`).remove();
        });
    }

    if (!isVirtualComp && (!notShowSave || (i !== 0 && i !== points.length - 1))) {
      pointBox
        .on('mouseenter', () => this.hybridPointTooltip({ pointBox, lineName, point, id: i, unit }, events))
        .on('mouseleave', () => {
          select(`.hybridPointToolTip-${lineName}-${i}`).remove();
        });
    }
  }
  canvasObjList.drawSVG(lineBox, "yellow", true);

}



LayoutRenderer.prototype.hybridPointTooltip = function ({ pointBox, lineName, point, id }, events) {
  const pointTooltipBox = pointBox.append('g').attr('class', `hybrid-points hybridPointToolTip-${lineName} hybridPointToolTip-${lineName}-${id}`);
  pointTooltipBox.append('rect')
    .attr('x', point.x + parseFloat(this.milToUnit(6)))
    .attr('y', point.y)
    .attr('fill', '#ffffff')
    .style('width', this.milToUnit(180))//150
    .style('height', this.milToUnit(60))
    .on('click', function () {
      event && event.stopPropagation();
    })
  //point edit button
  /*   pointTooltipBox.append('text')
      .text(`Edit`)
      .attr('transform', `translate(${point.x + parseFloat(this.milToUnit(20))},${point.y + parseFloat(this.milToUnit(22))}) rotate(-180) scale(-1,1)`)
      .style('font-size', `${this.milToUnit(28)}px`)
      .style('font-weight', 100)
      .attr('stroke-width', 1)
      .style('fill', '#1890ff')
      .style('cursor', "pointer")
      .style('line-height', `${this.milToUnit(50)}px`)
      .on('click', () => {
        //edit current click point
        //   this.editHybridPoint(point);
       window.event && window.event.stopPropagation();
      }) */
  //point delete icon
  pointTooltipBox.append('text')
    .text(`Delete Point`)
    .attr('transform', `translate(${point.x + parseFloat(this.milToUnit(20))},${point.y + parseFloat(this.milToUnit(22))}) rotate(-180) scale(-1,1)`)
    .style('font-size', `${this.milToUnit(28)}px`)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .style('fill', '#1890ff')
    .style('cursor', "pointer")
    .style('line-height', `${this.milToUnit(50)}px`)
    .on('click', () => {
      //remove current click point
      events.removeHybridPoint(point, lineName);
      event && event.stopPropagation();
    });
}

LayoutRenderer.prototype.showHybridLines = function (svgNode, points, lineName) {
  const size1 = this.milToUnit(10),
    size2 = this.milToUnit(4);
  if (points.length > 1) {
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const point2 = points[i + 1];
      if (!point || !point2) {
        continue;
      }
      let pointBox = svgNode.append('g').attr('class', `hybrid-points hybridLine-${lineName}`)
      pointBox.append('line')
        .attr('x1', point.x)
        .attr('y1', point.y)
        .attr('x2', point2.x)
        .attr('y2', point2.y)
        .attr('stroke', '#ffffffdd')
        .attr('stroke-dasharray', `${size1},${size1}`)
        .attr('stroke-width', size2)
    }
  }
}

LayoutRenderer.prototype.showVirtualCompBox = function ({ point, lineName, editingInfo, pointBox, events, isPolygon, VC_COMP_TYPES }) {
  //remove prev panel status
  this.removeInput("name");
  this.removeInput("cutLength");
  this.removeSelectOptions("type");
  this.removeSelectOptions("unit");
  const x = point.x + parseFloat(this.milToUnit(10)),
    y = point.y + parseFloat(this.milToUnit(-166)),
    width = this.milToUnit(384),
    height = this.milToUnit(166),
    size = `${this.milToUnit(22)}px`,
    lineHeight = `${this.milToUnit(50)}px`;

  const virtualCompInfo = { ...editingInfo };

  pointBox.append('g')
    .attr("class", `pointRectBox pointRectBox-${editingInfo.index}`)
    .attr('x', x)
    .attr('y', y)
    .on('click', function () {
      event && event.stopPropagation();
    })

  //box
  pointBox.select('g.pointRectBox')
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('fill', '#ffffff')
    .style('width', width)
    .style('height', height)
    .on('click', function () {
      event && event.stopPropagation();
    })

  //point edit button
  const xTranslate = parseFloat(this.milToUnit(20)),
    xDeleteTranslate = parseFloat(this.milToUnit(90)),
    xDividingTranslate = parseFloat(this.milToUnit(76)),
    xCloseTranslate = parseFloat(this.milToUnit(366)),
    yButtonTranslate = parseFloat(this.milToUnit(-29)),
    yNameTranslate = parseFloat(this.milToUnit(-66)),
    yTypeTranslate = parseFloat(this.milToUnit(-104)),
    yLengthTranslate = parseFloat(this.milToUnit(-144));

  const saveTransform = `translate(${point.x + xTranslate},${point.y + yButtonTranslate}) rotate(-180) scale(-1, 1)`;
  const deleteTransform = `translate(${point.x + xDeleteTranslate},${point.y + yButtonTranslate}) rotate(-180) scale(-1, 1)`;
  const dividingTransform = `translate(${point.x + xDividingTranslate},${point.y + parseFloat(this.milToUnit(-28))}) rotate(-180) scale(-1, 1)`;
  const closeTransform = `translate(${point.x + xCloseTranslate},${point.y + parseFloat(this.milToUnit(-30))}) rotate(-180) scale(-1, 1)`;
  const nameTransform = `translate(${point.x + xTranslate},${point.y + yNameTranslate}) rotate(-180) scale(-1, 1)`;
  const typeTransform = `translate(${point.x + xTranslate},${point.y + yTypeTranslate}) rotate(-180) scale(-1, 1)`;
  const lengthTransform = `translate(${point.x + xTranslate},${point.y + yLengthTranslate}) rotate(-180) scale(-1, 1)`;

  const _this = this;
  //save button
  pointBox.select('g.pointRectBox')
    .append('text')
    .text(`Save`)
    .attr('transform', saveTransform)
    .style('font-size', size)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#1890ff')
    .style('cursor', "pointer")
    .style('line-height', lineHeight)
    .on('click', function () {
      //save current line
      if (!isPolygon) {
        events.saveLine(lineName);
        _this.removeSelectOptions();
      }
      event && event.stopPropagation();
    })

  //dividing line
  pointBox.select('g.pointRectBox')
    .append('text')
    .text(`|`)
    .attr('transform', dividingTransform)
    .style('font-size', size)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#1890ff')
    .style('line-height', lineHeight)
    .on('click', function () {
      event && event.stopPropagation();
    })

  //delete button
  pointBox.select('g.pointRectBox')
    .append('text')
    .text(`Delete`)
    .attr('transform', deleteTransform)
    .style('font-size', size)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#1890ff')
    .style('cursor', "pointer")
    .style('line-height', lineHeight)
    .on('click', function () {
      if (isPolygon) {
        //delete current polygon
        events.deletePolygon(lineName);
      } else {
        //delete current line
        events.deleteLine(lineName);
      }
      event && event.stopPropagation();
      _this.removeSelectOptions();
    })

  //close button
  pointBox.select('g.pointRectBox')
    .append('text')
    .text("X")
    .attr('transform', closeTransform)
    .style('font-size', size)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#1890ff')
    .style('cursor', "pointer")
    .style('line-height', lineHeight)
    .on('click', function () {
      if (isPolygon) {
        //close
        events.closePolygon(lineName);
      } else {
        //delete current line
        events.deleteLine(lineName);
      }
      event && event.stopPropagation();
      _this.removeSelectOptions();
    })

  //name
  pointBox.select('g.pointRectBox')
    .append('text')
    .text(`Name`)
    .attr('transform', nameTransform)
    .style('font-size', size)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#1890ff')
    .style('line-height', lineHeight)
    .on('click', function () {
      event && event.stopPropagation();
      _this.removeSelectOptions();
    })

  this.createInputBox({
    svgNode: pointBox.select('g.pointRectBox'),
    x: point.x + parseFloat(this.milToUnit(150)),
    y: point.y + yNameTranslate - parseFloat(this.milToUnit(10)),
    updateInputValue: events.updateCompName,
    defaultValue: virtualCompInfo.name,
    virtualCompInfo,
    className: "layout-canvas-input-comp",
    type: "name",
    width: parseFloat(this.milToUnit(230)),
    getNames: events.getCompNames,
    events
  })

  //type
  pointBox.select('g.pointRectBox')
    .append('text')
    .text(`Type`)
    .attr('transform', typeTransform)
    .style('font-size', size)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#1890ff')
    .style('line-height', lineHeight)
    .on('click', function () {
      event && event.stopPropagation();
      _this.removeSelectOptions();
    })

  this.createSelectionBox({
    svgNode: pointBox.select('g.pointRectBox'),
    x: point.x + parseFloat(this.milToUnit(150)),
    y: point.y + yTypeTranslate - parseFloat(this.milToUnit(10)),
    options: VC_COMP_TYPES,
    updateSelectValue: events.updateCompType,
    defaultValue: virtualCompInfo.type,
    virtualCompInfo,
    updateInputValue: events.updateCompName,
    defaultCompValue: editingInfo.name,
    type: "type",
    width: parseFloat(this.milToUnit(230)),
    arrowTop: `translate(${parseFloat(this.milToUnit((70)))},${parseFloat(this.milToUnit(-47))})`,
    arrowBottom: `translate(${parseFloat(this.milToUnit((70)))},${parseFloat(this.milToUnit(78))}) rotate(-180) scale(-1, 1)`,
  })

  //cut length
  pointBox.select('g.pointRectBox')
    .append('text')
    .text(`Cut Length`)
    .attr('transform', lengthTransform)
    .style('font-size', size)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#1890ff')
    .style('line-height', lineHeight)
    .on('click', function () {
      event && event.stopPropagation();
      _this.removeSelectOptions();
    })

  this.createInputBox({
    svgNode: pointBox.select('g.pointRectBox'),
    x: point.x + parseFloat(this.milToUnit(150)),
    y: point.y + yLengthTranslate - parseFloat(this.milToUnit(10)),
    updateInputValue: events.updateCutLength,
    defaultValue: virtualCompInfo.cutLength,
    virtualCompInfo,
    type: "cutLength",
    width: parseFloat(this.milToUnit(150)),
    includeUnit: true,
    events
  })
  // cutLength unit
  this.createSelectionBox({
    svgNode: pointBox.select('g.pointRectBox'),
    x: point.x + parseFloat(this.milToUnit(300)),
    y: point.y + yLengthTranslate - parseFloat(this.milToUnit(10)),
    options: ["mil", "mm", "um"],
    updateSelectValue: events.updateCutLength ? (value) => events.updateCutLength(value, "unit") : null,
    defaultValue: virtualCompInfo.unit,
    width: parseFloat(this.milToUnit(80)),
    virtualCompInfo,
    type: "unit",
    includeUnit: true,
    inputType: "cutLength",
    arrowTop: `translate(${parseFloat(this.milToUnit((-80)))},${parseFloat(this.milToUnit(-47))})`,
    arrowBottom: `translate(${parseFloat(this.milToUnit((-80)))},${parseFloat(this.milToUnit(78))}) rotate(-180) scale(-1, 1)`,
  })
}

LayoutRenderer.prototype.removeRectangleByLocation = function (name) {
  if (name) {
    select(this.svgNode).select('g').selectAll(`g.rectangle-${name}`).remove()
  } else {
    select(this.svgNode).select('g').selectAll(`g.rectangle`).remove()
  }
  this.removeSelectOptions();
}

LayoutRenderer.prototype.showRectangleByLocation = function ({ locations, editingInfo, events, VC_COMP_TYPES }) {
  this.removeRectangleByLocation(editingInfo.index);
  const size2 = this.milToUnit(4);
  const rectBox = select(this.svgNode).select('g').append('g').attr('class', `rectangle rectangle-${editingInfo.index}`)
  if (locations.length > 1) {
    /* let points = "" */
    /*     for (let item of locations) {
          points += points ? ` ${item[0]},${item[1]}` : `${item[0]},${item[1]}`;
        }
        points += ` ${locations[0][0]},${locations[0][1]}` */

    /*     locations = [...locations, locations[0]] */
    /*    for (let i = 0; i < locations.length; i++) {
         const point = locations[i];
         const point2 = locations[i + 1];
         if (!point || !point2) {
           continue;
         }
         rectBox.append('line')
           .attr('x1', point.x)
           .attr('y1', point.y)
           .attr('x2', point2.x)
           .attr('y2', point2.y)
           .attr('stroke', '#ffffffdd')
           .attr('stroke-dasharray', `${size1},${size1}`)
           .attr('stroke-width', size2)
       } */

    /* rectBox.append('polygon')
      .attr('points', points)
      .attr('stroke-width', size2)
      .attr("fill", "#ffffff")
 */
    rectBox.append('line')
      .attr('x1', locations[0][0])
      .attr('y1', locations[0][1])
      .attr('x2', locations[1][0])
      .attr('y2', locations[1][1])
      .attr('stroke', '#ffffffdd')
      .attr('stroke-width', size2)

    for (let i = 0; i < locations.length; i++) {
      const point = locations[i];
      rectBox
        .append('circle')
        .attr('cx', point[0])
        .attr('cy', point[1])
        .attr('r', this.milToUnit(8))
        .attr('fill', 'yellow')
        .attr('class', `rectCircle-${editingInfo.index}-${i}`)
        .style('cursor', !events.isShowEditPanel ? "pointer" : null)
        .on('mouseenter', () => {
          rectBox.select(`circle.rectCircle-${editingInfo.index}-${i}`).attr('r', this.milToUnit(22))
        })
        .on('mouseleave', () => {
          rectBox.select(`circle.rectCircle-${editingInfo.index}-${i}`).attr('r', this.milToUnit(8))
        })
        .on("mousedown", () => {
          if (!events.isShowEditPanel && events.showEditPanel) {
            events.showEditPanel(editingInfo.index);
          }
        });
    }

    if (events.isShowEditPanel) {
      this.showVirtualCompBox({
        point: { x: locations[1][0], y: locations[1][1] },
        lineName: editingInfo.index,
        events,
        pointBox: rectBox,
        editingInfo,
        isPolygon: true,
        VC_COMP_TYPES
      })
    } else {
      this.removeSelectOptions();
    }
  }
}

LayoutRenderer.prototype.zoomToPortsGroupsBox = function (rate) {
  let boxes = [];
  let compElement = select('.portsGroups');
  let { x, y, height, width } = compElement.node().getBBox();
  boxes.push(...[{ x, y }, { x: x + width, y: y + height }]); // value of all components{ X, Y, width, height }
  const xArr = boxes.map(d => d.x), yArr = boxes.map(d => d.y);
  const xMin = Math.min(...xArr), xMax = Math.max(...xArr), yMin = Math.min(...yArr), yMax = Math.max(...yArr);
  // rate
  const _width = xMax - xMin, _height = yMax - yMin;
  const r = Math.min(this.viewBoxWidth / _width, this.viewBoxHeight / _height);
  let _rate = rate;
  if (!_rate) {
    if (r > 30) {
      _rate = 0.1;
    } else if (r > 10) {
      _rate = 0.3;
    } else if (r > 3) {
      _rate = 0.8;
    } else {
      _rate = 0.9;
    }
  }
  // TODO - rate
  this.zoomToBox(xMin, yMin, _width, _height, 0.9);
}

LayoutRenderer.prototype.removePort = function () {
  if (this.canvasType === "NET") {
    return;
  }
  select(this.svgNode).select('g').select('.portsGroups').remove();
  select(this.svgNode).select('g').select('.selectGroupNames').remove();
  select(this.svgNode).select('g').selectAll('.pin-positive-port-box').classed("pin-positive-port-box", false);
  select(this.svgNode).select('g').selectAll('.pin-negative-port-box').classed("pin-negative-port-box", false);
  select(this.svgNode).select('g').selectAll('.pin-opacity-port-0-6').classed("pin-opacity-port-0-6", false);
  select(this.svgNode).select('g').selectAll('.pin-opacity-port-0-2').classed("pin-opacity-port-0-2", false);
  select(this.svgNode).select('g').selectAll('.pin-opacity-port-1').classed("pin-opacity-port-1", false);
}

LayoutRenderer.prototype.removeNetName = function () {
  select(this.svgNode).select('g').select('.netNameBox').remove();
}

LayoutRenderer.prototype.removePortBox = function () {
  select(this.svgNode).select('g').select(".portBox").remove();
}

LayoutRenderer.prototype.removeCompsName = function () {
  select(this.svgNode).select('g').select('.compBox').remove();
}

LayoutRenderer.prototype.removeCompsAndNetsName = function () {
  select(this.svgNode).select('g').select('.selectGroupNames').remove();
}

LayoutRenderer.prototype.milToUnit = function (num) {
  // mil to unit
  return unitChange({
    num: parseFloat(num),
    newUnit: this.layoutDB.mUnits === 'mils' ? 'mil' : this.layoutDB.mUnits,
    decimals: 12
  }).number;
}

LayoutRenderer.prototype.unitToMil = function (num) {
  // pcb unit to mil
  return unitChange({
    num: parseFloat(num),
    oldUnit: this.layoutDB.mUnits === 'mils' ? 'mil' : this.layoutDB.mUnits,
    newUnit: 'mil',
    decimals: 12
  }).number;
}

LayoutRenderer.prototype.showPort = function ({ svgNode, pins, direction, color, posPins, showLines = false, showText = true, showConnectNet = false, showNetGroupPins = false, allowRightMouseClick = false }) {
  // positive pins and negative pins on same component
  let portBox = null, pinsLocation = [];
  const canvasObjList = new CanvasObjectList(this.clickCallback);
  for (let pinEle of pins) {
    if (!pinEle) continue;
    const _pinEle = _.cloneDeep(pinEle);
    if (!portBox) {
      portBox = svgNode.append('g').attr('class', 'portBox')
    }
    const location = getPinLocation(_pinEle);
    if (!location) {
      continue;
    }
    canvasObjList.addObject(_pinEle);
    const pinNumber = (showConnectNet && pinEle.connectNet) ? pinEle.connectNet : pinEle.pinNumber
    pinsLocation.push({ ...location, pinNumber, findPin: _pinEle })
  }
  // fontSize
  const fontSize = Math.ceil(this.unitToMil((pinsLocation.reduce((prev, curr) => {
    return prev + curr.r;
  }, 0)) / pinsLocation.length));

  const size0 = this.milToUnit(fontSize),
    size1 = this.milToUnit(fontSize / 3),
    size2 = this.milToUnit(fontSize / 5),
    size3 = this.milToUnit(fontSize / 10),
    size4 = this.milToUnit(fontSize * 10);
  // Dotted line between positive pin and negative pin
  if (direction === 'negative' && showLines && posPins.length) {
    const location = getPinLocation(posPins[0]);
    if (location) {
      const { x, y } = location;
      // pinsLocation
      pinsLocation.forEach(d => {
        portBox.append('line')
          .attr('x1', x)
          .attr('y1', y)
          .attr('x2', d.x)
          .attr('y2', d.y)
          .attr('stroke', '#ffffffdd')
          .attr('stroke-dasharray', `${size1},${size1} `)
          .attr('stroke-width', size2)
          .on("click", () => {
            event && event.stopPropagation()
          })
      })
      for (let i = 1; i < posPins.length; i++) {
        const posLoaction = getPinLocation(posPins[i]);
        if (posLoaction) {
          const { x: posX, y: posY } = posLoaction;
          portBox.append('line')
            .attr('x1', x)
            .attr('y1', y)
            .attr('x2', posX)
            .attr('y2', posY)
            .attr('stroke', '#ffffffdd')
            .attr('stroke-dasharray', `${size1},${size1} `)
            .attr('stroke-width', size2)
            .on("click", () => {
              event && event.stopPropagation()
            })
        }
      }
    }
  }
  if (!showConnectNet && !allowRightMouseClick) {
    canvasObjList.drawSVG(portBox, color, true)
  }
  // positive pin and negative pin text
  pinsLocation.forEach(d => {
    const { x, y, r, pinNumber, findPin } = d;
    if (!showConnectNet) {
      portBox
        .append('text')
        .text(direction === 'positive' ? '+' : '-')
        .attr('x', direction === 'positive' ? x - parseFloat(size1) : x - parseFloat(size2))
        .attr('y', direction === 'positive' ? y + parseFloat(size1) : y + parseFloat(size2))
        .attr('stroke-width', size3)
        .style('font-size', size0)
        /* .style('font-weight', size4) */
        .attr('stroke', 'yellow')
        .on("mousedown", (canvas) => {
          event && event.stopPropagation()
          if (event && event.button === 2) {
            this.rightClickPin({ findPin })
          }
        })
    }

    if (showText) {
      const xTrans = parseFloat(this.milToUnit(pinNumber.length * fontSize / 4));
      const yTrans = parseFloat(this.milToUnit(fontSize * 2 / 7));
      let _pinNumberColor = 'yellow';
      let transform = `translate(${x - xTrans}, ${y + 1.2 * r}) rotate(-180) scale(-1, 1)`;
      if (showNetGroupPins) {
        _pinNumberColor = '#1ba0e0';
        transform = `translate(${x - (xTrans * 8 / 7)}, ${y - yTrans}) rotate(-180) scale(-1, 1)`;
      }

      portBox
        .append('text')
        .text(pinNumber)
        .attr('transform', transform)
        .attr('stroke-width', size3)
        .style('font-size', size0)
        /* .style('font-weight', size4) */
        .attr('stroke', _pinNumberColor)
        .on("click", () => {
          event && event.stopPropagation()
        })
    }

  })
}

LayoutRenderer.prototype.showConnectPins = function ({ svgNode, pins, color, showText = true }) {
  // positive pins and negative pins on same component
  let portBox = null, pinsLocation = [];
  const canvasObjList = new CanvasObjectList(this.clickCallback);
  for (let pinEle of pins) {
    if (!pinEle) continue;
    const _pinEle = _.cloneDeep(pinEle);
    if (!portBox) {
      portBox = svgNode.append('g').attr('class', 'portBox')
    }
    const location = getPinLocation(_pinEle);
    if (!location) {
      continue;
    }
    canvasObjList.addObject(_pinEle);
    const pinNumber = pinEle.pinNumber
    pinsLocation.push({ ...location, pinNumber, findPin: _pinEle })
  }
  // fontSize
  canvasObjList.drawSVG(portBox, color, true)

  // positive pin and negative pin text
  pinsLocation.forEach(d => {
    const { x, y, r, pinNumber, findPin } = d;

    const fontSize = this.unitToMil(r)
    const size0 = this.milToUnit(fontSize * 0.8),
      size3 = this.milToUnit(fontSize / 10)

    if (showText) {
      const xTrans = parseFloat(this.milToUnit(pinNumber.length * fontSize / 4));
      const yTrans = parseFloat(this.milToUnit(fontSize * 2 / 7));
      let _pinNumberColor = 'cyan';
      let transform = `translate(${x - xTrans},${y - yTrans}) rotate(-180) scale(-1,1)`;

      portBox
        .append('text')
        .text(pinNumber)
        .attr('transform', transform)
        .attr('stroke-width', size3)
        .style('font-size', size0)
        /* .style('font-weight', size4) */
        .attr('stroke', _pinNumberColor)
        .append('title').text(findPin ? findPin.toolTip : pinNumber)
    }

  })
}

LayoutRenderer.prototype.setColorForSelectedPins = function (pins = {}, color) {
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].setPinSelection(pins, true, color);
    }
  }
}

LayoutRenderer.prototype.setOpacityForSelectedPins = function (pins = {}, opacity) {
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].setPinOpacity(pins, opacity);
    }
  }
}

// Highlight nets
LayoutRenderer.prototype.setColorForNetsInPort = function (nets, color) {
  if (!nets.length) return;
  this.selectionLayer.prepareData(nets);
  this.selectionLayer.redraw(nets, color);
}

LayoutRenderer.prototype.zoomToPins = function (pins = {}) {
  const locations = [];
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      locations.push(...this.layerList[i].findPins(pins).map(item => ({ x: item.xc, y: item.yc })))
    }
  }
  const xArr = locations.map(d => d.x), yArr = locations.map(d => d.y);
  const xMin = Math.min(...xArr), xMax = Math.max(...xArr), yMin = Math.min(...yArr), yMax = Math.max(...yArr);
  // rate
  let width = xMax - xMin, height = yMax - yMin;
  let _rate = null;
  if (width === 0 && height === 0) {
    _rate = 0.1;
    width = 1;
    height = 1;
  } else {
    const r = Math.min(this.viewBoxWidth / width, this.viewBoxHeight / height);
    if (!_rate) {
      if (r > 30) {
        _rate = 0.1;
      } else if (r > 10) {
        _rate = 0.3;
      } else if (r > 3) {
        _rate = 0.8;
      } else {
        _rate = 0.9;
      }
    }
  }
  this.zoomToBox(xMin, yMin, width, height, _rate);
}

LayoutRenderer.prototype.showPinsName = function (pinGroups = {}, show) {
  if (show) {
    const pins = [];
    for (let i = 0, len = this.layerList.length; i < len; i++) {
      if (this.layerList[i] instanceof CompLayerRenderer) {
        pins.push(...this.layerList[i].findPins(pinGroups))
      }
    }

    let pinNameBox = null, pinsLocation = [];
    if (!pinNameBox) {
      pinNameBox = select(this.svgNode)
        .select('g')
        .append('g')
        .attr('class', 'pinNameBox')
    }
    for (let pinEle of pins) {
      if (!pinEle) continue;
      const location = getPinLocation(pinEle);
      if (!location) {
        continue;
      }
      pinsLocation.push({ ...location, pinNumber: pinEle.pinNumber })
    }
    // fontSize
    const fontSize = Math.ceil(this.unitToMil((pinsLocation.reduce((prev, curr) => {
      return prev + curr.r;
    }, 0)) / pinsLocation.length));

    const size0 = this.milToUnit(fontSize),
      size3 = this.milToUnit(fontSize / 10),
      size4 = this.milToUnit(fontSize * 10);
    pinsLocation.forEach((d, i) => {
      const { x, y, r, pinNumber } = d;

      const xTrans = parseFloat(this.milToUnit(pinNumber.length * fontSize / 4));
      pinNameBox
        .append('text')
        .text(pinNumber)
        .attr('transform', `translate(${x - xTrans}, ${y + 1.2 * r}) rotate(-180) scale(-1, 1)`)
        .attr('stroke-width', size3)
        .style('font-size', size0)
        /*   .style('font-weight', size4) */
        .attr('stroke', 'yellow')
        .attr('class', this.onlyHiddenPins && this.showPinList && this.showPinList.length && !this.showPinList.find(item => item.pin === pinNumber) ? 'canvas-not-display-pin' : '');
    })
  } else {
    select(this.svgNode)
      .select('g')
      .select('.pinNameBox')
      .remove()
  }
}

LayoutRenderer.prototype.getPinsInfo = function (pinGroups = {}) {
  const pins = [];
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      pins.push(...this.layerList[i].findPins(pinGroups))
    }
  }
  return pins
}

//show net name
LayoutRenderer.prototype.onlyShowNetsName = function (nets, designId) {
  if (!nets.length) return;
  this.selectionLayer.prepareData(nets);
  this.selectionLayer.redraw(nets);
  select(this.svgNode)
    .select('g')
    .append('g')
    .attr('class', 'selectGroupNames')
  this.showNetsName(nets, false, designId, null, true)
}

// Highlight nets
LayoutRenderer.prototype.showNetsName = function (nets, isPower = false, designId, layoutDB, onlySignal) {
  let _nets = {};
  if (!this.layoutDB || !this.layoutDB.mNetManager) {
    return;
  }
  for (let net of nets) {
    const netInfo = this.layoutDB.mNetManager.GetNetFromName(net)
    const _symbol = this.layoutDB.mSymbolMgr
    const isPowerNet = onlySignal ? false : isPowerGND(netInfo, _symbol, designId, layoutDB)
    _nets[net] = (isPowerNet || isPower) ? POWERNET : SIGNALNET;
  }

  const netsInfo = this.selectionLayer.getNetsShape(_nets)
  let netNameBox = null, netsNameLocation = [];

  const svgNode = select(this.svgNode)
    .select('g')
    .select('.selectGroupNames')

  const canvasObjList = new CanvasObjectList(this.clickCallback);

  for (let netInfo of netsInfo) {
    if (!netNameBox) {
      netNameBox = svgNode.append('g').attr('class', 'netNameBox')
    }
    let _netInfo = []
    const { net, shapeInfo, type } = netInfo;
    for (let data of shapeInfo) {
      // const location = calculateNetNameLocation(data, type);
      canvasObjList.addObject(data)
      const fontSize = Math.ceil(this.unitToMil(data.r))
      const xTrans = parseFloat(this.milToUnit(net.length * fontSize / 4));
      const yTrans = parseFloat(this.milToUnit((2 * fontSize) / 3.5));
      _netInfo.push({ ...data, xTrans, yTrans, netType: isPower ? POWERNET : type, netName: net, fontSize })
    }
    if (_netInfo.length) {
      netsNameLocation.push(_netInfo)
    }
  }

  let _netsNameLocation = [], powerNetName = []
  for (let netNameLocation of netsNameLocation) {
    if (!netNameLocation || !netNameLocation.length) { continue }
    if (netNameLocation[0].netType === SIGNALNET) {
      _netsNameLocation.push(netNameLocation[0])
    } else {
      powerNetName.push(netNameLocation[0])
    }
  }

  const fontSize = Math.ceil(this.unitToMil((_netsNameLocation.reduce((prev, curr) => {
    return prev + curr.r;
  }, 0)) / _netsNameLocation.length))

  const _powerNetFontSize = Math.ceil(this.unitToMil((powerNetName.reduce((prev, curr) => {
    return prev + curr.r;
  }, 0)) / powerNetName.length))
  let locationList = [], netNameInfo = [], currentNameInfo = {};
  netsNameLocation.forEach(d => {
    const { x, y, r, netName, width, shape, netType, xTrans, yTrans } = d[0];
    let _xTrans = xTrans, _yTrans = yTrans;
    let _x, _y, rotate, _fontSize;
    let stroke = '#2f92fd';
    if (netType === POWERNET) {
      rotate = '-180';
      if (fontSize && _powerNetFontSize < fontSize * 3) {
        _fontSize = fontSize * 3
      } else if (fontSize && _powerNetFontSize > fontSize * 4) {
        _fontSize = fontSize * 4
      } else {
        // if (_powerNetFontSize < 50) {
        //   _fontSize = _powerNetFontSize;
        // } else if (_powerNetFontSize < 100) {
        //   _fontSize = _powerNetFontSize / 1.5;
        // } else if (_powerNetFontSize < 150) {
        //   _fontSize = _powerNetFontSize / 2.5;
        // } else if (_powerNetFontSize < 200) {
        //   _fontSize = _powerNetFontSize / 3;
        // } else if (_powerNetFontSize < 300) {
        //   _fontSize = _powerNetFontSize / 5;
        // } else if (_powerNetFontSize < 500) {
        //   _fontSize = _powerNetFontSize / 6;
        // } else if (_powerNetFontSize < 700) {
        //   _fontSize = _powerNetFontSize / 7;
        // } else if (_powerNetFontSize < 900) {
        //   _fontSize = _powerNetFontSize / 9;
        // } else if (_powerNetFontSize < 1200) {
        //   _fontSize = _powerNetFontSize / 12;
        // } else if (_powerNetFontSize < 1500) {
        //   _fontSize = _powerNetFontSize / 15;
        // } else if (_powerNetFontSize < 1800) {
        //   _fontSize = _powerNetFontSize / 17;
        // } else {
        //   _fontSize = _powerNetFontSize / 20
        // }
        const scaleFactor = Math.max(1, Math.ceil(_powerNetFontSize / 50));
        _fontSize = _powerNetFontSize / (scaleFactor > 20 ? 20 : scaleFactor < 1.5 ? 1.5 : scaleFactor);
      }
      let xTrans = parseFloat(this.milToUnit(netName.length * _fontSize / 4));
      let yTrans = parseFloat(this.milToUnit(2 * _fontSize / 3.5));
      _x = x - xTrans / 2;
      _y = y;
      const calData = calculatePowerNetNameTransform(d, xTrans, yTrans, locationList, _x, _y);
      _x = calData.translateX;
      _y = calData.translateY;
      _xTrans = calData.xTrans
      _yTrans = calData.yTrans
      locationList = [...calData._locationList];

      currentNameInfo = { x, y, translateX: _x, translateY: _y, rotate, direction: HORIZONTAL, xTrans, fontSize: _fontSize, yTrans, width: yTrans }
    } else {
      const calData = calculateNetNameTransform(d, locationList, 0);
      _x = calData.translateX;
      _y = calData.translateY;
      rotate = calData.rotate;
      _xTrans = calData.xTrans
      _yTrans = calData.yTrans
      currentNameInfo = { ...calData }
      locationList = [...calData._locationList];
      _fontSize = fontSize;

      if (nets && nets.length) {
        const index = nets.findIndex(item => item === netName)
        stroke = this.selectionLayer.layoutRenderer.layoutDB.GetLayoutSettings().GetSelectNetColor(index)[1];
      }
    }

    const size0 = this.milToUnit(_fontSize),
      size3 = this.milToUnit(_fontSize / 10),
      size4 = this.milToUnit(_fontSize * 10),
      size5 = this.milToUnit(_fontSize * 5);

    let fontWeight = size4;
    if (size0 > 50) {
      fontWeight = size5
    }
    netNameInfo.push({
      ...currentNameInfo,
      strokeWidth: size3,
      fontWeight: fontWeight,
      fontSize: size0,
      netName,
      netType
    })
    // Avoid unrecognized characters in using netName as className
    const _netName = netName.replace(/[^a-zA-Z0-9]/g, 'a');
    const textData = netNameBox.append('text').attr('class', `text-${_netName}`).text(netName)
      .attr('transform', `translate(${_x}, ${_y}) rotate(${rotate}) scale(-1, 1)`)
      .attr('stroke-width', size3)
      .style('font-size', size0)
      .attr('stroke', stroke)

    if (netType === POWERNET && textData.node() && textData.node().getBBox()) {
      var boxInfo = textData.node().getBBox();
      let paddingLeft = boxInfo.width * 0.05;
      let paddingTop = boxInfo.height * 0.1;
      netNameBox.insert("rect", "text")
        .attr('class', `rect-${_netName}`)
        .attr("x", _x - paddingLeft)
        .attr("y", _y - (2.5 * paddingTop))
        .attr("width", boxInfo.width + 1.7 * paddingLeft)
        .attr("height", boxInfo.height + paddingTop)
        .style("fill", "#fff")
        .style("fill-opacity", 0.75);
    }
  })
  this.netNameInfo = netNameInfo;
  this.updateStrokeWidth();
}

LayoutRenderer.prototype.showCompsName = function ({ svgNode, findCompInfo, compBox, color }) {
  function calculateCompLocation(compInfo) {
    let x, y, r;
    const { outlineObj } = compInfo
    if (outlineObj instanceof CanvasPath) {
      if (outlineObj.pathData) {
        const _points = outlineObj.pathData
        // M     L3
        // L1    L2
        // const _points = 'M -3031.758 -1796.9949999999997 L -3031.7580000000003 -2699.8549999999996 L -2089.498 -2699.855 L -2089.498 -1796.995 Z'
        if (_points) {
          var reg = /[MLZ]/ig;
          const _dataList = _points.split(reg)
          const res = /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/;
          let xMin = Infinity, xMax = -Infinity, yMin = Infinity, yMax = -Infinity;
          for (let data of _dataList) {
            if (!data) { continue }
            let splitData = data.split(' ')
            splitData = splitData.filter(item => res.test(item))
            if (splitData.length === 2) {
              const _x = splitData[0] * 1
              const _y = splitData[1] * 1
              if (_x < xMin) {
                xMin = _x;
              }
              if (_x > xMax) {
                xMax = _x;
              }
              if (_y < yMin) {
                yMin = _y;
              }
              if (_y > yMax) {
                yMax = _y;
              }
            }
          }
          x = (xMin + xMax) / 2;
          y = (yMin + yMax) / 2;

          const xLength = xMax - xMin;
          const yLength = yMax - yMin;
          let direction = HORIZONTAL;
          r = xLength / 2;
          let distanceMove = yLength / 2
          if (xLength * 2 < yLength) {
            // yLength/xLength
            // const proportion = (yLength / xLength) > 4 ? 4 : (yLength / xLength)
            // r = yLength / proportion;
            r = yLength / 2;
            distanceMove = xLength / 2;
            direction = VERTICAL;
          }
          const proportion = (xMax - xMin) / (yMax - yMin)

          return { x, y, r, proportion, direction, distanceMove }
        }
      }
    }
  }

  function getFontSize(data, pcbWidth) {
    const dataSize = Math.ceil(data / 30 > 5 ? 5 : data / 30 < 1 ? 1 : data / 30);
    const widthSize = Math.ceil(pcbWidth / 1000 > dataSize - 1 ? dataSize - 1 : pcbWidth / 1000 < 1 ? 0 : pcbWidth / 1000)
    const fontSize = data < 1 ? data * 5 : data / (Math.max(1, Math.log10(data)) + dataSize - widthSize);
    return fontSize
  }
  let compLocation = [];
  const canvasObjList = new CanvasObjectList(this.clickCallback);

  if (!this.layoutDB || !this.layoutDB.GetProfile) return;
  // get the bounding box of the outline
  let designOutline = this.layoutDB.GetProfile();
  if (!designOutline) return;

  let boundingBox = designOutline.BoundingBox();
  const pcbWidth = Math.ceil(this.unitToMil(Math.abs(boundingBox.mMaxPnt.mX - boundingBox.mMinPnt.mX)))

  for (let compInfo of findCompInfo) {
    if (!compInfo) continue;
    if (!compBox) {
      compBox = svgNode.append('g').attr('class', 'compBox')
    }
    const location = calculateCompLocation(compInfo);
    const pinList = this.layoutDB.getComponent(compInfo.name).mPinsLocationList
    canvasObjList.addObject(compInfo);
    let fontSize = getFontSize(Math.ceil(this.unitToMil(location.r)), pcbWidth)
    compLocation.push({ ...location, compName: compInfo.name, pinsLength: pinList.length, fontSize })
  }

  let locationList = [], compNameInfo = this.compNameInfo;
  compLocation.forEach(d => {
    const { compName, distanceMove, x, y, direction, fontSize } = d;
    let xTrans = parseFloat(this.milToUnit(compName.length * fontSize / 3.5));
    let yTrans = parseFloat(this.milToUnit(2 * fontSize / 3.5));
    let _distanceMove = distanceMove + yTrans / 2

    const { rotate, _locationList, _x, _y } = calculateCompNameTransform({ ...d, xTrans, yTrans, _distanceMove }, locationList)
    locationList = [..._locationList];

    const size0 = this.milToUnit(fontSize),
      size3 = this.milToUnit(fontSize / 10),
      size4 = this.milToUnit(fontSize * 5);

    compNameInfo.push({
      xTrans,
      yTrans,
      rotate,
      translateX: _x,
      translateY: _y,
      x, y, direction,
      distanceMove: _distanceMove,
      fontSize: size0,
      strokeWidth: size3,
      fontWeight: size4,
      compName
    })

    compBox
      .append('text')
      .text(compName)
      .attr('transform', `translate(${_x}, ${_y}) rotate(${rotate}) scale(-1, 1)`)
      .attr('stroke-width', size3)
      .style('font-size', size0)
      .attr('stroke', color)
  })
  this.compNameInfo = compNameInfo;
}
function calLocationFromPoints(points) {
  let xMin = Infinity, xMax = -Infinity, yMin = Infinity, yMax = -Infinity;
  for (let i = 0; i < points.length; i++) {
    const _x = points[i].x;
    const _y = points[i].y;
    if (_x < xMin) {
      xMin = _x;
    }
    if (_x > xMax) {
      xMax = _x;
    }
    if (_y < yMin) {
      yMin = _y;
    }
    if (_y > yMax) {
      yMax = _y;
    }
  }
  const x = (xMin + xMax) / 2;
  const y = (yMin + yMax) / 2;
  const r = yMax - y;
  return { x, y, r }
}

export function getPinLocation(pinEle) {
  let x, y, r;
  if (pinEle instanceof CanvasCircle || (pinEle.xc && pinEle.yc && pinEle.r)) {
    x = pinEle.xc;
    y = pinEle.yc;
    r = pinEle.r;
  } else if (pinEle instanceof CanvasPolygon) {
    if (pinEle.element) {
      const points = pinEle.element.points;
      let xMin = Infinity, xMax = -Infinity, yMin = Infinity, yMax = -Infinity;
      for (let i = 0; i < points.length; i++) {
        const _x = points.getItem(i).x;
        const _y = points.getItem(i).y;
        if (_x < xMin) {
          xMin = _x;
        }
        if (_x > xMax) {
          xMax = _x;
        }
        if (_y < yMin) {
          yMin = _y;
        }
        if (_y > yMax) {
          yMax = _y;
        }
      }
      x = (xMin + xMax) / 2;
      y = (yMin + yMax) / 2;
      r = yMax - y;
    }
    // else if (pinEle.polyData) {
    //   const data = pinEle.polyData.split(/\s/);
    //   const xList = data.filter(item => item.match(",")).map(item => item.replace(",", ""));
    //   const yList = data.filter(item => !item.match(","));
    //   const points = xList.map((x, index) => {
    //     return { x: Number(x), y: Number(yList[index]) }
    //   })
    //   const location = calLocationFromPoints(points)
    //   x = location.x
    //   y = location.y
    //   r = location.r
    // }
  } else if (pinEle.Xs && pinEle.Ys) {
    const { Xs, Ys } = pinEle
    const points = Xs.map((x, index) => {
      return { x: Number(x), y: Number(Ys[index]) }
    })
    const location = calLocationFromPoints(points)
    x = location.x
    y = location.y
    r = location.r
  } else {
    console.error('Not support:', pinEle)
    return false;
  }
  return { x, y, r }
}

LayoutRenderer.prototype.highlightCompGroups = function (comps, pins) {
  let _comps = comps.map(item => item.comp)
  if (Object.keys(pins).length) {
    _comps = [...new Set([..._comps, ...Object.keys(pins)])];
  }
  let _powerPinsObj = {}
  for (let compInfo of comps) {
    const { type, powerPins, comp } = compInfo;
    const keys = Object.keys(powerPins)
    if (type === 'pullRes' && keys.length) {
      _powerPinsObj[comp] = keys
    }
  }
  if (!_comps.length) return;
  const svgNode = select(this.svgNode)
    .select('g')
    .select('.selectGroupNames')

  this.removePortBox()
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].setCompSelection(_comps, true, comps);
      const keys = Object.keys(pins);
      this.layerList[i].hightlightCompOutline(keys, true)
      const _pins = this.layerList[i].findPins(pins)
      // show pins Name
      if (_pins.length) {
        this.showPort({ svgNode, pins: _pins, color: 'yellow', showNetGroupPins: true })
      }

      let _powerPins = this.layerList[i].findPins(_powerPinsObj)
      _powerPins.forEach(item => {
        const { pinNumber } = item
        const data = comps.find(it => it.comp === item.comp)
        if (data) {
          item.connectNet = data.powerPins && data.powerPins[pinNumber] && data.powerPins[pinNumber].mName ? data.powerPins[pinNumber].mName : null;
        }
      })
      if (_powerPins.length) {
        this.showPort({ svgNode, pins: _powerPins, showConnectNet: true, showNetGroupPins: true })
      }
    }
  }
}

LayoutRenderer.prototype.changLayerCompsName = function (shownLayers, hiddenLayers) {
  // When a layer is hidden, the name of the comp of that layer is also hidden
  if (this && this.checkCompLayers) {
    let compLayer = [...this.checkCompLayers]
    select(this.svgNode).select('g').select('.compBox').selectAll('text').each(function (d) {
      const currentCompLayerInfo = compLayer.find(it => it.compList.includes(this.innerHTML))
      const layer = currentCompLayerInfo && currentCompLayerInfo.name ? currentCompLayerInfo.name : '';
      if (layer) {
        if (shownLayers.includes(layer)) {
          select(this).attr('hidden', null);
        } else if (hiddenLayers.includes(layer)) {
          select(this).attr('hidden', true);
        }
      }
    })
  }
}

LayoutRenderer.prototype.drawStackupZonesBox = function () {
  if (this && select(this.svgNode)) {
    const ele = document.getElementsByClassName("stackup-zones-box");
    if (ele && ele[0]) {
      return;
    }

    select(this.svgNode)
      .select('g')
      .append('g')
      .attr('class', 'stackup-zones-box');
  }
}

LayoutRenderer.prototype.highlightStackupZone = function (zoneName, zone) {
  this.removeStackupZone(zoneName);
  let str = "M";

  if (!Array.isArray(zone.x) && !Array.isArray(zone.y)) {
    return;
  }

  const unit = zone.unit === "mils" ? "mil" : zone.unit;
  // mil to unit
  const milToUnit = (num, oldUnit) => {
    return unitChange({
      num: parseFloat(num),
      newUnit: this.layoutDB.mUnits === 'mils' ? 'mil' : this.layoutDB.mUnits,
      decimals: 12,
      oldUnit: oldUnit ? oldUnit : "mil"
    }).number;
  }
  let zoneData = zone.x.map((item, index) => {
    if (zone.unit !== this.layoutDB.mUnits) {
      return [milToUnit(item, unit), milToUnit(zone.y[index], unit)]
    }
    return [item, zone.y[index]]
  })

  zoneData.forEach((d) => {
    str += `${d[0]},`
    str += `${d[1]}L`
  })
  str = str.slice(0, str.length - 1)
  str += 'Z'
  let zoneClassName = zoneName.replace(/[^a-z0-9]+/ig, 'A');
  zoneClassName = `Z${zoneClassName}`;

  select(this.svgNode)
    .select('g')
    /* .select('g.stackup-zones-box') */
    .append("g")
    .attr("class", `stackup-zones stackup-zones-${zoneClassName}`)
    .append("path")
    .attr("d", str)
    .attr("stroke-width", milToUnit(2))
    .style("fill-opacity", 0)
    .style("stroke", "#ffffff")//ed7927

  const centerPoint = getCenterPoint(zoneData);
  const xTranslate = this.milToUnit(68);
  select(this.svgNode)
    .select('g')
    /*  .select('g.stackup-zones-box') */
    .append("text")
    .attr("class", `stackup-zones stackup-zones-name-${zoneClassName}`)
    .text(zoneName)
    .attr('transform', `translate(${centerPoint[0] - xTranslate},${centerPoint[1]}) rotate(-180) scale(-1,1)`)
    .style('font-size', `${this.milToUnit(68)}px`)
    .style('font-weight', 100)
    .attr('stroke-width', 1)
    .attr('fill', '#ffffff')//'#fdc647'
}

LayoutRenderer.prototype.removeStackupZone = function (zoneName) {
  if (!zoneName) {
    select(this.svgNode).select('g').selectAll(`.stackup-zones`).remove();
    return;
  }
  let zoneClassName = zoneName.replace(/[^a-z0-9]+/ig, 'A');
  zoneClassName = `Z${zoneClassName}`;
  select(this.svgNode).select('g').selectAll(`.stackup-zones-${zoneClassName}`).remove();
  select(this.svgNode).select('g').selectAll(`.stackup-zones-name-${zoneClassName}`).remove();
}

LayoutRenderer.prototype.updatePortSetupPinsInfo = function ({ selectedBoxList = [], deleteNegativePinsList = [], referencePins, editingPort }) {
  this.selectedBoxList = [...selectedBoxList];
  this.referencePins = [...referencePins];
  this.deleteNegativePinsList = [...deleteNegativePinsList];
  this.editingPort = editingPort;
}

LayoutRenderer.prototype.updatePortSetupEventFn = function ({ eventFns }) {
  this.eventFns = eventFns;
}

LayoutRenderer.prototype.rightClickPin = function ({ findPin }) {
  let _selectedList = [], findPositivePort = null, cpmType = "Add", canvasId = this.isCPMCanvas ? "cpm-setup-design-canvas" : "port-setup-canvas";
  //right mouse click
  if (this.isCPMCanvas) {
    //right mouse click
    cpmType = this.triPoints.includes(findPin.pinNumber) ? "Remove" : "Add";
    const key = this.triPoints.includes(findPin.pinNumber) ? Object.keys(this.triPointObj).find(key => this.triPointObj[key] && this.triPointObj[key].pin === findPin.pinNumber) : null;

    _selectedList = cpmType === "Add" ? ["C", "B", "A"].map(item => {
      return {
        title: `Add pin ${findPin.pinNumber} to point ${item} of the mapping triangle`,
        key: item
      };
    }) : [{ title: `Remove pin ${findPin.pinNumber} of the mapping triangle`, key }];
  } else {
    if (!this.referencePins || !this.referencePins.includes(findPin.pinNumber)) {
      return;
    }

    findPositivePort = this.editingPort && this.editingPort.references ? this.editingPort.references.find(item => item.component === findPin.comp && item.pin === findPin.pinNumber) : null;

    const selectedList = findPositivePort ? [...(this.deleteNegativePinsList || [])] : [...(this.selectedBoxList || [])];

    _selectedList = this.eventFns.getSelectedList ? this.eventFns.getSelectedList(selectedList, findPin) : selectedList.map(item => {
      if (item.editType === "Add") {
        return `Add ${findPin.pinNumber} to reference pin for port ${item.portInfo}`
      }
      if (item.editType === "Remove") {
        return `Remove ${findPin.pinNumber} from reference pin for port ${item.portInfo}`
      }
      return null;
    }).filter(item => !!item);

  }

  if (!_selectedList.length) {
    return;
  }

  const maxItem = _selectedList.map(item => item).sort((a, b) => {
    const length = a.title ? a.title.length - b.title.length : a.length - b.length;
    return length > 0 ? -1 : 1
  })[0];

  selectAll(`g.port-setup-canvas-pin-g`).remove();

  const location = getPinLocation(findPin);
  const { x, y, r } = location;
  const fontSize = this.unitToMil(r);
  const width = getCanvasTextWidth(`XX ${maxItem.title ? maxItem.title : maxItem} XXX`, parseFloat(this.milToUnit(fontSize)), select(`svg#${canvasId}`));
  const height = parseFloat(this.milToUnit(fontSize * 2 * _selectedList.length));

  const rectYc = y - height;
  select(`svg#${canvasId}`)
    .select('g')
    .append("g")
    .attr("id", `port-setup-canvas-pin-g-${findPin.comp}-${findPin.pinNumber}`)
    .attr("class", `port-setup-canvas-pin-g`)
    .on('click', () => {
      event.stopPropagation && event.stopPropagation();
    });

  //rect
  select(`g#port-setup-canvas-pin-g-${findPin.comp}-${findPin.pinNumber}`)
    .append("rect")
    .attr("width", width)
    .attr("height", height)
    .attr('x', x)
    .attr('y', y - height)
    .attr('fill', '#ffffff')

  //close button
  select(`g#port-setup-canvas-pin-g-${findPin.comp}-${findPin.pinNumber}`)
    .append("text")
    .text(`X`)
    .attr('x', x + width - this.milToUnit(fontSize * 1.5))
    .attr('y', y - this.milToUnit((fontSize) / 1.8))
    .style('font-size', this.milToUnit(fontSize))
    .style('font-weight', this.milToUnit(100))
    .attr('stroke-width', 1)
    .attr('fill', '#666')
    .style('cursor', "pointer")
    .on('click', () => {
      event.stopPropagation && event.stopPropagation();
      //remove
      selectAll(`g.port-setup-canvas-pin-g`).remove();
    });

  //select list text
  for (let i = 0; i < _selectedList.length; i++) {
    const item = _selectedList[i];
    const yc = rectYc + i * this.milToUnit(fontSize * 2);
    const xTranslate = parseFloat(this.milToUnit(fontSize)),
      yTranslate = parseFloat(this.milToUnit(-(fontSize * 3) / 4));
    const transform = `translate(${x + xTranslate}, ${yc - yTranslate}) rotate(-180) scale(-1, 1)`;
    select(`g#port-setup-canvas-pin-g-${findPin.comp}-${findPin.pinNumber}`)
      .append('text')
      .text(`${item.title ? item.title : item} `)
      .attr('transform', transform)
      .attr("class", "port-setup-select-item")
      .style('font-size', this.milToUnit(fontSize))
      .style('font-weight', this.milToUnit(100))
      .attr('stroke-width', 1)
      .attr('fill', '#40a9ff')
      .style('cursor', "pointer")
      .on('click', () => {
        //save port
        event.stopPropagation && event.stopPropagation();
        if (this.isCPMCanvas) {
          if (cpmType === "Remove") {
            if (this.eventFns && this.eventFns.removePin) {
              this.triPointObj[item.key] && this.deleteTriPoints(this.triPointObj[item.key].pinNumber);
              this.eventFns.removePin(findPin, item.key)
              this.triPointObj[item.key] = null;
              this.triPoints = [...Object.keys(this.triPointObj || {}).map(it => (this.triPointObj[it] || {}).pinNumber).filter(it => !!it)];
              this.canvasTripPoints();
            }
          } else {
            if (this.eventFns && this.eventFns.selectPin) {
              this.triPointObj[item.key] && this.deleteTriPoints(this.triPointObj[item.key].pinNumber);
              this.eventFns.selectPin(findPin, item.key)
              this.triPointObj[item.key] = findPin;
              this.triPoints = [...Object.keys(this.triPointObj || {}).map(it => (this.triPointObj[it] || {}).pinNumber).filter(it => !!it)];
              this.canvasTripPoints();
            }
          }
        } else {
          if (findPositivePort && this.eventFns && this.eventFns.deleteRefPin) {
            this.eventFns.deleteRefPin(findPin, item);
          }

          if (!findPositivePort && this.eventFns && this.eventFns.savePortPin) {
            this.eventFns.savePortPin(findPin, item);
          }
        }
        selectAll(`g.port-setup-canvas-pin-g`).remove();
      })
  }
}

LayoutRenderer.prototype.deleteTriPoints = function (pinNumber) {
  this.pointCanvasEle && this.pointCanvasEle.select(`circle#pin-canvas-point-circle-${pinNumber}-tri`).remove();
  this.pointCanvasEle && this.pointCanvasEle.select(`text#pin-canvas-point-circle-${pinNumber}-tri-text`).remove()
}

LayoutRenderer.prototype.canvasTripPoints = function (notDisplayMatch) {
  //remove prev
  select(`g#port-setup-canvas-trip-points`).remove()
  //create new
  select(`svg#cpm-setup-design-canvas`)
    .select('g')
    .append("g")
    .attr("id", "port-setup-canvas-trip-points")

  this.pointCanvasEle = select(`g#port-setup-canvas-trip-points`)
  //delete prev points
  /*  this.pointCanvasEle.selectAll(`circle.pin-canvas-point-circle-tri`).remove();
   this.pointCanvasEle.selectAll(`text.pin-canvas-point-circle-tri-text`).remove();
   this.pointCanvasEle.selectAll(`g.pin-canvas-point-match-tri`).remove(); */
  //draw line
  this.showPointLines()
  const showMatch = ["A", "B", "C"].filter(key => this.triPointObj[key] && this.triPointObj[key].pinNumber).length === 3;

  for (let key in this.triPointObj || {}) {
    if (!this.triPointObj[key]) {
      continue;
    }
    const point = this.triPointObj[key];
    const location = getPinLocation(point);
    const { x, y, r } = location;
    this.pointCanvasEle
      .append('circle')
      .attr('class', "pin-canvas-point-circle pin-canvas-point-circle-tri")
      .attr("id", `pin-canvas-point-circle-${point.pinNumber}-tri`)
      .attr('cx', x)
      .attr('cy', y)
      .attr('r', r)
      .attr('fill', getPointColor(key))
      .on("mousedown", () => {
        event && event.stopPropagation()
        if (event && event.button === 2) {
          this.rightClickPin({ findPin: point })
        }
      })
      .append('title')
      .text(point.toolTip);

    const xTrans = parseFloat(this.milToUnit(r / 3));
    let transform = `translate(${x - xTrans}, ${y - 0.3 * r}) rotate(-180) scale(-1, 1)`;
    this.pointCanvasEle
      .append('text')
      .attr("id", `pin-canvas-point-circle-${point.pinNumber}-tri-text`)
      .attr('class', "pin-canvas-point-circle-tri-text")
      .text(key)
      .attr('transform', transform)
      .attr('stroke-width', this.milToUnit(Math.ceil(this.unitToMil(r)) / 10))
      .style('font-size', this.milToUnit(Math.ceil(this.unitToMil(r))))
      .attr('stroke', 'yellow')
      .on("mousedown", (canvas) => {
        event && event.stopPropagation()
        if (event && event.button === 2) {
          this.rightClickPin({ findPin: point })
        }
      }).append('title')
      .text(point.toolTip);


    if (key === "C" && showMatch /* && _showMatch */ && !notDisplayMatch) {
      const matchEle = this.pointCanvasEle
        .append("g")
        .attr("class", "pin-canvas-point-match-tri");

      const fontSize = this.unitToMil(r);
      const width = getCanvasTextWidth(`MatchMat`, parseFloat(this.milToUnit(fontSize)), select(`svg#cpm-setup-design-canvas`));
      const height = parseFloat(this.milToUnit(fontSize * 2));
      matchEle
        .append("rect")
        .attr("class", "pin-canvas-point-match-tri-rect")
        .attr("width", width)
        .attr("height", height)
        .attr('x', x + r)
        .attr('y', y)
        .attr('fill', '#ffffff');

      const rectYc = y - height;
      const yc = rectYc + 1 * this.milToUnit(fontSize * 2);
      const xTranslate = parseFloat(this.milToUnit(fontSize)),
        yTranslate = parseFloat(this.milToUnit(-(fontSize * 3) / 4));
      const transform = `translate(${x + r + xTranslate}, ${yc - yTranslate}) rotate(-180) scale(-1, 1)`;
      matchEle
        .append('text')
        .text("Match")
        .attr('transform', transform)
        .attr("class", "pin-canvas-point-match-tri-text")
        .style('font-size', this.milToUnit(fontSize))
        .style('font-weight', this.milToUnit(100))
        .attr('stroke-width', 1)
        .attr('fill', '#40a9ff')
        .style('cursor', "pointer")
        .on('click', () => {
          //Match
          event.stopPropagation && event.stopPropagation();
          this.eventFns.matchClick && this.eventFns.matchClick()
        })
    }
  }
}

LayoutRenderer.prototype.showPointLines = function () {
  this.pointCanvasEle && this.pointCanvasEle.selectAll("g.spice-pin-canvas-points").remove()
  const points = Object.keys(this.triPointObj).map(item => this.triPointObj[item])

  const size1 = this.milToUnit(1.5),
    size2 = this.milToUnit(1);
  if (points.length > 1) {
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const point2 = i === points.length - 1 ? points[0] : points[i + 1];
      if (!point || !point2) {
        continue;
      }
      const location = getPinLocation(point);
      const location2 = getPinLocation(point2);
      let pointBox =
        this.pointCanvasEle
          .append('g').attr('class', `spice-pin-canvas-points`);

      pointBox.append('line')
        .attr('x1', location.x)
        .attr('y1', location.y)
        .attr('x2', location2.x)
        .attr('y2', location2.y)
        .attr('stroke', '#ffffffdd')
        .attr('stroke-dasharray', `${size1},${size1}`)
        .attr('stroke-width', size2)
    }
  }
}

LayoutRenderer.prototype.removeAllTriPoints = function () {
  for (let key in this.triPointObj) {
    this.triPointObj[key] && this.deleteTriPoints(this.triPointObj[key].pinNumber);
    this.triPointObj[key] = null;
  }
  this.triPoints = []
  select(`g#port-setup-canvas-trip-points`).remove()


}

LayoutRenderer.prototype.reDrawAllTriPoints = function (pairs, component, notDisplayMatch) {
  const pins = pairs.map(item => item.pin ? item.pin.pinNumber : null).filter(item => !!item);
  const compPins = { [component]: pins };
  let canvasPins = {};
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].findPins(compPins).forEach(item => {
        canvasPins[item.pinNumber] = _.cloneDeep(item);
      });
    }
  }
  for (let key in this.triPointObj) {
    const findPair = pairs.find(item => item.index === key);
    if (!findPair) {
      continue;
    }
    this.triPointObj[key] = findPair.pin && findPair.pin.pinNumber ? canvasPins[findPair.pin.pinNumber] : null;
  }
  this.triPoints = [...Object.keys(this.triPointObj || {}).map(it => (this.triPointObj[it] || {}).pinNumber).filter(it => !!it)];
  this.canvasTripPoints(notDisplayMatch)
}

LayoutRenderer.prototype.clearRightClickStatus = function () {
  selectAll(`g.port-setup-canvas-pin-g`).remove();
}

LayoutRenderer.prototype.createInputBox = function ({ svgNode, x, y, width,
  className, type, updateInputValue, defaultValue, virtualCompInfo, includeUnit, getNames, events }) {
  const _width = width ? width + parseFloat(this.milToUnit(4)) : parseFloat(this.milToUnit(154));
  /*   const height = parseFloat(this.milToUnit(36));
  
    const foreignObjectGroup = svgNode
      .append("g")
      .attr('transform', `translate(${x},${y})`);
  
    const foreignObject = foreignObjectGroup
      .append("foreignObject")
      .attr("class", "canvas-foreignObject")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", _width)
      .attr("height", height);
    //  .attr("requiredFeatures", "http://www.w3.org/TR/SVG11/feature#Extensibility")
  
    const div = foreignObject
      .append("xhtml:div")
      .attr("x", 0)
      .attr("y", 0)
      .attr("xmlns", "http://www.w3.org/1999/xhtml")
      .style("width", "100%")
      .style("height", "100%");
  
    const value = type === "name" && virtualCompInfo && RLC_TYPES.includes(virtualCompInfo.type) ? `${defaultValue}[N]` : defaultValue;
    const input = div
      .append("xhtml:input")
      .attr("type", "text")
      .style("width", "100%")
      .style("height", "100%")
      .style("font-size", parseFloat(this.milToUnit(12)))
      .attr("class", `layout-canvas-input ${className} ${includeUnit ? "layout-canvas-include-unit-input" : ""}`)
      .attr("value", value)
      .on("blur", function () {
        const inputValue = input.node().value;
        if (type === "name" && virtualCompInfo) {
          const names = getNames ? getNames() : [];
          if (names.includes(inputValue) || checkNameFormat(inputValue)) {
            input.node().value =  RLC_TYPES.includes(virtualCompInfo.type) ? `${virtualCompInfo.name}[N]` : virtualCompInfo.name;
            return;
          }
          input.node().value =  RLC_TYPES.includes(virtualCompInfo.type)? `${inputValue}[N]` : inputValue;
          virtualCompInfo.name = inputValue;
        }
        if (type === "cutLength" && numberCheck(inputValue)) {
          input.node().value = virtualCompInfo.cutLength;
          return;
        }
        virtualCompInfo[type] = inputValue;
        updateInputValue && updateInputValue(inputValue)
      })
      .on("focus", function () {
        if (type === "name" && virtualCompInfo &&  RLC_TYPES.includes(virtualCompInfo.type)) {
          input.node().value = virtualCompInfo.name;
        }
      }) */

  const cursorDefaultX = x + parseFloat(this.milToUnit(6));

  const gGroup = svgNode
    .append("g");

  const isDisabled = type === "cutLength" && VC_SHUNT_TYPES.includes(virtualCompInfo.type);

  const inputRect = gGroup
    .append("rect")
    .attr("id", `input-rect-border-${type}`)
    .attr("x", x)
    .attr("y", y)
    .attr("width", _width)
    .attr("height", parseFloat(this.milToUnit(32)))
    .attr("stroke", "#d9d9d9")
    .attr("stroke-width", parseFloat(this.milToUnit(1)))
    .attr("fill", isDisabled ? "#f0f0f0" : "#fff")
    .attr("rx", parseFloat(this.milToUnit(4)))
    .attr("ry", parseFloat(this.milToUnit(4)))
    .style("cursor", isDisabled ? "not-allowed" : "text")
    .on("click", () => {
      if (isDisabled) {
        return;
      }
      inputClick()
    });;

  const inputText = gGroup
    .append("text")
    .attr("id", `displayText-${type}`)
    .attr("x", parseFloat(this.milToUnit(6)))
    .attr('transform', `translate(${x},${y + parseFloat(this.milToUnit(11))}) rotate(-180) scale(-1, 1)`)
    .attr("width", _width)
    .attr("height", parseFloat(this.milToUnit(32)))
    .style("cursor", isDisabled ? "not-allowed" : "text")
    .style('font-size', this.milToUnit(12))
    .on("click", () => {
      if (isDisabled) {
        return;
      }
      inputClick()
    });


  const value = type === "name" && VC_RLC_TYPES.includes(virtualCompInfo.type) ? `${virtualCompInfo.name}[N]` : virtualCompInfo[type];

  inputText
    .append("tspan")
    .text(value)
    .style("color", "#000000d9")
    .style("cursor", isDisabled ? "not-allowed" : "text")
    .style('font-size', this.milToUnit(12));

  const cursorRect = gGroup
    .append("rect")
    .attr("id", `cursor-${type}`)
    .attr("x", cursorDefaultX)
    .attr("y", y + parseFloat(this.milToUnit(6)))
    .attr("width", parseFloat(this.milToUnit(1)))
    .attr("height", parseFloat(this.milToUnit(18)))
    .attr("fill", "#7b7b7b")
    .style("display", "none");

  cursorRect
    .append("animate")
    .attr("attributeName", "opacity")
    .attr("values", "0;1;0")
    .attr("dur", "1s")
    .attr("repeatCount", "indefinite");

  gGroup
    .append("filter")
    .attr("id", `shadow-${type}`)
    .append("feDropShadow")
    .attr("dx", "0")
    .attr("dy", "0")
    .attr("stdDeviation", "2")
    .attr("flood-color", "rgba(24, 144, 255, 0.2)")

  const _this = this;
  function inputClick() {
    _this.removeSelectOptions();
    const inputText = document.getElementById(`displayText-${type}`);
    let textContent = inputText.textContent;
    if (type === "name" && virtualCompInfo && VC_RLC_TYPES.includes(virtualCompInfo.type)) {
      textContent = virtualCompInfo.name;
      inputText.firstChild.textContent = virtualCompInfo.name;
    }
    inputRect.attr("stroke", "#40a9ff").attr("filter", `url(#shadow-${type})`);
    const cursor = document.getElementById(`cursor-${type}`);
    const cursorBlink = cursor.querySelector('animate');
    const inputTextLength = inputText.getComputedTextLength();
    const cursorX = cursorDefaultX + inputTextLength;
    select(`rect#cursor-${type}`).attr("x", cursorX).style("display", "block");
    cursorBlink.beginElement();
    const textInputG = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const textInput = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
    textInputG.setAttribute('id', `input-g-${type}`);
    const input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('style', 'border: none; outline: none;');

    //input.setAttribute('style', 'width: 100%; height: 100%; border: none; outline: none;');
    input.value = textContent;
    input.addEventListener('input', function () {
      inputText.firstChild.textContent = input.value;
      const inputTextLength = inputText.getComputedTextLength();
      const cursorX = cursorDefaultX + inputTextLength;
      select(`rect#cursor-${type}`).attr("x", cursorX);
    });
    textInput.appendChild(input);
    textInputG.appendChild(textInput)
    inputText.parentNode.appendChild(textInputG);
    input.focus();
    input.addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        input.blur();
      }
    });

    input.addEventListener('blur', function () {
      finishInput();
    });

    function finishInput() {
      let inputValue = input.value;
      if (inputValue === virtualCompInfo.name) {
        if (type === "name") {
          inputText.firstChild.textContent = VC_RLC_TYPES.includes(virtualCompInfo.type) ? `${virtualCompInfo.name}[N]` : virtualCompInfo.name;
        }
        _this.removeInput(type);
        return;
      }

      if (type === "name" && virtualCompInfo) {
        const names = getNames ? getNames() : [];
        if (names.includes(inputValue) || checkNameFormat(inputValue)) {
          inputValue = VC_RLC_TYPES.includes(virtualCompInfo.type) ? `${virtualCompInfo.name}[N]` : virtualCompInfo.name;
          updateInputText(inputValue)
          return;
        }
        virtualCompInfo.name = inputValue;
        inputValue = VC_RLC_TYPES.includes(virtualCompInfo.type) ? `${inputValue}[N]` : inputValue;
        updateInputText(inputValue)
        updateInputValue && updateInputValue(virtualCompInfo.name)
        return;
      }
      if (type === "cutLength" && numberCheck(inputValue)) {
        inputValue = virtualCompInfo.cutLength;
        updateInputText(inputValue)
        return;
      }
      updateInputText(inputValue)
      virtualCompInfo[type] = inputValue;
      updateInputValue && updateInputValue(inputValue)
    }

    function updateInputText(inputValue) {
      const inputText = document.getElementById(`displayText-${type}`);
      inputText.firstChild.textContent = inputValue;
      _this.removeInput(type);
    }
  }
}

LayoutRenderer.prototype.removeInput = function (type) {
  const foreignObject = select(`g#input-g-${type}`);
  if (foreignObject) {
    foreignObject.remove();
  }
  const cursor = select(`rect#cursor-${type}`);
  cursor && cursor.style("display", "none");

  const _cursor = document.getElementById(`cursor-${type}`);
  if (_cursor) {
    const cursorBlink = _cursor.querySelector('animate');
    cursorBlink && cursorBlink.endElement();
  }

  const inputRect = select(`rect#input-rect-border-${type}`);
  inputRect && inputRect.attr("stroke", "#d9d9d9").attr("filter", "")
}

LayoutRenderer.prototype.createSelectionBox = function ({ svgNode, x, y, width, options = [],
  type, updateSelectValue, defaultCompValue, defaultValue, virtualCompInfo, updateInputValue,
  includeUnit, arrowTop, arrowBottom, inputType }) {

  const _width = width ? width + parseFloat(this.milToUnit(4)) : parseFloat(this.milToUnit(154));
  const height = parseFloat(this.milToUnit(32));

  const isDisabled = inputType === "cutLength" && VC_SHUNT_TYPES.includes(virtualCompInfo.type);

  const gGroup = svgNode
    .append("g")
    .attr('transform', `translate(${x},${y})`);

  const selectRect = gGroup
    .append("rect")
    .attr("id", `selection-rect-${type}`)
    .attr("class", `selection-rect`)
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", _width)
    .attr("height", height)
    .attr("stroke", "#d9d9d9")
    .attr("stroke-width", parseFloat(this.milToUnit(1)))
    .attr("fill", isDisabled ? "#f0f0f0" : "#fff")
    .attr("rx", parseFloat(this.milToUnit(4)))
    .attr("ry", parseFloat(this.milToUnit(4)))
    .style("cursor", isDisabled ? "not-allowed" : "pointer")
    .on("click", () => {
      if (isDisabled) {
        return
      }
      selectClick()
    });

  gGroup
    .append("filter")
    .attr("id", `shadow-${type}-select`)
    .append("feDropShadow")
    .attr("dx", "0")
    .attr("dy", "0")
    .attr("stdDeviation", "2")
    .attr("flood-color", "rgba(24, 144, 255, 0.2)")

  /*   <rect x="50" y="50" width="1" height="30" fill="#000" />
   */
  if (includeUnit) {
    /* gGroup
      .attr('transform', `translate(${x},${y + parseFloat(this.milToUnit(2))})`);

    selectRect
      .attr("width", _width - parseFloat(this.milToUnit(2)))
      .attr("height", height - parseFloat(this.milToUnit(4)))
      .attr("stroke", "none")
      .attr("stroke-width", 0); */
  }

  const selectText = gGroup
    .append("text")
    .attr("id", `selectDisplayText-${type}`)
    .attr('transform', `translate(${parseFloat(this.milToUnit(6))},${parseFloat(this.milToUnit(11))}) rotate(-180) scale(-1, 1)`)
    .attr("width", _width)
    .attr("height", height)
    .style("cursor", isDisabled ? "not-allowed" : "pointer")
    .style("color", "#000000d9")
    .style('font-size', this.milToUnit(12))
    .text(virtualCompInfo[type])
    .on("click", () => {
      if (isDisabled) {
        return
      }
      selectClick()
    });

  const points = [[parseFloat(this.milToUnit(145)), parseFloat(this.milToUnit(60))],
  [parseFloat(this.milToUnit(155)), parseFloat(this.milToUnit(60))],
  [parseFloat(this.milToUnit(150)), parseFloat(this.milToUnit(65))]]
  gGroup
    .append("polygon")
    .attr("id", `arrow-${type}`)
    .attr('points', points)
    .attr('stroke-width', parseFloat(this.milToUnit(8)))
    .attr("fill", "rgb(167 167 167 / 85%)")
    .attr('transform', arrowTop);

  //options
  const _this = this;

  function selectClick() {
    let i = 0;
    const optionPar = select(_this.svgNode).select('g').append("g").attr("class", "virtual-selection-options");

    select(`polygon#arrow-${type}`).attr('transform', arrowBottom)
    selectRect.attr("stroke", "#40a9ff").attr("filter", `url(#shadow-${type}-select)`);
    const rectYc = parseFloat(_this.milToUnit(-28));
    for (let option of options) {

      const yc = y + rectYc + i * _this.milToUnit(-24);
      optionPar
        .append("rect")
        .attr("id", `selection-option-${type}-${option}-rect`)
        .attr("class", `selection-option-${type}-rect`)
        .attr("x", x)
        .attr("y", yc)
        .attr("width", _width)
        .attr("height", parseFloat(_this.milToUnit(24)))
        .attr('stroke-width', _this.milToUnit(1))
        .style("stroke", "#BBB")
        .style('cursor', "pointer")
        .attr("fill", virtualCompInfo[type] === option ? "#0d87f7" : "#fff")
        .on("click", () => {
          optionClick(option)
        });

      const yTranslate = parseFloat(_this.milToUnit(-8))
      const transform = `translate(${x + parseFloat(_this.milToUnit(8))}, ${yc - yTranslate}) rotate(-180) scale(-1, 1)`;
      optionPar
        .append('text')
        .text(option)
        .attr("id", `selection-option-${type}-${option}`)
        .attr("class", `selection-option-${type}`)
        .attr('transform', transform)
        .style('font-size', _this.milToUnit(12))
        .style('font-weight', _this.milToUnit(100))
        .style('color', virtualCompInfo[type] === option ? '#40a9ff' : 'rgb(0, 0, 0, 0.85)')
        .style('cursor', "pointer")
        .on("click", () => {
          optionClick(option)
        })
      i += 1;
    }
  }

  function optionClick(selectValue) {
    if (virtualCompInfo && selectValue === virtualCompInfo[type]) {
      selectAll(`g.virtual-selection-options`).remove();
      selectRect.attr("stroke", "#d9d9d9").attr("filter", "")
      return;
    }
    updateSelectValue && updateSelectValue(selectValue)
    if (virtualCompInfo) {
      virtualCompInfo[type] = selectValue;
    }
    selectText.text(selectValue);
    /*   select(`text.selection-option-${type}`).style("color", 'rgb(0, 0, 0, 0.85)');
      select(`rect.selection-option-${type}-rect`).attr("fill", '#fff');
  
      select(`text#selection-option-${type}-${selectValue}`).style("color", '#40a9ff');
      select(`rect#selection-option-${type}-${selectValue}-rect`).attr("fill", '#0d87f7'); */

    selectAll(`g.virtual-selection-options`).remove();
    selectRect.attr("stroke", "#d9d9d9").attr("filter", "")
  }


  /* const foreignObjectGroup = svgNode
    .append("g")
    .attr('transform', `translate(${x},${y})`);

  const foreignObject = foreignObjectGroup
    .append("foreignObject")
    .attr("class", "canvas-foreignObject")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", _width)
    .attr("height", height);

  const div = foreignObject
    .append("xhtml:div")
    .attr("xmlns", "http://www.w3.org/1999/xhtml")
    .style("width", "100%")
    .style("height", "100%")

  const select = div
    .append("xhtml:select")
    .attr("type", "text")
    .attr("xmlns", "http://www.w3.org/1999/xhtml")
    .style("font-size", parseFloat(this.milToUnit(12)))
    .attr("value", virtualCompInfo[type])
    .style("width", "100%")
    .style("height", "100%")
    .attr("class", `layout-canvas-select ${includeUnit ? "layout-canvas-include-unit-select" : ""}`)
  for (let option of options) {
    select.append("xhtml:option")
      .attr("class", `layout-canvas-selection-option-${type} layout-canvas-selection-option-${option}`)
      .attr("type", "text")
      .attr("xmlns", "http://www.w3.org/1999/xhtml")
      .text(option)
      .attr("value", option)
      .style("font-size", parseFloat(this.milToUnit(12)))

  }
  if (type && virtualCompInfo && virtualCompInfo[type]) {
    const option = svgNode.select(`.layout-canvas-selection-option-${virtualCompInfo[type]}`);
    if (option) {
      option.attr("selected", true)
    }
  }

  select.on("change", () => {
    const selectValue = select.node().value;
    updateSelectValue && updateSelectValue(selectValue)
    if (virtualCompInfo) {
      virtualCompInfo[type] = selectValue;
    }
    if (type === "type" && virtualCompInfo) {
      const input = svgNode.select('.layout-canvas-input-comp');
      virtualCompInfo.name =  RLC_TYPES.includes(selectValue) ? "UVC" : defaultCompValue;
      if (input) {
        input.node().value =  RLC_TYPES.includes(selectValue) ? `${virtualCompInfo.name}[N]` : virtualCompInfo.name
      }
      updateInputValue && updateInputValue(virtualCompInfo.name)
    }

    svgNode.selectAll(`.layout-canvas-selection-option-${type}`).attr("selected", false);
    const option = svgNode.select(`.layout-canvas-selection-option-${selectValue}`);
    if (option) {
      option.attr("selected", true)
    }
    select.attr("value", selectValue);
  }); */
}

LayoutRenderer.prototype.removeSelectOptions = function () {
  selectAll(`g.virtual-selection-options`).remove();
  if (selectAll(`rect.selection-rect`)) {
    selectAll(`rect.selection-rect`).attr("stroke", "#d9d9d9").attr("filter", "")
  }
}

LayoutRenderer.prototype.getHiddenComps = function (hiddenComps) {
  for (var i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].getHiddenComps(hiddenComps)
    }
  }
}

LayoutRenderer.prototype.deleteHiddenComp = function (hiddenComp) {
  for (var i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].deleteHiddenComp(hiddenComp)
    }
  }
}

LayoutRenderer.prototype.highlightStackupBend = function (bend, index) {

  const { x1, x2, y1, y2 } = bend;
  if ([x1, x2, y1, y2].some(i => i === undefined)) {
    return;
  }

  const unit = bend.unit === "mils" ? "mil" : bend.unit;
  // mil to unit
  const milToUnit = (num, oldUnit) => {
    return unitChange({
      num: parseFloat(num),
      newUnit: this.layoutDB.mUnits === 'mils' ? 'mil' : this.layoutDB.mUnits,
      decimals: 12,
      oldUnit: oldUnit ? oldUnit : "mil"
    }).number;
  }
  let bendData = [x1, x2, y1, y2, bend.radius].map((item, index) => {
    if (!item) {
      return 0
    }
    if (unit !== this.layoutDB.mUnits) {
      return milToUnit(item, unit)
    }
    return item
  })

  const [xs, xe, ys, ye, radius] = bendData;

  const { _point1: startLeft, _point2: endLeft } = calcParallelLine([xs, ys], [xe, ye], radius);
  const { _point1: startRight, _point2: endRight } = calcParallelLine([xs, ys], [xe, ye], -radius);

  select(this.svgNode)
    .select('g')
    /* .select('g.stackup-zones-box') */
    .append("g")
    .attr("class", `stackup-bends stackup-bends-${index}`)
    // center
    .append("line")
    .attr("class", "stackup-bends-center")
    .attr("x1", xs)
    .attr("y1", ys)
    .attr("x2", xe)
    .attr("y2", ye)
    .attr("stroke-width", milToUnit(2))
    .style("fill-opacity", 0)
    .style("stroke", "#6ab7ff")//ed7927


  // left
  select(this.svgNode)
    .select(`g.stackup-bends-${index}`)
    .append("line")
    .attr("stroke-dasharray", milToUnit(5))
    .attr("class", "stackup-bends-left")
    .attr("x1", startLeft[0])
    .attr("y1", startLeft[1])
    .attr("x2", endLeft[0])
    .attr("y2", endLeft[1])
    .attr("stroke-width", milToUnit(2))
    .style("fill-opacity", 0)
    .style("stroke", "#ffffff")//ed7927


  // right
  select(this.svgNode)
    .select(`g.stackup-bends-${index}`)
    .append("line")
    .attr("stroke-dasharray", milToUnit(5))
    .attr("class", "stackup-bends-right")
    .attr("x1", startRight[0])
    .attr("y1", startRight[1])
    .attr("x2", endRight[0])
    .attr("y2", endRight[1])
    .attr("stroke-width", milToUnit(2))
    .style("fill-opacity", 0)
    .style("stroke", "#ffffff")//ed7927
}

LayoutRenderer.prototype.removeStackupBends = function () {
  select(this.svgNode)
    .select('g')
    .selectAll('g.stackup-bends')
    .remove()
}
LayoutRenderer.prototype.resetModelPoints = function (points, radius, component, notDisplayMatch) {
  this.d3Root.select("g.layout-node-points-g").remove()
  this.pointCanvasNodesEle = this.d3Root
    .append('g')
    .attr('layer', "points")
    .attr("class", "layout-node-points-g")
    .attr('hidden', null);

  const minX = Math.min(...points.map(item => item.location[0]));
  const minY = Math.min(...points.map(item => item.location[1]));
  const maxX = Math.max(...points.map(item => item.location[0]));
  const maxY = Math.max(...points.map(item => item.location[1]));
  this.d3Root.select(`rect#${component}-rect`)
    .attr('x', minX - radius)
    .attr('y', minY - radius)
    .attr('fill', '#ffffff')
    .attr("opacity", "0.3")
    .style('width', maxX - minX + radius * 2)
    .style('height', maxY - minY + radius * 2)
  for (let point of points) {
    const color = /* point.type === "Power" ? "#14ec4f" : */ "#0f31b3";
    this.pointCanvasNodesEle
      .append('circle')
      .attr('class', "node-canvas-point-circle")
      .attr('cx', point.location[0])
      .attr('cy', point.location[1])
      .attr('r', radius - radius * 0.4)
      .attr('fill', color)
      .on("mousedown", (canvas) => {
        event && event.stopPropagation()
        if (event && event.button === 2) {
          for (let layerInfo of this.layerList) {
            const findComp = layerInfo.compObjList && layerInfo.compObjList.length ? layerInfo.compObjList.find(item => component === item.name) : {};
            if (findComp && findComp.objList && findComp.objList.canvasCircleList && findComp.objList.canvasCircleList.length) {
              const pointInfo = findComp.objList.canvasCircleList.find(item => item.pin === point.packagePin)
              if (pointInfo) {
                this.rightClickPin({ findPin: pointInfo })
                break
              }
            }
          }
        }
      })
      .attr("opacity", "0.8")
      .append('title')
      .text(point.type === "Signal" ? `${point.net}::${point.node}` : `${point.node}::${point.net}`);
  }
  this.canvasTripPoints(notDisplayMatch)
}

LayoutRenderer.prototype.removeModelPoints = function (component) {
  this.d3Root.select("g.layout-node-points-g").remove()
  this.d3Root.select(`rect#${component}-rect`)
    .attr('x', 0)
    .attr('y', 0)
    .attr('fill', "none")
    .attr("opacity", "0")
}

LayoutRenderer.prototype.removeGridLines = function () {
  select(this.svgNode)
    .select('g')
    .selectAll('g.gridLines')
    .remove()
}


LayoutRenderer.prototype.drawGridLine = function (column, row, targetIC) {
  if (targetIC) {
    const point = this.getCurrentPoints(targetIC)
    if (point && point.topLeft && point.bottomRight) {
      const { topLeft, bottomRight } = point
      const columnWidth = (bottomRight.x - topLeft.x) / column;
      const rowHeight = (topLeft.y - bottomRight.y) / row;
      let initialX1, initialY1;
      let lineIndex, lineDirection;
      let newX, newY;
      const lineDrag = drag().on("start", function () {
        const element = select(this)
        initialX1 = +element.attr("x1");
        initialY1 = +element.attr("y1");
        lineIndex = +element.attr("index")
        lineDirection = element.attr("direction");
      }).on("drag", function () {
        const element = select(this)
        if (lineDirection === 'v') {
          const dx = event.x - event.subject.x;
          newX = initialX1 + dx;
          newX = Math.max(newX, topLeft.x);
          newX = Math.min(newX, bottomRight.x);
          element
            .attr("x1", newX)
            .attr("x2", newX);
        } else if (lineDirection === 'h') {
          const dy = event.y - event.subject.y;
          newY = initialY1 + dy;
          newY = Math.min(newY, topLeft.y);
          newY = Math.max(newY, bottomRight.y);
          element
            .attr("y1", newY)
            .attr("y2", newY);
        }
      }).on("end", () => {
        if (lineDirection === 'v') {
          this.verticalLines[lineIndex].x1 = newX
          this.verticalLines[lineIndex].x2 = newX
        } else if (lineDirection === 'h') {
          this.horizontalLines[lineIndex].y1 = newY
          this.horizontalLines[lineIndex].y2 = newY
        }
      })

      for (let i = 0, len = this.layerList.length; i < len; i++) {
        if (this.layerList[i] instanceof CompLayerRenderer) {
          this.verticalLines = []
          this.horizontalLines = []
          const gridLines = select(this.svgNode)
            .select('g')
            .append("g")
            .attr("class", 'gridLines')
          for (let j = 1; j < column; j++) {
            const x1 = topLeft.x + j * columnWidth
            const y1 = topLeft.y
            const x2 = topLeft.x + j * columnWidth
            const y2 = bottomRight.y
            this.verticalLines.push({ x1, y1, x2, y2 })
            gridLines
              .append("line")
              .attr("x1", x1)
              .attr("y1", y1)
              .attr("x2", x2)
              .attr("y2", y2)
              .attr("stroke-width", this.outlineStrokeWidth)
              .attr("direction", 'v')
              .attr('index', j - 1)
              .style("fill-opacity", 0)
              .style("stroke", "orange")
              .on("mouseover", function () {
                select(this).style("cursor", "col-resize");
              })
              .on("mouseout", function () {
                select(this).style("cursor", "");
              })
              .call(lineDrag)
          }
          for (let j = 1; j < row; j++) {
            const x1 = topLeft.x
            const y1 = topLeft.y - j * rowHeight
            const x2 = bottomRight.x
            const y2 = topLeft.y - j * rowHeight
            this.horizontalLines.push({ x1, y1, x2, y2 })
            gridLines
              .append("line")
              .attr("x1", x1)
              .attr("y1", y1)
              .attr("x2", x2)
              .attr("y2", y2)
              .attr("stroke-width", this.outlineStrokeWidth)
              .attr("direction", 'h')
              .attr('index', j - 1)
              .style("fill-opacity", 0)
              .style("stroke", "orange")
              .on("mouseover", function () {
                select(this).style("cursor", "row-resize");
              })
              .on("mouseout", function () {
                select(this).style("cursor", "");
              })
              .call(lineDrag)
          }
        }
      }
    }
  }
}

LayoutRenderer.prototype.getCurrentLines = function () {
  return { verticalLines: this.verticalLines, horizontalLines: this.horizontalLines }
}

LayoutRenderer.prototype.getCurrentPoints = function (targetIC) {
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      if (targetIC) {
        const point = this.layerList[i].getFourPoint(targetIC);
        const { mXs, mYs } = point
        const topLeft = { x: Math.min(...mXs), y: Math.max(...mYs) };
        const bottomRight = { x: Math.max(...mXs), y: Math.min(...mYs) };
        return { topLeft, bottomRight }
      }
    }
  }
}

LayoutRenderer.prototype.getPinPath = function (pins, size, widthSize, heightSize) {
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].getPinPath(pins, size, widthSize, heightSize)
      let pinPathNode;
      const gridLines = select(this.svgNode)
        .select('g')
        .select('g.gridLines')
      if (!gridLines.empty()) {
        pinPathNode = select(this.svgNode)
          .select('g')
          .insert("g", ".gridLines")
          .attr("class", 'pinPath')
      } else {
        pinPathNode = select(this.svgNode)
          .select('g')
          .append("g")
          .attr("class", 'pinPath')
      }
      this.layerList[i].redrawPinPath(pinPathNode)
    }
  }
}

LayoutRenderer.prototype.removePinPath = function () {
  select(this.svgNode)
    .select('g')
    .selectAll('g.pinPath')
    .remove()
}

LayoutRenderer.prototype.getPinInfo = function (comp, pin) {
  let pinList = []
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      const pins = this.layerList[i].findPins({ [comp]: [pin] });
      pinList.push(...pins)
    }
  }
  return pinList
}

LayoutRenderer.prototype.hiddenPins = function (showPinList) {
  const comps = [...new Set(showPinList.map(item => item.name))];
  let displayPinList = {};
  for (let comp of comps) {
    displayPinList[comp] = showPinList.filter(item => item.name === comp).map(item => item.pin)
  }
  for (let i = 0, len = this.layerList.length; i < len; i++) {
    if (this.layerList[i] instanceof CompLayerRenderer) {
      this.layerList[i].setNotDisplayPin(displayPinList)
    }
  }
}

export default LayoutRenderer;