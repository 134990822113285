import React, { PureComponent } from "react";
import { CloseOutlined, DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Switch } from "antd";
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import '../Selection.css';

class SelectionPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.root = document.getElementById(`PCB-${this.props.designID}`);
  }

  onCancel = () => { this.props.cancel(); }

  onSelectedAll = (e) => {
    const { designID } = this.props;
    const chooseFlag = e.target.checked;
    if (chooseFlag) {
      this.props.selectedAll(designID);
    } else {
      this.props.cancelSelectedAll(designID);
    }
  }

  onSelect = (e, type, item) => {
    const { designID, selections } = this.props;
    const selected = selections[designID] && selections[designID].selected || [];
    const chooseFlag = e.target.checked;
    let selects = [...selected];
    selects.forEach(select => {
      if (select.name === item.name) {
        select.checked = chooseFlag;
      }
    });
    this.props.checkboxChanged({ selected: [...selects], designID });
  }

  onShowSelection = () => {
    const { designID } = this.props;
    this.props.showSelected(designID);
  }

  onHideSelection = () => {
    const { designID } = this.props;
    this.props.hideSelected(designID);
  }

  onClearSelection = (e) => {
    e && e.stopPropagation()
    const { designID } = this.props;
    this.props.clearSelection(designID);
  }

  onClearOneSelection = (e, name) => {
    e.stopPropagation();
    const { designID } = this.props;
    this.props.clearOneSelection(name, designID);
  }

  oncheckAllChange = (e) => {
    this.onSelectedAll(e);
  }

  changeShowNameStatus = (value) => {
    const { designID } = this.props;
    this.props.changeShowNameStatus(value, designID)
  }

  renderResult = () => {
    const { pageType, designID, selections } = this.props;
    const selected = selections[designID] && selections[designID].selected || [];
    const defaultCheckedList = selected.filter(item => item.checked);
    const checkedAllFlag = defaultCheckedList.length > 0 && defaultCheckedList.length === selected.length;
    const indeterminateFlag = checkedAllFlag ? false : defaultCheckedList.length ? true : false;
    const showNameStatus = selections[designID] ? (selections[designID].showNameStatus || false) : false;
    return (
      <div className="seletion-result" >
        {
          (selected && selected.length === 0) ? (<div className="result-title-nothing" > Nothing selected </div>
          ) : (
            <div className='selectionlist'>
              {<div className='result-selected' >
                <Checkbox
                  key={checkedAllFlag}
                  indeterminate={indeterminateFlag}
                  onChange={this.oncheckAllChange}
                  checked={checkedAllFlag}
                >All
                </Checkbox>
                <div className='seleted-icon'>
                  <Tooltip title='Display'>
                    <EyeOutlined className='dispaly-selection' onClick={(e) => this.onShowSelection(e)} />
                  </Tooltip>
                  <Tooltip title='Not Display'>
                    <EyeInvisibleOutlined className='dispaly-selection' onClick={(e) => this.onHideSelection(e)} />
                  </Tooltip>
                  <Tooltip title='Clear All'>
                    <DeleteOutlined className='dispaly-selection' onClick={(e) => this.onClearSelection(e)} />
                  </Tooltip>
                  {['SIERRA', 'CASCADE', "ROCKY"].includes(pageType) &&
                    <div className="display-selection-switch-box">
                      <span>Annotation</span>
                      <Switch
                        size="small"
                        className="aurora-switch-small display-selection-switch"
                        checked={showNameStatus}
                        onChange={this.changeShowNameStatus}
                      />
                    </div>
                  }
                </div>
              </div>}
              {selected && selected.length > 0 && (<ul className='result-list'>
                {selected.map((item, i) =>
                  <div key={item.name + i} >
                    <Checkbox
                      onChange={(e) => this.onSelect(e, item.type, item)}
                      checked={item.checked}
                    >{item.name}
                    </Checkbox>
                    <Tooltip title='Delete'>
                      <CloseOutlined
                        style={{ fontSize: '12px' }}
                        onClick={(e) => this.onClearOneSelection(e, item.name)}></CloseOutlined>
                    </Tooltip>
                  </div>)}
              </ul>)}
            </div>
          )
        }
      </div>
    );
  }

  render() {
    const { defaultLeft, defaultTop, width, leftWidth } = this.props;
    if (!this.root) return null;
    const content = (
      <Panel title={<label className='selection-panel-title' >Net and Component Search</label>}
        visible
        onCancel={this.onCancel}
        width={300}
        position='panel-bottom-right'
        className='selection-panel'
        left={width ? leftWidth : defaultLeft}
        top={defaultTop}
        draggable
        minWidth={256}
        minHeight={200}
        maxWidth={600}
        redirectDom='aurora-content'
      >
        <div>
          {this.renderResult()}
        </div>
      </Panel>
    )
    return createPortal(content, this.root)
  }
}

export default SelectionPanel;