import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import {
  CREATE_PRE_LAYOUT,
  CREATE_DECAP_GROUP,
  DELETE_DECAP_GROUP,
  SAVE_DECAP_GROUP,
  UPDATE_VRM_VOLTAGE,
  UPDATE_BALL,
  OPEN_PRE_LAYOUT,
  UPDATE_DC_COMPONENTS,
  UPDATE_DC_NETS
} from './actionTypes';
import projectDesigns from '@/services/helper/projectDesigns';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import { getDefaultName } from '../../../../services/helper/setDefaultName';
import { AC_PRE_LAYOUT_VERSION, DC_PRE_LAYOUT_VERSION } from '../../../../version';
import { PRE_LAYOUT_DESIGN_VERSION, PreLayoutBall, PreLayoutContent, initDecapGroup, initNewPreLayout } from '../../../../services/Cascade/prelayout';
import { getCascadePreLayout, saveCascadePreLayout } from '../../../../services/Cascade/prelayout/prelayoutCtrl';
import { openPage, openProject, updateExpand, updateSelectKeys, updateViewList } from '../project/action';
import { DC, PACKAGE, PACKAGES, PCB, PCBS, PCB_PRE_LAYOUT } from '../../../../constants/treeConstants';
import designConstructor from '../../../../services/helper/designConstructor';
import { firstOpenPreLayout, showDecapGroup, updateCanvasStatus, updatePreLayoutContent } from './action';
import { getViewListBySelectedKey } from '../../../../services/helper/filterHelper';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';

function* createPreLayout(action) {
  const { CascadeReducer: { project: { openProjectId, selectedKeys, viewList, expandedKeys } } } = yield select();
  const { designType, electric } = action;

  const designs = projectDesigns.getDesigns(openProjectId);
  const designNames = designs.filter(d => d.vendor === PRE_LAYOUT).map(d => d.name);
  const name = getDefaultName({ nameList: designNames, defaultKey: "PreLayout" });
  const _designType = designType === PACKAGES ? PACKAGE : PCB;

  const data = {
    id: "",
    name,
    projectId: openProjectId,
    version: electric === DC ? DC_PRE_LAYOUT_VERSION : AC_PRE_LAYOUT_VERSION,
    designVersion: PRE_LAYOUT_DESIGN_VERSION,
    type: _designType,
    electric,
    content: initNewPreLayout(_designType, electric)
  }

  try {
    const res = yield call(saveCascadePreLayout, data);

    //re open project -> update design list
    yield put(openProject(openProjectId));

    //if PCB not expand, expand PCB
    if (!expandedKeys.includes(`${designType}-${openProjectId}`)) {
      const expandKeys = [...expandedKeys, `${designType === PCB ? PCBS : PACKAGES}-${openProjectId}`];
      yield put(updateExpand(expandKeys));
    }

    if (res) {
      const designID = res.id;
      yield put(updatePreLayoutContent({ ...data, id: designID, groupShow: "" }));
      designConstructor.addDesign(designID, { ...data, ...res });
      projectDesigns.addDesign(openProjectId, { ...data, ...res })

      //open current pre layout
      let selectKeys = []
      if (viewList.length <= 1) {
        selectKeys = [`${PCB_PRE_LAYOUT}-${designID}`]
      } else {
        selectKeys = selectedKeys.filter(item => !item.match(PCB) && !item.match(PACKAGE) && !item.match(PCB_PRE_LAYOUT));
        selectKeys = [...selectKeys, `${PCB_PRE_LAYOUT}-${designID}`];
      }

      yield put(updateSelectKeys(selectKeys));
      yield put(updateViewList(getViewListBySelectedKey(selectKeys)));

      yield put(openPage({ pageType: PCB_PRE_LAYOUT, id: designID }));
      yield delay(300)
      yield put(firstOpenPreLayout(true));
    }
  } catch (e) {
    console.error("Create PreLayout failed:" + e)
  }
}

function* openPreLayout(action) {
  const { id } = action;
  if (!id) {
    return;
  }

  try {
    yield put(updateCanvasStatus(false));
    const res = yield call(getCascadePreLayout, id);
    if (res) {
      preLayoutData.savePreLayout(id, res);
      yield put(updatePreLayoutContent({ ...res, groupShow: "" }));
      yield delay(200);
      yield put(updateCanvasStatus(true));
    }
  } catch (e) {
    console.error("Open PreLayout failed:" + e);
  }
}

function* savePreLayout(action) {
  const { CascadeReducer: { project: { openProjectId }, prelayout } } = yield select();
  const { id, name, type, content, electric } = prelayout;

  try {
    const data = {
      id: id,
      name,
      projectId: openProjectId,
      version: electric === DC ? DC_PRE_LAYOUT_VERSION : AC_PRE_LAYOUT_VERSION,
      designVersion: PRE_LAYOUT_DESIGN_VERSION,
      electric,
      type,
      content: new PreLayoutContent(content)
    }
    const res = yield call(saveCascadePreLayout, data);
    if (res) {
      preLayoutData.savePreLayout(id, data);
    }
  } catch (e) {
    console.error("Save PreLayout failed:" + e);
  }
}

function* createNewDecapGroup() {
  const { CascadeReducer: { prelayout: { content } } } = yield select();
  const { decapGroups, powerNets, referenceNets } = content;
  const groupNames = decapGroups.map(item => item.name);
  const newGroupName = getDefaultName({ nameList: groupNames, defaultKey: 'DecapGroup_' });
  const newGroup = initDecapGroup(powerNets[0], referenceNets[0], newGroupName);
  let data = { ...content };
  data.decapGroups.push(newGroup);
  yield put(updatePreLayoutContent({ content: data }));
  yield put(showDecapGroup(newGroupName));
  yield put(updateCanvasStatus(true));
  yield call(savePreLayout)
}

function* deleteDecapGroup() {
  const { CascadeReducer: { prelayout: { groupShow, content = {} } } } = yield select();
  const { decapGroups } = content;
  const newGroups = decapGroups.filter(item => item.name !== groupShow);
  let data = { ...content };
  data.decapGroups = newGroups
  yield put(updatePreLayoutContent({ content: data }));
  yield put(showDecapGroup(""));
  yield put(updateCanvasStatus(true));
  yield call(savePreLayout)
}

function* saveDecapGroup(action) {
  const { group } = action;
  const { CascadeReducer: { prelayout: { groupShow, content = {} } } } = yield select();
  const { decapGroups } = content;
  const groupIndex = decapGroups.findIndex(item => item.name === groupShow);
  if (!group.name) {
    const groupNames = decapGroups.map(item => item.name);
    const newGroupName = getDefaultName({ nameList: groupNames, defaultKey: 'DecapGroup_' });
    group.name = newGroupName;
  }
  let data = { ...content };
  data.decapGroups[groupIndex] = group
  yield put(updatePreLayoutContent({ content: data }));
  yield put(showDecapGroup(group ? group.name : ''));
  yield put(updateCanvasStatus(true));
  yield call(savePreLayout)
}

function* updateVRMVoltage(action) {
  const { name, voltage } = action;
  const { CascadeReducer: { prelayout: { content = {} } } } = yield select();
  const data = { ...content };
  if (data.components) {
    const findIndex = data.components.findIndex(item => item.name === name);
    data.components[findIndex].voltage = voltage;
    yield put(updatePreLayoutContent({ content: data }));
    yield call(savePreLayout)
  }
}

function* updateBall(action) {
  const { value, key } = action;
  const { CascadeReducer: { prelayout: { content = {} } } } = yield select();
  const data = { ...content };
  const _ball = data.ball || {};
  data.ball = new PreLayoutBall({ ..._ball, [key]: value });
  yield put(updatePreLayoutContent({ content: data }));
  yield call(savePreLayout)
}

function* updateDcComponents(action) {
  const { comp } = action;
  const { CascadeReducer: { prelayout: { content = {} } } } = yield select();
  const { components, nets } = content;

  const findIndex = components.findIndex(item => item.id === comp.id);
  if (findIndex < 0) {
    return;
  }
  const oldComp = JSON.parse(JSON.stringify(components[findIndex]))
  components[findIndex] = comp;

  const newNets = nets.map(net => {
    const { components } = net;
    const _components = components.filter(c => c !== oldComp.name);
    return { ...net, components: [..._components, comp.name] }
  })
  const data = { ...content, components, nets: newNets };
  yield put(updatePreLayoutContent({ content: data }));
  yield call(savePreLayout)
}

function* updateDcNets(action) {
  const { net } = action;
  const { CascadeReducer: { prelayout: { content = {} } } } = yield select();
  const { components, nets } = content;

  const findIndex = nets.findIndex(item => item.id === net.id);

  if (findIndex < 0) {
    return;
  }
  const oldNet = JSON.parse(JSON.stringify(nets[findIndex]))
  nets[findIndex] = net;

  const newComponents = components.map(comp => {
    const { pins } = comp;
    const _pins = pins.filter(pin => pin.net !== oldNet.name);

    return { ...comp, pins: [..._pins, { pin: net.name, pinName: net.name, net: net.name, node: "" }] }
  })
  const data = { ...content, nets, components: newComponents };
  yield put(updatePreLayoutContent({ content: data }));
  yield call(savePreLayout)
}

function* CascadePreLayoutSaga() {
  yield takeEvery(CREATE_PRE_LAYOUT, createPreLayout);
  yield takeEvery(OPEN_PRE_LAYOUT, openPreLayout);

  // AC
  yield takeEvery(CREATE_DECAP_GROUP, createNewDecapGroup);
  yield takeEvery(DELETE_DECAP_GROUP, deleteDecapGroup);
  yield takeEvery(SAVE_DECAP_GROUP, saveDecapGroup);
  yield takeEvery(UPDATE_VRM_VOLTAGE, updateVRMVoltage);
  yield takeEvery(UPDATE_BALL, updateBall);

  //DC
  yield takeEvery(UPDATE_DC_COMPONENTS, updateDcComponents);
  yield takeEvery(UPDATE_DC_NETS, updateDcNets);
}

export default CascadePreLayoutSaga;