import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { DeleteFilled } from '@ant-design/icons';
import { Row, Col, Spin, message } from 'antd';
import Parameters from '@/services/PDN/Sparameter/parameter';
import Target from './Sider/target';
import Result from './Sider/result';
import Import from './Sider/import';
import History from './Sider/history';
import SparameterSetting from './Sider/setting';
import Line from '@/components/Line/index';
import { deleteImportFile, savePdnResult, delResultHistory } from '@/services/PDN';
import { getResultFiles } from '@/services/api/pdn/PDNAPICtrl';
import { uploadSnpFile, changeFileStatus } from '../store/result/action';
import HistoryPanel from './historyPanel';
import dayjs from 'dayjs';
import HistoryData from '../../../services/PDN/resultHistory';
import photo from '@/components/PublicSvg/photo.svg';
import saveSvg from 'save-svg-as-png';
import OptimizationPanel from './optimizationPanel';
import { updateProject } from '../store/project/action';
import { selectChange, selectLayer } from '../../LayoutExplorer/store/FastPI/actionCreators';
import FileSaver from 'file-saver';
import NP from 'number-precision';
import getIndex from '@/services/helper/insertionSearch';
import { scaleConversion } from '@/services/helper/numberHelper';
import { getVRMModelUpdate } from '../store/simulation/action';
import { strDelimited } from '@/services/helper/split';
import { axisFormat } from '@/services/Result/Public/sparameter/dataHelper';
import projectDesigns from '../../../services/helper/projectDesigns';
import canvas from '@/services/LayoutCanvas';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import './new.css';
import { RESULTS } from '../constants';
import ResultZoom from '../../../components/ResultTopMenu/resultZoom';

let historyData = null;
class Sparameter extends Component {

  constructor(props) {
    super(props);
    this.uploadSP = createRef();
    this.svgRef = createRef();
    this.contentRef = createRef();
    this.settingRef = createRef();
    this.uploadSnpRef = createRef();
    this.uploadDecapConfigRef = createRef();
    this.state = {
      files: [],
      settingAction: '',
      curveSettingAct: '',
      axis: {
        xShow: true,
        yShow: true,
        xMin: '',
        xMax: '',
        yMin: '',
        yMax: '',
        yUnit: '',
        xScale: 'log',
        yScale: 'linear'
      },
      setting: {
        parameter: 'z', // s / y / z
        value: 'am' // am / ph / re / im
      },
      portSelect: {},
      selectedTags: null,
      leftWidth: "70%",
      rightWidth: "30%",
      loading: true,
      fileListBottom: 318,
      XUnit: 'MHz',
      settingShow: true,
      historyShow: false,
      historyJson: false,
      historyItemList: [],
      historyNameList: [],
      resultName: '',
      saveInputVisible: false,
      saveHistoryError: null,
      importDisabled: false,
      showOptimizationPanel: false,
      curveUnit: null,
      optTime: ''
    }
    this.plot2d = null;
    this.resize = this.resize.bind(this);
  }

  _getIndex(xPoints, freq) {
    return getIndex(xPoints, freq, 0, xPoints.length - 1);
  }

  changeMouse = (frequence) => {
    let { files, portSelect, setting } = this.state;
    const { parameter, value } = setting;
    const list = Object.values(portSelect);
    for (const ports of list) {
      for (const port of ports) {
        const [fileId, row, col] = strDelimited(port, "::");
        let fileIndex = files.findIndex(item => item.id === fileId);
        if ((!fileIndex && fileIndex !== 0) || !row || !col) {
          continue;
        }
        const index = this._getIndex(files[fileIndex].freq, frequence);
        // When the mouse moves, the value of y is displayed.
        if (index > -1) {
          const fileData = files[fileIndex].matrix[row][col][parameter + value];
          if (fileData && fileData[index]) {
            if (parseInt(fileData[index]) < 1) {
              fileData[index] = parseFloat(fileData[index].toPrecision(3));
            }

            if (parseInt(fileData[index]) < 100 && parseInt(fileData[index]) >= 1) {
              fileData[index] = parseFloat(fileData[index].toFixed(2));
            }

            if (parseInt(fileData[index]) < 1000 && parseInt(fileData[index]) >= 100) {
              fileData[index] = parseFloat(fileData[index].toPrecision(4));
            }

            if (parseInt(fileData[index]) > 1000) {
              fileData[index] = parseInt(fileData[index]);
            };
            files[fileIndex].matrix[row][col].current = fileData[index];
            /*  files[fileIndex].matrix[row][col].current = (fileData[index]).toFixed(4); */
          } else {
            files[fileIndex].matrix[row][col].current = '';
          }
        } else {
          files[fileIndex].matrix[row][col].current = '';
        }
      };
    }
    this.setState({
      files
    });
  }

  cancelMove = () => {
    let { files, portSelect } = this.state;
    const list = Object.values(portSelect);
    for (const ports of list) {
      for (const port of ports) {
        const [file, row, col] = strDelimited(port, "::");
        let fileIndex = files.findIndex(item => item.id === file);
        files[fileIndex].matrix[row][col].current = '';
      }
    };
    this.setState({
      files
    });
  }

  changeAxis = (axis) => {
    const { XUnit } = this.state;
    this.setState((prevState) => ({
      axis: { ...prevState.axis, ...axisFormat(XUnit, axis) }
    }))
  }

  componentDidUpdate(prevProps) {
    const { verificationId, PDNID, currentProjectId } = this.props;
    if (prevProps.verificationId !== verificationId) {
      this.changeResult();
      historyData = new HistoryData(verificationId);
      this.props._getVRMModelUpdate(currentProjectId, PDNID);
    }
    const { contentHeight, contentWidth, files } = this.state;
    if (this.contentRef.current && contentHeight !== this.contentRef.current.offsetHeight) {
      const height = this.contentRef.current.offsetHeight;
      this.setState({ contentHeight: height });
      if (this.plot2d && files && files.length) {
        this.plot2d.resetPlot();
        const { axis, setting } = this.state;
        Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    }

    if (this.contentRef.current && contentWidth !== this.contentRef.current.offsetWidth) {
      const width = this.contentRef.current.offsetWidth;
      this.setState({ contentWidth: width });
      if (this.plot2d && files && files.length) {
        this.plot2d.resetPlot();
        const { axis, setting } = this.state;
        Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    }

    const { fileListBottom } = this.state;
    if (this.settingRef.current && fileListBottom !== this.settingRef.current.offsetHeight) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }

    const { importStatus } = this.props;
    if (importStatus !== prevProps.importStatus && importStatus === true) {
      this.getImportFile();
      this.uploadSnpRef.current.value = '';
      this.setState({
        importDisabled: false
      })
      this.props.changeFileStatus(false);
    }
  }

  componentDidMount() {
    const { verificationId, currentProjectId, PDNID } = this.props;
    if (verificationId) {
      this.changeResult();
      historyData = new HistoryData(verificationId);
      this.props._getVRMModelUpdate(currentProjectId, PDNID);
      if (this.settingRef.current) {
        this.setState({
          fileListBottom: this.settingRef.current.offsetHeight
        })
      }
    }
  }

  removeAllCurves = () => {
    const { portSelect, files } = this.state;
    const selects = Object.values(portSelect).reduce((a, b) => {
      return a.concat(b);
    }, []);
    let optCurve = null;
    let ports = [...selects];
    const removeCurves = ports.map(curve => {
      let [fileId, row, col] = strDelimited(curve, "::");
      let _file = files.find(file => file.id === fileId);
      let hashId = '';
      if (_file) {
        hashId = _file.hashId
      }
      if (_file.type === 'target') {
        let opt_file = files.find(file => file.id === fileId.replace('target', 'optTarget'))
        if (opt_file) {
          optCurve = { row, col, id: opt_file.id, hashId: opt_file.hashId }
        }
      }
      return { row, col, id: fileId, hashId };
    });
    if (optCurve) {
      removeCurves.push(optCurve);
    }
    if (removeCurves && removeCurves.length > 0) {
      Parameters.removeCurves(removeCurves);
    };
  }


  changeResult = () => {
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis,
    }
    this.removeAllCurves();
    if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
      Array.from((this.svgRef.current.children || [])).forEach(child => {
        this.svgRef.current.removeChild(child);
      })
    };
    Parameters.cleanParameters();
    this.setState({
      loading: true,
      portSelect: {}
    }, () => {
      const { verificationId, projectId, pdnId, existResult } = this.props;
      if (!existResult) {
        this.setState({
          files: [],
          portSelect: {},
          historyNameList: [],
          historyShow: true
        });
      }
      const { parameter, value } = this.state.setting;
      this.setState({
        historyNameList: []
      })
      this.plot2d = Parameters.createPlot(this.svgRef.current, { param: parameter, value }, events);
      Parameters.getParametersInfo(verificationId, projectId, pdnId, "current").then(({ params, time }) => {
        time = new Date(`${time.substring(0, 4)}-${time.substring(4, 6)}-${time.substring(6, 8)} ${time.substring(9, 11)}:${time.substring(11, 13)}+0000`);
        time.setUTCSeconds(time.getUTCSeconds());
        time = dayjs(time.toUTCString()).format('YYYYMMDD_HHmm');
        const parametersArr = Array.from(params.values());
        if (parametersArr.length > 0) {
          let files = Array.from(params.values()).map(item => ({ ...item, show: true }));
          this.getAllNpi(files).then(res => {
            const filter = this.setTargetColor(res.filter(item => !!item));
            this.setState({
              files: [...filter.map(item => ({ ...item, show: true }))],
              loading: false,
              resultName: time
            }, () => {
              const simulations = filter.map(item => item.type === "simulation" || item.type === "target");
              simulations.forEach((isSim, index) => {
                if (isSim) {
                  this.changePort([filter[index].id + '::0::0'], filter[index].id);
                }
              });
              this.getResultHistoryList();
            })
          }, error => {
            this.setState({
              files: files,
              loading: false,
              resultName: time
            });
            this.getResultHistoryList();
          })
        } else {
          this.setState({
            loading: false,
            files: [],
            resultName: time
          });
          this.getResultHistoryList();
          // return message.error('No result!');
        }
      })
      this.screenChange();
    })
  }

  getAllNpi = (files) => {
    const simulationResult = files.filter(item => item.type !== 'import');
    const importFiles = files.filter(item => item.type === 'import');
    const promiseList = simulationResult.map((item, index) => {
      return item.getNpiFile(index);
    });
    const importList = importFiles.map((item, index) => {
      return item.getImportNpiFile(simulationResult.length + index);
    });
    return Promise.all([...promiseList, ...importList]);
  }

  getSingleNpi = (file, type) => {
    const { files } = this.state;
    let length = files.length >= 0 ? files.length : 0;
    let promiseList = null;
    if (type && type === 'import') {
      promiseList = file.map((item, index) => {
        return item.getImportNpiFile(length);
      })
    } else {
      promiseList = file.map((item, index) => {
        return item.getNpiFile(length);
      })
    }
    return Promise.all(promiseList);
  }

  getHistoryNpi = (files) => {
    let length = this.state.files.length >= 0 ? this.state.files.length : 0;
    const promiseList = files.map((item, index) => {
      return item.getHistoryNpiFile(length + index);
    })
    return Promise.all(promiseList);
  }

  changeWidth = (leftWidth) => {
    const { contentWidth, files } = this.state;
    if (contentWidth) {
      let rightWidth = contentWidth - leftWidth;
      if (rightWidth < 240) {
        leftWidth = (contentWidth - 240) / contentWidth * 100 + "%";
        rightWidth = 240 / contentWidth * 100 + '%';
      } else {
        leftWidth = leftWidth / contentWidth * 100 + "%";
        rightWidth = rightWidth / contentWidth * 100 + "%";
      }
      this.setState({
        rightWidth,
        leftWidth,
      }, () => {
        if (this.plot2d && files && files.length) {
          this.plot2d.resetPlot();
          const { axis, setting } = this.state;
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      });
    }
  }

  getLeftWidth = () => {
    if (this.contentRef.current) {
      let contentWidth = this.contentRef.current.offsetWidth;
      let leftWidth = parseFloat(this.state.leftWidth) / 100 * contentWidth;
      return leftWidth;
    }
    return this.state.leftWidth;
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  changeSize = (leftWidth) => {
    const contentWidth = this.contentRef.current.offsetWidth;
    this.setState({ contentWidth }, () => {
      this.changeWidth(leftWidth);
    });
  }

  resize() {
    const contentWidth = this.contentRef.current.offsetWidth;
    const { files } = this.state;
    this.setState({ contentWidth });
    if (this.plot2d && files && files.length) {
      this.plot2d.resetPlot();
      const { axis, setting } = this.state;
      Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
      Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
    }
    if (this.settingRef.current) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }
  }


  deleteFile = (e, fileName, id) => {
    e.stopPropagation();
    const { verificationId } = this.props;
    let { portSelect, files } = this.state;
    if (!fileName) {
      return;
    }
    deleteImportFile(verificationId, `${fileName}`).then(res => {
      if (portSelect[id]) {
        const [fileId, row, col] = strDelimited(portSelect[id][0], "::");
        const file = files.find(item => item.id === id);
        Parameters.removeCurve({ row, col, id: fileId, hashId: file ? file.hashId : null });
        delete portSelect[id];
        this.setState({
          portSelect
        });
      }
      Parameters.deleteParam(id).then(params => {
        let { files } = this.state;
        let newFiles = files.filter(item => item.id !== id);
        const filter = newFiles.filter(item => !!item);
        this.setState({
          files: [...filter.map(item => ({ ...item, show: true }))],
        })
      });
    })
  }

  UploadFile = (e) => {
    const { importDisabled } = this.state;
    if (importDisabled) {
      return;
    }
    e.stopPropagation();
    const el = this.uploadSnpRef.current;
    if (!el) return;
    el.click();
  }

  onUploadSnpFile = (e) => {
    const file = e.target.files[0];
    this.setState({
      importDisabled: true
    })
    const { verificationId } = this.props;
    this.props.uploadSnpFile(verificationId, file);
  }

  getImportFile = () => {
    const { verificationId, projectId, pdnId } = this.props;
    const { files } = this.state;
    getResultFiles(verificationId).then(res => {
      const { data } = res.data;
      if (data) {
        let importFiles = data.results ? data.results.filter(item => item.type === 'import') : [];
        const importFile = importFiles.find(item => files.findIndex(file => file.type === 'import' && file.name === item.fileName) === -1);
        if (!importFile) {
          return;
        }
        Parameters.getSingleInfo({ verificationId, projectId, pdnId, value: { ...importFile } }).then(params => {
          let parameter = { ...params, show: true };
          this.getSingleNpi([parameter], 'import').then(res => {
            const filter = res.filter(item => !!item);
            this.setState({
              files: [...filter.map(item => ({ ...item, show: true })), ...this.state.files],
              loading: false,
            })
          }, error => {
            this.setState({
              files: [...this.state.files],
              loading: false
            })
          })
        })
      }
    })
  }

  settingTitleClick = () => {
    const { settingShow } = this.state;
    this.setState({
      settingShow: !settingShow
    });
  }

  historyTitleClick = () => {
    const { historyShow, historyNameList } = this.state;
    if (!historyNameList.length) {
      this.getResultHistoryList();
    }
    this.setState({
      historyShow: !historyShow
    });
  }

  historyItemTitleClick = (e, name, time) => {
    e.stopPropagation();
    const { historyNameList } = this.state;
    let list = [...historyNameList];
    const index = list.findIndex(item => item.time === time && item.name === name);
    list[index].show = !list[index].show;
    this.setState({
      historyNameList: [...list]
    })
  }

  showHistoryJson = (e) => {
    e.stopPropagation();
    const { historyNameList } = this.state;
    if (!historyNameList.length) {
      this.getResultHistoryList(true);
    } else {
      this.setState({
        historyJson: true
      });
    }
  }

  closeModal = () => {
    this.setState({
      historyJson: false
    })
  }

  resultSaveClick = (e) => {
    e.stopPropagation();
    const { resultName, historyNameList } = this.state;
    const { verificationId, currentProjectId } = this.props;
    const names = historyNameList.map(item => item.name);
    const errorWords = resultName.match(/[^0-9a-zA-Z_-]+/g);
    if (errorWords && errorWords.length > 0) {
      this.setState({
        saveHistoryError: 'Names may only contain the following characters: numbers, letters, underscores, minus.'
      });
      return;
    }
    if (names.includes(resultName)) {
      this.setState({
        saveHistoryError: 'Name already exists!'
      });
      return;
    }
    savePdnResult(verificationId, resultName).then(response => {
      this.getResultHistoryList();
      this.props.updateProject(currentProjectId);
    }, error => {
      message.error('Save result failed! ' + error);
    })
    this.setState({
      saveInputVisible: false,
      saveHistoryError: ''
    });
  }

  getResultHistoryList = (openHistoryPanel) => {
    const { verificationId, pdnId, projectId } = this.props;
    const { historyNameList, showHistoryJson } = this.state;
    const _openHistoryJson = openHistoryPanel ? openHistoryPanel : showHistoryJson;
    let times = historyNameList.map(item => item.time);
    Parameters.getHistoryList(verificationId, projectId, pdnId, historyNameList).then(({ params, historyList }) => {
      const parametersArr = Array.from(params.values());
      if (parametersArr.length > 0 && historyList.length > 0) {
        let files = Array.from(params.values()).filter(item => item.time && item.time !== 'current' && !times.includes(item.time));
        files = files.map(item => ({ ...item, show: true }));
        this.getHistoryNpi(files).then(res => {
          const filter = Array.isArray(res) ? res.filter(item => !!item) : [];
          let newFiles = [...this.state.files, ...filter.map(item => ({ ...item, show: true }))]
          let _files = [];

          if (Array.isArray(newFiles)) {
            newFiles.forEach(item => {
              let index = _files.findIndex(i => i.id === item.id);
              if (index === -1) {
                _files.push(item);
              }
            })
          }

          this.setState({
            files: _files,
            historyNameList: [...historyList],
            loading: false,
            showHistoryJson: _openHistoryJson
          })
        }, error => {
          let newFiles = Array.isArray(files) ? [...this.state.files, ...files] : [...this.state.files];
          let _files = [];
          if (Array.isArray(newFiles)) {
            newFiles.forEach(item => {
              let index = _files.findIndex(i => i.id === item.id);
              if (index === -1) {
                _files.push(item);
              }
            })
          }
          this.setState({
            files: _files,
            historyNameList: [...historyList],
            loading: false,
            showHistoryJson: _openHistoryJson
          })
        })
      } else {
        this.setState({
          files: [...this.state.files],
          historyNameList: [],
          loading: false,
          showHistoryJson: _openHistoryJson
        });
      }
    })
  }

  inputNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      resultName: e.target.value,
      saveHistoryError: ''
    })
  }

  cancelSaveResult = (e) => {
    e.stopPropagation();
    this.setState({
      saveInputVisible: false,
      saveHistoryError: null
    })
  }

  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({
        saveHistoryError: null
      })
    }
    this.setState({ saveInputVisible: visible });
  };

  deleteHistory = (e, history) => {
    e.stopPropagation();
    const { historyNameList, files } = this.state;
    let { portSelect } = this.state;
    const { verificationId } = this.props;
    if (history && history.time) {
      delResultHistory(verificationId, history.time).then(res => {
        const index = historyNameList.findIndex(item => item.time === history.time);
        historyNameList.splice(index, 1);
        const delFiles = files.filter(item => item.time === history.time);

        if (delFiles.length > 0) {
          delFiles.forEach(file => {
            if (portSelect[file.id]) {
              const [fileId, row, col] = strDelimited(portSelect[file.id][0], "::");
              Parameters.removeCurve({ row, col, id: fileId, hashId: file.hashId });
              delete portSelect[file.id];
              this.setState({
                portSelect: portSelect
              });
            }
            Parameters.deleteHistoryParam(file.id).then(params => {
              //let files = Array.from(params.values()).map(item => ({ ...item, show: true }));
              let { files } = this.state;
              const _filter = files.filter(item => !!item);
              const newFiles = _filter.filter(item => item.time !== history.time)
              this.setState({
                files: [...newFiles.map(item => ({ ...item, show: true }))],
              })
            });
          })
        }
      }, error => {
        message.error('Delete history failed! ' + error)
      })
    }
  }

  getZipBlob = (url, fileName) => {
    return new Promise(resolve => {
      let that = this;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);

      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
            clearInterval(that.Time);
            that.setState({
              downloadProgress: 100
            })
            setTimeout(() => {
              that.setState({
                downloadProgress: -1,
                downloadVisible: false
              })
            }, 1000);
            message.success(`Download ${fileName} successfully!`);
          } else {
            clearInterval(that.Time);
            that.setState({
              downloadProgress: -1,
              downloadVisible: false
            })
            message.error(`Download ${fileName} failed!`);
          }
        }

      };
      xhr.send();
    });
  }

  downloadHistory = (e, history) => {
    e.stopPropagation();
    const { verificationId } = this.props;
    const token = localStorage.getItem('token');
    const url = `/api/v3/result/history/folder?verificationId=${verificationId}&dataTime=${history.time}&access_token=${token}`;
    const fileName = `${history.name}.zip`;
    this.getZipBlob(url, fileName).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, fileName);
      }
    });
  }

  printResult = (e) => {
    e.preventDefault();
    const options = {
      backgroundColor: '#ffffff',
    }
    saveSvg.saveSvgAsPng(document.querySelector(".plot"), "result.png", options);
  }

  showOptimization = (e, time) => {
    this.setState({
      showOptimizationPanel: true,
      optTime: time
    });
  }

  closeOptModal = () => {
    this.setState({
      showOptimizationPanel: false
    })
  }

  portNameClick = (file, portIndex) => {
    let { files } = this.state;
    const fileIndex = files.findIndex(item => item.id === file.id);
    files[fileIndex].ports[portIndex].display = !files[fileIndex].ports[portIndex].display;
    this.setState({
      files: files
    })
  }

  clearCurveMark = () => {
    Parameters.clearCurveMark();
  }

  render() {
    const { files, settingAction, axis, setting, portSelect, leftWidth, rightWidth, loading, fileListBottom,
      XUnit, settingShow, historyShow, historyJson,
      historyNameList, saveInputVisible, importDisabled, showOptimizationPanel, optTime, resultName, saveHistoryError
    } = this.state;
    const { existResult, progress, verificationId, viewList } = this.props;
    let importProgress = -1;
    let currentProgress = progress.find(item => item.verificationId === verificationId);
    if (currentProgress) {
      importProgress = currentProgress.progress;
    }
    return (
      <Fragment>
        <div ref={this.contentRef} className='pdn-result-main'>
          <Spin spinning={loading} size='large'>
            <div className='pdn-result-parameter-right' style={{ width: leftWidth }}>
              <div className='photo-box' onClick={this.printResult}>
                <img src={photo} alt="" className='fastpi-photo' />
              </div>
              <div className='curve-table-box' onClick={this.clearCurveMark}>
                <DeleteFilled title='Clear Marks' className='fastpi-curve-table-icon' />
              </div>
              {<svg ref={this.svgRef}></svg>}
              {/* not support mask zoom */}
              {/* {ResultZoom({
                zoomIn: Parameters.zoomIn,
                zoomOut: Parameters.zoomOut,
                fitView: Parameters.fitView
              })} */}
            </div>
            <Line
              position={{ position: "absolute", left: this.getLeftWidth.bind(this)() }}
              // {rightWidth}
              changeWidth={(leftWidth) => this.changeWidth(leftWidth)}
              width={this.getLeftWidth.bind(this)()}
              resize={this.changeSize}
            />
            <div className='pdn-result-parameter-left' style={{ width: rightWidth }}>
              <div className='pdn-result-parameter-file-list' style={{ bottom: fileListBottom, position: settingShow ? 'absolute' : 'static', overflowY: 'auto' }}>
                {/* Target */}
                <Target
                  targetActions={
                    {
                      fileNameClick: this.fileNameClick,
                      changePort: this.changePort,
                      colorChange: this.colorChange,
                      deleteFile: this.deleteFile
                    }
                  }
                  targetData={
                    {
                      files,
                      portSelect,
                      verificationId
                    }
                  }
                />
                {/* Result */}
                <Result
                  resultActions={
                    {
                      fileNameClick: this.fileNameClick,
                      changePort: this.changePort,
                      colorChange: this.colorChange,
                      deleteFile: this.deleteFile,
                      handleVisibleChange: this.handleVisibleChange,
                      showOptimization: this.showOptimization,
                      portNameClick: this.portNameClick
                    }
                  }
                  resultData={
                    {
                      files,
                      portSelect,
                      verificationId,
                      saveInputVisible,
                      existResult
                    }
                  }
                  contentData={
                    {
                      resultName,
                      saveHistoryError,
                      cancelSaveResult: this.cancelSaveResult,
                      inputNameChange: this.inputNameChange,
                      resultSaveClick: this.resultSaveClick
                    }
                  }
                />
                {/* Import  */}
                <Import
                  importActions={
                    {
                      UploadFile: this.UploadFile,
                      fileNameClick: this.fileNameClick,
                      changePort: this.changePort,
                      colorChange: this.colorChange,
                      deleteFile: this.deleteFile,
                      portNameClick: this.portNameClick
                    }
                  }
                  importData={
                    {
                      importDisabled,
                      importProgress,
                      files,
                      portSelect,
                      verificationId
                    }
                  }
                />
                {/* History */}
                {
                  <History
                    historyActions={
                      {
                        historyTitleClick: this.historyTitleClick,
                        changePort: this.changePort,
                        fileNameClick: this.fileNameClick,
                        colorChange: this.colorChange,
                        historyItemTitleClick: this.historyItemTitleClick,
                        showHistoryJson: this.showHistoryJson,
                        deleteHistory: this.deleteHistory,
                        downloadHistory: this.downloadHistory,
                        showOptimization: this.showOptimization
                      }
                    }
                    historyData={
                      {
                        historyShow,
                        historyNameList,
                        portSelect,
                        files,
                        viewList,
                        existResult,
                        verificationId
                      }
                    }
                    portNameClick={this.portNameClick}
                  />
                }
              </div>
              {
                files.length > 0 ?
                  <div ref={this.settingRef} className='pdn-result-setting' style={settingShow ? { maxHeight: '50%' } : { position: 'static' }}>
                    <Row className='parameter-right-toolbar'>
                      <Col>
                        <SparameterSetting
                          settingAction={settingAction}
                          actions={this.settingActions}
                          axis={axis}
                          setting={setting}
                          XSettingUnit={XUnit}
                          settingShow={settingShow}
                          settingTitleClick={this.settingTitleClick}
                          portSelect={portSelect}
                        />
                      </Col>
                    </Row>
                  </div>
                  : null}
            </div>
          </Spin>
          <input
            type='file'
            ref={this.uploadSnpRef}
            style={{ display: 'none' }}
            accept='.s1p'
            onChange={(e) => this.onUploadSnpFile(e)}
          />
        </div>
        {
          historyJson ? <HistoryPanel
            actions={{
              historyNameList,
              historyData,
              files,
              closeModal: this.closeModal,
              viewStuffedDecap: this.viewStuffedDecap,
              viewUnStuffedDecap: this.viewUnStuffedDecap,
            }}
          /> : null
        }
        {
          showOptimizationPanel && <OptimizationPanel
            closeModal={this.closeOptModal}
            viewStuffedDecap={this.viewStuffedDecap}
            viewUnStuffedDecap={this.viewUnStuffedDecap}
            historyData={historyData}
            optTime={optTime}
          />
        }
      </Fragment>
    );
  }

  viewStuffedDecap = (e, components) => {
    let stuffedComps = [];
    if (components && components.length) {
      components.forEach(item => {
        stuffedComps = [...stuffedComps, ...item.selectComps];
      });
    }

    stuffedComps = [...new Set(stuffedComps)];
    const { selectedDesignIDs, currentProjectId } = this.props;
    let pcbId = projectDesigns.getAvailableDesignsFirstId(currentProjectId)
    if (selectedDesignIDs.includes(pcbId)) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer(stuffedComps);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [...stuffedComps] }, pcbId);
    }
  }

  viewUnStuffedDecap = (e, components) => {
    let unStuffedComps = [];
    if (components && components.length) {
      components.forEach(item => {
        unStuffedComps = [...unStuffedComps, ...item.unusedComps];
      });
    }
    unStuffedComps = [...new Set(unStuffedComps)];
    const { selectedDesignIDs, currentProjectId } = this.props;
    let pcbId = projectDesigns.getAvailableDesignsFirstId(currentProjectId)
    if (selectedDesignIDs.includes(pcbId)) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer(unStuffedComps);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [...unStuffedComps] }, pcbId);
    }
  }

  fileNameClick = (id) => {
    const { files } = this.state;
    let index = files.findIndex(item => item.id === id);
    const parameter = files[index];
    if (parameter.ports && parameter.matrix) {
      this.setState((prevState) => {
        prevState.files[index].show = !prevState.files[index].show;
        return {
          files: prevState.files
        }
      });
      return;
    }
    this.getNpiFile(parameter, index);
  }

  getNpiFile = (parameter, index) => {
    return new Promise((resolve, reject) => {
      parameter.getNpiFile(index).then(res => {
        this.setState((prevState) => {
          prevState.files[index] = { ...prevState.files[index], ...res, show: !prevState.files[index].show };
          return {
            files: prevState.files
          }
        }, () => {
          resolve(this.state.files);
        })
      })
    })
  }

  changePort = (values, id, type, resultType) => {
    let unSelect, newSelect = null;
    const { setting, files } = this.state;
    let { portSelect } = this.state;
    let pr = portSelect[id] || [];
    unSelect = pr.concat(values).filter(v => !values.includes(v));
    newSelect = values.filter(v => !pr.includes(v));
    let parameter = Parameters.getParameter(id);
    const curveType = parameter.type; // simulation || target || import || history 
    // display optimization targetZ
    let optTargetId = null, optTargetHashId = null, optTargetParam = null, optTargetIndex = -1;
    let targetFileId = null, targetFile = null, targetParam = null, targetChange = false, targetIndex = -1;
    if (curveType === 'target') {
      optTargetIndex = files.findIndex(d => d.name === parameter.name && d.type === 'optTarget');
      targetChange = true;
      if (optTargetIndex > -1) {
        optTargetId = files[optTargetIndex].id;
        optTargetHashId = files[optTargetIndex].hashId;
        optTargetParam = Parameters.getParameter(optTargetId);
      }
    } else if (curveType === 'simulation' && resultType === RESULTS) {
      targetFileId = id.replace('simulation', 'target').replace('SIM', 'TargetZ');
      targetFile = files.find(item => item.id === targetFileId);
      if (!targetFile) {
        targetFileId = null
      } else {
        targetChange = ((portSelect[id] && portSelect[targetFileId]) || (!portSelect[id] && !portSelect[targetFileId])) ? true : false;
        targetIndex = files.findIndex(item => item.id === targetFileId);
        targetParam = Parameters.getParameter(targetFileId);
        if (targetParam) {
          optTargetIndex = files.findIndex(d => d.name === targetParam.name && d.type === 'optTarget');
          if (optTargetIndex > -1) {
            optTargetId = files[optTargetIndex].id;
            optTargetHashId = files[optTargetIndex].hashId;
            optTargetParam = Parameters.getParameter(optTargetId);
          }
        }
      }
    }
    if (unSelect.length > 0) {
      const [fileId, row, col] = strDelimited(unSelect[0], "::");
      const file = files.find(item => item.id === id);
      Parameters.removeCurve({ row, col, id: fileId, hashId: file ? file.hashId : null });
      if (optTargetId && targetChange) {
        Parameters.removeCurve({ row, col, id: optTargetId, hashId: optTargetHashId });
      }
      if (targetFileId && targetChange) {
        Parameters.removeCurve({ row, col, id: targetFileId, hashId: targetFile ? targetFile.hashId : null });
        portSelect[targetFileId] = portSelect[targetFileId] ? portSelect[targetFileId].filter(item => item !== `${targetFileId}::${row}::${col}`) : [];
        if (!portSelect[targetFileId] || !portSelect[targetFileId].length) {
          delete portSelect[targetFileId];
        }
      }
    } else {
      const [fileId, row, col] = strDelimited(newSelect[0], "::");
      let fileIndex = files.findIndex(item => item.id === fileId);
      let fileType = files[fileIndex].fileType, time = files[fileIndex].time;
      if (!files[fileIndex].matrix || !files[fileIndex].matrix.length) {
        return;
      }
      const color = files[fileIndex].matrix[row][col].color;
      Parameters.showCurve({ parameter, fileIndex, row, col, param: setting.parameter, value: setting.value, curveType, fileType, time }).then(curves => {
        files[fileIndex].matrix[row][col][curves.type] = [...curves.y];
        curves.visible = true;
        this.plot2d.updateCurves(curves, true);
        this.plot2d.resetPlot();
        this.plot2d.resetColor({ id, row, col, color: color });
        const { axis, setting } = this.state;
        Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      });
      if (optTargetId && targetChange) {
        Parameters.showCurve({
          parameter: optTargetParam,
          fileIndex: optTargetIndex,
          row, col, param: setting.parameter, value: setting.value,
          curveType: 'optTarget', fileType, time
        }).then(curves => {
          files[optTargetIndex].matrix[row][col][curves.type] = [...curves.y];
          curves.visible = true;
          this.plot2d.updateCurves(curves, true);
          this.plot2d.resetPlot();
          const _color = targetIndex > -1 ? files[targetIndex].matrix[row][col].color : color;
          this.plot2d.resetColor({ id: optTargetId, row, col, color: _color });
          const { axis, setting } = this.state;
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        });
      }

      if (targetFileId && targetChange) {
        Parameters.showCurve({
          parameter: targetParam,
          fileIndex: targetIndex,
          row, col, param: setting.parameter, value: setting.value,
          curveType: 'target', fileType, time
        }).then(curves => {
          files[targetIndex].matrix[row][col][curves.type] = [...curves.y];
          curves.visible = true;
          this.plot2d.updateCurves(curves, true);
          this.plot2d.resetPlot();
          const color = files[targetIndex].matrix[row][col].color;
          this.plot2d.resetColor({ id: targetFileId, row, col, color: color });
          const { axis, setting } = this.state;
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        });
        const targetValue = `${targetFileId}::${row}::${col}`;
        portSelect[targetFileId] = portSelect[targetFileId] ? [...portSelect[targetFileId], targetValue] : [targetValue];
      }
    };
    portSelect[id] = [...values];

    if (unSelect && unSelect.length > 0 && values.length === 0) {
      delete portSelect[id];
    }
    this.setState({
      portSelect: portSelect
    })
  }

  colorChange = (e, { id, portIndex, matrixIndex }) => {
    const { files } = this.state;
    let fileIndex = files.findIndex(item => item.id === id)
    const value = e.toHexString()
    //find optimization targetZ
    let parameter = Parameters.getParameter(id);
    let optTargetId = null;
    if (parameter.type === 'target') { // simulation || target || import || history 
      const optTargetIndex = files.findIndex(d => d.name === parameter.name && d.type === 'optTarget');
      if (optTargetIndex > -1) {
        optTargetId = files[optTargetIndex].id;
      }
    }

    if (value) {
      let { files } = this.state;
      files[fileIndex].matrix[portIndex][matrixIndex].color = value;
      this.setState({
        files
      }, () => {
        this.plot2d.resetColor({ id, row: portIndex, col: matrixIndex, color: value })
        //if curve is target, reset optimization target curve color
        optTargetId && this.plot2d.resetColor({ id: optTargetId, row: portIndex, col: matrixIndex, color: value });
        this.plot2d.redrawCurves();
      })
    }
  }

  showSetting = () => {
    this.setState((prevState) => ({
      settingAction: prevState.settingAction ? '' : 'setting-box-show'
    }))
  }

  setTargetColor = (files) => {
    const simulations = files.filter(item => item.type === "simulation");
    const simColors = simulations.map(item => ({ name: item.name.replace('SIM', 'TargetZ'), matrix: item.matrix }));
    const _files = files;
    _files.forEach(item => {
      const find = simColors.find(sim => item.name === sim.name);
      if (find) {
        item.matrix = item.matrix.map((m, index) => {
          return m.map((n, _index) => {
            return { ...n, color: find.matrix[index][_index] ? find.matrix[index][_index].color : find.matrix[0][0].color }
          })
        })
      }
    })
    return _files
  }

  showAxisSetting = (type) => {
    this.setState((prevState) => {
      prevState.axis[type] = !prevState.axis[type];
      return {
        axis: prevState.axis
      }
    }, () => {
      if (this.settingRef.current) {
        this.setState({
          fileListBottom: this.settingRef.current.offsetHeight
        })
      }
    })
  }

  settingChange = (type, val) => {
    let { files, axis, setting } = this.state;
    setting[type] = val;
    const { value, parameter } = setting;
    let { yScale } = axis;
    if (value === 'am' && parameter === 'S') {
      yScale = 'db';
    } else if (value === 'ph') {
      yScale = 'degrees';
    } else if (value === 're' || value === 'im') {
      yScale = 'linear';
    } else {
      yScale = 'log';
    }
    axis.yScale = yScale;
    this.setState({
      setting,
      axis
    }, () => {
      Parameters.changeParameter({ parameters: files, setting, yScale: axis.yScale, xScale: axis.xScale });
    })
  }

  /**
   * axis -  x | y
   */

  axisChange = (axis, value) => {
    this.setState((prevState) => {
      prevState.axis[axis + 'Scale'] = value;
      return {
        axis: prevState.axis
      }
    }, () => {
      const { setting } = this.state;
      Parameters.changeAxis(axis, { param: setting.parameter, value: setting.value, scale: value })
    })
  }

  showCurveSetting = () => {
    this.setState((prevState) => ({
      curveSettingAct: prevState.curveSettingAct ? '' : 'setting-box-show'
    }))
  }

  changeRange = (type, value) => {
    if (parseFloat(value).toString() === 'NaN') return;
    this.setState((prevState) => {
      prevState.axis[type] = value;
      return {
        axis: prevState.axis
      }
    })
  }

  updateRange = (type, value) => {
    const { axis, XUnit, prevAxis } = this.state;
    let _axis = { ...axis };

    // if scale = log, value can not be 0
    if ((((type === 'xMin' || type === 'xMax') && axis.xScale === 'log')
      || ((type === 'yMin' || type === 'yMax') && axis.yScale === 'log'))
      && (Number(value) <= 0 || isNaN(Number(value)))) {
      this.setState({
        axis: prevAxis
      })
      return;
    }


    _axis[type] = value;
    if (type === 'xMax' || type === 'xMin') {
      let min = NP.strip(parseFloat(_axis.xMin)), max = NP.strip(parseFloat(_axis.xMax));
      if (XUnit === 'KHz') {
        max = NP.times(max, 1e3);
        min = NP.times(min, 1e3);
      } else if (XUnit === 'MHz') {
        max = NP.times(max, 1e6);
        min = NP.times(min, 1e6);
      } else if (XUnit === 'GHz') {
        max = NP.times(max, 1e9);
        min = NP.times(min, 1e9);
      }
      Parameters.updateRange('x', { start: min, end: max })
    } else if (type === 'yMax' || type === 'yMin') {
      Parameters.updateRange('y', { start: axis['yMin'], end: axis['yMax'], yScale: axis['yScale'] })
    }
  }

  savePrevRange = () => {
    const { axis } = this.state;
    this.setState({
      prevAxis: JSON.parse(JSON.stringify(axis))
    })
  }

  axisRangeKeyDown = (e, type) => {
    if (e.keyCode === 13) {
      /*  this.changeRange(type, e.target.value); */
      this.updateRange(type)
      e.target.blur();
    }
  }

  XUnitChange = (unit) => {
    const { axis, XUnit } = this.state;
    let _axis = { ...axis }
    const scale = scaleConversion(unit, XUnit);
    const xMin = _axis.xMin * scale;
    _axis.xMin = parseFloat(xMin.toPrecision(12));
    const xMax = _axis.xMax * scale;
    _axis.xMax = parseFloat(xMax.toPrecision(12));
    this.setState({
      XUnit: unit,
      axis: { ..._axis }
    });
  }

  settingActions = {
    showSetting: this.showSetting,
    showAxisSetting: this.showAxisSetting,
    settingChange: this.settingChange,
    axisChange: this.axisChange,
    changeRange: this.changeRange,
    axisRangeKeyDown: this.axisRangeKeyDown,
    XUnitChange: this.XUnitChange,
    updateRange: this.updateRange,
    savePrevRange: this.savePrevRange
  }
}

const mapState = (state) => {
  const {
    pdn: { VRMModel, VRMOpt },
    resultReducer: { progress, importStatus },
    project: { currentProjectPDNs, currentProjectId, viewList, PDNID, treeSelectedKeys },
    simulationReducer: { existResult }
  } = state.PDNReducer;
  return {
    progress,
    importStatus,
    currentProjectPDNs,
    currentProjectId,
    viewList,
    VRMOpt,
    VRMModel,
    PDNID,
    existResult,
    selectedDesignIDs: getSelectedDesignIDs(treeSelectedKeys),
  }
}

const mapDispatch = (dispatch) => ({
  uploadSnpFile(verificationId, file) {
    dispatch(uploadSnpFile(verificationId, file))
  },
  changeFileStatus(status) {
    dispatch(changeFileStatus(status));
  },
  updateProject(id) {
    dispatch(updateProject(id))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  _getVRMModelUpdate(currentProjectId, PDNID) {
    dispatch(getVRMModelUpdate({ currentProjectId, PDNID }))
  }
})

export default connect(mapState, mapDispatch)(Sparameter);