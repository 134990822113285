import { CascadeProjectSaga } from './project';
import { CascadeDCRSaga } from './DCR';
import CascadeDesignUploadSaga from '../CascadeSider/uploadPCB/store/saga';
import { CascadeLibrarySaga } from './library'
import DCRSimulationSaga from './simulation/DCR/saga'
import { CascadeSimulationSaga } from './simulation'
import { CascadeIRSage } from './IRExplorer';
import IRSimulationSaga from './simulation/IR/saga';
import ImpSimulationSaga from './simulation/Impedance/saga';
import { CascadeResultSaga } from './result';
import IRResultSaga from './result/IR/saga';
import { CascadeImpedanceSaga } from './Impedance';
import ImpedanceResultSaga from './result/Impedance/saga';
import { PowerTreeSaga } from './PowerTree';
import { SignOffTemplateSaga } from './SignOffTemplate';
import PowerTreeSimulationSaga from './simulation/PowerTree/saga';
import { DesignTreeSaga } from './DesignTree';
import { CascadePreLayoutSaga } from './prelayout';

export default [
  CascadeProjectSaga,
  CascadeDCRSaga,
  CascadeDesignUploadSaga,
  CascadeLibrarySaga,
  DCRSimulationSaga,
  CascadeSimulationSaga,
  CascadeIRSage,
  IRSimulationSaga,
  IRResultSaga,
  CascadeResultSaga,
  CascadeImpedanceSaga,
  ImpSimulationSaga,
  ImpedanceResultSaga,
  PowerTreeSaga,
  SignOffTemplateSaga,
  PowerTreeSimulationSaga,
  DesignTreeSaga,
  CascadePreLayoutSaga
]