import http from '../http';

/**
 * Do simulation by verification id(andes)
 * 2020.02.28
 *
 * @export
 * @param {string} verificationId verification id
 * @returns XMLHttpRequest 
 */
export function doSimulation(verificationIds) {
  return http.post(`/project/verification/simulation/Andes`, verificationIds);
}

/**
 * Get  simulation log andes 
 *  2020.03.23
 * @param {string} verificationId
 */
export function getAndesSimulationLog(verificationId) {
  return http.get(`/project/verification/${verificationId}/andes/download/log`);
}


/**
 * Get andes result file
 * 2020.03.11
 * 
 * @param {*} projectId project id
 * @param {*} verificationId verification id
 * @param {String} path andes_mm.npi
 *
 * @export
 * @returns XMLHttpRequest
 */
export function getResultFileInAndes({ projectId, verificationId, path }) {
  return http.get(`/project/${projectId}/verification/${verificationId}/andes/singleFile?path=${path}`)
}

/**
 * Get andes result curves
 * 2020.03.11
 *
 * @export
 * @param {*} verificationId verification id
 * @param {String} path andes_mm.sam
 * @param {Array} cols
 * @param {Array} rows
 * @returns
 */
export function getResultsCurveInAndes({ verificationId, cols, rows, path }) {
  return http.post(`/project/verification/${verificationId}/andes/data`, {
    cols,
    path,
    rows
  });
};

/**
 * upload debug file andes
 * 2020.03/23
 *
 * @export
 * @param {String} verificationId verificationId
 * @param {file} file debug file
 * @returns XMLHttpRequest
 */
export function uploadAndesDebugFile({ file, verificationId, step, UploadProgress }) {
  let formData = new FormData();
  formData.append('file', file);
  return http.post(`/project/verification/${verificationId}/Andes/upload/${step}`, formData, UploadProgress, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * debug  simulation andes
 * 2020.02/23
 *
 * @export
 * @param {string} step  /1(setup)/2(extraction)/3(pproc)
 * @param {string} verificationId verificationId 
 * @returns XMLHttpRequest
 */
export function debugAndesVerify({ step, verificationId }) {
  return http.post(`/project/verification/${verificationId}/andes/verify/${step}`);
};

/**
 * andes project copy
 * 2020/03/26
 *
 * @export
 * @param {String} projectId Project id
 * @returns XMLHttpRequest
 */
export function andesCopyProject(projectId) {
  return http.post(`/my-project/copy/project/${projectId}/Andes`);
}

/* Get  snp file andes 
* 2020/03/27
* @param {string} verificationId
* @param {String} designId
*/
export function getSnpFileInAndes(verificationId, designId) {
  return http.get(`/verification/${verificationId}/design/${designId}/andes/snpFile`);
}

/* Get  result hitory list in andes 
* 2020/04/02
* @param {string} verificationId
*/
export function getResultHistoryList(verificationId) {
  return http.get(`/result/history/list/Andes?verificationId=${verificationId}`);
}

/**
 * save andes current result
 *
 * @param verificationId
 * @param resultName
 * @export
 * @returns Promise
 */
export function saveCurrentAndesResult({ verificationId, resultName }) {
  return http.post(`/result/history?name=${resultName}&verificationId=${verificationId}`)
}

/**
 * delete result history
 *
 * @param verificationId
 * @param dataTime history time
 * @export
 * @returns Promise
 */
export function deleteResultHistory({ verificationId, dataTime }) {
  return http.delete(`/result/history/folder?verificationId=${verificationId}&dataTime=${dataTime}`)
}