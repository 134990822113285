import React, { Component, Fragment, createRef } from 'react';
import { FileOutlined, FolderOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { newNanoId } from '../../services/helper/idHelper';
import './index.css';

class TreeSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      close: [],
      search: '',
      open: false,
      treeKey: newNanoId(8, 'number')
    };
    this.selectRef = createRef()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { id } = this.props;
    const { treeKey } = this.state;
    const treeClass = document.getElementsByClassName(`tree-selection-class-${treeKey}`);
    if (!document.getElementById(`tree-selection-${treeKey}`).contains(target) &&
      treeClass && treeClass[0] && !treeClass[0].contains(target) &&
      (!document.getElementById(id || `tree-selection-dropdown-${treeKey}`) ||
        (document.getElementById(id || `tree-selection-dropdown-${treeKey}`) && !document.getElementById(id || `tree-selection-dropdown-${treeKey}`).contains(target)))) {
      this.closeOpen()
    }
  }

  blurSelect = () => {
    if (this.selectRef && this.selectRef.current) {
      this.selectRef.current.blur && this.selectRef.current.blur();
    }
    this.setState({
      open: false
    })
  }

  closeOpen = () => {
    this.setState({
      open: false
    })
  }

  selectOpen = () => {
    this.setState({
      open: true
    })
  }

  UpdateKeys = (e, name) => {
    e.stopPropagation();
    e.preventDefault();
    let newClose = [...this.state.close];
    newClose = newClose.includes(name) ? newClose.filter(item => item !== name) : [...newClose, name];
    this.setState({
      close: newClose
    })
  }

  findOptGroup = (fileList, index) => {
    const { name, children, id } = fileList;
    const { close, search } = this.state;
    return children && children.length > 0 ?
      <div style={{ paddingLeft: 8 * index }} key={id}>
        <div>
          <div className="tree-select-folder" onMouseDown={(e) => this.UpdateKeys(e, name)}>
            {close.includes(name) ? <FolderOutlined className="tree-select-folder-icon" /> : <FolderOpenOutlined className="tree-select-folder-icon" />}
            {name}
          </div>
          {!close.includes(name) && children.map(child => {
            return child.type === 'folder' ? this.findOptGroup(child, index + 1) : this.findFileGroup(child, search, index + 1);
          })}
        </div>
      </div> : null;
  }

  findRootGroup = (fileList) => {
    const { search } = this.state;
    return Array.isArray(fileList) && fileList.map(file => {
      return file.type === 'folder' ? this.findOptGroup(file, 1) : this.findFileGroup(file, search, 1);
    })
  }

  findFileGroup = (file, search, index) => {
    const { showFileFolder, fileIndex, selected, value, selectedkey } = this.props;
    const { libraryFormat, libraryStructure, id, children, name, key } = file;
    const label = value && value.label ? value.label : value;
    if (libraryStructure && libraryStructure.name.match(search)) {
      if (showFileFolder && libraryFormat === 'folder') {
        return children && <Fragment key={id}>
          <div
            className="tree-select-file-folder"
            key={id}
            style={{ paddingLeft: 8 * index }}
          >
            <span className="tree-select-folder-icon iconfont icon-folder-2 tree-select-file-folder-icon"></span>
            {libraryStructure.name}
          </div>
          <ul className="tree-select-file-ul" style={{ paddingLeft: 8 * index }}>
            {children.map(child => {
              return <li
                key={`${id}-${child.fileName}`}
                onClick={(e) => { e.stopPropagation(); this.props.onSelectItem({ ...file, childFile: child.fileName }, fileIndex); this.blurSelect(); }}
                style={selected === id && label === `${libraryStructure.name}::${child.fileName}` ? { background: '#eff5ff' } : {}}
              >
                {child.fileName}
              </li>
            })}
          </ul>
        </Fragment>
      } else if (libraryFormat === 'file' || libraryFormat === 'data') {
        return (
          <div
            className={selected === id ? "tree-select-file tree-selected-file" : "tree-select-file"}
            key={id}
            onClick={(e) => { e.stopPropagation(); this.props.onSelectItem(file, fileIndex); this.blurSelect(); }}
            style={{ paddingLeft: 8 * index }}
          >
            <FileOutlined className="tree-select-folder-icon" />
            {libraryStructure.name || name}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return children ? <Fragment key={id}>
        <div
          className="tree-select-file-folder"
          key={id}
          style={{ paddingLeft: 8 * index }}
        >
          <span className="tree-select-folder-icon iconfont icon-folder-2 tree-select-file-folder-icon"></span>
          {name}
        </div>
        <ul className="tree-select-file-ul" style={{ paddingLeft: 8 * index }}>
          {children.map(child => {
            return <li
              key={`${id}-${child.fileName}`}
              onClick={(e) => { e.stopPropagation(); this.props.onSelectItem({ ...file, childFile: child.fileName }); this.blurSelect(); }}
              style={selected === id && label === child.fileName ? { background: '#e0ebff' } : {}}
            >
              {child.fileName}
            </li>
          })}
        </ul>
      </Fragment>
        : <div
          className={(selectedkey !== "key" && (selected === `${id}::${name}` || selected === `${id}`)) || (selectedkey === "key" && selected === key) ? "tree-select-file tree-selected-file" : "tree-select-file tree-select-file-background"}
          key={`${id}::${name}`}
          onClick={(e) => { e.stopPropagation(); this.props.onSelectItem(file, fileIndex); this.blurSelect(); }}
          style={{ paddingLeft: 8 * index }}
        >
          <FileOutlined className="tree-select-folder-icon" />
          {name}
        </div>;
    }
  }

  searchValue = (value) => {
    this.setState({
      search: value
    })
  }

  treeDropdownRender = () => {
    const { dropdownRender, fileList, id } = this.props;
    const { treeKey } = this.state;
    let _fileList = fileList && fileList.length ? [...fileList] : null
    if (dropdownRender) {
      _fileList = dropdownRender()
    }
    return <div className='tree-select-dropdown' id={id || `tree-selection-dropdown-${treeKey}`}>{this.findRootGroup(_fileList)}</div>
  }

  render() {
    const { fileList, selectRef, id, onSelectItem, showFileFolder, className, ...otherProps } = this.props;
    const { open, treeKey } = this.state;
    return <Select
      {...otherProps}
      className={`${className} tree-selection-class-${treeKey}`}
      open={open}
      id={`tree-selection-${treeKey}`}
      ref={selectRef || this.selectRef}
      dropdownRender={this.treeDropdownRender}
      showSearch={true}
      onSearch={(value) => this.searchValue(value)}
      onClick={this.selectOpen}
    >
    </Select>
  }
}
export default TreeSelect;