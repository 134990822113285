import { combineReducers } from 'redux';
import { RockyProjectReducer } from './project';
import { default as explorerReducer } from '../../LayoutExplorer/store/Rocky/reducer';
import { default as resultReducer } from './result/reducer';
import RockyUploadReducer from '../RockySider/UploadPCB/reducer';
import { RockyReducer } from './rocky';
import { simulationReducer } from './simulation';
import { RockyPDNReducer } from './rockyPdn';
import { RockyPreLayoutReducer } from './prelayout';
import { RockyCardReducer } from './card';
import { RockySSNReducer } from './rockySSN'
import { RockyMultipleReducer } from './multiple/reducer';

const rootReducer = combineReducers({

  /* stackup: stackupReducer, */
  simulationReducer,
  explorer: explorerReducer,
  project: RockyProjectReducer,
  result: resultReducer,
  RockyUploadReducer,
  rocky: RockyReducer,
  RockyPDN: RockyPDNReducer,
  preLayout: RockyPreLayoutReducer,
  card: RockyCardReducer,
  rockySSN: RockySSNReducer,
  rockyMultiple: RockyMultipleReducer
});

export default rootReducer;