import { create, all } from 'mathjs'

const config = { number: 'BigNumber' }
const math = create(all, config);
/**
 * channel re_extraction
 *
 * @export
 * @param {string} word '100 mil to mil'
 * @param {string | number} num
 * @param {string} oldUnit
 * @param {string} newUnit
 * @param {number} decimals
 * @returns {value: '100 mil', number: 100, unit: 'mil'}
 */
function unitChange({ word = null, num, oldUnit = 'mil', newUnit = 'mil', decimals = -1 }) {
  let value = null, number = '', unit = null;

  oldUnit = unitFormat(oldUnit);
  newUnit = unitFormat(newUnit);

  try {
    if (word) {
      value = math.evaluate(word).toString();
    } else {
      if (num == null || isNaN(num)) return {};
      value = math.evaluate(`${Number(num)} ${oldUnit} to ${newUnit}`).toString();
    }
  } catch (error) {
    console.error(`Unit Change Error: ${oldUnit} --> ${newUnit}`)
    return { value, number: num, unit }
  }
  if (value) {
    let numList = value.split(' ');
    number = decimals > -1 ? Number(Number(numList[0]).toFixed(decimals)) : Number(numList[0]);
    if (decimals > 0 && Number.isInteger(number)) {
      number = Number(number).toFixed(0);
    }
    unit = numList[1];
  }
  value = `${number} ${unit}`;
  return { value, number, unit };
}

function unitFormat(unit) {
  let _unit = unit;
  if (_unit.includes('Ω') || _unit.includes('Ω')) {
    _unit = _unit.replace('Ω', 'ohm');
    _unit = _unit.replace('Ω', 'ohm');
  }
  switch (_unit) {
    case 'KHz':
      return 'kHz';
    case 'Kohm':
      return 'kohm';
    default:
      return _unit;
  }
}

export {
  unitChange
}