import React, { Component, Fragment, createRef } from 'react';
import {
  CameraOutlined,
  CheckOutlined,
  CloseOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined
} from '@ant-design/icons';
import { Input, message } from 'antd';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import DetailPanel from './detailPanel';

class TreeTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameEdit: false,
      tempName: props.name,
      open: false
    }
    this.inputRef = createRef();
  }

  changeName = (e, edit) => {
    e && e.stopPropagation();
    this.setState({
      nameEdit: edit
    }, () => {
      edit && this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    })
  }

  saveTempName = (e) => {
    this.setState({
      tempName: e.target.value
    })
  }

  saveNewName = (e) => {
    e && e.stopPropagation();
    const { tempName } = this.state;
    const reg = new RegExp(/[^ 0-9a-zA-Z_-]+/g)
    const error = checkNameFormat(tempName, { reg });
    if (error) {
      message.error(error, 5);
    } else {
      this.setState({
        tempName: tempName,
        nameEdit: false,
      })
      this.props.changeTreeName(tempName)
    }
  }

  openDetails = (e) => {
    e && e.stopPropagation();
    this.setState({
      open: true
    })
  }

  closeDetails = () => {
    this.setState({
      open: false
    })
  }

  changeWhatIf = (e, newWhatIf) => {
    e && e.stopPropagation();
    this.props.changeWhatIf(newWhatIf)
  }

  render() {
    const { name, maxWidth = '50%', showCanvas, couldDel = true, logs = [], whatIf, spiceSimulation } = this.props;
    const { nameEdit, open } = this.state;
    const ShowCanvasIcon = showCanvas ? ShrinkOutlined : ArrowsAltOutlined
    return (
      <Fragment>
        {!nameEdit ? <Fragment>
          <span className='font-bold power-tree-name-span' style={{ maxWidth }}>
            <span onClick={(e) => this.changeName(e, true)}>{name}</span>
            <ShowCanvasIcon
              className="name-icon shrink-tree-icon"
              title="Collapse Current Power Tree"
              onClick={this.props.updateShowCanvas}
            />
            <FileTextOutlined
              className='name-icon detail-tree-icon'
              title={"Details"}
              onClick={(e) => this.openDetails(e)} />
            <CameraOutlined
              className='name-icon camera-tree-icon'
              title={"Screenshot"}
              onClick={this.props.screenShot} />
            <ExperimentOutlined
              className={`name-icon experiment-tree-icon ${spiceSimulation ? '' : 'experiment-tree-not-allowed-icon'}`}
              style={whatIf ? { color: '#1890ff' } : {}}
              title={spiceSimulation ? "Offline IR Drop Calculation" : "Please re-run the simulation before using Offline IR Drop Calculation."}
              onClick={(e) => spiceSimulation ? this.changeWhatIf(e, !whatIf) : null} />
            {couldDel && <CloseOutlined
              className="name-icon delete-tree-icon"
              title="Delete Current Power Tree"
              onClick={this.props.deletePowerTree} />}
          </span>
        </Fragment> : <Fragment>
          <Input
            style={{ width: '50%' }}
            placeholder="Target Name"
            defaultValue={name}
            onChange={(e) => this.saveTempName(e)}
            onPressEnter={(e) => this.saveNewName(e)}
            ref={this.inputRef}
          />
          <CheckOutlined className='cascade-power-tree-icon' onClick={(e) => this.saveNewName(e)} />
          <CloseOutlined
            className='cascade-power-tree-icon'
            onClick={(e) => this.changeName(e, false)} />
        </Fragment>}
        {
          open && <DetailPanel logs={logs} name={name} closeDetails={this.closeDetails} />
        }
      </Fragment >
    );
  }
}

export default TreeTitle;