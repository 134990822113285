import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Select, Switch, Tabs, Tooltip } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { valueUnitSplit } from "@/services/helper/valueUnitSplit";
import { numberCheck } from "@/services/helper/dataProcess";
import UnitAddonAfter from "@/components/UnitAddonAfter";
import { CYLINDER, NONE, SPHEROID } from '../../../services/helper/ballSize';
import MaterialPanel from '@/components/Material';
import { METAL } from '../../../services/Stackup';
import { MATERIAL } from '../../../constants/libraryConstants';
import LayoutData from '@/services/data/LayoutData';
import { COPPER, NEW_MATERIAL, SOLDER } from '../../../services/Stackup/Material';
import { unitChange } from '@/services/helper/mathHelper';

const units = ["mm", "um", "mil"];
const Option = Select.Option;
const TabPane = Tabs.TabPane
class ballSizePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 400,
      maxHeight: 300,
      ballSizeUnit: 'um',
      ballHeightUnit: 'um',
      ballMiddleUnit: 'um',
      ballSize: '300',
      ballHeight: '300',
      ballMiddle: '300',
      ballShape: CYLINDER,
      compTabs: [],
      ballMaterialVisible: false,
      stackupData: {}
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 400),
      maxHeight: getPanelMaxHeight(offset, 300),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.initStackupData()
    this.getValue()
  }

  initStackupData = async () => {
    const { pcbId } = this.props
    const stackup = await LayoutData.getStackupJson({ pcbId, reload: true });
    this.setStackupData(stackup.stackup)
  }

  setStackupData = (stackupData) => {
    this.setState({ stackupData })
  }

  closeModal = () => {
    const { ballSize, ballSizeUnit, ballHeight, ballHeightUnit, ballShape, ballMiddle, ballMiddleUnit, ballMaterial, notGroupPositivePin, compTab } = this.state;
    this.props.closePanel({ ballShape, ballSize: `${ballSize}${ballSizeUnit}`, ballHeight: `${ballHeight}${ballHeightUnit}`, ballMiddle: `${ballMiddle}${ballMiddleUnit}`, ballMaterial, notGroupPositivePin, compTab }, true);
  }

  addonAfter = (unit, key) => {
    return UnitAddonAfter({
      unit,
      changeUnit: (e) => this.changeUnit(e, key),
      list: units,
    })
  }

  getValue = () => {
    const { data = {}, comps } = this.props;
    const tab = comps[0]
    const { ballSize: _size = '300um', ballHeight: _height = '300um', ballShape = NONE, ballMiddle: _middle = '300um', ballMaterial = SOLDER, notGroupPositivePin = false } = data[tab] || {}
    const { value: ballSize, unit: ballSizeUnit } = valueUnitSplit(_size);
    const { value: ballHeight, unit: ballHeightUnit } = valueUnitSplit(_height);
    const { value: ballMiddle, unit: ballMiddleUnit } = valueUnitSplit(_middle);
    this.setState({
      compTab: comps[0],
      ballShape,
      ballSize,
      ballSizeUnit,
      ballHeight,
      ballHeightUnit,
      ballMiddle,
      ballMiddleUnit,
      ballMaterial,
      notGroupPositivePin
    })
  }

  changeValue = (e, type) => {
    const value = e.target.value;
    this.setState({
      [type]: value
    })
  }

  checkValue = (type) => {
    const value = this.state[type];
    if (numberCheck(value)) {
      const { data = {} } = this.props;
      let propsValue = data[type];
      propsValue = propsValue || '300um';
      const { value } = valueUnitSplit(propsValue);
      this.setState({
        [type]: value
      })
    }
  }

  changeUnit = (unit, key) => {
    const newNum = unitChange({
      num: this.state[key],
      oldUnit: this.state[`${key}Unit`],
      newUnit: unit,
      decimals: 3
    }).number;

    this.setState({
      [key]: newNum,
      [`${key}Unit`]: unit
    })
  }

  changeShape = (ballShape) => {
    this.setState({
      ballShape
    })
  }

  changeTab = (tab) => {
    const { data } = this.props
    const { ballSize, ballSizeUnit, ballHeight, ballHeightUnit, ballShape, ballMiddle, ballMiddleUnit, ballMaterial, notGroupPositivePin, compTab } = this.state;
    this.props.closePanel({ ballShape, ballSize: `${ballSize}${ballSizeUnit}`, ballHeight: `${ballHeight}${ballHeightUnit}`, ballMiddle: `${ballMiddle}${ballMiddleUnit}`, ballMaterial, notGroupPositivePin, compTab }, false);

    const { ballSize: _size = '300um', ballHeight: _height = '300um', ballShape: _ballShape = NONE, ballMiddle: _middle = '300um', ballMaterial: _ballMaterial = SOLDER, notGroupPositivePin: _notGroupPositivePin = false } = data[tab] || {}
    const { value: _ballSize, unit: _ballSizeUnit } = valueUnitSplit(_size);
    const { value: _ballHeight, unit: _ballHeightUnit } = valueUnitSplit(_height);
    const { value: _ballMiddle, unit: _ballMiddleUnit } = valueUnitSplit(_middle);
    this.setState({
      compTab: tab,
      ballShape: _ballShape,
      ballSize: _ballSize,
      ballSizeUnit: _ballSizeUnit,
      ballHeight: _ballHeight,
      ballHeightUnit: _ballHeightUnit,
      ballMiddle: _ballMiddle,
      ballMiddleUnit: _ballMiddleUnit,
      ballMaterial: _ballMaterial,
      notGroupPositivePin: _notGroupPositivePin
    })
  }

  ballMaterialInput = (ball_material, ballMaterialVisible) => {
    const { stackupData } = this.state;
    const materialList = stackupData.materials || []
    const _materialList = [...materialList];
    for (const name of [COPPER, SOLDER]) {
      if (!materialList.find(item => item.name === name)) {
        _materialList.push(name === COPPER ? { name, sigma: "58000000", miu_r: '0.999991', type: METAL } : { name, sigma: "7000000", miu_r: '1', type: METAL });
      }
    }
    const isExist = _materialList.findIndex(item => item.name === ball_material) > -1 ? true : false;
    return (
      <Fragment>
        <Input
          className='cascade-ball-size-input'
          size='small'
          value={ball_material}
          onClick={() => this.setState({ ballMaterialVisible: true })}
          disabled={ballMaterialVisible}
          style={{ color: isExist ? '' : 'red' }}
        />
        {!isExist ? <Tooltip
          title={<Fragment>
            {`${ball_material} has been deleted, the material is invalid.`}
          </Fragment>}
          overlayClassName='aurora-tooltip'
        >
          <QuestionCircleOutlined
            className='cascade-ball-size-question-circle-icon'
            onClick={(e) => { e && e.stopPropagation() }} />
        </Tooltip> : null}
      </Fragment>
    );
  }

  ballMaterialPanel = (ball_material, compName, product) => {
    const { stackupData } = this.state;
    const record = {
      type: METAL,
      selectMaterialName: ball_material,
      material: ball_material,
      name: ball_material,
      dataIndex: MATERIAL
    }

    return <MaterialPanel
      record={record}
      dataIndex={MATERIAL}
      materialList={stackupData.materials || []}
      closePanel={(material) => this.saveBallMaterial(material, compName)}
      pageType={product}
      editType="ball_material"
    />
  }

  saveBallMaterial = (materialValue) => {
    this.saveStackup({ ...materialValue });
    this.setState({
      ballMaterialVisible: false,
      ballMaterial: materialValue.newMaterial.name
    });
  }

  saveStackup = ({ newMaterial, selectMaterialName }) => {
    const { stackupData } = this.state
    const { pcbId } = this.props;
    // materials
    let _materialList = [...stackupData.materials]
    if (selectMaterialName === NEW_MATERIAL) {
      _materialList.push({ ...newMaterial })
    } else if (!_materialList.find(item => item.name === selectMaterialName)) {
      _materialList.push({ ...newMaterial })
    } else {
      const _index = _materialList.findIndex(item => item.name === selectMaterialName);
      _materialList[_index] = { ...newMaterial };
    }

    // layers
    const _layers = stackupData.layers.map((item) => {
      if (item.material === selectMaterialName) {
        return {
          ...item,
          material: newMaterial.name
        };
      }
      return item;
    })

    const _stackupData = {
      layers: _layers,
      materials: _materialList,
      unit: stackupData.unit,
      version: stackupData.version
    }

    const stackups = LayoutData.saveStackupJson(pcbId, {
      data: _stackupData.layers,
      materialList: _stackupData.materials,
      unit: _stackupData.unit
    }, true);

    this.setStackupData(_stackupData)
    this.props.saveStackupToServer(stackups, pcbId);
  }

  changeSwitch = (checked) => {
    this.setState({
      notGroupPositivePin: checked
    })
  }

  render() {
    const { maxWidth, maxHeight, ballShape, ballSize, ballSizeUnit, ballHeight, ballHeightUnit, ballMiddle, ballMiddleUnit, ballMaterial, notGroupPositivePin, compTab, ballMaterialVisible } = this.state;
    const { title, comps, product } = this.props;
    const content = (
      <Panel
        className='cascade-power-select-panel'
        title={<div className='cascade-power-select-title'>{title}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 400 })}
        maxHeight={maxHeight}
        position='panel-center'
        draggable
        minHeight={150}
        minWidth={200}
        defaultTop={200}
      >
        <Tabs activeKey={compTab} type="card" onChange={this.changeTab} className='port-setup-tabs-content' items={comps.map(item => ({ key: item, label: item }))} />
        <div className='cascade-ball-size-content'>
          <div className='cascade-ball-size-line'>
            <span className='cascade-ball-size-span'>Ball Shape</span>
            <Select
              className='cascade-ball-size-input'
              size='small'
              value={ballShape}
              onChange={this.changeShape}
              dropdownStyle={{ zIndex: 100000 }}
            >
              {[NONE, CYLINDER, SPHEROID].map(item => <Option key={item}>{item}</Option>)}
            </Select>
          </div>
          {
            ballShape !== NONE && <Fragment>
              <div className='cascade-ball-size-line'>
                <span className='cascade-ball-size-span'>Ball Diameter</span>
                <Input
                  className='cascade-ball-size-input'
                  size='small'
                  value={ballSize}
                  onChange={(e) => this.changeValue(e, 'ballSize')}
                  onBlur={() => this.checkValue('ballSize')}
                  addonAfter={this.addonAfter(ballSizeUnit, 'ballSize')}
                />
              </div>
              <div className='cascade-ball-size-line'>
                <span className='cascade-ball-size-span'>Ball Height</span>
                <Input
                  className='cascade-ball-size-input'
                  size='small'
                  value={ballHeight}
                  onChange={(e) => this.changeValue(e, 'ballHeight')}
                  onBlur={() => this.checkValue('ballHeight')}
                  addonAfter={this.addonAfter(ballHeightUnit, 'ballHeight')}
                />
              </div>
              {ballShape === SPHEROID && <div className='cascade-ball-size-line'>
                <span className='cascade-ball-size-span'>Ball Middle</span>
                <Input
                  className='cascade-ball-size-input'
                  size='small'
                  value={ballMiddle}
                  onChange={(e) => this.changeValue(e, 'ballMiddle')}
                  onBlur={() => this.checkValue('ballMiddle')}
                  addonAfter={this.addonAfter(ballMiddleUnit, 'ballMiddle')}
                />
              </div>}
              <div className='cascade-ball-size-line'>
                <span className='cascade-ball-size-span'>Ball Material</span>
                {
                  <Fragment>
                    {this.ballMaterialInput(ballMaterial, ballMaterialVisible)}
                    {ballMaterialVisible ? this.ballMaterialPanel(ballMaterial, compTab, product) : null}
                  </Fragment>
                }
              </div>
            </Fragment>
          }
          {/* <div className='cascade-ball-size-line'>
            <span className='cascade-ball-size-not-group-span'>Do not group the positive pin</span>
            <Switch
              checked={notGroupPositivePin}
              size="small"
              className="aurora-switch-small"
              onChange={this.changeSwitch}
            />
          </div> */}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}


export default ballSizePanel;
