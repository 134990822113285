import React, { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import CPMCanvas from './canvas';
import EditableTable from '../../EditableTable';
import CSMTable from './CSMTable';
import { autoReadOrWriteSignalMapping, getPcbSignals, getUpdateSignalMapping } from '../../../services/LibraryHelper/SpiceModelHelper/csmCpmParseHelper';
import "./index.css";

const columns = [{
  title: "CSM Power/Ground Bump Node",
  dataIndex: "node",
  width: "25%"
},
{
  title: "CSM Net",
  dataIndex: "csmNet",
  width: "25%"
},
{
  title: "Package Die Bump",
  dataIndex: "pin",
  width: "25%"

}, {
  title: "Package Net",
  dataIndex: "net",
  width: "25%"
}]
class CPMConnection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 1650,
      maxHeight: 1200,
      pinMapping: [],
      showPinNodeObj: {},
      isShow: false,
      triMatchPairs: [],
      transforms: null,
      signalMapping: props.csmPairs || [],
      portList: [],
      nodeList: []
    }
    this.canvasRef = createRef();
    this.dialogRoot = document.getElementById('root');

    columns[1].render = (text, record) => {
      return <span onClick={() => { this.showPinAndNode(record, "pin") }}>{text}</span>
    }

    columns[0].render = (text, record) => {
      return <span onClick={() => { this.showPinAndNode(record, "node") }}>{text}</span>
    }

    columns[0].title = () => {
      const { modelType } = this.props
      return <div>
        <span>{`${modelType} Power/Ground Bump Node`}</span>
      </div>
    }

    columns[1].title = () => {
      const { modelType } = this.props
      return <div>
        <span>{`${modelType} Net`}</span>
      </div>
    }
  }

  showPinAndNode = (record, type) => {
    let pins = [], nodes = [];
    pins.push(record.pin);
    nodes.push(record.node);
    this.setState({
      showPinNodeObj: {
        pins,
        nodes
      },
      isShow: true
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 1200),
      maxWidth: getPanelMaxWidth(offset, 1650)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.initData();
  }

  componentDidUpdate = (prevProps) => {
    const { isUpdateSsnPackageChannel } = this.props;
    if (isUpdateSsnPackageChannel && isUpdateSsnPackageChannel !== prevProps.isUpdateSsnPackageChannel) {
      const isFileChange = this.getIsFileChange();
      let signalMapping = this.getDefaultSignalMapping(isFileChange)
      this.setState({
        signalMapping
      })
    }
  }

  getDefaultSignalMapping = (isDefault) => {
    const { SignalNets = [], content, pcbChannelInfo, pcb, pinsInfo } = this.props;
    const { signalMapping } = this.state;
    const pcbSignals = getPcbSignals(content, pcbChannelInfo, pcb);
    if (SignalNets.length) {
      let _signalMapping = [];
      for (let signal of SignalNets || []) {
        const pcbSignalInfo = pcbSignals.find(item => item.name === signal.name)
        const findPin = pinsInfo.find(item => item.signal === signal.name && signal.nets.includes(item.net))
        let findSignal = {};
        if (!isDefault) {
          findSignal = signalMapping.find(item => item.pkgSignal === signal.name) || {};
        }
        _signalMapping.push({
          pkgSignal: signal.name,
          nets: [...(signal.nets || [])],
          pcbNets: pcbSignalInfo && pcbSignalInfo.nets ? pcbSignalInfo.nets : [],
          csmSignal: findSignal.csmSignal || "",
          pad: findSignal.pad || "",
          in: findSignal.in || "",
          readPad: findSignal.readPad || "",
          pkgPin: findPin && findPin.pin ? findPin.pin : "",
          csmInstanceSignal: findSignal.csmInstanceSignal || ""
        })
      }
      return _signalMapping;
    }
    return signalMapping
  }

  initData = async () => {
    const { cpmPairs, triPairs, transforms } = this.props;
    const isFileChange = this.getIsFileChange();
    let signalMapping = this.getDefaultSignalMapping(isFileChange)
    this.setState({
      pinMapping: isFileChange ? [] : cpmPairs || [],
      triMatchPairs: isFileChange ? [] : triPairs || [],
      transforms: isFileChange ? [] : transforms || null,
      signalMapping: signalMapping || [],
    })
  }

  updateSignalMapping = () => {
    const { signalMapping, portList } = this.state;
    const { signalMapping: newSignalMapping, isUpdate } = getUpdateSignalMapping(signalMapping, portList)
    if (isUpdate) {
      this.setState({
        signalMapping: newSignalMapping
      })
    }
  }

  getWriteInfo = () => {
    const { writeSelectFile, readSelectFile, isUpdateFile } = this.props;
    const { signalMapping, portList } = this.state;
    if (!isUpdateFile) {
      this.updateSignalMapping()
      return
    }

    if (writeSelectFile && writeSelectFile.libraryId && writeSelectFile.subckt
      && readSelectFile && readSelectFile.libraryId && readSelectFile.subckt) {
      const { newSignalMapping, isUpdate } = autoReadOrWriteSignalMapping(signalMapping, portList)
      if (isUpdate) {
        this.setState({
          signalMapping: newSignalMapping
        })
      }
    } else {
      this.updateSignalMapping()
    }
  }

  getIsFileChange = () => {
    const { canvasFile, selectFile } = this.props;
    if (canvasFile && selectFile && canvasFile.libraryId === selectFile.libraryId && canvasFile.fileName === selectFile.fileName) {
      return false;
    }
    return true;
  }

  closePanel = () => {
    const { pinMapping, triMatchPairs, transforms, signalMapping } = this.state;
    let _signalMapping = JSON.parse(JSON.stringify(signalMapping))
    _signalMapping = _signalMapping.map(item => {
      delete item.pkgPin
      return item
    });
    this.props.saveCPMPinMapping({ cpmPairs: pinMapping, triPairs: triMatchPairs, transforms, canvasFile: { ...this.props.selectFile }, csmPairs: _signalMapping })
    this.canvasRef.current && this.canvasRef.current.removeCanvas()
    this.props.closePanel()
  }

  updatePinMapping = (pinMapping, triMatchPairs, transforms, signalMapping) => {
    let _signalMapping = signalMapping ? signalMapping : this.getDefaultSignalMapping(true)
    this.setState({
      pinMapping,
      triMatchPairs,
      transforms,
      signalMapping: _signalMapping
    })
  }

  clearMapping = () => {
    this.setState({
      pinMapping: [],
      clearMappingStatus: true,
      isShow: true,
      showPinNodeObj: {},
      triMatchPairs: [],
      signalMapping: []
    })
  }

  updateClearMappingStatus = (status) => {
    this.setState({
      clearMappingStatus: status
    })
  }

  updateShowStatus = (status) => {
    this.setState({
      isShow: status
    })
  }

  changeSizeMax = (max) => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    if (max) {
      this.setState({
        maxWidth: width - 10,
        maxHeight: height - 10,
        isMax: true
      })
    } else {
      this.resize()
      this.setState({
        isMax: false
      })
    }
  }

  getDataProcess = (pinMapping, nodeList = []) => {
    const GNDRule = /VSS/ig;
    let dataProcess = pinMapping.map(item => {
      if (!item.csmNet && item.node) {
        const findInfo = nodeList.find(it => it.node === item.node);
        return { ...item, csmNet: findInfo && findInfo.net ? findInfo.net : "" }
      }
      return { ...item }
    })
    dataProcess = dataProcess.sort((a, b) => {
      const aMatch = a.net.match(GNDRule);
      const bMatch = b.net.match(GNDRule);

      if (aMatch && !bMatch) {
        return 1;
      } else if (!aMatch && bMatch) {
        return -1
      } else {
        return 0
      }
    })
    return dataProcess
  }

  editSignalMapping = (signalMapping) => {
    this.setState({
      signalMapping
    })
  }

  updateShowPinNodeObj = ({ showPinNodeObj, isShow }) => {
    this.setState({
      showPinNodeObj,
      isShow
    })
  }

  updatePortList = (portList, nodeList) => {
    this.setState({
      portList,
      nodeList
    }, () => {
      this.getWriteInfo()
    })
  }

  render() {
    const { maxWidth, maxHeight, pinMapping, clearMappingStatus, showPinNodeObj, isShow, isMax, triMatchPairs, transforms, signalMapping, portList, nodeList } = this.state;
    const { designId, PowerNets, ReferenceNets, component, selectFile, SignalNets, getPortList, writeSelectFile, readSelectFile, isUpdateSsnPackageChannel, modelType, product, DesignInfo, autoMatch } = this.props;
    const svgMaxHeight = pinMapping && pinMapping.length ? (maxHeight - 75) * 0.5 : maxHeight - 75;
    const dataProcess = this.getDataProcess(pinMapping, nodeList)
    const content = (
      <Panel
        className='cpm-connection-edit-panel'
        title={<div className='cpm-connection-edit-title'>{`${modelType} Connection`}</div>}
        onCancel={this.closePanel}
        zIndex={2000}
        width={isMax ? maxWidth : getPanelWidth(maxWidth, { defaultWidth: 1650 })}
        position={isMax ? "panel-top-left" : 'panel-center'}
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        maximizeVisible={true}
        overflow={"auto"}
        changeSizeMax={this.changeSizeMax}
      >
        <div className='cpm-connection-edit-main' style={{ height: isMax ? maxHeight - 75 : null, maxHeight: maxHeight - 75 }}>
          <div className='cpm-connection-canvas-content' style={{ height: pinMapping && pinMapping.length ? "60%" : "100%", maxHeight: svgMaxHeight }}>
            <CPMCanvas
              ref={this.canvasRef}
              designId={designId}
              PowerNets={PowerNets}
              ReferenceNets={ReferenceNets}
              component={component}
              selectFile={selectFile}
              pinMapping={pinMapping}
              clearMappingStatus={clearMappingStatus}
              showPinNodeObj={showPinNodeObj}
              isShow={isShow}
              maxHeight={svgMaxHeight}
              triMatchPairs={triMatchPairs}
              transforms={transforms}
              updatePinMapping={this.updatePinMapping}
              updateClearMappingStatus={this.updateClearMappingStatus}
              updateShowStatus={this.updateShowStatus}
              SignalNets={SignalNets}
              getPortList={getPortList}
              readSelectFile={readSelectFile}
              writeSelectFile={writeSelectFile}
              signalMapping={signalMapping}
              portList={portList}
              updatePortList={this.updatePortList}
              isUpdateSsnPackageChannel={isUpdateSsnPackageChannel}
              modelType={modelType}
              product={product}
              getCSMCPMSpiceModelList={this.props.getCSMCPMSpiceModelList}
              DesignInfo={DesignInfo}
              autoMatch={autoMatch}
            />
          </div>
          {pinMapping && pinMapping.length ? <div className='cpm-connection-edit-table-content' style={{ maxHeight: (maxHeight - 75) * 0.25 }}>
            {/* <div className='cpm-connection-edit-table-title'>
              <span>Pin Mapping</span>
              <Icon type="delete" className="cpm-connection-edit-table-delete-icon" onClick={this.clearMapping} />
            </div> */}
            <EditableTable
              className="cpm-connection-edit-table"
              columns={columns}
              style={{ maxHeight: (maxHeight - 75) * 0.25 - 30 }}
              rowKey={(record) => record.pin}
              dataSource={dataProcess}
              scroll={dataProcess.length > 5 ? { y: (maxHeight - 75) * 0.25 - 95 } : {}}
              onRow={record => {
                return {
                  onClick: e => this.showPinAndNode(record, "row"),
                };
              }}
            />
          </div>
            : null}
          {pinMapping && pinMapping.length && signalMapping && signalMapping.length ?
            <div className='cpm-connection-edit-table-content' style={{ maxHeight: (maxHeight - 75) * 0.25 }}>
              <CSMTable
                signalMapping={signalMapping}
                maxHeight={(maxHeight - 75) * 0.25 + 50}
                portList={portList}
                editSignalMapping={this.editSignalMapping}
                updateShowPinNodeObj={this.updateShowPinNodeObj}
              /> </div>
            : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default CPMConnection;