import {
  CLOSE_FROM_MODAL,
  OPEN_CREATE_FOLDER_MODAL,
  CREATE_FOLDER,
  CREATE_FOLDER_FAILED,
} from './actionType';

export const cancelAction = () => ({
  type: CLOSE_FROM_MODAL
})

export const openCreateFolderModalAction = () => ({
  type: OPEN_CREATE_FOLDER_MODAL,
})

export const createFolderAction = (name, createType) => ({
  type: CREATE_FOLDER,
  name,
  createType
})

export const createFolderFailedAction = (msg) => ({
  type: CREATE_FOLDER_FAILED,
  msg,
})