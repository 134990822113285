import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import ResultLayout from '@/services/Result/Public/resultLayout';
import SparameterSetting from '@/services/Result/Public/sparameter/SparameterSetting';
import { getVerificationJson, getHistoryVerificationJson, getExperimentVerificationJson } from '@/services/Andes_v2/results'
import parameters from '@/services/Result/Public/sparameter/parameters';
import {
  axisFormat, portChange, displayCurve, getColor, resultListDefault, removeCurves,
  changeWidthCallBack, updateRangeCallBack, axisChangeCallBack,
  changeCurveColor, updatePlotColor, getMouseMoveYAxisValue, cancelMove,
  getSelectAllinSignalCurves, showCurves
} from '@/services/Result/Public/sparameter/dataHelper';
import AndesParameterData from '@/services/Andes_v2/results/sparameter/parameterData';
import { LineChartOutlined, SettingOutlined } from '@ant-design/icons';
import { Spin, Switch, Tabs, Tooltip } from 'antd';
import { ANDES_V2 } from '@/constants/pageType';
import DisplayMode from '@/components/Sparameter/displayMode';
import ResultReference from './ResultReference';
import { Mode } from '@/services/Andes_v2/results/sparameter/constants';
import { strDelimited } from '@/services/helper/split';
import ResultList, { RESULT, HISTORY } from '@/components/Sparameter/resultList';
import { getResultList, handleCPHYDefaultResultList } from '../../../../services/Result/Andes/dataHelper';
import { getEndToEndResultList, getSignalGroup } from '../../../../services/Andes_v2/results/sparameter/endToEnd';
import { rowDisplayChange } from '@/services/Result/Public/sparameter/resultsMenuHelper';
import ResultRightMenu from '@/services/Result/Public/resultRightMenu';
import makeCancelable from '@/services/api/makeCancelable';
import { isPreLayout } from '../../../../services/Andes_v2';
import { getPreLayoutInfoById } from "@/services/Andes_v2/preLayout";
import { updateReportInfo, saveReportConfig, getReportConfig } from '../../store/project/action';
import { getReportGuideValue, getReportGuideKeys } from '@/services/helper/reportHelper';
import { VERIFY_SUCCESS } from '../../../../constants/verificationStatus';
import { EXPERIMENTS } from '../../../../constants/treeConstants';
import { CPHY } from '../../../../services/PCBHelper/constants';
import { DriverOrReceiver, DriverPoints, ReceiverPoints } from '../../../../services/Andes_v2/constants';
import TxAndRxPortSetting from './TxAndRxPortSetting';
import './index.css';
import { saveReportConfigPromise } from '../../../../services/Andes_v2/channel';
import { getResultObject, initSignalPorts } from '../../../../services/Andes_v2/results/sparameter/dataHelper';

let sparameter = new parameters();

const defaultState = {
  portSelect: {}, // { id: { port: ["id:0:0"] } }
  loading: false,
  files: [],
  displayMode: 'IL',
  resultsObj: {}, // { Default: [] },
  portObject: {}, // { id: [{ comp, compType, display, fileId, impedance, index, name, portType, signal }] }
  historyObj: {}, // { id: { Default: [] } }
  modeActive: 'Diff Mode',
  displayReference: false,
  isShowReferenceLine: true,
  defaultReferenceLine: [],
  experimentObj: {},
  showPointType: [DriverPoints, ReceiverPoints],
  CPHYResultList: {},
  pairGroup: [],
  historyCPHYResultList: {},
  historyPairGroup: {}
}

const defaultGuideKey = getReportGuideKeys('andes_v2_reference_line');
const defaultCPHYGuideKey = getReportGuideKeys('andes_v2_CPHY_reference_line');

const signalPair = ['AB', 'BC', 'CA'];

class SparameterResult extends Component {
  constructor() {
    super()
    this.svgRef = createRef();
    this.state = {
      ...defaultState
    }
    this.plot2d = null;
    this.resize = this.resize.bind(this);
    this.historyContent = new Map();
    this.experimentContent = new Map();
  }

  onRef = (ref) => {
    this.sparameterSetting = ref;
  }

  resultRightMenuOnRef = (ref) => {
    this.resultRightMenu = ref;
  }

  getXUnit = () => {
    return this.sparameterSetting.getXUnit();
  }

  getCurrentSelects = () => {
    let { portSelect } = this.state;
    return Object.values(portSelect).reduce((a, b) => {
      return Object.values(a).concat(...Object.values(b));
    }, []);
  }

  changeMouse = (x) => {
    try {
      let { files, resultsObj, displayMode, historyObj, experimentObj } = this.state;
      const { interfaceType } = this.props;
      const axis = this.getAxis();
      const yScale = axis.yScale;
      const selectArr = this.getCurrentSelects();
      let _historyObj = { ...historyObj }, _experimentObj = { ...experimentObj };
      Object.keys(historyObj).forEach(id => {
        _historyObj[id] = getMouseMoveYAxisValue({ resultsObj: _historyObj[id], displayMode, selectArr, files, x, setting: this.getSetting(), yScale, type: interfaceType })
      })
      Object.keys(experimentObj).forEach(id => {
        _experimentObj[id] = getMouseMoveYAxisValue({ resultsObj: _experimentObj[id], displayMode, selectArr, files, x, setting: this.getSetting(), yScale })
      })
      this.setState({
        resultsObj: getMouseMoveYAxisValue({ resultsObj, displayMode, selectArr, files, x, setting: this.getSetting(), yScale, type: interfaceType }),
        historyObj: _historyObj,
        experimentObj: _experimentObj
      });
    } catch (error) {
      console.error(error)
    }
  }

  cancelMove = () => {
    let { resultsObj, displayMode, historyObj, experimentObj } = this.state;
    const selectArr = this.getCurrentSelects();
    let _historyObj = { ...historyObj }, _experimentObj = { ...experimentObj };
    Object.keys(historyObj).forEach(id => {
      _historyObj[id] = cancelMove({ resultsObj: _historyObj[id], displayMode, selectArr })
    })
    Object.keys(experimentObj).forEach(id => {
      _experimentObj[id] = cancelMove({ resultsObj: _experimentObj[id], displayMode, selectArr })
    })
    this.setState({
      resultsObj: cancelMove({ resultsObj, displayMode, selectArr }),
      historyObj: _historyObj,
      experimentObj: _experimentObj
    });
  }

  changeAxis = (axis) => {
    const xUnit = this.getXUnit();
    this.sparameterSetting.setAxis(axisFormat(xUnit, axis));
  }

  componentDidMount() {
    this.screenChange();
    if (this.props.id) {
      this.getResult();
      this.renderResultHistory();
      this.getResultConfig();
    }
  }

  componentDidUpdate(prevProps) {
    const { id, historyList, reportConfig, dataRate, reportConfigLoading, updateExperimentListStatus, interfaceType } = this.props;
    if (id !== prevProps.id) {
      this.getResult();
      this.renderResultHistory();
      this.getResultConfig();
      if (this.selectAllCancelAble) {
        this.selectAllCancelAble.cancel();
      }
    }

    if (prevProps.updateExperimentListStatus !== updateExperimentListStatus && updateExperimentListStatus !== 'first' && updateExperimentListStatus) {
      this.getResult();
      this.getResultConfig();
      this.props.changeUpdateExperimentListStatus(false);
    }

    const historyListIds = this.getHistoryIds();
    const preHistoryListsIds = prevProps.historyList.map(d => d.id);
    const historyListsNames = historyList.map(d => d.name);
    const preHistoryListsNames = prevProps.historyList.map(d => d.name);
    if (historyListIds.length !== preHistoryListsIds.length ||
      historyListIds.some(id => !preHistoryListsIds.includes(id))
    ) {
      this.renderResultHistory();
    }

    // Rename
    if (historyListIds.length === preHistoryListsIds.length &&
      historyListIds.every(id => preHistoryListsIds.includes(id)) &&
      historyListsNames.some(id => !preHistoryListsNames.includes(id))
    ) {
      this.refreshHistoryResultList();
    }

    if (dataRate !== prevProps.dataRate) {
      this.getResultConfig();
    }

    if (!reportConfigLoading && reportConfigLoading !== prevProps.reportConfigLoading) {
      const guideValue = reportConfig && reportConfig.custom && reportConfig.custom.guideValue ? reportConfig.custom.guideValue : [];
      const _guideValue = guideValue && guideValue.length > 0 ? guideValue.filter((value) => (interfaceType === CPHY ? defaultCPHYGuideKey : defaultGuideKey).includes(value.name)) : [];
      const referenceLineData = this.getDefaultGuideValue('andes_v2', dataRate, _guideValue);
      this.setState({ defaultReferenceLine: referenceLineData, isLoadingReference: true });
      this.crossReferenceLine(referenceLineData)
    }
  }

  refreshHistoryResultList = () => {
    const { historyList } = this.props;
    let { files } = this.state;
    historyList.forEach(his => {
      const index = files.findIndex(f => f.id === his.id);
      if (index > -1) {
        files[index].rowName = his.name;
      }
    });
    this.setState({
      files
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    this.initResult()
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize() {
    this.resultRightMenu.updateFileListState();
  }

  initResult = () => {
    this.interfaceContent = null;
    sparameter = new parameters();
    if (this.plot2d) {
      if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
        this.svgRef.current.removeChild(this.svgRef.current.children[0]);
      }
    }
    this.plot2d = null;
    this.setState({
      ...defaultState,
      resultsObj: {}
    })
  }

  getResult = () => {
    this.initResult();
    const { id, isEndToEnd, setupInfo, status, resultPageType } = this.props;
    if (!id) return;
    this.setState({
      loading: true,
      portSelect: {},
    }, async () => {
      try {
        // if not experiments or exist channel result
        if (resultPageType !== EXPERIMENTS || status === VERIFY_SUCCESS) {
          this.interfaceContent = setupInfo || await getVerificationJson(id, isEndToEnd);
          if (this.interfaceContent && !this.interfaceContent.prelayoutType && !isEndToEnd) {
            //There may not be data for preLayoutType in the setting
            //At this point, call the interface that retrieves the pre Layout data to obtain the type
            const preLayoutInfo = await getPreLayoutInfoById(this.interfaceContent.designId);
            if (preLayoutInfo && preLayoutInfo.content && preLayoutInfo.content.prelayout) {
              this.interfaceContent.prelayoutType = preLayoutInfo.content.prelayout;
            }
          }

          if (!this.interfaceContent) {
            this.setState({
              loading: false
            })
            return;
          }
          if (isEndToEnd) {
            const isPreLayoutList = this.interfaceContent.channels.map(item => { return isPreLayout(item.designId) });
            this.isPreLayout = !isPreLayoutList.includes(false);
          } else {
            this.isPreLayout = isPreLayout(this.interfaceContent.designId);
          }
        }
        try {
          await this.renderSimulationResult();
        } catch (error) {
          console.error(error)
        }
        this.setState({
          loading: false
        })
      } catch (error) {
        this.setState({
          loading: false
        })
      }
    })
  }

  getSetting = () => {
    return this.sparameterSetting.getSetting();
  }

  getAxis = () => {
    return this.sparameterSetting.getAxis();
  }

  renderSimulationResult = async () => {
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis,
    }
    const { displayMode, defaultReferenceLine } = this.state;
    const { id, isEndToEnd, experimentList, status, resultPageType, interfaceType } = this.props;
    const { parameter, value } = this.getSetting();
    this.plot2d = sparameter.createPlot(this.svgRef.current, { param: parameter, value, referenceLineData: defaultReferenceLine, displayMode }, events, ANDES_V2);
    if (resultPageType === EXPERIMENTS) {
      const params = experimentList.map(experiment => {
        sparameter.initParameter(experiment.id, new AndesParameterData({ verificationId: experiment.id, type: 'experiment' }))
        return sparameter.getParameter(experiment.id);
      })

      if (status === VERIFY_SUCCESS) {
        sparameter.initParameter(id, new AndesParameterData({ verificationId: id, type: 'channel' }));
        const file = sparameter.getParameter(id);
        params.unshift(file);
      }

      const res = (await this.getAllExperimentNpi(params)).filter(d => !!d);
      res.forEach((file, index) => {
        let rowName = 'Default';
        const experiment = experimentList.find(item => item.id === file.id)
        if (experiment) {
          rowName = experiment.name;
        }
        file.rowName = rowName;
        file.rowId = file.id;
        file.show = index === 0 ? true : false;
      })
      let _portObject = {};
      res.forEach(d => _portObject[d.id] = d.ports.map(_d => ({ ..._d, fileId: d.id })))

      this.setState((prevState) => {
        // const filterSimulation = prevState.files.filter(d => d.type === 'experiment');
        return {
          files: [...res],
          portObject: { ...prevState.portObject, ..._portObject }
        }
      }, () => {
        this.findExperimentResultList();
      })
      return;
    }
    let res = [], _portObject = {};
    if (interfaceType === CPHY) {
      let files = [], pairs = [], signals = [];
      const signalsData = isEndToEnd ? getSignalGroup(this.interfaceContent) : this.interfaceContent.content.signals
      for (const signal of (signalsData || [])) {
        for (const pair of signalPair) {
          sparameter.initParameter(`${id}::${signal.name}::${pair}`, new AndesParameterData({ id: `${id}::${signal.name}::${pair}`, verificationId: id, type: isEndToEnd ? 'endToEnd' : 'channel' }));
          const file = sparameter.getParameter(`${id}::${signal.name}::${pair}`);
          files.push(file);
          pairs.push(pair);
          signals.push(signal.name);
        }
      }
      const pairRes = await this.getAllNpi(files, id, pairs, signals);
      if (!pairRes || pairRes.length < 1) { return; }
      res.push(...pairRes);
      let portList = []
      res.forEach(d => {
        const ports = d && d.ports ? d.ports.map(_d => ({ ..._d, fileId: d.id })) : [];
        portList.push(...ports)
      })

      _portObject[id] = portList;
    } else {
      sparameter.initParameter(id, new AndesParameterData({ verificationId: id, type: isEndToEnd ? 'endToEnd' : 'channel' }));
      const file = sparameter.getParameter(id);
      res = await this.getAllNpi([file], id);
      if (!res || !res[0].ports) { return; }
      _portObject[res[0].id] = res[0].ports.map(d => ({ ...d, fileId: res[0].id }));
    }

    this.setState((prevState) => {
      return {
        files: [...prevState.files, ...res],
        portObject: { ...prevState.portObject, ..._portObject }
      }
    }, () => {
      this.getResultsByType(id)
    })
  }

  renderResultHistory = async () => {
    const { historyList, displayMode, isEndToEnd, interfaceType } = this.props;
    // historyList - [{ id, name, channelId }], id: historyId
    let params = [];
    for (const d of historyList) {
      const historyJson = await getHistoryVerificationJson(d.id, isEndToEnd);
      if (historyJson) {
        this.historyContent.set(d.id, historyJson);
        if (interfaceType === CPHY) {
          const signalsData = isEndToEnd ? getSignalGroup(historyJson) : historyJson.content.signals;
          for (const signal of (signalsData || [])) {
            for (const pair of signalPair) {
              sparameter.initParameter(`${d.id}::${signal.name}::${pair}`, new AndesParameterData({ id: `${d.id}::${signal.name}::${pair}`, verificationId: d.id, type: isEndToEnd ? 'endToEnd_history' : 'channel_history' }));
              const param = sparameter.getParameter(`${d.id}::${signal.name}::${pair}`);
              params.push(param);
            }
          }
        } else {
          sparameter.initParameter(d.id, new AndesParameterData({ verificationId: d.id, type: isEndToEnd ? 'endToEnd_history' : 'channel_history' }))
          const param = sparameter.getParameter(d.id);
          params.push(param);
        }
      }
    }

    const res = (await this.getAllHistoryNpi(params, isEndToEnd)).filter(d => !!d);
    res.forEach(
      file => {
        file.rowName = historyList.find(item => item.id === file.verificationId).name;
        file.rowId = file.verificationId;
        file.show = false;
      })
    let _portObject = {};
    res.forEach(d => {
      const [id] = d.id.split('::');
      const ports = d.ports.map(_d => ({ ..._d, fileId: d.id }))
      if (!_portObject[id]) {
        _portObject[id] = ports;
      } else {
        _portObject[id].push(...ports);
      }
    })

    this.setState((prevState) => {
      const filterSimulation = prevState.files.filter(d => d.type === 'channel' || d.type === 'endToEnd');
      return {
        files: [...filterSimulation, ...res],
        portObject: { ...prevState.portObject, ..._portObject }
      }
    }, () => {
      let _historyObj = this.state.historyObj;
      const ids = res.reduce((prev, cur) => {
        const [id] = cur.id.split('::');
        return prev.includes(id) ? prev : [...prev, id]
      }, []);
      ids.forEach(id => {
        if (displayMode === 'Default') {
          let general = resultListDefault({}, this.state.portObject[id], this.getColorByIndex, this.historyContent.get(id))
          general.Default.show = [];
          _historyObj[id][displayMode] = general.Default;
          this.setState({
            historyObj: _historyObj
          })
        } else {
          this.findHistoryResultList();
        }
      })
    })
  }

  getResultsByType = (id, switchMode) => {
    const { displayMode } = this.state;
    const { resultPageType } = this.props;
    if (displayMode === 'Default') {
      this.defaultResultList(id);
      switchMode && this.defaultHistoryList();
      resultPageType === EXPERIMENTS && this.defaultExperimentList();
    } else {
      this.updateResultList(id);
    }
  }

  defaultResultList = (id) => {
    let { resultsObj, portObject, CPHYResultList: oldCPHYResultList, pairGroup: oldPairGroup } = this.state;
    const { interfaceType } = this.props;
    if (!portObject[id]) return;
    const _resultList = resultListDefault(resultsObj, portObject[id], this.getColorByIndex, this.interfaceContent);
    let CPHYResultList = oldCPHYResultList, pairGroup = oldPairGroup;
    if (interfaceType === CPHY) {
      const newData = handleCPHYDefaultResultList(_resultList.Default.list, _resultList.Default.show, oldPairGroup);
      CPHYResultList = newData.CPHYResultList;
      pairGroup = newData.pairGroup;
    }
    this.setState({
      resultsObj: _resultList,
      CPHYResultList,
      pairGroup
    }, () => {
      this.defaultDisplay(_resultList);
    })
  }

  defaultHistoryList = () => {
    let { historyObj, portObject, displayMode, historyCPHYResultList, historyPairGroup } = this.state;
    const { interfaceType } = this.props;
    let _historyObj = { ...historyObj }, _historyCPHYResultList = { ...historyCPHYResultList }, _historyPairGroup = { ...historyPairGroup };
    const historyIds = this.getHistoryIds();
    historyIds.forEach(id => {
      let general = resultListDefault(historyObj[id], portObject[id], this.getColorByIndex, this.historyContent.get(id));
      general[displayMode].show = [];
      _historyObj[id] = general;
      if (interfaceType === CPHY) {
        const newData = handleCPHYDefaultResultList(historyObj[id].Default.list, historyObj[id].Default.show, _historyPairGroup[id]);
        _historyCPHYResultList[id] = newData.CPHYResultList;
        _historyPairGroup[id] = newData.pairGroup;
      }
    })
    this.setState({
      historyObj: _historyObj,
      historyCPHYResultList: _historyCPHYResultList,
      historyPairGroup: _historyPairGroup
    })
  }

  defaultExperimentList = () => {
    let { experimentObj, portObject, displayMode } = this.state;
    let _experimentObj = { ...experimentObj };
    const experimentIds = this.getExperimentIds();
    experimentIds.forEach(id => {
      let general = resultListDefault(experimentObj[id], portObject[id], this.getColorByIndex);
      general[displayMode].show = [];
      _experimentObj[id] = general;
    })
    this.setState({
      experimentObj: _experimentObj
    }, () => {
      this.defaultDisplay(_experimentObj[experimentIds[0]]);
    })
  }

  updateResultList(id) {
    const { experimentObj } = this.state;
    const { resultPageType } = this.props;
    const experimentIds = this.getExperimentIds();
    // { Default, FEXT, NEXT, IL, RL, DiffToComm }
    if (resultPageType === EXPERIMENTS) {
      this.defaultDisplay(experimentObj[experimentIds[0]]);
    } else {
      this.findResultList(id);
    }
  }

  findResultList = (id) => {
    const { portObject, files } = this.state;
    const { isEndToEnd, interfaceType } = this.props;
    let FEXT = [], NEXT = [], IL = [], RL = [], DiffToComm = [], COMMIL = [], COMMRL = [], CrossCoupling = [];
    if (!portObject[id]) return;
    let _list = null;
    const paramFiles = files.filter(item => item.id.search(id) > -1);
    if (isEndToEnd) {
      _list = getEndToEndResultList(this.interfaceContent, portObject[id], this.getColorByIndex, interfaceType, paramFiles);
    } else {
      _list = getResultList(this.interfaceContent.content, portObject[id], this.getColorByIndex, interfaceType, paramFiles);
    }
    FEXT = _list.FEXT;
    NEXT = _list.NEXT;
    IL = _list.IL;
    RL = _list.RL;
    DiffToComm = _list.DiffToComm;
    COMMIL = _list.COMMIL ? _list.COMMIL : [];
    COMMRL = _list.COMMRL ? _list.COMMRL : [];
    CrossCoupling = _list.CrossCoupling ? _list.CrossCoupling : [];
    let { resultsObj } = this.state;
    resultsObj.FEXT = { list: FEXT, show: FEXT.length ? [FEXT[0].rowName] : [] };
    resultsObj.NEXT = { list: NEXT, show: NEXT.length ? [NEXT[0].rowName] : [] };
    // Open all
    resultsObj.IL = { list: IL, show: IL.map(item => item.rowName) };
    resultsObj.RL = { list: RL, show: RL.map(item => item.rowName) };
    resultsObj.DiffToComm = { list: DiffToComm, show: DiffToComm.map(item => item.rowName) }

    // common model
    resultsObj.COMMIL = { list: COMMIL, show: COMMIL.map(item => item.rowName) };
    resultsObj.COMMRL = { list: COMMRL, show: COMMRL.map(item => item.rowName) };

    if (interfaceType === CPHY) {
      resultsObj.CrossCoupling = { list: CrossCoupling, show: CrossCoupling.map(item => item.rowName) }
    }
    this.setState({
      resultsObj,
    }, () => {
      this.defaultDisplay(resultsObj)
    });
  }

  findHistoryResultList = () => {
    let { historyObj, portObject, files } = this.state;
    const { isEndToEnd, interfaceType } = this.props;
    const historyIds = this.getHistoryIds();
    historyIds.forEach(id => {
      if (this.historyContent.get(id)) {
        let FEXT = [], NEXT = [], IL = [], RL = [], DiffToComm = [], _list = null, COMMIL = [], COMMRL = [], CrossCoupling = [];
        const paramFiles = files.filter(item => item.id.search(id) > -1);
        if (isEndToEnd) {
          _list = getEndToEndResultList(this.historyContent.get(id), portObject[id], this.getColorByIndex, interfaceType, paramFiles)
        } else {
          _list = getResultList(this.historyContent.get(id).content, portObject[id], this.getColorByIndex, interfaceType, paramFiles);
        }
        FEXT = _list.FEXT;
        NEXT = _list.NEXT;
        IL = _list.IL;
        RL = _list.RL;
        DiffToComm = _list.DiffToComm;
        COMMIL = _list.COMMIL ? _list.COMMIL : [];
        COMMRL = _list.COMMRL ? _list.COMMRL : [];
        CrossCoupling = _list.CrossCoupling ? _list.CrossCoupling : [];
        if (!historyObj[id]) {
          historyObj[id] = {};
        }
        historyObj[id].FEXT = { list: FEXT, show: FEXT.length ? [FEXT[0].rowName] : [] };
        historyObj[id].NEXT = { list: NEXT, show: NEXT.length ? [NEXT[0].rowName] : [] };
        // Open all
        historyObj[id].IL = { list: IL, show: IL.map(item => item.rowName) };
        historyObj[id].RL = { list: RL, show: RL.map(item => item.rowName) };
        historyObj[id].DiffToComm = { list: DiffToComm, show: DiffToComm.map(item => item.rowName) }

        // common model
        historyObj[id].COMMIL = { list: COMMIL, show: COMMIL.map(item => item.rowName) };
        historyObj[id].COMMRL = { list: COMMRL, show: COMMRL.map(item => item.rowName) };

        if (this.historyContent.get(id) && interfaceType === CPHY) {
          historyObj[id].CrossCoupling = { list: CrossCoupling, show: CrossCoupling.map(item => item.rowName) }
        }
      }
    })
    this.setState({
      historyObj
    })
  }

  findExperimentResultList = () => {
    const { status } = this.props;
    const { experimentObj, portObject } = this.state;
    const experimentIds = this.getExperimentIds();
    experimentIds.forEach(id => {
      if (this.experimentContent.get(id) || status === VERIFY_SUCCESS) {
        let FEXT = [], NEXT = [], IL = [], RL = [], DiffToComm = [], _list = null, COMMIL = [], COMMRL = [];
        const interfaceContent = this.experimentContent.get(id) || this.interfaceContent;
        _list = getResultList(interfaceContent.content, portObject[id], this.getColorByIndex);
        FEXT = _list.FEXT;
        NEXT = _list.NEXT;
        IL = _list.IL;
        RL = _list.RL;
        DiffToComm = _list.DiffToComm;
        COMMIL = _list.COMMIL ? _list.COMMIL : [];
        COMMRL = _list.COMMRL ? _list.COMMRL : [];
        if (!experimentObj[id]) {
          experimentObj[id] = {};
        }
        experimentObj[id].FEXT = { list: FEXT, show: FEXT.length ? [FEXT[0].rowName] : [] };
        experimentObj[id].NEXT = { list: NEXT, show: NEXT.length ? [NEXT[0].rowName] : [] };
        // Open all
        experimentObj[id].IL = { list: IL, show: IL.map(item => item.rowName) };
        experimentObj[id].RL = { list: RL, show: RL.map(item => item.rowName) };
        experimentObj[id].DiffToComm = { list: DiffToComm, show: DiffToComm.map(item => item.rowName) }

        // common model
        experimentObj[id].COMMIL = { list: COMMIL, show: COMMIL.map(item => item.rowName) };
        experimentObj[id].COMMRL = { list: COMMRL, show: COMMRL.map(item => item.rowName) };
      }
    })

    this.setState({
      experimentObj
    }, () => {
      this.defaultDisplay(experimentObj[experimentIds[0]]);
    })
  }

  defaultDisplay = (resultList) => {
    try {
      const { rxCentricMode } = this.props;
      const { displayMode } = this.state;
      const defaultList = resultList[displayMode].list.length ? resultList[displayMode].list[0] : null;
      if (!defaultList || (["NEXT", "FEXT"].includes(displayMode) && rxCentricMode.enable)) return;
      const id = defaultList.children.length ? defaultList.children[0].id : null;
      const defaultValues = [id];
      const defaultKey = defaultList.rowName;
      this.changePort(defaultValues, defaultKey, id);
    } catch (error) {
      console.error(error)
    }
  }

  changePort = (values, key, id) => {
    const { interfaceType } = this.props;
    let { portSelect } = this.state;
    const res = portChange({ portSelect: portSelect[id], values, key });
    if (!res) return;
    const { _portSelect, uncheck, newCheck } = res;
    const _fileId = strDelimited(id, "::")[0];
    let _newCheck = newCheck;
    let showFileId = strDelimited(id, "::")[0];
    if (interfaceType === CPHY && newCheck) {
      const [fileId, signal, pair, row, col] = strDelimited(newCheck, "::");
      _newCheck = [fileId, row, col].join("::");
      showFileId = [fileId, signal, pair].join("::");
    }

    this.updatePortSelect(_portSelect, _fileId);
    if (uncheck) {
      if (interfaceType === CPHY) {
        const [fileId, signal, pair, row, col] = strDelimited(uncheck, "::");
        const _fileId = [fileId, signal, pair].join("::");
        const isRemoveFail = sparameter.removeCurve({ row, col, id: _fileId });
        if (isRemoveFail) {
          this.removeFailCurve = [...(this.removeFailCurve || []), uncheck];
        }
      } else {
        const [fileId, row, col] = strDelimited(uncheck, "::");
        const isRemoveFail = sparameter.removeCurve({ row, col, id: fileId });;
        if (isRemoveFail) {
          this.removeFailCurve = [...(this.removeFailCurve || []), uncheck];
        }
      }
      return;
    };

    if (_newCheck) {
      this.showCurve(_newCheck, showFileId, newCheck).catch(error => console.error(error))
    }
  }

  showCurve = async (newCheck, fileId, check) => {
    const { files, displayMode } = this.state;
    const [, row, col] = strDelimited(newCheck, "::");
    const setting = this.getSetting();
    const axis = this.getAxis();

    let couplingType = ''
    if (displayMode === 'CrossCoupling') {
      couplingType = 'CPHY_COUPLING_SAM';
    }

    const { updateFiles: newFiles, curve, color } = await displayCurve({ files, fileId, row, col, Parameters: sparameter, setting, couplingType })
    if (!newFiles) return;
    if (this.plot2d && displayMode === this.state.displayMode && !(this.removeFailCurve || []).includes(check)) {
      this.plot2d.updateCurves(curve, true);
      this.plot2d.resetPlot();
      this.plot2d.resetColor({ id: fileId, row, col, color: color });
      if (sparameter.getSize() > 0) {
        sparameter.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        sparameter.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    } else {
      this.removeFailCurve = (this.removeFailCurve || []).filter(item => item !== check)
    }

    this.setState((prevState) => {
      let files = [...prevState.files];
      newFiles.forEach(fileItem => {
        const findIndex = files.findIndex(d => d.id === fileItem.id);
        if (findIndex > -1) {
          files[findIndex] = fileItem;
        }
      })
      return {
        files: [...files]
      }
    })
  }

  getAllNpi = (files, id, pairs, signals) => {
    const { isEndToEnd, interfaceType } = this.props;
    let data = [], params = {};
    if (!isEndToEnd) {
      data = this.interfaceContent.content.components || [];
      params = { channelId: id, type: 'RETURN_LOSS' };
    } else {
      data = this.interfaceContent;
      params = { endToEndChannelId: id, type: 'RETURN_LOSS' }
    }
    const promiseList = files.map((item, index) => {
      params.pair = pairs ? pairs[index] : null;
      params.signal = signals ? signals[index] : null;
      return item.getNpiFile(index, params, data, interfaceType);
    });
    return Promise.all(promiseList);
  }

  getAllExperimentNpi = async (experimentParams) => {
    const { files } = this.state;
    const promiseList = experimentParams.map(async (item, index) => {
      const interfaceContent = item.type === 'experiment' ? await getExperimentVerificationJson(item.id) : this.interfaceContent;
      if (interfaceContent) {
        this.experimentContent.set(item.id, interfaceContent);
        const data = interfaceContent.content.components || [];
        const params = item.type === 'experiment' ? { experimentId: item.id, type: 'RETURN_LOSS' } : { channelId: item.id, type: 'RETURN_LOSS' };
        const i = files.length ? files.length + index : index + 1;
        return item.getNpiFile(
          i,
          params,
          data
        )
      } else {
        return null;
      }
    })
    return Promise.all(promiseList)
  }

  getAllHistoryNpi = async (historyParams, isEndToEnd) => {
    const { interfaceType } = this.props;
    const { files } = this.state;
    const promiseList = await historyParams.map(async (item, index) => {
      const [historyId, signal, pair] = strDelimited(item.id, '::');
      const historyJson = this.historyContent.get(historyId);
      if (historyJson) {
        const data = item.getHistoryNpiFile({
          index: files.length ? files.length + index : index + 1,
          params: { historyId, type: 'RETURN_LOSS', signal, pair },
          data: isEndToEnd ? historyJson : (historyJson.content.components || []),
          isEndToEnd,
          type: interfaceType
        })
        return data;
      } else {
        return null;
      }
    })
    return Promise.all(promiseList)
  }

  getColorByIndex = (fileId, row, col) => {
    const { files } = this.state;
    return getColor({ files, fileId, row, col })
  }

  settingChangeCB = () => {
    const { files, displayMode } = this.state;
    const axis = this.getAxis();
    const setting = this.getSetting();
    let couplingType;
    if (displayMode === 'CrossCoupling') {
      couplingType = 'CPHY_COUPLING_SAM';
    }
    sparameter.changeParameter({ parameters: files, setting, yScale: axis.yScale, xScale: axis.xScale, couplingType });
  }

  changeFileListBottom = () => {
    this.resultRightMenu.updateFileListState();
  }

  axisChangeCB = (axis, value) => {
    axisChangeCallBack({ axis, value, setting: this.getSetting(), sparameter })
  }

  updateRangeCB = (update, min, max) => {
    updateRangeCallBack({ update, min, max, sparameter, axis: this.getAxis() })
  }

  changeWidthCB = () => {
    changeWidthCallBack({ plot2d: this.plot2d, sparameter, axis: this.getAxis(), setting: this.getSetting() })
  }

  changeIsShowReferenceLine = (tableData) => {
    this.setState({
      isShowReferenceLine: !this.state.isShowReferenceLine
    }, () => {
      this.crossReferenceLine(tableData, '');
    })
  }

  getResultConfig = () => {
    const { interfaceType, id, projectId, dataRate, isEndToEnd } = this.props;
    this.props._getReportConfig({ channelId: isEndToEnd ? '' : id, projectId, endToEndChannelId: isEndToEnd ? id : '', isEndToEnd, dataRate, interfaceType })
  }

  getDefaultGuideValue = (key, dataRate, referenceLineData = []) => {
    const { interfaceType } = this.props;
    const _key = interfaceType === CPHY ? 'andes_v2_CPHY' : key;
    const _defaultGuideKey = interfaceType === CPHY ? defaultCPHYGuideKey : defaultGuideKey
    const defaultGuideValue = getReportGuideValue(_key, _defaultGuideKey, dataRate);
    const nameList = []
    // Filter the qualified data
    let _referenceLineData = referenceLineData.filter((item) => {
      if (_defaultGuideKey.includes(item.name)) {
        nameList.push(item.name)
        return true;
      }
      return false;
    })
    // Add the data that are not available
    defaultGuideValue.forEach((item) => {
      if (!nameList.includes(item.name)) {
        _referenceLineData.push(item)
      }
    })
    return _referenceLineData;
  }

  resultLeft = () => {
    const components = this.interfaceContent && this.interfaceContent.content && this.interfaceContent.content.components ? this.interfaceContent.content.components : [];
    const { loading, displayReference, isShowReferenceLine, defaultReferenceLine, files, isLoadingReference } = this.state;
    const { id, projectId, reportConfig, dataRate, _updateReportInfo, _saveReportConfig, isEndToEnd, interfaceType } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <Spin spinning={loading} size='large'>
          <div className='andes-v2-result-parameter-left'>
            <svg ref={this.svgRef}></svg>
          </div>
          <div className='andes-v2-result-parameter-right'>
            <div className='reference-box' onClick={() => files && files.length > 0 && !loading && isLoadingReference && this.setState({ displayReference: true })}>
              <Tooltip title="Reference Lines" overlayClassName='aurora-tooltip'>
                <LineChartOutlined title='' className='andes-v2-reference-table-icon' />
              </Tooltip>
            </div>
          </div>
        </Spin>
        {displayReference && <ResultReference
          closePanel={() => this.setState({ displayReference: false })}
          projectId={projectId}
          reportConfig={reportConfig}
          defaultTableData={defaultReferenceLine}
          isEndToEnd={isEndToEnd}
          id={id}
          dataRate={dataRate}
          isShowReferenceLine={isShowReferenceLine}
          updateReportInfo={_updateReportInfo}
          saveReportConfig={_saveReportConfig}
          crossReferenceLine={this.crossReferenceLine}
          changeIsShowReferenceLine={this.changeIsShowReferenceLine}
          interfaceType={interfaceType}
          components={components}
        />}
      </div >
    );
  }

  getShowPointType = (portSelectInfo, _id) => {
    const { displayMode, resultsObj, historyObj, portSelect } = this.state;
    const { reportConfig, id } = this.props;
    let driver, receiver;
    if (reportConfig && reportConfig.custom) {
      driver = reportConfig.custom.driver || [];
      receiver = reportConfig.custom.receiver || [];
    }

    if (!driver && !receiver) {
      return [];
    }
    // Get the id of the selected curve
    let _portSelect = JSON.parse(JSON.stringify(portSelect));
    if (portSelectInfo && _id) {
      _portSelect[_id] = portSelectInfo;
    }
    const selectHistoryIds = this.getHistoryIds().filter(id => Object.keys(_portSelect).includes(id));
    const selectList = [id, ...selectHistoryIds].map(id => {
      if (_portSelect[id]) {
        return Object.values(_portSelect[id]) || [];
      }
      return []
    }).flat(2);

    let showPointType = new Set();
    if (['RL', 'COMMRL', 'DiffToComm'].includes(displayMode) && selectList.length > 0) {
      // Get the Info {id, name, comp, _comp} for the search curve
      const resultList = resultsObj[displayMode].list;
      let historyList = selectHistoryIds.map(id => {
        return historyObj[id][displayMode].list;
      }).flat();
      const dataList = [...resultList, ...historyList].map(item => item.children).flat();

      // Judge the type of component
      let findNum = 0;
      for (const data of dataList) {
        if (showPointType.size === 2 || findNum === selectList.length) break;
        if (selectList.includes(data.id)) {
          findNum++;
          if (receiver.includes(data.comp) || receiver.includes(data._comp)) showPointType.add(ReceiverPoints);
          if (driver.includes(data.comp) && displayMode === 'RL') showPointType.add(DriverPoints);
        }
      }
    }
    return Array.from(showPointType);
  }

  // Draw the reference line and mask line segments
  crossReferenceLine = (lineDatas = [], redrawType = '', isDraw = true, _portSelect, id) => {
    const { displayMode, isShowReferenceLine } = this.state;
    const { interfaceType } = this.props;
    const showPointType = interfaceType === CPHY ? this.getShowPointType(_portSelect, id) : undefined;
    this.plot2d && this.plot2d.crossReferenceLine({ lineDatas, displayMode, isShowReferenceLine, redrawType, isDraw, showPointType });
  }

  colorChange = (e, key) => {
    const { files, resultsObj, historyObj, displayMode, experimentObj } = this.state;
    const { resultPageType, interfaceType } = this.props;
    const fileId = strDelimited(key, "::")[0];
    // const list = this.getHistoryIds().includes(fileId) ? historyObj[fileId] : resultsObj;
    let list = {}
    if (this.getHistoryIds().includes(fileId)) {
      list = historyObj[fileId];
    } else if (this.getExperimentIds().includes(fileId) && resultPageType === EXPERIMENTS) {
      list = experimentObj[fileId];
    } else {
      list = resultsObj;
    }
    const { updateFiles } = changeCurveColor({ e, key, files, resultList: list, displayMode, type: interfaceType });
    if (!updateFiles) return;
    this.setState({
      files: updateFiles
    }, () => {
      updatePlotColor({ plot2d: this.plot2d, key, color: e.toHexString(), type: interfaceType });
    })
  }

  updatePortSelect = (_portSelect, id) => {
    if (this.props.interfaceType === CPHY) {
      this.crossReferenceLine([], DriverOrReceiver, false, _portSelect, id);
    }
    this.setState((prevState) => {
      prevState.portSelect[id] = _portSelect;
      return {
        portSelect: prevState.portSelect
      }
    });
  }

  _changeSelectAll = (e, group, _fileId) => {
    let { portSelect, files, displayMode } = this.state;
    const { isEndToEnd, id, interfaceType } = this.props;
    const axis = this.getAxis();
    const setting = this.getSetting();
    const check = e.target.checked;
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }

    const fileId = _fileId.split('::')[0];
    if (check) {
      const curves = getSelectAllinSignalCurves({
        e,
        group,
        portSelect: portSelect[fileId] || {},
        files,
        parameters: sparameter,
        setState: (d) => this.updatePortSelect(d, fileId),
        type: interfaceType
      })
      let curveType = "";
      // this.getHistoryIds().includes(fileId) ? (isEndToEnd ? 'endToEnd_history' : 'channel_history') : (isEndToEnd ? 'endToEnd' : 'channel')
      if (this.getHistoryIds().includes(fileId)) {
        curveType = isEndToEnd ? 'endToEnd_history' : 'channel_history';
      } else if (this.getExperimentIds().includes(fileId) && fileId !== id) {
        curveType = 'experiment';
      } else {
        curveType = isEndToEnd ? 'endToEnd' : 'channel';
      }

      let couplingType = ''
      if (displayMode === 'CrossCoupling') {
        couplingType = 'CPHY_COUPLING_SAM';
      }
      const _showCurves = showCurves({
        parameters: sparameter,
        curves,
        setting,
        curveType,
        files,
        couplingType
      });
      this.selectAllCancelAble = makeCancelable(_showCurves)
      this.selectAllCancelAble.promise.then(_curves => {
        this.plot2d.updateCurves(_curves, true);
        _curves.forEach(cur => {
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          const color = files[_fileIndex].matrix[cur.row][cur.col].color;
          this.plot2d.resetColor({ id: cur.id, row: cur.row, col: cur.col, color: color });
        })
        this.plot2d.resetPlot();
        if (sparameter.getSize() > 0) {
          sparameter.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          sparameter.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      })
    } else {
      if (!portSelect[fileId]) {
        portSelect[fileId] = {};
      }

      const _portSelect = JSON.parse(JSON.stringify(portSelect));
      _portSelect[fileId][group.rowName] = [];
      this.updatePortSelect(_portSelect[fileId], fileId);

      if (portSelect[fileId][group.rowName]) {
        const prevSelect = [...portSelect[fileId][group.rowName]];
        removeCurves(prevSelect, sparameter, interfaceType);
      }
    }
  }

  _rowNameClick = (displayMode, value) => {
    const { interfaceType } = this.props;
    this.setState((prevState) => {
      const resultsObj = rowDisplayChange(prevState.resultsObj, displayMode, value);
      let newData = {}
      if (displayMode === 'Default' && interfaceType === CPHY) {
        newData = handleCPHYDefaultResultList(resultsObj.Default.list, resultsObj.Default.show, prevState.pairGroup);
      }
      return {
        resultsObj: resultsObj,
        CPHYResultList: newData.CPHYResultList || prevState.CPHYResultList,
        pairGroup: newData.pairGroup || prevState.pairGroup
      }
    })
  }

  _historyRowNameClick = (displayMode, value, rowId) => {
    const { interfaceType } = this.props;
    this.setState((prevState) => {
      const [fileId] = rowId.split('::');
      prevState.historyObj[fileId] = rowDisplayChange(prevState.historyObj[fileId], displayMode, value)

      if (interfaceType === CPHY && displayMode === 'Default') {
        const newData = handleCPHYDefaultResultList(prevState.historyObj[fileId].Default.list, prevState.historyObj[fileId].Default.show, prevState.historyPairGroup[fileId]);
        prevState.historyCPHYResultList[fileId] = newData.CPHYResultList;
        prevState.historyPairGroup[fileId] = newData.pairGroup;
      }
      return {
        historyObj: prevState.historyObj,
        historyCPHYResultList: prevState.historyCPHYResultList,
        historyPairGroup: prevState.historyPairGroup
      }
    })
  }

  _experimentRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.experimentObj[rowId] = rowDisplayChange(prevState.experimentObj[rowId], displayMode, value)
      return {
        experimentObj: prevState.experimentObj
      }
    })
  }

  displayModeChange = (e) => {
    const value = toString.call(e) === '[object String]' ? e : e.target.value;
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    this.removeAllCurves();
    this.setState({
      displayMode: value,
      portSelect: {},
      portSettingVisible: false
    }, () => {
      this.crossReferenceLine([], '', false);
      this.getResultsByType(this.props.id, true);
    })
  }

  removeAllCurves = () => {
    const { interfaceType } = this.props;
    let ports = [...this.getCurrentSelects()];
    removeCurves(ports, sparameter, interfaceType);
  }

  resultModeChange = (key) => {
    this.setState({
      modeActive: key
    })
    this.displayModeChange(key === 'Diff Mode' ? 'IL' : key === 'Comm Mode' ? 'COMMIL' : 'DiffToComm')
  }

  getModeList = () => {
    const { interfaceType } = this.props;
    const type = this.interfaceContent && this.interfaceContent.prelayoutType ? this.interfaceContent.prelayoutType : "Schematic";
    let newMode = JSON.parse(JSON.stringify(Mode));
    const diffModeIndex = newMode.findIndex(item => item.title === "Diff Mode");

    if (interfaceType !== CPHY) {
      const mixedModeIndex = newMode.findIndex(item => item.title === "Mixed Mode");
      newMode[mixedModeIndex].children = newMode[mixedModeIndex].children.filter(item => !['Intra-Lane cross-coupling'].includes(item.name));
    } else {
      newMode[diffModeIndex].children = newMode[diffModeIndex].children.filter(item => !["NEXT", "FEXT"].includes(item.name));
    }

    if (!this.isPreLayout) {
      return newMode;
    }
    //When the type of pre Layout is equal to model, while there is near end crosstalk and far end crosstalk in the diff mode
    if (diffModeIndex < 0) {
      return Mode;
    }
    if (type !== "model") {
      newMode[diffModeIndex].children = newMode[diffModeIndex].children.filter(item => !["NEXT", "FEXT"].includes(item.name));
    }
    return newMode;
  }

  changeRxCentricMode = async (key, value) => {
    const { id, projectId, reportConfig, rxCentricMode } = this.props;
    const { portObject } = this.state;
    const newReportConfig = {
      ...reportConfig,
      custom: {
        ...reportConfig.custom,
        rxCentricMode: {
          ...rxCentricMode,
          [key]: value
        }
      }
    }

    // If filtering mode is enabled for the first time, the data must also be initialized
    if (!rxCentricMode.signalPorts && key === 'enable') {
      const channels = this.interfaceContent.config && this.interfaceContent.config.channels ? this.interfaceContent.config.channels : [];
      const adsConfigSignals = this.interfaceContent.adsConfig && this.interfaceContent.adsConfig.signals ? this.interfaceContent.adsConfig.signals : [];
      newReportConfig.custom.rxCentricMode.signalPorts = initSignalPorts(portObject[id], channels, adsConfigSignals);
    }

    await saveReportConfigPromise({ projectId, channelId: id, config: newReportConfig });
    this.props._updateReportInfo({ reportConfig: newReportConfig });

    if (key === 'enable' && value) {
      this.setState({ portSettingVisible: true })
    }
  }

  portViewSetting = () => {
    const { rxCentricMode } = this.props;
    return <div className='andes-result-port-icon-group'>
      <Tooltip title="Show RX-->TX series only" placement='topRight'>
        <span
          className="port-view-text"
        >
          Port View Mode
          <Switch
            size='small'
            checked={rxCentricMode.enable}
            onChange={(checked) => this.changeRxCentricMode("enable", checked)}
          />
        </span>
      </Tooltip>
      {rxCentricMode.enable ? <Tooltip title="Configure ports" placement='topRight'>
        <SettingOutlined onClick={() => this.setState({ portSettingVisible: true })} />
      </Tooltip> : null}
    </div>
  }

  rightTopRender = () => {
    const { displayMode, modeActive, resultPageType } = this.state;
    const modelList = this.getModeList();
    return (
      <Tabs onChange={this.resultModeChange} type="card" className='andes-result-mode-tab' activeKey={modeActive} items={modelList.map(d => ({
        key: d.title,
        label: d.title,
        children: <>
          <DisplayMode
            displayModeChange={this.displayModeChange}
            displayMode={displayMode}
            Modes={d.children}
          />
          {["NEXT", "FEXT"].includes(displayMode) && resultPageType !== EXPERIMENTS ? this.portViewSetting() : null}
        </>
      }))} />
    )
  }

  _fileNameClick = (id) => {
    const { files } = this.state;
    const index = files.findIndex(f => f.id === id);
    this.setState((prevState) => {
      prevState.files[index].show = !prevState.files[index].show;
      return {
        files: [...prevState.files]
      }
    })
  }

  getHistoryIds = () => {
    const { historyList } = this.props;
    return historyList ? historyList.map(d => d.id) : [];
  }

  getExperimentIds = () => {
    const { id, experimentList, status } = this.props;
    const experimentIds = experimentList ? experimentList.map(d => d.id) : []
    if (status === VERIFY_SUCCESS) {
      experimentIds.unshift(id);
    }
    return experimentIds;
  }

  customizeCompAndPins = (item, displayMode, multi) => {
    return <span className='andes-customized-title'>
      <span className='design-name'>{item.namePrev}&nbsp;</span>
      {multi ?
        <Fragment>
          <span>(</span>
          <span className='component-pin-name'>{item.comp}{`<+${item.positivePin}`},&nbsp;{`-${item.negativePin}>`}</span>
          <span>,&nbsp;</span>
          <span className='component-pin-name'>{item._comp}{`<+${item._positivePin}`},&nbsp;{`-${item._negativePin}>`}</span>
          {["NEXT", "FEXT"].includes(displayMode) ? <span className='signal-name'>&nbsp;{item._signal}</span> : null}
          <span>)</span>
        </Fragment>
        : <Fragment>
          <span className='component-pin-name'>{item.comp}{`<+${item.positivePin}`},&nbsp;{`-${item.negativePin}>`}</span>
          {displayMode === "Default" ?
            <Fragment>
              <span className='signal-name'>&nbsp;{item.signal}</span>
              <span className='port-mode'>&nbsp;{`[${item.portType}]`}</span>
            </Fragment>
            : null}
        </Fragment>
      }
    </span>
  }

  customizeTitle = (item, rowName) => {
    const { displayMode } = this.state;
    if (!this.props.isEndToEnd) {

      if (displayMode === 'CrossCoupling') {
        return <span className='andes-customized-title'>
          <span className='design-name'>{item.namePrev}</span>
        </span>
      }

      if (displayMode === "Default" && !rowName && item.portIndex === item._portIndex) {
        return <span className='andes-customized-title'>
          <span className='design-name'>{item.namePrev}</span>
        </span>
      }

      const multi = ["IL", "NEXT", "FEXT", "DiffToComm", "COMMIL"].includes(displayMode);
      return this.customizeCompAndPins(item, displayMode, multi);
    }

    if (displayMode === 'Default') {
      if (!rowName && item.portIndex === item._portIndex) {
        return <span className='andes-customized-title'>
          <span className='design-name'>{item.namePrev}</span>
        </span>
      } else {
        return <span className='andes-customized-title'>
          <span className='design-name'>{item.namePrev} {item._design}</span>
          <span className='component-pin-name'>&nbsp;{item.comp}{item.pinsText}</span>
          <span className='signal-name'>&nbsp;{item.signal}</span>
          <span className='port-mode'>&nbsp;{`[${item.portType}]`}</span>
        </span>
      }
    } else {
      return <span className='andes-customized-title'>
        <span className='design-name'>{item.namePrev}&nbsp;</span>
        {item._design2 ?
          <span>
            <span>(</span>
            <span className='design-name'>{item._design1}&nbsp;</span>
            <span className='component-pin-name'>{item._design1CompPin}</span>
            <span>,&nbsp;</span>
            <span className='design-name'>{item._design2}&nbsp;</span>
            <span className='component-pin-name'>{item._design2CompPin}</span>
            {(displayMode === 'NEXT' || displayMode === 'FEXT') && <span className='signal-name'>&nbsp;{item._signal2}</span>}
            <span>)</span>
          </span> :
          <span>
            <span className='design-name'>{item._design1}&nbsp;</span>
            <span className='component-pin-name'>{item._design1CompPin}</span>
          </span>}
      </span>
    }
  }

  getCPHYDefaultResultList = (rowId) => {
    const { id } = this.props;
    const { CPHYResultList, historyCPHYResultList } = this.state;
    const [fileId] = rowId.split('::');
    if (id === fileId) {
      return CPHYResultList.get(rowId) || { list: [], show: [] }
    } else {
      return historyCPHYResultList[fileId] ? historyCPHYResultList[fileId].get(rowId) : { list: [], show: [] };
    }
  }

  pairGroupClick = (rowId) => {
    const { id } = this.props;
    const [fileId] = rowId.split('::');

    // result
    if (fileId === id) {
      const { pairGroup } = this.state;
      const _pairGroup = [...pairGroup];
      const pairIndex = pairGroup.findIndex(item => item.rowId === rowId);
      if (pairIndex >= 0) {
        _pairGroup[pairIndex].show = !_pairGroup[pairIndex].show;
        this.setState({ pairGroup: _pairGroup })
      }
    } else { // history
      const { historyPairGroup } = this.state;
      const _historyPairGroup = { ...historyPairGroup };
      const pairIndex = _historyPairGroup[fileId] ? _historyPairGroup[fileId].findIndex(item => item.rowId === rowId) : -1;
      if (pairIndex >= 0) {
        _historyPairGroup[fileId][pairIndex].show = !_historyPairGroup[fileId][pairIndex].show;
        this.setState({ historyPairGroup: _historyPairGroup })
      }
    }
  }

  pairGroupSelectRowAll = (e, rowId) => {
    const { pairGroup, portSelect, files } = this.state;
    const { isEndToEnd, id, interfaceType } = this.props;
    const { list } = this.getCPHYDefaultResultList(rowId);
    const [fileId] = rowId.split('::');
    const axis = this.getAxis();
    const setting = this.getSetting();
    const check = e.target.checked;
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    let curves = [], curveType = '';
    if (this.getHistoryIds().includes(fileId)) {
      curveType = isEndToEnd ? 'endToEnd_history' : 'channel_history';
    } else if (this.getExperimentIds().includes(fileId) && fileId !== id) {
      curveType = 'experiment';
    } else {
      curveType = isEndToEnd ? 'endToEnd' : 'channel';
    }
    const _portSelect = JSON.parse(JSON.stringify(portSelect));
    for (const group of list) {
      // this._changeSelectAll(e, group, fileId);
      if (check) {
        const _curves = getSelectAllinSignalCurves({
          e,
          group,
          portSelect: _portSelect[fileId] || {},
          files,
          parameters: sparameter,
          setState: (d) => { _portSelect[fileId] = d; },
          type: interfaceType
        })
        curves.push(..._curves);
      } else {
        if (!portSelect[fileId]) {
          portSelect[fileId] = {};
        }
        _portSelect[fileId][group.rowName] = [];
        if (portSelect[fileId][group.rowName]) {
          const prevSelect = [...portSelect[fileId][group.rowName]];
          removeCurves(prevSelect, sparameter, interfaceType);
        }
      }
    }
    this.updatePortSelect(_portSelect[fileId], fileId)

    if (check) {
      const _showCurves = showCurves({
        parameters: sparameter,
        curves,
        setting,
        curveType,
        files
      });
      this.selectAllCancelAble = makeCancelable(_showCurves)
      this.selectAllCancelAble.promise.then(_curves => {
        this.plot2d.updateCurves(_curves, true);
        _curves.forEach(cur => {
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          const color = files[_fileIndex].matrix[cur.row][cur.col].color;
          this.plot2d.resetColor({ id: cur.id, row: cur.row, col: cur.col, color: color });
        })
        this.plot2d.resetPlot();
        if (sparameter.getSize() > 0) {
          sparameter.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          sparameter.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      })
    }

    // result
    if (fileId === id) {
      const _pairGroup = [...pairGroup];
      const pairIndex = pairGroup.findIndex(item => item.rowId === rowId);
      if (pairIndex >= 0) {
        _pairGroup[pairIndex].checked = e.target.checked;
        this.setState({ pairGroup: _pairGroup })
      }
    } else { // history
      const { historyPairGroup } = this.state;
      const _historyPairGroup = { ...historyPairGroup };
      const pairIndex = _historyPairGroup[fileId] ? _historyPairGroup[fileId].findIndex(item => item.rowId === rowId) : -1;
      if (pairIndex >= 0) {
        _historyPairGroup[fileId][pairIndex].checked = !_historyPairGroup[fileId][pairIndex].checked;
        this.setState({ historyPairGroup: _historyPairGroup })
      }
    }
  }

  // Filter out unwanted curves after grouping the port by TX and RX 2
  handleResultList = (obj = {}, id) => {
    const { displayMode, portSelect } = this.state;
    const { rxCentricMode } = this.props;
    if (["NEXT", "FEXT"].includes(displayMode) && rxCentricMode.enable) {
      const newList = getResultObject(obj.list, rxCentricMode.signalPorts);
      // handle portSelect: If the selected curve is not in the displayable range, deselect it
      for (const item of newList) {
        let _portSelect = (portSelect[id] || {})[item.rowName] || [];
        const newPortIds = item.children.map(it => it.id);
        const notShowPortIds = _portSelect.filter(item => !newPortIds.includes(item));

        for (const portId of notShowPortIds) {
          _portSelect = _portSelect.filter(item => item !== portId);
          this.changePort(_portSelect, item.rowName, id);
        }
      }

      return { ...obj, list: newList };
    }

    return obj;
  }

  resultListRender = () => {
    const { portSelect, displayMode, resultsObj, historyObj, files, experimentObj, pairGroup, historyPairGroup, portSettingVisible } = this.state;
    const { id, resultPageType, interfaceType, rxCentricMode } = this.props;
    const historyIds = this.getHistoryIds();
    const existIds = [];
    const historyFiles = files.filter(d => {
      const [id] = d.id.split('::');
      if (historyIds.includes(id) && !existIds.includes(id)) {
        existIds.push(id);
        return true;
      }
      return false;
    });
    const components = this.interfaceContent && this.interfaceContent.content && this.interfaceContent.content.components ? this.interfaceContent.content.components : [];

    return (
      <Fragment>
        {resultPageType === EXPERIMENTS ? <ResultList
          title='Current Result'
          type={HISTORY}
          multiFiles={true}
          displayMode={displayMode}
          portSelect={portSelect}
          portSelectMultiFile={true}
          changePort={this.changePort}
          colorChange={this.colorChange}
          resultList={experimentObj}
          rowNameClick={this._experimentRowNameClick}
          changeSelectAll={this._changeSelectAll}
          files={files}
          fileNameClick={this._fileNameClick}
          customizeTitle={this.customizeTitle}
        /> : <Fragment>
          {/* Result list */}
          <ResultList
            title='Current Result'
            type={RESULT}
            fileId={id}
            rowId={id}
            displayMode={displayMode}
            portSelect={portSelect}
            portSelectMultiFile={true}
            changePort={this.changePort}
            colorChange={this.colorChange}
            resultList={resultsObj}
            rowNameClick={this._rowNameClick}
            changeSelectAll={this._changeSelectAll}
            customizeTitle={this.customizeTitle}
            pairGroup={pairGroup || []}
            channelType={interfaceType}
            pairGroupClick={this.pairGroupClick}
            pairGroupSelectRowAll={this.pairGroupSelectRowAll}
            getCPHYDefaultResultList={this.getCPHYDefaultResultList}
            handleResultList={this.handleResultList}
          />
          {/* History */}
          <ResultList
            type={HISTORY}
            multiFiles={true}
            displayMode={displayMode}
            portSelect={portSelect}
            portSelectMultiFile={true}
            changePort={this.changePort}
            colorChange={this.colorChange}
            resultList={historyObj}
            rowNameClick={this._historyRowNameClick}
            changeSelectAll={this._changeSelectAll}
            files={historyFiles}
            fileNameClick={this._fileNameClick}
            customizeTitle={this.customizeTitle}
            channelType={interfaceType}
            historyPairGroup={historyPairGroup}
            pairGroupClick={this.pairGroupClick}
            pairGroupSelectRowAll={this.pairGroupSelectRowAll}
            getCPHYDefaultResultList={this.getCPHYDefaultResultList}
            handleResultList={this.handleResultList}
          />
        </Fragment>}
        {portSettingVisible && <TxAndRxPortSetting
          closePanel={() => this.setState({ portSettingVisible: false })}
          signalPorts={rxCentricMode.signalPorts}
          components={components}
          changeRxCentricMode={this.changeRxCentricMode}
        />}
      </Fragment>
    )
  }

  settingRender = () => {
    const selectArr = this.getCurrentSelects();
    return (
      <SparameterSetting
        onRef={this.onRef}
        portSelectLen={selectArr.length}
        settingChangeCB={this.settingChangeCB}
        changeFileListBottom={this.changeFileListBottom}
        axisChangeCB={this.axisChangeCB}
        updateRangeCB={this.updateRangeCB}
      />
    )
  }

  resultRight = () => {
    return (
      <ResultRightMenu
        onRef={this.resultRightMenuOnRef}
        top={this.rightTopRender}
        resultList={this.resultListRender}
        setting={this.settingRender}
      />
    )
  }

  render() {
    return (
      <ResultLayout
        defaultRightWidth={330}
        resultLeft={this.resultLeft}
        resultRight={this.resultRight}
        changeWidthCB={this.changeWidthCB}
      />
    )
  }
}

const mapState = (state) => {
  const {
    AndesV2Reducer: { project: { openProjectId, reportInfo } } } = state;
  const { reportConfig = {}, reportConfigLoading } = reportInfo || {};
  const rxCentricMode = reportConfig.custom && reportConfig.custom.rxCentricMode ? reportConfig.custom.rxCentricMode : {};
  return {
    projectId: openProjectId,
    reportConfig,
    reportConfigLoading,
    rxCentricMode
  };
}

const mapDispatch = (dispatch) => ({
  _updateReportInfo(info) {
    dispatch(updateReportInfo(info))
  },
  _saveReportConfig(channelId, isMultiPCB) {
    dispatch(saveReportConfig(channelId, isMultiPCB))
  },
  _getReportConfig({ channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType }) {
    dispatch(getReportConfig({ channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType }))
  }
})

export default connect(mapState, mapDispatch)(SparameterResult);


