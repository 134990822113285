import React, { Component, createRef } from 'react';
import { Input, Select, Spin } from 'antd';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { DIFF, SINGLE, TRACE } from '../../../../../constants/libraryConstants';
import sierraLibrary from '../../../../../services/Sierra/library/libraryStorage';
import _ from "lodash";
import { savePreLayoutTemplate, updateLibraryTree } from '../../../store/library/action';
import TraceSetup from '../../../../../components/PreLayoutLibrary/traceSetup';
import { calculateTraceImpedance, getTraceImpedanceData, getTraceTemplateLibrary } from '../../../../../services/Sierra/library';
import { SierraTraceConfig, TRACE_LENGTH_UNITS, checkTraceLength } from '../../../../../services/Sierra/prelayout/Schematic';
import { SIERRA } from '../../../../../constants/pageType';
import { updatePreLayoutData } from '../../../store/prelayout/action';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../../../../services/helper/panelSizeHelper';
import { numberCheck } from '../../../../../services/helper/dataProcess';
import { unitChange } from '../../../../../services/helper/mathHelper';
import { splitValueUnitByUnits } from '../../../../../services/helper/numberHelper';
import UnitAddonAfter from '../../../../../components/UnitAddonAfter';
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';


const Option = Select.Option;
class SignalSectionTemplate extends Component {

  constructor(props) {
    super(props);
    const { openSectionTemplate = {} } = props;
    const templateList = sierraLibrary.getTree(TRACE);
    const template = openSectionTemplate && openSectionTemplate.template ? openSectionTemplate.template : {};
    const { value, unit } = splitValueUnitByUnits(openSectionTemplate.traceLength || "", TRACE_LENGTH_UNITS);
    let name = (templateList.find(item => item.id === template.id) || {}).name;
    if (!name && template && template.name) {
      name = template.name;
    }
    this.state = {
      maxWidth: 1000,
      maxHeight: 800,
      templateList: sierraLibrary.getTree(TRACE),
      template: { ...template, name },
      traceLength: value,
      traceLengthUnit: unit || "mil",
      loading: false,
      signalType: SINGLE
    };
    this.dialogRoot = document.getElementById("root");
    this.lockRef = createRef();
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.initTemplate()
    this.props.changeOpenTemplateStatus(false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 1000) - 20,
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidUpdate = async (prevProps, prevStates) => {
    const { openedTemplateUpdate, libraryTreeInfo } = this.props;
    if (openedTemplateUpdate && openedTemplateUpdate !== prevProps.openedTemplateUpdate) {
      this.setState({
        loading: "Loading..."
      })
      await this.saveTemplate(false, true)
      this.initTemplate()
      this.props.changeOpenTemplateStatus(false)
    }


    if (libraryTreeInfo && libraryTreeInfo.length && libraryTreeInfo.includes(TRACE) && !_.isEqual(libraryTreeInfo, prevProps.libraryTreeInfo || [])) {
      this.setState({
        templateList: sierraLibrary.getTree(TRACE)
      })
      this.props._updateLibraryTree(null);
    }

    /*    const { isComplete, saveConfig } = this.state
       if (isComplete && isComplete !== prevStates.isComplete && saveConfig && this.saveInfo) {
         this.closePanelAndSave({ ...this.saveInfo, config: { ...saveConfig, type: this.saveInfo.signalType } })
       } */
  }

  scrollToBottom = () => {
    if (this.lockRef) {
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }

  initTemplate = () => {
    const { openSectionTemplate = {} } = this.props;
    const { templateList } = this.state;
    const template = openSectionTemplate && openSectionTemplate.template ? openSectionTemplate.template : {};
    const { value, unit } = splitValueUnitByUnits(openSectionTemplate.traceLength || "", TRACE_LENGTH_UNITS);
    let name = (templateList.find(item => item.id === template.id) || {}).name;
    if (!name && template && template.name) {
      name = template.name;
    }
    this.setState({
      loading: template.id ? "Loading..." : false,
      template: { ...template, name },
      traceLength: value,
      traceLengthUnit: unit || "mil",
      signalType: SINGLE
    })
  }

  saveTemplate = async (isClose = false, update = false) => {
    const { openSectionTemplate = {}, signals, _reDrawSignals, differentialSections } = this.props;
    const { template, traceLength, traceLengthUnit, errorMsg, signalType } = this.state;
    if (errorMsg && errorMsg.msg) {
      return;
    }
    let _signals = [...signals], diffSections = {}, _differentialSections = [...differentialSections || []];
    for (let signal of _signals) {

      for (let secItem of signal.sections) {
        if (secItem.id === openSectionTemplate.sectionId) {
          secItem.template = template;
          secItem.traceLength = traceLength ? `${traceLength}${traceLengthUnit}` : "";
        }

        const findSameTemplate = template.id && secItem.template && secItem.template.id === template.id;

        if (findSameTemplate) {
          _differentialSections = _differentialSections.filter(list => !(list.find(item => item.signal === signal.name && item.sectionId === secItem.id)))
          if (signalType === DIFF) {
            if (!diffSections[secItem.id]) {
              diffSections[secItem.id] = [signal.name]
            } else {
              diffSections[secItem.id].push(signal.name)
            }
            /*   diffSections.push({
                signal: signal.name,
                sectionId: secItem.id
              }) */
          }
        }
      }

    }

    if (Object.keys(diffSections).length) {
      _differentialSections.push(...Object.keys(diffSections).map(key => {
        return [...diffSections[key].map(name => {
          return {
            signal: name,
            sectionId: key
          }
        })]
      }));
    }
    /*    const index = _signals.findIndex(item => item.name === openSectionTemplate.signal);
       if (index < 0) {
         return;
       }
   
       const secIndex = _signals[index].sections.findIndex(item => item.id === openSectionTemplate.sectionId);
       if (secIndex < 0) {
         return;
       }
   
       _signals[index].sections[secIndex].template = template;
       _signals[index].sections[secIndex].traceLength = traceLength ? `${traceLength}${traceLengthUnit}` : ""; */

    _reDrawSignals && _reDrawSignals(_signals);
    this.props.updatePreLayoutData({ signals: _signals, differentialSections: _differentialSections, keys: ["signals", "differentialSections"] });
    if (this.Child && this.Child.getConfig) {
      const info = await this.Child.getConfig(update);
      if (info && info.save) {
        this.saveInfo = {
          id: template.id,
          name: template.name,
          type: info.type,
          config: info.config,
          version: info.version,
          signalType: info.signalType
        }
        /*    if (info.autoSave) { */
        this.closePanelAndSave(this.saveInfo, isClose);
        /*  } */
      } else {
        isClose && this.props.closePanel();
      }
    } else {
      isClose && this.props.closePanel();
    }
  }

  closePanelAndSave = (info, isClose) => {
    this.props._savePreLayoutTemplate(TRACE, info);
    isClose && this.props.closePanel();
  }

  close = async () => {
    const { errorMsg } = this.state;
    if (errorMsg && errorMsg.type && errorMsg.msg) {
      this.scrollToBottom()
      return;
    }
    this.setState({
      loading: "Saving..."
    })
    await this.saveTemplate(true);
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  changeTemplate = (key) => {
    const { templateList } = this.state;
    const findTemplate = templateList.find(item => item.id === key);
    if (!findTemplate) {
      return;
    }

    let template = {
      id: key,
      name: findTemplate.name
    }
    this.setState({
      loading: true,
      template
    })
  }

  updateErrorMsg = (errorMsg) => {
    this.setState({
      errorMsg
    }, () => {
      if (errorMsg && errorMsg.type && errorMsg.msg) {
        this.scrollToBottom()
      }
    })
  }

  updateLoading = (loading) => {
    this.setState({
      loading
    })
  }

  updateSignalType = (signalType) => {
    this.setState({
      signalType
    })
  }

  closeAndSave = (isComplete, config) => {
    this.setState({
      isComplete,
      saveConfig: config
    })
  }

  onRef = (ref) => {
    this.Child = ref;
  }

  selectAfter = () => {
    const { traceLengthUnit } = this.state;
    return UnitAddonAfter({ unit: traceLengthUnit, changeUnit: this.changeUnit, list: TRACE_LENGTH_UNITS })
  }

  changeUnit = (key) => {
    const { traceLength, traceLengthUnit } = this.state;
    this.setState({
      traceLengthUnit: key,
      traceLength: traceLength ? unitChange({
        num: traceLength,
        oldUnit: traceLengthUnit,
        newUnit: key,
        decimals: 2
      }).number : ""
    })
  }

  changeLength = (e) => {
    const { errorMsg } = this.state;
    this.setState({
      traceLength: e.target.value,
      errorMsg: errorMsg && errorMsg.type === "length" ? null : errorMsg
    })
  }

  saveLength = (e) => {
    const value = e.target.value;
    let error = value ? numberCheck(value) : null;
    const { errorMsg, traceLengthUnit } = this.state;
    if (value) {
      error = checkTraceLength(value, traceLengthUnit);
    }
    this.setState({
      traceLength: value,
      errorMsg: error ? { type: "length", msg: `Trace length ${error}.` } : errorMsg && errorMsg.type === "length" ? null : errorMsg
    }, () => {
      if (error) {
        this.scrollToBottom()
      }
    })
  }

  render() {
    const { errorMsg, templateList, template, maxWidth, maxHeight, traceLength, loading } = this.state;
    const errorExist = errorMsg && errorMsg.msg;
    const content = (
      <Panel
        className={`sierra-pre-layout-template-panel sierra-panel aurora-library-panel`}
        title="Trace Template"
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 800 })}
        // minWidth={400}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        position='panel-center-top-40'
        onCancel={this.close}
        overflow='hidden'
        draggable
      >
        <div className='sierra-pre-layout-template-content' style={{ maxHeight: maxHeight - 44 }} ref={c => this.lockRef = c}>
          <div className='sierra-pre-layout-template-item'>
            {/* type */}
            <label className='pre-layout-template-label'>Template</label>
            <Select
              value={template.id}
              onChange={this.changeTemplate}
              placeholder="Trace Template"
              className='aurora-select'
              popupClassName='aurora-select-dropdown'
              popupMatchSelectWidth={false}
              showSearch={true}
            >
              {templateList.map(item => <Option
                key={item.id}
                value={item.id}
                title={item.name}
              >{item.name}</Option>)}
            </Select>
          </div>
          <div className='sierra-pre-layout-template-item'>
            <label className='pre-layout-template-label'>Length</label>
            <Input
              value={traceLength || null}
              addonAfter={this.selectAfter()}
              placeholder="Trace Length"
              onChange={(e) => this.changeLength(e)}
              onBlur={(e) => this.saveLength(e)}
              onKeyDown={this.keyDown}
              className='aurora-input'
            />
          </div>
          {template && template.id ? <div className='pre-layout-trace-template-content'>
            <Spin spinning={loading ? true : false} tip={loading ? loading || "Loading..." : ""}>
              <TraceSetup
                product={SIERRA}
                singleMode={true}
                libraryId={template.id}
                name={template.name}
                TraceConfig={SierraTraceConfig}
                getLibraryDetail={getTraceTemplateLibrary}
                calculateImpedance={calculateTraceImpedance}
                getTraceImpedanceData={getTraceImpedanceData}
                onRef={this.onRef}
                updateErrorMsg={this.updateErrorMsg}
                errorMsg={errorMsg}
                updateLoading={this.updateLoading}
                updateSignalType={this.updateSignalType}
                closeAndSave={this.closeAndSave}
              />
            </Spin>
          </div> : null}
          {<div className={errorExist ? 'model-name-error-msg' : ""}>{errorExist ? errorMsg.msg : ""}</div>}
        </div>
      </Panel>)

    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const { SierraReducer: {
    library: { libraryTreeInfo },
    prelayout: { signals, differentialSections }
  } } = state;
  return {
    libraryTreeInfo,
    signals,
    differentialSections
  }
}

const mapDispatch = (dispatch) => ({
  updatePreLayoutData(info) {
    dispatch(updatePreLayoutData(info))
  },
  _updateLibraryTree(type) {
    dispatch(updateLibraryTree(type))
  },
  _savePreLayoutTemplate(modelType, data) {
    dispatch(savePreLayoutTemplate(modelType, data))
  }
})

export default connect(mapState, mapDispatch)(SignalSectionTemplate);
