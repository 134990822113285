import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import OperationDebugDownloadLog from '../../components/OperationLog/downloadLog';
import { closeDebugDownloadMsg, updateDownloadMsg } from './store/project/action';

class DownloadLog extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { debugDownloadMsg, debugDownloadMsgShow, _closeDebugDownloadMsg, _updateDownloadMsg } = this.props;
    return <OperationDebugDownloadLog
      debugDownloadMsg={debugDownloadMsg}
      debugDownloadMsgShow={debugDownloadMsgShow}
      _closeDebugDownloadMsg={_closeDebugDownloadMsg}
      _updateDownloadMsg={_updateDownloadMsg}
    />
  }
};

const mapState = (state) => {
  const { SierraReducer: { project: { debugDownloadMsg, debugDownloadMsgShow } } } = state;
  return {
    debugDownloadMsg,
    debugDownloadMsgShow,
  }
}

const mapDispatch = (dispatch) => ({
  _closeDebugDownloadMsg() {
    dispatch(closeDebugDownloadMsg());
  },
  _updateDownloadMsg(msg) {
    dispatch(updateDownloadMsg(msg));
  }
})

export default connect(mapState, mapDispatch)(DownloadLog);