import {
  CHANGE_RESULT_KEY,
  OPEN_RESULT_PAGE_INFO,
  CLEAN_RESULT_PAGE_INFO,
  UPDATE_RESULT_CONFIG,
  DEFAULT_RESULT_CONFIG,
  SAVE_RESULT_CONFIG,
  RELOAD_CURRENT_RESULT_PAGE,
  UPDATE_EYE_DIAGRAM_CONFIG,
  GET_CUSTOMIZED_EYE_DIAGRAM,
  UPDATE_RESULT_SIGNALS,
  UPDATE_CUSTOM_EYE_STATUS,
  CHANGE_TRAIN_SWITCH_VALUE,
  OPEN_RESULT_PACKAGE_PAGE_INFO,
  OPEN_RESULT_CARD_PAGE_INFO,
  UPDATE_RESULT_TYPE,
  UPDATE_SELECT_PATTERNS,
  UPDATE_RESULT_EXISTS,
} from './actionTypes';

export const changeResultKey = (key) => ({
  type: CHANGE_RESULT_KEY,
  key
});

export const openResultPageInfo = ({ verificationId,
  verificationSubId,
  designSubId,
  designID,
  interfaces,
  channelId,
  channelName,
  ddrType,
  verificationName
}) => ({
  type: OPEN_RESULT_PAGE_INFO,
  verificationId,
  verificationSubId,
  designSubId,
  designID,
  interfaces,
  channelId,
  channelName,
  ddrType,
  verificationName
});

export const cleanResultPageInfo = () => ({
  type: CLEAN_RESULT_PAGE_INFO
});

/* === result config === */
export const updateResultConfig = (resultConfig) => ({
  type: UPDATE_RESULT_CONFIG,
  resultConfig
});

export const defaultResultConfig = () => ({
  type: DEFAULT_RESULT_CONFIG
});

export const saveResultConfig = () => ({
  type: SAVE_RESULT_CONFIG
});

// Display setting, update result
export const reloadCurrentResultPage = (reloadStatus) => ({
  type: RELOAD_CURRENT_RESULT_PAGE,
  reloadStatus
});

export const updateEyeDiagramConfig = ({ eyeDiagramConfig, isSave, verificationId }) => ({
  type: UPDATE_EYE_DIAGRAM_CONFIG,
  eyeDiagramConfig,
  isSave,
  verificationId
});

export const getCustomizedEye = ({ content, verificationId }) => ({
  type: GET_CUSTOMIZED_EYE_DIAGRAM,
  content,
  verificationId
});

export const updateResultSignals = (resultSignals) => ({
  type: UPDATE_RESULT_SIGNALS,
  resultSignals
});

export const updateCustomEyeStatus = (customEyeHashId) => ({
  type: UPDATE_CUSTOM_EYE_STATUS,
  customEyeHashId
});

export const changeTrainSwitchValue = (trainSwitchValue) => ({
  type: CHANGE_TRAIN_SWITCH_VALUE,
  trainSwitchValue
})

export const openPackageResultPageInfo = ({
  verificationId,
  designID,
  name,
  packageVerificationId,
  channelName,
  ddrType,
  packageVerificationSubId
}) => ({
  type: OPEN_RESULT_PACKAGE_PAGE_INFO,
  verificationId,
  designID,
  name,
  packageVerificationId,
  channelName,
  ddrType,
  packageVerificationSubId
});

export const openCardResultPageInfo = ({
  verificationId,
  verificationSubId,
  designSubId,
  designID,
  interfaces,
  channelId,
  channelName,
  ddrType }) => ({
    type: OPEN_RESULT_CARD_PAGE_INFO,
    verificationId,
    verificationSubId,
    designSubId,
    designID,
    interfaces,
    channelId,
    channelName,
    ddrType
  })

export const updateResultType = (resultType) => ({
  type: UPDATE_RESULT_TYPE,
  resultType
})

export const updateSelectPatterns = (selectPatterns) => ({
  type: UPDATE_SELECT_PATTERNS,
  selectPatterns
})

export const updateResultExists = (list) => ({
  type: UPDATE_RESULT_EXISTS,
  list
})