import CeLayerMaker from './CeLayerMaker';
import CeLayerBase from './CeLayerBase';
import CeLayerSilkScreen from './CeLayerSilkScreen';
import FutprtPartPad from './FutprtPartPad';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import CeGeomHolder from '../geometry/CeGeomHolder';
import StringList from '../utility/StringList';

var FutprtMetalLayer = function (name, position) {
  this.mName = name;
  this.mGeomList = null;
  this.mType = position;
  this.mPadList = [];
  this.mLogicLayers = [];
};

FutprtMetalLayer.prototype.GetName = function () {
  return this.mName;
}

FutprtMetalLayer.prototype.GetPosition = function () {
  return this.mType;
}

FutprtMetalLayer.prototype.GetSilkScreenLayer = function () {
  for (var i = 0; i < this.mLogicLayers.length; i++) {
    var type = this.mLogicLayers[i].GetType();
    if (type == CeLayerBase.CeLayerType.SILK_SCREEN)
      return this.mLogicLayers[i];
  }
  return null;
}

FutprtMetalLayer.prototype.SetPadList = function (padList) {
  this.mPadList = padList;
}

FutprtMetalLayer.prototype.IsPadExistied = function (padId) {
  for (var i = 0; i < this.mPadList.length; i++) {
    if (padId == this.mPadList[i].mPadID)
      return true;
  }
  return false;
}

FutprtMetalLayer.prototype.AddPad = function (padId, templatePad) {
  var pad = new FutprtPartPad(padId, templatePad);
  this.mPadList.push(pad);
  return pad;
}

FutprtMetalLayer.prototype.GetPadsOutline = function (futprtSymbol) {
  var padGCPolygons = [];
  for (var i = 0; i < this.mPadList.length; i++) {
    this.mPadList[i].ToGCPolygon(padGCPolygons, futprtSymbol);
  }
  return padGCPolygons;
}

FutprtMetalLayer.prototype.GetPadGeometries = function (futprtSymbol) {
  var padGeoms = [];
  for (var i = 0; i < this.mPadList.length; i++) {
    // seems the GetPadGeometry call is problematic as the rotation
    // handling of rectangle is not implemented correctly
    this.mPadList[i].ToGCPolygon(padGeoms, futprtSymbol);
  }
  return padGeoms;
}

FutprtMetalLayer.prototype.AddGeometry = function (geom) {
  if (this.mGeomList == null)
    this.mGeomList = new CeGeomHolder();
  this.mGeomList.push(geom);
}

FutprtMetalLayer.prototype.AddGeometryOnSilkScreen = function (geom) {
  var silkscreen = this.GetSilkScreenLayer();
  if (silkscreen == null) {
    silkscreen = new CeLayerSilkScreen();
    this.mLogicLayers.push(silkscreen);
  }
  silkscreen.AddGeometry(-1, geom, null);
}

FutprtMetalLayer.prototype.GetLogicLayer = function (lyrName, lyrType, createIt) {

  var ceLyrType = CeLayerBase.CeLayerType.StringToLayerType(lyrType);
  for (var i = 0; i < this.mLogicLayers.length; i++) {
    if (lyrName == this.mLogicLayers[i].GetName() &&
      ceLyrType == this.mLogicLayers[i].GetType())
      return this.mLogicLayers[i];
  }
  if (createIt == true) {
    var logicLayer = CeLayerMaker.CreateLogicLayer(ceLyrType);
    logicLayer.SetName(lyrName);
    this.mLogicLayers.push(logicLayer);
    return logicLayer;
  }
  return null;
}

FutprtMetalLayer.prototype.AddGeomOnLogicLayer = function (lyrName, lyrType, geom) {
  var logicLayer = this.GetLogicLayer(lyrName, lyrType, true);
  logicLayer.AddGeometry(-1, geom, null);
}

FutprtMetalLayer.prototype.WriteIntoIODataNode = function (myBlock) {
  var nameType = new StringList();
  nameType.push(this.mName);
  nameType.push(this.mType);
  myBlock.AddDataItem("NameType", nameType);

  if (this.mGeomList != null) {
    var geomBlock = new CeIODataBlock("GeometryList");
    this.mGeomList.WriteIntoIODataNode(geomBlock);
    myBlock.AddBlockNode(geomBlock);
  }

  for (var i = 0; i < this.mPadList.length; i++) {
    var padBlock = new CeIODataBlock("PartPad");
    this.mPadList[i].WriteIntoIODataNode(padBlock);
    myBlock.AddBlockNode(padBlock);
  }
  for (var i = 0; i < this.mLogicLayers.length; i++) {
    var layerBlock = new CeIODataBlock("LogicLayer");
    this.mLogicLayers[i].WriteIntoIODataNode(layerBlock);
    myBlock.AddBlockNode(layerBlock);
  }
}

FutprtMetalLayer.prototype.ReadFromIODataNode = function (myBlock) {
  var nameType = myBlock.GetItemStringList("NameType");
  if (nameType != null) {
    if (nameType.size() > 0)
      this.mName = nameType.get(0);
    if (nameType.size() > 1)
      this.mType = nameType.get(1);
  }

  var geomBlock = myBlock.GetSubBlock("GeometryList");
  if (geomBlock != null) {
    if (this.mGeomList == null)
      this.mGeomList = new CeGeomHolder();
    this.mGeomList.ReadFromIODataNode(geomBlock);
  }

  var padBlocks = myBlock.GetSubBlocksOfSameName("PartPad");
  for (var i = 0; i < padBlocks.length; i++) {
    var partPad = new FutprtPartPad();
    partPad.ReadFromIODataNode(padBlocks[i]);
    this.mPadList.push(partPad);
  }

  var layerBlocks = myBlock.GetSubBlocksOfSameName("LogicLayer");
  for (var i = 0; i < layerBlocks.length; i++) {
    var type = layerBlocks[i].GetItemString("Type", CeLayerBase.CeLayerType.Unknown.toString());
    var logicLayer = CeLayerMaker.CreateLogicLayer(type);
    if (logicLayer != null) {
      logicLayer.ReadFromIODataNode(layerBlocks[i]);
      this.mLogicLayers.push(logicLayer);
    }
  }
}

export default FutprtMetalLayer;