import { TOUCHSTONE, SOCKET } from "../../../constants/libraryConstants";
import { getSignalPinTypeByNet } from "../connectorHelper";
import { getTextWidth } from "../getTextWidth";
import { PackagePin } from "../packageHelper";
import { strFormatChange } from "../stringHelper";
import { getPortNumberFromFileSuffix } from "../touchstoneHelper";
import { ANDES_V2 } from '@/constants/pageType';
import { getDefaultIndex } from '../setDefaultName';
import { N_PORT, getVCSignalName } from "../../VirtualComponent";
import { CPHY } from "../../PCBHelper/constants";
import { PACKAGE as DESIGN_PACKAGE } from "../../../constants/designType";

const MODEL_LIST = ["Package", "Connector", "Cable"];

function clearPairsPortByFile({ _pinList, _pairs, selectFile, modelType, product }) {
  for (let item of _pairs) {
    if (item.libraryId === selectFile.libraryId && (item.modelKey === selectFile.modelKey || (modelType === "DIE" && product === ANDES_V2))) {
      item.libraryId = "";
      item.subckt = "";
      item.modelKey = "";
      item.node = "";
    }
  }

  for (let item of _pinList) {
    for (const type of ["pinLeft", "pinRight", "pinTermination"]) {
      if (item[type] && item[type].length) {
        item[type].forEach(pin => {
          if (pin.pinLibraryId === selectFile.libraryId && (pin.pinModelKey === selectFile.modelKey || (modelType === "DIE" && product === ANDES_V2))) {
            pin.pinLibraryId = "";
            pin.pinLibraryId = "";
            pin.pinSubckt = "";
            pin.pinModelKey = "";
            pin.pinValue = "";
          }
        })
      }
    }
  }

  return {
    _pinList,
    _pairs,
  }
}

function getPortByType(pinItem, type) {
  if (!type && pinItem) {
    return {
      port: pinItem.pinValue,
      portLibraryId: pinItem.pinLibraryId,
      portModelKey: pinItem.pinModelKey
    }
  }
  if (pinItem[type] && pinItem[type].length) {
    return {
      port: pinItem[type][0].pinValue,
      portLibraryId: pinItem[type][0].pinLibraryId,
      portModelKey: pinItem[type][0].pinModelKey
    }
  } else {
    return {
      port: "", portLibraryId: "", portModelKey: ""
    }
  }
}

function getSelectedPorts(libraryId, pinList, modelKey) {
  let selectedPorts = [];
  for (let item of pinList) {
    if (item.pinLeft && item.pinLeft.length) {
      for (let pinLeftInfo of item.pinLeft) {
        if (pinLeftInfo.pinLibraryId === libraryId && pinLeftInfo.pinModelKey === modelKey) {
          selectedPorts.push(pinLeftInfo.pinValue)
        }
      }
    }
    if (item.pinRight && item.pinRight.length) {
      for (let pinRightInfo of item.pinRight) {
        if (pinRightInfo.pinLibraryId === libraryId && pinRightInfo.pinModelKey === modelKey) {
          selectedPorts.push(pinRightInfo.pinValue)
        }
      }
    }
  }
  return selectedPorts;
}

function getPkgDisplayPortList({ currentItem, type, searchValue, portsObj, selectFile = {}, pinList, modelType, product }) {
  if (!portsObj || Object.keys(portsObj).length === 0) {
    return [];
  }

  const selectFileId = selectFile.libraryId,
    modelKey = selectFile.modelKey;
  //current pin connected port libraryId
  const { port, portLibraryId, portModelKey } = getPortByType(currentItem, type);

  if (!selectFileId) {
    return [];
  }
  //find current libraryId ports
  let filePortObj = portsObj[selectFileId] ? portsObj[selectFileId] : [];
  let index = 0;
  // If the Type of the file is not passed
  // if (selectFile && selectFile.subckt) {
  //   let findIndex = filePortObj.findIndex(item => item.subckt === selectFile.subckt)
  //   if (findIndex > -1) { index = findIndex }
  // }
  let ports = filePortObj.length && filePortObj[index] ? filePortObj[index].ports || [] : [];

  if (selectFile.type === 'SPICE' || selectFile.type === 'spice') {
    const current = filePortObj.find(item => item.subckt === selectFile.subckt)
    ports = current ? current.ports || [] : [];
  }

  if (product === ANDES_V2 && modelType === "DIE" && ports.length < 3) {
    const currentPin = currentItem.pin.replace("_u", "");
    let otherPin = null;
    for (const pinInfo of pinList) {
      const left = pinInfo.pinLeft.find(item => item.pin.replace("_u", "") === currentPin && item.pin !== currentItem.pin);
      const right = pinInfo.pinRight.find(item => item.pin === currentPin && item.pin !== currentItem.pin);
      if (left || right) {
        otherPin = left || right;
        break;
      }
    }

    return ports.map(item => {
      if ((port && portLibraryId === selectFileId && item.port === port && portModelKey === modelKey)
        || (otherPin && otherPin.pinLibraryId === selectFileId && item.port === otherPin.pinValue && otherPin.pinModelKey === modelKey)) {
        return { ...item, libraryId: selectFileId, modelKey, select: true }
      } else {
        return { ...item, libraryId: selectFileId, modelKey, select: false }
      }
    });
  }

  const selectedPorts = getSelectedPorts(selectFileId, pinList, modelKey);

  //Add escape characters to special characters
  const value = strFormatChange(searchValue);
  const reg = new RegExp(`(${value})`, 'i');

  //sort ports
  let _portList = [], selectedList = [], currentPort = [], searchList = [], notSelectedList = [];
  ports.forEach(item => {
    if (portLibraryId === selectFileId && port && item.port === port && portModelKey === modelKey) {
      // selected port of current pin
      currentPort.push({ ...item, libraryId: selectFileId, modelKey, select: true });
    } else if (searchValue /* && (portLibraryId !== selectFileId || port !== searchValue) */ && item.info.match(reg)) {
      //   //searched ports
      searchList.push({ ...item, libraryId: selectFileId, modelKey, select: selectedPorts.includes(item.port) });
    } else if (selectedPorts.includes(item.port)) {
      //Ports that have been selected by other pins
      selectedList.push({ ...item, libraryId: selectFileId, modelKey, select: true });
    } else {
      //No selected ports
      notSelectedList.push({ ...item, libraryId: selectFileId, modelKey, select: false });
    }
  });

  const _searchSelectedList = searchList.filter(item => item.select);
  const _searchNotSelectedList = searchList.filter(item => !item.select);
  const _searchList = [..._searchNotSelectedList, ..._searchSelectedList];
  //current pin select port + searched ports + notSelected ports + selected ports
  _portList = [...currentPort, ..._searchList, ...notSelectedList, ...selectedList];
  return _portList;
}

function updatePkgPinPort({
  port,
  modelId,
  modelKey,
  pin,
  type,
  pinList,
  pairs,
  net,
  determineComp,
  component,
  subckt,
  modelType,
  product,
  files }) {
  let _pinList = JSON.parse(JSON.stringify(pinList)),
    _pairs = JSON.parse(JSON.stringify(pairs));
  const { pinListIndex, pinListIndexChild, pairIndex } = getPortIndexList({ pinList, pairs, pinInfo: { pin }, net, type, determineComp, component });

  const { _modelKey, newModel } = getNewModel({ port, modelKey, modelType, product, files, pairs, pin });
  if (pinListIndex > -1 && pinListIndexChild > -1 && _pinList[pinListIndex][type]) {
    _pinList[pinListIndex][type][pinListIndexChild].pinLibraryId = modelId;
    _pinList[pinListIndex][type][pinListIndexChild].pinValue = port;
    _pinList[pinListIndex][type][pinListIndexChild].pinModelKey = _modelKey;
    _pinList[pinListIndex][type][pinListIndexChild].pinSubckt = subckt;
  }

  if (pairIndex > -1) {
    _pairs[pairIndex].libraryId = modelId;
    _pairs[pairIndex].node = port;
    _pairs[pairIndex].modelKey = _modelKey;
    _pairs[pairIndex].subckt = subckt || "";
  }

  return { _pinList, _pairs, newModel }
}

function clearPkgPinsPortBySignal({
  pinList,
  pairs,
  signal,
  determinePinSame
}) {
  let clearPortPins = [];
  for (let item of pinList) {
    const { signal: _signal, pin } = getSignalAndPin(signal, determinePinSame)
    if (item.signal === _signal && (!pin || (pin && item.pinRight.find(item => item.pin === pin)))) {
      clearPortPins.push(...item.pinLeft.map(it => it.pin));
      clearPortPins.push(...item.pinRight.map(it => it.pin));
      item.pinLeft.forEach(it => {
        it.pinLibraryId = "";
        it.pinValue = "";
        it.pinModelKey = "";
        it.pinSubckt = "";
      });
      item.pinRight.forEach(it => {
        it.pinLibraryId = "";
        it.pinValue = "";
        it.pinModelKey = "";
        it.pinSubckt = "";
      })
    }
  }

  for (let item of pairs) {
    if (clearPortPins.includes(item.pin)) {
      item.libraryId = "";
      item.node = "";
      item.modelKey = "";
      item.subckt = "";
    }
  }

  return { pairs, pinList }
}

function filterUnusedModelFiles(models = [], pairs = []) {
  let modelMap = new Map();
  for (let item of pairs) {
    if (item.node) {
      modelMap.set(item.modelKey, {
        modelKey: item.modelKey.modelKey,
        id: item.libraryId
      })
    }
  }

  const newModels = []
  for (let item of models) {
    if (modelMap.get(item.modelKey) && modelMap.get(item.modelKey).id) {
      newModels.push(item)
      modelMap.delete(item.modelKey);
    }
  }
  return newModels;
}

function copyPinsPkgPorts({
  toSignal,
  fromSignal,
  pinList,
  pairs,
  copyModelKey,
  copyModelKey2,
  determineComp,
  models = [],
  multiPinDetection,
  toPin,
  fromPin,
  isRank,
  copyTerminationModelKey,
  terminationPairs,
  terminationFiles,
  modelType,
  product
}) {
  const signals = pinList.filter(item => item.signal === toSignal && ((!toPin) || (toPin && item.pinRight && item.pinRight.length && item.pinRight.find(item => item.pin === toPin))));
  const copySignals = pinList.filter(item => item.signal === fromSignal && ((!fromPin) || (fromPin && item.pinRight && item.pinRight.length && item.pinRight.find(item => item.pin === fromPin))));
  if (!signals.length || !copySignals.length) {
    return {
      pinList,
      pairs
    };
  }

  let usedMultiModelKey = false, newModels = [];
  if (signals.length > 1 && multiPinDetection) {
    //When the found pins are greater than two
    //The modelKey should be different
    usedMultiModelKey = true;
  }
  let currentSignalItemIndex = 0;

  for (let pinItem of pinList) {
    if (toSignal !== pinItem.signal || (toPin && !pinItem.pinRight.find(item => item.pin === toPin))) {
      continue;
    }
    const _copySignals = copySignals.filter(item => item.pinType === pinItem.pinType);
    const copySignal = _copySignals && _copySignals.length > 1 && _copySignals[currentSignalItemIndex] ? _copySignals[currentSignalItemIndex] : _copySignals[0];
    if (!copySignal) {
      continue;
    }
    let modelKey = pinItem.pinType === "negative" && copyModelKey2 ? copyModelKey2 : copyModelKey;
    if (usedMultiModelKey && currentSignalItemIndex > 0) {
      // When using the same port for different pins in signal, different modelKeys should be used
      const fileInfo = models.find(item => item.modelKey === modelKey)
      const newModelKey = getDefaultIndex(models.length, models.map(item => item.modelKey));
      newModels.push({
        fileName: fileInfo.fileName,
        libraryId: fileInfo.libraryId,
        type: fileInfo.type,
        subckt: fileInfo.subckt || "",
        modelKey: newModelKey,
        version: fileInfo.version
      })
      modelKey = newModelKey;
    }

    if (isRank && pinItem.pinLeft && pinItem.pinLeft.length > 1) {
      let _pinLeft = []
      for (let i = 0; i < pinItem.pinLeft.length; i++) {
        const copyInfo = copySignal.pinLeft.length && copySignal.pinLeft[i] ? copySignal.pinLeft[i] : {};
        _pinLeft.push({
          ...pinItem.pinLeft[i],
          pinLibraryId: copyInfo.pinLibraryId,
          pinValue: copyInfo.pinValue,
          pinModelKey: modelKey,
          pinSubckt: copyInfo.pinSubckt
        })
        const leftIndex = pairs.findIndex(item => item.pin === pinItem.pinLeft[i].pin && (!determineComp || (determineComp && item.component === pinItem.pinLeft[i].component)));
        pairs[leftIndex] = {
          ...pairs[leftIndex],
          libraryId: copyInfo.pinLibraryId,
          node: copyInfo.pinValue,
          modelKey: modelKey,
          subckt: copyInfo.pinSubckt || ""
        }
      }
      pinItem.pinLeft = _pinLeft;
    } else {
      const copyPinLeft = copySignal.pinLeft.length ? copySignal.pinLeft[0] : {};
      const { _modelKey, newModel } = getNewModel({
        port: copyPinLeft.pinValue,
        modelKey,
        modelType,
        product,
        files: [...models, ...newModels],
        pairs,
        copyModelKey: copyPinLeft.pinModelKey,
        pin: pinItem.pinLeft[0].pin
      })

      if (newModel) {
        newModels.push(newModel);
        modelKey = _modelKey;
      }
      pinItem.pinLeft[0] = {
        ...pinItem.pinLeft[0],
        pinLibraryId: copyPinLeft.pinLibraryId,
        pinValue: copyPinLeft.pinValue,
        pinModelKey: _modelKey,
        pinSubckt: copyPinLeft.pinSubckt
      }
      const leftIndex = pairs.findIndex(item => item.pin === pinItem.pinLeft[0].pin && (!determineComp || (determineComp && item.component === pinItem.pinLeft[0].component)));
      pairs[leftIndex] = {
        ...pairs[leftIndex],
        libraryId: copyPinLeft.pinLibraryId,
        node: copyPinLeft.pinValue,
        modelKey: _modelKey,
        subckt: copyPinLeft.pinSubckt || ""
      }
    }

    const copyPinRight = copySignal.pinRight.length ? copySignal.pinRight[0] : {};
    const { _modelKey, newModel } = getNewModel({
      port: copyPinRight.pinValue,
      modelKey,
      modelType,
      product,
      files: [...models, ...newModels],
      pairs,
      copyModelKey: copyPinRight.pinModelKey,
      pin: pinItem.pinRight[0].pin
    })

    if (newModel) {
      newModels.push(newModel);
    }
    pinItem.pinRight[0] = {
      ...pinItem.pinRight[0],
      pinLibraryId: copyPinRight.pinLibraryId,
      pinValue: copyPinRight.pinValue,
      pinModelKey: _modelKey,
      pinSubckt: copyPinRight.pinSubckt
    }

    const rightIndex = pairs.findIndex(item => item.pin === pinItem.pinRight[0].pin && (!determineComp || (determineComp && item.component === pinItem.pinRight[0].component)));

    pairs[rightIndex] = {
      ...pairs[rightIndex],
      libraryId: copyPinRight.pinLibraryId,
      node: copyPinRight.pinValue,
      modelKey: _modelKey,
      subckt: copyPinRight.pinSubckt || ""
    }

    if (pinItem.pinTermination && pinItem.pinTermination.length > 0) {
      const copyPinTermination = copySignal.pinTermination.length ? copySignal.pinTermination[0] : {};
      const { _modelKey, newModel } = getNewModel({
        port: copyPinTermination.pinValue,
        modelKey: copyTerminationModelKey,
        modelType,
        product,
        files: terminationFiles,
        pairs: terminationPairs
      })

      if (newModel) {
        terminationFiles.push(newModel);
      }
      pinItem.pinTermination[0] = {
        ...pinItem.pinTermination[0],
        pinLibraryId: copyPinTermination.pinLibraryId,
        pinValue: copyPinTermination.pinValue,
        pinModelKey: _modelKey,
        pinSubckt: copyPinTermination.pinSubckt,
        pinRes: copyPinTermination.pinRes
      }

      const index = terminationPairs.findIndex(item => item.pin === pinItem.pinTermination[0].pin);

      terminationPairs[index] = {
        ...terminationPairs[index],
        libraryId: copyPinTermination.pinLibraryId,
        node: copyPinTermination.pinValue,
        modelKey: _modelKey,
        subckt: copyPinTermination.pinSubckt || "",
        r: `${copyPinTermination.pinRes} ohm`
      }
    }
    currentSignalItemIndex++;
  }
  return { pairs, pinList, newModels, terminationPairs, terminationFiles }
}

function getPkgNetDisplay(signalInfo) {
  if (!signalInfo || !signalInfo.length) {
    return {};
  }
  if (signalInfo.length > 1) {
    const top = signalInfo.find(item => item.pinType === "positive");
    const bottom = signalInfo.find(item => item.pinType === "negative");

    return {
      topNet: top ? top.netDisplay : null,
      bottomNet: bottom ? bottom.netDisplay : null
    }
  } else {
    return {
      topNet: null,
      bottomNet: signalInfo[0] ? signalInfo[0].netDisplay : null
    }
  }
}

function copyPinPkgPortsBySignal({
  _copyGroups,
  pinList,
  pairs,
  fromSignal,
  determineComp,
  determinePinSame,
  isRank,
  terminationPairs = [],
  terminationFiles = [],
  modelType,
  product,
  models
}) {
  let _models = [...models],
    _pinList = [...pinList],
    _pairs = [...pairs],
    _terminationPairs = [...(terminationPairs || [])],
    _terminationFiles = [...(terminationFiles || [])];

  for (let i = 0; i < _copyGroups.length; i++) {
    for (let item of _copyGroups[i].signalMap) {
      const { signal: toSignal, pin: toPin } = getSignalAndPin(item.toSignal, determinePinSame)
      const { signal, pin: fromPin } = getSignalAndPin(item.fromSignal, determinePinSame)
      if (fromSignal && item.fromSignal !== fromSignal && signal !== fromSignal) {
        continue;
      }

      const info = copyPinsPkgPorts({
        toSignal: toSignal,
        fromSignal: signal,
        pinList: _pinList,
        pairs: _pairs,
        copyModelKey: _copyGroups[i].modelKey,
        copyModelKey2: _copyGroups[i].modelKey2,
        determineComp,
        toPin,
        fromPin,
        isRank,
        copyTerminationModelKey: _copyGroups[i].copyTerminationModelKey,
        terminationPairs: _terminationPairs,
        terminationFiles: _terminationFiles,
        modelType,
        product,
        models: _models
      })
      _pinList = info.pinList;
      _pairs = info.pairs;
      _terminationPairs = info.terminationPairs;
      _terminationFiles = info.terminationFiles;

      if (info.newModels && info.newModels.length) {
        _models = [..._models, ...info.newModels]
      }
    }
  }
  return {
    pinList: _pinList,
    pairs: _pairs,
    terminationPairs: _terminationPairs,
    terminationFiles: filterUnusedModelFiles(_terminationFiles, _terminationPairs),
    models: filterUnusedModelFiles(_models, _pairs)
  };
}

function getNewPkgModels(modelObj = {}) {
  let _models = [], newModelObj = [];
  const keyList = Object.keys(modelObj) && Object.keys(modelObj).filter(item => item !== 'allFiles');
  for (let key of keyList) {
    if (key === 'allFiles') { continue };
    const models = modelObj[key];
    const newModels = getPkgModels(models);
    newModelObj.push(newModels)
    const addModels = newModels.filter(item => !_models.find(it => it.libraryId === item.libraryId))
    _models = [..._models, ...addModels]
  }

  _models.forEach(item => {
    let circleNum = [], childrenList = [];
    newModelObj.forEach(modelList => {
      const findInfo = modelList.find(it => it.libraryId === item.libraryId && ((!item.subckt && !it.subckt) || (item.subckt === it.subckt)));
      if (findInfo && findInfo.children && findInfo.children.length) {
        const addFindInfoChild = findInfo.children.filter(it => !childrenList.find(child => child.libraryId === it.libraryId))
        childrenList = [...childrenList, ...addFindInfoChild]
        circleNum.push(findInfo.children.length.toString())
      } else {
        circleNum.push("0")
      }

    })
    item.children = childrenList;
    item.circleNum = circleNum;
  })
  return _models
}

function getPkgModels(models) {
  let _models = [];
  for (let i = 0; i < models.length; i++) {
    const model = models[i];
    let sameLibIndex = -1;
    if (model.type === TOUCHSTONE) {
      sameLibIndex = _models.findIndex(item => model.libraryId && item.libraryId === model.libraryId);
    }
    if (model.type === "SPICE") {
      sameLibIndex = _models.findIndex(item => model.libraryId && item.libraryId === model.libraryId && (model.subckt && model.subckt === item.subckt));
    }
    if (sameLibIndex > -1) {
      _models[sameLibIndex].children.push({
        fileName: model.fileName,
        type: model.type,
        libraryId: model.libraryId,
        subckt: model.subckt,
        modelKey: model.modelKey,
        version: model.version,
      })
    } else {
      _models.push({
        fileName: model.fileName,
        type: model.type,
        libraryId: model.libraryId,
        subckt: model.subckt,
        modelKey: model.modelKey,
        version: model.version,
        children: [{
          fileName: model.fileName,
          type: model.type,
          libraryId: model.libraryId,
          subckt: model.subckt,
          modelKey: model.modelKey,
          version: model.version,
        }]
      })
    }
  }

  let modelIndex = 1;
  _models.forEach(item => {
    item.children.forEach(it => {
      it.modelIndex = modelIndex;
      modelIndex += 1;
    })
  })

  return _models;
}

function getCPHYPkgSelectFile(signalPinList, files) {
  let selectFile = {};
  let modelKey = null, modelId = null, subcktName = null;
  const pinInfo = signalPinList[0] || {};
  let obj = findModelKey(pinInfo.pinLeft || [], pinInfo.pinRight || []);
  modelKey = obj.modelKey;
  modelId = obj.modelId;
  subcktName = obj.subckt;
  selectFile = findModelFile(modelKey, modelId, files, subcktName);
  return { selectFile: selectFile || {} }
}

function getPkgSelectFile(signalPinList, files, serdesType, product) {
  if (product === ANDES_V2 && serdesType === CPHY) {
    return getCPHYPkgSelectFile(signalPinList, files);
  }
  let selectFile = {}, selectFile2 = null;
  let modelKey = null, modelId = null, subcktName = null;
  let modelKey_n = null, modelId_n = null, subcktName_n = null;
  if (signalPinList.length > 1) {
    const positivePinInfo = signalPinList.find(item => item.pinType === "positive") || {};
    const negativePinInfo = signalPinList.find(item => item.pinType === "negative") || {};
    const pinLeft_p = positivePinInfo.pinLeft || [];
    const pinLeft_n = negativePinInfo.pinLeft || [];
    const pinRight_p = positivePinInfo.pinRight || [];
    const pinRight_n = negativePinInfo.pinRight || [];
    let obj = findModelKey(pinLeft_p, pinRight_p);
    modelKey = obj.modelKey;
    modelId = obj.modelId;
    subcktName = obj.subckt
    let obj_n = findModelKey(pinLeft_n, pinRight_n);
    modelKey_n = obj_n.modelKey;
    modelId_n = obj_n.modelId;
    subcktName_n = obj_n.subckt
  } else if (signalPinList.length === 1) {
    const positivePinInfo = signalPinList[0] || {};
    const pinLeft_p = positivePinInfo.pinLeft || [];
    const pinRight_p = positivePinInfo.pinRight || [];
    let obj = findModelKey(pinLeft_p, pinRight_p);
    modelKey = obj.modelKey;
    modelId = obj.modelId;
    subcktName = obj.subckt;
  }

  selectFile = findModelFile(modelKey, modelId, files, subcktName);

  if (modelId_n && modelKey_n && (!modelKey || modelKey !== modelKey_n)) {
    const _selectFile = findModelFile(modelKey_n, modelId_n, files, subcktName_n);
    if (modelKey) {
      selectFile2 = _selectFile
    } else {
      selectFile = _selectFile
    }
  }

  return { selectFile: selectFile || {}, selectFile2 };
}

function findModelKey(pinLeftList, pinRightList) {
  let modelId, modelKey, subckt;
  if (pinLeftList[0] && pinLeftList[0].pinValue) {
    modelId = pinLeftList[0].pinLibraryId;
    modelKey = pinLeftList[0].pinModelKey;
    subckt = pinLeftList[0].pinSubckt;
  } else if (pinRightList[0] && pinRightList[0].pinValue) {
    modelId = pinRightList[0].pinLibraryId;
    modelKey = pinRightList[0].pinModelKey;
    subckt = pinRightList[0].pinSubckt;
  }
  return { modelId, modelKey, subckt };
}

function findModelFile(modelKey, modelId, files, subckt) {
  if (modelId && modelKey) {
    const file = files.find(item => item.libraryId === modelId && item.modelKey === modelKey && ((!subckt || !item.subckt) || (subckt && item.subckt === subckt))) || {};
    const portNumber = file.fileName ? getPortNumberFromFileSuffix(file.fileName) : "";
    return {
      libraryId: file.libraryId,
      modelKey: file.modelKey,
      fileName: file.fileName,
      subckt: file.subckt || '',
      type: file.type,
      portNumber,
      version: file.version
    }
  }
  return null;
}

function savePinPortsBySelectedSignals({
  pinList,
  pairs,
  propsPinList,
  propsPairs,
  selectedSignals,
  copySignals,
  determineComp,
  determinePinSame,
  terminationPairs = [],
  propsTerminationPairs = []
}) {
  const allModifiedSignals = [...selectedSignals, ...copySignals];
  let _propsPinList = [...propsPinList], _propsPairs = [...propsPairs], _propsTerminationPairs = [...propsTerminationPairs];
  let allModifiedPins = [];
  for (let pin of _propsPinList) {
    const findSameInfo = allModifiedSignals.find(item => {
      const { signal, pin: modifiedSignalPin } = getSignalAndPin(item, determinePinSame)
      if (signal === pin.signal && (!modifiedSignalPin || !pin.pinRight || !pin.pinRight.length || (modifiedSignalPin && pin.pinRight.find(item => item.pin === modifiedSignalPin)))) {
        return true
      }
      return false
    })
    if (!findSameInfo) { continue }
    const findPin = pinList.find(item => item.signal === pin.signal && item.net === pin.net && (!item.pinRight || (item.pinRight && item.pinRight.length && pin.pinRight && pin.pinRight.length && item.pinRight[0].pin === pin.pinRight[0].pin)));
    pin.pinLeft = findPin.pinLeft;
    pin.pinRight = findPin.pinRight;
    allModifiedPins.push(...findPin.pinLeft.map(item => item.pin));
    allModifiedPins.push(...findPin.pinRight.map(item => item.pin));
    if (pin.pinTermination) {
      pin.pinTermination = findPin.pinTermination;
    }
  }

  for (let pin of _propsPairs) {
    if (!allModifiedPins.includes(pin.pin)) {
      continue;
    }

    const findPin = pairs.find(item => item.pin === pin.pin && (!determineComp || (determineComp && item.component === pin.component)));
    pin.node = findPin.node;
    pin.libraryId = findPin.libraryId;
    pin.modelKey = findPin.modelKey;
    pin.subckt = findPin.subckt;
  }

  if (propsTerminationPairs && propsTerminationPairs.length > 0) {
    for (let pin of _propsTerminationPairs) {
      if (!allModifiedPins.includes(pin.pin)) {
        continue;
      }

      const findPin = terminationPairs.find(item => item.pin === pin.pin);
      pin.node = findPin.node;
      pin.libraryId = findPin.libraryId;
      pin.modelKey = findPin.modelKey;
      pin.subckt = findPin.subckt;
    }
  }
  return {
    _pinList: _propsPinList,
    _pairs: _propsPairs,
    _terminationPairs: _propsTerminationPairs
  }
}

function getModelPinList({ pinList, modelInfoList }) {
  let _pinList = JSON.parse(JSON.stringify(pinList));

  let _modelInfoList = JSON.parse(JSON.stringify(modelInfoList));
  _modelInfoList = getSignalWidthByPinList(_modelInfoList);

  for (let pinItem of _pinList) {
    let _modelPinList = [];
    for (let modelItem of _modelInfoList) {
      const { modelPinList, modelType } = modelItem;
      if (!modelPinList || !modelPinList.length) { continue };
      const findPin = modelPinList.find(item => item.net === pinItem.net);
      if (!findPin) { continue }
      _modelPinList.push({ ...findPin, modelType })
    }
    pinItem.modelPinList = _modelPinList;
  }
  return _pinList;
}

function getSignalWidthByPinList(modelInfoList) {
  let _modelInfoList = modelInfoList.map(item => {
    const { modelPinList = [] } = item;
    if (!modelPinList.length) { return item }
    const sortPinList = modelPinList.sort((a, b) => { return b.signal.length - a.signal.length });
    const width = getTextWidth(sortPinList[0].signal, 12, 100);
    // modelPinList.forEach(item => )
    const _modelPinList = modelPinList.map(it => { return { ...it, width: width + 8 } })
    return { ...item, modelPinList: _modelPinList }
  })
  return _modelInfoList;
}

/* pins: [{ pin, net ,signal }] */
function getModelPinListByCompPins(pins, signals = [], type, pairs = []) {
  let pinList = [];
  for (let item of pins) {
    const findPin = pairs.find(it => it.pin === item.pin);
    const findPinDie = pairs.find(it => it.pin === `${item.pin}_u`);

    const display = type === "Package" ? "_die" : "_out";

    const leftPin = new PackagePin(item.pin, findPin),
      rightPin = new PackagePin(`${item.pin}_u`, findPinDie, `${item.pin}${display}`);
    const pinType = getSignalPinTypeByNet(signals, item.net, item.signal)
    pinList.push({
      pin: item.pin,
      net: item.net,
      netDisplay: item.net,
      signal: item.signal,
      signalDisplay: item.signal,
      pinLeft: [leftPin],
      pinRight: [rightPin],
      pinType
    })
  }
  return pinList;
}

function getModelPairsByCompPins(pins) {
  let pairs = [];
  for (let item of pins) {
    if (pairs.findIndex(pair => pair.pin === item.pin) < 0) {
      let pinR = { pin: `${item.pin}_u`, node: "", subckt: "", libraryId: "", modelKey: "" };
      let pinL = { pin: item.pin, node: "", subckt: "", libraryId: "", modelKey: "" };
      pairs = [...pairs, pinL, pinR];
    }
  }
  return pairs;
}

function getDefaultModelsObj({ pins, signals, newModelsObj, startModelType }) {
  let modelFilesObj = {}, modelInfoList = [], newModelFilesObj = {}, usedObj = {};

  if (newModelsObj && newModelsObj.length) {
    modelInfoList = newModelsObj.map((modelInfo, index) => {
      const { modelType, pairs, files } = modelInfo;
      const modelPinList = getModelPinListByCompPins(pins, signals, modelType, pairs)
      let key = '';
      if (!newModelFilesObj[modelType]) {
        usedObj[modelType] = startModelType === modelType ? 2 : 1;
        key = `${modelType}${usedObj[modelType]}`
        newModelFilesObj[modelType] = {
          allFiles: [...files],
          [key]: [...files]
        }
      } else {
        usedObj[modelType]++;
        key = `${modelType}${usedObj[modelType]}`;
        newModelFilesObj[modelType][key] = [...files];

        const addFiles = files.filter(item => !newModelFilesObj[modelType].allFiles.find(it => it.libraryId === item.libraryId && it.modelKey === item.modelKey))
        newModelFilesObj[modelType].allFiles = [...newModelFilesObj[modelType].allFiles, ...addFiles]
      }

      if (!modelFilesObj[modelType]) {
        modelFilesObj[modelType] = files
      } else {
        const addFiles = files.filter(item => !modelFilesObj[modelType].find(it => it.libraryId === item.libraryId))
        modelFilesObj[modelType] = [...modelFilesObj[modelType], ...addFiles]
      }

      const _pairs = getPairs(pairs, pins, 'moreModel')
      return { ...modelInfo, modelPinList, modelFileKey: key, pairs: _pairs }
    })
  }
  return {
    modelFilesObj,
    modelInfoList,
    newModelFilesObj
  }
}

function getPairs(prevPairs = [], pins, modelType) {
  let pairs = [];
  pins.forEach(item => {
    const findLPrev = prevPairs.find(it => it.pin === item.pin);
    const { pinName: rightPinSuffix } = getPinRightDisplay(modelType, item.pin);
    const findRPrev = prevPairs.find(it => it.pin === rightPinSuffix);

    if (pairs.findIndex(pair => pair.pin === item.pin) < 0) {
      let pinR = { pin: rightPinSuffix, node: "", subckt: "", libraryId: "", modelKey: "" };
      let pinL = { pin: item.pin, node: "", subckt: "", libraryId: "", modelKey: "" };
      if (findLPrev) {
        pinL = { ...pinL, ...findLPrev };
      }
      if (findRPrev) {
        pinR = { ...pinR, ...findRPrev };
      }
      pairs = [...pairs, pinL, pinR];
    }
  });
  return pairs;
}

function getModelsSelectList(newModelList) {
  let modelList = MODEL_LIST.filter(item => !newModelList.includes(item));
  if (newModelList.includes('Package')) {
    modelList = modelList.filter(item => !['Connector', 'Cable'].includes(item))
  }
  if (newModelList.includes('Connector') || newModelList.includes('Cable')) {
    modelList = modelList.filter(item => item !== "Package");
  }
  return modelList;
}

// board ---> package
// board ---> connector --> cable
// board ---> connector --> cable ---> connector ---> pcb ---> package
// board ---> connector --> cable ---> connector ---> pcb ---> connector ---> cable ---> connector ---> pcb ---> package (Not Supported)
const CONNECTOR = "Connector", PACKAGE = "Package", PCB = "PCB", CABLE = "Cable", PreLayout = 'PreLayout';
function getNewModelSelectList(index, modelInfoList, modelType) {
  const currentInfo = index > -1 ? modelInfoList[index] : { modelType };
  let modelList = []
  const lastInfo = modelInfoList.length > index + 1 ? modelInfoList[index + 1] : {};
  let menuList = [];
  switch (currentInfo.modelType) {
    case PACKAGE:
      modelList = [];
      break;
    case CONNECTOR:
      menuList = [CABLE, CONNECTOR, PCB];
      if (index > -1 && modelType === CONNECTOR) {
        menuList = [PCB]
      } else if (index > 0 && [CABLE, CONNECTOR].includes(modelInfoList[index - 1].modelType)) {
        menuList = [PCB]
      }
      break;
    case CABLE:
      menuList = [CONNECTOR, PCB];
      break;
    case PCB:
      if (index < 0) {
        menuList = [CONNECTOR, CABLE, PCB, PACKAGE];
        if (lastInfo.modelType === CABLE) {
          menuList = [CONNECTOR]
        }
        break;
      }
      menuList = [PACKAGE];
      break;
    default:
      menuList = [CONNECTOR, PCB, PACKAGE];
      if (lastInfo.modelType === CABLE) {
        menuList = [CONNECTOR]
      }
      break;
  }

  const findIndex = menuList.findIndex(item => item === lastInfo.modelType)
  modelList = findIndex > -1 ? menuList.slice(0, findIndex) : menuList;
  return modelList
}

// When there are fewer file ports than pins
// 1  =>  1  3
// 2      2  4
// 3
// 4
function getPortIndex(type, rightIndex, leftIndex, length, pinLength, rankNumber, isParallel) {
  let portRIndex, portLIndex, indexAdd, indexLeftAdd, indexRightAdd;
  if (isParallel) {
    return {
      portRIndex: -1,
      portLIndex: leftIndex,
      indexAdd: 1,
      indexLeftAdd: 1,
      indexRightAdd: -1
    }
  }
  switch (type) {
    case 1:
      portLIndex = leftIndex;
      portRIndex = rightIndex + rankNumber;
      indexAdd = rankNumber + 1;
      indexLeftAdd = rankNumber + 1;
      indexRightAdd = rankNumber + 1;
      break;
    case 2:
      portLIndex = leftIndex;
      portRIndex = pinLength + rightIndex;
      indexAdd = 1;
      indexLeftAdd = rankNumber;
      indexRightAdd = 1;
      break;
    case 3:
      portLIndex = leftIndex;
      portRIndex = length + pinLength - 1 - rightIndex;
      indexAdd = 1;
      indexLeftAdd = rankNumber;
      indexRightAdd = 1;
      break;
    default:
      break;
  }
  return { portRIndex, portLIndex, indexAdd, indexLeftAdd, indexRightAdd }
}

function getPinRightDisplay(modelType, pin) {
  let pinName = pin, display = pin;
  switch (modelType) {
    case PCB:
      pinName = `${pin}_pcb`;
      display = "";
      break;
    case SOCKET:
      pinName = `${pin}_out`;
      display = `${pin}_out`;
      break;
    case N_PORT:
      const signal = getVCSignalName(pin);
      const suffix = pin.match(/_1$/) ? "_2" : "_1";
      pinName = `${signal}${suffix}`;
      display = `${signal}${suffix}`;
      break;
    default:
      pinName = `${pin}_u`;
      display = `${pin}_out`;
      break;
  }
  return {
    pinName,
    display
  }
}

let Connector = "Connector";
function getModelTypePins(pairs, pins, modelType, signals, product, rank, termination = {}) {
  let _pinList = [];
  if (modelType === Connector || modelType === PCB || modelType === SOCKET) {
    pins.forEach(item => {
      if (item.pin) {
        const pin = pairs.find(it => it.pin === item.pin);
        const index = _pinList.findIndex(p => p.pinL === item.pin);
        if (index >= 0) {
          _pinList[index].signalDisplay = `${_pinList[index].signalDisplay}, ${item.signal}`;
        } else if (pin) {
          const pinType = product === ANDES_V2 ? getSignalPinTypeByNet(signals, item.net, item.signal) : null;
          const pinData = {
            net: item.net,
            signal: item.signal,
            signalDisplay: item.signal,
            netDisplay: item.net,
            pinType
          }

          const pinLeftDisplay = modelType === SOCKET ? `${item.pin}_in` : item.pin;
          pinData['pinLeft'] = [new PackagePin(item.pin, pin, pinLeftDisplay)];
          const right = [];
          const { pinName, display } = getPinRightDisplay(modelType, item.pin);
          const pinOut = pairs.find(it => it.pin === pinName);
          right.push(new PackagePin(pinName, pinOut, display));
          pinData['pinRight'] = [...right];
          _pinList.push(pinData)
        }
      }
    });
  } else if (modelType === PreLayout) {
    pins.forEach(item => {
      if (item.pin) {
        const pin = pairs.find(it => it.pin === item.pin && it.component === item.leftComp);
        const rightPin = pairs.find(it => it.pin === item.rightPin && it.component === item.rightComp)
        const index = _pinList.findIndex(p => p.pinR === item.pin);
        if (index >= 0) {
          _pinList[index].signalDisplay = `${_pinList[index].signalDisplay}, ${item.signal}`;
        } else if (pin) {
          const pinType = product === ANDES_V2 ? getSignalPinTypeByNet(signals, item.net, item.signal) : null;
          const pinData = {
            net: item.net,
            signal: item.signal,
            signalDisplay: item.signal,
            signalGroup: item.signal_group,
            netDisplay: item.net,
            pinType
          }
          pinData['pinRight'] = [new PackagePin(item.rightPin, rightPin, item.rightPin, item.rightComp)];
          pinData['pinLeft'] = [new PackagePin(item.pin, pin, item.pin, item.leftComp)]
          _pinList.push(pinData)
        }

      }
    })
  } else if (modelType === N_PORT) {
    pins.forEach(item => {
      if (item.pin) {
        const pin = pairs.find(it => it.pin === item.pin);
        const index = _pinList.findIndex(p => p.signal === item.signal);
        if (index > -1) {
          if (item.type === "out") {
            _pinList[index]['pinRight'] = [new PackagePin(item.pin, pin)];
          }
          if (item.type === "in") {
            _pinList[index]['pinLeft'] = [new PackagePin(item.pin, pin)];
          }
        } else {
          const pinData = {
            net: item.net,
            signal: item.signal,
            signalDisplay: item.signal,
            netDisplay: item.net
          }
          if (item.type === "out") {
            pinData['pinRight'] = [new PackagePin(item.pin, pin)];
          }
          if (item.type === "in") {
            pinData['pinLeft'] = [new PackagePin(item.pin, pin)];
          }
          _pinList.push(pinData)
        }

      }
    })
  } else {
    pins.forEach(item => {
      if (item.pin) {
        const pin = pairs.find(it => it.pin === item.pin);
        const index = _pinList.findIndex(p => p.pinR === item.pin);
        if (index >= 0) {
          _pinList[index].signalDisplay = `${_pinList[index].signalDisplay}, ${item.signal}`;
        } else if (pin) {
          const pinType = product === ANDES_V2 ? getSignalPinTypeByNet(signals, item.net, item.signal) : null;
          const pinData = {
            net: item.net,
            signal: item.signal,
            signalDisplay: item.signal,
            netDisplay: item.net,
            pinType
          }
          pinData['pinRight'] = [new PackagePin(item.pin, pin)];

          const left = [];
          if (rank && rank.number) {
            for (let i = 1; i <= Number(rank.number); i++) {
              const pinDieName = i === 1 ? `${item.pin}_u` : `${item.pin}_u_${i}`;
              const pin = pairs.find(it => it.pin === pinDieName);
              left.push(new PackagePin(pinDieName, pin, `${item.pin}_die_${i}`))
            }
          } else {
            const pinDieName = `${item.pin}_u`;
            const pin = pairs.find(it => it.pin === pinDieName);
            left.push(new PackagePin(pinDieName, pin, `${item.pin}_die`))
          }
          pinData['pinLeft'] = [...left];

          if (modelType === "DIE" && product === ANDES_V2) {
            const { pairs = [] } = termination;
            const pair = pairs.find(it => it.pin === item.pin);
            pinData['pinTermination'] = [new PackagePin(item.pin, pair)];
          }
          _pinList.push(pinData)
        }
      }
    });
  }

  return _pinList;
}

function getNewPairs(pins, type, portModelType, currentPorts, selectFileInfo, direction, determineComp, rank, isParallel) {
  let newPairs = [];
  let length = pins.length;
  let pinIndex = 0, leftIndex = 0, rightIndex = 0;
  const rankIndex = rank && rank.number ? Number(rank.number) : 1;
  const number = rankIndex + 1;
  let usedPinLength = pins.length * rankIndex

  if (!isParallel) {
    if (length * number > currentPorts.length) {
      length = Math.ceil(currentPorts.length / number);
      usedPinLength = length * rankIndex
    } else if (length * number < currentPorts.length) {
      usedPinLength = Math.ceil((currentPorts.length / number) * rankIndex)
    }
  }

  let usedPorts = [];

  for (let item of pins) {
    if (newPairs.findIndex(pair => pair.pin === item.pin) < 0) {
      let pinName = '';
      if (determineComp) {
        pinName = item.rightPin
      } else {
        const pinInfo = getPinRightDisplay(portModelType, item.pin);
        pinName = pinInfo.pinName
      }

      const { portRIndex, portLIndex, indexLeftAdd, indexRightAdd } = getPortIndex(type, rightIndex, leftIndex, length, usedPinLength, rankIndex, isParallel);

      let portR = pinIndex < length && currentPorts[portRIndex] ? currentPorts[portRIndex] : {};

      if (usedPorts.includes(portR.port)) {
        portR = {};
      } else {
        usedPorts.push(portR.port)
      }
      let pinR = {
        pin: direction === 'opposite' ? item.pin : pinName,
        node: portR && portR.port ? portR.port : "",
        subckt: portR && portR.port && selectFileInfo.subckt ? selectFileInfo.subckt : "",
        libraryId: portR && portR.port && selectFileInfo.libraryId ? selectFileInfo.libraryId : "",
        modelKey: portR && portR.port && selectFileInfo.modelKey ? selectFileInfo.modelKey : ""
      };

      let pinList = [], nodeIndex = portLIndex;
      for (let i = 1; i <= rankIndex; i++) {
        let _pinName = direction === 'opposite' ? pinName : item.pin;
        _pinName = i > 0 ? i === 1 ? `${_pinName}` : `${_pinName}_${i}` : _pinName;
        let portL = pinIndex < length && currentPorts[nodeIndex] ? currentPorts[nodeIndex] : {};

        if (usedPorts.includes(portL.port)) {
          portL = {};
        } else {
          usedPorts.push(portL.port)
        }
        pinList.push({
          pin: _pinName,
          node: portL && portL.port ? portL.port : "",
          subckt: portL && portL.port && selectFileInfo.subckt ? selectFileInfo.subckt : "",
          libraryId: portL && portL.port && selectFileInfo.libraryId ? selectFileInfo.libraryId : "",
          modelKey: portL && portL.port && selectFileInfo.modelKey ? selectFileInfo.modelKey : ""
        })
        nodeIndex++;
      }

      if (determineComp) {
        pinR.component = item.rightComp;
        pinList.forEach(it => it.component = item.leftComp)
      }
      newPairs = [...newPairs, ...pinList, pinR];
      rightIndex = rightIndex + indexRightAdd;
      leftIndex = leftIndex + indexLeftAdd;
      pinIndex = pinIndex + 1
    }
  }
  return newPairs
}

function updateSelectSignalDefaultValue({ portsObj, selectFileId, currentModelInfo, pins, type, selectFileInfo, signals, portModelType, lastPairs, selectedSignals, direction, determineComp, determinePinSame, rank, isParallel }) {
  let _currentModelInfo = JSON.parse(JSON.stringify(currentModelInfo))
  const { modelType } = _currentModelInfo;
  let filePortObj = portsObj[selectFileId] ? portsObj[selectFileId] : [];
  let currentPorts = filePortObj.length && filePortObj[0] ? filePortObj[0].ports || [] : [];

  let filterPinList = []
  for (let selectSignal of selectedSignals) {
    const { signal: _signal, pin } = getSignalAndPin(selectSignal, determinePinSame)
    const findPins = pins.filter((it) => it.signal === _signal && (!pin || pin === it.pin));
    if (findPins) {
      filterPinList = [...filterPinList, ...findPins]
    }
  }

  let newPairs = getNewPairs(filterPinList, type, portModelType, currentPorts, selectFileInfo, direction, determineComp, rank, isParallel);

  let _newPairs = lastPairs.map(item => {
    const findInfo = newPairs.find(it => it.pin === item.pin && (!determineComp || (determineComp && it.component === item.component)));
    if (findInfo) {
      return { ...findInfo }
    }
    return { ...item }
  })

  const modelPinList = getModelPinListByCompPins(pins, signals, modelType, _newPairs)
  const new_files = [selectFileInfo];
  _currentModelInfo = { ..._currentModelInfo, modelPinList, files: new_files, pairs: _newPairs }
  return _currentModelInfo;
}

function getDefaultPortSetting({ portsObj, selectFileId, currentModelInfo, pins, type, selectFileInfo, signals, portModelType, lastPairs, direction, determineComp, rank, isParallel }) {
  let _currentModelInfo = JSON.parse(JSON.stringify(currentModelInfo))
  const { modelType } = _currentModelInfo;
  let filePortObj = portsObj[selectFileId] ? portsObj[selectFileId] : [];
  let index = 0;

  if (selectFileInfo.subckt && filePortObj.length) {
    const findIndex = filePortObj.findIndex(item => item.subckt === selectFileInfo.subckt)
    if (findIndex > -1) {
      index = findIndex
    }
  }

  let currentPorts = filePortObj.length && filePortObj[index] ? filePortObj[index].ports || [] : [];

  let newPairs = getNewPairs(pins, type, portModelType, currentPorts, selectFileInfo, direction, determineComp, rank, isParallel)
  const modelPinList = getModelPinListByCompPins(pins, signals, modelType, newPairs)
  const new_files = [selectFileInfo];
  _currentModelInfo = { ..._currentModelInfo, modelPinList, files: new_files, pairs: newPairs }
  return _currentModelInfo
}

const FILE_TYPE_LIST = [TOUCHSTONE, 'EBD'];
function clearPairsPortsByType(params = {}) {
  const { clearPinList, clearPairs, delFile, newFile, clearTerminationPairs } = params;

  if (!delFile && !newFile) {
    clearPinList.forEach(item => {
      for (const key of ["pinLeft", "pinRight", "pinTermination"]) {
        item[key] && item[key].forEach(pinInfo => {
          //clear delFile selected nodes
          pinInfo.pinValue = "";
          pinInfo.pinLibraryId = "";
          pinInfo.pinSubckt = "";
          pinInfo.pinModelKey = "";
        })
      }
    })

    clearPairs.forEach(item => {
      item.node = "";
      item.libraryId = "";
      item.subckt = "";
      item.modelKey = "";
    })

    clearTerminationPairs && clearTerminationPairs.forEach(item => {
      item.node = "";
      item.libraryId = "";
      item.subckt = "";
      item.modelKey = "";
    })
  } else if (delFile) {
    clearPairs.forEach(item => {
      if (delFile.libraryId === item.libraryId
        && (FILE_TYPE_LIST.includes(delFile.type) || (delFile.type === 'SPICE' && item.subckt && delFile.subckt === item.subckt))) {
        //clear delFile selected nodes
        item.node = "";
        item.libraryId = "";
        item.subckt = "";
        item.modelKey = "";
      }
    })

    clearPinList.forEach(item => {
      for (const key of ["pinLeft", "pinRight", "pinTermination"]) {
        item[key] && item[key].forEach(pinInfo => {
          if (delFile.libraryId === pinInfo.pinLibraryId
            && (FILE_TYPE_LIST.includes(delFile.type) || (delFile.type === 'SPICE' && pinInfo.pinSubckt && (delFile.pinSubckt === pinInfo.pinSubckt || delFile.subckt === pinInfo.pinSubckt)))) {
            //clear delFile selected nodes
            pinInfo.pinValue = "";
            pinInfo.pinLibraryId = "";
            pinInfo.pinSubckt = "";
            pinInfo.pinModelKey = "";
          }
        })
      }
    })
  }

  return { clearPairs, clearPinList, clearTerminationPairs }
}

function getModelTitle(modelType, componentName, type, designType) {
  let leftName, rightName;
  switch (modelType) {
    case PCB:
      leftName = "BGA";
      rightName = "PCB";
      break;
    case PreLayout:
      if (designType === DESIGN_PACKAGE) {
        leftName = "DIE";
        rightName = "BGA";
      } else if (type === CPHY) {
        leftName = "U1";
        rightName = "J1";
      } else {
        leftName = "U1";
        rightName = "U2";
      }
      break;
    case CONNECTOR:
      leftName = componentName;
      rightName = "Connector";
      break;
    default: break;
  }
  return { leftName, rightName }
}

function getSignalAndPin(info, determinePinSame) {
  let pin = '';
  if (!determinePinSame) { return { signal: info } }
  const [signal, pinInfo] = info.split(" (");
  if (pinInfo) {
    const splitInfo = pinInfo.split(')')
    if (splitInfo && splitInfo.length) {
      pin = splitInfo[0]
    }
  }
  return { signal, pin }
}

function getMultiPinInfo(list, info, key) {
  let name = "";
  const findSameSignalPin = list.filter(item => item.signal === info.signal);
  if (findSameSignalPin.length > 1) {
    name = key === 'pinRight' ? `${info.signal} (${info.pinRight[0].pin})` : `${info.signal} (${info.pin})`
  } else {
    name = info.signal;
  }
  return name
}

function getPortModelKey({ port, modelKey, modelType, product, files, pairs, pin }) {
  if (product === ANDES_V2 && modelType === "DIE" && port) {
    const isSelect = pairs.find(pair => pair.node === port && pair.modelKey === modelKey && pair.pin !== pin);
    if (isSelect) {
      const file = files.find(item => item.modelKey === modelKey) || {};
      const sameModelKeys = files.filter(item => file.libraryId === item.libraryId && item.modelKey !== modelKey).map(it => it.modelKey);
      if (sameModelKeys.length) {
        const findKey = sameModelKeys.find(key => {
          const findPair = pairs.find(pair => pair.node === port && pair.modelKey === key);
          if (!findPair) return true;
          return false;
        })
        if (findKey) return findKey;
      }
      const modelKeys = files.map(item => item.modelKey);
      const index = modelKeys.length || "1";
      const newModelKey = getDefaultIndex(index, modelKeys);
      return newModelKey;
    }
  }

  return modelKey;
}

// type: pinRight / pinLeft / pinTermination
function getPortIndexList({ pinList, pairs, pinInfo, net, type, determineComp, component }) {
  const indexList = pinList.map((item, i) => { if (item.net === net) { return i }; return null }).filter(item => item !== null);
  let index = -1, index_child = -1;
  for (const item of indexList) {
    if (!pinList[item] || !pinList[item][type]) {
      continue;
    }
    const _index_child = pinList[item][type].findIndex(it => it.pin === pinInfo.pin && (!determineComp || it.component === component));
    if (_index_child > -1) {
      index = item;
      index_child = _index_child;
      break;
    }
  }

  const pairIndex = pairs.findIndex(item => item.pin === pinInfo.pin && (!determineComp || item.component === component));
  return { pinListIndex: index, pinListIndexChild: index_child, pairIndex }
}

function getNewModel({ port, modelKey, modelType, product, files, pairs, copyModelKey, pin }) {
  if (!modelKey) return { _modelKey: modelKey, newModel: null };
  const newModelKey = getPortModelKey({ port, modelKey, modelType, product, files, pairs, pin });

  let newModel = null, _modelKey = modelKey
  if (newModelKey !== modelKey || !files.find(item => item.modelKey === newModelKey)) {
    const fileInfo = files.find(item => item.modelKey === (copyModelKey || modelKey));
    if (fileInfo) {
      _modelKey = newModelKey;
      newModel = {
        fileName: fileInfo.fileName,
        libraryId: fileInfo.libraryId,
        type: fileInfo.type,
        subckt: fileInfo.subckt || "",
        modelKey: _modelKey,
        version: fileInfo.version
      }
    }
  }

  return { _modelKey, newModel };
}

function getAutoFillNode({ pinList, files, pairs, selectFile, ports, type = "pinTermination" }) {
  function getModelKey(files) {
    const modelKeys = files.map(item => item.modelKey);
    const index = modelKeys.length || "1";
    const newModelKey = getDefaultIndex(index, modelKeys)
    return newModelKey;
  }
  const port = ports[0].port;
  const { libraryId, subckt, modelKey } = selectFile;
  let _pinList = JSON.parse(JSON.stringify(pinList)), _files = JSON.parse(JSON.stringify(files)),
    _pairs = JSON.parse(JSON.stringify(pairs)), isUseSelectFile = false, _modelKey = modelKey;
  _pairs.forEach(pin => {
    if (!pin.node) {
      if (isUseSelectFile) {
        _modelKey = getModelKey(_files);
        _files.push({
          ...selectFile,
          modelKey: _modelKey
        });
      }
      pin.node = port;
      pin.libraryId = libraryId;
      pin.subckt = subckt;
      pin.modelKey = _modelKey;
      isUseSelectFile = true;
    }
  })

  _pinList.forEach(pinItem => {
    if (pinItem[type] && pinItem[type].length) {
      pinItem[type].forEach(pin => {
        if (!pin.pinValue) {
          const pairInfo = _pairs.find(p => p.pin === pin.pin);
          pin.pinValue = pairInfo.node;
          pin.pinLibraryId = pairInfo.libraryId;
          pin.pinSubckt = pairInfo.subckt;
          pin.pinModelKey = pairInfo.modelKey;
        }
      })
    }
  })

  return { _pinList, _files, _pairs };
}

export {
  clearPairsPortByFile,
  getPkgDisplayPortList,
  updatePkgPinPort,
  clearPkgPinsPortBySignal,
  filterUnusedModelFiles,
  copyPinsPkgPorts,
  getPkgNetDisplay,
  copyPinPkgPortsBySignal,
  getPkgModels,
  getPkgSelectFile,
  savePinPortsBySelectedSignals,
  getModelPinList,
  getModelPinListByCompPins,
  getModelPairsByCompPins,
  getDefaultModelsObj,
  getModelsSelectList,
  MODEL_LIST,
  getNewModelSelectList,
  getDefaultPortSetting,
  getPinRightDisplay,
  getNewPkgModels,
  clearPairsPortsByType,
  updateSelectSignalDefaultValue,
  getModelTypePins,
  getModelTitle,
  getSelectedPorts,
  getSignalAndPin,
  getMultiPinInfo,
  getPortModelKey,
  getPortIndexList,
  getAutoFillNode
}