import {
  LAYER_CHANGE,
  LOCATION_CHANGE,
  CLOSE_PANEL,
  OPEN_PANEL,
  COLORBY_CHANGE,
  SELECTED_ALL,
  CANCEL_SELECTED_ALL,
  SHOW_SELECTED,
  HIDE_SELECTED,
  SELECTION_CHECKBOX_CHANGE,
  SELECTION_CLEAR_ONE,
  CLEAN_STATUS,
  CHANGE_SELECTED_DISPLAY,
  SELECT_CHANGE,
  UPDATE_PCB_REFRESH_STATUS,
  SELECTION_CLEAR
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import {
  selectChange, selectedAll, locationChange, layerChange, selectionCheckboxChange,
  changeSelectedDisplay, showSelected, hideSelected, selectionClearOne, updatePanelType,
  updateColorBy, cleanPCBStatus, updateRefreshStatus,
  selectionClear
} from '../../../../services/helper/dataProcess';

const defaultState = {
  panelType: {},
  colorBy: {},
  selections: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        selections: locationChange(state.selections, action.designID, action.location)
      }
    case LAYER_CHANGE:
      return {
        ...state,
        selections: layerChange(state.selections, action.designID, action.layers)
      }
    case SELECTION_CLEAR_ONE:
      return {
        ...state,
        selections: selectionClearOne(state.selections, action.designID, action.name)
      }
    case SELECTION_CLEAR:
      return {
        ...state,
        selections: selectionClear(state.selections, action.designID)
      }
    case SELECTED_ALL:
      return {
        ...state,
        selections: selectedAll(state.selections, action.designID, true)
      }
    case CANCEL_SELECTED_ALL:
      return {
        ...state,
        selections: selectedAll(state.selections, action.designID, false)
      }
    case SHOW_SELECTED:
      return {
        ...state,
        selections: showSelected(state.selections, action.designID)
      }
    case HIDE_SELECTED:
      return {
        ...state,
        selections: hideSelected(state.selections, action.designID)
      }
    case SELECTION_CHECKBOX_CHANGE:
      return {
        ...state,
        selections: selectionCheckboxChange(state.selections, action.designID, action.selected),
      }
    case OPEN_PANEL:
      return {
        ...state,
        panelType: updatePanelType(state.panelType, action.designID, action.panel)
      }
    case CLOSE_PANEL:
      return {
        ...state,
        panelType: updatePanelType(state.panelType, action.designID, '')
      }
    case COLORBY_CHANGE:
      return {
        ...state,
        colorBy: updateColorBy(state.colorBy, action.designID, action.colorBy)
      }
    case CLEAN_STATUS:
      return {
        ...state,
        ...cleanPCBStatus(state.colorBy, state.panelType, state.selections, action.designID)
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CHANGE_SELECTED_DISPLAY:
      return {
        ...state,
        selections: changeSelectedDisplay(state.selections, action.designID, action.show),
      }
    case SELECT_CHANGE:
      return {
        ...state,
        selections: selectChange(state.selections, action.designID, action)
      }
    case UPDATE_PCB_REFRESH_STATUS:
      return {
        ...state,
        selections: updateRefreshStatus(state.selections, action.designID, action)
      }
    default:
      return state;
  }
}