/**
 * get the net names connected with the specified net by RLC
 * @param {string} netName 
 */
export function getRelatedNets(netName, _LayoutData) {
  const pinList = _LayoutData.mNetManager.GetNetFromName(netName).mPinList;
  const relatedNets = [];
  const RLC = 'RLCrlc';
  const rlcComponents = []
  for (const { mLayerName, mCompName, mPinNum } of pinList) {
    if (RLC.includes(mCompName[0])) {
      rlcComponents.push(mCompName);
    }
  }
  const nets = _LayoutData.mNetManager.mNetList.mVals;
  for (const { mName, mPinList } of nets) {
    if (mName !== netName) {
      for (const { mCompName } of mPinList) {
        if (rlcComponents.includes(mCompName)) {
          relatedNets.push(mName);
          break;
        }
      }
    }
  }
  return relatedNets;
}

/* filter signals net */
export function autoFilterSignalNets(netList) {
  let nets = [];
  for (let net of netList) {
    if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
      continue;
    }
    // Remove v, +v, N+V NONE, GND, via length check
    // if (net.mName.match(/^(v|\+v|NONET|GND)/i)) {
    //   continue;
    // };
    // if (net.mGeomList.length > 0) {
    //   const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
    //   const typeList = geomList.map(item => item instanceof CeLine);
    //   if (typeList.includes(true)) {
    //     continue;
    //   }
    // }
    // if (net.mViaList.length > 10) {
    //   continue;
    // }
    nets.push(net.mName)
  };
  return nets;
};

// Auto filter Power Ground Nets
export function autoFindPowerGNDNets(netList) {
  let nets = [];
  for (let net of netList) {
    if (!_isPowerGND(net)) {
      continue;
    } else {
      nets.push(net.mName);
    }
  };
  return nets;
}


export function _isPowerGND(net) {
  if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
    return false;
  };
  if (net.mName.match(/^NONE/i)) {
    return false;
  }
  // Remove v, +v, N+V NONE, GND, via length check
  // if (net.mName.match(/^(v|\+v|GND|VCC)/i)) {
  //   return true;
  // };
  // if (net.mName.match(/[0-9]+V/ig)) {
  //   return true;
  // }
  // Remove geometry check
  // if (net.mGeomList.length > 0) {
  //   const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
  //   const typeList = geomList.map(item => item instanceof CePolygon); // [true,false]
  //   // Exsit Polygon
  //   if (typeList.includes(true)) {
  //     return true;
  //   }
  // }
  // if (net.mViaList.length > 10) {
  //   return true;
  // }
  // return false;
  return true;
}