import { CLOSE_FROM_MODAL, OPEN_CREATE_FOLDER_MODAL, CREATE_FOLDER_FAILED } from './actionType';
import { MY_DESIGNS } from '@/constants/menuType';

const defaultStatus = ({
  visible: false,
  parentID: 0,
  menuSelected: MY_DESIGNS,
  comment: '',
  folderName: '',
  msg: null,
})

const folderCreateFormReducer = (state = defaultStatus, action) => {
  switch (action.type) {
    case OPEN_CREATE_FOLDER_MODAL:
      return {
        ...state,
        visible: true,
        comment: '',
        folderName: '',
        msg: null,
      }
    case CLOSE_FROM_MODAL:
      return {
        ...state,
        visible: false,
        msg: null,
      }
    case CREATE_FOLDER_FAILED:
      const { msg } = action;
      return {
        ...state,
        msg,
      }
    default: return state;
  }
}

export default folderCreateFormReducer;