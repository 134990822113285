import { CAP, FERRITE, IGNORE, IND, JUMPER, RES, SWITCH, TRANSISTOR } from '../../PCBHelper';
import { LINEAR, SWITCHING, SPECIALIZED, LINEAR_SWITCH } from '@/constants/componentType';
import { updateLibraryData } from '../library'
import { PIN_MAP } from '@/constants/libraryConstants';
import componentSetting from '../helper/compSettingHelper';
import libraryConstructor from './libraryConstructor';
import { getArraySimilar, splitArrayToArrays } from '../../helper/arrayHelper';
import { getConnectionGroundNet, ImpPackageDomain } from '../Impedance';
import { getLibraryFileInfo } from '../library'
import compPinMap from './compPinMap';
import { ConnectorComp } from './setupClass';
import { BGA, CONNECTOR, DIE, DIODE, IC, IPD, LOAD, PMIC, VRM } from '../../../constants/componentType';
import { SortComponents, SortFn } from '../../helper/sort';
import designConstructor from '../../helper/designConstructor';
import preLayoutData from '../prelayout/preLayoutData';
import { DECAP_SPICE } from '../../../constants/libraryConstants';
import { AC } from '../../../constants/treeConstants';
import auroraDBJson from '../../Designs/auroraDbData';
import { NONET, POWER } from '../../Designs/constants';
import _ from 'lodash';
import GetPMICs from '../../helper/componentsHelper/GetPath/getPMIC';
import { defaultGroundNets } from '../../../constants/defaultGroundNets'
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import compTableHelper from '@/services/Cascade/helper/compTableHelper.js'

const packageGndNets = ['VSS', 'GND', 'GROUND', 'DGND', '0'];
const packageAGndNets = packageGndNets.map(item => `A${item}`);

function getAllCascadeComponents({ pcbId }) {
  const isPreLayout = designConstructor.isPreLayout(pcbId);
  if (isPreLayout) {
    const data = preLayoutData.getLocalPreLayout(pcbId);
    const { components } = data.content;
    return new Map(components.map(item => ([item.name, { ...item, partName: item.name, usage: item.type, COMP_TYPE: item.type, pins: new Map(item.pins.map(pin => [pin.pin, pin])) }])))
  }
  const Components = auroraDBJson.getComponents(pcbId);
  return Components;
}

function getAllCascadeNets({ pcbId }) {
  const isPreLayout = designConstructor.isPreLayout(pcbId);
  if (isPreLayout) {
    const data = preLayoutData.getLocalPreLayout(pcbId);
    const { nets } = data.content;
    return nets
  }
  const nets = auroraDBJson.getNets(pcbId);
  return [...nets.keys()]
}


function getCascadeComponents({ pcbId, name }) {
  const isPreLayout = designConstructor.isPreLayout(pcbId);
  if (isPreLayout) {
    const data = preLayoutData.getLocalPreLayout(pcbId);
    const { components } = data.content;
    const component = components.find(comp => comp.name === name)
    return { ...component, partName: component.name, usage: component.type, COMP_TYPE: component.type, pins: new Map(component.pins.map(pin => [pin.pin, pin])) }
  }
  return auroraDBJson.getComponent(pcbId, name)
}

function getirPDNComponentsByNets({ ReferenceNets, PowerNets, pcbId, specify = [] }) {
  const { Components, findVRMComps, findCaps } = getComponents([...ReferenceNets, ...PowerNets], pcbId, specify);
  let new_Components = componentsFilter({ Components, ReferenceNets, PowerNets, includesCap: true });
  new_Components = addMultiNetsFieldToCapComps({ Components: new_Components, pcbId });
  return { Components: new_Components, findVRMComps, findCaps }
}

function getComponents(netList, pcbId, specify = []) {
  const isPreLayout = designConstructor.isPreLayout(pcbId);
  const specifyName = specify.map(item => item.name);
  let Components = []
  if (isPreLayout) {
    const data = preLayoutData.getLocalPreLayout(pcbId);
    const { components } = data.content;
    Components = components.map(item => ({ ...item, partName: item.name, usage: item.type, COMP_TYPE: item.type, pins: new Map(item.pins.map(pin => [pin.pin, pin])) }))
  } else {
    Components = auroraDBJson.getComponentsByNets(pcbId, netList);
  }
  const findCaps = Components.filter(item => item.type === CAP);
  const findVRMComps = Components.filter(item => [IND, RES, JUMPER, FERRITE, SWITCH, DIODE].includes(item.type) || item.pmicType || specifyName.includes(item.name))
    .map(item => ({ ...item, nets: [...new Set([...item.pins.values()])] }))
  return { Components, findVRMComps, findCaps };
}

function componentsFilter({ Components, PowerNets, ReferenceNets, includesCap = false }) {
  const _Components = _.cloneDeep(Components);
  const _filter = _Components.filter(comp => {
    if (comp.type === CAP && !includesCap) { return false }
    let nets = [...new Set([...comp.pins.values()].map(item => item.net))];
    if (nets.every(net => PowerNets.includes(net))) {
      // Component only connect to referenceNets
      if (nets.length > 1) {
        return comp;
      } else {
        if (includesCap) {
          if (comp.type !== CAP) {
            // If the component is only connected to single power net and the usage is not 'Cap', the usage is set to Ignore.
            comp.type = IGNORE;
            return comp;
          } else {
            return false;
            // Remove the 'Cap' components;
          }
        }
        return false;
        // The component only connect to single ground net.
      }
    } else if (nets.every(net => [...PowerNets, ...ReferenceNets].includes(net))) {
      // Component only connect to PowerNets
      if (nets.length > 1) {
        if (comp.type === SWITCH && includesCap) {
          nets = nets.filter(net => !ReferenceNets.includes(net))
          if (nets.length === 1) {
            comp.type = IGNORE;
          }
        }
        return comp;
      } else {
        return false;
      }
    } else {
      const new_net = nets.filter(item => PowerNets.includes(item))
      if ([...new Set(new_net)].length > 1) {
        return comp;
      }
      return false
    }
  });
  return _filter;
};

function getConnectNets({ PowerNets, Components, isAC }) {
  let _Components = componentsFilter({ Components, PowerNets: PowerNets, ReferenceNets: [] })
  let connectNets = {}, netsInfo = {};
  const typeList = isAC ? [RES, IND, JUMPER, SWITCH, FERRITE] : [RES, IND, JUMPER, SWITCH, FERRITE, DIODE];
  _Components = _Components.filter(item => typeList.includes(item.type));
  _Components.forEach(comp => {
    const nets = [...comp.pins.values()].map(item => item.net);
    PowerNets.forEach(net => {
      if (nets.includes(net)) {
        if (netsInfo[net]) {
          netsInfo[net].push({ name: comp.name, type: comp.type })
        } else {
          netsInfo[net] = [{ name: comp.name, type: comp.type }]
        }
      }
    })
  })
  const filterNets = Object.keys(netsInfo);
  filterNets.forEach(net => {
    if (netsInfo[net].length) {
      const types = netsInfo[net].map(item => item.type)
      if (types.every(net => [IND].indexOf(net) > -1)) {
        netsInfo[net].forEach(it => {
          if (connectNets[it.name]) {
            connectNets[it.name].push(net)
          } else {
            connectNets[it.name] = [net]
          }
        })
      }
    }
  })

  return connectNets
}

function getPowerDomain({ chip, designId, prevGround }) {
  // pre-layout get power and gnd nets
  const isPreLayout = designConstructor.isPreLayout(designId);
  if (isPreLayout) {
    const elecrtic = designConstructor.getPreLayoutElectric(designId);
    if (elecrtic === AC) {
      const data = preLayoutData.getLocalPreLayout(designId);
      const { powerNets, referenceNets } = data.content;
      return { powerNets, groundNet: referenceNets[0], allNets: powerNets }
    } else {
      const data = preLayoutData.getLocalPreLayout(designId);
      const { nets } = data.content;
      const rule = (item) => {
        return item.isGnd
      }
      const { trueArray, falseArray } = splitArrayToArrays({ array: nets, rule });
      const powerNets = falseArray.map(item => item.name);
      const referenceNets = trueArray.map(item => item.name);
      return { powerNets, groundNet: referenceNets[0], allNets: powerNets }
    }
  }

  const allNets = auroraDBJson.getNetsByComponent(designId, chip);
  let nets = allNets.filter(item => item.type === POWER && item.name !== NONET);
  if (!nets.length) {
    return { powerNets: [], allNets: [], groundNet: "" }
  }

  let groundNet = {};
  const gndObj = nets.reduce((max, net) => {
    return net.pins.size > max.pins.size ? net : max
  }, nets[0]);
  groundNet = { name: gndObj.name }
  //If any net connected to the component pin is named “GND”, “GROUND*” or “0”, we will use it as the ground net
  const findGround = nets.find(item => item.name === prevGround);
  if (findGround) {
    groundNet = { ...findGround }
  } else {
    const filterGroundNets = nets.filter(item => item.name.match(/^GROUND|^GND|VSS|GND[0-9]*$/g) || defaultGroundNets.includes(item.name))
      .sort((a, b) => {
        const getPriority = name => {
          if (name.includes('GND') || name.includes('GROUND')) return 1;
          if (name.includes('VDD')) return 2;
          return 3;
        };
        return getPriority(a.name) - getPriority(b.name);
      });
    if (filterGroundNets.length && !groundNet.name.match(/^GROUND|^GND|VSS|GND[0-9]*$/g) && !defaultGroundNets.includes(groundNet.name)) {
      groundNet = { name: filterGroundNets[0].name }
    }
  }
  nets = nets.filter(item => item.name !== groundNet.name).map(item => item.name).sort();
  //filter power gnd net by net name includes VD/VCC and 1V8/0P85...
  // 2024/09/02 Power judge move to backend
  // const filterNets = nets.filter(item => {
  //   if (item.name.match(/(VD)|(VCC)/ig) && item.name.match(/\d+[PV]\d+/ig)) {
  //     return true;
  //   } else {
  //     return isPowerGND(item, symbol, designId);
  //   }
  // })
  // const net = filterNets.map(item => item.name)
  return { powerNets: nets, allNets: allNets.map(item => item.name), groundNet: groundNet.name }
}

function getVRMComponents(pcbId) {
  const component = getAllCascadeComponents({ pcbId })
  const vrm = [...component.keys()]
  return vrm
}

function getExtendedNetByVRM({ designId, vrm, powerNets, filterNets, COMP_PREFIX_LIB }) {
  const extendNets = [], components = [], inductance = {}, connectComp = [];
  for (let comp of vrm) {
    const info = findExtendedNetsInLoops(designId, comp, powerNets, filterNets, 3);
    if (!info.connectToPowerNet) {
      let allInd = info.allPath;
      if (allInd.length > 0) {
        allInd.sort((a, b) => a.extendNets.length > b.extendNets.length ? 1 : -1)
        const currentInd = allInd[0];
        extendNets.push(...currentInd.extendNets);
        components.push(...currentInd.components);
        inductance[comp] = { powerPins: [], groundPins: [], powerComp: comp, groundComp: comp, vrmComp: comp };
      }
    }

  }
  return { extendNets: extendNets.filter(net => !powerNets.includes(net)), components, inductance, connectComp }
}

function findExtendedNetsInLoops(designId, comp, powerNets, filterNets, times) {
  let extendNets = [], components = [], allPath = [];
  const nets = auroraDBJson.getNetsByComponent(designId, comp).filter(net => !filterNets.includes(net.name));
  let findNets = [...new Set(nets.map(item => item.name))].filter(net => net !== NONET);
  if (findNets.some(net => powerNets.includes(net))) {
    return { components, extendNets, connectToPowerNet: true };
  } else if (times > 0) {
    const { Components } = getComponents(findNets, designId, []);
    let _comps = Components.filter(item => [RES, JUMPER, FERRITE, SWITCH, IND].includes(item.type))
      .filter(item => ![...item.pins.values()].every(pin => filterNets.includes(pin.net)))
      .map(item => ({
        ...item,
        part: item.partName,
        pins: [...item.pins.values()],
        type: item.COMP_TYPE,
        usage: item.COMP_TYPE
      }));
    for (let _comp of _comps) {
      const info = findExtendedNetsInLoops(designId, _comp.name, powerNets, filterNets, times - 1);
      if (info && info.connectToPowerNet === true) {
        extendNets = [...extendNets, ...info.extendNets, ..._comp.pins.map(pin => pin.net).filter(net => net !== NONET)];
        components = [...components, ...info.components, _comp];
        if (times < 3) {
          return { components, extendNets, connectToPowerNet: true }
        } else {
          allPath.push({
            name: _comp.name,
            extendNets: [...info.extendNets, ..._comp.pins.map(pin => pin.net).filter(net => net !== NONET)],
            components: [...info.components, { ..._comp }]
          })
        }
      }
    }
  }
  return { components, extendNets: [...new Set(extendNets)], allPath }
}

function getNets(designId) {

  const isPreLayout = designConstructor.isPreLayout(designId);
  if (isPreLayout) {
    const data = preLayoutData.getLocalPreLayout(designId);
    if (!data || !data.content) {
      return {
        nets: [],
        loadComponents: [],
        vrm: []
      }
    }

    const { powerNets, components } = data.content;
    return {
      nets: powerNets,
      loadComponents: components.filter(item => item.type === IC),
      vrm: components.filter(item => item.type === PMIC)
    }
  }

  // Nets filter
  const nets = [...auroraDBJson.getNets(designId).values()].sort((a, b) => a.type !== POWER && b.type === POWER ? 1 : -1).map(net => net.name);

  const components = auroraDBJson.getComponents(designId);
  // !name match(/^(iso|tp|sw)/) 
  const filterList = [...components.values()].filter(item =>
    item.pins.size > 3 &&
    (item.type === IGNORE || item.type === JUMPER || (item.type === CAP && !item.partName.match(/CAP/ig))) &&
    !item.name.match(/^(y|e|iso|tp|sw)/i));
  const _filterList = filterList.sort(function (a, b) {
    // Sort according to the number of pins
    return b.pins.size - a.pins.size
  });

  let vrm = getVRMComponents(designId);
  return {
    nets,
    loadComponents: _filterList.sort((a, b) => { return a.name > b.name ? 1 : -1 }),
    vrm: vrm.sort()
  }
}

function _getVRM({
  designId,
  GroundNets,
  PowerNets,
  ExtendNets,
  ExistNets,
  COMP_PREFIX_LIB,
  loadSelect,
  connectInductance,
  findExtend = true,
  isAC = false,
  PMIC = [],
  powerSwitch = [],
  doNotStuff = [],
  buckConverter,
  table = [],
  pinMapSense,
  isTemplate,
  pinConnection = [],
  pmicAndNets = [],
  pinMap = [],
  specify = [],
  extraNets = [],
  fuzzyMatch = false
}) {
  let getPMICs = {};
  let extendNets = [], components = [], vrmComps = [];
  let comps = loadSelect.map(select => select.comp);

  const isPreLayout = designConstructor.isPreLayout(designId);
  if (isPreLayout) {
    const preData = preLayoutData.getLocalPreLayout(designId);

    if (preData) {
      const { content } = preData
      const { components, decapGroups } = content;
      const VRM = components.find(item => item.type === 'PMIC');
      const { pins, name, voltage } = VRM;
      const groundPins = pins.filter(item => GroundNets.includes(item.pin));
      const powerPins = pins.filter(item => PowerNets.includes(item.pin));
      const _VRM = [{
        groundPin: [
          {
            comp: name,
            pins: [...groundPins.map(pin => pin.pin)],
            net: [...GroundNets]
          }
        ],
        powerPin: [
          {
            comp: name,
            pins: [...powerPins.map(pin => pin.pin)],
            net: [...PowerNets]
          }
        ],
        model: { name: "", id: "" },
        voltage: voltage,
        VRM_COMP: [name],
        inductance: name,
        component: [
          {
            pwrComp: name,
            powerPins: [...powerPins.map(pin => pin.pin)],
            extendNets: [...PowerNets],
            groundPins: [...groundPins.map(pin => pin.pin)],
            groundNet: [...GroundNets]
          }
        ]
      }];
      const IC = components.find(item => item.type === 'IC');
      const DEBUG_MONITOR = PowerNets.length ? [[{ type: "comp", name: IC.name }, { type: "net", name: PowerNets[0] }, { type: 'comp', name, pins: powerPins }]] : []
      let Components = [
        {
          usage: LOAD,
          part: IC.name,
          name: IC.name,
          COMP_TYPE: IGNORE,
          pins: IC.pins
        },
        {
          usage: IGNORE,
          part: name,
          name,
          COMP_TYPE: IGNORE,
          pins
        }
      ];
      const Caps = getPreLayoutCap(decapGroups, PowerNets, GroundNets)
      Components.push(...Caps)
      Components = SortComponents(Components)
      return {
        VRM: _VRM,
        PowerNets,
        DEBUG_MONITOR,
        Components
      }
    }
    return {
      DEBUG_MONITOR: [],
      VRM: [],
      PowerNets,
      Components: [],
    }
  } else {
    let _powerNets = PowerNets.filter(item => {
      const netInfo = auroraDBJson.getNet(designId, item)
      return netInfo && netInfo.pins && comps.some(comp => netInfo.pins.has(comp)) ? true : false;
    })

    const getPMIC = new GetPMICs({
      ReferenceNets: [...GroundNets],
      PowerNets: [..._powerNets, ...ExtendNets],
      designId,
      extraNets,
      COMP_PREFIX_LIB,
      findExtend,
      ExistNets: ExistNets,
      connectInductance: connectInductance,
      chips: comps,
      isAC,
      PMIC,
      powerSwitch,
      doNotStuff,
      buckConverter,
      pinMapSense,
      isTemplate,
      pinConnection,
      componentTable: table,
      pmicAndNets,
      pinMap,
      specify,
      fuzzyMatch 
    })
    getPMICs = getPMIC.getPMICs();
  }
  if (isAC) {
    return getPMICs;
  } else {
    const DEBUG_MONITOR = getPMICs && getPMICs.DEBUG_MONITOR ? getPMICs.DEBUG_MONITOR : [];
    let _PowerNets = getPMICs && getPMICs.PowerNets ? getPMICs.PowerNets : [];
    let _components = getPMICs && getPMICs.Components ? getPMICs.Components : [];
    let _vrms = getPMICs && getPMICs.VRM ? getPMICs.VRM : [];
    let inductance = {};
    getPMICs.vrms && getPMICs.vrms.forEach(item => {
      const _inductance = DEBUG_MONITOR.filter(d => d[d.length - 1].name === item.vrm).map(d => {
        const vrm = d[d.length - 1].name, net = d[d.length - 2].name;
        const netInfo = auroraDBJson.getNet(designId, net);
        const powerPins = netInfo.pins.get(vrm);
        const ground = GroundNets && GroundNets.length ? GroundNets[0] : ''
        const gndInfo = auroraDBJson.getNet(designId, ground)
        const groundPins = ground ? gndInfo.pins.get(vrm) : []
        return { powerPins, groundPins, powerComp: vrm, groundComp: vrm, vrmComp: vrm }
      });
      inductance[item.vrm] = _inductance
    })
    _components.filter(it => comps.includes(it.name) || Object.keys(inductance).includes(it.name) || it.usage !== IGNORE).forEach(it => {
      components.push({
        type: it.COMP_TYPE,
        name: it.name,
        part: it.part,
        usage: comps.includes(it.name) ? 'Load' : doNotStuff.includes(it.name) ? 'Ignore' : it.usage,
        value: it.value,
        pins: it.pins,
        pinMap: it.pinMap
      })
    })

    components = SortComponents(components)

    _vrms.forEach(item => {
      if (Array.isArray(item.VRM_COMP)) {
        // determine whether it is an array
        vrmComps = [...vrmComps, ...item.VRM_COMP]
      } else if (item.VRM_COMP) {
        vrmComps.push(item.VRM_COMP)
      }
    })
    vrmComps = [...new Set(vrmComps)];
    extendNets = _PowerNets.filter(item => !PowerNets.includes(item))
    return { extendNets, vrmComps, components, DEBUG_MONITOR, inductance }
  }
}

// Get the cap and VRM components connected to reference nets
function getReferenceComps({ VRM_COMPS = [], ReferenceNets, pcbId, powerPinList = [] }) {
  const { findCaps } = getirPDNComponentsByNets({ ReferenceNets, PowerNets: [], pcbId })
  let VRMComponents = [];
  let referenceComps = [...findCaps];
  if (VRM_COMPS.length) {
    const _Components = auroraDBJson.getComponentsByNames(pcbId, VRM_COMPS)
    VRMComponents = _Components.map(item => ({ name: item.name, pins: [...item.pins.values()] }))
    referenceComps.push(...VRMComponents)
  }
  if (powerPinList.length) {
    const _powers = powerPinList.filter(item => item.type !== CAP && item.usage !== LOAD && item.pins.some(pin => ReferenceNets.includes(pin.net)))
    referenceComps.push(..._powers)
  }
  referenceComps = referenceComps.map(item => item.name);
  const allReferenceComps = auroraDBJson.getComponentsByNets(pcbId, ReferenceNets);
  const extraReferenceComps = allReferenceComps.filter(item => !referenceComps.includes(item.name)).map(item => item.name).sort()
  return { referenceComps, VRMComponents, extraReferenceComps }
}

function getPMIC(COMP_PREFIX_LIB = {}) {
  const PMIC = [];
  const { linearSwitch = [], linearRegulator = [], switchingRegulator = [], specialized = [] } = COMP_PREFIX_LIB;
  if (COMP_PREFIX_LIB) {
    //LINEAR, SWITCHING, VOLTAGE
    PMIC.push(...linearSwitch.map(item => ({ type: LINEAR_SWITCH, partName: item })))
    PMIC.push(...linearRegulator.map(item => ({ type: LINEAR, partName: item })))
    PMIC.push(...switchingRegulator.map(item => ({ type: SWITCHING, partName: item })))
    PMIC.push(...specialized.map(item => ({ type: SPECIALIZED, partName: item })))
  }
  return PMIC
}

function getComponentByPartName(part, designId) {
  return auroraDBJson.getComponentsByPartName(designId, part);
}

async function checkConnectComp(name, pins, designId) {
  if (!auroraDBJson.checkAuroraJson(designId)) {
    const COMP_PREFIX_LIB = await componentSetting.getPrefixLib(designId);
    await auroraDBJson.getAuroraJson(designId, { compPrefixLib: COMP_PREFIX_LIB })
  }
  return pins.map(pin => {
    const comp = auroraDBJson.getComponentsByNets(designId, [pin.net]).filter(item => item.name !== name)
    let nextComp = comp.find(item => item.type === IND);

    if (nextComp) {
      nextComp = getCascadeComponents({ pcbId: designId, name: nextComp.name })
      const nextPin = [...nextComp.pins.values()].find(p => p.net !== pin.net);

      if (nextPin) {
        //find connector comp by netNet
        const _nextComps = auroraDBJson.getComponentsByNets(designId, [nextPin.net]).filter(item => item.name !== name && item.name !== nextComp.name);
        // filter: the PMIC output inductor is array inductor or coupling inductor
        if (_nextComps.length) {
          return { ...pin, nextComp: nextComp.name, nextNet: nextPin.net }
        }
      }
    }
    return pin
  }).sort((a, b) => a.pin - b.pin > 0 ? -1 : 1)
}

function CascadeCompRLCPrefixLib(obj = {}) {
  const {
    Res = ['R', 'PR', 'XW', 'SHRT'],
    Ind = ["L", "PL"],
    Cap = ["C", "PC"],
    Jumper = ['PJ', 'J'],
    Ferrite = ['FB', 'FL'],
    Diode = ['D'],
    Transistor = ['Q', 'PQ'],
    Load = ['U'],
    linearSwitch = [],
    specialized = [],
    linearRegulator = [],
    switchingRegulator = [],
    discreteBuckConverter = [],
    powerSwitch = [],
    driver = [],
    multiPortRes = [],
    Bga = [],
    Die = [],
    Ignore = [],
    Ipd = []
  } = obj;

  this.Res = Res;
  this.Ind = Ind;
  this.Cap = Cap;
  this.Jumper = Jumper;
  this.Ferrite = Ferrite;
  this.Diode = Diode;
  this.Transistor = Transistor;
  this.linearSwitch = linearSwitch;
  this.specialized = specialized;
  this.linearRegulator = linearRegulator;
  this.switchingRegulator = switchingRegulator;
  this.powerSwitch = powerSwitch;
  this.discreteBuckConverter = discreteBuckConverter;
  this.driver = driver;
  this.multiPortRes = multiPortRes;
  this.Load = Load;
  this.Bga = Bga;
  this.Die = Die;
  this.Ignore = Ignore;
  this.Ipd = Ipd;
};

function CascadeCompPrefixVersion(version) {
  this.version = version;
}

function getSelectedSetupIDs(selectedKeys) {
  return selectedKeys.filter(k => !k.match(/PCB-|package-/)).map(d => {
    return d.split('-')[1] ? d.split('-')[1] : ''
  })
}

function findInputPinByOutput(output, inputGroups, returnType) {
  let input = [];
  for (let outItem of output) {
    let out = typeof outItem === "object" ? outItem.pinName : outItem;
    const inputConst = getInputPinNameFrag((out || "").toUpperCase());
    if (inputConst.length < 3 || !inputConst.match(/[a-zA-Z]/ig)) {
      continue
    }
    const matchConst = new RegExp(`${inputConst}(\\D|$)`, 'g');
    const findInput = inputGroups.filter(item => !item.pinName.match(/(GND)|(REG)|(SW)|(FB)|(VSS)/ig) && item.pinName.match(matchConst));
    if (findInput.length) {
      if (returnType === "pinName") {
        input.push(...findInput.map(item => `${item.comp ? `${item.comp}_` : ""}${item.pinName}`));
        input = [...new Set(input)];
      } else {
        for (let item of findInput) {
          const findPin = input.find(it => (it.pin === item.pin) && (!it.comp || it.comp === item.comp));
          if (!findPin) {
            const inputInfo = { pin: item.pin, pinName: item.pinName, currPin: item.pin, currPinName: item.pinName };
            if (item.comp) {
              inputInfo.comp = item.comp;
              inputInfo.currComp = item.comp;
            }
            input.push(inputInfo);
          }
        }
      }
    }
  }
  return input;
}

function getInputPinNameFrag(out) {
  if (out.includes('OUT')) {
    return out.replace('OUT', 'IN');
  } else {
    return out.replace(/^[A-Z]*_|_[0-9]*$/g, '');
  }
}

async function getPMICInPCB({ designId, partList = undefined, partComps = [] }) {
  const isPreLayout = designConstructor.isPreLayout(designId);
  if (isPreLayout) {
    const preData = preLayoutData.getLocalPreLayout(designId);

    if (!preData) {
      return [];
    }

    const { content = {} } = preData;
    const { components = [] } = content;
    const PMICs = components.filter(item => item.type === PMIC).map(item => {
      const { name, pins } = item;
      return { type: IGNORE, COMP_TYPE: IGNORE, name, pins, partName: name, trace: true }
    })

    return PMICs
  }

  const COMP_PREFIX_LIB = await componentSetting.getPrefixLib(designId);
  const PMICPart = partList || getPMIC(COMP_PREFIX_LIB).map(item => item.partName);
  const buckConverter = partList ? COMP_PREFIX_LIB.discreteBuckConverter.filter(item => partList.includes(`${item[0] || ''} - ${item[1] || ''}`)) : COMP_PREFIX_LIB.discreteBuckConverter || []
  let components = getAllCascadeComponents({ pcbId: designId });
  const partCompsPart = partComps.map(part => part.part);
  const partCompsComps = partComps.map(part => part.type === 'buckConverter' ? part.split(' - ') : part.comps).flat(2);
  components = [...components.values()].filter(item =>
    (PMICPart.includes(item.partName) || buckConverter.some(buck => buck.includes(item.name))) &&
    (!partCompsPart.includes(item.partName) || partCompsComps.includes(item.name)));
  return components.map(comp => ({ ...comp, pins: [...comp.pins.values()], trace: true }))
}

//Add isMultiNets to cap components to supports caps that connect to more than 2 nets
function addMultiNetsFieldToCapComps({ Components, pcbId, isVersionUpdate = false }) {
  const allComponents = getAllCascadeComponents({ pcbId })
  //If the nets to which the cap component is connected is greater than 2, true
  for (let comp of Components) {
    if (comp.type !== CAP || (!isVersionUpdate && comp.isMultiNets !== undefined)) {
      continue;
    }
    const findComp = allComponents.get(comp.name);
    if (!findComp) {
      continue;
    }
    const pinNets = [...new Set([...findComp.pins.values()].map(item => item.net))];
    comp.isMultiNets = pinNets.length > 2 ? true : false;
  }
  return Components;
}

function getComponentByComponentName(comps, designId) {
  const components = auroraDBJson.getComponentsByNames(designId, comps);
  return components;
}

async function initDiscreteBuckConverter({ compPrefixLib, designId, updateLibraryMenu }) {
  const transistor = auroraDBJson.getComponentsByType(designId, TRANSISTOR);
  let newConverter = [], usedTransistor = [], buckInfo = [];
  for (let i = 0; i < transistor.length; i++) {
    const tran = transistor[i];
    if (usedTransistor.includes(tran.name)) {
      continue;
    }
    const { groundNet } = getPowerDomain({ chip: tran.name, designId, COMP_PREFIX_LIB: compPrefixLib });

    if (tran.pins && tran.pins.size <= 5) {
      continue;
    }
    const pins = [...tran.pins.values()].filter(pin => pin.net !== groundNet);
    for (let j = 0; j < transistor.length; j++) {
      const tran_2 = transistor[j];
      const pins_2 = [...tran_2.pins.values()];
      if (usedTransistor.includes(tran_2.name) || tran_2.name === tran.name || pins_2.length <= 5) {
        continue;
      }

      if (pins.some(pin => pins_2.some(pin_2 => pin.net === pin_2.net && pin.pinName !== pin_2.pinName))) {
        const buckCon = [tran.name, tran_2.name];
        buckInfo.push([tran, tran_2]);
        newConverter.push(buckCon);
        usedTransistor.push(...buckCon);
        break;
      }
    }
  }
  const pinMapList = libraryConstructor.getLibraryValues(PIN_MAP);
  let newLibrary = []
  const pinMapNames = pinMapList.map(item => item.name)
  for (let group of buckInfo) {
    if (pinMapNames.includes(`${group[0].name} - ${group[1].name}`) || pinMapNames.includes(`${group[1].name} - ${group[0].name}`)) {
      continue;
    }
    const tran_1 = group[0], tran_2 = group[1];
    let output = [], input = [];
    const pins_1 = tran_1 && tran_1.pins ? [...tran_1.pins.values()] : [], pins_2 = tran_2 && tran_2.pins ? [...tran_2.pins.values()] : [];
    const nets_1 = pins_1.map(pin => pin.net), nets_2 = pins_2.map(pin => pin.net);
    const nets = nets_1.filter(net => nets_2.includes(net)).filter(net => !defaultGroundNets.some(d => net === d));
    if (!nets.length) {
      continue;
    }
    const net = nets[0];
    const _pins1 = pins_1.filter(pin => pin.net === net), _pins2 = pins_2.filter(pin => pin.net === net);
    output.push(..._pins1.map(pin => ({ comp: tran_1.name, pin: pin.pin, pinName: pin.pinName })),
      ..._pins2.map(pin => ({ comp: tran_2.name, pin: pin.pin, pinName: pin.pinName })));
    // _pins1: [{pinName: D}], _pins2: [{pinName: S1},{pinName: S2},{pinName: S3}]
    // find pins_1 pinName in _pins2(S1, S2, S3), find pins_2 pinName in _pins1(D)
    // filter pin net is GND
    input.push(...pins_1.filter(pin => _pins2.some(p => p.pinName === pin.pinName)).filter(pin => !defaultGroundNets.some(d => pin.net === d)).map(pin => ({ comp: tran_1.name, pin: pin.pin, pinName: pin.pinName })),
      ...pins_2.filter(pin => _pins1.some(p => p.pinName === pin.pinName)).filter(pin => !defaultGroundNets.some(d => pin.net === d)).map(pin => ({ comp: tran_2.name, pin: pin.pin, pinName: pin.pinName })));
    input = input.filter(inp => !output.some(out => `${out.comp}-${out.pin}` === `${inp.comp}-${inp.pin}`))
    newLibrary.push({
      name: `${tran_1.name} - ${tran_2.name}`,
      id: "",
      version: "0.0.1",
      type: PIN_MAP,
      config: { pinTable: [{ index: 0, input, output }] },
      partName: `${tran_1.name} - ${tran_2.name}`
    })
  }

  if (newLibrary.length) {
    try {
      await saveBuckPinMap(newLibrary, updateLibraryMenu)
    } catch (error) {
      console.error(error)
    }
  }

  return newConverter
}

async function saveBuckPinMap(newLibrary, updateLibraryMenu) {
  for (let data of newLibrary) {
    await updateLibraryData({ data });
  }
  if (updateLibraryMenu) {
    updateLibraryMenu()
  }
}

function getPackagePowerDomain(pcbId, setting, targetDie = []) {
  const isPreLayout = designConstructor.isPreLayout(pcbId);
  if (isPreLayout) {
    const preData = preLayoutData.getLocalPreLayout(pcbId);
    const { content = {} } = preData;
    const { components = [], powerNets = [], referenceNets = [] } = content;
    const _BGA = components.find(item => item.type === BGA);
    const _DIE = components.filter(item => item.type === DIE);
    return { powerNets, referenceNets, DIE: _DIE, BGA: _BGA }
  }

  const components = auroraDBJson.getComponents(pcbId);
  const { Bga = [] } = setting
  const IC = [...components.values()].filter(item => item.type === IGNORE);
  const _DIE = targetDie.length ? targetDie.map(comp => components.get(comp)).filter(item => !!item) : IC.find(comp => (comp.name && comp.name.match(/(DIE)/ig)) || (comp.partName && comp.partName.match(/(DIE)/ig))) || [];
  const _BGA = Bga.length ? components.get(Bga[0]) : IC.find(comp => (comp.name && comp.name.match(/(BGA)/ig)) || (comp.partName && comp.partName.match(/(BGA)/ig))) || {};
  const DIENets = [...new Set(_DIE.map(die => auroraDBJson.getNetsByComponent(pcbId, die.name).map(net => net.name)).flat(2))];
  const BGANets = [...new Set(auroraDBJson.getNetsByComponent(pcbId, _BGA.name).map(net => net.name))];
  const currentNets = DIENets.filter(net => BGANets.includes(net));
  const powerNets = currentNets.filter(net => net.match(/(VD|VCC)/ig));
  const referenceNets = currentNets.filter(net => net.match(/(VSS|GND)/ig));

  return { powerNets, referenceNets, DIE: _DIE, BGA: _BGA }
}

function getPkgDomainInfo({ pcbId, powerNet, referenceNets, setting, DIE: DIEName, BGA: BGAName }) {
  const isPreLayout = designConstructor.isPreLayout(pcbId);
  if (isPreLayout) {
    const preData = preLayoutData.getLocalPreLayout(pcbId);
    const { content = {} } = preData;
    const { decapGroups = [], components } = content;
    const Caps = getPreLayoutCap(decapGroups, [powerNet], referenceNets);
    const BGAComp = components.find(item => item.name === BGAName);
    const DIEComp = components.filter(item => DIEName.includes(item.name));
    const Components = [
      {
        usage: BGA,
        part: BGAComp.name,
        name: BGAComp.name,
        COMP_TYPE: IGNORE,
        pins: BGAComp.pins
      }
    ]
    DIEComp.forEach(comp => {
      Components.push({
        usage: DIE,
        part: comp.name,
        name: comp.name,
        COMP_TYPE: IGNORE,
        pins: comp.pins
      })
    })
    let sortComponents = SortFn([...Components, ...Caps], [BGA, DIE, IPD, CAP], 'COMP_TYPE')
    sortComponents = SortComponents(sortComponents)
    const domain = new ImpPackageDomain({
      PowerNets: [powerNet],
      ReferenceNets: [...referenceNets],
      Components: sortComponents,
      MAIN_POWER_NETS: [powerNet],
      MAIN_REF_NETS: [...referenceNets]
    });
    return {
      add: true,
      domain
    }
  }

  let referenceNet = undefined;
  const _powerRefe = powerNet.replace(/(VD|VCC)/ig, 'VSS');
  // Three ways to find the correct reference net
  // 1. replace VD with VSS to find exactly the same net name
  const filterRefe = referenceNets.find(item => item === _powerRefe);
  referenceNet = filterRefe || undefined;

  // 2. Find nets with high similarity to power net
  if (!referenceNet) {
    const powerLength = _powerRefe.length;
    const filterRefes = referenceNets.filter(item => item.length <= powerLength + 1 || item.length >= powerLength - 1);
    const filterRefe = filterRefes.find(item => getArraySimilar(_powerRefe, item) > (1 - (2 / powerLength)));
    referenceNet = filterRefe || undefined;
  }

  // 3. Find public referenceNet (check power has A and chose) (A)VSS > (A)GND
  if (!referenceNet) {
    const hasA = powerNet.match(/(AVDD|AVCC)/ig);
    if (hasA) {
      const find = packageAGndNets.find(item => referenceNets.includes(item));
      if (find) {
        referenceNet = find
      }
    }

    if (!referenceNet) {
      const find = packageGndNets.find(item => referenceNets.includes(item));
      if (find) {
        referenceNet = find
      }
    }
  }

  // 4. Find refrenceNet includes *VSS/*GND
  if (!referenceNet) {
    const find = referenceNets.find(item => item.includes('VSS') || item.includes('GND'));
    if (find) {
      referenceNet = find
    }
  }

  // if without referenceNet, remove powerNet
  if (!referenceNet) {
    return {
      add: false
    }
  }

  const { Ignore = [] } = setting;
  const { Components } = getComponents([powerNet, referenceNet], pcbId);
  const _components = Components.filter(item => !Ignore.includes(item.name) && [...item.pins.values()].some(p => p.net === powerNet)).map(item => {
    const type = getPackageType(item.name, setting, DIEName, BGAName) || item.type;
    const value = type === CAP ? "" : item.value || "";
    const { name, pins, partName, partNumber } = item;
    return {
      name,
      COMP_TYPE: type,
      usage: type,
      pins: [...pins.values()],
      value,
      part: partName,
      partNumber: partNumber || undefined,
      isMultiNets: false,
      model: type === CAP ? { id: "", name: "" } : undefined
    }
  })
  const domain = new ImpPackageDomain({
    PowerNets: [powerNet],
    ReferenceNets: [referenceNet],
    Components: SortFn(_components, [BGA, DIE, IPD, CAP], 'usage'),
    MAIN_POWER_NETS: [powerNet],
    MAIN_REF_NETS: [referenceNet]
  });
  return {
    add: true,
    domain
  }
}

function getNewPackageComp({ PowerNets, designId, ReferenceNets, setting, DIE: DIEName, BGA: BGAName }) {
  const { Ignore = [] } = setting;
  const { Components } = getComponents([...PowerNets, ...ReferenceNets], designId);
  const _components = Components.filter(item => !Ignore.includes(item.name) && [...item.pins.values()].some(p => PowerNets.includes(p.net))).map(item => {
    const type = getPackageType(item.name, setting, DIEName, BGAName) || item.COMP_TYPE;
    return {
      name: item.name,
      COMP_TYPE: type || item.type,
      usage: type || item.type,
      pins: [...item.pins.values()],
      value: item.value,
      part: item.partName,
      partNumber: item.partNumber || undefined,
      isMultiNets: false,
      model: type === CAP ? { id: "", name: "" } : undefined
    }
  })
  return SortFn(_components, [BGA, DIE, IPD, CAP], 'usage')
}

function getPackageType(name, setting, DIEName, BGAName) {
  const { Die = [], Bga = [], Ipd = [] } = setting;
  if (Die.includes(name) || DIEName.includes(name)) {
    return DIE
  }

  if (Bga.includes(name) || name === BGAName) {
    return BGA
  }

  if (Ipd.includes(name)) {
    return IPD
  }

  return null;
}

async function resolveDriverModel(libraryId) {
  const libraryData = await getLibraryFileInfo(libraryId);
  let lineBuffer = libraryData.match(/[^\r\n]+/g);
  const subcktLine = lineBuffer.filter(line => line.match(/^(.SUBCKT)/ig));
  if (subcktLine.length) {
    let nodes = {}
    for (let subLine of subcktLine) {
      const [tip, subckt, ...node] = subLine.split(/\s/g);
      nodes[subckt] = [...node]
    }
    return nodes
  }
  return []
}

function getMultiPortResPart(designId) {
  const components = auroraDBJson.getComponentsByType(designId, RES)
  const multiRes = components.filter(item => item.type === RES && item.pins && item.pins.size > 2);
  const multiResPart = multiRes.map(item => item.partName);
  return [...new Set(multiResPart)]
}

async function replacePinMapTable(newComps, prevTable, pcbId, pinMapList) {
  let newTable = [];
  for (let row of prevTable) {
    const { components, partNumber, libraryId, nets } = row;
    const comps = newComps.filter(item => components.includes(item.name));
    if (comps.length) {
      const parts = [...new Set(comps.map(item => item.partName))];
      let partTable = []
      for (let part of parts) {
        const filterComps = comps.filter(item => item.partName === part);
        const components = filterComps.map(item => item.name);
        if (part === partNumber) {
          partTable.push({ ...row, components });
        } else {
          const pinMap = await matchPinMap(part, pinMapList, pcbId, libraryId, nets) || {};
          partTable.push({ ...row, components, partNumber: part, libraryId: pinMap.id || "", libraryName: pinMap.name || "", nets: pinMap.id === libraryId ? nets : [] })
        }
      }
      newTable.push(...partTable);
    } else {
      const findPart = newComps.find(item => item.partName === partNumber);
      if (findPart) {
        newTable.push({ ...row, components: [] })
      }
    }
  }
  return newTable
}

async function matchPinMap(partNumber, pinMapList, pcbId, libraryId, nets = []) {
  const pinMap = await compPinMap.getPinMapData(pcbId);
  let findMap = pinMapList.find(item => item.id === libraryId)
  if (findMap) {
    const components = auroraDBJson.getComponentsByPartName(pcbId, partNumber);
    if (components && components[0]) {
      const _nets = [...components[0].pins.values()].map(item => item.net);
      if (nets.map(net => net.net).every(net => _nets.includes(net))) {
        return { id: findMap.id, name: findMap.name }
      }
    }
  }
  findMap = pinMap.find(item => item.partNumber === partNumber);
  if (findMap && findMap.libraryId) {
    return { id: findMap.libraryId, name: findMap.libraryName };
  } else {
    const findMap = pinMapList.find(item => item.name.includes(partNumber));
    if (findMap) {
      return findMap
    }
  }
  return undefined
}

function changeNewConnectors(connections, newPCB) {
  const { value, index, pcbIndex } = newPCB;
  let _connections = [...connections], newNets = {};
  _connections[index][pcbIndex].pcb = value;

  // check component
  const pcbConnection = connections[index][pcbIndex];
  const { components, key } = pcbConnection;
  const newComponents = getAllCascadeComponents({ pcbId: value });
  let newSelectComps = [];
  for (let component of components) {
    const newComp = newComponents.get(component.name);
    if (!newComp) {
      continue;
    }

    let nets = [...newComp.pins.values()].filter(pin => pin.net).map(pin => pin.net);
    nets = [...new Set(nets)];
    newNets[newComp.name] = nets;

    let _newComp = { ...component };
    _newComp.prev = _newComp.prev.map(item => {
      const _nets = item.nets.filter(net => nets.includes(net[1]));
      return { ...item, nets: _nets };
    })
    _newComp.next = _newComp.next.map(item => {
      const _nets = item.nets.filter(net => nets.includes(net[0]));
      return { ...item, nets: _nets };
    })
    newSelectComps.push(_newComp);
  }
  if (!newSelectComps.length) {
    newSelectComps.push(new ConnectorComp())
  }
  _connections[index][pcbIndex].components = newSelectComps;

  // change Prev Connections
  if (index - 1 >= 0) {
    _connections[index - 1].forEach(pcb => {
      pcb.components.forEach(comp => {
        comp.next.forEach(next => {
          if (next.pcbKey === key) {
            next.pcb = value;
            const newNet = newNets[next.comp] || []
            next.nets = next.nets.filter(net => newNet.includes(net[1]))
          }
        })
      })
    })
  }

  // change Next Connections
  if (index + 1 < _connections.length) {
    _connections[index + 1].forEach(pcb => {
      pcb.components.forEach(comp => {
        comp.prev.forEach(prev => {
          if (prev.pcbKey === key) {
            prev.pcb = value;
            const newNet = newNets[prev.comp] || []
            prev.nets = prev.nets.filter(net => newNet.includes(net[0]))
          }
        })
      })
    })
  }

  return _connections
}

function getComponentColor(usage) {
  switch (usage) {
    case PMIC:
    case VRM:
      return '#f47188'
    case IC:
    case BGA:
    case LOAD:
      return '#6495ed'
    case DIE:
      return '#64c4ed'
    case DIODE:
      return '#f4b183'
    case CONNECTOR:
      return '#9998ff'
    default:
      return '#f4b183'
  }
}

function getFindVRMSpecifyComp(component, designId) {
  const { usage, name, to } = component;
  switch (usage) {
    case CONNECTOR:
      let __nets = []
      for (let t of to) {
        const { nets } = t;
        if (!nets) {
          continue;
        }
        const _nets = nets[`${name}-${designId}`];
        if (!_nets) {
          continue
        }
        __nets.push(..._nets)
      }
      return { name, nets: __nets }
    default: return component;
  }
}

/**
 * 
 * @param {*} basicComp { designId, name, pins > nets > pinNames }
 * @param {*} connectComp { designId, name }
 * @returns 
 */
function checkConnectCompsPinsAndNets(basicComp, connectComp) {
  const { designId: basicDesignId, pins: basicPins, nets: basicNets, name: basicName, pinNames: basicPinNames } = basicComp;
  const { designId, name } = connectComp;
  const compInfo = getCascadeComponents({ pcbId: designId, name });
  let pins = [], nets = [];

  if (!compInfo) {
    return { pins, nets }
  }

  if (basicPins) {
    const connectPins = [...compInfo.pins.values()].filter(pin => basicPins.includes(pin.pin));
    if (connectPins.length) {
      pins = [...connectPins];
      nets = [...new Set(connectPins.map(pin => pin.net))];
      return { pins, nets }
    }
  }

  if (basicNets) {
    const basicCompInfo = getCascadeComponents({ pcbId: basicDesignId, name: basicName });
    if (!basicCompInfo) {
      return { pins, nets }
    }
    const _basicPins = [...basicCompInfo.pins.values()].filter(pin => basicNets.includes(pin.net));
    const basicPinNumbers = _basicPins.map(pin => pin.pin)
    const connectPins = [...compInfo.pins.values()].filter(pin => basicPinNumbers.includes(pin.pin));
    if (connectPins.length) {
      pins = [...connectPins];
      nets = [...new Set(connectPins.map(pin => pin.net))];
      return { pins, nets }
    }

    const _basicPinNames = _basicPins.map(pin => pin.pinName)
    const _connectPins = [...compInfo.pins.values()].filter(pin => _basicPinNames.includes(pin.pin));
    if (connectPins.length) {
      pins = [..._connectPins];
      nets = [...new Set(_connectPins.map(pin => pin.net))];
      return { pins, nets }
    }
  }

  if (basicPinNames) {
    const connectPins = [...compInfo.pins.values()].filter(pin => basicPinNames.includes(pin.pin));
    if (connectPins.length) {
      pins = [...connectPins];
      nets = [...new Set(connectPins.map(pin => pin.net))];
      return { pins, nets }
    }
  }

  return { pins, nets }
}

function getPreLayoutCap(decapGroups, powerNets, referenceNets) {
  let Caps = [], capNumber = 1;
  for (let decapGroup of decapGroups) {
    const { decaps, powerNet, referenceNet } = decapGroup;
    if (!powerNets.includes(powerNet) || !referenceNets.includes(referenceNet)) {
      continue;
    }
    for (let decap of decaps) {
      const { name, number, model = {} } = decap;
      const { libraryId = "", fileName = "", subckt = "" } = model;
      const _model = {
        folderName: "",
        id: libraryId,
        libraryType: libraryId ? DECAP_SPICE : "",
        name: fileName,
        subcktName: subckt,
        type: "Decap"
      }
      for (let i = 0; i < number; i++) {
        Caps.push({
          usage: CAP,
          part: name,
          name: `C${capNumber}`,
          COMP_TYPE: CAP,
          pins: [{ pin: "1", net: powerNet, pinName: powerNet }, { pin: "2", net: referenceNet, pinName: referenceNet }],
          model: _model,
          value: { r: "", l: "", c: "" },
          isPreLayout: true
        })
        capNumber = capNumber + 1
      }
    }
  }
  return Caps
}

function getPreLayoutPinMap(designId) {
  const preData = preLayoutData.getLocalPreLayout(designId);

  if (!preData) {
    return []
  }

  const { content = {} } = preData;
  const { components = [], nets = [] } = content;
  const PMICs = components.filter(item => item.type === PMIC);
  const pinMapList = PMICs.map(item => {
    const { name, pins } = item;
    const output = pins.filter(pin => {
      const findNet = nets.find(net => net.name === pin.net)
      return findNet && !findNet.isGnd ? true : false
    }).map(pin => pin.pin)
    return {
      partNumber: name,
      type: LINEAR_SWITCH,
      data: [{ output: output }]
    }
  })
  return pinMapList
}

function getPreLayoutSetting(designId) {

  const preData = preLayoutData.getLocalPreLayout(designId);

  if (!preData) {
    return {}
  }

  const { content = {} } = preData;
  const { components = [] } = content;
  const PMICs = components.filter(item => item.type === PMIC);
  return { linearSwitch: PMICs.map(item => item.name) };
}

async function getAutoConnectorNets(designId, data, connName, rootPCBId, rootTargetIC) {
  const layout = data.find(item => item.designId === designId);
  if (!layout) {
    return [];
  }
  const isPreLayout = designConstructor.isPreLayout(designId);
  if (isPreLayout) {
    const preData = preLayoutData.getLocalPreLayout(designId);
    const { content = {} } = preData;
    const { powerNets = [], referenceNets } = content;
    return [...powerNets, ...referenceNets]
  }

  const { connectors, powerDomains = [] } = layout;
  const connectorRule = (item) => { return item.from.length && item.from.every(f => f.designId !== designId) }
  const setting = await componentSetting.getPrefixLib(designId);
  const doNotStuff = await componentDoNotStuff.getDoNotStuff(designId);
  const table = await compTableHelper.getTableData(designId);
  const pinConnection = await compPinMap.getPinConnection(designId)

  let connectNets = [];
  if (designId === rootPCBId) {
    if (!powerDomains.length) {
      return []
    }
    for (let powerDomain of powerDomains) {
      const { MAIN_POWER_NETS, MAIN_REF_NETS } = powerDomain.content
      const _getPMIC = new GetPMICs({
        ReferenceNets: [...MAIN_REF_NETS],
        PowerNets: [...MAIN_POWER_NETS],
        designId,
        extraNets: [],
        COMP_PREFIX_LIB: setting,
        findExtend: true,
        ExistNets: [],
        connectInductance: false,
        chips: [rootTargetIC],
        isAC: true,
        PMIC: getPMIC(setting),
        powerSwitch: setting.powerSwitch,
        doNotStuff,
        buckConverter: setting.discreteBuckConverter || [],
        isTemplate: false,
        pinConnection,
        componentTable: table,
        pinMap: [],
        specify: [{ name: connName }],
        fuzzyMatch: true
      })
      const paths = _getPMIC.getPaths();
      if (paths.length) {
        paths.forEach(path => {
          connectNets.push(path[path.length - 2].name)
        })
        connectNets.push(...MAIN_REF_NETS)
      }
    }
  } else {
    const { trueArray: fromConnectors } = splitArrayToArrays({ array: connectors.filter(item => item.from.length || item.to.length), rule: connectorRule });
    for (let connector of fromConnectors) {
      const { name, from } = connector;
      let powerNets = [], referenceNets = [];
      for (let f of from) {
        const { nets } = f;
        if (!nets) {
          continue;
        }
        const _nets = nets[`${name}-${designId}`];
        if (!_nets) {
          continue
        }
        let groundNet = getConnectionGroundNet({ designId, name, currentDesignId: rootPCBId, targetIC: rootTargetIC, setting, data });
        if (!groundNet) {
          const obj = getPowerDomain({ chip: name, designId });
          groundNet = obj ? obj.groundNet : ''
        }
        powerNets.push(...(_nets.filter(net => net !== groundNet) || []))
        referenceNets.push(...([groundNet] || []))
      }
      for (let powerNet of [...new Set(powerNets)]) {
        const _getPMIC = new GetPMICs({
          PowerNets: [powerNet],
          ReferenceNets: [...referenceNets],
          designId,
          extraNets: [],
          COMP_PREFIX_LIB: setting,
          findExtend: true,
          ExistNets: [],
          connectInductance: false,
          chips: [name],
          isAC: true,
          PMIC: getPMIC(setting),
          powerSwitch: setting.powerSwitch,
          doNotStuff,
          buckConverter: setting.discreteBuckConverter || [],
          isTemplate: false,
          pinConnection,
          componentTable: table,
          pinMap: [],
          specify: [{ name: connName }],
          fuzzyMatch: true
        })
        const paths = _getPMIC.getPaths();
        if (paths.length) {
          paths.forEach(path => {
            connectNets.push(path[path.length - 2].name)
          })
          connectNets.push(...referenceNets)
        }
      }
    }
  }
  return [...new Set(connectNets)]
}

export {
  getAllCascadeComponents,
  getirPDNComponentsByNets,
  componentsFilter,
  getConnectNets,
  getPowerDomain,
  getVRMComponents,
  getExtendedNetByVRM,
  getNets,
  _getVRM,
  getReferenceComps,
  getPMIC,
  CascadeCompRLCPrefixLib,
  CascadeCompPrefixVersion,
  checkConnectComp,
  getSelectedSetupIDs,
  getComponentByPartName,
  findInputPinByOutput,
  getPMICInPCB,
  addMultiNetsFieldToCapComps,
  getComponentByComponentName,
  initDiscreteBuckConverter,
  getPackagePowerDomain,
  getPkgDomainInfo,
  getNewPackageComp,
  resolveDriverModel,
  getMultiPortResPart,
  replacePinMapTable,
  matchPinMap,
  changeNewConnectors,
  getComponentColor,
  getFindVRMSpecifyComp,
  checkConnectCompsPinsAndNets,
  getCascadeComponents,
  getPreLayoutPinMap,
  getPreLayoutSetting,
  getAllCascadeNets,
  getAutoConnectorNets
}