import React, { Component, Fragment } from 'react';
import { Checkbox, Collapse, Input, Switch } from 'antd';
import { PCIE, ETHERNET } from '@/services/PCBHelper/constants';
import TagsInput from '@/components/TagsInput';
import { PACKAGE } from '../../constants/designType';
import './index.css'

class CreateChannelAdvanced extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: true,
    }
  }

  checkoutChange = (e, type) => {
    this.props.updateAdvancedConfig({ ...this.props.advancedConfig, [type]: e.target.checked });
  }

  valueChange = (e, type) => {
    this.props.updateAdvancedConfig({ ...this.props.advancedConfig, [type]: e.target.value }, type);
  }

  switchValueChange = (value, type) => {
    this.props.updateAdvancedConfig({ ...this.props.advancedConfig, [type]: value });
  }

  changeTagList = (type, list) => {
    this.props.updateAdvancedConfig({ ...this.props.advancedConfig, [type]: list });
  }

  render = () => {
    const { designType, defaultActiveKey, serdesType } = this.props;
    return designType === PACKAGE || serdesType === "Custom" ?
      <div className='aurora-create-advanced-content'>{this.contentRender()}</div>
      : <Collapse
        bordered={false}
        className='aurora-create-advanced-content'
        defaultActiveKey={defaultActiveKey || []}
        items={[
          {
            key: "advanced",
            label: "Advanced Channel Search Criteria",
            children: this.contentRender()
          }
        ]}
      />
  }

  getNetNamePrefixRender = ({ itemData, advancedConfig, isRegular, isSuffixIcon }) => {
    const regularKey = itemData.key === 'EXCLUDE' ? "excludeIsRegular" : "isRegular";
    return <div key={itemData.key} className="aurora-create-advanced-input-item">
      <label className='create-edit-input-label'>{itemData.title}</label>
      <TagsInput
        type={itemData.key}
        tagList={advancedConfig[itemData.key] || []}
        changeTagList={this.changeTagList}
        className={isRegular ? 'aurora-select-regular-input' : 'aurora-not-select-regular-input'}
        isSuffix={isSuffixIcon(advancedConfig, regularKey)}
      />
    </div>
  }

  contentRender = () => {
    const { advancedConfig, serdesType, advancedInputList, designType, isSuffixIcon } = this.props;

    return <Fragment>
      {advancedInputList.map(item =>
        (item.key === 'netNamePrefix' && item.title === 'Net name keywords')
          || item.key === "EXCLUDE" ?
          this.getNetNamePrefixRender({
            itemData: item,
            isSuffixIcon,
            isRegular: item.key === "EXCLUDE" ? advancedConfig.excludeIsRegular : advancedConfig.isRegular,
            advancedConfig
          }) :
          !["txFlag", "rxFlag", "isDifferential", "allowVoltageKeywords"].includes(item.key) || [PCIE, ETHERNET].includes(serdesType) ? <div key={item.key} className="aurora-create-advanced-input-item">
            <label className='create-edit-input-label'>{item.title}</label>
            {item.multiple ?
              <TagsInput
                type={item.key}
                tagList={advancedConfig[item.key]}
                changeTagList={this.changeTagList}
              />
              :
              <Input
                className='aurora-input create-content-input'
                value={advancedConfig[item.key]}
                placeholder={item.title}
                onChange={(e) => this.valueChange(e, item.key)}
              />}
          </div> :
            ["isDifferential"].includes(item.key) ? <div key={item.key} className={`aurora-create-advanced-input-item aurora-create-advanced-input-switch`}>
              <label className={`create-edit-input-label create-edit-input-label-${item.key}`}>{item.title}</label>
              <Switch
                size="small"
                className="aurora-switch-small create-advanced-switch"
                checked={advancedConfig[item.key]}
                onChange={(checked) => this.switchValueChange(checked, item.key)}
              />
            </div> : ['allowVoltageKeywords'].includes(item.key) ? <div key={item.key} className={`aurora-create-advanced-input-item aurora-create-advanced-input-checkout`}>
              <Checkbox checked={advancedConfig[item.key]} onChange={e => this.checkoutChange(e, item.key)} >{item.title}</Checkbox>
            </div> : null)}
      {/* CLK */}
      {[PCIE, ETHERNET].includes(serdesType) && designType !== PACKAGE ? <div className="aurora-create-advanced-input-item aurora-create-advanced-checkbox-item">
        <label className='create-edit-input-label'>Include {[PCIE, ETHERNET].includes(serdesType) ? 'REF' : ""} CLK</label>
        <Checkbox
          onChange={e => this.checkoutChange(e, 'addCLK')}
          checked={advancedConfig.addCLK}
        />
      </div> : null}
    </Fragment>
  }
}


export default CreateChannelAdvanced;