import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Divider } from 'antd';
import Monitor from '@/components/Monitor/detailMonitor'
import { DESIGN_TREE, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE } from '../../../../constants/treeConstants';
import './style.css';

class DetailMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null
    }
    this.scrollRef = createRef();
  }

  getPowerTreeDetail = () => {
    const { treeMonitorLogs } = this.props;
    return treeMonitorLogs.map(log => <span className={`cascade-power-tree-detail-tree-${log.type}`}>{log.text}</span>)
  }

  getSignoffTemplate = () => {
    const { signOffTemplateLogs } = this.props;
    return signOffTemplateLogs.map(log => <span className={`cascade-power-tree-detail-tree-${log.type}`}>{log.text}</span>)
  }

  getImpedance = () => {
    const { impedanceLogs } = this.props;
    return impedanceLogs.map(log => <span className={`cascade-power-tree-detail-tree-${log.type}`}>{log.text}</span>)
  }

  render() {
    const { viewList } = this.props;
    let debugVRMMonitor = [];
    if (viewList.includes(DESIGN_TREE) || viewList.includes(POWER_TREE) || viewList.includes(SINGLE_TREE)) {
      debugVRMMonitor = this.getPowerTreeDetail();
    } else if (viewList.includes(SIGN_OFF_TEMPLATE)) {
      debugVRMMonitor = this.getSignoffTemplate()
    } else if (viewList.includes(IMPEDANCE)) {
      debugVRMMonitor = this.getImpedance()
    }
    return (
      <Monitor {...this.props} debugVRMMonitor={debugVRMMonitor} />
    )
  }
}

const mapState = (state) => {
  const { monitorScreenInfo: {
    projectName }
  } = state.MonitorInfoReducer;
  const { project: { viewList }, DesignTree: { treeMonitorLogs }, SignOffTemplate: { signOffTemplateLogs }, Impedance: { impedanceLogs } } = state.CascadeReducer
  return {
    projectName,
    treeMonitorLogs,
    viewList,
    signOffTemplateLogs,
    impedanceLogs
  }
}

export default connect(mapState, null)(DetailMonitor);