import LayoutData from '../../data/LayoutData';
import { checkRLCValue } from '../dataProcess';
import { resetDefaultValue } from '../numberHelper';
import {
  RLComponent,
  CapComponent,
  BasicComponent,
  Model,
} from "./integratedinterface";
import { IND, JUMPER, RES, getTypeFromPartName } from "../../PCBHelper/components";
import { FASTPI, SIERRA } from "../../../constants/pageType";
import { getRLCCompValue } from '../../Sierra/setupHelper';
import { getCompTypeByPrefixLib } from '../../Designs/helper';
import { getCompType } from '../../Designs/compTypeHelper';

function getComponentsWithNetList(netList, pcbId, COMP_PREFIX_LIB, product) {
  const CompsInfo = getLayoutComponents({ pcbId, COMP_PREFIX_LIB, product });
  let components = []; // [{ pin, name, net, value, type, part }]
  if (!CompsInfo || Object.keys(CompsInfo).length === 0) {
    return components;
  }

  const layout = LayoutData.getLayout(pcbId);
  const _netList = [...new Set([...netList])];

  for (let netName of _netList) {
    const pinList = getPinList(netName, layout);
    if (!pinList) {
      continue;
    }
    const list = pinList.map((pin) => {
      const compInfo = CompsInfo[pin.mCompName];
      let _compInfo = compInfo;
      if (!compInfo) {
        _compInfo = {
          type: "Unused",
          value: "",
          part: "UNKNOW",
        };
      }

      const { type, value, part, location, pinList: _pinList = [] } = _compInfo;

      const pinInfo = _pinList.find((p) => p.mNumber === pin.mPinNum);
      let pinName = "";
      if (pinInfo) {
        pinName = pinInfo.mName;
      }

      return {
        pin: pin.mPinNum,
        name: pin.mCompName,
        pinName,
        net: netName,
        value: ["Res", "Ind", "Cap", "Jumper", "Ferrite"].includes(type)
          ? value
          : "",
        type: type === "Connector" ? "Ignore" : type,
        part,
        location,
      };
    });
    components.push(...list);
  }
  return components;
}

function getPinList(netName, layout) {
  const netObj = layout.mNetManager.GetNetFromName(netName);
  if (!netObj) {
    return null;
  }
  return netObj.mPinList; // mLayerName, mCompName, mPinNum, mMetalLayerName
}

function getLayoutComponents({ pcbId, COMP_PREFIX_LIB, product }) {
  const layout = LayoutData.getLayout(pcbId);
  return componentList(layout, COMP_PREFIX_LIB, product);
}

function componentList(layout, COMP_PREFIX_LIB, product) {
  const layerMgr = layout ? layout.GetLayerManager() : null;
  let compList = [];
  let layoutCompList = {};
  if (layerMgr && layerMgr.mMetalLayers && layerMgr.mMetalLayers.length > 0) {
    layerMgr.mMetalLayers.forEach((item) => {
      if (item.mComponentLayer !== null) {
        compList.push(item.mComponentLayer);
      }
    });
  }
  compList.forEach((layer) => {
    let comps = layer.mComponents;
    if (comps) {
      for (let component of comps) {
        layoutCompList[component.mName] = {
          comp: component,
          layer: layer.mName,
        };
      }
    }
  });

  //update the component information
  for (var compName in layoutCompList) {
    var compInfo = layoutCompList[compName];
    compInfo.name = compName;
    compInfo.part = compInfo.comp && compInfo.comp.mPart && compInfo.comp.mPart.mInfo ? compInfo.comp.mPart.mInfo.mPartName : "";
    compInfo.value = compInfo.comp && compInfo.comp.mPart && compInfo.comp.mPart.mInfo && compInfo.comp.mPart.mInfo.mPhyProps ? compInfo.comp.mPart.mInfo.mPhyProps.getValue('value') : "";
    compInfo.location = compInfo.comp && compInfo.comp.mLocation ? compInfo.comp.mLocation.mPosition : null;
    compInfo.pinList = compInfo.comp && compInfo.comp.mPart ? compInfo.comp.mPart.mPinList : [];

    if (
      compInfo.comp.mPart.mInfo.mPartType &&
      compInfo.comp.mPart.mInfo.mPartType.length > 0
    ) {
      compInfo.type = compInfo.comp.mPart.mInfo.mPartType;
      var type = compInfo.type.toUpperCase();
      if (type === "R" || type === "RES" || type === "RESISTOR") {
        compInfo.type = "Res";
      } else if (type === "C" || type === "CAP" || type === "CAPACITOR") {
        compInfo.type = "Cap";
      } else if (type === "L" || type === "IND" || type === "INDUCTOR") {
        compInfo.type = "Ind";
      } else if (product !== SIERRA) {
        compInfo.type = getUsage(
          compName,
          COMP_PREFIX_LIB,
          compInfo.pinList.length
        );
      }
    } else if (product !== SIERRA) {
      compInfo.type = getUsage(
        compName,
        COMP_PREFIX_LIB,
        compInfo.pinList.length
      );
    }

    if (product === SIERRA) {
      compInfo.type = getCompType({ compName, pinLength: compInfo.pinList.length, partName: compInfo.part, COMP_PREFIX_LIB, product })
    } else {
      if ((COMP_PREFIX_LIB && compInfo.type === "Ignore") || !COMP_PREFIX_LIB) {
        const _type = getTypeFromPartName(compInfo.part);
        if (_type) {
          compInfo.type = _type;
        }
      }
      if (compInfo.pinList.length > 2 && compInfo.type === "Jumper") {
        compInfo.type = "Ignore";
      }
      if (
        COMP_PREFIX_LIB &&
        COMP_PREFIX_LIB.powerSwitch &&
        COMP_PREFIX_LIB.powerSwitch.includes(compInfo.part)
      ) {
        compInfo.type = "Switch";
      }
    }
    delete compInfo.comp;
  }
  return layoutCompList;
}

function getUsage(compName, COMP_PREFIX_LIB, pinLength) {
  let type = "Ignore";
  if (toString.call(compName) !== "[object String]") {
    return type;
  }
  const name = compName.toString();
  const words = name.split(/^([A-Za-z]+)(([0-9]+)|-|_)/g);
  if (words.length < 2) {
    return type;
  }
  if (!words[1]) {
    return type;
  }
  switch (words[1].toLowerCase()) {
    case "c":
    case "ct":
      type = "Cap";
      break;
    case "r":
    case "rk":
    case "ra":
    case "rt":
    case "rz":
    case "rl":
    case "vr":
      type = "Res";
      break;
    case "l":
      type = "Ind";
      break;
    case "u":
      type = "Ignore";
      break;
    case "j":
      type = "Ignore";
      break;
    default:
      type = "Ignore";
      break;
  }

  if (type === "Cap" && (pinLength > 10 || pinLength < 2)) {
    type = "Ignore";
  }
  // Judging type by custom component reference designator.
  if (COMP_PREFIX_LIB) {
    type = getCompTypeByPrefixLib(name, COMP_PREFIX_LIB);
  }
  return type;
}

function getComponents(netList, pcbId, COMP_PREFIX_LIB, product, doNotStuff, specify = []) {
  let Components = [],
    exsitcomponentsName = [],
    findVRMComps = [],
    findCaps = [];
  const _Components = getComponentsWithNetList(netList, pcbId, COMP_PREFIX_LIB, product);
  for (const comp of _Components) {
    const { name, pin, net, value, type, part, pinName } = comp;
    const _index = exsitcomponentsName.indexOf(name);
    if ((doNotStuff || []).includes(name)) {
      continue;
    }
    if (_index < 0) {
      // Not exsit component
      let newComp;
      if (type === "Cap") {
        newComp = new CapComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net, pinName, mName: pinName }],
        });
        if (product === SIERRA) {
          const { value: _value, model } = getRLCCompValue({
            part,
            value,
            type
          })
          newComp.model = model;
          newComp.value = _value;
        } else {
          newComp.value = {
            r: "",
            l: "",
            c: "",
          };
          if (product === FASTPI) {
            newComp.models = [new Model({ type: "", name: "", subcktName: "" })];
          } else {
            newComp.model = new Model({ type: "", name: "", subcktName: "" });
          }
        }
        findCaps.push({ ...newComp, location: comp.location });
      } else if (type === RES || type === IND || type === JUMPER || specify.includes(name)) {
        let _value = checkRLCValue(value);
        if (product === SIERRA) {
          const { value: _value_, model } = getRLCCompValue({
            part,
            value,
            type
          })
          newComp = new RLComponent({
            name,
            usage: type,
            part,
            pins: [{ pin, net, pinName, mName: pinName }],
            value: _value,
          });
          newComp.model = model;
          newComp.value = _value_;
        } else {
          if (type === RES) {
            //resistor default value to 0 that is smaller than 10 mOhm
            _value = resetDefaultValue(_value);
          }
          newComp = new RLComponent({
            name,
            usage: type,
            part,
            pins: [{ pin, net, pinName, mName: pinName }],
            value: _value,
          });
        }
        findVRMComps.push({ ...newComp, pinList: newComp.pins, location: comp.location });
      } else {
        newComp = new BasicComponent({
          name,
          usage: type,
          part,
          pins: [{ pin, net, pinName, mName: pinName }],
        });
      }
      Components.push(newComp);
      // Update components name list
      exsitcomponentsName.push(name);
    } else {
      // Exsit component
      Components[_index].pins.push({ pin, net, pinName });
    }
  }
  return { Components, findVRMComps, findCaps };
}

export {
  getComponentsWithNetList,
  getLayoutComponents,
  componentList,
  getUsage,
  getComponents,
};
