import LayoutData from '../../data/LayoutData';
import { getComponentByName } from '../../PCBHelper';

let layout = {};

function componentFilter({ Components, PowerNets, ReferenceNets }) {
  const _Components = JSON.parse(JSON.stringify(Components));
  const _filter = _Components.filter(comp => {
    const nets = comp.pins.map(item => item.net);
    if (nets.every(net => ReferenceNets.indexOf(net) > -1)) {
      // Component only connect to referenceNets
      if ([...new Set(nets)].length > 1) {
        return comp;
      } else {
        return false;
        // The component only connect to single ground net.
      }
    } else if (nets.every(net => PowerNets.indexOf(net) > -1)) {
      // Component only connect to PowerNets
      if ([...new Set(nets)].length > 1) {
        return comp;
      } else {
        if (comp.usage !== 'Cap') {
          // If the component is only connected to single power net and the usage is not 'Cap', the usage is set to Ignore.
          comp.usage = 'Ignore';
          return comp;
        } else {
          return false;
          // Remove the 'Cap' components;
        }
      }
    } else {
      // The component connect to power and ground;
      return comp;
    }
  });
  return _filter;
};

function getNetsListByComps(comps, filterNets, pcbId) {
  if (!layout[pcbId]) {
    layout[pcbId] = LayoutData.getLayout(pcbId, true);
  };
  if (!layout[pcbId]) {
    return {};
  }
  const netsList = layout[pcbId].mNetManager.mNetList.mVals.filter(item =>
    !filterNets.includes(item.mName)
    && item.mName !== 'NONET');

  let compsInfo = {};
  const _comps = comps.map(item => item.name);
  for (let net of netsList) {
    const components = net.mPinList.map(item => item.mCompName);
    for (let comp of components) {
      if (_comps.includes(comp)) {
        if (compsInfo[comp]) {
          compsInfo[comp].push(net.mName)
        } else {
          compsInfo[comp] = [net.mName];
        };
      }
    }
  };
  return compsInfo;
}

function getComponentsByNets({ netList = [], pcbId, COMP_PREFIX_LIB, layers, getLayoutComponents, powerSwitch = [] }) {
  if (!layout[pcbId]) {
    layout[pcbId] = LayoutData.getLayout(pcbId, true);
  };

  if (!layout[pcbId]) {
    return []
  }

  return findCompsByNetList({ pcbId, netList, COMP_PREFIX_LIB, layers, getLayoutComponents, powerSwitch });
};

function findCompsByNetList({ pcbId, netList = [], COMP_PREFIX_LIB, layers, getLayoutComponents, powerSwitch }) {
  let components = [];
  const CompsInfo = getLayoutComponents({ pcbId, COMP_PREFIX_LIB, layers, powerSwitch });   // { Comp: { name, part, value, type, pinList, location} }

  if (!CompsInfo) {
    return components;
  }

  // [{ pin, name, net, value, type, part }]
  let componentsName = [];
  netList.forEach(netName => {
    const pinList = getPinList(netName, pcbId);
    if (pinList) {
      let list = [];
      for (let pinInfo of pinList) {
        const name = pinInfo.mCompName;
        if (componentsName.includes(name)) {
          continue;
        }
        componentsName.push(name);
        const compInfo = CompsInfo[name];
        if (compInfo) {
          const { type = "Ignore", part, location } = compInfo;
          list.push({
            name: name,
            net: netName,
            type,
            part,
            location,
            pinList: compInfo.pinList
          });
        }
      }
      components.push(...list);
    }
  });
  return components;
}

function _getNetsListByComp(compName, filterNets, pcbId, type = "netName") {
  if (!layout[pcbId]) {
    layout[pcbId] = LayoutData.getLayout(pcbId, true);
  };
  if (!layout[pcbId]) {
    return [];
  }
  const netsList = layout[pcbId].mNetManager.mNetList.mVals;
  return findNetListByComp({ compName, netsList, filterNets, type });
}

function findNetListByComp({ compName, netsList, filterNets = [], type = "netName", compReg, designId }) {
  const netList = netsList.filter(item =>
    !filterNets.includes(item.mName)
    && item.mName !== 'NONET');

  let arr = [];
  for (let net of netList) {
    const components = net.mPinList.map(item => item.mCompName);
    for (let comp of components) {
      const compInfo = designId ? getComponentByName(designId, comp) : null;
      if (comp === compName || (compReg && (comp.match(compReg) || (compInfo && compInfo.mPart.mInfo.mPartName.match(compReg))))) {
        const netInfo = type === "netName" ? net.mName : net;
        arr.push(netInfo)
      };
    }
  };
  return arr;
}

function getPinList(netName, pcbId, compName) {
  if (!layout[pcbId]) {
    layout[pcbId] = LayoutData.getLayout(pcbId, true);
  };
  if (!layout[pcbId]) {
    return []
  }
  const netObj = layout[pcbId].mNetManager.GetNetFromName(netName);
  if (!netObj) {
    return null;
  }
  if (compName) {
    return netObj.mPinList.filter(item => item.mCompName === compName);
  }
  return netObj.mPinList; // mLayerName, mCompName, mPinNum, mMetalLayerName
};

function findNetListByNetName(netName, pcbId) {
  if (!layout[pcbId]) {
    layout[pcbId] = LayoutData.getLayout(pcbId, true);
  };
  if (!layout[pcbId]) {
    return {};
  }
  const netsList = layout[pcbId].mNetManager.mNetList.mVals.filter(item =>
    netName.includes(item.mName)
    && item.mName !== 'NONET');
  return netsList;
}

function getNetPinList(nets, pcbId, compName) {
  let netPinList = [];
  for (let net of nets) {
    const pinList = getPinList(net, pcbId, compName) || [];
    netPinList.push(...pinList.map(item => { return { ...item, net } }));
  }
  return netPinList;
}

export {
  componentFilter,
  getNetsListByComps,
  getComponentsByNets,
  _getNetsListByComp,
  findNetListByComp,
  getPinList,
  findCompsByNetList,
  findNetListByNetName,
  getNetPinList
};
