import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { PCB_CHANNEL, PCB_PRE_LAYOUT, VERIFICATION, PCB, RESULT, PCB_CHANNEL_RESULT, DCR, IMPEDANCE, IR_EXPLORER, IR_EXPLORER_RESULT, IMPEDANCE_RESULT, POWER_TREE, PACKAGE, PDN, DESIGN_TREE, SINGLE_TREE, PACKAGE_PDN, PACKAGE_PDN_RESULT, PCB_PDN, PCB_PDN_RESULT } from '../../constants/treeConstants';
import { LEFT_RIGHT_lAYOUT, SINGLE_PAGE_LAYOUT, TOP_BOTTOM_LAYOUT } from '../../constants/layoutConstants';
import SplitPCBWindow from './splitPCBWindow';
import '@/publicCss/tree.css';
import './index.css';

const setupViewList = [VERIFICATION, RESULT, PCB_CHANNEL_RESULT, PCB_CHANNEL, DCR, IMPEDANCE, IR_EXPLORER, IR_EXPLORER_RESULT, IMPEDANCE_RESULT, POWER_TREE, PDN, DESIGN_TREE, SINGLE_TREE, PACKAGE_PDN, PACKAGE_PDN_RESULT, PCB_PDN, PCB_PDN_RESULT];
class SideFooter extends Component {

  _layoutClick = (e, layoutType, singleType) => {
    e && e.stopPropagation();
    this.props.actions.layoutClick(layoutType, singleType);
  }

  _changePCBLayout = (pcbLayout) => {
    this.props.actions.pcbLayoutChange(pcbLayout)
  }

  render = () => {
    const { openMenu, viewList, tabVisible, layout, currentProjectId, changeTabFooter, showPCBSplit = true, pcbLayout } = this.props.actions;
    const style = !openMenu ? { height: 'auto', padding: '10px 0' } : { paddingLeft: 10 };
    let disabledClassName = currentProjectId ? '' : 'view-icon-disabled';
    let layoutClassName = currentProjectId ? '' : 'layout-icon-disabled';
    let openClassName = openMenu ? 'sider-switch-icon' : 'sider-switch-vertical-icon';
    let openLayout = openMenu ? 'sider-layout-div' : 'sider-layout-vertical-div';
    const placement = openMenu ? 'top' : 'left';
    const setupClassName = (layout === SINGLE_PAGE_LAYOUT && setupViewList.find(item => viewList.includes(item))) ? 'aurora-switch-icon-selected' : 'aurora-switch-icon-disabled';
    const pcbClassName = layout === SINGLE_PAGE_LAYOUT && [PCB, PCB_PRE_LAYOUT, PACKAGE].find(item => viewList.includes(item)) ? 'aurora-switch-icon-selected' : 'aurora-switch-icon-disabled';

    return <div className='aurora-sider-footer' style={style}>
      {/* setup icon */}
      <div className={openClassName}>
        <Tooltip title='Setup' overlayClassName='aurora-tooltip' placement={placement}>
          <span
            className={`iconfont icon-setup ${disabledClassName} ${setupClassName}`}
            onClick={(e) => this._layoutClick(e, SINGLE_PAGE_LAYOUT, 'setup')}
          ></span>
        </Tooltip>
      </div>
      {/* pcb icon */}
      <div className={openClassName}>
        <Tooltip title={showPCBSplit ? <SplitPCBWindow openMenu={openMenu} changePCBLayout={this._changePCBLayout} pcbLayout={pcbLayout} /> : 'PCB'} overlayClassName='aurora-tooltip' placement={placement}>
          <span
            className={`iconfont icon-tool-xinpian- pcb-icon ${disabledClassName} ${pcbClassName}`}
            onClick={(e) => this._layoutClick(e, SINGLE_PAGE_LAYOUT, PCB)}
          ></span>
        </Tooltip>
      </div>
      {/* layout left and right icon */}
      <div className={openLayout}>
        <Tooltip title='Split Screen' overlayClassName='aurora-tooltip' placement={placement}>
          <div
            className={layout === LEFT_RIGHT_lAYOUT ? `aurora-layout-button-1 aurora-layout-selected ${layoutClassName}` : `aurora-layout-button-1 ${layoutClassName}`}
            onClick={(e) => this._layoutClick(e, LEFT_RIGHT_lAYOUT)}
          >
            <div className='aurora-layout-button-left'></div>
            <div className='aurora-layout-button-right'></div>
          </div>
        </Tooltip>
      </div>
      {/* layout top and bottom icon */}
      <div className={openLayout}>
        <Tooltip title='Split Screen' overlayClassName='aurora-tooltip' placement={placement}>
          <div
            className={layout === TOP_BOTTOM_LAYOUT ? `aurora-layout-button-2 aurora-layout-selected ${layoutClassName}` : `aurora-layout-button-2 ${layoutClassName}`}
            onClick={(e) => this._layoutClick(e, TOP_BOTTOM_LAYOUT)}
          >
            <div className='aurora-layout-button-top'></div>
            <div className='aurora-layout-button-bottom'></div>
          </div>
        </Tooltip>
      </div>
      {/* monitor box icon */}
      <div className={openClassName}>
        <Tooltip title='Monitor' overlayClassName='aurora-tooltip' placement={placement}>
          <span
            className={`iconfont icon-Monitor ${tabVisible ? 'aurora-switch-icon-selected' : ''}`}
            onClick={() => changeTabFooter()}
          ></span>
        </Tooltip>
      </div>
    </div>
  }
}
export default SideFooter;