import React, { Component, Fragment } from "react";
import { createPortal } from 'react-dom';
import { CloseCircleFilled, /* InfoCircleOutlined */ } from '@ant-design/icons';
import { Select, Checkbox, Spin, Divider, Radio } from "antd";
import Panel from '@/components/Panel';
import TreeSelect from '@/components/TreeSelect';
import SingleModel from "./singleModel";
import { ROCKY, SIERRA } from "../../constants/pageType";
import TemplatePanel from './TemplatePanel';
import SetSPNodeModel from '../pinNodeConnect/multiPinSPICEModel';
import {
  assignModelForInterfaces,
  AUTO_ASSIGN_MODEL,
  ASSIGN_MODEL_TO_ALL_INTERFACES,
  APPLY_MODEL_TO_ALL_INTERFACES,
  APPLY_MODEL_TO_SETUP,
  APPLY_PKG
} from '@/services/Sierra/helper/assignIBISModelsHelper.js'
import { updateChannelConfig } from '@/services/Rocky/projectCtrl';
import '@/publicCss/style.css';
import './index.css';


const Option = Select.Option;
const HSPICE = 'HSPICE', STATEYE = 'StatEye';
const SINGLE_TYPE = ['IBIS', 'SPICE'], IBIS = 'IBIS', SPICE = "SPICE", IBIS_AMI = "IBIS_AMI", MULTI_PIN_SPICE = 'MultiPinSPICE', NEW_SINGLE_TYPE = ['IBIS', 'IBIS_AMI', 'SPICE'];

class ICModelPanel extends Component {
  constructor(props) {
    super(props);
    const { compModel, product, record } = props;
    const { libType, _compModel } = this.getModel();

    let _model = { files: [], pairs: [] }, _pairs = this.getPairs();
    if (product === SIERRA && compModel) {
      _model = JSON.parse(JSON.stringify(compModel));
      _pairs = _model.pairs && _model.pairs.length > 0 ? _model.pairs : _pairs;
    }

    const _applyModelObj = record.applyModelObj ? { ...record.applyModelObj } : {};
    this.state = {
      libType,
      TemplateVisible: false,
      files: Array.isArray(_model.files) ? _model.files : [],
      components: [],
      /* 
      applyAllStatus: false, */
      loading: false,
      prevFile: null,
      pairs: _pairs,
      _compModel,
      maxHeight: 600,
      maxWidth: 730,
      applyModelObj: {
        [AUTO_ASSIGN_MODEL]: true,
        [ASSIGN_MODEL_TO_ALL_INTERFACES]: false, //all same part components
        [APPLY_MODEL_TO_SETUP]: false, //all ic components
        [APPLY_MODEL_TO_ALL_INTERFACES]: false, //all ic components
        [APPLY_PKG]: true, //Assign ibis file to pkg model
        ..._applyModelObj
      },
      bufferSelectionWidth: product === ROCKY && record.model && record.model.folder && ![IBIS_AMI, IBIS].includes(record.model.libType) ? "100%" : "50%",
      valueValidateStatus: true
    }
    this.dialogRoot = document.getElementById('root');
  }

  getMaxHeight = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { height } = offset;
      if (!height) return 800;
      const _height = (height - 100) * 0.77;
      return _height > 1200 ? 1200 : _height;
    }
  }

  getMaxWidth = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { width } = offset;
      if (!width) return 730;
      const _width = width * 0.8;
      return _width > 730 ? 730 : _width;
    }
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId } = this.props;
    if (verificationId !== prevProps.verificationId) {
      this.props.save();
    }
  }

  componentDidMount = () => {
    const { record } = this.props
    const prevFile = record.model && record.model.files && record.model.files.length > 0 && record.model.files[0]

    this.setState({
      prevFile: prevFile ? { ...prevFile } : {}
    })

    document.addEventListener('mousedown', this.handleClickOutside, true);
    window.addEventListener('resize', this.resize);
    this.resize();
    this.checkComponent()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({
      maxHeight: this.getMaxHeight(),
      maxWidth: this.getMaxWidth()
    })
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { className } = this.props;
    if (!className) {
      return;
    }
    const inputElements = document.getElementsByClassName(className);
    if (target && target.className && inputElements) {
      //open other BUFFER setup panel , close current panel
      const findTargetList = Array.prototype.filter.call(inputElements, function (testElement) {
        return testElement.className === target.className;
      });
      if (findTargetList && findTargetList.length > 0) {
        this.closeModal();
      }
    }
  }

  checkComponent = (selectFiles) => {
    const { getIbisPackageList, record, product, pdnInfo, isSev } = this.props
    // auto choose component when IBIS file already
    if (product === SIERRA) {
      const prevFile = record.model && record.model.files && record.model.files.length > 0 && record.model.files[0]

      if ((prevFile && prevFile.libraryId && prevFile.type === IBIS)
        || (selectFiles && selectFiles[0].libraryId && selectFiles[0].type === IBIS)) {
        const id = selectFiles ? selectFiles[0].libraryId : prevFile.libraryId
        const prevComponent = prevFile && prevFile.component

        getIbisPackageList(id).then(res => {
          if (res && res.length > 0) {
            const components = res.map(item => item.component)
            let file = selectFiles ? selectFiles[0] : prevFile
            const findComponent = components.length >= 1 ? components[0] : null
            file.component = selectFiles ? findComponent : (prevComponent || findComponent)
            this.setState({
              components: components,
              files: [file]
            })
          }
        })
      }
    }

    if (product === ROCKY && isSev && pdnInfo && pdnInfo.onDies && pdnInfo.onDies.length) {
      /* const model = record.model || {}; */
      const findCSM = pdnInfo.onDies.find(item => item.onDie && item.onDie.type === "CSM");
      //const csmModelExist = findCSM && findCSM.csmPairs && findCSM.csmPairs.find(item => item.csmSignal && item.pad && item.in);
      /*  const findModelFolder = onDieSpiceList.find(item => item.type === "folder" && item.id === model.libraryId && item.name === model.folder);
       const findModel = findModelFolder && findModelFolder.children ? findModelFolder.children.find(item => item.fileName === model.fileName) : null; */
      this.setState({
        isCSM: findCSM && record.type === "Controller" ? true : false,
        /*    csmModel: findModel ? findModelFolder : {} */
      })
    }
  }

  getModel = () => {
    let libType = SINGLE_TYPE[0], _compModel = {};
    const { record, compModel, displayType, product } = this.props;
    //_compModel :{ libType, fileName, libraryId }
    if (product === ROCKY) {
      _compModel = compModel;
    } else if (product === SIERRA && compModel && compModel.files && compModel.files.length) {
      const _model = compModel.files[0];
      _compModel = {
        libType: _model.type,
        libraryId: _model.libraryId,
        fileName: _model.fileName,
        version: _model.version
      };
    }

    if (displayType === 'model') {
      const model = record.model;
      libType = model && model.libType ? model.libType : libType;
      if ((!model || !model.libType) && _compModel && _compModel.libType) {
        libType = _compModel.libType;
      }
    } else if (displayType === 'file') {
      libType = _compModel && _compModel.libType ? _compModel.libType : libType;
    }

    return { libType, _compModel }
  }

  closeModal = () => {
    if (!this.state.valueValidateStatus) {
      return;
    }
    this.setState({
      loading: true
    }, () => this.saveICModel())
  }

  setValidate = (status) => {
    this.setState({
      valueValidateStatus: status
    })
  }

  getIbisModel = (type, fileName, fileId, libType) => {
    let obj = {
      libraryId: fileId,
      fileName,
      usage: type === 'tx' ? 'Tx' : 'Rx',
      libType
    }
    return this.props.getIbisModelList(obj);
  }

  getSpiceModel = (type, fileName, fileId) => {
    let obj = {
      libraryId: fileId,
      fileName,
      usage: type === 'tx' ? 'Tx' : 'Rx',
    }
    return this.props.getSpiceModelList(obj);
  }

  onRef = (ref) => {
    this.Child = ref;
  }

  onLibTypeChange = (libType) => {
    const { displayType } = this.props;

    if (libType !== this.state.libType) {
      if (this.Child && this.Child.onUseChange && displayType === 'model') {
        this.Child.onUseChange(libType);
      }
      this.setState({
        libType,
        files: [],
        pairs: this.getPairs(),
        bufferSelectionWidth: "50%"
      })
    }
  }

  getPairs = () => {
    let pairs = [];
    const { product } = this.props;
    if (product !== SIERRA) {
      return [];
    }
    const { pins, record: { usage } } = this.props;
    pins.forEach(item => {
      if (usage === 'Driver') {
        const pinIn = {
          pin: `${item.pin}_in`,
          node: "",
          libraryId: "",
          fileName: "",
          subckt: ""
        };
        const pinOut = {
          pin: `${item.pin}_u`,
          node: "",
          libraryId: "",
          fileName: "",
          subckt: ""
        };
        pairs = [...pairs, { ...pinIn }, { ...pinOut }];
      } else if (usage === 'Receiver') {
        let pinIn = {
          pin: `${item.pin}_u`,
          node: "",
          libraryId: "",
          fileName: "",
          subckt: ""
        };
        pairs = [...pairs, { ...pinIn }];
      }
    });
    return pairs;
  }

  // Close button, save model
  saveICModel = async () => {
    const { libType, files, pairs, prevFile, applyModelObj } = this.state;
    const { displayType, record, autoAssignStatus, currentProjectId, updateAssignStatus, product, verificationId,
      Config, updateCurrentConfig, currentChannelId, resultConfig, projectType, getDefaultClockAndTimeStep } = this.props;
    if (NEW_SINGLE_TYPE.includes(libType)) {
      if (displayType === 'file') {
        if ([IBIS, IBIS_AMI].includes(libType)) {
          if (autoAssignStatus && !autoAssignStatus.finish) return
          if (
            prevFile && libType === prevFile.type &&
            ((files && prevFile.libraryId && files[0] && files[0].libraryId === prevFile.libraryId && files[0].component === prevFile.component)
              || ((!files || files.length === 0 || !files[0].libraryId) && !prevFile.libraryId))) {
            this.setState({
              loading: false
            })
            this.props.save();
            return;
          }
          const file = (files && files[0] && files[0].libraryId) ? files[0] : null;
          if (product === SIERRA && !!file && (applyModelObj[ASSIGN_MODEL_TO_ALL_INTERFACES] || applyModelObj[APPLY_MODEL_TO_ALL_INTERFACES])) {
            await assignModelForInterfaces({
              file,
              part: record.part,
              projectId: currentProjectId,
              pcbId: record.pcbId,
              updateAssignStatus: updateAssignStatus,
              applyModelObj,
              verificationId
            })
          }
          // save 
          this.props.saveLibraryModel({ files, libType, pairs, record, displayType, applyModelObj });
        } else {
          if (libType !== prevFile.type) {
            this.props.saveLibraryModel({ files, libType, pairs, record, displayType });
          }
          if (
            libType === prevFile.type
            && (((!files || !files[0] || !files[0].libraryId) && (!prevFile || !prevFile.libraryId))
              || (files && files[0] && prevFile.libraryId && prevFile.libraryId === files[0].libraryId))) {
            this.setState({
              loading: false
            })
            this.props.save();
            return;
          }
          this.props.saveLibraryModel({ files, libType, pairs, record, displayType });
        }
      } else {
        this.Child && this.Child.saveModel();
      }

    } else if (libType === MULTI_PIN_SPICE) {
      const _modelInfo = this.Child.saveModel();
      this.props.saveLibraryModel({ files: _modelInfo.files, libType, pairs: _modelInfo.pairs, record, displayType });
    }
    let _config = { ...Config };
    let _channelConfig = { ...Config };

    // if select the ibis ami model, then change the simulation mode to statEye
    if (_config && (_config.simulate !== HSPICE || _config.simulateMode !== STATEYE)) {
      if (libType && libType === IBIS_AMI) {
        const defaultClockAndTimeStep = getDefaultClockAndTimeStep(projectType)
        const defaultClock = defaultClockAndTimeStep.clock;
        const defaultTimeStep = defaultClockAndTimeStep.timeStep;
        const statEyeConfig = {
          transientEngine: "HPP",
          simulationMode: "EDGE",
          edgesNum: "2",
          crosstalkType: "DDP",
          eyeDiagramTriggering: 1,
          eyeDiagramTriggeringClockMode: 2,
          numberBits: 100000,
        }
        _config.clock = {
          value: Config.clock && Config.clock.value ? Config.clock.value : '',
          unit: Config.clock && Config.clock.unit ? Config.clock.unit : 'M',
        }
        _channelConfig.clock = _config.clock.value && _config.clock.unit ? _config.clock.value + _config.clock.unit : defaultClock;

        _config.timeStep = {
          value: Config.timeStep && Config.timeStep.value ? Config.timeStep.value : '',
          unit: Config.timeStep && Config.timeStep.unit ? Config.timeStep.unit : 'n',
        }
        _channelConfig.timeStep = _config.timeStep.value && _config.timeStep.unit ? _config.timeStep.value + _config.timeStep.unit : defaultTimeStep;

        if (Config.caClock) {
          _config.caClock = {
            value: Config.caClock && Config.caClock.value ? Config.caClock.value : '',
            unit: Config.caClock && Config.caClock.unit ? Config.caClock.unit : 'M',
          }
          _channelConfig.caClock = _config.caClock.value && _config.caClock.unit ? _config.caClock.value + _config.caClock.unit : '';
        }

        if (Config.caTimeStep) {
          _config.caTimeStep = {
            value: Config.caTimeStep && Config.caTimeStep.value ? Config.caTimeStep.value : '',
            unit: Config.caTimeStep && Config.caTimeStep.unit ? Config.caTimeStep.unit : 'n',
          }
          _channelConfig.caTimeStep = _config.caTimeStep.value && _config.caTimeStep.unit ? _config.caTimeStep.value + _config.caTimeStep.unit : '';
        }
        _config.simulate = HSPICE;
        _config.simulateMode = STATEYE;
        _config.statEyeConfig = { ...statEyeConfig }
        _channelConfig.simulate = HSPICE;
        _channelConfig.simulateMode = STATEYE;
        _channelConfig.statEyeConfig = { ...statEyeConfig }
        updateCurrentConfig(_config);
        let params = {
          channelId: currentChannelId, config: _channelConfig, resultConfig
        }
        await updateChannelConfig(params);
      }
    }
    this.props.save();
  }

  TemplateCircuitFile = () => {
    this.setState({
      TemplateVisible: true
    });
  }

  closeTemplateModal = () => {
    this.setState({
      TemplateVisible: false
    });
  }

  setContentHeight = (time) => {
  }

  onFileChange = (key) => {
    const { files, libType } = this.state;
    const { ibisList, spiceList, ibisAmiList } = this.props;
    let _files = files;
    const fileList = libType === IBIS ? ibisList : libType === IBIS_AMI ? ibisAmiList : spiceList;
    const findF = fileList.find(item => item.name === key && item.type === 'file');
    if (!findF || !findF.id) {
      return;
    }
    _files = [{
      type: libType,
      libraryId: findF.id,
      fileName: key
    }]
    this.setState({
      files: _files
    })
  }

  onFolderFileChange = (item) => {
    const { id, libraryStructure, version } = item;
    const { libType } = this.state;
    if (!id || !libraryStructure) {
      return;
    }

    let _files = [{
      type: libType,
      libraryId: id,
      fileName: libraryStructure.name,
      version
    }]

    this.setState({
      files: _files
    })

    if (libType === IBIS) {
      // change component when file change
      this.checkComponent(_files)
    }
  }

  cleanModelFile = (e) => {
    e && e.stopPropagation();
    const { files } = this.state;
    const { product } = this.props;
    let _files = files;
    if (!_files) {
      return;
    }
    _files = _files.forEach(item => {
      item.libraryId = "";
      item.fileName = "";
      item.component = "";
      if (product === SIERRA) {
        item.version = "";
      }
    })
    this.setState({
      files: _files
    })
  }

  modelFileSelect = (product, fileName, fileList, libraryId) => {
    return product === SIERRA ?
      <TreeSelect
        value={fileName || undefined}
        onSelectItem={this.onFolderFileChange}
        size='small'
        showSearch
        popupClassName='IC-select-model-select-dropdown'
        className='IC-model-type-select'
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelFile(e)} /> }}
        fileList={fileList}
        getPopupContainer={() => document.getElementById('root')}
        selected={libraryId}
      />
      : <Select
        value={fileName || undefined}
        onChange={this.onFileChange}
        size='small'
        showSearch
        popupClassName='IC-select-model-select-dropdown'
        className='IC-model-type-select'
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelFile(e)} /> }}
        getPopupContainer={() => document.getElementById('root')}
      >
        {fileList.map((file, index) => <Option key={index} value={file.name} title={file.name}>{file.name}</Option>)}
      </Select>;
  }

  compsSelect = (component) => {
    return <Select
      value={component}
      size='small'
      onSelect={this.compSelectHandler}
      className='IC-model-type-select'
      popupClassName='IC-select-model-select-dropdown'
    >
      {this.state.components.map((item, index) => <Option key={index} value={item} title={item}>{item}</Option>)}
    </Select>
  }

  compSelectHandler = (value) => {
    let file = this.state.files[0]
    file.component = value
    this.setState({
      files: [file]
    })
  }

  updateAutoAssignModel = (e, key, type) => {
    e && e.stopPropagation && e.stopPropagation();
    const _applyModelObj = { ...this.state.applyModelObj }
    if (key === APPLY_PKG) {
      this.setState({
        applyModelObj: { ..._applyModelObj, [APPLY_PKG]: e.target.checked }
      })
      return;
    }
    const checked = !_applyModelObj[key];
    let anotherKey = null;
    if (type === "assign") {
      anotherKey = key === AUTO_ASSIGN_MODEL ? ASSIGN_MODEL_TO_ALL_INTERFACES : AUTO_ASSIGN_MODEL;
    } else {
      anotherKey = key === APPLY_MODEL_TO_SETUP ? APPLY_MODEL_TO_ALL_INTERFACES : APPLY_MODEL_TO_SETUP;
    }
    if (checked && anotherKey) {
      _applyModelObj[anotherKey] = false;
    }
    _applyModelObj[key] = checked;

    this.setState({
      applyModelObj: _applyModelObj
    })
  }

  updateBufferItemWidth = (width) => {
    this.setState({
      bufferSelectionWidth: width
    })
  }

  renderDialog = () => {
    const { record, componentName, product, ibisList, spiceList, displayType, autoAssignStatus, multipleModels = false, isMultiSetup, isSev, onDieSpiceList, ibisAmiList, compAmiModel } = this.props;
    const { pin, signal, usage, modelType } = record;
    const { libType, files, pairs, _compModel, maxHeight, maxWidth, loading, bufferSelectionWidth, applyModelObj } = this.state;
    const fileList = product !== SIERRA ?
      libType === IBIS ? ibisList.filter(item => item.type === 'file') : libType === IBIS_AMI ? ibisAmiList.filter(item => item.type === "folder") : spiceList.filter(item => item.type === 'file') :
      libType === IBIS ? ibisList : spiceList;
    const fileName = files && files.length > 0 ? files[0].fileName : null;
    const libraryId = files && files.length > 0 ? files[0].libraryId : '';
    const component = libraryId ? files[0].component : '';
    const spFileList = ![SIERRA, ROCKY].includes(product) || (product === ROCKY && !isSev) ? spiceList.filter(item => item.type === 'file') : spiceList;
    const BUFFER_OPTION = multipleModels ? [IBIS] : product === SIERRA && !isMultiSetup ? [...SINGLE_TYPE, /* MULTI_PIN_SPICE */] : product === ROCKY && !isSev ? NEW_SINGLE_TYPE : SINGLE_TYPE
    /*   const disabled = isCSM && !csmModel;
      const csmDisabled = isCSM && csmModel; */
    /*  const msg = disabled ? "Please set up CSM instance mapping in CSM first." : ""; */
    /* const _spFileList = csmModel && csmModel.id ? [{ ...csmModel }, ...spFileList] : spFileList; */
    const content = (
      <Panel
        className='IC-select-model-panel'
        title={<div className='IC-select-model-title'>{NEW_SINGLE_TYPE.includes(libType) && displayType === 'model' ? `${componentName} - ${pin} ${signal ? `${signal} ` : ""}${modelType ? modelType : usage} Model` : `${componentName}${usage ? ` ${usage}` : ""} Model`}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={maxWidth}
        position='panel-center'
        draggable
        mask={loading}
        minWidth={450 > maxWidth ? 450 : maxWidth}
        minHeight={200}
        maxHeight={maxHeight + 43}
        overflow={"hidden"}
      >
        <Spin tip={(autoAssignStatus && autoAssignStatus.msg) || 'waiting...'} spinning={loading} size="large">
          <div className='IC-select-model-content clear' id="IC-scrollId" style={{ maxHeight: maxHeight }}>
            <div className='IC-select-model-content-main' style={{ minWidth: libType === MULTI_PIN_SPICE && displayType === "model" ? 650 : null }}>
              <div
                style={{ width: product === ROCKY && bufferSelectionWidth ? bufferSelectionWidth : null }}
                className={product === ROCKY ? 'IC-select-dialog clear' : 'IC-select-dialog IC-multi-spice-select-dialog clear'}>
                <div className='IC-select-model-title-item'>Buffer
                {/*   {libType === SPICE && !isSev && <InfoCircleOutlined onClick={this.TemplateCircuitFile} className='IC-buffer-template-info' />} */}
                </div>
                <Select
                  value={libType}
                  onChange={this.onLibTypeChange}
                  getPopupContainer={() => document.getElementById('root')}
                  popupMatchSelectWidth={false}
                  size='small'
                  className='IC-model-type-select'
                  suffixIcon={null}
                  popupClassName='IC-select-model-select-dropdown'
                >
                  {BUFFER_OPTION.map((use, index) => <Option key={index} value={use}>{use}</Option>)}
                </Select>
              </div>
              {SINGLE_TYPE.includes(libType) && displayType === 'file' &&
                <Fragment>
                  <div className={product === ROCKY ? 'IC-select-dialog clear' : 'IC-select-dialog IC-multi-spice-select-dialog clear'}>
                    <div className='IC-select-model-title-item'>{product === ROCKY ? 'File' : 'Model File'}</div>
                    {this.modelFileSelect(product, fileName, fileList, libraryId)}
                  </div>
                  {product === SIERRA && libType === IBIS &&
                    <div>
                      <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <div className='IC-select-model-title-item'>Component</div>
                        {this.compsSelect(component)}
                      </div>
                      <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <Radio
                          checked={applyModelObj[AUTO_ASSIGN_MODEL]}
                          className='IC-apply-interface-title-radio'
                          onClick={(e) => this.updateAutoAssignModel(e, AUTO_ASSIGN_MODEL, "assign")}
                        >
                          Auto assign models to component pins based on IBIS [Pin] section information</Radio>
                        <Radio
                          checked={applyModelObj[ASSIGN_MODEL_TO_ALL_INTERFACES]}
                          className='IC-apply-interface-title-radio'
                          onClick={(e) => this.updateAutoAssignModel(e, ASSIGN_MODEL_TO_ALL_INTERFACES, "assign")}
                        >
                          Auto assign models to the same part components in this project</Radio>
                      </div>
                      <Divider className="ic-model-divider" />
                      <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <Radio
                          checked={applyModelObj[APPLY_MODEL_TO_SETUP]}
                          className='IC-apply-interface-title-radio'
                          onClick={(e) => this.updateAutoAssignModel(e, APPLY_MODEL_TO_SETUP, "apply")}
                        >
                          Apply model to all IC components in this interface</Radio>
                        <Radio
                          checked={applyModelObj[APPLY_MODEL_TO_ALL_INTERFACES]}
                          className='IC-apply-interface-title-radio'
                          onClick={(e) => this.updateAutoAssignModel(e, APPLY_MODEL_TO_ALL_INTERFACES, "apply")}
                        >
                          Apply model to all IC components in this project</Radio>
                      </div>
                      <Divider className="ic-model-divider" />
                      {/*  <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <div className='IC-apply-interface-title'>Auto assign models to component pins based on IBIS [Pin] section information</div>
                        <Checkbox
                          className='IC-model-apply-checkbox'
                          checked={applyModelObj[AUTO_ASSIGN_MODEL]}
                          onChange={(e) => this.updateAutoAssignModel(e, AUTO_ASSIGN_MODEL)}
                        />
                      </div>
                      <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <div className='IC-apply-interface-title'>Auto assign models to the same part components in this project</div>
                        <Checkbox
                          className='IC-model-apply-checkbox'
                          checked={applyModelObj[ASSIGN_MODEL_TO_ALL_INTERFACES]}
                          onChange={(e) => this.updateAutoAssignModel(e, ASSIGN_MODEL_TO_ALL_INTERFACES)}
                        />
                      </div>
                      <Divider className="ic-model-divider" />
                      <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <div className='IC-apply-interface-title'>Apply model to all IC components in this interface</div>
                        <Checkbox
                          className='IC-model-apply-checkbox'
                          checked={applyModelObj[APPLY_MODEL_TO_SETUP]}
                          onChange={(e) => this.updateAutoAssignModel(e, APPLY_MODEL_TO_SETUP)}
                        />
                      </div>
                      <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <div className='IC-apply-interface-title'>Apply model to all IC components in this project</div>
                        <Checkbox
                          className='IC-model-apply-checkbox'
                          checked={applyModelObj[APPLY_MODEL_TO_ALL_INTERFACES]}
                          onChange={(e) => this.updateAutoAssignModel(e, APPLY_MODEL_TO_ALL_INTERFACES)}
                        />
                      </div>
                      */}
                      <div className='IC-select-dialog IC-multi-spice-select-dialog clear'>
                        <Checkbox
                          className='IC-model-apply-checkbox IC-model-apply-checkbox-pre'
                          checked={applyModelObj[APPLY_PKG]}
                          onChange={(e) => this.updateAutoAssignModel(e, APPLY_PKG)}
                        />
                        <div className='IC-apply-interface-title'>Assign package model</div>
                      </div>
                    </div>
                  }
                </Fragment>
              }
              {NEW_SINGLE_TYPE.includes(libType) && displayType === 'model' ? <SingleModel
                onRef={this.onRef}
                {...this.props}
                libType={libType}
                spiceList={spFileList}
                _compModel={_compModel}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                getIbisModel={this.getIbisModel}
                getSpiceModel={this.getSpiceModel}
                product={product}
                onDieSpiceList={onDieSpiceList}
                closeModal={this.closeModal}
                setValidate={this.setValidate}
                updateBufferItemWidth={this.updateBufferItemWidth}
                compAmiModel={compAmiModel}
              /> :
                libType === MULTI_PIN_SPICE && <SetSPNodeModel
                  {...this.props}
                  onRef={this.onRef}
                  files={files}
                  pairs={pairs}
                  libraryList={spiceList}
                  getLibraryFile={this.props.getSpiceModelList}
                  setContentHeight={this.setContentHeight}
                  modelType={MULTI_PIN_SPICE}
                  modelPinType={MULTI_PIN_SPICE}
                  maxWidth={maxWidth}
                  maxHeight={maxHeight}
                  product={product}
                  scrollId={"IC-scrollId"}
                />
              }
            </div>
            {/*   {disabled ? <div className="aurora-model-name-warning-msg">{msg}</div> : null} */}
          </div>
        </Spin>
      </Panel >
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { inputRef } = this;
    const { libType, TemplateVisible } = this.state;
    const { text } = this.props;

    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div>
        {this.renderDialog()}
        {TemplateVisible && libType === SPICE && <TemplatePanel closeModal={this.closeTemplateModal} root={this.dialogRoot} />}
      </Fragment >
    )
  }
}

export default ICModelPanel;