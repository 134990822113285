import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { EXPERIMENTS } from '@/constants/treeConstants';
import './style.css';

class DebugMsg extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null
    }
    this.scrollRef = createRef();
  }


  scrollToBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "auto" });
  }

  componentDidMount() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }

  componentDidUpdate() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }


  render() {
    const { uploadDebug, projectName, currentProjectId, uploadProjectId, msg, endMsg, verificationName, isExperiment } = this.props;
    return (
      <Fragment>
        <div className='sierra-simulation-title'>
          <Row className='sierra-simulation-holygrail' span={24}>
            <Col className='sierra-simulation-msg'
              span={20}>
              {projectName && <div className='sierra-simulation-span sierra-simulation-project'>
                <span className='span-msg-project font-bold'>Project </span>
                <span className='span-msg'>{projectName}</span>
              </div>}
              {verificationName && <div className='sierra-simulation-span'>
                <span className='font-bold'>{isExperiment ? `Experiment ` : `Interface `}</span>
                <span className='span-msg'>{verificationName} </span>
              </div>}
            </Col>
          </Row>
        </div>
        <div className='my-sierra-monitor'>
          <pre style={{ margin: 20, overflow: 'unset' }}>
            {uploadProjectId === currentProjectId ? (<Fragment>
              {msg}
              {uploadDebug && uploadDebug.length > 0 &&
                <ul className='sierra-monitor-ul' style={{ paddingLeft: 34, margin: 0 }}>
                  {uploadDebug.length && uploadDebug.map(item =>
                    <li key={item.id} style={{ listStyle: 'none', width: '100%' }}>
                      <p style={{ margin: 0 }}>{item.time}  {item.log}</p>
                    </li>)}
                </ul>}
              {endMsg}
            </Fragment>) : null}
          </pre>
          <div ref={(el) => { this.scrollRef = el; }}></div>
        </div>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const {/*  TabMonitorReducer: { projectName, tabVisible, debugMonitor, verificationName }, */ project: { currentProjectId, viewList } } = state.SierraReducer;
  const { debug, uploadProjectId, msg, endMsg } = state.SierraReducer.SierraUploadReducer;
  const { tabVisible, projectName, debugMonitor, verificationName } = state.TabMonitorReducer;
  const isExperiment = viewList.includes(EXPERIMENTS) ? true : false;
  return {
    projectName,
    uploadProjectId,
    currentProjectId,
    uploadDebug: debug,
    tabVisible,
    msg,
    endMsg,
    debugMonitor,
    verificationName,
    isExperiment
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(DebugMsg);