import { combineReducers } from 'redux';
import {
  SierraProjectReducer
} from './project';
import {
  default as explorerReducer
} from '../../LayoutExplorer/store/Sierra/reducer';
import {
  default as stackupReducer
} from '../../LayoutExplorer/Sierra/sierraStackup/store/reducer';
import { SierraReducer } from './sierra';
import { simulationReducer } from './simulation';
import SierraUploadReducer from '../SierraSider/Upload/store/reducer';
import { resultReducer } from './result';
import { SierraPreLayoutReducer } from './prelayout';
import { SierraLibraryReducer } from './library';
import { MultiInterfaceReducer } from './multiInterface';
import { SweepReducer } from './sweep';


const rootReducer = combineReducers({
  project: SierraProjectReducer,
  explorer: explorerReducer,
  SierraUploadReducer: SierraUploadReducer,
  sierra: SierraReducer,
  stackup: stackupReducer,
  simulationReducer,
  resultReducer,
  prelayout: SierraPreLayoutReducer,
  library: SierraLibraryReducer,
  multiInterfaceSetup: MultiInterfaceReducer,
  sweep: SweepReducer
});

export default rootReducer;