import { MICROSTRIP, STRIPLINE, DIFF, SINGLE } from "../../../../constants/libraryConstants";
import { MIL } from "../../../PreLayout/PreLayoutLibrary";

const LAYERS = "layers",
  PRE_LAYOUT_WIDTH = "width",
  PRE_LAYOUT_SPACING = "spacing",
  PRE_LAYOUT_UNIT = "unit",
  PRE_LAYOUT_THICKNESS = "thickness",
  PRE_LAYOUT_ER = 'er',
  PRE_LAYOUT_LOSS_TANGENT = 'lossTangent',
  PRE_LAYOUT_CONDUCTIVITY = 'conductivity';

function SierraTraceConfig(traceType, type) {
  if (type === DIFF) {
    this[PRE_LAYOUT_SPACING] = "5"; //spacing (mil) DIFF only
  }
  this[PRE_LAYOUT_UNIT] = MIL;
  this.type = SINGLE;//SINGLE or DIFF
  if (traceType === MICROSTRIP) {
    this[PRE_LAYOUT_WIDTH] = "10"; //width (mil)
    this[LAYERS] = JSON.parse(JSON.stringify(MicroStripLayers));
  } else if (traceType === STRIPLINE) {
    this[PRE_LAYOUT_WIDTH] = "8"; //width (mil)
    this[LAYERS] = JSON.parse(JSON.stringify(StripLineLayers));
  }
}

const MicroStripLayers = [
  {
    name: "Trace",
    type: "Metal",
    thickness: "1.4",
    conductivity: "5.8e7"
  },
  {
    name: "D1",
    type: "Dielectric",
    thickness: "6",
    er: "4.6",
    lossTangent: "0.02"
  },
  {
    name: "Bottom",
    type: "Metal",
    thickness: "1.4",
    conductivity: "5.8e7"
  }
]

const StripLineLayers = [
  {
    name: "Top",
    type: "Metal",
    thickness: "1.4",
    conductivity: "5.8e7"
  },
  {
    name: "D1",
    type: "Dielectric",
    thickness: "10",
    er: "4",
    lossTangent: "0.02"
  },
  {
    name: "Trace",
    type: "Metal",
    thickness: "1.4",
    conductivity: "5.8e7"
  },
  {
    name: "D2",
    type: "Dielectric",
    thickness: "12",
    er: "4",
    lossTangent: "0.02"
  },
  {
    name: "Bottom",
    type: "Metal",
    thickness: "1.4",
    conductivity: "5.8e7"
  },
];

const SierraTraceKeyArr = [
  { title: 'Width', key: PRE_LAYOUT_WIDTH, unit: "mil", width: 56, modify: true },
  { title: 'Spacing', key: PRE_LAYOUT_SPACING, unit: "mil", width: 76, signalType: DIFF, modify: true }
];

const SierraTraceColumns = [
  {
    title: 'Name',
    dataIndex: "name",
    key: "name",
    width: 70,
    modify: false
  },
  {
    title: 'Type',
    dataIndex: "type",
    key: "type",
    width: 80,
    modify: false
  },
  {
    title: 'Thickness',
    dataIndex: PRE_LAYOUT_THICKNESS,
    key: PRE_LAYOUT_THICKNESS,
    modify: true
  },
  {
    title: 'Dielectric Constant (εᵣ)',
    dataIndex: PRE_LAYOUT_ER,
    key: PRE_LAYOUT_ER,
    modify: true
  },
  {
    title: 'Loss Tangent (δ)',
    dataIndex: PRE_LAYOUT_LOSS_TANGENT,
    key: PRE_LAYOUT_LOSS_TANGENT,
    modify: true
  },
  {
    title: 'Conductivity (S/m)',
    dataIndex: PRE_LAYOUT_CONDUCTIVITY,
    modify: true
  }
];

const TRACE_LENGTH_UNITS = ["mil", "mm", /* "um" */];

export {
  SierraTraceConfig,
  MicroStripLayers,
  StripLineLayers,
  PRE_LAYOUT_WIDTH,
  PRE_LAYOUT_UNIT,
  PRE_LAYOUT_SPACING,
  LAYERS,
  SierraTraceKeyArr,
  SierraTraceColumns,
  PRE_LAYOUT_ER,
  PRE_LAYOUT_LOSS_TANGENT,
  PRE_LAYOUT_CONDUCTIVITY,
  PRE_LAYOUT_THICKNESS,
  TRACE_LENGTH_UNITS
}