
import { getTraceTemplateLibrary } from '.';
import { TRACE } from '../../../constants/libraryConstants';

class PreLayoutLibraryData {
  constructor() {
    this.trace = new Map(); //key - library id, value :{ id ,name, type, version,config }
  }

  getLibrary = (id, type = TRACE) => {
    return this[type].get(id);
  }

  addLibrary = (type, id, data) => {
    this[type].set(id, data);
  }

  getLibraryConfig = (id, type) => {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(null);
      }
      const library = this.getLibrary(id, type);
      if (!library || !library.config) {
        getTraceTemplateLibrary(id).then(res => {
          this.addLibrary(type, id, res);
          resolve(res);
        })
      } else {
        resolve(library);
      }
    })
  }

  updateLibraryConfig = (id, type) => {
    if (id) {
      getTraceTemplateLibrary(id).then(res => {
        this.addLibrary(type, id, res);
      })
    }
  }

  deleteLibrary = (id, type) => {
    this[type].delete(id);
  }

  clearAllCache = () => {
    this.trace = new Map();
  }
}

let preLayoutLibraryData = new PreLayoutLibraryData();
export default preLayoutLibraryData;