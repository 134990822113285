import {
  UPDATE_LIBRARY_MENU,
  UPDATE_LIBRARY_DATA,
  DELETE_LIBRARY,
  SAVE_LIBRARY_CONFIG_SERVER,
  ADD_LIBRARY_TO_CACHE,
  UPDATE_LIBRARY_STATUS,
  AFTER_IMPORT_LIBRARY,
  OPEN_LIBRARY_FOLDER
} from './actionTypes';

export const updateLibraryMenu = (firstLoad) => ({
  type: UPDATE_LIBRARY_MENU,
  firstLoad
});

export const updateLibraryData = (libraryType, data) => ({
  type: UPDATE_LIBRARY_DATA,
  libraryType,
  data
});

export const deleteLibrary = (data) => ({
  type: DELETE_LIBRARY,
  data
});

export const saveLibraryConfigToServer = ({ model, config, templateVersion }) => ({
  type: SAVE_LIBRARY_CONFIG_SERVER,
  model,
  config,
  templateVersion
});

export const addLibraryToCache = ({ modelType, fileList }) => ({
  type: ADD_LIBRARY_TO_CACHE,
  fileList,
  modelType
})

export const updateLibraryStatus = () => ({
  type: UPDATE_LIBRARY_STATUS
})

export const afterImportLibrary = () => ({
  type: AFTER_IMPORT_LIBRARY
})

export const openLibraryFolder = (itemData, libraryType, filesInfo) => ({
  type: OPEN_LIBRARY_FOLDER,
  itemData,
  libraryType,
  filesInfo
})