function getNominalNumber(powerNet, extendNets = []) {
  const regex = /\d+((\.|P|V)\d)+/g;
  const _regex = /\d+((\.|P|V))+/g;
  let filterPowerData = powerNet.match(regex);
  if ((!filterPowerData || !filterPowerData.length) && _regex.test(powerNet)) {
    // 	+3VALW_SOC
    const matchData = powerNet.match(_regex);
    if (matchData && matchData.length) {
      filterPowerData = [matchData[0].replace(/(P|V)/g, '')];
    }
  }
  let nominal = null;
  if (filterPowerData && filterPowerData.length) {
    nominal = filterPowerData[0].replace(/(P|V)/g, '.');
    return nominal
  } else if (extendNets.length) {
    for (let extendNet of extendNets) {
      const filterData = extendNet.match(regex);
      if (filterData && filterData.length) {
        nominal = filterData[0].replace(/(P|V)/g, '.');
        break;
      }
    }
  }
  return nominal
}

function accSub(arg1, arg2) {
  let r1, r2, m, n
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  n = (r1 >= r2) ? r1 : r2
  // return ((arg1 * m - arg2 * m) / m).toFixed(n)
  return (((arg1 * m - arg2 * m) / m) * 1000).toFixed(2)
}

function getCustomLibraryPath(path) {
  if (!path) {
    return null;
  }
  let _path = "";
  const customFirstIndex = path.search(/Custom/);
  if (customFirstIndex < 0) {
    return _path;
  }
  _path = path.slice(customFirstIndex);
  return _path;
}

export {
  getNominalNumber,
  accSub,
  getCustomLibraryPath
}
