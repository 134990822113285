function getCanRunTypeList(onDies) {
  let runTypeList = ["ALL", "Write", "Read"]
  if (onDies && onDies.length) {
    const findCSM = onDies.find(it => it.onDie.type === "CSM");
    if (findCSM && findCSM.onDie) {
      const { onDie } = findCSM
      if (!onDie.readSelectFile || !onDie.readSelectFile.libraryId || !onDie.readSelectFile.subckt) {
        runTypeList = runTypeList.filter(item => !["ALL", "Read"].includes(item))
      }

      if (!onDie.writeSelectFile || !onDie.writeSelectFile.libraryId || !onDie.writeSelectFile.subckt) {
        runTypeList = runTypeList.filter(item => !["ALL", "Write"].includes(item))
      }

    }
  }
  return runTypeList
}

function getNewRunType({ onDies, oldOnDie = [], runType }) {
  let _runType = runType
  const findOldOnDie = oldOnDie.find(item => item.onDie.type === "CSM");
  const findOnDie = onDies.find(item => item.onDie.type === "CSM");
  if (findOldOnDie && !findOnDie) {
    _runType = "ALL"
  } else if (findOnDie) {
    let runTypeList = ["ALL", "Write", "Read"]
    if (findOnDie && findOnDie.onDie) {
      const { onDie } = findOnDie;
      if (!onDie.readSelectFile || !onDie.readSelectFile.libraryId || !onDie.readSelectFile.subckt) {
        runTypeList = runTypeList.filter(item => !["ALL", "Read"].includes(item))
      }

      if (!onDie.writeSelectFile || !onDie.writeSelectFile.libraryId || !onDie.writeSelectFile.subckt) {
        runTypeList = runTypeList.filter(item => !["ALL", "Write"].includes(item))
      }
    }
    if (runTypeList.includes("ALL")) {
      _runType = "ALL"
    } else if (runTypeList.length) {
      _runType = runTypeList[0];
    } else {
      _runType = "";
    }
  }

  return _runType;
}

export {
  getCanRunTypeList,
  getNewRunType
}