function getTextWidth(str, fontSize = 12, maxSize, fontWeight) {
  let result = 0;
  let ele = document.createElement("div");
  ele.style.position = "absolute";
  ele.style.whiteSpace = "nowrap";
  ele.style.fontSize = `${fontSize}px`;
  if (fontWeight) {
    ele.style.fontWeight = fontWeight;
  }
  ele.style.opacity = 0;
  ele.innerText = str;
  document.body.append(ele);
  result = ele.getBoundingClientRect().width;
  document.body.removeChild(ele);
  if (maxSize && maxSize < result) {
    result = maxSize;
  }
  return result;
}

function getMaxTextWidth(strList, fontSize = 12, maxSize, fontWeight) {
  // Obtain the longest length in name
  let result = 0;
  let ele = document.createElement("div");
  ele.style.position = "absolute";
  ele.style.whiteSpace = "nowrap";
  ele.style.fontSize = `${fontSize}px`;
  if (fontWeight) {
    ele.style.fontWeight = fontWeight;
  }
  ele.style.opacity = 0;

  strList.forEach(str => {
    ele.innerText = str;
    document.body.append(ele);
    const width = ele.getBoundingClientRect().width;
    if (result < width) {
      result = width
    }
    document.body.removeChild(ele);
  });

  if (maxSize && maxSize < result) {
    result = maxSize;
  }
  return result
}

function getAdaptWidthName(str, width, fontSize, fontWidth) {
  let _str = str;
  let textWidth = getTextWidth(_str, fontSize, null, fontWidth);
  if (textWidth < width) {
    return _str
  }

  while (textWidth > width) {
    _str = _str.slice(0, -3);
    textWidth = getTextWidth(`${_str}...`, fontSize, null, fontWidth);
  }
  return `${_str}...`
}

function getTextWidthAndHeight(str, { fontSize = 12, maxSize, fontWeight }) {
  let width = 0, height = 0;
  let ele = document.createElement("div");
  ele.style.position = "absolute";
  ele.style.whiteSpace = "nowrap";
  ele.style.fontSize = `${fontSize}px`;
  if (fontWeight) {
    ele.style.fontWeight = fontWeight;
  }
  ele.style.opacity = 0;
  ele.innerText = str;
  document.body.append(ele);
  const offset = ele.getBoundingClientRect();
  width = offset.width;
  height = offset.height;
  document.body.removeChild(ele);
  if (maxSize && maxSize < width) {
    width = maxSize;
  }
  return { width, height };
}

function getCanvasTextWidth(text, fontSize, svg) {
  let textWidth = 0;
  svg.append("text")
    .attr("font-size", fontSize)
    .attr("opacity", 0.0)
    .text(text)
    .each(function (d, i) {
      var thisWidth = this.getComputedTextLength()
      textWidth = thisWidth;
      this.remove() // remove them just after displaying them
    })
  return textWidth;
}

export {
  getTextWidth,
  getAdaptWidthName,
  getMaxTextWidth,
  getTextWidthAndHeight,
  getCanvasTextWidth
}