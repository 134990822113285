import { message } from "antd";
import { getBMAChannelListPromise, getBMAFilesInfoPromise, deleteBMAFilePromise, createBMAFolderPromise } from "../apiPromise/channel";

// "design": matches the first string of the file name
// "capacity": matches the value in GB in the file name and the file content
// "dimm_vendor": matches the file name Vendor or the file content DIMM:
// "speed": matches the numeric part of the file name
// "configuration": matches the value of the file name DPC
// "shift": matches the value of the file name shift
// "run": matches the value of the file name run
// "cpu_or_asic_vendor": matches the file content as DRAM:
// "pmic_vendor": matches the file content as PMIC:
// "rcd_vendor": matches the file content as RCD: (take the first RCD in the file)

class BMAChannelsConstructor {
  constructor() {
    this.info = new Map(); //BMA channel
    this.fileList = new Map(); //BMA folder
  }

  setInfo = (projectId, list) => {
    this.info.set(projectId, list)
  }

  getPromiseInfo = (projectId, isNew) => {
    return new Promise((resolve, reject) => {
      const info = this.info.get(projectId);
      if (info && !isNew) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getBMAChannelListPromise(projectId).then(res => {
          if (res) {
            this.setInfo(projectId, res)
            resolve(res)
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  setFileInfo = (projectId, list) => {
    this.fileList.set(projectId, list)
  }

  getFileInfo = (projectId) => {
    return this.fileList.get(projectId)
  }

  getFilePromiseInfo = (projectId, isNew) => {
    return new Promise((resolve, reject) => {
      const list = this.fileList.get(projectId);
      if (list && list.length && !isNew) {
        resolve(JSON.parse(JSON.stringify(list)));
      } else {
        getBMAFilesInfoPromise(projectId).then(res => {
          if (res) {
            this.setFileInfo(projectId, res)
            resolve(res)
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  deleteFilePromiseInfo = (projectId, deleteList) => {
    return new Promise((resolve, reject) => {
      deleteBMAFilePromise(deleteList).then(res => {
      }, error => {
        message.error('Delete files failed! ' + error)
        resolve(null)
      })
    })
  }

  addFolderPromiseInfo = ({ projectId, name, parentId = "0" }) => {
    return new Promise((resolve, reject) => {
      createBMAFolderPromise({ projectId, name, parentId }).then(res => {
        if (res) {
          this.setFileInfo(projectId, res)
          resolve(res)
        } else {
          resolve(null);
        }
      }, error => {
        message.error('Add folder failed! ' + error)
        resolve(null)
      })
    })
  }

  getFirstBMAInfo = async (projectId) => {
    const data = await this.getPromiseInfo(projectId);
    if (data && data.length) {
      return data[0]
    }
    return {}
  }

  delete = (projectId) => {
    this.info.delete(projectId)
  }

  clearAll = () => {
    this.info = new Map();
  }
}

let bmaChannelsConstructor = new BMAChannelsConstructor();
export default bmaChannelsConstructor;