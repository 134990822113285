export function checkFileName(names) {
  let errorName = [];
  names.forEach(item => {
    let errorWords = item.match(/[^0-9a-zA-Z_.-]+/g);
    if (errorWords && errorWords.length > 0) {
      errorName.push(item);
    }
  });
  return errorName;
}

export function repeatedNames(names) {
  let hash = {};
  for (var i in names) {
    if (hash[names[i]]) {
      return names[i];
    }
    hash[names[i]] = true;
  }
  return false;
}