import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Table from "@/components/EditableTable";
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  addPCBInPowerNets,
  pgNetSelection,
  netVoltageUpdate,
  deletePGNets,
  updateSierraInterface,
  addPowerGrounds
} from '../../store/sierra/action';
import { autoFindPowerGNDNets } from '@/services/Sierra';
import DesignInfo from '@/services/Sierra/pcbInfo';
import { selectChange, changeDisplaySelected } from '../../../LayoutExplorer/store/Sierra/actionCreators';
import {
  refreshPCB
} from '../../store/project/action';
import { numberCheck, getSelectedDesignIDs } from '@/services/helper/dataProcess';
import '../index.css';
import designConstructor from '../../../../services/helper/designConstructor';

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '20%'
  },
  {
    title: "PCB",
    dataIndex: "pcb",
    width: "33%",
  },
  {
    title: "Net",
    dataIndex: "name",
    width: "33%",
    render: (name, record) => {
      return <span>{name}</span>;
    }
  },
  {
    title: "Voltage (V)",
    dataIndex: "value",
    width: "33%"
  }
];

class PowerNetsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PCBNets: {}
    };

    columns[1].onCell = (record, index) => {
      return {
        record,
        edit: 'select',
        options: this.props.currentPCBNames,
        getPopupContainer: document.getElementById("sierra-content-main"),
        dataIndex: "pcb",
        handleSave: (record) => this.addPCBs(record, index),
      };
    };

    columns[1].render = (name, record, index) => {
      return <div>
        <span>{name}</span>
      </div>
    }

    columns[2].onCell = (record, index) => {
      const isPreLayout = designConstructor.isPreLayout(record.pcbId);
      if (isPreLayout) {
        return { edit: false }
      }
      const options = this.getNetsOptions(record);
      return {
        record,
        edit: 'aurora-select',
        options: options,
        dataIndex: "name",
        selectType: "name",
        handleSave: this.netSelection,
        onFocus: () => this.netSelectFocus(record),
        // dropdownMenuClassName: 'sierra-select-dropdown-menu'
      };
    };

    columns[2].render = (name, record) => {
      const isPreLayout = designConstructor.isPreLayout(record.pcbId);
      if (isPreLayout && record.comps && record.comps.length) {
        return <span title={`${record.comps.join("_")}_Pull Up`}>{name}</span>
      }
      return name;
    }

    columns[3].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'value',
        handleSave: this.voltageUpdate,
        className: 'pintopin-power-cell',
      }
    }

    columns[3].render = (value, record, index) => {
      const isPreLayout = designConstructor.isPreLayout(record.pcbId);
      return <Fragment>
        <div>
          <span>{value}</span>
        </div>
        {
          !this.props.pcbLoading && !isPreLayout && <CloseOutlined
            className='pintopin-power-delete'
            onClick={(e) => this.deletePG(e, record, index)} />
        }
      </Fragment>
    }
  }

  deletePG = (e, record, index) => {
    e.stopPropagation();
    const { powerNets } = this.props;
    const { name, pcbId, pcb } = record;
    if (pcbId && pcb) {
      this.props.deletePGNets({ name, pcbId, pcb });
    } else {
      let newPowerNets = [...powerNets];
      newPowerNets.splice(index, 1);
      this.props.updateSierraInterface({ powerNets: [...newPowerNets] })
    }
  }

  getNetsOptions = (record) => {
    const { PCBNets } = this.state;
    const { Interfaces } = this.props;
    let currentInterface = Interfaces.find(item => item.pcbId === record.pcbId);
    if (currentInterface && currentInterface.content && currentInterface.content.signals) {

      let signalNets = [];
      currentInterface.content.signals.forEach(item => {
        if (item.nets && item.nets.length > 0) {
          signalNets = [...signalNets, ...item.nets]
        }
      });

      if (PCBNets[record.pcbId]) {
        //Filter nets (not includes signal nets)
        let nets = PCBNets[record.pcbId].filter(item => !signalNets.includes(item));
        return nets;
      } else {
        return [];
      }
    } else {
      return PCBNets[record.pcbId] || [];
    }
  }

  netSelectFocus = (record) => {
    const { pcbId, name } = record;
    const { selectedDesignIDs } = this.props;
    if (selectedDesignIDs.includes(pcbId)) {
      this.props._selectChange({ nets: [name] }, pcbId);
      this.props.changeDisplaySelected(true, pcbId)
    }
  }

  netSelection = (record, prevRecord) => {
    const { pcb, pcbId, name } = record;
    const prevName = prevRecord && prevRecord.name ? prevRecord.name : '';
    this.netSelectFocus(record);
    this.props.netSelection(pcb, pcbId, name, prevName);
  };

  voltageUpdate = (record) => {
    const { pcb, pcbId, name, value } = record;
    //num check
    const error = numberCheck(value);
    if (!error) {
      this.props.netVoltageUpdate({ pcb, pcbId, name, value });
    }
  };

  addPCBs = (record) => {
    const { pcb, name } = record;
    this.props.addPCBInPowerNets(pcb, name);
  }

  editVddValue = (row) => {
    this.props.editInterface({ row, pram: "powerNets", key: "value" });
  };

  componentDidUpdate(prevProps) {
    this.getPcbNets(prevProps);
  }

  componentDidMount() {
    this.getPcbNets();
  }

  getPcbNets = (prevProps) => {
    const { currentPCBs, pcbComponentsNets, refreshStatus } = this.props;
    const { PCBNets } = this.state;

    currentPCBs.forEach(pcbId => {
      if ((!PCBNets[pcbId] || (refreshStatus && prevProps && refreshStatus !== prevProps.refreshStatus)) && pcbComponentsNets.includes(pcbId)) {
        const info = DesignInfo.getPCBInfo(pcbId);
        if (info && info.netsList) {
          const netsList = DesignInfo[pcbId].netsList;
          const filter = autoFindPowerGNDNets(netsList);
          this.setState((prevState) => {
            prevState.PCBNets[pcbId] = filter;
            return {
              PCBNets: prevState.PCBNets
            }
          });
        }
      }
    })

    if (refreshStatus && prevProps && refreshStatus !== prevProps.refreshStatus) {
      this.props.refreshPCB(false);
    }
  }


  powerGndNets = () => {
    this.props.addPowerGrounds();
    //The table has a scroll bar to scroll to the bottom
    setTimeout(() => {
      const table = ReactDOM.findDOMNode(this.table),
        tableBody = table ? table.querySelector('.aurora-table-body') : null;
      if (tableBody && tableBody.scrollHeight) {
        tableBody.scrollTop = tableBody.scrollHeight;
      }
    }, 240);
  }

  render() {
    const { powerNets, currentPCBs, /* refNetsLoading: { loading = false } */ } = this.props;
    const isPostLayout = currentPCBs.filter(id => !designConstructor.isPreLayout(id));
    return (
      /*   <Spin spinning={loading} tip={RUNNING_LOADING_MSG}> */
      <Fragment>
        {isPostLayout.length ? <PlusCircleOutlined className='signal-add-icon' onClick={this.powerGndNets} /> : null}
        <Table
          ref={(ref) => this.table = ref}
          columns={columns}
          className="space-10 sierra-PG-table"
          dataSource={powerNets}
          rowKey={(record) => `${record.name}-${record.pcb}-${record.type}`}
          scroll={
            powerNets && powerNets.length && powerNets.length > 8
              ? { y: 320 }
              : {}
          }
          tablePadding={true}
        />
      </Fragment>
      /* </Spin> */
    );
  }
}


const mapState = (state) => {
  const { SierraReducer: { sierra, project: { pcbComponentsNets, selectedKeys } } } = state;
  const sierraInfo = sierra.sierraInfo;
  let powerNets = [], currentPCBNames = [], currentPCBs = [], Interfaces = [];
  if (sierraInfo) {
    powerNets = sierraInfo.info ? sierraInfo.info.powerNets || [] : [];
    currentPCBNames = (sierraInfo.Interfaces || []).map(item => item.pcb);
    currentPCBs = (sierraInfo.Interfaces || []).map(item => item.pcbId);
    Interfaces = sierraInfo.Interfaces || [];
  }
  return {
    powerNets,
    pcbComponentsNets,
    currentPCBNames,
    currentPCBs,
    sierraInfo,
    Interfaces,
    refNetsLoading: sierra.refNetsLoading,
    newReferenceNets: sierra.newReferenceNets,
    pcbLoading: sierra.pcbLoading,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
  }
};

const mapDispatch = (dispatch) => ({
  addPowerGrounds() {
    dispatch(addPowerGrounds());
  },
  addPCBInPowerNets(pcb, net) {
    dispatch(addPCBInPowerNets(pcb, net))
  },
  netSelection(pcb, pcbId, netSelect, name) {
    dispatch(pgNetSelection(pcb, pcbId, netSelect, name))
  },
  netVoltageUpdate({ pcb, pcbId, name, value }) {
    dispatch(netVoltageUpdate({ pcb, pcbId, name, value }))
  },
  deletePGNets({ name, pcb, pcbId }) {
    dispatch(deletePGNets({ name, pcb, pcbId }))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  updateSierraInterface(info) {
    dispatch(updateSierraInterface(info));
  },
  refreshPCB(refreshStatus) {
    dispatch(refreshPCB(refreshStatus))
  }
});

export default connect(mapState, mapDispatch)(PowerNetsTable);