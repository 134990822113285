import { getPDNResultFile } from '../api/pdn/PDNAPICtrl';
import parseDecapConfig from './DecapConfig';

export function getOptCfg({ projectId, pdnId, time }) {
  let path = 'opt.cfg';
  if (time && time !== 'current') {
    path = `history/${time}/opt.cfg`;
  }
  return new Promise((resolve, reject) => {
    getPDNResultFile(projectId, pdnId, path).then(res => {
      if (res.data) {
        resolve(parseDecapConfig(res.data));
      } else {
        resolve(null);
      }
    }, error => {
      resolve(null);
      console.error(error)
    });
  })
};

export function OptOptions(params = {}) {
  const { minimizeLargeDecap = false, keptMinimumNumber = 'All', minimizeDecap = false, cap = '100u', balancedDecapDistribution = true } = params;

  this.minimizeDecap = minimizeDecap;
  this.cap = cap;
  this.minimizeLargeDecap = minimizeLargeDecap;
  this.keptMinimumNumber = keptMinimumNumber;
  this.balancedDecapDistribution = balancedDecapDistribution;
}