import { CloseCircleFilled, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Popover, Select } from "antd";
import React, { Component, Fragment } from "react";
import '../../publicCss/netlist.css';

const { Option } = Select;

const PIN_L = "pinL", PIN_R = "pinR", PIN_T = "pinT", PIN_B = "pinB";
class PinRender extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render = () => {
    const { pinLData, pinRData, signalInfo, portsList, maxHeight, maxWidth, pinName,
      openSelectNode, deleteSelectNode, editNode, selectPinPort, closeNodesSelection,
      searchNode, pins, leftPinWidth, rightPinWidth, leftPinValueWidth, rightPinValueWidth, searchValue,
      isFirst, isEnd, pinTData, pinBData, topPinWidth, bottomPinWidth, topPinValueWidth, bottomPinValueWidth } = this.props;
    const leftWidth = 58 + leftPinWidth + leftPinValueWidth, rightWidth = 58 + rightPinWidth + rightPinValueWidth;
    return <Fragment>
      {isFirst && pinTData ? <div className="spice-pin-connect-node-item spice-pin-node-item-center spice-pin-node-item-vertical-center" key={"vddq-node"}>
        {pinLData ? <div style={{ width: leftWidth }}></div> : null}
        {this.getTopPin({
          pinData: pinTData,
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          openSelectNode,
          deleteSelectNode,
          editNode,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinWidth: topPinWidth,
          pinValueWidth: topPinValueWidth
        })}
        {pinRData ? <div style={{ width: rightWidth }}></div> : null}
      </div> : null}
      <div className="spice-pin-connect-node-item spice-pin-node-item-center" key={pinName}>
        {pinLData ? this.getLeftPin({
          pinData: pinLData,
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          openSelectNode,
          deleteSelectNode,
          editNode,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinWidth: leftPinWidth,
          pinValueWidth: leftPinValueWidth
        }) : null}
        {/* {isFirst && pinTData ? this.getTopContent({
        pinData: pinTData
      }) : null} */}
        {this.getSignal(signalInfo, isFirst, isEnd, pinTData)}
        {/* {isEnd && pinBData ? this.getBottomContent() : null} */}
        {pinRData ? this.getRightPin({
          pinData: pinRData,
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          openSelectNode,
          deleteSelectNode,
          editNode,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinWidth: rightPinWidth,
          pinValueWidth: rightPinValueWidth
        }) : null}
      </div>
      {isEnd && pinBData ? <div className="spice-pin-connect-node-item spice-pin-node-item-center spice-pin-node-item-vertical-center" key={"vss-node"}>
        {pinLData ? <div style={{ width: leftWidth }}></div> : null}
        {this.getBottomPin({
          pinData: pinBData,
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          openSelectNode,
          deleteSelectNode,
          editNode,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinWidth: bottomPinWidth,
          pinValueWidth: bottomPinValueWidth
        })}
        {pinRData ? <div style={{ width: rightWidth }}></div> : null}
      </div> : null}
    </Fragment>
  }

  getLeftPin = ({
    pinData,
    portsList,
    searchValue,
    maxHeight,
    maxWidth,
    openSelectNode,
    deleteSelectNode,
    editNode,
    selectPinPort,
    closeNodesSelection,
    searchNode,
    pins,
    pinWidth,
    pinValueWidth }) => {

    return <div className='spice-pin-die-value-box spice-total-pin-box'>
      {/* port */}
      {editNode && editNode === `${pinData.pin}::${PIN_L}` ?
        portSetupPopover({
          pin: pinData.pinL,
          pinType: PIN_L,
          pinData,
          port: pinData.pinLValue,
          placeholder: "Node",
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinValueWidth,
          openSelectNode
        }) :
        pinData.pinLValue ?
          portSetupText({
            pin: pinData.pinL,
            pinType: PIN_L,
            pinData,
            port: pinData.pinLValue,
            openSelectNode,
            deleteSelectNode,
            pinValueWidth
          })
          : portSetupInput({
            placeholder: "Node",
            port: pinData.pinLValue,
            pin: pinData.pinL,
            pinType: PIN_L,
            pinData,
            openSelectNode,
            pinValueWidth
          })
      }
      {/* pin */}
      <div className='spice-pin-die-box'
        title={pinData.pinLDisplay ? pinData.pinLDisplay : pinData.pinL}
        style={{ width: pinWidth }}
      >
        {pinData.pinLDisplay ? pinData.pinLDisplay : pinData.pinL}
      </div>
      {/* - */}
      <div className='spice-pin-line'></div>
      <div className='spice-pin-circle'></div>
    </div>
  }

  getRightPin = ({
    pinData,
    portsList,
    searchValue,
    maxHeight,
    maxWidth,
    openSelectNode,
    deleteSelectNode,
    editNode,
    selectPinPort,
    closeNodesSelection,
    searchNode,
    pins,
    pinWidth,
    pinValueWidth }) => {
    return <div className='spice-pin-die-value-box spice-total-pin-box'>
      {/* - */}
      <div className='spice-pin-circle'></div>
      <div className='spice-pin-line'></div>
      {/* pin */}
      <div className='spice-pin-die-box'
        title={pinData.pinRDisplay ? pinData.pinRDisplay : pinData.pinR}
        style={{ width: pinWidth }}
      >
        {pinData.pinRDisplay ? pinData.pinRDisplay : pinData.pinR}
      </div>
      {/* port */}
      {editNode && editNode === `${pinData.pin}::${PIN_R}` ?
        portSetupPopover({
          pin: pinData.pinR,
          pinType: PIN_R,
          pinData,
          port: pinData.pinRValue,
          placeholder: "Node",
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinValueWidth,
          openSelectNode
        }) :
        pinData.pinRValue ?
          portSetupText({
            pin: pinData.pinR,
            pinType: PIN_R,
            pinData,
            port: pinData.pinRValue,
            openSelectNode,
            deleteSelectNode,
            pinValueWidth
          })
          : portSetupInput({
            placeholder: "Node",
            port: pinData.pinRValue,
            pin: pinData.pinR,
            pinType: PIN_R,
            pinData,
            openSelectNode,
            pinValueWidth
          })
      }
    </div>
  }

  getTopPin = ({ pinData,
    portsList,
    searchValue,
    maxHeight,
    maxWidth,
    openSelectNode,
    deleteSelectNode,
    editNode,
    selectPinPort,
    closeNodesSelection,
    searchNode,
    pins,
    pinWidth,
    pinValueWidth }) => {
    return <div className='spice-pin-die-value-box spice-vertical-pin-die'>
      {/* port */}
      {editNode && editNode === `${pinData.pin}::${PIN_T}` ?
        portSetupPopover({
          pin: pinData.pinT,
          pinType: PIN_T,
          pinData,
          port: pinData.pinTValue,
          placeholder: "Node",
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinValueWidth,
          openSelectNode
        }) :
        pinData.pinTValue ?
          portSetupText({
            pin: pinData.pinT,
            pinType: PIN_T,
            pinData,
            port: pinData.pinTValue,
            openSelectNode,
            deleteSelectNode,
            pinValueWidth
          })
          : portSetupInput({
            placeholder: "Node",
            port: pinData.pinTValue,
            pin: pinData.pinT,
            pinType: PIN_T,
            pinData,
            openSelectNode,
            pinValueWidth
          })
      }
      {/* pin */}
      <div className='spice-pin-die-box'
        title={pinData.pinTData ? pinData.pinTData : pinData.pinT}
        style={{ width: pinWidth }}
      >
        {pinData.pinTData ? pinData.pinTData : pinData.pinT}
      </div>
      {/* - */}
      <div className='spice-pin-vertical-line'></div>
      <div className='spice-pin-circle'></div>
    </div>
  }

  getBottomPin = ({
    pinData,
    portsList,
    searchValue,
    maxHeight,
    maxWidth,
    openSelectNode,
    deleteSelectNode,
    editNode,
    selectPinPort,
    closeNodesSelection,
    searchNode,
    pins,
    pinWidth,
    pinValueWidth }) => {
    return <div className='spice-pin-die-value-box spice-vertical-pin-die'>
      {/* - */}
      <div className='spice-pin-circle'></div>
      <div className='spice-pin-vertical-line'></div>
      {/* pin */}
      <div className='spice-pin-die-box'
        title={pinData.pinBDisplay ? pinData.pinBDisplay : pinData.pinB}
        style={{ width: pinWidth }}
      >
        {pinData.pinBDisplay ? pinData.pinBDisplay : pinData.pinB}
      </div>
      {/* port */}
      {editNode && editNode === `${pinData.pin}::${PIN_B}` ?
        portSetupPopover({
          pin: pinData.pinB,
          pinType: PIN_B,
          pinData,
          port: pinData.pinBValue,
          placeholder: "Node",
          portsList,
          searchValue,
          maxHeight,
          maxWidth,
          selectPinPort,
          closeNodesSelection,
          searchNode,
          pins,
          pinValueWidth,
          openSelectNode
        }) :
        pinData.pinBValue ?
          portSetupText({
            pin: pinData.pinB,
            pinType: PIN_B,
            pinData,
            port: pinData.pinBValue,
            openSelectNode,
            deleteSelectNode,
            pinValueWidth
          })
          : portSetupInput({
            placeholder: "Node",
            port: pinData.pinBValue,
            pin: pinData.pinB,
            pinType: PIN_B,
            pinData,
            openSelectNode,
            pinValueWidth
          })
      }
    </div>
  }

  getSignal = (signalInfo, isFirst = false, isEnd = false) => {
    return <Fragment>
      <div
        className={isFirst ? 'spice-box spice-box-top' : (isEnd ? 'spice-box spice-box-bottom' : 'spice-box')}
        title={`${signalInfo.signalDisplay}::${signalInfo.netDisplay}`}
      >
        <span className='spice-box-signal-text'>{signalInfo.signalDisplay}</span>
        <span className='spice-box-net-text'>{signalInfo.netDisplay}</span>
      </div>
    </Fragment>
  }
}

function portSetupPopover({
  pin,
  pinType,
  pinData,
  port,
  placeholder,
  portsList,
  searchValue,
  maxHeight,
  maxWidth,
  selectPinPort,
  closeNodesSelection,
  pins,
  pinValueWidth,
  searchNode,
  openSelectNode
}) {
  return <Popover
    overlayClassName='spice-node-select-Popover pin-spice-node-select-Popover'
    content={nodeSelectRender({
      pin,
      pinType,
      port,
      pinData,
      portsList,
      searchValue,
      maxHeight,
      maxWidth,
      selectPinPort,
      closeNodesSelection,
      pins,
      searchNode
    })}
    title=""
    trigger="click"
    open={true}
    placement={"top"}
  >{portSetupInput({
    placeholder,
    port,
    pin,
    pinType,
    pinData,
    pinValueWidth,
    openSelectNode
  })}</Popover>
}

function portSetupText({
  pin,
  pinType,
  pinData,
  port,
  openSelectNode,
  deleteSelectNode,
  pinValueWidth
}) {
  return (
    <div className='spice-pin-input'
      style={{ width: pinValueWidth ? pinValueWidth + 14 : null }}
      id={pin}
      title={port}
      onClick={() => openSelectNode({ pin, port, pinType, pinData })}
    >
      <span style={{ width: pinValueWidth }}>{port}</span>
      <CloseCircleFilled
        className='spice-pin-node-delete-icon'
        onClick={(e) => deleteSelectNode({ e, pinData, pinType })} />
    </div>
  );
}

function portSetupSelect({
  pin,
  pinType,
  pinData,
  port,
  ports,
  changeSelectPort,
  pinValueWidth,
  placeholder }) {
  return <div>
    <Select
      className='spice-pin-select aurora-select'
      style={{ width: pinValueWidth ? pinValueWidth + 40 : null }}
      placeholder={placeholder}
      value={port}
      id={pin}
      onChange={(key) => changeSelectPort({ pin, port: key, pinType, pinData })}
      popupClassName="aurora-select-dropdown"
      allowClear={true}
    >
      {ports.map(item =>
        <Option key={item} value={item}>{item}</Option>)}
    </Select>
  </div>
}

function portSetupInput({
  placeholder,
  port,
  pin,
  pinType,
  pinData,
  openSelectNode,
  pinValueWidth
}) {
  return <Input
    className='spice-pin-input'
    style={{ width: pinValueWidth ? pinValueWidth + 22 : null }}
    placeholder={placeholder}
    value={port}
    id={pin}
    onClick={() => openSelectNode({ pin, port, pinType, pinData })}
  />
}

function nodeSelectRender({
  pin,
  pinType,
  port,
  pinData,
  portsList,
  searchValue,
  maxHeight,
  maxWidth,
  selectPinPort,
  closeNodesSelection,
  searchNode,
  pins
}) {
  //pinType -> pin type "pinL" / "pinR"
  const _portList = getDisplayPortList({
    pinData,
    pinType,
    searchValue,
    portsList,
    pins
  });
  return (
    <div className='spice-pin-model-ports-content' style={{
      maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200,
      maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100
    }}>
      <div className='spice-pin-model-ports-content-header'>
        <span>Component Pin {pinData.pin}</span>
      </div>
      <div className='spice-pin-model-ports-content-close' onClick={() => closeNodesSelection()}>
        <CloseOutlined className='spice-pin-model-ports-content-close-icon' />
      </div>
      <div className="spice-pin-model-port-list-body-with-search">
        <div className="spice-pin-model-port-list-body-search-wrapper">
          <Input
            placeholder={`Search Node`}
            allowClear
            suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
            value={searchValue}
            onChange={(e) => searchNode(e)}
          />
          <ul className='spice-pin-model-port-list-ul'
            style={{
              maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100
            }}>
            {_portList.map(item =>
              <li
                key={item.port}
                title={item.port}
                className={item.port === port && item.select
                  ? 'spice-pin-model-selected-port-li'
                  : (item.select ? 'spice-pin-model-port-li-selected' : '')}
                onClick={!item.select ?
                  () => selectPinPort({
                    port: item.port,
                    pin,
                    type: pinType,
                    pinData
                  })
                  : null}
              >
                {item.port}
              </li>)}
          </ul>
        </div>
      </div>
    </div>
  );
}

function getDisplayPortList({
  pinData,
  pinType,
  searchValue,
  portsList,
  pins
}) {
  let searchList = [], notSelectedList = [], selectedList = [], currSelectPort = null;
  const searchReg = searchValue ? new RegExp(searchValue, "ig") : null;
  const selectedPorts = pins.map(item => {
    return item.model && item.model.node ? [item.model.node.in, item.model.node.out, item.model.node.vddq, item.model.node.vss] : []
  }).flat(2).filter(item => !!item);

  for (let portItem of portsList || []) {

    if (portItem.port === pinData[`${pinType}Value`]) {
      currSelectPort = { ...portItem, select: true };
      continue;
    }

    if (searchValue && searchReg && portItem.port.match(searchReg)) {
      searchList.push({ ...portItem, select: selectedPorts.includes(portItem.port) });
      continue;
    }

    if (selectedPorts.includes(portItem.port)) {
      selectedList.push({ ...portItem, select: true });
      continue;
    }

    notSelectedList.push({ ...portItem });
  }
  const list = [];
  if (currSelectPort) {
    list.push(currSelectPort)
  }
  return [...list, ...searchList, ...notSelectedList, ...selectedList];
}

export default PinRender;
export {
  portSetupPopover,
  nodeSelectRender,
  portSetupInput,
  portSetupText,
  portSetupSelect
}