import { CABLE_TOUCHSTONE, CONNECTOR_TOUCHSTONE } from '../../../constants/libraryConstants';
import libraryConstructor from '../../../services/Andes_v2/library/libraryConstructor';

export function endToEndErrorCheck(content = {}) {
  const { pcbConnections, connections } = content;
  let errors = [], checkedConnectionIds = [];
  const cableFileList = libraryConstructor.getLibraryValues(CABLE_TOUCHSTONE) || [],
    connectorFileList = libraryConstructor.getLibraryValues(CONNECTOR_TOUCHSTONE) || [];

  for (let i = 0; i < pcbConnections.length; i++) {
    const pcbChannel = pcbConnections[i];
    if (!pcbChannel.designId) {
      errors.push({
        title: "[Channel Topology]",
        boldKey: `PCB ${i + 1}`,
        errorMsg: "PCB is not select."
      });
      break;
    }

    if (!pcbChannel.channelId) {
      errors.push({
        title: "[Channel Topology]",
        boldKey: `PCB ${i + 1}`,
        errorMsg: "PCB channel is not select."
      })
      break;
    }

    if (pcbChannel.prevConnectionId) {
      checkedConnectionIds.push(pcbChannel.prevConnectionId);
      const connection = connections.find(item => item.CONNECTION_ID === pcbChannel.prevConnectionId);
      if (!connection || !connection.connection) {
        errors.push({
          title: "[Connector setup]",
          boldKey: `( PCB ${i}, PCB ${i + 1} )`,
          errorMsg: "connection is not set."
        })
        break;
      }
      const signal_connections_map = connection.connection.signal_connections_map,
        connector1 = connection.connection.connector1,
        connector2 = connection.connection.connector2,
        cableModels = connection.connection.cableModels;

      const notSetSignals = signal_connections_map.filter(item => (item.channel2_signal && !item.channel1_signal) || (!item.channel2_signal && item.channel1_signal));
      for (let signal of notSetSignals) {
        let errorMsg = !signal.channel1_signal ? `PCB ${i} did not select signal.` : "";
        errorMsg = !signal.channel2_signal ? `PCB ${i + 1} did not select signal.` : errorMsg;
        errors.push({
          title: "[Signal Mapping]",
          boldKey: `( PCB ${i}, PCB ${i + 1} ) - ${signal.name}`,
          errorMsg
        });
        break;
      }

      if (!connector1.component) {
        errors.push({
          title: "[Connector Setup]",
          boldKey: `( PCB ${i}, PCB ${i + 1} )`,
          errorMsg: `PCB ${i} did not select component.`
        });
        break;
      }

      if (!connector2.component) {
        errors.push({
          title: "[Connector Setup]",
          boldKey: `( PCB ${i}, PCB ${i + 1} )`,
          errorMsg: `PCB ${i + 1} did not select component.`
        });
        break;
      }

      //model file exist check
      if (cableModels && cableModels.length) {
        const notExistFiles = cableModels.filter(item => item.libraryId && !cableFileList.find(it => it.id === item.libraryId));
        for (let file of notExistFiles) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `cable model file "${file.file}" does not exist.`
          });
        }
      }

      if (connector1.models && connector1.models.length) {
        const notExistFiles = connector1.models.filter(item => item.libraryId && !connectorFileList.find(it => it.id === item.libraryId));
        for (let file of notExistFiles) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `connector1 model file "${file.file}" does not exist.`
          });
        }
      }

      if (connector2.models && connector2.models.length) {
        const notExistFiles = connector2.models.filter(item => item.libraryId && !connectorFileList.find(it => it.id === item.libraryId));
        for (let file of notExistFiles) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `connector2 model file "${file.file}" does not exist.`
          });
        }
      }

      for (let item of connector1.pins) {
        const pin_map = connector2.pins.find(it => it.pin === item.pin_map);
        if (!pin_map) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector1.component}-${item.pin} pin map is not set.`
          });
          break;
        }
        //cable model
        if ((!item.external_map || !item.external_map.cablePort)
          && (pin_map && pin_map.external_map && pin_map.external_map.cablePort)
        ) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector1.component}-${item.pin} cable port is not set.`
          });
        }

        //cable model
        if ((item.external_map && item.external_map.cablePort)
          && (!pin_map.external_map || !pin_map.external_map.cablePort)
        ) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector2.component}-${pin_map.pin} cable port is not set.`
          });
        }

        if ((item.external_map && item.external_map.cablePort)
          && (pin_map.external_map && pin_map.external_map.cablePort)
          && item.external_map.cableModelId !== pin_map.external_map.cableModelId
        ) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector1.component}-${item.pin} and ${connector2.component}-${pin_map.pin} cable ports should be the same model file.`
          });
        }

        //connector model
        if ((item.port && (!item.external_map || !item.external_map.port))
          || (!item.port && (item.external_map && item.external_map.port))) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector1.component}-${item.pin} port is not set.`
          });
          break;
        }

        if (item.port && item.external_map && item.external_map.port
          && item.external_map.modelId !== item.modelId
        ) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector1.component}-${item.pin} ports should be the same model file.`
          });
        }
      }

      for (let item of connector2.pins) {

        if ((item.port && (!item.external_map || !item.external_map.port))
          || (!item.port && (item.external_map && item.external_map.port))) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector2.component}-${item.pin} port is not set.`
          });
          break;
        }

        if (item.port && item.external_map && item.external_map.port
          && item.external_map.modelId !== item.modelId
        ) {
          errors.push({
            title: "[Connector Setup]",
            boldKey: `( PCB ${i}, PCB ${i + 1} )`,
            errorMsg: `${connector2.component}-${item.pin} ports should be the same model file.`
          });
        }
      }
    }
  }

  return errors.length ? errors : null;
}