function getWirBondTableData(profileInfo) {
  let columnKeys = ["name", "point", "diameter", "material"], data = [];

  for (let keyItem of profileInfo) {
    let dataObj = {};
    for (let key of columnKeys) {
      const findValue = keyItem.find(item => item.key === key);
      if (!findValue) {
        continue;
      }
      dataObj[key] = key === "point" ? getWireBondGeometry(findValue) : findValue.value;
    }
    Object.keys(dataObj).length && data.push(dataObj)
  }
  return data;
}

function getWireBondGeometry(data) {
  if (!data || !data.value || !data.value.length) {
    return {}
  }
  const hl = data.value.find(item => item.key === "horizontal") || {},
    vl = data.value.find(item => item.key === "vertical") || {};

  return {
    hl: hl.value || null,
    vl: vl.value || null
  }
}

export {
  getWirBondTableData
}