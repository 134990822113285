import { getPreLayoutInfoById } from "./preLayoutCtrl";

function getPreLayoutInfo(preLayoutId) {
  return new Promise((resolve, reject) => {
    getPreLayoutInfoById(preLayoutId).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
    });
  })
}
export {
  getPreLayoutInfo
}