import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import EditableTable from '@/components/EditableTable';
import {
  updateInterfaces,
  assignModel,
  saveModelPins,
  saveChip,
  savePinStimulusModel,
  updateApplyUsage,
  updateComponentPinUsage
} from '../../store/sierra/action';
import ICModelPanel from '@/components/ICModelPanel';
import { getIbisModelList, getSpiceModelList, getLibraryRepeaterFile, getFolderFileDetail } from '@/services/Sierra/library';
import { getPins } from '@/services/Sierra/library/IbisModelHelper';
import { savePowerOff, saveRepeater } from '../../store/sierra/action';
import PinNodeConnect from '@/components/pinNodeConnect';
import {
  pinColumns,
  getRepeaterModel,
  getRepeaterNode,
  driverReceiverModelText,
  getStimulusText,
  getPinModels,
  getComponentsByPCB
} from '@/services/Sierra';
import { SIERRA } from '@/constants/pageType';
import { MultiPinSPICE, CHIP, COMP_REPEATER, IC } from '../../constants';
import { SPI_TYPES } from '@/services/PCBHelper/constants';
import { SortFn } from '../../../../services/helper/sort';
import componentSetting from '../../../../services/helper/componentsHelper/compSettingHelper';
import { autoAssignRepeaterPorts } from '../../../../services/Sierra/library';
import '../index.css';

const CORNER = ["typ", "fast", "slow"];
const ChipType = [CHIP, COMP_REPEATER, IC];
const _IBIS = 'IBIS';
const chipModelUsages = ['Driver', 'Receiver'];
const PIN_TYPE = ['Driver', 'Receiver'];
const CLK_NAME_TYPES = ['CLK', 'SCK', 'SCLK', 'SCL'];

class PinTable extends Component {
  constructor(props) {
    super(props);

    this.getStimulus = this.getStimulus.bind(this);
    this.editCorner = this.editCorner.bind(this);
    this.columns = [];
    this.getPinColumns();
  }

  getPinColumns = () => {
    const columns = JSON.parse(JSON.stringify(pinColumns));
    // pin setup
    columns[0].render = (name, row) => {
      return <span>{name}</span>
    }

    columns[0].onCell = (record) => {
      let obj = {}
      if (record.signalLength && record.signalLength > 0) {
        obj.rowSpan = record.signalLength;
        obj.tdClassName = 'signalName'
      } else if (!record.pcb) {
        obj.rowSpan = 1;
      } else {
        obj.rowSpan = 0;
      }
      return {
        edit: false,
        ...obj
      }
    }

    columns[1].render = (name, record) => {
      return <span>{`${record.component} - ${record.pin}`}</span>
    }

    columns[2].render = (usage) => {
      if (usage === COMP_REPEATER) {
        return <span className='usage-repeater'>{usage}</span>
      } else {
        return <span>{usage}</span>
      }
    }

    columns[2].onCell = (record) => {
      return record.usage !== COMP_REPEATER && {
        record,
        edit: 'select',
        options: PIN_TYPE,
        dataIndex: 'usage',
        handleSave: this.editComponentPinUsage
      }
    };

    columns[3].title = () => {
      const { assignBufferModelStatus } = this.props;
      return (
        <Fragment>
          <span>Model</span>
          {assignBufferModelStatus ? <LoadingOutlined
            title="Assigning default buffer model."
            className='auto-assign-model-loading-icon' /> : null}
        </Fragment>
      );
    }

    columns[3].render = (text, record, index) => {
      if (record.usage && record.usage === COMP_REPEATER) {
        const model = getRepeaterModel(record);
        const nodeTxt = getRepeaterNode(model, record.pin);
        return <span className='sierra-repeater-editable-cell-value-wrap'>{nodeTxt}</span>;
      } else {
        const text = driverReceiverModelText(record, { compModel: record.compModel });
        return <span className='sierra-ic-editable-cell-value-wrap'>{text}</span>;
      }
    };

    columns[3].onCell = (record) => {
      const { verificationId, repeaterList } = this.props;
      if (record.usage && record.usage === COMP_REPEATER) {
        let compName = record.component;
        let pins = record.compPins || [];
        const model = getRepeaterModel(record);
        const text = getRepeaterNode(model, record.pin);
        return {
          edit: true,
          verificationId,
          record: { ...record },
          model,
          pins,
          compName,
          text,
          product: SIERRA,
          displayType: "model",
          className: 'sierra-repeater-editable-cell-value-wrap',
          customInput: PinNodeConnect,
          libraryList: repeaterList,
          dataIndex: 'model',
          saveLibraryModel: this.saveRepeaterModel,
          getLibraryFile: getLibraryRepeaterFile,
          getFolderFileDetail,
          modelType: COMP_REPEATER,
          componentSetting,
          designId: record.pcbId,
          autoAssignRepeaterPorts
        }
      } else {
        const { component, usage } = record;
        const { verificationId, ibisList, spiceList } = this.props;
        let pins = record.compPins ? record.compPins.filter(item => item.usage === usage) : [];
        const text = driverReceiverModelText(record, { compModel: record.compModel });
        return usage ? {
          edit: true,
          record: { ...record, pinModels: JSON.parse(JSON.stringify(record.pinModels || [])) },
          verificationId,
          componentName: component,
          compModel: record.compModel,
          customInput: ICModelPanel,
          className: 'sierra-ic-editable-cell-value-wrap',
          product: SIERRA,
          text,
          pins,
          dataIndex: 'model',
          displayType: "model",
          ibisList,
          spiceList,
          getIbisModelList: getIbisModelList,
          getSpiceModelList: getSpiceModelList,
          getFolderFileDetail,
          getPins,
          assignModel: this.assignModel,
          saveModelPins: () => { }/*  this.saveModelPins */,
          savePowerOff: this.savePowerOff,
          saveLibraryModel: this.saveChipModel
        } :
          {
            edit: false,
          }
      }
    }

    columns[4].onCell = (record) => {
      const type = record.usage === 'Driver' ? 'tx' : 'rx';
      const compName = record.component;
      const { verificationId, vectorList, spiceList, clock, interfaceType } = this.props;
      let _pins = record.compPins ? record.compPins.filter(item => item.usage === 'Driver') : [];
      const pinModels = getPinModels(record.pinModels);
      const pinNode = getStimulusText(record);
      let defaultDelayValue = SPI_TYPES.includes(interfaceType) && CLK_NAME_TYPES.find(item => record.signal.includes(item)) ? { type: 'percent', value: '35' } : null;
      return type === 'tx' ? {
        record,
        edit: true,
        verificationId,
        text: record.model && record.model.libType === MultiPinSPICE ? pinNode : record.inputStimulus,
        modelType: "Stimulus",
        pins: _pins,
        compName,
        compModel: record.compModel || null,
        product: SIERRA,
        model: record.model || {},
        className: 'sierra-stimulus-editable-cell-value-wrap',
        stimulusModel: {/* model */ },
        customInput: PinNodeConnect,
        pinModels: JSON.parse(JSON.stringify(record.pinModels || [])),//{type:type-seed}
        modelPins: JSON.parse(JSON.stringify(pinModels || [])),//{type,seed}
        displayType: "model",
        vectorList: vectorList,
        dataIndex: 'inputStimulus',
        libraryList: spiceList,
        getPins: getPins,
        getLibraryFile: getSpiceModelList,
        getFolderFileDetail,
        stimulus: this.getStimulus(record),
        saveModelPins: this.saveModelPins,
        savePinStimulusModel: this.savePinStimulus,
        clock,
        applyAll: false,
        defaultDelayValue
      } : {
        edit: false,
      }
    }

    columns[4].render = (text, record) => {
      if (!record.usage || record.usage !== 'Driver') return <span className='sierra-corner-row-disabled'></span>;
      if (!text) return
      if (text === MultiPinSPICE) {
        const pinNode = getStimulusText(record);
        return <span className='sierra-stimulus-editable-cell-value-wrap'>{pinNode}</span>
      }
      return <span className='sierra-stimulus-editable-cell-value-wrap'>{text}</span>
    }

    columns[5].onCell = (record) => {
      if (chipModelUsages.includes(record.usage) && record.model && record.model.libType === _IBIS) {
        return {
          record,
          edit: "select",
          options: CORNER,
          dataIndex: "corner",
          handleSave: this.editCorner,
        };
      } else {
        return {
          edit: false
        }
      }
    };

    columns[5].render = (text, record) => {
      if (chipModelUsages.includes(record.usage) && record.model && record.model.libType === _IBIS) {
        return <span>{text}</span>
      } else {
        return <span className='sierra-corner-row-disabled'></span>
      }
    }

    this.columns = columns
  }

  applyUsageChange = (checked) => {
    this.props._updateApplyUsage(checked);
  }

  savePinStimulus = (model, record) => {
    this.props._savePinStimulusModel(model, record);
  }

  saveChipModel = ({ files, libType, pairs, record }) => {
    const { pcb, pcbId, component } = record;
    this.props._saveChip({ files, libType, pairs, pcb, pcbId, name: component });
  }

  saveRepeaterModel = (model, record) => {
    const { pcb, pcbId, component } = record;
    if (component) {
      this.props._saveRepeater({ model, name: component, pcb, pcbId });
    }
  }

  editComponentPinUsage = async (row) => {
    const { usageApplyAll } = this.props;
    this.props._editComponentPinUsage(row, usageApplyAll)
  }

  getPinTableData() {
    let pins = [];
    const { Interfaces, current } = this.props;
    const components = getComponentsByPCB(Interfaces, current);
    let ICComps = components.filter(item => ChipType.includes(item.type));
    const sort = [CHIP, IC, COMP_REPEATER];
    ICComps = SortFn(ICComps, sort, 'type');
    let _pins = [], _signalNames = [];
    if (ICComps.length > 0) {
      ICComps.forEach(comp => {
        comp.pins.forEach(pinModel => {
          let model = pinModel.model;
          let inputStimulus = "", stimulus = null;
          if (pinModel.pinModels && pinModel.pinModels.length > 0) {
            const ND_IN = pinModel.pinModels.find(item => item.pinName === "nd_in");
            const ND_EN = pinModel.pinModels.find(item => item.pinName === "nd_en");
            if (ND_EN) {
              if (ND_EN.type === 'VEC') {
                inputStimulus = ND_EN.type;
                stimulus = ND_EN.stimulus;
              } else {
                inputStimulus = ND_EN.type || ND_IN.type;
              }
            } else {
              inputStimulus = ND_IN.type.includes('PRBS') && ND_IN.tabs && ND_IN.tabs.length ? `${ND_IN.type}[${ND_IN.tabs.join(',')}]` : ND_IN.type;
              stimulus = ND_IN.stimulus;
            }
          }

          if (pinModel.usage === COMP_REPEATER) {//repeater
            model = comp.model;
          }
          const info = {
            pcb: comp.pcb,
            pcbId: comp.pcbId,
            component: comp.name,
            compModel: comp.model,
            part: comp.part,
            ...pinModel,
            model: model,
            inputStimulus,
            stimulus,
            deviceVcc: comp.deviceVcc || "",
            compPins: comp.pins
          };
          const _index = _signalNames.indexOf(pinModel.signal)
          if (_index > -1) {
            _pins[_index].push(info);
          } else {
            _signalNames.push(pinModel.signal);
            _pins.push([info]);
          }
        });
      });
    };
    _pins.forEach(item => {
      // const sort = ['', 'Driver', 'Receiver', COMP_REPEATER];
      // let _sorted = SortFn(item, sort, 'usage');
      // _sorted[0].signalLength = _sorted.length;
      item[0].signalLength = item.length;
      pins.push(...item);
    });
    return pins;
  }

  editCorner(row) {
    const { component, pin, corner, signal, net, pcbId, pcb } = row;
    const { Interfaces } = this.props;
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let components = [...editInterface.components];
    const compIndex = components.findIndex(comp => comp.name === component);
    const pinIndex = components[compIndex].pins.findIndex(item => item.pin === pin && item.signal === signal && item.net === net);
    components[compIndex].pins[pinIndex].corner = corner;
    _Interfaces[_index].content.components = [...components];
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
  }

  assignModel = ({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }) => {
    this.props.saveModel({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff });
  }

  saveModelPins = ({ record, pinModels, applyAll, applyAllDriver }) => {
    this.props.savePinValue({ record, pinModels, applyAll, applyAllDriver });
  }

  savePowerOff = ({ record, powerOff, applyAll }) => {
    // this.props.savePowerOffMode(record, powerOff, applyAll);
  }

  getStimulus(record) {
    const pins = record.pinModels;
    let stimulus, pinName;
    if (!pins) {
      return { stimulus, pinName };
    }
    for (let pin of pins) {
      if (pin.stimulus) {
        pinName = pin.pinName;
        stimulus = pin.stimulus;
      }
    };
    return { stimulus, pinName }
  }

  render() {
    const { usageApplyAll } = this.props;
    let pins = this.getPinTableData() || [];
    let scrollX = null, scrollY = null, scroll = {};
    /* if (below && pins && pins.length && pins.length > 0) {
      scrollX = 1000;
    } */
    if (pins && pins.length && pins.length > 9) {
      scrollY = 342;
    }
    if (scrollY && scrollX) {
      scroll = {
        x: scrollX,
        y: scrollY
      }
    }

    if (!scrollY && scrollX) {
      scroll = {
        x: scrollX
      }
    }

    if (scrollY && !scrollX) {
      scroll = {
        y: scrollY
      }
    }
    return (
      <Fragment>
        <div className="sierra-copy-usage">
          <span>Apply the same usage setting to all the pins in the component</span>
          <Switch
            size="small"
            className="aurora-switch-small sierra-copy-usage-switch"
            checked={usageApplyAll}
            onChange={this.applyUsageChange}
          />
        </div>
        <EditableTable
          className='sierra-pin-table space-10'
          rowKey={record => record.pcb
            ? (record.pcb + "_" + record.component + "_" + record.pin)
            : (record.component + "_" + record.pin)}
          columns={this.columns}
          size="small"
          dataSource={pins}
          scroll={scroll}
        />
      </Fragment>
    )
  }
}
const mapState = (state) => {
  const { SierraReducer: { sierra, project: { verificationId, currentProjectVerifications } } } = state;
  const sierraInfo = sierra.sierraInfo;
  const currentConfig = sierra.currentConfig;
  let Interfaces = [];
  if (sierraInfo) {
    Interfaces = sierraInfo.Interfaces || [];
  }
  let interfaceInfo = currentProjectVerifications.find(item => item.id === verificationId);
  let interfaceType = interfaceInfo ? interfaceInfo.type : ''
  return {
    Interfaces,
    verificationId,
    usageApplyAll: sierra.usageApplyAll,
    clock: currentConfig && currentConfig.clock ? currentConfig.clock : {},
    interfaceType,
    assignBufferModelStatus: sierra.assignBufferModelStatus
  }
};


const mapDispatch = (dispatch) => ({
  updateInterfaces({ Interfaces, pcb, pcbId }) {
    dispatch(updateInterfaces({ Interfaces, pcb, pcbId }));
  },
  saveModel({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }) {
    dispatch(assignModel({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }));
  },
  savePinValue({ record, pinModels, applyAll, applyAllDriver }) {
    dispatch(saveModelPins({ record, pinModels, applyAll, applyAllDriver }));
  },
  savePowerOffMode(record, powerOff, applyAll) {
    dispatch(savePowerOff(record, powerOff, applyAll));
  },
  _saveRepeater({ model, name, pcb, pcbId }) {
    dispatch(saveRepeater({ model, name, pcb, pcbId }));
  },
  _saveChip({ files, libType, pairs, pcb, pcbId, name }) {
    dispatch(saveChip({ files, libType, pairs, pcb, pcbId, name }));
  },
  _savePinStimulusModel(model, record) {
    dispatch(savePinStimulusModel(model, record));
  },
  _updateApplyUsage(usageApplyAll) {
    dispatch(updateApplyUsage(usageApplyAll));
  },
  _editComponentPinUsage(row, usageApplyAll) {
    dispatch(updateComponentPinUsage(row, usageApplyAll))
  }
})

export default connect(mapState, mapDispatch)(PinTable);
