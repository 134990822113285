import React, { Component, Fragment, createRef } from 'react';
import { CameraOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { EffectBarCanvas } from '@/services/Cascade/result';

class EffectCanvas extends Component {
  constructor(props) {
    super(props);
    this.resRef = createRef();
    this.indRef = createRef();
  }

  componentDidMount() {
    this.screenChange();
    this.initCanvas();
  }

  componentDidUpdate(prevProps) {
    const { colors, colorStatus, loading, redraw } = this.props;
    if (colors.length !== prevProps.colors.length || (!loading && loading !== prevProps.loading)) {
      this.initBarColor()
    }

    if (colorStatus !== prevProps.colorStatus) {
      this.setNewBarColor()
    }

    if (redraw !== prevProps.redraw) {
      this.indCanvas && this.indCanvas.draw();
      this.resCanvas && this.resCanvas.draw();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    setTimeout(() => {
      this.drawBarChart()
    }, 50)
  }

  initCanvas = () => {
    const { powerNet } = this.props;
    const title = `${powerNet} - Ports`
    const resCanvas = new EffectBarCanvas({
      svgEle: this.resRef.current,
      title,
      yTitle: 'Resistance DC (mΩ)',
      key: 'res',
      powerNet,
      mouseMove: this.mouseMove
    });
    this.resCanvas = resCanvas;

    const indCanvas = new EffectBarCanvas({
      svgEle: this.indRef.current,
      title,
      yTitle: 'Inductances (pH)',
      key: 'ind',
      powerNet,
      mouseMove: this.mouseMove
    });
    this.indCanvas = indCanvas;

    this.initBarColor();
  }

  initBarColor = () => {
    const { colors } = this.props;
    this.resCanvas.setColor(colors);
    this.indCanvas.setColor(colors);
    this.drawBarChart();
  }

  setNewBarColor = () => {
    const { colors } = this.props;
    this.resCanvas.setColor(colors);
    this.indCanvas.setColor(colors);

    this.resCanvas.changeBarColor();
    this.indCanvas.changeBarColor();
  }

  drawBarChart = () => {
    const { ports } = this.props;
    this.resCanvas.setData(ports);
    this.resCanvas.draw();

    this.indCanvas.setData(ports);
    this.indCanvas.draw();
  }

  mouseMove = (boolean, powerNet) => {
    this.props.showTip(boolean, powerNet);
  }

  render() {
    const { powerNet = "", shot } = this.props;
    return (
      <Fragment>
        <div className="cascade-result-effect-title" style={{ margin: 0 }}>
          {powerNet}
          {!shot ? <CameraOutlined
            className='camera-icon'
            title={"Screenshot"}
            onClick={() => this.props.screenShot(powerNet)} /> : null}
        </div>
        <div className=" cascade-result-effect-canvas">
          <div className="canvas-content">
            <svg ref={this.resRef} style={{ height: 500, width: '100%', overflow: 'visible' }}></svg>
          </div>
          <Divider type="vertical" className="cascade-result-effect-divider" />
          <div className="canvas-content">
            <svg ref={this.indRef} style={{ height: 500, width: '100%', overflow: 'visible' }}></svg>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EffectCanvas