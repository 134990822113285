import React, { Component, createRef, Fragment, } from "react";
import { PlusSquareOutlined } from '@ant-design/icons';
import { message, Dropdown, Tooltip } from "antd";
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { openUploadProgressModal, changePercentAction, showErrorMsgAction, closeUploadProgressModal, translationProgress, saveCurrentPCBKey, changeDisableStatus } from './action';
import { uploadPromise, replaceDesignPromise } from '@/services/api/Design/design';
import { uploadProjectID, addMonitorMsg, currentUploadDesignId, updateCompressProgress, translationFailed } from './action';
import { LAYOUT } from '@/constants/designType';
import { getPCBUploadList, ZIP_ACCEPT_STR } from "@/services/PCBHelper/pcbHelper";
import { FASTPI } from '@/constants/pageType';
import JSZip from 'jszip';
import getDevelopMode from '@/services/api/cookies';
import { openTabFooter, changeTabMenu } from '../../../tabMonitor/action';
import { openProject } from '../../store/project/action';
import { fakeProgress } from '@/services/helper/dataProcess';
import NP from 'number-precision';
import { strDelimited } from "@/services/helper/split";
import '@/publicCss/uploadPCB.css';
import LayoutData from '@/services/data/LayoutData';

class DesignUpload extends Component {

  constructor(props) {
    super(props);
    this.vendor = null;
    this.designType = null;
    const { key } = props.data;
    const { type } = props
    const [projectId] = strDelimited(key, "-", { startSubscript: 1 });
    this.projectID = type === 'upload' ? projectId : props.currentProjectId;
    this.state = {
      acceptFormat: null,
      format: null,
      libraryType: null
    }
    this.uploadRef = createRef();
    this.uploadModelRef = createRef();
    this.uploadDirectory = createRef();
    this.develop = getDevelopMode();
    this.pcbList = getPCBUploadList(FASTPI);
  }

  UploadButtonClick = (e, v, format, update) => {
    e.domEvent.stopPropagation();
    this.vendor = v;
    this.designType = LAYOUT;
    this.update = update;
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'upload', projectId: this.projectID, verificationId: null });
    this.props.openTabFooter();
    if (format === 'directory') {
      const el = this.uploadDirectory.current;
      if (!el) return;
      el.click();
    } else {
      this.setState({
        acceptFormat: format
      }, () => {
        const ele = this.uploadRef.current;
        if (!ele) return;
        ele.click();
      })
    }
  }

  menu = (update) => {
    return this.pcbList.map(item => {
      return {
        key: item.key,
        label: item.title,
        className: item.className,
        onClick: item.children ? null : (e) => this.UploadButtonClick(e, item.vendor, item.accept, update),
        children: item.children ? item.children.map(child => {
          return {
            key: child.key,
            label: child.title,
            className: child.className,
            onClick: (e) => this.UploadButtonClick(e, child.vendor, child.accept, update),
          }
        }) : null
      }
    })
  }

  uploadChange = (e) => {
    this.props.changeDisableStatus(true);
    this.props.uploadProject(this.projectID);
    this.props.openUploadProgressModalAction();
    const { acceptFormat } = this.state;
    const files = e.target.files;
    let progress = 2, indexNum = 0;
    const isZipFile = ['application/x-zip-compressed', 'application/zip', "application/x-compressed-tar"].includes(files[0].type)
      || acceptFormat === ZIP_ACCEPT_STR;

    if (files.length === 1 && isZipFile) {
      this.props.updateCompressProgress(-1);
    } else {
      this.compressTime = setInterval(() => {
        indexNum += 1;
        progress = fakeProgress(progress, indexNum);
        this.props.updateCompressProgress(progress)
      }, 2000);
    }
    let zip = new JSZip();
    let fileObj;
    /* if (files.length === 1) {
      this.props.updateMsg(`==> Start uploading PCB: "${files[0].name}".`);
    } */
    if (files.length === 1 && isZipFile) {
      this.designName = files[0].name;
      this.props.progressAction(0);
      this.props.updateMsg(`==> Start uploading PCB: "${this.designName}".`);
      this.uploadLayout(files[0], this.designName);
    } else if (files.length === 1 && !isZipFile) {
      this.designName = files[0].name;
      if (files[0].webkitRelativePath) {
        const number = files[0].webkitRelativePath.indexOf('/');
        this.designName = files[0].webkitRelativePath.substring(0, number);
      }
      this.props.updateMsg(`==> Start compressing PCB: "${this.designName}".`);
      zip.file(files[0].name, files[0]);
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, this.metadata).then(blob => {
        clearInterval(this.compressTime);
        setTimeout(() => {
          this.props.updateCompressProgress(-1);
          this.props.updateMsg(`==> Finish compressing PCB.\n==> Start uploading PCB: "${this.designName}".`);
          this.props.progressAction(0);
          fileObj = new File([blob], "design.zip");
          this.uploadLayout(fileObj, this.designName);
        }, 800);
      })
    } else {
      this.designName = files[0].name;
      if (files[0].webkitRelativePath) {
        const number = files[0].webkitRelativePath.indexOf('/');
        const fileName = files[0].webkitRelativePath.substring(0, number);
        this.designName = fileName ? fileName : files[0].name;
      }
      this.props.updateMsg(`==> Start compressing PCB: "${this.designName}".`);
      for (const file of files) {
        zip.file(file.webkitRelativePath, file);
      }
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, this.metadata).then(blob => {
        setTimeout(() => {
          clearInterval(this.compressTime);
          this.props.updateCompressProgress(-1);
          this.props.progressAction(0);
          fileObj = new File([blob], "design.zip");
          this.props.updateMsg(`==> Finish compressing PCB.\n==> Start uploading PCB: "${this.designName}".`);
          //this.props.updateMsg(`==> Start uploading PCB: ${fileName}.`);
          this.uploadLayout(fileObj, this.designName);
        }, 800);
      })
    }
  }

  metadata = (metadata) => {
    clearInterval(this.compressTime);
    const progress = NP.strip(parseFloat(metadata.percent.toFixed(0)));
    let { compressProgress } = this.props;
    //Avoid progress updates too fast
    if ((progress - compressProgress) >= 10 || progress === 100) {
      this.props.updateCompressProgress(progress);
    }
  }


  splitFileName(text) {
    var pattern = /\.{1}[a-zA-Z]{1,}$/;
    if (pattern.exec(text) !== null) {
      return (text.slice(0, pattern.exec(text).index));
    } else {
      return text;
    }
  }

  uploadLayout = (zipfile, fileName) => {
    const _name = this.splitFileName(fileName);
    const { changeDisableStatus, data, currentProjectId } = this.props;
    if (this.update) {
      this.update = false;
      LayoutData.cleanLayoutInfo(data.id)
      this.props.uploadProject(currentProjectId);
      replaceDesignPromise({
        designId: data.id,
        file: zipfile,
        name: data.name,
        projectId: currentProjectId,
        type: this.designType,
        vendor: this.vendor,
        config: this.config,
        isNew: 'true'
      }).then(response => {
        this.translationFlow(response);
      }, error => {
        this.props.updateMsg(`==> Upload failed. ${error}.`);
        //msg:null
        this.props.translationFailed(null);
        console.error(error)
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
        message.error('Update failed!', 2);
        changeDisableStatus(false);
      })
    } else {
      uploadPromise({
        file: zipfile,
        name: _name,
        projectId: this.projectID,
        type: this.designType,
        vendor: this.vendor,
        config: this.config,
        isNew: 'true'
      }).then(response => {
        this.translationFlow(response);
      }, error => {
        this.props.updateMsg(`==> Upload failed.`);
        //msg:null
        this.props.translationFailed(null);
        console.error(error)
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
        message.error('Upload failed!', 2);
        changeDisableStatus(false);
      })
    }
  }

  translationFlow = (response) => {
    const { uploadSuccessAction, closeUploadProgressModalAction, beginTranslation, changeDisableStatus, currentProjectId } = this.props;
    // // Aurora DB
    if (response && !response.workflowId) {
      // reload the design table
      uploadSuccessAction();
      // close progress modal
      closeUploadProgressModalAction();
      if (currentProjectId === this.projectID) {
        this.props.openProject(this.projectID);
        message.success('Upload completed!', 3);
      }
      this.props.updateMsg(`==> Upload completed.`);
      changeDisableStatus(false);
    }

    // Translation
    if (response && response.workflowId) {
      const { workflowId, designId } = response;
      this.props.uploadDesignId(designId, workflowId);
      // begin translate
      this.props.updateMsg(`==> Finish uploading PCB.\n==> Start compiling PCB.`);
      beginTranslation({ workflowId, designId, projectID: this.projectID });
    }

    if (this.uploadDirectory.current) {
      this.uploadDirectory.current.value = '';
    }
    if (this.uploadRef.current) {
      this.uploadRef.current.value = '';
    }
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      // console.debug('onUploadProgress called with', arguments, 'Percent Completed:' + percentCompleted)
      this.props.progressAction(percentCompleted);
    }
  }

  addClick = (e, key) => {
    e.stopPropagation();
    this.props.saveCurrentPCBKey(key);
  }

  fileClick = (e) => {
    e.stopPropagation();
  }

  uploadFile = () => {
    const { acceptFormat } = this.state;
    const content = (
      <Fragment>
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={acceptFormat}
          onChange={this.uploadChange}
          onClick={(e) => this.fileClick(e)}
        />
        <input
          type='file'
          ref={this.uploadDirectory}
          style={{ display: 'none' }}
          onChange={this.uploadChange}
          webkitdirectory="true"
          directory="true"
          onClick={(e) => this.fileClick(e)}
        />
      </Fragment>
    )
    return createPortal(content, document.getElementById('root'));
  }

  render() {
    const { acceptFormat } = this.state;
    const { key, data, disabled, type } = this.props;
    return (
      <div className="aurora-PCB-upload">
        {
          type === 'upload' ?
            <Tooltip
              title={disabled ? 'There is already a pcb being uploaded.' : ''}
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              overlayClassName='pcb-icon-tooltip'
            >
              <Dropdown disabled={data.iconDisabled || disabled} menu={{ items: this.menu(false), className: "upload-dropdown-button" }} trigger={['click']}>
                <PlusSquareOutlined
                  className={data.iconDisabled || disabled ? 'aurora-tree-add-icon icon-disabled' : 'aurora-tree-add-icon'}
                  key={key}
                  onClick={(e) => this.addClick(e, data.key)} />
              </Dropdown>
            </Tooltip>
            :
            type === 'replace' && <Tooltip
              title={disabled ? 'There is already a pcb being uploaded.' : 'PCB Replace'}
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              overlayClassName='pcb-icon-tooltip'
              autoAdjustOverflow={false}
            >
              <Dropdown disabled={disabled} menu={{ items: this.menu(true), className: "upload-dropdown-button" }} trigger={['click']}>
                <span key={key}
                  onClick={(e) => this.addClick(e, 'replace')}
                  className={disabled ? `iconfont icon-replace1 aurora-replace-icon icon-disabled` : `iconfont icon-replace1 aurora-replace-icon`}></span>
              </Dropdown>
            </Tooltip>
        }
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={acceptFormat}
          onChange={this.uploadChange}
          onClick={(e) => this.fileClick(e)}
        />
        <input
          type='file'
          ref={this.uploadDirectory}
          style={{ display: 'none' }}
          onChange={this.uploadChange}
          webkitdirectory="true"
          directory="true"
          onClick={(e) => this.fileClick(e)}
        />
      </div>
    );
  }
}

const mapState = (state) => {
  const { PDNUploadReducer, project: { currentProjectId } } = state.PDNReducer;
  const { disabled, compressProgress } = PDNUploadReducer;

  return {
    disabled,
    compressProgress,
    currentProjectId
  }
}

const mapDispatch = (dispatch) => ({
  uploadSuccessAction(ID) {
  },
  openUploadProgressModalAction() {
    dispatch(openUploadProgressModal());
  },
  progressAction(percentCompleted) {
    dispatch(changePercentAction(percentCompleted));
  },
  showErrorMsg(msg) {
    dispatch(showErrorMsgAction(msg));
  },
  closeUploadProgressModalAction() {
    dispatch(closeUploadProgressModal());
  },
  beginTranslation({ workflowId, designId, projectID }) {
    dispatch(translationProgress({ workflowId, designId, projectID }));
  },
  uploadProject(projectId) {
    dispatch(uploadProjectID(projectId));
  },
  uploadDesignId(designId, workflowId) {
    dispatch(currentUploadDesignId(designId, workflowId));
  },
  updateMsg(msg) {
    dispatch(addMonitorMsg(msg));
  },
  openTabFooter() {
    dispatch(openTabFooter())
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }))
  },
  openProject(id) {
    dispatch(openProject(id));
  },
  saveCurrentPCBKey(key) {
    dispatch(saveCurrentPCBKey(key));
  },
  changeDisableStatus(disabled) {
    dispatch(changeDisableStatus(disabled));
  },
  updateCompressProgress(compressProgress) {
    dispatch(updateCompressProgress(compressProgress))
  },
  translationFailed(msg) {
    dispatch(translationFailed(msg))
  }
})
export default connect(mapState, mapDispatch)(DesignUpload);