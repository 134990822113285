import { all, takeEvery, put, call, fork, race, take, delay, select } from 'redux-saga/effects';
import { RUNNING, SUCCEED, FAILED, CANCEL } from '@/constants/workflowStatus';
import { SUCCESS, CHECK_FAIL } from '@/constants/returnCode';
import { getWorkFlow, cancelWorkflow } from '@/services/api/v2/workflowCtrl';
import getDesignFile from '@/services/api/designFile';
import {
  TRANSLATION_SUCCESS, TRANSLATION_FAILED, GET_WORK_FLOWS,
  TRANSLATION_RUNNING, GET_TRANSLATION_MONITOR, CANCEL_UPLOAD_WORKFLOW, TRANSLATION_FLOW,
  CLEAR_REPLACE_PCB_INFO
} from './actionType';
import {
  translationProgress, translationSuccess, translationFailed,
  getWorkFlow as workFlowAction,
  translationMonitor, addMonitorMsg,
  translationDebug,
  closeUploadProgressModal,
  updateUploadWorkflow
} from './action';
import { getMonitor } from '@/services/workflow/workflow';
import { expandMenu } from '../../../store/sierra/action';
import { OPEN_PROJECT } from '../../../store/project/actionTypes';
import { openProject, saveComponentsNetsInProject, updatePCBComponentsNets } from '../../../store/project/action';
import { REPLACE_PCB } from '../../../../../constants/treeConstants';
import DesignInfo from '@/services/Sierra/pcbInfo';
import LayoutData from '@/services/data/LayoutData';

function* pollData(workflowId, projectID, designId) {
  try {
    yield delay(3000);
    yield put(workFlowAction(workflowId, projectID, designId));
  } catch (error) {
    return;
  }
}


const KEYARR = ['Statistics', 'Total counts', 'Layers:', 'Trace length',
  'Net histograms', 'Polygon edges', 'Pins:', 'Vias:'];
function filterContent(log) {
  try {
    if (KEYARR === 'undefined' || !(KEYARR instanceof Array) || KEYARR.length < 1) return false;
    const arrTxt = KEYARR.join('|'),
      regObj = new RegExp(arrTxt, 'ig');
    const _match = log.match(regObj);
    if (_match && _match.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
  }
}

function* dataFetch(action) {
  yield takeEvery(GET_WORK_FLOWS, function* (action) {
    const { workflowId, projectID, designId } = action;
    try {
      const response = (yield call(getWorkFlow, workflowId)).data;
      let monitor = yield call(getMonitor, workflowId);
      let _monitor = [], _debugMonitor = [];
      const progress = /[0-9]+[%]/g;
      for (let item of monitor) {

        // Filter progress bar
        const _match = item.log.match(progress);
        if (_match && _match.length > 0) {
          continue;
        }

        // Filter '' string
        if (!item.log) {
          continue;
        }

        // Filter debug log
        if (filterContent(item.log)) {
          _debugMonitor.push(item);
          continue;
        }

        _monitor.push(item);
      };
      yield put(translationMonitor(_monitor));
      yield put(translationDebug(_debugMonitor));
      if (response.code === SUCCESS) {
        const { data } = response;
        if (data.status === RUNNING) {
          const progress = parseInt(data.progress);
          const running = translationProgress({ workflowId, designId, projectID, progress });
          yield put(running);
        }
        const { SierraReducer: { SierraUploadReducer: { currentPCBKey } } } = yield select();
        if (data.status === SUCCEED) {
          const msg = `==> Finish compiling PCB.`
          const succeeded = translationSuccess(data.progress, msg);
          yield put(succeeded);
          const { SierraReducer: { sierra: { expandedKeys }, SierraUploadReducer: { currentPCBKey }, project: { currentProjectId } } } = yield select();
          if (currentProjectId === projectID) {
            yield put({ type: OPEN_PROJECT, id: projectID });
            let Keys = [...expandedKeys];
            if (currentPCBKey && !Keys.includes(currentPCBKey)) {
              Keys.push(currentPCBKey);
            }
            yield put(expandMenu([...Keys]));
          }
          if (currentPCBKey === REPLACE_PCB) {
            yield put(saveComponentsNetsInProject([designId], true))
          }
        }

        if (data.status === FAILED) {
          const _msg = data.message || "Unknown reason"
          const msg = `==> Failed compiling PCB. \n==> ${_msg}`;
          const failed = translationFailed(msg, data.progress);
          const { SierraReducer: { project: { currentProjectId } } } = yield select();
          if (currentProjectId === projectID) {
            yield put({ type: OPEN_PROJECT, id: projectID });
          }
          if (currentPCBKey === REPLACE_PCB) {
            yield put(saveComponentsNetsInProject([designId], true))
          }
          yield put(failed);
        }

        if (data.status === CANCEL) {
          const msg = `==> Upload PCB closed.`;
          yield put(translationFailed(msg, 100));
          if (currentPCBKey === REPLACE_PCB) {
            yield put(saveComponentsNetsInProject([designId], true))
          }
        }
      } else if (response.code === CHECK_FAIL) {
        const msg = `==> Failed compiling PCB. \n` + response.msg;
        yield put(translationFailed(msg, 100));
      }
    } catch (error) {
      const _msg = typeof (error) === "string" ? error : "Unknown reason";
      const msg = `==> Failed compiling PCB. \n ==> ${_msg}`;
      yield put(translationFailed(msg, 100));
      console.error(error);
    }
  })
}

function* getLog(action) {
  yield takeEvery(GET_TRANSLATION_MONITOR, function* (action) {
    const { designId } = action;
    const response = yield call(getDesignFile, designId, 'lay_trans/log/layout_trans.log');
    let monitor = response ? response.data : null;
    yield put(translationMonitor(monitor));
  })
}

function* translationWork(action) {
  yield takeEvery(TRANSLATION_RUNNING, function* (action) {
    const { workflowId, designId, projectID } = action;
    try {
      yield race([
        call(pollData, workflowId, projectID, designId),
        take(TRANSLATION_SUCCESS),
        take(TRANSLATION_FAILED),
      ]);
    } catch (error) {
      console.error(error);
    }
  })
}

function* cancelUpload(action) {
  const { workflowId } = action;
  yield call(cancelWorkflow, workflowId);
  const msg = `==> Upload PCB closed.`;
  yield put(translationFailed(msg, 100));
  const { SierraReducer: { project: { currentProjectId } } } = yield select();
  if (currentProjectId) {
    yield put({ type: OPEN_PROJECT, id: currentProjectId });
  }
}

function* pcbTranslationFlow(action) {
  const { response, designId: _designId } = action;
  const responseData = response || {};
  const { SierraReducer: { SierraUploadReducer: { uploadProjectId, uploadDesignType } } } = yield select();
  //Directory (Aurora DB,AAF)
  if (responseData && !responseData.workflowId) {
    const { SierraReducer: { project: { currentProjectId } } } = yield select();
    // clean upload status
    yield put(closeUploadProgressModal(`==> Upload completed.`));
    if (currentProjectId === uploadProjectId) {
      yield call(reOpenProject, uploadProjectId);
    }
    yield call(_pcbReplaceFn, _designId);
  }

  // Translation
  if (responseData && responseData.workflowId) {
    const { workflowId, designId } = responseData;

    yield put(updateUploadWorkflow(designId, workflowId));
    // begin translate
    const _uploadDesignType = uploadDesignType || "PCB";
    yield put(addMonitorMsg(`==> Finish uploading ${_uploadDesignType}.\n==> Start compiling ${_uploadDesignType}.`));
    yield put(translationProgress({ workflowId, designId, projectID: uploadProjectId }));
  }
}

function* reOpenProject(uploadProjectId) {
  const { SierraReducer: { project: { currentProjectId } } } = yield select();
  if (currentProjectId === uploadProjectId) {
    yield put(openProject(uploadProjectId));
  }
}

function* _pcbReplaceFn(designId) {
  const { SierraReducer: { SierraUploadReducer: { currentPCBKey } } } = yield select();
  if (currentPCBKey === REPLACE_PCB) {
    //pcb replace
    yield put(saveComponentsNetsInProject([designId], true));
  }
}

function* clearReplacePCBCacheInfo(action) {
  const { designId } = action;
  let pcbsLayoutDB = [];
  const { SierraReducer: { project: { pcbComponentsNets } } } = yield select();

  if (pcbComponentsNets.length > 0 && pcbComponentsNets.includes(designId)) {
    pcbsLayoutDB = pcbComponentsNets.filter(item => item !== designId);
  }
  yield put(updatePCBComponentsNets(pcbsLayoutDB));
  DesignInfo.deletePCBInfo(designId);
  LayoutData.cleanLayoutInfo(designId)
}

export default function* FileUploadSaga() {
  yield all([
    fork(translationWork),
    fork(dataFetch),
    fork(getLog),
    takeEvery(CANCEL_UPLOAD_WORKFLOW, cancelUpload),
    takeEvery(TRANSLATION_FLOW, pcbTranslationFlow),
    takeEvery(CLEAR_REPLACE_PCB_INFO, clearReplacePCBCacheInfo)
  ])
}