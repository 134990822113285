import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { getLayoutDownloadSimulationUrl, PCB_PARSING, getDebugDownloadList } from '@/services/helper/debugHelper';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import DebugDownloadContent from '@/components/DebugDownload_v2';
import designConstructor from '../../../services/helper/designConstructor';
import '@/publicCss/style.css';
import '@/publicCss/debug.css';

class PCBDownloadPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 750,
      maxHeight: 304,
      debugMenuList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 304),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getDownloadOptions()
  }

  getDownloadOptions = () => {
    const { designId } = this.props;
    const design = designConstructor.getDesign(designId);
    const parsingData = this.getData(designId, design, PCB_PARSING);

    const debugMenuList = [parsingData];
    this.setState({
      debugMenuList
    })
  }

  getData = (id, design, menuType) => {
    const selectInfo = { id, name: design.name }
    const { downloadList, currentCheckValues } = this._getDownloadList({ menuType, vendor: design.vendor })
    const title = 'Layout Parsing';
    return {
      title,
      key: menuType,
      menuType: menuType,
      info: {
        selectList: [design],
        selectInfo
      },
      downloadList,
      currentCheckValues
    }
  }

  _getDownloadList = (params) => {
    const downloadList = this.getLayoutDebugDownloadList({ ...params });
    let currentCheckValues = [];
    for (let item of downloadList) {
      if (item.default) {
        currentCheckValues.push({ name: item.option, children: item.children ? item.children.map(it => it.option) : [] });
      }
    }
    return { downloadList, currentCheckValues }
  }

  getHeight = () => {
    const content = document.getElementById("debug-content-id");
    if (content) {
      const height = content.offsetHeight + 70 || 242;
      return height > 242 ? height : 242;
    }
    return 242;
  }

  closeModel = () => {
    this.props.closePanel(this.props.designId)
  }

  getLayoutDebugDownloadList = (params) => {
    return getDebugDownloadList(this.props.product, params)
  }

  render() {
    const { maxWidth, maxHeight, debugMenuList } = this.state;
    const height = this.getHeight();
    const content = (
      <Panel
        className='aurora-debug-panel panel-x-scroll-hidden'
        title={<div className='aurora-debug-title'>Layout Parsing</div>}
        onCancel={this.closeModel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 400 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        height={maxHeight > height ? null : height}
        overflow={"auto"}
      >
        <DebugDownloadContent
          debugMenuList={debugMenuList}
          getDownloadList={this.getLayoutDebugDownloadList}
          getDownloadSimulationURL={getLayoutDownloadSimulationUrl}
        />
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}
export default PCBDownloadPanel;