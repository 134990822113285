import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Select, Space, InputNumber, message, Dropdown } from 'antd';
import { changeStackupTable, settingUnit } from './store/actionCreators';
import { downloadFile } from '@/services/helper/downloadHelper'
import StackupData, { UNIT, changeExcelToCSV, uploadDatFile } from '@/services/data/stackupData';
import LayoutData from '@/services/CeLayoutDB/newCeLayoutData';
import { SIERRA, FASTPI } from '@/constants/pageType';

const Option = Select.Option;
const SpaceCompact = Space.Compact;

class StackupFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settingButon: 'block',
      settingInput: 'none',
      settingDielectric: 'none',
      type: null,
      range: null,
      thickness: null,
      dielectric: null,
      losstangent: null,
    }
    this.stackupRef = createRef();

  }

  showMetalSetting = (type, range) => {
    this.setState({
      type: type,
      range: range,
      settingButon: 'none',
      settingInput: 'block',
    })
  }

  showAllDielectric(type) {
    this.setState({
      type: type,
      settingButon: 'none',
      settingDielectric: 'block',
      settingInput: 'block',
    })
  }

  cancelClick = () => {
    this.setState({
      settingButon: 'block',
      settingInput: 'none',
      settingDielectric: 'none',
      thickness: null,
      dielectric: null,
      losstangent: null,
      type: null,
      range: null,
    })
  }

  settingUnitChange = (value) => {
    // TODO: Save unit to Server
    this.props.settingUnit(value);
  }

  thicknessChange = (value) => {
    this.setState({
      thickness: value,
    })
  }

  dielectricChange = (value) => {
    this.setState({
      dielectric: value,
    })
  }

  LossTangentChange = (value) => {
    this.setState({
      losstangent: value
    })
  }

  setLayersProperty = () => {
    const { data, unit } = this.props;
    const newData = [].concat(data);
    const { range, type, dielectric, losstangent } = this.state;
    let { thickness } = this.state;
    if (!thickness && !dielectric && !losstangent) {
      return;
    }
    const lastIndex = newData.length - 1;
    newData.forEach((item, index, array) => {
      if ((range === 'Outer' && index !== 0 && index !== lastIndex) ||
        (range === 'Inner' && (index === 0 || index === lastIndex))) {
        return;
      }
      if (item.type === type) {
        if (thickness) {
          item.thickness = thickness;
        }

        if (dielectric) {
          item.epsilon = dielectric;
        }

        if (losstangent) {
          item.delta = losstangent
        }
      }
    });
    this.props.changeTable(newData, unit);
    StackupData.saveLayer(newData, unit).then(res => {
      if (res.data.code !== 0) {
        message.error('Update stackup failed!');
      }
    })
    this.cancelClick();
  }

  reloadData = (unit, reSet) => {
    StackupData.getLayer(true, false, reSet).then(res => {
      const data = JSON.parse(JSON.stringify(res));
      this.props.changeTable(data, unit);
    })
  }

  reloadStackup = () => {
    LayoutData.requestStackup().then(response => {
      const unit = response.getUnitString();
      this.reloadData(unit, true);
    })
  }

  uploadStackup = (e) => {
    const el = this.stackupRef.current;
    if (!el) return;
    el.click();
  }

  onStackupChange = (e) => {
    const fileContent = e.target.files[0];
    let extensionName = null;
    if (fileContent) {
      const extensions = ['.csv', '.xls', '.xlsx', '.dat'];

      for (let i in extensions) {
        if (fileContent.name.lastIndexOf(extensions[i]) === fileContent.name.length - extensions[i].length) {
          extensionName = extensions[i];
          break;
        }
      }

      if (extensionName === null) {
        message.error("Unrecognized file type. We only support .csv, .dat, .xls and .xlsx files.")
      } else if (extensionName === '.dat') {
        uploadDatFile(fileContent).then((res) => {
          // TODO: error checking
          this.reloadStackup();
        }, (error) => {
          message.error('Upload stackup failed!');
          console.error(error);
        })
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (extensionName === '.xls' || extensionName === '.xlsx') {
            changeExcelToCSV(fileContent).then(res => {
              // TODO
            })
          } else {
            const { data, unit } = StackupData.parseUploadFile(e.target.result, this.props.data);
            if (!StackupData.checkUnit(unit)) {
              message.error("Unrecognized unit. We only support 'mil', 'mm', 'm', 'inch', 'um', 'cm'");
              return;
            }
            StackupData.saveLayer(data, unit).then((res) => {
              if (res.data.code !== 0) {
                message.error('Upload stackup failed!');
              }
              this.reloadData(unit);
            }, (error) => {
              message.error('Upload stackup failed!');
              console.error(error);

            })
          }
        };
        reader.readAsText(fileContent);
      }
    }

    // Fix the same path file can not be repeated upload problem : empty file value
    this.stackupRef.current.value = '';
  }

  downloadStackup = () => {
    let filename, link;

    var csv = this.convertArrayOfObjectsToCSV({
      data: this.generateStackupCSVData()
    });
    if (csv === null) return;

    filename = 'stackup.csv';

    csv = '#' + csv;

    let type = 'text/csv;charset=utf-8;';
    downloadFile(csv, filename, type)
  }

  convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data === null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  generateStackupCSVData() {
    const { data, unit } = this.props;
    const csvData = data.map(dataItem => {
      var outputData = {
        name: dataItem.name || "",
        type: dataItem.type || "",
        "dielectric constant": dataItem.epsilon || "",
        "loss tangent": dataItem.delta || ""
      };
      outputData["thickness(" + unit + ")"] = dataItem.thickness || "";
      return outputData;
    });
    return csvData;
  }

  render() {
    const { settingButon, settingInput, settingDielectric } = this.state;
    const { unit } = this.props;
    const menu = [
      { key: 'Upload Stackup', label: 'Upload Stackup', onClick: this.uploadStackup },
      { key: 'Download Stackup', label: 'Download Stackup', onClick: this.downloadStackup },
      { type: 'divider' },
      { key: 'All Metal Layers', label: 'All Metal Layers', onClick: () => this.showMetalSetting('Metal', 'All') },
      { key: 'Outer Metal Layers', label: 'Outer Metal Layers', onClick: () => this.showMetalSetting('Metal', 'Outer') },
      { key: 'Inner Metal Layers', label: 'Inner Metal Layers', onClick: () => this.showMetalSetting('Metal', 'Inner') },
      { type: 'divider' },
      { key: 'All Dielectric Layers', label: 'All Dielectric Layers', onClick: () => this.showAllDielectric('Dielectric') }
    ]
    return (
      <div className="stack-footer stackup-clear">
        <div className="stackup-footer-value-setting" style={{ display: settingButon }}>
          <Dropdown menu={{ items: menu }} trigger={['click']} placement="topRight">
            <Button
              type="primary"
              style={{ float: "right" }}
            >
              Menu
            </Button>
          </Dropdown>
          <input
            type='file'
            ref={this.stackupRef}
            style={{ display: 'none' }}
            accept=".csv,.xls,.xlsx,.dat"
            onChange={this.onStackupChange}
          />
        </div>
        <div className="stackup-input-row stackup-clear" style={{ display: settingInput }}>
          <div className="stackup-input-col stackup-clear">
            <span className="stackup-thickness-name">Thickness</span>
            <SpaceCompact className="stackup-input-group">
              <InputNumber
                min={0}
                onChange={this.thicknessChange}
                value={this.state.thickness}
                width={100}
              />
              <Select
                defaultValue={unit}
                value={unit}
                onChange={this.settingUnitChange}
                style={{ width: '64px' }}
              >
                {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
              </Select>
            </SpaceCompact>
          </div>
          <div className="stackup-input-col stackup-clear" style={{ display: settingDielectric }}>
            <span className="stackup-set-name">Dielectric Constant</span>
            <InputNumber
              min={0}
              onChange={this.dielectricChange}
              width={100}
              value={this.state.dielectric}
            />
          </div>
          <div className="stackup-input-col stackup-clear" style={{ display: settingDielectric }}>
            <span className="stackup-set-name">Loss Tangent</span>
            <InputNumber
              min={0}
              onChange={this.LossTangentChange}
              width={100}
              value={this.state.losstangent}
            />
          </div>
          <div className="stackup-input-col stackup-clear">
            <Button type="primary" className="stackup-set-button" onClick={this.setLayersProperty}>Set</Button>
            <Button type="primary" onClick={this.cancelClick}>Cancel</Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  const LoginReducer = state.LoginReducer;
  if (LoginReducer && LoginReducer.pageType === FASTPI) {
    return {
      data: [],
      unit: ""
    }
  } else if (LoginReducer && LoginReducer.pageType === SIERRA) {
    return {
      data: [],
      unit: ""
    }
  } else {
    const { data, unit } = state.stackup;
    return {
      data, unit
    }
  }
}

const mapDispatch = (dispatch) => ({
  changeTable(data, unit) {
    dispatch(changeStackupTable(data, unit));
  },
  settingUnit(unit) {
    dispatch(settingUnit(unit));
  }
})

export default connect(mapState, mapDispatch)(StackupFooter);