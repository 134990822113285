import { getSystemLibrary, getCustomLibrary } from './index';
import { SYS_LIBRARY_FOLDER, SYS_LIBRARY_FILE, CUSTOM_LIBRARY_FOLDER, CUSTOM_LIBRARY_FILE, CUSTOM_LIBRARY, CUSTOM_LIBRARY_DATA } from '@/constants/treeConstants';

const root = "-1"; // root folder
class SystemLibraryStore {
  constructor() {
    this.store = new Map(); // key: parentId, value:[]
  }

  cleanCache = () => {
    this.store = new Map();
  }

  initStore = async () => {
    try {
      let data = await getLibrary(root);
      let customData = await getCusLibrary(root);
      data = data.map(item => new SysLibraryItem(item));
      customData = customData.map(item => new SysLibraryItem(item, CUSTOM_LIBRARY))
      this.store.set(root, data);
      this.store.set(CUSTOM_LIBRARY, customData);
      return { data, customData };
    } catch (error) {
      console.error(error);
      return { data: [], customData: [] };
    }
  }

  getChildren = async ({ folderId, type }) => {
    let data = this.store.get(folderId);
    if (!data || !data.length) {
      try {
        data = type === CUSTOM_LIBRARY ? await getCusLibrary(folderId) : await getLibrary(folderId);
        data = Array.isArray(data) ? data.map(item => new SysLibraryItem(item, type)) : []
        this.store.set(folderId, data)
      } catch (err) {
        console.error(err);
        data = [];
      }
    }
    return data
  }

}

class SysLibraryItem {
  constructor({ id, name, parentId, type, path = '', nodeClass = 'tree-library-file' }, libraryType) {
    let dataType = "";

    if (libraryType === CUSTOM_LIBRARY) {
      dataType = type === 'folder' ? CUSTOM_LIBRARY_FOLDER : (type === "file" ? CUSTOM_LIBRARY_FILE : CUSTOM_LIBRARY_DATA);
    } else {
      dataType = type === 'folder' ? SYS_LIBRARY_FOLDER : SYS_LIBRARY_FILE;
    }
    this.name = name;
    this.type = dataType;
    this.icon = libraryType === CUSTOM_LIBRARY && parentId === "-1" ? "" : type;
    this.id = id;
    this.dataType = dataType;
    this.key = dataType + '-' + id;
    this.parentId = parentId;
    this.path = path;
    this.level = type === 'folder' ? path.split('/').length : path.split('/').length + 1;
    this.nodeClass = `${nodeClass} ${libraryType === CUSTOM_LIBRARY && parentId === "-1" ? 'expand-icon-middle' : ''}`;
    this.load = false;
  }
}

async function getLibrary(folderId) {
  try {
    const data = await getSystemLibrary(folderId);
    return data || [];
  } catch (err) {
    console.error(err);
    return []
  }
}

async function getCusLibrary(folderId) {
  try {
    const data = await getCustomLibrary(folderId);
    return data || [];
  } catch (err) {
    console.error(err);
    return []
  }
}


let newStore = new SystemLibraryStore();

export default newStore;
export { SysLibraryItem };