import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { VERIFICATION, RESULT, EXPERIMENTS, PCB_PRE_LAYOUT, MULTI_INTERFACE_SETUP, EXPERIMENTS_RESULT } from '@/constants/treeConstants';
import { openPage, changeTreeSelected, changeViewList, openNewPage } from '../../../store/project/action';
import { changeTabMenu, changeVerification, openTabFooter } from '../../../../tabMonitor/action';
import { startSierraVerification, changeUploadMes, updateRunSweeping } from '../../../store/simulation/action';
import {
  saveCurrentVerification,
  updateOptionsByClose,
  updateVerificationSetting,
  updateSetupAfterCloseSettings,
  updateCompSettingsStatus
} from '../../../store/sierra/action';
import { savePreLayoutSetting, createPreLayoutInterface } from '../../../store/prelayout/action';
import SetupOptionPanel from './setupOptionPanel';
import ComponentsSetting from '@/components/ComponentsSetting'
import componentSetting from '@/services/helper/componentsHelper/compSettingHelper';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import { SIERRA } from '@/constants/pageType';
import projectDesigns from '@/services/helper/projectDesigns';
import ExtractionSetup from '../ExtractionSetup';
import CompPinMap from '../../compPinMap';
import { PreLayoutSetting } from '../../../../../services/Sierra/prelayout';
import PreLayoutSettingPanel from '../../PreLayout/settingPanel';
import designConstructor from '../../../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../../../constants/designVendor';
import { startMultiSetupSim } from '../../../store/multiInterface/action';
import { parseSpiceModelSelector } from '@/services/LibraryHelper/SpiceModelHelper';
import { getLibraryFileContent, searchPowerNetsByKeywords } from '@/services/Sierra/library';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import { PASSIVE_SPICE, PASSIVE_TOUCHSTONE } from '../../../../../constants/libraryConstants';
import compTableHelper from '../../../../../services/Sierra/helper/compTableHelper';
import powerStore from '../../../../../services/Sierra/helper/powerNetsTableHelper';
import { TOP_BAR_RESULT } from '../../../../../services/TopBarHelper';
import NetsFilterPanel from '../../../LibraryPanel/NetsFilterPanel';
import DebugDownload from '../DebugDownload';
import getIcon from '../../../../../services/helper/iconHelper';
import MeasurementPanel from './measurement';
import Report from './Report';
import projectVerifications from '../../../../../services/Sierra/data/projectVerifications';
import Connections from '../../Connections';
import { updateExperimentInfo } from '../../../store/sweep/action';
import { updateTreeSelectKeys } from '../../../../../services/helper/filterHelper';
import preLayoutData from '../../../../../services/Sierra/prelayout/preLayoutData';
import { SCHEMATIC } from '../../../../../services/Sierra/prelayout/prelayoutConstants';
import './index.css';

const DEBUG = 'Debug',
  EXTRACTION_OPTIONS = 'Extraction Options',
  EXTRACTION_PORTS = 'Extraction Ports',
  SIMULATION = 'Run',
  COMPONENTS = "Components & Nets",
  OPTIONS = "Options",
  SETTING = "Settings",
  CREATE = "Create",
  MEASUREMENT = "Measurement",
  REPORT = "Report",
  CONNECTION = "Connection";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simulationloading: true,
      visible: {
        simOptions: false,
        extractionOptions: null,
        componentSetting: false,
        prelayoutSetting: false,
        [MEASUREMENT]: false,
        [REPORT]: false,
        [DEBUG]: false
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId, currentProjectId, viewList, preLayoutId, firstOpen } = this.props;
    const { visible } = this.state;
    const prevView = (prevProps.viewList || []).find(item => [MULTI_INTERFACE_SETUP, VERIFICATION].includes(item));
    const view = (viewList || []).find(item => [MULTI_INTERFACE_SETUP, VERIFICATION].includes(item));

    if (verificationId !== prevProps.verificationId || currentProjectId !== prevProps.currentProjectId || prevView !== view) {
      this.setState({
        visible: {
          ...visible,
          simOptions: false,
          componentSetting: false,
          extractionOptions: null,
          prelayoutSetting: false,
          [REPORT]: false
        }
      })
    }

    if ((preLayoutId !== prevProps.preLayoutId)) {
      this.setState({
        visible: {
          ...visible,
          simOptions: false,
          componentSetting: false,
          extractionOptions: null,
          prelayoutSetting: visible.prelayoutSetting === 'first' ? 'first' : false
        }
      })
    }

    if ((prevProps.viewList.includes(PCB_PRE_LAYOUT) && !viewList.includes(PCB_PRE_LAYOUT))) {
      this.setState({
        visible: {
          ...visible,
          simOptions: false,
          componentSetting: false,
          extractionOptions: null,
          prelayoutSetting: false
        }
      })
    }

    if (preLayoutId && firstOpen && firstOpen !== prevProps.firstOpen) {
      this.setState({
        visible: {
          ...visible,
          prelayoutSetting: 'first'
        }
      })
    }
  }

  getLibraryList = (type) => {
    if ([PASSIVE_SPICE, PASSIVE_TOUCHSTONE].includes(type)) {
      return sierraLibrary.getTree(type)
    }
    return []
  }

  openVerification = (verificationId, status) => {
    const { viewList, layout, pcbLayout, selectedKeys } = this.props;
    this.props._openPage(VERIFICATION, { verificationId });
    // cleanCompsInfo();
    let list = [...viewList, VERIFICATION];
    list = [...new Set(list)];
    list = list.filter(item => item !== RESULT);
    if (layout === 3) {
      list = [VERIFICATION]
    }
    if (status) {
      const selecteds = updateTreeSelectKeys({
        prevSelectedKeys: [...(selectedKeys || [])],
        key: VERIFICATION,
        eventKey: `${VERIFICATION}-${verificationId}`,
        selected: true,
        layout,
        pcbLayout,
        productType: SIERRA
      });
      this.props.changeTreeSelected([...selecteds]);
    }
    this.props.changeViewList(list);
    this.updateMonitorMessage(verificationId);
  }

  startVerification = () => {
    const { verificationId, simulateKeys, selectedKeys } = this.props;

    if (simulateKeys.length === 0 && verificationId) {
      if (selectedKeys.includes(`${VERIFICATION}-${verificationId}`)) {
        //save current verification ,and do simulation
        this.props.saveCurrentVerification([verificationId], verificationId);
        this.updateMonitorMessage(verificationId);
      }
      return;
    }

    if (simulateKeys.length === 0) {
      return;
    }
    let verificationIds = simulateKeys.map(item => item.verificationId);
    if (!verificationIds.includes(verificationId) || selectedKeys.includes(`result-${verificationId}`)) {
      this.openVerification(verificationIds[0], true);
      this.props.startSierraVerification(verificationIds, verificationIds[0]);
    } else {
      //save current verification ,and do simulation
      this.props.saveCurrentVerification(verificationIds, verificationId);
      this.updateMonitorMessage(verificationId);
    }
  }

  startSimulation = (e) => {
    e.stopPropagation();
    const { viewList } = this.props;
    // Prevent clicking too fast
    if (!this.state.simulationloading) {
      return
    }
    this.setState({
      simulationloading: false
    })
    setTimeout(() => {
      this.setState({
        simulationloading: true
      })
    }, 1000);

    if (viewList.includes(VERIFICATION)) {
      this.startVerification()
    } else if (viewList.includes(EXPERIMENTS)) {
      this.startExperiment()
    } else if (viewList.includes(MULTI_INTERFACE_SETUP)) {
      this.props._startMultiSetupSim()
    }
  }

  createPreLayoutInterface = (e) => {
    e && e.stopPropagation();
    const { create } = this.props;
    if (create) {
      return;
    }
    this.props.createPreLayoutInterface()
  }

  startExperiment() {
    const { forSim } = this.props;
    this.props.updateRunSweeping(forSim);
    this.props.updateExperimentInfo([], 'forSim');
  }

  updateMonitorMessage = (id) => {
    const { currentProjectVerifications, currentProjectId } = this.props;
    //open monitor box
    const index = currentProjectVerifications.findIndex(item => item.id === id);
    let name = null;
    if (index > -1) {
      name = currentProjectVerifications[index].name;
    }
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: id, projectId: currentProjectId });
    this.props._changeVerification(name);
    this.props._openTabFooter();
  }

  openSimulateOptionPanel = (e) => {
    if (this.props.verificationId) {
      this.setState({
        visible: {
          ...this.state.visible,
          simOptions: true,
        }
      })
    }
  }

  openExtractionOptionsPanel = (visible) => {
    if (this.props.verificationId) {
      this.setState({
        visible: {
          ...this.state.visible,
          extractionOptions: visible ? EXTRACTION_OPTIONS : null
        }
      })
    }
  }

  openExtractionPortPanel = (visible) => {
    if (this.props.verificationId) {
      this.setState({
        visible: {
          ...this.state.visible,
          extractionOptions: visible ? EXTRACTION_PORTS : null
        }
      })
    }
  }

  closeSimulationOptionsPanel = () => {
    this.props.updateOptionsByClose();
    if (this.props.verificationId) {
      this.setState({
        visible: {
          ...this.state.visible,
          simOptions: false,
        }
      })
    }
  }

  closeSettingPCB = (updateStatus, prevInfo) => {
    const { viewList } = this.props;
    const view = viewList.find(v => [VERIFICATION].includes(v));
    const _view = viewList.find(v => [VERIFICATION, MULTI_INTERFACE_SETUP].includes(v))
    if (view && updateStatus) {
      this.props.updateVerificationSetting(prevInfo)
    } else if (_view) {
      this.props._updateSetupAfterCloseSettings(_view);
    }
    this.setState({
      visible: {
        ...this.state.visible,
        componentSetting: false,
      }
    })
  }

  updateSettingPCB = (boolean) => {
    this.setState({
      visible: {
        ...this.state.visible,
        componentSetting: boolean,
      }
    })
  }

  updateComponentSettingPanel = (boolean) => {
    this.setState({
      visible: {
        ...this.state.visible,
        componentSetting: boolean,
      }
    })
  }

  openPreLayoutSetting = (boolean) => {
    this.setState({
      visible: {
        ...this.state.visible,
        prelayoutSetting: boolean,
      }
    })
  }

  updateMeasurementVisible = (bool) => {
    this.setState({
      visible: {
        ...this.state.visible,
        [MEASUREMENT]: bool
      }
    })
  }

  updateConnectionPanel = (bool) => {
    this.setState({
      visible: {
        ...this.state.visible,
        [CONNECTION]: bool,
      }
    })
  }

  getTopMenuList = () => {
    const { verificationId, simulateKeys, refNetsLoading, viewList, forSim, currentDesignId, designStatus, pcbLoading, Interfaces, create, selectedInterfaces, currentProjectId } = this.props;
    const isSweep = viewList.includes(EXPERIMENTS)
    const isInterface = viewList.includes(VERIFICATION);
    const isPreLayout = viewList.includes(PCB_PRE_LAYOUT);
    const isMultiSetup = viewList.includes(MULTI_INTERFACE_SETUP);
    const simulateEnabled = isMultiSetup
      ? (selectedInterfaces && selectedInterfaces.length ? true : false)
      : ((verificationId && !viewList.includes(EXPERIMENTS) && (!refNetsLoading || !refNetsLoading.loading)) || (simulateKeys && simulateKeys.length > 0) || (forSim && forSim.length)) ? true : false;

    const designs = designConstructor.getAvailableDesigns(currentProjectId);
    let list = [{ title: DEBUG, enabled: true, iconClassName: "iconfont icon-Debug", isSweep }];
    const report = { title: REPORT, key: REPORT, enabled: true };
    const connection = designs.length > 1 ? { title: "PCB Connections", key: CONNECTION, enabled: true, onClick: this.updateConnectionPanel, iconClassName: "iconfont icon-connection-box" } : null;
    const simulationRun = { title: SIMULATION, enabled: simulateEnabled, onClick: this.startSimulation, iconType: "play-circle" };
    const componentSetting = { title: COMPONENTS, enabled: !designStatus && currentDesignId ? true : false, onClick: this.updateComponentSettingPanel, iconClassName: "iconfont icon-icons8-circuit" };
    //sweep
    if (isSweep) {
      const sweepList = [
        simulationRun,
        connection,
        report,
        {
          title: 'Result',
          key: TOP_BAR_RESULT,
          className: "sierra-top-bar-result",
          icon: {
            type: "right",
            className: "sierra-top-bar-result-icon"
          },
          onClick: this.openResult
        }].filter(item => !!item)
      return [...sweepList, ...list]
    }

    if (isInterface) {
      const extractionPortEnable = Interfaces.find(item => item.content.channel && item.content.channel.type && item.content.channel.type !== "Default" && item.content.powerNets.length > 0) ? true : false;
      let enableExtractionOptions = [];
      const postLayoutInterfaces = Interfaces.filter(item => {
        const design = designConstructor.getDesign(item.pcbId)
        if (design && design.vendor === PRE_LAYOUT) {
          const prelayoutInfo = preLayoutData.getLocalPrelayout(item.pcbId);
          if (prelayoutInfo && prelayoutInfo.content && prelayoutInfo.content.prelayout === SCHEMATIC) {
            enableExtractionOptions.push(false)
          } else {
            enableExtractionOptions.push(true)
          }
          return false
        }
        enableExtractionOptions.push(true)
        return true
      })
      const interfaceList = [
        simulationRun,
        { title: OPTIONS, enabled: verificationId ? true : false, onClick: this.openSimulateOptionPanel, iconType: "setting" },
        enableExtractionOptions.includes(true) ? { title: EXTRACTION_OPTIONS, enabled: !pcbLoading && Interfaces && Interfaces.length, onClick: this.openExtractionOptionsPanel, iconType: "setting" } : null,
        postLayoutInterfaces.length ? { title: EXTRACTION_PORTS, enabled: !pcbLoading && extractionPortEnable, onClick: this.openExtractionPortPanel, iconType: "setting" } : null,
        componentSetting,
        { title: MEASUREMENT, enabled: verificationId ? true : false, onClick: () => this.updateMeasurementVisible(true), iconType: "setting" },
        connection,
        report,
        {
          title: 'Result',
          key: TOP_BAR_RESULT,
          className: "sierra-top-bar-result",
          icon: {
            type: "right",
            className: "sierra-top-bar-result-icon"
          },
          onClick: this.openResult
        },].filter(item => !!item)

      return [...interfaceList, ...list]
    }

    if (isPreLayout) {
      const prelayoutList = [{ title: CREATE, enabled: true, onClick: this.createPreLayoutInterface, iconType: create ? "loading" : "play-circle" },
      { title: SETTING, iconType: "setting", onClick: () => this.openPreLayoutSetting(true), enabled: true }]
      return [...prelayoutList, ...list]
    }

    if (isMultiSetup) {
      const multiSetupList = [
        simulationRun,
        componentSetting,
        connection,
        report
      ].filter(item => !!item)
      return [...multiSetupList, ...list]
    }

    const compList = [
      componentSetting,
      connection,
      report
    ].filter(item => !!item);
    return [...compList, ...list];
  }

  openReport = (visible) => {
    this.setState({
      visible: { ...this.state.visible, [REPORT]: visible }
    })
  }

  getPostLayoutList = (list, postLayoutDesignList) => {
    const _list = list.filter(item => {
      const vendor = designConstructor.getDesignVendor(item.id);
      if (vendor === PRE_LAYOUT) {
        return false;
      }
      return true;
    });
    if (postLayoutDesignList) {
      const { currentDesignId } = this.props;
      const findDesign = _list.find(item => currentDesignId === item.id);
      if (findDesign) {
        return findDesign.id
      }
      const _findDesign = postLayoutDesignList.find(item => _list.find(it => it.id === item.id));
      if (_findDesign) {
        return _findDesign.id
      } else {
        return postLayoutDesignList.length ? postLayoutDesignList[0].id : null
      }
    }
    return _list;
  }

  openResult = (e) => {
    e && e.stopPropagation()
    const { viewList, verificationId, experimentId } = this.props;
    const view = viewList.find(item => [EXPERIMENTS, VERIFICATION].includes(item));
    if (!view) {
      return;
    }
    let id = verificationId, pageType = RESULT;
    if (view === EXPERIMENTS) {
      id = experimentId;
      pageType = EXPERIMENTS_RESULT;
    }
    this.props._openNewPage({ pageType, id })
  }

  topItem = (item) => {
    const { enabled, onClick, iconType, title, iconClassName } = item;
    if (item.key === TOP_BAR_RESULT) {
      return <div
        className={'sierra-top-bar-item sierra-top-bar-result-item'}
        key={item.title}
        title={title}>
        <span className='aurora-top-bar-vertical-line'></span>
        <div className='sierra-top-bar-result-title'
          title={title}
          onClick={(e) => onClick(e)}>
          <span className='sierra-top-bar-item-title'>{title}</span>
          <span className={item.icon.className}>&gt;</span>
        </div>
      </div>
    } else {
      const Icon = getIcon(iconType)
      return (
        <div
          className={enabled ? 'sierra-top-bar-item' : 'sierra-top-bar-item sierra-top-bar-item-disabled'}
          onClick={enabled ? (e) => onClick(e) : null}
          key={item.title}
          title={title}
        >
          {iconType ? <Icon /> : iconClassName ? <span className={iconClassName} /> : null}
          <span className='sierra-top-bar-item-title'>{title}</span>
        </div>
      );
    }
  }

  debugButtonItem = (item) => {
    const { isSweep, iconClassName } = item;
    return <div
      key={item.title}
      className='sierra-top-bar-item sierra-top-bar-right-item'
      title={item.title}
      onClick={(e) => this.openDebug(e)}
      style={isSweep ? { marginLeft: 0 } : {}}>
      <span className={iconClassName}></span>
      <span className='sierra-top-bar-item-title'>Debug</span>
    </div>
  }

  openDebug = (e) => {
    e && e.stopPropagation();
    this.setState({
      visible: { ...this.state.visible, [DEBUG]: true }
    })
  }

  closeDebug = () => {
    this.setState({
      visible: { ...this.state.visible, [DEBUG]: false }
    })
  }

  getReportItem = (itemData) => {
    const { visible } = this.state;
    const { reportProgress, reportVisible, currentProjectId } = this.props;
    const projectVerList = projectVerifications.getVerifications(currentProjectId);
    const disabledClassName = !projectVerList || !projectVerList.length ? "sierra-top-bar-item-disabled" : "";
    return reportVisible && !visible[REPORT] ? <Fragment key={itemData.key}>
      <div
        className={`sierra-top-bar-item sierra-top-bar-report-item ${disabledClassName}`}
        key={itemData.key}
        onClick={() => !disabledClassName ? this.openReport(true) : null}
        style={reportVisible ? { background: '#c7e0f4' } : {}}
        title={itemData.title}
      >
        <div className='sierra-top-bar-report'>
          <span className="iconfont icon-baogao sierra-top-bar-report-icon" title={itemData.title}></span>
          <span className="sierra-top-bar-item-title">{itemData.title}</span>
        </div>
        <div className='andes-v2-top-sus-report-progress' style={{ width: `${reportProgress}%` }} />
      </div>
      <span className='andes-v2-report-progress-span'>{reportProgress}%</span>
    </Fragment>
      :
      <div className={`sierra-top-bar-item ${disabledClassName}`}
        key={itemData.key}
        onClick={!disabledClassName ? () => this.openReport(true) : null}
        style={visible[itemData.key] ? { background: '#c7e0f4' } : {}}
        title={itemData.title}
      >
        <div className='sierra-top-bar-report'>
          <span className="iconfont icon-baogao sierra-top-bar-report-icon" title={itemData.title}></span>
          <span className="sierra-top-bar-item-title">{itemData.title}</span>
        </div>
      </div>
  }

  render() {
    const { width, currentProjectId, currentInterfaceDesignList,
      verificationId, Interfaces, preLayoutName, preLayoutSetting,
      libraryTreeInfo, designList, updateSettingStatus, currentProjectVerifications } = this.props;
    const { visible } = this.state;
    const topMenu = this.getTopMenuList();
    const postLayoutDesignList = this.getPostLayoutList(designList);
    const componentSettingPcbId = this.getPostLayoutList(currentInterfaceDesignList, postLayoutDesignList);
    return (
      <Fragment>
        <div id='sierra-top-bar' style={{ width: width ? width : '100%' }}>
          {topMenu.map(item => {
            switch (item.title) {
              case DEBUG: return this.debugButtonItem(item);
              case REPORT: return this.getReportItem(item);
              default: return this.topItem(item);
            }
          })}
        </div>
        {
          visible['simOptions'] && <SetupOptionPanel
            closePanel={this.closeSimulationOptionsPanel}
          />
        }
        {
          visible['componentSetting'] ? <ComponentsSetting
            pcbId={componentSettingPcbId}
            closeModal={() => this.updateSettingPCB(false)}
            projectId={currentProjectId}
            updateSetting={this.closeSettingPCB}
            settingStore={componentSetting}
            designList={postLayoutDesignList}
            doNotStuffStore={componentDoNotStuff}
            product={SIERRA}
            CompPinMap={CompPinMap}
            tableStore={compTableHelper}
            libraryTreeInfo={libraryTreeInfo}
            parseSpiceModelSelector={parseSpiceModelSelector}
            getLibraryFileContent={getLibraryFileContent}
            getLibraryList={this.getLibraryList}
            powerStore={powerStore}
            updateSettingStatus={updateSettingStatus}
            _updateCompSettingsStatus={this.props._updateCompSettingsStatus}
            ReSearchNets={NetsFilterPanel}
            searchPowerNetsByKeywords={searchPowerNetsByKeywords}
          /> : null
        }
        {
          visible['extractionOptions'] && <ExtractionSetup
            closeModal={() => this.openExtractionOptionsPanel(false)}
            designList={currentInterfaceDesignList}
            Interfaces={Interfaces}
            extractionType={visible['extractionOptions']}
          />
        }
        {
          visible['prelayoutSetting'] && <PreLayoutSettingPanel
            {...preLayoutSetting}
            name={preLayoutName}
            closeModal={() => this.openPreLayoutSetting(false)}
            savePreLayout={this.props.savePreLayoutSetting}
          />
        }
        {
          visible[MEASUREMENT] ?
            <MeasurementPanel
              verificationId={verificationId}
              Interfaces={Interfaces}
              currentProjectVerifications={currentProjectVerifications}
              closePanel={() => this.updateMeasurementVisible(false)}
            />
            : null
        }
        {
          visible[REPORT] ? <Report
            closePanel={() => this.openReport(false)}
          /> : null
        }
        {
          visible[DEBUG] ?
            <DebugDownload
              closeModal={this.closeDebug}
            /> : null
        }
        {
          visible[CONNECTION] ?
            <Connections
              projectId={currentProjectId}
              isTopMenu={true}
              closePanel={this.updateConnectionPanel}
            />
            : null
        }
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { SierraReducer: { simulationReducer: { simulateKeys },
    sierra: { expandedKeys, refNetsLoading, sierraInfo, pcbLoading, updateSettingStatus },
    project: { verificationId, selectedKeys, currentProjectId, currentProjectVerifications, layout, viewList, reportInfo, pcbLayout },
    prelayout: { id: preLayoutId, name: preLayoutName, firstOpen, create, ...data },
    multiInterfaceSetup: { setupData },
    library: { libraryTreeInfo },
    sweep: { experimentInfo } } } = state;
  let currentDesignId = '', currentInterfaceDesignList, Interfaces = [];
  if (sierraInfo && sierraInfo.Interfaces && sierraInfo.Interfaces.length) {
    currentDesignId = sierraInfo.Interfaces[0].pcbId;
    Interfaces = sierraInfo.Interfaces;
    currentInterfaceDesignList = sierraInfo.Interfaces.map(item => { return { name: item.pcb, id: item.pcbId } });
  }

  const designList = projectDesigns.getAvailableDesigns(currentProjectId)
  if (!currentDesignId) {
    currentInterfaceDesignList = designList.map(item => { return { name: item.name, id: item.id } })
    const postLayoutInterfaces = currentInterfaceDesignList.filter(item => {
      const design = designConstructor.getDesign(item.id)
      if (design && design.vendor === PRE_LAYOUT) {
        return false
      }
      return true
    })
    currentDesignId = postLayoutInterfaces.length ? postLayoutInterfaces[0].id : ''
  }
  const preLayoutSetting = new PreLayoutSetting(data)
  const { reportProgress, reportVisible } = reportInfo || {};
  return {
    simulateKeys,
    selectedKeys,
    verificationId,
    currentProjectId,
    currentProjectVerifications,
    layout,
    viewList,
    expandedKeys,
    refNetsLoading,
    forSim: experimentInfo && experimentInfo.forSim ? experimentInfo.forSim : [],
    currentDesignId,
    currentInterfaceDesignList,
    pcbLoading,
    Interfaces: Interfaces,
    preLayoutId,
    preLayoutName,
    preLayoutSetting,
    firstOpen,
    create,
    selectedInterfaces: setupData && setupData.selectedInterfaces ? setupData.selectedInterfaces : null,
    libraryTreeInfo,
    designList,
    updateSettingStatus,
    experimentId: experimentInfo && experimentInfo.id ? experimentInfo.id : null,
    reportProgress,
    reportVisible,
    pcbLayout
  }
}

const mapDispatch = (dispatch) => ({
  _openPage(view, info) {
    dispatch(openPage(view, info));
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }))
  },
  _changeVerification(name) {
    dispatch(changeVerification(name));
  },
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  startSierraVerification(verificationIds, currentVerificationId) {
    dispatch(startSierraVerification(verificationIds, currentVerificationId));
  },
  changeTreeSelected(selectedKeys) {
    dispatch(changeTreeSelected(selectedKeys))
  },
  changeUploadMes(msg) {
    dispatch(changeUploadMes(msg));
  },
  changeViewList(viewList) {
    dispatch(changeViewList(viewList));
  },
  saveCurrentVerification(verificationIds, currentVerificationId) {
    dispatch(saveCurrentVerification(verificationIds, currentVerificationId))
  },
  updateOptionsByClose() {
    dispatch(updateOptionsByClose())
  },
  updateRunSweeping(forSim) {
    dispatch(updateRunSweeping(forSim))
  },
  updateExperimentInfo(info, currentType) {
    dispatch(updateExperimentInfo(info, currentType))
  },
  updateVerificationSetting(prevInfo) {
    dispatch(updateVerificationSetting(prevInfo))
  },
  savePreLayoutSetting(name, params) {
    dispatch(savePreLayoutSetting(name, params))
  },
  createPreLayoutInterface() {
    dispatch(createPreLayoutInterface())
  },
  _startMultiSetupSim() {
    dispatch(startMultiSetupSim())
  },
  _updateSetupAfterCloseSettings(view) {
    dispatch(updateSetupAfterCloseSettings(view))
  },
  _updateCompSettingsStatus(status) {
    dispatch(updateCompSettingsStatus(status))
  },
  _openNewPage({ pageType, id }) {
    dispatch(openNewPage({ pageType, id }))
  }
});

export default connect(mapState, mapDispatch)(TopBar)