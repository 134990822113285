import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { VerticalLeftOutlined } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import {
  getInterfaceMonitor,
  cancelVerificationWorkflow,
  getExperimentLog,
  cancelExperiment
} from '../../store/simulation/action';
import { EXPERIMENTS } from '@/constants/treeConstants';
import {
  changeTabMenu,
  changeVerification
} from '../../../tabMonitor/action';
import ErrorCheck from '../../errorCheck';
import { getSierraErrorCheck } from '../../errorCheck/ErrorCheck';
import { displayPortsError } from '@/components/Monitor/SimulationMonitor/MonitorErrorCheck';
import { TitleRender } from '@/components/Monitor/TitleRender_v2';
import { getLibraryErrorList, getSierraMonitorTree } from '@/services/Sierra/helper/monitorHelper';
import { displayStackupError } from '../../../../components/Monitor/SimulationMonitor/MonitorErrorCheck';
import _ from "lodash";
import './style.css';
import { VERIFICATION_GROUP } from '../../../../constants/treeConstants';

class SimulationMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: null,
      topWidth: 666,
      lockScroll: true,
      libraryError: [],
      monitorTree: []
    }
    this.lockRef = createRef();
  }

  scrollToBottom = () => {
    if (this.lockRef) {
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidMount() {
    this.screenChange();
    const { tabVisible, interfaceLibraryError, currentVerificationId, projectName, interfaceList, isExperiment, sweepName, sweepVerificationName } = this.props;
    if (tabVisible && this.scrollRef) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
    let libraryError = getLibraryErrorList(interfaceLibraryError, currentVerificationId);
    let monitorTree = getSierraMonitorTree({
      projectName,
      sweepName,
      sweepVerificationName,
      childId: currentVerificationId,
      interfaceList,
      isExperiment,
      interfaceType: isExperiment ? '' : 'Interface',
      changeInterface: isExperiment ? this.changeExperiment : this.changeInterface
    });
    this.setState({
      libraryError,
      monitorTree
    })
  }

  componentDidUpdate(prevProps) {
    const { tabVisible, currentVerificationId, log, startMsg, interfaceLibraryError,
      projectName, interfaceList, isExperiment, sweepName, sweepVerificationName } = this.props;
    const { topWidth, lockScroll } = this.state;
    const { currentVerificationId: prevcurrentVerificationId, log: prevlog, startMsg: prevstartMsg } = prevProps;
    if (tabVisible && this.scrollRef && !lockScroll) {
      this.scrollToBottom();
    }
    if ((currentVerificationId && currentVerificationId !== prevcurrentVerificationId)
      || (interfaceLibraryError && prevProps.interfaceLibraryError && interfaceLibraryError.length !== prevProps.interfaceLibraryError.length)
      || (interfaceLibraryError && !prevProps.interfaceLibraryError)) {
      let libraryError = getLibraryErrorList(interfaceLibraryError, currentVerificationId);
      this.setState({
        libraryError
      })
    }

    if ((currentVerificationId && currentVerificationId !== prevcurrentVerificationId)
      || (interfaceList && interfaceList.length !== prevProps.interfaceList.length)
      || (projectName && projectName !== prevProps.projectName)) {
      let monitorTree = getSierraMonitorTree({
        projectName,
        sweepName,
        sweepVerificationName,
        childId: currentVerificationId,
        interfaceList,
        isExperiment,
        interfaceType: isExperiment ? '' : 'Interface',
        changeInterface: isExperiment ? this.changeExperiment : this.changeInterface
      });
      this.setState({
        monitorTree
      })
    }

    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
    if (!_.isEqual(log, prevlog) || (startMsg && startMsg !== prevstartMsg)) {
      this.hasLockScroll();
    }
  };

  cancelProgress = (e) => {
    e.stopPropagation();
    //cancel simulation
    const { currentVerificationId, isExperiment } = this.props;
    if (isExperiment) {
      this.props.cancelExperiment(currentVerificationId)
    } else {
      this.props.cancelVerificationWorkflow(currentVerificationId);
    }
  }

  changeInterface = (key) => {
    const { currentProjectId, interfaceList } = this.props;
    const _interface = interfaceList.find(item => item.id === key);
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: _interface.id, projectId: currentProjectId });
    this.props.changeVerification(_interface.name);
    //get verification monitor/log
    this.props.getInterfaceMonitor(_interface.id);
  }

  changeExperiment = (key) => {
    const { currentProjectId, interfaceList } = this.props;
    const _interface = interfaceList.find(item => item.id === key);
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: key, projectId: currentProjectId });
    this.props.changeVerification(_interface ? _interface.name : 'Base');
    this.props.getExperimentLog(key)
  }

  hasLockScroll = () => {
    if (this.lockRef) {
      const { scrollHeight, scrollTop, clientHeight } = this.lockRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5) { this.setState({ lockScroll: false }); }
      else { this.setState({ lockScroll: true }); }
    }
  }

  render() {
    const {
      currentVerificationId, monitor, progress, projectName, verificationName,
      log, sierraInfo, uploadDebugMes, queueIndex, preLog, experimentErrorCheck,
      updateNetsComponents, errorCheck, endMsg, startMsg, touchstoneMsgList,
      portGenerateErrors, portGenerateWarnings, sweepName, sweepVerificationName, isExperiment,
      stackupErrorCheck, defaultBufferSpice
    } = this.props;
    const { topWidth, lockScroll, libraryError, monitorTree } = this.state;
    const { scrollHeight, clientHeight } = this.lockRef;
    let error = null;
    if (errorCheck) {
      error = getSierraErrorCheck(sierraInfo, defaultBufferSpice);
    }

    let currentUpdateNetsComps = updateNetsComponents.find(item => item.verificationId === currentVerificationId);
    let update = false;
    if (verificationName && currentUpdateNetsComps && (currentUpdateNetsComps.deleteNets.length > 0 || currentUpdateNetsComps.deleteComponents.length > 0 || currentUpdateNetsComps.deleteParts.length > 0)) {
      update = true;
    }

    if (!error && (queueIndex >= 0 || startMsg || endMsg || progress > -1 || monitor.length > 0 || preLog)) {
      update = false;
    }

    let libraryErrorDisplay = true;
    if (!error && (queueIndex >= 0 || startMsg || endMsg || progress > -1 || monitor.length > 0 || preLog)) {
      libraryErrorDisplay = false;
    }

    let logView = false;
    if (!error && !update && !startMsg && !endMsg && !uploadDebugMes && verificationName && queueIndex < 0) {
      logView = true;
    }

    let selectedKey = isExperiment ? [projectName, sweepVerificationName, sweepName, currentVerificationId] : [projectName, currentVerificationId];
    return (
      <Fragment>
        <div className='sierra-simulation-title'>
          <Row className='sierra-simulation-holygrail' span={24}>
            {TitleRender({
              topWidth,
              progress,
              waitingIndex: queueIndex,
              monitorTree,
              selectedKey
            })}
          </Row>
          <div className='sierra-simulation-btn'>
            {(((progress > -1 || queueIndex >= 0) /*|| simStatus === VERIFY_RUNNING || simStatus === WAITING*/)) && <Fragment>
              <div className='sierra-cancel-button'>
                <Tooltip title='Cancel simulation' overlayClassName='icon-tooltip'>
                  <span className='iconfont icon-cancel9' onClick={(e) => this.cancelProgress(e)}></span>
                </Tooltip>
              </div>
            </Fragment>}
          </div>
        </div>
        <div className='my-sierra-monitor' onScroll={() => this.hasLockScroll()} ref={c => { this.lockRef = c; }}>
          {((portGenerateErrors && portGenerateErrors.length) || (portGenerateWarnings && portGenerateWarnings.length)) ? <div className='sierra-update-pcb-box'>
            {displayPortsError({ portGenerateErrors, portGenerateWarnings })}
          </div> : null}
          {update ? <div className='sierra-update-pcb-box'>
            Design has been updated.
            {currentUpdateNetsComps.deleteNets && currentUpdateNetsComps.deleteNets.length > 0 ? <span className='sierra-update-pcb-span'>None of the nets [ {currentUpdateNetsComps.deleteNets.join(', ')} ].</span> : null}
            {currentUpdateNetsComps.deleteComponents && currentUpdateNetsComps.deleteComponents.length > 0 ? <span className='sierra-update-pcb-span'>None of the components [ {currentUpdateNetsComps.deleteComponents.join(', ')} ].</span> : null}
            {currentUpdateNetsComps.deleteParts && currentUpdateNetsComps.deleteParts.length > 0 ? <span className='sierra-update-pcb-span'>None of the parts [ {currentUpdateNetsComps.deleteParts.join(', ')} ].</span> : null}
          </div> : null}
          {libraryError && libraryError.length > 0 && libraryErrorDisplay ?
            <div className='sierra-update-library-box'>
              <ErrorCheck
                errorCheck={{ error: libraryError }}
              />
            </div> : null}
          {touchstoneMsgList && touchstoneMsgList.length > 0 ?
            <Fragment>
              <div className='sierra-stackup-error-dialog font-bold'>Touchstone macro modeling:</div>
              {touchstoneMsgList.map((msg, index) =>
                <span key={index} className='macro-modeling-error-span'>{msg}</span>)}
            </Fragment> : null}
          {stackupErrorCheck && stackupErrorCheck.length > 0 ?
            <div className='sierra-stackup-error-check-main'>
              <span className="aurora-stackup-error-span">Stackup: </span>
              {stackupErrorCheck.map((it, i) => <Fragment key={i}>
                {displayStackupError(it.stackupError, it.designName)}
              </Fragment>)}
            </div>
            : null}
          <ErrorCheck
            errorCheck={error}
          />
          {experimentErrorCheck && <ErrorCheck errorCheck={experimentErrorCheck} />}
          {touchstoneMsgList || error ? null : <pre style={{ margin: 0, overflow: 'unset', padding: '0 20px', paddingTop: '14px' }}>
            {uploadDebugMes && currentVerificationId ? <span className='debug-mes-span'>{uploadDebugMes} </span> : null}
            {startMsg ? <span className='debug-mes-span'>{startMsg}</span> : null}
            {queueIndex === 0 ? <span className='debug-mes-span'>==&gt; The current task is on the top of the waiting queue and ready to run.</span> : null}
            {queueIndex === 1 ? <span className='debug-mes-span'>==&gt; The current task is in the waiting queue and there is one task ahead.</span> : null}
            {queueIndex > 1 ? <span className='debug-mes-span'>==&gt; The current task is in the waiting queue and there are {queueIndex} tasks ahead.</span> : null}
            {!error && queueIndex < 0 && ((monitor && monitor.length > 0) || preLog)
              ? this.logRender({ preLog, monitor })
              : (logView ? this.logRender({ preLog, monitor: log }) : null)}
            {(currentVerificationId && endMsg && !error) ? <span className='debug-mes-span'>{endMsg} </span> : null}
          </pre>}
          {!error && (progress > -1) ? <div ref={(el) => { this.scrollRef = el; }}></div> : null}
        </div>
        {lockScroll && scrollHeight !== clientHeight ? <Tooltip title="Back to bottom" overlayClassName='icon-tooltip'><VerticalLeftOutlined className="sierra-monitor-backbottom" onClick={this.scrollToBottom} /></Tooltip> : null}
      </Fragment>
    );
  }

  logRender = ({ preLog, monitor }) => {
    return <ul className='sierra-monitor-ul'>
      {preLog && <p className='sierra-monitor-pre-log'>{preLog}</p>}
      {monitor && monitor.length > 0 ? monitor.map((item, index) =>
        <li key={item.id || index} className='sierra-monitor-li'>
          <p className={item.colorClassName || ""}>{item.log}</p>
        </li>) : null}
    </ul>
  }
}

const mapState = (state) => {
  const { projectName, verificationName, currentVerificationId, tabVisible } = state.TabMonitorReducer;
  const {
    project: { currentProjectId, treeItems, verificationStatus, generatePortsErrors, viewList },
    simulationReducer,
    sierra: { sierraInfo = null, updateNetsComponents, interfaceLibraryError, TouchstoneStatusList },
    library: { defaultBufferSpice },
    sweep: { experimentInfo },
  } = state.SierraReducer;
  const { singleProgress, singleMonitor, startMsg, endMsg, log, uploadDebugMes, waitingQueue, sierraInfoErrorCheck, preProcessLog } = simulationReducer;
  let monitor = [], progress = -1, queueIndex = -1, waitingTime = "", preLog = '', sMsg = null, eMsg = null;
  const findMonitor = singleMonitor && singleMonitor[currentVerificationId] ? singleMonitor[currentVerificationId] : null;
  if (findMonitor && findMonitor.monitor) {
    monitor = findMonitor.monitor;
  }
  const progressIndex = singleProgress && singleProgress.length > 0 ? singleProgress.findIndex(item => verificationName && item.verificationId === currentVerificationId) : -1;
  if (progressIndex > -1) {
    progress = singleProgress[progressIndex].progress;
  }

  let sMsgIndex = startMsg && startMsg.length > 0 ? startMsg.findIndex(item => verificationName && item.verificationId === currentVerificationId) : -1;
  if (sMsgIndex > -1) {
    sMsg = startMsg[sMsgIndex].msg;
  }

  let eMsgIndex = endMsg && endMsg.length > 0 ? endMsg.findIndex(item => verificationName && item.verificationId === currentVerificationId) : -1;
  if (eMsgIndex > -1) {
    eMsg = endMsg[eMsgIndex].msg;
  }

  let waitIndex = waitingQueue && waitingQueue.length > 0 ? waitingQueue.findIndex(item => verificationName && item.verificationId === currentVerificationId) : -1;
  if (waitIndex > -1) {
    queueIndex = waitingQueue[waitIndex].queueIndex;
    waitingTime = waitingQueue[waitIndex].waitingTime;
  }

  const p = preProcessLog && preProcessLog.length > 0 ? preProcessLog.findIndex(item => verificationName && item.verificationId === currentVerificationId) : -1;

  if (p > -1) {
    preLog = preProcessLog[p].log;
  }
  let interfaceList = [], project = null;
  if (currentProjectId) {
    project = treeItems[1].children.find(item => item.id === currentProjectId);
  }
  if (project && project.children && project.children[1].children) {
    interfaceList = project.children[1].children.filter(item => item.name !== 'Add Interface').map(item => {
      return item.dataType === VERIFICATION_GROUP ? item.children : [item]
    }).flat(2);
  }

  let errorCheck = null, stackupErrorCheck = null;
  if (sierraInfoErrorCheck.length > 0) {
    const currentErrorCheck = sierraInfoErrorCheck.find(item => item.verificationId === currentVerificationId);
    if (currentErrorCheck) {
      errorCheck = currentErrorCheck.errorCheck;
      stackupErrorCheck = currentErrorCheck.stackupError
    }
  }

  let touchstoneMsgList = null;

  const touchstoneStatus = Array.isArray(TouchstoneStatusList) ? TouchstoneStatusList.find(item => item.verificationId === currentVerificationId) : null;
  if (touchstoneStatus) {
    touchstoneMsgList = touchstoneStatus.msgList;
  }

  let portGenerateErrors = [], portGenerateWarnings = [];
  if (generatePortsErrors && generatePortsErrors.id === currentVerificationId) {
    portGenerateErrors = generatePortsErrors.errors || [];
    portGenerateWarnings = generatePortsErrors.warnings || [];
  }
  let simStatus = verificationStatus;

  const isExperiment = viewList.includes(EXPERIMENTS) ? true : false;
  let experimentErrorCheck = null;
  if (isExperiment) {
    const { base, experiments } = experimentInfo;
    interfaceList = [base, ...experiments].filter(item => !!item);
    const current = interfaceList.find(experiment => experiment && experiment.id === currentVerificationId)
    simStatus = current ? current.status : '';
    if (sierraInfoErrorCheck.length > 0) {
      const currentErrorCheck = sierraInfoErrorCheck.find(item => item.verificationId === currentVerificationId);
      if (currentErrorCheck) {
        experimentErrorCheck = currentErrorCheck.errorCheck;
      }
    }
  }

  return {
    projectName,
    verificationName,
    currentVerificationId,
    currentProjectId,
    monitor,
    progress,
    startMsg: sMsg,
    endMsg: eMsg,
    tabVisible,
    log: log && log[currentVerificationId] ? log[currentVerificationId] : null,
    sierraInfo,
    uploadDebugMes,
    queueIndex,
    waitingTime,
    preLog,
    interfaceList,
    updateNetsComponents,
    simStatus,
    interfaceLibraryError,
    errorCheck,
    touchstoneMsgList,
    portGenerateErrors,
    portGenerateWarnings,
    isExperiment,
    sweepName: experimentInfo.sweepName,
    sweepVerificationName: experimentInfo.verificationName,
    experimentErrorCheck: experimentErrorCheck ? { error: experimentErrorCheck } : null,
    stackupErrorCheck,
    defaultBufferSpice
  }
}

const mapDispatch = (dispatch) => ({
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }))
  },
  changeVerification(name) {
    dispatch(changeVerification(name));
  },
  getInterfaceMonitor(verificationId) {
    dispatch(getInterfaceMonitor(verificationId));
  },
  cancelVerificationWorkflow(verificationId) {
    dispatch(cancelVerificationWorkflow(verificationId))
  },
  getExperimentLog(experimentId) {
    dispatch(getExperimentLog(experimentId))
  },
  cancelExperiment(experimentId) {
    dispatch(cancelExperiment(experimentId))
  }
})

export default connect(mapState, mapDispatch)(SimulationMonitor);