import { VERIFY_SUCCESS, VERIFY_FAILED, VERIFY_CANCEL, VERIFY_NEVER, WAITING, VERIFY_RUNNING, TEMP } from "../../constants/verificationStatus";
import { WAITING as WORKFLOWWAITING, RUNNING, SUCCEED, FAILED, CANCEL } from "../../constants/workflowStatus";

const CLEAN = "clean", GET = "get";

function checkResultStatus(prev, curr) {
  const READY = "ready", RUNNING = "running", NONE = "none";
  function getStatus(s) {
    const readyList = [VERIFY_SUCCESS, VERIFY_FAILED, VERIFY_CANCEL, VERIFY_NEVER, TEMP];
    const runningList = [WAITING, VERIFY_RUNNING];
    if (readyList.includes(s)) {
      return READY;
    } else if (runningList.includes(s)) {
      return RUNNING;
    } else {
      return null;
    }
  };
  switch (getStatus(prev)) {
    case READY:
      if (RUNNING === getStatus(curr)) {
        return CLEAN;
      };
      return NONE;
    case RUNNING:
      if (READY === getStatus(curr)) {
        return GET;
      };
      return NONE;
    default: return NONE;
  }
}

function transformWorkflowStatusToVerification(status) {
  switch (status) {
    case WORKFLOWWAITING:
      return WAITING;
    case RUNNING:
      return VERIFY_RUNNING;
    case SUCCEED:
      return VERIFY_SUCCESS;
    case FAILED:
      return VERIFY_FAILED;
    case CANCEL:
      return VERIFY_CANCEL;
    default:
      return VERIFY_FAILED;
  }
}

export {
  checkResultStatus,
  transformWorkflowStatusToVerification,
  CLEAN,
  GET
}