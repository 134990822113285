import { calcDistanceOfTwoPoints } from '../../calcPointsDistance';

//Coordinate array sorting
function getSortPoints(arr) {
  let len = arr.length;
  const { priorityFlag, flag } = getFlagByCoordinates(arr);
  for (let i = 0; i < len - 1; i++) {
    for (let j = 0; j < len - 1 - i; j++) {
      if (sortTwoPointRule(arr[j], arr[j + 1], priorityFlag, flag)) {
        let tmp = arr[j];
        arr[j] = arr[j + 1];
        arr[j + 1] = tmp;
      }
    }
  }
  return arr;
}

//Compare the size of the two coordinates
function sortTwoPointRule(p1, p2, priorityFlag, flag) {

  if (p1[priorityFlag] > p2[priorityFlag]) {
    return true;
  }
  else if (p1[priorityFlag] === p2[priorityFlag]) {
    return (p1[flag] > p2[flag]);
  }
  else {
    return false;
  }
}

//Coordinate position with the largest difference
function getFlagByCoordinates(arr) {
  let maxX = Math.max(...arr.map(item => item.x)),
    minX = Math.min(...arr.map(item => item.x)),
    maxY = Math.max(...arr.map(item => item.y)),
    minY = Math.min(...arr.map(item => item.y));

  if (Math.abs(maxX - minX) > Math.abs(maxY - minY)) {
    return { flag: "y", priorityFlag: "x" }
  } else {
    return { flag: "x", priorityFlag: "y" }

  }
}

function getPointByToBoundariesMinDistance({ start, end, boundariesData }) {
  let startBoundaryPoint = null, endBoundaryPoint = null,
    startDistance = Infinity, endDistance = Infinity;
  const _start = start || {}, _end = end || {};

  for (let item of boundariesData) {
    const startDis = calcDistanceOfTwoPoints(
      { mX: _start.x, mY: _start.y },
      { mX: item[0], mY: item[1] });
    const endDis = end ? calcDistanceOfTwoPoints(
      { mX: _end.x, mY: _end.y },
      { mX: item[0], mY: item[1] }) : Infinity;

    if (startDis < startDistance) {
      startDistance = startDis;
      startBoundaryPoint = { x: item[0], y: item[1] }
    }

    if (endDis < endDistance) {
      endDistance = endDis;
      endBoundaryPoint = { x: item[0], y: item[1] }
    }
  }

  return { startBoundaryPoint, endBoundaryPoint, startDistance, endDistance }
}

export {
  getSortPoints,
  getPointByToBoundariesMinDistance
}