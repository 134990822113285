import {
  OPEN_LICENSE_PAGE,
  UPLOAD_LICENSE,
  UPDATE_LICENSE_TABLE,
  UPDATE_LICENSE_FEATURES_TABLE,
  UPDATE_LICENSE_USAGE_TABLE,
  UPDATE_LICENSE_USAGE_HISTORY,
  USAGE_HISTORY_PAGE_CHANGE,
  USAGE_REFRESH,
  UPDATE_CURRENT_USAGE_TIME,
  UPDATE_USAGE_HISTORY_TIME,
  UPDATE_LICENSE_MODE,
  UPLOAD_LICENSE_MODE,
  CHECK_IN_CLICK
} from './actionType';

export const openLicensePage = (page) => ({
  type: OPEN_LICENSE_PAGE,
  page
})

export const uploadLicenseAction = (file) => ({
  type: UPLOAD_LICENSE,
  file
})

export const updateLicenseTable = (data) => ({
  type: UPDATE_LICENSE_TABLE,
  data
})

export const updateLicenseFeaturesTable = (data) => ({
  type: UPDATE_LICENSE_FEATURES_TABLE,
  data
})

export const updateLicenseUsage = (data) => ({
  type: UPDATE_LICENSE_USAGE_TABLE,
  data
})

export const updateLicenseUsageHistory = (data) => ({
  type: UPDATE_LICENSE_USAGE_HISTORY,
  data
})

export const usageHistoryPageChange = (pagination) => ({
  type: USAGE_HISTORY_PAGE_CHANGE,
  pagination
})

export const usageRefresh = (table) => ({
  type: USAGE_REFRESH,
  table
})

export const updateCurrentUsageTime = (time) => ({
  type: UPDATE_CURRENT_USAGE_TIME,
  time
})

export const updateUsageHistoryTime = (time) => ({
  type: UPDATE_USAGE_HISTORY_TIME,
  time
})

export const updateLicenseModeAction = (mode, url) => ({
  type: UPDATE_LICENSE_MODE,
  mode, url
})

export const uplodeLicenseModeAction = (mode, url) => ({
  type: UPLOAD_LICENSE_MODE,
  mode, url
})

export const checkInClick = (id) => ({
  type: CHECK_IN_CLICK,
  id
})