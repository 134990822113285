import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import DelConfirm from '@/components/DelConfirm';
import UploadFile from '@/components/UploadFile';
import JSZip from 'jszip';
import NP from 'number-precision';
import { repeatedNames, checkFileName } from '@/services/helper/nameHelper';
import { createLibraryList } from '@/services/PDN/library/index'
import { expandMenu } from '../store/pdn/action';
import { updateLibraryMenu } from '../store/project/action';
import { updateLibraryDataCheck } from '../store/simulation/action';
import './pdnPanel.css'

class LibraryUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      name: null,
      errorFileList: [],
      errorMsg: '',
      repeatedName: [],
      doubleName: '',
      checking: false,
      uploading: false,
      compressProgress: -1,
      uploadProgress: -1,
      compressMsg: ''
    }
    this.decapRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  onUploadFiles = (fileList, errorFileList) => {
    fileList.forEach(file => {
      file.file.fileName = file.fileName;
    });
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    });
  }

  changeFileName = (name, prevName) => {
    const { fileList } = this.state;
    const { modelType } = this.props;
    let _fileList = [...fileList];
    const findIndex = _fileList.findIndex(item => item.name === prevName);
    const fileSuffix = _fileList[findIndex].fileSuffix;
    _fileList[findIndex].name = name;
    _fileList[findIndex].fileName = `${name}${fileSuffix}`;
    _fileList[findIndex].file.fileName = `${name}${fileSuffix}`;
    this.setState({
      fileList: [..._fileList]
    });
    const names = _fileList.map(item => item.name);
    return this.checkFileNames(modelType, names);
  }

  checkFileNames = (type, names) => {
    const { SPIMNames, DecapNames } = this.props;
    let existList = [];
    switch (type) {
      case 'SPIM': existList = [...SPIMNames]; break;
      case 'SPICE': existList = [...DecapNames]; break;
      default: break;
    }
    let reName = [], nameSet = true;
    names.forEach(item => {
      if (!item) {
        this.setState({
          checking: false,
          errorMsg: 'Model name is not set.'
        });
        nameSet = false;
        return;
      }

      const index = existList.findIndex(i => i.name === item);
      //Whether the file has been uploaded
      if (typeof (index) !== 'boolean' && index > -1) {
        reName.push(item);
        this.setState({
          checking: false,
        });
      }
    });

    if (!nameSet) {
      return;
    }
    if (reName.length > 0) {
      this.setState({
        errorMsg: 'Model name already exists!',
        repeatedName: [...reName]
      });
      return;
    }
    // Whether the file name is duplicated.
    const rName = repeatedNames(names);

    if (rName) {
      this.setState({
        doubleName: rName,
        checking: false,
        errorMsg: 'Model name cannot be repeated.'
      });
      return;
    }
    //Whether the file name is legal.
    const errorName = checkFileName(names);

    if (errorName && errorName.length > 0) {
      this.setState({
        checking: false,
        errorName: [...errorName],
        errorMsg: 'File names may only contain the following characters: numbers, letters, underscores, minus.'
      });
      return;
    }
    this.setState({
      errorMsg: '',
      repeatedName: [],
      errorName: [],
      doubleName: ''
    });
    return true;
  }

  changeName = (e) => {
    if (e.target.value) {
      this.setState({
        name: e.target.value
      })
    } else {
      this.setState({
        name: null
      })
    }
  }

  delFile = (fileList, errorFileList) => {
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    })
    const { modelType } = this.props;
    const names = fileList.map(item => item.name);
    this.checkFileNames(modelType, names);
  }

  saveLibraryFile = () => {
    const { fileList, compressProgress, uploadProgress } = this.state;
    const { modelType } = this.props;
    if (compressProgress === -1 && uploadProgress === -1) {
      this.createLibrary(modelType, { fileList: [...fileList] });
    }
  }

  compressLibrary = ({ typeName, files, libraries, libraryFormat }) => {
    let formData = new FormData();
    if (libraryFormat === 'file' || libraryFormat === 'folder') {
      let zip = new JSZip();
      if (typeName === 'Decap' || typeName === 'decap_touchstone') {
        for (let file of files) {
          zip.file(file.fileName, file.file);
        }
      } else {
        //if the file is zip file,keep file name consistent.
        zip.file(files.file.name, files.file);
      }
      return zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, this.metadata).then(blob => {
        this.setState({
          compressProgress: -1,
          uploadProgress: 0,
          compressMsg: ''
        });
        formData.append('file', new File([blob], 'file.zip'));
        formData.append('libraries', JSON.stringify(libraries));
        formData.append('typeName', typeName);
        formData.append('libraryFormat', libraryFormat);
        this.uploladLibrary(formData);
      })
    } else if (libraryFormat === 'data') {
      this.setState({
        compressProgress: -1,
        uploadProgress: 0,
        compressMsg: ''
      });
      formData.append('libraries', JSON.stringify(libraries));
      formData.append('typeName', typeName);
      formData.append('libraryFormat', libraryFormat);
      this.uploladLibrary(formData);
    }
  }

  updateLibrarydata = () => {
    const { expandedKeys, modelType } = this.props;
    let keys = [...expandedKeys];
    this.props._UpdateLibraryMenu();
    if (!keys.includes(modelType)) {
      keys.push(modelType);
    }
    this.props._expandMenu([...keys]);

    this.setState({
      uploading: false,
      modelType: null,
      modelName: '',
      repeatedName: [],
      VectorData: {},
      compressProgress: -1,
      uploadProgress: 100,
      compressMsg: ''
    });
  }

  removeError = (div) => {
    ReactDOM.unmountComponentAtNode(div);
  }

  uploladLibrary = (formData) => {
    createLibraryList(formData, this.config).then(res => {
      this.updateLibrarydata()
      setTimeout(() => {
        this.props.closeLibraryPanel();
      }, 500);
    }, error => {
      this.updateLibrarydata()
      if (error.includes('Unrecognized SPIM')) {
        const div = document.createElement('div');
        ReactDOM.render(<DelConfirm
          deleteConfirm={() => this.removeError(div)}
          message={error}
          removeCancel={true}
          mask={false}
        />, div)
        this.props.closeLibraryPanel();
      } else {
        this.props.openShowPanel(error);
      }
    })
  }

  createLibrary = (type, info) => {
    this.setState({
      checking: true,
      errorMsg: '',
    });
    let fileList = [...info.fileList];
    if (!fileList.length) {
      this.props.closeLibraryPanel();
      return;
    }
    const names = fileList.map(item => item.name);
    const status = this.checkFileNames(type, names);
    if (status) {
      this.setState({
        checking: false,
        uploading: true,
        compressProgress: 0,
      })
      let typeName = null, files = null, libraryFormat = null, libraries = null;
      if (type === 'SPIM') {
        typeName = 'Intel_SPIM';
        const file = [...fileList][0];
        libraries = [{
          id: '',
          name: file.name,
          fileName: file.name,
          libraryData: {}
        }];

        files = file.file;
        libraryFormat = 'file';
      } else if (type === 'SPICE' || type === 'Touchstone') {
        if (this.props.importText === 'Netlist') {
          typeName = 'Decap';
        } else if (this.props.importText === 'Touchstone') {
          typeName = 'decap_touchstone';
        }
        libraries = [];
        files = [];
        libraryFormat = 'file';
        fileList.forEach(file => {
          libraries.push({
            id: '',
            name: (this.props.importText !== 'Touchstone' || file.fileSuffix === ".zip") ? file.name : file.fileName,
            fileName: file.fileSuffix === ".zip" ? file.name : file.fileName,
            libraryData: {}
          });
          files.push(file.file);
          if (file.fileSuffix === ".zip") {
            libraryFormat = 'folder'
          }
        });
      }
      this.compressLibrary({
        typeName: typeName,
        files: files,
        libraries: libraries,
        libraryFormat: libraryFormat
      });
    };
  }

  metadata = (metadata) => {
    let msg = "Progress " + metadata.percent.toFixed(2) + " %";
    if (metadata.currentFile) {
      msg += ", current file " + metadata.currentFile;
    };
    const progress = NP.strip(parseFloat(metadata.percent.toFixed(2)));
    setTimeout(() => {
      this.setState({
        compressProgress: progress,
        compressMsg: msg
      });
    }, 50);
  }

  getUploadingStatus = () => { return this.state.uploading };

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setTimeout(() => {
        this.setState({
          compressProgress: 100,
          uploadProgress: percentCompleted,
        });
      }, 50);
    }
  }

  render() {
    const { uploadTitle, accept, importText, allowMulti, special, extraAccept } = this.props;
    const { uploading, errorMsg, repeatedName, doubleName, checking,
      compressProgress, uploadProgress, compressMsg, errorName } = this.state;
    let _multi = true;
    if (allowMulti !== undefined) {
      _multi = allowMulti;
    };
    return (
      <UploadFile
        text={uploadTitle}
        importText={importText}
        onUploadFile={this.onUploadFiles}
        delFile={this.delFile}
        saveFile={this.saveLibraryFile}
        changeFileName={this.changeFileName}
        repeatedName={repeatedName}
        doubleName={doubleName}
        errorName={errorName}
        errorMsg={errorMsg}
        accept={accept}
        extraAccept={extraAccept}
        nameStatus={true}
        allowMulti={_multi}
        compressProgress={compressProgress}
        uploadProgress={uploadProgress}
        compressMsg={compressMsg}
        uploading={uploading}
        checking={checking}
        special={special}
      />)
  }
}

const mapState = (state) => {
  const { PDNReducer } = state;
  const { project, pdn } = PDNReducer;
  const { SPIMNames = [], DecapNames = [] } = project;
  return {
    SPIMNames,
    DecapNames,
    expandedKeys: pdn.expandedKeys,
  }
}

const mapDispatch = (dispatch) => ({
  _UpdateLibraryMenu() {
    dispatch(updateLibraryMenu());
  },
  _expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  _updateLibraryDataCheck({ dataType }) {
    dispatch(updateLibraryDataCheck({ dataType }))
  }
});

export default connect(mapState, mapDispatch)(LibraryUpload);