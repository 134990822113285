import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ImpedanceComp, { PreLayoutImpedanceComp } from '../components/Impedance';
import { getImpedance, getInterfacesSignals, getImpedanceSignal, getSchematicImpedance, getPreLayoutInterfaceSignalNets, getPreImpedanceResultBySignals } from '@/services/Sierra/results';
import makeCancelable from '@/services/api/makeCancelable';
import { ResultData } from '@/services/Sierra';
import designConstructor from '../../../../services/helper/designConstructor';
import NP from 'number-precision';
import { SCHEMATIC } from '../../../../services/Sierra/prelayout/prelayoutConstants';
class ImpedanceResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      impedanceData: [],
      loading: true,
      designList: [],
      selectedKey: null,
      units: {
        stackupUnit: "",
        lengthUnit: "",
        widthUnit: ""
      },
      currentView: "VbN"
    }
  }

  // changeImpedanceView = (key) => {
  //   if (key === 'VbN') {
  //     this.setState({ currentView: 'VbN' });
  //   } else if (key === 'VbL') {
  //     this.setState({ currentView: 'VbL' });
  //   }
  //   else {
  //     this.setState({ currentView: 'Diff' });
  //   }
  // }

  componentDidMount() {
    const { verificationSubId } = this.props;
    if (verificationSubId) {
      this.getSelectedKey();
    }
  }

  componentDidUpdate(prevProps) {
    const { verificationSubId, verificationId } = this.props;
    if (prevProps.verificationSubId !== verificationSubId
      || prevProps.verificationId !== verificationId) {
      this.setState({
        loading: true,
        currentView: "VbN",
        selectedKey: "",
        designList: [],
        impedanceData: {}
      }, () => {
        this.getSelectedKey();
      })
    }
  }

  componentWillUnmount() {
    if (this.cancelable) {
      this.cancelable.cancel();
    }
  }

  getSelectedKey = async () => {
    const { verificationSubId, verificationId, currentProjectId } = this.props;

    const res = await ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId);
    let preLayoutInfoList = [];

    if (res) {
      let currentInterfaces = ResultData.getInterfaces();
      let designList = [], hasPreLayout = false;
      currentInterfaces && currentInterfaces.forEach(async item => {
        const isPreLayout = designConstructor.isPreLayout(item.PCBID);

        if (isPreLayout) {
          hasPreLayout = true;
        }

        designList.push({
          pcbSubId: item.pcbId,
          name: item.pcb,
          pcbId: item.PCBID,
          isPreLayout
        });
      });

      if (hasPreLayout) {
        try {
          preLayoutInfoList = await ResultData.getPreLayoutJsonByVerificationId(verificationId);
        } catch (error) {
          console.error(error);
        }

        designList = designList.map(item => {
          if (item.isPreLayout) {
            //designId and subId is design subId, id is curr design ID
            const findInfo = preLayoutInfoList.find(it => it.subId === item.pcbSubId || it.id === item.pcbId || it.designId === item.pcbSubId);
            item.isSchematic = findInfo && findInfo.content && findInfo.content.prelayout === SCHEMATIC;
          }
          return item;
        })
      }
      //schematic pre layout has impedance result(trace impedance)
      designList = designList.filter(item => !item.isPreLayout || item.isSchematic);
      let designs = designList.map(item => item.pcbSubId);

      const selectedKey = (designs && designs.length) ? 0 : null;
      if (!designs || !designs.length) {
        this.setState({
          selectedKey,
          designList,
          loading: false,
          impedanceData: {}
        })
        return;
      }
      this.setState({
        selectedKey,
        designList
      }, () => {
        this.getImpedanceData(selectedKey);
      })
    } else {
      this.setState({
        loading: false,
        impedanceData: {},
        currentView: "VbN",
        selectedKey: "",
        designList: []
      })
    }
  }

  getImpedanceData = async (selectedKey) => {
    const { currentProjectId, verificationId } = this.props;
    let currentInterfaces = ResultData.getInterfaces();
    if (!currentInterfaces || !currentInterfaces.length) {
      const { verificationSubId } = this.props;
      await ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId);
      currentInterfaces = ResultData.getInterfaces();
    }

    let interfaceName = ''
    if (currentInterfaces && currentInterfaces.length > 0) {
      interfaceName = currentInterfaces[0].name;
    }
    const { designList } = this.state;
    if (designList[selectedKey] && designList[selectedKey].isSchematic) {
      this.setState({
        currentView: "preLayout"
      }, () => {
        this.getPreLayoutImpedance({ interfaceName, currentInterfaces, designList, selectedKey })
      })
    } else {
      this.setState({
        currentView: "VbN"
      }, () => {
        this.getPostLayoutImpedance({ interfaceName, currentInterfaces, designList, selectedKey })
      })
    }
  }

  getPostLayoutImpedance = ({ interfaceName, currentInterfaces, designList, selectedKey }) => {
    const { currentProjectId, verificationId } = this.props;
    const promise = getImpedance({
      verificationId,
      verificationName: interfaceName,
      design: designList[selectedKey],
      Interfaces: currentInterfaces,
      projectId: currentProjectId
    });
    const signals = getInterfacesSignals(currentInterfaces);
    this.cancelable = makeCancelable(promise);
    this.cancelable.promise.then(response => {
      let impedance = getImpedanceSignal(response, signals);
      let { units } = this.state;
      let _units = units;
      _units = { stackupUnit: impedance.stackupUnit, lengthUnit: impedance.unit, widthUnit: impedance.unit };
      this.setState({
        loading: false,
        impedanceData: impedance,
        currentView: 'VbN',
        units: _units
      })
    }, error => {
      if (!error.isCancel) {
        this.setState({
          loading: false,
          impedanceData: {},
        })
      }
      console.error(error);
    });
  }

  getPreLayoutImpedance = ({ interfaceName, currentInterfaces, designList, selectedKey }) => {
    const { currentProjectId, verificationId } = this.props;

    const promise = getSchematicImpedance({
      verificationName: interfaceName,
      verificationId,
      design: designList[selectedKey],
      Interfaces: currentInterfaces,
      projectId: currentProjectId
    });
    this.cancelable = makeCancelable(promise);
    this.cancelable.promise.then(response => {
      const signalNets = getPreLayoutInterfaceSignalNets({ Interfaces: currentInterfaces, verificationName: interfaceName, name: designList[selectedKey] ? designList[selectedKey].name : "" });
      const { impedanceData = [], unit = "mil" } = response || {};
      const _impedanceData = getPreImpedanceResultBySignals(impedanceData, signalNets);
      this.setState({
        loading: false,
        impedanceData: { preLayoutImpData: _impedanceData },
        currentView: 'preLayout',
        units: { length: unit, width: unit, thickness: unit }
      })
    }, error => {
      if (!error.isCancel) {
        this.setState({
          loading: false,
          impedanceData: {},
        })
      }
      console.error(error);
    });
  }

  handleChange = (key) => {
    this.setState({
      selectedKey: key,
      loading: true
    }, () => {
      this.getImpedanceData(key, true);
    })
  }

  getScale = (changedUnit, prevUnit) => {
    const scaleObj = {
      mil: {
        mm: 0.0254,
        um: 25.4
      },
      mm: {
        mil: 1 / 0.0254,
        um: 1e3
      },
      um: {
        mil: 1 / 25.4,
        mm: 1 / 1e3
      }
    };
    if (scaleObj[prevUnit] && scaleObj[prevUnit][changedUnit]) {
      return scaleObj[prevUnit][changedUnit];
    }
    return 1;
  }

  updateDataByUnit = ({ _impedanceData, type, unit, prevUnit }) => {
    let stackupUnit = unit, lengthUnit = unit, widthUnit = unit,
      prevStackupUnit = prevUnit, prevLengthUnit = prevUnit, prevWidthUnit = prevUnit;

    const stackupScale = this.getScale(stackupUnit, prevStackupUnit);
    const stackupDecimals = stackupUnit === 'um' ? 1 : 4;
    const lengthScale = this.getScale(lengthUnit, prevLengthUnit);
    const decimals = lengthUnit === 'um' ? 1 : 4;
    const widthScale = this.getScale(widthUnit, prevWidthUnit);
    const widthDecimals = widthUnit === 'um' ? 1 : 4;
    _impedanceData.layerStats.forEach(item => {
      if (item.thickness && type === "stackupUnit") {
        const newThickness = NP.times(stackupScale, parseFloat(item.thickness));
        item.thickness = parseFloat(newThickness.toFixed(stackupDecimals));
      }

      if (item.w && type === "widthUnit") {
        const newWidth = NP.times(widthScale, parseFloat(item.w));
        item.w = parseFloat(newWidth.toFixed(widthDecimals));
      }

      if (item.length && type === "lengthUnit") {
        const newLength = NP.times(lengthScale, parseFloat(item.length));
        item.length = parseFloat(newLength.toFixed(decimals));
      }
    })

    _impedanceData.netStats.forEach(item => {
      if (item.length && type === "lengthUnit") {
        const newLength = NP.times(lengthScale, parseFloat(item.length));
        item.length = parseFloat(newLength.toFixed(decimals));
        item.layerStats.forEach(it => {
          const _newLength = NP.times(lengthScale, parseFloat(it.length));
          it.length = parseFloat(_newLength.toFixed(decimals));
        })
      }
    })
    return _impedanceData;
  }

  updatePreDataByUnit = ({ _impedanceData, type, unit, prevUnit }) => {
    let lengthUnit = unit, widthUnit = unit, thicknessUnit = unit,
      prevLengthUnit = prevUnit, prevWidthUnit = prevUnit, prevThicknessUnit = prevUnit;

    const thicknessScale = this.getScale(thicknessUnit, prevThicknessUnit);
    const thicknessDecimals = thicknessUnit === 'um' ? 1 : 4;
    const lengthScale = this.getScale(lengthUnit, prevLengthUnit);
    const decimals = lengthUnit === 'um' ? 1 : 4;
    const widthScale = this.getScale(widthUnit, prevWidthUnit);
    const widthDecimals = widthUnit === 'um' ? 1 : 4;
    _impedanceData.preLayoutImpData.forEach(item => {

      if (item.length && type === "length") {
        const newLength = NP.times(lengthScale, parseFloat(item.length));
        item.length = parseFloat(newLength.toFixed(decimals));
      }

      (item.traceList || []).forEach(child => {
        if (child.thickness && type === "thickness") {
          const newThickness = NP.times(thicknessScale, parseFloat(child.thickness));
          child.thickness = parseFloat(newThickness.toFixed(thicknessDecimals));
        }

        if (child.width && type === "width") {
          const newWidth = NP.times(widthScale, parseFloat(child.width));
          child.width = parseFloat(newWidth.toFixed(widthDecimals));
        }

        if (child.length && type === "length") {
          const newLength = NP.times(lengthScale, parseFloat(child.length));
          child.length = parseFloat(newLength.toFixed(decimals));
        }
      })
    })
    return _impedanceData;
  }

  settingUnitChange = (key, type, isPre) => {
    const { units, impedanceData } = this.state;
    let _impedanceData = { ...impedanceData };
    const prevUnit = units[type];
    _impedanceData = isPre ? this.updatePreDataByUnit({
      _impedanceData,
      type,
      unit: key,
      prevUnit
    }) : this.updateDataByUnit({
      _impedanceData,
      type,
      unit: key,
      prevUnit
    })
    this.setState({
      units: { ...units, [type]: key },
      impedanceData: _impedanceData
    })
  }

  render() {
    const { designList, selectedKey, loading, impedanceData, units, currentView } = this.state;
    return <Fragment>
      <Spin spinning={loading} size='large'>
        {currentView === "preLayout" ? PreLayoutImpedanceComp({
          key: 'Impedance',
          designList,
          selectedKey,
          impedanceData,
          handleChange: this.handleChange,
          units,
          settingUnitChange: (key, type) => this.settingUnitChange(key, type, true),
        }) : ImpedanceComp({
          key: 'Impedance',
          designList,
          selectedKey,
          impedanceData,
          handleChange: this.handleChange,
          units,
          settingUnitChange: this.settingUnitChange,
          // changeImpedanceView: this.changeImpedanceView
        })}
      </Spin>
    </Fragment>
  }
}

const mapState = (state) => {
  const { resultReducer, project, sierra } = state.SierraReducer;
  const { currentResultKey, resultInfo } = resultReducer;
  const { sierraInfo } = sierra;
  const { currentProjectId } = project;
  const { verificationSubId, verificationId } = resultInfo;
  return {
    currentResultKey,
    verificationId,
    sierraInfo,
    currentProjectId,
    verificationSubId
  }
}

export default connect(mapState)(ImpedanceResult);