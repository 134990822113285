import {
  CHANGE_CCC_MENU,
  UPDATE_CCC_QUEUE_MSG,
  UPDATE_CCC_RUNNING_QUEUE,
  UPDATE_BACKEND_LOG,
  UPDATE_DAEMON_LIST,
  UPDATE_DAEMON_DATA,
  CCC_EXPAND_MENU,
  CLEAR_CCC_STATUS,
  BROKER_LOG_CHANGE,
  UPDATE_SERVER_LIST,
  UPDATE_TASK_TABLE_LIST,
  SYSTEM_LOG_DOWNLOAD_UPDATE,
  UPDATE_SELECT_TASK_MESSAGE
} from './actionTypes';
import { USER_LOGOUT } from '../../Login/actionTypes';

const defaultState = {
  cccMenu: [],
  queueIndex: 0,
  queueLog: null,
  backendLog: null,
  serverList: [],
  taskList: [],
  daemonList: [],
  daemonServer: '',
  worker: '',
  level: 'info',
  expandedKeys: ['CCC_log'],
  taskServiceData: [],
  taskServiceDataTime: null,
  brokerLogType: 'info',
  serverListSimple: [],
  selectTaskMessage: ""
}

export const CCCReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_CCC_MENU:
      return {
        ...state,
        cccMenu: action.cccMenu
      }
    case UPDATE_CCC_QUEUE_MSG:
      return {
        ...state,
        queueIndex: action.index,
        queueLog: action.log
      }
    case UPDATE_CCC_RUNNING_QUEUE:
      return {
        ...state,
        serverList: [...action.serverList],
        taskList: [...action.taskList]
      }
    case UPDATE_BACKEND_LOG:
      let log = { ...state.backendLog };
      log[action.status] = action.log;
      return {
        ...state,
        backendLog: log
      }
    case UPDATE_DAEMON_LIST:
    case UPDATE_TASK_TABLE_LIST:
      return {
        ...state,
        daemonList: [...action.list],
        taskServiceData: [...action.taskServiceData],
        taskServiceDataTime: action.taskServiceDataTime
      }
    case UPDATE_DAEMON_DATA:
      return {
        ...state,
        daemonServer: action.daemonServer ? action.daemonServer : state.daemonServer,
        worker: action.worker,
        level: action.level ? action.level : state.level
      }
    case CCC_EXPAND_MENU:
      return {
        ...state,
        expandedKeys: action.expandedKeys
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CLEAR_CCC_STATUS:
      return {
        ...defaultState
      }
    case BROKER_LOG_CHANGE:
      return {
        ...state,
        brokerLogType: action.logType
      }
    case UPDATE_SERVER_LIST:
      return {
        ...state,
        serverListSimple: action.list
      }
    case SYSTEM_LOG_DOWNLOAD_UPDATE:
      return {
        ...state,
        systemLogDownload: {
          msg: action.msg,
          percent: action.percent
        }
      }
    case UPDATE_SELECT_TASK_MESSAGE:
      return {
        ...state,
        selectTaskMessage: action.message
      }
    default: return state;
  }
};