import { COMP_ERROR, PIN_ERROR, POINT_ERROR, NET_ERROR, POINT_SAME_ERROR, NO_NET_ERROR } from '../../../pages/Cascade/DCR/PathRContants'

function getCheckDataMessage({ errorType, data, index, compInfoList, pointName, type = "Error" }) {
  const { comp, pins } = data;
  switch (errorType) {
    case POINT_ERROR:
      return `[${type}] [${index}] Missing '${pointName}' definition.`;
    case COMP_ERROR:
      return `[${type}] [${index}] ${pointName ? `[${pointName}]` : ``} Component '${comp}' does not exist.`;
    case NET_ERROR:
      return `[${type}] [${index}] The pins '${compInfoList.join(', ')}' on '${comp}' are not on the same net.`
    case PIN_ERROR:
      return `[${type}] [${index}] ${pointName ? `[${pointName}]` : ``} Pin '${pins}' does not exist on component '${comp}'.`;
    case POINT_SAME_ERROR:
      if (pins.length > 1) {
        return `[${type}] [${index}] Path short-circuited. Pins '${pins.join(', ')}' are shared in Point1 and Point2.`
      }
      return `[${type}] [${index}] Path short-circuited. Pins '${pins[0] ? pins[0] : ""}' is shared in Point1 and Point2.`;
    case NO_NET_ERROR:
      return `[${type}] [${index}] '${pointName}': '${comp}' pins do not exist or pin net cannot be found in the layout.`;
    default:
      return;
  }
}

export {
  getCheckDataMessage
}