import React, { Component, Fragment } from 'react';
import { Input, Select } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { checkNameFormat } from '../../../../services/helper/nameFormatCheck';
import { GPIOList, PreLayoutSetting, componentList, prelayoutList } from '../../../../services/Sierra/prelayout';
import { numberCheck } from '../../../../services/helper/dataProcess';
import { GENERIC } from '../../../../services/PCBHelper/constants';
import { SCHEMATIC, schematicComponentList } from '../../../../services/Sierra/prelayout/prelayoutConstants';
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';

const Option = Select.Option;

class Setting extends Component {

  constructor(props) {
    super(props);
    const setting = new PreLayoutSetting(props)
    this.state = {
      name: props.name,
      ...setting,
      error: []
    };
    this.dialogRoot = document.getElementById('root');
  }

  close = (e) => {
    e.stopPropagation();
    const { error, name, ...setting } = this.state;
    if (Object.keys(error).length) {
      return;
    }

    const _setting = new PreLayoutSetting(setting);
    this.props.savePreLayout(name, _setting)

    this.props.closeModal();
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  selectChange = (type, key) => {
    const prelayout = type === "prelayout" ? key : this.state.prelayout;
    const GPIO = type === "GPIO" ? key : this.state.GPIO;

    let setting = new PreLayoutSetting({ prelayout, GPIO })
    this.setState({
      ...setting,
      [type]: key,
    })
  }


  changeValue = (e, type) => {
    const { error } = this.state;
    this.setState({
      [type]: e.target.value,
      error: error.filter(e => e.type !== type)
    });
  }

  inputCheck = (type) => {
    const value = this.state[type];
    if (type === "name") {
      const check = checkNameFormat(value);
      if (check) {
        this.setState({
          error: [
            ...this.state.error,
            { type, error: check }
          ]
        })
      }
      return;
    }

    let check = numberCheck(value);
    if (!check) {
      check = Number.isInteger(Number(value)) ? false : 'Value must be a integer!'
    }
    if (check) {
      this.setState({
        error: [
          ...this.state.error,
          { type, error: check }
        ]
      })
    }
  }

  nameRender = () => {
    const { name } = this.state;
    return <div className="pre-layout-pcie-select">
      <span className='out-title-color pre-layout-span'>Pre Layout Name</span>
      <Input
        className="aurora-input pre-layout-input"
        value={name}
        onChange={(e) => this.changeValue(e, "name")}
        onBlur={() => this.inputCheck("name")}
        onKeyDown={this.keyDown}
      />
    </div>
  }

  prelayoutRender = () => {
    const { prelayout } = this.state;
    return <div className="pre-layout-pcie-select">
      <span className='out-title-color pre-layout-span'>Pre Layout Type</span>
      <Select
        value={prelayout}
        onChange={(key) => this.selectChange("prelayout", key)}
        placeholder={"Pre Layout Prototype Type"}
        className='aurora-select pre-layout-select'
        popupClassName='aurora-select-dropdown'
        getPopupContainer={() => document.getElementById('root')}
      >
        {prelayoutList.map(item =>
          <Option key={item} >{item}</Option>
        )}
      </Select>
    </div>
  }

  typeRender = () => {
    const { GPIO, signalNumber } = this.state;
    return <Fragment>
      <div className="pre-layout-pcie-select">
        <span className='out-title-color pre-layout-span'>GPIO Type</span>
        <Select
          value={GPIO}
          onChange={(key) => this.selectChange("GPIO", key)}
          className='aurora-select pre-layout-select'
          popupClassName='aurora-select-dropdown'
          getPopupContainer={() => document.getElementById('root')}
        >
          {GPIOList.map(item =>
            <Option key={item} >{item}</Option>
          )}
        </Select>
      </div>
      {
        GPIO === GENERIC ? <div className='pre-layout-pcie-select'>
          <span className='out-title-color pre-layout-span'>Signal Number</span>
          <Input
            className="aurora-input pre-layout-input"
            value={signalNumber}
            onChange={(e) => this.changeValue(e, 'signalNumber')}
            onBlur={() => this.inputCheck('signalNumber')}
            onKeyDown={this.keyDown}
          />
        </div> : null
      }
    </Fragment>
  }

  componentsRender = () => {
    const { prelayout } = this.state;
    const _componentList = prelayout === SCHEMATIC ? schematicComponentList : componentList;
    return _componentList.map(comp => {
      const { title, key } = comp;
      return <div className='pre-layout-pcie-select' key={key}>
        <span className='out-title-color pre-layout-span'>{title}</span>
        <Input
          className="aurora-input pre-layout-input"
          value={this.state[key]}
          onChange={(e) => this.changeValue(e, key)}
          onBlur={() => this.inputCheck(key)}
          onKeyDown={this.keyDown}
        />
      </div>
    })
  }

  render() {
    const { error } = this.state;
    const content = (
      <Panel
        className='sierra-pre-layout-panel sierra-panel'
        title='Setting'
        zIndex={2000}
        width={600}
        minWidth={300}
        minHeight={200}
        position='panel-center'
        onCancel={this.close}
        overflow='hidden'
        draggable
      >
        <div className='pre-layout-setting'>
          {this.nameRender()}
          {this.prelayoutRender()}
          {this.typeRender()}
          {this.componentsRender()}
          {error && error[0] ? <span className='aurora-model-name-error-msg'>{error[0].error}</span> : null}
        </div>
      </Panel >)

    return createPortal(content, this.dialogRoot);
  }
}

export default Setting;