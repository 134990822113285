import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import LayoutLR from './layoutLR';
import LayoutTB from './layoutTB';
import LayoutContent from './layoutContent';
import { closeTabFooter, changeTabMenu } from '../../tabMonitor/action';
import MonitorChild from './Monitor';
import { getCCCVerifications, getCCCSpecifyServiceVerification } from '../../../services/CCC/cccHelper';
import { FASTPI } from '@/constants/pageType';
import ContentLayout from '@/components/ContentLayout';
import { RESULT, PCB } from '../constants';

class PDNContent extends PureComponent {
  monitorChild = (width) => {
    const { menuType, tabVisible, tabSelectKeys, currentProjectId, currentProjectPDNs, verificationId, projectName,
      verificationName } = this.props;
    return MonitorChild({
      menuType,
      tabVisible,
      tabSelectKeys,
      projectId: currentProjectId,
      getCCCVerifications: () => getCCCVerifications(currentProjectPDNs, FASTPI),
      getCurrentVerification: () => getCCCSpecifyServiceVerification(verificationId, currentProjectPDNs, FASTPI),
      width,
      titleSelectedKeys: [
        { type: 'Project', name: projectName },
        { type: "PDN", name: verificationName }
      ]
    })
  }


  getTopMenuVisible = () => {
    const { viewList, currentProjectId } = this.props;
    if (!currentProjectId) return false;

    if (viewList && viewList.length && viewList.every(v => [PCB, RESULT].includes(v))) {
      return false;
    }

    return true;
  }

  layoutLR = () => {
    return <LayoutLR
      {...this.props}
      MonitorChild={this.monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
    />;
  }

  layoutTB = () => {
    return <LayoutTB
      {...this.props}
      MonitorChild={this.monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
    />
  }

  layoutSingle = () => {
    return <LayoutContent
      {...this.props}
      MonitorChild={this.monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
    />
  }

  _changeMonitorTabMenu = ({ selectKeys }) => {
    const { verificationId, menuType, currentProjectId } = this.props;
    this.props._changeTabMenu({ selectKeys, menuType, verificationId, projectId: currentProjectId });
  }

  render() {
    const { layout, viewList } = this.props;
    return <ContentLayout
      {...this.props}
      pageType={FASTPI}
      layout={layout}
      viewList={viewList}
      layoutLR={this.layoutLR}
      layoutTB={this.layoutTB}
      layoutSingle={this.layoutSingle}
    />;
  }

}

const mapState = (state) => {
  const { PDNReducer } = state;
  const { project: { layout, currentProjectId, view, PDNID, currentProjectPDNs, verificationId, viewList } } = PDNReducer;
  const { tabVisible, tabSelectKeys, menuType, projectName, verificationName } = state.TabMonitorReducer;
  return {
    layout,
    view,
    PDNID,
    currentProjectId,
    verificationId,
    viewList,
    tabVisible,
    tabSelectKeys,
    menuType,
    currentProjectPDNs,
    projectName,
    verificationName
  };
}

const mapDispatch = (dispatch) => ({
  _closeTabFooter() {
    dispatch(closeTabFooter());
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }));
  },
})

export default connect(mapState, mapDispatch)(PDNContent);