import { CLK } from ".";
import hash from 'object-hash';
import { getDefaultIndex } from "../../helper/setDefaultName";
import { RLCSections, TemplateSections, CAPACITOR, INDUCTOR, RESISTOR, numSplit, SectionColumn, PreLayoutPin } from "../../PreLayout";
import { PCIE } from "../../PCBHelper/constants";
import libraryConstructor from "../library/libraryConstructor";
import { TRACE, VIA } from "../../../constants/libraryConstants";

/* set signals table columns by signal sections */
function getPreLayoutSignalColumns(maxSectionLength, type) {
  /*   const fixed = maxSectionLength > 1 ? 'left' : null; */
  const fixed = null;
  let signalsColumns = [];
  if (type === PCIE) {
    signalsColumns = [{
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      fixed: fixed,
      width: 58
    }]
  }

  signalsColumns = [
    ...signalsColumns,
    {
      title: 'SerDes Signal',
      dataIndex: 'name',
      key: 'name',
      fixed: fixed,
      width: 70
    }, {
      title: 'Signal Spacing',
      dataIndex: 'signal_spacing',
      key: 'signal_spacing',
      fixed: fixed,
      width: 70
    }, {
      title: 'Net+',
      dataIndex: 'nets_P',
      key: 'nets_P',
      fixed: fixed,
      width: 120
    }, {
      title: 'Net-',
      dataIndex: 'nets_N',
      key: 'nets_N',
      fixed: fixed,
      width: 120
    }];

  const allowDel = maxSectionLength === 1 ? false : true;
  for (let index = 0; index < maxSectionLength; index++) {
    signalsColumns.push(SectionColumn({ sectionIndex: index, allowDel, index: index + 1 }))
  }

  const rightColumns = [/* {
    title: 'Insertion Loss (dB at GHz)',
    dataIndex: 'insertionLoss',
    key: 'insertionLoss',
    fixed: fixed ? 'right' : null,
    width: 110
  } */];

  signalsColumns = [...signalsColumns, ...rightColumns];
  return signalsColumns;
}

function getSection(signal, traceList, viaList) {
  let sections = {};
  signal.sections.forEach((section, index) => {
    let error = null;
    if (section && section.template) {
      error = getSectionLibraryError(section, traceList, viaList);
    }
    sections[`section_${index}`] = { ...section, error };
  })
  return sections;
}

function getSectionLibraryError(section, traceList, viaList) {
  switch (section.type) {
    case TRACE:
      const findTrace = traceList.find(item => item.id === section.libId && item.type === section.libType && item.name === section.template);
      return !findTrace ? `Template ${section.template} is not exist.` : null;
    case VIA:
      const findVia = viaList.find(item => item.id === section.libId && item.type === section.libType && item.name === section.template);
      return !findVia ? `Template ${section.template} is not exist.` : null;
    case CAPACITOR:
    case INDUCTOR:
    case RESISTOR:
      return null;
    default:
      return `Template ${section.template} is not exist.`;
  }
}

/* get signal table data by signals 
 return => {
   name:signalName,
   group,
   index,
   nets_P:[],
   nets_N:[],
   section_${index}:{id,libId, ... }
  insertionLoss:"3"
 }
*/
function gerPreLayoutTableData(signal_groups, selectedGroups, foldList) {
  let dataList = [];
  const traceList = libraryConstructor.getLibraryValues(TRACE) || [],
    viaList = libraryConstructor.getLibraryValues(VIA) || [];
  selectedGroups.forEach(group => {
    const signal_group = signal_groups.find(item => item.name === group) || { signals: [] };
    const firstGroupSignalName = signal_group.signals[0] ? signal_group.signals[0].name : null;
    let signals = JSON.parse(JSON.stringify(signal_group.signals));
    if (foldList.includes(group)) {
      signals = [signals[0]];
    }
    signals.forEach(item => {
      //Get section
      const sectionData = getSection(item, traceList, viaList);
      //spit insertion loss value
      const { value } = numSplit(item.insertionLoss);
      let groupLength = 0;
      if (group === CLK || item.name === firstGroupSignalName) {
        groupLength = signals.length;
      }
      dataList.push({
        name: item.name,
        nets_P: [...item.nets_P],
        nets_N: [...item.nets_N],
        group,
        signal_spacing: signal_group.signal_spacing,
        index: item.index,
        ...sectionData,
        insertionLoss: value,
        groupLength,
        signalLength: signal_group.signals.length
      });
    })
  })
  return dataList;
}


function getPreLayoutContentVersion({ designVersion, prevInfo, preLayoutInfo }) {
  let _version = designVersion;
  const prevHash = hash(prevInfo);
  const newHash = hash(preLayoutInfo);
  if (prevHash !== newHash) {
    _version += _version;
  }
  return _version;
}

function addSectionsToSignals(prevKey, signal_groups) {
  let _signal_groups = JSON.parse(JSON.stringify(signal_groups));
  const numKey = parseInt(prevKey);

  for (let groupItem of _signal_groups) {
    groupItem.signals.forEach(item => {
      const prevSection = item.sections[numKey];
      const nextSection = item.sections[numKey + 1];
      if (prevSection && nextSection) {
        const sectionId = getDefaultIndex(numKey + 1, item.sections.map(it => it.id));
        item.sections[numKey].next = [sectionId];
        item.sections[numKey + 1].prev = sectionId;
        const newSection = new TemplateSections({ id: sectionId, prev: prevSection.id, next: [nextSection.id] });
        item.sections.splice(numKey + 1, 0, newSection);
      }
    });
  }

  return _signal_groups;
}

/**
 * @param key section index
 *  */
function deleteSectionsInSignals(sectionIndex, signal_groups, components) {
  const numSectionIndex = parseInt(sectionIndex);
  let _signal_groups = JSON.parse(JSON.stringify(signal_groups));
  let _components = JSON.parse(JSON.stringify(components));
  for (let groupItem of _signal_groups) {
    for (let item of groupItem.signals) {

      if (!item.sections[numSectionIndex]) {
        continue;
      }

      const sectionId = item.sections[numSectionIndex].id;
      const nextIndex = numSectionIndex + 1;
      const prevIndex = numSectionIndex - 1;

      if (item.sections[prevIndex]) {
        item.sections[prevIndex].next = item.sections[prevIndex].next.filter(it => it !== sectionId);
      }

      if (item.sections[nextIndex]) {
        item.sections[nextIndex].prev = item.sections[prevIndex] ? item.sections[prevIndex].id : "";
        item.sections[prevIndex] && item.sections[prevIndex].next.push(item.sections[nextIndex].id);
      }

      //Update component pin sectionId
      let compPinSectionId = "", sectionType = "";
      if (!item.sections[numSectionIndex].prev) {
        compPinSectionId = item.sections[nextIndex].id;
        sectionType = "U1";
      }
      if (!item.sections[numSectionIndex].next || !item.sections[numSectionIndex].next.length) {
        compPinSectionId = item.sections[prevIndex].id;
        sectionType = "U2";
      }

      for (let comp of _components) {
        if (sectionType && comp.name === sectionType) {
          comp.pins.forEach(pin => {
            if (pin.signal === item.name) {
              pin.sectionId = compPinSectionId;
            }
          })
        }
      }
      item.sections = item.sections.filter(it => it.id !== sectionId);
    }
  }

  return { _signal_groups, _components };
}

function updateComponentPinsBySignal({ deleteSignals, newSignals, components }) {

  if ((!deleteSignals || !deleteSignals.length) && (!newSignals || !newSignals.length)) {
    return components;
  }

  let _components = JSON.parse(JSON.stringify(components));
  const _deleteSignals = deleteSignals ? JSON.parse(JSON.stringify(deleteSignals)) : null;
  const _newSignals = newSignals ? JSON.parse(JSON.stringify(newSignals)) : null;

  //delete pins
  if (_deleteSignals && _deleteSignals.length > 0) {
    let delSignalNets = [];
    _deleteSignals.forEach(item => {
      delSignalNets = [...(item.nets_P || []), ...(item.nets_N || []), ...(item.nets_A || []), ...(item.nets_B || []), ...(item.nets_C || [])];
    });
    _components.forEach(comp => {
      comp.pins = comp.pins.filter(item => !delSignalNets.includes(item.pin));
    });
  }

  //Add new pins
  if (_newSignals && _newSignals.length > 0) {
    let newPins = [], allNewNets = [];
    _newSignals.forEach(item => {
      const nets = [...(item.nets_P || []), ...(item.nets_N || []), ...(item.nets_A || []), ...(item.nets_B || []), ...(item.nets_C || [])];
      allNewNets = [...allNewNets, ...nets];
      //find new pins
      newPins = [...newPins, ...nets.map(net => { return { pin: net, net, signal: item.name, sections: item.sections, group: item.group } })]
    });
    //filter not exist pins
    newPins = newPins.filter(item => item && item.pin && item.net && item.signal);

    _components.forEach(comp => {
      //filter same pins
      comp.pins = comp.pins.filter(item => !allNewNets.includes(item.net))
      for (let item of newPins) {
        let sectionId = "";
        if (comp.name === 'U1') {
          sectionId = item.sections[0].id;
        } else {
          sectionId = item.sections[item.sections.length - 1].id;
        }
        comp.pins.push(new PreLayoutPin({
          pin: item.pin,
          net: item.net,
          signal: item.signal,
          sectionId,
          signalGroup: item.group
        }))
      }
    })
  }

  return _components;
}

function updateCompPinsSectionId({ sectionId, _components, signal }) {
  //Update component u2 pin sectionId
  for (let comp of _components) {
    if (comp.name !== "U2") {
      continue;
    }
    comp.pins.forEach(pin => {
      if (pin.signal === signal) {
        pin.sectionId = sectionId;
      }
    })
  }
  return _components;
}

function updateAllSignalTemplate({
  _signal_groups,
  libType,
  type,
  libId,
  template,
  value,
  selectedGroups,
  sectionIndex,
  update,
  _components
}) {
  for (let groupItem of _signal_groups) {

    if (!selectedGroups.includes(groupItem.name) || !groupItem.signals) {
      continue;
    }

    for (let item of groupItem.signals) {
      //sectionIndex, prev section id, sectionId==="1" (sectionIndex===0) -> first section
      const prevSection = sectionIndex > 0 ? item.sections.find((sec, index) => index === (sectionIndex - 1)) : { id: "", template: true };

      if (!prevSection || (![CAPACITOR, RESISTOR, INDUCTOR].includes(prevSection.type) && !prevSection.template)) {
        continue;
      }
      update = true;
      const section = item.sections[sectionIndex] || {};
      let sectionId = getDefaultIndex(parseInt(sectionIndex) + 1, item.sections.map(it => it.id));
      let prev = prevSection.id, next = [];
      //update prev ,next,length
      if (Object.keys(section).length) {
        prev = section.prev;
        next = section.next;
        sectionId = section.id;
      } else {
        _components = updateCompPinsSectionId({ sectionId, _components, signal: item.name });
      }

      if (sectionIndex >= 1) {
        //add next id to prev section
        item.sections[sectionIndex - 1].next = [sectionId];
      }

      if ([CAPACITOR, RESISTOR, INDUCTOR].includes(type)) {
        item.sections[sectionIndex] = new RLCSections({
          id: sectionId,
          type,
          value,
          prev,
          next
        })
      } else {
        item.sections[sectionIndex] = new TemplateSections({
          id: sectionId,
          type: type,
          libId: libId,
          libType: libType,
          template: template,
          length: value,
          prev,
          next
        })
      }
    }
  }
  return { _signal_groups, update, _components };
}

function updateSignalTemplate({
  _signal_groups,
  update,
  libType,
  type,
  libId,
  template,
  sectionIndex,
  record,
  value,
  _components
}) {
  const group = record.group, signalName = record.name;
  //find group
  const groupIndex = _signal_groups.findIndex(item => item.name === group);
  if (groupIndex < 0) {
    return;
  }
  //find signal
  const signalIndex = _signal_groups[groupIndex].signals.findIndex(item => item.name === signalName);
  if (signalIndex < 0) {
    return;
  }

  const sections = _signal_groups[groupIndex].signals[signalIndex].sections;

  const prevSection = sectionIndex > 0 ? sections.find((sec, index) => index === (sectionIndex - 1)) : { id: "", template: true };
  if (!prevSection || (![CAPACITOR, RESISTOR, INDUCTOR].includes(prevSection.type) && !prevSection.template)) {
    return;
  }
  update = true;
  const section = _signal_groups[groupIndex].signals[signalIndex].sections[sectionIndex] || {};
  //get default section id
  let sectionId = getDefaultIndex(parseInt(sectionIndex) + 1, sections.map(it => it.id));
  let prev = prevSection.id, next = [];

  if (Object.keys(section).length) {
    prev = section.prev;
    next = section.next;
    sectionId = section.id;
  } else {
    _components = updateCompPinsSectionId({ sectionId, _components, signal: _signal_groups[groupIndex].signals[signalIndex].name });
  }

  if (sectionIndex >= 1) {
    _signal_groups[groupIndex].signals[signalIndex].sections[sectionIndex - 1].next = [sectionId];
  }

  if ([CAPACITOR, RESISTOR, INDUCTOR].includes(type)) {
    _signal_groups[groupIndex].signals[signalIndex].sections[sectionIndex] = new RLCSections({
      id: sectionId,
      type,
      value,
      prev,
      next
    })
  } else {
    _signal_groups[groupIndex].signals[signalIndex].sections[sectionIndex] = new TemplateSections({
      id: sectionId,
      type: type,
      libId: libId,
      libType: libType,
      template: template,
      length: value,
      prev,
      next
    })
  }
  return { _signal_groups, update, _components };
}

function getPreLayoutAllNets(signal_groups, net) {
  let nets = [];
  for (let group of signal_groups) {
    for (let signal of group.signals) {
      nets = [...nets, ...(signal.nets_P || []), ...(signal.nets_N || []), ...(signal.nets_A || []), ...(signal.nets_B || []), ...(signal.nets_C || [])];
    }
  }

  if (net) {
    nets = nets.filter(item => item !== net);
  }
  return nets;
}

export {
  getPreLayoutSignalColumns,
  gerPreLayoutTableData,
  getPreLayoutContentVersion,
  addSectionsToSignals,
  deleteSectionsInSignals,
  updateComponentPinsBySignal,
  updateAllSignalTemplate,
  updateSignalTemplate,
  getPreLayoutAllNets,
  getSectionLibraryError
}