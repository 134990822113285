import {
  UPDATE_LIBRARY_MENU,
  UPDATE_LIBRARY_STATUS
} from './actionTypes';

const defaultState = {
  libraryStatus: false
}

export const AndesLibraryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_LIBRARY_MENU:
    case UPDATE_LIBRARY_STATUS:
      return {
        ...state,
        libraryStatus: !state.libraryStatus
      }
    default: return state;
  }
};