import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PCB_CHANNEL } from '../../../constants/treeConstants';
import {
  getAMISignalList
} from '../../../services/Andes_v2/channel/AMIModelHelper';
import {
  updateADSConfig
} from '../store/channel/action';
import AMIModelRender from '../AMIModel';

class AMIModel extends Component {

  render() {
    const { adsConfig = {}, signals, components, selectedSignals, serdesType } = this.props;
    const { amiSignals: adsSignals, amiAllSignals: adsAllSignals } = getAMISignalList({ adsConfig, signals, components, selectedSignals, serdesType });
    return <AMIModelRender
      {...this.props}
      adsSignals={adsSignals}
      interfaceType={PCB_CHANNEL}
      adsAllSignals={adsAllSignals}
    />
  }
}

const mapState = (state) => {
  const {
    channel: { channelInfo: { content = {}, adsConfig, type }, channelId }
  } = state.AndesV2Reducer;
  const {
    components = [],
    signals = [],
    selectedSignals = []
  } = content;

  return {
    components,
    signals,
    selectedSignals,
    adsConfig,
    channelId,
    serdesType: type
  }
}

const mapDispatch = (dispatch) => ({
  _updateADSConfig(adsConfig, options) {
    dispatch(updateADSConfig(adsConfig, options))
  }
})

export default connect(mapState, mapDispatch)(AMIModel);