import apiProcess from "../api/utility";
import {
  AndesV2ChannelExtraction,
  getAndesV2ChannelExtractionLog
} from '../api/Andes_v2/extractionCtrl';
import {
  isExistSimulationResult
} from '../api/workflow';

/**
 * Andes v2 channel extraction start
 * 2021/04/23
 * @param {array} channelIds
 * @return Promise
 */
function channelStartExtraction({ channelIds, runSeasim = false, runSimulation = false, setupOnly = false }) {
  return apiProcess(AndesV2ChannelExtraction, { channelIds, runSeasim, runSimulation, setupOnly }, false, true);
}

/**
 * Get Andes v2 channel extraction log
 * 2021/04/26
 * @param {array} channelIds
 * @return Promise
 */
function getChannelExtractionLog(verificationId) {
  return new Promise((resolve, reject) => {
    getAndesV2ChannelExtractionLog(verificationId).then(res => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
}

function isExistResult(verificationId) {
  return apiProcess(isExistSimulationResult, { product: "andes", verificationId });
}

export {
  channelStartExtraction,
  getChannelExtractionLog,
  isExistResult
}