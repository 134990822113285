import React, { Component, Fragment, createRef } from "react";
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import EditableTable from '@/components/EditableTable';
import { CloseCircleFilled, CloseOutlined, CopyOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Select, Checkbox, Button } from "antd";
import VirtualList from "./VirtualList";
import { copyToClipboard } from "../../../services/helper/htmlElement";
import { strDelimited } from "../../../services/helper/split";

const PointColumns = [{
  title: 'Component',
  dataIndex: 'comp',
  width: '20%'
}, {
  title: 'Pin',
  dataIndex: 'pin',
  width: '60%'
}, {
  title: 'Net',
  dataIndex: 'net',
  width: '20%'
}]

class PointSelectPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      componentOptions: [],
      selectedNets: [],
      expandKeys: [],
      search: false,
      searchValue: '',
      compPinOptions: {} // { U0600: [ ...pinList ] }
    }
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
    this.enterKey = 13;

    PointColumns[0].onCell = (record) => {
      return {
        record,
        edit: 'aurora-select',
        options: this.state.componentOptions,
        dataIndex: "comp",
        selectType: 'comp',
        dropdownMenuClassName: 'dcr-select-dropdown-menu',
        handleSave: this.compSelected,
        onFocus: (e) => this.onFocus(e, record, 'comp'),
        enterSelect: true,
      }
    }

    PointColumns[1].onCell = (record) => {
      const { compPinOptions, expandKeys, search } = this.state;
      const pinOptions = compPinOptions ? compPinOptions[record.comp] || [] : []
      let new_options = search ? [...this.state.searchOptions] : [...pinOptions]
      new_options = this.optionsSort(new_options, record, expandKeys);
      return {
        record,
        edit: 'select',
        netSelect: true,
        options: new_options,
        editable: true,
        dataIndex: "pin",
        selectMode: 'multiple',
        dropdownMenuClassName: 'select-dropdown-menu',
        clearSelected: this.clearSelected,
        onFocus: this.onFocus,
        onInputKeyDown: (e, form, clearInputValue) => this.selectPinsBySearch(e, form, clearInputValue, pinOptions, record),
        onSearch: (val) => this.onSearch(val, pinOptions, record),
        dropdownRender: (form, clearInputValue) => this.treeSelectList(form, record, clearInputValue, new_options),
        clearSelectStatus: this.clearSelectStatus
      }
    }

    PointColumns[1].render = (text) => {
      return text ? text.join(', ') : ''
    }


    PointColumns[2].render = (net, record) => {
      return (
        <Fragment>
          <span>{net}</span>
          <CloseOutlined
            className='dcr-point-delete-icon'
            title="Delete The Point."
            onClick={(e) => this.deletePoint(e, record)} />
        </Fragment>
      );
    }
  }

  deletePoint = (e, record) => {
    e && e.stopPropagation();
    const { dataSource } = this.state;
    let _dataSource = [...dataSource];
    _dataSource = _dataSource.filter(item => item.index !== record.index);
    this.setState({
      dataSource: _dataSource
    })
  }

  clearSelectStatus = () => {
    this.setState({
      search: false
    });
  }

  changeExpandKeys = (e, key) => {
    const { expandKeys } = this.state;
    let _expandKeys = expandKeys;
    if (!_expandKeys.includes(key)) {
      _expandKeys.push(key);
    } else {
      _expandKeys = _expandKeys.filter(item => item !== key)
    }
    this.setState({
      expandKeys: _expandKeys
    })
  }

  getList = (options, expandKeys) => {
    let _index = 0, newList = [], nodeItemCount = 0;
    for (let item of options) {
      item.key = _index;
      _index = _index + 1;
      if (expandKeys.includes(item.id)) {
        nodeItemCount = nodeItemCount + item.children.length;
        for (let it of item.children) {
          it.key = _index;
          _index = _index + 1;
        }
      }
      newList.push(item);
    }
    return { newList, nodeItemCount };
  }

  treeSelectList = (form, record, clearInputValue, options) => {
    const { expandKeys } = this.state;
    const { setFieldsValue } = form;
    const { newList, nodeItemCount } = this.getList(options, expandKeys);
    return (
      <div
        className='path-r-editTable-select'
        onMouseDown={(e) => {
          e.preventDefault()
          return false
        }}>
        {!options.length ? <div className="menu-no-result virtual-list-menu">No Result</div>
          :
          <VirtualList
            data={options}
            count={options.length}
            size={16} // Number of list items rendered by the viewport                 
            viewSize={8} // Number of lists visible in the viewable area
            rowHeight={27}
            renderNode={(net) => this.renderNode(net, record, setFieldsValue, clearInputValue)} // Rendered list item DOM node
            nodeItemCount={nodeItemCount}
            newList={newList}
            expandKeys={expandKeys}
          />
        }
      </div>
    )
  }

  renderNode = (net, record, setFieldsValue, clearInputValue) => {
    const { expandKeys } = this.state;
    let checkAll = false;

    if (record.pin && record.pin.length === 1) {
      const [selectedNet, pinText] = strDelimited(record.pin[0], " (");
      checkAll = selectedNet === net.id && pinText.match("All Pins") ? true : false;
    }
    const Icon = expandKeys.includes(net.id) ? CaretDownOutlined : CaretRightOutlined
    return (
      <Fragment key={net.id}>
        {net.flag ? null : <li key={net.id} title={net.title} className="tree-list-content" >
          <Icon onClick={(e) => { this.changeExpandKeys(e, net.id) }} />
          <Checkbox
            indeterminate={!checkAll && record.pin.length && record.pin[0].includes(net.id) ? true : false}
            onChange={(e) => this.onCheckAllChange(e, record, net.id, setFieldsValue, clearInputValue)}
            checked={checkAll}
            disabled={record.net && record.net !== net.id ? true : false}
          >
          </Checkbox>
          <span className="tree-list-content-net" onClick={() => this.selectNetTitle(record, net.id, /* searchPinList, allPinList, */ setFieldsValue, clearInputValue)}>{net.title}</span>
          <CopyOutlined
            title="Copy text"
            id={`net-${net.title}`}
            className="tree-list-content-pin-copy-icon"
            onClick={(e) => copyToClipboard(e, net.title, `net-${net.title}`)}
          />
        </li>}
        {expandKeys.includes(net.id) ? this.pinRender(net, record, net.children, setFieldsValue, clearInputValue, checkAll) : null}
      </Fragment>
    );
  }

  selectNetTitle = (record, net, setFieldsValue, clearInputValue) => {
    this.checkAllPins({
      record,
      isUnChecked: record.pin & record.pin.length === 1 && record.pin[0].match("All Pins"),
      net,
      setFieldsValue,
      clearInputValue
    });
  }

  selectPinTitle = (pin, record, net, setFieldsValue, clearInputValue) => {
    const { dataSource, compPinOptions } = this.state;
    let _dataSource = [...dataSource];

    const index = _dataSource.findIndex(item => item.index === record.index);
    let pinList = [];
    // disable check
    if (record.net && record.net !== net) {
      return;
    }
    const { pin: selectedPins, comp } = record;
    const compNet = (compPinOptions[comp] || []).find(item => item.id === net) || {};

    const allCompPinList = compNet.children || [];

    //pin: pin::pinName
    const pinName = `${pin} (${net})`; // pin::pinName (netName)
    //all pins check
    const selectAll = selectedPins.length === 1 && selectedPins[0].match("All Pins");
    if (selectedPins && (selectedPins.includes(pinName) || selectAll)) {//cancel checked
      pinList = selectAll ? allCompPinList.filter(item => item.id !== pin).map(item => `${item.id} (${net})`) : selectedPins.filter(item => item !== pinName);
      setFieldsValue({ pin: pinList });
    } else {//check
      pinList = [...selectedPins, pinName];
      if (pinList.length === allCompPinList.length) {
        pinList = [`${net} (All Pins)`]
      }
      setFieldsValue({ pin: pinList });
    }
    _dataSource[index].pin = [...pinList];
    _dataSource[index].net = pinList.length ? net : "";
    clearInputValue();
    this.setState({
      dataSource: JSON.parse(JSON.stringify(_dataSource))
    });
  }

  onCheckAllChange = (e, record, net, setFieldsValue, clearInputValue) => {
    e && e.stopPropagation()
    this.checkAllPins({
      record,
      isUnChecked: !e.target.checked,
      net,
      setFieldsValue,
      clearInputValue
    });
  };

  checkAllPins = ({ record, isUnChecked, net, setFieldsValue, clearInputValue }) => {
    const { search, dataSource, searchOptions, compPinOptions } = this.state;
    //disabled
    if (record.net && record.net !== net) {
      return;
    }
    let pinList = [];
    let _dataSource = [...dataSource];
    const index = _dataSource.findIndex(item => item.index === record.index);
    if (isUnChecked) {//unChecked
      pinList = [];
      setFieldsValue({ pin: [] });
      _dataSource[index].net = "";
    } else if (search) {//select search all
      const findNet = searchOptions.find(item => item.id === net);
      pinList = [...findNet.children.map(item => `${item.id} (${net})`)];
      const allOptions = (compPinOptions[record.comp] || []).filter(item => item.id === net);
      if (allOptions[0] && allOptions[0].children && pinList.length === allOptions[0].children.length) {
        pinList = [`${net} (All Pins)`]
      }
      setFieldsValue({ pin: pinList });
      _dataSource[index].net = net;
    } else { // select all
      pinList = [`${net} (All Pins)`]
      setFieldsValue({ pin: pinList });
      _dataSource[index].net = net;
    }
    _dataSource[index].pin = [...pinList];

    clearInputValue();
    this.setState({
      dataSource: JSON.parse(JSON.stringify(_dataSource))
    });
  }

  pinRender = (net, record, viewPinList, setFieldsValue, clearInputValue, checkAll) => {
    return (
      <ul className="tree-list-dropdown">
        {viewPinList.map((pin, pinIndex) => {
          return (
            <Fragment key={pinIndex}>
              <li key={pinIndex} title={pin.title} className="tree-list-content">
                <Checkbox
                  onChange={() => this.selectPinTitle(pin.title, record, net.id, setFieldsValue, clearInputValue)}
                  checked={checkAll ? true : (record.pin.includes(`${pin.title} (${net.id})`) ? true : false)}
                  disabled={record.net && record.net !== net.id ? true : false}
                >
                </Checkbox>
                <span className="tree-list-content-net" onClick={() => this.selectPinTitle(pin.title, record, net.id, setFieldsValue, clearInputValue)}>
                  {pin.title}
                </span>
                <CopyOutlined
                  title="Copy text"
                  id={`pin-${pin.title}`}
                  className="tree-list-content-pin-copy-icon"
                  onClick={(e) => copyToClipboard(e, pin.title, `pin-${pin.title}`)} />
              </li>
            </Fragment>
          );
        })}
      </ul>
    );
  }

  searchPin = (value, pinOptions) => {
    let pins = [...(pinOptions || [])], _search = false;
    pins = this.optionsSort(pins);
    let new_options = [], _expandKeys = [];
    if (value && !value.match(/^\s+$/g)) {
      for (let item of pins) {
        let _pinOptions = [], pinList = [];
        if (!!item.children) {
          let isSort = false;
          item.children.forEach(option => {
            const optionsValue = option.search.toLowerCase();
            const searchValue = value.toLowerCase();
            if (optionsValue.indexOf(searchValue) > -1) {
              pinList.push(option);
              isSort = true;
            }
          });
          _pinOptions = [...pinList];
          if (isSort) {
            new_options.push({ ...item, children: _pinOptions });
            _search = true;
            _expandKeys.push(item.id);
          }
        }
      }
      if (new_options && new_options.length === 0) {
        // no result search
        _search = true;
      }
    } else {
      new_options = [...pins];
      _search = false;
    }
    this.setState({
      search: _search,
      expandKeys: _expandKeys,
      searchOptions: new_options
    })
  }

  onSearch = (val, pinOptions) => {
    this.setState({
      searchValue: val,
    }, () => {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(() => {
        this.searchPin(val, pinOptions)
      }, 500);
    })
  }

  optionsSort = (options) => {
    let filterNetsBySelectedNet = [];
    const { selectedNets } = this.state;
    if (!selectedNets.length) {
      return options;
    }
    if (selectedNets.length) {
      filterNetsBySelectedNet = options.filter(it => selectedNets.includes(it.id));
      return [...filterNetsBySelectedNet]
    }
    return options
  }

  onFocus = (e, record, type) => {
    this.setState({
      errorMsg: null
    })
    const { viewList, show } = this.props;
    if (type === 'comp' && viewList && viewList.includes('PCB') && show) {
      let comp = record.comp;
      if (comp) {
        this.props._select({ comps: [comp] });
      }
    }
  }

  clearSelected = (value, record, setFieldsValue) => {
    const { dataSource } = this.state;
    let _dataSource = [...dataSource];
    const pin = record.pin.filter(item => item !== value);
    const index = _dataSource.findIndex(item => item.index === record.index);
    _dataSource[index].pin = pin;
    if (setFieldsValue) {
      setFieldsValue({ pin: pin })
    }

    this.setState({
      dataSource: _dataSource,
    })
  }

  compSelected = (value, record) => {
    const { compPinOptions, dataSource } = this.state;
    const _dataSource = [...dataSource], _compPinOptions = { ...compPinOptions };
    const index = _dataSource.findIndex(item => item.index === record.index);
    _dataSource[index] = {
      comp: value.comp,
      pin: [],
      net: "",
      index: record.index
    }
    const { Components } = this.props;
    const data = Components.get(value.comp)
    const _pins = [...data.pins.values()]
    const pinOptions = this.getPinTree(_pins);
    _compPinOptions[value.comp] = pinOptions;
    this.setState({
      dataSource: _dataSource,
      compPinOptions: _compPinOptions
    })
  }

  getPinTree = (pins) => {
    const { nets = [] } = this.props;
    // set default treeItem
    let netOptions = [];
    for (let netName of nets) {
      let _ePinOptions = [];
      pins.forEach(it => {
        if (it.net === netName) {
          const name = `${it.pin}::${it.pinName}`
          _ePinOptions.push({
            pin: it.pin,
            search: `${it.pin}::${it.pinName}::${it.net}`,
            id: name,
            value: name,
            title: name
          })
        }
      })
      if (_ePinOptions && _ePinOptions.length) {
        netOptions.push({
          id: netName,
          value: netName,
          title: netName,
          children: _ePinOptions
        })
      }
    }
    return netOptions;
  }

  componentDidMount() {
    this.setState({
      offset: this.inputRef.current.getBoundingClientRect()
    })
    this.getDataSource()
  }

  getDataSource = () => {
    const { data, anotherPointNets } = this.props;
    /*   const { comp, pins, net } = data || {}; */
    const { Components } = this.props;

    let _dataSource = [], nets = [], index = 0;
    if (Array.isArray(data)) {
      for (let itemData of data) {
        const { comp, pins, net } = itemData || {};
        net && nets.push(net);
        const data = Components.get(comp);
        const _pins = data ? [...data.pins.values()] : [];
        let pinLength = _pins.filter(it => it.net === net);
        let selectedPins = [];
        for (let item of pins || []) {
          const findPin = _pins.find(it => it.pin === item);
          if (!findPin) {
            continue
          }
          selectedPins.push({
            pin: item,
            pinName: findPin.pinName,
            net: findPin.net
          });
        }
        let _pin = [];
        if (!!net) {
          if (!selectedPins.length) {
            _pin = [];
          } else if (selectedPins.length && pinLength.length !== selectedPins.length) {
            _pin = selectedPins.map((item) => `${item.pin}::${item.pinName} (${net})`);
          } else {
            _pin = [`${net} (All Pins)`]
          }
        }
        _dataSource.push({
          index,
          comp: comp,
          pin: _pin,
          net
        });
        index++;
      }
    }

    this.setState({
      dataSource: _dataSource,
      selectedNets: [...new Set([...nets, ...anotherPointNets])].filter(item => !!item)
    }, () => {
      this.getComponentOptions(this.state.selectedNets)
    })
  }

  getComponentOptions = (selectedNets) => {
    const { Components, } = this.props;
    let _componentOptions = [];
    let compPinOptions = {};
    let _Components = [...Components.values()];
    const { dataSource } = this.state;

    for (let net of (selectedNets || [])) {
      if (!net) {
        continue;
      }

      const filterData = dataSource.filter(item => item.net === net);
      const filterComps = _Components.filter(item => [...item.pins.values()].filter(it => it.net === net).length);
      _componentOptions = [...new Set([..._componentOptions, ...filterComps.map(item => item.name)])];
      if (filterData.length && _Components.length > 0) {
        for (let compInfo of filterData) {
          const data = Components.get(compInfo.comp);
          const _pins = data && data.pins ? [...data.pins.values()] : [];
          let pinOptions = [];
          _pins.forEach(it => {
            if (it.net !== 'NONET') {
              const findNetIndex = pinOptions.findIndex(item => item.id === it.net);
              const name = `${it.pin}::${it.pinName}`;
              if (findNetIndex > -1) {
                pinOptions[findNetIndex].children.push({
                  pin: it.pin,
                  search: `${it.pin}::${it.pinName}::${it.net}`,
                  id: name,
                  value: name,
                  title: name,
                  comp: compInfo.comp
                })
              } else {
                pinOptions.push({
                  id: it.net,
                  value: it.net,
                  title: it.net,
                  children: [{
                    pin: it.pin,
                    search: `${it.pin}::${it.pinName}::${it.net}`,
                    id: name,
                    value: name,
                    title: name,
                    comp: compInfo.comp
                  }],
                })
              }
            }
          });
          compPinOptions[compInfo.comp] = pinOptions;
        }
      }
    }

    if (!selectedNets.length) {
      _componentOptions = _Components.map(item => item.name);
    }
    this.setState({
      componentOptions: _componentOptions.sort(),
      compPinOptions,
    })
  }

  closeModel = () => {
    const { dataSource, selectedNets } = this.state;
    const { Components, dataIndex } = this.props;
    let _dataSource = [...dataSource];

    let errorMsg = "";
    for (let itemData of _dataSource) {

      const data = Components.get(itemData.comp) || {};
      const compPins = data.pins ? [...data.pins.values()] : [];
      if (itemData.pin && itemData.pin.length === 1 && itemData.pin[0].match(`All Pins`)) {
        const _net = strDelimited(itemData.pin[0], " ", { returnIndex: 0 });
        itemData.pins = compPins.filter(item => item.net === _net).map(item => item.pin);
      } else if (itemData.pin && itemData.pin.length) {
        itemData.pins = itemData.pin.map(item => strDelimited(item, "::", { returnIndex: 0 }))
      }
      delete itemData.pin;
      delete itemData.index;
    }
    if (errorMsg) {
      this.setState({
        errorMsg
      })
    } else {
      this.props.savaPointModel(_dataSource, selectedNets, dataIndex);
      this.props.save()
    }
  }

  addNewPoint = () => {
    const { dataSource } = this.state;
    let _dataSource = JSON.parse(JSON.stringify(dataSource));
    _dataSource.push({
      comp: "",
      pin: [],
      net: "",
      index: _dataSource.length + 1
    });
    this.setState({
      dataSource: _dataSource
    })
  }

  selectPinsBySearch = (e, form, clearInputValue, options, record) => {
    const keyCode = e.keyCode;
    if (keyCode === this.enterKey) {
      const { searchValue, dataSource } = this.state;
      const pins = searchValue.replace(' ', '').split(',');
      const { setFieldsValue } = form;

      const _dataSource = [...dataSource];
      const index = _dataSource.findIndex(item => item.index === record.index);
      let pinList = [];
      const { pin: selectedPins } = record;
      const selectAll = selectedPins.length === 1 && selectedPins[0].match("All Pins");
      if (selectAll) {
        clearInputValue();
        return;
      }
      let allPins = [...new Set([...selectedPins.map(item => item.split('::')[0]), ...pins])].filter(item => !!item);
      if (pins && pins.length === 1) {
        const net = options.find(item => item.id === pins[0]);
        if (net) {
          allPins = net.children.map(child => child.pin);
        }
      }
      const currentOptions = options.filter(item => item.children.some(child => allPins.includes(child.pin) || allPins.includes(child.id)));
      if (!currentOptions.length) {
        clearInputValue();
        return;
      }
      const option = currentOptions[0];
      const childrenLength = option.children.length;
      const net = option.id;
      const children = option.children.filter(child => allPins.includes(child.pin) || allPins.includes(child.id));

      if (children.length !== childrenLength) {
        pinList = children.map(item => `${item.id} (${net})`)
      } else {
        pinList = [`${net} (All Pins)`]
      }
      setFieldsValue({ pin: pinList });
      _dataSource[index].pin = [...pinList];
      _dataSource[index].net = pinList.length ? net : "";
      clearInputValue();
      this.setState({
        dataSource: JSON.parse(JSON.stringify(_dataSource))
      });
    }
  }

  renderDialog() {
    const { offset, dataSource, errorMsg } = this.state;
    const { title } = this.props;
    if (offset === undefined) return null;
    const content = (
      <Panel
        className="dcr-point-select-table-panel"
        title={title}
        onCancel={this.closeModel}
        zIndex={2000}
        position='panel-center'
        draggable
        // height={160}
        width={700}
      >
        <div className="point-panel-content">
          {this.getNetSelection()}
          <EditableTable
            rowKey={(record) => record.index}
            columns={PointColumns}
            size="small"
            dataSource={dataSource}
            className='dcr-point-table'
            tablePadding={true}
          />
          {errorMsg ? <div className="cascade-panel-error-msg">{errorMsg}</div> : ""}
          <Button
            title="Add New Component"
            className='cascade-dcr-point-add-button'
            type="primary"
            ghost={true}
            onClick={(e) => this.addNewPoint(e)}
          >Add New Component</Button>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  changeNetSelection = (nets) => {
    let _dataSource = [...this.state.dataSource];
    _dataSource.forEach(item => {
      if (!nets.includes(item.net)) {
        item.net = "";
        item.comp = "";
        item.pin = [];
      }
    })
    this.setState({
      selectedNets: nets.filter(item => !!item)
    }, () => {
      this.getComponentOptions(nets);
    })
  }

  getNetSelection = () => {
    const { selectedNets } = this.state;
    const { nets = [] } = this.props;
    return (
      <div className="path-r-point-net-selection-content">
        <span>Nets</span>
        <Select
          className='path-r-point-net-selection aurora-select'
          onChange={this.changeNetSelection}
          mode="multiple"
          placeholder="Select net to help filter components."
          popupClassName='path-r-point-net-selection-dropdown'
          showSearch={true}
          allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.changeNetSelection([]) }} /> }}
          value={selectedNets || []}
        >
          {nets.map(item =>
            <Select.Option
              key={item}
              value={item}
            >
              {item}
            </Select.Option>)}
        </Select>
      </div>
    );
  }

  render() {
    const { inputRef } = this;
    const { data, error } = this.props;
    return (
      <Fragment>
        <div className="editable-cell-value-wrap" ref={inputRef}>
          {Array.isArray(data) ? data.map((item, index) => <span key={index} className={error ? 'resistance-table-error-span' : ''}>
            {data.length > 1 && item.comp ? "(" : null}
            {item.comp}
            {(item.pins && item.pins.length) ?
              <Fragment> - <span className="point-pin-span">{item.pins.join(', ')}</span></Fragment> : ""
            }
            {data.length > 1 && item.comp ? ") " : null}
          </span>) : null}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default PointSelectPanel;


