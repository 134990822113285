import React, { Component, Fragment } from 'react';
import Table from '@/components/EditableTable';
import canvas from '@/services/LayoutCanvas';
import { ConfigProvider, Spin, Radio } from 'antd';

const columns = [{
  title: "Name",
  key: "name",
  dataIndex: "name"
}, {
  title: "Extraction",
  key: "extraction",
  dataIndex: "extraction"
}]
const MANUAL = 'manual', ANSYS = 'ansys';
class HybridExtraction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      radioValue: this.props.suggestHFSSRegions ? ANSYS : MANUAL
    }

    columns[1].onCell = (record) => {
      return {
        edit: "select",
        record,
        options: ['SIwave', 'HFSS'],
        dataIndex: "extraction",
        handleSave: (_record) => { this.updateHybridRegionExtraction(_record) }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;

    const boundaryline = document.querySelector('.boundaryline');
    const hybridBoundaryLine = document.querySelectorAll('.hybridBoundaryLine');
    let include = false;

    if (hybridBoundaryLine) {
      for (let i = 0; i < hybridBoundaryLine.length; i++) {
        if (hybridBoundaryLine[i].contains(target)) {
          include = true;
          break;
        }
      }
    }

    if (((!boundaryline || !boundaryline.contains(target)) && !include) || this.props.suggestHFSSRegions) {
      return;
    }

    setTimeout(() => {
      const layout = this.getLayout();
      if (!layout) return;
      const clickMousePosition = layout.getClickMousePosition();
      this.showHybridLine(clickMousePosition)
    }, 20)
  }

  getLayout = () => {
    const { designId } = this.props;
    return canvas.getLayout(designId);
  }

  showHybridLine = (clickMousePosition) => {
    const { signals, selectedSignals, designId, hybridRegionsInfo } = this.props;
    hybridRegionsInfo.addHybridLine({ clickMousePosition, designId, signals, selectedSignals, updateHybridRegions: this.props.updateHybridRegions })
  }

  updateHybridRegionExtraction = (record) => {
    const { hybridRegions, hybridRegionsInfo } = this.props;
    let _hybridRegions = JSON.parse(JSON.stringify(hybridRegions));
    const index = _hybridRegions.findIndex(item => item.name === record.name);
    if (index < 0) {
      return;
    }

    _hybridRegions[index].extraction = record.extraction;
    let hybridErrorMsg = this.props.getHybridRegionsHFSSError(_hybridRegions, true)
    if (hybridErrorMsg) { return }
    this.props.updateHybridRegions({ hybridRegions: _hybridRegions });
    hybridRegionsInfo.updateHybridRegionsCache(_hybridRegions);
  }

  updateValue = (e) => {
    const value = e.target.value;
    this.setState({
      radioValue: value
    })
    const checked = value === ANSYS ? true : false;
    this.props.switchRegionsChange(checked, "siwave", "suggestHFSSRegions")
  }

  render() {
    const { hybridRegions = [], hybridLoading, hybridErrorMsg, suggestHFSSRegions } = this.props;
    const { radioValue } = this.state;
    return <div className="hybrid-extraction-panel-content clear">
      <span className='hybrid-title'>HFSS Regions</span>
      <div className='hybrid-extraction-radio-content'>
        <Radio.Group
          onChange={(e) => this.updateValue(e, 'suggestHFSSRegions')}
          value={radioValue}
        >
          <div className='channel-config-item channel-config-sub-item'>
            <Radio value={MANUAL}><span>Manual region generation</span></Radio>
            {!suggestHFSSRegions && <Fragment>
              <Spin spinning={hybridLoading} tip="Loading Regions..." size="small">
                <ConfigProvider renderEmpty={() => <Fragment>Click inside the clipping polygon to draw piece-wise lines for solver regions.</Fragment>}>
                  <Table
                    rowKey="name"
                    columns={columns}
                    size="small"
                    dataSource={hybridRegions || []}
                    scroll={hybridRegions.length > 5 ? { y: 300 } : {}}
                    className='hybrid-extraction-table'
                  />
                </ConfigProvider>
              </Spin>
              {hybridErrorMsg && <span className="extraction-option-error-msg">{hybridErrorMsg}</span>}</Fragment>}
          </div>
          <div className='channel-config-item channel-config-sub-item'>
            <Radio value={ANSYS}><span className='channel-sub-item-title'>Ansys Suggested HFSS Regions​</span></Radio>
          </div>
        </Radio.Group>
      </div>
    </div>
  }
}

export default HybridExtraction;