import { SIERRA } from "../../constants/pageType";
import { SHUNT_C, SHUNT_L, SHUNT_R } from "../VirtualComponent";

const RLC_UNITS = {
  RES: [{ key: "Ω", title: "Ω" }, { key: "K", title: "KΩ" }, { key: "M", title: "MΩ" }],
  IND: [{ key: "u", title: "uH" }, { key: "n", title: "nH" }, { key: "p", title: "pH" }],
  CAP: [{ key: "u", title: "uF" }, { key: "n", title: "nF" }, { key: "p", title: "pF" }]
}

const _RLC_UNITS = {
  RES: [{ key: "m", title: "mΩ" }, { key: "Ω", title: "Ω" }, { key: "K", title: "KΩ" }, { key: "M", title: "MΩ" }],
  IND: [{ key: "H", title: "H" }, { key: "u", title: "uH" }, { key: "n", title: "nH" }, { key: "p", title: "pH" }],
  CAP: [{ key: "F", title: "F" }, { key: "u", title: "uF" }, { key: "n", title: "nF" }, { key: "p", title: "pF" }]
}

const RLC_UNITS_SUFFIX = {
  RES: "Ω",
  IND: "H",
  CAP: "F"
}

const RLC_PLACEHOLDER = {
  RES: "Resistance",
  IND: "Inductance",
  CAP: "Capacitance"
}

function getRLCInputKey(type, product, subType) {
  const units = product === SIERRA ? _RLC_UNITS : RLC_UNITS;
  const RES = { key: 'r', placeholder: 'Resistance', units: units["RES"] };
  const IND = { key: 'l', placeholder: 'Inductance', units: units["IND"] };
  const CAP = { key: 'c', placeholder: 'Capacitance', units: units["CAP"] };

  switch (type) {
    case 'Res':
    case SHUNT_R:
      return [RES];
    case 'Cap':
    case SHUNT_C:
      return subType === "virtualPassive" || type === SHUNT_C ? [CAP] : [RES, IND, CAP];
    case 'Ind':
    case SHUNT_L:
      return [IND];
    case 'Jumper':
      return product === SIERRA ? [RES] : []
    default: return [];
  }
}

export { getRLCInputKey, RLC_UNITS, RLC_UNITS_SUFFIX, RLC_PLACEHOLDER, _RLC_UNITS };