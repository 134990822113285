import { numberCheck } from '@/services/helper/dataProcess';

function getImportExportInputData(data) {
  let dataList = [];
  for (let childData of data) {
    const { libraryType, type, children, key, dataType, name, id } = childData;
    let _data = {}, _title, _value, _key = '', _children = [], _showTitle = '', _isFile = false;
    if (!libraryType || !type || !id) {
      let _childrenData = []
      if (children && children.length) {
        const childrenData = getImportExportInputData(children)
        if (childrenData.length) {
          _childrenData = childrenData
        }
      }
      _title = name;
      _value = key;
      _key = key;
      _children = _childrenData;
      _showTitle = 1 + name;
    } else {
      _title = name;
      _value = id;
      _key = key;
      _showTitle = 1 + name;
      if (type === 'file' && libraryType === 'file' && !children) {
        _isFile = true
      } else if (type === 'folder' && libraryType === 'file') {
        _isFile = true
      } else if (libraryType === 'folder' && children && children.length) {
        const _childrenData = getImportExportInputData(children)
        _children = _childrenData
      }
    }
    _data = {
      title: _title,
      value: _value,
      key: _key,
      showTitle: _showTitle,
      isDownload: _isFile
    }
    if (_children.length) {
      _data.children = _children
    }

    dataList.push(_data)
  }
  return dataList
}

function getProjectAllIdList(data) {
  let idList = []
  const { children, key, isDownload } = data;
  const [type, projectId, verificationId] = key.split('-')
  if (isDownload && !numberCheck(projectId)) {
    if (type === 'project') {
      idList.push(projectId)
    } else if (type === 'task') {
      idList.push(verificationId)
    }
  } else if (children && children.length) {
    for (let child of children) {
      const _childIdList = getProjectAllIdList(child);
      idList = [...idList, ..._childIdList]
    }
  }
  return idList
}

function getProjectFileIdList(treeData, keyList) {
  let idList = []
  for (let data of treeData) {
    const { children, key } = data;
    if (keyList.includes(key)) {
      const _idList = getProjectAllIdList(data)
      idList = [...idList, ..._idList]
    } else if (children && children.length) {
      const childIdList = getProjectFileIdList(children, keyList)
      idList = [...idList, ...childIdList]
    }
  }
  return idList
}

function getExportParamsInCascade(treeData, checkedKeys) {
  const exportProjectParams = []
  // The set of the selected tasks, {key: projectId,value: tasksId}
  const verificationIds = {}
  // fully selected project
  const checkedProjectIds = []
  checkedKeys.forEach((key) => {
    const [type, projectId, verificationId] = key.split('-')
    if (type === 'All') {
      const fileIdList = getProjectFileIdList(treeData, [key])
      fileIdList.forEach((fileId) => {
        if (!checkedProjectIds.includes(fileId)) {
          exportProjectParams.push({
            projectId: fileId,
            verificationIds: []
          })
          checkedProjectIds.push(fileId)
        }
      })
      return exportProjectParams
    } else if (type === 'project') {
      if (!checkedProjectIds.includes(projectId)) {
        exportProjectParams.push({
          projectId: projectId,
          verificationIds: []
        })
        checkedProjectIds.push(projectId)
      }
    } else if (type === 'task') {
      if (Object.keys(verificationIds).includes(projectId)) {
        verificationIds[projectId].push(verificationId)
      } else {
        verificationIds[projectId] = [verificationId]
      }
    }
  })
  Object.keys(verificationIds).forEach((item) => {
    if (!checkedProjectIds.includes(item)) {
      exportProjectParams.push({
        projectId: item,
        verificationIds: verificationIds[item]
      })
    }
  })
  return exportProjectParams
}

function getALlIdList(data) {
  let idList = []
  const { children, value, isDownload } = data;
  if (isDownload && !numberCheck(value)) {
    numberCheck(value)
    idList.push(value)
  } else if (children && children.length) {
    for (let child of children) {
      const _childIdList = getALlIdList(child);
      idList = [...idList, ..._childIdList]
    }
  }
  return idList
}

function getLibraryFileIdList(treeData, valueList) {
  let idList = []
  for (let data of treeData) {
    const { children, value } = data;
    if (valueList.includes(value)) {
      const _IdList = getALlIdList(data)
      idList = [...idList, ..._IdList]
    } else if (children && children.length) {
      const childIdList = getLibraryFileIdList(children, valueList)
      idList = [...idList, ...childIdList]
    }
  }
  return idList
}

export {
  getImportExportInputData,
  getLibraryFileIdList,
  getProjectFileIdList,
  getExportParamsInCascade,
}