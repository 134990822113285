import { parseTotalCapacitanceCSV } from ".";
import { getTotalTraceCapacitanceCSV } from "..";

class TotalTraceCapacitance {
  constructor() {
    this.verificationMap = new Map();//id, -> { csvFile}
  }

  getVerInfo = async (verificationId, fileName) => {
    const ver = this.verificationMap.get(verificationId);
    if (!ver || !ver.csvFile || !ver.csvFile.get(fileName) || !ver.csvFile.get(fileName).frequencies) {
      return await this.getCsv(verificationId, fileName)
    }
    return new Promise((resole, reject) => {
      resole(ver.csvFile.get(fileName));
    })
  }

  getCsv = async (verificationId, fileName) => {
    const csvContent = await getTotalTraceCapacitanceCSV({ verificationId, fileName });
    const _csv = parseTotalCapacitanceCSV(csvContent);
    let ver = this.verificationMap.get(verificationId)
    if (ver && ver.csvFile) {
      ver.csvFile.set(fileName, _csv);
    } else {
      if (!ver) {
        ver = {}
      }
      ver.csvFile = new Map();
      ver.csvFile.set(fileName, _csv);
    }

    this.verificationMap.set(verificationId, ver)
    return _csv
  }

  del = (id) => {
    this.verificationMap.delete(id)
  }

  clearAll = () => {
    this.verificationMap = new Map();
  }
}

const totalTraceCapacitance = new TotalTraceCapacitance();
export default totalTraceCapacitance;
