import React, { Component } from "react";
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Divider } from "antd";
import { getPanelWidth } from "../../services/helper/panelSizeHelper";

class RefPinEditHelp extends Component {
  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  render() {
    const { maxWidth, maxHeight } = this.props;
    const content = (
      <Panel
        className='extraction-ports-panel aurora-x-scroll-hidden'
        title={<div>Reference Pin Edit Help</div>}
        onCancel={this.props.closeHelp}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 760 })}
        maxWidth={maxWidth}
        position='panel-center'
        draggable
        minHeight={230}
        maxHeight={maxHeight}
      >
        <img src={'/lib/imgs/port_setup_edit_mode1.png'} style={{ width: '100%' }} alt="" />
        <Divider />
        <img src={'/lib/imgs/port_setup_edit_mode2.png'} style={{ width: '100%' }} alt="" />
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default RefPinEditHelp;