import {
  UPDATE_PRE_LAYOUT_CONTENT,
  FIRST_OPEN_PRE_LAYOUT,
  COMP_TABLE_LOADING,
  SCHEMATIC_LOADING,
  UPDATE_CANVAS,
  CREATE_LOADING,
  GROUP_SHOW
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { PRE_LAYOUT_DESIGN_VERSION } from '../../../../services/Cascade/prelayout';
import { AC_PRE_LAYOUT_VERSION } from '../../../../version';
import { AC } from '../../../../constants/treeConstants';

const defaultState = {
  id: "",
  name: "",
  designVersion: PRE_LAYOUT_DESIGN_VERSION,
  version: AC_PRE_LAYOUT_VERSION,
  firstOpen: false,
  canvasUpdate: false,
  create: false,
  groupShow: "",
  electric: AC,
  content: {}
}

export const CascadePreLayoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PRE_LAYOUT_CONTENT:
      return {
        ...state,
        ...action.params
      }
    case FIRST_OPEN_PRE_LAYOUT:
      return {
        ...state,
        firstOpen: action.boolean
      }
    case COMP_TABLE_LOADING:
      return {
        ...state,
        compTableLoading: action.boolean
      }
    case SCHEMATIC_LOADING:
      return {
        ...state,
        schematicLoading: action.boolean
      }
    case UPDATE_CANVAS:
      return {
        ...state,
        canvasUpdate: action.boolean
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CREATE_LOADING:
      return {
        ...state,
        create: action.boolean
      }
    case GROUP_SHOW:
      return {
        ...state,
        groupShow: action.groupName
      }
    default: return state
  }
};