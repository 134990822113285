import React, { Fragment } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Tooltip, Select } from 'antd';
import {
  getSimulationStatus,
  NO_SIMULATION,
  getSelectedVerification,
  getSelectedMonitorMenu
} from '@/services/workflow/workflowHelper';
import { CASCADE } from '../../constants/pageType';

const Option = Select.Option;

function TitleRender({
  topWidth,
  projectName,
  subType,
  subName,
  subSelect,
  subList = [],
  verificationName,
  verificationType,
  waitingIndex,
  progress,
  verifications = [],
  changeVerification,
  changeSubName,
  selectedKey,
  monitorMenu,
  displayMonitorList,
  changeMonitorMenu,
  hoverOption,
  displayTooltip,
  pageType,
  subOptionType,
  channelName,
}) {

  const status = getSimulationStatus({ waitingIndex, progress });
  const display = (topWidth > 590 || (status === NO_SIMULATION && topWidth > 360));
  const verificationKey = selectedKey === "end_to_end_channel_children" ? "pcbName" : "name";

  const verificationLabel = getSelectedVerification(verificationName, verifications, verificationKey);
  const monitorSelected = getSelectedMonitorMenu(displayMonitorList, monitorMenu);
  return (
    <div>
      <Tooltip
        title={TitleTooltip({ topWidth, projectName, subType, subName, verificationName: verificationLabel, verificationType, monitorSelected })}
        overlayClassName='icon-tooltip'
        placement="topLeft"
      >
        {display ?
          <div className='aurora-simulation-msg' style={{ width: '100%' }}>
            {projectName && <div className='aurora-simulation-span'>
              <span className='aurora-span-msg'>{projectName}</span>
            </div>}
            {/* subName ? "-" : null */}
            {subName && <div className='aurora-simulation-span aurora-simulation-project'>

              {selectedKey === "pcb" ? <Select
                className='aurora-simulation-monitor-select'
                value={subSelect ? subSelect.id : null}
                onChange={changeSubName}
                popupMatchSelectWidth={false}
              >
                {subOptionType === "group" ?
                  subList.map(group =>
                    <Select.OptGroup label={group.name} key={group.name}>
                      {group.children.map(item =>
                        <Option value={item.id} key={item.id}>{item.name}</Option>
                      )}
                    </Select.OptGroup>)
                  : subList.map(item => <Option value={item.id} key={item.id}>{item.name}</Option>)}
              </Select>
                : <span className='aurora-span-msg'>{subName}</span>}
            </div>}
            {/* verificationName ? "-" : null */}
            {channelName && <div className='aurora-simulation-span'>
              {selectedKey === "sweep" && <span className='aurora-span-msg'>{channelName}</span>}
            </div>}
            {verificationName && <div className='aurora-simulation-span'>
              {selectedKey === "pcb" ? <span className='aurora-span-msg'>{verificationName}</span>
                : <Select
                  className='aurora-simulation-monitor-select'
                  value={verificationLabel}
                  onChange={(key) => changeVerification(key, verificationKey)}
                  popupMatchSelectWidth={false}
                >
                  {verifications.map(item => <Option value={item[verificationKey]} key={item[verificationKey]}>
                    {item[verificationKey]}
                  </Option>)}
                </Select>}
            </div>}
            {displayMonitorList && displayMonitorList.length ?
              <div className='aurora-simulation-span'>
                <Tooltip
                  title={monitorSelected.hoverName}
                  overlayClassName='icon-tooltip'
                >
                  <Select
                    className='aurora-simulation-monitor-select'
                    value={{ key: monitorSelected.key, label: monitorSelected.label }}
                    onChange={option => option && changeMonitorMenu({ label: option.label, key: option.value })}
                    popupMatchSelectWidth={false}
                    labelInValue
                  >
                    {displayMonitorList.map((item, index) => <Option
                      value={item.verificationId}
                      key={item.verificationId}
                      onMouseEnter={() => { displayTooltip(item.verificationId) }}
                      onMouseLeave={() => { displayTooltip() }}
                    >
                      {hoverOption === item.verificationId && monitorSelected.key !== item.verificationId && < Tooltip
                        title={item.name}
                        overlayClassName='icon-tooltip'
                        placement='left'
                        defaultOpen={true}
                        getPopupContainer={() => document.getElementsByClassName('tab-footer')[0]}
                      />}
                      {item.displayName}
                    </Option>)}
                  </Select>
                </Tooltip></div> : null}
          </div>
          : <div className='span-msg-icon-div'><InfoCircleOutlined className='span-msg-icon' /></div>}
      </Tooltip>
    </div >
  );
}


function TitleTooltip({ topWidth, projectName, subName, subType, verificationType, verificationName, monitorSelected }) {
  const vType = verificationType ? verificationType : "Verification";
  return topWidth <= 680 ? <Fragment>
    {projectName &&
      <span className='aurora-span-msg-tooltip'><span className='font-bold'>Project</span> {projectName}</span>}
    {subName &&
      <span className='aurora-span-msg-tooltip'><span className='font-bold'>{subType}</span> {subName}</span>}
    {verificationName &&
      <span className='aurora-span-msg-tooltip'><span className='font-bold'>{vType}</span> {verificationName}</span>}
    {monitorSelected && monitorSelected.key &&
      <span className='aurora-span-msg-tooltip'><span className='font-bold'>PCB</span> {monitorSelected.hoverName}</span>}
  </Fragment> : null
}

export {
  TitleRender,
  TitleTooltip
}