import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

/**
 * unzip file
 *
 * @export
 * @param {*} zipFile
 * @param {string} [type="string"] - base64, binarystring, array, uint8array, arraybuffer, blob, nodebuffer
 * @returns
 */
export default function unzipFile(zipFile, type = "string") {
  return new Promise((resolve, reject) => {
    JSZip.loadAsync(zipFile).then(zip => {
      const entries = Object.keys(zip.files).map(function (name) {
        return zip.files[name];
      }).filter(item => !item.dir); // filter directory

      const listOfPromises = entries.map(function (entry) {
        // default type - string
        return entry.async(type).then(function (content) {
          // bind the two together to be able to match the name and the content in the last step
          return [entry.name, content];
        });
      });

      const promiseOfList = Promise.all(listOfPromises);

      promiseOfList.then(function (list) {
        // list is a list of [name, content]
        const result = list.reduce(function (accumulator, current) {
          const currentName = current[0];
          const currentValue = current[1];
          accumulator[currentName] = currentValue;
          return accumulator;
        }, {});
        resolve(result);
      });
    }, error => {
      resolve(zipFile);
      console.log(error)
    })
  })
}

/**
 * 
 * URL to Promise
 * 2020/05/13
 * 
 * example:     
 * const token = localStorage.getItem('token');
 * const url = `/api/v3/backend/log/all?&access_token=${token}`;
 *
 * @export
 * @param {*} url URL containing token
 * @returns
 */
export function urlToPromise(url) {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}