import React, { Component } from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import { Button } from "antd";
import Panel from "../../../components/Panel";
import { SetupInputGroupItem } from "../AMIModel/SetupComponents/SetupInputGroup";
import { getSettingDataList } from "../../../services/Andes_v2/simulation";
import { updateHSPICEConfig } from "../store/channel/action";
import libraryConstructor from "../../../services/Andes_v2/library/libraryConstructor";
import { CPHY_EYE_MASK, USER_DEFINED_NETLIST } from "../../../constants/libraryConstants";

class SettingPanel extends Component {
  constructor() {
    super();
    this.state = {
      settingInfo: {},
      eyeMaskList: libraryConstructor.getLibraryValues(CPHY_EYE_MASK),
      userDefinedNetlistList: libraryConstructor.getLibraryValues(USER_DEFINED_NETLIST),
    };
    this.dialogRoot = document.getElementById("root");
  }

  componentDidMount() {
    const { hspiceConfig = {} } = this.props;
    this.setState({
      settingInfo: {
        symbolRate: hspiceConfig.symbolRate,
        initialDelay: hspiceConfig.initialDelay,
        timeStep: hspiceConfig.timeStep,
        cycles: hspiceConfig.cycles,
        hspiceCores: hspiceConfig.hspiceCores,
        eyeMask: hspiceConfig.eyeMask,
        userDefinedNetlist: hspiceConfig.userDefinedNetlist,
        testMode: hspiceConfig.testMode
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { libraryStatus } = this.props;
    if (prevProps.libraryStatus !== libraryStatus) {
      this.setState({
        eyeMaskList: libraryConstructor.getLibraryValues(CPHY_EYE_MASK),
        userDefinedNetlistList: libraryConstructor.getLibraryValues(USER_DEFINED_NETLIST),
      })
    }
  }

  inputChange = (type, value) => {
    const { settingInfo } = this.state;
    let _value = value;
    if (['eyeMask', 'userDefinedNetlist'].includes(type)) {
      const libraryType = type === 'eyeMask' ? CPHY_EYE_MASK : USER_DEFINED_NETLIST;
      const libraryInfo = libraryConstructor.getLibrary(libraryType, value) || {};
      _value = {
        libraryId: value,
        fileName: libraryInfo.name,
      };
    }
    this.setState({
      settingInfo: {
        ...settingInfo,
        [type]: _value,
      },
    });
  };

  closeModal = () => {
    const { hspiceConfig } = this.props;
    const { settingInfo } = this.state;
    this.props._updateHSPICEConfig({
      ...hspiceConfig,
      ...settingInfo,
    });
    this.props.closePanel();
  };

  clickSimulate = () => {
    const { settingInfo } = this.state;
    const { channelId, channelLoading, hspiceConfig } = this.props;
    if (channelLoading) return;
    this.props._updateHSPICEConfig(
      {
        ...hspiceConfig,
        ...settingInfo,
      },
      channelId
    );
    this.props.closePanel();
  };

  footerRender = () => {
    return <div className="andes-v2-hspice-simulation-setting-button">
      <Button type="primary" size="small" onClick={() => this.clickSimulate()}>
        Simulate
      </Button>
    </div>
  }

  render() {
    const { settingInfo, eyeMaskList, userDefinedNetlistList } = this.state;
    const content = (
      <Panel
        className="component-CMC-model-panel"
        position="panel-center-left"
        title="HSPICE Simulation"
        zIndex={2000}
        onCancel={() => this.closeModal()}
        width={600}
        draggable
        minHeight={200}
        footer={this.footerRender()}
      >
        <div className="andes-v2-hspice-simulation-setting-model-content">
          <SetupInputGroupItem
            dataList={getSettingDataList(settingInfo, eyeMaskList, userDefinedNetlistList)}
            onChange={this.inputChange}
          />
        </div>
      </Panel>
    );
    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const {
    channel: {
      channelInfo: { hspiceConfig },
      channelId
    },
    library: { libraryStatus }
  } = state.AndesV2Reducer;

  return {
    hspiceConfig,
    channelId,
    libraryStatus
  };
};

const mapDispatch = (dispatch) => ({
  _updateHSPICEConfig(hspiceConfig, id) {
    dispatch(updateHSPICEConfig(hspiceConfig, id));
  },
});

export default connect(
  mapState,
  mapDispatch
)(SettingPanel);
