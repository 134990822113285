import http from "../http";

/**
 * get sweep list by channelId
 * 2024.06.11
 * @param {string} channelId
 * @return
 */
export function getAndesV2SweepListByChannelId(channelId) {
  return http.get(`/sweep-doe/list/channelId?channelId=${channelId}`);
}

/** 
 * delete sweep
 * 2024.06.11
 * @param {string} sweepId
 * @return
 */
export function deleteAndesV2SweepBySweepId(sweepId) {
  return http.delete(`/sweep-doe/variable/delete?id=${sweepId}`)
}

/** 
 * get sweep config by id
 * 2024.06.11
 * @param {string} channelId
 * @return
 */
export function getAndesV2SweepConfigs(sweepId) {
  return http.get(`/sweep-doe/variable/find?sweepId=${sweepId}`)
}

/** 
 * create sweep config
 * 2024.06.11
 * @param {object} content
 * @return
 */
export function createAndesV2SweepConfigs(content) {
  return http.post(`/sweep-doe/variable/create`, content);
}

/** 
 * update sweep config or rename sweep
 * 2024.06.11
 * @param {object} content
 * @return
 */
export function updateAndesV2SweepConfigs(content) {
  return http.put(`/sweep-doe/variable/edit`, content);
}

/** 
 * get sweep experiment by id
 * 2024.06.11
 * @param {Object} content
 * @return
 */
export function createAndesV2SweepContent(content) {
  return http.post(`/sweep-doe/experiment/create`, content)
}

/** 
 * update sweep experiment
 * 2024.06.11
 * @param {object} content // [{"id": "string","name": "string","params": [{}]}]
 * @param {string} sweepId
 * @return
 */
export function updateAndesV2SweepContent({ content, sweepId }) {
  return http.put(`/sweep-doe/experiment/edit?sweepId=${sweepId}`, content);
}

/** 
 * get sweep experiment by sweepId
 * 2024.06.11
 * @param {string} sweepId
 * @return
 */
export function getAndesV2ExperimentsBySweepId(sweepId) {
  return http.get(`/sweep-doe/experiment/find/sweepId?sweepId=${sweepId}`);
}

/** 
 * get sweep variables by sweepId
 * 2024.06.11
 * @param {string} sweepId
 * @return
 */
export function getAndesV2VariablesBySweepId(sweepId) {
  return http.get(`/sweep-doe/select-variable/find?id=${sweepId}`);
}

/** 
 * update sweep variables by sweepId
 * 2024.06.11
 * @param {string} sweepId
 * @param {content} content // [{}]
 * @return
 */
export function updateAndesV2VariablesBySweepId({ sweepId, content }) {
  return http.put(`/sweep-doe/select-variable/edit?id=${sweepId}`, content);
}

/** 
 * delete sweep experiment
 * 2024.06.11
 * @param {string} id
 * @return
 */
export function deleteAndesV2SweepContentById(id) {
  return http.delete(`/sweep-doe/experiment/delete?experimentId=${id}`);
}

/** 
 * get netList by channelId
 * 2024.06.11
 * @param {string} channelId
 * @return
 */
export function getAndesV2NetListByChannelId(channelId) {
  return http.get(`/sweep-doe/variable/nets/andes?channelId=${channelId}`);
}

/** 
 * start sweep extraction by experimentIds
 * 2024.06.11
 * @param {Array} experimentIds //[['string'], ...]]
 * @return
 */
export function startAndesV2SweepExtraction(experimentIds) {
  return http.post(`/andes/simulation/channel/extraction/sweep-doe/start`, experimentIds);
}

/** 
 * get Experiment extraction log
 * 2024.06.11
 * @param {string} verificationId
 * @return
 */
export function getAndesV2ExperimentExtractionLog(verificationId) {
  return http.get(`/andes/channel/sweep/extraction/log?verificationId=${verificationId}`);
}

/** 
 * get sweep id by project id
 * 2024.06.11
 * @param {string} projectId
 * @return
 */
export function getAndesV2SweepListByProjectId(projectId) {
  return http.get(`/andes/channel/structure?projectId=${projectId}`);
}

