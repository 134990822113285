import React, { Component } from 'react';
import { Tag } from 'antd';
import './index.css';

const { CheckableTag } = Tag;
let count = 0;
class CheckTag extends Component {

  handleChange = (name) => {
    const { keyCode, changeSelectComps, key } = this.props.info;
    count += 1;
    let { comps } = this.props.info;
    let selectNames = [];
    comps.map(item => {
      if (item.selected) {
        selectNames.push(item.name)
      }
      return null;
    });
    setTimeout(() => {
      if (count === 1 && (!keyCode || keyCode !== key)) {
        const { select, pcbOpened, record } = this.props.info;
        if (selectNames && selectNames.length > 0 && selectNames.includes(name)) {
          comps.forEach(item => {

            if (item.name === name && item.selected) {
              delete item.selected
            }
          });
          changeSelectComps({ comps })
          return;
        }
        if (!pcbOpened) {
          return;
        }
        select && select(record, [name]);
      } else if (count === 2) {
      } else if (count === 1 && keyCode && keyCode === key) {
        comps.forEach(item => {

          if (item.name === name) {
            if (item.selected) {
              item.selected = false;
            } else {
              item.selected = true;
            }
          }
        });
        changeSelectComps({ comps })
      }
      count = 0;
    }, 500);
  };

  doubleClick = (e, checked, name, disableDoubleClick) => {
    e.stopPropagation();
    if (disableDoubleClick) {
      return;
    }
    const { changeChecked, record, changeSelectComps } = this.props.info;
    if (record.usage === 'Removed') {
      return;
    }
    let { comps } = this.props.info;
    let selectNames = [];
    comps.map(item => {
      if (item.selected) {
        selectNames.push(item.name)
      }
      return null;
    });
    if (selectNames && selectNames.length > 0 && selectNames.includes(name)) {
      comps.forEach(item => {

        if (item.name === name && item.selected) {
          delete item.selected
        }
      });
      changeSelectComps({ comps })
      return;
    }
    comps.forEach(item => {

      if (item.name === name) {
        item.checked = checked;
      }
    });
    changeChecked(name, checked, record);
  }

  render() {
    const { checked, name, selected, disableDoubleClick } = this.props.info;
    const props = {
      ...this.props,
      info: null
    }
    let className = selected ? 'un-select-node selected-node' : 'un-select-node';
    return (
      <CheckableTag {...props} className={className} checked={checked} onChange={() => this.handleChange(name)
      } onDoubleClick={(e) => this.doubleClick(e, !checked, name, disableDoubleClick)} />
    );
  }
}

export default CheckTag;