import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { EyeOutlined, QuestionCircleOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Tabs, Spin } from 'antd';
import { getPanelMaxHeight, getPanelMaxWidth } from '../../services/helper/panelSizeHelper';
import { getPanelWidth } from '../../services/helper/panelSizeHelper';
import Table from '@/components/EditableTable';
import {
  getReferenceText,
  PIN_GROUP,
  SINGLE_PIN,
  NEARBY_PINS,
  GAP,
  CIRCUIT,
  PIN_PER_REF_NET,
  getPortsTableData,
  getReferencePins,
  searchNetAndPins,
  updatePortSetupsByType,
  getPortReferencePin
} from '../../services/ExtractionPortsHelper';
import LayoutData from '../../services/data/LayoutData';
import { getPortData } from '../../services/helper/portCanvasHelper';
import makeCancelable from '../../services/api/makeCancelable';
import PortCanvas from '../PortSetup/portCanvas';
import '@/publicCss/aurora.css';
import './index.css';
import { PORT_REFERENCE_PIN } from '../../services/ExtractionPortsHelper/portsSetup';
import RefPinEditHelp from './portRefPinEditHelp';
import { ROCKY } from '../../constants/pageType';

const columns = [{
  title: 'Index',
  dataIndex: 'portIndex',
  key: 'portIndex',
  width: 80
}, {
  title: 'Component - Pin',
  dataIndex: 'pin',
  key: 'pin',
  width: 140
},
{
  title: 'Reference Pin',
  dataIndex: 'reference',
  key: 'reference'
}];

class EditPortPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 800,
      maxHeight: 700,
      components: [],
      editComponent: null,
      designUpdate: false,
      compInfo: {},
      expandRefNet: [],
      updateSelected: false,
      showAll: false,
      loading: true,
      helpVisible: false
    }
    this.dialogRoot = document.getElementById('root');

    columns[0].title = () => {
      const { port_setups, isSinglePositivePin } = this.props;
      return (
        <div>Index
          {port_setups && port_setups.length && !isSinglePositivePin ? <EyeOutlined
            style={{ color: this.state.showAll ? "#40a9ff" : "" }}
            className="port-setup-edit-eye-icon"
            title='Display all port groups'
            onClick={(e) => this.showAllGroups(e)} /> : null}
        </div>
      );
    }

    columns[0].render = (text, record) => {
      return <div
        className='port-setup-edit-port-index'
        onClick={() => this.updateEditPort(record)}
      >{text}</div>
    }

    columns[1].render = (text, record) => {
      return <span
        className='port-setup-edit-port-pin'
        onClick={() => this.updateEditPort(record)}
      /*  onClick={() => this.showPins(record.pin, "SignalNet", true)} */
      >{record.component} - {record.pin}</span>
    }

    columns[2].render = (data, record) => {
      const { generateRefNets } = this.props;
      const text = getReferenceText(data, record, generateRefNets);
      return <div
        className='extraction-ports-table-reference'
        title={text}
      >{text}</div>
    }

    columns[2].onCell = (record) => {
      const { editComponent, compInfo } = this.state;
      const { setupList, referenceNets } = this.props;
      const findSetup = setupList.find(item => item.component === editComponent);
      if (!findSetup || !findSetup.setup) {
        return { edit: false }
      }
      const referencePins = getReferencePins({ compInfo, referenceNets, editComponent });


      //SINGLE_PIN, NEARBY_PINS, PIN_PER_REF_NET
      if (findSetup.setup.referenceType === SINGLE_PIN
        || (findSetup.setup.referenceType === PIN_PER_REF_NET && referenceNets.length === 1)) {
        const referencePin = getPortReferencePin(record, findSetup.setup.referenceType, referenceNets);
        return {
          edit: "select",
          record: { ...record, referencePin },
          options: referencePins,
          dataIndex: "referencePin",
          dropdownMenuClassName: "ports-setup-edit-reference-dropdown",
          getPopupContainer: document.getElementById('root'),
          handleSave: (record) => { this.editReferencePin(record, findSetup.setup.referenceType) },
        }
      } else if (findSetup.setup.referenceType === NEARBY_PINS) {
        const selectedReferencePins = getPortReferencePin(record, findSetup.setup.referenceType, referenceNets);
        return {
          edit: "select",
          selectMode: "multiple",
          record: { ...record, referencePins: selectedReferencePins },
          options: referencePins,
          dataIndex: "referencePins",
          dropdownMenuClassName: "ports-setup-edit-reference-dropdown",
          getPopupContainer: document.getElementById('root'),
          handleSave: (record) => { this.editReferencePin(record, NEARBY_PINS) },
        }
      }
      else if (findSetup.setup.referenceType === PIN_PER_REF_NET && referenceNets.length > 1) {

        const selectedReferencePins = getPortReferencePin(record, findSetup.setup.referenceType, referenceNets);
        return {
          edit: "select",
          netSelect: true,
          selectMode: "multiple",
          record: { ...record, referencePins: selectedReferencePins },
          options: referencePins,
          dataIndex: "referencePins",
          dropdownMenuClassName: "ports-setup-edit-reference-dropdown",
          getPopupContainer: document.getElementById('root'),
          onSearch: this.searchPin,
          clearSelectStatus: () => this.clearSelectStatus(),
          dropdownRender: (form, clearInputValue) => this.dropdownRender({ form, clearInputValue, record, selectedReferencePins, compInfo, referenceNets, editComponent }),
          handleSave: (record) => { this.editReferencePin(record, PIN_PER_REF_NET) },
        }
      }
      return {
        edit: false
      }
    }
  }

  showPins = (pin, netType) => {
    this.canvasRef.selectPins([pin], true, netType);
  }

  showAllGroups = (e, _port_setups) => {
    e && e.stopPropagation();
    const { port_setups } = this.props;
    const { editComponent, showAll } = this.state;
    if (showAll && !_port_setups) {
      //clear
      this.canvasRef.drawGroup([], false);
      this.setState({
        showAll: false
      })
      return;
    }
    const ports = _port_setups ? _port_setups : port_setups;
    const portShowList = ports.filter(item => item.positive && item.positive.component === editComponent).map(item => {
      const { positive, negative } = item;
      return {
        positivePorts: { [editComponent]: [positive.pin] },
        negativePorts: { [editComponent]: negative.type === PORT_REFERENCE_PIN ? [negative.pin] : [...negative.pins] },
        showLine: true
      }
    })

    if (portShowList.length > 0) {
      this.canvasRef.drawGroup(portShowList, false)
    }
    if (!_port_setups) {
      this.setState({
        showAll: true
      })
    }
  }

  showGroup = (record) => {
    const { editComponent, showAll } = this.state;
    const { pin, reference } = record;
    const portShowList = [{
      positivePorts: { [editComponent]: [pin] },
      negativePorts: { [editComponent]: reference.type === PORT_REFERENCE_PIN ? reference && reference.pin ? [reference.pin] : [] : reference && reference.pins && reference.pins.length ? [...reference.pins] : [] },
      showLine: true,
      showText: false
    }]
    this.canvasRef.drawGroup(portShowList, false);
    if (showAll) {
      this.setState({
        showAll: false
      })
    }
  }

  updateEditPort = (record) => {
    const { component, pin, reference } = record || {}
    this.setState({
      editingPort: { component, pin, reference },
      updateSelected: true
    }, () => {
      this.showGroup(record);
    })
  }

  searchPin = (value) => {
    this.setState({
      searchValue: value
    })
  }

  clearSelectStatus = () => {
    this.setState({
      searchValue: "",
      expandRefNet: [...this.props.referenceNets]
    })
  }

  dropdownRender = ({
    form,
    record,
    selectedReferencePins,
    compInfo,
    referenceNets,
    editComponent
  }) => {
    const referencePinsObj = compInfo && compInfo[editComponent] ? compInfo[editComponent].referencePins || {} : {};
    const { expandRefNet, searchValue } = this.state;
    const { nets, pinsObj } = searchNetAndPins(searchValue, referenceNets, referencePinsObj);
    return (
      <div className='port-setup-select-pin-main'
        onMouseDown={(e) => {
          e.preventDefault()
          return false
        }}
      >
        <ul className='port-setup-select-pin-ul'>
          {nets.map(net => <li
            className='port-setup-select-pin-net-li'
            key={net}
            onClick={(e) => { this.updateExpandRefNets(e, net) }}
          >
            {expandRefNet.includes(net) ? <DownOutlined className='port-setup-select-pin-icon' /> : <RightOutlined className='port-setup-select-pin-icon' />}
            <span>{net}</span>
            {expandRefNet.includes(net) && pinsObj[net] && pinsObj[net].length ?
              pinsObj[net].map(item =>
                <div
                  className={selectedReferencePins.includes(`${item}::${net}`) ? "port-setup-select-pin-selected port-setup-select-pin-li" : 'port-setup-select-pin-li'}
                  key={item}
                  onClick={(e) => { this.selectPin({ e, net, pin: item, record, form }) }}
                >{item}</div>
              ) : null}
          </li>)}
        </ul>
      </div>
    );
  }

  selectPin = ({ e, net, pin, record, form }) => {
    e && e.stopPropagation();
    this.editReferencePin({ ...record, referencePin: `${pin}::${net}`, }, PIN_PER_REF_NET, true);
    form && form.resetFields();
  }

  updateExpandRefNets = (e, net) => {
    e && e.stopPropagation();
    let _expandRefNet = [...this.state.expandRefNet];
    if (_expandRefNet.includes(net)) {
      _expandRefNet = _expandRefNet.filter(item => item !== net);
    } else {
      _expandRefNet.push(net);
    }
    this.setState({
      expandRefNet: _expandRefNet
    })
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();

    this.setState({
      maxWidth: getPanelMaxWidth(offset, 800),
      maxHeight: getPanelMaxHeight(offset, 700),
    })
  }

  getDefaultSetting = () => {
    const { setupList, referenceNets, isSinglePositivePin } = this.props;
    const components = setupList.filter(item => [PIN_GROUP, GAP, CIRCUIT].includes(item.setup.portType) && [SINGLE_PIN, NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)).map(item => item.component);
    const _components = isSinglePositivePin ? [isSinglePositivePin.component] : components;
    this.setState({
      components: _components,
      editComponent: _components[0],
      expandRefNet: [...referenceNets]
    });
    this.getLayout(_components)
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getDefaultSetting();
  }

  componentDidUpdate = (prevProps) => {
    const { isSinglePositivePin, editVisible, tableCellVisible } = this.props;
    if (
      (
        isSinglePositivePin &&
        (!prevProps.isSinglePositivePin
          || isSinglePositivePin.component !== prevProps.isSinglePositivePin.component
          || isSinglePositivePin.pin !== prevProps.isSinglePositivePin.pin)
      )
      || (
        prevProps.isSinglePositivePin && !isSinglePositivePin
      )
    ) {

      this.getDefaultSetting();
    }

    //current panel is single pin panel, then open all pins panel
    if (isSinglePositivePin && editVisible && editVisible !== prevProps.editVisible && this.props.save) {
      this.props.save();
      this.props.updateTableCell(null, false)
    }
    //current panel is all pins panel,then open single panel
    if (!isSinglePositivePin && tableCellVisible && tableCellVisible !== prevProps.tableCellVisible && this.props.closeEditRefPin) {
      this.props.closeEditRefPin();
    }
  }

  getLayout = async (components) => {
    setTimeout(async () => {

      const { referenceNets, designId, port_setups, isSinglePositivePin } = this.props;
      const loadLayoutDBPromise = LayoutData.LoadLayoutDB(designId, true);
      this.cancelableLoadLayout = makeCancelable(loadLayoutDBPromise);
      try {
        await this.cancelableLoadLayout.promise;
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false
        })
        return;
      }
      const _DesignData = LayoutData.getLayout(designId);
      let compInfo = {};
      for (let comp of components) {
        const signalNets = port_setups.filter(item => item.positive && item.positive.component === comp && item.info).map(item => item.info.net).filter(item => !!item);
        //designData, comp, powerNets,referenceNets,signalNets
        const filterData = getPortData(_DesignData, comp, [], referenceNets, signalNets);
        const { layerList, referencePins, signalPins, data } = filterData;
        compInfo[comp] = {
          referencePins,
          signalPins,
          layerList,
          DesignData: data
        }
      }

      this.setState({
        designUpdate: true,
        compInfo,
        loading: false
      }, () => {
        const findPin = port_setups.find(item => item.positive && item.positive.component === components[0] && (!isSinglePositivePin || isSinglePositivePin.pin === item.positive.pin));
        if (findPin && findPin.positive.pin) {
          const port = getPortsTableData([findPin])[0];
          this.updateEditPort(port);
        }
      })
    }, 100)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  closeModal = () => {
    this.props.closeEditRefPin();
    this.props.updateTableCell(null, false)
    if (this.props.save) {
      this.props.save()
    }
  }

  editReferencePin = (record, pinType, save) => {
    const { port_setups } = this.props;
    const { showAll, editingPort } = this.state;
    let _port_setups = [...port_setups];
    const index = _port_setups.findIndex(item => item.positive && item.positive.component === record.component
      && item.positive.pin === record.pin);
    if (index < 0) {
      return;
    }
    _port_setups = updatePortSetupsByType({ record, index, _port_setups, pinType, save });
    this.props.updatePortSetups(_port_setups);

    const findPort = editingPort ? _port_setups.find(item => item.positive && item.positive.component === editingPort.component && item.positive.pin === editingPort.pin) : null;

    if (findPort) {
      const port = getPortsTableData([findPort])[0];
      this.updateEditPort(port)
    }

    if (showAll) {
      this.showAllGroups(null, _port_setups);
      return;
    }

  }

  getComponentSetupTable = (comp, dataList, editingPort, isSinglePositivePin) => {
    const _dataList = isSinglePositivePin ? dataList.filter(item => item.component && isSinglePositivePin.component === item.component && item.pin === isSinglePositivePin.pin) : dataList.filter(item => item.component && comp === item.component);
    return <Table
      rowKey={record => record.pin}
      columns={columns}
      tabLayout="fixed"
      size="small"
      dataSource={_dataList || []}
      className='extraction-ports-table'
      onRow={(record) => {
        return {
          className: editingPort && record.component === editingPort.component && record.pin === editingPort.pin ? "port-setup-editing-port-row" : ""
        }
      }}
    />
  }

  changeCompTab = (comp) => {
    this.setState({
      editComponent: comp
    }, () => {
      //re load port canvas by component
      this.updateCurrentState("designUpdate", true, this.canvasRef.updateSelectedList);
      const findPin = this.props.port_setups.find(item => item.positive && item.positive.component === comp);
      if (findPin && findPin.positive.pin) {
        const port = getPortsTableData([findPin])[0];
        this.updateEditPort(port, true);
      }
    })
  }

  updateCurrentState = (key, value, afterFn) => {
    this.setState({
      [key]: value
    }, () => {
      afterFn && afterFn()
    })
  }

  savePortSetupPin = (refPinObj) => {
    const { editingPort, compInfo, editComponent } = this.state;
    const { setupList } = this.props;
    const findSetup = setupList.find(item => item.component === editComponent) || {};
    const pinType = findSetup.setup ? findSetup.setup.referenceType : null;
    const referencePins = compInfo[editComponent] ? compInfo[editComponent].referencePins || [] : [];
    if (!pinType) {
      return;
    }
    const findRefNet = Object.keys(referencePins).find(net => referencePins[net].includes(refPinObj.pinNumber));
    this.editReferencePin({ ...editingPort, referencePin: `${refPinObj.pinNumber}::${findRefNet}`, }, pinType, true, true)
  }

  deletePortSetupPin = (refPinObj) => {
    const { editingPort, compInfo, editComponent } = this.state;
    const { setupList } = this.props;
    const findSetup = setupList.find(item => item.component === editComponent) || {};
    const pinType = findSetup.setup ? findSetup.setup.referenceType : null;
    const referencePins = compInfo[editComponent] ? compInfo[editComponent].referencePins || [] : [];
    if (!pinType) {
      return;
    }
    const findRefNet = Object.keys(referencePins).find(net => referencePins[net].includes(refPinObj.pinNumber));
    this.editReferencePin({ ...editingPort, deletedReferencePin: `${refPinObj.pinNumber}::${findRefNet}`, }, pinType, true, true)
  }

  getEditPort = () => {
    const { editingPort } = this.state;
    return !editingPort ?
      { references: [] } : {
        component: editingPort.component,
        pin: editingPort.pin,
        references: editingPort.reference ?
          editingPort.reference.type === PORT_REFERENCE_PIN ? [{ component: editingPort.reference.component, pin: editingPort.reference.pin, net: editingPort.reference.NET }] : [...(editingPort.reference.PINS_INFO || [])]
          : []
      };
  }

  changeHelpVisible = (visible) => {
    this.setState({
      helpVisible: visible
    })
  }

  render() {
    const { maxWidth, maxHeight, editComponent, components, designUpdate, compInfo, editingPort, updateSelected, loading, helpVisible } = this.state;
    const { port_setups, referenceNets, isSinglePositivePin } = this.props;
    const dataList = getPortsTableData(port_setups);
    const layerList = compInfo[editComponent] ? compInfo[editComponent].layerList || [] : [];
    const referencePins = compInfo[editComponent] ? compInfo[editComponent].referencePins || [] : [];
    const signalPins = compInfo[editComponent] ? compInfo[editComponent].signalPins || [] : [];
    const DesignData = compInfo[editComponent] ? compInfo[editComponent].DesignData : null;
    const selectedBoxList = editingPort ? [{ editType: "Add", portInfo: `${editingPort.component} - ${editingPort.pin}` }] : [];
    const deleteNegativePinsList = editingPort ? [{ editType: "Remove", portInfo: `${editingPort.component} - ${editingPort.pin}` }] : [];
    const content = (
      <Panel
        className='extraction-ports-panel panel-x-scroll-hidden'
        title={<div className='extraction-ports-title'>
          <span>Update Reference Pin{isSinglePositivePin ? ` ${isSinglePositivePin.component} - ${isSinglePositivePin.pin}` : ""}</span>
          <QuestionCircleOutlined
            className='port-setup-help-icon'
            title="Help"
            onClick={() => this.changeHelpVisible(true)} />
        </div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 1300 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={100}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='port-setup-reference-edit-main'>
          <div className='port-setup-reference-edit-table'>
            {isSinglePositivePin ?
              this.getComponentSetupTable(null, dataList, editingPort, isSinglePositivePin)
              : < Tabs
                tabPosition="top"
                className="aurora-extraction-tabs"
                activeKey={editComponent}
                type="card"
                onChange={this.changeCompTab}
                items={components.map(comp => ({ key: comp, label: comp, children: this.getComponentSetupTable(comp, dataList, editingPort) }))}
              />
            }
          </div>
          <div className='port-setup-reference-edit-canvas'>
            <Spin spinning={loading} tip={loading ? `Loading...` : ''}>
              <PortCanvas
                onRef={node => this.canvasRef = node}
                DesignData={DesignData}
                designUpdate={designUpdate}
                layerList={layerList}
                powerPins={[]}
                signalPins={signalPins}
                referencePins={referencePins}
                chip={editComponent}
                PowerNets={[]}
                ReferenceNets={referenceNets}
                selectedBoxList={selectedBoxList}
                updateSelected={updateSelected}
                editingPort={this.getEditPort()}
                deleteNegativePinsList={deleteNegativePinsList}
                savePortSetupPin={this.savePortSetupPin}
                deletePortSetupPin={this.deletePortSetupPin}
                updateParentState={this.updateCurrentState}
                showPinList={[]}
              />
            </Spin>
          </div>
          {helpVisible ? <RefPinEditHelp
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            closeHelp={() => this.changeHelpVisible(false)}
          /> : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default EditPortPanel;