class Projects {
  constructor() {
    this.projects = new Map();//id:projectId,value:{projectObj}
  }

  setProject = (projectId, data) => {
    if (!projectId) {
      return
    }
    this.projects.set(projectId, data);
  }

  setProjects = (projects) => {
    for (let item of (projects || [])) {
      this.projects.set(item.id, item);
    }
  }

  get = (id) => {
    return this.projects.get(id);
  }

  getProjects = () => {
    return Array.from(this.projects.values());
  }

  clearAll = () => {
    this.projects = new Map();
  }
}

const projects = new Projects();
export default projects;