import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { getTemplateSpiceFile } from '@/services/api/library';
import { downloadFile } from '@/services/helper/downloadHelper'

let template = null;
class TemplatePanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileContent: null
    }
    this.width = this.getDefaultWidth();
  }

  getDefaultWidth = () => {
    const offset = this.props.root.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 600;
    const _width = width * 0.8;
    return _width > 1000 ? 1000 : _width;
  }

  componentDidMount() {
    if (!template) {
      getTemplateSpiceFile().then(res => {
        template = res;
        this.setState({
          fileContent: res
        })
      })
    } else {
      this.setState({
        fileContent: template
      })
    }
  }

  downloadTemplate = (e) => {
    e.target.blur();
    e.preventDefault();
    e.stopPropagation();
    const filename = 'spice_model.sp';
    const { fileContent } = this.state;
    downloadFile(fileContent, filename)
  }

  render() {
    const { closeModal } = this.props;
    const { fileContent } = this.state;

    const content = (
      <Panel
        position='panel-center-left'
        title={<div>Information
          <Tooltip
            title={'Download'}
            placement={"right"}
            overlayClassName='IC-icon-tooltip'
          >
            <DownloadOutlined
              onClick={this.downloadTemplate}
              className='IC-file-download'
              style={{ fontSize: 16 }} />
          </Tooltip>
        </div>}
        onCancel={closeModal}
        zIndex={2000}
        width={this.width}
        draggable
        className='IC-file-panel'
        id='template-file-view'
        minWidth={200}
        minHeight={200}
      >
        <div >
          <pre style={{ margin: 16, overflow: 'auto' }} className='IC-file-content'>
            {fileContent}
          </pre>
        </div>
      </Panel>
    )
    return createPortal(content, this.props.root)
  }
}

export default TemplatePanel;