
import apiProcess from '../api/utility';
import { importProjectData, exportProjectData, getAllLibraryData } from '../api/importExport';
import { SUCCESS } from '@/constants/returnCode';

export function importProjectDataPromise(formData, product) {
  return new Promise((resolve, reject) => {
    importProjectData({ formData, product }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve({ status: 'fail', msg: res.data.msg });
      }
    }, error => {
      console.error(error);
      resolve({ status: 'fail', msg: "Import zip file failed!" });
    });
  })
}

export function exportProjectDataPromise(exportData, product) {
  const { libraryIds, projectIds, exportProjectParams } = exportData
  return new Promise((resolve, reject) => {
    if (!libraryIds.length && !projectIds.length && !exportProjectParams.length) {
      resolve(null);
      return;
    }

    exportProjectData({ product, libraryIds, projectIds, exportProjectParams }).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve({ data: res })
      } else {
        resolve({ data: null, msg: res && res.data && res.data.msg ? res.data.msg : "Unknown error." })
      }
    }, error => {
      console.error(error);
      resolve({ data: null, msg: error && error.response && error.response.data && error.response.data.msg ? error.response.data.msg : "API Error." });
    })
  })
}

export function getAllLibraryDataPromise(product) {
  return apiProcess(getAllLibraryData, { product })
}