import { getChannelPowerNets, getComponents } from "./channelContentHelper";
import { ChannelSignals, ChannelComponents, ChannelContent, ChannelCPHYSignals } from "./IntegratedChannel";
import { strDelimited } from "../../helper/split";
import { PCBNets, SignalsCPHYIdentification, SignalsGroups, SignalsIdentification, setSignalName } from "./signalsIdentification";
import ChannelIdentify from './channelReIdentification';
import { PACKAGE as PCB_PACKAGE } from '../../../constants/designType';
import { CPHY } from "../../PCBHelper/constants";

/* Pre layout */
/**
 * set default channel signals by pre layout selected signals
 * @param {array} preLayoutSignals pre layout signals list
 * @param {array} preLayoutSelectedSignals pre layout selected signals list
 * */
function getPreLayoutChannelSignals(preLayoutSignal_Groups, preLayoutSelectedGroups, channelType) {
  let signals = [];
  for (let groupItem of preLayoutSignal_Groups) {
    if (!preLayoutSelectedGroups.includes(groupItem.name)) {
      continue;
    }
    for (let item of groupItem.signals) {
      const signal = channelType === CPHY ? new ChannelCPHYSignals({ ...item, group: groupItem.name }) : new ChannelSignals({ ...item, group: groupItem.name })
      signals.push(signal);
    }
  }
  return signals;
}

/**
 * set default channel signals by pre layout selected signals
 * @param {array} preLayoutSignals pre layout signals list
 * @param {array} selectedSignals pre layout selected signals list
 * */
function getPreLayoutChannelComponents(preLayoutComponents, selectedSignals) {
  let components = [];
  for (let comp of preLayoutComponents) {
    /*     const pins = comp.pins.filter(item => selectedSignals.includes(item.signal));
        if (!pins.length) {
          continue;
        } */
    components.push(new ChannelComponents({
      name: comp.name,
      type: comp.type,
      part: comp.name,
      pins: comp.pins
    }));
  }
  return components;
}

/**
 * set default channel info (pre layout)
 * @param {object} preLayoutInfo { content: {signals,components, ... } }
 * */
function getDefaultPreLayoutChannelInfo(preLayoutInfo) {
  if (!preLayoutInfo || !preLayoutInfo.content) {
    return {};
  }
  const { signal_groups: preLayoutSignal_groups, components: preLayoutComponents, selectedGroups: preLayoutSelectedGroups } = preLayoutInfo.content;
  //get signals
  const signals = getPreLayoutChannelSignals(preLayoutSignal_groups, preLayoutSelectedGroups, preLayoutInfo.content.type);
  const selectedSignals = signals.map(item => item.name);
  //get components
  const components = getPreLayoutChannelComponents(preLayoutComponents, selectedSignals);

  return new ChannelContent({ type: "PreLayout", signals, components, selectedSignals });
}

/* Post layout */
/**
 * set default channel info (post layout)
 * @param {string} component selected component name
 * @param {array} netsList pcb nets list
 * @param {array} layers pcb layers
 * @param {string} designId designId
 * @param {string} type Serdes type (PCIE / HDMI /... )
 * */
function getDefaultPostLayoutChannelInfo({ component, netsList, layers, designId, type, advancedConfig, designType, signals: defaultSignals, cmcComponents: updatedCMCComponents }) {
  if (!netsList || !layers) {
    return new ChannelContent({
      type: "PostLayout",
      components: [],
      signals: [],
      selectedSignals: [],
      powerNets: [],
      designType
    });
  }
  const netNamePrefix = advancedConfig.netNamePrefix;
  let signals = [], cmcComponents = [];
  /*  if (designType === PCB_PACKAGE) {
     signals = defaultSignals || [];
     signals = setSignalName({ signals, type, designType, netNamePrefix });
     //todo re set signal name
   } else {
     const { signals: _signals, cmcComponents: _components } = getSignals({
       component,
       netsList,
       layers,
       designId,
       type,
       advancedConfig,
       designType
     });
     signals = _signals;
     cmcComponents = _components;
   } */
  signals = defaultSignals || [];
  if (designType === PCB_PACKAGE && type !== CPHY) {
    signals = setSignalName({ signals, type, designType, netNamePrefix });
  }
  cmcComponents = updatedCMCComponents;

  let components = getComponentsBySignals({
    signals,
    layers,
    netsList,
    designId,
    cmcComponents,
    component,
    type,
    designType
  });

  //get power nets and update components by rlc components
  const powerInfo = getChannelPowerNets({ components, powerNets: [], netsList, signals, designId, type });
  components = powerInfo.components;
  const powerNets = powerInfo.powerNets;
  //return content
  return new ChannelContent({
    type: "PostLayout",
    components: signals.length === 0 ? [] : components,
    signals,
    selectedSignals: signals.map(item => item.name),
    powerNets,
    designType
  });
}

function getSignals({
  component,
  netsList,
  designId,
  type,
  advancedConfig,
  designType,
  updatedCMCComponents
}) {
  const netNamePrefix = advancedConfig.netNamePrefix;
  const pcbNets = new PCBNets();
  //filter nets by serdes type , net name prefix, and component
  pcbNets.filterNetsByCompAndNetName({
    designId,
    pcbNetsList: netsList,
    compName: component ? component.name : null,
    type,
    netNamePrefix,
    designType,
    updatedCMCComponents
  });
  const filterNets = pcbNets.getFilterNets();

  if (type === CPHY) {
    const signalsCPHYIdentification = new SignalsCPHYIdentification();
    signalsCPHYIdentification.filterDifferentLineNets({
      nets: filterNets,
      type,
      advancedConfig
    });

    signalsCPHYIdentification.getSignalsInfo({
      advancedConfig,
      netNamePrefix
    })

    let { signals, cmcComponents } = signalsCPHYIdentification.getSignals();
    signals = setSignalName({ signals, type, designType, netNamePrefix })
    //sort signals by signal number
    const sortSignals = signals.sort((a, b) => Number(findSignalNumber(a.name)) - Number(findSignalNumber(b.name)))
    return { signals: sortSignals, cmcComponents };
  }

  //identifier signals by net name
  const signalsIdentification = new SignalsIdentification();
  signalsIdentification.filterPositiveAndNegativeNets({
    nets: filterNets,
    type,
    advancedConfig
  });
  const signalsInfo = signalsIdentification.getSignalsInfo({
    advancedConfig,
    netNamePrefix
  })

  //Group signals according to net name
  const signalsGroups = new SignalsGroups();
  signalsGroups.getGroupsBySignalPrefix({
    signals: signalsInfo,
    advancedConfig
  });

  let { signals, cmcComponents } = signalsGroups.getSignals({
    type,
    designType
  });
  signals = setSignalName({ signals, type, designType, netNamePrefix })
  //sort signals by signal number
  const sortSignals = signals.sort((a, b) => Number(findSignalNumber(a.name)) - Number(findSignalNumber(b.name)))

  return { signals: sortSignals, cmcComponents };
}

function getComponentsBySignals({
  signals,
  layers,
  netsList,
  designId,
  cmcComponents,
  component,
  type,
  designType
}) {
  //get components
  let components = [];
  for (let signal of signals) {
    //find component bt P_Nets and N_Nets
    const netList = type === CPHY ? [...signal.nets_A, ...signal.nets_B, ...signal.nets_C] : [...signal.nets_P, ...signal.nets_N]
    components = getComponents({
      components,
      signalName: signal.name,
      layers,
      netList,
      pcbNetsList: netsList,
      identify: true,
      designId,
      cmcComponents,
      selectComponent: component ? component.name : null,
      serdesType: type,
      designType
    });
  }
  return components;
}

function findSignalNumber(signalName) {
  //PCIe_LANE0_TX
  //PCIe_LANE0
  const laneIndex = signalName.indexOf("LANE");
  const splitStr = signalName.substring(laneIndex, signalName.length);
  const laneStr = strDelimited(splitStr, "_", { returnIndex: 0 });
  const number = laneStr.match(/[0-9]*/ig).filter(it => !!it);
  return number ? number[0] : "";
}

function updateComponentAndSignalsByCMC({
  components,
  signals,
  designId,
  netsList = [],
  modifiedComponents = [],
  serdesType
}) {
  const channelIdentify = new ChannelIdentify();
  channelIdentify.getDefaultSignals({ signals, components, designId, netsList, modifiedComponents, serdesType });
  const newSignals = channelIdentify.updateSignalsByComp();
  return { newSignals };
}

function getCreateChannelDefaultSignals({
  component,
  designId,
  netsList,
  layers,
  type,
  advancedConfig,
  designType,
  updatedCMCComponents
}) {
  const { signals, cmcComponents } = getSignals({
    component,
    netsList,
    layers,
    designId,
    type,
    advancedConfig,
    designType,
    updatedCMCComponents
  });
  return { signals, cmcComponents };
}

export {
  getDefaultPreLayoutChannelInfo,
  getDefaultPostLayoutChannelInfo,
  getPreLayoutChannelSignals,
  getPreLayoutChannelComponents,
  updateComponentAndSignalsByCMC,
  getCreateChannelDefaultSignals
}