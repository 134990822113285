import React, { Component, Fragment } from 'react';
import { Select, Spin, Tabs } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '../Panel';
import Classification from './classification';
import PassiveTable from './passiveTable';
import DoNotStuff from './doNotStuff';
import PkgClassification from './pkgClassification';
import { SIERRA, CASCADE } from '@/constants/pageType';
import designConstructor from '../../services/helper/designConstructor';
import { PACKAGE } from '../../constants/treeConstants';
import PowerNetsTable from './powerNetsTable';
import { PRE_LAYOUT } from '../../constants/designVendor';
import auroraDBJson from '../../services/Designs/auroraDbData';
import './index.css';

const Option = Select.Option;
const CLASSIFICATION = 'Classification', PASSIVEVALUE = 'Passive Values',
  PINMAP = 'Pin Map', DONOTSTUFF = 'Do Not Stuff',
  PKG_CLASSIFICATION = 'Package Classification', POWER_NETS = "Power Nets";
class ComponentsSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      pcbId: '',
      designType: 'pcb',
      width: 900,
      save: false,
      activeKey: CLASSIFICATION,
      updateStatus: false,
      status: false,
      prevInfo: {},
      isUpdateSetting: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleHeight);
    this.handleHeight();
    const { pcbId, designList = [] } = this.props;
    const _designList = designList.filter(item => item.vendor !== PRE_LAYOUT);
    let _pcbId = pcbId ? pcbId : _designList && _designList.length ? _designList[0].id : '';
    if (_pcbId) {
      const isPreLayout = designConstructor.isPreLayout(_pcbId);
      if (isPreLayout) {
        _pcbId = ''
      }
    }
    const type = designConstructor.getDesignType(_pcbId);
    this.setState({
      pcbId: _pcbId,
      designType: type,
      activeKey: this.getActiveKeyByType(type),
      loading: !_pcbId ? false : true
    }, () => {
      //Record the data before modification
      this.setPrevSettingInfo(_pcbId)
    })
  }

  componentDidUpdate(prevProps) {
    const { pcbId, designList = [], updateSettingStatus } = this.props;
    const reInit = updateSettingStatus && prevProps.updateSettingStatus !== updateSettingStatus;
    if (pcbId !== prevProps.pcbId || reInit) {
      const _designList = designList.filter(item => item.vendor !== PRE_LAYOUT);
      let _pcbId = pcbId ? pcbId : _designList && _designList.length ? _designList[0].id : '';
      if (_pcbId) {
        const isPreLayout = designConstructor.isPreLayout(_pcbId);
        if (isPreLayout) {
          _pcbId = ''
        }
      }
      const type = designConstructor.getDesignType(_pcbId);
      if (reInit) {
        console.log("Part library update components and nets")
      }
      this.setState({
        pcbId: _pcbId,
        designType: type,
        activeKey: this.getActiveKeyByType(type),
        isUpdateSetting: reInit ? true : false
      }, () => {
        //Record the data before modification
        this.setPrevSettingInfo(_pcbId)
        if (reInit && this.props._updateCompSettingsStatus) {
          this.props._updateCompSettingsStatus(null)
        }
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleHeight);
  }

  handleHeight = () => {
    const { height = 800, width = 1600 } = this.dialogRoot.getBoundingClientRect();
    this.setState({
      panelMaxHeight: height - 200,
      maxWidth: width * 0.8
    });
  }

  setDefaultData = async () => {
    const { pcbId } = this.state;
    const { settingStore, updateLibraryMenu, product } = this.props;
    if (pcbId) {
      const componentSetting = await settingStore.getSetting({ designId: pcbId, updateLibraryMenu });
      const compPrefixLib = componentSetting && componentSetting.compPrefixLib ? componentSetting.compPrefixLib : {}
      await auroraDBJson.getAuroraJson(pcbId, compPrefixLib, product);
    }
  }

  setPrevSettingInfo = async (designId) => {
    //classification
    const { settingStore, product, doNotStuffStore, tableStore } = this.props;
    if (product !== SIERRA) {
      return;
    }
    const compPrefixLib = await settingStore.getPrefixLib(designId);
    const compPinMap = await settingStore.getCompPinMap(designId);
    const doNotStuff = await doNotStuffStore.getDoNotStuff(designId);
    const passiveTable = await tableStore.getTableData(designId);
    const { prevInfo } = this.state;
    this.setState({
      prevInfo: {
        ...prevInfo,
        [designId]: {
          compPrefixLib: JSON.parse(JSON.stringify(compPrefixLib)),
          compPinMap: JSON.parse(JSON.stringify(compPinMap)),
          doNotStuff: JSON.parse(JSON.stringify(doNotStuff)),
          passiveTable: JSON.parse(JSON.stringify(passiveTable)),
        }
      }
    })
  }

  changePCBSelect = async (pcbId) => {
    this.setState({
      saving: true
    }, async () => {
      await this.saveKeyValue(this.state.activeKey, this.state.pcbId);
      const type = designConstructor.getDesignType(pcbId);
      this.setState({
        loading: true,
        pcbId,
        designType: type,
        saving: false,
        activeKey: this.getActiveKeyByType(type),
      }, () => {
        this.setPrevSettingInfo(pcbId)
      })
    })
  }

  getActiveKeyByType = (designType) => {
    const { activeKey } = this.state;
    if (designType === PACKAGE) {
      return PKG_CLASSIFICATION
    }

    if (activeKey === PKG_CLASSIFICATION) {
      return CLASSIFICATION
    }

    return activeKey
  }

  dragEvent = () => {
    const { activeKey } = this.state;
    if (activeKey === CLASSIFICATION) {
      this.classificationRef && this.classificationRef.dragBuckClose();
    }
  }

  closeModal = () => {
    this.setState({
      saving: true
    }, async () => {
      const { activeKey, prevInfo } = this.state;
      await this.saveKeyValue(activeKey);
      setTimeout(() => {
        this.props.closeModal(false);
        this.props.updateSetting(this.state.updateStatus, prevInfo);
      }, 1000)
    })
  }

  changeSave = (save) => {
    this.setState({
      save: save ? true : this.state.save
    })
  }

  changeLoading = (bool) => {
    this.setState({
      loading: bool
    })
  }

  updateVersion = (designId, version, save) => {
    /*  this.classificationRef && this.classificationRef.updateVersion(designId, version, save); */
    /*  this.passiveRef && this.passiveRef.updateVersion(designId, version); */
  }

  customPCB = () => {
    const { designList } = this.props;
    const { pcbId, error, designType } = this.state;
    const _designList = designList.filter(item => item.vendor !== PRE_LAYOUT);
    return <div className='component-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key="PCB">
      <span className='component-RLC-title'>{designType === PACKAGE ? 'Package' : 'PCB'}</span>
      <Select
        onChange={(value) => this.changePCBSelect(value)}
        value={pcbId}
        dropdownStyle={{ zIndex: 100000 }}
        showSearch={true}
        className='component-PMIC-item-content'
      >
        {_designList.map(item => <Option title={item.name} key={item.id} value={item.id}>{item.name}</Option>)}
      </Select>
    </div >
  }

  getTabContent = (tab) => {
    switch (tab) {
      case CLASSIFICATION:
        return this.classificationRender();
      case PASSIVEVALUE:
        return this.passiveValueRender();
      case DONOTSTUFF:
        return this.doNotStuffRender();
      case POWER_NETS:
        return this.powerNetsRender();
      default: return;
    }
  }

  changeTab = async (tab) => {
    const { activeKey } = this.state;
    this.setState({
      saving: true,
    }, async () => {
      await this.saveKeyValue(activeKey);
      this.setState({
        activeKey: tab,
        saving: false,
        width: [PASSIVEVALUE, POWER_NETS].includes(tab) ? 950 : 900
      })
    })

  }

  saveKeyValue = async (tab, prevPcbId) => {
    let _updateStatus = false;
    switch (tab) {
      case CLASSIFICATION:
        let _savePinMap = false;
        if (this.pinMapRef) {
          _savePinMap = await this.pinMapRef.savePinMap(true);
        }
        let savePinMapModel = false;
        if (_savePinMap === "saveModel") {
          _savePinMap = false;
          savePinMapModel = true;
        }
        _updateStatus = this.classificationRef && await this.classificationRef.saveCompPrefix(_savePinMap, savePinMapModel, prevPcbId);
        break;
      case PASSIVEVALUE:
        this.passiveRef && await this.passiveRef.saveTable();
        break;
      case DONOTSTUFF:
        _updateStatus = this.doNotStuffRef && await this.doNotStuffRef.saveNotStuff();
        break;
      case PKG_CLASSIFICATION:
        _updateStatus = this.pkgClassificationRef && await this.pkgClassificationRef.saveCompPrefix();
        break;
      case POWER_NETS:
        if (this.powerRef) {
          const _savePower = await this.powerRef.saveTable();
          _updateStatus = this.classificationRef && await this.classificationRef.saveCompPrefix(_savePower);
        }
        break;
      default: break;
    }
    if (_updateStatus) {
      this.setState({
        updateStatus: _updateStatus
      })
    }
    return null
  }

  updateComponentSettingStatus = () => {
    this.setState({
      status: !this.state.status
    })
  }

  updateUpdateSettingStatus = (isUpdateSetting) => {
    this.setState({
      isUpdateSetting
    })
  }

  classificationRender = () => {
    const { pcbId, activeKey, panelMaxHeight, maxWidth, isUpdateSetting } = this.state;
    const { projectId, settingStore, product, getMultiPortResPart,
      pinMapStore, getPinMap, PinMapPanel, libraryStatus, CompPinMap, libraryTreeInfo, updateSettingStatus, pinMapList,
      setPart, openPartPanel, partVisible, tableStore, checkedPinMap, checkbox } = this.props;
    const pinMapProps = {
      designId: pcbId,
      pinMapStore: pinMapStore,
      onRef: this.onPinMapRef,
      changeLoading: this.changeLoading,
      getPinMap: getPinMap,
      setPart: setPart,
      pinMapList: pinMapList,
      PinMapPanel: PinMapPanel,
      updateLibraryMenu: this.props.updateLibraryMenu,
      update: activeKey === PINMAP,
      changeSave: this.changeSave,
      libraryStatus: libraryStatus,
      updateVersion: this.updateVersion,
      settingStore: settingStore,
      product: product,
      projectId: projectId,
      maxHeight: panelMaxHeight,
      maxWidth: maxWidth,
      libraryTreeInfo: libraryTreeInfo,
      isUpdateSetting,
      updateSettingStatus,
      setDefaultData: this.setDefaultData,
      openPartPanel,
      partVisible,
      checkedPinMap,
      checkbox
    }
    return <Classification
      projectId={projectId}
      settingStore={settingStore}
      product={product}
      pcbId={pcbId}
      tableStore={tableStore}
      onRef={this.onClassificationRef}
      changeLoading={this.changeLoading}
      update={activeKey === CLASSIFICATION}
      changeSave={this.changeSave}
      panelStateRef={this.panelStateRef}
      updateLibraryMenu={this.props.updateLibraryMenu}
      getMultiPortResPart={getMultiPortResPart}
      updateComponentSettingStatus={this.updateComponentSettingStatus}
      pinMapProps={pinMapProps}
      CompPinMap={CompPinMap}
      pinMapRef={this.pinMapRef}
      isUpdateSetting={isUpdateSetting}
      updateUpdateSettingStatus={this.updateUpdateSettingStatus}
      setDefaultData={this.setDefaultData}
    />
  }

  onClassificationRef = (ref) => {
    this.classificationRef = ref;
  }

  passiveValueRender = () => {
    const { pcbId, activeKey } = this.state;
    const { tableStore, powerTrees, product, libraryTreeInfo, getLibraryList, getLibraryFileContent, parseSpiceModelSelector } = this.props;
    return <PassiveTable
      powerTrees={powerTrees}
      designId={pcbId}
      tableStore={tableStore}
      onRef={this.onPassiveRef}
      changeLoading={this.changeLoading}
      update={activeKey === PASSIVEVALUE}
      product={product}
      libraryTreeInfo={libraryTreeInfo}
      getLibraryFileContent={getLibraryFileContent}
      parseSpiceModelSelector={parseSpiceModelSelector}
      getLibraryList={getLibraryList}
      changeSave={this.changeSave}
      setDefaultData={this.setDefaultData}
    />
  }

  powerNetsRender = () => {
    const { pcbId, activeKey, panelMaxHeight } = this.state;
    const { powerStore, product, ReSearchNets, searchPowerNetsByKeywords } = this.props;
    return <PowerNetsTable
      designId={pcbId}
      powerStore={powerStore}
      onRef={this.onPowerRef}
      changeLoading={this.changeLoading}
      update={activeKey === POWER_NETS}
      product={product}
      panelMaxHeight={panelMaxHeight}
      changeSave={this.changeSave}
      ReSearchNets={ReSearchNets}
      searchPowerNetsByKeywords={searchPowerNetsByKeywords}
      setDefaultData={this.setDefaultData}
    />
  }

  onPowerRef = (ref) => {
    this.powerRef = ref;
  }

  onPassiveRef = (ref) => {
    this.passiveRef = ref;
  }

  onPinMapRef = (ref) => {
    this.pinMapRef = ref;
  }

  doNotStuffRender = () => {
    const { pcbId, activeKey } = this.state;
    const { doNotStuffStore, settingStore, tableStore } = this.props;
    return <DoNotStuff
      pcbId={pcbId}
      doNotStuffStore={doNotStuffStore}
      onRef={this.onNotStuff}
      changeLoading={this.changeLoading}
      update={activeKey === DONOTSTUFF}
      changeSave={this.changeSave}
      settingStore={settingStore}
      setDefaultData={this.setDefaultData}
      tableStore={tableStore}
    />
  }

  onNotStuff = (ref) => {
    this.doNotStuffRef = ref;
  }

  pkgClassificationRender = () => {
    const { pcbId, activeKey } = this.state;
    const { projectId, settingStore, product, getMultiPortResPart } = this.props;
    return <PkgClassification
      projectId={projectId}
      settingStore={settingStore}
      product={product}
      pcbId={pcbId}
      onRef={this.onPkgClassificationRef}
      changeLoading={this.changeLoading}
      update={activeKey === PKG_CLASSIFICATION}
      changeSave={this.changeSave}
      panelStateRef={this.panelStateRef}
      updateLibraryMenu={this.props.updateLibraryMenu}
      getMultiPortResPart={getMultiPortResPart}
      updateComponentSettingStatus={this.updateComponentSettingStatus}
      setDefaultData={this.setDefaultData}
    />
  }

  onPkgClassificationRef = (ref) => {
    this.pkgClassificationRef = ref;
  }

  getTabs = () => {
    const { product } = this.props;
    switch (product) {
      case SIERRA:
        return [CLASSIFICATION, PASSIVEVALUE, DONOTSTUFF, POWER_NETS]
      case CASCADE:
      default:
        return [CLASSIFICATION, PASSIVEVALUE, DONOTSTUFF]
    }
  }

  setPanelRef = (ref) => {
    this.panelStateRef = ref;
  }

  getTabTitle = (tab) => {
    const { product } = this.props;
    if (product !== SIERRA) {
      return tab;
    }
    if (tab === PASSIVEVALUE) {
      return "Passive Value";
    }

    if (tab === POWER_NETS) {
      return <div>
        <div className='aurora-top-bar-vertical-line'>
        </div>
        <span>{POWER_NETS}</span>
      </div>;
    }
    return tab;
  }

  pcbRender = () => {
    const { activeKey } = this.state;
    const tabs = this.getTabs();
    return <Tabs
      activeKey={activeKey}
      className={activeKey === POWER_NETS ? 'custom-rlc-tabs' : ""}
      onChange={this.changeTab}
      items={tabs.map(tab => ({ key: tab, label: this.getTabTitle(tab), children: this.getTabContent(tab) }))}
    />
  }

  packageRender = () => {
    return this.pkgClassificationRender()
  }

  contentRender = () => {
    const { designType } = this.state;
    return designType === PACKAGE ? this.packageRender() : this.pcbRender()
  }

  render() {
    const { panelMaxHeight = 800, loading, saving, width, pcbId, maxWidth = 1600 } = this.state;
    const { pcbName, designList, product } = this.props;
    const title = product === SIERRA ? "Components & Nets" : "Components";
    const loadTitle = product === SIERRA ? "Loading..." : "Loading PCB...";
    const content = (
      <Fragment>
        <Panel
          className={`component-RLC-panel component-PMIC-panel ${(product || "").toLowerCase()}-panel`}
          title={`${title} ${pcbName ? ` - ${pcbName}` : ''}`}
          onCancel={this.closeModal}
          zIndex={2000}
          width={width}
          position='panel-center-left'
          draggable
          dragEvent={this.dragEvent}
          minHeight={220}
          minWidth={400}
          maxHeight={panelMaxHeight}
          maxWidth={maxWidth}
          panelStateRef={this.setPanelRef}
        >
          <Spin spinning={loading || saving} tip={loading ? loadTitle : saving ? `Saving...` : ''}>
            <div className='component-RLC-content'>
              {
                designList ? <Fragment>
                  <div className='component-RLC-sub-content'>
                    {this.customPCB()}
                  </div>
                </Fragment> : null
              }
              {pcbId && this.contentRender()}
            </div>
          </Spin>
        </Panel>
        <div id="components-setting-dialog"></div>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ComponentsSetting;