import Viewer, { CurveItem } from '../../../Result/Public/sparameter/PlotView';
import { select } from 'd3-selection';

class CPMParameter {

  constructor(curves) {
    this.parameters = new Map();
    this.plotViewer = null;
    this.data = null;
    this.id = curves.id || 'cpm-plot'
    this.unit = curves.unit
    this.xUnit = curves.xUnit
    this.yUnit = curves.yUnit
    this.xLabel = curves.xLabel
    this.yLabel = curves.yLabel
    this.xScale = curves.xScale
    this.yScale = curves.yScale
  }

  importData(data) {
    this.data = data;
  }

  getCurves() {
    return this.plotViewer.curves
  }

  createPlot(element, events) {
    const options = {
      background: '#fff',
    }
    this.plotViewer = new Viewer({ element, events, options, id: this.id });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit(this.xUnit || 's');
    xAxis.setLabel(this.xLabel || 'Time');
    xAxis.setting.scale = this.xScale || "linear";
    let yAxis = this.plotViewer.getAxis('y');
    yAxis.setUnit(this.yUnit || 'A');
    yAxis.setLabel(this.yLabel || 'Current');
    // Default y scale
    yAxis.setting.scale = this.yScale || "linear";
    return this.plotViewer;
  }

  showCurves() {
    return Promise.all(this.data.map(cur => {
      let curve = new CurveItem();
      curve.x = cur.x;
      curve.y = cur.y;
      curve.visible = cur.visible;
      curve.id = cur.id;
      curve.file = null;
      curve.name = cur.name;
      curve.curveType = 'current';
      curve.color = cur.color
      return curve;
    }))
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }
}

export default CPMParameter;