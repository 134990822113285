import apiProcess from "../api/utility"
import { updateUserDefaultSetting, getUserDefaultSetting } from '../api/userDefaultSetting/userDefaultSetting'
import { ANSYS_HFSS, ANSYS_SIWAVE, BUILT_IN, FAST } from "../../constants/resolution"
import { GAP, WAVE, getUpdateCompBallInfoByPinSize } from "@/services/ExtractionPortsHelper"
import permissionData from '@/services/helper/data/permissionData.js'
import { ALL_PINS, BALL_TYPE_NONE, BALL_TYPE_NONE_LIST, NEARBY_PINS, PIN } from "../ExtractionPortsHelper/portTableHelper"
import { BGA, DIE } from "../../constants/componentType"

class UserDefaultSettings {
  constructor() {
    this.settings = null
  }

  setSettings(settings) {
    if (settings) {
      this.settings = settings
      permissionData.updateSetting(settings.rockySettings)
    }
  }

  getSettings = async () => {
    let _settings = this.settings;
    if (!_settings) {
      try {
        _settings = await getUserDefaultSettingPromise();
        if (_settings) {
          this.settings = _settings;
        }
      } catch (error) {
        this.settings = {}
        console.error(error)
      }
    }
    return _settings
  }

  getLocalSetting = () => {
    const settings = this.settings;
    return settings || {}
  }

  debounce(fn, wait) {
    var timeout = null;
    return function () {
      if (timeout !== null)
        clearTimeout(timeout);
      timeout = setTimeout(fn, wait);
    }
  }

  getResolution = () => {
    // Obtain the value of resolution
    let setting = this.settings;
    return setting && setting.platformSettings && setting.platformSettings.renderMode ? setting.platformSettings.renderMode : FAST;
  }

  updateUserDefaultSettings(settings) {
    if (settings) {
      updateUserDefaultSettingPromise(settings);
      this.settings = settings;
      permissionData.updateSetting(settings.rockySettings)
    }
  }

  clearSettings = () => {
    this.settings = null
  }

  // cascade setting
  getUseFullPCBPowerTree = () => {
    const setting = this.getLocalSetting();
    const { cascadeSettings  ={} } = setting;
    const { showFullPowerTree = false } = cascadeSettings;
    return showFullPowerTree
  }
}


function updateUserDefaultSettingPromise(userDefaultSetting) {
  return apiProcess(updateUserDefaultSetting, userDefaultSetting)
}

function getUserDefaultSettingPromise() {
  return apiProcess(getUserDefaultSetting)
}

function applySierraUserDefaultSetting(content, userDefaultSetting, designId) {
  const { extractionOption, portType, portSetupInfo } = userDefaultSetting

  switch (extractionOption) {
    case BUILT_IN:
      content.channel.type = BUILT_IN
      break
    case ANSYS_SIWAVE:
      content.channel.type = ANSYS_SIWAVE
      break
    case ANSYS_HFSS:
      content.channel.type = ANSYS_HFSS
      break
    default: break
  }

  if (portType) {
    if (content.port_setups) {
      content.port_setups.forEach(item => item.type = portType)
    }

    if (content.ports_generate_setup_list) {

      let referenceType = undefined, filterPinOptions = ["number"], pinNumber = "5", pinDistance = "10mm";
      referenceType = portType ? (portType === WAVE || portType === GAP) ? ALL_PINS : PIN : undefined;
      //GET reference type by user setting
      if (portSetupInfo && portSetupInfo.referenceType) {
        referenceType = portSetupInfo.referenceType;
        if (referenceType === NEARBY_PINS) {
          filterPinOptions = portSetupInfo.filterPinOptions || filterPinOptions;
          pinNumber = portSetupInfo.pinNumber || pinNumber;
          pinDistance = portSetupInfo.pinDistance || pinDistance;
        }
      }

      content.ports_generate_setup_list.forEach(item => {
        if (referenceType === NEARBY_PINS) {
          item.setup = {
            portType,
            referenceType,
            filterPinOptions,
            pinNumber,
            pinDistance
          }
        } else {
          item.setup = {
            portType,
            referenceType
          }
        }
      })
    }

    const components = content.ports_generate_setup_list.map(item => item.component)

    if (content.components) {
      content.components.forEach(item => {
        if (components.includes(item.name)) {
          item = setDefaultPortData(item, portType, extractionOption, designId)
        }
      })
    }
  }

  return content
}

function setDefaultPortData(component, portType, extractionOption, designId) {
  if (portType === GAP) {
    if (extractionOption === ANSYS_HFSS) {
      component.gap_size = "10um"
    } else {
      component.gap_size = "0"
    }
  }
  if (![BGA, DIE].includes(component.type) && BALL_TYPE_NONE_LIST.includes(portType)) {
    component.ball_type = BALL_TYPE_NONE;
  } else {
    const updateBallInfo = getUpdateCompBallInfoByPinSize(component, designId);
    Object.keys(updateBallInfo).forEach(key => { component[key] = updateBallInfo[key] })
  }

  return component
}

const SierraDefaultResultSettings = {
  waveform: {
    displayMode: "Hi-Re"//Hi-Re / Fast
  }
}
function getSierraResultSettings(resultSettings) {
  const _resultSettings = resultSettings &&
    resultSettings.waveform
    && resultSettings.waveform.displayMode
    ? resultSettings
    : { ...SierraDefaultResultSettings };
  return _resultSettings;
}

const userDefaultSettings = new UserDefaultSettings()

export {
  userDefaultSettings,
  updateUserDefaultSettingPromise,
  getUserDefaultSettingPromise,
  applySierraUserDefaultSetting,
  setDefaultPortData,
  getSierraResultSettings
}