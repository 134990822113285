import { connect } from 'react-redux';
import {
  changeUploadDisableStatus,
  saveCurrentPCBKey
} from './store/action';
import higherOrderComponent from './explorerHOC';
import UploadPCBMenu from '@/components/UploadPCB';
import DesignUpload from './DesignUpload';
import cascadeProjects from '@/services/Cascade/DB/cascadeProject';
import { createPreLayout } from '../../store/prelayout/action';
import { CASCADE } from '../../../../constants/pageType';
import '@/publicCss/uploadPCB.css';

const mapState = (state) => {
  const CascadeUploadReducer = state.CascadeReducer.CascadeUploadReducer;
  const {
    disabled,
    compressProgress
  } = CascadeUploadReducer;
  return {
    disabled, compressProgress
  }
}

const mapDispatch = (dispatch) => ({
  _saveCurrentPCBKey(key) {
    dispatch(saveCurrentPCBKey(key))
  },
  _createPreLayout(designType, electric) {
    dispatch(createPreLayout(designType, electric))
  },
  _changeUploadDisableStatus(disabled) {
    dispatch(changeUploadDisableStatus(disabled))
  }
})
const UploadPCB = higherOrderComponent({ UploadPCBMenu, DesignUpload, projectsDB: cascadeProjects, product: CASCADE })

export default connect(mapState, mapDispatch)(UploadPCB);