class CompPrefixLib {
  constructor(obj = {}) {
    const {
      Res = ['R', 'PR', 'XW', 'SHRT'],
      Ind = ["L", "PL"],
      Cap = ["C", "PC"],
      Jumper = ['PJ', 'J'],
      Ferrite = ['FB', 'FL'],
      Diode = ['D'],
      Transistor = ['Q', 'PQ'],
      Connector = [],
      Repeater = [],
      Load = ['U'],
      Bga = [],
      Die = [],
      Ignore = [],
      Ipd = [],
    } = obj;

    this.Res = Res;
    this.Ind = Ind;
    this.Cap = Cap;
    this.Jumper = Jumper;
    this.Ferrite = Ferrite;
    this.Diode = Diode;
    this.Transistor = Transistor;
    this.Connector = Connector;
    this.Repeater = Repeater;
    this.Load = Load;
    this.Bga = Bga;
    this.Die = Die;
    this.Ignore = Ignore;
    this.Ipd = Ipd;
  }
};

class CompPMIC {
  constructor(obj = {}) {
    const { linearSwitch = [], specialized = [], linearRegulator = [], switchingRegulator = [], discreteBuckConverter = [], powerSwitch = [], driver = [], multiPortRes = [] } = obj;
    this.linearSwitch = linearSwitch;
    this.specialized = specialized;
    this.linearRegulator = linearRegulator;
    this.switchingRegulator = switchingRegulator;
    this.powerSwitch = powerSwitch;
    this.discreteBuckConverter = discreteBuckConverter;
    this.driver = driver;
    this.multiPortRes = multiPortRes;
  }
}

export {
  CompPrefixLib,
  CompPMIC
}