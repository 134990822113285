import React, { Component } from "react";
import Table from '../EditableTable';
import "../../publicCss/aurora.css";
import "../../publicCss/style.css";
import "./index.css";

const compColumns = [{
  title: 'Name',
  dataIndex: 'name',
  width: '33.33%',
}, {
  title: 'Layer',
  dataIndex: 'layer',
  width: '33.33%',
}, {
  title: 'Type',
  dataIndex: 'type',
  width: '33.33%'
}];
const layerList = ["Top", "Bottom"];

class ComponentTable extends Component {

  constructor(props) {
    super(props);

    compColumns[1].onCell = (record) => {
      return this.props.notSelect ? {} : {
        record,
        edit: "select",
        options: layerList,
        dataIndex: "layer",
        getPopupContainer: document.getElementById('root'),
        handleSave: (record) => { this.editComponent(record, "layer") },
      }
    }

    compColumns[2].onCell = (record) => {
      return this.props.notSelect ? {} : {
        record,
        edit: "select",
        options: this.props.typeList || [],
        dataIndex: "type",
        getPopupContainer: document.getElementById('root'),
        handleSave: (record) => { this.editComponent(record, "type") },
      }
    }
  }

  editComponent = (record, type) => {
    const { components } = this.props;
    let _components = [...components];
    const index = _components.findIndex(item => item.name === record.name);
    _components[index][type] = record[type];
    this.props.updateComponent({ components: _components, record: record });
  }

  render() {
    const { components } = this.props;
    return (<div className="aurora-setup-border">
      <span className='out-title-color font-bold'>Components</span>
      <Table
        rowKey="name"
        columns={compColumns}
        size="small"
        dataSource={components && components.length ? components : []}
        className='pre-layout-component-table space-10'
        rowClassName={(record, index) => index % 2 !== 0 ? 'editable-row aurora-row-background' : 'editable-row'}
      />
    </div>
    )
  }
}

export default ComponentTable;