import React from 'react';
import { RES } from '../PCBHelper';
import { N_PORT } from './virtualCompHelper';
import { checkRLCValueFormat } from '../helper/dataProcess';
import { CUSTOM_SPICE, CUSTOM_TOUCHSTONE } from '../../constants/libraryConstants';

function getVirtualCompsErrors(virtualComps, checkInfo) {
  const { touchstoneList = [], spiceList = [], getCheckFile, pcbName = null } = checkInfo || {};

  let errors = [];
  for (let comp of virtualComps || []) {

    if (!comp.pins || !comp.pins.length) {
      errors.push(<p style={{ margin: 0 }}>Virtual Component "<span className="font-bold">{pcbName ? `${pcbName}::${comp.name}` : comp.name}</span>" does not have any pins.</p>)
    }

    if (comp.type === RES) {
      errors = resValueCheck(comp, errors, pcbName);
    } else if (comp.type === N_PORT) {
      errors = nPortModelCheck({ comp, errors, touchstoneList, spiceList, getCheckFile, pcbName });
    }

    errors = pinsLocationCheck(comp, errors, pcbName);
  }
  return errors.length ? errors : []
}

function resValueCheck(comp, errors, pcbName) {
  if (!comp.value) {
    errors.push(<p style={{ margin: 0 }}>Virtual Component "<span className="font-bold">{pcbName ? `${pcbName}::${comp.name}` : comp.name}</span>" does not set resistance value.</p>);
    return errors;
  }

  const resCheck = checkRLCValueFormat(comp.value);
  if (!resCheck) {
    errors.push(<p style={{ margin: 0 }}>Virtual Component "<span className="font-bold">{pcbName ? `${pcbName}::${comp.name}` : comp.name}</span>" resistance value format error.</p>);
  }
  return errors;
}

function nPortModelCheck({ comp, errors, touchstoneList, spiceList, getCheckFile, pcbName }) {
  if (!comp.files || !comp.files.length) {
    errors.push(<p style={{ margin: 0 }}>Virtual Component "<span className="font-bold">{pcbName ? `${pcbName}::${comp.name}` : comp.name}</span>" does not set model.</p>);
    return errors;
  } else {
    for (let file of comp.files) {
      const fileType = file.type === 'Touchstone' ? CUSTOM_TOUCHSTONE : CUSTOM_SPICE;
      const fileExist = getCheckFile ? getCheckFile(fileType, file.libraryId) : getFileExist(file, touchstoneList, spiceList);
      if (!fileExist) {
        errors.push(<p style={{ margin: 0 }}>Virtual Component "<span className="font-bold">{pcbName ? `${pcbName}::${comp.name}` : comp.name}</span>" {file.type || ""} file {file.fileName} not exist.</p>);
      }
    }
  }

  for (let pin of comp.pins || []) {
    if (!pin.node || !pin.libraryId || !pin.modelKey) {
      errors.push(<p style={{ margin: 0 }}>Virtual Component "<span className="font-bold">{pcbName ? `${pcbName}::${comp.name}` : comp.name}::{pin.pin}</span>" does not set node.</p>);
    }
  }
  return errors;
}

function getFileExist(file, touchstoneList, spiceList) {
  let list = file.type === 'Touchstone' ? touchstoneList : (file.type === 'SPICE' ? spiceList : []);
  const find = (list || []).find(item => item.id === file.libraryId);
  if (!find) {
    return false;
  }
  return true;
}

function pinsLocationCheck(comp, errors, pcbName) {
  for (let pin of comp.pins || []) {
    if (!pin.location || pin.location.length !== 2) {
      errors.push(<p style={{ margin: 0 }}>Virtual Component "<span className="font-bold">{pcbName ? `${pcbName}::${comp.name}` : comp.name}::{pin.pin}</span>" location calculate error.</p>);
    }
  }
  return errors;
}

export {
  getVirtualCompsErrors
}