class EndToEndChildrenChannels {
  constructor() {
    this.endToEnds = new Map(); // key - endToEndChannelId, value - end to end  children channels

    this.set = this.set.bind(this);
    this.getChannelsByEndToEndId = this.getChannelsByEndToEndId.bind(this);
    this.delChannel = this.delChannel.bind(this);
    this.delete = this.delete.bind(this);
    this.clearCache = this.clearCache.bind(this);
  };

  set(endToEndChannelId, channels) {
    this.endToEnds.set(endToEndChannelId, channels);
  };

  getChannelsByEndToEndId(endToEndChannelId) {
    return this.endToEnds.get(endToEndChannelId);
  };

  delChannel(endToEndChannelId, channelId) {
    let channels = this.endToEnds.get(endToEndChannelId);
    channels = channels.filter(item => item.id !== channelId);
    this.endToEnds.set(endToEndChannelId, channels);
  }

  delete(endToEndChannelId) {
    this.endToEnds.delete(endToEndChannelId);
  }

  clearCache() {
    this.endToEnds = new Map();
  }
};

const endToEndChildrenChannelsConstructor = new EndToEndChildrenChannels();
export default endToEndChildrenChannelsConstructor;