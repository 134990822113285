import React, { Component, Fragment, createRef } from 'react';
import { drawPlot } from '@/services/Cascade/helper/CurrentParameter/PlotCtrl';
import hash from 'object-hash';
import CurrentParameter from '../../../../services/Cascade/helper/CurrentParameter/Parameter';
import { toNonExponential } from '../../../../services/helper/numberHelper';
import '../index.css';

class CurrentPlot extends Component {

  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.param = new CurrentParameter({ id: 'imp-pwl-current' });
    this.plot2d = null;
  }

  componentDidMount() {
    this.initPlot();
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate = (prevProps) => {
    const { width, height, points, x, y } = this.props;
    if (width !== prevProps.width || height !== prevProps.height || x !== prevProps.x || y !== prevProps.y) {
      this.draw();
    }

    if (points && hash(points) !== hash(prevProps.points)) {
      this.initPlot()
    }
  }

  initPlot = () => {
    const { points } = this.props
    let time = [], current = [];
    for (let point of points) {
      time.push(Number(toNonExponential(point.time)))
      current.push(Number(toNonExponential(point.current)))
    }
    const data = [{
      id: 'current-main',
      time,
      current
    }]

    this.param.importData(data);

    this.draw();
  }

  draw = () => {
    this.param.cleanPlotViewer();
    this.plot2d = this.param.createPlot(this.svgRef.current);
    drawPlot(this.param, this.plot2d, 'current');
  }

  render() {
    const { showType, width = '800px', height = '400px' } = this.props;
    let _width = Number(width.replace('px', '')) / 2 - 8;
    let _height = Number(height.replace('px', ''))
    return <Fragment>
      <svg ref={this.svgRef} id={showType} width={_width} height={_height}></svg>
    </Fragment>
  }
}

export default CurrentPlot;