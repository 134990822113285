import { call, put, takeEvery, select, delay, takeLatest, cancel, fork } from 'redux-saga/effects';
import { message } from 'antd';
import { getWorkFlow, cancelVerificationWorkflow } from '@/services/api/v2/workflowCtrl';
import * as taskStatus from '@/constants/workflowStatus';
import { VERIFY_SUCCESS, VERIFY_NEVER } from '@/constants/verificationStatus';
import dayjs from 'dayjs';
import {
  START_ROCKY_VERIFICATION,
  VERIFICATION_FLOW,
  CANCEL_VERIFICATION_WORKFLOW,
  GET_CURRENT_MONITOR,
  GET_INTERFACE_MONITOR,
  GET_VERIFICATION_LOG,
  UPDATE_SIMULATION_MESSAGE,
  RECALCULATE_EYEDIAGRAM,
  GET_CURRENT_PROFILE,
  START_ROCKY_PACKAGE_VERIFICATION,
  START_ROCKY_PACKAGE_PDN,
  START_ROCKY_PCB_CHANNEL,
  START_SSN_SEPARATE_EXTRACTION,
  GET_CURRENT_SSN_VERIFICATION_LOG,
  START_PRE_LAYOUT_SIMULATION
} from './actionTypes';
import {
  updateSimulationReducer,
  changeVerificationList,
  updateCurrentSimulationInfo,
  getCurrentMonitor,
  getVerificationLog,
  getCurrentProfile,
  updateNotSetCardVerifications,
  getInterfaceMonitor
} from './action';
import {
  doRockySimulation,
  getVerificationLogByID,
  getVerificationContentPromise,
  getChannelPDNContentPromise,
  judgeUpdateGapPortSetup,
  updateExtractionGapPortsSetup,
  getDefaultPortsGenerateSetupList,
  updatePortsComponentsByPortType,
  doRockyPackageSimulation,
  getChannelConfig,
  changeStdModelToDimmStdModels,
  judgeDimmStdModelExist,
  getCardModelExist,
  getSSNChannelLogPromise,
  getPackageVerificationInfoPromise
} from '@/services/Rocky';
import { getLayoutPCBInfo, getPackageContent, saveRockyPackagePDNContentToServer } from '../rocky/saga';
import DesignInfo from '@/services/Rocky/pcbInfo';
import {
  checkVerificationStatus,
  getMonitor,
  getVerificationWorkFlow,
  workflowWaitingIndex,
  getRunningWorkFlowPromise
} from '@/services/workflow/workflow';
import {
  autoGetVerificationList
} from '../project/action';
import {
  updateErrorCheckList,
  getVerificationContent,
  updateTouchstoneStatusList,
  updatePdnErrorCheck,
  updateChannelErrorCheckList,
} from '../rocky/action';
import { portSavePath } from '@/services/Rocky/constants';
import { eyediagramResult, CustomizedEye } from '@/services/Rocky/result';
import { getErrorCheck, getRlcCompsValueCheck, getChannelPowerComponentsErrorCheck } from '../../errorCheck/ErrorCheck';
import { PRE_LAYOUT } from '@/constants/designVendor';
import RockySetup from '@/services/Rocky/helper/rockyDatabase';
import ByteSetupInfo from '@/services/Rocky/helper/rockySetupInfo';
import { getDesignStackupJson } from "@/services/api/designFile";
import { stackupErrorCheck } from "@/services/Stackup";
import { fakeProgress, preLayoutJsonErrorCheck, versionCompareSize } from '@/services/helper/dataProcess';
import VerificationInfo from '@/services/Rocky/verificationHelper';
import { getRockyPdnErrorCheck, sevPDNErrorCheck } from '../../errorCheck/PDNErrorCheck';
import { updateRockyPDNErrorList } from '../rockyPdn/action';
import { ROCKY_PDN } from '@/services/Rocky/constants';
import { _UpdateTouchstoneStatus, getProjectType } from '../rocky/saga';
import { eyediagramGeneratePromise } from '@/services/Rocky/simulationCtrl';
import { openTabFooter } from '../../../MonitorStore/action';
import { getPreLayoutInfoById } from '@/services/Rocky/preLayout';
import rockyChannels from '@/services/Rocky/helper/channels';
import projectDesigns from '@/services/helper/projectDesigns';
import monitorLog from '@/services/helper/monitorLog';
import getProfileData, { parseProfile, shouldGetProfile } from '@/services/helper/profileCtrl';
import { ResultData } from '@/services/Rocky/result';
import { getDefaultReferenceNets, getDefaultPortSetupList } from "@/services/ExtractionPortsHelper";
import { ROCKY } from '@/constants/pageType';
import { VERIFICATION, RESULT } from '@/constants/treeConstants';
import { workflowJudge } from '../../../../services/workflow/workflowHelper';
import { PACKAGE, PACKAGE_RESULT, PACKAGE_VERIFICATION, CARD, CARD_VERIFICATION, CARD_RESULT, SSN_RESULT, MULTIPLE_CHANNEL, PACKAGE_PDN, PCB_PDN } from '../../../../constants/treeConstants';
import packageVerificationConstructor from '@/services/Rocky/PackageHelper';
import { autoGetCardVerifications } from './card/action';
import cardChannelsConstructor from '../../../../services/Rocky/cardHelper';
import { DDR5_RDIMM, DIMM_DDR_TYPES, MERGE_TYPE_LIST, PDN_EXTRACTION, SIGNAL_EXTRACTION, SINGLE_PATTERN, SSN_CHANNEL, SSN_CHANNEL_TYPE_LIST, SSN_PATTERN_TYPE_LIST, SSN_VERIFICATION } from '../../../../services/Rocky/constants';
import { API_RES_MESSAGE } from '../../../../constants/returnCode';
import { MODEL } from '../../../../services/Rocky/preLayout/preLayoutConfig';
import RockySSNChannelInfo from '../../../../services/Rocky/SSN/channelsInfo';
import { CONTROLLER } from '../../../../constants/componentType';
import { getPackageConfig } from '../project/saga';
import CurrentVerificationInfo from '@/services/Rocky/SSN/verificationInfo';
import { doRockyPackagePDNSimulation, doRockyPCBChannelSimulation, getRockyPackagePDNInfo } from '../../../../services/Rocky';
import { autoGetMultipleVerifications } from '../multiple/action';
import { PCB } from '../../../PDN/constants';
import PCBChannelContentConstructor from '../../../../services/Rocky/SSN/pcbChannelInfo';
import { changeTabMenu } from '../../../MonitorStore/action';
import projectChannels from '@/services/Rocky/helper/projectChannels';
import { saveModelPreLayoutToServer } from '../prelayout/saga';
import { TOUCHSTONE } from '../../../../constants/libraryConstants';

export let workflowTask = null;

function* startVerification(action) {
  let { verificationIds, currentVerificationId, ignoreCardModel = false, openSSNVerificationIds, isSSN, options } = action;
  const runOptions = options && options.length ? options : ['modeling', 'simulation'];
  const ifDoSimulation = runOptions.includes('simulation');
  yield put(changeVerificationList([]));
  yield put(updateNotSetCardVerifications(null));
  if (verificationIds.length === 0) {
    return;
  }
  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }

  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  let { RockyReducer: { rocky: { infoErrorCheck, TouchstoneStatusList } } } = yield select();

  let currentChannels = [], currentSimInfo = [];

  verificationIds.forEach(item => {
    currentSimInfo.push({
      verificationId: item,
      status: 'checking'
    });

    delete newSimulationReducer[item];
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    newSimulationReducer[item] = {
      startMsg: `==> ${waitingTime} Checking setup...`,
    }

    const channelId = VerificationInfo.getVerificationInfo(item);
    if (!currentChannels.includes(channelId) && channelId) {
      currentChannels.push(channelId);
    }
    //clear prev error message
    infoErrorCheck = infoErrorCheck.filter(it => it.verificationId !== item);
    //clear prev verification touchstone macro modeling status message
    TouchstoneStatusList = TouchstoneStatusList.filter(it => it.verificationId !== item);
    //clear result data
    ResultData.cleanVerificationResultData(item);
  });
  //update verification error message
  yield put(updateErrorCheckList(infoErrorCheck));

  //update verification Touchstone macro modeling status message
  yield put(updateTouchstoneStatusList(TouchstoneStatusList));


  yield put(updateSimulationReducer(newSimulationReducer));
  const { RockyReducer: { project: { currentProjectId, currentChannelId, ssnSelectPDNIds } } } = yield select();
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId, currentSimInfo }));

  //clear prev pdn error message
  const { RockyReducer: { RockyPDN: { pdnErrorCheck } } } = yield select();
  let _pdnErrorCheck = pdnErrorCheck && pdnErrorCheck.length ? pdnErrorCheck : [];
  ssnSelectPDNIds.forEach(item => {
    _pdnErrorCheck = _pdnErrorCheck.filter(it => it.pdnId !== item.PDNID);
  });
  yield put(updateRockyPDNErrorList(_pdnErrorCheck));

  //error check 
  //stackup / pdn / verification
  let channels = [];
  //{ channelId, verificationIds: [id1,id2], pdnId }
  /*  const notSetCardVerifications = info ? info.notSetCardVerifications : null; */
  if (ifDoSimulation) {
    const info = yield call(simulationErrorCheck, { currentChannels, ssnSelectPDNIds, verificationIds, currentSimInfo, ignoreCardModel, isSSN, openSSNVerificationIds, runOptions });
    channels = info ? info.channels : [];
    verificationIds = info ? info.verificationIds : [];
  } else {
    channels = [{ verificationIds: verificationIds, channelId: currentChannelId }];
  }

  if (!channels || channels.length === 0) {
    return;
  }

  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));

  // clean result cache 
  // eyedigram result cache
  eyediagramResult.cleanCache(verificationIds);
  //customized eye diagram cache
  CustomizedEye.cleanCache(verificationIds);
  reducer = yield select();
  simulationReducer = reducer.RockyReducer.simulationReducer;
  channels.forEach(item => delete item.channelId);//[ { pdnId, verificationList: [verificationId1, verificationId2, ...]}, { ... } ]
  let waitingTask = null;
  try {
    waitingTask = yield fork(updateWaitingStartMsg, { verificationIds, _simulation: simulationReducer })
    channels.forEach(item => {
      item.options = runOptions;
    });
    const response = yield call(doRockySimulation, channels);
    yield cancel(waitingTask);
    if ((!response || !response.length) && !runOptions.includes('simulation')) {
      let msg = `==> PCB has been extracted completely, no need to extract again!`;
      newSimulationReducer[currentVerificationId].simulationMsg = msg;
      yield put(getVerificationLog(currentVerificationId, { pageType: SSN_VERIFICATION }));
      yield put(updateSimulationReducer(newSimulationReducer));
      yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
      return
    }
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    yield* verificationIds.map(function* (item) {
      simulationReducer[item] = {};
      let msg = ""
      if (error && error.code === API_RES_MESSAGE) {
        msg = `==> ${error.msg}`;
        // get log
        yield put(getVerificationLog(item));
        simulationReducer[item].simulationMsg = msg;
      } else {
        msg = `==> ${error}`;
        simulationReducer[item].endMsg = msg;
      }
    })
    //update simulation reducer
    yield put(updateSimulationReducer(simulationReducer));
    //update tree verification status
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));

    yield delay(1000);
    yield put(updateCurrentSimulationInfo({ currentVerificationId, item: "startMsg", info: null }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId, item: "progress", info: -1 }));
    yield put(changeVerificationList([]));
    const promise = yield call(checkVerificationStatus, currentVerificationId);
    let resultExist = false;

    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(updateCurrentSimulationInfo({ currentVerificationId, item: "resultExist", info: resultExist }));

    return;
  }

  // fake progress
  // id: workflowId
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  const verificationStatus = yield call(getVerificationWorkFlow, currentVerificationId);
  if (verificationStatus && verificationStatus.length > 0) {
    if (workflowTask) {
      yield cancel(workflowTask);
    };
    workflowTask = yield fork(verificationWorkFlow, { verificationId: currentVerificationId, verificationWork: verificationStatus });
    return;
  };
}

function* getMonitorLog(action) {
  const { workflowId, verificationId, dataType, isJump, isMergeVerification } = action;
  try {
    // get  monitor
    if ([PDN_EXTRACTION, SIGNAL_EXTRACTION, SINGLE_PATTERN].includes(dataType)) {
      const logs = yield call(getRunningWorkFlowPromise, { product: ROCKY, workflowId, verificationId: isMergeVerification ? isMergeVerification : verificationId })
      if (logs.length > 0) {
        let _logs = logs.map(item => { return { ...item, workflowId } })
        const { RockyReducer } = yield select();
        let simulationReducer = RockyReducer.simulationReducer;
        if (simulationReducer[verificationId] && simulationReducer[verificationId].monitorList && simulationReducer[verificationId].monitorList.length > 0) {
          let _monitorList = []
          if ([PDN_EXTRACTION, SIGNAL_EXTRACTION].includes(dataType)) {
            // const
            _monitorList = simulationReducer[verificationId].monitorList.filter(item => !["PDN Extraction", "Signal Extraction"].includes(item.displayNameList))
          } else {
            _monitorList = simulationReducer[verificationId].monitorList.filter(item => ["PDN Extraction", "Signal Extraction"].includes(item.displayNameList))
          }
          _logs = [..._logs, ..._monitorList]
        }
        if (isJump) {
          const displayNameList = _logs.map(item => item.displayName);
          let dataType = displayNameList.includes("PDN Extraction") ? PDN_EXTRACTION : displayNameList.includes("Signal Extraction") ? SIGNAL_EXTRACTION : SINGLE_PATTERN;
          yield put(changeTabMenu({ dataType: dataType }))
        }
        yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitorList", info: _logs }));
      }
    } else {
      const log = yield call(getMonitor, workflowId);
      if (log.length > 0) {
        yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitor", info: monitorLog(log) }));
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* getProfileLog(action) {
  const { verificationId } = action;
  let profileLog = [];
  try {
    profileLog = yield call(getProfileData, verificationId);
  } catch (error) {
    console.error(error)
  }
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "profileLog", info: parseProfile(profileLog) }));
}

function* cancelVerificationWork(action) {
  const { verificationId } = action;
  const { MonitorInfoReducer: { monitorScreenInfo: { dataType } } } = yield select()
  try {
    let workFlowVerificationId = verificationId;
    if (MERGE_TYPE_LIST.includes(dataType)) {
      const info = yield call(_getMergeVerificationId, verificationId)
      workFlowVerificationId = info.workFlowVerificationId;
    }
    yield call(cancelVerificationWorkflow, workFlowVerificationId);
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "endMsg", info: "==> Simulation cancelled!" }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }))
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }))
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingIndex", info: -1 }))
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingTime", info: null }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "simulationMsg", info: null }));
    yield delay(5000);
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "endMsg", info: null }));
  } catch (error) {
    console.error(error);
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "endMsg", info: "==> Failed to cancel simulation!" }));
    yield delay(5000);
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "endMsg", info: null }));
  }
}

export function* verificationWorkFlow(action) {
  const { verificationId, verificationWork, simulationType, isUpdateDiagram, dataType, isGetLog, isJumpType, isMergeVerification, notUpdateLog } = action;
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "cancelDisabled", info: false }));
  //clean prev simulation info
  if (!verificationWork || verificationWork.length === 0) {
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }))
    return;
  }
  let autoGetVerificationsFn = simulationType === CARD ? autoGetCardVerifications : simulationType === MULTIPLE_CHANNEL ? autoGetMultipleVerifications : autoGetVerificationList;
  let { id, status, progress, currentTaskIndex, taskList } = verificationWork[verificationWork.length - 1];
  // [{ worker: "Ansys", taskName: "Ansys3DExtraction", taskId: null }]
  const getProfile = shouldGetProfile(taskList);
  let time;
  const { RockyReducer } = yield select();
  let simulationReducer = RockyReducer.simulationReducer;
  //update tree verification status
  const { RockyReducer: { project, card: { cardChannelId }, rockyMultiple: { channelId } } } = yield select();
  yield put(autoGetVerificationsFn({
    projectId: project.currentProjectId,
    channelId: simulationType === CARD ? cardChannelId : simulationType === MULTIPLE_CHANNEL ? channelId : project.currentChannelId,
    verificationType: simulationType,
    isUpdateDiagram
  }));
  /*  delete simulationReducer[verificationId]; */
  //update simulation reducer
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "log", info: null }))
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitor", info: null }))
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingIndex", info: -1 }))
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingTime", info: null }))
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingStartLogs", info: null }))
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: '==> Start simulating...' }))
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: true }));
  if (SSN_CHANNEL_TYPE_LIST.includes(dataType) && isGetLog) {
    yield put(getVerificationLog(verificationId, { pageType: SSN_CHANNEL }));
  }
  let isJump = isJumpType ? true : false;
  if (currentTaskIndex < 2) {
    let waitingIndex = -1;
    waitingIndex = yield call(workflowWaitingIndex, id);
    // while()
    while (waitingIndex >= 0) {
      const cancelWait = yield call(cancelWorkflowJudge, { verificationId, simulationType });
      if (cancelWait) {
        yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
        return;
      }
      time = new Date();
      time.setUTCSeconds(time.getUTCSeconds());
      let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
      //update current verification simulation info
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingIndex", info: waitingIndex }));
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingTime", info: waitingTime }));
      yield delay(3000);
      // update waiting index
      waitingIndex = yield call(workflowWaitingIndex, id);
    };

    if (waitingIndex === -2) {//-2  simulation cancel
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingIndex", info: -1 }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingTime", info: null }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
      yield delay(3000);
      yield put(getCurrentMonitor(id, verificationId, dataType, isJump, isMergeVerification));
      isJump = false;
      return;
    }

    if (waitingIndex === -3) {//-3 simulation complete
      const promise = yield call(checkVerificationStatus, verificationId);
      let resultExist = false;

      if (promise && promise.status) {
        if (promise.status === VERIFY_SUCCESS) {
          resultExist = true;
        }
      };
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "resultExist", info: resultExist }));

      const { RockyReducer: { rocky: { rockyInfo } } } = yield select();
      let info = rockyInfo;
      //current verification === simulation verificationId, get content (update ifDoExtraction)
      if (info && info.verificationId && info.verificationId === verificationId) {
        yield put(getVerificationContent(info.verificationId, true));
      }
      //update current verification simulation info
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "endMsg", info: null }));
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingIndex", info: -1 }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingTime", info: null }))
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "simulationMsg", info: null }));
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
      yield delay(3000);
      yield put(getCurrentMonitor(id, verificationId, dataType, isJump, isMergeVerification));
      isJump = false;
      return;
    }

    //clean current verification waiting info
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingIndex", info: null }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingTime", info: null }));
  }
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: '==> Simulation running...\n==> Data processing...' }))

  simulationReducer = RockyReducer.simulationReducer;

  if (verificationId && simulationReducer[verificationId]) {
    let currentInfo = simulationReducer[verificationId];
    if (currentInfo.progress && currentInfo.progress > 0) {
      progress = currentInfo.progress;
    }
  }

  if (progress === 0) {
    progress += 2;
  }
  //update current verification simulation info
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: progress }));
  let indexNum = 0;

  while (status === taskStatus.RUNNING) {
    const cancelSimulation = yield call(cancelWorkflowJudge, { verificationId, simulationType });
    if (cancelSimulation) {
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
      return;
    }
    yield put(getCurrentMonitor(id, verificationId, dataType, isJump, isMergeVerification));
    isJump = false;
    if (getProfile && indexNum % 6 === 0) {
      yield put(getCurrentProfile(verificationId));
    }
    yield delay(5000);
    const { data: { data } } = yield call(getWorkFlow, id);
    let _progress = progress;
    if (!data) {
      status = taskStatus.FAILED;
    } else {
      status = data.status;
      _progress = data.progress;
    }

    indexNum += 1;

    progress = fakeProgress(progress, indexNum, _progress);
    if (status === taskStatus.RUNNING) {
      //update current verification simulation progress
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: progress }));
    }
  }
  //update current verification simulation progress
  if (status === taskStatus.SUCCEED) {
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: 100 }));
  } else {
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }));
  }

  yield put(getCurrentProfile(verificationId));
  yield put(getCurrentMonitor(id, verificationId, dataType, isJump, isMergeVerification));
  isJump = false;
  yield delay(800);
  //update current verification simulation info
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
  yield put(changeVerificationList([]));

  const promise = yield call(checkVerificationStatus, isMergeVerification ? isMergeVerification : verificationId);
  let resultExist = false;

  if (promise && promise.status) {
    if (promise.status === VERIFY_SUCCESS) {
      resultExist = true;
    }
  };
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "resultExist", info: resultExist }));
  const { RockyReducer: { rocky: { rockyInfo }, project: { currentProjectId, currentChannelId }, card: { cardChannelId: _cardChannelId }, rockyMultiple: { channelId: multipleChannelId } } } = yield select();
  let Info = rockyInfo;
  //current verification === simulation verificationId, get content (update ifDoExtraction)
  if (Info && Info.verificationId && Info.verificationId === verificationId) {
    yield put(getVerificationContent(Info.verificationId, true));
  }
  //update tree verification status
  yield put(autoGetVerificationsFn({
    projectId: currentProjectId,
    channelId: simulationType === CARD ? _cardChannelId : simulationType === MULTIPLE_CHANNEL ? multipleChannelId : currentChannelId,
    verificationType: simulationType,
    isUpdateDiagram
  }));
  yield delay(3000);
  if (SSN_CHANNEL_TYPE_LIST.includes(dataType) && !notUpdateLog) {
    yield put(getVerificationLog(verificationId, { pageType: SSN_CHANNEL, dataType }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitorList", info: [] }));
  } else {
    yield put(getCurrentMonitor(id, verificationId, dataType, false, isMergeVerification));
  }
}

function* _getMergeVerificationId(verificationId) {
  const _info = rockyChannels.getInfoFromVerificationId(verificationId);
  let workFlowVerificationId = verificationId, isMergeVerification = null;
  if (_info && _info.id) {
    const mergeVerificationId = yield call(RockySSNChannelInfo.getMergeVerificationId, _info.id)
    if (mergeVerificationId) {
      workFlowVerificationId = mergeVerificationId;
      isMergeVerification = mergeVerificationId;
    }
  }
  return { workFlowVerificationId, isMergeVerification }
}

function* _getInterfaceWork(action) {
  const { verificationId, pageType, isUpdateDiagram, dataType, isJumpType } = action;
  try {
    let workFlowVerificationId = verificationId, isMergeVerification = null;
    if (MERGE_TYPE_LIST.includes(dataType)) {
      const info = yield call(_getMergeVerificationId, verificationId)
      workFlowVerificationId = info.workFlowVerificationId;
      isMergeVerification = info.isMergeVerification;
    }
    const verificationWork = yield call(getVerificationWorkFlow, workFlowVerificationId);
    if (workflowTask) {
      yield cancel(workflowTask);
    };

    if (verificationWork && verificationWork.length > 0) {

      const cardVer = cardChannelsConstructor.getVerification(verificationId);
      const simulationType = pageType === MULTIPLE_CHANNEL ? MULTIPLE_CHANNEL : cardVer ? CARD : null;
      //todo package
      workflowTask = yield fork(verificationWorkFlow, { verificationId, verificationWork, simulationType, isUpdateDiagram, dataType, isGetLog: true, isJumpType, isMergeVerification });
      return;
    } else {
      //update current verification simulation info
      yield call(cleanVerificationSimulationInfo, verificationId);
      const { RockyReducer } = yield select();
      let simulationReducer = RockyReducer.simulationReducer;

      if (simulationReducer[verificationId] && simulationReducer[verificationId].monitor && simulationReducer[verificationId].monitor.length > 0) {
        let workflowId = simulationReducer[verificationId].monitor[0].workflowId;
        if (workflowId) {
          const log = yield call(getMonitor, workflowId);
          if (log.length > 0) {
            yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitor", info: monitorLog(log) }));
          } else {
            yield put(getVerificationLog(verificationId, { pageType, dataType }, workFlowVerificationId));
            yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
          }
        } else {
          yield put(getVerificationLog(verificationId, { pageType, dataType }, workFlowVerificationId));
          yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
        }
      } else if (simulationReducer[verificationId] && simulationReducer[verificationId].monitorList && simulationReducer[verificationId].monitorList.length > 0) {
        let workflowId = simulationReducer[verificationId].monitorList[0].workflowId;
        if (workflowId) {
          const logs = yield call(getRunningWorkFlowPromise, { product: ROCKY, workflowId, verificationId })
          if (logs.length > 0) {
            yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitorList", info: logs }));
          } else {
            // get log
            yield put(getVerificationLog(verificationId, { pageType, dataType }, isMergeVerification));
            yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitorList", info: [] }));
            yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
          }
        } else {
          yield put(getVerificationLog(verificationId, { pageType, dataType }, isMergeVerification));
          yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitorList", info: [] }));
          yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
        }

      } else {
        // GET log
        yield put(getVerificationLog(verificationId, { pageType, dataType }, isMergeVerification));
        yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitor", info: [] }));
        yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "monitorList", info: [] }));
      }
      yield put(getCurrentProfile(verificationId));
    }
  } catch (error) {
    console.error(error);
    yield call(cleanVerificationSimulationInfo, verificationId);
    return;
  }
}

function* cleanVerificationSimulationInfo(verificationId) {
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "endMsg", info: null }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingIndex", info: -1 }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "waitingTime", info: null }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "stackupError", info: null }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "simulationMsg", info: null }));
  yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "running", info: null }));
}

function* getLog(action) {
  const { verificationId, info = {}, mergeVerificationId } = action;
  const { pageType, dataType } = info;
  let _log = '', logList = [];
  const promise = yield call(checkVerificationStatus, mergeVerificationId ? mergeVerificationId : verificationId);
  let existLog = false;

  if (promise && promise.status) {
    if (promise.status !== VERIFY_NEVER) {
      existLog = true;
    }
  };
  if (existLog) {
    if (pageType === SSN_CHANNEL) {
      let res = yield call(getSSNChannelLogPromise, mergeVerificationId ? mergeVerificationId : verificationId);
      if (res && res.data && res.data.length) {
        yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "logList", info: res.data || [] }));
      }

    } else {
      let log = yield call(getVerificationLogByID, verificationId);
      if (log) {
        _log = log;
      }
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "log", info: _log }));
    }

    yield put(getCurrentProfile(verificationId));
  }
}

export function* simulationErrorCheck(action) {
  const { currentChannels, ssnSelectPDNIds, ignoreCardModel, isSSN, ssnVerificationId, notGetPkgConfig } = action;
  const ddrType = yield call(getProjectType)
  let verificationIds = action.verificationIds;
  let currentSimInfo = action.currentSimInfo;
  const { RockyReducer: { project: { currentProjectId, currentChannelId, projectList, ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, ebdList, pkgTouchstoneList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList } } } = yield select();
  let errorCheckIds = [], pdnCheckIds = [];//Exist error check verification id;
  let { RockyReducer } = yield select();
  let simulationReducer = RockyReducer.simulationReducer;

  const designId = projectDesigns.getAvailableDesignsFirstId(currentProjectId);

  let PDNErrorCheckList = [], isPackageConfig = true;
  let currentChannelList = isSSN ? [] : currentChannels.map(id => rockyChannels.get(id));
  //filter current channels by pdn exist
  currentChannelList = currentChannelList.filter(item => item.children && item.children.find(it => it.type === ROCKY_PDN && item.id));

  //error check all pdn
  yield* currentChannelList.map(function* (item) {
    const { RockyReducer: { RockyPDN } } = yield select();
    let pdnErrorCheck = RockyPDN && RockyPDN.pdnErrorCheck && RockyPDN.pdnErrorCheck.length ? RockyPDN.pdnErrorCheck : [];
    //find current channel children ( pdn, byte0, ... , clk_adr)
    const currentPdn = item.children.find(it => it.type === ROCKY_PDN);
    const PDNID = currentPdn ? currentPdn.rockyPdnId : null;
    //error check power component
    const res = currentPdn ? yield call(getChannelPDNContentPromise, PDNID) : null;
    if (!PDNID) {
      message.error(`${item.name} does not have PDN, please check the settings.`);
    }
    const pdnContent = res && res.pdnContent ? res.pdnContent : {};
    //find select pdn
    const findPDN = ssnSelectPDNIds.find(it => it.PDNID === PDNID);
    //if pdn is selected, check all content, if not selected check power component
    const errorCheck = findPDN ? getRockyPdnErrorCheck(pdnContent) : null;
    PDNErrorCheckList.push({
      PDNID,
      verificationId: currentPdn.id,
      channelId: item.id,
      errorCheck,
      errorType: findPDN ? 'all' : 'powerComp'
    });

    const errorIndex = pdnErrorCheck.findIndex(error => error.verificationId === currentPdn.id);

    //update pdn error check
    if (findPDN && errorCheck) {
      currentSimInfo.push({
        verificationId: currentPdn.id,
        pdnId: PDNID,
        status: 'error'
      })
      pdnCheckIds.push(PDNID);
      if (errorIndex > -1) {
        pdnErrorCheck[errorIndex].errorCheck = { ...errorCheck };
      } else {
        pdnErrorCheck.push({
          verificationId: currentPdn.id,
          errorCheck,
          pdnId: PDNID
        })
      }
    } else {
      pdnErrorCheck = pdnErrorCheck.filter(it => it.verificationId !== currentPdn.id);
    }
    yield put(updateRockyPDNErrorList(pdnErrorCheck));
  })

  //check stackup data (if pcb is prelayout, check signal group);
  let stackupError = null, prelayoutError = null;
  const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId)
  if (designId && !isSSN) {
    if (vendor !== PRE_LAYOUT) {
      //DESIGN stackup.json error check
      const stackup = yield call(getDesignStackupJson, designId);
      if (!stackup || !stackup.layers) {
        stackupError = [{ error: 'Stackup file does not exist!' }];
      } else {
        stackupError = stackupErrorCheck({
          ...stackup,
          layers: stackup.layers,
          materialList: stackup.materials,
          unit: stackup.unit,
        });
      }
    } else {
      const res = yield call(getPreLayoutInfoById, designId);
      prelayoutError = res && res.content ? res.content.prelayout === MODEL ? null : preLayoutJsonErrorCheck(res.content['signal_groups']) : { error: ['preLayout.json file does not exist!'] };
    }
  }

  const currentSSNChannelInfo = {};

  let channels = []; //{ channelId, verificationIds: [id1,id2], pdnId }
  let channelExtractions = {}, notSetCardVerifications = [];
  for (let item of verificationIds) {
    //get current verification's channelId
    let channelId = null;
    if (isSSN) {
      const info = RockySSNChannelInfo.getCurrentInterfaceInfo(item);
      channelId = info && info.channelId ? info.channelId : null;
      if (ssnVerificationId && !currentSSNChannelInfo[channelId]) {
        currentSSNChannelInfo[channelId] = RockySSNChannelInfo.getInfo(channelId)
      }
    } else {
      channelId = VerificationInfo.getVerificationInfo(item);
    }

    //delete setup info by id(Update ifDoExtraction)
    ByteSetupInfo.deleteSetupInfo(item);
    // error check
    //get current verification content
    const responseInfo = yield call(getVerificationContentPromise, item);

    let { RockyReducer: { rocky: { infoErrorCheck }, project: { extraction, currentChannelId, currentProjectId } } } = yield select();
    const index = currentSimInfo.findIndex(sim => sim.verificationId === item);
    channelExtractions[currentChannelId] = JSON.parse(JSON.stringify(extraction));
    let _extraction = channelExtractions[channelId] || {};
    //if verification channel is not opened
    if (!channelExtractions[channelId]) {
      try {
        const configRes = yield call(getChannelConfig, channelId);
        _extraction = configRes && configRes.config && configRes.config.extraction
          ? configRes.config.extraction
          : _extraction;
        channelExtractions[channelId] = _extraction ? JSON.parse(JSON.stringify(_extraction)) : null;
      } catch (error) {
        console.error(error);
      }
    }
    if (responseInfo && responseInfo.interfaces) {
      const SETUP = RockySetup;
      //if interfaces not init,return
      if ((!responseInfo.version || responseInfo.version === "0")) {
        simulationReducer[item] = {};
        if (index > -1) {
          currentSimInfo[index].status = 'error';
        }

        errorCheckIds.push(item);
        const errorIndex = infoErrorCheck.findIndex(error => error.verificationId === item);
        if (errorIndex > -1) {
          infoErrorCheck[errorIndex].errorCheck = { error: ["Interface has not been initialized!"] };
        } else {
          infoErrorCheck.push({
            verificationId: item,
            errorCheck: { error: ["Interface has not been initialized!"] }
          })
        }
        continue;
      }

      let Interfaces = [...responseInfo.interfaces];
      let pcbInfo = yield call(getLayoutPCBInfo, responseInfo.designId);
      const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId);
      const interfaceIndex = Interfaces.findIndex(i => portSavePath.find(p => i.name.match(p))) || 0;
      const content = Interfaces[interfaceIndex].Content;
      if (vendor !== PRE_LAYOUT) {
        let { ReferenceNets, Port_setups, Ports_generation_setup, Ports_generate_setup_list, Components } = content;
        //update ports generation setup format
        if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
          Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
          Interfaces[interfaceIndex].Content.Ports_generate_setup_list = Ports_generate_setup_list;
        }

        if (content && content.Ports_generation_setup) {
          //delete ports_generation_setup
          delete Interfaces[interfaceIndex].Content.Ports_generation_setup;
        }
        if (!ReferenceNets || !Port_setups) {
          //default generation ports
          ReferenceNets = getDefaultReferenceNets(content.PowerNets);
          const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
            components: content.Components,
            signals: content.Signals,
            pcbInfo: pcbInfo,
            referenceNets: ReferenceNets,
            designId: responseInfo.designId,
            ports_generate_setup_list: Ports_generate_setup_list,
            types: ['Controller', 'Memory'],
            extractionType: _extraction.channelType
          });
          Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = { ...content, Ports_generate_setup_list: setupList, ReferenceNets, Port_setups: port_setups };
        } else {
          if (judgeUpdateGapPortSetup({ ReferenceNets, Port_setups, Ports_generate_setup_list }, _extraction)) {
            const { netsList, layers } = DesignInfo.getPCBInfo(responseInfo.designId);
            if (!netsList.length && !layers.length) {
              yield call(getLayoutPCBInfo, responseInfo.designId);
            }
            let data = updateExtractionGapPortsSetup({ ReferenceNets, Port_setups, Ports_generate_setup_list }, responseInfo.designId);
            Interfaces[interfaceIndex].Content = { ...content, ...data.newData };
          }
          if (versionCompareSize(responseInfo.version, '0.0.11')) {
            //update GAP port generate setup for components setup, gap_size
            Interfaces = updatePortsComponentsByPortType(Interfaces, Interfaces[interfaceIndex].Content.Ports_generate_setup_list, responseInfo.designId, _extraction.channelType);
          }
        }
        if (ddrType === DDR5_RDIMM && (versionCompareSize(responseInfo.version, '0.0.17') || !judgeDimmStdModelExist(Interfaces))) {
          Interfaces = yield call(changeStdModelToDimmStdModels, Interfaces)
        }
      }
      if (!ignoreCardModel && DIMM_DDR_TYPES.includes(ddrType) && !getCardModelExist(Interfaces)) {
        notSetCardVerifications.push({ id: item, name: responseInfo.name });
        continue;
      }

      if (/* isSSN && */ Interfaces[interfaceIndex].Content && !notGetPkgConfig) {
        // Before running the case, determine whether the data of the selected package is init
        const findCompInfo = Interfaces[interfaceIndex].Content.Components.find(item => item.type === CONTROLLER)
        if (findCompInfo && findCompInfo.pkg && findCompInfo.pkg.type === "Layout" && findCompInfo.pkg.packageLayoutInfo && findCompInfo.pkg.packageLayoutInfo.channelId) {
          if (isPackageConfig) {
            yield call(getPackageConfig, findCompInfo.pkg.packageLayoutInfo.packageId)
          }
          isPackageConfig = false;
          yield call(getPackageContent, { packageVerificationId: findCompInfo.pkg.packageLayoutInfo.channelId, notSave: true })
        }
      }

      const currentProject = projectList.find(project => project.id === currentProjectId);
      let projectType = currentProject ? currentProject.type : "";
      let info = SETUP.mergeInterfacesInfo(Interfaces, responseInfo.name, projectType);
      const rockyInfo = { info, Interfaces: Interfaces, verificationId: item }
      CurrentVerificationInfo.setInfo(item, rockyInfo)
      //update touchstone file macro modeling status list 
      const Components = info.Components;
      const touchstoneFileStatusMsgObj = yield call(_UpdateTouchstoneStatus, { Components, verificationId: item });
      const msgList = touchstoneFileStatusMsgObj ? touchstoneFileStatusMsgObj.msgList : null;
      if (msgList && msgList.length > 0) {
        simulationReducer[item] = {};
        if (index > -1) {
          currentSimInfo[index].status = 'error';
        }
        errorCheckIds.push(item);
      }

      const ssnChannelInfo = currentSSNChannelInfo[channelId];
      // const powerNets = isSSN && ssnVerificationId && ssnChannelInfo && ssnChannelInfo.content && ssnChannelInfo.content.pdn ? ssnChannelInfo.content.pdn.powerNets : [];
      let includePdn = false, runType = "ALL";
      if (isSSN && ssnVerificationId && ssnChannelInfo && ssnChannelInfo.content) {
        includePdn = ssnChannelInfo.content.includePdn ? ssnChannelInfo.content.includePdn : false;
        runType = ssnChannelInfo.content.runType
      }

      let errorCheck = getErrorCheck(rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, _extraction.channelType, projectType, includePdn, isSSN, runType);
      if (errorCheck) {
        simulationReducer[item] = {};
        if (index > -1) {
          currentSimInfo[index].status = 'error';
        }
        errorCheckIds.push(item);
        const errorIndex = infoErrorCheck.findIndex(error => error.verificationId === item);
        if (errorIndex > -1) {
          infoErrorCheck[errorIndex].errorCheck = { ...errorCheck };
        } else {
          infoErrorCheck.push({
            verificationId: item,
            errorCheck: errorCheck
          })
        }
        yield put(updateErrorCheckList(infoErrorCheck));
      } else {
        const findPDNError = PDNErrorCheckList.find(it => it.channelId === channelId);
        const pdnErrorCheck = findPDNError ? findPDNError.errorCheck : null;
        const errorIndex = infoErrorCheck ? infoErrorCheck.findIndex(error => error.verificationId === item) : -1;
        if (pdnErrorCheck) {
          if (errorIndex > -1) {
            infoErrorCheck[errorIndex].PDNErrorCheck = { ...pdnErrorCheck };
            infoErrorCheck[errorIndex].channelId = channelId;
            infoErrorCheck[errorIndex].errorType = findPDNError.errorType;
          } else {
            infoErrorCheck.push({
              verificationId: item,
              errorCheck: null,
              PDNErrorCheck: pdnErrorCheck,
              channelId,
              errorType: findPDNError.errorType
            })
          }
        } else {
          infoErrorCheck = infoErrorCheck.filter(it => it.verificationId !== item);
        }

        yield put(updateErrorCheckList(infoErrorCheck));

        if ((stackupError && stackupError.length) || pdnErrorCheck || prelayoutError) {
          errorCheckIds.push(item);
          simulationReducer[item] = {};
          simulationReducer[item].stackupError = stackupError;
          simulationReducer[item].prelayoutError = prelayoutError;
          if (index > -1) {
            currentSimInfo[index].status = 'error';
          }
        } else if (!errorCheckIds.includes(item)) {
          simulationReducer[item] = {};
          simulationReducer[item].startMsg = '==> Start simulating...';
          if (index > -1) {
            currentSimInfo[index].status = 'success';
          }

          const findCIndex = channels.findIndex(i => i.channelId === channelId);
          //find current verification's channel PDN
          const findPDN = ssnSelectPDNIds.find(pdn => pdn.channelId === channelId);
          const pdnId = findPDN ? findPDN.PDNID : null;
          if (findCIndex > -1) {
            channels[findCIndex].verificationIds = channels[findCIndex].verificationIds ? [...channels[findCIndex].verificationIds, item] : [item];
            channels[findCIndex].pdnId = pdnId;
          } else {
            if (!pdnCheckIds.includes(pdnId)) {
              channels.push({
                channelId,
                verificationIds: [item],
                pdnId
              })
            } else {
              simulationReducer[item] = {};
            }
          }
        }
      }
    }
  }

  errorCheckIds = [...new Set([...errorCheckIds])];

  //update tree verification status
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId, currentSimInfo }));
  //update simulation reducer
  yield put(updateSimulationReducer(simulationReducer));

  if (!ignoreCardModel && notSetCardVerifications && notSetCardVerifications.length) {
    for (let item of notSetCardVerifications) {
      simulationReducer[item.id] = {}
    }
    yield put(updateSimulationReducer(simulationReducer));
    yield put(updateNotSetCardVerifications(notSetCardVerifications));
  }

  // remove exist error check verification
  verificationIds = verificationIds.filter(item => !errorCheckIds.includes(item));

  if (!verificationIds || verificationIds.length === 0 || !channels || channels.length === 0) {
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
    return { verificationIds: [], channels: [], notSetCardVerifications, errorCheckIds };
  };
  return { verificationIds, channels, notSetCardVerifications, errorCheckIds };
}

function* _updateCurrentSimulationMsg(action) {
  const { verificationId } = action;
  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  let { RockyReducer: { rocky: { infoErrorCheck, TouchstoneStatusList } } } = yield select();

  let currentSimInfo = [];
  currentSimInfo.push({
    verificationId,
    status: 'checking'
  });
  //clean prev simulation info by verificationId
  delete newSimulationReducer[verificationId];
  const time = new Date();
  time.setUTCSeconds(time.getUTCSeconds());
  let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
  newSimulationReducer[verificationId] = {
    startMsg: `==> ${waitingTime} Checking setup...`
  }
  yield put(updateSimulationReducer(newSimulationReducer));

  //clear prev error message
  infoErrorCheck = infoErrorCheck.filter(it => it.verificationId !== verificationId);
  //update verification error message
  yield put(updateErrorCheckList(infoErrorCheck));

  //clear prev verification touchstone macro modeling status message
  TouchstoneStatusList = TouchstoneStatusList.filter(it => it.verificationId !== verificationId);
  //update verification Touchstone macro modeling status message
  yield put(updateTouchstoneStatusList(TouchstoneStatusList));

  const { RockyReducer: { project: { currentProjectId, currentChannelId, ssnSelectPDNIds } } } = yield select();
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId, currentSimInfo }));

  //clear prev pdn error message
  const { RockyReducer: { RockyPDN: { pdnErrorCheck } } } = yield select();
  let _pdnErrorCheck = pdnErrorCheck && pdnErrorCheck.length ? pdnErrorCheck : [];
  ssnSelectPDNIds.forEach(item => {
    _pdnErrorCheck = _pdnErrorCheck.filter(it => it.pdnId !== item.PDNID);
  });
  yield put(updateRockyPDNErrorList(_pdnErrorCheck));
}

function* generateEyediagram(action) {
  const { params } = action;
  const verificationIds = params.map(d => d.verificationId);
  // Clean cache
  eyediagramResult.cleanCache(verificationIds);
  //customized eye diagram cache
  CustomizedEye.cleanCache(verificationIds);
  try {
    const responseData = yield call(eyediagramGeneratePromise, params);
    // Running PPROC, update menu status
    // Cleanup eyediagram data;
    //update tree verification status
    const { RockyReducer: { project: { currentProjectId, currentChannelId } } } = yield select();
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId, isUpdateDiagram: true }));
    // open monitor
    yield put(openTabFooter());
    // monitor
    if (Array.isArray(responseData) && responseData.length > 0) {
      const { RockyReducer: { result: { resultInfo }, project: { contentType, openVerificationId } } } = yield select();
      const _openVerificationId = contentType === SSN_RESULT && resultInfo && resultInfo.verificationId ? resultInfo.verificationId : openVerificationId;
      const verificationWork = responseData.find(d => d.verificationId === _openVerificationId);
      if (verificationWork) {
        if (workflowTask) {
          yield cancel(workflowTask);
        };
        workflowTask = yield fork(verificationWorkFlow, { verificationId: _openVerificationId, verificationWork: [verificationWork], isUpdateDiagram: true });
      }
    }
  } catch (error) {
    console.error(error);
    return;
  }
}

function* cancelWorkflowJudge({ verificationId, simulationType }) {
  const { LoginReducer: { pageType }, RockyReducer: { project: { selectedKeys, contentType }, rockySSN: { id } },
    MonitorInfoReducer: { monitorScreenInfo: { currentVerificationId } } } = yield select();

  let verificationKey = VERIFICATION,
    resultKey = RESULT;

  switch (simulationType) {
    case PACKAGE:
      verificationKey = PACKAGE_VERIFICATION;
      resultKey = PACKAGE_RESULT;
      break;
    case CARD:
      verificationKey = CARD_VERIFICATION;
      resultKey = CARD_RESULT;
      break;
    default: break;
  }

  if ([SSN_VERIFICATION, SSN_RESULT].includes(contentType)) {
    verificationKey = SSN_CHANNEL;
    resultKey = SSN_RESULT;
  }

  if (pageType !== ROCKY || workflowJudge({
    selectedKeys,
    verificationId: verificationId,
    verificationKey: [SSN_VERIFICATION, SSN_RESULT].includes(contentType) ? `${verificationKey}-${id}` : `${verificationKey}-${verificationId}`,
    resultKey: `${resultKey}-${verificationId}`,
    monitorVerificationId: currentVerificationId
  })) {
    return true;
  }
}

function* startPackageVerification(action) {
  let { verificationIds, currentVerificationId } = action;
  // yield put(changeVerificationList([]));
  yield put(changeVerificationList([], 'packageSimulateKeys'));
  if (verificationIds.length === 0) {
    return;
  }

  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }

  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  let currentSimInfo = [], packageSimulationList = [];
  verificationIds.forEach(item => {
    const { id, verificationId } = item;
    packageSimulationList.push(id)
    currentSimInfo.push({
      verificationId: id,
      status: 'checking'
    })
    //clean prev simulation info by verificationId
    delete newSimulationReducer[verificationId];

    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    newSimulationReducer[verificationId] = {
      startMsg: `==> ${waitingTime} Checking setup...`
    }

    ResultData.cleanVerificationResultData(id);
  })

  yield put(updateSimulationReducer(newSimulationReducer));

  const { RockyReducer: { project: { currentProjectId, currentPackageDesignId } } } = yield select();
  const currentPackageVerificationInfo = packageVerificationConstructor.getPackageVerifications(currentPackageDesignId, currentVerificationId);

  yield put(autoGetVerificationList({
    projectId: currentProjectId,
    currentPkgDesignId: currentPackageDesignId,
    currentSimInfo,
    verificationType: PACKAGE
  }));

  //error check
  const stackupError = currentPackageDesignId ? yield call(stackupCheck, currentPackageDesignId) : null;
  const { error: modelError } = currentVerificationId ? yield call(getChannelPowerComponentsModelCheck, currentVerificationId, PACKAGE) : null;
  if (stackupError && stackupError.length) {
    newSimulationReducer[currentPackageVerificationInfo.verificationId] = {
      stackupError
    }
    yield put(updateSimulationReducer(newSimulationReducer));
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: currentPackageDesignId,
      verificationType: PACKAGE
    }));
    return;
  }

  if (modelError) {
    let _channelErrorCheckList = [];
    const find = verificationIds.find(item => item.id === currentVerificationId);
    const verificationId = find ? find.verificationId : null;
    modelError && _channelErrorCheckList.push({
      currentVerificationId: verificationId,
      errorCheck: { error: modelError },
    })
    yield put(updateChannelErrorCheckList(_channelErrorCheckList))
    newSimulationReducer[verificationId] = {
      modelError: modelError || null
    }
    yield put(updateSimulationReducer(newSimulationReducer));
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: currentPackageDesignId,
      verificationType: PACKAGE
    }));
    return;
  }

  // doRockySimulation
  let waitingTask = null;
  try {
    const verificationIdList = verificationIds.map(item => item.verificationId)
    waitingTask = yield fork(updateWaitingStartMsg, { verificationIds: verificationIdList, _simulation: newSimulationReducer })
    const response = yield call(doRockyPackageSimulation, packageSimulationList);
    yield cancel(waitingTask);
    // get package interface verification id
    const verificationStatus = response && response.length ? response.filter(item => item.verificationId === currentPackageVerificationInfo.verificationId) : [];
    if (verificationStatus && verificationStatus.length > 0) {
      if (workflowTask) {
        yield cancel(workflowTask);
      };
      workflowTask = yield fork(verificationWorkFlow, {
        verificationId: currentPackageVerificationInfo.verificationId,
        verificationWork: verificationStatus,
        simulationType: "package"
      });
      return;
    } else {
      yield* verificationIds.map(function* (item) {
        const { verificationId } = item;
        newSimulationReducer[verificationId] = {};
        let msg = `==> Package has been extracted completely, no need to extract again!`;
        newSimulationReducer[verificationId].simulationMsg = msg;
        yield put(getVerificationLog(verificationId));
      })

      yield put(updateSimulationReducer(newSimulationReducer));
      yield put(autoGetVerificationList({
        projectId: currentProjectId,
        currentPkgDesignId: currentPackageDesignId,
        verificationType: PACKAGE
      }));
      return
    }
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    verificationIds.forEach(item => {
      const { verificationId } = item;
      newSimulationReducer[verificationId] = {};
      let msg = error ? `==> [ERROR] ${error}` : "==> Simulation failed!";
      newSimulationReducer[verificationId].endMsg = msg;
    })
    //update simulation reducer
    yield put(updateSimulationReducer(newSimulationReducer));
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: currentPackageDesignId,
      verificationType: PACKAGE
    }));
    //update tree verification status
    return
  }
}

function* startPackagePDN(action) {
  let { id, verificationId, contentType } = action;
  // yield put(changeVerificationList([]));
  yield put(changeVerificationList([], 'packageSimulateKeys'));

  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }

  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  let currentSimInfo = [], packageSimulationList = [];
  packageSimulationList.push(id)
  currentSimInfo.push({
    verificationId: id,
    status: 'checking'
  })
  //clean prev simulation info by verificationId
  delete newSimulationReducer[verificationId];
  //save content
  yield call(saveRockyPackagePDNContentToServer);

  const time = new Date();
  time.setUTCSeconds(time.getUTCSeconds());
  let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
  newSimulationReducer[verificationId] = {
    startMsg: `==> ${waitingTime} Checking setup...`
  }
  ResultData.cleanVerificationResultData(id);
  yield put(updateSimulationReducer(newSimulationReducer));

  const { RockyReducer: { project: { currentProjectId, currentPackageDesignId, contentType: _contentType }, rocky: { pdnErrorCheckList } } } = yield select();

  const { error: pdnError, stackupError } = yield call(getPdnSimulationErrorCheck, { id, contentType })
  let _pdnErrorCheckList = pdnErrorCheckList || [];
  if (pdnError || (stackupError && stackupError.length)) {
    pdnError && _pdnErrorCheckList.push({
      verificationId,
      errorCheck: { error: pdnError },
    })
    yield put(updatePdnErrorCheck(_pdnErrorCheckList))
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: currentPackageDesignId,
      verificationType: PACKAGE
    }));
    newSimulationReducer[verificationId] = {
      stackupError: stackupError || null
    }
    yield put(updateSimulationReducer(newSimulationReducer));
    return;
  } else {
    _pdnErrorCheckList = _pdnErrorCheckList.filter(item => item.verificationId !== verificationId)
  }
  yield put(updatePdnErrorCheck(_pdnErrorCheckList))
  yield put(autoGetVerificationList({
    projectId: currentProjectId,
    currentPkgDesignId: currentPackageDesignId,
    currentSimInfo,
    verificationType: PACKAGE
  }));

  // doRockySimulation
  let waitingTask = null;
  try {
    waitingTask = yield fork(updateWaitingStartMsg, { verificationIds: [verificationId], _simulation: newSimulationReducer })
    const response = yield call(doRockyPackagePDNSimulation, id);
    yield cancel(waitingTask);
    // get package interface verification id
    const verificationStatus = response
    if (verificationStatus) {
      if (workflowTask) {
        yield cancel(workflowTask);
      };
      workflowTask = yield fork(verificationWorkFlow, {
        verificationId: verificationId,
        verificationWork: [verificationStatus],
        simulationType: "package"
      });
      return;
    } else {
      newSimulationReducer[verificationId] = {};
      const type = _contentType === PCB_PDN ? "PCB PDN" : "Package PDN";
      let msg = `==> ${type} has been extracted completely, no need to extract again!`;
      newSimulationReducer[verificationId].simulationMsg = msg;
      yield put(getVerificationLog(verificationId));

      yield put(updateSimulationReducer(newSimulationReducer));
      yield put(autoGetVerificationList({
        projectId: currentProjectId,
        currentPkgDesignId: currentPackageDesignId,
        verificationType: PACKAGE
      }));
      return
    }
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    newSimulationReducer[verificationId] = {};
    let msg = error ? `==> [ERROR] ${error}` : "==> Simulation failed!";
    newSimulationReducer[verificationId].endMsg = msg;
    //update simulation reducer
    yield put(updateSimulationReducer(newSimulationReducer));
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: currentPackageDesignId,
      verificationType: PACKAGE
    }));
    //update tree verification status
    return
  }
}

export function* getPdnSimulationErrorCheck({ id, contentType }) {
  const { RockyReducer: { rocky: { packagePDNInfo }, project: { contentType: currentType } } } = yield select();
  let res = {}
  if (packagePDNInfo && packagePDNInfo.id === id) {
    res = { ...packagePDNInfo };
  } else {
    res = yield call(getRockyPackagePDNInfo, id);
  }
  let error = res && res.content ? sevPDNErrorCheck(res.content, contentType ? contentType : currentType) : null;
  //DESIGN stackup.json error check
  let stackupError = yield call(stackupCheck, res.designId);
  return { error, stackupError };
}

export function* getSignalGroupSimulationErrorCheck({ content, getStackError, currentDesignId }) {
  let error = [], stackupError = []
  error = getRlcCompsValueCheck({
    Components: content.Components,
    error,
    resNotZero: true,
    PowerComponents: content.PowerComponents,
  });

  if (getStackError && currentDesignId) {
    stackupError = yield call(stackupCheck, currentDesignId)
  }

  return { error, stackupError }
}

export function* stackupCheck(designId) {
  //DESIGN stackup.json error check
  let stackupError = null;
  try {
    const stackup = yield call(getDesignStackupJson, designId);
    if (!stackup || !stackup.layers) {
      stackupError = [{ error: 'Stackup file does not exist!' }];
    } else {
      stackupError = stackupErrorCheck({
        ...stackup,
        layers: stackup.layers,
        materialList: stackup.materials,
        unit: stackup.unit,
      });
    }
    return stackupError;
  } catch (error) {
    return stackupError;
  }
}

export function updateWorkflowTask(task) {
  workflowTask = task;
}

export function getWorkflowTask() {
  return workflowTask;
}

export function* updateWaitingStartMsg(action) {
  const { verificationIds, _simulation, isSSN = false, ssnMsg } = action;
  let time, isFirst = true, indexNum = 1, _msg = isSSN && ssnMsg ? ssnMsg : "Data pre-processing...";
  while (true) {
    if (isFirst) {
      yield delay(3000);
    } else {
      yield delay(15000);
    }
    time = new Date();
    isFirst = false
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    // eslint-disable-next-line no-loop-func
    verificationIds.forEach(item => {
      const _progress = fakeProgress(_simulation[item].progress || 0, indexNum, _simulation[item].progress || 0);

      const addMsgs = `==> ${waitingTime} ${_msg}`;
      _simulation[item] = {
        ..._simulation[item],
        startMsg: _simulation[item].startMsg/*  + addMsg */,
        waitingStartLogs: [...(_simulation[item].waitingStartLogs || []), addMsgs],
        progress: isSSN ? _progress : -1,
        cancelDisabled: true
      };
    })
    indexNum += 1;
    yield put(updateSimulationReducer(_simulation));
  }
}

function* startRockyPcbChannel(action) {
  const { id, verificationId } = action;
  try {
    yield put(changeVerificationList([], 'packageSimulateKeys'));
    if (!id) { return }
    if (workflowTask) {
      yield cancel(workflowTask);
    }

    let reducer = yield select();
    let simulationReducer = reducer.RockyReducer.simulationReducer;
    let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};
    let currentSimInfo = [];
    currentSimInfo.push({
      verificationId: id,
      status: 'checking'
    })
    delete newSimulationReducer[verificationId];
    const { RockyReducer: { project: { currentProjectId, currentPackageDesignId } } } = yield select();
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    newSimulationReducer[verificationId] = {
      startMsg: `==> ${waitingTime} Checking setup...`
    }
    ResultData.cleanVerificationResultData(id);
    yield put(updateSimulationReducer(newSimulationReducer));
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: currentPackageDesignId,
      currentSimInfo,
      verificationType: PACKAGE
    }));
    //error check
    const currentChannelInfo = rockyChannels.get(id);

    //clear prev pdn error message
    const { RockyReducer: { rocky: { infoErrorCheck } } } = yield select();
    let _infoErrorCheck = infoErrorCheck && infoErrorCheck.length ? infoErrorCheck : [];

    const pcbSignalInfo = yield call(PCBChannelContentConstructor.getPcbChannelInfo, id, true);
    const { error: signalError, stackupError } = yield call(getSignalGroupSimulationErrorCheck, { content: pcbSignalInfo.contentDTO, contentType: PCB, getStackError: true, currentDesignId: currentChannelInfo && currentChannelInfo.designId ? currentChannelInfo.designId : null })
    if ((signalError && signalError.length) || (stackupError && stackupError.length)) {
      signalError && signalError.length && _infoErrorCheck.push({
        verificationId,
        errorCheck: { error: signalError },
      })
      yield put(updateErrorCheckList(_infoErrorCheck))
      yield put(autoGetVerificationList({
        projectId: currentProjectId,
        currentPkgDesignId: currentPackageDesignId,
        verificationType: PACKAGE
      }));
      newSimulationReducer[verificationId] = {
        stackupError: stackupError || null
      }
      yield put(updateSimulationReducer(newSimulationReducer));
      return;
    } else {
      _infoErrorCheck = _infoErrorCheck.filter(it => it.verificationId !== verificationId);
    }
    yield put(updateErrorCheckList(_infoErrorCheck))

    /*  yield put(autoGetVerificationList({
       projectId: currentProjectId,
       currentPkgDesignId: currentPackageDesignId,
       verificationType: PACKAGE
     }));
  */
    // doRockySimulation
    let waitingTask = null;
    try {
      waitingTask = yield fork(updateWaitingStartMsg, { verificationIds: [verificationId], _simulation: newSimulationReducer })
      const response = yield call(doRockyPCBChannelSimulation, id);
      yield cancel(waitingTask);
      // get package interface verification id
      const verificationStatus = response && response.length ? response.filter(item => item.verificationId === verificationId) : [];
      if (verificationStatus && verificationStatus.length) {
        if (workflowTask) {
          yield cancel(workflowTask);
        };
        workflowTask = yield fork(verificationWorkFlow, {
          verificationId: verificationId,
          verificationWork: verificationStatus,
          simulationType: "package"
        });
        return;
      } else {
        newSimulationReducer[verificationId] = {};
        let msg = `==> PCB channel has been extracted completely, no need to extract again!`;
        newSimulationReducer[verificationId].simulationMsg = msg;
        yield put(getVerificationLog(verificationId));

        yield put(updateSimulationReducer(newSimulationReducer));
        yield put(autoGetVerificationList({
          projectId: currentProjectId,
          currentPkgDesignId: currentPackageDesignId,
          verificationType: PACKAGE
        }));
        return
      }
    } catch (error) {
      if (waitingTask) { yield cancel(waitingTask); }
      newSimulationReducer[verificationId] = {};
      let msg = error ? `==> [ERROR] ${error}` : "==> Simulation failed!";
      newSimulationReducer[verificationId].endMsg = msg;
      //update simulation reducer
      yield put(updateSimulationReducer(newSimulationReducer));
      yield put(autoGetVerificationList({
        projectId: currentProjectId,
        currentPkgDesignId: currentPackageDesignId,
        verificationType: PACKAGE
      }));
      //update tree verification status
      return
    }

  } catch (error) {
    console.error(error)
  }
}

function* startSeparateExtraction(action) {
  const { obj } = action;
  const { pcbSignal, pcbPDN, packageSignal, packagePdn, packageId, packageSignalInfo, packagePdnInfo, pcbSignalInfo, pcbPdnInfo, currentProjectId } = obj;

  if (pcbSignal && pcbSignalInfo && pcbSignalInfo.channelId) {
    // run pcb channel
    yield call(doRockyPCBChannelSimulation, pcbSignalInfo.channelId);
  }

  if (pcbPDN && pcbPdnInfo && pcbPdnInfo.id) {
    // run pcb pdn
    yield call(doRockyPackagePDNSimulation, pcbPdnInfo.id);
  }

  if (packageSignal && packageSignalInfo && packageSignalInfo.id) {
    // run package signal
    let packageSimulationList = [packageSignalInfo.id]
    yield call(doRockyPackageSimulation, packageSimulationList);

  }

  if (packagePdn && packagePdnInfo && packagePdnInfo.id) {
    // run package pdn
    yield call(doRockyPackagePDNSimulation, packagePdnInfo.id);
  }

  // update libraryTree
  yield put(autoGetVerificationList({
    projectId: currentProjectId,
    currentPkgDesignId: packageId,
  }));
}

function* getSSNVerificationLog(action) {
  const { obj } = action;
  try {
    let { RockyReducer: { simulationReducer } } = yield select();
    const { dataType, currentVerificationId } = obj;
    if (simulationReducer[currentVerificationId]) {
      const { monitorList } = simulationReducer[currentVerificationId];
      if (monitorList && monitorList.length) {
        const displayName = dataType === PDN_EXTRACTION ? "PDN Extraction" : dataType === SIGNAL_EXTRACTION ? "Signal Extraction" : "Simulation";
        const currentInfo = monitorList.find(item => item.displayName === displayName)
        if (!currentInfo) {
          yield put(getVerificationLog(currentVerificationId, { pageType: SSN_CHANNEL }));
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* getChannelPowerComponentsModelCheck(designId, type) {
  const res = yield call(getPackageVerificationInfoPromise, designId);
  let content = null;
  if (type === PCB) {
    content = res && res.contentDTO ? res.contentDTO : null;
  } else if (type === PACKAGE) {
    content = res && res.content ? res.content : null;
  }
  const PowerComponents = content && content.PowerComponents ? content.PowerComponents : null;
  const error = PowerComponents && PowerComponents.length ? getChannelPowerComponentsErrorCheck(PowerComponents) : null;
  return { error };
}

function* startPreLayoutSimulation(action) {
  const { obj } = action;
  const { pcbType, verificationId, id, channelId } = obj
  if (!verificationId) {
    return;
  }

  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  // save preLayout data
  yield call(saveModelPreLayoutToServer, { updateImmediately: true })

  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};
  yield put(openTabFooter())
  let currentSimInfo = [({
    verificationId: id,
    status: 'checking'
  })]
  delete newSimulationReducer[verificationId];
  const time = new Date();
  time.setUTCSeconds(time.getUTCSeconds());
  let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
  newSimulationReducer[verificationId] = {
    startMsg: `==> ${waitingTime} Checking setup...`
  }
  ResultData.cleanVerificationResultData(id);

  const { RockyReducer: { project: { currentProjectId, currentPackageDesignId } } } = yield select();
  yield put(updateSimulationReducer(newSimulationReducer));
  yield put(autoGetVerificationList({
    projectId: currentProjectId,
    currentPkgDesignId: currentPackageDesignId,
    currentSimInfo,
    verificationType: pcbType
  }));

  //clear prev pdn error message
  const { RockyReducer: { rocky: { infoErrorCheck } } } = yield select();
  let _infoErrorCheck = infoErrorCheck && infoErrorCheck.length ? infoErrorCheck : [];
  const signalError = yield call(getPreLayoutErrorCheck, { verificationId })
  if (signalError && signalError.length) {
    _infoErrorCheck.push({
      verificationId,
      errorCheck: { error: signalError },
    })
    newSimulationReducer[verificationId] = {}
    yield put(updateErrorCheckList(_infoErrorCheck))
    yield put(updateSimulationReducer(newSimulationReducer));
    return;
  } else {
    _infoErrorCheck = _infoErrorCheck.filter(it => it.verificationId !== verificationId);
  }
  yield put(updateErrorCheckList(_infoErrorCheck))

  let waitingTask = null;

  try {
    waitingTask = yield fork(updateWaitingStartMsg, { verificationIds: [verificationId], _simulation: newSimulationReducer })
    const response = pcbType === PACKAGE ? yield call(doRockyPackageSimulation, [channelId]) : yield call(doRockyPCBChannelSimulation, channelId);
    yield cancel(waitingTask);
    // get package interface verification id
    const verificationStatus = response.filter(item => item.verificationId === verificationId)
    if (verificationStatus) {
      if (workflowTask) {
        yield cancel(workflowTask);
      };
      workflowTask = yield fork(verificationWorkFlow, {
        verificationId: verificationId,
        verificationWork: verificationStatus,
        simulationType: pcbType
      });
      return;
    } else {
      newSimulationReducer[verificationId] = {};
      const title = pcbType === PACKAGE ? "Package" : "PCB";
      let msg = `==> ${title} PreLayout has been extracted completely, no need to extract again!`;
      newSimulationReducer[verificationId].simulationMsg = msg;
      yield put(getVerificationLog(verificationId));

      yield put(updateSimulationReducer(newSimulationReducer));
      yield put(autoGetVerificationList({
        projectId: currentProjectId,
        currentPkgDesignId: currentPackageDesignId,
        verificationType: PACKAGE
      }));
      return
    }
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    newSimulationReducer[verificationId] = {};
    let msg = error ? `==> [ERROR] ${error}` : "==> Simulation failed!";
    newSimulationReducer[verificationId].endMsg = msg;
    //update simulation reducer
    yield put(updateSimulationReducer(newSimulationReducer));
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: currentPackageDesignId,
      verificationType: pcbType
    }));
    //update tree verification status
    return
  }
}

function* getPreLayoutErrorCheck(action) {
  try {
    const { verificationId } = action;
    const { RockyReducer: { preLayout: { verificationId: currentVerificationId, content } } } = yield select();
    if (verificationId !== currentVerificationId) { return null }
    const modelInfo = content && content.model ? content.model : {};
    if (!modelInfo || !modelInfo.modelType || modelInfo.modelType !== TOUCHSTONE || !modelInfo.libraries.length) {
      const error = ["Please select the touchstone file!"]
      return error;
    }
    return null
  } catch (error) {
    console.error(error)
  }
}

function* rockySimulationSaga() {
  yield takeEvery(START_ROCKY_VERIFICATION, startVerification);
  yield takeEvery(CANCEL_VERIFICATION_WORKFLOW, cancelVerificationWork);
  yield takeLatest(VERIFICATION_FLOW, verificationWorkFlow);
  yield takeEvery(GET_CURRENT_MONITOR, getMonitorLog);
  yield takeEvery(GET_INTERFACE_MONITOR, _getInterfaceWork);
  yield takeEvery(GET_VERIFICATION_LOG, getLog);
  yield takeEvery(UPDATE_SIMULATION_MESSAGE, _updateCurrentSimulationMsg);
  yield takeEvery(RECALCULATE_EYEDIAGRAM, generateEyediagram);
  yield takeLatest(GET_CURRENT_PROFILE, getProfileLog);
  yield takeEvery(START_ROCKY_PACKAGE_VERIFICATION, startPackageVerification);
  yield takeEvery(START_ROCKY_PACKAGE_PDN, startPackagePDN)
  yield takeEvery(START_ROCKY_PCB_CHANNEL, startRockyPcbChannel);
  yield takeEvery(START_SSN_SEPARATE_EXTRACTION, startSeparateExtraction)
  yield takeEvery(GET_CURRENT_SSN_VERIFICATION_LOG, getSSNVerificationLog);
  yield takeEvery(START_PRE_LAYOUT_SIMULATION, startPreLayoutSimulation);
};

export default rockySimulationSaga;