import { getMaxTextWidth } from "./getTextWidth";

function PackagePin(name, pin, display, component) {
  this.pin = name;
  this.pinDisplay = display !== undefined ? display : name;
  this.pinNodeDisplay = display !== undefined ? display : name;
  this.pinValue = pin ? pin.node : "";
  this.pinSubckt = pin && pin.subckt ? pin.subckt : "";
  this.pinLibraryId = pin && pin.libraryId ? pin.libraryId : "";
  this.pinModelKey = pin && pin.modelKey ? pin.modelKey : "";
  this.component = component ? component : "";
  this.pinRes = pin && pin.r ? pin.r.split(" ")[0] : "50";
}

function getPinHeight(item, defaultHeight) {
  const { pinRight, pinLeft } = item;
  const height = 32;
  let totalHeight = pinRight.length > pinLeft.length ? pinRight.length * height + 30 : pinLeft.length * height + 30;
  if (defaultHeight) {
    totalHeight = pinRight.length > pinLeft.length ? pinRight.length * defaultHeight : pinLeft.length * defaultHeight;
  }
  return totalHeight < 56 ? 56 : totalHeight;
}

/* get pin text width by pin name length */
function getPkgPinListWidth(pinList, key = "pinDisplay", params) {
  const { leftKey = "pinLeft", rightKey = "pinRight", leftTitle, rightTitle, fontSize = 12, maxWidth = 80, fontWeight = null } = params || {};
  let leftPins = [], rightPins = [];
  for (let item of pinList) {
    if (Array.isArray(item[leftKey])) {
      for (let it of item[leftKey]) {
        it[key] && leftPins.push(it[key]);
      }
    } else if (typeof (item[leftKey]) === "object") {
      item[leftKey][key] && leftPins.push(item[leftKey][key]);
    }

    if (Array.isArray(item[rightKey])) {
      for (let it of item[rightKey]) {
        it[key] && rightPins.push(it[key]);
      }
    } else if (typeof (item[rightKey]) === "object") {
      item[rightKey][key] && rightPins.push(item[rightKey][key]);
    }
  }

  if (!leftPins.length) {
    leftPins = [...rightPins];
  }

  if (!rightPins.length) {
    rightPins = [...leftPins];
  }

  if (leftTitle) {
    leftPins = [leftTitle, ...leftPins];
  }

  if (rightTitle) {
    rightPins = [rightTitle, ...rightPins];
  }

  const leftPinList = leftPins.sort((a, b) => { return b.length - a.length });
  const rightPinList = rightPins.sort((a, b) => { return b.length - a.length });

  return {
    leftPinWidth: leftPinList.length ? getMaxTextWidth(leftPinList, fontSize, maxWidth, fontWeight) : null,
    rightPinWidth: rightPinList.length ? getMaxTextWidth(rightPinList, fontSize, maxWidth, fontWeight) : null
  }
}

export {
  PackagePin,
  getPinHeight,
  getPkgPinListWidth
}