import {
  UPDATE_EXPAND_KEYS,
  UPDATE_TREE_LIST,
  UPDATE_SELECTED_KEYS,
  UPDATE_PROJECT_MENU,
  CLEAR_CURRENT_PROJECT,
  OPEN_PROJECT,
  REFRESH_PCB,
  SAVE_OPEN_PROJECT_INFO,
  OPEN_PAGE,
  OPEN_PAGE_INFO,
  CLOSE_PROJECT,
  DELETE_PCB,
  ADD_PROJECT,
  CREATE_PROJECT,
  DELETE_PROJECT,
  UPDATE_PAGE_LAYOUT,
  UPDATE_PCBS_LAYOUT_DB,
  UPDATE_TRASH_LIST,
  CLEAR_TRASH,
  PCB_REPLACE,
  PROJECT_RENAME,
  COPY_PROJECT,
  CHANGE_COPY_LOADING_ID,
  UPDATE_REPORT_INFO,
  CLEAR_REPORT_INFO,
  SAVE_REPORT_CONFIG,
  GET_REPORT,
  UPDATE_PCB_LAYOUT,
  UPDATE_VIEWLIST,
  UPDATE_SERVICE_CONFIG,
  SAVE_SERVICE_OPTION,
  GET_REPORT_CONFIG,
  UPDATE_STACKUP_STATUS_LIST
} from './actionTypes';

export const updateExpand = (expandedKeys) => ({
  type: UPDATE_EXPAND_KEYS,
  expandedKeys
});

export const updateSelectKeys = (selectedKeys) => ({
  type: UPDATE_SELECTED_KEYS,
  selectedKeys
});

export const updateTreeList = ({ treeItems }) => ({
  type: UPDATE_TREE_LIST,
  treeItems
});

export const updateProjectMenu = (data) => ({
  type: UPDATE_PROJECT_MENU,
  data
});

export const clearCurrentProject = (clearAll) => ({
  type: CLEAR_CURRENT_PROJECT,
  clearAll
});

export const openProject = (id, afterUploadPCBId) => ({
  type: OPEN_PROJECT,
  id,
  afterUploadPCBId
});

export const refreshPCB = (refreshDesignId) => ({
  type: REFRESH_PCB,
  refreshDesignId
});

export const saveOpenProjectInfo = ({ id }) => ({
  type: SAVE_OPEN_PROJECT_INFO,
  id
});

export const updatePageLayout = (layout, singleType) => ({
  type: UPDATE_PAGE_LAYOUT,
  layout,
  singleType
});

export const openPage = ({ pageType, id, simulating, firstCreate = false }) => ({
  type: OPEN_PAGE,
  pageType,
  id,
  simulating,
  firstCreate
});

export const openPageInfo = ({ designID }) => ({
  type: OPEN_PAGE_INFO,
  designID
});

export const closeProject = () => ({
  type: CLOSE_PROJECT
});

export const deletePCB = (data) => ({
  type: DELETE_PCB,
  data
});

export const addProject = () => ({
  type: ADD_PROJECT
});

export const createProject = (data) => ({
  type: CREATE_PROJECT,
  data
});

export const deleteProject = (data) => ({
  type: DELETE_PROJECT,
  data
});

export const updatePCBComponentsNets = (pcbsLayoutDB) => ({
  type: UPDATE_PCBS_LAYOUT_DB,
  pcbsLayoutDB
});

export const updateTrash = () => ({
  type: UPDATE_TRASH_LIST
});

export const clearTrash = () => ({
  type: CLEAR_TRASH
});

export const replacePCB = (designId) => ({
  type: PCB_REPLACE,
  designId
});

export const projectRename = (itemData) => ({
  type: PROJECT_RENAME,
  itemData
});

export const copyProject = (name, projectId) => ({
  type: COPY_PROJECT,
  name,
  projectId
});

export const changeCopyLoadingId = ({ copyProjectLoadingId, copyChannelLoadingId, copyEndToEndChannelLoadingId }) => ({
  type: CHANGE_COPY_LOADING_ID,
  copyProjectLoadingId,
  copyChannelLoadingId,
  copyEndToEndChannelLoadingId
});

export const updateReportInfo = (info) => ({
  type: UPDATE_REPORT_INFO,
  info
});

export const clearReportInfo = () => ({
  type: CLEAR_REPORT_INFO
});

export const saveReportConfig = (channelId, isMultiPCB) => ({
  type: SAVE_REPORT_CONFIG,
  channelId,
  isMultiPCB
});

export const getReportConfig = ({ channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType }) => ({
  type: GET_REPORT_CONFIG,
  channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType
});

export const getReport = ({ projectId, channelId, format, mime, fileName }) => ({
  type: GET_REPORT,
  projectId,
  channelId,
  format,
  mime,
  fileName
});

export const updatePCBLayout = (pcbLayout, prePcbLayout) => ({
  type: UPDATE_PCB_LAYOUT,
  pcbLayout,
  prePcbLayout
});

export const updateViewList = (viewList) => ({
  type: UPDATE_VIEWLIST,
  viewList
})

/* === Service options(config) === */
export const updateServiceConfig = (serviceOptions) => ({
  type: UPDATE_SERVICE_CONFIG,
  serviceOptions
});

export const saveServiceOption = () => ({
  type: SAVE_SERVICE_OPTION,
})

export const updateStackupStatusList = (stackupStatusList) => ({
  type: UPDATE_STACKUP_STATUS_LIST,
  stackupStatusList
});