import React, { Component } from 'react';
import { Dropdown, Tooltip } from 'antd';
import getIcon from '../../services/helper/iconHelper';
import './index.css';
class IconCollection extends Component {

  spanClick = (e) => {
    e.stopPropagation();
  }

  clickMenu = ({ item, key }) => {
    console.log(item, key)
  }

  getMenu = () => {
    const { menu, data } = this.props;
    return menu.map(item => {
      const Icon = item.icon ? getIcon(item.icon) : null;
      return {
        key: item.title,
        disabled: item.disabled,
        label: <Tooltip title={item.tooltip} classNames={{ root: 'aurora-tooltip' }} placement="bottomLeft">
          {item.icon && <Icon key={item.title} type={item.icon} className="collection-menu-icon" />}
          <span>{item.title}</span>
        </Tooltip>,
        onClick: (e) => item.func && item.func(e.domEvent, data, item.params)
      }
    })
  }

  render() {
    const { trigger, icon = "more", className = "", placement = "bottomRight", title = "" } = this.props;
    const Icon = getIcon(icon);
    const items = this.getMenu()
    return (
      <Dropdown menu={{ items: items, className: "upload-dropdown-button" }} overlayClassName="tree-icon-collection-dropdown" trigger={trigger ? trigger : ['click']} placement={placement}>
        <Icon className={`${className} aurora-tree-more-icon ${icon === 'more' ? 'collection-icon' : ''}`} type={icon} onClick={(e) => this.spanClick(e)} title={title} />
      </Dropdown>
    );
  }
}

export default IconCollection;