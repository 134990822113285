import NP from 'number-precision';
// {
//   "id": "tdh",
//   "name": "tDH",
//   "dimension": "time",
//   "unit": "ps",
//   "definition": {
//     "table": [
//       {
//         "data_rate": 800,
//         "definition": 150
//       }]
//   },
//   "dependency": [
//     "data_rate"
//   ],
//   "description": "Data hold time from DQS, DQS# referenced to Vih(dc)/Vil(dc) levels",
//   "reference": "JESD79-3F, table 68, table 69"
// },
class Parameter {
  constructor(param) {
    this.id = param.id;
    this.name = param.name;
    this.dimension = param.dimension || null; // time|frequency|voltage
    this.unit = param.unit;
    this.definition = null; // Definition
    this.description = param.description || null;
  }

  paramInit(_param) {
    this.definition = this.setDefinition(_param.definition);
  };

  setDefinition(definition) {
    let _define = new Definition(this.unit);
    if (definition) {
      _define.setDefinition(definition);
    };
    return _define;
  }

  // unitDefine() {
  //   const convert = ['8 * bps',
  //     'Bps = 8 * bps',
  //     'kBps = 8 * kbps',
  //     'MBps = 8 * Mbps',
  //     'GBps = 8 * Gbps',
  //     'Tbps = 1000 * Gbps',
  //     'TBps = 8000 * Gbps'];
  // };
};

export const DEFINITION = 'definition', CONSTANT = 'constant', MEASURED = 'measured', OPTIONS = 'options', DEFAULT = 'default', FORMULA = 'formula', TABLE = 'table';
export const DATA_RATE = 'data_rate', DATA_RATE_CA = "data_rate_ca", DATA_RATE_CS = "data_rate_cs";
// FORMULA - [[1, "tds", 1], [1, "tjit_dqs", 1]] 1 * Math.pow(tds, 1)
const DEFINITION_TYPE = [CONSTANT, MEASURED, OPTIONS, DEFAULT, FORMULA, TABLE];
class Definition {
  constructor(unit) {
    this.type = null; // constant|measured|options|default|formula|table
    // this.options = null;
    // this.default = null;
    // this.formula = null;
    // this.table = null;
    // this.quantity = null;
    this.unit = unit;
  };

  setDefinition = (definition) => {
    if (!isObject(definition)) {
      this.type = CONSTANT;
      this.is_constant = true;
      // TODO
      // this.quantity = definition * this.unit
      this.value = definition;
    } else {
      const keys = Object.keys(definition);
      for (const key of keys) {
        const value = definition[key];
        if (key === CONSTANT) {
          this.type = CONSTANT;
          // The definition is a constant value and cannot be changed
          // TODO
          // this.quantity = value * this.unit
          this.value = value;
          break;
        } else if (key === MEASURED) {
          this.type = MEASURED;
          // the definition is a measured value, and must be set explicitly
          // TODO
          // this.quantity = value * this.unit
          this.value = value;
          break;
        } else if (key === OPTIONS) {
          this.type = OPTIONS;
          // each option item is a definition
          this.options = value.map(d => {
            const optionParam = new Definition(this.unit);
            optionParam.setDefinition(d);
            return optionParam;
          });
        } else if (key === DEFAULT) {
          if (!this.type) {
            this.type = DEFAULT;
          }
          this.default = value;
          // this.quantity = value * this.unit
        } else if (key === FORMULA) {
          //  a definition item is given in the form of: 
          // [<coefficient>, <param1>, <power1>, <param2>, <power2>, ...]
          this.type = FORMULA;
          this.formula = value;
        } else if (key === TABLE) {
          this.type = TABLE;
          this.table = value.map(d => this.setTableDefinition(d));
        };
      }
    }
  };

  setTableDefinition = (definition) => {
    let tableObj = {};
    const keys = Object.keys(definition);
    for (const key of keys) {
      const value = definition[key];
      if (key === DEFINITION) {
        const newDefinition = new Definition(this.unit);
        newDefinition.setDefinition(value);
        tableObj[key] = newDefinition;
      } else {
        tableObj[key] = value;
      }
    };
    return tableObj;
  };

  getDefinitionType = () => {
    return this.type;
  };

  isDefinitionType = () => {
    return DEFINITION_TYPE.includes(this.type);
  };

  getDefinition = (type) => {
    const _type = type ? type : this.type;
    switch (_type) {
      case CONSTANT:
      case MEASURED:
        return this.value;
      case DEFAULT:
        return this.default;
      case OPTIONS:
        return this.options;
      case FORMULA:
        return this.formula;
      case TABLE:
        return this.table;
      default: return null;
    }
  };

  // parameters - Class Parameters
  getDefinitionValue = (parameters, dataRate) => {
    switch (this.type) {
      case CONSTANT:
      case MEASURED:
        return this.value;
      case FORMULA:
        return this.evaluateFormula(parameters, dataRate);
      case TABLE:
        return this.evaluateTable(parameters, dataRate);
      default: return null;
    }
  };

  definitionToJson = () => {
    if (this.type === FORMULA) {
      return { [FORMULA]: this.getDefinition() };
    }
  }

  setValue = (value) => {
    if (this.type === FORMULA) {
      this.formula = value;
    }
    this.value = value;
  }

  evaluateFormula = (parameters, dataRate) => {
    let value = 0;
    if (!Array.isArray(this.formula)) return this.formula; // number
    for (const item of this.formula) {
      // an item is defined as [<coeff>, <param1_name>, <param1_power>, <param2_name>, <param2_power>, ...]
      let term = item[0]  // the first term is a coefficient
      for (let i = 1; i < item.length; i += 2) {
        const param_id = item[i];
        const power = item[i + 1];
        if (param_id === CONSTANT) {
          //  include a constant, this excludes other parameters
          if (power === 1) {
            term = item[0];
          } else {
            term = NP.strip(1 / item[0]);
          };
          break;
        } else {
          // include a parameter'
          const param_quantity = parameters.getParameterValue(param_id, dataRate);
          if (power === 1) {
            term = term * param_quantity
          } else if (power === 2) {
            term = term + (param_quantity * param_quantity)
          } else if (power === -1) {
            // TODO
            if ([DATA_RATE, DATA_RATE_CA, DATA_RATE_CS].includes(param_id)) {
              // s -> ps  (1 / (1600(1e6))) * 1e12
              term = term / (param_quantity) * 1e6;
            } else {
              term = term / param_quantity
            }
          } else if (power === -2) {
            term = term / (param_quantity * param_quantity)
          }
        };
      }
      value += term;
    };
    return value;
  };

  evaluateTable(parameters, dataRate) {
    if (!this.table.length) return null;
    const itemFirst = this.table[0];
    const keys = Object.keys(itemFirst);
    if (keys.includes(DATA_RATE) || keys.includes(DATA_RATE_CA) || keys.includes(DATA_RATE_CS)) {
      for (const tblItem of this.table) {
        if (tblItem.data_rate && (tblItem.data_rate === dataRate ||
          (Array.isArray(tblItem.data_rate) && tblItem.data_rate.includes(dataRate)))) {
          const value = tblItem.definition.getDefinitionValue(parameters, dataRate);
          return value;
        };
        if (tblItem.data_rate_ca && (tblItem.data_rate_ca === dataRate ||
          (Array.isArray(tblItem.data_rate_ca) && tblItem.data_rate_ca.includes(dataRate)))) {
          const value = tblItem.definition.getDefinitionValue(parameters, dataRate);
          return value;
        };

        if (tblItem.data_rate_cs && (tblItem.data_rate_cs === dataRate ||
          (Array.isArray(tblItem.data_rate_cs) && tblItem.data_rate_cs.includes(dataRate)))) {
          const value = tblItem.definition.getDefinitionValue(parameters, dataRate);
          return value;
        };
      };
    } else {
      // TODO
      return null;
    };
    return null;
  };

  getDefinitionFormulaString = (eyeParameters) => {
    let value = null;
    const define = this.getDefinition();
    const definitionType = this.getDefinitionType();
    if (definitionType === FORMULA) {
      // [Coefficient, ParameterId, power] -> Coefficient * Math.pow(Parameter, power)
      value = define.reduce((preV, curV) => {
        const num = NP.strip(parseFloat(curV[0]));
        let _preV = preV;
        // first param
        if (preV) {
          _preV = _preV + (num > 0 ? ' + ' : ' - ');
        } else if (num < 0) {
          _preV = '- ';
        };
        const paramName = getParameterName(eyeParameters, curV[1]);
        let numToPositive = num > 0 ? num : NP.times(num, -1);
        if (numToPositive === 1) {
          if (paramName === 'constant') {
            return _preV + numToPositive;
          } else {
            return _preV + paramName;
          }
        } else {
          if (paramName === 'constant') {
            return _preV + numToPositive;
          } else {
            return _preV + numToPositive + ' * ' + paramName;
          }
        }
      }, '')
    }
    return value;
  }
}

function getParameterName(eyeParameters, id) {
  return eyeParameters ? eyeParameters.getParameterNameById(id) : '';
}

/** Check if a value is object */
function isObject(c) {
  return toString.call(c) === '[object Object]';
}

export default Parameter;