import {
  PCB_SUBCKT,
  PVT,
  SOC_SPICE,
  SOC_WRAPPER,
  SPICE_PARAMETERS,
  SPICE_PARAMETERS_LIB
} from "../../../constants/libraryConstants";
import { LIBRARY_FILE, LIBRARY_FOLDER } from "../../../constants/treeConstants";

function libraryItem({ id, name, dataType, type, vdeNum, fileType, children, path }) {
  this.id = id;
  this.name = name;
  this.dataType = dataType;
  this.key = `${type}-${id}`;
  this.type = type;
  this.fileType = getFileType(fileType); //file / folder
  this.nodeClass = "himalayas-library-tree-item";
  if (vdeNum) {
    this.vdeNum = vdeNum;
  }
  if (children) {
    this.children = children;
  }
  this.icon = getFileType(fileType);
  this.path = path || null
}

function getFileType(fileType) {
  if (fileType && typeof (fileType) === "string") {
    return fileType.toLowerCase()
  }
  return "file"
}

function getLibraryTree(list) {
  if (!Array.isArray(list) || !list.length) {
    return [];
  }

  return list.map(item => new libraryItem({
    id: item.id,
    name: item.name,
    type: item.type,
    dataType: item.fileType === "Folder" ? LIBRARY_FOLDER : LIBRARY_FILE,
    vdeNum: item.vdeNum,
    fileType: item.fileType,
    children: item.fileType === "Folder" && item.children ? getLibraryChildren(item) : null,
    path: item.path
  }))
}

function getLibraryTreeIndex(key) {
  let libraryIndex = -1, subLibraryIndex = -1;
  switch (key) {
    case SOC_SPICE:
      libraryIndex = 0;
      subLibraryIndex = 0;
      break;
    case SOC_WRAPPER:
      libraryIndex = 0;
      subLibraryIndex = 1;
      break;
    case SPICE_PARAMETERS:
    case SPICE_PARAMETERS_LIB:
      libraryIndex = 0;
      subLibraryIndex = 2;
      break;
    case PVT:
      libraryIndex = 0;
      subLibraryIndex = 3;
      break;
    case PCB_SUBCKT:
      libraryIndex = 1;
      break;
    default: break;
  }
  return { libraryIndex, subLibraryIndex }
}

function getLibraryChildren(data) {
  if (!data || !data.children || !data.children.length) {
    return [];
  }

  const list = data.children.map(item => new libraryItem({
    id: item.id,
    name: item.name,
    type: item.type,
    dataType: item.fileType === "Folder" ? LIBRARY_FOLDER : LIBRARY_FILE,
    vdeNum: item.vdeNum,
    fileType: item.fileType,
    children: item.fileType === "Folder" && item.children ? getLibraryChildren(item) : null
  }));
  return list;
}

export {
  getLibraryTree,
  getLibraryTreeIndex,
  libraryItem
}