import React, { Component, createRef, Fragment } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { Input, message, Tooltip, Select } from 'antd';
import { numberCheck } from '@/services/helper/dataProcess';
import { numExponentialFormat } from '@/services/helper/numberHelper';
import { unitChange } from '@/services/helper/mathHelper';
import { DIODE } from '@/constants/componentType';
import DriverModel from './driverModel';
import { resolveDriverModel } from '@/services/Cascade/helper/setupData';
import { strDelimited } from '@/services/helper/split';
import { getTextWidth, getTextWidthAndHeight } from '../../../../services/helper/getTextWidth';
import _ from 'lodash';
import getIcon from '../../../../services/helper/iconHelper';
import './tree.css';

const LOAD = 'Load', VRM = 'VRM', DRIVER = 'Driver', ROOT = 'root', CONNECTOR = 'Connector';
const INPUT = 'input', OUTPUT = 'output', OUTPUTGND = 'outputGround', INPUTGND = 'inputGround';
const Option = Select.Option;
class TreeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: 0,
      padding: "0px, 0px, 0px, 0px",
      fontSize: 20,
      inputStatus: {},
      passResultData: null,
      pairResultData: null,
      totalResResult: {},
      multiBro: false,
      multiPrev: false,
      showModel: false,
      nodes: {},
      nodesSelectBox: [],
      pairInput: {}
    }
    this.inputRef = createRef();
  }

  componentDidMount() {
    this.getItemPosition();
    this.getResult();
    this.getPairResult();
    this.getModelNode();
    this.getNodesSelectBox();
  }

  componentDidUpdate = (prevProps) => {
    const { gridWidth, gridHeight, posUpdate, running, passResult, columnIndex, prevComps, pairResult, nextComps, refreshResults, data: { type }, totalResResults, spiceTotalResult, whatIf } = this.props;
    if (gridWidth !== prevProps.gridWidth || gridHeight !== prevProps.gridHeight || posUpdate) {
      this.getItemPosition();
      this.changePairResultPosition();
      this.getNodesSelectBox();
      this.getModelNode();
      this.props.updatePosition(false);
    }

    if (running !== prevProps.running || passResult.length !== prevProps.passResult.length) {
      this.getResult();
    }

    if (type === LOAD && (whatIf !== prevProps.whatIf || running !== prevProps.running || totalResResults.length !== prevProps.totalResResults.length || spiceTotalResult.length !== prevProps.spiceTotalResult.length)) {
      this.getTotalResResult();
    }

    if (columnIndex > 0 && (prevComps.length !== prevProps.prevComps.length || pairResult.length !== prevProps.pairResult.length || refreshResults !== prevProps.refreshResults)) {
      this.getPairResult();
    }

    if (columnIndex > 0 && (prevComps.length !== prevProps.prevComps.length || nextComps.length !== prevProps.nextComps.length)) {
      this.getNodesSelectBox()
    }
  }

  getResult() {
    const { data, passResult } = this.props;
    const find = passResult.find(r => r.prevNetName === data.prevNet && r.componentName === data.name);
    if (find) {
      this.setState({
        passResultData: {
          pass: find.pass,
          realVMin: find.realVMin,
          realVMax: find.realVMax
        }
      })
    } else {
      this.setState({
        passResultData: null
      })
    }
  }

  getTotalResResult = () => {
    const { data, totalResResults, spiceTotalResult, whatIf } = this.props;
    const { name, prevNet, type } = data;
    let isWhatIf = whatIf, totalRes = '';

    if (type !== LOAD) {
      return;
    }

    if (isWhatIf) {
      const totalResResult = spiceTotalResult.find(item => item.loadName === name && item.loadPrevNet === prevNet);
      if (totalResResult && totalResResult.totalValue) {
        totalRes = totalResResult.totalValue;
      }
    }

    if (!totalRes) {
      const totalResResult = totalResResults.find(item => item.loadName === name && item.loadPrevNet === prevNet);
      if (totalResResult && totalResResult.totalValue) {
        totalRes = totalResResult.totalValue;
        isWhatIf = false;
      }
    }

    this.setState({
      totalResResult: {
        isWhatIf,
        totalRes
      }
    })
  }

  judgeUnitChange = (num) => {
    if (num < 10) {
      const newNum = unitChange({ num, oldUnit: 'Ω', newUnit: 'mΩ', decimals: 2 }).number;
      return { number: String(newNum), unit: 'mΩ' }
    } else {
      return { number: String(num), unit: 'Ω' }
    }
  }

  getPairResult = () => {
    const { prevComps, pairResult, data, groundNet = "", columnIndex, gridWidth, gridHeight, broComps, allComps, whatIfData, compRelationShip = [] } = this.props;
    const { pins, prevNet, name, anotherPrev, deepIndex, type, gnd, grid, isGnd, prevComp, pcbKey } = data;
    const _pins = pins.filter(pin => (!groundNet.split(', ').includes(pin.net) || isGnd) && pin.net === prevNet);
    const pairRes = pairResult.filter(pair => pair.componentIn === name && _pins.some(pin => pin.pin === pair.pinIn));
    const getMultiPrevExist = (_prevComps, _anotherPrev) => {
      return _anotherPrev.filter(item => _prevComps.find(comp => comp.name === item && (!comp.nextNet || comp.nextNet === prevNet)));
    }
    const curPrevComps = type === LOAD ? prevComps.filter(item => item.name === prevComp || anotherPrev.includes(item.name)) : prevComps;
    const multiBro = broComps.filter(item => item.prevNet === prevNet).length > 1 ? true : false;
    const multiPrev = anotherPrev && anotherPrev.length ? getMultiPrevExist(curPrevComps, anotherPrev).length ? true : false : false;
    let pairResArray = [] // [{ top, left, min, max, avg, pairs, componentOut，pins, prevIndex, dataIndex, columnIndex, multiPrev }];

    if (pairRes.length) {
      for (let res of pairRes) {
        const findIndex = pairResArray.findIndex(pair => pair.componentOut === res.componentOut && pair.pins.includes(res.pinOut))
        if (findIndex > -1) {
          pairResArray[findIndex].pairs.push(res);
        } else {
          const prevInfo = curPrevComps.find(comp => {
            let hiddenMatch = false;
            if (comp.name.match(/^(hidden)/g)) {
              const matchShip = compRelationShip.find(item => comp.name.includes(item[0]));
              if (matchShip) {
                hiddenMatch = matchShip[1]
              }
            }
            return (
              ((((comp.name === res.componentOut || !hiddenMatch) && comp.pins.some(pin => pin.pin === res.pinOut)) || res.componentOut === hiddenMatch) && (!comp.nextNet || comp.nextNet === prevNet))
              ||
              (comp.broComp && comp.broComp.name === res.componentOut && comp.broComp.pins.some(pin => pin.pin === res.pinOut))
            )
              && !comp.hiddenPins
          });
          if (prevInfo) {
            let top = multiPrev && !multiBro ? (prevInfo.deepIndex - 1) * gridHeight : (deepIndex - 1) * gridHeight
            pairResArray.push({
              top: top,
              left: gridWidth * columnIndex,
              pairs: [res],
              componentOut: res.componentOut,
              pins: prevInfo.name === res.componentOut ? prevInfo.pins.map(pin => pin.pin)
                : prevInfo.broComp && prevInfo.broComp.name === res.componentOut ? prevInfo.broComp.pins.map(pin => pin.pin) : [],
              prevIndex: prevInfo.deepIndex,
              columnIndex: columnIndex,
              deepIndex: deepIndex,
            })
          } else if (isGnd && type !== LOAD) {
            pairResArray.push({
              top: (deepIndex - 1) * gridHeight,
              left: gridWidth * columnIndex,
              pairs: [res],
              componentOut: res.componentOut,
              pins: [res.pinOut],
              prevIndex: deepIndex,
              columnIndex: columnIndex,
              deepIndex: deepIndex,
            })
          }
        }
      }
    }

    if (type === DRIVER) {
      const gndPins = gnd ? pins.filter(pin => pin.net === gnd) : pins.filter(pin => groundNet.split(', ').includes(pin.net));
      const gndPairRes = pairResult.filter(pair => pair.componentIn === name && gndPins.some(pin => pin.pin === pair.pinIn));
      if (gndPairRes.length) {
        let top = (grid - deepIndex) * gridHeight
        pairResArray.push({
          top: top,
          left: gridWidth * columnIndex,
          pairs: [...gndPairRes],
          componentOut: "",
          pins: gndPins,
          prevIndex: grid - deepIndex,
          columnIndex: columnIndex,
          deepIndex: deepIndex,
          type: DRIVER
        })
      }
    }
    pairResArray = pairResArray.map(pairRes => {
      const pairValue = pairRes.pairs.map(p => p.res === 'inf' ? Infinity : Number(p.res));
      const min = this.judgeUnitChange(_.min(pairValue));
      const max = this.judgeUnitChange(_.max(pairValue));
      const avg = this.judgeUnitChange(_.mean(pairValue));
      const single = min === max && min === avg ? true : false;
      let ifValue = '';
      if (pairRes.pairs.length === 1 || single) {
        const pair = pairRes.pairs[0]
        const ifData = whatIfData.find(d => d.pcbKey === pcbKey &&
          d.componentIn === pair.componentIn &&
          d.componentOut === pair.componentOut &&
          d.pinIn === pair.pinIn &&
          d.pinOut === pair.pinOut)
        if (ifData) {
          ifValue = this.judgeUnitChange(ifData.ifValue)
        }
      }
      return { ...pairRes, min, max, avg, ifValue, single }
    })

    const allCompNames = allComps.flat(2).map(item => [item.name, item.broComp ? item.broComp.name : null]).flat(2).filter(item => !!item);
    pairResArray = pairResArray.filter(item => allCompNames.includes(item.componentOut));
    this.setState({
      pairResultData: pairResArray,
      multiBro,
      multiPrev
    })
  }

  changePairResultPosition = () => {
    const { gridWidth, gridHeight } = this.props;
    const { pairResultData, multiPrev, multiBro } = this.state;
    if (pairResultData && pairResultData.length) {
      let _pairResultData = pairResultData.map(pairResult => {
        const { prevIndex, columnIndex, deepIndex, type } = pairResult;
        let top = type === DRIVER ? prevIndex * gridHeight : multiPrev && !multiBro ? (prevIndex - 1) * gridHeight : (deepIndex - 1) * gridHeight
        let left = gridWidth * columnIndex
        return { ...pairResult, top, left }
      })
      this.setState({
        pairResultData: _pairResultData
      })
    }
  }

  getModelNode = async (id = "") => {
    const { data } = this.props;
    const { type, model = {} } = data;
    if (type === DRIVER && (model.id || id)) {
      const nodes = await resolveDriverModel(id || model.id);
      this.setState({
        nodes
      })
    } else {
      this.setState({
        nodes: {}
      })
    }
  }

  getNodesSelectBox = (pinMap = undefined) => {
    const { data, prevComps, nextComps, gridHeight, broComps } = this.props;
    if (data.type === DRIVER) {
      let nodeSelect = [];
      const { prevComp, name, prevNet, nextNet, driverMap = [], pins, gnd, grid, deepIndex } = data;
      const prev = prevComps.find(comp => comp.name === prevComp && (!comp.nextNet || comp.nextNet === prevNet));
      const prevData = this.findDriverMapNode(pins, prevNet, pinMap || driverMap, INPUT);
      nodeSelect.push({ data: prevData, net: prevNet, position: 'left', deepIndex: prev.deepIndex + 1 - deepIndex, top: (prev.deepIndex - deepIndex) * gridHeight + 0.3 * gridHeight + 10 });
      const nexts = nextComps.filter(comp => comp.prevComp === name && (!nextNet || nextNet === comp.prevNet));
      for (let i = 0; i < nexts.length; i++) {
        const next = nexts[i];
        if (next.hiddenPins) {
          continue;
        }
        const nextData = this.findDriverMapNode(pins, next.prevNet, pinMap || driverMap, next.isGnd ? OUTPUTGND : OUTPUT);
        nodeSelect.push({ data: nextData, net: next.prevNet, position: 'right', deepIndex: next.deepIndex + 1 - deepIndex, top: (next.deepIndex - deepIndex) * gridHeight + (i === nexts.length - 1 ? -10 : 0.3 * gridHeight + 10) });
      }
      const gndInData = this.findDriverMapNode(pins, gnd, pinMap || driverMap, INPUTGND);
      nodeSelect.push({ data: gndInData, net: gnd, position: 'left', deepIndex: grid, top: (grid - 1) * gridHeight - 10 });
      this.setState({
        nodesSelectBox: nodeSelect
      })
    } else if (data.type === CONNECTOR) {
      let nodeSelect = [];
      const { name, prevNet, deepIndex, pcbKey, grid } = data;
      const bros = broComps.filter(comp => comp.name === name && comp.type === CONNECTOR && comp.pcbKey === pcbKey);
      if (prevNet.includes('CONNECT - ')) {
        const nexts = nextComps.filter(comp => comp.prevComp === name && comp.pcbKey === pcbKey);
        let _nexts = [], lastNet = '', lastGndKey = '';
        nexts.forEach(n => {
          if (n.prevNet !== lastNet || !n.gndKey || n.gndKey !== lastGndKey) {
            _nexts.push(n)
          }
          lastNet = n.prevNet
          lastGndKey = n.lastGndKey
        })
        for (let next of _nexts) {
          const bro = bros.find(i => i.name === next.prevComp && (!i.nextNet || i.nextNet === next.prevNet) && (!next.gndKey || next.gndKey === i.gndKey) && i.deepIndex >= deepIndex);
          if (next.hiddenPins || !bro || (bro && bro.hiddenPins) || (bro && nodeSelect.some(node => node.position === 'right' && _.isEqual(bro, node.data)))) {
            continue;
          }
          nodeSelect.push({ data: bro, net: `${next.prevNet} - ${next.nextNet}`, position: 'right', deepIndex: next.deepIndex + 1 - deepIndex, top: (next.deepIndex - 1) * gridHeight + 20 });
        }
        for (let bro of bros) {
          if (bro.hiddenPins || bro.deepIndex < deepIndex) {
            continue;
          }
          nodeSelect.push({ data: bro, net: `${bro.prevNet} - ${bro.nextNet}`, hidden: true, position: 'left', deepIndex: bro.deepIndex + 1 - deepIndex, top: (bro.deepIndex - 1) * gridHeight + 20 })
        }
      } else {
        for (let bro of bros) {
          if (bro.hiddenPins || bro.deepIndex < deepIndex) {
            continue;
          }
          nodeSelect.push({ data: bro, net: `${bro.prevNet} - ${bro.nextNet}`, position: 'left', deepIndex: bro.deepIndex + 1 - deepIndex, top: (bro.deepIndex - 1) * gridHeight + 20 })
        }
      }
      this.setState({
        nodesSelectBox: nodeSelect
      })
    }
  }

  findDriverMapNode = (pins, prevNet, driverMap, type) => {
    const currentPins = pins.filter(item => item.net === prevNet).map(item => item.pinName).sort().join(',');
    let indexs = [], node = "";
    for (let row of driverMap) {
      let _pins = row[type] || [];
      _pins = _pins.sort().join(',');
      const _node = row[`${type}_node`] || '';
      if (currentPins === _pins && (!_node || !node || node === _node)) {
        indexs.push(row.index)
        node = node ? node : _node;
      }
    }
    return { indexs, type, node }
  }

  getItemPosition() {
    const { data, columnIndex, gridWidth, gridHeight } = this.props;
    let top = 0, left = 0;
    if (data.type === 'root') {
      top = 0;
      left = 0;
    } else {
      left = gridWidth * columnIndex;
      const deepIndex = data.deepIndex;
      top = deepIndex ? (deepIndex - 1) * gridHeight : -1;
    }
    this.setState({
      top,
      left,
      padding: `${gridHeight * 0.2}px ${gridWidth * 0.2}px ${gridHeight * 0.2}px ${gridWidth * 0.2}px`,
      fontSize: Math.floor(gridWidth / 12) - 5 > 3 ? Math.floor(gridWidth / 12) - 5 : 3
    })
  }

  mouseEnterHandle = () => {
    const { gridWidth, gridHeight } = this.props;
    if (gridWidth < 150) {
      this.setState({
        padding: `0px 0px 0px 0px`,
        fontSize: Math.floor(gridWidth / 12)
      })
    } else if (gridWidth < 200) {
      this.setState({
        padding: `${gridHeight * 0.1}px ${gridWidth * 0.1}px ${gridHeight * 0.1}px ${gridWidth * 0.1}px`,
        fontSize: Math.floor(gridWidth / 12) - 2 > 3 ? Math.floor(gridWidth / 12) - 2 : 3
      })
    }
  }

  mouseLeaveHandle = () => {
    const { gridWidth, gridHeight } = this.props;
    this.setState({
      padding: `${gridHeight * 0.2}px ${gridWidth * 0.2}px ${gridHeight * 0.2}px ${gridWidth * 0.2}px`,
      fontSize: Math.floor(gridWidth / 12) - 5 > 3 ? Math.floor(gridWidth / 12) - 5 : 3
    })
  }

  getCompContent(type, name, grid) {
    const { gridHeight, data, includeInd, setting: { valueDisplay = true, autoSize, nameSize } } = this.props;
    const { isGnd, rootInd } = data;
    const { fontSize } = this.state;
    const { height } = getTextWidthAndHeight(name, { fontSize: autoSize ? fontSize : nameSize })
    let compContent = {};
    switch (type) {
      case ROOT:
        compContent = { color: '#f47188', render: this.rootValueRender, style: {} };
        break;
      case LOAD:
        compContent = { color: '#6495ed', render: isGnd ? null : this.compValueRender, style: isGnd ? { marginTop: 0.28 * gridHeight - 0.5 * height } : {} };
        break;
      case 'VRM':
        compContent = { color: '#f47188', render: isGnd ? null : this.compValueRender, style: isGnd ? { marginTop: 0.28 * gridHeight - 0.5 * height } : {} };
        break;
      case DIODE:
        compContent = { color: '#f4b183', render: null, style: { marginTop: 0.28 * gridHeight - 0.5 * height } };
        break;
      case DRIVER:
        compContent = { color: '#6495ed', render: null, style: { marginTop: ((grid - 0.4) / 2) * gridHeight - 0.5 * height } };
        break;
      case CONNECTOR:
        compContent = { color: '#9998ff', render: grid > 1 ? this.multiCompValueRender : this.compValueRender, style: grid > 1 ? { marginTop: ((grid - 0.4) / 2) * gridHeight - 0.5 * height } : {} };
        break;
      default:
        compContent = includeInd && rootInd ?
          { color: '#f4b183', render: null, style: { marginTop: 0.28 * gridHeight - 0.5 * height } }
          : { color: '#f4b183', render: this.compValueRender, style: {} };
        break;
    }
    if (!valueDisplay) {
      compContent.render = null;
      compContent.style = [DRIVER, CONNECTOR].includes(type) ? { marginTop: ((grid - 0.4) / 2) * gridHeight - 0.5 * height } : { marginTop: 0.28 * gridHeight - 0.5 * height };
    }
    return compContent
  }

  rootValueRender = () => {
    const { data, rootItem, setting: { autoSize = true, nameSize = 16 } } = this.props;
    const { fontSize } = this.state;
    const { type } = data;
    const { voltage, load } = rootItem;
    const disabled = type === 'root' ? false : true;
    const _fontSize = autoSize ? fontSize - 2 > 6 ? fontSize - 2 : 6 : Number(nameSize);
    return <Fragment>
      <div
        className="tree-component-value root-value"
        style={{ fontSize: `${_fontSize}px` }}
        title={`${load ? load : '__'}mA`}
      >
        {this.inputRender(load, 'A', _fontSize, { disabled })}
        <span className="cascade-power-tree-value-title root-title">&nbsp;&nbsp;mA</span>
      </div>
      <div
        className="tree-component-value root-value"
        style={{ fontSize: `${_fontSize}px` }}
        title={`${voltage ? voltage : '__'}V`}
      >
        {this.inputRender(voltage, 'V', _fontSize, { disabled })}
        <span className="cascade-power-tree-value-title root-title">&nbsp;&nbsp;&nbsp;V</span>
      </div>
    </Fragment>
  }

  multiCompValueRender = () => {
    const { nodesSelectBox, fontSize } = this.state;
    const { data, gridHeight, setting: { autoSize = true, nameSize = 16 } } = this.props;
    const _fontSize = autoSize ? fontSize : Number(nameSize)
    const { grid = 1 } = data;
    const compNameTop = ((grid - 0.2) / 2 - 0.2) * gridHeight
    const _nodesSelectBox = nodesSelectBox.filter(item => item.position === 'left');
    const unit = 'mΩ';
    const name = 'R';
    const _style = { marginTop: 0.07 * gridHeight, fontSize: _fontSize };
    return _nodesSelectBox.map(node => {
      const { data, net, deepIndex } = node;
      const top = (deepIndex - 0.78) * gridHeight;
      const _top = top > compNameTop - 10 && top < compNameTop + 10 ? top + 0.3 * _fontSize : top - 0.6 * _fontSize;
      const value = data.resistance;
      let prevTitle = net;
      if (net.match(/^(CONNECT)/g)) {
        const _net = strDelimited(net, '-', { returnIndex: 1 });
        prevTitle = `Connect - ${_net}`
      }
      return <Fragment key={`${net}-${deepIndex}`}>
        <div className={`tree-component-value comp-value tree-multi-component-value`} style={{ ..._style, top: _top }} title={`${prevTitle}: ${name} = ${value ? value : `__`} ${unit}`}>
          <span className="cascade-power-tree-value-title">{name}&nbsp;=&nbsp;</span>
          {this.inputRender(value, net, _fontSize, { data })}
          <span className="cascade-power-tree-value-title">&nbsp;{unit}</span>
        </div>
      </Fragment>
    });
  }

  compValueRender = () => {
    const { gridHeight, data, setting: { autoSize = true, nameSize = 16 } } = this.props;
    const { fontSize } = this.state;
    let value = "", unit = "", name = "", dataType = "", _style = {}, _fontSize = autoSize ? fontSize : Number(nameSize);
    if ([LOAD, VRM, DRIVER].includes(data.type)) {
      value = data.load;
      unit = 'mA';
      name = 'I';
      dataType = 'load';
      _fontSize = data.middle || data.type === DRIVER ? _fontSize - 5 : _fontSize - 2
      _fontSize = _fontSize < 3 ? 3 : _fontSize
      _style = { fontSize: _fontSize }
    } else {
      value = data.resistance;
      unit = 'mΩ';
      name = 'R';
      dataType = 'resistance';
      _style = { marginTop: 0.07 * gridHeight, fontSize: _fontSize }
    }
    return <Fragment>
      {
        data.type !== CONNECTOR && (data.middle || data.type === DRIVER) ? <Fragment>
          <div className={`tree-component-value comp-value`} style={{ ..._style }} title={`${name} = ${value ? value : `__`} ${unit} / V = ${data.voltage ? data.voltage : '__'} V`}>
            {this.inputRender(value, dataType, _fontSize)}
            <span className="cascade-power-tree-value-title">&nbsp;{unit}</span>
            <span>&nbsp;/&nbsp;</span>
            {this.inputRender(data.voltage, 'voltage', _fontSize)}
            <span className="cascade-power-tree-value-title">&nbsp;V</span>
          </div>
        </Fragment> : <div className={`tree-component-value comp-value`} style={{ ..._style }} title={`${name} = ${value ? value : `__`} ${unit}`}>
          <span className="cascade-power-tree-value-title">{name}&nbsp;=&nbsp;</span>
          {this.inputRender(value, dataType, _fontSize)}
          <span className="cascade-power-tree-value-title">&nbsp;{unit}</span>
        </div>
      }
      {[LOAD, VRM, DRIVER].includes(data.type) && this.vTableRender(_style.fontSize)}
    </Fragment >
  }

  inputRender = (value, type, fontSize, props = {}) => {
    const { inputStatus } = this.state;
    const { disabled = false, data } = props;
    let style = value ? {} : { borderBottom: '1px solid black', height: fontSize, lineHeight: `${fontSize}px` };
    style.cursor = disabled ? 'not-allowed' : 'pointer';
    return inputStatus[type] ?
      <Input
        className="component-input"
        defaultValue={value}
        onBlur={(e) => this.saveValue(e, value, type, data)}
        onPressEnter={(e) => this.saveValue(e, value, type, data)}
        ref={this.inputRef}
      />
      : <span
        className="component-span cascade-power-tree-value-title"
        style={style}
        onClick={(e) => disabled ? null : this.toInput(e, type)}
      >{value}</span>
  }

  vTableRender = (fontSize = 8) => {
    const { data } = this.props;
    const { vMin = "", vMax = "" } = data;
    const dataSource = [{ title: 'Vmin Spec', spec: vMin, real: '', type: 'vMin' }/**, { title: 'Vmax Spec', spec: vMax, real: '', type: 'vMax' } */]
    const _fontSize = fontSize > 4 ? fontSize - 1 : 4
    return dataSource.map(row => {
      return <div className="tree-component-v-table" style={{ fontSize: `${_fontSize}px` }} key={row.type} title={row.spec ? `${row.title}: ${row.spec}` : ''}>
        <span className="cascade-power-tree-value-title cascade-power-tree-v-value-title">{row.title}:</span>
        {this.inputRender(row.spec, row.type, _fontSize)}
      </div>
    })
  }

  saveValue = (e, prevValue, type, current = {}) => {
    const { data, columnIndex } = this.props;
    e && e.stopPropagation();
    let value = e.target.value;
    if (prevValue !== value) {
      let errorCheck = null;
      if (value) {
        errorCheck = numberCheck(value)
      }
      if (errorCheck) {
        message.error(`${data.name} - ${errorCheck}`);
      } else {

        const { connectorData } = this.props;
        const _data = { ...data };
        if (value && (value > 100 || value < 0.001)) {
          value = numExponentialFormat(value);
        }
        let valueType = ""
        switch (_data.type) {
          case ROOT:
            if (type === 'A') {
              _data.load = value;
              valueType = 'load'
            } else if (type === 'V') {
              _data.voltage = value;
              valueType = 'voltage'
            }
            break;
          case LOAD:
          case VRM:
          case DRIVER:
            _data[type] = value;
            valueType = type
            break;
          default:
            _data.resistance = value;
            valueType = 'resistance'
            break;
        }
        if (_data.type === 'Connector' && connectorData && connectorData.length > 1) {
          const { pcbKey } = current;
          const { nodesSelectBox } = this.state;
          let _nodesSelectBox = [...nodesSelectBox];
          connectorData.forEach(item => {
            if ((type === `${item.prevNet} - ${item.nextNet}`) && (!pcbKey || pcbKey === item.pcbKey)) {
              this.props.saveTreeItemValue({ ...item, resistance: value }, columnIndex, valueType);
            }
          })
          _nodesSelectBox.forEach(node => {
            if (node.position === 'left') {
              if (node.data && (type === `${node.data.prevNet} - ${node.data.nextNet}`) && (!pcbKey || pcbKey === node.data.pcbKey)) {
                node.data[valueType] = _data[valueType]
              }
            }
          })
          this.setState({
            nodesSelectBox: _nodesSelectBox
          })
        } else {
          this.props.saveTreeItemValue(_data, columnIndex, valueType);
        }
      }
    }
    this.setState({
      inputStatus: {
        ...this.state.inputStatus,
        [type]: false
      }
    })
  }

  toInput = (e, type) => {
    e && e.stopPropagation();
    this.setState({
      inputStatus: {
        ...this.state.inputStatus,
        [type]: true
      }
    }, () => {
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    })
  }

  showModelPanel = (e) => {
    e && e.stopPropagation();
    this.setState({
      showModel: !this.state.showModel
    })
  }

  saveDriver = (model, pinMap) => {
    this.showModelPanel()
    const { data, columnIndex } = this.props;
    let _data = { ...data }
    _data.model = { ...model }
    _data.driverMap = [...pinMap]
    this.props.saveTreeItemValue(_data, columnIndex);
    this.getModelNode(model.id)
  }

  selectCurrentNode = (node, info) => {
    const { indexs, type } = info;
    const { data, columnIndex } = this.props;
    let _data = { ...data }
    let pinMap = [..._data.driverMap];
    for (let index of indexs) {
      const findIndex = pinMap.findIndex(i => i.index === index);
      if (findIndex > -1) {
        pinMap[findIndex][`${type}_node`] = node;
      }
    }
    _data.driverMap = [...pinMap]
    this.props.saveTreeItemValue(_data, columnIndex);
    this.getNodesSelectBox(pinMap);
  }

  deleteItem = (e, { data, columnIndex }) => {
    e && e.stopPropagation();
    const { connectorData } = this.props;
    if (data.type === 'Connector' && connectorData && connectorData.length > 1) {
      this.props.deleteICPath({ data: connectorData, columnIndex })
    } else {
      this.props.deleteICPath({ data: [data], columnIndex })
    }

  }

  getDetail = (name, pins, prevNet, type, connectorPins = {}) => {
    const { groundNet = "", voltageResult, data, whatIf, spiceVoltageResult } = this.props;
    const { broComp, gnd = "", isGnd } = data;
    let detailList = [], _pins = [];
    if (type === "left") {
      _pins = pins.filter(pin => pin.net === prevNet);
      detailList = _pins.map(pin => `${prevNet} --> [${name}::${pin.pinName}::${pin.pin}]`);
    } else if (type === "gnd") {
      _pins = pins.filter(pin => pin.net === gnd);
      detailList = _pins.map(pin => `${gnd} --> [${name}::${pin.pinName}::${pin.pin}]`);
    } else {
      _pins = pins.filter(pin => (!groundNet.split(', ').includes(pin.net) || isGnd) && pin.net !== prevNet && pin.net !== gnd);
      detailList = _pins.map(pin => `[${name}::${pin.pinName}::${pin.pin}] --> ${pin.net}`);
    }


    let volPins = voltageResult.filter(v => _pins.some(p => p.pin === v.pin));
    let voltageArr = volPins.map(v => Number(v.voltage));

    let spiceVolPins = spiceVoltageResult.filter(v => _pins.some(p => p.pin === v.pin));
    let whatIfClass = false;
    if (whatIf && spiceVolPins.length) {
      voltageArr = spiceVolPins.map(v => Number(v.voltage))
      whatIfClass = true
    }

    if (broComp && broComp.pins) {
      const _nets = _pins.map(p => p.net);
      const broPins = broComp.pins.filter(p => _nets.includes(p.net));
      detailList.push(...broPins.map(pin => type === "left" ? `${prevNet} --> [${broComp.name}::${pin.pinName}::${pin.pin}]`
        : `[${broComp.name}::${pin.pinName}::${pin.pin}] --> ${pin.net}`))
    }

    const connPrevNet = Object.keys(connectorPins).filter(net => net !== prevNet);
    if (connPrevNet.length) {
      for (let connNet of connPrevNet) {
        const connPins = connectorPins[connNet]
        if (type === "left") {
          _pins = connPins.filter(pin => pin.net === connNet);
          detailList.push(..._pins.map(pin => `${connNet} --> [${name}::${pin.pinName}::${pin.pin}]`));
        } else {
          _pins = connPins.filter(pin => !groundNet.split(', ').includes(pin.net) && pin.net !== connNet);
          detailList.push(..._pins.map(pin => `[${connNet}::${pin.pinName}::${pin.pin}] --> ${pin.net}`));
        }
      }
    }
    const detail = detailList && detailList.length ? <div className="cascade-display-detail-box">
      {detailList.map((detail, index) => <div key={index}>{detail}</div>)}
    </div> : null
    const voltage = voltageArr.length ?
      Number(_.mean(voltageArr)).toPrecision(3).length > 5 ? Number(Number(_.mean(voltageArr)).toPrecision(3)).toExponential() : Number(_.mean(voltageArr)).toPrecision(3)
      : null
    return { detail, voltage, whatIfClass }
  }

  getPinStyle = (type, voltage = false) => {
    let style = {};
    if (type === "gnd") {
      const { data, gridHeight } = this.props;
      const { grid } = data;
      style.top = (grid - 0.75) * gridHeight - (voltage ? 20 : 0);
    }
    return style
  }

  openPairInput = (e, componentOut) => {
    e && e.stopPropagation();
    this.setState({
      pairInput: {
        ...this.state.pairInput,
        [componentOut]: true
      }
    }, () => {
      this.inputRef && this.inputRef.focus()
    })
  }

  changePairValue = (e, unit, pairs, componentOut) => {
    e && e.stopPropagation();
    this.setState({
      pairInput: {
        ...this.state.pairInput,
        [componentOut]: false
      }
    })
    const value = e.target.value;
    const pair = pairs[0];
    if (!pair) {
      return;
    }

    const error = numberCheck(value);
    const { data: { pcbKey } } = this.props;
    if (error) {
      this.props.saveWhatIfData(pair, "", pcbKey)
      this.saveIfValue(componentOut, "", unit)
      return;
    }

    if (unit === 'mΩ') {
      const number = unitChange({ num: value, oldUnit: 'mΩ', newUnit: 'Ω' }).number;
      this.saveIfValue(componentOut, value, unit);
      this.props.saveWhatIfData(pair, String(number), pcbKey);
    }
  }

  saveIfValue = (componentOut, value, unit) => {
    const { pairResultData } = this.state;
    let _pairResultData = [...pairResultData];
    const findIndex = pairResultData.findIndex(item => item.componentOut === componentOut);
    if (findIndex > -1) {
      _pairResultData[findIndex].ifValue = { number: value, unit };
      this.setState({
        pairResultData: _pairResultData
      })
    }
  }

  pairPressEnter = () => {
    this.inputRef && this.inputRef.blur()
  }

  pinSingle = (type) => {
    const { data } = this.props;
    const { pins, prevNet, name } = data;
    const { voltage, detail, whatIfClass } = this.getDetail(name, pins, prevNet, type);
    return this.pinDom(type, voltage, detail, {}, whatIfClass)
  }

  pinDom = (type, voltage, detail, style = {}, whatIfClass) => {
    const { showResult, shot, setting: { autoSize, voltageSize } } = this.props;
    const { pinStyle = {}, vStyle = {} } = style;
    const fontStyle = autoSize ? shot ? { fontSize: 14 } : {} : { fontSize: Number(voltageSize) }
    return detail && <div>
      {(voltage || voltage === 0) && showResult.voltage ?
        <Tooltip placement={type} title={`${voltage}V`} overlayClassName='aurora-tooltip'>
          <div className={`tree-voltage tree-pin-${type} ${whatIfClass ? 'cascade-power-tree-experiment-voltage' : ''}`} style={{ ...this.getPinStyle(type, true), ...vStyle, ...fontStyle }}>{voltage}V</div>
        </Tooltip>
        : null}
      <Tooltip
        overlayClassName="aurora-tooltip cascade-detail-tooltip"
        title={detail}
        placement={type === 'left' ? "topRight" : "topLeft"}
      >
        <div className={`tree-pin tree-pin-${type}`} style={{ ...this.getPinStyle(type), ...pinStyle }}></div>
      </Tooltip>
    </div>
  }

  multiPinRender = () => {
    const { nodesSelectBox } = this.state;
    const { voltageResult, data: _data, gridHeight, whatIf, spiceVoltageResult } = this.props;
    const { connectorPins = {}, pins, name } = _data;
    return nodesSelectBox.filter(node => !node.hidden).map(node => {
      const { net, position, deepIndex, data = {} } = node;
      const [prevNet, nextNet] = net.split(' - ');
      const _net = prevNet
      let _pins = connectorPins[_net] ? connectorPins[_net] : data.pins && data.pins.length ? data.pins.filter(pin => pin.net === _net) : pins.filter(pin => pin.net === _net);
      let volPins = voltageResult.filter(v => _pins.some(p => p.pin === v.pin));
      let voltageArr = volPins.map(v => Number(v.voltage));

      let spiceVolPins = spiceVoltageResult.filter(v => _pins.some(p => p.pin === v.pin));
      let whatIfClass = false;
      if (whatIf && spiceVolPins.length) {
        voltageArr = spiceVolPins.map(v => Number(v.voltage))
        whatIfClass = true
      }
      let detailList = [];
      if (position === "left") {
        detailList = _pins.map(pin => `${prevNet} --> [${name}::${pin.pinName}::${pin.pin}]`);
      } else {
        detailList = _pins.map(pin => `[${name}::${pin.pinName}::${pin.pin}] --> ${prevNet}`);
      }
      const detail = detailList && detailList.length ? <div className="cascade-display-detail-box">
        {detailList.map((detail, index) => <div key={index}>{detail}</div>)}
      </div> : null
      const voltage = voltageArr.length ?
        Number(_.mean(voltageArr)).toPrecision(3).length > 5 ? Number(Number(_.mean(voltageArr)).toPrecision(3)).toExponential() : Number(_.mean(voltageArr)).toPrecision(3)
        : null
      const top = (deepIndex - 0.75) * gridHeight;
      const style = {
        pinStyle: { top },
        vStyle: { top: top - 18 }
      }
      return <Fragment key={`${net}-${deepIndex}`}>
        {this.pinDom(position, voltage, detail, style, whatIfClass)}
      </Fragment>
    })
  }

  pinRender = () => {
    const { data } = this.props;
    const { type, middle } = data;
    const { pinSingle } = this;
    switch (type) {
      case ROOT:
        return pinSingle('right');
      case VRM:
      case LOAD:
        return middle ?
          <Fragment>
            {pinSingle('left')}
            {pinSingle('right')}
          </Fragment>
          : pinSingle('left');
      case DRIVER:
        return <Fragment>
          {pinSingle('left')}
          {pinSingle('right')}
          {/* {pinSingle('gnd')} */}
        </Fragment>
      default:
        return <Fragment>
          {pinSingle('left')}
          {pinSingle('right')}
        </Fragment>
    }
  }

  IconBoxRender = () => {
    const { data, columnIndex, showLayout, running } = this.props;
    const { fontSize } = this.state;
    const iconList = [];
    if (data.type === VRM || data.type === LOAD) {
      if (showLayout) {
        iconList.push({ type: 'eye', clickFn: this.props.showCurrentTree, data, columnIndex })
      }
    }
    if (data.type === DRIVER) {
      iconList.push({ type: 'setting', clickFn: this.showModelPanel, data, columnIndex })
    }
    if (data.type !== ROOT && !running) {
      iconList.push({ type: 'close', clickFn: this.deleteItem, data, columnIndex })
    }
    const _fontSize = fontSize > 14 ? 12 : fontSize < 10 ? 8 : fontSize - 2;
    const fontSizePx = `${_fontSize}px`
    return (
      <div className="tree-icon-box" style={{ height: fontSizePx, lineHeight: fontSizePx }} >
        {iconList.map(icon => {
          const Icon = getIcon(icon.type)
          if (!Icon) {
            return null;
          }
          return icon.tooltip ? <Tooltip title={icon.title} overlayClassName="aurora-tooltip" key={icon.type}>
            <Icon style={{ fontSize: _fontSize }} className="tree-component-icon" />
          </Tooltip>
            : <Icon style={{ fontSize: _fontSize }} key={icon.type} className="tree-component-icon" onClick={(e) => icon.clickFn(e, icon)} />;
        })}
      </div>
    );
  }

  resultRender = () => {
    const { passResultData = {} } = this.state;
    const { data = {}, setting: { autoSize, voltageSize } } = this.props;
    if (!passResultData) {
      return null;
    }

    const { realVMin, realVMax } = passResultData;
    const { vMin, vMax } = data;
    const notSet = vMin || vMax ? false : true
    // const pass = notSet ? false : realVMin && realVMax && vMin && vMax ?
    //   Number(realVMin) > Number(vMin) && Number(vMax) > Number(realVMax) ? true : false : false
    const pass = notSet ? false : realVMin && vMin ?
      Number(realVMin) > Number(vMin) ? true : false : false
    const spanStyle = notSet ? { color: '#c9c9c9' } : pass ? { color: '#00f61c' } : { color: '#f5222d' }
    return <div className='tree-result-box' style={autoSize ? {} : { fontSize: Number(voltageSize) }}>
      <Tooltip overlayClassName="aurora-tooltip cascade-detail-tooltip" title={this.resultDetail(passResultData, data, pass, notSet)}>
        <span style={{ fontWeight: 'bold', cursor: 'pointer', ...spanStyle }}>{notSet ? 'N' : pass ? 'P' : 'F'}</span>
      </Tooltip>
    </div>
  }

  resultDetail = (passResultData, data, pass, notSet) => {
    const { realVMin, realVMax } = passResultData;
    const { vMin, vMax } = data;
    const spanStyle = notSet ? { color: '#393939' } : pass ? { color: '#00a913' } : { color: '#f5222d' }
    const showMin = Number(realVMin).toPrecision(3).length > 5 ? Number(Number(realVMin).toPrecision(3)).toExponential() : Number(realVMin).toPrecision(3);
    // const showMax = Number(realVMax).toPrecision(3).length > 5 ? Number(Number(realVMax).toPrecision(3)).toExponential() : Number(realVMax).toPrecision(3);
    return <div className="cascade-display-detail-box">
      <div>Status: <span style={spanStyle}>{notSet ? 'Not set spec.' : pass ? 'Pass' : 'Failed'}</span></div>
      <div>Vmin: <span style={notSet ? {} : realVMin && Number(vMin) > Number(realVMin) ? { color: '#f5222d' } : {}}>
        {realVMin ? showMin : 'Not Found'}
      </span></div>
      {/* <div>Vmax: <span style={notSet ? {} : realVMax && Number(vMax) < Number(realVMax) ? { color: '#f5222d' } : {}}>
        {realVMax ? showMax : 'Not Found'}
      </span></div> */}
    </div>
  }

  multiBroDetail = (pairResultData) => {
    return pairResultData.length ? <div className="cascade-display-detail-box powertree-pair-result-detail-tooltip">
      {pairResultData.map((pairArray, index) => {
        const { min = {}, max = {}, avg = {}, pairs, componentOut, ifValue } = pairArray;
        return <div key={index}>
          <div style={{ fontWeight: 'bold' }}>{componentOut}:</div>
          {pairs.length > 1 ? <Fragment>
            <div><span>Max: </span>{max.number}{max.unit}</div>
            <div><span>Avg: </span>{avg.number}{avg.unit}</div>
            <div><span>Min: </span>{min.number}{min.unit}</div>
          </Fragment>
            : this.singlePairRender(avg, ifValue, componentOut, pairs)}
        </div>
      })}
    </div> : null
  }

  pairResultRender = () => {
    const { pairResultData, multiPrev, multiBro, fontSize } = this.state;
    const { gridHeight, gridWidth, shot, whatIf, setting: { autoSize, pairResSize } } = this.props;

    if (multiBro && multiPrev) {
      const firstPair = pairResultData && pairResultData.length ? pairResultData[0] : null;
      if (!firstPair) {
        return null;
      }

      const { top, left } = firstPair;
      const detail = this.multiBroDetail(pairResultData)
      if (shot) {
        const _fontSize = autoSize ? fontSize > 6 ? fontSize > 10 ? 10 : fontSize : 6 : Number(pairResSize)
        return <div className='cascade-power-tree-result-screen-shot-temp' style={{ left: left - 0.15 * gridWidth, top, fontSize: _fontSize }}>
          {detail}
        </div>
      }
      return (
        <Tooltip
          overlayClassName="aurora-tooltip cascade-detail-tooltip"
          title={detail}
          key={detail}
          trigger='click'
        >
          <FileTextOutlined
            className={`aurora-tree-info-icon powertree-pair-result-detail-icon`}
            style={{ left: left + 0.1 * gridWidth, top: top + 0.33 * gridHeight }} />
        </Tooltip>
      );
    } else {
      const _fontSize = autoSize ? fontSize > 8 ? fontSize > 12 ? 12 : fontSize : 8 : Number(pairResSize)
      return pairResultData && pairResultData.length ? pairResultData.map((pairArray, index) => {
        const { top, left, min = {}, max = {}, avg = {}, pairs = [], single, componentOut, ifValue } = pairArray;
        const tooltipValue = whatIf && ifValue && ifValue.number ? ifValue : avg
        const singleV = single || pairs.length <= 1 ? true : false;
        const _top = !singleV ? top : top + 0.3 * gridHeight;
        const width = getTextWidth('Max:', _fontSize);
        const displayValue = whatIf && ifValue && ifValue.number ? ifValue : avg;
        const { number, unit } = displayValue;
        const displayWidth = getTextWidth(`${number} ${unit}`, _fontSize)
        const _left = !singleV ? left - width
          : left - (displayWidth > (0.3 * gridWidth) ? 0.5 * displayWidth : (0.1 * gridWidth));
        const htmls = !singleV ? <Fragment>
          <div><span style={{ width }}>Max:</span>{max.number}{max.unit}</div>
          <div><span style={{ width }}>Avg:</span>{avg.number}{avg.unit}</div>
          <div><span style={{ width }}>Min:</span>{min.number}{min.unit}</div>
        </Fragment>
          : this.singlePairRender(avg, ifValue, componentOut, pairs)
        const tooltips = !singleV ? <Fragment>
          <div><span style={{ width }}>Max:</span>{max.number}{max.unit}</div>
          <div><span style={{ width }}>Avg:</span>{avg.number}{avg.unit}</div>
          <div><span style={{ width }}>Min:</span>{min.number}{min.unit}</div>
        </Fragment>
          : <div>{tooltipValue ? `${tooltipValue.number}${tooltipValue.unit}` : ''}</div>
        return <Tooltip title={tooltips} overlayClassName='aurora-tooltip' key={`tooltip_${index}`}>
          <div className="cascade-power-tree-pair-result" style={{ top: _top, left: _left, fontSize: _fontSize }} key={index}>
            {htmls}
          </div>
        </Tooltip>

      }) : null
    }
  }

  singlePairRender = (value, ifValue, componentOut, pair) => {
    const { whatIf } = this.props;
    const { pairInput } = this.state;
    const displayValue = whatIf && ifValue && ifValue.number ? ifValue : value;
    const { number, unit } = displayValue;
    const whatIfClass = whatIf ? value && value.number && ifValue && ifValue.number ? Number(ifValue.number) > Number(value.number) ? 'cascade-power-tree-experiment-up-modify' : 'cascade-power-tree-experiment-down-modify' : 'cascade-power-tree-experiment-not-modify' : ''
    const input = pairInput[componentOut] || false;
    return input ? <Input
      ref={(ref) => this.inputRef = ref}
      defaultValue={ifValue && ifValue.number ? ifValue.number : ""}
      addonAfter={ifValue && ifValue.unit ? ifValue.unit : unit}
      onPressEnter={this.pairPressEnter}
      onBlur={(e) => this.changePairValue(e, ifValue && ifValue.unit ? ifValue.unit : unit, pair, componentOut)}
      className='aurora-input cascad-power-tree-pair-input'
    />
      : <div className={whatIfClass} onClick={whatIf ? (e) => this.openPairInput(e, componentOut) : null}>{number}{unit}</div>
  }

  driverNodeRender = () => {
    const { nodesSelectBox, nodes } = this.state;
    const { data: { model = {} }, gridWidth } = this.props;
    const nodeList = nodes[model.subckt] || [];
    return nodesSelectBox.map(box => {
      const { position, top, data } = box;
      return <Select
        key={position + top}
        className={`tree-node-select tree-pin-${position} ${data.node ? 'tree-node-select-has-value' : ''}`}
        style={{ top, maxWidth: gridWidth * 0.27 }}
        size="small"
        suffixIcon={null}
        value={data.node || ""}
        popupMatchSelectWidth={false}
        onChange={(value) => this.selectCurrentNode(value, data)}
      >
        {nodeList.map(node => <Option key={node}>{node}</Option>)}
      </Select>
    });
  }

  resultShotRender = () => {
    const { gridWidth, showResult, gridHeight, setting: { autoSize, passSize, pairResSize } } = this.props;
    const { top, left, passResultData = {}, totalResResult } = this.state;
    const { pass, pairRes } = showResult;

    const { data = {} } = this.props;
    let passRender = null, totalResRender = null, width = gridWidth * 0.6;
    if (!pass || !passResultData || !Object.keys(passResultData).length) {
      passRender = null;
    } else {
      const { realVMin, realVMax } = passResultData;
      const { vMin, vMax } = data;
      const showMin = Number(realVMin).toPrecision(3).length > 5 ? Number(Number(realVMin).toPrecision(3)).toExponential() : Number(realVMin).toPrecision(3);
      // const showMax = Number(realVMax).toPrecision(3).length > 5 ? Number(Number(realVMax).toPrecision(3)).toExponential() : Number(realVMax).toPrecision(3);
      // const _width = getTextWidth(`Vmin: ${realVMin ? showMin : 'Not Found'}|Vmax: ${realVMax ? showMax : 'Not Found'}`, 12);
      const _width = getTextWidth(`Vmin: ${realVMin ? showMin : 'Not Found'}`, 12);
      width = width > _width ? width : _width
      passRender = <Fragment>
        <span>Vmin: <span style={realVMin && Number(vMin) > Number(realVMin) ? { color: '#f5222d' } : {}}>
          {realVMin ? showMin : 'Not Found'}
        </span></span>
        {/* <span>|</span>
        <span>Vmax: <span style={realVMax && Number(vMax) < Number(realVMax) ? { color: '#f5222d' } : {}}>
          {realVMax ? showMax : 'Not Found'}
        </span></span> */}
      </Fragment>
    }

    if (totalResResult && totalResResult.totalRes && pairRes) {
      const { isWhatIf, totalRes } = totalResResult;
      const { number, unit } = this.judgeUnitChange(Number(totalRes));
      const _width = getTextWidth(`Total Res: ${totalRes}Ω`, pairResSize ? Number(pairResSize) : 12);
      width = width > _width ? width : _width
      totalResRender = <Fragment>
        <Tooltip title={`Total Res: ${totalRes}Ω`} overlayClassName='aurora-tooltip'>
          <span>Total Res: </span>
          <span className={isWhatIf ? 'cascade-power-tree-experiment-not-modify' : ''}>{number}{unit}</span>
        </Tooltip>
      </Fragment>
    }

    if (!totalResRender && !passRender) {
      return;
    }

    const height = passRender && totalResRender ? 38 : 24;
    const topSize = 0.2 * gridHeight - height > 0 ? 0.2 * gridHeight - height : 0;
    const fontSize = passRender && totalResRender ? 0.2 * gridHeight <= 28 ? 9 : 11 : 12;
    const _style = { top: top + topSize, left: left + gridWidth * 0.2 - 0.5 * (width - 0.6 * gridWidth), width, height, fontSize };
    return <div style={_style} className='cascade-power-tree-result-screen-shot-temp'>
      <span style={autoSize ? {} : { fontSize: Number(pairResSize) }}>{totalResRender}</span>
      {passRender && totalResRender && <br />}
      <span style={autoSize ? {} : { fontSize: Number(passSize) }}>{passRender}</span>
    </div>
  }

  render() {
    const { gridWidth, gridHeight, data, showResult, resultDisplay, driverList, notDisplayBox, selectkey, showPCB, setting } = this.props;
    const { top, left, padding, fontSize, showModel, nodes } = this.state;
    const { type, name, broComp, model = {}, driverMap = [], grid = 1, pcbKey, hiddenPins } = data;
    const content = this.getCompContent(type, name, grid);
    const _style = { width: gridWidth, height: gridHeight * grid, top, left, padding };
    const { autoSize = true, nameSize } = setting
    return top > -1 ? <Fragment>
      {showResult && this.resultShotRender()}
      {!notDisplayBox && setting[`display${type}`] && <div className={`${pcbKey && selectkey === pcbKey ? 'cascade-power-tree-canvas-select-pcb-component' : ''} cascade-power-tree-canvas-component`} style={_style}>
        <div className="tree-component-content" style={{ backgroundColor: content.color }} onMouseEnter={this.mouseEnterHandle} onMouseLeave={this.mouseLeaveHandle}>
          <div className="tree-component-name" style={{ fontSize: autoSize ? fontSize : Number(nameSize), ...content.style }}>{`${name}${broComp ? ` - ${broComp.name}` : ''}`}</div>
          {content.render ? content.render() : null}
          {grid > 1 ? this.multiPinRender() : this.pinRender()}
          {type === DRIVER && this.driverNodeRender()}
          {this.IconBoxRender()}
          {showResult && showResult.pass && this.resultRender()}
          {showPCB && <span className="tree-component-pcb-name" style={{ top: 0 - 0.2 * gridHeight, left: - 0.2 * gridWidth }}>{showPCB.pcbName}</span>}
        </div>
      </div>}
      {!hiddenPins && resultDisplay && showResult.pairRes && this.pairResultRender()}
      {!notDisplayBox && showModel && <DriverModel name={`Driver Model - ${name}`} saveDriver={this.saveDriver} driverList={driverList} model={model} pinMap={driverMap} nodes={nodes} />}
    </Fragment> : null
  }
}

export default TreeComponent;