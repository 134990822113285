import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import {
  GET_CHANNEL_PDN_CONTENT,
  SAVE_ROCKY_PDN_TO_SERVER,
  UPDATE_ROCKY_PDN_INFO,
  SAVE_PDN_VRM,
  UPDATE_COMPONENTS,
  COMP_REMOVED_CHANGE,
  SAVE_SPLIT_COMPS,
  SAVE_MERGE_COMPS,
  SAVE_PDN_CONFIG,
  ROCKY_PDN_RE_EXTRACTION,
  SAVE_ROCKY_PDN_PKG_DIE_MODEL,
  UPDATE_COMPONENT_RLC_PREFIX,
  ROCKY_PDN_SELECT_NETS,
  UPDATE_PKG_DIE_PORTS_BY_CPM
} from './actionTypes';
import {
  getChannelPDNContentPromise,
  updateRockyPDNContentPromise,
  getPowerComponents,
  rockyPDNReExtraction,
  PDNExtraction,
  getComponentsWithNetList,
  RLCCONComponent,
  findVRMComponents,
  filterComps
} from '@/services/Rocky';
import {
  updateRockyPDNContent,
  updateRockyPDNInfo,
  updateRockyPDNIfDoExtraction,
  updateLoadingPCB,
  updateCompPrefixDisplay,
  reLaunchPDNExtraction
} from './action';
import {
  updateComponentLoading,
  updateErrorCheckList
} from '../rocky/action';
import { ROCKY_PDN_VERSION } from '@/version';
import DesignInfo from '@/services/Rocky/pcbInfo';
import { getVRMs, componentFilter, prefixCheckByVRM } from '@/services/helper/componentsHelper';
import { getLayoutPCBInfo } from '../rocky/saga';
import { getRockyPdnErrorCheck } from '../../errorCheck/PDNErrorCheck';
import { debugMonitorAction } from '../project/action';
import { SortFn } from '@/services/helper/sort';
import { checkRLCValue } from '@/services/helper/dataProcess';
import { resetDefaultValue } from '@/services/helper/numberHelper';
import { getDefaultRLCValue } from '@/services/helper/RLCValue';
import { getPwrComponentErrorCheck } from '../../errorCheck/ErrorCheck';
import CompRLCPrefixLib from '@/services/helper/componentsHelper/componentRLCPrefix';
import { getLayoutComponents } from "@/services/PCBHelper";
import packageVerificationConstructor from '../../../../services/Rocky/PackageHelper';

const ChipTypes = ['Controller', 'Memory'];
function* getContent(action) {
  const { PDNID } = action;
  //update component model and pin table loading
  yield put(updateComponentLoading(false));
  yield put(updateLoadingPCB(true));
  const response = yield call(getChannelPDNContentPromise, PDNID);
  if (!response) {
    return;
  }
  let pdnContent = response && response.pdnContent ? response.pdnContent : {};
  let save = false;

  //update readyForSim
  let readyForSim = response.readyForSim;
  const errorCheck = getRockyPdnErrorCheck(pdnContent);
  if (errorCheck) {
    readyForSim = 0;
  } else {
    readyForSim = 1;
  }

  if (response.readyForSim !== readyForSim) {
    save = true;
  }

  const prefixCheck = prefixCheckByVRM(pdnContent.VRM);
  yield put(updateCompPrefixDisplay(prefixCheck));

  const rockyPDNInfo = {
    PDNID: response.id,
    pdnContent,
    verificationId: response.verificationId,
    designId: response.designId,
    version: response.version,
    name: response.name,
    readyForSim: readyForSim,
    ifDoExtraction: response.ifDoExtraction
  };
  yield put(updateRockyPDNContent(rockyPDNInfo));
  yield call(getLayoutPCBInfo, response.designId);
  yield put(updateLoadingPCB(false));
  if (save) {
    yield put({ type: SAVE_ROCKY_PDN_TO_SERVER });
  }
}

export function* saveRockyPDNContentToServer(action) {
  const { RockyReducer: { RockyPDN: { rockyPDNInfo }, rocky: { infoErrorCheck }, project: { currentChannelId } } } = yield select();
  if (!rockyPDNInfo || Object.keys(rockyPDNInfo).length === 0) return;
  const { PDNID, pdnContent } = rockyPDNInfo;
  let content = { ...pdnContent };
  let readyForSim = rockyPDNInfo.readyForSim;
  if (!pdnContent.Config) {
    content.Config = new PDNExtraction();
    //re_extraction
    yield put({ type: ROCKY_PDN_RE_EXTRACTION });
  }

  const errorCheck = getRockyPdnErrorCheck(content);
  if (errorCheck) {
    readyForSim = 0;
  } else {
    readyForSim = 1;
  }
  // Update byte/adr/cmd's  pdn error check message
  const index = infoErrorCheck.findIndex(item => item.channelId === currentChannelId);
  if (index > -1 && infoErrorCheck[index].PDNErrorCheck) {
    const error = infoErrorCheck[index].errorType === 'all' ? errorCheck : getPwrComponentErrorCheck(pdnContent.PowerComponents);
    infoErrorCheck[index].PDNErrorCheck = error;
    if (!infoErrorCheck[index].errorCheck && !error) {
      infoErrorCheck.splice(index, 1);
    }
  }
  yield put(updateErrorCheckList(infoErrorCheck));


  const response = yield call(updateRockyPDNContentPromise, { pdnContent: { ...content }, PDNID, readyForSim, version: ROCKY_PDN_VERSION });
  return response;
};

function* _updatePDNInfo(action) {
  yield put({ type: SAVE_ROCKY_PDN_TO_SERVER });
}

function* _selectNets(action) {
  const { powerType, nets } = action;
  const { RockyReducer: { RockyPDN: { rockyPDNInfo } } } = yield select();
  const { pdnContent } = rockyPDNInfo;
  if (!pdnContent) return;

  const COMP_PREFIX_LIB = pdnContent.COMP_PREFIX_LIB || new CompRLCPrefixLib();
  let ReferenceNets = [...pdnContent.ReferenceNets];
  let PowerNets = [...pdnContent.PowerNets];
  let VRM = pdnContent.VRM;
  let PowerComponents = pdnContent.PowerComponents;
  if (powerType === 'Reference') {
    ReferenceNets = nets;
  } else if (powerType === 'Power') {
    PowerNets = nets;
  } else {
    return;
  }

  const pcbId = rockyPDNInfo.designId;
  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  const { netsList, layers } = pcbInfo;

  //find power components 
  let { newComponents, findVRMComps, findCaps } = getNewComponents({
    netList: [...ReferenceNets, ...PowerNets],
    pcbNetsList: netsList,
    layers,
    pcbId,
    COMP_PREFIX_LIB,
    prevComponents: PowerComponents
  });

  // Components filter
  newComponents = componentFilter({ Components: newComponents, ReferenceNets, PowerNets });

  // Automatically find VRM
  // // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
  //find vrm
  const _filterComps = filterComps(findVRMComps, findCaps, newComponents);

  const getVRM = getVRMs({
    VRM,
    pcbInfo: { layers, pcbNetsList: netsList },
    Components: newComponents,
    ReferenceNets,
    PowerNets,
    findVRMComps: _filterComps._findVRMComps,
    findCaps: _filterComps._findCaps,
    pcbId,
    findExtend: false,
    getLayoutComponents,
    getPowerComponentsByNets: getPowerComponents
  });
  const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
  const new_VRM = getVRM.VRM;
  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(new_VRM);
  const new_PowerNets = getVRM.PowerNets;
  let new_Component = getVRM.Components;

  // Sort by usage Cap, Res, Ind, Controller, Memory, Ignore.
  const sort = ['Cap', 'Res', 'Ind', 'Controller', 'Memory', 'Ignore'];
  new_Component = SortFn(new_Component, sort, 'usage');

  yield put(updateRockyPDNInfo({
    ReferenceNets,
    PowerNets: new_PowerNets,
    PowerComponents: new_Component,
    VRM: new_VRM
  }));

  // update prefix Check prompt
  yield put(updateCompPrefixDisplay(prefixCheck));

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  };
  //re_extraction
  yield put(reLaunchPDNExtraction());
}

function* _saveVRM(action) {
  const { vrmType, data, index } = action;
  const { RockyReducer: { RockyPDN: { rockyPDNInfo: { pdnContent } } } } = yield select();
  let { VRM, ReferenceNets, PowerNets } = pdnContent;
  if (index) {
    if (vrmType === 'model') {
      VRM.forEach(item => {
        item.model = { ...data }
      });
    } else {
      const nets = vrmType === 'powerPin' ? PowerNets : ReferenceNets;
      let { PowerComponents } = pdnContent;
      const _findComps = PowerComponents.filter(item => data === item.name);
      let vrmPins = [];
      _findComps.forEach(item => {
        const pins = item.pins.filter(pin => nets.indexOf(pin.net) > -1).map(item => item.pin);
        vrmPins.push({ comp: item.name, pins });
      })
      VRM[index - 1][vrmType] = vrmPins;
      const prefixCheck = prefixCheckByVRM(VRM);
      yield put(updateCompPrefixDisplay(prefixCheck));
    }
  }

  yield put(updateRockyPDNInfo({
    VRM
  }));
  if (vrmType !== 'model') {
    yield put({ type: ROCKY_PDN_RE_EXTRACTION })
  }
  /* yield delay(300);
*/
  //vrm data error check
  /*  if (vrmType === 'model' && data && data.id) {
     VRMData.removeContent(data.id); // delete prev data
     yield put(updateLibraryDataCheck({ dataType: 'VRM' })); // update current pdn library data error check
   } */
};

function* _updateComponent(action) {
  const { comps, usage, checked } = action;
  const { RockyReducer: { RockyPDN: { rockyPDNInfo } } } = yield select();
  let { pdnContent } = rockyPDNInfo;
  const pcbId = rockyPDNInfo.designId;
  let { PowerComponents, PowerNets, VRM, ReferenceNets } = pdnContent;
  PowerComponents.forEach(comp => {
    if (comps.includes(comp.name)) {
      if (usage === 'Removed') {
        let compItem = PowerComponents.find(item => item.part === comp.part && item.COMP_TYPE === comp.COMP_TYPE && item.usage !== 'Removed');
        if (compItem) {
          comp.value = { ...compItem.value }
        } else {
          comp.value = { r: '', l: '', c: '' }
        }
      }
      comp.usage = checked ? comp.COMP_TYPE : 'Unused';
    }
  });

  if (usage === 'Removed') {
    // re find VRM
    const res = yield call(_reFindVRM, { PowerComponents, PowerNets, ReferenceNets, VRM, pcbId });
    PowerComponents = res.PowerComponents;
    VRM = res.VRM;

    //re_extraction
    yield put({ type: ROCKY_PDN_RE_EXTRACTION });
  }
  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(VRM);
  // update prefix Check prompt
  yield put(updateCompPrefixDisplay(prefixCheck));

  yield put(updateRockyPDNInfo({
    PowerComponents,
    VRM
  }));
}

function* _compRemoved(action) {
  const { removes } = action;
  const { RockyReducer: { RockyPDN: { rockyPDNInfo } } } = yield select();
  let { pdnContent } = rockyPDNInfo;
  let { PowerComponents, PowerNets, VRM, ReferenceNets } = pdnContent;
  const pcbId = rockyPDNInfo.designId;
  PowerComponents.forEach(comp => {
    if (removes.includes(comp.name)) {
      comp.usage = 'Removed';
      comp.value = null;
    }
  });

  // re find VRM
  const res = yield call(_reFindVRM, { PowerComponents, PowerNets, ReferenceNets, VRM, pcbId });
  PowerComponents = res.PowerComponents;
  VRM = res.VRM;

  //re_extraction
  if (removes.length > 0) {
    yield put({ type: ROCKY_PDN_RE_EXTRACTION })
  }

  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(VRM);
  // update prefix Check prompt
  yield put(updateCompPrefixDisplay(prefixCheck));

  yield put(updateRockyPDNInfo({
    PowerComponents,
    VRM
  }));
}

function* _splitComponents(action) {
  const { part, splitPart, comps } = action;
  const { RockyReducer: { RockyPDN: { rockyPDNInfo: { pdnContent } } } } = yield select();
  let { PowerComponents } = pdnContent;
  const partList = PowerComponents.map(item => item.part);
  let compNames = comps.map(item => item.name)
  if (splitPart && !partList.includes(splitPart)) {
    PowerComponents.forEach(comp => {

      if (comp.part === part && compNames.includes(comp.name)) {
        comp.part = splitPart;
      }
    })
  } else if (splitPart && partList.includes(splitPart)) {
    let currentSplit = PowerComponents.filter(item => item.part === splitPart);
    PowerComponents.forEach(comp => {
      if (comp.part === splitPart && compNames.includes(comp.name)) {
        comps.part = splitPart;
        comps.model = currentSplit[0].model;
        comps.value = currentSplit[0].value;
        comps.modelName = currentSplit[0].modelName;
      }
    })
  }
  yield put(updateRockyPDNInfo({
    PowerComponents
  }));
}

function* _mergeComponents(action) {
  const { part, partList } = action;
  const { RockyReducer: { RockyPDN: { rockyPDNInfo: { pdnContent } } } } = yield select();
  let { PowerComponents } = pdnContent;
  let current = PowerComponents.filter(item => item.part === part);
  PowerComponents.forEach(comp => {
    if (partList.includes(comp.part)) {
      comp.part = part;
      comp.value = current[0].value;
      comp.model = current[0].model;
    }
  });
  yield put(updateRockyPDNInfo({
    PowerComponents
  }));
}

function* _saveConfig(action) {
  // Close extraction panel
  let { config } = action;
  let _config = { ...config };
  const { RockyReducer: { RockyPDN: { rockyPDNInfo } } } = yield select();
  const content = rockyPDNInfo && rockyPDNInfo.pdnContent ? rockyPDNInfo.pdnContent : null;
  if (!content) {
    return;
  }
  let prevConfig = { ...content.Config };
  if (!_config) {
    _config = new PDNExtraction();
  }

  const keys = Object.keys(_config);
  const prevKeys = Object.keys(prevConfig);
  const prevKeysLength = prevKeys.length;
  const reExtraction = keys.some(key => _config[key] !== prevConfig[key]);
  // re-launch extraction
  if (prevKeysLength !== keys.length || reExtraction) {
    yield put({ type: ROCKY_PDN_RE_EXTRACTION });
  }
  yield put(updateRockyPDNInfo({
    Config: { ..._config },
  }));
}

function* _reExtraction(action) {
  const { RockyReducer } = yield select();
  const RockyPDN = RockyReducer.RockyPDN;
  if (!RockyPDN) return;
  const rockyPDNInfo = RockyPDN.rockyPDNInfo;
  if (!rockyPDNInfo) return;
  const { PDNID } = rockyPDNInfo;
  yield call(rockyPDNReExtraction, PDNID);
  yield delay(500);
  yield put(updateRockyPDNIfDoExtraction(1));
}

function* _reFindVRM(action) {
  let { PowerComponents, PowerNets, ReferenceNets, VRM, pcbId, } = action;
  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  const { layers, netsList } = pcbInfo;
  // re find VRM
  const { findVRMComps, findCaps } = findVRMComponents({ PowerComponents, layers });

  // Automatically find VRM
  // // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
  const getVRM = getVRMs({
    VRM,
    pcbInfo: { layers, pcbNetsList: netsList },
    Components: PowerComponents,
    ReferenceNets,
    PowerNets,
    findVRMComps,
    findCaps,
    pcbId,
    findExtend: false,
    getLayoutComponents,
    getPowerComponentsByNets: getPowerComponents
  });
  const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
  VRM = getVRM.VRM;
  PowerComponents = getVRM.Components;

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }

  return {
    VRM,
    PowerComponents
  }
}

function* _savePKGDieModel(action) {
  const { pkg, die, part } = action;
  const { RockyReducer: { RockyPDN: { rockyPDNInfo: { pdnContent } } } } = yield select();
  let { PowerComponents } = pdnContent;
  PowerComponents.forEach(comp => {
    if (comp.part === part) {
      comp.pkg = pkg ? JSON.parse(JSON.stringify(pkg)) : comp.pkg;
      comp.die = die ? JSON.parse(JSON.stringify(die)) : comp.die;
    }
  });
  yield put(updateRockyPDNInfo({
    PowerComponents
  }));
}

function* _updateCompRLCPrefix(action) {
  const { COMP_PREFIX_LIB } = action;

  const { RockyReducer: { RockyPDN: { rockyPDNInfo } } } = yield select();
  const { pdnContent } = rockyPDNInfo;
  let { PowerComponents, VRM } = pdnContent;
  let ReferenceNets = [...pdnContent.ReferenceNets];
  let PowerNets = [...pdnContent.PowerNets];
  const pcbId = rockyPDNInfo.designId;

  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  const { netsList, layers } = pcbInfo;

  //update component type by custom component reference designator.
 /*  let newComponents = [] *//* , exsitcomponentsName = [], findVRMComps = [], findCaps = [] */;
  let { newComponents, findVRMComps, findCaps } = getNewComponents({
    netList: [...ReferenceNets, ...PowerNets],
    pcbNetsList: netsList,
    layers,
    pcbId,
    COMP_PREFIX_LIB,
    prevComponents: PowerComponents
  });

  // Components filter
  newComponents = componentFilter({ Components: newComponents, ReferenceNets, PowerNets });

  // Automatically find VRM
  // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]

  const pwrNets = PowerNets;
  const gndNets = ReferenceNets;

  const _filterComps = filterComps(findVRMComps, findCaps, newComponents);

  const getVRM = getVRMs({
    pcbInfo: { layers, pcbNetsList: netsList },
    VRM,
    Components: newComponents,
    ReferenceNets: gndNets,
    PowerNets: pwrNets,
    findVRMComps: _filterComps._findVRMComps,
    findCaps: _filterComps._findCaps,
    pcbId,
    COMP_PREFIX_LIB,
    findExtend: false,
    getLayoutComponents,
    getPowerComponentsByNets: getPowerComponents
  });
  const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
  VRM = getVRM.VRM;

  // If VRM is not recognized, we nedd to give a prompt: Check/update RLC components name prefix
  const prefixCheck = prefixCheckByVRM(VRM);

  //TODO
  // if includeExtended not exist , getVRM.PowerNets only main powerNets,not includes extended nets
  //PowerNets = includeExtended ? getVRM.PowerNets : PowerNets;
  PowerNets = getVRM.PowerNets
  newComponents = getVRM.Components;

  // Sort by usage Cap, Res, Ind, Controller, Memory, Ignore.
  const sort = ['Cap', 'Res', 'Ind', 'Controller', 'Memory', 'Ignore'];
  newComponents = SortFn(newComponents, sort, 'usage');

  if (DEBUG_MONITOR.length > 0) {
    yield put(debugMonitorAction(DEBUG_MONITOR));
  }

  yield put(updateRockyPDNInfo({
    PowerNets,
    ReferenceNets,
    PowerComponents: newComponents,
    VRM,
    COMP_PREFIX_LIB
  }));

  // update prefix Check prompt
  yield put(updateCompPrefixDisplay(prefixCheck));
}

function getNewComponents({ netList, pcbNetsList, layers, pcbId, COMP_PREFIX_LIB, prevComponents }) {
  //update component type by custom component reference designator.
  let newComponents = [], exsitcomponentsName = [], findVRMComps = [], findCaps = [];
  prevComponents = prevComponents ? JSON.parse(JSON.stringify(prevComponents)) : [];
  const _Components = getComponentsWithNetList({ netList, pcbNetsList, layers, pcbId, COMP_PREFIX_LIB });
  for (const comp of _Components) {
    const { name, pin, net, value, type, part } = comp;
    const _index = exsitcomponentsName.indexOf(name);
    if (_index < 0) {
      // Not exsit component
      let newComp;
      if (type === 'Cap') {
        let _value = checkRLCValue(value);
        newComp = new RLCCONComponent({ name, usage: type, part, pins: [{ pin, net }] });
        newComp.value = {
          r: "0",
          l: "0",
          c: _value ? _value : "0"
        }
        //set default res value by cap value
        if (!newComp.value.r || newComp.value.r === 0 || newComp.value.r === '0') {
          newComp.value.r = getDefaultRLCValue(_value, 'Res');
        }

        //set default ind value by cap value
        if (!newComp.value.l || newComp.value.l === 0 || newComp.value.l === '0') {
          newComp.value.l = getDefaultRLCValue(_value, 'Ind');
        }
        newComp.COMP_TYPE = type;

        //find component in prev components
        let existComp = prevComponents.find(item => item.name === name);
        //cap, When the C of Capacitance is deleted in Prefix, it will become Ignore
        if (existComp && [type, 'Ignore'].includes(existComp.usage)) {
          newComp.value = existComp.value;
          findCaps.push({ ...newComp, location: comp.location });
        }

        //Unused
        if (existComp && existComp.usage === 'Unused') {
          newComp.usage = 'Unused';
          newComp.value = existComp.value;
          findCaps.push({ ...newComp, location: comp.location });
        }

        //removed
        if (existComp && existComp.usage === 'Removed') {
          newComp.usage = 'Removed'
          newComp.value = null;
        }
      } else if (type === 'Res' || type === 'Ind') {
        let _value = checkRLCValue(value);
        if (type === 'Res') {
          //resistor default value to 0 that is smaller than 10 mOhm
          _value = resetDefaultValue(_value);
        }

        if (!_value) {
          _value = "0";
        }
        newComp = new RLCCONComponent({ name, usage: type, part, pins: [{ pin, net }], value: _value });
        newComp.COMP_TYPE = type;
        ////find component in prev components
        let existComp = prevComponents.find(item => item.name === name);
        if (existComp && existComp.usage === type) {
          newComp.value = existComp.value;
        }

        if (existComp && existComp.usage === 'Unused') {
          newComp.usage = 'Unused';
          newComp.value = existComp.value;
        }
        findVRMComps.push({ ...newComp, location: comp.location });
      } else {
        //find component in prev components
        let existComp = prevComponents.find(item => item.name === name);
        if (existComp) {
          //find Controller and Memory
          if (ChipTypes.includes(existComp.usage)) {
            newComp = JSON.parse(JSON.stringify(existComp));
          } else {
            newComp = new RLCCONComponent({ name, usage: type, value: existComp.value, part, pins: [{ pin, net }] });
            newComp.COMP_TYPE = type;
          }
        } else {
          newComp = new RLCCONComponent({ name, usage: type, value: '', part, pins: [{ pin, net }] });
          newComp.COMP_TYPE = type;
        }
      }
      newComponents.push(newComp);
      // Update components name list
      exsitcomponentsName.push(name);
    } else {
      // Exsit component
      newComponents[_index].pins.push({ pin, net });
    }
  }

  return { newComponents, findVRMComps, findCaps };
}

function* rockySaga() {
  yield takeEvery(GET_CHANNEL_PDN_CONTENT, getContent);
  yield takeEvery(UPDATE_ROCKY_PDN_INFO, _updatePDNInfo);
  yield takeEvery(SAVE_PDN_VRM, _saveVRM);
  yield takeEvery(UPDATE_COMPONENTS, _updateComponent);
  yield takeEvery(COMP_REMOVED_CHANGE, _compRemoved);
  yield takeEvery(SAVE_SPLIT_COMPS, _splitComponents);
  yield takeEvery(SAVE_MERGE_COMPS, _mergeComponents);
  yield takeEvery(SAVE_PDN_CONFIG, _saveConfig);
  yield takeEvery(ROCKY_PDN_RE_EXTRACTION, _reExtraction);
  yield takeEvery(SAVE_ROCKY_PDN_PKG_DIE_MODEL, _savePKGDieModel);
  yield takeEvery(UPDATE_COMPONENT_RLC_PREFIX, _updateCompRLCPrefix);
  yield takeEvery(ROCKY_PDN_SELECT_NETS, _selectNets);
}

export default rockySaga;