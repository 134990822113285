export const SIERRA = 'sierra-2020-05-30';  // --sierra
export const CASCADE = 'cascade';
export const AURORA = '2';
export const CN = 'Aurora-China-2019-12-20'; // --CN, TianjinYun
export const EN = 'en'; // --en, AWS
export const FASTPI = 'fastpi-2020-05-29';  // --fastPI
export const ALIYUN = 'Aurora-aliyun-2019-12-20';  // --aliyun
export const ROCKY = 'rocky'; // --rocky
export const ROCKY_PUBLIC = 'rocky_public' // --rocky public
export const AURORA_PUBLIC = 'aurora_public'; // --aurora
export const ANDES = "andes"; // --andes

class AuroraVersion {
  constructor() {
    this.platformVersion = null;
  }
};

const Version = new AuroraVersion();
export default Version;
