import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExtractionPorts from '@/components/ExtractionPorts/portsRender';
import { getSignalNetInfo, PortsGenerationSetup } from '@/services/ExtractionPortsHelper';
import { updatePortSetupsToServer } from '../../store/channel/action';
import DesignInfo from '@/services/Andes_v2/pcbInfo';
import { ANDES_V2 } from '@/constants/pageType';
import { updateSetupComponentsByPortType } from '@/services/ExtractionPortsHelper';
import { SERDES_TYPES } from '../../../../services/PCBHelper';
import { EXTRACTION_OPTIONS } from '../../../../services/TopBarHelper';
import { getExtractionType, updatePortAvoidSinglePinGroup } from '../../../../services/Andes_v2/channel';
import { saveStackupToServer } from '../../../LayoutExplorer/components/Stackup_v1/store/actionCreators';
import { NEW_MATERIAL, SOLDER } from '../../../../services/Stackup/Material';
import LayoutData from '@/services/data/LayoutData';
import { BALL_TYPE_NONE, PIN_GROUP } from '../../../../services/ExtractionPortsHelper/portTableHelper';

class ChannelExtractionPorts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      port_setups: [],
      referenceNets: [],
      generateRefNets: [],
      ports_generate_setup_list: [],
      setupComponents: [],
      avoidSinglePinGroup: {}
    }
  }

  componentWillMount = () => {
    this.getDefaultInfo();
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate = (prevProps) => {
    //switch channel , close panel
    if (this.props.channelId !== prevProps.channelId) {
      this.props.closePanel();
    }
  }

  getDefaultInfo = () => {
    const { port_setups, referenceNets, components, ports_generate_setup_list } = this.props;
    let setupList = ports_generate_setup_list, avoidSinglePinGroup = {};
    if (!ports_generate_setup_list || !ports_generate_setup_list.length) {
      setupList = components.filter(item => SERDES_TYPES.includes(item.type)).map(item => { return { component: item.name, setup: new PortsGenerationSetup({}) } });
    }
    const _port_setup_list = port_setups && port_setups.length ? port_setups : [];

    const _components = components.map((item) => {
      if (item.ball_type !== BALL_TYPE_NONE) {
        return {
          ...item,
          ball_material: item.ball_material || SOLDER
        }
      }
      return item;
    })
    //default avoid_single_pin_group
    for (let item of setupList) {
      if (item.setup && item.component && item.setup.portType === PIN_GROUP) {
        const findPort = _port_setup_list.find(it => it.positive && it.positive.component === item.component);
        avoidSinglePinGroup[item.component] = findPort && findPort.positive && findPort.positive.avoid_single_pin_group ? true : false
      }
    }
    this.setState({
      port_setups: _port_setup_list,
      ports_generate_setup_list: [...setupList],
      referenceNets,
      generateRefNets: [...referenceNets],
      setupComponents: JSON.parse(JSON.stringify(_components)),
      avoidSinglePinGroup
    })
    this.props.updateMaterials && this.props.updateMaterials();
  }

  _updatePortSetups = (port_setups, generationSetup, saveType) => {
    const { ports_generate_setup_list } = this.state;
    this.setState({
      port_setups,
      ports_generate_setup_list: generationSetup ? generationSetup : ports_generate_setup_list
    }, () => {
      this.saveSetup();
      this.changeSetupBySaveType(saveType);
    });
  }

  changeSetupBySaveType = (saveType) => {
    const { setupOnly } = this.props;
    switch (saveType) {
      case "close":
        this.props.closePanel();
        break;
      case "changePage":
        this.props.changePage(EXTRACTION_OPTIONS);
        break;
      case "modeling":
        this.props.startModeling(setupOnly);
        break;
      default: break;
    }
  }

  updateReferenceNets = (keys) => {
    this.setState({
      referenceNets: keys
    })
  }

  updateGenerateRefNets = (nets) => {
    this.setState({
      generateRefNets: nets
    })
  }

  updateSetupComponents = (setupComponents) => {
    this.setState({
      setupComponents
    })
  }

  updateAvoidSinglePinGroup = (avoidSinglePinGroup) => {
    this.setState({
      avoidSinglePinGroup
    })
  }

  saveSetup = () => {
    const { port_setups, generateRefNets, ports_generate_setup_list, setupComponents, avoidSinglePinGroup } = this.state;
    const { extraction, designId, stackupData } = this.props;
    this.props.updateMaterials && this.props.updateMaterials();
    const _setupComponents = updateSetupComponentsByPortType({
      components: setupComponents,
      ports_generate_setup_list,
      designId,
      extractionType: getExtractionType(extraction),
      isUsedBall: true
    });
    const _port_setups = updatePortAvoidSinglePinGroup({ port_setups, ports_generate_setup_list, avoidSinglePinGroup });

    this.props._updatePortSetupsToServer({ port_setups: _port_setups, referenceNets: generateRefNets, ports_generate_setup_list, components: _setupComponents });
    LayoutData.saveStackupJson(designId, {
      data: stackupData.layers,
      materialList: stackupData.materials,
      unit: stackupData.unit
    }, true);
    this.props._saveStackupToServer(stackupData, designId);
  }

  savePortsSetup = (saveType) => {
    const confirm = this.ref.savePorts(saveType);
    if (!confirm) {
      this.saveSetup();
      this.changeSetupBySaveType(saveType);
    }
    return confirm;
  }

  onRef = (ref) => {
    this.ref = ref;
  }

  saveStackupToServer = ({ newMaterial, selectMaterialName }) => {
    const { stackupData, designId } = this.props;
    // materials
    let _materialList = [...stackupData.materials]
    if (selectMaterialName === NEW_MATERIAL) {
      _materialList.push({ ...newMaterial })
    } else if (!_materialList.find(item => item.name === selectMaterialName)) {
      _materialList.push({ ...newMaterial })
    } else {
      const _index = _materialList.findIndex(item => item.name === selectMaterialName);
      _materialList[_index] = { ...newMaterial };
    }

    // layers
    const _layers = stackupData.layers.map((item) => {
      if (item.material === selectMaterialName) {
        return {
          ...item,
          material: newMaterial.name
        };
      }
      return item;
    })

    const _stackupData = {
      layers: _layers,
      materials: _materialList,
      unit: stackupData.unit,
      version: stackupData.version
    }

    const stackups = LayoutData.saveStackupJson(designId, {
      data: _stackupData.layers,
      materialList: _stackupData.materials,
      unit: _stackupData.unit
    }, true);

    this.props.setStackupData(_stackupData)
    this.props._saveStackupToServer(stackups, designId);
  }

  render() {
    const { port_setups, referenceNets, ports_generate_setup_list, generateRefNets, setupComponents, avoidSinglePinGroup } = this.state;
    const { designId, powerNets, signals, extraction } = this.props;

    return (<ExtractionPorts
      {...this.props}
      onRef={this.onRef}
      designId={designId}
      product={ANDES_V2}
      ports_generate_setup_list={ports_generate_setup_list}
      port_setups={port_setups}
      DesignInfo={DesignInfo}
      components={setupComponents}
      signals={signals}
      referenceNets={referenceNets}
      generateRefNets={generateRefNets}
      extractionType={getExtractionType(extraction)}
      allReferenceNetList={powerNets.map(item => item.name)}
      getSignalNetInfo={getSignalNetInfo}
      closePanel={this.props.closePanel}
      changePage={this.props.changePage}
      updatePortSetups={this._updatePortSetups}
      updateReferenceNets={this.updateReferenceNets}
      updateGenerateRefNets={this.updateGenerateRefNets}
      updateSetupComponents={this.updateSetupComponents}
      saveStackupToServer={this.saveStackupToServer}
      isUsedBall={true}
      avoidSinglePinGroup={avoidSinglePinGroup}
      updateAvoidSinglePinGroup={this.updateAvoidSinglePinGroup}
    />)
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: { channel: { channelId, channelInfo: { designId, content = {} } } } } = state;

  const {
    port_setups = [],
    components = [],
    powerNets = [],
    signals = [],
    referenceNets = [],
    ports_generate_setup_list,
    extraction = {}
  } = content;
  return {
    channelId,
    port_setups,
    components,
    powerNets,
    signals,
    referenceNets,
    designId,
    ports_generate_setup_list,
    extraction
  }
};


const mapDispatch = (dispatch) => ({
  _updatePortSetupsToServer(data) {
    dispatch(updatePortSetupsToServer(data))
  },
  _saveStackupToServer(stackupData, designID) {
    dispatch(saveStackupToServer(stackupData, designID))
  },
})

export default connect(mapState, mapDispatch)(ChannelExtractionPorts);