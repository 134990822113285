import { getIntegersInStr } from '../helper/numberHelper';
import _ from 'lodash';
import { strDelimited } from '../helper/split';
import auroraDBJson from './auroraDbData';
import { IND } from '../PCBHelper';
import componentSetting from '../Cascade/helper/compSettingHelper';

const OUTPUT = 'output';
function groupPinMapPinsByOutPutPinNet({ pinStore, pinTable, type }) {
  const targetPins = pinTable.map(item => item[OUTPUT]).flat(2) || [];
  const pinsInfo = type === "obj" ?
    pinStore.filter(pin => targetPins.find(item =>
      (item.pinName === pin.pinName || item.pin === pin.pin)
      && (!item.comp || item.comp === pin.comp)
    ))
    : pinStore.filter(pin => targetPins.includes(`${pin.comp ? `${pin.comp}_` : ""}${pin.pinName}`));

  let _pinTable = [];
  const notFindOutputPins = type === "obj"
    ? targetPins.filter(item => !pinStore.find(it =>
      (it.pinName === item.pinName || it.pin === item.pin)
      && (!it.comp || it.comp === item.comp)
    ))
    : targetPins.filter(item => !pinStore.find(it => `${it.comp ? `${it.comp}_` : ""}${it.pinName}` === item));

  const sortPinByNet = {};
  pinsInfo.forEach(pin => {
    const sortNet = pin.net;
    const findPrev = type === "obj" ? targetPins.find(item =>
      (item.pinName === pin.pinName || item.pin === pin.pin)
      && (!item.comp || item.comp === pin.comp)) : null;
    const pinInfo = type === "obj" ? {
      pin: pin.pin,
      pinName: findPrev && findPrev.pinName ? findPrev.pinName : pin.pinName,
      comp: pin.comp
    } : `${pin.comp ? `${pin.comp}_` : ""}${pin.pinName}`;
    if (sortPinByNet[sortNet]) {
      sortPinByNet[sortNet].push(pinInfo)
    } else {
      sortPinByNet[sortNet] = [pinInfo];
    }
  })
  const nets = Object.keys(sortPinByNet);
  let { notKnowPwrSensePins, notKnowGndSensePins, notKnowInputPins } = getAllSensePins(pinTable);
  nets.forEach((net, index) => {
    const output = sortPinByNet[net];
    const find = type === "obj"
      ? pinTable.find(item => item.output.some(outputItem => !!output.find(it =>
        (it.pin === outputItem.pin || it.pinName === outputItem.pinName)
        && (!it.comp || it.comp === outputItem.comp)
      )))
      : pinTable.find(item => item.output.some(name => output.includes(name)));

    const findOutputIndex = type === "obj"
      ? notFindOutputPins.findIndex(item => output.find(it =>
        (it.pin === item.pin || it.pinName === item.pinName)
        && (!it.comp || it.comp === item.comp)
      ))
      : notFindOutputPins.findIndex(item => output.includes(item));

    if (findOutputIndex > -1) {
      notFindOutputPins.splice(findOutputIndex, 1);
    }

    let input = find ? find.input || [] : [];
    let sense = find ? find.sense || [] : [];
    let gndSense = find ? find.gndSense || [] : [];
    const ground = find ? find.ground || [] : [];
    const outputGround = find ? find.outputGround || [] : [];
    const inputGround = find ? find.inputGround || [] : [];
    //re auto assign sense pins by output pins
    if (find && !_.isEqual(output, (find.output || [])) && output.length < (find.output || []).length) {
      const { pwrSensePins, gndSensePins, inputPins } = groupSensePinByPinName({ output, sense, gndSense, input });
      sense = pwrSensePins;
      gndSense = gndSensePins;
      input = inputPins;
    }
    //delete exist sense pins
    updateNotKnowSensePins({ sense, gndSense, input, notKnowPwrSensePins, notKnowGndSensePins, notKnowInputPins });
    _pinTable.push({ index, output, input, sense, gndSense, ground, outputGround, inputGround });
  })
  //add filter sense pins
  if (Object.keys(notKnowPwrSensePins).length || Object.keys(notKnowGndSensePins).length || Object.keys(notKnowInputPins).length) {
    _pinTable = addFilterSensePins({
      notKnowPwrSensePins,
      notKnowGndSensePins,
      notKnowInputPins,
      _pinTable,
      type
    })
  }
  if (notFindOutputPins.length) {
    //add curr design not find output pins to new pin table
    _pinTable = addNotFinsOutputPinsToPinTable({ notFindOutputPins, type, pinTable, _pinTable });
  }

  _pinTable = type === "obj" ?
    _pinTable.sort((a, b) => a.output[0] && b.output[0] && a.output[0].pinName > b.output[0].pinName ? 1 : -1).map((item, index) => ({ ...item, index }))
    : _pinTable.sort((a, b) => a.output[0] > b.output[0] ? 1 : -1).map((item, index) => ({ ...item, index }));
  return _pinTable;
}

function addNotFinsOutputPinsToPinTable({ notFindOutputPins, type, pinTable, _pinTable }) {
  for (let pinItem of notFindOutputPins) {

    const prevFind = type === "obj"
      ? pinTable.find(item => !!item.output.find(it =>
        (it.pin === pinItem.pin || it.pinName === pinItem.pinName)
        && (!it.comp || it.comp === pinItem.comp)))
      : pinTable.find(item => item.output.includes(pinItem));

    if (!prevFind) {
      continue;
    }

    const index = type === "obj"
      ? _pinTable.findIndex(item => item.output.some(it => !!prevFind.output.find(outputItem =>
        (outputItem.pin === it.pin || outputItem.pinName === it.pinName)
        && (!outputItem.comp || outputItem.comp === it.comp)
      )))
      : _pinTable.findIndex(item => item.output.some(it => prevFind.output.includes(it)));

    if (index > -1) {
      _pinTable[index].output.push(pinItem);
    } else {
      _pinTable.push({
        output: [pinItem],
        input: prevFind.input || [],
        sense: prevFind.sense || [],
        gndSense: prevFind.gndSense || [],
        ground: prevFind.ground || [],
        outputGround: prevFind.outputGround || [],
        inputGround: prevFind.inputGround || []
      })
    }
  }
  return _pinTable;
}

function getAllSensePins(pinTable) {
  let notKnowPwrSensePins = {}, notKnowGndSensePins = {}, notKnowInputPins = {};
  for (let item of pinTable) {
    for (let senseItem of (item.sense || [])) {
      const pin = typeof senseItem === "object" ? `${senseItem.comp ? `${senseItem.comp}_` : ""}${senseItem.pin}::${senseItem.pinName}` : senseItem;
      notKnowPwrSensePins[pin] = item.output ? [...item.output] : [];
    }
    for (let senseItem of (item.gndSense || [])) {
      const pin = typeof senseItem === "object" ? `${senseItem.comp ? `${senseItem.comp}_` : ""}${senseItem.pin}::${senseItem.pinName}` : senseItem;
      notKnowGndSensePins[pin] = item.output ? [...item.output] : [];
    }
    for (let pinItem of (item.input || [])) {
      const pin = typeof pinItem === "object" ? `${pinItem.comp ? `${pinItem.comp}_` : ""}${pinItem.pin}::${pinItem.pinName}` : pinItem;
      notKnowInputPins[pin] = item.output ? [...item.output] : [];
    }
  }
  return { notKnowPwrSensePins, notKnowGndSensePins, notKnowInputPins }
}

function groupSensePinByPinName({ output, sense, gndSense, input }) {
  //output pins
  const outNumberList = output.map(item => {
    return {
      pinName: item,
      numList: getIntegersInStr(typeof item === "object" ? item.pinName : item)
    }
  });
  //pwr sense pins
  const pwrSenseNumberList = sense.map(item => {
    return {
      pinName: item,
      numList: getIntegersInStr(typeof item === "object" ? item.pinName : item)
    }
  });
  //gnd sense pins
  const gndSenseNumberList = gndSense.map(item => {
    return {
      pinName: item,
      numList: getIntegersInStr(typeof item === "object" ? item.pinName : item)
    }
  });
  //input pins
  const inputNumberList = input.map(item => {
    return {
      pinName: item,
      numList: getIntegersInStr(typeof item === "object" ? item.pinName : item)
    }
  });

  let pwrSensePins = [], gndSensePins = [], inputPins = [];
  const sameNumberList = getOutputPinSameNumber(outNumberList);
  const sameNumberIndexList = getOutputPinSameNumber(outNumberList, true);
  pwrSensePins = pwrSenseNumberList.filter(item => !!item.numList.find((it, index) => (item.numList.length === 1 || sameNumberIndexList.includes(index)) && sameNumberList.includes(it))).map(item => item.pinName);
  gndSensePins = gndSenseNumberList.filter(item => !!item.numList.find((it, index) => (item.numList.length === 1 || sameNumberIndexList.includes(index)) && sameNumberList.includes(it))).map(item => item.pinName);
  inputPins = inputNumberList.filter(item => !!item.numList.find((it, index) => (item.numList.length === 1 || sameNumberIndexList.includes(index)) && sameNumberList.includes(it))).map(item => item.pinName);

  if (!pwrSensePins.length) {
    pwrSensePins = [...sense];
  }
  if (!gndSensePins.length) {
    gndSensePins = [...gndSense];
  }
  if (!inputPins.length) {
    inputPins = [...input];
  }

  return { pwrSensePins, gndSensePins, inputPins }
}

function updateNotKnowSensePins({ sense, gndSense, input, notKnowPwrSensePins, notKnowGndSensePins, notKnowInputPins }) {
  const deletePin = (list, obj) => {
    const pinKeys = Object.keys(obj);
    for (let item of list) {
      if (typeof item === "object") {
        const compName = `${item.comp ? `${item.comp}_` : ""}`;
        const pinName = `${compName}${item.pin}::${item.pinName}`;
        const deletePinKeys = pinKeys.filter(item =>
          item.match(pinName)
          || item.match(`${compName}${item.pin}::`)
          || (item.match(`${compName}`) && item.match(`::${item.pinName}`)))
        deletePinKeys.forEach(key => {
          delete obj[key];
        })
      } else {
        delete obj[item];
      }
    }
  }

  deletePin(sense, notKnowPwrSensePins);
  deletePin(gndSense, notKnowGndSensePins);
  deletePin(input, notKnowInputPins);
}

function addFilterSensePins({
  notKnowPwrSensePins,
  notKnowGndSensePins,
  notKnowInputPins,
  _pinTable,
  type }) {
  const pwrSensePins = Object.keys(notKnowPwrSensePins).map(item => {
    return {
      sense: item,//`comp_pin::pinName` / `comp_pinName`
      output: notKnowPwrSensePins[item] || []
    }
  });
  const gndSensePins = Object.keys(notKnowGndSensePins).map(item => {
    return {
      gndSense: item,//`comp_pin::pinName` / `comp_pinName`
      output: notKnowGndSensePins[item] || []
    }
  });
  const inputPins = Object.keys(notKnowInputPins).map(item => {
    return {
      input: item,//`comp_pin::pinName` / `comp_pinName`
      output: notKnowInputPins[item] || []
    }
  })

  for (let pinItem of _pinTable) {
    const findPwrSensePins = pwrSensePins.filter(item => !!item.output.find(it =>
      typeof it === "object"
        ? pinItem.output.find(pin => (pin.pin === it.pin || pin.pinName === it.pinName) || (!pin.comp || pin.comp === it.comp))
        : pinItem.output.includes(it)));

    if (findPwrSensePins.length) {
      if (type === "obj") {
        const sensePins = findPwrSensePins.map(item => item.sense);
        for (let _pinItem of sensePins) {
          //_pinItem -> `comp_pin_pinName`
          const [compPin, pinName] = strDelimited(_pinItem, "::");
          if (!pinItem.sense.find(item =>
            `${item.comp ? `${item.comp}_` : ""}${item.pin}` === compPin
            || pinName === item.pinName
          )) {
            pinItem.sense.push({ ..._pinItem })
          }
        }
      } else {
        pinItem.sense = [...new Set([...pinItem.sense, ...findPwrSensePins.map(item => item.sense)])]
      }
    }

    const findGndSensePins = gndSensePins.filter(item => !!item.output.find(it =>
      typeof it === "object"
        ? pinItem.output.find(pin => (pin.pin === it.pin || pin.pinName === it.pinName) || (!pin.comp || pin.comp === it.comp))
        : pinItem.output.includes(it)));

    if (findGndSensePins.length) {
      if (type === "obj") {
        const gndSensePins = findGndSensePins.map(item => item.gndSense);
        for (let _pinItem of gndSensePins) {
          //_pinItem -> `comp_pin_pinName`
          const [compPin, pinName] = strDelimited(_pinItem, "::");
          if (!pinItem.gndSense.find(item =>
            `${item.comp ? `${item.comp}_` : ""}${item.pin}` === compPin
            || pinName === item.pinName)) {
            pinItem.gndSense.push({ ..._pinItem })
          }
        }
      } else {
        pinItem.gndSense = [...new Set([...pinItem.gndSense, ...findGndSensePins.map(item => item.gndSense)])]
      }
    }

    const findInputPins = inputPins.filter(item =>
      !!item.output.find(it =>
        typeof it === "object"
          ? pinItem.output.find(pin => (pin.pin === it.pin || pin.pinName === it.pinName) || (!pin.comp || pin.comp === it.comp))
          : pinItem.output.includes(it)));

    if (findInputPins.length) {
      if (type === "obj") {
        const inputPins = findInputPins.map(item => item.input);
        for (let _pinItem of inputPins) {
          //_pinItem -> `comp_pin_pinName`
          const [compPin, pinName] = strDelimited(_pinItem, "::");
          if (!pinItem.input.find(item => `${item.comp ? `${item.comp}_` : ""}${item.pin}` === compPin
            || pinName === item.pinName)) {
            pinItem.input.push({ ..._pinItem })
          }
        }
      } else {
        pinItem.input = [...new Set([...pinItem.input, ...findInputPins.map(item => item.input)])];
      }
    }
  }
  return _pinTable;
}

function getOutputPinSameNumber(outNumberList, returnIndexList, returnAll = false) {
  let sameNumberList = [], sameNumberIndexList = [], sameNumberStrList = [];
  if (!outNumberList.length) {
    return [];
  }
  const maxNumberList = outNumberList.sort((a, b) => b.numList.length - a.numList.length);

  for (let i = 0; i < maxNumberList[0].numList.length; i++) {
    const allNumberList = maxNumberList.map(item => item.numList[i]);

    if ([...new Set(allNumberList)].length === 1) {
      sameNumberList.push(allNumberList[0]);
      sameNumberIndexList.push(i);
      if (returnAll) {
        sameNumberStrList.push(...maxNumberList[0].numStrList.map(it => it.str));
      }
    }
  }
  sameNumberStrList = [...new Set(sameNumberStrList)];
  if (returnAll) {
    return { sameNumberIndexList, sameNumberList, sameNumberStrList }
  }
  return returnIndexList ? sameNumberIndexList : sameNumberList;
}

function AddPinNumberToPinMap({ pinStore, pinTable }) {
  /* {
    gndSense: ['_RMT_GND_S1_'],
    ground: [],
    index: 0,
    input: [],
    inputGround: [],
    output: ['_VSW_S1_1_', '_VSW_S1_2_'],
    outputGround: [],
    sense: ['_VREG_S1_']
  } */
  let _pinTable = JSON.parse(JSON.stringify(pinTable));
  const pinComps = pinStore.map(item => item.comp).filter(item => !!item);
  for (let pinItem of _pinTable) {
    pinItem.output = AddPinNumberToPinList(pinItem.output, pinStore, pinComps);
    pinItem.input = AddPinNumberToPinList(pinItem.input, pinStore, pinComps);
    pinItem.sense = AddPinNumberToPinList(pinItem.sense, pinStore, pinComps);
    pinItem.gndSense = AddPinNumberToPinList(pinItem.gndSense, pinStore, pinComps);
    pinItem.outputGround = AddPinNumberToPinList(pinItem.outputGround, pinStore, pinComps);
    pinItem.inputGround = AddPinNumberToPinList(pinItem.inputGround, pinStore, pinComps);
    pinItem.ground = AddPinNumberToPinList(pinItem.ground, pinStore, pinComps);
  }
  return _pinTable;
}

function AddPinNumberToPinList(pinList, pinStore, pinComps) {
  pinList = (pinList || []).map(item => {
    let pinName = item, compName = "";
    if (pinComps && pinComps.length) {
      const pinInfo = strDelimited(item, "_");
      compName = pinInfo[0];
      pinName = pinInfo.filter((it, index) => index > 0).join("");
    }
    const findPin = pinStore.find(it => `${it.comp ? `${it.comp}_` : ""}${it.pinName}` === pinName);
    if (findPin) {
      return { pin: findPin.pin, pinName, comp: findPin.comp };
    } else {
      const matchPin = pinStore.find(it => (!it.comp || it.comp === compName) &&
        ((typeof (it.pinName) === "string" && it.pinName.match(pinName)) || (typeof (item) === "string" && item.match(it.pinName))));
      return matchPin ? { pin: matchPin.pin, pinName, comp: matchPin.comp } : { pin: "", pinName: pinName, comp: compName };
    }
  })
  return pinList;
}

function pinMapDataUpdateByDesign(pinList, pinStore) {
  let _pinList = JSON.parse(JSON.stringify(pinList));
  _pinList.forEach(item => {
    item.output = getPinMapPinName(item.output, pinStore);
    item.input = getPinMapPinName(item.input, pinStore);
    item.sense = getPinMapPinName(item.sense, pinStore);
    item.gndSense = getPinMapPinName(item.gndSense, pinStore);
    item.outputGround = getPinMapPinName(item.outputGround, pinStore);
    item.inputGround = getPinMapPinName(item.inputGround, pinStore);
    item.ground = getPinMapPinName(item.ground, pinStore);
  })
  return _pinList;
}

function getPinMapPinName(pinList, pinStore) {
  pinList = pinList.map(pinItem => {
    const findPin = pinStore.find(it =>
      (it.pinName === pinItem.pinName || it.pin === pinItem.pin)
      && (!it.comp || it.comp === pinItem.comp));
    if (findPin) {
      return findPin.comp ? `${findPin.comp}_${findPin.pinName}` : findPin.pinName;
    } else {
      const matchPin = pinStore.find(it => it.pinName.match(pinItem.pinName) || pinItem.pinName.match(it.pinName));
      return matchPin ? `${matchPin.comp ? `${matchPin.comp}_` : ""}${matchPin.pinName}` : `${pinItem.comp ? `${pinItem.comp}_` : ""}${pinItem.pinName}`;
    }
  })
  return pinList;
}

function getPinMapTableData(pinTable, pinStore, libraryShow = false) {
  let _pinTable = JSON.parse(JSON.stringify(pinTable));
  for (let pinItem of _pinTable) {
    pinItem.output = getPinInfo(pinItem.output, pinStore, libraryShow);
    pinItem.input = getPinInfo(pinItem.input, pinStore, libraryShow);
    pinItem.sense = getPinInfo(pinItem.sense, pinStore, libraryShow);
    pinItem.gndSense = getPinInfo(pinItem.gndSense, pinStore, libraryShow);
    pinItem.outputGround = getPinInfo(pinItem.outputGround, pinStore, libraryShow);
    pinItem.inputGround = getPinInfo(pinItem.inputGround, pinStore, libraryShow);
    pinItem.ground = getPinInfo(pinItem.ground, pinStore, libraryShow);
  }
  return _pinTable;
}

function getPinInfo(pinDataList, pinStore, libraryShow) {
  for (let itemData of (pinDataList || [])) {
    const findPin = pinStore.find(item =>
      (item.pin === itemData.pin || item.pinName === itemData.pinName)
      && (!item.comp || item.comp === itemData.comp)
    );
    if (findPin) {
      itemData.currPin = findPin.pin;
      itemData.currComp = findPin.comp;
      itemData.currPinName = findPin.pinName;
    } else if (libraryShow) {
      itemData.currPinName = itemData.pinName;
      itemData.currComp = itemData.comp;
    }
  }

  return pinDataList || [];
}

function updatePinList(prevPinList, pins) {
  const notFindPins = prevPinList.filter(item => !item.currPin);
  const findPins = prevPinList.filter(item => !!item.currPin);
  pins = pins.map(item => {
    const findPin = findPins.find(it =>
      (it.currPin === item.pin || it.currPinName === item.pinName)
      && (!it.currComp || it.currComp === item.comp)
    );
    if (findPin) {
      return {
        pin: findPin.pin,
        pinName: findPin.pinName,
        comp: findPin.comp,
        currPin: item.pin,
        currPinName: item.pinName,
        currComp: item.comp,
      }
    } else {
      const _findPinIndex = notFindPins.find(it =>
        (it.pin === item.pin || it.pinName === item.pinName)
        && (!it.comp || it.comp === item.comp)
      );
      const _findPin = _findPinIndex > -1 ? { ...notFindPins[_findPinIndex] } : null;
      notFindPins.splice(_findPinIndex, 1);
      return _findPin ? {
        pin: _findPin.pin,
        pinName: _findPin.pinName,
        comp: _findPin.comp,
        currPin: item.pin,
        currPinName: item.pinName,
        currComp: item.comp,
      } : {
        pin: item.pin,
        pinName: item.pinName,
        comp: item.comp,
        currPin: item.pin,
        currPinName: item.pinName,
        currComp: item.comp,
      }
    }
  })
  prevPinList = [...pins, ...notFindPins];
  return prevPinList;
}

function updateOutputPins(newPinTable, pinTable) {
  const prevNotFindOutputPinTable = pinTable.filter(item => !!item.output.find(it => !it.currPin));
  const prevNotFindOutputPins = prevNotFindOutputPinTable.map(item => item.output).flat(2);
  for (let outputItem of prevNotFindOutputPins) {
    //outputItem :{pin, pinName}
    //find exist
    const index = newPinTable.findIndex(pinItem => !!pinItem.output.find(it =>
      (outputItem.pin === it.pin || outputItem.pinName === it.pinName)
      && (!it.comp || outputItem.comp === it.comp)
    ))
    if (index > -1) {
      continue;
    }
    const prevIndex = pinTable.findIndex(item => !!item.output.some(it =>
      (outputItem.pin === it.pin || outputItem.pinName === it.pinName)
      && (!it.comp || outputItem.comp === it.comp)
    ));
    if (prevIndex < -1) {
      continue;
    }
    const prevPinObj = pinTable[prevIndex];
    const newIndex = newPinTable.findIndex(pinItem => !!pinItem.output.some(item =>
      !!prevPinObj.output.find(it =>
        (it.pin === item.pin || it.pinName === item.pinName)
        && (!it.comp || item.comp === it.comp)
      )));

    if (newIndex > -1) {
      newPinTable[newIndex].output.push({ ...outputItem });
    } else {
      newPinTable.push({
        index: newPinTable.length + 1,
        output: [{ ...outputItem }],
        input: prevPinObj && prevPinObj.input ? [...prevPinObj.input] : [],
        sense: prevPinObj && prevPinObj.sense ? [...prevPinObj.sense] : [],
        gndSense: prevPinObj && prevPinObj.gndSense ? [...prevPinObj.gndSense] : [],
        ground: prevPinObj && prevPinObj.ground ? [...prevPinObj.ground] : [],
        outputGround: prevPinObj && prevPinObj.outputGround ? [...prevPinObj.outputGround] : [],
        inputGround: prevPinObj && prevPinObj.inputGround ? [...prevPinObj.inputGround] : [],
      })
    }
  }
  return newPinTable;
}

function deleteCurrPinInfo(pinTable) {
  const deletePinFn = (pinList) => {
    pinList.forEach(item => {
      delete item.currPin;
      delete item.currPinName;
      delete item.currComp;
    })
    return pinList;
  }
  pinTable.forEach(pinItem => {
    pinItem.output = deletePinFn(pinItem.output || []);
    pinItem.input = deletePinFn(pinItem.input || []);
    pinItem.sense = deletePinFn(pinItem.sense || []);
    pinItem.gndSense = deletePinFn(pinItem.gndSense || []);
    pinItem.outputGround = deletePinFn(pinItem.outputGround || []);
    pinItem.inputGround = deletePinFn(pinItem.inputGround || []);
    pinItem.ground = deletePinFn(pinItem.ground || []);
  });
  return pinTable;
}

function findSensePinByOutput(output, pinGroups) {
  let sensePins = [];
  //find output pins number 
  const { sameNumberList, sameNumberIndexList, sameNumberStrList } = getOutPutPinsInfo(output);
  //filter and find out pwr sense pins -> VREG / FB
  const findSense = pinGroups.filter(item =>
    !item.pinName.match(/(GND)|(SW)|(VSS)|(VSW)|(OUT)|(IN)/ig) && item.pinName.match(/(VREG)|(FB)/ig));
  if (findSense.length) {
    //Find sense pins include number
    const senseNumberList = findSense.map(item => {
      return {
        pin: item,
        numList: getIntegersInStr(item.pinName)
      }
    });
    //find out sense pin by output pin same number
    let pwrSensePins = senseNumberList.filter(item =>
      !!item.numList.find((it, index) =>
        (item.numList.length === 1 || sameNumberIndexList.includes(index))
        && sameNumberList.includes(it))).map(item => item.pin);

    if (pwrSensePins.length > 1) {
      //find out sense pin by same str and number
      const _pwrSensePins = pwrSensePins.filter(item => !!sameNumberStrList.find(it => item.pinName.match(it)));
      if (_pwrSensePins.length > 0) {
        pwrSensePins = _pwrSensePins
      }
    }
    for (let item of pwrSensePins) {
      const findPin = sensePins.find(it => (it.pin === item.pin) && (!it.comp || it.comp === item.comp));
      if (!findPin) {
        const senseInfo = { pin: item.pin, pinName: item.pinName, currPin: item.pin, currPinName: item.pinName }
        if (item.comp) {
          senseInfo.comp = item.comp;
          senseInfo.currComp = item.comp;
        }
        sensePins.push(senseInfo);
      }
    }
  }

  return sensePins;
}

function getOutPutPinsInfo(output) {
  const outNumberList = output.map(item => {
    return {
      pin: item,
      numList: getIntegersInStr(item.pinName),
      numStrList: []
    }
  });
  for (let item of outNumberList) {
    const pinNameList = item.pin.pinName.split("");
    for (let num of item.numList) {
      const index = item.pin.pinName.indexOf(`${num}`);
      if (index > 0) {
        item.numStrList.push({ num, str: `${pinNameList[index - 1]}${num}` });
      }
    }
  }
  const { sameNumberList, sameNumberIndexList, sameNumberStrList } = getOutputPinSameNumber(outNumberList, true, true);
  return { sameNumberList, sameNumberIndexList, sameNumberStrList }
}


function findGndSensePinByOutput(output, pinGroups) {
  let gndSensePins = [];
  //find output pins number 
  const { sameNumberList, sameNumberIndexList, sameNumberStrList } = getOutPutPinsInfo(output);
   //filter and find out gnd sense pins -> RMT
  const findSense = pinGroups.filter(item => !item.pinName.match(/(VREG)|(FB)|(REG)|(SW)|(VSS)|(VSW)|(OUT)|(IN)/ig) && item.pinName.match(/(RMT)/ig));
  if (findSense.length) {
    const senseNumberList = findSense.map(item => {
      return {
        pin: item,
        numList: getIntegersInStr(item.pinName)
      }
    });
    //find out sense pin by output pin same number
    let findGndSensePins = senseNumberList.filter(item =>
      !!item.numList.find((it, index) =>
        (item.numList.length === 1 || sameNumberIndexList.includes(index))
        && sameNumberList.includes(it))).map(item => item.pin);

    if (findGndSensePins.length > 1) {
      //find out sense pin by same str and number
      const _findGndSensePins = findGndSensePins.filter(item => !!sameNumberStrList.find(it => item.pinName.match(it)));
      if (_findGndSensePins.length > 0) {
        findGndSensePins = _findGndSensePins
      }
    }

    for (let item of findGndSensePins) {
      const findPin = gndSensePins.find(it => (it.pin === item.pin) && (!it.comp || it.comp === item.comp));
      if (!findPin) {
        const senseInfo = { pin: item.pin, pinName: item.pinName, currPin: item.pin, currPinName: item.pinName }
        if (item.comp) {
          senseInfo.comp = item.comp;
          senseInfo.currComp = item.comp;
        }
        gndSensePins.push(senseInfo);
      }
    }
  }

  return gndSensePins;
}

async function checkConnectComp(name, pins, designId) {
  if (!auroraDBJson.checkAuroraJson(designId)) {
    const COMP_PREFIX_LIB = await componentSetting.getPrefixLib(designId);
    await auroraDBJson.getAuroraJson(designId, { compPrefixLib: COMP_PREFIX_LIB })
  }
  return pins.map(pin => {
    const comp = auroraDBJson.getComponentsByNets(designId, [pin.net]).filter(item => item.name !== name)
    let nextComp = comp.find(item => item.type === IND);

    if (nextComp) {
      nextComp = auroraDBJson.getComponent(designId, nextComp.name)
      const nextPin = [...nextComp.pins.values()].find(p => p.net !== pin.net);

      if (nextPin) {
        //find connector comp by netNet
        const _nextComps = auroraDBJson.getComponentsByNets(designId, [nextPin.net]).filter(item => item.name !== name && item.name !== nextComp.name);
        // filter: the PMIC output inductor is array inductor or coupling inductor
        if (_nextComps.length) {
          return { ...pin, nextComp: nextComp.name, nextNet: nextPin.net }
        }
      }
    }
    return pin
  }).sort((a, b) => a.pin - b.pin > 0 ? -1 : 1)
}

function getInputPinNameFrag(out) {
  if (out.includes('OUT')) {
    return out.replace('OUT', 'IN');
  } else {
    return out.replace(/^[A-Z]*_|_[0-9]*$/g, '');
  }
}

function findInputPinByOutput(output, inputGroups, returnType) {
  let input = [];
  for (let outItem of output) {
    let out = typeof outItem === "object" ? outItem.pinName : outItem;
    const inputConst = getInputPinNameFrag((out || "").toUpperCase());
    if (inputConst.length < 3 || !inputConst.match(/[a-zA-Z]/ig)) {
      continue
    }
    const matchConst = new RegExp(`${inputConst}(\\D|$)`, 'g');
    const findInput = inputGroups.filter(item => !item.pinName.match(/(GND)|(REG)|(SW)|(FB)|(VSS)/ig) && item.pinName.match(matchConst));
    if (findInput.length) {
      if (returnType === "pinName") {
        input.push(...findInput.map(item => `${item.comp ? `${item.comp}_` : ""}${item.pinName}`));
        input = [...new Set(input)];
      } else {
        for (let item of findInput) {
          const findPin = input.find(it => (it.pin === item.pin) && (!it.comp || it.comp === item.comp));
          if (!findPin) {
            const inputInfo = { pin: item.pin, pinName: item.pinName, currPin: item.pin, currPinName: item.pinName };
            if (item.comp) {
              inputInfo.comp = item.comp;
              inputInfo.currComp = item.comp;
            }
            input.push(inputInfo);
          }
        }
      }
    }
  }
  return input;
}

export {
  groupPinMapPinsByOutPutPinNet,
  AddPinNumberToPinMap,
  pinMapDataUpdateByDesign,
  getPinMapTableData,
  updatePinList,
  updateOutputPins,
  deleteCurrPinInfo,
  findSensePinByOutput,
  findGndSensePinByOutput,
  checkConnectComp,
  findInputPinByOutput
}