import NP from 'number-precision';
import { numberCheck, unitConversion } from "./dataProcess";
import { scaleConversion } from './numberHelper';
import { scaleKeys } from './constants';

function getErrorMsg(index, type) {
  const ErrorMsg = [
    "value cannot be empty.",//0
    "value must be a number.",//1
    "value cannot be less than 0.",//2
    "Clock cannot be greater than 1000GHz.",//3
    `${type === 'timeStep' ? 'Time Step' : 'CA Time Step'} cannot be less than 0.01ps.`,//4
    "Cycles cannot be greater than 10000.",//5
    "The value of Maximum Delay For Lumped Model should be integer in [0, 100] ps", //6
    "CA clock cannot be greate than 800MHz", //7
    "HspiceCores value cannot be less than 1.",//8
    "Number of Bits should be a positive integer."//9
  ]
  if (index < 3 && type) {
    let types = type.split('');
    const errorType = `${types[0].toUpperCase()}${types.filter((item, index) => index > 0).join('')}`;
    return `${errorType} ${ErrorMsg[index]}`;
  } else {
    return ErrorMsg[index];
  }
}


const HzUnits = ['clock', 'caClock'],
  sUnits = ['timeStep', 'caTimeStep'];

function simulationValueCheckIndex({ type, value, unit }) {

  if (!value) {
    return 0;
  }

  let notNum = numberCheck(value);
  if (notNum) {
    return 1;
  }

  if (parseFloat(value) <= 0 && type === 'hspiceCores') {
    return 8;
  };

  if (parseFloat(value) <= 0 && type !== 'ngspiceMDFLM') {
    return 2;
  };

  let errorIndex = -1;
  let _value = value;
  if (HzUnits.includes(type) && unit) {
    const scale = scaleConversion("Hz", unit);
    _value = NP.times(parseFloat(_value), scale);
  }

  if (sUnits.includes(type) && unit) {
    const preUnit = unit.split("");//ns ==> n
    const scale = unitConversion(-4, scaleKeys[preUnit[0]]);// -4 == p
    _value = NP.times(parseFloat(_value), scale);
  }
  switch (type) {
    case 'clock':
      if (parseFloat(_value) > 1e12) {
        errorIndex = 3;
      };
      break;
    case 'timeStep':
    case 'caTimeStep':
      if (parseFloat(_value) < 0.01) {
        errorIndex = 4;
      };
      break;
    case 'cycles':
      if (parseFloat(_value) > 10000) {
        errorIndex = 5;
      };
      break;
    case 'ngspiceMDFLM':
      if (_value.indexOf('.') >= 0 || parseInt(_value) > 100 || parseInt(_value) < 0) {
        errorIndex = 6;
      }
      break;
    case 'caClock':
      /* if (parseFloat(_value) > 8e8) {
        errorIndex = 7;
      }; */
      break;
    case 'numberBits':
      if (parseInt(_value) <= 0 || _value.indexOf('.') >= 0) {
        errorIndex = 9;
      }
      break;
    default: errorIndex = -1; break;
  };

  return errorIndex;
}

function simulationValueCheck(type, value, unit) {
  const index = simulationValueCheckIndex({ type, value, unit });
  if (index > -1) {
    return getErrorMsg(index, type);
  } else {
    return null;
  }
}

const simulationConfigList = ['clock', 'timeStep', 'cycles', 'caClock', 'caTimeStep'];
function simulationErrorCheck(config) {
  let error = null;
  const simulationList = config && config.simulate === 'Default' ? [...simulationConfigList, 'ngspiceMDFLM'] : [...simulationConfigList];
  for (const item of simulationList) {
    if ((item === 'caClock' && !config.caClock) || (item === 'caTimeStep' && !config.caTimeStep)) {
      continue;
    }
    const unit = config[item] && config[item].unit ? config[item].unit : null;
    const value = item === 'cycles' ? config[item] : item === 'ngspiceMDFLM' ? config[item].replace('ps', '') : config[item].value;
    const _check = simulationValueCheck(item, value, unit);
    if (_check) {
      error = { type: item, error: _check }
      break;
    }
  }
  return error;
}

export { simulationValueCheck, simulationValueCheckIndex, simulationErrorCheck, simulationConfigList };