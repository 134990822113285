const HIMALAYAS = "HIMALAYAS_PROJECT";
export const UPDATE_PROJECT_MENU = `${HIMALAYAS}/update_project_menu`;
export const UPDATE_PAGE_LAYOUT = `${HIMALAYAS}/update_page_layout`;
export const UPDATE_PCB_LAYOUT = `${HIMALAYAS}/update_pcb_layout`;
export const UPDATE_EXPAND = `${HIMALAYAS}/update_expand`;
export const UPDATE_SELECTED_KEYS = `${HIMALAYAS}/update_selected_keys`;
export const UPDATE_TREE_LIST = `${HIMALAYAS}/update_tree_list`;
export const ADD_PROJECT = `${HIMALAYAS}/add_project`;
export const CREATE_PROJECT = `${HIMALAYAS}/create_project`;
export const CANCEL_CREATE_PROJECT = `${HIMALAYAS}/cancel_create_project`;
export const OPEN_PROJECT = `${HIMALAYAS}/open_project`;
export const CLOSE_PROJECT = `${HIMALAYAS}/close_project`;
export const UPDATE_OPEN_PROJECT_INFO = `${HIMALAYAS}/update_opened_project_info`;
export const CLEAR_PROJECT_INFO = `${HIMALAYAS}/clear_project_info`;
export const UPDATE_VIEW_LIST = `${HIMALAYAS}/update_view_list`;
export const DELETE_PROJECT = `${HIMALAYAS}/delete_project`;
export const OPEN_PAGE = `${HIMALAYAS}/open_page`;
export const RENAME_CHANNEL = `${HIMALAYAS}/rename_channel`;
export const RENAME_PROJECT = `${HIMALAYAS}/rename_project`;
export const CLEAN_TRASH = `${HIMALAYAS}/clean_trash`;
export const UPDATE_TRASH = `${HIMALAYAS}/update_trash`;
/* service options */
export const UPDATE_SERVICE_CONFIG = `${HIMALAYAS}/update_service_config`;
export const SAVE_SERVICE_OPTION = `${HIMALAYAS}/save_service_option`;

/* REPORT */
export const UPDATE_REPORT_INFO = `${HIMALAYAS}/update_report_info`;
export const CLEAR_REPORT_INFO = `${HIMALAYAS}/clear_report_info`;
export const SAVE_REPORT_CONFIG = `${HIMALAYAS}/save_report_config`;
export const GET_PROJECT_REPORT = `${HIMALAYAS}/get_project_report`;