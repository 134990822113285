import http from "./http";

export function uploadChunk(formData) {
  return http.post('/chunk/file/upload', formData)
}

export function getUploadStatus(id) {
  return http.get(`/chunk/file/verify/${id}`)
}

// export function deleteBeforeShard(id) {
//   return http.delete()
// }