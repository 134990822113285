import React, { PureComponent } from 'react'
import TopMenu from './TopMenu'
import { connect } from 'react-redux'
import { changeResultType, getDCValue } from '../store/result/action'
import SparameterResult from '../Sparameter';
import DecapResult from './decap';
import DCContent from './DC'
import './index.css'

class Result extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    const { verificationId, resultKey } = this.props;
    this.props._getDCValue(verificationId, resultKey === 'dc' ? true : false)
  }

  componentDidUpdate(prevProps) {
    const { verificationId, resultKey } = this.props;
    if (this.props.verificationId !== prevProps.verificationId) {
      this.props._getDCValue(verificationId, resultKey === 'dc' ? true : false)
    }
  }

  getResultContent = () => {
    const { resultKey, verificationId, currentProjectId, PDNID, existResult, layoutStatus } = this.props
    switch (resultKey) {
      case 'channel':
        return <SparameterResult
          verificationId={verificationId}
          projectId={currentProjectId}
          pdnId={PDNID}
          existResult={existResult}
          layoutStatus={layoutStatus}
        />
      case 'decap':
        return <DecapResult
          projectId={currentProjectId}
          pdnId={PDNID}
          verificationId={verificationId}
        />
      case 'dc':
        return <DCContent
          projectId={currentProjectId}
          pdnId={PDNID}
          verificationId={verificationId} />
      default:
        return null
    }
  }

  render() {
    const { changeResultType, resultKey, showDC, leftWidth, layoutLeftWidth } = this.props
    return (
      <div className="pdn-result-content" style={{ height: '100%', position: 'relative', overflow: 'auto' }}>
        {TopMenu({
          selectedKey: resultKey,
          changeResultType,
          showDC,
          leftWidth,
          layoutLeftWidth
        })}
        {this.getResultContent()}
      </div>
    )
  }
}

const mapState = (state) => {
  const { resultReducer: { resultKey, showDC }, simulationReducer: { existResult }, project: { verificationId, currentProjectId, PDNID } } = state.PDNReducer
  return {
    resultKey,
    existResult,
    verificationId,
    currentProjectId,
    PDNID,
    showDC
  }
}
const mapDispatch = (dispatch) => ({
  changeResultType(key) {
    dispatch(changeResultType(key))
  },
  _getDCValue(verificationId, showFile) {
    dispatch(getDCValue(verificationId, showFile))
  }
})

export default connect(mapState, mapDispatch)(Result)