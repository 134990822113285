import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import getIcon from '../../../services/helper/iconHelper';
import '../TopBar/index.css';

class TopBar extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById(`andes-v2-pre-layout-main-${props.designID}`);
  }

  componentDidMount = () => {
    this.dialogRoot = document.getElementById(`andes-v2-pre-layout-main-${this.props.designID}`);
    this.setState({
      refresh: true
    })
  }

  getItem = ({ name, type, visible, className, iconType }) => {
    const Icon = getIcon(iconType || type)
    return (
      <div className={`andes-v2-top-bar-item ${className}`}
        style={visible ? { background: '#c7e0f4' } : {}}
        onClick={() => { this.props.openPanel(type, true) }}
        title={name}
      >
        {Icon && <Icon
          className='andes-v2-top-bar-setting-icon'
          title={name}
        />}
        <span className="andes-v2-top-bar-item-title">{name}</span>
      </div>
    );
  }

  render() {
    if (!this.dialogRoot) return null;
    const { helpVisible, settingVisible, modelVisible, modelDisPlay } = this.props;
    const content = (
      <div id='andes-v2-prelayout-top-bar'>
        {this.getItem({
          name: 'Setting',
          type: 'setting',
          visible: settingVisible
        })}
        {modelDisPlay && this.getItem({
          name: 'Extraction Options',
          type: 'model',
          visible: modelVisible,
          iconType: "setting",
        })}
        {this.getItem({
          name: 'Help',
          type: 'help',
          visible: helpVisible,
          iconType: "question-circle",
          className: "andes-pre-layout-help"
        })}
      </div>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default TopBar;
