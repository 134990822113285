function emailCheck(email) {
  const reg = /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-z-A-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/;
  if (!reg.test(email)) {
    return "Email format is incorrect.";
  }
  return false;
}

export {
  emailCheck
}