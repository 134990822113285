import React, { Component, Fragment } from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Select, TreeSelect } from 'antd';
import Panel from '../Panel';
import { createPortal } from 'react-dom';
import { getPortNumberFromFileSuffix } from '../../services/helper/touchstoneHelper';
import SignalPinsPortSetup from './signalPinsPortSetup';
import CopySignalPinsPortSetup from './copySignalPinsPortSetup';
import {
  getSignalSelectedFile,
  getDisplayPinList,
  updateConnectorModel,
  getSelectedSignalsBySelectFile,
  updateCableModel,
  clearPinsPortByFile,
  clearPinsPortBySignal,
  copyPinsPort,
  copyPinPortsBySignal,
  updateCopyGroupsBySelectFile,
  getModifiedPinsBySignals,
  updateCopyGroupsBySignal,
  getCopyGroups,
  getApplySignals,
  getPinNetWidth,
  autoMatchPort
} from '../../services/helper/connectorHelper';
import { getDefaultIndex } from '../../services/helper/setDefaultName';
import { SPICE, TOUCHSTONE } from '../../constants/libraryConstants';
import { SortFn } from '../../services/helper/sort';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import AutomaticPanel from '@/components/PortMatch';
import { CPHY } from '../../services/PCBHelper/constants';
import './index.css';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
function getTreeData(signals) {
  return [{
    title: 'All',
    value: 'all',
    key: 'all',
    children: signals.map(name => ({ title: name, value: name, key: name }))
  }]
}

class ConnectorModelSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectFile: {},
      selectFile2: null,
      modelInfo: {},
      portNumber: "4",
      selectedSignals: [],
      copySignals: [],
      error: null,
      connector1: {},
      connector2: {},
      pinList: [],
      connectorModels: [],
      copyGroups: []//{ modelKey: "", signalMap: [{ fromSignal: "", toSignal: "" }] }
    }
    this.dialogRoot = document.getElementById('root');
    this.signals = [];
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();

    this.setState({
      maxWidth: getPanelMaxWidth(offset, 770),
      maxHeight: getPanelMaxHeight(offset, 400),
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { modelInfo, signal, portType, connectorModels, connector1, connector2,
      channel1Comps, channel2Comps, signal_connections_map, channel_signal,
      signalType, channel1Info, channel2Info, serdesType, fileList } = this.props;
    let connectorInfo = {
      connector1: JSON.parse(JSON.stringify(connector1)),
      connector2: JSON.parse(JSON.stringify(connector2)),
    };
    //get selected file by open signal pins
    const { selectFile = {}, selectFile2 } = getSignalSelectedFile(modelInfo, portType, connectorModels, fileList, serdesType);
    //generate pin list
    const pinList = getDisplayPinList({
      connector1,
      channel1Comps,
      connector2,
      channel2Comps,
      signal_connections_map,
      channel_signal,
      channel1Info,
      channel2Info,
      connectorFileList: fileList
    });
    //find signals for connector model is selectFile
    const selectedSignals = getSelectedSignalsBySelectFile({ selectFile, selectFile2, pinList, signal, portType, signalType });
    let _selectedSignals = [...new Set([signal, ...selectedSignals])];
    let _connectorModels = JSON.parse(JSON.stringify(connectorModels));
    this.setState({
      selectFile: { ...selectFile },
      selectFile2,
      modelInfo: JSON.parse(JSON.stringify(modelInfo)),
      portNumber: selectFile.portNumber || "4",
      portNumber2: selectFile2 ? selectFile2.portNumber : null,
      selectedSignals: _selectedSignals,
      copySignals: [],
      error: null,
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      pinList,
      connectorModels: _connectorModels,
      copyGroups: []
    });
    this.signals = pinList.map(item => item[`${signalType}`]);
  }

  updatePinList = ({ connector1, connector2, isReturn = false }) => {
    const { channel1Comps, channel2Comps, signal_connections_map, channel_signal, channel1Info, channel2Info, fileList } = this.props;
    const pinList = getDisplayPinList({
      connector1,
      channel1Comps,
      connector2,
      channel2Comps,
      signal_connections_map,
      channel_signal,
      channel1Info,
      channel2Info,
      connectorFileList: fileList
    })
    if (isReturn) {
      return pinList;
    }
    this.setState({
      pinList
    })
  }

  _updateConnector = (connector, updateCopyPort, fromSignal) => {
    if (updateCopyPort) {
      connector = this.updateCopySignalPorts({ fromSignal, connector });
    }

    this.setState({
      ...this.state,
      ...connector
    }, () => {
      const { connector1, connector2 } = this.state;
      this.updatePinList({ connector1, connector2 });
    });
  }

  updateCopySignalPorts = ({ fromSignal, connector, _copyGroups }) => {
    const { connector1, connector2, copyGroups } = this.state;
    const { portType, signalType } = this.props;
    let connectorInfo = {
      connector1: connector.connector1 ? connector.connector1 : connector1,
      connector2: connector.connector2 ? connector.connector2 : connector2
    };
    const pinList = this.updatePinList({
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      isReturn: true
    })
    _copyGroups = _copyGroups ? _copyGroups : [...copyGroups];

    connectorInfo = copyPinPortsBySignal({
      _copyGroups,
      pinList,
      connectorInfo,
      signalType,
      portType,
      fromSignal
    })

    connector.connector1 = connectorInfo.connector1;
    connector.connector2 = connectorInfo.connector2;
    return connector;
  }

  closeModal = () => {
    const { connector1, connector2, cableModels } = this.getConnectorAndModels();
    this.props.closeModal({
      connector1,
      connector2,
      cableModels
    })
  }

  getConnectorAndModels = () => {
    const { connector1, connector2, connectorModels, copyGroups, selectedSignals, pinList } = this.state;
    const { signalType, portType, connector1: propsConnector1, connector2: propsConnector2 } = this.props;
    let _connector1 = { ...propsConnector1 },
      _connector2 = { ...propsConnector2 };
    let _connectorModels = [...connectorModels];
    const { saveLPins, saveRPins } = getModifiedPinsBySignals({ portType, signalType, copyGroups, selectedSignals, pinList })
    switch (portType) {
      case "pinL":
        _connector1 = updateConnectorModel({ connector: connector1, propsConnector: _connector1, savePins: saveLPins, _connectorModels });
        break;
      case "pinR":
        _connector2 = updateConnectorModel({ connector: connector2, propsConnector: _connector2, savePins: saveRPins, _connectorModels });
        break;
      case "cable":
        const info = updateCableModel({
          connector1,
          connector2,
          propsConnector1: _connector1,
          propsConnector2: _connector2,
          cableModels: _connectorModels,
          saveLPins,
          saveRPins
        });
        _connector1 = info.connector1;
        _connector2 = info.connector2;
        _connectorModels = info.cableModels;
        return {
          connector1: _connector1,
          connector2: _connector2,
          cableModels: _connectorModels
        }
      default: break;
    }
    return {
      connector1: _connector1,
      connector2: _connector2
    };
  }

  addSelectFile2 = () => {
    const { portType } = this.props;
    const { connector1, connector2, connectorModels, selectedSignals, selectFile, copyGroups } = this.state;
    let _connectorModels = [...connectorModels],
      connectorInfo = {
        connector1: { ...connector1 },
        connector2: { ...connector2 }
      };

    let _selectFile = { ...selectFile },
      _selectFile2 = {};
    const connectorIndex = portType === "pinL" ? "1" : "2";

    //add new modelKey
    const modelKeys = _connectorModels.map(item => item.modelKey);
    const modelKey = getDefaultIndex(_connectorModels.length, modelKeys);
    let file = {
      file: "",
      libraryId: "",
      type: "",
      modelKey,
      subckt: ""
    };

    //update connector models file
    _connectorModels.push({ ...file });

    //clear prev file ports
    if (_selectFile && _selectFile.libraryId) {
      connectorInfo = clearPinsPortByFile({
        connectorInfo,
        selectFile: _selectFile,
        connectorIndex,
        portType
      });
    }

    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file
    const copyInfo = updateCopyGroupsBySelectFile({
      _copyGroups,
      _selectedSignals: selectedSignals,
      selectFile: _selectFile,
      selectFile2: _selectFile2,
      _connectorModels
    });
    _connectorModels = copyInfo._connectorModels;
    _copyGroups = copyInfo._copyGroups;

    connectorInfo = this.updateCopySignalPorts({
      connector: connectorInfo,
      _copyGroups
    });

    if (portType !== "cable") {
      connectorInfo[`connector${connectorIndex}`].models = JSON.parse(JSON.stringify(_connectorModels));
    }

    this.setState({
      selectFile2: { ...file, portNumber: "" },
      portNumber2: "",
      _connectorModels,
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      copyGroups: _copyGroups
    }, () => {
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2
      });
    });
  }

  deleteFile = (pinType) => {
    const { portType } = this.props;
    const { connector1, connector2, connectorModels, selectedSignals, selectFile2, selectFile, portNumber2, portNumber, copyGroups } = this.state;
    let _connectorModels = [...connectorModels],
      connectorInfo = {
        connector1: { ...connector1 },
        connector2: { ...connector2 }
      };

    let _portNumber = portNumber,
      _selectFile = { ...selectFile },
      _selectFile2 = selectFile2 ? { ...selectFile2 } : null;

    let currDelFile = pinType === "negative" ? selectFile2 ? { ...selectFile2 } : null : { ...selectFile };

    const connectorIndex = portType === "pinL" ? "1" : "2";
    //clear prev file ports
    if (currDelFile && currDelFile.libraryId) {
      connectorInfo = clearPinsPortByFile({
        connectorInfo,
        selectFile: currDelFile,
        connectorIndex,
        portType
      });
    }

    if (pinType === "positive") {
      _portNumber = portNumber2;
      _selectFile = { ..._selectFile2 };
    }

    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file
    const copyInfo = updateCopyGroupsBySelectFile({
      _copyGroups,
      _selectedSignals: selectedSignals,
      selectFile: _selectFile,
      selectFile2: null,
      _connectorModels
    });
    _connectorModels = copyInfo._connectorModels;
    _copyGroups = copyInfo._copyGroups;

    connectorInfo = this.updateCopySignalPorts({
      connector: connectorInfo,
      _copyGroups
    });

    if (portType !== "cable") {
      connectorInfo[`connector${connectorIndex}`].models = JSON.parse(JSON.stringify(_connectorModels));
    }

    this.setState({
      selectFile: {
        ..._selectFile
      },
      selectFile2: null,
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      connectorModels: _connectorModels,
      portNumber: _portNumber,
      portNumber2: null,
      copyGroups: _copyGroups
    }, () => {
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2
      });
    })
  }

  selectModelFile = (key, pinType) => {
    const { fileList, portType } = this.props;
    const { connector1, connector2, connectorModels, selectedSignals, selectFile2, selectFile, portNumber2, portNumber, copyGroups } = this.state;
    let _connectorModels = [...connectorModels],
      connectorInfo = {
        connector1: { ...connector1 },
        connector2: { ...connector2 }
      };

    let _portNumber2 = portNumber2,
      _portNumber = portNumber,
      _selectFile = { ...selectFile },
      _selectFile2 = selectFile2 ? { ...selectFile2 } : null;

    let currSelectFile = pinType === "negative" ? selectFile2 ? { ...selectFile2 } : null : { ...selectFile };

    const findFile = fileList.find(item => item.name === key) || { name: "", type: "", id: "" };
    //getPortNumberFromFileSuffix
    const filePortNumber = getPortNumberFromFileSuffix(findFile.name);
    const connectorIndex = portType === "pinL" ? "1" : "2";
    //clear prev file ports
    if (currSelectFile && currSelectFile.libraryId && findFile.id !== currSelectFile.libraryId) {
      connectorInfo = clearPinsPortByFile({
        connectorInfo,
        selectFile: currSelectFile,
        connectorIndex,
        portType
      });
    }
    //add new modelKey
    const modelKeys = _connectorModels.map(item => item.modelKey);
    const modelKey = getDefaultIndex(_connectorModels.length, modelKeys);
    const file = {
      file: findFile.name,
      libraryId: findFile.id,
      type: findFile.type.match("touchstone") ? TOUCHSTONE : SPICE,
      modelKey,
      subckt: ""
    };

    //update connector models file
    _connectorModels.push({ ...file });

    currSelectFile = {
      ...file,
      portNumber: filePortNumber
    };

    if (pinType === "negative") {
      _portNumber2 = filePortNumber;
      _selectFile2 = currSelectFile;
    } else {
      _portNumber = filePortNumber;
      _selectFile = currSelectFile;
    }

    let _selectedSignals = [...selectedSignals];

    let _copyGroups = [...copyGroups];
    //update copyGroups by selectSignals and new select file
    const copyInfo = updateCopyGroupsBySelectFile({
      _copyGroups,
      _selectedSignals,
      selectFile: _selectFile,
      selectFile2: _selectFile2,
      _connectorModels
    });
    _connectorModels = copyInfo._connectorModels;
    _copyGroups = copyInfo._copyGroups;

    connectorInfo = this.updateCopySignalPorts({
      connector: connectorInfo,
      _copyGroups
    });

    if (portType !== "cable") {
      connectorInfo[`connector${connectorIndex}`].models = JSON.parse(JSON.stringify(_connectorModels));
    }

    this.setState({
      selectFile: {
        ..._selectFile
      },
      selectFile2: _selectFile2,
      selectedSignals: _selectedSignals,
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      connectorModels: _connectorModels,
      portNumber: _portNumber,
      portNumber2: _portNumber2,
      copyGroups: _copyGroups
    }, () => {
      this.props.getFileParse([{
        file: findFile.name,
        libraryId: findFile.id,
        type: findFile.type,
        modelKey
      }]);
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2
      });
    })
  }

  selectSignal = (key) => {
    const { selectedSignals, connector1, connector2, pinList, copyGroups, copySignals } = this.state;
    const { portType, signalType } = this.props;
    let connectorInfo = {
      connector1: { ...connector1 },
      connector2: { ...connector2 }
    },
      _copySignals = [...copySignals];
    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups];
    _selectedSignals.push(key);
    //update copy signals
    if (_copySignals.length && _copySignals[0] !== "all") {
      _copySignals = _copySignals.filter(item => item !== key);
    }
    const allSignals = this.signals.filter(item => !_selectedSignals.includes(item));
    if (_copySignals[0] && _copySignals[0] === "all" && !allSignals.length) {
      _copySignals = [];
    }
    _copyGroups.forEach(group => {
      group.signalMap.forEach(item => {
        if (item.toSignal === key) {
          item.toSignal = "";
        }
      })
      group.signalMap.push({ fromSignal: key, toSignal: "" });
      group.signalMap = SortFn(group.signalMap, _selectedSignals, "fromSignal");
    })

    connectorInfo = clearPinsPortBySignal({
      connectorInfo,
      portType,
      pinList,
      signal: key,
      signalType
    });

    this.setState({
      selectedSignals: _selectedSignals,
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      copyGroups: _copyGroups,
      copySignals: _copySignals
    }, () => {
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2
      });
    })
  }

  delSignal = (key) => {
    const { selectedSignals, connector1, connector2, pinList, copyGroups, copySignals } = this.state;
    const { portType, signalType } = this.props;
    let connectorInfo = {
      connector1: { ...connector1 },
      connector2: { ...connector2 }
    };
    let _selectedSignals = [...selectedSignals];
    let _copyGroups = [...copyGroups], _copySignals = [...copySignals];
    _selectedSignals = _selectedSignals.filter(item => item !== key);
    let deleteCopySignals = [];
    _copyGroups.forEach(group => {
      deleteCopySignals = [...deleteCopySignals, ...group.signalMap.filter(it => it.fromSignal === key).map(it => it.toSignal)]
      group.signalMap = group.signalMap.filter(it => it.fromSignal !== key);
      group.signalMap = SortFn(group.signalMap, _selectedSignals, "fromSignal");
    });

    _copyGroups = _copyGroups.filter(item => item.signalMap.length);

    if (_copySignals.length && _copySignals[0] === "all") {
      _copySignals = this.signals.filter(item => !selectedSignals.includes(item) && !deleteCopySignals.includes(item));
    } else {
      _copySignals = _copySignals.filter(item => !deleteCopySignals.includes(item));
    }

    connectorInfo = clearPinsPortBySignal({
      connectorInfo,
      portType,
      pinList,
      signal: key,
      signalType
    });

    this.setState({
      selectedSignals: _selectedSignals,
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      copyGroups: _copyGroups,
      copySignals: _copySignals
    }, () => {
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2
      });
    })
  }

  updateError = (error) => {
    clearTimeout(this.timer);
    this.setState({
      error
    });
    this.timer = setTimeout(() => {
      this.setState({
        error: null
      });
    }, 3000)
  }

  onTreeChange = (keys) => {
    const { selectFile, selectFile2, selectedSignals, connectorModels, connector1, connector2, pinList } = this.state;
    const { portType, signalType } = this.props;
    let connectorInfo = {
      connector1: { ...connector1 },
      connector2: { ...connector2 }
    };
    if (!selectedSignals.length) {
      return;
    }

    let _copyGroups = [];
    let _copySignals = [];
    if (keys.length && keys[0] === "all") {
      _copySignals = this.signals.filter(item => !selectedSignals.includes(item));
    } else {
      _copySignals = [...keys];
    }

    let groupLength = _copySignals.length / selectedSignals.length;
    const endGroup = _copySignals.length % selectedSignals.length;
    if (endGroup > 0) {
      groupLength += 1;
    }

    let _connectorModels = JSON.parse(JSON.stringify(connectorModels));
    const connectorIndex = portType === "pinL" ? "1" : "2";

    for (let i = 0; i < groupLength; i++) {
      let copyModelKey2;
      const copyModelKey = getDefaultIndex(_connectorModels.length, _connectorModels.map(item => item.modelKey));
      _connectorModels.push({
        file: selectFile.file,
        libraryId: selectFile.libraryId,
        type: selectFile.type,
        subckt: "",
        modelKey: copyModelKey,
      });
      if (selectFile2) {
        copyModelKey2 = getDefaultIndex(_connectorModels.length, _connectorModels.map(item => item.modelKey));
        _connectorModels.push({
          file: selectFile2.file,
          libraryId: selectFile2.libraryId,
          type: selectFile2.type,
          subckt: "",
          modelKey: copyModelKey2,
        });
      }

      _copyGroups = getCopyGroups({
        selectedSignals,
        copyModelKey,
        copyModelKey2,
        _copyGroups
      });
    }

    if (portType !== "cable") {
      connectorInfo[`connector${connectorIndex}`].models = JSON.parse(JSON.stringify(_connectorModels));
    }

    for (let i = 0; i < _copyGroups.length; i++) {
      for (let item of _copyGroups[i].signalMap) {
        const toSignal = _copySignals[0] ? _copySignals[0] : "";
        item.toSignal = toSignal;
        _copySignals = _copySignals.filter(it => it !== toSignal);
        const info = copyPinsPort({
          toSignal: toSignal,
          fromSignal: item.fromSignal,
          pinList,
          connector1: connectorInfo.connector1,
          connector2: connectorInfo.connector2,
          signalType,
          portType,
          copyModelKey: _copyGroups[i].modelKey,
          copyModelKey2: _copyGroups[i].modelKey2
        });
        connectorInfo.connector1 = { ...info.connector1 };
        connectorInfo.connector2 = { ...info.connector2 };
      }
    }

    this.setState({
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      connectorModels: _connectorModels,
      copyGroups: _copyGroups,
      copySignals: [...keys]
    }, () => {
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2
      });
    })
  }

  changeCopySignal = (toSignal, fromSignal, copyModelKey, copyModelKey2) => {
    const { pinList, connector1, connector2, copyGroups, copySignals, selectedSignals } = this.state;
    const { portType, signalType } = this.props;
    let _connector1 = { ...connector1 },
      _connector2 = { ...connector2 },
      _copyGroups = [...copyGroups];

    //delete pre signal
    const copyInfo = updateCopyGroupsBySignal({
      copyModelKey,
      _copyGroups,
      toSignal,
      fromSignal
    });
    const prevSignal = copyInfo.prevSignal;
    _copyGroups = copyInfo._copyGroups;

    //update copySignals
    const allSignals = this.signals.filter(item => !selectedSignals.includes(item));
    let _copySignals = copySignals[0] && copySignals[0] === "all" ? [...allSignals] : [...copySignals];
    _copySignals = _copySignals.filter(item => item !== prevSignal);

    if (!_copySignals.includes(toSignal)) {
      _copySignals.push(toSignal);
      if (JSON.stringify([...allSignals].sort()) === JSON.stringify([..._copySignals].sort())) {
        _copySignals = ["all"]
      }
    }

    const info = copyPinsPort({
      toSignal,
      fromSignal,
      pinList,
      connector1: _connector1,
      connector2: _connector2,
      signalType,
      portType,
      copyModelKey,
      copyModelKey2
    });
    _connector1 = { ...info.connector1 };
    _connector2 = { ...info.connector2 };
    this.setState({
      copyGroups: _copyGroups,
      connector1: _connector1,
      connector2: _connector2,
      copySignals: _copySignals
    }, () => {
      this.updatePinList({
        connector1: _connector1,
        connector2: _connector2
      });
    })
  }

  addCopyGroups = () => {
    const { copyGroups, selectFile, selectFile2, connectorModels, connector1, connector2, selectedSignals } = this.state;
    const { portType } = this.props;
    let _copyGroups = [...copyGroups];
    let connectorInfo = {
      connector1,
      connector2
    }

    let _connectorModels = JSON.parse(JSON.stringify(connectorModels));
    let copyModelKey2;
    const copyModelKey = getDefaultIndex(_connectorModels.length, _connectorModels.map(item => item.modelKey));
    const connectorIndex = portType === "pinL" ? "1" : "2";
    _connectorModels.push({
      file: selectFile.file,
      libraryId: selectFile.libraryId,
      type: selectFile.type,
      subckt: "",
      modelKey: copyModelKey,
    });

    if (selectFile2) {
      copyModelKey2 = getDefaultIndex(_connectorModels.length, _connectorModels.map(item => item.modelKey));
      _connectorModels.push({
        file: selectFile2.file,
        libraryId: selectFile2.libraryId,
        type: selectFile2.type,
        subckt: "",
        modelKey: copyModelKey2,
      });
    }
    if (portType !== "cable") {
      connectorInfo[`connector${connectorIndex}`].models = JSON.parse(JSON.stringify(_connectorModels));
    }
    _copyGroups = getCopyGroups({
      selectedSignals,
      copyModelKey,
      copyModelKey2,
      _copyGroups
    });

    this.setState({
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      copyGroups: _copyGroups,
      connectorModels: _connectorModels
    }, () => {
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2,
      });
    })
  }

  deleteCopyGroup = (group) => {
    const { connector1, connector2, pinList, copyGroups, copySignals, selectedSignals } = this.state;
    const { portType, signalType } = this.props;
    let connectorInfo = {
      connector1: { ...connector1 },
      connector2: { ...connector2 }
    };
    let _copyGroups = [...copyGroups];
    let deletedSignals = [];
    for (let item of group.signalMap) {
      deletedSignals.push(item.toSignal);
      connectorInfo = clearPinsPortBySignal({
        connectorInfo,
        portType,
        pinList,
        signal: item.toSignal,
        signalType
      });
    }
    _copyGroups = _copyGroups.filter(item => item.modelKey !== group.modelKey);
    //update copy signals
    let _copySignals = [...copySignals];
    if (deletedSignals.length) {
      if (_copySignals.length && _copySignals[0] === "all") {
        _copySignals = this.signals.filter(item => !selectedSignals.includes(item) && !deletedSignals.includes(item));
      } else {
        _copySignals = _copySignals.filter(item => !deletedSignals.includes(item));
      }
    }

    this.setState({
      connector1: connectorInfo.connector1,
      connector2: connectorInfo.connector2,
      copyGroups: _copyGroups,
      copySignals: _copySignals
    }, () => {
      this.updatePinList({
        connector1: connectorInfo.connector1,
        connector2: connectorInfo.connector2
      });
    })
  }

  automaticMatchPort = (fileInfo, type, save) => {
    if (fileInfo.libraryId && save) {
      const { pinList, selectFile, connector1, connector2, selectedSignals } = this.state;
      const { portsObj, portType, signalType } = this.props;

      const oldConnector = portType === "pinL" ? connector1 : connector2;
      const connectorType = portType === "pinL" ? "connector1" : "connector2"
      const portsList = portsObj[fileInfo.libraryId];
      if (portsList && portsList.length > 0) {
        const newConnector = autoMatchPort({ oldConnector, portsList, matchType: type, pinList, selectedSignals, signalType, modelFile: selectFile });
        this._updateConnector({
          connector1: connector1,
          connector2: connector2,
          [connectorType]: newConnector
        }, false);
      }
    }

    this.setState({ automaticInfo: null })
  }

  selectAutoMatchModelFile = (files) => {
    const { fileName } = files[0] || {};
    this.selectModelFile(fileName)
  }

  render() {
    const { selectFile, portNumber, selectedSignals = [],
      error, pinList, connector1, connector2, selectFile2,
      copyGroups = [], copySignals = [], maxWidth, maxHeight,
      automaticInfo
    } = this.state;
    const { fileList, signalType, portType, portsObj, serdesType } = this.props;
    const selectedPinList = pinList.filter(item => selectedSignals.includes(item[`${signalType}`]) || copySignals.includes(item[`${signalType}`]))
    const { leftPinWidth, rightPinWidth } = getPinNetWidth(selectedPinList, 14);

    const applySignals = getApplySignals(copyGroups);
    const multipleFile = selectFile2;
    const modelTitle = portType === "cable" ? "Cable" : "Connector";

    const content = (
      <Fragment>
        <Panel
          className='connector-model-select-panel'
          title={`${modelTitle} model`}
          onCancel={this.closeModal}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { defaultWidth: 770 })}
          position='panel-center-left'
          draggable
          minHeight={220}
          maxWidth={maxWidth}
          minWidth={300}
          maxHeight={maxHeight}
          overflow={true}
        >
          <div className="connector-model-select-main">
            {multipleFile ? <Fragment>
              {this.getModelFileSelect({
                value: selectFile.file,
                title: `${modelTitle} Model ( + )`,
                list: fileList,
                selectChange: this.selectModelFile,
                type: "file",
                pinType: "positive",
                className: "multi-connector-model-file-content"
              })}
              {this.getModelFileSelect({
                value: selectFile2.file,
                title: `${modelTitle} Model ( - )`,
                list: fileList,
                selectChange: this.selectModelFile,
                type: "file",
                pinType: "negative",
                className: "multi-connector-model-file-content"
              })}
            </Fragment> :
              this.getModelFileSelect({
                value: selectFile.file,
                title: `${modelTitle} Model`,
                list: fileList,
                selectChange: this.selectModelFile,
                type: "file",
                serdesType
              })}
            <div className="connector-model-select-content">
              {this.getModelFileSelect({
                value: selectedSignals,
                title: "Signals",
                list: this.signals,
                disabled: !selectFile.file,
                onSelect: this.selectSignal,
                onDeselect: this.delSignal,
                type: "signal",
                selectType: "multiple",
                className: multipleFile ? "multi-connector-model-file-content" : ""
              })}
              {error ? <span className="aurora-error-msg-span">{error}</span> : null}
              {selectedSignals.length ? <div className="connector-model-select-pins-content">
                {/* auto  */}
                {portType !== "cable" ? <div className='connector-model-auto-match-icon'>
                  <span>{modelTitle}</span>
                  <span
                    title="Ports Match"
                    className='iconfont icon-xinpian_chip'
                    onClick={() => this.setState({ automaticInfo: { modelType: modelTitle, modelFileKey: modelTitle } })}>
                  </span>
                </div>
                  : null}
                {selectedSignals.map(item => {
                  const findPins = pinList.find((it, index) => it[`${signalType}`] === item);
                  return <SignalPinsPortSetup
                    key={findPins[`${signalType}`]}
                    signalInfo={findPins}
                    portType={portType}
                    signalType={signalType}
                    portNumber={portNumber}
                    selectFile={selectFile}
                    selectFile2={selectFile2}
                    pinList={pinList}
                    portsObj={portsObj}
                    connector1={connector1}
                    connector2={connector2}
                    pinLWidth={leftPinWidth}
                    pinRWidth={rightPinWidth}
                    _updateConnector={this._updateConnector}
                  />
                })}
              </div> : null}
            </div>
            {selectedSignals.length ? <div className="connector-model-select-content">
              {this.getModelFileSelect({
                value: copySignals,
                title: "Apply model to",
                type: "copy_signal",
                selectType: "multiple",
                selectedSignals,
                className: multipleFile ? "multi-connector-model-file-content" : ""
              })}
              {copyGroups.length ? <div className="connector-model-select-pins-content">
                {copyGroups.map((group, i) => {
                  return (
                    <div key={i} className="connector-model-select-copy-group">
                      {group.signalMap.map((item, index) => {
                        const fromSignal = pinList.find((it, index) => item.fromSignal === it[`${signalType}`]);
                        const toSignal = pinList.find((it, index) => item.toSignal === it[`${signalType}`]);

                        return <CopySignalPinsPortSetup
                          key={index}
                          fromSignal={fromSignal}
                          toSignals={this.signals.filter(item => !selectedSignals.includes(item))}
                          toSignal={toSignal}
                          portType={portType}
                          signalType={signalType}
                          copyModelKey={group.modelKey}
                          copyModelKey2={group.modelKey2}
                          applySignals={applySignals}
                          pinLWidth={leftPinWidth}
                          pinRWidth={rightPinWidth}
                          changeCopySignal={this.changeCopySignal}
                        />
                      })}
                      <CloseOutlined
                        title="Delete the group."
                        onClick={() => this.deleteCopyGroup(group)}
                        className="connector-model-select-delete-group-icon" />
                    </div>
                  );
                })}
              </div> : null}
            </div> : null}
            {portType !== "cable" && automaticInfo && automaticInfo.modelType ? <AutomaticPanel
              automaticInfo={automaticInfo}
              closePanel={this.automaticMatchPort}
              libraryListObj={{ [`${automaticInfo.modelType.toLowerCase()}List`]: fileList }}
              getFileParse={this.selectAutoMatchModelFile}
              modelType={modelTitle}
              files={[selectFile]}
            /> : null}
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }

  getModelFileSelect = ({
    value,
    title,
    selectChange,
    type,
    list,
    selectType,
    onDeselect,
    onSelect,
    disabled,
    pinType,
    className,
    selectedSignals = [],
    serdesType
  }) => {
    return (
      <div className={`connector-model-select-item ${className}`}>
        <span>
          {title}
          {type === "copy_signal" ? <PlusCircleOutlined
            title="Add new Group."
            className="connector-model-select-copy-icon"
            onClick={this.addCopyGroups} /> : null}
          {type === "file" && !pinType && serdesType !== CPHY ? <PlusCircleOutlined
            title="Add a new file to make positive and negative pins use different models."
            className="connector-model-select-copy-icon"
            onClick={this.addSelectFile2} /> : null}
        </span>
        {type === "copy_signal" ? <TreeSelect
          treeData={getTreeData(this.signals.filter(item => !selectedSignals.includes(item)))}
          value={value}
          onChange={this.onTreeChange}
          treeCheckable={true}
          showCheckedStrategy={SHOW_PARENT}
          placeholder='Select signals'
          className='aurora-select connection-model-select'
          getPopupContainer={() => document.getElementById('root')}
          popupClassName='connector-select-dropdown-menu'
          size='small'
        /> :
          <Select
            value={value}
            placeholder={title}
            allowClear
            showSearch
            disabled={disabled}
            mode={selectType}
            onChange={selectChange ? (key) => selectChange(key, pinType) : null}
            onDeselect={onDeselect ? onDeselect : null}
            onSelect={onSelect ? onSelect : null}
            popupMatchSelectWidth={false}
            className={`aurora-select connection-model-select`}
            popupClassName="connector-select-dropdown-menu"
            getPopupContainer={() => document.getElementById('root')}
            size='small'
          >
            {list.map((item) => {
              return type.match("signal") ?
                <Option
                  key={item}
                  value={item}
                  title={item}
                >{item}</Option>
                : <Option
                  key={item.id}
                  value={item.name}
                  title={item.name}
                >{item.name}</Option>
            })}
          </Select>
        }
        {pinType ? <CloseOutlined
          title="Delete the file."
          className="connector-model-select-delete-icon"
          onClick={() => this.deleteFile(pinType)} /> : null}
      </div>
    );
  }
}

export default ConnectorModelSelect;