import {
  CHANGE_VERIFICATION_LIST,
  UPDATE_SIMULATION_REDUCER,
  UPDATE_CURRENT_SIMULATION_INFO,
  UPDATE_NOT_SET_CARD_VERIFICATIONS,
  UPDATE_CURRENT_MULTI_SIMULATION_INFO
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  simulateKeys: [],
  packageSimulateKeys: [],
  cardSimulateKeys: [],
  packageSimulationKeys: [],
  notSetCardVerifications: null
}

export const simulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_VERIFICATION_LIST:
      return {
        ...state,
        [action.simulationType]: action.simulateKeys
      }
    case UPDATE_SIMULATION_REDUCER:
      return {
        ...action.info
      }
    case UPDATE_CURRENT_SIMULATION_INFO:
      const info = state[action.currentVerificationId] ?
        {
          ...state[action.currentVerificationId],
          [action.item]: action.info
        } :
        { [action.item]: action.info }
      return {
        ...state,
        [action.currentVerificationId]: info
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_NOT_SET_CARD_VERIFICATIONS:
      return {
        ...state,
        notSetCardVerifications: action.verifications
      }
    case UPDATE_CURRENT_MULTI_SIMULATION_INFO:
      const _info = state[action.currentVerificationId] ? {
        ...state[action.currentVerificationId],
        ...action.info
      } : { ...action.info };
      return {
        ...state,
        [action.currentVerificationId]: _info
      }
    default: return state
  }
};