import React, { Component, Fragment } from 'react';
import { Switch, Input, Select, Divider } from 'antd';
import Panel from '@/components/Panel';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { optimizationChangeAction } from '../../../store/pdn/action';
import { fastPIValueCheck } from '@/services/helper/fastPIValueCheck';
import { OptOptions } from '../../../../../services/PDN/optimization';
import './new.css';


const { Option } = Select;

class SimulatePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      VR_FSW: 600,
      VR_FSW_Use: false,
      CVRM_MODEL: false,
      VRUnit: 'KHz',
      error: false,
      maxWidth: 950,
      maxHeight: 326,
      minimizeDecap: false,
      capValue: '100',
      capUnit: 'u',
      TGT: '',
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 950),
      maxHeight: getPanelMaxHeight(offset, 400)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { Config, pdnContent } = this.props;
    if (Config) {
      let { VR_FSW, TGT, CVRM_MODEL = 0 } = Config;
      let VR_FSW_Use = false, VRUnit = 'KHz', VRNum = 600;
      let CVRM = CVRM_MODEL === 0 ? false : true;
      if (VR_FSW) {
        VRUnit = VR_FSW.match(/[a-z|A-Z]+$/gi);
        VRNum = VR_FSW.match(/^\d+/gi);
        VR_FSW_Use = CVRM ? false : true;
      } else {
        VR_FSW_Use = false;
        VRNum = 600;
        VRUnit = 'KHz';
      }
      let newVRNum = VRNum.toString(), VRNumIndex = -1;
      if (newVRNum.indexOf('.') > -1) {
        VRNumIndex = newVRNum.substring(newVRNum.indexOf('.') + 1)
      }

      if ((newVRNum.indexOf('e') === -1 || newVRNum.indexOf('E') === -1) && (Number(VRNum) >= 10000 || Number(VRNum) < 0.001 || VRNumIndex.length >= 4)) {
        newVRNum = Number(VRNum).toExponential();
      }

      this.setState({
        VR_FSW: newVRNum || 600,
        VR_FSW_Use: VR_FSW_Use,
        CVRM_MODEL: CVRM,
        VRUnit: VRUnit || 'KHz',
        TGT: TGT,
      })
    }

    const Optimization = new OptOptions(pdnContent ? pdnContent.Optimization : {});
    const { minimizeLargeDecap = false, keptMinimumNumber = 'All', minimizeDecap = false, cap = '100u', balancedDecapDistribution = true } = Optimization;
    this.setState({
      minimizeDecap,
      capValue: cap.slice(0, -1),
      capUnit: cap.slice(-1),
      keptMinimumNumber,
      minimizeLargeDecap,
      balancedDecapDistribution
    })
  }

  changeInputValue = (e, type) => {
    let value = e.target.value;
    const { error } = this.state;
    const newError = error && error.type === type ? null : error;
    this.setState({
      [type]: value,
      error: newError
    });
  }

  changeSelectValue = (checked, selectType) => {
    let checkedConfig = {}
    if (checked) {
      switch (selectType) {
        case 'CVRM_MODEL':
          checkedConfig = { VR_FSW_Use: false }
          break;
        case 'VR_FSW_Use':
          checkedConfig = { CVRM_MODEL: false }
          break;
        case 'minimizeDecap':
          checkedConfig = { minimizeLargeDecap: false }
          break;
        case 'minimizeLargeDecap':
          checkedConfig = { minimizeDecap: false }
          break;
        default: break;
      }
    }
    this.setState({
      ...checkedConfig,
      [selectType]: checked,
      error: null
    })
  }

  changeTargetZ = (value) => {
    this.setState({
      TGT: value
    })
  }

  VR_FSWSelectAfter = () => {
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      defaultValue={this.state.VRUnit}
      onChange={(e) => this.changeUnit(e, 'VRUnit')}
      style={{ width: 65 }}
      popupClassName="fastpi-simulate-select-dropdown"
    >
      <Option value="KHz">KHz</Option>
    </Select>
  }

  getCapSelect = () => {
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      value={this.state.capUnit + 'F'}
      onChange={(e) => this.changeCapUnit(e)}
      style={{ width: 70 }}
    >
      <Option value="p">pF</Option>
      <Option value="n">nF</Option>
      <Option value="u">uF</Option>
    </Select>
  }

  changeCapUnit = (e) => {
    this.setState({
      capUnit: e
    })
  }

  changeUnit = (key, Unit) => {
    this.setState({
      [Unit]: key
    })
    let value = { target: { value: '' } };
    this.changeVR_FSW(value, Unit);
  }

  changeconfigValue = (e, Unit) => {
    let value = e.target.value;
    let key = ''
    if (Unit === 'VRUnit') {
      key = 'VR_FSW'
    }
    this.setState({
      [key]: value
    });
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let _check = null;
    const { error } = this.state;
    const unit = null
    _check = inputType === 'keptMinimumNumber' && value === 'All' ? null : fastPIValueCheck({ value, inputType, unit });

    if (_check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: _check }
      });
    } else {
      const newError = error && error.type === inputType ? null : error;
      this.setState({
        [inputType]: value,
        error: newError
      });
    }
  }

  closeModal = () => {
    const { VR_FSW, VRUnit, VR_FSW_Use, minimizeDecap, capValue, capUnit, TGT, error, CVRM_MODEL, keptMinimumNumber, minimizeLargeDecap, balancedDecapDistribution } = this.state;
    let _capValue = capValue + capUnit;

    if (error) {
      return;
    }
    let config = { ...this.props.Config }

    let minInputType = 'capValue', minValue = capValue;
    const _minCheck = fastPIValueCheck({ value: minValue, inputType: minInputType, unit: 'null' });
    if (_minCheck) {
      _capValue = '100u'
    }

    if (VR_FSW_Use || CVRM_MODEL) {
      const inputType = 'VR_FSW', value = VR_FSW;
      const _check = fastPIValueCheck({ value, inputType, VRUnit });
      if (_check) {
        this.setState({
          error: { type: inputType, error: _check }
        });
        return
      } else {
        config.VR_FSW = `${VR_FSW}${VRUnit}`
      }
    } else {
      delete config.VR_FSW
    }
    config.CVRM_MODEL = CVRM_MODEL ? 1 : 0
    config.TGT = TGT
    const opt = new OptOptions({ minimizeDecap, cap: _capValue, keptMinimumNumber, minimizeLargeDecap, balancedDecapDistribution })
    this.props._optimizationChange(opt);
    this.props.saveConfig(config, "Simulate")
    this.props.closeModal();
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  VRRender = () => {
    const { VR_FSW } = this.state;
    return <div className='pdn-top-option-panel-content'>
      <span className='pdn-top-option-panel-content-body' style={{ fontStyle: 'italic' }}>
        <span className='pdn-top-option-panel-sub-box'></span>
        VRM Switching Frequency Fsw
      </span>
      <Input className='pdn-top-option-panel-content-input'
        addonAfter={this.VR_FSWSelectAfter()}
        value={VR_FSW}
        onChange={(e) => this.changeInputValue(e, 'VR_FSW')}
        onBlur={(e) => this.inputBlur(e, 'VR_FSW')}
        onPressEnter={this.onPressEnter}
      />
    </div>
  }

  OptimizationRender = () => {
    const { minimizeDecap, capValue, keptMinimumNumber, minimizeLargeDecap, balancedDecapDistribution } = this.state;

    return <Fragment>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-title'>
          Decap Optimization
        </span>
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-sub-body'>
          Minimize the <span className="font-bold">total number of decaps</span> while keeping impedance target satisfied
        </span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={minimizeDecap}
          onChange={(e) => this.changeSelectValue(e, 'minimizeDecap')}
        />
      </div>
      {minimizeDecap && <Fragment>
        <div className='pdn-top-option-panel-content'>
          <span className='pdn-top-option-panel-content-body' style={{ fontStyle: 'italic' }}>
            <span className='pdn-top-option-panel-sub-box'></span>
            Balanced decap value distribution
          </span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={balancedDecapDistribution}
            onChange={(e) => this.changeSelectValue(e, 'balancedDecapDistribution')}
          />
        </div>
        <div className='pdn-top-option-panel-content'>
          <span className='pdn-top-option-panel-content-body' style={{ fontStyle: 'italic' }}>
            <span className='pdn-top-option-panel-sub-box'></span>
            Keep the decap with value no less than
          </span>
          <Input className='pdn-top-option-panel-content-input'
            addonAfter={this.getCapSelect()}
            value={capValue}
            onChange={(e) => this.changeInputValue(e, 'capValue')}
            onBlur={(e) => this.inputBlur(e, 'capValue')}
          />
        </div>
      </Fragment>}
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-sub-body'>
          Minimize the <span className="font-bold">large decap components</span> while keeping impedance target satisfied
        </span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={minimizeLargeDecap}
          onChange={(e) => this.changeSelectValue(e, 'minimizeLargeDecap')}
        />
      </div>
      {minimizeLargeDecap && <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body' style={{ fontStyle: 'italic' }}>
          <span className='pdn-top-option-panel-sub-box'></span>
          The minimum number of the largest cap to be kept
        </span>
        <Input className='pdn-top-option-panel-content-input'
          value={keptMinimumNumber}
          placeholder={`Enter an integer or 'All'`}
          onChange={(e) => this.changeInputValue(e, 'keptMinimumNumber')}
          onBlur={(e) => this.inputBlur(e, 'keptMinimumNumber')}
        />
      </div>}
    </Fragment>
  }

  render() {
    const { VR_FSW_Use, error, maxWidth, maxHeight, CVRM_MODEL } = this.state;
    const setting = { defaultWidth: 950 }
    const content = (
      <Panel
        className='pdn-top-option-panel'
        title={<div className='pdn-top-option-panel-name'>Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        mask={true}
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        minHeight="200"
      >
        <div className='pdn-top-option-panel-main pdn-top-opt-panel-main' id="simulate-content-id">
          <div className='pdn-top-option-panel-content'>
            <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-title'>
              VRM Model
            </span>
          </div>
          <div className='pdn-top-option-panel-content'>
            <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-sub-body'>
              Calculate VRM inductance and resistance
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={VR_FSW_Use}
              onChange={(e) => this.changeSelectValue(e, 'VR_FSW_Use')}
            />
          </div>
          {VR_FSW_Use && this.VRRender()}
          <div className='pdn-top-option-panel-content'>
            <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-sub-body'>
              Compact VRM Model with Feedback
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={CVRM_MODEL}
              onChange={(e) => this.changeSelectValue(e, 'CVRM_MODEL')}
            />
          </div>
          {CVRM_MODEL && this.VRRender()}
          <Divider />
          {this.OptimizationRender()}
          {error && <span className='pdn-top-option-panel-error-msg'>Error: {error.error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const mapState = (state) => {
  const { PDNReducer: { pdn, project: { SPIMNames } } } = state;
  const pdnContent = pdn.pdnInfo ? pdn.pdnInfo.pdnContent : null;
  const libraryId = pdn.pdnInfo ? pdn.pdnInfo.libraryId : null;
  const currentVRM = SPIMNames.filter(item => item.id === libraryId)
  const currentVRMInfo = currentVRM.length > 0 ? currentVRM[0].children.filter(item => item.name === pdnContent.Name)[0].children : []
  return {
    pdnContent,
    currentVRMInfo
  }
}

const mapDispatch = (dispatch) => ({
  _optimizationChange(opt) {
    dispatch(optimizationChangeAction(opt));
  }
});

export default connect(mapState, mapDispatch)(SimulatePanel);