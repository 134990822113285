function getPanelMaxHeight(offset = {}, defaultHeight = 700) {
  const { height } = offset;
  if (!height) return defaultHeight;
  const _height = height * 0.9;
  return _height > 1300 ? 1300 : _height;
}

function getPanelMaxWidth(offset = {}, defaultWidth = 700) {
  const { width } = offset;
  if (!width) return defaultWidth;
  const _width = width * 0.9;
  return _width > 1800 ? 1800 : _width;
}

function getPanelWidth(maxWidth, setting = {}) {
  const { minWidth = 200, defaultWidth = 762 } = setting;
  if (!maxWidth) {
    return defaultWidth;
  }
  if (maxWidth >= defaultWidth) {
    return defaultWidth;
  } else if (maxWidth < minWidth) {
    return minWidth;
  }
  return maxWidth;
}

export {
  getPanelMaxHeight,
  getPanelMaxWidth,
  getPanelWidth
}