import { call, put, fork, takeEvery, select, delay, cancel, takeLatest } from 'redux-saga/effects';
import { UPDATE_LIBRARY_MENU, UPDATE_PROJECT_MENU, OPEN_PROJECT, CHANGE_VIEW, SHOW_RESULT, UPDATE_TRASH_MENU, CHANGE_VIEW_LIST, UPDATE_CURRENT_PROJECT, DOWNLOAD_DEBUG_MSG, REMOVE_PREPARE, SET_DEFAULT_DECAP, DEL_DEFAULT_DECAP, CREATE_ALL_PDN, GET_PDN_REPORT, PDN_CLEAR_REPORT_INFO, CLEAR_CURRENT_PROJECT, UPDATE_PCB_LAYOUT, AFTER_IMPORT_LIBRARY, START_LAYOUT_CHECK, GET_LAYOUT_ERROR_RESULT } from './actionTypes';
import { libraryMenu, projectMenu, saveOpenProjectInfo, changeView, trashMenu, changeTreeSelected, closeDebugDownloadMsg, debugDownloadLog, updatePreparePDNS, saveDefaultDecap, updateProjectMenu, updatePDNProgress, updatePDNReoprtMessage, updatePDNReportConfig, updatePDNReportVisible, changeViewList } from './action';
import { getLibraryList, getDefaultDecap, setDefaultDecap, getTouchstoneFileNameList, getLibraryFile } from '@/services/PDN/library';
import { getLibraryDataList } from '@/services/PDN/library/libraryData';
import { getProjectList, getPDNProjectPromise, getPDNTrashList, debugDownMsg, createPDNPromise, PDNInterface, VRM, getPDNContentPromise, updatePDNLibraryIdPromise, getPDNReportStatusPromise, getPDNReportData } from '@/services/PDN';
import { PROJECT, PCBs, Packages, PDNs, PCB, Package, PDN, RESULT, My_TRASH, IntelSPIM_FILE, DEFAULT_LIBRARY_ID } from '../../constants';
import { VERIFY_SUCCESS } from '@/constants/verificationStatus';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { changeProject, changeVerification, closeTabFooter, openTabFooter, changeTabMenu } from '../../../tabMonitor/action';
import {
  changeVerificationList,
  existResult,
  pdnCheckError,
  getCurrentPDNLog,
  pdnStackupCheckError,
  _getPDNWorkflow
} from '../simulation/action';
import { parseSPModelSelector } from '@/services/Library';
import { expandMenu, getPDNContent, getNets } from '../pdn/action';
import { getVRMModelUpdate } from '../simulation/action'
import { message } from 'antd';
import FileSaver from 'file-saver';
import { getBlob } from '@/services/helper/downloadHelper';
import { strDelimited } from '@/services/helper/split';
import { FASTPI_SETUP_VERSION } from '@/version';
import LayoutData from '@/services/data/LayoutData';
import designConstructor from '@/services/helper/designConstructor';
import projectDesigns from '@/services/helper/projectDesigns';
import { getDecapValue } from '../result/action'
import { SPIM_UNAVAILABLE } from '@/constants/librarySPIMStatus'
import { PCB_ONLY, PCB_TOP_BOTTOM, PCB_LEFT_RIGHT } from '../../../../constants/layoutConstants';
import { updateTreeAfterChangePCBLayout } from '../../../../services/helper/filterHelper';
import { defaultTreeItems } from '../../../../services/PDN/library/defaultTreeItem';
import { changeLayoutDesign, saveCurrentProjectPCB, saveLayoutInfo, saveSimDesigns, startLayoutSimulation, updateLayoutResult } from '../../../LayoutExplorer/LayoutStore/action';
import { FASTPI } from '../../../../constants/pageType';
import { LAYOUT_RUNNING } from '../../../../constants/workflowStatus';
import { getLayoutCheckResult } from '../../../../services/api/Design/design';

let reportTask = null;
const WARNING_PDN = 'warning_pdn';

function* _updateProjectMenu(action) {
  const { PDNReducer: { project, pdn } } = yield select();
  let { treeItems, currentProjectId } = project;
  let res = [];
  try {
    res = yield call(getProjectList);
  } catch (error) {
    console.error(error);
  }

  //get open project data
  const { obj } = action;
  let openProjectId = '';
  let openProjectData = [];
  if (obj && obj.openProjectId) {
    openProjectId = obj.openProjectId;
  } else if (obj && obj.isImport && res.length) {
    // import project, open the last project
    openProjectId = res[0].id
    obj.openProjectId = openProjectId;
  } else {
    openProjectId = currentProjectId;
  }
  let _openProject = treeItems[1].children.filter(item => item.id === openProjectId)
  openProjectData = _openProject && _openProject.length > 0 ? _openProject[0].children : null;
  // treeItems[1] - 'projects'
  treeItems[1].children = res.map(item => ({
    id: item.id,
    name: item.name,
    key: PROJECT + '-' + item.id,
    dataType: PROJECT,
    children: item.id === openProjectId && openProjectData ? openProjectData :
      [{
        name: 'PCB',
        key: PCBs + '-' + item.id,
        dataType: PCBs
      }, {
        name: 'Chip',
        key: Packages + '-' + item.id,
        dataType: Packages
      },
      {
        name: 'PDN',
        key: PDNs + '-' + item.id,
        dataType: PDNs
      }]
  }));

  let projectList = []
  res.forEach(item => {
    projectList.push({
      name: item.name,
      id: item.id
    })
  })
  yield put(projectMenu({ treeItems: [...treeItems], projectList }));

  if (obj && obj.openProjectId) {
    yield call(openProject, { id: obj.openProjectId, verificationName: obj.verificationName, pdnId: obj.pdnId });

    let { expandedKeys } = pdn;
    const key = `${PROJECT}-${obj.openProjectId}`;

    if (!expandedKeys.includes(key)) {
      expandedKeys = expandedKeys.filter(item => {
        const [_key, _id] = strDelimited(item, "-");

        if (!(_key === PROJECT && _id !== obj.openProjectId)) {
          return item;
        }
        return null;
      });
      expandedKeys.push(key);
    }
    yield put(expandMenu(expandedKeys));
  }
}

function* afterImportLibrary(action) {
  // clear the treeItem of the library
  const { PDNReducer: { project: { treeItems } } } = yield select();
  const defaultLibraryMenu = defaultTreeItems.find(item => item.key === 'library')
  let _treeItems = treeItems.map(item => {
    if (item.key === 'library') {
      return JSON.parse(JSON.stringify(defaultLibraryMenu))
    }
    return item
  })
  yield put(libraryMenu({ treeItems: [..._treeItems] }));
  // update new library Menu
  yield call(updateLibraryMenu)
}

function* updateLibraryMenu() {
  let res = null;
  try {
    res = yield call(getLibraryList);
  } catch (error) {
    console.error(error);
    return;
  }
  const { PDNReducer: { project } } = yield select();
  const { treeItems } = project;
  // treeItems[0] - 'library', treeItems[1] - 'projects'
  let SPIMNames = [], DecapNames = [], VRMNames = [];
  let newData = [...treeItems];
  const treeIndex = 0, SPIMIndex = 0, VRMIndex = 1, decapIndex = 2, TouchstoneIndex = 0, SPICEIndex = 1, RLCIndex = 2;
  newData[treeIndex].children[SPIMIndex].children = [];
  if (res.Intel_SPIM && res.Intel_SPIM.length > 0) {
    res.Intel_SPIM.forEach((item) => {
      let SPIMItem = getLibraryDataList({
        item: item.libraryStructure,
        id: item.id,
        type: 'SPIM',
        name: item.name
      });
      SPIMItem.id = item.id;
      SPIMItem.dataType = IntelSPIM_FILE;
      let _SPIMItem = { ...SPIMItem };
      if (_SPIMItem.displayChildren) {
        _SPIMItem.children = _SPIMItem.displayChildren
      }
      newData[treeIndex].children[SPIMIndex].children.push(_SPIMItem);
      SPIMNames.push(SPIMItem);
    })
  };
  newData[treeIndex].children[decapIndex].children[TouchstoneIndex].children = []
  newData[treeIndex].children[decapIndex].children[SPICEIndex].children = []
  newData[treeIndex].children[decapIndex].children[RLCIndex].children = []
  if (res.Decap && res.Decap.length > 0) {
    res.Decap.forEach((item) => {
      let index = SPICEIndex
      if (item.libraryFormat === 'data') {
        index = RLCIndex
      }
      const DecapItem = getLibraryDataList({
        item: item.libraryStructure,
        id: item.id,
        type: 'Decap',
        name: item.name
      }
      );
      newData[treeIndex].children[decapIndex].children[index].children.push(DecapItem);
      DecapNames.push(DecapItem);
    });
  };
  if (res.decap_touchstone && res.decap_touchstone.length > 0) {
    try {
      const response = yield call(getTouchstoneFileNameList);
      res.decap_touchstone.forEach((item) => {
        let touchstoneList = response.filter(it => it.userLibraryId === item.id);
        let DecapTouchstoneItem = getLibraryDataList({
          item: item.libraryStructure,
          id: item.id,
          type: 'DecapTouchstone',
          name: item.name,
          touchstoneChildren: touchstoneList[0] && touchstoneList[0].librarySubFileDTOS
        })
        newData[treeIndex].children[decapIndex].children[TouchstoneIndex].children.push(DecapTouchstoneItem);
        DecapNames.push(DecapTouchstoneItem)
      })
    } catch (error) {
      console.error(error)
    }
  }
  newData[treeIndex].children[VRMIndex].children = [];
  if (res.VRM && res.VRM.length > 0) {
    res.VRM.forEach((item) => {
      const VRMItem = getLibraryDataList({
        item: item.libraryStructure,
        id: item.id,
        type: 'VRM',
        name: item.name
      });
      const _VRMItem = {
        ...VRMItem,
        nodeClass: 'tree-node-vrm-name',
      };
      newData[treeIndex].children[VRMIndex].children.push(_VRMItem);
      VRMNames.push(VRMItem);
    });
  };
  yield put(libraryMenu({ treeItems: newData, SPIMNames, DecapNames, VRMNames }));

  let decap = undefined;
  try {
    decap = yield call(getDefaultDecap)
  } catch (err) {
    console.error(err);
  }
  let _decap = DecapNames.find(decapName => decapName.type === 'file');
  if (decap && decap.id) {
    let res = null;
    try {
      res = yield call(getLibraryFile, decap.id);
    } catch (error) {
      console.error(error);
    }
    if (res) {
      let { models: subckts, type } = parseSPModelSelector(res);
      yield put(saveDefaultDecap({ id: decap.id, subckts, type }));
    }
  } else if (_decap && _decap.id) {
    let id = _decap.id;
    let getLibraryFileRes = null;
    if (id) {
      try {
        yield call(setDefaultDecap, id);
        getLibraryFileRes = yield call(getLibraryFile, id);
      } catch (error) {
        console.error(error);
      }
      let { models: subckts, type } = parseSPModelSelector(getLibraryFileRes);
      yield put(saveDefaultDecap({ id, subckts, type }));
    }
  }
}

function* openProject(action) {
  const { PDNReducer: { project } } = yield select();
  let { treeSelectedKeys } = project;
  const { id, pdnId, verificationName } = action;

  let pdns = [], name = "";
  try {
    const res = yield call(projectInfo, { id });
    if (res) {
      pdns = res.pdns;
      name = res.name;
    }
  } catch (error) {
    console.error(error);
    return;
  }
  yield put(changeProject(name));
  const { PDNReducer: { PDNUploadReducer: { disabled, uploadProjectId } } } = yield select();
  if (disabled && uploadProjectId === id) {
    yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'upload', verificationId: null, projectId: id }));
    yield put(openTabFooter());
  }
  let pdnName = verificationName;
  let verificationId = null;
  pdns.forEach(pdn => {
    if (pdn.id === pdnId) {
      pdnName = pdn.name;
      verificationId = pdn.verificationId
    }
  })

  if (verificationId && (treeSelectedKeys.includes(`${PDN}-${pdnId}`) || treeSelectedKeys.includes(`${RESULT}-${pdnId}-${verificationId}`))) {
    yield put(changeTabMenu({
      selectKeys: ['monitor'],
      menuType: 'simulation',
      verificationId,
      projectId: id,
      verificationName: pdnName
    }));
    if (treeSelectedKeys.includes(`${PDN}-${pdnId}`)) {
      yield put(openTabFooter());
    }
    yield put(_getPDNWorkflow(verificationId));
  }
  yield put(changeVerificationList([]));
}

function* viewChange(action) {
  const { view, verificationId } = action;
  if ([PDN, RESULT].includes(view) && verificationId) {
    yield put(_getPDNWorkflow(verificationId));
  }
}

function* showResult(action) {
  const { view, PDNID, verificationId } = action;
  const { PDNReducer: { project: { currentProjectId } } } = yield select();
  let resultExist = false;
  try {
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
  } catch (error) {
    console.error(error)
  }
  yield put(existResult(resultExist));
  yield put(getDecapValue(currentProjectId, PDNID, verificationId))
  yield put(changeView(view, { PDNID, verificationId }))
}

function* updateTrashMenu() {
  let res = [];
  try {
    res = yield call(getPDNTrashList);
  } catch (error) {
    console.error(error);
  }
  const { PDNReducer: { project } } = yield select();
  const { treeItems } = project;
  let newData = [...treeItems];
  newData[2].children = [];

  if (res.length < 1) {
    newData[2].children = [];
  } else {
    res.forEach(item => {
      newData[2].children.push({
        name: item.name,
        key: `project-${item.id}`,
        id: item.id,
        dataType: My_TRASH
      });
    });
  }
  yield put(trashMenu({ treeItems: [...newData] }));
}

// TODO 
function* _changeViewList(action) {
  if (!action.viewType) {
    return;
  }
  const { viewList, viewType } = action;
  const { PDNReducer: { project, pdn: { expandedKeys } } } = yield select();
  const { verificationId, PDNID, designID, treeSelectedKeys, currentProjectPDNs, currentProjectId } = project;
  if (viewList.includes(viewType)) {
    let newKeys = [...treeSelectedKeys];

    if (viewType === PCB) {
      if (!expandedKeys.includes(`PCBs-${currentProjectId}`)) {
        yield put(expandMenu([...expandedKeys, `PCBs-${currentProjectId}`]));
      }
      const designIds = projectDesigns.getAvailableDesignIds(currentProjectId)
      if (designIds.length === 0) {
        if (viewList.length === 1) {
          yield put(closeTabFooter());
        }
        newKeys = newKeys.filter(item => item !== PCB)
        yield put(changeTreeSelected([...newKeys]));
        yield put(changeView(PCB));
        return;
      }
      if (viewList.length === 1) {
        yield put(closeTabFooter());
        newKeys = [`${PCB}-${designIds[0]}`];
      } else {
        newKeys.push(`${PCB}-${designIds[0]}`);
      }

      yield put(changeTreeSelected([...newKeys]));
      yield put(changeView(PCB, { designId: designIds[0] }));

    } else {
      if (viewType === RESULT) {
        yield put(changeView('result', { PDNID, verificationId }));
      }
      if (viewType === PDN) {
        if (!expandedKeys.includes(`PDNs-${currentProjectId}`)) {
          yield put(expandMenu([...expandedKeys, `PDNs-${currentProjectId}`]));
        }
        if (!verificationId && !PDNID && currentProjectPDNs.length === 0) {
          if (viewList.length === 1) {
            newKeys = [];
          }
          yield put(changeTreeSelected([...newKeys]));
          yield put(changeView('PDN'));
          return;
        }
        let openVerificationId, openPdnId, pdnName;
        if (verificationId && PDNID) {
          const currentPdn = currentProjectPDNs.find(item => item.id === PDNID && item.verificationId === verificationId);
          if (currentPdn) {
            openVerificationId = verificationId;
            openPdnId = PDNID;
            pdnName = currentPdn.name;
          } else {
            openVerificationId = currentProjectPDNs[0].verificationId;
            openPdnId = currentProjectPDNs[0].id;
            pdnName = currentProjectPDNs[0].name;
          }
        } else {
          openVerificationId = currentProjectPDNs[0].verificationId;
          openPdnId = currentProjectPDNs[0].id;
          pdnName = currentProjectPDNs[0].name;
        }

        if (viewList.length === 1) {
          newKeys = [`PDN-${openPdnId}`];
        } else {
          newKeys.push(`PDN-${openPdnId}`);
        }

        yield put(changeTreeSelected([...newKeys]));
        yield put(changeView('PDN', { verificationId: openVerificationId, PDNID: openPdnId }));
        yield put(pdnCheckError(null));
        yield put(pdnStackupCheckError(null));
        yield put(getPDNContent(openPdnId));
        yield put(getVRMModelUpdate({ currentProjectId, PDNID: openPdnId }));
        yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: openVerificationId, projectId: currentProjectId }));
        yield put(changeVerification(pdnName));
        yield put(openTabFooter());
        yield put(getCurrentPDNLog(openVerificationId))
      }
    }
  } else {
    let newKeys = [...treeSelectedKeys];
    newKeys.forEach((item, index) => {
      const [key] = strDelimited(item, "-");

      if (key === viewType) {
        newKeys.splice(index, 1);
      }
    });
    yield put(changeTreeSelected([...newKeys]));

    if (viewList.length < 1) {
      yield put(changeView('', {}));
      yield put(closeTabFooter());
    } else {

      if (viewType === 'PCB') {

        if (viewList[0] === 'PDN') {
          yield put(changeView('PDN', { verificationId, PDNID }));
        } else if (viewList[0] === 'result') {
          yield put(changeView('result', { PDNID, verificationId }));
        }
      } else {
        // TODO - remove designID
        yield put(changeView('PCB', { designId: designID }));
        yield put(closeTabFooter());
      }
    }
  }
}

function* updateCurrentProject(action) {
  const { id } = action;
  yield call(projectInfo, { id })
}

function* projectInfo(action) {
  const { PDNReducer: { project } } = yield select();
  let { treeItems, SPIMNames } = project;
  const { id } = action;

  yield put(saveLayoutInfo({ designId: "", simulationDesigns: [], projectLayout: [] }))

  let _treeItems = [...treeItems];
  let pkgLibraryId = '';
  // treeItems[1] - 'projects'
  const findIndex = _treeItems[1].children.findIndex(item => item.id === id);
  let res = null;
  try {
    res = yield call(getPDNProjectPromise, id);
  } catch (error) {
    console.error(error)
    return;
  }
  const designs = res.designs.map(item => ({
    id: item.id,
    key: PCB + '-' + item.id,
    name: item.name,
    dataType: PCB,
    vendor: item.vendor,
    nodeClass: 'tree-node-pcb-name',
    category: item.category,
    layoutCheck: item.layoutCheck
  }));
  designConstructor.addDesigns(designs);
  projectDesigns.set(id, designs);
  yield put(saveCurrentProjectPCB(designs));
  const layoutRunning = designs.filter(item => item.layoutCheck === LAYOUT_RUNNING).map(item => item.id);
  yield put(saveSimDesigns(layoutRunning));
  if (designs.length && designs[0].id) {
    yield put(changeLayoutDesign(designs[0].id));
  }
  const pkgs = res.packages.map(item => {
    pkgLibraryId = item.libraryId;
    return {
      id: item.id,
      key: Package + '-' + item.id,
      name: item.model ? (item.chip + ' - ' + item.model) : item.chip,
      dataType: Package,
      chip: item.chip,
      model: item.model,
      libraryId: item.libraryId,
      nodeClass: 'tree-node-pkg-name',
    }
  });

  const pdns = res.pdns.map(item => {
    let libraryId = item.libraryId;
    if (item.libraryId === DEFAULT_LIBRARY_ID) {
      if (pkgLibraryId) {
        let SPIM = SPIMNames.filter(it => it.id === pkgLibraryId);
        let children = SPIM[0] ? SPIM[0].children : [];
        if (children && children.length > 0) {
          for (let i = 0; i < children.length; i++) {
            if (item.name === children[i].name) {
              libraryId = pkgLibraryId;
            }
          }
        }
      }
    }

    return {
      id: item.id,
      key: PDN + '-' + item.id,
      name: item.name,
      dataType: PDN,
      verificationId: item.verificationId,
      result: item.result,
      historyExist: item.historyExist,
      libraryId: libraryId,
      nodeClass: 'tree-node-pdn-name',
      children: [{
        name: 'Result',
        key: RESULT + '-' + item.id + '-' + item.verificationId,
        dataType: RESULT,
        nodeClass: 'tree-node-pdn-result-name',
      }],
      status: (pkgLibraryId && libraryId === pkgLibraryId) ? PDN : WARNING_PDN
    }
  });

  if (findIndex > -1) {
    _treeItems[1].children[findIndex].children = [{
      name: 'PCB',
      key: PCBs + '-' + id,
      dataType: PCBs,
      children: designs,
      iconDisabled: projectDesigns.getAvailableDesignsLength(id) >= 1 ? true : false
    }, {
      name: 'Chip',
      key: Packages + '-' + id,
      dataType: Packages,
      children: pkgs,
      iconDisabled: pkgs.length >= 1 ? true : false
    },
    {
      name: 'PDN',
      key: PDNs + '-' + id,
      dataType: PDNs,
      children: pdns
    }];
  }
  yield put(projectMenu({ treeItems: [..._treeItems] }));
  let name = "";
  if (findIndex > -1) {
    name = _treeItems[1].children[findIndex].name;
  }
  yield put(saveOpenProjectInfo({ id, name, designs, pdns, packages: pkgs }));
  return { pdns, name }
}

function* downloadDebugLog(action) {
  const { project, fileName, verificationId, downloadType } = action;
  let ready = false, first = true, count = 0;
  while (!ready) {
    count++;
    if (!first) {
      yield delay(2000);
    };
    if (first) {
      first = false;
    };
    if (count > 90) {
      // time out
      return;
    }
    // { data: [], ready: true }
    let response = undefined;
    try {
      response = yield call(debugDownMsg, verificationId, downloadType);
    } catch (err) {
      console.error(err);
    }
    // debugDownloadMsg: { verificationId: { downloadType: { data: [], ready: true } } }
    let { PDNReducer: { project: { debugDownloadMsg } } } = yield select();
    if (!debugDownloadMsg) {
      debugDownloadMsg = [];
    };
    if (!debugDownloadMsg[verificationId]) {
      debugDownloadMsg[verificationId] = {}
    };
    const findIndex = debugDownloadMsg.findIndex(item => item.verificationId === verificationId && item.downloadType === downloadType);
    if ((!response || (response && !response.data)) && count > 2) {
      message.error('Can not get download log.');
      if (!debugDownloadMsg.length) {
        yield delay(2000);
        yield put(closeDebugDownloadMsg())
      }
      return;
    }
    if (response && response.data) {
      ready = response.ready;
      if (findIndex > -1) {
        debugDownloadMsg[findIndex] = { ...debugDownloadMsg[findIndex], ...response };
      } else {
        const data = { project, fileName, verificationId, downloadType, ...response, show: true };
        debugDownloadMsg.push(data);
      }
      yield put(debugDownloadLog(debugDownloadMsg));
    };
  }
}

function* removePreparing(action) {
  const { PDNReducer: { project: { preparingPDN }, pdn: { pdnInfo } } } = yield select();
  let PDNS = [];
  if (action.id) {
    PDNS = preparingPDN.filter(id => id !== action.id);
  } else {
    PDNS = preparingPDN.filter(id => id !== pdnInfo.PDNID)
  }
  yield put(updatePreparePDNS(PDNS));
}

function* _setDefaultDecap(action) {
  const id = action.libraryId;
  if (id) {
    let res = null;
    try {
      yield call(setDefaultDecap, id);
      res = yield call(getLibraryFile, id);
    } catch (error) {
      console.error(error);
      message.error("Set default decap error");
      return;
    }
    let { models: subckts, type } = parseSPModelSelector(res);
    yield put(saveDefaultDecap({ id, subckts, type }));
  }
}

function* _delDefaultDecap() {
  try {
    yield call(setDefaultDecap, "");
    yield put(saveDefaultDecap({}));
  } catch (error) {
    console.error(error);
  }
}

function* _createAllPDN(action) {
  const { PDNs, pkgInfo, SPIM } = action;
  const { PDNReducer: { project, pdn } } = yield select();
  const { currentProjectId, PDNID } = project;
  const { expandedKeys } = pdn;
  const _libraryId = pkgInfo ? pkgInfo.libraryId : '';
  const _projectId = pkgInfo ? pkgInfo.projectId : '';
  let netList = [], pdnData = [];
  const _designId = projectDesigns.getAvailableDesignsFirstId(currentProjectId)

  netList = LayoutData.getLayout(_designId).mNetManager.mNetList;
  if (!_libraryId) return;
  // auto update pdns
  const updateList = yield call(updatePDNs, { PDNs, libraryId: _libraryId, SPIM });
  // auto create pdns
  const createList = yield call(createPDNs, { _designId, _libraryId, _projectId });
  pdnData = [...updateList, ...createList];
  yield put(pdnCheckError(null));
  yield put(pdnStackupCheckError(null));

  const openPDNs = expandedKeys.find(item => item === `PDNs-${currentProjectId}`);
  if (!openPDNs) {
    expandedKeys.push(`PDNs-${currentProjectId}`);
  }
  yield put(expandMenu(expandedKeys));

  yield put(updateProjectMenu({ openProjectId: currentProjectId, pdnId: PDNID }));

  // pdn to select nets
  yield* pdnData.map(function* (data) {
    const { pdnInfo, name, seiectType } = data;
    yield put(getNets({ netList, PDN: { pdnInfo, pkgInfo }, name, seiectType }));
  })
}


function* updatePDNs(action) {
  const { PDNs, SPIM, libraryId } = action;
  const { PDNReducer: { project } } = yield select();
  const { SPIMNames, preparingPDN } = project;
  let prepare = [...preparingPDN], pdnData = [];
  if (PDNs) {
    let PDNList = [];
    if (SPIM) {
      // Determine whether the old PDNs exist in the new package
      let current = SPIMNames.find(item => item.id === SPIM);
      if (current) {
        current.children.forEach(item => {
          PDNs.forEach(it => {
            if (it.name === item.name) {
              if (item.status !== SPIM_UNAVAILABLE) {
                PDNList.push(it);
              }
            }
          })
        })
      }
    } else {
      PDNList = [...PDNs];
    }

    if (PDNList.length > 0) {
      // update pdn
      yield* PDNList.map(function* (PDN) {
        const _id = PDN.id;
        // get pdn info
        let res = {};
        try {
          res = yield call(getPDNContentPromise, _id);
          const { id, pdnContent, designId, name, projectId, pdnVersion, verificationId, ifDoExtraction } = res;
          //update libraryId of pdn
          yield call(updatePDNLibraryIdPromise, { pdnId: id, libraryId });
          let pdnInfo = {
            PDNID: id,
            pdnContent: JSON.parse(JSON.stringify(pdnContent)),
            designId,
            projectId,
            pdnVersion,
            verificationId,
            ifDoExtraction,
            libraryId
          }
          pdnData.push({ pdnInfo, name, selectType: 'update' });
          // add current pdn to perparing list
          prepare.push(id);
          yield put(updatePreparePDNS(prepare));
        } catch (error) {
          console.error(error);
        }
      })
    }
  }
  return pdnData;
}

function* createPDNs(action) {
  const { PDNReducer: { project } } = yield select();
  const { _libraryId, _designId, _projectId } = action;
  const { SPIMNames, currentProjectPDNs, preparingPDN } = project;
  let prepare = [...preparingPDN], pdnData = [];
  const libraryInfo = SPIMNames.find(item => item.id === _libraryId);
  const exsitPDN = currentProjectPDNs.map(item => item.name);
  let children = libraryInfo && libraryInfo.children ? libraryInfo.children : [];
  // remove exist pdn
  children = children.filter(item => !exsitPDN.includes(item.name))
  children = children.filter(item => item.status !== SPIM_UNAVAILABLE)
  let _pdnVersion = FASTPI_SETUP_VERSION;
  yield* children.map(function* (item) {
    const pdnName = item.name;
    let _pdnContent = new PDNInterface(pdnName, true);
    _pdnContent.VRM.push(new VRM());
    // create empty pdn
    let res = null;
    try {
      res = yield call(createPDNPromise, { designId: _designId, pdnContent: _pdnContent, pdnName, projectId: _projectId, pdnVersion: _pdnVersion, libraryId: _libraryId });
    } catch (error) {
      console.error(error);
    }
    if (res) {
      const { id, pdnContent, designId, name, projectId, pdnVersion, verificationId, ifDoExtraction, libraryId } = res;
      let pdnInfo = {
        PDNID: id,
        pdnContent,
        designId,
        projectId,
        pdnVersion,
        verificationId,
        ifDoExtraction,
        libraryId
      };
      pdnData.push({ pdnInfo, name, selectType: 'create' });
      // add current pdn to perparing list
      prepare.push(id);
      yield put(updatePreparePDNS(prepare));
    }
  })
  return pdnData;
}

function* _getPDNReport(action) {
  const { projectId, format, mime, fileName } = action;
  reportTask = yield fork(_getPDNReportFlow, { projectId, format, mime, fileName });
}

function* _getPDNReportFlow(action) {
  const { projectId, format, mime, fileName } = action;
  let new_fileName;
  let new_mime = mime
  if (format === 'pptx') {
    new_fileName = `${fileName}_report.pptx`
  } else if (format === 'pdf') {
    new_fileName = `${fileName}_report.pdf`
  }
  let status = 'running';
  let progress = 0;
  while (status === 'running') {
    yield delay(3000);
    try {
      const res = yield call(getPDNReportStatusPromise, projectId);
      status = res.status;
      progress = res.progress;
      const fileType = res.fileType
      if (progress <= 97) {
        yield put(updatePDNProgress(progress));
      }
      if (fileType === 'zip') {
        new_fileName = `${fileName}_report.zip`;
        new_mime = 'application/zip'
      }
    } catch (error) {
      status = 'failed';
    }
  }
  if (status === 'failed') {
    yield put(updatePDNReportVisible(false))
    yield put(updatePDNReoprtMessage(`Download report in ${format.toUpperCase()} format failed!`));
  } else {
    yield put(updatePDNProgress(98));
    yield call(_getPDNReportFileByFormat, { projectId, format, mime: new_mime, fileName: new_fileName });
  }
}

function* _getPDNReportFileByFormat(action) {
  const { projectId, format, mime, fileName } = action;
  const url = yield call(getPDNReportData, projectId, format.toUpperCase(), mime);
  if (url) {
    const blob = yield call(getBlob, url);
    const { PDNReducer: { project: { currentProjectId } } } = yield select();
    if (currentProjectId !== projectId) {
      return;
    }
    if (blob) {
      FileSaver.saveAs(blob, fileName);
    }
    yield put(updatePDNProgress(100));
    yield put(updatePDNReoprtMessage(`Download report in ${format.toUpperCase()} format successfully!`));
  } else {
    yield put(updatePDNReoprtMessage(`Download report in ${format.toUpperCase()} format failed!`));
  }
  yield delay(1000);
  yield put(updatePDNReportVisible(false));
  yield put(updatePDNProgress(0));
}

function* _clearReportInfo() {
  if (reportTask) {
    yield cancel(reportTask);
  }
  yield put(updatePDNReportVisible(false));
  yield put(updatePDNProgress(0));
  yield put(updatePDNReoprtMessage(""));
  yield put(updatePDNReportConfig(""))
}

function* _updatePCBLayout(action) {
  const { pcbLayout, prePcbLayout } = action;
  // prePcbLayout b,c -> a: close PCB
  const { PDNReducer: { project } } = yield select();
  let { viewList, treeSelectedKeys } = project;
  if (pcbLayout === PCB_ONLY && [PCB_TOP_BOTTOM, PCB_LEFT_RIGHT].includes(prePcbLayout)) {
    const data = updateTreeAfterChangePCBLayout(treeSelectedKeys, viewList);
    if (data) {
      const { _selectedKeys, _viewList } = data;
      yield put(changeTreeSelected(_selectedKeys));
      yield put(changeViewList(_viewList));
    }
  }
}

function* startLayoutCheck(action) {
  const { designItem } = action;
  const { PDNReducer: { project: { openProjectId } } } = yield select();
  yield put(changeTabMenu({
    selectKeys: ['layout'],
    menuType: 'simulation',
    projectId: openProjectId,
  }));
  yield put(openTabFooter());
  const { id } = designItem;
  yield put(startLayoutSimulation(id, FASTPI))
}

function* getPDNLayoutResult(action) {
  const { designId } = action;
  try {
    const { LayoutReducer: { results } } = yield select();
    if (!results[designId]) {
      const result = yield call(getLayoutCheckResult, designId)
      yield put(updateLayoutResult(designId, result))
    }
  } catch (error) {
    console.error("Get layout error result failed: ", error)
  }
}

function* projectSaga() {
  yield takeEvery(UPDATE_LIBRARY_MENU, updateLibraryMenu);
  yield takeEvery(UPDATE_PROJECT_MENU, _updateProjectMenu);
  yield takeEvery(OPEN_PROJECT, openProject);
  yield takeEvery(CHANGE_VIEW, viewChange);
  yield takeEvery(SHOW_RESULT, showResult);
  yield takeEvery(UPDATE_TRASH_MENU, updateTrashMenu);
  yield takeEvery(CHANGE_VIEW_LIST, _changeViewList);
  yield takeEvery(UPDATE_CURRENT_PROJECT, updateCurrentProject);
  yield takeEvery(DOWNLOAD_DEBUG_MSG, downloadDebugLog);
  yield takeEvery(REMOVE_PREPARE, removePreparing);
  yield takeEvery(SET_DEFAULT_DECAP, _setDefaultDecap);
  yield takeEvery(DEL_DEFAULT_DECAP, _delDefaultDecap);
  yield takeEvery(CREATE_ALL_PDN, _createAllPDN);
  yield takeEvery(GET_PDN_REPORT, _getPDNReport);
  yield takeEvery(PDN_CLEAR_REPORT_INFO, _clearReportInfo);
  yield takeLatest(UPDATE_PCB_LAYOUT, _updatePCBLayout);
  yield takeEvery(AFTER_IMPORT_LIBRARY, afterImportLibrary);
  yield takeEvery(START_LAYOUT_CHECK, startLayoutCheck);
  yield takeEvery(GET_LAYOUT_ERROR_RESULT, getPDNLayoutResult)
}

export default projectSaga;