import React, { createRef } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Table, Input, InputNumber, Select, Spin, AutoComplete, Form } from 'antd';
import VirtualList from '../VirtualList';
import { changeUnit } from '../../services/helper/RLCValue';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AuroraTreeSelect from './treeSelect';
import _ from 'lodash';
import './EditableTable.css';

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;
const EditableContext = React.createContext();

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};
const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};
let dragingIndex = -1;

const DragRow = (props) => {
  const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = props;
  const style = { ...restProps.style, cursor: 'move' };

  let { className } = restProps;
  if (isOver) {
    if (restProps.index > dragingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < dragingIndex) {
      className += ' drop-over-upward';
    }
  }
  const [form] = Form.useForm()

  return <EditableContext.Provider value={form}>
    {
      connectDragSource(
        connectDropTarget(
          <Form form={form} component={false}>
            <tr {...restProps} className={className} style={style} />
          </Form>
        )
      )
    }
  </EditableContext.Provider>
}

// const EditDragableRow = DropTarget('row', rowTarget, (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
// }))(
//   DragSource('row', rowSource, connect => ({
//     connectDragSource: connect.dragSource(),
//   }))(DragRow),
// );


export const EditableFormRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return <EditableContext.Provider value={form}>
    <Form form={form} component={false}>
      <tr {...props} />
    </Form>
  </EditableContext.Provider>
};


export class EditableCell extends React.Component {
  constructor(props) {
    super(props);
    this.options = []
    this.state = {
      editing: false,
      loading: false,
    }
    this.selectRef = createRef();
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.options = [];
    this.setState({ editing, loading: false }, () => {
      if (editing) {
        const { dataIndex, record } = this.props;
        const value = this.form.getFieldValue(dataIndex);
        const propsValue = record[dataIndex];
        if (!_.isEqual(value, propsValue)) {
          this.form.setFieldValue(dataIndex, propsValue)
        }
      }

      if (editing && this.input) {
        this.input.focus();
      }

      if (editing && this.selectRef) {
        this.selectRefPoint(true);
      }
    });
  }

  selectRefPoint = (focus) => {
    if (this.selectRef && this.selectRef.current) {
      if (focus) {
        this.selectRef.current.focus && this.selectRef.current.focus();
      } else {
        this.selectRef.current.blur && this.selectRef.current.blur();
      }
      if (this.selectRef.current && this.selectRef.current.rcSelect && this.selectRef.current.rcSelect.inputRef) {
        if (focus) {
          this.selectRef.current.rcSelect.inputRef.focus && this.selectRef.current.rcSelect.inputRef.focus();
        } else {
          this.selectRef.current.rcSelect.inputRef.blur && this.selectRef.current.rcSelect.inputRef.blur();
        }
      }
    }
  }

  clearInputValue = () => {
    this.setState({
      value: ''
    }, () => {
      this.props.onSearch && this.props.onSearch('')
    })
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.save();
    }
  }

  save = (saveCheck = true) => {
    const { record, handleSave, netSelect, clearSelectStatus, auroraSelectBlur, closeColumnVisible, dataIndex } = this.props;
    if (netSelect && clearSelectStatus) {
      clearSelectStatus();
    }
    if (auroraSelectBlur) {
      auroraSelectBlur(record);
    }
    this.toggleEdit();
    if (saveCheck) {
      const value = this.form.getFieldValue(dataIndex)
      if (record[dataIndex] !== value) {
        handleSave && handleSave({ ...record, [dataIndex]: value }, record);
      }
    }
    closeColumnVisible && closeColumnVisible()
    this.input = null;
  }

  loadData = (selectedOptions) => {
    if (selectedOptions === undefined) {
      this.setState({
        loading: true
      });
    }
    const result = this.props.load(selectedOptions);
    if (result.then === undefined) {
      this.options = result;
      this.setState({
        loading: false
      });
    } else {
      result.then((res) => {
        this.options = res;
        if (selectedOptions === undefined) {
          this.setState({
            loading: true
          });
        }
      })
    }
  }

  selectChange = (value) => {
    if (value) {
      const { selectMode, ModeStatus } = this.props;
      if (!selectMode || (selectMode && ModeStatus)) {
        this.selectRefPoint(false);
      }
      const { record, handleSave, selectType } = this.props;
      if (selectType === 'net') {
        this.form.setFieldValue('nets', value)
        handleSave({ ...record, nets: value }, record);
      } else if (selectType) {
        this.form.setFieldValue(selectType, value)
        handleSave({ ...record, [selectType]: value }, record);
      }
    }
  }

  selectBlur = (value) => {
    const { selectMode, ModeStatus } = this.props;
    if (!selectMode || (selectMode && ModeStatus)) {
      this.selectRefPoint(false);
    }
  }

  clearSelected = (e) => {
    const { record, handleClear, selectMode, ModeStatus, dataIndex } = this.props;
    if (!selectMode || (selectMode && ModeStatus)) {
      this.selectRefPoint(false);
    }
    if (handleClear) {
      handleClear({ ...record }, dataIndex);
      this.save(false)
    }
  }

  auroraTableSearch = (value) => {
    this.setState({
      value
    })
  }

  getInputNumber() {
    return (
      <InputNumber
        size='small'
        autoFocus
        min={1}
        max={1000}
        ref={node => (this.input = node)}
        onKeyDown={this.handleKeyDown}
        onBlur={this.save}
      />
    )
  }

  customSelectSave = () => {
    const { record, handleSave, netSelect, clearSelectStatus, dataIndex } = this.props;
    if (netSelect && clearSelectStatus) {
      clearSelectStatus();
    }
    this.setState({ editing: false, loading: false })
    const value = this.form.getFieldValue(dataIndex)
    handleSave && handleSave({ ...record, [dataIndex]: value }, record);
    this.input = null;
  }

  inputWithSelectSave = (values) => {
    const { record, handleSave } = this.props;
    this.setState({ editing: false, loading: false })
    handleSave && handleSave({ ...record, ...values });
  }

  getSelect() {
    let options = this.props.options || this.options || [];
    let selectMode = this.props.selectMode || null;
    const dropdownMenuClassName = this.props.dropdownMenuClassName || '';
    const popupMatchSelectWidth = this.props.popupMatchSelectWidth || false;
    //Remove duplication
    options = options[0] && typeof options[0] === "string" ? [...new Set(options)] : options;
    const { loading, value } = this.state;
    const { netSelect, loadingMsg, getPopupContainer, allowClear = false, optGroup,
      onFocus, showSearch, dropdownRender, record, getLabel, selected, displaySelectStatus, onblurNotSave, onSearch } = this.props;
    const { setFieldsValue } = this.form;
    return (netSelect ? <Select
      mode={selectMode}
      ref={this.selectRef}
      className="editTable-select"
      autoFocus
      style={{ width: '100%' }}
      showSearch={true}
      searchValue={value}
      onSearch={(value) => { this.auroraTableSearch(value); onSearch && onSearch(value) }}
      notFoundContent={loading ? <Spin size="small" /> : "No Result"}
      optionFilterProp="children"
      onBlur={this.save}
      defaultOpen={true}
      popupMatchSelectWidth={popupMatchSelectWidth}
      suffixIcon={null}
      onChange={this.selectChange}
      getPopupContainer={(trigger) => getPopupContainer || trigger.parentNode}
      onSelect={this.selectBlur}
      onDeselect={this.props.clearSelected ? (e) => { this.props.clearSelected(e, record, setFieldsValue) } : null}
      onInputKeyDown={(e) => this.props.onInputKeyDown ? this.props.onInputKeyDown(e, this.form, this.clearInputValue) : null}
      popupClassName={dropdownMenuClassName}
      onFocus={onFocus ? onFocus : null}
      dropdownRender={(menu) => dropdownRender(this.form, this.clearInputValue)}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
    </Select> : <Select
      mode={selectMode}
      ref={this.selectRef}
      autoFocus
      style={{ width: '100%' }}
      showSearch={onSearch || showSearch || options.length > 2}
      searchValue={value}
      onSearch={onSearch || showSearch || options.length > 2 ? (value) => { this.auroraTableSearch(value); onSearch && onSearch(value) } : null}
      notFoundContent={loading ? <Spin size="small" /> : (loadingMsg ? loadingMsg : "No Result")}
      optionFilterProp="children"
      onBlur={(value) => this.save(onblurNotSave ? false : true)}
      defaultOpen={true}
      popupMatchSelectWidth={popupMatchSelectWidth}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      suffixIcon={null}
      onChange={this.selectChange}
      getPopupContainer={(trigger) => getPopupContainer || trigger.parentNode}
      onSelect={this.selectBlur}
      className='select-width editTable-select'
      popupClassName={dropdownMenuClassName}
      onFocus={onFocus ? onFocus : null}
      onClear={this.clearSelected}
      allowClear={allowClear ? { clearIcon: <CloseCircleFilled onClick={(e) => { this.clearSelected(e) }} /> } : false}
      dropdownRender={menu => dropdownRender ? dropdownRender(menu, record) : menu}
      optionLabelProp={getLabel ? 'label' : 'value'}
    >
      {this.getSelectOptions(options, optGroup, getLabel, selected, displaySelectStatus)}
    </Select>);
  }

  getSelectOptions = (options, optGroup, getLabel, selected, displaySelectStatus) => {

    options = options.filter(item => !!item);
    if (displaySelectStatus) {
      return options.map((option, i) => (
        <Option
          value={option.key}
          key={option.key}
          title={option.title || option.key}
          label={option.title || option.key}
          disabled={option.disabled}
          className={option && option.selected ? "aurora-table-select-selected" : ""}
        >{option.title ? option.title : option.key}</Option>))
    }
    return options.map((option, i) => (optGroup && typeof (option) !== "string") ?
      <OptGroup key={i} label={option.group}>
        {option.children.map(it => (typeof (it) === "string" ? <Option
          key={`${it}::${option.group}`}
          value={`${it}::${option.group}`}
          className={selected && selected.id === it ? "ant-select-dropdown-menu-item-selected" : ""}
          title={it}
        >{it}</Option> :
          <Option
            key={`${it.id}::${it.name}::${option.group}`}
            value={`${it.id}::${it.name}::${option.group}`}
            className={selected && selected.id === it.id ? "ant-select-dropdown-menu-item-selected" : ""}
            title={it.name}
          >{it.name}</Option>))}
      </OptGroup>
      :
      (typeof (option) === "string"
        ? <Option value={option} key={i} title={option} label={getLabel ? getLabel(option) : option} >{option}</Option>
        : <Option
          value={option.key || option.value}
          key={option.key || option.value}
          title={option.title ? option.title : option.value || option.key}
          label={getLabel ? getLabel(option) : option.title || option.value || option.key}
          disabled={option.disabled}
        >{option.title ? option.title : option.value}</Option>)
    )
  }

  getAuroraSelect() {
    let options = this.props.options || this.options || [];
    let selectMode = this.props.selectMode || null;
    const dropdownMenuClassName = this.props.dropdownMenuClassName || '';
    //Remove duplication
    options = [...new Set(options)];
    const { allowClear = false, onFocus, record, dataIndex, onChange, closeList = false,
      getPopupContainer, selectButtonTitle, getPointNewOptions, enterSelect = false, errorCheck,
      allowStick = false, stickMatch, optGroup
    } = this.props;
    const { value } = this.state;
    const signalName = record ? record.name : '';
    const { setFieldsValue } = this.form;
    const optionNum = options ? (optGroup && optGroup.length ? options.length + optGroup.length : options.length) : 0;
    return (
      <Select
        mode={selectMode}
        ref={this.selectRef}
        autoFocus
        size='middle'
        style={{ width: '100%' }}
        showSearch={true}
        optionFilterProp="children"
        onBlur={this.save}
        open={true}
        suffixIcon={null}
        onChange={this.selectChange}
        getPopupContainer={(trigger) => getPopupContainer || trigger.parentNode}
        onSelect={this.selectBlur}
        onSearch={(value) => this.auroraTableSearch(value)}
        className='select-width editTable-select'
        popupClassName={`aurora-virtual-list ${dropdownMenuClassName ? dropdownMenuClassName : ''}`}
        dropdownStyle={optionNum < 6 ? { height: optionNum * 32, minHeight: 36 } : { height: 200 }}
        onFocus={onFocus ? onFocus : null}
        allowClear={allowClear ? { clearIcon: <CloseCircleFilled onClick={(e) => { this.clearSelected(e, record) }} /> } : false}
        onDeselect={(e) => { this.props.clearSelected(e, record, setFieldsValue) }}
        searchValue={value}
        dropdownRender={() => {
          return <VirtualList
            options={options}
            labels={this.props.labels || null}
            selectMode={selectMode}
            value={value}
            record={record}
            selected={record[dataIndex]}
            onChange={onChange ? onChange : this.selectChange}
            onFocus={onFocus ? onFocus : null}
            id={`edittable-aurora-select-dropdown-${signalName}`}
            save={this.save}
            closeList={closeList}
            clearInputValue={this.clearInputValue}
            setFieldsValue={setFieldsValue}
            selectButtonTitle={selectButtonTitle}
            getPointNewOptions={getPointNewOptions}
            enterSelect={enterSelect}
            errorCheck={errorCheck}
            allowStick={allowStick}
            auroraTableSearch={this.auroraTableSearch}
            stickMatch={stickMatch}
            optGroup={optGroup}
          />
        }}
      >
      </Select>
    );
  }

  getAutoComplete() {
    const { record, dataIndex, dataSource, onChange, onSearch, onSelect, onBlur, getPopupContainer } = this.props;
    return (
      <AutoComplete
        options={dataSource}
        autoFocus
        onChange={onChange ? (value) => { onChange(value, dataIndex, record) } : null}
        onSelect={onSelect ? (value) => { onSelect(value, dataIndex, record); this.save() } : null}
        onSearch={onSearch ? (value) => onSearch(value, dataIndex) : null}
        onBlur={onBlur ? () => { onBlur(dataIndex, record); this.save() } : this.save}
        defaultOpen={true}
        getPopupContainer={(trigger) => getPopupContainer || trigger.parentNode}
        className='editTable-auto-complete'
        popupClassName='editTable-auto-complete-dropdown'
      />
    );
  }

  getInputWithSelect() {
    const { record, unitList, ...restProps } = this.props;

    return <InputWithSelect
      {...restProps}
      record={record}
      save={this.inputWithSelectSave}
      zIndex={2000}
      unitList={unitList}
    />
  }

  treeSelectSave = ({ values }) => {
    const { handleSave, record } = this.props;
    this.setState({ editing: false, loading: false })
    handleSave && handleSave(values, { ...record });
  }

  getTreeSelect = () => {

    return <AuroraTreeSelect
      {...this.props}
      save={this.treeSelectSave}
    />
  }

  getInput() {
    const { edit, customInput: CustomInput, dataIndex, record } = this.props;
    if (CustomInput) {
      const { ...restProps } = this.props;
      return <CustomInput dataIndex={dataIndex} save={this.save} record={record} {...restProps} />
    } else {
      return edit === 'number' ? this.getInputNumber() :
        edit === 'select' ? this.getSelect() :
          edit === 'aurora-select' ? this.getAuroraSelect() :
            edit === 'auto-complete' ? this.getAutoComplete() :
              edit === 'input-with-select' ? this.getInputWithSelect() :
                edit === "tree-select" ? this.getTreeSelect() : (
                  <Input
                    size='small'
                    ref={node => (this.input = node)}
                    onPressEnter={this.save}
                    onBlur={this.save}
                    className='editable-input'
                    autoComplete='off'
                  />
                )
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { load } = nextProps;
    const { editing: nextEditing } = nextState;
    const { editing } = this.state;
    if (load !== undefined && !editing && nextEditing) {
      this.loadData();
      return false;
    }
    return true;
  }

  render() {
    const { editing } = this.state;
    const {
      edit,
      dataIndex,
      record,
      children,
      className = '',
      rowSpan,
      colSpan,
      tdClassName = '',
      tdClick,
      isAddonInput = false,
      customInput: CustomInput,
      ...restProps
    } = this.props;
    return (
      <td ref={node => (this.cell = node)}
        rowSpan={rowSpan || 1}
        colSpan={colSpan || 1}
        className={tdClassName}
        onClick={tdClick}
      >
        {edit ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (isAddonInput && CustomInput) ?
                  <CustomInput dataIndex={dataIndex} save={this.save} record={record} {...restProps} />
                  : (
                    <FormItem className="edit-form-item" name={dataIndex} initialValue={record[dataIndex] || record[dataIndex] === 0 ? record[dataIndex] : ""}>
                      {this.getInput()}
                    </FormItem>
                  ) : (
                  <div
                    className={`editable-cell-value-wrap ${className}`}
                    onClick={this.toggleEdit}
                  >
                    {children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : children}
      </td>
    );
  }
}

const CustomTable = ({ ...props }) => {
  return <div className={`aurora-table-body`}>
    <table {...props} />
  </div>
}

class EditableTable extends React.Component {

  render() {
    const { columns, dataSource, pagination, border, dragable, className, rowClassName, tablePadding, tableExpand, virtual = false, ...restProps } = this.props;
    const components = {
      table: CustomTable,
      body: {
        // row: dragable ? EditDragableRow : EditableFormRow,
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const _rowClassName = typeof rowClassName === 'string' ? `editable-row ${rowClassName}` : rowClassName;
    // return dragable ? <DndProvider backend={HTML5Backend}>
    //   <Table
    //     components={components}
    //     rowClassName={_rowClassName}
    //     dataSource={dataSource}
    //     columns={columns}
    //     size='small'
    //     className={`aurora-table ${className} ${tablePadding ? 'aurora-padding-table' : ''}`}
    //     bordered={border === undefined ? true : border}
    //     pagination={pagination === undefined ? false : pagination}
    //     virtual={virtual}
    //     onRow={(record, index) => ({
    //       index,
    //       moveRow: this.props.moveRow,
    //     })}
    //     {...restProps}
    //   />
    // </DndProvider> :
    return <Table
      components={components}
      rowClassName={_rowClassName}
      dataSource={dataSource}
      columns={columns}
      virtual={virtual}
      size='small'
      className={`aurora-table ${className} ${tablePadding ? 'aurora-padding-table' : ''} ${tableExpand ? 'aurora-expanded-table' : ''}`}
      bordered={border === undefined ? true : border}
      pagination={pagination === undefined ? false : pagination}
      {...restProps}
    />
  }
}

class InputWithSelect extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      inputVal: '',
      selectVal: '',
      comp_focus: true
    }
  }

  selectAfter = () => {
    let { unitList = [], zIndex } = this.props
    return <Select value={this.state.selectVal}
      className="input-select-after"
      popupClassName="input-select-list"
      onSelect={this.changeSelectVal}
      popupMatchSelectWidth={false}
      dropdownStyle={{ zIndex }}>

      {unitList.map(item => typeof item === 'string' ? <Option key={item} value={item}>{item}</Option> : <Option key={item.key} value={item.key}>{item.title}</Option>)}
    </Select>
  }

  clickHandler = (e) => {
    let current = document.querySelector('.input-with-select')
    let select = document.querySelector(".input-select-list")

    // Event.path will be remove from Chrome in future, description: https://bugs.chromium.org/p/chromium/issues/detail?id=1277431
    const eventPath = e.composedPath()
    if (!eventPath.includes(current) && !eventPath.includes(select)) {
      this.setState({
        comp_focus: false
      })
    } else {
      this.setState({
        comp_focus: true
      })
    }
  }

  componentDidMount = () => {
    const { record, defaultUnit = "", dataIndex, unit } = this.props;
    const value = record[dataIndex] || record['value'];
    const _unit = record['unit'] || unit;
    this.setState({
      inputVal: value,
      selectVal: _unit || (!value ? defaultUnit : "")
    })

    document.addEventListener('mousedown', this.clickHandler)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { save, allowEmpty = false } = this.props
    const { inputVal, selectVal } = this.state

    if (prevState.comp_focus && !this.state.comp_focus) {
      save({ value: inputVal || (allowEmpty ? "" : 0), unit: selectVal })
    }
  }

  componentWillUnmount = () => {
    const { save, allowEmpty = false } = this.props
    const { inputVal, selectVal, comp_focus } = this.state

    if (comp_focus) {
      save({ value: inputVal || (allowEmpty ? "" : 0), unit: selectVal })
    }

    document.removeEventListener('mousedown', this.clickHandler)
  }

  changeInputValue = (e) => {
    let newInputVal = e.target.value
    this.setState({
      inputVal: newInputVal
    })
  }

  changeSelectVal = (newUnit) => {
    let { inputVal, selectVal: oldUnit } = this.state;
    const { unitAddonAfter = "", decimals = -1 } = this.props;
    let newVal = newUnit === '%' || oldUnit === '%' ? '' : changeUnit(inputVal, `${oldUnit}${unitAddonAfter}`, `${newUnit}${unitAddonAfter}`, decimals)
    this.setState({
      selectVal: newUnit,
      inputVal: newVal
    })
  }

  render() {
    return (
      <div className='input-with-select' >
        <Input
          addonAfter={this.selectAfter()}
          value={this.state.inputVal}
          autoFocus={true}
          onChange={this.changeInputValue}
          onPressEnter={() => this.setState({ comp_focus: false })}
        />
      </div>
    )
  }

}

export default EditableTable;