import React, { Fragment, PureComponent } from "react";
import { createPortal } from 'react-dom';
import { Input, Checkbox } from 'antd';
import { SimulationOptions } from "../../../services/Himalayas/project/channel";
import Panel from "../../../components/Panel/Panel";
import { getPanelWidth, getPanelMaxWidth } from "../../../services/helper/panelSizeHelper";
import { checkSimulationOptions } from "../../../services/Himalayas/helper/errorCheck";


class SimulationOptionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      simOptions: new SimulationOptions(props.simOptions),
      maxWidth: 862,
      maxHeight: 810,
      channelId: props.channelId,
      error: [],
      allInterfaces: true
    }
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    const { error, simOptions, channelId, allInterfaces } = this.state;
    if (error.length) {
      return;
    }
    this.props.saveSimOptions(channelId, simOptions, allInterfaces);
    this.props.closePanel();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 862)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  changeValue = (e, type, currentType) => {
    e && e.stopPropagation();
    const value = e.target.value;
    this.setState({
      simOptions: {
        ...this.state.simOptions,
        [type]: currentType ? {
          ...this.state.simOptions[type],
          [currentType]: value
        } : value,
      },
      error: this.state.error.filter(err => err.type !== (currentType || type))
    })
  }

  inputBlur = (e, type, value, optionTitle, options) => {
    e && e.stopPropagation();
    const error = checkSimulationOptions(type, value, optionTitle, options);

    if (error) {
      let _error = [...this.state.error]
      const findIndex = _error.findIndex(item => item.type === type);
      if (findIndex > -1) {
        _error[findIndex].error = error;
      } else {
        _error = [..._error, { type, error }]
      }
      this.setState({
        error: _error
      })
    }
  }

  changeApplyAll = (e) => {
    e && e.stopPropagation();
    this.setState({
      allInterfaces: e.target.checked
    })
  }

  ioSimHspiceRender = () => {
    const { simOptions } = this.state;
    const { hspiceConfig } = simOptions;
    const { hspiceLicenses } = hspiceConfig;
    return <Fragment>
      <div className='himalayas-top-option-panel-content'>
        <span className='himalayas-top-option-panel-content-body'>Hspice</span>
      </div>
      <div className='himalayas-top-option-panel-content'>
        <span className='himalayas-top-option-panel-content-body himalayas-top-option-panel-sub-span'>Number of Hspice Licenses</span>
        <span><Input
          className='himalayas-top-option-panel-content-input'
          value={hspiceLicenses}
          onChange={(e) => this.changeValue(e, 'hspiceConfig', 'hspiceLicenses')}
          onBlur={(e) => this.inputBlur(e, 'hspiceLicenses', hspiceLicenses, 'The number of Hspice License', { isInteger: true })}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
    </Fragment>
  }

  footerRender = () => {
    const { allInterfaces } = this.state;
    return <div className='himalayas-top-option-panel-apply'>
      <span className='himalayas-top-option-panel-apply-title'>Apply setting to all Interfaces</span>
      <Checkbox
        checked={allInterfaces}
        onChange={(e) => this.changeApplyAll(e)}
      />
    </div>
  }

  render() {
    const { maxWidth, maxHeight, error } = this.state;
    const content = (
      <Panel
        className='himalayas-top-option-panel'
        title={<div className='himalayas-top-option-panel-name'>Simulation Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth)}
        footer={this.footerRender()}
        position='panel-center'
        mask={false}
        draggable
        minWidth={200}
        minHeight={100}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='himalayas-top-option-panel-main'>
          {this.ioSimHspiceRender()}
          {error.length ? <span className='himalayas-top-option-panel-error-msg'>Error: {error[0].error}</span> : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default SimulationOptionPanel;