import React, { PureComponent } from "react";
import { Button } from "antd";
import StackupMultiZones from './stackupMultiZones';
import '../index.css';
import '../multiZone.css';

class StackupMultiZoneButton extends PureComponent {

  constructor(props) {
    super(props);
  }

  openStackupZone = () => {
    const { designID, stackupZonePanelShow } = this.props;
    this.props._changeStackupZonePanelShow(!stackupZonePanelShow, designID);

    if (stackupZonePanelShow && this.zonePanelRef && this.zonePanelRef.clearShowZones) {
      //if close panel , clear zones display
      this.zonePanelRef.clearShowZones();
    }
  }

  panelClose = () => {
    const { designID } = this.props;
    this.props._changeStackupZonePanelShow(false, designID)
  }

  onRef = (ref) => {
    this.zonePanelRef = ref;
  }

  render() {
    const { width, leftWidth, stackupZonePanelShow, ...restProp } = this.props;
    return (
      <div className='stackup-multi-zone'>
        <div>
          <Button
            placeholder='Stackup'
            onClick={this.openStackupZone}
            size='small'
            type='primary'
          >Stackup</Button>
        </div>
        {stackupZonePanelShow && <StackupMultiZones
          onRef={this.onRef}
          cancel={this.panelClose}
          width={width}
          leftWidth={leftWidth}
          {...restProp} />}
      </div>
    )
  }
}

export default StackupMultiZoneButton; 