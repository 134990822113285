import React, { Component } from 'react';
import EditableTable from '../EditableTable/EditableTable';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CAP, IND, RES } from '../../constants/componentType';
import {
  N_PORT,
  getVCompModelText,
  getVCompModelPinList,
  getVCDefaultModel,
  VC_RLC_TYPES,
  SHUNT_R,
  SHUNT_C,
  SHUNT_L,
  VC_SHUNT_TYPES
} from '../../services/VirtualComponent';
import ChipModel from '../PackageModel_v1';
import { getCompModelText, getModelFileExist } from '../../services/helper/fileExistCheck';
import ValueEdit from './valueEdit';
import { SIERRA } from '../../constants/pageType';

const columns = [
  {
    title: 'Component',
    dataIndex: 'name',
    width: '12%',
  }, {
    title: 'Layer',
    dataIndex: 'layer',
    width: '10%',
  }, /* {
    title: 'Location',
    dataIndex: 'location',
    width: '30%'
  }, */
  {
    title: 'Signals',
    dataIndex: 'signals',
    width: '30%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: '8%'
  },
  {
    title: "Trace cut length",
    dataIndex: 'cutLength',
    width: '16%'
  },
  {
    title: "Model",
    dataIndex: 'model',
    width: '26%'
  }
];

class VirtualComponentTable extends Component {
  constructor(props) {
    super(props);

    columns[0].title = () => {
      const { componentList } = this.props;
      return (
        <div>
          <span>Component</span>
          {componentList.length ? <EditOutlined
            title="Open for viewing and editing"
            onClick={(e) => this.addVirtualComponent(e)}
            className={"virtual-component-view-selected-icon virtual-component-view-icon"} />
            : null}
        </div>
      );
    }
    //location
    /*  columns[2].render = (text, record) => {
       const locationList = (record.location || []).map(item => { return item[0] && item[1] ? `( ${item[0].toFixed(2)}, ${item[1].toFixed(2)} )` : "" }).filter(item => !!item);
       return <span>{locationList.join(", ")}</span>
     } */
    //signals
    columns[2].render = (text, record) => {
      const signals = (record.pins || []).filter(item => item.pin.match(/_1$/)).map(item => { return item.pin.replace(/_1$/, ""); });
      return <span>{signals.join(", ")}</span>
    }

    columns[4].title = () => {
      const { componentList } = this.props;
      const units = [...new Set(componentList.map(item => item.unit))]
      return <span>Trace cut length {units.length === 1 ? `(${units[0]})` : ''}</span>
    }

    columns[4].render = (text, record) => {
      const { cutLength, unit } = record;
      const { componentList } = this.props;
      if (VC_SHUNT_TYPES.includes(record.type)) {
        return null;
      }
      const units = [...new Set(componentList.map(item => item.unit))]
      return <span>{cutLength}{units.length > 1 ? unit : ""}</span>
    }

    columns[4].onCell = (record) => {
      return {
        edit: false,
        tdClassName: VC_SHUNT_TYPES.includes(record.type) ? "virtual-components-disabled-td" : ""
      }
    }

    columns[5].title = () => {
      return this.props.product === SIERRA ? "Value" : "Model";
    }

    columns[5].render = (text, record) => {
      const { type, value } = record;
      const { touchstoneList, spiceList, getTouchstoneParsePorts } = this.props;
      if (VC_RLC_TYPES.includes(type)) {
        const unit = this.getUnit(record.type);
        const _text = value ? `${value}${unit}` : "";
        return <span title={_text}>{_text}</span>
      }
      /*   if (type === CAP) {
          const _text = this.getCapText(value);
          return <span title={_text}>{_text}</span>
        } */
      const model = getVCDefaultModel(record);
      const fileNotExist = type === N_PORT && record.files && record.files.length ? getModelFileExist(model, {
        spiceList,
        touchstoneList,
        getTouchstoneParsePorts
      }) : false;
      const _text = getCompModelText(model, record, { fileNotExist })
      return <span title={getVCompModelText(record)}>{_text}</span>
    }

    columns[5].onCell = (record) => {
      if (VC_RLC_TYPES.includes(record.type)) {
        const unit = this.getUnit(record.type);
        const text =/*  record.type === CAP ? this.getCapText(record.value) :  */(record.value ? `${record.value}${unit}` : "");
        return {
          record,
          edit: true,
          dataIndex: 'value',
          customInput: ValueEdit,
          virtualComps: this.props.componentList,
          text,
          editComponentValue: this.editComponentValue,
        }
      }

      if (record.type === N_PORT) {
        const { product, touchstoneList, spiceList, ModelInfo, ModelFileInfo
          , getTouchstoneParse, getSPiceParse, verificationId } = this.props;
        return {
          edit: true,
          record: { pkg: getVCDefaultModel(record), comps: [{ name: record.name, type: record.type, pins: record.pins }] },
          componentName: record.name,
          pinList: getVCompModelPinList(record.pins),
          text: getVCompModelText(record),
          modelType: "N-Port",
          displayType: "model",
          customInput: ChipModel,
          dataIndex: 'model',
          product,
          savePackageModel: this._saveNPortModel,
          spiceList: spiceList || [],
          touchstoneList: touchstoneList || [],
          PackageModelInfo: ModelInfo,
          PackageModelFileInfo: ModelFileInfo,
          getTouchstoneParse,
          getSPiceParse,
          verificationId,
          matchClear: true
        }
      }
    }
  }

  getUnit = (type) => {
    switch (type) {
      case RES:
      case SHUNT_R:
        return "Ω";
      case IND:
      case SHUNT_L:
        return "H";
      case CAP:
      case SHUNT_C:
        return "F";
      default: return "";
    }
  }

  getCapText = (value) => {
    if (value && Object.keys(value).length) {
      let rText = value.r ? `R = ${value.r}Ω` : "",
        lText = value.r ? `L = ${value.l}H` : "",
        cText = value.r ? `C = ${value.c}F` : "";
      return `${rText}${lText ? `, ${lText}` : ""}${cText ? `, ${cText}` : ""}`;
    }
    return ""
  }

  _saveNPortModel = (model, record) => {
    const { componentList } = this.props;
    let _componentList = [...componentList];
    const index = _componentList.findIndex(item => item.name === record.comps[0].name);
    if (index < 0) {
      return
    }
    _componentList[index].files = model.files || [];
    _componentList[index].pins.forEach(pin => {
      const findItem = model.pairs.find(item => item.pin === pin.pin);
      if (findItem) {
        pin.node = findItem.node;
        pin.libraryId = findItem.libraryId;
        pin.modelKey = findItem.modelKey;
        pin.subckt = findItem.subckt || "";
      }
    })
    this.props.updateVirtualComponents(_componentList);
  }

  addVirtualComponent = (e) => {
    e && e.stopPropagation();
    this.props.addVirtualComponent();
  }

  editComponentValue = (value, selectedComps, record) => {
    const { name } = record;

    const { componentList } = this.props;
    let _componentList = [...componentList];
    const index = _componentList.findIndex(item => item.name === name);
    if (index < 0) {
      return
    }
    _componentList[index].value = value;
    //apply all
    if (selectedComps && selectedComps.length) {
      _componentList.forEach(item => {
        if (selectedComps.includes(item.name)) {
          item.value = value;
        }
      })
    }
    this.props.updateVirtualComponents(_componentList);
  }

  render = () => {
    const { componentList, product } = this.props;
    return (
      <div className='virtual-component-table-main'>
        <span className={product === SIERRA ? "aurora-setup-title-color font-bold" : "font-bold"}>Virtual Components</span>
        <PlusCircleOutlined
          title="Add virtual components"
          className="virtual-component-add-icon"
          onClick={(e) => this.addVirtualComponent(e)} />
        {componentList.length ? <EditableTable
          rowKey={(record) => record.name}
          columns={columns}
          size="small"
          dataSource={componentList}
          scroll={componentList.length > 8 ? { y: 312 } : {}}
          className='virtual-component-table space-10'
        /> : null}
      </div>
    );
  }
}

export default VirtualComponentTable;