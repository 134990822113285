/**
 * The array is sorted by the item length
 * @param {Array} arr The array to be sorted
 */
function arraySort(arr) {
  const _arr = arr.filter(item => item && item !== undefined && item !== null);

  const rtn = _arr.map(item => ({
    arrayItem: item,
    length: item.length || 0
  }))
    .sort((p, n) => n.length - p.length)
    .map(item => item.arrayItem);
  return rtn;
}

function splitArrayToArrays({ array = [], rule }) {
  let trueArray = [], falseArray = [];
  for (let item of array) {
    rule(item) ? trueArray.push(item) : falseArray.push(item);
  }
  return { trueArray, falseArray }
}

/**
 * Levenshtein Distance
 * intConst, basicConst, strConst >=0, weight factor
 * intNum, in both basic and str
 * basicNum, only in basic
 * strNum, only in str 
*/
function getArraySimilar(basic, str) {
  if (!basic || !str) {
    return 0
  }

  const intConst = 2; //intersection constant;
  const basicConst = 1, strConst = 1;

  const basicArr = basic.split('');
  const strArr = str.split('');
  const intersection = basicArr.filter((item, index) => strArr[index] && strArr[index] === item);

  const intNum = intersection.length;
  const basicNum = basicArr.length - intNum;
  const strNum = strArr.length - intNum;

  return intConst * intNum / (intConst * intNum + basicNum * basicConst + strConst * strNum);
}

/**
 * Check whether two array elements are exactly the same
 * @param {Array} arr1 
 * @param {Array} arr2 
 * @returns 
 */
function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  return arr1.sort().every((value, index) => value === arr2.sort()[index]);
}

export {
  arraySort,
  splitArrayToArrays,
  getArraySimilar,
  areArraysEqual
};