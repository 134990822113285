import CeCircle from './CeCircle';
import GCPolygon from './GCPolygon';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeSquare = function () {
  CeCircle.call(this, "Square");
}

// subclass extends superclass
CeSquare.prototype = Object.create(CeCircle.prototype);
CeSquare.prototype.constructor = CeSquare;

CeSquare.prototype.CopyFrom = function (fromObj) {
  CeCircle.prototype.CopyFrom.call(this, fromObj);
}

CeSquare.prototype.Clone = function () {
  var clnObj = new CeSquare();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeSquare.prototype.WriteIntoIODataNode = function () {
  var dataVals = CeCircle.prototype.GetDataString.call(this);
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeSquare.prototype.ConvertToGCPolygon = function () {
  var center = this.GetCenter();
  var diameter = this.GetDiameter();

  var xArray = [];
  var yArray = [];
  var halfW = 0.5 * diameter;
  var halfH = 0.5 * diameter;
  xArray.push(this.mCenter.mX - halfW);
  yArray.push(this.mCenter.mY - halfH);
  xArray.push(this.mCenter.mX + halfW);
  yArray.push(this.mCenter.mY - halfH);
  xArray.push(this.mCenter.mX + halfW);
  yArray.push(this.mCenter.mY + halfH);
  xArray.push(this.mCenter.mX - halfW);
  yArray.push(this.mCenter.mY + halfH);
  var gcPolygon = new GCPolygon();
  gcPolygon.SetData(4, xArray, yArray);
  return gcPolygon;
}

export default CeSquare;