import React, { Component } from 'react';
import { VERIFICATION, PCB, RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, PCB_PRE_LAYOUT } from '@/constants/treeConstants';
import PinToPinSetting from './PinToPinSetting';
import Result from '../result';
import TopBar from './PinToPinSetting/TopBar';
import Sweeping from './Sweeping';
import LayoutSingle from '../../../components/ContentLayout/layoutSingle';
import PCBLayout from './pcbLayout';
import { topBarResize } from '@/services/helper/topBarResize';
import { MULTI_INTERFACE_SETUP } from '../../../constants/treeConstants';
import MultiInterfaceSetup from '../MultiInterfaceSetup';

class LayoutContent extends Component {

  topBarResize = () => {
    topBarResize('sierra-top-bar', this.props.getTopBarWidth());
    topBarResize('sierra-pre-layout-top-bar', this.props.getTopBarWidth());
  }

  getContent = (height) => {
    const { viewList, leftWidth } = this.props;
    switch (viewList[0]) {
      case PCB:
      case PCB_PRE_LAYOUT:
        return <PCBLayout siderWidth={leftWidth} bottomHeight={height} topBarResize={this.topBarResize} />
      case VERIFICATION:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <PinToPinSetting />
      case RESULT:
      case EXPERIMENTS_RESULT:
        return <Result siderLeftWidth={leftWidth} viewList={viewList} />;
      case EXPERIMENTS:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <Sweeping />
      case MULTI_INTERFACE_SETUP:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <MultiInterfaceSetup />
      default: return;
    }
  }

  topBar = () => {
    return <TopBar />;
  }

  getClassName = () => {
    const { viewList } = this.props;
    switch (viewList[0]) {
      case PCB:
        return 'sierra-pcb-normal';
      case VERIFICATION:
      case EXPERIMENTS:
      case PCB_PRE_LAYOUT:
      case MULTI_INTERFACE_SETUP:
        return 'sierra-setup-normal';
      case RESULT:
      case EXPERIMENTS_RESULT:
        return 'sierra-result-normal';
      default: return '';
    }
  }

  render() {
    return (
      <LayoutSingle
        {...this.props}
        id="sierra-content-main"
        className={this.getClassName()}
        getContent={(height) => this.getContent(height)}
        topBar={this.topBar}
        topBarResize={this.topBarResize}
      />
    )
  }
}

export default LayoutContent;