class Designs {
  constructor() {
    this.designs = new Map(); // key - designId, value - design

    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
    this.getName = this.getName.bind(this);
  };

  set(id, design) {
    this.designs.set(id, design);
  }

  get(id) {
    return this.designs.get(id) || null;
  };

  delete(id) {
    this.designs.delete(id);
  };

  getName(id) {
    return this.get(id) ? this.get(id).name : null;
  }
};

const designs = new Designs();
export default designs;