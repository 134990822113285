import { generateHimalayasReport, getHimalayasReportConfig, getHimalayasReportFile, getHimalayasReportStatus, updateHimalayasReportConfig } from "../../api/Himalayas/project"
import apiProcess from "../../api/utility"

/**
 * Get himalayas project report config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
function getReportConfig(projectId) {
  return apiProcess(getHimalayasReportConfig, projectId);
}

/**
 * Update himalayas project report config
 *
 * @export
 * @param {string} projectId
 * @param {Object} params :{ channelIds, customs, fileName, format, language, projectId, templateId }
 * @returns promise
 */
function updateReportConfig({ projectId, config }) {
  return apiProcess(updateHimalayasReportConfig, { projectId, config });
}

/**
 * Get himalayas project report status
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
function getReportGenerationStatus(projectId) {
  return apiProcess(getHimalayasReportStatus, projectId);
}

/**
 * Generate himalayas project report
 *
 * @export
 * @param {string} projectId
 * @param {Object} params :{ channelIds, customs, fileName, format, language, projectId, templateId }
 * @returns promise
 */
function generateReport(params) {
  return apiProcess(generateHimalayasReport, params);
}

/**
 * Get himalayas project report file
 *
 * @export
 * @param {string} projectId
 * @param {string} format pptx/pdf
 * @returns promise
 */
function getReportFile({ projectId, format }) {
  return apiProcess(getHimalayasReportFile, { projectId, format });
}

export {
  getReportConfig,
  getReportGenerationStatus,
  updateReportConfig,
  generateReport,
  getReportFile
}