import React, { Component, createRef } from 'react';
import { getPreLayoutSignalsSections, SchematicPlot, } from '../../../../../services/Sierra/prelayout/Schematic';
import { Input, Descriptions, Tooltip } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DeleteOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { CONNECTOR, IC, RES } from '../../../../../constants/componentType';
import CompInfoPanel from './compInfoPanel';
import { numberCheck } from '../../../../../services/helper/dataProcess';
import SignalSectionTemplate from './signalSectionTemplate';
import preLayoutLibraryData from '../../../../../services/Sierra/library/PreLayoutData';
import { signalTypes, TRACE } from '../../../../../constants/libraryConstants';
import './index.css'

const DescItem = Descriptions.Item;
class Canvas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openElements: true,
      openComp: null,
      newComp: null,
      openedCompUpdate: false,
      canvasContainer: {},
      openSectionTemplate: null,
      openedTemplateUpdate: false,
      signalHeight: 60
    }
    this.eleRef = createRef();
    this.svgRef = createRef()
  }

  componentDidMount() {
    /*  this.initData(); */
  }

  componentDidUpdate = (prevProps) => {
    const { width, canvasUpdate, height, isReset, canvasConfig } = this.props;

    const update = canvasUpdate && canvasUpdate !== prevProps.canvasUpdate;
    const reset = isReset && isReset !== prevProps.isReset;

    if (reset || update) {
      this.initData();
    } else if (width !== prevProps.width || height !== prevProps.height) {
      this.schematic && this.schematic.initContainer({ width, height, container: canvasConfig });
      this.schematic && this.schematic.draw();
    }
  }

  initData = () => {
    this.initPlot();
  }

  initPlot = () => {
    const { width, canvasConfig, GPIO, signalNumber, height, isSweep, isPuppeteer } = this.props;
    const canvasContainer = {
      width: 22,
      height: 16,
      ...canvasConfig || {}
    }
    this.setState({
      canvasContainer
    }, () => {
      this.schematic = new SchematicPlot({
        elementsSvg: this.eleRef.current,
        schematicSvg: this.svgRef.current,
        width,
        height,
        top: 72,//W,H content
        elementsTypes: [IC, CONNECTOR, RES],
        GPIO,
        signalNumber,
        isSweep,
        isPuppeteer,
        action: {
          compUpdate: this.compUpdate,
          openCompBox: this.openCompBox,
          saveNewComponent: this.saveNewComponent,
          getParam: this.getParam,
          /*   signalsUpdate: this.signalsUpdate, */
          selectSignalSection: this.selectSignalSection,
          updatePreLayoutInfo: this.updatePreLayoutInfo,
          showTemplateInfo: this.showTemplateInfo,
          closeTemplateInfo: this.closeTemplateInfo
        }
      });
      this.schematic.draw();
      setTimeout(() => {
        this.props.updateSchematicLoading(false);
        this.props.updateCanvasStatus && this.props.updateCanvasStatus(false);
        isPuppeteer && this.props.drawCompleted && this.props.drawCompleted()
      }, 500)
      this.getSignalsContentHeight()
    })
  }

  getSignalsContentHeight = () => {
    const { signals } = this.props;
    if (!signals || !signals.length) {
      this.setState({
        signalHeight: 0
      })
      return
    }
    const ele = document.getElementById("sierra-pre-layout-signals-data-main");
    if (ele && ele.offsetHeight) {
      this.setState({
        signalHeight: ele.offsetHeight
      })
    }
  }

  getParam = (key, type = "props") => {
    if (!key || !type) {
      return [];
    }
    return this[type] ? this[type][key] : [];
  }

  saveNewComponent = (newComp) => {
    const { components } = this.props;
    let _components = [...components];
    _components.push({ ...newComp });
    this.props.updateComponents && this.props.updateComponents(_components);
  }

  updatePreLayoutInfo = (info) => {
    this.props.updatePreLayoutData && this.props.updatePreLayoutData({ ...info });
    setTimeout(() => {
      this.getSignalsContentHeight();
    }, 10)
  }

  removeAllSignals = (signalName) => {
    this.schematic && this.schematic.removeAllSignals(signalName)
  }

  removeSignalSection = (signalName, sectionId) => {
    this.schematic && this.schematic.removeSignalSection(signalName, sectionId)
  }

  compUpdate = (comp, action) => {
    const { isUpdate, isDelete } = action || {}
    const { components } = this.props;
    const { openComp } = this.state;
    let _components = JSON.parse(JSON.stringify(components));
    if (isUpdate) {
      const index = _components.findIndex(item => item.name === comp.name);
      if (index < 0) {
        return;
      }
      _components[index].location = comp.location;
      _components[index].pins = comp.pins;
      _components[index].width = comp.width;
      _components[index].height = comp.height;
    }

    if (isDelete) {
      _components = _components.filter(item => item.name !== comp.name);
      this.setState({
        openComp: openComp && openComp.name === comp.name ? null : openComp,
      })
    }

    this.props.updateComponents(_components);
  }

  /*   signalsUpdate = (signal, action) => {
      const { isUpdate, isDelete, isAdd } = action || {}
      const { signals } = this.props;
      let _signals = JSON.parse(JSON.stringify(signals || []));
      if (isUpdate) {
        const index = _signals.findIndex(item => item.name === signal.name);
        if (index < 0) {
          return;
        }
        _signals[index].sections = signal.sections;
        _signals[index].points = signal.points;
        _signals[index].color = signal.color;
        _signals[index].name = signal.newName || signal.name;
        _signals[index].nets = signal.nets;
      }
  
      if (isAdd) {
        _signals.push(signal);
      }
  
      if (isDelete) {
        _signals = _signals.filter(item => item.name !== signal.name);
      }
  
      this.props.updatePreLayoutData({ signals: _signals, keys: ["signals"] });
    } */

  selectSignalSection = (signalName, sectionId) => {
    const { signals } = this.props;
    const { openSectionTemplate } = this.state;
    if (openSectionTemplate && openSectionTemplate.signal === signalName && openSectionTemplate.sectionId === sectionId) {
      return;
    }
    const signal = signals.find(item => item.name === signalName);
    const section = signal.sections.find(item => item.id === sectionId);
    this.setState({
      openSectionTemplate: {
        signal: signalName,
        sectionId,
        template: section.template,
        traceLength: section.traceLength
      },
      openedTemplateUpdate: true
    })
  }

  closeSectionTemplate = () => {
    this.setState({
      openSectionTemplate: null
    })
  }

  saveComp = (newComp, applyComps) => {
    const { components, signals } = this.props;
    let _components = JSON.parse(JSON.stringify(components)), comps = [], _signals = JSON.parse(JSON.stringify(signals)), isUpdateSignals = false;
    const index = _components.findIndex(item => item.name === newComp.prevName);
    if (index < 0) {
      return;
    }
    let prevType = _components[index].type, prevName = _components[index].name;
    let updateTypes = [];
    if (applyComps && applyComps.length) {
      //RES
      for (let comp of _components) {
        if (applyComps.includes(comp.name) && comp.name !== newComp.prevName) {

          comp.value = newComp.value;
          comp.voltage = newComp.voltage;
          updateTypes.push("value", "voltage");
          comps.push({
            prevName: comp.name,
            ...comp
          })
        }
      }
    }

    if (newComp.name !== prevName) {
      _signals.forEach(item => {
        (item.sections || []).forEach(secItem => {
          if (secItem.in && secItem.in.type === "component" && secItem.in.component === prevName) {
            secItem.in.component = newComp.name;
            isUpdateSignals = true;
          }
          if (secItem.out && secItem.out.type === "component" && secItem.out.component === prevName) {
            secItem.out.component = newComp.name;
            isUpdateSignals = true;
          }
        });
        (item.points || []).forEach(poiItem => {
          (poiItem.points || []).forEach(poi => {
            if (poi.type === "component" && poi.name === prevName) {
              poi.name = newComp.name;
              isUpdateSignals = true;
            }
          })
        })
      })
    }
    _components[index].name = newComp.name;
    _components[index].type = newComp.type;

    if (newComp.type === RES) {
      _components[index].value = newComp.value;
      _components[index].voltage = newComp.voltage;
      updateTypes.push("value", "voltage");
    }

    if (isUpdateSignals) {
      this.props.updatePreLayoutData({ signals: _signals, components: _components, keys: ["signals", "components"] });
    } else {
      this.props.updateComponents(_components);
    }

    const isUpdate = _components[index].type !== prevType || _components[index].name !== prevName;
    if (isUpdate || updateTypes.length) {
      comps.push({
        prevName,
        ..._components[index]
      })
      isUpdate && updateTypes.push("name", "type");
    }

    updateTypes.length && this.schematic.updateCanvas({ comps, signals: _signals, update: updateTypes, isUpdateSignals })

    this.setState({
      openComp: newComp
    })
  }

  openCompBox = (comp, comps) => {
    this.setState({
      openComp: !comp ? null : {
        ...comp,
        comps
      },
      openedCompUpdate: true
    })
  }

  changeOpenCompStatus = () => {
    this.setState({
      openedCompUpdate: false
    })
  }

  updateShowElements = (e) => {
    this.setState({
      openElements: !this.state.openElements
    })
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  updateCanvasContainer = (e, key) => {
    const value = e.target.value;
    this.setState({
      canvasContainer: { ...this.state.canvasContainer, [key]: value }
    })
  }

  saveCanvasContainer = (e, key) => {
    let check = null;
    const value = e.target.value;
    const { canvasConfig = {} } = this.props;
    const { canvasContainer } = this.state;
    if (canvasContainer[key] === canvasConfig[key]) {
      return;
    }

    check = numberCheck(value);

    if (check) {
      this.setState({
        canvasContainer: { ...this.state.canvasContainer, [key]: canvasConfig[key] }
      })
    } else {
      const newConfig = { ...canvasContainer, [key]: value }
      this.props.updateCanvasConfig && this.props.updateCanvasConfig(newConfig);
      this.schematic.updateCanvasContainer({ ...newConfig });
      this.props.getCanvasWidth && this.props.getCanvasWidth(false, newConfig)
    }
  }

  changeOpenTemplateStatus = (openedTemplateUpdate) => {
    this.setState({
      openedTemplateUpdate
    })
  }

  showTemplateInfo = ({ x, y, id, name, length }) => {
    const data = preLayoutLibraryData.getLibrary(id, TRACE);
    this.setState({
      showTemplateData: {
        x,
        y,
        id,
        name,
        length,
        data
      }
    })
  }

  closeTemplateInfo = () => {
    this.setState({
      showTemplateData: null
    })
  }

  render() {
    const { id, width, height, isSweep, components } = this.props;
    const { openElements, openComp, openedCompUpdate, openSectionTemplate, openedTemplateUpdate, showTemplateData, signalHeight } = this.state;
    return <div className='sierra-pre-layout-canvas'
      id={`pre-layout-schematic-${id}`}>
      {!isSweep ? <div className='schematic-canvas-component-content' style={openElements ? { width: 140, height: 257 + signalHeight } : { width: 30, height: 30 }}>
        <div className={`schematic-canvas-component-icon ${openElements ? "" : "schematic-canvas-component-right-icon"}`} onClick={(e) => this.updateShowElements(e)}>
          {openElements ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
        </div>
        {openElements ? this.getContainerRender() : null}
        {openElements ? this.showSignalRender() : null}
        <svg
          id={`pre-layout-schematic-canvas-elements-${id}`}
          className="pre-layout-schematic-canvas-elements-svg"
          ref={this.eleRef}
          //width={120}
          // height={200}
          display={openElements ? "block" : "none"}
        >
        </svg>
      </div> : null}
      <svg id={`pre-layout-schematic-canvas-${id}`}
        className="pre-layout-canvas"
        ref={this.svgRef}
        width={width}
        height={height} />

      {!isSweep && openComp && openComp.name ? <CompInfoPanel
        comp={openComp}
        components={components}
        openElements={openElements}
        openedCompUpdate={openedCompUpdate}
        id={id}
        saveComp={this.saveComp}
        changeOpenCompStatus={this.changeOpenCompStatus}
        closePanel={() => this.openCompBox(null)} /> : null}
      {!isSweep && openSectionTemplate && openSectionTemplate.signal && openSectionTemplate.sectionId ?
        <SignalSectionTemplate
          openSectionTemplate={openSectionTemplate}
          openedTemplateUpdate={openedTemplateUpdate}
          closePanel={this.closeSectionTemplate}
          _reDrawSignals={this.schematic ? this.schematic.reDrawSignals : null}
          changeOpenTemplateStatus={this.changeOpenTemplateStatus} /> : null}
      {showTemplateData ? this.getTemplateInfoTooltip(showTemplateData, id) : null}
    </div>
  }

  getContainerRender = () => {
    const { canvasContainer = {} } = this.state;
    return <div className='schematic-canvas-container-content'>
      <div className='schematic-canvas-container-item'>
        <span>W</span>
        <Input
          className="aurora-input"
          value={canvasContainer.width}
          onChange={(e) => this.updateCanvasContainer(e, "width")}
          onBlur={(e) => this.saveCanvasContainer(e, "width")}
          onKeyDown={this.keyDown}
        />
      </div>
      <div className='schematic-canvas-container-item'>
        <span>H</span>
        <Input
          className="aurora-input"
          value={canvasContainer.height}
          onChange={(e) => this.updateCanvasContainer(e, "height")}
          onBlur={(e) => this.saveCanvasContainer(e, "height")}
          onKeyDown={this.keyDown}
        />
      </div>
    </div>
  }

  showSignalRender = () => {
    const { signals } = this.props;
    if (!signals || !signals.length) {
      return;
    }
    const sections = getPreLayoutSignalsSections(signals);
    const firstSignal = signals.find(item => item.points && item.points.length) || {};
    return <div className="sierra-pre-layout-signals-main" id="sierra-pre-layout-signals-data-main">
      <div className="sierra-pre-layout-signals-title">
        <span>Signals:</span>
        <DeleteOutlined
          className="sierra-pre-layout-signals-del-icon"
          title="Remove All Signals"
          onClick={() => this.removeAllSignals(firstSignal.name)} />
      </div>
      <div className="sierra-pre-layout-signals-content">
        <div className="sierra-pre-layout-signals-content-names">{signals.map(item => item.name).join(", ")}</div>
      </div>
      <div className="sierra-pre-layout-signals-content">
        <div className="sierra-pre-layout-signals-title">
          <span>Sections:</span>
        </div>
        {sections.map(item =>
          <div className="sierra-pre-layout-signals-content-names" key={item.id}>
            <span title={item.title} className="sierra-pre-layout-signals-section-name">{item.title}</span>
            <CloseOutlined
              className="sierra-pre-layout-signals-del-icon"
              title="Remove This Section"
              onClick={() => this.removeSignalSection(firstSignal.name, item.id)}
            />
          </div>
        )}
      </div>
    </div>
  }

  getTemplateInfoTooltip = (showTemplateData, id) => {
    const data = showTemplateData.data || {};
    const config = data.config || {};
    const findThickness = (config.layers || []).find(item => item.name === "Trace");
    const signalType = (signalTypes.find(item => item.key === config.type) || {}).title;
    const type = data.type || "";
    return <Tooltip
      overlayStyle={{ left: showTemplateData.x, top: showTemplateData.y }}
      title={<Descriptions size="small" column={1} bordered={true}>
        <DescItem label="Length">{showTemplateData.length || ""}</DescItem>
        <DescItem label="Template">{showTemplateData.name || ""}</DescItem>
        <DescItem label="Trace Type">{type.charAt(0).toUpperCase() + type.slice(1)}</DescItem>
        <DescItem label="Signal Type">{signalType || ""}</DescItem>
        <DescItem label="Width">{config.width ? `${config.width}${config.unit}` : ""}</DescItem>
        <DescItem label="Thickness">{findThickness && findThickness.thickness ? `${findThickness.thickness}${config.unit}` : ""}</DescItem>
      </Descriptions>}
      overlayClassName='aurora-tooltip sierra-template-tooltip'
      placement='right'
      open={true}
      getPopupContainer={() => document.getElementById(`pre-layout-schematic-${id}`)}
    />
  }
}

export default Canvas;