import { call, put, takeEvery, select, delay, take, cancel, fork } from 'redux-saga/effects';
import {
  GET_CARD_VERIFICATION_CONTENT,
  OPEN_CARD_CHANNEL,
  GET_CARD_CHANNEL_CONFIG,
  CLOSE_CARD_CHANNEL,
  SAVE_CARD_EXTRACTION_CONFIG,
  SAVE_CARD_PORT_SETUPS,
  SAVE_CARD_CONTENT_TO_SERVER,
  START_CARD_VERIFICATIONS_MODELING,
  SAVE_CARD_COMP_PACKAGE_MODEL,
  SAVE_CARD_COMP_RLC_MODEL,
  SAVE_CARD_TO_LIBRARY,
  CHANGE_CARD_COMPONENT_TYPE
} from './actionTypes';
import {
  getVerificationContentPromise,
  getChannelConfig,
  updateChannelExtractionConfig,
  updateInterfaceInfo,
  updateVerificationContent,
  getDefaultPortsGenerateSetupList,
  judgeUpdateGapPortSetup,
  updateExtractionGapPortsSetup,
  saveCardToLibraryPromise
} from '../../../../services/Rocky';
import {
  updateCardInfo,
  updateCardExtractionConfig,
  getCardChannelConfig
} from './action';
import RockySetup from '@/services/Rocky/helper/rockyDatabase';
import { getLayoutPCBInfo, getProjectType } from '../rocky/saga';
import {
  defaultExtractionConfig,
  expandMenu,
  updateTreeSelectedKeys,
  updateViewList,
  saveComponentsNetsInProject,
  updateCardLibraryList
} from '../project/action';
import { CARD, CARD_RESULT, CARD_VERIFICATION, PACKAGE_RESULT, RESULT, VERIFICATION, PACKAGE_VERIFICATION } from '../../../../constants/treeConstants';
import { strDelimited } from '../../../../services/helper/split';
import { CARD_CHANNEL, portSavePath } from '../../../../services/Rocky/constants';
import {
  getDefaultReferenceNets,
  getDefaultPortSetupList,
  getPortGenerateSetupList
} from "@/services/ExtractionPortsHelper";
import { getPCBInterfaces } from '../project/saga';
import cardChannelsConstructor from '../../../../services/Rocky/cardHelper';
import { CARD_SETUP_VERSION } from '../../../../version';
import { splitComponentName } from '../../../../services/helper/splitComponent';
import {
  judgeUpdateGapPortGapSizeSetup,
  updateHFSSExtractionCompsGapSize
} from "@/services/ExtractionPortsHelper";
import { autoGetCardVerifications, startCardModeling } from '../simulation/card/action';
import { getCardErrorCheck } from '../../errorCheck/cardErrorCheck';
import { openPage } from '../rocky/action';
import { changeTabMenu, openTabFooter } from '../../../MonitorStore/action';
import { getPageKeyList } from '@/services/helper/filterHelper';
import { checkRLCValue } from '../../../../services/helper/dataProcess';
import { CAP } from '../../../../constants/componentType';
import { message } from 'antd';
import { CIRCUIT, GAP, PIN_GROUP, SPHEROID, WAVE } from '../../../../services/ExtractionPortsHelper';
import { BALL_TYPE_NONE } from '../../../../services/ExtractionPortsHelper/portTableHelper';

let autoSaveTask = null;
const ChipTypes = ['Controller', 'Memory'];
function* _getContent(action) {
  const { id } = action;
  const response = yield call(getVerificationContentPromise, id);
  if (!response) {
    return;
  }
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
  }
  autoSaveTask = yield fork(autoSave);
  const { RockyReducer: { project, card: { extraction } } } = yield select();
  let { currentProjectId } = project;
  let Interfaces = response.interfaces || [];
  const ddrType = yield call(getProjectType);

  if ((!response.version || response.version === "0") && Interfaces && Interfaces.length > 0) {
    const pcbInfo = yield call(getLayoutPCBInfo, response.designId);
    // auto find power nets and powerComponents
    //channel and extraction
    const channelId = response.channelId;
    const currentChannelInfo = yield call(getCardChannelInfo, {
      projectId: currentProjectId,
      channelId,
      designId: response.designId
    });
    Interfaces = updateInterfaceInfo({
      Interfaces,
      pcbId: response.designId,
      pcbInfo,
      projectType: ddrType,
      currentChannelInfo,
      cmd_2t_mode: false,
      isReplace: false,
      isCard: true
    });
    //default generation ports
    const _content = Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content;
    const ports_generate_setup_list = getPortGenerateSetupList({ components: _content.Components });
    const _ports_generate_setup_list = _content.Ports_generate_setup_list && _content.Ports_generate_setup_list.length ? _content.Ports_generate_setup_list : ports_generate_setup_list;
    const referenceNets = getDefaultReferenceNets(_content.PowerNets);
    const _referenceNets = _content.ReferenceNets && _content.ReferenceNets.length ? _content.ReferenceNets : referenceNets;
    const referenceZ0 = _content.Port_setups && _content.Port_setups.length ? _content.Port_setups[0].z0 : 50
    const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
      components: _content.Components,
      signals: _content.Signals,
      pcbInfo: pcbInfo,
      referenceNets: _referenceNets,
      designId: response.designId,
      ports_generate_setup_list: _ports_generate_setup_list,
      types: ChipTypes,
      referenceZ0: referenceZ0,
      extractionType: extraction.channelType || "SIwave"
    });
    _content.ReferenceNets = _referenceNets;
    _content.Port_setups = port_setups;
    _content.Ports_generate_setup_list = setupList;
    Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = _content;
    response.interfaces = Interfaces;
    response.version = CARD_SETUP_VERSION;
    yield put({ type: SAVE_CARD_CONTENT_TO_SERVER });
  }

  yield call(checkCardContentIsNeedUpdate, { response, extraction })

  let pcbComponentsNets = project.pcbComponentsNets;
  let addPCB = [];
  if (!pcbComponentsNets.includes(response.designId)) {
    addPCB.push(response.designId)
  }
  yield put(saveComponentsNetsInProject(addPCB));
}

export function* checkCardContentIsNeedUpdate({ response, isUpdateNow }) {
  const { RockyReducer: { card: { extraction } } } = yield select();
  const ddrType = yield call(getProjectType);
  let saveToServer = false;
  const Interfaces = response.interfaces || [];
  const index = Interfaces.findIndex(i => portSavePath.find(p => i.name.match(p)));
  const content = Interfaces[index].Content;
  let { ReferenceNets, Port_setups, Ports_generation_setup, Ports_generate_setup_list, Components } = content;
  //update ports generation setup format
  if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
    Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
    Interfaces[index].Content.Ports_generate_setup_list = Ports_generate_setup_list;
    saveToServer = true;
  }
  const portsExists = !ReferenceNets || !Port_setups || !Port_setups.length ? false : true;

  if (!portsExists) {
    const pcbInfo = yield call(getLayoutPCBInfo, response.designId);
    const ReferenceNets = getDefaultReferenceNets(content.PowerNets);
    const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
      components: content.Components,
      signals: content.Signals,
      pcbInfo: pcbInfo,
      referenceNets: ReferenceNets,
      designId: response.designId,
      ports_generate_setup_list: Ports_generate_setup_list,
      types: ChipTypes,
      extractionType: extraction.channelType || "SIwave"
    });
    content.ReferenceNets = ReferenceNets;
    content.Port_setups = port_setups;
    content.Ports_generate_setup_list = setupList;
    Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = content;
    saveToServer = true;
  }

  response.interfaces = Interfaces;
  response.version = CARD_SETUP_VERSION;
  const info = RockySetup.mergeInterfacesInfo(Interfaces, response.name, ddrType);
  yield put(updateCardInfo({ ...response, info }));

  if (isUpdateNow) {
    yield call(saveCardContentToServer);
  } else if (saveToServer) {
    yield put({ type: SAVE_CARD_CONTENT_TO_SERVER });
  }

  return { ...response }
}

function* getCardChannelInfo(action) {
  const { channelId, designId } = action;
  const channels = cardChannelsConstructor.getChannels(designId);
  let currentChannelInfo = null;
  const _Info = yield call(getPCBInterfaces, { id: designId, channels, type: CARD });
  if (_Info && _Info.id === designId && _Info[channelId]) {
    currentChannelInfo = _Info[channelId];
  }
  return currentChannelInfo;
}

function* _expandCardChannel(action) {
  const { id, key } = action;
  const { RockyReducer: { project } } = yield select();
  let { expandedKeys, currentProjectId } = project;
  expandedKeys = [...new Set([...expandedKeys, `${key}-${id}`])];
  yield put(expandMenu(expandedKeys));
  //get card channel config extraction config
  yield put(getCardChannelConfig(id));
  //update verification list status
  yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: id }));
  //get card interfaces.json
  const channel = cardChannelsConstructor.getChannel(id) || {};
  const channels = cardChannelsConstructor.getChannels(channel.designId) || [];
  yield call(getPCBInterfaces, { id: channel.designId, channels, type: CARD });

}

function* getCardConfig(action) {
  const { id } = action;
  try {
    let res = yield call(getChannelConfig, id);
    if (res && res.config && res.config.extraction) {
      let save = false;
      const extraction = res.config.extraction
      // Add siwave and hfss to extraction config,default is {}, support yml file selection
      if (extraction.extractionConfig && !extraction.extractionConfig.siwave) {
        extraction.extractionConfig.siwave = {};
        save = true;
      }
      if (extraction.extractionConfig && !extraction.extractionConfig.hfss) {
        extraction.extractionConfig.hfss = {};
        save = true;
      }
      //true: Whether to allow opening of the extraction panel
      yield put(updateCardExtractionConfig(extraction, true));
      if (save) {
        yield call(updateChannelExtractionConfig, id, extraction);
      }
    } else {
      yield delay(500);
      const xTalk = '1';
      yield put(defaultExtractionConfig(id, xTalk, true));
    }
  } catch (error) {
    console.error(error);
  }
}

function* _clearCardChannel() {
  const { RockyReducer: { project } } = yield select();
  let { selectedKeys, viewList } = project;
  //update viewList:  remove card verification or result 
  yield put(updateViewList(viewList.filter(item => ![CARD_VERIFICATION, CARD_RESULT].includes(item))));
  //update selectedKeys: remove card verification or result 
  let selectKeys = selectedKeys.filter(item => {
    if (item) {
      const [_key] = strDelimited(item, "-");
      if (![CARD_VERIFICATION, CARD_RESULT].includes(_key)) {
        return item;
      }
    };
    return false;
  });
  yield put(updateTreeSelectedKeys(selectKeys));
}

function* _saveExtractionConfig(action) {
  const { extraction, prevExtraction } = action;
  //save channel extraction config
  const { RockyReducer: { card: { cardChannelId, cardInfo } } } = yield select();
  let _cardInfo = { ...cardInfo };

  // when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  // re generate port setups by port type is GAP, reference type is All pins
  if (_cardInfo && _cardInfo.interfaces && _cardInfo.interfaces.length) {
    let _Interfaces = _cardInfo.interfaces;
    const interfaceIndex = _Interfaces.findIndex(i => portSavePath.find(p => i.name.match(p))) || 0;
    let { ReferenceNets, Port_setups, Ports_generation_setup, Ports_generate_setup_list, Components } = _Interfaces[interfaceIndex].Content;
    let saveInterface = false;
    if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
      Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
      _Interfaces[interfaceIndex].Content.Ports_generate_setup_list = Ports_generate_setup_list;
      saveInterface = true;
    }
    let data = { ReferenceNets, Port_setups, Ports_generate_setup_list }
    if (extraction.channelType !== prevExtraction.channelType && judgeUpdateGapPortSetup(data, extraction)) {
      data = updateExtractionGapPortsSetup(data, _cardInfo.designId);
      saveInterface = true;
      _Interfaces[interfaceIndex].Content = { ..._Interfaces[interfaceIndex].Content, ...data.newData };
    }

    if (judgeUpdateGapPortGapSizeSetup({
      components: Components,
      extractionType: extraction.channelType,
      prevExtractionType: prevExtraction.channelType,
      ports_generate_setup_list: Ports_generate_setup_list
    })) {
      _Interfaces.forEach(info => {
        info.Content.Components = updateHFSSExtractionCompsGapSize(info.Content.Components, Ports_generate_setup_list)
      });
      saveInterface = true;
    }

    if (saveInterface) {
      const SETUP = RockySetup;
      _cardInfo.interfaces = _Interfaces;
      const newInfo = SETUP.mergeInterfacesInfo(_Interfaces, cardInfo.name, yield call(getProjectType));
      yield put(updateCardInfo({ ..._cardInfo, info: newInfo }));
      yield put({ type: SAVE_CARD_CONTENT_TO_SERVER });
    }
  }

  yield put(updateCardExtractionConfig(extraction));
  yield call(updateChannelExtractionConfig, cardChannelId, extraction);
}

function* _savePortSetups(action) {
  const { data, ComponentSetups } = action;
  const { RockyReducer: { card: { cardInfo } } } = yield select();
  let _cardInfo = { ...cardInfo };
  let Interfaces = cardInfo.interfaces || [];
  const Ports_generate_setup_list = data.Ports_generate_setup_list || [];
  let content = Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content;
  Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = { ...content, ...data };

  Interfaces.forEach(item => {
    item.Content.Components.forEach(comp => {
      const compName = splitComponentName(comp.name);
      const findComp = ComponentSetups.find(it => it.name === compName);
      const compPortSetup = Ports_generate_setup_list.find(it => it.component === compName) || { setup: {} };
      if (findComp) {
        if ([WAVE, PIN_GROUP, CIRCUIT, GAP].includes(compPortSetup.setup.portType)) {
          if (compPortSetup.setup.portType === GAP) {
            comp.gap_size = findComp.gap_size || "0";
          }
          comp.ball_type = findComp.ball_type;
          if (comp.ball_type === BALL_TYPE_NONE) {
            delete comp.ball_size;
            delete comp.ball_height;
            delete comp.ball_material;
          } else {
            comp.ball_size = findComp.ball_size;
            comp.ball_height = findComp.ball_height;
            comp.ball_material = findComp.ball_material;
          }

          if (comp.ball_type === SPHEROID) {
            comp.ball_mid_diameter = findComp.ball_mid_diameter;
          } else {
            delete comp.ball_mid_diameter;
          }
        } else {
          delete comp.gap_size;
          delete comp.ball_size;
          delete comp.ball_height;
          delete comp.ball_mid_diameter;
          delete comp.ball_type;
          delete comp.ball_material;
        }
      }
    })
  })

  _cardInfo.interfaces = Interfaces;
  const SETUP = RockySetup;
  let newInfo = SETUP.mergeInterfacesInfo(Interfaces, _cardInfo.name, yield call(getProjectType));
  newInfo = { ...newInfo, ...data };
  _cardInfo.info = newInfo;
  yield put(updateCardInfo(_cardInfo));

  yield delay(300);
  yield call(saveCardContentToServer);
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([SAVE_CARD_CONTENT_TO_SERVER]);
    let delayTime = 3000;
    if (newAction.type === SAVE_CARD_CONTENT_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveCardContentToServer, newAction);
    }
  }
}

function* debounce(time, callback, updateStatus) {
  yield delay(time);
  yield call(callback, updateStatus);
}

export function* saveCardContentToServer() {
  const { RockyReducer: { project: { currentProjectId, pkgSpiceList, pkgTouchstoneList, ebdList, ibisList }, card: { cardInfo, cardChannelId, extraction = {} } } } = yield select();
  if (!cardInfo || !cardInfo.interfaces || !cardInfo.interfaces.length) return;

  //error check and update readyForSim
  const error = getCardErrorCheck(cardInfo, extraction.channelType, { pkgSpiceList, pkgTouchstoneList, ebdList, ibisList });
  let readyForSim = error ? 0 : 1;

  const obj = {
    name: cardInfo.name,
    interfaces: cardInfo.interfaces,
    config: cardInfo.config,
    readyForSim,
    ifDoExtraction: cardInfo.ifDoExtraction,
    version: CARD_SETUP_VERSION,
    designVersion: cardInfo.designVersion ? cardInfo.designVersion : "0"
  };

  try {
    yield call(updateVerificationContent, { id: cardInfo.id, ...obj });
  } catch (error) {
    console.error(error)
  }
  //update verification readyForSim
  yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: cardChannelId }));
}

function* _startCardModeling(action) {
  const { verificationIds, verificationId, isOpenNewVe } = action;
  const current = cardChannelsConstructor.getVerification(verificationId) || {};
  yield put(openTabFooter());
  yield put(changeTabMenu({
    menuType: "simulation",
    tabSelectKeys: ['monitor'],
    verificationName: current.name,
    currentVerificationId: verificationId,
    channelName: current.channelName,
    currentChannelId: current.channelId
  }))
  if (isOpenNewVe) {
    const { RockyReducer: { project: { layout, viewList, expandedKeys, selectedKeys } } } = yield select();
    //open new card verification
    let list = [];
    if (layout !== 3) {
      list = [...viewList, CARD_VERIFICATION];
      list = [...new Set(list)];
      list = list.filter(item => ![RESULT, VERIFICATION, PACKAGE_RESULT, PACKAGE_VERIFICATION, CARD_RESULT].includes(item))
    } else {
      list = [CARD_VERIFICATION];
    }
    //open current verification channel
    if (!expandedKeys.includes(`${CARD_CHANNEL}-${current.channelId}`)) {
      yield call(_expandCardChannel, { id: current.channelId, key: CARD_CHANNEL });
    }
    //update selectedKeys ui
    const pageList = getPageKeyList(CARD_VERIFICATION);
    let selectKeys = selectedKeys.filter(item => {
      if (item) {
        const [_key] = strDelimited(item, "-");
        if (!pageList.includes(_key)) {
          return item;
        }
      };
      return false;
    });
    selectKeys.push(`${CARD_VERIFICATION}-${verificationId}`);
    yield put(updateTreeSelectedKeys(selectKeys));
    yield put(updateViewList(list));
    yield put(openPage({ pageType: CARD_VERIFICATION, id: verificationId }));
  } else {
    //save current open verification content
    yield call(saveCardContentToServer);

  }
  yield put(startCardModeling(verificationIds, verificationId));
}

function* _saveCompPkgModel(action) {
  const { pkg, compName } = action;
  const { RockyReducer: { card: { cardInfo } } } = yield select();
  let Interfaces = [...cardInfo.interfaces];
  Interfaces.forEach(info => {
    const index = info.Content.Components.findIndex(item => item.name === compName);
    if (index > -1) {
      info.Content.Components[index].pkg = { ...pkg };
    }
  });
  cardInfo.interfaces = Interfaces;
  const info = RockySetup.mergeInterfacesInfo(Interfaces, cardInfo.name, yield call(getProjectType));
  yield put(updateCardInfo({ ...cardInfo, info }));
  yield put({ type: SAVE_CARD_CONTENT_TO_SERVER })
}

function* _saveRLCModel(action) {
  const { value, part, compType, model } = action;
  const { RockyReducer: { card: { cardInfo } } } = yield select();
  let Interfaces = [...cardInfo.interfaces];
  let _value = value;
  if (compType !== CAP) {
    _value = checkRLCValue(value);
  }
  Interfaces.forEach(info => {
    info.Content.Components.forEach(comp => {
      if (comp.part === part) {
        comp.value = _value;
        comp.model = model;
      }
    });
  });
  cardInfo.interfaces = Interfaces;
  const info = RockySetup.mergeInterfacesInfo(Interfaces, cardInfo.name, yield call(getProjectType));
  yield put(updateCardInfo({ ...cardInfo, info }));
  yield put({ type: SAVE_CARD_CONTENT_TO_SERVER })
}

function* _saveCardToLibrary(action) {
  const { designId } = action;
  try {
    const res = yield call(saveCardToLibraryPromise, designId);
    yield put(updateCardLibraryList(res));
    message.success("Save library successfully!");
  } catch (error) {
    console.error(error);
  }
}

function* _changeCardCompType(action) {
  const { record } = action;
  const { RockyReducer: { card: { cardInfo } } } = yield select();
  let Interfaces = [...cardInfo.interfaces];

  try {
    const compNames = record.comps.map(item => item.name);
    Interfaces.forEach(info => {
      info.Content.Components.forEach(comp => {
        if (compNames.includes(comp.name)) {
          comp.type = record.type;
        }
      });
    });

    cardInfo.interfaces = Interfaces;
    const info = RockySetup.mergeInterfacesInfo(Interfaces, cardInfo.name, yield call(getProjectType));
    yield put(updateCardInfo({ ...cardInfo, info }));
    yield put({ type: SAVE_CARD_CONTENT_TO_SERVER })

  } catch (error) {
    console.error(error)
  }
}

function* rockyCardSaga() {
  yield takeEvery(GET_CARD_VERIFICATION_CONTENT, _getContent);
  yield takeEvery(OPEN_CARD_CHANNEL, _expandCardChannel);
  yield takeEvery(GET_CARD_CHANNEL_CONFIG, getCardConfig);
  yield takeEvery(CLOSE_CARD_CHANNEL, _clearCardChannel);
  yield takeEvery(SAVE_CARD_EXTRACTION_CONFIG, _saveExtractionConfig);
  yield takeEvery(SAVE_CARD_PORT_SETUPS, _savePortSetups);
  yield takeEvery(START_CARD_VERIFICATIONS_MODELING, _startCardModeling);
  yield takeEvery(SAVE_CARD_COMP_PACKAGE_MODEL, _saveCompPkgModel);
  yield takeEvery(SAVE_CARD_COMP_RLC_MODEL, _saveRLCModel);
  yield takeEvery(SAVE_CARD_TO_LIBRARY, _saveCardToLibrary);
  yield takeEvery(CHANGE_CARD_COMPONENT_TYPE, _changeCardCompType);
}

export default rockyCardSaga;