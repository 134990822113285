import React, { Component, Fragment, createRef } from 'react';
import { Progress, Row, Col, Tooltip } from 'antd';
import { TitleRender } from './TitleRender';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';

class UploadMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      topWidth: 666
    }
    this.scrollRef = createRef();
  }

  scrollToBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "auto" });
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount() {
    const { tabVisible } = this.props;
    this.screenChange();
    tabVisible && this.scrollToBottom();
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
    const { topWidth } = this.state;
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
  }

  cancelWorkflow = (e) => {
    e && e.stopPropagation();
    //cancel upload workflow
    const { uploadWorkflowId } = this.props;
    if (uploadWorkflowId) {
      this.props._cancelUploadWorkflow(uploadWorkflowId);
    }
  }

  changeDesign = (designId) => {
    const { designs = [] } = this.props;
    const _designs = designs.reduce((prev, curr) => { return [...prev, ...curr.children] }, []);
    const design = _designs.find(item => item.id === designId);
    if (!design) {
      return;
    }
    this.props._changeDesign(design.id);
  }

  render() {
    const { visible,
      startMsg,
      uploadProgress,
      transProgress,
      monitor,
      endMsg,
      projectName,
      uploadProjectId,
      openProjectId,
      compressProgress,
      designs = [],
      subName,
      designLog,
      openDesignId,
      pageType,
      subOptionType,
      subSelect
    } = this.props;
    const { topWidth } = this.state;
    const progressStyle = monitor ? 0 : 20;
    const isUploading = openProjectId === uploadProjectId && visible && openDesignId === "upload";
    return (
      <Fragment>
        <div className='aurora-simulation-title'>
          <Row className='my-aurora-monitor-holygrail' span={24}>
            {TitleRender({
              topWidth,
              projectName,
              waitingIndex: -1,
              subName,
              subList: designs,
              progress: visible ? 2 : -1,
              changeSubName: this.changeDesign,
              selectedKey: "pcb",
              isUploading,
              pageType,
              subOptionType,
              subSelect
            })}
            <Col className='my-aurora-middle' span={12} style={{ marginLeft: progressStyle }}>
              {isUploading && <div className='my-aurora-monitor-progress'>
                {compressProgress > -1 && compressProgress <= 100 && uploadProgress <= 0 ?
                  <Progress
                    size={{ height: 12 }}
                    strokeColor={'#f5850d'}
                    percent={compressProgress}
                    format={(percent) => `Compressing: ${percent}%`}
                    className="aurora-monitor-upload-progress-bar"
                  />
                  :
                  <Progress
                    size={{ height: 12 }}
                    strokeColor={'#1890ff'}
                    percent={uploadProgress}
                    success={{ percent: transProgress }}
                    format={(percent, successPercent) => `Uploading: ${percent}% / Compiling: ${successPercent}%`}
                    className="aurora-monitor-upload-progress-bar"
                  />}
              </div>}
            </Col>
            <div className='aurora-simulation-btn'>
              {isUploading && transProgress > -1 &&
                <div className='aurora-cancel-button'>
                  <Tooltip title='Cancel upload PCB' overlayClassName='icon-tooltip'>
                    <span className='iconfont icon-cancel9' onClick={(e) => this.cancelWorkflow(e)}></span>
                  </Tooltip>
                </div>}
            </div>
          </Row>
        </div>
        <div className='my-aurora-monitor'>
          <div className='my-aurora-monitor-log'>
            {isUploading || openDesignId === "upload" ? <pre className="my-aurora-monitor-pre">
              {startMsg ? startMsg : null}
              <br />
              {<ul className="aurora-monitor-ul">
                {monitor && monitor.length > 0 && monitor.map(item =>
                  <li key={item.id}>
                    <p style={{ margin: 0 }}>{item.time}  {item.log}</p>
                  </li>)}
              </ul>}
              {endMsg}
            </pre> : <pre className="my-aurora-monitor-pre">
              {designLog}
            </pre>}
            <div ref={(el) => { this.scrollRef = el; }}></div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default UploadMonitor;