import React, { Component, Fragment } from "react";
import { createPortal } from "react-dom";
import Panel from "../../../../components/Panel";
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from "../../../../services/helper/panelSizeHelper";
import "./index.css";

class CheckFilesPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
      maxWidth: 0,
    }
    this.dialogRoot = document.getElementById("root");
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    this.resize()
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 700),
      maxWidth: getPanelMaxWidth(offset, 500)
    })
  }

  closeModal = () => {
    this.props.closeCheckPanel()
  }

  render = () => {
    const { checkFiles } = this.props;
    const { maxHeight, maxWidth } = this.state;

    const content = (
      <Fragment>
        <Panel
          title={"Check Model"}
          className='sierra-part-library-panel sierra-panel'
          position='panel-center-top'
          minHeight={200}
          minWidth={100}
          width={getPanelWidth(maxWidth, { defaultWidth: 500 })}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onCancel={() => { this.closeModal() }}
          draggable
          zIndex={2000}
        >
          <div className="part-library-content">
            <div>These files are not uploaded to the library:</div>
            {<div className="part-library-check-files-content">
              {checkFiles.length ? checkFiles.map(item =>
                <div key={item}>{item}</div>)
                : <div className="part-library-check-files-no-data">No Data</div>}
            </div>}
          </div>
        </Panel>
      </Fragment>
    );
    return createPortal(content, this.dialogRoot)
  }
}

export default CheckFilesPanel;