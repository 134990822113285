import React, { Component } from 'react';
import { connect } from 'react-redux';
import Monitor from '../../../../components/Monitor/SimulationMonitor';
import { cancelVerificationWorkflow, getVerificationWorkflow } from '../../store/simulation/action';
import { changeTabMenu } from '../../../MonitorStore/action';
import { getMonitorInfo } from '../../../../services/Andes_v2';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';

class SimulationMonitor extends Component {
  updateVerification = (verification) => {
    // type judge channel or end to end channel
    const workType = verification.pageType;
    const verificationId = verification.verificationId;
    let info = {
      tabSelectKeys: ['monitor'],
      menuType: 'simulation',
      currentVerificationId: verificationId,
      verificationName: verification.name || null
    }
    this.props._changeTabMenu(info);

    this.props._getVerificationWorkflow({
      channelId: verification.id,
      workType,
      verificationId,
      endToEndChannelId: verification.id
    });
  }

  render() {
    return (
      <Monitor
        {...this.props}
        updateVerification={this.updateVerification}
      />
    )
  }
}

const mapState = (state) => {
  const { simulation, project: { viewList },
    channel: { channelInfo, replaceMonitor = [], generatePortsErrors = {}, hybridInfo = null },
    endToEndChannel: { endToEndChannelInfo, endToEndSetupUpdateInfo, channelReplaceMonitorList },
    sweep: { notExistNets }
  } = state.AndesV2Reducer;
  const { monitorScreenInfo: {
    currentProjectId,
    currentVerificationId,
    projectName,
    verificationName },
    tabVisible
  } = state.MonitorInfoReducer;
  let hybridRegions = hybridInfo && hybridInfo.hybridRegions ? hybridInfo.hybridRegions : null;

  let monitor = [],
    progress = -1,
    waitingIndex = -1,
    waitingTime = null,
    startMsg = null,
    endMsg = null,
    log = null,
    errorCheck = null,
    portGenerateErrors = [],
    portGenerateWarnings = [],
    designStackupErrors = simulation.designStackupErrors || [],
    errorCheckList = [],
    monitorList = [],
    logList = [],
    seasimConfigErrors = [],
    preLayoutErrors = [],
    adsConfigErrors = [];

  if (currentVerificationId && simulation[currentVerificationId]) {
    const currentInfo = simulation[currentVerificationId];
    monitor = currentInfo.monitor ? currentInfo.monitor : [];
    progress = typeof (currentInfo.progress) === 'number' ? currentInfo.progress : -1;
    waitingIndex = typeof (currentInfo.waitingIndex) === 'number' ? currentInfo.waitingIndex : -1;
    waitingTime = currentInfo.waitingTime;
    startMsg = currentInfo.startMsg;
    endMsg = currentInfo.endMsg;
    log = currentInfo.log;
    errorCheck = currentInfo.errorCheck;
    errorCheckList = currentInfo.channelsErrorCheckList || [];
    monitorList = currentInfo.monitorList || [];
    logList = currentInfo.logList || [];
    seasimConfigErrors = currentInfo.seasimConfigErrors || [];
    preLayoutErrors = currentInfo.preLayoutErrors || [];
    adsConfigErrors = currentInfo.adsConfigErrors || [];
  }

  const { subName,
    designStackupErrorList,
    verificationType,
    subType,
    verifications,
    _errorCheck,
    _replaceMonitor,
    selectedKey,
    setupUpdateMsgList,
    endToEndReplaceMonitorList,
    idEndToEndChildren,
    channelName
  } = getMonitorInfo({
    viewList,
    errorCheck,
    channelInfo,
    endToEndChannelInfo,
    currentVerificationId,
    currentProjectId,
    designStackupErrors,
    replaceMonitor,
    endToEndSetupUpdateInfo,
    channelReplaceMonitorList,
    hybridRegions,
    notExistNets
  });

  if (generatePortsErrors && generatePortsErrors.id === channelInfo.id) {
    portGenerateErrors = generatePortsErrors.errors || null;
    portGenerateWarnings = generatePortsErrors.warnings || null;
  }

  return {
    projectName,
    verificationName,
    verificationType,
    subName,
    subType,
    currentVerificationId,
    currentProjectId,
    tabVisible,
    monitor,
    progress,
    waitingIndex,
    waitingTime,
    startMsg,
    endMsg,
    log,
    verifications,
    error: _errorCheck,
    replaceMonitor: _replaceMonitor,
    designStackupErrorList,
    portGenerateErrors,
    portGenerateWarnings,
    setupUpdateMsgList,
    errorCheckList,
    monitorList,
    selectedKey,
    logList,
    endToEndReplaceMonitorList,
    seasimConfigErrors,
    idEndToEndChildren,
    preLayoutErrors,
    adsConfigErrors,
    channelName
  }
}

const mapDispatch = (dispatch) => ({
  _cancelVerificationWorkflow(verificationId) {
    dispatch(cancelVerificationWorkflow(verificationId))
  },
  _getVerificationWorkflow({ channelId, workType, verificationId }) {
    dispatch(getVerificationWorkflow({ channelId, workType, verificationId }))
  },
  _changeTabMenu(info) {
    dispatch(changeTabMenu(info))
  },
})

export default connect(mapState, mapDispatch)(SimulationMonitor);