/**
 * @description: Slice upload tool class
 */
import { getUploadStatusPromise, uploadChunkPromise } from '.'
import hash from 'object-hash'

class FileChunk {
  constructor() {
    this.id = ''
    this.md5 = ''
    this.chunkList = []
    // A successful list has already been uploaded
    this.uploadedChunkList = []
    // Number of current failures
    this.count = 0
    this.progress = 0
  }

  // Clear the data before each upload
  clearData() {
    this.id = ''
    this.md5 = ''
    this.chunkList = []
    this.uploadedChunkList = []
    this.count = 0
    this.progress = 0
  }

  calculateFileMd5(file) {
    return hash(file) + (new Date()).valueOf()
  }
  /**
   * 
   * @param {File} file
   * @param {number} maxSize The maximum limit；default: 512MB
   * @returns 
   */
  createFileChunk(file, maxSize = 1024 * 1024 * 512) {
    let max = maxSize,
      count = Math.ceil(file.size / maxSize),
      index = 0,
      chunkList = []
    if (count > 18) {
      max = Math.ceil(file.size / 18)
      count = 18
    }
    this.md5 = this.calculateFileMd5(file)
    while (index < count) {
      (index + 1) * max < file.size
        ?
        chunkList.push(
          {
            name: file.name,
            start: index * max,
            chunks: count,
            chunk: index + 1,
            file: file.slice(index * max, (index + 1) * max)
          }
        )
        :
        chunkList.push(
          {
            name: file.name,
            start: index * max,
            chunks: count,
            chunk: index + 1,
            file: file.slice(index * max, file.size)
          }
        )
      index++
    }
    return chunkList
  }

  uploadChunkList() {
    const uploadedChunkIndex = this.uploadedChunkList.map((item) => item.chunkIndex)
    const chunkList = this.chunkList.filter((item) => !uploadedChunkIndex.includes(item.chunk))

    return new Promise((resolve, reject) => {
      const length = chunkList.length
      let index = 0, counter = 0, max = 4
      const start = async () => {
        while (index < length && max > 0) {
          max--
          const formData = new FormData()
          formData.append('name', chunkList[index].name)
          formData.append('md5', this.md5)
          formData.append('start', chunkList[index].start)
          formData.append('chunks', chunkList[index].chunks)
          formData.append('chunk', chunkList[index].chunk)
          formData.append('file', chunkList[index].file)
          index++
          await uploadChunkPromise(formData).then(res => {
            this.id = res
            counter++
            max++
            // Keep the decimal place to one place
            this.progress = Math.floor((index + 1) / length * 1000) / 10
            if (counter === length) {
              resolve()
            } else {
              start()
            }
          }).catch((err) => {
            console.error(err);
          });
        }
      }
      start()
    })
  }

  async getUploadResult() {
    const id = this.id
    const res = await getUploadStatusPromise(id)
    // Limit the upload to three times
    if (res.status === 0 && this.count < 3) {
      this.count++
      this.uploadedChunkList = res.uploadedChunkList
      this.uploadChunkList()
      await this.getUploadResult()
    } else if (res.status === 1) {
      this.progress = 'success'
    } else {
      this.progress = 'fail'
    }
  }

  /**
   * 
   * @param {File} file
   * @param {number} maxSize
   */
  async doUpload(file, maxSize) {
    this.clearData()
    this.chunkList = this.createFileChunk(file, maxSize)
    await this.uploadChunkList()
    await this.getUploadResult()
    return this.progress
  }
}

const fileChunk = new FileChunk()
export default fileChunk