import React, { PureComponent } from "react";
import { Tabs } from 'antd';
import { roughnessList } from '../../services/Stackup';


class RoughnessTabs extends PureComponent {

  render() {
    const { tabKey, changeTab, children } = this.props;
    return <Tabs onChange={changeTab} activeKey={tabKey} type="card" className="roughness-tabs" items={roughnessList.map(item => ({ key: item.key, label: item.title, children }))} />
  }

}

export default RoughnessTabs;