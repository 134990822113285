import React, { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '../Panel';
import { getPanelMaxWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { getTextWidth } from '../../services/helper/getTextWidth';
import { DownloadOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { downloadFile } from '../../services/helper/downloadHelper';
import './index.css';

class MsgDialogPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: null,
      maxHeight: null,
      searchList: [],
      logList: []
    }
    this.lockRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.setState({
      logList: [...this.props.messageList]
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset),
      maxWidth: getPanelMaxWidth(offset)
    })
  }

  clickDownloadButton = () => {
    const { messageList } = this.props;
    const filename = this.props.fileName || "message.log";
    const log = messageList.join("\n");
    downloadFile(log, filename)
  }

  changeSearch = (e) => {
    this.setState({
      searchKey: e.target.value
    })
  }

  searchBlur = (e) => {
    e.target.blur && e.target.blur();
    const searchKey = e.target.value;
    const { messageList, getSearchLog } = this.props;
    if (!searchKey) {
      this.setState({
        searchList: [],
        logList: [...messageList]
      })
      return;
    }

    const { searchList, logList } = getSearchLog({
      searchKey,
      messageList
    })
    this.setState({
      searchList: searchList || [],
      logList: logList || []
    }, () => {
      if (this.lockRef) {
        this.lockRef.scrollTop = 0;
      }
    })
  }

  render = () => {
    const { maxWidth, maxHeight, logList, searchList, searchKey } = this.state;
    const { messageList, title, allowDownload, showSearch, className } = this.props;
    const maxMessages = [...messageList].sort((a, b) => { return b.length - a.length });
    let width = getTextWidth(maxMessages[0], "14px") + 90;
    width = width < 300 ? 300 : width;
    const content = (
      <Panel
        className={`aurora-msg-dialog-info-panel ${className || ""}`}
        zIndex={2000}
        width={width + 20}
        title={<div>
          <div className='aurora-msg-dialog-title'>{title}</div>
          {allowDownload ? <DownloadOutlined
            className='aurora-msg-dialog-info-download'
            title='Download log file'
            onClick={this.clickDownloadButton} /> : null}
        </div>}
        onCancel={this.props.closePanel}
        position='panel-center'
        minWidth={300}
        minHeight={100}
        /*  createWH */
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        overflow={'hidden'}
        draggable
      >
        <div className='aurora-msg-dialog-main'>
          {showSearch ? <div className='aurora-msg-dialog-search'>
            <Input
              value={searchKey}
              className='aurora-input'
              onChange={(e) => this.changeSearch(e)}
              onBlur={(e) => this.searchBlur(e)}
              onPressEnter={(e) => this.searchBlur(e)}
            />
          </div> : null}
          <div className='aurora-msg-dialog-content' ref={c => { this.lockRef = c; }}>
            {messageList && messageList.length ?
              [...searchList, ...logList].map((item, index) =>
                (this.getMsgItem({ index, item })))
              : null}
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  getMsgItem = ({ index, item }) => {

    return <div key={index} className='aurora-msg-dialog-info-item'>
      <span>{item}</span>
    </div>
  }
}

export default MsgDialogPanel;