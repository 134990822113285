import http from "../http";

export function getImpedance(verificationId) {
  return http.get(`/Cascade/verification/${verificationId}/impedance`);
}

export function updateImpedanceSetup({ verificationId, content }) {
  return http.post(`/Cascade/update/impedance/setup`, {
    verificationId,
    content
  })
}

export function getDecapEffectiveRLById(verificationId) {
  return http.get(`/Cascade/decap/table?verificationId=${verificationId}`)
}

export function getDecapGroupById(verificationId) {
  return http.get(`/Cascade/decap/group/table?verificationId=${verificationId}`)
}

export function updateImpedanceDomain({ verificationId, layouts }) {
  return http.post(`/Cascade/update/impedance/domain`, {
    layouts,
    verificationId
  })
}

export function deleteDomains({ verificationId, ids, designId }) {
  return http.post(`/Cascade/delete/impedance`, {
    verificationId, ids, designId
  })
}

export function simulationImpedance({ options, verificationId }) {
  return http.post(`/Cascade/impedance/simulation`, {
    options,
    verificationId
  })
}

export function getImpResult(verificationId) {
  return http.get(`/Cascade/impedance/result?verificationId=${verificationId}`)
}

export function getImpNpiFile({ verificationId, fileName }) {
  return http.get(`/Cascade/impedance/npi/file?verificationId=${verificationId}&fileName=${fileName}`)
}

export function getImpCurves({ cols, rows, type, verificationId, fileName }) {
  return http.post(`/Cascade/impedance/curve/data`, {
    curveRequestDTO: { cols, rows, type }, verificationId, fileName
  })
}

export function getImpedanceRelated(verificationId) {
  return http.get(`/Cascade/impedance/result/related?verificationId=${verificationId}`)
}

export function uploadTouchstone({ files, name, config, targetName, }) {
  let formData = new FormData();
  let file = files[0].file
  const blob = new Blob([file], {
    type: 'plain/text;charset=utf-8;'
  });
  formData.append('file', blob, name);
  formData.append('targetName', targetName);
  return http.post(`/Cascade/impedance/target/upload/file`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

export function uploadPWLTable({ file }) {
  let formData = new FormData();
  const blob = new Blob([file], {
    type: 'plain/text;charset=utf-8;'
  });
  formData.append('file', blob);
  return http.post(`/Cascade/impedance/parse/pwl`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

export function getTargetTouchstone({ powerDomainId, targetFileName, libraryId }) {
  return http.get(`/Cascade/impedance/target/file?libraryId=${libraryId}&powerDomainId=${powerDomainId}&targetFileName=${targetFileName}`);
}

export function deleteTargetFile({ powerDomainId = '', libraryId = '' }) {
  return http.post(`/Cascade/impedance/target/delete?libraryId=${libraryId}&powerDomainId=${powerDomainId}`);
}

export function getEffectResult({ verificationId, frequency }) {
  return http.get(`/Cascade/impedance/result/effective?verificationId=${verificationId}&frequency=${frequency}`)
}

export function getEffectFrequency({ verificationId }) {
  return http.get(`/Cascade/calculate/frequency?verificationId=${verificationId}`)
}

export function getRdieCdieResult(libraryId) {
  return http.get(`/Cascade/impedance/result/rdie-cdie?libraryId=${libraryId}`)
}

// Result History
export function saveHistory({ name, verificationId }) {
  return http.post(`/Cascade/impedance/result/history?verificationId=${verificationId}&name=${name}`);
}

export function deleteHistory({ historyIds }) {
  return http.post(`/Cascade/impedance/result/history/delete`, { historyIds })
}

export function getHistoryList(verificationId) {
  return http.get(`/Cascade/impedance/result/history/list?verificationId=${verificationId}`)
}

// Result History File
export function getImpHistoryCurves({ cols, rows, type, fileName, historyId }) {
  return http.post(`/Cascade/impedance/history/curve/data`, {
    curveRequestDTO: { cols, rows, type }, fileName, historyId
  })
}

export function getImpHistoryNpiFile({ historyId, fileName }) {
  return http.get(`/Cascade/impedance/history/npi/file?historyId=${historyId}&fileName=${fileName}`)
}

export function getImpHistoryResult(historyId) {
  return http.get(`/Cascade/impedance/history/file?historyId=${historyId}`)
}

export function getImpedanceHistoryRelated(historyId) {
  return http.get(`/Cascade/impedance/history/result/related?historyId=${historyId}`)
}

export function getHistoryEffectResult({ historyId, frequency }) {
  return http.get(`/Cascade/impedance/history/effective?historyId=${historyId}&frequency=${frequency}`)
}

export function getImpedanceJsonById(verificationId) {
  return http.get(`/Cascade/impedance/sim/json?verificationId=${verificationId}`)
}

export function getHistoryDecapEffectiveRLById(historyId) {
  return http.get(`/Cascade/impedance/history/decap/table?historyId=${historyId}`)
}

export function getHistoryDecapGroupById(historyId) {
  return http.get(`/Cascade/history/decap/group/table?historyId=${historyId}`)
}

/**
 * Save cutdesign
 *
 * @export
 * @param {*} param {content:{unit,x:[],y:[]},verificationId}
 * @return {*} 
 */
export function saveCutDesign(param) {
  return http.post(`Cascade/impedance/powersi/cutdesign`, param)
}

/* Result import api**/
export function importSnp({ zip, verificationId, isZip = false }) {
  let formData = new FormData();
  if (isZip) {
    formData.append('file', zip);
    formData.append('verificationId', verificationId);
    return http.post(`/Cascade/impedance/upload/snp`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  } else {
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 }
    }).then(blob => {
      formData.append('file', new File([blob], verificationId));
      formData.append('verificationId', verificationId);
      return http.post(`/Cascade/impedance/upload/snp`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
    })
  }
}

export function getImportList(verificationId) {
  return http.get(`/Cascade/impedance/snp/list?verificationId=${verificationId}`)
}

// Result History File
export function getImpImportCurves({ cols, rows, type, fileName, snpId }) {
  return http.post(`/Cascade/impedance/snp/curve/data`, {
    curveRequestDTO: { cols, rows, type }, fileName, snpId
  })
}

export function getImpImportNpiFile({ snpId }) {
  return http.get(`/Cascade/impedance/snp/npi?snpId=${snpId}`)
}

export function deleteImportFile({ snpIds }) {
  return http.post(`/Cascade/impedance/snp/delete`, { ids: snpIds })
}

export function getImpSuccess() {
  return http.get(`/Cascade/impedance/success`)
}

export function getComparisonList(verificationId) {
  return http.get(`/Cascade/impedance/results/comparison?verificationId=${verificationId}`)
}

export function saveComparison({ comparison, verificationId }) {
  return http.post(`/Cascade/impedance/results/comparison`, {
    comparison,
    verificationId
  })
}

export function uploadDecapGroup({ verificationId, file }) {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return http.post(`/Cascade/decap/group/verification/${verificationId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function uploadDecapModel({ file, type }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return http.post(`/Cascade/impedance/parse/model/excel`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function getTransientResultById(verificationId) {
  return http.get(`/Cascade/impedance/transient/result?verificationId=${verificationId}`)
}

export function getImpOptCurves({ cols, rows, type, verificationId, fileName }) {
  return http.post(`/Cascade/impedance/optimization/curve/data`, {
    curveRequestDTO: { cols, rows, type }, verificationId, fileName
  })
}

export function getImpOptNpiFile(verificationId) {
  return http.get(`/Cascade/impedance/optimization/npi?verificationId=${verificationId}`)
}

export function getImpOptDecap(verificationId) {
  return http.get(`/Cascade/impedance/optimization/decap?verificationId=${verificationId}`)
}

export function getImpOptList(verificationId) {
  return http.get(`/Cascade/impedance/optimization/decap/list?verificationId=${verificationId}`)
}

export function getImpSweepCurves({ cols, rows, type, verificationId, fileName, sweepKey }) {
  return http.post(`/Cascade/impedance/sweep/curve/data`, {
    curveRequestDTO: { cols, rows, type }, verificationId, fileName, sweepKey
  })
}