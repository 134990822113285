import React, { Component } from "react";
import { Collapse, Select } from "antd";

const ENABLE_OPTIONS = [{
  key: "traceCapacitance",
  title: "Total Trace Capacitance"
},
{
  key: "endToEnd",
  title: "End to End"
},
{
  key: "impedance",
  title: "Impedance"
},
{
  key: "delay",
  title: "Delay"
},
{
  key: "sParameter",
  title: "Network Parameter"
}];
const Option = Select.Option;
class ReportAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  enableOptionsChange = (keys) => {
    const enableOptions = [...(keys || [])];
    this.props.changeAdvanceData(enableOptions, "enableOptions");
  }

  render = () => {
    const { reportConfig = {} } = this.props;
    const { enableOptions = [] } = reportConfig || {};
    return (
      (<Collapse className="sierra-report-advances-collapse">
        <Collapse.Panel
          header={
            <div className="report-advances-header">
              <span>Advanced</span>
            </div>}
          key="advanced"
        >
          <div className="sierra-report-advances-enable-options">
            <span className="sierra-report-advances-enable-title">Optional Results</span>
            <Select
              mode="multiple"
              className="sierra-report-advances-enable-select"
              value={enableOptions || []}
              onChange={this.enableOptionsChange}
              popupMatchSelectWidth ={false}
              allowClear={true}
              popupClassName="sierra-report-advances-enable-select-dropdown"

            >
              {ENABLE_OPTIONS.map(item => <Option key={item.key} value={item.key}>{item.title}</Option>)}
            </Select>
          </div>
        </Collapse.Panel>
      </Collapse>)
    );
  }
}
export default ReportAdvance