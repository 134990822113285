import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { saveConfg } from '../../store/actionCreators';

class InterfaceFooter extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {

    const { saveConfig } = this.props;

    return (
      <Fragment>
        <Button
          type='primary'
          onClick={saveConfig}
        >
          Done
        </Button>
      </Fragment>
    )
  }

}

// const mapState = (state) => {
//   return {}
// }

const mapDispatch = (dispatch) => ({
  saveConfig() {
    dispatch(saveConfg())
  },
})

export default connect(null, mapDispatch)(InterfaceFooter);