import React, { Component } from 'react';
import LayoutSingle from '@/components/ContentLayout/layoutSingle';
import { PCB, PACKAGE, DCR, IR_EXPLORER, IR_EXPLORER_RESULT, IMPEDANCE, IMPEDANCE_RESULT, POWER_TREE, DESIGN_TREE, SINGLE_TREE, PCB_PRE_LAYOUT } from '@/constants/treeConstants';
import TopBar from './TopBar';
import DCRContent from '../DCR';
import IRContent from '../IRExplorer';
import IRResultContent from '../results/IR';
import ImpedanceContent from '../Impedance';
import ImpedanceResult from '../results/Impedance';
import DesignTreeContent from '../DesignTree';
import PCBLayout from './pcbLayout';
import SignOffTemplateContent from '../SignOffTemplate';
import { topBarResize } from '@/services/helper/topBarResize';
import { SIGN_OFF_TEMPLATE } from '../../../constants/treeConstants';

class LayoutContent extends Component {
  topBarResize = () => {
    topBarResize('cascade-top-bar', this.props.getTopBarWidth(this.props.viewList));
  }

  getContent = (height, view) => {
    const { siderWidth } = this.props;
    switch (view) {
      case PCB:
      case PACKAGE:
      case PCB_PRE_LAYOUT:
        return <PCBLayout siderWidth={siderWidth} bottomHeight={height} />;
      case DCR:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <DCRContent />
      case IR_EXPLORER:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <IRContent />
      case IR_EXPLORER_RESULT:
        return <IRResultContent leftWidth={siderWidth} />
      case IMPEDANCE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <ImpedanceContent />
      case IMPEDANCE_RESULT:
        return <ImpedanceResult leftWidth={siderWidth} />
      case SIGN_OFF_TEMPLATE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <SignOffTemplateContent />
      case DESIGN_TREE:
      case SINGLE_TREE:
      case POWER_TREE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <DesignTreeContent />
      default: return null;
    }
  }

  topBar = () => {
    return <TopBar openMenu={this.props.openMenu} />
  }

  getClassName = (view) => {
    switch (view) {
      case PCB:
      case PACKAGE:
        return 'cascade-pcb-normal';
      case DCR:
      case IR_EXPLORER:
      case IMPEDANCE:
      case POWER_TREE:
      case SIGN_OFF_TEMPLATE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        return 'cascade-setup-normal';
      case IR_EXPLORER_RESULT:
      case IMPEDANCE_RESULT:
        return 'cascade-result-normal';
      default: return '';
    }
  }

  render() {
    const { viewList } = this.props;
    const view = viewList.length ? viewList[0] : null;
    return (
      <LayoutSingle
        {...this.props}
        id="cascade-content-main"
        className={this.getClassName(view)}
        getContent={(height) => this.getContent(height, view)}
        topBar={this.topBar}
        topBarResize={this.topBarResize}
      />
    )
  }
}

export default LayoutContent;