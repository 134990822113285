import React, { Component, createRef } from "react";
import { Select, Input, InputNumber } from "antd";
import { ANDES_INTERFACE_TYPES, PCIE_WIDTH_LIST } from '@/services/Andes_v2/constants';
import { numberCheck } from "@/services/helper/dataProcess";
import { CPHY, GENERIC, PCIE, ETHERNET } from "../../../services/PCBHelper/constants";
import './index.css';
import { MODEL } from "../../../services/Andes_v2/preLayout";

const Option = Select.Option;
class SerdesType extends Component {
  constructor(props) {
    super(props);
    const { width, diffPairsNumber } = props;
    this.state = {
      widthOpen: false,
      widthInput: width,
      diffPairsNumberInput: diffPairsNumber,
    }
    this.inputRef = createRef();
  }

  componentDidUpdate = (prevProps) => {
    const { width, diffPairsNumber } = this.props;
    if (prevProps.width !== width) {
      this.setState({
        widthInput: width
      })
    }

    if (prevProps.diffPairsNumber !== diffPairsNumber) {
      this.setState({
        diffPairsNumberInput: diffPairsNumber
      })
    }
  }

  widthFocus = () => {
    this.setState({
      widthOpen: true
    })
  }

  handleWidthChange = (e) => {
    this.setState({
      widthOpen: true,
      widthInput: e.target.value
    })
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  saveWidth = (value) => {
    this.setState({
      widthOpen: false
    });
    const { width } = this.props;
    if (!value) {
      this.resetWidthInput(width);
      return;
    }

    //check format
    let first = value.split("")[0];

    if (first.toUpperCase() !== "X") {
      this.resetWidthInput(width);
      return;
    }
    //check number
    let number = value.slice(1);
    const error = numberCheck(number);
    if (error || (number < 1 || number > 32)) {
      this.resetWidthInput(width);
      return;
    }
    const _value = `${first.toLowerCase()}${number}`;
    this.resetWidthInput(_value);
    this.props.inputChange("width", _value);
  }

  resetWidthInput = (width) => {
    this.setState({
      widthInput: width
    });
  };

  updatePCIeWidth = (value) => {
    this.resetWidthInput(value);
    this.props.inputChange("width", value);
    this.inputRef.current.blur();
    this.saveWidth(value);
  }

  handleNumberChange = (e) => {
    this.setState({
      diffPairsNumberInput: e.target.value
    })
  }

  saveNumber = (e) => {
    const value = e.target.value
    const error = numberCheck(value);
    if (!error) {
      this.props.inputChange("diffPairsNumber", value);
    }
  }

  updateCPHYLanesNumber = (value) => {
    if (!!numberCheck(value)) {
      return;
    }
    this.props.inputChange("lanesNumber", parseInt(value));
  }

  render() {
    const { widthOpen, widthInput, diffPairsNumberInput } = this.state;
    const { type, width, prelayout, lanesNumber } = this.props;
    const interfaceTypes = prelayout === MODEL ? ANDES_INTERFACE_TYPES : ANDES_INTERFACE_TYPES.filter(item => item.key !== CPHY);
    return <div>
      <div className="pre-layout-pcie-select">
        <span className='out-title-color pre-layout-span'>Serdes Type</span>
        <Select
          value={type}
          onChange={this.props.changeInterfaceType}
          placeholder={"Interface Type"}
          className='aurora-select pre-layout-select'
          popupClassName='aurora-select-dropdown'
          getPopupContainer={() => document.getElementById("root")}
        >
          {interfaceTypes.map(item =>
            <Option key={item.key} disabled={item.disabled || false}>{item.title}</Option>
          )}
        </Select>
      </div>
      {type === PCIE || type === ETHERNET ? <div className='pre-layout-pcie-select'>
        <span className='out-title-color pre-layout-span'>Width</span>
        <div className='pre-layout-serdes-type-content'>
          <Input
            key="width"
            id="pre-layout-serdes-type-width-input-id"
            className="pre-layout-serdes-type-width-input"
            onFocus={e => this.widthFocus(e)}
            value={widthInput}
            onChange={e => this.handleWidthChange(e)}
            onKeyDown={e => this.handleKeyDown(e)}
            onBlur={(e) => this.saveWidth(e.target.value)}
            ref={this.inputRef}
          />
          <Select
            value={width}
            open={widthOpen}
            onChange={this.updatePCIeWidth}
            placeholder="PCIe width"
            className='aurora-select pre-layout-serdes-type-width-select'
            popupClassName='aurora-select-dropdown'
            getPopupContainer={() => document.getElementById("root")}
            suffixIcon={null}
          >
            {PCIE_WIDTH_LIST.map(item =>
              <Option key={item}>{item}</Option>
            )}
          </Select>
        </div>
      </div> : null}
      {type === GENERIC ? <div className="pre-layout-pcie-select">
        <span className='out-title-color pre-layout-span'>Number of diff pairs</span>
        <Input
          key="diffPairsNumber"
          className="aurora-input pre-layout-input"
          value={diffPairsNumberInput}
          onChange={e => this.handleNumberChange(e)}
          onKeyDown={e => this.handleKeyDown(e)}
          onBlur={(e) => this.saveNumber(e)}
          ref={this.inputRef}
        />
      </div> : null}
      {type === CPHY ? <div className='pre-layout-pcie-select'>
        <span className='out-title-color pre-layout-span'>Number of Lanes</span>
        <InputNumber min={1} value={lanesNumber} className="aurora-input pre-layout-input" onChange={this.updateCPHYLanesNumber} />
      </div> : null}
    </div>
  }
}

export default SerdesType;