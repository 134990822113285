import LayoutRenderer, { getPinLocation } from './LayoutRenderer';

/* todo:
  change color mode
  select and deselect net
  fit view, zoom in and zoom out
*/
let layoutRenderer, layout, nets, components = [], lastSelectedPin = {};
const cache = {};

class CanvasManager {
  constructor() {
    this.instances = new Map(); // store all canvas instances
    this._currentRenderer = null;
  }

  add(id, instance) {
    this.instances.set(id, instance);
    this.setCurrent(instance);
  }

  remove(id) {
    if (this.instances.has(id)) {
      const instanceToRemove = this.instances.get(id);

      if (instanceToRemove.destroy) {
        instanceToRemove.destroy();
      }
      this.instances.delete(id);

      // If the current instance is removed, switch to the next available instance
      if (this._currentRenderer === instanceToRemove) {
        const remainingInstances = this.instances.values();
        this.setCurrent(remainingInstances.next().value || null);
      }
    }
  }

  setCurrent(instance) {
    this._currentRenderer = instance;
    layoutRenderer = instance;
  }

  current() {
    return this._currentRenderer;
  }

  clear() {
    this.instances.forEach((instance) => {
      if (instance.destroy) {
        instance.destroy();
      }
    });
    this.instances.clear();
    this.setCurrent(null);
  }
}

const canvasManager = new CanvasManager();

/**
 * create and initial layerRenderer by layout db
 * @param {dom} svg dom element to be rendered
 * @param {object} layoutDB layout data object
 * @param {dom} locationSvg location dom element
 * @param {object} events zoom and mousemove event functions
 */

function initLayout(svg, layoutDB, locationSvg, events, isCPMCanvas = false, showPinList = []) {
  layout = layoutDB;
  if (isCPMCanvas) {
    const cpmCanvasInstance = new LayoutRenderer(svg, layoutDB, locationSvg, events, null, true);
    canvasManager.add("cpmCanvas", cpmCanvasInstance);
  } else {
    const portSetupInstance = new LayoutRenderer(svg, layoutDB, locationSvg, events, null, true);
    canvasManager.add("portSetup", portSetupInstance);
  }
  layoutRenderer.setShowPinList(showPinList, true);
  this.eventFns = events;
  layoutRenderer.isCPMCanvas = isCPMCanvas;
  if (isCPMCanvas) {
    layoutRenderer.triPoints = [];
    layoutRenderer.triPointObj = { A: null, B: null, C: null }
  }
  layoutRenderer.resetCanvas();
  layoutRenderer.fitView();
  layoutRenderer.addSelectionLyr();
  layout.GetLayerManager().GetAllMetalLayers().mValues.forEach(name => {
    layoutRenderer.addMetalLayer(name);
  });
  const metalLyrs = layout.mLayerMgr.mMetalLayers;
  nets = layout.mNetManager.mNetList.mKeys;
  if (metalLyrs && metalLyrs.length) {
    for (let metalItem of metalLyrs) {

      if (!metalItem.mComponentLayer) {
        continue;
      }
      const layer = metalItem.mComponentLayer;

      if (!layer.mComponents || !layer.mComponents.length) {
        continue;
      }
      components = components.concat(layer.mComponents.map(function (comp) {
        return comp.mName;
      }));
    }
  }

  // init layer select
  const settings = layout.GetLayoutSettings();
  if (settings.mLayerVisibility
    && settings.mLayerVisibility.mKeys
    && settings.mLayerVisibility.mKeys.length
  ) {
    settings.mLayerVisibility.mKeys.forEach(d => {
      settings.SetLayerVisible(d, false);
    })
  }

  //Deduplication
  nets = [...new Set(nets)];
  components = [...new Set(components)];

  cache.nets = nets.map(function (net) {
    return net.toLowerCase();
  });
  cache.comps = components.map(function (comp) {
    return comp.toLowerCase();
  })
}

function removeCanvas(key) {
  canvasManager.remove(key);
}

/**
 * draw shownLayers and hide hiddenLayers
 * @param {array} shownLayers the display layer names
 * @param {array} hiddenLayers the hidden layer names
 */
function updateLayers(shownLayers, hiddenLayers) {
  const settings = layout.GetLayoutSettings();
  hiddenLayers.forEach(name => {
    settings.SetLayerVisible(name, false);
  });
  shownLayers.forEach(name => {
    settings.SetLayerVisible(name, true);
  });
  layoutRenderer.updateLayerVisibility();
  layoutRenderer.updateColoring();
}

/**
 * find components in which layers
 * @param {array} names selected components' names
 */
function findCurrentLayer(names) {
  if (names.length && names.length > 0) {
    let layers = layoutRenderer.getCurrentLayer(names);
    return layers;
  } else {
    return [];
  }
}

function fitView() {
  layoutRenderer.fitView();
};

function zoomIn() {
  layoutRenderer.zoom(1.272);
};

function zoomOut() {
  layoutRenderer.zoom(1 / 1.272);
};

const reg = /^[-\+]?\d+(\.\d+)?\,[-\+]?\d+(\.\d+)?$/;

/**
 * search location, nets and components by value
 * @param {string} value 
 */
function search(value) {
  const keyword = value.replace(/[( )]/g, "");
  if (keyword.match(reg) !== null) {
    const [x, y] = keyword.split(',');
    layoutRenderer.zoomToLocate(parseFloat(x), parseFloat(y));
  } else if (value) {
    const searchValue = value.toLowerCase();
    return {
      nets: searchNet(searchValue),
      components: searchComp(searchValue)
    };
  }
}

/**
 * search nets in cache by value 
 * @param {string} value 
 */
function searchNet(value, PCIeName) {
  if (value === '') {
    if (PCIeName) {
      let key = 'TXP';
      switch (PCIeName) {
        case 'Tx_P': key = 'TXP'; break;
        case 'Tx_N': key = 'TXN'; break;
        case 'Rx_P': key = 'RXP'; break;
        case 'Rx_N': key = 'RXN'; break;
        default: break;
      }
      let cacheNets = cache.nets;
      let selectNet = nets.filter(function (net, i) {
        return !(cacheNets[i].indexOf(`PCIE_${key}`.toLowerCase()) < 0);
      })
      let otherNets = nets.filter(function (net, i) {
        return (cacheNets[i].indexOf(`PCIE_${key}`.toLowerCase()) < 0);
      })
      return [...selectNet, ...otherNets];
    } else {
      return nets;
    }
  } else {
    var cacheNets = cache.nets;
    return nets ? nets.filter(function (net, i) {
      return !(cacheNets[i].indexOf(value) < 0);
    }) : []
  }
}

/**
 * serach components in cache by value 
 * @param {string} value 
 */
function searchComp(value) {
  var cacheComps = cache.comps;
  return components.filter(function (comp, i) {
    return !(cacheComps[i].indexOf(value) < 0);
  });
}

/**
 * change the color model in canvas 
 * @param {'layer' | 'net' } colorBy 
 */
function changeColorMode(colorBy) {
  layout.GetLayoutSettings().SetColorByLayer(colorBy === 'layer');
  layoutRenderer.updateColoring();
}

/**
 * get the net names connected with the specified net by RLC
 * @param {string} netName 
 */
function getRelatedNets(netName) {
  const pinList = layout.mNetManager.GetNetFromName(netName).mPinList;
  const relatedNets = [];
  const RLC = 'RLCrlc';
  const rlcComponents = []
  for (const { mCompName } of pinList) {
    if (RLC.includes(mCompName[0])) {
      rlcComponents.push(mCompName);
    }
  }
  const nets = layout.mNetManager.mNetList.mVals;
  for (const { mName, mPinList } of nets) {
    if (mName !== netName) {
      for (const { mCompName } of mPinList) {
        if (rlcComponents.includes(mCompName)) {
          relatedNets.push(mName);
          break;
        }
      }
    }
  }
  return relatedNets;
}

function getLayerColor(layer) {
  return layout.GetLayoutSettings().GetLayerColor(layer);
}

function setPinColorByNets({ PowerPins, ReferencePins, SignalPins, chip }) {
  for (let key in PowerPins) {
    layoutRenderer.setColorForSelectedPins({ [chip]: PowerPins[key] }, 'coral');
  }

  for (let key in ReferencePins) {
    layoutRenderer.setColorForSelectedPins({ [chip]: ReferencePins[key] }, 'cornflowerblue');
  }

  for (let key in (SignalPins || {})) {
    layoutRenderer.setColorForSelectedPins({ [chip]: SignalPins[key] }, '#05c11a');
  }
}

function setPowerNetsColor(PowerNets) {
  layoutRenderer.setColorForNetsInPort(PowerNets, '#464646');
}

function setPinsOpacity(pins, chip, opacity) {
  layoutRenderer.setOpacityForSelectedPins({ [chip]: pins }, opacity)
}

function getPinColor(netType) {

  switch (netType) {
    case "PowerNet":
      return "coral";
    case "SignalNet":
      return "#05c11a";
    case "ReferenceNet":
    default: return "cornflowerblue";
  }
}

function selectPins({ pins, chip, isSelected, netType }) {
  layoutRenderer.removePort();
  layoutRenderer.transparentCompLyr(false);
  let color = 'yellow';
  if (!isSelected) {
    color = getPinColor(netType);
    lastSelectedPin = {}
  } else {
    if (lastSelectedPin.select) {
      const lastColor = getPinColor(lastSelectedPin.netType);
      layoutRenderer.setColorForSelectedPins(lastSelectedPin.select, lastColor);
    }
    lastSelectedPin = { select: { [chip]: pins }, netType };
  }
  layoutRenderer.setOpacityForSelectedPins({ [chip]: pins }, isSelected ? '1' : '')
  layoutRenderer.setColorForSelectedPins({ [chip]: pins }, color);
  isSelected && layoutRenderer.zoomToPins({ [chip]: pins })
}

function showPinsName(pinGroup, show) {
  layoutRenderer.showPinsName(pinGroup, show)
}

function zoomInFromPins(component, pins = []) {
  if (component && pins.length) {
    layoutRenderer.zoomToPins({ [component]: pins })
  }
}

/**
 * Select ports groups
 * 
 * ports - { [comp]: [pin], [comp1]: [pin1, pin2] }
 *
 * @param {*} portsGroups - {  positivePorts = {}, negativePorts = {} }
 */
function selectPortsGroups(portsGroups, transparent = true) {
  layoutRenderer.removePort();
  //layoutRenderer.transparentCompLyr(transparent);
  if (portsGroups && portsGroups.length) {
    layoutRenderer.highlightPortsGroups(portsGroups, true);
  }
}

function setAllPinsOpacity(pins, chip, opacity, portsGroups = []) {
  let _pins = [...pins];
  if (portsGroups && portsGroups.length) {
    const highlightPins = getHighlightPins(portsGroups);
    _pins = _pins.filter(item => !highlightPins.includes(item))
  }
  layoutRenderer.setOpacityForSelectedPins({ [chip]: _pins }, opacity)
}

function getHighlightPins(portsGroups) {
  let pins = [];
  for (let port of portsGroups) {
    const pPorts = Object.keys(port.positivePorts || {}).map(item => port.positivePorts[item] || []).flat(2),
      nPorts = Object.keys(port.negativePorts || {}).map(item => port.negativePorts[item] || []).flat(2);
    pins.push(...pPorts, ...nPorts);
  }
  pins = [...new Set(pins)];
  return pins;
}

function getPinsDetail(pinGroups) {
  return layoutRenderer.getPinsInfo(pinGroups);
}

function updatePortSetupPinsInfo({ selectedBoxList = [], referencePins = [], deleteNegativePinsList = [], editingPort = null }) {
  layoutRenderer.updatePortSetupPinsInfo({ selectedBoxList, referencePins, deleteNegativePinsList, editingPort });
}

function updatePortSetupEventFn(eventFns) {
  layoutRenderer.updatePortSetupEventFn({ eventFns });
}

function clearRightClickStatus() {
  layoutRenderer.clearRightClickStatus();
}

function resetModelPoints(points, radius, component, notDisplayMatch) {
  layoutRenderer.resetModelPoints(points, radius, component, notDisplayMatch)
}

function removeModelPoints(component) {
  layoutRenderer.removeModelPoints(component)
}

function getPointColor(key) {
  switch (key) {
    case "A":
      return "red";
    case "B":
      return "green";
    case "C":
      return "blue";
    default: return "";
  }
}

function removeAllTriPoints() {
  layoutRenderer.removeAllTriPoints()
}

function reDrawAllTriPoints(pairs, component, notDisplayMatch) {
  layoutRenderer.reDrawAllTriPoints(pairs, component, notDisplayMatch)
}

function highlightPins(pins, component, prevPowerPins, prevRefPins) {
  removeHighlightPins(prevPowerPins, prevRefPins, component)
  layoutRenderer.setColorForSelectedPins({ [component]: pins }, "magenta");
  layoutRenderer.zoomToPins({ [component]: pins })
}

function removeHighlightPins(prevPowerPins, prevRefPins, component) {
  prevPowerPins.length && layoutRenderer.setColorForSelectedPins({ [component]: prevPowerPins }, 'coral');
  prevRefPins.length && layoutRenderer.setColorForSelectedPins({ [component]: prevRefPins }, 'cornflowerblue');
}

function updateTriPairs(pairs) {
  layoutRenderer.triPairs = pairs;
}

function resizeCanvas(height) {
  layoutRenderer.resizeCanvas(height)
}
function removeGridLines() {
  layoutRenderer.removeGridLines()
}

function drawGridLine(column, row, targetIC) {
  layoutRenderer.drawGridLine(column, row, targetIC)
}

function getCurrentPoints(targetIC) {
  return layoutRenderer.getCurrentPoints(targetIC)
}

function getCurrentLines() {
  return layoutRenderer.getCurrentLines()
}

function getPinPath(pins, size, widthSize, heightSize) {
  layoutRenderer.getPinPath(pins, size, widthSize, heightSize)
}

function removePinPath() {
  layoutRenderer.removePinPath()
}

function getPinInfo(comp, pin) {
  const pinList = layoutRenderer.getPinInfo(comp, pin);
  return pinList && pinList.length ? pinList[0] : null;
}

function hiddenNotDisplayPins(showPinList) {
  layoutRenderer.hiddenPins(showPinList);
}

export {
  initLayout,
  removeCanvas,
  updateLayers,
  fitView,
  zoomIn,
  zoomOut,
  search,
  searchNet,
  changeColorMode,
  getRelatedNets,
  getLayerColor,
  findCurrentLayer,
  setPinColorByNets,
  setPowerNetsColor,
  selectPins,
  showPinsName,
  selectPortsGroups,
  getPinsDetail,
  getPinLocation,
  setPinsOpacity,
  updatePortSetupPinsInfo,
  updatePortSetupEventFn,
  setAllPinsOpacity,
  clearRightClickStatus,
  resetModelPoints,
  removeModelPoints,
  getPointColor,
  removeAllTriPoints,
  reDrawAllTriPoints,
  highlightPins,
  updateTriPairs,
  resizeCanvas,
  removeGridLines,
  drawGridLine,
  getCurrentPoints,
  getCurrentLines,
  getPinPath,
  removePinPath,
  zoomInFromPins,
  getPinInfo,
  hiddenNotDisplayPins
};

