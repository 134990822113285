import CeArc from './CeArc';
import CeRectangle from './CeRectangle';
import CeVertex from './CeVertex';
import CeVertexArc from './CeVertexArc';
import GCPolygon from './GCPolygon';
import StringHelper from '../utility/StringHelper';

const CeRectCutCorner = function () {
  CeRectangle.call(this, "RectCutCorner");
  this.mCornerR = 1;
  this.mChamfered = false;
  this.mCorners = [true, true, true, true];
}

// subclass extends superclass
CeRectCutCorner.prototype = Object.create(CeRectangle.prototype);
CeRectCutCorner.prototype.constructor = CeRectCutCorner;

CeRectCutCorner.prototype.SetData = function (cx, cy, r, chamfer, corner) {
  CeRectangle.call(this, "RectCutCorner");
  this.mCornerR = r;
  this.mChamfered = chamfer;
  this.mCorners = corner;
}

CeRectCutCorner.prototype.CopyFrom = function (fromObj) {
  CeRectangle.prototype.CopyFrom.call(this, fromObj);
  this.mCornerR = fromObj.mCornerR;
  this.mChamfered = fromObj.mChamfered;
  for (var i = 0; i < 4; i++) {
    this.mCorners[i] = fromObj.mCorners[i];
  }
}

CeRectCutCorner.prototype.Clone = function () {
  var clnObj = new CeRectCutCorner();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeRectCutCorner.prototype.ConvertToGCPolygon = function () {
  var ndiv = 5;
  var totalPnts = 4;
  if (this.mChamfered)
    totalPnts = 8;
  else
    totalPnts = 8 + 4 * (ndiv - 1);
  var xArray = [];
  xArray.length = totalPnts;
  var yArray = [];
  yArray.length = totalPnts;

  var halfW = 0.5 * this.mWidth;
  var halfH = 0.5 * this.mHeight;
  var llx = this.mCenter.mX - halfW;
  var lly = this.mCenter.mY - halfH;
  var rux = this.mCenter.mX + halfW;
  var ruy = this.mCenter.mY + halfH;
  var idx = 0;

  var startPnt = new CeVertex();
  var endPnt = new CeVertexArc();

  xArray[idx] = llx + this.mCornerR;
  yArray[idx] = lly;
  idx++;
  xArray[idx] = rux - this.mCornerR;
  yArray[idx] = lly;
  idx++;
  if (this.mChamfered == false) {
    startPnt.mX = rux - this.mCornerR;
    startPnt.mY = lly;
    endPnt.mX = rux;
    endPnt.mY = lly + this.mCornerR;
    endPnt.mCenter.mX = rux - this.mCornerR;
    endPnt.mCenter.mY = lly + this.mCornerR;
    var arcPolyline = CeArc.ArcToLineSegments2(ndiv, startPnt, endPnt);
    for (var i = 1; i < arcPolyline.GetNumPoints(); i++) {
      xArray[idx] = arcPolyline.mXs[i];
      yArray[idx] = arcPolyline.mYs[i];
      idx++;
    }
  }

  xArray[idx] = rux;
  yArray[idx] = lly + this.mCornerR;
  idx++;
  xArray[idx] = rux;
  yArray[idx] = ruy - this.mCornerR;
  idx++;
  if (this.mChamfered == false) {
    startPnt.mX = rux;
    startPnt.mY = ruy - this.mCornerR;
    endPnt.mX = rux - this.mCornerR;
    endPnt.mY = ruy;
    endPnt.mCenter.mX = rux - this.mCornerR;
    endPnt.mCenter.mY = ruy - this.mCornerR;
    var arcPolyline = CeArc.ArcToLineSegments2(ndiv, startPnt, endPnt);
    for (var i = 1; i < arcPolyline.GetNumPoints(); i++) {
      xArray[idx] = arcPolyline.mXs[i];
      yArray[idx] = arcPolyline.mYs[i];
      idx++;
    }
  }

  xArray[idx] = rux - this.mCornerR;
  yArray[idx] = ruy;
  idx++;
  xArray[idx] = llx + this.mCornerR;
  yArray[idx] = ruy;
  idx++;
  if (this.mChamfered == false) {
    startPnt.mX = llx + this.mCornerR;
    startPnt.mY = ruy;
    endPnt.mX = llx;
    endPnt.mY = ruy - this.mCornerR;
    endPnt.mCenter.mX = llx + this.mCornerR;
    endPnt.mCenter.mY = ruy - this.mCornerR;
    var arcPolyline = CeArc.ArcToLineSegments2(ndiv, startPnt, endPnt);
    for (var i = 1; i < ndiv; i++) {
      xArray[idx] = arcPolyline.mXs[i];
      yArray[idx] = arcPolyline.mYs[i];
      idx++;
    }
  }

  xArray[idx] = llx;
  yArray[idx] = ruy - this.mCornerR;
  idx++;
  xArray[idx] = llx;
  yArray[idx] = lly + this.mCornerR;
  idx++;
  if (this.mChamfered == false) {
    startPnt.mX = llx;
    startPnt.mY = lly + this.mCornerR;
    endPnt.mX = llx + this.mCornerR;
    endPnt.mY = lly;
    endPnt.mCenter.mX = llx + this.mCornerR;
    endPnt.mCenter.mY = lly + this.mCornerR;
    var arcPolyline = CeArc.ArcToLineSegments2(ndiv, startPnt, endPnt);
    for (var i = 1; i < ndiv; i++) {
      xArray[idx] = arcPolyline.mXs[i];
      yArray[idx] = arcPolyline.mYs[i];
      idx++;
    }
  }

  var gcPolygon = new GCPolygon();
  gcPolygon.SetData(totalPnts, xArray, yArray);
  return gcPolygon;
}

CeRectCutCorner.prototype.WriteIntoIODataNode = function () {
  var retItem = CeRectangle.prototype.WriteIntoIODataNode.call(this);
  return retItem;
}

CeRectCutCorner.prototype.ReadFromIODataNode = function (fileItem) {
  var success = CeRectangle.prototype.ReadFromIODataNode.call(this, fileItem);
  if (success == false)
    return false;

  var dataVals = fileItem.GetItemValue();
  if (dataVals.size() < 6)
    return false;

  this.mCornerR = parseFloat(dataVals.get(4));
  this.mChamfered = StringHelper.StringToBool(dataVals.get(5));

  for (var i = 6; i < dataVals.size() && i < 10; i++) {
    this.mCorners[i - 6] = StringHelper.StringToBool(dataVals.get(i));
  }

  return true;
}

CeRectCutCorner.prototype.GetDataString = function () {
  var defData = CeRectangle.prototype.GetDataString.call(this);
  defData.push(this.mCornerR);
  defData.push(StringHelper.BoolToString(this.mChamfered))
  for (var i = 0; i < 4; i++)
    defData.push(StringHelper.BoolToString(this.mCorners[i]));
  return defData;
}

CeRectCutCorner.prototype.UpdateByString = function (dataVals) {

  if (dataVals.size() < 5)
    return false;

  CeRectangle.prototype.UpdateByString.call(this, dataVals);
  this.mCornerR = parseFloat(dataVals.get(4));

  if (dataVals.size() == 5) {
    for (var i = 0; i < 4; i++)
      this.mCorners[i] = true;
  }

  return true;
}

export default CeRectCutCorner;