import React, { Component, Fragment } from 'react';
import { Button, Spin, Checkbox } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import EditableTable from '../../../../components/EditableTable';
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';

const columns = [
  {
    dataIndex: "check",
    key: "check",
    width: 80,
  },
  {
    title: 'Interface Name',
    dataIndex: 'verificationName',
    key: 'verificationName',
    width: "20%",
    render: (text, record) => {
      const obj = {
        children: <span>{record.verificationName}</span>,
        props: {
          rowSpan: record.verLength
        }
      }
      return obj;
    }
  },
  {
    title: 'Signal',
    dataIndex: 'name',
    key: 'name',
    width: "20%",
    render: (text, record) => {
      return <span>{record.name}</span>
    }
  },
  {
    title: 'Nets',
    dataIndex: 'nets',
    key: 'nets',
    width: "60%",
    render: (text, record) => {
      return <span>{(record.nets || []).join(", ")}</span>
    }
  }]


class CreateConfirmPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectKeys: []
    };
    this.dialogRoot = document.getElementById('root');

    columns[0].title = () => {
      const { checked, indeterminate, selectedProportion } = this.getCheckBoxStatus(null)
      return <Fragment>
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          onChange={(e) => this.checkInterface(e, null, "all")}
        />
        <span className="select-checkbox-title-proportion">{selectedProportion}</span>
      </Fragment>
    }

    columns[0].render = (check, record) => {
      const { selectKeys } = this.state;
      const obj = {
        children: <div className='sierra-re-create-interface-checkbox'>
          <Checkbox
            checked={selectKeys.includes(record.verificationName)}
            onChange={(e) => this.checkInterface(e, record.verificationName)}
          />
        </div>,
        props: {
          rowSpan: record.verLength
        }
      }
      return obj;
    }
  }

  componentDidMount = () => {
    const { duplicationVerificationInfo } = this.props;
    let data = [], selectKeys = [];
    for (let item of duplicationVerificationInfo.verifications) {
      let first = true;
      for (let signal of item.signals) {
        data.push({
          ...signal,
          verificationName: item.name,
          verLength: first ? item.signals.length : 0
        });
        first = false;
      }
      selectKeys.push(item.name)
    }
    this.setState({
      data,
      selectKeys
    })
  }

  getCheckBoxStatus = () => {
    const { selectKeys } = this.state;
    const { duplicationVerificationInfo } = this.props;
    const verifications = duplicationVerificationInfo.verifications || [];
    const selectedProportion = `${selectKeys.length}/${verifications.length}`;
    let checked = false, indeterminate = false;
    if (selectKeys.length === 0) { return { checked, indeterminate, selectedProportion }; }
    if (verifications.length === selectKeys.length) {
      checked = true;
      indeterminate = false;
    } else {
      checked = false;
      indeterminate = true;
    }
    return { checked, indeterminate, selectedProportion };
  }

  checkInterface = (e, key, all) => {
    const { selectKeys } = this.state;
    if (all) {
      const { duplicationVerificationInfo } = this.props;
      const verifications = duplicationVerificationInfo.verifications || [];
      if (e.target.checked) {
        this.setState({
          selectKeys: verifications.map(item => item.name)
        })
      } else {
        this.setState({
          selectKeys: []
        })
      }
      return;
    }
    if (e.target.checked) {
      this.setState({
        selectKeys: [...selectKeys, key]
      })
    } else {
      this.setState({
        selectKeys: selectKeys.filter(item => item !== key)
      })
    }
  }

  close = () => {
    this.props.reCreate({ cancel: true });
  }

  getFooter = () => {
    return <div className="create-footer-content">
      <Button key="re-create" type="primary" className='sierra-re-create-ok-button' onClick={this.reCreateClick}>Create</Button>
      <Button key="cancel" className='sierra-re-create-cancel-button' onClick={this.cancelConfirm} >Cancel</Button>
    </div>
  }

  reCreateClick = () => {
    this.props.reCreate({ selectKeys: this.state.selectKeys });
  }

  cancelConfirm = () => {
    this.props.reCreate({ cancel: true });
  }

  render() {
    const { data } = this.state;
    const { duplicationVerificationInfo, maxWidth, maxHeight } = this.props;
    const content = (
      <Panel
        className='sierra-create-interface-confirm-panel sierra-panel'
        title='Recreate Duplicate Interfaces'
        zIndex={2000}
        width={700}
        maxWidth={maxWidth}
        maxHeight={maxHeight - 40}
        position='panel-center'
        onCancel={this.close}
        overflow='auto'
        draggable
        footer={this.getFooter()}
      >
        <div className='sierra-create-interface-confirm-content'>
          <Spin
            spinning={duplicationVerificationInfo.loading ? true : false}
            tip={"Creating..."}>
            <div className='sierra-create-interface-confirm-content-title'>
              <span>The following interfaces already exist. Do you want to create them again?</span>
            </div>
            <EditableTable
              rowKey={(record) => `${record.verificationName}-${record.name}`}
              columns={columns}
              className="sierra-create-interface-confirm-table"
              size="small"
              dataSource={data}
              tableLayout="fixed"
            />
          </Spin>
        </div>
      </Panel>)

    return createPortal(content, this.dialogRoot);
  }
}

export default CreateConfirmPanel;