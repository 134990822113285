import {
  OPEN_UPLOAD_PROGRESS_MODAL,
  UPLOAD_PROGRESS_CHANGE,
  SHOW_ERROR_MSG,
  CLOSE_UPLOAD_PROGRESS_MODAL,
  TRANSLATION_RUNNING,
  TRANSLATION_FAILED,
  TRANSLATION_SUCCESS,
  TRANSLATION_MONITOR,
  UPLOAD_PROJECT_ID,
  ADD_MSG,
  CURRENT_UPLOAD_DESIGN_ID
} from './actionType';

const defalState = ({
  visible: false,
  uploadProgress: 0,
  transProgress: 0,
  msg: null,
  endMsg: null,
  // 0 - upload,1 - translate
  progressType: null,
  monitor: null,
  uploadProjectId: null,
  uploadDesignId: null,
})

const UploadReducer = (state = defalState, action) => {
  switch (action.type) {
    case OPEN_UPLOAD_PROGRESS_MODAL:
      return {
        ...state,
        visible: true,
        progressType: 0,
        uploadProgress: 0,
        monitor: null,
        msg: '',
        endMsg: '',
      }
    case UPLOAD_PROGRESS_CHANGE:
      const { uploadProgress } = action;
      return {
        ...state,
        uploadProgress,
      }
    case SHOW_ERROR_MSG:
      const { msg } = action;
      return {
        ...state,
        msg,
      }
    case CLOSE_UPLOAD_PROGRESS_MODAL:
      return {
        ...state,
        visible: false,
        uploadProgress: 0,
        transProgress: 0,
        msg: null,
        progressType: null,
        monitor: null
      }
    case TRANSLATION_RUNNING:
      const { transProgress } = action;
      return {
        ...state,
        progressType: 1,
        transProgress,
      }
    case TRANSLATION_SUCCESS:
      return {
        ...state,
        transProgress: action.transProgress,
        visible: false,
        uploadProgress: 0,
        transProgress: 0,
        progressType: null,
        endMsg: action.msg
      }
    case TRANSLATION_FAILED:
      return {
        ...state,
        transProgress: action.transProgress,
        endMsg: action.msg,
        visible: false,
        uploadProgress: 0,
        transProgress: 0,
        progressType: null,
      }
    case TRANSLATION_MONITOR:
      return {
        ...state,
        monitor: action.monitor
      }
    case UPLOAD_PROJECT_ID:
      return {
        ...state,
        uploadProjectId: action.projectId,
      }
    case ADD_MSG:
      return {
        ...state,
        msg: state.msg && (state.msg + '\n' + action.msg) || action.msg
      }
    case CURRENT_UPLOAD_DESIGN_ID:
      return {
        ...state,
        uploadDesignId: action.designId
      }
    default: return state
  }
}

export default UploadReducer;