import copy from 'copy-to-clipboard';

function compareAndUpdateHeight(basicCSSID, compareCSSID, fatherCSSID) {
  //get element
  let basicRef = document.getElementById(basicCSSID);
  let compareRef = document.getElementById(compareCSSID);

  //clear prev css height
  if (basicRef) {
    basicRef.style.height = null;
    //clear prev basic children first span height
    if (!fatherCSSID && basicRef && basicRef.childNodes && basicRef.childNodes.length === 2) {
      basicRef.childNodes[0].style.height = null;
    }
  }

  if (compareRef) {
    compareRef.style.height = null;
    //clear prev compare children first span height
    if (!fatherCSSID && compareRef && compareRef.childNodes && compareRef.childNodes.length === 2) {
      compareRef.childNodes[0].style.height = null;
    }
  }

  //get new element
  basicRef = document.getElementById(basicCSSID);
  compareRef = document.getElementById(compareCSSID);

  //get new height
  const basicHeight = basicRef && basicRef.offsetHeight;
  const compareHeight = compareRef && compareRef.offsetHeight;

  //Determine the height of two element height, take the maximum value
  let height = basicHeight > compareHeight ? basicHeight : compareHeight;
  //find min height element
  const smallRef = basicHeight > compareHeight ? compareRef : basicRef;

  //Determine the height of new height and father element height, take the maximum value
  if (fatherCSSID) {
    const fatherRef = document.getElementById(fatherCSSID);
    const fatherHeight = fatherRef && fatherRef.offsetHeight;
    height = fatherHeight > height ? fatherHeight : height;
  }

  //update new height
  if (basicRef) {
    basicRef.style.height = height + 'px';
  }

  if (compareRef) {
    compareRef.style.height = height + 'px';
  }

  // reset min height element children first span height (Vertically centered)
  if (!fatherCSSID && smallRef && smallRef.childNodes && smallRef.childNodes.length === 2) {
    smallRef.childNodes[0].style.height = height + "px";
  }
}

function updateElePosition({ basicTopCSSID, compareTopCSSID, basicCompCSSID, compareCompCSSID, basicOPTCSSID, compareOPTCSSID, basicVRMCSSID, compareVRMCSSID }) {
  //get  top div element
  const basicTopRef = document.getElementById(basicTopCSSID);
  const compareTopRef = document.getElementById(compareTopCSSID);

  const basicTopHeight = basicTopRef && basicTopRef.offsetHeight || 0;
  const compareTopHeight = compareTopRef && compareTopRef.offsetHeight || 0;

  //get component table ele
  let basicCompRef = document.getElementById(basicCompCSSID);
  let compareCompRef = document.getElementById(compareCompCSSID);

  let basicCompHeight = basicCompRef && basicCompRef.offsetHeight || 0;
  let compareCompHeight = compareCompRef && compareCompRef.offsetHeight || 0;

  let compTop = 0;

  //basic height > compare height, compare component table margin top add Difference, basic component table margin top to 0
  if (basicTopHeight >= compareTopHeight && compareCompRef && basicCompRef) {
    compTop = basicTopHeight - compareTopHeight;
    compareCompRef.style.marginTop = (compTop + 6) + 'px';
    basicCompRef.style.marginTop = 0 + 'px';
  }

  //compare height > basic height, basic component table margin top add Difference, compare component table margin top to 0
  if (compareTopHeight >= basicTopHeight && basicCompRef && compareCompRef) {
    compTop = compareTopHeight - basicTopHeight;
    basicCompRef.style.marginTop = (compTop + 6) + 'px';
    compareCompRef.style.marginTop = 0 + 'px';
  }

  let basicOPTRef = document.getElementById(basicOPTCSSID);
  let compareOPTRef = document.getElementById(compareOPTCSSID);

  //get new basic and compare component table 
  basicCompRef = document.getElementById(basicCompCSSID);
  compareCompRef = document.getElementById(compareCompCSSID);

  basicCompHeight = basicCompRef && basicCompRef.offsetHeight || 0;
  compareCompHeight = compareCompRef && compareCompRef.offsetHeight || 0;

  //top div + component table height
  const basicTop = basicCompHeight;
  const compareTop = compareCompHeight;

  //basic height > compare height, compare opt table margin top add Difference, basic opt table margin top to 0
  if (basicTop >= compareTop && compareOPTRef && basicOPTRef) {
    compareOPTRef.style.marginTop = (basicTop - compareTop + 6) + 'px';
    basicOPTRef.style.marginTop = 0 + 'px';
  }

  //compare height > basic height, basic opt table margin top add Difference, compare opt table margin top to 0
  if (compareTop >= basicTop && basicOPTRef && compareOPTRef) {
    basicOPTRef.style.marginTop = (compareTop - basicTop + 6) + 'px';
    compareOPTRef.style.marginTop = 0 + 'px';
  }

  let basicVRMRef = document.getElementById(basicVRMCSSID);
  let compareVRMRef = document.getElementById(compareVRMCSSID);

  basicOPTRef = document.getElementById(basicOPTCSSID);
  compareOPTRef = document.getElementById(compareOPTCSSID);

  basicCompHeight = basicOPTRef && basicOPTRef.offsetHeight || 0;
  compareCompHeight = compareOPTRef && compareOPTRef.offsetHeight || 0;

  //top div + component table height
  const basicOPTTop = basicCompHeight;
  const compareOPTTop = compareCompHeight;

  //basic height > compare height, compare opt table margin top add Difference, basic opt table margin top to 0
  if (basicOPTTop >= compareOPTTop && compareVRMRef && basicVRMRef) {
    compareVRMRef.style.marginTop = (basicOPTTop - compareOPTTop + 6) + 'px';
    basicVRMRef.style.marginTop = 0 + 'px';
  }

  //compare height > basic height, basic opt table margin top add Difference, compare opt table margin top to 0
  if (compareOPTTop >= basicOPTTop && basicVRMRef && compareVRMRef) {
    basicVRMRef.style.marginTop = (compareOPTTop - basicOPTTop + 6) + 'px';
    compareVRMRef.style.marginTop = 0 + 'px';
  }
}

function getPopoverPlacement({
  cssId,
  type,
  maxWidth,
  maxHeight
}) {
  let currentPinInputElement = document.getElementById(cssId);
  let leftPlacement = "top", rightPlacement = "top";
  if (currentPinInputElement) {
    //width: the width of the popover
    const width = maxWidth - 200 > 100 ? maxWidth - 200 : 100;
    const height = maxHeight - 210 > 200 ? maxHeight - 210 : 200;

    //get current port input position
    const offset = currentPinInputElement.getBoundingClientRect();
    const { left, top } = offset;
    //GET body offsetWidth,offsetHeight
    const bodyWidth = document.body.offsetWidth;
    const bodyHeight = document.body.offsetHeight;
    //Calculate the right of the current Input
    const right = bodyWidth - left - 40;
    //Calculate the bottom of the current Input
    const bottom = bodyHeight - top - 10;

    //When the top of the input of the current port is less than half of the height of the popover, 
    //the placement of the popover of pinL,pinR is displayed as "bottom"
    if ((height / 2) > (top + 10)) {
      leftPlacement = "bottom";
      rightPlacement = "bottom";
    }

    //When the bottom of the input of the current port is less than half of the height of the popover, 
    //the placement of the popover of pinL,pinR is displayed as "top"
    if ((height / 2) > (bottom + 10)) {
      leftPlacement = "top";
      rightPlacement = "top";
    }

    if (type.match("pinL")) {

      if ((width / 2) > (left + 40)) {
        //When the left of the input of the current port is less than half of the width of the popover, 
        //the placement of the popover of pinL is displayed as right
        leftPlacement = "right";
      }

      //left < width / 2 and bottom < height / 2 
      if (((width / 2) > (left + 40)) && ((height / 2) > (bottom + 10))) {
        leftPlacement = "rightBottom";
      }

      //left < width / 2 and top < height / 2 
      if (((width / 2) > (left + 40)) && ((height / 2) > (top + 10))) {
        leftPlacement = "rightTop";
      }
    }

    if (type.match("pinR")) {
      if ((width / 2) > right) {
        //When the input right of the current port is less than half the width of the popover,
        //the placement of the popover of pinR is displayed as left
        rightPlacement = "left";
      }

      //right < width / 2 and bottom < height / 2 
      if (((width / 2) > right) && ((height / 2) > (bottom + 10))) {
        rightPlacement = "leftBottom";
      }

      //right < width / 2 and top < height / 2 
      if (((width / 2) > right) && ((height / 2) > (top + 10))) {
        rightPlacement = "leftTop";
      }
    }
  }
  return { leftPlacement, rightPlacement }
}

function getOffsetLeft(obj) {
  let tmp = obj.offsetLeft;
  let node = obj.offsetParent;
  while (node != null) {
    tmp += node.offsetLeft;
    node = node.offsetParent;
  }
  return tmp;
}


function getOffsetTop(obj) {
  let tmp = obj.offsetTop;
  let node = obj.offsetParent;
  while (node != null) {
    tmp += node.offsetTop;
    node = node.offsetParent;
  }
  return tmp;
}

function getScreenLeft(obj) {
  let tmp = obj.offsetLeft - obj.scrollLeft;
  let node = obj.offsetParent;
  while (node != null) {
    tmp += node.offsetLeft;
    tmp -= node.scrollLeft;
    node = node.offsetParent;
  }
  return tmp;
}


function getScreenTop(obj) {
  // If used in aurora panel, set panel body position to relative
  let tmp = obj.offsetTop - obj.scrollTop;
  let node = obj.offsetParent;
  while (node != null) {
    tmp += node.offsetTop;
    tmp -= node.scrollTop;
    node = node.offsetParent;
  }
  return tmp;
}

function copyToClipboard(e, text, cssId) {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      showCopySuccess(cssId);
    });
  } else {
    if (copy(text)) {
      showCopySuccess(cssId);
    }
  }
  //Only available in safe environments -navigator.clipboard
  /*  if (navigator.clipboard) {
     // clipboard api copy
     navigator.clipboard.writeText(text).then(() => {
 
       var eleTips = document.createElement('span');
       eleTips.className = 'text-popup';
       eleTips.innerHTML = 'Copy successfully!';
       document.body.appendChild(eleTips);
       eleTips.addEventListener('animationend', function () {
         eleTips.parentNode.removeChild(eleTips);
       });
       const copyButton = document.getElementById(cssId);
       const top = getScreenTop(copyButton),
         left = getScreenLeft(copyButton);
       eleTips.style.left = (left - eleTips.clientWidth / 2) + 'px';
       eleTips.style.top = (top - eleTips.clientHeight + 20) + 'px';
     })
   } */
}

function showCopySuccess(cssId) {
  let eleTips = document.createElement('span');
  eleTips.className = 'text-popup';
  eleTips.innerHTML = 'Copy successfully!';
  document.body.appendChild(eleTips);
  eleTips.addEventListener('animationend', function () {
    eleTips.parentNode.removeChild(eleTips);
  });
  const copyButton = document.getElementById(cssId);
  if (copyButton) {
    const top = getScreenTop(copyButton),
      left = getScreenLeft(copyButton);
    eleTips.style.left = (left - eleTips.clientWidth / 2) + 'px';
    eleTips.style.top = (top - eleTips.clientHeight + 20) + 'px';
  }
}

export {
  compareAndUpdateHeight,
  updateElePosition,
  getPopoverPlacement,
  getOffsetLeft,
  getOffsetTop,
  getScreenLeft,
  getScreenTop,
  copyToClipboard
};