export default class SettingTableData {
  constructor() {
    this.settingData = new Map();
  }

  get = (key) => {
    return this.settingData.get(key) || null;
  }

  set = (key, data) => {
    this.settingData.set(key, data);
  }

  setDataMap = (dataObj) => {
    for (let key in dataObj) {
      this.settingData.set(key, dataObj[key]);
    }
  }

  remove = () => {
    this.settingData = new Map();
  }
}
