import { getAllCascadeComponents, getAllCascadeNets, getCascadeComponents } from "../helper/setupData";
import { updateTemplateItemCompsByVersion } from "./signOffComponentHelper";
import settingStore from '../helper/compSettingHelper';
import { SIGN_OFF_TEMPLATE_VERSION } from "../../../version";
import { getMessageByStatus } from '../../workflow/workflowHelper';
import { SortFn } from "../../helper/sort";
import { groupSignOffTemplate } from "./impedanceInfo";
import { unitChange } from "../../helper/mathHelper";
import { valueUnitSplit } from "../../helper/valueUnitSplit";
import { PMIC } from "../../../constants/componentType";

function getSignOffTemplateColumns(signOffTemplate = []) {

  let columns = [
    /* {
      title: "NO.",
      dataIndex: "index",
      width: 50
    }, */
    {
      title: "Power Net",
      dataIndex: "powerNetName",
      /*   width: 180 */
      textWrap: 'word-break',
      ellipsis: true,
    },
    {
      title: "GND Net",
      dataIndex: "gndNetName",
      width: 130,
      textWrap: 'word-break',
      ellipsis: true,
    },
    {
      title: "Component",
      dataIndex: "refdes",
      width: 100,
      textWrap: 'word-break',
      ellipsis: true,
    }
  ]
  //add comp type
  const findCompType = signOffTemplate.filter(item => item.compType);

  if (findCompType.length) {
    columns.push({
      title: "Type",
      dataIndex: "compType",
      width: 70,
      textWrap: 'word-break',
      ellipsis: true,
    })
  }

  //add voltage
  const findVoltage = signOffTemplate.filter(item => !!item.voltage);
  if (findVoltage.length) {
    columns.push({
      title: "Voltage (V)",
      dataIndex: "voltage",
      width: 120,
      textWrap: 'word-break',
      ellipsis: true,
    })
  }

  /*  columns.push(
     {
       title: "Peak Current (mA)",
       dataIndex: "peakCurrent",
       width: 150
     })
  */
  //add comp type
  const findPortName = signOffTemplate.filter(item => !!item.portName);
  columns.push(
    {
      title: "Port Name",
      dataIndex: "portName",
      width: findPortName && findPortName.length ? 140 : 90,
      textWrap: 'word-break',
      ellipsis: true,
    })

  columns.push(
    {
      title: "Power Pins",
      titleText: "Power Pins",
      dataIndex: "powerPins",
      textWrap: 'word-break',
      ellipsis: true,
      /*    width: 200 */
    },
    {
      title: "Ground Pins",
      titleText: "Ground Pins",
      dataIndex: "groundPins",
      textWrap: 'word-break',
      ellipsis: true,
      /*  width: 200, */
      className: "cascade-sign-off-table-ground-pin-title"
    });

  const frequencyList = getFrequencyList(signOffTemplate, "impedance");
  const impedanceUnit = getImpedanceUnits(signOffTemplate, "impedance")[0] || "mΩ";
  const couplingFrequencyList = getFrequencyList(signOffTemplate, "couplingImpedance");
  const couplingImpedanceUnit = getImpedanceUnits(signOffTemplate, "couplingImpedance")[0] || "mΩ";
  const shortSocFrequencyList = getFrequencyList(signOffTemplate, "shortSocCoupling");
  const shortSocImpedanceUnit = getImpedanceUnits(signOffTemplate, "shortSocCoupling")[0] || "mΩ";
  const indFrequencyList = getFrequencyList(signOffTemplate, "inductanceSpec");
  const indUnit = getImpedanceUnits(signOffTemplate, "inductanceSpec", true)[0] || "pH";
  const pmicUnit = getLoopDcrSpecUnitList(signOffTemplate, 'pmicUnit');
  const senseUnit = getLoopDcrSpecUnitList(signOffTemplate, 'senseUnit');

  let loopDcrSpecObj = {
    title: "Loop DCR Spec",
    titleText: "Loop DCR Spec",
    dataIndex: "loopDcrSpec",
    key: "loopDcrSpec",
    textWrap: 'word-break',
    ellipsis: true,
    /*  width: 150, */
  }

  loopDcrSpecObj.children = []

  loopDcrSpecObj.children.push({
    title: `PMIC Spec (${pmicUnit})`,
    dataIndex: `loopDcrSpec_soc`,
    textWrap: 'word-break',
    ellipsis: true
  }, {

    title: `PMIC BGA to SOC BGA (${pmicUnit})`,
    dataIndex: `loopDcrSpec_PMIC`,
    textWrap: 'word-break',
    ellipsis: true
  }, {
    title: `Sense Spec (${senseUnit})`,
    dataIndex: `loopDcrSpec_sense`,
    textWrap: 'word-break',
    ellipsis: true
  }, {
    title: `Vsense to SOC BGA (${senseUnit})`,
    dataIndex: `loopDcrSpec_Vsense`,
    textWrap: 'word-break',
    ellipsis: true
  })
  columns.push(loopDcrSpecObj);

  let impedanceObj = {
    title: "Self Impedance Spec",
    dataIndex: "impedance",
    key: "impedance",
    textWrap: 'word-break',
    ellipsis: true,
    /*  width: 250, */
  }

  if (frequencyList.length) {
    impedanceObj.children = frequencyList.map(item => {
      const freq = frequencyStrFormat(item);
      return {
        title: `${impedanceUnit}@${item}`,
        titleText: `${impedanceUnit}@${item}`,
        dataIndex: `impedance_${impedanceUnit}_${freq}`,
        textWrap: 'word-break',
        ellipsis: true,
      }
    });
  }
  //Impedance rl value
  const findRLImpedance = signOffTemplate.find(item => item.impedance && item.impedance.find(it => it.res && it.ind))
  if (findRLImpedance) {
    if (!impedanceObj.children) {
      impedanceObj.children = [];
    }
    const findRL = findRLImpedance.impedance.find(it => it.res && it.ind) || {};
    const rUnit = findRL.res.unit || "mΩ";
    const iUnit = findRL.ind.unit || "pH";
    //rlResult
    const rlChildren = [{
      title: `Resistance (${rUnit})`,
      titleText: `Resistance (${rUnit})`,
      dataIndex: `impedance_res`,
      textWrap: 'word-break',
      ellipsis: true,
    }, {
      title: `Inductance (${iUnit})`,
      titleText: `Inductance (${iUnit})`,
      dataIndex: `impedance_ind`,
      textWrap: 'word-break',
      ellipsis: true,
    }];
    if (findRL.rlResult) {
      rlChildren.push({
        title: `Result`,
        titleText: `Result`,
        dataIndex: `impedance_rl_result`,
        textWrap: 'word-break',
        ellipsis: true,
      })
    }

    impedanceObj.children.push({
      title: `RL Value`,
      titleText: `RL Value`,
      dataIndex: `impedance_rl_value`,
      textWrap: 'word-break',
      ellipsis: true,
      children: rlChildren
    })
  }

  columns.push(impedanceObj);

  let inductanceObj = {
    title: "Inductance Spec",
    dataIndex: "inductanceSpec",
    key: "inductanceSpec",
    textWrap: 'word-break',
    ellipsis: true,
    /*  width: 250, */
  }

  if (indFrequencyList.length) {
    inductanceObj.children = indFrequencyList.map(item => {
      const freq = frequencyStrFormat(item);
      return {
        title: `${indUnit}@${item}`,
        titleText: `${indUnit}@${item}`,
        dataIndex: `inductanceSpec_${indUnit}_${freq}`,
        textWrap: 'word-break',
        ellipsis: true,
      }
    });
  }
  columns.push(inductanceObj);

  let couplingObj = {
    title: "SOC Max Coupling Impedance Spec",
    dataIndex: "couplingImpedance",
    key: "coupling",
    textWrap: 'word-break',
    ellipsis: true,
    /*  width: 250, */
  }

  if (couplingFrequencyList.length) {
    //couplingResult
    if (!couplingObj.children) {
      couplingObj.children = [];
    }
    for (let item of couplingFrequencyList) {
      const freq = frequencyStrFormat(item);
      couplingObj.children.push({
        title: `${couplingImpedanceUnit}@${item}`,
        titleText: `${couplingImpedanceUnit}@${item}`,
        dataIndex: `couplingImpedance_${couplingImpedanceUnit}_${freq}`,
        textWrap: 'word-break',
        ellipsis: true
      })
    }
  }
  //couplingImpedance rl value
  let findRLCouplingImpedance = signOffTemplate.find(item => item.couplingImpedance && item.couplingImpedance.find(it => it.res && it.ind && it.rlResult));
  if (!findRLCouplingImpedance) {
    findRLCouplingImpedance = signOffTemplate.find(item => item.couplingImpedance && item.couplingImpedance.find(it => it.res && it.ind));
  }
  if (findRLCouplingImpedance) {
    if (!couplingObj.children) {
      couplingObj.children = [];
    }
    const findRL = findRLCouplingImpedance.couplingImpedance.find(it => it.res && it.ind) || {};
    const rUnit = findRL.res.unit || "mΩ";
    const iUnit = findRL.ind.unit || "pH";
    const rlChildren = [{
      title: `Resistance (${rUnit})`,
      titleText: `Resistance (${rUnit})`,
      dataIndex: `couplingImpedance_res`,
      textWrap: 'word-break',
      ellipsis: true,
    }, {
      title: `Inductance (${iUnit})`,
      titleText: `Inductance (${iUnit})`,
      dataIndex: `couplingImpedance_ind`,
      textWrap: 'word-break',
      ellipsis: true,
    }];
    if (findRL.rlResult) {
      rlChildren.push({
        title: `Result`,
        titleText: `Result`,
        dataIndex: `couplingImpedance_rl_result`,
        textWrap: 'word-break',
        ellipsis: true,
      })
    }
    couplingObj.children.push({
      title: `RL Value`,
      titleText: `RL Value`,
      dataIndex: `couplingImpedance_rl_value`,
      textWrap: 'word-break',
      ellipsis: true,
      children: rlChildren
    })
  }

  columns.push(couplingObj);

  // short soc coupling
  let shortsocObj = {
    title: "PMIC Max Coupling Impedance Spec",
    dataIndex: "shortSocCoupling",
    key: "shortsoc",
    textWrap: 'word-break',
    ellipsis: true,
    /*  width: 250, */
  }
  if (shortSocFrequencyList.length) {
    //couplingResult
    if (!shortsocObj.children) {
      shortsocObj.children = [];
    }
    for (let item of shortSocFrequencyList) {
      const freq = frequencyStrFormat(item);
      shortsocObj.children.push({
        title: `${shortSocImpedanceUnit}@${item}`,
        titleText: `${shortSocImpedanceUnit}@${item}`,
        dataIndex: `shortSocCoupling_${shortSocImpedanceUnit}_${freq}`,
        textWrap: 'word-break',
        ellipsis: true
      })
    }
  }
  //couplingImpedance rl value
  let findRLShortSocCoupling = signOffTemplate.find(item => item.shortSocCoupling && item.shortSocCoupling.find(it => it.res && it.ind && it.rlResult));
  if (!findRLShortSocCoupling) {
    findRLShortSocCoupling = signOffTemplate.find(item => item.shortSocCoupling && item.shortSocCoupling.find(it => it.res && it.ind));
  }
  if (findRLShortSocCoupling) {
    if (!shortsocObj.children) {
      shortsocObj.children = [];
    }
    const findRL = findRLShortSocCoupling.shortSocCoupling.find(it => it.res && it.ind) || {};
    const rUnit = findRL.res.unit || "mΩ";
    const iUnit = findRL.ind.unit || "pH";
    const rlChildren = [{
      title: `Resistance (${rUnit})`,
      titleText: `Resistance (${rUnit})`,
      dataIndex: `shortSocCoupling_res`,
      textWrap: 'word-break',
      ellipsis: true,
    }, {
      title: `Inductance (${iUnit})`,
      titleText: `Inductance (${iUnit})`,
      dataIndex: `shortSocCoupling_ind`,
      textWrap: 'word-break',
      ellipsis: true,
    }];
    if (findRL.rlResult) {
      rlChildren.push({
        title: `Result`,
        titleText: `Result`,
        dataIndex: `shortSocCoupling_rl_result`,
        textWrap: 'word-break',
        ellipsis: true,
      })
    }
    shortsocObj.children.push({
      title: `RL Value`,
      titleText: `RL Value`,
      dataIndex: `shortSocCoupling_rl_value`,
      textWrap: 'word-break',
      ellipsis: true,
      children: rlChildren
    })
  }

  columns.push(shortsocObj);

  return columns;
}

function getFrequencyList(signOffTemplate = [], type) {
  let frequencyList = [];
  for (let item of signOffTemplate) {
    if (!item[type]) {
      continue
    }
    const points = [...item[type]].map(it => {
      if (it.res && it.ind) {
        return null;
      }
      const { value: frequency, unit: frequencyUnit } = valueUnitSplit(it.frequency || "");
      return {
        frequencyValue: unitChange({ num: frequency, oldUnit: frequencyUnit || "Hz", newUnit: 'Hz' }).number,
        ...it
      }
    }).filter(item => !!item)
    const _points = points.sort((a, b) => a.frequencyValue - b.frequencyValue);
    frequencyList.push(..._points.map(it => it.frequency))
  }

  return [...new Set(frequencyList)]
}

function ohmUnitConversion(unit) {
  if (typeof (unit) !== "string") {
    return unit;
  }
  if (unit.match("Ω")) {
    unit = unit.replace("Ω", "Ω");
  }
  return unit;
}


function getImpedanceUnits(signOffTemplate = [], type, isInd) {
  let unitList = [];
  for (let item of signOffTemplate) {
    if (!item[type]) {
      continue
    }
    if (isInd) {
      unitList.push(...item[type].map(it => it.unit))
    } else {
      unitList.push(...item[type].map(it => ohmUnitConversion(it.unit)))
    }
  }

  return [...new Set(unitList)].filter(item => !!item)
}

function frequencyStrFormat(freq, back = false) {
  return back ? freq.replace('$', ".").replace("R", "-").replace("A", "+") :
    freq.replace('.', "$").replace("-", "R").replace("+", "A")
}

function getLoopDcrSpecUnitList(signOffTemplate = [], key) {
  if(signOffTemplate.length) {
    for(let template of signOffTemplate) {
      if(template.loopDcrSpec && template.loopDcrSpec[key]) {
        return template.loopDcrSpec[key]
      }
    }
  }
  return 'mΩ'
}

function getSignOffTemplateData(signOffTemplate = []) {
  let data = [];
  let groupKeys = [], groupIndexObj = {}, groupIndex = 0;

  for (let i = 0; i < signOffTemplate.length; i++) {
    const template = signOffTemplate[i];
    if (!groupKeys.includes(template.groupKey)) {
      groupIndexObj[template.groupKey] = groupIndex++;
    }
    let info = {
      index: template.index,
      powerNetName: template.powerNetName,
      gndNetName: template.gndNetName,
      refdes: template.refdes,
      compType: template.compType,
      voltage: template.voltage,
      peakCurrent: template.peakCurrent,
      powerPins: template.powerPins,
      groundPins: template.groundPins,
      groupKey: template.groupKey,
      templateLength: groupKeys.includes(template.groupKey) ? 0 : 1,
      effectivePowerPins: template.effectivePowerPins,
      effectiveGroundPins: template.effectiveGroundPins,
      groupSortIndex: groupIndexObj[template.groupKey],
      portName: template.portName
    }

    groupKeys.push(template.groupKey);

    if (template.loopDcrSpec) {
      info[`loopDcrSpec_PMIC`] = { actualValue: template.loopDcrSpec.pmicValue || '', value: template.loopDcrSpec.pmicSpec || '' };
      info['loopDcrSpec_Vsense'] = { actualValue: template.loopDcrSpec.senseValue || '', value: template.loopDcrSpec.senseSpec || '' };
      info[`loopDcrSpec_soc`] = { actualValue: template.loopDcrSpec.pmicValue || '', value: template.loopDcrSpec.pmicSpec || '' };
      info[`loopDcrSpec_sense`] = { actualValue: template.loopDcrSpec.senseValue || '', value: template.loopDcrSpec.senseSpec || '' };
    }

    for (let it of template.impedance || []) {
      if (it.res && it.ind) {
        info.impedance_res = it.res;
        info.impedance_ind = it.ind;
        info.impedance_rl_result = it.rlResult;
        continue;
      }

      const freq = frequencyStrFormat(it.frequency);
      info[`impedance_${ohmUnitConversion(it.unit)}_${freq}`] = it;
    }

    for (let it of template.inductanceSpec || []) {
      const freq = frequencyStrFormat(it.frequency);
      info[`inductanceSpec_${it.unit}_${freq}`] = it;
    }


    for (let it of template.couplingImpedance || []) {
      if (it.res && it.ind) {
        info.couplingImpedance_res = it.res;
        info.couplingImpedance_ind = it.ind;
        info.couplingImpedance_rl_result = it.rlResult;
        info.couplingImpedance_couplingResult = it.couplingResult;
        continue;
      }
      const freq = frequencyStrFormat(it.frequency);
      info[`couplingImpedance_${ohmUnitConversion(it.unit)}_${freq}`] = it;
    }

    for (let it of template.shortSocCoupling || []) {
      if (it.res && it.ind) {
        info.shortSocCoupling_res = it.res;
        info.shortSocCoupling_ind = it.ind;
        info.shortSocCoupling_rl_result = it.rlResult;
        info.shortSocCoupling_couplingResult = it.couplingResult;
        continue;
      }
      const freq = frequencyStrFormat(it.frequency);
      info[`shortSocCoupling_${ohmUnitConversion(it.unit)}_${freq}`] = it;
    }


    data.push(info)
  }

  for (let item of data) {
    const groupLength = data.filter(it => it.groupKey === item.groupKey).length;
    if (item.templateLength !== 0) {
      item.templateLength = groupLength;
    }
  }

  const sort = [...new Set(Array.from(Object.values(groupIndexObj)))];
  data = SortFn(data, sort, "groupSortIndex");
  return data;
}

async function checkAndUpdateSignOffTemplateContent({
  signOffTemplateInfo,
  isUpdateComp = true,
  group = false,
  createDCRTask = false,
  saveLogs
}) {
  let errors = [];

  const designId = signOffTemplateInfo.designId;
  const allComponents = getAllCascadeComponents({ pcbId: designId });
  let soc = signOffTemplateInfo.soc, gnd = signOffTemplateInfo.gnd;

  let netsList = getAllCascadeNets({ pcbId: designId });
  //add soc and gnd
  const targetICFind = signOffTemplateInfo.signOffTemplate.find(item => item.compType !== PMIC && !!item.refdes);
  if (!soc && targetICFind) {
    const targetComp = allComponents.get(targetICFind.refdes);
    soc = targetComp ? targetICFind.refdes : "";
    signOffTemplateInfo.soc = soc;
  }
  const gndNetFind = signOffTemplateInfo.signOffTemplate.find(item => item.compType !== PMIC && !!item.gndNetName);
  if (!gnd && gndNetFind) {
    const newGndNet = netsList.includes(gndNetFind.gndNetName);
    gnd = newGndNet ? gndNetFind.gndNetName : "";
    signOffTemplateInfo.gnd = gnd;
  }

  let newTemplates = [], tempPMICGroupKey = "";
  let powerErrorMap = new Map(),
    powerPinNotExistErrorMap = new Map(),
    powerPinNotConnNetErrorMap = new Map(),
    gndPinNotExistErrorMap = new Map(),
    gndPinNotConnNetErrorMap = new Map();

  for (let info of signOffTemplateInfo.signOffTemplate) {
    let { refdes, powerNetName, gndNetName, powerPins = [], groundPins = [], compType, groupKey } = info || {};
    let errorList = [];
    //find powerNet
    let notCreate = false, noSoc = false,
      newPowerPins = [],
      newGndPins = [];
    const powerNet = netsList.includes(powerNetName) ? powerNetName : null;
    const compName = refdes ? refdes : signOffTemplateInfo.soc;
    const compInfo = getCascadeComponents({ pcbId: designId, name: compName });

    if (!powerNetName) {
      if (compInfo && compInfo.pins) {
        const currentPin = powerPins.length ? compInfo.pins.get(powerPins[0]) : null;
        if (currentPin) {
          const powerNet = currentPin.net;
          info.powerNetName = powerNet;
          info.toNormalRow[1] = powerNet;
          if (groupKey) {
            if (compType !== PMIC) {
              tempPMICGroupKey = `${powerNet}::${refdes || soc}::${gndNetName || gnd}`
              info.groupKey = tempPMICGroupKey;
            }
          }
        }
      } else {
        notCreate = true;
        errors.push({
          title: `[Power Net]`,
          error: ` - Net does not exist.`
        });
        continue;
      }
    }
    const currNetErrorList = powerErrorMap.has(powerNetName) ? powerErrorMap.get(powerNetName) : [];

    if (powerNetName && !powerNet && !powerErrorMap.has(powerNetName)) {
      const errorItem = {
        title: `[Power Net]`,
        error: ` - Net ${powerNetName} does not exist.`
      }
      const findError = currNetErrorList.find(item => item.title === errorItem.title && item.error === errorItem.error)
      !findError && errorList.push(errorItem);
      notCreate = true;
    }

    if (!gndNetName) {
      if (compInfo && compInfo.pins) {
        const currentPin = groundPins.length ? compInfo.pins.get(groundPins[0]) : null;
        if (currentPin) {
          const groundNet = currentPin.net;
          info.gndNetName = groundNet;
          info.toNormalRow[2] = groundNet
          gndNetName = groundNet
          if (!gnd) {
            gnd = groundNet
            signOffTemplateInfo.gnd = gnd;
          }
          if (groupKey) {
            if (compType !== PMIC) {
              tempPMICGroupKey = `${info.powerNetName}::${refdes || soc}::${groundNet}`
              info.groupKey = tempPMICGroupKey;
            }
          }
        }
      }
    }

    if (groupKey && compType === PMIC) {
      const [pmicPower, pmic, pmicGnd] = groupKey.split("::");
      if (!pmicPower || !pmicGnd || !pmic) {
        info.groupKey = tempPMICGroupKey;
      }
    }

    const _gnd = gnd;
    //find groundNet
    const gndNet = netsList.includes(gndNetName) ? gndNetName : null;
    const newGndNetInfo = netsList.find(item => item.mName === _gnd);

    if (!gndNet && !_gnd) {
      const errorItem = {
        title: `[Ground Net]`,
        error: ` - Net ${gndNetName ? gndNetName + " " : ""}does not exist.`
      }
      const findError = currNetErrorList.find(item => item.title === errorItem.title && item.error === errorItem.error)
      !findError && errorList.push(errorItem);
      notCreate = true;
    }
    const newGndNet = !gndNet && _gnd && newGndNetInfo ? _gnd : gndNetName;

    //component check
    const findComp = allComponents.get(refdes);
    const socComp = allComponents.get(soc);
    if (!findComp && (compType !== PMIC && !socComp)) {
      const errorItem = {
        title: `[Component]`,
        error: ` - Component ${refdes ? refdes + " " : ""}does not exist.`
      }
      const findError = currNetErrorList.find(item => item.title === errorItem.title && item.error === errorItem.error)
      !findError && errorList.push(errorItem);
      noSoc = true;
    }

    const compPins = findComp ? [...findComp.pins.values()] || [] : [];
    const socPins = socComp && compType !== PMIC ? [...socComp.pins.values()] || [] : [];
    //power pins check

    const pins = findComp ? compPins : socPins;

    //power and ground pins not exist
    const findPowerPins = pins.filter(it => it.net === powerNetName);
    const findGroundPins = pins.filter(it => it.net === newGndNet);

    if (compType !== PMIC && (findComp || socComp) && !findPowerPins.length && !findGroundPins.length) {
      const pinErrorItem = {
        title: `[Port Power / Ground Pins]`,
        error: ` - Net ${powerNetName} power / ground Pins do not exist.`
      }
      const _findError = currNetErrorList.find(item => item.title === pinErrorItem.title && item.error === pinErrorItem.error)
      !_findError && errorList.push(pinErrorItem);
    }
    //only power pins not exist
    if (compType !== PMIC && (!powerPins || !powerPins.length) && !findPowerPins.length && findGroundPins.length) {
      const pinErrorItem = {
        title: `[Port Power Pins]`,
        error: ` - Net ${powerNetName} power pins do not exist.`
      }
      const _findError = currNetErrorList.find(item => item.title === pinErrorItem.title && item.error === pinErrorItem.error)
      !_findError && errorList.push(pinErrorItem);
    }

    //only ground pins not exist
    if (compType !== PMIC && (!groundPins || !groundPins.length) && findPowerPins.length && !findGroundPins.length) {
      const pinErrorItem = {
        title: `[Port Ground Pins]`,
        error: ` - Net ${newGndNet} ground pins do not exist.`
      }
      const _findError = currNetErrorList.find(item => item.title === pinErrorItem.title && item.error === pinErrorItem.error)
      !_findError && errorList.push(pinErrorItem);
    }

    if (powerPins && powerPins.length && !powerPins[0].match(/ALL/ig)) {
      const filterPins = powerPins.filter(item => !pins.find(it => it.pin === item));
      if (filterPins.length) {
        powerPinNotExistErrorMap.set(powerNetName, [...(powerPinNotExistErrorMap.get(powerNetName) || []), ...filterPins])
      }
      newPowerPins = powerPins.filter(item => !filterPins.includes(item));
      if (compType !== PMIC) {
        let _filterPins = [];
        for (let pin of powerPins) {
          if (filterPins.includes(pin)) { continue; }
          const findPin = pins.find(it => it.pin === pin);
          if (findPin.net !== powerNetName) {
            _filterPins.push(pin);
          }
        }

        if (_filterPins.length) {
          powerPinNotConnNetErrorMap.set(powerNetName, [...(powerPinNotConnNetErrorMap.get(powerNetName) || []), ..._filterPins])
        }
        newPowerPins = newPowerPins.filter(item => !_filterPins.includes(item));
      }
    }

    if (powerPins && powerPins.length && powerPins[0].match(/ALL/ig)) {
      info.effectivePowerPins = pins.filter(it => it.net === powerNetName).map(item => item.pin);
      newPowerPins = [...powerPins];
    } else {
      info.effectivePowerPins = [...newPowerPins];
    }
    //if create dcr and the user does not set the power pins, select all pins
    if (createDCRTask && compType !== PMIC && (!powerPins || !powerPins.length)) {
      info.effectivePowerPins = pins.filter(it => it.net === powerNetName).map(it => it.pin);
    }

    //ground pins check
    if (groundPins && groundPins.length && !groundPins[0].match(/ALL/ig)) {
      const filterPins = groundPins.filter(item => !pins.find(it => it.pin === item));
      if (filterPins.length) {
        newGndNet && gndPinNotExistErrorMap.set(powerNetName, [...(gndPinNotExistErrorMap.get(powerNetName) || []), ...filterPins])
      }
      newGndPins = groundPins.filter(item => !filterPins.includes(item));
      let _filterPins = [];
      for (let pin of groundPins) {
        if (filterPins.includes(pin)) { continue; }
        const findPin = pins.find(it => it.pin === pin);
        if (findPin.net !== newGndNet) {
          _filterPins.push(pin);
        }
      }
      if (_filterPins.length) {
        newGndNet && gndPinNotConnNetErrorMap.set(powerNetName, [...(gndPinNotConnNetErrorMap.get(powerNetName) || []), ..._filterPins])
      }
      newGndPins = newGndPins.filter(item => !_filterPins.includes(item));
    }

    if (groundPins && groundPins.length && groundPins[0].match(/ALL/ig)) {
      info.effectiveGroundPins = pins.filter(it => it.net === newGndNet).map(item => item.pin);
      newGndPins = [...groundPins];
    } else {
      info.effectiveGroundPins = [...newGndPins];
    }

    //if create dcr and the user does not set the gnd pins, select all pins
    if (createDCRTask && compType !== PMIC && (!groundPins || !groundPins.length)) {
      info.effectiveGroundPins = pins.filter(it => it.net === newGndNet).map(item => item.pin);
    }

    if (errorList.length) {
      if (powerErrorMap.has(powerNetName)) {
        powerErrorMap.set(powerNetName, [...powerErrorMap.get(powerNetName), ...errorList])
      } else {
        powerErrorMap.set(powerNetName, [...errorList]);
      }
    }
    if (notCreate || noSoc) {
      continue;
    }
    newTemplates.push({
      ...JSON.parse(JSON.stringify(info)),
      powerPins: [...newPowerPins],
      groundPins: [...newGndPins],
      effectiveGroundPins: [...info.effectiveGroundPins],
      effectivePowerPins: [...info.effectivePowerPins],
    })
  }

  //update pin errors
  if (powerPinNotExistErrorMap.size) {
    for (let netInfo of powerPinNotExistErrorMap.entries()) {
      const [net, pins] = netInfo || [];
      if (!net || !pins) {
        continue;
      }
      const text = pins.length > 1 ? true : false;
      const errorItem = {
        title: `[Port Power Pins]`,
        error: ` - ${text ? "Pins" : "Pin"} ${pins.join(", ")} ${text ? "do" : "does"} not exist.`
      }
      if (powerErrorMap.has(net)) {
        powerErrorMap.set(net, [...powerErrorMap.get(net), errorItem])
      } else {
        powerErrorMap.set(net, [errorItem]);
      }
    }
  }

  if (powerPinNotConnNetErrorMap.size) {
    for (let netInfo of powerPinNotConnNetErrorMap.entries()) {
      const [net, pins] = netInfo || [];
      if (!net || !pins) {
        continue;
      }
      const text = pins.length > 1 ? true : false;
      const errorItem = {
        title: `[Port Power Pins]`,
        error: ` - ${text ? "Pins" : "Pin"} ${pins.join(", ")} ${text ? "are" : "is"} not connected to power net ${net}.`
      }
      if (powerErrorMap.has(net)) {
        powerErrorMap.set(net, [...powerErrorMap.get(net), errorItem])
      } else {
        powerErrorMap.set(net, [errorItem]);
      }
    }
  }
  if (gndPinNotExistErrorMap.size) {
    for (let netInfo of gndPinNotExistErrorMap.entries()) {
      const [net, pins] = netInfo || [];
      if (!net || !pins) {
        continue;
      }
      const text = pins.length > 1 ? true : false;
      const errorItem = {
        title: `[Port Ground Pins]`,
        error: ` - ${text ? "Pins" : "Pin"} ${pins.join(", ")} ${text ? "do" : "does"} not exist.`
      }
      if (powerErrorMap.has(net)) {
        powerErrorMap.set(net, [...powerErrorMap.get(net), errorItem])
      } else {
        powerErrorMap.set(net, [errorItem]);
      }
    }
  }

  if (gndPinNotConnNetErrorMap.size) {
    for (let netInfo of gndPinNotConnNetErrorMap.entries()) {
      const [net, pins] = netInfo || [];
      if (!net || !pins) {
        continue;
      }
      const text = pins.length > 1 ? true : false;
      const errorItem = {
        title: `[Port Ground Pins]`,
        error: ` - ${text ? "Pins" : "Pin"} ${pins.join(", ")} ${text ? "are" : "is"} not connected to ground net ${net}.`
      }
      if (powerErrorMap.has(net)) {
        powerErrorMap.set(net, [...powerErrorMap.get(net), errorItem])
      } else {
        powerErrorMap.set(net, [errorItem]);
      }
    }
  }

  const findGroupKey = newTemplates.find(item => !item.groupKey || (item.groupKey.match("undefined") && soc && gnd));
  if (group || findGroupKey) {
    signOffTemplateInfo.signOffTemplate = groupSignOffTemplate(signOffTemplateInfo.signOffTemplate, soc, gnd);
    newTemplates = newTemplates.forEach(item => {
      const findItem = signOffTemplateInfo.signOffTemplate.find(it => it.index === item.index);
      item.groupKey = findItem ? findItem.groupKey : null;
    })
  }

  if (!isUpdateComp) {
    return { errors, powerErrorMap, signOffTemplateInfo, newTemplates }
  }
  signOffTemplateInfo = await updateCapComponents({
    signOffTemplateInfo,
    newTemplates,
    designId,
    saveLogs
  })
  return { errors, powerErrorMap, signOffTemplateInfo, newTemplates }
}

async function updateCapComponents({
  signOffTemplateInfo,
  newTemplates,
  designId,
  saveLogs
}) {
  signOffTemplateInfo = await updateTemplateItemCompsByVersion(signOffTemplateInfo, newTemplates, saveLogs);
  const _setting = await settingStore.getSetting({ designId });
  signOffTemplateInfo.compSettingVersion = _setting.version;
  signOffTemplateInfo.version = SIGN_OFF_TEMPLATE_VERSION;
  return signOffTemplateInfo;
}

function getPowerGndPinsByNetAndComp({
  comp,
  powerNet,
  gndNet,
  designId
}) {
  let powerPins = [], gndPins = [];
  const allComponents = getAllCascadeComponents({ pcbId: designId });

  const findComp = allComponents.get(comp);
  if (!findComp || !findComp.pins) {
    return { powerPins, gndPins };
  }

  powerPins = [...findComp.pins.values()].filter(item => item.net === powerNet).map(item => item.pin);
  gndPins = [...findComp.pins.values()].filter(item => item.net === gndNet).map(item => item.pin);
  return { powerPins, gndPins }
}

function getTemplatePorts({
  signOffTemplate,
  designId,
  record,
  soc,
  gnd
}) {
  //powerNetName, compType, gndNetName,refdes
  const recordSoc = record.refdes || soc, recordGnd = record.gndNetName || gnd;
  let ports = [];
  const compType = record.compType

  for (let i = 0; i < signOffTemplate.length; i++) {
    const item = signOffTemplate[i];

    if (item.compType !== compType || item.powerNetName !== record.powerNetName) {
      continue;
    }
    const itemSoc = item.refdes || soc, itemGnd = item.gndNetName || gnd;
    if (recordSoc !== itemSoc || recordGnd !== itemGnd) {
      continue;
    }

    let _powerPins = item.powerPins ? [...item.powerPins] : [],
      _referencePins = item.groundPins ? [...item.groundPins] : []
    const allPower = item.powerPins && item.powerPins[0] && item.powerPins[0].match(/ALL/ig),
      allGnd = item.groundPins && item.groundPins[0] && item.groundPins[0].match(/ALL/ig);

    if (allPower || allGnd) {
      const { powerPins, gndPins } = getPowerGndPinsByNetAndComp({
        comp: item.refdes || soc,
        powerNet: item.powerNetName,
        gndNet: item.gndNetName || gnd,
        designId
      });

      if (allPower) {
        _powerPins = [...powerPins]
      }
      if (allGnd) {
        _referencePins = [...gndPins]
      }
    }

    ports.push({
      port: ports.length + 1,
      index: item.index,
      portName: item.portName,
      powerPins: _powerPins,
      referencePins: _referencePins
    })
  }
  return ports;
}

function getTemplatePowerTasks(taskMapping, signOffTaskList = []) {
  let newTaskMapping = new Map();
  for (let item in taskMapping) {
    const taskIds = taskMapping[item] || [];
    const tasks = signOffTaskList.filter(it => taskIds.includes(it.id) && !!getMessageByStatus(it.status));
    if (!tasks.length) {
      continue;
    }
    newTaskMapping.set(item, tasks);
  }
  return newTaskMapping;
}

function getTemplatePathRTasks(pathRTaskMapping, signOffPathRList = []) {
  let newPathRMap = new Map()
  for (let item in pathRTaskMapping) {
    const taskIds = pathRTaskMapping[item] || []
    const tasks = signOffPathRList.filter(it => taskIds.includes(it.id) && !!getMessageByStatus(it.status))
    newPathRMap.set(item, tasks)
  }
  return newPathRMap
}

function templateOhmUnitConversion(signOffTemplate) {
  for (let temp of signOffTemplate) {
    if (temp.loopDcrSpec && temp.loopDcrSpec.pmicUnit) {
      temp.loopDcrSpec.pmicUnit = ohmUnitConversion(temp.loopDcrSpec.pmicUnit);
    }

    if (temp.loopDcrSpec && temp.loopDcrSpec.senseUnit) {
      temp.loopDcrSpec.senseUnit = ohmUnitConversion(temp.loopDcrSpec.senseUnit);
    }

    if (temp.couplingImpedance && temp.couplingImpedance.length) {
      for (let item of temp.couplingImpedance) {
        if (item.res && item.res.unit) {
          item.res.unit = ohmUnitConversion(item.res.unit);
        } else if (item.unit) {
          item.unit = ohmUnitConversion(item.unit);
        }
      }
    }

    if (temp.impedance && temp.impedance.length) {
      for (let item of temp.impedance) {
        if (item.res && item.res.unit) {
          item.res.unit = ohmUnitConversion(item.res.unit);
        } else if (item.unit) {
          item.unit = ohmUnitConversion(item.unit);
        }
      }
    }

    if (temp.shortSocCoupling && temp.shortSocCoupling.length) {
      for (let item of temp.shortSocCoupling) {
        if (item.res && item.res.unit) {
          item.res.unit = ohmUnitConversion(item.res.unit);
        } else if (item.unit) {
          item.unit = ohmUnitConversion(item.unit);
        }
      }
    }
  }
  return signOffTemplate;
}

//If the loop dcr spec does not exist, add the default 
function templateAddDefaultLoopDcrSpec(signOffTemplate) {
  const loopDcrFind = signOffTemplate.find(temp => temp.loopDcrSpec && temp.loopDcrSpec.unit);
  const loopDcrUnit = loopDcrFind ? loopDcrFind.loopDcrSpec.unit : "mΩ";

  for (let temp of signOffTemplate) {
    //add loop dcr spec 
    if (!temp.loopDcrSpec) {
      temp.loopDcrSpec = {
        pmicSpec: "",
        pmicUnit: loopDcrUnit,
        senseSpec: "",
        senseUnit: loopDcrUnit,
        sensePort: false
      }
    }
  }
  return signOffTemplate;
}

//If the loop dcr spec does not exist, add the default 
function templateUpdateLoopDcrSpec(signOffTemplate) {
  const loopDcrFind = signOffTemplate.find(temp => temp.loopDcrSpec && temp.loopDcrSpec.unit);
  const loopDcrUnit = loopDcrFind ? loopDcrFind.loopDcrSpec.unit : "mΩ";

  for (let temp of signOffTemplate) {
    //add loop dcr spec 
    const sensePort = temp.loopDcrSpec ? temp.loopDcrSpec.sensePort : false;
    if (!temp.loopDcrSpec) {
      temp.loopDcrSpec = {
        pmicSpec: "",
        pmicUnit: loopDcrUnit,
        senseSpec: "",
        senseUnit: loopDcrUnit,
        sensePort
      }
    } else if (!temp.loopDcrSpec.pmicSpec || !temp.loopDcrSpec.senseSpec) {
      temp.loopDcrSpec = {
        pmicSpec: sensePort ? "" : temp.loopDcrSpec.value,
        pmicUnit: loopDcrUnit,
        senseSpec: sensePort ? temp.loopDcrSpec.value : "",
        senseUnit: loopDcrUnit,
        sensePort
      }
    }
    let newPathRPorts = {};
    if (temp.pathRPorts && !temp.pathRPorts.sensePort) {
      const { gnd, power } = temp.pathRPorts;
      newPathRPorts = sensePort ? { senseGnd: gnd, sensePower: power } : { gnd, power }
    }
    temp.pathRPorts = { ...newPathRPorts };
  }
  return signOffTemplate;
}

export {
  getSignOffTemplateColumns,
  getSignOffTemplateData,
  checkAndUpdateSignOffTemplateContent,
  getPowerGndPinsByNetAndComp,
  getTemplatePorts,
  getTemplatePowerTasks,
  getTemplatePathRTasks,
  ohmUnitConversion,
  templateOhmUnitConversion,
  templateAddDefaultLoopDcrSpec,
  frequencyStrFormat,
  templateUpdateLoopDcrSpec
}