class PcbChannelConstructor {
  constructor() {
    this.list = new Map();
  }

  add = (list) => {
    for (let item of list) {
      this.list.set(item.id, item)
    }
  }

  get = (id) => {
    return this.list.get(id)
  }

  delete = (id) => {
    this.list.delete(id)
  }

  clearAll = () => {
    this.list = new Map();
  }
}

let pcbChannelConstructor = new PcbChannelConstructor();
export default pcbChannelConstructor;