import ParameterData from '../../../Result/Public/sparameter/parameterData';
import { npiFileParseV2 } from '../../../Result/Andes/dataHelper';
import { getChannelExtractionResult, getHistoryResults, getEndToEndChannelResult, getSweepExtractionResult } from '../../../api/Andes_v2/result';
import { getMultiPCBHistoryResults } from '../../../api/Andes_v2/multiPCBHistory';
import { getResultCurveDataPromise } from '../../channel/channelCtrl';
import { getAndesHistoryCurveData, getEndToEndChannelResultCurve, getAndesMultiPCBHistoryCurveData, getExperimentResultCurveData } from '../../results';
import { endToEndNpiParse } from './endToEnd';

class AndesParameterData extends ParameterData {
  constructor(props) {
    super(props)
    if (props.type === 'endToEnd' || props.type === 'endToEnd_history') {
      this.initGetNpiFileFn(getEndToEndChannelResult);
      this.initParseNpiFile(endToEndNpiParse);
    } else if (props.type === 'experiment') {
      this.initGetNpiFileFn(getSweepExtractionResult);
      this.initParseNpiFile(npiFileParseV2);
    } else {
      // channel
      this.initGetNpiFileFn(getChannelExtractionResult);
      this.initParseNpiFile(npiFileParseV2);
    }
    this.initGetCurve(getResultCurveDataPromise);
    this.id = props.id || this.verificationId;
    this.name = this.verificationId;
    this.type = props.type;
  }

  async getCurve({ col, row, value, curveType, signal, pair, couplingType }) {
    const type = value === 'db' ? 'sam' : 's' + value;
    const _couplingType = couplingType ? type === 'sam' ? couplingType : "CPHY_COUPLING_SPH" : null
    if (curveType === 'channel_history') {
      return await getAndesHistoryCurveData({
        historyId: this.verificationId,
        signal,
        pair,
        couplingType: _couplingType,
        resultDataParam: {
          cols: [col], rows: [row],
          type: type
        }
      })
    } else if (curveType === 'endToEnd') {
      return await getEndToEndChannelResultCurve({
        endToEndChannelId: this.verificationId,
        signal,
        pair,
        couplingType: _couplingType,
        resultDataParam: {
          cols: [col], rows: [row],
          type: type
        }
      })
    } else if (curveType === 'endToEnd_history') {
      return await getAndesMultiPCBHistoryCurveData({
        historyId: this.verificationId,
        signal,
        pair,
        couplingType: _couplingType,
        resultDataParam: {
          cols: [col], rows: [row],
          type: type
        }
      })
    } else if (curveType === 'experiment') {
      return await getExperimentResultCurveData({
        experimentId: this.id,
        resultDataParam: {
          cols: [col], rows: [row],
          type: type
        }
      })
    } else {
      return await this.getChannelResultCurve({
        channelId: this.verificationId,
        signal,
        pair,
        couplingType: _couplingType,
        resultDataParam: {
          cols: [col], rows: [row],
          type: type
        }
      });
    }
  }

  // data - is end to end : content, pcb channel: components
  getHistoryNpiFile = ({ index, params, data, isEndToEnd, type }) => {
    return new Promise((resolve, reject) => {
      let fn = isEndToEnd ? getMultiPCBHistoryResults : getHistoryResults;
      fn(params).then(res => {
        const parseData = this.parseNpiFile(res.data, data, type);
        this.ports = parseData.ports;
        this.freq = parseData.freq;
        this.initMatrix(index, type);
        resolve(this);
      }, error => {
        console.error(error);
        resolve(this);
      })
    })
  }
}

export default AndesParameterData;