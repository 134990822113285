import React, { Component } from 'react';
import { connect } from 'react-redux';
import { END_TO_END_CHANNEL } from '../../../constants/treeConstants';
import { getChannelInfo } from '../../../services/Andes_v2/endToEndChannel';
import {
  getAMISignalList,
  getAdsSignalMap,
  getAdsComponentIds
} from '../../../services/Andes_v2/endToEndChannel/AMIModelHelper';
import { SERDES_TYPES } from '../../../services/PCBHelper';
import AMIModelRender from '../AMIModel';
import { updateADSConfig } from '../store/endToEndChannel/action';

class AMIModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controllerInfo: null,
      deviceInfo: null
    }
  }

  componentDidMount = async () => {
    const { adsConfig, pcbConnections = [], connections = [] } = this.props;
    if (!pcbConnections.length || !connections.length || !adsConfig) {
      return;
    }
    let controllerInfo = null, deviceInfo = null;

    const firstPcbConn = pcbConnections[0] || {},
      endPcbConn = pcbConnections.length - 1 > 0 ? pcbConnections[pcbConnections.length - 1] : null;
    const firstConnId = firstPcbConn.nextConnectionId,
      endConnId = endPcbConn ? endPcbConn.prevConnectionId : null;

    const { controllerId, deviceId } = getAdsComponentIds({
      adsConfig,
      firstPcbConn,
      endPcbConn
    });

    controllerInfo = await getChannelInfo(controllerId);
    deviceInfo = await getChannelInfo(deviceId);

    const firstConn = connections.find(item => item.CONNECTION_ID === firstConnId),
      endConn = connections.find(item => item.CONNECTION_ID === endConnId);

    let contrComp = null, deviceComp = null, contrPCBIndex = 0, devicePCBIndex = pcbConnections.length - 1;
    if (firstPcbConn.channelId === controllerInfo.id) {
      contrComp = firstConn.connection.connector1.component;
    }

    if (endPcbConn.channelId === controllerInfo.id) {
      contrComp = endConn.connection.connector2.component;
      contrPCBIndex = pcbConnections.length - 1;
    }

    if (firstPcbConn.channelId === deviceInfo.id) {
      deviceComp = firstConn.connection.connector1.component;
      devicePCBIndex = 0;
    }

    if (endPcbConn.channelId === deviceInfo.id) {
      deviceComp = endConn.connection.connector2.component;
    }
    let contrComps = [], deviceComps = [];

    if (controllerInfo && controllerInfo.content && controllerInfo.content.components) {
      contrComps = controllerInfo.content.components.filter(item => SERDES_TYPES.includes(item.type) && item.name !== contrComp);
    }

    if (deviceInfo && deviceInfo.content && deviceInfo.content.components) {
      deviceComps = deviceInfo.content.components.filter(item => SERDES_TYPES.includes(item.type) && item.name !== deviceComp);
    }

    this.setState({
      controllerInfo,
      deviceInfo,
      contrPCBIndex,
      devicePCBIndex,
      controllerICComps: contrComps.map(item => { return { ...item, pcb: `PCB ${contrPCBIndex + 1}` } }),
      deviceICComps: deviceComps.map(item => { return { ...item, pcb: `PCB ${devicePCBIndex + 1}` } })
    })
  }

  render() {
    const { adsConfig = {}, pcbConnections = [], connections = [], serdesType } = this.props;
    const { controllerInfo, deviceInfo, controllerICComps, deviceICComps, contrPCBIndex, devicePCBIndex } = this.state;
    const signal_map = getAdsSignalMap({ pcbConnections, connections });
    const adsSignals = getAMISignalList({ adsConfig, controllerInfo, deviceInfo, signal_map, pcbConnections, connections, serdesType });

    return <AMIModelRender
      {...this.props}
      adsSignals={adsSignals}
      signal_map={signal_map}
      contrPCBIndex={contrPCBIndex}
      devicePCBIndex={devicePCBIndex}
      controllerInfo={controllerInfo}
      deviceInfo={deviceInfo}
      controllerICComps={controllerICComps}
      deviceICComps={deviceICComps}
      interfaceType={END_TO_END_CHANNEL}
      adsConfig={adsConfig}
      selectedSignals={adsSignals.map(item => item.signal)}
      adsAllSignals={adsSignals}
    />
  }
}

const mapState = (state) => {
  const {
    endToEndChannel: { endToEndChannelInfo: { content = {}, adsConfig, id, type } }
  } = state.AndesV2Reducer;
  const {
    pcbConnections = [],
    connections = []
  } = content;

  return {
    adsConfig,
    pcbConnections,
    connections,
    endToEndId: id,
    serdesType: type
  }
}

const mapDispatch = (dispatch) => ({
  _updateADSConfig(adsConfig, options) {
    dispatch(updateADSConfig(adsConfig, options))
  }
})

export default connect(mapState, mapDispatch)(AMIModel);