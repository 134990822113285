import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import Table from "@/components/EditableTable";
import { updateInterfaces } from '../../store/andes/action';
import { canvasSelect, changeDisplaySelected } from '../../../LayoutExplorer/store/Andes/actionCreators';
import { autoFindPowerGNDNets } from '@/services/Andes';
import '../content.css';

const columns = [{
  title: 'Pwr Nets',
  dataIndex: 'power',
  width: '50%',
}, {
  title: 'Gnd Nets',
  dataIndex: 'ground',
  width: '50%',
}];

class PowerNetsTable extends Component {
  constructor(props) {
    super(props);

    columns[0].render = (data, record) => {
      const { power } = record;
      return power.length > 0 ? <span>{power.join(", ")}</span> : null
    };

    columns[0].onCell = (record) => {
      const nets = this.getPowerNetsOptions();
      return {
        record,
        edit: "select",
        options: nets,
        dataIndex: "power",
        selectMode: 'multiple',
        getPopupContainer: document.getElementById("andes-content-main"),
        handleSave: (record) => this.savePowerNets(record),
        onFocus: () => this.netSelectFocus(record, "power"),
        dropdownMenuClassName: 'andes-select-dropdown-menu'
      }
    };

    columns[1].render = (data, record) => {
      const { ground } = record;
      return ground.length > 0 ? <span>{ground.join(", ")}</span> : null
    };

    columns[1].onCell = (record) => {
      const nets = this.getGNDNetsOptions();
      return {
        record,
        edit: "select",
        options: nets,
        dataIndex: "ground",
        selectMode: 'multiple',
        handleSave: (record) => this.saveGroundNets(record),
        onFocus: () => this.netSelectFocus(record, 'ground'),
        dropdownMenuClassName: 'andes-select-dropdown-menu'
      }
    }
  };

  netSelectFocus = (record, type) => {
    const { pcbId, power, ground } = record;
    const { designID, viewList } = this.props;
    let nets = [];
    if (type === 'power') {
      nets = [...power];
    } else {
      nets = [...ground];
    }
    if (designID === pcbId && viewList && viewList.length > 1) {
      this.props.select({ nets: [...nets] });
      this.props.changeDisplaySelected(true);
    }
  }

  savePowerNets = (record) => {
    const { pcb, pcbId, power } = record;
    const { designID, viewList, Interfaces } = this.props;
    if (!Interfaces || Interfaces.length === 0) {
      message.error('Does not set signal.');
      return;
    }
    let netList = this.getPowerNetsOptions();
    if (designID === pcbId && viewList && viewList.length > 1) {
      this.props.select({ nets: [...power] });
    }
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let refNets = { ...editInterface.refNets };
    let powerNets = [];
    power.forEach(item => {
      if (netList.includes(item)) {
        powerNets.push({
          name: item
        })
      }
    })
    refNets.power = [...powerNets];
    _Interfaces[_index].content.refNets = { ...refNets };
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
  };

  saveGroundNets = (record) => {
    const { pcb, pcbId, ground } = record;
    const { designID, viewList, Interfaces } = this.props;
    if (!Interfaces || Interfaces.length === 0) {
      message.error('Does not set signal.');
      return;
    }
    let netList = this.getGNDNetsOptions();
    if (designID === pcbId && viewList && viewList.length > 1) {
      this.props.select({ nets: [...ground] });
    }
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let refNets = { ...editInterface.refNets };
    let groundNets = [];
    ground.forEach(item => {
      if (netList.includes(item)) {
        groundNets.push({
          name: item
        })
      }
    })
    refNets.ground = [...groundNets];
    _Interfaces[_index].content.refNets = { ...refNets };
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
  };

  getPowerNetsOptions = (record) => {
    const { designId, PCBNets } = this.props;
    let netList = PCBNets[designId] || [];
    let powerGndNets = autoFindPowerGNDNets(netList);
    let powerNets = [];
    powerGndNets.forEach(net => {
      if (!net.match(/gnd/ig)) {
        powerNets.push(net);
      }
    })
    return powerNets || [];
  }

  getGNDNetsOptions = (record) => {
    const { designId, PCBNets } = this.props;
    let netList = PCBNets[designId] || [];
    const autoGND = autoFindPowerGNDNets(netList).filter(item => item.match(/gnd/ig));
    return autoGND || [];
  }

  getRefNets = (refNets) => {
    let power = [], ground = [];
    if (refNets && Object.keys(refNets).length > 0) {
      power = refNets.power.map(item => item.name);
      ground = refNets.ground.map(item => item.name);
    }
    return [{
      power,
      ground,
      pcb: refNets.pcb,
      pcbId: refNets.pcbId
    }]
  }

  render() {
    const { refNets, below } = this.props;
    let data = this.getRefNets(refNets)
    return (
      <Fragment>
        <div className='andes-PG-net-box' style={{ marginLeft: below ? 0 : 20 }}>
          <Table
            columns={columns}
            className="andes-PG-table"
            dataSource={data}
            rowKey={(record, index) => index}
            scroll={
              data && data.length && data.length > 4
                ? { y: 150 }
                : {}
            }
          />
        </div>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { AndesReducer: { andes, project: { currentProjectDesigns, pcbComponentsNets, designID, viewList } } } = state;
  const andesInfo = andes.andesInfo;
  let refNets = { power: [], ground: [] }, currentPCBNames = [], designId = null, Interfaces = [];
  if (andesInfo) {
    refNets = andesInfo.info.refNets;
    currentPCBNames = andesInfo.Interfaces.map(item => item.pcb);
    Interfaces = andesInfo.Interfaces;
  }
  const PCBNames = currentProjectDesigns.map(item => item.name);
  if (currentProjectDesigns && currentProjectDesigns.length > 0) {
    designId = currentProjectDesigns[0].id;
  }
  return {
    refNets,
    PCBNames,
    pcbComponentsNets,
    currentPCBNames,
    designID,
    andesInfo,
    viewList,
    designId,
    Interfaces
  }
};


const mapDispatch = (dispatch) => ({
  updateInterfaces({ Interfaces, pcb, pcbId }) {
    dispatch(updateInterfaces({ Interfaces, pcb, pcbId }));
  },
  select(canvasObj) {
    dispatch(canvasSelect(canvasObj))
  },
  changeDisplaySelected(show) {
    dispatch(changeDisplaySelected(show))
  }
})

export default connect(mapState, mapDispatch)(PowerNetsTable);