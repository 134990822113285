import React, { Component, Fragment } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Checkbox, Select } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import TreeSelect from '../../../components/TreeSelect';

const { Option } = Select
class InterPoserPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      apply: false,
      subcktList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.initData()
  }

  initData = () => {
    const { interposer } = this.props
    const fileName = interposer && interposer.fileName ? interposer.fileName : null
    const libraryId = interposer && interposer.libraryId ? interposer.libraryId : null
    const subckt = interposer && interposer.subckt ? interposer.subckt : null
    const pairs = interposer && interposer.pairs ? interposer.pairs : []
    this.setState({
      fileName,
      libraryId,
      subckt,
      pairs
    }, () => {
      this.getFileAndParse(interposer)
    })
  }

  getFileAndParse = (model) => {
    let nodes = []
    if (model && model.libraryId) {
      this.props.getPkgSpiceModelList(model.libraryId, model.fileName, 'SPICE').then(res => {
        if (res) {
          const subcktList = res && res.models && Array.isArray(res.models) ? [...res.models] : [];
          this.setState({
            subcktList: subcktList
          })
          if (model.subckt) {
            let _subcktList = [...this.state.subcktList];
            this.setState({
              subcktList: _subcktList
            })
            const _subcktIndex = _subcktList.findIndex(sub => sub.name === model.subckt);
            if (_subcktList[_subcktIndex] && _subcktList[_subcktIndex].ports) {
              nodes = _subcktList[_subcktIndex].ports;
            }
            this.firstSetPairsAndNodes(nodes);
          }
        }
      });
    }
  }

  firstSetPairsAndNodes(nodes) {
    const { interposer: model } = this.props;
    const originPairs = model && model.pairs && model.pairs.length > 0 ? model.pairs : this.initPairs();
    let pairs = [];
    if (originPairs.length < 0) {
      pairs = this.initPairs();
    } else {
      pairs = originPairs.map(pair => {
        if (pair.node && nodes.includes(pair.node)) {
          nodes = nodes.filter(node => node !== pair.node);
        }
        return pair;
      })
    }
    this.setState({
      pairs,
      nodes,
      fileName: model.fileName || '',
      libraryId: model.libraryId || '',
      subckt: model.subckt || '',
    })
  }

  closeModal = () => {
    const { apply, fileName, libraryId, subckt, pairs } = this.state
    const _info = { fileName, libraryId, subckt, pairs }
    this.props.closeModal(_info, apply)
  }

  changeApply = () => {
    this.setState({
      apply: !this.state.apply
    })
  }

  applyRender = () => {
    const { apply } = this.state;
    return <div className="imp-current-apply">
      <Checkbox
        checked={apply}
        onChange={this.changeApply}
      />
      <span>
        Apply interposer model to all Ports
      </span>
    </div>
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  initPairs = (nodes = []) => {
    return ['diePort', 'pkgPort'].map((item, index) => {
      return { pin: item, node: nodes.length > index ? nodes[index] : '' }
    })
  }

  selectSpiceFile = (key) => {
    const { onDieSpiceList } = this.props;
    let spice = [];
    const name = key.id ? key.name : key;
    if (key.id) {
      spice = { ...key }
    } else {
      if (Array.isArray(onDieSpiceList)) {
        spice = onDieSpiceList.find(item => item.name === name);
      }
    }
    if (spice) {
      this.setState({
        fileName: name,
        subckt: '',
        libraryId: spice.id,
      })
      this.props.getPkgSpiceModelList(spice.id, spice.name, 'SPICE').then(res => {
        let subcktList = [], defaultSubckt = {};
        subcktList = res && res.models && Array.isArray(res.models) ? [...res.models] : [];
        if (subcktList && subcktList.length === 1) {
          defaultSubckt = subcktList[0]
        }
        if (res) {
          this.setState({
            subcktList: subcktList
          })
        }
        if (defaultSubckt && defaultSubckt.name) {
          this.setState({
            subckt: defaultSubckt.name,
            nodes: defaultSubckt.ports,
            pairs: this.initPairs(defaultSubckt.ports)
          })
        }
      });
    }
  }

  selectSpiceSubckt = (key) => {
    const { subcktList } = this.state;
    const subckt = subcktList.find(item => item.name === key);
    this.setState({
      subckt: key,
      nodes: subckt ? subckt.ports : [],
      pairs: this.initPairs(subckt ? subckt.ports : [])
    })
  }

  deleteFileSelect = (target) => {
    if (target === 'spice') {
      this.setState({
        nodes: [],
        fileName: '',
        libraryId: '',
        subckt: '',
        subcktList: [],
        pairs: this.initPairs()
      })
    } else if (target === 'subckt') {
      this.setState({
        nodes: [],
        subckt: '',
        pairs: this.initPairs()
      })
    }
  }

  renderDialog = () => {
    const { onDieSpiceList } = this.props
    const _spiceList = onDieSpiceList.map(item => {
      if (item.format && item.format === 'Folder') {
        item.children = item.children.map(item => {
          return { ...item, type: 'file' }
        })
        return { ...item, type: 'folder' }
      } else {
        return { ...item, type: 'file' }
      }
    })
    const { fileName, subckt, subcktList, libraryId } = this.state
    const content = (
      <Panel
        className='interposer-panel'
        id='interposer-panel'
        title={'Interposer Model'}
        onCancel={this.closeModal}
        zIndex={2000}
        width={600}
        minWidth={300}
        minHeight={200}
        position='panel-center-left'
        draggable
        overflow={"auto"}
        footer={this.applyRender()}
      >
        <div className='interposer-content'>
          <div className='netlist-model-simall-row'>
            <div className='netlist-model-row'>
              <span>File</span>
              <TreeSelect
                showSearch
                value={fileName}
                onSelectItem={this.selectSpiceFile}
                className='netlist-model-selection'
                popupClassName='netlist-model-dropdown'
                popupMatchSelectWidth={false}
                allowClear={true}
                clearIcon={<CloseCircleFilled onClick={(e) => { this.deleteFileSelect('spice') }} />}
                getPopupContainer={() => document.getElementById('root')}
                fileList={_spiceList}
                selected={`${libraryId}-${fileName}`}
                selectedkey={"key"}
              />
            </div>
          </div>
          <div className='netlist-model-simall-row'>
            <div className='netlist-model-row'>
              <span>Subckt</span>
              <Select
                value={subckt}
                onSelect={this.selectSpiceSubckt}
                className='netlist-model-selection'
                popupClassName='netlist-model-dropdown'
                dropdownMatchSelectWidth={false}
                allowClear={true}
                clearIcon={<CloseCircleFilled onClick={(e) => { this.deleteFileSelect('subckt') }} />}
                getPopupContainer={() => document.getElementById('root')}
              >
                {subcktList.map(item => <Option
                  key={item.name}
                  value={item.name}
                  title={item.disabled ? 'The number of ports should be two' : item.name}
                  disabled={item.disabled}
                >{item.name}</Option>)}
              </Select>
            </div>
          </div>
        </div>
      </Panel>
    );
    return createPortal(content, this.dialogRoot);
  }

  render() {
    return <Fragment>
      {this.renderDialog()}
    </Fragment>
  }
}

export default InterPoserPanel;
