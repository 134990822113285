import {
  CHANGE_CCC_MENU,
  UPDATE_CCC_QUEUE_MSG,
  UPDATE_CCC_RUNNING_QUEUE,
  GET_CCC_MSG,
  UPDATE_BACKEND_LOG,
  UPDATE_DAEMON_LIST,
  UPDATE_DAEMON_DATA,
  CCC_EXPAND_MENU,
  CLEAR_CCC_STATUS,
  STOP_POLL_DATA,
  START_POLL_DATA,
  BROKER_LOG_CHANGE,
  GET_SERVER_LIST,
  UPDATE_SERVER_LIST,
  STOP_SERVER_LIST,
  UPDATE_TASK_TABLE_LIST,
  SYSTEM_LOG_DOWNLOAD_UPDATE,
  SELECT_TASK,
  UPDATE_SELECT_TASK_MESSAGE
} from './actionTypes';


export const changeCCCMenu = (cccMenu, status, isCCCPage) => ({
  type: CHANGE_CCC_MENU,
  cccMenu,
  status,
  isCCCPage
});

export const updateQueueMsg = (log) => ({
  type: UPDATE_CCC_QUEUE_MSG,
  log
});

export const updateRunningQueue = ({ serverList, taskList }) => ({
  type: UPDATE_CCC_RUNNING_QUEUE,
  serverList,
  taskList
});

export const getCCCMsg = (isCCCPage) => ({
  type: GET_CCC_MSG,
  isCCCPage
});

export const updateBackendLog = (log, status) => ({
  type: UPDATE_BACKEND_LOG,
  log,
  status
});

export const updateDaemonList = ({ list, taskServiceData, taskServiceDataTime, status }) => ({
  type: UPDATE_DAEMON_LIST,
  list,
  taskServiceData,
  taskServiceDataTime,
  status
});

export const updateDaemonData = ({ daemonServer, worker, level }) => ({
  type: UPDATE_DAEMON_DATA,
  daemonServer,
  worker,
  level: level
});

export const CCCExpandMenu = (expandedKeys) => ({
  type: CCC_EXPAND_MENU,
  expandedKeys
});

export const clearCCCStatus = () => ({
  type: CLEAR_CCC_STATUS
});

export const stopPollData = () => ({
  type: STOP_POLL_DATA
});

export const startPollData = () => ({
  type: START_POLL_DATA
});

export const brokerLogTypeChange = (type) => ({
  type: BROKER_LOG_CHANGE,
  logType: type
});

export const getServerList = () => ({
  type: GET_SERVER_LIST,
});

export const updateServerList = (list) => ({
  type: UPDATE_SERVER_LIST,
  list
});

export const stopServerList = () => ({
  type: STOP_SERVER_LIST
});

export const updateTaskTableList = ({ list, taskServiceData, taskServiceDataTime }) => ({
  type: UPDATE_TASK_TABLE_LIST,
  list,
  taskServiceData,
  taskServiceDataTime,
});

export const systemLogDownloadPercent = ({ msg, percent }) => ({
  type: SYSTEM_LOG_DOWNLOAD_UPDATE,
  msg,
  percent
});

export const selectTask = (verification, serviceSelectListMap, setupType) => ({
  type: SELECT_TASK,
  verification,
  serviceSelectListMap,
  setupType
});

export const updateSelectTaskMessage = (message) => ({
  type: UPDATE_SELECT_TASK_MESSAGE,
  message
})