import canvas from '@/services/LayoutCanvas';

function addHybridRegionsBox(designID) {
  let times = 0;
  const timeout = setInterval(() => {
    times++;
    const layout = canvas.getLayout(designID);
    if (layout) {
      layout.addHybridRegionBox();
      clearInterval(timeout)
    }
    if (times === 120) {
      clearInterval(timeout)
    }
  }, 800)
}

export {
  addHybridRegionsBox
}