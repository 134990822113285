import http from "../http";

/**
 * 2022/09/15
 * Upload xlsx , parse and create sign-off templateId
 *  */
export function parseCreateSignOffTemplate({ formData }) {
  return http.post(`/Cascade/upload/template`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * 2023/08/14
 * get sign-off template upload log
 *  */

export function getSignOffTemplateUploadLog({ projectId, verificationId }) {
  return http.get(`/Cascade/template/log?projectId=${projectId}&verificationId=${verificationId}`);
}

/**
 * 2022/09/15
 * get sign-off template content
 *  */
export function getSignOffTemplateContent({ verificationId }) {
  return http.get(`/Cascade/verification/${verificationId}/template`);
}

/**
 * 2022/09/15
 * update sign-off template content
 *  */
export function updateSignOffTemplateContent({ data }) {
  return http.post(`/Cascade/update/template`, data);
}

/**
 * 2022/09/19
 * update sign-off template status
 *  */
export function updateSignOffTemplateStatus({ verificationId }) {
  return http.post(`/Cascade/update/template/finish`, { id: verificationId });
}

/**
 * 2022/12/01
 * download template result s1p file by power net
 *  */
export function downloadSignOffTemplateS1P(downloadInfo) {
  return http.post(`Cascade/download/s1p`, {
    ...downloadInfo
  });
}

/**
 * 2023/05/16
 * select template design
 *  */
export function selectTemplatePCB({ verificationId, designId }) {
  return http.post(`/Cascade/template/pcb/select`, {
    verificationId,
    designId
  })
}

/**
 * Select specify service for template
 * 2023.05.15
 * @export
 * @param { Object } serviceObj - { verificationId, path_r: serverMap:{ taskName: [server1, server2] }, impedance: serverMap }
 * @returns
 */
export function selectTemplateServices({ verificationId, pathR, impedance }) {
  return http.post(`/Cascade/template/select/service`, {
    verificationId,
    pathR,
    impedance
  })
}

/**
 * 2024/09/02
 * upload model excel
 *  */
export function uploadDecapModel({ file, type }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return http.post(`/Cascade/impedance/parse/model/excel`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * 2025/02/10
 * pdf table choose
 */
export function getTemplatePdfTable(verificationId) {
  return http.get(`Cascade/template/table/info?verificationId=${verificationId}`)
}

/**
 * 2025/02/10
 * pdf table choose finish
 */
export function selectTemplatePdfTable({ verificationId, sheetNumber }) {
  return http.post(`Cascade/template/update?verificationId=${verificationId}&sheetNumber=${sheetNumber}`)
}