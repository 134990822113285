import { getQueueLog, getRunningQueueInfo, cancelServerTask } from '../api/v2/workflowCtrl';
import {
  getBackendLog, getDaemonList, updateCCCServerStatus, setBackendServerDebug,
  getServerList, selectServer, selectSpecifyServiceForVerification
} from '../api/backend';
import checkError from '../api/checkError';
import apiProcess from '../api/utility';
import { message } from 'antd';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { urlToPromise } from '../helper/unzip';

/**
 * Get workflow log in queue 
 *
 * @export
 * @returns Promise
 */

export function getCCCLog() {
  return new Promise((resolve, reject) => {
    getQueueLog().then(res => {
      if (res.data && res.data.code === 0) {
        resolve(res.data.data)
      } else {
        resolve('')
      }
    }, error => {
      checkError(error, false);
      resolve('');
    })
  })
};

/**
 * Get running queue information
 * 
 * {
 *    "server": "pc",
 *    "taskList" : [{
 *       "name": "TRANS_LAY",
 *       "id": "11"
 *    }]
 * }
 *
 * @export
 * @returns Promise
 */
export function getRunningQueue() {
  return new Promise((resolve, reject) => {
    getRunningQueueInfo().then(res => {
      if (res.data && res.data.code === 0) {
        resolve(res.data.data);
      } else {
        resolve(null)
      }
    }, error => {
      checkError(error, false);
      resolve(null)
    })
  })
}

/**
 * kill running queue 
 * 
 * @param id taskId
 *
 * @export
 * @returns Promise
 */
export function cancelTask(id) {
  return new Promise((resolve, reject) => {
    cancelServerTask(id).then(res => {
      if (res.data && res.data.code === 0) {
        resolve(res.data.data);
      } else {
        resolve(null)
      }
    }, error => {
      checkError(error, false);
      resolve(null)
    })
  })
}

/**
 * Get backend log 
 * 2019.12.10
 *  
 * {
 *  "logType": "ccc",
 *  "logs": [{
 *    "time": "UTC",
 *    "log": "log content"
 *  }]
 *}
 * 
 * @export
 * @param {'broker'|'ccc'|'machine name'} type
 * @param subType:{ccc:ccc,broker:broker,server:task}
 * 
 * @returns XMLHttpRequest
 */
export function getBackendServerLog(type, subType, level) {
  return new Promise((resolve) => {
    apiProcess(getBackendLog, { type, subType, level }).then(res => {
      resolve(res);
    }, error => {
      resolve(null)
    })
  })
}

/**
 * Get daemon list 
 * 2019.12.11
 * 
 * @export
 * @returns XMLHttpRequest
 */
export function getServerDaemonList(type) {
  return new Promise((resolve, reject) => {
    apiProcess(getDaemonList, type).then(res => {
      resolve(res)
    }, error => {
      resolve(null)
    })
  })
}

/**
 * Switch backend server
 * 2019.12.27
 * 
 * @export
 * @returns XMLHttpRequest
 */
export function updateServerStatus(workerServers) {
  return new Promise((resolve, reject) => {
    apiProcess(updateCCCServerStatus, workerServers, false, true).then(res => {
      resolve(res)
    }, errorMsg => {
      reject(errorMsg);
    })
  })
}

/**
 * 2020.04.24 Set Backend Debug
 * 
 * @param {Object} server { serverName: "ip_mac_uuid", "debug": false / true }
 * @export
 * @returns XMLHttpRequest
 */
export function setServerDebug(worker) {
  return new Promise((resolve, reject) => {
    apiProcess(setBackendServerDebug, worker).then(res => {
      resolve(res)
    }, error => {
      reject(error)
    })
  })
}

/**
 * 2020.04.29 Get backend server_list simple
 * 
 * [{ 'taskType': 'Ansys|Simulation|Translation', 'servers': [{ "name": "machineIP_macAddress_uuid", "status": "free" }] }]
 * 
 * @export
 * @returns XMLHttpRequest
 */
export function getBackendServerListSimple() {
  return new Promise((resolve, reject) => {
    apiProcess(getServerList, null).then(res => {
      resolve(res)
    }, error => {
      resolve([]);
    })
  })
};

/**
 * 2020.04.29 Select specific server for verification
 *
 * @export
 * @param {String} verificationId - verification id
 * @param {String} serverName - "machineIP_macAddress_uuid"
 * @returns
 */
export function selectServerForVerification(verificationId, serverName) {
  return new Promise((resolve, reject) => {
    apiProcess(selectServer, { verificationId, serverName }).then(res => {
      resolve(true);
      message.success('Set task service successfully!');
    }, error => {
      reject(false);
      message.error('Set task service failed!');
    })
  })
}

export function downloadCCC(systemLogDownloadPercent) {
  getCCCLog().then(log => {
    // CCC log
    const blob = new Blob([log], {
      type: 'plain/text;charset=utf-8;'
    });
    const zip = new JSZip();
    zip.file('CCC.log', blob)

    // Backend service log
    const token = localStorage.getItem('token');
    const url = `/api/v3/backend/log/all?&access_token=${token}`;
    zip.file('AuroraService.zip', urlToPromise(url), { binary: true });

    // when everything has been downloaded, we can trigger the dl
    // generateAsync(options[, onUpdate]) onUpdate -> updateCallback
    zip.generateAsync({ type: "blob" }, (metadata) => {
      var msg = "Progress " + metadata.percent.toFixed(2) + " %";
      if (metadata.currentFile) {
        msg += ", current file " + metadata.currentFile;
      }
      systemLogDownloadPercent({ msg: msg, percent: metadata.percent })
    }).then((blob) => {

      const downloadName = "AuroraSysLog.zip";
      FileSaver.saveAs(blob, downloadName);
      systemLogDownloadPercent({ msg: '', percent: -1 });
      message.success(`${downloadName} download completed.`)
    }, error => {
      systemLogDownloadPercent({ msg: '', percent: -1 });
      message.error('System log download failed!');
      console.error('[Error] System log download failed!', error);
    });
  })
}

export const CCCSiderItems = [{
  name: 'CCC',
  key: 'CCC_log',
  className: 'admin-tree-main-item',
  children: [{
    name: 'Task Dispatcher',
    key: 'ccc',
    className: 'admin-tree-child-item',
    children: []
  },
  {
    name: 'Message Agent',
    key: 'broker',
    className: 'admin-tree-child-item',
    children: []
  }, {
    name: 'Task Service',
    key: 'daemon',
    className: 'admin-tree-child-item',
    children: []
  }]
}];

export const CCCKeys = ['CCC_log', 'ccc', 'broker', 'daemon'];

/**
 * Select specify service for verification
 * 2021.06.11
 * @export
 * @param {Object} serviceObj - { verificationId, serverMap:{ taskName:[ server1, server2 ] } }
 * @returns
 */
export function selectSpecifyService(serviceObj) {
  return new Promise((resolve, reject) => {
    return apiProcess(selectSpecifyServiceForVerification, serviceObj).then(res => {
      resolve('Set task service successfully!');
    }, error => {
      reject("Set task service failed!");
    });
  })
}
