import React, { Fragment } from 'react';
import { CloseOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Divider } from 'antd';

const CheckboxGroup = Checkbox.Group;

function resultList(i, info, signal, colorChange) {
  return (
    <Fragment key={i}>
      <label className='font-bold' style={{ display: 'block' }}>{signal}</label>
      {info.compInfo && info.compInfo.filter(item => item.signal === signal).map((item) => {
        const { color, component, curveIndex, curveId, usage, time, current } = item;
        return (
          <Checkbox value={curveId} key={curveId} className='andes-waveform-checkbox waveform-clear'>
            <span className='andes-waveform-checkbox-lable'>
              <input type='color' className="color-select" value={color}
                onChange={(e) => colorChange(e, curveIndex, time)} />
              <span className='font-bold andes-waveform-check-lable'>{usage}:</span>
              <Tooltip title={`${signal}::${component}`} placement="top" className='andes-waveform-tooltip'>
                <span className='andes-waveform-component-name'>{signal}</span>
                <span className='andes-waveform-comp-name'>::{component}</span>
                <span className='andes-waveform-current' style={{ color }}>{current}</span>
              </Tooltip>
            </span>
          </Checkbox>
        )
      })}
    </Fragment>
  )
}

function WaveformSelection(props) {
  const { selectedComponentKeys, compInfo, onComponentChange, colorChange, resultShow,
    historyShow, historyTitleClick, resultTitleClick, historyItemTitleClick, deleteHistory } = props;
  let historyInfo = compInfo.filter(item => item.time !== 'current');
  let currentInfo = compInfo.find(item => item.time === 'current');
  return (
    <Fragment>
      <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }} className='andes-result-driver'>
        <div className='andes-result-driver-title' onClick={resultTitleClick}>
          {resultShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
          <span className='andes-result-menu-title'>Result</span>
        </div>
      </Divider>
      {resultShow && <CheckboxGroup value={selectedComponentKeys} onChange={(values) => onComponentChange(values, 'current')} className='andes-waveform-checkboxgroup'>
        {currentInfo && currentInfo.signals && currentInfo.signals.map((signal, i) => (
          resultList(i, currentInfo, signal, colorChange)
        ))}
      </CheckboxGroup>}
      <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }} className='andes-result-driver'>
        <div className='andes-result-driver-title' onClick={historyTitleClick}>
          {historyShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
          <span className='andes-result-menu-title'>History</span>
        </div>
      </Divider>
      {historyShow && historyInfo && historyInfo.length > 0 && historyInfo.map(history =>
        <div key={history.time}>
          <div className='andes-result-history-title' onClick={(e) => historyItemTitleClick(e, history.name, history.time)}>
            {history.show ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
            <span title={history.name}>{history.name}</span>
            <CloseOutlined
              className="andes-file-delete-icon"
              title='Delete History'
              onClick={(e) => deleteHistory(e, history.time)} />
          </div>
          {history.show && <CheckboxGroup value={selectedComponentKeys} onChange={(values) => onComponentChange(values, history.time)} className='andes-waveform-checkboxgroup'>
            {
              history && history.signals && history.signals.map((signal, i) => (
                resultList(i, history, signal, colorChange)
              ))
            }
          </CheckboxGroup>}
        </div>)}
    </Fragment>
  );
}

export default WaveformSelection;