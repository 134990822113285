import http from '../http';

/**
 * download Cascade simulation log, response: file
 * 2021.12.02
 *
 * @export
 * @param {string} verificationId verificationId 
 * @returns XMLHttpRequest
 */
export function getSimulationLog(verificationId) {
  return http.get(`/Cascade/verification/${verificationId}/log`)
}