import { numberCheck } from "../../helper/dataProcess";

const beNumber = 'should be a number.';
const beInteger = 'should to be an integer.'

export function checkSimulationOptions(type, value, optionTitle, options = {}) {
  const { isNumber = true, isInteger = false } = options;
  if (isNumber && numberCheck(value)) {
    return `${optionTitle} ${beNumber}`;
  }

  if (isInteger && !Number.isInteger(Number(value))) {
    return `${optionTitle} ${beInteger}`
  }
  switch (type) {
    case 'hspiceLicenses':
      return value < 1 ? `${optionTitle} should be greater than 1.` : ''
    default:
      return ''
  }
}