import React, { Component, Fragment, createRef } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { setInitialState } from '@/services/helper/virtualListHelper';
import './index.css';

const DefaultSettings = {
  itemHeight: 28,  //The length of a single row in the table
  amount: 10,      //The number of data displayed in the table
  tolerance: 20,   //The number of data on both sides of the data displayed
  minIndex: 0,
  maxIndex: 0,
  startIndex: 0   //The starting position of the data displayed
}

/* 
*    list :         -------------------------------------------------------|
*                    |           |top padding        |       |             |
*             topPaddingHeight   |-------------------|   itemAbove         |
*                    |           |                   |       |             |
*                    ------------|-------------------|--------             |
*                    |           |outlet             |       |             |
*             toleranceHeight    |-------------------|       |             |
*                    |           |                   |       |             |
*                    ------------|-------------------|       |             |
*                    |           |visible viewport   |  bufferedHeight     |
*                    |           |-------------------|       |             |--totalHeight
*             viewportHeight     |                   |  bufferItems=3+2*2  |
*                    |           |-------------------|       |             |
*                    |           |                   |       |             |
*                    ------------|-------------------|       |             |
*                    |           |outlet             |       |             |
*             toleranceHeight    |-------------------|       |             |
*                    |           |                   |       |             |
*                    ------------|-------------------|--------             |
*                    |           |bottom padding     |                     |
*            bottomPaddingHeight |-------------------|                     |
*                    |           |                   |                     |
*                    ------------------------------------------------------|
*/
class VirtualList extends Component {

  constructor(props) {
    super(props);
    const { options, selectMode } = props;
    const setting = { ...DefaultSettings, maxIndex: options ? options.length : 0 };
    this.state = {
      options: options,
      keyCode: null,
      nets: [],
      start: '',
      value: '',
      setting,
      initialState: setInitialState(setting),
      data: []
    }
    this.shiftKey = 16;
    this.enterKey = 13;
    this.commaKey = 188;
    this.singleSelect = selectMode === 'multiple' || selectMode === 'tags' ? false : true;
    this.scrollRef = createRef();
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.KeyDown, true);
    document.addEventListener('keyup', this.KeyUp, true);
    this.getNewScrollData()
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.KeyDown, true);
    document.removeEventListener('keyup', this.KeyUp, true);
  }

  componentDidUpdate = (prevProps) => {
    const { options, value, getPointNewOptions, record, labels, allowStick, optGroup } = this.props;
    const { setting } = this.state;
    let stick = "";
    if (options && prevProps.options.length !== options.length) {
      const newSetting = { ...setting, maxIndex: options ? options.length : 0 };
      this.setState({
        options,
        setting: newSetting,
        initialState: setInitialState(newSetting)
      }, () => this.getNewScrollData())
    }

    if (prevProps.value !== value) {
      let newOptions = []
      if (getPointNewOptions) {
        newOptions = getPointNewOptions(value, options, record)
      } else {
        if (labels && value) {
          let filterLabels = labels.filter(l => l.label.toLowerCase().indexOf(value.toLowerCase()) >= 0).map(l => l.key);
          newOptions = options.filter(option => {
            const newOption = optGroup && optGroup.length ? option.value : option;
            return filterLabels.includes(newOption)
          });
        } else {
          newOptions = value ? options.filter(option => {
            const newOption = optGroup && optGroup.length ? option.value : option;
            return newOption && newOption.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }) : options;
        }
      }
      if (allowStick) {
        let exist = options.find(option => {
          const newOption = optGroup && optGroup.length ? option.value : option;
          return newOption.toLowerCase() === value
        });
        exist = exist && optGroup && optGroup.length ? exist.value : exist;
        if (value.match(/,+/g) || exist) {
          stick = "Press Enter to select the entered pins";
        }
      }
      const newSetting = { ...setting, maxIndex: newOptions ? newOptions.length : 0, startIndex: 0 };
      this.setState({
        options: newOptions,
        start: '',
        nets: [],
        value,
        setting: newSetting,
        initialState: setInitialState(newSetting),
        stick
      }, () => this.getNewScrollData())
    }

    // delete seleted options, update data
    if (this.props.errorCheck && this.props.errorCheck() && prevProps.record.pin && record.pin && record.pin.length < prevProps.record.pin.length) {
      this.getNewScrollData()
    }
  }

  getNewScrollData = () => {
    const { initialState, setting } = this.state;
    const scrollTop = this.scrollRef ? this.scrollRef.scrollTop : 0;
    const index = setting.minIndex + Math.floor((scrollTop - initialState.toleranceHeight) / setting.itemHeight);
    const data = this.getData(index, initialState.bufferedItems);
    const topPaddingHeight = Math.max((index - setting.minIndex) * setting.itemHeight, 0);
    const bottomPaddingHeight = Math.max(
      initialState.totalHeight - topPaddingHeight - data.length * setting.itemHeight,
      0
    ) - 50;
    const PaddingHeight = Object.assign({}, initialState, {
      topPaddingHeight,
      bottomPaddingHeight
    })
    this.setState({
      data: data,
      initialState: PaddingHeight
    });
  }

  getData(offset, limit) {
    const data = [];
    const { setting, options, start } = this.state;
    const { record, errorCheck, optGroup } = this.props;
    const _start = Math.max(setting.minIndex, offset);
    const end = Math.min(offset + limit - 1, setting.maxIndex);
    if (_start <= end) {
      for (let i = _start; i < end; i++) {
        let error = false;
        if (optGroup && optGroup.length) {
          if (errorCheck) {
            error = errorCheck(options[i].value, record, start)
          }
          data.push({ optiondata: options[i].value, optGroup: options[i].type, error })
        } else {
          if (errorCheck) {
            error = errorCheck(options[i], record, start)
          }
          data.push({ optiondata: options[i], error })
        }
      }
    }
    return data;
  }

  onScrollEvent = (e) => {
    e.stopPropagation();
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }
    this.scrollTimer = setTimeout(() => { this.getNewScrollData() }, 1);
  }

  KeyDown = (e) => {
    this.setState({
      keyCode: e.keyCode
    })
  }


  KeyUp = (e) => {
    const keyCode = e.keyCode;
    const { record, setFieldsValue, enterSelect, allowStick, options, value, stickMatch, optGroup } = this.props;
    const { nets, data } = this.state;
    this.setState({
      keyCode: null
    })

    if ([this.enterKey, this.commaKey].includes(keyCode) && allowStick && value) {
      const valueList = value.replace(/ /g, '').split(',');
      let _nets = [];
      valueList.forEach(item => {
        let exist = stickMatch ? stickMatch(item, options) : options.find(option => {
          const newOption = optGroup && optGroup.length ? option.value : option;
          return newOption.toLowerCase() === item.toLowerCase()
        });
        if (exist) {
          _nets.push(optGroup && optGroup.length ? exist.value : exist)
        }
      })
      this.props.onChange(_nets, record, setFieldsValue);
      this.props.auroraTableSearch('');
      this.props.clearInputValue();
    } else if (keyCode === this.enterKey && !this.singleSelect) {
      let _nets = [...nets]
      if (enterSelect && !data[0].error) {
        // Press Enter to select when multiple selections
        _nets.push(data[0].optiondata)
      }
      this.props.onChange(_nets, record, setFieldsValue);
      this.props.save();
    } else if (keyCode === this.enterKey && enterSelect) {
      // Press Enter to select when single selection
      if (data[0] && !data[0].error) {
        this.props.onChange(data[0].optiondata, record, setFieldsValue);
        this.props.save();
      }
    }
  }

  selectList = (list, name) => {
    return list.includes(name) ? list.filter(item => item !== name) : [...list, name]
  }


  shiftSelect = (name) => {
    const { nets, options, start } = this.state;
    const { optGroup } = this.props;
    const include = nets.includes(start);
    if (!start || !include) {
      this.setState({
        start: name,
        nets: [name],
      })
    } else if (start === name) {
      this.setState({
        start: '',
        nets: [],
      })
    } else {
      let newOptions = optGroup && optGroup.length ? options.map(item => item.value) : options;
      const indexStart = newOptions.indexOf(start), indexEnd = newOptions.indexOf(name);
      const newNets = indexStart > indexEnd ? newOptions.slice(indexEnd, indexStart + 1) : newOptions.slice(indexStart, indexEnd + 1);
      this.setState({
        nets: newNets
      })
    }
  }


  selectValue = (e, option) => {
    const { optiondata, error } = option
    if (error) {
      return
    }
    const { record, setFieldsValue, closeList, errorCheck } = this.props;
    const { keyCode } = this.state;
    if (this.singleSelect) {
      this.props.onChange(optiondata);
    } else {
      if (keyCode && keyCode === this.shiftKey) {
        this.shiftSelect(optiondata);
      } else {
        this.props.clearInputValue();
        this.props.onChange([optiondata], record, setFieldsValue);
        if (closeList) {
          this.props.save();
        }
        if (errorCheck) {
          this.setState({
            nets: [],
            start: ''
          }, () => this.getNewScrollData())
        } else {
          this.setState({
            nets: [],
            start: ''
          })
        }
      }
    }
  }

  showSelectedNets = (e) => {
    e.stopPropagation();
    const { record, setFieldsValue } = this.props;
    const { nets } = this.state;
    this.props.onChange(nets, record, setFieldsValue);
    this.props.clearInputValue();
    this.setState({
      nets: [],
      start: ''
    });
    this.props.save();
  }

  getOptionClassName = (option) => {
    const { selected } = this.props;
    const { optiondata, error } = option;
    const { nets } = this.state;
    if (this.singleSelect) {
      return selected === optiondata ? "menu-selected-item" : ""
    } else {
      let ErrorclassName = "";
      if (error) {
        ErrorclassName = "menu-selecting-item-error"
      }
      if (nets.includes(optiondata)) {
        return selected.includes(optiondata) ? `menu-selected-item menu-selecting-item ${ErrorclassName}` : `menu-selecting-item ${ErrorclassName}`
      } else {
        return selected.includes(optiondata) ? `menu-selected-item ${ErrorclassName}` : `${ErrorclassName}`
      }
    }
  }

  render() {
    const { options, nets, data, initialState, stick } = this.state;
    const { id, selectButtonTitle, labels, optGroup } = this.props;
    const _selectButtonTitle = selectButtonTitle || "Select nets";
    const optionNum = options ? (optGroup && optGroup.length ? options.length + optGroup.length : options.length) : 0;
    return !options || options.length === 0 ? <div id={id} className="menu-no-result virtual-list-menu">{stick || "No Result"}</div> :
      <Fragment>
        <div
          id={id}
          className='virtual-list-menu'
          style={optionNum < 6 ? { height: optionNum * 32, minHeight: 36 } : {}}
          onMouseDown={(e) => {
            e.preventDefault();
            return false;
          }}
          ref={r => this.scrollRef = r}
          onScrollCapture={(e) => this.onScrollEvent(e)}
        >
          <ul className='menu-list'>
            <div style={{ height: initialState.topPaddingHeight }} />
            {optGroup && optGroup.length ? <Fragment> {optGroup.map(key => {
              const dataOptions = data.filter(option => option.optGroup === key)
              return (
                <div className='menu-list-group' key={key}>
                  {dataOptions && dataOptions.length ? <div className='vlrtual-select-dropdown-menu-item-group-title'>{key}</div> : null}
                  {dataOptions.map(option => {
                    const label = labels ? labels.find(l => l.key === option.optiondata) : null;
                    return option ? <li
                      key={option.optiondata}
                      className={this.getOptionClassName(option)}
                      onMouseDown={(e) => this.selectValue(e, option)}
                      style={this.singleSelect ? { paddingLeft: 8 } : {}}
                      title={option.optiondata}
                    >
                      {!this.singleSelect && <CheckOutlined className="menu-item-check" />}
                      {label ? label.label : option.optiondata}
                    </li> : null;
                  })}
                </div>
              );
            })}</Fragment> :
              <Fragment> {data.map(option => {
                const label = labels ? labels.find(l => l.key === option.optiondata) : null;
                return option ? <li
                  key={option.optiondata}
                  className={this.getOptionClassName(option)}
                  onMouseDown={(e) => this.selectValue(e, option)}
                  style={this.singleSelect ? { paddingLeft: 8 } : {}}
                  title={option.optiondata}
                >
                  {!this.singleSelect && <CheckOutlined className="menu-item-check" />}
                  {label ? label.label : option.optiondata}
                </li> : null;
              })}</Fragment>
            }
            <div style={{ height: initialState.bottomPaddingHeight < 0 ? 0 : initialState.bottomPaddingHeight }} />
          </ul>
        </div>
        {nets.length > 0 && <div
          className="virtual-list-select-show"
          onMouseDown={(e) => this.showSelectedNets(e)}
          style={optionNum < 6 ? { top: optionNum * 32 + 1, } : { top: 201 }}
        >
          {_selectButtonTitle}
        </div>}
      </Fragment>;
  }
}

export default VirtualList;