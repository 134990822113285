import {
  ADS_RX,
  ADS_TX,
  AMIDisplaySignals,
  libraryCheck,
  getCompPinBySignal,
  getCPHYCompPinBySignal
} from "../../AMIModelHelper";
import { GENERIC_TOUCHSTONE, IBIS, IBIS_AMI, SPICE } from "../../../../constants/libraryConstants";
import libraryConstructor from "../../library/libraryConstructor";
import { CPHY } from "../../../PCBHelper/constants";

function getAMISignalList({ adsConfig, signals, components, selectedSignals, serdesType }) {
  if (!adsConfig.signals) {
    return [];
  }

  if (serdesType === CPHY) {
    return getCPHYSignalList({ adsConfig, signals, components, selectedSignals });
  }

  let amiSignals = [], amiAllSignals = [];

  const controllerName = adsConfig.controller,
    deviceName = adsConfig.device;

  const controllerComp = components.find(item => item.name === controllerName) || {};
  const deviceComp = components.find(item => item.name === deviceName) || {};

  let modelKey = 'Ami', libraryType = IBIS_AMI;
  if (adsConfig.signals[0] && adsConfig.signals[0].IbisHasAMI === "no") {
    modelKey = "";
    libraryType = IBIS;
  }

  const libraryList = libraryConstructor.getLibraryValues(libraryType) || [];
  const SPICElibraryList = libraryConstructor.getLibraryValues(SPICE) || [];
  const genericTouchstoneList = libraryConstructor.getLibraryValues(GENERIC_TOUCHSTONE) || [];

  for (let signal of adsConfig.signals) {

    if (!controllerName && !deviceName) {
      const AMISignalInfo = new AMIDisplaySignals({
        signal: signal.signalName,
        IbisHasAMI: signal.IbisHasAMI,
        txModel: signal[`tx${modelKey}Model`],
        rxModel: signal[`rx${modelKey}Model`],
        rcModel: signal.rcModel,
        txCircuitModel: signal.txCircuitModel,
        rxCircuitModel: signal.rxCircuitModel,
        aggressors: signal.aggressors
      })
      if (selectedSignals.includes(signal.signalName)) {
        amiSignals.push(AMISignalInfo);
      }
      amiAllSignals.push(AMISignalInfo)
      continue;
    }
    //find component pins by signal
    const findSignal = signals.find(it => it.name === signal.signalName);
    //controller
    const { positive, negative } = getCompPinBySignal({
      signal: findSignal,
      pins: controllerComp.pins
    });
    //device
    const { positive: devicePositive, negative: deviceNegative } = getCompPinBySignal({
      signal: findSignal,
      pins: deviceComp.pins
    });

    //Model library is not exist
    const TXModelFileError = libraryCheck({
      signalName: signal.signalName,
      model: signal[`tx${modelKey}Model`],
      type: ADS_TX,
      modelKey,
      libraryList
    });

    let isUsedRC = !signal[`rx${modelKey}Model`] && signal.rcModel ? true : false;

    const RXModelFileError = libraryCheck({
      signalName: signal.signalName,
      modelKey,
      model: isUsedRC ? signal.rcModel : signal[`rx${modelKey}Model`],
      type: ADS_RX,
      libraryList: isUsedRC ? SPICElibraryList : libraryList
    })

    let TXCircuitModelFileError = null, RXCircuitModelFileError = null;
    if (signal.txCircuitModel && signal.txCircuitModel.libraryId) {
      TXCircuitModelFileError = libraryCheck({
        signalName: signal.signalName,
        model: signal.txCircuitModel,
        type: ADS_TX,
        modelKey: "circuit",
        libraryList: [...SPICElibraryList, ...genericTouchstoneList]
      })
    }
    if (signal.rxCircuitModel && signal.rxCircuitModel.libraryId) {
      RXCircuitModelFileError = libraryCheck({
        signalName: signal.signalName,
        model: signal.rxCircuitModel,
        type: ADS_RX,
        modelKey: "circuit",
        libraryList: [...SPICElibraryList, ...genericTouchstoneList]
      })
    }

    const _AMISignalInfo = new AMIDisplaySignals({
      signal: signal.signalName,
      IbisHasAMI: signal.IbisHasAMI,
      txModel: signal[`tx${modelKey}Model`],
      rxModel: signal[`rx${modelKey}Model`],
      rcModel: signal.rcModel,
      txCircuitModel: signal.txCircuitModel,
      rxCircuitModel: signal.rxCircuitModel,
      controller: {
        component: controllerName,
        positive,
        negative
      },
      device: {
        component: deviceName,
        positive: devicePositive,
        negative: deviceNegative
      },
      aggressors: signal.aggressors,
      TXModelFileError,
      RXModelFileError,
      prbs: signal.prbs,
      TXCircuitModelFileError,
      RXCircuitModelFileError
    })

    if (selectedSignals.includes(signal.signalName)) {
      amiSignals.push(_AMISignalInfo);
    }
    amiAllSignals.push(_AMISignalInfo)
  }
  return { amiSignals, amiAllSignals };
}


function getCPHYSignalList({ adsConfig, signals, components, selectedSignals }) {
  let amiSignals = [], amiAllSignals = [];

  const controllerName = adsConfig.controller,
    deviceName = adsConfig.device;

  const controllerComp = components.find(item => item.name === controllerName) || {};
  const deviceComp = components.find(item => item.name === deviceName) || {};

  const modelKey = "";

  const libraryList = [...(libraryConstructor.getLibraryValues(IBIS) || []), ...(libraryConstructor.getLibraryValues(IBIS_AMI) || [])];
  const SPICElibraryList = libraryConstructor.getLibraryValues(SPICE) || [];

  for (let signal of adsConfig.signals) {

    if (!controllerName && !deviceName) {
      const AMISignalInfo = new AMIDisplaySignals({
        signal: signal.signalName,
        cphyTxModel: signal.cphyTxModel,
        cphyRxModel: signal.cphyRxModel,
      })
      if (selectedSignals.includes(signal.signalName)) {
        amiSignals.push(AMISignalInfo);
      }
      amiAllSignals.push(AMISignalInfo)
      continue;
    }
    //find component pins by signal
    const findSignal = signals.find(it => it.name === signal.signalName);
    //controller
    const { lineA, lineB, lineC } = getCPHYCompPinBySignal({
      signal: findSignal,
      pins: controllerComp.pins
    });
    //device
    const { lineA: deviceLineA, lineB: deviceLineB, lineC: devicedeviceLineC } = getCPHYCompPinBySignal({
      signal: findSignal,
      pins: deviceComp.pins
    });

    //Model library is not exist
    const TXModelFileError = libraryCheck({
      signalName: signal.signalName,
      model: signal.cphyTxModel,
      type: ADS_TX,
      modelKey,
      libraryList
    });

    let isUsedRC = !signal.cphyRxModel && signal.rcModel ? true : false;

    const RXModelFileError = libraryCheck({
      signalName: signal.signalName,
      modelKey,
      model: signal.cphyRxModel,
      type: ADS_RX,
      libraryList: isUsedRC ? SPICElibraryList : libraryList
    })

    const _AMISignalInfo = new AMIDisplaySignals({
      signal: signal.signalName,
      cphyTxModel: signal.cphyTxModel,
      cphyRxModel: signal.cphyRxModel,
      controller: {
        component: controllerName,
        lineA,
        lineB,
        lineC
      },
      device: {
        component: deviceName,
        lineA: deviceLineA,
        lineB: deviceLineB,
        lineC: devicedeviceLineC
      },
      TXModelFileError,
      RXModelFileError,
      prbs: signal.prbs
    })

    if (selectedSignals.includes(signal.signalName)) {
      amiSignals.push(_AMISignalInfo);
    }
    amiAllSignals.push(_AMISignalInfo)
  }
  return { amiSignals, amiAllSignals };
}

export {
  getAMISignalList
}