export const CHANGE_VIEW = 'PDN/change_view';
export const SAVE_OPEN_PROJECT_INFO = 'PDN/save_open_project_info';
export const UPDATE_LIBRARY_MENU = 'PDN/update_lirary_menu';
export const UPDATE_PROJECT_MENU = 'PDN/update_project_menu';
export const LIBRARY_MENU = 'PDN/library_menu';
export const OPEN_PROJECT = 'PDN/open_project';
export const PROJECT_MENU = 'PDN/project_menu';
export const CHANGE_LAYOUT = 'PDN/change_layout';
export const SHOW_RESULT = 'PDN/show_result';
export const CLEAR_CURRENT_PROJECT = 'PDN/clear_current_project';
export const UPDATE_TRASH_MENU = 'PDN/update_trash_menu';
export const TRASH_MENU = 'PDN/trash_menu';
export const CHANGE_VIEW_LIST = 'PDN/change_view_list';
export const CHANGE_TREE_SELECTED_KEY = 'PDN/change_tree_selected_key';
export const SAVE_HISTORY_PROMPT = 'PDN/save_history_prompt';
export const UPDATE_CURRENT_PROJECT = 'PDN/update_current_project';
export const DOWNLOAD_DEBUG_MSG = 'PDN/download_debug_msg';
export const DEBUG_DOWNLOAD_LOG_UPDATE = 'PDN/debug_download_log_update';
export const DOWNLOAD_DEBUG_MSG_CLOSE = 'PDN/download_debug_msg_close';
export const UPDATE_DOWNLOAD_MSG = 'PDN/update_download_msg';
export const UPDATE_PREPARE_PDNS = `PDN/update_prepare_pdns`;
export const REMOVE_PREPARE = `PDN/remove_prepare`;
export const GET_DEFAULT_DECAP = `PDN/get_default_decap`;
export const SET_DEFAULT_DECAP = `PDN/set_default_decap`;
export const DEL_DEFAULT_DECAP = `PDN/del_default_decap`;
export const CREATE_ALL_PDN = `PDN/create_all_pdn`;
export const UPDATE_PDN_REPORT_CONFIG = 'PDN/updete_PDN_report_config';
export const GET_PDN_REPORT = 'PDN/get_report';
export const UPDATE_PDN_REPORT_PROGRESS = 'PDN/update_pdn_report_progress';
export const UPDATE_PDN_REPORT_MESSAGE = 'PDN/update_PDN_report_message';
export const UPDATE_PDN_REPORT_VISIBLE = 'PDN/update_pdn_report_visible';
export const PDN_CLEAR_REPORT_INFO = 'PDN/clear_report_info';
export const FORCE_PREVIEW_SHOW = 'PDN/force_preview_button_show';
export const UPDATE_PCB_LAYOUT = `PDN/update_pcb_layout`;
export const AFTER_IMPORT_LIBRARY = `PDN/after_import_library`;
export const UPDATE_SIM_LOADING = `PDN/update_simulation_loading`;
export const START_LAYOUT_CHECK = `PDN/start_layout_check`;
export const GET_LAYOUT_ERROR_RESULT = `PDN/get_layout_error_result`;