import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import { Popover, Tooltip, Spin, message } from 'antd';
import { ignoreComponents, saveSplitComponents, saveMergeComponents, saveComponents } from '../store/IRExplorer/action';
import { SortFn } from '@/services/helper/sort';
import { isMac } from '@/services/api/userAgent';
import TableTag from '@/components/TableTag';
import { getSplitPartName } from '@/services/helper/setDefaultName';
import { selectChange, selectLayer } from '../../LayoutExplorer/store/Cascade/actionCreators';
import { checkRLCValue, getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { RES, IND, CAP, JUMPER, FERRITE, SWITCH } from '@/services/PCBHelper';
import canvas from '@/services/LayoutCanvas';
import { CHIP, DIODE, IGNORE, REMOVED } from '../../../constants/componentType';

const ComponentsColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '20%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Components',
  dataIndex: 'selectComps',
  width: '40%',
}, {
  title: 'Usage',
  dataIndex: 'usage',
  width: '10%',
  sorter: (a, b) => a.usage.localeCompare(b.usage),
}, {
  title: 'DC Resistance Model (Ω)',
  dataIndex: 'modelName',
  width: '30%',
}];

const RLCUsages = [RES, IND, SWITCH, JUMPER, FERRITE];

class ComponentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      mergePartList: [],
      mergeVisible: false
    }

    ComponentsColumns[0].render = (text, record) => {
      const { mergeVisible, mergePartList } = this.state;
      if (RLCUsages.includes(record.usage) && mergePartList && mergePartList.length > 0 && mergePartList[0] === record.part) {
        return <Popover
          placement="topLeft"
          overlayClassName='merge-part-popover'
          getPopupContainer={() => document.getElementById('cascade-content-main')}
          content={<Fragment>
            <Tooltip title='Merge Part' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className='merge-part-popover-span' onClick={(e) => this.MergePart(e)}>Merge</span>
            </Tooltip>
          </Fragment>}
          open={mergeVisible}
        >
          <span onClick={(e) => this.tableOnRow(e, record)} className='cascade-part-name'>{record.part}</span>
        </Popover>
      } else if (RLCUsages.includes(record.usage)) {
        return <span className='cursor-pointer cascade-part-name' onClick={(e) => this.tableOnRow(e, record)}>{record.part}</span>
      } else {
        return <span className='cascade-part-name'>{record.part}</span>
      }
    }

    ComponentsColumns[1].render = (selectComps, record) => {
      const mac = isMac();
      let tdRef = document.getElementById(`td-${record.part}`);
      const height = tdRef && tdRef.offsetHeight;
      const usageTypejudge = ((!['Removed', ...RLCUsages].includes(record.usage)) ? true : false)
      return <Fragment>
        <div
          className='cascade-component-td'
          id={`td-${record.part}`}
          style={[CAP, 'Removed', ...RLCUsages].includes(record.usage) ? {} : { paddingLeft: 5 }}
        >
          <span className={usageTypejudge
            ? 'cascade-components-name' : 'cascade-component-td-2'} onClick={usageTypejudge
              ? this._selectClick.bind(this, record) : null} >
            {selectComps.length > 0 ? selectComps.join(', ') : <span className='cascade-component-td-2'>Unstuffed</span>}
          </span>
          {mac && height && height > 88 ? <div className='cascade-component-arrow'></div> : null}
        </div>
      </Fragment>
    };

    ComponentsColumns[1].onCell = (record) => {
      return this.componentColumnsDefault(record);
    }

    ComponentsColumns[2].render = (usage, record) => {
      if (usage === IGNORE) {
        return <span className='cascade-components-ignore-name margin-left-5'>{usage}</span>
      } else if (usage === 'Unused') {
        return <span className='cascade-components-unused-name margin-left-5'>{usage}</span>
      } else {
        return <span className='cascade-components-name margin-left-5' >{usage && usage === 'Ferrite' ? 'Ferrite Bead' : usage}</span>
      }
    }

    ComponentsColumns[3].render = (data, record) => {
      const { value } = record;
      if (record.usage === 'Load' || record.usage === DIODE) {
        return null;
      } if (record.usage !== IGNORE && record.usage !== 'Unused') {
        return <span className='cascade-component-td-2'>{value}</span>
      } else {
        return null;
      }
    };

    ComponentsColumns[3].onCell = (record) => {
      return (RLCUsages.includes(record.usage)) && {
        record,
        edit: true,
        dataIndex: 'value',
        handleSave: this.saveValue,
      }
    }
  }

  componentDidMount = () => {
    this.columnsCellFn()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.noPCB !== prevProps.noPCB) {
      this.columnsCellFn()
    }
  }

  columnsCellFn = () => {
    const { noPCB } = this.props;
    if (noPCB) {
      ComponentsColumns[1].onCell = (record) => {
        return {
          record,
          edit: false
        }
      }

      ComponentsColumns[3].onCell = (record) => {
        return {
          record,
          edit: false
        }
      }
    } else {
      ComponentsColumns[1].onCell = (record) => {
        return this.componentColumnsDefault(record);
      }
    }

    ComponentsColumns[3].onCell = (record) => {
      return (RLCUsages.includes(record.usage)) && {
        record,
        edit: true,
        dataIndex: 'value',
        handleSave: this.saveValue,
      }
    }
  }

  componentColumnsDefault = (record) => {
    const { comps } = record;

    let compsName = [];
    comps.forEach(item => {
      if (item.usage !== 'Unused') {
        compsName.push({
          name: item.name,
          checked: true
        });
      } else {
        compsName.push({
          name: item.name,
          checked: false
        });
      }
    });

    return [CAP, REMOVED, ...RLCUsages].includes(record.usage) ? {
      record,
      compsName,
      edit: true,
      customInput: TableTag,
      dataIndex: "selectComps",
      popupContainer: 'cascade-content-main',
      changeCompsChecked: this.changeCompsChecked,
      // changeDecapRemove: this.changeDecapRemove,
      select: this.select,
      splitComps: this.splitComps,
      onClick: (e) => this.stopProps(e),
      isPCBOpened: this.isPCBOpened
    } : null
  }

  isPCBOpened = () => {
    const { designId, selectedDesignIDs } = this.props;
    return selectedDesignIDs.includes(designId);
  }

  MergePart = (e) => {
    e.stopPropagation();
    const { mergePartList } = this.state;
    if (mergePartList.length === 0) {
      return;
    }
    let partName = mergePartList[0];
    this.props._saveMergeComponents({ part: partName, partList: mergePartList });
    this.setState({
      mergePartName: partName,
      mergePartList: []
    });
    setTimeout(() => {
      this.setState({
        mergePartName: null
      });
    }, 3000);
  }

  stopProps = (e) => {
    e.stopPropagation();
  }

  tableOnRow = (e, record) => {
    e.stopPropagation();
    const { part } = record;
    const { mergePartList, mergeVisible, mergeType } = this.state;
    if (mergePartList && mergePartList.length > 0) {
      if (record.usage !== mergeType) {
        message.info("Please select components of the same type to merge")
        return;
      }
    }
    let partList = [...mergePartList], visible = mergeVisible;
    if (!mergePartList.includes(part)) {
      partList.push(part);
    } else {
      partList = partList.filter(item => item !== part);
    }
    if (partList.length > 1) {
      visible = true;
    } else {
      visible = false;
    }
    this.setState({
      mergePartList: partList,
      splitPartName: null,
      mergeVisible: visible,
      mergeType: record.usage
    })
  }

  splitComps = (compList, record) => {
    const { part } = record;
    let partName = part;
    const { allComponents } = this.props;
    const components = this.getComponents(allComponents);
    const newPartName = getSplitPartName({ components, partName });
    this.setState({
      splitPartName: newPartName,
      mergePartList: []
    });
    this.props._saveSplitComponents({ part: record.part, splitPart: newPartName, comps: compList });
    setTimeout(() => {
      this.setState({
        splitPartName: null
      });
    }, 3000);
  }

  select = (record, names) => {
    const { designId } = this.props;
    if (this.isPCBOpened()) {
      const layout = canvas.getLayout(designId);
      let layers = layout.findCurrentLayer(names);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, designId);
      this.props._selectChange({ comps: [...names] }, designId);
    }
  }

  _selectClick = (record) => {
    const { designId } = this.props;
    if (this.isPCBOpened()) {
      const names = record.comps.map(item => item.name)
      this.props._selectChange({ comps: [...names] }, designId);
    }
  }

  changeCompsChecked = (compsName, checked, record) => {
    let components = [...compsName];
    this.props._ignoreComponents({ part: record.part, components, checked, usage: record.usage });
  }

  saveValue = (record) => {
    const value = checkRLCValue(record.value);
    this.props._saveComponents({ part: record.part, model: record.model, value: value })
  }

  openPanel = (e) => {
    e.stopPropagation();
    this.setState({
      visible: true
    })
  }

  getComponents = (Components) => {
    let partList = [], _partComps = [];
    Components.forEach((comp) => {
      const { part, usage, name, pins, type } = comp;
      let existIndex = RLCUsages.includes(type) ? partList.findIndex(item => item.part === part && item.type === type)
        : partList.findIndex(item => item.part === part && item.type === type && item.usage === usage);
      if (existIndex > -1) {
        const _findIndex = RLCUsages.includes(type) ? _partComps.findIndex(item => item.part === part)
          : _partComps.findIndex(item => item.part === part && item.usage === usage);
        _partComps[_findIndex].comps.push({ name, pins, usage, type });

        if (usage !== 'Unused') {
          _partComps[_findIndex].selectComps.push(name);
        }
      } else {
        partList.push({ part, type, usage });
        let _comp = {
          part,
          comps: [{ name, pins, usage, type }],
          usage
        };
        if (RLCUsages.includes(type) || usage === 'Unused') {
          _partComps.push({
            ..._comp,
            value: comp.value,
            prevValue: comp.value,
            selectComps: usage === 'Unused' ? [] : [name]
          });
        } else {
          _partComps.push({
            ..._comp,
            value: comp.value,
            selectComps: [name]
          });
        }
      }
    });

    _partComps.forEach((item, index) => {

      let usages = item.comps.map(comp => comp.usage);
      //If the usage of all components of an item is "Unused", set the usage of the item to COPM_TYPE and selectComps to empty.(Unstuffed)
      //Unstuffed
      if (usages.every(usage => usage === 'Unused')) {
        item.usage = item.comps[0].type;
        item.selectComps = []
      } else if (usages.includes('Unused')) {//includes Unused
        item.usage = item.comps[0].type;
      }
    })

    // update selectComps(Cap/Res/Ind)
    _partComps.forEach((item, index) => {
      if (item.usage !== 'Unused' && item.usage !== CHIP && item.usage !== IGNORE) {
        let selects = []
        selects = item.comps.filter(item => item.usage !== 'Unused');
        item.selectComps = [...selects.map(item => item.name)];
      }
    })

    //Merge removed and other
    _partComps = [..._partComps];
    const sort = ['Load', RES, SWITCH, JUMPER, FERRITE, IND, DIODE, 'Unused', IGNORE];
    _partComps = SortFn(_partComps, sort, 'usage');
    return _partComps;
  }


  render() {
    const { allComponents, IRTableLoading } = this.props;
    const dataSource = this.getComponents(allComponents);
    const { mergePartList, splitPartName, mergePartName } = this.state;
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color">Components</span>
        <div className="space-10">
          <Spin spinning={IRTableLoading}>
            <EditableTable
              onRow={record => {
                let rowClassName = '';
                if (record.part === splitPartName && mergePartList.length === 0 && !mergePartName) {
                  rowClassName = 'cascade-component-split-row'
                } else if (mergePartList.length > 0 && mergePartList.includes(record.part) && !mergePartName) {
                  rowClassName = 'cascade-component-select-row'
                } else if (mergePartName && record.part === mergePartName) {
                  rowClassName = 'cascade-component-merge-row'
                }
                return RLCUsages.includes(record.usage) && {
                  className: rowClassName
                };
              }}
              rowKey={record => `${record.part}-${record.usage}`}
              columns={ComponentsColumns}
              size="small"
              dataSource={dataSource}
              className="cascade-components-table"
            />
          </Spin>
        </div>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, selectedKeys },
    IR: { designId, IRExplorerInfo: { allComponents = [] }, IRTableLoading } } } = state;
  return {
    projectId: openProjectId,
    allComponents,
    IRTableLoading,
    designId,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
  };
}

const mapDispatch = (dispatch) => ({
  _ignoreComponents({ part, components, checked, usage }) {
    dispatch(ignoreComponents({ part, components, checked, usage }));
  },
  _saveSplitComponents({ part, splitPart, comps }) {
    dispatch(saveSplitComponents({ part, splitPart, comps }));
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _saveMergeComponents({ part, partList }) {
    dispatch(saveMergeComponents({ part, partList }));
  },
  _saveComponents({ part, model, value }) {
    dispatch(saveComponents({ part, model, value }));
  },
})

export default connect(mapState, mapDispatch)(ComponentsTable);