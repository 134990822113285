import {
  PROJECT,
  SPICE_CARD_TEMPLATES,
  IO_SIMS,
  SPICE_CARD_TEMPLATE,
  IO_SIM,
  HIMALAYAS_WRITE
} from "../../../constants/treeConstants";
import { SPICE_CARD_TEMPLATE_INDEX, HIMALAYAS_IO_SIM_INDEX } from "../constants";
import himalayasChannels from "./channelsConstructor";

/* Project item*/
function projectItem({ id, name, category, children = [] }) {
  this.id = id;//project id
  this.name = name; //project name
  this.key = PROJECT + '-' + this.id;
  this.dataType = PROJECT; // tree type
  this.category = category;
  this.nodeClass = 'tree-node-project-name';
  this.children = children;
}

/**
 * get project tree children item */
function projectChild({ name, id, dataType, category }) {
  this.name = name; // 'Input' || 'Interface'
  this.key = dataType + '-' + id; // id - projectId
  this.dataType = dataType; // SPICE_CARD_TEMPLATES || IO_SIMS
  this.nodeClass = "";
  this.category = category;
}

/**
 * get project tree list */
function getDefaultProjectTree(arr) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const projectList = arr.map(item => new projectItem({
    id: item.id,
    name: item.name,
    category: item.category,
    children: getProjectChildren(item)
  }));
  return projectList
}
/**
 *  get default project children title */
function getProjectChildren(data) {
  return [SPICE_CARD_TEMPLATES, IO_SIMS].map((key) => new projectChild({
    name: getProjectChildTitle(key),
    id: data.id,
    category: data.category,
    dataType: key
  }))
}

function getProjectChildTitle(key) {
  if ([SPICE_CARD_TEMPLATES, SPICE_CARD_TEMPLATE].includes(key)) {
    return "Input"
  }
  if ([IO_SIMS, IO_SIM].includes(key)) {
    return "Interface"
  }
}

/**
 *  
 * Spice Card Template children item */
function SpiceCardTemplateItem({ id, name, verificationId }) {
  this.id = id; //- spice card template id
  this.name = name;// - spice card template name
  this.verificationId = verificationId;
  this.dataType = SPICE_CARD_TEMPLATE;
  this.key = SPICE_CARD_TEMPLATE + "-" + id; //tree node key
  this.nodeClass = "himalayas-project-spice-card-template-tree"
}

/**
 *  
 * get SpiceCardTemplate children tree list */
function getSpiceCardTemplateTree(list) {
  if (!Array.isArray(list) || !list.length) {
    return [];
  }
  return list.map(item => new SpiceCardTemplateItem({
    id: item.id,
    name: item.name,
    verificationId: item.verificationId
  }))
}

/**
 *  
 * interface children item */
function IOSimItem({ id, name, verificationId, spiceCardId, verificationStatus, mode = HIMALAYAS_WRITE }) {
  this.id = id; //- id
  this.name = name;// -  name
  this.verificationId = verificationId;//verification id
  this.dataType = IO_SIM;
  this.key = IO_SIM + "-" + id; //tree node key
  this.spiceCardId = spiceCardId;
  this.verificationStatus = verificationStatus;
  this.mode = mode;
  this.nodeClass = "himalayas-project-io-sim-tree";
}

/**
 *  
 * get interface children tree list */
function getIOSimTree(list, projectId) {
  if (!Array.isArray(list) || !list.length) {
    return [];
  }
  let ioSimTreeList = [], ioSimList = [], templateMap = new Map();

  for (let item of JSON.parse(JSON.stringify(list))) {
    //
    const itemData = new IOSimItem({
      id: item.id,
      name: item.name,
      verificationId: item.verificationId,
      spiceCardId: item.spiceCardId,
      verificationStatus: item.verificationStatus,
      mode: item.mode
    });
    ioSimList.push(JSON.parse(JSON.stringify(itemData)));
    //update tree
    if (templateMap.has(item.spiceCardId)) {
      templateMap.set(item.spiceCardId, [...templateMap.get(item.spiceCardId), itemData]);
    } else {
      templateMap.set(item.spiceCardId, [itemData]);
    }
  }

  for (let spiceCardId of templateMap.keys()) {
    const signOffItem = himalayasChannels.getInfoById(SPICE_CARD_TEMPLATE, projectId, spiceCardId) || {};
    ioSimTreeList.push({
      id: spiceCardId,
      name: signOffItem.name,
      key: "tree-" + SPICE_CARD_TEMPLATE + "-" + spiceCardId,
      dataType: "tree-" + SPICE_CARD_TEMPLATE,
      designId: signOffItem.designId,
      nodeClass: 'himalayas-project-template-io-sim-tree',
      children: [...templateMap.get(spiceCardId)]
    })
  }

  return { ioSimTreeList, ioSimList }
}

/**
 * update project children
 *  */
function updateProjectChild(children, { spiceCardTemplateList, ioSimTreeList }) {
  children[SPICE_CARD_TEMPLATE_INDEX].children = spiceCardTemplateList;
  children[HIMALAYAS_IO_SIM_INDEX].children = ioSimTreeList;
  return children;
}

function getSetupIndexAndType(dataType) {
  let channelIndex = -1, channelType = null;
  switch (dataType) {
    case SPICE_CARD_TEMPLATE:
      channelIndex = SPICE_CARD_TEMPLATE_INDEX;
      channelType = SPICE_CARD_TEMPLATE;
      break;
    case IO_SIM:
      channelIndex = HIMALAYAS_IO_SIM_INDEX;
      channelType = IO_SIM;
      break;
    default: break;
  }
  return { channelIndex, channelType };
}

function updateChannelTree(channels, dataType, projectId) {
  let list = [], treeList = [];
  switch (dataType) {
    case SPICE_CARD_TEMPLATE:
      list = getSpiceCardTemplateTree(channels);
      break;
    case IO_SIM:
      const { ioSimTreeList, ioSimList } = getIOSimTree(channels, projectId);
      list = ioSimList;
      treeList = ioSimTreeList;
      break;
    default: break;
  }
  return { list, treeList }
}

function getChannelList({ name, projectId, ioSimVerificationId, spiceCardVerificationId }) {

  switch (name) {
    case 'Input':
    case SPICE_CARD_TEMPLATE:
      return { list: himalayasChannels.getList(SPICE_CARD_TEMPLATE, projectId), id: spiceCardVerificationId };
    case 'Interface':
    case IO_SIM:
      return { list: himalayasChannels.getList(IO_SIM, projectId), id: ioSimVerificationId };
    default: return { list: [], id: '' };
  }
}

export {
  getDefaultProjectTree,
  projectItem,
  getProjectChildren,
  getSpiceCardTemplateTree,
  getIOSimTree,
  updateProjectChild,
  getSetupIndexAndType,
  updateChannelTree,
  getProjectChildTitle,
  getChannelList
}