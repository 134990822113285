import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { updateServiceConfig, saveServiceOption } from "../../store/project/action";
import { getServiceConfig } from "../../../../services/Cascade/project/projectCtrl";
import ServiceOption from "../../../../components/ServiceOptions";
import { CASCADE } from "../../../../constants/pageType";

class ServiceOptionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  closeModal = () => {
    this.props._saveServiceOption();
    this.props.closePanel();
  }

  render() {
    const { serviceOptions, openProjectId } = this.props;
    return <ServiceOption
      ansysOption={serviceOptions ? serviceOptions.ansysOption : ""}
      optionsValue={serviceOptions ? serviceOptions.options : {}}
      projectId={openProjectId}
      closeModal={this.closeModal}
      getServiceOptionsConfig={getServiceConfig}
      product={CASCADE}
      _updateServiceConfig={this.props._updateServiceConfig}
    />
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { openProjectId, serviceOptions } } } = state;
  return {
    openProjectId,
    serviceOptions
  }
}

const mapDispatch = (dispatch) => ({
  _updateServiceConfig(serviceOptions) {
    dispatch(updateServiceConfig(serviceOptions))
  },
  _saveServiceOption() {
    dispatch(saveServiceOption())
  }
})

export default connect(mapState, mapDispatch)(ServiceOptionPanel);