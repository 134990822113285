import { call, delay, put, select, takeEvery, fork, cancel } from "@redux-saga/core/effects";
import { START_CHANNEL_EXTRACTION } from "../actionTypes";
import { saveChannelContentToServer, _channelReplace, channelSetupUpdate, updateClipBoundary, _saveHybridRegions } from '../../channel/channelSaga';
import { channelStartExtraction } from '@/services/Andes_v2/extractionCtrl';
import { PCB_CHANNEL } from "@/constants/treeConstants";
import channelConstructor from "@/services/Andes_v2/channel/channelConstructor";
import { getVerificationWorkFlow } from "@/services/workflow/workflow";
import { startNewWorkflow, checkChannelSimStatus, updateResultExistStatus } from '../saga';
import { updateSimulationReducer, updateMultiSimulationInfo, updateSimSelectKeys } from '../action';
import { autoGetChannelList, updateChannelReplaceMonitor } from "../../channel/action";
import { openPage } from '../../project/action';
import { channelErrorCheck } from "../../../Channel/errorCheck";
import {
  getChannelContentPromise,
  updateChannelContentPromise
} from "@/services/Andes_v2/channel";
import { openTabFooter, changeTabMenu } from "../../../../MonitorStore/action";
import { closeCountTime, getCountTime } from "@/services/helper/hasOperate";
import { getDesignStackupJson } from "@/services/api/designFile";
import { stackupErrorCheck } from "@/services/Stackup";
import channelSetupInfo from "@/services/Andes_v2/channel/channelInfo";
import { isEndToEndChildrenChannel, isPreLayout } from "@/services/Andes_v2";
import { getPreLayoutErrorCheck, getPreLayoutInfoById } from '@/services/Andes_v2/preLayout';
import { eyeDiagramResult } from '@/services/Andes_v2/results/eyeDiagram';
import { getPCBInfo } from "../../project/projectSaga";
import { adsConfigErrorCheck } from "../../../../../services/Andes_v2/AMIModelHelper";
import { HDMI, PCIE, GENERIC, CPHY } from "../../../../../services/PCBHelper/constants";
import { ANDES_V2 } from "../../../../../constants/pageType";
import { getDesignHybridInfoPromise } from "@/services/helper/cutDesign/hybrid";
import dayjs from 'dayjs';
import LayoutData from '@/services/data/LayoutData';
import { HSPICE, hspiceConfigErrorCheck } from "../../../../../services/Andes_v2/simulation";

let deleteTask = null
function* _startExtraction(action) {
  const { channelIds, runSeasim, runSimulation, setupOnly, hybridInfo } = action;
  //clear checkbox channel
  yield put(updateSimSelectKeys([], "pcbChannel"));
  const { AndesV2Reducer: { channel: { channelInfo }, project: { viewList, openProjectId }, simulation } } = yield select();
  let currentChannel = null, verificationIds = [];
  let _simulation = { ...simulation }, currentSimInfo = [], simulationChannelIds = [], designStackupErrors = [];

  let _channelIds = [];

  //clear eye diagram cache
  const adsChannelIds = channelIds.map(item => { return { id: item, type: "ads" } });
  const seaSimChannelIds = channelIds.map(item => { return { id: item, type: "compliance" } });
  const hspiceChannelIds = channelIds.map(item => { return { id: item, type: "hspice" } });
  eyeDiagramResult.cleanEyeDiagram([...adsChannelIds, ...seaSimChannelIds, ...hspiceChannelIds]);

  for (let id of channelIds) {
    const channel = channelConstructor.getChannel(id);
    if (!channel) {
      continue;
    }

    //check prev simulation status,if running or waiting, return
    if (yield call(checkChannelSimStatus, channel.verificationId)) {
      continue;
    }

    _channelIds.push(id);
    //clear prev simulation info
    _simulation[channel.verificationId] && delete _simulation[channel.verificationId];
    currentSimInfo.push({
      channelId: id,
      verificationId: channel.verificationId,
      status: 'checking'
    });
    _simulation[channel.verificationId] = {
      startMsg: '==> Checking setup...',
    }
    yield put(autoGetChannelList({ projectId: openProjectId, currentSimInfo }));
    //clear replace monitor
    const { AndesV2Reducer: { channel: { replaceMonitor } } } = yield select();
    const _replaceMonitor = replaceMonitor.filter(item => item.id !== id);
    yield put(updateChannelReplaceMonitor(_replaceMonitor));
  }
  yield put(updateSimulationReducer(_simulation));

  if (_channelIds.length === 0) {
    return;
  }

  for (let id of _channelIds) {
    const channel = channelConstructor.getChannel(id);
    if (!channel) {
      continue;
    }
    const isPreLayoutChannel = isPreLayout(channel.designId);
    let isCurrentOpen = false;
    if (channelInfo && viewList.includes(PCB_CHANNEL) && id === channelInfo.id) {
      //save setup json to server
      //Get current reference nets that need to be updated
      let timeInterVal = getCountTime();
      const isUpdateRefNets = timeInterVal ? true : false;
      //clear count update reference nets time
      closeCountTime();
      yield call(saveChannelContentToServer, { notUpdateList: true, isUpdateRefNets });
      yield put(openTabFooter());
      let verificationName = channel.name;
      if (isEndToEndChildrenChannel(channel)) {
        verificationName = channel.pcbName;
      }
      yield put(changeTabMenu({
        tabSelectKeys: ["monitor"],
        currentVerificationId: channel.verificationId,
        verificationName,
        menuType: "simulation"
      }));
      currentChannel = channel;
      isCurrentOpen = true;
    }
    //get content setup info
    let setupInfo = yield call(getChannelSetupInfo, { id, isCurrentOpen, channelInfo });
    //replace setup and set default port setups

    let hybrid_regions = hybridInfo && hybridInfo.hybridRegions ? hybridInfo.hybridRegions : null, _hybridInfo = hybridInfo;
    if (!hybridInfo && setupInfo && setupInfo.content && setupInfo.content.extraction && setupInfo.content.extraction.hybrid) {
      const info = yield call(getDesignHybridInfoPromise, setupInfo.verificationId);
      hybrid_regions = info.hybrid_regions || null;
      _hybridInfo = info
    }
    let stackup = {};
    if (!isPreLayoutChannel) {
      stackup = yield call(getDesignStackupJson, channel.designId) || {};
    }
    setupInfo = yield call(updateChannelSetupByVersion, { channelInfo: setupInfo, isUpdate: isCurrentOpen, isPreLayoutChannel, updateClip: true, _hybridInfo, materialList: stackup.materials });
    //error check
    const errorCheck = yield call(channelErrorCheck, setupInfo, null, hybrid_regions, stackup.materials);
    if (errorCheck && !isPreLayoutChannel) {
      yield call(LayoutData.getStackupJson, { pcbId: channel.designId });
    }
    let seasimConfigErrors = null;
    /* if (runSeasim && setupInfo.type === PCIE) {
      seasimConfigErrors = channelSeaSimConfigCheck(setupInfo);
    } */

    let adsConfigErrors = null;
    if (runSimulation && [PCIE, HDMI, GENERIC, CPHY].includes(setupInfo.type) && setupInfo.content.simulationSolver !== HSPICE) {
      adsConfigErrors = adsConfigErrorCheck(setupInfo.adsConfig, setupInfo.content.selectedSignals, false, setupInfo.type);
    }

    if (runSimulation && setupInfo.type === CPHY && setupInfo.content && setupInfo.content.simulationSolver === HSPICE) {
      const hspiceConfigErrors = yield call(hspiceConfigErrorCheck, setupInfo.hspiceConfig, setupInfo.content.selectedSignals);
      adsConfigErrors = (adsConfigErrors || []).concat(hspiceConfigErrors);
    }
    let stackupError = null, preLayoutErrors = [];
    //DESIGN stackup error check
    if (!isPreLayoutChannel) {
      const design = designStackupErrors.find(item => item.id === channel.designId);
      if (!design) {
        try {
          if (!stackup || !stackup.layers) {
            stackupError = [{ error: "Stackup file not exist." }]
          } else {
            const extractionSolver = setupInfo && setupInfo.content && setupInfo.content.extraction ? setupInfo.content.extraction.type : null;
            stackupError = stackupErrorCheck({
              ...stackup,
              layers: stackup.layers,
              materialList: stackup.materials,
              unit: stackup.unit,
              extractionSolver,
              pageType: ANDES_V2
            });
          }
          designStackupErrors.push({ id: channel.designId, stackupError });
        } catch (error) {
          console.error(error)
        }
      } else {
        stackupError = design.stackupError;
      }
    } else {
      //pre layout setup check
      const preLayoutInfo = yield call(getPreLayoutInfoById, channel.designId);
      preLayoutErrors = yield call(getPreLayoutErrorCheck, preLayoutInfo);
    }

    let simulationStatus = "success";
    if ((errorCheck && errorCheck.length)
      || (stackupError && stackupError.length)
      || (seasimConfigErrors && seasimConfigErrors.length)
      || (preLayoutErrors && preLayoutErrors.length)
      || (adsConfigErrors && adsConfigErrors.length)
    ) {
      _simulation[channel.verificationId] = {
        errorCheck,
        stackupError,
        seasimConfigErrors,
        preLayoutErrors,
        adsConfigErrors
      }
      simulationStatus = "error";
    } else {
      simulationChannelIds.push(id);
      _simulation[channel.verificationId] = {
        startMsg: '==> Start simulating...',
      }
      verificationIds.push(channel.verificationId);
    }
    const index = currentSimInfo.findIndex(it => it.channelId === id);
    if (index > -1) {
      currentSimInfo[index].status = simulationStatus;
    } else {
      currentSimInfo.push({
        channelId: id,
        verificationId: channel.verificationId,
        status: simulationStatus
      });
    }
  }

  yield put(autoGetChannelList({ projectId: openProjectId, currentSimInfo }));
  //update stackup error 
  _simulation.designStackupErrors = designStackupErrors;
  //update stimulation reducer info
  yield put(updateSimulationReducer(_simulation));
  if (!currentChannel) {
    //open channel
    yield put(openPage({ pageType: PCB_CHANNEL, id: _channelIds[0], simulating: true }));
    currentChannel = channelConstructor.getChannel(_channelIds[0]) || {};
  }

  if (!simulationChannelIds.length) {
    yield put(autoGetChannelList({ projectId: openProjectId }));
    return;
  }

  try {
    if (deleteTask) {
      yield cancel(deleteTask)
    }
    deleteTask = yield fork(updateWaitStartMsg, { verificationIds, _simulation })
    const response = yield call(channelStartExtraction, { channelIds: simulationChannelIds, runSeasim, runSimulation, setupOnly });
    yield cancel(deleteTask)

    if (!response || !Array.isArray(response) || response.length === 0) {
      verificationIds.forEach(item => {
        _simulation[item] = {
          endMsg: response && response.msg ? response.msg : '==> Simulation failed!'
        };
      })
      //update simulation reducer
      yield put(updateSimulationReducer(_simulation));
      yield put(autoGetChannelList({ projectId: openProjectId }));
      return;
    }

    yield put(autoGetChannelList({ projectId: openProjectId }));
    if (currentChannel && currentChannel.verificationId) {
      yield call(updateResultExistStatus, {
        workType: PCB_CHANNEL,
        verificationId: currentChannel.verificationId,
        channelId: currentChannel.id
      });
      const verificationStatus = yield call(getVerificationWorkFlow, currentChannel.verificationId);
      if (verificationStatus && verificationStatus.length > 0) {
        yield call(startNewWorkflow, {
          workType: PCB_CHANNEL,
          channelId: currentChannel.id,
          verificationId: currentChannel.verificationId,
          verificationWork: verificationStatus
        })
        return;
      }
    }
  } catch (error) {
    console.error(error);
    if (deleteTask) { yield cancel(deleteTask) }
    yield delay(1000);
    if (currentChannel.verificationId) {
      //clear current open channel stimulation info
      yield put(updateMultiSimulationInfo({
        verificationId: currentChannel.verificationId,
        info: {
          startMsg: null,
          progress: -1,
          endMsg: error ? `==> ${error}` : '==> Simulation failed!'
        }
      }));
      yield call(updateResultExistStatus, {
        workType: PCB_CHANNEL,
        verificationId: currentChannel.verificationId,
        channelId: currentChannel.id
      })
    }
    yield put(autoGetChannelList({ projectId: openProjectId }));
  }
}

function* updateWaitStartMsg(action) {
  const { verificationIds, _simulation } = action;
  let time, isFirst = true;
  while (true) {
    if (isFirst) {
      yield delay(3000)
    } else {
      yield delay(30000); // wait 30s
    }
    time = new Date();
    isFirst = false
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    verificationIds.forEach(item => {
      if (_simulation[item].startMsg) {
        const addMsg = `\n==> ${waitingTime}  Data pre-processing...`;
        _simulation[item] = {
          startMsg: _simulation[item].startMsg + addMsg,
        };
      }
    })
    yield put(updateSimulationReducer(_simulation));
  }
}

function* getChannelSetupInfo({ id, isCurrentOpen, channelInfo }) {
  if (isCurrentOpen) {
    return channelInfo;
  }
  const res = yield call(getChannelContentPromise, id);
  if (res && res.content) {
    return res;
  }
  return { content: {} };
}

export function* updateChannelSetupByVersion({ channelInfo, isUpdate, endToEndId, isPreLayoutChannel, updateClip, hybridInfo, materialList }) {
  if (!channelInfo || !channelInfo.id) {
    return channelInfo;
  }

  //channel setup update by version
  let {
    save,
    isReplace,
    channelInfo: _channelInfo
  } = yield call(channelSetupUpdate, { channelInfo });

  if (isReplace) {
    //replace pcb
    _channelInfo = yield call(_channelReplace, { channelInfo, isUpdate, endToEndId })
    save = true;
  }

  //updateClipBoundary
  let clipData = null;
  if (updateClip && !isPreLayoutChannel && _channelInfo.content && _channelInfo.content.selectedSignals) {
    yield call(getPCBInfo, { designId: channelInfo.designId });
    clipData = yield call(updateClipBoundary, { selectedSignals: _channelInfo.content.selectedSignals, channelInfo, isSimulate: true });
  }

  //updateClip hybrid Boundary
  if (!isPreLayoutChannel && _channelInfo.content && _channelInfo.content.extraction && _channelInfo.content.extraction.hybrid) {
    yield call(getPCBInfo, { designId: channelInfo.designId });
    const _hybridInfo = hybridInfo || {};
    yield call(_saveHybridRegions, {
      ..._hybridInfo,
      verificationId: _channelInfo.verificationId,
      saveHfssZones: true,
      clipData
    });
  }

  if (save) {
    const hybrid_regions = hybridInfo && hybridInfo.hybrid_regions ? hybridInfo : null;
    const error = yield call(channelErrorCheck, _channelInfo, null, hybrid_regions, materialList)
    channelSetupInfo.set(_channelInfo.id, JSON.parse(JSON.stringify(_channelInfo)));
    yield call(updateChannelContentPromise, { ..._channelInfo, readyForSim: error ? 0 : 1 });
  }

  return _channelInfo;
}

function* channelExtractionSaga() {
  yield takeEvery(START_CHANNEL_EXTRACTION, _startExtraction);
}

export default channelExtractionSaga;