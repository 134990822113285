import {
  VIA,
  TRACE,
  LIBRARY_PACKAGE,
  PKG_TOUCHSTONE,
  PASSIVE,
  PASSIVE_TOUCHSTONE,
  PASSIVE_SPICE,
  CONNECTOR_TOUCHSTONE,
  CABLE_TOUCHSTONE,
  IBIS_AMI,
  PCB_TOUCHSTONE,
  IBIS, SPICE, BUFFER,
  EXTRACTION_OPTIONS,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  ADS_OPTIONS,
  EYE_MASK,
  GENERIC_TOUCHSTONE,
  GENERIC_LIBRARY,
  LEQ_RESPONSE,
  BUFFER_SPICE,
  USER_DEFINED_NETLIST
} from '../../../constants/libraryConstants';
import {
  MY_LIBRARY,
  LIBRARY,
  PROJECTS,
  TRASH,
  LIBRARY_OPEN
} from '@/constants/treeConstants';

export function treeItem({ name, key, dataType, ...restProps }) {
  this.name = name;
  this.key = key;
  this.dataType = dataType;
  this.children = [];
  Object.keys(restProps).map(key => this[key] = restProps[key])
};

function getDefaultTreeItems() {
  return [{
    name: 'Library',
    key: LIBRARY,
    dataType: MY_LIBRARY,
    nodeClass: 'expand-icon-large',
    children: [
      {
        name: 'Trace Template',
        key: TRACE,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      }, {
        name: 'Via Template',
        key: VIA,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      },
      {
        name: 'Passive Model',
        key: PASSIVE,
        dataType: LIBRARY_OPEN,
        nodeClass: 'expand-icon-middle',
        children: [
          { name: 'Touchstone', key: PASSIVE_TOUCHSTONE },
          { name: 'SPICE', key: PASSIVE_SPICE }
        ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
      },
      {
        name: 'Package Model',
        key: LIBRARY_PACKAGE,
        dataType: LIBRARY_OPEN,
        nodeClass: 'expand-icon-middle',
        children: [
          { name: 'Touchstone', key: PKG_TOUCHSTONE },
          //{ name: 'SPICE', key: PKG_SPICE }
        ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
      },
      {
        name: 'PCB Model',
        key: PCB_TOUCHSTONE,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      },
      {
        name: 'Connector Model',
        key: CONNECTOR_TOUCHSTONE,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      },
      {
        name: 'Cable Model',
        key: CABLE_TOUCHSTONE,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      },
      {
        name: 'Buffer Model',
        key: BUFFER,
        dataType: LIBRARY_OPEN,
        nodeClass: 'expand-icon-middle',
        children: [
          { name: 'IBIS AMI', key: IBIS_AMI },
          { name: 'IBIS', key: IBIS },
          { name: 'SPICE', key: BUFFER_SPICE },
          /*  { name: 'SPICE Netlist', key: SPICE, } */
        ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
      },
      {
        name: 'Extraction Options',
        key: EXTRACTION_OPTIONS,
        dataType: LIBRARY_OPEN,
        nodeClass: 'expand-icon-middle',
        children: [
          { name: 'HFSS', key: HFSS_OPTIONS },
          { name: "SIwave", key: SIWAVE_OPTIONS }
        ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
      },
      {
        name: 'ADS Options',
        key: ADS_OPTIONS,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      },
      {
        name: 'Eye Mask',
        key: EYE_MASK,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      },
      {
        name: 'Generic Model',
        key: GENERIC_LIBRARY,
        dataType: LIBRARY_OPEN,
        nodeClass: 'expand-icon-middle',
        children: [
          { name: 'Touchstone', key: GENERIC_TOUCHSTONE, },
          { name: 'SPICE', key: SPICE }
        ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
      },
      {
        name: 'LEQ Response',
        key: LEQ_RESPONSE,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      },
      {
        name: 'User Defined NetList',
        key: USER_DEFINED_NETLIST,
        dataType: LIBRARY,
        nodeClass: 'expand-icon-middle',
        children: []
      }
    ]
  }, {
    name: 'Project',
    key: PROJECTS,
    dataType: PROJECTS,
    nodeClass: 'expand-icon-large',
    children: []
  }, {
    name: 'Trash',
    key: TRASH,
    dataType: TRASH,
    nodeClass: 'expand-icon-large'
  }];
};

export const defaultTreeItems = getDefaultTreeItems();