import { HIMALAYAS_WRITE } from '../../../../constants/treeConstants';
import {
  UPDATE_IO_SIM_INFO,
  CLEAR_IO_SIM_INFO
} from './actionTypes';

const defaultState = {
  verificationId: null,
  channelId: null,
  data: {},
  simOptions: {},
  mode: HIMALAYAS_WRITE
}

export const HimalayasIoSimReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_IO_SIM_INFO:
      return {
        ...state,
        ...action.data //data -> { [dataType]: value, ... }
      }
    case CLEAR_IO_SIM_INFO:
      return {
        ...state,
        verificationId: null,
        channelId: null,
        data: {},
        mode: HIMALAYAS_WRITE
      }
    default: return state;
  }
};