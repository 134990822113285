export const MY_LIBRARY = 'my_library',
  LIBRARY = 'library',
  LIBRARYS = 'librarys',
  PROJECTS = 'projects',
  PROJECT = 'project',
  IntelSPIM_FILE = 'intelSPIM_file',
  VRM_DATA = 'vrm_data',
  DECAP_DATA = 'decap_data',
  DECAP_FILE = 'decap_file',
  DECAP_TOUCHSTONE = 'decap_touchstone',
  PCBs = 'PCBs',
  Packages = 'Packages',
  PDNs = 'PDNs',
  PCB = 'PCB',
  Package = 'Package',
  PDN = 'PDN',
  PROJECT_CREATE = 'project_create',
  PDN_CREATE = 'pdn_create',
  TRASH = 'trash',
  RESULT = 'result',
  My_TRASH = 'my_trash',
  POWER_DOMAIN_FILE = 'power_domain_file',
  POWER_DOMAIN_TOUCHSTONE = 'power_domain_touchstone',
  LIBRARY_FILE = 'library_file',
  TARGET = 'target',
  RESULTS = 'results',
  IMPORT = 'import',
  HISTORY = 'history',
  CREATE_PDN = 'create_pdn',
  UPDATE_PDN = 'update_pdn',
  CREATE_UPDATE = 'create_update',
  DEFAULT_LIBRARY_ID = '0';


export const EXTRACTION_PCB_MODEL = true;