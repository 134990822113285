import AdminSaga from '../../Admin/sagas';
import DesignSagas from '../../Design/components/sagas';
import FileUploadSaga from '../../Design/components/Upload/sagas';
import FolderCreateSaga from '../../Design/components/FolderCreate/FolderForm/sagas';
import LoginSagas from '../../Login/sagas';
import AdminUserSagas from '../../Admin/AdminTable/User/sagas';
import AdminRoleSagas from '../../Admin/AdminTable/Role/sagas';
import PDNSages from '../../PDN/store/rootSagas';
import SierraSagas from '../../Sierra/store/rootSagas';
import CCCSagas from '../../CCC/store/sagas';
import AndesSagas from '../../Andes/store/rootSagas';
import RockySagas from '../../Rocky/store/rootSagas';
import licenseSaga from '../../License/store/saga';
import AndesV2Sagas from '../../Andes_v2/store/rootSagas';
import CascadeSaga from '../../Cascade/store/rootSage'
import StackupSaga from '../../LayoutExplorer/components/Stackup_v1/store/sagas';
import HimalayasSaga from '../../Himalayas/store/rootSagas';
import LayoutSaga from '../../LayoutExplorer/LayoutStore';
import BMASagas from '../../BMA/store/rootSaga';

export default [
  /* Admin */
  AdminSaga,
  AdminUserSagas,
  AdminRoleSagas,
  LoginSagas,
  /* ----- DESIGN SAGA BEGIN ----- */
  DesignSagas,
  FileUploadSaga,
  FolderCreateSaga,
  StackupSaga,
  LayoutSaga,
  /* ---- PDN ----- */
  ...PDNSages,
  /* ---- Sierra ---- */
  ...SierraSagas,
  /* ---- ccc ---- */
  CCCSagas,
  /* ---- Andes ---- */
  ...AndesSagas,
  /* ---- Rocky ---- */
  ...RockySagas,
  /* ---- Rocky BMA ---- */
  ...BMASagas,
  licenseSaga,
  /* Andes v2 */
  ...AndesV2Sagas,
  /* Cascade */
  ...CascadeSaga,
  /* Himalayas */
  ...HimalayasSaga
];