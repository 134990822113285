/**
 * async/await error handling
 * 
 * @param {promise} promise
 * @returns
 */

function errorCatch(promise) {
  return promise
    .then(data => [null, data])
    .catch(err => [err, null])
}


export default errorCatch;