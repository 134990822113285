import React, { Fragment, PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { Spin, Tabs, Checkbox, Divider } from 'antd';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import ExtractionPortContent from './ExtractionPorts';
import ExtractionOptionsRender from './extractionOptionsRender';
import designConstructor from '../../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import '../index.css';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import { SCHEMATIC } from '../../../../services/Sierra/prelayout/prelayoutConstants';

const EXTRACTION_OPTIONS = 'Extraction Options',
  EXTRACTION_PORTS_SETUP = 'Extraction Ports';
class ExtractionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 600,
      maxWidth: 830,
      current: this.getCurrent(props.Interfaces),//pcbId
      loading: false,
      apply: false,
    }
    this.dialogRoot = document.getElementById('root');
    this.selectCurrent = '';
    this.extractionPortRef = {};
    this.extractionContentRef = {};
  };

  getCurrent = (Interfaces) => {
    const { extractionType } = this.props;
    let current = ""
    if (!Interfaces || !Interfaces.length) { return current }
    if (extractionType === EXTRACTION_OPTIONS) {
      const optionList = Interfaces.filter(item => {
        const prelayoutInfo = preLayoutData.getLocalPrelayout(item.pcbId);
        if (prelayoutInfo && prelayoutInfo.content && prelayoutInfo.content.prelayout === SCHEMATIC) {
          return false
        }
        return true;
      })

      current = optionList[0].pcb
    } else {
      const siwaveHfssList = Interfaces.filter(item => item.content.channel && item.content.channel.type && item.content.channel.type !== "Default" && item.content.powerNets && item.content.powerNets.length > 0)
      current = siwaveHfssList ? siwaveHfssList[0].pcb : ""
    }
    return current;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 600),
      maxWidth: getPanelMaxWidth(offset, 830),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();

    const { designList } = this.props;
    if (!this.state.current && designList.length > 0) {
      this.setState({
        current: designList[0].name
      })
    }
  }

  closeModal = () => {
    const { extractionType } = this.props;
    const { current } = this.state
    if (extractionType === EXTRACTION_OPTIONS && this.extractionContentRef && this.extractionContentRef[current]) {
      this.extractionContentRef[current].closeModal(true)
    } else if (extractionType === EXTRACTION_PORTS_SETUP && this.extractionPortRef && this.extractionPortRef[current]) {
      this.extractionPortRef[current].savePortsSetup("close");
    }
    this.props.closeModal()
  }

  onRef = (ref, current) => {
    if (current) {
      this.extractionContentRef[current] = ref
    }
  }

  onPortRef = (ref, current) => {
    if (current) {
      this.extractionPortRef[current] = ref;
    }
  }

  changePCBSelect = (value) => {
    //Switch PCB board
    //Data needs to be saved during switching
    this.selectCurrent = value;
    const { extractionType } = this.props;
    this.setState({
      loading: true
    })
    const { current } = this.state;
    if (extractionType === EXTRACTION_OPTIONS && this.extractionContentRef && this.extractionContentRef[current]) {
      this.extractionContentRef[current].closeModal(false)
    } else if (extractionType === EXTRACTION_PORTS_SETUP && this.extractionPortRef && this.extractionPortRef[current]) {
      const confirm = this.extractionPortRef[current].savePortsSetup("")
      if (confirm) {
        return
      }
    }
    this.updateCurrent()
  }

  updateCurrent = () => {
    setTimeout(() => {
      this.setState({
        current: this.selectCurrent,
        loading: false
      })
    }, 300)
  }

  getDesignList = () => {
    const { Interfaces, extractionType } = this.props;
    const designList = Interfaces.map(item => {
      const vendor = designConstructor.getDesignVendor(item.pcbId);
      let disable = extractionType === EXTRACTION_PORTS_SETUP
        && ((item.content.channel && item.content.channel.type && item.content.channel.type === "Default")
          || item.content.powerNets.length < 1
          || vendor === PRE_LAYOUT) ? true : false;

      const prelayoutInfo = preLayoutData.getLocalPrelayout(item.pcbId);
      if (!disable && prelayoutInfo && prelayoutInfo.content && prelayoutInfo.content.prelayout === SCHEMATIC) {
        disable = true;
      }
      return { name: item.pcb, id: item.pcbId, disable: disable }
    })
    return designList
  }

  getContent = () => {
    const { current, maxHeight, apply } = this.state;
    const { extractionType } = this.props
    if (extractionType === EXTRACTION_OPTIONS) {
      return <ExtractionOptionsRender {...this.props} onRef={this.onRef} current={current} apply={apply} />
    } else {
      return <ExtractionPortContent {...this.props} onRef={this.onPortRef} current={current} maxHeight={maxHeight} updateCurrent={this.updateCurrent} />
    }
  }

  changeApplyToChannels = () => {
    this.setState({
      apply: !this.state.apply
    })
  }

  render() {
    const { maxWidth, maxHeight, current, loading, apply } = this.state;
    const { extractionType } = this.props;
    const designList = this.getDesignList()
    const content = (
      <Panel
        className='sierra-advance-panel sierra-panel'
        title={<div className='sierra-extraction-name'>{extractionType}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        width={getPanelWidth(maxWidth, { defaultWidth: 830 })}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <Spin tip="Loading..." spinning={loading}>
          <div className='sierra-advance-content'>
            {designList && designList.length === 1 ? this.getContent(current) :
              <Tabs activeKey={current} onChange={this.changePCBSelect} items={designList.map(item => ({ key: item.name, label: item.name, disabled: item.disable, children: this.getContent(item.name) }))} />}
            {extractionType === EXTRACTION_OPTIONS && designList && designList.length > 1 ?
              <Fragment>
                <Divider className='sierra-advance-apply-diver' />
                <div className="sierra-extraction-content-item">
                  <Checkbox
                    checked={apply}
                    onChange={this.changeApplyToChannels}
                    style={{ verticalAlign: 'middle' }}
                  />
                  <span>
                    Apply extraction options to all PCBs
                  </span>
                </div>
              </Fragment> : null}
          </div>
        </Spin>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default ExtractionPanel;