import React, { Component } from "react";
import { SettingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import Table from '@/components/EditableTable';
import ChannelCompSelect from './channelCompSelect';
import { TX, RX } from "../../../services/Andes_v2/preLayout";
import { updateChannelsBySignalAndComp, getChannels } from '../../../services/Andes_v2/seaSim';
import { SERDES_TYPES } from "../../../services/PCBHelper";
import { strDelimited } from "../../../services/helper/split";
import DirectionOptions from './DirectionOptions';

const Option = Select.Option;
const channelsColumns = [
  {
    title: 'Signal',
    dataIndex: 'signal',
    width: '34%',
  },
  /*  {
     title: 'Controller',
     dataIndex: 'controller',
     width: '18%',
   },
   {
     title: 'Device',
     dataIndex: 'device',
     width: '18%',
   }, */
  {
    title: 'Direction',
    dataIndex: 'direction',
    width: '20%',
  },
  {
    title: 'Aggressors',
    dataIndex: 'aggressors',
    width: '54%',
  }
]

class ChannelsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popoverVisible: null,
      directionOptionsVisible: false,
      applyToAll: false,
      applyToGroupSignals: true
    };

    /*  channelsColumns[1].title = () => {
       return <div className="channels-component-title">
         <span>Controller</span>
         <Popover
           content={this.SelectComponentRender("controller")}
           trigger="click"
           visible={this.state.popoverVisible === "controller"}
           onVisibleChange={(visible) => this.handleVisibleChange(visible, "controller")}
           overlayClassName="channels-component-controller-component-popover"
           getPopupContainer={() => document.getElementById('channel-config-main-id')}
         >
           <Icon type="edit" />
         </Popover>
       </div>
     } */

    /*  channelsColumns[2].title = () => {
       return <div className="channels-component-title">
         <span>Device</span>
         <Popover
           content={this.SelectComponentRender("device")}
           trigger="click"
           visible={this.state.popoverVisible === "device"}
           onVisibleChange={(visible) => this.handleVisibleChange(visible, "device")}
           overlayClassName="channels-component-device-component-popover"
           getPopupContainer={() => document.getElementById('channel-config-main-id')}
         >
           <Icon type="edit" />
         </Popover>
       </div>
     } */

    /*   channelsColumns[1].render = (value, record) => {
        const controller = record.controller || {};
        let text = `${controller.component}`;
        if (this.props.isEndToEnd && controller.design && controller.design.designName) {
          text = `${controller.design.designName} - ${controller.component}`;
        }
        return record.error ? <Tooltip
          title={`Controller and device must be different components.`}
          overlayClassName='aurora-tooltip'
          mouseEnterDelay={0.4}
        ><div className='aurora-file-check-div'>
            {text}
          </div>
        </Tooltip>
          : <span>{text}</span>
      } */

    /*  channelsColumns[1].onCell = (record) => {
       return {
         edit: "select",
         record: { ...record, controllerComp: record.controller.component },
         options: this.getComponentsOptions(record),
         getPopupContainer: document.getElementById("andes-v2-content-main"),
         dataIndex: "controllerComp",
         dropdownMenuClassName: 'aurora-select-dropdown',
         handleSave: (record) => { this.updateComponent(record, "controller") },
       }
     } */

    /*   channelsColumns[2].render = (value, record) => {
        const device = record.device || {};
        let text = `${device.component}`;
        if (this.props.isEndToEnd && device.design && device.design.designName) {
          text = `${device.design.designName} - ${device.component}`;
        }
        return record.error ? <Tooltip
          title={`Controller and device must be different components.`}
          overlayClassName='aurora-tooltip'
          mouseEnterDelay={0.4}
        ><div className='aurora-file-check-div'>
            {text}
          </div>
        </Tooltip>
          : <span>{text}</span>
      } */

    /*  channelsColumns[2].onCell = (record) => {
       return {
         edit: "select",
         record: { ...record, deviceComp: record.device.component },
         options: this.getComponentsOptions(record),
         getPopupContainer: document.getElementById("andes-v2-content-main"),
         dataIndex: "deviceComp",
         dropdownMenuClassName: 'aurora-select-dropdown',
         handleSave: (record) => { this.updateComponent(record, "device") },
       }
     } */

    channelsColumns[1].title = (record) => {
      return (
        <div>
          <span>Direction</span>
          <SettingOutlined className="direction-setting-icon" onClick={this.openDirectionOptions} />
        </div>
      );
    }

    channelsColumns[1].onCell = (record) => {
      return {
        edit: "select",
        record,
        options: [TX, RX],
        dataIndex: "direction",
        handleSave: (record) => { this.editDirection(record) }
      }
    }

    channelsColumns[2].render = (text, record) => {
      const aggressors = record.aggressors || [];
      return <span>{aggressors.join(', ')}</span>
    }

    channelsColumns[2].onCell = (record) => {
      return {
        edit: "aurora-select",
        selectMode: 'multiple',
        selectButtonTitle: "Select aggressors",
        record,
        options: this.getAggressorsOptions(record),
        getPopupContainer: document.getElementById("root"),
        dataIndex: "aggressors",
        dropdownMenuClassName: 'aurora-select-dropdown',
        handleSave: (record) => { this.editAggressors(record) },
        onChange: (list, record, setFieldsValue) => this.changeAggressors(list, record, setFieldsValue),
        clearSelected: (value, record, setFieldsValue) => this.clearSelectedAggressors(value, record, setFieldsValue)
      }
    }
  }

  openDirectionOptions = () => {
    this.setState({
      directionOptionsVisible: true
    })
  }

  updateDirectionOptions = (checkedOptions) => {
    this.setState({
      applyToAll: checkedOptions.includes("applyToAll"),
      applyToGroupSignals: checkedOptions.includes("applyToGroupSignals"),
      directionOptionsVisible: false
    })
  }

  getAggressorsOptions = (record) => {
    const { signals, selectedSignals = [] } = this.props;
    return signals.filter(item => selectedSignals.includes(item.name) && item.name !== record.signal).map(item => item.name);
  }

  getComponentsOptions = (record) => {
    const { components, isEndToEnd } = this.props;
    //ic / connector and component pins connected current signal nets
    let ICComps = [], ICCompNames = [];
    if (isEndToEnd) {
      for (let comp of components) {
        if (comp.pcbIndex === 1 && comp.pins.find(item => item.signal === record.firstPCB.name)) {
          ICComps.push(comp);
        }
        if (comp.pcbIndex !== 1 && comp.pins.find(item => item.signal === record.lastPCB.name)) {
          ICComps.push(comp);
        }
      }
      ICCompNames = ICComps.map(item => `PCB_${item.pcbIndex}::${item.name}`);
    } else {
      ICComps = components.filter(item => SERDES_TYPES.includes(item.type) && item.pins.find(it => it.signal === record.signal));
      ICCompNames = ICComps.map(item => item.name);
    }
    return ICCompNames;
  }

  handleVisibleChange = (visible, type) => {
    this.setState({ popoverVisible: visible ? type : null })
  }

  SelectComponentRender = (type) => {
    const { components, isEndToEnd } = this.props;
    return <ChannelCompSelect
      components={components}
      type={type}
      isEndToEnd={isEndToEnd}
      closePanel={(comp) => this.saveComponent(comp, type)}
    />
  }

  saveComponent = (component, type, anotherType) => {
    this.setState({ popoverVisible: null });
    const { channels, signals, isEndToEnd, components } = this.props;
    let newComp = null;
    if (isEndToEnd) {
      newComp = components.find(item => component === `PCB_${item.pcbIndex} - ${item.name}`);
    } else {
      newComp = components.find(item => item.name === component);
    }

    if (!newComp) {
      return;
    }

    const _channels = updateChannelsBySignalAndComp({ channels, signals, newComp, type, isEndToEnd, components });
    this.props.saveChannels(_channels);

  }

  updateComponent = (record, type) => {
    const component = record[`${type}Comp`];
    if (!component) {
      return;
    }

    const { channels, signals, components, isEndToEnd } = this.props;
    let newComp = null;
    if (isEndToEnd) {
      const [pcb, compName] = strDelimited(component, "::");
      const pcbIndex = strDelimited(pcb, "_", { returnIndex: 1 });
      newComp = components.find(item => item.name === compName && item.pcbIndex === Number(pcbIndex));
    } else {
      newComp = components.find(item => item.name === component);

    }
    if (!newComp) {
      return;
    }
    const _channels = updateChannelsBySignalAndComp({
      channels,
      signals,
      newComp,
      type,
      signal: record.signal,
      isEndToEnd,
      record,
      components
    });
    this.props.saveChannels(_channels);
  }

  editDirection = (record) => {
    const { channels, signals } = this.props;
    const { applyToGroupSignals, applyToAll } = this.state;

    let _channels = [...channels];
    const index = _channels.findIndex(item => item.signal === record.signal);
    if (index < 0) {
      return;
    }
    _channels[index].direction = record.direction;

    if (applyToAll) {
      _channels.forEach(item => {
        item.direction = record.direction
      })
    }

    if (applyToGroupSignals) {
      const signal = signals.find(item => item.name === _channels[index].signal) || {};
      _channels.forEach(item => {
        const findSignal = signals.find(it => it.name === item.signal) || {};
        if (signal.group && signal.group === findSignal.group) {
          item.direction = record.direction;
        }
      })
    }

    this.props.saveChannels(_channels);
  }

  changeAggressors = (list, record, setFieldsValue) => {
    let aggressors = [];
    if (list.length === 1 && record.aggressors.includes(list[0])) {
      aggressors = record.aggressors.filter(item => item !== list[0]);
    } else {
      aggressors = [...new Set([...record.aggressors, ...list])];
    }

    if (setFieldsValue) {
      setFieldsValue({ aggressors });
    }
    this.saveAggressors(aggressors, record.signal);
  }

  clearSelectedAggressors = (value, record, setFieldsValue) => {
    let aggressors = [...record.aggressors];
    aggressors = aggressors.filter(item => item !== value);
    if (setFieldsValue) {
      setFieldsValue({ aggressors });
    }
    this.saveAggressors(aggressors, record.signal);
  }

  editAggressors = (record) => {
    this.saveAggressors(record.aggressors, record.signal);
  }

  saveAggressors = (aggressors, signal) => {
    const { analysisChannels } = this.props;
    let _analysisChannels = [...analysisChannels];
    const index = _analysisChannels.findIndex(item => item.victim === signal);
    if (index < 0) {
      return;
    }

    _analysisChannels[index].aggressors = [...aggressors];
    this.props.saveAnalysisChannels(_analysisChannels);
  }

  render() {
    const { controller, device, dataList } = getChannels(this.props);
    const { maxHeight, isEndToEnd } = this.props;
    const { directionOptionsVisible, applyToGroupSignals, applyToAll } = this.state;
    return (
      <div className="andes-seasim-channels-content">
        {this.getComponents(controller, device)}
        <Table
          rowKey="signal"
          columns={channelsColumns}
          size="small"
          dataSource={dataList}
          scroll={{ y: dataList.length > 8 ? maxHeight : null }}
          className='andes-v2-table channel-config-channels-table space-10'
        />
        {directionOptionsVisible ? <DirectionOptions
          applyToGroupSignals={applyToGroupSignals}
          applyToAll={applyToAll}
          isEndToEnd={isEndToEnd}
          closePanel={this.updateDirectionOptions}
        /> : null}
      </div>
    )
  }

  getICComps = () => {
    const { components = [], isEndToEnd } = this.props;
    if (isEndToEnd) {
      return JSON.parse(JSON.stringify(components)).map(item => { item.name = `PCB_${item.pcbIndex} - ${item.name}`; return item; });
    } else {
      return components.filter(item => SERDES_TYPES.includes(item.type));
    }
  }

  getComponents(controller, device) {
    const ICComps = this.getICComps();
    return <div className="andes-seasim-channels-component-content">
      <div className="andes-seasim-channels-component-controller">
        <span>Controller</span>
        <Select
          value={controller}
          showSearch
          onChange={(key) => this.saveComponent(key, "controller", "device")}
          placeholder={"Controller"}
          className='aurora-select'
          popupClassName='aurora-select-dropdown'
        >
          {ICComps.map(item =>
            <Option key={item.name}>{item.name}</Option>
          )}
        </Select>
      </div>
      <div className="andes-seasim-channels-component-device">
        <span>Device</span>
        <Select
          value={device}
          showSearch
          onChange={(key) => this.saveComponent(key, "device", "controller")}
          placeholder={"Device"}
          className='aurora-select'
          popupClassName='aurora-select-dropdown'
        >
          {ICComps.map(item =>
            <Option key={item.name}>{item.name}</Option>
          )}
        </Select>
      </div>
    </div>
  }
}


export default ChannelsTable;