import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import PreLayoutComponents from './components';
import Setup from './setup';
import SchematicSetup from './SchematicSetup';
import { SINGLE_PAGE_LAYOUT } from '../../../../constants/layoutConstants';
import { MODEL, SCHEMATIC } from '../../../../services/Sierra/prelayout/prelayoutConstants';
import './index.css';

class PreLayout extends Component {


  getPreLayout = () => {
    const { prelayout, id, components, fixed } = this.props;

    if (prelayout === MODEL) {
      return components.length && id ? <Row className='space-16'>
        <Col span={24} className='sierra-pre-layout-setup-border'>
          <Setup fixed={fixed} />
        </Col>
      </Row> : null
    } else if (prelayout === SCHEMATIC) {
      return id ? <Row className='space-16'>
        <Col span={24} className='sierra-pre-layout-setup-border'>
          <SchematicSetup />
        </Col>
      </Row> : null
    }
  }

  render() {
    const { fixed } = this.props;
    return <div className='sierra-pre-layout-body'>
      <Row>
        <Col span={24} className='sierra-pre-layout-setup-border'>
          <PreLayoutComponents fixed={fixed} />
        </Col>
      </Row>
      {this.getPreLayout()}
    </div>
  }

}

const mapState = (state) => {
  const { SierraReducer: { prelayout: { components, id, prelayout }, project: { layout } } } = state;
  return {
    components,
    id,
    fixed: layout !== SINGLE_PAGE_LAYOUT ? true : false,
    prelayout
  }
}

const mapDispatch = (dispatch) => ({})

export default connect(mapState, mapDispatch)(PreLayout);
