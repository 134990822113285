import React, { Component } from 'react';
import { RedoOutlined } from '@ant-design/icons';
import Panel from '../Panel';
import Table from '../EditableTable';
import { getLicenseFeaturesPromise, getLicenseUsagePromise } from '../../services/License/licenseCtrl';
import dayjs from 'dayjs';
import './index.css';

const columns = [{
  dataIndex: 'feature',
  title: 'Feature Name',
  width: '50%'
}, {
  dataIndex: 'license',
  title: 'Avaliable License',
  width: '50%'
}]

class LicensePanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      licenseTable: [],
      time: ''
    }
  }

  componentDidMount() {
    this.getLicenseTable()
  }

  getLicenseTable = async () => {
    const updateTime = new Date();
    updateTime.setUTCSeconds(updateTime.getUTCSeconds());
    const time = dayjs(updateTime.toUTCString()).format('MM/DD HH:mm:ss');

    const feature = await getLicenseFeaturesPromise() || [];
    const usage = await getLicenseUsagePromise() || [];
    const featureNames = [...new Set(feature.map(item => item.name))];
    const tableData = featureNames.map(item => {
      const usedLicense = usage.filter(u => u.featureName === item);
      const total = feature.filter(f => f.name === item).map(f => f.tokenNumTotal).reduce((a, b) => a + b);
      return { feature: item, license: total - usedLicense.length }
    })

    this.setState({
      licenseTable: tableData,
      time
    })
  }

  panelClose = () => {
    this.props.closeModal(false)
  }

  render() {
    const { licenseTable, time } = this.state;
    return (
      <Panel
        className='develop-panel'
        title={<div className='development-name'>
          License
          <span className='license-time'>
            Updated on {time} <RedoOutlined onClick={this.getLicenseTable} className='license-time-icon' />
          </span>
        </div>}
        onCancel={this.panelClose}
        zIndex={2000}
        width={650}
        position='panel-center-top'
        mask={true}
        draggable
        minWidth={200}
        minHeight={500}
        maxHeight={500}
      >
        <Table
          columns={columns}
          dataSource={licenseTable}
          rowKey={record => record.feature}
          scroll={licenseTable.length > 10 ? { y: 400 } : {}}
          size="small"
        />
      </Panel >
    );
  }
}

export default LicensePanel;