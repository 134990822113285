const PROJECT_TYPE = 'ROCKY';
/* --- Library --- */
export const UPDATE_LIBRARY_MENU = `${PROJECT_TYPE}/update_library_menu`;
export const LIBRARY_MENU = `${PROJECT_TYPE}/library_menu`;
export const UPDATE_MASK_LIBRARY_LIST = `${PROJECT_TYPE}/update_mask_list`;
export const GET_LIBRARY_FOLDER_CHILDREN = `${PROJECT_TYPE}/get_library_folder_children`;
/* --- Project --- */
export const UPDATE_PROJECT_MENU = `${PROJECT_TYPE}/update_project_menu`;
export const EXPAND_MENU = `${PROJECT_TYPE}/expand_menu`;
export const PROJECT_MENU = `${PROJECT_TYPE}/project_menu`;
export const OPEN_PROJECT = `${PROJECT_TYPE}/open_project`;
export const SAVE_OPEN_PROJECT_INFO = `${PROJECT_TYPE}/save_open_project_info`;
export const UPDATE_TREE_SELECTED_KEYS = `${PROJECT_TYPE}/update_tree_selected_keys`;
export const UPDATE_VIEW_LIST = `${PROJECT_TYPE}/update_view_list`;
export const OPEN_CHANNEL = `${PROJECT_TYPE}/update_channel`;
export const CLEAR_CURRENT_PROJECT = `${PROJECT_TYPE}/clear_current_project`;
export const SAVE_CURRENT_CHANNEL_INFO = `${PROJECT_TYPE}/save_current_channel_info`;
export const OPEN_PAGE_INFO = `${PROJECT_TYPE}/open_page_info`;
export const UPDATE_PCBS_LAYOUT_DB = `${PROJECT_TYPE}/update_pcbs_layout_db`;
export const SAVE_COMPONENTS_NETS_IN_PROJECT = `${PROJECT_TYPE}/save_components_nets_in_project`;
export const AUTO_GET_VERIFICATION_LIST = `${PROJECT_TYPE}/auto_get_verification_list`;
export const CHANGE_LAYOUT = `${PROJECT_TYPE}/change_layout`;
export const CLEAN_CURRENT_CHANNEL = `${PROJECT_TYPE}/clean_current_channel`;
export const CLEAN_CHANNEL_BY_PROJECTID = `${PROJECT_TYPE}/clean_channel_by_project`;
export const UPDATE_STACKUP_STATUS_LIST = `${PROJECT_TYPE}/update_stackup_status_list`;
export const UPDATE_CURRENT_LOADING_PCB = `${PROJECT_TYPE}/update_current_loading_pcb`;
export const UPDATE_WAVEFORM_CONFIG = `${PROJECT_TYPE}/update_waveform_config`;
export const SAVE_WAVEFORM_CONFIG = `${PROJECT_TYPE}/save_waveform_config`;
export const SET_DEFAULT_WAVEFORM_CONFIG = `${PROJECT_TYPE}/set_default_waveform_config`;
export const SAVE_WIRE_BOND_PROFILE = `${PROJECT_TYPE}/save_wire_bond_file`;
export const OPEN_SSN_CHANNEL = `${PROJECT_TYPE}/open_ssn_channel`;
export const CLEAR_PCB_RELATED_DATA = `${PROJECT_TYPE}/clear_pcb_related_data`;
export const EDIT_PROJECT_NAME = `${PROJECT_TYPE}/edit_project_name`;
export const LIBRARY_DEFAULT = `${PROJECT_TYPE}/library_default`;
export const UPDATA_DEFAULT_DECAP = `${PROJECT_TYPE}/update_default_decap`;

/* trash */
export const UPDATE_TRASH_MENU = `${PROJECT_TYPE}/update_trash_menu`;
export const TRASH_MENU = `${PROJECT_TYPE}/trash_menu`;

export const GET_REPORT = `${PROJECT_TYPE}/get_report`;
export const UPDATE_REPORT_PROGRESS = `${PROJECT_TYPE}/update_report_progress`;
export const UPDATE_REPORT_VISIBLE = `${PROJECT_TYPE}/update_report_visible`;
export const GET_REPORT_FILE = `${PROJECT_TYPE}/get_report_file`;
export const UPDATE_REPORT_CONFIG = `${PROJECT_TYPE}/update_report_config`;
export const SAVE_REPORT_CONFIG = `${PROJECT_TYPE}/save_report_config`;
export const CLEAR_REPORT_INFO = `${PROJECT_TYPE}/clear_report_info`;
export const UPDATE_REPORT_MESSAGE = `${PROJECT_TYPE}/update_report_message`;

export const DEFAULT_CHANNEL_CONFIG = `${PROJECT_TYPE}/default_channel_config`;

/* Extraction config */
export const UPDATE_EXTRACTION_CONFIG = `${PROJECT_TYPE}/update_extraction_config`;
export const DEFAULT_EXTRACTION_CONFIG = `${PROJECT_TYPE}/default_extraction_config`;
export const SAVE_CHANNEL_EXTRACTION_CONFIG = `${PROJECT_TYPE}/save_channel_extraction_config`;

/* CHANNEL */
export const UPDATE_CHANNEL_COMPONENT_LIST = `${PROJECT_TYPE}/update_channel_component_list`;
export const UPDATE_SSN_CHECK = `${PROJECT_TYPE}/update_ssn_check`;

export const DEBUG_MONITOR = `${PROJECT_TYPE}/debug_monitor`;
export const GET_TOUCHSTONE_FILE_STATUS = `${PROJECT_TYPE}/get_touchstone_file_status`;

export const UPDATE_PROJECT_INTERFACES_INFO = `${PROJECT_TYPE}/update_project_interfaces_info`;

export const UPDATE_SHARE_MENU = `${PROJECT_TYPE}/update_share_menu`;

/* SHARE_MENU */
export const SHARE_MENU = `${PROJECT_TYPE}/share_menu`;

/* PRE_LAYOUT */
export const SAVE_PRE_LAYOUT_TEMPLATE = `${PROJECT_TYPE}/save_pre_layout_template`;
export const UPDATE_EXPORT_PROGRESS = `${PROJECT_TYPE}/update_export_progress`;

/* PCB */
export const REPLACE_PCB = `${PROJECT_TYPE}/replace_pcb`;
export const UPDATE_PCB_LAYOUT = `${PROJECT_TYPE}/update_pcb_layout`;

/* Package Layout*/
export const OPEN_PACKAGE_TREE = `${PROJECT_TYPE}/open_package_tree`;
export const UPDATE_PACKAGE_EXTRACTION_CONFIG = `${PROJECT_TYPE}/update_package_extraction_config`;
export const SAVE_PACKAGE_VERIFICATION_EXTRACTION_CONFIG = `${PROJECT_TYPE}/save_package_verification_extraction_config`;
export const CLEAN_PACKAGE_CURRENT_CHANNEL = `${PROJECT_TYPE}/clean_package_current_channel`;
export const UPDATE_CURRENT_PACKAGE_ID = `${PROJECT_TYPE}/update_current_package_id`;

/* card */
export const DELETE_CARD = `${PROJECT_TYPE}/delete_card`;
export const UPDATE_CARD_LIBRARY_LIST = `${PROJECT_TYPE}/update_card_library_list`;
export const CARD_LIBRARY_RENAME = `${PROJECT_TYPE}/card_library_rename`;

// system library
export const INIT_SYS_LIBRARY = `${PROJECT_TYPE}/init_system_library`;
export const UPDATE_SYS_LIBRARY = `${PROJECT_TYPE}/update_systema_library`;
export const SAVE_LIBRARY_LIST = `${PROJECT_TYPE}/save_library_list`;
