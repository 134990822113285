import CeGeomFactory from './CeGeomFactory';
import CeLocation from './CeLocation';

const CeRefGeometry = function () {
  this.mSymbolID = -1;
  this.mGeometry = null;
  this.mLocation = null;
}

CeRefGeometry.prototype.SetData = function (symbolID, geometry, location) {
  this.mSymbolID = symbolID;
  this.mGeometry = geometry;
  this.mLocation = location;
}

CeRefGeometry.prototype.SetGeomID = function (id) {
  this.mGeomID = id;
}


CeRefGeometry.prototype.WriteIntoIODataNode = function (geomBlk) {
  geomBlk.AddDataItem("SymbolID", this.mSymbolID);
  if (this.mLocation != null) {
    geomBlk.AddDataItem("Location", this.mLocation.GetDataStrings());
  }
  if (this.mGeometry != null) {
    geomBlk.AddBlockNode(this.mGeometry.WriteIntoIODataNode());
  }
}

CeRefGeometry.prototype.ReadFromIODataNode = function (geomBlk) {
  this.mSymbolID = geomBlk.GetItemLong("SymbolID", this.mSymbolID);
  var locVals = geomBlk.GetItemStringList("Location");
  if (locVals != null) {
    this.mLocation = new CeLocation();
    this.mLocation.UpdateByString(locVals);
  }

  var geomNode = geomBlk.GetLastNode();
  if (geomNode != null) {
    var nodeName = geomNode.GetName();
    if (!(nodeName == "SymbolID" || nodeName == "Location")) {
      this.mGeometry = CeGeomFactory.CreateGeometryFromType(nodeName);
      if (this.mGeometry != null)
        this.mGeometry.ReadFromIODataNode(geomNode);
    }
  }
  return true;
}

export default CeRefGeometry;