import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TreeForm from '@/components/TreeNode';
import { strDelimited } from '../../../services/helper/split';
import { PROJECT, SPICE_CARD_TEMPLATE, IO_SIM } from '../../../constants/treeConstants';
import {
  updateExpand,
  openProject,
  closeProject,
  updateSelectedKeys,
  openPage,
  updateProjectMenu
} from '../store/project/action';
import {
  openLibraryFolder
} from '../store/library/action';
import { filterKeys, updateKeys, updateTreeSelectKeys } from '@/services/helper/filterHelper';
import { DESIGN_FAILED } from '../../../constants/designCategory';
import { HIMALAYAS } from '../../../constants/pageType';
import { SOC_SPICE, SPICE_PARAMETERS_LIB } from '../../../constants/libraryConstants';
class TreeSider extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  onExpand = (keys, { expanded, node }) => {
    const [key, id] = strDelimited(node.key, "-");

    if (expanded) {
      //Only one project is allowed to open at the same time
      if (key.match(new RegExp(`${PROJECT}$`))) {
        keys = filterKeys(keys, PROJECT, id);
        this.props._openProject({ keys, id });
      }

      if (key.match(new RegExp(`${SOC_SPICE}|${SPICE_PARAMETERS_LIB}`))) {
        const libraryType = key.match(SOC_SPICE) ? SOC_SPICE : SPICE_PARAMETERS_LIB;
        this.props._openLibraryFolder(id ? node.dataRef : null, libraryType);
      }
    } else {
      if (key.match(new RegExp(`${PROJECT}$`))) {
        this.props._closeProject();
      }
    }
    this.props._updateExpand(keys);
  }

  expandProject = ({ keys, id }) => {

  }

  onSelect = (selectedKeys, { selected, node }) => {
    if (!selected) return;
    const { expandedKeys, layout, pcbLayout } = this.props;
    let keys = [...expandedKeys];
    const eventKey = node.key;
    // curr selected key
    const [key, id] = strDelimited(eventKey, "-");
    if (!key) return;
    //If select design and design category is failed, not open page
    if (node.dataRef.category === DESIGN_FAILED) { return }

    // expand current click node
    const { _keys, preExpand } = updateKeys(keys, eventKey, expandedKeys);
    this.onExpand(_keys, { expanded: !preExpand, node });

    // update selectedKeys
    const prevSelectedKeys = this.props.selectedKeys;
    const _selectedKeys = updateTreeSelectKeys({
      prevSelectedKeys: [...prevSelectedKeys],
      key,
      eventKey,
      selected,
      layout,
      pcbLayout,
      productType: HIMALAYAS
    });
    _selectedKeys.length && this.props._updateSelectedKeys(_selectedKeys);
    const preSelected = prevSelectedKeys.includes(eventKey);
    if (!preSelected && [SPICE_CARD_TEMPLATE, IO_SIM].includes(key)) {
      // open page
      this.props._openPage({ pageType: key, id });
    }
  }

  updateProjectMenu = () => {
    this.props._updateProjectMenu()
  }

  render = () => {
    const { treeItems, expandedKeys, selectedKeys, renameProjectInfoId } = this.props;

    return (
      <Fragment>
        <TreeForm
          treeData={treeItems}
          renderTitle={(item) => this.props.renderTitle(item)}
          onExpand={this.onExpand}
          expandedKeys={expandedKeys}
          onSelect={this.onSelect}
          selectedKeys={selectedKeys}
          autoExpandParent={false}
          defaultExpandParent={false}
          updateProjectMenu={this.updateProjectMenu}
          disableDragProjectId={renameProjectInfoId}
        // Expand parent: autoExpandParent || !prevProps && defaultExpandParent (Auto find the parent node)
        />
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { HimalayasReducer: { project, } } = state;
  const { treeItems, expandedKeys, selectedKeys, layout, pcbLayout } = project;
  return {
    treeItems,
    expandedKeys,
    selectedKeys,
    layout,
    pcbLayout
  }
}

const mapDispatch = (dispatch) => ({
  _updateExpand(expandedKeys) {
    dispatch(updateExpand(expandedKeys))
  },
  _openProject({ keys, id }) {
    dispatch(openProject({ keys, id }))
  },
  _closeProject() {
    dispatch(closeProject())
  },
  _updateSelectedKeys(selectedKeys) {
    dispatch(updateSelectedKeys(selectedKeys))
  },
  _openPage({ pageType, id }) {
    dispatch(openPage({ pageType, id }))
  },
  _openLibraryFolder(itemData, libraryType) {
    dispatch(openLibraryFolder(itemData, libraryType))
  },
  _updateProjectMenu(){
    dispatch(updateProjectMenu())
  }
});

export default connect(mapState, mapDispatch)(TreeSider);