import LayerRenderer from './LayerRenderer';
import CanvasPath from './CanvasPath';

var OutlineLayerRenderer = function (layoutRenderer, layerName, hidden) {
  LayerRenderer.call(this, layoutRenderer, layerName, hidden);
  this.outlineObj = null;
};

OutlineLayerRenderer.prototype = Object.create(LayerRenderer.prototype);
OutlineLayerRenderer.prototype.constructor = OutlineLayerRenderer;

OutlineLayerRenderer.prototype.prepareData = function () {

  // get the design profile and convert it into SVG path data
  if (!this.layoutDB) {
    return;
  }
  var profile = this.layoutDB.GetProfile();
  var gcpoly = profile.ConvertToGCPolygon();

  this.outlineObj = new CanvasPath();
  this.outlineObj.pathData = gcpoly.GetSvgPath();

  // call the virtual function of the parent
  LayerRenderer.prototype.prepareData.call(this);
};

/** Make the outline always visible */
OutlineLayerRenderer.prototype.redraw = function () {
  // add the path to the layer's svg element
  this.layerElement.selectAll('path').remove();
  this.outlineObj.drawSVG(this.layerElement, {
    fill: 'none'
  });

  this.updateStrokeWidth();

  // call the virtual function of the parent
  LayerRenderer.prototype.redraw.call(this);
};

/** virtual function - for line shapes, update the line width of the layer */
OutlineLayerRenderer.prototype.updateStrokeWidth = function () {
  if (this.outlineObj)
    this.outlineObj.setStroke(this.layoutRenderer.outlineStrokeWidth, 'red');
};

export default OutlineLayerRenderer;