import React, { Fragment } from 'react';
import ResultTopMenu from '@/components/ResultTopMenu';
import SaveResult from '@/components/ResultTopMenu/save';
import { CASCADE } from '../../../../constants/pageType';

const menuList = [{
  name: 'Impedance',
  key: 'impedance'
}, {
  name: 'Transient',
  key: 'transient'
}, {
  name: 'Effective RL',
  key: 'effective'
}, {
  name: 'Decap',
  key: 'decap'
}, {
  name: 'Decap Group',
  key: 'decapGroup'
},
  // {
  //   name: 'Rdie & Cdie',
  //   key: 'rdieCdie'
  // }
]

function TopMenu({
  selectedKey,
  changeResultType,
  layoutLeftWidth,
  leftWidth,
  saveHistory }) {
  return (
    <Fragment>
      <ResultTopMenu
        selectedKey={selectedKey}
        changeResultType={changeResultType}
        menu={menuList}
        backSetup={{
          name: 'Setup',
          key: 'setup'
        }}
        layoutLeftWidth={layoutLeftWidth}
        leftWidth={leftWidth}
        product={CASCADE}
      >
        <SaveResult saveResult={saveHistory} />
      </ResultTopMenu>
    </Fragment>
  )
}

export default TopMenu