import { PACKAGE } from "../../../constants/treeConstants";
import { PROJECTS_INDEX, DESIGN_INDEX, PACKAGE_INDEX } from "../index";
import { addChannelAction } from "../project";

/* add channelList to treeItem by designId ,projectId */
function channelListToTree({ _treeItems, projectId, designId, channels, designType }) {
  const design_Index = designType === PACKAGE ? PACKAGE_INDEX : DESIGN_INDEX;
  const { projectIndex, designIndex } = getIndex({ _treeItems, projectId, designId, design_Index });
  if (designIndex < 0 || projectIndex < 0) {
    return _treeItems;
  }

  _treeItems[PROJECTS_INDEX].children[projectIndex].children[design_Index].children[designIndex].children = [
    ...channels,
    new addChannelAction(designId),
  ];
  return _treeItems;
}

/* get project index and design index of treeItem */
function getIndex({ _treeItems, projectId, designId, design_Index }) {
  const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);

  if (projectIndex < 0) {
    return { projectIndex, designIndex: -1 }
  }

  const designIndex = _treeItems[PROJECTS_INDEX].children[projectIndex].children[design_Index].children.findIndex(item => item.id === designId);

  return { projectIndex, designIndex }
}

export {
  channelListToTree
}