import { numberCheck } from "./dataProcess";
import { FRAME_SIZE } from '../../pages/Sierra/constants';

const ErrorMsg = [
  "Please set the plot width from 1 to 2.", //0
]

function eyeDiagramErrorCheckIndex({ value, inputType }) {

  let errorIndex = -1;

  switch (inputType) {
    case FRAME_SIZE:
      if (parseFloat(value) > 2 || parseFloat(value) < 1) {
        errorIndex = 0;
      };
      break;
    default: errorIndex = -1; break;
  };

  return errorIndex;
}

function eyeDiagramErrorCheck({ value, inputType }) {
  let notNum = numberCheck(value, true);
  if (notNum) {
    return notNum;
  }
  const index = eyeDiagramErrorCheckIndex({ value, inputType });
  if (index > -1) {
    return ErrorMsg[index];
  } else {
    return null;
  }
}

export { eyeDiagramErrorCheck, eyeDiagramErrorCheckIndex }; 