import { getDDRSignalType, getNum } from './getSignalType';

class EBDHelper {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  getEBDFiles() {
    return Object.keys(this);
  }

  deleteEBD(id) {
    delete this[id];
  }

  getEBDJson(id) {
    const json = this[id] ? this[id] : null;
    return json;
  }

  getEBDRankList(id) {
    return this[`${id}_rank`] ? this[`${id}_rank`] : null;
  }

  setEBDJson(id, parse) {
    if (id && parse) {
      let ports = [];

      // parse pins
      if (parse.pins) {
        parse.pins.forEach(item => {
          if (!REMOVE.includes(item.signal)) {
            const { pin, signal } = item;
            ports.push({ port: pin, signal, info: `Signal: ${signal}, Pin: ${pin}`, select: false });
          }
        })
      }

      let rankName = [];
      //parse die pins
      if (parse['reference_designators'] && parse['reference_designators'].length) {
        for (let diePins of parse['reference_designators']) {
          const { name, pins } = diePins;
          rankName.push(name);
          pins.forEach(item => {
            if (!REMOVE.includes(item.signal)) {
              const { pin, signal } = item;
              ports.push({ port: `${name}_${pin}`, signal, name, info: `Name: ${name}, Signal: ${signal}`, select: false });
            }
          })
        }
      }

      this[id] = ports;
      this[`${id}_rank`] = rankName;
      return ports
    }
    return [];
  }
}

const REMOVE = ['POWER', 'GND', 'NC'];

function autoSelectEBDPorts({ ports, pairs, libraryId, pinList, rankList, updateModelKey, modelKey }) {
  let _ports = ports ? JSON.parse(JSON.stringify(ports)) : [];
  let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];
  let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : [];

  _pinList.forEach(pinGroup => {
    const { signal, net, pinLeft, pinRight } = pinGroup;
    let rightInfo = null;
    pinRight.forEach((pin, index) => {
      const pinInfo = findCurrentEBDPort({ _pairs, _ports, rankList, libraryId, pin, signal, net, pinType: 'R', index, updateModelKey, modelKey });
      if (pinInfo) {
        _pairs = pinInfo.pairs;
        _ports = pinInfo.ports;
        pin = pinInfo.pin;
        rightInfo = { ...pinInfo.currentPort };
      }
    })
    pinLeft.forEach((pin, index) => {
      const pinInfo = findCurrentEBDPort({ _pairs, _ports, rankList, libraryId, pin, signal, net, pinType: 'L', index, rightInfo, updateModelKey, modelKey });
      if (pinInfo) {
        _pairs = pinInfo.pairs;
        _ports = pinInfo.ports;
        pin = pinInfo.pin;
      }
    })
  })
  return { pinList: _pinList, pairs: _pairs }
}

function findCurrentEBDPort({ _pairs, _ports, rankList, libraryId, pin, signal, net, pinType, index, rightInfo, updateModelKey, modelKey }) {
  if (pin && pin.pinValue) {
    if (pinType === 'R') {
      const currentPort = _ports.find(port => port.port === pin.pinValue);
      return currentPort ? { currentPort, pairs: _pairs, ports: _ports, pin } : null;
    } else {
      return;
    }
  }

  let pairs = _pairs ? JSON.parse(JSON.stringify(_pairs)) : [];
  let ports = _ports ? JSON.parse(JSON.stringify(_ports)) : [];
  let selectList = [], bestSelectList = [], currentPort = null;
  let ports_f = pinType === 'R' ? ports.filter(port => !port.name) : ports.filter(port => port.name);

  let findPort = ports_f.find(port => port.port === pin.pin || signal === port.signal);
  if (pinType === 'L' && rightInfo) {
    const findPorts = ports_f.filter(port => port.name && port.signal === rightInfo.signal);
    let rankName = null;
    if (rankList && rankList.length) {
      rankName = rankList[index];
    }
    if (rankName) {
      findPort = findPorts.find(item => item.name === rankName);
    }
  }

  if (findPort) {
    currentPort = findPort;
  } else if (pinType === 'R') {
    const ddrSignalType = getDDRSignalType(signal);
    if (!ddrSignalType) {
      return;
    }

    for (let port of ports_f) {
      if (!port.name) {
        if (checkPortBestMatch({ signal, port, net })) {
          bestSelectList.push(port);
        } else if (checkEBDPort({ port, ddrSignalType, net, signal })) {
          selectList.push(port);
        }
      }
    }

    if (bestSelectList.length) {
      currentPort = bestSelectList.length > index ? bestSelectList[index] : bestSelectList[0];
    } else if (selectList.length) {
      currentPort = selectList.length > index ? selectList[index] : selectList[0];
    }
  }

  if (currentPort && !currentPort.select) {
    const findIndex = pairs.findIndex(pair => pair.pin === pin.pin);
    pin.pinValue = currentPort.port;
    pin.pinLibraryId = libraryId;
    if (updateModelKey) {
      pin.pinModelKey = modelKey
    }
    if (pairs[findIndex]) {
      pairs[findIndex].node = currentPort.port;
      pairs[findIndex].libraryId = libraryId;
      if (updateModelKey) {
        pairs[findIndex].modelKey = modelKey
      }
    }
    ports = ports.filter(port => port.port !== currentPort.port);
  }
  return { pairs, ports, pin, currentPort }
}

function checkPortBestMatch({ signal, port, net }) {
  // signal: DQ0, port.signal: DQ0_U1
  // net: DQ0_1, port.signal: XX_DQ0_1_XX
  if (port.signal.includes(signal) || port.signal.includes(net)) {
    return true;
  }
  return false;
}

function checkEBDPort({ port, ddrSignalType, net, signal }) {
  const _signal = port.signal.toUpperCase();
  if (['CLK', 'DQS', 'WCK'].includes(ddrSignalType)) {
    const matchWord = ["DQSP", "CLKP", "WCK_T", "RDQS_T"].includes(signal) ? 'T' : 'C';
    const reg = new RegExp(`${matchWord}`, 'gi');
    for (let type of ddrSignalType.portTypeList) {
      const typeReg = new RegExp(`([^a-z]+${type}[^a-z]*)|(^${type})`, 'gi');
      if (_signal.match(typeReg) && _signal.replace(type, '').match(reg)) { //judge clk_t and clk_c
        return true;
      }
    }
  } else if (ddrSignalType.type === 'DQ' && _signal.includes(signal)) {
    return true;
  } else {
    for (let type of ddrSignalType.portTypeList) {
      if (_signal.includes(type) && (type !== 'A' || (type === 'A' && !_signal.match(/[a-z]a[a-z]*/ig)))) {
        // number: 0 , type: CA, _signal: CA0
        // number: null, type: WE, _signal: WE_1
        const number = getNum(signal);
        if (!number || number === getNum(_signal)) {
          return true;
        }
      }
    }
  }
  return false;
}

function findSelectedEBDPorts({ ports, pairs, libraryId }) {
  let _ports = [...ports];
  const selected = pairs.filter(item => item.node && item.libraryId === libraryId).map(item => item.node);
  if (selected && selected.length) {
    _ports.forEach(port => {
      port.select = selected.includes(port.port) ? true : false;
    })
  }
  return _ports
}

export default EBDHelper;

export {
  autoSelectEBDPorts,
  findSelectedEBDPorts
}