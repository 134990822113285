import { combineReducers } from 'redux';
import {
  PDNProjectReducer
} from './project';
import {
  default as explorerReducer
} from '../../LayoutExplorer/store/FastPI/reducer';
import {
  default as stackupReducer
} from '../../LayoutExplorer/FastPI/stackup/store/reducer';
import { TabMonitorReducer } from './tabMonitor';
import { PDNReducer } from './pdn';
import { simulationReducer } from './simulation';
import PDNUploadReducer from '../PdnSider/Upload/reducer';
import { resultReducer } from './result';

const rootReducer = combineReducers({
  project: PDNProjectReducer,
  explorer: explorerReducer,
  PDNUploadReducer: PDNUploadReducer,
  TabMonitorReducer: TabMonitorReducer,
  pdn: PDNReducer,
  stackup: stackupReducer,
  simulationReducer,
  resultReducer
});

export default rootReducer;