import { EXPERIMENTS } from '../../../constants/treeConstants';

function sweepItem({ id, name, channelId, designId, nodeClass, verificationId, status }) {
  this.id = id;
  this.name = name;
  this.key = EXPERIMENTS + '-' + id;
  this.dataType = EXPERIMENTS;
  this.designId = designId
  this.channelId = channelId;
  this.children = [];
  this.icon = 'experiments';
  this.nodeClass = nodeClass;
  this.status = status;
  this.verificationId = verificationId;
}

function getSweepsTree(arr) {
  if (!Array.isArray(arr)) {
    return [];
  };
  return arr.map(item => new sweepItem({
    id: item.id,
    name: item.name,
    channelId: item.channelId,
    designId: item.designId,
    verificationId: item.verificationId,
    status: item.status,
    nodeClass: 'andes-v2-sweep-li'
  }));
}

export {
  getSweepsTree,
  sweepItem
}