import React, { Component } from 'react';
import EditableTable from '@/components/EditableTable';
import { Row, Col } from 'antd';
import { EMPTY_VALUE_DISABLED_LIST, getIOSignalsTableData } from '../../../services/Himalayas';
import './index.css';

const defaultColumns = [{
  title: "",
  key: "key",
  dataIndex: "key",
  width: 212
}]

class SignalsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    }
  }

  componentDidMount = () => {
    this.initColumns()
    this.initTableData();
  }

  componentDidUpdate = (prevProps) => {
    const { signalList, selectedTab } = this.props;
    if (signalList && signalList.length && (!prevProps.signalList || signalList.length !== prevProps.signalList.length || selectedTab !== prevProps.selectedTab)) {
      this.initColumns();
      this.initTableData();
    }
  }

  initColumns = () => {
    const { signalList } = this.props;
    let columns = JSON.parse(JSON.stringify(defaultColumns));

    const mainEle = document.getElementById("himalayas-content-main");
    let mainWidth = 0;
    if (mainEle && mainEle.offsetWidth) {
      mainWidth = mainEle.offsetWidth - 292;
      mainWidth = mainWidth > 520 ? mainWidth : 520
    }
    let signalWidth = mainWidth / signalList.length;
    signalWidth = signalWidth < 136 ? 136 : signalWidth;

    columns[0].render = (text, record) => {
      return <span className="spice-card-name-title"
        style={{ backgroundColor: record.color || "" }}>
        {record.name}</span>
    }
    for (let item of signalList) {
      columns.push({
        title: item.sig,
        key: item.sig,
        dataIndex: item.sig,
        width: signalWidth,
        render: (text, record) => {
          const disabledClassName = EMPTY_VALUE_DISABLED_LIST.includes(record.key) && !text ? "aurora-table-row-disabled" : ""
          return !text
            ? <div className='spice-card-table-td'>
              <span className={`${disabledClassName}`}></span>
            </div>
            : <span className={`${disabledClassName}`}>{text || ""}</span>
        }
      })
    }
    this.setState({
      columns
    })
  }

  initTableData = () => {
    const { signalList } = this.props;
    this.setState({
      signals: getIOSignalsTableData(signalList)
    })
  }

  render() {
    const { columns, signals } = this.state;
    return <Row className='space-16'>
      <Col span={24} className='aurora-setup-border'>
        <span className="font-bold cascade-setup-title-color">Signals</span>
        <EditableTable
          rowKey={record => record.key}
          columns={columns || []}
          size="small"
          dataSource={signals || []}
          /*  scroll={{ x: "max-content" }} */
          className="himalayas-spice-card-table"
        />
      </Col>
    </Row>
  }
}

export default SignalsTable;