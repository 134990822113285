import {
  UPDATE_SSN_CENTRIC_INFO,
  UPDATE_CENTRIC_CONTENT_LOADING,
  CHANGE_SELECT_INTERFACE_INFO,
  UPDATE_PATTERN_SWEEP_TABLE,
  UPDATE_PATTERN_SWEEP_INFO,
  UPDATE_SOME_CONTENT_INFO,
  CLEAR_SSN_INFO
} from './actionType';

const defaultState = {
  id: "",
  centricContentLoading: false,
  name: "",
  selectInterfaceId: "",
  selectInterfaceName: "",
  selectInterfaceInfo: {},
  ssn: [],
  updatePatternTable: false,
  content: {}
}

export const RockySSNReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SSN_CENTRIC_INFO:
      return {
        ...state,
        ...action.info
      }
    case UPDATE_CENTRIC_CONTENT_LOADING:
      return {
        ...state,
        centricContentLoading: action.boolean
      }
    case CHANGE_SELECT_INTERFACE_INFO:
      return {
        ...state,
        selectInterfaceInfo: action.info
      }
    case UPDATE_PATTERN_SWEEP_TABLE:
      return {
        ...state,
        updatePatternTable: action.updatePatternTable
      }
    // case UPDATE_PATTERN_SWEEP_INFO:
    //   return {
    //     ...state,
    //     content: {
    //       ...state.content,
    //       patternSweeps: action.patternSweeps
    //     }
    //   }
    case UPDATE_SOME_CONTENT_INFO:
      return {
        ...state,
        content: {
          ...state.content,
          ...action.info
        }
      }
    case CLEAR_SSN_INFO:
      return {
        ...defaultState
      }

    default: return state
  }
}