import { call, put, takeEvery, select, delay, fork } from 'redux-saga/effects';
import { SAVE_EYE_DIAGRAM, SET_DEFAULT_EYE_DIAGRAM_CONFIG, UPDATE_EYE_DIAGRAM_CONFIG, RECALC_MEASURE } from './actionTypes';
import { saveEyeDiagramConfig, getDefaultTimeScale, getEyeDefaultUI, getSimulationClock, getEyeDefaultVRef } from '@/services/Sierra/results';
import { updateEyeDiagramConfig, saveEyeUnitValue, updateEyeDiagramClocks, updateRecalc, updateSweepReCalc, updateSweepMeasurementProgress } from './action';
import { EyeDiagramConfig, settingUnit } from '@/services/Sierra';
import { getUnitConversionValue } from '@/services/helper/numberHelper';
import {
  DOUBLE_EDGE,
  RISING_EDGE,
  DELAY,
  T_SHIFT,
  CLOCKS,
  SINGLE_DATA_RATE,
  DOUBLE_DATA_RATE,
  NO_CLOCK,
  SINGLE,
  DIFFERENTIAL,
  timeScaleList,
  CLOCK_FREQUENCY,
  EYE_CONFIG,
  V_REF
} from '../../constants';
import { saveProcessingConfig } from '../../../../services/Sierra/results';
import { runMultiExperimentSim, verificationFlow } from '../simulation/action';
import { autoGetVerificationList } from '../project/action';

function* saveEyeDiagram(action) {
  /*   const { verificationId, clocks, signals } = action;
    const res = yield call(saveEyeDiagrams, { verificationId, clocks, signals });
    if (res) {
      let { status, progress, id } = res;
      yield put(changeEyeDiagramProgress(verificationId, progress));
      while (status === 1) {
        yield delay(2000);
        const { data: { data } } = yield call(getWorkFlow, id);
        status = data.status;
        if (data.progress >= 3) {
          progress = data.progress;
        } else {
          progress = 3;
        }
        yield put(changeEyeDiagramProgress(verificationId, progress));
      }
  
      yield put(changeEyeDiagramProgress(verificationId, 100));
      yield delay(2000);
      yield put(changeEyeDiagramProgress(verificationId, -1));
    } */
}

function* setDefaultEyeDiagramConfig(action) {
  const { verificationId, clocks, /* receiverSignals, */ DDRSignals, signals = [], currentInterfaceInfo, /* allSignalPins = [] */ } = action;
  let _clocks = clocks ? [...clocks] : [];
  let clockPins = [];
  const { SierraReducer: { resultReducer: { simulationConfig, eyeDiagramUnitValue } } } = yield select();
  //Get clock
  const clock = getSimulationClock(simulationConfig);
  const clockObj = simulationConfig && simulationConfig.clock ? settingUnit(simulationConfig.clock, "M") : { value: 1, unit: "M" };

  let _eyeDiagramUnitValue = JSON.parse(JSON.stringify(eyeDiagramUnitValue)), eyeClockType = NO_CLOCK,
    clockPositive = "", clockNegative = "", clockPPin = {}, clockNPin = {};

  if (_clocks && _clocks.length === 1 && signals && signals.length > 1) {
    eyeClockType = SINGLE;
    /*     const clockObj = clockPins = allSignalPins.find(item => item.signal === _clocks[0]);
        clockPins = clockObj ? [{
          component: clockObj.component,
          pin: clockObj.pin,
          pcbId: clockObj.pcbId,
          signal: clockObj.signal
        }] : []; */
  }

  if (_clocks && _clocks.length === 2) {
    eyeClockType = DIFFERENTIAL;
    clockPositive = _clocks[0];
    clockNegative = _clocks[1];
    /*   const clockPObj = clockPins = allSignalPins.find(item => item.signal === clockPositive);
      clockPPin = clockPObj ? {
        component: clockPObj.component,
        pin: clockPObj.pin,
        pcbId: clockPObj.pcbId,
        signal: clockPObj.signal
      } : {};
      const clockNObj = clockPins = allSignalPins.find(item => item.signal === clockNegative);
      clockNPin = clockNObj ? {
        component: clockNObj.component,
        pin: clockNObj.pin,
        pcbId: clockNObj.pcbId,
        signal: clockNObj.signal
      } : {};
      clockPins = clockPObj && clockNObj ? [clockPPin, clockNPin] : [] */
  }
  //trigger
  //If 'No Clock' is selected in Clock Type, change the Trigger Type options to 'Single Data Rate' and 'Double Data Rate'. The default value is determined by the stimulus.
  // If there is a DDR in the stimulus, then set the default to 'Double Data Rate'. Otherwise use 'Single Data Rate'
  let trigger = "";
  if (_clocks && _clocks.length && eyeClockType !== NO_CLOCK) {
    trigger = DDRSignals && DDRSignals.length ? DOUBLE_EDGE : RISING_EDGE;
  } else {
    trigger = DDRSignals && DDRSignals.length ? DOUBLE_DATA_RATE : SINGLE_DATA_RATE;
  }

  //get ui
  const ui = getEyeDefaultUI(clock, trigger);

  //v_ref ===> 1/2 * voltage
  const v_ref = getEyeDefaultVRef(currentInterfaceInfo);

  //get time_scale
  let time_scale = getDefaultTimeScale(ui);
  //set default x_label by time_scale
  const findTimeScale = timeScaleList.find(item => item.name === time_scale);
  let x_label = "";
  if (findTimeScale && findTimeScale.display) {
    x_label = findTimeScale.display;
  }

  let config = new EyeDiagramConfig({
    clocks: eyeClockType === NO_CLOCK ? [] : _clocks,
    signals: [],
    ui,
    v_ref,
    trigger: trigger,
    time_scale: time_scale,
    x_label,
    clockFrequency: clock,
    clockPins
  });

  _eyeDiagramUnitValue[DELAY] = {
    value: "0",
    unit: 'ps'
  }
  _eyeDiagramUnitValue[T_SHIFT] = {
    value: "0",
    unit: 'ps'
  }

  _eyeDiagramUnitValue[CLOCK_FREQUENCY] = {
    value: clockObj.value,
    unit: `${clockObj.unit}Hz`
  }

  if (eyeClockType === NO_CLOCK || eyeClockType === DIFFERENTIAL) {
    config[EYE_CONFIG][V_REF] = "0";
  }

  yield put(updateEyeDiagramConfig({ eyeDiagramConfig: config }));
  yield put(updateEyeDiagramClocks({ eyeClockType, clockPositive, clockNegative, clockPPin, clockNPin }));
  yield put(saveEyeUnitValue(_eyeDiagramUnitValue));
  yield call(saveEyeDiagramConfig, { eyeDiagramConfig: config, verificationId });
}

function* _saveEyeDiagramConfig(action) {
  const { eyeDiagramConfig, isSave, isUpdate, verificationId } = action;
  if (isSave && verificationId) {
    yield call(saveEyeDiagramConfig, { eyeDiagramConfig, verificationId });
  }

  const { SierraReducer: { resultReducer: { eyeDiagramUnitValue, simulationConfig } } } = yield select();
  if (isUpdate) {
    let _eyeDiagramConfig = eyeDiagramConfig ? JSON.parse(JSON.stringify(eyeDiagramConfig)) : {};
    let save = false;
    const eye_config = eyeDiagramConfig.eye_config, plot_config = eyeDiagramConfig.plot_config;
    let _eyeDiagramUnitValue = JSON.parse(JSON.stringify(eyeDiagramUnitValue)), eyeClockType = NO_CLOCK, clockPositive = "", clockNegative = "";
    _eyeDiagramUnitValue[DELAY] = {
      value: getUnitConversionValue(eye_config[DELAY], 'ps', 's'),
      unit: 'ps'
    }
    _eyeDiagramUnitValue[T_SHIFT] = {
      value: getUnitConversionValue(eye_config[T_SHIFT], 'ps', 's'),
      unit: 'ps'
    }

    const clockHzValue = eye_config[CLOCK_FREQUENCY] ? eye_config[CLOCK_FREQUENCY] : getSimulationClock(simulationConfig);
    _eyeDiagramUnitValue[CLOCK_FREQUENCY] = {
      value: getUnitConversionValue(clockHzValue, "MHz", 'Hz'),
      unit: `MHz`
    }

    if (eye_config[CLOCK_FREQUENCY] === undefined) {
      _eyeDiagramConfig.eye_config[CLOCK_FREQUENCY] = clockHzValue;
      save = true;
    }

    if (eye_config[CLOCKS] && eye_config[CLOCKS].length === 1) {
      eyeClockType = SINGLE;
    }

    if (eye_config[CLOCKS] && eye_config[CLOCKS].length === 2) {
      eyeClockType = DIFFERENTIAL;
      clockPositive = eye_config[CLOCKS][0];
      clockNegative = eye_config[CLOCKS][1];
    }

    if ((eyeClockType === NO_CLOCK || eyeClockType === DIFFERENTIAL) && _eyeDiagramConfig[EYE_CONFIG][V_REF] !== "0") {
      _eyeDiagramConfig[EYE_CONFIG][V_REF] = "0";
      save = true;
    }

    if (eyeClockType === NO_CLOCK && _eyeDiagramConfig[EYE_CONFIG][T_SHIFT] !== "0") {
      _eyeDiagramConfig[EYE_CONFIG][T_SHIFT] = "0";
      save = true;
    }

    if (plot_config.x_label === undefined) {
      //get time_scale
      let time_scale = plot_config.time_scale;
      //set default x_label by time_scale
      const findTimeScale = timeScaleList.find(item => item.unit === time_scale || parseFloat(item.unit) === parseFloat(time_scale));
      let x_label = "";
      if (findTimeScale && findTimeScale.display) {
        x_label = findTimeScale.display;
        time_scale = findTimeScale.name;
      }
      _eyeDiagramConfig.plot_config.x_label = x_label;
      _eyeDiagramConfig.plot_config.time_scale = time_scale;
      save = true;
    }

    if (plot_config.paper_x === undefined) {
      _eyeDiagramConfig.plot_config.paper_x = 6.4;
      _eyeDiagramConfig.plot_config.paper_y = 4.8;
      _eyeDiagramConfig.plot_config.dpi = 200; //default 100, The default(100) label is very fuzzy
      save = true;
    }

    if (save) {
      yield put(updateEyeDiagramConfig({ eyeDiagramConfig: _eyeDiagramConfig }));
      yield call(saveEyeDiagramConfig, { eyeDiagramConfig: _eyeDiagramConfig, verificationId });
    }

    yield put(updateEyeDiagramClocks({ eyeClockType, clockPositive, clockNegative }));

    yield put(saveEyeUnitValue(_eyeDiagramUnitValue));
  }
}

function* recalcMeasure(action) {
  const { configList, isSweep } = action;
  const { SierraReducer: { project: { currentProjectId }, resultReducer: { resultInfo, sweepReCalc } } } = yield select();
  try {
    if (isSweep) {
      yield fork(updateSweepPostProcessProgress, { runningIds: (configList || []).map(item => item.id), projectId: currentProjectId })
      let runningIds = [];
      for (let item of configList || []) {
        const res = item.verificationId && item.config ? yield call(saveProcessingConfig, item.verificationId, item.config) : null;
        if (res) {
          runningIds.push(item.id)
          yield put(updateSweepMeasurementProgress(0));
        } else {
          const _sweepReCalc = (sweepReCalc || []).filter(item => item !== item.id);
          yield put(updateSweepReCalc(_sweepReCalc))
        }
      }

      if (runningIds.length) {
        yield put(runMultiExperimentSim(runningIds));
      }
    } else {
      const res = configList && configList[0] && configList[0].config ? yield call(saveProcessingConfig, resultInfo.verificationId, configList[0].config) : null;
      if (res) {
        yield put(verificationFlow({ verificationId: resultInfo.verificationId, verificationWork: [res] }))
        yield delay(1000);
        yield put(autoGetVerificationList(currentProjectId));
      } else {
        yield put(updateRecalc(false))
      }
    }
  } catch (e) {
    if (isSweep) {
      yield put(updateSweepReCalc(null))
    } else {
      yield put(updateRecalc(false))
    }
    console.error(e)
  }
}

function* updateSweepPostProcessProgress(action) {
  const { runningIds, projectId } = action;
  let _runningIds = [...runningIds];
  while (_runningIds.length) {
    const { SierraReducer: { project: { currentProjectId }, resultReducer: { sweepMeasureProgress, sweepReCalc } } } = yield select();
    let progress = sweepMeasureProgress;
    if (projectId !== currentProjectId || !sweepReCalc || !sweepReCalc.length) {
      yield put(updateSweepMeasurementProgress(100));
      yield delay(600)
      yield put(updateSweepMeasurementProgress(-1));
      break;
    }
    progress = Number((progress + Math.random() * 5).toFixed(1));
    _runningIds = runningIds.filter(item => (sweepReCalc || []).includes(item));

    yield put(updateSweepMeasurementProgress(progress));
    yield delay(1500)
  }
}

function* sierraResultSaga() {
  yield takeEvery(SAVE_EYE_DIAGRAM, saveEyeDiagram);
  yield takeEvery(SET_DEFAULT_EYE_DIAGRAM_CONFIG, setDefaultEyeDiagramConfig);
  yield takeEvery(UPDATE_EYE_DIAGRAM_CONFIG, _saveEyeDiagramConfig);
  yield takeEvery(RECALC_MEASURE, recalcMeasure)
}

export default sierraResultSaga;