import React, { PureComponent, Fragment } from "react";
import ReactDOM, { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Input, Button, Collapse, Select } from "antd";
import { ADAPT_CSPACE, ADAPT_EQ, JITTER_UNIT, USE_ADAPT_CSPACE, PORT_TX_VPKPK, SEA_SIM_BASIC_OPTIONS, JITTER_OPTIONS, EQUALIZATION_OPTIONS } from "@/services/Andes_v2/seaSim/constants";
import {
  SeaSimAnalysisConfig,
  SeaSimConfig,
  getJitterConfigByUnit,
  getEqualizationConfig,
  seaSimErrorCheck,
  updateSeasimConfigSignals
} from "@/services/Andes_v2/seaSim";
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import BasicOptions from "./basicOptions";
import JitterOptions from './jitterOptions';
import EqualizationOptions from './equalizationOptions';
import ChannelsTable from './channels';
import TagsInput from '@/components/TagsInput';
import { ADAPT_PS, ADAPT_PSDE_PAIRS, BER, INTERFACE_TYPE, ADAPT_DE, USE_ADAPT_TX_PRESET, USE_LEQ_RESPONSE, LEQ_RESPONSE, ADAPT_LEQ_INDEX1, ADAPT_LEQ_INDEX2, LEQ_RESPONSE_ID, LEQ_RESPONSE_INDEX1, LEQ_RESPONSE_INDEX2, TX_DEEMPHASIS, TX_PRE_SHOOT } from "../../../services/Andes_v2/seaSim/constants";
import { numberCheck } from "../../../services/helper/dataProcess";
import { channelSeaSimConfigCheck } from "../../../services/Andes_v2/seaSim";
import { displayReplaceMonitor } from "../../../components/Monitor/SimulationMonitor/MonitorErrorCheck";
import _ from 'lodash';
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';
import libraryConstructor from "../../../services/Andes_v2/library/libraryConstructor";


const BASIC = "Basic", ADVANCED = "Advanced", RESTORE_DEFAULT = "Restore Default";
const Option = Select.Option;
class SeasimConfigPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageOption: BASIC,
      maxWidth: 750,
      maxHeight: 752,
      height: 752,
      simulationErrors: null
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const maxHeight = getPanelMaxHeight(offset, 752);

    let height = this.state.height;
    if (maxHeight < height) {
      height = maxHeight;
    }
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 750),
      maxHeight: maxHeight,
      height: height
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getDefaultConfig();
  }

  componentDidUpdate = (prevProps) => {
    //switch setup , close panel
    if (this.props.id !== prevProps.id) {
      this.props.closePanel();
    }
    if (!_.isEqual(prevProps.signals, this.props.signals)) {
      this.getDefaultConfig();
    }
  }

  getDefaultConfig = () => {
    const { config, signals, components, isEndToEnd } = this.props;
    let _config = config;
    if (!config || !Object.keys(config).length) {
      _config = new SeaSimConfig();
      this.props.updateConfig({
        channels: _config.channels,
        analysisOptions: { ..._config.analysis.options },
        analysisChannels: [..._config.analysis.channels]
      });
    } else if (signals.length && components.length && _config) {
      const { config: newConfig, save } = updateSeasimConfigSignals({ config: _config, signals, isEndToEnd, components });
      if (save) {
        this.props.updateConfig({ channels: newConfig.channels, analysisChannels: newConfig.analysis.channels });
        this.props.saveConfig();
      }
    }
    this.setState({
      pageOption: BASIC
    })
  }

  closeModal = (id) => {
    if (this.state.error) {
      return;
    }

    this.props.saveConfig(id);
    this.props.closePanel();
  }

  selectTypeChange = (key) => {
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let _analysisOptions = { ...analysisOptions };
    _analysisOptions = new SeaSimAnalysisConfig(key);
    this.props.updateConfig({ analysisOptions: _analysisOptions });
    this.getSimulationErrors();
    this.clearError();
  }

  basicInputChange = (e, type, useStatsimOptions) => {
    const value = e.target.value;
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let _analysisOptions = { ...analysisOptions };
    if (useStatsimOptions) {
      _analysisOptions.statsim_options[type] = value
    } else {
      _analysisOptions[type] = value;
    }
    this.props.updateConfig({ analysisOptions: _analysisOptions });
    this.clearError(type);
  }

  advancedInputChange = (e, type) => {
    const value = e.target.value;
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let _analysisOptions = { ...analysisOptions };
    _analysisOptions.statsim_options[type] = value;

    if ([TX_PRE_SHOOT, TX_DEEMPHASIS].includes(type) && value === "") {
      //When there is no data in fields TX_PRE_SHOOT and TX_DEEMPHASIS, delete this field
      delete _analysisOptions.statsim_options[type]
    }

    this.props.updateConfig({ analysisOptions: _analysisOptions });
    this.clearError(type);
  }

  clearError = (type) => {
    const { error } = this.state;
    this.setState({
      error: error && error.type === type ? null : error
    })
  }

  advancedSelectionChange = (value, type) => {
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let _analysisOptions = { ...analysisOptions };
    _analysisOptions.statsim_options[type] = value;

    if (type === JITTER_UNIT) {
      const jitterConfig = getJitterConfigByUnit(analysisOptions.type, value) || {};
      _analysisOptions.statsim_options = {
        ..._analysisOptions.statsim_options,
        ...jitterConfig
      }
    }
    if (type === LEQ_RESPONSE_ID) {
      const list = libraryConstructor.getLibraryValues(LEQ_RESPONSE)
      const findInfo = list.find(item => item.id === value);
      if (findInfo && findInfo.name) {
        _analysisOptions.statsim_options[LEQ_RESPONSE] = findInfo.name
      } else if (!value) {
        _analysisOptions.statsim_options[LEQ_RESPONSE] = "";
        _analysisOptions.statsim_options[type] = "";
      }
    }
    this.props.updateConfig({ analysisOptions: _analysisOptions })
  }

  onBlur = (e, type) => {
    let value = e.target.value;
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    const pcieType = analysisOptions[INTERFACE_TYPE];
    let _analysisOptions = { ...analysisOptions };

    if ([TX_PRE_SHOOT, TX_DEEMPHASIS].includes(type) && value === "") {
      delete _analysisOptions.statsim_options[type]
    } else if (value.replace) {
      //remove spaces of value
      value = value.replace(/\s*/g, "")
      if ([BER, PORT_TX_VPKPK].includes(type)) {
        _analysisOptions[type] = value;
      } else {
        _analysisOptions.statsim_options[type] = value;
      }
    }
    //error check
    const error = seaSimErrorCheck(type, value, { unit: analysisOptions.statsim_options[JITTER_UNIT], pcieType });
    this.props.updateConfig({ analysisOptions: _analysisOptions });
    this.setState({
      error
    }, () => {
      this.getSimulationErrors()
    })
  }

  changePage = (key) => {
    this.setState({
      pageOption: key
    })
  }

  restoreDefault = () => {
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    this.props.updateConfig({ analysisOptions: new SeaSimAnalysisConfig(analysisOptions.type) });
    this.getSimulationErrors();
  }

  simulateClick = () => {
    const { id, isEndToEnd, selectedSignals, config } = this.props;
    if (this.state.error) {
      return;
    }
    const seasimConfigErrors = channelSeaSimConfigCheck(config, selectedSignals, isEndToEnd);
    if (seasimConfigErrors && seasimConfigErrors.length > 0) {
      this.setState({
        simulationErrors: seasimConfigErrors
      });
      //The panel has a scroll bar to scroll to the bottom
      setTimeout(() => {
        const panelEle = ReactDOM.findDOMNode(this.panel);
        const panelEleBody = panelEle ? panelEle.querySelector('.panel-body') : null;
        if (panelEleBody && panelEleBody.scrollHeight) {
          panelEleBody.scrollTop = panelEleBody.scrollHeight;
        }
      }, 240);
    } else {
      this.closeModal(id);
    }
  }

  equalizationCheckChange = (e, type) => {
    const checked = e.target.checked;
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let _analysisOptions = { ...analysisOptions };
    _analysisOptions.statsim_options[type] = checked;

    if (type === ADAPT_PSDE_PAIRS && !checked) {
      //If Treat PS/DE lists as pairs is set to false, ADAPT is not allowed_ DE and ADAPT_ PS has duplicate data, clear it.
      let adaptDeValue = _analysisOptions.statsim_options[ADAPT_DE]
      let adaptPsValue = _analysisOptions.statsim_options[ADAPT_PS]
      if (adaptDeValue && adaptDeValue.length) {
        _analysisOptions.statsim_options[ADAPT_DE] = [...new Set([...adaptDeValue])]
      }
      if (adaptPsValue && adaptPsValue.length) {
        _analysisOptions.statsim_options[ADAPT_PS] = [...new Set([...adaptPsValue])]
      }
    }

    if (type === USE_LEQ_RESPONSE) {
      // After shutdown, data will be restored to default values
      _analysisOptions.statsim_options[LEQ_RESPONSE] = "";
      _analysisOptions.statsim_options[LEQ_RESPONSE_ID] = "";
      if (_analysisOptions.statsim_options[ADAPT_EQ]) {
        _analysisOptions.statsim_options[ADAPT_LEQ_INDEX1] = [0];
        _analysisOptions.statsim_options[ADAPT_LEQ_INDEX2] = [0];
      } else {
        _analysisOptions.statsim_options[LEQ_RESPONSE_INDEX1] = 0;
        _analysisOptions.statsim_options[LEQ_RESPONSE_INDEX2] = 0;
      }
    }

    if (type === ADAPT_EQ) {
      _analysisOptions.statsim_options = getEqualizationConfig(analysisOptions.type, checked, _analysisOptions.statsim_options) || {};
      this.clearError();
    }

    this.props.updateConfig({ analysisOptions: _analysisOptions })
  }

  saveChannels = (channels) => {
    this.props.updateConfig({ channels });
    this.getSimulationErrors()
  }

  saveAnalysisChannels = (analysisChannels) => {
    this.props.updateConfig({ analysisChannels });
    this.getSimulationErrors()
  }

  changeTagList = (type, list, focus) => {
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let _analysisOptions = { ...analysisOptions };
    _analysisOptions.statsim_options[type] = list;

    this.props.updateConfig({ analysisOptions: _analysisOptions })
    if (focus) {
      this.clearError(type);
      this.getSimulationErrors()
    } else {
      this.tagInputBlur(type);
    }
  }

  tagInputBlur = (type) => {
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let valueList = analysisOptions.statsim_options[type];
    const pcieType = analysisOptions[INTERFACE_TYPE];
    if (Array.isArray(valueList)) {
      //remove spaces and filter NaN
      valueList = valueList.map(item => {
        if (item.replace) {
          return item.replace(/\s*/g, "");
        }
        return item;
      })
      valueList = valueList.filter(item => !numberCheck(item));
      analysisOptions.statsim_options[type] = valueList;
    }
    //error check
    const error = seaSimErrorCheck(type, valueList, { unit: analysisOptions.statsim_options[JITTER_UNIT], pcieType });
    this.props.updateConfig({ analysisOptions });
    this.setState({
      error,
    }, () => {
      this.getSimulationErrors();
    })
  }

  advancedRadioChange = (e) => {
    const value = e.target.value;
    const { config: { analysis: { options: analysisOptions } } } = this.props;
    let _analysisOptions = { ...analysisOptions };

    _analysisOptions.statsim_options[USE_ADAPT_TX_PRESET] = value === USE_ADAPT_TX_PRESET ? true : false;
    _analysisOptions.statsim_options[USE_ADAPT_CSPACE] = value === ADAPT_CSPACE ? true : false;
    this.props.updateConfig({ analysisOptions: _analysisOptions });
    this.getSimulationErrors();
  }

  getPanelHeight = (height) => {
    this.setState({ height });
  }

  getSimulationErrors = () => {
    const { simulationErrors } = this.state;
    if (!simulationErrors || !simulationErrors.length) {
      return null;
    }
    const { isEndToEnd, selectedSignals, config } = this.props;
    this.setState({
      simulationErrors: channelSeaSimConfigCheck(config, selectedSignals, isEndToEnd)
    });
  }

  render() {
    const { components, signals, selectedSignals, isEndToEnd, config } = this.props;
    const { maxWidth, maxHeight, pageOption, error, height, simulationErrors } = this.state;
    const title = isEndToEnd ? "Multi-PCB Channel Compliance Simulation" : "Channel Compliance Simulation";
    const { channels, analysis: { channels: analysisChannels, options: analysisOptions } } = config || {};
    const _error = simulationErrors && simulationErrors.length > 0 ? null : error;
    const content = (
      <Panel
        className='andes-v2-channel-config-panel panel-x-scroll-hidden'
        title={<div className='andes-v2-channel-config-title'>{title}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 750 })}
        position='panel-center'
        draggable
        maxHeight={maxHeight}
        overflow="auto"
        footer={this.configOptions(pageOption)}
        ref={(ref) => this.panel = ref}
        getPanelHeight={this.getPanelHeight}
      >
        <div className='channel-config-main' id="channel-config-main-id">
          <div className='channel-config-content'>
            {pageOption === BASIC ?
              <Fragment>
                <div className='channel-config-basic-content'>
                  <BasicOptions
                    analysisOptions={analysisOptions}
                    selectTypeChange={this.selectTypeChange}
                    inputChange={this.basicInputChange}
                    inputComponent={this.inputComponent}
                  />
                  {_error && SEA_SIM_BASIC_OPTIONS.includes(_error.type) ? <span className="aurora-model-name-error-msg">{_error.errorMsg}</span> : null}
                </div>
                <ChannelsTable
                  channels={channels}
                  analysisChannels={analysisChannels}
                  components={components}
                  isEndToEnd={isEndToEnd}
                  selectedSignals={selectedSignals}
                  signals={signals}
                  saveChannels={this.saveChannels}
                  saveAnalysisChannels={this.saveAnalysisChannels}
                  maxHeight={height > 752 ? 400 : height - 292}
                />
              </Fragment> : null}
            {pageOption === ADVANCED ?
              <div>
                <div className='channel-config-jitters-content'>
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["Jitters"]}
                    className='channel-advanced-collapse-content'
                    items={[{
                      key: "Jitters",
                      label: "Jitters",
                      children: <Fragment>
                        <JitterOptions
                          analysisOptions={analysisOptions}
                          selectChange={this.advancedSelectionChange}
                          inputChange={this.advancedInputChange}
                          inputComponent={this.inputComponent}
                        />
                        {_error && JITTER_OPTIONS.includes(_error.type) ? <span className="aurora-model-name-error-msg">{_error.errorMsg}</span> : null}
                      </Fragment>
                    }]}
                  />
                </div>
                <div className='channel-config-equalization-content'>
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["Equalization"]}
                    className='channel-advanced-collapse-content'
                    items={[{
                      key: "Equalization",
                      label: "Equalization",
                      children: <Fragment>
                        <EqualizationOptions
                          analysisOptions={analysisOptions}
                          checkChange={this.equalizationCheckChange}
                          inputComponent={this.inputComponent}
                          selectChange={this.advancedSelectionChange}
                          inputChange={this.advancedInputChange}
                          radioChange={this.advancedRadioChange}
                          tagsInputComponent={this.tagsInputComponent}
                          selectComponent={this.selectComponent}
                          LeqOptionList={libraryConstructor.getLibraryValues(LEQ_RESPONSE)}
                        />
                        {_error && EQUALIZATION_OPTIONS.includes(_error.type) ? <span className="aurora-model-name-error-msg">{_error.errorMsg}</span> : null}
                      </Fragment>
                    }]}
                  />
                </div>
              </div>
              : null}
            {simulationErrors && simulationErrors.length ?
              displayReplaceMonitor(simulationErrors, "Errors:", "aurora-error-content andes-compliance-error-content", false)
              : null}
          </div>
        </div>
      </Panel>)
    return createPortal(content, this.dialogRoot);
  }

  configOptions = (pageOption) => {
    return (
      <div className='channel-config-options'>
        <div>{pageOption === ADVANCED ? <div
          className="channel-config-left-options"
          onClick={() => this.changePage(BASIC)}
        >
          <LeftOutlined />
          <span>Basic Settings</span>
        </div> : null}
        </div>
        <div
          className="channel-config-middle-options"
        >
          {!this.props.isEndToEndChildren ? <Button size="small" onClick={() => this.simulateClick()}>
            Simulate
          </Button> : null}
        </div>
        <div>
          {this.getRightButton(pageOption)}
        </div>
      </div>
    );
  }

  getRightButton = (pageOption) => {
    if (pageOption === BASIC) {
      return (
        <div
          className="channel-config-right-options"
          onClick={() => this.changePage(ADVANCED)}
        >
          <span>Advanced Settings</span>
          <RightOutlined />
        </div>
      );
    }
    if (pageOption === ADVANCED) {
      return <div
        className="channel-config-right-options"
        onClick={() => this.restoreDefault()}
      >
        {RESTORE_DEFAULT}
      </div>
    }
  }

  inputComponent = ({
    label,
    value,
    type,
    inputChange,
    className,
    suffix,
    disabled,
    onBlur = null,
    useStatsimOptions
  }) => {
    onBlur = onBlur ? onBlur : this.onBlur;
    if (label === undefined) {
      return <Input
        value={value}
        onChange={(e) => inputChange(e, type)}
        onBlur={(e) => onBlur(e, type)}
        className={`aurora-input ${className}`}
        disabled={disabled}
      />
    }
    return <div className={`channel-config-item ${className}`}>
      <span>{label}</span>
      <Input
        value={value}
        onChange={(e) => inputChange(e, type, useStatsimOptions)}
        onBlur={(e) => onBlur(e, type)}
        placeholder={label}
        className='aurora-input'
        disabled={disabled}
      />
      {suffix ? suffix : null}
    </div>
  }

  tagsInputComponent = ({
    label,
    value,
    type,
    className,
    suffix,
    disabled,
    allowedDuplicates
  }) => {
    const valueList = Array.isArray(value) ? value : [];
    if (label === undefined) {
      return <TagsInput
        tagList={valueList}
        type={type}
        changeTagList={this.changeTagList}
        className={className}
        displayInput={true}
        disabled={disabled}
        tagInputBlur={this.tagInputBlur}
        allowedDuplicates={allowedDuplicates}
      />
    }
    return <div className={`channel-config-item ${className}`}>
      <span>{label}</span>
      <TagsInput
        tagList={valueList}
        type={type}
        changeTagList={this.changeTagList}
        displayInput={true}
        disabled={disabled}
        tagInputBlur={this.tagInputBlur}
        allowedDuplicates={allowedDuplicates}
      />
      {suffix ? suffix : null}
    </div>
  }

  selectComponent = ({
    value,
    type,
    disabled,
    optionList,
    selectChange,
    mode,
    optionType,
    label,
    className,
    allowClear,
    selectClassName
  }) => {
    if (label === undefined) {
      return <Select
        value={value}
        onChange={(e) => selectChange(e, type)}
        disabled={disabled}
        mode={mode}
        showSearch
        className={`aurora-select ${selectClassName}`}
        popupClassName='aurora-select-dropdown'
        allowClear={allowClear}
      >
        {optionList.map(item => {
          const key = optionType === 'file' && item && item.id ? item.id : item;
          const title = optionType === 'file' && item && item.name ? item.name : item;
          return <Option
            key={key}
            value={key}
            title={title}
          >{title}</Option>
        })}
      </Select>
    }

    return <div className={`channel-config-item ${className}`}>
      <span>{label}</span>
      <Select
        value={value}
        onChange={(e) => selectChange(e, type)}
        disabled={disabled}
        mode={mode}
        showSearch
        className={`aurora-select ${selectClassName}`}
        popupClassName='aurora-select-dropdown'
        allowClear={allowClear}
      >
        {optionList.map(item => {
          const key = optionType === 'file' && item && item.id ? item.id : item;
          const title = optionType === 'file' && item && item.name ? item.name : item;
          return <Option
            key={key}
            value={key}
            title={title}
          >{title}</Option>
        })}
      </Select>
    </div>
  }
}



export default SeasimConfigPanel;