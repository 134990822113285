import React, { Component, Fragment } from 'react';
import { Input } from 'antd';
import { calcCurrentPoints } from '../../../../services/Cascade/Impedance';
import { numberCheck } from '../../../../services/helper/dataProcess';
import { CURRENT_TRAPEZOIDAL } from '../../../../services/Cascade/constants';
import _ from "lodash";
import TimeSetting from './TimeSetting';
import Decimal from 'decimal.js';
import '../index.css';

const KeyArr = [
  { title: 'Idle', key: 'idle', unit: 'ns' },
  { title: 'dt1', key: 'dt1', unit: 'ns' },
  { title: 'Burst', key: 'burst', unit: 'ns' },
  { title: 'dt2', key: 'dt2', unit: 'ns' },
  { title: 'I1', key: 'current1', unit: 'A' },
  { title: 'I2', key: 'current2', unit: 'A' }
];

class TrapezoidalValue extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.initState();
  }

  safeDecimal = (value) => {
    const num = Number(value);
    return new Decimal(isNaN(num) || value === "" ? "0" : num);
  }

  initState = () => {
    const { dieCurrent, maxStopTime } = this.props;
    const isCurrentType = dieCurrent && dieCurrent.currentType && dieCurrent.currentType === CURRENT_TRAPEZOIDAL
    this.setState({
      idle: dieCurrent && dieCurrent.idle ? dieCurrent.idle : null,
      dt1: dieCurrent && dieCurrent.dt1 ? dieCurrent.dt1 : null,
      burst: dieCurrent && dieCurrent.burst ? dieCurrent.burst : null,
      dt2: dieCurrent && dieCurrent.dt2 ? dieCurrent.dt2 : null,
      current1: dieCurrent && dieCurrent.current1 ? dieCurrent.current1 : null,
      current2: dieCurrent && dieCurrent.current2 ? dieCurrent.current2 : null,
      stepTime: (dieCurrent && dieCurrent.stepTime && isCurrentType) ? dieCurrent.stepTime : '10',
      startTime: (dieCurrent && dieCurrent.startTime && isCurrentType) ? dieCurrent.startTime : '0',
      error: null,
    }, () => {
      const { idle, dt1, burst, dt2 } = this.state
      const calcStopTime = this.safeDecimal(idle).times(2)
        .plus(this.safeDecimal(dt1))
        .plus(this.safeDecimal(burst))
        .plus(this.safeDecimal(dt2));
      const defaultStopTime = calcStopTime > maxStopTime ? calcStopTime : maxStopTime
      this.setState({
        stopTime: (dieCurrent && dieCurrent.stopTime && isCurrentType) ? dieCurrent.stopTime : defaultStopTime.toString()
      })
      this.props.savePoints(this.getCurrentPoints());
    })
  }

  changeValue = (e, type) => {
    const { maxStopTime } = this.props
    this.setState({
      [type]: e.target.value
    }, () => {
      const { idle, dt1, burst, dt2 } = this.state;
      const defaultStopTime = this.safeDecimal(idle).times(2)
        .plus(this.safeDecimal(dt1))
        .plus(this.safeDecimal(burst))
        .plus(this.safeDecimal(dt2));
      if (defaultStopTime > maxStopTime) {
        this.setState({
          stopTime: defaultStopTime.toString()
        })
      }
      this.props.refreshPlot()
    })
  }

  saveCurrent = (closePanel) => {
    const { idle, dt1, burst, dt2, current1, current2, stepTime, stopTime, startTime } = this.state;
    const currentPoints = this.getCurrentPoints('Error');
    if (currentPoints === 'Error') {
      return false;
    }
    const dieCurrent = {
      currentPoints,
      idle,
      dt1,
      burst,
      dt2,
      current1,
      current2,
      currentType: CURRENT_TRAPEZOIDAL,
      stepTime,
      stopTime,
      startTime
    }
    this.props.updateCurrent(dieCurrent, closePanel)
    return true;
  }

  getCurrentPoints = (errorReturn = []) => {
    const { idle, dt1, burst, dt2, current1, current2 } = this.state;
    const currentObj = { Idle: idle, dt1, Burst: burst, dt2, I1: current1, I2: current2 }
    const currentKeys = Object.keys(currentObj)
    const numberCheckKeys = currentKeys.filter(item => numberCheck(currentObj[item]))
    if (numberCheckKeys.length && !_.isEqual(numberCheckKeys.sort(), currentKeys.sort())) {
      if (errorReturn === 'Error') {
        this.setState({
          error: `${numberCheckKeys[0]} must be a number.`
        })
      }
      return errorReturn;
    }
    if (_.isEqual(numberCheckKeys.sort(), currentKeys.sort())) {
      return []
    }
    const currentPoints = calcCurrentPoints({
      idle,
      dt1,
      burst,
      dt2,
      current1,
      current2
    });
    return currentPoints
  }

  changeSettingValue = (e, type) => {
    this.setState({
      [type]: e.target.value,
    })
  }

  render() {
    const { error, stepTime, stopTime, startTime } = this.state;

    return <Fragment>
      <div className='imp-current-pwl cascade-setup-border'>
        {KeyArr.map(item => <div key={item.key} className='imp-current-value-content'>
          <span className="imp-current-value-content-span">{item.title}</span>
          <Input
            key={item.key}
            value={this.state[item.key]}
            onChange={(e) => this.changeValue(e, item.key)}
            addonAfter={<div className='imp-current-after'>{item.unit}</div>}
          />
        </div>)}
      </div>
      {/* <TimeSetting
        stepTime={stepTime}
        stopTime={stopTime}
        startTime={startTime}
        changeSettingValue={this.changeSettingValue}
      /> */}
      {error && <span className='imp-current-name-error-msg'>{error}</span>}
    </Fragment>
  }
}

export default TrapezoidalValue;