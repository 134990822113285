import http from './http';
import apiProcess from './utility';
import React from 'react';
import ReactDOM from 'react-dom';
import DelConfirm from '../../components/DelConfirm';

const localStorage = window.localStorage;

export function setToken(token) {
  localStorage.setItem('token', token);
  updateHttpAuth(token);
}

function updateHttpAuth(token) {
  http.updateAuth(token);
  if(token) {
    expire = parseJwt(token).exp * 1000;
    http.setRequestInterceptor(tokenCheck, (error) => Promise.reject(error));
  }
}

export function setRefreshToken(token) {
  localStorage.setItem('refreshToken', token);
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export function login({ email, password }) {
  return http.post('auth/login', { email, password });
};

let expire = 0;

function tokenCheck(config) {
  if (expire > 0 && expire < Date.now()) {
    let originalConfig = config;
    return refreshToken().then(() => {
      return Promise.resolve(originalConfig);
    })
  } else {
    return config;
  }
}

export function isAuthed() {
  const token = localStorage.getItem('token');
  if (token !== null) {
    let { exp } = parseJwt(token);
    if (exp * 1000 > Date.now()) {
      updateHttpAuth(token);
      return true;
    } else {
      //todo: refresh token
      clearToken();
      return false;
      //return refreshToken();
    }
  }
  return false;
}

export function clearToken() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  updateHttpAuth('');
}

export function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  http.post('/auth/refresh-token', { refreshToken }).then(
    (res) => {
      const { token, refreshToken } = res.data.data;
      setToken(token);
      setRefreshToken(refreshToken);
    }
  )
}

function getUserDetail() {
  return http.get(`/auth/detail`);
}

async function getUserDetailPromise() {
  try {
    return await apiProcess(getUserDetail);
  } catch (error) {
    return null;
  }
}

function showServerError(div, msg) {
  return ReactDOM.render(<DelConfirm deleteConfirm={() => removeError(div)} message={msg} removeCancel={true} />, div);
}

function removeError(div) {
  ReactDOM.unmountComponentAtNode(div);
}

export function getUser() {
  if (isAuthed()) {
    return new Promise(async (resolve) => {
      let timeout = null, count = 0;
      let res = await getUserDetailPromise();
      if (!res) {
        const div = document.createElement('div');
        showServerError(div, 'Waiting for the server response...')
        const callUserDetail = async () => {
          count++;
          if (count === 60) {
            clearInterval(timeout);
            resolve({});
          }
          res = await getUserDetailPromise();
          if (res) {
            removeError(div)
            clearInterval(timeout);
            resolve(res);
          }
        }
        timeout = setInterval(async () => {
          await callUserDetail()
        }, 10000)
      } else {
        resolve(res)
      }
    })
  } else {
    return new Promise(resolve => resolve({}));
  }
}

export function getVersion() {
  return http.get('/auth/version');
}

/**
 * update time Zone
 * 2020/05/15
 * @param timeZone   eg: "+8:00"
 * @export
 * @returns XMLHttpRequest
 */

export function updateTimeZone(timeZone) {
  return http.put(`/admin/users/timeZone?timeZone=${timeZone}`);
}

export function getDescription() {
  return http.get('/description')
}

export function updatePassword(userPasswordParam) {
  return http.post('/auth/updatePassword', userPasswordParam)
}