import React, { Fragment, Component } from "react";
import { Select } from "antd";
import {
  JITTER_UNIT,
  SECOND,
  UI,
  STATSIM_OPTIONS,
  JIT_PWRJ,
  JIT_PWDDJ,
  JIT_HFRJ_NUI,
  NUI,
  JIT_LFRJ,
  JIT_LFDDJ,
  SQRT,
  JIT_HFRJ_GROWTH,
  LINEAR,
  CONST
} from "@/services/Andes_v2/seaSim/constants";

const Option = Select.Option;

class JitterOptions extends Component {
  render = () => {
    const {
      analysisOptions,
      selectChange,
      inputChange,
      inputComponent
    } = this.props;
    return <Fragment>
      <div className='channel-config-item'>
        <span>Jitter Unit (jit_seconds)</span>
        <Select
          value={analysisOptions[STATSIM_OPTIONS][JITTER_UNIT]}
          showSearch
          onChange={(key) => selectChange(key, JITTER_UNIT)}
          placeholder={"Jitter Unit"}
          className='aurora-select'
          popupClassName='aurora-select-dropdown'
        >
          {[SECOND, UI].map(item =>
            <Option key={item}>{item}</Option>
          )}
        </Select>
      </div>
      {inputComponent({
        type: JIT_PWRJ,
        value: analysisOptions[STATSIM_OPTIONS][JIT_PWRJ],
        label: "Pulse Width Random Jitter (jit_pwrj)",
        inputChange
      })}
      {inputComponent({
        type: JIT_PWDDJ,
        value: analysisOptions[STATSIM_OPTIONS][JIT_PWDDJ],
        label: "Pulse Width Dual-Dirac Jitter (jit_pwddj)",
        inputChange
      })}
      {inputComponent({
        type: JIT_HFRJ_NUI,
        value: analysisOptions[STATSIM_OPTIONS][JIT_HFRJ_NUI],
        label: "Edge RJ Nui earlier than cursor (jit_hfrj_nui)",
        inputChange
      })}
      <div className='channel-config-item'>
        <span>Edge RJ growth before cursor (jit_hfrj_growth)</span>
        <Select
          value={analysisOptions[STATSIM_OPTIONS][JIT_HFRJ_GROWTH]}
          showSearch
          onChange={(key) => selectChange(key, JIT_HFRJ_GROWTH)}
          placeholder={"Edge RJ growth before cursor"}
          className='aurora-select'
          popupClassName='aurora-select-dropdown'
        >
          {[CONST, SQRT, LINEAR].map(item =>
            <Option key={item}>{item}</Option>
          )}
        </Select>
      </div>
      {inputComponent({
        type: JIT_LFRJ,
        value: analysisOptions[STATSIM_OPTIONS][JIT_LFRJ],
        label: "Low Freq. Random Jitter (jit_lfrj)",
        inputChange
      })}
      {inputComponent({
        type: JIT_LFDDJ,
        value: analysisOptions[STATSIM_OPTIONS][JIT_LFDDJ],
        label: "Low Freq. Dual-Dirac Jitter (jit_lfddj)",
        inputChange
      })}
    </Fragment>
  }
}

export default JitterOptions;