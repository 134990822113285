import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Select, Input, Checkbox, Descriptions, Spin } from 'antd';
import Panel from '@/components/Panel';
import {
  parseLibraryConfig,
  LIBRARY_UNITS,
  UNIT,
  configUnitKeys
} from '@/services/PreLayout/PreLayoutLibrary';
import { Capacitor, CAPACITOR, Inductor, Resistor, RESISTOR, INDUCTOR, numSplit } from '@/services/PreLayout';
import { TRACE, VIA, SINGLE } from '../../../constants/libraryConstants';
import { numberCheck } from '@/services/helper/dataProcess';
import UnitAddonAfter from '@/components/UnitAddonAfter';
import { unitChange } from '@/services/helper/mathHelper';
import { RLC_UNITS, RLC_PLACEHOLDER } from '../../../services/helper/getRLCInputKey';
import { closeCountTime, countTime } from '@/services/helper/hasOperate';
import _ from 'lodash';
import "../../../publicCss/style.css";
import "../../../publicCss/preLayout.css";
import './index.css';

const Option = Select.Option;
const templateTypeList = [{
  key: TRACE,
  label: "Trace"
}, {
  key: VIA,
  label: "Via"
}, {
  key: CAPACITOR,
  label: Capacitor
}, {
  key: RESISTOR,
  label: Resistor
}, {
  key: INDUCTOR,
  label: Inductor
}];
const onlyTemplateList = [{
  key: TRACE,
  label: "Trace"
}, {
  key: VIA,
  label: "Via"
}]
const RLCList = [CAPACITOR, INDUCTOR, RESISTOR];
const Item = Descriptions.Item;
class LibrarySelectPanel extends Component {

  constructor(props) {
    super(props);
    const { record, dataIndex, lengthUnit } = this.props;
    const model = this.getModel();
    // model -> { type, libType, libId, template }
    const templateType = this.getTemplateType(model.type);
    const isLength = !RLCList.includes(templateType.key);
    let value, unit;
    if (isLength) {
      value = record[dataIndex] ? record[dataIndex].length : '';
      unit = lengthUnit;
    } else {
      let data = record[dataIndex].value ? numSplit(record[dataIndex].value) : '';
      value = data.value;
      unit = data.unit;
      if (!unit) {
        unit = this.getDefaultUnit(templateType.key);
      }
    }
    this.state = {
      templateType: { ...templateType },
      model: { ...model },
      configList: [],
      templateList: this.getTemplateList(model.type),
      value,
      unit,
      isLength,
      changeAll: false,
      changeByte: false,
      error: '',
      config: {},
      shouldSave: false,
      loading: false,
      templateVersion: "",
      unitLoading: false,
    }

    this.dialogRoot = document.getElementById('root');
  }

  getModel = () => {
    const { record, dataIndex } = this.props;
    let recordModel = {};
    if (record[dataIndex]) {
      const { type, libType, libId, template } = record[dataIndex];
      recordModel = { type, libType, libId, template };
    } else {
      recordModel = { libId: "", libType: "", template: "", type: "" };
    }
    return [CAPACITOR, RESISTOR, INDUCTOR].includes(recordModel.type) ? this.getRLCTemModel(recordModel) : recordModel;
  }

  getTemplateType = (type) => {
    if (!type) {
      return { key: "", label: "" }
    }

    const [firstWord, ...words] = type.split("");
    return {
      key: type,
      label: `${firstWord[0].toUpperCase()}${words.join('')}` //Trace / Via / Capacitor / Resistor / Inductor
    }
  }

  getRLCTemModel = (model) => {
    return {
      type: model.type
    }
  }

  closeModal = async () => {
    const { record, dataIndex, type, copy } = this.props;
    const { model, value, changeAll, isLength, unit, config, shouldSave, templateVersion, changeByte } = this.state;
    if (copy) {
      const errorMsg = value ? numberCheck(value) : '';
      if (errorMsg) {
        const error = errorMsg.replace('value', isLength ? 'Length' : 'Value');
        this.setState({
          error
        })
        return;
      }
    }
    closeCountTime();
    const _value = value ? isLength ? value : unit !== "Ω" ? `${value}${unit}` : value : '';
    this.props.selectTemplate({ record, model, dataIndex, type, _value, change: changeAll, changeByte });
    if (shouldSave) {
      this.setState({ loading: true });
      const newConfig = model.libType === VIA ? config : await this.calculate(config);
      this.props.saveLibraryConfigToServer({ model, config: newConfig, templateVersion });
    }
    this.setState({ loading: false });
    this.props.save();
  }

  componentDidMount = () => {
    const { record, dataIndex } = this.props;
    if (record[dataIndex]) {
      const { type, libType, libId, template } = record[dataIndex];
      const model = {
        type,
        libType,
        libId,
        template
      };
      if (model && ![CAPACITOR, RESISTOR, INDUCTOR].includes(model.type) && model.libId) {
        this.getData(model);
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { record: prevRecord, dataIndex: prevDataIndex, openedSection: prevOpenSection } = prevProps;

    const model = this.getModel();
    const prevModel = prevRecord[prevDataIndex] ? prevRecord[prevDataIndex] : { libId: "" };

    if (model.libId !== prevModel.libId && (!!model.libId || !!prevModel.libId)) {
      this.getData(model);
    }

    if (this.props.openedSection && this.props.openedSection !== prevOpenSection) {
      this.props.save();
    }
  }


  getData = ({ libId, type, libType }) => {
    const { libraryConstructor, product } = this.props;
    closeCountTime();
    libraryConstructor.getLibraryConfig(libId, type).then(res => {
      const configList = libType === VIA ? parseLibraryConfig(res, libType, product) : [{ title: 'Trace Type', value: libType, modify: false }, ...parseLibraryConfig(res, libType, product)];
      let config = {}
      configList.forEach(conf => {
        if (conf.key) {
          config[conf.key] = conf.value
          if (conf.unit) {
            config.Unit = conf.unit;
          }
        }
      })
      this.setState({
        configList: configList || [],
        config,
        templateVersion: res.version,
        prevCalcConfig: { ...config }
      })
    })
  }

  changeConfig = (e, key) => {
    const { configList } = this.state;
    const newConfig = [...configList];
    const value = e.target.value;
    const item = newConfig.find(conf => conf.key === key);
    if (item) {
      item.value = value;
    }
    this.setState({
      configList: newConfig
    })
  }

  saveConfig = (e, key) => {
    const { configList, config } = this.state;
    const value = e.target.value
    const errorMsg = numberCheck(value);
    if (errorMsg) {
      const error = errorMsg.replace('value', configList.find(item => item.key === key).title);
      this.setState({
        error
      })
      return;
    } else {
      this.setState({
        error: null
      })
    }
    let newConfig = { ...config };
    newConfig[key] = value;
    this.setState({
      config: newConfig,
      shouldSave: true
    }, () => {
      this.waitForCaculate(newConfig)
    })
  }

  waitForCaculate = (newConfig) => {
    countTime(() => this.calculate(newConfig), 2000);
  }

  calculate = (config) => {
    this.setState({
      unitLoading: true
    })
    const { model, configList, prevCalcConfig } = this.state;
    let newConfig = { ...config }, newConfigList = [...configList];
    const isSingle = newConfig.type === SINGLE ? true : false;
    newConfig.Spacing = isSingle ? String(Number(newConfig.Width) * 2) : newConfig.Spacing;
    newConfig.type = model.libType;
    if (_.isEqual((prevCalcConfig || {}), { ...config })
    ) {
      this.setState({
        unitLoading: false
      })
      return config;
    }
    return new Promise((resolve, reject) => {
      this.props.calculateImpedance(newConfig).then(res => {
        for (let key in res) {
          const conf = newConfigList.find(item => item.key === key);
          if (conf) {
            conf.value = res[key];
          }
          if (newConfig[key]) {
            newConfig[key] = res[key];
          }
        }
        if (isSingle) {
          delete newConfig.Spacing;
        }
        this.setState({
          configList: newConfigList,
          config: { ...newConfig, type: config.type },
          prevCalcConfig: { ...newConfig, type: config.type },
          unitLoading: false
        }, () => {
          resolve({ ...newConfig, type: config.type });
        })
      }).catch(err => {
        this.setState({
          unitLoading: false
        })
        reject(err);
      })
    })

  }

  changeTemplate = ({ value, label }) => {
    const { templateType, templateList } = this.state;
    const findLib = templateList.find(it => it.id === value);
    if (!findLib) {
      return;
    }

    this.setState({
      model: {
        libId: value,
        template: label,
        libType: findLib.type,
        type: templateType.key
      }
    }, () => {
      this.getData(this.state.model);
    })
  }

  getTemplateList = (type) => {
    const { libraryList } = this.props;
    const findGroup = libraryList.find(item => item.type === type);
    return findGroup ? findGroup.children : [];
  }

  changeTemplateType = ({ value, label }) => {
    let templateList = [], model = { type: value };
    let isLength = false;
    if ([TRACE, VIA].includes(value)) {
      templateList = this.getTemplateList(value);
      model = { type: value, libType: "", libId: "", template: "" };
      isLength = true;
    }
    const unit = isLength ? this.props.lengthUnit : this.getDefaultUnit(value);
    this.setState({
      templateType: { key: value, label },
      templateList,
      model,
      configList: [],
      isLength,
      unit
    });
  }

  selectAfter = () => {
    const { unit, templateType } = this.state;
    const units = RLC_UNITS[templateType.key.slice(0, 3).toUpperCase()];
    return UnitAddonAfter({ unit, changeUnit: this.changeUnit, list: units })
  }

  configUnitAfter = (unit, modify, unitLoading) => {
    return UnitAddonAfter({ unit, changeUnit: this.changeConfigUnit, list: LIBRARY_UNITS, disabled: (!modify || unitLoading) })
  }

  changeConfigUnit = (key) => {
    const { configList, config } = this.state;
    let _configList = [...configList];
    let _config = { ...config };
    const oldUnit = _config[UNIT];
    for (let item of _configList) {
      if (configUnitKeys.includes(item.key)) {
        const num = item.value;
        const _num = unitChange({ num, oldUnit, newUnit: key, decimals: 6 }).number.toString();
        item.unit = key;
        item.value = _num;
        if (_config[item.key]) {
          _config[item.key] = _num;
        }
      }
    }
    _config[UNIT] = key;
    this.setState({
      configList: _configList,
      config: _config,
      shouldSave: true
    }, () => {
      this.calculate(_config);
    })
  }

  changeUnit = (key) => {
    this.setState({
      unit: key
    })
  }

  getDefaultUnit = (templateType) => {
    const units = RLC_UNITS[templateType.slice(0, 3).toUpperCase()];
    if ([CAPACITOR, INDUCTOR].includes(templateType)) {
      return units[1].key;
    } else {
      return units[0].key;
    }
  }

  saveValue = (e) => {
    const value = e.target.value;
    this.setState({
      value
    })
  }

  checkboxChange = (e, type) => {
    if (type === 'all') {
      this.setState({
        changeAll: e.target.checked,
        changeByte: false
      })
    } else {
      this.setState({
        changeByte: e.target.checked,
        changeAll: false
      })
    }
  }

  renderDialog = () => {
    const { model, configList, templateType, templateList, isLength, unit, value, changeAll, error, loading, changeByte, unitLoading } = this.state;
    const { copy, inputValue, record, only, sectionName, byteType } = this.props;
    const _templateTypeList = only ? onlyTemplateList : templateTypeList;
    const lengthName = isLength ? 'Length' : 'Value';
    const _title = sectionName ? `${record.name} ${sectionName}` : record.name;
    const content = (
      <Panel
        className='pre-layout-library-select-panel'
        position='panel-center-left'
        title={`${_title} - Section Proprety`}
        zIndex={2000}
        onCancel={this.closeModal}
        width={600}
        draggable
        minHeight={200}
        minWidth={540}
      >
        <Spin spinning={loading} tip="Saving">
          <div className='library-select-content'>
            <div className='library-item'>
              {/* type */}
              <label className='library-edit-value-label'>Template Type</label>
              <Select
                value={templateType}
                labelInValue
                onChange={this.changeTemplateType}
                placeholder={"Template Type"}
                className='aurora-select library-select'
                popupClassName='aurora-select-dropdown'
              >
                {_templateTypeList.map(item =>
                  <Option
                    key={item.key}
                    value={item.key}
                    title={item.label}
                  >{item.label}</Option>)}
              </Select>
            </div>
            {[TRACE, VIA].includes(templateType.key) ? <div className='library-item'>
              {/* type */}
              <label className='library-edit-value-label'>Template</label>
              <Select
                value={{ key: model.libId, label: model.template }}
                labelInValue
                onChange={this.changeTemplate}
                placeholder={"Trace Template"}
                className='aurora-select library-select'
                popupClassName='aurora-select-dropdown'
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {templateList.map(item => <Option
                  key={item.id}
                  value={item.id}
                  title={item.name}
                >{item.name}</Option>)}
              </Select>
            </div> : null}
            {
              inputValue && <Fragment>
                <div className='library-item'>
                  <label className='library-edit-value-label'>{lengthName}</label>
                  <Input
                    value={value || null}
                    addonAfter={isLength ? unit : this.selectAfter()}
                    placeholder={isLength ? 'length' : RLC_PLACEHOLDER[templateType.key.slice(0, 3).toUpperCase()]}
                    onChange={(e) => this.saveValue(e)}
                    className='aurora-input library-input'
                  />
                </div>
                {
                  copy && <div className='pre-layout-input-model-copy library-item'>
                    <Checkbox
                      className='pre-layout-input-model-checkbox'
                      checked={changeAll}
                      onChange={(e) => this.checkboxChange(e, 'all')}
                    >
                      {`Apply ${lengthName.toLowerCase()} to other nets`}
                    </Checkbox>
                  </div>
                }
                {
                  byteType && <div className='pre-layout-input-model-copy library-item'>
                    <Checkbox
                      className='pre-layout-input-model-checkbox'
                      checked={changeByte}
                      onChange={(e) => this.checkboxChange(e, 'byte')}
                    >
                      {`Apply ${lengthName.toLowerCase()} to other nets in the same ${byteType}`}
                    </Checkbox>
                  </div>
                }
              </Fragment>
            }
            {configList && configList.length ?
              <Descriptions bordered column={2} size='small' className='pre-layout-library-des'>
                {configList.map(item =>
                  <Item key={item.title} label={item.title}>
                    {<Input
                      className='aurora-input des-input'
                      value={item.value}
                      addonAfter={item.unit ? this.configUnitAfter(item.unit, item.modify, unitLoading) : null}
                      onChange={(e) => this.changeConfig(e, item.key)}
                      onBlur={(e) => this.saveConfig(e, item.key)}
                      disabled={!item.modify}
                    />}
                  </Item>)}
              </Descriptions>
              : null}
            {error && <div className="pre-layout-library-error">{error}</div>}
          </div>
        </Spin>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { inputRef } = this;
    const { text, record, dataIndex } = this.props;
    const section = record[dataIndex];
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {section && section.libType && <img src={`/lib/imgs/svgImgs/${section.libType}.svg`} alt="" className={`pre-layout-section-img`} />}
          <div id='click-pre-layout-library' className='pre-layout-relative pre-layout-center'>{text}</div>
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default LibrarySelectPanel;