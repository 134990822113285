import React, { Fragment, PureComponent } from "react";
import { Select, Input } from 'antd';
import {
  SURFACE_RATIO,
  NODULE_RADIUS,
  hurayUnitList,
  METAL,
  roughnessHuraySettingList,
  stackupItemErrorCheck,
  CUSTOM,
  updateHurayRoughnessBySetting
} from '../../services/Stackup';
import UnitAddonAfter from "../UnitAddonAfter";
import { newValueUnitSplit, valueUnitSplit } from "../../services/helper/valueUnitSplit";
import { numberCheck } from "../../services/helper/dataProcess";

const Option = Select.Option;
class HurayRoughnessSetup extends PureComponent {

  addonAfter = (unit, type, disabled) => {
    return UnitAddonAfter({
      unit,
      disabled,
      changeUnit: (key) => this.changeUnit(key, type),
      list: hurayUnitList
    })
  }

  changeUnit = (key, type) => {
    const { roughness } = this.props;
    const valueObj = NODULE_RADIUS === type ? newValueUnitSplit(roughness[type]): valueUnitSplit(roughness[type]);
    let _roughness = { ...roughness };
    _roughness[type] = `${valueObj.value}${key}`;
    this.props.updateRoughness(_roughness);
  }

  changeValue = (e, type) => {
    const value = e.target.value;
    const { errorMsg, roughness } = this.props;
    let _roughness = { ...roughness };
    if (NODULE_RADIUS === type) {
      const noduleRadiusObj = newValueUnitSplit(roughness.nodule_radius);
      _roughness[type] = `${value}${noduleRadiusObj.unit}`;
    } else {
      _roughness[type] = value;
    }

    const _errorMsg = errorMsg && errorMsg.type === type ? null : errorMsg;
    this.props.updateRoughness(_roughness);
    this.props.updateErrorMsg(_errorMsg);
  }

  saveValue = (e, type) => {
    let value = e.target.value;
    // Error check
    if (NODULE_RADIUS === type) {
      const { roughness } = this.props;
      const noduleRadiusObj = newValueUnitSplit(roughness.nodule_radius);
      value = `${value}${noduleRadiusObj.unit}`
    }
    const error = stackupItemErrorCheck(type, value, { type: METAL });
    if (error) {
      e.target.focus && e.target.focus();
      this.props.updateErrorMsg({ type, error: error.error });
      return error;
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  changeRoughnessType = (key) => {
    const { roughness } = this.props;
    let _roughness = { ...roughness };
    _roughness.setting = key;
    _roughness = updateHurayRoughnessBySetting(_roughness);
    this.props.updateRoughness(_roughness);
  }

  getDisabled = (roughness) => {
    if (roughness.setting === CUSTOM) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { roughness } = this.props;
    const noduleRadiusObj = newValueUnitSplit(roughness.nodule_radius);
    const disabled = this.getDisabled(roughness);
    return <Fragment>
      <div className='surface-roughness-item surface-roughness-item-tabs'>
        <label className='surface-roughness-edit-value-label'>Setting</label>
        <Select
          value={roughness.setting}
          onChange={this.changeRoughnessType}
          placeholder={"Select Roughness Type"}
          className='aurora-select surface-roughness-select'
          popupClassName='aurora-select-dropdown'
        >
          {roughnessHuraySettingList.map(item =>
            <Option key={item.key}>{item.name}</Option>
          )}
        </Select>
      </div>
      <div className='surface-roughness-item surface-roughness-item-tabs'>
        <label className='surface-roughness-edit-value-label'>Nodule Radius</label>
        <Input
          className='aurora-input surface-roughness-input'
          placeholder='Nodule Radius'
          value={noduleRadiusObj.value}
          disabled={disabled}
          onChange={(e) => this.changeValue(e, NODULE_RADIUS)}
          onBlur={(e) => this.saveValue(e, NODULE_RADIUS)}
          onKeyDown={(e) => this.keyDown(e, NODULE_RADIUS)}
          addonAfter={this.addonAfter(noduleRadiusObj.unit, NODULE_RADIUS, disabled)}
        />
      </div>
      <div className='surface-roughness-item surface-roughness-item-tabs'>
        <label className='surface-roughness-edit-value-label'>Hall-Huray Surface Ratio</label>
        <Input
          className='aurora-input surface-roughness-input'
          placeholder='Hall-Huray Surface Ratio'
          value={roughness.surface_ratio}
          disabled={disabled}
          onChange={(e) => this.changeValue(e, SURFACE_RATIO)}
          onBlur={(e) => this.saveValue(e, SURFACE_RATIO)}
          onKeyDown={(e) => this.keyDown(e, SURFACE_RATIO)}
        />
      </div>
    </Fragment>
  }
}

export default HurayRoughnessSetup;