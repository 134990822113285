import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { Input, Radio, Select, Spin } from 'antd';
import { numberCheck } from '@/services/PDN/library/libraryData';
// import DecapEdit from './decapEdit';
import LibraryUpload from './libraryUpload';
import './pdnPanel.css';

const { Option } = Select;
const RUnit = ['Ω', 'KΩ', 'MΩ'];
const LUnit = ['pH', 'nH', 'uH'];
const CUnit = ['pF', 'nF', 'uF'];
class DecapPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DecapDataList: { key: 'create', R: '', L: '', C: '' },
      RUnit: 'Ω',
      LUnit: 'nH',
      CUnit: 'nF',
      fileList: [],
      name: null,
      errorFileList: [],
      RValue: null,
      LValue: null,
      CValue: null,
    }
    this.dialogRoot = document.getElementById('root');
  }

  selectRAfter = () => {
    return (
      <Select dropdownStyle={{ zIndex: 100000 }} className='unit-select' defaultValue={this.state.RUnit} onChange={(e) => this.changeRUnit(e)}>
        {RUnit.map(item =>
          <Option key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  selectLAfter = () => {
    return (
      <Select dropdownStyle={{ zIndex: 100000 }} className='unit-select' defaultValue={this.state.LUnit} onChange={(e) => this.changeLUnit(e)}>
        {LUnit.map(item =>
          <Option key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  selectCAfter = () => {
    return (
      <Select dropdownStyle={{ zIndex: 100000 }} className='unit-select' defaultValue={this.state.CUnit} onChange={(e) => this.changeCUnit(e)}>
        {CUnit.map(item =>
          <Option key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  changeRUnit = (key) => {
    this.setState({
      RUnit: key
    })
  }

  changeLUnit = (key) => {
    this.setState({
      LUnit: key
    })
  }

  changeCUnit = (key) => {
    this.setState({
      CUnit: key
    })
  }

  saveRValue = (e) => {
    this.props.changeErrorMsg(null);
    this.setState({
      RValue: e.target.value
    })
  }

  saveIValue = (e) => {
    this.props.changeErrorMsg(null);
    this.setState({
      LValue: e.target.value
    })
  }

  saveCValue = (e) => {
    this.props.changeErrorMsg(null);
    this.setState({
      CValue: e.target.value
    })
  }

  saveValue = (e, type) => {
    let value = e.target.value;
    //Check the value format
    let error = numberCheck(value);

    if (error) {

      if (type === 'R') {
        this.props.changeErrorMsg(`Resistance ${error}`);
      } else if (type === 'L') {
        this.props.changeErrorMsg(`Inductance ${error}`);
      } else if (type === 'C') {
        this.props.changeErrorMsg(`Capacitance ${error}`);
      }
    }
  }

  onUploadDecapFile = (fileList, errorFileList) => {
    fileList.forEach(file => {
      file.file.fileName = file.fileName;
    });
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    });
  }

  changeFileName = (name, prevName) => {
    const { fileList } = this.state;
    let _fileList = [...fileList];
    _fileList.forEach(item => {
      if (item.name === prevName) {
        item.name = name;
        item.fileName = `${name}.sp`;
        item.file.fileName = `${name}.sp`;
      }
    });
    this.setState({
      fileList: [..._fileList]
    });
    const names = _fileList.map(item => { return item.name });
    this.props.changeDecapName(names, _fileList);
  }

  changeName = (e) => {
    if (e.target.value) {
      this.setState({
        name: e.target.value
      })
    } else {
      this.setState({
        name: null
      })
    }
  }

  saveName = (e) => {
    const { DecapNames } = this.props;
    const { DecapDataList } = this.state;
    if (e.target.value) {
      const index = DecapNames.findIndex(item => item.name === e.target.value && item.id !== DecapDataList.key);
      if (typeof (index) !== 'boolean' && index > -1) {
        e.target.focus();
        this.props.changeErrorMsg('Decap model name cannot be repeated.');
        this.setState({
          name: null
        });
      } else {
        //Whether the file name is legal.
        const errorWords = e.target.value.match(/[^0-9a-zA-Z_-]+/g);
        if (errorWords && errorWords.length > 0) {
          this.props.changeErrorMsg('Decap name may only contain the following characters: numbers, letters, underscores, minus.');
        } else {
          this.setState({
            name: e.target.value
          })
          this.props.changeErrorMsg('');
        }
      }
    } else {
      e.target.focus();
      this.props.changeErrorMsg('Decap model name not set.');
      this.setState({
        name: null
      })
    }
  }

  componentDidMount() {
    const { libraryData, defaultDecapName } = this.props;
    if (libraryData && Object.keys(libraryData).length > 0) {
      const data = {
        R: libraryData.data.R,
        L: libraryData.data.L,
        C: libraryData.data.C,
        key: libraryData.id
      }
      this.setState({
        DecapDataList: data,
        name: libraryData.name,
        RUnit: libraryData.RUnit,
        LUnit: libraryData.LUnit,
        CUnit: libraryData.CUnit,
        RValue: data.R,
        LValue: data.L,
        CValue: data.C
      })
    } else {
      this.setState({
        name: defaultDecapName
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { libraryData, defaultDecapName } = this.props;
    if ((libraryData && Object.keys(libraryData).length > 0 && prevProps.libraryData && Object.keys(prevProps.libraryData).length > 0 && libraryData.id !== prevProps.libraryData.id)
      || (libraryData && Object.keys(libraryData).length > 0 && (!prevProps.libraryData || Object.keys(prevProps.libraryData).length === 0))) {
      const data = {
        R: libraryData.data.R,
        L: libraryData.data.L,
        C: libraryData.data.C,
        key: libraryData.id
      }
      this.setState({
        DecapDataList: data,
        name: libraryData.name,
        RUnit: libraryData.RUnit,
        LUnit: libraryData.LUnit,
        CUnit: libraryData.CUnit,
        RValue: data.R,
        LValue: data.L,
        CValue: data.C
      })
    } else if (prevProps.libraryData && (!libraryData || Object.keys(libraryData).length === 0)) {
      this.setState({
        name: defaultDecapName,
        DecapDataList: { key: 'create', R: '', L: '', C: '' },
        RUnit: 'Ω',
        LUnit: 'nH',
        CUnit: 'nF',
        fileList: [],
        errorFileList: [],
        RValue: null,
        LValue: null,
        CValue: null
      })
    }
  }

  delDecapFile = (fileList, errorFileList) => {
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    })
    const names = fileList.map(item => { return item.name });
    this.props.changeDecapName(names, fileList);
  }

  closeModal = () => {
    const { closeModal, modelType } = this.props;
    const { DecapDataList, name, RUnit, LUnit, CUnit, errorFileList, RValue, LValue, CValue } = this.state;

    if (errorFileList.length > 0) {
      return;
    } else {
      if (modelType === 'SPICE' || modelType === 'Touchstone') {
        if (!this.libraryUploadChild.getUploadingStatus()) {
          this.props.closeLibraryPanel();
        }
      } else {
        let data = { ...DecapDataList };
        data.R = RValue;
        data.L = LValue;
        data.C = CValue;
        if (!name) { return }
        const errorWords = name.match(/[^0-9a-zA-Z_-]+/g);
        if (errorWords && errorWords.length > 0) {
          this.props.changeErrorMsg('Decap name may only contain the following characters: numbers, letters, underscores, minus.');
          return;
        }
        this.props.changeErrorMsg('')
        if (DecapDataList.key === 'create' && !RValue && !LValue && !CValue) {
          closeModal('decap', { type: modelType, name, DecapDataList: { ...data }, RUnit, LUnit, CUnit });
        } else {
          //Check the Resistance value format
          let error = numberCheck(RValue);

          if (error) {
            this.props.changeErrorMsg(`Resistance ${error}`);
            return;
          }

          //Check the Inductance value format
          error = numberCheck(LValue);

          if (error) {
            this.props.changeErrorMsg(`Inductance ${error}`);
            return;
          }

          //Check the Capacitance value format
          error = numberCheck(CValue);

          if (error) {
            this.props.changeErrorMsg(`Capacitance ${error}`);
            return;
          }
          closeModal('decap', { type: modelType, name, DecapDataList: { ...data }, RUnit, LUnit, CUnit });
        }
      }
    }
  }

  onRef = (ref) => {
    this.libraryUploadChild = ref;
  }

  render() {
    const { title, errorMsg, closeLibraryPanel, modelType } = this.props;
    const { name, RValue, LValue, CValue } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='decap-panel'
          position='panel-center-left'
          title={`${title} ${modelType}`}
          zIndex={2000}
          onCancel={this.closeModal}
          width={400}
          draggable
          minHeight={200}
        >
          <Spin tip="Uploading..." spinning={this.props.loading}>
            {modelType === 'SPICE' &&
              <LibraryUpload
                uploadTitle='Import Netlist'
                accept='.sp'
                importText='Netlist'
                modelType={modelType}
                closeLibraryPanel={closeLibraryPanel}
                onRef={this.onRef}
              />}
            {modelType === 'RLC' && <div className='decap-rlc-box'>
              <Input className='model-input' placeholder='Name' value={name} onChange={(e) => this.changeName(e)} onBlur={(e) => this.saveName(e)} />
              <Input className='rlc-input' value={RValue} addonAfter={this.selectRAfter()} placeholder='Resistance' onChange={(e) => this.saveRValue(e)} onBlur={(e) => this.saveValue(e, 'R')} />
              <Input className='rlc-input' value={LValue} addonAfter={this.selectLAfter()} placeholder='Inductance' onChange={(e) => this.saveIValue(e)} onBlur={(e) => this.saveValue(e, 'L')} />
              <Input className='rlc-input' value={CValue} addonAfter={this.selectCAfter()} placeholder='Capacitance' onChange={(e) => this.saveCValue(e)} onBlur={(e) => this.saveValue(e, 'C')} />
              {errorMsg && <span className='model-name-error-msg'>{errorMsg}</span>}
            </div>}
            {modelType === 'Touchstone' &&
              <LibraryUpload
                uploadTitle='Import TouchStone'
                special='sNpFolder'
                accept="*"
                importText='Touchstone'
                modelType={modelType}
                closeLibraryPanel={closeLibraryPanel}
                onRef={this.onRef}
              />
            }
          </Spin>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const mapState = (state) => {
  const { PDNReducer: { project } } = state;
  return {
    DecapNames: project.DecapNames
  }
}

export default connect(mapState, null)(DecapPanel);