import React, { Component, createRef, Fragment } from 'react'
import { CloseOutlined, FullscreenExitOutlined, FullscreenOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import ScaleableBox from './ScalableBox';
import './index.css';

const start = [];
const centerClassNames = ['panel-center', 'panel-center-top', 'panel-center-left', "panel-center-top-40"];
class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDragging: false,
      isChangeing: false,
      left: 0,
      top: 0,
      width: null,
      height: null,
      headerHeight: null,
      footerHeight: null,
      marginLeft: null,
      defaultLeft: 0,
      defaultTop: 0,
      mirrorLeft: true,
      mirrorTop: true,
      maximize: false,
      screenHeight: 1000
    }
    this.panelRef = createRef();
  }

  componentWillMount() {
    const { width, footerHeight, position, left, top, height, footer, headerHeight, minWidth, minHeight, createWH, maxWidth, noHeader } = this.props;
    // Determine whether to use the minimum width directly according to minWidth and createWH
    let setWidth = !width ? (minWidth && createWH ? minWidth : '1000px') : width;
    // Determine whether to use the minimum height directly according to minHeight and createWH
    let setHeight = !height ? (minHeight && createWH ? minHeight : null) : height;
    let setFooterHeight = footer ? (!footerHeight ? 50 : footerHeight) : null;
    let setHeaderHeight = noHeader ? 1 : !headerHeight ? 43 : headerHeight;
    let setLeft = !left ? 0 : left;
    let setTop = !top ? 0 : top;
    let setMirrorLeft = (position === 'panel-top-right') || (position === 'panel-bottom-right') ? false : true;
    let setMirrorTop = (position === 'panel-bottom-left') || (position === 'panel-bottom-right') ? false : true;

    const _width = !isNaN(maxWidth) && maxWidth > 0 && maxWidth < setWidth ? maxWidth : setWidth;
    this.setState({
      height: setHeight,
      width: setWidth,
      defaultLeft: setLeft,
      defaultTop: setTop,
      marginLeft: centerClassNames.includes(position) && - _width / 2,
      footerHeight: setFooterHeight,
      headerHeight: setHeaderHeight,
      mirrorLeft: setMirrorLeft,
      mirrorTop: setMirrorTop
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeScreen);
    if (this.props.panelStateRef) {
      this.props.panelStateRef(this)
    }
    this.getScreenHeight()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.left !== this.props.left) {
      const { left, top } = this.props;
      let setLeft = !left ? 0 : left;
      let setTop = !top ? 0 : top;
      this.setState({
        defaultLeft: setLeft,
        defaultTop: setTop,
      })
    };
    if ((!prevProps.changePosition && this.props.changePosition)
      || (prevProps.changePosition
        && (prevProps.changePosition.width !== this.props.changePosition.width
          || prevProps.changePosition.height !== this.props.changePosition.height)
      )
    ) {
      this.setPosition();
    }

    if (prevProps.width !== this.props.width || prevProps.maxWidth !== this.props.maxWidth) {
      const { width, position, maxWidth } = this.props;
      const _width = !isNaN(maxWidth) && maxWidth > 0 && maxWidth < width ? maxWidth : width;
      this.setState({
        width: width,
        marginLeft: centerClassNames.includes(position) && - _width / 2
      })
    }

    if (prevProps.height !== this.props.height) {
      const { height } = this.props;
      this.setState({
        height: height
      })
    }

    if (prevProps.position !== this.props.position) {
      this.setState({
        left: 0,
        top: 0
      })
    }

    if (prevState.top !== this.state.top) {
      const ele = document.getElementsByClassName('panel-box-position')[0];
      if (ele) {
        const offsetTop = ele.offsetTop;
        if (offsetTop + this.state.top < 0) {
          this.setState({
            top: -offsetTop
          })
        }
      }
    }

    if (this.props.footer && this.props.footer !== prevProps.footer) {
      let setFooterHeight = this.props.footer ? (!this.state.footerHeight ? 50 : this.state.footerHeight) : null;
      this.setState({
        footerHeight: setFooterHeight
      })
    }

    const { width, height } = this.state;
    if (this.props.getPanelHeight && height !== prevState.height) {
      this.props.getPanelHeight(height);
    }

    if (this.props.getPanelWidth && width !== prevState.width) {
      this.props.getPanelWidth(width);
    }
    if (this.props.footerHeight && this.props.footerHeight !== prevProps.footerHeight) {
      this.setState({
        footerHeight: this.props.footerHeight
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeScreen);
  }

  getScreenHeight = () => {
    const { redirectDom, isRedirectDomId } = this.props;
    let _dialogRoot = redirectDom ? redirectDom === 'root' || isRedirectDomId ? document.getElementById(redirectDom || 'root') : document.getElementsByClassName(redirectDom)[0] : document.getElementById('root');
    const { height = 800 } = _dialogRoot.getBoundingClientRect();
    this.setState({
      screenHeight: height * 0.92
    });
  }

  resizeScreen = () => {
    if (this.props.resizeScreen) {
      this.props.resizeScreen();
    }
    this.getScreenHeight()
  }

  setPosition = () => {
    const { width, height } = this.props.changePosition;
    this.setState({
      width,
      height,
      marginLeft: -width / 2
    })
  }

  handleCancel = (e) => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(e);
    }
  }

  handleMinimize = (e) => {
    const { onMinimize } = this.props;
    if (onMinimize) {
      onMinimize(e);
    }
  }

  onDragStart = (e) => {
    e.stopPropagation();
    const { clientX, clientY, dataTransfer } = e;
    const { redirectDom, isRedirectDomId } = this.props;
    const ele = redirectDom && isRedirectDomId ? document.getElementById(redirectDom) : null;
    const { left: _left, top: _top } = ele ? ele.getBoundingClientRect() : {}
    start[0] = clientX;
    start[1] = clientY;
    this.setState((state) => {
      const { left, top, defaultLeft, defaultTop } = state;
      const panel = this.panelRef.current;
      const { offsetLeft, offsetTop } = panel;
      dataTransfer.setDragImage(panel,
        clientX - offsetLeft - left - defaultLeft - (_left || 0),
        clientY - offsetTop - top - defaultTop - (_top || 0));
      return {
        isDragging: true
      }
    })
  }

  onDrag = (e) => {
    e.preventDefault();
    if (this.props.dragEvent) {
      this.props.dragEvent()
    }
  }

  onDragEnd = (e) => {
    e.preventDefault();
    const { left, top } = this.state;
    const { redirectDom, isRedirectDomId } = this.props;
    let { clientX, clientY } = e;
    const { innerWidth, innerHeight } = window;
    const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = this.panelRef.current;
    // Drag range

    const lMin = -offsetLeft, tMin = -offsetTop;

    // Recalculate the movable distance according to whether there is a redirected parent element
    let lMax, tMax;
    if (redirectDom) {
      let redirectDomStyle = redirectDom === 'root' || isRedirectDomId ? document.getElementById(redirectDom || 'root') : document.getElementsByClassName(redirectDom)[0];
      const { width, height } = redirectDomStyle.getBoundingClientRect()
      lMax = width - offsetWidth - offsetLeft;
      tMax = height - offsetHeight - offsetTop;
    } else {
      lMax = innerWidth - offsetWidth - offsetLeft;
      tMax = innerHeight - offsetHeight - offsetTop;
    }

    clientX = clientX < 0 ? 0 : clientX > innerWidth ? innerWidth : clientX;
    clientY = clientY < 0 ? 0 : clientY > innerHeight ? innerHeight : clientY;
    let newLeft = left + clientX - start[0], newTop = top + clientY - start[1];

    if (newLeft <= lMin) {
      newLeft = lMin;
    }

    if (newLeft >= lMax) {
      newLeft = lMax;
    }

    if (newTop <= tMin) {
      newTop = tMin;
    }

    if (newTop >= tMax) {
      newTop = tMax;
    }
    this.setState({
      isDragging: false,
      left: newLeft,
      top: newTop
    }, () => {
      this.resize();
      this.props.updateLeftAndTop && this.props.updateLeftAndTop({ left: newLeft, top: newTop });
    })
  }

  getFooterHeight(panel) {
    const { footerHeight, footer } = this.props;
    if (footer) {
      if (footerHeight && footerHeight > 0) {
        return footerHeight
      }
      const footer = this.props.maximizeVisible ? panel.children[5] : panel.children[4];
      if (footer && footer.getBoundingClientRect) {
        return footer.getBoundingClientRect().height;
      } else if (footer) {
        return footer.offsetHeight;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getHeaderHeight(panel) {
    const header = panel.children[2];
    if (header && header.getBoundingClientRect) {
      return header.getBoundingClientRect().height;
    } else {
      return header.offsetHeight;
    }
  }

  changeStart() {
    this.setState({
      isChangeing: true
    })
  }

  changeEnd() {
    this.resize();
    this.setState({
      isChangeing: false
    }, () => {
      this.props.resizeEnd && this.props.resizeEnd();
    })
  }

  changeSize(i, width, height, footerHeight, headerHeight) {

    this.setState({
      footerHeight,
      headerHeight
    });
    if (i === 0) {
      //Change width 
      setTimeout(() => {
        this.setState({ width });
      }, 50)
    } else if (i === 1) {
      //Change height 
      setTimeout(() => {
        this.setState({ height });
      }, 50)
    } else {
      //Change width and height
      setTimeout(() => {
        this.setState({
          width,
          height
        })
      }, 50);
    }
  }

  resize = () => {
    if (this.props.resize) {
      setTimeout(() => {
        if (this.panelRef.current && this.panelRef.current.getBoundingClientRect) {
          const { width, height, x, y } = this.panelRef.current.getBoundingClientRect();
          this.props.resize({ width, height, x, y });
        }
      }, 50);
    }
  }

  getPanelTop = () => {
    const { top } = this.state;
    return top || 0;
  }

  getPanelLeft = () => {
    const { left } = this.state;
    return left || 0;
  }

  getClassName = (position = 'panel-center', className = '') => {
    return `${position} ${className}`;
  }

  changeSizeMax = (e) => {
    e.stopPropagation();
    const { maximize, height } = this.state;

    this.setState({
      maximize: !maximize,
      height: !maximize ? "unset" : height
    }, () => {
      this.props.changeSizeMax(!maximize)
    });
  }

  /* 
  * position : ------------------------------------------
  *            | panel-top-left    |    panel-top-right |
  *            |----------------------------------------|
  *            |           panel-center (defalt)        |
  *            |----------------------------------------|
  *            | panel-bottom-left | panel-bottom-right |
  *            ------------------------------------------
  * width : panel width
  * height : panel height // TODO
  */


  render() {
    //if (!this.props.visible) return null
    const { footer, title, children, className, draggable, position, zIndex, bodyStyle, mask, id = '',
      defaultLeft, defaultTop, minWidth, minHeight, maxWidth, maxHeight, minimizeVisible, maskStyle, overflow, panelBodyId, maximizeVisible, panelOverflow,
      noHeader } = this.props;
    const { isDragging, isChangeing, left, top, footerHeight, headerHeight, height, width, marginLeft, maximize, screenHeight, display } = this.state;
    const events = draggable ? {
      onDragStart: this.onDragStart,
      onDrag: this.onDrag,
      onDragEnd: this.onDragEnd
    } : {};
    const style = {
      opacity: isDragging ? 0.5 : 1,
      transform: `translate3d(${left}px, ${top}px, 0)`,
    };
    const _height = height && typeof height === 'number' ? height > screenHeight ? screenHeight : height : height,
      _minHeight = minHeight && typeof minHeight === 'number' ? minHeight > screenHeight ? screenHeight : minHeight : minHeight,
      _maxHeight = maxHeight && typeof maxHeight === 'number' ? maxHeight > screenHeight && !maximize ? screenHeight : maxHeight : screenHeight;
    const classNames = this.getClassName(position, className);
    let _style = {
      left: defaultLeft,
      top: defaultTop,
      marginLeft: marginLeft ? marginLeft : null,
      paddingBottom: footer ? footerHeight : null,
      zIndex,
      overflow: panelOverflow ? panelOverflow : "hidden",
      display,
      ...style,
    }
    if (display) {
      _style = { display, ..._style }
    }
    return (
      <Fragment>
        {mask ? <div className={maskStyle ? "panel-ant-modal-mask panel-mask" : "panel-ant-modal-mask"} style={display ? { display } : {}}></div> : null}
        <div
          id={id}
          className={`panel-box-position panel-box-shadow panel-ant-modal-content ${classNames}`}
          style={{
            ..._style
          }}
          ref={this.panelRef}
        >
          <ScaleableBox
            getFooterHeight={() => this.getFooterHeight(this.panelRef.current)}
            getHeaderHeight={() => this.getHeaderHeight(this.panelRef.current)}
            minWidth={minWidth}
            maxWidth={maxWidth}
            minHeight={_minHeight}
            maxHeight={_maxHeight}
            mirrorLeft={this.state.mirrorLeft}
            mirrorTop={this.state.mirrorTop}
            panelWidth={width}
            panelHeight={_height === "unset" ? null : _height}
            changeStart={() => this.changeStart()}
            changeSize={(i, width, height, footerHeight, headerHeight) => this.changeSize(i, width, height, footerHeight, headerHeight)}
            changeEnd={() => this.changeEnd()}
          />
          {!noHeader && <button aria-label="Close" className="panel-ant-modal-close" onClick={this.handleCancel}>
            <span className='panel-ant-modal-close-x panel-modal-close-x'>
              <CloseOutlined />
            </span>
          </button>}
          {minimizeVisible ? <Tooltip title="Minimize"
            overlayClassName='panel-tooltip'
            mouseEnterDelay={0.4}
            mouseLeaveDelay={0.5}
          >
            <button aria-label="minimize" className="panel-modal-minimize-button" onClick={this.handleMinimize}>
              <span className='panel-modal-minimize'>
                <ShrinkOutlined />
              </span>
            </button></Tooltip> : null}

          {maximizeVisible ? <Fragment
          >
            {!maximize ? <FullscreenOutlined
              title="Maximize"
              className='panel-graph-maximize'
              onClick={(e) => { this.changeSizeMax(e) }} />
              : <FullscreenExitOutlined
                title="Minimize"
                className='panel-graph-maximize'
                onClick={(e) => { this.changeSizeMax(e) }} />}
          </Fragment> : null}
          {title ? <div
            className="panel-ant-modal-header panel-modal-header"
            style={{ cursor: draggable ? 'move' : '' }}
            draggable={draggable}
            {...events}
          >
            <div className="panel-ant-modal-title" style={{ maxWidth: width - 50 }}>{title}</div>
          </div> : null}
          <div className="panel-ant-modal-body panel-body"
            id={panelBodyId || ""}
            style={{
              ...bodyStyle,
              height: _height === "unset" ? _height : _height - headerHeight - (!isNaN(Number(footerHeight)) && footer ? footerHeight : 0),
              width,
              maxHeight: _maxHeight ? _maxHeight - headerHeight - (!isNaN(Number(footerHeight)) && footer ? footerHeight : 0) : null,
              maxWidth,
              overflow: overflow ? overflow : (isChangeing ? 'hidden' : 'auto')
            }}
          >
            {children}
          </div>
          {
            footer ? (
              <div className='modal-foot-position' style={{ height: footerHeight }}>
                <div className="panel-ant-modal-footer">
                  {footer}
                </div>
              </div>
            ) : null
          }
        </div >
      </Fragment >
    );
  }
}

export default Panel;