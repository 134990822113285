import React, { PureComponent } from "react";
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import {
  updatePCBChannelSelectList,
  updatePCBChannelSelectChildList,
  getProjectDesigns,
  getDebugMenuList,
  updateDebugMenuList,
  updateEndToEndSelectList,
  getEndToEndDebugDownloadList,
  getDebugCheckValues,
  getEndToEndDebugDownloadOptions,
  getReportDownloadList,
  updateSweepSelectInfo,
  updateSweepSelectChild,
  updateSweepSelectSweep
} from '@/services/Andes_v2';
import DebugMenu from '@/components/DebugDownload/DebugMenu';
import DebugContent from '@/components/DebugDownload/DebugContent';
import DebugFooter from "@/components/DebugDownload/DebugFooter";
import { PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS } from "@/constants/treeConstants";
import {
  SIMULATION_DEBUG,
  getAndesV2DebugDownloadList,
  PCB_PARSING,
  getAndesV2DownloadSimulationUrl,
  getDebugMessage,
  DEBUG_SUCCESS,
  DEBUG_FAILED,
  REPORT
} from '@/services/helper/debugHelper';
import designConstructor from '@/services/helper/designConstructor';
import { getResBlob, getDebugDownloadProgress } from '@/services/helper/downloadHelper';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import '@/publicCss/style.css';
import '@/publicCss/debug.css';
import { isPreLayout } from "../../../../services/Andes_v2";
import { getSweepListByProjectIdPromise } from "../../../../services/Andes_v2/sweep";
import { getDownloadFileId, getDownloadFileIdForPost, getDownloadFileSize, downloadFileByChunk } from "../../../../services/helper/downloadHelper";
import { ANDES_V2 } from "../../../../constants/pageType";

class DebugPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuType: PCB_PARSING,
      debugMenuList: getDebugMenuList(),
      downloadList: [],
      currentCheckValues: [],
      downloadMsgInfo: {},
      downloadProgressInfo: {},
      checkExpandKeys: [],
      maxWidth: 1000,
      maxHeight: 304,
    }
    this.sweepList = new Map();
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 304),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { debugType } = this.props;
    this.getDebugOptions(debugType);

  }

  componentDidUpdate = (prevProps) => {
    const { debugType } = this.props;
    if (debugType !== prevProps.debugType) {
      this.getDebugOptions(debugType);
    }
  }

  getDebugOptions = async (debugType) => {
    const { debugMenuList, menuType } = this.state;
    const { channelId, projectId, endToEndChannelId, sweepId } = this.props;
    let _debugMenuList = [...debugMenuList];
    const sweepList = (await getSweepListByProjectIdPromise(projectId)).channel;
    if (sweepList) {
      for (const item of sweepList) {
        if (item.children && item.children.length > 0) {
          this.sweepList.set(item.id, item.children)
        }
      }
    }
    _debugMenuList = updateDebugMenuList({
      debugMenuList: _debugMenuList,
      debugType,
      channelId,
      projectId,
      sweepId,
      endToEndChannelId,
      sweepList: this.sweepList
    });
    this.setState({
      debugMenuList: _debugMenuList,
      menuType: debugType ? debugType : menuType
    }, () => {
      this.getSimulationDownloadList();
    })
  }

  getVendor = (menuType) => {
    const { debugMenuList } = this.state;
    let designId = null;
    if ([PCB_CHANNEL, PCB_PARSING].includes(menuType)) {
      const data = debugMenuList.find(item => item.key === menuType) || {};
      designId = data.info && data.info.selectInfo ? data.info.selectInfo.id : null;
    }
    const design = designConstructor.getDesign(designId);
    return design ? design.vendor : null;
  }

  getSimulationDownloadList = async () => {
    const { menuType, debugMenuList } = this.state;
    const vendor = this.getVendor(menuType);
    const _menuType = PCB_CHANNEL === menuType ? SIMULATION_DEBUG : menuType;
    let downloadList = [];
    if (_menuType === SIMULATION_DEBUG) {
      const selectInfo = this.getSelectInfoByType(menuType);
      downloadList = getAndesV2DebugDownloadList({ menuType: _menuType, vendor, selectInfo });
    } else if (_menuType === PCB_PARSING) {
      downloadList = getAndesV2DebugDownloadList({ menuType: _menuType, vendor });
    } else if (_menuType === END_TO_END_CHANNEL) {
      downloadList = await getEndToEndDebugDownloadList({ debugMenuList });
    } else if (_menuType === REPORT) {
      downloadList = getReportDownloadList({ debugMenuList });
    } else if (_menuType === EXPERIMENTS) {
      downloadList = [
        { name: 'Log file', default: true, option: 'log' },
        { name: 'Extraction folder', default: true, option: 'extraction' },
        { name: 'The uploaded layout file', default: true, option: "pcb" }
      ]
    }
    let currentCheckValues = [], checkExpandKeys = [];
    for (let item of downloadList) {
      if (item.default) {
        currentCheckValues.push({
          name: item.option,
          children: item.children ? item.children.map(it => it.option) : []
        });
        item.children && checkExpandKeys.push(item.option)
      }
    }
    this.setState({
      downloadList,
      currentCheckValues,
      checkExpandKeys
    });
  }

  changeDebugItem = (e, type) => {
    e && e.stopPropagation();
    this.setState({
      menuType: type
    }, () => {
      this.getSimulationDownloadList();
    })
  }

  changeSelectChild = (value, type) => {
    //pcb channel
    const { debugMenuList } = this.state;
    let _debugMenuList = [...debugMenuList];
    const index = _debugMenuList.findIndex(item => item.key === type);
    if (index < 0) {
      return;
    }
    switch (type) {
      case PCB_CHANNEL:
        _debugMenuList[index].info = updatePCBChannelSelectChildList(_debugMenuList[index].info, value);
        break;
      case EXPERIMENTS:
        _debugMenuList[index].info = updateSweepSelectChild(_debugMenuList[index].info, value, this.sweepList);
        break;
      default: break;
    }
    this.setState({
      debugMenuList: _debugMenuList
    }, () => {
      this.getSimulationDownloadList();
    })
  }

  closeModal = () => {
    this.props.closePanel();
  }

  changeDebugCheckList = (values) => {
    const { currentCheckValues, downloadList } = this.state;
    this.setState({
      currentCheckValues: getDebugCheckValues(values, currentCheckValues, downloadList)
    })
  }

  changeDebugCheckChildren = (values, parent) => {
    const { currentCheckValues } = this.state;
    let _currentCheckValues = [...currentCheckValues];
    const index = currentCheckValues.findIndex(item => item.name === parent);
    if (index < 0) {
      return;
    }
    _currentCheckValues[index].children = [...values];
    this.setState({
      currentCheckValues: _currentCheckValues
    })
  }

  changeSelectInfo = (value, type) => {
    const { projectId } = this.props;
    let _debugMenuList = [...this.state.debugMenuList];
    const index = _debugMenuList.findIndex(item => item.key === type);
    if (index < 0) {
      return;
    }
    switch (type) {
      case PCB_PARSING:
      case REPORT:
        const designs = getProjectDesigns(projectId);
        const design = designs.find(item => item.id === value);
        _debugMenuList[index].info.selectInfo = design ? { id: design.id, name: value } : { id: "", name: "" }
        break;
      case PCB_CHANNEL:
        _debugMenuList[index].info = updatePCBChannelSelectList(_debugMenuList[index].info, value);
        break;
      case END_TO_END_CHANNEL:
        _debugMenuList[index].info = updateEndToEndSelectList(projectId, value, _debugMenuList[index].info);
        break;
      case EXPERIMENTS:
        _debugMenuList[index].info = updateSweepSelectInfo(_debugMenuList[index].info, value, this.sweepList);
        break;
      default: break;
    }
    this.setState({
      debugMenuList: _debugMenuList
    }, () => {
      this.getSimulationDownloadList();
    })
  }

  changeSelectSweep = (value, type) => {
    //pcb channel
    const { debugMenuList } = this.state;
    let _debugMenuList = [...debugMenuList];
    const index = _debugMenuList.findIndex(item => item.key === type);
    if (index < 0) {
      return;
    }
    _debugMenuList[index].info = updateSweepSelectSweep(_debugMenuList[index].info, value);
    this.setState({
      debugMenuList: _debugMenuList
    }, () => {
      this.getSimulationDownloadList();
    })
  }

  expandChange = (e, option) => {
    e.stopPropagation();
    const { checkExpandKeys } = this.state;
    let _checkExpandKeys = [...checkExpandKeys];
    if (_checkExpandKeys.includes(option)) {
      _checkExpandKeys = _checkExpandKeys.filter(item => item !== option);
    } else {
      _checkExpandKeys.push(option);
    }
    this.setState({
      checkExpandKeys: _checkExpandKeys
    })
  }

  render() {
    const { menuType, currentCheckValues, checkExpandKeys,
      downloadList, downloadMsgInfo, downloadProgressInfo,
      maxWidth, maxHeight, debugMenuList
    } = this.state;
    const content = (
      <Panel
        className='aurora-debug-panel panel-x-scroll-hidden'
        title={<div className='aurora-debug-title'>Debug</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 1000 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        overflow={"auto"}
      >
        <div className='aurora-debug-content clear' id="andes-debug-content-id">
          <DebugMenu
            debugMenuList={debugMenuList}
            menuType={menuType}
            changeDebugItem={this.changeDebugItem}
            changeSelectInfo={this.changeSelectInfo}
            changeSelectChild={this.changeSelectChild}
            changeSelectSweep={this.changeSelectSweep}
            product={ANDES_V2}
          />
          <div className='aurora-debug-list-content'>
            <DebugContent
              menuType={menuType}
              currentCheckValues={currentCheckValues}
              checkExpandKeys={checkExpandKeys}
              downloadList={downloadList}
              changeDebugCheckList={this.changeDebugCheckList}
              changeDebugCheckChildren={this.changeDebugCheckChildren}
              expandChange={this.expandChange}
            />
            <DebugFooter
              menuType={menuType}
              debugMenuList={debugMenuList}
              currentCheckValues={currentCheckValues}
              downloadMsgInfo={downloadMsgInfo}
              downloadProgressInfo={downloadProgressInfo}
              DownloadDebugClick={this.DownloadDebugClick}
            />
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }


  DownloadDebugClick = (e) => {
    const { menuType, currentCheckValues, downloadMsgInfo } = this.state;
    const options = currentCheckValues && currentCheckValues.length > 0 ? [...currentCheckValues.map(item => item.name)] : ["empty"];
    const token = localStorage.getItem('token');
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    const targetTime = dayjs(time.toUTCString()).format('YYYYMMDD');
    this.setState({
      downloadMsgInfo: {
        ...downloadMsgInfo,
        [menuType]: null
      }
    })

    switch (menuType) {
      case PCB_PARSING:
        this.downloadPCBParsing(options, token, targetTime);
        break;
      case PCB_CHANNEL:
        this.downloadPCBChannel(options, token, targetTime);
        break;
      case END_TO_END_CHANNEL:
        this.downloadEndToEnd(token, targetTime);
        break;
      case REPORT:
        this.downloadReport(options, token, targetTime);
        break;
      case EXPERIMENTS:
        this.downloadExperiment(options, token, targetTime);
        break;
      default: break;
    }
  }

  getSelectInfoByType = (type) => {
    const { debugMenuList } = this.state;
    let info = debugMenuList.find(item => item.key === type).info;
    if (!info || !info.selectInfo) {
      return;
    }
    return info;
  }

  downloadReport = (options, token, time) => {
    const { menuType } = this.state;
    const info = this.getSelectInfoByType(REPORT);
    const { selectInfo } = info || {};
    if (!selectInfo || !selectInfo.id) {
      return;
    }

    const url = getAndesV2DownloadSimulationUrl({
      menuType,
      channelId: selectInfo.id,
      options,
      token
    });
    const fileName = `${selectInfo.name}_report_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "Report" })
  }

  downloadPCBChannel = (options, token, time) => {
    const { menuType } = this.state;
    const info = this.getSelectInfoByType(PCB_CHANNEL);
    const { selectInfo, selectChild } = info || {};

    if (!selectChild || !selectChild.id) {
      return;
    }
    const isPre = isPreLayout(selectInfo.id);
    if (isPre) {
      options = options.filter(item => item !== "pcb");
    }
    const url = getAndesV2DownloadSimulationUrl({
      menuType,
      channelId: selectChild.id,
      options,
      token
    });
    const fileName = `${selectInfo.name}_${selectChild.name}_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "PCB channel" })
  }

  downloadPCBParsing = (options, token, time) => {
    const { menuType } = this.state;
    const info = this.getSelectInfoByType(PCB_PARSING);
    if (!info || !info.selectInfo || !info.selectInfo.id) {
      return;
    }
    const pcbSelected = info.selectInfo;
    const url = getAndesV2DownloadSimulationUrl({
      menuType,
      designId: pcbSelected.id,
      options,
      token
    });
    const fileName = `${pcbSelected.name}_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "PCB parsing" })
  }

  downloadEndToEnd = (token, time) => {
    const { currentCheckValues } = this.state;
    const info = this.getSelectInfoByType(END_TO_END_CHANNEL);
    if (!info || !info.selectInfo || !info.selectInfo.id) {
      return;
    }
    const endToEnd = info.selectInfo;
    //params: {endToEndChannelId, channels: [ { channelId, options:[] } ], options: [] }
    const params = getEndToEndDebugDownloadOptions(currentCheckValues, endToEnd.id);
    const url = "/api/v3/andes/end-to-end/channel/debug/download";
    const fileName = `${endToEnd.name}_${time}.zip`;
    this.downloadProgress({ url, type: "POST", token, params, fileName, intervalTime: 1500, messageType: "multi-PCB channel" })
  }

  downloadExperiment = (options, token, time) => {
    const { menuType } = this.state;
    const info = this.getSelectInfoByType(EXPERIMENTS);
    const { selectSweep } = info || {};
    const url = getAndesV2DownloadSimulationUrl({ menuType, options, sweepId: selectSweep.id })
    const fileName = `${selectSweep.name}_${time}.zip`;
    this.downloadProgress({ url, fileName, messageType: "Experiment Touchstone", intervalTime: 1000 })
  }

  downloadProgress = ({ url, type, token, params, fileName, messageType, intervalTime = 800 }) => {
    const { menuType, downloadMsgInfo, downloadProgressInfo } = this.state;

    let progress = 0, indexNum = 0;
    this.setState({
      downloadProgressInfo: {
        ...downloadProgressInfo,
        [menuType]: 0
      },
    })
    this[`${menuType}Time`] = setInterval(() => {
      indexNum += 1;
      progress = getDebugDownloadProgress(progress);
      if (indexNum % 3 === 0) {
        this.setState({
          downloadProgressInfo: {
            ...downloadProgressInfo,
            [menuType]: progress
          },
        })
      }
    }, intervalTime);
    if (url) {
      if (menuType === EXPERIMENTS) {
        this.getDownloadFileKey({
          url,
          menuType,
          downloadMsgInfo,
          downloadProgressInfo,
          type,
          params,
          fileName,
          messageType
        })
        return;
      }
      getResBlob(url, { type, params, token }).then(blob => {
        clearInterval(this[`${menuType}Time`]);
        if (blob) {
          FileSaver.saveAs(blob, fileName);
          this.setState({
            downloadProgressInfo: {
              ...downloadProgressInfo,
              [menuType]: 100
            },
            downloadMsgInfo: {
              ...downloadMsgInfo,
              [menuType]: getDebugMessage(DEBUG_SUCCESS, messageType)
            }
          })
        } else {
          this.setState({
            downloadMsgInfo: {
              ...downloadMsgInfo,
              [menuType]: getDebugMessage(DEBUG_FAILED, messageType)
            }
          })
        }
        setTimeout(() => {
          this.setState({
            downloadProgressInfo: {
              ...downloadProgressInfo,
              [menuType]: -1
            }
          })
        }, 1000);
      }, error => {
        this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType, messageType });
      });
    } else {
      this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType, messageType });
    }
  }

  downloadFailed = ({ downloadProgressInfo, downloadMsgInfo, messageType, menuType }) => {
    clearInterval(this[`${menuType}Time`]);
    setTimeout(() => {
      this.setState({
        downloadProgressInfo: {
          ...downloadProgressInfo,
          [menuType]: -1
        },
        downloadMsgInfo: {
          ...downloadMsgInfo,
          [menuType]: getDebugMessage(DEBUG_FAILED, messageType)
        }
      })
    }, 1000);
  }

  getDownloadFileKey = ({
    url,
    menuType,
    downloadMsgInfo,
    downloadProgressInfo,
    type,
    params,
    fileName,
    messageType
  }) => {
    this.setState({
      downloadMsgInfo: {
        ...downloadMsgInfo,
        [menuType]: 'Preparing file...'
      }
    })
    getDownloadFileId(url, params).then(async res => {
      if (res && res.status === "success") {
        let fileSize = 0;
        if (menuType === EXPERIMENTS) {
          try {
            const fileSizeInfo = await getDownloadFileSize(res.key);
            fileSize = fileSizeInfo.size;
          } catch (error) {
            fileSize = 0;
          }
        }
        if (fileSize > 1024 * 1024 * 1024) {
          downloadFileByChunk({
            fileSize,
            downloadKey: res.key,
            fileName,
            downloadMsgInfo,
            menuType,
            downloadProgressInfo,
            updateDownloadProgress: this.updateDownloadProgress,
            clearProgressInterval: this.clearProgressInterval
          })
        } else {
          clearInterval(this[`${menuType}Time`]);
          const a = document.createElement('a');
          // a.download = fileName;
          const token = localStorage.getItem('token');
          a.href = `api/v3/download/file?downloadKey=${res.key}&access_token=${token}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({
            downloadProgressInfo: {
              ...downloadProgressInfo,
              [menuType]: 100
            },
            downloadMsgInfo: {
              ...downloadMsgInfo,
              [menuType]: 'Prepared file successfully and waiting for download!'
            }
          })
          setTimeout(() => {
            this.setState({
              downloadMsgInfo: {
                ...downloadMsgInfo,
                [menuType]: getDebugMessage(DEBUG_SUCCESS, messageType)
              }
            })
          }, 8000);
          setTimeout(() => {
            this.setState({
              downloadProgressInfo: {
                ...downloadProgressInfo,
                [menuType]: -1
              }
            })
          }, 2000);
        }
      } else {
        this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType, message: res.error || "", timeout: 0 });
      }

    }, error => {
      this.downloadFailed({ downloadProgressInfo, downloadMsgInfo, menuType, message: error, timeout: 0 });
    })
  }

  updateDownloadProgress = ({ downloadProgressInfo, downloadMsgInfo, menuType, progress, message }) => {
    if (downloadProgressInfo) {
      this.setState({
        downloadProgressInfo: {
          ...downloadProgressInfo,
          [menuType]: progress !== undefined ? progress : downloadProgressInfo[menuType]
        }
      })
    }

    if (downloadMsgInfo) {
      this.setState({
        downloadMsgInfo: {
          ...downloadMsgInfo,
          [menuType]: message !== undefined ? message : downloadMsgInfo[menuType]
        }
      })
    }
  }

  clearProgressInterval = (menuType) => {
    clearInterval(this[`${menuType}Time`]);
  }
}

export default DebugPanel;