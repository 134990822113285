import { call, put, takeEvery, delay, select, takeLatest, fork, cancel, take } from 'redux-saga/effects';
import { message } from 'antd';
import {
  GET_POWER_TREE_CONTENT,
  UPDATE_POWER_TREE,
  UPDATE_COMP_PREFIX,
  DELETE_TREE_ITEM,
  INIT_DESIGN_TREE,
  UPLOAD_SPEC,
  DELETE_POWER_TREE,
  SAVE_OPTION,
  SAVE_EXTRACTION,
  UPDATE_TREE_LOADING,
  UPDATE_TREE_MONITOR,
  UPDATE_TREE_SETTING
} from './actionType';
import {
  resetSetup,
  updateLoading,
  updateError,
  powerTreeUpdateCompSetting,
  updatePCB,
  setPowerTreeData,
  updateDrawType,
  updateTreeLoading,
  updateResults,
  setTreeTip,
  updateTreeMonitor,
  showDesignTreeResult
} from './action';
import { saveMultiResults, saveMultiSpiceResults } from '../PowerTree/action';
import { updateDesignStatus, updatePCBLog, updateExpand, updateViewList, updateSelectKeys, updatePageLayout } from '../project/action';
import { changeTabMenu, openTabFooter } from '../../../MonitorStore/action';
import { getVerificationWorkflow } from '../simulation/action';
import { DESIGN_TREE, SINGLE_TREE, POWER_TREE } from '@/constants/treeConstants';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import projectDesigns from '@/services/helper/projectDesigns';
import { versionCompareSize } from '@/services/helper/dataProcess';
import {
  // controller
  getPowerTree,
  savePowerTree,
  importTreeSpec,
  getPowerSetting,
  savePowerSetting,
  savePowerTreeSingle,

  // helper
  deletePowerTree,
  sortTree,
  getDesignPowerTree_DEPRECATED,
  reShowGndLine,
  generatePowerTree,
  checkingPowerTree,
  mergePowerTree,

  // class
  TreeOption,
  PowerTree
} from '@/services/Cascade/PowerTree';
import { DCExtraction } from '../../../../services/Cascade/helper/setupClass';
import componentSetting from '@/services/Cascade/helper/compSettingHelper';
import { splitArrayToArrays } from '@/services/helper/arrayHelper';
import compTableHelper from '@/services/Cascade/helper/compTableHelper';
import compPinMap from '../../../../services/Cascade/helper/compPinMap';
import { POWER_TREE_VERSION } from '@/version';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import { updateLibraryMenu } from '../library/action';
import { LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT, SINGLE_PAGE_LAYOUT } from '@/constants/layoutConstants';
import { IND, JUMPER, RES, SWITCH, FERRITE, POWER_SWITCH } from '../../../../constants/componentType';
import { PIN_MAP } from "@/constants/libraryConstants";
import libraryConstructor from '@/services/Cascade/helper/libraryConstructor';
import pinMapStore from '@/services/Cascade/library/pinMapHelper';
import _ from 'lodash';
import { strDelimited } from '../../../../services/helper/split';
import designConstructor from '../../../../services/helper/designConstructor';
import store from '../../../store';
import { userDefaultSettings } from '../../../../services/userDefaultSetting/userDefaultSettingCtrl';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import { getPreLayoutPinMap, getPMICInPCB, getPreLayoutSetting } from '../../../../services/Cascade/helper/setupData';
import { TreePlotSetting } from '../../../../services/Cascade/PowerTree';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import { getLibraryDataInfo } from '../../../../services/Cascade/library';
import { pinMapDataUpdateByDesign } from '../../../../services/Cascade/helper/pmicPinMapHelper';

function* getDesignTreeContent(action) {
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  const { verificationId, loadPCB } = action;
  yield put(resetSetup())
  if (!verificationId) {
    return;
  }

  yield put(updateError([]))
  yield put(updatePCBLog([]))
  yield put(updateTreeMonitor([]))
  const designList = CascadeChannels.getList(DESIGN_TREE, openProjectId);
  const powerList = CascadeChannels.getList(POWER_TREE, openProjectId)
  const singleList = CascadeChannels.getList(SINGLE_TREE, openProjectId)
  const currentItem = [...designList, ...powerList, ...singleList].find(i => i.id === verificationId);

  yield put(setPowerTreeData({ verificationId }));
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: verificationId,
    verificationName: currentItem ? currentItem.name : DESIGN_TREE,
    menuType: "simulation"
  }))

  yield put(updateDesignStatus(true));
  yield put(updateLoading(true));
  yield put(setTreeTip('Getting Power Tree Information...'))

  let _designId = "", upadteCompPrefix = false, init = false;

  try {
    const res = yield call(getPowerTree, verificationId);
    yield put(getVerificationWorkflow(verificationId));
    if (res) {
      const { compPrefixLib = { version: '0.0.0' }, designId, powerTrees } = res;
      _designId = designId;
      const version = yield call(compPinMap.getVersion, designId);
      const lastVersion = (compPrefixLib && compPrefixLib.version) || '0.0.0';
      init = powerTrees.length || !versionCompareSize(lastVersion, version) ? false : true;
      const setup = yield call(getPowerSetting, verificationId);
      const userSetting = yield call([userDefaultSettings, userDefaultSettings.getSettings]);
      const { cascadeSettings = {} } = userSetting;
      const setting = cascadeSettings && cascadeSettings.treePlotSetting ? new TreePlotSetting(cascadeSettings.treePlotSetting) : new TreePlotSetting();
      const { pass = true, pairRes = true, voltage = true } = setting;
      const showResult = {
        pass,
        pairRes,
        voltage
      }
      yield put(setPowerTreeData({
        COMP_PREFIX_LIB: compPrefixLib,
        powerTrees,
        designId: _designId,
        option: new TreeOption(setup.option || {}),
        extraction: new DCExtraction(setup.config || {}),
        showResult,
        setting
      }));
      if (!setup.option || !setup.config) {
        yield call(savePowerSetting, { verificationId, setup: { option: new TreeOption(setup.option || {}), config: new DCExtraction(setup.config || {}) } });
      }
    }
    yield put(setPowerTreeData({ verificationId }));
  } catch (error) {
    console.error(error)
  }

  yield put(setTreeTip(''))
  const designExist = projectDesigns.getDesignExist(openProjectId, _designId)
  yield put(powerTreeUpdateCompSetting(upadteCompPrefix))

  const { CascadeReducer: { DesignTree: { verificationId: currentVerificationId } } } = yield select();
  if (currentVerificationId === verificationId) {
    if (_designId && designExist) {
      try {
        if (loadPCB) {
          yield call(getAuroraDB, _designId);
        }
        yield call(componentSetting.getSettingFromBackend, { designId: _designId })
        yield put(updateLibraryMenu())
      } catch (error) {
        console.error(error)
      }
    }
    const { CascadeReducer: { project: { layout, selectedKeys, viewList, expandedKeys } } } = yield select();
    if ([LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT].includes(layout)) {
      if (_designId && designExist) {
        let _selectedKeys = [...selectedKeys];
        let _viewList = [...viewList]
        if (projectDesigns.getAvailableDesignsLength(openProjectId) > 0) {
          _viewList = _viewList.includes('PCB') ? _viewList : [...viewList, 'PCB'];
          let _expandedKeys = [...expandedKeys];
          _selectedKeys = _selectedKeys.filter(key => !key.includes('PCB'))
          _selectedKeys.push(`PCB-${_designId}`);
          let expandedKey = `PCBs-${openProjectId}`;

          if (!_expandedKeys.includes(expandedKey)) {
            _expandedKeys.push(expandedKey)
            yield put(updateExpand(_expandedKeys));
          }
        }
        yield put(updateSelectKeys(_selectedKeys));
        yield put(updateViewList(_viewList));
      } else {
        let _viewList = viewList.filter(item => item !== 'PCB');
        let _selectedKeys = selectedKeys.filter(item => !item.includes('PCB'))
        yield put(updateViewList(_viewList));
        yield put(updateSelectKeys(_selectedKeys));
        yield put(updatePageLayout(SINGLE_PAGE_LAYOUT, 'setup'))
      }
    }
  }

  if (init) {
    yield call(newInitDesignTree);
  } else {
    yield put(updateLoading(false));
  }
  yield put(openTabFooter())
  yield put(updatePCB(_designId));
  yield put(updateDesignStatus(false));
}

function* getAuroraDB(designId) {
  try {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (isPreLayout) {
      yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      return;
    }
    const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
    yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId, setting);
  } catch (e) {
    console.error(e);
  }
}

function* newInitDesignTree(action = {}) {
  const { CascadeReducer: { project: { viewList, openProjectId }, DesignTree: { designId, verificationId, powerTrees } } } = yield select();
  const { total = true } = action;
  yield put(updateLoading(true));
  yield put(updateResults(undefined));
  yield put(saveMultiResults([]));
  yield put(saveMultiSpiceResults([]));
  yield put(updateTreeMonitor([], true));

  const designList = CascadeChannels.getList(DESIGN_TREE, openProjectId);
  const powerList = CascadeChannels.getList(POWER_TREE, openProjectId)
  const singleList = CascadeChannels.getList(SINGLE_TREE, openProjectId)
  const currentItem = [...designList, ...powerList, ...singleList].find(i => i.id === verificationId);
  yield put(openTabFooter());
  yield put(changeTabMenu({
    tabSelectKeys: ["detail"],
    currentVerificationId: verificationId,
    verificationName: currentItem ? currentItem.name : DESIGN_TREE,
    menuType: "simulation"
  }))
  yield call(consoleTreeTip, 'Start to initialize Power Tree...')
  yield delay(100);

  const oldPowerTrees = JSON.parse(JSON.stringify(powerTrees));
  const treeColor = powerTrees.map(item => item.tree ? item.tree.pcbIndex : []).flat(2);
  yield call(deletePowerTree, { verificationId, ids: powerTrees.map(item => item.id) });
  const treeKeys = powerTrees.map(item => item.tree ? [item.tree.key || item.tree.name, ...item.tree.nextKeys || []] : []).flat(2);
  yield put(setPowerTreeData({ powerTrees: [] }));
  yield delay(100);

  // get PCB setting (includes multi PCB)
  yield call(consoleTreeTip, 'Get PCB Settings...')
  let _designId = designId, partList = undefined, partComps = [], _pinMap = undefined, _connectors = [], moreSetting = {}, pcbTable = [];
  if (viewList.includes(POWER_TREE) || viewList.includes(SINGLE_TREE)) {
    const { CascadeReducer: { PowerTree: { root = {}, connectors } } } = yield select();
    const { pcbId, pinMapTable = [] } = root;
    pcbTable = [...pinMapTable]
    if (!pcbId) {
      yield put(updateLoading(false));
      return;
    }
    _designId = pcbId;
    partList = pinMapTable.map(item => item.partNumber);
    partComps = pinMapTable.filter(item => item.components && item.components.length).map(item => ({ comps: item.components || [], part: item.partNumber, type: item.type }));
    const pinMapList = libraryConstructor.getLibraryValues(PIN_MAP);
    const pinMapIds = pinMapList.map(i => i.id);
    const rule = item => {
      return item.partNumber && ((item.customPinMap && item.pinTable && item.pinTable.length) || (!item.customPinMap && (pinMapIds.includes(item.libraryId) || item.pinTable || item.pinTable.length)));
    }
    const { trueArray, falseArray } = splitArrayToArrays({ array: pinMapTable, rule });
    const isPreLayout = designConstructor.isPreLayout(_designId);
    if (falseArray.length && !isPreLayout) {
      const parts = falseArray.filter(i => !!i.partNumber).map(i => i.partNumber).join(', ');
      parts.length && message.error(`Cannot get Library file for [${parts}], please set them in Root PCB!`, 8)
    }
    _pinMap = [...trueArray];
    _connectors = [...connectors];
    const ids = _connectors.map(c => c.map(i => i.pcb)).flat(2);
    yield* ids.map(function* (id) {
      if (id) {
        yield call(getAuroraDB, id);
        let setting = yield call(componentSetting.getPrefixLib, id);
        const table = yield call(compTableHelper.getTableData, id);
        let pinMap = yield call(compPinMap.getPinMapData, id);
        const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, id);
        const _pinConnection = yield call(compPinMap.getPinConnection, id);
        const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
        const isPreLayout = designConstructor.isPreLayout(id);
        if (isPreLayout) {
          setting = getPreLayoutSetting(id);
          pinMap = getPreLayoutPinMap(id);
        }
        moreSetting[id] = {
          setting,
          table,
          pinMap,
          doNotStuff,
          pinConnection
        }
      }
    })
  }

  const pcbName = designConstructor.getDesignName(_designId);
  yield put(updateTreeMonitor([{ text: `==> Start Power Tree Identification for ${pcbName}`, type: 'normal' }]));
  yield delay(100);

  const PMICs = yield call(getPMICInPCB, { designId: _designId, partList, partComps });
  yield put(updateTreeMonitor([{ text: `==> Get ${PMICs.length} PMICs from PCB...`, type: 'normal' }]))
  let setting = yield call(componentSetting.getPrefixLib, _designId);
  const table = yield call(compTableHelper.getTableData, _designId);
  let pinMap = yield call(compPinMap.getPinMapData, _designId);
  const _pinConnection = yield call(compPinMap.getPinConnection, _designId);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, _designId);
  const version = yield call(compPinMap.getVersion, _designId);

  if (_pinMap) {
    const driver = pinMap.filter(item => item.type === "driver");
    pinMap = [..._pinMap, ...driver];
  }

  let pinMapList = []
  yield* pinMap.map(function* (_map) {
    let data = [];
    if (_map.customPinMap) {
      const pinStore = yield call([pinMapStore, pinMapStore.getPinStoreByComponent], { type: _map.type, partNumber: _map.partNumber, designId: _designId })
      data = _map.pinTable ? pinMapDataUpdateByDesign([..._map.pinTable], pinStore) : []
    } else {
      if (_map.libraryId) {
        try {
          const res = yield call([pinMapStore, pinMapStore.getPinMapLibrary], _map, _designId, "pinName")
          data = res && res.config && res.config.pinTable ? [...res.config.pinTable] : [];
        } catch (error) {
          console.error("Can not find library file");
        }
      }
    }
    pinMapList.push({ ..._map, data })
  })

  const isPreLayout = designConstructor.isPreLayout(_designId);
  if (isPreLayout) {
    setting = getPreLayoutSetting(_designId);
    pinMapList = getPreLayoutPinMap(_designId);
  }

  const { CascadeReducer: { DesignTree: { option } } } = yield select();
  const setTreeMonitors = (logs, replace = false) => {
    store.dispatch({ type: UPDATE_TREE_MONITOR, logs, replace })
  }

  let originalTreeObj = {}, index = 1;

  const userDefaultSetting = yield call([userDefaultSettings, userDefaultSettings.getSettings])
  const { cascadeSettings = {} } = userDefaultSetting;
  const { powerTreeLevel = 8 } = cascadeSettings

  for (let pmic of PMICs) {
    if (!pmic.trace) {
      continue;
    }
    yield call(consoleTreeTip, `Checking PMICs (${index}/${PMICs.length})...`);
    yield put(updateTreeMonitor([{ text: `\tPMIC ${index} : ${pmic.name}`, type: 'title' }]))
    yield delay(100);
    const singleTreeObj = yield call(generatePowerTree, {
      pmic,
      PMICs,
      setting,
      table,
      pinMap: pinMapList,
      designId: _designId,
      doNotStuff,
      connectors: _connectors,
      moreSetting,
      pinConnection,
      showGnd: option ? option.showGnd : true,
      useInput: option ? option.useInput : true,
      pcbTable,
      treeColor,
      setTreeMonitors,
      powerTreeLevel
    })
    originalTreeObj = { ...originalTreeObj, ...singleTreeObj }
    index = index + 1;
  }

  // merge tree by pin map
  yield call(consoleTreeTip, `Generating Power Tree...`);
  yield delay(100);

  // Saving Power Tree frame 
  const newTreeKeys = Object.keys(originalTreeObj)
  let trees = newTreeKeys.map(treeItem => new PowerTree({ name: treeItem }));
  if (!total && treeKeys.length) {
    trees = trees.filter(item => treeKeys.includes(item.tree.key))
  }
  yield call(consoleTreeTip, `Generated ${trees.length} Power Trees...`);
  yield put(updateTreeMonitor([{ text: `==> Power Tree Identification Completed`, type: 'normal' }]))
  yield delay(500);
  yield call(consoleTreeTip, `Saving Power Tree Frame...`);

  yield call(savePowerTrees, { powerTrees: [...trees], COMP_PREFIX_LIB: { version }, update: true });
  yield call(consoleTreeTip, `Finish Generating Power Tree...`);
  yield call(consoleTreeTip, ``);
  yield put(updateLoading(false));

  // set power tree loading
  const { CascadeReducer: { DesignTree: { powerTrees: newPowerTrees } } } = yield select();
  let initTreeLoading = {};
  newPowerTrees.forEach(tree => initTreeLoading[tree.id] = 'Initializing Power Tree Data...');
  yield put(setPowerTreeData({ treeLoading: initTreeLoading }));


  const setSingleTreeLoading = (id, tip) => {
    store.dispatch({ type: UPDATE_TREE_LOADING, id, tip })
  }

  let finishedTreeName = [], mergeTreeObj = originalTreeObj;
  for (let newPowerTree of newPowerTrees) {
    const { id, tree } = newPowerTree;
    const { name = "" } = tree;
    const root = strDelimited(name, ' - ', { returnIndex: 0 });
    yield put(updateTreeLoading(id, 'Checking Power Tree Data...'));
    try {
      mergeTreeObj = yield call(checkingPowerTree, {
        treeObj: mergeTreeObj,
        setting,
        table,
        pinMap: pinMapList,
        designId: _designId,
        root: name,
        pcbTable
      })
      yield put(updateTreeLoading(id, 'Merging Power Tree Data...'));
      if (!mergeTreeObj[name]) {
        yield put(updateTreeLoading(id, ''));
        return;
      }
      if (mergeTreeObj[name].paths.length) {
        yield fork(mergeSingleTree, mergeTreeObj[name], root, id, setSingleTreeLoading, verificationId, oldPowerTrees);
        finishedTreeName.push({ name, id })
      } else {
        yield put(updateTreeLoading(id, 'hidden'));
        yield call(deletePowerTreeByIds, id)
      }
      for (let finished of finishedTreeName) {
        if (!mergeTreeObj[finished.name] || !mergeTreeObj[finished.name].paths || !mergeTreeObj[finished.name].paths.length) {
          yield call(deletePowerTreeByIds, finished.id);
          finishedTreeName = finishedTreeName.filter(item => item.id !== finished.id);
        }
      }
    } catch (e) {
      console.error(e);
      yield put(updateTreeLoading(id, ''));
    }
  }
}

function* consoleTreeTip(tip) {
  tip && console.log(tip)
  yield put(setTreeTip(tip))
}

function* mergeSingleTree(treeObj, root, id, setSingleTreeLoading, verificationId, oldPowerTrees) {
  const { CascadeReducer: { DesignTree: { treeMonitorLogs } } } = yield select();
  const mergeTree = yield call(mergePowerTree, treeObj, root, id, setSingleTreeLoading, oldPowerTrees, treeMonitorLogs);

  if (mergeTree.length && !deleteIds.includes(id)) {
    yield call(saveSinglePowerTree, { id, verificationId, powerTrees: mergeTree });
    yield put(getVerificationWorkflow(verificationId));
    yield put(updateTreeLoading(id, ''));
  } else {
    yield call(deletePowerTreeByIds, id)
  }
}

let deleteIds = [], deleteTask = null;
function* deletePowerTreeByIds(id) {
  yield put(updateTreeLoading(id, 'Power Tree will be merged into other tree...'));
  if (deleteTask) {
    yield cancel(deleteTask)
  }
  deleteIds.push(id);
  deleteTask = yield fork(debounceDeletePowerTree, deletePowerTreeById, { ids: deleteIds }, 3000);
  yield delay(1000)
  yield put(updateTreeLoading(id, 'hidden'));
}

function* debounceDeletePowerTree(callback, action, time = 0) {
  try {
    yield delay(time);
    yield call(callback, action);
    deleteIds = [];
  } catch (e) {
    console.error(e);
  }
}

function* initDesignTree_DEPRECATED(action = {}) {
  const { CascadeReducer: { project: { viewList }, DesignTree: { designId, verificationId, powerTrees } } } = yield select();
  const { total = true } = action;
  yield put(updateLoading(true));
  yield put(updateResults(undefined));
  yield put(saveMultiResults([]));
  yield put(setTreeTip('Start to initialize Power Tree...'))
  yield delay(100);

  const treeColor = powerTrees.map(item => item.tree ? item.tree.pcbIndex : []).flat(2);
  yield call(deletePowerTree, { verificationId, ids: powerTrees.map(item => item.id) });
  const treeKeys = powerTrees.map(item => item.tree && (item.tree.key || item.tree.name));

  let _designId = designId, partList = undefined, partComps = [], _pinMap = undefined, _connectors = [], moreSetting = {}, pcbTable = [];
  if (viewList.includes(POWER_TREE) || viewList.includes(SINGLE_TREE)) {
    const { CascadeReducer: { PowerTree: { root = {}, connectors } } } = yield select();
    yield put(setTreeTip('Get PCB Settings...'))
    yield delay(10);
    const { pcbId, pinMapTable = [] } = root;
    pcbTable = [...pinMapTable]
    if (!pcbId) {
      yield put(updateLoading(false));
      return;
    }
    _designId = pcbId;
    partList = pinMapTable.map(item => item.partNumber);
    partComps = pinMapTable.filter(item => item.components && item.components.length).map(item => ({ comps: item.components || [], part: item.partNumber, type: item.type }));
    const pinMapList = libraryConstructor.getLibraryValues(PIN_MAP);
    const pinMapIds = pinMapList.map(i => i.id);
    const rule = item => {
      return item.partNumber && pinMapIds.includes(item.libraryId);
    }
    const { trueArray, falseArray } = splitArrayToArrays({ array: pinMapTable, rule });

    if (falseArray.length) {
      const parts = falseArray.filter(i => !!i.partNumber).map(i => i.partNumber).join(', ');
      parts.length && message.error(`Cannot get Library file for [${parts}], please set them in Root PCB!`, 8)
    }
    _pinMap = [...trueArray];
    _connectors = [...connectors];
    const ids = _connectors.map(c => c.map(i => i.pcb)).flat(2);
    yield* ids.map(function* (id) {
      if (id) {
        yield call(getAuroraDB, id);
        const setting = yield call(componentSetting.getPrefixLib, id);
        const table = yield call(compTableHelper.getTableData, id);
        let pinMap = yield call(compPinMap.getPinMapData, id);
        const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, id);
        const _pinConnection = yield call(compPinMap.getPinConnection, id);
        const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
        moreSetting[id] = {
          setting,
          table,
          pinMap,
          doNotStuff,
          pinConnection
        }
      }
    })
  }

  const PMICs = yield call(getPMICInPCB, { designId: _designId, partList, partComps });
  const setting = yield call(componentSetting.getPrefixLib, _designId);
  const table = yield call(compTableHelper.getTableData, _designId);
  let pinMap = yield call(compPinMap.getPinMapData, _designId);
  const _pinConnection = yield call(compPinMap.getPinConnection, _designId);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, _designId);
  const version = yield call(compPinMap.getVersion, _designId);

  if (_pinMap) {
    const driver = pinMap.filter(item => item.type === "driver");
    pinMap = [..._pinMap, ...driver];
  }

  let pinMapList = []
  yield* pinMap.map(function* (_map) {
    let data = [];
    if (_map.libraryId) {
      try {
        const res = yield call([pinMapStore, pinMapStore.getPinMapLibrary], _map, _designId, "pinName")
        data = res && res.config && res.config.pinTable ? [...res.config.pinTable] : [];
      } catch (error) {
        console.error("Can not find library file");
      }
    }
    pinMapList.push({ ..._map, data })
  })

  yield put(setTreeTip('Generating Power Tree...'))
  yield delay(10);

  const { CascadeReducer: { DesignTree: { option } } } = yield select();
  const trees = yield call(getDesignPowerTree_DEPRECATED, {
    PMICs,
    setting,
    table,
    pinMap: pinMapList,
    designId: _designId,
    doNotStuff,
    connectors: _connectors,
    moreSetting,
    pinConnection,
    treeKeys: total ? [] : treeKeys,
    showGnd: option ? option.showGnd : true,
    pcbTable,
    treeColor
  })

  let _powerTrees = [...trees];

  _powerTrees.forEach(p => {
    const findTree = powerTrees.find(item => item.tree.name === p.tree.name
      || (item.tree.root === p.tree.root && item.tree.pins.select
        && p.tree.pins.select && p.tree.pins.select.some(pin => item.tree.pins.select.includes(pin))));
    if (findTree) {
      const oldData = findTree.tree.branch.flat(2);
      p.tree.branch.forEach(column => {
        column.forEach(item => {
          if (item.type === 'root') {
            const find = oldData.find(i => i.name === item.name);
            if (find) {
              item.load = find.load;
              item.voltage = find.voltage;
            }
          } else if (['VRM', 'Load'].includes(item.type)) {
            const find = oldData.find(i => i.name === item.name && i.prevNet === item.prevNet
              && i.prevComp === item.prevComp && (!item.nextNet || item.nextNet === i.nextNet));
            if (find) {
              item.load = find.load;
              item.vMin = find.vMin;
              item.vMax = find.vMax;
              item.voltage = find.voltage;
            }
          } else if (['Connector', RES, IND, SWITCH, JUMPER, FERRITE].includes(item.type)) {
            const find = oldData.find(i => i.name === item.name && i.prevNet === item.prevNet
              && i.prevComp === item.prevComp && (!item.nextNet || item.nextNet === i.nextNet));
            if (find) {
              item.resistance = find.resistance || item.resistance;
            }
          } else if (['Driver'].includes(item.type)) {
            const find = oldData.find(i => i.name === item.name && i.prevNet === item.prevNet
              && i.prevComp === item.prevComp && (!item.nextNet || item.nextNet === i.nextNet));
            if (find) {
              item.model = find.model || {};
              const _driverMap = find.driverMap || [];
              const keyList = ['output', 'input', 'outputGround', 'inputGround'];
              item.driverMap.forEach((record, index) => {
                const _record = _driverMap[index];
                if (_record) {
                  keyList.forEach(key => {
                    if (_.isEqual(record[key], _record[key])) {
                      record[`${key}_node`] = _record[`${key}_node`]
                    }
                  })
                }
              })
            }
          }
        })
      })
    }
  })
  yield put(setTreeTip('Saving Power Tree...'))
  yield call(savePowerTrees, { powerTrees: [..._powerTrees], COMP_PREFIX_LIB: { version }, update: true });
  yield put(getVerificationWorkflow(verificationId));
  yield put(updateLoading(false));
  yield put(setTreeTip(''))
}

export function* savePowerTrees(action) {
  const { type, compPrefixLib, update = false, resultSave = false, ...params } = action;
  const { CascadeReducer: { DesignTree: { designId, verificationId, COMP_PREFIX_LIB, powerTrees, reset } } } = yield select();
  const newParams = { designId, compPrefixLib: compPrefixLib ? compPrefixLib : COMP_PREFIX_LIB, powerTrees, ...params };
  try {
    const res = yield call(savePowerTree, { verificationId, params: { ...newParams, version: POWER_TREE_VERSION } });
    if (res && !resultSave) {
      const updateParams = update ? res : { COMP_PREFIX_LIB: compPrefixLib ? compPrefixLib : COMP_PREFIX_LIB, ...params }
      yield put(setPowerTreeData({ ...updateParams, reset: !reset }));
    }
  } catch (error) {
    message.error("Saving power tree failed: " + error);
  }
}

let treeTask = {};
function* saveSinglePowerTree(action) {
  const { id, verificationId, powerTrees, redraw = true, ...others } = action;
  const { CascadeReducer: { DesignTree: { verificationId: _verificationId, powerTrees: _powerTrees } } } = yield select();
  if (_verificationId === verificationId) {
    let newTrees = [..._powerTrees];
    const findIndex = newTrees.findIndex(item => item.id === id);
    if (findIndex > -1) {
      newTrees[findIndex] = powerTrees[0];
      yield put(setPowerTreeData({ powerTrees: newTrees, ...others }));
      delay(50);
      if (redraw) {
        yield put(updateDrawType(id, true));
      }
    }
  }

  if (treeTask[id]) {
    yield cancel(treeTask[id])
  }

  const name = powerTrees[0].tree.name;
  const errorMsg = `Save Power Tree ( ${name} ) Error!`;
  treeTask[id] = yield fork(debounce, savePowerTreeSingle, { verificationId, params: { powerTrees } }, 3000, errorMsg);
}

function* debounce(callback, action, time = 0, errorMsg) {
  try {
    yield delay(time);
    yield call(callback, action);
  } catch (e) {
    console.error(e);
    message.error(errorMsg, 10);
  }
}

function* _updateCurrentTree(action) {
  const { id, params, key } = action;
  const { CascadeReducer: { DesignTree: { powerTrees, verificationId } } } = yield select();
  let _powerTrees = [...powerTrees], save = true, redraw = false;
  const powerTree = _powerTrees.find(item => item.id === id);
  if (powerTree) {
    const tree = powerTree.tree;
    switch (key) {
      case 'name':
        powerTree.tree.name = params;
        break;
      case 'branch':
        powerTree.tree = { ...tree, ...params };
        break;
      case 'table':
        save = false;
        if (tree.root && tree.pins && tree.pins.select && tree.pins.select.length) {
          yield call(newInitDesignTree, { tree, id });
        }
        break;
      case 'pcbIndex':
        powerTree.tree = { ...tree, ...params };
        redraw = true;
        break;
      case 'whatIf':
        powerTree.tree.whatIf = params;
        break;
      case 'whatIfData':
        powerTree.tree.whatIfData = params;
        break;
      default: break;
    }
    if (save) {
      yield call(saveSinglePowerTree, { id, verificationId, powerTrees: [powerTree], redraw });
    }
  }
}

function* updateComponentPrefix(action) {
  const { version } = action;
  const { CascadeReducer: { DesignTree: { COMP_PREFIX_LIB } } } = yield select();
  yield put(powerTreeUpdateCompSetting(false))
  yield put(updateLoading(true));
  yield put(setPowerTreeData({ COMP_PREFIX_LIB: { version } }));

  if (versionCompareSize(version, (COMP_PREFIX_LIB && COMP_PREFIX_LIB.version) || '0.0.0')) {
    yield call(newInitDesignTree)
  } else {
    const { CascadeReducer: { DesignTree: { verificationId, reset } } } = yield select();
    const res = yield call(getPowerTree, verificationId);
    const { compPrefixLib, designId, powerTrees } = res;
    yield put(setPowerTreeData({ COMP_PREFIX_LIB: compPrefixLib, powerTrees, designId, reset: !reset }));
  }

  yield put(updateLoading(false));
}

function* deleteTreeItem(action) {
  const { id, treeItems, columnIndex } = action;
  const { CascadeReducer: { DesignTree: { powerTrees, refreshResults, verificationId }, PowerTree: { connectors = [] } } } = yield select();
  const powerTree = powerTrees.find(item => item.id === id);
  yield put(updateTreeLoading(id, 'Deleting Tree Item...'));
  yield delay(50)
  if (powerTree) {
    let _branch = [...powerTree.tree.branch];
    if (!_branch[columnIndex]) {
      return
    }
    for (let treeItem of treeItems) {
      const deepIndex = treeItem.deepIndex;
      _branch[columnIndex] = _branch[columnIndex].filter(item => item.deepIndex !== deepIndex);
      let prevComp = [treeItem.prevComp, ...treeItem.anotherPrev], prevNets = [treeItem.prevNet], pcbKeys = [treeItem.pcbKey];
      const gndKey = treeItem.gndKey
      // delete comp parent node
      for (let i = columnIndex - 1; i > 0; i--) {
        let column = _branch[i], breaks = false, _prevComp = [], _prevNets = [...prevNets], _pcbKeys = [...pcbKeys];
        prevNets = []; pcbKeys = [];
        for (let comp of prevComp) {
          let _findIndex = column.findIndex(item => (item.name === comp
            && (!item.nextNet || _prevNets.includes(item.nextNet))
            && ((item.type === 'Connector' && item.connectionType === 'prev') || !item.pcbKey || _pcbKeys.includes(item.pcbKey)))
            && (!item.isGnd || (item.isGnd && item.gndKey === gndKey))
          );
          if (_findIndex > -1) {
            const deepIndex = column[_findIndex].deepIndex;
            const _nextNet = column[_findIndex].nextNet || '';
            const _pcbKey = column[_findIndex].pcbKey || '';
            const _groundKey = column[_findIndex].groundKey || '';
            const _isGnd = column[_findIndex].isGnd
            const list = _branch[i + 1].filter(item => {
              return (item.prevComp === comp || item.anotherPrev.includes(comp))
                && (!_nextNet || _nextNet === item.prevNet)
                && ((item.type === 'Connector' && item.connectionType === 'next') || !_pcbKey || _pcbKey === item.pcbKey)
                && (!_isGnd || (_isGnd && item.gndKey === _groundKey))
            });
            if (list.length) {
              breaks = true;
            } else {
              _prevComp.push(column[_findIndex].prevComp, ...column[_findIndex].anotherPrev);
              prevNets.push(column[_findIndex].prevNet)
              pcbKeys.push(column[_findIndex].pcbKey)
              _branch[i] = _branch[i].filter(item => deepIndex !== item.deepIndex);
            }
          }
        }
        if (breaks) {
          break;
        } else {
          prevComp = _prevComp;
          pcbKeys = pcbKeys.filter(i => !!i);
        }
      }

      // delete children node
      const { middle, type, connectionType, pcbKey } = treeItem;
      if (middle || !['Load', 'VRM'].includes(type)) {
        let nextNet = treeItem.nextNet ? [treeItem.nextNet] : [], nextComp = [treeItem.name],
          pcbKeys = pcbKey ? [pcbKey] : [], connectionTypes = connectionType ? [connectionType] : [];
        for (let i = columnIndex + 1; i < _branch.length; i++) {
          let column = _branch[i], _nextComp = [...nextComp], _nextNet = [...nextNet], _pcbKeys = [...pcbKeys], _connectionTypes = [...connectionTypes];
          let CompFilter = (item) => {
            if (item.type === 'Connector' && item.connectionType === 'next' && _nextNet.includes(item.prevNet) && _nextComp.includes(item.prevComp)) {
              return _connectionTypes.includes('prev') ? true : false;
            }
            return _nextComp.includes(item.prevComp)
              && (!_nextNet.length || _nextNet.includes(item.prevNet))
              && (!_pcbKeys.length || _pcbKeys.includes(item.pcbKey))
              && (!item.anotherPrev || !item.anotherPrev.length) ? true : false;
          }
          const { trueArray: filterComps, falseArray: keepComps } = splitArrayToArrays({ array: column, rule: CompFilter });
          _branch[i] = keepComps.map(item => {
            let _item = { ...item }
            if (_nextComp.includes(_item.prevComp) && _item.anotherPrev.length) {
              let _prevComp = _item.anotherPrev.shift();
              _item.prevComp = _prevComp
            }
            return _item
          });
          nextComp = filterComps.map(item => item.name);
          nextNet = filterComps.filter(item => !item.hiddenComp).map(item => item.nextNet).filter(item => !!item);
          if (nextNet.length) {
            nextNet = filterComps.map(item => item.nextNet).filter(item => !!item);
          }
          pcbKeys = filterComps.map(item => item.pcbKey).filter(item => !!item);
          connectionTypes = filterComps.map(item => item.connectionType).filter(item => !!item);
        }
      }
    }

    _branch = _branch.map(br => br.map(b => ({ ...b, deepIndex: undefined })))
    let newBranch = yield call(sortTree, _branch, connectors.length > 1 ? connectors : []);
    let deep = 1;
    newBranch = newBranch.filter(c => c.length > 0);
    newBranch = reShowGndLine(newBranch)
    newBranch.forEach(branch => {
      if (branch[branch.length - 1] && branch[branch.length - 1].deepIndex) {
        if (deep < branch[branch.length - 1].deepIndex) {
          deep = branch[branch.length - 1].deepIndex
        }
      }
      branch.forEach(comp => {
        if (comp.type === 'Connector') {
          const existDeep = branch.map(item => item.deepIndex).sort((a, b) => a - b)
          const connList = branch.filter(item => item.name === comp.name && item.type === comp.type && item.pcbKey === comp.pcbKey);
          const connMap = connList.map(item => item.deepIndex).sort((a, b) => a - b)
          let grid = 1, index = connMap.findIndex(i => i === comp.deepIndex), startIndex = comp.deepIndex;
          for (let i = index + 1; i < connMap.length; i++) {
            if (connMap[i] === startIndex + 1) {
              grid = grid + 1;
              startIndex = startIndex + 1
            } else {
              let exist = false;
              for (let j = startIndex + 1; j < connMap[i]; j++) {
                if (existDeep.includes(j)) {
                  exist = true;
                  break;
                }
              }
              if (exist) {
                break;
              }
              grid = grid + connMap[i] - startIndex;
              startIndex = connMap[i]
            }
          }
          comp.grid = grid
          let connectorPins = {};
          connList.forEach(conn => {
            connectorPins[conn.prevNet] = conn.pins
          })
          comp.connectorPins = connectorPins;
        }
      })
    })
    powerTree.tree.branch = newBranch;
    powerTree.tree.deep = deep;

    yield put(updateTreeLoading(id, ''));
    yield call(saveSinglePowerTree, { id, verificationId, powerTrees: [powerTree], refreshResults: !refreshResults });
  }
}

function* uploadTreeSpec(action) {
  const { verificationId, file } = action;

  try {
    const { CascadeReducer: { DesignTree: { powerTrees } } } = yield select();

    for (let powertree of powerTrees) {
      yield put(updateTreeLoading(powertree.id, "Updating value..."))
    }

    const res = yield call(importTreeSpec, { verificationId, file });
    let _powerTrees = [...powerTrees];

    for (let powertree of powerTrees) {
      const { id, tree } = powertree;
      const branch = [...tree.branch];

      branch.forEach(column => {
        column.forEach(item => {
          if (item.type === 'root') {
            const { pins, name } = item;
            const pinList = pins.map(pin => pin.pinName);
            const find = res.find(r => r.pmicComp === name && pinList.join(', ').includes(r.pmicPins) && r.nominalVoltage);
            if (find && find.nominalVoltage) {
              item.voltage = find.nominalVoltage;
            }
          }
          if (['Load', 'VRM'].includes(item.type)) {
            const { prevNet, name } = item;
            const find = res.find(r => r.loadComponent === name && r.componentNetName === prevNet);

            if (find) {
              const { loadCurrent, nominalVoltage, vmax, vmin } = find;
              item.load = loadCurrent;
              item.vMin = vmin;
              item.vMax = vmax;
              if (item.middle) {
                item.voltage = nominalVoltage
              }
            }
          }
        })
      })

      const findIndex = _powerTrees.findIndex(tree => tree.id === id);
      _powerTrees[findIndex].tree.branch = [...branch];
      yield put(setPowerTreeData({ powerTrees: [..._powerTrees] }));
      yield put(updateTreeLoading(id, ""))
    }

    yield call(savePowerTrees, { powerTrees: [..._powerTrees] });

  } catch (err) {
    console.error(err);

    const { CascadeReducer: { DesignTree: { powerTrees } } } = yield select();
    for (let powertree of powerTrees) {
      yield put(updateTreeLoading(powertree.id, ""))
    }
  }
}

function* deletePowerTreeById(action) {
  const { CascadeReducer: { DesignTree: { powerTrees, verificationId } } } = yield select();
  const { id, ids = [] } = action;
  try {
    yield call(deletePowerTree, { verificationId, ids: id ? [id] : ids });
    const newPowerTrees = powerTrees.filter(item => item.id !== id && !ids.includes(item.id));
    yield put(setPowerTreeData({ powerTrees: newPowerTrees }));
  } catch (error) {
    message.error('Delete power tree failed!');
    console.error(error);
  }
}

function* saveOption(action) {
  const { option } = action;
  const _option = new TreeOption(option);
  const { CascadeReducer: { DesignTree: { verificationId, option: prevOption, extraction } } } = yield select();
  try {
    yield call(savePowerSetting, { verificationId, setup: { option: _option, extraction } });
    yield put(setPowerTreeData({ option: _option }));
  } catch (e) {
    console.error('Save Option Error:' + e);
  }
  if (prevOption.showGnd !== _option.showGnd || prevOption.useInput !== _option.useInput) {
    yield call(newInitDesignTree, { total: false });
  }
}

function* saveExtraction(action) {
  const { extraction } = action;
  const _extraction = new DCExtraction(extraction);
  const { CascadeReducer: { DesignTree: { verificationId, option } } } = yield select();
  try {
    yield call(savePowerSetting, { verificationId, setup: { option, config: _extraction } });
    yield put(setPowerTreeData({ extraction: _extraction }));
  } catch (e) {
    console.error('Save Extraction Error:' + e);
  }
}

function* updateTreeSetting(action) {
  const { setting, redraw, result, close } = action;
  const { CascadeReducer: { DesignTree: { reset } } } = yield select();
  const _setting = new TreePlotSetting(setting);
  yield put(setPowerTreeData({
    setting: _setting
  }));
  if (redraw) {
    yield put(setPowerTreeData({
      reset: !reset
    }));
  }
  if (result) {
    const { pass, pairRes, voltage } = _setting;
    yield put(showDesignTreeResult({
      pass, pairRes, voltage
    }));
  }
  if (close) {
    const userSetting = yield call([userDefaultSettings, userDefaultSettings.getSettings]);
    const { cascadeSettings = {} } = userSetting;
    let _userSetting = { ...userSetting, cascadeSettings: { ...cascadeSettings, treePlotSetting: _setting } };
    yield call([userDefaultSettings, userDefaultSettings.updateUserDefaultSettings], _userSetting);
  }
}

function* getPowerSwitchPinMap(pinConnection) {
  let _pinConnection = [];
  yield* pinConnection.map(function* (connection) {
    if (connection.type === POWER_SWITCH && connection.libraryId) {
      try {
        const libraryData = yield call(getLibraryDataInfo, connection.libraryId);
        const { config = {} } = libraryData;
        const { pinTable = [] } = config;
        _pinConnection.push({ ...connection, pinMap: pinTable })
      } catch (e) {
        _pinConnection.push(connection)
      }
    } else {
      _pinConnection.push(connection)
    }
  })
  return _pinConnection;
}

function* DesignTreeSaga() {
  yield takeEvery(GET_POWER_TREE_CONTENT, getDesignTreeContent);
  yield takeEvery(UPDATE_POWER_TREE, _updateCurrentTree);
  yield takeEvery(UPDATE_COMP_PREFIX, updateComponentPrefix);
  yield takeEvery(DELETE_TREE_ITEM, deleteTreeItem);
  yield takeEvery(INIT_DESIGN_TREE, newInitDesignTree);
  yield takeEvery(UPLOAD_SPEC, uploadTreeSpec);
  yield takeEvery(DELETE_POWER_TREE, deletePowerTreeById);
  yield takeEvery(SAVE_OPTION, saveOption);
  yield takeEvery(SAVE_EXTRACTION, saveExtraction);
  yield takeEvery(UPDATE_TREE_SETTING, updateTreeSetting)
}

export default DesignTreeSaga;