import React, { Fragment, PureComponent } from 'react';
import { CheckOutlined, CloseOutlined, SaveOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Divider, Tooltip, Popover, Input } from 'antd';
import ResultFile from './fileContent';
import { RESULTS } from '../../constants';


class Result extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      resultShow: true
    };
  }

  resultTitleClick = () => {
    const { resultShow } = this.state;
    this.setState({
      resultShow: !resultShow
    });
  }

  content = () => {
    const { resultName, saveHistoryError, cancelSaveResult, inputNameChange, resultSaveClick } = this.props.contentData;
    return (
      <Fragment> <Input
        value={resultName}
        className='pdn-result-name'
        onChange={(e) => inputNameChange(e)}
        ref={(input) => { this.inputRef = input; }}
        onPressEnter={(e) => resultSaveClick(e)}
        onClick={(e) => this.inputClick(e)}
        addonAfter={<Fragment>
          <CloseOutlined className='aurora-tree-create-icon' onClick={(e) => cancelSaveResult(e)} />
          <CheckOutlined
            className='aurora-tree-create-icon mg-r-8'
            onClick={(e) => resultSaveClick(e)} />
        </Fragment>}
      />
        {saveHistoryError && <span className='pdn-result-history-error' onClick={(e) => this.inputClick(e)}>{saveHistoryError}</span>}
      </Fragment>
    );
  }


  inputClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { resultActions, resultData } = this.props;
    const { fileNameClick, changePort, colorChange, deleteFile, showOptimization, portNameClick, handleVisibleChange } = resultActions;
    const { files, portSelect, verificationId, existResult, saveInputVisible } = resultData;
    const { resultShow } = this.state;
    const resultFiles = files.filter(file => file.fileType !== 'history' && (file.type === 'simulation' || file.type === 'opt'));
    const Icon = resultShow ? DownOutlined : RightOutlined;
    return (
      <div className='pdn-result-upload' id='pdn-sparameter-result'>
        <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className='result-import-title'>
            <Icon className="title-expand-icon" onClick={this.resultTitleClick} />
            <span className='pdn-result-menu-title' onClick={this.resultTitleClick}>Result</span>
            <Tooltip
              title={!existResult ? 'Current Result Not Exist' : (!saveInputVisible ? 'Save To History' : '')}
              overlayClassName='aurora-tooltip'
              onClick={this.inputClick}
              getPopupContainer={() => document.getElementById('pdn-sparameter-result')}
            >
              <Popover
                content={this.content()}
                getPopupContainer={() => document.getElementById('pdn-sparameter-result')}
                trigger="click"
                onClick={this.inputClick}
                open={!existResult ? false : saveInputVisible}
                onOpenChange={handleVisibleChange}
                overlayClassName='pdn-result-popover'>
                <SaveOutlined
                  className={!existResult ? 'pdn-result-save-icon-disabled' : "pdn-result-save-icon"} />
              </Popover>
            </Tooltip>
          </div>
        </Divider>
        <div className='pdn-result-file-content' style={{ display: resultShow ? 'block' : 'none' }}>
          {resultFiles.length > 0 ? resultFiles.map((file, fileIndex) =>
            <ResultFile
              file={file}
              fileNameClick={fileNameClick}
              fileIndex={fileIndex}
              portSelect={portSelect}
              changePort={changePort}
              verificationId={verificationId}
              colorChange={colorChange}
              portNameClick={portNameClick}
              showOptimization={showOptimization}
              deleteFile={deleteFile}
              time='current'
              key={file.id}
              type={RESULTS}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default Result;