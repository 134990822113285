import {
  CHANGE_SELECT_KEY,
  UPDATE_HISTORT_LIST,
  UPDATE_HISTORY_STATUS,
  UPDATE_IMPORT_LIST,
  UPDATE_IMPORT_STATUS,
  RUNNING_IMPORT_LIST,
  UPDATE_COMPARE_LIST,
  UPDATE_COMPARE_STATUS,
} from './actionType';

const defaultState = {
  resultKey: 'impedance',
  historys: [],
  updateHistory: false,
  imports: [],
  updateImports: false,
  runningImports: [],
  compares: [],
  updateCompare: false
}

export const impedanceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_SELECT_KEY:
      return {
        ...state,
        resultKey: action.key
      }
    case UPDATE_HISTORT_LIST:
      return {
        ...state,
        historys: action.historys
      }
    case UPDATE_HISTORY_STATUS:
      return {
        ...state,
        updateHistory: !state.updateHistory
      }
    case UPDATE_IMPORT_LIST:
      return {
        ...state,
        imports: action.imports
      }
    case UPDATE_IMPORT_STATUS:
      return {
        ...state,
        updateImports: !state.updateImports
      }
    case RUNNING_IMPORT_LIST:
      return {
        ...state,
        runningImports: action.list
      }
    case UPDATE_COMPARE_LIST:
      return {
        ...state,
        compares: action.compares,
        updateCompare: action.status ? !state.updateCompare : state.updateCompare
      }
    case UPDATE_COMPARE_STATUS:
      return {
        ...state,
        updateCompare: !state.updateCompare
      }
    default: return state;
  }
};

