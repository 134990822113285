
import { CARD_VERIFICATION, MULTIPLE_CHANNEL, PCB_CHANNEL, PCB_CHANNEL_RESULT } from "../../../constants/treeConstants";
import designConstructor from "../../helper/designConstructor";
import cardChannelsConstructor from "../cardHelper";
import { CARD_RESULT, ROCKY_PDN } from "../constants";
import multipleResultConstructor from "../multipleResultHelper";
import PCBChannelContentConstructor from "../SSN/pcbChannelInfo";
import rockyChannels from "./channels";
import projectChannels from "./projectChannels";

function getDebugChannelList(currentProjectId) {
  const interfaceChannelNames = projectChannels.get(currentProjectId).map(id => rockyChannels.get(id)).map(n => n.name);
  return ["All", ...interfaceChannelNames];
}

function getCardChannelList(currentProjectId) {
  const designs = designConstructor.getAvailableCardDesignValues(currentProjectId);
  let list = [];
  for (let item of designs) {

    let children = [];
    //add current card channels
    const cardChannelNames = cardChannelsConstructor.getChannels(item.id);
    for (let it of cardChannelNames) {
      children.push({
        title: it.name,
        id: it.id
      })
    }
    //add card
    list.push({
      title: item.name,
      id: item.id,
      children
    })
  }
  return list;
}


function getDownloadSPInterfaceOptions({
  menuType,
  currentChannelId,
  verificationId,
  currentProjectId,
  viewList,
  cardChannelId,
  cardDesignId,
  cardVerificationId
}) {
  let selectInfo = {}, selectAssemblyList = {}, selectList = [];
  //card
  if (viewList.includes(CARD_VERIFICATION) || viewList.includes(CARD_RESULT)) {
    //card
    const cardDesign = designConstructor.getDesign(cardDesignId);
    const cardChannels = cardDesign ? cardChannelsConstructor.getChannels(cardDesign.id) : [];
    //find current Card open channel
    const findCardChannelItem = cardChannelId ? cardChannelsConstructor.getChannel(cardChannelId) : null;
    const findCardChannel = findCardChannelItem ? findCardChannelItem.name : "All";
    //find current open channel's verifications
    const cardVerificationList = cardChannelsConstructor.getVerifications(cardChannelId) || [];
    //find current open verification
    const findV = cardVerificationList.find(item => item.id === cardVerificationId);
    const cardVerification = findV ? findV.id : "All";
    let ids = cardVerification === "All" ? [...cardVerificationList.map(item => item.id)] : [cardVerification]
    if (findCardChannel === "All") {
      ids = cardChannelsConstructor.getVerificationsByDesignId(cardDesignId).map(item => item.id);
    }
    selectInfo = {
      channelName: findCardChannel,
      verificationIds: [cardVerification],
      ids
    };
    selectAssemblyList = {
      channelName: ['All', ...cardChannels.map(item => item.name)],
      verificationIds: [findCardChannel === "All" ? {
        title: 'All',
        value: 'All',
        key: 'All'
      } : {
        title: 'All',
        value: 'All',
        key: 'All',
        children: cardVerificationList.map(item => ({ title: item.name, value: item.id, key: item.id, id: item.id, children: [] }))
      }]
    };
    selectList = cardChannels;
  } else if (viewList.includes(PCB_CHANNEL_RESULT)) {
    let channels = [];
    const channelList = projectChannels.get(currentProjectId);
    channels = channelList.map(id => PCBChannelContentConstructor.getInfo(id)) || [];
    const findChannelItem = currentChannelId ? channels.find(item => item.channelId === currentChannelId) : null;
    const findChannel = findChannelItem ? findChannelItem.channelName : "";
    selectInfo = {
      channelName: findChannel,
      projectId: currentProjectId,
      channelId: findChannelItem ? findChannelItem.channelId : '',
      dataType: PCB_CHANNEL_RESULT,
      id: findChannelItem ? findChannelItem.verificationId : "",
    };
    selectAssemblyList = {
      channelName: channels && channels.length ? [...channels.map(item => item.channelName)] : [],
    };
    selectList = channels.map(item => { return { name: item.channelName, dataType: PCB_CHANNEL, designId: item.designId, id: item.channelId, key: item.key, verificationId: item.verificationId } });
  } else {
    //interface
    //get channel list
    let channels = [];
    const channelList = projectChannels.get(currentProjectId);
    channels = channelList.map(id => rockyChannels.get(id)) || [];
    //find current open channel
    const findChannelItem = currentChannelId ? rockyChannels.get(currentChannelId) : null;
    const findChannel = findChannelItem ? findChannelItem.name : "All";
    //find current open channel's verifications
    let currentVerificationList = [];
    if (viewList.includes(MULTIPLE_CHANNEL)) {
      currentVerificationList = multipleResultConstructor.getCurrentChannelInfo(currentChannelId)
    } else {
      currentVerificationList = findChannelItem && findChannelItem.children && findChannelItem.children.length ? JSON.parse(JSON.stringify(findChannelItem.children)).filter(item => item.type !== ROCKY_PDN) : [];
    }
    //find current open verification
    const findV = currentVerificationList.find(item => item.id === verificationId);
    const verification = findV ? findV.id : "All";
    let ids = verification === "All" ? [...currentVerificationList.map(item => item.id)] : [verification];
    if (findChannel === "All") {
      ids = channels.map(item => { return item.children.filter(item => item.type !== ROCKY_PDN) }).flat(2).map(item => item.id);
    }
    selectInfo = {
      channelName: findChannel,
      verificationIds: [verification],
      ids,
      projectId: currentProjectId,
      channelId: findChannelItem ? findChannelItem.id : 'all',
    };
    selectAssemblyList = {
      channelName: ['All', ...channels.map(item => item.name)],
      verificationIds: [findChannel === "All" ? {
        title: 'All',
        value: 'All',
        key: 'All'
      } : {
        title: 'All',
        value: 'All',
        key: 'All',
        children: currentVerificationList.map(item => ({ title: item.name, value: item.id, key: item.id, id: item.id }))
      }]
    };
    selectList = channels;
  }

  return {
    selectList,
    selectInfo,
    selectAssemblyList,
    isMultiple: true,
    multiSelectedKeys: menuType === MULTIPLE_CHANNEL ? [] : ["verificationIds"]
  }
}

function getDownloadSPUpdateInterfaceInfo({
  selectAssemblyList,
  selectList,
  channelName,
  verificationIds,
  info,
  viewList,
  cardDesignId,
  currentProjectId
}) {
  const currentChannelInfo = channelName ? selectList.find(item => item.name === channelName) : null;
  let verificationList = [];
  if (viewList.includes(CARD_VERIFICATION) || viewList.includes(CARD_RESULT)) {
    verificationList = currentChannelInfo ? cardChannelsConstructor.getVerifications(currentChannelInfo.id) || [] : [];
  } else if (viewList.includes(MULTIPLE_CHANNEL)) {
    verificationList = currentChannelInfo ? multipleResultConstructor.getCurrentChannelInfo(currentChannelInfo.id) || [] : []
  } else {
    verificationList = currentChannelInfo && currentChannelInfo.children ? currentChannelInfo.children.filter(item => item.type !== ROCKY_PDN) : [];
  }
  const currentVerificationList = verificationIds && !verificationIds.includes('All') ? verificationList.filter(item => verificationIds.includes(item.id)) : null;

  const channel = currentChannelInfo ? currentChannelInfo.name : "All";
  if (viewList.includes(PCB_CHANNEL_RESULT)) {
    // pcb channel result
    if (currentChannelInfo && currentChannelInfo.id) {
      // const value = PCBChannelContentConstructor.getInfo(currentChannelInfo.id)
    }
    let _selectInfo = {
      channelName: channel,
      channelId: currentChannelInfo ? currentChannelInfo.id : '',
      projectId: currentProjectId,
      dataType: PCB_CHANNEL_RESULT,
      id: currentChannelInfo ? currentChannelInfo.verificationId : "",
    }
    return { ...info, selectInfo: _selectInfo, selectList }
  } else {
    const _verificationIds = verificationIds && !verificationIds.length ? [] : (currentVerificationList && currentVerificationList.length ? [...currentVerificationList.map(it => it.id)] : ["All"])
    let ids = _verificationIds.includes('All') ? [...verificationList.map(item => item.id)] : [..._verificationIds];
    if (channel === "All") {
      ids = viewList.includes(CARD_VERIFICATION) || viewList.includes(CARD_RESULT) ?
        cardChannelsConstructor.getVerificationsByDesignId(cardDesignId).map(item => item.id)
        : selectList.map(item => { return item.children.filter(item => item.type !== ROCKY_PDN) }).flat(2).map(item => item.id);
    }

    let _selectInfo = {
      channelName: channel,
      channelId: currentChannelInfo ? currentChannelInfo.id : 'all',
      verificationIds: _verificationIds,
      ids,
      projectId: currentProjectId
    }

    let _selectAssemblyList = {
      ...selectAssemblyList,
      verificationIds: [channel === "All" ? {
        title: 'All',
        value: 'All',
        key: 'All'
      } : {
        title: 'All',
        value: 'All',
        key: 'All',
        children: verificationList.map(item => ({ title: item.name, value: item.id, key: item.id, id: item.id }))
      }]
    }
    return { ...info, selectInfo: _selectInfo, selectAssemblyList: _selectAssemblyList, selectList }
  }
}

export {
  getDebugChannelList,
  getCardChannelList,
  getDownloadSPInterfaceOptions,
  getDownloadSPUpdateInterfaceInfo
}