import React, { Component, Fragment } from 'react'
import { EyeOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { getImpedanceJson, getDecapEffectiveRL, getHistoryDecapEffectiveRL } from '@/services/Cascade/Impedance';
import { PCB, PACKAGE } from '@/constants/treeConstants';
import { mergeDecapData } from "@/services/Cascade/result/Impedance/decapEffective"
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { selectChange, selectLayer } from '@/pages/LayoutExplorer/store/Cascade/actionCreators';
import canvas from '@/services/LayoutCanvas';
import EditableTable from '@/components/EditableTable';
import { updateSelectKeys, updateExpand, updateViewList } from '../../../store/project/action';
import './index.css'

const decapColumns = [
  {
    title: "Component",
    dataIndex: "name",
    width: "10%",
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: "Part Name",
    dataIndex: "part",
    width: "18%",
    sorter: (a, b) => a.part.localeCompare(b.part),
  },
  {
    title: "Resistance DC(mOhm)",
    dataIndex: "resistance",
    width: "18%",
    sorter: (a, b) => a.resistance - b.resistance
  },
  {
    title: "Inductance @1MHz(pH)",
    dataIndex: "inductance",
    width: '18%',
    sorter: (a, b) => a.inductance - b.inductance
  },
  {
    title: "Inductance @10MHz(pH)",
    dataIndex: "inductance_10",
    width: '18%',
    sorter: (a, b) => a.inductance_10 - b.inductance_10
  },
  {
    title: "Inductance @100MHz(pH)",
    dataIndex: "inductance_100",
    width: '18%',
    sorter: (a, b) => a.inductance_100 - b.inductance_100
  }
]

class Decap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableDataList: [],
      historyList: [],
      isMounted: false,
      designID: '',
      maxHeight: 1000,
      show: true
    }
  }

  componentDidMount = () => {
    this.setState({
      isMounted: true
    })
    this.resize()
    if (this.props.id) {
      this.getData()
    }

    if (this.props.historys) {
      this.getHistoryData()
    }

    decapColumns[0].render = (text, record) => {
      return <span onClick={() => this.viewComponents([text], record.designId, record.type)} className="aurora-cursor">{text}</span>
    }

    document.addEventListener('resize', this.resize)
  }

  componentDidUpdate = (prevProps) => {
    const { updateHistory } = this.props;
    if (updateHistory !== prevProps.updateHistory) {
      this.getHistoryData()
    }
  }

  componentWillUnmount = () => {
    this.setState({
      isMounted: false
    })
    document.removeEventListener('resize', this.resize)
  }

  resize = () => {
    const ele = document.getElementById('cascade-content-main');
    const height = (ele.clientHeight || 1145) - 145;
    this.setState({
      maxHeight: height
    })
  }

  getData = async () => {
    const { id } = this.props
    try {
      const impedanceSetup = await getImpedanceJson(id)
      const decapEffectiveRL = await getDecapEffectiveRL(id)
      const result = mergeDecapData(impedanceSetup, decapEffectiveRL)
      if (this.state.isMounted) {
        this.setState({
          tableDataList: result,
          designId: impedanceSetup.designId
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  getHistoryData = async () => {
    const { historys, id } = this.props;
    if (!historys.length) {
      this.setState({
        historyList: []
      })
      return;
    }
    try {
      let historyList = [];
      const impedanceSetup = await getImpedanceJson(id)

      for (let history of historys) {
        const decapEffectiveRL = await getHistoryDecapEffectiveRL(history.id)
        const result = mergeDecapData(impedanceSetup, decapEffectiveRL);
        historyList.push({ name: history.name, tableList: result, show: false })
      }

      this.setState({
        historyList,
      })
    } catch (error) {
      console.error(error)
    }
  }

  toggleTable = (powerNet, designName) => {
    this.setState(prevState => {
      const updateItems = prevState.tableDataList.map(item => {
        if (item.powerNet === powerNet && item.designName === designName) {
          return {
            ...item,
            showTable: !item.showTable
          }
        }
        return item
      })
      return {
        tableDataList: updateItems
      }
    })
  }

  viewStuffedDecap = (e, components, designId, type) => {
    let stuffedComps = components.map(item => item.name);
    this.viewComponents(stuffedComps, designId, type)
  }

  viewComponents = (stuffedComps, designId, type) => {
    const { viewList, selectedKeys, openProjectId, expandedKeys } = this.props;
    if (!viewList.includes(PCB) && !viewList.includes(PACKAGE)) {
      return;
    }
    const _type = type === 'pcb' ? PCB : type
    const _selectedKeys = selectedKeys.filter(k => !k.match(/PCB-|package-|card-/))
    _selectedKeys.push(`${_type}-${designId}`)
    this.props._updateSelectKeys(_selectedKeys)
    let expandedKey = `${_type}s-${openProjectId}`;
    if (!expandedKeys.includes(expandedKey)) {
      expandedKeys.push(expandedKey)
      this.props._updateExpand(expandedKeys)
    }
    const _viewList = viewList.filter(k => !k.match(/PCB|package|card/))
    _viewList.push(_type)
    this.props._updateViewList(_viewList)

    let elapsedTime = 0;
    const intervalId = setInterval(() => {
      const layout = canvas.getLayout(designId);
      if (layout) {
        clearInterval(intervalId);
        let layers = layout.findCurrentLayer(stuffedComps);
        layers = [...new Set(layers)];
        this.props._selectLayer(layers, designId);
        this.props._selectChange({ comps: [...stuffedComps] }, designId);
      } else if (elapsedTime >= 10000) {
        clearInterval(intervalId);
        return
      }
      elapsedTime += 100;
    }, 100);
  }

  showCurrent = () => {
    this.setState({
      show: !this.state.show
    })
  }

  showHistory = (name) => {
    const { historyList } = this.state;
    let _historyList = [...historyList];
    const findIndex = _historyList.findIndex(item => item.name === name);
    if (findIndex > -1) {
      _historyList[findIndex].show = !_historyList[findIndex].show
      this.setState({
        historyList: _historyList
      })
    }
  }

  showHistoryTable = (name, powerNet, designName) => {
    const { historyList } = this.state;
    let _historyList = [...historyList];
    const findIndex = _historyList.findIndex(item => item.name === name);
    if (findIndex > -1) {
      const _findIndex = _historyList[findIndex].tableList.findIndex(item => item.powerNet === powerNet && item.designName === designName);
      _historyList[findIndex].tableList[_findIndex].showTable = !_historyList[findIndex].tableList[_findIndex].showTable;
      if (_findIndex > -1) {
        this.setState({
          historyList: _historyList
        })
      }
    }
  }

  currentRender = () => {
    const { viewList } = this.props
    const { tableDataList, maxHeight, show } = this.state
    let view = viewList.includes(PCB) || viewList.includes(PACKAGE) ? true : false
    const ShowIcon = show ? DownOutlined : RightOutlined;
    return <Fragment>
      <div className='cascade-decap-content'>
        <Row className='cascade-decap-row'>
          <ShowIcon className='cascade-decap-icon' onClick={() => this.showCurrent()} />
          <span className='font-bold cascade-setup-title-color'>Current</span>
        </Row>
      </div>
      {
        show && tableDataList.map((item, key) => {
          const Icon = item.showTable ? DownOutlined : RightOutlined;
          return (
            <div className='cascade-decap-content' key={key}>
              <Row className='cascade-decap-row'>
                <Col className='cascade-decap-setup-border'>
                  <Icon className='cascade-decap-icon' onClick={() => this.toggleTable(item.powerNet, item.designName)} />
                  <span className='font-bold cascade-setup-title-color'>{`${item.powerNet}（${item.designName}）`}</span>
                  <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                    <EyeOutlined
                      className={view ? "cascade-decap-eye-icon" : "cascade-decap-eye-icon cascade-decap-eye-icon-disable"}
                      onClick={(e) => this.viewStuffedDecap(e, item.capComps, item.designId, item.type)} />
                  </Tooltip>
                  {
                    item.showTable ? <div className='space-10'>
                      <EditableTable
                        rowKey={(record) => { return record.name }}
                        columns={decapColumns}
                        dataSource={item.capComps.map(obj => {
                          return {
                            ...obj,
                            designId: item.designId,
                            type: item.type
                          };
                        })}
                        size="small"
                        className="cascade-imp-table"
                        scroll={item.capComps.length > (maxHeight / 38) ? { y: maxHeight } : {}}
                      />
                    </div> : null}
                </Col>
              </Row>
            </div>
          )
        })
      }
    </Fragment>
  }

  historyRender = () => {
    const { viewList } = this.props
    const { historyList, maxHeight } = this.state
    let view = viewList.includes(PCB) ? true : false;
    return historyList.map(item => {
      const { name, show, tableList } = item;
      const ShowIcon = show ? DownOutlined : RightOutlined
      return (
        <Fragment key={name}>
          <div className='cascade-decap-content'>
            <Row className='cascade-decap-row'>
              <ShowIcon className='cascade-decap-icon' onClick={() => this.showHistory(name)} />
              <span className='font-bold cascade-setup-title-color'>{name}</span>
            </Row>
          </div>
          {
            show && tableList.map((item, key) => {
              const ShowTableIcon = item.showTable ? DownOutlined : RightOutlined;
              return (
                <div className='cascade-decap-content' key={key}>
                  <Row className='cascade-decap-row'>
                    <Col className='cascade-decap-setup-border'>
                      <ShowTableIcon className='cascade-decap-icon' onClick={() => this.showHistoryTable(name, item.powerNet)} />
                      <span className='font-bold cascade-setup-title-color'>{`${item.powerNet}（${item.designName}）`}</span>
                      <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                        <EyeOutlined
                          className={view ? "cascade-decap-eye-icon" : "cascade-decap-eye-icon cascade-decap-eye-icon-disable"}
                          onClick={(e) => this.viewStuffedDecap(e, item.capComps, item.designId, item.type)} />
                      </Tooltip>
                      {
                        item.showTable ? <div className='space-10'>
                          <EditableTable
                            rowKey={(record) => { return record.name }}
                            columns={decapColumns}
                            dataSource={item.capComps.map(obj => {
                              return {
                                ...obj,
                                designId: item.designId,
                                type: item.type
                              };
                            })}
                            size="small"
                            className="cascade-imp-table"
                            scroll={item.capComps.length > (maxHeight / 38) ? { y: maxHeight } : {}}
                          />
                        </div> : null}
                    </Col>
                  </Row>
                </div>
              );
            })
          }
        </Fragment>
      );
    });
  }

  render() {
    return (
      <div className='cascade-decap-main'>
        {this.currentRender()}
        {this.historyRender()}
      </div>
    )
  }
}

const mapState = (state) => {
  const { project: { viewList, openProjectId, selectedKeys, expandedKeys } } = state.CascadeReducer
  return {
    viewList,
    openProjectId,
    selectedKeys,
    selectedIDs: getSelectedDesignIDs(selectedKeys),
    expandedKeys,
  }
}

const mapDispatch = (dispatch) => ({
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _updateSelectKeys(selectedKeys) {
    dispatch(updateSelectKeys(selectedKeys))
  },
  _updateExpand(expandedKeys) {
    dispatch(updateExpand(expandedKeys))
  },
  _updateViewList(viewList) {
    dispatch(updateViewList(viewList))
  }
})
export default connect(mapState, mapDispatch)(Decap)
