export const DiffMode = [
  {
    name: 'Insertion Loss',
    value: 'IL'
  }, {
    name: 'Return Loss',
    value: 'RL'
  }, {
    name: 'NEXT',
    value: 'NEXT'
  }, {
    name: 'FEXT',
    value: 'FEXT'
  }
];

export const MixedMode = [
  {
    name: 'Diff to Comm',
    value: 'DiffToComm'
  },
  {
    name: 'Intra-Lane cross-coupling',
    value: 'CrossCoupling'
  },
  {
    name: 'All',
    value: 'Default'
  }
];

export const CommModel = [{
  name: 'Insertion Loss',
  value: 'COMMIL'
}, {
  name: 'Return Loss',
  value: 'COMMRL'
}]

export const Mode = [{
  title: 'Diff Mode',
  children: DiffMode
}, {
  title: 'Comm Mode',
  children: CommModel
}, {
  title: 'Mixed Mode',
  children: MixedMode
}]