import React, { Component, Fragment } from 'react';
import { Checkbox } from 'antd';
import { AMISignalPRBSConfig, TerminationModelConfig } from '@/services/Andes_v2/AMIModelHelper';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import { SPICE } from '@/constants/libraryConstants';
import { getFileContent } from '@/services/Andes_v2/library';
import NetListModel from '@/components/NetListModel';
import { ANDES_V2 } from '../../../../constants/pageType';
import { parseSpiceModelSelector } from "@/services/LibraryHelper/SpiceModelHelper";
import IbisEQ from '../IbisEQ';
import JittersTable from '../jittersTable';
import { IBIS } from '@/constants/libraryConstants';
import { setDefaultNoAmiJitters } from '../../../../services/Andes_v2/AMIModelHelper/IntegratedConfig';
import { getValueFormEquation } from '@/services/Andes_v2/AMIModelHelper/equationCount';
import "./index.css";
import { END_TO_END_CHANNEL } from '../../../../constants/treeConstants';

const jittersColumns = [
  {
    key: "name",
    title: "Name",
    width: 140
  },
  {
    key: "type",
    title: "Type",
    width: 100
  },
  {
    key: "value",
    title: "Value"
  }
];
class TerminationSetup extends Component {
  constructor(props) {
    super(props);
    const { modelInfo: { model, component, prbs, interfaceType, channelId, dirType }, serdesType } = props;
    if (model && !model.jitters) { model.jitters = setDefaultNoAmiJitters(dirType) }
    this.state = {
      applyAll: false,
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new TerminationModelConfig({
        type: interfaceType,
        component,
        channelId,
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
      terminationList: [],
      pins: []
    }
    this.selectModels = [];
    this.components = [];
    this.fileInfo = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.defaultModel();
  }

  componentDidUpdate = (prevProps) => {
    const { modelInfo: { signal, dirType } } = this.props;
    const prevSignal = prevProps.modelInfo ? prevProps.modelInfo.signal : null;
    const prevDirType = prevProps.modelInfo ? prevProps.modelInfo.dirType : null;

    if ((prevSignal !== signal) || (dirType !== prevDirType)) {
      this.defaultModel();
    }
  }

  closeModal = () => {
    const { applyAll, prbs, model } = this.state;
    const { modelInfo: { dirType, signal, component, channelId, interfaceType } } = this.props;

    let _model = {};
    if (this.modelChild) {
      _model = this.modelChild.getNetListModel();
    }
    delete _model.version;
    _model.component = component;
    if (interfaceType === END_TO_END_CHANNEL) {
      _model.channelId = channelId;
    }
    if (model && model.EQ) {
      _model.EQ = getValueFormEquation(model.EQ);
    }
    if (model && model.jitters) {
      _model.jitters = model.jitters;
    }
    this.props.closeModal({ model: _model, prbs, signal, dirType, applyAll, type: 'rcModel' });
  }

  defaultModel = () => {
    const { modelInfo: { model, component, prbs, channelId, interfaceType }, serdesType } = this.props;
    this.fileInfo = {};

    const fileList = libraryConstructor.getLibraryValues(SPICE) || [];

    this.setState({
      terminationList: fileList,
    })

    this.setState({
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new TerminationModelConfig({
        type: interfaceType,
        component,
        channelId,
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
    })
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  applyChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  modelEQSRender = (model) => {
    const { modelInfo: { dirType }, bitRate, prbsType, serdesType } = this.props;
    return <IbisEQ
      model={model}
      dirType={dirType}
      error={this.state.error}
      bitRate={bitRate}
      saveModel={this.saveModel}
      updateError={this.updateError}
      prbsType={prbsType}
      serdesType={serdesType}
    />
  }

  modelJittersRender = (_model) => {
    const { bitRate, modelInfo } = this.props;
    return <JittersTable
      model={_model}
      type={IBIS}
      modelInfo={modelInfo}
      jitters={_model.jitters}
      bitRate={bitRate}
      jittersColumns={jittersColumns}
      saveModel={this.saveModel}
      updateError={this.updateError}
    />
  }

  saveModel = (model) => {
    this.setState({
      model
    })
  }

  updateError = (error) => {
    this.setState({
      error
    })
  }

  render() {
    const { modelInfo } = this.props;
    const { dirType, pins } = modelInfo
    const { model, applyAll, error, terminationList } = this.state;
    return <Fragment>
      <NetListModel
        onRef={this.onRef}
        pins={pins}
        spiceList={terminationList}
        product={ANDES_V2}
        modelType={"Termination"}
        model={{ type: "Termination", ...model }}
        getFileContent={getFileContent}
        parseModelSelector={parseSpiceModelSelector}>
      </NetListModel>
      {model.jitters && model.jitters.length ? this.modelJittersRender(model) : null}
      {model.EQ ? this.modelEQSRender(model) : null}
      {error ? <span className={error.errorType === "warning" ? "aurora-model-name-warning-msg" : "aurora-error-msg-span"}>{error.error}</span> : null}
      {<div className="ads-model-apply-all-content">
        <span>Apply setting to all {dirType} Model</span>
        <Checkbox
          checked={applyAll}
          onChange={(e) => this.applyChange(e)}
        />
      </div>}
    </Fragment>
  }
}


export default TerminationSetup;