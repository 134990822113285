import React, { PureComponent, createRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Tooltip, Menu } from 'antd';
import { connect } from 'react-redux';
import permissionData from '@/services/helper/data/permissionData';
import BottomLine from '../BottomLine';
import { MONITOR, PCB, PROFILE, DETAIL, CCC, getMenuListByPageType, SERVICE, LAYOUT } from '../../services/helper/tabSwitchHelper';
import { ANDES_V2, CASCADE, HIMALAYAS, ROCKY } from '../../constants/pageType';
import { getCCCMsg, stopServerList } from '../../pages/CCC/store/action';
import './index.css';

class TabFooter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 'simulation_monitor',
      menuWidth: null,
      siderHeight: null

    };
    this.contentRef = createRef();
    this.menuRef = createRef();
    this.siderRef = createRef();
  }

  closeTabFooter = (e) => {
    e.stopPropagation();
    const { closeTabFooter } = this.props;
    closeTabFooter();
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  componentWillMount = () => {
    this.screenChange()
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    const siderHeight = this.siderRef.current.offsetHeight;
    this.setState({ siderHeight })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { tabSelectKeys } = this.props;

    const siderHeight = this.siderRef.current.offsetHeight;
    if (prevState.siderHeight !== siderHeight) {
      this.setState({
        siderHeight
      })
    }

    const selectKey = this.getCurrentMenu(tabSelectKeys),
      prevSelectKey = this.getCurrentMenu(prevProps.tabSelectKeys);
    if (selectKey !== SERVICE && prevSelectKey === SERVICE) {
      this.props._stopServerList();
    }
  }


  resize = () => {
    const siderHeight = this.siderRef && this.siderRef.current ? this.siderRef.current.offsetHeight : null;
    this.setState({ siderHeight })
  }

  changeMenu = ({ key }) => {
    this.props.changeTabMenu({ selectKeys: [key] });
    if (key === 'ccc') {
      this.props._getCCCMsg();
    }
  }

  getMenuItem = (item) => {
    return { key: item.index, label: <span>{item.name}</span> }
  }

  getCurrentMenu = (tabSelectKeys) => {
    const selectedKey = tabSelectKeys.length ? tabSelectKeys[0] : "monitor";
    switch (selectedKey) {
      case PCB:
        return PCB;
      case MONITOR:
        return MONITOR;
      case DETAIL:
        return DETAIL;
      case PROFILE:
        return PROFILE;
      case CCC:
        return CCC;
      case SERVICE:
        return SERVICE;
      case LAYOUT:
        return LAYOUT
      default: return null;
    }
  }


  getMenuList = (menuList, currentMenu, siderHeight, siderType) => {
    if (siderType === "default") {
      return { menus: [], subMenus: [] }
    }
    const maxIndex = Math.floor(siderHeight / 40) - 2;
    const unSelectedMenu = menuList.filter((item, index) => item.index !== currentMenu);
    const menus = unSelectedMenu.filter((item, index) => index < maxIndex);
    const subMenus = unSelectedMenu.filter((item, index) => index >= maxIndex);
    return { menus, subMenus };
  }

  render() {
    const { height, linePosition = {}, children, tabSelectKeys = [], changeSize, pageType, drageable = true } = this.props;
    const { siderHeight } = this.state;
    const currentMenu = this.getCurrentMenu(tabSelectKeys);
    const hasCCC = permissionData.hasCCC();
    const menuList = getMenuListByPageType(pageType, hasCCC);
    const siderType = siderHeight > (menuList.length * 40) ? 'default' : 'subMenu';
    const currentMenuName = currentMenu ?  menuList.find(item => item.index === currentMenu) || null : null;

    const { menus, subMenus } = this.getMenuList(menuList, currentMenu, siderHeight, siderType)
    return (
      <div className='tab-footer' style={{ height: height }} ref={this.contentRef}>
        {drageable && <BottomLine
          position={linePosition}
          changeHeight={this.props.changeHeight}
          height={height}
          resize={changeSize}
        />}
        <div className='tab-header'>
          <div className='tab-right-close'>
            <div className='tab-button-close tab-button'>
              <Tooltip title='Close' overlayClassName='icon-tooltip'>
                <CloseOutlined onClick={(e) => this.closeTabFooter(e)} />
              </Tooltip>
            </div>
          </div>
          <div style={{ position: 'static', float: 'right', width: 'calc(100% - 48px)' }}>
            {children}
          </div>
        </div>
        <div className='tab-sider' ref={this.siderRef}>
          {(siderType === 'default') &&
            <Menu className='tab-menu'
              onClick={this.changeMenu}
              selectedKeys={tabSelectKeys}
              multiple={true}
              items={menuList.map(item => this.getMenuItem(item))}
            />
          }
          {siderType === 'subMenu' &&
            <Menu className='tab-menu'
              onClick={this.changeMenu}
              selectedKeys={tabSelectKeys}
              multiple={true}
              items={[
                {
                  key: currentMenu,
                  label: currentMenuName ? currentMenuName.name : null
                },
                ...menus.map(item => this.getMenuItem(item)),
                {
                  key: 'subMenu',
                  label: <span className='tab-subMenu-title'>. . .</span>,
                  popupClassName: 'tab-subMenu-children',
                  className: 'tab-sub-menu',
                  children: subMenus.map(item => this.getMenuItem(item))
                }
              ]}
            />
          }
        </div>
      </div>
    );
  }
}


const mapState = (state) => {
  const { pageType } = state.LoginReducer;
  let tabSelectKeys = [MONITOR], menuType = "simulation";

  if ([ROCKY, ANDES_V2, CASCADE, HIMALAYAS].includes(pageType)) {
    const { monitorScreenInfo } = state.MonitorInfoReducer;
    tabSelectKeys = monitorScreenInfo.tabSelectKeys;
    menuType = monitorScreenInfo.menuType;
  } else {
    const { TabMonitorReducer } = state;
    tabSelectKeys = TabMonitorReducer.tabSelectKeys;
    menuType = TabMonitorReducer.menuType;
  }

  return {
    pageType,
    tabSelectKeys,
    menuType
  }
}

const mapDispatch = (dispatch) => ({
  _getCCCMsg() {
    dispatch(getCCCMsg())
  },
  _stopServerList() {
    dispatch(stopServerList());
  }
})

export default connect(mapState, mapDispatch)(TabFooter);