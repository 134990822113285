import {
  getVerificationContentByID, updateRockyVerificationContent, getRockyResultHaveTrain,
  getHavePackageVerification, getPackageExtraction, updatePackageExtraction, updatePackageVerification, getPackageVerificationList,
  getPackageVerificationInfo, deletePackageVerification, changePackageVerification, renamePackageChannel, getPackageResultInfo, getDimmCardRankIdById,
  getPackagePDN, getPackagePDNInfo, updatePackagePDNInfo, getPackagePDNNpiFile, getPackagePDNResult, getPackagePDNCurves, getDecapEffectiveRLfileById, getImpedanceRelated, getPackagePDNJson,
  createModelingInfo, getModelingList, deleteModelingInfo, getPCBChannelContent, updatePCBChannelContent, getPCBChannelResultContent,
  getPreLayoutResultJson, createPackageMultiInterface
} from '../api/Rocky/rockyCtrl';
import { getVixSpec } from '../api/Rocky/mask';
import { uploadPCB } from '../api/Rocky/rockyDesign';
import apiProcess from '../api/utility';

/**
 * Get verification content by verification id
 *
 * @export
 * @param {string} verificationId
 * @returns
 */
export function getVerificationContentPromise(verificationId) {
  return new Promise((resolve, reject) => {
    apiProcess(getVerificationContentByID, verificationId).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
      console.error(error);
    })
  })
};

/**
 * Get dimm card rankId by dimm card model id and type
 *
 * @export
 * @param {num} id
 * @param {string} type
 * @returns
 */
export function getDimmCardRankIdPromise({ id, type }) {
  return apiProcess(getDimmCardRankIdById, { id, type })
};

/**
 * update rocky verification info
 *
 * @export
 * @param {string} id  verificationId
 * @param {string} name
 * @param {array[object]} interfaces:[{},{}]
 * @param {num} readyForSim  0/1
 * @param {string} version  setup version
 * @param {string} designVersion
 * @param {object} config
 * @param {string} ifDoExtraction 0/1
 * @returns
 */
export function updateVerificationContent({ id, name, interfaces, config, ifDoExtraction, readyForSim, version, designVersion }) {
  return new Promise((resolve, reject) => {
    apiProcess(updateRockyVerificationContent, { id, name, interfaces, config, ifDoExtraction, readyForSim, version, designVersion }).then(res => {
      resolve(res);
    }, error => {
      console.error(error);
      resolve(null);
    })
  })
};

export function uploadPCBPromise(params) {
  return apiProcess(uploadPCB, params);
}

export function getRockyResultHaveTrainPromise(verificationId) {
  return apiProcess(getRockyResultHaveTrain, verificationId);
}

export function getHavePackageVerificationPromise({ designId, verificationId, category }) {
  return apiProcess(getHavePackageVerification, { designId, verificationId, category })
}

export function getPackageExtractionPromise(designId) {
  return apiProcess(getPackageExtraction, designId)
}

export function updatePackageExtractionPromise({ designId, extractionConfig }) {
  return apiProcess(updatePackageExtraction, { designId, extractionConfig })
}

export function updatePackageVerificationPromise(packageVerificationList) {
  return apiProcess(updatePackageVerification, packageVerificationList)
}

export function getPackageVerificationListPromise(designId) {
  return apiProcess(getPackageVerificationList, designId)
}

export function getPackageVerificationInfoPromise(packageVerificationId) {
  return apiProcess(getPackageVerificationInfo, packageVerificationId)
}

export function deletePackageVerificationPromise(channelId) {
  return apiProcess(deletePackageVerification, channelId)
}

export function changePackageVerificationPromise(name, packageChannelId) {
  return apiProcess(changePackageVerification, { name, packageChannelId })
}

export function renamePackageChannelPromise(newName, oldName, packageId) {
  return apiProcess(renamePackageChannel, { newName, oldName, packageId })
}

export function getPackageResultInfoPromise(packageVerificationId) {
  return new Promise((resolve, reject) => {
    getPackageResultInfo(packageVerificationId).then(res => {
      resolve(res)
    }, error => {
      resolve(null);
    })
  })
}

export function getRockyPackagePDN(designId) {
  return apiProcess(getPackagePDN, designId)
}

export function getRockyPackagePDNInfo(id) {
  return apiProcess(getPackagePDNInfo, id)
}

export function updateRockyPackagePDNInfo({ id, name, content }) {
  return apiProcess(updatePackagePDNInfo, { id, name, content })
}

export function getRockyPackagePDNResult({ pdnId, verificationId }) {
  return apiProcess(getPackagePDNResult, { pdnId, verificationId })
}

export function getRockyPackagePDNNpiFile({ pdnId, verificationId }, fileName) {
  return apiProcess(getPackagePDNNpiFile, { verificationId, fileName, pdnId }, false, false, true)
}

export function getRockyImpedanceCurves({ verificationId, rows, cols, type, fileName, ssnVerificationId }) {
  return apiProcess(getPackagePDNCurves, { verificationId, rows, cols, type, fileName, ssnVerificationId })
}

export function getRockyDecapfile(verificationId) {
  return apiProcess(getDecapEffectiveRLfileById, verificationId, false, false, true)
}

export function getRockyImpedanceRelated(pdnId, verificationId) {
  return apiProcess(getImpedanceRelated, { pdnId, verificationId })
}

export function getRockyPackagePDNJson(verificationId) {
  return apiProcess(getPackagePDNJson, verificationId)
}

export function createModelingInfoPromise(verificationIds) {
  return apiProcess(createModelingInfo, verificationIds)
}

export function getModelingListPromise(channelId) {
  return apiProcess(getModelingList, channelId)
}

export function deleteModelingInfoPromise({ channelId, verificationIds }) {
  return apiProcess(deleteModelingInfo, { channelId, verificationIds })
}

export function getPCBChannelContentPromise({ channelId }) {
  return apiProcess(getPCBChannelContent, channelId)
}

export function updatePCBChannelContentPromise(info) {
  return apiProcess(updatePCBChannelContent, info)
}

export function getPCBChannelResultContentPromise(verificationId) {
  return apiProcess(getPCBChannelResultContent, verificationId)
}

export function getVixSpecPromise(type) {
  return apiProcess(getVixSpec, type)
}

export function getPreLayoutResultJsonPromise(verificationId) {
  return new Promise((resolve, reject) => {
    getPreLayoutResultJson(verificationId).then(response => {
      if (response && response.data) {
        resolve({ ...response.data });
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  });
}

export function createPackageMultiInterfacePromise(channelIds) {
  return apiProcess(createPackageMultiInterface, channelIds)
}