import {
  PCB_SUBCKT,
  PVT,
  SOC_SPICE,
  SOC_WRAPPER,
  SPICE_PARAMETERS,
  SPICE_PARAMETERS_LIB
} from "../../../constants/libraryConstants";
import { getLibraryDetail } from "./libraryCtrl";

export const libraryTypeList = [
  SPICE_PARAMETERS,
  SPICE_PARAMETERS_LIB,
  SOC_SPICE,
  SOC_WRAPPER,
  PCB_SUBCKT,
  PVT
];

class LibraryConstructor {
  constructor() {
    for (let item of libraryTypeList) {
      this[item] = new Map(); //key - library id, value :{ id ,name, type, version ,config };
    }
  }

  getSize = (type) => {
    return this[type].size;
  };

  getLibraryList = (type) => {
    return this[type];
  }

  getLibraryValues = (type) => {
    let list = [];
    if (!this[type]) {
      return [];
    }
    for (let val of this[type].values() || []) {
      list.push(val);
    }
    return list;
  }

  getLibrary = (type, id) => {
    return this[type].get(id);
  }

  addLibrary = (type, id, data) => {
    this[type].set(id, data);
  }

  delLibrary = (type, id) => {
    this[type].delete(id);
  }

  checkFile = (type, libraryId) => {
    if (this[type].get(libraryId)) {
      return true;
    } else {
      return false;
    }
  }

  getLibraryContent(id, type) {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(null);
        return;
      }
      const library = this.getLibrary(type, id);
      if (!library || !library.config) {

        getLibraryDetail(id).then(res => {
          this.addLibrary(type, id, res);
          resolve(res);
        })
      } else {
        resolve(library);
      }
    })
  }

  clearCache = (type) => {
    this[type] = new Map();
  }

  clearAllCache = () => {
    for (let item of libraryTypeList) {
      this[item] = new Map(); //key - library id, value :{ id ,name, type, version ,config };
    }
  }
};

let libraryConstructor = new LibraryConstructor();
export default libraryConstructor;