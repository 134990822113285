import React, { PureComponent } from 'react';
import PDN from './PDNSetting';
import TopBar from './PDNSetting/TopBar';
import { topBarResize } from '@/services/helper/topBarResize';
import Result from '../result'
import projectDesigns from '@/services/helper/projectDesigns';
import { PDN as PDNConst, RESULT, PCB } from '../constants';
import ContentLayoutTB from '@/components/ContentLayout/layoutTB';
import PCBLayout from './pcbLayout';

class LayoutTB extends PureComponent {
  topBarResize = () => {
    topBarResize('pdn-top-bar-block', 650);
  }

  getBottomSetup = (width) => {
    const { viewList } = this.props;
    const views = viewList.filter(item => ![PCB].includes(item));
    let content = null, className = '';
    switch (views[0]) {
      case RESULT:
        content = <div id='pdn-content-main'
          style={{ height: '100%' }}
        >
          <Result layoutStatus={true} leftWidth={width} layoutLeftWidth={width} />
        </div>
        className = 'fastpi-tb-result-main';
        break;
      case PDNConst:
        content = <div id='pdn-content-main'>
          <PDN />
        </div>
        className = 'fastpi-tb-setup-main';
        break;
      default: return;
    }
    setTimeout(() => {
      this.topBarResize();
    }, 100)
    return <div
      className={className}
      style={{ width }}
    >
      {content}
    </div>
  }

  getTop = (height) => {
    const { leftWidth, viewList, currentProjectId } = this.props;
    if (viewList.includes(PCB) && projectDesigns.getAvailableDesignsLength(currentProjectId) > 0) {
      return <PCBLayout siderWidth={leftWidth} bottomHeight={height} />
    };
    return null;
  }

  topBar = (width) => {
    return <TopBar width={width} />
  }

  render() {
    return (
      <ContentLayoutTB
        {...this.props}
        id={"pdn-content-topbottom"}
        bottomClassName={"pdn-content-bottom"}
        topBarResize={this.topBarResize}
        getTop={this.getTop}
        getBottomSetup={this.getBottomSetup}
        topBar={this.topBar}
      />
    )
  }
};

export default LayoutTB;
