import { IC, CONNECTOR, RES } from '../PCBHelper';
import SchematicCanvas from './canvas';
import ElementsCanvas from './elementsCanvas';

const IC_COLOR = "#6495ed",
  CONNECTOR_COLOR = "#9998ff",
  RES_COLOR = "#f4b183";
function getColor(type) {
  switch (type) {
    case IC: return IC_COLOR;
    case CONNECTOR: return CONNECTOR_COLOR;
    case RES: return RES_COLOR;
    default: return;
  }
}

function getTitle(type) {

  switch (type) {
    case IC: return IC;
    case CONNECTOR: return CONNECTOR;
    case RES: return "Pull Up Resistor";
    default: return;
  }
}

export {
  SchematicCanvas,
  ElementsCanvas,
  getColor,
  getTitle
}
