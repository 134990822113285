import React, { Component, Fragment, } from 'react';
import "../../publicCss/style.css";
import './index.css';

class ErrorCheck extends Component {

  render() {
    const { error } = this.props;
    return (
      <Fragment>
        {error && error.length ?
          <Fragment>
            <pre className="error-check-warning-dialog">
              {error.map((item, index) => {
                return <Fragment key={index}>
                  <p style={{ margin: 0 }}>
                    {item.title}
                    {item.boldKey ? <span className="font-bold"> "{item.boldKey}"</span> : null}
                    &nbsp;{item.errorMsg}
                  </p>
                </Fragment>
              })}
            </pre>
          </Fragment>
          : null}
      </Fragment>
    )
  }
}

export default ErrorCheck;