import {
  VERIFY_NEVER,
  VERIFY_RUNNING,
  WAITING,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  VERIFY_CANCEL
} from '@/constants/verificationStatus'

function getWaitingMsg(waitingIndex) {
  if (waitingIndex < 0) {
    return null;
  }
  switch (waitingIndex) {
    case 0:
      return "==> The current task is on the top of the waiting queue and ready to run.";
    case 1:
      return "==> The current task is in the waiting queue and there is one task ahead.";
    default:
      return `==> The current task is in the waiting queue and there are ${waitingIndex} tasks ahead.`;
  }
}

/** 
 * is simulation running or waiting
 */
const NO_SIMULATION = 0, SIMULATION_RUNNING = 1, SIMULATION_WAITING = 2;
function getSimulationStatus({ waitingIndex, progress }) {
  if (progress > 0 || waitingIndex > -1) {
    return waitingIndex > -1 ? SIMULATION_WAITING : SIMULATION_RUNNING;
  } else {
    return NO_SIMULATION;
  }
}

/** 
 * get tree verification status
 * // rocky / andes v2
 */
function getVerificationTreeStatus(status, readyForSim, verification) {
  let verificationStatus = '', msg = '', color = '';
  if (verification.simStatus) {
    switch (verification.simStatus) {
      case 'checking':
        verificationStatus = 'loading3'; msg = 'Checking for simulation'; color = '#ff5500'; break;
      case 'success':
        verificationStatus = 'check-circle'; msg = 'Checked Successful'; color = '#4dbd16'; break;
      case "error":
      default: verificationStatus = ''; msg = ''; color = ''; break;
    }
    return { verificationStatus, msg, color }
  }

  switch (status) {
    case VERIFY_NEVER:
      if (verification.version && verification.version !== "0" && readyForSim === 1) {
        verificationStatus = 'icon_tab_already';
        msg = 'Prepared for simulation';
        color = "#539ef9"
      }
      break;
    case VERIFY_RUNNING:
      verificationStatus = 'zhengzaiyunhang'; msg = 'Running'; color = '#31aaf3'; break;
    case WAITING:
      verificationStatus = 'Waiting'; msg = 'Waiting'; color = '#ff5500'; break;
    default:
      if (readyForSim === 1) {
        verificationStatus = 'icon_tab_already';
        msg = 'Prepared for simulation';
        color = "#539ef9"
      }
      break;
  }

  return { verificationStatus, msg, color }
}

function getStartMsg(monitorInfo) {
  if (!monitorInfo) {
    return null;
  }
  switch (monitorInfo.status) {
    case VERIFY_RUNNING:
      return "==> Simulation running...\n==> Data processing...";
    case WAITING:
      return "==> Simulation waiting...";
    default: return "";
  }
}

function getLogListDisplay(props) {
  if ((props.waitingIndex > -1 || props.progress > -1) && props.monitorList && props.monitorList.length) {
    return true;
  }

  const { errorCheckList, setupUpdateMsgList, error } = props;

  if (setupUpdateMsgList && setupUpdateMsgList.length) {
    return false;
  }
  if (errorCheckList && errorCheckList.length) {
    return false;
  }

  if (error && error.length) {
    return false;
  }
  return true;
}

function getSelectedVerification(verificationName, verifications, verificationKey) {
  const verification = verifications.find(item => item[verificationKey] === verificationName);
  let verificationLabel = verificationName;

  if (verification) {
    verificationLabel = verification[verificationKey] || verificationName;
  }

  return verificationLabel;
}


function getSelectedMonitorMenu(displayMonitorList, monitorMenu) {
  const selected = displayMonitorList ? displayMonitorList.find(item => item.verificationId === monitorMenu) : null;
  let selectedObj = { verificationId: "", displayName: "", name: "" };

  if (selected) {
    selectedObj = { ...selected };
  }

  return { key: selectedObj.verificationId, label: selectedObj.displayName, hoverName: selectedObj.name };
}

function workflowJudge({ selectedKeys, verificationId, verificationKey, resultKey, monitorVerificationId }) {
  if (selectedKeys.includes(verificationKey) || selectedKeys.includes(resultKey)) {
    return monitorVerificationId === verificationId ? false : true;
  } else if (monitorVerificationId === verificationId) {
    //not open current verification,switch monitor to current verification
    return false;
  }
  return true;
}


function isSimulationRunning(waitingIndex, progress) {
  if (waitingIndex > 0 || progress > -1) {
    return true;
  }
  return false;
}

function getMessageByStatus(status) {
  switch (status) {
    case VERIFY_RUNNING:
      return "Running...";
    case VERIFY_SUCCESS:
      return "Success";
    case WAITING:
      return "Waiting...";
    case VERIFY_FAILED:
      return "Failed";
    case VERIFY_CANCEL:
      return "Cancelled";
    default: return "Not Running";
  }
}

export {
  getWaitingMsg,
  NO_SIMULATION,
  SIMULATION_RUNNING,
  SIMULATION_WAITING,
  getSimulationStatus,
  getVerificationTreeStatus,
  getStartMsg,
  getLogListDisplay,
  getSelectedVerification,
  getSelectedMonitorMenu,
  workflowJudge,
  isSimulationRunning,
  getMessageByStatus
}