import React, { Fragment } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Tooltip, Select, Progress } from 'antd';
import {
  getSimulationStatus,
  NO_SIMULATION,
} from '@/services/workflow/workflowHelper';

const Option = Select.Option;

function TitleRender({
  topWidth,
  waitingIndex,
  progress,
  monitorTree,
  selectedKey,
  hoverOption,
  displayTooltip,
  waitingTime = "",
  displayList
}) {

  const status = getSimulationStatus({ waitingIndex, progress });
  const display = (topWidth > 700 || (status === NO_SIMULATION && topWidth > 360));
  const { displayMonitor, displayTitleTooltip } = getMonitorDisplay({ monitorTree, selectedKey, hoverOption, displayTooltip });
  const _displayTitleTooltip = displayList ? getDisplayTooltip(displayList) : displayTitleTooltip;

  return (
    <Fragment>
      <div>
        <Tooltip
          title={topWidth <= 1000 ? _displayTitleTooltip : null}
          overlayClassName='icon-tooltip display-inline-flex'
          placement="topLeft"
        >
          {display ?
            <div className='aurora-simulation-msg' style={{ width: '100%' }}>
              {displayMonitor.map(displayItem => displayItem)}
            </div>
            : <div className='span-msg-icon-div'><InfoCircleOutlined className='span-msg-icon' /></div>}
        </Tooltip>
      </div>
      {
        progress > -1 ? <div className='aurora-simulation-middle'>
          <Col className='aurora-simulation-progress' span={22}>
            <Progress
              size={{ height: 14 }}
              strokeColor={'#1890ff'}
              percent={progress}
              className="simulation-progress-bar"
            />
          </Col>
        </div> : ((waitingIndex >= 0) &&
          <Col className='aurora-simulation-middle' span={topWidth <= 528 && topWidth >= 438 ? 14 : topWidth < 438 ? 16 : 10}>
            {topWidth > 777 && <span className='simulation-waiting-title'> Simulation waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
            {topWidth <= 777 && <span className='simulation-waiting-title'>Waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
          </Col>)
      }
    </Fragment>
  );
}

/**
 @param {object} monitorTree: {
  @param {String} type (project, Interface, etc.)
  @param {String} key
  @param {String} name
  @param {boolean} isSelect
  if(isSelect) {
    @param {function} changeSelected
  }
 }
 @return {
  {ReactNode} displayMonitor
  {String} displayTitleTooltip (projectType + projectName ...)
 }
 */

function getMonitorDisplay({ monitorTree, selectedKey, hoverOption, displayTooltip }) {
  let displayMonitor = [], displayTitleTooltip = "";
  for (let treeItem of monitorTree) {
    if (selectedKey.includes(treeItem.key)) {
      displayMonitor.push(
        <div className='aurora-simulation-span aurora-simulation-project' key={treeItem.key}>
          {treeItem.type ? <span className='span-msg-project font-bold'>{treeItem.type}&nbsp;&nbsp;</span> : null}
          {treeItem.isSelect ? <Select
            className='aurora-simulation-monitor-select'
            value={treeItem.key}
            onChange={treeItem.changeSelected}
            popupMatchSelectWidth={false}
          >
            {monitorTree.map(item => <Option
              value={item.key}
              key={item.key}
              onMouseEnter={() => { displayTooltip && displayTooltip(item.key) }}
              onMouseLeave={() => { displayTooltip && displayTooltip() }}
            >
              {hoverOption === item.key && !selectedKey.includes(item.key) && < Tooltip
                title={item.tooltip ? item.tooltip : item.name}
                overlayClassName='icon-tooltip'
                placement='left'
                defaultVisible={true}
                getPopupContainer={() => document.getElementsByClassName('tab-footer')[0]}
              />}
              {item.title ? item.title : item.name}
            </Option>)
            }
          </Select>
            : <span className='aurora-span-msg'>{treeItem.name}</span>}
        </div>
      )
      displayTitleTooltip = [<span
        style={{ textIndent: 8, display: 'inline-flex' }}
        key={`${treeItem.type}-${treeItem.name}`}
      >
        {treeItem.type && <span className='font-bold'>{treeItem.type} </span>}
        <span>{treeItem.name}</span>
      </span>]
      if (treeItem.children && treeItem.children.length) {
        const childrenTree = getMonitorDisplay({ monitorTree: treeItem.children, selectedKey });
        displayMonitor = [...displayMonitor, ...childrenTree.displayMonitor];
        displayTitleTooltip = [...displayTitleTooltip, ...childrenTree.displayTitleTooltip];
      }
    }
  }
  return { displayMonitor, displayTitleTooltip };
}

function getDisplayTooltip(displayList) {
  if (!displayList.length) {
    return null;
  }
  return displayList.map(item => <span
    style={{ textIndent: 8, display: 'inline-flex' }}
    key={`${item.type}-${item.name}`}
  >
    {item.type && <span className='font-bold'>{item.type} </span>}
    <span>{item.name}</span>
  </span>);
}

export {
  TitleRender
}