import React, { Component, Fragment } from "react";
import { ExclamationCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Dropdown, Tooltip } from "antd";
import { connect } from 'react-redux';
import {
  saveCurrentPCBKey
} from './store/action';
import { getPCBUploadList } from "@/services/PCBHelper/pcbHelper";
import { DESIGN_FAILED } from '@/constants/designCategory';
import { PRE_LAYOUT } from '@/constants/designVendor';
import DesignUpload from './designUpload';
import { strDelimited } from "@/services/helper/split";
import { createPreLayout } from "../../store/preLayout/action";
import { UPLOAD_PCB, REPLACE_PCB } from "@/constants/treeConstants";
import { ANDES_V2 } from "@/constants/pageType";
import { PACKAGES } from "../../../../constants/treeConstants";
import '@/publicCss/uploadPCB.css';

class UploadPCB extends Component {

  constructor(props) {
    super(props);
    const { key } = props.data;
    const { type } = props;
    const [pcbType, projectId] = strDelimited(key, "-");
    this.projectID = type === UPLOAD_PCB ? projectId : props.data.projectId;
    this.state = {
      acceptFormat: null,
      vendor: null
    }

    this.pcbType = pcbType;
    this.pcbList = getPCBUploadList(pcbType === PACKAGES ? pcbType : ANDES_V2, ANDES_V2);
  }

  uploadPCBClick = (e, vendor, format, update) => {
    e.domEvent.stopPropagation();
    this.setState({
      vendor
    }, () => {
      if (vendor === PRE_LAYOUT) {
        this.props._createPreLayout(this.pcbType);
      } else {
        this.child && this.child.uploadPCB(vendor, format, update, this.pcbType);
      }
    })
  }

  addClick = (e, key) => {
    e.stopPropagation();
    this.props._saveCurrentPCBKey(key);
  }

  menu = (update) => {
    return this.pcbList.map(item => {
      return this.getPCBDisabled(item, update) ?
        {
          key: item.key,
          className: item.className,
          label: item.title,
          onClick: item.children ? null : (e) => this.uploadPCBClick(e, item.vendor, item.accept, update),
          children: item.children ? item.children.map(child => ({ key: child.key, className: child.className, label: child.title, onClick: (e) => this.uploadPCBClick(e, child.vendor, child.accept, update) })) : null
        } : null
    }).filter(item => !!item)
  }

  getPCBDisabled = (itemData, update) => {
    //Develop, replace-> disabled pre layout
    return (!itemData.develop || this.develop) && (!update || itemData.vendor !== PRE_LAYOUT);
  }

  onRef = (ref) => {
    this.child = ref;
  }

  render() {
    const { data, disabled, type } = this.props;
    const { vendor } = this.state;
    const title = type === REPLACE_PCB ? "PCB Replace" : null;
    const className = type === REPLACE_PCB ? "aurora-PCB-replace" : "aurora-PCB-upload";
    return (
      <Fragment>
        <div className={className}>
          <Tooltip
            title={disabled ? 'There is already a pcb being uploaded.' : title}
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
            key={data.key}
            overlayClassName='pcb-icon-tooltip'
            autoAdjustOverflow={false}
          >
            {type === UPLOAD_PCB ? this.pcbUpload({ data, disabled }) : null}
            {type === REPLACE_PCB && data.vendor !== PRE_LAYOUT && data.category !== DESIGN_FAILED ? this.pcbReplace({ data, disabled }) : null}
          </Tooltip>
          {data.category === DESIGN_FAILED &&
            <Tooltip title="PCB upload failed" overlayClassName='aurora-tooltip aurora-design-tooltip'>
              <ExclamationCircleOutlined className="design-failed-icon" />
            </Tooltip>}
        </div>
        {
          vendor !== PRE_LAYOUT ?
            <DesignUpload
              onRef={this.onRef}
              data={data}
              projectId={this.projectID}
            /> : null
        }
      </Fragment>
    );
  }

  pcbUpload = ({ data, disabled }) => {
    return (
      <Dropdown menu={{ items: this.menu(false), className: "upload-dropdown-button" }} trigger={['click']} disabled={disabled}>
        <PlusSquareOutlined
          className={disabled ? 'aurora-tree-add-icon icon-disabled' : 'aurora-tree-add-icon'}
          key={data.key}
          onClick={(e) => this.addClick(e, data.key)} />
      </Dropdown>
    );
  }

  pcbReplace = ({ data, disabled }) => {
    return <Dropdown disabled={disabled} menu={{ items: this.menu(true), className: "upload-dropdown-button" }} trigger={['click']}>
      <span key={data.key}
        onClick={(e) => this.addClick(e, REPLACE_PCB)}
        className={disabled ? `iconfont icon-replace1 aurora-replace-icon icon-disabled` : `iconfont icon-replace1 aurora-replace-icon`}></span>
    </Dropdown>
  }

}

const mapState = (state) => {
  const { AndesV2UploadReducer } = state.AndesV2Reducer;
  const { disabled } = AndesV2UploadReducer;

  return {
    disabled
  }
}

const mapDispatch = (dispatch) => ({
  _saveCurrentPCBKey(key) {
    dispatch(saveCurrentPCBKey(key))
  },
  _createPreLayout(pcbType) {
    dispatch(createPreLayout(pcbType))
  }
})
export default connect(mapState, mapDispatch)(UploadPCB);