const TYPE = `Layout`;

export const GET_LAYOUT_CHECKOUT = `${TYPE}/get_layout_checkout`;
export const SAVE_CURRENT_PROJECT_PCB = `${TYPE}/current_project_pcb`;
export const SAVE_CURRENT_DESIGN = `${TYPE}/current_design`;
export const UPDATE_DESIGN_MONITOR_INFO = `${TYPE}/update_design_monitor_info`;
export const SAVE_INFO = `${TYPE}/save_info`;
export const SAVE_SIMULATION_DESIGNS = `${TYPE}/save_simulation_designs`;
export const SAVE_RESULT = `${TYPE}/save_result`;

export const START_LAYOUT_CHECK = `${TYPE}/start_layout_check`;
export const CANCEL_LAYOUT_CHECK = `${TYPE}/cancel_layout_check`;
export const CHANGE_DESIGN = `${TYPE}/change_design`;
export const CHANGE_PRODUCT = `${TYPE}/change_product`;