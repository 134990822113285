import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Radio, Tooltip } from 'antd';
import LibraryUpload from './libraryUpload';
import VectorEdit from './vectorEdit';
import SamplePanel from '@/components/VectorSample';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import './sierraPanel.css';

class VectorPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      radioValue: 'upload',
      disabled: false,
      vecSampleVisible: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  onChangeType = (e) => {
    this.setState({
      radioValue: e.target.value,
    });
  }

  componentDidMount() {
    this.judgeFileOrValue(this.props.fileId);
  }

  componentDidUpdate(prevProps) {
    const { fileId } = this.props;
    if (prevProps.fileId !== fileId) {
      this.judgeFileOrValue(fileId);
      this.setState({
        vecSampleVisible: false
      });
    }
  }

  async judgeFileOrValue(id) {
    const { parentId } = this.props;
    const vectorList = await sierraLibrary.get({ id: parentId, type: 'vector' });
    if (Array.isArray(vectorList) && vectorList.find(item => item.id === id)) {
      this.setState({
        radioValue: 'edit',
        disabled: true
      })
    } else {
      this.setState({
        radioValue: 'upload',
        disabled: false
      })
    }
  }

  closeModal = () => {
    const { radioValue } = this.state;
    if (radioValue === 'upload') {
      // child component function
      if (!this.libraryUploadChild.getUploadingStatus()) {
        this.props.closeLibraryPanel();
      }
    } else {
      // child component function
      this.libraryEditChild.saveVectorData();
    }
  }

  onRef = (ref) => {
    this.libraryUploadChild = ref;
  }

  onEditRef = (ref) => {
    this.libraryEditChild = ref;
  }

  openSampleVEC = (e) => {
    e && e.stopPropagation();
    this.setState({
      vecSampleVisible: true
    });
  }

  closeSample = () => {
    this.setState({
      vecSampleVisible: false
    });
  }

  render() {
    const { title, modelType, uploadTitle, importText, accept, closeLibraryPanel, openRemoveModelsPanel, defaultVectorName, fileId, name, vectorList, parentId, updateLibrary } = this.props;
    const { radioValue, disabled, vecSampleVisible } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='vector-panel sierra-panel'
          position='panel-center-left'
          title={<Fragment>
            <span>{title}</span>
            <Tooltip
              title={'Sample'}
              placement={"right"}
              overlayClassName='icon-tooltip'
            >
              <QuestionCircleOutlined className='vector-help-icon' onClick={(e) => this.openSampleVEC(e)} />
            </Tooltip>
          </Fragment>}
          zIndex={2000}
          onCancel={this.closeModal}
          width={400}
          draggable
          minHeight={200}
        >
          <Radio.Group onChange={(e) => this.onChangeType(e)} value={radioValue} className='sierra-vector-radio-group'>
            <Radio value='upload' className='sierra-vector-radio-upload' disabled={disabled}>Import Vector File</Radio>
            <Radio value='edit' className='sierra-vector-radio-edit' disabled={updateLibrary && updateLibrary.id ? true : false}>Input Value</Radio>
          </Radio.Group>
          {radioValue === 'upload' &&
            <LibraryUpload
              uploadTitle={uploadTitle}
              importText={importText}
              accept={accept}
              modelType={modelType}
              closeLibraryPanel={closeLibraryPanel}
              openRemoveModelsPanel={openRemoveModelsPanel}
              onRef={this.onRef}
              parentId={parentId}
              updateLibrary={updateLibrary}
            />
          }
          {radioValue === 'edit' &&
            <VectorEdit
              name={name}
              defaultVectorName={defaultVectorName}
              closeLibraryPanel={closeLibraryPanel}
              onRef={this.onEditRef}
              id={fileId}
              vectorList={vectorList}
              parentId={parentId}
            />}
        </Panel>
        {vecSampleVisible ? <SamplePanel root={this.dialogRoot} closeModal={this.closeSample} /> : null}
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default VectorPanel;