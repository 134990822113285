import { getPackageResultInfoPromise } from '@/services/Rocky'

class PackageResultInfoConstructor {
  constructor() {
    this.packageVerificationInfo = new Map();
  }

  setInfo = (id, verificationData) => {
    this.packageVerificationInfo.set(id, verificationData)
  }

  getInfo = (id) => {
    return this.packageVerificationInfo.get(id)
  }

  getPackageResultInfo = (verificationId) => {
    const info = this.packageVerificationInfo.get(verificationId)
    if (!info) {
      return new Promise((resolve, reject) => {
        getPackageResultInfoPromise(verificationId).then(res => {
          if (res) {
            this.setInfo(verificationId, res.data)
            resolve(res.data)
          }
          resolve(null);
        }, error => {
          resolve(null);
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve(info)
      })
    }
  }
}

let packageResultInfoConstructor = new PackageResultInfoConstructor();
export default packageResultInfoConstructor;