
import libraryConstructor from '@/services/Cascade/helper/libraryConstructor'
import { getLibraryArray } from "./libraryTree";
import { DECAP, VRM, DECAP_RLC, DECAP_TOUCHSTONE, DECAP_SPICE, PIN_MAP, DRIVER, WIRE_BOND_XML, CUSTOM_TOUCHSTONE, CUSTOM_SPICE, CUSTOM, HFSS_OPTIONS, SIWAVE_OPTIONS, EXTRACTION_OPTIONS, POWERSI_OPTIONS, POWERDC_OPTIONS } from "../../../constants/libraryConstants";
import { getLibraryFileInfo } from './libraryCtrl';
import SpiceModelHelper from './SpiceModelHelper';
import CPMCSMSpice from '../../LibraryHelper/SpiceModelHelper/csmCpmParseHelper';
import { getCascadeLibraryFileInfo } from '../../api/Cascade/library';
import { CASCADE } from '../../../constants/pageType';

function decapDataType(libraryType) {
  switch (libraryType) {
    case DECAP_RLC:
      return "RLC"
    case DECAP_TOUCHSTONE:
    case CUSTOM_TOUCHSTONE:
      return "Touchstone"
    case DECAP_SPICE:
    case CUSTOM_SPICE:
      return "SPICE"
    case HFSS_OPTIONS:
      return "HFSS";
    case SIWAVE_OPTIONS:
      return "SIwave";
    case POWERSI_OPTIONS:
      return "PowerSI";
    case POWERDC_OPTIONS:
      return "PowerDC";
    default: return;
  }
}

function getPanelLibraryList() {
  const types = [/** VRM, */ DECAP_RLC, DECAP_TOUCHSTONE, DECAP_SPICE, WIRE_BOND_XML, DRIVER, PIN_MAP, CUSTOM_TOUCHSTONE, CUSTOM_SPICE, HFSS_OPTIONS, SIWAVE_OPTIONS, POWERSI_OPTIONS, POWERDC_OPTIONS];
  const libraryList = types.map((item) => {
    return libraryConstructor.getLibraryValues(item); // dataArr
  });
  let newList = []; // new libraryList
  let decapChildren = [], customChildren = [], extractionChildren = [];
  for (let item of libraryList) {
    if (item && item.length) {
      switch (item[0].type) {
        case VRM:
          newList.push({
            key: "VRM",
            name: "VRM",
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case PIN_MAP:
          newList.push({
            key: PIN_MAP,
            name: "PMIC Pin Map",
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case DRIVER:
          newList.push({
            key: DRIVER,
            name: "Driver",
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case WIRE_BOND_XML:
          newList.push({
            key: WIRE_BOND_XML,
            name: "Wire Bond Profile",
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case DECAP_RLC:
        case DECAP_TOUCHSTONE:
        case DECAP_SPICE:
          let typeName = decapDataType(item[0].type);
          decapChildren.push({
            key: item[0].type,
            name: typeName,
            children: getLibraryArray(item[0].type, item)
          })
          break;
        case CUSTOM_TOUCHSTONE:
        case CUSTOM_SPICE:
          let _typeName = decapDataType(item[0].type);
          customChildren.push({
            key: item[0].type,
            name: _typeName,
            children: getLibraryArray(item[0].type, item)
          })
          break;
        case HFSS_OPTIONS:
        case SIWAVE_OPTIONS:
        case POWERSI_OPTIONS:
        case POWERDC_OPTIONS:
          let extraTypeName = decapDataType(item[0].type);
          extractionChildren.push({
            key: item[0].type,
            name: extraTypeName,
            children: getLibraryArray(item[0].type, item),
          });
          break;
        default:
          break;
      }
    }
  }
  newList.push({
    key: DECAP,
    name: "Decap",
    children: decapChildren
  });
  newList.push({
    key: CUSTOM,
    name: "Custom Model",
    children: customChildren
  })
  newList.push({
    key: EXTRACTION_OPTIONS,
    name: "Extraction Options",
    children: extractionChildren,
  });
  const addKey = arr => arr.map(item => ({
    ...item,
    title: item.name,
    showTitle: item.name,
    value: item.children ? item.key : item.id,
    isDownload: item.children ? false : true,
    children: item.children ? addKey(item.children) : null
  }))
  let _newList = addKey(newList).filter(item => item.children.length);
  // check file
  if (_newList.length) {
    let res = {
      title: 'All',
      value: 'All',
      key: 'All',
      typeName: "Library",
      isDownload: false,
      children: _newList,
    };
    return [res];
  } else {
    return [];
  }
}

let spiceModelHelper = null;
function getPkgSpiceModelList(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, models: [] });
      return;
    }
    if (!spiceModelHelper) {
      spiceModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = spiceModelHelper.getSpiceModelList(libraryId);
    if (!models) {
      getLibraryFileInfo(libraryId).then((response) => {
        spiceModelHelper.parseModelSelector(libraryId, response);
        const _models = spiceModelHelper.getSpiceModelList(libraryId);
        resolve({ libraryId, models: _models });
      }, error => {
        console.error(error);
        resolve({ libraryId, models: [] });
      })
    } else {
      resolve({ libraryId, models });
    }
  });
}

let spiceCSMCPMModelHelper = null;

function getCascadeCSMCPMSpiceModelList({ libraryId, fileName, fileType }) {
  const key = fileName ? `${libraryId}::${fileName}` : libraryId;
  return new Promise((resolve, reject) => {
    if (!spiceCSMCPMModelHelper) {
      spiceCSMCPMModelHelper = new CPMCSMSpice([key]);
    }
    const model = spiceCSMCPMModelHelper.getSpiceModelList(key);
    if (!model || !Object.keys(model).length) {
      getCascadeLibraryFileInfo(libraryId).then((response) => {
        spiceCSMCPMModelHelper.parseSpice(key, response.data, fileType, CASCADE);
        const model = spiceCSMCPMModelHelper.getSpiceModelList(key);
        resolve({ model, libraryId, fileName });
      }, error => {
        console.error(error)
        resolve({ model, libraryId, fileName });
      })
    } else {
      resolve({ model, libraryId, fileName });
    }
  });
}

export {
  getPanelLibraryList,
  getPkgSpiceModelList,
  getCascadeCSMCPMSpiceModelList
}