import CeRectangle from './CeRectangle';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeDiamond = function () {
  CeRectangle.call(this, "Diamond");
}

// subclass extends superclass
CeDiamond.prototype = Object.create(CeRectangle.prototype);
CeDiamond.prototype.constructor = CeDiamond;

CeDiamond.prototype.Clone = function () {
  var clnObj = new CeDiamond();
  CeRectangle.prototype.CopyFrom.call(this, this);
  return clnObj;
}

CeDiamond.prototype.WriteIntoIODataNode = function () {
  var dataVals = CeRectangle.prototype.GetDataString.call(this);
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

export default CeDiamond;