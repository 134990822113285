import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LayoutExplorer from '../../LayoutExplorer/Andes';
import TabFooter from '@/components/TabSwitchFooter';
import { closeTabFooter, changeTabMenu } from '../../tabMonitor/action';
import { getCCCMsg } from '../../CCC/store/action';
import { VERIFICATION } from '../constants';
import Setup from './Setup';
import Result from '../results';

class LayoutContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 250,
      linePosition: {
        position: "absolute",
        bottom: 250
      },
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount() {
    this.screenChange();
  }

  componentDidUpdate = (prevProps) => {
    const { tabVisible, tabSelectKeys } = this.props;
    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props.getCCCMsg();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getBodySize();
  }

  getBodySize = () => {
    let bodyClientHeight = document.body.clientHeight;
    let bodyClientWidth = document.body.clientWidth;
    if (bodyClientHeight <= 300) {
      this.setState({
        height: 100,
        linePosition: {
          position: "absolute",
          bottom: 100,
        },
      })
    } else {
      this.setState({
        height: 250,
        linePosition: {
          position: "absolute",
          bottom: 250,
        },
      })
    }
    return { bodyClientWidth, bodyClientHeight };
  }

  changeHeight = (height) => {
    let { bodyClientHeight } = this.getBodySize();
    if (height <= 142) {
      height = 142;
    } else if (height >= bodyClientHeight - 100) {
      height = bodyClientHeight - 100
    }
    if (height < 142) {
      height = 142;
    }
    this.setState({ height })
    let position = {
      position: "absolute",
      bottom: height + 1,
      minHeight: 142,
      maxHeight: bodyClientHeight - 100
    }
    this.setState({
      linePosition: position
    })
  }

  getContent = (height) => {
    const { view, currentProjectVerifications, currentProjectDesigns, leftWidth } = this.props;
    switch (view) {
      case 'PCB':
        return currentProjectDesigns.length > 0 ? <LayoutExplorer leftWidth={leftWidth} bottomHeight={height} /> : null;
      case VERIFICATION:
        return currentProjectVerifications.length > 0 ? <Setup /> : null;
      case 'result':
        return <Result />;
      default: return null;
    }
  }

  changeSize = (height) => {
    this.changeHeight(height)
  }

  changeTabMenu = ({ selectKeys }) => {
    const { menuType, verificationId, currentProjectId } = this.props;
    this.props._changeTabMenu({ selectKeys, menuType, verificationId, projectId: currentProjectId })
  }

  render() {
    const { linePosition, height } = this.state;
    const { tabVisible, closeTabFooter, leftWidth } = this.props;
    return (
      <Fragment>
        <div id='andes-content-main' className='my-andes-content aurora-PCB-layout-content' style={{ bottom: tabVisible ? linePosition.bottom : 0 }}>
          {this.getContent(height)}
        </div>
        {tabVisible && <div className='andes-footer'>
          <TabFooter
            linePosition={linePosition}
            height={height}
            changeHeight={this.changeHeight}
            closeTabFooter={closeTabFooter}
            changeSize={this.changeSize}
            leftWidth={leftWidth}
            changeTabMenu={this.changeTabMenu}
          >
            {this.props.monitorChild()}
          </TabFooter>
        </div>}
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { AndesReducer } = state;
  const { project: { view, verificationId, currentProjectId, currentProjectVerifications, currentProjectDesigns },
    simulationReducer: { existResult } } = AndesReducer;

  const { tabVisible, tabSelectKeys, menuType } = state.TabMonitorReducer;
  return {
    view,
    tabVisible,
    tabSelectKeys,
    verificationId,
    currentProjectId,
    existResult,
    menuType,
    currentProjectVerifications,
    currentProjectDesigns
  };
}

const mapDispatch = (dispatch) => ({
  closeTabFooter() {
    dispatch(closeTabFooter())
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }));
  },
  getCCCMsg() {
    dispatch(getCCCMsg())
  }
})

export default connect(mapState, mapDispatch)(LayoutContent);