import { getDesignHybridInfo, saveDesignHybridInfo } from '../../../api/Ansys';
import apiProcess from '../../../api/utility';

function getDesignHybridInfoPromise(verificationId) {
  return apiProcess(getDesignHybridInfo, verificationId);
}

function saveDesignHybridInfoPromise(params) {
  return apiProcess(saveDesignHybridInfo, params);
}

function HybridRegionItem({
  name,
  centerPoint,
  unit,
  extraction
}) {
  this.name = name || "";
  this.extraction = extraction || "SIwave";
  this.centerPoint = centerPoint || { x: "", y: "" }
  this.unit = unit || "";
}

function HFSSZoneItem({
  unit,
  xList = [],
  yList = []
}) {
  this.unit = unit || "";
  this.x = [...xList];
  this.y = [...yList];
}

/*  "hfss_zones": [
    {
      "unit": "mm",
      "x": [58, 76, 77, 67, 51, 58],
      "y": [8, 3, -4, -8, -5, 8]
    },
    {
      "unit": "mm",
      "x": [95, 95, 117, 118, 107, 95],
      "y": [51, 40, 40, 48, 53, 51]
    }
  ] */

export {
  HFSSZoneItem,
  HybridRegionItem,
  getDesignHybridInfoPromise,
  saveDesignHybridInfoPromise
}