
/** 
 * @param {string} str Need to do delimited string
 * @param {string} delimiter 
 * @param {num} returnIndex  return arr index
 * @param {num} startSubscript  The starting index of the returned array
*/
function strDelimited(str, delimiter = "", setting) {
  const { returnIndex, startSubscript = 0 } = setting ? setting : {};

  if (typeof (str) !== "string") {
    return returnIndex !== undefined ? str : [];
  }

  const arr = str.split(delimiter);
  const index = returnIndex !== undefined ? returnIndex < 0 ? arr.length + returnIndex : returnIndex : undefined;
  if (index !== undefined) {
    return arr[index] ? arr[index] : null;
  } else {
    return arr.filter((item, index) => index >= startSubscript);
  }
}

/** 
 * @param {number} number The number of white space you need
*/
function getWhiteSpace(number = 2) {
  const whiteSpace = '\u00A0';
  let data = '';
  for (let i = 0; i < number; i++) {
    data = `${data}${whiteSpace}`;
  }
  return data
}

/** 
 * find str list max same str
 * @eg: ["PCIEG3_LN0_TX","PCIEG3_LN1_TX","PCIEG3_LN0_RX"] -> PCIEG3_LN
 * @eg: ["MT_PCIEG3_LN0_TX","W_PCIEG3_LN1_TX","PCIEG3_LN0_RX"] -> PCIEG3_LN
*/
function maxPrefix(strList, type) {
  if (!strList || !strList.length || strList.filter(item => !item).length) {
    return '';
  }

  const maxPrefix = _maxPrefix(strList);
  if (maxPrefix.length > 1) {
    return maxPrefix;
  }
  if (type === "all") {
    return "";
  }
  let strLengthList = [...new Set(strList.map(item => item.length))];
  let strLengthObj = {};

  for (let length of strLengthList) {
    strLengthObj[length] = strList.filter(item => item.length === length);
    const _maxPrefix1 = _maxPrefix(strLengthObj[length]);
    if (strLengthObj[length].length > 1 && _maxPrefix1.length > 1) {
      return _maxPrefix1;
    }
  }
  return "";
}

function _maxPrefix(strList) {
  if (!strList || !strList.length || strList.filter(item => !item).length) {
    return '';
  }


  // Compare sequentially from 0
  let startIndex = 0, endIndex = 1, currentIndex = 0;

  function currentAllMatchFn(refer) {
    return strList.reduce((prev, curr) => {
      return prev && curr.charAt(currentIndex) === refer;
    }, true);
  }

  let isStart = false;

  while (true) {
    //Take the current character of the first string as a reference
    const refer = strList[0][currentIndex];
    // Whether all match
    const currentAllMatch = currentAllMatchFn(refer);
    if (currentAllMatch) {
      if (!isStart) {
        isStart = true;
        startIndex = currentIndex;
      }
    } else if (isStart) {
      endIndex = currentIndex;
      break;
    }

    currentIndex++;
    if (currentIndex > strList[0].length) {
      break;
    }
  }

  const maxPrefix = strList[0].substring(startIndex, endIndex).replace(/^([^a-zA-Z0-9])+|([^a-zA-Z0-9])+$/g, '');
  return maxPrefix.length > 1 ? maxPrefix : "";
}

export {
  strDelimited,
  getWhiteSpace,
  maxPrefix,
  _maxPrefix
}