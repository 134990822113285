import React, { Component, Fragment, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Dropdown, Avatar } from 'antd';
import { Link } from "react-router-dom";
import logo from './aurora.svg';
import SetupPanel from './SetupPanel';
import cookie from 'react-cookies';
import ProjectMenu from '../ProjectMenu';
import AccountPanel from './AccountPanel';
import { ANDES, FASTPI, SIERRA, ANDES_V2, ROCKY, CASCADE, HIMALAYAS } from '@/constants/pageType';
import { getVersionPromise, updateTimeZonePromise } from '../../services/authCtrl';
import { SYS_VERSION } from '../../version';
import dayjs from 'dayjs';
import { setPageType, saveSystemVersion } from '../../pages/Login/action';
import permissionData from '@/services/helper/data/permissionData';
import { isChrome } from '../../services/helper/browser';
import DelConfirm from '../DelConfirm';
import './index.css';
import LicensePanel from './licensePanel';

const Tool = lazy(() => import("../ToolNew"));

const { Header } = Layout;

class NavHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPanel: false,
      showAccount: false,
      showLicense: false,
      sysVersion: null,
      browserVisible: false,
    }
  }

  showSetup = () => {
    this.setState({
      showPanel: true,
    })
  };

  closeModal = () => {
    this.setState({
      showPanel: false,
    })
  };

  closeAccountModal = () => {
    this.setState({
      showAccount: false
    })
  }

  showAccount = () => {
    this.setState({
      showAccount: true
    })
  }

  switchLicenseVisible = (checked) => {
    this.setState({
      showLicense: checked
    })
  }

  componentWillMount() {
    let debugMode = cookie.load('debugMode');
    // get resolution value
    let SIWEmode = cookie.load('UseSiWaveSolverExplicitly');
    let couplingMinFreqValue = cookie.load('couplingMinFreq');

    if (!debugMode) {
      cookie.save('debugMode', false, {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      });
    }

    if (couplingMinFreqValue === undefined || isNaN(parseFloat(couplingMinFreqValue))) {
      cookie.save('couplingMinFreq', '3.5MHz', {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      })
    }

    if (SIWEmode === undefined || !['off', 'on'].includes(SIWEmode)) {
      cookie.save('UseSiWaveSolverExplicitly', 'off', {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { email } = this.props;

    if (email && prevProps.email !== email) {
      this.updateVersion();
      this.updateTimeZoom();
    }
  }

  updateVersion = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    getVersionPromise().then(res => {
      const version = res.version;
      this.props._saveSystemVersion(version);
      this.setState({
        sysVersion: version
      })
    }, error => {
      console.error(error);
    });
  }

  updateTimeZoom = () => {
    let time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let timeZone = dayjs(time.toUTCString()).format();
    timeZone = timeZone.slice(-6);
    updateTimeZonePromise(timeZone);
  }

  logout = () => {
    this.props.setPageType(null);
    this.props.logout();
  }

  getProduct = (pageType) => {
    let product = null;
    switch (pageType) {
      case ANDES:
      case ANDES_V2:
        product = 'ANDES';
        break;
      case FASTPI:
        product = 'FASTPI';
        break;
      case SIERRA:
        product = 'SIERRA';
        break;
      case ROCKY:
        product = 'ROCKY';
        break;
      case CASCADE:
        product = 'CASCADE';
        break;
      case HIMALAYAS:
        product = 'HIMALAYAS';
        break;
      default: break;
    }
    return product;
  }

  reloadPage = async () => {
    const chrome = isChrome();
    this.setState({
      browserVisible: chrome ? false : true
    });
    if (!chrome) {
      return;
    }

    const token = localStorage.getItem('token');
    if (!this.props.email || !token) {
      return;
    }
    const res = await getVersionPromise();
    const { sysVersion } = this.state;
    //version is updated, reloading page
    if (res && res.version && (!sysVersion || res.version !== sysVersion)) {
      window.location.reload();
      window.location.href = "/";
    }
  }

  okConfirm = () => {
    this.setState({
      browserVisible: false
    })
  }

  clickMenu = ({ key }) => {
    switch (key) {
      case 'setup':
        this.showSetup()
        break;
      case 'account':
        this.showAccount()
        break;
      case 'license':
        this.switchLicenseVisible(true);
        break;
      case 'logout':
        this.logout();
        break;
      default:
        break;
    }
  }

  render() {
    const { authed, userName, logout, pageType, installerVisible, pageStyle, email } = this.props;
    const { showPanel, showAccount, sysVersion, browserVisible, showLicense } = this.state;
    const product = this.getProduct(pageType);
    const isAdmin = permissionData.isAdmin();
    const isRoot = permissionData.isRoot();
    const className = pageStyle === "LCFC" ? "lcfc-account" : '';
    let user, admin;
    if (authed) {
      const items = [{ label: 'Settings', key: 'setup' }, { label: 'Account', key: 'account' }, { label: 'License', key: 'license' }, { label: 'Logout', key: 'logout' },]
      user = (
        <Dropdown placement="bottom" menu={{ items, className: "AccountMenuItem", onClick: this.clickMenu }}>
          <span className={`account ${className}`}>
            {pageStyle === "LCFC" ?
              <Avatar src="/lib/imgs/lcfc-avatarFrame.png" className="avatar" /> :
              <Avatar icon={<UserOutlined />} className="avatar" />
            }
            <span>{userName}</span>
          </span>
        </Dropdown>
      );

      if (isAdmin) {
        admin = (
          <ul
            className="header-menu"
          >
            <li key="admin"><Link to='/admin'>{isRoot ? 'Root' : 'Admin'}</Link></li>
          </ul>
        )
      } else {
        admin = (
          <ul
            className={installerVisible ? "header-has-download-menu header-menu" : "header-menu"}
          >
            {installerVisible ? <li>
              <span className='account' style={{ float: 'right' }}>
                <Link to='/download' className="log-in-btn">
                  <DownloadOutlined className="dld_login" />
                  <span className="dld">Download</span>
                </Link>
              </span>
            </li> : null}
            <li key="tool"><Tool /></li>
            <li key="projectMenu"><ProjectMenu systemVersion={sysVersion} email={email} /></li>
          </ul>
        )
      }
    } else {
      user = (
        <Fragment>
          <span className={`account ${className}`} style={{ float: 'right' }} >
            <Link to="/login" className='log-in-btn'>
              {pageStyle === "LCFC" ?
                <Avatar src="/lib/imgs/lcfc-avatarFrame.png" className="avatar" /> :
                <Avatar icon={<UserOutlined />} className="avatar" />
              }
              <span>Login</span>
            </Link>
          </span>
          {installerVisible ? <span className='account' style={{ float: 'right' }}>
            <Link to="/download" className="log-in-btn">
              <DownloadOutlined className="avatar ant-avatar download-Icon ant-avatar-download-Icon" />
              <span className="download">Download</span>
            </Link>
          </span> : null}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Header className="header">
          {pageStyle === "LCFC" && <Fragment>
            <img src={`/lib/imgs/lcfc-icon.png`} className="header-lcfc-icon" alt="" />
            <div className='header-lcfc-Separator'></div>
          </Fragment>}
          <Link to='/' className="logo" onClick={this.reloadPage}>
            <img src={logo} alt="logo" />
            <h1 className='logo-name-aurora'>Aurora</h1>
          </Link>
          {isAdmin ? <div className='header-product-version'>
            <span className='header-version'>v{SYS_VERSION}#{sysVersion}</span>
          </div> : (product && <div className='header-product-version'>
            <span className='header-product-name'>{product}</span><span className='header-version'>v{SYS_VERSION}#{sysVersion}</span>
          </div>)}
          <Suspense fallback={null}>
            {user}
            {admin}
          </Suspense>
        </Header>
        {showPanel && <SetupPanel
          closeModal={this.closeModal}
          email={this.props.email}
          pageType={this.props.pageType}
        />}
        {showAccount && <AccountPanel closeModal={this.closeAccountModal} logout={logout} />}
        {
          browserVisible ? <DelConfirm
            data={null}
            removeCancel={true}
            deleteConfirm={this.okConfirm}
            message={"Non Chrome browser is not supported in the simulation platform."}
          /> : null
        }
        {showLicense && <LicensePanel closeModal={this.switchLicenseVisible} />}
      </Fragment>

    )
  }
}

const mapState = (state) => {
  const { pageType } = state.LoginReducer;
  return {
    pageType
  }
};

const mapDispatch = (dispatch) => ({
  setPageType(type) {
    dispatch(setPageType(type))
  },
  _saveSystemVersion(version) {
    dispatch(saveSystemVersion(version))
  }
});

export default connect(mapState, mapDispatch)(NavHeader);