const TYPE = "CARD";
export const GET_CARD_VERIFICATION_CONTENT = `${TYPE}/get_card_verification_content`;
export const UPDATE_CARD_INFO = `${TYPE}/update_card_info`;
export const OPEN_CARD_CHANNEL = `${TYPE}/open_card_channel`;
export const UPDATE_CARD_EXTRACTION_CONFIG = `${TYPE}/update_card_extraction_config`;
export const GET_CARD_CHANNEL_CONFIG = `${TYPE}/get_card_channel_config`;
export const CLOSE_CARD_CHANNEL = `${TYPE}/close_card_channel`;
export const SAVE_CARD_EXTRACTION_CONFIG = `${TYPE}/save_card_extraction_config`;
export const SAVE_CARD_PORT_SETUPS = `${TYPE}/save_card_port_setups`;
export const SAVE_CARD_CONTENT_TO_SERVER = `${TYPE}/save_card_content_to_server`;
export const UPDATE_CARD_ERROR_CHECK_LIST = `${TYPE}/update_card_error_check_list`;
export const START_CARD_VERIFICATIONS_MODELING = `${TYPE}/start_card_verifications_modeling`;
export const SAVE_CARD_COMP_PACKAGE_MODEL = `${TYPE}/save_card_package_model`;
export const SAVE_CARD_COMP_RLC_MODEL = `${TYPE}/save_card_comp_rlc_model`;
export const SAVE_CARD_TO_LIBRARY = `${TYPE}/save_card_to_library`;
export const CHANGE_CARD_COMPONENT_TYPE = `${TYPE}/change_card_component_type`;