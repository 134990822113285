import { MEMORY } from "../../PCBHelper";
import designConstructor from "../../helper/designConstructor";
import { RDIMM_TYPES, DDR5_SODIMM } from "../constants";
import { Model } from './IntegratedInterface'
import { getInterfaceType } from "./setupData";

function copyComponentDimmModel({
  currentInterfaces,
  model,
  partName,
  compType,
  rank,
  projectType
}) {
  let copyStatus = false;
  const interfaceType = currentInterfaces && currentInterfaces[0] ? getInterfaceType(currentInterfaces[0].name) : '';
  const allowRanks = RDIMM_TYPES.includes(projectType);
  const isCLK = interfaceType === "CLK";
  if (partName && compType && model && (model.id || model.type === "None")) {

    currentInterfaces.forEach((info) => {

      info.Content.Components.forEach(comp => {
        //find memory
        if (compType === comp.type || partName === comp.part) {
          //copy dimm card model and dimm stdModels
          comp.dimmCardModel = { ...model }
          if (allowRanks) {
            comp.pins = updateDimmStdModelsByRank(comp, rank, isCLK)
          }
          copyStatus = true;
        }
      })
    });
  }
  return { Interfaces: currentInterfaces, copyStatus };
}

function copyComponentSocketModel({
  currentInterfaces,
  model,
  partName,
  compType
}) {
  let copyStatus = false;
  if (partName && compType && model && model.type && model.files && model.files.length) {

    currentInterfaces.forEach((info) => {

      info.Content.Components.forEach(comp => {
        //find memory
        if (/* compType === comp.type ||  */partName === comp.part) {

          if (!comp.socketModel || !comp.socketModel.type || !comp.socketModel.files || !comp.socketModel.files.length) {
            //copy dimm socket model
            const _model = JSON.parse(JSON.stringify(model));
            //update pairs by comp pins
            const prevPairs = model.pairs || [];
            comp.pins.forEach(item => {
              if (_model.pairs.findIndex(pair => pair.pin === item.pin) < 0) {

                const existPin = prevPairs.find(it => it.pin === item.pin);
                const existOutPin = prevPairs.find(it => `${it.pin}_out` === `${item.pin}_out`);

                let pinR = { pin: `${item.pin}_out`, node: "", subckt: "", libraryId: "", modelKey: "" };
                let pinL = { pin: item.pin, node: "", subckt: "", libraryId: "", modelKey: "" };

                if (existPin) {
                  pinL = {
                    ...pinL,
                    subckt: existPin.subckt || "",
                    libraryId: existPin.libraryId || "",
                    modelKey: existPin.modelKey || ""
                  }
                }
                if (existOutPin) {
                  pinR = {
                    ...pinR,
                    subckt: existOutPin.subckt || "",
                    libraryId: existOutPin.libraryId || "",
                    modelKey: existOutPin.modelKey || ""
                  }
                }
                _model.pairs = [..._model.pairs, pinL, pinR];
              }
            });
            comp.socketModel = _model;
            copyStatus = true;
          }
        }
      })
    });
  }
  return { Interfaces: currentInterfaces, copyStatus };
}

function getCardSelectionOptions({ currentProjectId, cardLibraryList, projectType }) {
  let list = ["None"];
  // DIMM Connector
  const cards = designConstructor.getAvailableCardDesignValues(currentProjectId) || [];
  list.push({
    group: "Project Card",
    children: [...cards]
  })
  //projectType -> DDR5-RDIMM, card library name -> RDIMM
  const _cardLibraryList = (cardLibraryList.find(item => projectType.match(item.name)) || {}).children || [];
  list.push(..._cardLibraryList.map(item => {
    return {
      group: `Library Card ${item.name}`,
      children: [...item.children]
    }
  }));
  return list;
}

function getDimmSelectValue(dimmCardModel) {
  if (!dimmCardModel || !dimmCardModel.type || dimmCardModel.type === "None") {
    return "None";
  }
  if (dimmCardModel.type === "Card") {
    return `Project Card - ${dimmCardModel.name}`;
  }

  if (dimmCardModel.type === "Library") {
    return `Library Card - ${dimmCardModel.name}`;
  }
}

function updateDimmStdModelsByRank(comp, rank, isCLK) {
  comp.pins.forEach(pin => {
    let dimmStdModels = []
    if (rank && !isCLK) {
      for (let i = 0; i < rank; i++) {
        const model = pin.dimmStdModels ? (pin.dimmStdModels[i] || pin.dimmStdModels[0] || new Model({})) : new Model({});
        dimmStdModels.push({
          ...model, rankId: i + 1
        })
      }
    } else {
      dimmStdModels = [{ ...(pin.dimmStdModels && pin.dimmStdModels[0] ? pin.dimmStdModels[0] : new Model({})), rankId: null }]
    }
    pin.dimmStdModels = dimmStdModels
  })
  return comp.pins
}

function judgeDimmStdModelExist(Interfaces) {

  for (let info of Interfaces) {
    if (!info.Content || !info.Content.Components || !info.Content.Components.length) {
      continue;
    }
    const memoryList = info.Content.Components.filter(item => item.type === MEMORY && item.dimmCardModel);
    if (!memoryList.length) {
      continue;
    }

    for (let comp of memoryList) {
      if (comp.dimmCardModel.type && comp.pins && !!comp.pins.find(it => !it.dimmStdModels || !it.dimmStdModels.length)) {
        return false
      }
    }
  }
  return true;
}

function getCardModelExist(Interfaces) {
  if (!Interfaces || !Interfaces.length) {
    return true;
  }
  const Components = Interfaces[0] && Interfaces[0].Content && Interfaces[0].Content.Components ? Interfaces[0].Content.Components : null;
  if (!Components || !Components.length) {
    return true;
  }

  const findCardModel = Components.find(item => item.type === MEMORY && item.dimmCardModel && item.dimmCardModel.type !== "None" && item.dimmCardModel.id)
  if (!findCardModel) {
    return false;
  }
  return true;
}

function getDimmCardMultiRanks(components) {
  const findMemory = components.filter(item => item.type === MEMORY);
  const findCardModel = findMemory.find(item => item.dimmCardModel && item.dimmCardModel.type && item.dimmCardModel.type !== "None" && item.dimmCardModel.id);
  const multiRanksPin = findCardModel && findCardModel.pins
    ? findCardModel.pins.find(item => item.dimmStdModels && item.dimmStdModels.length > 0)
    : null;
  const ranks = multiRanksPin && multiRanksPin.dimmStdModels ? multiRanksPin.dimmStdModels.length : 0;
  return ranks;
}

function getRDimmStdModelDisabled(components, interfaceType) {
  const findMemory = components.filter(item => item.type === MEMORY);

  if (interfaceType === "CLK" && findMemory.length === 1) {
    return true;
  }

  const ranks = getDimmCardMultiRanks(components);
  if (findMemory.length < 2 && ranks < 1) {
    return true;
  }
}

function getRDIMMDisabled({ components, projectType, record, interfaceType }) {
  const findMemorys = components.filter(item => item.type === MEMORY);
  const rdimmDisabled = RDIMM_TYPES.includes(projectType)
    && (record.active || findMemorys.length === 1)
    && interfaceType !== 'CLK'
    && (!record.rankId || record.rankId === 1);
  return rdimmDisabled;
}

function getSODIMMDisabled({ projectType, interfaceType, record }) {
  const soDimmDisabled = projectType === DDR5_SODIMM
    && interfaceType === 'CLK'
    && record.type === MEMORY
    && ["CLKP", "CLKN"].includes(record.signal);
  return soDimmDisabled;
}

function getTableCardModelExist({ projectType, record }) {

  const notSetCardModel = RDIMM_TYPES.includes(projectType)
    &&
    (!record.dimmCardModel
      || !record.dimmCardModel.type
      || record.dimmCardModel.type === "None"
      || !record.dimmCardModel.id);
  return !notSetCardModel;
}

export {
  copyComponentDimmModel,
  copyComponentSocketModel,
  getCardSelectionOptions,
  getDimmSelectValue,
  updateDimmStdModelsByRank,
  judgeDimmStdModelExist,
  getCardModelExist,
  getDimmCardMultiRanks,
  getRDimmStdModelDisabled,
  getRDIMMDisabled,
  getSODIMMDisabled,
  getTableCardModelExist
}