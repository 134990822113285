import { DCR, DESIGN_TREE, IMPEDANCE, IR_EXPLORER, SIGN_OFF_TEMPLATE } from "../../../constants/treeConstants"

export function getNewStackupList(oldStackList, status = 'open', allIds, isPCB) {
  const { selectedKeys, treeDesignId, dcrDesignId, irDesignId, impDesignId, signOffDesignId, currentID } = allIds

  const getMsgOnce = key => {
    let [type, id] = key.split('-')
    switch (type) {
      case DESIGN_TREE:
        id = treeDesignId
        break
      case DCR:
        id = dcrDesignId
        break
      case IR_EXPLORER:
        id = irDesignId
        break
      case IMPEDANCE:
        id = impDesignId
        break
      case SIGN_OFF_TEMPLATE:
        id = signOffDesignId
        break
      default: new Date().getTime()
    }
    return { designId: id, type, status: 'close' }
  }

  let newStackupList = []
  if (selectedKeys.length === 1) newStackupList = [getMsgOnce(selectedKeys[0])]
  else if (selectedKeys.length === 2) {
    newStackupList = [getMsgOnce(selectedKeys[0]), getMsgOnce(selectedKeys[1])]
  } else if (selectedKeys.length === 3) {
    newStackupList = [getMsgOnce(selectedKeys[0]), getMsgOnce(selectedKeys[1]), getMsgOnce(selectedKeys[2])]
  }

  newStackupList.forEach(newItem => {
    const prevMsg = oldStackList.find(oldItem => newItem.designId === oldItem.designId && newItem.type === oldItem.type)
    if (prevMsg) newItem.status = prevMsg.status

    if (newItem.designId === currentID) {
      if (isPCB && newItem.type === 'PCB') {
        newItem.status = status
      }
      if (!isPCB && newItem.type !== 'PCB') {
        newItem.status = status
      }
    }
  })
  return newStackupList
}


