import ProjectDB from '../../helper/DB/projectDB'
class CascadeProject extends ProjectDB {
  updateProjectName(id, name) {
    const data = this.projectList.get(id);
    if (data) {
      this.projectList.set(id, { ...data, name })
    }
  }
}

let cascadeProject = new CascadeProject()
export default cascadeProject