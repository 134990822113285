import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Table from "@/components/EditableTable";
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { filterPowerNets } from '@/services/PCBHelper';
import { numberCheck } from '@/services/helper/dataProcess';
import {
  updateChannelContent,
  powerNetsSelection,
  powerVoltageUpdate,
  deletePwrGnd
} from "../store/channel/action";
import './index.css';
import { getSignalElementType } from '../../../services/Andes_v2/channel/signalsIdentification';

const columns = [
  {
    title: "Net",
    dataIndex: "name",
    width: "50%"
  },
  {
    title: "Voltage (V)",
    dataIndex: "value",
    width: "50%"
  }
];

class PowerNetsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PCBNets: [],
      allPCBNets: []
    };

    columns[0].render = (name) => (
      <div className={this.state.light && !name ? 'aurora-table-tr-light' : ""}>
        <span>{name}</span>
      </div>
    )

    columns[0].onCell = (record, index) => {
      const options = this.getNetsOptions(record);
      const { searching, filterNets = [] } = this.state;
      const _options = record.name && !options.includes(record.name) ? [record.name, ...options].sort() : options;
      return {
        record,
        edit: 'select',
        options: searching ? filterNets || [] : _options,
        dataIndex: "name",
        onSearch: this.onSearch,
        handleSave: this.netSelection,
        getPopupContainer: document.getElementById("andes-v2-content-main"),
        onFocus: () => this.netSelectFocus(record),
        auroraSelectBlur: this.auroraSelectBlur
      };
    };

    columns[1].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'value',
        handleSave: this.voltageUpdate
      }
    }

    columns[1].render = (value, record) => (
      <div>
        <span>{value}</span>
        <CloseOutlined className='power-nets-delete' onClick={(e) => this.deletePG(e, record)} />
      </div>
    )
  }

  deletePG = (e, record) => {
    e && e.stopPropagation();
    const { name } = record;
    this.props._deletePwrGnd(name);
    this.setState({
      light: false
    });
  }

  auroraSelectBlur = () => {
    this.setState({
      searching: false
    });
  }

  onSearch = (value) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.searchNets(value)
    }, 500);
  }

  searchNets = (value) => {
    const { allPCBNets } = this.state;
    if (!value || value.match(/^\s+$/g)) {
      this.setState({
        searching: false,
        filterNets: []
      })
      return;
    }
    const reg = new RegExp(value, 'ig');
    const filterNets = allPCBNets.filter(item => item.match(reg));
    this.setState({
      searching: true,
      filterNets
    })
  }

  getNetsOptions = () => {
    const { PCBNets } = this.state;
    const { signals, serdesType } = this.props;
    if (signals && signals.length) {
      let signalNets = [];
      for (const item of signals) {
        const otherNets = getSignalElementType(serdesType).map(key => item[key] && item[key].length > 0 ? item[key] : []).flat(2);
        signalNets = [...signalNets, ...otherNets];
      }
      //Filter nets (not includes signal nets)
      return PCBNets.length ? PCBNets.filter(item => !signalNets.includes(item)) : []
    } else {
      return PCBNets || [];
    }
  }

  netSelectFocus = (record) => {
    const { name } = record;
    this.props.displayToLayout([], [name]);
    this.setState({
      light: false
    });
  }

  netSelection = (record, prevRecord) => {
    const { name } = record;
    this.props.displayToLayout([], [name]);
    /*  let _powerNets = [...this.props.powerNets]; */
    const prevName = prevRecord && prevRecord.name ? prevRecord.name : '';
    this.props._powerNetsSelection({ name, prevName });
    this.setState({
      light: false,
      searching: false
    });
  };

  voltageUpdate = (record, prevRecord) => {
    const { name, value } = record;
    const { value: prevVoltage } = prevRecord;
    //num check
    const error = value ? numberCheck(value) : null;
    if (!error) {
      this.props._powerVoltageUpdate({ name, voltage: value, prevVoltage });
    }
    this.setState({
      light: false
    });
  };

  componentDidUpdate(prevProps) {
    this.getPcbNets(prevProps);
  }

  componentDidMount() {
    this.getPcbNets();
    this.loadPCBID = null;
  }

  getPcbNets = (prevProps = {}) => {
    const { channelDesignId, pcbNetsList } = this.props;
    if ((this.loadPCBID !== channelDesignId || channelDesignId !== prevProps.channelDesignId) && pcbNetsList.length) {
      const filter = filterPowerNets(pcbNetsList, null, channelDesignId).map(item => item.mName);
      this.setState({
        PCBNets: filter,
        allPCBNets: pcbNetsList.map(item => item.mName)
      });
      this.loadPCBID = channelDesignId;
    }
  }

  addPowerGndNets = () => {
    let _powerNets = [...this.props.powerNets];
    let find = _powerNets.find(item => !item.name);
    if (find) {
      this.setState({
        light: true
      });
      return;
    } else {
      this.setState({
        light: false
      });
    }
    _powerNets.push({
      name: "",
      value: ""
    });
    this.props._updateChannelContent({ powerNets: _powerNets });
    //The table has a scroll bar to scroll to the bottom
    setTimeout(() => {
      const table = ReactDOM.findDOMNode(this.table),
        tableBody = table ? table.querySelector('.aurora-table-body') : null;
      if (tableBody && tableBody.scrollHeight) {
        tableBody.scrollTop = tableBody.scrollHeight;
      }
    }, 240);
  }

  render() {
    const { powerNets } = this.props;
    return (
      <div className="aurora-setup-border">
        <span className='out-title-color font-bold'>Power / Ground Nets</span>
        <PlusCircleOutlined className='signal-add-icon' onClick={this.addPowerGndNets} />
        <Table
          ref={(ref) => this.table = ref}
          columns={columns}
          className="space-10 andes-v2-PG-table"
          dataSource={powerNets}
          rowKey="name"
          scroll={
            powerNets && powerNets.length && powerNets.length > 8
              ? { y: 320 }
              : {}
          }
          tablePadding={true}
        />
      </div>
    );
  }
}

const mapDispatch = (dispatch) => ({
  _updateChannelContent(data) {
    dispatch(updateChannelContent(data))
  },
  _powerNetsSelection({ name, prevName }) {
    dispatch(powerNetsSelection({ name, prevName }))
  },
  _powerVoltageUpdate({ name, voltage, prevVoltage }) {
    dispatch(powerVoltageUpdate({ name, voltage, prevVoltage }))
  },
  _deletePwrGnd(name) {
    dispatch(deletePwrGnd(name))
  }
});

export default connect(null, mapDispatch)(PowerNetsTable);