import { IO_SIM, SPICE_CARD_TEMPLATE } from "../../../constants/treeConstants";
import { getHimalayasDebugDownloadList } from "../../helper/debugHelper";
import { getProjectChildTitle } from "../project";
import himalayasChannels from "../project/channelsConstructor";

function getDebugSimulationData({
  treeList = [],
  selectList = [],
  menuType,
  key,
  view,
  channelId,
  verificationId,
  projectId,
  currentProjectName
}) {
  let _verificationId = null;
  if (view === IO_SIM) {
    _verificationId = verificationId;
  } else if (view === SPICE_CARD_TEMPLATE) {
    const findIoSim = (himalayasChannels.getInputChildren(projectId, channelId) || [])[0] || null;
    _verificationId = findIoSim ? findIoSim.verificationId : null;
  }
  const currentProjectData = _verificationId ? selectList.find(item => item.verificationId === _verificationId) : selectList[0];
  let currentId = '', name = '';
  if (currentProjectData) {
    currentId = currentProjectData.verificationId;
    name = currentProjectData.name;
  }
  const selectInfo = { id: currentId, name };
  const { downloadList, currentCheckValues } = getDownloadList(menuType);

  return {
    title: getProjectChildTitle(key),
    key: `${menuType}-${key}`,
    menuType: menuType,
    info: {
      selectList: [...selectList],
      selectInfo,
      treeList: [...treeList]
    },
    downloadList,
    currentCheckValues,
    currentProjectName
  }
}

function getDebugReportData({ treeList = [], menuType, view, channelId, projectId, currentProjectName }) {
  let currentCheckValues = [], defaultCheckExpandKeys = [];
  if (view === IO_SIM) {
    const findIoSim = himalayasChannels.getInfoById(IO_SIM, projectId, channelId);
    currentCheckValues = findIoSim && findIoSim.spiceCardId ? [{ name: findIoSim.spiceCardId, children: [channelId] }] : []
  } else if (view === SPICE_CARD_TEMPLATE) {
    const findIoSims = himalayasChannels.getInputChildren(projectId, channelId) || [];
    currentCheckValues = [{ name: channelId, children: [...findIoSims.map(item => item.id)] }]
  }

  defaultCheckExpandKeys = currentCheckValues.map(item => item.name);

  let downloadList = treeList.map(item => {
    return {
      name: item.name,
      option: item.id,
      children: item.children.map(item => {
        return {
          name: item.name, option: item.id, default: true
        }
      }),
      default: true
    }
  })

  return {
    title: 'Report',
    key: menuType,
    menuType: menuType,
    info: { notSelected: true },
    downloadList: downloadList,
    currentCheckValues,
    enabledChildren: true,
    defaultCheckExpandKeys,
    currentProjectName
  }
}


function getDownloadList(menuType, ioSimMode) {
  const downloadList = getHimalayasDebugDownloadList(menuType, ioSimMode);
  let currentCheckValues = [];
  for (let item of downloadList) {
    if (item.default) {
      currentCheckValues.push({
        name: item.option,
        children: item.children ? item.children.map(it => it.option) : []
      });
    }
  }
  return { downloadList, currentCheckValues }
}

export {
  getDebugSimulationData,
  getDebugReportData,
  getDownloadList
}