import React from "react";
import { SIERRA } from '@/constants/pageType';
import { getPCBUploadList } from "../../../../services/PCBHelper/pcbHelper";

export default function higherOrderComponent({ UploadPCBMenu, ...otherProps }) {
  return class extends React.Component {
    constructor(props) {
      super()
    }
    render() {
      const pcbList = getPCBUploadList(SIERRA);
      return <UploadPCBMenu
        {...otherProps}
        {...this.props}
        pcbList={pcbList}
      />
    }
  }
}