import LayoutData from "../data/LayoutData";

function getPartNumber(partName, designId) {
  if (!designId || !partName) {
    return null;
  }
  const layout = LayoutData.getLayout(designId);
  if (!layout || !layout.mPartMgr || !layout.mPartMgr.GetPart) {
    return null;
  }
  const part = layout.mPartMgr.GetPart(partName);
  if (!part || !part.mInfo || !part.mInfo.mPhyProps) {
    return null;
  }

  const partNumber = part.mInfo.mPhyProps.getValue('PART_NUMBER');
  return partNumber;
}


function getPartAttributesValue(partName, designId) {
  if (!designId || !partName) {
    return null;
  }
  const layout = LayoutData.getLayout(designId);
  if (!layout || !layout.mPartMgr || !layout.mPartMgr.GetPart) {
    return null;
  }
  const part = layout.mPartMgr.GetPart(partName);
  if (!part || !part.mInfo || !part.mInfo.mPhyProps) {
    return null;
  }

  const value = part.mInfo.mPhyProps.getValue('VALUE');
  return value;
}


export {
  getPartNumber,
  getPartAttributesValue
} 