import { getChannelSimOptions, updateChannelSimOptions, getIoSimContent } from "../../api/Himalayas/channel";
import apiProcess from "../../api/utility";

function getSimulationOptions(channelId) {
  return apiProcess(getChannelSimOptions, channelId);
}

function updateSimulationOptions({ channelId, options, allInterfaces }) {
  return apiProcess(updateChannelSimOptions, { channelId, options, allInterfaces })
}

function getIoSimContentPromise(channelId) {
  return apiProcess(getIoSimContent, channelId)

}

export {
  getSimulationOptions,
  updateSimulationOptions,
  getIoSimContentPromise
}