import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Menu from './Menu';
import Panel from '@/components/Panel';
import {
  openPanel,
  closePanel,
} from '../store/actionCreators';
import "./index.css"

const menu = [{
  key: 'Design Information',
  label: 'Design Information',
  children: [{
    key: 'General Info',
    label: 'General Info'
  }, {
    key: 'Stackup',
    label: 'Stackup',
  }]
}, {
  key: 'Simulation Setup',
  title: 'Simulation Setup',
  children: [
    {
      label: 'PCIe',
      key: 'SERDES',
    }
  ]
}];

class MenuPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(value) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.props.openPanel(value);
    }
  }

  render() {
    const { closePanel, defaultLeft, defaultTop } = this.props;

    return (
      <Panel
        visible
        onCancel={closePanel}
        width='320px'
        position='panel-top-left'
        className='menu-panel'
        draggable
        left={defaultLeft}
        top={defaultTop}
      >
        <div className='menu-list'>
          <Menu menu={menu} defaultExpanded={true} onItemClick={this.onClick} />
        </div>
      </Panel>
    )
  }
}

const mapState = (state) => {
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return { defaultLeft, defaultTop }
}


const mapDispatch = (dispatch) => ({
  openPanel(type, designID) {
    dispatch(openPanel(type, designID))
  },
  closePanel() {
    dispatch(closePanel())
  },
})

export default connect(mapState, mapDispatch)(MenuPanel);