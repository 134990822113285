import React, { Fragment, PureComponent } from "react";
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Input, Popover, Select } from "antd";
import _ from 'lodash';
import '../../../../publicCss/netlist.css';

const Option = Select.Option;
class Port extends PureComponent {

  constructor(props) {
    super(props);
    const { pin } = props;
    this.state = {
      searchValue: "",
      selectFile: pin.model || { libraryId: "", subckt: "", port: "" },
      visible: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { pin } = this.props;
    if (!_.isEqual(pin.model, prevProps.pin.model)) {
      this.setState({
        selectFile: pin.model || { libraryId: "", subckt: "", port: "" },
      })
    }
  }

  selectNodesFile = (key) => {
    const [libraryId, subckt] = key.split('::')
    this.setState({
      selectFile: {
        libraryId,
        subckt,
        port: ""
      }
    }, () => {
      const { selectFile } = this.state;
      this.props.selectOpenSubckt(selectFile.libraryId, selectFile.subckt)
    })
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { pin, index } = this.props;
    const { pinName } = pin;
    const popover = document.getElementById(`${pinName}-${index}-pre-layout-popover`);
    if (!popover || !popover.contains(target)) {
      this.setState({
        visible: false
      })
    }
  }


  initSelectFile = (e) => {
    const { selectFile } = this.state;
    const { libraries, portsObj, selectOpenSubckt, lastOpenSubckt } = this.props;
    this.openPortSelect(e)
    if (lastOpenSubckt) {
      const libraryIds = Object.keys(lastOpenSubckt);
      if (libraryIds.length) {
        const libraryId = libraryIds[0];
        const subckt = lastOpenSubckt[libraryId];
        const selectFile = { libraryId: libraryId, subckt: subckt, port: "" };
        this.setState({
          selectFile
        })
        return
      }
    }
    if ((!selectFile.libraryId || !libraries.find(item => selectFile.libraryId === item.id && selectFile.subckt === item.subckt)) && libraries.length) {
      const libraryId = libraries[0].id;
      if (portsObj[libraryId] && portsObj[libraryId].length) {
        const selectFile = { libraryId: libraries[0].id, subckt: libraries[0].subckt || "", port: "" };
        this.setState({
          selectFile
        })
        if (!selectOpenSubckt[libraryId] && libraries[0].subckt) {
          this.props.selectOpenSubckt(libraryId, libraries[0].subckt)
        }
      }
    }
  }

  selectPinNode = (port) => {
    this.setState({
      selectFile: {
        ...this.state.selectFile,
        port
      },
      visible: false
    }, () => {
      const { selectFile } = this.state;
      const { pin } = this.props;
      this.props.saveModel(pin, selectFile)
    })
  }

  deleteSelectNode = (e) => {
    e && e.stopPropagation();
    this.setState({
      selectFile: {
        libraryId: "",
        subckt: "",
        port: ""
      }
    }, () => {
      const { selectFile } = this.state;
      const { pin } = this.props;
      this.props.saveModel(pin, selectFile)
    })
  }

  searchNode = (e) => {
    const value = e.target.value;
    this.setState({
      searchValue: value
    })
  }

  openPortSelect = (e) => {
    e && e.stopPropagation()
    this.setState({
      visible: true
    })
  }

  nodeSelectRender = () => {
    const { searchValue, selectFile } = this.state;
    const { pin, libraries = [], portsObj, usedPorts } = this.props;
    const { pinName } = pin;
    const { libraryId, subckt, port } = selectFile;
    const subckts = portsObj[libraryId] || []
    const currentSubckt = subckts.find(s => s.name === subckt);
    const ports = currentSubckt ? currentSubckt.ports.filter(port => port.includes(searchValue)) : [];
    return (
      <div className='nodes-content' style={{ maxHeight: 400, maxWidth: 400 }}>
        <div className='nodes-content-header'>
          <span className='nodes-content-header-span'>{pinName} - Port Select</span>
        </div>
        <div className="node-list-body-with-search">
          <div className="node-list-body-search-wrapper">
            <Select
              placeholder={`Select Model`}
              className='nodes-list-file-select'
              value={libraryId && subckt ? `${libraryId}::${subckt}` : ''}
              popupClassName='nodes-list-file-dropdown'
              popupMatchSelectWidth={false}
              onChange={this.selectNodesFile}
            >
              {libraries.map(item => (
                <Option
                  key={`${item.id}::${item.subckt}`}
                  title={`${item.name}::${item.subckt}`}
                  className='spice-nodes-content-file'
                >
                  {`${item.name}::${item.subckt}`}
                </Option>
              ))
              }
            </Select>
            <Input
              placeholder={`Search Ports`}
              allowClear
              suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='node-list-ul' style={{ maxHeight: 200 }}>
              {ports.map(item => {
                const selected = usedPorts.find(used => used.libraryId === libraryId && used.subckt === subckt && used.port === item)
                return <li
                  key={item}
                  title={item}
                  className={item === port ? 'current-pin-selected-node-li' : (selected ? 'node-li-selected' : '')}
                  onClick={() => selected ? null : this.selectPinNode(item)}
                >
                  {item.info ? <Fragment>{item.port}&nbsp;&nbsp;&nbsp;&nbsp;{item.info}</Fragment> : item}
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { pin, index, fixed } = this.props;
    const { model, pinName } = pin;
    const { visible } = this.state;
    return (
      <div className='spice-pin-die-value-box' id={`${pinName}-${index}-pre-layout-port`}>
        <Popover
          overlayClassName='spice-port-select-Popover'
          content={!fixed ? this.nodeSelectRender() : null}
          title=""
          trigger="click"
          placement={"top"}
          open={visible}
          id={`${pinName}-${index}-pre-layout-popover`}
        >
          {
            model.port ?
              <div className='spice-pin-input' title={`${pinName} - ${model.port}`} onClick={this.openPortSelect}>
                <span>{model.port}</span>
                {!fixed && <CloseCircleFilled
                  className='spice-pin-node-delete-icon'
                  onClick={(e) => { this.deleteSelectNode(e) }} />}
              </div>
              : <div className='spice-pin-input port-empty-input' title={pinName} onClick={!fixed ? this.initSelectFile : null}>
                <span>Port</span>
              </div>
          }
        </Popover>
      </div>
    );
  }
}

export default Port;