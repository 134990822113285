import React, { Component, Fragment, createRef } from 'react';
import { Input } from 'antd';
import ModelDialog from "../../../../components/SelectModel/ModelDialog";
import { splitNumUnit } from '../../../../services/helper/numberHelper';
import UnitAddonAfter from '../../../../components/UnitAddonAfter';
import { changeUnit } from '../../../../services/helper/RLCValue';
import './index.css';

const CIRCLE = 'circle', SQUARE = 'square', RECT = 'rectangle', OVAL = 'oval'
class PadStackDialog extends Component {
  constructor(props) {
    super(props);
    const { trigger, record, shapIndex } = props;
    const shape = record[shapIndex] || CIRCLE;

    this.state = {
      shape,
    }
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById(trigger ? trigger : "root");
  }

  componentDidMount() {
    this.resize()
    document.addEventListener("mousedown", this.handleClickOutside, true);
    window.addEventListener("resize", this.resize);
    this.getDefaultValue();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, true);
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.setState({
      offset: this.inputRef.current.getBoundingClientRect(),
    });
  };

  handleClickOutside = (e) => {
    const { target } = e;
    if (!this.dialogRoot.contains(target)) {
      this.handleSave();
      this.props.save();
    }
  };

  getDefaultValue = () => {
    const { shape } = this.state;
    const { record, dataIndex } = this.props;
    const params = record[dataIndex] && record[dataIndex].length ? record[dataIndex] : ['0mil', '0mil'];
    let stateObj = {};
    switch (shape) {
      case RECT:
      case OVAL:
        const [width, height, ...others] = params;
        const { num: widthNum, unit: widthUnit } = splitNumUnit(width);
        const { num: heightNum, unit: heightUnit } = splitNumUnit(height);
        stateObj = { widthNum, widthUnit, heightNum, heightUnit }
        break;
      case SQUARE:
      case CIRCLE:
      default:
        const { num, unit } = splitNumUnit(params[0]);
        stateObj = { num, unit }
        break;
    }
    this.setState({
      ...stateObj
    })
  }

  addonAfter = (unit, key, numKey) => {
    return UnitAddonAfter({
      unit,
      changeUnit: (newUnit) => this.changeUnit(newUnit, key, numKey),
      list: ['mil', 'um', 'mm'],
      className: "pad-stack-hole-size-unit",
      getPopupContainer: this.dialogRoot
    })
  }

  changeInputValue = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    })
  }

  changeUnit = (unit, key, numKey) => {
    const num = this.state[numKey];
    const oldUnit = this.state[key];
    const newNum = changeUnit(num, oldUnit, unit, 3);
    this.setState({
      [key]: unit,
      [numKey]: newNum
    })
  }

  renderDialog = () => {
    const { offset } = this.state;
    if (offset === undefined) return null;
    return <ModelDialog
      offset={this.inputRef}
      root={this.dialogRoot}
    >
      <div className="pad-stack-hole-size-body">{this.inputRenderByShape()}</div>
    </ModelDialog>
  }

  inputRenderByShape = () => {
    const { shape, num = '', unit = 'mil', widthNum = '', heightNum = '', widthUnit = 'mil', heightUnit = 'mil' } = this.state;
    switch (shape) {
      case RECT:
      case OVAL:
        return <Fragment>
          <div className="pad-stack-hole-size-content">
            <span className="pad-stack-hole-size-title">Width</span>
            <Input
              addonAfter={this.addonAfter(widthUnit, 'widthUnit', 'widthNum')}
              value={widthNum}
              onChange={(e) => this.changeInputValue(e, 'widthNum')}
              size='small'
              className='pad-stack-hole-size-input'
            />
          </div>
          <div className="pad-stack-hole-size-content">
            <span className="pad-stack-hole-size-title">Height</span>
            <Input
              addonAfter={this.addonAfter(heightUnit, 'heightUnit', 'heightNum')}
              value={heightNum}
              onChange={(e) => this.changeInputValue(e, 'heightNum')}
              size='small'
              className='pad-stack-hole-size-input'
            />
          </div>
        </Fragment>
      case SQUARE:
        return <Fragment>
          <div className="pad-stack-hole-size-content">
            <span className="pad-stack-hole-size-title">Size</span>
            <Input
              addonAfter={this.addonAfter(unit, 'unit', 'num')}
              value={num}
              autoFocus={true}
              onChange={(e) => this.changeInputValue(e, 'num')}
              size='small'
              className='pad-stack-hole-size-input'
            />
          </div>
        </Fragment>
      case CIRCLE:
      default:
        return <Fragment>
          <div className="pad-stack-hole-size-content">
            <span className="pad-stack-hole-size-title">Diameter</span>
            <Input
              addonAfter={this.addonAfter(unit, 'unit', 'num')}
              value={num}
              autoFocus={true}
              onChange={(e) => this.changeInputValue(e, 'num')}
              size='small'
              className='pad-stack-hole-size-input'
            />
          </div>
        </Fragment>
    }
  }

  handleSave = () => {
    const { shape, num = '', unit = 'mil', widthNum = '', heightNum = '', widthUnit = 'mil', heightUnit = 'mil' } = this.state;
    const { record } = this.props;
    switch (shape) {
      case RECT:
        this.props.dialogSave([`${widthNum}${widthUnit}`, `${heightNum}${heightUnit}`], record);
        break;
      case OVAL:
        this.props.dialogSave([`${widthNum}${widthUnit}`, `${heightNum}${heightUnit}`, `${Number(widthNum) / 2}${widthUnit}`], record);
        break;
      case SQUARE:
        this.props.dialogSave([`${num}${unit}`], record);
        break;
      case CIRCLE:
      default:
        this.props.dialogSave([`${num}${unit}`], record);
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <div className="editable-cell-value-wrap" ref={this.inputRef}>
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default PadStackDialog;