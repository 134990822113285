import { call, put, takeEvery, delay, select, fork, cancel, take } from 'redux-saga/effects';
import { getBMAChannelInfoPromise, updateBMAChannelInfoPromise } from '../../../../services/BMA';
import { updateBMAContentLoading, updateChannelInfo, updateGetFilesStatus, updateBMAContentStatus } from './action';
import { GET_BMA_CHANNEL_CONTENT_INFO, SAVE_BMA_CHANNEL_CONTENT_INFO, UPDATE_UPLOAD_FILE_LIST, SAVE_BMA_CHANNEL_CONTENT_SERVER } from './actionType';
import { openTabFooter, changeTabMenu } from '../../../MonitorStore/action';
import { BMA_CHANNEL } from '../../../../constants/treeConstants';
import { getVerificationWorkflow } from '../../store/simulation/action';

function* getChannelInfo(action) {
  const { id } = action
  try {
    const { RockyReducer: { project: { currentProjectName } } } = yield select()
    if (autoSaveTask) {
      yield call(saveRockyPackagePDNContentToServer)
      yield cancel(autoSaveTask);
    }
    autoSaveTask = yield fork(autoSave);
    let res = yield call(getBMAChannelInfoPromise, id);
    yield put(updateChannelInfo(res))

    yield put(openTabFooter())
    const verificationId = res && res.content && res.content.verificationId ? res.content.verificationId : id;
    yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], currentVerificationId: verificationId, verificationName: '', channelName: null, dataType: BMA_CHANNEL, projectName: currentProjectName }));
    yield put(getVerificationWorkflow(verificationId))

    yield put(updateBMAContentLoading(false))

  } catch (error) {
    console.error(error)
  }
}

let autoSaveTask = null;
export function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([SAVE_BMA_CHANNEL_CONTENT_SERVER]);
    let delayTime = 3000;
    if (newAction.type === SAVE_BMA_CHANNEL_CONTENT_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveRockyPackagePDNContentToServer, newAction.updateStatus);
    }
  }
}

function* debounce(time, callback, updateStatus) {
  yield delay(time);
  yield call(callback, updateStatus);
}

export function* saveRockyPackagePDNContentToServer() {
  const { BMAReducer: { channel: { id, content } } } = yield select();
  if (!id || !content || !Object.keys(content).length) {
    return;
  }
  try {
    const data = { bmaChannelId: id, content }
    // update backend content
    yield call(updateBMAChannelInfoPromise, data);
  } catch (e) {
    console.error(e)
  }
}

function* saveChannelContentInfo(action) {
  const { updateContent } = action;
  try {
    const { BMAReducer: { channel: { content } } } = yield select()
    const newContent = {
      ...content,
      ...updateContent
    }
    yield put(updateChannelInfo({ content: newContent }))
    yield put(updateBMAContentLoading(false))
    yield put({ type: SAVE_BMA_CHANNEL_CONTENT_SERVER });
  } catch (error) {
    console.error(error)
  }
}

function* updateFilesFileList(action) {
  const { projectId, uploadType, list } = action;
  try {
    if (uploadType === "spec") {
      const { BMAReducer: { channel: { content, id } } } = yield select()
      let res = yield call(getBMAChannelInfoPromise, id);
      const specTable = res && res.content && res.content.specTable ? res.content.specTable : [];

      const _content = {
        ...content,
        specTable
      }
      yield put(updateChannelInfo({ content: _content }))
      yield put(updateBMAContentStatus("specStatus", false))
    } else {
      yield put(updateBMAContentLoading(false))
      yield put(updateGetFilesStatus(true))
    }
  } catch (error) { console.error(error) }
}

function* BMAChannelSaga() {
  yield takeEvery(GET_BMA_CHANNEL_CONTENT_INFO, getChannelInfo);
  yield takeEvery(SAVE_BMA_CHANNEL_CONTENT_INFO, saveChannelContentInfo);
  yield takeEvery(UPDATE_UPLOAD_FILE_LIST, updateFilesFileList)
}
export default BMAChannelSaga;