import { select, event } from 'd3-selection';
import CanvasCircle from './CanvasCircle';
import CanvasEllipse from './CanvasEllipse';
import CanvasPath from './CanvasPath';
import CanvasPolygon from './CanvasPolygon';
import CanvasPolyline from './CanvasPolyline';
import CeXMark from '../geometry/CeXMark';
import CePolygon from '../geometry/CePolygon';
import CeSquare from '../geometry/CeSquare';
import CePolygonWithHole from '../geometry/CePolygonWithHole';
import CeOval from '../geometry/CeOval';
import CeCircle from '../geometry/CeCircle';
import GCPolygon from '../geometry/GCPolygon';
import CeRectangle from '../geometry/CeRectangle';
import CeSurface from '../geometry/CeSurface';
import CeLarc from '../geometry/CeLarc';
import CeLine from '../geometry/CeLine';
import globalVariable from '../globalVariable';
import { FASTPI, CASCADE } from '../../constants/pageType';

var CanvasObjectList = function (clickCallback) {
  //.call(this);
  this.canvasLineList = [];
  this.canvasPathList = [];
  this.canvasLarcList = [];
  this.canvasCircleList = [];
  this.canvasPolygonList = [];
  this.canvasEllipseList = [];
  this.clickCallback = clickCallback || function () { }
};

//CanvasObjectList.prototype = Object.create(CanvasObject.prototype);
//CanvasObjectList.prototype.constructor = CanvasObjectList;

/** Add an object to the list.
 *  @param canvasObject - the object to be added, must be one of CanvasPath,
 *                        CanvasCircle or CanvasPolygon
 */
CanvasObjectList.prototype.addObject = function (canvasObject) {
  let shape = "";
  if (canvasObject instanceof CanvasPath) {
    this.canvasPathList.push(canvasObject);
    shape = "path";
  } else if (canvasObject instanceof CanvasCircle) {
    this.canvasCircleList.push(canvasObject);
    shape = "circle";
  } else if (canvasObject instanceof CanvasPolygon) {
    this.canvasPolygonList.push(canvasObject);
    shape = "polygon";
  } else if (canvasObject instanceof CanvasEllipse) {
    this.canvasEllipseList.push(canvasObject);
    shape = "ellipse";
  } else if (canvasObject instanceof CanvasPolyline) {
    this.canvasLineList.push(canvasObject);
    shape = "polyline";
  }

  return shape;
};

var clickEventHandling = function (d, callback) {
  event.stopPropagation();
  if (event.defaultPrevented) {
    return;
  }

  var nets = getElementsByPosition(event.clientX, event.clientY);
  // multi nets select
  let object = {};
  if (nets.length > 1) {
    object.nets = nets;
    object.multi = event.ctrlKey === true;
  }
  else {
    object.nets = [d.net];
    object.multi = event.ctrlKey === true;
  }
  callback(object);
};

function getElementsByPosition(x, y) {
  var element = document.elementFromPoint(x, y);
  if (element.tagName !== 'svg') {
    select(element).attr('display', 'none');
    var nets = getElementsByPosition(x, y);
    select(element).attr('display', null);
    var net = element.__data__.net;
    if (net && nets.indexOf(net) < 0)
      nets.push(net)
    return nets;
  } else {
    return [];
  }
}

/** redraw all the objects in the list
 *  @param svgElement - the root element passed as a d3 selector
 */
var element_tip = select("body")
  .append("div")
  .attr("class", "element-tip")
  .style('visibility', 'hidden');
/**
 * draw all the objects in the list
 * drawing order: line, circle, path, Larc, polygon, ellipse
 * @param {HTMLElement} svgElement 
 * @param {string} color line stroke color
 */
CanvasObjectList.prototype.drawSVG = function (svgElement, color, ifFill = false) {
  const _this = this;

  svgElement
    .append('g')
    .attr('stroke', color)

  if (this.canvasLineList.length)
    svgElement
      .select("g")
      .selectAll('polyline')
      .data(this.canvasLineList)
      .enter()
      .append('polyline')
      .attr('points', function (d) {
        return d.points;
      })
      .attr('stroke-width', function (d) {
        return d.width;
      })
      .each(function (d) {
        if (d.rotate !== undefined && d.rotate !== 0) {
          select(this)
            .attr('transform', 'rotate(' + d.rotate + ',' + d.cx + ',' + d.cy + ')');
        }
        if (d.length) {
          var text = d.net +
            '\nLayer: ' + d.layer +
            '\nLength: ' + d.length +
            '\nWidth: ' + d.width +
            '\n(' + d.points.replace(' ', ')\n(').replace(/,/g, ', ') + ')';
          select(this)
            .append('title')
            .text(text);
          // d3.select(this)
          //   .on('mouseover', function () {
          //     element_tip[0][0].innerText = text;
          //     element_tip.style('top', d3.event.pageY + 'px')
          //                 .style('left', d3.event.pageX + 'px')
          //                 .transition()
          //                 .duration(500)
          //                 .style('visibility', 'visible');
          //   })
          //   .on('mouseout', function () {
          //     element_tip.transition()
          //                 .duration(500)
          //                 .style('visibility', 'hidden');
          //   });
        }

      });
  //.attr('stroke', color)

  if (this.canvasCircleList.length)
    svgElement
      .selectAll('circle')
      .data(this.canvasCircleList)
      .enter()
      .append('circle')
      .attr('class', function (d) {
        if (d.type === 'pad' || d.type === 'barrel') {
          select(this)
            .on('click', (d) => {
              clickEventHandling(d, _this.clickCallback)
            })
        }
        if (d.type === 'pad') {
          return 'pad';
        } else if (d.type === 'barrel')
          return 'barrel';
        else if (d.type === 'antipad')
          return 'antipad';
        // else{
        //     return 'lay_geom';
        // }
      })
      .attr('cx', function (d) {
        return d.xc;
      })
      .attr('cy', function (d) {
        return d.yc;
      })
      .attr('r', function (d) {
        return d.r;
      })
      .attr('fill', ifFill ? color : '')
      .each(function (d) {
        d.element = this;
        if (d.toolTip) select(this)
          .append('title')
          .text(d.toolTip);
      });

  if (this.canvasPathList.length)
    svgElement
      .selectAll('path')
      .data(this.canvasPathList)
      .enter()
      .append('path')
      .attr('class', function (d) {
        if (d.type === 'pad' || d.type === 'barrel') {

          select(this)
            .on('click', (d) => {
              clickEventHandling(d, _this.clickCallback)
            });
        }
        if (d.type === 'pad') {
          return 'pad';
        } else if (d.type === 'barrel')
          return 'barrel';
        else if (d.type === 'antipad')
          return 'antipad';

      })
      .attr('d', function (d) {
        return d.pathData;
      })
      .each(function (d) {
        d.element = this;
        if (d.width) select(this)
          .attr('stroke-width', d.width)
          .attr('stroke', d.type === 'barrel' ? '#555555' : null);
        if (d.rotate) select(this).attr('transform', 'rotate(' + d.rotate + ')');
        if (d.toolTip) select(this)
          .append('title')
          .text(d.toolTip);

      });

  if (this.canvasLarcList.length)
    svgElement
      .select('g')
      .selectAll('path')
      .data(this.canvasLarcList)
      .enter()
      .append('path')
      .attr('d', function (d) {
        return d.pathData;
      })
      .attr('stroke-width', function (d) {
        return d.width;
      })
      .attr('fill', 'none')
      .each(function (d) {
        d.element = this;
        if (d.toolTip) select(this)
          .append('title')
          .text(d.toolTip);

      });

  if (this.canvasPolygonList.length)
    svgElement
      .selectAll('polygon')
      .data(this.canvasPolygonList)
      .enter()
      .append('polygon')
      .attr('class', function (d) {
        if (d.type === 'pad' || d.type === 'barrel') {

          select(this)
            .on('click', (d) => {
              clickEventHandling(d, _this.clickCallback)
            });
        }
        if (d.type === 'pad') {
          return 'pad';
        } else if (d.type === 'barrel')
          return 'barrel';
        else if (d.type === 'antipad')
          return 'antipad';
        // else{
        //     return 'lay_geom';
        // }
      })
      .attr('points', function (d) {
        return d.polyData;
      })
      .attr('fill', ifFill ? color : '')
      .each(function (d) {
        d.element = this;
        if (d.toolTip) select(this)
          .append('title')
          .text(d.toolTip);
      });

  svgElement
    .selectAll('ellipse')
    .data(this.canvasEllipseList)
    .enter()
    .append('ellipse')
    .attr('class', function (d) {
      if (d.type === 'pad' || d.type === 'barrel') {

        select(this)
          .on('click', (d) => {
            clickEventHandling(d, _this.clickCallback)
          });
      }
      if (d.type === 'pad') {
        return 'pad';
      } else if (d.type === 'barrel')
        return 'barrel';
      else if (d.type === 'antipad')
        return 'antipad';
      // else{
      //     return 'lay_geom';
      // }
    })
    .attr('cx', function (d) {
      return d.cx;
    })
    .attr('cy', function (d) {
      return d.cy;
    })
    .attr('rx', function (d) {
      return d.rx;
    })
    .attr('ry', function (d) {
      return d.ry;
    })
    .each(function (d) {
      if (d.rotate !== 0) {
        select(this)
          .attr('transform', 'rotate(' + d.rotate + ',' + d.cx + ',' + d.cy + ')');
      }
      d.element = this;
      if (d.toolTip) select(this)
        .append('title')
        .text(d.toolTip);
    });

};

/**
 * draw vias in the list
 * @param {HTMLElement} svgElement 
 */
CanvasObjectList.prototype.drawVia = function (svgElement, color) {
  const _this = this;
  svgElement
    .selectAll('path')
    .data(this.canvasPathList)
    .enter()
    .append('path')
    .attr('class', function (d) {
      if (d.type === 'pad' || d.type === 'barrel') {

        select(this)
          .on('click', (d) => {
            clickEventHandling(d, _this.clickCallback)
          });
      }
      if (d.type === 'pad') {
        return 'pad';
      } else if (d.type === 'barrel')
        return 'barrel';
      else if (d.type === 'antipad')
        return 'antipad';

    })
    .attr('d', function (d) {
      return d.pathData;
    })
    .each(function (d) {
      d.element = this;
      if (d.width) select(this)
        .attr('stroke-width', d.width)
        .attr('stroke', d.type === 'barrel' ? '#555555' : null);
      if (d.rotate) select(this).attr('transform', 'rotate(' + d.rotate + ')');
      if (d.toolTip) select(this)
        .append('title')
        .text(d.toolTip);

    });

  svgElement
    .select('g')
    .selectAll('path')
    .data(this.canvasLarcList)
    .enter()
    .append('path')
    .attr('d', function (d) {
      return d.pathData;
    })
    .attr('stroke-width', function (d) {
      return d.width;
    })
    .attr('fill', 'none')
    .each(function (d) {
      d.element = this;
      if (d.toolTip) select(this)
        .append('title')
        .text(d.toolTip);

    });

  svgElement
    .selectAll('polygon')
    .data(this.canvasPolygonList)
    .enter()
    .append('polygon')
    .attr('class', function (d) {
      if (d.type === 'pad' || d.type === 'barrel') {
        select(this)
          .on('click', (d) => {
            clickEventHandling(d, _this.clickCallback)
          });
      }
      if (d.type === 'pad') {
        return 'pad';
      } else if (d.type === 'barrel')
        return 'barrel';
      else if (d.type === 'antipad')
        return 'antipad';
      // else{
      //     return 'lay_geom';
      // }
    })
    .attr('points', function (d) {
      return d.polyData;
    })
    .each(function (d) {
      d.element = this;
      if (d.toolTip) select(this)
        .append('title')
        .text(d.toolTip);
    });

  svgElement
    .selectAll('ellipse')
    .data(this.canvasEllipseList)
    .enter()
    .append('ellipse')
    .attr('class', function (d) {
      if (d.type === 'pad' || d.type === 'barrel') {

        select(this)
          .on('click', (d) => {
            clickEventHandling(d, _this.clickCallback)
          });
      }
      if (d.type === 'pad') {
        return 'pad';
      } else if (d.type === 'barrel')
        return 'barrel';
      else if (d.type === 'antipad')
        return 'antipad';
      // else{
      //     return 'lay_geom';
      // }
    })
    .attr('cx', function (d) {
      return d.cx;
    })
    .attr('cy', function (d) {
      return d.cy;
    })
    .attr('rx', function (d) {
      return d.rx;
    })
    .attr('ry', function (d) {
      return d.ry;
    })
    .each(function (d) {
      if (d.rotate !== 0) {
        select(this)
          .attr('transform', 'rotate(' + d.rotate + ',' + d.cx + ',' + d.cy + ')');
      }
      d.element = this;
      if (d.toolTip) select(this)
        .append('title')
        .text(d.toolTip);
    });

  svgElement
    .selectAll('circle')
    .data(this.canvasCircleList)
    .enter()
    .append('circle')
    .attr('class', function (d) {

      if (d.type === 'pad' || d.type === 'barrel') {
        select(this)
          .on('click', (d) => {
            clickEventHandling(d, _this.clickCallback)
          });
      }
      if (d.type === 'pad') {
        return 'pad';
      } else if (d.type === 'barrel')
        return 'barrel';
      else if (d.type === 'antipad')
        return 'antipad';
      // else{
      //     return 'lay_geom';
      // }
    })
    .attr('cx', function (d) {
      return d.xc;
    })
    .attr('cy', function (d) {
      return d.yc;
    })
    .attr('r', function (d) {
      return d.r;
    })
    .each(function (d) {
      d.element = this;
      if (d.toolTip) select(this)
        .append('title')
        .text(d.toolTip);
    });

};

/** add a canvas object from a geometry object
 *  @param orgGeomObj the CeGometry object to be added
 *  @param ceLocation a CeLocation object for the geometry transform. Could be null.
 *  @param templateObj a CanvasObject object containing the detailed information to be copied
 *                     to the geometries own canvas object
 */
CanvasObjectList.prototype.addGeometry = function (orgGeomObj, ceLocation, templateObj, ADD_GROM) {

  if (!orgGeomObj) return;

  /* 
  canvasCircleList: []
canvasEllipseList: []
canvasLarcList: []
canvasLineList: []
canvasPathList: []
canvasPolygonList:  */
  var geomObj = orgGeomObj.Clone();
  var canvasObj = null;
  if (geomObj instanceof CeSquare) {
    var gcPoly = geomObj;
    if (geomObj instanceof GCPolygon == false) {
      gcPoly = geomObj.ConvertToGCPolygon();
    }
    if (ceLocation)
      ceLocation.TransformGCPolygon(gcPoly);

    canvasObj = new CanvasPolygon();
    canvasObj.polyData = gcPoly.GetSvgPolygon();

  } else if (geomObj instanceof CeCircle) {
    if (ADD_GROM && ![FASTPI, CASCADE].includes(globalVariable.pageType)) {
      return;
    }
    if (ceLocation)
      geomObj.Transform(ceLocation);

    canvasObj = new CanvasCircle();

    canvasObj.xc = geomObj.mCenter.mX;
    canvasObj.yc = geomObj.mCenter.mY;
    canvasObj.r = 0.5 * geomObj.mDiameter;

  } else if (geomObj instanceof CeOval) {
    // var canvasObj = new CanvasEllipse();
    // if (ceLocation){
    //     geomObj.Transform(ceLocation);
    //     canvasObj.rotate = ceLocation.mRotAngle;
    // }
    // canvasObj.cx = geomObj.mCenter.mX;
    // canvasObj.cy = geomObj.mCenter.mY;
    // canvasObj.rx = 0.5 * geomObj.mWidth;
    // canvasObj.ry = 0.5 * geomObj.mHeight;
    if (ADD_GROM && ![FASTPI, CASCADE].includes(globalVariable.pageType)) {
      return;
    }
    canvasObj = new CanvasPolyline();
    if (ceLocation) {
      geomObj.Transform(ceLocation);
      canvasObj.rotate = ceLocation.mRotAngle;
    }

    canvasObj.width = geomObj.mHeight;
    canvasObj.cx = geomObj.mCenter.mX;
    canvasObj.cy = geomObj.mCenter.mY;
    var r = 0.5 * geomObj.mWidth;
    canvasObj.points = (canvasObj.cx - r + 0.5 * canvasObj.width) + "," + canvasObj.cy + ' ' +
      (canvasObj.cx + r - 0.5 * canvasObj.width) + ',' + canvasObj.cy;

  } else if (geomObj instanceof CePolygonWithHole) {
    canvasObj = this.addPolygonWithHole(geomObj, ceLocation);
  } else if (geomObj instanceof GCPolygon ||
    geomObj instanceof CePolygon ||
    geomObj instanceof CeRectangle ||
    geomObj instanceof CeSquare) {
    var gcPoly = geomObj;
    if (geomObj instanceof GCPolygon == false) {
      gcPoly = geomObj.ConvertToGCPolygon();
    }
    if (ceLocation)
      ceLocation.TransformGCPolygon(gcPoly);

    var canvasObj = new CanvasPolygon();
    canvasObj.polyData = gcPoly.GetSvgPolygon();

  } else if (geomObj instanceof CeSurface) {

    for (var i = 0; i < geomObj.mPolygons.length; i++) {
      this.addGeometry(geomObj.mPolygons[i], ceLocation, templateObj, ADD_GROM);
    }
  } else if (geomObj instanceof CeXMark) {
    if (ceLocation)
      geomObj.Transform(ceLocation);

    var canvasObj = new CanvasPath(),
      xc = geomObj.mCenter.mX,
      yc = geomObj.mCenter.mY,
      r = 0.5 * geomObj.mDiameter;

    canvasObj.pathData = 'M' + xc + ' ' + (yc + r) + 'L' + xc + ' ' + (yc - r) +
      'M' + (xc - r) + ' ' + yc + 'L' + (xc + r) + ' ' + yc;
    canvasObj.width = r / 2;
    if (orgGeomObj.rotate)
      canvasObj.rotate = orgGeomObj.rotate + ',' + xc + ',' + yc;
  } else
    console.error("Geometry type " + geomObj.GetGeomType() + " drawing is not yet supported.");

  if (canvasObj) {
    if (templateObj) {
      canvasObj.copyFrom(templateObj);
    }
    const shape = this.addObject(canvasObj);
    canvasObj.shape = shape;
    return canvasObj;
  }

}; // CanvasObjectList.prototype.addGeometry

/** Add a geometry from symbol ID
 *  @param refGeom - the CeRefGeometry object to be added
 *  @param symbolMgr - the CeSymbolManager object that holds the actual geometry
 *  @param templateObj a CanvasObject object containing the detailed information to be copied
 *                     to the geometries own canvas object
 */
CanvasObjectList.prototype.addRefGeometry = function (refGeom, symbolMgr, templateObj, ADD_GROM = null) {

  var canvasObj = null;
  var shapeObj = null;

  if (refGeom.mSymbolID >= 0) {

    var csGeomObj = symbolMgr.GetShapeGeomObj(refGeom.mSymbolID);

    if (csGeomObj == null) {
      console.error('Error: Symbol geometry with ID ' + refGeom.mSymbolID + ' is not found in symbol manager');
      return;
    }

    shapeObj = csGeomObj.GetGeometry();
    if (shapeObj == null) {
      console.error('Error: Symbol geometry with ID ' + refGeom.mSymbolID + ' is null.');
      return;
    }

    if (refGeom.mGeometry == null) {
      this.addGeometry(shapeObj, refGeom.mLocation, templateObj, ADD_GROM);
    }
  }

  if (refGeom.mGeometry != null) {

    if (shapeObj != null) {
      if (refGeom.mGeometry instanceof CeLarc) {
        //add Larc
        var larcObj = this.addLarc(refGeom.mGeometry, shapeObj.mDiameter);
        if (larcObj) {
          if (templateObj)
            larcObj.copyFrom(templateObj);
          this.canvasLarcList.push(larcObj);
        }
      } else if (refGeom.mGeometry instanceof CeLine) {
        if (ADD_GROM && ![FASTPI, CASCADE].includes(globalVariable.pageType)) {
          return;
        }
        var canvasObj = new CanvasPolyline();
        canvasObj.width = shapeObj.mDiameter;
        var d = refGeom.mGeometry;
        canvasObj.points = d.mStart.mX + "," + d.mStart.mY + ' ' + d.mEnd.mX + ',' + d.mEnd.mY;
        canvasObj.length = Math.sqrt(Math.pow(d.mStart.mX - d.mEnd.mX, 2) +
          Math.pow(d.mStart.mY - d.mEnd.mY, 2)).toFixed(2);
        if (canvasObj) {
          if (templateObj)
            canvasObj.copyFrom(templateObj);
          this.canvasLineList.push(canvasObj);
        }
      } else {
        var gcPolygons = refGeom.mGeometry.ConvertToGCPolygons(shapeObj);
        for (var i = 0; i < gcPolygons.length; i++) {
          this.addGeometry(gcPolygons[i], refGeom.mLocation, templateObj, ADD_GROM);
        }
      }
    } else {
      this.addGeometry(refGeom.mGeometry, refGeom.mLocation, templateObj, ADD_GROM);
    }
  }

}; // CanvasObjectList.prototype.addRefGeometry

/** Convert a polygon with hole into a CanvasObject
 *  @param polyhole - the CePolygonWithHole object to be added
 *  @param ceLocation - the CeLocation object containing the geomety transformation information
 */
CanvasObjectList.prototype.addPolygonWithHole = function (polyhole, ceLocation) {

  if (ceLocation)
    polyhole.Transform(ceLocation);

  var gcPoly = polyhole.ConvertToGCPolygon();
  var canvasObj = new CanvasPath();
  canvasObj.pathData = gcPoly.GetSvgPath();

  for (var i = 0; i < polyhole.mHoles.length; i++) {
    canvasObj.pathData += '\n';
    if (polyhole.mHoles[i] instanceof CePolygon) {
      if (polyhole.mHoles[i].IsCircle()) {
        var circle = polyhole.mHoles[i].ConvertToCircle();
        canvasObj.pathData += this.getCirclePath(circle.mCenter.mX, circle.mCenter.mY, circle.mDiameter) + ' ';
      } else {
        var gcPoly = polyhole.mHoles[i].ConvertToGCPolygon();
        canvasObj.pathData += gcPoly.GetSvgPath(null, true);
      }
    }
  }

  return canvasObj;

}; // CanvasObjectList.prototype.addPolygonWithHole

/** Convert a circle into a SVG path data section
 *  This is for the inner circle of a polygon only. The circle is converted into two arc sections:
 *      move to the center (no drawing)
 *      move to (-r, 0)
 *      arc to (r, 0)
 *      arc to (-r, 0)
 *  In the path data syntax, the 'a' command is followed by the parameters
 *      rx ry x-axis-rotation large-arc-flag sweep-flag x y
 *  The parameter 'sweep-flag' controls the arc direction. Refer to
 *  https://www.w3.org/TR/SVG/paths.html for more details.
 */
CanvasObjectList.prototype.getCirclePath = function (cx, cy, d) {
  var r = 0.5 * d;
  return 'M ' + cx + ' ' + cy + ' m -' + r + ' 0 a ' + r + ' ' + r + ' 0 0 0 ' + d + ' 0 a ' + r + ' ' + r + ' 0 0 0 -' + d + ' 0';
};

/** Convert a Larc into a CanvasObject
 *  @param geometry - the CeLarc object to be added
 *  @param diameter - the diameter of the shape obj, corresponding to the width of the Larc
 */
CanvasObjectList.prototype.addLarc = function (ceLarc, diameter) {
  var canvasObj = new CanvasPath();

  var sweep = ceLarc.mCCW ? 0 : 1;
  var largeArc = ceLarc.GetLargeArcFlag();
  var radius = diameter / 2;
  // var arcs = ceLarc.GetConvetArcs(radius);
  // var inner = arcs[0];
  // var outer = arcs[1];

  canvasObj.pathData = this.getArcPath(ceLarc.GetRadius(), sweep, largeArc, ceLarc.mStart, ceLarc.mEnd);
  canvasObj.width = diameter;
  //add inner arc
  //canvasObj.pathData = this.getArcPath(inner.r, sweep, largeArc, inner.end, inner.start);
  //add circle arc
  //canvasObj.pathData += this.getArcPath(radius, 1-sweep, 0, outer.end);
  //add outer arc
  //canvasObj.pathData += this.getArcPath(outer.r, 1-sweep, largeArc, outer.start);
  //add circle arc
  //canvasObj.pathData += this.getArcPath(radius, 1-sweep, 0, inner.start);

  return canvasObj;

}; // CanvasObjectList.prototype.addLarc

/** Convert a arc into a SVG path data section
 *  @param r - radius of the arc
 *  @param sweep - the sweep-flag, 0 means convert clock wise(CCW), 1 means clock wise
 *  @param largeArc - the large-arc-flag, determines if the arc should be greater than or less than 180 degrees,
 *                    0 - less than 180 degrees
 *  @param end - the end point of arc
 *  @param end - the start point of arc
 */
CanvasObjectList.prototype.getArcPath = function (r, sweep, largeArc, end, start) {
  var path = '';
  if (start) path += 'M' + start.mX + ' ' + start.mY + ' ';
  path += 'A' + r + ' ' + r + ' ' + '0 ' + largeArc + ' ' + sweep + ' ' + end.mX + ' ' + end.mY + ' ';
  return path;
};

export default CanvasObjectList;