/**
 * Split component name
 *
 * @param name component name "U8" / "U2 A"
 * @param display Whether for display  true "U2 A" ==> "U2 A " | false "U2 A" ==> "U2"
 */
function splitComponentName(name, display = false) {
  //name "U2 A"
  if (!name || typeof (name) !== 'string' || display) {
    return name;
  }
  const [compName, num] = name.split(/[\s\t]+/g);
  /*   if (display && num) {
      return `${compName}(${num})`;
    } else { */
  return compName; // "U2"
  /* } */
}

export { splitComponentName };