import { ModelPin } from '../../helper/IntegratedInterface';
import { getIbisModelList } from '../library';
import { getModelNameRegBySignal } from './setupData';
import { DDR3, DDR3L, DDR4, DDR4L, LPDDR4, DDR5, LPDDR5, MEMORY_BYTE_PROJECT_TYPES, MEMORY_CLK_PROJECT_TYPES, DDR5_RDIMM, DDR5_SODIMM, DDR5_UDIMM, DDR5_RDIMM_CARD, DDR5_SODIMM_CARD, DDR5_UDIMM_CARD, DDR5_RDIMM_X4 } from '../constants';
import { parseIBISModelSelector } from '../../LibraryHelper/IBISModelHelper';
import { TX_MODEL_TYPES, RX_MODEL_TYPES, AUTO } from '../../LibraryHelper';

function exsitEnableVoltage(type) {
  const _type = type.toUpperCase();
  if (_type.includes('I/O') || _type.includes('IO') || _type === 'TRISTATE' || _type === '3-STATE' || _type === '3STATE') {
    return true;
  };
  return false;
}
class IbisModelHelper {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  getIbisFiles() {
    return Object.keys(this);
  }

  deleteModel(id) {
    delete this[id];
  }

  /*get model list by filename and type(Rx, Tx)*/
  getIbisModelList(id, type) {
    const models = this[id];
    const modelTypes = type === 'Tx' ? [...TX_MODEL_TYPES] :
      type === 'Rx' ? RX_MODEL_TYPES : null;
    if (modelTypes === null || !models) {
      return models;
    } else {
      return { models: models.models.filter(model => modelTypes.includes(model.type)), selectorModels: models.selectorModels, packages: models.packages }
    }
  }

  parseModelSelector(id, fileContent) {
    const models = parseIBISModelSelector(fileContent);
    //models : { models, selectorModels, packages }
    this[id] = models;
  }

  getIbisPackageList(id) {
    const models = this[id];

    if (!models || !models.packages) {
      return null;
    } else {
      return models.packages;
    }
  }
}

// Usage - Driver/Receiver
function getPins({ usage }) {
  let pins = [];
  if (usage === 'Receiver') { return pins }
  pins = AUTO;
  // ModelPin
  const modelPins = pins.map(pinName => new ModelPin({ pinName }));
  return modelPins;
}

function getIBISSortedList(modelsObj) {
  let list = [];
  if (modelsObj && modelsObj.models) {
    const modelList = JSON.parse(JSON.stringify(modelsObj.models));
    const selectorModels = modelsObj.selectorModels ? JSON.parse(JSON.stringify(modelsObj.selectorModels)) : [];
    let selectorModelList = []
    for (let item of selectorModels) {
      item.models && item.models.forEach(model => {
        selectorModelList.push({ selector: item.selector, ...model });
      })
    }
    for (let item of modelList) {
      const find = selectorModelList.find(model => model.name === item.name);
      if (find) {
        list.push({ ...find, ...item });
      } else {
        list.push({ ...item });
      }
    }
  }
  return list;
}

function getIBISModelList({ type, fileName, fileId, libType }) {
  let obj = {
    libraryId: fileId,
    fileName,
    usage: type === 'Driver' || type === 'tx' ? 'Tx' : 'Rx',
    libType
  }
  return getIbisModelList(obj);
}

const TypeList = ['DQS', 'DM', 'WCK']
//get default model type and model name
function getIBISModelName({ modelObj, usage, signal, compType, speed, projectType, interfaceName }) {
  let modelList = getIBISSortedList(modelObj);
  let modelName = "", modelType = "", deviceVcc = "", enable = "";
  const packages = modelObj && modelObj.packages ? modelObj.packages : [];
  const type = getModelNameRegBySignal(signal, interfaceName);
  //find DQ reg
  //(?<![a-zA-Z0-9])  The character before type is not a number or letter
  //(?!S) The character after type is not a number or letter
  const dqReg = new RegExp(`(?<![a-zA-Z0-9])DQ(?!S)`, 'ig');
  const byteRegObj = type === 'DQ' ? dqReg : new RegExp(`${type}`, 'ig');
  //Memory
  if (compType === 'Memory') {
    //signal is (DQ/DQS/DM)
    if ([...TypeList, 'DQ'].includes(type) && MEMORY_BYTE_PROJECT_TYPES.includes(projectType)) {
      //find selector includes DQ( but not DQS)
      //find selector includes DQS( but not DQ)
      let DQList = modelList.filter(item => item.selector && item.selector.match(byteRegObj));
      if ([...TypeList].includes(type) && DQList.length === 0) {
        //If DQS(DM) not find ,find DQ
        DQList = modelList.filter(item => item.selector && item.selector.match(dqReg));
      }

      //find type includes I/O
      let IOList = DQList.filter(item => item.type === 'I/O');
      let outList = DQList.filter(item => item.type === 'Output');
      let inputList = DQList.filter(item => item.type === "Input");

      if ([...TypeList].includes(type) && IOList.length === 0 && outList.length === 0 && usage === 'Driver') {
        //If DQS(DM) not find ,find DQ
        DQList = modelList.filter(item => item.selector && item.selector.match(dqReg));
        IOList = DQList.filter(item => item.type === 'I/O');
        outList = DQList.filter(item => item.type === 'Output');
      }

      if ([...TypeList].includes(type) && IOList.length === 0 && inputList.length === 0 && usage === 'Receiver') {
        //If DQS(DM) not find ,find DQ
        DQList = modelList.filter(item => item.selector && item.selector.match(dqReg));
        IOList = DQList.filter(item => item.type === 'I/O');
        inputList = DQList.filter(item => item.type === "Input");
      }

      //find model
      const model = getModel({ IOList, outList, inputList, usage, speed, projectType });
      if (model && model.modelName && model.modelType) {
        return { modelName: model.modelName, modelType: model.modelType, deviceVcc: model.deviceVcc, enable: model.enable }
      }
    }

    //CLK/ADR/CMD Receiver
    if ((type === 'CLK' || type === 'ADR' || type === 'CMD') && MEMORY_CLK_PROJECT_TYPES.includes(projectType)) {
      let reg = null;
      if (type === 'CLK') {
        //find selector includes CLK/CLKIN
        reg = `(CLK)|(CLKIN)|(CLOCK)`;
      } else if (type === 'ADR') {
        //find selector includes ADR/ADD/ADDR
        reg = `(ADR)|(ADD)|(ADDR)|(ADDRESS)|(CA)`;
      } else if (type === 'CMD') {
        //find selector includes CMD
        reg = `(CMD)|(COMMAND)|(CA)`;
      }
      const clkRegObj = new RegExp(reg, 'ig');
      const notIncludeKey = type !== 'CLK' ? `(CLK)|(CK)|(DM)|(DQ)|(DQS)` : "(DM)|(DQ)|(DQS)";
      //find selector by reg
      let CLKList = modelList.filter(item => item.selector && item.selector.match(clkRegObj));

      if (CLKList.length === 0) {
        //if reg not find ,find INPUT
        const inputRegObj = new RegExp(`(INPUT)`, 'ig');
        //find selector includes INPUT
        CLKList = modelList.filter(item => item.selector && item.selector.match(inputRegObj) && (!item.selector.match(notIncludeKey)));

        if (CLKList.length === 0) {
          const inputRegObj = new RegExp(`(${reg})`, 'ig');
          //find description includes reg
          CLKList = modelList.filter(item => item.description && item.description.match(inputRegObj) && (!item.description.match(notIncludeKey)));
          if (CLKList.length === 0) {
            const inputRegObj = new RegExp(`(${reg})`, 'ig');
            //find model name includes reg
            CLKList = modelList.filter(item => item.name && item.name.match(inputRegObj) && (!item.name.match(notIncludeKey)));
            if (CLKList.length === 0) {
              const inputRegObj = new RegExp(`(INPUT)`, 'ig');
              //find model name includes reg/INPUT
              CLKList = modelList.filter(item => item.name && item.name.match(inputRegObj) && (!item.name.match(notIncludeKey)));
            }
          }
        }
      }
      //find model
      const model = getCLKModel({ CLKList, speed });
      if (model && model.modelName && model.modelType) {
        return { modelName: model.modelName, modelType: model.modelType, deviceVcc: model.deviceVcc, enable: model.enable }
      }
    }
  } else if (compType === 'Controller') {
    if ([...TypeList, 'DQ'].includes(type) && MEMORY_BYTE_PROJECT_TYPES.includes(projectType)) {
      const standardType = getModelStandard(projectType);
      //find model by buffer signaling standard
      const reg = standardType ? new RegExp(`${standardType}`, 'ig') : new RegExp(`${projectType}`, 'ig');
      let standardSelectorList = modelList.filter(item => (item.description && item.description.match(reg)) || (item.name && item.name.match(reg)));
      //Find model list by model selector/description
      let newModelList = standardSelectorList && standardSelectorList.length > 0 ? standardSelectorList : modelList;
      let selectorList = newModelList.filter(item => (item.selector && item.selector.match(byteRegObj)) || (item.description && (item.description.match(byteRegObj))) || (item.name && (item.name.match(byteRegObj))));

      let notIncludeKey = '';
      if (type === 'DQ') {
        notIncludeKey = `(CLK)|(CLOCK)|(DM)|(ADR)|(ADD)|(ADDR)|(ADDRESS)|(CMD)|(COMMAND)`
      } else if (type === 'DQS') {
        notIncludeKey = `(CLK)|(CLOCK)|(DM)|(ADR)|(ADD)|(ADDR)|(ADDRESS)|(CMD)|(COMMAND)`
      } else if (type === 'DM') {
        notIncludeKey = `(CLK)|(CLOCK)|(ADR)|(ADD)|(ADDR)|(ADDRESS)|(CMD)|(COMMAND)`
      }

      const filterReg = new RegExp(notIncludeKey, 'ig');
      if (selectorList.length === 0) {
        selectorList = standardSelectorList.filter(item => (!item.selector || !item.selector.match(filterReg)) && (!item.description || !item.description.match(filterReg)) && (!item.name || !item.name.match(filterReg)));
      }

      if (selectorList.length === 0) {
        //find model selector by signal name in pin section
        let modelSelector = getModelSelector(signal, packages);
        const RegObj = new RegExp(`(${modelSelector})`, 'ig');
        //find model list by modelSelector
        selectorList = modelList.filter(item => item.selector && item.selector.match(RegObj));
      }

      if ([...TypeList].includes(type) && selectorList.length === 0) {
        notIncludeKey = `(CLK)|(CLOCK)|(ADR)|(ADD)|(ADDR)|(ADDRESS)|(CMD)|(COMMAND)`;
        let standardSelectorList = modelList.filter(item => (item.description && item.description.match(reg)) || (item.name && item.name.match(reg)));
        //Find model list by model selector/description
        let newModelList = standardSelectorList && standardSelectorList.length > 0 ? standardSelectorList : modelList;
        selectorList = newModelList.filter(item => (item.selector && item.selector.match(/DQ/ig)) || (item.description && (item.description.match(/DQ/ig))) || (item.name && (item.name.match(/DQ/ig))));
        if (selectorList.length === 0) {
          selectorList = standardSelectorList.filter(item => (!item.selector || !item.selector.match(filterReg)) && (!item.description || !item.description.match(filterReg)) && (!item.name || !item.name.match(filterReg)));
        }
        if (selectorList.length === 0) {
          //find model selector by signal name in pin section
          let modelSelector = getModelSelector(signal, packages);
          const RegObj = new RegExp(`(${modelSelector})`, 'ig');
          //find model list by modelSelector
          selectorList = modelList.filter(item => item.selector && item.selector.match(RegObj));
        }
      }

      //find model list by model type is I/O
      let IOList = selectorList.filter(item => item.type === 'I/O');
      let outList = selectorList.filter(item => item.type === 'Output' || item.type === '3-state');
      let inputList = selectorList.filter(item => item.type === 'Input');
      if (IOList && IOList.length > 0) {
        let model = getControllerModel(IOList, usage, 'I/O');
        if (model) {
          modelName = model.modelName;
          modelType = model.modelType;
          deviceVcc = model.deviceVcc;
          enable = model.enable;
        }
        if (modelName && modelType) {
          return { modelName, modelType, deviceVcc, enable }
        }
      }
      if (usage === 'Driver') {
        let model = getControllerModel(outList, usage, 'Output');
        if (model) {
          modelName = model.modelName;
          modelType = model.modelType;
          deviceVcc = model.deviceVcc;
          enable = model.enable;
        }
        if (modelName && modelType) {
          return { modelName, modelType, deviceVcc, enable }
        } else {
          const model = getModel({ IOList, outList, inputList, usage, speed, projectType });
          if (model && model.modelName && model.modelType) {
            return { modelName: model.modelName, modelType: model.modelType, deviceVcc: model.deviceVcc, enable: model.enable }
          }
        }
      } else if (usage === 'Receiver') {
        let model = getControllerModel(inputList, usage, 'Input');
        if (model) {
          modelName = model.modelName;
          modelType = model.modelType;
          deviceVcc = model.deviceVcc;
          enable = model.enable;
        }
        if (modelName && modelType) {
          return { modelName, modelType, deviceVcc, enable }
        } else {
          const model = getModel({ IOList, outList, inputList, usage, speed, projectType });
          if (model && model.modelName && model.modelType) {
            return { modelName: model.modelName, modelType: model.modelType, deviceVcc: model.deviceVcc, enable: model.enable }
          }
        }
      }
    }

    //CLK/ADR/CMD Receiver
    if ((type === 'CLK' || type === 'ADR' || type === 'CMD') && MEMORY_CLK_PROJECT_TYPES.includes(projectType)) {
      let regStr = null;
      if (type === 'CLK') {
        //find selector includes CLK/CLKIN
        regStr = `(CLK)|(CLKIN)|(CLOCK)`;
      } else if (type === 'ADR') {
        //find selector includes ADR/ADD/ADDR
        regStr = `(ADR)|(ADD)|(ADDR)|(ADDRESS)`;
      } else if (type === 'CMD') {
        //find selector includes CMD
        regStr = `(CMD)|(COMMAND)`;
      }

      const notIncludeKey = type !== 'CLK' ? `(CLK)|(DM)|(DQ)|(DQS)` : "(DM)|(DQ)|(DQS)";
      const filterReg = new RegExp(notIncludeKey, 'ig');

      const reg = new RegExp(regStr, 'ig');
      const standardType = getModelStandard(projectType);
      const standardReg = standardType ? new RegExp(`${standardType}`, 'ig') : new RegExp(`${projectType}`, 'ig');
      let standardSelectorList = modelList.filter(item => (item.description && item.description.match(standardReg)) || (item.name && item.name.match(standardReg)));
      let newModelList = standardSelectorList && standardSelectorList.length > 0 ? standardSelectorList : modelList;
      let selectorList = newModelList.filter(item => (item.selector && item.selector.match(reg)) || (item.description && (item.description.match(reg))) || (item.name && (item.name.match(reg))));
      if (selectorList.length === 0) {
        selectorList = standardSelectorList.filter(item => (!item.selector || !item.selector.match(filterReg)) && (!item.description || !item.description.match(filterReg)) && (!item.name || !item.name.match(filterReg)));
      }
      if (selectorList.length === 0) {
        //find model selector by signal name in pin section
        let modelSelector = getModelSelector(signal, packages);
        const RegObj = new RegExp(`(${modelSelector})`, 'ig');
        //find model list by modelSelector
        selectorList = modelList.filter(item => item.selector && item.selector.match(RegObj));
      }
      //find model list by model type is I/O
      let IOList = selectorList.filter(item => item.type === 'I/O');
      if (IOList && IOList.length > 0) {
        let model = getControllerModel(IOList, usage, 'I/O');
        if (model) {
          modelName = model.modelName;
          modelType = model.modelType;
          deviceVcc = model.deviceVcc;
          enable = model.enable;
        }
        if (modelName && modelType) {
          return { modelName, modelType, deviceVcc, enable }
        }
      }

      if (usage === 'Driver') {
        let outList = selectorList.filter(item => item.type === 'Output' || item.type === '3-state');
        let model = getControllerModel(outList, usage, 'Output');
        if (model) {
          modelName = model.modelName;
          modelType = model.modelType;
          deviceVcc = model.deviceVcc;
          enable = model.enable;
        }
        if (modelName && modelType) {
          return { modelName, modelType, deviceVcc, enable }
        } else {
          const model = getModel({ IOList, outList, inputList: [], usage, speed, projectType });
          if (model && model.modelName && model.modelType) {
            return { modelName: model.modelName, modelType: model.modelType, deviceVcc: model.deviceVcc, enable: model.enable }
          }
        }
      }
    }
  }

  return { modelName, modelType, deviceVcc, enable }
}

function findMemoryOutput(list) {
  let selectList = [], out40List = [], out40_50List = [];
  let minOutput = 10000;
  const RegPU = new RegExp(`(PU)|(pullup)|((pull_up))`, 'ig');
  const RegPD = new RegExp(`(PD)|(pulldown)|((pull_down))`, 'ig');
  for (let item of list) {
    let arr = item.name.split("_");
    //DQ_40_ODT40_1066
    //"DQ_PD40_ODT40_VOH50_4266"
    if (arr.length >= 1) {//DQ_40_ODT40_1066
      //find output
      let { firstNumStr } = getFirstNum(item.name);
      if (firstNumStr === "40") {
        out40List.push(item)
      } else if (firstNumStr >= 40 && firstNumStr < 50) {
        //ZOUT >40 <50
        //DQ_48_ODT40_1066
        out40_50List.push(item);
      } else if (Math.abs(firstNumStr - 40) < minOutput) {
        //Closest 40 ZOUT 
        minOutput = Math.abs(firstNumStr - 40);
      }
    }
  }

  if (!out40List || out40List.length === 0) {
    if (out40_50List && out40_50List.length > 0) {
      selectList = out40_50List;
    } else {
      for (let item of list) {
        let arr = item.name.split("_");
        //DQ_34_ODT40_1066
        //"DQ_PD60_ODT40_VOH50_4266"
        if (arr.length >= 1) {
          //find output  ZOUT closest 40
          let { firstNumStr } = getFirstNum(item.name);
          if (firstNumStr && Math.abs(firstNumStr - 40) === minOutput) {
            selectList.push(item);
          }
        }
      }
    }
  } else {
    selectList = out40List;
  }

  if (selectList.length > 0) {
    out40List = []; out40_50List = []; minOutput = 100000;
    for (let item of selectList) {
      let arr = item.name.split("_");
      //DQ_40_ODT40_1066
      //"DQ_PD40_ODT40_VOH50_4266"
      //DQ_PU_PD_40_48_ODT48_4266
      if (arr.length >= 1) {
        //find output
        //DQ_PU_PD_40_48_...
        if (item.name.match(RegPU) && item.name.match(RegPD)) {

          let { firstNumUnderlineIndex } = getFirstNum(item.name);
          //find second num
          let newName = item.name.slice(firstNumUnderlineIndex);
          let { firstNumStr } = getFirstNum(newName);

          if (firstNumStr === "40") {
            out40List.push(item)
          } else if (firstNumStr >= 40 && firstNumStr < 50) {
            //ZOUT >40 <50
            //DQ_48_ODT40_1066
            out40_50List.push(item);
          } else if (Math.abs(firstNumStr - 40) < minOutput) {
            //Closest 40 ZOUT 
            minOutput = Math.abs(firstNumStr - 40);
          }
        } else {

        }
      }
    }
    if (!out40List || out40List.length === 0) {
      if (out40_50List && out40_50List.length > 0) {
        selectList = out40_50List;
      } else {
        for (let item of list) {
          let arr = item.name.split("_");
          //DQ_PU40_PD48_ODT48_4266
          //DQ_PU_PD_40_48_ODT48_4266
          if (arr.length >= 1) {
            //find output  ZOUT closest 40
            let { firstNumUnderlineIndex } = getFirstNum(item.name);
            //find second num
            let newName = item.name.slice(firstNumUnderlineIndex);
            let { firstNumStr } = getFirstNum(newName);
            if (firstNumStr && Math.abs(firstNumStr - 40) === minOutput) {
              selectList.push(item);
            }
          }
        }
      }
    } else {
      selectList = out40List;
    }
  }
  return selectList;
}

/* find model name includes ODT */
function findMemoryODT(list) {
  let selectList = [];
  const RegODT = new RegExp(`(ODT)`, 'ig');
  let ODT40List = [], ODT40_50List = [], closestList = [], minODT = 10000;
  for (let item of list) {
    let arr = item.name.split("_");
    //DQ_40_ODT40_1066
    //"DQ_PD40_ODT40_VOH50_4266"
    //find ODT
    const ODTIndex = arr.findIndex(i => i.match(RegODT));
    //find ODT === 40
    if (ODTIndex > -1) {
      let odtValue = null;
      if (getNum(arr[ODTIndex])) {//DQ_40_ODT40
        odtValue = getNum(arr[ODTIndex])[0];
      } else {
        //DQ_40_ODT_40
        //DQ_40_ODT_40OHM
        //DQ_40_ODT_OFF
        let num = arr[ODTIndex + 1] && getNum(arr[ODTIndex + 1]) ? getNum(arr[ODTIndex + 1])[0] : null;
        odtValue = num && num < 500 ? num : null;
      }

      if (odtValue === "40") {
        ODT40List.push(item);
      } else if (odtValue >= 40 && odtValue < 50) {
        //ODT >40 <50
        //DQ_48_ODT48_1066
        ODT40_50List.push(item);
      } else if (odtValue && Math.abs(odtValue - 40) < minODT) {
        //Closest 40 ODT 
        minODT = Math.abs(odtValue - 40);
      }
    }
  }

  if (!ODT40List || ODT40List.length === 0) {
    if (ODT40_50List && ODT40_50List.length > 0) {
      selectList = ODT40_50List;
    } else {
      for (let item of list) {
        let arr = item.name.split("_");
        const ODTIndex = arr.findIndex(i => i.match(RegODT));
        //find ODT === 40
        if (ODTIndex > -1) {
          let odtValue = null;
          if (getNum(arr[ODTIndex])) {
            odtValue = getNum(arr[ODTIndex])[0];
          } else {
            let pattern = new RegExp("^[0-9]+$", "g");
            let num = arr[ODTIndex + 1] ? arr[ODTIndex + 1].match(pattern) : null;
            odtValue = num ? num : null;
          }
          if (odtValue && Math.abs(odtValue - 40) === minODT) {
            closestList.push(item);
          }
        }
      }

      if (closestList.length > 0) {
        selectList = closestList;
      } else {
        selectList = [];
      }
    }
  } else {
    selectList = ODT40List;
  }
  return selectList;
}

/* find model */
function getControllerModel(list, usage, type) {
  let modelName = null, modelType = null, deviceVcc = null, enable = "";

  if (list && list.length > 0) {
    if (type === 'I/O') {
      let outList = getControllerOUT(list);
      if (outList && outList.length > 0) {
        let odtList = getControllerODT(outList);
        if (odtList && odtList.length > 0) {
          modelName = odtList[0].name;
          modelType = odtList[0].type;
          deviceVcc = parseFloat(odtList[0].deviceVcc).toString();
          enable = odtList[0].enable;
        } else if (usage === 'Driver') {
          modelName = outList[0].name;
          modelType = outList[0].type;
          deviceVcc = parseFloat(outList[0].deviceVcc).toString();
          enable = outList[0].enable;
        }
      }
    } else if (type === 'Output') {
      let outList = getControllerOUT(list);
      if (outList && outList.length > 0) {
        modelName = outList[0].name;
        modelType = outList[0].type;
        deviceVcc = parseFloat(outList[0].deviceVcc).toString();
        enable = outList[0].enable;
      }
    } else if (type === 'Input') {
      let odtList = getControllerODT(list);
      if (odtList && odtList.length > 0) {
        modelName = odtList[0].name;
        modelType = odtList[0].type;
        deviceVcc = parseFloat(odtList[0].deviceVcc).toString();
        enable = odtList[0].enable;
      }
    }
  }

  return { modelName, modelType, deviceVcc, enable }
}

function getControllerOUT(list) {
  let selectList = [], out40List = [], out40_50List = [], minOutput = 10000;
  const RegZOUT = new RegExp(`(ZOUT)|(OUT)|(ZO)|(Z0)`, 'ig');
  const Z0Reg = new RegExp(`(Z0)`, 'ig');

  for (let item of list) {
    let nameList = item.name.split('_');
    if (nameList && nameList.length > 0) {
      for (let arrItem of nameList) {
        if (arrItem && arrItem.match(RegZOUT)) {
          let firstNumStr = getNum(arrItem) ? getNum(arrItem)[0] : null;
          if (firstNumStr) {
            if (firstNumStr === "40") {  //ZOUT ===40
              out40List.push(item)
            } else if (firstNumStr >= 40 && firstNumStr < 50) {
              //ZOUT >40 <50
              out40_50List.push(item);
            } else if (Math.abs(firstNumStr - 40) < minOutput) {
              //Closest 40 ZOUT 
              minOutput = Math.abs(firstNumStr - 40);
            }
          }
        }
      }
    }
    let words = item.description ? item.description.split(',') : [];
    if (!words || words.length === 0 || words.length === 1) {
      words = item.description ? item.description.match(/[^\s\t]+/g) : [];
    }
    if (words && words.length > 0) {
      for (let word of words) {
        if (word && word.match(RegZOUT)) {
          let firstNumStr = getNum(word) ? getNum(word)[0] : null;
          if (word.match(Z0Reg)) {
            let str = word.replace(/Z0/ig, '');
            firstNumStr = getNum(str) ? getNum(str)[0] : null;
          } else {
            firstNumStr = getNum(word) ? getNum(word)[0] : null;
          }

          if (firstNumStr) {
            if (firstNumStr === "40") {  //ZOUT ===40
              out40List.push(item)
            } else if (firstNumStr >= 40 && firstNumStr < 50) {
              //ZOUT >40 <50
              out40_50List.push(item);
            } else if (Math.abs(firstNumStr - 40) < minOutput) {
              //Closest 40 ZOUT 
              minOutput = Math.abs(firstNumStr - 40);
            }
          }
        }
      }
    }
  }

  if (!out40List || out40List.length === 0) {
    if (out40_50List && out40_50List.length > 0) {
      selectList = out40_50List;
    } else if (minOutput < 10000) {
      for (let item of list) {
        let nameArr = item.name.split("_");
        if (nameArr && nameArr.length > 0) {
          for (let arrItem of nameArr) {
            if (arrItem && arrItem.match(RegZOUT)) {
              //find output  ZOUT closest 40
              let firstNumStr = getNum(arrItem) ? getNum(arrItem)[0] : null;
              if (firstNumStr && Math.abs(firstNumStr - 40) === minOutput) {
                selectList.push(item);
              }
            }
          }
        }

        let arr = item.description ? item.description.split(",") : [];
        if (!arr || arr.length === 0 || arr.length === 1) {
          arr = item.description ? item.description.match(/[^\s\t]+/g) : [];
        }
        if (arr && arr.length > 0) {
          for (let word of arr) {
            if (word && word.match(RegZOUT)) {
              //find output  ZOUT closest 40
              let firstNumStr = getNum(word) ? getNum(word)[0] : null;
              if (firstNumStr && Math.abs(firstNumStr - 40) === minOutput) {
                selectList.push(item);
              }
            }
          }
        }
      }
    }
  } else {
    selectList = out40List;
  }

  return selectList;
}

function getControllerODT(list) {
  const RegODT = new RegExp(`(ODT)|(IN)`, 'ig');
  let selectList = [], odt40List = [], odt40_50List = [], minODT = 10000;

  for (let item of list) {
    //find odt list by model name
    let nameArr = item.name ? item.name.split('_') : [];
    if (nameArr && nameArr.length > 0) {
      for (let word of nameArr) {
        if (word && word.match(RegODT)) {
          let firstNumStr = getNum(word) ? getNum(word)[0] : null;
          if (firstNumStr) {
            if (firstNumStr === "40") {  //ODT ===40
              odt40List.push(item)
            } else if (firstNumStr >= 40 && firstNumStr < 50) {
              //ODT >40 <50
              odt40_50List.push(item);
            } else if (Math.abs(firstNumStr - 40) < minODT) {
              //Closest 40 ODT 
              minODT = Math.abs(firstNumStr - 40);
            }
          }
        }
      }
    }
    //find odt list by model description
    let words = item.description ? item.description.split(',') : [];
    if (!words || words.length === 0 || words.length === 1) {
      words = item.description ? item.description.match(/[^\s\t]+/g) : [];
    }
    if (words && words.length > 0) {
      for (let word of words) {
        if (word && word.match(RegODT)) {
          let firstNumStr = getNum(word) ? getNum(word)[0] : null;
          if (firstNumStr) {
            if (firstNumStr === "40") {  //ODT ===40
              odt40List.push(item)
            } else if (firstNumStr >= 40 && firstNumStr < 50) {
              //ODT >40 <50
              odt40_50List.push(item);
            } else if (Math.abs(firstNumStr - 40) < minODT) {
              //Closest 40 ODT 
              minODT = Math.abs(firstNumStr - 40);
            }
          }
        }
      }
    }
  }

  if (!odt40List || odt40List.length === 0) {
    if (odt40_50List && odt40_50List.length > 0) {
      selectList = odt40_50List;
    } else if (minODT < 10000) {
      for (let item of list) {
        //model name
        let nameArr = item.name ? item.name.split("_") : [];
        if (nameArr && nameArr.length > 0) {
          for (let word of nameArr) {
            if (word && word.match(RegODT)) {
              //find ODT  ODT closest 40
              let firstNumStr = getNum(word) ? getNum(word)[0] : null;
              if (firstNumStr && Math.abs(firstNumStr - 40) === minODT) {
                selectList.push(item);
              }
            }
          }
        }
        //description
        let arr = item.description ? item.description.split(",") : [];
        if (!arr || arr.length === 0 || arr.length === 1) {
          arr = item.description ? item.description.match(/[^\s\t]+/g) : [];
        }
        if (arr && arr.length > 0) {
          for (let word of arr) {
            if (word && word.match(RegODT)) {
              //find ODT  ODT closest 40
              let firstNumStr = getNum(word) ? getNum(word)[0] : null;
              if (firstNumStr && Math.abs(firstNumStr - 40) === minODT) {
                selectList.push(item);
              }
            }
          }
        }
      }
    }
  } else {
    selectList = odt40List;
  }

  return selectList;
}

function getModel({ IOList, outList, inputList, usage, speed, projectType }) {
  let selectList = [], maxSpeed = 100000;
  let modelName = '', modelType = '', deviceVcc = '', enable = '';
  //Driver and Receiver I/O
  selectList = findMemoryOutput(IOList);
  if (usage === "Driver") {
    if (selectList && selectList.length > 0) {
      const list = findMemoryODT(selectList);

      if (list.length > 0) {
        selectList = list;
      }
      //find proper speed 
      const info = getModelInfo({ selectList, speed, maxSpeed, length: 3 });
      modelName = info.modelName;
      modelType = info.modelType;
      deviceVcc = info.deviceVcc;
      enable = info.enable;
    } else {
      //Driver Output
      maxSpeed = 100000;
      selectList = findMemoryOutput(outList);
      //find proper speed 
      if (selectList) {
        const info = getModelInfo({ selectList, speed, maxSpeed });
        modelName = info.modelName;
        modelType = info.modelType;
        deviceVcc = info.deviceVcc;
        enable = info.enable;
      }
    }
  }

  if (usage === 'Receiver') {
    let _selectLists = [LPDDR4, LPDDR5].includes(projectType) ? [inputList, selectList] : [selectList, inputList];
    for (let list of _selectLists) {
      //Driver Input
      maxSpeed = 100000;
      /*  let inputList = DQList.filter(item => item.type === "Input"); */
      selectList = [];
      selectList = findMemoryODT(list);
      //find proper speed 
      if (selectList && selectList.length > 0) {
        const info = getModelInfo({ selectList, speed, maxSpeed });
        modelName = info.modelName;
        modelType = info.modelType;
        deviceVcc = info.deviceVcc;
        enable = info.enable;
        break;
      }
    }
  }
  if (modelName && modelType) {
    return { modelName, modelType, deviceVcc, enable };
  } else {
    return null;
  }
}

function getModelInfo({ selectList, speed, maxSpeed, length = 2 }) {
  let modelName = '', modelType = '', deviceVcc = '', enable = '';
  for (let item of selectList) {
    //DQ_40_1066
    let arr = item.name.split("_");
    if (arr.length > length) {
      let currentSpeed = arr[arr.length - 1];
      //find model by speed
      const model = getSpeedModel({ currentSpeed, item, speed, maxSpeed });
      if (model) {
        maxSpeed = model.maxSpeed;
        if (model.modelName && model.modelType) {
          modelName = model.modelName;
          modelType = model.modelType;
          deviceVcc = model.deviceVcc;
          enable = model.enable;
        }
      }
    } else if (!modelName) {
      modelName = item.name;
      modelType = item.type;
      deviceVcc = parseFloat(item.deviceVcc).toString();
      enable = item.enable;
    }
  }
  return { modelName, modelType, deviceVcc, enable }
}

function getSpeedModel({ currentSpeed, item, speed, maxSpeed }) {
  let modelName = '', modelType = '', deviceVcc = '', enable = '';
  const speedNum = getNum(currentSpeed) && getNum(currentSpeed)[0] && getNum(currentSpeed)[0] > 500 ? getNum(currentSpeed)[0] : null;
  if (speedNum && Math.abs(speedNum - (speed * 2)) < maxSpeed) {
    maxSpeed = Math.abs(speedNum - (speed * 2));
    modelName = item.name;
    modelType = item.type;
    deviceVcc = parseFloat(item.deviceVcc).toString();
    enable = item.enable;
  } else if (!modelName) {
    modelName = item.name;
    modelType = item.type;
    deviceVcc = parseFloat(item.deviceVcc).toString();
    enable = item.enable;
  }

  if (modelName && modelType) {
    return { modelName, modelType, deviceVcc, enable, maxSpeed }
  } else {
    return { maxSpeed };
  }

}

function getCLKModel({ CLKList, speed }) {
  let maxSpeed = 100000;
  let modelName = '', modelType = '', deviceVcc = '', enable = '';

  const list = findMemoryODT(CLKList);
  let selectList = CLKList;

  if (list.length > 0) {
    selectList = list;
  }

  for (let item of selectList) {
    let arr = item.name.split("_");
    //CLK_IN/CLKIN/INPUT
    if (arr.length > 0) {
      //find proper speed 
      let currentSpeed = arr[arr.length - 1];
      //find model by speed
      const model = getSpeedModel({ currentSpeed, item, speed, maxSpeed });
      if (model) {
        maxSpeed = model.maxSpeed;
        if (model.modelName && model.modelType) {
          modelName = model.modelName;
          modelType = model.modelType;
          deviceVcc = model.deviceVcc;
          enable = model.enable;
        }
      }
    }
  }
  if (modelName && modelType) {
    return { modelName, modelType, deviceVcc, enable };
  } else {
    return null;
  }
}

function getModelStandard(projectType) {
  let type = null;
  switch (projectType) {
    case DDR3:
      type = '(SSTL15)|(SSTL_15)|(DDR3(?!L))';
      break;
    case DDR3L:
      type = '(DDR3L)|(SSTL135)|(SSTL_135)';
      break;
    case DDR4:
      type = '(POD12)|(POD_12)|((?<!LP)DDR4(?!L))';
      break;
    case DDR4L:
      type = '(DDR4L)|(POD105)|(POD_105)';
      break;
    case LPDDR4:
      type = '(LVSTL)|(LPDDR4)|(DL4)';
      break;
    case DDR5:
    case DDR5_RDIMM:
    case DDR5_SODIMM:
    case DDR5_UDIMM:
    case DDR5_RDIMM_CARD:
    case DDR5_SODIMM_CARD:
    case DDR5_UDIMM_CARD:
    case DDR5_RDIMM_X4:
      type = '(DDR5(?!L))';
      break;
    case LPDDR5:
      type = '(LPDDR5)|(DL5)';
      break;
    default:
      break;
  }

  return type;
}

function getModelSelector(signal, packages) {
  let modelSelector = null;
  if (!packages || packages.length === 0 || !signal) {
    return modelSelector;
  }
  const RegObj = new RegExp(`(${signal})`, 'ig');

  let pinSections = [];
  packages.forEach(item => {
    if (item.pinSection) {
      const pins = JSON.parse(JSON.stringify(item.pinSection));
      pinSections = [...pinSections, ...pins];
    }
  })

  for (let item of pinSections) {
    if (item && item.signal_name.match(RegObj)) {
      modelSelector = item.model_name;
      break;
    }
  }

  return modelSelector;
}

function getFirstNum(value) {
  let firstNum = getNum(value);
  //Find the index position of the first number
  const firstNumIndex = value.indexOf(firstNum);
  const firstNumUnderlineIndex = value.indexOf("_", firstNumIndex);
  let firstNumStr = value.slice(firstNumIndex, firstNumUnderlineIndex);
  if (getNum(firstNumStr) && getNum(firstNumStr)[0]) {
    firstNumStr = getNum(firstNumStr)[0];
  } else {
    firstNumStr = "";
  }
  return { firstNumStr, firstNumUnderlineIndex };
}

function getNum(str) {
  //Matches the first digit in the string
  var pattern = new RegExp("[0-9]+");
  var num = str.match(pattern);
  return num;
}

export default IbisModelHelper;
export { getPins, getIBISModelName, getIBISModelList, exsitEnableVoltage };