import { takeEvery, call, select, put } from 'redux-saga/effects';
import {
  UPDATE_LIBRARY_MENU,
  UPDATE_LIBRARY_DATA,
  DELETE_LIBRARY,
  SAVE_LIBRARY_CONFIG_SERVER,
  ADD_LIBRARY_TO_CACHE,
  AFTER_IMPORT_LIBRARY,
  OPEN_LIBRARY_FOLDER
} from './actionTypes';
import {
  getLibraryList,
  saveLibraryData,
  deleteLibraryById,
  PKG_TOUCHSTONE_INDEX,
  PKG_INDEX,
  PASSIVE_TOUCHSTONE_INDEX,
  PASSIVE_SPICE_INDEX,
  PASSIVE_INDEX,
  CABLE_INDEX,
  CONNECTOR_INDEX,
  getLibraryArray,
  libraryTypeList,
  IBIS_AMI_INDEX,
  BUFFER_SPICE_INDEX,
  getFolderDetail,
  libraryItem,
  IBIS_INDEX,
  PCB_INDEX,
  BUFFER_INDEX,
  EXTRACTION_OPTIONS_INDEX,
  HFSS_OPTIONS_INDEX,
  SIWAVE_OPTIONS_INDEX,
  ADS_OPTIONS_INDEX,
  EYE_MASK_INDEX,
  getIbisAmiFileList,
  GENERIC_INDEX,
  GENERIC_TOUCHSTONE_INDEX,
  GENERIC_SPICE_INDEX,
  LEQ_RESPONSE_INDEX,
  getLibraryFolderChildren,
  deleteFolderChild,
  USER_DEFINED_NETLIST_INDEX
} from '../../../../services/Andes_v2/library';
import {
  LIBRARY_INDEX,
  TRACE_INDEX,
  VIA_INDEX,
  getLibraryArray as getPreLayoutLibraryArray
} from '@/services/PreLayout/PreLayoutLibrary';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import {
  TRACE,
  VIA,
  PKG_TOUCHSTONE,
  PASSIVE_SPICE,
  PASSIVE_TOUCHSTONE,
  CONNECTOR_TOUCHSTONE,
  CABLE_TOUCHSTONE,
  IBIS_AMI,
  IBIS,
  PCB_TOUCHSTONE,
  SPICE,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  EYE_MASK,
  GENERIC_TOUCHSTONE,
  LEQ_RESPONSE,
  ADS_OPTIONS,
  BUFFER_SPICE,
  CPHY_EYE_MASK,
  USER_DEFINED_NETLIST
} from '../../../../constants/libraryConstants';
import {
  updateLibraryMenu,
  updateLibraryStatus
} from './action';
import {
  updateTreeList
} from '../project/action';
import { LIBRARY_FOLDER_FILE } from '../../../../constants/treeConstants';
import { defaultTreeItems } from '../../../../services/Andes_v2';

function* _updateLibraryList(action) {
  const { firstLoad } = action;
  let res = null;
  try {
    res = yield call(getLibraryList);
    if (!res) {
      return;
    }
  } catch (error) {
    console.error(error)
    return;
  }

  const { AndesV2Reducer: { project } } = yield select();
  const { treeItems } = project;
  let _treeItems = [...treeItems];
  let traceLibraryList = [], viaLibraryList = [], pkgTouchstoneList = [],
    passiveTouchstoneList = [], passiveSpiceList = [], connectorTouchstoneList = [],
    cableTouchstoneList = [], amiModelList = [], ibisModelList = [], pcbTouchstoneList = [],
    genericSpiceList = [], extractionOptionsList = [], extractionSiwaveOptionsList = [], eyeMaskList = [],
    genericTouchstoneList = [], leqResponseList = [], adsOptionsList = [], bufferSpiceList = [], cphyEyeMaskList = [],
    userDefinedNetList = [];

  if (_treeItems[LIBRARY_INDEX] && _treeItems[LIBRARY_INDEX].children) {
    //update trace library list
    traceLibraryList = getPreLayoutLibraryArray(TRACE, res[TRACE]);
    _treeItems[LIBRARY_INDEX].children[TRACE_INDEX].children = traceLibraryList;

    //update via library list
    viaLibraryList = getPreLayoutLibraryArray(VIA, res[VIA]);
    _treeItems[LIBRARY_INDEX].children[VIA_INDEX].children = viaLibraryList;

    //update package touchstone library list
    pkgTouchstoneList = getLibraryArray(PKG_TOUCHSTONE, res[PKG_TOUCHSTONE]);
    _treeItems[LIBRARY_INDEX].children[PKG_INDEX].children[PKG_TOUCHSTONE_INDEX].children = pkgTouchstoneList;

    //update passive touchstone library list
    passiveTouchstoneList = getLibraryArray(PASSIVE_TOUCHSTONE, res[PASSIVE_TOUCHSTONE]);
    _treeItems[LIBRARY_INDEX].children[PASSIVE_INDEX].children[PASSIVE_TOUCHSTONE_INDEX].children = passiveTouchstoneList;

    //update passive SPICE library list
    passiveSpiceList = getLibraryArray(PASSIVE_SPICE, res[PASSIVE_SPICE]);
    _treeItems[LIBRARY_INDEX].children[PASSIVE_INDEX].children[PASSIVE_SPICE_INDEX].children = passiveSpiceList;

    //update connector touchstone library list
    connectorTouchstoneList = getLibraryArray(CONNECTOR_TOUCHSTONE, res[CONNECTOR_TOUCHSTONE]);
    _treeItems[LIBRARY_INDEX].children[CONNECTOR_INDEX].children = connectorTouchstoneList;

    //update cable touchstone library list
    cableTouchstoneList = getLibraryArray(CABLE_TOUCHSTONE, res[CABLE_TOUCHSTONE]);
    _treeItems[LIBRARY_INDEX].children[CABLE_INDEX].children = cableTouchstoneList;

    //update ami library list
    amiModelList = getLibraryArray(IBIS_AMI, res[IBIS_AMI]);
    _treeItems[LIBRARY_INDEX].children[BUFFER_INDEX].children[IBIS_AMI_INDEX].children = amiModelList;

    //update IBIS library list
    ibisModelList = getLibraryArray(IBIS, res[IBIS]);
    _treeItems[LIBRARY_INDEX].children[BUFFER_INDEX].children[IBIS_INDEX].children = ibisModelList;

    //update buffer spice library list
    bufferSpiceList = getLibraryArray(BUFFER_SPICE, res[BUFFER_SPICE]);
    _treeItems[LIBRARY_INDEX].children[BUFFER_INDEX].children[BUFFER_SPICE_INDEX].children = bufferSpiceList;

    //update package touchstone library list
    pcbTouchstoneList = getLibraryArray(PCB_TOUCHSTONE, res[PCB_TOUCHSTONE]);
    _treeItems[LIBRARY_INDEX].children[PCB_INDEX].children = pcbTouchstoneList;

    // update hfss extraction options library list
    extractionOptionsList = getLibraryArray(HFSS_OPTIONS, res[HFSS_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[HFSS_OPTIONS_INDEX].children = extractionOptionsList;

    // update siwave extraction options library list
    extractionSiwaveOptionsList = getLibraryArray(SIWAVE_OPTIONS, res[SIWAVE_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[SIWAVE_OPTIONS_INDEX].children = extractionSiwaveOptionsList;

    // update ads options library list
    adsOptionsList = getLibraryArray(ADS_OPTIONS, res[ADS_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[ADS_OPTIONS_INDEX].children = adsOptionsList;

    // update eye mask library list
    eyeMaskList = getLibraryArray(EYE_MASK, res[EYE_MASK]);
    cphyEyeMaskList = getLibraryArray(CPHY_EYE_MASK, res[CPHY_EYE_MASK]);
    _treeItems[LIBRARY_INDEX].children[EYE_MASK_INDEX].children = [...eyeMaskList, ...cphyEyeMaskList];

    //update generic Touchstone library list
    genericTouchstoneList = getLibraryArray(GENERIC_TOUCHSTONE, res[GENERIC_TOUCHSTONE]);
    _treeItems[LIBRARY_INDEX].children[GENERIC_INDEX].children[GENERIC_TOUCHSTONE_INDEX].children = genericTouchstoneList;
    //update generic SPICE library list
    genericSpiceList = getLibraryArray(SPICE, res[SPICE]);
    _treeItems[LIBRARY_INDEX].children[GENERIC_INDEX].children[GENERIC_SPICE_INDEX].children = genericSpiceList;
    // update leq response library list
    leqResponseList = getLibraryArray(LEQ_RESPONSE, res[LEQ_RESPONSE]);
    _treeItems[LIBRARY_INDEX].children[LEQ_RESPONSE_INDEX].children = leqResponseList;
    // update User Defined NetList library list
    userDefinedNetList = getLibraryArray(USER_DEFINED_NETLIST, res[USER_DEFINED_NETLIST]);
    _treeItems[LIBRARY_INDEX].children[USER_DEFINED_NETLIST_INDEX].children = userDefinedNetList;

    firstLoad && libraryConstructor.clearAllCache();

    for (let item of amiModelList) {
      try {
        let list = yield call(getFolderDetail, item.id);
        list = Array.isArray(list) ? list.filter(it => !!it.fileName.match(".ibs")) : [];
        firstLoad && libraryConstructor.addLibrary(IBIS_AMI, item.id, { ...item, children: list });
        item.children = [];
        let fileNames = [];
        for (let it of list) {
          let ibisAmiList = getIbisAmiFileList(it, fileNames);
          item.children.push(
            new libraryItem(IBIS_AMI, {
              name: it.fileName,
              type: IBIS_AMI,
              id: item.id,
              dataType: LIBRARY_FOLDER_FILE,
              nodeClass: 'tree-library-folder-file'
            }));
          item.children.push(...(ibisAmiList || []).map(it =>
            new libraryItem(IBIS_AMI, {
              name: it.fileName,
              type: IBIS_AMI,
              id: item.id,
              dataType: LIBRARY_FOLDER_FILE,
              nodeClass: 'tree-library-folder-file'
            })))
          fileNames.push(...ibisAmiList.map(_it => _it.fileName));
        }
      } catch (error) {
        console.error(error)
      }
    }

    yield put(updateTreeList({ treeItems: [..._treeItems] }));

    if (firstLoad) {
      //save library to cache
      for (const type of libraryTypeList) {
        if (type === IBIS_AMI) {
          continue;
        }
        res[type] && res[type].forEach(item => {
          libraryConstructor.addLibrary(type, item.id, item);
        });
      }
      yield put(updateLibraryStatus());
    }
  }
}

function* _saveLibrary(action) {
  const { data, libraryType } = action;
  // libraryType - trace / via
  if (data) {
    const library = yield call(saveLibraryData, data);
    // add / update library
    libraryConstructor.addLibrary(libraryType, library.id, library);
    yield put(updateLibraryMenu());
  }
}

function* _delLibrary(action) {
  const { data } = action;
  const { id, type } = data;
  yield call(deleteLibraryById, id, type);
  //delete library cache by id
  libraryConstructor.delLibrary(type, id);

  if ([BUFFER_SPICE, SPICE].includes(type)) {
    const { AndesV2Reducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];
    let list = [];
    if (type === BUFFER_SPICE) {
      list = _treeItems[LIBRARY_INDEX].children[BUFFER_INDEX].children[BUFFER_SPICE_INDEX].children;
    } else if (type === SPICE) {
      list = _treeItems[LIBRARY_INDEX].children[GENERIC_INDEX].children[GENERIC_SPICE_INDEX].children;
    }

    let pathIds = data.path ? data.path.split('/') : [];
    pathIds = pathIds.filter(item => !!item)
    if (pathIds.length) {
      list = deleteFolderChild(list, id, pathIds);
    } else {
      list = list.filter(item => item.id !== id)
    }
    if (type === BUFFER_SPICE) {
      _treeItems[LIBRARY_INDEX].children[BUFFER_INDEX].children[BUFFER_SPICE_INDEX].children = list;
    } else if (type === SPICE) {
      _treeItems[LIBRARY_INDEX].children[GENERIC_INDEX].children[GENERIC_SPICE_INDEX].children = list;
    }
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
  } else {
    //update menu
    yield put(updateLibraryMenu());
  }
}

function* updatePreLayoutLibrary(action) {
  const { model, config, templateVersion } = action;
  const { libId, libType, type, template } = model;
  const dataType = type;
  const data = {
    id: libId || "",
    name: template,
    type: libType,
    config,
    version: templateVersion
  }
  const res = yield call(saveLibraryData, data);
  libraryConstructor.addLibrary(dataType, res.id, res);
}

function* _addLibraryToCache(action) {
  const { fileList, modelType } = action;
  for (let file of fileList) {
    if (modelType === IBIS_AMI) {
      try {
        let list = yield call(getFolderDetail, file.id);
        list = Array.isArray(list) ? list.filter(it => !!it.fileName.match(".ibs")) : [];
        libraryConstructor.addLibrary(IBIS_AMI, file.id, { ...file, children: list });
      } catch (error) {
        console.error(error);
      }
    } else {
      libraryConstructor.addLibrary(modelType, file.id, file);
    }
  }
}

function* afterImportLibrary() {
  // clear the treeItem of the library
  const { AndesV2Reducer: { project: { treeItems } } } = yield select();
  const defaultLibraryMenu = defaultTreeItems.find(item => item.key === 'library')
  let _treeItems = treeItems.map(item => {
    if (item.key === 'library') {
      return JSON.parse(JSON.stringify(defaultLibraryMenu))
    }
    return item
  })

  yield put(updateTreeList({ treeItems: [..._treeItems] }));
  // update new library Menu，update libraryConstructor
  yield call(_updateLibraryList, { firstLoad: true })
}

function* _openFolder(action) {
  const { itemData, libraryType, filesInfo } = action;
  if (![BUFFER_SPICE, SPICE].includes(libraryType)) {
    return;
  }
  let uploadFiles = filesInfo && filesInfo.length ? getLibraryArray(libraryType, filesInfo) : [];
  let files = uploadFiles && uploadFiles.length ? uploadFiles : itemData ? [itemData] : [];

  const { AndesV2Reducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems];
  try {
    let list = [];
    if (libraryType === BUFFER_SPICE) {
      list = _treeItems[LIBRARY_INDEX].children[BUFFER_INDEX].children[BUFFER_SPICE_INDEX].children;
    } else if (libraryType === SPICE) {
      list = _treeItems[LIBRARY_INDEX].children[GENERIC_INDEX].children[GENERIC_SPICE_INDEX].children;
    }

    if (files.length) {
      uploadFiles.length && list.push(...uploadFiles)
      for (let itemData of files) {
        if (!itemData.children && (itemData.fileType || "").toLowerCase() !== "Folder") {
          continue;
        }
        let library = itemData.id ? libraryConstructor.getLibrary(libraryType, itemData.id) : itemData;
        let res = [];
        if (!library || !library.children) {
          res = yield call(getLibraryFolderChildren, itemData.id);
          if (!res || !res[libraryType] || !res[libraryType].length) {
            return;
          }
          if (!library) {
            library = itemData;
          }
          library.children = getLibraryArray(libraryType, res[libraryType]);
          libraryConstructor.addLibrary(libraryType, itemData.id, library);
        }
        for (let data of library.children) {
          if (data.fileType !== "folder") {
            continue;
          }
          try {
            let childLibrary = libraryConstructor.getLibrary(libraryType, data.id);
            let childrenRes = {};
            if (!childLibrary || !childLibrary.children) {
              childrenRes = yield call(getLibraryFolderChildren, data.id);
              if (!childrenRes || !childrenRes[libraryType]) {
                continue;
              }
              data.children = getLibraryArray(libraryType, childrenRes[libraryType]);
              libraryConstructor.addLibrary(libraryType, data.id, data);
            }
          } catch (error) {
            console.error(error)
          }
        }

        let pathIds = itemData.path ? itemData.path.split('/') : [];
        pathIds = pathIds.filter(item => !!item)
        let newList = list;
        for (let i = 0; i < pathIds.length; i++) {
          const folderIndex = newList.findIndex(it => it.id === pathIds[i]);
          newList = newList[folderIndex] ? newList[folderIndex].children : [];
        }
        newList = library.children;
      }
    } else {
      for (let libItem of list) {
        if (libItem.fileType !== "folder") {
          continue;
        }
        try {
          let childLibrary = libraryConstructor.getLibrary(libraryType, libItem.id);
          let childrenRes = {};
          if (!childLibrary || !childLibrary.children) {
            childrenRes = yield call(getLibraryFolderChildren, libItem.id);
            if (!childrenRes || !childrenRes[libraryType] || !childrenRes[libraryType].length) {
              continue;
            }
            libItem.children = getLibraryArray(libraryType, childrenRes[libraryType] || []);
            libraryConstructor.addLibrary(libraryType, libItem.id, libItem);
          } else {
            libItem.children = childLibrary.children;
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    if (libraryType === BUFFER_SPICE) {
      _treeItems[LIBRARY_INDEX].children[BUFFER_INDEX].children[BUFFER_SPICE_INDEX].children = list;
    } else if (libraryType === SPICE) {
      _treeItems[LIBRARY_INDEX].children[GENERIC_INDEX].children[GENERIC_SPICE_INDEX].children = list;
    }
  } catch (error) {
    console.error(error)
  }
  yield put(updateTreeList({ treeItems: _treeItems }));
}

function* librarySaga() {
  yield takeEvery(UPDATE_LIBRARY_MENU, _updateLibraryList);
  yield takeEvery(UPDATE_LIBRARY_DATA, _saveLibrary);
  yield takeEvery(DELETE_LIBRARY, _delLibrary);
  yield takeEvery(SAVE_LIBRARY_CONFIG_SERVER, updatePreLayoutLibrary);
  yield takeEvery(ADD_LIBRARY_TO_CACHE, _addLibraryToCache);
  yield takeEvery(AFTER_IMPORT_LIBRARY, afterImportLibrary);
  yield takeEvery(OPEN_LIBRARY_FOLDER, _openFolder);
}

export default librarySaga;