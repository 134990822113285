export const CREATE_USER = 'create_user';
export const CREATE_USER_FAILED = 'create_user_failed';

export const RELOAD_USER_TABLE = 'reload_user_table';

export const OPEN_CREATE_USER_MODAL = 'open_create_user_modal';
export const CLOSE_CREATE_USER_MODAL = 'close_create_user_modal';

export const INIT_USER_TABLE = 'init_user_table';
export const INIT_USER_TABLE_SUCCESS = 'init_user_table_success';

export const CHANGE_MENU = 'menu_change';
export const CHANGE_TABLE_SUCCESS = 'change_table_success';

export const JUMP_TO_ADMIN_PAGE = 'jump_to_admin_page';
export const JUMP_TO_PAGE_SUCCESS = 'jump_to_page_success';

export const OPEN_CREATE_ROLE_MODAL = 'open_create_role_modal';
export const CLOSE_CREATE_ROLE_MODAL = 'close_create_role_modal';

export const CREATE_ROLE = 'create_role';
export const CREATE_ROLE_FAILED = 'craete_role_failed';

export const CHANGE_EMAIL_MODAL_VISIBLE = 'change_email_modal_visible';

export const GET_ROLE_PERM_LIST = 'get_role_permission_list';
export const SAVE_ROLE_LIST = 'save_role_list';
export const SAVE_PERM_LIST = 'save_permission_list';

export const CLEAR_DATA = 'clear_data';

export const UPDATE_ROLE_MANAGEMENT = "update_role_management";