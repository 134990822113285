import React from 'react';
import { CloseCircleFilled, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Popover, Input } from 'antd';

const { Option } = Select;

/**
 * @param {[{name,disabled}]} nodeList node select option
 * @param {string} nodeValue
 * @param {Function} clickNode
 * @param {string} id
 * @param {string} nodeClassName node box className
 * @param {Function} selectNode
 * @param {boolean} visible Whether to display input
 * @param {Function} closeNodelSelect
 * @param {string} nodeWidth
 */
class SubcktNodeSelect extends React.Component {
  constructor() {
    super();
    this.state = {
      searchValue: ""
    };
  }

  handleVisibleChange = (visible) => {
    if (visible) {
      this.props.clickNode()
    } else {
      this.props.closeNodelSelect()
    }
  };

  selectRender = () => {
    const { nodeList = [], id } = this.props;
    return (
      (<div className='andes-v2-hspice-simulation-node-popover-content'>
        <Select
          allowClear
          showSearch
          placeholder="Select Node"
          className='nodes-list-file-select'
          popupClassName='nodes-list-file-dropdown'
          onChange={(key) => this.props.selectNode(key)}
          suffixIcon={<SearchOutlined />}
          getPopupContainer={() => document.getElementById(id)}
        >
          {nodeList.map(item => <Option
            key={item.name}
            value={item.name}
            disabled={item.disabled}
            className='spice-nodes-content-file'
          >
            {item.name}
          </Option>)}
        </Select>
      </div>)
    );
  }

  clearNode = (e) => {
    e.stopPropagation();
    this.props.selectNode('');
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  nodeSelectRender = () => {
    const { searchValue } = this.state;
    const { nodeList = [], nodeValue, displayPin } = this.props;

    const nodeListFilter = nodeList.filter(item => !searchValue || item.name.includes(searchValue));
    return (
      <div className='nodes-content'>
        <div className='nodes-content-header'>
          <span className='nodes-content-header-span'>Component Pin {displayPin}</span>
        </div>
        <div className='node-content-close' onClick={() => this.handleVisibleChange(false)}>
          <CloseOutlined className='node-content-close-icon' />
        </div>
        <div className="node-list-body-with-search">
          <div className="node-list-body-search-wrapper">
            <Input
              placeholder="Search Node"
              allowClear
              suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} />}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='node-list-ul'>
              {nodeListFilter.map(item =>
                <li
                  key={item.name}
                  title={item.name}
                  className={nodeValue === item.name ? 'current-pin-selected-node-li' : (item.disabled ? 'node-li-selected' : '')}
                  onClick={!item.disabled ? () => this.props.selectNode(item.name) : null}
                >
                  {item.name}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { nodeValue, visible, id, nodeClassName, nodeWidth = 80, displayPin } = this.props;
    const isDisabled = !displayPin;
    return (
      <Popover
        placement="bottom"
        content={this.nodeSelectRender()}
        trigger="click"
        open={visible}
        overlayClassName='spice-node-select-Popover'
        id={id}
      >
        <div
          className={`andes-v2-hspice-simulation-node-box ${nodeClassName}`}
          onClick={() => isDisabled ? null : this.handleVisibleChange(true)}
          style={{ width: nodeWidth + 'px' }}
        >
          {visible || !nodeValue || isDisabled ? <Input
            className='andes-v2-hspice-simulation-node-input'
            placeholder='Node'
            value={nodeValue}
            disabled={isDisabled ? true : false}
          /> : <div title={nodeValue}>
            <span className='andes-v2-hspice-simulation-node-span andes-v2-hspice-no-wrap'>{nodeValue || "Node"}</span>
            <CloseCircleFilled
              onClick={(e) => this.clearNode(e)}
              className='andes-v2-hspice-simulation-node-icon' />
          </div>}
        </div>
      </Popover>
    );
  }
}

export default SubcktNodeSelect;