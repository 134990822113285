import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PackageModel from '@/components/PackageModel_v1';
import { BGA, CONNECTOR } from '@/constants/componentType';
import { ANDES_V2 } from "@/constants/pageType";
import { Divider } from 'antd';
import { getPkgCompPinList, PackageConnModelInfo } from '@/services/Andes_v2/channel';
import libraryConstructor from "@/services/Andes_v2/library/libraryConstructor";
import {
  CONNECTOR_TOUCHSTONE,
  PKG_TOUCHSTONE,
  PCB_TOUCHSTONE,
  CABLE_TOUCHSTONE
} from "@/constants/libraryConstants";
import { saveCompPCBModel, saveCompPackageModel, saveCompConnectorModel } from '../store/channel/action';
import { getTouchstoneParse, getPkgSpiceModelList } from "@/services/Andes_v2/library";
import { PACKAGE } from '@/constants/designType';
import "./index.css";
import { SPICE } from '../../../constants/libraryConstants';

const PCB = 'PCB';
class MultiModelContent extends Component {
  constructor(props) {
    super(props);
    const { touchKey } = this.getKeys(PCB);
    this.state = {
      newComponents: [],
      modelType: PCB,
      spiceList: libraryConstructor.getLibraryValues(SPICE),
      touchstoneList: libraryConstructor.getLibraryValues(touchKey),
      connectorList: libraryConstructor.getLibraryValueList([CONNECTOR_TOUCHSTONE, SPICE]),
      cableList: libraryConstructor.getLibraryValueList([CABLE_TOUCHSTONE, SPICE]),
      packageList: libraryConstructor.getLibraryValueList([PKG_TOUCHSTONE, SPICE]),
      pcbList: libraryConstructor.getLibraryValueList([PCB_TOUCHSTONE, SPICE]),
    }
  }

  componentDidMount = () => {
    this.updateComponents();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { multiModelUpdateStatus, verificationId, updateComponentsStatus, libraryStatus } = this.props;
    const { modelType } = this.state;

    if ((verificationId !== prevProps.verificationId) || multiModelUpdateStatus || updateComponentsStatus !== prevProps.updateComponentsStatus) {
      this.updateComponents();
      multiModelUpdateStatus && this.props.updateMultiModelStatus(false)
    }

    if (prevProps.libraryStatus !== libraryStatus) {
      const { touchKey } = this.getKeys(modelType);
      this.setState({
        spiceList: libraryConstructor.getLibraryValues(SPICE),
        touchstoneList: libraryConstructor.getLibraryValues(touchKey),
        connectorList: libraryConstructor.getLibraryValueList([CONNECTOR_TOUCHSTONE, SPICE]),
        cableList: libraryConstructor.getLibraryValueList([CABLE_TOUCHSTONE, SPICE]),
        packageList: libraryConstructor.getLibraryValueList([PKG_TOUCHSTONE, SPICE]),
        pcbList: libraryConstructor.getLibraryValueList([PCB_TOUCHSTONE, SPICE]),
      })
    }

    if (prevState.modelType !== this.state.modelType) {
      const { touchKey } = this.getKeys(modelType);
      this.setState({
        touchstoneList: libraryConstructor.getLibraryValues(touchKey),
      })
    }
  }

  updateComponents = () => {
    const { components, design, verificationId } = this.props;
    let _newComponents = [], modelType = PCB, modelKey = "pcbModel";
    if (design && design.type === PACKAGE && components && components.length) {
      //When the opened page is a package channel, item.type===BGA
      _newComponents = components.filter(item => item.type === BGA /* && item.pcbModel && item.pcbModel.type && item.pcbModel.type !== "None" */);
    } else if (design && components && components.length) {
      // When the open page is channel, the package is connected in the IC
      //The Component of the Connector is connected to subsequent models
      modelKey = "connectorModel";
      _newComponents = components.filter(item => item.type === CONNECTOR /* && item.connectorModel && item.connectorModel.type && item.connectorModel.type !== "None" */)
      modelType = CONNECTOR
    }

    if (_newComponents.find(item => !item[modelKey] || (item[modelKey].type && item[modelKey].type === "None"))) {
      for (let item of _newComponents) {
        if (!item[modelKey] || (item[modelKey].type && item[modelKey].type === "None")) {
          item[modelKey] = {
            type: "Touchstone / SPICE",
            files: [],
            pairs: []
          }
        }
      }
    }

    _newComponents.forEach(item => item.verificationId = verificationId);


    this.setState({
      newComponents: _newComponents,
      modelType
    }, () => {
      this.updateContent()
    })
  }

  updateContent = () => {
    const { newComponents } = this.state;
    newComponents.forEach((item, index) => {
      const key = `multiModel${index + 1}Ref`;
      this[key] && this[key].updateContent();
    });
  }

  onRef = (ref, index) => {
    if (index) {
      const key = `multiModel${index}Ref`
      this[key] = ref;
    }
  }

  _savePackageModel = (model, record, modelsObj) => {
    const { modelType } = this.state;
    const { verificationId } = this.props;
    const { name } = record;
    // Do not update when the saved verifications are different
    if (!name || (verificationId && verificationId !== record.verificationId)) { return }
    switch (modelType) {
      case PCB:
        this.props._saveCompPCBModel(model, modelsObj, name);
        return;
      case CONNECTOR:
        this.props._saveCompConnectorModel(model, name, modelsObj);
        return;
      default: return;
    }
  }


  nameTitleContent = (componentName) => {
    const { newComponents } = this.state;
    if (newComponents.length < 2) { return null }
    return <Fragment>
      <div className='package-setup-name-title'>{componentName} Channel Builder</div>
      <Divider className='package-setup-name-divider' />
    </Fragment>
  }

  getKeys = (modelType) => {
    let pkgKey = '', touchKey = '';
    switch (modelType) {
      case PCB:
        pkgKey = 'pcbModel';
        touchKey = PCB_TOUCHSTONE;
        break;
      case CONNECTOR:
        pkgKey = 'connectorModel';
        touchKey = CONNECTOR_TOUCHSTONE;
        break
      default: break
    }
    return { pkgKey, touchKey }
  }

  render() {
    const { components, signals, changeUpdateMultiStatus, serdesType } = this.props;
    const { newComponents, modelType, spiceList, touchstoneList, connectorList, cableList, packageList, pcbList } = this.state;
    if (!newComponents || !newComponents.length) { return null }
    const { pkgKey } = this.getKeys(modelType)
    return <div className='space-10'>
      <div className="aurora-setup-border" >
        <span className='out-title-color font-bold'>Channel Builder</span>
        {newComponents.map((comp, index) => {
          const { name, modelList } = comp;
          const _pinList = getPkgCompPinList(name, components, signals);
          return <div key={index} className="setup-package-model-content" id={`${name}-multi-content-setup-dialog`}>
            <PackageModel
              onRef={this.onRef}
              record={{ ...comp, pkg: comp[pkgKey] }}
              product={ANDES_V2}
              componentName={name}
              pinList={_pinList}
              modelType={modelType}
              displayType={"model"}
              signals={signals}
              savePackageModel={this._savePackageModel}
              spiceList={spiceList}
              touchstoneList={touchstoneList}
              // connectorList={libraryConstructor.getLibraryValues(CONNECTOR_TOUCHSTONE)}
              // cableList={libraryConstructor.getLibraryValues(CABLE_TOUCHSTONE)}
              // packageList={libraryConstructor.getLibraryValues(PKG_TOUCHSTONE)}
              // pcbList={libraryConstructor.getLibraryValues(PCB_TOUCHSTONE)}
              connectorList={connectorList}
              cableList={cableList}
              packageList={packageList}
              pcbList={pcbList}
              // spiceList={}
              PackageModelInfo={PackageConnModelInfo}
              getTouchstoneParse={getTouchstoneParse}
              getSPiceParse={getPkgSpiceModelList}
              newModelsObj={modelList || []}
              isPageDisplay={true}
              compIndex={index + 1}
              nameTitleContent={this.nameTitleContent}
              changeUpdateMultiStatus={changeUpdateMultiStatus}
              isDisplayModelTitle={true}
              hideModelList={true}
              allowManual={true}
              type={serdesType}
            />
          </div>
        })}
      </div>
    </div>
  }
}

const mapDispatch = (dispatch) => ({
  _saveCompPCBModel(model, modelsObj, component) {
    dispatch(saveCompPCBModel(model, modelsObj, component))
  },
  _saveCompPackageModel(pkg, component, modelsObj) {
    dispatch(saveCompPackageModel(pkg, component, modelsObj))
  },
  _saveCompConnectorModel(model, component, modelList) {
    dispatch(saveCompConnectorModel(model, component, modelList))
  }
})

export default connect(null, mapDispatch)(MultiModelContent);