import { getVerificationJson } from '@/services/Sierra/results';
import SierraVerify from '@/services/Sierra/PinToPinVerifyDatabase';
import designConstructor from '../../helper/designConstructor';
import { getPreLayoutJsonByVerificationId } from '../results';

class ResultData {
  constructor() {
    this.verificationId = null;
    this.subId = null;
    this.verificationJson = null;
    this.Interface = null;
    this.Info = null;
    this.preLayoutInfo = new Map()
  }

  cleanAll = () => {
    this.verificationId = null;
    this.subId = null;
    this.verificationJson = null;
    this.Interface = null;
    this.Info = null;
    this.preLayoutInfo = new Map()
  }

  cleanVerificationJson = () => {
    this.verificationJson = null;
  }

  getVerificationJsonPromise = (verificationSubId, verificationId, projectId) => {
    return new Promise((resolve, reject) => {
      if (projectId) {
        if (this.verificationId === verificationId && this.subId === verificationSubId && this.verificationJson) {
          resolve(this.verificationJson);
        } else {
          this.cleanVerificationJson();
          getVerificationJson(verificationId, projectId).then(res => {
            this.subId = verificationSubId;
            this.verificationId = verificationId;
            this.verificationJson = res;
            this.cleanInfo();
            this.cleanInterface();
            const { interfaces, info } = saveInterface(res);
            this.Interface = interfaces;
            this.Info = info;
            resolve(this.verificationJson);
          }, error => {
            resolve(null);
          })
        }
      } else {
        resolve(null);
      }
    })
  }

  cleanInterface = () => {
    this.Interface = null;
  }

  getInterfaces = () => {
    return this.Interface;
  }

  cleanInfo = () => {
    this.Info = null;
  }

  getInfo = () => {
    return this.Info;
  }

  getVerificationId = () => {
    return this.verificationId
  }

  getPreLayoutJsonByVerificationId = (verificationId) => {
    return new Promise((resolve, reject) => {
      const data = this.preLayoutInfo.get(verificationId);
      if (data && data.length) {
        resolve(data)
      } else {
        getPreLayoutJsonByVerificationId(verificationId).then(res => {
          this.preLayoutInfo.set(verificationId, res ? res : []);
          resolve(res)
        }, error => {
          this.preLayoutInfo.set(verificationId, []);
          console.error(error);
          resolve([])
        });
      }
    })
  }
}


export function saveInterface(res) {
  if (!res || !res.Interfaces) {
    return { interfaces: [], info: {} }
  }
  let interfaces = [...res.Interfaces];
  interfaces.forEach(item => {
    item.verificationID = res.VerificationID;
  })

  let Interfaces = [...interfaces];
  Interfaces.forEach(item => {
    let pcb = null, pcbId = null, PCBID = null;
    //TODO  PCB type
    if (item.pcb && typeof (item.pcb) === "object") {
      pcb = item.pcb.name;
      pcbId = item.pcb.id;
      PCBID = item.pcb.PCBID ? item.pcb.PCBID : item.pcb.id;
    } else if (item.pcb && item.pcbId) {
      pcb = item.pcb;
      pcbId = item.pcbId;
      PCBID = item.PCBID ? item.PCBID : item.pcbId;
    }
    const findPCB = designConstructor.getDesign(PCBID);
    //pre layout rename fix
    const currPCBName = findPCB && findPCB.name ? findPCB.name : null;
    item.pcb = currPCBName || pcb;
    item.pcbId = pcbId;
    item.PCBID = PCBID;

  });
  const SETUP = SierraVerify;
  const info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name, true);
  return { interfaces, info }
}

const _ResultData = new ResultData();
export default _ResultData;