import React, { Component } from "react";
import { createPortal } from 'react-dom';
import Panel from "@/components/Panel/Panel";
import "./index.css";

class WireBondHelpPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.dialogRoot = document.getElementById('root');
  }


  closeModal = () => {
    this.props.closeHelp();
  }

  render() {
    const content = (
      <Panel
        className='top-sus-wire-bond-panel'
        title={<div className='top-sus-wire-bond-title'>Wire Bond Profile Help</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={600}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
      >
        <div className='top-sus-wire-bond-help-content'>
          <img src={`/lib/imgs/svgImgs/wire_bond_4.svg`} alt="" className={`wire-bond-img`} />
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default WireBondHelpPanel;