import http from '../http';

/**
 * Do simulation
 *
 * @export
 * @param {array} ids
 * [
 *  {
 *    "pdnId": "string",
 *    "verificationIds": [
 *      "string"
 *    ]
 *  }
 * ]
 * 
 * 
 * 
 * @returns XMLHttpRequest 
 */
export function doSimulation(ids) {
  return http.post(`/verify/rocky/v2`, ids);
}

/**
 * Get Rocky Verification Log
 * @param {string} verificationId
 * @export
 * @returns XMLHttpRequest
 */
export function getRockyVerificationLog(verificationId) {
  return http.get(`/debug/rocky/verification/${verificationId}/log`)
}

/**
 * Get channel property results list
 *
 * @export
 * @param {*} verificationId
 * @returns
 */
export function getChannelResultList(verificationId) {
  return http.get(`/verify/project/verification/${verificationId}/Rocky/snpFileList`)
}

/**
 *  Get sparameter curve
 *
 * @export
 * @param {*} verificationId
 * @param {*} params { col: [0], path: "string", "rows": [0] }
 * @returns
 */
export function getChannelResultCurve({ verificationId, fileName, params }) {
  return http.post(`/verify/project/verification/${verificationId}/Rocky/${fileName}/data`, params)
};


/**
 * Re-calculate eye diagram and margin result after updating eye mask
 *
 * @param {*} params [{ "eyemaskId": "string", "force": true, "origin": "string", "verificationId": "string" }]
 * @returns
 */
export function eyediagramGenerate(params) {
  return http.post(`/verify/rocky/eye-diagram/generate`, params)
}

export function getVerificationEyeMaskSetting(verificationId) {
  return http.get(`/verification/eyemask/setting`, { params: { verificationId } })
}

// Get eye diagram status, Whether the eye diagram can be generated
export function canGenerateEyeDiagram(verificationId) {
  return http.get(`/verify/rocky/eye-diagram/status`, { params: { verificationId } });
}

/**
 * generate customized eye diagram
 *
 * @export
 * @param {*string} verificationId
 * @param {*object} eyeDiagramConfig
 * @returns
 */
export function generateRockyEyeDiagram({ verificationId, eyeDiagramConfig }) {
  return http.post(`/rocky/eye-diagram/generate`, {
    ...eyeDiagramConfig,
    verificationId
  });
}

/**
 * get customized eye diagram eye pic, response: file
 *
 * @export
 * @param {*string} verificationId
 * @param {*string} interfaceId
 * @returns
 */
export function getRockyEyeDiagramResult({ verificationId, interfaceId, component, adjusted, type = 'PNG' }) {
  return http.get(`/rocky/eye-diagram/result`, {
    params: {
      verificationId,
      interfaceId,
      component,
      type,
      adjusted
    },
    responseType: type === 'PNG' ? 'arraybuffer' : 'json'
  });
}

/**
 *  Get package sparameter curve
 *
 * @export
 * @param {*} verificationId
 * @param {*} params { col: [0], type: "string", "rows": [0] }
 * @returns
 */
export function getPackageChannelResultCurve({ verificationId, curveRequestDTO }) {
  return http.post(`/rocky/package/curve-data?channelId=${verificationId}`, curveRequestDTO)
};


export function doPackageSimulation(ids) {
  return http.post(`/rocky/package/channel/run`, ids);
}

export function doPackagePDNSimulation(id) {
  return http.post(`/rocky/pdn/run`, { pdnId: id })
}

export function doSSNSimulation(verificationId) {
  return http.post(`rocky/ssn/channel/simulation?verificationId=${verificationId}`)
}

export function doPCBChannelSimulation(id) {
  return http.post(`rocky/ssn/channel/layout/extraction?channelId=${id}`)
}

export function doSSNExtraction({ verificationId, signal, pdn }) {
  return http.post(`rocky/ssn/channel/extraction?pdn=${pdn}&signal=${signal}&verificationId=${verificationId}`)
}

export function getMerge() {
  return http.get(`/rocky/ssn/channel/merge/extraction/curve-data`)
}

/**
 * Get merge channel property results list
 *
 * @export
 * @param {*} verificationId
 * @returns
 */
export function getMergeChannelResultList({ verificationId, category }) {
  return http.get(`/rocky/ssn/channel/merge/extraction/result-file?category=${category}&verificationId=${verificationId}`)
}

/**
 * Get merge channel property results list
 *
 * @export
 * @param {*} verificationId
 * @returns
 */
export function getMergeChannelResultCurve({ verificationId, curveRequestDTO }) {
  return http.post(`/rocky/ssn/channel/merge/extraction/curve-data?verificationId=${verificationId}`, curveRequestDTO)
}

/**
 * Get Rocky ssn channel Log
 * @param {string} verificationId
 * @export
 * @returns XMLHttpRequest
 */
export function getRockySSNChannelLog(verificationId) {
  return http.get(`/rocky/ssn/channel/log?verificationId=${verificationId}`)
}