import { getTextWidth } from "../helper/getTextWidth";
import { CONNECTOR, IC, RES } from "../PCBHelper";
import { select, event } from 'd3-selection';
import { drag } from "d3-drag";
import { getColor, getTitle } from ".";



class ElementsCanvas {
  constructor(props) {
    const { svgElement, width = 140, height, top, types = [IC, CONNECTOR, RES], createElement, saveElement, updateElementLocation } = props;
    this.svgElement = svgElement;
    this.width = width;
    this.types = types || [];
    this.top = top || 0;
    //55 is rect height, 20 id rect padding top, this.top is content box top
    this.height = height ? height : this.types.length ? this.types.length * 55 + 20 + this.top : 200;
    this.createElement = createElement;
    this.saveElement = saveElement;
    this.updateElementLocation = updateElementLocation;
  }

  clear = () => {
  /*   this.svgElement = null;
    this.width = 140;
    this.height = 200;
    this.types = [];
    this.createElement = null;
    this.saveElement = null;
    this.updateElementLocation = null; */
    select(this.svgElement).selectAll('*').remove();
  }

  draw = () => {
    if (!this.svgElement) {
      return
    }
    select(this.svgElement).selectAll('*').remove();
    this.root = select(this.svgElement);
    this.root.attr("width", this.width);
    this.root.attr("height", this.height);
    let initialX, initialY;
    let rectKey, rectColor;
    let newX, newY;
    const plot = this;
    this.rectDrag = drag().on("start", function () {

      const currElement = select(this);
      rectKey = currElement.attr("key");
      const element = select(`rect.rect-${rectKey}`);
      initialX = +element.attr("x");
      initialY = +element.attr("y");
      rectKey = element.attr("key");
      rectColor = element.attr("stroke");
      newX = NaN; newY = NaN;

      plot.dragRect = plot.root.append("rect")
        .attr("id", "drag-rect")
        .attr('x', initialX)
        .attr('y', initialY)
        .attr('width', 110)
        .attr('height', 40)
        .attr('rx', 6)
        .attr('ry', 6)
        .attr('key', rectKey)
        .attr('stroke', rectColor)
        .attr("fill", "none")
        .attr('opacity', 0.5)
        .attr('stroke-width', 3);
      plot.createElement({ key: rectKey, x: initialX, y: initialY })
    }).on("drag", function () {
      const dx = event.x - event.subject.x;
      newX = initialX + dx;

      const dy = event.y - event.subject.y;
      newY = initialY + dy;

      plot.dragRect
        .attr("x", newX)
        .attr("y", newY);
      plot.updateElementLocation({ key: rectKey, x: newX, y: newY })
    }).on("end", () => {
      plot.saveElement({ key: rectKey, x: newX, y: newY });
      select("#drag-rect").remove();
    })
    let num = 0;
    for (let key of this.types || []) {
      //rect
      const color = getColor(key);
      const title = getTitle(key);
      this.root.append('rect')
        .attr("class", `rect-${key}`)
        .attr('x', 15)
        .attr('y', 20 + this.top + num * 55)
        .attr('width', 110)
        .attr('height', 40)
        .attr('rx', 6)
        .attr('ry', 6)
        .attr('key', key)
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 3)
        .attr("pointer-events", "all")
        .on("mouseover", function () {
          select(this).style("cursor", "pointer");
        })
        .on("mouseout", function () {
          select(this).style("cursor", "");
        })
        .call(this.rectDrag)

      //text
      const textWidth = getTextWidth(title, 14);
      this.root.append("text")
        .text(title)
        .attr("class", `text-${key}`)
        .attr('x', 68 - textWidth / 2)
        .attr('y', 20 + this.top + num * 55 + 24)
        .style("cursor", "pointer")
        .attr("font-weight", "500")
        .attr('key', key)
        .call(this.rectDrag)
      num += 1;
    }
  }
}

export default ElementsCanvas;