import http from "../http";

export function getCascadeIRData(verificationId) {
  return http.get(`/Cascade/verification/${verificationId}/ir`)
}

export function updateCascadeIRData(data) {
  return http.post(`/Cascade/update/ir`, data)
}

export function deleteCascadeIRData({ ids, verificationId }) {
  return http.post(`/Cascade/delete/ir`, { ids, verificationId })
}

export function importCascadeIRData() {
  return http.post(`/Cascade/ir/import`)
}

export function simulationIR(verificationId) {
  return http.post(`/Cascade/ir/simulation?verificationId=${verificationId}`)
}

export function importIRExplorer({ verificationId, file, importType }) {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return http.post(`/Cascade/ir/import?verificationId=${verificationId}&importType=${importType}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function getIRResultList(verificationId) {
  return http.get(`/Cascade/ir/result/list?verificationId=${verificationId}`)
}
export function getIRResultFile(resultId, resultType, verificationId) {
  return http.get(`/Cascade/ir/result/file?resultId=${resultId}&resultType=${resultType}&verificationId=${verificationId}`)
}

export function getResultPowerDomainList(verificationId) {
  return http.get(`Cascade/verification/${verificationId}/ir/data`)
}

export function saveIRSetting({ verificationId, setup }) {
  return http.post(`/Cascade/update/ir/setup`, {
    verificationId,
    ...setup
  })
}