import { ALL } from '../../../constants';
import { ResultData } from '../../index';

function eyeDigramByteItem({ id, readWriteMode, selectEyeParam = [], imgSrc = null, eyeParams = null, loading = false, memory = null, isTrain = false, signalSelect = ALL }) {
  this.id = id;
  this.type = 'byte';                     // Verification type
  this.loading = loading;                   // Table loading
  this.readWriteMode = readWriteMode;     // Read or write mode
  this.title = readWriteMode ? readWriteMode.replace(/^\S/, s => s.toUpperCase()) : '';
  this.signalSelect = signalSelect;              // Select signal
  this.imgSrcs = null;                 // Eyediagram image src for all signal
  this.imgSrc = imgSrc;                   // Eyediagram image src for select signal
  this.eyeParams = eyeParams;             // Eyediagram parameters data for all signals
  this.selectEyeParam = selectEyeParam;   // Eyediagram parameter data for select signal
  this.loadingSingle = true;              // loading single signle
  this.memory = memory;
  this.isTrain = isTrain;
};

function eyeDigramADRCMDItem({ id, memory = null, selectEyeParam = [], imgSrc = null, eyeParams = null, loading = false, isTrain = false, signalSelect = ALL }) {
  this.id = id;
  this.type = 'adrcmd';                   // Verification type
  this.loading = loading;                   // Table loading
  this.signalSelect = signalSelect;              // Select signal
  this.memory = memory;                   // Select memory name
  this.title = memory;
  this.imgSrcs = null;                 // Eyediagram image src for all signal
  this.imgSrc = imgSrc;                   // Eyediagram image src for select signal
  this.eyeParams = eyeParams;             // Eyediagram parameters data for all signals
  this.selectEyeParam = selectEyeParam;   // Eyediagram parameter data for select signal
  this.loadingSingle = true;              // loading single signle
  this.isTrain = isTrain
}

async function getJson({ verificationId, channelId, verificationSubId }) {
  const content = await ResultData.getVerificationJsonPromise({ verificationId, channelId, verificationSubId });
  if (content &&
    content.Interfaces &&
    content.Interfaces.length > 0 &&
    content.Interfaces[0].Content &&
    content.Interfaces[0].Content.Signals) {
    return content;
  }
  return null;
}

function isByte(name) {
  return name ? name.match(/^Byte/g) : null;
}

export { eyeDigramByteItem, eyeDigramADRCMDItem, getJson, isByte }