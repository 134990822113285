import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Button, Input } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { getTextWidth } from '@/services/helper/getTextWidth';
import { getCustomLibraryPath } from '@/services/Cascade/helper/match';
import { CustomLibraryListRender } from '@/components/ModelSelect/customLIbraryListRender';

class SearchLibraryPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 500,
      maxHeight: 500,
      partValue: "",
      customLibraryList: []
    };
    this.dialogRoot = document.getElementById('root');
    this.pageRef = createRef()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset),
      maxHeight: getPanelMaxHeight(offset, 500),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  closeModal = () => {
    this.props.closeModal();
  }

  searchLibrary = () => {
    const { partValue } = this.state;
    this.props.getCustomLibSearch(partValue).then(res => {
      let customLibraryList = [];
      if (res) {
        customLibraryList = [...res];
        customLibraryList.forEach(library => {
          library.path = library.type === "data" ? `${library.folderPath}/${library.name}` : library.path;
          library.displayPath = getCustomLibraryPath(library.path);
        })
        const maxNameLibrary = customLibraryList.length ? customLibraryList.sort((a, b) => { return b.name.length - a.name.length })[0] : { name: "" };
        let maxName = maxNameLibrary.name || ""
        maxName = maxName.length > "ManufacturerPartNumber".length ? maxName : "ManufacturerPartNumber";
        this.nameWidth = getTextWidth(maxName, 14, 350, "bold") + 20;

        const maxManufacturerLibrary = customLibraryList.length ? customLibraryList.sort((a, b) => { return b.manufacturer.length - a.manufacturer.length })[0] : { manufacturer: "" };
        const maxManufacturer = maxManufacturerLibrary.manufacturer || ""
        const manufacturerTitleWidth = getTextWidth("Manufacturer", 14, 350, "bold") + 20;
        const manufacturerWidth = getTextWidth(maxManufacturer, 14, 350) + 20;
        this.manufacturerWidth = manufacturerWidth > manufacturerTitleWidth ? manufacturerWidth : manufacturerTitleWidth;
      } else {
        this.nameWidth = getTextWidth("ManufacturerPartNumber", 14, 350, "bold") + 20;
        this.manufacturerWidth = getTextWidth("Manufacturer", 14, 350, "bold") + 20;
      }
      this.setState({ customLibraryList });
    }, (error) => {
      console.error(error);
      this.setState({ customLibraryList: [] });
      this.nameWidth = getTextWidth("ManufacturerPartNumber", 14, 350, "bold") + 20;
      this.manufacturerWidth = getTextWidth("Manufacturer", 14, 350, "bold") + 20;
    })
  }

  changePartValue = (e) => {
    this.setState({ partValue: e.target.value })
  }

  render() {
    const { maxWidth, maxHeight, partValue, customLibraryList } = this.state;
    const { title } = this.props;
    const content = (
      <Panel
        className='cascade-search-library-panel'
        title={<div className='cascade-search-library-title'>{title}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: customLibraryList.length ? maxWidth : 600 })}
        maxHeight={maxHeight}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={600}
        defaultTop={200}
      >
        <Fragment>
          <div className='search-library-content'>
            <div className='search-library-content-item'>
              <span>Part</span>
              <Input
                value={partValue}
                className="search-library-input"
                onChange={this.changePartValue}
              />
              <div className='search-library-content-button'>
                <Button
                  onClick={() => this.searchLibrary()}
                  size="small"
                  className='search-library-btn'
                >Search</Button>
              </div>
            </div>
          </div>
          {CustomLibraryListRender({
            customLibraryList,
            nameWidth: this.nameWidth,
            manufacturerWidth: this.manufacturerWidth
          })}
        </Fragment>
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }
};


export default SearchLibraryPanel;