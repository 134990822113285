/** A container of canvas objects for a layer in the layout design */
var LayerRenderer = function (layoutRenderer, layerName, hidden) {

  if (!hidden) hidden = null;

  // the g element has to be created at construction because the layerRenderer
  // object is constructed in a certain order to ensure the geometry overlapping
  // is correct
  this.layerElement = layoutRenderer.d3Root
    .append('g')
    .attr('layer', layerName)
    .attr('hidden', hidden);

  this.layoutRenderer = layoutRenderer;
  this.layoutDB = layoutRenderer.layoutDB;
  this.layerName = layerName;

  this.dataLoaded = false;
  this.rendered = false;
};

/** remove the layer element */
LayerRenderer.prototype.remove = function () {
  this.layerElement.remove();
};

/** process the layout DB data in this layer and construct the canvas objects without rendering */
LayerRenderer.prototype.prepareData = function () {
  this.dataLoaded = true;
  this.rendered = false;
}; // LayerRenderer.prototype.prepareData

/** draw all the objects on this layer */
LayerRenderer.prototype.redraw = function () {
  if (this.dataLoaded) this.rendered = true;
}; // LayerRenderer.prototype.redraw

/** virtual function - set the object visibility of this layer */
LayerRenderer.prototype.updateVisibility = function () {};

/** virtual function - set the object color of this layer */
LayerRenderer.prototype.updateColoring = function () {};

/** virtual function - for line shapes, update the line width of the layer */
LayerRenderer.prototype.updateStrokeWidth = function () {};

export default LayerRenderer;