import { SYSTEM_VIEW } from '@/services/BMA/helper/result';
import { UPDATE_RESULT_KEY } from './actionType';
import { CLEAR_ALL_BMA_INFO } from '../channel/actionType';

const defaultState = {
  resultKey: SYSTEM_VIEW
};

export const BMAResultReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_RESULT_KEY:
      return {
        ...state,
        resultKey: action.resultKey
      }
    case CLEAR_ALL_BMA_INFO:
      return {
        ...defaultState
      }
    default: return state;
  }
}