import React, { Component } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Select, Input, Divider } from 'antd';
import { getYLabel } from '../../../helper/sparameter/utility';
import { scaleConversion } from '../../../helper/numberHelper';
import NP from 'number-precision';
import './sparameterSetting.css';

const attr = {
  type: "flex",
  justify: "space-around",
  align: "middle"
}

class SparameterSettingComponent extends Component {
  constructor() {
    super()
    this.state = {
      setting: {
        parameter: 'S', // S / Y / Z
        value: 'db' // am / ph / re / im / db
      },
      axis: {
        xShow: true,
        yShow: true,
        xMin: '',
        xMax: '',
        yMin: '',
        yMax: '',
        yUnit: '',
        xScale: 'linear',
        yScale: 'linear'
      },
      XSettingUnit: 'MHz',
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  getSetting = () => {
    return this.state.setting;
  }

  getAxis = () => {
    return this.state.axis;
  }

  setSetting = (setting) => {
    this.setState({
      setting
    })
  }

  setAxis = (axis) => {
    this.setState((prevState) => ({
      axis: { ...prevState.axis, ...axis }
    }))
  }

  getXUnit = () => {
    return this.state.XSettingUnit;
  }

  settingChange = (type, val) => {
    let { axis, setting } = this.state;
    setting[type] = val;
    const { value, parameter } = setting;
    let { yScale } = axis;
    if (value === 'am' && parameter === 'S') {
      yScale = 'linear';
    } else if (value === 'ph') {
      yScale = 'degrees';
    } else if (value === 're' || value === 'im') {
      yScale = 'linear';
    } else {
      yScale = 'linear';
    }
    axis.yScale = yScale;
    this.setState({
      axis,
      setting
    }, () => {
      this.props.settingChangeCB();
    })
  }

  showAxisSetting = (type) => {
    this.setState((prevState) => {
      prevState.axis[type] = !prevState.axis[type];
      return {
        axis: prevState.axis
      }
    }, () => {
      this.props.changeFileListBottom();
    })
  }

  /**
 * axis -  x | y
 */
  axisChange = (axis, value) => {
    this.setState((prevState) => {
      prevState.axis[axis + 'Scale'] = value;
      return {
        axis: prevState.axis
      }
    }, () => {
      this.props.axisChangeCB(axis, value);
    })
  }

  changeRange = (type, e) => {
    const value = e.target.value;
    this.setState((prevState) => {
      prevState.axis[type] = value;
      return {
        axis: prevState.axis
      }
    })
  }

  axisRangeKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  XUnitChange = (unit) => {
    const { axis, XSettingUnit } = this.state;
    let _axis = { ...axis }
    const scale = scaleConversion(unit, XSettingUnit);
    const xMin = _axis.xMin * scale;
    _axis.xMin = parseFloat(xMin.toPrecision(12));
    const xMax = _axis.xMax * scale;
    _axis.xMax = parseFloat(xMax.toPrecision(12));
    this.setState({
      XSettingUnit: unit,
      axis: { ..._axis }
    });
  }

  updateRange = (type, value) => {
    const { axis, XSettingUnit, prevAxis } = this.state;
    let _axis = { ...axis };

    // if scale = log, value can not be 0
    if ((((type === 'xMin' || type === 'xMax') && axis.xScale === 'log')
      || ((type === 'yMin' || type === 'yMax') && axis.yScale === 'log'))
      && (Number(value) < 0 || isNaN(Number(value)))) {
      this.setState({
        axis: prevAxis
      })
      return;
    }

    _axis[type] = parseFloat(value);
    let min = _axis.xMin, max = _axis.xMax, update = 'x';

    if (type === 'xMax' || type === 'xMin') {
      if (XSettingUnit === 'KHz') {
        max = NP.times(max, 1e3);
        min = NP.times(min, 1e3);
      } else if (XSettingUnit === 'MHz') {
        max = NP.times(max, 1e6);
        min = NP.times(min, 1e6);
      } else if (XSettingUnit === 'GHz') {
        max = NP.times(max, 1e9);
        min = NP.times(min, 1e9);
      }
    } else if (type === 'yMax' || type === 'yMin') {
      if (_axis.yScale === 'degrees') {
        if (_axis[type] < -360) {
          _axis[type] = -360;
        } else if (_axis[type] > 360) {
          _axis[type] = 360;
        }
      }
      if (_axis.yScale === 'radians') {
        if (_axis[type] < -3.14) {
          _axis[type] = -3.14;
        } else if (_axis[type] > 3.14) {
          _axis[type] = 3.14;
        }
      }
      update = 'y';
    }
    this.setState({
      axis: _axis
    }, () => {
      this.props.updateRangeCB(update, min, max);
    })
  }

  savePrevRange = () => {
    const { axis } = this.state;
    this.setState({
      prevAxis: JSON.parse(JSON.stringify(axis))
    })
  }

  render() {
    const { setting, axis, XSettingUnit } = this.state;
    return <SparameterSetting {...this.props}
      setting={setting}
      axis={axis}
      XSettingUnit={XSettingUnit}
      settingChange={this.settingChange}
      showAxisSetting={this.showAxisSetting}
      axisChange={this.axisChange}
      changeRange={this.changeRange}
      axisRangeKeyDown={this.axisRangeKeyDown}
      XUnitChange={this.XUnitChange}
      updateRange={this.updateRange}
      savePrevRange={this.savePrevRange}
    />
  }
}

class SparameterSetting extends Component {
  xInputAndSelectRender = (type) => {
    const { axis, portSelectLen, savePrevRange, changeRange, axisRangeKeyDown, updateRange, XSettingUnit, XUnitChange } = this.props;
    return <Input
      className='result-sp-setting-input'
      value={axis[type] || !isNaN(axis[type]) ? axis[type] : ""}
      disabled={portSelectLen === 0 ? true : false}
      onChange={(e) => changeRange(type, e)}
      onKeyDown={(e) => axisRangeKeyDown(e, type)}
      onBlur={(e) => updateRange(type, e.target.value)}
      onFocus={savePrevRange}
      addonAfter={
        <Select
          value={XSettingUnit ? XSettingUnit : 'MHz'}
          className='result-sp-unit-select'
          onChange={(value) => XUnitChange(value)}
          options={[
            { value: 'Hz', label: 'Hz' },
            { value: 'KHz', label: 'KHz' },
            { value: 'MHz', label: 'MHz' },
            { value: 'GHz', label: 'GHz' }
          ]}
        />
      }
    />
  }

  scaleChangeSelectRender = (key, type, options) => {
    const { axis, axisChange } = this.props;
    return <Select
      value={axis[key]}
      className='result-sp-setting-select'
      onChange={(value) => axisChange(type, value)}
      style={{ width: 105 }}
      options={options}
    />
  }

  yInputRender = (type) => {
    const { axis, portSelectLen, savePrevRange, changeRange, axisRangeKeyDown, updateRange } = this.props;
    return <Input
      className='result-sp-setting-input result-sp-y-input'
      value={axis[type] || !isNaN(axis[type]) ? axis[type] : ""}
      disabled={portSelectLen === 0 ? true : false}
      onChange={(e) => changeRange(type, e)}
      onKeyDown={(e) => axisRangeKeyDown(e, 'yMin')}
      onBlur={(e) => updateRange(type, e.target.value)}
      onFocus={savePrevRange}
      style={{ width: 105 }}
    />
  }

  render() {
    const { axis, setting, settingChange, showAxisSetting, showFormat = true } = this.props;
    const { value, parameter } = setting;
    return (
      <div className='result-parameter-setting-box'>
        <Divider orientation="left" style={{ marginTop: 6, marginBottom: 5, marginLeft: 14 }}>
          <span className='result-menu-title'>Setting</span>
        </Divider>
        <div>
          {showFormat ? <Row className='setting-row'>
            <Col span={10}>
              <label className='setting-input-lable padding-left-42'>Format</label>
            </Col>
            <Col span={14}>
              <Select
                value={setting.value}
                className='result-sp-setting-select'
                popupMatchSelectWidth={false}
                onChange={(value) => settingChange('value', value)}
                style={{ width: 105 }}
                options={[
                  { value: 'am', label: 'Magnitude' },
                  { value: 'ph', label: 'Phase' },
                  { value: 're', label: 'Real' },
                  { value: 'im', label: 'Imaginary' },
                  { value: "db", label: "dB" }
                ]}
              />
            </Col>
          </Row> : null}
          <Row onClick={() => showAxisSetting('xShow')} className='sp-axis-title'>
            {axis.xShow ? <DownOutlined className="result-setting-expand-icon" /> : <RightOutlined className="result-setting-expand-icon" />}
            <span>X axis -- Frequency</span>
          </Row>
          <Row style={{ display: axis.xShow ? 'block' : 'none' }} className='sp-axis-content'>
            <Row {...attr}>
              <Col span={10}>
                <label className='setting-input-lable padding-left-42'>Scale</label>
              </Col>
              <Col span={14}>
                {this.scaleChangeSelectRender('xScale', 'x', [
                  { value: 'linear', label: 'Linear' },
                  { value: 'log', label: 'Log' }
                ])}
              </Col>
            </Row>
            {[{ key: 'xMin', label: 'Begin' }, { key: 'xMax', label: 'End' }].map(info => {
              return <Row {...attr} key={`sparameter-setting-${info.key}`}>
                <Col span={10}>
                  <label className='setting-input-lable padding-left-42'>{info.label}</label>
                </Col>
                <Col span={14}>
                  {this.xInputAndSelectRender(info.key)}
                </Col>
              </Row>
            })}
          </Row>
          <Row onClick={() => showAxisSetting('yShow')} className='sp-axis-title'>
            {axis.yShow ? <DownOutlined className="result-setting-expand-icon" /> : <RightOutlined className="result-setting-expand-icon" />}
            <span>Y axis{getYLabel({ value, yScale: axis.yScale, parameter }) ? ` -- ${getYLabel({ value, yScale: axis.yScale, parameter })}` : ''}</span>
          </Row>
          <Row style={{ display: axis.yShow ? 'block' : 'none' }} className='sp-axis-content'>
            <Row {...attr}>
              <Col span={10}>
                <label className='setting-input-lable padding-left-42'>Scale</label>
              </Col>
              <Col span={14}>
                {this.scaleChangeSelectRender('yScale', 'y', [
                  { value: 'linear', label: 'Linear' },
                  { value: 'log', label: 'Log' },
                  { value: 'degrees', label: 'Degrees' },
                  { value: 'radians', label: 'Radians' }
                ])}
              </Col>
            </Row>
            {[{ key: 'yMin', label: 'Begin' }, { key: 'yMax', label: 'End' }].map(info => {
              return <Row {...attr} key={`sparameter-setting-${info.key}`}>
                <Col span={10}>
                  <label className='setting-input-lable padding-left-42'>{info.label}</label>
                </Col>
                <Col span={14}>
                  {this.yInputRender(info.key)}
                </Col>
              </Row>
            })}
          </Row>
        </div>
      </div>
    );
  }
}

export default SparameterSettingComponent;