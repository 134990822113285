import { call, put, takeEvery, select, delay, takeLatest, cancel, fork } from 'redux-saga/effects';
import { getWorkFlow, cancelVerificationWorkflow } from '@/services/api/v2/workflowCtrl';
import { workflowWaitingIndex, checkVerificationStatus, getMonitor } from '@/services/workflow/workflow';
import * as taskStatus from '@/constants/workflowStatus';
import { VERIFY_SUCCESS, VERIFY_FAILED, VERIFY_CANCEL, VERIFY_NEVER, VERIFY_RUNNING, WAITING, ERROR } from '@/constants/verificationStatus';
import { versionCompareSize, fakeProgress } from '@/services/helper/dataProcess';
import dayjs from 'dayjs';
import {
  getInterfaceWorkStatus
} from '@/services/project';
import {
  START_SIERRA_VERIFICATION,
  DEBUG_VERIFY,
  VERIFICATION_FLOW,
  GET_CURRENT_LOG,
  CANCEL_VERIFICATION_WORKFLOW,
  GET_INTERFACE_MONITOR,
  GET_VERIFICATION_MONITOR,
  UPDATE_SIMULATION_MESSAGE,
  GET_CURRENT_PROFILE,
  UPDATE_EXPERIMENT_QUEUE,
  GET_EXPERIMENT_LOG,
  CANCEL_EXPERIMENT,
  RUN_MULTI_EXPERIMENT_SIM
} from './actionTypes';
import {
  updateProgress,
  cleanSingleProgress,
  updateSingleMonitor,
  cleanMonitor,
  existResult,
  changeVerificationList,
  verificationFlow,
  updateCurrentLog,
  changeUploadMes,
  waitingIndexAction,
  updateErrorCheckList,
  updatePreProcessLog,
  getCurrentLog,
  updateMonitor,
  updateStartMsg,
  updateEndMsg,
  getCurrentProfile,
  saveCurrentProfile,
  saveExperimentRunning,
  updateConnectorErrors
} from './action';
import { getSierraErrorCheck, getSweepErrorCheck, connComponentsErrorCheck } from '../../errorCheck/ErrorCheck';
import { getSierraInterfaceErrorCheck } from '../../errorCheck/interfaceCheck';
import SierraVerify from '@/services/Sierra/PinToPinVerifyDatabase';
import { updateReadyForSim } from '@/services/api/interface';
import projectDesigns from '@/services/helper/projectDesigns';
import {
  doPinToPinSimulation, getLogInSierraPinToPinProject, doDebugVerify,
  getVerificationContentPromise, Extraction, SweepResultData,
  updateInterfacePromise, updateLibraryInInterface, updateInterfaceContent,
  updateConnector, runSweeping, getRunningExperimentLog, getCurrentExperimentLog,
  cancelCurrentExperiment, updateConnCompToUnused, removeUnusedCompsPorts, getConnectorList
} from '@/services/Sierra';
import { autoGetVerificationList, updateGeneratePortsErrors } from '../project/action';
import { updateSierraInfo, updateInterfaceLibraryMsg, updateTouchstoneStatusList, changeConnectorStatus } from '../sierra/action';
import { designReplace, _UpdateTouchstoneStatus } from '../sierra/saga';
import { saveComponentsNets } from '../project/saga';
import { changeTabMenu } from '../../../tabMonitor/action';
import { SIERRA_SETUP_VERSION } from '@/version';
import { ResultData } from '@/services/Sierra';
import monitorLog from '@/services/helper/monitorLog';
import getProfileData, { parseProfile, shouldGetProfile } from '@/services/helper/profileCtrl';
import { updateExtractionPortsSetup } from '@/services/Sierra/helper/extractionPortsHelper';
import DesignInfo from '@/services/Sierra/pcbInfo';
import { SIERRA } from '@/constants/pageType';
import { RESULT, VERIFICATION } from '@/constants/treeConstants';
import { workflowJudge } from '../../../../services/workflow/workflowHelper';
import { updateSetupComponentsByPortType } from '@/services/ExtractionPortsHelper';
import componentSetting from '@/services/helper/componentsHelper/compSettingHelper';
import { getDesignStackupJson } from "@/services/api/designFile";
import { stackupErrorCheck } from "@/services/Stackup";
import designConstructor from '../../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import { updateRecalc, updateSweepReCalc } from '../result/action';
import { updateMultiSetupSimErrorMsg } from '../multiInterface/action';
import { getLibraryErrorList } from '../../../../services/Sierra/helper/monitorHelper';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import totalTraceCapacitance from '../../../../services/Sierra/results/totalTraceCapacitance/capacitanceConstructor';
import { updateLogFormat } from '../../../../services/helper/monitorLog';
import { updateExperimentInfo, updateExperimentStatus } from '../sweep/action';

let workflowTask = null;
const SIERRA_SETUP_UPDATE_VERSION = "3.1.0";

function* startVerification(action) {
  let { verificationIds, currentVerificationId, updateConn = false, isMultiSim = false } = action;
  if (!verificationIds) return;
  yield put(changeVerificationList([]));

  if (verificationIds.length === 0) {
    return;
  }

  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }

  const { SierraReducer: { project, simulationReducer } } = yield select();
  const { currentProjectId } = project;
  let startMsg = simulationReducer.startMsg;
  let endMsg = simulationReducer.endMsg;
  let currentSimInfo = [];
  //update interface list status(checking)
  verificationIds.forEach(item => {
    currentSimInfo.push({
      verificationId: item,
      status: 'checking'
    });
  });
  yield put(autoGetVerificationList(currentProjectId, currentSimInfo));

  yield call(clearMonitorBeforeRun, { verificationIds });

  //pcb replace check and error check
  try {
    yield call(checkVerification, { verificationIds, currentSimInfo, updateConn, isMultiSim });
  } catch (error) {
    console.error(error);
  }
  const { SierraReducer: { simulationReducer: { connError } } } = yield select();
  const _verificationId = currentVerificationId ? currentVerificationId : verificationIds[0];
  if (connError && connError.id === _verificationId) {
    //update start message
    const { SierraReducer: { simulationReducer } } = yield select();
    verificationIds = verificationIds.filter(item => item !== _verificationId);
    startMsg = simulationReducer.startMsg;
    startMsg = startMsg.filter(msg => msg.verificationId !== _verificationId);
    yield put(updateStartMsg(startMsg));
    yield put(autoGetVerificationList(currentProjectId));
    /*  return; */
  }

  //filter verification by error check exist
  const { SierraReducer: { simulationReducer: { sierraInfoErrorCheck } } } = yield select();
  if (sierraInfoErrorCheck.length > 0) {
    let ids = sierraInfoErrorCheck.map(item => item.verificationId);
    verificationIds = verificationIds.filter(item => !ids.includes(item));
    //update start message
    const { SierraReducer: { simulationReducer } } = yield select();
    startMsg = simulationReducer.startMsg;
    startMsg = startMsg.filter(msg => !ids.includes(msg.verificationId));
    yield put(updateStartMsg(startMsg));
  }

  if (!verificationIds || verificationIds.length === 0) {
    yield put(autoGetVerificationList(currentProjectId));
    return;
  }
  const verificationId = currentVerificationId ? currentVerificationId : verificationIds[0];
  //clean pre process log
  yield* verificationIds.map(function* (item) {
    let { SierraReducer: { simulationReducer: { preProcessLog } } } = yield select();
    const preIndex = preProcessLog.findIndex(i => i.verificationId === item);
    if (preIndex > -1) {
      preProcessLog[preIndex].log = null;
    }
    yield put(updatePreProcessLog(preProcessLog));
  })
  yield put(autoGetVerificationList(currentProjectId));
  try {

    let response = yield call(doPinToPinSimulation, verificationIds);

    if (!response || response.length === 0 || !Array.isArray(response)) {
      yield put(updateProgress({ verificationId, progress: -1 }));
      yield put(cleanSingleProgress(verificationId));
      yield put(changeVerificationList([]));
      const { SierraReducer: { simulationReducer } } = yield select();
      startMsg = simulationReducer.startMsg;
      endMsg = simulationReducer.endMsg;
      startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
      yield put(updateStartMsg(startMsg));

      let msg = response && response.msg ? response.msg : 'Simulation failed!';
      const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
      if (msgIndex > -1) {
        endMsg[msgIndex].msg = `==> ${msg}`;
      } else {
        endMsg.push({ msg: `==> ${msg}`, verificationId })
      }
      yield put(updateEndMsg(endMsg));
      yield put(autoGetVerificationList(currentProjectId));
      if (isMultiSim) {
        yield delay(800)
        yield put(updateMultiSetupSimErrorMsg([{
          title: "",
          logs: [`==> ${msg}`]
        }]))
      }
      return;
    }

    // fake progress
    // id: workflowId
    response.find(item => item.verificationId === verificationId);
    yield put(autoGetVerificationList(currentProjectId));
    const verificationWork = yield call(getInterfaceWorkStatus, verificationId);
    if (verificationWork && verificationWork.length > 0) {
      if (workflowTask) {
        yield cancel(workflowTask);
      };
      workflowTask = yield fork(verificationWorkFlow, { verificationId, verificationWork });
      return;
    };
    /* if (isMultiSim) {
      const { SierraReducer: { multiInterfaceSetup: { simCheckLogs } } } = yield select();
      let _simCheckLogs = [...(simCheckLogs || [])]
      for (let id of verificationIds) {
        const index = (_simCheckLogs || []).findIndex(item => item.verificationId === id);
        if (index > -1) {
          _simCheckLogs[index].logs = ["==> Simulation running..."];
        }
      }
      yield delay(1000)
      yield put(updateMultiSetupSimErrorMsg(_simCheckLogs))
    } */
  } catch (error) {
    console.error(error);
    yield delay(1000);
    yield put(updateProgress({ verificationId, progress: -1 }));
    yield put(cleanSingleProgress(verificationId));
    yield put(changeVerificationList([]));
    const { SierraReducer } = yield select();
    startMsg = SierraReducer.simulationReducer.startMsg;
    endMsg = SierraReducer.simulationReducer.endMsg;
    startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
    yield put(updateStartMsg(startMsg));

    endMsg = endMsg.filter(msg => msg.verificationId !== verificationId);
    yield put(updateEndMsg(endMsg));
    if (isMultiSim) {
      yield put(updateMultiSetupSimErrorMsg([{
        title: "",
        logs: ["==> Simulation failed!"]
      }]))
    }
  }
}

function* clearMonitorBeforeRun(action) {
  const { verificationIds } = action;
  //clear prev monitor/ msg /library file error check msg
  yield* verificationIds.map(function* (item) {
    //delete total trace capacitance csv cache
    totalTraceCapacitance.del(item);
    yield put(cleanMonitor(item));
    //update start message
    const { SierraReducer: { simulationReducer, sierra: { TouchstoneStatusList } } } = yield select();
    let startMsg = simulationReducer.startMsg;
    let endMsg = simulationReducer.endMsg;
    let msg = '==> Start simulating...';
    const msgIndex = startMsg.findIndex(msg => msg.verificationId === item);
    if (msgIndex > -1) {
      startMsg[msgIndex].msg = msg;
    } else {
      startMsg.push({ msg, verificationId: item })
    }
    yield put(updateStartMsg(startMsg));
    //delete end message
    endMsg = endMsg.filter(msg => msg.verificationId !== item);
    yield put(updateEndMsg(endMsg));
    yield put(updateCurrentLog(null, item));
    yield put(changeUploadMes(null));

    let touchstoneStatusList = TouchstoneStatusList.filter(it => it.verificationId !== item);
    yield put(updateTouchstoneStatusList(touchstoneStatusList));

    //clean library file exist check message
    const { SierraReducer: { sierra: { interfaceLibraryError } } } = yield select();
    if (interfaceLibraryError) {
      let _interfaceLibraryError = [...interfaceLibraryError];
      let errorIndex = _interfaceLibraryError.findIndex(i => i.verificationId === item);
      if (errorIndex > -1) {
        _interfaceLibraryError.splice(errorIndex, 1);
      }
      yield put(updateInterfaceLibraryMsg(_interfaceLibraryError));
    }
  });
}

function* getVerificationMonitor(action) {
  const { workflowId, verificationId } = action;
  // get log
  const log = yield call(getMonitor, workflowId);
  if (log.length > 0) {
    yield put(updateSingleMonitor({ workflowId, monitor: monitorLog(log, null, true), verificationId }));
  }
}

function* startDebugVerify(action) {
  const { verificationId, step } = action;

  let { SierraReducer: { simulationReducer: { startMsg, endMsg } } } = yield select();
  //clear prev monitor/message/log
  yield put(cleanMonitor(verificationId));
  yield put(updateCurrentLog(null, verificationId));
  //Add start msg
  let msg = '==> Start simulating...';
  const msgIndex = startMsg.findIndex(msg => msg.verificationId === verificationId);
  if (msgIndex > -1) {
    startMsg[msgIndex].msg = msg;
  } else {
    startMsg.push({ startMsg: msg, verificationId })
  }
  yield put(updateStartMsg(startMsg));
  //clear prev end
  endMsg = endMsg.filter(msg => msg.verificationId !== verificationId);
  yield put(updateEndMsg(endMsg));
  yield put(changeUploadMes(null));

  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  const verificationWork = yield call(getInterfaceWorkStatus, verificationId);
  if (verificationWork) {
    yield put(verificationFlow({ verificationId, verificationWork }));
    return;
  }
  try {
    //get sierra info (verification json)
    let sierraInfo = null, num = 1, time;
    while (!sierraInfo || (sierraInfo && Object.keys(sierraInfo).length === 0) || (sierraInfo.verificationId !== verificationId)) {
      yield delay(500);
      const { SierraReducer: { sierra } } = yield select();
      sierraInfo = sierra.sierraInfo ? sierra.sierraInfo : null;
      num += 1;
      if (num === 5) {
        break;
      }
    }

    //error check
    const { SierraReducer: { simulationReducer: { sierraInfoErrorCheck }, library: { defaultBufferSpice } } } = yield select();
    const errorCheck = getSierraErrorCheck(sierraInfo, defaultBufferSpice);
    if (errorCheck) {
      const index = sierraInfoErrorCheck.findIndex(item => item.verificationId === verificationId);
      if (index > -1) {
        sierraInfoErrorCheck[index].errorCheck = { ...errorCheck };
      } else {
        sierraInfoErrorCheck.push({
          verificationId: verificationId,
          errorCheck: errorCheck
        })
      }
      yield put(updateErrorCheckList(sierraInfoErrorCheck));
    } else {
      const index = sierraInfoErrorCheck.findIndex(item => item.verificationId === verificationId);
      if (index > -1) {
        sierraInfoErrorCheck.splice(index, 1);
      }
      yield put(updateErrorCheckList(sierraInfoErrorCheck));
      let response = null;
      yield delay(2000);

      //do debug simulation
      response = yield call(doDebugVerify, { step, verificationId });

      //setup success
      if (step === 'setup' && response.code === 0) {
        const { SierraReducer: { simulationReducer } } = yield select();
        startMsg = simulationReducer.startMsg;
        endMsg = simulationReducer.endMsg;
        startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
        yield put(updateStartMsg(startMsg));
        let message = response.msg ? response.msg : 'Setup completed!';
        const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
        if (msgIndex > -1) {
          endMsg[msgIndex].msg = `==> ${message}`;
        } else {
          endMsg.push({ msg: `==> ${message}`, verificationId })
        }
        yield put(updateEndMsg(endMsg));
        yield put(changeVerificationList([]));
        yield put(updateProgress({ verificationId, progress: -1 }));
        return;
      }
      //update start message 'simulating...' ===> 'running...'
      const { SierraReducer: { simulationReducer } } = yield select();
      startMsg = simulationReducer.startMsg;
      endMsg = simulationReducer.endMsg;
      let msg = '==> Simulation running...\n==> Data processing...';
      const msgIndex = startMsg.findIndex(msg => msg.verificationId === verificationId);
      if (msgIndex > -1) {
        startMsg[msgIndex].msg = msg;
      } else {
        startMsg.push({ startMsg: msg, verificationId })
      }
      yield put(updateStartMsg(startMsg));

      //!response
      if (!response || response.code !== 0) {
        let message = response && response.msg ? response.msg : 'Simulation failed!';
        const { SierraReducer: { simulationReducer } } = yield select();
        endMsg = simulationReducer.endMsg;
        startMsg = simulationReducer.startMsg;
        startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
        yield put(updateStartMsg(startMsg));
        const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
        if (msgIndex > -1) {
          endMsg[msgIndex].msg = `==> ${message}`;
        } else {
          endMsg.push({ msg: `==> ${message}`, verificationId })
        }
        yield put(updateEndMsg(endMsg));
        yield put(changeVerificationList([]));
        yield put(updateProgress({ verificationId, progress: -1 }));
        return;
      }

      // id: workflowId
      let { id, status, progress, taskList } = response;
      const getProfile = shouldGetProfile(taskList);
      if (!id) {
        return;
      }
      let waitingIndex = -1;
      waitingIndex = yield call(workflowWaitingIndex, id);
      // while()
      while (waitingIndex >= 0) {
        time = new Date();
        time.setUTCSeconds(time.getUTCSeconds());
        let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
        const { SierraReducer: { simulationReducer: { waitingQueue } } } = yield select();
        let queue = [...waitingQueue];
        const n = queue.findIndex(item => item.verificationId === verificationId);
        if (n > -1) {
          queue[n].queueIndex = waitingIndex;
          queue[n].waitingTime = waitingTime;
        } else {
          queue.push({
            queueIndex: waitingIndex,
            verificationId,
            waitingTime
          })
        }
        yield put(waitingIndexAction(queue));
        yield delay(3000);
        // update waiting index
        waitingIndex = yield call(workflowWaitingIndex, id);
      };

      //clear current waiting index
      const { SierraReducer: { simulationReducer: { waitingQueue } } } = yield select();
      let queue = [...waitingQueue];
      const n = queue.findIndex(item => item.verificationId === verificationId);
      if (n > -1) {
        queue.splice(n, 1);
      }
      yield put(waitingIndexAction(queue));

      if (waitingIndex === -2) {//-2  simulation cancel
        yield put(cleanSingleProgress(verificationId));
        const msg = `==> Simulation cancelled!`;
        const { SierraReducer: { simulationReducer } } = yield select();
        endMsg = simulationReducer.endMsg;
        startMsg = simulationReducer.startMsg;
        startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
        yield put(updateStartMsg(startMsg));
        const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
        if (msgIndex > -1) {
          endMsg[msgIndex].msg = msg;
        } else {
          endMsg.push({ msg, verificationId })
        }
        yield put(updateEndMsg(endMsg));
        return;
      }

      if (waitingIndex === -3) {//-3 simulation complete
        const promise = yield call(checkVerificationStatus, verificationId);
        let resultExist = false;

        if (promise && promise.status) {
          if (promise.status === VERIFY_SUCCESS) {
            resultExist = true;
          }
        };
        yield put(existResult(resultExist));
        yield put(cleanSingleProgress(verificationId));
        let msg = "";
        if (resultExist) {
          msg = `==> Simulation completed!`;
        } else {
          msg = `==> Simulation failed!`;
        }
        const { SierraReducer: { simulationReducer } } = yield select();
        endMsg = simulationReducer.endMsg;
        startMsg = simulationReducer.startMsg;
        startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
        yield put(updateStartMsg(startMsg));
        const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
        if (msgIndex > -1) {
          endMsg[msgIndex].msg = msg;
        } else {
          endMsg.push({ msg, verificationId })
        }
        yield put(updateEndMsg(endMsg));
        return;
      }


      if (progress === 0) {
        progress += 2;
        yield put(updateProgress({ verificationId, progress }));
      }
      let indexNum = 0;
      while (status === taskStatus.RUNNING) {
        if (getProfile && indexNum % 6 === 0) {
          yield put(getCurrentProfile(verificationId));
        }
        yield put(updateMonitor(id, verificationId));
        yield delay(5000);
        const { data: { data } } = yield call(getWorkFlow, id);
        if (!data) {
          status = taskStatus.FAILED;
        } else {
          status = data.status;
          if (data.progress >= 2) {
            progress = data.progress;
          } else {
            progress = 2;
          }
        }
        indexNum++;

        yield put(updateProgress({ verificationId, progress }));
      }
      yield put(getCurrentProfile(verificationId));
      if (status === taskStatus.CANCEL) {
        yield put(cleanSingleProgress(verificationId));
        const msg = `==> Simulation cancelled!`;
        const { SierraReducer: { simulationReducer } } = yield select();
        endMsg = simulationReducer.endMsg;
        const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
        if (msgIndex > -1) {
          endMsg[msgIndex].msg = msg;
        } else {
          endMsg.push({ msg, verificationId })
        }
        yield put(updateEndMsg(endMsg));
      } else {
        yield put(updateProgress({ verificationId, progress }));
      }
      yield delay(1000);
      yield put(updateProgress({ verificationId, progress: -1 }));
      yield put(cleanSingleProgress(verificationId));
      yield put(changeVerificationList([]));
      const { SierraReducer } = yield select();
      startMsg = SierraReducer.simulationReducer.startMsg;
      startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
      yield put(updateStartMsg(startMsg));
      yield delay(3000);
      yield put(updateMonitor(id, verificationId))
    }
  } catch (error) {
    console.error(error);
    yield delay(1000);
    yield put(updateProgress({ verificationId, progress: -1 }));
    yield put(cleanSingleProgress(verificationId));
    yield put(changeVerificationList([]));
    const { SierraReducer } = yield select();
    startMsg = SierraReducer.simulationReducer.startMsg;
    endMsg = SierraReducer.simulationReducer.endMsg;
    startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
    yield put(updateStartMsg(startMsg));

    endMsg = endMsg.filter(msg => msg.verificationId !== verificationId);
    yield put(updateEndMsg(endMsg));
  }
}

function* cancelVerificationWork(action) {
  const { verificationId } = action;
  yield call(cancelVerificationWorkflow, verificationId);
  const msg = `==> Simulation cancelled!`;
  let { SierraReducer: { simulationReducer: { endMsg, startMsg } } } = yield select();
  startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
  yield put(updateStartMsg(startMsg));
  const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
  if (msgIndex > -1) {
    endMsg[msgIndex].msg = msg;
  } else {
    endMsg.push({ msg, verificationId })
  }
  yield put(updateEndMsg(endMsg));

  //after getting monitor,clear end msg `==> Simulation cancelled!`
  yield delay(4000)
  const { SierraReducer: { simulationReducer } } = yield select();
  endMsg = simulationReducer.endMsg;
  endMsg = endMsg.filter(msg => msg.verificationId !== verificationId);
  yield put(updateEndMsg(endMsg));
}

function* verificationWorkFlow(action) {
  const { verificationId, verificationWork } = action;
  if (!verificationWork || verificationWork.length === 0) {
    return;
  }
  //id : workflowId
  let { id, status, progress, currentTaskIndex, taskList } = verificationWork[verificationWork.length - 1];
  const getProfile = shouldGetProfile(taskList);
  yield put(updateRecalc(true))
  let time;
  //clear prev message/monitor/log
  let { SierraReducer: { simulationReducer: { startMsg, endMsg } } } = yield select();
  yield put(updateCurrentLog(null, verificationId));
  yield put(cleanMonitor(verificationId));
  //update start message
  const msgIndex = startMsg.findIndex(msg => msg.verificationId === verificationId);
  if (msgIndex > -1) {
    startMsg[msgIndex].msg = '==> Start simulating...';
  } else {
    startMsg.push({ msg: '==> Start simulating...', verificationId })
  }
  yield put(updateStartMsg(startMsg));
  //clear prev end msg
  endMsg = endMsg.filter(msg => msg.verificationId !== verificationId);
  yield put(updateEndMsg(endMsg));
  yield put(changeUploadMes(null));

  //get current verification info
  let sierraInfo = null, num = 1;
  while (!sierraInfo || (sierraInfo && Object.keys(sierraInfo).length === 0) || (sierraInfo.verificationId !== verificationId)) {
    yield delay(500);
    const { SierraReducer: { sierra } } = yield select();
    sierraInfo = sierra.sierraInfo ? sierra.sierraInfo : null;
    num += 1;
    if (num === 5) {
      break;
    }
  }

  if (currentTaskIndex < 2) {
    let waitingIndex = -1;
    waitingIndex = yield call(workflowWaitingIndex, id);
    // while()
    if (waitingIndex >= 0) {
      //clear prev progress
      yield put(updateProgress({ verificationId, progress: -1 }));
    }

    while (waitingIndex >= 0) {
      const cancelWaiting = yield call(cancelWorkflowJudge, { verificationId });
      if (cancelWaiting) {
        yield put(updateRecalc(false))
        return;
      }
      time = new Date();
      time.setUTCSeconds(time.getUTCSeconds());
      let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
      const { SierraReducer: { simulationReducer: { waitingQueue } } } = yield select();
      let queue = [...waitingQueue];
      const n = queue.findIndex(item => item.verificationId === verificationId);
      if (n > -1) {
        queue[n].queueIndex = waitingIndex;
        queue[n].waitingTime = waitingTime;
      } else {
        queue.push({
          queueIndex: waitingIndex,
          verificationId,
          waitingTime
        })
      }
      yield put(waitingIndexAction(queue));
      yield delay(3000);
      // update waiting index
      waitingIndex = yield call(workflowWaitingIndex, id);
    };

    //clear waiting index
    const { SierraReducer: { simulationReducer: { waitingQueue } } } = yield select();
    let queue = [...waitingQueue];
    const n = queue.findIndex(item => item.verificationId === verificationId);
    if (n > -1) {
      queue.splice(n, 1);
    }
    yield put(waitingIndexAction(queue));

    if (waitingIndex === -2) {//-2  simulation cancel
      yield put(cleanSingleProgress(verificationId));
      const msg = `==> Simulation cancelled!`;
      const { SierraReducer: { simulationReducer } } = yield select();
      endMsg = simulationReducer.endMsg;
      const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
      if (msgIndex > -1) {
        endMsg[msgIndex].msg = msg;
      } else {
        endMsg.push({ msg, verificationId })
      }
      yield put(updateEndMsg(endMsg));
      yield put(updateRecalc(false))
      return;
    }

    if (waitingIndex === -3) {//-3 simulation complete
      yield put(cleanSingleProgress(verificationId));
      // Update verification content
      const { SierraReducer: { sierra: { sierraInfo } } } = yield select();
      const _curVerificationId = (sierraInfo && sierraInfo.verificationId) || null;
      if (_curVerificationId === verificationId) {
        const _response = yield call(getVerificationContentPromise, verificationId);
        // { name, signals, components, mddels, stimuli, powerNets, channel, powerComponents }
        const Interfaces = _response.Interfaces;
        yield put(updateSierraInfo({ Interfaces: Interfaces }));
      };
      yield put(cleanSingleProgress(verificationId));
      //check result exist
      const promise = yield call(checkVerificationStatus, verificationId);
      let resultExist = false;

      if (promise && promise.status) {
        if (promise.status === VERIFY_SUCCESS) {
          resultExist = true;
        }
      };
      yield put(existResult(resultExist));
      //update end message
      let msg = "";
      if (resultExist) {
        msg = `==> Simulation completed!`;
      } else {
        msg = `==> Simulation failed!`;
      }
      const { SierraReducer: { simulationReducer } } = yield select();
      endMsg = simulationReducer.endMsg;
      const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
      if (msgIndex > -1) {
        endMsg[msgIndex].msg = msg;
      } else {
        endMsg.push({ msg, verificationId })
      }
      yield put(updateEndMsg(endMsg));
      yield put(updateRecalc(false))
      return;
    }
  }

  //update start message 'simulating...' ===> 'running...'
  const { SierraReducer: { simulationReducer } } = yield select();
  startMsg = simulationReducer.startMsg;
  const _msgIndex = startMsg.findIndex(msg => msg.verificationId === verificationId);
  if (_msgIndex > -1) {
    startMsg[_msgIndex].msg = '==> Simulation running...\n==> Data processing...';
  } else {
    startMsg.push({ msg: '==> Simulation running...\n==> Data processing...', verificationId })
  }
  yield put(updateStartMsg(startMsg));

  //get pre_process log
  const { SierraReducer: { sierra } } = yield select();
  let _curVerificationId = (sierra.sierraInfo && sierra.sierraInfo.verificationId) || null;
  //update progress
  const { SierraReducer: { simulationReducer: { singleProgress } } } = yield select();
  const currentProgress = singleProgress.find(item => item.verificationId === verificationId);
  if (currentProgress) {
    if (currentProgress.progress >= 0 && currentProgress.progress < 100) {
      progress = currentProgress.progress;
    }
  }
  if (progress === 0) {
    progress += 2;
  }

  yield put(updateProgress({ verificationId, progress }));
  let indexNum = 0, apiErrorNum = 0, prevData = null;
  //running
  while (status === taskStatus.RUNNING) {
    const cancelSimulation = yield call(cancelWorkflowJudge, { verificationId });
    if (cancelSimulation) {
      yield put(updateRecalc(false))
      return;
    }
    if (getProfile && indexNum % 6 === 0) {
      yield put(getCurrentProfile(verificationId));
    }
    //get monitor
    yield put(updateMonitor(id, verificationId))
    yield delay(5000);
    let data = null;
    try {
      const { data: { data: _data } } = yield call(getWorkFlow, id);
      data = _data;
    } catch (error) {
      apiErrorNum += 1;
      if (apiErrorNum >= 3) {
        data = null
      } else {
        data = prevData;
      }
    }
    let _progress = progress;
    if (!data) {
      status = taskStatus.FAILED;
    } else {
      status = data.status;
      _progress = data.progress;
    }
    indexNum += 1;
    prevData = data && typeof (data) === "object" ? JSON.parse(JSON.stringify(data)) : null;

    progress = fakeProgress(progress, indexNum, _progress);
    yield put(updateProgress({ verificationId, progress }));
  }

  yield put(getCurrentProfile(verificationId));
  if (status === taskStatus.CANCEL) {//cancel
    yield put(cleanSingleProgress(verificationId));
    //update end message (cancelled)
    const { SierraReducer } = yield select();
    endMsg = SierraReducer.simulationReducer.endMsg;
    const _msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
    if (_msgIndex > -1) {
      endMsg[_msgIndex].msg = '==> Simulation cancelled!';
    } else {
      endMsg.push({ msg: '==> Simulation cancelled!', verificationId })
    }
    yield put(updateEndMsg(endMsg));
  } else {
    yield put(updateProgress({ verificationId, progress }));
  }

  yield delay(1000);
  yield put(updateProgress({ verificationId, progress: -1 }));
  yield put(cleanSingleProgress(verificationId));
  yield put(changeVerificationList([]));
  //update start message (clear "simulation running..." message)
  const { SierraReducer } = yield select();
  startMsg = SierraReducer.simulationReducer.startMsg;
  startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
  yield put(updateStartMsg(startMsg));
  // Update verification content
  sierraInfo = SierraReducer.sierra.sierraInfo;
  _curVerificationId = (sierraInfo && sierraInfo.verificationId) || null;
  if (_curVerificationId === verificationId) {
    const _response = yield call(getVerificationContentPromise, verificationId);
    // { name, signals, components, powerNets, channel, powerComponents }
    const Interfaces = _response.Interfaces;
    yield put(updateSierraInfo({ Interfaces: Interfaces }));
  };

  //get monitor (end message)
  yield delay(3000);
  yield put(updateMonitor(id, verificationId));
  yield put(updateRecalc(false))
}

//get log
function* _getVerificationLog(action) {
  const { verificationId } = action;
  let _log = '';
  const promise = yield call(checkVerificationStatus, verificationId);
  let existLog = false;

  if (promise && promise.status) {
    if (promise.status !== VERIFY_NEVER) {
      existLog = true;
    }
  };
  if (existLog) {

    let log = yield call(getLogInSierraPinToPinProject, verificationId);
    if (log) {
      _log = updateLogFormat(log);
    }
    yield put(getCurrentProfile(verificationId));
  }
  yield put(updateCurrentLog(_log, verificationId));
}

function* getProfileLog(action) {
  const { verificationId } = action;
  let profileLog = [];
  try {
    profileLog = yield call(getProfileData, verificationId);
  } catch (error) {
    console.error(error)
  }
  yield put(saveCurrentProfile(parseProfile(profileLog)));
}

//pcb replace and verification check
function* checkVerification(action) {
  const { verificationIds, currentSimInfo, updateConn, isMultiSim } = action;
  const { SierraReducer } = yield select();
  const { currentProjectId, verificationId } = SierraReducer.project;
  if (isMultiSim) {
    yield delay(1000)
    yield put(updateMultiSetupSimErrorMsg(
      [{
        title: "",
        logs: ["==> Checking setup..."]
      }]
    ))
  }
  let multiSetupErrors = [];
  yield* verificationIds.map(function* (id) {
    const responseContent = yield call(getVerificationContentPromise, id);
    const SETUP = SierraVerify;
    // { name, signals, powerNets }
    let Interfaces = responseContent.Interfaces;
    let version = Interfaces && Interfaces[0] ? Interfaces[0].version : SIERRA_SETUP_VERSION;
    //versionCompareSize(currentVersion,basicVersion)  if currentVersion < basicVersion return true
    if (versionCompareSize(version, SIERRA_SETUP_UPDATE_VERSION)) {
      Interfaces = updateInterfaceContent({ Interfaces });
    }
    let interfaceUpdateDesigns = [];
    yield* Interfaces.map(function* (info) {
      let currentPcb = projectDesigns.getAvailableDesigns(currentProjectId).find(item => item.id === info.pcbId)
      if (currentPcb && currentPcb.designVersion !== info.designVersion) {
        interfaceUpdateDesigns.push(info.pcbId);
      }
      if (versionCompareSize(version, '3.2.1')) {
        yield call(saveComponentsNets, { pcbIds: [info.pcbId] });
        const vendor = designConstructor.getDesignVendor(info.pcbId);
        if (vendor !== PRE_LAYOUT) {
          const pcbInfo = DesignInfo.getPCBInfo(info.pcbId);
          const { ports_generate_setup_list, referenceNets, port_setups, components: portComponents, errors, warnings } = yield call(updateExtractionPortsSetup, info, pcbInfo);
          yield put(updateGeneratePortsErrors({ id: verificationId, errors, warnings }));
          info.content.ports_generate_setup_list = ports_generate_setup_list;
          info.content.referenceNets = referenceNets;
          info.content.port_setups = port_setups;
          info.content.components = portComponents;
        }
      }
      if (versionCompareSize(version, '3.2.2')) {
        let components = [];
        for (let comp of info.content.components) {
          if (comp.type === 'Connector') {
            components.push(updateConnector(comp, newInterfaces));
          } else {
            components.push(comp);
          }
        }
        info.content.components.forEachcomp = components;
      }
      if (versionCompareSize(version, '3.2.3')) {
        info.content.components = updateSetupComponentsByPortType({
          components: info.content.components,
          designId: info.pcbId,
          ports_generate_setup_list: info.content.ports_generate_setup_list,
          extractionType: info.content.channel ? info.content.channel.type : ""
        });
      }
    });
    if (interfaceUpdateDesigns.length > 0) {
      Interfaces = yield call(designReplace, { Interfaces, verificationID: id })
    }

    let updateInfo = false
    if (isMultiSim) {
      const connError = connComponentsErrorCheck(Interfaces);
      if (connError) {
        Interfaces = updateConnCompToUnused(Interfaces);
        updateInfo = true;
      }
    }
    if (updateConn) {
      Interfaces = updateConnCompToUnused(Interfaces);
      updateInfo = true;
    }

    const _info = removeUnusedCompsPorts(Interfaces);
    updateInfo = _info.updateInfo ? true : updateInfo;
    Interfaces = _info.Interfaces


    //connector components error check
    if (verificationId === id && !isMultiSim) {
      const connError = connComponentsErrorCheck(Interfaces);
      if (connError) {
        yield put(updateConnectorErrors({ id, connError }))
        return;
      }
    }

    //Error check
    //library file exist check
    let newInterfaces = yield call(updateLibraryInInterface, Interfaces);
    let error = {}, errorExist = null;
    if (newInterfaces && newInterfaces.Interfaces) {
      error = newInterfaces.error;
      errorExist = newInterfaces.errorExist;
      Interfaces = newInterfaces.Interfaces;
    }

    const { SierraReducer: { sierra: { interfaceLibraryError } } } = yield select();
    let _interfaceLibraryError = interfaceLibraryError ? [...interfaceLibraryError] : [];
    let errorIndex = _interfaceLibraryError.findIndex(item => item.verificationId === id);
    if (errorIndex > -1) {
      if (!errorExist) {
        _interfaceLibraryError.splice(errorIndex, 1)
      } else {
        _interfaceLibraryError[errorIndex].error = { ...error };
      }
    } else {
      if (errorExist) {
        _interfaceLibraryError.push({
          verificationId: id,
          error: { ...error }
        })
      }
    }
    if (errorExist && isMultiSim) {
      multiSetupErrors.push({
        title: responseContent.Name,
        verificationId: id,
        errors: getLibraryErrorList([{ error, verificationId: id }], id)
      });
      /*   yield put(updateMultiSetupSimErrorMsg(multiSetupErrors)) */
    }

    yield put(updateInterfaceLibraryMsg(_interfaceLibraryError));

    let Components = Interfaces.map(item => item.content && item.content.components ? item.content.components : []).flat(2);
    const touchstoneFileStatusMsgObj = yield call(_UpdateTouchstoneStatus, { Components, verificationId: id });
    const msgList = touchstoneFileStatusMsgObj ? touchstoneFileStatusMsgObj.msgList : null;
    if (msgList && msgList.length > 0) {
      _interfaceLibraryError.push({
        verificationId: id,
        error: { ...msgList },
        errorExist: true
      })
    }


    if (errorExist || updateInfo) {
      //interface error check (update readForSim)
      yield* Interfaces.map(function* (info) {
        const errorCheck = getSierraInterfaceErrorCheck(info);
        //1:ready, 0: not ready
        let readyForSim = 1;
        if (errorCheck) {
          readyForSim = 0;
        }
        if (!info.content.extraction) {
          info.content.extraction = new Extraction();
        }
        ResultData.cleanAll();
        let settingVersion = info.settingVersion;
        if (!settingVersion) {
          settingVersion = yield call(componentSetting.getVersion, info.pcbId);
        }
        yield call(updateInterfacePromise, {
          designId: info.pcbId,
          interfaceId: info.interfaceId,
          interfaceName: info.name,
          projectId: currentProjectId,
          verificationId: id,
          verificationName: info.name,
          content: info.content,
          readyForSim: readyForSim,
          version: info.version,
          designVersion: info.designVersion,
          settingVersion
        });
      })
      const { SierraReducer: { project } } = yield select();
      //if current open setup. update new setup
      if (project.verificationId === id) {
        const SETUP = SierraVerify;
        let info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name);
        const connectorList = getConnectorList(Interfaces);
        yield put(updateSierraInfo({ Interfaces, connectorList, info }));
        yield put(changeConnectorStatus(true))
      }
    }

    let info = SETUP.mergeInterfacesInfo(Interfaces, responseContent.Name);
    const connectorList = getConnectorList(Interfaces);
    const PCBsInInterface = Interfaces.map(item => item.pcbId);
    const sierraInfo = {
      Interfaces,
      PCBsInInterface,
      info,
      connectorList,
      verificationId: id,
    };


    //check stackup data (if pcb is prelayout, check signal group);
    let stackupError = [];
    if (Interfaces && Interfaces.length) {
      yield* Interfaces.map(function* (interfaceInfo) {
        let error = null;
        const vendor = designConstructor.getDesignVendor(interfaceInfo.pcbId);
        if (vendor === PRE_LAYOUT) {
          yield call([preLayoutData, preLayoutData.getPreLayout], interfaceInfo.pcbId);
        }
        if (vendor !== PRE_LAYOUT) {
          const stackup = yield call(getDesignStackupJson, interfaceInfo.pcbId);
          if (!stackup || !stackup.layers) {
            error = [{ error: `Stackup file does not exist!` }];
          } else {
            const extractionSolver = interfaceInfo.content && interfaceInfo.content.channel ? interfaceInfo.content.channel.type : null;
            error = stackupErrorCheck({
              ...stackup,
              layers: stackup.layers,
              materialList: stackup.materials,
              unit: stackup.unit,
              extractionSolver,
              pageType: SIERRA
            });
          }
          if (error && error.length) {
            stackupError.push({
              designName: interfaceInfo.pcb,
              stackupError: error
            })
          }
        }
      })
    }

    const { SierraReducer: { simulationReducer, library: { defaultBufferSpice } } } = yield select();
    const errorCheck = getSierraErrorCheck(sierraInfo, defaultBufferSpice);
    let { sierraInfoErrorCheck } = simulationReducer;
    if (errorCheck || (stackupError && stackupError.length)) {
      const { SierraReducer: { project: { currentProjectId } } } = yield select();
      currentSimInfo.forEach(item => {
        if (item.verificationId === id) {
          item.status = 'error'
        }
      })
      yield put(autoGetVerificationList(currentProjectId, currentSimInfo));
      //update interface readForSim
      yield* Interfaces.map(function* (item) {
        const interfaceError = getSierraInterfaceErrorCheck(item);
        if (interfaceError) {
          yield call(updateReadyForSim, item.interfaceId, 0);
        }
      });
      const index = sierraInfoErrorCheck.findIndex(item => item.verificationId === id);
      if (index > -1) {
        sierraInfoErrorCheck[index].errorCheck = { ...errorCheck };
        if (stackupError && stackupError.length) {
          sierraInfoErrorCheck[index].stackupError = [...stackupError]
        }
      } else {
        sierraInfoErrorCheck.push({
          verificationId: id,
          errorCheck: errorCheck,
          stackupError
        })
      }
      yield put(updateErrorCheckList(sierraInfoErrorCheck));
      if (isMultiSim) {
        const index = multiSetupErrors.findIndex(it => it.verificationId === id);
        if (index > -1) {
          multiSetupErrors[index] = {
            title: responseContent.Name,
            verificationId: id,
            stackupErrors: stackupError,
            errors: [...multiSetupErrors[index].errors, ...errorCheck.error]
          };
        } else {
          multiSetupErrors.push({
            title: responseContent.Name,
            verificationId: id,
            stackupErrors: stackupError,
            errors: errorCheck.error
          });
        }

        /*  yield put(updateMultiSetupSimErrorMsg(multiSetupErrors)) */
      }
    } else {
      const index = sierraInfoErrorCheck.findIndex(item => item.verificationId === id);
      if (index > -1) {
        sierraInfoErrorCheck.splice(index, 1);
      }
      yield put(updateErrorCheckList(sierraInfoErrorCheck));
      currentSimInfo.forEach(item => {
        if (item.verificationId === id) {
          item.status = 'success'
        }
      })
      yield put(autoGetVerificationList(currentProjectId, currentSimInfo));

      //clean library check message
      const { SierraReducer: { sierra: { interfaceLibraryError } } } = yield select();
      if (interfaceLibraryError) {
        let _interfaceLibraryError = [...interfaceLibraryError];
        _interfaceLibraryError = _interfaceLibraryError.filter(item => item.verificationId !== id);
        yield put(updateInterfaceLibraryMsg(_interfaceLibraryError));
      }
      const _index = multiSetupErrors.findIndex(it => it.verificationId === id);
      if (_index > -1) {
        multiSetupErrors[_index] = {
          title: responseContent.Name,
          verificationId: id,
          logs: ["==> Start simulating..."]
        };
      } else {
        multiSetupErrors.push({
          title: responseContent.Name,
          verificationId: id,
          logs: ["==> Start simulating..."]
        });
      }

      /*   yield put(updateMultiSetupSimErrorMsg(multiSetupErrors)) */
    }
  });
  if (isMultiSim) {
    yield put(updateMultiSetupSimErrorMsg(multiSetupErrors))
  }
}

function* _getInterfaceMonitor(action) {
  const { verificationId } = action;
  const verificationWork = yield call(getInterfaceWorkStatus, verificationId);
  if (verificationWork) {
    if (workflowTask) {
      yield cancel(workflowTask);
    };
    // workflow
    workflowTask = yield fork(verificationWorkFlow, { verificationId, verificationWork });
  } else {
    const { SierraReducer: { simulationReducer } } = yield select();
    let { singleProgress, singleMonitor, startMsg, endMsg } = simulationReducer;
    let index = singleProgress.findIndex(item => item.verificationId === verificationId);
    if (index > -1) {
      yield put(cleanSingleProgress(verificationId));
    }
    const { SierraReducer: { simulationReducer: { waitingQueue } } } = yield select();
    let queue = [...waitingQueue];
    const n = queue.findIndex(item => item.verificationId === verificationId);
    if (n > -1) {
      queue.splice(n, 1);
    }
    yield put(waitingIndexAction(queue));
    //clear current verification start and end message
    startMsg = startMsg.filter(msg => msg.verificationId !== verificationId);
    yield put(updateStartMsg(startMsg));
    endMsg = endMsg.filter(msg => msg.verificationId !== verificationId);
    yield put(updateEndMsg(endMsg));

    const findMonitor = singleMonitor[verificationId];
    //get monitor
    const log = findMonitor && findMonitor.workflowId ? yield call(getMonitor, findMonitor.workflowId) : null;
    if (log && log.length > 0) {
      yield put(updateSingleMonitor({ workflowId: findMonitor.workflowId, monitor: monitorLog(log, null, true), verificationId }));
    } else {
      yield put(cleanMonitor(verificationId));
      //get log
      yield put(getCurrentLog(verificationId));
    }
    yield put(getCurrentProfile(verificationId));
  }
}

function* _updateCurrentSimulationMsg(action) {
  const { verificationId } = action;
  //clear monitor
  yield put(cleanMonitor(verificationId));
  //clear log
  yield put(updateCurrentLog(null, verificationId));
  //clear upload message
  yield put(changeUploadMes(null));
  //update start message
  const { SierraReducer: { simulationReducer, sierra: { TouchstoneStatusList, sierraInfoErrorCheck }, project: { currentProjectId } } } = yield select();
  let currentSimInfo = [];
  currentSimInfo.push({
    verificationId,
    status: 'checking'
  });
  let startMsg = simulationReducer.startMsg;
  let endMsg = simulationReducer.endMsg;
  let msg = '==> Checking setup...';
  if (!startMsg || !startMsg.length) {
    startMsg = [];
  }
  const msgIndex = startMsg.findIndex(msg => msg.verificationId === verificationId);
  if (msgIndex > -1) {
    startMsg[msgIndex].msg = msg;
  } else {
    startMsg.push({ msg, verificationId: verificationId })
  }

  yield put(updateStartMsg(startMsg));
  //delete end message
  endMsg = endMsg ? endMsg.filter(msg => msg.verificationId !== verificationId) : [];
  yield put(updateEndMsg(endMsg));

  //clean setup error check message
  if (sierraInfoErrorCheck && sierraInfoErrorCheck.length > 0) {
    const new_sierraInfoErrorCheck = sierraInfoErrorCheck.filter(item => item.verificationId !== verificationId);
    yield put(updateErrorCheckList(new_sierraInfoErrorCheck));
  }

  //clear prev verification touchstone macro modeling status message
  if (TouchstoneStatusList && TouchstoneStatusList.length > 0) {
    let touchstoneStatusList = TouchstoneStatusList.filter(it => it.verificationId !== verificationId);
    yield put(updateTouchstoneStatusList(touchstoneStatusList));
  }

  //clean library file exist check message
  const { SierraReducer: { sierra: { interfaceLibraryError } } } = yield select();
  if (interfaceLibraryError && interfaceLibraryError.length) {
    let _interfaceLibraryError = [...interfaceLibraryError];
    _interfaceLibraryError = _interfaceLibraryError.filter(i => i.verificationId !== verificationId);
    yield put(updateInterfaceLibraryMsg(_interfaceLibraryError));
  }
  yield put(autoGetVerificationList(currentProjectId, currentSimInfo));
}

function* cancelWorkflowJudge({ verificationId, sweep = false }) {
  const { LoginReducer: { pageType }, SierraReducer: { project: { selectedKeys } }, TabMonitorReducer: { currentVerificationId } } = yield select();

  if (pageType !== SIERRA || (!sweep && workflowJudge({
    selectedKeys,
    verificationId,
    verificationKey: `${VERIFICATION}-${verificationId}`,
    resultKey: `${RESULT}-${verificationId}`,
    monitorVerificationId: currentVerificationId
  }))) {
    return true;
  }
}

let experimentTask = null
function* updateRunningExperiment(action) {
  const { forSim, clear, run } = action;
  const { SierraReducer: { simulationReducer: { experimentQueue, sierraInfoErrorCheck }, project: { currentProjectId }, sweep: { experimentInfo: { experiments, base } } } } = yield select();
  const sweepList = [base, ...experiments].filter(item => !!item);
  let tasks = clear ? [...forSim] : forSim.filter(task => !experimentQueue.includes(task));
  let newTasks = [], errorList = [...sierraInfoErrorCheck], statusError = [];
  for (let task of tasks) {
    const error = yield call(checkExperimentInfo, task);
    if (error) {
      statusError.push(task)
      yield put(cleanMonitor(task))
      const index = errorList.findIndex(item => item.verificationId === task);
      if (index > -1) {
        errorList[index].errorCheck = [...error];
      } else {
        errorList.push({
          verificationId: task,
          errorCheck: error
        })
      }

    } else {
      newTasks.push(task);
      errorList = errorList.filter(item => item.verificationId !== task);
    }
  }
  yield put(updateErrorCheckList(errorList));
  if (statusError.length) {
    yield put(updateExperimentStatus({ experimentIds: statusError, status: ERROR }));
    if (!newTasks.length) {
      let verification = sweepList.find(item => item.id === statusError[0]);
      yield put(changeTabMenu({
        selectKeys: ['monitor'],
        menuType: 'simulation',
        verificationId: statusError[0],
        projectId: currentProjectId,
        verificationName: verification ? verification.name : 'Base'
      }));
    }
  }
  if (newTasks.length) {
    try {
      let verification = sweepList.find(item => item.id === newTasks[0]);
      yield put(changeTabMenu({
        selectKeys: ['monitor'],
        menuType: 'simulation',
        verificationId: newTasks[0],
        projectId: currentProjectId,
        verificationName: verification ? verification.name : 'Base'
      }));
      if (run) {
        yield call(runSweeping, newTasks);
      }
      yield call(clearMonitorBeforeRun, { verificationIds: newTasks });
      SweepResultData.cleanResultByIds(newTasks);
      newTasks = [...experimentQueue, ...newTasks];
    } catch (error) {
      const msg = `==> [ERROR] ${error}.\n==> Simulation failed!`;
      const { SierraReducer: { simulationReducer } } = yield select();
      let endMsg = simulationReducer.endMsg;
      for (let verificationId of newTasks) {
        const msgIndex = endMsg.findIndex(msg => msg.verificationId === verificationId);
        if (msgIndex > -1) {
          endMsg[msgIndex].msg = msg;
        } else {
          endMsg.push({ msg, verificationId })
        }
      }
      yield put(updateEndMsg(endMsg));
      return;
    }
  }
  if (experimentTask) {
    yield cancel(experimentTask);
  }
  if (newTasks) {
    yield put(saveExperimentRunning(newTasks));
  }
  experimentTask = yield fork(runningExperimentLog, { experimentQueue: newTasks })
}

const finishStatus = [VERIFY_SUCCESS, VERIFY_FAILED, VERIFY_CANCEL];
const simulateStatus = [VERIFY_RUNNING, WAITING];
function* runningExperimentLog(action) {
  const { experimentQueue } = action;
  let queue = [...experimentQueue];
  while (queue.length) {
    try {
      let finish = []
      for (let experimentId of queue) {
        const cancelSimulation = yield call(cancelWorkflowJudge, { verificationId: experimentId, sweep: true });
        if (cancelSimulation) {
          const { SierraReducer: { resultReducer: { sweepReCalc } } } = yield select();
          const _sweepReCalc = (sweepReCalc || []).filter(item => experimentId !== item);
          yield put(updateSweepReCalc(_sweepReCalc))
          continue;
        }
        let res = {}
        try {
          res = yield call(getRunningExperimentLog, experimentId);
        } catch (error) {
          console.error(error)
          const { SierraReducer: { resultReducer: { sweepReCalc } } } = yield select();
          const _sweepReCalc = (sweepReCalc || []).filter(item => experimentId !== item);
          yield put(updateSweepReCalc(_sweepReCalc))
          continue;
        }
        const { logs = [], status = WAITING, progress } = res;
        const { SierraReducer: { sweep: { experimentInfo: { experiments, base = {} } } } } = yield select();
        if (experimentId === base.id) {
          let newBase = JSON.parse(JSON.stringify(base));
          newBase.status = status;
          yield put(updateExperimentInfo(newBase, 'base'));
        } else {
          let newExperiments = JSON.parse(JSON.stringify(experiments));
          const exp = newExperiments.find(item => item.id === experimentId);
          if (exp) {
            exp.status = status;
            yield put(updateExperimentInfo(newExperiments, 'experiments'));
            yield delay(500)
          }
        }
        if (finishStatus.includes(status)) {
          finish.push(experimentId)
        }
        /*   if (currentVerificationId === experimentId) { */
        const { SierraReducer: { simulationReducer: { singleProgress } } } = yield select();
        let currentProgress = singleProgress.find(item => item.verificationId === experimentId);
        let lastProgress = currentProgress ? currentProgress.progress : 0;
        let _progress = progress;
        if (finishStatus.includes(status)) {
          _progress = -1;
        } else if (_progress <= lastProgress && status !== WAITING) {
          _progress = lastProgress > 40 ? lastProgress : Number((lastProgress + Math.random() * 5).toFixed(1));
        }
        yield put(updateSingleMonitor({ workflowId: experimentId, monitor: monitorLog(logs, null, true), verificationId: experimentId }));
        yield put(updateProgress({ verificationId: experimentId, progress: _progress }));
        /*   } */
      }
      if (finish.length) {
        queue = queue.filter(item => !finish.includes(item));
        yield put(saveExperimentRunning(queue));
        const { SierraReducer: { resultReducer: { sweepReCalc } } } = yield select();
        const _sweepReCalc = (sweepReCalc || []).filter(item => !finish.includes(item));
        yield put(updateSweepReCalc(_sweepReCalc))
      }
    } catch (error) {
      console.error(error)
    }
    yield delay(3000)
  }
}

function* getExperimentLog(action) {
  const { experimentId } = action;
  const { SierraReducer: { simulationReducer: { experimentQueue } } } = yield select();
  yield put(updateCurrentLog(null, experimentId))
  if (!experimentQueue.includes(experimentId)) {
    let res = null;
    try {
      res = yield call(getRunningExperimentLog, experimentId);
    } catch (error) {
      console.error(error);
    }
    if (!res || finishStatus.includes(res.status)) {
      try {
        let log = yield call(getCurrentExperimentLog, experimentId);
        log = updateLogFormat(log || "");
        const { SierraReducer: { simulationReducer } } = yield select();
        let startMsg = simulationReducer.startMsg;
        let endMsg = simulationReducer.endMsg;
        yield put(updateStartMsg(startMsg.filter(item => item.verificationId !== experimentId)));
        //delete end message
        yield put(updateEndMsg(endMsg.filter(item => item.verificationId !== experimentId)));
        yield put(updateProgress({ verificationId: experimentId, progress: -1 }));
        yield put(cleanMonitor(experimentId))
        yield put(updateCurrentLog(log, experimentId));
        const { SierraReducer: { resultReducer: { sweepReCalc } } } = yield select();
        const _sweepReCalc = (sweepReCalc || []).filter(item => experimentId !== item);
        yield put(updateSweepReCalc(_sweepReCalc))
      } catch (error) {
        console.error(error);
      }
    } else if (res && simulateStatus.includes(res.status)) {
      const newTasks = [...experimentQueue, experimentId];
      yield put(saveExperimentRunning(newTasks));
      yield delay(300);
      if (experimentTask) {
        yield cancel(experimentTask);
      }
      experimentTask = yield fork(runningExperimentLog, { experimentQueue: newTasks })
    }
  }
}

function* cancelExperimentFlow(action) {
  const { experimentId } = action;
  yield call(cancelCurrentExperiment, experimentId);
  const msg = `==> Simulation cancelled!`;
  let { SierraReducer: { simulationReducer: { endMsg, startMsg, experimentQueue }, sweep: { experimentInfo: { experiments, base } } } } = yield select();
  startMsg = startMsg.filter(msg => msg.verificationId !== experimentId);
  yield put(updateStartMsg(startMsg));
  const msgIndex = endMsg.findIndex(msg => msg.verificationId === experimentId);
  if (msgIndex > -1) {
    endMsg[msgIndex].msg = msg;
  } else {
    endMsg.push({ msg, verificationId: experimentId })
  }
  yield put(updateEndMsg(endMsg));
  const queue = experimentQueue.filter(item => item !== experimentId);
  yield put(saveExperimentRunning(queue));
  if (base && experimentId === base.id) {
    let newBase = JSON.parse(JSON.stringify(base));
    newBase.status = VERIFY_CANCEL;
    yield put(updateExperimentInfo(newBase, 'base'));
  } else {
    let newExperiments = JSON.parse(JSON.stringify(experiments));
    const exp = newExperiments.find(item => item.id === experimentId);
    if (exp) {
      exp.status = VERIFY_CANCEL;
      yield put(updateExperimentInfo(newExperiments, 'experiments'));
    }
  }
  //after getting monitor,clear end msg `==> Simulation cancelled!`
  yield delay(4000)
  const { SierraReducer: { simulationReducer } } = yield select();
  endMsg = simulationReducer.endMsg;
  endMsg = endMsg.filter(msg => msg.verificationId !== experimentId);
  yield put(updateEndMsg(endMsg));
}

function* checkExperimentInfo(experimentId) {
  let { SierraReducer: { sweep: { experimentInfo: { experiments, base } } } } = yield select();
  if (!experimentId) {
    return null;
  }
  const exp = experiments.find(experiment => experiment.id === experimentId);
  let error = null;
  if (exp) {
    error = getSweepErrorCheck(exp, base);
  }
  return error;
}

function* runMultiExperiment(action) {
  const { experimentIds } = action;
  const { SierraReducer: { simulationReducer: { experimentQueue } } } = yield select();
  const newIds = [...new Set([...experimentQueue, ...experimentIds])];
  yield put(saveExperimentRunning(newIds));
  yield delay(300);
  if (experimentTask) {
    yield cancel(experimentTask);
  }
  experimentTask = yield fork(runningExperimentLog, { experimentQueue: newIds })
}

function* sierraSimulationSaga() {
  yield takeEvery(START_SIERRA_VERIFICATION, startVerification);
  yield takeLatest(VERIFICATION_FLOW, verificationWorkFlow);
  yield takeEvery(GET_CURRENT_LOG, _getVerificationLog);
  yield takeEvery(DEBUG_VERIFY, startDebugVerify);
  yield takeEvery(CANCEL_VERIFICATION_WORKFLOW, cancelVerificationWork);
  yield takeLatest(GET_INTERFACE_MONITOR, _getInterfaceMonitor);
  yield takeLatest(GET_VERIFICATION_MONITOR, getVerificationMonitor);
  yield takeEvery(UPDATE_SIMULATION_MESSAGE, _updateCurrentSimulationMsg);
  yield takeLatest(GET_CURRENT_PROFILE, getProfileLog);
  yield takeEvery(UPDATE_EXPERIMENT_QUEUE, updateRunningExperiment);
  yield takeEvery(GET_EXPERIMENT_LOG, getExperimentLog);
  yield takeEvery(CANCEL_EXPERIMENT, cancelExperimentFlow);
  yield takeEvery(RUN_MULTI_EXPERIMENT_SIM, runMultiExperiment);
};

export default sierraSimulationSaga;