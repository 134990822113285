import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Radio, Select } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import PinMapTable from './pinMapTable';
import auroraDBJson from '../../../services/Designs/auroraDbData';

const Option = Select.Option;
class PinMapFileSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 500,
      maxHeight: 500,
      nets: [],
      compName: ''
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getNets()
  }
  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 500),
      maxHeight: getPanelMaxHeight(offset, 500),
    })
  }

  closeModal = () => {
    this.props.closePinMapSelect(this.props.record);
    this.props.save()
  }

  getNets = () => {
    const { record, designId } = this.props;
    const { components = [], partNumber } = record;
    let compName = components.length ? components[0] : null;
    if (!compName) {
      const _components = auroraDBJson.getComponentsByPartName(designId, partNumber);
      if (_components.length) {
        compName = _components[0].name
      }
    }
    if (!compName) {
      return;
    }
    const netsInfo = auroraDBJson.getNetsByComponent(designId, compName);
    const nets = netsInfo.map(item => {
      const name = item.name;
      const pins = item.pins.get(compName);
      return { name, pins }
    });
    this.setState({
      nets,
      compName
    })
  }

  render() {
    const { maxWidth, maxHeight, nets, compName } = this.state;
    const { record, pinMap, designId, NET, TABLE, text, selectedComps } = this.props;
    const { partNumber, customPinMap = false, libraryName, pinTable = [] } = record;
    const _pinMap = pinMap && pinMap[partNumber] ? pinMap[partNumber] : {}
    const options = _pinMap.pinMap ? Object.values(_pinMap.pinMap) : [];
    const _customPinMap = customPinMap === true ? TABLE : customPinMap;
    const outputs = pinTable.map(pin => pin.output ? pin.output.map(p => p.pin) : []).flat()
    const netList = _customPinMap === NET && outputs.length ? nets.filter(net => outputs.find(pin => net.pins.includes(pin))).map(n => n.name) : []
    const content = (
      <Panel
        className='cascade-power-select-panel'
        title={<div className='cascade-power-select-title'>{`Select Pin Map`}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 900 })}
        maxHeight={maxHeight}
        position='panel-center'
        draggable
        minHeight={100}
        minWidth={400}
        defaultTop={200}
      >
        <div className='cascade-setup-border cascade-pin-map-select-content'>
          <Radio.Group name='pinMapSelect' value={_customPinMap} onChange={(e) => this.props.changePinMapSelect(e, record)}>
            <div className='cascade-pin-map-file-select-radio-div'>
              <Radio value={false} className='cascade-pin-map-file-select-radio'>
                <span>Use Library Pin Map</span>
              </Radio>
              <span className={`cascade-pin-map-select-library-span ${_customPinMap ? "cascade-pin-map-select-library-span-disabled" : ''}`} onClick={() => this.props.openPartSelected(record)}>
                {libraryName}
              </span>
              {/* <Select
                className='cascade-pin-map-select-library-select'
                popupClassName='cascade-pin-map-select-library-select-dropdown'
                disabled={!canUseLibrary || _customPinMap}
                value={libraryId}
                onChange={(value) => this.props.changePinMap(value, record)}
                size="small"
              >
                {
                  options.map(option => <Option key={option.libraryId}>{option.name}</Option>)
                }
              </Select> */}
            </div>
            <div className='cascade-pin-map-file-select-radio-div'>
              <Radio value={NET} className='cascade-pin-map-file-select-radio'>
                <span>Use Output Net</span>
              </Radio>
              <Select
                className='cascade-pin-map-select-library-select'
                popupClassName='cascade-pin-map-select-library-select-dropdown'
                mode='multiple'
                value={netList}
                disabled={_customPinMap !== NET}
                onChange={(value) => this.props.savePinTableByNets(value, compName, record)}
                size="small"
              >
                {
                  nets.map(option => <Option key={option.name}>{option.name}</Option>)
                }
              </Select>
            </div>
            <Radio value={TABLE} className='cascade-pin-map-file-select-radio'>
              <span>Use Custom Pin Map</span>
            </Radio>
            {_customPinMap === TABLE && <div className='cascade-pin-map-file-select-table'>
              <PinMapTable
                pinTable={pinTable}
                libraryInfo={{ partNumber, type: _pinMap.type || "" }}
                designId={designId}
                disabled={false}
                noSense={true}
                savePinTable={(pinTable) => this.props.savePinTable(pinTable, record)}
                selectedComps={selectedComps}
              />
            </div>}
          </Radio.Group>
        </div>
      </Panel >
    )
    return <Fragment>
      <div className='editable-cell-value-wrap'>
        {text}
      </div>
      {createPortal(content, this.dialogRoot)}
    </Fragment>
  }
};


export default PinMapFileSelect;
