import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  ExclamationOutlined,
  PlusSquareOutlined,
  RetweetOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Spin, Button, Row, Col, Popover, Descriptions, Radio } from 'antd';
import {
  filterPowerNetsByIC,
  getSignOffTemplateColumns,
  getSignOffTemplateData,
  getTemplatePorts
} from '../../../services/Cascade/SignOffTemplate';
import EditableTable from '@/components/EditableTable';
import portSetup from '../components/portSetup';
import {
  updateTemplatePorts,
  downloadTemplateS1PFile,
  saveImpedanceValue,
  updateTemplateSetup,
  saveTemplateValue,
  createNewPower,
  deleteTemplatePwrDomain,
  addTemplatePowerDomain,
  saveImpFreqList,
  deleteImpedanceFreq,
  updateTemplateSetupValue,
  updateTemplateContentLoading
} from '../store/SignOffTemplate/action';
import { getTextWidth } from '@/services/helper/getTextWidth';
import { numberCheck } from '@/services/helper/dataProcess';
import CreatePowerDomain from './CreatePowerDomain';
import TagsInput from '../../../components/TagsInput';
import { TARGET_RL_VALUE, TARGET_TABLE, /* TARGET_TOUCHSTONE */ } from '../../../services/Cascade/constants';
import RLSpecIntervalPanel from './RLSpecIntervalPanel';
import _ from 'lodash';
import './index.css';


const DescItem = Descriptions.Item;
class SignOffTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      visibleObj: {},
      impedanceFreqList: [],
      couplingImpedanceFreqList: [],
      shortSocCouplingFreqList: [],
      tableExtendKeys: [],
      impedanceRLValueChecked: false,
      couplingImpedanceRLValueChecked: false,
      shortSocCouplingRLValueChecked: false,
      impedanceTargetType: TARGET_TABLE,
      couplingImpedanceTargetType: TARGET_TABLE,
      shortSocCouplingTargetType: TARGET_TABLE,
      isAddPMIC: false,
      impedanceValue: 'actualValue',
      rlSpecIntervalPanel: false
    }
  }

  componentDidMount = () => {
    const { signOffTemplate } = this.props;
    const columns = getSignOffTemplateColumns(signOffTemplate);
    this.setState({
      columns
    }, () => {
      this.columnsCell()
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { signOffTemplate, templateSetupUpdate, templateValueUpdate } = this.props;
    if (
      (!prevProps.signOffTemplate && signOffTemplate)
      || (prevProps.signOffTemplate && signOffTemplate && prevProps.signOffTemplate.length !== signOffTemplate.length)
      || (prevProps.signOffTemplate && signOffTemplate && (JSON.stringify(prevProps.signOffTemplate) !== JSON.stringify(signOffTemplate)))
      || (templateSetupUpdate && templateSetupUpdate !== prevProps.templateSetupUpdate)) {
      const columns = getSignOffTemplateColumns(signOffTemplate);
      this.setState({
        columns
      }, () => {
        this.columnsCell()
        this.props._updateTemplateSetup(false);
      })
    }

    if (templateValueUpdate !== prevProps.templateValueUpdate && templateValueUpdate) {
      this.props._updateTemplateSetupValue(false);
    }
  }

  columnsCell = () => {
    const { columns } = this.state;
    let _columns = [...columns];
    _columns.forEach((column, index) => {

      if (["gndNetName", "voltage", "peakCurrent"].includes(column.dataIndex)) {
        column.render = (name, record) => {
          return {
            children: <span>{column.dataIndex === "gndNetName" ? name : name ? name.value : null}</span>,
            props: {
              rowSpan: record.templateLength || 0,
              tdClassName: record.templateLength ? "cascade-sign-off-pmic-comp-div-td" : ""
            }
          }
        };

        if (["voltage", "peakCurrent"].includes(column.dataIndex)) {

          column.onCell = (record) => {
            const value = (record[column.dataIndex] ? record[column.dataIndex].value : "");
            return {
              edit: true,
              dataIndex: column.dataIndex,
              record: { ...record, [column.dataIndex]: value },
              handleSave: (_record) => this.saveTemplateValue(_record, record, column.dataIndex)
            }
          }
        }
      }

      if (column.dataIndex === "powerNetName") {
        column.render = (name, record) => {
          return {
            props: {
              tdClassName: "template-power-net-td"
            },
            children: <div>
              {/* {record.templateLength ? <Icon
              type="eye"
              className="cascade-template-eye-icon"
              onClick={(e) => this.openTask(record)} /> : null} */}
              <span>{name}</span>
              <CloseOutlined
                className='template-power-delete'
                onClick={(e) => this.deletePD(e, record)} />
            </div>
          };
        };

        column.onCell = (record) => {
          const { nets, designId, soc, signOffTemplate } = this.props
          let filterNets = this.filterPowerNets(nets, signOffTemplate)
          if (soc) {
            filterNets = filterPowerNetsByIC(designId, soc, filterNets);
          }
          return record[column.dataIndex] ? {
            edit: false
          } : {
            edit: 'select',
            options: filterNets,
            record,
            dataIndex: 'powerNetName',
            handleSave: (_record) => this.saveTemplateValue(_record, record, column.dataIndex)
          }
        }
      }

      if (column.dataIndex === "compType") {
        column.render = (name, record) => {
          const _name = name || "";
          return <span className={`cascade-sign-off-${_name.toLowerCase()}-span`}
            title={name}
          >{name}</span>
        };
      }

      if (column.dataIndex === "portName") {
        column.onCell = (record) => {
          if (record.compType === "PMIC") {
            return { edit: false }
          }
          return {
            edit: true,
            dataIndex: column.dataIndex,
            record: record,
            handleSave: (_record) => this.saveTemplateValue(_record, record, column.dataIndex)
          }
        }

        column.render = (text, record) => {
          if (record.compType === "PMIC" && !text) {
            return <div className="aurora-table-row-disabled"></div>
          } else {
            return text;
          }
        }
      }

      if (["powerPins", "groundPins"].includes(column.dataIndex)) {
        column.title = () => {
          const { tableExtendKeys } = this.state;

          return (
            <span
              className="cascade-sign-off-table-title-span"
              onClick={() => this.updateExpandKeys(column.dataIndex)}
            >
              <DownOutlined
                className={tableExtendKeys.includes(column.dataIndex) ? "pre-layout-fold-icon pre-layout-fold-icon-transform" : "pre-layout-fold-icon"} />
              {column.titleText}
            </span>
          );
        }

        column.render = (name, record) => {
          let text = name ? name.join(", ") : "";
          const content = document.getElementById("cascade-content-main");
          let maxWidth = content.offsetWidth ? content.offsetWidth / 4 : 280;
          maxWidth = maxWidth < 100 ? 100 : maxWidth;
          const textWidth = getTextWidth(text, 14, maxWidth);
          const { tableExtendKeys } = this.state;
          let width = textWidth, _text = text;
          if (tableExtendKeys.includes(column.dataIndex) && width > 100) {
            width = 100;
            _text = name && name.length ? (name.length > 2 ? `${name[0]}, ${name[1]}...` : name.join(", ")) : "";
          }
          return {
            props: {
              tdWidth: width,
              tdClick: (e) => this.openTableCell(e, record, "port"),
              tdClassName: column.dataIndex === "groundPins" ? "cascade-sign-off-table-ground-pin-div" : ""
            },
            children: <span
              className="cascade-sign-off-table-span"
              style={{ width, maxWidth: width }}
              title={text}
              onClick={() => this.closePortsPanel()}
            >{_text}</span>
          }
        };
        //support modified ports
        column.onCell = (record) => {
          const { designId, verificationId, signOffTemplate, soc, gnd } = this.props;
          const { visibleObj } = this.state;

          if ((!record.refdes && !soc) || (!record.gndNetName && !gnd)) {
            return { edit: false }
          }
          if (!visibleObj.port || visibleObj.port !== record.index) {
            return {
              edit: true,
              record: { ...record, ports: "" },
              dataIndex: 'ports'
            }
          }

          const ports = getTemplatePorts({
            signOffTemplate,
            designId,
            record,
            soc,
            gnd
          })
          const defaultPortIndexList = ports.map(item => item.index);

          return {
            record,
            edit: true,
            customInput: portSetup,
            dataIndex: 'ports',
            text: "",
            targetIC: record.refdes || soc,
            pcbId: designId,
            notIncludeTarget: true,
            signOffTemplate: true,
            powerDomainId: verificationId,
            ports,
            savePorts: (_id, _ports) => this.updatePorts(_id, _ports, record, defaultPortIndexList),
            onPortsRef: this.onPortsRef,
            PowerNets: [record.powerNetName],
            ReferenceNets: [record.gndNetName || gnd],
            savePortsAndClose: (_ports) => this.updatePorts(verificationId, _ports, record, defaultPortIndexList),
            closeColumnVisible: () => this.closeColumnVisible(record, "port")
          }
        }
      }

      if (column.dataIndex === "loopDcrSpec") {
        column.title = () => {
          const { tableExtendKeys } = this.state;

          return (
            <span
              className="cascade-sign-off-table-title-span"
              onClick={() => this.updateExpandKeys(column.dataIndex)}
            >
              <DownOutlined
                className={tableExtendKeys.includes(column.dataIndex) ? "pre-layout-fold-icon pre-layout-fold-icon-transform" : "pre-layout-fold-icon"} />
              {column.titleText}
            </span>
          );
        }
        if (!this.state.tableExtendKeys.includes(column.dataIndex) && column.children && column.children.length) {
          column.children.forEach(item => {
            item.render = (info, record) => {
              if (record.compType === "PMIC") {
                return <div className="aurora-table-row-disabled"></div>
              }
              const _info = info || {};
              const className = parseFloat(_info.actualValue) > parseFloat(_info.value) ? "cascade-target-actual-error-value" : "";
              if (item.dataIndex === 'loopDcrSpec_PMIC' || item.dataIndex === 'loopDcrSpec_Vsense') {
                return <span className={`cascade-target-actual-value-span ${className}`}>{_info.actualValue}</span>
              } else {
                return _info.value
              }
            }
            if (!(item.dataIndex === 'loopDcrSpec_PMIC' || item.dataIndex === 'loopDcrSpec_Vsense')) {
              item.onCell = (record) => {
                return {
                  edit: true,
                  dataIndex: item.dataIndex,
                  record: { ...record, [item.dataIndex]: record[item.dataIndex] ? record[item.dataIndex].value : "" },
                  handleSave: (_record) => this.saveTemplateValue(_record, record, item.dataIndex)
                }
              }
            }
          })
        } else {
          column.children = [{ title: "", dataIndex: "loopDcrSpec_expand" }];
        }
      }

      if (column.dataIndex === "impedance") {
        this.impedanceSpecColumnsRender({
          column,
          impType: "impedance",
          title: this.state.impedanceValue === 'actualValue' ? "Self Impedance Spec" : "Effective Impedance Spec",
          shortTitle: "Impedance"
        })
      }

      if (column.dataIndex === "inductanceSpec") {
        this.impedanceSpecColumnsRender({
          column,
          impType: "inductanceSpec",
          title: "Inductance Spec",
          shortTitle: "Inductance"
        })
      }

      if (column.dataIndex === "couplingImpedance") {
        this.impedanceSpecColumnsRender({
          column,
          impType: "couplingImpedance",
          title: "SOC Max Coupling Impedance Spec",
          shortTitle: "SOC Coupling"
        })
      }

      if (column.dataIndex === "shortSocCoupling") {
        this.impedanceSpecColumnsRender({
          column,
          impType: "shortSocCoupling",
          title: "PMIC Max Coupling Impedance Spec",
          shortTitle: "PMIC Coupling"
        })
      }
    })
    this.setState({
      columns: _.cloneDeep(_columns)
    })
  }

  impedanceColumnRender = (item, column) => {
    item.title = () => {
      return (
        <div>
          <span>{item.titleText}</span>
          {!["impedance_res", "impedance_ind", "impedance_rl_result",
            "couplingImpedance_res", "couplingImpedance_ind", "couplingImpedance_rl_result",
            "shortSocCoupling_res", "shortSocCoupling_ind", "shortSocCoupling_rl_result"
          ].includes(item.dataIndex) ? <CloseOutlined
            className='template-impedance-delete-freq-icon'
            title="Delete Frequency"
            onClick={(e) => this.deleteImpFreq(e, item.dataIndex)} /> : null}
        </div>
      );
    }
    item.render = (info, record) => {
      if (record.compType === "PMIC") {
        return <div className="aurora-table-row-disabled"></div>
      }
      if (["impedance_rl_result", "couplingImpedance_rl_result", "shortSocCoupling_rl_result"].includes(item.dataIndex)) {
        if (!info) return null;

        if (info && info.couplingResult) {
          return (
            <Fragment>
              <Popover
                content={this.getCouplingResultPopover(info.couplingResult)}
                overlayClassName="template-coupling-result-popover"
                title={null}
              >
                {info === "fail" ?
                  <ExclamationOutlined className='impedance-rl-result-failed' title="Failed" />
                  : <CheckOutlined className='impedance-rl-result-passed' title="Passed" />}
              </Popover>
            </Fragment>
          );
        }
        return info === "fail" ?
          <ExclamationOutlined className='impedance-rl-result-failed' title="Failed" />
          : <CheckOutlined className='impedance-rl-result-passed' title="Passed" />;
      }
      const { impedanceValue } = this.state;
      if (info && info[impedanceValue]) {
        const className = parseFloat(info[impedanceValue]) > parseFloat(info.value) ? "cascade-target-actual-error-value" : "";
        return <Fragment>
          <span className="cascade-sign-off-impedance-value-span">{info.value} |</span>
          <span className={`cascade-target-actual-value-span ${className}`}>{info[impedanceValue]}</span>
        </Fragment>
      }
      if (info && info.couplingResult) {
        const className = parseFloat(info.couplingResult.maxCouplingValue) > parseFloat(info.value) ? "cascade-target-actual-error-value" : "";
        return <Fragment>
          <Popover
            content={this.getCouplingResultPopover(info.couplingResult)}
            overlayClassName="template-coupling-result-popover"
            title={null}
          >
            <span className="cascade-sign-off-impedance-value-span">{info.value} |</span>
            <span className={`cascade-target-actual-value-span ${className}`}>{info.couplingResult.maxCouplingValue}</span>
            {/*  <Icon type="info-circle" className='template-coupling-result-info-icon' /> */}
          </Popover>
        </Fragment>
      }
      return <span>{info ? info.value : ""}</span>
    }

    item.onCell = (record) => {
      if (["impedance_rl_result", "couplingImpedance_rl_result", "shortSocCoupling_rl_result"].includes(item.dataIndex)) {
        return { edit: false }
      }

      return {
        edit: true,
        dataIndex: item.dataIndex,
        record: { ...record, [item.dataIndex]: record[item.dataIndex] ? record[item.dataIndex].value : "" },
        handleSave: (_record) => this.saveImpedance(_record, record, item.dataIndex, column.dataIndex)
      }
    }
  }

  getCouplingResultPopover = (couplingResult) => {
    /* couplingResult: { powerNetName, portName, groundPins: ['J9'], maxCouplingValue: "135.09", powerPins: ['J8'] } */
    const { powerPins, groundPins, portName, powerNetName, maxCouplingValue } = couplingResult;
    let pinWidth = 100, powerWidth = 100;
    if (!portName) {
      const maxLengthPins = powerPins.length > groundPins.length ? powerPins : groundPins;
      pinWidth = getTextWidth(maxLengthPins.join(", "), 14, 350);
    } else {
      pinWidth = getTextWidth(portName, 14, 350);
      powerWidth = getTextWidth(powerNetName, 14, 350);
    }
    const width = Math.max(pinWidth, powerWidth);

    return <Descriptions size="small" className='template-coupling-descriptions' style={{ width: width + 200 }}>
      <DescItem label="Max Coupling Value">{maxCouplingValue}</DescItem>
      {powerNetName ? <DescItem label="Power Net">{powerNetName}</DescItem> : null}
      {portName ?
        <DescItem label="Port Name">{portName}</DescItem>
        : <Fragment>
          <DescItem label="Power Pins" className='template-coupling-descriptions-power'>{powerPins.join(", ")}</DescItem>
          <DescItem label="Ground Pins" className='template-coupling-descriptions-gnd'>{groundPins.join(", ")}</DescItem>
        </Fragment>
      }


    </Descriptions>
  }

  impedanceSpecColumnsRender = ({ column, impType, title, shortTitle }) => {
    column.title = () => {
      const includesRL = column.children && column.children.find(it => it.dataIndex === `${impType}_rl_value`);
      const { tableExtendKeys } = this.state;
      const expanded = !tableExtendKeys.includes(column.dataIndex);
      return (
        <div>
          <span
            className='cascade-sign-off-table-title-span'
            onClick={() => this.updateExpandKeys(column.dataIndex)}>
            <DownOutlined
              className={expanded ? "pre-layout-fold-icon" : "pre-layout-fold-icon pre-layout-fold-icon-transform"} />
            <span>{expanded ? title : shortTitle}</span>
          </span>
          {
            expanded && impType === 'impedance' && <RetweetOutlined
              className="template-impedance-add-icon"
              onClick={(e) => this.changeImpedanceDisplay(e)} />
          }
          {expanded ? <Popover
            content={this.impedanceFreqContent(column.dataIndex)}
            trigger="click"
            onClick={(e) => { e.stopPropagation() }}
            open={this.state[`${column.dataIndex}FreqVisible`]}
            onOpenChange={(visible) => this.updateImpedanceFreqVisible(visible, column.dataIndex, includesRL)}
            overlayClassName='impedance-freq-popover'>
            <PlusSquareOutlined
              className='template-impedance-add-icon'
              title="Add impedance spec frequencies."
              onClick={() => this.updateImpedanceFreqVisible(true, column.dataIndex, includesRL)} />
          </Popover> : null}
        </div>
      );
    }
    if (!this.state.tableExtendKeys.includes(column.dataIndex) && column.children && column.children.length) {
      column.children.forEach(item => {
        if (item.dataIndex === `${impType}_rl_value`) {
          item.title = () => {
            return (
              <div>
                <span>{item.titleText}</span>
                {impType === 'impedance' ? <SettingOutlined
                  className='template-impedance-delete-freq-icon'
                  title='Impedance Spec Check Frequency Range'
                  onClick={(e) => this.updateRLSpecInterval(true, e)} /> : null}
                <CloseOutlined
                  className='template-impedance-delete-freq-icon'
                  title="Delete RL Value"
                  onClick={(e) => this.deleteImpFreq(e, item.dataIndex)} />
              </div>
            );
          }
          item.children.forEach(item => {
            this.impedanceColumnRender(item, column);
          })
        } else {
          this.impedanceColumnRender(item, column);
        }
      })
    } else {
      column.children = [{ title: "", dataIndex: `${impType}_expand` }];
    }
  }

  filterPowerNets = (nets, signOffTemplate) => {
    const existNets = signOffTemplate.filter(it => it.compType !== "PMIC").map(item => item.powerNetName).filter(item => !!item);
    return nets.filter(item => !existNets.includes(item));
  }

  updateRLSpecInterval = (boolean, e) => {
    e && e.stopPropagation()
    this.setState({
      rlSpecIntervalPanel: boolean
    })
  }

  updateExpandKeys = (key) => {
    let tableExtendKeys = this.state.tableExtendKeys;
    if (tableExtendKeys.includes(key)) {
      tableExtendKeys = tableExtendKeys.filter(item => item !== key);
    } else {
      tableExtendKeys.push(key);
    }
    this.setState({
      tableExtendKeys
    }, () => {
      this.props._updateTemplateSetup(true);
    });
  }

  deleteImpFreq = (e, dataIndex) => {
    this.props._deleteImpFreq(dataIndex);
  }

  addPowerDomain = (e, record) => {
    e && e.stopPropagation();
    this.props._addTemplatePowerDomain(record);
  }

  deletePD = (e, record) => {
    e && e.stopPropagation();
    this.props._deleteTemplatePwrDomain(record);
  }

  updateImpedanceFreqVisible = (visible, type, includesRL) => {
    this.setState({
      [`${type}FreqVisible`]: visible,
      [`${type}TargetType`]: includesRL ? TARGET_RL_VALUE : TARGET_TABLE
    })
  }

  updateImpFreq = (list, type) => {
    this.setState({
      [`${type}FreqList`]: list
    })
  }

  saveImpFreq = (type) => {
    if (!this.state[`${type}FreqList`].length && this.state[`${type}TargetType`] !== TARGET_RL_VALUE) {
      return;
    }
    const rlChecked = this.state[`${type}TargetType`] === TARGET_RL_VALUE;
    this.props._saveImpFreqList(this.state[`${type}FreqList`], rlChecked, type);
    this.setState({
      [`${type}FreqVisible`]: false,
      [`${type}FreqList`]: [],
      [`${type}RLValueChecked`]: false
    })
  }

  onChangeImpTargetType = (e, type) => {
    this.setState({
      [`${type}TargetType`]: e.target.value,
      [`${type}FreqList`]: [],
      [`${type}RLValueChecked`]: false
    })
  }

  changeImpedanceDisplay = (e) => {
    e && e.stopPropagation();
    const { impedanceValue, columns } = this.state;
    const value = impedanceValue === 'actualValue' ? 'effectiveImpedance' : 'actualValue';
    const impedanceIndex = columns.findIndex(item => item.dataIndex === 'impedance');
    if (columns[impedanceIndex].children && columns[impedanceIndex].children.length === 1
      && columns[impedanceIndex].children[0].title === '' && columns[impedanceIndex].children[0].dataIndex === 'impedance_expand') {
      columns[impedanceIndex].children = []
    }
    this.impedanceSpecColumnsRender({
      column: columns[impedanceIndex],
      impType: "impedance",
      title: value === 'actualValue' ? "Self Impedance Spec" : "Effective Impedance Spec",
      shortTitle: "Impedance"
    })
    this.setState({
      impedanceValue: value,
      columns: _.cloneDeep(columns)
    })
  }

  impedanceFreqContent = (_type) => {
    const targetType = this.state[`${_type}TargetType`];
    return <div>
      {_type !== "inductanceSpec" ? <Radio.Group
        onChange={(e) => this.onChangeImpTargetType(e, _type)}
        value={targetType}
        className='template-imp-target-radio'
      >
        <Radio value={TARGET_TABLE}>Frequency</Radio>
        <Radio value={TARGET_RL_VALUE}>RL Value</Radio>
        {/* TODO - touchstone */}
        {/* <Radio value={TARGET_TOUCHSTONE}>Touchstone</Radio> */}
      </Radio.Group>
        : <span className='template-imp-target-radio'>Frequency</span>}
      {targetType === TARGET_TABLE ? <TagsInput
        tagList={this.state[`${_type}FreqList`] || []}
        className={'impedance-freq-tagsInput'}
        allowedDuplicates={false}
        changeTagList={(type, list) => this.updateImpFreq(list, _type)}
        addonAfterUnit={"MHz"}
      ></TagsInput> : null}
      {/* {!includesRL && targetType === TARGET_RL_VALUE ? <Checkbox
        className='impedance-spec-rl-checkbox'
        onChange={(e) => this.addRLValue(e, _type)}
        checked={this.state[`${_type}RLValueChecked`]}
      >
        Add RL value spec
      </Checkbox> : null} */}
      <div className='impedance-freq-button-content'>
        <Button
          type="primary"
          ghost
          className='impedance-freq-button'
          onClick={() => this.saveImpFreq(_type)}
        >Save</Button>
      </div>

    </div>
  }

  addRLValue = (e, type) => {
    this.setState({
      [`${type}RLValueChecked`]: e.target.checked
    })
  }

  saveTemplateValue = (record, prevRecord, dataIndex) => {
    const value = record[dataIndex];
    if (!['portName', 'powerNetName'].includes(dataIndex) && value && numberCheck(value)) {
      return;
    }
    const _value = value ? value : (dataIndex !== "portName" ? "" : "");
    this.props._saveTemplateValue(_value, prevRecord, dataIndex);
  }

  saveImpedance = (record, prevRecord, dataIndex, impType) => {
    const value = record[dataIndex];
    if (value && numberCheck(value)) {
      return;
    }
    const _value = value ? value : "";
    this.props._saveImpedanceValue(_value, prevRecord, dataIndex, impType);
  }

  openTableCell = (e, record, type) => {
    let visibleObj = { ...this.state.visibleObj };

    if (visibleObj[type] === record.index) {
      return;
    }

    if (visibleObj[type]) {
      switch (type) {
        case "port":
          this.closePortsPanel();
          break;
        default: break;
      }
    }
    visibleObj[type] = record.index;

    this.setState({
      visibleObj
    })
  }

  closeColumnVisible = (record, type) => {
    let visibleObj = { ...this.state.visibleObj };
    visibleObj[type] = visibleObj[type] === record.index ? null : visibleObj[type];
    this.setState({
      visibleObj
    })
  }

  downloadSParameter = (e, record) => {
    e && e.stopPropagation();
    this.props._downloadTemplateS1PFile(record);
  }

  openTask = (record) => {
    //todo
  }

  updatePorts = (id, ports, record, defaultPortIndexList) => {
    this.props._updateTemplatePorts(id, ports, record, defaultPortIndexList);
  }

  onPortsRef = (ref) => {
    this.portsRef = ref;
  }

  closePortsPanel = () => {
    this.portsRef && this.portsRef.closeModal();
  }

  createTemplateDomain = (powerDomainInfo) => {
    this.props._updateTemplateContentLoading(true)
    this.setState({
      createVisible: false
    }, () => {
      setTimeout(() => {
        this.props._createNewPower(powerDomainInfo);
      }, 100)
    })

    if (!this.state.isScroll) {
      return;
    }
    this.setState({
      isScroll: false
    })
    //The table has a scroll bar to scroll to the bottom
    setTimeout(() => {
      const contentEle = document.querySelector('#cascade-content-main');
      const tableEle = document.querySelector('#cascade-sign-off-template-domain-table-main');
      if (contentEle) {
        const tableHeight = (tableEle.offsetHeight || 50) - 40;
        contentEle.scrollTop = tableHeight;
      }
    }, 240);
  }

  openCreateTemplateDomain = (e, isScroll, isAddPMIC) => {
    e && e.stopPropagation();
    this.setState({
      createVisible: true,
      isScroll,
      isAddPMIC
    })
  }

  closeCreatePanel = () => {
    this.setState({
      createVisible: false,
      isScroll: false,
      isAddPMIC: false
    })
  }

  render() {
    const { signOffTemplate, loading, contentLoading, soc, gnd, designId, nets, socList } = this.props;
    const { columns, createVisible, isAddPMIC, rlSpecIntervalPanel } = this.state;
    const data = getSignOffTemplateData(signOffTemplate, soc, gnd);
    return <Fragment>
      <div className='cascade-sign-off-template-table' id="cascade-sign-off-template-domain-table-main">
        <Spin size='small' spinning={loading || contentLoading} tip={contentLoading ? "Loading..." : "Loading PCB..."}>
          <Row className='cascade-template-add-button-row'>
            <Col span={24}>
              <div className='cascade-template-add-button-flex'>
                <Button
                  title="Add New Power Domain"
                  className='cascade-template-add-button'
                  type="primary"
                  ghost={true}
                  disabled={!designId || createVisible}
                  onClick={(e) => this.openCreateTemplateDomain(e, true)}
                >Add New Power Domain</Button>
                <Button
                  title="Assign PMIC To Power Domain."
                  className='cascade-template-add-button cascade-template-add-PMIC-button'
                  type="primary"
                  ghost={true}
                  disabled={!designId || createVisible}
                  onClick={(e) => this.openCreateTemplateDomain(e, false, "PMIC")}
                >Assign PMIC To Power Domain</Button>
              </div>
            </Col>
          </Row>
          <EditableTable
            rowKey={(record) => record.index}
            onRow={(record, index) => {
              if (data[index + 1] && record.groupKey !== data[index + 1].groupKey) {
                return {
                  className: 'cascade-sign-off-pmic-comp-div'
                }
              }
            }
            }
            columns={columns || []}
            tableLayout='auto'
            size="small"
            ref={(ref) => this.table = ref}
            dataSource={data || []}
            className="cascade-components-table"
          />
          <Row className='space-10'>
            <Col span={24}>
              <Button
                title="Add New Power Domain"
                className='cascade-template-add-button'
                type="primary"
                ghost={true}
                disabled={!designId}
                onClick={(e) => this.openCreateTemplateDomain(e)}
              >Add New Power Domain</Button>
            </Col>
          </Row>
        </Spin>
      </div>
      {createVisible ?
        <CreatePowerDomain
          nets={nets}
          signOffTemplate={signOffTemplate}
          soc={soc}
          gnd={gnd}
          designId={designId}
          socList={socList}
          isAddPMIC={isAddPMIC}
          createPower={this.createTemplateDomain}
          closeModal={this.closeCreatePanel}
        />
        : null}
      {rlSpecIntervalPanel ?
        <RLSpecIntervalPanel
          closeModal={this.updateRLSpecInterval}
        />
        : null}
    </Fragment>
  }
}

const mapDispatch = (dispatch) => ({
  _updateTemplatePorts(id, ports, record, defaultPortIndexList) {
    dispatch(updateTemplatePorts(id, ports, record, defaultPortIndexList))
  },
  _downloadTemplateS1PFile(record) {
    dispatch(downloadTemplateS1PFile(record))
  },
  _saveImpedanceValue(value, record, dataIndex, impType) {
    dispatch(saveImpedanceValue(value, record, dataIndex, impType))
  },
  _updateTemplateSetup(status) {
    dispatch(updateTemplateSetup(status))
  },
  _saveTemplateValue(value, record, dataIndex) {
    dispatch(saveTemplateValue(value, record, dataIndex))
  },
  _createNewPower(powerDomainInfo) {
    dispatch(createNewPower(powerDomainInfo))
  },
  _deleteTemplatePwrDomain(record) {
    dispatch(deleteTemplatePwrDomain(record))
  },
  _addTemplatePowerDomain(record) {
    dispatch(addTemplatePowerDomain(record))
  },
  _saveImpFreqList(list, RLValueChecked, impType) {
    dispatch(saveImpFreqList(list, RLValueChecked, impType))
  },
  _deleteImpFreq(dataIndex) {
    dispatch(deleteImpedanceFreq(dataIndex))
  },
  _updateTemplateSetupValue(updateStatus) {
    dispatch(updateTemplateSetupValue(updateStatus))
  },
  _updateTemplateContentLoading(loading) {
    dispatch(updateTemplateContentLoading(loading))
  }
})

export default connect(null, mapDispatch)(SignOffTable);