import { PCB, PACKAGE, DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE } from '@/constants/treeConstants';

export function getTopBarWidth(viewList) {
  const view = viewList.filter(item => ![PCB, PACKAGE].includes(item));
  if (view.length) {
    const topBar = document.getElementById('cascade-top-bar');
    const topBarLength = topBar && topBar.children && topBar.children.length ? topBar.children.length : 0
    const topBarRight = document.getElementById('cascade-right-top-bar')
    const topBarRightLength = topBarRight && topBarRight.children && topBarRight.children.length ? topBarRight.children.length : 0
    const children = topBarLength + topBarRightLength - 1;
    if (children > 0) {
      return children * 110
    }
    switch (view[0]) {
      case DCR:
        return 530;
      case IR_EXPLORER:
        return 555;
      case IMPEDANCE:
        return 990;
      case POWER_TREE:
        return 780;
      case DESIGN_TREE:
        return 830;
      case SINGLE_TREE:
        return 930
      case SIGN_OFF_TEMPLATE:
        return 766;
      default: return 650;
    }
  }
  return 650;
}
