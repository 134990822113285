import http from './http';
import { S_PARAMETER } from '../../constants/designType';

export function getNetworkCurve({ id, path, rows, cols }) {
  return http.post(`/services/s-parameter/data/${id}`, {
    path,
    rows,
    cols
  })
}

export function getParameterInfo() {
  return http.get('/services/s-parameter');
}

/**
 * 
 * @param {*} ids {ids:[]}
 */
export function deleteParameter(ids) {
  return http.delete('/services/s-parameter', {
    data: {
      ids: [...ids.ids],
      isNew: ''
    }
  });
}

export function uploadSParameter({ file, name }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('projectId', 0)
  formData.append('type', S_PARAMETER)
  formData.append('vendor', 2)
  formData.append('isNew', '')
  return http.post('/services/s-parameter/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}