import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { changeTabMenu, closeTabFooter } from '../../MonitorStore/action';
import endToEndChannelConstructor from '@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor';
import { PCB, END_TO_END_CHANNEL_RESULT, PCB_CHANNEL_RESULT, PCB_CHANNEL, END_TO_END_CHANNEL, PCB_PRE_LAYOUT, PACKAGE, EXPERIMENTS } from '@/constants/treeConstants';
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import ContentLayout from '@/components/ContentLayout';
import { getTopBarWidth, getCCCVerifications, getCCCSpecifyServiceVerification, getServiceTitleList } from '@/services/Andes_v2';
import LayoutContent from './layoutContent';
import LayoutLR from './layoutLR';
import LayoutTB from './layoutTB';
import { ANDES_V2 } from '@/constants/pageType';
import AndesMonitorChild from './Monitor';
import { isEndToEndChildrenChannel } from '../../../services/Andes_v2';
import sweepConstructor from '../../../services/Andes_v2/sweep/sweepConstructor';

class AndesV2Content extends PureComponent {

  _changeMonitorTabMenu = ({ selectKeys }) => {
    const { endToEndChannelId, projectId, menuType, viewList, channelId, sweepId } = this.props;
    const view = viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS].includes(item));
    let verification = {};

    if (view === PCB_CHANNEL) {
      const channel = channelConstructor.getChannel(channelId);
      verification = channel ? channel : {};

      //end to end channel children single channel, display name is pcb name
      if (isEndToEndChildrenChannel(verification)) {
        verification = { ...verification, name: verification.pcbName }
      }
    } else if (view === EXPERIMENTS) {
      const sweep = sweepConstructor.getSweep(sweepId)
      verification = sweep ? sweep : {};
    } else {
      //get current project EndToEndChannel list
      const endToEndChannels = endToEndChannelConstructor.getEndToEndChannelValues(projectId);
      verification = endToEndChannels.find(item => item.id === endToEndChannelId);
      if (!verification && endToEndChannels.length > 0) {
        verification = endToEndChannels[0] ? endToEndChannels[0] : null;
      }
    }

    let info = {
      tabSelectKeys: selectKeys,
      currentVerificationId: verification ? verification.verificationId : null,
      verificationName: verification ? verification.name : null,
      menuType
    }
    this.props._changeTabMenu(info);
  }

  render() {
    const { layout, viewList } = this.props;
    return <ContentLayout
      {...this.props}
      pageType={ANDES_V2}
      layout={layout}
      viewList={viewList}
      layoutLR={this.layoutLR}
      layoutTB={this.layoutTB}
      layoutSingle={this.layoutSingle}
    />;
  }

  getTopMenuVisible = () => {
    const { viewList, projectId } = this.props;
    if (!projectId) {
      return false;
    }

    if (viewList && viewList.length && viewList.every(v => [PCB_CHANNEL_RESULT, PCB, END_TO_END_CHANNEL_RESULT, PCB_PRE_LAYOUT, PACKAGE].includes(v))) {
      return false;
    }

    return true;
  }

  _monitorChild = (width) => {
    const { menuType, tabVisible, tabSelectKeys, projectId, currentVerificationId, viewList, projectName,
      verificationName } = this.props;
    const channel = channelConstructor.getChannelByVerificationId(currentVerificationId) || {};
    return AndesMonitorChild({
      menuType,
      tabVisible,
      tabSelectKeys,
      projectId,
      getCCCVerifications,
      getCurrentVerification: () => getCCCSpecifyServiceVerification(currentVerificationId, viewList),
      width,
      pageType: ANDES_V2,
      titleSelectedKeys: getServiceTitleList({ projectName, pcbName: channel.pcbName, verificationName })
    })
  }

  layoutSingle = () => {
    const { channelId, viewList, endToEndChannelId } = this.props;
    return <LayoutContent  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={() => getTopBarWidth({ channelId, viewList, endToEndChannelId })}
    />
  }

  layoutLR = () => {
    const { channelId, viewList, endToEndChannelId } = this.props;
    return <LayoutLR  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={() => getTopBarWidth({ channelId, viewList, endToEndChannelId })}
    />
  }

  layoutTB = () => {
    const { channelId, viewList, endToEndChannelId } = this.props;
    return <LayoutTB  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={() => getTopBarWidth({ channelId, viewList, endToEndChannelId })}
    />
  }
}

const mapState = (state) => {
  const { project, channel: { channelId }, endToEndChannel: { endToEndChannelId }, sweep: { sweepId } } = state.AndesV2Reducer;
  const { viewList, layout, openProjectId } = project;
  const { monitorScreenInfo: { tabSelectKeys, menuType, currentVerificationId, projectName, verificationName }, tabVisible } = state.MonitorInfoReducer;

  return {
    viewList,
    layout,
    tabVisible,
    channelId,
    projectId: openProjectId,
    tabSelectKeys,
    menuType,
    currentVerificationId,
    endToEndChannelId,
    projectName,
    verificationName,
    sweepId
  };
}

const mapDispatch = (dispatch) => ({
  _changeTabMenu(info) {
    dispatch(changeTabMenu(info));
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  }
})

export default connect(mapState, mapDispatch)(AndesV2Content);