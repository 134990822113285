import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { FileTextOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { getEyeDiagramConfig, getEyeDiagramSignals, generateEyeDiagram, checkEyeDiagramError, getDownloadEyeGenerateLog } from '../../../services/Sierra/results';
import {
  saveEyeDiagramsImg,
  saveEyeDiagramsSignals,
  saveSelectSignals,
  updateEyeDiagramConfig,
  defaultEyeDiagramConfig
} from '../store/result/action';
import EyeDiagramConfig from './eyeDiagramConfig';
import { getSierraEyeDiagramImg } from '@/services/api/sierra';
import { ResultData } from '@/services/Sierra';
import Line from '@/components/Line/index';
import MsgDialog from '../../../components/MsgDialog';
import './index.css';

const generateError = 'Failed to generate eye diagram.';
const GENERATE = "generate";
class EyeDiagrams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabled: false,
      progress: -1,
      imgSrc: null,
      settingVisible: true,
      left: 800,
      right: 370,
      errorMsg: null,
      currentInterfaceInfo: {},
      logVisible: false,
      setupErrors: null
    }
    this.contentRef = createRef();
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    this.screenChange();
    if (this.contentRef && this.contentRef.current && this.contentRef.current.offsetWidth) {
      const width = this.contentRef.current.offsetWidth;
      let right = width * 0.34;
      let left = width * 0.66;
      if (right < 370) {
        right = 370;
        left = width - 370;
      }
      this.setState({
        left,
        right
      })
    }
    const { verificationId, verificationSubId, currentProjectId } = this.props;
    this.props.saveEyeDiagramsImg(null);

    if (verificationSubId && verificationId) {
      ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId).then(res => {
        if (res) {
          let currentInterfaceInfo = ResultData.getInfo();
          this.getEyeDiagramInfo(currentInterfaceInfo, verificationId);
          this.getEyeImg();
          this.setState({
            currentInterfaceInfo
          })
        }
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId, verificationSubId, currentProjectId, siderLeftWidth, layoutLeftWidth } = this.props;
    if (siderLeftWidth !== prevProps.siderLeftWidth || layoutLeftWidth !== prevProps.layoutLeftWidth) {
      this.resize(true);
    }

    if (verificationId !== prevProps.verificationId && verificationSubId) {
      ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId).then(res => {
        if (res) {
          this.setState({
            errorMsg: null,
            loading: true
          })
          let currentInterfaceInfo = ResultData.getInfo();
          this.props.saveEyeDiagramsImg(null);
          this.getEyeDiagramInfo(currentInterfaceInfo, verificationId);
          this.getEyeImg();
          this.setState({
            currentInterfaceInfo
          })
        }
      })
    }
  }

  getEyeDiagramInfo = (currentInterfaceInfo, verificationId) => {
    if (currentInterfaceInfo && currentInterfaceInfo.components) {
      const interfaces = ResultData.getInterfaces() || [];
      const { signals, clocks, receiverSignals, DDRSignals, allSignalPins } = getEyeDiagramSignals(currentInterfaceInfo.components, interfaces);
      this.props._saveEyeDiagramsSignals({ clocks, signals, receiverSignals, DDRSignals, allSignalPins });

      getEyeDiagramConfig(verificationId).then(res => {
        if (res && res.eye_config && res.plot_config && res.eye_config.components) {
          this.props._updateEyeDiagramConfig({ eyeDiagramConfig: res, isUpdate: true, verificationId });
        } else {
          this.props._defaultEyeDiagramConfig({ verificationId, clocks, receiverSignals, signals, DDRSignals, currentInterfaceInfo, allSignalPins });
        }
        this.setState({
          loading: false
        })
      })
    } else {
      this.props._saveEyeDiagramsSignals({ clocks: [], signals: [], receiverSignals: [], DDRSignals: [], allSignalPins: [] });
      this.setState({
        loading: false
      })
    }
  }

  getEyeImg = () => {
    const { verificationId } = this.props;
    getSierraEyeDiagramImg(verificationId).then(response => {
      if (!response || !response.data || !response.data.byteLength) {
        this.props.saveEyeDiagramsImg(null);
        return;
      } else {
        return 'data:image/png;base64,' + btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
      }
    }).then(src => {
      this.props.saveEyeDiagramsImg(src);
    })
  }

  generateEye = () => {
    const { errorMsg } = this.state;
    const { verificationId, eyeDiagramConfig, eyeClockType, clockPositive, clockNegative, } = this.props;
    const errors = checkEyeDiagramError({ eyeDiagramConfig, eyeClockType, clockPositive, clockNegative, });
    if (errors) {
      this.setState({
        setupErrors: errors
      })
      return;
    }
    this.setState({
      progress: 0,
      errorMsg: errorMsg && errorMsg.type === GENERATE ? null : errorMsg,
      setupErrors: null
    })
    let time = setInterval(() => {
      let progress = this.state.progress + 4;
      this.setState({
        progress: progress <= 98 ? progress : 98
      });
    }, 2000);
    generateEyeDiagram({ verificationId, eyeDiagramConfig }).then(res => {
      if (res && res.status === "success") {
        getSierraEyeDiagramImg(verificationId).then(response => {
          this.setState({
            progress: 100
          });
          setTimeout(() => {
            this.setState({
              progress: -1
            });
          }, 1000);
          clearInterval(time);
          if (!response || !response.data || !response.data.byteLength) {
            this.props.saveEyeDiagramsImg(null);
            this.setState({
              errorMsg: {
                type: GENERATE, error: generateError, errorContent: ["Failed to get the eye diagram image."],
              },
              logVisible: true
            })
            return;
          } else {
            return 'data:image/png;base64,' + btoa(
              new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
          }
        }, error => {
          this.setState({
            errorMsg: { type: GENERATE, error: generateError }
          })
          clearInterval(time);
          this.setState({
            progress: -1
          });
        }).then(src => {
          this.props.saveEyeDiagramsImg(src);
        })
      } else {
        this.setState({
          errorMsg: { type: GENERATE, error: generateError, errorContent: typeof (res.error) === "string" ? [res.error] : [] },
          logVisible: true
        })
        clearInterval(time);
        this.setState({
          progress: -1
        });
      }
    }, error => {
      console.error(error);
      this.setState({
        errorMsg: { type: GENERATE, error: generateError, errorContent: error && typeof (error.error) === "string" ? error.error.split("\n") : [] },
        logVisible: true
      })
      clearInterval(time);
      this.setState({
        progress: -1
      });
    })
  }

  closeSetting = () => {
    const { settingVisible } = this.state;
    this.setState({
      settingVisible: !settingVisible
    }, () => {
      const { settingVisible } = this.state;
      let contentWidth = this.contentRef.current ? this.contentRef.current.offsetWidth : 800;
      const left = contentWidth - 370;
      if (settingVisible) {
        this.changeWidth(left);
      }
    })
  }

  changeWidth = (leftWidth) => {
    this.updateWidth(leftWidth);
  }

  updateWidth = (left, resize) => {
    const { settingVisible } = this.state;
    if (this.contentRef.current) {
      let contentWidth = this.contentRef.current.offsetWidth;
      let right = contentWidth - left;

      if (left < 200 && right > 370 && !resize) {
        left = 200;
        right = contentWidth - left;
      }


      if (right < 370 && left > 200 && !resize) {
        right = 370;
        left = contentWidth - 370;
      }

      if (left < 200) {
        left = 200;
      }
      if (right < 370 && settingVisible) {
        right = 370;
      }
      this.setState({
        right,
        left
      });
    }
  }

  resize = (isSiderUpdate) => {
    const { left, right } = this.state;
    let _left = left;
    if (isSiderUpdate && this.contentRef.current && this.contentRef.current.offsetWidth) {
      _left = this.contentRef.current.offsetWidth - right;
    }
    this.updateWidth(_left, true);
  }

  updateErrorMsg = (errorMsg, clockTypeUpdate) => {
    this.setState({
      errorMsg,
      setupErrors: clockTypeUpdate ? null : this.state.setupErrors
    })
  }

  // drag end
  changeSize = (leftWidth) => {
    if (!this.contentRef.current) {
      return;
    }
    const { right } = this.state;
    const contentWidth = this.contentRef.current.offsetWidth;
    let _left = leftWidth;
    let _right = contentWidth - _left;
    // prev right === 46 (settingVisible - false)
    if (right === 46 && _right > 46) {
      if (_right < 370) {
        _right = 370;
        _left = contentWidth - 370;
      }
      this.setState({
        right: _right,
        left: _left,
        settingVisible: true
      });
      return;
    } else if (_right < 370) {
      _right = 46;
      _left = contentWidth - 46;
      this.setState({
        right: _right,
        left: _left,
        settingVisible: false
      });
      return;
    };
    this.changeWidth(_left);
  }

  openLogPanel = (e) => {
    e && e.stopPropagation();
    this.setState({
      logVisible: true
    })
  }

  closeLogPanel = () => {
    this.setState({
      logVisible: false
    })
  }


  render() {
    const { progress, settingVisible, left, right, errorMsg, loading, currentInterfaceInfo, logVisible, setupErrors } = this.state;
    const { imgSrc, verificationId } = this.props;
    const generateLogExist = errorMsg && errorMsg.error && errorMsg.errorContent && errorMsg.errorContent.length;

    return (
      <Spin spinning={loading} size='large'>
        <div className='sierra-eye-diagram-main' ref={this.contentRef} id="sierra-eye-diagram-main-id">
          <div className='sierra-eye-diagram-left' style={{ right: settingVisible ? right : 0 }}>
            {/* eye diagram img */}
            {imgSrc ? <img src={imgSrc} alt="" className='sierra-result-img' /> : null}
          </div>
          <Line
            position={{ position: "absolute", left: left }}
            changeWidth={(left) => this.changeWidth(left)}
            width={left}
            resize={this.changeSize}
          />
          <div className='sierra-eye-diagram-right' style={{ left: left, minWidth: settingVisible ? 370 : 46 }}>
            <div className='setting-menu' style={{ display: !settingVisible ? 'block' : 'none' }}>
              <MenuOutlined className='setting-menu-icon' onClick={(e) => this.closeSetting(e)} />
            </div>
            <div style={{ display: settingVisible ? 'block' : 'none' }}>
              {/* eye diagram config */}
              <EyeDiagramConfig
                updateErrorMsg={this.updateErrorMsg}
                errorMsg={errorMsg}
                currentInterfaceInfo={currentInterfaceInfo} />
              {/* eye diagram button */}
              <div className='sierra-eye-diagram-button'>
                <Button
                  onClick={progress === -1 ? this.generateEye : null}
                  className='sierra-eye-diagram-btn'
                  style={generateLogExist ? { width: "calc(100% - 30px)" } : {}}
                  disabled={errorMsg && errorMsg.type && errorMsg.type !== GENERATE ? true : false}
                >
                  <div
                    className='sierra-eye-diagram-btn-progress'
                    style={{ right: progress > -1 ? `${(100 - progress)}%` : '100%' }}
                  ></div>
                  <span className='sierra-eye-diagram-btn-span'
                  >{progress > -1 ? `Generating... ${progress}%` : "Generate Eye Diagram"}</span>
                </Button>
                {generateLogExist ? <FileTextOutlined
                  title={"Generate eye diagram log."}
                  onClick={(e) => this.openLogPanel(e)}
                  className='sierra-eye-diagram-log-icon'
                /> : null}
              </div>
              {errorMsg && errorMsg.error ? <span className="sierra-eye-diagram-error-msg">{errorMsg.error}</span> : null}
              {setupErrors && setupErrors.length ? <div className='sierra-eye-diagram-error-msg-content'>
                {setupErrors.map(item => <span key={item}>{item}</span>)}
              </div> : null}
              {logVisible && generateLogExist ?
                <MsgDialog
                  mask={false}
                  type="log"
                  allowButton={true}
                  allowDownload={true}
                  isGetDownloadMsg={true}
                  getDownloadLog={getDownloadEyeGenerateLog}
                  downloadParams={{ verificationId }}
                  messageList={errorMsg.errorContent}
                  fileName="Eye_diagram_generation.log"
                  className="aurora-msg-dialog-info-panel sierra-download-eye-diagram-log-panel"
                  clickOkButton={this.closeLogPanel}
                />
                : null}
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapState = (state) => {
  const { resultReducer, project } = state.SierraReducer;
  const { selectedClocks, selectedSignals, clocks, signals, imgSrc, eyeDiagramConfig, resultInfo, eyeClockType, clockPositive,
    clockNegative } = resultReducer;
  const { currentProjectId } = project;
  const { verificationId, verificationSubId } = resultInfo;
  return {
    verificationId,
    currentProjectId,
    selectedClocks,
    selectedSignals,
    clocks,
    signals,
    imgSrc,
    eyeDiagramConfig,
    verificationSubId,
    eyeClockType,
    clockPositive,
    clockNegative
  }
}

const mapDispatch = (dispatch) => ({
  saveEyeDiagramsImg(imgSrc) {
    dispatch(saveEyeDiagramsImg(imgSrc));
  },
  saveSelectSignals({ selectedClocks, selectedSignals }) {
    dispatch(saveSelectSignals({ selectedClocks, selectedSignals }));
  },
  _saveEyeDiagramsSignals({ clocks, signals, receiverSignals, DDRSignals, allSignalPins }) {
    dispatch(saveEyeDiagramsSignals({ clocks, signals, receiverSignals, DDRSignals, allSignalPins }));
  },
  _updateEyeDiagramConfig({ eyeDiagramConfig, isUpdate, verificationId }) {
    dispatch(updateEyeDiagramConfig({ eyeDiagramConfig, isUpdate, verificationId }))
  },
  _defaultEyeDiagramConfig({ verificationId, clocks, receiverSignals, signals, DDRSignals, currentInterfaceInfo, allSignalPins }) {
    dispatch(defaultEyeDiagramConfig({ verificationId, clocks, receiverSignals, signals, DDRSignals, currentInterfaceInfo, allSignalPins }))
  }
})

export default connect(mapState, mapDispatch)(EyeDiagrams);