import React, { Component, Fragment } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import AdvancePanel from './AdvancePanel';

class ExtractionSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  openPanel = (e) => {
    e.stopPropagation();
    this.setState({
      visible: true
    })
  }

  closeModal = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { visible } = this.state;
    const { extraction } = this.props;
    return (
      <Fragment>
        <div className='andes-re-extraction'>
          <span className="font-bold">Extraction options</span>
          <SettingOutlined
            className='andes-extraction-button'
            onClick={(e) => { this.openPanel(e) }} />
        </div>
        {visible && <AdvancePanel
          closeModal={this.closeModal}
          saveExtraction={this.props.saveExtraction}
          extraction={extraction}
          simulate={this.props.simulate}
        />}
      </Fragment>
    );
  }
}

export default ExtractionSetup;