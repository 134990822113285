import React, { Component, createRef } from 'react';
import { MonitorOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import ConnectionLine from '@/services/Cascade/helper/connectionLine';
import designConstructor from '@/services/helper/designConstructor';
import { strDelimited } from '@/services/helper/split';
import { getTextWidth } from '../../../services/helper/getTextWidth';
import NetsConnectionSetting from './netsConnectionSetting';
import { userDefaultSettings } from '../../../services/userDefaultSetting/userDefaultSettingCtrl';
import { NETS_PINS, NETS_PINNAMES, NONE, BY_NETS, BY_PINS } from '../../../constants/connectionType';
import debounce from '../../../services/helper/debounceFn';
import { getArraySimilar } from '../../../services/helper/arrayHelper';
import { checkConnectCompsPinsAndNets } from '../../../services/Cascade/helper/setupData';
import auroraDBJson from '../../../services/Designs/auroraDbData';
import { POWER } from '../../../services/Designs/constants';
import './index.css';

class netsConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nets: [],
      selectNets: [],
      save: false,
      sortNets: [],
      open: false,
      search: {}
    };
    this.svgRef = createRef();
    this.connectionLine = null;
  }

  componentDidMount() {
    this.connectionLine = new ConnectionLine(this.svgRef.current, this.overClick, this.selectLine);
    this.props.onRef && this.props.onRef(this);
    const { netsInfo: { nets = [] }, nets: sortNets = [] } = this.props;
    this.setState({
      nets,
      sortNets
    }, () => {
      this.setPointLocation(true);
    })
  }

  componentDidUpdate = (prevProps) => {
    const { drawStatus, autoConnection = [] } = this.props;
    if (prevProps.drawStatus !== drawStatus) {
      const { netsInfo: { nets = [] }, nets: sortNets = [] } = this.props;
      this.setState({
        nets,
        sortNets
      }, () => {
        this.setPointLocation(true);
      })
    }

    if (autoConnection.length) {
      const { netsInfo, nets = [] } = this.props;
      const { next, prev } = netsInfo;
      const { netsInfo: prevNetsInfo } = prevProps;
      const { next: prevNext, prev: prevPrev } = prevNetsInfo;
      if (((next.comp !== prevNext.comp) || (prev.comp !== prevPrev.comp)) && !nets.length) {
        const { netsInfo: { nets = [] }, nets: sortNets = [] } = this.props;
        this.setState({
          nets,
          sortNets
        }, () => {
          this.setPointLocation(false, true);
        })
      }
    }
  }

  openConnectionSetting = (e) => {
    e && e.stopPropagation();
    this.setState({
      open: true
    })
  }

  closeConnectionSetting = () => {
    this.setState({
      open: false
    }, () => {
      this.setPointLocation(true);
    })
  }

  searchNets = (e, key) => {
    const value = e.target.value;
    const { search } = this.state;
    this.setState({
      search: {
        ...search,
        [key]: value
      }
    }, () => {
      debounce(() => {
        this.connectionLine && this.connectionLine.removeAllLines();
        this.setPointLocation(true);
      }, 500, false, 'connectionNetKey')()
    })
  }

  getNetsAndSave = () => {
    const { nets, save } = this.state;
    const { netsInfo } = this.props;
    return { nets, save, netsInfo }
  }

  getNets = (pins, pcbKey) => {
    const { sortNets, search } = this.state;
    const pcbSearch = search[pcbKey]
    const nets = [...new Set(pins.map(i => i.net))].sort((a, b) => !sortNets.includes(a) && sortNets.includes(b) ? 1 : -1);
    const userSetting = userDefaultSettings.getLocalSetting();
    const { cascadeSettings = {} } = userSetting;
    const { connectionDisplay = NETS_PINS } = cascadeSettings;
    return nets.map(net => {
      const _pins = pins.filter(p => p.net === net);
      let pinText = connectionDisplay === NETS_PINS ? _pins.map(p => p.pin) : connectionDisplay === NETS_PINNAMES ? _pins.map(p => p.pinName) : [];
      if (pcbSearch) {
        pinText = pinText.sort((a, b) => !a.toLowerCase().includes(pcbSearch.toLowerCase()) && b.toLowerCase().includes(pcbSearch.toLowerCase()) ? 1 : -1);
      }
      if (pinText && pinText.length > 3) {
        pinText = pinText.slice(0, 3);
        pinText = `${pinText.join(', ')}...`
      } else {
        pinText = pinText.join(', ')
      }
      return { net, text: !pinText ? net : `${net} ( ${pinText} )` }
    }).filter(netInfo => !pcbSearch || netInfo.text.toLowerCase().includes(pcbSearch.toLowerCase()))
  }

  getAutoMatch = () => {
    const userSetting = userDefaultSettings.getLocalSetting();
    const { cascadeSettings = {} } = userSetting;
    const { autoSelectConnection = NONE } = cascadeSettings;
    return autoSelectConnection === NONE ? false : autoSelectConnection
  }

  showNetsRender = ({ comp, compInfo, pcbKey, type, pcb }) => {
    if (!compInfo) {
      console.log({ comp, compInfo, pcbKey, type, pcb })
    }
    let pins = compInfo && compInfo.pins ? [...compInfo.pins.values()] : [];
    const pcbName = designConstructor.getDesignName(pcb);
    const nets = this.getNets(pins, pcbKey);
    const autoType = this.getAutoMatch();
    return (
      <div className='cascade-net-connection-column-content'>
        <div className='cascade-net-connection-column-content-comp'>{pcbName}</div>
        <div className='cascade-net-connection-column-content-comp'>{comp}</div>
        <Input className='cascade-net-connection-column-content-input' addonBefore={<SearchOutlined />} onChange={(e) => this.searchNets(e, pcbKey)} size='small' />
        {nets.map(netInfo => {
          const { net, text } = netInfo;
          const width = `calc(50% - ${getTextWidth(text) / 2 + 5}px)`;
          return (
            <div className='cascade-net-connection-column-content-net' key={`${pcbKey}-${net}`}>
              <span className={`dashed`} style={type === 'prev' ? { width, left: 0 } : { width, right: 0 }} />
              {type === 'next' && autoType && <MonitorOutlined
                className='cascade-net-connection-column-content-auto-match-icon'
                onClick={(e) => this.autoMatchLine(e, `${type}@${pcbKey}@${comp}@${net}`)} />}
              <span>{text}</span>
              {type === 'prev' && autoType && <MonitorOutlined
                className='cascade-net-connection-column-content-auto-match-icon'
                onClick={(e) => this.autoMatchLine(e, `${type}@${pcbKey}@${comp}@${net}`)} />}
              {<div
                className={`${type}-button connection-net-button`}
                id={`${type}@${pcbKey}@${comp}@${net}`}
                onClick={(e) => this.clickLine(e, `${type}@${pcbKey}@${comp}@${net}`)}
              ></div>}
            </div>
          );
        })}
      </div>
    );
  }

  clickLine = (e, key) => {
    e && e.stopPropagation();
    this.setState({
      drawLine: true
    }, () => {
      this.connectionLine.drawTempLine(key)
    })
  }

  autoMatchLine = (e, key) => {
    e && e.stopPropagation();
    const { netsInfo } = this.props;
    const [type, pcbKey, comp, net] = key.split('@');
    const anotherType = type === 'prev' ? 'next' : 'prev';
    const _compInfo = netsInfo ? netsInfo[anotherType] : null;
    const c_compInfo = netsInfo ? netsInfo[type] : null;
    if (!_compInfo || !c_compInfo) {
      message.error('No Nets found to automatically match.')
      return;
    }
    const { pcb, comp: anotherComp, compInfo, pcbKey: anotherKey } = _compInfo;
    let matchNets = [];
    const autoType = this.getAutoMatch();
    if (autoType === BY_NETS) {
      const pins = compInfo && compInfo.pins ? [...compInfo.pins.values()] : [];
      const nets = [...new Set(pins.map(i => i.net))];
      if (!nets.length) {
        message.error('No Nets found to automatically match.')
        return;
      }
      const netsSimilar = nets.map(_net => ({ net: _net, similar: getArraySimilar(net, _net) })).filter(_net => _net.similar > 0.5).sort((a, b) => b.similar - a.similar);
      if (!netsSimilar.length) {
        message.error('No Nets found to automatically match.')
        return;
      }
      matchNets = [netsSimilar[0].net]
    } else if (autoType === BY_PINS) {
      const { pcb: c_pcb } = c_compInfo;
      const { nets } = checkConnectCompsPinsAndNets({ designId: c_pcb, name: comp, nets: [net] }, { designId: pcb, name: anotherComp })
      matchNets = [...nets];
    }

    if (!matchNets.length) {
      message.error('No Nets found to automatically match.')
      return;
    }

    let _nets = this.state.nets;
    for (let matchNet of matchNets) {
      const _key = `${anotherType}@${anotherKey}@${anotherComp}@${matchNet}`
      const ele = document.getElementById(`line@${key}@${_key}`);
      const _ele = document.getElementById(`line@${_key}@${key}`);
      if (!ele && !_ele) {
        const start = this.pointLocation[key] || null;
        const end = this.pointLocation[_key] || null;
        if (!start || !end) {
          message.error(`Net ${matchNet} draw failed.`)
          continue;
        }
        this.connectionLine.drawLines(`line@${key}@${_key}`, { x: type === 'next' ? start.x + 8 : start.x, y: start.y, _x: type === 'next' ? end.x : end.x + 8, _y: end.y })
        const newNet = type === 'next' ? [net, matchNet] : [matchNet, net];
        _nets.push(newNet);
      }
    }

    this.setState({
      nets: _nets,
      save: true
    }, () => {
      this.props.saveNets && this.props.saveNets()
    })
  }

  setAutoConnectionNets = () => {
    const type = 'next', anotherType = 'prev';
    const { netsInfo, autoConnection } = this.props;
    const _compInfo = netsInfo ? netsInfo[anotherType] : null;
    const c_compInfo = netsInfo ? netsInfo[type] : null;

    const { pcb, comp, compInfo, pcbKey } = c_compInfo;
    const { pcb: anotherPcb, comp: anotherComp, compInfo: anotherCompInfo, pcbKey: anotherKey } = _compInfo;
    const pins = compInfo && compInfo.pins ? [...compInfo.pins.values()] : [];
    const nets = [...new Set(pins.map(item => item.net).filter(net => autoConnection.includes(net)))];
    let _nets = [];
    this.removeAllLines();

    for (let net of nets) {
      let matchNet = '';
      const autoType = this.getAutoMatch();
      if (autoType === BY_NETS) {
        const pins = anotherCompInfo && anotherCompInfo.pins ? [...anotherCompInfo.pins.values()] : [];
        const nets = [...new Set(pins.map(i => i.net))];
        if (!nets.length) {
          continue;
        }
        const netsSimilar = nets.map(_net => ({ net: _net, similar: getArraySimilar(net, _net) })).filter(_net => _net.similar > 0.5).sort((a, b) => b.similar - a.similar);
        if (!netsSimilar.length) {
          continue;
        }
        matchNet = netsSimilar[0].net
      } else if (autoType === BY_PINS) {
        const { nets = [] } = checkConnectCompsPinsAndNets({ designId: pcb, name: comp, nets: [net] }, { designId: anotherPcb, name: anotherComp })
        if (nets.length) {
          matchNet = nets[0];
        }
      }

      if (matchNet) {
        const _key = `${anotherType}@${anotherKey}@${anotherComp}@${matchNet}`
        const key = `${type}@${pcbKey}@${comp}@${net}`
        const ele = document.getElementById(`line@${key}@${_key}`);
        const _ele = document.getElementById(`line@${_key}@${key}`);
        if (!ele && !_ele) {
          const start = this.pointLocation[key] || null;
          const end = this.pointLocation[_key] || null;
          if (!start || !end) {
            message.error(`Net ${matchNet} draw failed.`)
            continue;
          }
          this.connectionLine.drawLines(`line@${key}@${_key}`, { x: type === 'next' ? start.x + 8 : start.x, y: start.y, _x: type === 'next' ? end.x : end.x + 8, _y: end.y })
          const newNet = type === 'next' ? [net, matchNet] : [matchNet, net];
          _nets.push(newNet);
        }
      }
    }

    this.setState({
      nets: _nets,
      sortNets: _nets.flat(),
      save: true
    }, () => {
      this.props.saveNets && this.props.saveNets()
    })
  }

  overClick = (key, endX, endY) => {
    this.setState({
      drawLine: false
    })
    const { nets } = this.state;
    let _nets = [...nets];
    const keys = Object.keys(this.pointLocation);
    const [type, pcbKey, comp, net] = key.split('@');
    const start = this.pointLocation[key] || {}
    let x = start.x || 0, y = start.y || 0;
    for (let _key of keys) {
      let value = this.pointLocation[_key];
      const { x: _x, y: _y } = value;
      if (key === _key) {
        continue;
      }
      if (Math.abs(endX - _x) <= 10 && Math.abs(endY - _y) <= 10) {
        const [_type, _pcbKey, _comp, _net] = _key.split('@');
        if (_type !== type && _pcbKey !== pcbKey) {
          const ele = document.getElementById(`line@${key}@${_key}`);
          const _ele = document.getElementById(`line@${_key}@${key}`);
          if (!ele && !_ele) {
            this.connectionLine.drawLines(`line@${key}@${_key}`, { x: type === 'next' ? x + 8 : x, y, _x: type === 'next' ? _x : _x + 8, _y })
            const newNet = type === 'next' ? [net, _net] : [_net, net];
            _nets.push(newNet);
          }
        }
      }
    }
    this.setState({
      nets: _nets,
      save: true
    }, () => {
      this.props.saveNets && this.props.saveNets()
    })
  }

  selectLine = (key) => {
    const { selectNets } = this.state;
    const _key = key.replace(/[^A-Za-z0-9]/ig, '');
    const ele = document.getElementById(_key);
    if (selectNets.includes(key)) {
      ele.classList.remove('cascade-nets-select')
      this.setState({
        selectNets: selectNets.filter(i => i !== key)
      })
    } else {
      ele.classList.add('cascade-nets-select')
      this.setState({
        selectNets: [...selectNets, key]
      })
    }
  }

  setPointLocation = (redraw = false, auto = false) => {
    const points = document.getElementsByClassName('connection-net-button');
    this.pointLocation = {};
    if (points) {
      for (let point of points) {
        let y = point.offsetTop, x = point.offsetLeft;
        let _point = point;
        while (point.offsetParent) {
          _point = _point.offsetParent;
          x = x + _point.offsetLeft;
          y = y + _point.offsetTop;
          if (_point.id === "cascade-net-connection-content") {
            break;
          }
        }
        this.pointLocation[point.id] = { x: x - 10, y: y - 10 }
      }
    }
    redraw && this.redrawLine();
    auto && this.setAutoConnectionNets()
  }

  redrawLine = () => {
    const { nets } = this.state;
    const { netsInfo } = this.props;
    const { next, prev } = netsInfo;
    for (let i = 0; i < nets.length; i++) {
      const netGroup = nets[i];
      const [nextNet, prevNet] = netGroup;
      const id = `next@${next.pcbKey}@${next.comp}@${nextNet}`;
      let location = this.pointLocation[id];
      const _id = `prev@${prev.pcbKey}@${prev.comp}@${prevNet}`;
      let nextLocation = this.pointLocation[_id];
      this.connectionLine.removeLine(`line@${_id}@${id}`);
      if (!location || !nextLocation) {
        continue;
      }
      if (nextLocation) {
        this.connectionLine.drawLines(`line@${id}@${_id}`, { x: location.x + 8, y: location.y, _x: nextLocation.x, _y: nextLocation.y })
      }
    }
  }

  removeNetLine = () => {
    const { selectNets, nets } = this.state;
    let _nets = [...nets];
    for (let key of selectNets) {
      const pNet = strDelimited(key, '@', { returnIndex: 4 })
      const nNet = strDelimited(key, '@', { returnIndex: 8 })
      _nets = _nets.filter(item => !(item[0] === pNet && item[1] === nNet) && !(item[0] === nNet && item[1] === pNet));
      this.connectionLine.removeLine(key);
    }
    this.setState({
      nets: _nets,
      selectNets: [],
      save: true
    }, () => {
      this.props.saveNets && this.props.saveNets()
    })
  }

  removeAllLines = () => {
    this.connectionLine && this.connectionLine.removeAllLines();
  }

  render() {
    const { drawLine, selectNets, open } = this.state;
    const { netsInfo, contentHeight, contentWidth } = this.props;
    const { next, prev } = netsInfo;
    return (
      <div className="cascade-connection-content cascade-net-connection-content" id="cascade-net-connection-content">
        <div className="cascade-connection-column cascade-net-connection-column">
          {this.showNetsRender(next)}
        </div>
        <div className="cascade-connection-column cascade-net-connection-column">
          {this.showNetsRender(prev)}
        </div>
        <SettingOutlined
          className="cascade-connection-nets-setting"
          onClick={this.openConnectionSetting} />
        {selectNets.length ? <span className="cascade-net-connection-delete" onClick={this.removeNetLine}>Delete Connection</span> : <span className="cascade-net-connection-delete" onClick={this.autoMatchAllLine}>Auto Connection</span>}
        <svg ref={this.svgRef} className={`plot ${drawLine ? 'cascade-connect-draw-line-status' : ''}`} style={{ width: contentWidth - 20, height: contentHeight - 20 }}></svg>
        {open && <NetsConnectionSetting closeModal={this.closeConnectionSetting} />}
      </div>
    );
  }
}


export default netsConnection;
