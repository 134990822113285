import React, { Component } from 'react';
import EditableTable from '../EditableTable/EditableTable';
import { CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { VC_SHUNT_TYPES } from '../../services/VirtualComponent';

const columns = [
  {
    title: 'Component',
    dataIndex: 'name',
    width: '18%',
  }, {
    title: 'Layer',
    dataIndex: 'layer',
    width: '14%',
  },/*  {
    title: 'Location',
    dataIndex: 'location',
    width: '36%'
  }, */
  {
    title: 'Signals',
    dataIndex: 'signals',
    width: '36%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: '12%'
  },
  {
    title: "Trace cut length",
    dataIndex: 'cutLength',
    width: '24%'
  }
];

class VirtualCompEditTable extends Component {
  constructor(props) {
    super(props);

    columns[0].title = () => {
      const { viewVirtualComps, componentList } = this.props;
      const comps = componentList.map(item => item.name);
      const hideComps = componentList.filter(item => !viewVirtualComps.includes(item.name));
      return (
        <div>
          <span className='virtual-component-comps'>Components</span>
          {componentList.length ? <EyeOutlined
            title="Open for viewing and editing"
            onClick={(e) => this.showVirtualComps({ e, comps, type: !hideComps.length ? "delete" : "add" })}
            className={!hideComps.length ? "virtual-component-view-selected-icon virtual-component-view-icon" : 'virtual-component-view-icon'} />
            : null}
          {componentList.length ? <DeleteOutlined
            title="Remove all virtual components"
            onClick={(e) => this.deleteVirtualComps(e)}
            className='virtual-component-clean-icon' />
            : null}
        </div>
      );
    }

    columns[0].render = (text, record) => {
      const { openedVirtualComps } = this.props;
      const isShow = openedVirtualComps.includes(record.name);
      return (
        <div
          onClick={(e) => this.openedVirtualCompEditing({ e, name: record.name, type: isShow ? "delete" : "add" })}
        >
          <span className='virtual-component-comps'>{text}</span>
          {/* <Icon
            type="eye"
            onClick={(e) => this.showVirtualComps(e, [record.name], isShow ? "delete" : "add")}
            className={isShow ? "virtual-component-view-selected-icon virtual-component-view-icon" : 'virtual-component-view-icon'} /> */}
          {record.errors && record.errors.length ? <ExclamationCircleOutlined
            title="Warning"
            onClick={(e) => this.showVirtualCompsErrors(e, record.errors, record.name)}
            className='virtual-component-view-icon' /> : null}
        </div>
      );
    }
    //locations
    /* columns[2].render = (text, record) => {
      const locationList = (record.location || []).map(item => { return item[0] && item[1] ? `( ${item[0].toFixed(2)}, ${item[1].toFixed(2)} )` : "" }).filter(item => !!item);
      return <span>{locationList.join(", ")}</span>
    } */

    //signals
    columns[2].render = (text, record) => {
      const signals = (record.pins || []).filter(item => item.pin.match(/_1$/)).map(item => { return item.pin.replace(/_1$/, ""); });
      return <span>{signals.join(", ")}</span>
    }

    columns[4].title = () => {
      const { componentList } = this.props;
      const units = [...new Set(componentList.map(item => item.unit))]
      return <span>Trace cut length {units.length === 1 ? `(${units[0]})` : ''}</span>
    }

    columns[4].render = (text, record) => {
      const { cutLength, unit } = record;
      const { componentList } = this.props;
      const units = [...new Set(componentList.map(item => item.unit))]
      return (
        <div className='virtual-component-edit-cut-length'>
          {VC_SHUNT_TYPES.includes(record.type) ? null : <span>{cutLength}{units.length > 1 ? unit : ""}</span>}
          <CloseOutlined
            className='signal-delete-icon'
            onClick={(e) => this.deleteVirtualComps(e, record.name)} />
        </div>
      );
    }

    columns[4].onCell = (record) => {
      return {
        edit: false,
        tdClassName: VC_SHUNT_TYPES.includes(record.type) ? "virtual-components-disabled-td" : ""
      }
    }
  }

  showVirtualComps = ({ e, comps, type }) => {
    e && e.stopPropagation();
    const _comps = type === "add" ? comps : [];
    this.props._updateViewVirtualComps({ comps: _comps, isUpdateCanvas: true })
  }

  openedVirtualCompEditing = ({ e, name, type }) => {
    e && e.stopPropagation();
    const comps = type === "add" ? [name] : [];
    this.props._updateViewVirtualCompsPanel({ comps, isUpdateCanvas: true })
  }

  deleteVirtualComps = (e, name) => {
    e && e.stopPropagation();
    this.props._delVirtualComps(name)
  }

  showVirtualCompsErrors = (e, errors, name) => {
    e && e.stopPropagation();
    this.props.showErrors(errors, name)
  }

  render = () => {
    const { componentList, openedVirtualComps } = this.props;
    return <div className='virtual-component-table-main'>
      <EditableTable
        rowKey={record => record.name}
        rowClassName={record => {
          if (openedVirtualComps.includes(record.name)) {
            return 'virtual-component-selected-row'
          }
        }}
        columns={columns}
        locale={{
          emptyText: "Click inside the layout to draw virtual component."
        }}
        size="small"
        dataSource={componentList}
        scroll={componentList.length > 8 ? { y: 312 } : {}}
        className='virtual-component-table virtual-component-edit-table'
      />
    </div>
  }
}

export default VirtualCompEditTable;