import React, { PureComponent } from "react";
import { Select } from "antd";
import { SERDES_TYPES } from "@/services/PCBHelper";
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';

class ChannelCompSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      component: ""
    }
  }

  closeModal = () => {
    const { components, isEndToEnd } = this.props;
    const { component } = this.state;
    let comp = null;
    if (isEndToEnd) {
      comp = components.find(item => component === `PCB_${item.pcbIndex} - ${item.name}`);
    } else {
      comp = components.find(item => item.name === component);
    }

    this.props.closePanel(comp);
    setTimeout(() => {
      this.setState({ component: "" })
    }, 300)
  }

  selectChange = (key) => {
    this.setState({
      component: key
    }, () => {
      key && this.closeModal();
    })
  }

  getICComps = () => {
    const { components = [], isEndToEnd } = this.props;
    if (isEndToEnd) {
      return JSON.parse(JSON.stringify(components)).map(item => { item.name = `PCB_${item.pcbIndex} - ${item.name}`; return item; });
    } else {
      return components.filter(item => SERDES_TYPES.includes(item.type));
    }
  }

  render() {

    const { component } = this.state;
    const ICComps = this.getICComps();
    return <div className='channel-component-main'>
      <Select 
        className="aurora-select channel-select-component-select"
        value={component}
        onChange={this.selectChange}
        popupClassName='aurora-select-dropdown'
        allowClear
      >
        {ICComps.map(item =>
          <Select.Option key={item.name} value={item.name}>
            {item.name}
          </Select.Option>)}
      </Select>
    </div>
  }
}

export default ChannelCompSelect;