import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getAllCascadeComponents, getAllCascadeNets } from '@/services/Cascade/helper/setupData';
import { changeResistanceTableLoading, changePCB } from '../store/DCR/action';
import projectDesigns from '@/services/helper/projectDesigns';
import Resistance from './Resistance';
import PCBSelect from '../components/PCBSelect';
import LoopResistance from './LoopResistance';
import designConstructor from '../../../services/helper/designConstructor';
import './index.css'

class DCRContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Components: [],
      nets: [],
      loading: true
    }
  }

  componentDidMount = () => {
    this.getNets();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.designId !== prevProps.designId) {
      this.getNets();
    }
  }

  getNets = async () => {
    let Components = [], nets = [];
    const { designId } = this.props;
    try {
      if (designId) {
        Components = getAllCascadeComponents({ pcbId: designId })
        nets = getAllCascadeNets({ pcbId: designId })
      }
    } catch (error) { }
    this.setState({
      Components,
      nets,
      loading: false
    })
  }

  render() {
    const { Components, loading, nets } = this.state;
    const { designId, projectId, designStatus, pcbUpdate, designName } = this.props;
    const noPCB = designId && !projectDesigns.getDesignExist(projectId, designId)
    const _designType = designConstructor.getDesignType(designId);
    return <Fragment>
      <div className='cascade-DCR-content'>
        <div className='cascade-setup-border'>
          <PCBSelect
            pcbId={designId}
            projectId={projectId}
            selectPCB={this.props.changePCB}
            noPCB={noPCB}
            designStatus={designStatus}
            pcbUpdate={pcbUpdate}
            designName={designName}
            includePkg={true}
            designType={_designType}
          />
        </div>
        <Resistance
          Components={Components}
          loading={loading}
          nets={nets}
          designId={noPCB ? '' : designId}
          designStatus={designStatus}
          noPCB={noPCB} />
        <LoopResistance
          loading={loading}
          nets={nets} />
        <div id="DCR-content-dialog"></div>
      </div>
    </Fragment>
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { viewList, openProjectId, designStatus, treeItems, pcbUpdate },
    DCR: { designId, designName } } } = state
  return {
    viewList,
    projectId: openProjectId,
    designId,
    designStatus,
    treeItems,
    pcbUpdate,
    designName
  };
}

const mapDispatch = (dispatch) => ({
  _changeResistanceTableLoading(loading) {
    dispatch(changeResistanceTableLoading(loading))
  },
  changePCB(designId) {
    dispatch(changePCB(designId))
  }
})

export default connect(mapState, mapDispatch)(DCRContent);