import React, { Component } from "react";
import { createPortal } from "react-dom";
import { Checkbox } from "antd";
import Panel from "../../../components/Panel";
import SPICEFileSelect from "./components/SPICEFileSelect";
import {
  drawTxModelHelper,
  getDrawer,
} from "../../../services/Andes_v2/simulation/canvasDrawer";
import { BUFFER_SPICE, SPICE } from "../../../constants/libraryConstants";
import SubcktNodeSelect from "./components/SubcktNodeSelect";
import {
  handleSelectSubckt,
  getNodeSelectOption,
  getSubcktByPairInfo,
  handleSelectFile,
  getFileTreeData,
} from "../../../services/Andes_v2/simulation";

/**
 * TxModel: Only the selection of one spice model file is supported
 */
class TxModel extends Component {
  constructor() {
    super();
    this.state = {
      applyAll: false,
      fileTreeData: [],
      subckt: {},
      nodeVisible: "",
      model: {
        type: SPICE,
        files: [],
        pairs: [],
      },
    };
    this.dialogRoot = document.getElementById("root");
  }
  async componentDidMount() {
    const { model } = this.props;
    const { drawer, canvas } = getDrawer(
      "andes-v2-hspice-simulation-tx-model-canvas",
      null,
      { left: 1.5, top: 1.5 }
    );

    this.drawer = drawer;
    this.canvas = canvas;
    drawTxModelHelper({ drawer, canvas });
    document.addEventListener("mousedown", this.handleClickOutside, true);
    this.setState({
      model: JSON.parse(JSON.stringify(model)),
      fileTreeData: await getFileTreeData([SPICE, BUFFER_SPICE], model.files)
    }, () => {
      this.isMountedFlag = true;
    });
  }

  componentWillUnmount() {
    this.isMountedFlag = false;
    document.removeEventListener("mousedown", this.handleClickOutside, true);
  }

  async componentDidUpdate(prevProps) {
    const { libraryStatus, model } = this.props;
    if (prevProps.libraryStatus !== libraryStatus) {
      this.setState({
        fileTreeData: await getFileTreeData([SPICE, BUFFER_SPICE], model.files)
      });
    }
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { nodeVisible } = this.state;
    const PopoverRoot = document.getElementById(nodeVisible);

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || !PopoverRoot.contains(target)) {
      this.setState({ nodeVisible: "" });
    }
  };

  closeModal = () => {
    const { model, applyAll } = this.state;
    if (this.isMountedFlag) {
      this.props.closeModal(model, applyAll);
    }
  };

  selectModelFile = (file, fileIndex) => {
    const { model } = this.state;
    const { files, pairs } = handleSelectFile(
      "txModel",
      model,
      file,
      fileIndex
    );
    this.setState({ model: { ...model, files, pairs }, subckt: {} });
  };

  selectSubckt = (subckt, modelKey, file) => {
    const { model } = this.state;
    const { files, pairs } = handleSelectSubckt(
      "txModel",
      model,
      subckt,
      modelKey,
      file
    );
    this.setState({ model: { ...model, files, pairs }, subckt });
  };

  selectNode = (node, pairIndex) => {
    const { model } = this.state;
    const _pairs = [...model.pairs],
      file = model.files[0];
    _pairs[pairIndex].node = node;
    _pairs[pairIndex].fileName = file.fileName;
    _pairs[pairIndex].libraryId = file.libraryId;
    _pairs[pairIndex].subckt = file.subckt;
    this.setState({ model: { ...model, pairs: _pairs }, nodeVisible: "" });
  };

  applyChange = (e) => {
    this.setState({ applyAll: e.target.checked });
  };

  clearNodeVisible = () => {
    this.setState({ nodeVisible: "" });
  };

  setNodeVisible = async (visible, pair) => {
    const {
      subckt,
      model: { files = [] },
    } = this.state;
    const fileInfo = pair.node ? pair : files[0];
    if (visible && !Object.keys(subckt).length && fileInfo) {
      const subcktInfo = await getSubcktByPairInfo(fileInfo);
      this.setState({ subckt: subcktInfo });
    }
    this.setState({ nodeVisible: visible });
  };

  updateFileTreeData = (children) => {
    const { fileTreeData } = this.state;
    this.setState({ fileTreeData: fileTreeData.concat(children) })
  }

  render() {
    const {
      applyAll,
      subckt,
      nodeVisible,
      model: { files = [], pairs = [] },
      fileTreeData
    } = this.state;
    const { signalName } = this.props;
    const nodeList = getNodeSelectOption("txModel", pairs, subckt);
    const content = (
      <Panel
        className="component-CMC-model-panel"
        position="panel-center-left"
        title="Tx Model"
        zIndex={2000}
        onCancel={() => this.closeModal()}
        width={600}
        draggable
        minHeight={200}
      >
        <div className="andes-v2-hspice-simulation-model-content">
          {files.length !== 0 ? (
            files.map((file, index) => (<div key={`${file.libraryId}-${index}` || `rx-model-${index}`}>
              <SPICEFileSelect
                fileTreeData={fileTreeData}
                selectModelFile={(file) => this.selectModelFile(file, index)}
                selectSubckt={(subckt) => this.selectSubckt(subckt, file.modelKey, file)}
                updateFileTreeData={this.updateFileTreeData}
                file={file}
              />
            </div>
            ))) : <SPICEFileSelect
            fileTreeData={fileTreeData}
            selectModelFile={(file) => this.selectModelFile(file, -1)}
            selectSubckt={(subckt) => this.selectSubckt(subckt, -1)}
            updateFileTreeData={this.updateFileTreeData}
            file={{}}
          />}
          {/* modal - node */}
          <div className="andes-v2-hspice-simulation-tx-model-canvas-box">
            {/* Node select */}
            {pairs.map((item, index) => (
              <div
                key={`tx-model-${item.pin}`}
                className="andes-v2-hspice-simulation-tx-model-node"
              >
                <SubcktNodeSelect
                  nodeList={nodeList}
                  displayPin={item.pin}
                  nodeValue={item.node}
                  selectNode={(node) => this.selectNode(node, index)}
                  visible={nodeVisible === `tx-model-subckt-node-popover-${item.pin}`}
                  clickNode={() => this.setNodeVisible(`tx-model-subckt-node-popover-${item.pin}`, item)}
                  closeNodelSelect={() => this.clearNodeVisible()}
                  id={`tx-model-subckt-node-popover-${item.pin}`}
                />
              </div>
            ))}
            {/* Net text */}
            {pairs.map((item) => (
              <span key={`tx-model-${item.net}`}>{item.net}</span>
            ))}
            {/* Signal */}
            <span className="andes-v2-hspice-simulation-tx-model-signal-name andes-v2-hspice-no-wrap">{signalName}</span>
            <canvas id="andes-v2-hspice-simulation-tx-model-canvas" width="600" height="200"></canvas>
          </div>
          {/* modal - apply all */}
          {<div className="ads-model-apply-all-content">
            <span>Apply setting to all Signal</span>
            <Checkbox
              checked={applyAll}
              onChange={(e) => this.applyChange(e)}
            />
          </div>}
        </div>
      </Panel>
    );
    return createPortal(content, this.dialogRoot);
  }
}

export default TxModel;
