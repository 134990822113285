import React, { Component, Fragment } from 'react';
import { Input, Tag } from 'antd';
import './index.css';
import UnitAddonAfter from '../UnitAddonAfter';

class TagsInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      inputUnit: "",
      inputFocus: false
    }
  }

  addonAfter = (unit) => {
    const { unitOptions, type } = this.props;
    if (!unitOptions.length) {
      return unit;
    }
    return UnitAddonAfter({
      id: `tag-unit-select-${type}-id`,
      popupClassName: `tag-unit-select-unit-${type}-dropdown`,
      unit,
      changeUnit: (key) => this.changeUnit(key),
      list: unitOptions
    });
  }

  changeUnit = (unit) => {
    this.setState({ inputUnit: unit })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { type, tagInputBlur } = this.props;
    const InputRoot = document.getElementById(`tag-input-${type}-id`);
    const unitSelectRoot = document.getElementById(`tag-unit-select-${type}-id`);
    const unitSelectDrop = document.getElementsByClassName(`tag-unit-select-unit-${type}-dropdown`);

    if (!InputRoot) {
      return;
    }

    if (!InputRoot.contains(target)
      && (!unitSelectRoot || !unitSelectRoot.contains(target))
      && (!unitSelectDrop || !unitSelectDrop[0] || !unitSelectDrop[0].contains(target))) {
      this.onPressEnter({ target: { value: this.state.inputValue } }, type);
      this.setState({
        focusClassName: null,
        inputFocus: false
      }, () => {
        tagInputBlur && tagInputBlur(type);
      });
    }
  }

  inputClick = () => {
    const { type } = this.props;
    const { inputFocus } = this.state;
    if (inputFocus) {
      return;
    }
    if (this[`${type}Ref`] && this[`${type}Ref`].focus) {
      this[`${type}Ref`].focus()
      this.setState({
        focusClassName: type,
        inputFocus: true
      })
    }
  }

  inputChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    this.setState({
      inputValue: value === ',' ? "" : value,
      focusClassName: this.props.type
    }, () => {
      if (this.props.inputChange) {
        this.props.inputChange(this.state.inputValue)
      }
    });
  }

  onPressEnter = (e) => {
    e.stopPropagation && e.stopPropagation();
    const value = e.target.value;
    const { tagList, unit, type, allowedDuplicates } = this.props;
    const { focusClassName, inputUnit } = this.state;
    if (!value) {
      return;
    }
    const _value = inputUnit || unit ? `${value} ${inputUnit || unit}` : value;
    const _tagList = allowedDuplicates ? [...tagList, _value] : [...new Set([...tagList, _value])];
    this.setState({
      inputValue: "",
      inputUnit: ""
    });
    this.props.changeTagList(type, _tagList, focusClassName, _value);
    if (this.props.inputChange) {
      this.props.inputChange("")
    }
  }

  handleDelete = (value, index) => {//delete item
    const { tagList, type } = this.props;
    if (!value && value !== 0) {
      return;
    }
    const _tagList = tagList.filter((item, i) => item !== value || index !== i);
    this.props.changeTagList(type, _tagList);
  }

  onKeyDown = (e) => {
    if (e.keyCode === 188) {
      this.onPressEnter(e);
    }
  }

  inputBlur = (e) => {
    const { type } = this.props;
    const InputRoot = document.getElementById(`tag-input-${type}-id`);
    const unitSelectRoot = document.getElementById(`tag-unit-select-${type}-id`);
    const unitSelectDrop = document.getElementsByClassName(`tag-unit-select-unit-${type}-dropdown`);
    if (e.target
      &&
      ((InputRoot && InputRoot.contains(e.target))
        || (unitSelectRoot && unitSelectRoot.contains(e.target))
        || (unitSelectDrop && unitSelectDrop[0] && unitSelectDrop[0].contains(e.target)))) {
      return;
    }

    this.onPressEnter(e);
    this.setState({
      inputFocus: false
    })
  }

  inputOnFocus = () => {
    this.setState({ inputFocus: true });
  }

  render = () => {
    const { type, tagList, className, displayInput, disabled, width, isAddonAfter, unit, isSuffix, customInput, closable = true, addonAfterUnit, placeholder = "" } = this.props;
    const { inputValue, focusClassName, inputUnit, inputFocus } = this.state;
    const boxShadow = 'rgba(9, 128, 247, 0.14) 0px 0px 0px 2px, rgba(13, 151, 249, 0.1) 0px 0px 7px 0px';
    const disabledClassName = disabled ? "tab-input-disabled" : "";
    const _inputUnit = inputUnit || unit;
    const unitWidth = _inputUnit ? 90 : 0;

    return <Fragment>
      <div
        className={`aurora-tags-input-content ${className} ${disabledClassName} ${addonAfterUnit ? "aurora-tags-input-unit-content" : ""}`}
        id={`tag-input-${type}-id`}
        style={focusClassName ? { borderColor: '#40a9ff', boxShadow, width } : { width }}
        onClick={(e) => !disabled ? this.inputClick(e) : null}
      >
        <ul className='tags-input-list-ul' onClick={(e) => !disabled ? this.inputClick(e) : null}>
          {displayInput && !focusClassName ?
            <span title={tagList.join(", ")}>{tagList.join(", ")}</span>
            : tagList.length || !placeholder ? tagList.map((tag, index) =>
              <li className='tags-input-tag' key={`${tag}_${index}`}>
                <Tag
                  key={tag}
                  closable={closable}
                  onClose={() => this.handleDelete(tag, index)}
                >
                  {tag}
                </Tag>
              </li>
            ) : !inputValue ? <span className='tags-input-list-placeholder'>{placeholder}</span> : null
          }
          <li className='tags-input-tag-input-li' key={`${type}-input`}>
            {customInput ? customInput :
              <Input
                className='tags-input-tag-input'
                value={inputValue}
                style={{ width: inputValue || unitWidth ? (inputValue.length * 12 || 30) + unitWidth : '.75em' }}
                ref={(input) => { this[`${type}Ref`] = input; }}
                onChange={(e) => this.inputChange(e)}
                onPressEnter={(e) => this.onPressEnter(e)}
                onBlur={(e) => this.inputBlur(e)}
                onKeyDown={(e) => this.onKeyDown(e)}
                /*onFocus={this.inputOnFocus}*/
                addonAfter={inputFocus && isAddonAfter ? this.addonAfter(_inputUnit) : null}
              />}
          </li>
          {isSuffix ? isSuffix : null}
        </ul>
      </div>
      {/* todo unit select */}
      {addonAfterUnit ? <div className='aurora-tag-input-after-unit-content'>
        {addonAfterUnit}
      </div> : null}
      {/* {addonAfterUnit ? UnitAddonAfter({
        unit: addonAfterUnit,
        changeUnit: null,
        list: []
      }) : null} */}
    </Fragment>
  }
}


export default TagsInput;