import React, { Component, Fragment } from 'react';
import { Input } from 'antd';
import { CANVAS_PADDING, COMP_HEIGHT, CanvasPlot, SIGNAL_DISTANCE, SIGNAL_TO_COMP, calcPortsLocation, getCanvasCompWidth } from '../../../../services/Sierra/prelayout';
import debounce from '../../../../services/helper/debounceFn';
import Port from './port';
import './index.css'
import { numberCheck } from '../../../../services/helper/dataProcess';
import { PORT_DISTANCE } from '../../../../services/Sierra/prelayout/prelayoutConstants';

class PreLayoutCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 800,
      caclWidth: 1000,
      resValue: {},
      volValue: {}
    }
  }

  componentDidMount() {
    this.getHeight(true);
    this.getResValue();
  }

  componentDidUpdate = (prevProps) => {
    const { width, canvasUpdate, components } = this.props;
    if (width !== prevProps.width) {
      this.getHeight(false);
    }

    if (canvasUpdate && canvasUpdate !== prevProps.canvasUpdate) {
      this.brush && this.brush.clear();
      this.getHeight(true);
      this.getResValue();
    }

    if (components && prevProps.components && components.length !== prevProps.components.length) {
      this.getResValue();
    }
  }

  getResValue = () => {
    const { components } = this.props;
    let resValue = {}, volValue = {};
    components.forEach(comp => {
      resValue[comp.name] = {
        value: comp.value,
        input: comp.value ? false : true
      }
      volValue[comp.name] = {
        voltage: comp.voltage,
        input: comp.voltage ? false : true,
        group: comp.group
      }
    })
    this.setState({
      resValue,
      volValue
    })
  }

  getHeight = (init) => {
    const { signals, components } = this.props;
    const height = (signals.length - 1) * SIGNAL_DISTANCE + 2 * CANVAS_PADDING + SIGNAL_TO_COMP + COMP_HEIGHT; // signal num * 100 + top padding + bottom padding + signal to comp + comp height
    const minWidth = 60 + components.map(comp => getCanvasCompWidth(comp, signals)).reduce((total, value) => total + value, 0);// left padding + right padding + comp width
    this.setState({
      height,
      caclWidth: minWidth
    }, () => {
      init ? this.initPlot() : this.resetPlot();
    })
  }

  initPlot = () => {
    const { width, id, components, signals } = this.props;
    const { height, caclWidth } = this.state;
    this.brush = new CanvasPlot({ id: `pre-layout-canvas-${id}`, width: caclWidth > width ? caclWidth : width, height, components, signals });
    this.draw();
  }

  resetPlot = () => {
    if (!this.brush) {
      this.initPlot();
    } else {
      const { width, components, signals } = this.props;
      const { height, caclWidth } = this.state;
      this.brush.setNewData({ width: caclWidth > width ? caclWidth : width, height, components, signals });
      this.draw();
    }
  }

  draw = () => {
    const { id } = this.props;
    debounce(() => {
      this.brush.clear();
      this.brush.draw();
      this.props.updateSchematicLoading(false);
      this.props.updateCanvasStatus(false);
    }, 1000, false, `pre-layout-canvas-${id}`)();
  }

  openResInput = (e, comp) => {
    e && e.stopPropagation();
    const { resValue } = this.state;
    this.setState({
      resValue: {
        ...resValue,
        [comp]: {
          value: resValue[comp] ? resValue[comp].value : '',
          input: true
        }
      }
    }, () => {
      this.input && this.input.focus();
    })
  }

  openVolInput = (e, comp) => {
    e && e.stopPropagation();
    const { volValue } = this.state;
    this.setState({
      volValue: {
        ...volValue,
        [comp]: {
          voltage: volValue[comp] ? volValue[comp].voltage : '',
          input: true,
          group: volValue[comp] ? volValue[comp].group : '',
        }
      }
    }, () => {
      this.input && this.input.focus();
    })
  }

  closeResInput = (comp) => {
    const { resValue } = this.state;
    let _resValue = { ...resValue }
    let value = _resValue[comp].value;
    const error = numberCheck(value);
    if (error) {
      const { components } = this.props;
      const findComp = components.find(item => item.name === comp);
      value = findComp ? findComp.value || '' : '';
      _resValue[comp].value = value;
    }
    _resValue[comp].input = value ? false : true;
    this.props.updateResValue(comp, value);
    this.setState({
      resValue: _resValue
    })
  }

  closeVolInput = (comp) => {
    const { volValue } = this.state;
    let _volValue = { ...volValue }
    let voltage = _volValue[comp].voltage;
    let group = _volValue[comp].group;
    const error = numberCheck(voltage);
    if (error) {
      const { components } = this.props;
      const findComp = components.find(item => item.name === comp);
      voltage = findComp ? findComp.voltage || '' : '';
      _volValue[comp].voltage = voltage;
    }
    const keys = Object.keys(_volValue);
    for (let key of keys) {
      if (_volValue[key] && _volValue[key].group === group) {
        _volValue[key].input = voltage ? false : true;
        _volValue[key].voltage = voltage;
      }
    }
    this.props.updateVolValue(comp, voltage);
    this.setState({
      volValue: _volValue
    })
  }

  changeResValue = (e, comp) => {
    this.setState({
      resValue: {
        ...this.state.resValue,
        [comp]: {
          value: e.target.value,
          input: true
        }
      }
    })
  }

  changeVolValue = (e, comp) => {
    this.setState({
      volValue: {
        ...this.state.volValue,
        [comp]: {
          ...(this.state.volValue[comp] || {}),
          voltage: e.target.value,
          input: true,
        }
      }
    })
  }

  portsRender = () => {
    const { width, components, signals, loading } = this.props;
    const { caclWidth } = this.state;
    const portsLocation = calcPortsLocation(components, signals, caclWidth > width ? caclWidth : width);
    const resLocation = portsLocation.filter(item => item.comp.match(/^R[0-9]+$/g)).map(item => ({ ...item, y: Number(item.y) + 25 }))
    const volLocation = portsLocation.filter(item => item.comp.match(/^R[0-9]+$/g)).map(item => ({ ...item, y: Number(item.y) + COMP_HEIGHT - 5 - PORT_DISTANCE }))
    return loading ? null : [
      ...portsLocation.map((port, index) => this.portRender(port, index)),
      ...resLocation.map((res, index) => this.resRender(res, index)),
      ...volLocation.map((vol, index) => this.volRender(vol, index))
    ];
  }

  portRender = (port, index) => {
    const { x, y } = port;
    const { libraries, portsObj, model, fixed, lastOpenSubckt, components } = this.props;
    const models = components.map(item => item.pins.map(pin => pin.model)).flat(3).filter(item => item.port);
    return <div key={index} className="port-select" style={{ left: x - 25, top: y }}>
      <Port
        pin={port}
        index={index}
        libraries={libraries}
        portsObj={portsObj}
        usedPorts={models}
        model={model}
        lastOpenSubckt={lastOpenSubckt}
        saveModel={this.props.saveModel}
        selectOpenSubckt={this.props.selectOpenSubckt}
        fixed={fixed}
      />
    </div>
  }

  resRender = (port, index) => {
    const { x, y, comp } = port;
    const { resValue } = this.state;
    const value = resValue[comp] ? resValue[comp].value : '';
    const open = resValue[comp] ? resValue[comp].input : true;
    return <div key={`res-${index}`} className="port-select" style={{ left: x - 25, top: y }}>
      <div className='spice-pin-die-value-box sierra-pre-layout-value-box'>
        {
          value && !open ?
            <div className='spice-pin-input sierra-pre-layout-cursor' title={`${comp} - ${value}Ω`} onClick={(e) => this.openResInput(e, comp)}>
              <span>{value}Ω</span>
            </div>
            : <Fragment>
              <Input
                className='spice-pin-input'
                placeholder={'Res'}
                ref={(node) => this.input = node}
                value={value}
                onChange={(e) => this.changeResValue(e, comp)}
                onBlur={() => this.closeResInput(comp)}
                onPressEnter={() => this.closeResInput(comp)}
              />
              <span className='sierra-pre-layout-empty-unit'> Ω</span>
            </Fragment>
        }
      </div>
    </div>
  }

  volRender = (port, index) => {
    const { x, y, comp } = port;
    const { volValue } = this.state;
    const value = volValue[comp] ? volValue[comp].voltage : '';
    const open = volValue[comp] ? volValue[comp].input : true;
    return <div key={`vol-${index}`} className="port-select" style={{ left: x - 25, top: y }}>
      <div className='spice-pin-die-value-box sierra-pre-layout-value-box'>
        {
          value && !open ?
            <div className='spice-pin-input sierra-pre-layout-cursor' title={`${comp} - ${value}V`} onClick={(e) => this.openVolInput(e, comp)}>
              <span>{value}V</span>
            </div>
            : <Fragment>
              <Input
                className='spice-pin-input'
                placeholder={'Vol'}
                ref={(node) => this.input = node}
                value={value}
                onChange={(e) => this.changeVolValue(e, comp)}
                onBlur={() => this.closeVolInput(comp)}
                onPressEnter={() => this.closeVolInput(comp)}
              />
              <span className='sierra-pre-layout-empty-unit'> V</span>
            </Fragment>
        }
      </div>
    </div>
  }

  render() {
    const { id, width } = this.props;
    const { height, caclWidth } = this.state;
    return <div className='sierra-pre-layout-canvas' id={`pre-layout-${id}`} style={caclWidth > width ? { overflowX: 'auto' } : {}}>
      {this.portsRender()}
      <canvas id={`pre-layout-canvas-${id}`} className="pre-layout-canvas" width={caclWidth > width ? caclWidth : width} height={height} />
    </div>
  }
}

export default PreLayoutCanvas;