import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import './pdnPanel.css';

class DecapTouchstonePanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.dialogRoot = document.getElementById('root');
  }

  render() {
    const { decapTouchstoneFileInfo, decapTouchstoneinfo } = this.props;
    const content = (
      <Panel
        className="PDN-show-models"
        position='panel-center-left'
        title={decapTouchstoneinfo}
        zIndex={2000}
        onCancel={() => this.props.closeModal('decapTouchstone')}
        width={830}
        draggable
        minHeight={200}
      >
        <pre className="show-model-content">{decapTouchstoneFileInfo}</pre>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default DecapTouchstonePanel;