import React, { Component } from 'react';
import Table from '@/components/EditableTable';
import { numberCheck } from "@/services/helper/dataProcess";
import { CAP, IND, JUMPER, RES } from '../../constants/componentType';
import { SIERRA } from '../../constants/pageType';
import RLCCompModel from '../RLCCompModel';
import { formatRLCValue, getModelTypeName, getModelValue, getRLCModel } from '../../services/RLCModel';
import { PASSIVE_SPICE, PASSIVE_TOUCHSTONE } from '../../constants/libraryConstants';
import _ from "lodash";
import auroraDBJson from '../../services/Designs/auroraDbData';
import { RetweetOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './index.css';

const ComponentsColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '20%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Components',
  dataIndex: 'name',
  width: '25%',
}, {
  title: 'Usage',
  dataIndex: 'usage',
  width: '10%',
  sorter: (a, b) => a.usage.localeCompare(b.usage),
}, {
  title: 'DC Resistance Value',
  dataIndex: 'value',
  width: '30%',
}];
class PassiveTable extends Component {
  constructor(props) {
    super(props)
    const branchs = (props.powerTrees || []).map(item => item.tree.branch);
    const partNames = branchs.flat(3).map(item => item.part);
    this.state = {
      designId: props.designId,
      partNames: [...new Set(partNames)],
      dataSource: [],
      save: false,
      passiveSpiceList: [],
      passiveTouchstoneList: [],
      partNumberInfo: {},
      displayPartNumber: false
    }
  }

  componentDidMount() {
    this.props.onRef(this);
    this.initColumns();
    this.getTableData();
    const { product } = this.props;
    if (product === SIERRA) {
      this.getLibraryList()
    }
  }

  initColumns = () => {

    ComponentsColumns[0].title = () => {
      const { displayPartNumber, partNumberInfo } = this.state;
      return <div>
        <span className='passive-table-part-title'>Part {displayPartNumber ? "Number" : "Name"}</span>
        {Object.keys(partNumberInfo).length ? <Tooltip
          title='Toggle to display part name or part number.'
          mouseLeaveDelay={0}
          mouseEnterDelay={0.3}
          overlayClassName='icon-tooltip'
        >
          <RetweetOutlined
            className="sierra-comp-repeater-part-switch-icon"
            onClick={(e) => this.changePartDisplay(e)}
          />
        </Tooltip> : null}
      </div>
    }

    ComponentsColumns[0].render = (text, record) => {
      const { partNames, displayPartNumber, partNumberInfo } = this.state;
      const partText = displayPartNumber && partNumberInfo[record.part] ? partNumberInfo[record.part] : record.part;

      return <div className='passive-table-part-name-text' style={partNames.includes(record.part) ? { fontWeight: 'bold' } : {}}>
        {partText}
      </div>;
    }

    ComponentsColumns[1].render = (text, record) => {
      return <div style={{ maxHeight: 200, overflow: 'auto' }}>
        {text && text.length ? text.join(', ') : ''}
      </div>;
    }

    ComponentsColumns[3].title = () => {
      const { product } = this.props;
      return product === SIERRA ? "Value / Model" : "DC Resistance Value";
    }

    ComponentsColumns[3].render = (text, record) => {
      let unit = record.unit || '';
      const { product } = this.props;
      if (product === SIERRA) {
        const originUnit = this.getDisplayUnit(record.usage)
        const value = record.usage === CAP ? record.value : `${record.value}${record.unit}`
        return <div>
          {formatRLCValue(record.usage, value, record.model, originUnit)}
        </div>
      }

      let content = `${text} ${unit}Ω`;
      return <div>
        {content}
      </div>
    }

    ComponentsColumns[2].onCell = (record) => {
      const { product } = this.props;
      if (product === SIERRA) {
        return {
          record,
          edit: "select",
          dataIndex: 'usage',
          options: [RES, IND, CAP, JUMPER],
          handleSave: this.compUsageUpdate,
        }
      } else {
        return { edit: false }
      }
    }

    ComponentsColumns[3].onCell = (record) => {
      const { product } = this.props;
      if (product === SIERRA) {
        const originUnit = this.getDisplayUnit(record.usage)
        const value = record.usage === CAP ? record.value : `${record.value}${record.unit}`;
        const { passiveSpiceList, passiveTouchstoneList } = this.state;
        const { getLibraryFileContent, parseSpiceModelSelector } = this.props;
        return {
          edit: true,
          text: <span>{formatRLCValue(record.usage, value, record.model, originUnit)}</span>,
          record: {
            ...record,
            type: record.usage,
            model: getRLCModel({ ...record, type: record.usage, value }),
            comps: [{ name: record.name && record.name.length ? record.name[0] : "", pins: (record.pins || []).map(item => { return { pin: item } }) }]
          },
          customInput: RLCCompModel,
          dataIndex: 'model',
          powerNets: [],
          isPassiveTable: true,
          saveRLCModel: this.saveRLCModel,
          spiceList: passiveSpiceList,
          touchstoneList: passiveTouchstoneList,
          getFileContent: getLibraryFileContent,
          parseModelSelector: parseSpiceModelSelector,
          product: SIERRA
        }
      }
      return {
        record,
        edit: 'input-with-select',
        dataIndex: 'value',
        handleSave: this.resistanceUpdate,
        unitAddonAfter: 'Ω',
        unitList: [{ key: "m", title: 'mΩ' }, { key: "", title: 'Ω' }, { key: "K", title: 'KΩ' }, { key: "M", title: 'MΩ' }]
      }
    }
  }

  changePartDisplay = (e) => {
    e && e.stopPropagation();
    this.setState({
      displayPartNumber: !this.state.displayPartNumber
    })
  }

  getDisplayUnit = (usage, type) => {
    let unit = "", unitList = [];
    if (usage === RES) {
      unit = "Ω";
      unitList = [{ key: "m", title: 'mΩ' }, { key: "", title: 'Ω' }, { key: "K", title: 'KΩ' }, { key: "M", title: 'MΩ' }]
    } else if (usage === IND) {
      unit = "H";
      unitList = [{ key: "", title: "H" }, { key: "u", title: "uH" }, { key: "n", title: "nH" }, { key: "p", title: "pH" }]
    } else if (usage === CAP) {
      unit = "F";
      unitList = [{ key: "", title: "F" }, { key: "u", title: "uF" }, { key: "n", title: "nF" }, { key: "p", title: "pF" }]
    }
    return type === "UNITS" ? unitList : unit;
  }

  compUsageUpdate = (record) => {
    const { part, usage } = record;
    const { dataSource } = this.state;
    let _dataSource = [...dataSource];
    const index = _dataSource.findIndex(item => item.part === part);
    if (index < 0) {
      return;
    }

    if (usage === CAP) {
      _dataSource[index].value = { r: "0", l: "0", c: "0" };
    } else if ([RES, IND, JUMPER].includes(usage)) {
      _dataSource[index].value = "0";
    }

    _dataSource[index].unit = "";
    _dataSource[index].usage = usage;
    this.setState({
      dataSource: _dataSource,
      save: true
    })
  }

  saveRLCModel = (model, record, unit) => {
    const { part } = record;
    const { dataSource } = this.state;
    let _dataSource = [...dataSource];
    const index = _dataSource.findIndex(item => item.part === part);
    if (index < 0) {
      return;
    }
    const _model = { type: getModelTypeName(model.type), ...model.passiveModel };
    const value = getModelValue(record.usage, model.value);

    _dataSource[index].value = value;
    _dataSource[index].model = _model;
    if (record.usage !== CAP) {
      _dataSource[index].unit = unit;
    }

    this.setState({
      dataSource: _dataSource,
      save: true
    })
  }

  componentDidUpdate(prevProps) {
    const { designId, update, libraryTreeInfo, product } = this.props;
    if (designId !== prevProps.designId || (update && update !== prevProps.update)) {
      this.setState({
        designId: designId
      }, () => {
        this.getTableData()
      })
    }

    if (product === SIERRA
      && libraryTreeInfo
      && libraryTreeInfo.length && (libraryTreeInfo.includes(PASSIVE_SPICE) || libraryTreeInfo.includes(PASSIVE_TOUCHSTONE))
      && (!prevProps.libraryTreeInfo || libraryTreeInfo.length !== prevProps.libraryTreeInfo.length
        || !_.isEqual(prevProps.libraryTreeInfo, libraryTreeInfo)
      )
    ) {
      this.getLibraryList()
    }
  }

  getLibraryList = () => {
    const { getLibraryList } = this.props;
    if (!getLibraryList) {
      return;
    }
    this.setState({
      passiveSpiceList: getLibraryList(PASSIVE_SPICE),
      passiveTouchstoneList: getLibraryList(PASSIVE_TOUCHSTONE)
    })
  }

  getTableData = async () => {
    const { designId, partNames } = this.state;
    if (designId) {
      await this.props.setDefaultData();
      const data = await this.props.tableStore.getTable(designId);
      const table = data && data.table ? data.table.sort((a, b) => !partNames.includes(b.part) && partNames.includes(a.part) ? -1 : 1) : [];

      let partNumberInfo = {};
      for (let item of table) {
        const partNumber = auroraDBJson.getPartNumberByPartName(designId, item.part);
        partNumberInfo[item.part] = partNumber;
      }
      this.setState({
        dataSource: table,
        partNumberInfo
      }, () => {
        this.props.changeLoading(false)
      })
    }
  }

  resistanceUpdate = (record) => {
    const { part, value, unit } = record;
    if (value) {
      const error = numberCheck(value);
      if (error) {
        return;
      }
    }

    const { dataSource } = this.state;
    let _dataSource = [...dataSource];
    const findIndex = _dataSource.findIndex(item => item.part === part);
    if (findIndex > -1) {
      _dataSource[findIndex].value = value;
      _dataSource[findIndex].unit = unit
      this.props.changeSave(true);
      this.setState({
        dataSource: _dataSource,
        save: true
      })
    }
  }

  saveTable = async () => {
    const { designId, dataSource, save } = this.state;
    const { product } = this.props;
    if (save) {
      const version = await this.props.tableStore.getVersion(designId);
      let tableData = dataSource;
      if (product === SIERRA) {
        tableData = dataSource.map(item => { return { ...item, value: item.value && !isNaN(Number(item.value)) ? Number(item.value) : item.value } })
      }
      await this.props.tableStore.updateTable([{ designId, componentTable: { table: tableData, version } }]);
    }
  }

  render() {
    const { dataSource } = this.state;
    return <div className='component-pcb-passive-table'>
      <Table
        columns={ComponentsColumns}
        dataSource={dataSource}
        rowKey={record => `${record.part}-${record.usage}`}
        scroll={dataSource.length > 9 ? { y: 780 } : {}}
        size="small"
      />
    </div>
  }
}

export default PassiveTable;