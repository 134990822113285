/**
 * class StringList
 */
const StringList = function () {
  this.mValues = [];
};

StringList.prototype.GetJSArrary = function () {
  return this.mValues;
};

StringList.prototype.Join = function (delimiter) {
  if (this.isEmpty())
    return null;

  var retStr = "";
  for (var i = 0; i < this.mValues.length; i++) {
    retStr = retStr + this.mValues[i];
    if (i < this.mValues.length - 1)
      retStr = retStr + delimiter;
  }
  return retStr;
}

StringList.prototype.push = function (item) {
  this.add(item);
}

StringList.prototype.add = function (item) {
  if (item == null || item == undefined) {
    console.log("Push an undefined in StringList");
  }

  if (typeof item === 'string')
    this.mValues.push(item);
  else if (item instanceof StringList) {
    for (var i = 0; i < item.size(); i++) {
      this.mValues.push(item.get(i));
    }
  } else if (item instanceof Array) {
    this.mValues.push(item.join(', '));
  } else
    this.mValues.push(item.toString());
}

StringList.prototype.shift = function () {
  return this.mValues.shift();
}

StringList.prototype.pop_front = function () {
  this.mValues.splice(0, 1);
}

StringList.prototype.push_front = function (item) {
  this.mValues.unshift(item);
}

StringList.prototype.SubStringList = function (fromPos, toPos) {
  var subStrlist = new StringList();
  for (var i = fromPos; i < toPos; i++) {
    subStrlist.push(this.mValues[i]);
  }
  return subStrlist;
}

StringList.prototype.GenerateUniqueString = function (baseName) {
  var retStr;
  var count = 0;
  while (true) {
    var tmpStr;
    if (baseName != null)
      tmpStr = baseName + count;
    else
      tmpStr = count.toString();
    var bUnique = true;
    for (var item in this.mValues) {
      if (item == tmpStr) {
        bUnique = false;
        break;
      }
    }
    if (bUnique) {
      retStr = tmpStr;
      break;
    }
    count++;
  }
  return retStr;
}

StringList.prototype.size = function () {
  return this.mValues.length;
}

StringList.prototype.isEmpty = function () {
  return this.mValues.length == 0;
}

StringList.prototype.get = function (i) {
  return this.mValues[i];
}

StringList.prototype.contains = function (item) {
  return this.mValues.indexOf(item) > -1;
}

StringList.prototype.CleanEmptyItem = function () {
  var newVal = [];
  for (var i = 0; i < this.mValues.length; i++) {
    var item = this.mValues[i].trim();
    if (item.length > 0)
      newVal.push(this.mValues[i]);
  }
  if (newVal.length < this.mValues.length)
    this.mValues = newVal;
}

export default StringList;