/* import { forceSimulation, forceLink, forceManyBody, forceCenter } from 'd3-force'; */
import { select, event, selectAll, mouse } from 'd3-selection';
import { drag } from 'd3-drag';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { getColor } from '.';
import { getTextWidth } from '../helper/getTextWidth';
import ElementsCanvas from './elementsCanvas';
import _ from "lodash";
import sierraLibrary from '../Sierra/library/libraryStorage';
import { TRACE } from '../../constants/libraryConstants';
import { RES } from '../PCBHelper';

const LEFT = "left", RIGHT = "right", TOP = "top", BOTTOM = "bottom";



class SchematicCanvas {
  constructor(props) {
    const { elementsSvg, schematicSvg, elementsTypes, width, height, top, rects, lines, container, action = {}, isSweep, isPuppeteer } = props;
    this.elementsSvg = elementsSvg;
    this.svgElement = schematicSvg;
    this.width = width || 800;
    this.height = height || 900;
    this.rects = rects || [];
    this.lines = lines || [];
    this.rectWidth = 3;//gird number
    this.rectHeight = 2;//gird number
    this.top = top;
    this.elementsTypes = elementsTypes || [];
    this.action = action || {};
    this.rectUpdate = action.rectUpdate;
    this.rectDelete = action.rectDelete;
    this.openRectBox = action.openRectBox;
    this.elements = null;
    this.createdEle = null;
    this.containerLines = [];
    this.isSweep = isSweep;
    this.isPuppeteer = isPuppeteer;
    this.initContainer({ width, height, container });
  }

  initContainer = ({ width, height, container }) => {
    this.width = width || 800;
    this.height = height || 900;
    this.xDomain = this.initDomain(this.width);
    this.yDomain = this.initDomain(this.height);
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
    this.xGridSize = this.width / this.xDomain;
    this.yGridSize = this.height / this.yDomain;
    //140 is elements width + 10, (x,y) is left top coordinates
    this.container = {
      x: Math.ceil(140 / this.xGridSize),
      y: 1,
      width: container && container.width ? Number(container.width) : 22, //gird number
      height: container && container.height ? Number(container.height) : 16, //gird number
    }
  }

  getRectPointColorByLine = (lineName) => {
    const line = this.lines.find(item => item.name === lineName) || {};
    return line.color || "";
  }

  getPointColorByLine = (pointName) => {
    const line = this.lines.find(item => item.sections
      && item.sections.find(it => (it.in && it.in.component === pointName) || (it.out && it.out.component === pointName))) || {};
    return line.color || "";
  }

  getSectionPointByLine = (pointName, sections) => {
    const line = sections.find(it => (it.in && it.in.type === "point" && it.in.component === pointName) || (it.out && it.out.type === "point" && it.out.component === pointName));
    return line ? true : false;
  }

  drawElements = () => {
    this.elements = new ElementsCanvas({
      svgElement: this.elementsSvg,
      types: this.elementsTypes,
      top: this.top,
      createElement: this.createElement,
      saveElement: this.saveElement,
      updateElementLocation: this.updateElementLocation,
    });
    this.elements.draw();
  }

  createElement = ({ key, x, y }) => {
    const newRect = this.action.createElement({ key, x, y });
    this.newRect = JSON.parse(JSON.stringify(newRect));
    this.drawRects([newRect], "coord");
  }

  saveElement = ({ x, y }) => {
    const newX = this.xSetScale(x);
    const newY = this.ySetScale(y);
    if (isNaN(newX) || isNaN(newY) || this.limitBoundaries({ x: newX, y: newY, width: this.newRect.width, height: this.newRect.height })) {
      this.deleteRect(this.newRect)
      this.newRect = null;
      return;
    }
    const _newRect = JSON.parse(JSON.stringify(this.newRect));
    _newRect.location = [newX, newY];
    this.rects.push(_newRect);
    this.updateRects([_newRect], ["location"]);
    this.action.saveElement(_newRect);
    this.newRect = null;
  }

  getRectSize = ({ width, height, x, y, type = "all" }) => {
    let _width = width, _height = height, _x = x, _y = y;
    switch (type) {
      case "width":
        _width = (width || this.rectWidth) * this.xGridSize;
        break;
      case "height":
        _height = (height || this.rectHeight) * this.yGridSize;
        break;
      case "coord":
        _x = x * this.xGridSize;
        _y = y * this.yGridSize;
        break;
      case "size":
        _width = (width || this.rectWidth) * this.xGridSize;
        _height = (height || this.rectHeight) * this.yGridSize;
        break;
      case "all":
      default:
        _width = (width || this.rectWidth) * this.xGridSize;
        _height = (height || this.rectHeight) * this.yGridSize;
        _x = x * this.xGridSize;
        _y = y * this.yGridSize;
        break;
    }
    return { _width, _height, _x, _y };
  }

  limitBoundaries = ({ x, y, width, height, returnCoord = false }) => {
    if (!returnCoord) {
      if (x < this.leftTop[0] || x + width > this.rightTop[0] + width
        || y < this.leftTop[1] || y + height > this.leftBottom[1] + height) {
        return true;
      }
      return false;
    }
    let newX = x, newY = y;

    if (x < this.leftTop[0]) {
      newX = this.leftTop[0];
    }
    if (x + width > this.rightTop[0] + width) {
      newX = this.rightTop[0] - width;
    }
    if (y < this.leftTop[1]) {
      newY = this.leftTop[1];
    }
    if (y + height > this.leftBottom[1] + height) {
      newY = this.leftBottom[1] - height;
    }

    return { newX, newY }
  }

  updateElementLocation = ({ x, y }) => {
    if (!this.newRect) {
      return
    }
    this.newRect.location = [x, y];
    this.updateRects([this.newRect], ["location"], true);
  }

  updateCanvasContainer = (container) => {
    this.container = {
      ...this.container,
      width: container && container.width ? Number(container.width) : this.container.width,
      height: container && container.height ? Number(container.height) : this.container.height
    }
    this.drawGrid();
    this.drawContainer()
  }

  initDomain = (size) => {
    const remainder = size % 36;
    let domain = 0;
    if (remainder < 18) {
      domain = Math.floor(size / 36);
    } else {
      domain = Math.ceil(size / 36);
    }
    return domain;
  }

  clear = () => {
    select(this.svgElement).selectAll('*').remove();
    this.elements.clear();
  }

  getNewAxis = (num, type) => {
    return type === 'x' ? this.xScale(num) : this.yScale(num);
  }

  xSetScale = (x, isCoord) => {
    const remainder = x % this.xGridSize;
    let newX = x;
    if (this.xGridSize / 2 > remainder) {
      newX = Math.floor(x / this.xGridSize);
    } else {
      newX = Math.ceil(x / this.xGridSize);
    }
    return isCoord ? newX * this.xGridSize : newX;
  }

  ySetScale = (y, isCoord) => {
    const remainder = y % this.yGridSize;
    let newY = y;
    if (this.yGridSize / 2 > remainder) {
      newY = Math.floor(y / this.yGridSize);
    } else {
      newY = Math.ceil(y / this.yGridSize);
    }
    return isCoord ? newY * this.yGridSize : newY;
  }

  draw = () => {
    this.drawGridContainer();
    this.drawContainer();
    this.drawRects();
    this.drawLines();
    /*     this.drawSimulation() */
  }

  /*   drawSimulation = () => {
      let nodes = this.rects.map(item => {
        if (!item.location || !item.location.length) {
          return null;
        }
        return {
          id: item.name,
          x: item.location.x * this.xGridSize,
          y: item.location.y * this.yGridSize,
          width: item.width * this.xGridSize,
          height: item.height * this.yGridSize
        }
      }).filter(item => !!item);
  
      let links = [];
      for (let line of this.lines) {
        if (!line.points || !line.points.length) {
          for (let pointSection of line.points) {
            for (let i = 0; i < pointSection.points.length; i++) {
  
              const start = pointSection.points[i];
              const end = pointSection.points[i + 1];
  
              if (!start || !end) {
                continue;
              }
  
              if (start.type === "point") {
                nodes.push({
                  id: start.name,
                  x: start.location.x * this.xGridSize,
                  y: start.location.y * this.yGridSize
                })
              }
  
              links.push({
                source: start.name,
                target: end.name
              })
            }
          }
        }
      }
      this.simulation = forceSimulation(nodes)
        .force("link", forceLink(links).distance(100))
        .force("charge", forceManyBody().strength(-50))
        .force("center", forceCenter(this.container.width * this.xGridSize / 2, this.container.height * this.yGridSize / 2));
  
      this.simulation.stop();
  
    }
  
    simulationTick = () => {
      this.simulation.tick();
      selectAll("line.line-item")
        .attr("x1", d => {return d.source.x })
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);
  
      selectAll("rect.canvas-rect-item")
        .attr("x", d => d.x)
        .attr("y", d => d.y);
  
      selectAll("circle.line-point")
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
  
    } */

  drawGridContainer = () => {
    select(this.svgElement).selectAll('*').remove();
    // Create SVG container
    this.svgRoot = select(this.svgElement);
    this.svgRoot.append("g")
      .attr("id", "grid-main");
    this.drawGrid();

    const plot = this;
    this.svgRoot.on('click', function () {
      if (event.defaultPrevented) return;
      //remove
      if (!event.target) return;
      const targetElement = select(event.target);
      const classNames = targetElement.attr("class") || "";
      const hasValidClass = ["canvas-rect-item", "canvas-rect-text-item"].some(key => classNames.includes(key));
      if (!hasValidClass) {
        plot.clearAllRectChildren();
      }
      const hasRemoveClass = ["line-item-remove-line"].some(key => classNames.includes(key));
      if (!hasRemoveClass) {
        selectAll(`rect.line-item-remove-line`).remove();
        selectAll(`text.line-item-remove-line`).remove();
      }
    });
  }

  drawGrid = () => {
    this.xScale = scaleLinear().domain([0, this.xDomain]).range([this.margin.left, this.width - this.margin.right]);
    this.yScale = scaleLinear().domain([0, this.yDomain]).range([this.height - this.margin.bottom, this.margin.top]);
    // create Grid
    let gridMain = this.svgRoot.select("g#grid-main");

    if (gridMain.empty()) {
      gridMain = this.svgRoot.append("g")
        .attr("id", "grid-main");
    } else {
      gridMain.selectAll("g.grid").remove();
    }

    gridMain.append("g")
      .attr("class", "grid")
      .selectAll("line")
      .data(this.yScale.ticks(this.yDomain))
      .enter().append("line")
      .attr("class", "grid-line")
      .attr("x1", this.margin.left)
      .attr("x2", this.width - this.margin.right)
      .attr("stroke", "#d5d5d5")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", 2)
      .attr("y1", d => this.yScale(d))
      .attr("y2", d => this.yScale(d));

    gridMain.append("g")
      .attr("class", "grid")
      .selectAll("line")
      .data(this.xScale.ticks(this.xDomain))
      .enter().append("line")
      .attr("class", "grid-line")
      .attr("stroke", "#d5d5d5")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", 2)
      .attr("y1", this.margin.top)
      .attr("y2", this.height - this.margin.bottom)
      .attr("x1", d => this.xScale(d))
      .attr("x2", d => this.xScale(d));
  }

  drawContainer = () => {
    this.svgRoot.select("g.container-g").remove()  //remove old

    this.leftTop = [this.container.x, this.container.y];
    this.rightTop = [this.container.x + this.container.width, this.container.y];
    this.leftBottom = [this.container.x, this.container.y + this.container.height];
    this.rightBottom = [this.rightTop[0], this.leftBottom[1]];

    this.coordLeftTop = [this.leftTop[0] * this.xGridSize, this.leftTop[1] * this.yGridSize];
    this.coordRightTop = [this.rightTop[0] * this.xGridSize, this.rightTop[1] * this.yGridSize];
    this.coordLeftBottom = [this.leftBottom[0] * this.xGridSize, this.leftBottom[1] * this.yGridSize];
    this.coordRightBottom = [this.rightBottom[0] * this.xGridSize, this.rightBottom[1] * this.yGridSize];

    this.containerLines = [
      [this.coordLeftTop, this.coordRightTop],
      [this.coordLeftTop, this.coordLeftBottom],
      [this.coordLeftBottom, this.coordRightBottom],
      [this.coordRightTop, this.coordRightBottom]
    ]
    const container = this.svgRoot.append("g")
      .attr("class", "container-g");
    container.selectAll("line")
      .data(this.containerLines)
      .enter()
      .append('line')
      .attr("class", "container-line")
      .attr("x1", d => d[0][0])
      .attr("y1", d => d[0][1])
      .attr("x2", d => d[1][0])
      .attr("y2", d => d[1][1])
      .attr("stroke", "red")
      .attr("stroke-width", 1.5)
  }

  drawRects = (rects, locationType, isResList) => {
    //draw rects
    let canvasRect = this.svgRoot.select('g#canvas-rect');

    if (canvasRect.empty()) {
      canvasRect = this.svgRoot.append("g")
        .attr("id", "canvas-rect");
    }
    const plot = this;
    const _rects = rects || this.rects;

    if (_rects && _rects.length) {

      for (let item of _rects) {
        if (!item.location || !item.location.length) {
          continue;
        }

        const isCoord = locationType === "coord";
        const rectPoints = item.points || [];
        let width = item.width, height = item.height, dx = 0, dy = 0;
        /*         if (rectPoints.length && item.type !== RES) {
                  const pinLine = rectPoints[0].line;
                  let findPointLocation = null, hasRes = false;
                  const pointLocation = (item.points.find(it => it.location && it.location.length) || {}).location;
                  const dir = this.getRectPointsDir({ pointLocation, rectLocation: item.location, width: item.width, height: item.height });
         */
        /*    const findLine = [TOP, LEFT].includes(dir) ? this.lines.find(it => it.points && it.points.length && it.name === pinLine) || {} : {};
           const findInSection = (findLine.sections || []).find(it => (it.in && it.in.component === item.name) && it.out && it.out.type === "point"),
             findOutSection = (findLine.sections || []).find(it => (it.out && it.out.component === item.name) && it.in && it.in.type === "point");
 
           if (findInSection && [TOP, LEFT].includes(dir)) {
             const findPointSection = findLine.points.find(_it => _it.name === findInSection.id) || {};
             const findPoint = (findPointSection.points || []).find(_it => _it.name === findInSection.out.component);
             findPointLocation = findPoint && findPoint.location ? findPoint.location : [];
             const findOtherOutRects = findLine.sections.filter(_it => _it.in && _it.in === findInSection.out.component && _it.out).map(it => it.out.component);
             const findOtherInRects = findLine.sections.filter(_it => _it.out && _it.out === findInSection.out.component && _it.in).map(it => it.in.component);
             hasRes = this.rects.find(it => (findOtherOutRects.includes(it.name) || findOtherInRects.includes(it.name)) && it.type === RES);
 
           } else if (findOutSection && [TOP, LEFT].includes(dir)) {
             const findPointSection = findLine.points.find(item => item.name === findOutSection.id) || {};
             const findPoint = (findPointSection.points || []).find(item => item.name === findOutSection.in.component);
             findPointLocation = findPoint && findPoint.location ? findPoint.location : [];
             const findOtherOutRects = findLine.sections.filter(_it => _it.in && _it.in.component === findOutSection.in.component && _it.out).map(it => it.out.component);
             const findOtherInRects = findLine.sections.filter(_it => _it.out && _it.out.component === findOutSection.in.component && _it.in).map(it => it.in.component);
             hasRes = this.rects.find(it => (findOtherOutRects.includes(it.name) || findOtherInRects.includes(it.name)) && it.type === RES);
           } */
        /*   let pointNum = 1, lineLength = this.lines.length - 1;
          if ([TOP, BOTTOM].includes(dir)) {
            pointNum = Math.abs(pointLocation[0] - item.location[0]);
            const newWidth = rectPoints.length + pointNum;
            width = width < newWidth ? newWidth : width;
            if (hasRes && findPointLocation && item.location[1] < findPointLocation[1] + 3 * lineLength) {
              dy = 3 * lineLength;
            } else if (!hasRes && findPointLocation && item.location[1] < findPointLocation[1] + lineLength) {
              dy = lineLength;
            }
          }
          if ([LEFT, RIGHT].includes(dir)) {
            pointNum = Math.abs(pointLocation[1] - item.location[1])
            const newHeight = rectPoints.length + pointNum;
            height = height < newHeight ? newHeight : height;
            if (hasRes && findPointLocation && item.location[0] < findPointLocation[0] + 3 * lineLength) {
              dx = 3 * lineLength;
            } else if (!hasRes && findPointLocation && item.location[0] < findPointLocation[0] + lineLength) {
              dx = lineLength;
            }
          }
        } */
        const { _width, _height, _x, _y } = this.getRectSize({
          width: width,
          height: height,
          x: item.location[0] + dx,
          y: item.location[1] + dy,
          type: isCoord ? "size" : "all"
        });
        const x = isCoord ? item.location[0] || 0 : _x,
          y = isCoord ? item.location[1] || 0 : _y;
        canvasRect.select(`text.text-${item.name}`).remove();
        canvasRect.select(`rect.rect-${item.name}`).remove();
        canvasRect.select(`g#rect-children-${item.name}`).remove();
        canvasRect
          .append("rect")
          .attr("class", `rect-${item.name} canvas-rect-item`)
          .attr("x", x)
          .attr("y", y)
          .attr('rx', 6)
          .attr('ry', 6)
          .attr("rectName", item.name)
          .attr("width", _width)
          .attr("height", _height)
          .attr("fill", "none")
          .attr("stroke", getColor(item.type))
          .attr("stroke-width", 3)
          .style("cursor", "pointer")
          .attr("pointer-events", "all")
          .on("click", () => {
            if (this.isSweep || this.isPuppeteer) {
              return;
            }
            const data = this.rects.find(it => it.name === item.name);
            this.clickRect(data || item, item.names)
          })
          .on("mouseover", function () {
            select(this).style("cursor", "pointer");
            if (plot.isSweep || plot.isPuppeteer) {
              return;
            }
            plot.closeRectIcon(item, item.names);
            if (isResList || rectPoints.length) {
              return
            }

            if (!item.points || !item.points.length) {
              plot.drawLineConnPoints(item)
            }
            if (plot.currentLine && plot.currentLine.points && plot.currentLine.start && plot.currentLine.start.name !== item.name) {
              const lineConnPoints = plot.getRectLineConnPoints(item);
              let findPoint = null, linePoints = [];
              for (let point of lineConnPoints) {
                if (plot.currentLine.start.type === point.type
                  || ([LEFT, RIGHT].includes(plot.currentLine.start.type) && [TOP, BOTTOM].includes(point.type))
                  || ([TOP, BOTTOM].includes(plot.currentLine.start.type) && [LEFT, RIGHT].includes(point.type))) {
                  continue;
                }

                if ((LEFT === plot.currentLine.start.type && plot.currentLine.start.x < point.x)
                  || (TOP === plot.currentLine.start.type && plot.currentLine.start.y < point.y)
                  || (RIGHT === plot.currentLine.start.type && plot.currentLine.start.y > point.y)
                  || (BOTTOM === plot.currentLine.start.type && plot.currentLine.start.y > point.y)) {
                  continue;
                }
                linePoints.push(point);
                // const min = calcDistance([plot.currentLine.start, x, plot.currentLine.start.y], [point.x, point.y])
                if (plot.currentLine.start.x === point.x || plot.currentLine.start.y === point.y) {
                  findPoint = point;
                  break;
                }
              }
              if (!findPoint && linePoints[0]) {
                findPoint = linePoints[0];
              }

              if (!findPoint) {
                return;
              }
              const { points, isStraightLine } = plot.calculateShortestPath({
                x: plot.currentLine.start.x,
                y: plot.currentLine.start.y,
                type: plot.currentLine.start.type
              },
                { x: findPoint.x, y: findPoint.y, type: findPoint.type, endType: "rect" },
                true);
              plot.currentLine.points = points;
              plot.currentLine.isStraightLine = isStraightLine;
              plot.currentLine.end = {
                endType: "rect",
                name: item.name,
                x: findPoint.x,
                y: findPoint.y,
                type: findPoint.type
              }
              plot.linePath && plot.linePath.attr("d", line().x(d => d.x * plot.xGridSize).y(d => d.y * plot.yGridSize)(plot.currentLine.points));
            }
          })
          .on("mouseout", function () {
            select(this).style("cursor", "");
            if (plot.isSweep || plot.isPuppeteer) {
              return;
            }
            const targetElement = event.relatedTarget ? select(event.relatedTarget) : null;
            const classNames = targetElement ? targetElement.attr("class") || "" : "";

            if (!classNames.includes(`rect-remove-text-${item.name}`) && !classNames.includes(`rect-line-point-item-${item.name}`)) {
              select(`text.rect-remove-text-${item.name}`).remove();
            }

            if (isResList || rectPoints.length) {
              return
            }

            if (classNames.includes(`rect-line-point-item-${item.name}`)) {
              return;
            }
            plot.removeDrawLinePoints(item.name)
          })
          .call(isResList || rectPoints.length || plot.isSweep || plot.isPuppeteer ? () => null : this.startRectDrag());


        const text = isResList && item.names ? item.names.join(", ") : item.name;
        canvasRect
          .append("text")
          .text(text)
          .attr("class", `text-${item.name} canvas-rect-text-item`)
          .attr('x', x + _width / 2 - getTextWidth(text, 14) / 2)
          .attr('y', y + _height / 2)
          .attr('dy', 6)
          .style("cursor", "pointer")
          .attr("rectName", item.name)
          .attr("font-weight", "500")
          .on("click", () => {
            if (this.isSweep || this.isPuppeteer) {
              return
            }
            const data = plot.rects.find(it => it.name === item.name);
            this.clickRect(data || item, item.names)
          })
          .call(isResList || rectPoints.length || plot.isSweep || plot.isPuppeteer ? () => null : this.startRectDrag());

        canvasRect.append("g")
          .attr("class", `rect-children-g`)
          .attr("id", `rect-children-${item.name}`)

        // this.drawRectPoints(item);
      }
    }
  }

  updateLines = (lines) => {
    this.lines = lines;
    this.drawLines();
  }

  drawLines = () => {
    let canvasLines = this.svgRoot.select('g#canvas-lines');
    const templateList = sierraLibrary.getTree(TRACE) || [];

    if (canvasLines.empty()) {
      canvasLines = this.svgRoot.append("g")
        .attr("id", "canvas-lines");
    }
    this.svgRoot.selectAll(`g.canvas-line-item`).remove();
    const plot = this;
    /* const lineNames = this.lines.map(item => item.name); */
    const lineItem = (this.lines || []).find(item => item.points && item.points.length);
    if (!lineItem) {
      return;
    }

    const _lines = [...this.lines.filter(item => item.name !== lineItem.name)];


    let lineEle = canvasLines.append("g")
      .attr("id", `canvas-line-${lineItem.name}`)
      .attr("class", `canvas-line-item`);

    let resRects = []

    const lineRects = this.rects.filter(item => item.points && item.points.find(it => it.signal === lineItem.name)).map(item => item.name);
    let lineNameLocation = null, pointLocationMap = [], connResPoints = {};
    const lineNames = this.lines.map(item => item.name);

    for (let pointSection of (lineItem.points || [])) {
      if (!pointSection.points || !pointSection.points.length) {
        continue;
      }
      let newSignalPoints = [];
      const section = lineItem.sections.find(item => item.id === pointSection.name) || {};
      let templateName = section && section.model ? (templateList.find(item => item.id === section.model.id) || {}).name : null;
      if (!templateName && section && section.model && section && section.model.name) {
        templateName = section.model.name;
      }
      /* const modelExist = section && section.model && section.model.id && !templateName; */
      const length = section ? section.length : null;
      let maxDistance = { distance: 0 };
      let newPoints = [];

      for (let i = 0; i < pointSection.points.length; i++) {

        const start = pointSection.points[i];
        const end = pointSection.points[i + 1];
        if (!start || !end) {
          continue;
        }

        let startSectionKey = "", endSectionKey = "";
        if (section.in.component === start.name) {
          startSectionKey = "in"
        }
        if (section.out.component === start.name) {
          startSectionKey = "out"
        }
        if (section.in.component === end.name) {
          endSectionKey = "in"
        }
        if (section.out.component === end.name) {
          endSectionKey = "out"
        }

        const { location: startLocation, dir: startDir, type: startType } = start.type === "component" ? this.findLineSectionPoints(start) : { location: start.location },
          { location: endLocation, dir: endDir, type: endType } = end.type === "component" ? this.findLineSectionPoints(end) : { location: end.location };

        if (!lineNameLocation) {
          lineNameLocation = [...startLocation]
        }

        const xDistance = Math.abs(endLocation[0] - startLocation[0]),
          yDistance = Math.abs(endLocation[1] - startLocation[1]);
        if (xDistance > 0 && maxDistance.distance < xDistance) {
          const minLocation = startLocation[0] < endLocation[0] ? startLocation : endLocation;
          maxDistance = { distance: xDistance, location: [...minLocation], dir: "X" }
        } else if (yDistance > 0 && maxDistance.distance < yDistance) {
          const minLocation = startLocation[1] < endLocation[1] ? startLocation : endLocation;
          maxDistance = { distance: yDistance, location: [...minLocation], dir: "Y" }
        }

        lineEle.append("line")
          .attr("class", `line-item-${lineItem.name}-${pointSection.name} line-item`)
          .attr("x1", startLocation[0] * this.xGridSize)
          .attr("y1", startLocation[1] * this.yGridSize)
          .attr("x2", endLocation[0] * this.xGridSize)
          .attr("y2", endLocation[1] * this.yGridSize)
          .attr("stroke-width", 5)
          .style("stroke", lineItem.color || "#1896ff")
          .style("cursor", "pointer")
          .on("mouseover", function () {
            select(this).attr("stroke-width", 8);
            if (plot.isSweep || plot.isPuppeteer) {
              return
            }
            if (plot.currentLine && plot.currentLine.points && plot.currentLine.start && !lineRects.includes(plot.currentLine.start.name)) {
              const [_x, _y] = mouse(plot.svgRoot.node());
              const x = plot.xSetScale(_x),
                y = plot.ySetScale(_y);
              const { points, isStraightLine } = plot.calculateShortestPath({
                x: plot.currentLine.start.x,
                y: plot.currentLine.start.y,
                type: plot.currentLine.start.type,
              },
                { x, y, endType: "line" },
                true);
              plot.currentLine.points = points;
              plot.currentLine.isStraightLine = isStraightLine;
              plot.currentLine.end = {
                endType: "line",
                name: lineItem.name,
                section,
                sectionPoints: { start, end },
                x,
                y
              }
              plot.linePath && plot.linePath.attr("d", line().x(d => d.x * plot.xGridSize).y(d => d.y * plot.yGridSize)(plot.currentLine.points));
            }
          })
          .on("mouseout", function () {
            select(this).attr("stroke-width", 5);
          })
          .on("click", () => {
            if (this.isSweep || this.isPuppeteer) {
              return;
            }
            this.selectLine(lineItem.name, pointSection.name)
          })
          .on("contextmenu", function () {
            event.preventDefault();
            if (plot.isSweep || plot.isPuppeteer) {
              return;
            }
            selectAll(`rect.line-item-${lineItem.name}-remove-line`).remove();
            selectAll(`text.line-item-${lineItem.name}-remove-line`).remove();
            const [x, y] = mouse(plot.svgRoot.node());
            lineEle.append("rect")
              .attr("class", `line-item-${lineItem.name}-remove-line line-item-remove-line`)
              .attr("x", x)
              .attr("y", y)
              .attr("fill", "#ffffff")
              .attr("stroke", "#f0f0f0")
              .style("cursor", "pointer")
              .attr("width", 142)
              .attr("height", 46);

            lineEle.append("text")
              .attr("class", `line-item-${lineItem.name}-remove-line line-item-remove-line`)
              .attr("x", x + 6)
              .attr("y", y + 16)
              .text("Remove This Section")
              .style("cursor", "pointer")
              .on("click", () => {
                plot.deleteLineSection(lineItem.name, section.id);
                selectAll(`.line-item-${lineItem.name}-remove-line`).remove();
              })

            lineEle.append("text")
              .attr("class", `line-item-${lineItem.name}-remove-line line-item-remove-line`)
              .attr("x", x + 6)
              .attr("y", y + 42)
              .style("cursor", "pointer")
              .text("Remove All Signals")
              .on("click", () => {
                if (plot.isSweep || plot.isPuppeteer) {
                  return;
                }
                plot.deleteLine(lineItem.name)
                selectAll(`line-item-${lineItem.name}-remove-line`).remove();
              })
          });

        let startIsPoint = false, endIsPoint = false;
        if (start.type === "point" && this.getSectionPointByLine(start.name, lineItem.sections) && !newPoints.find(item => item.name === start.name)) {
          newPoints.push({ ...start, sectionPoints: { start, end } })
          startIsPoint = true;
        }

        if (end.type === "point" && this.getSectionPointByLine(end.name, lineItem.sections) && !newPoints.find(item => item.name === end.name)) {
          newPoints.push({ ...end, sectionPoints: { start, end } })
          endIsPoint = true;
        }

        if (start.type === "component") {
          newPoints.push({ ...start, location: startLocation })
        }

        if (end.type === "component") {
          newPoints.push({ ...end, location: endLocation })
        }

        if (startType === RES && end.type === "point") {
          if (!connResPoints[end.name]) {
            connResPoints[end.name] = {}
          }
          if ([LEFT, RIGHT].includes(startDir)) {
            connResPoints[end.name].bottom = 3;
          }
          if ([TOP, BOTTOM].includes(startDir)) {
            connResPoints[end.name].right = 3;
          }

        }
        if (endType === RES && start.type === "point") {
          if (!connResPoints[start.name]) {
            connResPoints[start.name] = {}
          }
          if ([LEFT, RIGHT].includes(endDir)) {
            connResPoints[start.name].bottom = 3;
          }
          if ([TOP, BOTTOM].includes(startDir)) {
            connResPoints[start.name].right = 3;
          }
        }
        newSignalPoints.push({
          start,
          end,
          startLocation,
          startDir,
          endLocation,
          endDir,
          startIsPoint,
          endIsPoint,
          startType,
          endType,
          section,
          startSectionKey,
          endSectionKey
        })
      }
      this.drawLinePoints({ points: newPoints, lineItem, lineRects, section, i: 0 });
      pointLocationMap.push({ name: pointSection.name, points: newSignalPoints })

      if ((templateName || length) && maxDistance && maxDistance.location) {
        const /* lengthSize = getTextWidth(length || "", 14), */
          templateSize = getTextWidth(templateName || "", 14),
          allSize = getTextWidth(`${length || ""}, ${templateName || ""}`, 14);
        const gridSize = maxDistance.dir === "X" ? this.xGridSize : this.yGridSize;

        let x = maxDistance.dir === "X" ? (maxDistance.location[0] + maxDistance.distance / 2) * this.xGridSize : (maxDistance.location[0] + 0.2) * this.xGridSize,
          y = maxDistance.dir === "Y" ? (maxDistance.location[1] + maxDistance.distance / 2) * this.yGridSize : (maxDistance.location[1] + 0.2) * this.yGridSize;
        if (maxDistance.dir === "Y" || allSize > maxDistance.distance * gridSize * 1.1) {
          lineEle.append("text")
            .attr("class", `line-item-${lineItem.name}-${pointSection.name}-text`)
            .attr("x", maxDistance.dir === "X" ? x - templateSize / 2 : x + 4)
            .attr("y", maxDistance.dir === "Y" ? y - 15 : y + 14)
            .style("cursor", "pointer")
            .text(length || "")
            .on("click", () => {
              if (this.isSweep || this.isPuppeteer) {
                return;
              }
              this.selectLine(lineItem.name, pointSection.name)
            })
            .on("mouseover", function () {
              if (templateName && section.model && section.model.id && plot.action.showTemplateInfo) {
                const [x, y] = mouse(plot.svgRoot.node());
                plot.action.showTemplateInfo({ x, y, id: section.model.id, name: templateName, length })
              }
            })
            .on("mouseout", function () {
              plot.action.closeTemplateInfo()
            });;

          lineEle.append("text")
            .attr("class", `line-item-${lineItem.name}-${pointSection.name}-text`)
            .attr("x", maxDistance.dir === "X" ? x - templateSize / 2 : x + 4)
            .attr("y", maxDistance.dir === "Y" ? y + 1 : y + 30)
            .style("cursor", "pointer")
            .text(templateName || "")
            .on("click", () => {
              if (this.isSweep || this.isPuppeteer) {
                return;
              }
              this.selectLine(lineItem.name, pointSection.name)
            })
            .on("mouseover", function () {
              if (templateName && section.model && section.model.id && plot.action.showTemplateInfo) {
                const [x, y] = mouse(plot.svgRoot.node());
                plot.action.showTemplateInfo({ x, y, id: section.model.id, name: templateName, length })
              }
            })
            .on("mouseout", function () {
              plot.action.closeTemplateInfo()
            });;
        } else {
          lineEle.append("text")
            .attr("class", `line-item-${lineItem.name}-${pointSection.name}-text`)
            .attr("x", maxDistance.dir === "X" ? x - allSize / 2 : x + 4)
            .attr("y", maxDistance.dir === "Y" ? y - 15 : y + 14)
            .style("cursor", "pointer")
            .text(`${length || ""}, ${templateName || ""}`)
            .on("click", () => {
              if (this.isSweep || this.isPuppeteer) {
                return;
              }
              this.selectLine(lineItem.name, pointSection.name)
            })
            .on("mouseover", function () {
              if (templateName && section.model && section.model.id && plot.action.showTemplateInfo) {
                const [x, y] = mouse(plot.svgRoot.node());
                plot.action.showTemplateInfo({ x, y, id: section.model.id, name: templateName, length })
              }
            })
            .on("mouseout", function () {
              plot.action.closeTemplateInfo()
            });
        }
      }

      if (section.in && section.in.type === "component") {
        const findComp = this.rects.find(comp => comp.name === section.in.component);
        if (findComp && findComp.type === RES) {
          let resNames = [];
          for (let item of _lines) {
            const findS = item.sections.find(it => it.id === section.id);
            findS && findS.in && resNames.push(findS.in.component);
          }
          resRects.push({
            ...findComp,
            names: [findComp.name, ...resNames]
          })
        }
      }

      if (section.out && section.out.type === "component") {
        const findComp = this.rects.find(comp => comp.name === section.out.component);
        if (findComp && findComp.type === RES) {
          let resNames = [];
          for (let item of _lines) {
            const findS = item.sections.find(it => it.id === section.id);
            findS && findS.out && resNames.push(findS.out.component);
          }
          resRects.push({
            ...findComp,
            names: [findComp.name, ...resNames]
          })
        }
      }
    }
    this.drawLineName({ lineEle, lineItem, lineNameLocation, lineNames });
    if (resRects.length) {
      this.drawRects(resRects, false, true)
    }

    /*   for (let i = 0; i < _lines.length; i++) {
        const currLine = _lines[i];
  
        let currLineEle = canvasLines.append("g")
          .attr("id", `canvas-line-${currLine.name}`)
          .attr("class", `canvas-line-item`)
        let currLineNameLocation = null, resRects = [];
  
        for (let pointSection of (pointLocationMap || [])) {
          if (!pointSection.points || !pointSection.points.length) {
            continue;
          }
  
          const currSection = currLine.sections.find(item => item.id === pointSection.name) || {};
          let newPoints = [];
          for (let poiItem of pointSection.points) {
            const {
              start,
              end,
              startLocation,
              startDir,
              endLocation,
              endDir,
              startIsPoint,
              endIsPoint,
              startType,
              endType,
              startSectionKey,
              endSectionKey } = poiItem;
  
            if (!start || !end) {
              continue;
            }
            let dx = i + 1, dy = i + 1;
  
            let startDx = dx, endDx = dx, startDy = dy, endDy = dy;
  
            if (start.type === "point" && connResPoints[start.name]) {
              startDx = connResPoints[start.name].right || startDx;
              startDy = connResPoints[start.name].bottom || startDy;
            }
            if (end.type === "point" && connResPoints[end.name]) {
              endDx = connResPoints[end.name].right || endDx;
              endDy = connResPoints[end.name].bottom || endDy;
            }
  
            if ([LEFT, RIGHT].includes(startDir) && start.type === "component") {
              startDx = 0;
              if (startType === RES) {
                startDy = endDy;
              }
            }
            if ([TOP, BOTTOM].includes(startDir) && start.type === "component") {
              startDy = 0;
              if (startType === RES) {
                startDx = endDx;
              }
            }
  
            if ([LEFT, RIGHT].includes(endDir) && end.type === "component") {
              endDx = 0;
              if (endType === RES) {
                endDy = startDy;
              }
            }
            if ([TOP, BOTTOM].includes(endDir) && end.type === "component") {
              endDy = 0;
              if (endType === RES) {
                endDx = startDx;
              }
            }
  
            if (!currLineNameLocation) {
              currLineNameLocation = [startLocation[0] + startDx, startLocation[1] + startDy];
            }
            currLineEle.append("line")
              .attr("class", `line-item-${currLine.name}-${pointSection.name} line-item`)
              .attr("x1", (startLocation[0] + startDx) * this.xGridSize)
              .attr("y1", (startLocation[1] + startDy) * this.yGridSize)
              .attr("x2", (endLocation[0] + endDx) * this.xGridSize)
              .attr("y2", (endLocation[1] + endDy) * this.yGridSize)
              .attr("stroke-width", 4)
              .style("stroke", currLine.color || "#1896ff")
              .style("cursor", "pointer")
              .on("mouseover", function () {
                select(this).attr("stroke-width", 7);
              })
              .on("mouseout", function () {
                select(this).attr("stroke-width", 4);
              })
              .on("click", () => {
                this.selectLine(lineItem.name, pointSection.name)
              })
  
            const pointName = currSection.in.type === "point" ? currSection.in.component : currSection.out.type === "point" ? currSection.out.component : "";
            const pointKey = pointName.replace(/[0-9]+/g, "");
            if (startIsPoint && start && start.name) {
              const prevName = start.name.match(/[0-9]+/g);
              const num = prevName ? prevName[0] : "1"
              newPoints.push({
                name: `${pointKey}${num}`,
                type: "point",
                location: [start.location[0] + startDx, start.location[1] + startDy]
              })
            }
  
            if (endIsPoint && end && end.name) {
              const prevName = end.name.match(/[0-9]+/g);
              const num = prevName ? prevName[0] : "1"
              newPoints.push({
                name: `${pointKey}${num}`,
                type: "point",
                location: [end.location[0] + endDx, end.location[1] + endDy]
              })
            }
  
            if (start.type === "component") {
              const findComp = this.rects.find(comp => comp.name === start.name);
              findComp && findComp.location && findComp.type === RES && resRects.push({
                name: currSection[startSectionKey].component,
                type: RES,
                width: 2,
                height: 2,
                location: [findComp.location[0] + startDx, findComp.location[1] + startDy]
              })
              newPoints.push({
                name: currSection[startSectionKey].component,
                type: currSection[startSectionKey].type,
                pin: currSection[startSectionKey].pin,
                location: [startLocation[0] + startDx, startLocation[1] + startDy]
              })
            }
  
            if (end.type === "component") {
              const findComp = this.rects.find(comp => comp.name === end.name);
              findComp && findComp.location && findComp.type === RES && resRects.push({
                name: currSection[endSectionKey].component,
                type: RES,
                width: 2,
                height: 2,
                location: [findComp.location[0] + endDx, findComp.location[1] + endDy]
              })
              newPoints.push({
                name: currSection[endSectionKey].component,
                type: currSection[endSectionKey].type,
                pin: currSection[endSectionKey].pin,
                location: [endLocation[0] + endDx, endLocation[1] + endDy]
              })
            }
          }
          this.drawLinePoints({ points: newPoints, lineItem: currLine, section: currSection, i: -1 });
        }
  
        currLineNameLocation && this.drawLineName({ lineEle: currLineEle, lineItem: currLine, lineNameLocation: currLineNameLocation });
        if (resRects && resRects.length) {
          this.drawRects(resRects, false, true)
        }
      } */
  }

  drawLineName = ({ /* lineEle, */ lineItem, lineNameLocation, lineNames }) => {

    const plot = this;
    const lineEle = this.svgRoot.select(`g#canvas-line-${lineItem.name}`);
    lineEle.append("text")
      .attr("class", `line-item-${lineItem.name}-text`)
      .attr("x", (lineNameLocation[0] + 0.2) * this.xGridSize)
      .attr("y", (lineNameLocation[1] - 0.2) * this.yGridSize)
      .style("stroke", lineItem.color || "#1896ff")
      .style("cursor", "pointer")
      .text(lineNames.join(", "))
      /*    .text(lineItem.name) */
      .on("mouseover", function () {
        if (plot.isSweep || plot.isPuppeteer) {
          return
        }
        select(`text.line-remove-text-${lineItem.name}`).remove();
        //remove button
        lineEle.append("text")
          .attr("class", `line-remove-text-item line-remove-text-${lineItem.name}`)
          .text("X")
          .attr("x", (lineNameLocation[0] + 0.2) * plot.xGridSize + getTextWidth(lineNames.join(", ")/* lineItem.name */, 14))
          .attr("y", (lineNameLocation[1] - 0.2) * plot.yGridSize)
          .style("cursor", "pointer")
          .attr("title", "Remove All Signals")
          .on("click", () => {
            plot.deleteLine(lineItem.name)
          }).on("mouseout", function () {
            select(`text.line-remove-text-${lineItem.name}`).remove();
          });
      })
      .on("mouseout", function () {
        const targetElement = event.relatedTarget ? select(event.relatedTarget) : null;
        const classNames = targetElement ? targetElement.attr("class") : "";
        if (classNames.includes(`line-remove-text-${lineItem.name}`)) {
          return;
        }
        select(`text.line-remove-text-${lineItem.name}`).remove();
      })
  }

  deleteLine = (lineName) => {
    const { updateRects, deleteRects } = this.action.deleteLine(lineName);
    select(this.svgElement).selectAll(`g#canvas-line-${lineName}`).remove();
    if (deleteRects.length) {
      this.rects = this.rects.filter(item => !deleteRects.includes(item.name));
      for (let rectItem of deleteRects) {
        selectAll(`text.text-${rectItem}`).remove();
        selectAll(`rect.rect-${rectItem}`).remove();
        selectAll(`g#rect-children-${rectItem}`).remove();
        selectAll(`g#rect-children-points-${rectItem}`).remove();
      }
    }

    if (updateRects && updateRects.length) {
      for (let rectItem of updateRects) {
        const index = this.rects.findIndex(item => item.name === rectItem.name);
        if (index < 0) {
          continue;
        }
        this.rects[index].points = [...rectItem.points];
        /*  this.drawRectPoints(this.rects[index]); */
      }

      this.drawRects(this.rects)
    }
  }

  deleteLineSection = (lineName, sectionId) => {
    const { updateRects, lines, deleteRects } = this.action.deleteLineSection(lineName, sectionId);

    this.lines = lines;
    if (deleteRects && deleteRects.length) {
      this.rects = this.rects.filter(item => !deleteRects.includes(item.name));
      for (let rectItem of deleteRects) {
        selectAll(`text.text-${rectItem}`).remove();
        selectAll(`rect.rect-${rectItem}`).remove();
        selectAll(`g#rect-children-${rectItem}`).remove();
        selectAll(`g#rect-children-points-${rectItem}`).remove();
      }
    }
    if (updateRects && updateRects.length) {
      for (let rectItem of this.rects) {
        const findRect = updateRects.find(item => item.name === rectItem.name);
        if (!findRect) {
          continue;
        }
        rectItem.points = [...findRect.points];
      }
    }
    this.drawRects(this.rects);
    this.drawLines(this.lines);
  }

  getRectPointsDir = ({ pointLocation, rectLocation, width, height }) => {
    let dir = "";
    if (pointLocation && pointLocation.length && rectLocation && rectLocation.length) {
      if (pointLocation[0] === rectLocation[0]) {
        dir = LEFT;
      }
      if (pointLocation[1] === rectLocation[1]) {
        dir = TOP;
      }

      if (pointLocation[0] === Number(rectLocation[0]) + Number(width)) {
        dir = RIGHT;
      }

      if (pointLocation[1] === Number(rectLocation[1]) + Number(height)) {
        dir = BOTTOM;
      }
    }
    return dir;
  }

  selectLine = (lineName, sectionId) => {
    this.action.selectLine(lineName, sectionId);
  }

  drawLinePoints = ({ points, lineItem, lineRects, section, i }) => {
    const _points = points || [];
    const lineName = lineItem.name;
    const color = lineItem.color || "#1896ff";
    const lineEle = this.svgRoot.select(`g#canvas-line-${lineName}`);

    for (let poi of _points) {
      if (!poi.location || !poi.location.length) {
        continue;
      }

      const x = poi.location[0] * this.xGridSize, y = poi.location[1] * this.yGridSize;

      lineEle.append("circle")
        .attr("class", `point-item-${poi.name}-${lineName}-point line-point-item-${lineName}-point line-point`)
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", poi.type === "point" ? 9 : 6)
        .style("stroke", color)
        .style("stroke-width", 3)
        .attr("fill", color)
        .on("mouseover", () => {
          if (i !== 0 || poi.type !== "point") {
            return;
          }
          if (i === 0 && this.currentLine && this.currentLine.points && this.currentLine.start && !lineRects.includes(this.currentLine.start.name)) {
            const [_x, _y] = mouse(this.svgRoot.node());
            const x = this.xSetScale(_x),
              y = this.ySetScale(_y);
            const { points, isStraightLine } = this.calculateShortestPath({
              x: this.currentLine.start.x,
              y: this.currentLine.start.y,
              type: this.currentLine.start.type,
            },
              { x, y, endType: "line" },
              true);
            this.currentLine.points = points;
            this.currentLine.isStraightLine = isStraightLine;
            this.currentLine.end = {
              endType: "line",
              name: lineItem.name,
              section,
              sectionPoints: poi.sectionPoints,
              x,
              y
            }
            this.linePath && this.linePath.attr("d", line().x(d => d.x * this.xGridSize).y(d => d.y * this.yGridSize)(this.currentLine.points));
          };
        })

      if (poi.type === "point") {
        lineEle.append("text")
          .attr("class", `point-item-${poi.name}-text line-point-item-${lineName}-point-text`)
          .attr('x', x - getTextWidth(poi.name, 12) / 2)
          .attr('y', y)
          .attr('dy', 4)
          .style("font-size", "12px")
          .text(poi.name);
      }
    }
  }

  drawRectPoints = (rectData) => {
    const points = rectData.points || [];
    const canvasRect = this.svgRoot.select('g#canvas-rect');
    canvasRect.select(`g#rect-children-points-${rectData.name}`).remove();
    if (!points.length) {
      return;
    }

    const rectChild = canvasRect.append("g")
      .attr("class", `rect-children-points-g`)
      .attr("id", `rect-children-points-${rectData.name}`);

    for (let point of points) {
      if (!point.location || !point.location.length) {
        continue;
      }
      rectChild.append("circle")
        .attr("class", `rect-line-conn-point-item-${rectData.name}`)
        .attr("cx", point.location[0] * this.xGridSize)
        .attr("cy", point.location[1] * this.yGridSize)
        .attr("r", 6)
        .attr("fill", this.getRectPointColorByLine(point.line))
        .attr("opacity", 1)
    }
  }

  startRectDrag = (rectData) => {
    const plot = this;

    let rectKey, newX, newY, initialX, initialY, rectWidth = 0, rectHeight = 0, circleExist = false;
    const dragRect = drag().on("start", function () {
      //current click element (text / rect)
      const currElement = select(this);
      rectKey = currElement.attr("rectName");
      //rect element
      const element = select(`rect.rect-${rectKey}`);
      initialX = +element.attr("x");
      initialY = +element.attr("y");
      rectWidth = +element.attr("width");
      rectHeight = +element.attr("height");
      newX = initialX;
      newY = initialY;
      let canvasRect = plot.svgRoot.select('g#canvas-rect');
      const rectChild = canvasRect.select(`#rect-children-${rectKey}`);
      if (!rectChild.select(`circle.rect-resize-circle-item-${rectKey}`).empty()) {
        circleExist = true;
        rectChild.selectAll(`circle.rect-resize-circle-item-${rectKey}`).remove();
      } else {
        circleExist = false;
      }

    }).on("drag", function () {
      const dx = event.x - event.subject.x;
      newX = initialX + dx;
      const dy = event.y - event.subject.y;
      newY = initialY + dy;

      plot.updateRectLocation({ rectKey, newX, newY, rectWidth, rectHeight });

    }).on("end", () => {
      const index = this.rects.findIndex(item => item.name === rectKey);
      if (index > -1 && !isNaN(newX) && !isNaN(newY)) {
        const _newX = this.xSetScale(newX),
          _newY = this.ySetScale(newY);
        this.rects[index].location = [_newX, _newY];
        this.rectUpdate && this.rectUpdate(this.rects[index]);
        this.updateRectLocation({ rectKey, newX: _newX * this.xGridSize, newY: _newY * this.yGridSize, rectWidth, rectHeight, isReDrawCircle: circleExist });
        /*  this.simulationTick() */
      }
    })
    return dragRect;
  }

  drawLineConnPoints = (_rectData) => {
    if (!_rectData) {
      return;
    }
    this.removeDrawLinePoints(_rectData.name);
    const canvasRect = this.svgRoot.select('g#canvas-rect');
    const rectChild = canvasRect.select(`#rect-children-${_rectData.name}`);
    const rectData = (this.rects || []).find(item => item.name === _rectData.name);
    if (!rectData || (rectData.points && rectData.points.length)) {
      return;
    }

    const lineConnPoints = this.getRectLineConnPoints(rectData);
    const plot = this;
    for (let point of lineConnPoints) {
      rectChild.append("circle")
        .attr("class", `rect-line-point-item-${rectData.name}`)
        .attr("cx", point.x * this.xGridSize)
        .attr("cy", point.y * this.yGridSize)
        .attr("r", 6)
        .attr("fill", getColor(rectData.type))
        .attr("opacity", 0.5)
        .style("cursor", "pointer")
        .on("mouseover", function () {
          select(this).attr("r", 10)
            .attr("opacity", 1);
          if (plot.currentLine && plot.currentLine.points && plot.currentLine.start && plot.currentLine.start.name !== rectData.name) {
            if ((LEFT === plot.currentLine.start.type && plot.currentLine.start.x < point.x)
              || (TOP === plot.currentLine.start.type && plot.currentLine.start.y < point.y)
              || (RIGHT === plot.currentLine.start.type && plot.currentLine.start.y > point.y)
              || (BOTTOM === plot.currentLine.start.type && plot.currentLine.start.y > point.y)) {
              return;
            }
            const { points, isStraightLine } = plot.calculateShortestPath({
              x: plot.currentLine.start.x,
              y: plot.currentLine.start.y,
              type: plot.currentLine.start.type
            },
              { x: point.x, y: point.y, type: point.type, endType: "rect" },
              true);
            plot.currentLine.points = points;
            plot.currentLine.isStraightLine = isStraightLine;
            plot.currentLine.end = {
              endType: "rect",
              name: rectData.name,
              x: point.x,
              y: point.y,
              type: point.type
            }
            plot.linePath && plot.linePath.attr("d", line().x(d => d.x * plot.xGridSize).y(d => d.y * plot.yGridSize)(plot.currentLine.points));
          }
        })
        .on("mouseout", function () {
          select(this).attr("r", 6);
          const targetElement = event.relatedTarget ? select(event.relatedTarget) : null;
          const classNames = targetElement ? targetElement.attr("class") : "";
          if (classNames.includes(`rect-${rectData.name}`) || classNames.includes(`rect-line-point-item-${rectData.name}`)) {
            return;
          }
          plot.removeDrawLinePoints(rectData.name)
        })
        .on("mousedown", function () {
          event.preventDefault()
          plot.startDrawLine({ rectData, x: point.x, y: point.y, type: point.type })
        })
    }
  }

  getRectLineConnPoints = (_rectData) => {
    const rectData = this.rects.find(item => item.name === _rectData.name);
    const [x, y] = rectData.location || [];
    let points = [];

    //const { _width, _height, _x, _y } = this.getRectSize({ width: rectData.width, height: rectData.height, x, y });
    const existPoints = (rectData.points || []).map(item => item.location);
    /*
    +--1----2--+
    |         |
   1|         |2
    |         |
    +--1----2--+
  */
    //3 -> 1, 2
    for (let i = 1; i < rectData.width; i++) {
      const _xa = x + i, _ya = y;
      if (!existPoints.find(it => _.isEqual(it, [_xa, _ya]))) {
        points.push({
          x: _xa,
          y: _ya,
          type: TOP
        });
      }

      const _xb = x + i, _yb = y + rectData.height;
      if (!existPoints.find(it => _.isEqual(it, [_xb, _yb]))) {
        points.push({
          x: _xb,
          y: _yb,
          type: BOTTOM
        })
      }
    }

    for (let i = 1; i < rectData.height; i++) {
      const _x1 = x,
        _y1 = y + i;
      if (!existPoints.find(it => _.isEqual(it, [_x1, _y1]))) {
        points.push({
          x: _x1,
          y: _y1,
          type: LEFT
        });
      }

      const _x2 = x + rectData.width,
        _y2 = y + i;
      if (!existPoints.find(it => _.isEqual(it, [_x2, _y2]))) {
        points.push({
          x: _x2,
          y: _y2,
          type: RIGHT
        })
      }
    }
    return points;
  }

  removeDrawLinePoints = (name) => {
    const canvasRect = this.svgRoot.select('g#canvas-rect');
    const rectChild = canvasRect.select(`#rect-children-${name}`);
    rectChild.selectAll(`circle.rect-line-point-item-${name}`).remove();
  }

  updateRectLocation = ({ rectKey, newX, newY, rectWidth, rectHeight, resize = false, isReDrawCircle = false }) => {
    select(`rect.rect-${rectKey}`)
      .attr("x", newX)
      .attr("y", newY);

    if (resize) {
      select(`rect.rect-${rectKey}`)
        .attr("width", rectWidth)
        .attr("height", rectHeight)
    }

    select(`text.text-${rectKey}`)
      .attr("x", newX + rectWidth / 2 - getTextWidth(rectKey, 14) / 2)
      .attr("y", newY + rectHeight / 2);

    select(`text.rect-remove-text-${rectKey}`)
      .attr("x", newX + rectWidth + 5)
      .attr("y", newY - 5);

    if (isReDrawCircle) {
      this.updateRectResizeCircles({ name: rectKey });
      this.drawLineConnPoints({ name: rectKey })
    }
  }

  clearAllRectChildren = () => {
    const canvasRect = this.svgRoot.select('g#canvas-rect');
    if (canvasRect.empty()) {
      return;
    }
    canvasRect.selectAll("g.rect-children-g")
      .each(function () {
        select(this)
          .selectAll("*")
          .remove();
      });
  }

  clickRect = (rectData, nameList) => {
    this.openRectBox(rectData, nameList);

    //resize circle
    //this.updateRectResizeCircles(rectData, rectChild);
  }

  closeRectIcon = (_rectData, nameList) => {
    const canvasRect = this.svgRoot.select('g#canvas-rect');
    if (canvasRect.empty()) {
      return;
    }
    const rectChild = canvasRect.select(`#rect-children-${_rectData.name}`);

    if (rectChild.empty()) {
      return;
    }
    const rectData = this.rects.find(item => item.name === _rectData.name) || _rectData;
    if (!rectData || !rectData.location || !rectData.location.length) {
      return;
    }
    select(`text.rect-remove-text-${rectData.name}`).remove();
    const rectX = rectData.location[0],
      rectY = rectData.location[1];
    const { _width, _x, _y } = this.getRectSize({ width: rectData.width, height: 0, x: rectX, y: rectY, type: "all" });
    //remove button
    rectChild.append("text")
      .attr("class", `rect-remove-text-item rect-remove-text-${rectData.name}`)
      .text("X")
      .attr("x", _x + _width - 12)
      .attr("y", _y + 13)
      .style("cursor", "pointer")
      .attr("title", "Remove")
      .on("click", () => {
        this.deleteRect(rectData)
      });
  }

  deleteRect = (rectData) => {
    const info = this.rectDelete(rectData);
    if (info) {
      const { updateRects, lines, deleteRects } = info;

      this.lines = lines;
      if (deleteRects && deleteRects.length) {
        this.rects = this.rects.filter(item => !deleteRects.includes(item.name));
        for (let rectItem of deleteRects) {
          selectAll(`text.text-${rectItem}`).remove();
          selectAll(`rect.rect-${rectItem}`).remove();
          selectAll(`g#rect-children-${rectItem}`).remove();
          selectAll(`g#rect-children-points-${rectItem}`).remove();
        }
      }
      if (updateRects && updateRects.length) {
        for (let rectItem of this.rects) {
          const findRect = updateRects.find(item => item.name === rectItem.name);
          if (!findRect) {
            continue;
          }
          rectItem.points = [...findRect.points];
        }
      }
      this.drawRects(this.rects);
      this.drawLines(this.lines);
    } else {
      this.rects = this.rects.filter(item => item.name !== rectData.name);
      selectAll(`text.text-${rectData.name}`).remove();
      selectAll(`rect.rect-${rectData.name}`).remove();
      selectAll(`g#rect-children-${rectData.name}`).remove();
      selectAll(`g#rect-children-points-${rectData.name}`).remove();
    }
  }

  updateRects = (rects, update, isCoord) => {
    const reDrawRects = []
    for (let recItem of rects) {
      const canvasRect = select("g#canvas-rect");
      const rectName = recItem.prevName || recItem.name;

      const index = this.rects.findIndex(item => item.name === rectName);
      if (index > -1 && !isCoord) {
        for (let key of update) {
          this.rects[index][key] = recItem[key];
        }
      }

      if (update.includes("name") && index > -1) {
        canvasRect.select(`text.text-${rectName}`).remove();
        canvasRect.select(`rect.rect-${rectName}`).remove();
        canvasRect.select(`g#rect-children-${rectName}`).remove();
        reDrawRects.push(this.rects[index]);
        continue;
      }

      const rectEle = canvasRect.select(`rect.rect-${rectName}`);
      const textEle = canvasRect.select(`text.text-${rectName}`)
      if (rectEle.empty() || textEle.empty()) {
        continue
      }

      if (update.includes("name") || update.includes("type")) {
        rectEle.attr("class", `rect-${recItem.name} canvas-rect-item`)
          .attr("rectName", recItem.name)
          .attr("stroke", getColor(recItem.type));

        textEle.attr("class", `text-${recItem.name} canvas-rect-text-item`)
          .attr("rectName", recItem.name)
          .text(recItem.name)
      }

      if (update.includes("location")) {
        let { _x, _y, _width, _height } = isCoord ?
          this.getRectSize({ width: recItem.width, height: recItem.height, type: "size" })
          : this.getRectSize({ x: recItem.location[0] || 0, y: recItem.location[1] || 0, width: recItem.width, height: recItem.height })

        const x = isCoord ? recItem.location[0] : _x,
          y = isCoord ? recItem.location[1] : _y;
        rectEle
          .attr("x", x)
          .attr("y", y);

        textEle
          .attr('x', x + _width / 2 - getTextWidth(recItem.name, 14) / 2)
          .attr('y', y + _height / 2)
      }

    }
    if (reDrawRects.length) {
      this.drawRects(reDrawRects);
    }
  }

  updateRectResizeCircles = (_rectData) => {
    const canvasRect = this.svgRoot.select('g#canvas-rect');
    const rectChild = canvasRect.select(`#rect-children-${_rectData.name}`);

    rectChild.selectAll(`circle.rect-resize-circle-item-${_rectData.name}`).remove();

    const rectData = this.rects.find(item => item.name === _rectData.name);
    const rectX = rectData.location[0],
      rectY = rectData.location[1];

    const { _width, _height, _x, _y } = this.getRectSize({
      width: rectData.width,
      height: rectData.height,
      x: rectX,
      y: rectY
    });

    const circlesData = [
      { x: _x, y: _y, index: 1 },   // left top
      { x: _x + _width, y: _y, index: 2 }, // right top
      { x: _x, y: _y + _height, index: 3 },    // left bottom
      { x: _x + _width, y: _y + _height, index: 4 } // right bottom
    ];

    for (let item of circlesData) {
      rectChild.append("circle")
        .attr("class", `rect-resize-circle-item-${rectData.name} rect-resize-circle-${rectData.name}-${item.index}`)
        .attr("r", 6)
        .attr("cx", item.x)
        .attr("cy", item.y)
        .attr("index", item.index)
        .attr("fill", "#1896ff")
        .style("cursor", "pointer")
        .on("mouseover", function () {
          select(this).attr("r", 8);
        })
        .on("mouseout", function () {
          select(this).attr("r", 6);
        })
        .call(this.reSizeDrag(rectData));
    }
  }

  reSizeDrag = (rectData) => {
    let rectX = rectData.location[0],
      rectY = rectData.location[1];
    let { _width, _height, _x, _y } = this.getRectSize({ width: rectData.width, height: rectData.height, x: rectX, y: rectY });

    let indexId, newX, newY, initialX, initialY, newRect = {};
    const indexList = ["1", "2", "3", "4"];

    const plot = this;
    const dragCircle = drag().on("start", function () {
      //circle element
      const element = select(this);
      initialX = +element.attr("cx");
      initialY = +element.attr("cy");
      indexId = element.attr("index");
      newX = initialX;
      newY = initialY;
    }).on("drag", function () {
      const dx = event.x - event.subject.x;
      newX = initialX + dx;
      const dy = event.y - event.subject.y;
      newY = initialY + dy;

      if (newX < plot.coordLeftTop[0]) {
        newX = plot.coordLeftTop[0];
      }
      if (newX > plot.coordRightTop[0]) {
        newX = plot.coordRightTop[0];
      }
      if (newY < plot.coordLeftTop[1]) {
        newY = plot.coordLeftTop[1];
      }
      if (newY > plot.coordLeftBottom[1]) {
        newY = plot.coordLeftBottom[1];
      }

      if (indexId === "1") {
        //left top
        newRect.x = newX;
        newRect.y = newY;
        newRect.width = _width - dx;
        newRect.height = _height - dy;
      }
      if (indexId === "2") {
        //right top
        newRect.x = _x;
        newRect.y = newY;
        newRect.width = _width + dx;
        newRect.height = _height - dy;
      }

      if (indexId === "3") {
        //left bottom
        newRect.x = _x + dx;
        newRect.y = _y;
        newRect.width = _width - dx;
        newRect.height = _height + dy;
      }

      if (indexId === "4") {
        //right bottom
        newRect.x = _x;
        newRect.y = _y;
        newRect.width = _width + dx;
        newRect.height = _height + dy;
      }

      select(this)
        .attr("cx", newX)
        .attr("cy", newY);

      indexList.forEach(item => {
        if (item !== indexId) {
          select(`circle.rect-resize-circle-${rectData.name}-${item}`)
            .attr("visibility", "hidden")
        }
      });

      plot.updateRectLocation({
        rectKey: rectData.name,
        newX: newRect.x,
        newY: newRect.y,
        rectWidth: newRect.width,
        rectHeight: newRect.height,
        resize: true
      });
    }).on("end", () => {
      const index = this.rects.findIndex(item => item.name === rectData.name);
      if (index > -1 && !isNaN(newRect.x) && !isNaN(newRect.y)) {
        const _newX = this.xSetScale(newRect.x),
          _newY = this.ySetScale(newRect.y);
        const newX = _newX * this.xGridSize, newY = _newY * this.yGridSize;

        const dw = newX - newRect.x, dh = newY - newRect.y;
        let width = newRect.width + dw, height = newRect.height + dh;
        let widthSize = width / this.xGridSize, heightSize = height / this.yGridSize;
        if (!Number.isInteger(widthSize)) {
          widthSize = this.xSetScale(width);
          width = widthSize * this.xGridSize;
        }
        if (!Number.isInteger(heightSize)) {
          heightSize = this.ySetScale(height);
          height = heightSize * this.yGridSize;
        }
        this.rects[index].location = [_newX, _newY];
        this.rects[index].width = widthSize;
        this.rects[index].height = heightSize;
        this.rectUpdate && this.rectUpdate(this.rects[index]);
        this.updateRectLocation({
          rectKey: rectData.name,
          newX,
          newY,
          rectWidth: width,
          rectHeight: height,
          resize: true,
          isReDrawCircle: true
        });
      }
    });

    return dragCircle
  }

  createLine = (currentLine) => {
    if (!currentLine.end) {
      this.svgRoot.select("path.current-line-path").remove()
      return
    }
    const { lines, newRects } = this.action.createLine({ currentLine });
    this.lines = lines;
    for (let rectItem of this.rects) {
      const findRect = newRects.find(item => item.name === rectItem.name);
      if (!findRect) {
        continue;
      }
      rectItem.points = [...findRect.points];
    }
    this.drawRects(this.rects);
    this.drawLines(this.lines);

    this.svgRoot.select("path.current-line-path").remove()
  }

  addRectToLine = (currentLine) => {
    if (!currentLine.end) {
      this.svgRoot.select("path.current-line-path").remove()
      return
    }
    const { lines, newRects } = this.action.addRectToLine({ currentLine });
    if (!lines || !newRects) {
      return;
    }

    this.lines = lines;

    for (let rectItem of newRects) {
      const index = this.rects.findIndex(item => item.name === rectItem.name);
      if (index < 0) {
        this.rects.push(rectItem)
        continue;
      }
      this.rects[index].points = rectItem.points;
    }
    this.drawRects(this.rects);
    this.drawLines(lines);
    this.svgRoot.select("path.current-line-path").remove()
  }

  findLineSectionPoints = (sectionItem) => {
    let location = [], dir = "";

    if (sectionItem.type === "component") {
      const findRect = this.rects.find(it => it.name === sectionItem.name);
      if (findRect && findRect.points) {
        const point = findRect.points.find(item => item.point === sectionItem.pin);
        location = point ? point.location : [];
        dir = this.getRectPointsDir({ pointLocation: location, rectLocation: findRect.location, width: findRect.width, height: findRect.height })
      }

      return { location, dir, type: findRect ? findRect.type : "" };
    }
  }

  startDrawLine = ({ rectData, x, y, type }) => {

    this.currentLine = {
      start: { name: rectData.name, x, y, type },
      end: null,
      points: [{ x, y }]
    };
    this.createLinePath(this.currentLine.points);
    this.svgRoot.on("mousemove", this.onMouseMove)
      .on("mouseup", this.onMouseUp);
  }

  createLinePath = (points) => {
    this.linePath = this.svgRoot.append("path")
      .attr("class", "current-line-path")
      .attr("d", line().x(d => d.x * this.xGridSize).y(d => d.y * this.yGridSize)(points))
      .attr("stroke", "steelblue")
      .attr("stroke-width", 4)
      .attr("fill", "none");
  }

  onMouseMove = () => {
    event.preventDefault()
    if (!this.currentLine || !this.linePath || this.currentLine.end) {
      return;
    }
    /*  const plot = this; */
    const [_x, _y] = mouse(this.svgRoot.node());
    const x = this.xSetScale(_x),
      y = this.ySetScale(_y);

    if (!isSamePoint(this.currentLine.points[this.currentLine.points.length - 1], { x, y })) {
      const { points } = this.calculateShortestPath({
        x: this.currentLine.start.x,
        y: this.currentLine.start.y,
        type: this.currentLine.start.type,
      },
        { x, y });
      this.currentLine.points = points;
      this.linePath.attr("d", line().x(d => d.x * this.xGridSize).y(d => d.y * this.yGridSize)(this.currentLine.points));
    }

    function isSamePoint(point1, point2) {
      return point1.x === point2.x && point1.y === point2.y;
    }
  }

  calculateShortestPath = (start, end, isEnd) => {
    /*    let pathPoints = [start]; */
    let endExtendPoint = null
    let endDir = null, endAxis = null;
    let _end = { ...end };
    if (isEnd && end.endType === "rect") {

      /*       const middlePoint = { x: start.x, y: end.y }
            newPoints = [start, middlePoint, end]; */
      endExtendPoint = null;
      switch (end.type) {
        case TOP:
          endDir = "+";
          endAxis = "Y";
          endExtendPoint = { x: end.x, y: end.y - 1 };
          break;
        case BOTTOM:
          endDir = "-";
          endAxis = "Y";
          endExtendPoint = { x: end.x, y: end.y + 1 };
          break;
        case LEFT:
          endDir = "+";
          endAxis = "X";
          endExtendPoint = { x: end.x - 1, y: end.y };
          break;
        case RIGHT:
          endDir = "-";
          endAxis = "X";
          endExtendPoint = { x: end.x + 1, y: end.y };
          break;
        default: break;
      }
      _end = { ...endExtendPoint }
    }
    let { pathPoints,/*  startDir, startAxis, */ isStraightLine } = this.calcPath(start, _end, isEnd && end.endType ? end : null);
    if (!isEnd || end.endType === "line") {
      return { points: pathPoints, isStraightLine };
    }


    let lastLineAxis = null, lastLineDir = null;

    //Merge end and end -1, dir and axis is the same
    if (pathPoints[pathPoints.length - 1].x === pathPoints[pathPoints.length - 2].x) {
      lastLineAxis = "Y";
      lastLineDir = pathPoints[pathPoints.length - 1].y - pathPoints[pathPoints.length - 2].y > 0 ? "+" : "-";
    } else {
      lastLineAxis = "X";
      lastLineDir = pathPoints[pathPoints.length - 1].x - pathPoints[pathPoints.length - 2].x > 0 ? "+" : "-";
    }

    if (isStraightLine) {
      if (lastLineAxis === endAxis && endDir === lastLineDir) {
        pathPoints.splice(pathPoints.length - 1, 1, end);
      } else {
        pathPoints.push(end);
      }
      return { points: pathPoints, isStraightLine };
    }

    if (lastLineAxis === endAxis && endDir === lastLineDir) {
      if (endExtendPoint.y === pathPoints[pathPoints.length - 2].y || endExtendPoint.x === pathPoints[pathPoints.length - 2].x) {
        pathPoints.splice(pathPoints.length - 1, 1, end);
      }
      return { points: pathPoints, isStraightLine };
    }

    let prevAxis = null, prevDir = "";
    //reset points, dir and axis is the vertical
    if (pathPoints[pathPoints.length - 3].x === pathPoints[pathPoints.length - 2].x) {
      prevAxis = "Y";
      prevDir = pathPoints[pathPoints.length - 2].y - pathPoints[pathPoints.length - 3].y > 0 ? "+" : "-";
    } else {
      prevAxis = "X";
      prevDir = pathPoints[pathPoints.length - 2].x - pathPoints[pathPoints.length - 3].x > 0 ? "+" : "-";
    }

    if (prevAxis === endAxis && endDir === prevDir) {
      pathPoints.splice(pathPoints.length - 3, 3);
      const addP = endAxis === "Y" ? { x: end.x, y: pathPoints[pathPoints.length - 1].y } : { x: pathPoints[pathPoints.length - 1].x, y: end.y };
      pathPoints.push(addP, end)
      return { points: pathPoints, isStraightLine };
    }
    pathPoints.push(end)
    return { points: pathPoints, isStraightLine };
  }

  calcPath = (start, end, actualEnd) => {
    let pathPoints = [start];
    const xDistance = actualEnd ? actualEnd.x - start.x : end.x - start.x,
      yDistance = actualEnd ? actualEnd.y - start.y : end.y - start.y;
    let extendPoint = null;
    let startDir = null, startAxis = null, isStraightLine = false;
    switch (start.type) {
      case BOTTOM:
      case TOP:
        //A straight line from the start to the end
        if (start.x === end.x) {
          pathPoints.push(end);
          isStraightLine = true;
          return { pathPoints, isStraightLine };
        }
        startAxis = "Y";
        //Extend a grid based on the position of the starting point relative to the Rect
        if (start.type === BOTTOM) {
          extendPoint = { x: start.x, y: start.y + 1 };
          startDir = "+";
        } else {
          extendPoint = { x: start.x, y: start.y - 1 };
          startDir = "-";
        }
        //start -> extendPoint -> end
        if (end.y === extendPoint.y) {
          pathPoints.push({ ...extendPoint }, end);
          break;
        }

        let midY = Math.ceil(yDistance / 2);
        //Avoid overlapping of line and rect
        if ((start.type === BOTTOM && yDistance < 0) || (start.type === TOP && yDistance > 0)) {
          pathPoints.push({ ...extendPoint });
          const _midX = Math.ceil(xDistance / 2);
          //start -> extendPoint -> (middle x, extendPoint y) -> (middle x, end y) -> end
          /* 
                ____
          |____|
        */
          pathPoints.push(
            { x: start.x + _midX, y: extendPoint.y },
            { x: start.x + _midX, y: end.y },
            end
          );
          break;
        }

        //start -> (start x, middle y) -> (end x, middle y) -> end
        /* 
           |
           |__
             |
             |
         */
        pathPoints.push(
          { x: start.x, y: start.y + midY },
          { x: end.x, y: start.y + midY }
          , end);
        break;
      case LEFT:
      case RIGHT:
        //A straight line from the start to the end
        if (start.y === end.y) {
          pathPoints.push(end);
          isStraightLine = true;
          return { pathPoints, isStraightLine };
        }
        startAxis = "X";
        //Extend a grid based on the position of the starting point relative to the Rect
        if (start.type === LEFT) {
          startDir = "-";
          extendPoint = { x: start.x - 1, y: start.y }
        } else {
          startDir = "+";
          extendPoint = { x: start.x + 1, y: start.y };
        }
        if (end.x === extendPoint.x) {
          pathPoints.push({ ...extendPoint }, end);
          break;
        }

        const midX = Math.ceil(xDistance / 2);
        //Avoid overlapping of line and rect
        if ((start.type === RIGHT && xDistance < 0) || (start.type === LEFT && xDistance > 0)) {
          pathPoints.push({ ...extendPoint });
          const _midY = Math.ceil(yDistance / 2);
          //start -> extendPoint -> (extendPoint x, middle y) -> (end x, middle y) -> end
          pathPoints.push(
            { x: extendPoint.x, y: start.y + _midY },
            { x: end.x, y: start.y + _midY },
            end
          );
          break;
        }

        //start -> (middle x, start y) -> (middle x, end y) -> end
        pathPoints.push(
          { x: start.x + midX, y: start.y },
          { x: start.x + midX, y: end.y },
          end
        );
        break;
      default: break;
    }

    return { pathPoints, startDir, startAxis };
  }

  __calculateShortestPath = (start, end, isEnd) => {
    const pathPoints = [start];

    if (start.x === end.x) {
      pathPoints.push(end);
    } else if (start.y === end.y) {
      pathPoints.push(end);
    } else {
      pathPoints.push({ x: start.x, y: end.y });
      pathPoints.push(end);
    }
    return pathPoints;
  }


  onMouseUp = () => {
    event.preventDefault();
    this.svgRoot.on("mousemove", null).on("mouseup", null);
    if (!this.currentLine || !this.currentLine.points || this.currentLine.points.length < 2 || !this.currentLine.isStraightLine) {
      select("path.current-line-path").remove();
      this.currentLine = null;
      return;
    }

    if (this.currentLine && this.currentLine.end && this.currentLine.end.endType === "line") {
      this.addRectToLine(JSON.parse(JSON.stringify(this.currentLine)));
    } else {
      this.createLine(JSON.parse(JSON.stringify(this.currentLine)));
    }
    this.currentLine = null;
    select("path.current-line-path").remove();
  }
}

export default SchematicCanvas;
