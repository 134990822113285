import React, { Component } from 'react';
import { Input, Select } from 'antd';
import {
  FileTextOutlined,
  RedoOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { MICROSTRIP, TRACE_VERSION, STRIPLINE, SINGLE, DIFF, signalTypes } from '../../constants/libraryConstants';
import Table from '@/components/EditableTable';
import {
  checkLibraryValue,
  getLibraryConfigVersion,
  TraceConfig,
  TraceKeyArr,
  TraceColumns,
  TraceKeyZDArr,
  LIBRARY_UNITS,
  /*  unitChange, */
  LOSS_TANGENT,
  ER,
  CONDUCTIVITY,
  Z0,
  Z_DIFF,
  UNIT,
  HEIGHT2,
  HEIGHT1,
  HEIGHT,
  SPACING,
} from '@/services/PreLayout/PreLayoutLibrary';
import { unitChange } from '@/services/helper/mathHelper';
import { closeCountTime, countTime } from '@/services/helper/hasOperate';
import UnitAddonAfter from '@/components/UnitAddonAfter';
import _ from 'lodash';
import { SIERRA } from '../../constants/pageType';
import { LAYERS, MicroStripLayers, PRE_LAYOUT_SPACING, PRE_LAYOUT_UNIT, SierraTraceKeyArr, StripLineLayers, SierraTraceColumns, PRE_LAYOUT_ER, PRE_LAYOUT_LOSS_TANGENT, PRE_LAYOUT_CONDUCTIVITY, PRE_LAYOUT_THICKNESS } from '../../services/Sierra/prelayout/Schematic/constants';
import { METAL, DIELECTRIC } from '../../services/Stackup';
import MsgDialog from '../MsgDialog';
import { RUNNING, WAITING } from '../../constants/workflowStatus';
import { cancelWorkflow, getWorkFlow } from '../../services/api/v2/workflowCtrl';
import { SUCCESS } from '../../constants/returnCode';
import { getMonitor } from '../../services/workflow/workflow';

const Option = Select.Option;
class TraceSetup extends Component {

  constructor(props) {
    super(props);
    const _TraceConfig = props.TraceConfig || TraceConfig;

    const signalType = props.singleMode ? SINGLE : DIFF;
    const config = new _TraceConfig(MICROSTRIP, signalType);
    this.state = {
      config,
      prevConfig: null,//{...prevConfig,traceType,type:signalType}
      version: TRACE_VERSION,
      type: MICROSTRIP,
      signalType,
      prevCalcConfig: {},
      logVisible: false
    }

    this.TraceColumns = JSON.parse(JSON.stringify(props.product === SIERRA ? SierraTraceColumns : TraceColumns));

    this.TraceColumns.forEach(column => {
      switch (column.dataIndex) {
        case ER:
        case PRE_LAYOUT_ER:
          column.onCell = (record) => {
            if (column.dataIndex === PRE_LAYOUT_ER && record.type === METAL) {
              return { edit: false }
            }
            return {
              record,
              edit: true,
              dataIndex: column.dataIndex,
              handleSave: (_record) => { this.editConfig(_record, column.dataIndex, record) },
            }
          };
          break;
        case LOSS_TANGENT:
        case PRE_LAYOUT_LOSS_TANGENT:
          column.onCell = (record) => {
            if (column.dataIndex === PRE_LAYOUT_LOSS_TANGENT && record.type === METAL) {
              return { edit: false }
            }
            return {
              record,
              edit: true,
              dataIndex: column.dataIndex,
              handleSave: (_record) => { this.editConfig(_record, column.dataIndex, record) },
            }
          }
          break;
        case CONDUCTIVITY:
        case PRE_LAYOUT_CONDUCTIVITY:
          column.onCell = (record) => {
            if (column.dataIndex === PRE_LAYOUT_CONDUCTIVITY && record.type === DIELECTRIC) {
              return { edit: false }
            }
            return {
              record,
              edit: true,
              dataIndex: column.dataIndex,
              handleSave: (_record) => { this.editConfig(_record, column.dataIndex, record) },
            }
          }
          break;
        case PRE_LAYOUT_THICKNESS:
          column.onCell = (record) => {
            return {
              record,
              edit: true,
              dataIndex: column.dataIndex,
              handleSave: (_record) => { this.editConfig(_record, column.dataIndex, record) },
            }
          }
          column.title = () => {
            const { config } = this.state;
            return <span>
              Thickness <Select
                value={config.unit}
                onChange={this.changeUnit}
                className='pre-layout-layers-unit-selection'
                popupClassName='pre-layout-layers-unit-option'
              >
                {LIBRARY_UNITS.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
              </Select>
            </span>
          }
          break;
        default: break;
      }
    })
  }

  editConfig = (record, type, prevRecord) => {
    const value = record[type];
    const prevValue = prevRecord[type];
    const title = this.TraceColumns.find(item => item.dataIndex === type).title;
    const { product } = this.props;
    const { config } = this.state;
    //check value
    const error = checkLibraryValue({ value, type, title });
    if (error) {
      return;
    }
    let _config = { ...config };
    if (product === SIERRA) {
      const index = _config.layers.findIndex(item => item.name === record.name);
      _config.layers[index] = {
        ..._config.layers[index],
        [type]: value
      }
    } else {
      _config = {
        ...config,
        [type]: value
      }
    }


    this.setState({
      config: _config
    }, () => {
      if (prevValue !== value) {
        this.calculateImpedance(_config);
      }
    })
  }

  addonAfter = () => {
    const { errorMsg, product } = this.props;
    const { config } = this.state;
    const unit = product === SIERRA ? config[PRE_LAYOUT_UNIT] : config[UNIT];
    return UnitAddonAfter({ unit, changeUnit: this.changeUnit, list: LIBRARY_UNITS, disabled: errorMsg ? true : false })
  }

  changeUnit = (key) => {
    const { config } = this.state;
    const { product } = this.props;
    const unitKey = product === SIERRA ? PRE_LAYOUT_UNIT : UNIT;
    let _config = { ...config };
    const oldUnit = _config[unitKey];
    const TraceKeys = product === SIERRA ? SierraTraceKeyArr : TraceKeyArr;
    for (let item of TraceKeys) {
      if (item.key && item.unit && _config[item.key]) {
        const defaultValue = _config[item.key],
          defaultUnit = oldUnit;
        _config[item.key] = unitChange({
          num: defaultValue,
          oldUnit: defaultUnit,
          newUnit: key,
          decimals: 6
        }).number.toString();
      }
    }
    if (product === SIERRA) {
      for (let item of _config.layers || []) {
        item.thickness = unitChange({
          num: item.thickness,
          oldUnit: oldUnit,
          newUnit: key,
          decimals: 4
        }).number.toString();
      }
    }

    _config[unitKey] = key;
    this.setState({
      config: {
        ..._config
      }
    }, () => {
      this.calculateImpedance(_config);
    })
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    // Get data
    this.getData();
  }

  getData = () => {
    const { libraryId, getLibraryDetail } = this.props;
    if (libraryId) {
      getLibraryDetail(libraryId).then(res => {
        if (res && res.config) {
          const signalType = res.config.type ? res.config.type : DIFF;
          let _config = { ...res.config };
          delete _config.type;
          this.setState({
            config: { ..._config },
            prevConfig: { ...JSON.parse(JSON.stringify(_config)), type: signalType, traceType: res.type },
            type: res.type,
            version: res.version,
            signalType,
            prevCalcConfig: { ...JSON.parse(JSON.stringify(res.config)), type: signalType, traceType: res.type }
          }, () => {
            this.props.updateLoading(false);
            this.props.updateSignalType && this.props.updateSignalType(this.state.signalType)
            const isHasDiff = this.state.signalType === DIFF;

            if (!res.config[Z0] || parseFloat(res.config[Z0]) === 0
              || (isHasDiff && (!res.config[Z_DIFF] || parseFloat(res.config[Z_DIFF]) === 0))) {
              this.calculate(this.state.config, true);
            }
          })
        }
      }, error => {
        this.cancelCalcImpedance();
        this.props.updateLoading(false);
      })
    } else {
      this.props.updateLoading(false);
      const { config, signalType } = this.state;
      const isHasDiff = signalType === DIFF;
      if (!config[Z0] || parseFloat(config[Z0]) === 0
        || (isHasDiff && (!config[Z_DIFF] || parseFloat(config[Z_DIFF]) === 0))) {
        this.calculate(this.state.config, true);
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { libraryId, TraceConfig: propsTraceConfig } = this.props;
    if (libraryId !== prevProps.libraryId) {
      const _TraceConfig = propsTraceConfig || TraceConfig;
      const config = new _TraceConfig(MICROSTRIP);
      this.setState({
        config,
        prevConfig: null,
        version: TRACE_VERSION,
        type: MICROSTRIP
      }, () => {
        this.getData();
      })
    }
  }

  async getConfig(isUpdate = false) {
    const { config, type, version, prevConfig, signalType } = this.state;
    let newConfig = config;

    const traceList = this.props.product === SIERRA ? SierraTraceKeyArr : TraceKeyArr;

    for (let item of traceList) {
      if (item.type && type !== item.type) {
        continue;
      }
      if (item.signalType && signalType !== item.signalType) {
        continue;
      }
      if (item.key === LAYERS) {
        continue;
      }
      //check value
      const error = checkLibraryValue({ value: config[item.key], key: item.key, title: item.title });
      if (error) {
        this.props.updateErrorMsg(error);
        return null;
      }
    }

    closeCountTime();
    let _version = version, save = false;
    const { libraryId, product } = this.props;
    if (libraryId) {
      //update version by config
      _version = getLibraryConfigVersion({ version, prevConfig, config: { ...newConfig, traceType: type, type: signalType } });
    }

    if (_version !== version || !libraryId) {
      this.props.closeAndSave(false, config);
      if (product !== SIERRA) {
        newConfig = await this.calculate(config);
      } else {
        this.cancelCalcImpedance();
      }
      save = true;
    }

    if (signalType === SINGLE && newConfig) {
      delete newConfig.Spacing;
      delete newConfig.Zdiff;
      delete newConfig.spacing;//sierra
    }
    return { type, config: { ...newConfig, type: signalType }, version: _version, save, signalType, autoSave: newConfig && newConfig.layers && newConfig.width }
  }

  saveValue = (e, key) => {
    const value = e.target.value;
    const { errorMsg } = this.props;
    const { config } = this.state;
    let _config = config ? { ...config } : {};
    _config[key] = value;
    this.setState({
      config: _config
    })
    closeCountTime();
    if (errorMsg && errorMsg.type === key) {
      this.props.updateErrorMsg(null);
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  checkData = (e, key) => {
    const value = e.target.value;
    const { product } = this.props;
    const traceList = [Z0, Z_DIFF].includes(key) ? TraceKeyZDArr : product === SIERRA ? SierraTraceKeyArr : TraceKeyArr;
    const title = (traceList.find(item => item.key === key) || {}).title;
    //check value
    const error = checkLibraryValue({ value, key, title });
    if (error) {
      e.target.focus();
      this.props.updateErrorMsg(error);
    } else {
      const { config } = this.state;
      let _config = config ? { ...config } : {};
      _config[key] = value;

      this.setState({
        config: _config,
      }, () => {
        this.calculateImpedance(_config);
      });
    }
  }

  calculateImpedance = (_config, isUpdate) => {
    this.cancelCalcImpedance()
    countTime(() => this.calculate(_config, isUpdate), 3000);
  }

  cancelCalcImpedance = (updateStatus = false) => {
    this.cancelCalc && this.cancelCalc();
    closeCountTime();
    clearInterval(this.time);
    if (this.workflowId) {
      cancelWorkflow(this.workflowId);
    }
    if (!updateStatus) {
      this.setState({
        calculating: false
      })
    }
  }

  calculate = (_config, isUpdate) => {
    const { type, config, signalType, prevCalcConfig } = this.state;
    const { calculateImpedance, product, getTraceImpedanceData } = this.props;
    this.cancelCalcImpedance(true);

    if (product !== SIERRA) {
      _config.Spacing = signalType === SINGLE ? String(Number(_config.Width) * 2) : _config.Spacing;
    }

    let _Config = { ..._config, type: signalType };
    delete _Config[Z0];
    delete _Config[Z_DIFF];
    //Every item exists before calculating impedance
    const values = Object.values(_Config);
    const _values = values.filter(item => !!item);

    if (!isUpdate
      && (_values.length !== values.length
        || _.isEqual((prevCalcConfig || {}), { ..._config, traceType: type, type: signalType }))
    ) {
      this.props.closeAndSave(true, _config);
      return _config;
    }
    this.setState({
      calculating: true
    })

    let calcConfig = JSON.parse(JSON.stringify(_Config));
    let _calculateImpedance = calculateImpedance;

    if (product === SIERRA) {
      calcConfig = { config: { ...calcConfig } };
      //allow cancel request
      let promise, cancel;
      try {
        const resInfo = _calculateImpedance(calcConfig) || {};
        if (!resInfo.promise) {
          this.props.closeAndSave(true, _config);
          return _config;
        }
        promise = resInfo.promise;
        cancel = resInfo.cancel;
      } catch (error) {
        console.error(error)
        this.props.closeAndSave(true, _config);
        return _config;
      }

      this.cancelCalc = cancel;
      let log = [];
      promise.then(async res => {
        const workflowId = res && res.id ? res.id : null;
        this.workflowId = workflowId;
        if (!workflowId) {
          this.setState({
            calculating: false,
            calcErrorLog: [`Calculate Failed: get calculation data failed!`]
          })
          this.props.closeAndSave(true, _config);
          return _config;
        }
        let status = RUNNING;
        try {
          this.setState({
            calcErrorLog: ["Calculating..."]
          })
          let num = 0;
          clearInterval(this.time);
          this.time = setInterval(async () => {
            const res = await getWorkFlow(workflowId);
            const logRes = await getMonitor(workflowId);
            log = logRes && logRes.length > 0 ? logRes.map(item => item.log) : [];
            if (res && res.data && res.data.code === SUCCESS) {
              status = res.data.data.status;
              if (![RUNNING, WAITING].includes(status)) {//success,failed
                clearInterval(this.time);
                const logs = log && log.length > 0 ? [...log] : ["Calculate Z0 completed."];
                this.setState({
                  calcErrorLog: logs
                })
                _calculateImpedance = getTraceImpedanceData(workflowId);
                return this.getImpedanceData({ _calculateImpedance, config, type, signalType, logs })
              } else if ([RUNNING, WAITING].includes(status)) {
                this.setState({
                  calcErrorLog: log && log.length > 0 ? [...log] : ["Calculating..."]
                })

                if (status === WAITING || (!log.length || (log[0] && log[0].match("service is busy")))) {
                  num += 1;
                  if (num > 20) {
                    clearInterval(this.time);
                    this.setState({
                      calculating: false,
                      calcErrorLog: [...log, "Calculate Failed: service is busy!"]
                    })
                    cancelWorkflow(workflowId);
                    this.props.closeAndSave(true, _config);
                    return _config;
                  }
                }
              }
            } else {
              clearInterval(this.time);
              cancelWorkflow(workflowId);
              this.setState({
                calculating: false,
                calcErrorLog: [...log, "Calculate Failed: Get status failed!"]
              })
              this.props.closeAndSave(true, _config);
              return _config;
            }
          }, 1500);
        } catch (error) {
          clearInterval(this.time);
          cancelWorkflow(workflowId);
          this.setState({
            calculating: false,
            calcErrorLog: [...log, "Calculate Failed: Get status failed!"]
          })
          this.props.closeAndSave(true, _config);
          return _config;
        }
      }, error => {
        console.error(error)
        this.setState({
          calculating: false,
          calcErrorLog: [...log, "Calculate Failed: Get status failed!"]
        })
        this.props.closeAndSave(true, _config);
        return _config;
      })
    } else {
      _calculateImpedance = calculateImpedance(calcConfig);
      return this.getImpedanceData({ _calculateImpedance, config, type, signalType })
    }
  }

  getImpedanceData = ({ _calculateImpedance, config, type, signalType, logs = [] }) => {
    return new Promise((resolve, reject) => {
      _calculateImpedance.then(res => {
        let newConfig = { ...config };
        let _errors = [];
        if (res && res[Z0]) {
          newConfig[Z0] = res[Z0] && !isNaN(res[Z0]) ? Number(res[Z0]).toFixed(2) : newConfig[Z0];
          if (signalType === DIFF) {
            newConfig[Z_DIFF] = res[Z_DIFF] && !isNaN(res[Z_DIFF]) ? Number(res[Z_DIFF]).toFixed(2) : newConfig[Z_DIFF];
          }
        } else {
          _errors = typeof (res) === "string" ? res.split("\n") : ["Get Z0 Failed: Unknown reason!"];
        }
        let _prevCalcConfig = { ...JSON.parse(JSON.stringify(newConfig)), traceType: type, type: signalType };
        this.cancelCalc = null;
        this.setState({
          config: newConfig,
          prevCalcConfig: _prevCalcConfig,
          calculating: false,
          calcErrorLog: [...(logs || []), ..._errors]
        }, () => {
          this.props.closeAndSave(true, newConfig);
          resolve(newConfig);
        });
      }, error => {
        console.error(error)
        let _errors = typeof (error) === "string" ? error.split("\n") : ["Get Z0 Failed: Unknown reason!"];
        if (_errors.includes("Request Cancelled!")) {
          _errors = []
        }
        this.setState({
          calculating: false,
          calcErrorLog: [...(logs || []), ..._errors]
        })
        this.cancelCalc = null;
        this.props.closeAndSave(true, this.state.config);
        resolve(this.state.config);
      })
    })
  }

  changeType = (value) => {
    const { config, type } = this.state;
    const { product } = this.props;
    let _config = { ...config };
    if (value === type) {
      return;
    }
    switch (value) {
      case MICROSTRIP:
        if (product === SIERRA) {
          _config.width = "10"
          _config.layers = JSON.parse(JSON.stringify(MicroStripLayers));
        } else {
          delete _config[HEIGHT1];
          delete _config[HEIGHT2];
          _config[HEIGHT] = "8";
        }
        break;
      case STRIPLINE:
        if (product === SIERRA) {
          _config.width = "8"
          _config.layers = JSON.parse(JSON.stringify(StripLineLayers));
        } else {
          delete _config[HEIGHT];
          _config[HEIGHT1] = "8";
          _config[HEIGHT2] = "12";
        }
        break;
      default: break;
    }
    this.setState({
      config: _config,
      type: value
    }, () => {
      this.calculateImpedance(this.state.config);
    });
  }

  changeSignalType = (value) => {
    const { product } = this.props;
    const spacingKey = product === SIERRA ? PRE_LAYOUT_SPACING : SPACING;
    const { config } = this.state;
    const unitKey = product === SIERRA ? PRE_LAYOUT_UNIT : UNIT;
    const unit = config[unitKey];
    const spacing = config[spacingKey] ? config[spacingKey] : unitChange({
      num: 5,
      oldUnit: "mil",
      newUnit: unit || "mil",
      decimals: 4
    }).number.toString();
    this.setState({
      signalType: value,
      config: { ...config, [spacingKey]: spacing }
    }, () => {
      this.props.updateSignalType && this.props.updateSignalType(this.state.signalType)
      this.calculateImpedance(this.state.config);
    })
  }

  getTableData = () => {
    const { config } = this.state;
    const { product } = this.props;
    let dataList = [];
    if (!config) {
      return dataList;
    }

    if (product === SIERRA) {
      return config.layers || [];
    }

    dataList.push({
      [ER]: config[ER],
      [LOSS_TANGENT]: config[LOSS_TANGENT],
      [CONDUCTIVITY]: config[CONDUCTIVITY]
    });
    return dataList;
  }

  pngType = () => {
    const { type, signalType } = this.state;
    const { product } = this.props;
    const _product = product === SIERRA ? `_${product.toLowerCase()}` : "";
    const pngType = type === MICROSTRIP ? "traceMicrostrip" : "traceStripline";
    const signal = signalType === SINGLE ? "_single" : "";
    return `${pngType}${signal}${_product}`;
  }

  getHeight2Style = () => {
    const { signalType } = this.state;
    return { width: signalType === SINGLE ? '76px' : '56px' };
  }

  openLogVisible = (visible) => {
    this.setState({
      logVisible: visible !== undefined ? visible : !this.state.logVisible
    })
  }

  render = () => {
    const { config, type, signalType, calculating, calcErrorLog, logVisible } = this.state;
    const { singleMode, product, libraryId } = this.props;
    const traceKeys = product === SIERRA ? SierraTraceKeyArr : TraceKeyArr;
    const isHalf = signalType === SINGLE ? false : true;

    const traceTypes = [
      { key: MICROSTRIP, title: "Microstrip" },
      { key: STRIPLINE, title: "Stripline" }
    ];
    const zdClassName = product === SIERRA ? "library-item-zd-half-right" : ""
    const data = this.getTableData().map((record, index) => ({ ...record, index }))
    return <div className='trace-content clear'>
      <div className='library-item'>
        {/* type */}
        <label className='library-edit-value-label'>Trace Type</label>
        <Select
          value={type}
          onChange={this.changeType}
          placeholder={"Trace Type"}
          className='aurora-select library-select'
          popupClassName='aurora-select-dropdown'
          getPopupContainer={() => document.getElementById('root')}
        >
          {traceTypes.map(item =>
            <Option key={item.key}>{item.title}</Option>
          )}
        </Select>
      </div>
      {singleMode && <div className='library-item'>
        {/* signal type */}
        <label className='library-edit-value-label'>Signal Type</label>
        <Select
          value={signalType}
          onChange={this.changeSignalType}
          disabled={product === SIERRA && libraryId}
          className='aurora-select library-select'
          popupClassName='aurora-select-dropdown'
          getPopupContainer={() => document.getElementById('root')}
        >
          {signalTypes.map(item =>
            <Option key={item.key}>{item.title}</Option>
          )}
        </Select>
      </div>
      }
      <div className={product === SIERRA ? 'library-png library-png-margin' : 'library-png'}>
        <img src={`/lib/imgs/${this.pngType()}.png`} alt="" />
      </div>
      {/* value list */}
      <div className='clear'>
        {traceKeys.map(item => (!item.type || item.type === type) && (!item.signalType || item.signalType === signalType) && <div
          key={item.key}
          className={isHalf ? 'library-item library-item-half' : 'library-item'}>
          <label className='library-edit-value-label' style={item.key !== HEIGHT2 ? { width: item.width } : this.getHeight2Style()}>{item.title}</label>
          <Input
            key={item.key}
            className='aurora-input library-input'
            value={config && config[item.key] ? config[item.key] : null}
            placeholder={item.title}
            style={item.key !== HEIGHT2 ? { width: `calc(100% - ${item.width || 76}px)` } : { width: `calc(100% - ${this.getHeight2Style()})` }}
            addonAfter={this.addonAfter()}
            onChange={(e) => this.saveValue(e, item.key)}
            onKeyDown={(e) => this.keyDown(e)}
            onBlur={(e) => this.checkData(e, item.key)}
          />
        </div>)}
      </div>
      <Table
        rowKey={(record) => record.index}
        columns={this.TraceColumns}
        size="small"
        dataSource={data}
        className='library-table'
        rowClassName={(record) => {
          if (record.type === METAL) {
            return "aurora-row-background"
          }
        }}
      />
      <div className='library-zd'>
        {TraceKeyZDArr.map(item => (!item.signalType || item.signalType === signalType) &&
          <div key={item.key} className={isHalf ? `library-item library-item-zd library-item-half library-item-zd-half ${zdClassName}` : `library-item library-item-zd library-item-half`}>
            <label className='library-edit-value-label' style={{ width: item.width }}>{item.title}</label>
            <Input
              key={item.key}
              disabled={true}
              className='aurora-input library-input'
              style={{ width: `calc(100% - ${item.width || 76}px)` }}
              value={config && config[item.key] ? config[item.key] : null}
              placeholder={item.title}
              onChange={(e) => this.saveValue(e, item.key)}
              onKeyDown={(e) => this.keyDown(e)}
              onBlur={(e) => this.checkData(e, item.key)}
            />
          </div>)}
        {product === SIERRA ?
          calculating ? <LoadingOutlined
            title={"Calculating..."}
            className={`library-zd-calculate-icon ${isHalf ? "library-zd-calculate-half-icon" : ""}`} /> :
            <RedoOutlined
              className={`library-zd-calculate-icon ${isHalf ? "library-zd-calculate-half-icon" : ""}`}
              title={`Re Calculate Z0${isHalf ? " and Zdiff" : ""}.`}
              onClick={() => this.calculate(this.state.config, true)} /> : null}
        {calcErrorLog && calcErrorLog.length && logVisible ?
          <MsgDialog
            mask={false}
            type="log"
            allowButton={true}
            allowDownload={true}
            messageList={calcErrorLog}
            position='panel-center-top'
            fileName="Calculate_trace_Z0.log"
            className="aurora-msg-dialog-info-panel aurora-trace-log-panel"
            clickOkButton={() => this.openLogVisible(false)}
          /> : null}
        {product === SIERRA && calcErrorLog && calcErrorLog.length ?
          <FileTextOutlined
            className={`library-zd-calculate-icon library-zd-calculate-log-icon ${isHalf ? "library-zd-calculate-log-half-icon" : ""}`}
            title={`Error Logs.`}
            onClick={() => this.openLogVisible()} /> : null}
      </div>
    </div>
  }
}


export default TraceSetup;