import { getSortPoints } from "./hybridHelper";

class LayoutLine {
  constructor(name) {
    this.name = name;
    this.points = [];
    this.boundaries = [];
    this.designID = null;
  }

  addPoint = (point) => {
    this.points.push(point);
  }

  removePoints = () => {
    this.points = [];
  }

  getName = () => {
    return this.name;
  }

  removePoint = (point) => {
    this.points = this.points.filter(item => !(item.x === point.x && item.y === point.y));
  }

  pointsSort = () => {
    if (this.points.length <= 1) {
      return;
    }
    this.points = getSortPoints(this.points);
  }

  getPoints = () => {
    return this.points;
  }

  updatePoints = (points) => {
    this.points = points;
  }
}

class HybridLines {
  constructor(id) {
    this.id = id;
    this.lines = new Map();
  }

  addLine = (name, points) => {
    this.lines.set(name, points)
  }

  removeLine = (name) => {
    this.lines.delete(name)
  }

  getLine = (name) => {
    return this.lines.get(name) || null;
  }

  getLines = () => {
    return this.lines;
  }
}

export default HybridLines;
export {
  LayoutLine
}