import {
  getFileInRockyVerification, getFileInRockyPackageVerification, getSSNResultChannelContent, getSSNResultChannelExistList, getPTSPVerification, getVerificationContentByID
} from '../../../api/Rocky/rockyCtrl';
import { getPTSWSignalsStatus } from '../../../api/Rocky/rockySSNCtrl';
import apiProcess from '../../../api/utility';

function getVerificationJson(channelId, verificationSubId) {
  return new Promise((resolve, reject) => {
    if (!channelId || !verificationSubId) {
      resolve(null);
      return;
    }
    const folderPath = `${verificationSubId}/verification.json`;
    getFileInRockyVerification({ channelId, filePath: folderPath }).then(response => {
      if (response && response.data) {
        resolve({ ...response.data });
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  });
};

function getSSNVerificationJson(verificationSubId) {
  return new Promise((resolve, reject) => {
    if (!verificationSubId) {
      resolve(null);
      return;
    }
    getVerificationContentByID(verificationSubId).then(response => {
      if (response && response.data && response.data.data) {
        resolve({ ...response.data.data });
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  });
}

function getPTSPVerificationJsonPromise(verificationId) {
  return new Promise((resolve, reject) => {
    if (!verificationId) {
      resolve(null);
      return;
    }
    getPTSPVerification(verificationId).then(response => {
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  });
}

function getPortJson({ channelId, verificationSubId, designSubId }) {
  return new Promise((resolve, reject) => {
    if (!channelId || !verificationSubId || !designSubId) {
      resolve(null);
      return;
    }
    //pcb_designId_verificationSubId_ports.json
    //pcb_516238407454822400_516238531174207488_ports.json
    const fileName = `pcb_${designSubId}_${verificationSubId}_ports.json`
    const folderPath = `${verificationSubId}/result/${fileName}`;
    getFileInRockyVerification({ channelId, filePath: folderPath }).then(response => {
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  });
};

function getPackagePortJson({ verificationId }) {
  return new Promise((resolve, reject) => {
    if (!verificationId) {
      resolve(null);
      return;
    }
    const type = `ports_json`;
    getFileInRockyPackageVerification({ channelId: verificationId, filePath: type }).then(response => {
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  });
};

function getSSNResultChannelContentPromise(verificationId) {
  return apiProcess(getSSNResultChannelContent, verificationId)
}

function getSSNResultChannelExistListPromise(channelId) {
  return apiProcess(getSSNResultChannelExistList, channelId)
}

function getPTSWSignalsStatusPromise(channelId) {
  return apiProcess(getPTSWSignalsStatus, channelId)
}

// export function 
export {
  getVerificationJson, getPortJson, getPackagePortJson, getSSNResultChannelContentPromise,
  getSSNResultChannelExistListPromise, getPTSPVerificationJsonPromise, getSSNVerificationJson, getPTSWSignalsStatusPromise
};
