import React, { Component } from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Collapse, Input } from 'antd';
import EditableTable from '@/components/EditableTable';
import {
  getJittersUnits,
  getJittersList,
  getJitterUnit,
  JITTER_PDF_LIST,
  getJitterPDFByType,
  TX_JITTER_PDF,
  RX_JITTER_PDF,
  adsConfigItemErrorCheck
} from '@/services/Andes_v2/AMIModelHelper';
import UnitAddonAfter from '@/components/UnitAddonAfter';
import { IBIS } from "../../../constants/libraryConstants";
import { TX_PJ_AMPLITUDE, TX_PJ_FREQUENCY } from "../../../services/Andes_v2/AMIModelHelper";
import { RANDOM } from "../../../services/Andes_v2/AMIModelHelper/constants";

class JittersTable extends Component {

  constructor(props) {
    super(props);

    props.jittersColumns[0].onCell = (record) => {
      const { jitters, model: { amiParameters }, modelInfo: { dirType }, type } = this.props;
      if (type === IBIS) {
        return;
      }
      const options = getJittersList(dirType);
      const _options = options.filter(item => !jitters.find(it => it.name === item) && !amiParameters.find(it => it.name === item));
      return {
        record,
        edit: "select",
        options: _options,
        dataIndex: "name",
        handleSave: (_record, prevRecord) => { this._editJitterName(_record, prevRecord) },
      }
    };
    props.jittersColumns[0].render = (value, record) => {
      return <span>{record.name}</span>
    }

    props.jittersColumns[1].render = (value, record) => {
      const { type } = this.props;

      if (type === "ami") {
        return this.jitterValueRender(record);
      }

      return [TX_JITTER_PDF, RX_JITTER_PDF].includes(record.name) ?
        <span>{record.type}</span> : <span className="aurora-table-row-disabled"></span>;
    }

    props.jittersColumns[1].onCell = (record) => {

      if (![TX_JITTER_PDF, RX_JITTER_PDF].includes(record.name)) {
        return;
      }

      return {
        record,
        edit: "select",
        options: JITTER_PDF_LIST.map(item => item.key),
        dataIndex: "type",
        handleSave: (_record, prevRecord) => { this.editJittersType(_record, prevRecord) },
      }
    }

    if (props.type === IBIS) {
      props.jittersColumns[2].render = (value, record) => {
        return this.jitterValueRender(record);
      }
    }
  }

  jitterValueRender = (record) => {
    const { type } = this.props;
    if ([TX_JITTER_PDF, RX_JITTER_PDF].includes(record.name)) {
      const jitterPDFType = JITTER_PDF_LIST.find(item => item.key === record.type);
      return jitterPDFType && jitterPDFType.list ?
        <div className='ibis-jitters-pdf-input-main'>
          {jitterPDFType.list.map(item =>
            <div
              key={item}
              className={`ibis-jitter-pdf-input-content ${record.type === RANDOM ? "ibis-jitter-pdf-random-input-content" : ""}`}
              style={{ width: `calc(100% / ${jitterPDFType.list.length})` }}>
              <span className='ibis-jitter-pdf-input-title'>{item}</span>
              <Input
                className='aurora-input'
                value={record[item.toLowerCase()]}
                onChange={(e) => this.changeJitterValue(e, record.name, item.toLowerCase())}
                onBlur={(e) => this.saveJitterValue(e, record.name, item.toLowerCase())}
                addonAfter={"UI"}
              >
              </Input>
            </div>)}
        </div> : null
    }
    return (
      <div className="ads-model-jitter-table-value-content">
        <Input
          className={"ads-model-jitter-input aurora-input"}
          value={record.value}
          addonAfter={this.addonAfter(record.unit, record.name)}
          onChange={(e) => this.changeJitterValue(e, record.name, null, record.unit)}
          onBlur={(e) => this.saveJitterValue(e, record.name, null, record.unit)}
        />
        {type === "ami" ? <CloseOutlined
          className="ami-model-jitter-table-delete-icon"
          onClick={(e) => this.deleteJitter(e, record)} /> : null}
      </div>
    );
  }

  editJittersType = (record) => {
    const { model } = this.props;
    let _model = { ...model };
    const index = _model.jitters.findIndex(item => item.name === record.name);
    if (index < 0) {
      return;
    }
    _model.jitters[index] = getJitterPDFByType(record);

    this.setState({
      model: _model
    })
  }

  deleteJitter = (e, record) => {
    const { model } = this.props;
    let _model = { ...model };
    _model.jitters = _model.jitters.filter(item => item.name !== record.name);
    this.props.saveModel(_model);
  }

  addonAfter = (unit, name) => {
    const unitOptions = getJittersUnits(name);
    if (!unitOptions.length) {
      return unit;
    }
    return UnitAddonAfter({
      popupClassName: "ads-model-jitter-value-unit-dropdown",
      unit,
      changeUnit: (key) => this.changeJitterUnit(key, name),
      list: unitOptions
    });
  }

  changeJitterValue = (e, name, subType) => {
    const value = e.target.value;
    const { model } = this.props;
    const _subType = subType ? subType : "value";
    const errorType = `${name} ${subType || ""}`;
    let _model = { ...model };
    const index = _model.jitters.findIndex(item => item.name === name);
    if (index < 0) {
      return;
    }
    _model.jitters[index][_subType] = value;
    const { error } = this.props;
    const newError = error && error.type === errorType ? null : error;
    this.props.updateError(newError);

    this.props.saveModel(_model);
  }

  saveJitterValue = (e, name, subType, unit) => {
    const { bitRate, type: modelType, model } = this.props;
    const value = e.target.value;
    const errorName = `${name} ${subType || ""}`;

    let pj_amplitude = null;
    if (name === TX_PJ_FREQUENCY) {
      const find = model.jitters.find(item => item.name === TX_PJ_AMPLITUDE);
      pj_amplitude = find ? find.value : ""
    }

    const { error: _error, errorType } = adsConfigItemErrorCheck({
      value,
      type: subType || name,
      bitRate,
      modelType,
      parentType: subType ? name : "",
      unit,
      pj_amplitude
    });
    if (_error) {
      this.props.updateError({ type: errorName, error: _error, errorType });
      return;
    }
    const { error } = this.props;
    const newError = error && error.type === errorName ? null : error;
    this.props.updateError(newError);
    this.changeJitterValue(e, name, subType);
  }

  changeJitterUnit = (key, name) => {
    const { model } = this.props;
    let _model = { ...model };
    const index = _model.jitters.findIndex(item => item.name === name);
    if (index < 0) {
      return;
    }

    _model.jitters[index].unit = key;

    this.props.saveModel(_model);
  }

  _editJitterName = (record, prevRecord) => {
    const { model } = this.props;
    let _model = { ...model };
    const _index = _model.jitters.findIndex(item => item.name === record.name);
    if (_index > -1) {
      return;
    }
    const index = _model.jitters.findIndex(item => item.name === prevRecord.name);
    _model.jitters[index] = {
      name: record.name,
      value: "0",
      unit: getJitterUnit(record.name)
    }
    this.props.saveModel(_model, 'jitter');
  }

  render = () => {
    const { jitters, tableClassName, header, jittersColumns } = this.props;
    const data = jitters ? jitters.map((jitter, index) => ({ ...jitter, index })) : []
    const items = [{
      key: "jitters",
      label: header
        || <div className="ads-model-jitter-title">
          <span>Jitters</span>
        </div>,
      children: <EditableTable
        rowKey={record => record.index}
        columns={jittersColumns}
        dataSource={data || []}
        size="small"
        className={`ads-model-jitters-table ${tableClassName}`}
        tablePadding={true}
      />
    }]
    return <Collapse className="ami-model-collapse-content jitter-collapse-content" items={items} />
  }
}

export default JittersTable