import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import './index.css';

class CreatePanel extends Component {
  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  getText = (message) => {
    switch (message) {
      case 'allChange':
        return 'It is detected that the chip and SPIM have changed, whether to create or update power domains.';
      case 'chipChange':
        return 'It is detected that the Chip has changed, Whether to update power domains.';
      case 'modelChange':
        return 'SPIM has been changed. Click Yes to update the power domains';
      default:
        return 'Do you need to save the package and update power domains?';
    }
  }

  render = () => {
    const { message, closeCreate, closeWithCreate, data } = this.props;
    const maskStyle = true;
    const content = (
      <Panel
        className={maskStyle ? "aurora-confirm-mask-panel pdn-package-save-panel" : 'pdn-package-save-panel'}
        zIndex={2000}
        width={360}
        noHeader={true}
        position='panel-center'
        mask={true}
        maskStyle={maskStyle}
        draggable
        minWidth={360}
        maxWidth={360}
        maxHeight={173}
        createWH
        overflow={'hidden'}
        title='Create PDNs'
        onCancel={() => closeCreate()}
      >
        <div className='pdn-package-create-main'>
          <div className='pdn-package-create-content'>
            {this.getText(message)}
          </div>
          <div className='pdn-package-create-btn'>
            <Fragment>
              <Button
                className='aurora-confirm-ok-button'
                style={{ width: '50%' }}
                onClick={() => closeWithCreate(data,true)}
              >Yes</Button>
              <Button
                className='aurora-confirm-cancel-button'
                style={{ width: '50%' }}
                onClick={() => closeWithCreate(data,false)}
              >No</Button>
            </Fragment>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
};


export default CreatePanel;