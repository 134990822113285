import { CAP, DIODE, FERRITE, IGNORE, IND, JUMPER, LINEAR, LINEAR_SWITCH, LOAD, RES, SPECIALIZED, SWITCH, SWITCHING, TRANSISTOR } from "../../../../constants/componentType";
import auroraDBJson from "../../../Designs/auroraDbData";
import { POWER } from "../../../Designs/constants";
import { getNearbyComponent } from "../../../Designs/helper";
import { userDefaultSettings } from "../../../userDefaultSetting/userDefaultSettingCtrl";
import { splitArrayToArrays } from "../../arrayHelper";
import _ from 'lodash';
import { SortComponents } from "../../sort";

const VRM_CHARACTERISTIC = ['SW', 'VOUT', 'OUT', 'BUCK'];
const VRM_SENSE_PIN = ['FB', 'FEEDBACK', 'VCCA'];
const VRM_NOT_CURRENT_PIN = ['VIN', 'IN', 'GND', 'GPIO', 'FSOB', 'EN', 'PGOOD', 'SCL', 'SDA', 'VSELECT', 'VDDIO'];
// const SWITCH_CHARA = ['OUT', 'IN'];
const COMP = 'comp', NET = 'net', CONNECT = 'connect';
const NONET = 'NONET';

class GetPMICs {
  constructor(props) {
    const {
      ReferenceNets = [], PowerNets = [], designId, COMP_PREFIX_LIB, findExtend = true,
      ExistNets = [], connectInductance = false, chips = [], isAC = false,
      PMIC = [], powerSwitch = [], doNotStuff = [], buckConverter = [], pinMapSense,
      isTemplate = false, pinConnection = [], pmicAndNets = [], pinMap = [], specify = [],
      extraNets = [], componentTable = [], fuzzyMatch
    } = props;
    this.ReferenceNets = ReferenceNets;
    this.PowerNets = PowerNets;
    this.designId = designId;
    this.setting = COMP_PREFIX_LIB;
    this.findExtend = findExtend;
    this.ExistNets = ExistNets;
    this.connectInductance = connectInductance;
    this.chips = chips;
    this.isAC = isAC;
    this.PMIC = PMIC;
    this.powerSwitch = powerSwitch;
    this.doNotStuff = doNotStuff;
    this.buckConverter = buckConverter;
    this.pinMapSense = pinMapSense;
    this.isTemplate = isTemplate;
    this.pinConnection = pinConnection;
    this.pmicAndNets = pmicAndNets;
    this.pinMap = pinMap;
    this.specify = specify; //[{ name, nets, pins}]
    this.extraNets = extraNets;
    this.componentTable = componentTable;
    this.fuzzyMatch = fuzzyMatch || false

    const userSetting = userDefaultSettings.getLocalSetting();
    const { cascadeSettings = {} } = userSetting;
    const { impedanceLevel = 5 } = cascadeSettings;
    this.maxLevel = impedanceLevel;
    this.PMICs = [];
    this.topology = [];
    this.pwrSensePort = []; // power sense
    this.gndSensePort = []; // ground sense
    this.sensePorts = []; // power and gnd sense
    this.PathNets = [];
  }

  getPaths = () => {
    try {
      if (this.PowerNets.length > 0) {
        this.autoFindPMIC()
        return this.topology
      }
      return []
    } catch (error) {
      console.error(error)
      return [];
    }
  }

  getPMICs = () => {
    try {
      let newComponents = [], vrms = []
      if (this.PowerNets.length > 0) {
        this.autoFindPMIC();

        const { topology, PowerNets, designId, isAC, findExtend, ReferenceNets, extraNets, isTemplate } = this;
        if (!topology.length) {
          this.PathNets = [...PowerNets];
        } else if (isAC) {
          if (findExtend) {
            topology.forEach(path => {
              const ind = path.length > 3 && path[path.length - 3] && path[path.length - 3].type === COMP
                ? auroraDBJson.getComponent(designId, path[path.length - 3].name) : {}
              const indCheck = ind.type === IND;
              if (!indCheck) {
                this.PathNets.push(...path.filter(item => item.type === NET).map(item => item.name))
              } else {
                const _path = path.slice(0, -2);
                this.PathNets.push(..._path.filter(item => item.type === NET).map(item => item.name))
              }
              this.PathNets = [...new Set(this.PathNets)]
            })
          } else {
            this.PathNets = [...PowerNets];
          }
        } else {
          this.PathNets = topology.flat().filter(item => item.type === NET).map(item => item.name);
          this.PathNets = [...new Set(this.PathNets)];
        }

        let components = auroraDBJson.getComponentsByNets(designId, [...this.PathNets, ...extraNets]);

        const VRMs = [];
        vrms = JSON.parse(JSON.stringify(this.PMICs))
        for (let PMIC of this.PMICs) {
          let powerPins = [], groundPins = [];
          const { vrm, inductance, title, pwr } = PMIC;
          const VRM_COMP = isAC && title ? title : vrm;
          const power = auroraDBJson.getComponent(designId, pwr);
          const connectPowerNets = topology.map(path => {
            const lastIndex = path.findLastIndex(item => item.name === pwr);
            if (lastIndex < 1) {
              return null;
            }
            const net = path[lastIndex - 1];
            if (!net) {
              return null;
            }
            return net.name;
          }).flat().filter(item => !!item);
          const pwrInfo = [...power.pins.values()].filter(item => item.pin && connectPowerNets.includes(item.net))
          const pwrPins = pwrInfo.map(item => item.pin);
          const pwrNets = [...new Set(pwrInfo.map(item => item.net))];
          powerPins.push({ comp: pwr, pins: pwrPins, net: pwrNets });
          const findIndex = VRMs.findIndex(vrm => _.isEqual(powerPins, vrm.powerPin));
          if (findIndex > -1) {
            if (!VRMs[findIndex].VRM_COMP.includes(VRM_COMP)) {
              VRMs[findIndex].VRM_COMP.push(VRM_COMP);
            }
            continue;
          }

          let gnd = pwr === vrm ? pwr : '';
          if (gnd) {
            const ground = auroraDBJson.getComponent(designId, gnd);
            const gndInfo = [...ground.pins.values()].filter(pin => ReferenceNets.includes(pin.net))
            if (!gndInfo.length) {
              gnd = '';
            }
          }
          if (!gnd) {
            gnd = this.getGround(pwr);
          }
          const ground = auroraDBJson.getComponent(designId, gnd);
          const gndInfo = [...ground.pins.values()].filter(pin => ReferenceNets.includes(pin.net))
          const gndPins = gndInfo.map(item => item.pin);
          const gndPinNets = [...new Set(gndInfo.map(item => item.net))];
          groundPins.push({ comp: gnd, pins: gndPins, net: gndPinNets });

          VRMs.push({
            groundPin: groundPins,
            model: { name: "", id: "" },
            powerPin: powerPins,
            VRM_COMP: isAC ? [VRM_COMP] : VRM_COMP,
            inductance: isAC ? inductance : null
          })
        }

        if (isTemplate) {
          VRMs.forEach(VRM => {
            const { VRM_COMP = [], inductance, powerPin = [] } = VRM;
            if (VRM_COMP.length) {
              const pmicComp = auroraDBJson.getComponent(designId, VRM_COMP[0]);

              const powerNets = this.topology.map(path => {
                const findPmic = path.findLast(item => item.type === "comp");
                if (findPmic && findPmic.name === VRM_COMP[0]) {
                  const net = path.findLast(item => item.type === 'net') || {}
                  return net.name
                } else {
                  return false;
                }
              }).filter(item => !!item);

              if (inductance && powerPin[0]) {
                const inductanceComp = auroraDBJson.getComponent(designId, inductance);
                let groundNet = this.ReferenceNets;
                const groundPins = pmicComp ? [...pmicComp.pins.values()].filter(_item => _item.net === groundNet[0]) : []
                if (powerPin[0].net && powerPin[0].net.length) {
                  powerPin[0].net.forEach(net => {
                    if (!VRM.component) {
                      VRM.component = []
                    }
                    const powerPins = inductanceComp ? [...inductanceComp.pins.values()].filter(item => item.pin && net === item.net) : [];
                    VRM.component.push({
                      pwrComp: inductance,
                      powerPins: powerPins.map(item => item.pin),
                      extendNets: net ? [net] : [],
                      groundNet: [groundNet[0]],
                      groundPins: groundPins.map(item => item.pin),
                    })
                  })
                } else {
                  VRM.component = [{
                    pwrComp: inductance,
                    powerPins: [...powerPin[0].pins],
                    extendNets: [],
                    groundNet: [groundNet[0]],
                    groundPins: groundPins.map(item => item.pin),
                  }]
                }
              } else {
                VRM.component = powerNets.filter(item => !!item).map(item => {
                  let powerNetPins = pmicComp ? [...pmicComp.pins.values()].filter(_item => _item.net === item) : []
                  let groundNet = this.ReferenceNets;
                  const groundPins = pmicComp ? [...pmicComp.pins.values()].filter(_item => _item.net === groundNet[0]) : []
                  return {
                    pwrComp: pmicComp.name,
                    powerPins: powerNetPins.map(item => item.pin),
                    extendNets: [item],
                    groundNet: [groundNet[0]],
                    groundPins: groundPins.map(item => item.pin)
                  }
                })
              }
            }
          })
        }
        this.PMICs = VRMs;

        let senseComps = [], senseNets = []
        if (isAC && this.sensePorts.length) {
          const rule = (item) => {
            return item.type === NET ? false : true
          }
          this.sensePorts.forEach(ports => {
            const { groundSensePort, powerSensePort } = ports;
            const { trueArray: compArray, falseArray: netArray } = splitArrayToArrays({ array: [...powerSensePort, ...groundSensePort], rule });
            senseComps.push(...compArray.map(item => item.name));
            senseNets.push(...netArray.map(item => item.name))
          })
          senseComps = [...new Set(senseComps)];
          senseNets = [...new Set(senseNets)];
          senseComps.forEach(comp => {
            if (!components.find(item => item.name === comp)) {
              components.push(auroraDBJson.getComponent(designId, comp))
            }
          })
        }

        if (!isAC) {
          components = components.filter(comp => comp.type !== CAP);
        }

        const pwrs = vrms.map(pmic => pmic.pwr);
        const usedComps = this.topology.map(path => {
          let compPath = path.filter(item => item.type === COMP);
          if (!isAC) {
            return compPath.map(item => item.name);
          }
          compPath = compPath.slice(1, -1)
          let comps = [];
          for (let comp of compPath) {
            if (pwrs.includes(comp.name)) {
              break;
            }
            comps.push(comp.name)
          }
          return comps
        }).flat()

        const { doNotStuff, componentTable, chips, pinConnection } = this;
        const vrmComps = VRMs.map(vrm => vrm.VRM_COMP).flat()
        newComponents = components.map(comp => {
          const { name, type, pins, value, partName, partNumber } = comp;

          let component = {
            name,
            COMP_TYPE: type,
            usage: type,
            pins: [...pins.values()],
            value,
            part: partName,
            partNumber: partNumber || undefined,
            isMultiNets: false,
            model: type === CAP ? { id: "", name: "" } : undefined
          }

          component.pins = component.pins.filter(pin => [...this.PathNets, ...this.ReferenceNets, ...extraNets, ...senseNets].includes(pin.net));

          if (type === CAP && !component.pins.some(pin => this.ReferenceNets.includes(pin.net))) {
            return null;
          }

          if ([SWITCH, JUMPER, FERRITE, RES, IND].includes(type)) {
            component.pins = component.pins.filter(pin => !this.ReferenceNets.includes(pin.net))
            component.usage = usedComps.includes(component.name) ? component.COMP_TYPE : IGNORE;
            if (component.pins.length > 2) {
              const connection = pinConnection.find(p => p.partNumber === component.partName) || { pinMap: [] };
              let pinNumbers = component.pins.map(pin => pin.pin);
              const _pins = connection.pinMap ? connection.pinMap.filter(map => map && map[0] && map[1] && (pinNumbers.includes(map[0]) || pinNumbers.includes(map[1]))).flat(2) : [];
              let pinMap = _pins.length ? _pins : [];
              if (!pinMap.length) {
                pinNumbers.sort((a, b) => a - b);
                for (let i = 0; i < Math.floor(pinNumbers.length / 2); i++) {
                  if (pinNumbers[i]) {
                    pinMap.push(pinNumbers[i])
                  }
                  if (pinNumbers[pinNumbers.length - 1 - i]) {
                    pinMap.push(pinNumbers[pinNumbers.length - 1 - i])
                  }
                }
              }
              component.pinMap = pinMap;
            }
          } else if (type === CAP) {
            if ([...new Set(component.pins.map(pin => pin.net))].length > 2) {
              component.isMultiNets = true;
            }
            component.value = "";
          }

          if (doNotStuff.includes(name)) {
            return { ...component, usage: IGNORE };
          }
          if ([RES, FERRITE, JUMPER, SWITCH, IND].includes(type)) {
            let itemData = componentTable.find(data => data.name && data.name.includes(name))
            let value = itemData ? (parseFloat(itemData.value) === 0 ? '0' : itemData.value + itemData.unit) : '0'
            return { ...component, value }
          } else if (chips.includes(name)) {
            return { ...component, usage: LOAD }
          } else if (vrmComps.includes(vrmComps.name)) {
            return { ...component, COMP_TYPE: IGNORE, usage: IGNORE }
          } else if (type === CAP) {
            return { ...component, usage: CAP }
          }
          return { ...component, usage: IGNORE }
        }).filter(comp => !!comp)
      }
      const { chips } = this
      newComponents = SortComponents(newComponents, chips)
      return {
        DEBUG_MONITOR: this.topology,
        VRM: this.PMICs,
        PowerNets: this.PathNets,
        Components: newComponents,
        sensePorts: this.sensePorts,
        vrms
      }
    } catch (error) {
      console.error(error)
      return {
        DEBUG_MONITOR: [],
        VRM: [],
        PowerNets: [],
        Components: [],
        sensePorts: [],
        vrms: []
      }
    }
  }

  autoFindPMIC = () => {
    const { PowerNets, chips, designId } = this;

    for (let PowerNet of PowerNets) {
      const powerNetComps = auroraDBJson.getComponentsByNets(designId, [PowerNet]).map(item => item.name)
      const filterChips = chips.filter(chip => powerNetComps.includes(chip));
      for (let chip of filterChips) {
        this.trace({
          comp: chip,
          net: PowerNet,
          level: 0,
          filterComps: [],
          filterNets: [PowerNet],
          path: [{ type: COMP, name: chip }, { type: NET, name: PowerNet }]
        })
      }
    }

    const { specify, topology, pwrSensePort } = this;
    if (specify.length) {
      this.filterNetsBySpecify();
    }
    if (topology.length > 1) {
      // Keep the path have Ind, if all path without Ind, keep all.
      this.filterNetsByInd();
    }
    // If have discrete buck converter, Keep only discrete buck converter.
    this.filterNetsByBuckConverter();
    // Keep last net is power
    this.filterNetsByNetType();
    // Keep min path
    this.filterNetsByLevel();
    // Filter sense
    if (pwrSensePort.length) {
      this.sensePortFilter();
      // Get ground sense
      this.getGndSensePort();
    }
  }

  trace = (options) => {
    const { designId, maxLevel, specify, doNotStuff, isAC, pinConnection } = this;
    const { comp, net, level, filterComps, filterNets, path, pwr } = options;
    const _path = JSON.parse(JSON.stringify(path));
    if (level > maxLevel) {
      return;
    }

    const compInfo = auroraDBJson.getComponent(designId, comp);
    const compType = compInfo.type;
    const fromInd = compType === IND ? true : false;
    const typeList = isAC ? [RES, JUMPER, FERRITE, SWITCH, IND] : [RES, JUMPER, FERRITE, SWITCH, IND, DIODE];

    const components = auroraDBJson.getComponentsByNets(designId, [net]).filter(_comp => ![...doNotStuff, ...filterComps].includes(_comp.name));
    const checkedComps = [];

    if (specify.length) {
      const specifyComps = this.specifyCheck(components, net);
      if (specifyComps.length) {
        this.getPMIC({
          ...options,
          fromInd,
          PMICs: specifyComps,
          pwr
        })
        return;
      }
    }

    const pmicCheck = this.pmicCheck(components, net, fromInd);
    checkedComps.push(...pmicCheck.map(item => item.name));
    if (pmicCheck.length) {
      this.getPMIC({
        ...options,
        fromInd,
        PMICs: pmicCheck,
        pwr
      })
    }


    if ([RES, JUMPER].includes(compType)) {
      const senseCheck = this.senseCheck(components, net);
      checkedComps.push(...senseCheck.map(item => item.name));
      if (senseCheck.length) {
        this.getSense({
          ...options,
          sense: senseCheck
        })
      }
    }

    for (let component of components) {
      const { name, type, pins, partName } = component;

      if (comp === name) {
        continue;
      }

      if (!typeList.includes(type) || checkedComps.includes(name)) {
        continue;
      }

      let nets = auroraDBJson.getNetsByComponent(designId, name).filter(net => ![NONET, ...this.ReferenceNets].includes(net.name));
      if (nets.length > 2 && (type === SWITCH || (type === RES && pins.size > 2))) {
        const connection = pinConnection.find(p => p.partNumber === partName) || { pinMap: [] };
        const prevNetPins = [...pins.values()].filter(pin => pin.net === net);
        const prevPins = prevNetPins.map(item => item.pin)
        const connPins = connection.pinMap ? connection.pinMap.filter(map => map && map[0] && map[1] && (prevPins.includes(map[0]) || prevPins.includes(map[1]))).flat(2) : [];
        if (connPins.length) {
          nets = nets.filter(net => net.pins.get(name).some(pin => connPins.includes(pin)));
        } else {
          const prevPins = type === SWITCH ? prevNetPins.filter(pin => !pin.pinName.match(/(IN)|(EN)|(ON)|(GND)/ig)) : prevNetPins;
          if (prevPins.length) {
            const pinSize = pins.size % 2 === 0 ? pins.size + 1 : pins.size;
            const prevPinNumbers = prevPins.map(pin => Number(pin.pin));
            let nextPins = [...pins.values()].filter(pin => prevPinNumbers.includes(pinSize - Number(pin.pin))).map(pin => pin.pin);
            if (!nextPins.length) {
              nextPins = [...pins.values()].filter(pin => pin.pinName.match(/IN/ig)).map(pin => pin.pin);
            }
            nets = nets.filter(net => net.pins.get(name).some(pin => nextPins.includes(pin)));
          } else {
            continue;
          }
        }
      }
      nets = nets.map(net => net.name);
      for (let netName of nets) {
        if (filterNets.includes(netName)) {
          continue;
        }
        this.trace({
          comp: name,
          net: netName,
          level: level + 1,
          filterComps: [...filterComps, name],
          filterNets: [...filterNets, netName],
          path: [..._path, { type: COMP, name: name }, { type: NET, name: netName }],
          pwr: pwr || name
        })
      }
    }
  }

  chipFilter = (comps) => {
    const { chips, doNotStuff } = this;
    return comps.filter(item => ![...chips, ...doNotStuff].includes(item.name));
  }

  transistorFilter = (comps, isPMICs) => {
    const { buckConverter, ReferenceNets } = this;
    let isTransistor = comps.filter(c => c.type === TRANSISTOR);
    isTransistor = isTransistor.filter(tran => !isPMICs.some(vrm => vrm.name === tran.name));
    if (isTransistor.length) {
      isTransistor = isTransistor.map(trans => {
        const findBuck = buckConverter.find(buck => buck.includes(trans.name));
        if (findBuck && trans.pins && [...trans.pins.values()].some(pin => ReferenceNets.includes(pin.net))) {
          return { ...trans, pins: trans.pins, title: findBuck.join(' - ') }
        }
        return false
      })
    }
    return isTransistor.filter(item => !!item);
  }

  specifyCheck = (components, net) => {
    const { specify, doNotStuff, designId, fuzzyMatch } = this;
    const netInfo = auroraDBJson.getNet(designId, net);
    if (!netInfo) {
      return false;
    }
    const comps = components.filter(comp => {
      if (doNotStuff.includes(comp.name)) {
        return false;
      }
      const specs = specify.filter(sp => sp.name === comp.name);
      if (!specs.length) {
        return false;
      }
      for (let spec of specs) {
        const compPins = netInfo.pins.get(comp.name);
        if (spec.pins && spec.pins.some(pin => compPins.includes(pin))) {
          return true;
        }
        if (spec.nets && spec.nets.includes(net)) {
          return true;
        }
        if (fuzzyMatch && spec) {
          return true
        }
      }
      return false;
    })
    return comps
  }

  pmicCheck = (components, net, fromInd) => {
    const netInfo = auroraDBJson.getNet(this.designId, net);
    const comps = this.chipFilter(components);
    const PMICRule = fromInd ? [SWITCHING, LINEAR_SWITCH, SPECIALIZED] : [LINEAR, SPECIALIZED, LINEAR_SWITCH];
    const PMICFilter = this.PMIC.filter(item => PMICRule.includes(item.type)).map(pmic => pmic.partName);
    const isPMICs = comps.filter(comp => PMICFilter.includes(comp.partName) && this.pmicPinCheck(comp, netInfo));
    const isTransistor = this.transistorFilter(comps, isPMICs);
    return [...isPMICs, ...isTransistor]
  }

  pmicPinCheck = (comp, netInfo) => {
    const { partName, name, pins: pinInfo } = comp;
    const { pinMap, pinMapSense } = this;

    if (!netInfo) {
      return false
    }
    const pins = netInfo.pins.get(name);
    const pinNames = pins.map(pin => pinInfo.get(pin) ? pinInfo.get(pin).pinName : "").filter(name => !!name);

    const curPinMap = pinMap.find(item => item.partNumber === partName);
    if (curPinMap && curPinMap.data && curPinMap.data.length) {
      const output = curPinMap.data.map(item => item.output).flat(2);
      if (output.some(pin => pinNames.includes(pin))) {
        return true;
      }
      return false
    }

    if (pinNames.length) {
      const senseMap = pinMapSense && pinMapSense.get(partName);
      const sensePins = senseMap ? senseMap.map(item => item.sense).flat().filter(item => !!item) : [];
      const notCurPins = pinNames.filter(pinName => this.pinJudge([...sensePins, ...VRM_SENSE_PIN, ...VRM_NOT_CURRENT_PIN], pinName));
      const curPins = pinNames.filter(pinName => !notCurPins.includes(pinName) && this.pinJudge(VRM_CHARACTERISTIC, pinName));
      const unknowPins = pinNames.filter(pinName => !this.pinJudge([...curPins, ...notCurPins, ...VRM_SENSE_PIN, ...VRM_NOT_CURRENT_PIN, ...VRM_CHARACTERISTIC], pinName));
      if (!notCurPins.length || curPins.length || unknowPins.length) {
        return true;
      }
    }

    return false
  }

  pinJudge = (ruleList, pinName) => {
    for (let pinRule of ruleList) {
      if (pinName.includes(pinRule)) {
        return true;
      }
    }
    return false
  }

  senseCheck = (components, net) => {
    const netInfo = auroraDBJson.getNet(this.designId, net);
    const comps = this.chipFilter(components);
    const PMICRule = [SWITCHING, LINEAR_SWITCH, SPECIALIZED, LINEAR];
    const PMICFilter = this.PMIC.filter(item => PMICRule.includes(item.type)).map(pmic => pmic.partName);
    const isSense = comps.filter(comp => PMICFilter.includes(comp.partName) && this.sensePinCheck(comp, netInfo));
    return isSense;
  }

  sensePinCheck = (vrm, netInfo) => {
    const { name, pins: pinInfo } = vrm;
    const pins = netInfo.pins.get(name);
    const pinNames = pins.map(pin => pinInfo.get(pin) ? pinInfo.get(pin).pinName : "").filter(name => !!name);
    if (pinNames.length) {
      // get sensePin
      const partPinMap = this.pinMapSense && this.pinMapSense.get(vrm.partName)
      const currSensePin = (partPinMap && partPinMap.map(item => item.sense).flat().filter(item => !!item)) || []
      const currentPin = pinNames.filter(pinName => currSensePin.some(sense => pinName.toUpperCase().includes(sense.toUpperCase())));
      if (currentPin.length) {
        return true;
      }
    }
    return false;
  }

  getPMIC = ({ PMICs, net, fromInd, path, comp, level, pwr }) => {
    for (let PMIC of PMICs) {
      if (!PMIC) {
        continue;
      }
      const { name, title, type, pins } = PMIC;
      const _path = JSON.parse(JSON.stringify(path));
      let ruleE = !this.ExistNets.length || this.ExistNets.includes(net) ? true : false;
      if (ruleE) {
        const inductance = fromInd ? comp : name;
        const _pwr = this.findExtend ? inductance : pwr || name;
        this.PMICs.push({ vrm: name, inductance, level, title, pwr: _pwr, fromInd });
        const _pins = [...pins.values()].filter(pin => pin.net === net);
        this.topology.push([..._path, { type: COMP, name, title: type === TRANSISTOR ? title : name, pins: _pins }])
      }
    }
  }

  getSense = ({ sense, net, path }) => {
    const { pinMapSense } = this;
    if (!pinMapSense) {
      return;
    }
    for (let sensePMIC of sense) {
      const { name, pins, partName } = sensePMIC;
      const _path = JSON.parse(JSON.stringify(path));
      const _pins = [...pins.values()].filter(pin => pin.net === net);
      const partPinMap = pinMapSense && pinMapSense.get(partName);
      const sensePin = ((partPinMap && partPinMap.map(item => item.sense).flat().filter(item => !!item)) || []).map(sense => sense.toUpperCase())
      const sensePins = _pins.filter(pin => sensePin.includes(pin.pinName.toUpperCase()));
      const newPath = [..._path, { type: COMP, name, pins: sensePins }];
      if (newPath.length > 3) {
        newPath[newPath.length - 3].sense = true;
      } else {
        newPath[newPath.length - 1].sense = true;
      }
      this.pwrSensePort.push(newPath)
    }
  }

  filterNetsBySpecify = () => {
    const { topology, specify, fuzzyMatch } = this;

    const rule = (array) => {
      const pmic = array[array.length - 1] || {};
      const net = array[array.length - 2] || {};
      if (![COMP, CONNECT].includes(pmic.type) || ![NET].includes(net.type)) {
        return false;
      }
      return specify.some(spec => spec.name === pmic.name && (fuzzyMatch || (spec.nets && spec.nets.includes(net.name)) || (spec.pins && spec.pins.some(pin => pmic.pins.find(_pin => _pin.pin === pin)))));
    }
    const { trueArray: specifyArray } = splitArrayToArrays({ array: topology, rule });
    if (specifyArray.length) {
      this.filterPMICs(specifyArray);
    }
  }

  filterNetsByInd = () => {
    const { topology, designId } = this;

    const rule = (array) => {
      const compTypes = array.filter(comp => comp.type === COMP).map(comp => {
        const compInfo = auroraDBJson.getComponent(designId, comp.name);
        if (!compInfo) {
          return false
        }
        return compInfo.type
      })
      return compTypes.includes(IND)
    }
    const { trueArray: indArray } = splitArrayToArrays({ array: topology, rule });
    if (indArray.length) {
      this.filterPMICs(indArray, { fromInd: true });
    }
  }

  filterNetsByBuckConverter = () => {
    const { topology, buckConverter } = this;

    const rule = (array) => {
      const vrm = array[array.length - 1];
      return buckConverter.find(buck => buck.includes(vrm.name)) ? true : false;
    }

    const { trueArray: converterArray } = splitArrayToArrays({ array: topology, rule });
    if (converterArray.length) {
      this.filterPMICs(converterArray);
    }
  }

  filterNetsByNetType = () => {
    const { topology, designId } = this;

    const rule = (array) => {
      const net = array[array.length - 2];
      const netType = auroraDBJson.getNet(designId, net.name);
      return netType === POWER
    }
    const { trueArray: powerArray } = splitArrayToArrays({ array: topology, rule });
    if (powerArray.length) {
      this.filterPMICs(powerArray);
    }
  }

  filterNetsByLevel = () => {
    const { topology } = this;
    const min = Math.min(...topology.map(path => path.length));
    const minArray = topology.filter(path => path.length === min);
    if (minArray.length) {
      this.filterPMICs(minArray)
    }
  }

  filterPMICs = (keepArray, keepParams = {}) => {
    this.topology = JSON.parse(JSON.stringify(keepArray));
    const keepPMICs = keepArray.map(path => ({ name: path[path.length - 1].name, level: (path.length - 1) / 2 - 1 }));
    this.PMICs = this.PMICs.filter(pmic => {
      if (keepParams.fromInd) {
        if (!pmic.fromInd) {
          return false
        }
      }
      const samePMIC = keepPMICs.find(_pmic => _pmic.name === pmic.vrm && _pmic.level === pmic.level);
      if (pmic.vrm === pmic.pwr) {
        return samePMIC ? true : false
      }
      const pwrInPath = keepArray.flat().some(item => item.name === pmic.pwr);
      return pwrInPath && samePMIC ? true : false
    });
  }

  sensePortFilter = () => {
    const { PMICs, pwrSensePort } = this;
    if (!PMICs.length) {
      this.pwrSensePort = [];
      return;
    }
    const vrms = PMICs.map(item => item.vrm);
    let newSensePort = pwrSensePort.filter(item => vrms.includes(item[item.length - 1].name));
    if (newSensePort.length <= 1) {
      this.pwrSensePort = [...newSensePort];
      return;
    }
    const minSet = {};
    newSensePort.forEach(path => {
      const length = path.length, vrm = path[length - 1];
      const vrmKey = `${vrm.name}`
      if (!minSet[vrmKey] || minSet[vrmKey] > length) {
        minSet[vrmKey] = length;
      }
    })
    newSensePort = newSensePort.filter(path => {
      const vrm = path[path.length - 1], vrmKey = `${vrm.name}`
      return minSet[vrmKey] && path.length === minSet[vrmKey]
    });
    this.pwrSensePort = this.filterAndGroupSensePort(newSensePort);
  }

  filterAndGroupSensePort = (sensePorts) => {
    if (!sensePorts || !sensePorts.length) {
      return;
    }
    //filter repeater sense paths
    let filterSenseList = [];
    for (let item of sensePorts) {
      const findItem = filterSenseList.find(it => _.isEqual(it, item));
      if (!findItem) {
        filterSenseList.push(item);
      }
    }

    //group pmic sense pins
    let newSenseList = [], existSenseGroup = new Map();
    for (let item of filterSenseList) {
      const key = item.map(it => it.name).join("::");
      const findItem = existSenseGroup.get(key);
      const currVRMPins = item[item.length - 1].pins || []
      if (!findItem) {
        existSenseGroup.set(key, { sensePath: JSON.parse(JSON.stringify(item)), VRMPins: currVRMPins })
      } else {
        existSenseGroup.set(key, { sensePath: findItem.sensePath, VRMPins: [...findItem.VRMPins, ...currVRMPins] })
      }
    }

    for (let key of Array.from(existSenseGroup.keys())) {
      const item = existSenseGroup.get(key);
      item.sensePath[item.sensePath.length - 1].pins = item.VRMPins;
      newSenseList.push(item.sensePath);
    }

    sensePorts = newSenseList;
    return sensePorts;
  }

  getGndSensePort = () => {
    const { PMICs, pwrSensePort, designId } = this;
    if (!PMICs.length || !pwrSensePort.length) {
      return;
    }
    const vrms = PMICs.map(item => item.vrm);

    for (let pwrSense of (pwrSensePort)) {
      let pwrSensePins = [];
      const senseVrm = pwrSense && pwrSense.length && pwrSense[pwrSense.length - 1] ? pwrSense[pwrSense.length - 1] : null;

      if (!senseVrm || !vrms.includes(senseVrm.name)) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }


      pwrSensePins = (senseVrm.pins || []).map(it => it.pinName);
      const vrmComp = senseVrm.name;
      this.gndSensePort = [];
      const findVrmComp = auroraDBJson.getComponent(designId, vrmComp);
      if (!findVrmComp) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }

      //find gnd sense pin map by pmic part
      const partPinMap = this.pinMapSense.get(findVrmComp.partName);
      if (!partPinMap) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }
      const sensePinMap = partPinMap ? partPinMap.filter(item => item.sense && item.sense.find(it => pwrSensePins.includes(it))) : [];
      const currentGndSensePins = sensePinMap.map(item => item.gndSense).flat().filter(item => !!item) || [];
      const gndSensePins = [...findVrmComp.pins.values()].filter(item => currentGndSensePins.includes(item.pinName));
      if (!gndSensePins || !gndSensePins.length) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }

      //Find the nets connected to the gnd sense pin
      for (let pin of gndSensePins) {

        this.gndSenseConnectors = [];
        if (this.ReferenceNets.includes(pin.net) && this.isTemplate) {
          //If it is directly connected to the sense of reference nets
          this.gndSenseConnectors.push({ name: pin.net, type: NET, sense: false })
          this.gndSenseConnectors.push({
            name: findVrmComp.name,
            type: COMP,
            sense: true,
            pins: [{ net: pin.net, pin: pin.pin, pinName: pin.pinName }]
          })
          this.gndSensePort.push(this.gndSenseConnectors);
          continue;
        }
        //add pmic to sense port list
        this.gndSenseConnectors.push({
          name: findVrmComp.name,
          type: COMP,
          sense: false,
          pins: [{ net: pin.net, pin: pin.pin, pinName: pin.pinName }]
        })
        const { isGndSense, nextNetList = [], findSenseAllPath } = this.gndSenseCheck({ net: pin.net, prev: findVrmComp.name });
        if (!isGndSense) {
          continue;
        }
        //find sense and gnd net
        if (findSenseAllPath) {
          this.gndSenseConnectors.filter(item => !item.allPins);
          this.gndSensePort.push(this.gndSenseConnectors.reverse());
          continue;
        }

        //find sense but gnd net not find
        if (nextNetList && nextNetList.length) {
          this.gndSensePortsList = [];
          this.gndSenseLevel = 1;
          this.existCheckNets = [];
          this.getGndSensePins({
            nets: nextNetList,
            prevList: []
          });
          let _sensePorts = [], allSensePins = [];
          for (let i = 0; i < this.gndSenseConnectors.length; i++) {
            //comp: item.name, type:"comp", sense:true,pins:[],allPins:[]
            const item = this.gndSenseConnectors[i];
            if (i < 2) {
              _sensePorts.push(item);
            } else {
              for (let pin of item.allPins) {
                //find current pin connected net and path connect to reference net
                const findConnNets = this.gndSensePortsList.find(it =>
                  it.length && it[0] && it[0].name === pin.net && it[0].type === "net"
                  && it[it.length - 1] && it[it.length - 1].isRef)
                if (!findConnNets || !findConnNets.length) {
                  continue;
                }
                delete findConnNets[findConnNets.length - 1].isRef;
                allSensePins.push([{
                  ...item,
                  pins: item.pins && item.pins.length >= 2 ? item.pins : [...item.pins, pin]
                },
                ...findConnNets
                ])
              }
            }
          }
          if (!allSensePins || !allSensePins.length) {
            continue;
          }
          if (allSensePins.length > 1) {
            allSensePins.sort((a, b) => { return a.length - b.length });
          }
          _sensePorts.push(...allSensePins[0]);
          _sensePorts.forEach(item => delete item.allPins);
          this.gndSensePort.push(_sensePorts.reverse());
        }
      }
      this.gndSensePort = this.filterAndGroupSensePort(this.gndSensePort);
      const groundSensePort = this.gndSensePort && this.gndSensePort.length ? [...this.gndSensePort[0]] : [];
      this.sensePorts.push({
        powerSensePort: [...pwrSense],
        groundSensePort: groundSensePort
      })
    }
  }

  gndSenseCheck = ({ net }) => {
    const { designId } = this;
    const netPinList = auroraDBJson.getComponentsByNets(designId, [net]).map(comp => [...comp.pins.values()].filter(pin => pin.net === net).map(pin => ({ ...pin, name: comp.name, type: comp.type }))).flat();
    let isGndSense = false, nextNetList = [], findSenseAllPath = false;
    this.gndSenseConnectors.push({ name: net, type: "net", sense: false });
    for (let netPin of netPinList) {
      //netPin.name: sense component
      /*  if (this.doNotStuff.includes(netPin.name)) {
         continue;
       } */
      //get comp type
      const compType = netPin.type;
      if (![JUMPER, RES].includes(compType)) {
        continue;
      }
      // Find another net connected to the sense component
      const checkNets = auroraDBJson.getNetsByComponent(designId, netPin.name).map(net => net.name);
      if (!checkNets.length) {
        continue;
      }
      isGndSense = netPin;
      const findReference = checkNets.find(it => this.ReferenceNets.includes(it));
      const findSenseComp = auroraDBJson.getComponent(designId, netPin.name);
      const gndSensePin = [...findSenseComp.pins.values()].find(item => item.net === netPin.net);
      let gndPin = null, allPins = [];
      //find reference nets, return sense path
      if (findReference) {
        gndPin = [...findSenseComp.pins.values()].find(item => item.net === findReference);
        this.gndSenseConnectors.push({
          name: netPin.name,
          type: "comp",
          compType,
          sense: true,
          pins: [
            { net: netPin.net, pin: gndSensePin.pin, pinName: gndSensePin.pinName },
            { net: findReference, pin: gndPin.pin, pinName: gndPin.pinName }
          ].filter(it => !!it)
        });
        this.gndSenseConnectors.push({ name: findReference, type: "net", sense: false })
        findSenseAllPath = true;
        break;
      } else {
        //exist multi nets,continue to find reference net
        gndPin = checkNets.length === 1 ? [...findSenseComp.pins.values()].find(item => item.net === checkNets[0]) : null;
        nextNetList = [...nextNetList, ...checkNets.map(it => { return { net: it, comp: netPin.name } })];
        allPins = checkNets.map(item => { return [...findSenseComp.pins.values()].find(it => it.net === item) }).filter(it => !!it);
      }
      const gndNetPin = gndPin ? { net: gndPin.net, pin: gndPin.pin, pinName: gndPin.pinName } : null;
      this.gndSenseConnectors.push({
        name: netPin.name,
        type: "comp",
        compType,
        sense: true,
        pins: [
          { net: netPin.net, pin: gndSensePin.pin, pinName: gndSensePin.pinName },
          gndNetPin
        ].filter(it => !!it),
        allPins: [...allPins.map(it => { return { net: it.net, pin: it.pin, pinName: it.pinName } })]
      });
    }
    return { isGndSense, nextNetList, findSenseAllPath };
  }

  getGndSensePins = ({ nets, prevList }) => {
    let netPinList = [];

    if (this.gndSenseLevel > this.maxLevel) {
      return;
    }
    this.gndSenseLevel += 1;
    const { designId } = this;
    for (let item of nets) {
      if (this.existCheckNets.includes(item.net)) {
        continue
      }
      const compPinList = auroraDBJson.getComponentsByNets(designId, [item.net]).filter(it => it.name !== item.comp).map(comp => [...comp.pins.values()].filter(pin => pin.net === item.net).map(pin => ({ ...pin, name: comp.name, type: comp.type }))).flat();

      //Filter out the net connected to the vrm(pmic) or prev comp
      const _netCompPinList = compPinList
      netPinList.push(..._netCompPinList);
      this.existCheckNets.push(item.net);
    }

    for (let netPin of netPinList) {
      //netPin.name: sense component
      /* if (this.doNotStuff.includes(netPin.name)) {
        continue;
      } */
      //get comp type
      const compType = netPin.type;
      const typeList = this.isAC ? [RES, JUMPER, FERRITE, SWITCH, IND] : [RES, JUMPER, FERRITE, SWITCH, IND, DIODE];
      if (!typeList.includes(compType)) {
        continue;
      }
      let newList = [];
      // Find another net connected to the sense component
      let checkNets = auroraDBJson.getNetsByComponent(designId, netPin.name).map(net => net.name);
      if (!checkNets.length) {
        continue;
      }
      const findReference = checkNets.find(it => this.ReferenceNets.includes(it));

      if (findReference) {
        const findSenseComp = auroraDBJson.getComponent(designId, netPin.name);
        const gndPin = [...findSenseComp.pins.values()].find(item => item.net === findReference);
        const gndSensePin = [...findSenseComp.pins.values()].find(item => item.net === netPin.net);
        if (!gndPin || !gndSensePin) {
          continue;
        }
        this.gndSensePortsList.push([
          ...prevList,
          {
            name: netPin.net,
            type: "net",
            sense: false
          },
          {
            name: netPin.name,
            type: "comp",
            compType,
            sense: false
          },
          {
            name: findReference,
            type: "net",
            sense: false,
            isRef: true
          }])
        continue;
      } else {
        newList = [...prevList, {
          name: netPin.net,
          type: "net",
          sense: false
        }, {
          name: netPin.name,
          type: "comp",
          compType,
          sense: false
        }];
        this.getGndSensePins({
          nets: checkNets.map(it => { return { net: it, comp: netPin.name } }),
          prevList: newList
        });
      }
    }
  }

  getGround = (pwr) => {
    const { designId, ReferenceNets, PathNets } = this;
    const caps = auroraDBJson.getComponentsByType(designId, CAP);
    let gnd = getNearbyComponent({ designId, target: pwr, components: caps.map(cap => cap.name), targetNets: PathNets, nets: ReferenceNets, sameLayer: true });
    if (!gnd) {
      gnd = getNearbyComponent({ designId, target: pwr, components: caps.map(cap => cap.name), targetNets: PathNets, nets: ReferenceNets, sameLayer: false });
    }
    return gnd
  }
}

export default GetPMICs