import CanvasObject from './CanvasObject';

var CanvasEllipse = function () {
  CanvasObject.call(this);
  this.cx = 0;
  this.cy = 0;
  this.rx = 0;
  this.ry = 0;
  this.rotate = 0;
};

CanvasEllipse.prototype = Object.create(CanvasObject.prototype);
CanvasEllipse.prototype.constructor = CanvasEllipse;

export default CanvasEllipse;