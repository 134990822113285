import {
  INTERFACE_TYPE,
  PCIE_2,
  PCIE_3,
  PCIE_4,
  PCIE_5,
  BER,
  PORT_TX_VPKPK,
  JITTER_UNIT,
  JIT_PWRJ,
  JIT_PWDDJ,
  JIT_HFRJ_NUI,
  JIT_LFRJ,
  JIT_LFDDJ,
  ADAPT_EQ,
  ADAPT_FOM,
  ADAPT_CSPACE,
  ADAPT_DC,
  ADAPT_POLE,
  ADAPT_INC_XTALK,
  STATSIM_OPTIONS,
  NUI,
  SEA_SEM_DATA_RATE,
  SECOND,
  UI,
  USE_ADAPT_CSPACE,
  TX_PRE_SHOOT,
  TX_DEEMPHASIS,
  LEQ_DC,
  LEQ_POLE,
  LEQ_DC2,
  LEQ_POLE2,
  ADAPT_PS,
  ADAPT_DE,
  ADAPT_DC2,
  ADAPT_POLE2,
  LEQ_BW,
  LEQ_AC,
  LEQ_BW2,
  LEQ_AC2,
  ADAPT_NUI,
  PRE_CURSORS,
  JIT_HFRJ_GROWTH,
  ADAPT_PSDE_PAIRS,
  VPT,
  NUI_IFFT,
  CURSOR_BLOCK,
  SQRT,
  USE_ADAPT_TX_PRESET,
  ADAPT_TX_PRESET,
  USE_LEQ_RESPONSE,
  LEQ_RESPONSE,
  LEQ_RESPONSE_ID,
  ADAPT_LEQ_INDEX1,
  ADAPT_LEQ_INDEX2,
  LEQ_RESPONSE_INDEX1,
  LEQ_RESPONSE_INDEX2,
  ADAPT_DFE_MAG,
  ADAPT_DFE_H1H0,
  RXCOEFF
} from "./constants";

class SeaSimAnalysisConfig {
  constructor(type, params = {}) {
    const _type = type ? type : PCIE_4;
    this[INTERFACE_TYPE] = _type;
    this[BER] = 1e-12;
    this[PORT_TX_VPKPK] = 0.8;
    this[SEA_SEM_DATA_RATE] = this.getDataRate(_type);
    this[STATSIM_OPTIONS] = new AnalysisStatsimOptions(_type);
  }

  getDataRate = (type) => {
    switch (type) {
      case PCIE_2:
        return "5GT/s";
      case PCIE_3:
        return "8GT/s";
      case PCIE_4:
        return "16GT/s";
      case PCIE_5:
        return "32GT/s";
      default: return;
    }
  }
}

class AnalysisStatsimOptions {
  constructor(type) {
    this.getStatsimOptions(type);
  }

  getStatsimOptions = (type) => {
    switch (type) {
      case PCIE_2:
        this.getPCIE2Options();
        break;
      case PCIE_3:
        this.getPCIE3Options();
        break;
      case PCIE_4:
        this.getPCIE4Options();
        break;
      case PCIE_5:
        this.getPCIE5Options();
        break;
      default: break;
    }
  }

  getPCIE2Options = () => {
    this[NUI] = 80;
    this[JITTER_UNIT] = SECOND;
    const jitterConfig = new Pcie2JitterConfig(SECOND);
    for (let item in jitterConfig) {
      this[item] = jitterConfig[item];
    }
    const eqConfig = new Pcie2EqualizationNotAdaptConfig();
    for (let item in eqConfig) {
      this[item] = eqConfig[item];
    }
    const generalConfig = new Pcie2GeneralConfig()
    for (let item in generalConfig) {
      this[item] = generalConfig[item];
    }
  }

  getPCIE3Options = () => {
    this[NUI] = 80;
    this[JITTER_UNIT] = SECOND;
    const jitterConfig = new Pcie3JitterConfig(SECOND);
    for (let item in jitterConfig) {
      this[item] = jitterConfig[item];
    }
    const eqConfig = new Pcie3EqualizationAdaptConfig();
    for (let item in eqConfig) {
      this[item] = eqConfig[item];
    }
    const generalConfig = new Pcie2GeneralConfig()
    for (let item in generalConfig) {
      this[item] = generalConfig[item];
    }
  }

  getPCIE4Options = () => {
    this[NUI] = 120;
    this[JITTER_UNIT] = SECOND;
    const jitterConfig = new Pcie4JitterConfig(SECOND);
    for (let item in jitterConfig) {
      this[item] = jitterConfig[item];
    }
    const eqConfig = new Pcie4EqualizationAdaptConfig();
    for (let item in eqConfig) {
      this[item] = eqConfig[item];
    }
    const generalConfig = new Pcie4GeneralConfig()
    for (let item in generalConfig) {
      this[item] = generalConfig[item];
    }
  }

  getPCIE5Options = () => {
    this[NUI] = 120;
    this[JITTER_UNIT] = SECOND;
    const jitterConfig = new Pcie5JitterConfig(SECOND);
    for (let item in jitterConfig) {
      this[item] = jitterConfig[item];
    }
    const eqConfig = new Pcie5EqualizationAdaptConfig();
    for (let item in eqConfig) {
      this[item] = eqConfig[item];
    }
    const generalConfig = new Pcie5GeneralConfig()
    for (let item in generalConfig) {
      this[item] = generalConfig[item];
    }
  }
}

function getJitterConfigByUnit(type, unit) {
  switch (type) {
    case PCIE_2:
      return new Pcie2JitterConfig(unit);
    case PCIE_3:
      return new Pcie3JitterConfig(unit);
    case PCIE_4:
      return new Pcie4JitterConfig(unit);
    case PCIE_5:
      return new Pcie5JitterConfig(unit);
    default: return;
  }
}

function Pcie2JitterConfig(unit) {
  //1ui = (1 / data_rate)s , 1ui = 2e-10s
  //1s = (1 / 2e-10) ui = 5e9 ui
  this[JIT_HFRJ_GROWTH] = SQRT;
  if (unit === SECOND) {
    this[JIT_PWRJ] = 0;
    this[JIT_PWDDJ] = 40e-12;
    this[JIT_HFRJ_NUI] = 1.42e-12;
    this[JIT_LFRJ] = 3.4e-12;
    this[JIT_LFDDJ] = 20e-12;
  } else if (unit === UI) {
    this[JIT_PWRJ] = 0.0;
    this[JIT_PWDDJ] = 0.2;
    this[JIT_HFRJ_NUI] = 0.0071;
    this[JIT_LFRJ] = 0.017;
    this[JIT_LFDDJ] = 0.1;
  }
}

function Pcie3JitterConfig(unit) {
  //1ui = (1 / data_rate)s , 1ui = 1.25e-10s
  //1s = (1 / 1.25e-10) ui = 8e9 ui
  //ui = 8e9 * second
  this[JIT_HFRJ_GROWTH] = SQRT;
  if (unit === SECOND) {
    this[JIT_PWRJ] = 0.995e-12;
    this[JIT_PWDDJ] = 10e-12;
    this[JIT_HFRJ_NUI] = 1.105e-12;
    this[JIT_LFRJ] = 1.314e-12;
    this[JIT_LFDDJ] = 7e-12;
  } else if (unit === UI) {
    this[JIT_PWRJ] = 0.008;
    this[JIT_PWDDJ] = 0.08;
    this[JIT_HFRJ_NUI] = 0.009;
    this[JIT_LFRJ] = 0.011;
    this[JIT_LFDDJ] = 0.056;
  }
}

function Pcie4JitterConfig(unit) {
  //1ui = (1 / data_rate)s , 1ui = 6.25e-11s
  //1s = (1 / 6.25e-11) ui = 1.6e10 ui
  this[JIT_HFRJ_GROWTH] = SQRT;
  if (unit === SECOND) {
    this[JIT_PWRJ] = 0.533e-12;
    this[JIT_PWDDJ] = 5e-12;
    this[JIT_HFRJ_NUI] = 0.394e-12;
    this[JIT_LFRJ] = 0.71e-12;
    this[JIT_LFDDJ] = 3.75e-12;
  } else if (unit === UI) {
    this[JIT_PWRJ] = 0.009;
    this[JIT_PWDDJ] = 0.08;
    this[JIT_HFRJ_NUI] = 0.006;
    this[JIT_LFRJ] = 0.011;
    this[JIT_LFDDJ] = 0.06;
  }
}

function Pcie5JitterConfig(unit) {
  //1ui = (1 / data_rate)s , 1ui = 3.125e-11s
  //1s = (1 / 3.125e-11) ui = 3.2e10 ui
  this[JIT_HFRJ_GROWTH] = SQRT;
  if (unit === SECOND) {
    this[JIT_PWRJ] = 0.267e-12;
    this[JIT_PWDDJ] = 2.5e-12;
    this[JIT_HFRJ_NUI] = 0.222e-12;
    this[JIT_LFRJ] = 0.276e-12;
    this[JIT_LFDDJ] = 1.875e-12;
  } else if (unit === UI) {
    this[JIT_PWRJ] = 0.008;
    this[JIT_PWDDJ] = 0.08;
    this[JIT_HFRJ_NUI] = 0.007;
    this[JIT_LFRJ] = 0.009;
    this[JIT_LFDDJ] = 0.06;
  }
}

function getEqualizationConfig(type, adaptEq, statsim_options) {
  let config = {}, deleteKeys = [
    TX_PRE_SHOOT,
    TX_DEEMPHASIS,
    LEQ_DC,
    LEQ_POLE,
    LEQ_DC2,
    LEQ_POLE2,
    ADAPT_PS,
    ADAPT_DE,
    USE_ADAPT_CSPACE,
    ADAPT_CSPACE,
    ADAPT_DC,
    ADAPT_POLE,
    ADAPT_DC2,
    ADAPT_POLE2,
    LEQ_BW,
    LEQ_AC,
    LEQ_BW2,
    LEQ_AC2,
    ADAPT_INC_XTALK,
    ADAPT_FOM,
    ADAPT_PSDE_PAIRS,
    ADAPT_NUI,
    USE_ADAPT_TX_PRESET,
    ADAPT_TX_PRESET,
    USE_LEQ_RESPONSE,
    LEQ_RESPONSE,
    LEQ_RESPONSE_ID,
    ADAPT_LEQ_INDEX1,
    ADAPT_LEQ_INDEX2,
    LEQ_RESPONSE_INDEX1,
    LEQ_RESPONSE_INDEX2,
    ADAPT_DFE_MAG,
    ADAPT_DFE_H1H0,
    RXCOEFF];

  //delete prev equalization parameters
  for (let key of deleteKeys) {
    delete statsim_options[key]
  }

  //get default equalization by pcie protocol type
  switch (type) {
    case PCIE_2:
      config = adaptEq ?
        new Pcie2EqualizationAdaptConfig() :
        new Pcie2EqualizationNotAdaptConfig();
      break;
    case PCIE_3:
      config = adaptEq ?
        new Pcie3EqualizationAdaptConfig() :
        new Pcie3EqualizationNotAdaptConfig();
      break;
    case PCIE_4:
      config = adaptEq ?
        new Pcie4EqualizationAdaptConfig() :
        new Pcie4EqualizationNotAdaptConfig();
      break;
    case PCIE_5:
      config = adaptEq ?
        new Pcie5EqualizationAdaptConfig() :
        new Pcie5EqualizationNotAdaptConfig();
      break;
    default: break;
  }

  statsim_options = {
    ...statsim_options,
    ...config
  }
  return statsim_options;
}

/* === PCIe 2 EQ === */
function Pcie2EqualizationNotAdaptConfig() {
  this[ADAPT_EQ] = false;
  this[ADAPT_INC_XTALK] = true;
  this[ADAPT_FOM] = "area";
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[LEQ_RESPONSE_INDEX1] = 0;
  this[LEQ_RESPONSE_INDEX2] = 0;
  this[RXCOEFF] = [0]
}

function Pcie2EqualizationAdaptConfig() {
  this[ADAPT_EQ] = true;
  this[ADAPT_INC_XTALK] = true;
  this[ADAPT_FOM] = "area";
  this[USE_ADAPT_CSPACE] = true;
  this[ADAPT_CSPACE] = 24.0;
  this[ADAPT_NUI] = 20;
  this[ADAPT_PSDE_PAIRS] = true;
  this[USE_ADAPT_TX_PRESET] = false;
  this[ADAPT_TX_PRESET] = [];
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[ADAPT_LEQ_INDEX1] = [0];
  this[ADAPT_LEQ_INDEX2] = [0];
  this[ADAPT_DFE_MAG] = [];
  this[ADAPT_DFE_H1H0] = 1
}

/* === PCIe 3 EQ === */
function Pcie3EqualizationNotAdaptConfig() {
  this[ADAPT_EQ] = false;
  this[ADAPT_FOM] = "area";
  this[ADAPT_INC_XTALK] = true;
  this[LEQ_POLE] = "2e9";
  this[LEQ_DC] = -6;
  this[LEQ_BW] = "8e9";
  this[LEQ_AC] = 0;
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[LEQ_RESPONSE_INDEX1] = 0;
  this[LEQ_RESPONSE_INDEX2] = 0;
  this[RXCOEFF] = [0]
}

function Pcie3EqualizationAdaptConfig() {
  this[ADAPT_EQ] = true;
  this[ADAPT_FOM] = "area";
  this[ADAPT_INC_XTALK] = true;
  this[USE_ADAPT_CSPACE] = true;
  this[ADAPT_CSPACE] = 24.0;
  this[ADAPT_DC] = [
    -12.0,
    -11.0,
    -10.0,
    -9.0,
    -8.0,
    -7.0,
    -6.0
  ];
  this[ADAPT_POLE] = [
    "2e9"
  ];
  this[LEQ_BW] = "8e9";
  this[LEQ_AC] = 0;
  this[ADAPT_NUI] = 20;
  this[ADAPT_PSDE_PAIRS] = true;
  this[USE_ADAPT_TX_PRESET] = false;
  this[ADAPT_TX_PRESET] = [];
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[ADAPT_LEQ_INDEX1] = [0];
  this[ADAPT_LEQ_INDEX2] = [0];
  this[ADAPT_DFE_MAG] = [0.03];
  this[ADAPT_DFE_H1H0] = 1
}

/* === PCIe 4 EQ === */
function Pcie4EqualizationNotAdaptConfig() {
  this[ADAPT_EQ] = false;
  this[ADAPT_FOM] = "area";
  this[ADAPT_INC_XTALK] = true;
  this[LEQ_POLE] = "2e9";
  this[LEQ_DC] = -6;
  this[LEQ_BW] = "16e9";
  this[LEQ_AC] = 0;
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[LEQ_RESPONSE_INDEX1] = 0;
  this[LEQ_RESPONSE_INDEX2] = 0;
  this[RXCOEFF] = [0]
}

function Pcie4EqualizationAdaptConfig() {
  this[ADAPT_EQ] = true;
  this[ADAPT_FOM] = "area";
  this[ADAPT_INC_XTALK] = true;
  this[USE_ADAPT_CSPACE] = true;
  this[ADAPT_CSPACE] = 24.0;
  this[ADAPT_DC] = [
    -12.0,
    -11.0,
    -10.0,
    -9.0,
    -8.0,
    -7.0,
    -6.0
  ];
  this[ADAPT_POLE] = [
    "2e9"
  ];
  this[LEQ_BW] = "16e9";
  this[LEQ_AC] = 0;
  this[ADAPT_NUI] = 20;
  this[ADAPT_PSDE_PAIRS] = true;
  this[USE_ADAPT_TX_PRESET] = false;
  this[ADAPT_TX_PRESET] = [];
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[ADAPT_LEQ_INDEX1] = [0];
  this[ADAPT_LEQ_INDEX2] = [0];
  this[ADAPT_DFE_MAG] = [0.03, 0.02];
  this[ADAPT_DFE_H1H0] = 1
}

/* === PCIe 5 EQ === */
function Pcie5EqualizationNotAdaptConfig() {
  this[ADAPT_EQ] = false;
  this[ADAPT_FOM] = "area";
  this[LEQ_POLE] = "9.5e9";
  this[LEQ_DC] = -6;
  this[LEQ_BW] = "28e9";
  this[LEQ_AC] = 0;
  this[LEQ_POLE2] = "742.5e6";
  this[LEQ_DC2] = 0;
  this[LEQ_BW2] = "28e9";
  this[LEQ_AC2] = 4.35;
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[LEQ_RESPONSE_INDEX1] = 0;
  this[LEQ_RESPONSE_INDEX2] = 0;
  this[RXCOEFF] = [0]
}

function Pcie5EqualizationAdaptConfig() {
  this[ADAPT_EQ] = true;
  this[ADAPT_FOM] = "area";
  this[ADAPT_INC_XTALK] = true;
  this[USE_ADAPT_CSPACE] = true;
  this[ADAPT_CSPACE] = 24.0;
  this[ADAPT_DC] = [
    -15.0,
    -14.0,
    -13.0,
    -12.0,
    -11.0,
    -10.0,
    -9.0,
    -8.0,
    -7.0,
    -6.0,
    -5.0
  ];
  this[ADAPT_DC2] = [
    0
  ];
  this[ADAPT_POLE] = [
    "9.5e9"
  ];
  this[ADAPT_POLE2] = [
    "742.5e6"
  ];
  this[LEQ_BW] = "28e9";
  this[LEQ_AC] = 0;
  this[LEQ_BW2] = "28e9";
  this[LEQ_AC2] = 4.35;
  this[ADAPT_NUI] = 80;
  this[ADAPT_PSDE_PAIRS] = true;
  this[USE_ADAPT_TX_PRESET] = false;
  this[ADAPT_TX_PRESET] = ["P0", "P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9"];
  this[USE_LEQ_RESPONSE] = false;
  this[LEQ_RESPONSE] = "";
  this[LEQ_RESPONSE_ID] = "";
  this[ADAPT_LEQ_INDEX1] = [0];
  this[ADAPT_LEQ_INDEX2] = [0];
  this[ADAPT_DFE_MAG] = [0.06, 0.02, 0.02];
  this[ADAPT_DFE_H1H0] = 0.8;
}

function Pcie2GeneralConfig() {
  this[VPT] = 1024;
  this[CURSOR_BLOCK] = 5;
  this[NUI_IFFT] = 64;
  this[PRE_CURSORS] = 2;
}
function Pcie4GeneralConfig() {
  this[VPT] = 1024;
  this[CURSOR_BLOCK] = 5;
  this[NUI_IFFT] = 64;
  this[PRE_CURSORS] = 2;
}
function Pcie5GeneralConfig() {
  this[VPT] = 4096;
  this[CURSOR_BLOCK] = 6;
  this[NUI_IFFT] = 4096;
  this[PRE_CURSORS] = 2;
}

function getSeasimDefaultValue(type, adapt_eq) {
  let config = {};
  config[PRE_CURSORS] = 2;
  config[JIT_HFRJ_GROWTH] = SQRT;
  if (adapt_eq) {
    config[ADAPT_PSDE_PAIRS] = true;
  }

  switch (type) {
    case PCIE_2:
    case PCIE_3:
    case PCIE_4:
      config[VPT] = 1024;
      config[CURSOR_BLOCK] = 5;
      config[NUI_IFFT] = 64;
      if (adapt_eq) {
        config[ADAPT_NUI] = 20;
      }
      break;
    case PCIE_5:
      config[VPT] = 4096;
      config[CURSOR_BLOCK] = 6;
      config[NUI_IFFT] = 4096;
      if (adapt_eq) {
        config[ADAPT_NUI] = 80;
      }
      break;
    default: break;
  }
  return config;
}

export {
  SeaSimAnalysisConfig,
  AnalysisStatsimOptions,
  getJitterConfigByUnit,
  getEqualizationConfig,
  getSeasimDefaultValue
}
