import React, { Component, Fragment } from "react";
import { ExclamationCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Dropdown, Tooltip } from "antd";
import { DESIGN_FAILED } from '@/constants/designCategory';
import { PRE_LAYOUT } from '@/constants/designVendor';
import { strDelimited } from "@/services/helper/split";
import { UPLOAD_PCB, REPLACE_PCB } from "@/constants/treeConstants";
import { AC_PRE_LAYOUT, DC_PRE_LAYOUT, postLayoutItem } from "../../services/PCBHelper/pcbHelper";

class UploadPcb extends Component {
  constructor(props) {
    super(props);
    const { key } = props.data;
    const { type } = props;
    const projectId = strDelimited(key, "-", { returnIndex: 1 });
    this.projectID = type === UPLOAD_PCB ? projectId : props.data.projectId;
    this.state = {
      uploadVisible: false,
      replaceVisible: false
    }
  }

  uploadPCBClick = (e, vendor, format, update) => {
    e.domEvent.stopPropagation();
    this.setState({
      uploadVisible: false,
      replaceVisible: update ? true : false
    }, () => {
      if (vendor === PRE_LAYOUT) {
        const { data } = this.props;
        this.props._createPreLayout(data.dataType, format);
      } else {
        this.child && this.child.uploadPCB(vendor, format, update);
      }
    })
  }

  addClick = (e, key) => {
    e.stopPropagation();
    this.props._saveCurrentPCBKey(key);
  }

  pcbMenuClick = (e, item, update) => {
    e.domEvent.stopPropagation();
    if (item.key === "post_layout") {
      this.setState({
        uploadVisible: true,
        replaceVisible: false
      })
      this.props._changeUploadDisableStatus(true);
      return;
    }
    this.uploadPCBClick(e, item.vendor, item.accept, update)
  }

  menu = (update) => {
    if (!update) {
      const { pcbList } = this.props;
      //AC_PRE_LAYOUT, DC_PRE_LAYOUT,
      const preLayoutList = pcbList.filter(item => [AC_PRE_LAYOUT, DC_PRE_LAYOUT, PRE_LAYOUT].includes(item.vendor));
      return [...preLayoutList, postLayoutItem()].map(item => {
        return {
          key: item.key,
          className: item.className,
          label: item.title,
          onClick: (e) => this.pcbMenuClick(e, item, update)
        }
      }
      )
    }
    return this.props.pcbList.map(item => {
      return this.getPCBDisabled(item, update) ?
        {
          key: item.key,
          className: item.className,
          label: item.title,
          onClick: item.children ? null : (e) => this.uploadPCBClick(e, item.vendor, item.accept, update),
          children: item.children ? item.children.map(child => ({ key: child.key, className: child.className, label: child.title, onClick: (e) => this.uploadPCBClick(e, child.vendor, child.accept, update) })) : null
        } : null
    }).filter(item => !!item)
  }

  getPCBDisabled = (itemData, update) => {
    //Develop, replace-> disabled pre layout
    return (!itemData.develop || this.develop) && (!update || itemData.vendor !== PRE_LAYOUT);
  }

  onRef = (ref) => {
    this.child = ref;
  }

  closePanel = (type) => {
    if (type === REPLACE_PCB) {
      this.setState({
        replaceVisible: false
      })
    }
    this.setState({
      uploadVisible: false
    })
  }

  render() {
    const { data, disabled, type, DesignUpload, projectsDB, uploadDesignType, pcbList, product, uploadPromise, replaceDesignPromise } = this.props;
    const { uploadVisible, replaceVisible } = this.state;
    const title = type === REPLACE_PCB ? "PCB Replace" : null;
    const className = type === REPLACE_PCB ? "aurora-PCB-replace" : "aurora-PCB-upload";
    const upload = uploadDesignType === 'Package' ? 'package' : 'pcb';
    return (
      <Fragment>
        <div className={className}>
          <Tooltip
            title={disabled ? `There is already a ${upload} being uploaded.` : title}
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
            key={data.key}
            overlayClassName='pcb-icon-tooltip'
            autoAdjustOverflow={false}
          >
            {type === UPLOAD_PCB ? this.pcbUpload({ data, disabled: disabled || uploadVisible }) : null}
            {type === REPLACE_PCB && data.vendor !== PRE_LAYOUT && data.category !== DESIGN_FAILED ? this.pcbReplace({ data, disabled }) : null}
          </Tooltip>
          {data.category === DESIGN_FAILED &&
            <Tooltip title="PCB upload failed" overlayClassName='aurora-tooltip aurora-design-tooltip'>
              <ExclamationCircleOutlined className="design-failed-icon" />
            </Tooltip>}
        </div>
        {
          (type === REPLACE_PCB && replaceVisible)
            || uploadVisible ?
            <DesignUpload
              onRef={this.onRef}
              data={data}
              type={type}
              visiblePanel={uploadVisible}
              pcbVendorList={pcbList}
              projectId={this.projectID}
              product={product}
              projectsDB={projectsDB}
              uploadPromise={uploadPromise}
              replaceDesignPromise={replaceDesignPromise}
              getPCBDisabled={this.getPCBDisabled}
              closePanel={() => this.closePanel(type)}
            /> : null}
      </Fragment>
    );
  }

  pcbUpload = ({ data, disabled }) => {
    return (
      <Dropdown menu={{ items: this.menu(false), className: "upload-dropdown-button" }} trigger={['click']} disabled={data.iconDisabled || disabled}>
        <PlusSquareOutlined
          className={data.iconDisabled || disabled ? 'aurora-tree-add-icon icon-disabled' : 'aurora-tree-add-icon'}
          key={data.key}
          onClick={(e) => this.addClick(e, UPLOAD_PCB)} />
      </Dropdown>
    );
  }

  pcbReplace = ({ data, disabled }) => {
    return <Dropdown disabled={disabled} menu={{ items: this.menu(true), className: "upload-dropdown-button" }} trigger={['click']}>
      <span key={data.key}
        onClick={(e) => this.addClick(e, REPLACE_PCB)}
        className={disabled ? `iconfont icon-replace1 aurora-replace-icon icon-disabled` : `iconfont icon-replace1 aurora-replace-icon`}></span>
    </Dropdown>
  }
}

export default UploadPcb;