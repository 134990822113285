import { takeEvery, select, put, call, delay, fork } from "@redux-saga/core/effects";
import {
  START_IMP_SIMULATION,
} from '../actionType';
import {
  updateMultiSimulationInfo
} from '../action'
import { runImpedance, ImpedanceErrorCheck, saveImpedanceDomain, ImpedanceWarningCheck } from '@/services/Cascade/Impedance';
import { updateError, updateWarning } from '../../Impedance/action';
import { updateSimulationLoading } from "../../project/action";
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { changeTabMenu, openTabFooter } from "../../../../MonitorStore/action";
import { getInterfaceWorkStatus } from '@/services/project';
import { getStackupErrorCheck, startNewWorkflow } from '../simulationSaga';
import { initSimulationInfo } from '../action';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { updateImpedanceResultExist } from '../../Impedance/action'
import { updateSignOffTemplateListStatus } from "../../SignOffTemplate/action";
import CascadeChannels from "../../../../../services/Cascade/DB/cascadeChannels";
import { SIGN_OFF_TEMPLATE, IMPEDANCE } from "../../../../../constants/treeConstants";
import { getImpedanceHistorys, saveCascadeCutDesign, POWERSI, getNetsAndCompsFromDetails } from '@/services/Cascade/Impedance';
import { IMPEDANCE_DIE, IMPEDANCE_PACKAGE } from "../../../../../services/Cascade/constants";
import { getPowerNetsBoundaryArr } from '@/services/helper/cutDesignHelper';
import { updateErrorToTemplate } from "../../SignOffTemplate/signOffTemplateSaga";
import { getCascadeSimulationLog } from '@/services/Cascade/simulation/simulationCtrl';
import _LayoutData from "../../../../../services/data/LayoutData";
import designConstructor from "../../../../../services/helper/designConstructor";
import { preLayoutErrorCheck } from "../../../../../services/Cascade/helper/setupCheck";
import preLayoutData from "../../../../../services/Cascade/prelayout/preLayoutData";
import componentSetting from "../../../../../services/Cascade/helper/compSettingHelper";
import auroraDBJson from "../../../../../services/Designs/auroraDbData";

function* _startImpedanceSimulation(action) {
  let { id, data: impedanceData, signOffVerificationId, designType: _designType, options } = action;
  const { CascadeReducer: {
    Impedance: { verificationId, data, signOffId, designType },
    project: { openProjectId },
    library: { DecapList }
  } } = yield select();

  let _data = impedanceData ? impedanceData : data,
    _signOffId = signOffVerificationId ? signOffVerificationId : signOffId;

  id = id ? id : verificationId;
  _designType = _designType ? _designType : designType;

  try {
    if (!_data.length) {
      return;
    }
    // get Impedance status
    const promise = yield call(checkVerificationStatus, id);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        return;
      }
    }
    // start simulation
    yield put(openTabFooter());
    if (!_signOffId) {
      const list = CascadeChannels.getList(IMPEDANCE, openProjectId);
      const currentItem = list.find(i => i.id === id);
      yield put(changeTabMenu({
        tabSelectKeys: ["monitor"],
        currentVerificationId: id,
        verificationName: currentItem ? currentItem.name : IMPEDANCE,
        menuType: "simulation"
      }))
    }
    yield put(updateError([]));

    //stackup error check
    let stackupError = null
    yield* _data.map(function* (layout) {
      const isPreLayout = designConstructor.isPreLayout(layout.designId)
      if (layout.type !== IMPEDANCE_DIE && !isPreLayout) {
        stackupError = yield call(getStackupErrorCheck, {
          designId: layout.designId,
          extractionSolver: layout.extraction.type,
          verificationId: id
        });
      }
    })
    if (stackupError && stackupError.length) {
      return;
    }

    // check pre-layout error
    const preLayoutError = []
    yield* _data.map(function* (layout) {
      const isPreLayout = designConstructor.isPreLayout(layout.designId)
      if (isPreLayout) {
        try {
          const preLayout = yield call([preLayoutData, preLayoutData.getPreLayout], layout.designId);
          const error = preLayoutErrorCheck(preLayout, DecapList);
          preLayoutError.push(...error)
        } catch (error) {
          console.error(error)
        }
      }
    })
    if (preLayoutError.length) {
      yield put(updateError(preLayoutError));
      return;
    }

    // check data error
    yield* _data.map(function* (layout) {
      const isPreLayout = designConstructor.isPreLayout(layout.designId)
      if (layout.designId !== IMPEDANCE_DIE && !isPreLayout) {
        yield call(getAuroraDB, layout.designId);
      }
    })
    const { error, data: newData } = ImpedanceErrorCheck(_data, DecapList);
    _data = newData;
    yield put(updateError(error));
    //save power domain to server
    yield call(saveImpedanceDomain, {
      verificationId: id,
      layouts: _data
    });
    if (!_data.find(layout => layout.powerDomains.some(item => item.readyForSim === 1))) {
      yield put(updateMultiSimulationInfo({
        verificationId: id,
        info: {
          startMsg: null,
          log: null,
          monitor: null,
          endMsg: null
        }
      }))
      return;
    }

    let networkFailed = false;
    yield* _data.map(function* (layout) {
      const { extraction, designId, type } = layout;
      if (type !== IMPEDANCE_DIE) {
        if (extraction && extraction.type === POWERSI && extraction.CLIP === 1) {
          let clipsave = '', trySave = 1;
          yield call(getLayoutDB, designId);
          while (trySave <= 3) {
            clipsave = yield call(saveCutBoundary, { data: layout.powerDomains, designId: designId, verificationId: id, Config: extraction });
            if (clipsave !== 'failed') {
              break;
            }
            trySave = trySave + 1;
            yield delay(1000);
          }

          if (clipsave === 'failed') {
            yield put(updateError(["[Error] Save clipping infomation failed, please check the network or PCB and try again!"]));
            networkFailed = true;
          }
        }
      }
    })

    if (networkFailed) {
      return;
    }

    yield put(updateMultiSimulationInfo({
      verificationId: id,
      info: {
        startMsg: `==> Prepare Extraction data...`,
        log: null,
        monitor: null,
        endMsg: null
      }
    }))

    const warning = _designType === IMPEDANCE_PACKAGE ? [] : ImpedanceWarningCheck(_data)
    yield put(updateWarning(warning))

    const res = yield call(runImpedance, { options, verificationId: id });
    if (!res) {
      yield put(updateMultiSimulationInfo({
        verificationId: id,
        info: {
          startMsg: null,
          log: null,
          monitor: null,
          endMsg: "Simulation failed!"
        }
      }));
      if (_signOffId) {
        //if impedance created by sign-off template, update error message to sign-off template
        yield call(updateErrorToTemplate, {
          openProjectId,
          id,
          _signOffId,
          error: "Simulation failed!",
          type: IMPEDANCE
        })
      }
      return;
    }

    const interfaceStatus = yield call(getInterfaceWorkStatus, id);
    const historys = yield call(getImpedanceHistorys, id);
    yield put(updateImpedanceResultExist(historys.length ? true : false))
    if (interfaceStatus && interfaceStatus.length > 0) {
      //if sign off id exist, update sign off template list status
      if (_signOffId) {
        const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
        yield put(updateSignOffTemplateListStatus(list, openProjectId));
      }

      yield fork(startNewWorkflow, {
        verificationId: id,
        interfaceStatus,
        key: IMPEDANCE
      })
    } else {
      // _initSimulationInfo 
      yield put(initSimulationInfo);

      if (_signOffId) {
        //if impedance created by sign-off template, update error message to sign-off template
        yield call(updateErrorToTemplate, {
          openProjectId,
          id,
          _signOffId,
          error: "Simulation failed!",
          type: IMPEDANCE
        })
        //if sign off id exist, update sign off template list status
        const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
        yield put(updateSignOffTemplateListStatus(list, openProjectId));
      }
      yield put(updateMultiSimulationInfo({
        verificationId: id,
        info: {
          startMsg: null,
          log: null,
          monitor: null,
          endMsg: "Simulation failed!"
        }
      }))
    }
  } catch (error) {
    console.error(error);
    if (error) {
      const { CascadeReducer: { Impedance: { errorMsg } } } = yield select();
      yield put(updateError([...errorMsg, `==> ${error}`]));
    }
    yield delay(1000);
    let log = yield call(getCascadeSimulationLog, id)
    yield put(updateMultiSimulationInfo({
      verificationId: id,
      info: {
        startMsg: null,
        log: log,
        monitor: null,
        endMsg: null
      }
    }));

    if (_signOffId) {
      //if impedance created by sign-off template, update error message to sign-off template
      yield call(updateErrorToTemplate, {
        openProjectId,
        id,
        _signOffId,
        error,
        type: IMPEDANCE
      })
      //if impedance created by sign-off template, update sign off template list status
      const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
      yield put(updateSignOffTemplateListStatus(list, openProjectId));
    }
  } finally {
    yield put(updateSimulationLoading(false))
  }
}

function* saveCutBoundary(param) {
  const { designId, verificationId, data, Config, targetIC } = param;
  try {
    const ratio = Config.ratio || 0.3;
    const { nets = [], comps = [], referenceNets = [] } = getNetsAndCompsFromDetails(data, targetIC, designId);
    if (!nets.length) {
      yield put(updateError(["[Error] Can not find nets for clipping!"]));
      return '';
    }
    const boundaryData = getPowerNetsBoundaryArr({ ratio, nets, designId, components: comps, referenceNets });
    const info = boundaryData.toDesignClipObject();
    // PowerSI does not read duplicate data, so remove the last data
    info.x.pop();
    info.y.pop();
    yield call(saveCascadeCutDesign, { content: info, verificationId, designId });
    return '';
  } catch (error) {
    console.error(error);
    return 'failed';
  }
}

function getLayoutDB(id, onlyCompLayer) {
  return new Promise((resolve, reject) => {
    try {
      _LayoutData.LoadLayoutDB(id, onlyCompLayer).then(res => {
        resolve(true);
      }, error => {
        reject(error);
      })
    } catch (error) {
      reject(error);
    }
  })
}

function* getAuroraDB(designId) {
  try {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (isPreLayout) {
      yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      return;
    }
    const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
    yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId, setting);
  } catch (e) {
    console.error(e);
  }
}

function* ImpSimulationSaga() {
  yield takeEvery(START_IMP_SIMULATION, _startImpedanceSimulation);
}

export default ImpSimulationSaga;