const CASCADE = 'cascade';
export const LOCATION_CHANGE = `${CASCADE}/location`;
export const LAYER_CHANGE = `${CASCADE}/layer_change`;
export const OPEN_PANEL = `${CASCADE}/open_panel`;
export const CLOSE_PANEL = `${CASCADE}/close_panel`;
export const COLORBY_CHANGE = `${CASCADE}/colorby_change`;
export const SELECTION_CLEAR_ONE = `${CASCADE}/selection_clear_one`;
export const SELECTION_CLEAR = `${CASCADE}/selection_clear`;
export const SELECTED_ALL = `${CASCADE}/selected_all`;
export const CANCEL_SELECTED_ALL = `${CASCADE}/cancel_selected_all`;
export const SHOW_SELECTED = `${CASCADE}/show_selected`;
export const HIDE_SELECTED = `${CASCADE}/hide_selected`;
export const SELECTION_CHECKBOX_CHANGE = `${CASCADE}/selection_checkbox_change`;
export const CLEAN_STATUS = `${CASCADE}/clean_status`;
export const CHANGE_SELECTED_DISPLAY = `${CASCADE}/change_selected_display`;
export const SELECT_CHANGE = `${CASCADE}/select_change`;
export const CHANGE_SHOW_NAME_STATUS = `${CASCADE}/change_show_name_status`;