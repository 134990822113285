import ParameterData from "./dataFormat";
import { select } from 'd3-selection';
import Viewer, { CurveItem } from '../../Result/Public/sparameter/PlotView';
import { deleteParameter } from '../../api/sparameterNew';
import { getYLabel } from '../../helper/sparameter/utility';
class Parameters {
  constructor() {
    this.parameters = new Map();
    this.plotViewer = null;
  }

  getParameter(id) {
    return this.parameters.get(id);
  }

  // initialization paramter list
  getParametersInfo({ verificationId, projectId, pcbList, fileType, time }) {
    return new Promise((resolve, reject) => {
      pcbList.forEach(ele => {
        this.initParameter(`${fileType}-${time}-${ele.pcbId}`, new ParameterData({ id: ele.pcbId, verificationId, projectId, pcb: ele.pcb, fileType, time }))
      });
      resolve(this.parameters);
    })
  }

  initParameter(key, parameterData) {
    this.parameters.set(key, parameterData)
  }

  createPlot(element, setting, events) {
    this.plotViewer = new Viewer({ element, events, product: 'Andes' });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit('Hz');
    xAxis.setLabel('Frequency');
    xAxis.setScale('linear');
    let yAxis = this.plotViewer.getAxis('y');
    // TODO: Y axis unit:  zam - ohm
    yAxis.setUnit('');
    yAxis.setLabel(getYLabel(setting));
    // Default y scale
    yAxis.setting.scale = "linear";
    return this.plotViewer;
  }

  showCurve({ parameter, row, col, param, value, fileIndex }) {
    return new Promise((resolve) => {
      let curve = new CurveItem();
      if (!parameter || !parameter.getCurveData) {
        resolve(curve);
        return;
      }
      try {
        parameter.getCurveData({ row, col, param, value, fileIndex }).then((response) => {
          if (response) {
            curve.x = parameter.freq;
            curve.y = response.curve;
            curve.row = row;
            curve.col = col;
            curve.type = response.type;
            curve.id = parameter.id;
            curve.color = response.color;
          }
          resolve(curve)
        });
      } catch (error) {
        resolve(curve)
        console.error(error);
      }
    })
  }

  removeCurve({ row, col, id }) {
    this.plotViewer.removeCurve({ row, col, id });
  }

  // [{ row, col, id }]
  removeCurves(curves) {
    this.plotViewer && this.plotViewer.removeCurves && this.plotViewer.removeCurves(curves);
  }

  changeAxis(axis, { param, value, scale }) {
    if (this.plotViewer) {
      const _axis = axis.toUpperCase();
      if (_axis === 'Y') {
        let yAxis = this.plotViewer.getAxis('y');
        yAxis.setLabel(getYLabel({ value, yScale: scale }));
      }
      this.plotViewer['update' + _axis + 'Axis']({ param, value, scale });
    }
  }

  updateRange(type, axis) {
    if (axis) {
      this.plotViewer.updateAxisRange(type, axis)
    }
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }

  changeParameter({ parameters, setting, yScale, xScale }) {
    if (this.plotViewer === null) return;
    const xAxis = this.plotViewer.getAxis('x'),
      yAxis = this.plotViewer.getAxis('y');
    xAxis.setScale(xScale);
    yAxis.setScale(yScale);

    const curves = this.plotViewer.curves;
    const promises = curves.map(item => {
      const parameter = parameters.find((param) => param.id === item.id);
      return parameter.getCurveData({ row: item.row, col: item.col, param: setting.parameter, value: setting.value }).then(
        (response) => {
          item.y = response.curve;
          item.type = response.type;
        }
      )
    });
    return Promise.all(promises).then(() => {
      yAxis.setLabel(getYLabel({ value: setting.value, yScale }));
      let unit = '';
      xAxis.setUnit("Hz");

      if (setting.value === 'am') {
        if (setting.parameter === 's') {
          unit = '';
        } else {
          unit = setting.parameter === 'y' ? 'Ω⁻¹' : 'Ω';
        }
      } else {
        unit = '';
      }
      yAxis.setUnit(unit);
      this.plotViewer.updateYAxis({ param: setting.parameter, value: setting.value, scale: yScale });
    })
  }

  deleteParam(id) {
    return new Promise((resolve, reject) => {
      deleteParameter({ ids: [id] }).then(res => {
        this.parameters.delete(id);
        resolve(this.parameters);
      })
    })
  }

  cleanParameters = () => {
    this.parameters = new Map();
    this.plotViewer = null;
  }
}

const parameters = new Parameters();
export default parameters;