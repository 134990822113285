import { WAITING, RUNNING } from '@/constants/workflowStatus';
/**
 * if last workflow status is not running or waiting, return null;
 * 
 * @param {promise} promise
 * @returns
 */

function workflowFliter(promise) {
  return new Promise((resolve, reject) => {
    promise.then(data => {
      // status < 2, is waiting or running;
      if (data && (data[data.length - 1].status === WAITING || data[data.length - 1].status === RUNNING)) {
        resolve(data);
      } else {
        resolve(null);
      }
    }, error => {
      resolve(null);
    })
  })
}


export default workflowFliter;