import {
  OPEN_FOLDER,
  REFRESH_FOLDER,
  OPEN_PROJECT,
  UPDATE_DATA,
  SELECT_ROW,
  SHOW_VERIFICATION
} from './actionType';

export const openFolder = (data, page) => ({
  type: OPEN_FOLDER,
  data,
  page
})

export const refreshFolder = (page) => ({ type: REFRESH_FOLDER, page })

export const openProject = (data) => ({
  type: OPEN_PROJECT,
  data
})

export const updateData = (data) => ({
  type: UPDATE_DATA,
  data
})

export const selectRow = (selectedRowKeys) => ({
  type: SELECT_ROW,
  selectedRowKeys
})

export const showVerification = (show) => ({
  type: SHOW_VERIFICATION,
  show
})