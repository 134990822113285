import { checkRLCValue } from "../../helper/dataProcess";
import { CAP, CMC, CONNECTOR, DIODE, getComponentsWithNetList, getLayoutComponents, IC, IGNORE, IND, RES, RLC_TYPES, SERDES_TYPES } from "../../PCBHelper";
import { getPowerNets, getCMCPinConnections, filterPowerNets } from "../../PCBHelper/net";
import { RLCModelInfo } from "../../RLCModel";
import LayoutData from "../../data/LayoutData";
import { ChannelComponents, ChannelRLCComponents, RLCValue } from "./IntegratedChannel";
import {
  PortsGenerationSetup,
  getDefaultPortSetupList,
  AutoGeneratePorts,
  ALL_PINS,
  GAP,
  NEARBY_PINS,
  PIN_PER_REF_NET,
  getDefaultReferenceNets,
  getPortGenerateSetupList,
  PIN_GROUP,
  getUpdateCompBallInfoByPinSize,
  sortPortSetups
} from '../../ExtractionPortsHelper';
import DesignInfo from '../pcbInfo';
import { HDMI, PCIE, GENERIC, CPHY } from "../../PCBHelper/constants";
import { findPinsBySignal, getSeaSimChannels, SeaSimConfig } from "../seaSim";
import { getDefaultPostLayoutChannelInfo, getPreLayoutChannelComponents, getPreLayoutChannelSignals } from '.';
import { TOUCHSTONE_SPICE } from "../../../constants/libraryConstants";
import {
  filterUnusedModelFiles
} from '../../helper/packageModelHelper';
import { ADSConfig } from "../AMIModelHelper";
import { getAMIComponents, getDefaultAdsSignals } from "./AMIModelHelper";
import { PACKAGE as PCB_PACKAGE } from '../../../constants/designType';
import { strFormatChange } from '@/services/helper/stringHelper'
import { ANDES_V2 } from "../../../constants/pageType";
import { BALL_TYPE_NONE, BALL_TYPE_NONE_LIST } from "../../ExtractionPortsHelper/portTableHelper";
import { findReferenceNetsBySignal, getReferenceNetsBySignal } from "@/services/api/refNets";
import {
  FIND_REF_NET_RUNNING,
  FIND_REF_NET_SUCCESS,
  FIND_REF_NET_FAILED,
} from "@/constants/findRefNetsConstants";
import { delay } from "../../Sierra/helper/assignIBISModelsHelper";
import { getSignalElementType } from "./signalsIdentification";
import { ADS, initChannelHspiceConfig } from "../simulation";

const BGA = "BGA", DIE = "DIE";
function getComponents({
  components,
  signalName,
  netList,
  pcbNetsList,
  layers,
  returnNewComps = false,
  cmcComponents = [],
  selectComponent = null,
  serdesType,
  designType
}) {
  const _netList = signalName ? netList : netList.map(item => item.net);
  const existNames = components.map(item => item.name);
  const CompsInfo = getLayoutComponents({ layers }); // { Comp: { name, part, value, type, pinList, location} }
  const compList = getComponentsWithNetList({ netList: _netList, pcbNetsList, CompsInfo, layers });

  let newComps = [];
  for (let comp of compList) {
    const { pin, name, net, value, type, part } = comp;
    const _index = existNames.indexOf(name);
    const _signalName = signalName ? signalName : netList.find(it => it.net === net).signal;
    if (_index < 0) {
      // Not exist component
      let newComp;
      if (RLC_TYPES.includes(type)) {
        let _value = checkRLCValue(value);
        const findComp = components.find(it => it.type === type && it.part === part);
        const findModel = findComp ? findComp.model : null;
        const model = findModel || new RLCModelInfo({ type: "value", value: getRLCCompValue(type, _value) });
        newComp = new ChannelRLCComponents({ name, type, model, pins: [{ pin, net, signal: _signalName }] });
      } else {
        const findCMC = cmcComponents.find(it => it.mCompName === name) || components.find(it => it.mCompName === name && it.type === CMC);
        let pinsConnection = [];
        let _type = getDefaultCompType(name, type, cmcComponents.map(it => it.mCompName), designType);
        if (findCMC) {
          _type = findCMC.type || CMC;
          pinsConnection = findCMC.pinsConnection;
        } else if (designType !== PCB_PACKAGE && serdesType !== PCIE && CompsInfo[name] && CompsInfo[name].pinList && CompsInfo[name].pinList.length === 4) {
          _type = CMC;
          pinsConnection = getCMCPinConnections({ pinList: CompsInfo[name].pinList });
        }
        newComp = new ChannelComponents({ name, type: _type || IGNORE, pins: [{ pin, net, signal: _signalName }], selectComponent, pinsConnection });
      }
      newComp.part = part;
      components.push(newComp);
      // Update components name list
      existNames.push(name);
      newComps.push(JSON.parse(JSON.stringify(newComp)));
    } else {
      const index = components[_index].pins.findIndex(it => it.pin === pin);
      if (index > -1) {
        components[_index].pins[index].signal = signalName;
        continue;
      }
      const existPins = components[_index].pins;
      const findSameSignalNetPin = existPins.find(it => it.net === net && it.signal === _signalName);
      if (findSameSignalNetPin) {
        continue;
      }

      // Exsit component
      components[_index].pins.push({ pin, net, signal: _signalName });
      //add pkg pairs,connecor,pcbModel pairs and passive model _pairs
      components[_index] = addPkgConnPassivePairs(components[_index], pin);
      //Update new comps
      const _compName = components[_index].name;
      const newIndex = newComps.findIndex(it => it.name === _compName);
      if (newIndex > -1) {
        newComps[newIndex].pins.push({ pin, net, signal: _signalName });
      } else {
        newComps.push({
          ...JSON.parse(JSON.stringify(components[_index])),
          pins: [{ pin, net, signal: _signalName }]
        })
      }
    }
  }

  if (returnNewComps) {
    return { components, newComps };
  } else {
    return components;
  }
}

/**
 * get default component type by name
 * @param {string} name component name
 * @param {type} type component default type
 * if name prefix is [J,K], type is Connector,if name prefix is U or other, type is IC
 *  */
function getDefaultCompType(name, type, cmcComponents, designType) {

  if (designType === PCB_PACKAGE) {
    if (name.match(/(BG)|(BGA)/ig)) {
      return BGA;
    } else {
      return DIE;
    }
  }

  if (SERDES_TYPES.includes(type) || RLC_TYPES.includes(type)) {
    return type;
  }

  if (cmcComponents.includes(name)) {
    return CMC;
  }

  const words = name.split(/^([A-Za-z]+)(([0-9]+)|-|_)/g);

  if (words.length < 2) {
    return IC;
  }
  if (!words[1]) {
    return IC;
  }
  let __type = IC;
  switch (words[1].toLowerCase()) {
    case "j":
    case "k":
      __type = CONNECTOR;
      break;
    case "d":
      __type = DIODE;
      break;
    default:
      __type = IC;
      break;
  }

  //find Diode
  const diodeReg = /^(d)/ig;
  if (words[1].match(diodeReg)) {
    __type = DIODE;
  }

  return __type;
}

function getRLCCompValue(type, value) {
  switch (type) {
    case RES:
      return new RLCValue({ r: value });
    case IND:
      return new RLCValue({ l: value });
    case CAP:
      return new RLCValue({ r: "0", l: "0", c: value });
    default:
      return new RLCValue({});
  }
}

function getChannelPowerNets({ components, powerNets, netsList, signals, findGND = true, designId, type }) {
  let signalNets = [];
  // signals.forEach(item => { signalNets = [...signalNets, ...item.nets_P, ...item.nets_N] });
  for (const signal of signals) {
    const netList = type === CPHY ? [...(signal.nets_A || []), ...(signal.nets_B || []), ...(signal.nets_C || [])]
      : [...(signal.nets_P || []), ...(signal.nets_N || [])];
    signalNets = [...signalNets, ...netList];
  }
  const info = getPowerNets({ components, powerNets, netsList, signalNets, findGND, designId })
  return { components: info.components, powerNets: info.powerNets };
}

const NEW_GROUP = "New Group";
/** get add signal group list */
function getGroupList(signals) {
  let groups = signals.map(item => item.group);
  groups = [...new Set(groups)];
  groups.push(NEW_GROUP);
  return groups;
}

function getNetsOptions({ record, channelDesignId, serdesType, signals, components, onSearchValue, pcbNetsList, PCBNets, relatedGroups }) {
  let allSelectedNets = [];
  //find all other signal selected nets 
  for (let item of signals) {
    if (item.name !== record.signal) {
      allSelectedNets = serdesType === CPHY ? [...allSelectedNets, ...item.nets_A, ...item.nets_B, ...item.nets_C] : [...allSelectedNets, ...item.nets_P, ...item.nets_N];
    } else {
      let netType = ""
      if (serdesType === CPHY) {
        netType = record.netType === "Line A" ? "nets_A" : record.netType === "Line B" ? "nets_B" : "nets_C";
      } else {
        netType = record.netType === "positive" ? "nets_P" : "nets_N";
      }
      allSelectedNets.push(...item[netType]);
    }
  }

  const netList = PCBNets || [];
  //find related nets
  let _relatedNets = [];
  const _LayoutData = LayoutData.getLayout(channelDesignId);

  relatedGroups.setGroups({
    netList: record.nets.map(item => _LayoutData.mNetManager.GetNetFromName(item)),
    designId: channelDesignId,
    setupComps: components,
    type: serdesType
  });

  const allRelateGroups = relatedGroups.getGroups(record.nets) || [];

  for (let item of allRelateGroups) {
    _relatedNets.push(...item.relatedNets)
  }

  const powerNets = filterPowerNets(_relatedNets.map(item => { return pcbNetsList.find(it => it.mName === item) }), _LayoutData.mSymbolMgr, channelDesignId);
  const powerNetNames = powerNets.map(item => item.mName);

  _relatedNets = [...new Set(_relatedNets.filter(i => !record.nets.includes(i) && !powerNetNames.includes(i)))];


  if (!onSearchValue) {
    let currentSerdesNets = [], otherNets = [];
    const reg = new RegExp(serdesType, 'i');
    netList.forEach(item => {
      if (item.match(reg)) {
        currentSerdesNets.push(item);
      } else {
        otherNets.push(item)
      }
    });
    return { relatedNets: _relatedNets, nets: [...new Set([...currentSerdesNets, ...otherNets])].filter(item => !allSelectedNets.includes(item)) }
  } else {
    let newOptions = [];
    const value = strFormatChange(onSearchValue)
    const reg = new RegExp(value, 'i');
    netList.forEach(item => {
      if (item.match(reg)) {
        newOptions.push(item);
      }
    });
    return { relatedNets: _relatedNets, nets: [...new Set(newOptions)].filter(item => !allSelectedNets.includes(item)) }
  }
}

function deleteCompsConnectWithNets({ netList, pcbNetsList, layers, signalName, components }) {
  const CompsInfo = getLayoutComponents({ layers }); // { Comp: { name, part, value, type, pinList, location} }
  const deletedComps = getComponentsWithNetList({ netList, pcbNetsList, CompsInfo, layers });
  for (let delComp of deletedComps) {
    const compIndex = components.findIndex(item => item.name === delComp.name);
    if (compIndex < 0) {
      continue;
    }
    components[compIndex].pins = components[compIndex].pins.filter(item => !!item.signal);
    const pinModelIndex = components[compIndex].pins.findIndex(item =>
      item.signal === signalName && item.pin === delComp.pin && item.net === delComp.net
    );
    // Delete pin model
    if (pinModelIndex > -1) {
      components[compIndex].pins.splice(pinModelIndex, 1);
    }
    if (components[compIndex].pins.length === 0) {
      components.splice(compIndex, 1);
    }

    //delete pkg, connector,pcb model and passive pairs
    components = deletePkgConnPassiveByDelNets(deletedComps, components);
  };
  return { components, deletedComps };
}

function judgeType(prevType, type) {
  const _prevType = getUsageType(prevType);
  const _type = getUsageType(type);
  if (_prevType !== _type) {
    return true;
  }
}

function getUsageType(usage) {
  if (RLC_TYPES.includes(usage)) {
    return "RLC"
  }

  return usage;
}

function modifyCompType(comp, type) {

  if (judgeType(comp.type, type)) {
    if (RLC_TYPES.includes(type)) {
      const value = [RES, IND].includes(type) ? "0.1" : "0";
      const model = new RLCModelInfo({ type: "value", value: getRLCCompValue(type, value) });
      comp.model = model;
      delete comp.pkg;
    } else if (type === IC) {
      comp.model = {};
      comp.pkg = { type: "None" };
      delete comp.connectorModel;
    } else if (type === CONNECTOR) {
      comp.model = {};
      comp.connectorModel = { type: "None" };
      delete comp.pkg;
    }
  }
  comp.type = type;
  return comp;
}

function determineNets(nets, pcbNetNameList, deleteNets, existNets) {
  for (let item of nets) {
    if (pcbNetNameList.includes(item.net)) {
      existNets.push(item)
    } else {
      deleteNets.push(item);
    }
  }
  return { deleteNets, existNets }
}

function channelSetupReplace({ channelInfo, designVersion }) {
  let info = { ...channelInfo }, replaceMonitor = [];
  if (!channelInfo.designId) {
    return { channelInfo: info, replaceMonitor: { id: info.id, monitor: [] } };
  }

  const { netsList = [], layers = [] } = DesignInfo.getPCBInfo(channelInfo.designId) || {};

  //const CompsInfo = getLayoutComponents({ layers }); // { Comp: { name, part, value, type, pinList, location} }
  let components = [...info.content.components];
  let signals = [...info.content.signals];
  let powerNets = [...info.content.powerNets];
  let referenceNets = info.content.referenceNets ? [...info.content.referenceNets] : [];

  const netNameList = netsList.map(item => item.mName);
  let deleteNets = [], existNets = [];
  //Determine whether nets exists
  for (let signal of signals) {
    const allSignalNets = [
      ...(signal.nets_P || []).map(item => { return { net: item, signal: signal.name, netType: "positive" } }),
      ...(signal.nets_N || []).map(item => { return { net: item, signal: signal.name, netType: "negative" } }),
      ...(signal.nets_A || []).map(item => { return { net: item, signal: signal.name, netType: "Line A" } }),
      ...(signal.nets_B || []).map(item => { return { net: item, signal: signal.name, netType: "Line B" } }),
      ...(signal.nets_C || []).map(item => { return { net: item, signal: signal.name, netType: "Line C" } })
    ];
    const _netInfo = determineNets(allSignalNets, netNameList, deleteNets, existNets);
    deleteNets = _netInfo.deleteNets;
    existNets = _netInfo.existNets;
    for (const netsName of getSignalElementType(channelInfo.type)) {
      signal[netsName] = signal[netsName].filter(item => netNameList.includes(item));
    }
  }

  //get new components by signal nets
  const new_Components = getComponents({
    components: [],
    netList: existNets,
    pcbNetsList: netsList,
    layers,
    serdesType: channelInfo.type
  });

  //update components
  const newCompInfo = replaceComponents(new_Components, components, replaceMonitor);
  components = newCompInfo.components;
  replaceMonitor = newCompInfo.replaceMonitor;

  //update power ground nets
  /* const prevPowerNetNames = powerNets.map(item => item.name); */
  const pwrInfo = getChannelPowerNets({
    components,
    powerNets: powerNets.filter(item => netNameList.includes(item.name)),
    netsList,
    signals,
    designId: channelInfo.designId,
    type: channelInfo.type
  });

  //find new comps
  const new_pwrComps = pwrInfo.components.filter(comp => components.findIndex(item => item.name === comp.name) < 0);
  if (new_pwrComps.length > 0) {
    new_pwrComps.forEach(comp => {
      replaceMonitor.push(`Update component ${comp.name}.\n`);
    })
  }

  components = pwrInfo.components;
  powerNets = pwrInfo.powerNets;

  //update referenceNets
  referenceNets = powerNets.filter(item => !!powerNets.find(it => it.name === item));
  if (!referenceNets.length) {
    //if referenceNets not exist, re find reference nets
    referenceNets = getDefaultReferenceNets(powerNets);
  }

  //add replace monitor
  //find deleted nets and new nets
  /*   const new_pwr = powerNets.filter(item => !prevPowerNetNames.includes(item.name));
    const del_pwr = prevPowerNetNames.filter(item => !powerNets.find(it => it.name === item));
    const monitor = replacePwrNetsMonitor(new_pwr, del_pwr);
    replaceMonitor.push(...monitor); */

  //update sea sim config
  if (info.type === PCIE) {
    let config = info.config && info.config.channels ? info.config : new SeaSimConfig();
    config.channels = replaceConfigByComponents(config.channels, components, signals);
    info.config = config;
  }

  //update ads config
  if ([PCIE, HDMI, GENERIC, CPHY].includes(info.type)) {
    let adsConfig = null;
    if (info.adsConfig && Object.keys(info.adsConfig).length) {
      adsConfig = info.adsConfig;
      adsConfig = updateADSComponent(adsConfig, components.filter(item => SERDES_TYPES.includes(item.type)).map(item => item.name), info.type);
      info.adsConfig = adsConfig;
    } else {
      const { controller, device } = getAMIComponents(components);
      const adsSignals = getDefaultAdsSignals({
        signals: signals,
        ICComps: components.filter(item => SERDES_TYPES.includes(item.type)),
        controller,
        device,
        selectedSignals: info.content.selectedSignals,
        netsList,
        layers,
        serdesType: info.type
      });
      info.adsConfig = new ADSConfig({ serdesType: info.type, signals: adsSignals, controller, device });
    }
  }

  info.content.components = [...components];
  info.content.powerNets = [...powerNets];
  info.content.signals = [...signals];
  info.content.referenceNets = [...referenceNets];
  info.designVersion = designVersion;

  /*  replaceMonitor.push(`Channel setup updated successfully.`); */

  return { channelInfo: info, replaceMonitor: { id: info.id, designId: info.designId, title: info.pcbName, monitor: replaceMonitor } };
}

function replaceComponents(new_Components, components, replaceMonitor) {
  //update components
  //deleted components
  const del_comps = components.filter(comp => !new_Components.find(item => item.name === comp.name));
  del_comps.forEach(comp => {
    replaceMonitor.push(`Deleted component ${comp.name}.\n`);
  })
  let _components = [];
  for (let comp of new_Components) {
    let monitorExist = false;
    let prevComp = components.find(item => item.name === comp.name);
    if (!prevComp) {
      replaceMonitor.push(`Newly added component ${comp.name}.\n`);
      _components.push({ ...comp });
      continue;
    }

    if (prevComp.part !== comp.part || prevComp.pins.length !== comp.pins.length) {
      const updateMsg = addComponentsUpdateMsg(replaceMonitor, monitorExist, comp.name);
      replaceMonitor = updateMsg.replaceMonitor;
      monitorExist = updateMsg.monitorExist;
    }
    prevComp.part = comp.part;

    const newPinNames = comp.pins.map(item => item.pin);
    const newPinDieNames = newPinNames.map(item => `${item}_u`);
    //remove pins
    let pairs = prevComp.pkg && prevComp.pkg.pairs ? prevComp.pkg.pairs.filter(item => newPinNames.includes(item.pin) || newPinDieNames.includes(item.pin)) : null;
    prevComp.pins = prevComp.pins.filter(item => newPinNames.includes(item.pin));
    //update pins
    for (let pin of comp.pins) {
      const prevPinIndex = prevComp.pins.findIndex(item => item.pin === pin.pin);
      if (prevPinIndex < 0) {
        prevComp.pins.push({ ...pin });
        pairs && pairs.push({
          pin: pin.pin,
          libraryId: "",
          node: "",
          subckt: ""
        },
          {
            pin: `${pin.pin}_u`,
            libraryId: "",
            node: "",
            subckt: ""
          })

        if (!monitorExist) {
          replaceMonitor.push(`Update component ${comp.name}.\n`);
          monitorExist = true;
        }
        const updateMsg = addComponentsUpdateMsg(replaceMonitor, monitorExist, comp.name);
        replaceMonitor = updateMsg.replaceMonitor;
        monitorExist = updateMsg.monitorExist;
        continue;
      }
      if (prevComp.pins[prevPinIndex].net !== pin.net || prevComp.pins[prevPinIndex].signal !== pin.signal) {
        const updateMsg = addComponentsUpdateMsg(replaceMonitor, monitorExist, comp.name);
        replaceMonitor = updateMsg.replaceMonitor;
        monitorExist = updateMsg.monitorExist;
      }
      prevComp.pins[prevPinIndex].net = pin.net;
      prevComp.pins[prevPinIndex].signal = pin.signal;
    }
    if (pairs) {
      prevComp.pkg.pairs = pairs;
    }
    _components.push({ ...prevComp });
  }

  return { components: _components, replaceMonitor }
}

function addComponentsUpdateMsg(replaceMonitor, monitorExist, name) {
  if (!monitorExist) {
    replaceMonitor.push(`Update component ${name}.\n`);
    monitorExist = true;
  }
  return { monitorExist, replaceMonitor }
}

function replaceConfigByComponents(channels, components, signals) {
  for (let item of channels) {
    if (item.controller && item.controller.component) {
      const findComp = components.find(it => it.name === item.controller.component);
      if (!findComp) {
        item.controller = {
          component: "",
          pins: []
        }
      } else {
        item.controller.pins = findPinsBySignal({ pins: findComp.pins, signals, signal: item.signal });
      }
    }

    if (item.device && item.device.component) {
      const findComp = components.find(it => it.name === item.device.component);
      if (!findComp) {
        item.device = {
          component: "",
          pins: []
        }
      } else {
        item.device.pins = findPinsBySignal({ pins: findComp.pins, signals, signal: item.signal });
      }
    }
  }
  return channels;
}

function replacePwrNetsMonitor(new_pwr, del_pwr) {
  let monitor = [];
  if (new_pwr.length || del_pwr.length) {
    monitor.push(`Update power / ground nets:\n`);
  }

  if (del_pwr.length) {
    monitor.push(`\tDeleted nets: ${del_pwr.join(", ")}\n`);
  }

  if (new_pwr.length) {
    monitor.push(`\tNewly added nets: ${new_pwr.join(", ")}\n`);
  }
  return monitor;
}

function updateReplaceMonitor(replaceMonitor, newMonitor, type) {
  const itemType = type === "design" ? "designId" : "id";
  //replaceMonitor-> [ { id, monitor:[] }, ... ]
  //newMonitor -> { id, monitor:[] }
  const monitorIndex = replaceMonitor.findIndex(item => item[itemType] === newMonitor[itemType]);
  if (monitorIndex > -1) {
    replaceMonitor[monitorIndex].monitor = [...newMonitor.monitor];
  } else {
    replaceMonitor.push({
      ...newMonitor
    });
  }
  return replaceMonitor;
}

function updateComponentsPortSetup(channelInfo, pcbInfo) {
  if (!channelInfo || !channelInfo.content || !channelInfo.content.components) {
    return channelInfo;
  }
  let _channelInfo = { ...channelInfo };

  const content = _channelInfo.content;
  //default generation ports
  const ports_generate_setup_list = getPortGenerateSetupList({ components: content.components });
  const referenceNets = getDefaultReferenceNets(content.powerNets);
  const defaultZ0 = _channelInfo.type === PCIE ? 42.5 : 50;
  const avoid_single_pin_group = content.port_setups && content.port_setups.length ? (content.port_setups[0].positive || {}).avoid_single_pin_group : null;
  let { port_setups, ports_generate_setup_list: setupList, errors, warnings } = getDefaultPortSetupList({
    components: content.components,
    signals: content.signals,
    pcbInfo,
    designId: _channelInfo.designId,
    referenceNets,
    ports_generate_setup_list,
    referenceZ0: defaultZ0,
    extractionType: getExtractionType(_channelInfo.content.extraction),
    avoid_single_pin_group
  });

  //sort port setups by component and pin
  port_setups = sortPortSetups(port_setups, content.components, content.signals);
  _channelInfo.content.port_setups = [...port_setups];
  _channelInfo.content.referenceNets = [...referenceNets];
  _channelInfo.content.ports_generate_setup_list = setupList;

  return {
    channelInfo: _channelInfo,
    errors,
    warnings
  };
}

function judgeUpdatePortSetup(channelInfo = {}) {
  const content = channelInfo.content || {};
  const components = content.components ? content.components : [];
  const port_setups = content.port_setups ? content.port_setups : [];
  //filter ic connector components
  const serdesComps = components.filter(item => SERDES_TYPES.includes(item.type));
  //get default port_setups if port setups not exist and ic components exist
  if (!port_setups.length && serdesComps.length) {
    return true;
  }
  return false;
}

/* set default component package model  */
function updateComponentsPackage(channelInfo = {}) {
  if (!channelInfo.content || !channelInfo.content.components) {
    return channelInfo;
  }
  let components = channelInfo.content.components ? [...channelInfo.content.components] : [];
  components.forEach(item => {
    if (SERDES_TYPES.includes(item.type) && (!item.pkg || !Object.keys(item.pkg).length)) {
      item.pkg = { type: "None" };
    }
  });

  channelInfo.content.components = components;
  return channelInfo;
}

function getPkgCompPinList(compName, components, signals) {
  let pinList = [];
  const Comp = components.find(item => item.name === compName);
  if (Comp && Comp.pins) {
    Comp.pins.forEach(item => {
      pinList.push({
        pin: item.pin,
        net: item.net,
        signal: item.signal
      })
    })
  }

  const nets = signals.map(item => {
    return [
      ...(item.nets_P || []),
      ...(item.nets_N || []),
      ...(item.nets_A || []),
      ...(item.nets_B || []),
      ...(item.nets_C || [])
    ]
  }).flat();

  pinList.sort((a, b) => {
    const aIndex = nets.findIndex(item => item === a.net),
      bIndex = nets.findIndex(item => item === b.net);
    return aIndex - bIndex;
  })
  return pinList;
}

function judgeUpdateGapPortSetup(channelInfo, extraction) {
  const ports_generate_setup_list = channelInfo.content.ports_generate_setup_list;
  for (let item of ports_generate_setup_list) {
    //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
    //re generate port setups by port type is GAP, reference type is All pins
    if (item.setup.portType === GAP && [NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)) {
      return true;
    }
  }

  return false;
}

function updateExtractionGapPortsSetup(channelInfo) {
  if (!channelInfo || !channelInfo.content) {
    return channelInfo
  }
  let _channelInfo = { ...channelInfo };
  let ports_generate_setup_list = JSON.parse(JSON.stringify(_channelInfo.content.ports_generate_setup_list));
  const referenceNets = _channelInfo.content.referenceNets;
  let port_setups = JSON.parse(JSON.stringify(_channelInfo.content.port_setups));

  let applyComponents = [];
  //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  //re generate port setups by port type is GAP, reference type is All pins
  ports_generate_setup_list.forEach(item => {
    if (item.setup.portType === GAP && [NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)) {
      item.setup = new PortsGenerationSetup({
        portType: GAP,
        referenceType: ALL_PINS,
      });
      applyComponents.push(item.component)
    }
  });

  if (!applyComponents.length) {
    return { channelInfo, errors: [] };
  }

  const pcbInfo = DesignInfo.getPCBInfo(channelInfo.designId);
  const autoPorts = new AutoGeneratePorts();
  const data = autoPorts.autoGeneratePorts(
    port_setups,
    pcbInfo,
    {
      ports_generate_setup_list,
      applyComponents,
      referenceNets,
      referenceZ0: port_setups[0].z0,
      designId: channelInfo.designId
    }
  );
  port_setups = data.port_setups;
  ports_generate_setup_list = data.setupList;
  const warnings = autoPorts.getWarnings();
  const errors = autoPorts.getErrors();

  if (!errors || !errors.length) {
    channelInfo.content.ports_generate_setup_list = ports_generate_setup_list;
    channelInfo.content.port_setups = port_setups;
  }

  return { channelInfo, errors, warnings };
}


/**
 * delete nets or signals, delete package and passive model ,pairs pins
 * @param {array} deletedComps [{ name, pin , ... }]
 * @param {array} components [ { name, pins:[], }]
 *  */
function deletePkgConnPassiveByDelNets(
  deletedComps,
  components
) {
  //format 
  const _deleteCompNames = [...new Set(deletedComps.map(item => item.name))];
  const _deleteComps = _deleteCompNames.map(item => {
    return {
      name: item,
      pins: deletedComps.filter(it => it.name === item)
    }
  })
  let _components = [...components];
  for (let compItem of _deleteComps) { // { pin ,name, ...}
    //find pin
    const pinNames = compItem.pins.map(item => item.pin);
    const diePinNames = pinNames.map(item => `${item}_u`);
    const compIndex = _components.findIndex(item => item.name === compItem.name);
    if (compIndex < 0) {
      continue;
    }
    const comp = _components[compIndex];
    //delete passive model by pin: { type, passiveModel:{pairs:[]}}
    /*   let _passiveModel = comp.model ? _components[compIndex].model.passiveModel : null;
      if (_passiveModel && _passiveModel.pairs.length) {
        _passiveModel.pairs = _passiveModel.pairs.filter(item => !pinNames.includes(item.pin));
        _components[compIndex].model.passiveModel = _passiveModel;
      } */
    //delete pkg pairs by pin
    if (comp.pkg && comp.pkg.pairs && comp.pkg.pairs.length) {
      _components[compIndex].pkg.pairs = _components[compIndex].pkg.pairs.filter(item => !pinNames.includes(item.pin) && !diePinNames.includes(item.pin));
      _components[compIndex].pkg.files = filterUnusedModelFiles(_components[compIndex].pkg.files, _components[compIndex].pkg.pairs);

    }
    //delete connectorModel and BGA connectorModel pairs by pin
    if (comp.connectorModel && comp.connectorModel.pairs && comp.connectorModel.pairs.length) {
      _components[compIndex].connectorModel.pairs = _components[compIndex].connectorModel.pairs.filter(item => !pinNames.includes(item.pin) && !diePinNames.includes(item.pin));
      _components[compIndex].connectorModel.files = filterUnusedModelFiles(_components[compIndex].connectorModel.files, _components[compIndex].connectorModel.pairs);
    }

    //delete pcbModel pairs by pin
    if (comp.pcbModel && comp.pcbModel.pairs && comp.pcbModel.pairs.length) {
      const pcbPinNames = pinNames.map(item => `${item}_pcb`);
      _components[compIndex].pcbModel.pairs = _components[compIndex].pcbModel.pairs.filter(item => !pinNames.includes(item.pin) && !pcbPinNames.includes(item.pin));
      _components[compIndex].pcbModel.files = filterUnusedModelFiles(_components[compIndex].pcbModel.files, _components[compIndex].pcbModel.pairs);
    }

    //delete BGA cableModel pairs by pin
    if (comp.cableModel && comp.cableModel.pairs && comp.cableModel.pairs.length) {
      _components[compIndex].cableModel.pairs = _components[compIndex].cableModel.pairs.filter(item => !pinNames.includes(item.pin) && !diePinNames.includes(item.pin));
      _components[compIndex].cableModel.files = filterUnusedModelFiles(_components[compIndex].cableModel.files, _components[compIndex].cableModel.pairs);
    }

    //delete BGA packageModel pairs by pin
    if (comp.packageModel && comp.packageModel.pairs && comp.packageModel.pairs.length) {
      _components[compIndex].packageModel.pairs = _components[compIndex].packageModel.pairs.filter(item => !pinNames.includes(item.pin) && !diePinNames.includes(item.pin));
      _components[compIndex].packageModel.files = filterUnusedModelFiles(_components[compIndex].packageModel.files, _components[compIndex].packageModel.pairs);
    }

    // delete BGA connectorModel/cableModel/packageModel/pcbModel (modelList)
    if (comp.modelList && comp.modelList.length) {
      const _modelList = []
      for (let modelInfo of comp.modelList) {
        const { pairs } = modelInfo;
        const _pairs = pairs.filter(item => !pinNames.includes(item.pin) && !diePinNames.includes(item.pin));
        // const _files = filterUnusedModelFiles(files, _pairs); //Judging files such as modifying the files used by all files are stored differently
        _modelList.push({ ...modelInfo, pairs: _pairs })
      }
      _components[compIndex].modelList = [..._modelList]
    }
  }
  return _components;
}

function addPkgConnPassivePairs(compObj, pinName) {
  //add pkg
  if (compObj.pkg && compObj.pkg.pairs && compObj.pkg.pairs.length) {
    const findPin = compObj.pkg.pairs.find(item => item.pin === pinName);
    if (!findPin) {
      compObj.pkg.pairs = [...compObj.pkg.pairs, new Pair({ pin: pinName }), new Pair({ pin: `${pinName}_u` })]
    }
  }

  //Add pins to connectorModel or BGA connectorModel pairs
  if (compObj.connectorModel && compObj.connectorModel.pairs && compObj.connectorModel.pairs.length) {
    const findPin = compObj.connectorModel.pairs.find(item => item.pin === pinName);
    if (!findPin) {
      compObj.connectorModel.pairs = [...compObj.connectorModel.pairs, new Pair({ pin: pinName }), new Pair({ pin: `${pinName}_u` })]
    }
  }
  //Add pins to BGA pcbModel pairs
  if (compObj.pcbModel && compObj.pcbModel.pairs && compObj.pcbModel.pairs.length) {
    const findPin = compObj.pcbModel.pairs.find(item => item.pin === pinName);
    if (!findPin) {
      compObj.pcbModel.pairs = [...compObj.pcbModel.pairs, new Pair({ pin: pinName }), new Pair({ pin: `${pinName}_pcb` })]
    }
  }

  //Add pins to BGA cableModel pairs
  if (compObj.cableModel && compObj.cableModel.pairs && compObj.cableModel.pairs.length) {
    const findPin = compObj.cableModel.pairs.find(item => item.pin === pinName);
    if (!findPin) {
      compObj.pcbModel.pairs = [...compObj.cableModel.pairs, new Pair({ pin: pinName }), new Pair({ pin: `${pinName}_u` })]
    }
  }

  //Add pins to BGA packageModel pairs
  if (compObj.packageModel && compObj.packageModel.pairs && compObj.packageModel.pairs.length) {
    const findPin = compObj.packageModel.pairs.find(item => item.pin === pinName);
    if (!findPin) {
      compObj.pcbModel.pairs = [...compObj.packageModel.pairs, new Pair({ pin: pinName }), new Pair({ pin: `${pinName}_u` })]
    }
  }
  //add passive 
  /* if (compObj.model && ["touchstone", "spice"].includes(compObj.model.type) && compObj.model.passiveModel && compObj.model.passiveModel.pairs) {
    const findPin = compObj.model.passiveModel.pairs.find(item => item.pin === pinName);
    if (!findPin) {
      compObj.model.passiveModel.pairs.push({
        pin: pinName,
        node: ""
      })
    }
  } */

  // delete BGA connectorModel/cableModel/packageModel/pcbModel (modelList)
  if (compObj.modelList && compObj.modelList.length) {
    const _modelList = []
    for (let modelInfo of compObj.modelList) {
      const { pairs } = modelInfo;
      const findPin = pairs.find(item => item.pin === pinName);
      let _pairs = [...pairs]
      if (!findPin) {
        _pairs = [...pairs, new Pair({ pin: pinName }), new Pair({ pin: `${pinName}_u` })]
      }
      _modelList.push({ ...modelInfo, pairs: _pairs })
    }
    compObj.modelList = [..._modelList]
  }
  return compObj;
}

function Pair({ pin, node = "", subckt = "", libraryId = "" }) {
  this.pin = pin;
  this.node = node;
  this.subckt = subckt;
  this.libraryId = libraryId;
}

function preLayoutChannelSetupReplace({ channelInfo, designVersion, preLayoutInfo }) {
  let info = { ...channelInfo }, replaceMonitor = [];
  if (!channelInfo.designId || !preLayoutInfo || !preLayoutInfo.content) {
    return { channelInfo: info, replaceMonitor: { id: info.id, monitor: [] } };
  }
  const signal_groups = preLayoutInfo.content.signal_groups || [];
  const selectedGroups = preLayoutInfo.content.selectedGroups || [];
  const preLayoutComponents = preLayoutInfo.content.components || [];
  const prevConfig = channelInfo.config || {};
  const type = preLayoutInfo.content.type;

  let components = [...info.content.components];

  replaceMonitor.push(`Update signals.\n`);
  //GET channel signals by pre layout signal_groups
  const new_signals = getPreLayoutChannelSignals(signal_groups, selectedGroups, type);

  const selectedSignals = new_signals.map(item => item.name);
  //get components by new signals
  const new_components = getPreLayoutChannelComponents(preLayoutComponents, selectedSignals);

  //update components
  const newCompInfo = replaceComponents(new_components, components, replaceMonitor);
  components = newCompInfo.components;
  replaceMonitor = newCompInfo.replaceMonitor;

  //update seasim config
  if (type === PCIE) {
    const { channels, analysisChannels } = getSeaSimChannels({
      signals: new_signals,
      components,
      selectedSignals: new_signals.map(item => item.name),
      isPreLayoutChannel: true
    });
    info.config = new SeaSimConfig({ channels, analysisChannels, options: prevConfig.analysis ? prevConfig.analysis.options : null });
  } else {
    delete info.config;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(type)) {
    //ads config
    const { controller, device } = getAMIComponents(components);
    const adsSignals = getDefaultAdsSignals({
      signals: new_signals,
      ICComps: components.filter(item => SERDES_TYPES.includes(item.type)),
      controller,
      device,
      selectedSignals: new_signals.map(item => item.name),
      isPreLayoutChannel: true,
      serdesType: type
    });
    info.adsConfig = new ADSConfig({ serdesType: type, signals: adsSignals, controller, device });
  }

  // update hspiceConfig
  if ([CPHY].includes(type)) {
    info.hspiceConfig = initChannelHspiceConfig({
      signals: new_signals,
      components
    });
  } else {
    delete info.hspiceConfig;
  }

  info.content.components = [...components];
  info.content.signals = [...new_signals];
  info.content.selectedSignals = new_signals.map(item => item.name);
  info.designVersion = designVersion;
  info.type = type;

  return {
    channelInfo: info,
    replaceMonitor: { id: info.id, designId: info.designId, title: info.pcbName, monitor: replaceMonitor }
  };
}

function setDefaultModelKeyInConnPkgModel(channelInfo) {
  if (!channelInfo || !channelInfo.content || !channelInfo.content.components) {
    return channelInfo;
  }
  let components = [...channelInfo.content.components];

  for (let comp of components) {
    if (!SERDES_TYPES.includes(comp.type)) {
      continue;
    }
    if (comp.pkg && comp.pkg.type === TOUCHSTONE_SPICE) {
      comp.pkg = setDefaultModelKey(comp.pkg);
    }

    if (comp.connectorModel && comp.connectorModel.type === TOUCHSTONE_SPICE) {
      comp.connectorModel = setDefaultModelKey(comp.connectorModel);
    }
  }
  channelInfo.content.components = components;
  return channelInfo;
}

function setDefaultModelKey(model) {
  if (!model || model.type !== TOUCHSTONE_SPICE) {
    return model;
  }
  let modelKeyMap = new Map();
  for (let i = 0; i < model.files.length; i++) {
    if (model.files[i].libraryId) {
      const modelKey = (i + 1).toString();
      modelKeyMap.set(model.files[i].libraryId, modelKey);
      model.files[i].modelKey = modelKey;
    }
  }
  for (let pair of model.pairs) {
    if (pair.libraryId) {
      pair.modelKey = modelKeyMap.get(pair.libraryId);
    }
  }
  return model;
}

function judgeSelectCompExist(components) {
  if (!components) {
    return false;
  }
  const findSelectComp = components.find(item => !!item.SELECT_COMP);
  if (!findSelectComp) {
    return true;
  }
  return false;
}

function setSelectCompInComponents(channelInfo) {
  const components = channelInfo.content.components || [];

  for (let comp of channelInfo.content.components) {
    if (SERDES_TYPES.includes(comp.type)) {
      comp.SELECT_COMP = true;
      break;
    }
  }
  channelInfo.content.components = components;
  return channelInfo;
}

function updateADSAndSeasimComponent(channelInfo) {

  //update ads config controller and device 
  let adsConfig = channelInfo.adsConfig ? { ...channelInfo.adsConfig } : {};
  let config = channelInfo.config ? { ...channelInfo.config } : {};
  const components = channelInfo.content.components || [];
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));

  //Update ads config
  adsConfig = updateADSComponent(adsConfig, ICComps.map(item => item.name), channelInfo.type);
  channelInfo.adsConfig = adsConfig;

  //update seasim config
  if (channelInfo.type === PCIE && Object.keys(config).length) {
    config = updateSeasimComponent(config, ICComps);
    channelInfo.config = config;
  }

  return channelInfo;
}

function updateSeasimComponent(config, ICComps) {
  let channels = config.channels || [];

  if (!channels.length) {
    return config;
  }

  const ICCompNames = ICComps.map(item => item.name);

  if (!ICComps.length) {
    for (let channel of channels) {
      channel.controller = { component: "", pins: [] };
      channel.device = { component: "", pins: [] }
    }
    config.channels = channels;
    return config;
  }

  let controller = channels[0].controller.component, device = channels[0].device.component;

  if (!controller || !ICCompNames.includes(controller)) {
    const _icComps = ICCompNames.filter(item => item !== device);
    controller = _icComps.length ? _icComps[0] : "";
  }

  if (!device || !ICCompNames.includes(device)) {
    const _icComps = ICCompNames.filter(item => item !== controller);
    device = _icComps.length ? _icComps[0] : "";
  }

  for (let channel of channels) {
    const controllerPins = controller ? ICComps.find(item => item.name === controller).pins.filter(item => item.signal === channel.signal).map(item => item.pin) : [];
    channel.controller = { component: controller, pins: [...controllerPins] };

    const devicePins = device ? ICComps.find(item => item.name === device).pins.filter(item => item.signal === channel.signal).map(item => item.pin) : [];
    channel.device = { component: device, pins: [...devicePins] }
  }
  config.channels = channels;
  return config;
}

function updateADSComponent(adsConfig, ICComps, serdesType) {
  if (!Object.keys(adsConfig).length) {
    return adsConfig;
  }

  if (serdesType === CPHY) {
    return updateCPHYADSComponent(adsConfig, ICComps);
  }

  if (!ICComps.length) {
    adsConfig.controller = "";
    adsConfig.device = "";

    for (let signal of adsConfig.signals) {
      const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";
      signal[`tx${modelKey}Model`].component = "";
      if (signal[`rx${modelKey}Model`]) {
        signal[`rx${modelKey}Model`].component = "";
      } else if (signal.rcModel) {
        signal.rcModel.component = "";
      }
      if (signal.txCircuitModel && signal.txCircuitModel.component) {
        signal.txCircuitModel.component = "";
      }
      if (signal.rxCircuitModel && signal.rxCircuitModel.component) {
        signal.rxCircuitModel.component = "";
      }
    }
    return adsConfig;
  }

  if (!adsConfig.controller || !ICComps.includes(adsConfig.controller)) {
    const _icComps = ICComps.filter(item => item !== adsConfig.device);
    adsConfig.controller = _icComps.length ? _icComps[0] : "";
  }

  if (!adsConfig.device || !ICComps.includes(adsConfig.device)) {
    const _icComps = ICComps.filter(item => item !== adsConfig.controller);
    adsConfig.device = _icComps.length ? _icComps[0] : "";
  }

  for (let signal of adsConfig.signals) {
    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";

    const rcKey = signal[`rx${modelKey}Model`] ? `rx${modelKey}Model` : 'rcModel'
    if (signal.signalName.match(/TX/ig)) {
      signal[`tx${modelKey}Model`].component = adsConfig.controller;
      signal[rcKey].component = adsConfig.device;
      if (signal.txCircuitModel && signal.txCircuitModel.component) {
        signal.txCircuitModel.component = adsConfig.controller;
      }
      if (signal.rxCircuitModel && signal.rxCircuitModel.component) {
        signal.rxCircuitModel.component = adsConfig.device;
      }
      continue;
    }

    if (signal.signalName.match(/RX/ig)) {
      signal[`tx${modelKey}Model`].component = adsConfig.device;
      signal[rcKey].component = adsConfig.controller;
      if (signal.txCircuitModel && signal.txCircuitModel.component) {
        signal.txCircuitModel.component = adsConfig.device;
      }
      if (signal.rxCircuitModel && signal.rxCircuitModel.component) {
        signal.rxCircuitModel.component = adsConfig.controller;
      }
      continue;
    }
    signal[`tx${modelKey}Model`].component = adsConfig.controller;
    signal[rcKey].component = adsConfig.device;
    if (signal.txCircuitModel && signal.txCircuitModel.component) {
      signal.txCircuitModel.component = adsConfig.controller;
    }
    if (signal.rxCircuitModel && signal.rxCircuitModel.component) {
      signal.rxCircuitModel.component = adsConfig.device;
    }
  }
  return adsConfig;
}

function updateCPHYADSComponent(adsConfig, ICComps) {
  if (!ICComps.length) {
    adsConfig.controller = "";
    adsConfig.device = "";

    for (let signal of adsConfig.signals) {
      signal.cphyTxModel.component = "";
      signal.cphyRxModel.component = "";
    }
    return adsConfig;
  }

  if (!adsConfig.controller || !ICComps.includes(adsConfig.controller)) {
    const _icComps = ICComps.filter(item => item !== adsConfig.device);
    adsConfig.controller = _icComps.length ? _icComps[0] : "";
  }

  if (!adsConfig.device || !ICComps.includes(adsConfig.device)) {
    const _icComps = ICComps.filter(item => item !== adsConfig.controller);
    adsConfig.device = _icComps.length ? _icComps[0] : "";
  }

  for (let signal of adsConfig.signals) {
    signal.cphyTxModel.component = adsConfig.controller;
    signal.cphyRxModel.component = adsConfig.device;
  }

  return adsConfig;
}

function getExtractionType(extraction) {
  let extractionType = extraction ? extraction.type : "";
  if (extraction && extraction.type === 'SIwave' && extraction.hybrid) {
    extractionType = "HFSS";
  }
  return extractionType;
}

// setDefaultModelKeyInConnPkgModel
function updateModelInfoInConnPkgModel(components) {
  if (!components || !components.length) { return components }
  const _components = components.map(comp => {
    if (comp.type !== BGA) { return comp };
    let _comp = JSON.parse(JSON.stringify(comp))
    let _modelList = [];
    [['connectorModel', 'Connector'], ['cableModel', 'Cable'], ['packageModel', 'Package']].forEach(item => {
      const key = item[0];
      if (comp[key]) {
        _modelList.push({ ...comp[key], modelType: item[1] })
      }
    })

    if (!_modelList.length) { return comp }

    delete _comp.connectorModel;
    delete _comp.cableModel;
    delete _comp.packageModel;
    return { ..._comp, modelList: _modelList }
  })
  return _components
}

function updatePortAvoidSinglePinGroup({ port_setups, ports_generate_setup_list, avoidSinglePinGroup, clear = false }) {
  //Update avoid_single_pin_group
  let _port_setups = [...port_setups];

  if (clear) {
    for (let item of _port_setups) {
      if (item.positive && item.positive.component) {
        const findSetup = ports_generate_setup_list.find(it => it.component === item.positive.component);
        if (!findSetup || !findSetup.setup || findSetup.setup.portType !== PIN_GROUP) {
          delete item.positive.avoid_single_pin_group;
        }
      }
    }
    return _port_setups
  }

  for (let item of _port_setups) {
    if (item.positive && item.positive.component) {
      const findSetup = ports_generate_setup_list.find(it => it.component === item.positive.component);
      if (findSetup && findSetup.setup && findSetup.setup.portType === PIN_GROUP && avoidSinglePinGroup[item.positive.component]) {
        item.positive.avoid_single_pin_group = avoidSinglePinGroup[item.positive.component]
      } else {
        delete item.positive.avoid_single_pin_group;
      }
    }
  }
  return _port_setups;
}

function handleRefNetsToPowerNets({ actionChannelInfo, channelInfo, pcbId, data }) {
  let _channelInfo = actionChannelInfo ? { ...actionChannelInfo } : { ...channelInfo };
  if (!_channelInfo || !_channelInfo.content) {
    return;
  }
  let powerNets = _channelInfo.content.powerNets ? [..._channelInfo.content.powerNets] : [];
  const components = _channelInfo.content.components ? [..._channelInfo.content.components] : [];
  const signals = _channelInfo.content.signals ? [..._channelInfo.content.signals] : [];
  let signalNets = [];
  // signals.forEach(item => { signalNets = [...signalNets, ...item.nets_P, ...item.nets_N] });
  for (const signal of signals) {
    const netList = signal.nets_A ? [...signal.nets_A, ...signal.nets_B, ...signal.nets_C] : [...signal.nets_P, ...signal.nets_N];
    signalNets = [...signalNets, ...netList];
  }
  let compPowerNets = [];
  for (let comp of components) {
    if (!RLC_TYPES.includes(comp.type)) {
      continue;
    };
    for (let pin of comp.pins) {
      // power,ground nets
      if (!pin.signal) {
        compPowerNets.push(pin.net);
      }
    }
  };
  const currentPcbRefNets = data.find(item => item.pcbId === pcbId);
  currentPcbRefNets.nets = currentPcbRefNets ? currentPcbRefNets.nets.filter(item => !signalNets.includes(item)) : null;
  if (!currentPcbRefNets || !Array.isArray(currentPcbRefNets.nets)) {
    return;
  }

  //reference and not includes rlc components power nets
  const refNets = powerNets.filter(item => !!item.reference && !compPowerNets.includes(item.name));
  //find deleted reference nets name
  let deleteRefNets = [];
  refNets.forEach(item => {
    const find = currentPcbRefNets.nets.find(it => it === item.name);
    if (!find) {
      deleteRefNets.push(item.name);
    }
  })
  //filter deleted ref nets
  powerNets = powerNets.filter(item => !deleteRefNets.includes(item.name));
  //update power nets(add new reference nets) 
  currentPcbRefNets.nets.forEach(item => {
    const index = powerNets.findIndex(net => net.name === item);
    if (index > -1) {
      powerNets[index].reference = true;
      if (powerNets[index].custom) {
        delete powerNets[index].custom;
      }
    } else {
      powerNets.push({
        name: item,
        value: item === 'VSS' ? "0" : "",
        reference: true
      })
    }
  });
  _channelInfo.content.powerNets = [...powerNets];
  return { _channelInfo, powerNets }
}

async function findRefNets(action) {
  const { pcbId, signals, isSave, channelInfo } = action;
  //signals :[ { pcbId, signals:[ { name, nets:[] } ] } ]
  const key = await findReferenceNetsBySignal({ signals: [{ pcbId, signals }] });
  if (!key) {
    //failed
    return channelInfo;
  }
  await delay(1000)
  let res = await getReferenceNetsBySignal({ key });
  let { status = 0, data = null } = res ? res : {};
  while (status === FIND_REF_NET_RUNNING) {
    await delay(2000)
    res = await getReferenceNetsBySignal({ key });
    if (!res) {
      break;
    }
    status = res.status;
    data = res.data;
  }

  if (status === FIND_REF_NET_FAILED || !data || !Array.isArray(data)) {
    //failed
    return channelInfo;
  }

  if (status === FIND_REF_NET_SUCCESS) {
    const { _channelInfo } = handleRefNetsToPowerNets({ pcbId, data, isSave, channelInfo })
    return _channelInfo;
  }
}

async function createChannelContent({ data, designId, advancedConfig, designType, signals, cmcComponents }) {
  let content = {}, generationPortErrors = null, generationPortWarnings = null, netInfo = {}, isExistPorts = false;

  const component = data.component; //{name, part , type, ...}
  const { netsList, layers } = DesignInfo.getPCBInfo(designId);
  content = getDefaultPostLayoutChannelInfo({ component, netsList, layers, designId, type: data.type, advancedConfig, designType, signals, cmcComponents });
  // find reference nets

  let signalNets = content.signals.reduce((prev, curr) => {
    return [
      ...prev,
      {
        name: curr.name,
        nets: data.type === CPHY ? [...curr.nets_A, ...curr.nets_B, ...curr.nets_C] : [...curr.nets_P.map(d => d), ...curr.nets_N.map(d => d)]
      }
    ]
  }, [])

  if (signalNets.length) {
    const _channelInfo = await findRefNets({ pcbId: designId, signals: signalNets, isSave: true, channelInfo: { content: { ...content } } })
    content.components = _channelInfo.content.components;
    content.powerNets = _channelInfo.content.powerNets;
  }
  //default generation ports
  const ports_generate_setup_list = getPortGenerateSetupList({ components: content.components, pageType: ANDES_V2 });
  // reference nets = power nets filter names
  const referenceNets = getDefaultReferenceNets(content.powerNets);

  const defaultZ0 = data.type === PCIE ? 42.5 : 50;
  let { port_setups, ports_generate_setup_list: setupList, errors, warnings, isExistPorts: _isExistPorts } = getDefaultPortSetupList({
    components: content.components,
    signals: content.signals,
    pcbInfo: { netsList, layers },
    referenceNets,
    designId,
    ports_generate_setup_list,
    referenceZ0: defaultZ0,
    extractionType: getExtractionType(content.extraction)
  });
  isExistPorts = _isExistPorts

  // Value in change components
  let _components = [];
  for (let compInfo of content.components) {
    const info = setupList.find(item => compInfo.name === item.component);
    if (info && info.setup && info.setup && info.setup.portType) {
      let updateBallInfo = {};
      if (BALL_TYPE_NONE_LIST.includes(info.setup.portType) && ![BGA, DIE].includes(info.setup.portType)) {
        updateBallInfo.ball_type = BALL_TYPE_NONE;
      } else if ((!compInfo.ball_type || (compInfo.ball_type !== BALL_TYPE_NONE && (!compInfo.ball_size || !compInfo.ball_height)))) {
        updateBallInfo = getUpdateCompBallInfoByPinSize(compInfo, designId);
      }
      compInfo = { ...compInfo, ...updateBallInfo }
    }
    _components.push(compInfo)
  }
  content.components = [..._components]

  //sort port setups by component and pin
  port_setups = sortPortSetups(port_setups, content.components, content.signals);
  if (data.type === CPHY) {
    content.simulationSolver = ADS;
  }
  content.referenceNets = referenceNets;
  content.port_setups = port_setups;
  content.ports_generate_setup_list = setupList;
  generationPortErrors = errors;
  generationPortWarnings = warnings;
  netInfo = {
    netsList,
    layers
  }
  return { content, generationPortErrors, generationPortWarnings, netInfo, isExistPorts };
}

export {
  getComponents,
  getChannelPowerNets,
  getDefaultCompType,
  getGroupList,
  NEW_GROUP,
  getNetsOptions,
  deleteCompsConnectWithNets,
  getRLCCompValue,
  judgeType,
  modifyCompType,
  channelSetupReplace,
  updateReplaceMonitor,
  replacePwrNetsMonitor,
  updateComponentsPortSetup,
  judgeUpdatePortSetup,
  updateComponentsPackage,
  getPkgCompPinList,
  updateExtractionGapPortsSetup,
  judgeUpdateGapPortSetup,
  preLayoutChannelSetupReplace,
  setDefaultModelKeyInConnPkgModel,
  judgeSelectCompExist,
  setSelectCompInComponents,
  updateADSAndSeasimComponent,
  getExtractionType,
  updateModelInfoInConnPkgModel,
  updatePortAvoidSinglePinGroup,
  createChannelContent,
  handleRefNetsToPowerNets
}