import http from '../http';
import JSZip from 'jszip';

/**
 * Get Andes user library list
 * 2021.03.10
 * @export
 * @returns
 */
export function getAndesLibraryList() {
  return http.get(`/library/andes/list`);
}

/** 
 * Save library data
 * {object} data { id, name, version , type, config :{ ...} }
 * 2021.03.10
*/

export function saveAndesLibraryData(data, productType = "Andes") {
  return http.post(`/library/${productType}/data/update`, { ...data });
}

/**
 * DELETE Andes library by id
 * 2021.03.10
 * @export
 * @returns
 */
export function delAndesLibrary(libraryId, productType = "Andes") {
  return http.post(`/library/${productType}/delete`, {
    id: libraryId
  });
}


/**
 * Get Andes library  detail data by id
 * 2021.03.11
 * @export
 * @returns
 */
export function getAndesLibraryDetail(libraryId, productType = "Andes") {
  return http.get(`/library/${productType}/detail?id=${libraryId}`);
}

/**
 * calculate Zdiff and Z0
 * 2021.03.17
 * @export
 * @returns
 */
export function calculateAndesImpedance(config) {
  return http.post(`/library/impedance/calculate`, {
    ...config
  });
}

/**  
 * upload andes library
 * 2021.04.25
 * @param {Object} library {
  "fileType": "string",
  "libraries": [
    {
      "fileName": "string",
      "name": "string",
      "type": "string"
    }
  ],
  "typeName": "string"
}
 * @export
 * @return
*/
export function uploadAndesLibrary({ files, fileType, typeName, config, metadata }, productType = "andes") {
  let formData = new FormData();
  //ibis/spice/vector file
  let zip = new JSZip();
  for (let file of files) {
    zip.file(file.fileName, file.file);
  }
  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level: 1 }
  }, metadata).then(blob => {
    formData.append('file', new File([blob], 'file.zip'));
    formData.append('typeName', typeName);
    formData.append('fileType', fileType);
    return http.post(`/library/${productType}/upload`, formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  });
}

export function getLibraryFileContent(libraryId, fileName, productType = 'andes') {
  let url = `/library/${productType}/resource?libraryId=${libraryId}`;
  if (fileName) {
    url = `/library/${productType}/resource?libraryId=${libraryId}&fileName=${fileName}`;
  }
  return http.get(url);
}

/**
 * Get a list of folder in the library
 * 2022/02/15
 * @param {string} libraryId folder libraryId
 * @param {string} productType andes
 * @export
 * @returns
 */
export function getFolderFileDetail({ libraryId, productType = "andes" }) {
  return http.get(`/user/library/${productType}/detail?libraryId=${libraryId}`);
}

/**
 * Get a list of folder in the library
 * 2022/02/23
 * @param {string} libraryId folder libraryId
 * @param {string} productType andes
 * @param {string} fileName Folder file name
 * @export
 * @returns
 */
export function getAndesFolderFileContent({ libraryId, fileName, productType = "andes" }) {
  return http.get(`/user/library/${productType}/detail?libraryId=${libraryId}&fileName=${fileName}`);
}

/**
 * Get ibis file parse
 * 2022/05/12
 * @param {string} libraryId
 * @export
 * @returns
 */
export function getAndesIBISFileParse(libraryId) {
  return http.get(`library/andes/ibis/parse?libraryId=${libraryId}`);
}
