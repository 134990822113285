import {
  UPLOAD_PCB_START_UPDATE_STATUS,
  UPDATE_UPLOAD_PROGRESS,
  UPDATE_UPLOAD_PCB_MSG,
  TRANSLATION_FLOW,
  CLEAN_UPLOAD_PCB_STATUS,
  UPDATE_UPLOAD_WORKFLOW,
  UPDATE_TRANSLATION_MONITOR,
  UPDATE_TRANSLATION_DEBUG,
  UPDATE_TRANSLATION_PROGRESS,
  UPDATE_UPLOAD_END_MSG,
  CANCEL_UPLOAD_WORKFLOW,
  SAVE_CURRENT_PCB_KEY,
  UPDATE_COMPRESS_PROGRESS,
  GET_TRANSLATION_MONITOR,
  CHANGE_DESIGN,
  UPDATE_DESIGN_LOG,
  UPDATE_DESIGN_NAME,
  CLEAR_UPLOAD_DATA,
  CHANGE_UPLOAD_DISABLE_STATUS
} from './actionType';

export const uploadPCBStart = (projectId, uploadType) => ({
  type: UPLOAD_PCB_START_UPDATE_STATUS,
  projectId,
  uploadType
})

export const updateUploadProgress = (uploadProgress) => ({
  type: UPDATE_UPLOAD_PROGRESS,
  uploadProgress
});

export const updateMsg = (msg) => ({
  type: UPDATE_UPLOAD_PCB_MSG,
  msg
});

export const translationFlow = (response, designId) => ({
  type: TRANSLATION_FLOW,
  response,
  designId
});

export const cleanUploadPCBStatus = (status) => ({
  type: CLEAN_UPLOAD_PCB_STATUS,
  status
});

export const updateUploadWorkflow = (uploadDesignId, uploadWorkflowId) => ({
  type: UPDATE_UPLOAD_WORKFLOW,
  uploadDesignId,
  uploadWorkflowId
});

export const translationMonitor = (monitor) => ({
  type: UPDATE_TRANSLATION_MONITOR,
  monitor
});

export const translationDebug = (debug) => ({
  type: UPDATE_TRANSLATION_DEBUG,
  debug
});

export const updateTranslationProgress = ({ progress }) => ({
  type: UPDATE_TRANSLATION_PROGRESS,
  transProgress: progress
});

export const updateEndMsg = (msg) => ({
  type: UPDATE_UPLOAD_END_MSG,
  msg
});

export const cancelUploadWorkflow = (workflowId) => ({
  type: CANCEL_UPLOAD_WORKFLOW,
  workflowId
});

export const saveCurrentPCBKey = (pcbKey) => ({
  type: SAVE_CURRENT_PCB_KEY,
  pcbKey
});

export const updateCompressProgress = (compressProgress) => ({
  type: UPDATE_COMPRESS_PROGRESS,
  compressProgress
});

export const getTranslationMonitor = (workflowId, projectId) => ({
  type: GET_TRANSLATION_MONITOR,
  workflowId,
  projectId
});

export const changeDesign = (designId) => ({
  type: CHANGE_DESIGN,
  designId
});

export const updateDesignLog = ({ log, designId }) => ({
  type: UPDATE_DESIGN_LOG,
  log,
  designId
});

export const updateDesignName = (designName, designType) => ({
  type: UPDATE_DESIGN_NAME,
  designName,
  designType
})

export const clearUploadData = () => ({
  type: CLEAR_UPLOAD_DATA
})

export const changeUploadDisableStatus = (disabled) => ({
  type: CHANGE_UPLOAD_DISABLE_STATUS,
  disabled
})