import { checkRLCValueInPowerTree, getValueByComp } from '../../RLCValue';
import { RES, IND, SWITCH, FERRITE, JUMPER, IGNORE, DIODE } from '../../../PCBHelper';
import { getDefaultName } from '../../setDefaultName';
import designConstructor from '../../designConstructor';
import { newNanoId } from '../../idHelper';
import { mergeInPowerTree } from '../../../workers/Cascade/mergePowerTree.worker.js';
import _ from 'lodash';
import { CONNECTOR, LOAD, TRANSISTOR } from '../../../../constants/componentType';
import auroraDBJson from '../../../Designs/auroraDbData';

const ComponentType = [IND, RES, SWITCH, FERRITE, JUMPER, DIODE];
const VRM_CHARACTERISTIC = ['SW', 'VOUT', 'OUT'];
class GetPowerTree {
  constructor(propsParams, propsClass, propsFn) {
    const {
      root, pins, GroundNet, COMP_PREFIX_LIB = {}, PMIC, designId, compTable, isDesignTree = false, doNotStuff, broComp, pinMap,
      connectors = [], moreSetting, pinConnection, showGnd, treeColor, treeName, useInput, powerTreeLevel, extraPMIC
    } = propsParams;
    // Input params
    this.root = root;
    this.pins = pins;
    this.GroundNet = GroundNet || "";
    this.COMP_PREFIX_LIB = COMP_PREFIX_LIB;
    this.driverList = COMP_PREFIX_LIB.driver || [];
    this.PMIC = PMIC;
    this.designId = designId;
    this.compTable = compTable;
    this.isDesignTree = isDesignTree;
    this.doNotStuff = doNotStuff;
    this.broComp = broComp;
    this.pinMap = pinMap || [];
    this.connectors = connectors && connectors.length && connectors.length > 1 ? connectors : [];
    this.propConnectors = connectors;
    this.isConnection = connectors && connectors.length && connectors.length > 1 ? true : false;
    this.moreSetting = moreSetting;
    this.pinConnection = pinConnection || [];
    this.GroundNets = {
      [designId]: GroundNet.split(', ')
    }
    this.showGnd = showGnd
    this.treeColor = treeColor || []
    this.treeName = treeName
    this.useInput = useInput
    this.extraPMIC = extraPMIC || []

    const { RootItem, ComponentItem, ICItem, DriverItem, ConnectionItem } = propsClass;
    this.RootItem = RootItem;
    this.ComponentItem = ComponentItem;
    this.ICItem = ICItem;
    this.DriverItem = DriverItem;
    this.ConnectionItem = ConnectionItem

    const { getComponents, getPowerDomain, sortTree, reShowGndLine, setTreeMonitors } = propsFn;
    this.getComponents = getComponents
    this.getPowerDomain = getPowerDomain;
    this.sortTree = sortTree;
    this.reShowGndLine = reShowGndLine;
    this.setTreeMonitors = setTreeMonitors;

    // Default
    this.maxLevel = Number(powerTreeLevel) || 8;
    this.allComponents = getComponents({ pcbId: designId });
    this.PMICPath = [];
    this.usedPCB = [];
    this.connectionNets = [];
    this.prevComps = {};

    // Output
    this.treeData = [];
    this.pcbIndex = [];
    this.logs = [];
  }

  getPowerTree() {
    const { root, designId, pins, RootItem, PMIC, allComponents, isDesignTree, broComp, GroundNet = "", isConnection } = this;
    if (!root || !designId || !pins || !pins.length) {
      return []
    }
    const rootCompInfo = allComponents.get(root);
    const _pins = [...rootCompInfo.pins.values()].filter(p => pins.includes(p.pin));
    const gndPins = [...rootCompInfo.pins.values()].filter(p => GroundNet.split(', ').includes(p.net));
    const rootComp = new RootItem({ ...rootCompInfo, type: 'root', pins: [..._pins, ...gndPins], broComp });
    const pmic = PMIC.find(item => item.partName === rootComp.partName);

    this.treeData.push([rootComp]);
    let rootNets = _pins.map(p => p.net).filter(n => n !== 'NONET');
    this.rootNets = [...new Set(rootNets)]
    const tempLog = [{ type: 'comp', name: root }]
    if (isConnection) {
      this.setGroundNets()
    }
    for (let rootNet of this.rootNets) {
      this.getChildComp({
        level: 1,
        rootNet,
        prevNet: rootNet,
        prevComp: root,
        prevCompList: [],
        pmic,
        driverPath: [],
        tempLog: [...tempLog, { type: 'net', name: rootNet }],
        prevNets: [{ net: rootNet, pcbKey: undefined }],
        prevComps: []
      })
    }

    if (isDesignTree) {
      return this.PMICPath;
    }

    // const tree = await this.sortTree(this.treeData, connectors);
    // return tree;
    return [];
  }

  setGroundNets = () => {
    const { connectors } = this;
    for (let i = 0; i < connectors.length - 1; i++) {
      for (let conn of connectors[i]) {
        const { pcb, components } = conn;
        const ground = this.GroundNets[pcb];
        if (ground) {
          components.forEach(comp => {
            comp.next.forEach(item => {
              const { pcb, nets } = item;
              nets.forEach(net => {
                if (net[0] && ground.includes(net[0])) {
                  if (this.GroundNets[pcb]) {
                    this.GroundNets[pcb].push(net[1])
                  } else {
                    this.GroundNets[pcb] = [net[1]]
                  }
                }
              })
            })
          })
        }
      }
    }
  }

  getChildComp = (options) => {
    const {
      level, rootNet, prevNet, prevComp, prevCompList, pmic, driverCompPrevList, prevDriver,
      pcbId, connectionIndex = 0, isGnd = false, tempLog, pcbKey, fromConn = false, prevNets,
      prevComps = []
    } = options;
    const {
      getComponents, COMP_PREFIX_LIB, designId, allComponents, ComponentItem, maxLevel, PMIC, GroundNet = "",
      root, compTable, doNotStuff, driverList, pinMap, DriverItem, moreSetting, pinConnection, useInput
    } = this;
    let _GroundNet = pcbKey ? [undefined] : GroundNet.split(", ");
    const _prevNets = JSON.parse(JSON.stringify(prevNets))
    const gndNet = pcbKey ? ['GND'] : ['GND', ...GroundNet.split(", ")];
    if (level > maxLevel) {
      if (prevDriver) {
        const { rootNet: _rootNet, prevNet: _prevNet, prevComp: _prevComp, name, part, pins } = prevDriver;
        this.setTreeMonitors([{ text: `\t\t√ ${name}`, type: 'success', key: this.treeName }])
        this.getICComps({
          rootNet: _rootNet,
          prevNet: _prevNet,
          prevCompList: driverCompPrevList,
          prevComp: _prevComp,
          IC: { name, part, pins },
          type: "Load",
          tempLog,
          pcbKey
        })
      } else {
        this.setTreeMonitors([{ text: `\t\tX Maximum search depth exceeded.`, type: 'error', key: this.treeName }])
      }
      return;
    }

    let _COMP_PREFIX_LIB = COMP_PREFIX_LIB, _doNotStuff = doNotStuff, _compTable = compTable, _pinMap = pinMap, _pinConnection = pinConnection;
    if (pcbId && moreSetting[pcbId]) {
      _COMP_PREFIX_LIB = moreSetting[pcbId].setting || COMP_PREFIX_LIB;
      _doNotStuff = moreSetting[pcbId].doNotStuff || doNotStuff;
      _compTable = moreSetting[pcbId].table || compTable;
      _pinMap = moreSetting[pcbId].pinMap || pinMap;
      _pinConnection = moreSetting[pcbId].pinConnection || pinConnection;
    }
    const _allComponents = pcbId ? getComponents({ pcbId }) : allComponents

    let components = [];
    const isPreLayout = designConstructor.isPreLayout(pcbId || designId);
    if (isPreLayout) {
      components = [..._allComponents.values()].filter(item => [...item.pins.values()].some(pin => pin.net === prevNet));
    } else {
      components = auroraDBJson.getComponentsByNets(pcbId || designId, [prevNet])
    }
    let types = ComponentType, foundNext = false;
    for (let comp of components) {
      if (_doNotStuff.includes(comp.name)) {
        this.setTreeMonitors([{ text: `\t\tX Skip ${comp.name}. Component is DNS.`, type: 'error', key: this.treeName }])
        continue;
      }

      if (comp.name !== root && comp.name === prevComp) {
        continue;
      }

      let transistorVRM = true
      if (comp.type === TRANSISTOR && !_pinMap.some(map => map.type === "buckConverter" && map.partNumber.includes(comp.name))) {
        transistorVRM = false;
      }

      const realType = this.getLoadComp(comp, _COMP_PREFIX_LIB) || comp.type;
      const _comp = _allComponents.get(comp.name);
      if (this.prevComps[pcbKey || designId] && this.prevComps[pcbKey || designId].find(item => item.comp === comp.name && item.level !== level)) {
        if (_comp.pins.size < 4) {
          this.setTreeMonitors([{ text: `\t\tX Skip ${comp.name}. Component has been used.`, type: 'error', key: this.treeName }])
          continue;
        }
        if (this.prevComps[pcbKey || designId].find(item => item.comp === comp.name && item.level !== level && [item.net, item.prevNet].includes(prevNet))) {
          this.setTreeMonitors([{ text: `\t\tX Skip ${comp.name}. Component has been used.`, type: 'error', key: this.treeName }])
          continue;
        }
      }

      if (types.includes(realType) || !transistorVRM) {

        if (_comp.pins.size < 2) {
          this.setTreeMonitors([{ text: `\t\tX Skip ${comp.name}. Component only has one pin.`, type: 'error', key: this.treeName }])
          continue;
        }

        const currentPins = [..._comp.pins.values()].filter(pin => pin.net === prevNet);
        const currentPinNumbers = currentPins.map(pin => pin.pin);

        let anotherPins = [], corrPins = [];
        let pins = [..._comp.pins.values()];
        if ([SWITCH, RES].includes(_comp.type) && _comp.pins.size > 2) {

          const pinConn = _pinConnection.find(item => item.partNumber === _comp.partName);

          if (pinConn) {
            const pinMap = pinConn.pinMap || [];
            if (pinMap.length) {
              pinMap.forEach(array => {
                const left = array[0] ? array[0].split(', ') : [];
                const right = array[1] ? array[1].split(', ') : [];
                if (left.some(item => currentPinNumbers.includes(item))) {
                  corrPins.push(...right);
                } else if (right.some(item => currentPinNumbers.includes(item))) {
                  corrPins.push(...left);
                }
              })
              pins = pins.filter(item => corrPins.includes(item.pin));
            } else {
              this.setTreeMonitors([{ text: `\t\t? ${_comp.name} is missing Pin Map. Go to Component Setting -> Classfication -> ${_comp.type === SWITCH ? ' Power Switch' : 'Multi Resistor'}  for Pin Map setting.`, type: 'warning', key: this.treeName }])
              if (_comp.type === SWITCH) {
                const otherPin = [..._comp.pins.values()].filter(pin => pin.pinName.toUpperCase().includes('OUT') && ![..._GroundNet, ...gndNet].includes(pin.net));
                if (otherPin.length) {
                  anotherPins.push(...otherPin.map(pin => pin.pin))
                }
              }
              if (!anotherPins.length) {
                for (let cur of currentPins) {
                  if (!isNaN(Number(cur.pin))) {
                    let othersNum = _comp.pins.size + 1 - Number(cur.pin);
                    const otherPin = [..._comp.pins.values()].find(pin => pin.pin === String(othersNum));
                    if (otherPin && ![..._GroundNet, ...gndNet, 'NONET'].includes(otherPin.net)) {
                      anotherPins.push(String(othersNum))
                    }
                  }
                }
              }
            }
          }
        }

        const valueComp = _compTable.find(c => c.part === _comp.partName);
        let _value = valueComp ? getValueByComp(valueComp) : _comp.type === IND ? 0 : checkRLCValueInPowerTree(_comp.value);
        if (Number(_value) === 0 && _comp.type === FERRITE) {
          _value = '55';
        }
        if (isNaN(Number(_value)) || Number(_value) >= 1e5) {
          this.setTreeMonitors([{ text: `\t\tX Skip ${comp.name}. Resistance value ${_value} is greater than tracing threshold value (1e5mΩ).`, type: 'error', key: this.treeName }])
          continue;
        }

        let nextNets = pins.map(p => p.net).filter(net => ![prevNet, ..._GroundNet, 'NONET', ...gndNet].includes(net))
        if (_comp.type === SWITCH) {
          let _pins = pins.filter(p => p.pinName.toUpperCase().includes('OUT'))
          nextNets = _pins.length ? _pins.map(p => p.net).filter(net => ![prevNet, ..._GroundNet, ...gndNet, 'NONET'].includes(net)) : nextNets
        } else if (_comp.type === DIODE) {
          let pins = [..._comp.pins.values()].filter(pin => pin.net === prevNet);
          let anothers = [..._comp.pins.values()].filter(pin => pin.net !== prevNet);

          if (!pins.some(item => ['A', 'A1'].includes(item.pin)) || anothers.some(item => [..._GroundNet, ...gndNet, 'NONET'].includes(item.net))) {
            this.setTreeMonitors([{ text: `\t\tX Skip ${comp.name}. Two pins are not connected to Power Net at the same time or the pins are illegal.`, type: 'error', key: this.treeName }])
            continue;
          }
        }
        nextNets = [...new Set(nextNets)]

        if (!isGnd) {
          const { GroundNets } = this;
          const groundNets = GroundNets[pcbId || designId];
          nextNets = nextNets.filter(item => !groundNets.includes(item));
        }

        const _nextNets = nextNets.filter(nextNet => !nextNet.includes('GPIO') && !nextNet.includes('I2C') && !_prevNets.find(net => net.net === nextNet && level !== net.level))
        if (!_nextNets.length) {
          this.setTreeMonitors([{ text: `\t\tX Skip ${comp.name}. No nets to trace.`, type: 'error', key: this.treeName }]);
          continue;
        }

        for (let nextNet of _nextNets) {

          if (!this.prevComps[pcbKey || designId]) {
            this.prevComps[pcbKey || designId] = []
          }
          const _prevComps = [...JSON.parse(JSON.stringify(prevComps)), { net: nextNet, prevNet, comp: comp.name, level }]

          if (corrPins.length) {
            pins = [..._comp.pins.values()].filter(pin => pin.net === prevNet || corrPins.includes(pin.pin) || pin.net === nextNet);
          } else if (anotherPins.length) {
            pins = [..._comp.pins.values()].filter(pin => pin.net === prevNet || anotherPins.includes(pin.pin) || pin.net === nextNet);
          } else {
            pins = [..._comp.pins.values()].filter(pin => pin.net === prevNet || pin.net === nextNet);
          }
          if (![SWITCH, RES, FERRITE, JUMPER].includes(_comp.type) && _pinMap) {
            const output = _pinMap.map(item => item.data ? item.data.map(d => d.output) : []).flat(3);
            pins = pins.filter(item => !output.includes(item.pinName))
          }
          let pinMap = [];
          if ([SWITCH, RES].includes(_comp.type) && _comp.pins.size > 2) {
            const pinConn = _pinConnection.find(item => item.partNumber === _comp.partName);
            if (pinConn) {
              let _pinMap = pinConn.pinMap || [];
              if (_pinMap.length) {
                pinMap = _pinMap.flat(2)
              }
            }
          }
          const compItem = new ComponentItem({
            type: _comp.type,
            name: _comp.name,
            part: _comp.partName,
            pins,
            prevComp,
            rootNet,
            prevNet,
            pinMap,
            resistance: _value,
            isGnd,
            pcbKey,
            pcbId: pcbId || designId,
            rootInd: level === 1 && prevComp === root && _comp.type === IND ? true : false
          })
          const __prevNets = [...JSON.parse(JSON.stringify(_prevNets)), { net: nextNet, level }];
          this.getChildComp({
            level: level + 1,
            rootNet: _comp.type === IND ? nextNet : rootNet,
            prevNet: nextNet,
            prevComp: _comp.name,
            prevCompList: [...prevCompList, compItem],
            driverCompPrevList,
            prevDriver,
            pmic,
            isGnd,
            tempLog: [...tempLog, { type: 'comp', name: _comp.name }, { type: 'net', name: nextNet }],
            pcbKey,
            pcbId,
            prevNets: JSON.parse(JSON.stringify(__prevNets)),
            prevComps: _prevComps,
            connectionIndex
          })

          foundNext = true
        }
      } else if ([IGNORE, TRANSISTOR].includes(realType)) {
        let isIC = true;
        const _comp = _allComponents.get(comp.name)
        if (driverList.includes(comp.partName)) {
          const currentPinMap = _pinMap.find(item => item.partNumber === _comp.partName);
          if (currentPinMap) {
            const data = currentPinMap.data || [];
            const input = data.map(d => d.input).flat(2)
            const output = data.map(d => d.output).flat(2);
            const outputGround = data.map(d => d.outputGround).flat(2);
            const inputGround = data.map(d => d.inputGround).flat(2);
            const inputNets = [..._comp.pins.values()].filter(pin => input.includes(pin.pinName)).map(pin => pin.net);
            if (inputNets.includes(prevNet) && output.length) {
              const allPins = [...input, ...output, ...inputGround, ...outputGround];
              let pins = [..._comp.pins.values()].filter(pin => output.includes(pin.pinName));
              let outGndPins = [..._comp.pins.values()].filter(pin => outputGround.includes(pin.pinName));
              let inputGndPins = [..._comp.pins.values()].filter(pin => inputGround.includes(pin.pinName));
              const outGndNet = [...new Set(outGndPins.map(p => p.net))];
              const inputGndNet = [...new Set(inputGndPins.map(p => p.net))];
              let _pins = [..._comp.pins.values()].filter(pin => allPins.includes(pin.pinName));
              let nextNets = pins.map(p => p.net);
              nextNets = [...new Set([...nextNets, ...outGndNet])];
              const gndOut = outGndNet.length ? outGndNet[0] : "";
              const driverItem = new DriverItem({
                type: 'Driver',
                name: _comp.name,
                part: _comp.partName,
                pins: _pins,
                prevComp,
                rootNet,
                prevNet,
                driverMap: data,
                gnd: inputGndNet.length ? inputGndNet[0] : "",
                gndOut,
                isGnd,
                pcbKey
              })
              for (let nextNet of nextNets) {
                const __prevNets = [...JSON.parse(JSON.stringify(_prevNets)), { net: nextNet, level }]
                this.getChildComp({
                  level: level + 1,
                  rootNet: _comp.type === IND ? nextNet : rootNet,
                  prevNet: nextNet,
                  prevComp: _comp.name,
                  driverCompPrevList: [...prevCompList],
                  prevDriver: driverItem,
                  prevCompList: [...prevCompList, driverItem],
                  pmic,
                  isGnd: nextNet === gndOut ? true : false,
                  tempLog: [...tempLog, { type: 'comp', name: _comp.name }, { type: 'net', name: nextNet }],
                  pcbKey,
                  prevNets: __prevNets,
                  connectionIndex
                })
                foundNext = true
              }
              isIC = false;
            }
          }
        }
        if (isIC) {
          if (this.isConnection) {
            const { connectors } = this;
            const connector = connectors[connectionIndex];
            const currentDesign = pcbId || designId;
            this.checkConnection({
              connector,
              currentDesign,
              rootNet,
              prevNet,
              prevCompList,
              prevComp,
              IC: _comp,
              connectionIndex,
              driverCompPrevList,
              prevDriver,
              pmic,
              isGnd,
              tempLog,
              pcbKey,
              prevNets: JSON.parse(JSON.stringify(_prevNets)),
              doNotStuff: _doNotStuff,
              prevComps: JSON.parse(JSON.stringify(prevComps))
            })
          } else {
            let _check = this.ICcheck(_comp, PMIC, prevNet, pcbId || designId);
            let check = ["VRM", "Load"].includes(_check) ? _check : this.getLoadComp(_comp, _COMP_PREFIX_LIB, _check);
            check = check === IGNORE ? 'Load' : check;
            const { connectors } = this;
            const currentDesign = pcbId || designId;
            const connComps = connectors.flat(2).find(pcb => currentDesign === pcb.pcb && (!pcbKey || pcbKey === pcb.key));
            if (connComps) {
              const comp = connComps.components.find(item => item.name === _comp.name);
              if (comp) {
                check = false
              }
            }
            if (check) {
              if (!this.prevComps[pcbKey || designId]) {
                this.prevComps[pcbKey || designId] = [];
              }
              this.prevComps[pcbKey || designId].push(...prevComps)
              this.setTreeMonitors([{ text: `\t\t√ ${_comp.name}`, type: 'success', key: this.treeName }]);
              this.getICComps({
                rootNet,
                prevNet,
                prevCompList,
                prevComp,
                IC: _comp,
                type: check,
                isGnd,
                tempLog,
                pcbKey,
                pcbId
              })
            } else {
              if (!this.rootNets.includes(prevNet)) {
                const input = [..._comp.pins.values()].filter(pin => pin.net === prevNet)
                this.setTreeMonitors([{ text: `\t\tX Skip ${_comp.name}. ${!_check ? `Pin ${input.map(pin => pin.pin).join(', ')} is not specified as PMIC input` : `${_comp.name} is not a Load`}. ${useInput ? 'Close <Only trace to PMICs through input pins> option in Options or review' : !_check ? 'Review' : ''}${!_check ? ` ${_comp.name} PMIC map or set` : 'Set'} ${_comp.name} as Load.`, type: 'error', key: this.treeName }])
              }
            }
          }
          foundNext = true
        }
      }
    }
    if (!foundNext) {
      if (fromConn) {
        if (!this.isDesignTree) {
          this.mergeCompsToTree([...prevCompList]);
        } else {
          this.PMICPath.push([...prevCompList])
        }
      }
    }
  }

  getICComps = (options) => {
    const { rootNet, prevNet, prevCompList, prevComp, IC, type, isGnd, pcbKey, extraNet = [], pcbId } = options;
    const { GroundNet = "", ICItem, root, pins, isDesignTree, pinMap, moreSetting, designId } = this;
    let _GroundNet = pcbKey ? [undefined] : GroundNet.split(', ')
    let _pins = [...IC.pins.values()].filter(pin => [..._GroundNet, prevNet, ...extraNet].includes(pin.net));
    if (IC.name === root && pcbId === designId) {
      _pins = _pins.filter(pin => !pins.includes(pin.pin)
        && !VRM_CHARACTERISTIC.some(words => pin.pinName.includes(words)));
    }
    if (type === 'VRM') {
      let _pinMap = pinMap;
      if (pcbId && moreSetting[pcbId]) {
        _pinMap = moreSetting[pcbId].pinMap || pinMap;
      }
      const pinMapInfo = _pinMap.find(item => item.partNumber === IC.part);
      if (pinMapInfo) {
        const output = pinMapInfo.data ? pinMapInfo.data.map(d => d.output).flat(2) : [];
        _pins = _pins.filter(item => !output.includes(item.pinName))
      }
    }
    if (_pins.filter(pin => !_GroundNet.includes(pin.net)).length || isGnd) {
      const pathKey = [...prevCompList, { name: IC.name, pcbKey }].map(prev => ({ name: prev.name, pcbKey: prev.pcbKey }))
      const ICComp = new ICItem({
        name: IC.name,
        part: IC.partName,
        type,
        pins: _pins,
        rootNet,
        prevComp,
        prevNet,
        isGnd,
        pcbKey,
        pathKey,
        pcbId,
      })
      let _prevCompList = _.cloneDeep(prevCompList)
      _prevCompList.forEach(prev => {
        prev.pathKey = pathKey;
      })
      if (!isDesignTree) {
        this.mergeCompsToTree([..._prevCompList, ICComp]);
      } else {
        this.PMICPath.push([..._prevCompList, ICComp])
      }
    }
  }

  mergeCompsToTree = async (compList) => {
    const { treeData } = this;
    const tree = [...treeData];
    const gndKey = newNanoId();
    const connectionKey = newNanoId();
    const _compList = _.cloneDeep(compList);
    let _connectionNets = _compList.filter(item => item.type === 'Connector').map(item => [item.nextNet]).flat(2);
    let _isGnd = _compList.map(_comp => _comp.isGnd).some(isGnd => isGnd) ? true : false;
    _connectionNets = [...new Set(_connectionNets)];
    const findSameConnectionNets = this.connectionNets.find(nets => _.isEqual(nets.connectionNets, _connectionNets));
    let anotherPrevBranch = _isGnd ? false : findSameConnectionNets ? true : false;
    if (!anotherPrevBranch) {
      this.connectionNets.push({ connectionNets: _connectionNets, connectionKey })
    }
    const _connectionKey = anotherPrevBranch ? findSameConnectionNets.connectionKey : connectionKey
    const _tree = await mergeInPowerTree(_compList, tree, gndKey, _connectionKey, anotherPrevBranch);
    this.treeData = _tree;
  }

  checkConnection = (options) => {
    const { connector, currentDesign, rootNet, prevNet, prevCompList, prevComp, IC, connectionIndex, driverCompPrevList, prevDriver, pmic, isGnd, tempLog, pcbKey, doNotStuff, prevComps } = options;
    const { moreSetting, COMP_PREFIX_LIB, useInput, designId } = this;
    const connComps = connector.find(pcb => currentDesign === pcb.pcb && (!pcbKey || pcbKey === pcb.key));
    const { PMIC, ConnectionItem, getComponents, GroundNet = "", root } = this;
    let isIC = true;
    if (connComps) {
      const comp = connComps.components.find(item => item.name === IC.name);
      if (comp) {
        const next = comp.next || [];
        if (next.length) {
          this.usedPCB.push(connComps.key)
          const connectionItem = new ConnectionItem({
            type: 'Connector',
            name: IC.name,
            part: IC.partName,
            pins: [...IC.pins.values()].filter(item => item.net === prevNet),
            prevNet,
            rootNet,
            nextNet: `CONNECT - ${prevNet} - ${connComps.key}`,
            prevComp,
            pcbId: currentDesign,
            connectionIndex,
            isGnd,
            connectionType: 'prev',
            pcbKey: connComps.key,
            resistance: isGnd ? 5 : 50,
          })
          if (!this.prevComps[pcbKey || designId]) {
            this.prevComps[pcbKey || designId] = [];
          }
          this.prevComps[pcbKey || designId].push(...prevComps)
          for (let nextComp of next) {
            const { pcb, nets, comp: _comp, pcbKey: _pcbKey } = nextComp;
            for (let net of nets) {
              let _isGnd = !pcbKey && net.length && GroundNet.split(', ').includes(net[0]) ? true : false;
              let gndConnItem = { ...connectionItem, nextConnNet: net[1] };
              if (_isGnd) {
                gndConnItem = new ConnectionItem({
                  ...connectionItem,
                  resistance: _isGnd ? 5 : 50,
                  isGnd: _isGnd,
                  prevNet: net[0],
                  nextNet: `CONNECT - ${net[0]} - ${connComps.key}`,
                  pins: [...IC.pins.values()].filter(item => item.net === net[0])
                })
                if (this.GroundNets[currentDesign]) {
                  this.GroundNets[currentDesign].push(net[0])
                } else {
                  this.GroundNets[currentDesign] = [net[0]]
                }
              }
              if (net && net.length && (net[0] === prevNet || _isGnd)) {
                isIC = false;
                const _components = getComponents({ pcbId: pcb });
                const _component = _components.get(_comp);
                if (_component) {
                  this.usedPCB.push(_pcbKey)
                  if (this.GroundNets[pcb]) {
                    this.GroundNets[pcb].push(net[1])
                  } else {
                    this.GroundNets[pcb] = [net[1]]
                  }
                  const _connectionItem = new ConnectionItem({
                    type: 'Connector',
                    name: _component.name,
                    part: _component.partName,
                    pins: [..._component.pins.values()].filter(item => item.net === net[1]),
                    prevNet: `CONNECT - ${_isGnd ? net[0] : prevNet} - ${connComps.key}`,
                    rootNet,
                    prevComp: IC.name,
                    nextNet: net[1],
                    pcbId: pcb,
                    connectionIndex: connectionIndex + 1,
                    isGnd: _isGnd ? _isGnd : isGnd,
                    connectionType: 'next',
                    pcbKey: _pcbKey,
                    resistance: _isGnd ? 5 : isGnd ? 5 : 50,
                    nextConnNet: ""
                  })
                  this.setTreeMonitors([{ text: `\t\t- ${net[1]} -> ${_component.name}  -> ${IC.name} -> ${prevNet}`, type: 'normal', key: this.treeName }]);
                  this.getChildComp({
                    level: 1,
                    rootNet: rootNet,
                    prevNet: net[1],
                    prevComp: _comp,
                    prevCompList: [...prevCompList, _isGnd ? gndConnItem : { ...connectionItem, nextConnNet: net[1] }, _connectionItem],
                    driverCompPrevList,
                    prevDriver,
                    pmic,
                    pcbId: pcb,
                    connectionIndex: connectionIndex + 1,
                    isGnd: _isGnd ? _isGnd : isGnd,
                    pcbKey: _pcbKey,
                    fromConn: true,
                    prevNets: [],
                    tempLog: [...tempLog, { type: 'comp', name: IC.name }, { type: 'net', name: 'Connect' }, { type: 'comp', name: _component.name }, { type: 'net', name: net[1] }],
                    prevComps: []
                  })
                }
              } else if (net && net.length && net[0]) {
                const { gnd, prevComps } = this.traceBackGround(net[0], GroundNet, IC.name, currentDesign, prevCompList.length + 5, { pcbKey, rootNet, doNotStuff });
                if (gnd) {
                  isIC = false;
                  const _components = getComponents({ pcbId: pcb });
                  const _component = _components.get(_comp);
                  if (_component) {
                    this.usedPCB.push(_pcbKey);
                    gndConnItem = new ConnectionItem({
                      ...gndConnItem,
                      prevComp,
                      resistance: 5,
                      truePrevComp: prevComps[prevComps.length - 1].name,
                      prevNet: net[0],
                      isGnd: true,
                      nextNet: `CONNECT - ${net[0]} - ${connComps.key}`,
                      pins: [...IC.pins.values()].filter(item => item.net === net[0]),
                      nextConnNet: net[1]
                    })
                    let _prevCompList = [...prevCompList];
                    _prevCompList.splice(-(prevComps.length))
                    prevComps[0].prevComp = _prevCompList.length ? _prevCompList[_prevCompList.length - 1].name : root;
                    const _connectionItem = new ConnectionItem({
                      type: 'Connector',
                      name: _component.name,
                      part: _component.partName,
                      pins: [..._component.pins.values()].filter(item => item.net === net[1]),
                      prevNet: `CONNECT - ${net[0]} - ${connComps.key}`,
                      rootNet,
                      prevComp: IC.name,
                      nextNet: net[1],
                      pcbId: pcb,
                      connectionIndex: connectionIndex + 1,
                      isGnd: true,
                      connectionType: 'next',
                      pcbKey: _pcbKey,
                      resistance: 5,
                      nextConnNet: ""
                    })
                    this.setTreeMonitors([{ text: `\t\t- ${net[1]} -> ${_component.name}  -> ${IC.name} -> ${prevNet}`, type: 'normal', key: this.treeName }])
                    this.getChildComp({
                      level: 1,
                      rootNet: rootNet,
                      prevNet: net[1],
                      prevComp: _comp,
                      prevCompList: [..._prevCompList, ...prevComps, gndConnItem, _connectionItem],
                      driverCompPrevList,
                      prevDriver,
                      pmic,
                      pcbId: pcb,
                      connectionIndex: connectionIndex + 1,
                      isGnd: true,
                      pcbKey: _pcbKey,
                      fromConn: true,
                      prevNets: [],
                      prevComps: [],
                      tempLog: [{ type: 'net', name: net[0] }, { type: 'comp', name: IC.name }, { type: 'net', name: 'Connect' }, { type: 'comp', name: _component.name }, { type: 'net', name: net[1] }]
                    })
                  }
                }
              }
            }
          }
        }
      }
    }
    if (isIC) {
      let _COMP_PREFIX_LIB = moreSetting && moreSetting[currentDesign] ? moreSetting[currentDesign].setting : COMP_PREFIX_LIB;
      let _check = this.ICcheck(IC, PMIC, prevNet, currentDesign);
      let check = ["VRM", "Load"].includes(_check) ? _check : this.getLoadComp(IC, _COMP_PREFIX_LIB, _check);
      check = check === IGNORE ? 'Load' : check;

      const connComps = this.connectors.flat(2).find(pcb => currentDesign === pcb.pcb && (!pcbKey || pcbKey === pcb.key));
      if (connComps) {
        const comp = connComps.components.find(item => item.name === IC.name);
        if (comp) {
          check = false
        }
      }
      if (check) {
        if (!this.prevComps[pcbKey || designId]) {
          this.prevComps[pcbKey || designId] = [];
        }
        this.prevComps[pcbKey || designId].push(...prevComps)
        this.setTreeMonitors([{ text: `\t\t√ ${IC.name}`, type: 'success', key: this.treeName }])
        this.getICComps({
          rootNet,
          prevNet,
          prevCompList,
          prevComp,
          IC,
          type: check,
          isGnd,
          tempLog,
          pcbKey,
          pcbId: currentDesign
        })
      } else {
        if (!this.rootNets.includes(prevNet)) {
          const input = [...IC.pins.values()].filter(pin => pin.net === prevNet)
          this.setTreeMonitors([{ text: `\t\tX Skip ${IC.name}. ${!_check ? `Pin ${input.map(pin => pin.pin).join(', ')} is not specified as PMIC input` : `${IC.name} is not a Load`}. ${useInput ? 'Close <Only trace to PMICs through input pins> option in Options or review' : !_check ? 'Review' : ''}${!_check ? ` ${IC.name} PMIC map or set` : 'Set'} ${IC.name} as Load.`, type: 'error', key: this.treeName }])
        }
      }
    }
  }

  generateDesignTree = async (paths, select) => {
    this.pins = select;
    const { root, pins, RootItem, allComponents, broComp, GroundNet = "", connectors, showGnd, isConnection } = this;

    const rootCompInfo = allComponents.get(root);
    const _pins = [...rootCompInfo.pins.values()].filter(p => pins.includes(p.pin));
    const gndPins = [...rootCompInfo.pins.values()].filter(p => GroundNet.split(', ').includes(p.net));
    const rootComp = new RootItem({ ...rootCompInfo, type: 'root', pins: [..._pins, ...gndPins], broComp });

    let _paths = [...paths]
    if (!isConnection && showGnd) {
      _paths = this.setGroundForSinglePCB(_paths);
    } else if (isConnection) {
      _paths = this.onlySaveMinPath(_paths);
    }
    _paths = this.filterHasPowerNetGnd(_paths, rootComp);
    _paths = this.insertSameLoadComps(_paths, rootComp);
    _paths = this.insertHiddenComps(_paths, rootComp);
    if (isConnection) {
      _paths = this.splitOnlyGndLoad(_paths);
    }
    this.treeData = [[rootComp]];
    _paths = _paths.sort((a, b) => {
      const aIndex = ['Connector', 'Driver', ...ComponentType, 'Load', 'VRM'].findIndex(item => item === a[0].type)
      const bIndex = ['Connector', 'Driver', ...ComponentType, 'Load', 'VRM'].findIndex(item => item === b[0].type)
      if (aIndex === bIndex) {
        return a[0].name < b[0].name ? -1 : 1
      }
      return aIndex - bIndex < 0 ? -1 : 1;
    })
    for (let path of _paths) {
      const checked = await this.mergeCompsToTree(path);
    }
    let rootNets = _pins.map(p => p.net).filter(n => n !== 'NONET');
    this.rootNets = [...new Set(rootNets)];
    let tree = await this.sortTree(this.treeData, connectors);
    tree = this.reShowGndLine(tree);
    this.setConnectorPCBIndex()

    return tree;
  }

  ICcheck = (comp, pmic, prevNet, pcbId, shouldInput = true) => {
    const { pinMap, designId, moreSetting, extraPMIC } = this;
    let _pinMap = pinMap;
    if (designId !== pcbId) {
      _pinMap = moreSetting[pcbId].pinMap || pinMap
    }

    if (comp.type === TRANSISTOR) {
      const map = _pinMap.find(item => item.partNumber === comp.partName || (item.type === "buckConverter" && item.partNumber.includes(comp.name)));
      let pins = [...comp.pins.values()].filter(pin => pin.net === prevNet)
      if (shouldInput && this.useInput) {
        const input = map && map.data && map.data.length ? map.data.map(item => item.input).flat(2) : []
        pins = pins.filter(pin => input.includes(`${comp.name}_${pin.pinName}`))
      } else {
        const output = map && map.data && map.data.length ? map.data.map(item => item.output).flat(2) : []
        pins = pins.filter(pin => !output.includes(`${comp.name}_${pin.pinName}`))
      }
      return pins.length ? 'VRM' : false
    }

    if (comp.name === this.root || pmic.find(item => item.partName === comp.partName) || extraPMIC.includes(comp.name)) {
      let pins = [...comp.pins.values()].filter(pin => pin.net === prevNet)
        .filter(pin => !VRM_CHARACTERISTIC.some(words => pin.pinName.includes(words)));
      if (!pins.length) {
        return false;
      }
      const map = _pinMap.find(item => item.partNumber === comp.partName);
      if (shouldInput && this.useInput) {
        const input = map && map.data && map.data.length ? map.data.map(item => item.input).flat(2) : []
        pins = pins.filter(pin => input.includes(pin.pinName))
      } else {
        const output = map && map.data && map.data.length ? map.data.map(item => item.output).flat(2) : []
        pins = pins.filter(pin => !output.includes(pin.pinName))
      }

      return pins.length ? "VRM" : false;
    }
    if (comp.pins && comp.pins.size >= 2) {
      // const { powerNets } = this.getPowerDomain({ designId: this.designId, chip: comp.name });
      // if (powerNets.includes(prevNet)) {
      return "Load";
      // }
    }
    return 'Not Found';
  }

  addLogs = (tempLog, success, type, error) => {
    let log = '';
    tempLog.forEach((item, index) => {
      if (index === 0) {
        log = `${log} ${item.type === 'comp' ? `{${item.name}}` : item.name}`
      } else {
        log = `${log} -> ${item.type === 'comp' ? `{${item.name}}` : item.name}`
      }
    })
    if (!success) {
      log = `${log}\n\t${error}\n`
    } else {
      log = `${log}\n`
    }
    this.logs.push({ success, type, log, error })
  }

  setConnectorPCBIndex = () => {
    const { propConnectors = [], designId, usedPCB, treeColor } = this;
    for (let connector of propConnectors) {
      for (let design of connector) {
        const { pcb, key } = design;
        let _pcbIndex = [...this.pcbIndex];

        if (!_pcbIndex.length && designId === pcb) {
          let pcbName = designConstructor.getDesignName(pcb);
          this.pcbIndex.push({ pcbId: pcb, pcbName, key, color: '#FFFFFF' });
          continue;
        }


        if (!usedPCB.includes(key)) {
          continue;
        }

        if (_pcbIndex.map(pcb => pcb.key).includes(key)) {
          return;
        }

        let pcbName = designConstructor.getDesignName(pcb);
        const pcbNameList = _pcbIndex.map(pcb => pcb.pcbName);
        if (pcbNameList.includes(pcbName)) {
          pcbName = getDefaultName({ nameList: pcbNameList.filter(item => item.includes(pcbName) && item !== pcbName), defaultKey: `${pcbName}`, delimiter: " (", endDelimiter: ")" });
        }
        const prevColor = treeColor.find(item => item.key === key);
        let color = prevColor ? prevColor.color : randomColor();
        const colorList = _pcbIndex.map(pcb => pcb.color);
        while (colorList.includes(color)) {
          color = randomColor();
        }
        this.pcbIndex.push({ pcbId: pcb, pcbName, key, color })
      }
    }
  }

  getPCBIndex = () => {
    return this.pcbIndex;
  }

  getLogs = () => {
    return this.logs.sort((a, b) => a.success && !b.success ? -1 : 1);
  }

  onlySaveMinPath = (paths) => {
    let minPath = {};
    paths.forEach(path => {
      const lastIndex = path.length - 1;
      const lastComp = path[lastIndex];
      if (lastComp.isGnd) {
        const { pcbKey, name } = lastComp;
        if (!minPath[`${pcbKey}-${name}`] || minPath[`${pcbKey}-${name}`] > path.length) {
          minPath[`${pcbKey}-${name}`] = path.length;
        }
      }
    })
    return paths.filter(path => {
      const lastIndex = path.length - 1;
      const lastComp = path[lastIndex];
      if (lastComp.isGnd) {
        const { pcbKey, name } = lastComp;
        if (minPath[`${pcbKey}-${name}`] < path.length) {
          return false;
        }
      }
      return true;
    })
  }

  splitOnlyGndLoad = (paths) => {
    let newPath = [];
    for (let path of paths) {
      const lastIndex = path.length - 1;
      const lastComp = path[lastIndex];
      if (!lastComp || !lastComp.isGnd) {
        lastComp && newPath.push(path);
        continue;
      }
      const powerPath = paths.find(_path => {
        const _last = _path[lastIndex];
        if (!_last || (_last && _last.isGnd)) {
          return false;
        }
        if (lastComp.name === _last.name && lastComp.pcbKey === _last.pcbKey) {
          return true;
        }
        return false
      })

      if (powerPath) {
        newPath.push(path)
      }
    }
    return newPath
  }

  insertSameLoadComps = (paths, rootComp) => {
    const { ComponentItem } = this;
    let _paths = [...paths], doseLoad = [], sameGnds = [];
    const Loads = _paths.map(path => path[path.length - 1]).filter(load => (load.type === 'Load' || load.type === 'VRM') && !load.isGnd);
    for (let load of Loads) {
      const { prevNet, name, pcbKey, type } = load;
      if (doseLoad.find(item => item.prevNet === prevNet && item.name === name && item.pcbKey === pcbKey && item.type === type)) {
        continue;
      }

      doseLoad.push({ prevNet, name, pcbKey, type });

      const sameLoadIndex = _paths.map((path, index) => {
        const _load = path[path.length - 1];
        if (_load.prevNet === prevNet && _load.name === name && _load.pcbKey === pcbKey && _load.type === type) {
          return index;
        }
        return -1;
      }).filter(item => item > -1);

      if (sameLoadIndex.length < 2) {
        continue;
      }

      const loadId = newNanoId();
      const isConnection = sameLoadIndex.some(index => paths[index].find(item => item.type === 'Connector'));

      if (isConnection) {
        const pathKeys = sameLoadIndex.map(index => {
          const path = paths[index];
          const pathKey = path[path.length - 1].pathKey
          const pcbKeys = pathKey.map(item => item.pcbKey)
          return [...new Set(pcbKeys)]
        });
        const sameGndIndex = _paths.map((path, index) => {
          const _load = path[path.length - 1];
          const pcbKeys = _load.pathKey.map(item => item.pcbKey);
          const _pcbKey = [...new Set(pcbKeys)]
          if (_load.isGnd && pathKeys.find(item => _.isEqual(item, _pcbKey)) && _load.name === name && _load.pcbKey === pcbKey && _load.type === type) {
            return index;
          }
          return -1;
        }).filter(item => item > -1);
        const connectionLengths = [...sameLoadIndex, ...sameGndIndex].map(index => {
          const path = JSON.parse(JSON.stringify(_paths[index]));
          let connectionLength = [], len = 0;
          for (let item of path) {
            len = len + 1;
            if (item.type === 'Connector' && item.connectionType === 'prev') {
              connectionLength.push(len);
              len = 0
            }
          }
          connectionLength.push(len)
          return connectionLength;
        })
        const maxConnection = Math.max(...connectionLengths.map(len => len.length));
        let maxConnectionLength = [];
        for (let i = 0; i < maxConnection; i++) {
          maxConnectionLength.push(Math.max(...connectionLengths.map(len => len[i] ? len[i] : -1)));
        }
        for (let index of [...sameLoadIndex, ...sameGndIndex]) {
          const path = _paths[index];
          let addCompsIndex = [];
          let connIndexs = path.map((item, index) => item.type === 'Connector' && item.connectionType === 'prev' ? index + 1 : -1).filter(item => item > -1);
          connIndexs = [...connIndexs, path.length];
          for (let i = 0; i < maxConnectionLength.length; i++) {
            const connectionLength = maxConnectionLength[i];
            const connIndex = connIndexs[i];
            const connLength = connIndexs[i] - (connIndexs[i - 1] ? connIndexs[i - 1] : 0);
            const length = connectionLength - connLength;
            if (length < 1) {
              continue;
            }
            addCompsIndex.push({ index: connIndex, length, skip: sameGndIndex.includes(index) && i === 0 ? true : false })
          }
          let addLength = 0;
          for (let adds of addCompsIndex) {
            const { index, length, skip } = adds;
            if (skip) {
              continue;
            }
            const connIndex = index + addLength
            let addComps = [];
            const _comp = path[connIndex - 1];
            if (!_comp) {
              continue;
            }
            const pcbKey = path[connIndex - 2] ? path[connIndex - 2].pcbKey : path[connIndex - 1].pcbKey;
            const pins = path[connIndex - 2] ? path[connIndex - 2].pins : [...(rootComp.pins || []), ...(rootComp.broComp ? rootComp.broComp.pins : [])];
            const prevNet = path[connIndex - 2] && path[connIndex - 2].prevNet && !path[connIndex - 2].prevNet.includes('CONNECT - ') ? path[connIndex - 2].prevNet : path[connIndex - 1].prevNet;
            const isGnd = path[connIndex - 2] ? path[connIndex - 2].isGnd : path[connIndex - 1].isGnd;
            for (let add = 0; add < length; add++) {
              const insertComp = new ComponentItem({
                ..._comp,
                showPrevNet: _comp.prevNet,
                prevNet: prevNet,
                isGnd,
                prevComp: add === 0 ? _comp.prevComp : `hidden${_comp.prevComp}_${add - 1}`,
                name: `hidden${_comp.prevComp}_${add}`,
                pins,
                hidden: true,
                connComp: _comp.name,
                type: RES,
                pcbKey,
                nextNet: "",
                hiddenComp: true,
                resistance: 0
              });
              addComps.push(insertComp)
            }
            path[connIndex - 1].prevComp = `hidden${_comp.prevComp}_${length - 1}`;
            path.splice(connIndex - 1, 0, ...addComps);
            addLength = addLength + length;
          }
          const pathKey = path.map(p => ({ name: p.name, pcbKey: p.pcbKey }));
          path.forEach((p, index) => {
            if (index < path.length - 1) {
              p.loadId = loadId
            }
            p.pathKey = pathKey;
          })
          _paths[index] = path
        }

        if (sameGndIndex.length > 1) {
          const _sameGndIndex = sameGndIndex.slice(1, -1);
          sameGnds.push(..._sameGndIndex)
        }
      } else {
        const maxLength = Math.max(...sameLoadIndex.map(index => _paths[index].length));
        for (let index of sameLoadIndex) {
          const path = _paths[index];
          const pathLength = path.length;
          const insertIndex = pathLength - 1;
          const length = maxLength - pathLength;
          if (length < 1) {
            path.forEach((p, index) => {
              if (index < path.length - 1) {
                p.loadId = loadId
              }
            })
            _paths[index] = path
            continue;
          }
          let addComps = [];
          const _comp = path[insertIndex];
          if (!_comp) {
            continue;
          }
          const pcbKey = path[pathLength - 2] ? path[pathLength - 2].pcbKey : path[pathLength - 1].pcbKey;
          const pins = path[pathLength - 2] ? path[pathLength - 2].pins : [...(rootComp.pins || []), ...(rootComp.broComp ? rootComp.broComp.pins : [])];
          for (let add = 0; add < length; add++) {
            const insertComp = new ComponentItem({
              ..._comp,
              prevNet: _comp.prevNet,
              prevComp: add === 0 ? _comp.prevComp : `hidden${_comp.prevComp}_${add - 1}`,
              name: `hidden${_comp.prevComp}_${add}`,
              pins,
              hidden: true,
              connComp: _comp.name,
              type: RES, pcbKey,
              nextNet: _comp.prevNet,
              hiddenComp: true,
              resistance: 0
            });
            addComps.push(insertComp)
          }
          path[pathLength - 1].prevComp = `hidden${_comp.prevComp}_${length - 1}`;
          path.splice(pathLength - 1, 0, ...addComps);
          const pathKey = path.map(p => ({ name: p.name, pcbKey: p.pcbKey }));
          path.forEach((p, index) => {
            if (index < path.length - 1) {
              p.loadId = loadId
            }
            p.pathKey = pathKey;
          })
          _paths[index] = path
        }
      }
    }
    return _paths.filter((path, index) => !sameGnds.includes(index))
  }

  insertHiddenComps = (paths, rootComp) => {
    const { ComponentItem } = this;
    let _paths = [...paths], continueTag = false;
    const pathChangeInfo = [];
    const maxPath = Math.max(..._paths.map(p => p.length));
    for (let i = 0; i < maxPath; i++) {
      let isGndIndexs = [];
      const colPath = _paths.map((p, index) => {
        const comp = p[i];
        if (comp && comp.isGnd) {
          isGndIndexs.push(index);
        }
        return comp
      }).flat(2);
      for (let index of isGndIndexs) {
        const comp = colPath[index];
        const filterPower = colPath.filter(col => col && col.name === comp.name && col.pcbKey === comp.pcbKey && !col.isGnd);
        if (!filterPower.length) {
          let j = i;
          while (j + 1 < maxPath) {
            const k = j;
            const column = _paths.map(p => p[k + 1]) || [];
            const hasGnd = column.find(col => col && col.name === comp.name && col.pcbKey === comp.pcbKey && col.isGnd)
            const filterPowerIndex = column.filter(col => col && col.name === comp.name && col.pcbKey === comp.pcbKey && !col.isGnd)
            if ((!hasGnd || (hasGnd.connectionType === comp.connectionType && comp.connectionType === 'prev')) && filterPowerIndex.length) {
              let path = _.cloneDeep(_paths[index]);
              const _comp = path[i];
              const pcbKey = path[i - 1] ? path[i - 1].pcbKey : path[i].pcbKey;
              const pins = path[i - 1] ? path[i - 1].pins : [...(rootComp.pins || []), ...(rootComp.broComp ? rootComp.broComp.pins : [])];
              let insertComps = [];
              for (let add = 0; add < j - i + 1; add++) {
                const insertComp = new ComponentItem({
                  ..._comp,
                  prevNet: _comp.prevNet,
                  prevComp: add === 0 ? _comp.prevComp : `hidden${_comp.prevComp}${_comp.name}_${add - 1}`,
                  name: `hidden${_comp.prevComp}${_comp.name}_${add}`,
                  pins, hidden: true,
                  connComp: _comp.name,
                  type: RES, pcbKey,
                  nextNet: _comp.prevNet,
                  hiddenComp: true
                })
                if (_comp.middleGnd) {
                  insertComp.middleGnd = true;
                }
                insertComps.push(insertComp)
              }
              pathChangeInfo.push({ index: k + 1, prevComp: `hidden${_comp.prevComp}${_comp.name}_${j - i}`, name: _comp.name })
              path[i].prevComp = `hidden${_comp.prevComp}${_comp.name}_${j - i}`;
              path[i].prevNet = `${_comp.prevNet}`
              path.splice(i, 0, ...insertComps);
              const pathKey = path.map(p => ({ name: p.name, pcbKey: p.pcbKey }));
              path.forEach(p => p.pathKey = pathKey);
              _paths[index] = path;
              continueTag = true
              break;
            }
            j = j + 1;
          }
          if (continueTag) {
            continueTag = false;
            continue;
          }

          j = i;
          while (j - 1 >= 0) {
            const k = j;
            const column = _paths.map(p => p[k - 1]) || [];
            const hasGnd = column.find(col => col && col.name === comp.name && col.pcbKey === comp.pcbKey && col.isGnd)
            const filterPowerIndex = column.map((col, index) => {
              return col && !col.isGnd && col.name === comp.name && col.pcbKey === comp.pcbKey ? index : undefined;
            }).filter(col => col !== undefined);
            if ((!hasGnd || (hasGnd.connectionType === comp.connectionType && comp.connectionType === 'prev')) && filterPowerIndex.length) {
              for (let powerIndex of filterPowerIndex) {
                let path = _.cloneDeep(_paths[powerIndex]);
                const _comp = path[k - 1];
                const pcbKey = path[k - 2] ? path[k - 2].pcbKey : path[k - 1].pcbKey;
                const pins = path[k - 2] ? path[k - 2].pins : [...(rootComp.pins || []), ...(rootComp.broComp ? rootComp.broComp.pins : [])];
                let insertComps = [];
                for (let add = 0; add < i - j + 1; add++) {
                  const insertComp = new ComponentItem({
                    ..._comp,
                    prevNet: _comp.prevNet,
                    prevComp: add === 0 ? _comp.prevComp : `hidden${_comp.prevComp}${_comp.name}_${add - 1}`,
                    name: `hidden${_comp.prevComp}${_comp.name}_${add}`,
                    pins, hidden: true,
                    connComp: _comp.name,
                    type: RES, pcbKey,
                    nextNet: _comp.prevNet,
                    hiddenComp: true
                  })
                  if (_comp.middleGnd) {
                    insertComp.middleGnd = true;
                  }
                  insertComps.push(insertComp)
                }
                pathChangeInfo.push({ index: i, prevComp: `hidden${_comp.prevComp}${_comp.name}_${i - j}`, name: _comp.name })
                path[k - 1].prevComp = `hidden${_comp.prevComp}${_comp.name}_${i - j}`;
                path[k - 1].prevNet = `${_comp.prevNet}`;
                path.splice(k - 1, 0, ...insertComps);
                const pathKey = path.map(p => ({ name: p.name, pcbKey: p.pcbKey }));
                path.forEach(p => p.pathKey = pathKey);
                _paths[powerIndex] = path
              }
            }
            j = j - 1
          }
        }
      }
    }

    pathChangeInfo.forEach(item => {
      const { index, prevComp, name } = item;
      _paths.forEach(path => {
        if (path[index] && path[index].name === name && path[index].truePrevComp && path[index].isGnd) {
          path[index].prevComp = prevComp
        }
      })
    })
    return _paths
  }

  filterHasPowerNetGnd = (paths, rootComp) => {
    const powerPaths = paths.filter(path => path.every(item => !item.isGnd));
    let powerNets = powerPaths.map(path => path.map(item => item.prevNet).filter(item => !item.includes('CONNECT - '))).flat(2);
    powerNets = [...new Set(powerNets)];
    return paths.filter(path => {
      const isPower = path.every(item => !item.isGnd);
      if (isPower) {
        return true;
      }
      let startPower = true;
      const gndNets = path.map(item => {
        if (startPower) {
          if (powerNets.includes(item.prevNet)) {
            return null
          } else {
            startPower = false;
          }
        }
        return item.prevNet.includes('CONNECT - ') ? null : item.prevNet
      }).filter(item => !!item)
      return gndNets.some(item => powerNets.includes(item)) ? false : true;
    })
  }

  traceBackGround = (prevNet, GroundNet = "", name, pcbId, maxLevel, { rootNet, pcbKey, doNotStuff }) => {
    const groundNets = GroundNet.split(', ');
    let level = 1, prevCompList = [];
    const { designId, moreSetting, getComponents, allComponents, ComponentItem, compTable, pinMap } = this;
    let _compTable = compTable, _pinMap = pinMap;
    if (pcbId && moreSetting[pcbId]) {
      _compTable = moreSetting[pcbId].table || compTable;
      _pinMap = moreSetting[pcbId].pinMap || pinMap;
    }

    const _allComponents = pcbId ? getComponents({ pcbId }) : allComponents;

    const icComponent = _allComponents.get(name);
    if (icComponent && [...icComponent.pins.values()].find(pin => groundNets.includes(pin.net)) && groundNets.includes(prevNet)) {
      return { gnd: false, prevComps: [] }
    }

    const traceBack = (level, net, prevComps, prevComp) => {
      if (level > maxLevel) {
        return;
      }
      let components = auroraDBJson.getComponentsByNets(pcbId || designId, [net])
      for (let comp of components) {
        const _prevComps = JSON.parse(JSON.stringify(prevComps))
        if (comp.name === prevComp || doNotStuff.includes(comp.name)) {
          continue;
        }
        let transistorVRM = true
        if (comp.type === TRANSISTOR && !_pinMap.some(map => map.type === "buckConverter" && map.partNumber.includes(comp.name))) {
          transistorVRM = false;
        }
        const component = _allComponents.get(comp.name)
        if (component && ([RES, FERRITE, JUMPER].includes(component.type) || !transistorVRM)) {
          const pins = component.pins ? [...component.pins.values()] : [];
          if (pins.length < 2) {
            continue;
          }
          const valueComp = _compTable.find(c => c.part === component.partName);
          const _value = valueComp ? getValueByComp(valueComp) : component.type === IND ? 0 : checkRLCValueInPowerTree(component.value);
          if (isNaN(Number(_value)) || Number(_value) >= 1e5) {
            continue;
          }
          const compItem = new ComponentItem({
            type: component.type,
            name: component.name,
            part: component.partName,
            pins,
            prevComp,
            rootNet,
            resistance: _value,
            isGnd: true,
            pcbKey,
            pcbId: pcbId || designId,
            nextNet: net,
            extraGndComp: true
          })
          if (pins.some(pin => groundNets.includes(pin.net))) {
            const pinNets = pins.map(pin => pin.net)
            const _compItem = JSON.parse(JSON.stringify(compItem))
            _compItem.prevNet = groundNets.find(net => pinNets.includes(net))
            _compItem.pins = pins.filter(item => [net, ...groundNets].includes(item.net))
            if (_prevComps.length) {
              _prevComps[0].prevComp = component.name
            }
            prevCompList.push([_compItem, ..._prevComps])
          } else {
            const nets = [...new Set(pins.map(pin => pin.net).filter(_net => _net !== net))];
            for (let _net of nets) {
              const _compItem = JSON.parse(JSON.stringify(compItem))
              _compItem.prevNet = _net;
              _compItem.pins = pins.filter(item => [_net, net].includes(item.net))
              if (_prevComps.length) {
                _prevComps[0].prevComp = component.name
              }
              traceBack(level + 1, _net, [_compItem, ..._prevComps], component.name);
            }
          }
        }
      }
    }

    traceBack(level, prevNet, [], name)
    return { gnd: prevCompList.length ? true : false, prevComps: prevCompList.length ? prevCompList.sort((a, b) => a.length - b.length ? 1 : 1)[0] : [] }
  }

  setGroundForSinglePCB = (paths) => {
    let _paths = [...paths];
    const { GroundNet, root, designId, COMP_PREFIX_LIB, allComponents, ComponentItem, compTable, ICItem, PMIC, doNotStuff, pinMap } = this;
    this.setTreeMonitors([{ text: `\tFind Ground Paths: ${root}`, type: 'title', key: this.treeName }]);
    const dirLoads = _paths.map(path => {
      const hasMiddle = path.map(item => item.name === root ? false : item.middle);
      if (hasMiddle.includes(true)) {
        const comp = path.find(item => item.name !== root && item.middle);
        if (comp) {
          return { name: comp.name, gnd: comp.gnd }
        }
      }
      return { name: path[path.length - 1].name, gnd: path[path.length - 1].gnd }
    }).filter(load => !!load.name && load.name !== root);
    let gndPaths = [], gndMonitor = [];
    const trace = (level, net, prevComps, prevComp, rootNet) => {
      if (level > 4) {
        return;
      }

      let components = []
      const isPreLayout = designConstructor.isPreLayout(designId);
      if (isPreLayout) {
        components = [...allComponents.values()].filter(item => [...item.pins.values()].some(pin => pin.net === net));
      } else {
        components = auroraDBJson.getComponentsByNets(designId, [net])
      }

      for (let comp of components) {
        if (comp.name === prevComp) {
          continue;
        }
        if (doNotStuff.includes(comp.name)) {
          gndMonitor.push({ text: `\t\tX Skip ${comp.name}. Component is DNS.`, type: 'error', key: this.treeName })
          continue;
        }
        let transistorVRM = true
        if (comp.type === TRANSISTOR && !pinMap.some(map => map.type === "buckConverter" && map.partNumber.includes(comp.name))) {
          transistorVRM = false;
        }
        const component = allComponents.get(comp.name);
        const realType = this.getLoadComp(comp, COMP_PREFIX_LIB) || comp.type;
        if (component && ([RES, FERRITE, JUMPER].includes(realType) || !transistorVRM)) {
          const pins = [...component.pins.values()] || [];
          const valueComp = compTable.find(c => c.part === component.partName);
          const _value = valueComp ? getValueByComp(valueComp) : component.type === IND ? 0 : checkRLCValueInPowerTree(component.value);
          if (isNaN(Number(_value)) || Number(_value) >= 1e5) {
            gndMonitor.push({ text: `\t\tX Skip ${comp.name}. Resistance value ${_value} is greater than tracing threshold value (1e5mΩ).`, type: 'error', key: this.treeName })
            continue;
          }

          const nextNets = [...new Set(pins.map(pin => pin.net))].filter(_net => _net !== net);
          for (let nextNet of nextNets) {
            const compItem = new ComponentItem({
              type: component.type,
              name: component.name,
              part: component.partName,
              pins: pins.filter(pin => pin.net === nextNet || pin.net === net),
              prevComp,
              rootNet,
              resistance: _value,
              isGnd: true,
              pcbId: designId,
              prevNet: net
            })

            trace(level + 1, nextNet, [...prevComps, compItem], compItem.name, rootNet)
          }
        } else if (component && realType === IGNORE) {
          let check = this.ICcheck(component, PMIC, net, designId, false);
          check = ["VRM", "Load"].includes(check) ? check : this.getLoadComp(component, COMP_PREFIX_LIB, check);
          check = check === IGNORE ? 'Load' : check;
          if (check) {
            const pathKey = [...prevComps, { name: component.name, pcbKey: "" }].map(prev => ({ name: prev.name, pcbKey: prev.pcbKey }))
            const ICComp = new ICItem({
              name: component.name,
              part: component.partName,
              type: check,
              pins: [...component.pins.values()].filter(pin => pin.net === net),
              rootNet,
              prevComp,
              prevNet: net,
              isGnd: true,
              pathKey,
              pcbId: designId
            })
            if (!gndPaths.some(path => _.isEqual(path[path.length - 1].pathKey, pathKey))) {
              gndPaths.push([...prevComps, ICComp])
            }
          }
        }
      }
    }

    let groundNets = GroundNet.split(', ');
    for (let groundNet of groundNets) {
      trace(1, groundNet, [], root, groundNet)
    }
    const _gndPaths = gndPaths.filter(path => dirLoads.some(load => load.name === path[path.length - 1].name && (!load.gnd || load.gnd === path[path.length - 1].prevNet)))
    if(_gndPaths.length) {
      gndPaths = _gndPaths
    }
    console.log("Debug for Transistor:")
    console.log(JSON.parse(JSON.stringify(gndPaths)))
    console.log(dirLoads)
    let minLength = {};
    gndPaths.forEach(path => {
      const load = path[path.length - 1].name;
      minLength[load] = minLength[load] && minLength[load] < path.length ? minLength[load] : path.length
    })
    gndPaths = gndPaths.filter(path => path.length === minLength[path[path.length - 1].name]);
    let returnPaths = [..._paths, ...gndPaths]

    const indirPaths = _paths.filter(path => {
      const hasMiddle = path.map(item => item.name !== root && item.middle);
      return hasMiddle.includes(true)
    })
    let indirGndPaths = [];
    for (let indirPath of indirPaths) {
      let _indirPath = [...indirPath];
      const loadName = _indirPath[_indirPath.length - 1].name
      while (!_indirPath[_indirPath.length - 1].middle) {
        _indirPath.pop();
      }
      gndPaths = [];
      const { gnd, nextNet, name } = _indirPath[_indirPath.length - 1];
      const gndNets = gnd ? gnd.split(', ') : GroundNet.split(', ')
      for (let _groundNet of gndNets) {
        trace(1, _groundNet, [], name, nextNet)
      }
      gndPaths = gndPaths.filter(path => path[path.length - 1].name === loadName)
      let minLength = {}
      gndPaths.forEach(path => {
        const load = path[path.length - 1].name;
        minLength[load] = minLength[load] && minLength[load] < path.length ? minLength[load] : path.length
      })
      gndPaths = gndPaths.filter(path => path.length === minLength[path[path.length - 1].name]);
      gndPaths.forEach(path => {
        path.forEach(p => {
          p.middleGnd = true;
        })
      })
      gndPaths.forEach(path => {
        indirGndPaths.push([..._indirPath, ...path])
      })
    }
    if (![...returnPaths, ...indirGndPaths]) {
      this.setTreeMonitors(gndMonitor)
    } else {
      this.setTreeMonitors([...returnPaths, ...indirGndPaths].map(path => ({ text: `\t\t√ ${path[path.length - 1].name}`, type: 'success', key: this.treeName })))
    }
    return [...returnPaths, ...indirGndPaths]
  }

  getLoadComp = (comp, COMP_PREFIX_LIB = {}, check) => {

    if (comp.type === LOAD || comp.type === CONNECTOR) {
      return IGNORE;
    }

    const { Load = ['U'] } = COMP_PREFIX_LIB;
    if (Load && Load.length) {
      if (Load.includes(comp.name)) {
        return IGNORE;
      } else if (check === 'Not Found') {
        const txt = Load.join('|');
        const regObj = new RegExp(`^(${txt})`, 'ig');
        const words = comp.name.match(regObj);
        if (words && words.length) {
          if (comp.type === JUMPER && comp.pinList.length <= 2) {
            return null
          }
          if (comp.type === SWITCH) {
            return null;
          }
          if (comp.type === TRANSISTOR) {
            return null;
          }
          return IGNORE;
        }
      }
    }
    return null
  }
}

function randomColor() {
  let color = ['#', 'E', 'F', 'E', 'F', 'E', 'F'];
  let limit = ['F', 'E', 'D']
  for (let i = 2; i < 7; i = i + 2) {
    color[i] = parseInt(Math.random() * 16).toString(16);
  }

  for (let i = 1; i < 7; i = i + 2) {
    color[i] = limit[parseInt(Math.random() * 3).toString(16)];
  }
  return color.join('');
}

export default GetPowerTree;