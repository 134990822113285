import { COMINSERTION, COMRETURN, CROSSCOUPLING, DIFFTOCOM, INSERTIONLOSS, RETURNLOSS } from '../helper/reportHelper';
import { PCIE, HDMI, ETHERNET, DP, MIPI, USB, GENERIC, CPHY } from '../PCBHelper/constants';

export const ANDES_V2_PROJECT_VERSION = '0.0.1';
export const
  ANDES_INTERFACE_TYPES = [
    {
      key: PCIE,
      title: PCIE
    },
    {
      key: HDMI,
      title: HDMI
    },
    {
      key: GENERIC,
      title: GENERIC
    },
    {
      key: CPHY,
      title: 'C-PHY'
    },
    {
      key: ETHERNET,
      title: ETHERNET,
      // disabled: true
    },
    {
      key: DP,
      title: DP,
      disabled: true
    },
    {
      key: MIPI,
      title: MIPI,
      disabled: true
    }, {
      key: USB,
      title: USB,
      disabled: true
    }]

//PCIE width
const X1 = "x1",
  X2 = "x2",
  X4 = "x4",
  X6 = "x6",
  X8 = "x8",
  X10 = "x10",
  X12 = "x12",
  X14 = "x14",
  X16 = "x16", X32 = "x32";
export const PCIE_WIDTH_LIST = [X1, X2, X4, X6, X8, X10, X12, X14, X16, X32];

/* channel */
const DATA_RATE = "data_rate", GENERATION = "generation";
const GEN1 = "Gen 1", GEN2 = "Gen 2", GEN3 = "Gen 3";
const GENERATION_LIST = [{ key: GEN1, title: GEN1 }, { key: GEN2, title: GEN2 }, { key: GEN3, title: GEN3 }];
const channelInputList = [{
  title: "Name",
  key: "name"
},
{
  title: "Type",
  key: "serdesType",
  list: ANDES_INTERFACE_TYPES
}];

const channelAdvancedInputList = [
  {
    title: "Net name prefix",
    key: "netNamePrefix",
    multiple: true
  },
  {
    title: "TX pair flag",
    key: "txFlag"
  },
  {
    title: "RX pair flag",
    key: "rxFlag"
  },
  {
    title: "Positive net flag",
    key: "positiveFlags",
    multiple: true
  },
  {
    title: "Negative net flag",
    key: "negativeFlags",
    multiple: true
  },
  {
    title: "A Line flag",
    key: "ALineFlags",
    multiple: true
  },
  {
    title: "B Line flag",
    key: "BLineFlags",
    multiple: true
  },
  {
    title: "C Line flag",
    key: "CLineFlags",
    multiple: true
  },
]

const defaultInsertion = {
  dataRate2p5: [{ x: [1, 1.25], y: [-4.5, -4.7] }, { x: [1, 1.25], y: [-5.0, -5.2] }],
  dataRate5: [{ x: [1, 2.5], y: [-4.5, -10.0] }, { x: [1, 2.5], y: [-5.0, -11.0] }],
  dataRate8: [{ x: [1, 4], y: [-5, -20] }, { x: [1, 4], y: [-8, -22] }],
  dataRate16: [{ x: [1, 8], y: [-4.2, -22.5] }, { x: [1, 8], y: [-5.2, -23.5] }],
  dataRate32: [{ x: [1, 16], y: [-3.2, -26.5] }, { x: [1, 16], y: [-4.2, -27.5] }]
}

const defaultReturn = {
  dataRate2p5: [{ x: [0.05, 1.25], y: [-10, -10] }],
  dataRate5: [{ x: [0.05, 1.25], y: [-10, -10] }, { x: [1.25, 2.5], y: [-8, -8] }],
  dataRate8: [{ x: [0.05, 1.25], y: [-10, -10] }, { x: [1.25, 2.5], y: [-8, -8] }, { x: [2.5, 4.0], y: [-6, -6] }],
  dataRate16: [{ x: [0.05, 1.25], y: [-10, -10] }, { x: [1.25, 2.5], y: [-8, -8] }, { x: [2.5, 4.0], y: [-6, -6] }, { x: [4.0, 8.0], y: [-6, -6] }],
  dataRate32: [{ x: [0.05, 1.25], y: [-10, -10] }, { x: [1.25, 2.5], y: [-8, -8] }, { x: [2.5, 4.0], y: [-6, -6] }, { x: [4.0, 8.0], y: [-6, -6] }, { x: [8.0, 16.0], y: [-6, -6] }],
}

const defaultCommModeReturn = {
  dataRate2p5: [{ x: [0.05, 1.25], y: [-6, -6] }],
  dataRate5: [{ x: [0.05, 1.25], y: [-6, -6] }, { x: [1.25, 2.5], y: [-6, -6] }],
  dataRate8: [{ x: [0.05, 1.25], y: [-6, -6] }, { x: [1.25, 2.5], y: [-6, -6] }, { x: [2.5, 4.0], y: [-3, -3] }],
  dataRate16: [{ x: [0.05, 1.25], y: [-6, -6] }, { x: [1.25, 2.5], y: [-6, -6] }, { x: [2.5, 4.0], y: [-3, -3] }, { x: [4.0, 8.0], y: [-3, -3] }],
  dataRate32: [{ x: [0.05, 1.25], y: [-6, -6] }, { x: [1.25, 2.5], y: [-6, -6] }, { x: [2.5, 4.0], y: [-3, -3] }, { x: [4.0, 8.0], y: [-3, -3] }, { x: [8.0, 16.0], y: [-3, -3] }],
}

const defaultCPHYInsertion = {
  Short: [{ x: [1.25, 5], y: [-1.1, -4.3] }, { x: [1.25, 5], y: [-1.9, -5.4] }],
  Standard: [{ x: [1.25, 5], y: [-3.25, -11.1] }, { x: [1.25, 5], y: [-4.25, -12.5] }],
  Long: [{ x: [1.25, 5], y: [-5.8, -19.2] }, { x: [1.25, 5], y: [-6.8, -20.8] }]
}

const DriverPoints = 'driverPoints', ReceiverPoints = 'receiverPoints', DriverOrReceiver = 'driverOrReceiver';

function getDefaultPointData({ key, fh, fLPMax, fMax, pointType, symbolRate, fINTMin }) {
  switch (key) {
    case RETURNLOSS:
      if (pointType === DriverPoints) {
        if (Number(symbolRate) <= 2.5) {
          return [{ x: [fLPMax, fh], y: [-15, -3] }, { x: [fh, fMax], y: [-3, -1.5] }]
        } else if (Number(symbolRate) > 4.5) {
          return [{ x: [fLPMax, fh], y: [-15, -1] }, { x: [fh, fMax], y: [-1, -0.5] }]
        } else {
          return [{ x: [fLPMax, fh], y: [-15, -2] }, { x: [fh, fMax], y: [-2, -1] }]
        }
      } else if (pointType === ReceiverPoints) {
        if (Number(symbolRate) <= 2.5) {
          return [{ x: [0, fLPMax], y: [-15, -15] }, { x: [fLPMax, fh], y: [-15, -5] }, { x: [fh, fMax], y: [-5, -3] }]
        } else if (Number(symbolRate) > 4.5) {
          return [{ x: [0, fLPMax], y: [-15, -15] }, { x: [fLPMax, fh], y: [-15, -2.8] }, { x: [fh, fMax], y: [-2.8, -1.5] }]
        } else {
          return [{ x: [0, fLPMax], y: [-15, -15] }, { x: [fLPMax, fh], y: [-15, -3] }, { x: [fh, fMax], y: [-3, -1.5] }]
        }
      }
      return [{ x: [0, fMax], y: [-12, -12] }];
    case DIFFTOCOM:
      if (pointType === DriverPoints) {
        return [];
      } else if (pointType === ReceiverPoints) {
        return [{ x: [0, fMax], y: [-26, -26] }]
      }
      return [{ x: [0, fMax], y: [-29, -29] }];
    case COMRETURN:
      if (pointType === DriverPoints) {
        return [];
      } else if (pointType === ReceiverPoints) {
        const fINTMin0p25 = parseFloat(0.25 * fINTMin).toFixed(3)
        if (Number(symbolRate) <= 2.5) {
          return [{ x: [0, fINTMin0p25], y: [0, 0] }, { x: [fINTMin0p25, fINTMin], y: [0, -6] }, { x: [fINTMin, fMax], y: [-6, -3] }]
        } else if (Number(symbolRate) > 4.5) {
          return [{ x: [0, fINTMin0p25], y: [0, 0] }, { x: [fINTMin0p25, fINTMin], y: [0, -6] }, { x: [fINTMin, fMax], y: [-6, -1.5] }]
        } else {
          return [{ x: [0, fINTMin0p25], y: [0, 0] }, { x: [fINTMin0p25, fINTMin], y: [0, -6] }, { x: [fINTMin, fMax], y: [-6, -1] }]
        }
      }
      return [{ x: [0, 2 * fh], y: [-12, -12] }];
    case CROSSCOUPLING:
      if (pointType === DriverPoints || pointType === ReceiverPoints) {
        return [];
      }
      return [{ x: [0, 10 * fLPMax], y: [-20, -20] }, { x: [10 * fLPMax, fMax], y: [-18, -18] }];
    default:
      return [];
  }
}

// Get the default ponits data
function getDefaultList({ PCIeType, type, dataRate = 16, symbolRate = 2.5, fLPMax = 0.01, fMax = 1.875, fh = 1.25, fINTMin = 0.45, cphyInsertionLossSpec = 'Short', pointType = "" }) {
  let defaultList = [];
  if (PCIeType === PCIE) {
    const rate = 'dataRate' + String(dataRate).replace('.', 'p');
    switch (type) {
      case INSERTIONLOSS:
        defaultList = defaultInsertion[rate] || [];
        break;
      case RETURNLOSS:
        defaultList = defaultReturn[rate] || [];
        break;
      case COMRETURN:
        defaultList = defaultCommModeReturn[rate] || [];
        break;
      default:
        break;
    }
  }

  if (PCIeType === CPHY) {
    switch (type) {
      case INSERTIONLOSS:
      case COMINSERTION:
        if (![DriverPoints, ReceiverPoints].includes(pointType)) {
          defaultList = defaultCPHYInsertion[cphyInsertionLossSpec] || [];
        }
        break;
      case RETURNLOSS:
      case COMRETURN:
      case DIFFTOCOM:
      case CROSSCOUPLING:
        defaultList = getDefaultPointData({ key: type, symbolRate, fMax, fLPMax, fh, fINTMin, pointType })
        break;
      default:
        break;
    }
  }

  return defaultList;
}

export {
  channelInputList,
  GENERATION_LIST,
  DATA_RATE,
  GENERATION,
  GEN3,
  channelAdvancedInputList,
  defaultInsertion,
  defaultReturn,
  defaultCommModeReturn,
  defaultCPHYInsertion,
  getDefaultList,
  DriverOrReceiver,
  DriverPoints,
  ReceiverPoints
}