import Viewer, { CurveItem } from '../../../Result/Public/sparameter/PlotView';
import { select } from 'd3-selection';
import { unitChange } from '../../../helper/mathHelper';
class CurrentParameter {

  constructor(curves) {
    this.parameters = new Map();
    this.plotViewer = null;
    this.data = null;
    this.id = curves.id
  }

  importData(data) {
    const newData = [];
    for (let item of data) {
      const { time, current } = item;
      let newTime = [...time], newCurrent = [...current];
      if (newTime.length && newTime[0] === 0) {
        newTime[0] = newTime[1] ? newTime[1] / 100 : 1e-6;
      }
      newTime = newTime.map(i => (unitChange({ num: i, oldUnit: 'ns', newUnit: 's' }).number))
      newData.push({ ...item, time: newTime, current: newCurrent })
    }
    this.data = newData;
  }

  createPlot(element) {
    const options = {
      background: '#fff',
    }
    this.plotViewer = new Viewer({ element, options, id: this.id });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit('s');
    xAxis.setLabel('Time');
    xAxis.setting.scale = "linear";
    // xAxis.setScale('log');
    let yAxis = this.plotViewer.getAxis('y');
    yAxis.setUnit('A');
    yAxis.setLabel('Current');
    // Default y scale
    yAxis.setting.scale = "linear";
    return this.plotViewer;
  }

  // curves -[{ parameter, fileIndex, row, col, curveType }]
  showCurves() {
    return Promise.all(this.data.map(cur => {
      let curve = new CurveItem();
      curve.x = cur.time;
      curve.y = cur.current;
      curve.visible = true;
      curve.id = cur.id;
      curve.file = null;
      curve.curveType = 'current';
      return curve;
    }))
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }
}

export default CurrentParameter;