import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExtractionPorts from '@/components/ExtractionPorts/portsRender';
import { getSignalNetInfo, PortsGenerationSetup, updateSetupComponentsByPortType } from '@/services/ExtractionPortsHelper';
import { updatePortSetupsToServer } from '../../store/sierra/action';
import DesignInfo from '@/services/Sierra/pcbInfo';
import { SIERRA } from '@/constants/pageType';
import { sierraExtractionPortsType } from '../../constants';
class SierraExtractionPorts extends Component {

  constructor(props) {
    super(props);
    const { Interfaces, current } = this.props;
    const currentInterface = Interfaces && Interfaces.length ? Interfaces.find(item => item.pcb === current) : {};
    const { port_setups, ports_generate_setup_list, referenceNets, components } = currentInterface.content;
    let setupList = ports_generate_setup_list;
    if (!ports_generate_setup_list || !ports_generate_setup_list.length) {
      setupList = components.filter(item => sierraExtractionPortsType.includes(item.type)).map(item => item.name).map(item => { return { component: item, setup: new PortsGenerationSetup({}) } });
    }
    const _port_setup_list = port_setups && port_setups.length ? JSON.parse(JSON.stringify(port_setups)) : [];
    this.state = {
      port_setups: _port_setup_list,
      referenceNets: referenceNets,
      generateRefNets: referenceNets ? [...referenceNets] : [],
      ports_generate_setup_list: setupList,
      currentInterface: currentInterface,
      setupComponents: JSON.parse(JSON.stringify(components))
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this, this.props.current);
    }
  }

  componentDidUpdate = (prevProps) => {
    //switch channel , close panel
    if (this.props.verificationId !== prevProps.verificationId) {
      this.props.closeModal();
    }
    if (this.props.current !== prevProps.current) {
      this.initPortSetups()
    }
  }

  initPortSetups = () => {
    const { Interfaces, current } = this.props;
    const currentInterface = Interfaces && Interfaces.length ? Interfaces.find(item => item.pcb === current) : {};
    const { port_setups, ports_generate_setup_list, referenceNets, components } = currentInterface.content;
    let setupList = ports_generate_setup_list;
    if (!ports_generate_setup_list || !ports_generate_setup_list.length) {
      setupList = components.filter(item => sierraExtractionPortsType.includes(item.type)).map(item => item.name).map(item => { return { component: item, setup: new PortsGenerationSetup({}) } });
    }
    const _port_setup_list = port_setups && port_setups.length ? JSON.parse(JSON.stringify(port_setups)) : [];
    this.setState({
      port_setups: _port_setup_list,
      referenceNets: referenceNets,
      generateRefNets: referenceNets ? [...referenceNets] : [],
      ports_generate_setup_list: setupList,
      currentInterface: currentInterface,
      setupComponents: JSON.parse(JSON.stringify(components))
    }, () => {
      if (this.portsRenderRef) {
        this.portsRenderRef.updatePortRenderValue()
      }
    })
  }

  savePortsSetup = (saveType) => {
    const confirm = this.portsRenderRef.savePorts(saveType);
    return confirm;
  }

  _updatePortSetups = (port_setups, generationSetup, isClose) => {
    const { ports_generate_setup_list } = this.state;
    this.setState({
      port_setups,
      ports_generate_setup_list: generationSetup ? generationSetup : ports_generate_setup_list
    }, () => {
      this.saveSetup();
      isClose && this.props.closeModal();
    })
  }

  saveSetup = () => {
    const { Interfaces, current, designId } = this.props;
    const currentInterface = Interfaces && Interfaces.length ? Interfaces.find(item => item.pcb === current) : { content: {} };
    const { port_setups, generateRefNets, ports_generate_setup_list, setupComponents } = this.state;
    const _setupComponents = updateSetupComponentsByPortType({
      components: setupComponents,
      ports_generate_setup_list,
      designId,
      extractionType: currentInterface.content.channel ? currentInterface.content.channel.type : ""
    });
    this.props._updatePortSetupsToServer({ port_setups, referenceNets: generateRefNets, ports_generate_setup_list, components: _setupComponents }, current);
  }

  updateReferenceNets = (keys) => {
    this.setState({
      referenceNets: keys
    })
  }

  updateGenerateRefNets = (nets) => {
    this.setState({
      generateRefNets: nets
    })
  }

  closePanel = () => {
    this.saveSetup();
    this.props.closeModal();
  }

  updateSetupComponents = (setupComponents) => {
    this.setState({
      setupComponents
    })
  }

  onRef = (ref) => {
    this.portsRenderRef = ref;
  }

  render() {
    const { port_setups, referenceNets, ports_generate_setup_list, generateRefNets, currentInterface, setupComponents } = this.state;
    const designId = currentInterface.pcbId;
    const { signals = [], powerNets = [], channel } = currentInterface && currentInterface.content ? currentInterface.content : currentInterface;
    return (<ExtractionPorts
      designId={designId}
      product={SIERRA}
      ports_generate_setup_list={ports_generate_setup_list}
      port_setups={port_setups}
      DesignInfo={DesignInfo}
      components={setupComponents}
      signals={signals}
      referenceNets={referenceNets}
      generateRefNets={generateRefNets}
      extractionType={channel ? channel.type : ""}
      allReferenceNetList={powerNets.map(item => item.name)}
      getSignalNetInfo={getSignalNetInfo}
      closePanel={this.closePanel}
      updatePortSetups={this._updatePortSetups}
      updateReferenceNets={this.updateReferenceNets}
      updateGenerateRefNets={this.updateGenerateRefNets}
      updateSetupComponents={this.updateSetupComponents}
      maxHeight={this.props.maxHeight}
      onRef={this.onRef}
      updateCurrent={this.props.updateCurrent}
    />)
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra: { sierraInfo } } } = state;
  if (!sierraInfo) {
    return {
      verificationId: null,
      Interfaces: []
    }
  }
  const { verificationId, Interfaces = [] } = sierraInfo;
  return {
    verificationId,
    Interfaces
  }
};


const mapDispatch = (dispatch) => ({
  _updatePortSetupsToServer(data, current) {
    dispatch(updatePortSetupsToServer(data, current))
  }
})

export default connect(mapState, mapDispatch)(SierraExtractionPorts);