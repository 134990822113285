import { all, takeEvery, put, call, fork, race, take, delay } from 'redux-saga/effects';
import { upload as _upload, } from '@/services/api/Design/design';
import { RUNNING, SUCCEED, FAILED } from '@/constants/workflowStatus';
import { SUCCESS, CHECK_FAIL } from '@/constants/returnCode';
import { getWorkFlow } from '@/services/api/v2/workflowCtrl';
import getDesignFile from '@/services/api/designFile';
import { TRANSLATION_SUCCESS, TRANSLATION_FAILED, GET_WORK_FLOWS, TRANSLATION_RUNNING, GET_TRANSLATION_MONITOR } from './actionType';
import {
  translationProgress, translationSuccess, translationFailed,
  getWorkFlow as workFlowAction,
  translationMonitor, addMonitorMsg,
} from './action';
import dayjs from 'dayjs';
import { openProject } from '../action';
import { message } from 'antd';
import { getMonitor } from '@/services/workflow/workflow';

let progressData = 0;

function* pollData(workflowId, parentID, designId) {
  try {
    yield delay(3000);
    yield put(workFlowAction(workflowId, parentID, designId));
  } catch (error) {
    return;
  }
}

function* dataFetch(action) {
  yield takeEvery(GET_WORK_FLOWS, function* (action) {
    const { workflowId, parentID, designId } = action;
    try {
      const response = (yield call(getWorkFlow, workflowId)).data;
      let monitor = yield call(getMonitor, workflowId);
      monitor.forEach(item => {
        const time = new Date(item.createdAt);
        time.setUTCSeconds(time.getUTCSeconds());
        item.time = dayjs(time.toUTCString()).format('YYYY-MM-DD HH:mm:ss Z');
      });
      yield put(translationMonitor(monitor));
      if (response.code === SUCCESS) {
        const { data } = response;
        if (data.status === RUNNING) {
          const progress = parseInt(data.progress);
          let step = 6;
          if (progress === 0) {
            if (progressData > 85) {
              step = 2
            } if (progressData > 50) {
              step = 4;
            }
            if (progressData < 97) {
              progressData += Math.floor(Math.random() * step);
            }
            if (progressData > 98) {
              progressData = 98;
            }
          } else {
            progressData = progressData <= progress ? progress : progressData;
          }
          const running = translationProgress({ workflowId, designId, parentID, progress: progressData });
          yield put(running);
        }

        if (data.status === SUCCEED) {
          const time = new Date();
          time.setUTCSeconds(time.getUTCSeconds());
          const formatTime = dayjs(time.toUTCString()).format('YYYY-MM-DD HH:mm:ss Z');
          // success: 1. get log 2.reload design page
          const msg = `==> ${formatTime} Finish compiling PCB.`
          const succeeded = translationSuccess(data.progress, msg);
          // my design reload
          const reload = openProject();
          // close modal
          yield put(succeeded);
          yield put(reload);
          progressData = 0;
          message.success('Upload completed!', 3);
        }

        if (data.status === FAILED) {
          const time = new Date();
          time.setUTCSeconds(time.getUTCSeconds());
          const formatTime = dayjs(time.toUTCString()).format('YYYY-MM-DD HH:mm:ss Z');
          const msg = `==> ${formatTime} Failed compiling PCB. \n` + data.message;
          const failed = translationFailed(msg, data.progress);
          const reload = openProject();
          yield put(reload);
          yield put(failed);
          progressData = 0;
          message.error('Upload failed!', 3);
        }
      } else if (response.code === CHECK_FAIL) {
        const time = new Date();
        time.setUTCSeconds(time.getUTCSeconds());
        const formatTime = dayjs(time.toUTCString()).format('YYYY-MM-DD HH:mm:ss Z');
        const msg = `==> ${formatTime} Failed compiling PCB. \n` + response.msg;
        yield put(translationFailed(msg, 100));
        progressData = 0;
        message.error('Upload failed!', 3);
      }
    } catch (error) {
      const time = new Date();
      time.setUTCSeconds(time.getUTCSeconds());
      const formatTime = dayjs(time.toUTCString()).format('YYYY-MM-DD HH:mm:ss Z');
      yield put(addMonitorMsg(`==> ${formatTime} Failed compiling PCB.`));
      const msg = `==> ${formatTime} Failed compiling PCB. \n` + msg;
      yield put(translationFailed(msg, 100));
      progressData = 0;
      message.error('Upload failed!', 3);
      console.error(error);
    }
  })
}

function* getLog(action) {
  yield takeEvery(GET_TRANSLATION_MONITOR, function* (action) {
    const { designId } = action;
    const response = yield call(getDesignFile, designId, 'lay_trans/log/layout_trans.log');
    let monitor = response && response.data || null;
    yield put(translationMonitor(monitor));
  })
}

function* translationWork(action) {
  yield takeEvery(TRANSLATION_RUNNING, function* (action) {
    const { workflowId, designId, parentID } = action;
    try {
      yield race([
        call(pollData, workflowId, parentID, designId),
        take(TRANSLATION_SUCCESS),
        take(TRANSLATION_FAILED),
      ]);
    } catch (error) {
      console.error(error);
    }
  })
}

export default function* FileUploadSaga() {
  yield all([
    fork(translationWork),
    fork(dataFetch),
    fork(getLog)
  ])
}