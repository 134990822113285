import React, { Fragment } from "react";
import { Collapse, Input } from 'antd';
import { PRBS } from "../../../../services/Andes_v2/AMIModelHelper";

class PRBSSetup extends React.Component {
  render() {
    const { prbs, modelList = [] } = this.props;
    const PRBSItem = modelList.find(item => prbs && item.key === prbs.mode) || {};
    const items = [{
      key: "PRBS",
      label: <div className="ami-model-collapse-title">PRBS</div>,
      children: <Fragment>
        {this.props.getSelectComponent({
          title: "Mode",
          type: "mode",
          value: prbs ? prbs.mode : "",
          list: modelList,
          saveValue: this.props.saveConfigValue,
          saveConfigValue: this.props.saveConfigValue
        })}
        {PRBSItem && PRBSItem.children && PRBSItem.children.length ?
          PRBSItem.children.map(item => {
            return <div key={item.key} className={`ami-advanced-select-item ami-advanced-sub-item`}>
              <span>{item.title}</span>
              <Input
                className='aurora-input'
                placeholder={item.title}
                value={prbs[item.key]}
                onChange={(e) => this.props.inputChange(e, item.key, PRBS)}
                onBlur={(e) => this.props.inputBlur(e, item.key, PRBS)}
              />
            </div>
          })
          : null}
      </Fragment>
    }]
    return <Collapse className="ami-model-collapse-content ami-model-prbs-content" items={items}>
    </Collapse>
  }
}

export default PRBSSetup;