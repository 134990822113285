import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Spin, Table } from 'antd';
import EditableTable from "../../../../components/EditableTable";
import { TEST_POINT, TEST_POINT_DISPLAY } from "../../../../constants/componentType";
import { updateComponents, updateSchematicLoading } from '../../store/prelayout/action';
import { reSortComponents } from "../../../../services/Sierra/prelayout";
import sierraLibrary from "../../../../services/Sierra/library/libraryStorage";
import { PCB_SPICE, SPICE } from "../../../../constants/libraryConstants";
import update from 'immutability-helper';
import '../../../../components/PreLayout/index.css';
import './index.css'
import { SCHEMATIC } from "../../../../services/Sierra/prelayout/prelayoutConstants";


const ComponentsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "33.3%"
  },
  {
    title: "Layer",
    dataIndex: "layer",
    width: "33.3%"
  },
  {
    title: "Type",
    dataIndex: "type",
    width: '33.3%'
  }
]
const layerList = ["Top", "Bottom"];
const pinColumns = [
  {
    title: "Pin",
    dataIndex: "pin",
    width: "20%"
  },
  {
    title: "Signal",
    dataIndex: "signal",
    width: "20%"
  },
  {
    title: "Net",
    dataIndex: "net",
    width: "20%"
  },
  {
    title: "Model",
    dataIndex: "model",
    width: "30%"
  }
],
  schematicPinColumns = [
    {
      title: "Pin",
      dataIndex: "pin",
      width: "20%"
    },
    {
      title: "Signal",
      dataIndex: "signal",
      width: "20%"
    },
    {
      title: "Net",
      dataIndex: "net",
      width: "20%"
    }
  ]

class PreLayoutComponents extends Component {

  componentDidMount = () => {
    this.initColumns();
    this.initPinColumns();
  }

  initColumns = () => {

    ComponentsColumns[1].onCell = (record) => {
      const { fixed } = this.props;
      return fixed ? {
        edit: false
      } : {
        record,
        edit: "select",
        options: layerList,
        dataIndex: "layer",
        getPopupContainer: document.getElementById('root'),
        handleSave: (record) => { this.editComponent(record, "layer") },
      }
    }

    ComponentsColumns[2].render = (text) => {
      if (text === TEST_POINT) {
        return TEST_POINT_DISPLAY;
      }
      return text;
    }
  }

  initPinColumns = () => {
    if (this.props.prelayout === SCHEMATIC) {
      return;
    }
    pinColumns[3].render = (text) => {
      const { libraryId = "", port = "", subckt } = text || {};
      if (port) {
        const { model } = this.props;
        const type = model && model.modelType ? model.modelType === SPICE ? PCB_SPICE : PCB_SPICE : PCB_SPICE
        const file = sierraLibrary.checkFile(type, libraryId);
        if (file) {
          return `${file.name}::${subckt}::${port}`
        }
        return ""
      }
      return ""
    }
  }

  editComponent = (record, type) => {
    const { components, prelayout } = this.props;
    let _components = JSON.parse(JSON.stringify(components));
    if (prelayout === SCHEMATIC) {
      _components.forEach(comp => {
        comp[type] = record[type];
      })
    } else {
      const index = _components.findIndex(item => item.name === record.name);
      _components[index][type] = record[type];
    }
    this.props.updateComponents(_components);
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { components } = this.props;
    this.props.updateSchematicLoading(true);
    let _components = JSON.parse(JSON.stringify(components));
    let _hoverIndex = hoverIndex;
    if (dragIndex < hoverIndex) {
      if (_components[_hoverIndex] && _components[_hoverIndex].group && _components[_hoverIndex + 1] && _components[_hoverIndex + 1].group && _components[_hoverIndex].group === _components[_hoverIndex + 1].group) {
        _hoverIndex = _hoverIndex - 1 > 0 ? _hoverIndex - 1 : 0
      }
    } else {
      if (_components[_hoverIndex] && _components[_hoverIndex].group && _components[_hoverIndex - 1] && _components[_hoverIndex - 1].group && _components[_hoverIndex].group === _components[_hoverIndex - 1].group) {
        _hoverIndex = _hoverIndex - 2 > 0 ? _hoverIndex - 2 : 0;
      }
    }
    const moveItem = _components[dragIndex];
    if (moveItem.group) {
      const moveItem_1 = _components[dragIndex + 1];
      if (!moveItem_1 || !moveItem_1.group || moveItem_1.group !== moveItem.group) {
        const _moveItem = _components[dragIndex - 1];
        _components = update(_components, {
          $splice: [[dragIndex - 1 > 0 ? dragIndex - 1 : 0, 2], [_hoverIndex - 1 > 0 ? _hoverIndex - 1 : 0, 0, _moveItem, moveItem]],
        })
      } else {
        _components = update(_components, {
          $splice: [[dragIndex, 2], [_hoverIndex - 1 > 0 ? _hoverIndex - 1 : 0, 0, moveItem, moveItem_1]],
        })
      }
    } else {
      _components = update(_components, {
        $splice: [[dragIndex, 1], [_hoverIndex, 0, moveItem]],
      })
    }
    const sortComponents = reSortComponents(_components, components);
    this.props.updateComponents(sortComponents)
  }

  expandedRowRender = (record) => {
    const pins = record.pins || [];
    return <Table
      columns={this.props.prelayout === SCHEMATIC ? schematicPinColumns : pinColumns}
      dataSource={pins}
      pagination={false}
      size="small"
      rowKey={(record) => `${record.pin}-${record.net}`}
      className="sierra-pre-layout-pin-table"
    />
  }

  render() {
    const { loading, components } = this.props;
    return <Fragment>
      <span className="font-bold sierra-setup-title-color">Components</span>
      <div className="space-10">
        <Spin spinning={loading}>
          <EditableTable
            rowKey={(record) => `${record.name}-${record.type}`}
            columns={ComponentsColumns}
            size="small"
            dataSource={components}
            className="pre-layout-component-table sierra-pre-layout-component-table"
            expandable={{
              expandedRowRender: this.expandedRowRender
            }}

            rowClassName={(record, index) => index % 2 !== 0 ? 'editable-row aurora-row-background' : 'editable-row'}
            dragable={false}
            moveRow={this.moveRow}
          />
        </Spin>
      </div>
    </Fragment>
  }
}

const mapState = (state) => {
  const { SierraReducer: { prelayout: { components, compTableLoading, model, prelayout } } } = state;
  return {
    components,
    model,
    loading: compTableLoading,
    prelayout
  }
}

const mapDispatch = (dispatch) => ({
  updateComponents(components) {
    dispatch(updateComponents(components))
  },
  updateSchematicLoading(boolean) {
    dispatch(updateSchematicLoading(boolean))
  }
})

export default connect(mapState, mapDispatch)(PreLayoutComponents);