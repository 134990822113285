import React, { Component } from 'react';
import { connect } from 'react-redux';
import Monitor from '@/components/Monitor/UploadMonitor';
import { cancelUploadWorkflow, changeDesign } from '../../AndesSider/uploadPCB/store/action';
import designConstructor from '@/services/helper/designConstructor';
import { PACKAGE, LAYOUT } from '../../../../constants/designType';
import { getDisplayDesignList } from '../../../../services/PCBHelper/pcbHelper';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';

class SimulationMonitor extends Component {

  render() {
    return (
      <Monitor
        {...this.props}
      />
    )
  }
}

const mapState = (state) => {
  const { visible, uploadProgress, transProgress, msg, monitor, uploadProjectId,
    endMsg, uploadDesignId, uploadDesignName, uploadWorkflowId, compressProgress, designLog, openDesignId, openDesignType } = state.AndesV2Reducer.AndesV2UploadReducer;
  const { monitorScreenInfo: {
    projectName,
    currentProjectId
  }, tabVisible } = state.MonitorInfoReducer;

  let design = designConstructor.getDesign(openDesignId) || {};
  let designs = currentProjectId ? designConstructor.getDesignValues(currentProjectId) : [];
  if (uploadProjectId === currentProjectId && (visible || (uploadDesignName && ((monitor && monitor.length) || msg)))) {
    designs.push({ id: "upload", name: uploadDesignName, type: openDesignType === "package" ? PACKAGE : LAYOUT });
  }

  if (uploadProjectId === currentProjectId && openDesignId === "upload") {
    design = { name: uploadDesignName, id: "upload", type: openDesignType === "package" ? PACKAGE : LAYOUT }
  }
  const _designs = getDisplayDesignList(designs);

  return {
    visible,
    startMsg: msg,
    uploadProgress,
    transProgress,
    compressProgress,
    monitor,
    uploadProjectId,
    endMsg,
    uploadDesignId,
    tabVisible,
    projectName,
    openProjectId: currentProjectId,
    uploadWorkflowId,
    designLog,
    openDesignId,
    uploadDesignName,
    subName: design.name,
    designs: _designs,
    subOptionType: "group",
    subSelect: { ...design }
  }
}

const mapDispatch = (dispatch) => ({
  _cancelUploadWorkflow(workflowId) {
    dispatch(cancelUploadWorkflow(workflowId));
  },
  _changeDesign(designId) {
    dispatch(changeDesign(designId))
  }
})

export default connect(mapState, mapDispatch)(SimulationMonitor);