import React, { Component, Fragment } from "react";
import { Select, Input } from 'antd';
import { numberCheck } from "../../../services/helper/dataProcess";
import UnitAddonAfter from "../../UnitAddonAfter";
import './index.css';

const Option = Select.Option;
class ReferencePoint extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locationX: 10,
      locationY: 10,
      locationUnit: "mm"
    }
  }

  addonAfter = () => {
    const unit = this.state.locationUnit;
    return UnitAddonAfter({
      unit,
      changeUnit: (key) => this.changeUnit(key),
      list: ["mm", "mil"]
    });
  }

  changeUnit = (unit) => {
    this.setState({
      locationUnit: unit
    })
  }

  updateValue = (e, type) => {
    this.setState({
      [type]: e.target.value
    });
    const { errorMsg } = this.props;
    if (errorMsg && errorMsg.type === type) {
      this.props.updateError(null);
    }
  }

  valueOnBlur = (e, type) => {
    const value = e.target.value;
    const error = numberCheck(value);
    if (error) {
      this.props.updateError({ type, error: `Location ${error}` });
    }
  }

  changePoint = (key) => {
  }

  changeLayer = () => {

  }

  render() {
    const { distance } = this.state;
    const { layerList = [] } = this.props;
    /*   const { point_x, point_y } = reference; */
    return <Fragment>
      <div className="signal-port-edit-item signal-ports-edit-point">
        <label>Search distance​</label>
        <Input
          className='aurora-input signal-ports-edit-point-input'
          value={distance}
          onChange={(e) => this.updateValue(e)}
          onBlur={(e) => this.valueOnBlur(e)}
        />
        <Input
          className='aurora-input'
          value={distance}
          onChange={(e) => this.updateValue(e)}
          onBlur={(e) => this.valueOnBlur(e)}
        />
        {this.addonAfter()}
      </div>
      {this.getSelectComponent({
        label: "Layer​",
        value: "",
        changeFn: this.changeLayer,
        options: layerList,
        type: "layer"
      })}
    </Fragment>
  }

  getSelectComponent = ({
    label,
    value,
    changeFn,
    options
  }) => {
    return <div className='signal-port-edit-item'>
      <label>{label}</label>
      <Select
        className='aurora-select'
        value={value}
        showSearch
        onChange={(key) => changeFn(key)}
        popupMatchSelectWidth={false}
        popupClassName='aurora-select-dropdown'
      >
        {options.map(item => {
          return <Option key={item.name} title={item.name} > {item.name}</Option>
        })}
      </Select>
    </div >
  }
}

export default ReferencePoint;