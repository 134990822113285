import { checkConnectTree, checkNotConnectTree } from '../../workers/Cascade/checkPowerTree.worker.js';
import { sortPowerTree } from '../../workers/Cascade/sortPowerTree.worker.js';
import { setPowerTreeValue } from '../../workers/Cascade/setPowerTreeValue.worker.js';

const maxLevel = 20;
async function checkPowerTreeWorker(params, fn) {
  const { paths, allComponents, pinMap, designId, treeObj, rootGnd, setting, table, root, pcbTable, level = 1, nets } = params;
  const { checkConnectComp, getCompsInGndPath, getGndComps } = fn;
  let _paths = [], groundComps = [], groundNets = [], objChange = {}, nextKeys = [];
  const treeObjNames = Object.keys(treeObj).filter(item => item !== root);
  let _treeObj = {};
  treeObjNames.forEach(name => {
    _treeObj[name] = {};
    _treeObj[name].pins = treeObj[name].pins;
    _treeObj[name].paths = treeObj[name].paths;
    _treeObj[name].select = treeObj[name].select;
    _treeObj[name].groundNet = treeObj[name].groundNet;
    _treeObj[name].nextKeys = treeObj[name].nextKeys;
  })
  let __treeObj = { ...treeObj };
  const treeCompName = treeObj[root] ? treeObj[root].name : null;
  const treeSelectPin = treeObj[root] ? treeObj[root].select : [];
  try {
    for (let path of paths) {
      const lastComp = path[path.length - 1];
      const { part, type, name, pins } = lastComp;
      const currentComp = allComponents.get(name);
      const allPins = currentComp && currentComp.pins ? [...currentComp.pins.values()] : [];
      let broComp = undefined;
      if (type === 'VRM') {
        const find = pinMap.find(item => item.partNumber === part || (item.type === "buckConverter" && item.partNumber.includes(name)));
        let out = [];
        if (find && find.data && find.data.length) {
          const { data, type, partNumber } = find;
          const pinNames = pins.map(item => item.pinName);
          if (type === "buckConverter") {
            out = pinNames.map(item => {
              const findMap = data.filter(d => d.input.includes(`${name}_${item}`));
              return findMap.length ? findMap.map(d => d.output).flat(2).map(item => item.replace(`${name}_`, '')) : [];
            })
            const bro = partNumber.split(' - ').find(item => item !== name);
            if (bro) {
              const broInfo = allComponents.get(bro);
              broComp = { ...broInfo, pins: [...broInfo.pins.values()] }
            }
          } else {
            out = pinNames.map(item => {
              const findMap = data.filter(d => d.input.includes(item));
              return findMap.length ? findMap.map(d => d.output).flat(2) : [];
            })
          }
          out = [...new Set(out.flat(2))]
        }
        let _pins = allPins.filter(p => out.includes(p.pinName));
        if (pcbTable.length) {
          const row = pcbTable.find(item => (item.components && item.components.includes(name)) || part === item.partNumber);
          if (row && row.nets) {
            const rowOutput = row.nets.map(item => item.pinNames).flat(2);
            _pins = allPins.filter(p => rowOutput.includes(p.pinName));
          }
        }
        if (name === treeCompName) {
          _pins = _pins.filter(p => !treeSelectPin.includes(p.pin));
        }
        let _connect = await checkConnectComp(name, _pins, designId);
        _connect = [...new Set(_connect.map(c => c.nextNet ? c.nextNet : c.net))];
        if (_connect.length && level < maxLevel) {
          for (let conn of _connect) {
            const { name, partName } = currentComp
            const treeName = `${conn} - ${name} - ${partName}`;
            if (!__treeObj[treeName]) {
              continue;
            }
            const __paths = __treeObj[treeName].paths, __rootGnd = __treeObj[treeName].groundNet
            const nextTreeParams = await checkPowerTreeWorker({ ...params, root: treeName, paths: __paths, rootGnd: __rootGnd, treeObj: __treeObj, level: level + 1 }, fn)
            groundComps.push(...nextTreeParams.groundComps)
            _treeObj[treeName].paths = [...nextTreeParams._paths]
            _treeObj[treeName].nextKeys = [...nextTreeParams.nextKeys]
            __treeObj[treeName].paths = [...nextTreeParams._paths]
            __treeObj[treeName].nextKeys = [...nextTreeParams.nextKeys]
            __treeObj[treeName].groundComps = [...(__treeObj[treeName].groundComps || []), ...nextTreeParams.groundComps]
            const objKeys = Object.keys(nextTreeParams.objChange);
            for (let key of objKeys) {
              const itemKeys = Object.keys(nextTreeParams.objChange[key]);
              for (let itemKey of itemKeys) {
                if (_treeObj[key][itemKey]) {
                  _treeObj[key][itemKey] = nextTreeParams.objChange[key][itemKey]
                }
                __treeObj[key][itemKey] = nextTreeParams.objChange[key][itemKey]
              }
            }
          }
          const outputObj = await checkConnectTree(_connect, _treeObj, out, rootGnd, path, nets, allComponents, currentComp, broComp);
          _paths.push(...outputObj.paths);
          groundNets.push(...outputObj.outputGnd);
          objChange = { ...objChange, ...outputObj.objChange };
          console.log(objChange)
          nextKeys = [...outputObj.nextKeys];
        } else {
          const outputObj = await checkNotConnectTree(path, nets, rootGnd, allComponents);
          _paths.push(...outputObj.paths);
          groundNets.push(...outputObj.outputGnd);
          objChange = { ...objChange, ...outputObj.objChange };
        }
      } else {
        const outputObj = await checkNotConnectTree(path, nets, rootGnd, allComponents);
        _paths.push(...outputObj.paths);
        groundNets.push(...outputObj.outputGnd);
        objChange = { ...objChange, ...outputObj.objChange };
      }
    }

    for (let gnd of groundNets) {
      const { groundNet, rootGnd } = gnd;
      let gndComps = getCompsInGndPath(groundNet, rootGnd, [], designId, setting, allComponents);
      gndComps = getGndComps(gndComps, groundComps, table);
      groundComps.push(...gndComps)
    }
  } catch (error) {
    console.error(error)
  }


  return { _paths, groundComps, objChange, nextKeys, __treeObj }
}

async function sortPowerTreeWorker(treeData, connectors, ComponentType) {
  const newTree = await sortPowerTree(treeData, connectors, ComponentType)
  return newTree
}

async function setPowerTreeValueWorker(powerTree, oldData) {
  const newTree = await setPowerTreeValue(powerTree, oldData);
  return newTree
}

export {
  checkPowerTreeWorker,
  sortPowerTreeWorker,
  setPowerTreeValueWorker
}