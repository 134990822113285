import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { autoReadOrWriteSignalMapping, getPcbSignals, getUpdateSignalMapping } from '../../services/LibraryHelper/SpiceModelHelper/csmCpmParseHelper';
import CSMTable from './CPMConnection/CSMTable';
import "./index.css";
class CSMSignalsConnection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 1200,
      maxHeight: 800,
      signalMapping: props.csmPairs || [],
      signals: [],
      portList: [],
      pcbSignals: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 800),
      maxWidth: getPanelMaxWidth(offset, 1200)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.initSignals();
  }

  componentDidUpdate = (prevProps) => {
    const { isUpdateSsnPackageChannel, selectFile, writeSelectFile, readSelectFile } = this.props;
    if (isUpdateSsnPackageChannel && isUpdateSsnPackageChannel !== prevProps.isUpdateSsnPackageChannel) {
      this.initSignals()
      this.props.updateSSNPackageInfo(false)
    }

    // if ((!prevProps.selectFile || !prevProps.selectFile.subckt || (selectFile && prevProps.selectFile.subckt !== selectFile.subckt))
    //   && (selectFile && selectFile.libraryId && selectFile.fileName && selectFile.subckt
    //   )) {
    //   this.initModel()
    // }

    if (((!prevProps.writeSelectFile || !prevProps.writeSelectFile.subckt || (writeSelectFile && prevProps.writeSelectFile.subckt !== writeSelectFile.subckt)) && (writeSelectFile && writeSelectFile.libraryId && writeSelectFile.fileName && writeSelectFile.subckt))
      || ((!prevProps.readSelectFile || !prevProps.readSelectFile.subckt || (readSelectFile && prevProps.readSelectFile.subckt !== readSelectFile.subckt)) && (readSelectFile && readSelectFile.libraryId && readSelectFile.fileName && readSelectFile.subckt))) {
      this.initModel()
    }

  }

  initSignals = () => {
    const { rockyPackageInfo, packageChannelId, content, pcbChannelInfo, pcb } = this.props;
    if (rockyPackageInfo && rockyPackageInfo.id === packageChannelId && rockyPackageInfo.content) {
      const pcbSignals = getPcbSignals(content, pcbChannelInfo, pcb)
      this.setState({
        signals: rockyPackageInfo.content.Signals || [],
        pcbSignals: pcbSignals
      }, () => {
        this.initSignalMapping();
      })
    }
  }

  initSignalMapping = () => {
    const { signals, signalMapping, pcbSignals } = this.state;
    let _signalMapping = [];
    for (let signal of signals || []) {
      const findSignal = signalMapping.find(item => item.pkgSignal === signal.name) || {};
      const pcbSignalInfo = pcbSignals.find(item => item.name === signal.name)
      _signalMapping.push({
        pkgSignal: signal.name,
        nets: [...(signal.nets || [])],
        csmSignal: findSignal.csmSignal || "",
        pad: findSignal.pad || "",
        in: findSignal.in || "",
        readPad: findSignal.readPad || "",
        pcbNets: pcbSignalInfo && pcbSignalInfo.nets ? pcbSignalInfo.nets : [],
        csmInstanceSignal: findSignal.csmInstanceSignal
      })
    }

    this.setState({
      signalMapping: _signalMapping
    }, () => {
      this.initModel()
    })
  }

  initModel = async () => {
    const { selectFile, writeSelectFile, readSelectFile, product } = this.props;
    if (!selectFile || !selectFile.libraryId || !selectFile.fileName || !selectFile.subckt) {
      return;
    }
    const portList = await this.props.getPortList(writeSelectFile, readSelectFile, product);
    this.setState({
      portList
    }, () => {
      if (writeSelectFile && writeSelectFile.libraryId && writeSelectFile.subckt
        && readSelectFile && readSelectFile.libraryId && readSelectFile.subckt) {
        const { signalMapping, portList } = this.state;
        const { newSignalMapping, isUpdate } = autoReadOrWriteSignalMapping(signalMapping, portList)
        if (isUpdate) {
          this.setState({
            signalMapping: newSignalMapping
          })
        }
      } else {
        this.updateSignalMapping()
      }
    })
  }

  updateSignalMapping = () => {
    const { signalMapping, portList } = this.state;
    const { signalMapping: newSignalMapping, isUpdate } = getUpdateSignalMapping(signalMapping, portList)
    if (isUpdate) {
      this.setState({
        signalMapping: newSignalMapping
      })
    }
  }

  // autoMatchPort = () => {
  //   const { signalMapping, portList } = this.state;
  //   const _signalMapping = autoMatchCSMPorts(signalMapping, portList)
  //   this.setState({
  //     signalMapping: _signalMapping
  //   })
  // }

  // editCSMSignal = (record, prevRecord) => {
  //   const { signalMapping, portList } = this.state;
  //   const [csmSignal, PAD, IN] = record.csmSignal ? record.csmSignal.split(" :: ") : null;
  //   if (!csmSignal) {
  //     return;
  //   }
  //   let _signalMapping = [...signalMapping];
  //   const index = _signalMapping.findIndex(item => item.pkgSignal === record.pkgSignal);
  //   if (index < 0) {
  //     return;
  //   }
  //   const findPort = portList.find(item => item.name === csmSignal && item.PAD === PAD && item.IN === IN);
  //   if (!findPort) {
  //     return;
  //   }
  //   //remove exist csm signal in other signals
  //   const prevIndex = _signalMapping.findIndex(item => item.csmSignal === csmSignal && item.pad === PAD && item.in === IN);
  //   if (prevIndex > -1) {
  //     _signalMapping[prevIndex] = {
  //       ..._signalMapping[prevIndex],
  //       csmSignal: "",
  //       pad: "",
  //       in: "",
  //       readPad: ""
  //     }
  //   }
  //   _signalMapping[index] = {
  //     ..._signalMapping[index],
  //     csmSignal: findPort.name,
  //     pad: findPort.port.PAD,
  //     in: findPort.port.IN,
  //     readPad: findPort.port.READ_PAD
  //   }
  //   this.setState({
  //     signalMapping: _signalMapping
  //   })
  // }

  closePanel = () => {
    const { signalMapping } = this.state;
    this.props.saveCSMPairs(signalMapping)
    this.props.closePanel()
  }

  render() {
    const { maxWidth, maxHeight, signalMapping, portList } = this.state;

    const content = (
      <Panel
        className='csm-connection-edit-panel'
        title={<div className='csm-connection-edit-title'>CSM Instance Mapping</div>}
        onCancel={this.closePanel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 1200 })}
        position={'panel-center'}
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='csm-connection-edit-main' style={{ maxHeight: (maxHeight - 50) }}>
          <CSMTable
            getPortsOptions={this.getPortsOptions}
            signalMapping={signalMapping}
            maxHeight={maxHeight}
            portList={portList}
          />
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default CSMSignalsConnection;