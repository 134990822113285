import React, { Fragment } from "react";
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Select } from "antd";
import { PCB_SPICE, SPICE } from "../../../../constants/libraryConstants";
import TreeSelect from "../../../../components/TreeSelect";
import sierraLibrary from "../../../../services/Sierra/library/libraryStorage";
import '../../../../publicCss/netlist.css';

const Option = Select.Option;
const modelTypes = [{ key: SPICE, title: 'SPICE', type: PCB_SPICE }];

const ModelSelect = (props) => {
  const { width, selectModelType, model, fixed, portsObj } = props;
  const { modelType, libraries } = model;
  const libraryFiles = props[modelType] || [];
  const libraryType = modelTypes.find(m => m.key === modelType);
  return <Fragment>
    <div className='pcb-model-select'>
      <span style={{ width, minWidth: width }}>Model Type</span>
      <Select
        value={modelType}
        onSelect={selectModelType}
        className='pcb-model-selection'
        popupClassName='pcb-model-select-dropdown'
        popupMatchSelectWidth={true}
        getPopupContainer={() => document.getElementById('root')}
        disabled={fixed}
      >
        {modelTypes.map(m => <Option key={m.key} value={m.key}>{m.title}</Option>)}
      </Select>
    </div>
    {
      libraries.length ? libraries.map((library, index) => FileSelect({ ...props, libraryType: libraryType.type, libraryId: library.libraryId, subckt: library.subckt, libraryFiles, showTitle: index === 0 ? true : false, index, portsObj }))
        : FileSelect({ ...props, libraryType: libraryType.type, libraryId: "", libraryFiles, index: 0, portsObj })
    }
  </Fragment>
}

const AddEmptyModel = (addNewFileSelect) => {
  return (
    <PlusCircleOutlined
      title={'Add new model'}
      className='spice-model-add-file-icon'
      onClick={(e) => addNewFileSelect(e)} />
  );
}

const DeleteModel = (deleteModel, index) => {
  return (
    <CloseOutlined
      title={'Delete model'}
      className='spice-model-add-file-icon sierra-pre-layout-model-delete-icon'
      onClick={(e) => deleteModel(e, index)} />
  );
}

const FileSelect = (props) => {
  const { width, libraryType, libraryId, libraryFiles, showTitle = true, onSelectLibrary, selectSubckt, index, addNewFileSelect, deleteModel, fixed, portsObj, subckt } = props;
  const libraryFile = sierraLibrary.checkFile(libraryType, libraryId);
  if (!libraryFile && libraryId) {
    onSelectLibrary("", index);
  }
  let value = libraryFile ? libraryFile.name : '';
  const subcktList = portsObj[libraryId] ? portsObj[libraryId].map(item => item.name) : []
  return <Fragment key={index}>
    <div className='pcb-model-select'>
      <span style={{ width, minWidth: width }}>{showTitle ? `Model` : ''}{showTitle && !fixed ? AddEmptyModel(addNewFileSelect) : ''}</span>
      <TreeSelect
        value={value || undefined}
        onSelectItem={({ id }) => onSelectLibrary(id, index)}
        size='small'
        showSearch
        popupClassName='pcb-model-select-dropdown'
        className='sierra-pre-layout-pcb-model-select'
        allowClear={false}
        fileList={libraryFiles}
        getPopupContainer={() => document.getElementById('root')}
        selected={libraryId}
        disabled={fixed}
      />
      <Select
        value={subckt}
        onSelect={(subckt) => selectSubckt(subckt, index)}
        className='sierra-pre-layout-pcb-model-select sierra-pre-layout-pcb-model-select-right'
        popupClassName='pcb-model-select-dropdown'
        popupMatchSelectWidth={true}
        getPopupContainer={() => document.getElementById('root')}
        disabled={fixed}
      >
        {subcktList.map(m => <Option key={m} value={m}>{m}</Option>)}
      </Select>
      {!fixed && DeleteModel(deleteModel, index)}
    </div>
  </Fragment>
}

export default ModelSelect