import CePolygon from './CePolygon';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

const CePolygonWithHole = function (outline, holes) {
  CePolygon.call(this, "PolygonHole", outline);
  this.mHoles = holes || [];
}

// subclass extends superclass
CePolygonWithHole.prototype = Object.create(CePolygon.prototype);
CePolygonWithHole.prototype.constructor = CePolygonWithHole;

CePolygonWithHole.prototype.GetHoles = function () {
  return this.mHoles;
}

CePolygonWithHole.prototype.AddHole = function (hole) {
  this.mHoles.push(hole);
}

CePolygonWithHole.prototype.CopyFrom = function (fromObj) {
  CePolygon.prototype.CopyFrom.call(this, fromObj);

  this.mHoles = [];
  for (var i = 0; i < fromObj.mHoles.length; i++) {
    this.mHoles.push(fromObj.mHoles[i].Clone());
  }
}

CePolygonWithHole.prototype.Clone = function () {
  var clnObj = new CePolygonWithHole();
  clnObj.CopyFrom(this);
  return clnObj;
}

CePolygonWithHole.prototype.Scaling = function (scaleFactor) {
  CePolygon.prototype.Scaling.call(this, scaleFactor);
  for (var i = 0; i < this.mHoles.length; i++) {
    this.mHoles[i].Scaling(scaleFactor);
  }
}

CePolygonWithHole.prototype.WriteIntoIODataNode = function () {

  var myBlock = CePolygon.prototype.WriteIntoIODataNode.call(this);
  myBlock.SetName(this.GetGeomType());
  var holeBlock = new CeIODataBlock("Holes");
  for (var i = 0; i < this.mHoles.length; i++) {
    holeBlock.AddBlockNode(this.mHoles[i].WriteIntoIODataNode());
  }
  myBlock.AddBlockNode(holeBlock);
  return myBlock;
}

CePolygonWithHole.prototype.ReadFromIODataNode = function (myBlock) {
  CePolygon.prototype.ReadFromIODataNode.call(this, myBlock);

  var holesBlock = myBlock.GetSubBlock("Holes");
  if (holesBlock != null) {
    this.mHoles = [];
    var holeList = holesBlock.GetAllSubBlocks();
    for (var i = 0; i < holeList.length; i++) {
      var hole = new CePolygon();
      hole.ReadFromIODataNode(holeList[i]);
      this.mHoles.push(hole);
    }
  }
  return true;
}

CePolygonWithHole.prototype.SetOutline = function (polygon) {
  CePolygon.prototype.CopyFrom.call(this, polygon);
}

CePolygonWithHole.prototype.Transform = function (celocation) {
  CePolygon.prototype.Transform.call(this, celocation);
  for (var i = 0; i < this.mHoles.length; i++) {
    this.mHoles[i].Transform(celocation);
  }
}

export default CePolygonWithHole;