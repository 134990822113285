import React, { Component, createRef } from 'react';
import NetListModel from './NetListModel';
import { numberCheck } from '@/services/helper/dataProcess';
import { PackagePin } from '@/services/helper/packageHelper';
import { autoSelectEBDPorts, findSelectedEBDPorts } from '@/services/helper/ebdHelper';
import { getDefaultPortSetting } from '@/services/helper/packageModelHelper';
import './index.css';

const EBD = 'EBD', TOUCHSTONE = "Touchstone", SPICE = "SPICE", Connector = "Connector";
function filesInfo(fileName = "", libraryId = "", subckt = "", type = "", version = "") {
  return { fileName, libraryId, subckt, type, version };
}
class SetPKGNodeModel extends Component {
  constructor(props) {
    super(props);
    const { pkg } = props;
    const pairs = pkg.pairs && pkg.pairs.length > 0 ? pkg.pairs : this.getPairs();
    this.state = {
      //type: modelType,// Repeater / SPICE(todo) / "Package"(todo)
      files: pkg.files && pkg.files.length > 0 ? [...pkg.files] : [],
      pairs: pairs, //{ pin:"", node:"", subckt:"", libraryId:""}
      pinList: this.getPins(pairs, pkg.rank),
      subcktList: [], //[ { libraryId:"", fileName, models:[{ name:"", ports:[ port:"", info:"" , ...] } ], } ]
      portsObj: {}, //{ libraryId: [{ fileName, type, subckt, ports:[{ port:"",info:"", select: true/false}] } }] }
      errorMsg: null,
      rank: pkg && pkg.rank ? pkg.rank : null,
      top: 0
    };
    this.inputRef = createRef();
  }

  getPins = (pairs, rank = null) => {
    const { pins, modelType } = this.props;
    let _pinList = [];
    if (modelType === Connector) {
      pins.forEach(item => {
        if (item.pin) {
          const pin = pairs.find(it => it.pin === item.pin);
          const index = _pinList.findIndex(p => p.pinLeft && p.pinLeft.find(it => it.pin === item.pin));
          if (index >= 0) {
            _pinList[index].signalDisplay = `${_pinList[index].signalDisplay}, ${item.signal}`;
          } else if (pin) {
            const pinData = {
              net: item.net,
              signal: item.signal,
              signalDisplay: item.signal,
              netDisplay: item.net
            }
            pinData['pinLeft'] = [new PackagePin(item.pin, pin)];
            const right = [];
            const pinOutName = `${item.pin}_u`;
            const pinOut = pairs.find(it => it.pin === pinOutName);
            right.push(new PackagePin(pinOutName, pinOut, `${item.pin}_out`));
            pinData['pinRight'] = [...right];
            _pinList.push(pinData)
          }
        }
      });
    } else {
      pins.forEach(item => {
        if (item.pin) {
          const pin = pairs.find(it => it.pin === item.pin);
          const index = _pinList.findIndex(p => p.pinRight && p.pinRight.find(it => it.pin === item.pin));
          if (index >= 0) {
            _pinList[index].signalDisplay = `${_pinList[index].signalDisplay}, ${item.signal}`;
          } else if (pin) {
            const pinData = {
              net: item.net,
              signal: item.signal,
              signalDisplay: item.signal,
              netDisplay: item.net
            }
            pinData['pinRight'] = [new PackagePin(item.pin, pin)];
            const left = [];
            if (rank && rank.number) {
              for (let i = 1; i <= Number(rank.number); i++) {
                const pinDieName = i === 1 ? `${item.pin}_u` : `${item.pin}_u_${i}`;
                const pin = pairs.find(it => it.pin === pinDieName);
                left.push(new PackagePin(pinDieName, pin, `${item.pin}_die_${i}`))
              }
            } else {
              const pinDieName = `${item.pin}_u`;
              const pin = pairs.find(it => it.pin === pinDieName);
              left.push(new PackagePin(pinDieName, pin, `${item.pin}_die`))
            }
            pinData['pinLeft'] = [...left];
            _pinList.push(pinData)
          }
        }
      });
    }
    return _pinList;
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { pkg } = this.props;
    //files [ { fileName:"", libraryId:"", type:"", subckt:"" }]
    if (pkg && pkg.files && pkg.files.length > 0) {
      this.getFileParse(pkg.files);
    }

    //update pin node connect box top position
    this.props.setContentHeight(0);
  }

  changePKGType = () => {
    this.clearPinNode();
    this.setState({
      files: [],
      subcktList: [],
      portsObj: {}
    })
  }

  getNetListModel = () => {
    const { files, pairs, rank } = this.state;
    return {
      files,
      pairs: Array.isArray(pairs) ? pairs : this.getPairs(),
      rank
    }
  }

  getPairs = () => {
    let pairs = [];
    const { pins } = this.props;
    pins.forEach(item => {
      if (pairs.findIndex(pair => pair.pin === item.pin) < 0) {
        let pinR = { pin: item.pin, node: "", subckt: "", libraryId: "", modelKey: "" };
        let pinL = { pin: `${item.pin}_u`, node: "", subckt: "", libraryId: "", modelKey: "" };
        pairs = [...pairs, pinL, pinR];
      }
    });
    return pairs;
  }


  updatePairs = (number = null) => {
    let newPairs = [];
    const { pins } = this.props;
    const { pairs, files } = this.state;
    const num = number ? number : 1;
    pins.forEach(item => {
      for (let i = 0; i <= num; i++) {
        const pinName = i > 0 ? i === 1 ? `${item.pin}_u` : `${item.pin}_u_${i}` : item.pin;
        const pair = pairs.find(p => p.pin === pinName);
        newPairs.push(pair ? pair : { pin: pinName, node: "", subckt: "", libraryId: "", modelKey: "" });
      }
    });
    this.setState({
      pairs: newPairs,
      pinList: this.getPins(pairs, { number })
    }, () => {
      this.getFileParse(files, true);
    })
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  getFileParse = (files, isUpdate) => {
    if (!files || files.length === 0) {
      return;
    }
    //filter libraryId not exist files
    const _files = files.filter(item => !!item.libraryId);
    const { pairs, pinList, portsObj, subcktList, rank } = this.state;
    const { pins, autoSelectPorts, modelType } = this.props;
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let _pairs = [...pairs], _pinList = [...pinList];
    //get files ports
    this.getFilesParseList(files).then(res => {
      if (res && Array.isArray(res)) {
        _files.forEach(file => {
          const libraryId = file.libraryId;
          let selectedPorts = [], _ports = [];
          const currentFileInfo = res.find(it => it.libraryId === libraryId);
          if (file.type === TOUCHSTONE) {
            _ports = currentFileInfo && Array.isArray(currentFileInfo.ports) ? currentFileInfo.ports : [];
            if (isUpdate && modelType !== 'Connector') {
              // auto set pin node
              const info = autoSelectPorts({ pairs: _pairs, libraryId, pins, pinList: _pinList, ports: _ports, rank });
              _pairs = info.pairs;
              _pinList = info.pinList;
              selectedPorts = info.selectedPorts;
            } else {
              //find selected ports
              const selectedPairs = _pairs.filter(item => item.node && item.libraryId === libraryId);
              selectedPorts = selectedPairs.map(item => item.node);
            }
            selectedPorts = selectedPorts.filter(item => !!item);
            //update port select status
            _ports.forEach(item => {
              if (selectedPorts.includes(item.port)) {
                item.select = true;
              } else {
                item.select = false;
              }
            });
            //update file portsObj
            _portsObj[libraryId] = [{ fileName: file.fileName, type: TOUCHSTONE, ports: _ports }];
          } else if (file.type === SPICE) {
            const models = currentFileInfo && Array.isArray(currentFileInfo.models) ? [...currentFileInfo.models] : [];
            //update state subckt list
            const findIndex = _subcktList.findIndex(it => it.libraryId === libraryId);
            if (findIndex > -1) {
              _subcktList[findIndex] = {
                libraryId,
                fileName: file.fileName,
                models
              }
            } else {
              _subcktList.push({
                libraryId,
                fileName: file.fileName,
                models
              });
            }

            if (file.subckt) {
              const currentSubckt = models.find(item => item.name === file.subckt);
              let selectedPairs = pairs.filter(item => item.node && item.libraryId === libraryId && item.subckt === file.subckt);
              let selectedPorts = selectedPairs.map(item => item.node);
              //update ports
              if (currentSubckt && Array.isArray(currentSubckt.ports)) {
                currentSubckt.ports.forEach(item => {
                  _ports.push({
                    port: item,
                    info: item,
                    subckt: file.subckt,
                    select: selectedPorts.includes(item) ? true : false
                  })
                });
              }
              if (_portsObj[libraryId]) {
                //find current subckt ports
                const index = _portsObj[libraryId].findIndex(item => item.subckt === file.subckt);
                if (index > -1) {
                  //update current subckt ports
                  _portsObj[libraryId][index] = { fileName: file.fileName, type: SPICE, subckt: file.subckt, ports: _ports };
                } else {
                  //Add current subckt ports
                  _portsObj[libraryId] = [..._portsObj[libraryId], { fileName: file.fileName, type: SPICE, subckt: file.subckt, ports: _ports }];
                }
              } else {
                //Add current file and current subckt ports
                _portsObj[libraryId] = [{ fileName: file.fileName, type: SPICE, subckt: file.subckt, ports: _ports }];
              }
            }
          } else if (file.type === EBD) {
            _ports = currentFileInfo && currentFileInfo.ports ? JSON.parse(JSON.stringify(currentFileInfo.ports)) : [];
            _ports = findSelectedEBDPorts({ ports: _ports, pairs: _pairs, libraryId });
            const rankList = this.props.getEBDRankList(libraryId);
            if (isUpdate) {
              const info = autoSelectEBDPorts({ ports: _ports, pairs: _pairs, libraryId, pinList: _pinList, rankList });
              _pairs = info.pairs;
              _pinList = info.pinList;
              _ports = findSelectedEBDPorts({ ports: _ports, pairs: _pairs, libraryId });
            }
            if (_ports.length) {
              _portsObj[libraryId] = [{ fileName: file.fileName, type: EBD, ports: _ports }];
            }
          }
        });
        this.setState({
          pairs: _pairs,
          pinList: _pinList,
          portsObj: _portsObj,
          subcktList: _subcktList
        })
      }
    })
  }

  getFilesParseList = (files) => {
    const { getTouchstoneParse, getSPiceParse, getEBDParse } = this.props;
    const promiseList = files.map(item => {
      if (item.type === TOUCHSTONE) {
        return getTouchstoneParse(item.libraryId, item.fileName);
      } else if (item.type === SPICE) {
        return getSPiceParse(item.libraryId);
      } else if (item.type === EBD) {
        return getEBDParse(item.libraryId);
      }
      return null;
    });
    return Promise.all(promiseList);
  }

  selectNodes = ({ pinList, pairs, portsObj }) => {
    this.setState({
      pinList,
      pairs,
      portsObj
    })
  }

  addNewFileSelect = () => {
    const { files } = this.state;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    if (_files.length === 0) {
      //default one select
      _files.push(filesInfo());
    }
    //add new file select
    _files.push(filesInfo())
    this.setState({
      files: _files,
      errorMsg: null
    }, () => {
      this.props.setContentHeight(0);
    });
  }

  selectFile = (key, fileIndex) => {
    if (!key) {
      return;
    }
    const { key: id, label: name } = key;
    const { touchstoneList, spiceList, pkgType, ebdList } = this.props;
    const { files, portsObj, subcktList } = this.state;
    let delFile = fileIndex > -1 ? files[fileIndex] : null;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let newFile = null;
    if (pkgType === EBD) {
      const findE = ebdList.find(it => it.id === id);
      if (findE) {
        _portsObj[findE.id] = [];
        newFile = filesInfo(name, findE.id, "", EBD);
      }
    } else {
      const findT = touchstoneList.find(it => it.id === id);
      if (findT) {
        _portsObj[findT.id] = [];
        newFile = filesInfo(name, findT.id, "", TOUCHSTONE);
      } else {
        const findS = spiceList.find(it => it.id === id);
        if (findS) {
          if (!_portsObj[findS.id]) {
            _portsObj[findS.id] = [];
          }
          newFile = filesInfo(name, findS.id, "", SPICE);
        }
      }
    }
    if (!newFile) {
      return;
    }

    this.setNewFile(fileIndex, newFile, delFile, _files, _portsObj, _subcktList);
  }

  selectByFolder = (file, fileIndex) => {
    if (!file) {
      return;
    }
    const { files, portsObj, subcktList } = this.state;
    let delFile = fileIndex > -1 ? files[fileIndex] : null;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let newFile = null;
    const { id, name, type, version } = file;
    _portsObj[id] = [];
    newFile = type === 'pkg_spice' ? filesInfo(name, id, "", SPICE, version) : newFile = filesInfo(name, id, "", TOUCHSTONE, version);

    if (!newFile) {
      return;
    }

    this.setNewFile(fileIndex, newFile, delFile, _files, _portsObj, _subcktList);
  }

  setNewFile = (fileIndex, newFile, delFile, _files, _portsObj, _subcktList) => {
    const find = _files.find(item => (newFile.type === TOUCHSTONE || newFile.type === EBD) && item.libraryId === newFile.libraryId);
    if (find) {
      this.setState({
        errorMsg: "The current file has been selected."
      }, () => {
        //update pin node connect box top position
        this.props.setContentHeight(0);
      })
      return;
    }

    //Add new file to files
    if (fileIndex > -1) {
      _files[fileIndex] = newFile;
    } else {
      _files.push(newFile);
    }

    //delete prev select file portsObj and subcktList
    if (delFile && delFile.libraryId) {
      const findList = _files.filter(item => item.libraryId === delFile.libraryId);
      if (findList.length === 0) {
        //delete portsObj
        delete _portsObj[delFile.libraryId];
        //delete subckt
        _subcktList = _subcktList.filter(item => item.libraryId !== delFile.libraryId);
      } else {
        //delete current delete file current subckt ports
        _portsObj[delFile.libraryId] = delFile.subckt ? _portsObj[delFile.libraryId].filter(item => (item.subckt !== delFile.subckt)) : _portsObj[delFile.libraryId];
      }
    }
    this.setState({
      files: _files,
      portsObj: _portsObj,
      subcktList: _subcktList,
      errorMsg: null
    }, () => {
      this.clearPinNode({ delFile, newFile });
      this.props.setContentHeight(0);
    });
  }

  delFileSelect = (type, fileIndex, deleteLine) => {
    const { portsObj, files, subcktList } = this.state;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    if (fileIndex < 0) {
      return;
    }
    if (type !== "Subckt") {
      //delete file
      const delFile = _files.find((item, index) => index === fileIndex);

      if (delFile && delFile.libraryId) {
        const otherFiles = _files.filter((item, index) => index !== fileIndex && item.libraryId === delFile.libraryId);
        if (otherFiles.length === 0) {
          delete _portsObj[delFile.libraryId];
          _subcktList = _subcktList.filter(item => item.libraryId !== delFile.libraryId);
        } else if (delFile.subckt) {
          _portsObj[delFile.libraryId] = _portsObj[delFile.libraryId].filter(item => item.subckt !== delFile.subckt);
        }
      }
      if (deleteLine) {
        //delete current file line
        _files = _files.filter((item, index) => index !== fileIndex);
      } else {
        //delete current file
        _files[fileIndex] = filesInfo();
      }
      this.setState({
        files: _files,
        portsObj: _portsObj,
        subcktList: _subcktList,
        errorMsg: null
      }, () => {
        deleteLine && this.props.setContentHeight(0);;
        this.clearPinNode({ delFile });
      })
    } else {
      if (fileIndex < 0) {
        return;
      }
      //delete current file subckt
      const delFile = _files[fileIndex] ? { ..._files[fileIndex] } : null;
      const libraryId = _files[fileIndex].libraryId;
      _portsObj[libraryId] = _portsObj[libraryId].filter(item => item.subckt !== _files[fileIndex].subckt);
      _files[fileIndex].subckt = "";
      this.setState({
        files: _files,
        portsObj: _portsObj,
        errorMsg: null
      }, () => {
        this.clearPinNode({ delFile });
      })
    }
  }

  clearPinNode = (params) => {
    const { delFile, newFile } = params ? params : {};
    const { pairs, pinList } = this.state;
    let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : this.getPairs();
    for (let i = 0; i < _pairs.length; i++) {
      let item = _pairs[i];
      if (!item.node) {
        continue;
      }
      //param is null,clear all nodes
      if (!params) {
        item.node = "";
        item.libraryId = "";
        item.subckt = "";
      } else if (delFile) {
        if (delFile.libraryId === item.libraryId && (delFile.type === TOUCHSTONE || delFile.type === EBD || (item.subckt && delFile.subckt === item.subckt))) {
          //clear delFile selected nodes
          item.node = "";
          item.libraryId = "";
          item.subckt = "";
        }
      }
      _pairs[i] = item;
    }

    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : this.getPins();

    for (let i = 0; i < _pinList.length; i++) {
      let item = _pinList[i];
      if (!item.pinLeft && !item.pinRight && !item.pinLeft.pinValue && !item.pinRight.pinValue) {
        continue;
      }
      //param is null,clear all nodes
      if (!params) {
        item.pinRight.forEach(pinR => {
          //clear delFile selected nodes
          pinR.pinValue = "";
          pinR.pinLibraryId = "";
          pinR.pinSubckt = "";
        })
        item.pinLeft.forEach(pinL => {
          //clear delFile selected nodes
          pinL.pinValue = "";
          pinL.pinLibraryId = "";
          pinL.pinSubckt = "";
        })
      } else if (delFile) {
        if (item.pinRight) {
          item.pinRight.forEach(pinR => {
            if (delFile.libraryId === pinR.pinLibraryId && (delFile.type === TOUCHSTONE || delFile.type === EBD || (pinR.pinSubckt && delFile.subckt === pinR.pinSubckt))) {
              //clear delFile selected nodes
              pinR.pinValue = "";
              pinR.pinLibraryId = "";
              pinR.pinSubckt = "";
            }
          })
        }

        if (item.pinLeft) {
          item.pinLeft.forEach(pinL => {
            if (delFile.libraryId === pinL.pinLibraryId && (delFile.type === TOUCHSTONE || delFile.type === EBD || (pinL.pinSubckt && delFile.subckt === pinL.pinSubckt))) {
              //clear delFile selected nodes
              pinL.pinValue = "";
              pinL.pinLibraryId = "";
              pinL.pinSubckt = "";
            }
          })
        }
      }
      _pinList[i] = item;
    }
    this.setState({
      pairs: _pairs,
      pinList: _pinList
    }, () => {
      if (newFile && newFile.libraryId) {
        this.getFileParse([newFile], true);
      }
    })
  }

  selectSpiceSubckt = (key, fileIndex) => {
    if (!key) {
      return;
    }
    const { label: name } = key;
    const { subcktList, files, portsObj } = this.state;
    let _portsObj = { ...portsObj };
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let currentFile = fileIndex > -1 && _files[fileIndex] ? { ..._files[fileIndex] } : null;
    if (!currentFile) {
      return;
    }

    const libraryId = currentFile.libraryId, fileName = currentFile.fileName, prevSubckt = currentFile.subckt;
    const find = _files.find(item => item.libraryId === libraryId && item.subckt && item.subckt === name);
    if (find) {
      this.setState({
        errorMsg: "The current subckt has been selected."
      }, () => {
        //update pin node connect box top position
        this.props.setContentHeight(0);
      })
      return;
    }
    _files[fileIndex].subckt = name;
    // add current subckt ports to portsObj
    const findSubckt = subcktList.find(it => it.libraryId === libraryId);
    const models = findSubckt && findSubckt.models ? findSubckt.models : [];
    const subcktFind = models.find(it => it.name === name);
    const ports = subcktFind && Array.isArray(subcktFind.ports) ? subcktFind.ports : [];
    let _ports = [];
    ports.forEach(port => {
      _ports.push({
        port,
        info: port,
        subckt: name
      });
    });
    //filter prev subckt ports
    _portsObj[libraryId] = _portsObj[libraryId] ? _portsObj[libraryId].filter(it => (it.subckt !== name) && (it.subckt !== prevSubckt)) : [];
    //add current select subckt ports
    if (name) {
      _portsObj[libraryId] = [..._portsObj[libraryId], { fileName, type: SPICE, subckt: name, ports: _ports }];
    }

    this.setState({
      files: _files,
      portsObj: _portsObj,
      errorMsg: null
    }, () => {
      prevSubckt && this.clearPinNode({ delFile: { ...currentFile } });
      this.props.setContentHeight(0);
    })
  }

  switchRankSetting = (checked, e) => {
    let rank = null;
    if (checked) {
      rank = { number: "2", active: "1" };
      this.updatePairs(2);
    } else {
      this.updatePairs();
      this.setState({ errorMsg: "" });
    }
    this.setState({
      rank
    })
  }

  changeRankNumber = (e) => {
    const number = e.target.value;
    this.setState({
      rank: { ...this.state.rank, number }
    })
  }

  checkRankNumber = (e) => {
    const { rank } = this.state;
    const { number, active } = rank;
    let error = numberCheck(number);
    error = error ? error : Number(number) < 1 ? 'value must be greater than 0' : '';
    this.setState({
      errorMsg: error ? `Multi rank - rank number ${error}` : ''
    })
    if (error) {
      e.target.focus();
    } else {
      if (Number(active) > Number(number)) {
        this.setState({
          rank: { ...rank, active: "1" }
        })
      }
      this.updatePairs(Number(rank.number));
    }
  }

  changeRankActive = (value) => {
    const { rank } = this.state;
    if (value && Number(value) <= Number(rank.number)) {
      this.setState({
        rank: { ...rank, active: value }
      })
    }
  }

  automaticMatchPort = (fileInfo, type, automaticInfo) => {
    const { portsObj } = this.state;
    const { pins } = this.props;
    const { modelType } = automaticInfo;

    const { pairs: _pairs, files: _files } = getDefaultPortSetting({ portsObj, selectFileId: fileInfo.libraryId, currentModelInfo: automaticInfo, pins, type, selectFileInfo: fileInfo, signals: [], portModelType: modelType, direction: 'opposite' })
    const _pinList = this.getPins(_pairs)

    this.setState({
      pairs: _pairs,
      files: _files,
      pinList: _pinList,
    }, () => {
      this.props.setContentHeight(0);
    })
  }

  render() {
    const { files, pinList, pairs, portsObj, subcktList, errorMsg, top, rank } = this.state;
    const { record, modelType, displayType, spiceList, touchstoneList, panelHeight, scrollId, maxWidth, maxHeight, product, pkgType, ebdList } = this.props;
    const fileList = pkgType === EBD ? [...ebdList] : [...spiceList, ...touchstoneList];
    return (
      <NetListModel
        {...this.props}
        onRef={this.onRef}
        displayType={displayType}
        modelType={modelType}
        files={files}
        pinList={pinList}
        pairs={pairs}
        fileList={fileList}
        record={record}
        portsObj={portsObj}
        subcktList={subcktList}
        top={top}
        errorMsg={errorMsg}
        scrollId={scrollId}
        panelHeight={panelHeight}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        product={product}
        rank={rank}
        {...this.actions}
        {...this.rankActions}
      />
    )
  }

  actions = {
    _addNewFileSelect: this.addNewFileSelect,
    _selectFile: this.selectFile,
    _delFileSelect: this.delFileSelect,
    _clearPinNode: this.clearPinNode,
    _selectSpiceSubckt: this.selectSpiceSubckt,
    _selectNodes: this.selectNodes,
    _selectByFolder: this.selectByFolder,
    _automaticMatchPort: this.automaticMatchPort,
    getFileParse: this.getFileParse,
  }

  rankActions = {
    changeRankNumber: this.changeRankNumber,
    switchRankSetting: this.switchRankSetting,
    checkRankNumber: this.checkRankNumber,
    changeRankActive: this.changeRankActive
  }
}

export default SetPKGNodeModel;