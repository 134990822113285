const TYPE = 'ROCKY';
export const START_ROCKY_VERIFICATION = `${TYPE}/start_rocky_verification`;
export const CHANGE_VERIFICATION_LIST = `${TYPE}/change_verification_list`;
export const CLEAN_SIMULATION_REDUCER = `${TYPE}/clean_simulation_reducer`;
export const UPDATE_SIMULATION_REDUCER = `${TYPE}/update_simulation_reducer`;
export const UPDATE_CURRENT_SIMULATION_INFO = `${TYPE}/update_current_simulation_info`;
export const VERIFICATION_FLOW = `${TYPE}/verification_flow`;
export const CANCEL_VERIFICATION_WORKFLOW = `${TYPE}/cancel_verification_workflow`;
export const GET_CURRENT_MONITOR = `${TYPE}/get_current_monitor`;
export const GET_INTERFACE_MONITOR = `${TYPE}/get_interface_monitor`;
export const GET_VERIFICATION_LOG = `${TYPE}/get_verification_log`;
export const UPDATE_SIMULATION_MESSAGE = `${TYPE}/update_simulation_message`;
export const RECALCULATE_EYEDIAGRAM = `${TYPE}/recalculate_eyediagram`;
export const GET_CURRENT_PROFILE = `${TYPE}/get_current_profile`;
export const START_ROCKY_PACKAGE_VERIFICATION = `${TYPE}/start_rocky_package_verification`;
export const UPDATE_NOT_SET_CARD_VERIFICATIONS = `${TYPE}/update_not_set_card_verifications`;
export const START_ROCKY_PACKAGE_PDN = `${TYPE}/start_rocky_package_pdn`;
export const UPDATE_CURRENT_MULTI_SIMULATION_INFO = `${TYPE}/update_current_multi_simulation_info`;
export const START_ROCKY_PCB_CHANNEL = `${TYPE}/start_rocky_pcb_channel`;
export const START_SSN_SEPARATE_EXTRACTION = `${TYPE}/start_ssn_separate_extraction`;
export const GET_CURRENT_SSN_VERIFICATION_LOG = `${TYPE}/get_current_ssn_verification_log`;
export const START_PRE_LAYOUT_SIMULATION = `${TYPE}/start_pre_layout_simulation`;
