import React, { Component, Fragment } from 'react';
import { Checkbox, Select } from 'antd';
import { CircuitModelConfig, PARALLEL, SERIES, ADS_RX, ADS_TX } from '@/services/Andes_v2/AMIModelHelper';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import { SPICE, GENERIC_TOUCHSTONE } from '@/constants/libraryConstants';
import { getFileContent, getTouchstoneParse } from '@/services/Andes_v2/library';
import NetListModel from '@/components/NetListModel';
import { ANDES_V2 } from '../../../../constants/pageType';
import { parseSpiceModelSelector } from "@/services/LibraryHelper/SpiceModelHelper";
import { END_TO_END_CHANNEL } from '../../../../constants/treeConstants';
import "./index.css";


const Option = Select.Option;
const P_DEVICE = "P_DEVICE", P_CHANNEL = "P_CHANNEL", N_DEVICE = "N_DEVICE", N_CHANNEL = "N_CHANNEL";
class CircuitModelSetup extends Component {
  constructor(props) {
    super(props);
    const { modelInfo: { model, component, interfaceType, channelId } } = props;

    const _model = model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new CircuitModelConfig({
      interfaceType,
      component,
      channelId,
    });
    this.state = {
      applyAll: false,
      model: _model,
      topology: _model.topology,
      spiceList: []
    }
    this.selectModels = [];
    this.components = [];
    this.fileInfo = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.defaultModel();
  }

  componentDidUpdate = (prevProps) => {
    const { modelInfo: { signal, dirType } } = this.props;
    const prevSignal = prevProps.modelInfo ? prevProps.modelInfo.signal : null;
    const prevDirType = prevProps.modelInfo ? prevProps.modelInfo.dirType : null;

    if ((prevSignal !== signal) || (dirType !== prevDirType)) {
      this.defaultModel();
    }
  }

  closeModal = () => {
    const { applyAll, topology } = this.state;
    const { modelInfo: { dirType, signal, component, channelId, interfaceType } } = this.props;

    let _model = {};
    if (this.modelChild) {
      _model = this.modelChild.getNetListModel();
    }
    delete _model.version;
    _model.component = component;
    if (interfaceType === END_TO_END_CHANNEL) {
      _model.channelId = channelId;
    }
    _model.topology = topology;
    this.props.closeModal({ model: _model, signal, dirType, applyAll, type: 'circuit' });
  }

  defaultModel = () => {
    const { modelInfo: { model, component, channelId, interfaceType } } = this.props;
    this.fileInfo = {};

    const spiceList = libraryConstructor.getLibraryValues(SPICE) || [];
    const touchstoneList = libraryConstructor.getLibraryValues(GENERIC_TOUCHSTONE) || [];
    const _model = model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new CircuitModelConfig({
      interfaceType,
      component,
      channelId,
    });
    this.setState({
      model: _model,
      topology: _model.topology,
      spiceList,
      touchstoneList
    })
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  applyChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  saveModel = (model) => {
    this.setState({
      model
    })
  }

  updateError = (error) => {
    this.setState({
      error
    })
  }

  topologyChange = (key = "") => {
    let type = 'SPICE'
    if (this.modelChild) {
      const _model = this.modelChild.getNetListModel();
      type = _model.type
    }
    this.setState({
      model: { ...this.state.model, topology: key.toLowerCase(), pairs: [], type },
      topology: key.toLowerCase()
    })
  }

  getTopologySelection = () => {
    const { topology } = this.state;
    return <div className={`ads-model-select-item ads-circuit-model-topology-selection`}>
      <span>Topology</span>
      <Select
        value={topology === PARALLEL ? "Parallel" : (topology === SERIES ? "Series" : "")}
        onChange={(key) => this.topologyChange(key)}
        popupMatchSelectWidth={false}
        className={`aurora-select`}
        popupClassName="aurora-select-dropdown"
        getPopupContainer={() => document.getElementById('root')}
      >
        {["Parallel", "Series"].map(item => <Option
          key={item}
          value={item}
          title={item}
        >{item}</Option>)}
      </Select>
    </div>
  }

  getPinListByPairs = (pairs) => {
    const { modelInfo } = this.props;
    const { pins, pinAndNets, dirType } = modelInfo;
    let pinLeft = [], pinRight = [];
    for (let pin of pins || []) {
      let findIN = null,
        findOUT = null;
      //P_DEVICE,N_DEVICE,P_CHANNEL,N_CHANNEL
      let net = null, positionLeft, positionRight;
      if (pinAndNets && pinAndNets.positive && pinAndNets.positive.pin === pin) {
        net = pinAndNets.positive.net;
        positionLeft = dirType === ADS_TX ? P_DEVICE : P_CHANNEL;
        positionRight = dirType === ADS_TX ? P_CHANNEL : P_DEVICE;
        findIN = pairs.find(item => item.pin === pin && item.position === positionLeft) || {};
        findOUT = pairs.find(item => item.pin === pin && item.position === positionRight) || {};
      };
      if (pinAndNets && pinAndNets.negative && pinAndNets.negative.pin === pin) {
        net = pinAndNets.negative.net;
        positionLeft = dirType === ADS_TX ? N_DEVICE : N_CHANNEL;
        positionRight = dirType === ADS_TX ? N_CHANNEL : N_DEVICE;
        findIN = pairs.find(item => item.pin === pin && item.position === positionLeft) || {};
        findOUT = pairs.find(item => item.pin === pin && item.position === positionRight) || {};
      };

      pinLeft.push({
        pin: pin,
        pinValue: findIN.node,
        nodeIndex: findIN.index,
        position: positionLeft,
        net: dirType === ADS_TX ? net : null
      });
      pinRight.push({
        pin: pin,
        pinValue: findOUT.node,
        nodeIndex: findOUT.index,
        position: positionRight,
        net: dirType === ADS_RX ? net : null
      })
    }
    return [{
      component: modelInfo.component,
      pinLeft,
      pinRight
    }]
  }

  getInitPairs = () => {
    const { modelInfo } = this.props;
    const { pins, pinAndNets } = modelInfo;
    let pairs = []
    for (let pin of pins || []) {
      //P_DEVICE,N_DEVICE,P_CHANNEL,N_CHANNEL
      if (pinAndNets && pinAndNets.positive && pinAndNets.positive.pin === pin) {
        pairs.push({
          pin,
          node: '',
          position: P_DEVICE
        },
          {
            pin,
            node: '',
            position: P_CHANNEL
          })
      };
      if (pinAndNets && pinAndNets.negative && pinAndNets.negative.pin === pin) {
        pairs.push({
          pin,
          node: '',
          position: N_DEVICE
        },
          {
            pin,
            node: '',
            position: N_CHANNEL
          })
      };
    }
    return pairs;
  }

  render() {
    const { modelInfo } = this.props;
    const { dirType, pins } = modelInfo
    const { model, applyAll, error, spiceList, topology, touchstoneList } = this.state;
    return <Fragment>
      <NetListModel
        onRef={this.onRef}
        pins={pins}
        spiceList={spiceList}
        touchstoneList={touchstoneList}
        product={ANDES_V2}
        modelType="Circuit"
        model={{ ...model }}
        dirType={dirType}
        getFileContent={getFileContent}
        parseModelSelector={parseSpiceModelSelector}
        getTouchstoneParse={getTouchstoneParse}
        getPinListByPairs={this.getPinListByPairs}
        getInitPairs={this.getInitPairs}
        displayModel={topology === PARALLEL ? "table" : "schematic"}>
      </NetListModel>
      {this.getTopologySelection()}
      {error ? <span className={error.errorType === "warning" ? "aurora-model-name-warning-msg" : "aurora-error-msg-span"}>{error.error}</span> : null}
      {<div className="ads-model-apply-all-content ads-circuit-model-apply-all-content">
        <span>Apply setting to all {dirType} Circuit Model</span>
        <Checkbox
          checked={applyAll}
          onChange={(e) => this.applyChange(e)}
        />
      </div>}
    </Fragment>
  }
}


export default CircuitModelSetup;