import React, { Component } from 'react';
import { Input, TreeSelect, Button, Tooltip } from 'antd';
import UnitAddonAfter from '../../../../../components/UnitAddonAfter';
import { numberCheck } from '../../../../../services/helper/dataProcess';
import { percentIndex, percentOutIndex } from '../../../../../services/Sierra/helper/measurementHelper';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import "./index.css";

function getTreeData(experiments) {
  return [{
    title: 'All',
    value: 'all',
    key: 'all',
    children: experiments.map(item => ({ title: item.name, value: item.id, key: item.id }))
  }]
}
class SettingFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  getLabel = (key) => {
    switch (key) {
      case "voutl":
        return "VOL";
      case "vouth":
        return "VOH";
      case "vinl":
        return "VIL";
      case "vinh":
        return "VIH";
      default: return key;
    }
  }

  settingApplyClose = () => {
    this.props.updateSettingApply(false);
  }

  saveSetting = (e, itemKey) => {
    const { applyAllList } = this.props;

    let value = e.target.value;
    const error = numberCheck(value);
    if (error) {
      return null;
    }
    this.updateDataBySetting(applyAllList, value, itemKey);
  }

  saveDataBySetting = ({ data, key, perValue, newValue }) => {
    let update = false;
    for (let item of (data || [])) {

      if ((percentOutIndex.includes(key) && (!item.usage || item.usage === "Receiver"))
        || (percentIndex.includes(key) && item.usage === "Driver")) {
        continue;
      }

      const _key = key.replace("out", "in");

      let prevValue = item[_key];
      if (perValue) {
        const vdd = item['vdd'];
        item[_key] = !isNaN(vdd) ? Number((Number(perValue) / 100 * vdd).toFixed(4)) : item[_key];
        item[`${_key}_per`] = !isNaN(vdd) ? perValue : item[`${_key}_per`];
      } else {
        item[_key] = newValue;
        delete item[`${_key}_per`];
      }
      if (item[_key] !== prevValue) {
        update = true;
      }
    }
    return { data, update };
  }

  updateDataBySetting = (applyAllList, itemValue, itemKey) => {
    const { tableData, setting, type, isSweep } = this.props;
    let _tableData = [...tableData];

    const updateList = itemKey ? [itemKey] : ["vouth", "voutl", "vinl", "vinh"];
    for (let key of updateList) {
      let value = itemKey ? itemValue : setting[key],
        unit = setting[`${key}Unit`];

      const error = numberCheck(value);
      if (error) {
        continue;
      }
      let newValue = null, perValue = null;
      if (unit === '%') {
        perValue = Number(value);
      } else {
        newValue = Number(value);
        perValue = null;
      }

      if (type === "result" && isSweep) {
        for (let item of _tableData) {
          if (!applyAllList.includes(item.id) && !applyAllList.includes("all")) {
            continue
          }
          const { data, update: _update } = this.saveDataBySetting({ data: item.measurementInfo, key, perValue, newValue });
          item.measurementInfo = data;
          item.isUpdate = _update ? _update : item.isUpdate;
        }
      } else if (_tableData && _tableData[0]) {
        const { data, update: _update } = this.saveDataBySetting({ data: _tableData[0].measurementInfo, key, perValue, newValue });
        _tableData[0].measurementInfo = data;
        _tableData[0].isUpdate = _update ? _update : _tableData[0].isUpdate;
      }
    }

    this.props.updateTableData(_tableData);
  }

  /*  saveSetting = () => {
     this.props.updateSettingApply(false);
   
     const { tableData, setting } = this.props;
     let _tableData = [...tableData];
   
     const valueList = [...percentIndex, ...percentOutIndex].map(item => {
       let value = setting[item], unit = setting[`${item}Unit`];
       const error = numberCheck(value);
       if (error) {
         return null;
       }
       let newValue = null, perValue = null;
       if (unit === '%') {
         perValue = Number(value);
       } else {
         newValue = Number(value);
         perValue = null;
       }
       return {
         key: item,
         value: newValue,
         perValue
       }
     }).filter(item => !!item)
   
     _tableData.forEach(item => {
       const _valueList = item.usage === "Driver" ? valueList.filter(it => percentOutIndex.includes(it.key)) : valueList.filter(it => percentIndex.includes(it.key));
       for (let valueItem of _valueList) {
         const key = percentOutIndex.includes(valueItem.key) ? valueItem.key.replace("out", "in") : valueItem.key;
         if (valueItem.perValue) {
           const vdd = item['vdd'];
           item[key] = !isNaN(vdd) ? Number((Number(valueItem.perValue) / 100 * vdd).toFixed(4)) : item[key];
           item[`${key}_per`] = !isNaN(vdd) ? valueItem.perValue : item[`${key}_per`];
         } else {
           item[key] = valueItem.value;
         }
       }
     })
     this.props.updateTableData(_tableData, valueList.length ? true : false);
   } */

  getApplyDisabled = (setting, applyAllList, applying) => {
    if (!applying && ["vouth", "voutl", "vinl", "vinh"].find(item => !!setting[item]) && applyAllList.length) {
      return false
    }
    return true;
  }

  changeApplyAll = (values) => {
    const { applyAllList } = this.props;
    const newValues = values.filter(item => !applyAllList.includes(item));
    this.props.changeApplyAll(values)
    if (newValues.length) {
      this.updateDataBySetting(newValues);
    }
  }

  render = () => {
    const { setting, isSweep, experiments, applyAllList, type, applying, showMsg } = this.props;

    return <div className='measurement-footer-apply-content' style={{ height: isSweep ? 62 : 28 }}>
      {[...percentIndex, ...percentOutIndex].map((item, index) => (
        <div className={`measurement-footer-item ${index !== 0 ? "measurement-footer-padding-left-item" : ""}`} key={item}>
          <label>{this.getLabel(item)}</label>
          <Input
            value={setting[item] || ""}
            onChange={(e) => this.props.changeSettingInput(e, item)}
            onBlur={(e) => this.saveSetting(e, item)}
            className="aurora-input"
            addonAfter={UnitAddonAfter({
              unit: setting[`${item}Unit`],
              changeUnit: (key) => this.props.changeSettingUnit(key, item),
              list: [{ key: "V", title: 'V' }, { key: "%", title: '% VDD' }],
              className: "measurement-footer-item-unit-select"
            })}
          />
        </div>))}
      {/*  <div className='measurement-footer-item-button'>
        <Button
          onClick={() => this.saveSetting()}
          size="small"
        >Save</Button>
        <Button
          onClick={() => this.settingApplyClose(false)}
          size="small"
        >Cancel</Button>
      </div> */}
      {isSweep ? <div className={type === "result" ? "measurement-footer-apply-all-content measurement-result-footer-apply-all-content" : "measurement-footer-apply-all-content"}>
        <span className='measurement-footer-apply-all-title'>{type !== "result" ? "Apply" : "Set"} VIH, VIL, VOH, VOL condition to</span>
        {showMsg && isSweep && type === "result" ? <Tooltip
          title="Conditions will only be set once the experiments have been selected.">
          <ExclamationCircleOutlined className='measurement-footer-sweep-warning-icon' />
        </Tooltip> : null}
        <TreeSelect
          treeData={getTreeData(experiments || [])}
          value={applyAllList}
          onChange={this.changeApplyAll}
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          placeholder='Select Experiments'
          className='aurora-select'
          getPopupContainer={() => document.getElementById('root')}
          popupClassName='aurora-select-dropdown'
        />
        {type !== "result" ? <Button
          onClick={this.props.applyAllUpdate}
          size="small"
          disabled={this.getApplyDisabled(setting, applyAllList, applying)}
        >Apply</Button> : null}
      </div> : null}
    </div>
  }
}

export default SettingFooter;