import LayoutCanvas from './Canvas';

class Layouts {
  constructor() {
    this.layouts = new Map(); // key - designId, value - canvas
  }

  getLayout = (designID) => {
    return this.layouts.get(designID);
  }

  createLayout = (designID) => {
    const newLayout = new LayoutCanvas();
    this.layouts.set(designID, newLayout);
    return this.getLayout(designID);
  }

  clearLayout = (designID) => {
    this.layouts.delete(designID);
  }
}

const layouts = new Layouts();
export default layouts;