import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import LayoutLR from './layoutLR';
import LayoutTB from './layoutTB';
import LayoutContent from './layoutContent';
import { changeView, changeViewList, showResult } from '../store/project/action';
import { closeTabFooter } from '../../tabMonitor/action';
import MonitorChild from './Monitor';
import { getCCCVerifications, getCCCSpecifyServiceVerification } from '../../../services/CCC/cccHelper';

class AndesContent extends PureComponent {

  getLayout = () => {
    const { layout, leftWidth, view } = this.props;
    const { viewList } = this.props;
    if (viewList.length > 1 && layout === 1) {
      return <LayoutLR leftWidth={leftWidth} viewList={viewList} monitorChild={this.monitorChild} />;
    } else if (viewList.length > 1 && layout === 2) {
      return <LayoutTB leftWidth={leftWidth} viewList={viewList} monitorChild={this.monitorChild} />
    } else if (view && viewList.length === 1 && viewList.includes(view)) {
      return <LayoutContent leftWidth={leftWidth} monitorChild={this.monitorChild} />
    } else if (!view || viewList.length === 0) {
      return <LayoutContent leftWidth={leftWidth} monitorChild={this.monitorChild} />
    } else {
      return null
    }
  }


  monitorChild = (width) => {
    const { menuType, tabVisible, tabSelectKeys, currentProjectId, currentProjectVerifications, verificationId } = this.props;
    return MonitorChild({
      menuType,
      tabVisible,
      tabSelectKeys,
      projectId: currentProjectId,
      getCCCVerifications: () => getCCCVerifications(currentProjectVerifications),
      getCurrentVerification: () => getCCCSpecifyServiceVerification(verificationId, currentProjectVerifications),
      width
    })
  }

  render() {
    return (
      <Fragment>
        {this.getLayout()}
      </Fragment>
    )
  }

}

const mapState = (state) => {
  const { AndesReducer } = state;
  const { project: { layout, view, designID, verificationId, viewList, currentProjectId, currentProjectVerifications } } = AndesReducer;
  const { tabVisible, tabSelectKeys, menuType } = state.TabMonitorReducer;
  return {
    layout,
    view,
    designID,
    verificationId,
    viewList,
    tabVisible,
    tabSelectKeys,
    menuType,
    currentProjectId,
    currentProjectVerifications
  };
}

const mapDispatch = (dispatch) => ({
  viewChange(view, info) {
    dispatch(changeView(view, info))
  },
  closeTabFooter() {
    dispatch(closeTabFooter());
  },
  showResult(view, info) {
    dispatch(showResult(view, info));
  },
  changeViewList(viewList) {
    dispatch(changeViewList(viewList))
  }
})

export default connect(mapState, mapDispatch)(AndesContent);