export const NULL = 0;
export const AURORA_AAF = 1; // *.layout, *.part
export const AURORA_DB = 2; // layers, layout.db, parts.db
export const CADENCE_BRD = 3; // *.brd
export const ALTIUM = 4; // .PcbDoc
export const ANSYS = 5; // ANF(v2) aurora flow *anf, *cmp, vendor is 5, transSwitch is "false"
export const ANSYS_EDB_FLOW = 50;  // ANF(V2,V4), current support v2, not support v4, vendor is 5, transSwitch is "true"
export const ODB = 6;
export const CADENCE_MCM = 7; // .mcm
export const ALLEGRO_ALG = 8; // .alg
export const PRE_LAYOUT = 9;//Pre layout
export const AEDB = 10;
export const ANSYS_V4 = 11;
export const ODB_PP = 12;
export const AURORA_AAF_DB = 13;
export const SPD = 14; // .spd
export const CADENCE_SIP = 15; // .sip