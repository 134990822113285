import NP from 'number-precision';
import { numberCheck } from '../../helper/dataProcess';

class PowerSumTDRSetting {
  constructor(channelId) {
    this.channelId = channelId;

    this.doTdr = false;
    this.dataRate = "";
    this.tdr_duration_ratio = "1";
    this.tdr_delay = "0";
    this.tdr_risetime = "25ps";

    this.generate_powersum = false;

    this.powersum_frequencies = [];
    this.libraryIds = [];
    this.powerSumTargetLine = {};

    this.getRange = this.getRange.bind(this);
  }

  getRange(type) {
    switch (type) {
      case 'dataRate':
        return [0.001, 8000]; // 1Mbps ~ 1TBps, but must be smaller than the maximum extraction frequency, unit GHz
      case 'duration_ratio':
        return [0, 100]; // value range: (0~100], default = 20
      case 'risetime':  // tdr_risetime | sbr_risetime
        return [0, Infinity]; // value range: > 0 ps, default = 25ps
      case 'delay':
        return [0, 1000]; // value range: 0~1000 ps, default = 0
      default: return [];
    }
  }

  getDisplayNameByType(type) {
    switch (type) {
      case 'dataRate':
        return 'data rate';
      case 'duration_ratio':
        return 'duration';
      case 'risetime':
        return 'rise time';
      default: return;
    }
  }

  // dataRate < fmax/2
  checkRange(type, value, maxFreq) {
    const name = this.getDisplayNameByType(type);
    //number check
    const numError = numberCheck(value);
    if (numError) {
      return `The ${name} ${numError}`;
    }
    const range = this.getRange(type);
    let from = range[0], to = range[1], _maxFreq = maxFreq;

    if (type === 'dataRate' && maxFreq !== -1) {
      _maxFreq = maxFreqHzToGHz(maxFreq);
      to = _maxFreq / 2;
    }

    if (type === "duration_ratio" && parseFloat(value) === from) {
      return `Please set the ${name} value greater than 0.`
    }

    if (value < from || value > to) {

      if (type === 'dataRate') {
        to = to ? to + "Gbps" : '1TBps';

        return value < from
          ? `The data rate value must be greater than 1Mbps.`
          : `The maximum extraction frequency is ${_maxFreq}GHz. Please set the data rate to be smaller or equal to ${to} (fmax/2).`
      }

      if (type === 'risetime') {
        return `Please set the ${name} value greater than 0.`
      }
      return `Please set the ${name} value from ${from} to ${to}.`
    } else {
      return false;
    }
  }

  updateData(data) {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    })
  }

  getSetting(dataRateUnit, generate_powersum, doTdr) {
    return {
      dataRate: this.dataRate.includes('bps') ? this.dataRate : this.dataRate + dataRateUnit,
      tdr_duration_ratio: Number(this.tdr_duration_ratio),
      tdr_delay: this.tdr_delay,
      tdr_risetime: this.tdr_risetime.includes('ps') ? this.tdr_risetime : this.tdr_risetime + 'ps',
      powersum_frequencies: this.powersum_frequencies,
      powerSumTargetLine: this.powerSumTargetLine,
      libraryIds: this.libraryIds,
      generate_powersum: generate_powersum || false,
      doTdr: doTdr || false
    }
  }

  updateSettingData(delayData, dataRate) {
    const _dateRate = getDataRate(dataRate);

    const { tdr_risetime, riseTime } = getRisetimeByDataRate(_dateRate);
    //update rise time
    this.tdr_risetime = tdr_risetime;

    //update duration ratio
    const { tdr_duration_ratio } = getMaxDelayUI(delayData, _dateRate, riseTime);
    this.tdr_duration_ratio = tdr_duration_ratio;
  }
}

function maxFreqHzToGHz(value) {
  return Number(value) / 1e9;
}

const keys = [{
  key: 'dataRate',
  name: 'Data Rate'
}, {
  key: 'risetime',
  name: 'Rise Time'
}, {
  key: 'delay',
  name: 'Delay'
}, {
  key: `duration_ratio`,
  name: 'Duration'
}];

function getMaxDiffDelay(delayData) {
  if (delayData && delayData.dataList && delayData.dataList.length) {
    const diffDelayList = delayData.dataList.map(item => Number(item.diff_delay));
    //unit ps to s
    return Math.max(...diffDelayList) * 1e-12;
  }
  return 0;
}

function getMaxDelayUI(delayData, dataRate, risetime) {
  const channelMaxDiffDelay = getMaxDiffDelay(delayData);
  const _risetime = risetime ? risetime : 25;
  const tdr_duration_ratio = Math.round((2 * channelMaxDiffDelay + 0 + _risetime * 1e-12) * dataRate + 3);
  return {
    tdr_duration_ratio: tdr_duration_ratio < 20 ? 20 : tdr_duration_ratio
  };
}

function round(number, precision) {
  return Math.round(+number + 'e' + precision) / Math.pow(10, precision);
}

function getRisetimeByDataRate(dataRate) {
  // ui = 1 / dataRate
  const ui = NP.divide(1, dataRate);
  //risetime = 1/3 * ui ,  unit is s
  let riseTime = NP.divide(ui, 3);

  //s -> ps 
  riseTime = NP.times(riseTime, 1e12);

  // if rise time less than 5ps,Round to 1 decimal places.
  if (riseTime < 5) {
    riseTime = round(riseTime, 1);
  } else {
    riseTime = Math.round(riseTime);
  }

  if (riseTime <= 0) {
    riseTime = 0.1;
  }

  const _riseTime = `${riseTime}ps`;
  return {
    tdr_risetime: _riseTime,
    riseTime: riseTime
  };
}


function getDataRate(dataRate) {
  const match = dataRate.match(/(M|G|T)bps/g);
  const dataRateUnit = match ? match[0] : 'Gbps';
  const _dateRate = valueMatch(dataRate);
  let scale = 1e9;
  switch (dataRateUnit) {
    case "Gbps":
      scale = 1e9; break;
    case "Mbps":
      scale = 1e6; break;
    case "Tbps":
      scale = 1e12; break;
    default: scale = 1e9; break;
  }
  return NP.times(scale, parseFloat(_dateRate));
}

function valueMatch(value) {
  const m = value.toString().match(/^[0-9]\d*(\.\d+)?e(-|\+)?\d+|^[0-9]\d*(\.\d+)?/);
  return m ? m[0] : ''
}

function maxFreqHzToMHz(value) {
  return Number(value) / 1e6;
}

export default PowerSumTDRSetting;
export {
  valueMatch,
  keys,
  maxFreqHzToGHz,
  maxFreqHzToMHz
}
