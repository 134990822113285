import { selectAll } from 'd3-selection';
import CanvasObject from './CanvasObject';

const CanvasPath = function () {
  CanvasObject.call(this);
  this.pathData = "";
};

CanvasPath.prototype = Object.create(CanvasObject.prototype);
CanvasPath.prototype.constructor = CanvasPath;

/** This method is provided to draw a outline object without fill. Regular object drawing
 *  should be using the CanvasObjectList class.
 */
CanvasPath.prototype.drawSVG = function (svgElement, attributes) {

  this.element = svgElement.append('path')
    .attr('class', this.width ? null : 'lay_geom')
    .attr('d', this.pathData)
    .on('click', function () {
      //remove
      selectAll(`g.port-setup-canvas-pin-g`).remove();
    });

  if (attributes) {
    for (let key in attributes) {
      this.element.attr(key, attributes[key]);
    }
  }
  // .on('click', function() {
  // 	if (d3.event.defaultPrevented) {
  // 		return;
  // 	}
  //     d3.event.stopPropagation();
  // 	$rootScope.$broadcast('canvasObjClicked', self);
  // });


  if (this.toolTip) {
    //todo
    // outlineElement.append('title').text(self.toolTip);
  }

}; // CanvasPath.prototype.drawSVG

// the stroke width of an outline do not scale with zoom, and needs to be adjusted accordingly
CanvasPath.prototype.setStroke = function (strokeWidth, color) {
  this.element && this.element.attr('stroke-width', strokeWidth).attr('stroke', color);
};

export default CanvasPath;