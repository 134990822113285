import { call, put, takeEvery, fork, select } from 'redux-saga/effects';
import {
  CREATE_USER,
  RELOAD_USER_TABLE,
  INIT_USER_TABLE,
  CHANGE_MENU,
  JUMP_TO_ADMIN_PAGE,
  CREATE_ROLE,
  GET_ROLE_PERM_LIST
} from './actionTypes';
import { getUsers, createUserWithRole } from '@/services/api/users';
import { getPermissions, createRole, getRoles } from '@/services/api/v2/roles';
import { SUCCESS, CHECK_FAIL } from '@/constants/returnCode';
import {
  reloadUserTableAction,
  closeCreateUserModal,
  creatUserErrorAction,
  initUserSuccessAction,
  changeTableSuccessAction,
  jumpToPageSuccessAction,
  createUserRoleFailed,
  closeCreateRoleModal,
  saveRoleListAction,
  savePermissionListAction,
  clearTableAction
} from './actionCreators';
import { isAuthed } from '@/services/api/auth';
import { USER_LOGOUT } from '../Login/actionTypes';
import { message } from 'antd';
import checkError from '@/services/api/checkError';
import { getUserRoleListPromise } from '../../services/userCtrl';

function* userCreate(action) {
  try {
    const { email, name, password, region, roles } = action;
    const data = {
      email,
      name,
      password,
      region: region ? region : '',
      roleIds: roles ? roles : ["003"] // 003 === OPERATOR
    }
    const { reducer: { pagination: { total } } } = yield select();
    const response = (yield call(createUserWithRole, data)).data;
    if (response.code === SUCCESS) {
      message.success('Create user successfully!', 2);
      const page = Math.ceil((parseInt(total) + 1) / 10) - 1;
      const reload = reloadUserTableAction(page);
      const closeModal = closeCreateUserModal();
      yield put(reload);
      yield put(closeModal);
    } else if (response.code === CHECK_FAIL) {
      const createUserError = creatUserErrorAction(response.msg);
      yield put(createUserError);
      message.error('Create user failed!', 2);
    } else {
      message.error('Create user failed!', 2);
      console.error(response.msg)
    }
  } catch (error) {
    message.error('Create user failed!', 2);
    if (checkError(error) !== 401) {
      console.error(error);
    }
  }
}

function* reloadUserTable(action) {
  const { page } = action;
  yield put({ type: INIT_USER_TABLE, page });
}

function* initUserTable(action) {
  try {
    if (!isAuthed) {
      yield put({ type: USER_LOGOUT });
    }
    const { reducer: { menu } } = yield select();
    const { page } = action;
    const _page = page || 0;
    let response = null
    if (menu === 'user') {
      response = yield call(getUsers, _page, 10);
    } else if (menu === 'role') {
      response = yield call(getRoles, _page, 10);
    }
    if (response) {
      const { data } = response;
      if (data.code === SUCCESS) {
        const action = initUserSuccessAction(data.data, _page + 1);
        yield put(action);
      } else {
        // TODO init user failed
      }
    }
  } catch (error) {
    if (checkError(error) !== 401) {
      console.error(error);
    }
  }
}

function* changeTableInfo(action) {
  try {
    const { menu } = action;
    if (!menu || !['user', 'role'].includes(menu)) {
      return;
    }
    yield put(clearTableAction());
    let responseData;
    switch (menu) {
      case 'user':
        responseData = (yield call(getUsers, 0, 10)).data
        break;
      case 'role':
        responseData = (yield call(getRoles, 0, 10)).data
        break;
      default: break;
    }
    if (responseData.code === SUCCESS) {
      const changeTableSuccess = changeTableSuccessAction(responseData.data);
      yield put(changeTableSuccess);
    } else {
      console.error(responseData.msg)
    }
  } catch (error) {
    if (checkError(error) !== 401) {
      console.error(error);
    }
  }
}

function* jumpToPage(action) {
  try {
    const { page, menu } = action;
    let responseData;
    switch (menu) {
      case 'user':
        responseData = (yield call(getUsers, page - 1, 10)).data
        break;
      case 'role':
        responseData = (yield call(getRoles, page - 1, 10)).data
        break;
      default: break;
    }
    if (responseData.code === SUCCESS) {
      const jumpToPageSuccess = jumpToPageSuccessAction(responseData.data, page);
      yield put(jumpToPageSuccess);
    } else {
      console.error(responseData.msg)
    }
  } catch (error) {
    if (checkError(error) !== 401) {
      console.error(error);
    }
  }
}

function* roleCreate(action) {
  try {
    const { value } = action;
    const { name, permissions } = value;
    const description = value.description ? value.description : '';
    const data = {
      name, permissions, description
    }
    const { reducer: { pagination: { total } } } = yield select();
    const responseData = (yield call(createRole, data)).data;
    if (responseData.code === SUCCESS) {
      const page = Math.ceil((parseInt(total) + 1) / 10);
      yield call(jumpToPage, { page, menu: 'role' });
      yield put(closeCreateRoleModal());
      yield fork(getRoleList);
      message.success('Create role successfully !', 2);
    } else if (responseData.code === CHECK_FAIL) {
      yield put(createUserRoleFailed(responseData.msg));
      message.error('Create role failed !', 2);
    } else {
      // responseData.msg
      message.error('Create role failed !', 2);
      console.error(responseData.msg);
    }
  } catch (error) {
    message.error('Create role failed !', 2);
    if (checkError(error) !== 401) {
      console.error(error);
    }
  }
}

function* getRoleAndPermissionList() {
  yield fork(getRoleList);
  yield fork(getPermissionList);
}

function* getRoleList() {
  try {
    const resRole = yield call(getUserRoleListPromise);
    yield put(saveRoleListAction(resRole));
  } catch (error) {
    console.error(error);
  }
}

function* getPermissionList() {
  try {
    const resPerm = yield call(getPermissions);
    if (resPerm.data.code === SUCCESS) {
      yield put(savePermissionListAction(resPerm.data.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* mySaga() {
  yield takeEvery(INIT_USER_TABLE, initUserTable);
  yield takeEvery(RELOAD_USER_TABLE, reloadUserTable);
  yield takeEvery(CHANGE_MENU, changeTableInfo);
  yield takeEvery(JUMP_TO_ADMIN_PAGE, jumpToPage);
  yield takeEvery(CREATE_USER, userCreate);
  yield takeEvery(CREATE_ROLE, roleCreate);
  yield takeEvery(GET_ROLE_PERM_LIST, getRoleAndPermissionList);
}

export default mySaga;