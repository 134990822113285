import React, { Component, Fragment, createRef } from "react";
import { connect } from 'react-redux';
import { DC_PLOT_BOTTOM, DC_PLOT_ROW_HEIGHT } from "../../../services/Cascade/prelayout";
import Plot from "../../../services/Cascade/prelayout/plot";
import DcInfo from "./dcInfoPanel";
import { updateDcComponents, updateDcNets } from "../store/prelayout/action";
import { checkNameFormat } from "../../../services/helper/nameFormatCheck";
import './index.css';

class DcPlot extends Component {

  constructor() {
    super();
    this.state = {
      height: DC_PLOT_ROW_HEIGHT,
      maxRow: 1,
      drawerVisible: false,
      drawKey: '',
      drawInfo: {}
    }
    this.svgRef = createRef();
  }

  componentDidMount() {
    this.getCanvansWidth();
    this.getPlotHeight();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate = (prevProps) => {
    const { components, id } = this.props;
    const { maxRow } = this.state;
    const _maxRow = Math.max(...components.map(item => Number(item.row)));
    if (maxRow !== _maxRow || id !== prevProps.id) {
      this.getPlotHeight()
    }
  }

  resize = () => {
    this.getPlotHeight()
  }

  getCanvansWidth = () => {
  }

  getPlotHeight = () => {
    const { components } = this.props;
    const maxRow = Math.max(...components.map(item => Number(item.row)));
    this.setState({
      height: (maxRow + 1) * DC_PLOT_ROW_HEIGHT + DC_PLOT_BOTTOM,
      maxRow
    }, () => {
      this.draw()
    })
  }

  draw = () => {
    if (!this.canvas) {
      this.canvas = new Plot({ svg: this.svgRef.current, events: { click: this.clickEle } })
    }

    const { components, nets } = this.props;
    // this.canvas.drawGrid();
    this.canvas.drawNets(nets, components);
    this.canvas.drawComps(components);
  }

  clickEle = (key, id) => {
    this.setState({
      drawerVisible: false,
      drawerKey: '',
      drawerInfo: {}
    }, () => {
      const { components, nets } = this.props;
      if (key === 'comp') {
        const comp = components.find(item => item.id === id);
        if (comp) {
          this.setState({
            drawerKey: key,
            drawerInfo: JSON.parse(JSON.stringify(comp)),
            drawerVisible: true
          })
        }
        return;
      } else if (key === 'net') {
        const net = nets.find(item => item.id === id);
        if (net) {
          this.setState({
            drawerKey: key,
            drawerInfo: JSON.parse(JSON.stringify(net)),
            drawerVisible: true
          })
        }
      }
    })
  }

  closeDrawer = () => {
    const { drawerKey, drawerInfo } = this.state;
    const { components, nets } = this.props;
    if (drawerKey === 'comp') {
      let _components = [...components];
      const comp = _components.find(item => item.id === drawerInfo.id);
      if (comp && (comp.name !== drawerInfo.name || comp.type !== drawerInfo.type)) {
        this.props.updateDcComponents(drawerInfo)
        this.canvas.drawComp(drawerInfo);
      }
    }

    if (drawerKey === 'net') {
      let _nets = [...nets];
      const net = _nets.find(item => item.id === drawerInfo.id);
      if (net && (net.name !== drawerInfo.name || net.value !== drawerInfo.value)) {
        this.props.updateDcNets(drawerInfo)
        this.canvas.drawNet(drawerInfo, components);
      }
    }

    this.setState({
      drawerVisible: false,
      drawerKey: '',
      drawerInfo: {}
    })
  }

  changeInfo = (name, key) => {
    const { drawerInfo } = this.state;
    this.setState({
      drawerInfo: {
        ...drawerInfo,
        [key]: name
      }
    })
  }

  checkName = (name) => {
    const error = checkNameFormat(name);

    if (error) {
      return error;
    }

    const { drawerInfo, drawerKey } = this.state;
    if (drawerKey === 'comp') {
      const { components } = this.props;

      const comps = components.filter(item => item.id !== drawerInfo.id);

      if (comps.find(item => item.name === name)) {
        return `Component name ${name} has been used in another component.`
      }
    }

    if (drawerKey === 'net') {
      const { nets } = this.props;

      const _nets = nets.filter(item => item.id !== drawerInfo.id);

      if (_nets.find(item => item.name === name)) {
        return `Net name ${name} has been used in another component.`
      }
    }
  }

  render() {
    const { id } = this.props;
    const { height, drawerVisible, drawerKey, drawerInfo } = this.state;
    return <Fragment>
      <div id={`pre-layout-${id}`} className="cascade-prelayout-setup cascade-prelayout-setup-dc" style={{ height: height + 10 }}>
        <svg className='cascade-prelayout-svg' ref={this.svgRef} />
      </div>
      {drawerVisible && <DcInfo
        id={id}
        onClose={this.closeDrawer}
        drawerKey={drawerKey}
        data={drawerInfo}
        changeInfo={this.changeInfo}
        checkName={this.checkName}
      />}
    </Fragment>
  }
}

const mapState = (state) => {
  const { CascadeReducer: { prelayout: { id, content = {} } } } = state;
  const { components = [], nets = [] } = content;
  return {
    id,
    components,
    nets
  }
}

const mapDispatch = (dispatch) => ({
  updateDcComponents(comp) {
    dispatch(updateDcComponents(comp))
  },
  updateDcNets(net) {
    dispatch(updateDcNets(net))
  }
})

export default connect(mapState, mapDispatch)(DcPlot);