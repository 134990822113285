import React, { Component, Fragment, createRef } from 'react';
import hash from 'object-hash';
import EyeMaskPlot, { drawPlot } from '@/services/Andes_v2/library/EyeMask';

class EyeMaskPlotComponent extends Component {

  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.param = new EyeMaskPlot({ id: 'eyeMask' })
  }

  componentDidMount() {
    this.initPlot();
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.draw()
  }

  componentDidUpdate = (prevProps) => {
    const { width, height, pointsList, x, y } = this.props;
    if (width !== prevProps.width || height !== prevProps.height || x !== prevProps.x || y !== prevProps.y) {
      this.draw();
    }

    if (pointsList && hash(pointsList) !== hash(prevProps.pointsList)) {
      this.initPlot()
    }
  }

  initPlot = () => {
    const { pointsList, unitValue } = this.props;
    let importInfo = [];
    for (let pointInfo of pointsList) {
      const { data } = pointInfo;
      let times = [], voltages = [];
      for (let point of data) {
        if ((point.time || point.time === 0) && (point.voltage || point.voltage === 0)) {
          times.push(Number(point.time))
          voltages.push(Number(point.voltage))
        }
      }
      if (times.length && voltages.length && pointsList && pointsList.length) {
        times.push(Number(data[0].time))
        voltages.push(Number(data[0].voltage))
      }
      importInfo.push({
        id: 'eyeMask-main',
        times,
        voltages,
        unit: unitValue
      })
    }
    this.param.importData(importInfo);
    this.draw();
  }

  draw = () => {
    this.param.cleanPlotViewer();
    this.plot2d = this.param.createPlot(this.svgRef.current);
    drawPlot(this.param, this.plot2d);
  }


  render() {
    const { width = '800px', height = '350px' } = this.props;
    let _width = Number(width.replace('px', '')) / 2 - 18;
    let _height = height ? Number(height.replace('px', '')) - 89 : null;
    const style = _width > -1 ? _height > -1 ? { width: _width, height: _height } : { width: _width } : {}
    return <Fragment>
      <svg ref={this.svgRef} {...style} ></svg>
    </Fragment>
  }
}

export default EyeMaskPlotComponent;