export const DECAP_PARSE = '0.0.2';
export const FASTPI_SETUP_VERSION = '0.2.2';

export const SYS_VERSION = '1.0.0';

/* ---Rocky --- */
export const ROCKY_PROJECT_VERSION = '0.0.1';
export const ROCKY_SETUP_VERSION = '0.0.18';
export const ROCKY_PDN_VERSION = '0.0.1';
export const ROCKY_PRELAYOUT_VERSION = '0.0.1';
export const ROCKY_PACKAGE_SETUP_VERSION = '0.0.1';
export const CARD_SETUP_VERSION = "0.0.1";
export const ROCKY_PACKAGE_PRELAYOUT_VERSION = '0.0.1';


/* --- Andes v2 --- */
/* channel setup */
export const ANDES_SETUP_VERSION = '0.0.1';
export const ANDES_V2_CHANNEL_VERSION = "0.0.23";

/* multi pcb channel setup */
export const ANDES_V2__MULTI_PCB_CHANNEL_VERSION = "0.0.7";

/* --- Cascade --- */
export const IMPEDANCE_VERSION = "1.1.2"
export const IR_VERSION = "0.0.4"
export const POWER_TREE_VERSION = "0.0.1"
export const SIGN_OFF_TEMPLATE_VERSION = "1.0.0"
export const AC_PRE_LAYOUT_VERSION = "0.0.1"
export const DC_PRE_LAYOUT_VERSION = "0.0.1"

/* --- Sierra --- */
export const SIERRA_SETUP_VERSION = '3.2.5';
export const SIERRA_PRELAYOUT_VERSION = "0.0.1";
export const EXPERIMENT_SETUP_VERSION = "1.0.1";
export const EXPERIMENT_GROUP_VERSION = "1.0.1";

/* --- Public --- */
export const PIN_MAP_VERSION = "0.0.4";
