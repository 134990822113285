import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Switch, Input, Divider, Radio } from 'antd';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import ClipDesign from './ClipDesign';
import { PACKAGE } from '../../../../constants/designType';

const FREQUENCY = 'Frequency Sweep', MODELING = 'Modeling', MISC = 'Misc';

class ExtractionAdvancedPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 450,
      maxWidth: 960,
      advancedKey: FREQUENCY,
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 450),
      maxWidth: getPanelMaxWidth(offset, 960)
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { advancedKey } = this.props;
    if (advancedKey) {
      this.setState({
        advancedKey: advancedKey
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { extraction } = this.props
    if (extraction.type !== prevProps.extraction.type) {
      this.closeModal()
    }
  }

  closeModal = () => {
    const { error } = this.props
    if (error && error.type !== "judge") {
      return;
    }
    this.props.closeAdvancesPanel()
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  modelingContent = () => {
    const { allowModify, extraction = {}, simulateHFSS, hybrid, isShowHybridPreview, hybridRegionsInfo, designType, displayHfssOptionList,
      switchChange, inputBlur, changeConfigValue, unitSelect } = this.props;
    const { backdrillStubSize, backdrillVias, siwave = {}, hfss = {}, type } = extraction;
    return <Fragment>
      <div className='extraction-content'>
        <ClipDesign
          onRef={this.props.onAdvancedRef}
          saveClipOptions={this.props.saveClipOptions}
          hybrid={hybrid}
          hybridRegionsInfo={hybridRegionsInfo}
          isShowHybridPreview={isShowHybridPreview}
          showHybridRegions={this.props.showHybridRegions}
          updateHybridRegions={this.props.updateHybridRegions}
          _updatePreviewButtonStatus={this.props._updatePreviewButtonStatus}
        />
      </div>
      {designType !== PACKAGE ? <Fragment>
        <div className='extraction-content extraction-content-back-drill'>
          <span className="extraction-content-body">Back Drill Vias</span>
          <Switch
            disabled={!allowModify}
            size="small"
            className="aurora-switch-small"
            checked={backdrillVias}
            onChange={(checked) => switchChange(checked, "backdrillVias")}
          />
          {/* backdrillStubSize [1, 50] */}
          <div className='extraction-content-back-drill-input'>
            <span className='extraction-content-body'>Back Drill Stub Size</span>
            <Input
              className='extraction-input extraction-input-width'
              value={backdrillStubSize}
              addonAfter={"mil"}
              onChange={(e) => changeConfigValue(e, 'backdrillStubSize')}
              disabled={!allowModify || !backdrillVias}
              onBlur={(e) => inputBlur(e, 'backdrillStubSize')}
            />
          </div>
        </div>
      </Fragment> : null}
      {type === "SIwave" &&
        <Fragment>
          <Divider />
          <div className='extraction-content SIwave-extraction-setting-content'>
            <span className="extraction-content-body">SIwave Accuracy Settings</span>
            <div className='extraction-content'>
              <span className='extraction-content-body'>AC</span>
              <Radio.Group
                value={siwave.siSliderPos}
                onChange={(e) => changeConfigValue(e, 'siwave', 'siSliderPos')}
              >
                <Radio value={0} className='extraction-SIwave-radio-item' >Optimum speed</Radio>
                <Radio value={1} className='extraction-SIwave-radio-item' >Balanced</Radio>
                <Radio value={2} className='extraction-SIwave-radio-item' >Optimum accuracy</Radio>
              </Radio.Group>
            </div>
            <div className='extraction-content'>
              <span className='extraction-content-body'>DC</span>
              <Radio.Group
                value={siwave.dcSliderPos}
                onChange={(e) => changeConfigValue(e, 'siwave', 'dcSliderPos')}
              >
                <Radio value={0} className='extraction-SIwave-radio-item' >Optimum speed</Radio>
                <Radio value={1} className='extraction-SIwave-radio-item' >Balanced</Radio>
                <Radio value={2} className='extraction-SIwave-radio-item' >Optimum accuracy</Radio>
              </Radio.Group>
            </div>
          </div>
          <Divider />
          <div className='extraction-content SIwave-extraction-options-content'>
            <span className="extraction-content-body">SIwave Advanced Options</span>
            <Fragment>
              <div className='extraction-content'>
                <span className='extraction-content-body'>Do not mesh the void smaller than</span>
                <Input
                  className='extraction-input extraction-input-width'
                  addonAfter={'mm^2'}
                  value={siwave.minVoidArea}
                  onChange={(e) => changeConfigValue(e, 'siwave', 'minVoidArea')}
                  onBlur={(e) => inputBlur(e, 'siwave', 'minVoidArea')}
                  onPressEnter={this.onPressEnter}
                />
              </div>
              <div className='extraction-content'>
                <span className='extraction-content-body'>Explicitly mesh pads larger than</span>
                <Input
                  className='extraction-input extraction-input-width'
                  addonAfter={'mm^2'}
                  value={siwave.minPadAreaToMesh}
                  onChange={(e) => changeConfigValue(e, 'siwave', 'minPadAreaToMesh')}
                  onBlur={(e) => inputBlur(e, 'siwave', 'minPadAreaToMesh')}
                  onPressEnter={this.onPressEnter}
                />
              </div>
              <div className='extraction-content'>
                <span className='extraction-content-body'>Ignore planes smaller than</span>
                <Input
                  className='extraction-input extraction-input-width'
                  addonAfter={'mm^2'}
                  value={siwave.minPlaneAreaToMesh}
                  onChange={(e) => changeConfigValue(e, 'siwave', 'minPlaneAreaToMesh')}
                  onBlur={(e) => inputBlur(e, 'siwave', 'minPlaneAreaToMesh')}
                  onPressEnter={this.onPressEnter}
                />
              </div>
              <div className='extraction-content'>
                <span className='extraction-content-body'>Snap vertices separated by less</span>
                <Input
                  className='extraction-input extraction-input-width'
                  addonAfter={'um'}
                  value={siwave.snapLengthThreshold}
                  onChange={(e) => changeConfigValue(e, 'siwave', 'snapLengthThreshold')}
                  onBlur={(e) => inputBlur(e, 'siwave', 'snapLengthThreshold')}
                  onPressEnter={this.onPressEnter}
                />
              </div>
            </Fragment>
          </div>
          <div className='extraction-content SIwave-extraction-setting-content'>
            <span className="extraction-content-body">Mesh Refinement</span>
            <div className='extraction-content'>
              <span className="extraction-content-body">Automatic</span>
              <Switch
                size="small"
                className="aurora-switch-small"
                checked={siwave.meshAutomatic}
                onChange={(e) => switchChange(e, "siwave", "meshAutomatic")}
              />
              {/* backdrillStubSize [1, 50] */}
              <div className='extraction-content-back-frequency-input'>
                <span className='extraction-content-body'>Frequency</span>
                <Input
                  className='extraction-input extraction-input-width'
                  value={siwave.meshFrequency}
                  addonAfter={unitSelect('meshFrequency')}
                  onChange={(e) => changeConfigValue(e, 'siwave', 'meshFrequency')}
                  disabled={siwave.meshAutomatic}
                  onBlur={(e) => inputBlur(e, 'siwave', 'meshFrequency')}
                />
              </div>
            </div>
          </div>
        </Fragment>
      }
      {simulateHFSS && displayHfssOptionList && <Fragment>
        <Divider />
        <div className='extraction-content SIwave-extraction-setting-content'>
          <span className="extraction-content-body">HFSS Adaptive Meshing</span>
          <div className='extraction-content'>
            <span className='extraction-content-body' style={{ width: 308 }}>Frequency</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hfss.adaptiveFrequency}
              addonAfter={unitSelect('adaptiveFrequency')}
              onChange={(e) => changeConfigValue(e, 'hfss', 'adaptiveFrequency')}
              onBlur={(e) => inputBlur(e, 'hfss', 'adaptiveFrequency')}
            />
          </div>
          <div className='extraction-content'>
            <span className='extraction-content-body' style={{ width: 308 }}>Max Number of Passes</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hfss.maxPasses}
              onChange={(e) => changeConfigValue(e, 'hfss', 'maxPasses')}
              onBlur={(e) => inputBlur(e, 'hfss', 'maxPasses')}
            />
          </div>
          <div className='extraction-content'>
            <span className='extraction-content-body' style={{ width: 308 }}>Max Delta</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hfss.maxDelta}
              onChange={(e) => changeConfigValue(e, 'hfss', 'maxDelta')}
              onBlur={(e) => inputBlur(e, 'hfss', 'maxDelta')}
            />
          </div>
        </div>
        <Divider />
        <div className='extraction-content SIwave-extraction-setting-content'>
          <span className="extraction-content-body">HFSS Order of Basis Functions</span>
          <div>
            <Radio.Group
              className='radio-hfss-type-group'
              value={hfss.orderBasis}
              onChange={(e) => changeConfigValue(e, 'hfss', 'orderBasis')}
            >
              <Radio value={0} >Zero Order</Radio>
              <Radio value={1} >First Order</Radio>
              <Radio value={2} >Second Order</Radio>
              <Radio value={-1} >Mixed Order</Radio>
            </Radio.Group>
          </div>
        </div>
        <Divider />
        <div className='extraction-content SIwave-extraction-setting-content'>
          <span className="extraction-content-body">HFSS Via Modeling</span>
          <div className='extraction-content'>
            <span className='extraction-content-body' style={{ width: 308 }}>Number of sides</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hfss.viaNumSides}
              onChange={(e) => changeConfigValue(e, 'hfss', 'viaNumSides')}
              onBlur={(e) => inputBlur(e, 'hfss', 'viaNumSides')}
            />
          </div>
        </div>
        <Divider />
        <div className='extraction-content SIwave-extraction-airbox-content'>
          <span className="extraction-content-body">HFSS Airbox</span>
          <div className='extraction-content'>
            <div>
              <span className='extraction-content-body'>Horizontal Padding</span>
              <Input
                className='extraction-input'
                value={hfss.airHorExt}
                onChange={(e) => changeConfigValue(e, 'hfss', 'airHorExt')}
                onBlur={(e) => inputBlur(e, 'hfss', 'airHorExt')}
              />
            </div>
            <div>
              <span className='extraction-content-body' >Vertical Padding</span>
              <Input
                className='extraction-input'
                value={hfss.airPosZExt}
                onChange={(e) => changeConfigValue(e, 'hfss', 'airPosZExt')}
                onBlur={(e) => inputBlur(e, 'hfss', 'airPosZExt')}
              />
            </div>
          </div>
        </div>
      </Fragment>
      }

    </Fragment>
  }

  miscContent = () => {
    const { allowModify, extraction = {}, hpcOptionSwitch, licWaitSwitch, timeoutSwitch,
      switchChange, inputBlur, changeConfigValue, unitSelect } = this.props;
    const { lic_wait, lic_wait_unit, hpc_options = {}, timeoutHours, timeoutMinutes, saveResultFolder } = extraction;
    return <Fragment>
      <div className='extraction-content extraction-content-hpc'>
        <span className='extraction-content-body'>Custom HPC</span>
        <Switch
          disabled={!allowModify}
          size="small"
          className="aurora-switch-small"
          checked={hpcOptionSwitch}
          onChange={(e) => switchChange(e, "hpcOptionSwitch")}
        />
        {hpcOptionSwitch ? <Fragment>
          <div className='extraction-content'>
            <span className='extraction-content-body'>Number of CPU</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hpc_options.num_cores}
              onChange={(e) => changeConfigValue(e, 'hpc_options', "num_cores")}
              disabled={!allowModify}
              onBlur={(e) => inputBlur(e, 'hpc_options', "num_cores")}
            />
          </div>
          <div className='extraction-content'>
            <span className='extraction-content-body'>Number of GPU</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hpc_options.num_gpus}
              onChange={(e) => changeConfigValue(e, 'hpc_options', "num_gpus")}
              disabled={!allowModify}
              onBlur={(e) => inputBlur(e, 'hpc_options', "num_gpus")}
            />
          </div>
          <div className='extraction-content'>
            <span className='extraction-content-body'>Max RAM</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hpc_options.ram_percent}
              onChange={(e) => changeConfigValue(e, 'hpc_options', 'ram_percent')}
              disabled={!allowModify}
              addonAfter={"%"}
              onBlur={(e) => inputBlur(e, 'hpc_options', "ram_percent")}
            />
          </div>
          <div className='extraction-content'>
            <span className='extraction-content-body'>Parallel Freq. solve</span>
            <Input
              className='extraction-input extraction-input-width'
              value={hpc_options.num_tasks}
              onChange={(e) => changeConfigValue(e, 'hpc_options', "num_tasks")}
              disabled={!allowModify}
              onBlur={(e) => inputBlur(e, 'hpc_options', "num_tasks")}
            />
          </div></Fragment> : null}
      </div>
      <Divider />
      <div className='extraction-content'>
        <span className='extraction-content-body'>License Wait Time</span>
        <Switch
          disabled={!allowModify}
          size="small"
          className="aurora-switch-small"
          checked={licWaitSwitch}
          onChange={(e) => switchChange(e, "licWaitSwitch")}
        />
        {licWaitSwitch ? <div className='extraction-content-lic-wait-input'>
          <Input
            className='extraction-input'
            value={lic_wait}
            addonAfter={unitSelect("lic_wait_unit", lic_wait_unit)}
            onChange={(e) => changeConfigValue(e, 'lic_wait')}
            disabled={!allowModify}
            onBlur={(e) => inputBlur(e, 'lic_wait')}
          />
        </div> : null}
      </div>
      <div className='extraction-content'>
        <span className='extraction-content-body'>Custom Timeout</span>
        <Switch
          disabled={!allowModify}
          size="small"
          className="aurora-switch-small"
          checked={timeoutSwitch ? true : false}
          onChange={(e) => switchChange(e, "timeoutSwitch")}
        />
        {timeoutSwitch ? <div className='extraction-content-time-out-input'>
          <span className="extraction-content-body">Abort the extraction after</span>
          <Input
            className='extraction-input'
            value={timeoutHours}
            onChange={(e) => changeConfigValue(e, 'timeoutHours')}
            disabled={!allowModify}
            onBlur={(e) => inputBlur(e, 'timeoutHours')}
            addonAfter={"hour"}
            style={{ marginRight: 10 }}
          />
          <Input
            className='extraction-input'
            value={timeoutMinutes}
            onChange={(e) => changeConfigValue(e, 'timeoutMinutes')}
            disabled={!allowModify}
            onBlur={(e) => inputBlur(e, 'timeoutMinutes')}
            addonAfter={"minute"}
          />
        </div> : null}
      </div>
      <Divider />
      <div className='extraction-content'>
        <span className='extraction-content-body'>Save AEDT result folder </span>
        <Switch
          disabled={!allowModify}
          size="small"
          className="aurora-switch-small"
          checked={saveResultFolder}
          onChange={(e) => switchChange(e, "saveResultFolder")}
        />
      </div>
    </Fragment>

  }

  frequencyContent = () => {
    const { allowModify, extraction = {}, simulateHFSS, frequencyPointsInfo, displayHfssOptionList,
      switchChange, inputBlur, changeConfigValue, unitSelect } = this.props;
    const { logSweepMin, logSweepMax, logSweepSAPD, linearSweepMax, linearSweepFreqStep, maxFreq, includeDC, exactDC, errorTolerance, maxSolution, enableLogSweep, hfss = {}, discreteSweep } = extraction;
    const { frequencyPointsTotal } = frequencyPointsInfo;
    return <Fragment>
      <div className='extraction-content'>
        <span className='extraction-content-body'>Include DC</span>
        <Switch
          disabled={!allowModify}
          size="small"
          className="aurora-switch-small"
          checked={includeDC}
          onChange={(e) => switchChange(e, "includeDC")}
        />
        <div className='extraction-content-sub-body'>
          <span className='extraction-content-body'>Exact DC Solver</span>
          <Switch
            size="small"
            checked={exactDC}
            className="aurora-switch-small"
            onChange={(e) => switchChange(e, "exactDC")}
            disabled={!allowModify || !includeDC}
          />
        </div>
      </div>
      {/* Frequency Sweep */}
      <div className='extraction-content'>
        <span className='extraction-content-body extraction-content-log-sweep-body'>Low frequency log sweep</span>
        <Switch
          disabled={!allowModify}
          size="small"
          className="aurora-switch-small"
          checked={enableLogSweep}
          onChange={(e) => switchChange(e, "enableLogSweep")}
        />
      </div>
      {enableLogSweep ? <div className='extraction-content'>
        {/* 1K - 10M */}
        <span className='extraction-content-body'>Log Sweep</span>
        <Input
          value={logSweepMin}
          className='extraction-input extraction-input-item'
          style={{ marginRight: 7 }}
          addonAfter={unitSelect('logSweepMin')}
          onChange={(e) => changeConfigValue(e, 'logSweepMin')}
          onBlur={(e) => inputBlur(e, 'logSweepMin')}
          disabled={!allowModify}
        />
        To
        <Input
          value={logSweepMax}
          className='extraction-input extraction-input-item'
          style={{ marginLeft: 6 }}
          addonAfter={unitSelect('logSweepMax')}
          onChange={(e) => changeConfigValue(e, "logSweepMax")}
          onBlur={(e) => inputBlur(e, 'logSweepMax')}
          disabled={!allowModify}
        />
        <Input
          className='sub-extraction-input extraction-input-item-sub'
          value={logSweepSAPD}
          onChange={(e) => changeConfigValue(e, "logSweepSAPD")}
          onBlur={(e) => inputBlur(e, 'logSweepSAPD')}
          disabled={!allowModify}
        />
        <span className='extraction-content-body extraction-step-width'>Per Decade</span>
      </div> : true}
      <div className='extraction-content'>
        <span className='extraction-content-body'>Linear Sweep</span>
        <Input
          value={enableLogSweep ? logSweepMax : linearSweepFreqStep}
          className='extraction-input extraction-input-item'
          style={{ marginRight: 7 }}
          addonAfter={unitSelect('logSweepMax')}
          onChange={(e) => changeConfigValue(e, 'logSweepMax')}
          onBlur={(e) => inputBlur(e, 'logSweepMax')}
          disabled={!allowModify || !enableLogSweep}
        />
        To
        <Input
          value={linearSweepMax}
          className='extraction-input extraction-input-item'
          style={{ marginLeft: 6 }}
          addonAfter={unitSelect('linearSweepMax')}
          onChange={(e) => changeConfigValue(e, 'linearSweepMax')}
          onBlur={(e) => inputBlur(e, 'linearSweepMax')}
          disabled={!allowModify}
        />
        <Input
          className='sub-extraction-input extraction-input-item-sub'
          value={linearSweepFreqStep}
          onChange={(e) => changeConfigValue(e, 'linearSweepFreqStep')}
          onBlur={(e) => inputBlur(e, 'linearSweepFreqStep')}
          addonAfter={unitSelect('linearSweepFreqStep')}
          disabled={!allowModify}
        />
        <span className='extraction-content-body extraction-step-width'>Step</span>
      </div>
      <div className='extraction-content extraction-points-content'>
        <div>
          <span className='extraction-content-body'>Maximum Frequency</span>
          {/* >1 */}
          <Input
            className='extraction-input extraction-input-item'
            value={maxFreq}
            addonAfter={unitSelect('maxFreq')}
            onChange={(e) => changeConfigValue(e, 'maxFreq')}
            disabled={!allowModify}
            onBlur={(e) => inputBlur(e, 'maxFreq')}
          />
          <span className='extraction-content-body-span'>Sampled at 20MHz steps above the first linear sweep</span>
        </div>
        <span className='extraction-content-body extraction-points-content-body'>( {frequencyPointsTotal} points defined )</span>
      </div>
      <div className='extraction-content'>
        <span className='extraction-content-body'>Discrete Sampling</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={discreteSweep}
          disabled={!allowModify}
          onChange={(checked) => switchChange(checked, "discreteSweep")}
        />
      </div>
      {displayHfssOptionList && <Fragment>
        <Divider />
        <div className='extraction-content'>
          <span className='extraction-content-body' style={{ width: 308 }}>Result Interpolation Error Tolerance</span>
          <Input
            className='extraction-input extraction-input-width'
            value={errorTolerance}
            onChange={(e) => changeConfigValue(e, 'errorTolerance')}
            disabled={!allowModify}
            onBlur={(e) => inputBlur(e, 'errorTolerance')}
            addonAfter={"%"}
          />
        </div>
        <div className='extraction-content'>
          <span className='extraction-content-body' style={{ width: 308 }}>Maximum Number of Solutions</span>
          <Input
            className='extraction-input extraction-input-width'
            value={maxSolution}
            onChange={(e) => changeConfigValue(e, 'maxSolution')}
            disabled={!allowModify}
            onBlur={(e) => inputBlur(e, 'maxSolution')}
          />
        </div>
        {simulateHFSS && <Fragment>
          <Divider />
          <div className='extraction-content'>
            <span className='extraction-content-body' style={{ width: 308 }}>HFSS Advanced DC Extrapolation</span>
            <Switch
              disabled={!allowModify}
              size="small"
              className="aurora-switch-small"
              checked={hfss.advDCExtrapolation}
              onChange={(e) => switchChange(e, "hfss", "advDCExtrapolation")}
            />
          </div>
          <div className='extraction-content'>
            <span className='extraction-content-body' style={{ width: 360, paddingLeft: 20 }}>Specify the minimum frequency to be explicitly solved</span>
            <Switch
              size="small"
              className="aurora-switch-small extraction-specify-switch"
              checked={hfss.specifyMinSolvedFreq}
              disabled={!hfss.advDCExtrapolation}
              onChange={(e) => switchChange(e, "hfss", "specifyMinSolvedFreq")}
            />
            {/* backdrillStubSize [1, 50] */}
            <Input
              className='extraction-input extraction-input-item'
              value={hfss.minSolvedFreq}
              addonAfter={unitSelect('minSolvedFreq')}
              disabled={!hfss.specifyMinSolvedFreq || !hfss.advDCExtrapolation}
              onChange={(e) => changeConfigValue(e, 'hfss', 'minSolvedFreq')}
              onBlur={(e) => inputBlur(e, 'hfss', 'minSolvedFreq')}
            />
          </div>
          <div className='extraction-content'>
            <span className='extraction-content-body extraction-content-log-sweep-body' style={{ width: 308 }}>Enforce Causality (DC point required)</span>
            <Switch
              disabled={!allowModify || !includeDC}
              size="small"
              className="aurora-switch-small"
              checked={hfss.generateStateSpace}
              onChange={(e) => switchChange(e, "hfss", "generateStateSpace")}
            />
          </div>
        </Fragment>}
      </Fragment>}
    </Fragment>
  }

  getContent = () => {
    const { advancedKey } = this.state;
    switch (advancedKey) {
      case FREQUENCY: return this.frequencyContent();
      case MODELING: return this.modelingContent();
      case MISC: return this.miscContent();
      default: return null
    }
  }

  restoreClick = () => { }
  saveClick = () => { }

  getSide = () => {
    const { advancedKey } = this.state;
    let leftSide = null, rightSide = null
    switch (advancedKey) {
      case FREQUENCY:
        rightSide = MODELING
        break
      case MODELING:
        leftSide = FREQUENCY
        rightSide = MISC
        break
      case MISC:
        leftSide = MODELING
        break
      default: break;
    }
    return { leftSide, rightSide }
  }

  changeSide = (e, key) => {
    e.stopPropagation();
    this.setState({
      advancedKey: key
    })
    this.props.cleanWarningMsg()
  }

  getFooter = () => {
    const { leftSide, rightSide } = this.getSide()
    return (
      <div className='andes_v2-channel-extraction-advanced-footer'>
        <div className='extraction-advanced-footer-sides'>
          {leftSide && <div onClick={(e) => this.changeSide(e, leftSide)}>
            <LeftOutlined />
            <span>{leftSide}</span>
          </div>}
        </div>
        {/* <div className='extraction-advanced-footer-box'>
          <Button key="ok" type="primary" onClick={this.restoreClick}>Restore Default</Button>
          <Button key="cancel" style={{ marginLeft: 30 }} onClick={this.saveClick} >Save as Default</Button>
        </div> */}
        <div className='extraction-advanced-footer-sides'>
          {rightSide && <div onClick={(e) => this.changeSide(e, rightSide)}>
            <span>{rightSide}</span>
            <RightOutlined />
          </div>}
        </div>
      </div>
    );
  }

  render() {
    const { maxHeight, maxWidth, advancedKey } = this.state;
    const { error, warningMsg } = this.props;
    const content = (
      <Panel
        className='andes_v2-channel-extraction-advanced-panel panel-x-scroll-hidden'
        title={<div className='andes_v2-channel-extraction-advanced-option-title'>{advancedKey}</div>}
        onCancel={() => this.closeModal()}
        zIndex={2000}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={930}
        width={getPanelWidth(maxWidth, { defaultWidth: 960 })}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className={`andes_v2-channel-extraction-advanced-content andes_v2-channel-extraction-${advancedKey}-content`}>
          {this.getContent()}
          {error && <span className={error && error.type === "warning" ? "extraction-option-warning-msg" : "extraction-option-error-msg"}>Error: {error.error}</span>}
          {warningMsg && warningMsg.advancedKey && warningMsg.advancedKey === advancedKey && !error && <span className='aurora-model-name-warning-msg'>{warningMsg.error}</span>}
        </div>
        {this.getFooter()}
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ExtractionAdvancedPanel;