import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { DownloadOutlined } from '@ant-design/icons';
import { Radio, Upload, message, Tooltip } from 'antd';
import { COMPONENTBASED, PARTBASED } from '../../../constants/resolution';
import { getResBlob } from '../../../services/helper/downloadHelper';
import { CASCADE } from '../../../constants/pageType';
import FileSaver from 'file-saver';
import './index.css';

class DecapSettingPanel extends Component {
  constructor(props) {
    super(props);

    this.dialogRoot = document.getElementById('root');
    this.uploadFileRef = createRef();
    this.state = {
      componentsTableDisplay: PARTBASED
    }
  }

  componentDidMount = () => {
    const { componentsTableDisplay } = this.props
    this.setState({
      componentsTableDisplay
    })
  }

  componentDidUpdate = (prevProps) => {
    const { componentsTableDisplay } = this.props
    if (componentsTableDisplay !== prevProps.componentsTableDisplay) {
      this.setState({
        componentsTableDisplay
      })
    }
  }

  closeModal = (clearModel) => {
    const { componentsTableDisplay } = this.state
    this.props.changeComponentsTableDisplay(componentsTableDisplay, clearModel)
    this.props.updateDecapSettingPanel(false)
  }

  changeCompTableDisplay = (e) => {
    const value = e.target.value
    this.setState({
      componentsTableDisplay: value
    })
  }

  uploadModel = (file) => {
    const { componentsTableDisplay } = this.state
    this.props.handleUpload(file, componentsTableDisplay)
    setTimeout(() => {
      this.closeModal(false)
    }, 100)
  }

  downloadHelper = () => {
    const token = localStorage.getItem('token');
    let url = `/api/v3/Cascade/impedance/decap/excel/help`;
    getResBlob(url, { token, pageType: CASCADE }).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, 'decap_excel_help.zip');
      } else {
        message.error('Download failed!')
      }
    })
  }

  renderDialog() {
    const { powerDomainGroups, hasDecapGroup } = this.props
    const { componentsTableDisplay } = this.state
    const content = (
      <Panel
        className='cascade-decap-setting-panel'
        title={'Components'}
        onCancel={() => this.closeModal(true)}
        zIndex={2000}
        width={600}
        minWidth={600}
        minHeight={100}
        position='panel-center-left'
        draggable
      >
        <div className='impedance-decap-setting-main'>
          <div className='impedance-decap-setting-content'>
            <span className='impedance-decap-setting-content-body'>Components Table</span>
            <Radio.Group onChange={this.changeCompTableDisplay} value={componentsTableDisplay}>
              <Radio value={PARTBASED}>Part Based</Radio>
              <Radio value={COMPONENTBASED}>Component Based</Radio>
            </Radio.Group>
          </div>
          <div className='impedance-decap-setting-content'>
            <span className='impedance-decap-setting-content-body'>
              <span>Decap Model Excel</span>
              <Tooltip
                title='Click to download excel helper.'
                overlayClassName='icon-tooltip'
              >
                <DownloadOutlined
                  className="cascade-imp-icon"
                  onClick={this.downloadHelper}
                  style={{ marginBottom: 4 }} />
              </Tooltip>
            </span>
            <Upload
              name='file'
              showUploadList={false}
              customRequest={this.uploadModel}
            >
              <span className='cascade-imp-decap-group-span'>
                Upload
              </span>
            </Upload>
          </div>
          {hasDecapGroup && <div className='impedance-decap-setting-content'>
            <span className='impedance-decap-setting-content-body'>Decap Group</span>
            <span className='cascade-imp-decap-group-span'>
              <span onClick={() => this.props.decapGroupOpen(true)}>Decap Group</span>
            </span>
          </div>}
        </div>
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    return (
      <Fragment>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default DecapSettingPanel;