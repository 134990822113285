import { takeEvery, select, put, call, delay, fork } from "@redux-saga/core/effects";
import {
  START_IR_SIMULATION,
} from '../actionType';
import {
  updateMultiSimulationInfo,
} from '../action'
import { IR_EXPLORER } from '@/constants/treeConstants';
import { simulationIRPromise } from '@/services/Cascade/IRExplorer/IRCtrl';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { openTabFooter } from "../../../../MonitorStore/action";
import { getInterfaceWorkStatus } from '@/services/project';
import { getStackupErrorCheck, startNewWorkflow } from '../simulationSaga';
import { initSimulationInfo } from '../action';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { getCascadeSimulationLog } from '@/services/Cascade/simulation/simulationCtrl';
import { updateIRResultExist } from '../../IRExplorer/action'
import { updateSimulationLoading } from "../../project/action";

function* _startIRSimulation(action) {
  const { id } = action;
  const { CascadeReducer: { IR: { verificationId, designId, IRExplorerInfo: { IRTableData } } } } = yield select();
  try {
    if (!IRTableData.length) {
      return;
    }
    // get IR status
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        return
      }
    }

    // start simulation
    yield put(openTabFooter());
    //stackup error check
    const stackupError = yield call(getStackupErrorCheck, { designId, verificationId });
    if (stackupError && stackupError.length) {
      return;
    }

    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: "==> Prepare Extraction data...",
        log: null,
        monitor: null,
        endMsg: null
      }
    }))
    yield call(simulationIRPromise, verificationId);
    const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
    yield put(updateIRResultExist({ id: verificationId, exist: false }))
    if (interfaceStatus && interfaceStatus.length > 0) {
      yield fork(startNewWorkflow, {
        verificationId,
        interfaceStatus,
        key: IR_EXPLORER
      })
      return
    } else {
      // _initSimulationInfo 
      yield put(initSimulationInfo)
    }
  } catch (error) {
    console.error(error);
    yield delay(1000);
    let log = yield call(getCascadeSimulationLog, verificationId);
    const errorLog = error && Object.prototype.toString.call(error) === '[object String]' ?
      error.split('\n').map(err => err ? `==> ${err}\n` : '') : '';
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: errorLog,
        log: log,
        monitor: null,
        endMsg: null
      }
    }))
  } finally {
    yield put(updateSimulationLoading(false))
  }
}

function* IRSimulationSaga() {
  yield takeEvery(START_IR_SIMULATION, _startIRSimulation);
}

export default IRSimulationSaga;