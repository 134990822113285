import { getProjectContentTypeName } from '../api/v2/projectCtrl';
import { createVerification, getVerificationContentByTypeName, deleteVerification, combineInterfaces, getSierraCreateInterfaceStatusInfo } from '../api/v2/verificationCtrl';
import { createInterfaceByTypeName, updateInterfaceInPCB, deleteInterfaceInPCBByTypeName, updateMultipleInterfaceInPCB } from '../api/v2/interfaceCtrl';
import checkError from '../api/checkError';
import { SUCCESS } from '../../constants/returnCode';
import {
  RLCCONComponent,
  BasicCompModel,
  BasicComponent
} from '../helper/IntegratedInterface';
import { createVerificationConfig, updateVerificationConfig } from '../api/v2/verificationConfig';
import { removeExsitResult } from '../api/sierra';
import { SIERRA_SETUP_VERSION } from '../../version';
import { RepeaterComponent, RepeaterPin, ICComponent, ICCompPinModel } from './IntegratedInterface';
import { MultiPinSPICE, ICTypes, RLCTypes } from '../../pages/Sierra/constants';
import {
  IBIS,
  SPICE,
  CONNECTOR as Connector,
  REPEATER, PKG_TOUCHSTONE, PKG_SPICE
} from '../../constants/libraryConstants';
import { RLC_TYPES } from '../PCBHelper';
import {
  updateIbisModelList, updateLibraryRepeaterFile, updateLibraryConnectorFile,
  updateSpiceModelList, updateFolderFileDetail, updatePkgSpiceModelList, updateTouchstoneParse,
} from './library';
import {
  updateICModel, updateICPackageIbis, updateICPackage,
  updateICStimulus, updateICStimulusPin, updateRepeater,
  updateConnectorModels, updatePassive
} from './errorCheck';
import { getDefaultConnector } from './helper/connectorHelper';
import apiProcess from '../api/utility';
import {
  UNUSED,
  COMP_REPEATER,
  TEST_POINT,
  IGNORE,
  CONNECTOR,
} from '../../constants/componentType';
import { getCompTypeChange } from './interfaceData';
import { getSierraPreLayoutInfoById, updateSierraPreLayout } from '../api/sierra/preLayout';
import { getRLCCompValue } from './setupHelper';
import { getCompType } from '../Designs/compTypeHelper';
import { SIERRA } from '../../constants/pageType';

/**
 * Get PDN project by project ID
 * 2019.09.09
 *
 * @export
 * @param {String} projectId Project ID
 * @returns Promise
 */
function getSierraProjectPromise(projectId) {
  return new Promise((resolve, reject) => {
    getProjectContentTypeName({ projectId, typeName: 'sierra' }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    });
  });
};

function createVerificationPromise({ name, projectId, readyForSim, tag = "" }) {
  return new Promise((resolve, reject) => {
    createVerification({ id: "", name, projectId, typeName: "PinToPin", readyForSim, tag }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    });
  })
};

function getVerificationContentPromise(verificationId) {
  return new Promise((resolve, reject) => {
    getVerificationContentByTypeName(verificationId, 'sierra').then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    });
  })
}

function createInterfacePromise({ projectId, designId, verificationId, verificationName, content, interfaceName, readyForSim, version, settingVersion }) {
  return new Promise((resolve, reject) => {
    createInterfaceByTypeName({
      projectId,
      designId,
      type: 'PinToPin',
      verificationId,
      verificationName,
      content,
      interfaceName,
      readyForSim,
      version,
      typeName: 'sierra',
      settingVersion
    }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    });
  })
};

function updateInterfacePromise({ designId, interfaceId, interfaceName, projectId, verificationId, verificationName, content, readyForSim, designVersion, settingVersion, initialized }) {
  return new Promise((resolve, reject) => {
    updateInterfaceInPCB({
      designId,
      interfaceId,
      interfaceName,
      projectId,
      type: 'PinToPin',
      verificationId,
      verificationName,
      content,
      readyForSim,
      version: SIERRA_SETUP_VERSION,
      designVersion,
      settingVersion,
      initialized
    }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(res);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      reject(error);
    })
  })
};

// Auto filter Signal Nets
function autoFilterSignalNets(netList) {
  let nets = [];
  for (let net of netList) {
    if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
      continue;
    }
    // Remove v, +v, N+V NONE, GND, via length check
    // if (net.mName.match(/^(v|\+v|NONET|GND)/i)) {
    //   continue;
    // };
    // if (net.mName.match(/[0-9]+V/ig)) {
    //   continue;
    // }
    // if (net.mViaList.length > 10) {
    //   continue;
    // }
    // Remove geometry check
    // if (net.mGeomList.length > 0) {
    //   const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
    //   const typeList = geomList.map(item => item instanceof CePolygon); // [true,false]
    //   // Exist Polygon
    //   if (typeList.includes(true)) {
    //     continue;
    //   }
    // }
    nets.push(net.mName)
  };
  return nets;
};

function sierraCtrl() {
  this.pcbId = '';
  this.CompsInfo = null;
}
let ctrl = new sierraCtrl();

function getComponentsWithNetList({ netList = [], pcbNetsList, layers, pcbId, pcbReplace, COMP_PREFIX_LIB, doNotStuff, passiveTable, compPinMap }) {
  let CompsInfo = null;
  if (ctrl.pcbId !== pcbId || pcbReplace) {
    CompsInfo = getSierraLayoutComponents(layers);
    ctrl.pcbId = pcbId;
    ctrl.CompsInfo = CompsInfo;
  } else {
    CompsInfo = ctrl.CompsInfo;
  }

  let components = []; // [{pin,name,net,value,type}]
  //filter net list
  const _netList = [...new Set([...(netList || [])])];
  _netList.forEach(netName => {
    const pinList = getPinList(netName, pcbNetsList);
    const list = pinList.map(pin => {
      const compInfo = CompsInfo[pin.mCompName];
      let _compInfo = compInfo;
      if (!compInfo) {
        _compInfo = {
          type: UNUSED,
          value: "",
          part: "UNKNOW"
        }
      }
      let { type, value, part, pinList = [] } = _compInfo;

      //update rlc comp type by passive
      const findPassive = (passiveTable || []).find(item => item.part === part);

      if (doNotStuff && doNotStuff.includes(pin.mCompName)) {
        type = UNUSED;
      } else if (findPassive && findPassive.usage) {
        type = findPassive.name && findPassive.name.includes(_compInfo.name) ? findPassive.usage : UNUSED;
      } else {
        //re check type- after comp_prefix_lib updated
        type = getCompType({
          compName: pin.mCompName,
          pinLength: pinList.length,
          partName: part,
          COMP_PREFIX_LIB,
          compPinMap,
          product: SIERRA
        });
        type = type === IGNORE ? UNUSED : type;
      }

      return {
        pin: pin.mPinNum,
        name: pin.mCompName,
        net: netName,
        value: ((type === 'Res' || type === 'Ind' || type === 'Cap') && value) || "",
        type: type,
        part
      }
    });
    components.push(...list);
  });

  return components;
};

function getPinList(netName, netList) {
  if (!netName || !netList) return [];
  const netObj = netList.find(item => item.mName === netName);
  // netObj.mPinList - mLayerName, mCompName, mPinNum, mMetalLayerName
  return netObj ? netObj.mPinList : [];
}

function getSierraLayoutComponents(layers) {
  let compList = [];
  let layoutCompList = {};
  layers.forEach(item => {
    if (item.mComponentLayer !== null) {
      compList.push(item.mComponentLayer);
    }
  });
  compList.forEach(layer => {
    let comps = layer.mComponents;
    if (comps) {
      for (let component of comps) {
        layoutCompList[component.mName] = { comp: component };
      }
    }
  });

  //update the component information
  for (var compName in layoutCompList) {

    var compInfo = layoutCompList[compName];
    compInfo.name = compName;
    if (compInfo.comp && compInfo.comp.mPart) {
      compInfo.part = compInfo.comp.mPart.mInfo ? compInfo.comp.mPart.mInfo.mPartName : compInfo.part;
      compInfo.value = compInfo.comp.mPart.mInfo ? compInfo.comp.mPart.mInfo.mPhyProps.getValue('value') : compInfo.value;
      compInfo.pinList = compInfo.comp.mPart.mPinList || [];
    }

    if (compInfo.comp && compInfo.comp.mPart && compInfo.comp.mPart.mInfo && compInfo.comp.mPart.mInfo.mPartType &&
      compInfo.comp.mPart.mInfo.mPartType.length > 0) {
      compInfo.type = compInfo.comp.mPart.mInfo.mPartType;
      var type = compInfo.type.toUpperCase();
      if (type === 'R' || type === 'RES' || type === 'RESISTOR') {
        compInfo.type = 'Res';
      }
      else if (type === 'C' || type === 'CAP' || type === 'CAPACITOR') {
        compInfo.type = 'Cap';
      }
      else if (type === 'L' || type === 'IND' || type === 'INDUCTOR') {
        compInfo.type = 'Ind';
      }
    }

    if (compInfo.comp.mPart.mPinList.length === 1) {
      //Determine the type by the number of pins,
      //When pinList is 1, type is testPoint
      compInfo.type = 'testPoint';
    }

    delete compInfo.comp;
  }
  return layoutCompList;
};

// Auto filter Power Ground Nets
function autoFindPowerGNDNets(netList) {
  let nets = [];
  for (let net of netList) {
    if (!_isPowerGND(net)) {
      continue;
    } else {
      nets.push(net.mName);
    }
  };
  return nets;
}

/**
 * Delete verifications by verificationIds
 * 2019.09.09
 *
 * @export
 * @param {Array} pdnIds
 * @returns Promise
 */
function delVerification(verificationIds) {
  return new Promise((resolve, reject) => {
    deleteVerification(verificationIds).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
};

function getPowerComponents({ nets, pcbNetsList, layers, pcbId, prevPowerComponents, COMP_PREFIX_LIB, doNotStuff, changeTypeFromSetting, updateInfo, passiveTable, compPinMap, updateSignalComps, components }) {
  let newPowerComps = [];
  const componentsList = getComponentsWithNetList({ netList: nets, pcbNetsList, layers, pcbId, COMP_PREFIX_LIB, doNotStuff, passiveTable, compPinMap });
  let existComponentsName = [];
  for (const comp of componentsList) {
    const { pin, name, net, value, part } = comp;
    if (updateSignalComps && components) {
      const compIndex = components.findIndex(compItem => compItem.name === name);
      if (compIndex > -1 && RLC_TYPES.includes(components[compIndex].type)) {
        const findPin = components[compIndex].pins.find(item => item.pin === pin);
        !findPin && components[compIndex].pins.push({
          pin,
          net,
          signal: ""
        })
        continue;
      }

    }
    let type = comp.type;
    const _index = existComponentsName.indexOf(name);
    //pinLength, partNumber, COMP_PREFIX_LIB
    if (ICTypes.includes(type) || type === CONNECTOR || type === COMP_REPEATER) continue;
    if (_index < 0) {
      // Not exist component
      let newComp;
      if (RLCTypes.includes(type)) {
        const { value: _value } = getRLCCompValue({
          passiveTable,
          part,
          value,
          type,
          isPowerComp: true
        })
        if (doNotStuff && doNotStuff.includes(name)) {
          type = UNUSED
        }
        newComp = new RLCCONComponent({ name, type, value: _value, pins: [{ pin, net }] });
      } else {
        newComp = new RLCCONComponent({ name, type: UNUSED, value: "", pins: [{ pin, net }] });
      }
      newComp.part = part;
      newPowerComps.push(newComp);
      // Update newPowerComps name list
      existComponentsName.push(name);
    } else {
      // Exsit component
      newPowerComps[_index].pins.push({ pin, net });
    }
  };
  let Comps = [];
  for (const comp of newPowerComps) {
    if (comp.pins.length > 1) {
      const nets = comp.pins.map(item => item.net);
      if ([...new Set(nets)].length > 1) {
        Comps.push(comp);
      };
    };
  };

  //copy value
  if (prevPowerComponents && prevPowerComponents.length > 0) {
    Comps.forEach(pComp => {
      let prevPowerComp = prevPowerComponents.find(item => item.name === pComp.name);
      if (prevPowerComp) {
        pComp.value = prevPowerComp.value;
        if (changeTypeFromSetting && prevPowerComp.type !== pComp.type) {
          const update = getCompTypeChange({
            updateInfo,
            pcbId,
            type: pComp.type,
            component: prevPowerComp
          })
          if (!update) {
            pComp.type = prevPowerComp.type;
          }
        } else {
          pComp.type = prevPowerComp.type;
        }
      } else {
        //same part and same type component / rlc type is unused
        let prevSamePowerComp = prevPowerComponents.find(item => item.part === pComp.part && (item.type === pComp.type || (item.type === UNUSED && RLCTypes.includes(pComp.type))));
        pComp.value = prevSamePowerComp ? prevSamePowerComp.value : pComp.value;
      }
    })
  }

  if (updateSignalComps && components) {
    return { newPowerComps: Comps, updatedComponents: components }
  }
  return Comps;
}

/**
 * Create verfication config by verifcation id
 * 2019.08.08
 * 
 * @export
 * @param {string} verificationId verification id
 * @param {string} config { XTalk, clock, cycles, slewRate, timeStep }
 * @returns Promise
 */
function createPinToPinConfig({ verificationId, config, projectId }) {
  return new Promise((resolve, reject) => {
    createVerificationConfig({ type: 'PinToPin', projectId: projectId, verificationId, config }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(false)
      }
    }, error => {
      resolve(false)
    })
  })
};

function changeConfigFormat(config) {
  let { clock, timeStep, slewRate } = config;
  if (typeof clock === "string" && clock) {
    config.clock = settingUnit(clock, "M");
  }

  if (typeof timeStep === "string" && timeStep) {
    config.timeStep = settingUnit(timeStep, "n");
  }

  if (typeof slewRate === "string" && slewRate) {
    config.slewRate = settingUnit(slewRate, "n");
  }

  return config;
}

function settingUnit(param, defaultUnit) {
  let value, unit;
  // If param is a number, the number 1 is expressed as 1MHz.
  if (!isNaN(Number(param))) {
    return { value: param, unit: defaultUnit }
  } else {
    if (param.indexOf('e') > -1) {
      value = parseFloat(param).toExponential();
    } else {
      value = parseFloat(param);
    }
    unit = param.slice(-1);
    if ((unit && !isNaN(Number(unit))) || !unit) {
      // If the last character of param is still a number, set the default.
      unit = defaultUnit;
    }
    // If value is NaN
    if (isNaN(value)) {
      value = " ";
    }
    return { value, unit }
  }
}

/**
 * Update PinToPin config by config id
 * 2019.11.20
 * 
 * @export
 * @param {*} { verificationId, config, id }
 * @returns Promise
 */
function updatePinToPinConfig({ projectId, verificationId, config, id }) {
  return new Promise((resolve, reject) => {
    updateVerificationConfig({ type: 'PinToPin', projectId, verificationId, config, id }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(false)
      }
    }, error => {
      resolve(false)
    })
  })
};

function deleteInterfacePromise(interfaceIds) {
  return new Promise((resolve, reject) => {
    deleteInterfaceInPCBByTypeName(interfaceIds, 'sierra').then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        reject(res);
      }
    }, error => {
      console.error(error);
      checkError(error);
      reject(error);
    })
  })
}

/**
 * @export {object} component
 * @param {*} { type, prevType, component } - type: present type, prevType: previous type, component: previout component
 */
function componentTypeChange({ type, component }) {
  let newComp;
  if (ICTypes.includes(type)) {
    // RLC, Ignore/UNUSED, Connector -> IC
    newComp = new ICComponent({
      name: component.name,
      pins: component.pins,
      type
    });
    newComp.value = "";
    newComp.pins = newComp.pins.map(item => new ICCompPinModel({ ...item }));
  } else if (type === COMP_REPEATER) {
    newComp = new RepeaterComponent({
      name: component.name,
      pins: component.pins,
      type
    });
    newComp.pins = newComp.pins.map(item => {
      const { corner, signal, pin, net } = item;
      return new RepeaterPin({
        signal: signal,
        pin: pin,
        net: net,
        corner: corner
      })
    });
    if (newComp.pkg) {
      delete newComp.pkg;
    }
  } else if (type === TEST_POINT) {
    newComp = new BasicComponent({
      name: component.name,
      pins: component.pins,
      type,
      probePins: component.probePins ? component.probePins : []
    });
    newComp.pins = newComp.pins.map(item => {
      const { signal, pin, net } = item;
      return new BasicCompModel({ pin, net, signal });
    });
    newComp.part = component.part || '';
  } else if (type === CONNECTOR) {
    newComp = getDefaultConnector(component);
  } else {
    // IC -> RLC, Ignore,UNUSED, Connector
    // const { name, pkg, pins } = component;
    newComp = new RLCCONComponent({
      name: component.name,
      pkg: "",
      pins: component.pins,
      type,
      probePins: component.probePins ? component.probePins : []
    });
    newComp.value = component.value || "";
    newComp.pins = newComp.pins.map(item => {
      const { signal, pin, net } = item;
      return new BasicCompModel({ signal, pin, net });
    });
    if (type !== CONNECTOR && newComp.pkg) {
      delete newComp.pkg;
    }
  }
  newComp.part = component.part || '';
  return { newComp };
}

function _isPowerGND(net) {
  if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
    return false;
  };
  if (net.mName.match(/^NONE/i)) {
    return false;
  }
  // Remove v, +v, N+V NONE, GND, via length check
  // if (net.mName.match(/^(v|\+v|GND|VCC)/i)) {
  //   return true;
  // };
  // if (net.mName.match(/[0-9]+V/ig)) {
  //   return true;
  // }
  // Remove geometry check
  // if (net.mGeomList.length > 0) {
  //   const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
  //   const typeList = geomList.map(item => item instanceof CePolygon); // [true,false]
  //   // Exsit Polygon
  //   if (typeList.includes(true)) {
  //     return true;
  //   }
  // }
  // if (net.mViaList.length > 10) {
  //   return true;
  // }
  // return false;
  return true;
}

/**
 * 
 * @param {Object} -deletedComps :delete components
 *                 -components :components list
 *                 -models :models list
 *                 -stimuli :stimuli list
 *                 -signalName :signalName
 */
function deleteCompsConnectWithNets({ signalName, deletedComps, components }) {
  for (let delComp of deletedComps) {
    const compIndex = components.findIndex(item => item.name === delComp.name);
    if (compIndex < 0) {
      continue;
    }
    components[compIndex].pins = components[compIndex].pins.filter(item => !!item.signal);
    const pinModelIndex = components[compIndex].pins.findIndex(item =>
      item.signal === signalName && item.pin === delComp.pin && item.net === delComp.net
    );
    // Delete pin model
    if (pinModelIndex > -1) {
      components[compIndex].pins.splice(pinModelIndex, 1);
      //DELETE pin package
      if (components[compIndex].pkg && components[compIndex].pkg.pairs && components[compIndex].pkg.pairs.length) {
        components[compIndex].pkg.pairs = components[compIndex].pkg.pairs.filter(item => (item.pin !== delComp.pin && item.pin !== `${delComp.pin}_u`))
      }

      //delete model
      if (components[compIndex].model && components[compIndex].model.pairs && components[compIndex].model.pairs.length) {
        components[compIndex].model.pairs = components[compIndex].model.pairs.filter(item => (item.pin !== delComp.pin && item.pin !== `${delComp.pin}_u` && item.pin !== `${delComp.pin}_in`))
      }
    }
    if (components[compIndex].pins.length === 0) {
      components.splice(compIndex, 1);
    }

  };
  return { components };
}

function deleteExsitResult(verificationId) {
  return new Promise((resolve, reject) => {
    removeExsitResult(verificationId).then(res => {
      resolve(true);
    }, error => {
      console.error(error);
      resolve(true);
    })
  })
}

//library file exist check in interfaces
async function updateLibraryInInterface(Interfaces) {
  let _Interfaces = JSON.parse(JSON.stringify(Interfaces));
  let error = {}, errorExist = null, newInterfaces = [];
  for (let info of _Interfaces) {
    if (info.content) {
      let components = info.content.components ? [...info.content.components] : [];
      let newComponents = [];
      for (let comp of components) {
        //check ibis/spice model
        if (ICTypes.includes(comp.type)) {
          //comp model error check
          if (comp.model && comp.model.files && comp.model.files.length > 0) {
            let pairs = comp.model.pairs ? comp.model.pairs : [];
            for (let i = 0; i < comp.model.files.length; i++) {
              let file = comp.model.files[i];
              if (!file.libraryId) {
                continue;
              }
              const { newFile, newPairs, newError, newErrorExist } = await updateICModel({ file, pairs, comp, info, error, errorExist });
              comp.model.files[i] = newFile;
              pairs = [...newPairs];
              error = newError;
              errorExist = newErrorExist;
            }
            comp.model.pairs = pairs;
          }

          //comp pkg error check
          if (comp.pkg && comp.pkg.libraryId) {
            let file = { ...comp.pkg };
            const { newFile, newError, newErrorExist } = await updateICPackageIbis({ file, comp, info, error, errorExist });
            error = newError;
            errorExist = newErrorExist;
            comp.pkg = { ...newFile };
          }

          if (comp.pkg && comp.pkg.files && comp.pkg.files.length > 0) {
            let len = comp.pkg.files.length;
            let pairs = comp.pkg.pairs ? comp.pkg.pairs : [];
            for (let i = 0; i < len; i++) {
              let file = comp.pkg.files[i];
              if (!file.libraryId) {
                continue;
              }
              const { newFile, newPairs, newError, newErrorExist } = await updateICPackage({ file, pairs, comp, info, error, errorExist });
              comp.pkg.files[i] = newFile;
              pairs = [...newPairs];
              error = newError;
              errorExist = newErrorExist;
            }
            comp.pkg.pairs = pairs;
          }

          //comp pins model error check
          let newPins = [];
          for (let pin of comp.pins) {
            const { newPin, newError, newErrorExist } = await updateICStimulusPin({ pin, comp, info, error, errorExist });
            error = newError;
            errorExist = newErrorExist;
            //check stimulus
            if (newPin.pinModels) {
              let newModels = [];
              for (let item of newPin.pinModels) {
                if (item.stimulus && typeof (item.stimulus) === 'object' && item.stimulus.libraryId) {
                  const { newFile, newError, newErrorExist } = await updateICStimulus({ item, pin: newPin, comp, info, error, errorExist });
                  newModels.push(newFile);
                  error = newError;
                  errorExist = newErrorExist;
                } else {
                  newModels.push(item);
                }
              }
            }
            newPins.push(newPin);
          }
          comp.pins = newPins;
        }

        //repeater file check
        if (comp.type === COMP_REPEATER && comp.model) {
          let files = comp.model.files ? comp.model.files : [];
          let pairs = comp.model.pairs ? comp.model.pairs : [];
          if (comp.model.id || comp.model.name) {
            files.push({
              folder: "",
              libraryId: comp.model.id,
              fileName: comp.model.name,
              subckt: comp.model.subcktName,
              type: COMP_REPEATER
            });
            pairs = [];
            comp.pins.forEach(item => {
              pairs.push({
                pin: item.pin,
                node: item.node ? item.node : "",
                fileName: item.node ? comp.model.name : "",
                libraryId: item.node ? comp.model.id : "",
                subckt: item.node ? comp.model.subcktName : "",
                modelKey: item.node ? comp.model.id : ""
              });
              delete item.node;
            });
          }
          let newFiles = [];
          for (let file of files) {
            const { newFile, newPairs, newError, newErrorExist } = await updateRepeater({ file, pairs, comp, info, error, errorExist })
            newFiles.push(newFile);
            pairs = [...newPairs];
            error = newError;
            errorExist = newErrorExist;
          }
          const libraryIdObj = {};
          //update repeater libraryId
          for (let pair of pairs) {
            if (pair.node && pair.libraryId) {
              const findFile = newFiles.find(item => item.libraryId === pair.libraryId);
              if (!findFile && newFiles.length === 1 && newFiles[0].libraryId && newFiles[0].subckt === pair.subckt && newFiles[0].fileName === pair.fileName) {
                libraryIdObj[pair.libraryId] = newFiles[0].libraryId;
                pair.libraryId = newFiles[0].libraryId;
                continue;
              }

              if (!findFile && newFiles.length > 1) {
                pair.libraryId = "";
                pair.node = "";
                pair.subckt = "";
                pair.fileName = "";
                pair.modelKey = "";
                continue;
              }
            }
          }
          comp.model = { files: newFiles, pairs }
        }

        //connector file check
        if (comp.type === 'Connector') {
          if (comp.models && comp.models.length) {
            const { newModels, newError, newErrorExist } = await updateConnectorModels({ models: comp.models, info, error, errorExist, comp });
            comp.models = [...newModels];
            error = newError;
            errorExist = newErrorExist;
          }

          if (comp.cableModels && comp.cableModels.length) {
            const { newModels, newError, newErrorExist } = await updateConnectorModels({ models: comp.cableModels, info, error, errorExist, comp });
            comp.cableModels = [...newModels];
            error = newError;
            errorExist = newErrorExist;
          }
        }

        //passive file check
        if (comp.type === 'Res' || comp.type === 'Ind' || comp.type === 'Cap') {
          if (comp.model && (comp.model.type === 'SPICE' || comp.model.type === 'Touchstone') && comp.model.libraryId) {
            const { newComp, newError, newErrorExist } = await updatePassive({ comp, info, error, errorExist });
            comp = { ...newComp };
            error = newError;
            errorExist = newErrorExist;
          }
        }

        newComponents.push(comp);
      }

      //update
      info.content.components = [...newComponents];
    }
    newInterfaces.push(info);
  }
  return { Interfaces: _Interfaces, error, errorExist };
}

/* export function getPairs(modelType, pins) {
  let pairs = [];
  switch (modelType) {
    case COMP_REPEATER:
      pins.forEach(item => {
        let pin = {
          pin: item.pin,
          node: item.node ? item.node : "",
          libraryId: "",
          fileName: "",
          subckt: ""
        };
        pairs = [...pairs, pin];
      });
      break;
    default: break;
  }
  return pairs;
} */

function getPairs({ pins, modelType, usage }) {
  let pairs = [];
  switch (modelType) {
    case COMP_REPEATER:
      pins.forEach(item => {
        let pin = {
          pin: item.pin,
          node: item.node ? item.node : "",
          libraryId: "",
          fileName: "",
          subckt: "",
          modelKey: ""
        };
        pairs = [...pairs, pin];
      });
      break;
    case MultiPinSPICE:
      pins.forEach(item => {
        if (usage === 'Driver') {
          const pinIn = {
            pin: `${item.pin}_in`,
            node: "",
            libraryId: "",
            fileName: "",
            subckt: ""
          };
          const pinOut = {
            pin: `${item.pin}_u`,
            node: "",
            libraryId: "",
            fileName: "",
            subckt: ""
          };
          pairs = [...pairs, { ...pinIn }, { ...pinOut }];
        } else if (usage === 'Receiver') {
          let pinIn = {
            pin: `${item.pin}_u`,
            node: "",
            libraryId: "",
            fileName: "",
            subckt: ""
          };
          pairs = [...pairs, { ...pinIn }];
        }
      });
      break;
    case "Package":
      pins.forEach(item => {
        let pinR = { pin: item.pin, node: "", subckt: "", libraryId: "" };
        let pinL = { pin: `${item.pin}_u`, node: "", subckt: "", libraryId: "" };
        pairs = [...pairs, pinL, pinR];
      });
      break;
    default: break;
  }
  return pairs;
}

function getSPLibraryList(list) {
  let libraryList = [];
  const _list = list ? JSON.parse(JSON.stringify(list)) : []
  _list.forEach(item => {
    if (item.type === 'file' && item.name.match(/.sp$/i)) {
      //.sp file
      libraryList.push(item);
    } else if (item.type === 'folder') {
      const children = [];
      if (item.children) {
        item.children.forEach(file => {
          //file and .sp
          if (file.type === 'file' && file.fileName.match(/.sp$/i)) {
            children.push(file);
          }
        });
      }

      item.children = children.length > 0 ? children : item.children;
      libraryList.push(item);
    }
  });
  return libraryList.length > 0 ? libraryList : list;
}

function multiPinSpiceComponent({ components, component, pin, prevUsage, usage }) {
  let compIndex = components.findIndex(item => item.name === component);
  if (compIndex < 0 || !components[compIndex].pins) {
    return components;
  }

  let model = components[compIndex].model, libType = "";
  if (model && model.files && model.files[0]) {
    libType = model.files[0].type;
  }
  if (libType !== MultiPinSPICE) {
    return components;
  }
  let pinIndex = components[compIndex].pins.findIndex(item => item.pin === pin);
  components[compIndex].pins[pinIndex].model = { libType: MultiPinSPICE };
  if (model && model.pairs && model.pairs.length) {
    if (prevUsage !== usage) {
      const pinInIndex = model.pairs.findIndex(item => item.pin === `${pin}_in`);
      const pinDieIndex = model.pairs.findIndex(item => item.pin === `${pin}_u`);
      if (pinInIndex > -1 && usage === 'Driver') {
        model.pairs[pinInIndex].node = "";
        model.pairs[pinInIndex].libraryId = "";
        model.pairs[pinInIndex].fileName = "";
        model.pairs[pinInIndex].subckt = "";
      } else if (usage === 'Driver') {
        model.pairs.push({
          pin: `${pin}_in`,
          node: "",
          libraryId: "",
          fileName: "",
          subckt: ""
        })
      }

      if (pinDieIndex > -1 && (usage === 'Driver' || usage === 'Receiver')) {
        model.pairs[pinDieIndex].node = "";
        model.pairs[pinDieIndex].libraryId = "";
        model.pairs[pinDieIndex].fileName = "";
        model.pairs[pinDieIndex].subckt = "";
      } else if (usage === 'Driver' || usage === 'Receiver') {
        model.pairs.push({
          pin: `${pin}_u`,
          node: "",
          libraryId: "",
          fileName: "",
          subckt: ""
        })
      }

      if (usage === 'Receiver' && pinInIndex > -1) {
        model.pairs = model.pairs.filter(item => item.pin !== `${pin}_in`);
      }
    }
  }

  components[compIndex].model = model;

  if (usage === "Receiver") {
    components[compIndex].pinModels = []
  }
  return components;
}

function updateComponentsByPowerNets({ PowerNets, components }) {
  const PowerNetNames = PowerNets.map(item => item.name);
  components.forEach(comp => {
    if (comp.pins && Array.isArray(comp.pins)) {
      //Delete the component pin connected to the deleted power nets
      comp.pins = comp.pins.filter(item => (!!item.signal) || PowerNetNames.includes(item.net));
    }
  })
  return components;
}

function updateLibraryHelper({ id, accept, type, fileName }) {
  switch (type) {
    case IBIS:
      updateIbisModelList(id);
      break;
    case SPICE:
      if (accept === '.zip') {
        updateFolderFileDetail(id);
      } else {
        updateSpiceModelList(id);
      }
      break;
    case Connector:
      updateLibraryConnectorFile(id);
      break;
    case REPEATER:
      if (accept === '.zip') {
        updateFolderFileDetail(id);
      } else {
        updateLibraryRepeaterFile(id);
      }
      break;
    case PKG_TOUCHSTONE:
      updateTouchstoneParse(id, fileName);
      break;
    case PKG_SPICE:
      updatePkgSpiceModelList(id);
      break;
    default: break;
  }
}

function updateMultipleInterfacePromise({ verificationParams, enableFilter }) {
  return apiProcess(updateMultipleInterfaceInPCB, { verificationParams, enableFilter });
};

function combineInterfacePromise(verificationIds) {
  return apiProcess(combineInterfaces, verificationIds)
}

function getSierraPreLayout(id) {
  return apiProcess(getSierraPreLayoutInfoById, id)
}

function saveSierraPreLayout(data) {
  return apiProcess(updateSierraPreLayout, data)
}

/**
 * Get sierra create interfaces status and log
 * @param {string} createKey hash key
 *  */
function getCreateInterfaceStatusInfo(createKey) {
  return apiProcess(getSierraCreateInterfaceStatusInfo, createKey)
}

export {
  multiPinSpiceComponent,
  getSPLibraryList,
  getPairs,
  updateLibraryInInterface,
  deleteExsitResult,
  deleteCompsConnectWithNets,
  componentTypeChange,
  deleteInterfacePromise,
  updatePinToPinConfig,
  settingUnit,
  changeConfigFormat,
  createPinToPinConfig,
  getPowerComponents,
  delVerification,
  autoFindPowerGNDNets,
  getComponentsWithNetList,
  autoFilterSignalNets,
  updateInterfacePromise,
  createInterfacePromise,
  getVerificationContentPromise,
  createVerificationPromise,
  getSierraProjectPromise,
  updateComponentsByPowerNets,
  updateLibraryHelper,
  updateMultipleInterfacePromise,
  combineInterfacePromise,
  getSierraPreLayout,
  saveSierraPreLayout,
  getSierraLayoutComponents,
  getCreateInterfaceStatusInfo
}