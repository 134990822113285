class PCBInfo {
  constructor() {
    this.getPCBInfo.bind(this);
    this.savePCBInfo.bind(this);
    this.deletePCBInfo.bind(this);
  }

  getPCBInfo(id) {
    return this[id];
  }

  savePCBInfo(id, info) {
    this[id] = info;
  }

  deletePCBInfo(id) {
    if (this[id]) {
      delete this[id]
    }
  }
}

const Info = new PCBInfo();
export default Info;
