import {
  SAVE_OPEN_PROJECT_INFO,
  LIBRARY_MENU, PROJECT_MENU,
  CHANGE_LAYOUT, CLEAR_CURRENT_PROJECT,
  TRASH_MENU, CHANGE_VIEW_LIST,
  UPDATE_PCBS_LAYOUTDB,
  CHANGE_TREE_SELECTED_KEY,
  UPDATE_VIEW_STATUS,
  REFRESH_PCB,
  UPDATE_CURRENT_VERIFICATION_STATUS,
  DEBUG_DOWNLOAD_LOG_UPDATE,
  DOWNLOAD_DEBUG_MSG,
  DOWNLOAD_DEBUG_MSG_CLOSE,
  UPDATE_DOWNLOAD_MSG,
  UPDATE_COPY_LIST,
  UPDATE_GENERATE_PORTS_ERRORS,
  UPDATE_PCB_LAYOUT,
  UPDATE_PRELAYOUT_DATA,
  UPDATE_REPORT_INFO,
  CLEAR_REPORT_INFO
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { sierraDefaultTree } from '@/services/Sierra/library/libraryData';
import { SINGLE_PAGE_LAYOUT, PCB_ONLY } from '@/constants/layoutConstants';

const defaultState = {
  currentProjectId: null,
  treeItems: JSON.parse(JSON.stringify(sierraDefaultTree)),
  verificationId: null,
  layout: SINGLE_PAGE_LAYOUT,
  viewList: [],
  pcbComponentsNets: [],
  preLayoutReady: [],
  selectedKeys: [],
  projectList: [],
  copyProjectList: [],
  currentProjectVerifications: [],
  refreshStatus: false,
  verificationStatus: null,
  generatePortsErrors: null,
  pcbLayout: PCB_ONLY,
  reportInfo: {}
}

export const SierraProjectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_VIEW_STATUS:
      return {
        ...state,
        verificationId: action.verificationId || state.verificationId,
        getStatus: action.getStatus || false,
        verificationName: action.verificationName || state.verificationName
      };
    case SAVE_OPEN_PROJECT_INFO:
      return {
        ...state,
        currentProjectId: action.id,
        currentProjectVerifications: action.verifications,
        currentProjectName: action.name
      }
    case LIBRARY_MENU:
      return {
        ...state,
        treeItems: [...action.treeItems]
      }
    case PROJECT_MENU:
      return {
        ...state,
        treeItems: action.treeItems,
        projectList: action.projectList ? action.projectList : state.projectList,
        copyProjectList: action.copyProjectList ? action.copyProjectList : state.copyProjectList,
        currentProjectVerifications: action.verifications ? action.verifications : state.currentProjectVerifications
      }
    case CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      }
    case CLEAR_CURRENT_PROJECT:
      if (action.status) {
        return {
          ...state,
          currentProjectId: null,
          verificationId: null,
          viewList: []
        }
      } else {
        return {
          ...state,
          verificationId: null,
        }
      }
    case TRASH_MENU:
      return {
        ...state,
        treeItems: [...action.treeItems]
      }
    case CHANGE_VIEW_LIST:
      return {
        ...state,
        viewList: [...action.viewList]
      }
    case UPDATE_PCBS_LAYOUTDB:
      return {
        ...state,
        pcbComponentsNets: action.pcbsLayoutDB
      }
    case UPDATE_PRELAYOUT_DATA:
      return {
        ...state,
        preLayoutReady: action.prelayoutData
      }
    case CHANGE_TREE_SELECTED_KEY:
      return {
        ...state,
        selectedKeys: action.selectedKeys
      }
    case REFRESH_PCB:
      return {
        ...state,
        refreshStatus: action.refreshStatus
      }
    case USER_LOGOUT:
      return {
        ...defaultState,
        treeItems: JSON.parse(JSON.stringify(sierraDefaultTree))
      }
    case UPDATE_CURRENT_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: action.verificationStatus
      }
    case DEBUG_DOWNLOAD_LOG_UPDATE:
      return {
        ...state,
        debugDownloadMsg: [...action.debugDownloadMsg],
        debugDownloadMsgShow: true
      }
    case DOWNLOAD_DEBUG_MSG:
      return {
        ...state,
        debugDownloadMsgShow: true
      }
    case DOWNLOAD_DEBUG_MSG_CLOSE:
      return {
        ...state,
        debugDownloadMsgShow: false,
        debugDownloadMsg: []
      }
    case UPDATE_DOWNLOAD_MSG:
      return {
        ...state,
        debugDownloadMsg: [...action.msg]
      }
    case UPDATE_COPY_LIST:
      return {
        ...state,
        copyProjectList: [...action.copyProjectList]
      }
    case UPDATE_GENERATE_PORTS_ERRORS:
      return {
        ...state,
        generatePortsErrors: action.errorObj
      }
    case UPDATE_PCB_LAYOUT:
      return {
        ...state,
        pcbLayout: action.pcbLayout
      }
    case UPDATE_REPORT_INFO:
      return {
        ...state,
        reportInfo: { ...state.reportInfo, ...action.info }
      }
    case CLEAR_REPORT_INFO:
      return {
        ...state,
        reportInfo: {}
      }
    default: return state
  }
};