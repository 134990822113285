import React, { PureComponent, Fragment } from "react";
import { Input } from 'antd';
import { METAL, MetalMaterial, SIGMA, stackupItemErrorCheck } from '../../services/Stackup';
import { ANDES_V2, CASCADE, ROCKY } from "../../constants/pageType";
import { COPPER, SOLDER, MIU_R } from "../../services/Stackup/Material";

class MetalSetup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      config: this.generateMaterialConfig()
    }
  }

  getMaterialConfig = () => {
    return this.state.config;
  }

  generateMaterialConfig = () => {
    const { material, pageType } = this.props;
    if (pageType === ANDES_V2 || pageType === CASCADE || pageType === ROCKY) {
      return typeof (material) === 'object' ? Object.keys(material).includes(MIU_R) ? material : { ...material, [MIU_R]: '1' } : new MetalMaterial({}, pageType)
    }
    return typeof (material) === 'object' ? material : new MetalMaterial({})
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.updateConfig();
  }

  updateConfig = () => {
    this.setState({
      config: this.generateMaterialConfig()
    })
  }

  changeValue = (e, type) => {
    const value = e.target.value;
    const { errorMsg } = this.props;
    this.setState({
      config: {
        ...this.state.config,
        [type]: value
      }
    });
    const _errorMsg = errorMsg && errorMsg.type === type ? null : errorMsg;
    this.props.updateErrorMsg(_errorMsg);
  }

  saveValue = (e, type) => {
    const value = e.target.value;
    // Error check
    const error = stackupItemErrorCheck(type, value, { type: METAL });
    if (error) {
      e.target.focus && e.target.focus();
      this.props.updateErrorMsg({ type, error: error.error });
      return error;
    }
    this.setState({
      config: {
        ...this.state.config,
        [type]: String(value) // remove zero
      }
    });
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  render() {
    const { pageType } = this.props;
    const { config } = this.state;
    return <Fragment>
      <div className='material-definition-item material-metal-item'>
        {/* name */}
        <label className='material-edit-value-label'>Conductivity</label>
        <Input
          className='aurora-input material-input'
          placeholder='Conductivity'
          value={config.sigma}
          onChange={(e) => this.changeValue(e, SIGMA)}
          onBlur={(e) => this.saveValue(e, SIGMA)}
          onKeyDown={(e) => this.keyDown(e)}
          addonAfter={"S/m"}
          disabled={[SOLDER, COPPER].includes(config.name)}
        />
      </div>
      {
        (pageType === ANDES_V2 || pageType === CASCADE || pageType === ROCKY) ? <div className='material-definition-item material-metal-item'>
          {/* name */}
          <label className='material-edit-value-label'>Relative Permeability</label>
          <Input
            className='aurora-input material-input'
            placeholder='Relative Permeability'
            value={config[MIU_R]}
            onChange={(e) => this.changeValue(e, MIU_R)}
            onBlur={(e) => this.saveValue(e, MIU_R)}
            onKeyDown={(e) => this.keyDown(e)}
            disabled={[SOLDER, COPPER].includes(config.name)}
          />
        </div> : null
      }
    </Fragment>
  }
}

export default MetalSetup;