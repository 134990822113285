import { getSierraLibraryTree, getSierraLibraryMap, getSierraLibraryFolder } from './folderCtrl';

class LibraryStorage {
  constructor() {
    this.folder = new Map(); //key - library Id / library type(Outermost), value - folder{}

    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
    this.getMap = this.getMap.bind(this);
    this.hasMap = this.hasMap.bind(this);
    //this.delete = this.delete.bind(this);

    this.tree = new Map(); //key - type, value -tree

    this.getTree = this.getTree.bind(this);
    this.setTree = this.setTree.bind(this);
    this.updateTree = this.updateTree.bind(this);
    this.checkFile = this.checkFile.bind(this);
  }

  set(id, folder) {
    this.folder.set(id, folder)
  }

  get({ id, type, update = false }) {
    return new Promise((resolve, reject) => {
      const folder = id === '-1' ? this.folder.get(type) : this.folder.get(id);
      if (folder && !update) {
        resolve(folder);
      } else {
        getSierraLibraryFolder(id, type).then(res => {
          const list = mergeList(res);
          const _id = id === '-1' ? type : id;
          this.set(_id, list);
          resolve(list);
        })
      }
    })
  }

  hasMap({ id, type }) {
    const folder = id === '-1' ? this.folder.get(type) : this.folder.get(id);
    return folder ? true : false;
  }

  getMap() {
    return new Promise((resolve, reject) => {
      getSierraLibraryMap().then(res => {
        let library = {};
        for (let key in res) {
          const list = mergeList(res[key]);
          library[key] = list;
          this.set(key, list);
        }
        resolve(library);
      })
    })
  }

  getTree(type) {
    return this.tree.get(type) || [];
  }

  setTree(type, list) {
    this.tree.set(type, list);
  }

  updateTree(type) {
    return new Promise((resolve, reject) => {
      getSierraLibraryTree(type).then(res => {
        const list = processingTree(res);
        this.setTree(type, list.children);
        resolve(list);
      }, error => {
        resolve(null)
      })
    })
  }

  checkFile(type, id, childName) {
    const tree = this.getTree(type);
    return !tree || !id ? false : fileExsit(tree, id, childName);
  }

  findFileByName = (type, fileName, folder) => {
    const tree = this.getTree(type);
    return !tree || !fileName ? false : findFileByFileName(tree, fileName, folder);
  }
}


function mergeList(obj) {
  const { folders, libraries } = obj;
  return [...folders.map(item => ({ ...item, libraryType: 'folder' })),
  ...libraries.map(item => ({ ...item, libraryType: 'file' }))];
}

function processingTree(list) {
  const { name, libraries, folders, folderId } = list;
  // folder - { name, libraries, folders }
  const _folders = folders.map(folder => processingTree(folder));
  return {
    name,
    id: folderId,
    children: [..._folders, ...libraries],
    type: 'folder'
  }
}

export function fileExsit(tree, id, childName) {
  let exsit = false;
  for (let item of tree) {
    if (item.type === 'folder') {
      exsit = fileExsit(item.children || [], id);
    } else if (item.id === id && !childName) {
      exsit = { ...item };
    } else if (item.id === id && childName) {
      if ((item.children || []).find(child => child.fileName === childName)) {
        exsit = { ...item };
      }
    }
    if (exsit && exsit.id) {
      break;
    }
  }
  return exsit;
}

export function findFileByFileName(tree, fileName, folder) {
  let exsit = false;
  for (let item of tree) {
    if (item.type === 'folder') {
      exsit = findFileByFileName(item.children || [], fileName, folder);
    } else if (item.name === folder && fileName) {
      if ((item.children || []).find(child => child.fileName === fileName)) {
        exsit = { ...item };
      }
      //folder
    } else if (item.name === fileName && !folder) {
      exsit = { ...item };
    }
    if (exsit && exsit.id) {
      break;
    }
  }
  return exsit;
}

const sierraLibrary = new LibraryStorage();
export default sierraLibrary;