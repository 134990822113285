import http from "../http";
import JSZip from 'jszip';

/**
 * Get library list
 * 2023/05/22
 * @export
 * @return */
export function getHimalayasLibraryList() {
  return http.get(`/library/Himalayas/list`)
}

/**
 * Delete library by id
 * 2023/05/22
 * @export
 * @returns
 */
export function delHimalayasLibrary(libraryId) {
  return http.post(`/library/Himalayas/delete`, { id: libraryId });
}

/**
 * get library detail content by id
 * 2023/05/23
 * @param {string} libraryId
 * @return */
export function getHimalayasLibraryDetail(libraryId) {
  return http.get(`/library/Himalayas/resource?libraryId=${libraryId}`)
}

/**
 * create library ,upload file
 * 2023/05/25
 * @param {files} files
 * @param {string} fileType //file / data
 * @param {string} typeName //spice_parameters / soc_spice / soc_wrapper / pvt / pcb_subckt
 * @return */
export function uploadHimalayasLibrary({ files, fileType, typeName, libraries, config, metadata }) {
  let formData = new FormData();
  //file
  let zip = new JSZip();
  for (let file of files) {
    zip.file(file.fileName, file.file);
  }
  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level: 1 }
  }, metadata).then(blob => {
    formData.append('file', new File([blob], 'file.zip'));
    formData.append('typeName', typeName);
    formData.append('fileType', fileType);
    if (libraries && libraries.length) {
      formData.append('libraries', JSON.stringify(libraries));
    }
    return http.post(`/library/Himalayas/upload`, formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  });
}