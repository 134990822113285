function topBarResize(element, minWidth, elementType = 'id') {
  let TopBar = null;
  if (elementType === 'id') {
    TopBar = document.getElementById(element);
  } else {
    TopBar = document.getElementsByClassName(element) && document.getElementsByClassName(element)[0];
  }
  if (!TopBar) return;
  if (TopBar && TopBar.getBoundingClientRect()) {
    const { width } = TopBar.getBoundingClientRect();

    if (width < minWidth) {
      TopBar.className = 'hide';
    } else {
      if (TopBar.className) {
        TopBar.className = '';
      }
    }
  }
}

export { topBarResize };