const ANDES = 'Andes';
export const LOCATION_CHANGE = `${ANDES}/location`;
export const NET_CHANGE = `${ANDES}/net_change`;
export const LAYER_CHANGE = `${ANDES}/layer_change`;
export const COMP_CHANGE = `${ANDES}/comp_change`;
export const CLEAR_SELECTION = `${ANDES}/clear_selection`;
export const OPEN_PANEL = `${ANDES}/open_panel`;
export const CLOSE_PANEL = `${ANDES}/close_panel`;
export const COLORBY_CHANGE = `${ANDES}/colorby_change`;
export const SELECTION_CLEAR_ONE = `${ANDES}/selection_clear_one`;
export const SELECTED_ALL = `${ANDES}/selected_all`;
export const CANCEL_SELECTED_ALL = `${ANDES}/cancel_selected_all`;
export const SHOW_SELECTED = `${ANDES}/show_selected`;
export const HIDE_SELECTED = `${ANDES}/hide_selected`;
export const SELECTION_CHECKBOX_CHANGE = `${ANDES}/selection_checkbox_change`;
export const CLEAN_STATUS = `${ANDES}/clean_status`;
export const CHANGE_SELECTED_DISPLAY = 'change_selected_display';
export const SELECT_CHANGE = `${ANDES}/select_change`;