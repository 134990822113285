import { connect } from 'react-redux';
import DesignUpload from '@/components/UploadPCB/designUpload'
import {
  uploadPCBStart,
  updateUploadProgress,
  updateMsg,
  cleanUploadPCBStatus,
  translationFlow,
  updateCompressProgress,
  updateDesignName,
  changeUploadDisableStatus
} from './store/action'
import {
  openTabFooter,
  changeTabMenu,
  cleanTabMonitorStatus
} from '../../../MonitorStore/action';

const mapState = (state) => {
  const CascadeUploadReducer = state.CascadeReducer.CascadeUploadReducer;
  const {
    compressProgress,
    uploadProgress,
    disabled
  } = CascadeUploadReducer;
  return {
    disabled,
    compressProgress,
    uploadProgress
  }
}

const mapDispatch = (dispatch) => ({
  _cleanTabMonitorStatus() {
    dispatch(cleanTabMonitorStatus())
  },
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  _changeTabMenu(info) {
    dispatch(changeTabMenu(info))
  },
  _uploadPCBStart(projectId, uploadType) {
    dispatch(uploadPCBStart(projectId, uploadType))
  },
  _updateUploadProgress(progress) {
    dispatch(updateUploadProgress(progress))
  },
  _updateMsg(msg) {
    dispatch(updateMsg(msg))
  },
  _translationFlow(response, designId) {
    dispatch(translationFlow(response, designId))
  },
  _cleanUploadPCBStatus() {
    dispatch(cleanUploadPCBStatus())
  },
  _updateCompressProgress(progress) {
    dispatch(updateCompressProgress(progress))
  },
  _updateDesignName(designName, designType) {
    dispatch(updateDesignName(designName, designType))
  },
  _changeUploadDisableStatus(disabled) {
    dispatch(changeUploadDisableStatus(disabled))
  }
})

export default connect(mapState, mapDispatch)(DesignUpload)