import React, { Component, Fragment, createRef } from 'react';
import LayoutData from '@/services/data/LayoutData';
import canvas from '@/services/LayoutCanvas';
import ContextMenu from '@/components/ContextMenu';
import Coord from '../Coord';
import { Spin } from 'antd';
import makeCancelable from '@/services/api/makeCancelable';
import designsDB from '@/services/helper/designConstructor';
import { AURORA_AAF, AURORA_DB, PRE_LAYOUT } from '../../../constants/designVendor';
import menu from '../../../components/LayoutExplorer/menu';
import { getMetalCompLayers } from '@/services/PCBHelper';
import { equal } from '../../../services/utility/ArrayHelper';
import { isEqualObject } from '../../../services/utility/ObjectHelper';
import { getOffsetLeft, getOffsetTop } from '../../../services/helper/htmlElement';
import { ANDES_V2, CASCADE, SIERRA, FASTPI, ROCKY } from '../../../constants/pageType';
import designConstructor from '../../../services/helper/designConstructor';
import { CARD, PACKAGE, PCB } from '../../../constants/treeConstants';
import { getCompsAndNetsCrossPins } from '../../../services/helper/dataProcess';
import '../index.css';
import '../pdnLayoutStyle.css';

class LayoutExplorer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.locationMark = createRef();
    this.state = {
      metalLayers: [],
      contextMenu: {},
      searchData: [],
      loading: true,
      maxWidth: null,
      maxHeight: null,
      offsetLeft: null,
      offsetTop: null,
      showSelfStackup: true,
      showBigPanel: false
    }
    this.layout = null;
    this.viewLayout = null
    this.designID = props.designID;
  }

  changeMouse = (x, y) => {
    this.props.updateLocation(x.toFixed(2) + ', ' + y.toFixed(2), this.designID);
  }

  canvasSelect = (canvasObj) => {
    const { leftWidth, topHeight } = this.props;
    if (canvasObj && canvasObj.nets && canvasObj.comps) {
      const { nets, comps, x, y, multi } = canvasObj;
      const top = topHeight ? y - 64 - topHeight : y - 64;
      this.setState({
        contextMenu: {
          style: {
            display: 'block',
            top: top,
            left: x - leftWidth
          },
          items: [...nets, ...comps],
          onClick: (e) => {
            const name = e.target.innerText;
            const obj = { multi };
            if (nets.includes(name)) {
              obj.nets = [name];
            } else {
              obj.comps = [name];
            }
            this.props.select(obj, this.designID);
            this.setState({
              contextMenu: {}
            })
          }
        }
      })
    } else {
      const { hybridStatus } = this.props;
      if (hybridStatus) {
        return;
      }
      this.props.select(canvasObj, this.designID);
      this.setState({
        contextMenu: {}
      })
    }
  }

  getComponentLength(pcbId) {
    const metalLayers = LayoutData.getLayout(pcbId).GetLayerManager().mMetalLayers;
    let length = 0;
    for (const layer of metalLayers) {
      const { mComponentLayer } = layer;
      if (!mComponentLayer) continue;
      const { mComponents } = mComponentLayer;
      length += mComponents.length;
    };
    return length;
  }

  componentDidMount() {
    this.renderDesign();
    this.getPanelWidthHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    const { designID, selections, layout, pageType } = this.props;
    const selectionObj = selections[designID] || {};
    const prevSectionObj = prevProps.selections[designID] || {};
    const refresh = selectionObj.refresh && selectionObj.refresh !== prevSectionObj.refresh;

    if ((prevProps.designID !== designID && designID)
      || refresh
    ) {
      canvas && canvas.clearLayout(designID);
      this.designID = designID;
      this.setState({
        loading: true,
      });
      this.props.updatePCBRefreshStatus && this.props.updatePCBRefreshStatus(false, this.designID)
      this.renderDesign()
      if ([CASCADE, ROCKY, ANDES_V2].includes(pageType)) this.updateStackupList()
      //close zones display
      if ([CASCADE, ANDES_V2, SIERRA].includes(pageType)) {
        this.props._updateSelectedZones([], prevProps.designID);
        this.props._changeStackupZonePanelShow(false, prevProps.designID);
      }
    }

    if ([CASCADE, ROCKY, ANDES_V2].includes(pageType) && this.viewLayout !== layout) {
      this.viewLayout = layout
      this.closeAllStackupStatus()
    }

    this.getPanelWidthHeight(prevState);
  }

  getStackupJson = () => {
    const { designID } = this.props;
    LayoutData.getStackupJson({ pcbId: designID, reload: true }).then(stackupData => {
      if (!stackupData || !stackupData.stackup || !stackupData.stackup.zones) {
        return;
      }
      const unit = stackupData.getUnitString();
      const data = stackupData.getStackupTableData();
      this.props.setStackupData && this.props.setStackupData({
        data: data.tableData,
        materialList: data.materialList,
        stackups: data.stackups,
        zones: data.zones,
        bends: data.bends,
        unit,
        designID
      });
      this.props._updateShowZonesStatus && this.props._updateShowZonesStatus(data.zones ? true : false, designID);
    })
  }

  updateStackupList = () => {
    const { getNewStackupList, stackupListProps } = this.props;
    const allIds = { ...stackupListProps, currentID: this.designID }

    // update stackupList when page change
    let { stackupList } = this.props
    if (stackupList) {
      stackupList = getNewStackupList(stackupList, 'close', allIds, false)
      this.props.updateStackupList(stackupList)
    }
  }

  closeAllStackupStatus() {
    let { stackupList } = this.props
    if (stackupList) {
      stackupList.forEach(item => item.status = 'close')
      this.props.updateStackupList(stackupList)
    }
  }

  async renderDesign() {
    this._isMounted = true;
    this.props.closePanel(this.designID);
    this.setState({
      loading: true,
    })
    const design = designsDB.getDesign(this.designID);
    if (!design || design.vendor === PRE_LAYOUT) {
      this.setState({
        loading: false
      })
      return;
    }

    const loadLayoutDBPromise = LayoutData.LoadLayoutDB(this.designID);
    this.cancelableLoadLayout = makeCancelable(loadLayoutDBPromise);
    try {
      await this.cancelableLoadLayout.promise;
    } catch (error) {
      console.error(error);
      return;
    }
    LayoutData.saveNetsList(this.designID);
    const events = {
      click: this.canvasSelect,
      mousemove: this.changeMouse
    };
    if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
      this.svgRef.current.removeChild(this.svgRef.current.children[0])
    }
    const _DesignData = LayoutData.getLayout(this.designID);
    this.layout = canvas.getLayout(this.designID);
    if (!this.layout) {
      this.layout = canvas.createLayout(this.designID);
      this.layout.initLayoutRender(this.svgRef.current, _DesignData, this.locationMark.current, events);
    } else {
      this.layout.rebindSvg(this.svgRef.current, this.locationMark.current, events)
    }

    if (design.vendor === AURORA_DB || design.vendor === AURORA_AAF) {
      this.generateStackup(this.designID);
    }
    const defaultLayer = this.defaultRenderLayer(_DesignData, this.designID);
    window.addEventListener('resize', this.resize);
    const obj = this.props.selections[this.designID];
    if (obj) {
      const { selectedNets = [], selectedComps = [], layers = [], showNameStatus = false } = obj;
      let showName = false;
      if ([SIERRA, CASCADE, ROCKY].includes(this.props.pageType) && showNameStatus) {
        showName = true;
      }
      let _selectLayer = this.layout.findCurrentLayer(selectedComps);
      let newLayer = [...new Set([...layers, ..._selectLayer])];
      this.props.selectLayer(newLayer, this.designID);
      this.layout.updateLayers(newLayer, []);
      if (selectedNets.length > 0 || selectedComps.length > 0) {
        this.props.changeDisplaySelected(true, this.designID);
        const connectPins = getCompsAndNetsCrossPins({ nets: selectedNets, comps: selectedComps, designId: this.designID })
        this.layout.selectNetCompPin({ nets: selectedNets, comps: selectedComps, connectPins, showName, designId: this.designID });
      }
    } else {
      this.props.selectLayer(defaultLayer, this.designID);
      this.layout.updateLayers(defaultLayer, []);
    }
    if (this.props.pageType !== FASTPI) {
      this.getStackupJson();
    }
  }

  defaultRenderLayer(designData, designID) {
    const _LayerManager = designData.GetLayerManager();
    const metalLayerNames = _LayerManager.GetAllMetalLayers().mValues;
    const topComp = _LayerManager.GetMetalCompLayer(metalLayerNames[0]);
    let defaultLayer = [];
    if (this._isMounted) {
      this.setState({
        metalLayers: getMetalCompLayers(metalLayerNames, _LayerManager),
        unit: designData.GetLayoutUnits()
      });
      // set the first layer's component layer to be visible
      if (this.getComponentLength(designID) < 1000) {
        if (topComp) {
          defaultLayer = [topComp.GetName(), metalLayerNames[0]];
        } else {
          defaultLayer = [metalLayerNames[0]];
        }
      }
      this.setState({
        loading: false,
      })
    }
    return defaultLayer;
  }

  getPanelWidthHeight = (prevState) => {
    this.layoutRef = document.getElementsByClassName(`aurora-PCB-layout-content-${this.designID}`)[0];
    if (this.layoutRef) {

      const maxWidth = this.layoutRef.offsetWidth;
      const maxHeight = this.layoutRef.offsetHeight;

      const offsetLeft = getOffsetLeft(this.layoutRef);
      const offsetTop = getOffsetTop(this.layoutRef);
      if (!prevState || (maxWidth && (maxWidth - 100) !== prevState.maxWidth)) {
        this.setState({
          maxWidth: maxWidth - 100
        });
      }
      if (!prevState || (maxHeight && (maxHeight - 127) !== prevState.maxHeight)) {
        this.setState({
          maxHeight: maxHeight - 127
        });
      }

      if (!prevState || (offsetLeft && offsetLeft !== prevState.offsetLeft)) {
        this.setState({
          offsetLeft
        });
      }

      if (!prevState || (offsetTop && offsetTop !== prevState.offsetTop)) {
        this.setState({
          offsetTop
        });
      }
    }
  }

  resize = () => {
    this.getPanelWidthHeight()
  }

  generateStackup(pcbId) {
    if (this.props.pageType === FASTPI) {
      LayoutData.getStackup(pcbId, false, FASTPI);
    } else {
      LayoutData.getStackupJson({ pcbId, reload: true, save: true });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { colorBy: prevColorBy, selections: prevSelection } = this.props;
    const { colorBy, selections } = nextProps;
    let prevNets = [], prevComps = [], prevPins = {}, prevLayers = [], prevShowNameStatus = false,
      selectedNets = [], selectedComps = [], selectedPins = {}, layers = [], showNameStatus = false;
    const prevObj = prevSelection[this.designID];
    if (prevObj) {
      prevNets = prevObj.selectedNets || [];
      prevComps = prevObj.selectedComps || [];
      prevPins = prevObj.selectedPins || {};
      prevLayers = prevObj.layers || [];
      prevShowNameStatus = prevObj.showNameStatus || false;
    }

    const selectObj = selections[this.designID];
    if (selectObj) {
      selectedNets = selectObj.selectedNets || [];
      selectedComps = selectObj.selectedComps || [];
      selectedPins = selectObj.selectedPins || {};
      layers = selectObj.layers || [];
      showNameStatus = selectObj.showNameStatus || false;
    }

    let showName = false
    if ([SIERRA, CASCADE, ROCKY].includes(this.props.pageType) && showNameStatus) {
      showName = true
    }

    if (!equal(layers, prevLayers)) {
      this.layout && this.layout.updateLayers(layers, prevLayers, showName);
    }

    if (colorBy[this.designID] !== prevColorBy[this.designID]) {
      this.layout && this.layout.changeColorMode(colorBy[this.designID]);
      return false;
    }

    if (!equal(selectedComps, prevComps) || !equal(selectedNets, prevNets) || !isEqualObject(selectedPins, prevPins) || prevShowNameStatus !== showNameStatus) {
      if (this.layout) {
        let _selectLayer = this.layout.findCurrentLayer(selectedComps);
        let newLayer = Array.from(new Set([...layers, ..._selectLayer]));
        this.props.changeDisplaySelected(true, this.designID);
        this.layout.updateLayers(newLayer, []);
        this.props.selectLayer(newLayer, this.designID);
        setTimeout(() => {
          const connectPins = getCompsAndNetsCrossPins({ nets: selectedNets, comps: selectedComps, designId: this.designID })
          this.layout.selectNetCompPin({ nets: selectedNets, comps: selectedComps, pins: selectedPins, connectPins, showName, designId: this.designID })
        }, 500);
      }
    }
    return true;
  }

  onSearch = (value) => {
    if (this.layout && this.layout.search) {
      return this.layout.search(value);
    } else {
      return null;
    }
  }

  onShowVias = (name, lastName) => {
    return this.layout.selectViaHoles(name, lastName);
  }

  onJudge = (element) => {
    if (this.layout && this.layout.judgeLayoutElement) {
      return this.layout.judgeLayoutElement(element);
    }
    return null;
  }

  getPanel = () => {
    const { panelType, pageType, layoutResults = {},
      ComponentTable, NetTable, Stackup, projectId, StackupPanel, PadStackInfo, PCBDownload, LayoutError } = this.props;
    const { maxWidth, maxHeight, offsetLeft, offsetTop, showSelfStackup, showBigPanel } = this.state;
    const _maxWidth = maxWidth > 50 ? maxWidth : 50;
    const currentPCBs = designConstructor.getAvailableDesigns(projectId);
    const layoutError = layoutResults[this.designID];
    if (panelType[this.designID]) {
      switch (panelType[this.designID]) {
        case 'Stackup':
          if (showSelfStackup) {
            return <Stackup
              {...this.props}
              maxHeight={maxHeight}
              maxWidth={_maxWidth}
              leftWidth={offsetLeft}
              topWidth={offsetTop}
              designID={this.designID}
              closePanel={this.closeStackupPanel}
              _closePanel={() => this.props.closePanel(this.designID)}
            />
          }
          else {
            return showBigPanel && <StackupPanel
              {...this.props}
              designID={this.designID}
              designList={currentPCBs}
              Stackup={Stackup}
              closeModal={this.closeStackupPanel} />
          }
        case 'Components':
          return <ComponentTable
            maxHeight={maxHeight}
            maxWidth={_maxWidth}
            leftWidth={offsetLeft}
            topWidth={offsetTop}
            designID={this.designID}
            select={this.select}
          />
        case 'Nets':
          return <NetTable
            maxHeight={maxHeight}
            maxWidth={_maxWidth}
            leftWidth={offsetLeft}
            topWidth={offsetTop}
            designID={this.designID}
            select={this.select}
          />
        case 'Padstacks':
          return <Fragment>
            <PadStackInfo
              maxHeight={maxHeight}
              maxWidth={_maxWidth}
              leftWidth={offsetLeft}
              topWidth={offsetTop}
              designId={this.designID}
              onShowVias={this.onShowVias}
            />
            <div id='pad-stack-dialog'></div>
          </Fragment>
        case 'Download':
          return <PCBDownload
            product={pageType}
            designId={this.designID}
            closePanel={this.props.closePanel}
          />
        case 'LayoutErrors':
          return <LayoutError
            maxHeight={maxHeight}
            maxWidth={_maxWidth}
            leftWidth={offsetLeft}
            topWidth={offsetTop}
            designId={this.designID}
            result={layoutError}
            closePanel={this.props.closePanel}
            onJudge={this.onJudge}
            onSelect={this.select}
            onSearch={this.onSearch}
            layoutCheck={this.layoutCheck}
          />
        default: return null;
      }
    }
    return null;
  }

  closeStackupPanel = () => {
    this.setState({
      showSelfStackup: false,
      showBigPanel: false
    })

    const { pageType } = this.props

    if ([CASCADE, ROCKY, ANDES_V2].includes(pageType)) {
      let { stackupList } = this.props
      const { getNewStackupList, stackupListProps } = this.props
      const allIds = { ...stackupListProps, currentID: this.designID }
      stackupList = getNewStackupList(stackupList, 'close', allIds, true)
      this.props.updateStackupList(stackupList)
    }

    this.props.closePanel(this.designID)
  }

  menuSelect = ({ key, domEvent }) => {
    domEvent && domEvent.preventDefault();
    domEvent && domEvent.stopPropagation();

    const { pageType } = this.props

    if ([CASCADE, ROCKY, ANDES_V2].includes(pageType)) {
      let { stackupList } = this.props
      const { getNewStackupList, stackupListProps } = this.props
      const allIds = { ...stackupListProps, currentID: this.designID }
      stackupList = getNewStackupList(stackupList, 'open', allIds, true)
      if (key === 'Stackup') {
        if (stackupList.length >= 2) {
          let existSamePanel = stackupList.find(item => item.designId === this.designID && ![PCB, CARD, PACKAGE].includes(item.type))
          if (existSamePanel && existSamePanel.status === 'close') {
            stackupList = getNewStackupList(stackupList, 'open', allIds, true)
            this.setState({
              showSelfStackup: false,
              showBigPanel: true
            })
            this.props.openPanel(key, this.designID);
          } else if (existSamePanel && existSamePanel.status === 'open') {
            stackupList = getNewStackupList(stackupList, 'close', allIds, true)
            this.props.closePanel(this.designID)
          } else {
            this.setState({
              showSelfStackup: true,
              showBigPanel: false
            })
            stackupList = getNewStackupList(stackupList, 'open', allIds, true)
            this.props.openPanel(key, this.designID);
          }
        }
        else {
          stackupList = getNewStackupList(stackupList, 'open', allIds, true)
          this.setState({
            showSelfStackup: true,
            showBigPanel: false
          })
          this.props.openPanel(key, this.designID);
        }
      }
      else {
        stackupList = getNewStackupList(stackupList, 'close', allIds, true)
        this.props.openPanel(key, this.designID);
      }
      this.props.updateStackupList(stackupList)
    }
    else {
      if (key === 'Stackup') {
        this.setState({
          showSelfStackup: true
        })
      }
      this.props.openPanel(key, this.designID);
    }
  }

  closeBigPanel = (status) => {
    this.setState({
      showBigPanel: status
    })
  }

  updateSelfStackupStatus = () => {
    return
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.cancelable) {
      this.cancelable.cancel();
    };
    if (this.cancelableLoadLayout) {
      this.cancelableLoadLayout.cancel();
    }
    window.removeEventListener('resize', this.resize);
  }

  select = (canvasObj) => {
    this.props.select(canvasObj, this.designID);
    this.props.changeDisplaySelected(true, this.designID)
  }

  layoutCheck = (e) => {
    e && e.stopPropagation();
    const { startLayoutCheck, simulationDesigns = [] } = this.props;
    if (startLayoutCheck && !simulationDesigns.includes(this.designID)) {
      startLayoutCheck(this.designID)
    }
  }

  render() {
    const { unit, metalLayers, contextMenu, maxWidth, maxHeight } = this.state;
    const { width, leftWidth, SearchBox, LayerTab, pageType, layoutResults = {} } = this.props;
    const _maxWidth = maxWidth > 50 ? maxWidth : 50;
    const { loading } = this.state;
    const layoutError = layoutResults[this.designID];
    return (
      <div className={`pcb-explorer aurora-PCB-layout-content-${this.designID}`} id={`PCB-${this.designID}`}>
        <Spin size='large' spinning={loading} delay={400}>
          <svg className='canvas' ref={this.svgRef} />
        </Spin>
        <svg className="location-mark" viewBox="0 0 24 24" ref={this.locationMark} >
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        <div className="btn-group-vertical">
          {menu(this.menuSelect, this.layout, pageType, layoutError, this.layoutCheck)}
        </div>
        <Coord unit={unit} className='canvas-coord' designID={this.designID} />
        {this.getPanel()}
        <SearchBox className="global-search"
          onSearch={this.onSearch}
          onSelect={this.select}
        />
        <LayerTab
          {...this.props}
          openStackupPanel={this.menuSelect}
          maxHeight={maxHeight}
          maxWidth={_maxWidth}
          metalLayers={metalLayers}
          getColor={this.layout ? this.layout.getLayerColor : null}
          width={width}
          leftWidth={leftWidth}
          designID={this.designID}
        />
        <ContextMenu {...contextMenu} />
      </div>
    )
  }
}

export default LayoutExplorer