import { takeEvery, select, put, call, delay, fork } from "@redux-saga/core/effects";
import {
  START_POWER_TREE_SIMULATION,
} from '../actionType';
import {
  updateMultiSimulationInfo,
} from '../action'
import { POWER_TREE, DESIGN_TREE, SINGLE_TREE } from '@/constants/treeConstants';
import { powerTreeSimulation, powerTreeErrorCheck } from '@/services/Cascade/PowerTree';
import { updateError as updateDesignError, updateRunning, updateResults, setPowerTreeData, saveSpiceResults, saveExtraction } from '../../DesignTree/action';
import { saveMultiResults, saveMultiSpiceResults } from '../../PowerTree/action';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { openTabFooter, changeTabMenu } from "../../../../MonitorStore/action";
import { updateSimulationLoading } from "../../project/action";
import { getInterfaceWorkStatus } from '@/services/project';
import { startNewWorkflow } from '../simulationSaga';
import { initSimulationInfo } from '../action';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { getCascadeSimulationLog } from '@/services/Cascade/simulation/simulationCtrl';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { savePowerTrees } from "../../DesignTree/saga";
import auroraDBJson from "../../../../../services/Designs/auroraDbData";

function* _startPowerTreeSimulation(action) {
  const { id: verificationId, runType } = action;
  const { CascadeReducer: { DesignTree: { powerTrees, extraction }, project: { openProjectId } } } = yield select();
  try {

    const designList = CascadeChannels.getList(DESIGN_TREE, openProjectId);
    const powerList = CascadeChannels.getList(POWER_TREE, openProjectId)
    const singleList = CascadeChannels.getList(SINGLE_TREE, openProjectId)
    const currentItem = [...designList, ...powerList, ...singleList].find(i => i.id === verificationId);
    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : DESIGN_TREE,
      menuType: "simulation"
    }))

    if (!powerTrees.length) {
      return;
    }
    // get power tree status
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        return;
      }
    }
    // start simulation
    yield put(openTabFooter());
    yield put(updateDesignError([]))

    // check data error
    // const error = powerTreeErrorCheck(powerTrees);
    // runType === 'power' ? yield put(updateError(error)) : yield put(updateDesignError(error));
    // if (error.length) {
    //   yield put(updateMultiSimulationInfo({
    //     verificationId,
    //     info: {
    //       startMsg: null,
    //       log: null,
    //       monitor: null,
    //       endMsg: null
    //     }
    //   }))
    //   return;
    // }

    // check extraction clipping
    const _extraction = { ...extraction };
    for (let powerTree of powerTrees) {
      if (!_extraction.clipping) {
        break;
      }
      const { pcbIndex = [] } = powerTree;
      for (let pcb of pcbIndex) {
        const designId = pcb.pcbId;
        if (!auroraDBJson.checkAuroraJson(designId)) {
          yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId)
        }
        if (auroraDBJson.isFlexBoard(designId)) {
          _extraction.clipping = false;
          break;
        }
      }
    }
    if (_extraction.clipping !== extraction.clipping) {
      yield put(saveExtraction(_extraction));
      yield delay(3000);
    }

    yield call(savePowerTrees, { resultSave: true })
    yield put(setPowerTreeData({ spiceSimulation: {} }))
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: "==> Prepare Extraction data...",
        log: null,
        monitor: null,
        endMsg: null
      }
    }))
    yield call(powerTreeSimulation, verificationId);

    if (runType === 'design' || runType === 'single') {
      yield put(updateResults(undefined));
      yield put(saveSpiceResults(undefined));
    } else if (runType === 'power') {
      yield put(saveMultiResults([]));
      yield put(saveMultiSpiceResults([]));
    }
    yield put(updateRunning(true));

    const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
    if (interfaceStatus && interfaceStatus.length > 0) {
      yield fork(startNewWorkflow, {
        verificationId,
        interfaceStatus,
        key: runType === 'single' ? SINGLE_TREE : runType === 'power' ? POWER_TREE : DESIGN_TREE
      })
      return
    } else {
      // _initSimulationInfo 
      yield put(initSimulationInfo)
    }
  } catch (error) {
    console.error(error);
    yield put(updateDesignError(error ? ['==> Power Tree simulation failed!', `==> ${error}`] : ['==> Power Tree simulation failed!']))
    yield delay(1000);
    let log = yield call(getCascadeSimulationLog, verificationId)
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: null,
        log: log,
        monitor: null,
        endMsg: null
      }
    }))
  } finally {
    yield put(updateSimulationLoading(false))
  }
}

function* PowerTreeSimulationSaga() {
  yield takeEvery(START_POWER_TREE_SIMULATION, _startPowerTreeSimulation);
}

export default PowerTreeSimulationSaga;