import { call, put, takeEvery, delay, select, takeLatest, fork, cancel, take } from 'redux-saga/effects';
import { message } from 'antd'
import {
  resetSetup,
  setPowerTreeData,
} from './action';
import { resetDesignTree } from '../DesignTree/action';
import { GET_POWER_TREE_SETTING, SAVE_CONNECTORS_TO_SETUP, SAVE_ROOT_TO_SETUP } from './actionType';
import {
  getPowerSetting,
  savePowerSetting
} from '@/services/Cascade/PowerTree';
import { ConnectorPCB } from '@/services/Cascade/helper/setupClass';
import { getDesignTreeContent } from '../DesignTree/action';
import { SINGLE_TREE } from '../../../../constants/treeConstants';
import { getAllCascadeComponents, changeNewConnectors } from '../../../../services/Cascade/helper/setupData';
import PinMapStore from '../../../../services/Cascade/library/pinMapHelper';
import compPinMap from '../../../../services/Cascade/helper/compPinMap';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import designConstructor from '../../../../services/helper/designConstructor';
import componentSetting from '../../../../services/Cascade/helper/compSettingHelper';
import auroraDBJson from '../../../../services/Designs/auroraDbData';

function* getPowerTreeSetting(action) {
  const { verificationId } = action;
  try {
    yield put(resetSetup())
    const res = yield call(getPowerSetting, verificationId);
    const { connectors = [], root = {} } = res;
    let _connectors = [...connectors], initConn = false
    if (!root.pcbId) {
      initConn = true;
    } else {
      const { pinMapTable, pcbId } = root;
      yield call(getAuroraDB, pcbId);
      let table = [];
      for (let record of pinMapTable) {
        let _record = { ...record };
        if (!_record.components) {
          _record.components = [];
        }
        if (!_record.nets) {
          let nets = [];
          const { partNumber, libraryId } = _record;
          if (partNumber && libraryId) {
            const pinMap = yield call(compPinMap.getPinMapData, pcbId);
            const findMap = pinMap.find(item => item.partNumber === partNumber);
            if (findMap) {
              const res = yield call([PinMapStore, PinMapStore.getPinMapLibrary], { libraryId: libraryId, partNumber, type: findMap.type }, pcbId, 'pinName')
              const data = res && res.config ? res.config.pinTable || [] : [];
              const outputs = data.map(item => item.output).flat(2);
              const components = getAllCascadeComponents({ pcbId })
              const comp = [...components.values()].find(item => item.partName === partNumber);
              if (comp) {
                const pins = [...comp.pins.values()].filter(pin => outputs.includes(pin.pinName));
                pins.forEach(pin => {
                  const findIndex = nets.findIndex(n => n.net === pin.net);
                  if (findIndex < 0) {
                    nets.push({ net: pin.net, pinNames: [pin.pinName] });
                  } else {
                    nets[findIndex].pinNames.push(pin.pinName)
                  }
                })
              }
            }
          }
          _record.nets = nets;
        }
        table.push(_record)
      }
      root.pinMapTable = table;
    }

    yield put(setPowerTreeData({ verificationId, connectors: _connectors, initConn, root }));
    yield put(getDesignTreeContent(verificationId, false))
  } catch (error) {
    console.error(error);
    yield put(setPowerTreeData({ verificationId }));
    message.error('Get power tree setting failed!');
  }
}

function* saveConnector(action) {
  const { connectors, refresh = false } = action;
  try {
    yield put(setPowerTreeData({ connectors }));
    yield delay(10);
    yield call(saveSetting);
    if (refresh) {
      let pcbList = [];
      connectors.forEach(pcbs => pcbs.forEach(pcb => pcbList.push(pcb.pcb)));
      pcbList = [...new Set(pcbList)];
      yield* pcbList.map(function* (pcb) {
        yield call(getAuroraDB, pcb);
      });
      yield put(resetDesignTree(false))
    }
  } catch (error) {
    console.error(error);
  }
}

function* saveRoot(action) {
  const { root, refresh = false } = action;
  try {
    const { CascadeReducer: { PowerTree: { root: _root, connectors: _connectors }, project: { viewList } } } = yield select();
    yield put(setPowerTreeData({ root }));
    const id = root.pcbId || "";
    if (refresh && _root.pcbId !== id && !viewList.includes(SINGLE_TREE)) {
      const connectors = [[new ConnectorPCB({ pcb: id })]];
      yield put(setPowerTreeData({ connectors }));
    }
    if (refresh) {
      if (id) {
        yield call(getAuroraDB, id);
      }

      if (refresh && _root.pcbId !== id && !viewList.includes(SINGLE_TREE)) {
        let connectors = [];
        if (!_root.pcbId) {
          connectors = [[new ConnectorPCB({ pcb: id })]];
        } else {
          connectors = changeNewConnectors(_connectors, { value: id, pcbIndex: 0, index: 0 })
        }
        if(!connectors.length) {
          connectors = [[new ConnectorPCB({ pcb: id })]];
        }
        yield put(setPowerTreeData({ connectors }));
      }
      yield put(resetDesignTree())
    }

    yield delay(10);
    yield call(saveSetting);
  } catch (error) {
    console.error(error);
  }
}

function* saveSetting() {
  const { CascadeReducer: { PowerTree: { verificationId, connectors, root } } } = yield select();
  try {
    yield call(savePowerSetting, { verificationId, setup: { connectors, root } })
  } catch (e) {
    console.error(e)
  }
}


function* getAuroraDB(designId) {
  try {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (isPreLayout) {
      yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      return;
    }
    const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
    yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId, setting);
  } catch (e) {
    console.error(e);
  }
}

function* PowerTreeSaga() {
  yield takeEvery(GET_POWER_TREE_SETTING, getPowerTreeSetting);
  yield takeEvery(SAVE_CONNECTORS_TO_SETUP, saveConnector);
  yield takeEvery(SAVE_ROOT_TO_SETUP, saveRoot);
}

export default PowerTreeSaga;