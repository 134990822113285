import React from 'react';
import { Row, Col, Select, InputNumber, Divider } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { getYLabel } from '@/services/helper/sparameter/utility';

const { Option } = Select;
const attr = {
  type: "flex",
  justify: "space-around",
  align: "middle"
}

const Setting = (props) => {
  const { axis, actions, setting, XSettingUnit, settingShow, settingTitleClick } = props;
  const { value, parameter } = setting;
  const { showAxisSetting, axisChange, changeRange, axisRangeKeyDown, XUnitChange, updateRange } = actions;
  return (
    <div className='decap-graph-setting-box'>
      <Divider orientation="left" style={{ marginTop: 0, marginBottom: 6 }}>
        <span onClick={settingTitleClick} className='decap-graph-setting-title'>
          {settingShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
          Setting
        </span>
      </Divider>
      <div style={{ display: settingShow ? 'block' : 'none' }}>
        <Row onClick={() => showAxisSetting('xShow')} className='sp-axis-title'>
          {axis.xShow ? <DownOutlined className="result-setting-expand-icon" /> : <RightOutlined className="result-setting-expand-icon" />}
          <span className='setting-input-title'>X axis -- Frequency</span>
        </Row>
        <Row style={{ display: axis.xShow ? 'block' : 'none' }} className='sp-axis-content'>
          <Row {...attr}>
            <Col span={8}>
              <label className='setting-input-lable padding-left-20'>Scale</label>
            </Col>
            <Col span={16}>
              <Select
                value={axis.xScale}
                className='sp-setting-select'
                onChange={(value) => axisChange('x', value)}
                dropdownStyle={{ zIndex: 1000000 }}
              >
                <Option value='linear'>Linear</Option>
                <Option value='log'>Log</Option>
              </Select>
            </Col>
          </Row>
          <Row {...attr}>
            <Col span={8}>
              <label className='setting-input-lable padding-left-20'>Begin</label>
            </Col>
            <Col span={16}>
              <InputNumber
                className='decap-graph-setting-input'
                value={axis.xMin ? axis.xMin : 0}
                onChange={(value) => changeRange('xMin', value)}
                step={0.1}
                onKeyDown={(e) => axisRangeKeyDown(e, 'xMin')}
                onBlur={(e) => updateRange('xMin', e.target.value)}
              />
              <Select
                value={XSettingUnit}
                className='sp-unit-select'
                onChange={(value) => XUnitChange(value)}
                dropdownStyle={{ zIndex: 1000000 }}
              >
                <Option value='Hz'>Hz</Option>
                <Option value='KHz'>KHz</Option>
                <Option value='MHz'>MHz</Option>
                <Option value='GHz'>GHz</Option>
              </Select>
            </Col>
          </Row>
          <Row {...attr}>
            <Col span={8}>
              <label className='setting-input-lable padding-left-20'>End</label>
            </Col>
            <Col span={16}>
              <InputNumber
                className='decap-graph-setting-input'
                value={axis.xMax ? axis.xMax : 0}
                onChange={(value) => changeRange('xMax', value)}
                step={0.1}
                onKeyDown={(e) => axisRangeKeyDown(e, 'xMax')}
                onBlur={(e) => updateRange('xMax', e.target.value)}
              />
              <Select
                value={XSettingUnit ? XSettingUnit : 'MHz'}
                className='sp-unit-select'
                onChange={(value) => XUnitChange(value)}
                dropdownStyle={{ zIndex: 1000000 }}
              >
                <Option value='Hz'>Hz</Option>
                <Option value='KHz'>KHz</Option>
                <Option value='MHz'>MHz</Option>
                <Option value='GHz'>GHz</Option>
              </Select>
            </Col>
          </Row>
        </Row>
        <Row onClick={() => showAxisSetting('yShow')} className='sp-axis-title'>
          {axis.yShow ? <DownOutlined className="result-setting-expand-icon" /> : <RightOutlined className="result-setting-expand-icon" />}
          <span className='setting-input-title'>Y axis -- {getYLabel({ value, yScale: axis.yScale, param: 'Z' })}</span>
          {/* TODO unit <span>(db)</span>*/}
        </Row>
        <Row style={{ display: axis.yShow ? 'block' : 'none' }} className='sp-axis-content'>
          <Row {...attr}>
            <Col span={8}>
              <label className='setting-input-lable padding-left-20'>Scale</label>
            </Col>
            <Col span={16}>
              <Select
                value={axis.yScale}
                className='sp-setting-select'
                onChange={(value) => axisChange('y', value)}
                dropdownStyle={{ zIndex: 1000000 }}
              >
                <Option value='linear' style={{ display: value !== 'ph' ? 'block' : 'none' }}>Linear</Option>
                <Option value="log" style={{ display: value === 'am' ? 'block' : 'none' }}>Log</Option>
                <Option value="db" style={{ display: value === 'am' && parameter === 's' ? 'block' : 'none' }}>dB</Option>
                <Option value="radians" style={{ display: value === 'ph' ? 'block' : 'none' }}>Radian</Option>
                <Option value="degrees" style={{ display: value === 'ph' ? 'block' : 'none' }}>Degree</Option>
              </Select>
            </Col>
          </Row>
          <Row {...attr}>
            <Col span={8}>
              <label className='setting-input-lable padding-left-20'>Begin</label>
            </Col>
            <Col span={16}>
              <InputNumber
                className='decap-graph-setting-input'
                value={axis.yMin ? axis.yMin : 0}
                onChange={(value) => changeRange('yMin', value)}
                step={0.1}
                onKeyDown={(e) => axisRangeKeyDown(e, 'yMin')}
                onBlur={(e) => updateRange('yMin', e.target.value)}
              />
              <Select
                defaultValue='Ω'
                className='sp-unit-select'
                dropdownStyle={{ zIndex: 1000000 }}
              >
                <Option value='Ω'>Ω</Option>
              </Select>
            </Col>
          </Row>
          <Row {...attr}>
            <Col span={8}>
              <label className='setting-input-lable padding-left-20'>End</label>
            </Col>
            <Col span={16}>
              <InputNumber
                className='decap-graph-setting-input'
                value={axis.yMax ? axis.yMax : 0}
                onChange={(value) => changeRange('yMax', value)}
                step={0.1}
                onKeyDown={(e) => axisRangeKeyDown(e, 'yMax')}
                onBlur={(e) => updateRange('yMax', e.target.value)}
              />
              <Select
                defaultValue='Ω'
                className='sp-unit-select'
                dropdownStyle={{ zIndex: 1000000 }}
              >
                <Option value='Ω'>Ω</Option>
              </Select>
            </Col>
          </Row>
        </Row>
        <Divider style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
}

export default Setting;