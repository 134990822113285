import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import '@/publicCss/style.css';

class EtchFactorHelpPanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  render() {
    const content = (
      <Panel
        className='etch-factor-help-panel'
        title='Etch Factor Help'
        zIndex={2000}
        width={660}
        minWidth={300}
        minHeight={200}
        position='panel-center'
        onCancel={this.props.closeEtchFactor}
        overflow='hidden'
        draggable
      >
        <div className='help-panel-content'>
          <img src={`/lib/imgs/etchFactor.png`} alt="" />
        </div>
      </Panel>)

    return createPortal(content, this.dialogRoot);
  }
}

export default EtchFactorHelpPanel;