import {
  CHANGE_RESULT_KEY,
  RESULT_CHANNEL,
  SAVE_EYE_DIAGRAM,
  CHANGE_EYE_DIAGRAM_PROGRESS,
  SAVE_SELECT_SIGNALS,
  SAVE_EYE_DIAGRAM_SIGNALS,
  SAVE_EYE_DIAGRAM_IMG,
  UPDATE_EYE_DIAGRAM_CONFIG,
  SET_DEFAULT_EYE_DIAGRAM_CONFIG,
  SAVE_SIMULATION_CONFIG,
  SAVE_EYE_UNIT_VALUE,
  UPDATE_EYE_DIAGRAM_CLOCKS,
  RESULT_PAGE_INFO,
  RECALC_MEASURE,
  UPDATE_RECALC,
  UPDATE_SWEEP_RECALC,
  UPDATE_SWEEP_MEASUREMENT_PROGRESS
} from './actionTypes';

export const changeResultKey = (key) => ({
  type: CHANGE_RESULT_KEY,
  key
});

export const saveResultChannel = (channelList) => ({
  type: RESULT_CHANNEL,
  channelList
});

export const saveEyeDiagrams = ({ verificationId, clocks, signals }) => ({
  type: SAVE_EYE_DIAGRAM,
  verificationId,
  clocks,
  signals
});

export const changeEyeDiagramProgress = (verificationId, progress) => ({
  type: CHANGE_EYE_DIAGRAM_PROGRESS,
  verificationId,
  progress
});

export const saveSelectSignals = ({ selectedClocks, selectedSignals }) => ({
  type: SAVE_SELECT_SIGNALS,
  selectedClocks,
  selectedSignals
});

export const saveEyeDiagramsImg = (imgSrc) => ({
  type: SAVE_EYE_DIAGRAM_IMG,
  imgSrc
});

export const saveEyeDiagramsSignals = ({ clocks, signals, receiverSignals, DDRSignals, allSignalPins }) => ({
  type: SAVE_EYE_DIAGRAM_SIGNALS,
  clocks,
  signals,
  receiverSignals,
  DDRSignals,
  allSignalPins
});

export const defaultEyeDiagramConfig = ({ verificationId, clocks, receiverSignals, DDRSignals, signals, currentInterfaceInfo, allSignalPins }) => ({
  type: SET_DEFAULT_EYE_DIAGRAM_CONFIG,
  verificationId,
  clocks,
  receiverSignals,
  DDRSignals,
  currentInterfaceInfo,
  signals,
  allSignalPins
});

export const updateEyeDiagramConfig = ({ eyeDiagramConfig, clockPositive, clockNegative, isSave, isUpdate, verificationId }) => ({
  type: UPDATE_EYE_DIAGRAM_CONFIG,
  eyeDiagramConfig,
  clockPositive,
  clockNegative,
  isSave,
  verificationId,
  isUpdate
});

export const saveCurrentSimulationConfig = (simulationConfig) => ({
  type: SAVE_SIMULATION_CONFIG,
  simulationConfig
});

export const saveEyeUnitValue = (eyeDiagramUnitValue) => ({
  type: SAVE_EYE_UNIT_VALUE,
  eyeDiagramUnitValue
});

export const updateEyeDiagramClocks = ({ clockPositive, clockNegative, eyeClockType, clockPPin, clockNPin }) => ({
  type: UPDATE_EYE_DIAGRAM_CLOCKS,
  clockPositive,
  clockNegative,
  eyeClockType,
  clockPPin,
  clockNPin
});

export const getResultPage = ({ verificationId, verificationSubId, name, status }) => ({
  type: RESULT_PAGE_INFO,
  verificationId,
  verificationSubId,
  name,
  status
})

export const recalcMeasure = (configList, isSweep) => ({
  type: RECALC_MEASURE,
  configList,
  isSweep
})

export const updateRecalc = (calc) => ({
  type: UPDATE_RECALC,
  calc
})

export const updateSweepReCalc = (sweepReCalc) => ({
  type: UPDATE_SWEEP_RECALC,
  sweepReCalc
});

export const updateSweepMeasurementProgress = (progress) => ({
  type: UPDATE_SWEEP_MEASUREMENT_PROGRESS,
  progress
})