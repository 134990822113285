function JsonData({ verificationId, compPrefixLib, version, designName }) {
  this.dcIrs = []
  this.verificationId = verificationId;
  this.compPrefixLib = compPrefixLib;
  this.version = version;
  this.designName = designName;
}

function load(comp = "", current = []) {
  this.comp = comp;
  this.current = current;
}

function dcIrsData(content = {}, id = "") {
  this.content = content;
  this.id = id
}

function content({ components = [], extendNets = [], gndNets = [], loads = [], maxBgaI = "1", nominal = "", powerNets = [], vrmComps = [], maxVia = "1", maxBgaDv = "0.05", detail, inductance = {}, load_0 = "" }) {
  this.components = components;
  this.extendNets = extendNets;
  this.gndNets = gndNets;
  this.loads = loads;
  this.maxBgaI = maxBgaI;
  this.nominal = nominal;
  this.powerNets = powerNets;
  this.vrmComps = vrmComps;
  this.maxVia = maxVia;
  this.maxBgaDv = maxBgaDv;
  this.detail = detail;
  this.inductance = inductance;
  this.load_0 = load_0;
}

function inductance(key, value = []) {
  this[key] = value;
}

JsonData.prototype.toJson = function (arr = [], irLoadSelect = []) {
  let dcIrs = [];
  arr.forEach(item => {
    let loads = [];
    irLoadSelect.forEach(it => {
      loads.push(new load(it.comp, item[it.name]))
    })
    const _content = new content({ ...item, loads });
    dcIrs.push(new dcIrsData(_content, item.id))
  });
  this.dcIrs = dcIrs;
}
export default JsonData;