import React, { Component } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { userDefaultSettings } from '../../../services/userDefaultSetting/userDefaultSettingCtrl';
import { BY_NETS, BY_PINS, NETS, NETS_PINNAMES, NETS_PINS, NONE } from '../../../constants/connectionType';
import './index.css';

const Option = Select.Option;
const displayList = [NETS, NETS_PINS, NETS_PINNAMES];
const autoList = [NONE, BY_PINS, BY_NETS];
class netsConnectionSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoSelectConnection: NONE,
      connectionDisplay: NETS_PINS,
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const userSetting = userDefaultSettings.getLocalSetting();
    const { cascadeSettings = {} } = userSetting;
    const { autoSelectConnection = NONE, connectionDisplay = NETS_PINS } = cascadeSettings;
    this.setState({
      connectionDisplay,
      autoSelectConnection
    })
  }

  changeValue = (value, key) => {
    this.setState({
      [key]: value
    })
  }

  closeModal = () => {
    const userSetting = userDefaultSettings.getLocalSetting();
    const { cascadeSettings = {} } = userSetting;
    const { connectionDisplay, autoSelectConnection } = this.state;
    const _userSetting = { ...userSetting, cascadeSettings: { ...cascadeSettings, connectionDisplay, autoSelectConnection } }
    userDefaultSettings.updateUserDefaultSettings(_userSetting);
    this.props.closeModal()
  }

  render() {
    const { connectionDisplay, autoSelectConnection } = this.state;
    const content = (
      <Panel
        className='cascade-power-select-panel'
        title={<div className='cascade-power-select-title'>Connection Setting</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={500}
        height={153}
        position='panel-center'
        draggable
        minHeight={153}
        minWidth={500}
        defaultTop={200}
      >
        <div className='cascade-connection-setting-option'>
          <span className='cascade-connection-setting-option-body'>Connection Display</span>
          <Select
            className="cascade-connection-setting-option-select"
            popupClassName="aurora-select-dropdown"
            onChange={(value) => this.changeValue(value, 'connectionDisplay')}
            value={connectionDisplay}
            size='small'
          >
            {displayList.map(item => <Option title={item} key={item} value={item}>{item}</Option>)}
          </Select>
        </div>
        <div className='cascade-connection-setting-option'>
          <span className='cascade-connection-setting-option-body'>
            Auto Connection
            <Tooltip overlayClassName='aurora-tooltip' title='Click the magnifier next to net to automatically connect.'>
              <InfoCircleOutlined className='cascade-connection-info-circle' />
            </Tooltip>
          </span>
          <Select
            className="cascade-connection-setting-option-select"
            popupClassName="aurora-select-dropdown"
            value={autoSelectConnection}
            onChange={(value) => this.changeValue(value, 'autoSelectConnection')}
            size='small'
          >
            {autoList.map(item => <Option title={item} key={item} value={item}>{item}</Option>)}
          </Select>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}


export default netsConnectionSetting;
