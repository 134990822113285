export {
  getVirtualComponentList,
  getVCompModelText,
  getVCompModelPinList,
  getVCSignalName,
  getVCDefaultModel,
  N_PORT,
  VC_RLC_TYPES,
  VC_SHUNT_TYPES,
  SHUNT_R,
  SHUNT_L,
  SHUNT_C,
  updateViewVComps,
  updateViewVCompsPanel,
  getShuntRLCType
} from './virtualCompHelper';