import React, { Component } from 'react';
import IRMenu from './IRMenu';
import IRTables from './IRResultContent';
import { viaColumns } from '@/services/Result/Public/DCTable/tableData';

function ViaResult() {
  return (
    <div>
      <IRMenu />
      <IRTables
        tableColumns={viaColumns}
        chartXType='I'
        chartYType='components'
        svgHeight='610'
        compareTypes={['I']}
        rowKey='components'
        chartVisible={false}
      />
    </div>
  )
}

export default ViaResult