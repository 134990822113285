import { saveInterface } from './resultData';
import { getExperimentResultPromise } from '../projectCtrl';
import { VERIFICATION_JSON } from '../../../pages/Sierra/constants';
import SierraVerify from '@/services/Sierra/PinToPinVerifyDatabase';
import { getPreLayoutJsonByVerificationId } from '../results';


class SweepResultData {
  constructor() {
    this.Interface = new Map(); //key - experimentId
    this.preLayoutInfo = new Map();//key - verificationId
    this.Info = new Map();

    this.cleanInterface = this.cleanInterface.bind(this);
    this.cleanResultByIds = this.cleanResultByIds.bind(this);

    this.getInterfaces = this.getInterfaces.bind(this);
    this.getInfo = this.getInfo.bind(this);

  }

  cleanAll() {
    this.verificationJson = new Map();
    this.Interface = new Map();
    this.Info = new Map();
    this.preLayoutInfo = new Map();
  }

  getVerificationJsonPromise(experimentId, projectId) {
    return new Promise((resolve, reject) => {
      if (projectId) {
        const data = this.Interface.get(experimentId);
        if (data) {
          resolve(data);
        } else {
          getExperimentResultPromise({ experimentId, option: VERIFICATION_JSON }).then(res => {
            const { interfaces } = saveInterface(res);
            this.Interface.set(experimentId, interfaces);
            resolve(interfaces);
          }, error => {
            resolve(null);
          })
        }
      } else {
        resolve(null);
      }
    })
  }

  cleanInterface() {
    this.Interface = new Map();
  }

  getInterfaces(id) {
    return this.Interface.get(id);
  }

  getInfo(id) {
    const _interface = this.Interface.get(id);
    const info = _interface ? SierraVerify.mergeInterfacesInfo(_interface, _interface[0].name, true) : {};
    return info;
  }

  cleanResultByIds(Ids) {
    for (let id of Ids) {
      if (this.Interface.get(id)) {
        this.Interface.set(id, null)
        this.preLayoutInfo.set(id, null)
      }
    }
  }

  getPreLayoutJsonByVerificationId = (expId, verificationId) => {
    return new Promise((resolve, reject) => {
      const data = this.preLayoutInfo.get(expId);
      if (data && data.length) {
        resolve(data)
      } else {
        getPreLayoutJsonByVerificationId(verificationId).then(res => {
          this.preLayoutInfo.set(expId, res ? res : []);
          resolve(res)
        }, error => {
          this.preLayoutInfo.set(expId, []);
          console.error(error);
          resolve([])
        });
      }
    })
  }
}

const ResultData = new SweepResultData();
export default ResultData;