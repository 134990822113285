import React, { Component, Fragment } from 'react';
import { CloseCircleFilled, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip, Spin } from 'antd';
import CPMConnection from './CPMConnection';
import { DIE } from '../../constants/componentType';
import CSMSignalsConnection from './CSMSignalsConnection';
import { PRE_LAYOUT } from '../../constants/designVendor';
import { autoReadOrWriteSignalMapping, getWriteAndReadPortList } from '../../services/LibraryHelper/SpiceModelHelper/csmCpmParseHelper';
import TreeSelect from '@/components/TreeSelect';
import { CASCADE, ROCKY } from '../../constants/pageType';
import './index.css';
import { updateSSNPackageInfo } from '../../pages/Rocky/store/rocky/action';

const Option = Select.Option;
const OptGroup = Select.OptGroup;
const CSM = "CSM"/* , CPM = "CPM" */;
const READ = "Read"

export function FileInfo({ libraryId = "", fileName = "", subckt = "", folder = "" }) {
  this.libraryId = libraryId;
  this.fileName = fileName;
  this.subckt = subckt;
  this.folder = folder;
}

export function PlocFileInfo({ libraryId = "", fileName = "" }) {
  this.libraryId = libraryId;
  this.fileName = fileName;
}
class CPMCSMContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectFile: {},
      PowerNets: [],
      ReferenceNets: [],
      cpmVisible: false,
      csmVisible: false,
      subcktList: [],
      canvasFile: {},
      readSelectFile: new FileInfo({}),
      readSubcktList: [],
      isUpdateCSMValue: false,
      loading: false,
      plocSelectFile: new PlocFileInfo({})
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.initData()
    this.getPackageChannelInfo()
  }

  initData = async () => {
    const { packageId, info, product, targetPkg } = this.props;
    this.setState({
      loading: true
    })
    let PowerNets = [], ReferenceNets = [], dieComponent = null;
    if (product === CASCADE) {
      const powerDomain = targetPkg.content
      PowerNets.push(...powerDomain.PowerNets);
      ReferenceNets.push(...powerDomain.ReferenceNets)
      if (!dieComponent) {
        dieComponent = powerDomain.Components.find(item => item.usage === DIE);
      }
    } else if (product === ROCKY) {
      const packagePDN = this.props.packageVerificationConstructor.getPackagePDN(packageId) || {}
      const pkgInfo = await this.props.PackagePCBPdnInfo.getPCBPackagePdnInfo(packagePDN.id) || {};//package pdn id
      const powerDomains = pkgInfo.content && pkgInfo.content.powerDomains ? pkgInfo.content.powerDomains : []
      for (let powerDomain of powerDomains) {
        PowerNets.push(...powerDomain.PowerNets);
        ReferenceNets.push(...powerDomain.ReferenceNets)
        if (!dieComponent) {
          dieComponent = powerDomain.Components.find(item => item.usage === DIE);
        }
      }
    }
    PowerNets = [...new Set(PowerNets)];
    ReferenceNets = [...new Set(ReferenceNets)];
    const _info = JSON.parse(JSON.stringify(info || {}));
    const selectFile = { libraryId: _info.libraryId, fileName: _info.fileName, subckt: _info.subckt };
    const writeSelectFile = _info.writeSelectFile ? _info.writeSelectFile : new FileInfo({ ..._info });
    const readSelectFile = _info.readSelectFile ? _info.readSelectFile : new FileInfo({});
    const plocSelectFile = _info.plocSelectFile ? _info.plocSelectFile : new PlocFileInfo({})
    this.setState({
      PowerNets,
      ReferenceNets,
      component: dieComponent ? dieComponent.name : null,
      info: _info,
      selectFile: { ...selectFile },
      csmPairs: _info.csmPairs || [],
      cpmPairs: _info.cpmPairs || [],
      triPairs: _info.cpmConfig ? _info.cpmConfig.triPairs : [],
      transforms: _info.cpmConfig ? _info.cpmConfig.transforms : null,
      canvasFile: { ...selectFile },
      writeSelectFile: { ...writeSelectFile },
      readSelectFile: { ...readSelectFile },
      isUpdateCSMValue: false,
      loading: false,
      plocSelectFile
    }, () => {
      this.getPlocFile(plocSelectFile)
      this.getFile(writeSelectFile);
      this.getFile(readSelectFile, READ)
    })
  }

  getPackageChannelInfo = () => {
    const { rockyPackageInfo, packageChannelId, pcb, channelId, pcbChannelInfo, preLayoutId } = this.props;
    let getChannelId = null, getPackageChannelId = null, getPCBPreLayoutId = null
    if (pcb && pcb.vendor !== PRE_LAYOUT && channelId && (
      !pcbChannelInfo
      || !Object.keys(pcbChannelInfo).length
    )) {
      getChannelId = channelId;
    } else if (pcb && pcb.vendor === PRE_LAYOUT && channelId && (!preLayoutId || preLayoutId !== pcb.id)) {
      getPCBPreLayoutId = pcb.id
    }

    if (packageChannelId && (
      !rockyPackageInfo
      || !Object.keys(rockyPackageInfo).length
      || rockyPackageInfo.id !== packageChannelId)) {
      //get package channel info
      getPackageChannelId = packageChannelId;
    }
    if (getPackageChannelId || getChannelId) {
      this.props._getPCBAndPackageChannelInfo(getChannelId, getPackageChannelId, getPCBPreLayoutId)
    }
  }

  getModel = async (type) => {
    const { selectFile, csmPairs, cpmPairs, canvasFile, triPairs, transforms, readSelectFile, writeSelectFile, isUpdateCSMValue, plocSelectFile } = this.state;
    const { product } = this.props
    let cpmExist = false;
    if ((canvasFile && selectFile && canvasFile.libraryId === selectFile.libraryId && canvasFile.fileName === selectFile.fileName)) {
      cpmExist = true;
    }

    let _cpmPairs = cpmPairs, _triPairs = triPairs, _transforms = transforms, _csmPairs = csmPairs;

    if (!cpmExist) {
      _cpmPairs = [];
      _triPairs = [];
      _transforms = null;
      _csmPairs = [];
    } else if (writeSelectFile && writeSelectFile.libraryId && writeSelectFile.subckt
      && readSelectFile && readSelectFile.libraryId && readSelectFile.subckt) {
      let autoType = null;
      let csmSignalList = [], readPadList = [], padList = [];
      _csmPairs.forEach(it => {
        const { csmSignal, pad, readPad } = it;
        csmSignal && csmSignalList.push(csmSignal)
        pad && padList.push(pad)
        readPad && readPadList.push(readPad)
      });
      if (csmSignalList.length && padList.length && !readPadList.length) {
        autoType = 'read'
      } else if (csmSignalList.length && readPadList.length && !padList.length) {
        autoType = 'write'
      }
      if (autoType) {
        let portList = await getWriteAndReadPortList(writeSelectFile, readSelectFile, product);
        const { newSignalMapping } = autoReadOrWriteSignalMapping(_csmPairs, portList);
        _csmPairs = newSignalMapping;
      }
    }

    const _info = {
      ...selectFile,
      writeSelectFile: writeSelectFile,
      readSelectFile: readSelectFile,
      csmPairs: _csmPairs,
      cpmPairs: _cpmPairs,
      cpmConfig: {
        triPairs: _triPairs,
        transforms: _transforms
      }
    }
    if (product === CASCADE) {
      _info.plocSelectFile = plocSelectFile
    }
    if (type === "CPM") {
      delete _info.csmPairs;
    }
    return _info;
  }

  changeSelectModel = (key, modelType) => {
    const { product } = this.props
    if (product === CASCADE) {
      if (key && key.id) {
        const { id: libraryId, name: fileName } = key
        const { selectFile, subcktList, writeSelectFile } = this.state;
        let _selectFile = { ...selectFile }, updateSelectFile = false;
        let _subcktList = [...subcktList], _writeSelectFile = { ...writeSelectFile }
        let updateFileInfo = { libraryId, fileName, subckt: "" };
        if (this.getIsCPMUpdate(selectFile, writeSelectFile)) {
          _selectFile = { ...updateFileInfo }
          updateSelectFile = true;
        }
        _writeSelectFile = { ...updateFileInfo };
        _subcktList = []
        this.clearCsmPairs(modelType)
        this.setState({
          selectFile: _selectFile,
          subcktList: _subcktList,
          writeSelectFile: _writeSelectFile,
          isUpdateCSMValue: true
        }, () => {
          this.getFile({ ...updateFileInfo }, modelType, updateSelectFile, true);
        });
      } else {
        // clear data
        const { selectFile, subcktList, writeSelectFile } = this.state;
        let _selectFile = { ...selectFile };
        let _subcktList = [...subcktList], _writeSelectFile = { ...writeSelectFile }
        if (_writeSelectFile.subckt && selectFile.libraryId === _writeSelectFile.libraryId && selectFile.fileName === _writeSelectFile.fileName && selectFile.subckt === _writeSelectFile.subckt) {
          _selectFile = new FileInfo({})
        }
        _writeSelectFile = new FileInfo({})
        _subcktList = [];
        this.setState({
          selectFile: _selectFile,
          subcktList: _subcktList,
          writeSelectFile: _writeSelectFile,
          isUpdateCSMValue: true
        });
        this.clearCsmPairs(modelType)
      }
    } else {
      if (key && key.id) {
        const { id: libraryId, fileName } = key
        const { onDieSpiceList } = this.props;
        const currentFolder = onDieSpiceList.find(item => item.id === libraryId);
        if (currentFolder) {
          const { selectFile, readSelectFile, readSubcktList, subcktList, writeSelectFile } = this.state;
          let _selectFile = { ...selectFile }, updateSelectFile = false;
          let _readSelectFile = { ...readSelectFile }, _subcktList = [...subcktList], _readSubcktList = [...readSubcktList], _writeSelectFile = { ...writeSelectFile }
          let updateFileInfo = { libraryId, fileName, subckt: "", folder: currentFolder.name };
          if (modelType === READ) {
            if (this.getIsUpdate(selectFile, readSelectFile, writeSelectFile)) {
              _selectFile = { ...updateFileInfo }
              updateSelectFile = true;
            }
            _readSelectFile = { ...updateFileInfo };
          } else {
            if (this.getIsUpdate(selectFile, writeSelectFile)) {
              _selectFile = { ...updateFileInfo }
              updateSelectFile = true;
            }
            _writeSelectFile = { ...updateFileInfo };
            _subcktList = []
          }
          this.clearCsmPairs(modelType)
          this.setState({
            selectFile: _selectFile,
            subcktList: _subcktList,
            readSelectFile: _readSelectFile,
            readSubcktList: _readSubcktList,
            writeSelectFile: _writeSelectFile,
            isUpdateCSMValue: true
          }, () => {
            this.getFile({ ...updateFileInfo }, modelType, updateSelectFile, true);
          });
        }
      } else {
        // clear data
        const { selectFile, readSelectFile, readSubcktList, subcktList, writeSelectFile } = this.state;
        let _selectFile = { ...selectFile };
        let _readSelectFile = { ...readSelectFile }, _subcktList = [...subcktList], _readSubcktList = [...readSubcktList], _writeSelectFile = { ...writeSelectFile }
        if (modelType === READ) {
          if (_readSelectFile.subckt && selectFile.libraryId === _readSelectFile.libraryId && selectFile.fileName === _readSelectFile.fileName && selectFile.subckt === _readSelectFile.subckt) {
            if (_writeSelectFile.libraryId && _writeSelectFile.fileName && _writeSelectFile.subckt) {
              _selectFile = { ..._writeSelectFile }
            } else {
              _selectFile = new FileInfo({})
            }

          }
          _readSelectFile = new FileInfo({})
          _readSubcktList = [];
        } else {
          if (_writeSelectFile.subckt && selectFile.libraryId === _writeSelectFile.libraryId && selectFile.fileName === _writeSelectFile.fileName && selectFile.subckt === _writeSelectFile.subckt) {
            if (_readSelectFile.libraryId && _readSelectFile.fileName && _readSelectFile.subckt) {
              _selectFile = { ..._readSelectFile }
            } else {
              _selectFile = new FileInfo({})
            }
          }
          _writeSelectFile = new FileInfo({})
          _subcktList = [];
        }
        this.setState({
          selectFile: _selectFile,
          subcktList: _subcktList,
          readSelectFile: _readSelectFile,
          readSubcktList: _readSubcktList,
          writeSelectFile: _writeSelectFile,
          isUpdateCSMValue: true
        });
        this.clearCsmPairs(modelType)
      }
    }
  }

  changeReadSelectModel = (key, modelType) => {
    const [libraryId, fileName] = key ? key.split("::") : [];
    const { onDieSpiceList } = this.props;
    const currentFolder = onDieSpiceList.find(item => item.id === libraryId);
    if (currentFolder) {
      const { selectFile, readSelectFile, readSubcktList, subcktList, writeSelectFile } = this.state;
      let _selectFile = { ...selectFile }, updateSelectFile = false;
      let _readSelectFile = { ...readSelectFile }, _subcktList = [...subcktList], _readSubcktList = [...readSubcktList], _writeSelectFile = { ...writeSelectFile }
      let updateFileInfo = { libraryId, fileName, subckt: "", folder: currentFolder.name };
      if (modelType === READ) {
        if (this.getIsUpdate(selectFile, readSelectFile, writeSelectFile)) {
          _selectFile = { ...updateFileInfo }
          updateSelectFile = true;
        }
        _readSelectFile = { ...updateFileInfo };
      } else {
        if (this.getIsUpdate(selectFile, writeSelectFile)) {
          _selectFile = { ...updateFileInfo }
          updateSelectFile = true;
        }
        _writeSelectFile = { ...updateFileInfo };
        _subcktList = []
      }
      this.clearCsmPairs(modelType)
      this.setState({
        selectFile: _selectFile,
        subcktList: _subcktList,
        readSelectFile: _readSelectFile,
        readSubcktList: _readSubcktList,
        writeSelectFile: _writeSelectFile,
        isUpdateCSMValue: true
      }, () => {
        this.getFile({ ...updateFileInfo }, modelType, updateSelectFile, true);
      });
    }
  }

  changeSelectPLOCModel = (value) => {
    const { customSpiceList } = this.props
    const currentFile = customSpiceList.find(item => item.id === value)
    if (currentFile) {
      const { selectFile, plocSelectFile } = this.state
      let _selectFile = { ...selectFile }, updateSelectFile = false;
      let _plocSelectFile = { ...plocSelectFile }
      let updateFileInfo = { libraryId: currentFile.id, fileName: currentFile.name };
      _selectFile = { ...updateFileInfo }
      updateSelectFile = true;
      _plocSelectFile = { ...updateFileInfo };
      this.clearCsmPairs('PLOC')
      this.setState({
        selectFile: _selectFile,
        plocSelectFile: _plocSelectFile,
        isUpdateCSMValue: true
      }, () => {
        this.getPlocFile({ ...updateFileInfo }, updateSelectFile);
      });
    }
  }

  deletePlocFileSelect = () => {
    const { writeSelectFile } = this.state
    this.setState({
      selectFile: { ...writeSelectFile },
      plocSelectFile: {},
    }, () => {
      this.getFile({ ...writeSelectFile }, true);
    });
  }

  clearCsmPairs = (modelType) => {
    const _csmPairs = this.state.csmPairs.map(item => {
      if (modelType === READ) {
        item.readPad = ""
      } else {
        item.pad = "";
        item.in = "";
      }
      if (!item.pad && !item.in && !item.readPad) {
        item.csmSignal = "";
      }
      return item;
    })
    this.setState({
      csmPairs: _csmPairs
    })
  }

  getFile = async (file = {}, modelType, isUpdate, isGetCSM) => {
    const { product } = this.props
    const { libraryId, fileName, subckt, folder } = file;
    if (!libraryId || !fileName) {
      return;
    }
    const model = await this.props.getCSMCPMSpiceModelList({ libraryId, fileName });
    if (!model || !model.model || !model.model.CSM) {
      if (modelType === READ) {
        this.setState({
          readSubcktList: []
        })
      } else {
        this.setState({
          subcktList: []
        })
      }
      return;
    }

    const subcktList = model.model.CSM.map(item => item.name);
    let _subckt = "";
    if (subckt) {
      _subckt = subcktList.find(item => item === subckt);
    }
    if (!_subckt) {
      _subckt = subcktList.length === 1 ? subcktList[0] : (subcktList.find(item => item.match(/CSM/ig)) || "");
    }
    const { selectFile } = this.state;
    let updateSelectFileInfo = product === CASCADE ? { libraryId, fileName, subckt: _subckt } : { libraryId, fileName, subckt: _subckt, folder };
    if (modelType === READ) {
      this.setState({
        readSelectFile: { ...updateSelectFileInfo },
        readSubcktList: subcktList,
        selectFile: isUpdate ? { ...updateSelectFileInfo } : selectFile
      })
    } else {
      this.setState({
        subcktList,
        writeSelectFile: { ...updateSelectFileInfo },
        selectFile: isUpdate ? { ...updateSelectFileInfo } : selectFile
      })
    }
  }

  getPlocFile = async (file = {}, isUpdate) => {
    const { libraryId, fileName } = file;
    if (!libraryId || !fileName) {
      return;
    }
    const model = this.props.getCSMCPMSpiceModelList({ libraryId, fileName, fileType: 'ploc' });
    const { selectFile } = this.state;
    let updateSelectFileInfo = { libraryId, fileName }
    this.setState({
      plocSelectFile: { ...updateSelectFileInfo },
      selectFile: isUpdate ? { ...updateSelectFileInfo } : selectFile
    })
  }

  getIsUpdate = (selectFile, fileInfo, firstFileInfo) => {
    if (!selectFile || !selectFile.libraryId || !selectFile.subckt) {
      return true
    }
    if (selectFile.libraryId === fileInfo.libraryId && selectFile.fileName === fileInfo.fileName && selectFile.subckt === fileInfo.subckt) {
      if (firstFileInfo && selectFile.libraryId === firstFileInfo.libraryId && selectFile.fileName === firstFileInfo.fileName && selectFile.subckt === firstFileInfo.subckt) {
        return false
      }
      return true
    }
    return false
  }

  getIsCPMUpdate = (selectFile, fileInfo) => {
    const { plocSelectFile } = this.state
    if (!selectFile || !selectFile.libraryId) {
      return true
    }
    if (plocSelectFile && plocSelectFile.libraryId && plocSelectFile.fileName) {
      return false
    }
    if (selectFile.libraryId === fileInfo.libraryId && selectFile.fileName === fileInfo.fileName && selectFile.subckt === fileInfo.subckt) {
      return true
    }
    return false
  }

  changeSelectModelSubckt = (subckt, modelType) => {
    const { product } = this.props
    const { readSelectFile, writeSelectFile, selectFile } = this.state;
    if (modelType === READ) {
      let _selectFile = this.getIsUpdate(selectFile, readSelectFile, writeSelectFile) ? { ...selectFile, subckt } : { ...selectFile }
      let _readSelectFile = readSelectFile || {};
      this.setState({
        readSelectFile: { ..._readSelectFile, subckt },
        selectFile: { ..._selectFile },
        isUpdateCSMValue: true
      });
    } else {
      let _selectFile = (product === CASCADE ? this.getIsCPMUpdate(selectFile, writeSelectFile) : this.getIsUpdate(selectFile, writeSelectFile)) ? { ...selectFile, subckt } : { ...selectFile }
      let _writeSelectFile = writeSelectFile || {};
      this.setState({
        writeSelectFile: { ..._writeSelectFile, subckt },
        selectFile: { ..._selectFile },
        isUpdateCSMValue: true
      });
    }
    this.clearCsmPairs()
  }

  openCPMConnection = (e, visible) => {
    e && e.stopPropagation();
    this.setState({
      cpmVisible: visible
    })
  }

  openCSMMapping = (e, visible) => {
    e && e.stopPropagation()
    this.setState({
      csmVisible: visible
    })
  }

  saveCPMPinMapping = ({ cpmPairs, triPairs, transforms, canvasFile, csmPairs }) => {
    this.setState({
      cpmPairs,
      triPairs,
      transforms,
      canvasFile,
      csmPairs
    })
  }

  saveCSMPairs = (csmPairs) => {
    this.setState({
      csmPairs,
      isUpdateCSMValue: false
    })
  }

  csmCpmSelectContent = () => {
    const { onDieSpiceList, customSpiceList, product } = this.props;
    const { subcktList, readSelectFile, readSubcktList, writeSelectFile, plocSelectFile } = this.state;
    const csmCpmSpiceList = product === CASCADE ?
      customSpiceList.filter(item => item.format === 'Folder').map(item => { return { ...item, type: 'folder' } }) :
      onDieSpiceList.filter(item => item.type === 'folder');
    let writeDisable
    if (product === CASCADE) {
      writeDisable = writeSelectFile && writeSelectFile.libraryId && !csmCpmSpiceList.find(item => item.children.find(file => file.id === writeSelectFile.libraryId)) ? true : false;
    } else {
      writeDisable = writeSelectFile && writeSelectFile.libraryId && !csmCpmSpiceList.find(item => item.id === writeSelectFile.libraryId) ? true : false;
    }
    const readDisable = readSelectFile && readSelectFile.libraryId && !csmCpmSpiceList.find(item => item.id === readSelectFile.libraryId) ? true : false;
    return <Fragment>
      <div className='csm-content-item'>
        <span>{product === CASCADE ? 'Model' : 'Write Model'}   </span>
        <TreeSelect
          showSearch
          value={writeSelectFile && writeSelectFile.fileName ? `${writeSelectFile.fileName}` : null}
          onSelectItem={this.changeSelectModel}
          className={writeDisable ? "aurora-select cpm-select-disable" : 'aurora-select'}
          popupClassName="aurora-select-dropdown"
          popupMatchSelectWidth={false}
          allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.changeSelectModel() }} /> }}
          getPopupContainer={() => document.getElementById('root')}
          fileList={csmCpmSpiceList}
          size='small'
          suffixIcon={writeDisable ? <Tooltip
            title={`The corresponding package was not found, please choose again.`}
            overlayClassName='aurora-tooltip'
          ><QuestionCircleOutlined className='aurora-file-check-icon' /></Tooltip> : null}
          selected={writeSelectFile && writeSelectFile.fileName ? `${writeSelectFile.libraryId}::${writeSelectFile.fileName}` : null}
        />
      </div>
      <div className='csm-content-subckt-item'>
        <Select
          className='aurora-select'
          popupClassName="aurora-select-dropdown"
          onSelect={this.changeSelectModelSubckt}
          value={writeSelectFile && writeSelectFile.subckt ? writeSelectFile.subckt : ""}
          size='small'
          showSearch={true}
          popupMatchSelectWidth={false}
        >
          {(subcktList || []).map(item =>
            <Option
              key={item}
              value={item}
            >{item}</Option>
          )}
        </Select>
      </div>
      {product !== CASCADE && <Fragment>
        <div className='csm-content-item'>
          <span>Read Model</span>
          <TreeSelect
            showSearch
            value={readSelectFile && readSelectFile.fileName ? `${readSelectFile.fileName}` : null}
            onSelectItem={(value) => this.changeSelectModel(value, READ)}
            className={readDisable ? "aurora-select cpm-select-disable" : 'aurora-select'}
            popupClassName="aurora-select-dropdown"
            popupMatchSelectWidth={false}
            allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.changeSelectModel(null, READ) }} /> }}
            getPopupContainer={() => document.getElementById('root')}
            fileList={csmCpmSpiceList}
            size='small'
            suffixIcon={readDisable ? <Tooltip
              title={`The corresponding package was not found, please choose again.`}
              overlayClassName='aurora-tooltip'
            ><QuestionCircleOutlined className='aurora-file-check-icon' /></Tooltip> : null}
            selected={readSelectFile && readSelectFile.fileName ? `${readSelectFile.libraryId}::${readSelectFile.fileName}` : null}
          />
        </div>
        <div className='csm-content-subckt-item'>
          <Select
            className='aurora-select'
            popupClassName="aurora-select-dropdown"
            onSelect={(value) => this.changeSelectModelSubckt(value, READ)}
            value={readSelectFile && readSelectFile.subckt ? readSelectFile.subckt : ""}
            size='small'
            showSearch={true}
            popupMatchSelectWidth={false}
          >
            {(readSubcktList || []).map(item =>
              <Option
                key={item}
                value={item}
              >{item}</Option>
            )}
          </Select>
        </div>
      </Fragment>}
      {/* {product === CASCADE && <Fragment>
        <div className='csm-content-item'>
          <span>PLOC Model</span>
          <Select
            className='aurora-select'
            dropdownClassName="aurora-select-dropdown"
            onSelect={(value) => this.changeSelectPLOCModel(value)}
            value={plocSelectFile && plocSelectFile.libraryId ? plocSelectFile.libraryId : null}
            size='small'
            showSearch={true}
            popupMatchSelectWidth={false}
            allowClear={true}
            clearIcon={<Icon type='close-circle' theme='filled' onClick={(e) => { this.deletePlocFileSelect() }} />}
          >
            {(plocModelList || []).map(item =>
              <Option
                key={item.id}
                value={item.id}
              >{item.name}</Option>
            )}
          </Select>
        </div>
      </Fragment>} */}
    </Fragment>
  }

  csmSelectContent = () => {
    const { modelType, onDieSpiceList } = this.props;
    const { selectFile } = this.state;
    const csmCpmSpiceList = onDieSpiceList.filter(item => item.type === "folder");
    return <Fragment>
      <div className='csm-cpm-content-item csm-cpm-content-item-flex'>
        <span>{modelType} Model</span>
        <Select
          className='aurora-select'
          popupClassName="aurora-select-dropdown"
          onSelect={this.changeSelectModel}
          value={selectFile && selectFile.fileName ? `${selectFile.libraryId}::${selectFile.fileName}` : null}
          size='small'
          showSearch={true}
        >
          {(csmCpmSpiceList || []).map(folder =>
            <OptGroup key={folder.id} label={folder.name}>
              {(folder.children || []).map(item =>
                <Option
                  key={`${folder.id}::${item.fileName}`}
                  value={`${folder.id}::${item.fileName}`}
                  className={selectFile && selectFile.fileName && selectFile.libraryId === folder.id && selectFile.fileName === item.fileName ? "ant-select-dropdown-menu-item-selected" : ""}
                >{item.fileName}</Option>
              )}
            </OptGroup>

          )}
        </Select>
      </div>
    </Fragment>
  }

  render = () => {
    const { modelType, onDieSpiceList, packageId, packageChannelId, pcb, SignalNets, product, rockyPackageInfo, isUpdateSsnPackageChannel, pcbChannelInfo, content, pinsInfo, DesignInfo } = this.props;
    const { selectFile, cpmVisible, component, PowerNets, ReferenceNets, csmVisible,
      csmPairs, triPairs, transforms, cpmPairs, canvasFile, readSelectFile, writeSelectFile, loading } = this.state;
    return <Spin spinning={loading}>
      <div className='csm-cpm-content'>
        {this.csmCpmSelectContent()}
        <div className='csm-cpm-content-item'>
          <span>{`${modelType} Connection`}</span>
          <EditOutlined className='csm-cpm-content-icon' onClick={(e) => this.openCPMConnection(e, true)} />
        </div>
        {/* {modelType === CSM ? <div className='csm-cpm-content-item'>
          <span>CSM Instance Mapping</span>
          <Icon type="edit" className='csm-cpm-content-icon' onClick={(e) => this.openCSMMapping(e, true)} />
        </div> : null} */}
        {cpmVisible ? <CPMConnection
          designId={packageId}
          component={component}
          PowerNets={PowerNets}
          ReferenceNets={ReferenceNets}
          selectFile={selectFile}
          triPairs={triPairs}
          transforms={transforms}
          cpmPairs={cpmPairs}
          canvasFile={canvasFile}
          closePanel={() => this.openCPMConnection(null, false)}
          saveCPMPinMapping={this.saveCPMPinMapping}
          SignalNets={SignalNets}
          readSelectFile={readSelectFile}
          writeSelectFile={writeSelectFile}
          getPortList={getWriteAndReadPortList}
          csmPairs={csmPairs}
          pcb={pcb}
          isUpdateFile={true}
          modelType={modelType}
          product={product}
          pcbChannelInfo={pcbChannelInfo}
          isUpdateSsnPackageChannel={isUpdateSsnPackageChannel}
          content={content}
          pinsInfo={pinsInfo}
          getCSMCPMSpiceModelList={this.props.getCSMCPMSpiceModelList}
          DesignInfo={DesignInfo}
        /> : null}
        {csmVisible ? <CSMSignalsConnection
          designId={packageId}
          component={component}
          selectFile={selectFile}
          csmPairs={csmPairs}
          packageChannelId={packageChannelId}
          saveCSMPairs={this.saveCSMPairs}
          closePanel={() => this.openCSMMapping(null, false)}
          readSelectFile={readSelectFile}
          writeSelectFile={writeSelectFile}
          getPortList={getWriteAndReadPortList}
          pcb={pcb}
          rockyPackageInfo={rockyPackageInfo}
          isUpdateSsnPackageChannel={isUpdateSsnPackageChannel}
          pcbChannelInfo={pcbChannelInfo}
          content={content}
          updateSSNPackageInfo={updateSSNPackageInfo}
        /> : null}
      </div>
    </Spin >
  }
}

export default CPMCSMContent;