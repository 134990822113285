import React from 'react';
import {
  getEndToEndChannelEye,
  getChannelsEye,
  getAdsResultEyeDiagram,
  getAdsResultEyeParameters,
  getAdsResultHistoryEyeParameters,
  getAdsResultHistoryEyeDiagram,
  getEndToEndAdsEyeDiagram,
  getEndToEndAdsEyeParameters,
  getEndToEndAdsHisEyeDiagram,
  getEndToEndAdsHistoryEyeParameters,
  getHspiceResultEyeDiagram
} from '../../api/Andes_v2/result';
import NP from 'number-precision';
import { MAXIMAL_LENGTH_LFSR, USER_DEFINED_LFSR, USER_DEFINED_SEQUENCE, BIT_BY_BIT, ADS_TX, ADS_RX, TMDS_2, TMDS_14, getProbeType } from '../AMIModelHelper';
import { CPHY, PCIE } from '../../PCBHelper/constants';
import { versionCompareSize } from '@/services/helper/dataProcess';

async function getSeaSimEyeDiagramFile(id, isEndToEnd, resultType) {
  let response = null;
  if (isEndToEnd) {
    response = await getEndToEndChannelEye(id, resultType);
  } else {
    response = await getChannelsEye(id, resultType);
  }
  return response;
}

async function getAdsEyeDiagramFiles(id, isEndToEnd, resultType, signal, probe) {
  if (isEndToEnd) {
    return await getEndToEndAdsEyeFiles(id, resultType, signal, probe)
  } else {
    return await getAdsEyeFiles(id, resultType, signal, probe)
  }
}

async function getAdsEyeFiles(id, resultType, signal, probe) {
  let adsEyeResult = null, adsEyeParamResult = null;
  if (resultType === "current") {
    adsEyeResult = await getAdsResultEyeDiagram({ channelId: id, signal: signal.name, probe });
    adsEyeParamResult = await getAdsResultEyeParameters({ channelId: id, signal: signal.name, probe });
  } else {
    adsEyeResult = await getAdsResultHistoryEyeDiagram({ historyId: resultType, signal: signal.name, probe });
    adsEyeParamResult = await getAdsResultHistoryEyeParameters({ historyId: resultType, signal: signal.name, probe });
  }
  return { adsEyeResult, adsEyeParamResult }
}

async function getEndToEndAdsEyeFiles(id, resultType, signal, probe) {
  let adsEyeResult = null, adsEyeParamResult = null;
  if (resultType === "current") {
    adsEyeResult = await getEndToEndAdsEyeDiagram({ endToEndChannelId: id, signal: signal.name, probe });
    adsEyeParamResult = await getEndToEndAdsEyeParameters({ endToEndChannelId: id, signal: signal.name, probe });
  } else {
    adsEyeResult = await getEndToEndAdsHisEyeDiagram({ historyId: resultType, signal: signal.name, probe });
    adsEyeParamResult = await getEndToEndAdsHistoryEyeParameters({ historyId: resultType, signal: signal.name, probe });
  }
  return { adsEyeResult, adsEyeParamResult }
}

async function getHspiceEyeDiagramFiles(id, isEndToEnd, resultType, signal) {
  let hspiceEyeResult = null, hspiceEyeParamResult = null;
  if (resultType === "current") {
    hspiceEyeResult = await getHspiceResultEyeDiagram({ channelId: id, signal: signal.name });
  } else {
    // TODO
    // hspiceEyeResult = await getAdsResultHistoryEyeDiagram({ historyId: resultType, signal: signal.name, probe });
    // hspiceEyeParamResult = await getAdsResultHistoryEyeParameters({ historyId: resultType, signal: signal.name, probe });
  }
  return { hspiceEyeResult, hspiceEyeParamResult };
}

class EyeDiagramResult {

  constructor() {
    this.eyeDiagrams = new Map(); // key: id ,value: eye diagram
  }

  getEyeDiagram = async ({ resultId, id, isEndToEnd, type, signals, resultType }) => {
    const results = this.eyeDiagrams.get(resultId);
    if (results && results.length) {
      return results;
    }
    let _resultFiles = null;
    if (type === "compliance") {
      _resultFiles = await getSeaSimEyeDiagramFile(id, isEndToEnd, resultType);
      return await this.parseSeaSimEyeResult(id, _resultFiles, resultId);
    }
  }

  getAdsEyeDiagram = async ({ resultId, id, isEndToEnd, type, signal, resultType, probe }) => {
    const results = this.eyeDiagrams.get(resultId);
    if (results && results.length && results.find(item => item.signal === signal.name)) {
      return results;
    }
    return await this.parseAdsEyeResult({ id, signal, isEndToEnd, resultId, resultType, probe });
  }

  getHspiceEyeDiagram = async ({ resultId, id, isEndToEnd, type, signal, resultType }) => {
    const results = this.eyeDiagrams.get(resultId);
    if (results && results.length && results.find(item => item.signal === signal.name)) {
      return results;
    }
    return await this.parseHspiceEyeResult({ id, signal, isEndToEnd, resultId, resultType });
  }

  parseSeaSimEyeResult = async (id, resultFiles, resultId) => {
    if (!resultFiles) {
      return null;
    }
    const signalsFileContent = resultFiles[`Pictures/result.json`];
    const signalsFileJson = JSON.parse(arrayBufferToString(signalsFileContent));
    let results = [];
    for (let signal of signalsFileJson) {
      /*  signal -> {
         imageFile: "CDF_pcb_645722538961408000_0.png"
         parameterFile: "CDF_pcb_645722538961408000_0.json"
         signal: "PCIE_LANE0_TX"
       } */
      const imageFile = resultFiles[`Pictures/${signal.imageFile}`],
        parameterFile = resultFiles[`Pictures/${signal.parameterFile}`];
      const parameter = parameterFile ? JSON.parse(arrayBufferToString(parameterFile)) : null;
      let imgSrc = "";

      if (imageFile) {
        imgSrc = await ('data:image/png;base64,' + btoa(
          new Uint8Array(imageFile)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        ))
      }

      results.push({
        signal: signal.signal,
        imgSrc,
        parameter
      })
    }
    this.eyeDiagrams.set(resultId, results);
    return results;
  }

  parseAdsEyeResult = async ({ signal, id, resultId, isEndToEnd, resultType, probe, type }) => {
    let _resultFile = [];
    const { adsEyeResult, adsEyeParamResult } = await getAdsEyeDiagramFiles(id, isEndToEnd, resultType, signal, probe) || {};

    let imgSrc = "";

    if (adsEyeResult) {
      imgSrc = await ('data:image/png;base64,' + btoa(
        new Uint8Array(adsEyeResult)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      ))
    }

    _resultFile = {
      signal: signal.name,
      imgSrc,
      parameter: adsEyeParamResult || {},
      probeType: getProbeType(probe)
    };

    let resultFiles = this.eyeDiagrams.get(resultId);
    if (resultFiles) {
      resultFiles.push(_resultFile)
    } else {
      resultFiles = [_resultFile];
    }
    this.eyeDiagrams.set(resultId, resultFiles);
    return resultFiles;
  }

  parseHspiceEyeResult = async ({ signal, id, resultId, isEndToEnd, resultType }) => {
    let _resultFile = [];
    const { hspiceEyeResult, hspiceEyeParamResult } = await getHspiceEyeDiagramFiles(id, isEndToEnd, resultType, signal) || {};

    let imgSrc = "";

    if (hspiceEyeResult) {
      imgSrc = await ('data:image/png;base64,' + btoa(
        new Uint8Array(hspiceEyeResult)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      ))
    }

    _resultFile = {
      signal: signal.name,
      imgSrc,
      parameter: hspiceEyeParamResult || {}
    };

    const resultFiles = this.eyeDiagrams.get(resultId) || [];
    resultFiles.push(_resultFile)
    this.eyeDiagrams.set(resultId, resultFiles);
    return resultFiles;
  }

  cleanEyeDiagram = (ids = []) => {

    Array.from(this.eyeDiagrams.keys()).forEach(id => {

      if (ids.filter(item => id.match(`${item.id}_${item.type}`)).length) {
        this.eyeDiagrams.delete(id);
      }
    });
  }

  clearAllEyeDiagrams = () => {
    this.eyeDiagrams = new Map();
  }
}

function eyeDiagramItem({
  id,
  signal,
  loading,
  imgSrc,
  parameter,
  config = {},
  adsConfig = {},
  isEndToEnd,
  firstId,
  lastPCBId,
  lastPCBIndex,
  type,
  probeType,
  interfaceType
}) {
  this.id = id; //id_signal
  this.signal = signal;
  this.loading = loading;
  this.imgSrc = imgSrc;
  this.parameter = parameter;
  if (type === "compliance") {
    this.direction = config.direction || "";
    if (isEndToEnd) {
      if (config.controller.design.designId === firstId) {
        this.controller = `PCB 1_${config.controller.component}`;
      } else if (config.controller.design.designId === lastPCBId) {
        this.controller = `PCB ${lastPCBIndex + 1}_${config.controller.component}`;
      }

      if (config.device.design.designId === firstId) {
        this.device = `PCB 1_${config.device.component}`;
      } else if (config.device.design.designId === lastPCBId) {
        this.device = `PCB ${lastPCBIndex + 1}_${config.device.component}`;
      }
    } else {
      this.controller = config.controller ? config.controller.component : "";
      this.device = config.device ? config.device.component : "";
    }
  }

  if (type === "ads") {
    const findSignal = adsConfig.signals ? adsConfig.signals.find(item => item.signalName === signal) : null;
    this.probeType = probeType;
    if (findSignal) {
      const txModel = getAdsSignalModel(ADS_TX, findSignal, interfaceType),
        rxModel = getAdsSignalModel(ADS_RX, findSignal, interfaceType);
      this.controller = txModel ? txModel.component : "";
      this.device = rxModel ? rxModel.component : "";
      if (isEndToEnd) {
        if (adsConfig.controllerChannel && adsConfig.controllerChannel.designId === firstId) {
          this.controller = `PCB 1_${this.controller}`;
        } else if (adsConfig.controllerChannel && adsConfig.controllerChannel.designId === lastPCBId) {
          this.controller = `PCB ${lastPCBIndex + 1}_${this.controller}`;
        }

        if (adsConfig.deviceChannel && adsConfig.deviceChannel.designId === firstId) {
          this.device = `PCB 1_${this.device}`;
        } else if (adsConfig.deviceChannel && adsConfig.deviceChannel.designId === lastPCBId) {
          this.device = `PCB ${lastPCBIndex + 1}_${this.device}`;
        }
      }
    }
  }

  if (type === "hspice") {
    this.probeType = probeType;
    this.controller = adsConfig.controller;
    this.device = adsConfig.device;
  }
}

function getAdsSignalModel(dir, signal, interfaceType) {
  const modelKey = signal.ibisHasAMI === "yes" ? "Ami" : "";
  if (interfaceType === CPHY) {
    return dir === ADS_TX ? signal.cphyTxModel : signal.cphyRxModel;
  }
  if (dir === ADS_RX && signal.rcModel && !signal[`${dir.toLowerCase()}${modelKey}Model`]) {
    return signal.rcModel || {}
  }

  if (signal.ibisHasAMI === "no") {
    return signal[`${dir.toLowerCase()}Model`] || {};
  }

  if (signal.ibisHasAMI === "yes") {
    return signal[`${dir.toLowerCase()}AmiModel`] || {};
  }

  if (!signal.ibisHasAMI) {
    return signal[`${dir.toLowerCase()}AmiModel`] || signal[`${dir.toLowerCase()}Model`] || signal.rcModel || {}
  }
}

function arrayBufferToString(buffer) {
  return String.fromCharCode.apply(null, new Uint8Array(buffer));
}

function getSeaSimEyeParametersArr(version) {
  let seaSimEyeParametersArr = [{ key: 'UI', valueKey: 'ui', unit: 's' }]
  if (version && !versionCompareSize(version, '1.0')) {
    seaSimEyeParametersArr.push(
      { key: 'Eye Width (ew)', valueKey: 'eye_width', unit: 'UI' },
      { key: 'Eye Height (eh peak)', valueKey: 'eye_height', unit: 'mV' },
      { key: 'Eye Height at Center (eh center)', valueKey: 'center_eye_height', unit: 'mV' },
      { key: 'Eye Height offset (pkeh off)', valueKey: 'eye_center_offset', unit: 'UI' },
      { key: 'Eye Center @Peak EH', valueKey: 'eye_height_center_x', unit: 'UI' },
    )
  } else {
    seaSimEyeParametersArr.push(
      { key: 'Eye Width (ew)', valueKey: 'eye_width', unit: 'UI' },
      { key: 'Eye Height (eh peak)', valueKey: 'eye_height', unit: 'mV' },
      { key: 'Eye Center @Peak EH', valueKey: 'eye_height_center_x', unit: 'UI' },
      { key: 'Eye Center Y', valueKey: 'eye_center_y', unit: 'mV' }
    )
  }
  return seaSimEyeParametersArr
}

const adsEyeParametersArr = [
  { key: 'Eye Width', valueKey: 'Width', unit: 's' },
  { key: 'Eye Height', valueKey: 'Height', unit: 'V' },
  { key: 'Eye Width At BER', valueKey: 'WidthAtBER', unit: '' },
  { key: 'Eye Height At BER', valueKey: 'HeightAtBER', unit: '' },
  { key: 'Eye Jitter PP', valueKey: 'JitterPP', unit: '' },
  { key: 'Eye Jitter RMS', valueKey: 'JitterRMS', unit: '' },
  { key: 'Eye Crossing level', valueKey: 'CrossingLevel', unit: '' }
];

const adsEyeParametersArrCPHY = [
  { key: 'Eye Opening Width', valueKey: 'EyeOpeningWidth', unit: 'ns' },
  { key: 'Eye Opening Height', valueKey: 'EyeOpeningHeight', unit: '' },
  { key: 'Max Jitter In UI', valueKey: 'MaxJitterInUI', unit: '' },
  { key: 'Min UI', valueKey: 'MinUI', unit: '' },
  // { key: 'Average UI Duration', valueKey: 'AverageUIDuration', unit: '' },
  { key: 'CPHY Mask Violated', valueKey: 'CphyMaskViolated', unit: '' },
]

function getEyeParamSpec(key, dataRate = "") {
  let width = "", height = "", widthAtBER = "", heightAtBER = "";
  //1ui = 1 / data rate
  //s = 1 / ui
  //s = ui / dataRate
  //calc second by dataRate and ui
  const _width = dataRate === "2.5" ? 0.35 : 0.3;
  let secondWidth = NP.times(1e12, NP.divide(_width, dataRate * 1e9));
  const secondWidthArr = secondWidth.toString().split(".");
  if (secondWidthArr[1] && secondWidthArr[1].length > 3) {
    secondWidth = secondWidth.toFixed(3);
  }
  switch (dataRate) {
    case "2.5":
      width = `0.35 UI (${secondWidth} ps)`;
      height = "130 mV";
      widthAtBER = `0.35 UI (${secondWidth} ps)`;
      heightAtBER = "130 mV";
      break;
    case "5":
      width = `0.3 UI (${secondWidth} ps)`;
      height = "85 mV";
      widthAtBER = `0.3 UI (${secondWidth} ps)`;
      heightAtBER = "85 mV";
      break;
    case "8":
      width = `0.3 UI (${secondWidth} ps)`;
      height = "25 mV";
      widthAtBER = `0.3 UI (${secondWidth} ps)`;
      heightAtBER = "25 mV";
      break;
    case "16":
      width = `0.3 UI (${secondWidth} ps)`;
      height = "15 mV";
      widthAtBER = `0.3 UI (${secondWidth} ps)`;
      heightAtBER = "15 mV";
      break;
    case "32":
      width = `0.3 UI (${secondWidth} ps)`;
      height = "15 mV";
      widthAtBER = `0.3 UI (${secondWidth} ps)`;
      heightAtBER = "15 mV";
      break;
    default: break;
  }
  if (key === "Width") {
    return width;
  }

  if (key === "Height") {
    return height;
  }

  if (key === "WidthAtBER") {
    return widthAtBER
  }

  if (key === "HeightAtBER") {
    return heightAtBER
  }
  return null;
}

function getAdsEyeWidth({ param, key, dataRate }) {
  const value = parseFloat(param[key]);
  //0.750 UI (93.750 ps)
  if (isNaN(value)) {
    return "0.0 UI"
  }
  // ui = 1 / dataRate
  const ui = NP.divide(1, (dataRate * 1e9));
  // s = 1 / ui
  let eyeWidthUI = NP.times(value, NP.divide(1, ui));

  const arr = eyeWidthUI.toString().split(".");

  if (arr[1] && arr[1].length > 3) {
    eyeWidthUI = eyeWidthUI.toFixed(3);
  }

  //display width to ps
  let _value = NP.times(value, 1e12);

  const valueArr = _value.toString().split(".");

  if (valueArr[1] && valueArr[1].length > 3) {
    _value = _value.toFixed(3);
  }

  return `${eyeWidthUI} UI (${_value} ps)`;
}

function getAdsSettingData(config, signalName) {
  if (!config || !Object.keys(config).length) {
    return [];
  }

  const dataRate = config.prbs ? config.prbs.bitRate : "";
  const simMethod = config.simulation ? getAdsSetting(config.simulation.mode) : null;
  const clockDataRate = getHDMIClockBitRate(config.prbs);

  const findSignal = config.signals ? config.signals.find(item => item.signalName === signalName) || {} : {};

  const usageRcModel = findSignal.rcModel && Object.keys(findSignal.rcModel).length ? true : false

  const txModel = getAdsSignalModel(ADS_TX, findSignal),
    rxModel = usageRcModel ? findSignal.rcModel : getAdsSignalModel(ADS_RX, findSignal);

  let prbs = findSignal.prbs ? getAdsSetting(findSignal.prbs.mode) : null,
    txModelName = getModelName(txModel),
    rxModelName = usageRcModel ? rxModel.fileName : getModelName(rxModel);

  return [{
    dataRate,
    simMethod,
    prbs,
    txModelName,
    rxModelName,
    clockDataRate
  }]
}

function getHDMIClockBitRate(prbs) {
  if (prbs && [TMDS_14, TMDS_2].includes(prbs.type)) {
    return prbs.clockBitRate;
  }
  return "";
}

function getAdsSetting(type) {
  switch (type) {
    case "Maximal_Length_LFSR":
      return MAXIMAL_LENGTH_LFSR;
    case "User_Defined_LFSR":
      return USER_DEFINED_LFSR;
    case "User_Defined_Sequence":
      return USER_DEFINED_SEQUENCE;
    case "Bit_by_bit":
      return BIT_BY_BIT;
    default: return type;
  }
}

function getModelName(model) {
  if (!model) {
    return null;
  }
  if (model.invModelName && model.invModelName !== model.modelName) {
    return `${model.modelName} / ${model.invModelName}`
  }
  return model.modelName;
}

function getEyeParamColumn(type, interfaceType) {


  let eyeParamColumn = [{
    title: '',
    dataIndex: 'key',
    width: '50%',
  }, {
    title: 'Simulation',
    dataIndex: 'value',
    width: '50%',
  }];

  if (interfaceType === PCIE && type === "ads") {
    eyeParamColumn.forEach(item => item.width = "30%");
    eyeParamColumn.push({
      title: "PCIe Spec",
      dataIndex: 'spec',
      width: '40%',
      render: (value) => {
        return <span className="andes-result-eye-param-spec">{value}</span>
      }
    })

    eyeParamColumn[1].render = (item, record) => {
      const disableClassName = record.disable ? "eye-param-table-disable-td" : "";
      return <div className={disableClassName}>{item}</div>
    }
  }

  if (interfaceType === CPHY) {
    eyeParamColumn[1].render = (item, record) => {
      let className = ""
      if (["MaskViolated", "CPHY Mask Violated"].includes(record.key)) {
        className = record.value === "Pass" ? "eye-param-table-pass-td" : "eye-param-table-fail-td";
      }
      return <div className={className}>{item}</div>
    }
  }

  return eyeParamColumn;
}

function getEyeSettingColumns(clockBitRateExist) {
  let eyeSettingColumns = [{
    title: "Data Rate",
    dataIndex: "dataRate",
    width: "12%"
  }, {
    title: "IBIS TX model",
    dataIndex: "txModelName",
    width: "26%"
  }, {
    title: "IBIS RX model",
    dataIndex: "rxModelName",
    width: "26%"
  }, {
    title: "PRBS",
    dataIndex: "prbs",
    width: "18%"
  }, {
    title: "Simulation Method",
    dataIndex: "simMethod",
    width: "18%"
  }];

  if (clockBitRateExist) {
    eyeSettingColumns.splice(1, 0, {
      title: "Clock Data Rate",
      dataIndex: "clockDataRate",
      width: "14%"
    });
    eyeSettingColumns[0].width = "12%";
    eyeSettingColumns[2].width = "21%";
    eyeSettingColumns[3].width = "21%";
    eyeSettingColumns[4].width = "14%";
  }
  return eyeSettingColumns;
}

function getComplianceColumns() {
  const columns = [{
    title: "paramName",
    dataIndex: "paramName",
    width: "50%",
    render: (text, record, index) => {
      if (!record.isColSpan) {
        return <span>{text}</span>
      }
      return {
        children: <strong>{text}</strong>,
        props: {
          colSpan: 2,
        },
      };
    },
  }, {
    title: "paramValue",
    dataIndex: "paramValue",
    width: "50%",
    render: (text, record, index) => {
      if (!record.isColSpan) {
        return <span>{text}</span>
      }
      return {
        children: null,
        props: {
          colSpan: 0,
        },
      };
    },
  }];
  return columns
}

function getComplianceCollapseData(seaSimParameters) {
  let dataSource = [], expandedRowKeys = [];
  seaSimParameters.forEach(item => {
    const { group, params = [] } = item;
    const paramName = group;
    let children = [];
    for (let paramInfo of params) {
      if (paramInfo && paramInfo.length > 1) {
        children.push({ paramName: paramInfo[0], paramValue: paramInfo[1] })
      }
    }
    expandedRowKeys.push(paramName)
    dataSource.push({ paramName, children, isColSpan: true })
  })
  return { dataSource, expandedRowKeys }
}

const eyeDiagramResult = new EyeDiagramResult();

export {
  eyeDiagramResult,
  eyeDiagramItem,
  adsEyeParametersArr,
  adsEyeParametersArrCPHY,
  getEyeParamSpec,
  getAdsEyeWidth,
  getAdsSettingData,
  getEyeSettingColumns,
  getEyeParamColumn,
  getComplianceColumns,
  getComplianceCollapseData,
  getSeaSimEyeParametersArr
};
