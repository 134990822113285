import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Input, Select } from 'antd';
import Panel from '@/components/Panel';
import { PCIE } from '@/services/PCBHelper/constants';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { getDefaultName } from '@/services/helper/setDefaultName';
import { channelInputList } from '../../../../services/Andes_v2/constants';
import './index.css';
import { CPHY } from '../../../../services/PCBHelper/constants';

const NAME = "name";
const Option = Select.Option;
class CreateEndToEndChannel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: null,
      serdesType: PCIE,
      errorMsg: null
    }
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    const stateContent = this.state;
    const { errorMsg, name, serdesType } = stateContent;
    if (errorMsg || this.checkValue(name, NAME)) {
      return;
    }

    this.props.closePanel({
      data: {
        id: null,
        name,
        type: serdesType
      }
    });
  }

  componentDidMount = () => {
    //set default end to end channel name
    this.setDefaultName();
  }

  componentDidUpdate = (prevProps) => {
    const { data: { id } } = this.props;//id -> projectId
    const { data: { id: prevProjectId } } = prevProps;
    if (prevProjectId !== id) {
      //set default end to end channel name
      this.setDefaultName();
    }
  }

  setDefaultName = () => {
    const { endToEndChannelList } = this.props;
    const name = getDefaultName({ nameList: endToEndChannelList, defaultKey: "Multi-PCB-Channel" });
    this.setState({
      name
    });
  }

  inputChange = (e, type) => {
    const { errorMsg } = this.state;
    this.setState({
      [type]: e.target.value,
      errorMsg: errorMsg && errorMsg.type === type ? null : errorMsg
    });
  }

  selectChange = (key, type) => {
    this.setState({
      [type]: key
    });
  }

  checkData = (e, type) => {
    this.checkValue(e.target.value, type);
  }

  checkValue = (value, type) => {
    const { endToEndChannelList } = this.props;
    let error = checkNameFormat(value);
    const findChannel = endToEndChannelList.find(item => item.name === value);
    if (findChannel) {
      error = `${value} already exists!`;
    }

    this.setState({
      errorMsg: error ? { type, msg: error } : this.state.errorMsg
    });
    return error;
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  render = () => {
    const config = this.state;
    const { errorMsg } = config;
    const content = (
      <Panel
        className='andes-create-end-to-end-channel-panel'
        position='panel-center-left'
        title="Multi-PCB Channel"
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={500}
        draggable
        minHeight={200}
      >
        <div className='create-end-to-end-channel-content'>
          {channelInputList.map(item =>
            <div key={item.key} className="end-to-end-channel-input-item">
              <label className='end-to-end-channel-edit-input-label'>{item.title}</label>
              {item.key === NAME ? <Input
                className='aurora-input end-to-end-channel-input'
                value={config[item.key]}
                placeholder={item.title}
                onChange={(e) => this.inputChange(e, item.key)}
                onKeyDown={(e) => this.keyDown(e)}
                onBlur={(e) => this.checkData(e, item.key)}
              /> :
                <Select
                  value={config[item.key]}
                  onChange={(key) => this.selectChange(key, item.key)}
                  placeholder={"Serdes Type"}
                  className='aurora-select end-to-end-channel-select'
                  popupClassName='aurora-select-dropdown'
                  allowClear
                >
                  {item.list.map(it =>
                    <Option key={it.key} disabled={it.disabled}>{it.title}</Option>
                  )}
                </Select>
              }
            </div>)}
          {errorMsg && errorMsg.msg ?
            <div className="end-to-end-channel-input-item">
              <span className='aurora-model-name-error-msg'>{errorMsg.msg}</span>
            </div> : null
          }
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}


export default CreateEndToEndChannel;