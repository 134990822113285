import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import Panel from '../Panel';

class OperationLog extends PureComponent {
  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.width = -1;
  }

  componentWillMount() {
    const domRect = this.dialogRoot.getBoundingClientRect();
    const width = domRect.width || -1;
    this.width = width;
  }

  render() {
    const { title = '', closeModal, children, className, ...restProps } = this.props;
    const defaultLeft = this.width > 300 && this.width - 150 || '50%';
    const content = (
      <Panel
        position='panel-center-left'
        title={title}
        zIndex={2000}
        onCancel={closeModal}
        width={300}
        draggable
        className={className}
        {...restProps}
        defaultLeft={defaultLeft}
        minWidth={300}
        minHeight={100}
      >
        {children}
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
};

export default OperationLog;