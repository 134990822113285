import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { GET_DCR_INFO, UPDATE_DCR_DATA, UPLOAD_DCR, DELETE_PATHR_DATA, CHANGE_PCB, SAVE_EXTRACTION } from './actionType'
import { saveDCRResistanceData, changeResistanceTableLoading, uploadDCRErrorMsg, updatePCBId, updateDCRData, updateWarningMsg } from './action'
import { getDCRData, updateDCRInfo, importDCRPromise, deletePathRInfo, selectDCRDesign } from '@/services/Cascade/DCR/DCRCtrl'
import projectDesigns from '@/services/helper/projectDesigns';
import { getVerificationWorkflow, updateSimulationReducer } from '../simulation/action';
import { updateDesignStatus, updatePCBLog, updateExpand, updateViewList, updateSelectKeys, updatePageLayout } from '../project/action';
import { changeTabMenu, openTabFooter } from "../../../MonitorStore/action";
import { getCheckDataMessage } from '../../../../services/Cascade/helper/PathRHelper';
import { checkResistanceData, generateDataAfterChangePCB } from '../../../../services/Cascade/DCR/checkPathRData';
import { getAllCascadeComponents } from '@/services/Cascade/helper/setupData';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { DCR } from '@/constants/treeConstants';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import compTableHelper from '@/services/Cascade/helper/compTableHelper';
import { LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT, SINGLE_PAGE_LAYOUT } from '@/constants/layoutConstants';
import designConstructor from '../../../../services/helper/designConstructor';
import { DCExtraction, POWERDC } from '../../../../services/Cascade/helper/setupClass';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import componentSetting from '../../../../services/Cascade/helper/compSettingHelper';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import { SPD } from '../../../../constants/designVendor';

function* _getDCRInfo(action) {
  const { verificationId } = action;
  let _currentVerificationId = null, _designId = null, _designName = "";
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  try {
    const res = yield call(getDCRData, verificationId)
    const { dcr, designId, pairs, designName, config, errorMessage } = res;
    _designId = designId;
    _designName = designName;
    yield put(updateDesignStatus(true))
    if (dcr.length && !designId) {
      _designId = projectDesigns.getAvailablePCBsFirstId(openProjectId);
      yield call(selectDCRDesign, { verificationId, designId: _designId })
    }
    if (!dcr.length && !designId) {
      const PCBList = projectDesigns.getAvailablePostLayouts(openProjectId);
      if (PCBList.length === 1) {
        _designId = PCBList[0].id;
        yield call(selectDCRDesign, { verificationId, designId: _designId });
        //init component setting
        yield call(compTableHelper.getTable, _designId);
      }
    }
    const vendor = designConstructor.getDesignVendor(_designId)
    const extraction = vendor === SPD ? new DCExtraction({ ...config, simulator: POWERDC }) : new DCExtraction(config)
    if (!_designName && _designId) {
      //add design name to setup
      _designName = designConstructor.getDesignName(_designId);
      const _data = dcr.map(item => ({
        point1s: item.content ? item.content.point1s : [],
        point2s: item.content ? item.content.point2s : [],
        id: item.id ? item.id : "",
        net: item.content.net || [],
        spec: item.spec || "",
        resistance: item.resistance || ""
      }));
      yield put(updateDCRData({ resistanceData: _data, pairs, designName: _designName, extraction, errorMessage }))
    } else if (!config) {
      yield put(updateDCRData({ resistanceData: dcr, pairs, designName: _designName, extraction, errorMessage }))
    }
    yield call(savaResistanceData, dcr, pairs, _designId, _designName, extraction)
    yield put(uploadDCRErrorMsg(errorMessage))
    yield put(updateWarningMsg([]))
    yield put(getVerificationWorkflow(res.verificationId))
    _currentVerificationId = res.verificationId
  } catch (error) {
    console.error(error)
  }
  const DCRList = CascadeChannels.getList(DCR, openProjectId);
  const currentItem = DCRList.find(dcr => dcr.id === verificationId);
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: _currentVerificationId,
    verificationName: currentItem ? currentItem.name : 'PathR',
    menuType: "simulation"
  }))
  yield put(updatePCBLog([]))

  if (_designId && projectDesigns.getDesignExist(openProjectId, _designId)) {
    yield call(getAuroraDB, _designId);
    const { CascadeReducer: { project: { layout, selectedKeys, viewList, expandedKeys } } } = yield select();
    if ([LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT].includes(layout)) {
      let _selectedKeys = [...selectedKeys];
      let _viewList = [...viewList]
      if (projectDesigns.getAvailableDesignsLength(openProjectId) > 0) {
        _viewList = _viewList.includes('PCB') ? _viewList : [...viewList, 'PCB'];
        let _expandedKeys = [...expandedKeys];
        _selectedKeys = _selectedKeys.filter(key => !key.includes('PCB'))
        _selectedKeys.push(`PCB-${_designId}`);
        let expandedKey = `PCBs-${openProjectId}`;

        if (!_expandedKeys.includes(expandedKey)) {
          _expandedKeys.push(expandedKey)
          yield put(updateExpand(_expandedKeys));
        }
      }
      yield put(updateSelectKeys(_selectedKeys));
      yield put(updateViewList(_viewList));
    } else {
      let _viewList = viewList.filter(item => item !== 'PCB');
      let _selectedKeys = selectedKeys.filter(item => !item.includes('PCB'))
      yield put(updateViewList(_viewList));
      yield put(updateSelectKeys(_selectedKeys));
      yield put(updatePageLayout(SINGLE_PAGE_LAYOUT, 'setup'))
    }
  }
  const { CascadeReducer: { DCR: { verificationId: _verificationId } } } = yield select();
  if (_verificationId === verificationId) {
    yield put(updatePCBId(_designId))
    yield put(updateDesignStatus(false))
  }
}

function* _updateDCRData(action) {
  const { resistanceData, pairs, designName, extraction, errorMessage } = action;
  const { CascadeReducer: { DCR: { verificationId, pairs: storePairs, designName: _designName, extraction: _extraction, errorMessage: _errorMessage } } } = yield select();
  try {
    const _pairs = pairs ? pairs : storePairs;
    const res = yield call(updateDCRInfo, {
      verificationId,
      dcr: resistanceData,
      pairs: _pairs,
      designName: designName ? designName : _designName,
      config: extraction ? extraction : _extraction,
      errorMessage: errorMessage || _errorMessage
    })
    if (errorMessage) {
      yield put(uploadDCRErrorMsg([...errorMessage]));
    }
    yield call(savaResistanceData, res.dcr, _pairs, null, designName, extraction ? extraction : _extraction);
  } catch (error) {
    console.error(error)
  }
}

function* saveErrorMessageToBackend(action) {
  const { CascadeReducer: { DCR: { resistanceData, verificationId, pairs: storePairs, designName: _designName, extraction: _extraction, errorMessage } } } = yield select();
  try {
    yield call(updateDCRInfo, {
      verificationId,
      dcr: resistanceData,
      pairs: storePairs,
      designName: _designName,
      config: _extraction,
      errorMessage
    })
  } catch (error) {
    console.error(error)
  }
}

function* savaResistanceData(data, pairs, _designId, _designName, extraction) {
  const defaultPoint = {
    comp: "",
    net: "",
    pins: [],
    error: false
  }
  let dataList = [];
  if (data.length) {
    dataList = data.map((item, index) => {
      let _net = [];
      if (!item.content.net || !item.content.net.length) {
        if (Array.isArray(item.content.point1s)) {
          _net.push(...item.content.point1s.map(it => it.net).filter(it => !!it))
        } else if (item.content.point1 && item.content.point1.net) {
          _net.push(item.content.point1.net)
        }
        if (Array.isArray(item.content.point2s)) {
          _net.push(...item.content.point2s.map(it => it.net).filter(it => !!it))
        } else if (item.content.point2 && item.content.point2.net) {
          _net.push(item.content.point2.net)
        }
      } else {
        _net = item.content.net
      }
      const point1s = Array.isArray(item.content.point1s) ? item.content.point1s : (item.content.point1 ? [item.content.point1] : [defaultPoint]);
      const point2s = Array.isArray(item.content.point2s) ? item.content.point2s : (item.content.point2 ? [item.content.point2] : [defaultPoint]);
      const samePoints = [];
      for (let point1 of point1s) {
        const { comp, pins } = point1;
        const pins2 = point2s.filter(point => point.comp === comp).map(point => point.pins).flat();
        if (pins2.length) {
          pins.forEach(pin => {
            if (pins2.includes(pin)) {
              samePoints.push(...[comp, `${comp}::${pin}`])
            }
          })
        }
      }
      return ({
        point1s: point1s,
        point2s: point2s,
        net: [...new Set(_net)],
        id: item.id,
        resistance: item.resistance,
        index: index + 1,
        spec: item.spec,
        error: item.error,
        samePoints: [...new Set(samePoints)]
      })
    });
  }
  yield put(saveDCRResistanceData(dataList, pairs, _designName, extraction))
}

function* _uploadDCR(action) {
  const { verificationId, file, importType } = action;
  try {
    yield call(importDCRPromise, { verificationId, file, importType })
    const response = yield call(getDCRData, verificationId);
    const { CascadeReducer: { DCR: { designId, extraction } } } = yield select();

    const resistanceData = response.dcr.map(item => ({
      ...item.content,
      id: item.id,
      resistance: item.resistance,
      spec: item.spec
    }));
    let newData = [];
    for (let resData of resistanceData) {
      if (!resData.net) {
        let net = [];
        const components = getAllCascadeComponents({ pcbId: designId });
        let _point1s = JSON.parse(JSON.stringify(resData.point1s || []));
        for (let pointItem of _point1s) {
          let currentComp = components.get(pointItem.comp);
          if (!currentComp) {
            pointItem = { comp: "", pins: [], net: "" }
          } else if (pointItem.pins.length) {
            let _net = [...currentComp.pins.values()].find(pin => pin.pin === pointItem.pins[0]);
            if (_net) {
              net.push(_net.net);
              pointItem.net = _net.net;
            }
          }
        }
        let _point2s = JSON.parse(JSON.stringify(resData.point2s || []));
        for (let pointItem of _point2s) {
          let currentComp = components.get(pointItem.comp);
          if (!currentComp) {
            pointItem = { comp: "", pins: [], net: "" }
          } else if (pointItem.pins.length) {
            let _net = [...currentComp.pins.values()].find(pin => pin.pin === pointItem.pins[0]);
            if (_net) {
              net.push(_net.net);
              pointItem.net = _net.net;
            }
          }
        }

        const samePoints = [];
        for (let point1 of _point1s) {
          const { comp, pins } = point1;
          const pins2 = _point2s.filter(point => point.comp === comp).map(point => point.pins).flat();
          if (pins2.length) {
            pins.forEach(pin => {
              if (pins2.includes(pin)) {
                samePoints.push(...[comp, `${comp}::${pin}`])
              }
            })
          }
        }
        newData.push({ ...resData, point1s: _point1s, point2s: _point2s, net: [...new Set(net)], samePoints })
      } else {
        const samePoints = [];
        for (let point1 of resData.point1s) {
          const { comp, pins } = point1;
          const pins2 = resData.point2s.filter(point => point.comp === comp).map(point => point.pins).flat();
          if (pins2.length) {
            pins.forEach(pin => {
              if (pins2.includes(pin)) {
                samePoints.push(...[comp, `${comp}::${pin}`])
              }
            })
          }
        }
        newData.push({ ...resData, samePoints })
      }
    }
    // check resistance data
    const { debugMonitor, _data, _resistanceData, warningMessage } = checkResistanceData({ data: [...newData], designId, getMessage: getCheckDataMessage });
    const vendor = designConstructor.getDesignVendor(designId)
    const _extraction = vendor === SPD ? new DCExtraction({ ...extraction, simulator: POWERDC }) : new DCExtraction(extraction)
    yield call(updateDCRInfo, { verificationId, dcr: _resistanceData, pairs: response.pairs, designName: response.designName, config: _extraction });
    yield put(openTabFooter())
    yield put(uploadDCRErrorMsg([...(response.errorMessage || []), ...debugMonitor]));
    yield put(updateWarningMsg(warningMessage))

    const promise = yield call(checkVerificationStatus, response.verificationId);
    if (promise && promise.status) {
      if (promise.status !== VERIFY_RUNNING && promise.status !== WAITING) {
        yield put(updateSimulationReducer([]));
      }
    }
    yield put(saveDCRResistanceData(_data, response.pairs));
    yield put(changeResistanceTableLoading(false));
    yield delay(500);
    if (debugMonitor.length) {
      yield call(saveErrorMessageToBackend)
    }
  } catch (error) {
    yield put(changeResistanceTableLoading(false))
  }
}

function* _deletePathRData(action) {
  const { idList } = action;
  const { CascadeReducer: { DCR: { verificationId, extraction, designId, designName } } } = yield select();
  try {
    const res = yield call(deletePathRInfo, { verificationId, dcrIdList: idList });
    const { dcr, pairs, config } = res;
    yield call(savaResistanceData, dcr, pairs, designId, designName, config || extraction)
  } catch (error) {
    console.error(error)
  }
}

function* getAuroraDB(designId) {
  try {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (isPreLayout) {
      yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      return;
    }
    const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
    yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId, setting);
  } catch (e) {
    console.error(e);
  }
}

function* updatePCB(action) {
  const _designId = action.designId;
  const { CascadeReducer: { DCR: { verificationId, pairs, resistanceData } } } = yield select();
  try {
    yield put(updatePCBLog([]))
    yield put(updateDesignStatus(true))
    const newResData = yield call(selectDCRDesign, { verificationId, designId: _designId })
    let postResistanceData = resistanceData, postPairs = pairs;
    if (_designId) {
      yield call(getAuroraDB, _designId);
      if (newResData && newResData.dcr) {
        const data = newResData.dcr.map(item => ({
          point1s: item.content ? item.content.point1s : [],
          point2s: item.content ? item.content.point2s : [],
          id: item.id ? item.id : "",
          net: item.content.net || [],
          spec: item.spec || "",
          resistance: item.resistance || ""
        }));
        postResistanceData = data
      }
      if (newResData && newResData.pairs) {
        postPairs = newResData.pairs
      }
      if (postResistanceData && postResistanceData.length) {
        yield put(changeResistanceTableLoading(true))
        const { data, error } = yield call(generateDataAfterChangePCB, { data: postResistanceData, designId: _designId, getMessage: getCheckDataMessage });
        const { debugMonitor, _data, _resistanceData, warningMessage } = checkResistanceData({ data: [...data], designId: _designId, getMessage: getCheckDataMessage });
        const designName = designConstructor.getDesignName(_designId);
        const vendor = designConstructor.getDesignVendor(_designId)
        const extraction = vendor === SPD ? new DCExtraction({ simulator: POWERDC }) : new DCExtraction();
        yield call(_updateDCRData, { resistanceData: _resistanceData, pairs: postPairs, designName, extraction })
        yield put(saveDCRResistanceData(_data, postPairs));
        yield put(uploadDCRErrorMsg([...debugMonitor]));
        yield put(updateWarningMsg(warningMessage))
        yield put(updatePCBLog(error))
        yield call(saveErrorMessageToBackend)
        if (error.length) {
          yield put(openTabFooter())
        }
        yield put(changeResistanceTableLoading(false))
      } else {
        const vendor = designConstructor.getDesignVendor(_designId)
        const extraction = vendor === SPD ? new DCExtraction({ simulator: POWERDC }) : new DCExtraction()
        yield call(_updateDCRData, { resistanceData: [], extraction })
      }
    }
    //init component setting
    yield call(compTableHelper.getTable, _designId);
    yield put(updatePCBId(_designId))
    yield put(updateDesignStatus(false))
  } catch (error) {
    console.error(error)
  }

}

function* saveExtraction(action) {
  const { extraction } = action;
  const _extraction = new DCExtraction(extraction);
  const { CascadeReducer: { DCR: { resistanceData } } } = yield select();
  yield put(updateDCRData({ resistanceData, extraction: _extraction }))
}

function* DCRSaga() {
  yield takeEvery(GET_DCR_INFO, _getDCRInfo);
  yield takeEvery(UPDATE_DCR_DATA, _updateDCRData);
  yield takeEvery(UPLOAD_DCR, _uploadDCR);
  yield takeEvery(DELETE_PATHR_DATA, _deletePathRData);
  yield takeEvery(CHANGE_PCB, updatePCB);
  yield takeEvery(SAVE_EXTRACTION, saveExtraction);
}

export default DCRSaga