import { getCascadeDCRData, updateCascadeDCRInfo, importDCR, simulationDCR, deleteCascadePathRInfo, selectDCRPCB } from '../../api/Cascade/DCR'
import apiProcess from '../../api/utility'

function getDCRData(verificationId) {
  return apiProcess(getCascadeDCRData, verificationId)
}

function updateDCRInfo({ verificationId, dcr, pairs, designName, config, errorMessage }) {
  return apiProcess(updateCascadeDCRInfo, { verificationId, dcr, pairs, designName, config, errorMessage })
}

function importDCRPromise({ verificationId, file, importType }) {
  return apiProcess(importDCR, { verificationId, file, importType })
}

function simulationDCRPromise(verificationId) {
  return apiProcess(simulationDCR, { verificationId }, false, true)
}
function deletePathRInfo({ verificationId, dcrIdList }) {
  return apiProcess(deleteCascadePathRInfo, { verificationId, dcrIdList })
}

function selectDCRDesign({ verificationId, designId }) {
  return apiProcess(selectDCRPCB, { verificationId, designId })
}

export {
  getDCRData,
  updateDCRInfo,
  importDCRPromise,
  simulationDCRPromise,
  deletePathRInfo,
  selectDCRDesign
}