import {
  START_CARD_MODELING,
  AUTO_GET_CARD_VERIFICATIONS
} from './actionTypes';

export const startCardModeling = (verificationIds, verificationId) => ({
  type: START_CARD_MODELING,
  verificationIds,
  verificationId
});

export const autoGetCardVerifications = ({ projectId, channelId, currentSimInfo }) => ({
  type: AUTO_GET_CARD_VERIFICATIONS,
  projectId,
  channelId,
  currentSimInfo
});