export const
  IGNORE = 'Ignore',
  RES = "Res",
  IND = "Ind",
  CAP = "Cap",
  IC = "IC",
  CONNECTOR = "Connector",
  CONTROLLER = "Controller",
  MEMORY = "Memory",
  CHIP = 'Chip',
  JUMPER = 'Jumper',
  FERRITE = 'Ferrite',
  SWITCH = 'Switch',
  CMC = "CMC",
  DIODE = "Diode",
  CMC_COMMENT = "Common Mode Choke",
  BGA = "BGA",
  DIE = "DIE",
  TRANSISTOR = "Transistor",
  REMOVED = "Removed",
  RLC = "RLC",
  UNUSED = "Unused",
  COMP_REPEATER = 'Repeater',
  TEST_POINT = 'testPoint',
  TEST_POINT_DISPLAY = "Test Point",
  IPD = 'IPD',
  PMIC = 'PMIC',
  LOAD = 'Load',
  VRM = 'VRM';

// PMIC Type
export const
  SWITCHING = 'switching', // most have Inductor
  SPECIALIZED = 'specialized', // may be have Inductor
  LINEAR = 'linear', // without Inductor
  LINEAR_SWITCH = 'linearSwitch', // may be have Inductor
  DISCRETE_BUCK = 'discreteBuck',
  POWER_SWITCH = 'powerSwitch'; // 