// value - am,ph,re,im,db,
function getYLabel(setting) {
  if (!setting) return '';
  const { value, yScale, parameter, param } = setting;
  let label = '';
  if (!value) {
    return label;
  };
  switch (value) {
    case 'am':
      if (parameter === 'Z' || param === 'Z') {
        label = 'Impedance (Ω)';
      } else {
        label = 'Magnitude';
      }
      break;
    case 'ph':
      if (yScale && yScale === 'radians') {
        label = 'Angle (radians)';
      } else {
        label = 'Angle (degrees)';
      }
      break;
    case 'db':
      label = 'Magnitude (dB)';
      break;
    case 're':
      label = 'Real';
      break;
    case 'im':
      label = 'Imaginary';
      break;
    default: break;
  }
  return label;
};

/** display a unit with scaling
 *  @param  prefix - a return from d3.formatPrefix, with scaling factor and symbol name
 *  @param  unit   - String, name of the unit
 *  @return a unit with prefix or a scaling factor
 */
function displayUnit(prefix, unit) {
  if (prefix(1).includes('NaN') || prefix(1).includes('undefined')) {
    return '';
  } else {
    const str = prefix(1).match(/\D$/gi);

    if (str === null) {
      return unit ? '(' + unit + ')' : '';
    }
    const _unit = '(' + str + unit + ')';
    return _unit;
  }
};

function db(value) {
  const log10 = Math.log10;
  if (value === 0) {
    return -80;
  } else if (value < 0) {
    return value;
  } else {
    return log10(value) * 20
  }
};

// radians = degrees * PI / 180
function radiansToDegrees(value) {
  const pi = Math.PI;
  return value / pi * 180;
};

export { getYLabel, displayUnit, db, radiansToDegrees };