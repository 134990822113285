const SWEEP_TYPE = 'Andes_v2';

export const OPEN_EXPERIMENT = `${SWEEP_TYPE}/open_experiment`;
export const ADD_EXPERIMENT = `${SWEEP_TYPE}/add_experiment`;
export const CREATE_EXPERIMENT = `${SWEEP_TYPE}/create_experiment`;
export const RENAME_EXPERIMENT = `${SWEEP_TYPE}/rename_experiment`;
export const DELETE_EXPERIMENT = `${SWEEP_TYPE}/delete_experiment`;

export const ADD_EXPERIMENT_DATE = `${SWEEP_TYPE}/add_experiment_data`;
export const HANDLE_SIGNAL_EXPERIMENT_DATA = `${SWEEP_TYPE}/handle_signal_experiment_data`;
export const UPDATE_EXPERIMENT_DATA = `${SWEEP_TYPE}/update_experiment_data`;
export const UPDATE_IS_UPDATE_EXPERIMENT_DATA = `${SWEEP_TYPE}/update_is_update_experiment_data`;
export const CHANGE_VARIABLES = `${SWEEP_TYPE}/change_variables`;

export const UPDATE_SWEEP_INFO = `${SWEEP_TYPE}/update_sweep_info`;
export const UPDATE_TRACE_CONFIGS = `${SWEEP_TYPE}/update_trace_configs`;
export const UPDATE_TRACE_SETTING = `${SWEEP_TYPE}/update_trace_setting`;
export const HANDLE_TRACE_SETTING = `${SWEEP_TYPE}/handle_trace_setting`;
export const UPDATE_SWEEP_LOADING = `${SWEEP_TYPE}/update_sweep_loading`;
export const CLEAR_SWEEPINFO = `${SWEEP_TYPE}/clear_sweep_info`;
export const UPDATE_EXPERIMENT_IDS = `${SWEEP_TYPE}/update_experiment_ids`;
export const UPDATE_NOT_EXIST_NETS = `${SWEEP_TYPE}/update_not_exist_nets`;
