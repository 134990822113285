import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { getFastPIDebugDownloadList } from '@/services/helper/debugHelper';
import Panel from '@/components/Panel';
import { SIMULATION_DEBUG, PCB_PARSING, getFastPIDownloadSimulationUrl, REPORT } from '@/services/helper/debugHelper';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import DebugDownloadContent from '@/components/DebugDownload_v2'
import '@/publicCss/style.css';
import '@/publicCss/debug.css';

class DebugDownloadPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 750,
      maxHeight: 304,
      debugMenuList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 304),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    const { currentProjectDesigns, currentProjectPDNs } = this.props;
    this.resize();
    if (currentProjectDesigns || currentProjectPDNs) {
      this.getDownloadOptions()
    }
  }

  componentDidUpdate = (prevState) => {
    const { PDNID } = this.props;
    if (PDNID !== prevState.PDNID) {
      this.getDownloadOptions()
    }
  }

  getDownloadOptions = () => {
    const { currentProjectDesigns, currentProjectPDNs, PDNID } = this.props;
    const designId = currentProjectDesigns.length > 0 ? currentProjectDesigns[0].id : "";
    let PDNId;
    if (PDNID) {
      PDNId = currentProjectPDNs.length > 0 ? currentProjectPDNs.filter(item => item.id === PDNID)[0].id : null
    } else {
      PDNId = currentProjectPDNs.length > 0 ? currentProjectPDNs[0].id : "";
    }
    const parsingData = this.getData(designId, currentProjectDesigns, PCB_PARSING);
    const simulationData = this.getData(PDNId, currentProjectPDNs, SIMULATION_DEBUG);
    const reportData = this.getReportData(currentProjectPDNs, REPORT);

    const debugMenuList = [simulationData, parsingData, reportData];
    this.setState({
      debugMenuList
    })
  }

  getReportData = (currentList, menuType) => {
    const { currentProjectName } = this.props
    const currentCheckValues = currentList.map(item => { return { name: item.id, children: [] } })
    const downloadList = currentList.map(item => { return { name: item.name, option: item.id, default: true } })
    return {
      title: 'Report',
      key: menuType,
      menuType: menuType,
      info: { notSelected: true },
      downloadList: downloadList,
      currentCheckValues,
      currentProjectName
    }
  }

  getData = (id, currentList = [], menuType) => {
    const currentProjectData = currentList.find(item => item.id === id)
    let currentId = '', name = '';
    if (currentProjectData) {
      currentId = menuType === PCB_PARSING ? currentProjectData.id : currentProjectData.verificationId;
      name = currentProjectData.name
    }
    const selectInfo = { id: currentId, name }
    const { downloadList, currentCheckValues } = this._getDownloadList({ menuType, vendor: currentProjectData ? currentProjectData.vendor : null })
    const title = menuType === PCB_PARSING ? 'Layout Parsing' : 'PDN Simulation';
    return {
      title,
      key: menuType,
      menuType: menuType,
      info: {
        selectList: [...currentList],
        selectInfo
      },
      downloadList,
      currentCheckValues
    }
  }

  _getDownloadList = (params) => {
    const downloadList = getFastPIDebugDownloadList({ ...params });
    let currentCheckValues = [];
    for (let item of downloadList) {
      if (item.default) {
        currentCheckValues.push({ name: item.option, children: item.children ? item.children.map(it => it.option) : [] });
      }
    }
    return { downloadList, currentCheckValues }
  }

  getHeight = () => {
    const content = document.getElementById("debug-content-id");
    if (content) {
      const height = content.offsetHeight + 70 || 242;
      return height > 242 ? height : 242;
    }
    return 242;
  }

  render() {
    const { maxWidth, maxHeight, debugMenuList } = this.state;
    const height = this.getHeight();
    const content = (
      <Panel
        className='aurora-debug-panel panel-x-scroll-hidden'
        title={<div className='aurora-debug-title'>Debug</div>}
        onCancel={this.props.closeModel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 960 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        height={maxHeight > height ? null : height}
        overflow={"auto"}
      >
        <DebugDownloadContent
          debugMenuList={debugMenuList}
          // PDNID={PDNID}
          getDownloadList={getFastPIDebugDownloadList}
          getDownloadSimulationURL={getFastPIDownloadSimulationUrl}
        />
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}
export default DebugDownloadPanel;