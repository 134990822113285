import React from "react";
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Collapse, Input, Select, Checkbox, Tooltip, Switch } from 'antd';
import './setupInputGroup.css'
import { numberCheck } from "../../../../services/helper/dataProcess";

const { Option } = Select;

const SELECT = 'select', INPUT = 'input', INPUTANDSELECT = 'inputAndSelect', CHECKBOX = 'checkbox', SWITCH = "switch";

/**
 * type: select/input/selectAndInput/inputAndSelect
 * header: string
 * dataList: [{label,value,type,labelWidth,[unit],[options],[suffixOptions],eleName,[eleType]}]
 */
class SetupInputGroup extends React.Component {
  render = () => {
    const { header, children, collapseClassName, contentClassName, onChange, childrenPosition = "before" } = this.props;

    return <Collapse
      className={`ami-model-collapse-content jitter-collapse-content ${collapseClassName}`}
      items={[{
        key: { header },
        label: <div className="ami-model-jitter-title">{header}</div>,
        children: <>
          {childrenPosition === 'before' ? children : null}
          <SetupInputGroupItem dataList={this.props.dataList} contentClassName={contentClassName} onChange={onChange} />
          {childrenPosition === 'after' ? children : null}
        </>
      }]} />
  }
}

export default SetupInputGroup;

export class SetupInputGroupItem extends React.Component {

  constructor() {
    super()
    this.state = {
      currentInput: {
        item: {},
        value: null
      }
    }
  }

  // input
  onChangeCurrentInput = (item, value) => {
    this.setState({
      currentInput: {
        item,
        value
      }
    })
  }

  onInputBlur = () => {
    const { currentInput: { item, value } } = this.state;
    let _value = value;
    this.setState({ currentInput: { item: {}, value: null } })
    if (value && !!numberCheck(value)) return;

    if (item.unit) {
      _value = value + ' ' + item.unit;
    } else if (item.eleType === 'number') {
      _value = Number(_value);
    }
    this.props.onChange(item.eleName, _value)
  }

  // select unit
  onSelectSuffixChange = (item, suffix) => {
    const value = item.value + " " + suffix;
    this.props.onChange(item.eleName, value)
  }

  // select
  onSelectChange = (item, value) => {
    let _value = value;
    if (item.eleType === 'number') {
      _value = Number(_value);
    }
    this.props.onChange(item.eleName, _value)
  }

  onSimpleChange = (item, value) => {
    this.props.onChange(item.eleName, value)
  }

  getInputAndSelectRender = (item = {}) => {
    const { currentInput: { item: currentItem, value: currentValue } } = this.state;
    const selectAfter = <Select
      value={item.unit}
      className="aurora-select setup-input-group-select-item"
      popupClassName="setup-input-group-select-item-dropdown"
      onChange={(suffix) => this.onSelectSuffixChange(item, suffix)}
      disabled={item.disabled}
    >
      {(item.suffixOptions || [{ value: 'null', label: 'null' }]).map(option => {
        return <Option value={option.value} key={option.value}>{option.label}</Option>
      })}
    </Select>
    return <Input
      style={{ width: `calc(100% - ${item.labelWidth || 220}px)` }}
      value={currentItem && currentItem.eleName === item.eleName ? currentValue : item.value}
      className="aurora-input"
      onChange={(e) => this.onChangeCurrentInput(item, e.target.value)}
      onFocus={() => this.onChangeCurrentInput(item, item.value)}
      onBlur={() => this.onInputBlur()}
      disabled={item.disabled}
      addonAfter={selectAfter}
    />
  }

  getSelectRender = (item = {}) => {
    return <div style={{ width: `calc(100% - ${item.labelWidth || 220}px)` }}>
      <Select
        value={item.value}
        className={`aurora-select ${item.notExist ? "setup-select-width-not-exist" : "setup-select-width"}`}
        popupClassName="setup-input-group-select-item-dropdown"
        onChange={(value) => this.onSelectChange(item, value)}
        disabled={item.disabled}
        allowClear={item.allowClear}
      >
        {(item.options || []).map(option => {
          return <Option value={option.value} key={option.value}>{option.label}</Option>
        })}
      </Select>
    </div>
  }

  getInputRender = (item = {}) => {
    const { currentInput: { item: currentItem, value: currentValue } } = this.state;
    return <>
      <Input
        value={currentItem && currentItem.eleName === item.eleName ? currentValue : item.value}
        className="aurora-input"
        style={{ width: `calc(100% - ${item.labelWidth || 220}px)` }}
        addonAfter={item.unit}
        onChange={(e) => this.onChangeCurrentInput(item, e.target.value)}
        onFocus={() => this.onChangeCurrentInput(item, item.value)}
        onBlur={() => this.onInputBlur()}
        disabled={item.disabled}
      />
    </>;
  }

  getCheckoutRender = (item = {}) => {
    return <Checkbox
      checked={item.value}
      style={{ width: `calc(100% - ${item.labelWidth || 220}px)` }}
      onChange={(e) => this.onSimpleChange(item, e.target.checked)}
      disabled={item.disabled}
    />
  }

  getSwitchRender = (item = {}) => {
    return <div style={{ width: `calc(100% - ${item.labelWidth || 220}px)` }}>
      <Switch
        checked={item.value}
        onChange={(checked) => this.onSimpleChange(item, checked)}
        disabled={item.disabled}
        size="small"
      />
    </div>
  }

  render() {
    const { contentClassName } = this.props;
    const dataList = this.props.dataList || [];
    return <div className={contentClassName}>
      {dataList.map(item => {
        return <div className="ami-advanced-select-item setup-input-group-flex-box" key={item.label}>
          <label>
            {item.label}
            {item.tooltip ? <Tooltip title={item.tooltip} overlayClassName='icon-tooltip'>
              <InfoCircleOutlined className="setup-info-circle-icon" />
            </Tooltip> : null}
          </label>
          {item.type === INPUT ? this.getInputRender(item)
            : item.type === SELECT ? this.getSelectRender(item)
              : item.type === INPUTANDSELECT ? this.getInputAndSelectRender(item)
                : item.type === CHECKBOX ? this.getCheckoutRender(item)
                  : item.type === SWITCH ? this.getSwitchRender(item)
                    : null}
          {item.notExist ? <Tooltip
            title="The file was not found, please choose again"
            overlayClassName='aurora-tooltip'
          >
            <QuestionCircleOutlined className="setup-question-circle-icon" />
          </Tooltip> : null}
        </div>
      })}
    </div>
  }
}

export {
  SELECT,
  INPUT,
  INPUTANDSELECT,
  CHECKBOX,
  SWITCH
}