import http from './http';

export function importProjectData({ product, formData }) {
  return http.post(`project/import/${product}`, formData)
}

export function exportProjectData({ product, libraryIds, projectIds, exportProjectParams }) {
  return http.post(`project/export/${product}`, { libraryIds, projectIds, exportProjectParams })
}

export function getAllLibraryData({ product }) {
  return http.get(`/all/library/${product}`)
}