import { IGNORE, JUMPER, RES, SWITCH } from "../../constants/componentType";
import { SIERRA } from "../../constants/pageType";
import { getAuroraPCBData } from "../api/Design/design";
import { checkRLCValue } from "../helper/dataProcess";
import { CompPMIC, CompPrefixLib } from "./class";
import { NONET, POWER } from "./constants";
import { getCompTypeByPrefixLib, getPMIC } from "./helper";

const trueWord = 'Y', falseWord = 'N';
class AuroraDbData {
  constructor() {
    this.auroraDB = new Map();
  }

  getAuroraJson = async (designId, setting, product) => {
    const auroraJson = this.auroraDB.get(designId);
    if (auroraJson) {
      return auroraJson
    }
    try {
      let _auroraJson = await getAuroraPCBData(designId);
      _auroraJson = this.parseAuroraJson(_auroraJson, setting, product);
      console.log("Get AuroraDB Json Finished.");
      this.auroraDB.set(designId, _auroraJson);
      return _auroraJson
    } catch (error) {
      console.error("Get AuroraDB Json Failed:" + error);
      this.parseLayoutDB(designId);
    }
  }

  parseAuroraJson = (auroraJson, setting, product) => {
    const { components, nets, symbol, unit } = auroraJson;
    const _nets = objectToMap('nets', nets);
    console.log("Parse Net Finished.");
    const _symbol = objectToMap('symbol', symbol);
    console.log("Parse Symbol Finished.");
    const { prefix, pmic, powerSwitch } = this.getCompPrefixLibByProduct(product, setting);
    const _components = objectToMap('components', components, { getType: true, prefix, pmic, powerSwitch, getValue: true });
    console.log("Parse Component Finished.");
    return { nets: _nets, symbol: _symbol, components: _components, unit }
  }

  getCompPrefixLibByProduct = (product, setting) => {
    const compPrefixLib = setting ? setting.compPrefixLib || {} : {};
    let prefix = {}, pmic = [], powerSwitch = [];
    switch (product) {
      case SIERRA:
        prefix = new CompPrefixLib({
          Res: [],
          Ind: [],
          Cap: [],
          Jumper: [],
          Ferrite: [],
          Diode: [],
          Transistor: [],
          Load: [],
          ...compPrefixLib
        });
        break;
      default:
        //cascade
        prefix = new CompPrefixLib({ ...compPrefixLib });
        const compPMICs = new CompPMIC(compPrefixLib);
        pmic = getPMIC(compPMICs);
        powerSwitch = compPMICs.powerSwitch || [];
        break;
    }

    return { prefix, pmic, powerSwitch }
  }

  parseLayoutDB = (designId) => {
    // TODO - parse layout data to auroraDB json when get auroraDB json error
  }

  checkAuroraJson = (designId) => {
    return this.auroraDB.get(designId) ? true : false
  }

  getComponents = (designId) => {
    const auroraJson = this.auroraDB.get(designId);
    return auroraJson ? auroraJson.components || new Map() : new Map();
  }

  getComponentsByNames = (designId, compNames) => {
    const components = this.getComponents(designId);
    return compNames.map(name => components.get(name)).filter(item => !!item)
  }

  getComponentsByPartName = (designId, partName) => {
    const components = this.getComponents(designId);
    return [...components.values()].filter(comp => comp.partName === partName);
  }

  getComponentsByType = (designId, type) => {
    const components = this.getComponents(designId);
    return [...components.values()].filter(comp => comp.type === type);
  }

  getComponentsByNets = (designId, _nets = []) => {
    const auroraJson = this.auroraDB.get(designId) || {};
    const components = auroraJson.components || new Map();
    const nets = auroraJson.nets || new Map();
    const compArray = _nets.map(net => nets.get(net) ? [...nets.get(net).pins.keys()] : []);
    const compSet = [...new Set(compArray.flat(2))];
    return compSet.map(comp => components.get(comp)).filter(item => !!item);
  }

  getComponent = (designId, compName) => {
    const components = this.getComponents(designId);
    return components.get(compName) || undefined;
  }

  getCompPinList = (designId, compName) => {
    const component = this.getComponent(designId, compName);
    if (!component) {
      return []
    }
    const { pins } = component;
    return pins ? [...pins.values()] : [];
  }

  getComponentPins = (designId, compName) => {
    const component = this.getComponent(designId, compName);
    if (!component || !component.location) {
      return []
    }
    const { location, pins } = component;
    const { X, Y, clockwise = trueWord, flipX = falseWord, flipY = falseWord, rotangle = '0', scale = '1' } = location
    // component shift and rotation matrix
    let dx = Number(X) * Number(scale);
    let dy = Number(Y) * Number(scale);
    // mFlipOnX: x = x, y = -y; 
    // mFlipOnY: x = -x, y = y;
    let xm = flipY === trueWord ? -Number(scale) : Number(scale);
    let ym = flipX === trueWord ? -Number(scale) : Number(scale);
    // mCW - true: Clockwise (-1), false: anti-clockwise (1)
    let angle = (clockwise === trueWord ? -1 : 1) * Number(rotangle) * Math.PI / 180.;
    let t = [[xm * Math.cos(angle), - ym * Math.sin(angle)], [xm * Math.sin(angle), ym * Math.cos(angle)]];

    const symbol = this.getSymbol(designId, component.symbol);
    if (!symbol) {
      return []
    }
    // d - x|y
    function getCoordinate(d, x, y) {
      if (d === 'x') {
        return t[0][0] * x + t[0][1] * y + dx;
      } else {
        return t[1][0] * x + t[1][1] * y + dy;
      }
    }
    const pinsInfo = [...pins.values()];
    const _pins = pinsInfo.map(pin => {
      const key = pin.pin;
      const pinLocation = symbol.pins.get(key);
      if (!pinLocation || !pinLocation.location) {
        return null;
      }
      const x = getCoordinate('x', pinLocation.location.X, pinLocation.location.Y),
        y = getCoordinate('y', pinLocation.location.X, pinLocation.location.Y)
      return { ...pin, x, y }
    })
    return _pins
  }

  getMultiRes = (designId, setting) => {
   this.setComponentsType(designId, setting);
   const  components = this.getComponentsByType(designId, RES);
   return components.filter(comp => comp.pins.size > 2)
  }

  getNets = (designId) => {
    const auroraJson = this.auroraDB.get(designId);
    return auroraJson ? auroraJson.nets || new Map() : new Map();
  }

  getNet = (designId, netName) => {
    const components = this.getNets(designId);
    return components.get(netName) || undefined;
  }

  getNetNames = (designId) => {
    const nets = this.getNets(designId);
    return [...nets.keys()];
  }

  getNetsByComponent = (designId, compName) => {
    const nets = this.getNets(designId);
    return [...nets.values()].filter(netInfo => netInfo.pins.get(compName));
  }

  getPowerNetsByComponent = (designId, compName) => {
    const nets = this.getNetsByComponent(designId, compName);
    return nets.filter(net => net.type === POWER && net.name !== NONET)
  }

  getNetsByComponents = (designId, compNames = []) => {
    const auroraJson = this.auroraDB.get(designId) || {};
    const components = auroraJson.components || new Map();
    const nets = auroraJson.nets || new Map();
    const netArray = compNames.map(name => components.get(name) ? [...components.get(name).pins.values()].map(pin => pin.net) : []);
    const netSet = [...new Set(netArray.flat(2))];
    return netSet.map(net => nets.get(net)).filter(item => !!item);
  }

  getNetsByNames = (designId, nets = []) => {
    const netsInfo = this.getNets(designId);
    return nets.map(name => netsInfo.get(name)).filter(item => !!item);
  }

  getSymbols = (designId) => {
    const auroraJson = this.auroraDB.get(designId);
    return auroraJson ? auroraJson.symbol || new Map() : new Map();
  }

  getSymbol = (designId, symbolName) => {
    const symbol = this.getSymbols(designId).get(symbolName);
    return symbol
  }

  getUnit = (designId) => {
    const db = this.auroraDB.get(designId);
    return db ? db.unit : 'mil';
  }

  getPartNames = (designId) => {
    const components = this.getComponents(designId);
    const partNames = [...components.values()].map(comp => comp.partName);
    return [...new Set(partNames)]
  }

  getPartNumberByPartName = (designId, partName) => {
    const components = this.getComponents(designId);
    const component = [...components.values()].find(comp => comp.partName === partName);
    if (!component) {
      return null;
    }
    return component.partNumber
  }

  getPartNameByPartNumber = (designId, partNumber) => {
    const components = this.getComponents(designId);
    const component = [...components.values()].find(comp => comp.partNumber === partNumber);
    if (!component) {
      return null;
    }
    return component.partName
  }

  getPartValueByPartName = (designId, partName) => {
    const components = this.getComponents(designId);
    const component = [...components.values()].find(comp => comp.partName === partName);
    if (!component) {
      return null;
    }
    return component.value
  }

  setComponentsType = (designId, setting) => {
    if (this.checkAuroraJson(designId)) {
      const { compPrefixLib = new CompPrefixLib() } = setting;
      const compPMICs = new CompPMIC(compPrefixLib)
      const prefix = new CompPrefixLib(compPrefixLib), pmic = getPMIC(compPMICs), powerSwitch = compPMICs.powerSwitch || [];
      const components = this.getComponents(designId);
      const newComponents = setType(components, { prefix, pmic, powerSwitch });
      this.setAuroraDB(designId, 'components', newComponents)
    }
  }

  setAuroraDB = (designId, key, value) => {
    const auroraJson = this.auroraDB.get(designId);
    this.auroraDB.set(designId, { ...auroraJson, [key]: value });
  }

  isFlexBoard = (designId) => {
    const compsMap = this.getComponents(designId);
    const comps = [...compsMap.values()];
    //filter pins number more than 2,(Remove RLC,only check flex board,two connector components)
    const filterComps = comps.filter(item => item.pins && item.pins.size > 2);
    if (filterComps && comps.length && filterComps.length <= 2) {
      return true
    }
    return false;
  }
}

const toMap = ['pins', 'template', 'nets', 'symbol', 'components'];
function objectToMap(_key, obj, settings = {}) {
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    const { getType = false, prefix, pmic, getValue = false, powerSwitch } = settings;
    const map = new Map(), _obj = {};;
    for (const [key, value] of Object.entries(obj)) {
      if (getType) {
        const isPMIC = pmic.find(item => item.partName === value.partName);
        if (isPMIC) {
          value.type = IGNORE;
          value.pmicType = isPMIC.type;
        } else if (powerSwitch.includes(value.partName)) {
          value.type = SWITCH
        } else {
          const type = getCompTypeByPrefixLib(key, prefix);
          value.type = type === JUMPER ? value.pins && Object.keys(value.pins).length > 2 ? IGNORE : type : type;
        }
      }
      if (getValue) {
        value.value = checkRLCValue(value.value || "")
      }
      if (toMap.includes(_key)) {
        map.set(key, objectToMap(key, value));
      } else {
        _obj[key] = objectToMap(key, value);
      }
    }
    if (toMap.includes(_key)) {
      return map;
    } else {
      return _obj
    }
  }
  return obj
}

function setType(components, settings = {}) {
  const { prefix, pmic, powerSwitch } = settings;
  const newComponents = new Map();
  components.forEach((value, key) => {
    const isPMIC = pmic.find(item => item.partName === value.partName);
    if (isPMIC) {
      value.type = IGNORE;
      value.pmicType = isPMIC.type;
    } else if (powerSwitch.includes(value.partName)) {
      value.type = SWITCH
    } else {
      const type = getCompTypeByPrefixLib(key, prefix);
      value.type = type === JUMPER ? value.pins && value.pins.size > 2 ? IGNORE : type : type;
    }
    newComponents.set(key, value)
  })
  return newComponents
}

const auroraDBJson = new AuroraDbData();
export default auroraDBJson;