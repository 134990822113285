import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { Radio, Input, Select, Checkbox, Divider, Tooltip } from 'antd';
import { numberCheck } from "@/services/helper/dataProcess";
import NetListModel from '@/components/NetListModel';
import { getScale } from '@/services/helper/numberHelper';
import { checkRLCValue } from '@/services/helper/dataProcess';
import { settingUnit } from '../../services/helper/unitChange';
import NP from 'number-precision';
import DieCurrentPanel from '../../pages/Cascade/Impedance/dieCurrentPanel';
import './index.css';

const { Option } = Select;
const FILE = 'file', VALUE = 'value', ON_DIE = 'onDie', TOUCHSTONE = 'Touchstone', SPICE = "SPICE", CSM = "CSM", CPM = "CPM", PER_PORT = 'perPort', UNIFIED = 'unified', CUSTOM = 'custom'
const s = 0, m = -1, u = -2, n = -3, p = -4, K = 1, M = 2;
const scaleKeys = {
  m: m,
  u: u,
  n: n,
  p: p,
  s: s,
  K: K,
  M: M
}

const rDieOptionList = [{ unit: 'mΩ', num: "m" }, { unit: 'Ω', num: "1" }, { unit: 'KΩ', num: 'K' }, { unit: 'MΩ', num: 'M' }],
  cDieOptionList = [{ unit: 'pF', num: "p" }, { unit: 'nF', num: "n" }, { unit: 'uF', num: 'u' }, { unit: 'F', num: '1' }],
  fileOptionList = [{ title: "Touchstone", key: TOUCHSTONE }, { title: "SPICE", key: SPICE }], rockyOnDieFileOptionList = [{ title: "SPICE", key: SPICE }]

function defaultValue(params) {
  const { rdieValue = '10', rdieUnit = 'm', cdieValue = '100', cdieUnit = 'n', type = 'value' } = params || {}
  this.rdieValue = rdieValue;
  this.rdieUnit = rdieUnit;
  this.cdieValue = cdieValue;
  this.cdieUnit = cdieUnit;
  this.type = type
}
function defaultFileInfo(params) {
  const { libraryId = '', fileName = '', subckt = '', pairs = [], pins = [], type = TOUCHSTONE } = params || {}
  this.libraryId = libraryId;
  this.fileName = fileName;
  this.subckt = subckt;
  this.pairs = pairs;
  this.type = type;
  this.pins = pins
}

export function WriteOrReadOnDieFileInfo({ libraryId = "", fileName = "", subckt = "", folder = "" }) {
  this.libraryId = libraryId;
  this.fileName = fileName;
  this.subckt = subckt;
  this.folder = folder;
}
function defaultCPMInfo(params) {
  const { libraryId = '', fileName = '', cpmPairs = [], cpmConfig = {}, writeSelectFile = new WriteOrReadOnDieFileInfo({}), type = CPM } = params || {}
  this.libraryId = libraryId;
  this.fileName = fileName;
  this.cpmPairs = cpmPairs;
  this.cpmConfig = cpmConfig;
  this.writeSelectFile = writeSelectFile;
  this.type = type
}

class CascadeOnDieModelPanel extends Component {
  constructor(props) {
    super(props);
    this.dataStore = {
      [VALUE]: new defaultValue(),
      [FILE]: new defaultFileInfo({ pins: this.getPins(PER_PORT) }),
      [UNIFIED]: new defaultFileInfo({ pins: this.getPins(UNIFIED) }),
      [CPM]: new defaultCPMInfo(),
    };

    this.state = {
      info: {},
      apply: false,
      openCurrent: false,
      dieCurrentList: [],
      maxStopTime: this.props.maxStopTime
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    this.getValue()
  }

  componentDidUpdate = (prevProps) => {
    const { name, powerNet } = this.props;
    if (name !== prevProps.name || (name === ON_DIE && powerNet !== prevProps.powerNet)) {
      this.getValue()
    }
  }

  getValue = () => {
    const { pdnInfo, name, powerNet } = this.props;
    let modelInfo = pdnInfo[name];
    const findInfo = pdnInfo.powerNets.find(item => powerNet.includes(item.powerNet));
    if (findInfo && findInfo.onDie) {
      modelInfo = findInfo.onDie;
    }

    if (!modelInfo) return;

    let modelInfoType = FILE, info = JSON.parse(JSON.stringify(modelInfo));
    let perPortModelType
    if (modelInfo.type === VALUE) {
      modelInfoType = VALUE;
      const rValue = settingUnit(info.rdieValue, '1')
      const cValue = settingUnit(info.cdieValue, '1')
      info = {
        ...info,
        rdieValue: rValue.value,
        rdieUnit: rValue.unit,
        cdieValue: cValue.value,
        cdieUnit: cValue.unit
      }
    } else if (info && !info.pins) {
      info.pins = this.getPins()
    }

    if (modelInfo.type === CPM) {
      modelInfoType = CPM;
    }

    if (modelInfoType === FILE) {
      info.pins = this.getPins(pdnInfo.customType);
      const pairs = info.pairs;
      info.pairs = info.pins.map(pin => {
        const pair = pairs.find(item => item.pin === pin);
        return pair || { node: "", pin }
      })
    }

    const customType = pdnInfo.customType
    const modelType = customType === CPM ? CPM : CUSTOM
    const dieCurrentList = pdnInfo.dieCurrentList
    if (customType === PER_PORT) {
      perPortModelType = info.type === VALUE ? VALUE : FILE
    } else {
      perPortModelType = VALUE
    }
    this.changeDataStore(customType, perPortModelType, info)
    this.setState({
      info,
      modelType,
      customType: customType === CPM ? PER_PORT : customType,
      perPortModelType,
      dieCurrentList
    }, () => {
      if (this.modelChild) {
        this.modelChild.getValue();
      }
    })
  }

  changeDataStore = (customType, perPortModelType, info) => {
    if (customType === PER_PORT) {
      if (perPortModelType === VALUE) {
        this.dataStore[VALUE] = { ...info }
      } else {
        this.dataStore[FILE] = { ...info }
      }
    } else {
      this.dataStore[customType] = { ...info }
    }
  }

  getInfo = async (info) => {
    const { type } = info
    let _info = {}
    if (type === VALUE) {
      _info = { ...info }
    } else if (type === CPM) {
      let modelInfo = {}
      if (this.csmCpmChild) {
        modelInfo = await this.csmCpmChild.getModel(type);
      }
      const { readSelectFile, ...leftInfo } = { ...modelInfo, type }
      _info = { ...leftInfo }
    } else {
      let _model = {}
      if (this.modelChild) {
        _model = this.modelChild.getNetListModel();
      }
      delete _model.version;
      _info = { ...info, ..._model }
    }
    return _info
  }

  close = async () => {
    const { info, apply, modelType, customType, dieCurrentList } = this.state;
    const { name, powerNet, targetDie } = this.props;
    const { type } = info;
    let _info = {};

    if (type === VALUE) {
      _info = this.getValueInfo(info)
    } else if ([CSM, CPM].includes(type)) {
      let modelInfo = {}
      if (this.csmCpmChild) {
        modelInfo = await this.csmCpmChild.getModel(type);
      }
      const { readSelectFile, ...leftInfo } = { ...modelInfo, type }
      _info = { ...leftInfo }
    } else {
      let _model = {}
      if (this.modelChild) {
        _model = this.modelChild.getNetListModel();
      }
      delete _model.version;
      _info = { ...info, ..._model }
    }

    const _customType = modelType === CUSTOM ? customType : modelType

    this.props.closePanel(name, _info, powerNet, targetDie, apply, _customType, dieCurrentList)
  }

  getValueInfo = (info) => {
    const { cdieValue, cdieUnit, rdieValue, rdieUnit, type } = info;
    let value = { rdieValue, cdieValue, type };
    if (rdieValue || cdieValue) {
      if (rdieValue) {
        if (parseFloat(rdieValue) === 0) {
          value.rdieValue = "0";
        } else {
          value.rdieValue = rdieUnit !== '1' ? (rdieValue + rdieUnit) : rdieValue;
        }
      };
      if (cdieValue) {
        if (parseFloat(cdieValue) === 0) {
          value.cdieValue = "0";
        } else {
          value.cdieValue = cdieUnit !== "1" ? cdieValue + cdieUnit : cdieValue;
        }
      }
    };
    value.rdieValue = checkRLCValue(value.rdieValue);
    value.cdieValue = checkRLCValue(value.cdieValue);
    return value
  }

  getPins = (customType) => {
    const { pdnInfo, portIndex } = this.props;
    let pins = [];
    let ports = []
    if (customType === PER_PORT) {
      const port = pdnInfo.ports.find(item => item.port.toString() === portIndex)
      if (port) {
        ports.push(port.portName || port.port)
      }
    } else {
      ports = pdnInfo.ports.map(item => item.portName || item.port)
    }
    pins = [...ports, ...pdnInfo.gndNet]
    return pins;
  }

  onChangeModelType = async (e) => {
    e.stopPropagation();
    const { modelType, customType, perPortModelType, info } = this.state
    const _info = await this.getInfo(info)
    this.changeDataStore(modelType === CPM ? CPM : customType, perPortModelType, _info)
    const value = e.target.value;

    let newInfo = {}
    if (value === CPM) {
      newInfo = this.dataStore[CPM];
    } else if (customType === PER_PORT) {
      newInfo = this.dataStore[perPortModelType === VALUE ? VALUE : FILE];
    } else if (customType === UNIFIED) {
      newInfo = this.dataStore[UNIFIED];
    }
    this.setState({
      modelType: value,
      info: newInfo,
    })
  }

  onChangePerPortType = async (e) => {
    e && e.stopPropagation();
    const { customType, perPortModelType, info } = this.state
    const _info = await this.getInfo(info)
    this.changeDataStore(customType, perPortModelType, _info)
    const value = e.target.value;
    let newInfo = this.dataStore[value === VALUE ? VALUE : FILE]
    this.setState({
      perPortModelType: value,
      info: newInfo,
    })
  }

  inputChange = (e, key) => {
    let value = e.target.value;
    let _info = this.state.info;
    _info[key] = value
    this.setState({
      info: _info
    })
  }

  valueBlur = (e, key) => {
    let value = e.target.value;
    if (value) {
      const error = numberCheck(value);
      if (error) {
        e.target.focus();
      }
    }

    let _info = this.state.info;
    _info[key] = value

    this.setState({
      info: _info
    })
  }

  changeUnit = (value, key, valueKey) => {
    let _info = this.state.info;

    // The value changes during unit conversion
    const prevUnit = _info[key] === "1" ? "s" : _info[key], unit = value === "1" ? "s" : value;
    const scale = getScale(scaleKeys[unit], scaleKeys[prevUnit], 1e3);
    const _value = NP.strip(NP.times(_info[valueKey], scale));

    _info[key] = value;
    _info[valueKey] = _value;

    this.setState({
      info: _info
    })
  }

  changeType = (value) => {
    let _info = this.state.info;
    _info.type = value;
    const newInfo = new defaultFileInfo({ pins: _info.pins })
    this.setState({
      info: {
        ...newInfo,
        type: value
      }
    }, () => {
      // reset model data
      if (this.modelChild) {
        this.modelChild.resetModel();
      }
    })
  }

  cDieRender = () => {
    const { info } = this.state;
    const { cdieValue } = info;
    return <div className='ssn-pdn-content-value-input-item'>
      <span className='ssn-pdn-content-value-input-item-title'>Cdie</span>
      <Input
        className='ssn-pdn-content-value-input-item-input aurora-input'
        value={cdieValue}
        onChange={(e) => this.inputChange(e, 'cdieValue')}
        size="small"
        addonAfter={this.inputSelectAfter('cdieUnit', cDieOptionList, 'cdieValue')}
        onBlur={(e) => this.valueBlur(e, 'cdieValue')}
      />
    </div>
  }

  rDieRender = () => {
    const { info } = this.state;
    const { rdieValue } = info;
    return <div className='ssn-pdn-content-value-input-item'>
      <span className='ssn-pdn-content-value-input-item-title'>Rdie</span>
      <Input
        className='ssn-pdn-content-value-input-item-input aurora-input'
        value={rdieValue}
        onChange={(e) => this.inputChange(e, 'rdieValue')}
        size="small"
        addonAfter={this.inputSelectAfter('rdieUnit', rDieOptionList, 'rdieValue')}
        onBlur={(e) => this.valueBlur(e, 'rdieValue')}
      />
    </div>
  }

  valueContent = () => {
    const { product } = this.props
    return <div className={`ssn-value-box-content ${`${product}-ssn-value-box-content`}`}>
      {this.rDieRender()}
      {this.cDieRender()}
    </div>
  }

  inputSelectAfter = (key, optionList, valueKey) => {
    const { info } = this.state;
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      value={info[key]}
      onChange={(e) => this.changeUnit(e, key, valueKey)}
      style={{ width: 65 }}
    >
      {optionList.map(item => <Option key={item.num}>{item.unit}</Option>)}
    </Select>
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  onCsmRef = (ref) => {
    this.csmCpmChild = ref;
  }

  fileContent = () => {
    const { info, customType } = this.state;
    const { type, pins } = info;
    const { onDieTouchstoneList = [], pdnTouchstoneList = [], name, onDieSpiceList = [], pdnSpiceList = [], product } = this.props;
    return <div className='ssn-file-box-content'>
      <div className='ssn-pdn-content-file-select-item'>
        <span className='ssn-pdn-content-file-select-item-title'>Model Type</span>
        <Select
          value={type}
          onChange={(e) => this.changeType(e)}
          className="aurora-select"
          popupClassName='aurora-select-dropdown'
        >
          {fileOptionList.map(item => <Option key={item.key}>{item.title || item.name}</Option>)}
        </Select>
      </div>
      <NetListModel
        onRef={this.onRef}
        pins={pins}
        touchstoneList={name === ON_DIE ? onDieTouchstoneList : pdnTouchstoneList}
        spiceList={name === ON_DIE ? onDieSpiceList : pdnSpiceList}
        product={product}
        modelType={type || TOUCHSTONE}
        model={{ ...info }}
        getFileContent={this.props.getPkgSpiceModelList}
        type={"ssn_pdn"}
        popupMatchSelectWidth={true}
        showCurrent={customType === UNIFIED}
        openCurrentPanel={this.openCurrentPanel}
      >
      </NetListModel>
    </div>
  }

  changeApply = () => {
    this.setState({
      apply: !this.state.apply
    })
  }

  applyRender = () => {
    const { apply } = this.state;
    return <div className="model-apply">
      <Checkbox
        checked={apply}
        onChange={this.changeApply}
      />
      <span>
        Apply model to all Ports
      </span>
    </div>
  }

  getCPMCSMContent = (type) => {
    const { info } = this.state;
    return this.props.getCPMCSMContent(type, info, this.onCsmRef)
  }

  onChangeCustomType = async (e) => {
    e && e.stopPropagation();
    const { customType, perPortModelType, info } = this.state
    const _info = await this.getInfo(info)
    this.changeDataStore(customType, perPortModelType, _info)
    const value = e.target.value;
    let newInfo = {}
    if (value === PER_PORT) {
      newInfo = this.dataStore[perPortModelType === VALUE ? VALUE : FILE];
    } else if (value === UNIFIED) {
      newInfo = this.dataStore[UNIFIED];
    }
    this.setState({
      customType: value,
      info: newInfo,
    })
  }

  getCustomContent = () => {
    const { customType } = this.state
    const { multiTargetDie } = this.props
    return <Fragment>
      <div className='ssn-die-per-port'>
        <span className='ssn-die-per-port-type'>Custom Type</span>
        <Radio.Group onChange={(e) => this.onChangeCustomType(e)} value={customType} className='ssn-die-per-port-radio-group'>
          <Radio value={PER_PORT} className='ssn-die-radio-value'>
            <Tooltip
              title='Set model for each port. When the type is changed from others to Per-port, the model for other ports will be reset.'
              overlayClassName="aurora-tooltip"
            >
              Per-port
            </Tooltip>
          </Radio>
          <Radio value={UNIFIED} className='ssn-die-radio-value' disabled={multiTargetDie}>
            <Tooltip
              title='Set a unified model for all ports'
              overlayClassName="aurora-tooltip"
            >
              Unified
            </Tooltip>
          </Radio>
        </Radio.Group>
      </div>
      {
        customType === PER_PORT ? this.getPerPortContent() : this.getUnifiedContent()
      }
    </Fragment>
  }

  getPerPortContent = () => {
    const { perPortModelType } = this.state
    return <Fragment>
      <div className='ssn-die-per-port'>
        <span className='ssn-die-per-port-type'>Type</span>
        <Radio.Group onChange={(e) => this.onChangePerPortType(e)} value={perPortModelType} className='ssn-die-per-port-radio-group'>
          <Radio value={VALUE} className='ssn-die-radio-value'>Input Value</Radio>
          <Radio value={FILE} className='ssn-die-radio-value'>Select Model File</Radio>
        </Radio.Group>
      </div>
      {this.getPerPortModelContent()}
    </Fragment>
  }

  getPerPortModelContent = () => {
    const { perPortModelType } = this.state
    if (perPortModelType === VALUE) {
      return this.valueContent()
    } else if (perPortModelType === FILE) {
      return this.fileContent()
    }
  }

  getUnifiedContent = () => {
    return this.fileContent()
  }

  openCurrentPanel = (e, index, title) => {
    e && e.stopPropagation();
    const { dieCurrentList } = this.state
    const dieCurrent = dieCurrentList.length > index ? dieCurrentList[index] : {}
    this.setState({
      openCurrent: true,
      dieCurrent,
      currentIndex: index,
      currentTitle: title
    })
  }

  changeCurrentPanel = (e, open) => {
    e && e.stopPropagation();
    this.setState({
      openCurrent: open
    })
  }

  updateCurrent = (dieCurrent, apply) => {
    const { currentIndex, dieCurrentList } = this.state
    let _dieCurrentList = [...dieCurrentList]
    if (apply) {
      _dieCurrentList = _dieCurrentList.map(() => dieCurrent)
    } else {
      _dieCurrentList[currentIndex] = dieCurrent
      const stopTime = dieCurrent.stopTime
      _dieCurrentList.forEach(item => {
        if (item && Number(item.stopTime) < stopTime) {
          item.stopTime = stopTime
        }
      })
    }
    this.setState({
      dieCurrentList: _dieCurrentList
    }, () => {
      this.updateMaxStopTime()
    })
  }

  updateMaxStopTime = () => {
    const { dieCurrentList, maxStopTime } = this.state
    let _maxStopTime = maxStopTime
    dieCurrentList.forEach(item => {
      if (item && Number(item.stopTime) > _maxStopTime) {
        _maxStopTime = Number(item.stopTime)
      }
    })
    this.setState({
      maxStopTime: _maxStopTime
    })
  }

  render() {
    const { powerNet, packageIsPreLayout, multiTargetDie } = this.props;
    const { modelType, customType, openCurrent, dieCurrent, currentTitle, maxStopTime } = this.state;
    const content = (
      <Panel
        className='ssn-centric-file-select-panel'
        position='panel-center-left'
        title={`${powerNet} On Die Model`}
        onCancel={this.close}
        zIndex={2000}
        width={modelType === CPM ? 880 : 600}
        minWidth={300}
        minHeight={200}
        maxHeight={1000}
        draggable
        footer={modelType === CUSTOM && customType === PER_PORT ? this.applyRender() : null}
      >
        <div className={`ssn-centric-file-select-panel-content`}>
          <Radio.Group onChange={(e) => this.onChangeModelType(e)} value={modelType} className={`ssn-die-radio-group  ${!packageIsPreLayout ? "cascade-ssn-die-radio-group" : ""}`}>
            <Radio value={CUSTOM} className='ssn-die-radio-value'>Custom Model</Radio>
            <Radio value={CPM} className='ssn-die-radio-value' disabled={multiTargetDie}>CPM</Radio>
          </Radio.Group>
          <Divider className='ssn-die-divider' />
          {modelType === CPM ? this.getCPMCSMContent(modelType) : this.getCustomContent()}
        </div>
        {openCurrent && <DieCurrentPanel
          title={`${currentTitle} Current`}
          dieCurrent={dieCurrent}
          updateDieCurrent={this.updateCurrent}
          closeCurrentPanel={this.changeCurrentPanel}
          applyVisible={true}
          maxStopTime={maxStopTime}
        />}
      </Panel >)
    return createPortal(content, this.dialogRoot)
  }
}

export default CascadeOnDieModelPanel;