import { SUCCESS } from "../../../constants/returnCode";
import { getAndesV2PreLayoutInfoById, updateAndesV2ePreLayout } from '../../api/Andes_v2/preLayout';

/** 
* Get pre layout content
* 2021.03.17
* @return Promise
*/
function getPreLayoutInfoById(id) {
  return new Promise((resolve, reject) => {
    getAndesV2PreLayoutInfoById(id).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.log(error);
      resolve(null);
    })
  })
}

/** 
* update pre layout content
* @param {object} data {id, name, version }
* 2021.03.17
* @return Promise
*/
function updatePreLayout(data) {
  return new Promise((resolve, reject) => {
    updateAndesV2ePreLayout(data).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.log(error);
      resolve(null);
    })
  })
}

export {
  getPreLayoutInfoById,
  updatePreLayout
}