import { getProjectsByTypeName, createProject, generatePDNReport, getPDNReportStatus, getPDNReportConfig, postPDNReportConfig, deleteProjectsByIDs, UploadDebugFile, getTrashList, projectRename, getPDNReport } from '../api/v2/projectCtrl';
import checkError from '../api/checkError';
import { SUCCESS } from '../../constants/returnCode';
import { deleteDesignsPromise } from '../api/Design/design';
import LayoutData from '../data/LayoutData';
import { debugDownloadMsg } from '../api/v2/verificationCtrl';
import apiProcess from '../api/utility';
import http from '../api/http';
/**
 * Get project list by type name
 *
 * @export
 * @returns Promise
 */
export function getProjectList() {
  return new Promise((resolve, reject) => {
    getProjectsByTypeName('PDN').then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve([]);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve([]);
    });
  });
};

/**
 * Create PDN project
 *
 * @export
 * @param {String} name Project name
 * @returns Promise
 */
export function createProjectPromise(name) {
  return new Promise((resolve, reject) => {
    createProject({ name, typeName: 'PDN' }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
};

/**
 * Delete project promise
 *
 * @export
 * @param {String} ids Project ID list
 * @returns Promise
 */
export function deleteProjectPromise(ids) {
  return new Promise((resolve, reject) => {
    deleteProjectsByIDs(ids).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
}

/**
 * Upload debug file
 *
 * @export
 * @param {String} verificationId  verificationId 
 * @returns Promise
 */
export function UploadDebug(file, verificationId, status, UploadProgress) {
  return new Promise((resolve, reject) => {
    UploadDebugFile(file, verificationId, status, UploadProgress).then(res => {
      if (res.data && res.data.code === 0) {
        resolve({ status: 'success', msg: res.data.msg });
      } else {
        resolve({ status: 'fail' });
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve({ status: 'fail' });
    });
  });
}

export function deleteDesignInProject(id) {
  return new Promise((resolve, reject) => {
    deleteDesignsPromise([id], 'true').then(res => {
      LayoutData.cleanLayoutInfo(id);
      resolve(true);
    }, error => {
      checkError(error);
      resolve(true);
    })
  })
}

export function getPDNTrashList(id) {
  return new Promise((resolve, reject) => {
    getTrashList('PDN').then(res => {
      if (res.data.data) {
        const filterTrash = res.data.data.filter(item => item.typeName === 'PDN');
        resolve(filterTrash);
      } else {
        resolve([]);
      }
    }, error => {
      checkError(error);
      resolve([]);
    })
  })
}

/**
 * pdn  project rename
 * 2020.05.15
 * @param projectId
 * @param projectName new name
 * @export
 * @returns XMLHttpRequest

 */
export function changeProjectName(projectId, projectName) {
  return new Promise((resolve, reject) => {
    projectRename({ projectId, projectName }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null)
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    });
  });
};

export function debugDownMsg(verificationId, type) {
  return new Promise((resolve, reject) => {
    debugDownloadMsg(verificationId, type).then(res => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    })
  })
}

export function getPDNReportConfigpromise(projectId) {
  return apiProcess(getPDNReportConfig, projectId)
}

export function getPDNReportStatusPromise(projectId) {
  return apiProcess(getPDNReportStatus, projectId)
}

export function generatePDNReportNew(projectId, param) {
  return apiProcess(generatePDNReport, { projectId, param })
}

export function getPDNReportData( projectId, format, mime ) {
  return new Promise((resolve, reject) => {
    if (!projectId) {
      resolve(null);
      return;
    }
    getPDNReport(projectId, format).then(res => {
      resolve(`data:${mime};base64,` + btoa(
        new Uint8Array(res.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      ))
    }, error => {
      resolve(null);
      checkError(error, false);
    })
  })
};

export function postPDNReportConfigData(param){
  return apiProcess(postPDNReportConfig, param)
}