import React, { PureComponent, Fragment, createRef } from 'react';
import { Select } from 'antd';
import ModelDialog from '@/components/SelectModel/ModelDialog';
import designConstructor from '@/services/helper/designConstructor';
import channelConstructor from '../../../../services/Andes_v2/channel/channelConstructor';
import { PACKAGE } from '../../../../constants/designType';
import './index.css';

const { Option } = Select;
class ChannelSelect extends PureComponent {
  constructor(props) {
    super(props);
    const { info } = this.props;
    this.state = {
      design: { designId: info.designId, designName: info.designName },
      channel: { channelId: info.channelId, channelName: info.channelName },
      designList: [],
      channelList: []
    }

    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('end-to-end-channel-setup-dialog');
  }

  componentDidMount() {
    this.setState({
      offset: this.inputRef.current.getBoundingClientRect()
    })
    document.addEventListener('mousedown', this.handleClickOutside, true);
    this.getSelectList();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    if (this.inputRef !== null
      && this.inputRef.current !== target
      && !this.dialogRoot.contains(target)
      && target.classList[0] !== 'ant-select-item-option-content'
      && target.classList[0] !== 'ant-select-item-option'
    ) {

      this.closeSelect();
    }
  }

  getSelectList = () => {
    const { projectId, info, pcbConnections, endToEndChannel } = this.props;
    const existDeisgnIds = pcbConnections.map(item => item.designId).filter(item => !!item && item !== info.designId);
    const designList = designConstructor.getDesignValues(projectId).filter(item => !existDeisgnIds.includes(item.id) && item.type !== PACKAGE);
    //const _designs = getDisplayDesignList(designList);

    let channelList = [];
    if (info.designId) {
      channelList = channelConstructor.getChannelValues(info.designId).filter(item => !endToEndChannel.type || endToEndChannel.type === item.type);
    }
    this.setState({
      designList,
      channelList
    })
  }

  selectChange = (valueObj, type) => {
    const { key = "", label = "" } = valueObj || {};
    const { endToEndChannel } = this.props;
    let channels = this.state.channelList;

    if (type === "design") {
      channels = key ? channelConstructor.getChannelValues(key).filter(item => !endToEndChannel.type || endToEndChannel.type === item.type) : [];
      this.setState({
        channel: { channelId: "", channelName: "" }
      });
    }

    this.setState({
      [type]: { [`${type}Id`]: key, [`${type}Name`]: label },
      channelList: channels,
    })
  }

  saveSelectChannel = () => {
    const { record, dataIndex } = this.props;
    const { design, channel } = this.state;
    let _record = { ...record };
    //if modified, save
    if (_record[dataIndex].designId !== design.designId || _record[dataIndex].channelId !== channel.channelId) {
      _record[dataIndex] = {
        ..._record[dataIndex],
        ...design,
        ...channel
      }
      this.props.saveChannel(_record, dataIndex);
    }

    this.props.save();
  }

  closeSelect = (e) => {
    e && e.stopPropagation();
    this.saveSelectChannel();
  }

  renderDialog() {
    const { offset, design, channel, designList, channelList } = this.state;
    if (offset === undefined) return null;
    return (
      <ModelDialog offset={this.inputRef} root={this.dialogRoot} type='entToEndModel'>
        <div className='end-to-end-channel-select-content'>
          <div>
            {this.getSelectComponent({
              value: { key: design.designId, label: design.designName },
              list: designList,
              type: "design",
              placeholder: "Design"
            })}
            {this.getSelectComponent({
              value: { key: channel.channelId, label: channel.channelName },
              list: channelList,
              type: "channel",
              placeholder: "Channel"
            })}
          </div>
        </div>
      </ModelDialog>
    )
  }

  getSelectComponent = ({ value, list, type, placeholder }) => {
    return <Fragment>
      <span className="end-to-end-channel-label">{placeholder}</span>
      <Select
        value={value}
        placeholder={placeholder}
        labelInValue
        allowClear
        showSearch
        onChange={option => option && this.selectChange({ label: option.label, key: option.value }, type)}
        popupMatchSelectWidth={false}
        className="aurora-select end-to-end-channel-select"
        getPopupContainer={() => document.getElementById('andes-v2-content-main')}
      >
        {list.map((item) =>
         /*  type === "design" ?
            <Select.OptGroup label={item.name} key={item.name}>
              {item.children.map(it =>
                <Option value={it.id} key={it.id}>{it.name}</Option>
              )}
            </Select.OptGroup>
            : */ <Option
            key={item.id}
            value={item.id}
            title={item.name}
          >{item.name}</Option>)}
      </Select>
    </Fragment>
  }

  render() {
    const { inputRef } = this;
    const { text } = this.props;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default ChannelSelect;