import {
  getVerificationJson,
  getSSNResultChannelContentPromise,
  getSSNResultChannelExistListPromise,
} from "./helper/data";

class VerificationInfo {
  constructor() {
    this.resultVerifications = new Map(); // key - channelId, value - resultVerifications
    this.resultExists = new Map();
  }

  setInfo = (id, verificationInfo) => {
    this.resultVerifications.set(id, verificationInfo);
  };

  getCurrentVerificationInfo = ({
    channelId,
    verificationId,
    verificationSubId,
  }) => {
    return new Promise((resolve, reject) => {
      const info = this.resultVerifications.get(verificationId);
      if (info) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getVerificationJson(channelId, verificationSubId).then(
          (res) => {
            if (res) {
              this.setInfo(verificationId, { ...res });
              resolve(res);
            } else {
              resolve(null);
            }
          },
          (error) => {
            console.error(error);
            resolve(null);
          }
        );
      }
    });
  };

  getSSNResultVerificationInfo = (verificationId) => {
    return new Promise((resolve, reject) => {
      const info = this.resultVerifications.get(verificationId);
      if (info) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getSSNResultChannelContentPromise(verificationId).then(
          (res) => {
            if (res) {
              this.setInfo(verificationId, { ...res });
              resolve(res);
            } else {
              resolve(null);
            }
          },
          (error) => {
            console.error(error);
            resolve(null);
          }
        );
      }
    });
  };

  getVerificationInfo = (id) => {
    return this.resultVerifications.get(id);
  };

  setExistsInfo = (channelId, data) => {
    this.resultExists.set(channelId, data);
  };

  getResultExists = (channelId, isNew) => {
    return new Promise((resolve, reject) => {
      const info = this.resultExists.get(channelId);
      if (info && !isNew) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getSSNResultChannelExistListPromise(channelId).then((res) => {
          if (res) {
            this.setExistsInfo(channelId, [...res]);
            resolve(res);
          } else {
            resolve(null);
          }
        }, (error) => {
          console.error(error);
          resolve(null);
        });
      }
    });
  };

  cleanVerificationInfo = () => {
    this.resultVerifications = new Map();
    this.resultExists = new Map();
  };
}

const SSNResultVerificationInfo = new VerificationInfo();
export default SSNResultVerificationInfo;
