import React, { Component } from 'react';
import { connect } from 'react-redux';
import PackageModel from '@/components/PackageModel_v1';
import { ANDES_V2 } from "@/constants/pageType";
import { PackageConnModelInfo } from '@/services/Andes_v2/channel';
import libraryConstructor from "@/services/Andes_v2/library/libraryConstructor";
import { PCB_TOUCHSTONE } from "@/constants/libraryConstants";
import { getTouchstoneParse } from "@/services/Andes_v2/library";
import { savePreLayoutPCBModel, editPinOrNetName } from '../store/preLayout/action';
import { getPreLayoutCompPinList, getPreLayoutSignals } from '../../../services/Andes_v2/preLayout';
import { PACKAGE as DESIGN_PACKAGE } from "../../../constants/designType";
import "./index.css";

class PcbModelContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameStatus: false,
      touchstoneList: libraryConstructor.getLibraryValues(PCB_TOUCHSTONE)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { designID, updatePcbModelStatus, libraryStatus } = this.props;
    if (designID !== prevProps.designID || updatePcbModelStatus !== prevProps.updatePcbModelStatus) {
      if (this.multiModelRef) {
        this.multiModelRef.updateContent();
        setTimeout(() => {
          this.multiModelRef.savePackageModelInfo();
        }, 200);
      }
    }

    if (prevProps.libraryStatus !== libraryStatus) {
      this.setState({
        touchstoneList: libraryConstructor.getLibraryValues(PCB_TOUCHSTONE)
      })
    }
  }

  _savePackageModel = (model, record, modelsObj) => {
    this.props._savePreLayoutPCBModel(model, this.props.designID)
  }

  onRef = (ref) => {
    this.multiModelRef = ref;
  }

  editPinOrNetName = (preData, newName, nameType, designID) => {
    this.setState({
      nameStatus: !this.state.nameStatus
    })
    const { _editPinOrNetName } = this.props
    _editPinOrNetName(preData, newName, nameType, designID)
  }

  render() {
    const { designID, components, signal_groups, preLayoutModel = {}, type, designType } = this.props;
    const { touchstoneList } = this.state;
    const signals = getPreLayoutSignals(signal_groups, type)
    const _pinList = getPreLayoutCompPinList(components, signals);
    return <div className='space-10'>
      <div className="aurora-setup-border" >
        <span className='out-title-color font-bold'>{designType === DESIGN_PACKAGE ? "Package" : "PCB"} Model</span>
        <div className="setup-package-model-content">
          <PackageModel
            onRef={this.onRef}
            record={{ pkg: { ...preLayoutModel } }}
            product={ANDES_V2}
            componentName={components[0].name}
            pinList={_pinList}
            modelType="PreLayout"
            displayType="model"
            signals={signals}
            savePackageModel={this._savePackageModel}
            spiceList={[]}
            touchstoneList={touchstoneList}
            PackageModelInfo={PackageConnModelInfo}
            getTouchstoneParse={getTouchstoneParse}
            newModelsObj={[]}
            isPageDisplay={true}
            nameTitleContent={this.nameTitleContent}
            editPinOrNetName={this.editPinOrNetName}
            nameStatus={this.state.nameStatus}
            designID={designID}
            type={type}
            designType={designType}
          // notUseMultipleFiles={true}
          />
        </div>
      </div>
    </div>
  }
}

const mapDispatch = (dispatch) => ({
  _savePreLayoutPCBModel(model, designID) {
    dispatch(savePreLayoutPCBModel(model, designID))
  },
  _editPinOrNetName(preData, newName, nameType, designID) {
    dispatch(editPinOrNetName(preData, newName, nameType, designID))
  }
})

export default connect(null, mapDispatch)(PcbModelContent);