import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import PdnSider from './PdnSider';
import PDNContent from './Content';
import Line from '../../components/Line/index';
import { changeLeft, changeTop } from "../panelStore/action";
import { closeTabFooter, openTabFooter, cleanTabMonitorStatus } from '../tabMonitor/action';
/* import { getLayoutMode } from '@/services/api/cookies'; */
import { changeLayout } from './store/project/action';
import { showPdnResult, changeViewList, updatePCBLayout } from './store/project/action';
import { clearCCCStatus } from '../CCC/store/action';
import { isChrome } from '../../services/helper/browser';
import DelConfirm from '@/components/DelConfirm';
import { FASTPI } from '@/constants/pageType';
import { setPageType } from '../Login/action';
import projectDesigns from '@/services/helper/projectDesigns';
import SideFooter from '../../components/SideFooter';
import { PDN, PCB, RESULT, PACKAGE, PCB_PRE_LAYOUT } from '../../constants/treeConstants';
import { SINGLE_PAGE_LAYOUT } from '../../constants/layoutConstants';
import { changeProduct } from '../LayoutExplorer/LayoutStore/action';
import '@/publicCss/waveform.css';
import '@/publicCss/style.css';
import '@/publicCss/tree.css';
import '@/publicCss/libraryStyle.css';
import '@/publicCss/waveform.css';
import './pdn.css';

const { Content } = Layout;

class PdnPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 280,
      linePosition: {
        position: "absolute",
        left: 280
      },
      openMenu: true,
      layoutVisible: false,
      tooltipVisible: false,
      viewVisible: false,
      viewToolTip: false,
      chrome: true,
      browserVisible: false
    }
  }

  changeWidth(width) {
    /*  width = width < 211 ? 56 : width;
     width = width > 1000 ? 1000 : width;
     if (width === 56) {
       this.setState({
         openMenu: false
       });
     } */
    this.setState({ width })
    let position = {
      position: "absolute",
      left: width,
      minWidth: 56,
      maxWidth: 1000
    }
    this.setState({
      linePosition: position
    })
    this.props.changeLeft(width);
  }

  componentWillMount() {
    this.props.setPageType(FASTPI);
    this.props.clearCCCStatus();
  }

  componentDidMount = () => {
    this.props.changeLeft(280);
    this.props.changeTop(54);
    this.props.cleanTabMonitorStatus();
    this.props.changeProduct(FASTPI);
    const chrome = isChrome();
    this.setState({
      chrome,
      browserVisible: chrome ? false : true
    })
  }

  changeSize = (width) => {
    let newWidth = width;
    const prevWidth = this.state.width;
    if (prevWidth === 56 && width > 56) {
      this.setState({
        openMenu: true
      });
      newWidth = 211;
      this.changeWidth(newWidth);
    } else {
      width = width < 211 ? 56 : width;
      width = width > 1000 ? 1000 : width;
      if (width === 56) {
        this.setState({
          openMenu: false
        });
      }
      this.changeWidth(width);
    }

  }

  openMenu = (e) => {
    e.stopPropagation();
    let position = {
      position: "absolute",
      left: 211,
      minWidth: 56,
      maxWidth: 1000,
    }
    this.setState({
      openMenu: true,
      width: 211,
      linePosition: position
    });
  }

  componentDidUpdate = (prevProps) => {
    const { pageType } = this.props;
    if (pageType !== prevProps.pageType) {
      this.props.cleanTabMonitorStatus();
    }
  }


  changeTabFooter = () => {
    const { openTabFooter, closeTabFooter, tabVisible } = this.props;
    tabVisible ? closeTabFooter() : openTabFooter();
  }

  layoutClick = (layoutType, singleType) => {
    this.props.changeLayout(layoutType);
    if (singleType) {
      this.viewClick(singleType);
      return;
    }
    const { viewList, currentProjectId, currentProjectPDNs, } = this.props;

    if (!currentProjectId) {
      return;
    }

    if (layoutType !== SINGLE_PAGE_LAYOUT && viewList.length === 0) {

    } else if (viewList.length === 1) {
      if (viewList[0] === PDN || viewList[0] === RESULT) {
        // Open PCB
        let newList = [...viewList];
        if (currentProjectPDNs.length === 0) {
          newList = [];
        }
        if (projectDesigns.getAvailableDesignsLength(currentProjectId) > 0) {
          newList = [...newList, PCB];
          this.props.changeViewList([...newList], PCB);
        } else {
          this.props.changeViewList([...newList]);
        }
      } else {
        // Open PDN
        let newList = [...viewList]
        if (projectDesigns.getAvailableDesignsLength(currentProjectId) === 0) {
          newList = [];
        }
        if (currentProjectPDNs.length > 0) {
          newList = [...newList, PDN];
          this.props.changeViewList([...newList], PDN);
        } else {
          this.props.changeViewList([...newList]);
        }
      }
    }
  }

  viewMouseOver = (e) => {
    this.setState({
      viewToolTip: true
    })
  }

  viewMouseOut = (e) => {
    this.setState({
      viewToolTip: false
    })
  }

  ViewVisibleChange = (visible) => {
    this.setState({
      viewVisible: visible
    });
  }

  viewClick = (type) => {
    const { viewList, currentProjectId } = this.props;
    if (!currentProjectId) {
      return;
    }

    function getViewType(view) {
      if ([PCB, PACKAGE, PCB_PRE_LAYOUT].includes(view)) {
        return PCB;
      } else {
        return 'setup';
      }
    }

    if (viewList.length === 1 && getViewType(viewList[0]) === type) {
      return;
    }

    if (type === PCB) {
      let newView = [PCB];
      this.props.changeViewList([...newView], PCB);
    } else {
      if (viewList.includes(PDN)) {
        this.props.changeViewList([PDN], PDN);
      } else if (viewList.includes(RESULT)) {
        this.props.changeViewList([RESULT], RESULT);
      } else if (!viewList.includes(PDN) && !viewList.includes(RESULT)) {
        this.props.changeViewList([PDN], PDN);
      }
    }
  }

  okConfirm = () => {
    this.setState({
      browserVisible: false
    });
    window.location.href = "./";
  }

  sideFooterActions = () => {
    const { openMenu } = this.state;
    const { viewList, tabVisible, layout, currentProjectId, pcbLayout } = this.props;
    return {
      openMenu,
      viewList,
      tabVisible,
      layout,
      pcbLayout,
      currentProjectId,
      showPCBSplit: false,
      pcbLayoutChange: this.pcbLayoutChange,
      layoutClick: this.layoutClick,
      changeTabFooter: this.changeTabFooter
    }
  }

  pcbLayoutChange = (type) => {
    const { pcbLayout } = this.props;
    this.props._updatePCBLayout(type, pcbLayout);
  }

  render = () => {
    const { openMenu, chrome, browserVisible } = this.state;
    const errorMsg = 'Non Chrome browser is not supported in the simulation platform.';
    return (
      <Fragment>
        {chrome ? <Layout className="my-pdn-layout">
          <div className='pdn-sider'
            style={{ width: this.state.width }}
          >
            <div className='pdn-sider-menu' style={{ display: !openMenu ? 'block' : 'none' }}>
              <MenuOutlined className='pdn-sider-button' onClick={(e) => this.openMenu(e)} />
            </div>
            <div style={{ display: openMenu ? 'block' : 'none' }} className='pdn-sider-menu-tree'>
              <PdnSider width={this.state.width} />
            </div>
            <SideFooter
              actions={this.sideFooterActions()}
            />
          </div>
          <Line
            //Position is used to locate the Line
            position={this.state.linePosition}
            changeWidth={(width) => this.changeWidth(width)}
            width={this.state.width}
            resize={this.changeSize}
          />
          <Content className='aurora-content' style={{ left: this.state.width, overflow: '' }}>
            <PDNContent leftWidth={this.state.width} />
          </Content>
        </Layout> : null}
        {browserVisible ? <DelConfirm
          data={null}
          removeCancel={true}
          deleteConfirm={this.okConfirm}
          message={errorMsg}
        /> : null}
      </Fragment>
    );
  }
}


const mapState = (state) => {
  const { PanelStatus, LoginReducer: { pageType } } = state;
  const { project: { layout, viewList, PDNID, verificationId, currentProjectId, currentProjectPDNs, pcbLayout } } = state.PDNReducer;
  const { defaultTop, defaultLeft } = PanelStatus;
  const { tabVisible } = state.TabMonitorReducer;
  return {
    defaultTop,
    defaultLeft,
    tabVisible,
    layout,
    viewList,
    PDNID,
    verificationId,
    currentProjectId,
    pageType,
    currentProjectPDNs,
    pcbLayout
  }
}

const mapDispatch = (dispatch) => ({
  setPageType(type) {
    dispatch(setPageType(type));
  },
  changeLeft(width) {
    dispatch(changeLeft(width));
  },
  changeTop(top) {
    dispatch(changeTop(top));
  },
  openTabFooter() {
    dispatch(openTabFooter())
  },
  closeTabFooter() {
    dispatch(closeTabFooter())
  },
  changeLayout(layout) {
    dispatch(changeLayout(layout))
  },
  showPdnResult(view, info) {
    dispatch(showPdnResult(view, info));
  },
  changeViewList(viewList, viewType) {
    dispatch(changeViewList(viewList, viewType))
  },
  cleanTabMonitorStatus() {
    dispatch(cleanTabMonitorStatus());
  },
  clearCCCStatus() {
    dispatch(clearCCCStatus());
  },
  _updatePCBLayout(pcbLayout, prePcbLayout) {
    dispatch(updatePCBLayout(pcbLayout, prePcbLayout))
  },
  changeProduct(product) {
    dispatch(changeProduct(product))
  }
})

export default connect(mapState, mapDispatch)(PdnPage);
