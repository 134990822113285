import canvas from '@/services/LayoutCanvas';

class StackupZonesCanvas {
  constructor({ designID }) {
    this.designID = designID;
  }

  updateShowZones = ({ selectedZones, deletedZones, zones = [] }) => {
    const layout = canvas.getLayout(this.designID);
    if (!layout) {
      return;
    }
    //clear deleted zones boundaries
    for (let zoneName of deletedZones || []) {
      layout.clearZoneBoundaries(zoneName);
    }
    //show new selected zones
    for (let zoneName of selectedZones || []) {
      const zone = zones.find(item => item.name === zoneName);
      if (!zone) {
        console.error(`Can not get zone ${zoneName}!`);
        continue;
      }
      layout.showZoneBoundaries(zoneName, zone);
    }
  }

  updateShowBends = ({ bends, selectedBends }) => {
    const layout = canvas.getLayout(this.designID);
    if (!layout) {
      return;
    }
    layout.showBends(bends, selectedBends);
  }
}

export default StackupZonesCanvas;