import React, { Component, Fragment } from 'react';
import {/*  Button, */ Spin, } from 'antd';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import SettingFooter from '../../../result/components/measurement/settingFooter';
import MeasurementTable from '../../../result/components/measurement/measurementTable';
import {
  getMeasurementConfig,
  initMeasurementData,
  getMeasureModelType,
  updateMeasureByTableData,
  updateMeasurementConfig,
  updateMeasureByApplyAll
} from '../../../../../services/Sierra/helper/measurementHelper';
import { saveVerificationContentToServer, updateMeasureConfigStatus } from '../../../store/sierra/action';
import { getAllExpMeasurementConfig } from '../../../store/sweep/action';
import "./index.css";

class MeasurementPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      setting: {
        "voutlUnit": "%",
        "vouthUnit": "%",
        "vinlUnit": "%",
        "vinhUnit": "%"
      },
      settingApply: false,
      maxHeight: 600,
      maxWidth: 1100,
      modelType: "",
      config: {},
      loading: true,
      applyAllList: [],
      applying: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    this.initData()
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 600) - 80,
      maxWidth: getPanelMaxWidth(offset, 1100)
    })
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId, isUpdateMeasure, isUpdateMeasureType } = this.props;
    if (prevProps.verificationId !== verificationId) {
      this.closeModal()
    }

    if (isUpdateMeasure && isUpdateMeasure !== prevProps.isUpdateMeasure) {
      //init data
      this.getData();
      this.props._updateMeasureConfigStatus("isUpdateMeasure", false);
    }

    if (isUpdateMeasureType && isUpdateMeasureType !== prevProps.isUpdateMeasureType) {
      //get new data when changing model type
      this.getNewData()
      this.props._updateMeasureConfigStatus("isUpdateMeasureType", false)
    }
  }

  initData = () => {
    const { isSweep } = this.props;
    if (isSweep) {
      this.getData();
    } else {
      this.props._saveVerificationContentToServer({ isMeasureConfig: true, key: "isUpdateMeasure" });
    }
  }

  getData = async () => {
    const { verificationId, Interfaces, currentProjectVerifications, isSweep, rootVerificationId, currExp, base } = this.props;
    try {
      let data = await getMeasurementConfig({ verificationId });
      //init
      if (!data || !Object.keys(data).length) {
        const findV = isSweep ?
          currentProjectVerifications.find(item => item.verificationId === rootVerificationId) || {}
          : currentProjectVerifications.find(item => item.verificationId === verificationId) || {};
        const modelType = getMeasureModelType(findV.tag);
        data = await getMeasurementConfig({ verificationId, modelType });
      }
      const tableData = initMeasurementData({ config: data, Interfaces, isSweep, currExp, base });

      this.setState({
        tableData,
        modelType: data.modelType,
        config: data,
        loading: false
      })
    } catch (error) {
      console.error(error)
      this.setState({
        tableData: [],
        config: {},
        modelType: "",
        loading: false
      })
    }
  }

  closeModal = async () => {
    const { isSweep } = this.props;
    await this.saveConfig();
    if (isSweep) {
      this.props.save();
      this.props._getAllExpMeasurementConfig();
    } else {
      this.props.closePanel();
    }
  }

  saveConfig = async () => {
    const { verificationId } = this.props;
    const { config, tableData, modelType } = this.state;
    try {
      const _config = updateMeasureByTableData(config, tableData);
      _config.modelType = modelType;
      await updateMeasurementConfig({ verificationId, config: _config });
    } catch (error) {
      console.error(error)
    }
  }

  showSetting = (key) => {
    this.setState({
      settingApply: key
    })
  }

  changeSettingInput = (e, key) => {
    this.setState({
      setting: { ...this.state.setting, [key]: e.target.value }
    })
  }

  changeSettingUnit = (unit, key) => {
    this.setState({
      setting: { ...this.state.setting, [`${key}Unit`]: unit, [key]: "" }
    })
  }

  updateSettingApply = (settingApply) => {
    this.setState({
      settingApply
    })
  }

  changeApplyAll = (values) => {
    this.setState({
      applyAllList: values
    })
  }

  applyAllUpdate = () => {
    this.setState({
      applying: "Applying settings..."
    }, () => {
      this.applyToExp()
    })
  }

  applyToExp = async () => {
    const { applyAllList, setting } = this.state;
    const { base, experiments, currExp } = this.props;
    const list = [...(experiments || [])];
    let applyList = []
    if (applyAllList.includes("all")) {
      applyList = list.filter(item => currExp.id !== item.id);
    } else {
      applyList = list.filter(item => applyAllList.includes(item.id));
    }

    let index = 1;
    await this.saveConfig();
    for (let expItem of applyList) {
      try {
        this.setState({
          applying: `Applying setting to ${expItem.name} (${index}/${applyList.length})...`
        })

        let data = await getMeasurementConfig({ verificationId: expItem.verificationId });
        if (data && data.pcbs && data.pcbs.length) {
          data = updateMeasureByApplyAll({ config: data, setting, currExp: expItem, base });
          await updateMeasurementConfig({ verificationId: expItem.verificationId, config: data });
        }
      } catch (error) {
        console.error(error);
      }
    }
    this.setState({
      applying: `Apply settings completed.`
    })
    this.props._getAllExpMeasurementConfig();
    setTimeout(() => {
      this.setState({
        applying: false
      })
    }, 700)
  }

  footRender = () => {
    const { /* settingApply, */ setting, tableData, applyAllList, applying } = this.state;
    const { isSweep, experiments, currExp } = this.props;
    return <Fragment>
      <SettingFooter
        setting={setting}
        isSweep={isSweep}
        tableData={[{ measurementInfo: tableData }]}
        applyAllList={applyAllList}
        applying={applying}
        experiments={isSweep ? [...experiments].filter(item => item.id !== currExp.id) : []}
        updateSettingApply={this.updateSettingApply}
        updateTableData={(data) => this.updateTableData(data, true)}
        changeSettingUnit={this.changeSettingUnit}
        changeSettingInput={this.changeSettingInput}
        changeApplyAll={this.changeApplyAll}
        applyAllUpdate={this.applyAllUpdate}
      />
    </Fragment>
  }

  updateTableData = (tableData, isFooter) => {
    if (isFooter) {
      this.setState({
        tableData: tableData && tableData[0] && tableData[0].measurementInfo ? JSON.parse(JSON.stringify(tableData[0].measurementInfo)) : this.state.tableData
      })
    } else {
      this.setState({
        tableData: JSON.parse(JSON.stringify(tableData))
      })
    }

  }

  changeModelType = (e) => {
    const { isSweep } = this.props;

    this.setState({
      modelType: e.target.value,
      loading: true
    }, () => {
      if (isSweep) {
        this.getNewData()
      } else {
        //save json
        this.props._saveVerificationContentToServer({ isMeasureConfig: true, key: "isUpdateMeasureType" });
      }
    })
  }

  getNewData = async () => {
    try {
      const { modelType } = this.state;
      const { verificationId, Interfaces, isSweep, currExp, base } = this.props;
      let data = await getMeasurementConfig({ verificationId, modelType });
      const tableData = initMeasurementData({ config: data, Interfaces, isSweep, currExp, base });

      this.setState({
        tableData,
        modelType: data.modelType,
        config: data,
        loading: false
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  renderDialog() {
    const { tableData, maxHeight, maxWidth, modelType, loading, applying } = this.state;
    const { Interfaces, isSweep, isMultiPCB, currExp, base } = this.props;
    const isBase = isSweep && currExp && base && currExp.name === base.name;
    const content = (
      <Panel
        className='sierra-setup-measure-panel sierra-panel'
        title={`${isSweep && currExp ? `${currExp.name} - ` : ""}Measurement Conditions`}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 1100 })}
        maxHeight={maxHeight}
        position='panel-center-top'
        draggable
        minHeight={100}
        minWidth={800}
        maxWidth={maxWidth}
        defaultTop={80}
        footerHeight={isBase ? 0 : (isSweep ? 84 : 50)}
        footer={isBase ? null : this.footRender()}
      >
        <div className='waveFrom-post-process-content waveFrom-post-process-measure-content'>
          <Spin spinning={loading || applying} tip={applying ? applying : 'Loading data...'}>
            <MeasurementTable
              updateTableData={this.updateTableData}
              tableData={tableData}
              modelType={modelType}
              type="setup"
              Interfaces={Interfaces}
              isSweep={isSweep}
              isBase={isBase}
              isMultiPCB={isMultiPCB}
              updateModelType={this.changeModelType}
            />
          </Spin>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { isSweep } = this.props;
    if (isSweep) {
      return (
        <Fragment>
          <div className='editable-cell-value-wrap'>
            {this.props.text}
          </div>
          {this.renderDialog()}
        </Fragment>
      )
    }
    return this.renderDialog();
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra: { isUpdateMeasure, isUpdateMeasureType } } } = state;
  return {
    isUpdateMeasure,
    isUpdateMeasureType
  }
}


const mapDispatch = (dispatch) => ({
  _saveVerificationContentToServer({ isMeasureConfig, key }) {
    dispatch(saveVerificationContentToServer({ isMeasureConfig, key }))
  },
  _updateMeasureConfigStatus(key, status) {
    dispatch(updateMeasureConfigStatus(key, status))
  },
  _getAllExpMeasurementConfig() {
    dispatch(getAllExpMeasurementConfig())
  }
})

export default connect(mapState, mapDispatch)(MeasurementPanel);