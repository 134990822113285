import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { InfoCircleOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import { Progress, Col, Tooltip, Row } from 'antd';
import {
  cancelWorkflow
} from '../../store/simulation/action';
import { getWaitingMsg } from '@/services/workflow/workflowHelper';
import {
  SPICE_CARD_TEMPLATE,
  IO_SIM
} from '../../../../constants/treeConstants';
import { getTextWidth } from '../../../../services/helper/getTextWidth';

class SimulationMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topWidth: 666,
      lockScroll: true
    };
    this.lockRef = createRef();
  }

  scrollToBottom = () => {
    if (this.lockRef) {
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidMount() {
    this.screenChange();
    const { tabVisible } = this.props;
    if (tabVisible && this.scrollRef) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { topWidth, lockScroll } = this.state;
    const { tabVisible, log, startMsg } = this.props;
    const { log: prevLog, startMsg: prevStartMsg } = prevProps;
    if (tabVisible && this.scrollRef && !lockScroll) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
    if ((log !== prevLog) || (startMsg && startMsg !== prevStartMsg)) {
      this.hasLockScroll();
    }
  }

  cancelProgress = (e) => {
    this.props._cancelWorkflow(this.props.verificationId);
  }

  hasLockScroll = () => {
    if (this.lockRef) {
      const { scrollHeight, scrollTop, clientHeight } = this.lockRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5) { this.setState({ lockScroll: false }); }
      else { this.setState({ lockScroll: true }); }
    }
  }

  render() {
    const { startMsg, endMsg, log, monitor, progress, projectName,
      waitingIndex, waitingTime, verificationName
    } = this.props;
    const { topWidth, lockScroll } = this.state;
    const { scrollHeight, clientHeight } = this.lockRef;
    const waitingMsg = getWaitingMsg(waitingIndex);

    const titleWidth = topWidth - 96;//96px is close and cancel icon width
    const textWidth = getTextWidth(projectName + '    ' + verificationName, 14);
    const progressWaitMinWidth = waitingIndex >= 0 ? 95 : (progress > -1 ? 34 : 0);//95 is waiting msg min width, 34 is progress min width
    const progressWidth = titleWidth < (textWidth + progressWaitMinWidth) ? titleWidth - 44 : titleWidth - textWidth; //44 is title tooltip icon width

    return (
      <Fragment>
        <div className='aurora-simulation-title himalayas-simulation-title'
          id="aurora-simulation-title-id">
          <Row className='aurora-simulation-holygrail' span={24} style={{ width: topWidth - 36 }}>
            {/* title */}
            {this.getTopTitle({
              titleWidth,
              textWidth,
              progressWaitMinWidth,
              projectName,
              verificationName
            })}
            {/* progress / waiting index info */}
            {this.getProgressAndWaiting({
              progress,
              waitingIndex,
              progressWidth,
              waitingTime
            })}
          </Row>
          {/*cancel workflow icon */}
          {this.getCancelIcon(progress, waitingIndex)}
        </div>
        <div
          className="my-aurora-monitor aurora-simulation-monitor"
          onScroll={() => this.hasLockScroll()}
          ref={c => { this.lockRef = c; }}>
          <pre className="my-aurora-monitor-pre">
            {startMsg}
            {waitingIndex >= 0 ? <span className="debug-mes-span">{waitingMsg}</span> : null}
            {(monitor && monitor.length)
              ? monitor.map((item, index) => { return <div key={index}>{item.log}</div> })
              : (log ? log : null)}
            {endMsg}
          </pre>
          {(progress > -1) ? <div ref={(el) => { this.scrollRef = el; }}></div> : null}
        </div>
        {lockScroll && scrollHeight !== clientHeight ?
          <Tooltip title="Back to bottom" overlayClassName='icon-tooltip'>
            <VerticalLeftOutlined className="aurora-monitor-backbottom" onClick={this.scrollToBottom} />
          </Tooltip>
          : null}
      </Fragment>
    );
  }

  getTopTitle = ({
    titleWidth,
    textWidth,
    progressWaitMinWidth,
    projectName,
    verificationName
  }) => {
    return (
      <div>
        {titleWidth < (textWidth + progressWaitMinWidth) ?
          <Tooltip
            overlayClassName='icon-tooltip'
            placement="topLeft"
            title={
              this.getTitleText({
                projectName,
                verificationName
              })
            }
          >
            <div className='span-msg-icon-div'>
              <InfoCircleOutlined className='span-msg-icon' />
            </div>
          </Tooltip>
          :
          this.getTitleText({
            projectName,
            verificationName
          })
        }
      </div>
    );

  }

  getTitleText = ({
    projectName,
    verificationName
  }) => {
    return <div className="himalayas-monitor-title-box">
      {projectName && <div className='aurora-simulation-span'>
        <span className='aurora-span-msg'>
          {verificationName ? projectName + '    ' + verificationName : projectName}
        </span>
      </div>}
    </div>
  }

  getProgressAndWaiting = ({
    progress,
    waitingIndex,
    progressWidth,
    waitingTime
  }) => {
    return progress > -1 && (!waitingIndex || waitingIndex < 0) ?
      <div className='aurora-simulation-middle'
        style={{ width: progressWidth }}
      >
        <Col className='aurora-simulation-progress' span={24}>
          <Progress
            type={progressWidth < 130 ? "circle" : "line"}
            size={{ height: 14 }}
            strokeColor={'#1890ff'}
            percent={progress}
            className="simulation-progress-bar"
          />
        </Col>
      </div>
      :
      ((waitingIndex >= 0) &&
        <div className='aurora-simulation-middle'
          style={{ width: progressWidth }}
        >
          {progressWidth > 210 &&
            <span className='simulation-waiting-title'> Simulation waiting: {waitingIndex}
              <span className='waiting-time-span'>{waitingTime}</span>
            </span>}
          {progressWidth <= 210 && progressWidth > 150
            && <span className='simulation-waiting-title'>Waiting: {waitingIndex}
              <span className='waiting-time-span'>{waitingTime}</span>
            </span>}
          {progressWidth <= 150
            && <span className='simulation-waiting-title'>Waiting: {waitingIndex}</span>}
        </div>)
  }

  getCancelIcon = (progress, waitingIndex) => {
    return progress > -1 || waitingIndex >= 0 ?
      <div className='aurora-simulation-btn'>
        <div className='aurora-cancel-button'>
          <Tooltip title='Cancel simulation' overlayClassName='icon-tooltip'>
            <span className='iconfont icon-cancel9' onClick={(e) => this.cancelProgress(e)}></span>
          </Tooltip>
        </div>
      </div>
      : null
  }
}

const mapState = (state) => {
  const {
    simulation,
    project: { viewList }
  } = state.HimalayasReducer;
  const { monitorScreenInfo: {
    currentProjectId,
    currentVerificationId,
    projectName,
    verificationName },
    tabVisible
  } = state.MonitorInfoReducer;

  const view = viewList.find(item => [SPICE_CARD_TEMPLATE, IO_SIM].includes(item));

  let endMsg = null, log = null, startMsg = null,
    progress = -1, monitor = [], waitingIndex = -1,
    waitingTime = null;

  if (currentVerificationId && simulation[currentVerificationId]) {
    endMsg = simulation[currentVerificationId].endMsg;
    log = simulation[currentVerificationId].log;
    startMsg = simulation[currentVerificationId].startMsg;
    progress = simulation[currentVerificationId].progress;
    monitor = simulation[currentVerificationId].monitor;
    waitingIndex = simulation[currentVerificationId].waitingIndex;
    waitingTime = simulation[currentVerificationId].waitingTime
  }

  return {
    projectId: currentProjectId,
    projectName,
    verificationId: currentVerificationId,
    verificationName,
    tabVisible,
    startMsg,
    endMsg,
    progress,
    monitor,
    log,
    waitingIndex,
    waitingTime,
    view
  }
}

const mapDispatch = (dispatch) => ({
  _cancelWorkflow(verificationId) {
    dispatch(cancelWorkflow(verificationId))
  }
})

export default connect(mapState, mapDispatch)(SimulationMonitor);
