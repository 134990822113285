import {
  CLOSE_MONITOR_TAB_FOOTER,
  OPEN_MONITOR_TAB_FOOTER,
  CHANGE_MONITOR_SCREEN_MENU,
  CLEAN_MONITOR_SCREEN_STATUS
} from './actionTypes';

const defaultState = {
  tabVisible: false,
  monitorScreenInfo: {
    menuType: 'simulation',
    tabSelectKeys: ['monitor'],
  },
  /*  monitorScreenInfo: {
      tabSelectKeys: ['monitor'],
     currentProjectId,
     projectName,
     currentChannelId,
     channelName,
     currentVerificationId,
     verificationName,
     menuType:'simulation', //'simulation'/upload
   } */

}

export const MonitorInfoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLOSE_MONITOR_TAB_FOOTER:
      return {
        ...state,
        tabVisible: false
      }
    case OPEN_MONITOR_TAB_FOOTER:
      return {
        ...state,
        tabVisible: true
      }
    case CHANGE_MONITOR_SCREEN_MENU:
      return {
        ...state,
        monitorScreenInfo: {
          ...state.monitorScreenInfo,
          ...action.info
        }
      }
    case CLEAN_MONITOR_SCREEN_STATUS:
      return {
        ...defaultState
      }
    default: return state;
  }
};