import { TreeSelect } from "antd";
import React, { Component } from "react";
import "./index.css";

/* const Option = Select.Option; */
class PCBSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render = () => {
    const { PCBList = [], pcbSelectionList, selectChange } = this.props;
    const treeData = [{
      value: "All",
      title: "All",
      children: pcbSelectionList.map(item => {
        return {
          value: item.id,
          title: item.name
        }
      })
    }]
    return <div className="create-content-input-item create-content-input-pcb">
      <label className='create-edit-input-label'>PCB</label>
      <TreeSelect
        treeData={treeData}
        value={PCBList}
        treeCheckable={true}
        treeDefaultExpandAll={true}
        allowClear
        onChange={(keys) => selectChange(keys, "PCBList")}
        placeholder="PCB"
        className='aurora-select create-content-select'
        popupClassName='aurora-select-dropdown'
      />
    </div>
  }
}

export default PCBSelection;