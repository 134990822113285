import CeLarc from "../geometry/CeLarc";
import CeLine from "../geometry/CeLine";
import CePolygon from "../geometry/CePolygon";
import CePolygonWithHole from "../geometry/CePolygonWithHole";
import Circle from "../helper/cutDesign/geometry/Circle";

class TraceWidth {
  constructor() {
    this.desginShape = new Map(); // {key: designId - value: Shape}
  }

  getShapeByNets({ designId, nets, layoutDB }) {
    const desginShape = this.desginShape.get(designId);
    // If the design does not have relevant net data
    if (!desginShape) {
      this.setShapeByNets({ designId, nets, layoutDB });
    }

    const widthList = new Map(); // {key: net - value: [] }
    for (const net of nets) {
      const widths = this.getWidthsByNet({ designId, net });
      // If it does not exist, the data in the designShape needs to be updated
      if (!widths) {
        const shape = desginShape.setShapeWidthByNets([net]);
        // console.log(shape);
        this.desginShape.set(designId, shape);
      }
      widthList.set(net, this.getWidthsByNet({ designId, net }));
    }
    return widthList;
  }

  setShapeByNets({ designId, nets, layoutDB }) {
    const shape = new Shape({ layoutDB });
    shape.setShapeWidthByNets(nets);
    this.desginShape.set(designId, shape);
  }

  getWidthsByNet({ designId, net }) {
    const desginShape = this.desginShape.get(designId);
    if (!desginShape) {
      return null;
    }
    return desginShape.getShapeWidthByNet(net) || null;
  }
}

class Shape {
  constructor({ layoutDB }) {
    this.layoutDB = layoutDB;
    this.netShapeList = new Map(); // {key: netName - value: [width]}
    this.tempShape = new Map();
  }

  getShapeWidthByNet(netName) {
    return this.netShapeList.get(netName);
  }

  // The width list of the new net is designed for the first time
  setShapeWidthByNets(nets) {
    for (const netName of nets) {
      const { mNetManager } = this.layoutDB;
      const net = mNetManager.GetNetFromName(netName);
      // console.log({ net, mNetManager });
      let mGeomList = [].concat(net.mGeomList);
      let widths = []
      mGeomList = mGeomList.reduce((prev, curr) => {
        if (curr.mRefGeom.mGeometry &&
          (curr.mRefGeom.mGeometry instanceof CePolygonWithHole ||
            curr.mRefGeom.mGeometry instanceof CePolygon)
        ) {
          prev.unshift(curr)
        } else {
          prev.push(curr)
        }
        return prev;
      }, []);
      for (let { mRefGeom: geom } of mGeomList) {
        // console.log(geom);
        // case1: mGeometry: null, mLocation ("Circle", "Rectangle")
        if (!geom.mLocation && geom.mGeometry) {
          if (geom.mGeometry instanceof CeLine) {
            const item = this.getShapeWidthByID(geom.mSymbolID);
            widths.push(item.mDiameter);
          } else if (geom.mGeometry instanceof CeLarc) {
            const item = this.getShapeWidthByID(geom.mSymbolID);
            widths.push(item.mDiameter);
          }
        } else {
          // TODO
          continue;
        }
      }
      this.netShapeList.set(netName, widths)
    }
    return this;
  }

  getShapeWidthByID(id) {
    let symbol = null;
    if (id !== -1) {
      symbol = this.getShapeSymbolById(id);
    }
    if (id !== -1 && !symbol) return null;
    // console.log(symbol);
    if (symbol instanceof Circle) {
      return symbol;
    }
  }

  getShapeSymbolById(id) {
    if (!this.tempShape.has(id)) {
      /*  const twoDistance = 2 * this.distance; */
      const { mSymbolMgr } = this.layoutDB;
      const symbol = mSymbolMgr.GetShapeGeomObj(id);
      const geomType = symbol.mGeometry.mGeomType;
      if (geomType === 'Circle') {
        const { mCenter, mDiameter } = symbol.mGeometry;
        this.tempShape.set(id, new Circle({
          mDiameter: mDiameter/*  + twoDistance */,
          mX: mCenter.mX,
          mY: mCenter.mY
        }))
      }
    }
    return this.tempShape.get(id) || [];
  }
}

const traceWidth = new TraceWidth();
export default traceWidth;