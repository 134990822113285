import http from '../http';

/**
 * Get multi-pcb channel last simulation finished time
 *
 * @export
 * @param {*} channelId
 * @returns
 */
export function getMultiPCBChannelFinishedTime(endToEndId) {
  return http.get(`/andes/end-to-end/channel/history/time`, { params: { endToEndId } })
}


/**
 *  Save current multi-pcb results to history
 *
 * @export
 * @param {*} { endToEndId, name }
 * @returns
 */
export function saveMultiPCBResultToHistory({ endToEndId, name }) {
  return http.post(`/andes/end-to-end/channel/history/save`, {
    endToEndId,
    name
  })
}

export function getMultiPCBHistoryList(endToEndId) {
  return http.get(`/andes/end-to-end/channel/history/list`, { params: { endToEndId } })
}

export function getMultiPCBHistoryCurveData({ historyId, resultDataParam, signal, pair, couplingType }) {
  let otherParam = '';
  if (signal && pair) {
    otherParam = `&signal=${signal}&pair=${pair}`
  }

  if (couplingType) {
    otherParam += `&type=${couplingType}`;
    return http.post(`/andes/end-to-end/channel/history/curve/data?historyId=${historyId}${otherParam}`, {})
  }
  return http.post(`/andes/end-to-end/channel/history/curve/data?historyId=${historyId}${otherParam}`, resultDataParam)
}

export function getMultiPCBHistorySetting(historyId) {
  return http.get(`/andes/end-to-end/channel/history/setting`, { params: { historyId } })
}

export function getMultiPCBHistoryResults({ historyId, type, pair, signal }) {
  return http.get(`/andes/end-to-end/channel/history/result`, { params: { historyId, type, pair, signal } })
}