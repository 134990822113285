import { MoreOutlined, EditOutlined, CloseOutlined, CopyOutlined, ExperimentOutlined, SettingOutlined, QuestionCircleOutlined, PlayCircleOutlined, DownloadOutlined, RightOutlined, LoadingOutlined, EyeOutlined, PlusSquareOutlined } from '@ant-design/icons';

function getIcon(icon) {
  switch (icon) {
    case 'more':
      return MoreOutlined;
    case 'edit':
      return EditOutlined;
    case 'close':
      return CloseOutlined;
    case 'copy':
      return CopyOutlined;
    case 'experiment':
      return ExperimentOutlined;
    case 'question-circle':
      return QuestionCircleOutlined;
    case "play-circle":
      return PlayCircleOutlined
    case "setting":
      return SettingOutlined
    case "download":
      return DownloadOutlined
    case "right":
      return RightOutlined
    case "eye":
      return EyeOutlined
    case "close":
      return CloseOutlined
    case 'setting':
      return SettingOutlined;
    case 'loading':
      return LoadingOutlined;
    case 'add':
      return PlusSquareOutlined;
    default:
      return SettingOutlined;
  }
}

export default getIcon