import CeObject from './CeObject';
import CeGeomFactory from '../geometry/CeGeomFactory';

var CeShapeObject = function () {
  CeObject.call(this);
  this.mGeometry = null;
};

// subclass extends superclass
CeShapeObject.prototype = Object.create(CeObject.prototype);
CeShapeObject.prototype.constructor = CeShapeObject;

CeShapeObject.prototype.SetData = function (name, id, geom) {
  this.SetName(name);
  this.SetID(id);
  this.mGeometry = geom;
}

CeShapeObject.prototype.GetGeometry = function () {
  return this.mGeometry;
}

CeShapeObject.prototype.WriteIntoIODataNode = function (parentBlk) {
  var idName = CeObject.prototype.GetDataStrings.call(this);
  parentBlk.AddDataItem("IdName", idName);
  parentBlk.AddBlockNode(this.mGeometry.WriteIntoIODataNode());
}

CeShapeObject.prototype.ReadFromIODataNode = function (idNameNode, geomNode) {
  var idName = idNameNode.GetItemValue();
  CeObject.prototype.UpdateByString.call(this, idName);
  this.mGeometry = CeGeomFactory.CreateGeometryFromType(geomNode.GetName());
  return this.mGeometry.ReadFromIODataNode(geomNode);
}

export default CeShapeObject;