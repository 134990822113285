import React, { Component } from 'react';
import Table from '@/components/EditableTable';
import {
  getSignalPinsData,
  getSignalTitleCheckBox,
  getSignalCheckBox,
  CHECK_BOX_ALL,
  getAdvancedInputListByserdesType
} from '../../../../services/Andes_v2/channel';
import { SettingOutlined } from '@ant-design/icons';
import CreateChannelAdvanced from '@/components/createChannelContent/createAdvanced';
import { PACKAGE } from '../../../../constants/designType';

const signalColumns = [/* {
  title: 'Group',
  dataIndex: 'group',
  key: 'group',
  width: "20%"
},  */
  {
    title: 'checkBox',
    dataIndex: 'check',
    key: 'check',
    width: 50
  },/*  {
    title: 'Signal',
    dataIndex: 'signal',
    key: 'signal',
    width: "30%"
  }, */ {
    title: 'Differential Nets',
    dataIndex: 'nets',
    key: 'nets'
  }
];
class SignalNetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedVisible: false,
      signalColumns
    }
  }

  componentDidMount() {
    this.columnsCell();
  }

  componentDidUpdate(prevProps) {
    const { serdesType } = this.props;
    if (serdesType !== prevProps.serdesType) {
      this.columnsCell();
    }
  }

  columnsCell = () => {
    const { signalColumns } = this.state;
    const { serdesType } = this.props;
    signalColumns[0].title = () => {
      const { selectedSignals, signals } = this.props;
      return getSignalTitleCheckBox({
        selectedSignals,
        signals,
        checkSignals: this.checkSignals
      })
    }

    signalColumns[0].render = (name, record) => {
      return getSignalCheckBox({ record, checkSignals: this.checkSignals, serdesType })
    };

    signalColumns[1].render = (name, record) => {
      return {
        children: <span>{record.nets && record.nets.length ? record.nets.join(", ") : <span className='aurora-placeHolder'>{record.netPlaceHolder}</span>}</span>,
        /* props: {
          tdClick: (e) => this.netSelect(record.nets)
        } */
      }
    };
    this.setState({ signalColumns })
  }

  delSignal = (e, record) => {
    e.stopPropagation();
    let _signals = this.props.signals;
    _signals = _signals.filter(item => item.name !== record.signal);
    this.props.updateSignals(_signals)
  }

  checkSignals = (e, key, type) => {
    const checked = e && e.target ? e.target.checked : false;
    let { selectedSignals, signals } = this.props;
    let _selectedSignals = [...selectedSignals];
    if (key === CHECK_BOX_ALL) {
      _selectedSignals = checked ? signals.map(item => item.name) : [];
    } else {
      if (checked) {
        _selectedSignals = [...new Set([..._selectedSignals, key])];
      } else {
        _selectedSignals = _selectedSignals.filter(item => item !== key);
      }
    }
    this.props.updateSelectedSignals(_selectedSignals);
  }

  openAdvanced = (e) => {
    this.setState({
      advancedVisible: !this.state.advancedVisible
    })
  }

  render = () => {
    const { signals, selectedSignals, serdesType, advancedConfig, designType, } = this.props;
    const { advancedVisible, signalColumns } = this.state;
    const dataList = getSignalPinsData([], signals, selectedSignals, serdesType);
    const data = dataList ? dataList.map((record, index) => ({ ...record, index })) : []
    return (
      <div className="create-channel-signals-table-content">
        <span>Signals</span>
        {designType === PACKAGE ? <SettingOutlined
          className="signal-add-icon create-channel-setting-icon"
          style={{
            color: advancedVisible ? "#0d87f7" : "#000000A6",
          }}
          title="Advanced Differential Nets Search Criteria"
          onClick={(e) => this.openAdvanced(e)} /> : null}
        {advancedVisible ?
          <CreateChannelAdvanced
            serdesType={serdesType}
            designType={designType}
            advancedConfig={advancedConfig}
            defaultActiveKey={"advanced"}
            updateAdvancedConfig={this.props.updateAdvancedConfig}
            advancedInputList={getAdvancedInputListByserdesType(serdesType)}
          /> : null}
        <Table
          ref={(ref) => this.table = ref}
          rowKey={(record) => record.index}
          columns={signalColumns}
          size="small"
          dataSource={data}
          /*  scroll={{ y: 300 }} */
          className='andes-v2-table create-channel-signals-table channel-signals-pins-table space-10'
        />

      </div>
    );
  }
}
export default SignalNetsTable;