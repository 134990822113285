import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import Table from '@/components/EditableTable'
import { generateNewCompareMap, autoMapCompare } from '../../../../../services/Cascade/Impedance';
import '../index.css';

const columns = [{
  title: 'origin',
  dataIndex: 'origin',
  width: '50%'
}, {
  title: 'bind',
  dataIndex: 'bind',
  width: '50%'
}]

class mapPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      maxWidth: 1200,
      maxHeight: 600,
      data: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 1200),
      maxHeight: getPanelMaxHeight(offset, 600)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getColumns();
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title) {
      this.getData()
    }
  }

  closeModal = () => {
    const { data } = this.state;
    const { verificationId } = this.props
    this.props.saveCompareMap(verificationId, data)
    this.props.closeModal();
  }

  getColumns = () => {
    columns[0].render = (text, record) => {
      const { type, powerPins } = record;
      return type === 'port' && text ? `Port ${text} [${powerPins.length > 3 ? powerPins.slice(0, 3).join(', ') : powerPins.join(', ')}]` : text
    }

    columns[1].render = (text, record) => {
      const { type, currentPins } = record;
      return type === 'port' && text ? `Port ${text} [${currentPins.length > 3 ? currentPins.slice(0, 3).join(', ') : currentPins.join(', ')}]` : text
    }

    columns[1].onCell = (record) => {
      const options = this.getOptions(record);
      return {
        record,
        edit: 'select',
        options,
        dataIndex: "bind",
        selectType: 'bind',
        dropdownMenuClassName: 'cascade-effect-select-dropdown-menu',
        getPopupContainer: document.getElementById('root'),
        handleSave: this.bindSelected,
        showSearch: true,
        allowClear: true,
        handleClear: this.bindClear
      }
    }
  }

  getData = () => {
    const { info, compareEffects, currentInfo } = this.props;
    const { map = [], verificationId, historys } = info;
    let _map = [];
    for (let id of [verificationId, ...historys.map(i => i.historyId)]) {
      const find = map.find(i => i.id === id);
      if (find) {
        _map.push({ ...find });
      } else {
        const row = generateNewCompareMap({ compareEffects, id });
        if (row) {
          const _row = autoMapCompare(row, currentInfo, 'verification');
          _map.push(_row)
        }
      }
    }
    this.setState({
      data: _map
    })
  }

  getOptions = (record) => {
    const { key, type, parent } = record;
    const { currentInfo } = this.props;
    const { data } = this.state;
    switch (type) {
      case 'verification':
        return currentInfo.map(i => i.name)
      case 'net':
        const _parent = data.find(i => i.id === parent);
        if (!_parent || !_parent.bind) {
          return [];
        }
        const current = currentInfo.find(i => i.name === _parent.bind);
        const usedNet = _parent.children.map(i => i.bind).filter(i => !!i);
        if (!current) {
          return [];
        }
        return current.effects ? current.effects.map(effect =>
          ({ key: effect.powerNet, value: effect.powerNet, disabled: usedNet.includes(effect.powerNet) ? true : false })
        ).sort((a, b) => !a.disabled && b.disabled ? -1 : 1)
          : [];
      case 'port':
        const [portId, powerNet, id] = key.split('-');
        const verification = data.find(i => i.id === id);
        if (!verification || !verification.bind) {
          return [];
        }
        const vCurrent = currentInfo.find(i => i.name === verification.bind)
        const net = verification.children.find(i => i.id === powerNet);
        if (!net || !net.bind) {
          return [];
        }
        const nCurrent = vCurrent.effects.find(effect => effect.powerNet === net.bind);
        if (!nCurrent) {
          return [];
        }
        const usedPort = net.children.map(i => i.bind).filter(i => !!i);
        return nCurrent.portInformation ? nCurrent.portInformation.map(portInfo => {
          const { port, powerPins } = portInfo
          const title = `Port ${port} [${powerPins.length > 3 ? powerPins.slice(0, 3).join(', ') : powerPins.join(', ')}]`;
          return { key: port, value: port, title, disabled: usedPort.includes(port) ? true : false }
        }).sort((a, b) => !a.disabled && b.disabled ? -1 : 1) : []
      default: return [];
    }
  }

  bindSelected = (record) => {
    const { key, type, bind, parent } = record;
    const { currentInfo } = this.props;
    const { data } = this.state;
    let _data = [...data];
    switch (type) {
      case 'verification':
        const findIndex = _data.findIndex(d => d.key === key);
        _data[findIndex].bind = bind;
        _data[findIndex] = autoMapCompare(_data[findIndex], currentInfo, 'net');
        break;
      case 'net':
        const pIndex = data.findIndex(i => i.id === parent);
        if (pIndex < 0 || !_data[pIndex].bind) {
          break;
        }
        const nIndex = _data[pIndex].children.findIndex(i => i.key === key);
        if (nIndex > -1) {
          _data[pIndex].children[nIndex].bind = bind;
          const curNet = _data[pIndex].children[nIndex].origin;
          _data[pIndex] = autoMapCompare(_data[pIndex], currentInfo, 'port', { curNet });
        }
        break;
      case 'port':
        const [portId, powerNet, id] = key.split('-');
        const vIndex = data.findIndex(i => i.id === id);
        const vCurrent = currentInfo.find(i => i.name === _data[vIndex].bind)
        if (vIndex < 0 || !_data[vIndex].bind || !vCurrent) {
          break;
        }
        const netIndex = _data[vIndex].children.findIndex(i => i.id === powerNet);
        const nCurrent = vCurrent.effects.find(effect => effect.powerNet === _data[vIndex].children[netIndex].bind);
        if (netIndex < 0 || !_data[vIndex].children[netIndex].bind || !nCurrent) {
          break;
        }
        const portIndex = _data[vIndex].children[netIndex].children.findIndex(i => i.key === key);
        const pCurrent = nCurrent.portInformation ? nCurrent.portInformation.find(p => p.port === bind) : null
        if (portIndex > -1 && pCurrent) {
          _data[vIndex].children[netIndex].children[portIndex].bind = bind;
          _data[vIndex].children[netIndex].children[portIndex].currentPins = pCurrent.powerPins || [];
        }
        break;
      default: break;
    }
    this.setState({
      data: _data
    })
  }

  bindClear = (record) => {
    const { key, type, parent } = record;
    const { data } = this.state;
    let _data = [...data];
    switch (type) {
      case 'verification':
        const findIndex = _data.findIndex(d => d.key === key);
        _data[findIndex].bind = '';
        _data[findIndex].children.forEach(n => {
          n.bind = '';
          n.children.forEach(c => {
            c.bind = '';
          })
        })
        break;
      case 'net':
        const pIndex = data.findIndex(i => i.id === parent);
        if (pIndex < 0 || !_data[pIndex].bind) {
          break;
        }
        const nIndex = _data[pIndex].children.findIndex(i => i.key === key);
        if (nIndex > -1) {
          _data[pIndex].children[nIndex].bind = '';
          _data[pIndex].children[nIndex].children.forEach(c => {
            c.bind = ''
          })
        }
        break;
      case 'port':
        const [portId, powerNet, id] = key.split('-');
        const vIndex = data.findIndex(i => i.id === id);
        if (vIndex < 0 || !_data[vIndex].bind) {
          break;
        }
        const netIndex = _data[vIndex].children.findIndex(i => i.id === powerNet);
        if (netIndex < 0 || !_data[vIndex].children[netIndex].bind) {
          break;
        }
        const portIndex = _data[vIndex].children[netIndex].children.findIndex(i => i.key === key);
        if (portIndex > -1) {
          _data[vIndex].children[netIndex].children[portIndex].bind = "";
          _data[vIndex].children[netIndex].children[portIndex].currentPins = "";
        }
        break;
      default: break;
    }
    this.setState({
      data: _data
    })
  }

  render() {
    const { maxWidth, maxHeight, data } = this.state;
    const setting = { defaultWidth: 800 }
    const { title } = this.props;
    const content = (
      <Panel
        className='cascade-effect-map-panel'
        title={<div className='cascade-effect-map-name'>{title}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        minHeight="100"
      >
        <div className="cascade-setup-border cascade-effect-map-content">
          <Table
            dataSource={data}
            key={data}
            columns={columns}
            size="small"
            rowKey={(record) => { return record.key }}
            showHeader={false}
            defaultExpandAllRows={true}
          // className="cascade-result-effect-table-main"
          />
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default mapPanel;