import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import Parameters from '@/services/Sierra/Sparameter/parameter';
import { getFileInSierraPinToPinProject } from '@/services/api/sierra';
import { ResultData } from '@/services/Sierra';
import { getResultList } from '@/services/helper/sparameter/dataProcess';
import { strDelimited } from '@/services/helper/split';
import { downloadFile } from '@/services/helper/downloadHelper'
import Sparameter from '../components/Sparameter';
import {
  sparameterShowCurves,
  getVCCompsPCB,
  parseEndToEndResult,
  setEndToEndParameters
} from '@/services/Sierra/helper/sparameterDataHelper'
import makeCancelable from '@/services/api/makeCancelable';
import { getEndToEndResultList } from '../../../../services/Sierra/results';
import { cancelMove, getMouseMoveYAxisValue } from '../../../../services/Result/Public/sparameter/dataHelper';
import { getEndToEndMouseMoveYAxisValue, cancelEndToEndMove } from '../../../../services/Sierra/helper/sparameterDataHelper';

const defaultState = {
  files: [],
  portSelect: {},
  Interfaces: {},
  resultList: {},
  hasVcPCBs: [],
  hideIL: false
}
class Channel extends Component {

  constructor(props) {
    super(props);
    this.uploadSP = createRef();
    this.uploadSnpRef = createRef();
    this.state = {
      ...defaultState
    }
    this.plot2d = null;
  }

  changeMouse = (frequency) => {
    try {
      let { files, resultList, endToEndList } = this.state;
      const displayMode = this.childComp.getDisplayMode();

      const axis = this.childComp.getAxis();
      const yScale = axis.yScale;
      const selectArr = this.childComp.getCurrentSelects();
      let _resultList = { ...resultList }, _endToEndList = [...(endToEndList || [])];
      const setting = this.childComp.getSetting();
      for (let pcb in _resultList) {
        _resultList[pcb] = getMouseMoveYAxisValue({
          resultsObj: _resultList[pcb],
          displayMode,
          selectArr,
          files,
          x: frequency,
          setting,
          yScale
        })
      }
      _endToEndList = getEndToEndMouseMoveYAxisValue({
        displayMode,
        selectArr,
        setting,
        endToEndList: _endToEndList,
        files,
        x: frequency,
        yScale
      })

      this.setState({
        resultList: _resultList,
        endToEndList: _endToEndList
      });
    } catch (error) {
      console.error(error)
    }
  }

  cancelMove = () => {
    try {
      let { resultList, endToEndList } = this.state;
      const selectArr = this.childComp.getCurrentSelects();
      const displayMode = this.childComp.getDisplayMode();
      let _resultList = { ...resultList };
      for (let pcb in _resultList) {
        _resultList[pcb] = cancelMove({
          resultsObj: _resultList[pcb],
          displayMode,
          selectArr,
        })
      }
      this.setState({
        resultList: _resultList,
        endToEndList: cancelEndToEndMove({ endToEndList, displayMode })
      });
    } catch (error) {
      console.error(error)
    }
  }

  componentDidUpdate(prevProps) {
    const { verificationId, verificationSubId, projectId } = this.props;
    if (verificationId !== prevProps.verificationId && verificationSubId) {
      this.setState({
        ...defaultState,
        portSelect: {},
        resultList: {},
      });
      this.removeAllCurves();
      Parameters.cleanParameters();
      ResultData.getVerificationJsonPromise(verificationSubId, verificationId, projectId).then(res => {
        if (res) {
          let Interfaces = ResultData.getInterfaces();
          const _Interfaces = { ...this.state.Interfaces };
          _Interfaces[verificationId] = Interfaces;
          const { hasVcPCBs,/*  hideIL */ } = getVCCompsPCB(Interfaces);
          this.setState({
            Interfaces: _Interfaces,
            hasVcPCBs,
            /*  hideIL */
          }, () => {
            this.changeResult();
          })
        }
      })

      if (this.selectAllCancelAble) {
        this.selectAllCancelAble.cancel();
      }
      if (this.childComp) {
        this.childComp.updateDisplayModeModel()
      }
    }
  }

  componentWillUnmount = () => {
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
  }

  componentDidMount() {
    const { verificationSubId, projectId, verificationId } = this.props;
    this.setState({
      ...defaultState,
      portSelect: {},
      resultList: {},
    });
    if (verificationSubId && verificationId) {
      ResultData.getVerificationJsonPromise(verificationSubId, verificationId, projectId).then(res => {
        if (res) {
          let Interfaces = ResultData.getInterfaces();
          const _Interfaces = { ...this.state.Interfaces };
          _Interfaces[verificationId] = Interfaces
          const { hasVcPCBs,/*  hideIL */ } = getVCCompsPCB(Interfaces);
          this.setState({
            Interfaces: _Interfaces,
            hasVcPCBs,
            /*  hideIL */
          }, () => {
            this.changeResult();
          })
        }
      })
    }
  }

  changeResult = () => {
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.childComp.changeAxis,
    }
    Parameters.cleanParameters();
    this.childComp.setLoading(true)
    this.setState({
      portSelect: {},
      resultList: {}
    }, () => {
      this.mounted = true;
      const { verificationId, verificationSubId, projectId, channelList } = this.props;
      const { parameter, value } = this.childComp.getSetting();
      this.plot2d = Parameters.createPlot(this.childComp.svgRef.current, { param: parameter, value }, events);
      Parameters.getParametersInfo({ verificationId, verificationSubId, projectId, channelList }).then(params => {
        const parametersArr = Array.from(params.values());
        if (parametersArr.length > 0) {
          let files = Array.from(params.values()).map(item => ({ ...item, show: true }));
          this.getAllNpi(files).then(res => {
            this.childComp.setLoading(false)
            this.setState({
              files: [...res.map(item => ({ ...item, show: true, rowName: item.pcb, rowId: item.pcb }))],
            }, () => {
              this.defaultResultList();
              this.getEndToEndResult();
            })
          }, error => {
            this.childComp.setLoading(false)
            this.setState({
              files: files,
            }, () => {
              this.defaultResultList()
            })
          })
        } else {
          this.childComp.setLoading(false)
          this.setState({
            files: [],
          }, () => {
            this.defaultResultList()
          });
          return message.error('No result!');
        }
      });
      this.childComp.screenChange();
    })
  }

  getAllNpi = (files) => {
    const { verificationId } = this.props;
    const { Interfaces } = this.state;
    const promiseList = files.map((item, index) => {
      const content = Interfaces[verificationId].find(d => item.id.includes(d.pcbId));
      return item.getNpiFile(index, verificationId, content);
    })
    return Promise.all(promiseList);
  }

  getSingleNpi = (file) => {
    const { files } = this.state;
    const { verificationId } = this.props;
    let length = files.length >= 0 ? files.length : 0;
    const promiseList = file.map((item, index) => {
      return item.getNpiFile(length, verificationId);
    })
    return Promise.all(promiseList);
  }

  getEndToEndResult = async () => {
    const { files } = this.state;
    const { verificationId, verificationSubId, projectId } = this.props;
    const res = await getEndToEndResultList(verificationId);
    const endToEndList = parseEndToEndResult(res);
    const { endToEndList: endToEndParametersArr, files: newFiles } = setEndToEndParameters({
      endToEndList,
      Parameters,
      verificationId,
      verificationSubId,
      projectId,
      files
    });
    this.setState({
      endToEndList: endToEndParametersArr,
      files: newFiles
    })
  }

  fileDownload = (e, file) => {
    e.stopPropagation();
    let filename;
    const { verificationId } = this.props;
    getFileInSierraPinToPinProject(file.projectId, `${verificationId}/result/${file.name}`).then(res => {
      let content = ''
      if (res) {
        content = res.data;
      }
      const Suffix = file.name.match(/\.s[0-9]{1,}p/i)[0];
      filename = file.pcb + '_channel' + Suffix;
      downloadFile(content, filename)

    }, error => {
      console.error(error);
      let content = ''
      const Suffix = file.name.match(/\.s[0-9]{1,}p/i)[0];
      filename = file.pcb + '_channel' + Suffix;
      downloadFile(content, filename)
    });
  }

  removeAllCurves = () => {
    const { portSelect } = this.state;
    const selects = Object.values(portSelect).reduce((a, b) => {
      return a.concat(b);
    }, []);
    let ports = [...selects];
    const removeCurves = ports.map(curve => {
      const [fileId, row, col] = strDelimited(curve, "::");
      return { row, col, id: fileId };
    });
    if (removeCurves.length) {
      Parameters.removeCurves(removeCurves);
    };
  }

  getColorByIndex = (fileId, row, col) => {
    const { files } = this.state;
    const _file = files.find(file => file.id === fileId);
    return _file ? _file.matrix[row][col].color : "";
  }

  defaultResultList = () => {
    const { resultList, files, Interfaces } = this.state;
    const { verificationId } = this.props;
    let _resultList = resultList ? resultList : {};
    const _Interfaces = Interfaces[verificationId] ? Interfaces[verificationId] : [];
    let pcbs = _Interfaces.map(item => item.pcb);
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    for (let _interface of _Interfaces) {
      const pcb = _interface.pcb;
      const fileIndex = files.findIndex(file => file.pcb === pcb);
      if (fileIndex < 0) {
        continue;
      }
      const findFile = files[fileIndex];
      if (!findFile.ports || !findFile.ports.length) {
        continue;
      }

      const portList = findFile.ports;
      const fileId = files[fileIndex].id;
      if (!_resultList[pcb]) {
        _resultList[pcb] = {};
      }
      if (!_resultList[pcb].Default) {
        let _default = [];
        for (let i = 0; i < portList.length; i++) {
          const port = portList[i];
          _default[i] = { rowName: `Port ${port.index} (${port.name})`, children: [] };
          _default[i].children = portList.map(_port => {
            return {
              id: `${fileId}::${port.index - 1}::${_port.index - 1}`,
              name: `S(${port.index}, ${_port.index}) ${port.index !== _port.index ? _port.name : ''}`,
              color: this.getColorByIndex(fileId, port.index - 1, _port.index - 1)
            }
          })
        }
        _resultList[pcb].Default = { list: _default, show: _default[0] ? [_default[0].rowName] : [] };
      }
    }
    this.setState({
      resultList: _resultList,
      portSelect: {}
    }, () => {
      pcbs.forEach(pcb => {
        const _findFile = files.find(item => item.pcb === pcb);
        const currentList = _resultList[pcb];
        if (currentList && currentList.Default && currentList.Default.list && currentList.Default.list.length > 0 && currentList.Default.list[0].children[0]) {
          const defaultList = currentList.Default.list[0];
          const defaultValues = [defaultList.children[0].id];
          const defaultKey = defaultList.rowName;
          this.changePort(defaultValues, defaultKey, _findFile.id);
        }
      })
    })
  }

  findResultList = () => {
    const { files, Interfaces, resultList } = this.state;
    const { verificationId } = this.props;
    let _resultList = resultList ? resultList : {};
    const _Interfaces = Interfaces[verificationId] ? Interfaces[verificationId] : [];
    let edit = false;
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    for (let _interface of _Interfaces) {
      const pcb = _interface.pcb;
      const fileIndex = files.findIndex(file => file.pcb === pcb);
      if (fileIndex < 0) {
        continue;
      }
      const findFile = files[fileIndex];
      if (!findFile.ports || !findFile.ports.length) {
        continue;
      }
      const content = _interface.content ? _interface.content : {};
      const { signals, components, virtualComps } = content;
      if (!_resultList[pcb]) {
        _resultList[pcb] = {};
      }
      if (!_resultList[pcb].IL || _resultList[pcb].RL) {
        edit = true;
        const portList = findFile.ports;
        const { IL, RL, FEXT, NEXT } = getResultList({
          Signals: signals,
          Components: components,
          VirtualComps: virtualComps,
          portList,
          getColorByIndex: this.getColorByIndex,
          infoList: ["IL", "RL", "FEXT", "NEXT"],
          fileId: findFile.id
        });
        _resultList[pcb].IL = { list: IL, show: IL.map(item => item.rowName) };
        _resultList[pcb].RL = { list: RL, show: RL.map(item => item.rowName) };
        // If there is no FEXT or EXTX, do not expand the tree
        const FEXTShow = FEXT.filter(item => item.children && item.children.length) || []
        const EXTXShow = NEXT.filter(item => item.children && item.children.length) || []
        _resultList[pcb].FEXT = { list: FEXT, show: FEXTShow.map(item => item.rowName) };
        _resultList[pcb].NEXT = { list: NEXT, show: EXTXShow.map(item => item.rowName) };
      }
    }

    if (edit) {
      this.setState({
        resultList: _resultList,
        portSelect: {}
      });
    }
  }

  _rowNameClick = (displayMode, value, pcb) => {
    let _resultList = { ...this.state.resultList };
    if (_resultList[pcb] && _resultList[pcb][displayMode]) {
      let _show = [..._resultList[pcb][displayMode].show];
      if (_show.includes(value)) {
        _show = _show.filter(item => item !== value);
      } else {
        _show.push(value);
      }
      _resultList[pcb][displayMode].show = _show;
      this.setState({
        resultList: _resultList
      });
    }
  };

  showDataCurves = (idList) => {
    const { files } = this.state;
    const setting = this.childComp.getSetting();
    const curves = idList.map(d => {
      const [fileId, row, col] = strDelimited(d, "::");
      let parameter = Parameters.getParameter(fileId);
      if (!parameter) {
        return null;
      }
      const curveType = parameter.type; // simulation || target
      let fileIndex = files.findIndex(item => item.id === fileId);
      if (!files[fileIndex].ports || !parameter.ports) {
        return null;
      };
      return { parameter, fileIndex, row, col, curveType }
    });
    const _showCurves = sparameterShowCurves({
      parameters: Parameters,
      curves,
      param: setting.parameter,
      value: setting.value,
      sweep: false,
      files
    });
    this.selectAllCancelAble = makeCancelable(_showCurves)
    this.selectAllCancelAble.promise.then(resCurves => {
      if (resCurves) {
        this.plot2d.updateCurves(resCurves, true);
        resCurves.forEach(cur => {
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          const color = files[_fileIndex].matrix[cur.row][cur.col].color;
          this.plot2d.resetColor({ id: cur.id, row: cur.row, col: cur.col, color: color });
        })
        this.plot2d.resetPlot();
        const axis = this.childComp.getAxis();
        const setting = this.childComp.getSetting();
        if (Parameters.getSize() > 0) {
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      };
    })
  }

  _changeSelectAll = (e, group, fileId) => {
    const check = e.target.checked;
    let { portSelect } = this.state;
    const prevSelect = portSelect[group.rowName] || [];
    let prevOtherPcbSelect = [];
    portSelect[group.rowName] && portSelect[group.rowName].forEach(item => {
      const [id] = strDelimited(item, "::");
      if (id !== fileId) {
        prevOtherPcbSelect.push(item);
      }
    })
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    if (check) {
      portSelect[group.rowName] = [...prevOtherPcbSelect, ...group.children.map(d => d.id)];
    } else {
      portSelect[group.rowName] = [...prevOtherPcbSelect];
    };
    this.setState({
      portSelect: portSelect
    });
    if (check) {
      const allSelect = group.children.map(d => d.id);
      const newChecks = allSelect.filter(a => !prevSelect.includes(a));
      this.showDataCurves(newChecks)
    } else {
      const removedSelect = prevSelect.filter(item => !prevOtherPcbSelect.includes(item));
      const removeCurves = removedSelect.map(uncheck => {
        const [id, row, col] = strDelimited(uncheck, "::");
        return { id, row, col };
      })
      Parameters.removeCurves(removeCurves);
    };
  }

  setChannelState = (value, key) => {
    this.setState({
      key: value
    })
  }

  render() {
    const { files, portSelect, resultList, hasVcPCBs, hideIL, endToEndList } = this.state;
    return <Sparameter
      onRef={(ref) => { this.childComp = ref }}
      files={files}
      portSelect={portSelect}
      resultList={resultList}
      endToEndList={endToEndList}
      plot2d={this.plot2d}
      hasVcPCBs={hasVcPCBs}
      hideIL={hideIL}
      setChannelState={this.setChannelState}
      defaultResultList={this.defaultResultList}
      findResultList={this.findResultList}
      settingChangeCB={this.settingChangeCB}
      {...this.resultListAction}
    />
  }

  fileNameClick = (id) => {
    const { files } = this.state;
    let index = files.findIndex(item => item.id === id);
    const parameter = files[index];
    if (parameter.ports && parameter.matrix) {
      this.setState((prevState) => {
        prevState.files[index].show = !prevState.files[index].show;
        return {
          files: prevState.files
        }
      });
      return;
    }
    this.getNpiFile(parameter, index);
  }

  getNpiFile = (parameter, index) => {
    const { verificationId } = this.props;
    return new Promise((resolve, reject) => {
      parameter.getNpiFile(index, verificationId).then(res => {
        this.setState((prevState) => {
          prevState.files[index] = { ...prevState.files[index], ...res, show: !prevState.files[index].show };
          return {
            files: prevState.files
          }
        }, () => {
          resolve(this.state.files);
        })
      })
    })
  }

  changePort = (values, key, fileId) => {
    let { portSelect } = this.state;
    //current pcb select keys
    let currentKeys = [];

    if (!portSelect[key]) {
      portSelect[key] = [];
    }
    let prev = [];
    if (fileId) {
      portSelect[key].forEach(item => {
        const [id] = strDelimited(item, "::");
        if (id === fileId) {
          prev.push(item);
          currentKeys.push(item);
        }
      })
    } else {
      prev = portSelect[key] || [];
    }
    if (!Array.isArray(values)) {
      return;
    }

    // find uncheck, pre ['0::0','0::1'] -> values ['0::0']
    let uncheck = null, newCheck = null;
    if (prev.length - values.length === 1) {
      const _uncheck = prev.filter(p => !values.includes(p));
      if (_uncheck.length === 1) {
        uncheck = _uncheck[0];
      };
    };

    if (uncheck) {
      const selectIndex = portSelect[key].findIndex(port => port === uncheck);
      portSelect[key].splice(selectIndex, 1);
    } else {
      portSelect[key] = [...new Set([...portSelect[key], ...values])];
      currentKeys = [...new Set([...currentKeys, ...values])];
      const newCheckList = currentKeys.filter(p => !prev.includes(p));
      if (newCheckList.length === 1) {
        newCheck = newCheckList[0];
      } else {
        return;
      }
    };
    this.setState({
      portSelect: portSelect
    });

    if (uncheck) {
      const [fileId, row, col] = strDelimited(uncheck, "::");
      Parameters.removeCurve({ row, col, id: fileId });
      return;
    };

    if (newCheck) {
      this.showCurve(newCheck);
    }
  }

  changeEndToEndPorts = (portSelect, newPorts, deletePorts) => {
    this.setState({
      portSelect
    });
    if (deletePorts && deletePorts.length) {
      const removeCurves = deletePorts.map(uncheck => {
        const [id, row, col] = strDelimited(uncheck, "::");
        return { id, row, col };
      })
      Parameters.removeCurves(removeCurves);
    };

    if (newPorts && newPorts.length) {
      this.showEndToEndCurves(newPorts)
    }
  }

  showEndToEndCurves = (newPorts) => {
    let { endToEndList, files } = this.state;
    const setting = this.childComp.getSetting();
    const displayMode = this.childComp.getDisplayMode();
    const curves = newPorts.map(d => {
      let [id, row, col] = strDelimited(d, "::");
      let parameter = Parameters.getParameter(id);
      if (!parameter) {
        return null;
      }
      const [curveType, pcb1, pcb2, /* signal */] = strDelimited(id, "@");
      const index = endToEndList.findIndex(item => item.rowName === `${pcb1}@${pcb2}`);
      const signalIndex = index > -1 ? endToEndList[index].signals.findIndex(item => item.id === id) : -1;
      if (!parameter.ports || index < 0 || signalIndex < 0) {
        return null;
      };
      const curr = endToEndList[index].signals[signalIndex] || {};
      const mode = (curr[displayMode] || []).find(item => item.row === Number(row) && item.col === Number(col));
      if (!mode) {
        return null;
      }
      return { parameter, id, row, col, curveType, folderName: mode.folderName }
    });
    const _showCurves = sparameterShowCurves({
      parameters: Parameters,
      curves,
      param: setting.parameter,
      value: setting.value,
      sweep: false,
      files
    });
    this.selectAllCancelAble = makeCancelable(_showCurves)
    this.selectAllCancelAble.promise.then(resCurves => {
      if (resCurves) {
        this.plot2d.updateCurves(resCurves, true);
        resCurves.forEach(cur => {
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          const color = files[_fileIndex].matrix[cur.row][cur.col].color;
          this.plot2d.resetColor({ id: cur.id, row: cur.row, col: cur.col, color: color });
        })
        this.plot2d.resetPlot();
        const axis = this.childComp.getAxis();
        const setting = this.childComp.getSetting();
        if (Parameters.getSize() > 0) {
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      };
    })
    this.setState({
      endToEndList
    })
  }

  showCurve = (newCheck) => {
    const { files } = this.state;
    const setting = this.childComp.getSetting();
    let [id, row, col] = strDelimited(newCheck, "::");
    let parameter = Parameters.getParameter(id);
    if (!parameter) {
      return;
    }
    const curveType = parameter.type; // simulation || target
    let fileIndex = files.findIndex(item => item.id === id);
    if (!files[fileIndex].ports || !files[fileIndex].ports.length || !parameter.ports || !parameter.ports.length) {
      return;
    }
    Parameters.showCurve({ parameter, fileIndex, row, col, param: setting.parameter, value: setting.value, curveType }).then(curves => {
      if (curves) {
        files[fileIndex].matrix[row][col][curves.type] = curves.y;
        curves.visible = true;
        this.plot2d.updateCurves(curves, true);
        this.plot2d.resetPlot();
        if (!files[fileIndex].matrix || !files[fileIndex].matrix.length) {
          return;
        }
        const color = files[fileIndex].matrix[row][col].color;
        this.plot2d.resetColor({ id, row, col, color: color });
        const axis = this.childComp.getAxis();
        const setting = this.childComp.getSetting();
        Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    });
  }

  colorChange = (e, key) => {
    const [id, row, col] = strDelimited(key, "::");
    const value = e.toHexString()
    const { files, resultList } = this.state;
    const displayMode = this.childComp.getDisplayMode();
    let fileIndex = files.findIndex(item => item.id === id);
    const pcb = files[fileIndex].pcb;
    let _resultList = resultList[pcb];
    if (value) {
      files[fileIndex].matrix[row][col].color = value;
      if (_resultList && _resultList[displayMode] && _resultList[displayMode].list) {
        for (let group of _resultList[displayMode].list) {
          let _find = false;
          for (let child of group.children) {
            if (child.id === key) {
              child.color = value;
              _find = true;
              break;
            }
          }
          if (_find) {
            break;
          }
        }
      }

      this.setState({
        files,
        resultList: pcb && _resultList ? { ...resultList, pcb: _resultList } : { ...resultList }
      }, () => {
        this.plot2d.resetColor({ id, row: row, col: col, color: value })
        this.plot2d.redrawCurves();
      })
    }
  }

  settingChangeCB = () => {
    const { files } = this.state;
    const axis = this.childComp.getAxis();
    const setting = this.childComp.getSetting();
    Parameters.changeParameter({ parameters: files, setting, yScale: axis.yScale, xScale: axis.xScale });
  }

  resultListAction = {
    changePort: this.changePort,
    colorChange: this.colorChange,
    _rowNameClick: this._rowNameClick,
    _changeSelectAll: this._changeSelectAll,
    // fileDownload: this.fileDownload,
    fileNameClick: this.fileNameClick,
    changeEndToEndPorts: this.changeEndToEndPorts
  }
}

const mapState = (state) => {
  const { resultReducer: { channelList, resultInfo }, project } = state.SierraReducer;
  const { currentProjectId, verificationName } = project;
  const { verificationId, verificationSubId } = resultInfo;
  return {
    interfaceName: verificationName,
    channelList,
    verificationId,
    projectId: currentProjectId,
    verificationSubId
  }
}

export default connect(mapState, null)(Channel);