
import WaveformResultHelper from '../helper/WaveformResultHelper';
import {
  getFileInSierraPinToPinProject,
  getSierraTouchstoneFile,
  getSierraPostProcessConfig,
  getSierraPostProcessEdgesInfo,
  getSierraPostProcessJsonConfig,
  saveSierraPostProcessJsonConfig,
  getSierraEndToEndResultList,
  getTotalTraceCapacitanceResult,
  reCalcTotalCapacitanceByFreq,
  getTotalTraceCapacitanceCSVFile
} from '../../api/sierra';
import { getStackupByPCBId } from '../../data/LayoutData';
import SignalDelayResultHelper from '../helper/SignalDelayResultHelper';
import ImpedanceResultHelper from '../helper/ImpedanceResultHelper';
import PinToPinVerify from '../PinToPinVerifyDatabase';
import { SUCCESS } from '../../../constants/returnCode';
import { getDesignUnitFromLayoutDB, getExperimentResultPromise } from '../projectCtrl';
import { numberToScientific, unitToNumber } from '../../helper/dataProcess';
import { getPCBCompAndPin } from '../../helper/resultPublic';
import apiProcess from '../../api/utility';
import { getPreLayoutByVerificationId } from '../../api/sierra/preLayout';

/**
* Get the waveform result of the interface.
* 
* @param {String} verificationSubId - verification subId
* @param {String} name - Design and component name e.g. 'Design_Comp'
* @param {Boolean} original - true - Hiresolution, false - Fast Display
* @returns  Return a promise, promise resolve waveform
*/
export function getWaveform(verificationId, original, projectId) {
  //Waveform - Fast - path={verification_subid}/result/data/PinToPinSim.raw
  let path = `${verificationId}/result/data/PinToPinSim.raw`;
  if (original) {
    //Waveform - Hi-Re - path={verification_subid}/result/data/original/PinToPinSim.raw
    path = `${verificationId}/result/data/original/PinToPinSim.raw`;
  }
  return new Promise((resole, reject) => {
    getFileInSierraPinToPinProject(projectId, path).then((res) => {
      // get file success, parse the curve file
      let waveform = new WaveformResultHelper();
      waveform.parseRawCurve(res.data);
      resole(waveform);
    }, (error) => {
      if (original) {
        // 404
        getFileInSierraPinToPinProject(projectId, `${verificationId}/result/data/PinToPinSim.raw`).then((res) => {
          // get file success, parse the curve file
          let waveform = new WaveformResultHelper();
          waveform.parseRawCurve(res.data);
          resole(waveform);
        }, (error) => {
          let waveform = new WaveformResultHelper();
          console.error(error)
          resole(waveform);
        })
      } else {
        let waveform = new WaveformResultHelper();
        console.error(error)
        resole(waveform);
      }
    })
  });
}

const sweepWaveformData = {}
export function getSweepWaveform(expId, option, keep = false) {
  return new Promise((resolve, reject) => {
    const mapId = `${expId} - ${option}`
    if (keep && sweepWaveformData[mapId]) {
      const res = sweepWaveformData[mapId];
      let waveform = new WaveformResultHelper();
      waveform.parseRawCurve(res);
      resolve(waveform);
    } else {
      getExperimentResultPromise({ experimentId: expId, option }).then(res => {
        // get file success, parse the curve file
        let waveform = new WaveformResultHelper();
        waveform.parseRawCurve(res);
        sweepWaveformData[mapId] = res;
        resolve(waveform);
      }, error => {
        let waveform = new WaveformResultHelper();
        console.error(error)
        resolve(waveform);
      })
    }
  })
}

/**
 * Exports a component information array list
 * 
 * @param {Object} info - interface info -{ components, config, models, name, signals, sourceNets } 
 * @param {Array} curves - [{ curve }]
 * @param {Array} designs - [{ name, id }]
 * @exports compInfo - color, component, curveIndex, curveName, designName, pin, signal, usage
 */
export function getComponentInfo(info, curves, designs) {
  const { components, virtualComps } = info;
  let pins = [];
  const CHIP_TYPE = ['IC', 'Chip', 'Repeater', 'testPoint', "Connector"]
  const ICComps = components.filter(item => CHIP_TYPE.includes(item.type) || (item.probePins && item.probePins.length));
  const allDisplayComps = virtualComps && virtualComps.length ? [...ICComps, ...virtualComps] : ICComps;
  if (allDisplayComps.length > 0) {
    allDisplayComps.forEach(comp => {
      comp.pins.forEach(pinModel => {
        const { signal = "", usage = "", pin } = pinModel;
        let _usage = usage;

        if (comp.type === 'testPoint') {
          _usage = 'Test Point';
        };
        if (!usage) {
          _usage = comp.type;
        }
        let ndEn = false;
        if (pinModel.pinModels && pinModel.pinModels.length > 0) {
          const findEn = pinModel.pinModels.find(item => item.pinName === 'nd_en');
          if (findEn && findEn.type) {
            ndEn = true;
          }
        }
        pins.push({ component: comp.name, signal, usage: _usage, pin, pcb: comp.pcb, ndEn, pcbId: comp.pcbId })
      });
    });
  };

  // const newSignals = signals.map(signal => signal.name);
  const designIds = designs.map(design => design.id);
  const compInfo = curves.map((curve, i) => {
    const { name, color } = curve;
    let item = {
      curveName: name,
      curveIndex: i,
      color,
    }
    //if (name.includes('_in)')) return item;
    const indexStart = name.indexOf('('), indexEnd = name.lastIndexOf(')');
    if (indexStart > -1 && indexEnd > -1) {
      const _name = name.slice(indexStart + 1, indexEnd);
      let { compPinName, pcbInfo } = getPCBCompAndPin(_name);
      let index = designIds.indexOf(pcbInfo.slice(3));
      if (index < 0) {
        throw new Error(`can't find designId in design info`)
      } else {
        item.designName = designs[index].name;
      }
      let pinInfo = pins.find(pin =>
        compPinName.toLowerCase() === `${pin.component.toLowerCase()}_${pin.pin.toLowerCase()}`
        && designs[index].name
        && pin.pcb === designs[index].name
      );
      if (pinInfo && name.match(/_in[)]$/)) {
        pinInfo.usage = 'Stimulus in';
      }
      if (pinInfo && name.match(/nd_en/)) {
        if (pinInfo.ndEn) {
          pinInfo.usage = 'Stimulus en';
        } else {
          pinInfo.usage = '';
        }
      }
      let pkg = false;
      if (name.match(/_u[)]$/ig) || name.match(/_u$/ig)) {
        pkg = true;
      }
      item = { ...item, ...pinInfo, pkg };
    } else {
      let { compPinName, pcbInfo } = getPCBCompAndPin(name);
      let index = designIds.indexOf(pcbInfo.slice(3));
      if (index < 0) {
        throw new Error(`can't find designId in design info`)
      } else {
        item.designName = designs[index].name;
      }
      let pinInfo = pins.find(pin =>
        compPinName.toLowerCase() === `${pin.component.toLowerCase()}_${pin.pin.toLowerCase()}`
        && designs[index].name
        && pin.pcb === designs[index].name
      );
      if (pinInfo && name.match(/_in$/)) {
        pinInfo.usage = 'Stimulus in';
      }
      if (pinInfo && name.match(/nd_en/)) {
        if (pinInfo.ndEn) {
          pinInfo.usage = 'Stimulus en';
        } else {
          pinInfo.usage = '';
        }
      }
      let pkg = false;
      if (name.match(/_u[)]$/ig) || name.match(/_u$/ig)) {
        pkg = true;
      }
      item = { ...item, ...pinInfo, pkg };
    }
    return item;
  });
  return compInfo;
};

/**
 * Get delay result
 * 2019.11/19
 *  @param {string} verificationSubId verfication subId
 * @export
 * @returns Promise
 */
export function getSignalDelay({ verificationId, designs, projectId, experimentId, verificationSubId }) {
  /* const designs = SETUP.getDesignsByVerificationId(verificationId); */
  let nameIdMap = {};
  designs.forEach(design => {
    nameIdMap[design.name] = design.id;
  });
  return experimentId ? SignalDelayResultHelper.getSweepDelayData(experimentId, nameIdMap, projectId)
    : SignalDelayResultHelper.getSignalDelayData(verificationId, nameIdMap, projectId, verificationSubId);
};

/**
 * Get impedence result
 * 2019.11.19
 * @param {string} verificationSubId verification subId
 * @param {string} verificationName verification name
 * @export
 * @returns Promise
 */
export function getImpedance({ verificationId, verificationName, design, Interfaces, projectId, experimentId }) {
  if (!design || !design.pcbSubId) {
    return [];
  }
  const { pcbSubId, name, pcbId } = design;
  const setup = PinToPinVerify;
  const { signals } = setup.mergeInterfacesInfo(Interfaces, verificationName);
  let signal = []; // [{ name: "signal", nets: [{ net: "", pcb: "" }] }]
  signals.forEach(item => {
    let net = []
    item.nets.forEach(n => {
      // name - pcb name
      // filter pcb
      if (item.pcb === name) {
        net.push({
          net: n,
          pcb: item.pcb
        });
      }
    });
    const index = signal.findIndex(i => i.name === item.name);
    if (index > -1) {
      signal[index].nets = [...signal[index].nets, ...net]
    } else {
      signal.push({
        name: item.name,
        nets: [...net]
      })
    }
  });

  const ImpedanceHandel = (stackup, res) => {
    if (!stackup) { return {} }
    return getDesignUnitFromLayoutDB(pcbSubId).then(unit => {
      const designUnit = unit || stackup.unit;
      const result = JSON.parse(JSON.stringify(res));
      const layers = stackup.metalLayerList.map(item => item.name);
      result.layerStats.forEach(layerItem => {
        const index = layers.indexOf(layerItem.layer);
        layerItem.thickness = stackup.metalLayerList[index] ? stackup.metalLayerList[index].mThickness : '';
      });
      result.unit = unitConvert[StringToUnits(designUnit)];
      result.stackupUnit = unitConvert[StringToUnits(stackup.unit)];
      return result;
    })
  }

  return getStackupByPCBId(pcbId).then(stackup => {
    return ImpedanceResultHelper.getImpedanceData({
      signals: signal,
      interfaceId: verificationId,
      PCBID: pcbSubId,
      projectId,
      experimentId
    }).then(res => {
      return ImpedanceHandel(stackup, res)
    })
  })
};

const unitConvert = {
  Length_m: "m",
  Length_cm: "cm",
  Length_mm: "mm",
  Length_um: "um",
  Length_inch: "inch",
  Length_mil: "mil",
  Length_mils: "mil",
  Unknown: "Unknown"
};

const StringToUnits = function (strUnit) {
  if (strUnit === "M") {
    return "Length_um";
  }

  switch (strUnit.toLowerCase()) {
    case "i":
    case "mil":
    case "mils":
      return "Length_mils";
    case "inch":
    case "inches":
      return "Length_inch";
    case "u":
    case "um":
      return "Length_um";
    case "mm":
      return "Length_mm";
    case "cm":
      return "Length_cm";
    case "m":
      return "Length_m";
    default:
      return "Unknown";
  }
};
/**
 * Get touchstone file extraction by HFSS or SIWave
 * 
 * [{
 *    "fileName": "pcb_pcbId_verificationId.snp",
 *    "type": "simulation"
 *  }]
 * @export
 * @param {*} verificationId verification id
 * @returns Promise
 */
export function getTouchstoneFile(verificationId) {
  return new Promise((resolve, reject) => {
    getSierraTouchstoneFile(verificationId).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      }
    }, error => {
      console.error(error);
      resolve([]);
    });
  })
}

/**
 * get sierra current result verification.json
 * @param verificationId
 * @param projectId
*/
export function getVerificationJson(verificationId, projectId) {
  let path = `${verificationId}/verification.json`
  return new Promise((resolve, reject) => {
    getFileInSierraPinToPinProject(projectId, path).then((res) => {
      resolve(res.data)
    }, (error) => {
      reject(null);
    })
  })
}

function getInterfacesSignals(interfaces) {
  if (!interfaces || !interfaces.length) {
    return [];
  }
  let signals = [];
  interfaces.forEach(item => {
    if (item && item.content && item.content.signals && item.content.signals.length) {
      item.content.signals.forEach(signal => {
        const findIndex = signals.findIndex(it => it.signal === signal.name);
        if (findIndex > -1) {
          signals[findIndex].nets = [...new Set([...signals[findIndex].nets, ...signal.nets])];
        } else {
          signals.push({
            signal: signal.name,
            nets: [...signal.nets]
          })
        }
      })
    }
  });
  return signals;
}

function getImpedanceSignal(impedance, signals) {
  if (impedance && impedance.netStats && Array.isArray(impedance.netStats)) {
    impedance.netStats.forEach(item => {
      signals.forEach(signal => {
        if (!item.signals) {
          item.signals = [];
        }
        if (signal.nets && signal.nets.includes(item.name)) {
          item.signals = [...new Set([...item.signals, signal.signal])];
        }
      })
    })
  }
  return impedance;
}

/**
 * get WaveForm Post Process config
 *
 * @param {*string} verificationId
 * @returns Promise
 */
function getWaveFormPostProcessConfig(verificationId) {
  return new Promise((resolve, reject) => {
    getSierraPostProcessConfig({ verificationId }).then(res => {
      if (res) {
        const data = parsePostProcessData(res.data)
        resolve(data)
      }
    }, error => {
      reject(null);
    })
  });
}

function parsePostProcessData(_data) {
  const matchData = _data.match(/[^\r\n]+/g);
  if (matchData && matchData.length) {
    let curveName = matchData[0];
    let type = '', parseData = {};
    for (let info of matchData) {
      const rule = /pcb/ig;
      if (rule.test(info)) {
        curveName = info
      }
      if (['#RisingEdge', '#FallingEdge'].includes(info)) {
        type = info.replace('#', '')
      } else {
        const splitData = info.split('\t')
        if (splitData && splitData.length > 3) {

          if (!parseData[curveName]) {
            parseData[curveName] = {
              [type]: { data: [] }
            }
          }
          if (!parseData[curveName][type]) {
            parseData[curveName][type] = { data: [] }
          }

          let timeUnit = parseData[curveName].timeUnit
          if (!timeUnit) {
            const numberUnit = numberToScientific(splitData[0])
            timeUnit = numberUnit.replace(/[^a-z]+/ig, "");
            parseData[curveName].timeUnit = timeUnit
          }
          const number = unitToNumber(timeUnit)
          // unit conversion
          const timeBegin = (splitData[0] / number)
          const timeEnd = (splitData[1] / number)
          const time = [timeBegin, timeEnd]
          let timeDifference = (splitData[2] / number).toFixed(2)

          parseData[curveName][type].data.push({
            time,
            timeDifference,
            doubleEdge: splitData[3]
          })
          if (!parseData[curveName][type].maxDifferenceTime || parseData[curveName][type].maxDifferenceTime < timeDifference) {
            parseData[curveName][type].maxDifferenceTime = timeDifference
          }
          if (!parseData[curveName][type].minDifferenceTime || parseData[curveName][type].minDifferenceTime > timeDifference) {
            parseData[curveName][type].minDifferenceTime = timeDifference
          }

        }
      }
    }
    return parseData
  }
}

/**
 * get WaveForm Post Process config
 *
 * @param {*string} verificationId
 * @returns Promise
 */

function getWaveFormPostProcessEdgesInfo(verificationId) {
  return new Promise((resolve, reject) => {
    getSierraPostProcessEdgesInfo({ verificationId }).then(res => {
      if (res) {
        const data = parsePostProcessEdgesData(res.data)
        resolve(data)
      } else {
        // If there is no data, call the previous interface to obtain the data.
        getSierraPostProcessConfig({ verificationId }).then(res => {
          if (res) {
            const data = parsePostProcessData2(res.data)
            resolve(data)
          }
        }, error => {
          reject(null);
        })
      }
    }, error => {
      getSierraPostProcessConfig({ verificationId }).then(res => {
        if (res) {
          const data = parsePostProcessData2(res.data)
          resolve(data)
        }
      }, error => {
        reject(null);
      })
    })
  });
}

function getWaveFormJsonConfig(verificationId, modelType) {
  return apiProcess(getSierraPostProcessJsonConfig, { verificationId, modelType }, false, false, true);
}

function parsePostProcessData2(_data) {
  const matchData = _data.match(/[^\r\n]+/g);
  if (matchData && matchData.length) {
    let curveName = matchData[0];
    let type = '', parseData = {};
    for (let info of matchData) {
      const rule = /pcb/ig;
      if (rule.test(info)) {
        curveName = info
      }
      if (['#RisingEdge', '#FallingEdge'].includes(info)) {
        type = info.replace('#', '')
      } else {
        const splitData = info.split('\t')
        if (splitData && splitData.length > 3) {

          if (!parseData[curveName]) {
            parseData[curveName] = {
              [type]: { data: [] }
            }
          }
          if (!parseData[curveName][type]) {
            parseData[curveName][type] = { data: [] }
          }

          let timeUnit = parseData[curveName].timeUnit
          if (!timeUnit) {
            const numberUnit = numberToScientific(splitData[0])
            timeUnit = numberUnit.replace(/[^a-z]+/ig, "");
            parseData[curveName].timeUnit = timeUnit
          }
          const number = unitToNumber(timeUnit)
          // unit conversion
          const timeBegin = (splitData[0] / number)
          const timeEnd = (splitData[1] / number)
          const time = [timeBegin, timeEnd]
          let timeDifference = (splitData[2] / number).toFixed(2)

          parseData[curveName][type].data.push({
            time,
            timeDifference,
            doubleEdge: splitData[3]
          })
          if (!parseData[curveName][type].maxDifferenceTime || parseData[curveName][type].maxDifferenceTime < timeDifference) {
            parseData[curveName][type].maxDifferenceTime = timeDifference
          }
          if (!parseData[curveName][type].minDifferenceTime || parseData[curveName][type].minDifferenceTime > timeDifference) {
            parseData[curveName][type].minDifferenceTime = timeDifference
          }
        }
      }

      const keys = Object.keys(parseData);
      for (let key of keys) {
        const risingLength = parseData[key].RisingEdge && parseData[key].RisingEdge.data ? parseData[key].RisingEdge.data.length : 0;
        const fallingLength = parseData[key].FallingEdge && parseData[key].FallingEdge.data ? parseData[key].FallingEdge.data.length : 0;
        let length = risingLength > fallingLength ? risingLength : fallingLength;
        let newEdges = [];
        for (let i = 0; i < length; i++) {
          const risingInfo = parseData[key].RisingEdge && parseData[key].RisingEdge.data && parseData[key].RisingEdge.data[i] ? parseData[key].RisingEdge.data[i] : {};
          const fallInfo = parseData[key].FallingEdge && parseData[key].FallingEdge.data && parseData[key].FallingEdge.data[i] ? parseData[key].FallingEdge.data[i] : {};
          let risingData = {
            timeRising: risingInfo.time,
            timeFalling: [],
            risingTimeDifference: risingInfo.timeDifference,
            doubleEdge: risingInfo.doubleEdge,
            is_rising: true
          }
          let fallingData = {
            timeRising: [],
            timeFalling: fallInfo.time,
            fallingTimeDifference: fallInfo.timeDifference,
            doubleEdge: fallInfo.doubleEdge,
            is_rising: false
          }
          newEdges.push(risingData, fallingData)
        }

        const minInfo = {
          timeRising: getMinValue(newEdges, 'risingTimeDifference', true, 2),
          timeFalling: getMinValue(newEdges, 'fallingTimeDifference', true, 2),
          index: 'Min',
        }
        const maxInfo = {
          timeRising: getMinValue(newEdges, 'risingTimeDifference', false, 2),
          timeFalling: getMinValue(newEdges, 'fallingTimeDifference', false, 2),
          index: 'Max',
        }
        parseData[key].edgesInfo = { edges: newEdges, minInfo, maxInfo }
      }
    }
    return parseData
  }
}

function getMinValue(list, key, isMin, toFixed = false) {
  const filterValue = list.map(item => item[key]).filter(item => item);
  if (!filterValue || !filterValue.length) { return '' }
  // return isMin ? Math.min(...filterValue) : Math.max(...filterValue);
  const value = isMin ? Math.min(...filterValue) : Math.max(...filterValue);
  return toFixed ? value.toFixed(toFixed) : value
}

function parsePostProcessEdgesData(edgesData) {
  let parseData = {};
  function getUnitConvertValue(number, isList, value, value2) {
    // Obtain the numerical value after unit conversion
    if (!isList && !value) { return "" };
    if (isList && (!value || !value2)) { return [] }
    const _value = value / number;
    if (!isList) {
      return _value;
    }
    const _value2 = value2 / number;
    return [_value, _value2]
  }

  for (let edgesInfo of edgesData) {
    const { waveform_signal, edges } = edgesInfo;
    if (!parseData[waveform_signal]) { parseData[waveform_signal] = {} }
    parseData[waveform_signal].edgesInfo = edgesInfo;

    let newEdges = [];
    for (let info of edges) {
      const { is_rising, setup_time, t_start, t_stop, hold_time, overshoot, /* undershoot, */ double_edge_low, double_edge_high, high_time, low_time } = info;

      let timeUnit = parseData[waveform_signal].timeUnit
      if (!timeUnit) {
        const numberUnit = numberToScientific(t_start)
        timeUnit = numberUnit.replace(/[^a-z]+/ig, "");
        parseData[waveform_signal].timeUnit = timeUnit
      }

      const number = unitToNumber(timeUnit);
      const time = getUnitConvertValue(number, true, t_start, t_stop);
      const doubleEdgeFalling = (double_edge_low || double_edge_low === 0) && (double_edge_high || double_edge_high === 0) ? [double_edge_low, double_edge_high] : []
      const data = {
        timeRising: is_rising ? time : '',
        timeFalling: !is_rising ? time : [],
        doubleEdgeFalling,
        hold_time: getUnitConvertValue(number, false, hold_time),
        setup_time: getUnitConvertValue(number, false, setup_time),
        high_time: getUnitConvertValue(number, false, high_time),
        low_time: getUnitConvertValue(number, false, low_time),
        overshoot: is_rising ? overshoot : '',
        undershoot: is_rising ? '' : overshoot,
        risingTimeDifference: is_rising && time.length ? time[1] - time[0] : '',
        fallingTimeDifference: !is_rising && time.length ? time[1] - time[0] : '',
        doubleEdgeFallingDifference: doubleEdgeFalling && doubleEdgeFalling.length ? doubleEdgeFalling[1] - doubleEdgeFalling[0] : "",
      }
      newEdges.push({ ...info, ...data })
    }

    const min = {
      timeRising: getMinValue(newEdges, 'risingTimeDifference', true),
      timeFalling: getMinValue(newEdges, 'fallingTimeDifference', true),
      doubleEdgeFalling: getMinValue(newEdges, 'doubleEdgeFallingDifference', true),
      hold_time: getMinValue(newEdges, 'hold_time', true),
      setup_time: getMinValue(newEdges, 'setup_time', true),
      high_time: getMinValue(newEdges, 'high_time', true),
      low_time: getMinValue(newEdges, 'low_time', true),
      overshoot: getMinValue(newEdges, 'overshoot', true),
      undershoot: getMinValue(newEdges, 'undershoot', true),
      index: 'Min',
    }
    const max = {
      timeRising: getMinValue(newEdges, 'risingTimeDifference', false),
      timeFalling: getMinValue(newEdges, 'fallingTimeDifference', false),
      doubleEdgeFalling: getMinValue(newEdges, 'doubleEdgeFallingDifference', false),
      hold_time: getMinValue(newEdges, 'hold_time'),
      setup_time: getMinValue(newEdges, 'setup_time'),
      high_time: getMinValue(newEdges, 'high_time', false),
      low_time: getMinValue(newEdges, 'low_time', false),
      overshoot: getMinValue(newEdges, 'overshoot'),
      undershoot: getMinValue(newEdges, 'undershoot'),
      index: 'Max',
    }
    parseData[waveform_signal].edgesInfo.edges = newEdges;
    parseData[waveform_signal].edgesInfo.minInfo = min;
    parseData[waveform_signal].edgesInfo.maxInfo = max;
  }
  return parseData;
}

function mergeProcessData(config, table) {
  let _config = { ...config };
  for (let row of table) {
    const { pcb, pin, v_high, v_low, vinh, vinl, vinh_per, vinl_per } = row;
    const pins = pin.split('::');
    let comp = null, _pin = null;
    if (pins.length === 3) {
      comp = pins[1];
      _pin = pins[2];
    } else {
      comp = pins[0];
      _pin = pins[1];
    }

    const pcbIndex = _config.pcbs.findIndex(p => p.name === pcb);
    if (pcbIndex > -1) {
      const compIndex = _config.pcbs[pcbIndex].components.findIndex(c => c.name === comp);
      if (compIndex > -1) {
        const pinIndex = _config.pcbs[pcbIndex].components[compIndex].pins.findIndex(p => p.name === _pin);
        if (pinIndex > -1) {
          const data = _config.pcbs[pcbIndex].components[compIndex].pins[pinIndex];
          _config.pcbs[pcbIndex].components[compIndex].pins[pinIndex] = {
            ...data,
            v_high,
            v_low,
            vih: vinh,
            vil: vinl,
            vih_per: vinh_per,
            vil_per: vinl_per
          };
        }
      }
    }
  }

  return _config
}

function saveProcessingConfig(verificationId, config) {
  return apiProcess(saveSierraPostProcessJsonConfig, { verificationId, config })
}

/**
 * Get end to end result ports info 
 * 
 * endToEndList: [{
 *    "driver": {component,pin, pcb},
 *    "receiver": {component,pin, pcb},
 *    "signal":"signalName"
 *  }]
 * @export
 * @param {*} verificationId verification id
 * @returns Promise
 */
function getEndToEndResultList(verificationId) {
  return new Promise((resolve, reject) => {
    getSierraEndToEndResultList(verificationId).then(res => {
      if (res.data && res.data.endToEndList) {
        resolve(res.data.endToEndList);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  })
}

/** 
 * Get total trace capacitance 
 * 20240719
 * @param {string} verificationId
 * @param {string} designId
 * **/
function getTotalTraceCapacitance(verificationId, pcbId) {
  return apiProcess(getTotalTraceCapacitanceResult, { verificationId, pcbId }, false, false, true)
}

/** 
 * re calculate total trace capacitance by frequency
 * 20240723
 * @param {string} verificationId 
 * @param {string} frequency 10M
 * **/
function reCalcCapacitanceByFreq({ frequency, verificationId }) {
  return apiProcess(reCalcTotalCapacitanceByFreq, { verificationId, frequency })
}

/** 
 * Get total trace capacitance 
 * 20240723
 * @param {string} verificationId
 * @param {string} designId
 * **/
function getTotalTraceCapacitanceCSV({ verificationId, fileName }) {
  return apiProcess(getTotalTraceCapacitanceCSVFile, { verificationId, fileName }, false, false, true)
}

function getPreLayoutJsonByVerificationId(verificationId) {
  return apiProcess(getPreLayoutByVerificationId, verificationId)
}

export {
  getInterfacesSignals,
  getImpedanceSignal,
  getWaveFormPostProcessConfig,
  getWaveFormPostProcessEdgesInfo,
  getWaveFormJsonConfig,
  mergeProcessData,
  saveProcessingConfig,
  getEndToEndResultList,
  getTotalTraceCapacitance,
  reCalcCapacitanceByFreq,
  getTotalTraceCapacitanceCSV,
  getPreLayoutJsonByVerificationId
}

export {
  parseTotalTraceCapacitance,
  getTotalTraceCapacitanceTableData
} from "./totalTraceCapacitance";

export {
  getResultDisplaySettings,
  saveResultDisplaySettings,
  getWaveformDisplayMode
} from './resultSettingHelper';

export {
  getEyeDiagramConfig,
  saveEyeDiagramConfig,
  generateEyeDiagram,
  getEyeDiagramSignals,
  getEyeDiagramsInfo,
  getEyeDefaultUI,
  getEyeDefaultVRef,
  getDefaultTimeScale,
  getSimulationClock,
  checkEyeDiagramError,
  getDownloadEyeGenerateLog
} from './eyeDiagrams';

export {
  getSchematicImpedance,
  getPreLayoutInterfaceSignalNets,
  getPreImpedanceResultBySignals
} from './impedance';