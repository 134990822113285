import CeLinkedMap from '../utility/CeLinkedMap';
import StringList from '../utility/StringList';

var mDefaultColors = ["Darkorange", "CadetBlue ", "CornflowerBlue", "DarkSlateGray",
  "IndianRed", "ForestGreen", "INDIGO", "TEAL", "#cc5555", "SLATEBLUE",
  "SADDLEBROWN", "#ffc107", "SEAGREEN", "LIGHTPINK", "LIGHTGREEN", "#00bcd4", "#b09998",
  "CHOCOLATE", "OLIVE", "CORNSILK", "KHAKI", "DARKSALMON", "LIGHTPINK",
  "BEIGE", "CRIMSON", "FUCHSIA", "LAVENDER", "LIGHTSEAGREEN",
  "BISQUE", "CYAN", "GAINSBORO", "LAVENDERBLUSH", "LIGHTSTEELBLUE",
  "DARKCYAN", "CORAL", "LIGHTBLUE", "LIMEGREEN",
  "BROWN", "DARKGOLDENROD", "GRAY", "LIGHTCORAL", "MAGENTA",
  "BURLYWOOD", "DARKGRAY", "GREEN", "LIGHTPINK", "MAROON",
  "CADETBLUE", "DARKGREEN", "LIGHTCYAN", "MEDIUMAQUAMARINE",
  "CHARTREUSE", "DARKKHAKI", "HONEYDEW", "LIGHTGOLDENRODYELLOW"
];

let mDefaultNetColors = [
  "YELLOW", "CORNSILK", "LIGHTPINK", "LIGHTGOLDENRODYELLOW", "BEIGE",
  "LAVENDER", "CYAN", "GAINSBORO", "LAVENDERBLUSH", "LIGHTGREEN", "BISQUE",
  "LIGHTCORAL", "KHAKI", "LIGHTPINK", "CHARTREUSE", "LIGHTBLUE"
];

let _mDefaultNetColors = [
  'Gold', 'BlanchedAlmond', 'HotPink', 'PaleGoldenrod', 'PaleGoldenrod',
  'Thistle', 'DarkTurquoise', 'DarkGray', 'MistyRose', 'MediumSeaGreen', 'BurlyWood',
  'IndianRed', 'DarkKhaki', 'HotPink', 'LimeGreen', 'LightSkyBlue'
]

const CeLayoutSettings = function () {
  this.mShowCompName = false;
  this.mFilled = true;
  this.mViaOutline = true;
  this.mColorByLayer = false;
  this.mProfileColor = "Maroon";
  this.mPartColor = new CeLinkedMap();
  this.mLayerColor = new CeLinkedMap();
  this.mLayerVisibility = new CeLinkedMap();
  this.mNetColor = new CeLinkedMap();
}

CeLayoutSettings.prototype.IsEmpty = function () {
  return this.mLayerColor.isEmpty() || this.mNetColor.isEmpty();
}

CeLayoutSettings.prototype.ClearAll = function () {
  this.mPartColor.clear();
  this.mLayerColor.clear();
  this.mLayerVisibility.clear();
  this.mNetColor.clear();
}

CeLayoutSettings.prototype.SetShowComponentname = function (nameOn) {
  this.mShowCompName = nameOn;
}

CeLayoutSettings.prototype.GetSelectNetColor = function (index) {
  let colorIndex = index % mDefaultNetColors.length;
  let resColor = [mDefaultNetColors[colorIndex], _mDefaultNetColors[colorIndex]]
  return resColor;
}

CeLayoutSettings.prototype.GetDefaultColor = function (index) {
  if (index < 0)
    index = 0;

  while (index >= mDefaultColors.length) {
    index -= mDefaultColors.length;
  }
  return mDefaultColors[index];
}

CeLayoutSettings.prototype.ShowComponentname = function () {
  return this.mShowCompName;
}

CeLayoutSettings.prototype.SetFilled = function (filled) {
  this.mFilled = filled;
}

CeLayoutSettings.prototype.InFilledMode = function () {
  return this.mFilled;
}

CeLayoutSettings.prototype.SetViaOutline = function (viaOutline) {
  this.mViaOutline = viaOutline;
}

CeLayoutSettings.prototype.ShowViaOutline = function () {
  return this.mViaOutline;
}

CeLayoutSettings.prototype.SetColorByLayer = function (mode) {
  this.mColorByLayer = mode;
}

CeLayoutSettings.prototype.ColorByLayer = function () {
  return this.mColorByLayer;
}

CeLayoutSettings.prototype.GetPartColor = function (part) {
  return "lightgray";
}

CeLayoutSettings.prototype.GetLayerList = function () {
  var layers = new StringList();
  for (var i = 0; i < this.mLayerColor.mKeys.length; i++)
    layers.push(this.mLayerColor.mKeys[i]);
  return layers;
}

// Net settings
CeLayoutSettings.prototype.InitNetSettings = function (nets) {
  if (this.mNetColor.isEmpty() == false)
    return;

  for (var i = 0; i < nets.size(); i++) {
    var netColor = this.GetDefaultColor(i);
    this.mNetColor.put(nets.get(i), netColor);
  }
}

CeLayoutSettings.prototype.GetNetColor = function (netName) {
  var color = this.mNetColor.getValue(netName);
  if (color == null) {
    color = this.GetDefaultColor(this.mNetColor.size());
    this.mNetColor.put(netName, color);
  }
  return color;
}

// Layer settings
CeLayoutSettings.prototype.InitLayerSettings = function (layers) {
  if (this.mLayerColor.isEmpty() == false)
    return;

  for (var i = 0; i < layers.size(); i++) {
    var lyrColor = this.GetDefaultColor(i);
    this.mLayerColor.put(layers.get(i), lyrColor);
    this.mLayerVisibility.put(layers.get(i), false);
  }
  //    this.mLayerVisibility.setValue(layers.get(0), true);
  //  this.mLayerVisibility.setValue(layers.get(5), true);
  //        this.mLayerVisibility.setValue(layers.get(6), true);
}

CeLayoutSettings.prototype.GetLayerColor = function (layer) {
  var color = this.mLayerColor.getValue(layer);
  if (color == null) {
    color = this.GetDefaultColor(this.mLayerColor.size());
    this.mLayerColor.put(layer, color);
  }
  return color;
}

CeLayoutSettings.prototype.IsLayerVisible = function (layer) {
  var visible = this.mLayerVisibility.getValue(layer);
  if (visible == null) {
    visible = false;
    this.mLayerVisibility.put(layer, visible);
  }
  return visible;
}

CeLayoutSettings.prototype.SetLayerVisible = function (layer, bvis) {
  this.mLayerVisibility.setValue(layer, bvis);
}

CeLayoutSettings.prototype.ReadFromIODataNode = function (myBlock) {

  if (!myBlock) return;

  var layerBlk = myBlock.GetSubBlock("Layers");
  if (layerBlk != null) {
    var layers = layerBlk.GetBlockItemNames();
    this.InitLayerSettings(layers);
  }

  var netsBlk = myBlock.GetSubBlock("Nets");
  if (netsBlk != null) {
    var nets = netsBlk.GetBlockItemNames();
    this.InitNetSettings(nets);
  }
}

// CeLayoutSettings.prototype.WriteWriteIntoIODataNode = function (attribBlk) {

// };

export default CeLayoutSettings;