import React, { Component, Fragment } from 'react';
import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import CheckTag from './CheckableTag';
import { isMac } from '@/services/api/userAgent';
import './index.css';
import { CAP, IPD, REMOVED } from '../../constants/componentType';
class TableTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyCode: null,
      visible: false,
      comps: [],
      mac: isMac(),
      key: isMac() ? 91 : 17,
      openHelp: false,
      disableDoubleClick: false
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('keydown', this.CtrlDown, true);
    document.addEventListener('keyup', this.CtrlUp, true);
    const { compsName, showToolTip = true, disableDoubleClick = false } = this.props;
    let comps = [];
    compsName.forEach(item => {
      comps.push(item.name);
    });
    this.setState({
      comps: compsName,
      visible: showToolTip,
      disableDoubleClick: disableDoubleClick
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('keydown', this.CtrlDown, true);
    document.removeEventListener('keyup', this.CtrlUp, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const tagBox = document.getElementById('my-tag-box');
    const tagPopover = document.getElementsByClassName('select-tag-popover')[0];
    const tabHelpBox = document.getElementsByClassName('tab-help-Popover')[0];

    if ((tagBox && tagBox.contains(target)) || (tagPopover && tagPopover.contains(target)) || (tabHelpBox && tabHelpBox.contains(target))) {
      if (tabHelpBox && !tabHelpBox.contains(target)) {
        this.setState({
          openHelp: false
        });
      }
      return;
    }

    this.props.save();
  }

  changeChecked = (name, checked, record) => {
    let { comps } = this.state;
    comps.forEach(item => {
      if (item.name === name) {
        item.checked = checked
      }
    });
    this.setState({
      comps
    });
    this.props.changeCompsChecked([name], checked, record);
  };

  CtrlDown = (e) => {
    if (e.keyCode === this.state.key) {
      this.setState({
        keyCode: e.keyCode
      })
    }
  }

  CtrlUp = (e) => {
    if (e.keyCode === this.state.key) {
      this.setState({
        keyCode: null,
      });
    }
  }

  changeSelectComps = ({ comps }) => {
    this.setState({
      comps
    })
  }

  changeStatus = (e, status, disabled) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    let { record, select, isPCBOpened } = this.props;
    let { comps } = this.state;
    if (status && status === 'display') {
      if (!isPCBOpened(record)) {
        return;
      }
      let selectedComps = comps.filter(item => item.selected);
      let selectedCompsName = selectedComps.map(item => item.name);
      select && select(record, selectedCompsName);
      comps.forEach(item => {
        delete item.selected;
      });
      this.setState({
        comps
      })
    } else {
      let changeComps = []
      comps.forEach(item => {
        if (item.selected && !status) {
          item.checked = false;
        } else if (item.selected && status) {
          item.checked = true;
        }
        if (item.selected) {
          changeComps.push(item.name)
        }
        delete item.selected;
      })
      this.props.changeCompsChecked(changeComps, status, record);
      this.props.save();
    }
  }

  selectAll = (e, checked, disabled) => {
    e.stopPropagation();
    if (!disabled) {
      return;
    }
    let { comps } = this.state;
    let selectNames = [];
    comps.map(item => {
      if (item.selected) {
        selectNames.push(item.name);
      }
      return null;
    })
    if (selectNames && selectNames.length > 0) {
      return;
    }
    comps.forEach(item => {
      item.checked = checked
    });
    this.setState({
      comps
    });
    const { record } = this.props;
    let changeComps = comps.map(item => item.name);
    this.props.changeCompsChecked(changeComps, checked, record);
    this.props.save();
  }

  splitComponent = (e, disabled) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    let { record } = this.props;
    let { comps } = this.state;
    let selectedComps = comps.filter(item => item.selected);
    this.props.splitComps(selectedComps, record);
    comps.forEach(item => {
      delete item.selected;
    });
    this.setState({
      comps
    });
    this.props.save();
  }

  removeAll = (e, disabled) => {
    e.stopPropagation();
    if (!disabled) {
      return;
    }
    let { comps } = this.state;
    let selectNames = [];
    comps.map(item => {
      if (item.selected) {
        selectNames.push(item.name);
      }
      return null;
    })
    if (selectNames && selectNames.length > 0) {
      return;
    }
    comps.forEach(item => {
      item.removed = true;
    });
    this.setState({
      comps
    });
    this.props.changeDecapRemove([...comps], this.props.record);
    this.props.save();
  }

  changeRemoved = (e, disabled) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    let { record } = this.props;
    let { comps } = this.state;
    comps.forEach(item => {
      if (item.selected) {
        item.removed = true;
      }
      delete item.selected;
    })
    this.props.changeDecapRemove([...comps], record);
    this.props.save();
  }

  openHelp = (e) => {
    const { openHelp } = this.state;
    this.setState({
      openHelp: !openHelp
    })
  }

  helpRender = () => {
    const { mac } = this.state;
    const { record } = this.props;
    const keyDown = `${mac ? 'Command' : 'Ctrl'}`;
    const notRemoved = record.usage !== "Removed" ? true : false;
    const cap = record.usage === 'Cap' ? true : false;
    return <div id='tab-help-main'>
      <div className='tab-help-item'> -
        <span> Click SA / UA {notRemoved && cap ? "/ RA " : ""} </span>
        to toggle all components status (Stuff / Unstuff{notRemoved && cap ? " / Removed" : ""}).</div>
      {notRemoved && <div className='tab-help-item'> -
        <span> Double click </span>
        component name to toggle single component status (Stuff / Unstuff).</div>}
      <div className='tab-help-item'> -
        <span> {keyDown} + Click </span>
        component names, and then <span>click the Stuff / Unstuff
          {notRemoved ? " / Split" : ""}
          {cap ? " / Remove" : ""}
        </span> button.</div>
      <div className='tab-help-item'>-
        <span> Open PCB Layout, {keyDown} + Click </span>
        component names, and then <span>click the View</span> button.</div>
    </div>
  }

  render() {
    const { compsName, record, select, popupContainer, isPCBOpened } = this.props;
    const pcbOpened = isPCBOpened(record);
    const { keyCode, visible, comps, mac, key, openHelp, disableDoubleClick } = this.state;
    let selectNames = [];
    comps.map(item => {
      if (item.selected) {
        selectNames.push(item.name)
      }
      return null
    });

    let classAll = 'select-tab-popover-content',
      className = 'select-tab-popover-content tag-disabled',
      disabled = true, viewDisabled = true, splitDisabled = true,
      splitClassName = "select-tab-popover-content tag-disabled",
      viewClassName = 'select-tab-popover-content tag-disabled';

    if (selectNames && selectNames.length > 0) {
      classAll = 'select-tab-popover-content tag-disabled';
      className = 'select-tab-popover-content';
      disabled = false;
      if (selectNames.length < comps.length) {
        splitDisabled = false;
        splitClassName = "select-tab-popover-content";
      }
    }

    if (selectNames && selectNames.length > 0 && pcbOpened) {
      viewClassName = 'select-tab-popover-content';
      viewDisabled = false;
    }
    const tooltipText = `${mac ? 'Command' : 'Ctrl'} + Click component names, and then click this button.`;
    let view = null;
    if (selectNames && selectNames.length > 0 && !pcbOpened) {
      view = 'Please Open PCB Layout';
    } else {
      view = `View In Layout · Open PCB Layout, ${tooltipText}`;
    }
    return (
      <Fragment>
        <Popover
          overlayClassName='select-tag-popover'
          getPopupContainer={() => document.getElementById(popupContainer)}
          content={<Fragment>
            <Tooltip title='Stuff All' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className={classAll} onClick={(e) => this.selectAll(e, true, disabled)}>SA</span>
            </Tooltip>
            <Tooltip title='Unstuff All' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className={classAll} onClick={(e) => this.selectAll(e, false, disabled)}>UA</span>
            </Tooltip>
            {[CAP, IPD].includes(record.usage) && <Tooltip title='Remove All' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className={classAll} onClick={(e) => this.removeAll(e, disabled)}>RA</span>
            </Tooltip>}
            <Tooltip title={`Stuff · ${tooltipText}`} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className={className} onClick={(e) => this.changeStatus(e, true, disabled)}>S</span>
            </Tooltip>
            <Tooltip title={`Unstuff · ${tooltipText}`} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className={className} onClick={(e) => this.changeStatus(e, false, disabled)}>U</span>
            </Tooltip>
            {[CAP, IPD].includes(record.usage) && <Tooltip title={`Remove · ${tooltipText}`} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className={className} onClick={(e) => this.changeRemoved(e, disabled)}>R</span>
            </Tooltip>}
            {record.usage !== REMOVED && <Tooltip title={`Split Part · ${tooltipText}`} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip tag-tooltip'>
              <span className={splitClassName} onClick={(e) => this.splitComponent(e, splitDisabled)}>Split</span>
            </Tooltip>}
            <Tooltip title={view} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip tag-tooltip'>
              <span className={viewClassName} onClick={(e) => this.changeStatus(e, 'display', viewDisabled)}>
                <EyeOutlined className='select-tab-display-content' />
              </span>
            </Tooltip>
            {openHelp ? <Popover
              overlayClassName='tab-help-Popover'
              content={this.helpRender()}
              title=""
              trigger="click"
              open={openHelp}
              placement="top"
              autoAdjustOverflow={false}
              getPopupContainer={() => document.getElementById(popupContainer)}
            >
              <span className="select-tab-popover-content" onClick={(e) => this.openHelp(e)}>
                <QuestionCircleOutlined className='select-tab-display-content' />
              </span>
            </Popover>
              :
              <Tooltip title={"Help"} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip tag-tooltip'>
                <span className="select-tab-popover-content" onClick={(e) => this.openHelp(e)}>
                  <QuestionCircleOutlined className='select-tab-display-content' />
                </span>
              </Tooltip>
            }
          </Fragment>}
          open={visible}
        >
          <div className='tag-box' id='my-tag-box'
          >
            {comps.map(item =>
              <CheckTag
                key={item.name}
                className='tag-item'
                info={{
                  checked: item.checked,
                  name: item.name,
                  selected: item.selected,
                  compsName: compsName,
                  record: record,
                  changeChecked: this.changeChecked,
                  select: select,
                  keyCode: keyCode,
                  changeSelectComps: this.changeSelectComps,
                  comps: comps,
                  pcbOpened: pcbOpened,
                  key: key,
                  disableDoubleClick: disableDoubleClick
                }}
              >
                {item.name}
              </CheckTag>
            )}
          </div>
        </Popover>
      </Fragment>
    );
  }
}
export default TableTag;