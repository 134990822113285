import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import '@/publicCss/style.css';
import './index.css';

class Setting extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }


  close = (e) => {
    e.stopPropagation();
    this.props.closePanel("help", false);
  }

  render() {
    const { product } = this.props;
    const content = (
      <Panel
        className='pre-layout-help-panel'
        title='Pre Layout Help'
        zIndex={2000}
        width={800}
        height={200}
        minWidth={300}
        minHeight={200}
        position='panel-center'
        onCancel={this.close}
        overflow='hidden'
        draggable
      >
        <div className='pre-layout-help-content'>
          <img src={`/lib/imgs/svgImgs/${product}Guide.svg`} alt="" />
        </div>
      </Panel>)

    return createPortal(content, this.dialogRoot);
  }
}

export default Setting;