import React, { Component, createRef } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import Panel from '../Panel';
import { Input, message } from 'antd';
import { updatePassword } from '@/services/api/users';
import userData from '@/services/helper/data/userData';
import './index.css';

const FormItem = Form.Item;

class AccountEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.form = createRef();
  }

  panelClose = () => {
    this.props.closeModal()
  }

  compareToFirstPassword = (rule, value) => {
    const form = this.form.current;
    return new Promise((resolve, reject) => {
      if (value && value !== form.getFieldValue('password')) {
        reject('Two passwords that you enter is inconsistent!');
      } else {
        resolve();
      }
    });
  }

  validateToNextPassword = (rule, value) => {
    return new Promise((resolve, reject) => {
      if (value) {
        resolve();
      }
    });
  }

  updatePassword = (e) => {
    e.preventDefault();
    const form = this.form.current;
    form.validateFields().then(values => {
      const userId = userData.getUserId();
      updatePassword({ userId, ...values }).then(res => {
        message.success('The password is changed successfully. Please log in again!')
          .then(() => {
            this.props.logout();
            this.props.closeModal();
          })
      }, error => {
        message.error('Failed to change password, please try again!')
      });
    })
  }

  render() {
    const { closeModal } = this.props;
    let footer;
    footer = <Button className='develop-settingBtn' type="primary" onClick={this.updatePassword} >Update</Button>
    const formItemLayout = {
      labelCol: { span: 0, display: 'none' },
      wrapperCol: { span: 24 },
    };

    return (
      <Panel
        className='account-panel'
        title={<div className='development-name'>Account</div>}
        onCancel={closeModal}
        zIndex={2000}
        width={400}
        position='panel-center-top'
        mask={true}
        footer={footer}
        draggable
        minWidth={200}
        minHeight={250}
      >
        <div className='develop-content'>
          <div className='develop-content-item account-password'>
            <span className='account-password-title'>Password</span>
            <Form ref={this.form}>
              <FormItem {...formItemLayout} className='account-password-item-1' name='password' validateTrigger='onBlur' rules={[
                {
                  required: true,
                  message: 'Password should be between 8 and 16 characters and must contain numbers and uppercase and lowercase letters!',
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,16}$/
                },
                {
                  validator: this.validateToNextPassword
                }]}>
                <Input.Password
                  type="password"
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="New password"
                />
              </FormItem>
              <FormItem {...formItemLayout} className='account-password-item-1' name='confirmPassword' validateTrigger='onBlur' rules={[{
                required: true, message: 'Please confirm your password!',
              }, {
                validator: this.compareToFirstPassword,
              }]}>
                <Input.Password
                  type="password"
                  onBlur={this.handleConfirmBlur}
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Confirm password"
                  onPressEnter={this.checkComfirmPassword}
                />
              </FormItem>
            </Form>
          </div>
        </div>
      </Panel>
    );
  }
}

export default AccountEdit;