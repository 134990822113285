import {
  UPDATE_LIBRARY_MENU,
  DELETE_LIBRARY,
  SAVE_EDITED_LIBRARY,
  OPEN_LIBRARY_FOLDER
} from "./actionTypes";

export const updateLibraryMenu = () => ({
  type: UPDATE_LIBRARY_MENU
});

export const deleteLibrary = (itemData) => ({
  type: DELETE_LIBRARY,
  itemData
});

export const saveEditedLibrary = (library) => ({
  type: SAVE_EDITED_LIBRARY,
  library
});

export const openLibraryFolder = (itemData, libraryType, filesInfo) => ({
  type: OPEN_LIBRARY_FOLDER,
  itemData,
  libraryType,
  filesInfo
})