import React, { Component, Fragment } from "react";
import { CloseCircleFilled, CloseOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Popover, Input, Select } from "antd";
import { getPopoverPlacement } from "@/services/helper/htmlElement";
import { getDisplayPortList, updateConnectorPinPort, updateConnectionPinMap } from "@/services/helper/connectorHelper";
import { getPanelMaxHeight, getPanelMaxWidth } from '@/services/helper/panelSizeHelper';
import { SIERRA } from '@/constants/pageType';
import { TOUCHSTONE } from "../../constants/libraryConstants";
import { getPinNetWidth } from '../../services/helper/connectorHelper';
import ConnectionPinMapPanel from './ConnectionPinMapPanel';
import "./index.css";

const { Option } = Select;
class PinPortRender extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editNode: "",
      selectFile: { key: "", label: "", type: "", subckt: "" },
      maxWidth: 600,
      maxHeight: 600,
    }
    this.dialogRoot = document.getElementById('root');
  }

  selectNodesFile = (key) => {
    this.setState({
      selectFile: key
    })
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: "",
      searchValue: "",
      /*   selectFile: { key: "", label: "" } */
    })
  }

  selectPinPort = ({ port, pin, type, currentPortInfo }) => {
    //type -> pin type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
    const { portsObj, connector1, connector2 } = this.props;
    const libraryId = currentPortInfo.libraryId;
    //find current libraryId ports
    const ports = portsObj[libraryId] ? portsObj[libraryId] : [];
    if (ports.length === 0) {
      return;
    }
    const { _connector1, _connector2 } = updateConnectorPinPort({
      port,
      modelId: libraryId,
      pin,
      type,
      connector1,
      connector2,
    })
    this.props.updateConnector({
      connector1: _connector1,
      connector2: _connector2
    });
    this.setState({
      searchValue: port
    })
    this.closeNodesSelection();
  }

  deleteSelectedPort = (e, pin, type) => {
    e && e.stopPropagation();
    const { connector1, connector2 } = this.props;
    const { _connector1, _connector2 } = updateConnectorPinPort({
      pin,
      type,
      connector1,
      connector2,
      port: "",
      modelId: ""
    })
    this.props.updateConnector({
      connector1: _connector1,
      connector2: _connector2
    });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 360),
      maxWidth: getPanelMaxWidth(offset, 830)
    })
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const PopoverRoot = document.getElementsByClassName('connection-port-select-Popover')[0];
    const selectRoot = document.getElementsByClassName('connection-ports-list-file-dropdown')[0];

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || (PopoverRoot && !PopoverRoot.contains(target) && (!selectRoot || !selectRoot.contains(target)))) {
      this.closeNodesSelection();
    }
  }


  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  nodeSelectRender = (pin, type, port, currentItem) => {
    //type -> pin type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
    const { searchValue, selectFile, maxWidth, maxHeight } = this.state;
    const { portsObj, pinList, } = this.props;
    const _portList = getDisplayPortList({ currentItem, type, searchValue, portsObj, selectFile, pinList });
    const selectFileList = this.getSelectedModels(type);
    return (
      <div className='connection-ports-content' style={{ maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200, maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100 }}>
        <div className='connection-ports-content-header'>
          <span>Component Pin {pin}</span>
        </div>
        <div className='connection-ports-content-close' onClick={() => this.closeNodesSelection()}>
          <CloseOutlined className='connection-ports-content-close-icon' />
        </div>
        <div className="connection-port-list-body-with-search">
          <div className="connection-port-list-body-search-wrapper">
            <Select
              placeholder={`Select File`}
              className='connection-ports-list-file-select'
              allowClear
              labelInValue
              value={selectFile}
              popupClassName='connection-ports-list-file-dropdown'
              popupMatchSelectWidth={false}
              onChange={option => option && this.selectNodesFile({ label: option.label, key: option.value }, selectFileList)}
            >
              {selectFileList.map(item => (
                <Option
                  key={item.libraryId}
                  title={item.file}
                >
                  {item.subckt ? `${item.file}::${item.subckt}` : item.file}
                </Option>
              ))
              }
            </Select>
            <Input
              placeholder={`Search Port`}
              allowClear
              suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='connection-port-list-ul' style={{ maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100 }}>
              {_portList.map(item =>
                <li
                  key={item.port}
                  title={item.info}
                  className={item.port === port && item.select ? 'current-pin-selected-port-li' : (item.select ? 'port-li-selected' : '')}
                  onClick={!item.select ? () => this.selectPinPort({ port: item.port, pin, type, currentPortInfo: item }) : null}
                >
                  {item.info !== item.port ? <Fragment>{item.port}&nbsp;&nbsp;&nbsp;&nbsp;{item.info}</Fragment> : item.port}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  openSelectNode = ({ pinPort, pinType, cssId, libraryId }) => {
    const { maxWidth, maxHeight } = this.props;
    const { leftPlacement, rightPlacement } = getPopoverPlacement({ cssId, type: pinType, maxWidth, maxHeight })
    const selectModels = this.getSelectedModels(pinType);
    let file = selectModels.find(item => item.libraryId === libraryId);
    if (!file) {
      file = selectModels.length ? { libraryId: selectModels[0].libraryId, file: selectModels[0].file, type: selectModels[0].type, subckt: selectModels[0].subckt }
        : { libraryId: "", file: "", type: "", subckt: "" }
    }
    this.setState({
      leftPlacement,
      rightPlacement,
      editNode: cssId,
      searchValue: pinPort,
      selectFile: { key: file.libraryId, label: file.file, type: file.type, subckt: file.subckt },
      openPinType: pinType
    })
  }

  getSelectedModels = (type) => {
    const { connector1Models, connector2Models, cableModels } = this.props;
    let options = [];
    if (type.match("Cable")) {
      options = cableModels;
    }
    else if (type.match("pinL")) {
      options = connector1Models;
    }
    else if (type.match("pinR")) {
      options = connector2Models;
    }
    options = options || [];
    return JSON.parse(JSON.stringify(options)).filter(item => !!item.libraryId && (item.type === TOUCHSTONE || !!item.subckt));
  }

  signalConnPinMap = (signal, item) => {
    const { connector1, connector2, channel1DesignId, channel2DesignId } = this.props;
    this.connectorPinMapInfo = {
      connectorL: { comp: connector1.component, designId: channel1DesignId },
      connectorR: { comp: connector2.component, designId: channel2DesignId }
    }
    this.setState({
      connectionPinMap: signal
    })

  }

  closeConnectorPinMap = (pinMap) => {
    this._updateConnectionPinMap({
      pinMap,
      signal: this.state.connectionPinMap.signal
    })
    this.connectorPinMapInfo = null;
    this.setState({
      connectionPinMap: null
    })
  }

  _updateConnectionPinMap = ({ pinMap, signal }) => {
    const { connector1, connector2 } = this.props;
    const { _connector1, _connector2 } = updateConnectionPinMap({
      pinMap,
      signal,
      connector1,
      connector2
    })
    this.props.updateConnector({
      connector1: _connector1,
      connector2: _connector2
    });
  }

  render = () => {
    const { pinList, connector1Models = [], connector2Models = [], cableModels = [],
      product, top, CONNECTION_INDEX, nameDisplayBelow } = this.props;
    const { leftPlacement, rightPlacement, connectionPinMap } = this.state;
    const fontSize = product === SIERRA ? 14 : 12;
    const { leftNetWidth, leftPinWidth, rightNetWidth, rightPinWidth } = getPinNetWidth(pinList, fontSize);
    return (
      <Fragment>
        <div className='connection-pin-port-content' style={{ top: top || 72 }}>
          <ul className="connection-pin-list-ul">
            {pinList.map((signal, pinListIndex) =>
              <div key={`${signal.signal}-${pinListIndex}`} className="connection-pin-list-signal">
                {signal.pins.map((item, index) =>
                  <div className="connection-pin-connect-port-item" key={index}>
                    {/* pin L connection */}
                    <div className='connection-pin-l-value-box'>
                      {item.pinL ?
                        this.pinLDisplay({
                          item,
                          models: connector1Models,
                          placement: leftPlacement,
                          signal,
                          index,
                          leftNetWidth,
                          leftPinWidth,
                          pinListIndex,
                          pinList,
                          pins: signal.pins,
                          CONNECTION_INDEX,
                          nameDisplayBelow,
                          product
                        }) : null}
                    </div>
                    {/* cable port */}
                    <div className='connection-pin-cable-value-box'>
                      {/* connection signal */}
                      {item.pinL && item.pinR ? <Fragment>
                        {index === 0 && signal.signal && (product === SIERRA || (signal.channel1_signal && signal.channel2_signal)) ?
                          <div
                            className='connection-pin-list-connection-signal-title'
                            style={product === SIERRA ? { height: 50, lineHeight: '50px', top: -42 } : {}}
                          >
                            <span title={signal.signal}>{signal.signal}</span>
                            {signal.pins.length > 1 && product === SIERRA ?
                              <EditOutlined
                                title="Edit connection pin map"
                                className="connection-pin-map-edit-icon"
                                onClick={() => this.signalConnPinMap(signal, item)} />
                              : null}
                          </div> : null}
                        {/* cable port */}
                        {cableModels && cableModels.length && cableModels.find(item => !!item.libraryId) ? <Fragment>
                          {this.portPopover({
                            pinItem: item,
                            pin: item.pinL,
                            pinPort: item.pinLExternalMap.cablePort,
                            libraryId: item.pinLExternalMap.cableModelId,
                            pinType: "pinLExternalCable",
                            placement: leftPlacement
                          })}
                          {this.portPopover({
                            pinItem: item,
                            pin: item.pinR,
                            pinPort: item.pinRExternalMap.cablePort,
                            libraryId: item.pinRExternalMap.cableModelId,
                            pinType: "pinRExternalCable",
                            placement: leftPlacement
                          })}</Fragment> : null}
                      </Fragment> : null}
                    </div>
                    {item.pinL && item.pinR ? <div className='connection-pin-long-line'></div> : null}
                    {/* pin R connection */}
                    <div className='connection-pin-r-value-box'>
                      {item.pinR ?
                        this.pinRDisplay({
                          item,
                          models: connector2Models,
                          placement: rightPlacement,
                          signal,
                          index,
                          rightNetWidth,
                          rightPinWidth,
                          pinListIndex,
                          pinList,
                          pins: signal.pins,
                          CONNECTION_INDEX,
                          nameDisplayBelow,
                          product
                        }) : null}
                    </div>
                  </div>)}
              </div>)}
          </ul>
        </div>
        {connectionPinMap ? <ConnectionPinMapPanel
          connectionPinMap={connectionPinMap}
          {...this.connectorPinMapInfo || {}}
          closePanel={this.closeConnectorPinMap}
        /> : null}
      </Fragment>
    );
  }

  portPopover = ({ pinItem, pin, pinPort, pinType, libraryId, placement }) => {
    const { editNode } = this.state;
    const cssId = `${pin}::${pinType}`;

    if (editNode === cssId) {
      return <Popover
        overlayClassName='connection-port-select-Popover'
        content={this.nodeSelectRender(pin, pinType, pinPort, pinItem)}
        title=""
        trigger="click"
        open={true}
        placement={placement ? placement : "top"}
      >{this.connectorPinPortInput({
        pin,
        pinPort,
        pinType,
        cssId,
        libraryId
      })}</Popover>
    }

    if (pinPort) {
      return (
        <div
          className='connection-pin-input connection-pin-input-value'
          id={cssId}
          title={pinPort}
          onClick={() => this.openSelectNode({ pin, pinPort, pinType, cssId, libraryId })}
        >
          <div className='connection-pin-input-pinport'>{pinPort}</div>
          <CloseCircleFilled
            className='connection-pin-node-delete-icon'
            onClick={(e) => { this.deleteSelectedPort(e, pin, pinType) }} />
        </div>
      );
    }

    return this.connectorPinPortInput({
      pin,
      pinPort,
      pinType,
      cssId,
      libraryId
    })
  }

  connectorPinPortInput = ({
    pin,
    pinPort,
    pinType,
    cssId,
    libraryId
  }) => {
    return <Input
      className='connection-pin-input'
      placeholder="Port"
      value={pinPort}
      id={cssId}
      onClick={() => this.openSelectNode({ pin, pinPort, pinType, cssId, libraryId })}
    />
  }

  pinLDisplay = ({ item, placement, models, leftNetWidth, leftPinWidth, product, CONNECTION_INDEX, nameDisplayBelow }) => {
    const showPort = (item.pinLPort || (models.length && models.find(item => !!item.libraryId)));
    return <Fragment>
      {item.pinLNet ? <Fragment>
        {nameDisplayBelow ? <div className='connection-pin-placeholder-box' style={{ width: 18 }}></div> : null}
        <div className={nameDisplayBelow ? 'connection-pin-net-box connection-pin-net-l-name' : 'connection-pin-net-box'}
          style={{ width: leftNetWidth + 22, maxWidth: product === SIERRA && showPort ? `calc(100% - 300px)` : `calc(100% - 200px)` }}
          title={item.pinLNet}>{item.pinLNet}</div>
      </Fragment> : null}

      <div className='connection-pin-long-line'></div>
      {/* Andes */}
      {/* {index === 0 && signal.channel1_signal && item.pinL ?
        <div className='connection-pin-list-channel1-signal-title'>
          {signal.channel1_signal}
        </div> : null} */}
      {/* pin_L */}

      <div className="connector-model-pinName-box">
        {item.pinL ? <Fragment>
          <div className='connection-pin-l-box' style={{ width: leftPinWidth + 22 }} title={item.pinL}>{item.pinL}</div>
        </Fragment> : null}

        {showPort ? <Fragment>
          {this.portPopover({
            pinItem: item,
            pin: item.pinL,
            pinPort: item.pinLPort,
            libraryId: item.pinLModelId,
            pinType: "pinL",
            placement
          })}
        </Fragment> : null}

        <div className={`connector-model-box-left connector-model-box-left-${CONNECTION_INDEX}`}></div>

        {((item.pinLExternalMap && item.pinLExternalMap.port) || (models.length && models.find(item => !!item.libraryId))) ? <Fragment>
          {this.portPopover({
            pinItem: item,
            pin: item.pinL,
            pinPort: item.pinLExternalMap.port,
            libraryId: item.pinLExternalMap.modelId,
            pinType: "pinLExternal",
            placement
          })}
        </Fragment> : null}
      </div>


    </Fragment>
  }

  pinRDisplay = ({ item, placement, models, rightNetWidth, rightPinWidth, product, CONNECTION_INDEX, nameDisplayBelow }) => {
    const showPort = (item.pinRPort || (models.length && models.find(item => !!item.libraryId)))
    return <Fragment>
      <div className='connection-pin-long-line'></div>

      {/* port */}
      <div className="connector-model-pinName-box">
        {((item.pinRExternalMap && item.pinRExternalMap.port) || (models.length && models.find(item => !!item.libraryId))) ? <Fragment>
          {this.portPopover({
            pinItem: item,
            pin: item.pinR,
            pinPort: item.pinRExternalMap.port,
            libraryId: item.pinRExternalMap.modelId,
            pinType: "pinRExternal",
            placement
          })}
        </Fragment> : <div></div>}
        <div className={`connector-model-box-right connector-model-box-right-${CONNECTION_INDEX}`}></div>
        {showPort ? <Fragment>
          {this.portPopover({
            pinItem: item,
            pin: item.pinR,
            pinPort: item.pinRPort,
            libraryId: item.pinRModelId,
            pinType: "pinR",
            placement
          })}
        </Fragment> : null}

        {item.pinR ? <Fragment>
          <div className='connection-pin-r-box' style={{ width: rightPinWidth + 22 }} title={item.pinR}>{item.pinR}</div>
        </Fragment> : null}
      </div>

      {/* pin_R */}
      {/* Andes */}
      {/* {index === 0 && signal.channel2_signal && item.pinR ?
        <div className='connection-pin-list-channel2-signal-title'>
          {signal.channel2_signal}
        </div> : null} */}
      {item.pinRNet ? <Fragment>
        <div className={nameDisplayBelow ? 'connection-pin-net-box connection-pin-net-r-name' : 'connection-pin-net-box'}
          style={{ width: rightNetWidth + 22, maxWidth: product === SIERRA && showPort ? `calc(100% - 300px)` : `calc(100% - 200px)` }}
          title={item.pinRNet}>{item.pinRNet}</div>
        {nameDisplayBelow ? <div className='connection-pin-placeholder-box' style={{ width: 18 }}></div> : null}
      </Fragment> : null}
    </Fragment>
  }
}

export default PinPortRender;