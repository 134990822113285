import { getPicInRockyVerification, getEyeDiagram, getAMIPicInRockyVerification } from '../../../../api/Rocky/rockyCtrl';

class DdrResultEyePic {
  constructor() {
    // eye_diagram.png;
    this.eyeDataDataList = new Map(); // key - name of the signal, value - EyeParamDataItem
  }

  getSize() {
    return this.eyeDataDataList.size;
  };

  getData() {
    return this.eyeDataDataList;
  };

  updateData(newData) {
    this.eyeDataDataList = newData;
  }

  // patterns
  readAllPatternEyePicFiles(channelId, victimNets, type, fileName) {
    const _type = type === 'write' ? 'Write' : 'Read';
    return new Promise((resolve, reject) => {
      const allParam = Promise.all(victimNets.map((victimNet) => this.readPatternEyePicFile({ channelId, victimNet: victimNet.name, type: _type, fileName, memory: victimNet.memory, victimNetName: victimNet.victimNet })));
      allParam.then(() => {
        resolve(this.eyeDataDataList);
      });
    });
  }

  //patterns
  readPatternEyePicFile({ channelId, victimNet, type, fileName, memory, victimNetName }) {
    return new Promise((resolve, reject) => {
      getEyeDiagram(channelId, victimNetName, type, fileName, memory).then(response => {
        if (response && response.data) {
          let haveData = true;
          try {
            const uint8Array = String.fromCharCode.apply(null, new Uint8Array(response.data));
            const jsonData = JSON.parse(uint8Array);
            if (jsonData && jsonData.data === null) {
              haveData = false;
            }
          } catch (e) {
          }
          const url = 'data:image/png;base64,' + btoa(
            new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          if (haveData) {
            this.eyeDataDataList.set(victimNet, url);
            resolve(url);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      }, error => {
        console.error(error);
        resolve(null);
      });
    });
  }


  // signals -[{ name, path }]
  readAllEyePicFiles(channelId, signals = []) {
    return new Promise((resolve, reject) => {
      const allParam = Promise.all(signals.map((signal) => this.readEyeParamFile({ name: signal.name, path: signal.path, channelId, train: signal.train })));
      allParam.then(() => {
        resolve(this.eyeDataDataList);
      });
    });
  }

  //signals -[{name,path}] 
  readEyeParamFile({ name, path, channelId, train }) {
    const fileName = train ? '/eye_diagram_adjusted.png' : '/eye_diagram.png';
    const filePath = path + fileName;
    return new Promise((resolve, reject) => {
      getPicInRockyVerification(channelId, filePath).then(response => {
        if (response.data) {
          const url = 'data:image/png;base64,' + btoa(
            new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          this.eyeDataDataList.set(name, url);
          resolve(url);
        } else {
          resolve(null);
        }
      }, error => {
        console.error(error);
        resolve(null);
      });
    });
  }

  //AMI
  readAllAMIEyePicFiles({ signals, channelId, type, verificationId }) {
    return new Promise((resolve, reject) => {
      const allParam = Promise.all(signals.map((signal) => this.readAMIEyeParamFile({ signalName: signal.name, channelId, type, verificationId })));
      allParam.then(() => {
        resolve(this.eyeDataDataList);
      });
    });
  }

  //AMI
  readAMIEyeParamFile({ signalName, channelId, type, verificationId }) {
    const fileName = 'eye_diagram.png';
    return new Promise((resolve, reject) => {
      getAMIPicInRockyVerification(signalName, channelId, type, fileName, verificationId).then(response => {
        if (response.data && response.data.byteLength) {
          const url = 'data:image/png;base64,' + btoa(
            new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          this.eyeDataDataList.set(signalName, url);
          resolve(url);
        } else {
          resolve(null);
        }
      }, error => {
        console.error(error);
        resolve(null);
      });
    });
  }

  // name - signal name or All
  getEyeParamData(name) {
    this.eyeDataDataList.get(name);
  }
};

export default DdrResultEyePic;