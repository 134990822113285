import {
  getAndesV2ChannelListByDesignId,
  createAndesV2Channel,
  deleteAndesV2ChannelByChannelId,
  getAndesV2Detail,
  updateAndesV2Detail,
  AndesCopyChannel,
  getAndesChannelReportConfig,
  saveAndesChannelReportConfig,
  generateAndesChannelReport,
  getAndesChannelReport,
  getAndesReportStatus
} from "../../api/Andes_v2/channel";
import { getResultCurveData } from "../../api/Andes_v2/result";
import { saveDesignClipInfo } from "../../api/Ansys";
import checkError from "../../api/checkError";
import apiProcess from "../../api/utility";

/**
 * get channel list by designId
 * 2021.03.24
 * @param {string} designId
 * @return promise
 */
function getChannelListByDesignId(designId) {
  return apiProcess(getAndesV2ChannelListByDesignId, designId);
}

/**
 * create channel
 * 2021.03.24
 * @param {object} channel
 * {
  "config": {},
  "content": {},
  "designId": "string",
  "designVersion": 0,
  "id": "string",
  "name": "string",
  "type": "string",
  "version": "string"
}
* @return promise
*/
function createChannelPromise(channel) {
  return apiProcess(createAndesV2Channel, channel);
}

/**
 * delete channel by channelId
 * @param {string} channelId
 * @return promise
 */
function deleteChannelByChannelId(channelId) {
  return apiProcess(deleteAndesV2ChannelByChannelId, channelId);
}

/** 
 * get channel content by channelId
 * 2021.03.26
 * @param {string} id -> channelId
 * @return promise
 */
function getChannelContentPromise(id) {
  return apiProcess(getAndesV2Detail, id);
}

/** 
 * update channel content or rename channel
 * 2021.03.26
 * @param {object} content -> channel content
 * {
  "config": {},
  "content": {},
  "designId": "string",
  "designVersion": 0,
  "id": "string",
  "name": "string",
  "type": "string",
  "version": "string"
 * }
 * @return promise
 */
function updateChannelContentPromise(content) {
  return apiProcess(updateAndesV2Detail, content);
}

function getResultCurveDataPromise(params) {
  return apiProcess(getResultCurveData, params);
}


/** 
 * channel copy
 * @param {String} channelId 
 * @param {String} name
 * 2021.07.12
*/
function copyPCBChannel(name, channelId) {
  return apiProcess(AndesCopyChannel, { name, channelId });
}

/**
 * Save clip design info
 *
 * @param {*} info 
 * @return {*} 
 * 2021.12.21
 */
function saveClipDesignPromise(info) {
  return apiProcess(saveDesignClipInfo, info)
}

/**
 * get channel report config
 * 2022/04/06
 * @param {String} channelId
 * @param {String} projectId
 *  */
function getChannelReportConfig({ projectId, channelId }) {
  return apiProcess(getAndesChannelReportConfig, { projectId, channelId });
}

/** 
 * save report config
 * 2022/04/06
 * @param {String} channelId
 * @param {String} projectId
 * @param {Object} config
 */
function saveReportConfigPromise({ projectId, channelId, config }) {
  return apiProcess(saveAndesChannelReportConfig, { projectId, channelId, config });
}

/**
 * Generate channel report
 * 2022/04/06
 * @param {*} param
 * @returns
 */
function generateChannelReport(projectId, param) {
  return apiProcess(generateAndesChannelReport, { projectId, param }, false, true)
};

function getReportStatus({ projectId, channelId }) {
  return apiProcess(getAndesReportStatus, { projectId, channelId }, false, true)
};

function getReportData({ projectId, channelId, format, mime }) {
  return new Promise((resolve, reject) => {
    if (!projectId) {
      resolve(null);
      return;
    }
    getAndesChannelReport({ projectId, channelId, format }).then(res => {
      resolve(`data:${mime};base64,` + btoa(
        new Uint8Array(res.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      ))
    }, error => {
      resolve(null);
      checkError(error, false);
    })
  })
};

export {
  getChannelListByDesignId,
  createChannelPromise,
  deleteChannelByChannelId,
  getChannelContentPromise,
  updateChannelContentPromise,
  getResultCurveDataPromise,
  copyPCBChannel,
  saveClipDesignPromise,
  getChannelReportConfig,
  saveReportConfigPromise,
  generateChannelReport,
  getReportStatus,
  getReportData
}