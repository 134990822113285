import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Monitor from '@/components/Monitor/detailMonitor'
import './style.css';

class DetailMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null
    }
    this.scrollRef = createRef();
  }

  render() {
    let debugVRMMonitor = [];
    return (
      <Monitor
        {...this.props}
        debugVRMMonitor={debugVRMMonitor} />
    )
  }
}

const mapState = (state) => {
  const { monitorScreenInfo: {
    projectName, verificationName }
  } = state.MonitorInfoReducer;
  return {
    projectName,
    verificationName
  }
}

export default connect(mapState, null)(DetailMonitor);