import React, { Component, Fragment, createRef } from 'react';
import { Row, Col } from 'antd';
import { TitleRender } from './TitleRender';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';

class DetailMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null,
      topWidth: 666
    }
    this.scrollRef = createRef();
  }


  scrollToBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "auto" });
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount() {
    const { tabVisible } = this.props;
    this.screenChange();
    tabVisible && this.scrollToBottom();
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
    const { topWidth } = this.state;
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
  }
  render() {
    const { projectName, subName, debugVRMMonitor } = this.props;
    const { topWidth } = this.state;
    return (
      <Fragment>
        <div className='aurora-simulation-title'>
          <Row className='my-aurora-monitor-holygrail' span={24}>
            <Col className='my-aurora-middle'
              span={20}>
              {TitleRender({
                projectName,
                waitingIndex: -1,
                subName,
                topWidth
              })}
            </Col>
          </Row>
        </div>
        <div className='my-aurora-monitor'>
          <pre className="my-aurora-monitor-pre">
            {debugVRMMonitor && debugVRMMonitor.length > 0 && <ul className='pdn-monitor-ul' style={{ margin: 0 }}>
              {debugVRMMonitor.length && debugVRMMonitor.map((item, index) =>
                <li key={'debug_' + index} style={{ listStyle: 'none', width: '100%' }}>
                  <span style={{ margin: 0 }}>{item}</span>
                </li>)}
            </ul>}
          </pre>
          <div ref={(el) => { this.scrollRef = el; }}></div>
        </div>
      </Fragment>
    )
  }
}

export default DetailMonitor