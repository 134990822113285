import Viewer, { CurveItem } from '../../Result/Public/sparameter/PlotView';
import { unitChange } from '../../helper/mathHelper';
import { select, selectAll } from 'd3-selection';
import { numberCheck } from '../../helper/dataProcess';
import { CPHY_EYE_MASK } from '../../../constants/libraryConstants';

function drawPlot(param, plot2d) {
  param.showCurves().then((curves) => {
    plot2d.updateCurves(curves, true);
    plot2d.setCurveColors();
    plot2d.resetPlot();
    plot2d.updateAxisRange('y', { start: plot2d.options.yMin, end: plot2d.options.yMax });
    plot2d.updateAxisRange('x', { start: plot2d.options.xMin, end: plot2d.options.xMax });

    plot2d.redrawCurves();
    selectAll('g.axis--x .tick text').attr("y", '10');
    selectAll('g.tick text').style('font-size', '12');
    selectAll('text.xlabel').style('font-size', '12');
    selectAll('text.xlabel').attr('dy', '3.2em');
    selectAll('text.ylabel').style('font-size', '12');
  })
}

function eyeMaskInfoConversion(data) {
  let splitInfoList = data.split(/[(\r\n)\r\n]+/);
  let eyeMaskParsedData = [];
  let indexValue = null, verticesValue = null, tableData = [];
  for (let i = 0; i < splitInfoList.length; i++) {
    const info = splitInfoList[i];
    if (info) {
      let value = info.split(/,\s*/).filter(item => item)
      if (value && value.length) {
        if (value.length === 1) {
          const splitValue = value[0].split(/\s\//);
          if (splitValue && splitValue.length && !numberCheck(splitValue[0])) {
            if (!indexValue || tableData.length) {
              indexValue = splitValue[0];
              verticesValue = null;
            } else if (!verticesValue) {
              verticesValue = splitValue[0];
            }
            tableData = [];
          }
        } else if (value.length === 2 && indexValue && verticesValue && !numberCheck(value[0]) && !numberCheck(value[1])) {
          tableData.push({ time: Number(value[0]), voltage: Number(value[1]) })
          if (tableData.length === Number(verticesValue)) {
            eyeMaskParsedData.push({ verticesNum: Number(verticesValue), data: [...tableData] })
            tableData = [];
            indexValue = null;
            verticesValue = null;
          }

        }
      }
    }
  }
  return eyeMaskParsedData;
}

function getDefaultConfigData(eyeMaskType) {
  if (eyeMaskType === CPHY_EYE_MASK) {
    return [{
      data: {
        "v_idth": "40 mV",
        "v_idtl": "-40 mV",
        "t_eye_ramp": 0.25,
        "t_mid": 0,
        "symbol_rate": "1 Gsps"
      }
    }]
  }

  return []
}

export {
  drawPlot,
  eyeMaskInfoConversion,
  getDefaultConfigData
}

class EyeMaskPlot {
  constructor(props) {
    this.points = new Map();
    this.plotViewer = null;
    this.data = null;
    this.id = props.id;
  }

  importData(data) {
    const newData = [];
    for (let item of data) {
      const { times, voltages } = item;
      let newTimes = [...times], newVoltages = [...voltages];
      if (newTimes.length && newTimes[0] === 0) {
        newTimes[0] = newTimes[1] ? newTimes[1] / 100 : 1e-6;
      }
      newVoltages = newVoltages.map(i => (unitChange({ num: i, oldUnit: 'V', newUnit: 'V' }).number))
      newData.push({ ...item, times: newTimes, voltages: newVoltages })
    }
    this.data = newData;
  }

  // curves -[{ parameter, fileIndex, row, col, curveType }]
  showCurves() {
    return Promise.all(this.data.map(cur => {
      let curve = new CurveItem();
      curve.x = cur.times;
      curve.y = cur.voltages;
      curve.visible = true;
      curve.id = cur.id;
      curve.file = null;
      curve.curveType = 'eyeMask';
      return curve;
    }))
  }

  createPlot(element) {
    const options = {
      background: '#fff'
    }

    this.plotViewer = new Viewer({ element, options, id: 'Andes' + this.id });
    const unit = this.data && this.data.length && this.data[0].unit ? this.data[0].unit : 'UI'
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit(unit);
    xAxis.setLabel('Time');
    xAxis.setScale('linear');
    let yAxis = this.plotViewer.getAxis('y');
    yAxis.setUnit("V");
    yAxis.setLabel('Voltage');
    // Default y scale
    yAxis.setting.scale = "linear";
    return this.plotViewer;
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }
}

export default EyeMaskPlot;
