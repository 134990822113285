import { FASTPI } from '@/constants/pageType';
import CeLine from '../../geometry/CeLine';


function autoFindPowerGNDNets(netList, product) {
  const { mVals } = netList;
  let nets = [], otherNets = [];
  let matchV = [], matchGeos = [], matchVias = [];
  for (let net of mVals) {
    if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
      continue;
    }
    if (net.mName.match(/^NONE/i)) {
      continue;
    }
    if (net.mName.match(/^(v|\+v)/i)) {
      matchV.push(net.mName);
      continue;
    };
    if (net.mGeomList.length > 0) {
      const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
      // Any geometry that is not CeLine, returns true
      const typeList = geomList.map(item => item instanceof CeLine);
      if (typeList.includes(false)) {
        matchGeos.push(net.mName);
        continue;
      }
    }
    if (net.mViaList.length > 10) {
      matchVias.push(net.mName);
      continue;
    }
    if (product !== FASTPI) {
      otherNets.push(net.mName);
    }
  };
  nets = [...new Set([...matchV, ...matchGeos, ...matchVias, ...otherNets])];
  return nets;
};

export { autoFindPowerGNDNets };