export const auroraTheme = {
  components: {
    Tooltip: {
      colorBgSpotlight: "#ffffff",
      colorTextLightSolid: "#1890ff",
      zIndexPopup: 3000
    },
    Select: {
      multipleItemBg: "#fafafa",
      multipleItemBorderColor: "#d9d9d9",
      zIndexPopup: 2001
    },
    Table: {
      headerBg: "#ffffff"
    },
    Menu: {
      zIndexPopup: 2001
    },
    Tree: {
      titleHeight: 24,
      nodeSelectedBg: "#bae7ff",
      indentSize: 10
    },
    Tabs: {
      zIndexPopup: 2001
    },
    Popover: {
      zIndexPopup: 2001
    }
  }
}