import NP from 'number-precision';

function mergeDecapGroupData(decapGroup) {
  let data = [];
  Object.keys(decapGroup).forEach(key => {
    const capGroups = decapGroup[key].map(item => {
      const { groupName, components, res, ind, ind10MHz, ind100MHz } = item;
      const capGroup = new DecapGroup({ groupName, components, resistance: res, inductance: ind, inductance_10: ind10MHz, inductance_100: ind100MHz })
      capGroup.changeResistanceTomOhm()
      capGroup.changeInductanceTopH()
      return capGroup
    })
    const _data = {
      powerNet: key,
      showTable: true,
      capGroups
    }
    data.push(_data)
  })
  return data
}

function DecapGroup({ groupName = '', components = [], resistance = '', inductance, inductance_10, inductance_100 }) {
  this.groupName = groupName
  this.components = components
  this.resistance = Number(resistance)
  this.inductance = Number(inductance)
  this.inductance_10 = Number(inductance_10)
  this.inductance_100 = Number(inductance_100)
}

DecapGroup.prototype.changeResistanceTomOhm = function () {
  if (typeof this.resistance === "number") {
    this.resistance = NP.round(NP.times(this.resistance, 1000), 3)
  }
}
DecapGroup.prototype.changeInductanceTopH = function () {
  if (typeof this.inductance === "number") {
    this.inductance = NP.round(NP.times(this.inductance, 1e12), 2)
  }
  if (typeof this.inductance_10 === "number") {
    this.inductance_10 = NP.round(NP.times(this.inductance_10, 1e12), 2)
  }
  if (typeof this.inductance_100 === "number") {
    this.inductance_100 = NP.round(NP.times(this.inductance_100, 1e12), 2)
  }
}

export {
  mergeDecapGroupData
}