const TYPE = "Cascade";

export const UPDATE_PROJECT_MENU = `${TYPE}/update_project_menu`;
export const INIT_SIMULATION_INFO = `${TYPE}/init_simulation_info`;
export const UPDATE_SIMULATION_REDUCER = `${TYPE}/update_simulation_reducer`;
export const UPDATE_MULTI_SIMULATION_INFO = `${TYPE}/update_multi_simulation_info`;
export const UPDATE_SIMULATION_INFO = `${TYPE}/update_simulation_info`;
export const GET_WORKFLOW_MONITOR = `${TYPE}/get_workflow_monitor`;
export const GET_VERIFICATION_WORKFLOW = `${TYPE}/get_verification_workflow`;
export const GET_VERIFICATION_LOG = `${TYPE}/get_verification_log`;
export const CANCEL_WORKFLOW = `${TYPE}/cancel_workfolw`;
export const DOES_SIMULATION_FAILED = `${TYPE}/does_simulation_finish`;
export const CLEAR_SIMULATION_INFO = `${TYPE}/clear_simulation_info`

export const START_DCR_SIMULATION = `${TYPE}/start_dcr_simulation`;
export const START_IR_SIMULATION = `${TYPE}/start_ir_simulation`;
export const START_IMP_SIMULATION = `${TYPE}/start_impedance_simulation`;
export const START_POWER_TREE_SIMULATION = `${TYPE}/start_power_tree_simulation`;
