import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutData from '@/services/CeLayoutDB/newCeLayoutData';
import * as canvas from '@/services/LayoutCanvas/oldCanvas';
import {
  selectLayer,
  canvasSelect,
  openPanel,
  closePanel,
  updateLocation,
  cleanStatus,
  cancelSelectedAll,
} from './store/actionCreators';
import ContextMenu from '@/components/ContextMenu';
import Panel from '@/components/Panel'
import SearchBox from './SearchBox';
import LayerTab from './LayerTab';
import MenuPanel from './MenuPanel';
import Coord from './Coord';
import Stackup from './stackup';
import StackupFooter from './stackup/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearchPlus, faSearchMinus, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import DesignInfo from './DesignInfo';
import Serdes from './Serdes';
import { Spin } from 'antd';
import { equal } from '../../services/utility/ArrayHelper';
import { getCompsAndNetsCrossPins } from '../../services/helper/dataProcess';
import './index.css';

class LayoutExplorer extends Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.locationMark = React.createRef();
    this.state = {
      metalLayers: [],
      contextMenu: {},
      searchData: [],
      loading: true,
    }
  }

  changeMouse = (x, y) => {
    this.props.updateLocation(x.toFixed(2) + ', ' + y.toFixed(2));
  }

  canvasSelect = (canvasObj) => {
    if (canvasObj && canvasObj.nets && canvasObj.comps) {
      const { leftWidth } = this.props;
      const { nets, comps, x, y, multi } = canvasObj;
      this.setState({
        contextMenu: {
          style: {
            display: 'block',
            top: y,
            left: x - leftWidth
          },
          items: [...nets, ...comps],
          onClick: (e) => {
            const name = e.target.innerText;
            const obj = { multi };
            if (nets.includes(name)) {
              obj.nets = [name];
            } else {
              obj.comps = [name];
            }
            this.props.select(obj);
            this.setState({
              contextMenu: {}
            })
          }
        }
      })
    } else {
      this.props.select(canvasObj);
      this.setState({
        contextMenu: {}
      })
    }
  }

  fitView() {
    canvas.fitView();
  }

  zoomIn() {
    canvas.zoomIn();
  }

  zoomOut() {
    canvas.zoomOut();
  }

  getComponentLength() {
    const metalLayers = LayoutData.getLayout().GetLayerManager().mMetalLayers;
    let length = 0;
    for (const layer of metalLayers) {
      const { mComponentLayer } = layer;
      if (!mComponentLayer) continue;
      const { mComponents } = mComponentLayer;
      length += mComponents.length;
    };
    return length;
  }

  componentDidMount() {
    this.props.closePanel();
    this.setState({
      loading: true,
    })
    this.props.cleanStatus();
    let _vendor = null;
    LayoutData.getLayoutInfo().then(res => {
      const { vendor } = res;
      _vendor = vendor;
    });
    LayoutData.LoadLayoutDB().then(res => {
      LayoutData.saveComponentsList();
      LayoutData.saveNetsList();
      const events = {
        click: this.canvasSelect,
        mousemove: this.changeMouse
      };
      const _LayoutData = LayoutData.getLayout();
      canvas.initLayout(this.svgRef.current, _LayoutData, this.locationMark.current, events);
      if (_vendor === 'AURORA_DB' || _vendor === 'AURORA_AAF') {
        this.generateStackup();
      }
      const _LayerManager = _LayoutData.GetLayerManager();
      const metalLayerNames = _LayerManager.GetAllMetalLayers().mValues;
      const topComp = _LayerManager.GetMetalCompLayer(metalLayerNames[0]);
      const bottomComp = _LayerManager.GetMetalCompLayer(metalLayerNames[metalLayerNames.length - 1]);
      let metalLayers = metalLayerNames;
      if (topComp) {
        metalLayers = [topComp.GetName(), ...metalLayers];
      };
      if (bottomComp) {
        metalLayers = [...metalLayers, bottomComp.GetName()];
      }
      this.setState({
        metalLayers,
        unit: _LayoutData.GetLayoutUnits()
      });
      // set the first layer's component layer to be visible
      if (this.getComponentLength() < 1000) {
        if (topComp) {
          this.props.selectLayer([topComp.GetName(), metalLayerNames[0]]);
        } else {
          this.props.selectLayer([metalLayerNames[0]]);
        }
      }
      this.setState({
        loading: false,
      })
    }, error => {
      console.log(error);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.designID !== this.props.designID) {
      this.setState({
        loading: true,
        contextMenu: {}
      })
      this.props.closePanel();
      let _vendor = null;
      LayoutData.getLayoutInfo().then(res => {
        this.props.cleanStatus();
        const { vendor } = res;
        _vendor = vendor;
      });
      LayoutData.LoadLayoutDB().then(res => {
        LayoutData.saveComponentsList();
        LayoutData.saveNetsList();
        const events = {
          click: this.canvasSelect,
          mousemove: this.changeMouse
        };
        this.svgRef.current.removeChild(this.svgRef.current.children[0])
        const _LayoutData = LayoutData.getLayout();
        canvas.initLayout(this.svgRef.current, _LayoutData, this.locationMark.current, events);
        if (_vendor === 'AURORA_DB' || _vendor === 'AURORA_AAF') {
          this.generateStackup();
        }
        const _LayerManager = _LayoutData.GetLayerManager();
        const metalLayerNames = _LayerManager.GetAllMetalLayers().mValues;
        const topComp = _LayerManager.GetMetalCompLayer(metalLayerNames[0]);
        const bottomComp = _LayerManager.GetMetalCompLayer(metalLayerNames[metalLayerNames.length - 1]);
        let metalLayers = metalLayerNames;
        if (topComp) {
          metalLayers = [topComp.GetName(), ...metalLayers];
        };
        if (bottomComp) {
          metalLayers = [...metalLayers, bottomComp.GetName()];
        }
        this.setState({
          metalLayers,
          unit: _LayoutData.GetLayoutUnits()
        });
        // set the first layer's component layer to be visible
        if (this.getComponentLength() < 1000) {
          if (topComp) {
            this.props.selectLayer([topComp.GetName(), metalLayerNames[0]]);
          } else {
            this.props.selectLayer([metalLayerNames[0]]);
          }
        }
        this.setState({
          loading: false,
        })
      }, error => {
        console.log(error);
      });
    }
  }

  generateStackup() {
    LayoutData.getStackup();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { checkedlayers: prevLayers, selectedNets: prevNets, selectedComps: prevComps, colorBy: prevColorBy, designID } = this.props;
    const { checkedlayers: layers, selectedNets, selectedComps, colorBy } = nextProps;
    if (layers !== prevLayers) {
      canvas.updateLayers(layers, prevLayers);
      // return true;
    }

    if (colorBy !== prevColorBy) {
      canvas.changeColorMode(colorBy);
      return false;
    }

    if (!equal(selectedComps, prevComps) || !equal(selectedNets, prevNets)) {
      let selectLayer = canvas.findCurrentLayer(selectedComps);
      let newLayer = Array.from(new Set([...layers, ...selectLayer]));
      const connectPins = getCompsAndNetsCrossPins({ nets: selectedNets, comps: selectedComps, designId: designID })
      if (newLayer.length !== layers.length) {
        this.props.selectLayer(newLayer);
        setTimeout(() => {
          canvas.selectNetCompPin({ nets: selectedNets, comps: selectedComps, connectPins, designId: designID })
        }, 500);
      } else {
        canvas.selectNetCompPin({ nets: selectedNets, comps: selectedComps, connectPins, designId: designID })
      }
      // return false;
    }
    return true;
  }

  onSearch = (value) => {
    const { search } = this.props;
    if (search && search.type === 'signal') {
      if (search.name !== '' && search.name.slice(0, 4) === 'Lane') {
        return canvas.searchNet(value.toLowerCase(), search.name.slice(-4));
      } else {
        return canvas.searchNet(value.toLowerCase());
      }
    } else {
      return canvas.search(value);
    }
  }

  openMenu = () => {
    this.props.openPanel('MenuPanel');
  }

  getPanel = () => {
    const { panelType, selectingSignal, closePanel, defaultLeft, defaultTop } = this.props;
    const visible = !selectingSignal;
    let title, footer, content, position, className;
    switch (panelType) {
      case 'MenuPanel':
        return <MenuPanel />
      case 'Stackup':
        footer = <StackupFooter />;
        title = 'Stackup';
        content = <Stackup />;
        className = 'stackup-panel'
        break;
      case 'SERDES':
        return <Serdes />;
      case 'General Info':
        return <DesignInfo />
      default: return null;
    }
    return (
      <Panel
        className={className}
        title={title}
        footer={footer}
        visible={visible}
        onCancel={closePanel}
        position={position}
        draggable
        left={defaultLeft}
        top={defaultTop}
        minHeight={250}
      >
        {content}
      </Panel>
    )
  }

  render() {
    const { unit, metalLayers, contextMenu } = this.state;
    const { select } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <Spin size='large' spinning={loading} delay={400}>
          <svg className='canvas' ref={this.svgRef} />
        </Spin>
        <svg className="location-mark" viewBox="0 0 24 24" ref={this.locationMark} >
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>

        <div className="btn-group-vertical">
          <button className="layBtn" title="Menu" onClick={this.openMenu}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <button className="layBtn" title="Zoom In" onClick={this.zoomIn}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </button>
          <button className="layBtn" title="Zoom Out" onClick={this.zoomOut}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </button>
          <button className="layBtn" title="Fit View" onClick={this.fitView}>
            <FontAwesomeIcon icon={faArrowsAlt} />
          </button>
        </div>
        <Coord unit={unit} className='canvas-coord' />
        {this.getPanel()}
        <SearchBox className="global-search"
          onSearch={this.onSearch}
          onSelect={select}
        />
        <LayerTab metalLayers={metalLayers} getColor={canvas.getLayerColor} />
        <ContextMenu {...contextMenu} />
      </React.Fragment>
    )
  }
}

const mapState = (state) => {
  const {
    selectedNets,
    selectedComps,
    layers: checkedlayers,
    panelType,
    search,
    colorBy,
    simulation,
  } = state.default;
  const { designID } = state.multiDefault;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return {
    selectedNets, checkedlayers, selectedComps, panelType, search, colorBy, simulation, designID, defaultLeft, defaultTop,
  }
}


const mapDispatch = (dispatch) => ({
  updateLocation(location) {
    dispatch(updateLocation(location))
  },
  select(canvasObj) {
    dispatch(canvasSelect(canvasObj))
  },
  selectLayer(checkedList) {
    dispatch(selectLayer(checkedList));
  },
  openPanel(type, designID) {
    dispatch(openPanel(type, designID))
  },
  closePanel() {
    dispatch(closePanel())
  },
  cleanStatus() {
    dispatch(cleanStatus())
  },
  cancelSelectedAll() {
    dispatch(cancelSelectedAll())
  },
})

export default connect(mapState, mapDispatch)(LayoutExplorer);