import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { Tooltip } from 'antd';
import EditableTable from '@/components/EditableTable';
import { getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import './index.css'

const ComponentsColumns = [{
  title: 'Part Name',
  dataIndex: 'part',
  width: '35'
}, {
  title: 'Components',
  dataIndex: 'selectComps',
  width: '50%',
}, {
  title: 'Quantity',
  dataIndex: 'quantity',
  width: '15%'
}];

class OptimizationPanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.state = {
      maxWidth: 800,
      maxHeight: 1000,
      keepComponents: [],
      optimizedComponents: []
    }

    ComponentsColumns[0].render = (text, record) => {
      return <Tooltip title={text} overlayClassName='aurora-tooltip'>
        <div className='pdn-result-component-part'>{text}</div>
      </Tooltip>
    };

    ComponentsColumns[1].render = (selectComps, record, index) => {
      return <span className='pdn-result-verticalAlign-middle-components-span'>{selectComps.join(', ')}</span>
    };

    ComponentsColumns[2].render = (value, record) => {
      return <span>{record.selectComps.length}</span>
    }
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 830)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getData()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  getData = () => {
    const { id, optResult, optPower, impedanceSetup } = this.props
    const currentDecap = optResult.find(item => item.name === optPower)
    if (currentDecap) {
      const { keepDecapList, optimizedDecap } = currentDecap
      const { layouts, powerDomains = [], designId, designName, type } = impedanceSetup;
      let _layouts = layouts;
      if (!_layouts) {
        _layouts = [{ powerDomains, designId, designName, type }]
      }
      let findDomain
      for (let layout of _layouts) {
        findDomain = layout.powerDomains.find(domain => domain.content.MAIN_POWER_NETS[0] === optPower)
        if (findDomain) {
          break
        }
      }

      if (findDomain) {
        const { content: { Components } } = findDomain
        const keepComponents = this.mergeDecapData(keepDecapList, Components)
        const optimizedComponents = this.mergeDecapData(optimizedDecap, Components)
        this.setState({
          keepComponents,
          optimizedComponents,
        })
      }
    }
  }

  mergeDecapData = (decapList, components) => {
    let comps = []
    decapList.forEach(decap => {
      const comp = components.find(item => item.name === decap)
      const findIndex = comps.findIndex(item => item.part === comp.part)
      if (findIndex > -1) {
        comps[findIndex].selectComps.push(decap)
      } else {
        comps.push({ part: comp.part, selectComps: [decap] })
      }
    })
    return comps
  }

  render() {
    const { closeModal } = this.props;
    const { keepComponents, optimizedComponents, maxWidth, maxHeight } = this.state;
    const content = (
      <Panel
        className='cascade-opt-result-panel'
        id='cascade-opt-result-panel'
        zIndex={100000}
        onCancel={() => closeModal()}
        width={getPanelWidth(maxWidth)}
        maxHeight={maxHeight}
        draggable
        minWidth={400}
        minHeight={200}
        position={'panel-center-left'}
        title='Optimization'
        overflow={"auto"}
      >
        <div className='cascade-opt-result-panel-main' id='cascade-result-opt-main'>
          <div style={{ background: '#f2f7fa' }} className='cascade-opt-result-panel-content-keep' id='cascade-opt-result-panel-content-keep'>
            <div className='cascade-opt-result-select-box'>
              <span className="font-bold">Current setup</span>
              <TotalDecap comps={keepComponents} />
            </div>
            <div className='cascade-opt-result-table'>
              <EditableTable
                rowKey={(record, index) => index}
                columns={ComponentsColumns}
                size="small"
                dataSource={keepComponents}
                className='cascade-opt-result-components-table'
                tableLayout="fixed"
              />
            </div>
          </div>
          <div style={{ background: 'rgb(220, 241, 253)' }} className='cascade-opt-result-panel-content-opt' id='cascade-opt-result-panel-content-opt'>
            <div className='cascade-opt-result-select-boxx'>
              <span className="font-bold">Decap optimization</span>
              <TotalDecap comps={optimizedComponents} unstuff={true} />
            </div>
            <div className='cascade-opt-result-table'>
              <EditableTable
                rowKey={(record, index) => index}
                columns={ComponentsColumns}
                size="small"
                dataSource={optimizedComponents}
                className='cascade-opt-result-components-table'
                tableLayout="fixed"
              />
            </div>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const TotalDecap = (props) => {
  let count = 0;
  if (props.comps && props.comps.length && props.comps.length !== 0) {
    for (let { selectComps } of props.comps) {
      count += selectComps.length;
    }
  }
  return count > 0 ? (
    <span className='cascade-opt-sum-title'>(<span style={{ fontWeight: 'bold' }}>{count}</span> {props.unstuff ? 'unstuffed' : 'stuffed'} decaps)</span>
  ) : null
}

export default OptimizationPanel;