import {
  RUN_MULTIPLE_BYTE,
  UPDATE_MULTIPLE_LIST,
  GET_MULTIPLE_CHANNEL_CONTENT,
  UPDATE_MULTIPLE_SELECT_VERIFICATION,
  OPEN_MULTIPLE_RESULT_CONTENT,
  DELETE_INTERFACES,
  AUTO_MULTIPLE_GET_VERIFICATIONS,
  UPDATE_MULTIPLE_STATUS,
  UPDATE_RESULT_MENU_STATUS
} from './actionType';

export const getMultipleChannelContent = (id) => ({
  type: GET_MULTIPLE_CHANNEL_CONTENT,
  id
})

export const runMultipleByte = (runInfo) => ({
  type: RUN_MULTIPLE_BYTE,
  runInfo
})

export const updateMultiList = (list) => ({
  type: UPDATE_MULTIPLE_LIST,
  list
})

export const updateMultipleSelectVerification = (id) => ({
  type: UPDATE_MULTIPLE_SELECT_VERIFICATION,
  id
})

export const openMultipleResult = () => ({
  type: OPEN_MULTIPLE_RESULT_CONTENT
})

export const deleteInterfaces = (ids) => ({
  type: DELETE_INTERFACES,
  ids
})

export const autoGetMultipleVerifications = ({ channelId }) => ({
  type: AUTO_MULTIPLE_GET_VERIFICATIONS,
  channelId
})

export const updateMultipleStatus = (status) => ({
  type: UPDATE_MULTIPLE_STATUS,
  status
})

export const updateResultMenuStatus = (status) => ({
  type: UPDATE_RESULT_MENU_STATUS,
  status
})
