import React, { Fragment } from 'react';
import {
  MICROSTRIP,
  STRIPLINE,
  VIA
} from '../../constants/libraryConstants';
import './index.css';

const tipList = [{ name: 'Microstrip', key: MICROSTRIP }, { name: 'Stripline', key: STRIPLINE }, { name: 'Via', key: VIA }];

function IconTip({ style, spanStyle }) {
  return <div className="pre-layout-tip" style={style}>
    {tipList.map(item => <Fragment key={item.key}>
      <div className="pre-layout-img-main">
        <img src={`/lib/imgs/svgImgs/${item.key}.svg`} alt="" />
      </div>
      <span style={spanStyle}>{item.name}</span>
    </Fragment>)}
  </div>
}

export default IconTip;