import {
  CLOSE_TAB_FOOTER, OPEN_TAB_FOOTER, CHANGE_TAB_MENU, CHANGE_PROJECT,
  CHANGE_PDN, CLEAN_MONITOR_MSG, DEBUG_MONITOR
} from './actionTypes';

export const closeTabFooter = () => ({
  type: CLOSE_TAB_FOOTER,
});

export const openTabFooter = () => ({
  type: OPEN_TAB_FOOTER,
});

export const changeTabMenu = ({ selectKeys, menuType, verificationId, projectId }) => ({
  type: CHANGE_TAB_MENU,
  selectKeys,
  verificationId,
  projectId,
  menuType
});

export const changeProject = (projectName) => ({
  type: CHANGE_PROJECT,
  projectName
});

export const changePdn = (pdnName) => ({
  type: CHANGE_PDN,
  pdnName
});

export const cleanTabMonitorMsg = () => ({
  type: CLEAN_MONITOR_MSG,
});

export const debugMonitorAction = (msg) => ({
  type: DEBUG_MONITOR,
  msg
})