import { CAP, COMP_REPEATER, CONNECTOR, IC, JUMPER, RES, TEST_POINT } from "../../../constants/componentType";
import { ICTypes, RLCTypes } from "../../../pages/Sierra/constants";
import { RLC_TYPES } from "../../PCBHelper";
import { GENERIC } from "../../PCBHelper/constants";
import { BasicCompModel, BasicComponent, RLCCONComponent } from "../../helper/IntegratedInterface";
import { getTextWidthAndHeight } from "../../helper/getTextWidth";
import { Connector, ConnectorPin, ICCompPinModel, ICComponent, RepeaterComponent, RepeaterPin } from "../IntegratedInterface";
import { componentTypeChange, getPairs } from "../SierraCtrl";
import { getCompTypeChange } from "../interfaceData";
import { getSchematicPreLayoutPowerNets } from "./Schematic";
import preLayoutData from "./preLayoutData";
import { PreLayoutComponent, PreLayoutPin, PreLayoutSetting } from "./prelayoutClass";
import { CANVAS_PADDING, COMP_PADDING, CONNECTOR_INDEX, GROUP_RES_DISTANCE, IC_INDEX, PORT_DISTANCE, PRE_CONNECTOR, PRE_IC, PRE_REPEATER, PRE_RES, PRE_TEST, REPEATER_INDEX, REPEATER_WIDTH, RES_INDEX, RES_WIDTH, SIGNAL_COMP_WIDTH, SIGNAL_DISTANCE, SIGNAL_TO_COMP, TEST_INDEX, TEST_WIDTH, netList, genericSignals, SCHEMATIC, MODEL } from "./prelayoutConstants";

function getCompsByPreLayoutSetting(propSetting, prevComps) {
  let components = [];
  const setting = new PreLayoutSetting(propSetting);
  const { GPIO, IC, connector, repeater, pullUpRes, testPoint, signalNumber, prelayout } = setting;
  const compTypes = prelayout === SCHEMATIC ? [IC, pullUpRes, connector] : [IC, pullUpRes, testPoint, repeater, connector];
  const signals = prelayout === SCHEMATIC ? [] : GPIO === GENERIC ? genericSignals(signalNumber) : netList[GPIO];
  let netNum = 0, compNum = 1, numList = compTypes.map(n => Number(n));
  while (numList.some(n => n > 0)) {
    let _numList = JSON.parse(JSON.stringify(numList))
    for (let i = 0; i < _numList.length; i++) {
      if (_numList[i] <= 0) {
        continue;
      }
      const prefix = getPrefixFromIndex(i);
      const type = getTypeFromIndex(i);

      if (prefix === PRE_RES) {
        for (let r = 1; r <= signals.length; r++) {
          const signal = signals[r - 1];
          const net = netNum > 0 ? `${signal}_${netNum}` : signal;
          const name = `${prefix}${compNum}${r}`;
          const pin = prelayout === MODEL ? new PreLayoutPin({ net, signal, pin: signal, pinName: signal, prelayout }) : null;
          const comp = new PreLayoutComponent({
            name,
            type,
            pins: prelayout === SCHEMATIC ? [] : [pin],
            signals: [signal],
            group: `${compNum}`
          });
          components.push(comp);
        }
        _numList[i] = _numList[i] - 1;
        continue;
      }

      const name = `${prefix}${compNum}`;
      let pins = [];
      if (prelayout === MODEL) {
        for (let signal of signals) {
          const net = netNum > 0 ? `${signal}_${netNum}` : signal;
          if (prefix === PRE_REPEATER) {
            const pin_in = new PreLayoutPin({ net, signal, pin: `${signal}_in`, pinName: `${signal}_in` })
            pins.push(pin_in)
            const pin_out = new PreLayoutPin({ net: `${signal}_${netNum + 1}`, signal, pin: `${signal}_out`, pinName: `${signal}_out` })
            pins.push(pin_out)
            continue;
          }
          const pin = new PreLayoutPin({ net, signal, pin: signal, pinName: signal });
          pins.push(pin)
        }
      }

      const comp = new PreLayoutComponent({ name, type, pins, signals, prelayout });
      components.push(comp);

      if (prefix === PRE_REPEATER) {
        netNum = netNum + 1;
      }

      _numList[i] = _numList[i] - 1;
    }
    numList = JSON.parse(JSON.stringify(_numList))
    compNum = compNum + 1;
  }

  return setModelFromPrevComps(components, prevComps)
}

function getPrefixFromIndex(i) {
  switch (i) {
    case RES_INDEX:
      return PRE_RES;
    case TEST_INDEX:
      return PRE_TEST;
    case REPEATER_INDEX:
      return PRE_REPEATER;
    case CONNECTOR_INDEX:
      return PRE_CONNECTOR;
    case IC_INDEX:
    default:
      return PRE_IC;
  }
}

function getTypeFromIndex(i) {
  switch (i) {
    case RES_INDEX:
      return RES;
    case TEST_INDEX:
      return TEST_POINT;
    case REPEATER_INDEX:
      return COMP_REPEATER;
    case CONNECTOR_INDEX:
      return CONNECTOR;
    case IC_INDEX:
    default:
      return IC;
  }
}

function reSortComponents(components, prevComps) {
  let netNum = 0, newComponents = [];
  for (let comp of components) {
    let component = new PreLayoutComponent(comp);
    let _netNum = netNum;
    if (component.type === COMP_REPEATER) {
      component.pins = component.pins.map(pin => {
        const { pinName, signal } = pin;
        const net = _netNum === 0 && pinName.includes('_in') ? signal : `${signal}_${pinName.includes('_in') ? _netNum : _netNum + 1}`;
        return new PreLayoutPin({ net: net, signal, pin: pinName, pinName: pinName })
      })
      newComponents.push(component);
      netNum = _netNum + 1;
      continue;
    }

    component.pins = component.pins.map(pin => {
      const { signal } = pin;
      return new PreLayoutPin({ net: _netNum === 0 ? signal : `${signal}_${_netNum}`, signal, pin: signal, pinName: signal })
    })
    newComponents.push(component);
  }
  return setModelFromPrevComps(newComponents, prevComps)
}

function setModelFromPrevComps(newComps, prevComps) {
  return newComps.map(comp => {
    const prevComp = prevComps.find(c => c.name === comp.name);
    if (prevComp) {
      const _pins = comp.pins.map(pin => {
        const prevPin = prevComp.pins.find(p => pin.pin === p.pin);
        if (prevPin) {
          return { ...pin, model: prevPin.model }
        }
        return pin
      })
      const value = prevComp.value;
      const voltage = prevComp.voltage;
      return { ...comp, pins: _pins, value, voltage }
    }
    return comp
  })
}

function getCanvasCompWidth(comp, signals) {
  switch (comp.type) {
    case RES:
      return COMP_PADDING + RES_WIDTH;
    case TEST_POINT:
      return 2 * COMP_PADDING + TEST_WIDTH;
    case COMP_REPEATER:
      return 2 * COMP_PADDING + REPEATER_WIDTH;
    case CONNECTOR:
    case IC:
    default:
      return 2 * COMP_PADDING + signals.length * SIGNAL_COMP_WIDTH;
  }
}

function calcPortsLocation(components, signals, width) {
  let ports = [];

  const calcWidth = 2 * CANVAS_PADDING + components.map(comp => getCanvasCompWidth(comp, signals)).reduce((total, value) => total + value, 0);

  let redundancyWidth = 0;
  if (calcWidth < width) {
    redundancyWidth = (width - calcWidth) / components.length;
    redundancyWidth = redundancyWidth > 10 ? redundancyWidth : 0;
  }
  let startX = CANVAS_PADDING + redundancyWidth;
  let resGroup = []
  for (let comp of components) {
    const { type, pins, name, value, group, voltage } = comp;
    const compStartX = startX + COMP_PADDING;


    if (type === COMP_REPEATER) {
      const compWidth = type === COMP_REPEATER ? REPEATER_WIDTH : TEST_WIDTH
      for (let pin of pins) {
        const signalIndex = signals.findIndex(s => s === pin.signal);
        const y = CANVAS_PADDING + SIGNAL_DISTANCE * signalIndex;;
        if (pin.pin.includes('_in')) {
          const { width: nameWidth } = getTextWidthAndHeight(pin.pin, { fontSize: 12, fontWeight: 'bold' });
          ports.push({ comp: name, value, ...pin, x: compStartX + 8 + 0.5 * nameWidth, y: y + PORT_DISTANCE })
        } else if (pin.pin.includes('_out')) {
          const { width: nameWidth } = getTextWidthAndHeight(pin.pin, { fontSize: 12, fontWeight: 'bold' });
          ports.push({ comp: name, value, ...pin, x: compStartX + compWidth - 0.5 * nameWidth - 5, y: y + PORT_DISTANCE })
        }
      }

      startX = startX + getCanvasCompWidth(comp, signals) + redundancyWidth;
      continue;
    }

    let signalX = startX + (resGroup.includes(group) && type === RES ? GROUP_RES_DISTANCE : COMP_PADDING) + 0.5 * (type === RES ? RES_WIDTH : SIGNAL_COMP_WIDTH);
    for (let pin of pins) {
      const signalIndex = signals.findIndex(s => s === pin.signal);
      const signalY = CANVAS_PADDING + SIGNAL_DISTANCE * signalIndex;
      const endY = signalY + SIGNAL_DISTANCE * (signals.length - 1 - signalIndex) + SIGNAL_TO_COMP;
      ports.push({ comp: name, value, voltage, ...pin, x: signalX, y: endY + 15 + PORT_DISTANCE })

      signalX = signalX + SIGNAL_COMP_WIDTH;
    }

    if (type === RES && !resGroup.includes(group)) {
      resGroup.includes(group);
      startX = startX + getCanvasCompWidth(comp, signals);
      continue;
    }

    if (type === RES && resGroup.includes(group)) {
      startX = startX + getCanvasCompWidth(comp, signals) + COMP_PADDING + redundancyWidth;
      continue;
    }

    startX = startX + getCanvasCompWidth(comp, signals) + redundancyWidth;
  }

  return ports;
}

function getPreLayoutComponentsByPinNet(components, nets) {
  const _components = [];
  for (let comp of components) {
    for (let pin of comp.pins) {
      _components.push({ ...comp, ...pin, part: comp.name })
    }
  }
  return _components.filter(item => nets.includes(item.net))
}

function editPreLayoutComponents({ componentsList, exsitcomponentsName, components, changeTypeFromSetting, signalName, pcbId, updateInfo }) {
  let _exsitcomponentsName = [...exsitcomponentsName], _components = [...components];
  for (const comp of componentsList) {
    let { pin, name, net, value, type, part } = comp;
    const _index = _exsitcomponentsName.indexOf(name);
    if (_index < 0) {
      // Not exist component
      let newComp;
      if (RLCTypes.includes(type)) {
        let _value = value || '0';
        // Cap 
        if (type === CAP) {
          _value = {
            r: '0',
            l: '0',
            c: '0'
          }
        }
        newComp = new RLCCONComponent({ name, type, value: _value, probePins: [] });
        if (RLCTypes.includes(newComp.type)) {
          newComp.model = {
            type: 'value',
            fileName: '',
            libraryId: '',
            subckt: '',
            pairs: []
          }
        }
      } else if (ICTypes.includes(type)) {
        newComp = new ICComponent({ name, type: IC });
      } else if (type === COMP_REPEATER) {
        newComp = new RepeaterComponent({ name, type })
      } else if (type === CONNECTOR) {
        newComp = new Connector({ name, type });
      } else {
        newComp = new BasicComponent({ name, type, probePins: [] });
      }
      if (ICTypes.includes(type)) {
        newComp.pins = [new ICCompPinModel({ pin, net, signal: signalName })];
      } else if (type === COMP_REPEATER) {
        newComp.pins = [new RepeaterPin({ pin, net, signal: signalName })];
      } else if (type === CONNECTOR) {
        newComp.pins = [new ConnectorPin({ pin, net, signal: signalName })];
      } else {
        newComp.pins = [new BasicCompModel({ pin, net, signal: signalName })];
      }
      newComp.part = part;
      _components.push(newComp);
      // Update components name list
      _exsitcomponentsName.push(name);
    } else {
      if (changeTypeFromSetting && _components[_index].type !== type) {
        let update = false;
        // If the components setting is turned off, the usage of comp will change according to the changed type
        //comp change
        update = getCompTypeChange({
          updateInfo,
          pcbId,
          type,
          component: _components[_index]
        })
        if (update) {
          const { newComp } = componentTypeChange({ type: type, component: _components[_index] });
          _components[_index] = newComp;
        }
        continue;
      } else if (RLCTypes.includes(type) && type !== _components[_index].type && !(type === JUMPER && !changeTypeFromSetting && _components[_index].type !== CONNECTOR)) {
        let _value = value || '0';
        // Cap 0.0.1 no cap
        if (type === CAP) {
          if (!_value || parseFloat(_value) === 0) {
            _value = '0';
          }
          _value = {
            r: '0',
            l: '0',
            c: '0'
          }
        }
        let newComp = new RLCCONComponent({ name, type, value: _value, probePins: [] })
        newComp.part = part;
        _components[_index] = newComp;
      }

      //find current pin exist and net ,signal same
      const pinIndex = _components[_index].pins.findIndex(item => item.pin === pin & item.net === net && item.signal === signalName);
      if (pinIndex > -1) {
        continue;
      }

      //find current pin
      const _pinIndex = _components[_index].pins.findIndex(item => item.pin === pin);
      if (_pinIndex > -1) {
        _components[_index].pins[_pinIndex].net = net;
        _components[_index].pins[_pinIndex].signal = signalName;
        continue;
      }

      // Exsit component
      if (ICTypes.includes(_components[_index].type)) {
        _components[_index].pins.push(new ICCompPinModel({ pin, net, signal: signalName }));
      } else if (_components[_index].type === COMP_REPEATER) {
        _components[_index].pins.push(new RepeaterPin({ pin, net, signal: signalName }));
      } else if (_components[_index].type === CONNECTOR) {
        _components[_index].pins.push(new ConnectorPin({ pin, net, signal: signalName }));
      } else {
        _components[_index].pins.push(new BasicCompModel({ pin, net, signal: signalName }));
      }
    }
  }
  return _components;
}

function getUpdatePreLayoutInfo({ content, pcbId }) {
  const preLayoutInfo = preLayoutData.getLocalPrelayout(pcbId);
  if (!content || !content.components || !content.components.length || !content.signals || !content.signals.length
    || !preLayoutInfo
    || !preLayoutInfo.content
    || !preLayoutInfo.content.components) {
    return {}
  }

  let isUpdate = false;
  const preComponents = preLayoutInfo.content.components || [];
  /*  preSignals = preLayoutInfo.content.signals || []; */
  const preNets = preLayoutData.getNets(pcbId);


  const signals = content.signals || [],
    components = content.components || [];

  const preCompPins = mergeCompPins(preComponents),
    compPins = mergeCompPins(components);

  let addCompPins = [], delCompPins = [], delNets = [];

  for (let signal of signals) {
    for (let net of (signal.nets || [])) {

      if (!preNets.includes(net)) {
        delNets.push(net);
        continue;
      }

      const preComps = preCompPins.filter(pin => pin.net === net);
      const curCompPins = compPins.filter(pin => pin.net === net);
      const addList = preComps.filter(item => !curCompPins.find(it => it.component === item.component && it.pin === item.pin));
      const delList = curCompPins.filter(item => !preComps.find(it => it.component === item.component && it.pin === item.pin));

      addCompPins.push(...addList);
      delCompPins.push(...delList);
    }
  }
  if (delNets.length || addCompPins.length || delCompPins.length) {
    isUpdate = true;
  }
  return {
    isUpdate,
    updateInfo: {
      delNets,
      addCompPins,
      delCompPins
    }
  }
}

function mergeCompPins(components) {
  let compPins = []
  for (let comp of components) {
    compPins.push(...comp.pins.map(it => {
      return {
        component: comp.name,
        type: comp.type,
        ...it
      }
    })
    )
  }
  return compPins;
}

function updateInterfaceByPreLayout({
  preLayoutInfo,
  components,
  signals,
  powerNets,
  pcbId }) {
  if (!preLayoutInfo
    || !preLayoutInfo.content
    || !preLayoutInfo.content.components
    || !preLayoutInfo.content.signals) {
    return { newComps: components, newSignals: signals, newPowerNets: powerNets }
  }

  const preComponents = preLayoutInfo.content.components || [];
  /*  preSignals = preLayoutInfo.content.signals || []; */
  const allNets = preLayoutData.getNets(pcbId);

  let _signals = JSON.parse(JSON.stringify(signals));
  let exsitcomponentsName = [], newComponents = [];
  for (let signal of _signals) {

    signal.nets = signal.nets.filter(item => allNets.includes(item));
    const componentsList = getPreLayoutComponentsByPinNet(preComponents, signal.nets);
    newComponents = editPreLayoutComponents({ componentsList, exsitcomponentsName, components: newComponents, signalName: signal.name, pcbId });
    exsitcomponentsName = newComponents.map(item => item.name);
  }
  let _components = JSON.parse(JSON.stringify(components));

  for (let comp of newComponents) {
    const findComp = _components.find(item => item.name === comp.name);
    if (!findComp || findComp.type !== comp.type) {
      continue;
    }

    if (comp.type === IC) {
      comp.model = findComp.model || {};
      comp.deviceVcc = findComp.deviceVcc || "";
      comp.pins.forEach(pin => {
        const findPin = (findComp.pins || []).find(it => it.pin === pin.pin);
        if (findPin) {
          pin.corner = findPin.corner || "typ";
          pin.model = findPin.model || {};
          pin.usage = findPin.usage;
          pin.powerOff = findPin.powerOff;
          pin.pinModels = findPin.pinModels;
        }
      })
      //update pkg
      if (comp.pkg && comp.pkg.type === "Touchstone / SPICE") {
        const pairs = getPairs({ modelType: 'Package', pins: comp.pins });
        pairs.forEach(pin => {
          const findPair = findComp.model ? (findComp.model.pairs || []).find(item => item.pin === pin.pin) : null;
          const findPairDie = findComp.model ? (findComp.model.pairs || []).find(item => item.pin === `${pin.pin}_u`) : null;
          if (findPair) {
            pin.node = findPair.node || "";
            pin.subckt = findPair.subckt || "";
            pin.libraryId = findPair.libraryId || "";
            pin.modelKey = findPair.modelKey || "";
          } else if (findPairDie) {
            pin.node = findPairDie.node || "";
            pin.subckt = findPairDie.subckt || "";
            pin.libraryId = findPairDie.libraryId || "";
            pin.modelKey = findPairDie.modelKey || "";

          }
        });
        comp.pkg = {
          ...findComp.pkg,
          pairs,
        }
      } else {
        comp.pkg = findComp.pkg || { type: "None" };
      }
    }

    //update repeater model pairs
    if (comp.type === COMP_REPEATER) {
      let pairs = [];
      pairs = getPairs({ modelType: COMP_REPEATER, pins: comp.pins });
      pairs.forEach(pin => {
        const findPair = findComp.model ? (findComp.model.pairs || []).find(item => item.pin === pin.pin) : null;
        if (findPair) {
          pin.node = findPair.node || "";
          pin.libraryId = findPair.libraryId || "";
          pin.fileName = findPair.fileName || "";
          pin.subckt = findPair.subckt || "";
          pin.modelKey = findPair.modelKey || "";
        }
      });
      comp.model = {
        files: findComp.model && findComp.model.files ? findComp.model.files : [],
        pairs,
      }
    }

    if (comp.type === CONNECTOR) {
      comp.cableModels = findComp.cableModels || [];
      comp.models = findComp.models || [];
      comp.connect = findComp.connect || {};
      comp.pins.forEach(pin => {
        const findPin = (findComp.pins || []).find(it => it.pin === pin.pin);
        if (findPin) {
          pin.pin_map = findPin.pin_map || "";
          pin.port = findPin.port || "";
          pin.modelId = findPin.modelId;
          pin.external_map = findPin.external_map || {};
          pin.pinModels = findPin.pinModels;
        }
      })
    }
  }

  const newPowerNets = preLayoutInfo.content.prelayout === SCHEMATIC ? getSchematicPreLayoutPowerNets(preLayoutInfo.content.signals, preComponents, newComponents, JSON.parse(JSON.stringify(powerNets))) : [];

  return { newComps: newComponents, newSignals: _signals, newPowerNets }

}

export {
  getCompsByPreLayoutSetting,
  reSortComponents,
  getCanvasCompWidth,
  calcPortsLocation,
  getPreLayoutComponentsByPinNet,
  editPreLayoutComponents,
  getUpdatePreLayoutInfo,
  updateInterfaceByPreLayout
} 