import { COMP_ERROR, NET_ERROR, PIN_ERROR, POINT_SAME_ERROR, POINT_ERROR, NO_NET_ERROR } from '@/pages/Cascade/DCR/PathRContants';
import { getAllCascadeComponents } from '@/services/Cascade/helper/setupData';

function checkResistanceData({ data, designId, getMessage }) {
  let debugMonitor = [], _data = [], _resistanceData = [], errorIds = [], warningMessage = [];
  data.forEach((item, index) => {
    const dataIndex = item.index ? item.index : index + 1;

    const { debugMonitor: _debugMonitor, errorIds: _errorIds, resistancePointObj, _net, pointDataObj, warningMonitor } = checkSingleResistanceData({ item, getMessage, dataIndex, designId })

    _resistanceData.push({
      ...resistancePointObj,
      id: item.id ? item.id : ""
    })

    _data.push({
      ...item,
      ...pointDataObj,
      net: _net,
      index: dataIndex
    })
    debugMonitor.push(..._debugMonitor);
    errorIds.push(..._errorIds)
    warningMessage.push(...warningMonitor);
  })

  return { debugMonitor, _data, _resistanceData, errorIds: [...new Set(errorIds)], warningMessage }
}

function checkSingleResistanceData({ item, getMessage, dataIndex, designId }) {
  let pointDataObj = { error: false }, _net = [], resistancePointObj = {}, errorIds = [], debugMonitor = [], warningMonitor = [];
  for (let pointKey of ['point1', 'point2']) {
    const pointsKey = `${pointKey}s`;
    let points = [...(item[pointsKey] || [])];
    let pointDataList = [], resistancePoints = [];
    const pointName = pointKey.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
    if (!points.length) {
      let errorMsg = getMessage({ errorType: POINT_ERROR, data: {}, index: dataIndex, pointName })
      errorIds.push(item.id)
      debugMonitor.push(errorMsg)
      pointDataObj.error = true;
    }
    for (let point of points) {
      const { pointData, resistancePoint, _net: currNets, error, debugMonitor: _debugMonitor } = checkPointData({
        point,
        designId,
        getMessage,
        dataIndex,
        pointName,
        _net
      });
      _net = [...new Set(currNets)];
      pointDataList.push(pointData);
      resistancePoints.push(resistancePoint);
      if (error) {
        errorIds.push(item.id)
        debugMonitor.push(..._debugMonitor)
        pointDataObj.error = true;
      }
    }
    pointDataObj[pointsKey] = pointDataList;
    resistancePointObj[pointsKey] = resistancePoints;
  }

  const point1Pins = getCompPinList(item.point1s);
  const point2Pins = getCompPinList(item.point2s);
  const sameCompPins = point1Pins.filter(it => point2Pins.find(i => i.comp === it.comp && i.pin === it.pin));
  if (sameCompPins.length) {
    pointDataObj.error = true;
    let errorMsg = getMessage({ errorType: POINT_SAME_ERROR, data: { pins: sameCompPins.map(it => `${it.comp}::${it.pin}`) }, index: dataIndex, type: 'Warning' })
    warningMonitor.push(errorMsg)
  }
  return { debugMonitor, errorIds, resistancePointObj, _net, pointDataObj, warningMonitor }
}

function getCompPinList(points) {
  let compPinList = [];
  for (let item of points || []) {
    if (!item.pins || !item.pins.length) {
      continue;
    }
    compPinList.push(...item.pins.map(it => { return { comp: item.comp, pin: it } }));
  }
  return compPinList;
}

function checkPointData({
  point,
  designId,
  getMessage,
  dataIndex,
  pointName,
  _net
}) {
  let error = false, debugMonitor = [];
  const components = getAllCascadeComponents({ pcbId: designId })
  if (point && point.comp) {
    if (point.net) {
      const _component = components.get(point.comp);
      let pointNetError = false, _compInfoList = [];
      if (_component) {
        if (!point.pins.length) {
          error = true;
          let errorMsg = getMessage({ errorType: NO_NET_ERROR, data: point, index: dataIndex, pointName })
          debugMonitor.push(errorMsg)
        }
        point.pins.forEach(it => {
          const _compInfo = [..._component.pins.values()].filter(comp => (comp.net !== 'NONET' && comp.pin === it));
          if (_compInfo.length) {
            _compInfoList.push(`${_compInfo[0].pin} [${_compInfo[0].net}]`)
            if (point.net && point.net !== _compInfo[0].net) {
              pointNetError = true;
            } else {
              point.net = _compInfo[0].net
            }
          } else {
            error = true;
            let errorMsg = getMessage({ errorType: PIN_ERROR, data: point, index: dataIndex })
            debugMonitor.push(errorMsg)
          }
        })
        if (pointNetError) {
          point.net = "";
          error = true;
          let errorMsg = getMessage({ errorType: NET_ERROR, data: point, index: dataIndex, compInfoList: _compInfoList })
          debugMonitor.push(errorMsg)
        }
        if (point.net && point.net !== 'NONET') {
          _net.push(point.net)
        }
      } else {
        error = true;
        let errorMsg = getMessage({ errorType: COMP_ERROR, data: point, index: dataIndex })
        debugMonitor.push(errorMsg)
      }
    } else {
      error = true;
      let errorMsg = getMessage({ errorType: NO_NET_ERROR, data: point, index: dataIndex, pointName })
      debugMonitor.push(errorMsg)
    }
  } else {
    error = true;
    let errorMsg = getMessage({ errorType: POINT_ERROR, data: point, index: dataIndex, pointName })
    debugMonitor.push(errorMsg)
  }
  return {
    pointData: point,
    resistancePoint: {
      net: point.net,
      comp: point.comp,
      pins: [...(point.pins || [])]
    },
    _net,
    error,
    debugMonitor
  }
}


async function generateDataAfterChangePCB({ data, designId, getMessage }) {
  const newData = [], error = [];
  const components = getAllCascadeComponents({ pcbId: designId });
  for (let row of data) {
    let newRowData = { ...row }, newNet = [];
    const _nets = row.net;
    const { index } = newRowData;
    for (let pointKey of ['point1', 'point2']) {
      const pointsKey = `${pointKey}s`;
      const points = newRowData[pointsKey];
      const pointName = pointKey.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      for (let i = 0; i < points.length; i++) {
        let pointItem = points[i] || {};
        const { comp, pins } = pointItem;
        const findItem = components.get(comp);
        if (comp) {
          if (findItem) {
            let _pins = (pins || []).map(pin => {
              const findPin = [...findItem.pins.values()].find(p => p.pin === pin);
              return findPin ? findPin : null;
            }).filter(pin => !!pin)

            let _net = _pins.length ? _pins.map(p => p.net)[0] : ''
            if (_net) {
              newNet.push(_net);
            }

            _pins = _pins.map(p => p.pin)
            let notExsit = (pins || []).filter(p => !_pins.includes(p))
            if (notExsit.length) {
              error.push(getMessage({ errorType: PIN_ERROR, data: { pins: notExsit, comp }, index: index, pointName, type: 'Update' }))
            }
            pointItem = { comp, pins: _pins, net: _net }
          } else {
            error.push(getMessage({ errorType: COMP_ERROR, data: { ...pointItem }, index: index, pointName, type: 'Update' }))
            pointItem = { comp: '', pins: [], net: '' }
          }
        }
        points[i] = pointItem;
      }
      newRowData[pointsKey] = points;
    }
    newNet = [...new Set(newNet)];
    newRowData.net = newNet
    if (newNet.length !== _nets.length || (newNet.length && newNet.some(n => !_nets.includes(n)))) {
      error.push(`[Update][${index}] ${_nets.length > 1 ? "Nets" : "Net"} "${_nets.join(',')}" ${_nets.length > 1 ? "have" : "has"} been updated to "${newRowData.net.join(',')}".`)
    }

    newData.push(newRowData)
  }
  return { error, data: newData }
}

export { checkResistanceData, generateDataAfterChangePCB }