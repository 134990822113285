import { call, put, takeEvery, delay, select, takeLatest, fork, take, cancel } from 'redux-saga/effects';
import { message } from 'antd';
import {
  GET_IMPEDANCE_CONTENT,
  UPDATE_TARGET_IC,
  REFIND_DOMAIN_INFO,
  DELETE_IMPEDANCE_ROW,
  SAVE_VRM,
  SAVE_RLC_VALUE,
  SAVE_RL_MODEL,
  SAVE_CAP_MODEL,
  UPDATE_EXTENDED,
  MERGE_PART,
  UPDATE_COMP_USAGE,
  SPLIT_COMP,
  UPDATE_COMP_PREFIX,
  UPDATE_EXTRACTION,
  UPDATE_OPTIMIZATION,
  REFRESH,
  REMOVE_COMP,
  ADD_ROW,
  CHANGE_NETS,
  CHANGE_EXTRA_NETS,
  SAVE_TARGET,
  SAVE_PORTS,
  SAVE_LOAD_PORTS,
  CHANGE_PCB,
  SAVE_POWER_DOMAIN,
  POWER_NET_SELECT_PANEL,
  RE_ASSIGN_DECAP_MODEL,
  DELETE_SENSE_PORT,
  UPDATE_OPTIONS,
  SAVE_PACKAGE_PORTS,
  UPDATE_PACKAGE_NETS,
  SAVE_DOMAIN_BY_NEW_NETS,
  SWITCH_PACKAGE_PORTS_TYPE,
  SAVE_BALL_SIZE,
  SET_ADDITIONAL_NETS,
  REFRESH_PACKAGE,
  SWITCH_TARGET_DIE,
  SAVE_WIRE_BOND,
  CHANGE_PAGE,
  CREATE_NEW_LAYOUT,
  DELETE_LAYOUT,
  DELETE_LAYOUT_CONNECTOR,
  TRACE_OVERVIEW,
  DELETE_SHIP,
  CREATE_DIE,
  SAVE_ON_DIE_MODEL,
  SAVE_MULTI_PCB_TARGET,
  UPDATE_DECAP_GROUP,
  SAVE_DIE_CURRENT,
  UPDATE_DECAP_MODEL,
  CHANGE_USAGE,
  SAVE_COMP_TABLE_DISPLAY,
  SAVE_PMIC_VOLTAGE,
  SAVE_RLEAK_RRDL,
  SWITCH_PACKAGE,
  SAVE_INTERPOSER,
  SAVE_INTERPOSER_NODE,
  SAVE_HAS_INTERPOSER,
  UPDATE_IC_CURRENT_STATUS,
  SAVE_TRANSIENT_TIME_SETTING
} from './actionType';
import {
  updateImpedanceLoading,
  updateImpedanceContent,
  updateImpedanceStatus,
  updateImpedanceResultExist,
  resetSetup,
  updateError,
  updateSelectPowerNets,
  savaImpedanceVerificationId,
  updatePCB,
  needUpdateCompSetting,
  savaImpedanceSignOffId,
  updateOpenTargetICLoading,
  updateSelectedPowerNets,
  updateWarning,
  updateLogs,
  saveImpedanceDisplay
} from './action';
import { updateModelSetting, updateExpand, updateViewList, updateSelectKeys, updatePageLayout } from '../project/action';
import { LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT, SINGLE_PAGE_LAYOUT } from '@/constants/layoutConstants';
import { changeTabMenu, openTabFooter } from '../../../MonitorStore/action';
import { isExistResult } from '@/services/Cascade/simulation/simulationCtrl'
import projectDesigns from '@/services/helper/projectDesigns';
import { getVerificationWorkflow } from '../simulation/action';
import {
  getImpedanceInfo,
  ImpExtraction,
  ImpOptimization,
  saveImpedanceDomain,
  saveImpedanceSetup,
  deleteImpedanceDomains,
  mergeVRMInfo,
  filterPortsByPCB,
  getCompUpdateLog,
  ImpOptions,
  upgradeSetupDataToMulti,
  ImpSetup,
  getImpedanceLayoutComp,
  getConnectorLayoutIndex,
  mergeConnectionTrace,
  setNewConnectorNetsSpec,
  getConnectionGroundNet,
  uploadDecapGroupExcel,
  uploadDecapModelExcel,
  ImpedanceErrorCheck,
  SIWAVE
} from '../../../../services/Cascade/Impedance';
import LayoutData from '@/services/data/LayoutData';
import {
  _getVRM,
  getPowerDomain,
  getPMIC,
  CascadeCompPrefixVersion,
  getNets,
  addMultiNetsFieldToCapComps,
  getPackagePowerDomain,
  getPkgDomainInfo,
  getNewPackageComp,
  getFindVRMSpecifyComp,
  CascadeCompRLCPrefixLib,
  getAllCascadeNets
} from '../../../../services/Cascade/helper/setupData';
import { IMPEDANCE } from '@/constants/treeConstants';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { versionCompareSize } from '@/services/helper/dataProcess';
import { selectTaskPCB } from '@/services/Cascade/project';
import componentSetting, { compareCompPrefixLib } from '../../../../services/Cascade/helper/compSettingHelper';
import { updateDesignStatus, updatePCBLog } from '../project/action';
import { updateLibSetting } from '@/services/Cascade/library';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import { updateComponentDecapModel } from '../SignOffTemplate/signOffTemplateSaga';
import { updateLibraryMenu } from '../library/action';
import compTableHelper from '@/services/Cascade/helper/compTableHelper.js'
import compPinMap from '../../../../services/Cascade/helper/compPinMap';
import { IMPEDANCE_DIE, IMPEDANCE_PACKAGE, IMPEDANCE_PCB, IMPEDANCE_PCB_PRELAYOUT, TARGET_DIE_INDEX, TARGET_PACKAGE_INDEX, TARGET_PCB_INDEX } from '../../../../services/Cascade/constants';
import { ImpLayout, ImpPackageDomain, OVERVIEW, PSI } from '../../../../services/Cascade/Impedance';
import { getPortData, autoSplitRefeByPower } from '@/services/helper/portCanvasHelper';
import pinMapStore from '@/services/Cascade/library/pinMapHelper'
import designConstructor from '../../../../services/helper/designConstructor';
import { DC, PCB } from '../../../../constants/treeConstants';
import { savePackageWireBondProfile } from '../../../../services/api/Design/design';
import { BallSizeMap } from '../../../../services/helper/ballSize';
import { CAP, CONNECTOR, IC, PMIC, BGA as BGAConst, DIE as DIEConst, DIE, FERRITE, IGNORE, IND, JUMPER, RES, SWITCH, LOAD, POWER_SWITCH, IPD } from '../../../../constants/componentType';
import { checkConnectCompsPinsAndNets, getCascadeComponents } from '../../../../services/Cascade/helper/setupData';
import { splitArrayToArrays } from '../../../../services/helper/arrayHelper';
import _ from 'lodash';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import { userDefaultSettings } from '../../../../services/userDefaultSetting/userDefaultSettingCtrl';
import { DIE_PACKAGE_PCB, PARTBASED, COMPONENTBASED } from '../../../../constants/resolution';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import { getLibraryDataInfo, getLibraryFileInfo } from '../../../../services/Cascade/library';
import { parseSPModelSelector } from '../../../../services/Library';
import { DECAP_SPICE, DECAP_TOUCHSTONE } from '../../../../constants/libraryConstants';
import { filterPortsByPackage, getDiePortsByCPM } from '../../../../services/Cascade/Impedance/impedanceHelper';
import { SortComponents } from '../../../../services/helper/sort';
import { POWERSI } from '../../../../constants/extractionType';
import { SPD } from '../../../../constants/designVendor';
import { getDiePortsByPloc } from '../../../../services/helper/portCanvasHelper';

let autoSaveTask = null;
function* getImpedanceContent(action) {
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  const { id: verificationId } = action;
  yield put(resetSetup())
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
  }
  if (!verificationId) {
    return;
  }

  yield put(updateError([]))
  yield put(updateWarning([]))
  yield put(updatePCBLog([]))
  const list = CascadeChannels.getList(IMPEDANCE, openProjectId);
  const currentItem = list.find(i => i.id === verificationId);
  const signOffId = currentItem ? currentItem.signOffId : null;

  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: verificationId,
    verificationName: currentItem ? currentItem.name : IMPEDANCE,
    menuType: "simulation"
  }))

  yield put(updateDesignStatus(true))
  yield put(updateImpedanceLoading('Loading...'));

  const userSetting = userDefaultSettings.getLocalSetting();
  const { cascadeSettings = {} } = userSetting;
  const { impedanceOverviewDisplay = DIE_PACKAGE_PCB, impedanceComponentsTableDisplay = PARTBASED } = cascadeSettings;
  yield put(saveImpedanceDisplay(impedanceOverviewDisplay, impedanceComponentsTableDisplay))

  let designId = "", updateCompPrefix = false, _data = [], designName = "", page = OVERVIEW;

  try {
    const res = yield call(getImpedanceInfo, verificationId);
    yield put(getVerificationWorkflow(verificationId))
    if (res) {
      const setupVersion = res.setupVersion || '0.0.10';
      const impedance = yield call(updateImpedanceByVersion, { data: res, version: setupVersion, verificationId });
      const Config = new ImpExtraction(impedance.Config);
      const Optimization = new ImpOptimization(impedance.Optimization);
      const Options = new ImpOptions(impedance.Options)
      let data = impedance.layouts || [];
      const targetIC = impedance.targetIC ? impedance.targetIC : '';
      const targetDie = impedance.targetDie || [];
      let designType = impedance.type || IMPEDANCE_PCB;
      //Fix the bug of designType is PCB,actually designType is pcb/package
      if (designType === PCB) {
        designType = IMPEDANCE_PCB;
      }
      designId = impedance.designId;
      designName = impedance.designName;
      const PCBList = projectDesigns.getAvailablePCBIds(openProjectId);
      yield call([componentSetting, componentSetting.getSetting], { designId });
      let COMP_PREFIX_LIB = impedance.COMP_PREFIX_LIB ? impedance.COMP_PREFIX_LIB : new CascadeCompPrefixVersion('0.0.1');

      // auto set pcb if only one pcb in project
      if (!designId && !targetIC && (!data || !data.length) && PCBList.length === 1) {
        designId = PCBList[0];
        const type = designConstructor.getDesignType(designId);
        const electric = designConstructor.getPreLayoutElectric(designId);
        if ((type === IMPEDANCE_PCB || type === IMPEDANCE_PCB_PRELAYOUT) && electric !== DC) {
          yield call(selectTaskPCB, { verificationId, designId });
          const designName = designConstructor.getDesignName(designId);
          const version = yield call([componentSetting, componentSetting.getVersion], designId);
          COMP_PREFIX_LIB = { version: version }
          const vendor = designConstructor.getDesignVendor(designId)
          const extraction = vendor === SPD ? new ImpExtraction({ type: POWERSI }) : new ImpExtraction();
          yield call(getAuroraDB, designId);
          if (auroraDBJson.isFlexBoard(designId)) {
            extraction.CLIP = 0
          }
          data = [new ImpLayout({ index: TARGET_PCB_INDEX, designId, designName, type: IMPEDANCE_PCB, COMP_PREFIX_LIB, extraction })];
        }
        if (electric === DC) {
          designId = "";
        }
      }

      // check component setting
      for (let layout of data) {
        const { designId: layoutDesignId, COMP_PREFIX_LIB, powerDomains, extraction } = layout;
        const designExist = projectDesigns.getDesignExist(openProjectId, layoutDesignId)
        if (layoutDesignId && designExist) {
          const _version = COMP_PREFIX_LIB.version ? COMP_PREFIX_LIB.version : '0.0.1';
          const setting = yield call([componentSetting, componentSetting.getSetting], { designId: layoutDesignId });
          if (_version === '0.0.0') {
            delete COMP_PREFIX_LIB.version
            const save = compareCompPrefixLib(COMP_PREFIX_LIB, setting.compPrefixLib);
            if (save && data && data.length) {
              updateCompPrefix = false;
            }
            layout.COMP_PREFIX_LIB = { version: setting.version }
          } else if (versionCompareSize(_version, setting.version) && powerDomains && powerDomains.length) {
            updateCompPrefix = true;
          }
        }
        layout.extraction = new ImpExtraction(extraction)
      }

      // set select nets
      if (impedance.targetNets) {
        yield put(updateSelectedPowerNets({ select: impedance.targetNets, allPowerNets: [], allNets: [], open: false }))
      }

      delete impedance.layouts;
      delete impedance.setupVersion;
      delete impedance.type;

      const { CascadeReducer: { project: { applyModelList } } } = yield select();
      // auto set apply modle
      for (let layout of data) {
        for (let item of (layout.powerDomains || [])) {
          if (!item.content.Components) {
            continue;
          }
          item.content.Components.forEach(comp => {
            if (comp.COMP_TYPE === CAP) {
              const findItem = applyModelList.find(item => item.partName === comp.part);
              //comp.model.apply === false , The model modified by the user without checking apply all
              if (findItem && !findItem.defaultId && (comp.models && comp.models.length === 1 && comp.models[0].apply !== false)) {
                comp.models = [{ ...findItem.model, key: 0 }]
              }
            }
          })
        }
      }

      _data = [...data];
      if (!designName) {
        //add design name to setup
        designName = designConstructor.getDesignName(designId);
      }
      if (data.length === 1) {
        page = data[0].designId ? data[0].designId : OVERVIEW;
      }
      yield put(updateImpedanceContent({ ...impedance, targetIC, targetDie, COMP_PREFIX_LIB, Config, designType, Optimization, Options, data, status: true, designName, page }));
      yield put(savaImpedanceVerificationId(verificationId));
      yield put(savaImpedanceSignOffId(signOffId));
      if (data.length === 1) {
        const page = data[0].designId;
        if (page) {
          yield call(changePageByDesign, { page })
        }
      }
    } else {
      const Config = new ImpExtraction();
      const Optimization = new ImpOptimization();
      const Options = new ImpOptions();
      const COMP_PREFIX_LIB = new CascadeCompPrefixVersion('0.0.1');
      yield put(updateImpedanceContent({ Config, Optimization, COMP_PREFIX_LIB, Options }));
      yield put(savaImpedanceVerificationId(verificationId));
    }
    const { CascadeReducer: { Impedance: { verificationId: currentVerificationId } } } = yield select();
    if (currentVerificationId === verificationId) {
      yield call(saveSetup);
    }
    yield put(openTabFooter());
  } catch (error) {
    yield put(updateImpedanceResultExist(false))
    console.error(error)
  }

  try {
    const resultExist = yield call(isExistResult, verificationId);
    yield put(updateImpedanceResultExist(resultExist ? resultExist.exist : false, verificationId))
  } catch (error) {
    yield put(updateImpedanceResultExist(false))
  }

  const designExist = projectDesigns.getDesignExist(openProjectId, designId)

  const { CascadeReducer: { Impedance: { verificationId: currentVerificationId } } } = yield select();
  if (currentVerificationId === verificationId) {
    if (designId && designExist) {
      try {
        yield* _data.map(function* (layout) {
          if (layout.designId !== IMPEDANCE_DIE) {
            if (layout.designId) {
              yield call(getAuroraDB, layout.designId);
            }
          }
        })
        if (page && page !== OVERVIEW && page !== designId) {
          yield call(getAuroraDB, page);
        }
        const isPreLayout = designConstructor.isPreLayout(designId);
        if (!isPreLayout) {
          // check component setting init
          const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
          if (!setting.init || !Object.keys(setting.init).length || Object.values(setting.init).some(item => item === false)) {
            yield call([componentSetting, componentSetting.getSettingFromBackend], { designId })
            yield put(updateLibraryMenu())
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
    yield put(updatePCB(designId))
    yield put(updateDesignStatus(false))
    yield put(updateImpedanceStatus(true));
    yield put(updateImpedanceLoading(false));
    autoSaveTask = yield fork(autoSave);
    yield fork(saveLayouts, { data: _data, update: true });
    yield put(needUpdateCompSetting(updateCompPrefix));
    const { CascadeReducer: { project: { layout, selectedKeys, viewList, expandedKeys } } } = yield select();
    if ([LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT].includes(layout)) {
      if (designId && designExist) {
        let _selectedKeys = [...selectedKeys];
        let _viewList = [...viewList]
        if (projectDesigns.getAvailableDesignsLength(openProjectId) > 0) {
          _viewList = _viewList.includes('PCB') ? _viewList : [...viewList, 'PCB'];
          let _expandedKeys = [...expandedKeys];
          _selectedKeys = _selectedKeys.filter(key => !key.includes('PCB'))
          _selectedKeys.push(`PCB-${designId}`);
          let expandedKey = `PCBs-${openProjectId}`;

          if (!_expandedKeys.includes(expandedKey)) {
            _expandedKeys.push(expandedKey)
            yield put(updateExpand(_expandedKeys));
          }
        }
        yield put(updateSelectKeys(_selectedKeys));
        yield put(updateViewList(_viewList));
      } else {
        let _viewList = viewList.filter(item => item !== 'PCB');
        let _selectedKeys = selectedKeys.filter(item => !item.includes('PCB'))
        yield put(updateViewList(_viewList));
        yield put(updateSelectKeys(_selectedKeys));
        yield put(updatePageLayout(SINGLE_PAGE_LAYOUT, 'setup'))
      }
    }
  }
  yield put(updateImpedanceLoading(false));
}

function* getAuroraDB(designId) {
  try {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (isPreLayout) {
      yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      return;
    }
    const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
    yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId, setting);
  } catch (e) {
    console.error(e);
  }
}

function getLayoutDB(id, onlyCompLayer) {
  return new Promise((resolve, reject) => {
    try {
      const isPreLayout = designConstructor.isPreLayout(id);
      if (!isPreLayout) {
        LayoutData.LoadLayoutDB(id, onlyCompLayer).then(res => {
          resolve(true);
        }, error => {
          reject(error);
        })
      } else {
        preLayoutData.getPreLayout(id).then(res => {
          resolve(true);
        }, error => {
          reject(error);
        })
      }
    } catch (error) {
      reject(error);
    }
  })
}

function* changeTargetIC(action) {
  const { value, pcbId } = action;
  yield put(updateSelectPowerNets({ open: false }))
  const { CascadeReducer: { Impedance: { designId, data = [] } } } = yield select();
  const _designId = pcbId || designId;

  yield put(updateImpedanceContent({ targetIC: value, data: [] }));
  const { powerNets, allNets } = getPowerDomain({ chip: value, designId: _designId });
  yield put(updateSelectPowerNets({ select: [], allPowerNets: [...powerNets], allNets: [...allNets], open: value ? true : false }))

  // set table to loading and close select dropdown
  yield delay(10);
  try {
    yield call(saveSetup);
    const ic = getImpedanceLayoutComp(value, _designId, IC);
    const packageIndex = data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
    if (packageIndex > -1 && data[packageIndex].Bgas.length) {
      ic.from = [{ components: data[packageIndex].Bgas.map(item => item.name), designId: data[packageIndex].designId }]
      data[packageIndex].Bgas.forEach(item => {
        item.to = [{ components: [value], designId: _designId }]
      })
    }
    let layouts = data.map(layout => {
      if (layout.designId === _designId) {
        return { ...layout, ICs: value ? [ic] : [], powerDomains: [] }
      }
      return { ...layout, powerDomains: [] }
    })
    yield put(updateImpedanceContent({ data: [...layouts], status: true }));
    yield call(saveLayouts, { data: [...layouts], update: true })
  } catch (error) {
    console.error(error)
  }
}

function* getVRMs() {
  const { CascadeReducer: { project: { openProjectId }, Impedance: { designId: currentDesignId, verificationId, targetIC, data = [], powerNetSelect: { select: powerNets } } } } = yield select();

  yield put(updateImpedanceLoading('Loading PCBs...'));
  yield delay(100)

  yield* data.map(function* (layout) {
    if (layout.designId !== IMPEDANCE_DIE) {
      if (layout.designId) {
        yield call(getAuroraDB, layout.designId);
      }
    }
  })

  const designList = CascadeChannels.getList(IMPEDANCE, openProjectId);
  const currentItem = [...designList].find(i => i.id === verificationId);
  yield put(openTabFooter());
  yield put(changeTabMenu({
    tabSelectKeys: ["detail"],
    currentVerificationId: verificationId,
    verificationName: currentItem ? currentItem.name : IMPEDANCE,
    menuType: "simulation"
  }))
  yield put(updateLogs([], true))
  yield delay(100);

  let layoutPowerDomains = {};
  yield* data.map(function* (layout) {
    if (layout.index !== TARGET_PACKAGE_INDEX && layout.index !== TARGET_DIE_INDEX) {
      const { designId, connectors, designName, powerDomainIds } = layout;

      const isPreLayout = designConstructor.isPreLayout(designId);
      yield put(updateImpedanceLoading(`Tracing ${designName}...`));
      yield delay(100);
      if (powerDomainIds && powerDomainIds.length) {
        try {
          yield call(deleteImpedanceDomains, { verificationId, designId, ids: powerDomainIds });
        } catch (e) {
          console.error(e)
        }
      }

      const connectorRule = (item) => { return item.from.length && item.from.every(f => f.designId !== designId) }
      const { trueArray: fromConnectors, falseArray: toConnectors } = splitArrayToArrays({ array: connectors.filter(item => item.from.length || item.to.length), rule: connectorRule });
      const setting = yield call(componentSetting.getPrefixLib, designId);
      const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
      const table = yield call(compTableHelper.getTableData, designId);
      const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName")
      const _pinConnection = yield call(compPinMap.getPinConnection, designId);
      const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
      const pinMap = yield call(getImpedancePinMap, designId);
      if (designId === currentDesignId) {
        const { groundNet } = getPowerDomain({ chip: targetIC, designId, COMP_PREFIX_LIB: setting });
        const designName = designConstructor.getDesignName(designId)
        let loadSelect = [{ comp: targetIC }];
        const specify = toConnectors.map(connector => { return getFindVRMSpecifyComp(connector, designId) });
        if (!pinMap.some(item => item.data.length) && !specify.length && !isPreLayout) {
          yield put(updateLogs([{ type: 'error', text: `[${designName}] The Pin Map of the current PCB is not set, please open Component Setting to set it.` }]))
          yield delay(100)
        }
        if (!powerNets.length) {
          yield put(updateLogs([{ type: 'error', text: `[${designName}] The Power Nets of the current TargetIC are not set, please click the icon on the right side of TargetIC to set it.` }]))
          yield delay(100)
        }
        let powerDomains = []
        for (let powerNet of powerNets) {
          let VRMInfo = yield call(_getVRM, {
            designId: currentDesignId,
            GroundNets: [groundNet],
            PowerNets: [powerNet],
            ExtendNets: [],
            COMP_PREFIX_LIB: setting,
            loadSelect,
            connectInductance: false,
            findExtend: true,
            isAC: true,
            PMIC: getPMIC(setting),
            powerSwitch: setting.powerSwitch,
            buckConverter: setting.discreteBuckConverter || [],
            doNotStuff,
            table,
            pinMapSense,
            pinConnection,
            pinMap,
            specify
          })

          VRMInfo.Components = yield call(updateComponentDecapModel, {
            components: VRMInfo.Components,
            prevComps: [],
            designId: currentDesignId,
            voltage: "1"
          })
          const content = {
            ...VRMInfo,
            voltage: "1",
            ReferenceNets: [groundNet],
            MAIN_POWER_NETS: [powerNet],
            MAIN_REF_NETS: [groundNet],
            includeExtended: true,
            ports: [],
            target: []
          }
          powerDomains.push(content)
        }
        if (!powerDomains.length) {
          yield put(updateLogs([{ type: 'error', text: `[${designName}] Can not find Power Domains.` }]))
        } else {
          const currentDomains = powerDomains.filter(item => item.DEBUG_MONITOR && item.DEBUG_MONITOR.length)
          yield put(updateLogs([{ type: 'success', text: `[${designName}] Found ${powerDomains.length} Power Domains, ${currentDomains.length} Power Domains have PMICs.` }]))
        }
        yield delay(100)
        layoutPowerDomains[designId] = powerDomains
      } else {
        const specify = toConnectors.map(connector => { return getFindVRMSpecifyComp(connector, designId) });
        if (!pinMap.some(item => item.data.length) && !specify.length && isPreLayout) {
          const designName = designConstructor.getDesignName(designId)
          yield put(updateLogs([{ type: 'error', text: `[${designName}] The Pin Map of the current PCB is not set, please open Component Setting to set it.` }]))
          yield delay(100)
        }
        let powerDomains = []
        for (let connector of fromConnectors) {
          const { name, from } = connector;
          let powerNets = [], referenceNets = [];
          for (let f of from) {
            const { nets } = f;
            if (!nets) {
              continue;
            }
            const _nets = nets[`${name}-${designId}`];
            if (!_nets) {
              continue
            }
            let groundNet = getConnectionGroundNet({ designId, name, currentDesignId, targetIC, setting, data });
            if (!groundNet) {
              const obj = getPowerDomain({ chip: name, designId });
              groundNet = obj ? obj.groundNet : ''
            }
            powerNets.push(...(_nets.filter(net => net !== groundNet) || []))
            referenceNets.push(...([groundNet] || []))
          }
          let loadSelect = [{ comp: name }];
          for (let powerNet of [...new Set(powerNets)]) {
            let VRMInfo = yield call(_getVRM, {
              designId,
              GroundNets: [...new Set(referenceNets)],
              PowerNets: [powerNet],
              ExtendNets: [],
              COMP_PREFIX_LIB: setting,
              loadSelect,
              connectInductance: false,
              findExtend: true,
              isAC: true,
              PMIC: getPMIC(setting),
              powerSwitch: setting.powerSwitch,
              buckConverter: setting.discreteBuckConverter || [],
              doNotStuff,
              table,
              pinMapSense,
              pinConnection,
              pinMap,
              specify
            })

            VRMInfo.Components = yield call(updateComponentDecapModel, {
              components: VRMInfo.Components,
              prevComps: [],
              designId,
              voltage: "1"
            })

            const content = {
              ...VRMInfo,
              voltage: "1",
              ReferenceNets: [...new Set(referenceNets)],
              MAIN_POWER_NETS: [powerNet],
              MAIN_REF_NETS: [...new Set(referenceNets)],
              includeExtended: true,
              ports: [],
              target: []
            }
            powerDomains.push(content);
          }
        }
        if (!powerDomains.length) {
          yield put(updateLogs([{ type: 'error', text: `[${designName}]Can not find Power Domains.` }]))
        } else {
          const currentDomains = powerDomains.filter(item => item.DEBUG_MONITOR && item.DEBUG_MONITOR.length)
          yield put(updateLogs([{ type: 'success', text: `[${designName}] Found ${powerDomains.length} Power Domains, ${currentDomains.length} Power Domains have PMICs.` }]))
        }
        layoutPowerDomains[designId] = powerDomains
      }
    }
  })

  yield put(updateImpedanceLoading(`Checking...`));
  yield delay(100)

  let _data = [...data];
  if (data.length > 1) {
    layoutPowerDomains = mergeConnectionTrace(_data, layoutPowerDomains);
  }
  yield put(updateLogs([{ type: 'normal', text: `[Layouts] Checking...` }]))
  yield delay(100)
  for (let layout of _data) {
    const { designId, connectors, index, ballSizeMap } = layout;
    if (index !== TARGET_PACKAGE_INDEX && index !== TARGET_DIE_INDEX) {
      const powerDomains = layoutPowerDomains[designId] || [];
      const VRMs = powerDomains.map(pow => (pow.VRM && pow.VRM.map(vrm => vrm.VRM_COMP)) || []).flat(3);
      const PMICs = [...new Set(VRMs)].filter(vrm => !connectors.find(conn => conn.name === vrm));
      layout.PMICs = PMICs.map(pmic => getImpedanceLayoutComp(pmic, designId, PMIC));

      for (let powerDomain of powerDomains) {
        const DEBUG_MONITOR = powerDomain.DEBUG_MONITOR;
        layout.ICs = layout.ICs.filter(item => item.name === targetIC)
        if (DEBUG_MONITOR.length) {
          for (let monitor of DEBUG_MONITOR) {
            const _monitor = monitor.filter(item => item.type === 'comp');
            const first = _monitor[0].name;
            const last = _monitor[_monitor.length - 1].name;
            if (designId === currentDesignId) {
              layout.ICs = layout.ICs.filter(item => item.name === first)
              const firstIndex = layout.ICs.findIndex(item => item.name === first);
              if (firstIndex > -1 && !layout.ICs[firstIndex].to.find(item => item.designId === designId && item.components.includes(last))) {
                layout.ICs[firstIndex].to.push({ designId, components: [last] })
              }
            } else {
              const firstIndex = layout.connectors.findIndex(item => item.name === first);
              if (firstIndex > -1 && !layout.connectors[firstIndex].to.find(item => item.designId === designId && item.components.includes(last))) {
                layout.connectors[firstIndex].to.push({ designId, components: [last] })
              }
            }

            let lastIndex = layout.connectors.findIndex(item => item.name === last);
            if (lastIndex > -1 && !layout.connectors[lastIndex].from.find(item => item.designId === designId && item.components.includes(first))) {
              layout.connectors[lastIndex].from.push({ designId, components: [first] })
            } else {
              lastIndex = layout.PMICs.findIndex(item => item.name === last);
              if (lastIndex > -1 && !layout.PMICs[lastIndex].from.find(item => item.designId === designId && item.components.includes(first))) {
                layout.PMICs[lastIndex].from.push({ designId, components: [first] })
              }
            }
          }
        }
      }

      layout.powerDomains = powerDomains.map(domain => ({ id: "", content: domain }));
      let ballSizeMapObj = new BallSizeMap({ ballSizeMap, comps: layout.ICs, designId })
      layout.ballSizeMap = ballSizeMapObj.getBallSizeMap()
    }
  }

  yield put(updateLogs([{ type: 'normal', text: `[Layouts] Saving...` }]))
  yield delay(100)
  yield call(saveLayouts, { data: _data, update: true });
  yield put(updateImpedanceLoading(false));

  const pkg = _data.find(item => item.type === IMPEDANCE_PACKAGE)
  if (pkg) {
    yield call(updatePackageDomain, pkg.designId, true)
  }
  yield put(updateLogs([{ type: 'normal', text: `[Layouts] Trace completed.` }]))
}

function* saveSetup() {
  const { CascadeReducer: { Impedance } } = yield select();
  try {
    const verificationId = Impedance.verificationId
    yield call(saveImpedanceSetup, {
      verificationId,
      content: {
        ...new ImpSetup(Impedance)
      }
    })
  } catch (error) {
    console.error(error)
  }
}

function* saveLayouts(action) {
  let { data, update = false } = action;
  const { CascadeReducer: { Impedance: { verificationId } } } = yield select();
  try {
    // check data error,update readyForSim: 0 / 1
    const { data: newData } = ImpedanceErrorCheck(data);
    data = newData;
    const res = yield call(saveImpedanceDomain, {
      verificationId,
      layouts: data
    });
    if (update) {
      yield put(updateImpedanceContent({ data: res && res.layouts ? res.layouts : [] }));
      yield put(updateImpedanceStatus(true));
    }
  } catch (error) {
    console.error(error)
  }
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([SAVE_POWER_DOMAIN]);
    let delayTime = 2000;
    if (newAction.type === SAVE_POWER_DOMAIN) {
      delayTime = 2000;
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveLayouts, newAction);
    }
  }
};

function* debounce(time = 0, callback, info) {
  yield delay(time);
  yield call(callback, info);
}

function* addRowForDomain() {
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  if (page === OVERVIEW) {
    return;
  }
  const index = data.findIndex(item => item.designId === page);
  if (index < 0) {
    return;
  }
  let _data = [...data];
  _data[index].powerDomains = [..._data[index].powerDomains, {
    id: '',
    content: _data[index].type === IMPEDANCE_PACKAGE ? new ImpPackageDomain({}) : {
      Components: [],
      DEBUG_MONITOR: [],
      MAIN_POWER_NETS: [],
      MAIN_REF_NETS: [],
      PowerNets: [],
      ReferenceNets: [],
      VRM: [],
      includeExtended: true
    }
  }]
  yield call(saveLayouts, { data: _data, update: true })
}

function* changeNets(action) {
  const { CascadeReducer: { Impedance: { targetIC, data, page } } } = yield select();
  const { id, nets } = action;
  if (page === OVERVIEW) {
    return;
  }
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < 0) { return };
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  if (index < 0) { return }
  let currentData = _data[findIndex].powerDomains[index];
  currentData.content = { ...currentData.content, ...nets }
  const { id: _id, content } = currentData;
  const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], PowerNets, ReferenceNets, includeExtended, extraNets = [] } = content;
  const setting = yield call(componentSetting.getPrefixLib, page);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, page);
  const table = yield call(compTableHelper.getTableData, page);
  const _pinConnection = yield call(compPinMap.getPinConnection, page);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const pinMap = yield call(getImpedancePinMap, page);
  if (PowerNets && PowerNets.length && ReferenceNets && ReferenceNets.length) {
    const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], page, "pinName")
    let VRMInfo = yield call(_getVRM,
      {
        designId: page,
        GroundNets: [...MAIN_REF_NETS],
        PowerNets: [...MAIN_POWER_NETS],
        ExtendNets: [],
        ExistNets: PowerNets.filter(item => !MAIN_POWER_NETS.includes(item)),
        COMP_PREFIX_LIB: setting,
        loadSelect: [{ comp: targetIC }],
        connectInductance: false,
        findExtend: includeExtended,
        isAC: true,
        PMIC: getPMIC(setting),
        powerSwitch: setting.powerSwitch,
        buckConverter: setting.discreteBuckConverter || [],
        doNotStuff,
        table,
        pinMapSense,
        pinConnection,
        pinMap,
        extraNets
      })
    currentData = {
      id: _id,
      content: {
        ...VRMInfo,
        includeExtended: true,
        ReferenceNets: [...MAIN_REF_NETS],
        PowerNets: [...new Set([...PowerNets, ...VRMInfo.PowerNets])],
        MAIN_POWER_NETS: MAIN_POWER_NETS,
        MAIN_REF_NETS: MAIN_REF_NETS,
        extraNets: [...extraNets]
      }
    }
  }
  _data[findIndex].powerDomains[index] = currentData;
  const newPowerDomains = _data[findIndex].powerDomains.map(item => ({ id: item.id, ...item.content }));
  const VRMs = newPowerDomains.map(pow => (pow.VRM && pow.VRM.map(vrm => vrm.VRM_COMP)) || []).flat(3);
  const PMICs = [...new Set(VRMs)].filter(vrm => !_data[findIndex].connectors.find(conn => conn.name === vrm));
  _data[findIndex].PMICs = PMICs.map(pmic => getImpedanceLayoutComp(pmic, page, PMIC));
  yield call(saveLayouts, { data: _data, update: true });
}

function* changeExtraNets(action) {
  const { CascadeReducer: { Impedance: { targetIC, data, page, designId: mainDesignId } } } = yield select();
  const { id, nets } = action;
  if (page === OVERVIEW) {
    return;
  }
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < 0) { return };
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  if (index < 0) { return }
  const designId = page
  const connectors = _data[findIndex].connectors
  let currentData = _data[findIndex].powerDomains[index];
  currentData.content = { ...currentData.content, ...nets }
  const { id: _id, content } = currentData;
  const { PowerNets = [], extraNets = [], VRM, ReferenceNets = [], DEBUG_MONITOR = [] } = content;
  let { Components = [] } = content
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, page);
  const table = yield call(compTableHelper.getTableData, page);
  const _pinConnection = yield call(compPinMap.getPinConnection, page);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  let chips = []
  if (designId === mainDesignId) {
    chips.push(targetIC)
  } else {
    const connectorRule = (item) => { return item.from.length && item.from.every(f => f.designId !== designId) }
    const { trueArray: fromConnectors } = splitArrayToArrays({ array: connectors.filter(item => item.from.length || item.to.length), rule: connectorRule });
    for (let connector of fromConnectors) {
      const { name } = connector;
      chips.push(name);
    }
  }
  let _Components = auroraDBJson.getComponentsByNets(designId, [...extraNets, ...PowerNets]);
  let powerPinComps = [];
  for (let item of VRM || []) {
    item.powerPin && powerPinComps.push(...item.powerPin.map(it => it.comp))
  }
  yield put(updateImpedanceLoading('Loading...'));
  _Components = _Components.map(item => {
    const { name, type, pins, value, partName, partNumber } = item;

    let component = {
      name,
      COMP_TYPE: type,
      usage: type,
      pins: [...pins.values()],
      value,
      part: partName,
      partNumber: partNumber || undefined,
      isMultiNets: false,
      model: type === CAP ? { id: "", name: "" } : undefined
    }

    component.pins = component.pins.filter(pin => [...PowerNets, ...extraNets, ...ReferenceNets].includes(pin.net));
    if (type === CAP && !component.pins.some(pin => ReferenceNets.includes(pin.net))) {
      return null;
    }

    if ([SWITCH, JUMPER, FERRITE, RES, IND].includes(type)) {
      component.pins = component.pins.filter(pin => !ReferenceNets.includes(pin.net))
      component.usage = component.COMP_TYPE;
      if (component.pins.length > 2) {
        const connection = pinConnection.find(p => p.partNumber === component.partName) || { pinMap: [] };
        let pinNumbers = component.pins.map(pin => pin.pin);
        const _pins = connection.pinMap ? connection.pinMap.filter(map => map && map[0] && map[1] && (pinNumbers.includes(map[0]) || pinNumbers.includes(map[1]))).flat(2) : [];
        let pinMap = _pins.length ? _pins : [];
        if (!pinMap.length) {
          pinNumbers.sort((a, b) => a - b);
          for (let i = 0; i < Math.floor(pinNumbers.length / 2); i++) {
            if (pinNumbers[i]) {
              pinMap.push(pinNumbers[i])
            }
            if (pinNumbers[pinNumbers.length - 1 - i]) {
              pinMap.push(pinNumbers[pinNumbers.length - 1 - i])
            }
          }
        }
        component.pinMap = pinMap;
      }
    } else if (type === CAP) {
      if ([...new Set(component.pins.map(pin => pin.net))].length > 2) {
        component.isMultiNets = true;
      }
      component.value = "";
    }

    if (doNotStuff.includes(name)) {
      return { ...component, usage: IGNORE };
    }

    if ([RES, FERRITE, JUMPER, SWITCH, IND].includes(type)) {
      let itemData = table.find(data => data.name && data.name.includes(name))
      let value = itemData ? (parseFloat(itemData.value) === 0 ? '0' : itemData.value + itemData.unit) : '0'
      return { ...component, value }
    } else if (chips.includes(name)) {
      return { ...component, usage: LOAD }
    }
    return component
  })

  let mergeComponents = []

  // key - load, value :[ ...vrmComps ]
  let monitorComps = new Map()
  const anotherLoadComps = Components.filter(item => item.usage === LOAD && !chips.includes(item.name))
  anotherLoadComps.forEach(item => {
    monitorComps.set(item.name, item.loadMonitor && item.loadMonitor.length ? item.loadMonitor.flat().filter(item => item.type === 'comp').map(item => item.name) : [])
  })
  if (chips.length) {
    chips.forEach(chip => monitorComps.set(chip, DEBUG_MONITOR && DEBUG_MONITOR.length ? DEBUG_MONITOR.flat().filter(item => item.type === 'comp').map(item => item.name) : []))
  }
  const loads = monitorComps.keys()
  const filterLoad = [];
  const currentLoad = [];
  loads.forEach(load => {
    (_Components.map(item => item.name).includes(load) ? currentLoad : filterLoad).push(load);
  });
  if (filterLoad.length) {
    const currentComps = [...new Set(currentLoad.map(item => { return [...monitorComps.get(item)] }).flat())]
    filterLoad.forEach(item => {
      const filterComps = monitorComps.get(item)
      if (filterComps && filterComps.length) {
        Components = Components.map(comp => {
          if (filterComps.includes(comp.name) && !currentComps.includes(comp.name)) {
            return { ...comp, usage: IGNORE }
          }
          return { ...comp }
        })
      }
    })
    _data[findIndex].ICs = _data[findIndex].ICs.filter(item => !filterLoad.includes(item.name))
  }

  _Components.forEach(_comp => {
    if (_comp && _comp.name) {
      if (Components.map(comp => comp.name).includes(_comp.name)) {
        mergeComponents.push(Components.find(comp => comp.name === _comp.name))
      } else {
        mergeComponents.push(_comp)
      }
    }
  })
  mergeComponents = yield call(updateComponentDecapModel, {
    components: mergeComponents,
    prevComps: [],
    designId: designId,
    voltage: "1"
  })
  mergeComponents = SortComponents(mergeComponents, chips)
  currentData = {
    id: _id,
    content: {
      ...content,
      Components: mergeComponents
    }
  }
  _data[findIndex].powerDomains[index] = currentData;
  yield call(saveLayouts, { data: _data, update: true });
  yield put(updateImpedanceLoading(false));
}

function* reGetDomainRowInfo(action) {
  const { row } = action;
  const { CascadeReducer: { Impedance: { targetIC, data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < 0) { return };
  let loadSelect = [{ comp: targetIC }]
  const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], id, PowerNets, ReferenceNets, includeExtended, extraNets = [] } = row;
  if (!MAIN_POWER_NETS.length || !MAIN_REF_NETS.length) {
    return;
  }
  const currentIndex = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  const current = _data[findIndex].powerDomains[currentIndex] || null;

  if (current) {
    const content = current.content;
    if (content.PowerNets.length && content.ReferenceNets.length &&
      _.isEqual(PowerNets, content.PowerNets) &&
      _.isEqual(ReferenceNets, content.ReferenceNets)) {
      return;
    }
  }

  const setting = yield call(componentSetting.getPrefixLib, page);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, page);
  const table = yield call(compTableHelper.getTableData, page);
  const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], page, "pinName");
  const _pinConnection = yield call(compPinMap.getPinConnection, page);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const pinMap = yield call(getImpedancePinMap, page);
  let VRMInfo = yield call(_getVRM,
    {
      designId: page,
      GroundNets: [...MAIN_REF_NETS],
      PowerNets: [...MAIN_POWER_NETS],
      ExtendNets: [],
      ExistNets: PowerNets.filter(item => !MAIN_POWER_NETS.includes(item)),
      COMP_PREFIX_LIB: setting,
      loadSelect,
      connectInductance: false,
      findExtend: includeExtended,
      isAC: true,
      PMIC: getPMIC(setting),
      powerSwitch: setting.powerSwitch,
      buckConverter: setting.discreteBuckConverter || [],
      doNotStuff,
      table,
      pinMapSense,
      pinConnection,
      pinMap,
      extraNets
    })
  const newInfo = current ? mergeVRMInfo(VRMInfo, current.content) : VRMInfo;
  let newRow = {
    id,
    content: {
      ...newInfo,
      ReferenceNets: [...MAIN_REF_NETS],
      PowerNets: [...new Set([...PowerNets, ...VRMInfo.PowerNets])],
      MAIN_POWER_NETS: MAIN_POWER_NETS,
      MAIN_REF_NETS: MAIN_REF_NETS,
      extraNets: [...extraNets]
    }
  }

  if (currentIndex > -1) {
    _data[findIndex].powerDomains[currentIndex] = newRow;
  } else {
    _data[findIndex].powerDomains.push(newRow);
  }
  yield call(saveLayouts, { data: _data, update: true });
}

function* deletePowerDomains(action) {
  const { id } = action;
  const { CascadeReducer: { Impedance: { data, page, verificationId, designId, designType, powerNetSelect: { select: powerNets } } } } = yield select();
  if (page === OVERVIEW) {
    return;
  }
  let _data = [...data]
  const designIndex = data.findIndex(item => item.designId === page);
  if (designIndex < 0) {
    return;
  }
  const delData = data[designIndex].powerDomains.find(item => item.id === id);
  if (designType === IMPEDANCE_PCB && designId === page && delData.content && delData.content.MAIN_POWER_NETS) {
    const newSelect = powerNets.filter(item => !delData.content.MAIN_POWER_NETS.includes(powerNets));
    yield put(updateSelectPowerNets({ select: newSelect }));
  }

  const powerNet = delData.content.MAIN_POWER_NETS && delData.content.MAIN_POWER_NETS.length ? delData.content.MAIN_POWER_NETS[0] : null
  _data[designIndex].powerDomains = _data[designIndex].powerDomains.filter(item => item.id !== id);
  if (data[designIndex].type === IMPEDANCE_PCB) {
    const vrms = _data[designIndex].powerDomains.map(item => item.content && item.content.VRM ? item.content.VRM.map(i => i.VRM_COMP) : []).flat(3);
    _data[designIndex].PMICs = _data[designIndex].PMICs.filter(item => vrms.includes(item.name));
  } else if (data[designIndex].type === IMPEDANCE_PACKAGE) {
    const newDIEs = _data[designIndex].powerDomains.map(domain => domain && domain.content ? domain.content.Components.filter(item => item.COMP_TYPE === DIEConst) : []).flat(3);
    const newDIENames = [...new Set(newDIEs.map(item => item.name))];
    const removeDie = _data[designIndex].Dies.find(item => !newDIENames.includes(item.name));
    if (removeDie && removeDie.name) {
      const _ballSizeMap = _data[designIndex].ballSizeMap
      delete _ballSizeMap[removeDie.name]
      _data[designIndex].ballSizeMap = _ballSizeMap
    }
    _data[designIndex].Dies = _data[designIndex].Dies.filter(item => newDIENames.includes(item.name));

    const dieIndex = _data.findIndex(item => item.index === TARGET_DIE_INDEX)
    if (dieIndex > -1) {
      let ics = _data[dieIndex].ICs
      ics = ics.filter(ic => newDIENames.includes(ic.to[0].components[0]) && ic.powerNet !== powerNet)
      _data[dieIndex].ICs = ics
    }
    yield put(updateImpedanceContent({ targetDie: newDIENames }));
  }
  try {
    yield put(updateImpedanceContent({ data: _data, status: true }));
    yield call(deleteImpedanceDomains, { verificationId, designId: page, ids: [id] });
    yield call(saveLayouts, { data: _data, update: true });
    yield call(saveSetup)
  } catch (error) {
    message.error('Delete power domain failed!');
    console.error(error)
  }
}

function* saveVRM(action) {
  const { key } = action;
  switch (key) {
    case 'model':
      yield call(saveVRMModel, action);
      break;
    case 'comp':
      yield call(saveVRMComp, action);
      break;
    case 'voltage':
      yield call(saveVoltage, action);
      break;
    default: break;
  }
}

function* saveVoltage(action) {
  const { id, voltage } = action.params;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data]
  if (page === OVERVIEW) {
    return;
  }
  const layoutIndex = _data.findIndex(item => item.designId === page);
  const rowIndex = _data[layoutIndex].powerDomains.findIndex(item => item.id === id);
  const row = _data[layoutIndex].powerDomains[rowIndex] || null;
  if (!row) {
    return;
  }
  const newRow = { ...row };
  newRow.content.voltage = voltage;
  yield put(updateImpedanceContent({ data: _data, status: true }));

  yield delay(100)
  const info = yield call(updateComponentDecapModel, {
    designId: page,
    components: newRow.content.Components.map(item => ({ ...item, model: {} })),
    prevComps: [],
    existModelParts: [],
    type: "re-assign",
    saveLib: false,
    voltage: voltage || "1"
  });
  newRow.content.Components = info.components;

  _data[layoutIndex].powerDomains[rowIndex] = newRow;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* saveVRMModel(action) {
  const { id, vrm } = action.params;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  if (page === OVERVIEW) {
    return;
  }
  const layoutIndex = _data.findIndex(item => item.designId === page);
  const rowIndex = _data[layoutIndex].powerDomains.findIndex(item => item.id === id);
  const row = _data[layoutIndex].powerDomains[rowIndex] || null;
  if (!row) {
    return;
  }
  const newRow = { ...row };
  newRow.content.VRM = newRow.content.VRM.map(item => {
    return { ...item, model: { id: vrm.id, name: vrm.name } }
  })
  _data[layoutIndex].powerDomains[rowIndex] = newRow;
  yield put(updateImpedanceContent({ data: _data, status: false }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* saveVRMComp(action) {
  const { id, VRM } = action.params;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  if (page === OVERVIEW) {
    return;
  }
  const layoutIndex = _data.findIndex(item => item.designId === page);
  const rowIndex = _data[layoutIndex].powerDomains.findIndex(item => item.id === id);
  const row = _data[layoutIndex].powerDomains[rowIndex] || null;
  if (!row) {
    return;
  }
  const newRow = { ...row };
  newRow.content.VRM = VRM;
  _data[layoutIndex].powerDomains[rowIndex] = newRow;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* saveRLCValue(action) {
  const { rowData, value } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  const { components } = rowData;
  const compName = components.map(item => item.name);
  let newData = [...data];
  const findIndex = newData.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  newData[findIndex].powerDomains.forEach(item => {
    item.content.Components = item.content.Components.map(comp => {
      if (compName.includes(comp.name)) {
        return { ...comp, value }
      } else {
        return { ...comp }
      }
    })
  })

  yield put(updateImpedanceContent({ data: newData, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: newData });
}

function* saveRLModel(action) {
  const { model, value, part, components } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  const compName = components.map(item => item.name);
  let newData = [...data];
  const findIndex = newData.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  newData[findIndex].powerDomains.forEach(item => {
    item.content.Components = item.content.Components.map(comp => {
      if (comp.part === part && compName.includes(comp.name)) {
        if (model.type === 'value') {
          comp.value = value;
        }
        comp.model = { ...model };
      }
      return { ...comp }
    })
  })
  yield put(updateImpedanceContent({ data: newData, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: newData });
}

function* saveModel(action) {
  const { record, value, models, apply, applyModelList, applySweep } = action;

  const { CascadeReducer: { Impedance: { data, page }, project: { openProjectId } } } = yield select();
  const { part, components, pcbId } = record;
  const compName = components.map(item => item.name);
  let newData = [...data];
  let findIndex
  if (page === OVERVIEW) {
    findIndex = newData.findIndex(item => item.designId === pcbId);
  } else {
    findIndex = newData.findIndex(item => item.designId === page);
  }
  if (findIndex < 0) {
    return;
  }
  models.forEach(model => {
    delete model.matchWeights;
  })
  const _applySweep = models.length > 1 && applySweep
  newData[findIndex].powerDomains.forEach(item => {
    item.content.Components = item.content.Components.map(comp => {
      if ((comp.part === part && apply) || compName.includes(comp.name)) {
        return { ...comp, value, models: models.map(model => { return { ...model, apply: apply } }), applySweep: _applySweep };
      } else {
        if (comp.applySweep && _applySweep) {
          return { ...comp, applySweep: false }
        }
        return { ...comp }
      }
    })
  })
  yield put(updateImpedanceContent({ data: newData, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: newData });
  if (apply) {
    try {
      yield call(updateLibSetting, { librarySettings: applyModelList, projectId: openProjectId })
      yield put(updateModelSetting(applyModelList))
    } catch (error) {
      console.error(error)
    }
  }
}

function* updateExtendNets(action) {
  const { checked, id } = action;

  const { CascadeReducer: { Impedance: { targetIC, data, designId, page } } } = yield select();
  let loadSelect = [{ comp: targetIC }];
  let newData = [...data];
  if (page === OVERVIEW) {
    return;
  }
  const layoutIndex = newData.findIndex(item => item.designId === page);
  const findIndex = newData[layoutIndex].powerDomains.findIndex(item => item.id === id);
  if (findIndex < -1) {
    return;
  }
  const setting = yield call(componentSetting.getPrefixLib, designId);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
  let newRow = { ...newData[layoutIndex].powerDomains[findIndex] };
  const { content } = newRow;
  const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], ReferenceNets = [], extraNets = [] } = content;
  const table = yield call(compTableHelper.getTableData, designId);
  const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
  const _pinConnection = yield call(compPinMap.getPinConnection, designId);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const pinMap = yield call(getImpedancePinMap, designId);

  let VRMInfo = yield call(_getVRM,
    {
      designId,
      GroundNets: [...MAIN_REF_NETS],
      PowerNets: [...MAIN_POWER_NETS],
      ExtendNets: [],
      COMP_PREFIX_LIB: setting,
      loadSelect,
      connectInductance: false,
      findExtend: checked,
      isAC: true,
      PMIC: getPMIC(setting),
      powerSwitch: setting.powerSwitch,
      buckConverter: setting.discreteBuckConverter || [],
      doNotStuff,
      table,
      pinMapSense,
      pinConnection,
      pinMap,
      extraNets
    })
  const newInfo = mergeVRMInfo(VRMInfo, content);
  newData[layoutIndex].powerDomains[findIndex] = {
    id: id,
    content: {
      ...newInfo,
      includeExtended: checked,
      ReferenceNets: [...ReferenceNets],
      PowerNets: [...new Set([...MAIN_POWER_NETS, ...VRMInfo.PowerNets])],
      MAIN_POWER_NETS: MAIN_POWER_NETS,
      MAIN_REF_NETS: MAIN_REF_NETS,
      extraNets: [...extraNets]
    }
  }
  yield call(saveLayouts, { data: newData, update: true });
}

function* mergeCompByPart(action) {
  const { mergeList, info } = action;
  const { id, value, model, models } = info;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const powerDomains = _data[findIndex].powerDomains;
  const index = powerDomains.findIndex(item => item.id === id);
  const currentData = powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  currentData.content.Components.forEach(item => {
    if (mergeList.includes(item.part)) {
      item.part = mergeList[0];
      item.value = value;
      item.models = models;
    }
  })
  powerDomains[index] = currentData;
  _data[findIndex].powerDomains = powerDomains;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* changeCompUsage(action) {
  const { compNames, part, checked, id } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const powerDomains = _data[findIndex].powerDomains;
  const index = powerDomains.findIndex(item => item.id === id);
  const currentData = powerDomains[index] || null;
  if (!currentData) {
    return;
  }

  currentData.content.Components.forEach(item => {
    if (item.part === part && compNames.includes(item.name)) {
      item.usage = checked ? item.COMP_TYPE : 'Unused';
    }
  })
  powerDomains[index] = currentData;
  _data[findIndex].powerDomains = powerDomains;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* removeComp(action) {
  const { id, compNames, part } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const powerDomains = _data[findIndex].powerDomains;
  const index = powerDomains.findIndex(item => item.id === id);
  const currentData = powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  currentData.content.Components.forEach(item => {
    if (item.part === part && compNames.includes(item.name)) {
      item.usage = 'Removed';
    }
  })
  powerDomains[index] = currentData;
  _data[findIndex].powerDomains = powerDomains;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* splitComponent(action) {
  const { id, compList, part, newPart } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const powerDomains = _data[findIndex].powerDomains;
  const index = powerDomains.findIndex(item => item.id === id);
  const currentData = powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  currentData.content.Components.forEach(item => {
    if (item.part === part && compList.includes(item.name)) {
      item.part = newPart;
    }
  })
  powerDomains[index] = currentData;
  _data[findIndex].powerDomains = powerDomains;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* updateComponentPrefix(action) {
  const { update = true } = action;
  yield put(needUpdateCompSetting(false));

  if (!update) {
    const { CascadeReducer: { Impedance: { data } } } = yield select();
    let _data = JSON.parse(JSON.stringify(data));

    yield* _data.map(function* (layout) {
      const version = yield call(componentSetting.getVersion, layout.designId);
      layout.COMP_PREFIX_LIB = { version }
    })
    yield call(saveLayouts, { data: _data, update: true });
    return;
  }

  yield put(updateImpedanceLoading(true));
  const { CascadeReducer: { Impedance: { verificationId, targetIC, targetDie, designId: currentDesignId } } } = yield select();
  const res = yield call(getImpedanceInfo, verificationId);
  const data = res.layouts;
  let _data = JSON.parse(JSON.stringify(data));

  yield put(updateImpedanceContent({ data, status: true }));

  yield* _data.map(function* (layout) {
    const { designId, connectors, COMP_PREFIX_LIB, powerDomains } = layout;
    const version = yield call(componentSetting.getVersion, designId);
    const designName = designConstructor.getDesignName(designId) || layout.designName;
    if (layout.index !== TARGET_PACKAGE_INDEX && layout.index !== TARGET_DIE_INDEX) {
      if (versionCompareSize(COMP_PREFIX_LIB.version, version)) {
        yield put(updateImpedanceLoading(`Tracing ${designName}...`));
        yield delay(100);
        yield call(getAuroraDB, designId);

        const connectorRule = (item) => { return item.from.length && item.from.every(f => f.designId !== designId) }
        const { falseArray: toConnectors } = splitArrayToArrays({ array: connectors.filter(item => item.from.length || item.to.length), rule: connectorRule });
        const setting = yield call(componentSetting.getPrefixLib, designId);
        const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
        const table = yield call(compTableHelper.getTableData, designId);
        const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName")
        const _pinConnection = yield call(compPinMap.getPinConnection, designId);
        const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
        const pinMap = yield call(getImpedancePinMap, designId);
        if (designId === currentDesignId) {
          let loadSelect = [{ comp: targetIC }];
          const specify = toConnectors.map(connector => { return getFindVRMSpecifyComp(connector, designId) });
          let _powerDomains = []
          for (let row of powerDomains) {
            const { content, id } = row;
            const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], includeExtended, extraNets = [] } = content;
            let VRMInfo = yield call(_getVRM, {
              designId: currentDesignId,
              GroundNets: [...MAIN_REF_NETS],
              PowerNets: [...MAIN_POWER_NETS],
              ExtendNets: [],
              COMP_PREFIX_LIB: setting,
              loadSelect,
              connectInductance: false,
              findExtend: includeExtended,
              isAC: true,
              PMIC: getPMIC(setting),
              powerSwitch: setting.powerSwitch,
              buckConverter: setting.discreteBuckConverter || [],
              doNotStuff,
              table,
              pinMapSense,
              pinConnection,
              pinMap,
              specify,
              extraNets
            })
            const newInfo = mergeVRMInfo(VRMInfo, content);
            let newRow = {
              id: id,
              content: {
                ...content,
                ...newInfo,
                PowerNets: [...new Set([...MAIN_POWER_NETS, ...VRMInfo.PowerNets])],
              }
            }
            _powerDomains.push(newRow)
          }
          layout.powerDomains = _powerDomains
        } else {
          const specify = toConnectors.map(connector => { return getFindVRMSpecifyComp(connector, designId) });
          let _powerDomains = []
          for (let row of powerDomains) {
            const { content, id } = row;
            const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], includeExtended, DEBUG_MONITOR, extraNets = [] } = content;
            const loadComp = DEBUG_MONITOR && DEBUG_MONITOR.length ? DEBUG_MONITOR[0].filter(item => item.type !== 'connect')[0].name : '';
            if (!loadComp) {
              continue;
            }
            let VRMInfo = yield call(_getVRM, {
              designId: designId,
              GroundNets: [...MAIN_REF_NETS],
              PowerNets: [...MAIN_POWER_NETS],
              ExtendNets: [],
              COMP_PREFIX_LIB: setting,
              loadSelect: [{ comp: loadComp }],
              connectInductance: false,
              findExtend: includeExtended,
              isAC: true,
              PMIC: getPMIC(setting),
              powerSwitch: setting.powerSwitch,
              buckConverter: setting.discreteBuckConverter || [],
              doNotStuff,
              table,
              pinMapSense,
              pinConnection,
              pinMap,
              specify,
              extraNets
            })
            const newInfo = mergeVRMInfo(VRMInfo, content);
            let newRow = {
              id: id,
              content: {
                ...content,
                ...newInfo,
                PowerNets: [...new Set([...MAIN_POWER_NETS, ...VRMInfo.PowerNets])],
              }
            }
            _powerDomains.push(newRow)
          }
          layout.powerDomains = _powerDomains
        }
      }
      layout.COMP_PREFIX_LIB = { version: version }
    } else if (layout.index === TARGET_PACKAGE_INDEX) {
      if (versionCompareSize(COMP_PREFIX_LIB.version, version)) {
        const setting = yield call(componentSetting.getPrefixLib, designId);
        if (setting && setting.Die && !_.isEqual(setting.Die, targetDie)) {
          const _targetDie = targetDie.every(item => setting.Die.includes(item)) ? targetDie : setting.Die
          yield put(updateImpedanceContent({ targetDie: _targetDie }));
          yield delay(10);
          yield call(saveSetup);

          yield put(updateImpedanceLoading(`Tracing ${designName}...`));
          yield delay(100);
          const { powerNets, referenceNets, DIE, BGA } = getPackagePowerDomain(designId, setting, _targetDie);
          let _powerNets = [...powerNets];
          let powerDomains = [];

          for (let powerNet of _powerNets) {
            const packageVRMs = yield call(getPkgDomainInfo, {
              pcbId: designId,
              powerNet,
              referenceNets,
              DIE: DIE.map(die => die.name),
              BGA: BGA.name,
              setting
            })

            if (!packageVRMs.add) {
              continue;
            }
            packageVRMs.domain.Components = yield call(updateComponentDecapModel, {
              components: packageVRMs.domain ? packageVRMs.domain.Components : [],
              prevComps: [],
              designId
            })

            powerDomains.push({
              id: "",
              content: { ...packageVRMs.domain }
            })
          }
          const bgaObj = getImpedanceLayoutComp(BGA.name, designId, BGAConst);
          bgaObj.from = [{ components: DIE.map(die => die.name), designId }];
          const dieObj = DIE.map(die => {
            const _dieObj = getImpedanceLayoutComp(die.name, designId, DIEConst)
            _dieObj.to = [{ components: [BGA.name], designId }];
            return _dieObj
          })

          if (currentDesignId !== designId && targetIC) {
            bgaObj.to = [{ components: [targetIC], designId: currentDesignId }];
            const currentIndex = _data.findIndex(item => item.designId === currentDesignId);
            if (currentIndex > -1) {
              const icIndex = _data[currentIndex].ICs.findIndex(item => item.name === targetIC);
              if (icIndex > -1) {
                _data[currentIndex].ICs[icIndex].from = [{ components: DIE.map(die => die.name), designId }]
              }
            }
          }

          const dieIndex = _data.findIndex(item => item.index === TARGET_DIE_INDEX)
          const dieNames = DIE.map(item => item.name)
          if (dieIndex > -1) {
            let ics = _data[dieIndex].ICs
            ics = ics.filter(ic => dieNames.includes(ic.to[0].components[0]))
            powerDomains.forEach(domain => {
              const { content } = domain
              const { Components, diePorts = [] } = content
              const dieInfo = Components.find(item => item.usage === DIE)
              const die = dieInfo ? dieInfo.name : null
              const powerNet = content.MAIN_POWER_NETS.length ? content.MAIN_POWER_NETS[0] : null
              const findIc = ics.find(ic => ic.to[0].components[0] === die)
              if (!findIc) {
                if (diePorts.length === 0) {
                  ics.push({
                    name: 'Die',
                    usage: 'DIE',
                    model: {
                      rdieValue: "10m",
                      cdieValue: "100n",
                      type: "value"
                    },
                    from: [],
                    to: [{
                      designId,
                      components: [die]
                    }],
                    rleak: '1',
                    rdl: '0.001',
                    powerNet: powerNet,
                    portIndex: '1'
                  })
                } else {
                  diePorts.forEach(item => {
                    ics.push({
                      name: 'Die',
                      usage: 'DIE',
                      model: {
                        rdieValue: "10m",
                        cdieValue: "100n",
                        type: "value"
                      },
                      from: [],
                      to: [{
                        designId,
                        components: [die]
                      }],
                      rleak: '1',
                      rdl: '0.001',
                      powerNet: powerNet,
                      portIndex: item.port.toString()
                    })
                  })
                }
              }
            })
            _data[dieIndex].ICs = ics
          }

          const { ballSizeMap } = layout
          const ballSizeMapObj = new BallSizeMap({ ballSizeMap, comps: [...dieObj, bgaObj], designId })
          const _ballSizeMap = ballSizeMapObj.getBallSizeMap()

          layout.Dies = dieObj;
          layout.Bgas = [bgaObj];
          layout.powerDomains = powerDomains;
          layout.ballSizeMap = _ballSizeMap;
        }
      }
      layout.COMP_PREFIX_LIB = { version: version }
    }
  })

  let layoutPowerDomains = {};
  if (_data.length > 1) {
    for (let layout of _data) {
      const { powerDomains, designId: design, index } = layout;
      if (index === TARGET_PACKAGE_INDEX || layout.index === TARGET_DIE_INDEX) {
        continue
      }
      layoutPowerDomains[design] = powerDomains.map(item => {
        const { id, content } = item;
        const DEBUG_MONITOR = (content && content.DEBUG_MONITOR ? content.DEBUG_MONITOR : []).map(monitor => monitor.filter(m => m.type !== 'connect'));
        return { ...content, id, DEBUG_MONITOR }
      })
    }
    layoutPowerDomains = mergeConnectionTrace(_data, layoutPowerDomains);
  }

  for (let layout of _data) {
    const { designId, connectors, index, powerDomains } = layout;
    if (index === TARGET_PACKAGE_INDEX || layout.index === TARGET_DIE_INDEX) {
      continue
    }
    const newPowerDomains = layoutPowerDomains[designId] || powerDomains.map(item => ({ id: item.id, ...item.content }));
    const VRMs = newPowerDomains.map(pow => (pow.VRM && pow.VRM.map(vrm => vrm.VRM_COMP)) || []).flat(3);
    const PMICs = [...new Set(VRMs)].filter(vrm => !connectors.find(conn => conn.name === vrm));
    layout.PMICs = PMICs.map(pmic => getImpedanceLayoutComp(pmic, designId, PMIC));
    layout.powerDomains = newPowerDomains.map(item => {
      const { id, ...content } = item;
      return { id, content }
    })
  }
  yield call(saveLayouts, { data: _data, update: true });

  yield put(updateImpedanceLoading(false));
}

function* updateExtraction(action) {
  const { designId, config, apply } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  const newConfig = new ImpExtraction({ ...config });
  let _data = [...data];
  const index = _data.findIndex(item => item.designId === designId);
  if (index > -1) {
    _data[index].extraction = newConfig;
    if (apply) {
      _data.forEach(d => d.extraction = newConfig);
    }
    yield put(updateImpedanceContent({ data: _data }));
    yield call(saveLayouts, { data: _data, update: false });
  }
}

function* updateOpt(action) {
  const { config, opt } = action;
  const { CascadeReducer: { Impedance: { Config } } } = yield select();
  const newConfig = { ...Config, ...config };
  yield put(updateImpedanceContent({ Config: newConfig, Optimization: opt }));
  yield delay(500);
  yield call(saveSetup);
}

function* updateOptions(action) {
  const { options } = action;
  const { CascadeReducer: { Impedance: { Options } } } = yield select();
  const _options = new ImpOptions({ ...Options, ...options });
  yield put(updateImpedanceContent({ Options: _options }));
  yield delay(500);
  yield call(saveSetup);
}

function* updateMultiPCBTarget(action) {
  const { target } = action;
  yield put(updateImpedanceContent({ target }));
  yield delay(500);
  yield call(saveSetup);
}

function* saveDieCurrent(action) {
  const { dieCurrent, currentDesignId, currentComp, currentPowerNet, currentIndex, apply } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === currentDesignId)
  if (findIndex < 0) {
    return;
  }
  if (_data[findIndex].index === TARGET_DIE_INDEX) {
    if (apply) {
      _data[findIndex].ICs.forEach(ic => {
        if (ic.to[0].components[0] === currentComp) {
          ic.dieCurrent = dieCurrent
        }
      })
    } else {
      _data[findIndex].ICs.forEach(ic => {
        if (ic.powerNet === currentPowerNet && ic.portIndex === currentIndex && ic.to[0].components[0] === currentComp) {
          ic.dieCurrent = dieCurrent
        }
      })
    }
  } else {
    _data[findIndex].ICs.forEach(ic => {
      if (ic.name === currentComp) {
        ic.dieCurrent = dieCurrent
      }
    })
  }

  const stopTime = dieCurrent.stopTime
  _data.forEach(item => {
    item.ICs.forEach(ic => {
      if (ic.dieCurrent && Number(ic.dieCurrent.stopTime) < Number(stopTime)) {
        ic.dieCurrent.stopTime = stopTime
      }
    })
  })
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* refreshImp(action) {
  const { selectNets } = action;
  const { CascadeReducer: { project: { openProjectId }, Impedance: { verificationId, targetIC, data, powerNetSelect: { allPowerNets } } } } = yield select();
  if (targetIC) {
    yield put(updateSelectPowerNets({ select: selectNets, allPowerNets: [...new Set([...allPowerNets, ...selectNets])], open: false }));
    yield call(saveSetup)
    const designList = CascadeChannels.getList(IMPEDANCE, openProjectId);
    const currentItem = [...designList].find(i => i.id === verificationId);
    yield put(openTabFooter());
    yield put(changeTabMenu({
      tabSelectKeys: ["detail"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : IMPEDANCE,
      menuType: "simulation"
    }))
    yield put(updateLogs([], true))
    yield put(updateLogs([{ type: 'success', text: '[Layouts] The selected Nets are saved successfully.' }]))
    if (data.length === 1) {
      yield call(getVRMs);
    } else if (data.length > 1) {
      yield put(updateLogs([{ type: 'normal', text: '[Layouts] Multiple PCB/Packages are detected. Please click the Trace button to trace.' }]))
    }
  }
}

function* updateImpedanceByVersion({ data, version, verificationId }) {
  let newData = { ...data };
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  if (versionCompareSize(version, '0.0.2')) {
    newData.powerDomains.forEach(item => {
      item.content.VRM.forEach(vrm => {
        if (!Array.isArray(vrm.VRM_COMP)) {
          vrm.VRM_COMP = [vrm.VRM_COMP]
        }
      })
    })
  }

  if (versionCompareSize(version, '0.0.3')) {
    const includeExtended = data.includeExtended;
    newData.powerDomains.forEach(item => {
      item.content.includeExtended = includeExtended;
    });
    const currentProjectDesignID = projectDesigns.getAvailablePCBsFirstId(openProjectId);

    // load PCB
    try {
      yield call(getAuroraDB, currentProjectDesignID);
    } catch (error) {
      console.error(error)
    }

    let loadSelect = [{ comp: newData.targetIC }];
    let newPowerDomain = [];
    const setting = yield call(componentSetting.getPrefixLib, currentProjectDesignID);
    const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, currentProjectDesignID);
    const table = yield call(compTableHelper.getTableData, currentProjectDesignID);
    const _pinConnection = yield call(compPinMap.getPinConnection, currentProjectDesignID);
    const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
    const pinMap = yield call(getImpedancePinMap, currentProjectDesignID);
    if (currentProjectDesignID) {
      const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], currentProjectDesignID, "pinName")
      for (let row of newData.powerDomains) {
        const { id, content } = row;
        const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], includeExtended, extraNets = [] } = content;
        let VRMInfo = yield call(_getVRM,
          {
            designId: currentProjectDesignID,
            GroundNets: [...MAIN_REF_NETS],
            PowerNets: [...MAIN_POWER_NETS],
            ExtendNets: [],
            COMP_PREFIX_LIB: newData.setting,
            loadSelect,
            connectInductance: false,
            findExtend: includeExtended,
            isAC: true,
            PMIC: getPMIC(setting),
            powerSwitch: setting.powerSwitch,
            buckConverter: setting.discreteBuckConverter || [],
            doNotStuff,
            table,
            pinMapSense,
            pinConnection,
            pinMap,
            extraNets
          })
        const newInfo = mergeVRMInfo(VRMInfo, content);
        let newRow = {
          id: id,
          content: {
            ...content,
            ...newInfo,
            PowerNets: [...new Set([...MAIN_POWER_NETS, ...VRMInfo.PowerNets])],
          }
        }
        newPowerDomain.push(newRow)
      }
      newData.powerDomains = [...newPowerDomain];
    }
  }

  if (versionCompareSize(version, '0.0.4')) {
    newData.powerDomains.forEach(item => {
      if (!item.content.target) {
        item.content.target = [];
      }
      if (!item.content.ports) {
        item.content.ports = [];
      }
    })
  }

  if (versionCompareSize(version, '0.0.5')) {
    if (!newData.designId) {
      const designId = projectDesigns.getAvailablePCBsFirstId(openProjectId);
      newData.designId = designId
      yield call(selectTaskPCB, { verificationId, designId })
    }
  }

  if (versionCompareSize(version, '0.0.6')) {
    //Add isMultiNets to cap components to supports caps that connect to more than 2 nets
    // load PCB
    try {
      yield call(getAuroraDB, newData.designId);
    } catch (error) {
      console.error(error)
    }

    newData.powerDomains.forEach(item => {
      item.content.Components = addMultiNetsFieldToCapComps({
        pcbId: newData.designId,
        Components: item.content.Components,
        isVersionUpdate: true
      })
    })
  }

  if (versionCompareSize(version, '0.0.8')) {
    //support gnd sense ports , update sense ports json format
    newData.powerDomains.forEach(item => {
      if (item.content.sensePort && item.content.sensePort.length && !item.content.sensePorts) {
        item.content.sensePorts = item.content.sensePort.map(it => {
          return {
            powerSensePort: it,
            groundSensePort: []
          }
        })
      } else {
        item.content.sensePorts = []
      }
    })
  }

  //Support clipping to SIwavePSI solver,default open clipping
  if (versionCompareSize(version, '0.0.9') && newData.Config && newData.Config.type === PSI && newData.Config.CLIP !== 1) {
    newData.Config.CLIP = 1;
  }

  if (versionCompareSize(version, '0.0.10')) {
    newData.powerDomains.forEach(item => {
      if (item.content) {
        item.content.voltage = '1';
      }
    })
  }

  if (versionCompareSize(version, '1.0.0')) {
    try {
      if (newData.designId) {
        yield call(getAuroraDB, newData.designId);
      }
    } catch (error) {
      console.error(error)
    }
    newData = upgradeSetupDataToMulti(newData);
  }

  if (versionCompareSize(version, '1.0.1')) {
    const extraction = new ImpExtraction(newData.Config);
    if (newData.layouts) {
      newData.layouts.forEach(layout => layout.extraction = extraction);
    }
  }

  if (versionCompareSize(version, '1.0.2')) {
    let selectPowerNets = []
    if (newData.designId && newData.targetIC && newData.layouts) {
      const layout = newData.layouts.find(d => d.designId === newData.designId);
      if (layout && layout.powerDomains) {
        layout.powerDomains.forEach(item => {
          selectPowerNets.push(...item.content.MAIN_POWER_NETS)
        })
        newData.targetNets = [...new Set(selectPowerNets)];
      }
    }
  }

  if (versionCompareSize(version, '1.0.3')) {
    if (newData.layouts) {
      newData.layouts.forEach(layout => {
        layout.connectors.forEach(connector => {
          connector.from.forEach(f => {
            if (f.nets) {
              f.nets = setNewConnectorNetsSpec(f.nets);
            }
          })
          connector.to.forEach(t => {
            if (t.nets) {
              t.nets = setNewConnectorNetsSpec(t.nets);
            }
          })
        })
      })
    }
  }

  if (versionCompareSize(version, '1.0.4')) {
    if (newData.layouts) {
      newData.layouts.forEach(layout => {
        if (layout.extraction.type === SIWAVE) {
          layout.extraction['errorTolerance'] = '0.005'
        }
      })
    }
  }

  if (versionCompareSize(version, '1.1.0')) {
    const findDieIndex = newData.layouts.findIndex(item => item.index === TARGET_DIE_INDEX)
    if (findDieIndex > -1) {
      const layout = newData.layouts[findDieIndex]
      const _ICs = JSON.parse(JSON.stringify(layout.ICs || []))
      if (layout.dieCurrent) {
        _ICs.forEach(ic => ic.dieCurrent = layout.dieCurrent)
      }
      const pkgLayout = newData.layouts.find(item => item.index === TARGET_PACKAGE_INDEX)
      const newICs = []
      _ICs.forEach(ic => {
        const die = ic.to[0].components[0]
        const targetDomain = pkgLayout.powerDomains.find(domain => {
          return domain.content.Components.find(item => item.name === die)
        })
        if (targetDomain) {
          const powerNet = targetDomain.content.MAIN_POWER_NETS.length > 0 ? targetDomain.content.MAIN_POWER_NETS[0] : null
          ic.powerNet = powerNet
          if (targetDomain.content.diePorts.length === 0) {
            ic.portIndex = '1'
            newICs.push(ic)
          } else {
            targetDomain.content.diePorts.forEach(portInfo => {
              const _ic = JSON.parse(JSON.stringify(ic))
              _ic.portIndex = portInfo.port
              newICs.push(_ic)
            })
          }
        }
      })
      newData.layouts[findDieIndex].ICs = newICs
    }
  }

  if (versionCompareSize(version, '1.1.1')) {
    const findDieIndex = newData.layouts.findIndex(item => item.index === TARGET_DIE_INDEX)
    if (findDieIndex > -1) {
      const layout = newData.layouts[findDieIndex]
      const _ICs = JSON.parse(JSON.stringify(layout.ICs || []))
      const findIc = _ICs.find(ic => ic.model)
      let customType
      if (findIc) {
        const type = findIc.model.type || 'value'
        customType = type === 'CPM' ? 'CPM' : 'perPort'
      } else {
        customType = 'perPort'
      }
      newData.layouts[findDieIndex].customType = customType
    }
  }

  if (versionCompareSize(version, '1.1.2')) {
    if (newData.layouts) {
      newData.layouts.forEach(layout => {
        layout.powerDomains.forEach(domain => {
          domain.content.Components.forEach(comp => {
            if ([CAP, IPD].includes(comp.usage) && comp.model) {
              comp.models = [{ ...comp.model, key: 0 }]
            }
          })
        })
      })
    }
  }

  return newData;
}

function* saveTarget(action) {
  const { id, target } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  const currentData = _data[findIndex].powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  currentData.content.target = target && target.length ? target : [];
  _data[findIndex].powerDomains[index] = currentData;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* savePorts(action) {
  const { id, ports } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  const currentData = _data[findIndex].powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  currentData.content.ports = ports ? [...ports] : [];
  _data[findIndex].powerDomains[index] = currentData;

  const findPackgeIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findPackgeIndex > -1) {
    let packgeIndex
    if (_data[findIndex].powerDomains.length === 1 && _data[findPackgeIndex].powerDomains.length === 1) {
      packgeIndex = 0
    } else if (_data[findIndex].powerDomains.length > 1 || _data[findPackgeIndex].powerDomains.length > 1) {
      packgeIndex = _data[findPackgeIndex].powerDomains.findIndex(item => {
        return item.content.MAIN_POWER_NETS.length &&
          currentData.content.MAIN_POWER_NETS.length &&
          _.isEqual(item.content.MAIN_POWER_NETS, currentData.content.MAIN_POWER_NETS)
      });
    }
    const packageCurrentData = _data[findPackgeIndex].powerDomains[packgeIndex] || null;
    if (packgeIndex > -1) {
      packageCurrentData.content.bgaPorts = ports ? [...ports] : [];
      _data[findPackgeIndex].powerDomains[packgeIndex] = packageCurrentData;
    }
  }

  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* saveLoadPorts(action) {
  const { load, id, ports } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  const currentData = _data[findIndex].powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  currentData.content.Components.find(comp => comp.name === load).loadPorts = ports ? [...ports] : [];
  _data[findIndex].powerDomains[index] = currentData;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* updateImpedancePCB(action) {
  const { pcbId, designType } = action;
  const { CascadeReducer: { Impedance: { designId, verificationId, data, designType: prevDesignType, page } } } = yield select();
  yield put(updateDesignStatus(true));
  yield put(updateImpedanceLoading('Loading...'));
  try {
    yield call(selectTaskPCB, { verificationId, designId: pcbId })
    if (pcbId) {
      yield call(getAuroraDB, pcbId);
    }

    let designName = "";
    if (pcbId) {
      designName = designConstructor.getDesignName(pcbId);
      yield put(updateImpedanceContent({ designName }));
      yield call(saveSetup)
    }
    yield put(updateImpedanceContent({ designType }));
    if (designType !== prevDesignType) {
      yield call(deleteImpedanceDomains, { verificationId, ids: data.map(p => p.powerDomainIds ? p.powerDomainIds : p.powerDomains.map(d => d.id)).flat(2) });
      if (designType === IMPEDANCE_PACKAGE) {
        yield call(updateDesignPackage, { pcbId })
      } else if (designType === IMPEDANCE_PCB) {
        yield put(updateImpedanceContent({ data: [], status: true }));
        yield call(updatePCBDesign, { pcbId })
      }
    } else if (designType === IMPEDANCE_PCB) {
      if (designId !== pcbId) {
        yield call(updatePCBDesign, { pcbId })
      }
    } else if (designType === IMPEDANCE_PACKAGE) {
      yield call(updateDesignPackage, { pcbId })
    }

    yield delay(100);
    if (page === designId) {
      yield call(changePageByDesign, { page: pcbId })
    }
    yield put(updatePCB(pcbId))

    yield delay(100);
    yield call(saveSetup)
  } catch (error) {
    console.error(error)
  }
  yield put(updateDesignStatus(false))
  yield put(updateImpedanceLoading(false));
}

function* updatePCBDesign({ pcbId }) {
  const { CascadeReducer: { Impedance: { verificationId, data, targetIC, designId } } } = yield select();
  let pcbLog = [];
  if (!data.length) {
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    yield put(updateImpedanceContent({ targetIC: "", targetDie: [], powerNetSelect: { select: [] } }));
    const version = yield call(componentSetting.getVersion, pcbId);
    const COMP_PREFIX_LIB = { version: version }
    const pcbName = designConstructor.getDesignName(pcbId);
    const vendor = designConstructor.getDesignVendor(pcbId)
    const extraction = vendor === SPD ? new ImpExtraction({ type: POWERSI }) : new ImpExtraction();
    if (auroraDBJson.isFlexBoard(pcbId)) {
      extraction.CLIP = 0
    }
    const _data = [new ImpLayout({ index: TARGET_PCB_INDEX, designId: pcbId, designName: pcbName, type: 'pcb', COMP_PREFIX_LIB, extraction })];
    yield call(saveLayouts, { data: _data, update: true });
    if (isPreLayout) {
      yield delay(500);
      const preData = yield call([preLayoutData, preLayoutData.getPreLayout], pcbId);
      const { content = {} } = preData;
      const { components = [] } = content;
      const ic = components.find(item => item.type === IC);
      let targetIC = ic ? ic.name : ""
      yield call(changeTargetIC, { value: targetIC, pcbId })
    }
    return;
  }

  if (data.length === 1) {
    const setting = yield call(componentSetting.getPrefixLib, pcbId);
    const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
    const table = yield call(compTableHelper.getTableData, pcbId);
    const netsInfo = getNets(pcbId);
    if (!netsInfo || !netsInfo.loadComponents || !netsInfo.loadComponents.map(comp => comp.name).includes(targetIC)) {
      pcbLog.push("[Update] TargetIC does not exist.")
      yield put(updateImpedanceContent({ targetIC: "", data: [] }));
      yield put(updateSelectPowerNets({ select: [], allPowerNets: [], allNets: [], open: false }))
      const version = yield call(componentSetting.getVersion, pcbId);
      const COMP_PREFIX_LIB = { version: version }
      const pcbName = designConstructor.getDesignName(pcbId);
      const extraction = data[0].extraction || {}
      const _data = [new ImpLayout({ index: TARGET_PCB_INDEX, designId: pcbId, designName: pcbName, type: 'pcb', COMP_PREFIX_LIB, extraction })];
      yield call(deleteImpedanceDomains, { verificationId, ids: data.map(p => p.powerDomainIds ? p.powerDomainIds : p.powerDomains.map(d => d.id)).flat(2) });
      yield put(updateImpedanceContent({ data: _data, targetIC: "", targetDie: [], powerNetSelect: { select: [] } }));
      yield call(saveLayouts, { data: _data, update: true });
      yield call(saveSetup);
    } else {
      const { powerNets, groundNet, allNets } = getPowerDomain({ chip: targetIC, designId: pcbId, COMP_PREFIX_LIB: setting });
      let deleteTableDatas = [], select = [];
      data[0].powerDomains.forEach(d => {
        const { id, content = {} } = d;
        const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [] } = content;
        if (!MAIN_POWER_NETS.every(item => netsInfo.nets.includes(item))) {
          pcbLog.push(`[Update][Power Domains] ${MAIN_POWER_NETS.join(',')} does not exist.`)
          deleteTableDatas.push(id)
        } else if (!MAIN_REF_NETS.includes(groundNet)) {
          pcbLog.push(`[Update][Power Domains] ${MAIN_REF_NETS.join(',')} does not exist.`)
          deleteTableDatas.push(id)
        } else {
          select.push(...MAIN_POWER_NETS)
        }
      })
      yield put(updateSelectPowerNets({ select, allPowerNets: [...new Set([...powerNets, ...select])], allNets: [...allNets], open: false }))
      const newTableData = data[0].powerDomains.filter(item => !deleteTableDatas.includes(item.id));
      yield call(deleteImpedanceDomains, { verificationId, ids: deleteTableDatas });
      yield call(saveSetup)
      let loadSelect = [{ comp: targetIC }], newData = [];
      const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
      const _pinConnection = yield call(compPinMap.getPinConnection, designId);
      const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
      const pinMap = yield call(getImpedancePinMap, designId);
      for (let row of newTableData) {
        const { id, content } = row;
        const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], includeExtended, ports: _ports, extraNets = [] } = content;
        let VRMInfo = yield call(_getVRM,
          {
            designId: pcbId,
            GroundNets: [...MAIN_REF_NETS],
            PowerNets: [...MAIN_POWER_NETS],
            ExtendNets: [],
            COMP_PREFIX_LIB: setting,
            loadSelect,
            connectInductance: false,
            findExtend: includeExtended,
            isAC: true,
            PMIC: getPMIC(setting),
            powerSwitch: setting.powerSwitch,
            buckConverter: setting.discreteBuckConverter || [],
            doNotStuff,
            table,
            pinMapSense,
            pinConnection,
            pinMap,
            extraNets
          })
        const { ports, deletePower = [], deleteRefe = [] } = filterPortsByPCB(pcbId, targetIC, MAIN_POWER_NETS, MAIN_REF_NETS, _ports);
        if (deletePower.length) {
          pcbLog.push(`[Update][Power Domains][${MAIN_POWER_NETS.join(',')}][Ports] Pins "${deletePower.join(',')}" does not exist.`)
        }
        if (deleteRefe.length) {
          pcbLog.push(`[Update][Power Domains][${MAIN_REF_NETS.join(',')}][Ports] Pins "${deleteRefe.join(',')}" does not exist.`)
        }
        const componentUpdate = getCompUpdateLog({ ...VRMInfo, MAIN_POWER_NETS }, content);
        pcbLog.push(...componentUpdate)
        const newInfo = mergeVRMInfo(VRMInfo, { ...content, ports });
        let newRow = {
          id: id,
          content: {
            ...content,
            ...newInfo,
            PowerNets: [...new Set([...MAIN_POWER_NETS, ...VRMInfo.PowerNets])],
          }
        }
        newData.push(newRow)
      }
      const _data = [...data];
      const _layout = data[0];
      _layout.powerDomains = newData;
      const { connectors } = _layout;
      const VRMs = newData.map(domain => (domain.content && domain.content.VRM && domain.content.VRM.map(vrm => vrm.VRM_COMP)) || []).flat(3);
      const PMICs = [...new Set(VRMs)].filter(vrm => !connectors.find(conn => conn.name === vrm));
      _layout.PMICs = PMICs.map(pmic => getImpedanceLayoutComp(pmic, pcbId, PMIC));
      const version = yield call(componentSetting.getVersion, pcbId);
      const COMP_PREFIX_LIB = { version: version }
      const pcbName = designConstructor.getDesignName(pcbId);
      _layout.designId = pcbId;
      _layout.designName = pcbName;
      _layout.COMP_PREFIX_LIB = COMP_PREFIX_LIB;
      let newAdditionalNets = _layout.additionalNets || [];
      if (newAdditionalNets.length) {
        const nets = getAllCascadeNets({ pcbId });
        const oldNets = getAllCascadeNets({ pcbId: designId });
        newAdditionalNets = oldNets.length === newAdditionalNets.length ? nets : newAdditionalNets.filter(net => nets.includes(net))
        _layout.additionalNets = [...newAdditionalNets];
      }
      _data[0] = _layout
      yield call(saveLayouts, { data: _data, update: true });
      if (pcbLog.length) {
        yield put(openTabFooter())
      }
      yield put(updatePCBLog(pcbLog))
    }
    return;
  }

  if (data.length > 1) {
    const setting = yield call(componentSetting.getPrefixLib, pcbId);
    const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
    const table = yield call(compTableHelper.getTableData, pcbId);
    let layouts = [...data];
    if (!layouts.find(item => item.designId === pcbId)) {
      const pcbLayout = layouts.find(item => item.index === TARGET_PCB_INDEX)
      const netsInfo = getNets(pcbId);
      let ICs = []
      if (!netsInfo || !netsInfo.loadComponents || !netsInfo.loadComponents.map(comp => comp.name).includes(targetIC)) {
        pcbLog.push("[Update] TargetIC does not exist.")
        yield put(updateImpedanceContent({ targetIC: "", data: [] }));
        yield put(updateSelectPowerNets({ select: [], allPowerNets: [], allNets: [], open: false }))
      } else {
        const { powerNets, groundNet, allNets } = getPowerDomain({ chip: targetIC, designId: pcbId, COMP_PREFIX_LIB: setting });
        let deleteTableDatas = [], select = [];
        pcbLayout.powerDomains.forEach(d => {
          const { id, content = {} } = d;
          const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [] } = content;
          if (!MAIN_POWER_NETS.every(item => netsInfo.nets.includes(item))) {
            pcbLog.push(`[Update][Power Domains] ${MAIN_POWER_NETS.join(',')} does not exist.`)
            deleteTableDatas.push(id)
          } else if (!MAIN_REF_NETS.every(item => netsInfo.nets.includes(item))) {
            pcbLog.push(`[Update][Power Domains] ${MAIN_REF_NETS.join(',')} does not exist.`)
            deleteTableDatas.push(id)
          } else {
            select.push(...MAIN_POWER_NETS)
          }
        })
        yield put(updateSelectPowerNets({ select, allPowerNets: [...new Set([...powerNets, ...select])], allNets: [...allNets], open: false }))
        const newTableData = pcbLayout.powerDomains.filter(item => !deleteTableDatas.includes(item.id));
        yield call(deleteImpedanceDomains, { verificationId, ids: deleteTableDatas });
        yield call(saveSetup)
        let loadSelect = [{ comp: targetIC }], newData = [];
        const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
        const pinConnection = yield call(compPinMap.getPinConnection, designId);
        const pinMap = yield call(getImpedancePinMap, designId);
        for (let row of newTableData) {
          const { id, content } = row;
          const { MAIN_POWER_NETS = [], MAIN_REF_NETS = [], includeExtended, ports: _ports, extraNets = [] } = content;
          const ic = getImpedanceLayoutComp(targetIC, pcbId, IC);
          let VRMInfo = yield call(_getVRM,
            {
              designId: pcbId,
              GroundNets: [...MAIN_REF_NETS],
              PowerNets: [...MAIN_POWER_NETS],
              ExtendNets: [],
              COMP_PREFIX_LIB: setting,
              loadSelect,
              connectInductance: false,
              findExtend: includeExtended,
              isAC: true,
              PMIC: getPMIC(setting),
              powerSwitch: setting.powerSwitch,
              buckConverter: setting.discreteBuckConverter || [],
              doNotStuff,
              table,
              pinMapSense,
              pinConnection,
              pinMap,
              extraNets
            })
          if (VRMInfo.DEBUG_MONITOR.length) {
            VRMInfo.DEBUG_MONITOR.forEach(monitor => {
              const _monitor = monitor.filter(item => item.type === 'comp');
              const last = _monitor[_monitor.length - 1].name;
              ic.to.push({ designId: pcbId, components: [last] })
            })
            ICs.push(ic)
          }
          const { ports, deletePower = [], deleteRefe = [] } = filterPortsByPCB(pcbId, targetIC, MAIN_POWER_NETS, MAIN_REF_NETS, _ports);
          if (deletePower.length) {
            pcbLog.push(`[Update][Power Domains][${MAIN_POWER_NETS.join(',')}][Ports] Pins "${deletePower.join(',')}" does not exist.`)
          }
          if (deleteRefe.length) {
            pcbLog.push(`[Update][Power Domains][${MAIN_REF_NETS.join(',')}][Ports] Pins "${deleteRefe.join(',')}" does not exist.`)
          }
          const componentUpdate = getCompUpdateLog({ ...VRMInfo, MAIN_POWER_NETS }, content);
          pcbLog.push(...componentUpdate)
          const newInfo = mergeVRMInfo(VRMInfo, { ...content, ports });
          let newRow = {
            id: id,
            content: {
              ...content,
              ...newInfo,
              PowerNets: [...new Set([...MAIN_POWER_NETS, ...VRMInfo.PowerNets])],
            }
          }
          newData.push(newRow)
        }

        pcbLayout.ICs = ICs
        pcbLayout.powerDomains = newData;
        const newConnectors = pcbLayout.connectors
        const vrmComp = pcbLayout.ICs[0].to[0].components[0]
        if (newConnectors && newConnectors.length) {
          newConnectors[0].from[0].designId = pcbId
          const nets = newConnectors[0].to[0].nets
          const newNets = {}
          Object.keys(nets).forEach(key => {
            if (key.split('-')[0] === vrmComp) {
              newNets[`${vrmComp}-${pcbId}`] = nets[key]
            } else {
              newNets[key] = nets[key]
            }
          })
          newConnectors[0].to[0].nets = newNets
        }
        pcbLayout.connectors = newConnectors

        const VRMs = newData.map(domain => (domain.content && domain.content.VRM && domain.content.VRM.map(vrm => vrm.VRM_COMP)) || []).flat(3);
        const PMICs = [...new Set(VRMs)].filter(vrm => !pcbLayout.connectors.find(conn => conn.name === vrm));
        pcbLayout.PMICs = PMICs.map(pmic => getImpedanceLayoutComp(pmic, pcbId, PMIC));
        const version = yield call(componentSetting.getVersion, pcbId);
        const COMP_PREFIX_LIB = { version: version }
        const pcbName = designConstructor.getDesignName(pcbId);
        pcbLayout.designId = pcbId;
        pcbLayout.designName = pcbName;
        pcbLayout.COMP_PREFIX_LIB = COMP_PREFIX_LIB;
        let newAdditionalNets = pcbLayout.additionalNets || [];
        if (newAdditionalNets.length) {
          const nets = getAllCascadeNets({ pcbId });
          const oldNets = getAllCascadeNets({ pcbId: designId });
          newAdditionalNets = oldNets.length === newAdditionalNets.length ? nets : newAdditionalNets.filter(net => nets.includes(net))
          pcbLayout.additionalNets = [...newAdditionalNets];
        }
      }

      const vendor = designConstructor.getDesignVendor(pcbId)
      let extraction = vendor === SPD ? new ImpExtraction({ type: POWERSI }) : new ImpExtraction();
      if (data.length) {
        const layout = data.find(item => item.index !== TARGET_DIE_INDEX);
        if (layout) {
          extraction = new ImpExtraction(layout.extraction || {});
        }
      }
      pcbLayout.extraction = extraction

      const connectIndex = layouts.findIndex(item => item.index > TARGET_PCB_INDEX)
      if (connectIndex > -1) {
        const _layout = layouts[connectIndex]
        const connectComp = _layout.connectors.length ? _layout.connectors[0].from[0].components[0] : null
        if (pcbLayout.ICs[0].to[0].components[0] === connectComp) {
          _layout.connectors[0].from[0].designId = pcbId
          const nets = _layout.connectors[0].from[0].nets
          const newNets = {}
          Object.keys(nets).forEach(key => {
            if (key.split('-')[0] === connectComp) {
              newNets[`${connectComp}-${pcbId}`] = nets[key]
            } else {
              newNets[key] = nets[key]
            }
          })
          _layout.connectors[0].from[0].nets = newNets
        } else {
          _layout.connectors[0].from = []
        }
      }
      layouts = [pcbLayout, ...layouts.filter(item => item.index !== TARGET_PCB_INDEX)]
    }
    yield put(updatePCB(pcbId))
    yield call(saveLayouts, { data: layouts, update: true });
    yield delay(100)
    yield call(getVRMs);
    yield call(saveSetup)
    return;
  }
}

function* openPowerNetSelectPanel(action) {
  const { obj: { open, isLoadingNets = false } } = action;
  if (!open || !isLoadingNets) {
    yield put(updateOpenTargetICLoading(false));
    return;
  }
  //open power nets panel
  const { CascadeReducer: { project: { openProjectId },
    Impedance: { targetIC, verificationId, designId, powerNetSelect } } } = yield select();
  if (powerNetSelect && powerNetSelect.allPowerNets && powerNetSelect.allPowerNets.length) {
    yield put(updateOpenTargetICLoading(false));
    return;
  }
  const designExist = projectDesigns.getDesignExist(openProjectId, designId)
  if (targetIC && designId && designExist && verificationId) {
    const setting = yield fork(componentSetting.getPrefixLib, designId);
    const { powerNets, allNets } = getPowerDomain({ chip: targetIC, designId, COMP_PREFIX_LIB: setting });
    const targetNets = powerNetSelect.select
    yield put(updateSelectedPowerNets({ select: targetNets, allPowerNets: [...new Set([...powerNets, ...targetNets])], allNets: [...allNets], open: true }))
    yield put(updateOpenTargetICLoading(false))
  }
}

function* reAssignDecapModel() {
  const { CascadeReducer: { Impedance: { data = [] } } } = yield select();

  yield put(updateImpedanceContent({ modelAssignLoading: true }));
  let _data = [...data];

  let existModelParts = [];
  yield* _data.map(function* (layout) {
    const { designId } = layout
    yield* layout.powerDomains.map(function* (item) {
      const info = yield call(updateComponentDecapModel, {
        designId,
        components: item.content.Components,
        prevComps: [],
        existModelParts,
        type: "re-assign",
        saveLib: false,
        voltage: item.content.voltage || "1"
      });
      item.content.Components = info.components;
      existModelParts = info.existModelParts;
    })
  })

  yield put(updateImpedanceContent({ data: _data, status: true, modelAssignLoading: false }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
  const { CascadeReducer: { project: { applyModelList, openProjectId } } } = yield select();
  yield call(updateLibSetting, { librarySettings: applyModelList, projectId: openProjectId });
}

function* deleteSensePath(action) {
  const { key, id, senseType } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  const currentData = _data[findIndex].powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  if (senseType === "power") {
    currentData.content.sensePorts.splice(key);
  } else if (senseType === "gnd") {
    currentData.content.sensePorts[key].groundSensePort = [];
  }
  _data[findIndex].powerDomains[index] = currentData;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

let saveAdditionWork
function* saveAdditionalNets(action) {
  const { nets } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  const findIndex = data.findIndex(item => item.designId === page);
  if (findIndex > -1) {
    let layouts = [...data];
    layouts[findIndex].additionalNets = nets
    yield put(updateImpedanceContent({ data: layouts }));
    yield put({ type: SAVE_POWER_DOMAIN, data: layouts });
  }
}

function* getImpedancePinMap(pcbId) {
  let pinMap = yield call(compPinMap.getPinMapData, pcbId);

  let pinMapList = []
  yield* pinMap.map(function* (_map) {
    let data = [];
    if (_map.libraryId) {
      try {
        const res = yield call([pinMapStore, pinMapStore.getPinMapLibrary], _map, pcbId, "pinName")
        data = res && res.config && res.config.pinTable ? [...res.config.pinTable] : [];
      } catch (error) {
        console.error("Can not find library file");
      }
    }
    pinMapList.push({ ..._map, data })
  })
  return pinMapList
}

// Impedance Package saga function
function* updateDesignPackage({ pcbId }) {
  yield call(deletePackageDomains);
  yield put(updateImpedanceContent({ data: [], status: true }));
  yield call(saveLayouts, { data: [], update: false });
  yield delay(100);
  yield call(createLayout, { pcbId, connectors: [] })
}

function* switchDie(action) {
  const { die } = action;
  const { CascadeReducer: { Impedance: { targetDie = [], data } } } = yield select();
  if (!_.isEqual(die, targetDie)) {
    yield call(deletePackageDomains);
    yield put(updateImpedanceContent({ targetDie: die }));
    yield delay(10);
    yield call(saveSetup);
    const layout = data.find(item => item.index === TARGET_PACKAGE_INDEX);
    if (!layout) {
      return;
    }
    yield call(updatePackageDomain, layout.designId)
  }
}

function* deletePackageDomains() {
  const { CascadeReducer: { Impedance: { verificationId, data = [] } } } = yield select();
  const findIndex = data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findIndex > -1) {
    yield call(deleteImpedanceDomains, { verificationId, ids: data[findIndex].powerDomains.map(d => d.id) });
    let _data = [...data];
    _data[findIndex].powerDomains = [];
    yield put(updateImpedanceContent({ data: _data, status: true }));
  }
}

function* updatePackageDomain(pcbId, fromPCB) {
  const { CascadeReducer: { project: { openProjectId }, Impedance: { targetDie, verificationId, data = [], targetIC, designId: currentDesignId, powerNetSelect: { select: selectNets } } } } = yield select();

  const isPreLayout = designConstructor.isPreLayout(pcbId);
  let setting = new CascadeCompRLCPrefixLib({})
  if (isPreLayout) {
    yield call([preLayoutData, preLayoutData.getPreLayout], pcbId)
  } else {
    setting = yield call([componentSetting, componentSetting.getPrefixLib], pcbId);
    yield call(getAuroraDB, pcbId);
  }

  if (!targetDie.length) {
    return;
  }
  let _data = [...data];
  if (!fromPCB) {
    const designList = CascadeChannels.getList(IMPEDANCE, openProjectId);
    const currentItem = [...designList].find(i => i.id === verificationId);
    yield put(openTabFooter());
    yield put(changeTabMenu({
      tabSelectKeys: ["detail"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : IMPEDANCE,
      menuType: "simulation"
    }))
    yield put(updateLogs([], true))
    yield delay(100);
  }
  const { powerNets, referenceNets, DIE, BGA } = getPackagePowerDomain(pcbId, setting, targetDie);
  let _powerNets = [...powerNets];
  if (targetIC && selectNets.length && currentDesignId !== pcbId && !isPreLayout) {
    const { nets } = checkConnectCompsPinsAndNets({ designId: currentDesignId, name: targetIC, nets: selectNets }, { designId: pcbId, name: BGA.name })
    if (nets && nets.length) {
      _powerNets = _powerNets.filter(item => nets.includes(item))
    }
  }
  yield put(updateImpedanceLoading('Loading...'));
  const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findIndex < 0) {
    yield put(updateImpedanceLoading(false));
    return;
  }

  let powerDomains = [];
  const _targetDie = [];
  for (let powerNet of _powerNets) {
    const packageVRMs = yield call(getPkgDomainInfo, {
      pcbId,
      powerNet,
      referenceNets,
      DIE: DIE.map(die => die.name),
      BGA: BGA.name,
      setting
    })

    if (!packageVRMs.add) {
      continue;
    }
    packageVRMs.domain.Components = yield call(updateComponentDecapModel, {
      components: packageVRMs.domain ? packageVRMs.domain.Components : [],
      prevComps: [],
      designId: pcbId
    })

    const die = targetDie.find(item => {
      return packageVRMs.domain.Components.find(comp => comp.name === item)
    })
    if (die && !_targetDie.includes(die)) {
      _targetDie.push(die)
    }

    powerDomains.push({
      id: "",
      content: { ...packageVRMs.domain }
    })
  }

  const { DIE: _DIE } = getPackagePowerDomain(pcbId, setting, _targetDie);
  const bgaObj = getImpedanceLayoutComp(BGA.name, pcbId, BGAConst);
  bgaObj.from = [{ components: _DIE.map(die => die.name), designId: pcbId }];
  const dieObj = _DIE.map(die => {
    const _dieObj = getImpedanceLayoutComp(die.name, pcbId, DIEConst)
    _dieObj.to = [{ components: [BGA.name], designId: pcbId }];
    return _dieObj
  })

  if (currentDesignId !== pcbId && targetIC) {
    bgaObj.to = [{ components: [targetIC], designId: currentDesignId }];
    const currentIndex = _data.findIndex(item => item.designId === currentDesignId);
    if (currentIndex > -1) {
      const icIndex = _data[currentIndex].ICs.findIndex(item => item.name === targetIC);
      if (icIndex > -1) {
        _data[currentIndex].ICs[icIndex].from = [{ components: _DIE.map(die => die.name), designId: pcbId }]
      }
    }
  }

  const dieIndex = _data.findIndex(item => item.index === TARGET_DIE_INDEX)
  const dieNames = _DIE.map(item => item.name)
  if (dieIndex > -1) {
    let ics = _data[dieIndex].ICs
    ics = ics.filter(ic => dieNames.includes(ic.to[0].components[0]))
    powerDomains.forEach(domain => {
      const { content } = domain
      const { Components, diePorts = [] } = content
      const dieInfo = Components.find(item => item.usage === DIEConst)
      const die = dieInfo && dieInfo.name ? dieInfo.name : null
      const powerNet = content.MAIN_POWER_NETS.length ? content.MAIN_POWER_NETS[0] : null
      const findIc = ics.find(ic => ic.to[0].components[0] === die)
      if (!findIc) {
        if (diePorts.length === 0) {
          ics.push({
            name: 'Die',
            usage: 'DIE',
            model: {
              rdieValue: "10m",
              cdieValue: "100n",
              type: "value"
            },
            from: [],
            to: [{
              designId: pcbId,
              components: [die]
            }],
            rleak: '1',
            rdl: '0.001',
            powerNet: powerNet,
            portIndex: '1'
          })
        } else {
          diePorts.forEach(item => {
            ics.push({
              name: 'Die',
              usage: 'DIE',
              model: {
                rdieValue: "10m",
                cdieValue: "100n",
                type: "value"
              },
              from: [],
              to: [{
                designId: pcbId,
                components: [die]
              }],
              rleak: '1',
              rdl: '0.001',
              powerNet: powerNet,
              portIndex: item.port.toString()
            })
          })
        }
      }
    })
    _data[dieIndex].ICs = ics
  }

  const { ballSizeMap } = _data[findIndex]
  const ballSizeMapObj = new BallSizeMap({ ballSizeMap, comps: [...dieObj, bgaObj], designId: pcbId })
  const _ballSizeMap = ballSizeMapObj.getBallSizeMap()

  _data[findIndex].Dies = dieObj;
  _data[findIndex].Bgas = [bgaObj];
  _data[findIndex].powerDomains = powerDomains;
  _data[findIndex].ballSizeMap = _ballSizeMap;

  yield put(updateImpedanceContent({ targetDie: _targetDie }));
  yield call(saveSetup);

  const designName = designConstructor.getDesignName(pcbId);
  yield put(updateLogs([{ type: 'success', text: `[Package] [${designName}] Found ${powerDomains.length} Power Domains` }, { type: 'normal', text: `[Package] Saving...` }]))
  yield delay(100)

  yield put(updateImpedanceLoading(false));
  try {
    yield call(saveLayouts, { data: _data, update: true });
    yield put(updateLogs([{ type: 'normal', text: `[Package] Save completed.` }]))
  } catch (e) {
    yield put(updateLogs([{ type: 'error', text: `[Package] Save failed...` }]))
    console.error("Package Error:" + e)
  }
}

function* savePackagePorts(action) {
  const { id, ports, portType } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findIndex < 0) {
    return;
  }
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  const currentData = _data[findIndex].powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  currentData.content[portType] = ports ? [...ports] : [];
  _data[findIndex].powerDomains[index] = currentData;

  if (portType === 'diePorts') {
    const findDieIndex = _data.findIndex(item => item.index === TARGET_DIE_INDEX)
    if (findDieIndex > -1) {
      const designId = _data[findIndex].designId
      const powerNet = currentData.content.MAIN_POWER_NETS[0]
      const die = currentData.content.Components.find(item => item.usage === DIE).name
      const ICs = _data[findDieIndex].ICs
      let ics = JSON.parse(JSON.stringify(ICs))
      let newICs = []
      const leftICs = ics.filter(ic => ic.powerNet !== powerNet)
      if (ports.length === 0) {
        const ic = {
          name: 'Die',
          usage: 'DIE',
          model: {
            rdieValue: "10m",
            cdieValue: "100n",
            type: "value"
          },
          from: [],
          to: [{
            designId,
            components: [die]
          }],
          rleak: '1',
          rdl: '0.001',
          powerNet,
          portIndex: '1'
        }
        newICs = [ic, ...leftICs]
      } else {
        let newIC = []
        ports.forEach(item => {
          const ic = {
            name: 'Die',
            usage: 'DIE',
            model: {
              rdieValue: "10m",
              cdieValue: "100n",
              type: "value"
            },
            from: [],
            to: [{
              designId,
              components: [die]
            }],
            rleak: '1',
            rdl: '0.001',
            powerNet,
            portIndex: item.port.toString()
          }
          newIC.push(ic)
        })
        newICs = [...newIC, ...leftICs]
      }
      _data[findDieIndex].ICs = newICs
    }
  }
  if (portType === 'bgaPorts') {
    const findPCBIndex = _data.findIndex(item => item.index === TARGET_PCB_INDEX);
    if (findPCBIndex > -1) {
      let pcbIndex
      if (_data[findIndex].powerDomains.length === 1 && _data[findPCBIndex].powerDomains.length === 1) {
        pcbIndex = 0
      } else if (_data[findIndex].powerDomains.length > 1 || _data[findPCBIndex].powerDomains.length > 1) {
        pcbIndex = _data[findPCBIndex].powerDomains.findIndex(item => {
          return item.content.MAIN_POWER_NETS.length &&
            currentData.content.MAIN_POWER_NETS.length &&
            _.isEqual(item.content.MAIN_POWER_NETS, currentData.content.MAIN_POWER_NETS)
        });
      }
      const pcbCurrentData = _data[findPCBIndex].powerDomains[pcbIndex] || null;
      if (pcbIndex > -1) {
        const _ports = ports ? JSON.parse(JSON.stringify(ports)) : [];
        _ports.forEach(item => delete item.target)
        pcbCurrentData.content.ports = _ports
        _data[findPCBIndex].powerDomains[pcbIndex] = pcbCurrentData;
      }
    }
  }

  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* updatePackageNets(action) {
  const { id, nets } = action;
  const { CascadeReducer: { Impedance: { data, targetDie } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findIndex < 0) {
    return;
  }
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  if (index < 0) { return }
  _data[findIndex].powerDomains[index].content = { ..._data[findIndex].powerDomains[index].content, ...nets }
  const { PowerNets, ReferenceNets } = _data[findIndex].powerDomains[index].content;
  if (PowerNets.length && ReferenceNets.length) {
    const pcbId = _data[findIndex].designId;
    const setting = yield call([componentSetting, componentSetting.getPrefixLib], pcbId);

    const { DIE, BGA } = getPackagePowerDomain(pcbId, setting, targetDie);
    const packageVRMs = yield call(getPkgDomainInfo, {
      pcbId,
      powerNet: PowerNets[0],
      referenceNets: ReferenceNets,
      DIE: DIE.map(die => die.name),
      BGA: BGA.name,
      setting
    })

    _data[findIndex].powerDomains[index].content = { ...packageVRMs.domain };
    const findDieIndex = _data.findIndex(item => item.index === TARGET_DIE_INDEX);
    if (findDieIndex > -1) {
      const { Components = [] } = packageVRMs.domain
      const comp = Components.find(item => item.usage === DIEConst)
      const powerNetExist = _data[findDieIndex].ICs.findIndex(ic => ic.powerNet === PowerNets[0])
      if (comp && comp.name && powerNetExist < 0) {
        const newIC = {
          name: 'Die',
          usage: 'DIE',
          model: {
            rdieValue: "10m",
            cdieValue: "100n",
            type: "value"
          },
          from: [],
          to: [{
            designId: _data[findIndex].designId,
            components: [comp.name]
          }],
          rleak: '1',
          rdl: '0.001',
          powerNet: PowerNets[0],
          portIndex: '1'
        }
        _data[findDieIndex].ICs.push(newIC)
      }
    }
  }
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* refindPackagePins(action) {
  const { id } = action;
  const { CascadeReducer: { Impedance: { data, designId, targetDie } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findIndex < 0) {
    return;
  }
  const setting = yield call(componentSetting.getPrefixLib, _data[findIndex].designId);
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  if (index < 0) { return }
  let currentData = _data[findIndex].powerDomains[index];
  if (!currentData) {
    return;
  }
  const { content } = currentData;
  const { PowerNets, ReferenceNets, Components, diePorts = [] } = content;
  const BGA = Components.find(item => item.COMP_TYPE === 'BGA');
  const DIE = Components.filter(item => item.COMP_TYPE === 'DIE');
  if (!BGA || !DIE) {
    return;
  }
  const components = getNewPackageComp({ PowerNets, ReferenceNets, designId: _data[findIndex].designId, BGA: BGA ? BGA.name : '', DIE: DIE.map(die => die.name), setting });
  currentData.content.Components = yield call(updateComponentDecapModel, {
    components: components ? components : [],
    prevComps: [],
    designId: _data[findIndex].designId
  });
  _data[findIndex].powerDomains[index] = { ...currentData };

  if (!_data[findIndex].Dies.some(die => DIE.map(die => die.name).includes(die.name))) {
    const dieObj = DIE.map(die => {
      const _dieObj = getImpedanceLayoutComp(die.name, _data[findIndex].designId, DIEConst)
      _dieObj.to = [{ components: [BGA.name], designId: _data[findIndex].designId }];
      return _dieObj
    })
    _data[findIndex].Dies.push(...dieObj);
  }
  const dieIndex = _data.findIndex(item => item.index === TARGET_DIE_INDEX)
  const dieNames = DIE.map(item => item.name)
  if (dieIndex > -1) {
    let ics = _data[dieIndex].ICs
    dieNames.forEach(item => {
      if (!ics.find(ic => ic.to[0].components[0] === item)) {
        if (diePorts.length === 0) {
          ics.push({
            name: 'Die',
            usage: 'DIE',
            model: {
              rdieValue: "10m",
              cdieValue: "100n",
              type: "value"
            },
            from: [],
            to: [{
              designId: _data[findIndex].designId,
              components: [item]
            }],
            rleak: '1',
            rdl: '0.001',
            powerNet: PowerNets[0],
            portIndex: '1'
          })
        } else {
          diePorts.forEach(item => {
            ics.push({
              name: 'Die',
              usage: 'DIE',
              model: {
                rdieValue: "10m",
                cdieValue: "100n",
                type: "value"
              },
              from: [],
              to: [{
                designId: _data[findIndex].designId,
                components: [item]
              }],
              rleak: '1',
              rdl: '0.001',
              powerNet: PowerNets[0],
              portIndex: item.port.toString()
            })
          })
        }
      }
    })
    _data[dieIndex].ICs = ics
  }
  const _targetDie = targetDie
  dieNames.forEach(item => {
    if (!_targetDie.includes(item)) {
      _targetDie.push(item)
    }
  })
  yield put(updateImpedanceContent({ data: _data, targetDie: _targetDie, status: true }));
  yield delay(10);
  yield call(saveSetup)
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* switchPackagePorts(action) {
  const { lumped, compType } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  yield put(updateImpedanceLoading('Loading...'));
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  const findPCBIndex = _data.findIndex(item => item.index === TARGET_PCB_INDEX);
  if (findIndex < 0) {
    return;
  }
  const designId = _data[findIndex].designId;
  const portType = compType === 'BGA' ? 'bgaPorts' : 'diePorts';

  if (lumped) {
    _data[findIndex].powerDomains.forEach(row => {
      row.content[portType] = []
    })
    if (compType === 'BGA' && findPCBIndex > -1) {
      _data[findPCBIndex].powerDomains.forEach(row => {
        row.content.ports = []
      })
    }
    yield put(updateImpedanceContent({ data: _data, status: true }));
  } else {
    try {
      yield call(getLayoutDB, designId);
    } catch (error) {
      console.error(error)
      return;
    }

    for (let i = 0; i < _data[findIndex].powerDomains.length; i++) {
      let _content = _data[findIndex].powerDomains[i].content || {};
      if (_content[portType] && _content[portType].length) {
        continue;
      }

      const { PowerNets, ReferenceNets, Components } = _content

      const component = Components.find(item => item.COMP_TYPE === compType);
      if (!component) {
        continue;
      }

      const DesignData = LayoutData.getLayout(designId, true);
      const filterData = getPortData(DesignData, component.name, PowerNets, ReferenceNets);
      const { powerPins, referencePins, data: PCBData } = filterData;
      let newPorts = []
      if (PowerNets.length === 1 && powerPins[PowerNets[0]] && powerPins[PowerNets[0]].length === 1 &&
        ReferenceNets.length === 1 && referencePins[ReferenceNets[0]] && referencePins[ReferenceNets[0]].length === 1) {
        newPorts = [{ port: "1", powerPins: powerPins[PowerNets[0]], referencePins: referencePins[ReferenceNets[0]] }]
      } else {
        const powers = Object.values(powerPins).flat(2), refe = Object.values(referencePins).flat(2);
        const pinsInfo = PCBData.getComponent(component.name).mPinsLocationList;
        const powerInfo = pinsInfo.filter(pin => powers.includes(pin.pin)).map(pin => ({ ...pin, ...pin.mLocation })),
          refeInfo = pinsInfo.filter(pin => refe.includes(pin.pin)).map(pin => ({ ...pin, ...pin.mLocation }));
        const powerInfoWithRefe = autoSplitRefeByPower(powerInfo, refeInfo, []);
        newPorts = powers.map((p, index) => {
          const find = powerInfoWithRefe.find(item => item.pinNumber === p);
          return { port: String(index + 1), powerPins: [p], referencePins: find ? [...find.refePins] : [] }
        });
      }

      _data[findIndex].powerDomains[i].content[portType] = newPorts

      if (compType === 'BGA' && findPCBIndex > -1) {
        let pcbIndex
        if (_data[findIndex].powerDomains.length === 1 && _data[findPCBIndex].powerDomains.length === 1) {
          pcbIndex = 0
        } else if (_data[findIndex].powerDomains.length > 1 || _data[findPCBIndex].powerDomains.length > 1) {
          pcbIndex = _data[findPCBIndex].powerDomains.findIndex(item => {
            return item.content.MAIN_POWER_NETS.length &&
              _data[findIndex].powerDomains[i].content.MAIN_POWER_NETS.length &&
              _.isEqual(item.content.MAIN_POWER_NETS, _data[findIndex].powerDomains[i].content.MAIN_POWER_NETS)
          });
        }
        const pcbCurrentData = _data[findPCBIndex].powerDomains[pcbIndex] || null;
        if (pcbIndex > -1) {
          const _ports = newPorts ? JSON.parse(JSON.stringify(newPorts)) : [];
          _ports.forEach(item => delete item.target)
          pcbCurrentData.content.ports = _ports
          _data[findPCBIndex].powerDomains[pcbIndex] = pcbCurrentData;
        }
      }

      yield put(updateImpedanceContent({ data: _data, status: true }));
      yield delay(5);
    }
  }
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
  yield put(updateImpedanceLoading(false));
}

function* updateBallSize(action) {
  const { pcbId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = JSON.parse(JSON.stringify(data));
  const index = _data.findIndex(item => item.designId === pcbId);
  if (index < 0) {
    return;
  }
  const ballSizeMap = _data[index].ballSizeMap || {};
  ballSizeMap[compTab] = { ballShape, ballSize, ballHeight, ballMiddle, ballMaterial }
  _data[index].ballSizeMap = { ...ballSizeMap }

  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* updatePkgDomain(action) {
  const { update } = action;
  yield put(needUpdateCompSetting(false));

  if (!update) {
    return;
  }

  if (update === 'updateVersion') {
    const { CascadeReducer: { Impedance: { data, designId } } } = yield select();
    let _data = [...data];
    const version = yield call([componentSetting, componentSetting.getVersion], designId);
    const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
    _data[findIndex].COMP_PREFIX_LIB = { version: version };
    yield call(saveLayouts, { data: _data, update: true });
    return;
  }

  const { CascadeReducer: { Impedance: { data, designId, verificationId, targetDie } } } = yield select();
  const version = yield call([componentSetting, componentSetting.getVersion], designId);

  yield put(updateImpedanceLoading('Loading...'));
  yield delay(10);

  const setting = yield call(componentSetting.getPrefixLib, designId);
  const _targetDie = targetDie.every(item => setting.Die.includes(item)) ? targetDie : setting.Die
  const { DIE, BGA, powerNets, referenceNets } = getPackagePowerDomain(designId, setting, _targetDie);

  let _data = [...data];
  const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findIndex < 0) {
    yield put(updateImpedanceLoading(false));
    return;
  }
  yield call(deleteImpedanceDomains, { verificationId, ids: _data[findIndex].powerDomains.map(d => d.id) });

  for (let powerNet of powerNets) {
    const packageVRMs = yield call(getPkgDomainInfo, {
      pcbId: designId,
      powerNet,
      referenceNets,
      DIE: DIE.map(die => die.name),
      BGA: BGA.name,
      setting
    })

    if (!packageVRMs.add) {
      continue;
    }

    const prevData = _data[findIndex].powerDomains.find(item => item.content.PowerNets.some(p => p === powerNet));
    const prevComps = prevData ? prevData.content.Components : []
    packageVRMs.domain.Components = yield call(updateComponentDecapModel, {
      components: packageVRMs.domain ? packageVRMs.domain.Components : [],
      prevComps: prevComps,
      designId
    })

    _data[findIndex].powerDomains.push({
      id: "",
      content: { ...packageVRMs.domain }
    })
  }
  _data[findIndex].COMP_PREFIX_LIB = { version: version }
  yield put(updateImpedanceContent({ targetDie: _targetDie }));
  yield delay(10);
  yield call(saveSetup);
  yield call(saveLayouts, { data: _data, update: true });
  yield put(updateImpedanceLoading(false));
}

function* saveWireBondFile(action) {
  const { libraryId, designId } = action;
  if (!designId) {
    return;
  }
  try {
    yield call(savePackageWireBondProfile, { libraryId: libraryId || null, designId });
  } catch (error) {
    console.error(error)
  }
}
// End (Impedance Package saga)

function* changePageByDesign(action) {
  const { page } = action;
  const designName = designConstructor.getDesignName(page)
  yield put(updateImpedanceLoading(`Loading PCB - ${designName}...`));
  if (page && page !== OVERVIEW) {
    yield delay(100);
    yield call(getAuroraDB, page);
  }
  yield put(updateImpedanceContent({ page }));
  yield put(updateImpedanceLoading(false));
}

function* createLayout(action) {
  const { pcbId, connectors } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = JSON.parse(JSON.stringify(data));
  const version = yield call(componentSetting.getVersion, pcbId);
  const COMP_PREFIX_LIB = { version: version }
  const designName = designConstructor.getDesignName(pcbId);
  const isPreLayout = designConstructor.isPreLayout(pcbId);
  const vendor = designConstructor.getDesignVendor(pcbId)
  const type = isPreLayout ? designConstructor.getPreLayoutType(pcbId) : designConstructor.getDesignType(pcbId);
  let extraction = vendor === SPD ? new ImpExtraction({ type: POWERSI }) : new ImpExtraction();
  if (data.length) {
    const layout = data.find(item => item.index !== TARGET_DIE_INDEX);
    if (layout) {
      extraction = new ImpExtraction(layout.extraction || {});
    }
  }
  if (auroraDBJson.isFlexBoard(pcbId)) {
    extraction.CLIP = 0
  }
  if (type === IMPEDANCE_PACKAGE) {
    const newLayout = new ImpLayout({ index: TARGET_PACKAGE_INDEX, designId: pcbId, designName, type, COMP_PREFIX_LIB, extraction });
    _data = _data.filter(item => item.index !== TARGET_PACKAGE_INDEX);
    _data.forEach(layout => {
      layout.ICs.forEach(ic => {
        ic.applyCurrent = false
      })
    })
    _data.push(newLayout);
    yield put(updateImpedanceContent({ data: _data, status: true }));
    let Dies = [];
    if (isPreLayout) {
      const preData = yield call([preLayoutData, preLayoutData.getPreLayout], pcbId);
      const { content = {} } = preData;
      const { components = [] } = content;
      Dies = components.filter(item => item.type === DIE).map(item => item.name);
    } else {
      const setting = yield call([componentSetting, componentSetting.getPrefixLib], pcbId);
      const { Die = [] } = setting;
      Dies = Die;
    }
    if (Dies.length > 0) {
      yield put(updateImpedanceContent({ targetDie: Dies }));
      yield call(saveSetup);
    }
    yield call(updatePackageDomain, pcbId)
    return;
  }
  let maxIndex = getConnectorLayoutIndex(connectors, _data);
  let newLayoutIndex = _data.findIndex(item => item.designId === pcbId);
  if (newLayoutIndex < 0) {
    const newLayout = new ImpLayout({ index: maxIndex + 1, designId: pcbId, designName, type, COMP_PREFIX_LIB, extraction });
    _data.push(newLayout);
    newLayoutIndex = _data.length - 1;
  } else {
    maxIndex = _data[newLayoutIndex].index - 1;
  }

  if (connectors.length) {
    for (let connector of connectors) {
      const { name, netShip, designId: _designId } = connector;
      if (!name) continue;
      let currentConnIndex = _data[newLayoutIndex].connectors.findIndex(c => c.name === name);
      _data[newLayoutIndex].PMICs = _data[newLayoutIndex].PMICs.filter(c => c.name !== name);
      if (currentConnIndex < 0) {
        let component = getImpedanceLayoutComp(name, pcbId, CONNECTOR);
        _data[newLayoutIndex].connectors.push(component);
        currentConnIndex = _data[newLayoutIndex].connectors.length - 1
      }
      for (let ship of netShip) {
        const { designId, connector, nets: connectNets } = ship;
        if (!connector) continue;
        let layoutIndex = _data.findIndex(item => item.designId === designId);
        if (layoutIndex < 0) {
          const version = yield call(componentSetting.getVersion, designId);
          const COMP_PREFIX_LIB = { version: version }
          const designName = designConstructor.getDesignName(designId);
          const type = designConstructor.getDesignType(designId);
          const _newLayout = new ImpLayout({ index: maxIndex + 2, designId, designName, type, COMP_PREFIX_LIB, extraction });
          _data.push(_newLayout);
          layoutIndex = _data.length - 1;
        }

        let connComp = getImpedanceLayoutComp(connector, designId, CONNECTOR);
        let currentKey = _data[layoutIndex].index < _data[newLayoutIndex].index ? 'from' : 'to';
        let connKey = currentKey === 'to' ? 'from' : 'to';

        let nets = {
          [`${name}-${_designId}`]: [],
          [`${connector}-${designId}`]: []
        }
        for (let connectNet of connectNets) {
          if (!connectNet[0] || !connectNet[1]) {
            continue;
          }
          nets[`${name}-${_designId}`].push(connectNet[0]);
          nets[`${connector}-${designId}`].push(connectNet[1]);
        }
        _data[newLayoutIndex].connectors[currentConnIndex][currentKey] = _data[newLayoutIndex].connectors[currentConnIndex][currentKey].filter(item => item.designId !== designId || (item.designId === designId && !item.components.includes(connector)))
        _data[newLayoutIndex].connectors[currentConnIndex][currentKey].push({ designId, components: [connector], nets });

        let connIndex = _data[layoutIndex].connectors.findIndex(c => c.name === connector);
        _data[layoutIndex].PMICs = _data[layoutIndex].PMICs.filter(c => c.name !== connector);
        if (connIndex < 0) {
          _data[layoutIndex].connectors.push(connComp);
          connIndex = _data[layoutIndex].connectors.length - 1
        }
        _data[layoutIndex].connectors[connIndex][connKey] = _data[layoutIndex].connectors[connIndex][connKey].filter(item => item.designId !== pcbId || (item.designId === pcbId && !item.components.includes(name)))
        _data[layoutIndex].connectors[connIndex][connKey].push({ designId: pcbId, components: [name], nets })
      }
    }
  }
  yield call(saveLayouts, { data: _data, update: true });
}

const compTypes = ['connectors', 'Bgas', 'Dies', 'ICs', 'PMICs'];
function* deleteLayout(action) {
  const { pcbId } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();

  let _data = [];
  const isPackage = data.find(item => item.designId === pcbId && item.index === TARGET_PACKAGE_INDEX);
  const isDie = data.find(item => item.designId === pcbId && item.index === TARGET_DIE_INDEX);
  for (let layout of data) {
    if (isPackage && layout.index === TARGET_DIE_INDEX) {
      continue;
    }
    if (layout.designId !== pcbId) {
      let _layout = { ...layout };
      if (isDie && layout.extraction && layout.extraction.FMAX && layout.extraction.FMAX === '2e9') {
        _layout.extraction.FMAX = '2e8';
      }
      for (let compType of compTypes) {
        if (!_layout[compType]) {
          continue;
        }
        _layout[compType].forEach(conn => {
          conn.from = conn.from.filter(item => item.designId !== pcbId);
          conn.to = conn.to.filter(item => item.designId !== pcbId);
        })
      }
      _data.push(layout);
    }
  }
  if (isDie || isPackage) {
    yield put(updateImpedanceContent({ hasInterposer: false }));
    yield delay(10);
    yield call(saveSetup);
  }
  yield call(saveLayouts, { data: _data, update: true });
}

function* deleteLayoutConnector(action) {
  const { pcbId, connector } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const checkComps = ['Bgas', 'Dies', 'ICs', 'PMICs', 'connectors']
  const findIndex = _data.findIndex(item => item.designId === pcbId);
  if (findIndex > -1) {
    const conn = _data[findIndex].connectors.find(iten => iten.name === connector);
    _data[findIndex].connectors = _data[findIndex].connectors.filter(c => c.name !== connector);
    const connection = [...conn.from, ...conn.to];
    for (let connect of connection) {
      const { components, designId } = connect;
      const findIndex = _data.findIndex(item => item.designId === designId);
      if (findIndex < 0) {
        continue;
      }
      checkComps.forEach(type => {
        if (_data[findIndex][type]) {
          _data[findIndex][type] = _data[findIndex][type].map(comp => {
            if (components.includes(comp.name)) {
              let newComp = { ...comp };
              newComp.from.forEach(f => {
                f.components = f.components.filter(fc => fc !== connector);
                f.nets && delete f.nets[`${connector}-${pcbId}`];
              })
              newComp.from = newComp.from.filter(f => f.components.length);

              newComp.to.forEach(t => {
                t.components = t.components.filter(tc => tc !== connector);
                t.nets && delete t.nets[`${connector}-${pcbId}`];
              })
              newComp.to = newComp.to.filter(t => t.components.length);
              return newComp;
            }
            return comp;
          })
        }
      })
    }
    yield call(saveLayouts, { data: _data, update: true });
  }
}

function* traceByLayout() {
  yield call(getVRMs);
}

function* deleteShip(action) {
  const { ship, connector } = action;
  const { designId, connector: connName } = ship
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const designIndex = _data.findIndex(item => item.designId === designId);
  if (designIndex > -1) {
    let connIndex = _data[designIndex].connectors.findIndex(item => item.name === connName);
    if (connIndex > -1) {
      const fromIndex = _data[designIndex].connectors[connIndex].from.findIndex(item => item.components.includes(connector.name) && item.designId === connector.designId);
      if (fromIndex > -1) {
        const fromItem = _data[designIndex].connectors[connIndex].from[fromIndex];
        fromItem.components = fromItem.components.filter(comp => comp !== connector.name);
        if (!fromItem.components.length) {
          _data[designIndex].connectors[connIndex].from.splice(fromIndex, 1)
        }
      }

      const toIndex = _data[designIndex].connectors[connIndex].to.findIndex(item => item.components.includes(connector.name) && item.designId === connector.designId);
      if (toIndex > -1) {
        const toItem = _data[designIndex].connectors[connIndex].to[toIndex];
        toItem.components = toItem.components.filter(comp => comp !== connector.name);
        if (!toItem.components.length) {
          _data[designIndex].connectors[connIndex].to.splice(toIndex, 1)
        }
      }
    }
  }

  const { designId: _designId, name } = connector;
  const _designIndex = _data.findIndex(item => item.designId === _designId);
  if (_designIndex > -1) {
    let connIndex = _data[_designIndex].connectors.findIndex(item => item.name === name);
    if (connIndex > -1) {
      const fromIndex = _data[_designIndex].connectors[connIndex].from.findIndex(item => item.components.includes(ship.connector) && item.designId === ship.designId);
      if (fromIndex > -1) {
        const fromItem = _data[_designIndex].connectors[connIndex].from[fromIndex];
        fromItem.components = fromItem.components.filter(comp => comp !== ship.connector);
        if (!fromItem.components.length) {
          _data[_designIndex].connectors[connIndex].from.splice(fromIndex, 1)
        }
      }

      const toIndex = _data[_designIndex].connectors[connIndex].to.findIndex(item => item.components.includes(ship.connector) && item.designId === ship.designId);
      if (toIndex > -1) {
        const toItem = _data[_designIndex].connectors[connIndex].to[toIndex];
        toItem.components = toItem.components.filter(comp => comp !== ship.connector);
        if (!toItem.components.length) {
          _data[_designIndex].connectors[connIndex].to.splice(toIndex, 1)
        }
      }
    }
  }

  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* createDie() {
  const { CascadeReducer: { Impedance: { data } } } = yield select();

  let _data = JSON.parse(JSON.stringify(data));
  const newLayout = new ImpLayout({ index: TARGET_DIE_INDEX, designId: IMPEDANCE_DIE, designName: 'Die', type: IMPEDANCE_DIE });
  const pkgIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX)
  if (pkgIndex < 0) {
    return;
  }
  let newICs = []
  const pkgDesignId = _data[pkgIndex].designId
  _data[pkgIndex].powerDomains.forEach(domain => {
    const { content } = domain
    const die = content.Components.find(item => item.usage === DIE).name
    const powerNet = content.MAIN_POWER_NETS.length ? content.MAIN_POWER_NETS[0] : null
    if (content.diePorts.length === 0) {
      newICs.push({
        name: 'Die',
        usage: 'DIE',
        model: {
          rdieValue: "10m",
          cdieValue: "100n",
          type: "value"
        },
        from: [],
        to: [{
          designId: pkgDesignId,
          components: [die]
        }],
        rleak: '1',
        rdl: '0.001',
        powerNet: powerNet,
        portIndex: '1'
      })
    } else {
      content.diePorts.forEach(item => {
        const ic = {
          name: 'Die',
          usage: 'DIE',
          model: {
            rdieValue: "10m",
            cdieValue: "100n",
            type: "value"
          },
          from: [],
          to: [{
            designId: pkgDesignId,
            components: [die]
          }],
          rleak: '1',
          rdl: '0.001',
          powerNet: powerNet,
          portIndex: item.port.toString()
        }
        newICs.push(ic)
      })
    }
  })

  newLayout.ICs = [...newICs]
  newLayout.customType = 'perPort'
  _data = _data.filter(item => item.index !== TARGET_DIE_INDEX);
  _data.forEach(layout => {
    if (layout.extraction && layout.extraction.FMAX && layout.extraction.FMAX === '2e8') {
      layout.extraction.FMAX = '2e9'
    }
  })
  _data.push(newLayout);
  _data[pkgIndex].Dies.forEach(item => item.from = [{ designId: IMPEDANCE_DIE, components: ['Die'] }]);
  yield call(saveLayouts, { data: _data, update: true });
}

function* saveOnDieModel(action) {
  const { model, targetDie, modelPowerNet, modelIndex, apply, customType, dieCurrentList } = action;
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = JSON.parse(JSON.stringify(data));
  const dieIndex = _data.findIndex(item => item.type === IMPEDANCE_DIE)
  if (dieIndex < -1) {
    return;
  }
  const prevCustomType = _data[dieIndex].customType
  _data[dieIndex].customType = customType
  if (customType === 'perPort') {
    if (apply) {
      _data[dieIndex].ICs.forEach(ic => {
        if (ic.to[0].components[0] === targetDie) {
          ic.model = model
        }
      })
    } else {
      _data[dieIndex].ICs.forEach(ic => {
        if (ic.powerNet === modelPowerNet && ic.portIndex === modelIndex && ic.to[0].components[0] === targetDie) {
          ic.model = model
        } else if (prevCustomType !== customType) {
          ic.model = {
            rdieValue: "10m",
            cdieValue: "100n",
            type: "value"
          }
        }
      })
    }
  } else if (customType === 'unified') {
    const { pairs, ...modelInfo } = model
    const lastPair = pairs.length ? pairs[pairs.length - 1] : {}
    let pairsIndex = 0;
    _data[dieIndex].ICs.forEach((ic, index) => {
      if (ic.powerNet === modelPowerNet && ic.to[0].components[0] === targetDie) {
        ic.model = { ...modelInfo, pairs: pairs.length && pairsIndex < pairs.length ? [pairs[pairsIndex], lastPair] : [] }
        if (dieCurrentList.length > index) {
          ic.dieCurrent = dieCurrentList[index]
        }
        pairsIndex++;
      }
    })
  } else {
    for (let ic of _data[dieIndex].ICs) {
      if (ic.to[0].components[0] === targetDie) {
        ic.model = model;
        break;
      }
    }
  }
  if (model.type === 'CPM') {
    const pkgIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX)
    const { cpmPairs } = model
    for (let domain of _data[pkgIndex].powerDomains) {
      const { PowerNets = [], ReferenceNets = [], Components = [] } = domain.content;
      const component = Components.find(item => item.usage === "DIE");
      if (!component) {
        continue;
      }

      const cpmPins = cpmPairs.filter(item => [...PowerNets, ...ReferenceNets].includes(item.net));

      if (!cpmPins.length) {
        continue;
      }

      try {
        yield call(getLayoutDB, _data[pkgIndex].designId);
      } catch (error) {
        console.error(error)
        return;
      }

      const newPorts = model.plocSelectFile && model.plocSelectFile.libraryId ? getDiePortsByPloc({
        packageId: _data[pkgIndex].designId,
        component,
        PowerNets,
        ReferenceNets,
        cpmPins
      }) : getDiePortsByCPM({
        packageId: _data[pkgIndex].designId,
        component,
        PowerNets,
        ReferenceNets,
        cpmPins
      });
      domain.content.diePorts = newPorts && newPorts.length ? newPorts : domain.content.diePorts;
    }
  }
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* updateDecapGroup(action) {
  const { file } = action;

  const { CascadeReducer: { Impedance: { verificationId } } } = yield select();
  try {
    if (file) {

      if (Array.isArray(file)) {
        // file is powerDomainGroups data
        yield put(updateImpedanceContent({ powerDomainGroups: file }));
        yield delay(100);
        yield call(saveSetup);
        return;
      }

      // file is powerDomainGroups excel file
      const res = yield call(uploadDecapGroupExcel, { verificationId, file });
      const { powerDomainGroups } = res;
      yield put(updateImpedanceContent({ powerDomainGroups }));
      return;
    }

    // file delete
    yield put(updateImpedanceContent({ powerDomainGroups: undefined }));
    yield delay(100);
    yield call(saveSetup)
  } catch (e) {
    console.error(e)
  }
}

function* updateDecapModel(action) {
  const { file, pcbId, display } = action;
  const { CascadeReducer: { project: { openProjectId, applyModelList }, library: { DecapList }, Impedance: { data, verificationId } } } = yield select();
  const newData = [...data];
  const findIndex = newData.findIndex(item => item.designId === pcbId)
  if (findIndex < 0) {
    return;
  }
  yield put(updateImpedanceLoading(true));
  const designList = CascadeChannels.getList(IMPEDANCE, openProjectId);
  const currentItem = [...designList].find(i => i.id === verificationId);
  yield put(openTabFooter());
  yield put(changeTabMenu({
    tabSelectKeys: ["detail"],
    currentVerificationId: verificationId,
    verificationName: currentItem ? currentItem.name : IMPEDANCE,
    menuType: "simulation"
  }))
  try {
    if (file) {
      const res = yield call(uploadDecapModelExcel, { file, type: display });
      if (!res) {
        yield put(updateLogs([{ type: 'error', text: `Parse file failed!` }]))
        return
      }
      const subcktsMap = new Map();// key - file, value :[ { name, ports }... ]
      const capModelMap = new Map();// key - component name, value :{ name, subcktName, id, type, libraryType }
      const partModelMap = new Map();// key - component part, value :{ name, subcktName, id, type, libraryType }
      for (let item of res) {
        const { componentName, fileName: parseFileName, subckt, part } = item;
        const matchParseName = parseFileName.replace(/\.s(\d*p|np)$/, '')
        let fileInfo
        for (let decap of DecapList) {
          if (decap.format === 'Folder') {
            if (decap.children && decap.children.length) {
              fileInfo = decap.children.find(d => {
                const matchDecapName = d.name.replace(/\.s(\d*p|np)$/, '')
                const isFileNameMatch = d.name === parseFileName || matchDecapName === parseFileName || matchDecapName.includes(matchParseName)
                return d.type === (subckt ? DECAP_SPICE : DECAP_TOUCHSTONE) && isFileNameMatch
              })
              if (fileInfo) {
                break
              }
            }
          } else {
            const matchDecapName = decap.name.replace(/\.s(\d*p|np)$/, '')
            const isFileNameMatch = decap.name === parseFileName || matchDecapName === parseFileName || matchDecapName.includes(matchParseName)
            if (decap.type === (subckt ? DECAP_SPICE : DECAP_TOUCHSTONE) && isFileNameMatch) {
              fileInfo = decap
              break
            }
          }
        }
        if (fileInfo) {
          let model;
          const fileName = fileInfo.name
          if (fileInfo.dataType === DECAP_SPICE) {
            if (!subcktsMap.has(fileName)) {
              const res = yield call(getLibraryFileInfo, fileInfo.id);
              const { models: subckts } = parseSPModelSelector(res);
              subcktsMap.set(fileName, subckts)
            }
            const subkctInfo = subcktsMap.get(fileName).find(_subckt => _subckt.name === subckt)
            if (subkctInfo) {
              model = {
                name: fileName,
                subcktName: subckt,
                id: fileInfo.id,
                type: 'Decap',
                libraryType: DECAP_SPICE
              }
            }
          } else {
            model = {
              name: fileName,
              subcktName: '',
              id: fileInfo.id,
              type: fileInfo.dataType,
              libraryType: fileInfo.dataType
            }
          }
          if (model) {
            if (display === COMPONENTBASED) {
              capModelMap.set(componentName, { ...model })
            } else if (display === PARTBASED) {
              partModelMap.set(part, { ...model })
            }
          }
        } else {
          yield put(updateLogs([{ type: 'normal', text: `${parseFileName}${subckt ? ` - ${subckt}` : ''} does not exist in decap library.` }]))
        }
      }

      let _applyModelList = [...applyModelList]

      if (display === COMPONENTBASED) {
        newData[findIndex].powerDomains.forEach(item => {
          item.content.Components = item.content.Components.map(comp => {
            if (capModelMap.has(comp.name)) {
              return { ...comp, models: [{ ...capModelMap.get(comp.name), apply: false }] };
            } else {
              return { ...comp }
            }
          })
        })
      } else if (display === PARTBASED) {
        newData[findIndex].powerDomains.forEach(item => {
          item.content.Components = item.content.Components.map(comp => {
            const { part } = comp
            const partNumber = auroraDBJson.getPartNumberByPartName(pcbId, part);
            const _part = partModelMap.has(partNumber) ? partNumber : (partModelMap.has(part) ? part : null);
            if (_part) {
              const model = partModelMap.get(_part)
              const findIndex = applyModelList.findIndex(m => m.partName === _part);
              let applyModel = { model, partName: _part };
              if (findIndex > -1) {
                _applyModelList[findIndex] = { ...applyModel }
              } else {
                _applyModelList = [..._applyModelList, { ...applyModel }]
              }
              return { ...comp, models: [{ ...model, apply: true }] };
            } else {
              const { models, ..._comp } = comp
              return _comp
            }
          })
        })
      }
      yield call(updateLibSetting, { librarySettings: _applyModelList, projectId: openProjectId })
      yield put(updateModelSetting(_applyModelList))
      yield put(updateImpedanceContent({ data: newData, status: true }));
      yield put({ type: SAVE_POWER_DOMAIN, data: newData });
      yield put(updateImpedanceLoading(false));
    }
  } catch (e) {
    yield put(updateImpedanceLoading(false));
    yield put(updateLogs([{ type: 'error', text: `Upload file failed!` }]))
    message.error('Upload file failed!');
    console.error(e)
  }
}

function* saveComponentsTableDisplay(action) {
  const { display, pcbId, clearModel } = action
  const { CascadeReducer: { Impedance: { componentsTableDisplay, data } } } = yield select();
  const prevDisplay = componentsTableDisplay
  if (!prevDisplay || prevDisplay !== display) {
    yield put(updateImpedanceLoading(true));
    try {
      yield put(updateImpedanceContent({ componentsTableDisplay: display }));
      yield delay(500);
      yield call(saveSetup);
      if (prevDisplay === COMPONENTBASED && display === PARTBASED && clearModel) {
        const newData = [...data];
        const findIndex = newData.findIndex(item => item.designId === pcbId)
        if (findIndex < 0) {
          return;
        }
        newData[findIndex].powerDomains.forEach(item => {
          item.content.Components.forEach(comp => {
            if (comp.usage === CAP) {
              delete comp.models
            }
          })
        })
        yield put(updateImpedanceContent({ data: newData, status: true }));
        yield put({ type: SAVE_POWER_DOMAIN, data: newData });
      }
      yield put(updateImpedanceLoading(false));
    } catch (error) {
      yield put(updateImpedanceLoading(false));
      console.error(error)
    }
  }
}

function* changeUsage(action) {
  const { compNames, part, id, usage } = action;
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < -1) {
    return;
  }
  let { ICs, ballSizeMap } = _data[findIndex]
  const powerDomains = _data[findIndex].powerDomains;
  const index = powerDomains.findIndex(item => item.id === id);
  const currentData = powerDomains[index] || null;
  if (!currentData) {
    return;
  }
  const { DEBUG_MONITOR } = currentData.content
  let monitorComps = []
  const anotherLoadComps = currentData.content.Components.filter(item => item.usage === LOAD && item.part !== part)
  const anotherPowerLoadComps = []
  powerDomains.forEach(item => {
    if (item.id !== id) {
      const loadCompInfo = item.content.Components.filter(i => i.usage === LOAD)
      loadCompInfo.forEach(comp => {
        if (!anotherPowerLoadComps.includes(comp.name)) {
          anotherPowerLoadComps.push(comp.name)
        }
      })
    }
  })
  let monitorList = anotherLoadComps.map(comp => comp.loadMonitor || [])
  monitorList = [...monitorList, DEBUG_MONITOR].flat(3)
  let comps = [...new Set(monitorList.filter(item => item.type === 'comp').map(item => item.name))]
  monitorComps.push(...comps)

  currentData.content.Components.forEach(item => {
    if (item.part === part && compNames.includes(item.name)) {
      item.usage = usage;
      if (usage === IGNORE) {
        let loadComps = []
        if (item.loadMonitor) {
          item.loadMonitor.forEach(monitor => {
            const comps = monitor.filter(item => item.type === 'comp').map(item => item.name)
            loadComps.push(...comps)
          })
          loadComps.forEach(loadComp => {
            if (!monitorComps.includes(loadComp)) {
              const currentComp = currentData.content.Components.find(comp => comp.name === loadComp)
              if (currentComp && currentComp.usage) {
                currentComp.usage = IGNORE
              }
            }
          })
        }
        delete item.loadMonitor
        delete item.loadPorts
        if (!anotherPowerLoadComps.includes(item.name)) {
          ICs = ICs.filter(ic => ic.name !== item.name)
        }
      }
    }
  })
  powerDomains[index] = currentData;
  _data[findIndex].powerDomains = powerDomains;
  _data[findIndex].ICs = ICs;
  const ballSizeMapObj = new BallSizeMap({ ballSizeMap, comps: ICs, designId: page })
  _data[findIndex].ballSizeMap = ballSizeMapObj.getBallSizeMap()

  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
  if (usage === LOAD) {
    yield delay(500);
    yield call(getVRMfromNewLoad, { id, compNames })
  }
}

function* getVRMfromNewLoad(action) {
  const { CascadeReducer: { Impedance: { data, page } } } = yield select();
  const { id, compNames } = action;
  if (page === OVERVIEW) {
    return;
  }
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === page);
  if (findIndex < 0) { return };
  const index = _data[findIndex].powerDomains.findIndex(item => item.id === id);
  if (index < 0) { return }
  let { ICs, ballSizeMap } = _data[findIndex]
  let currentData = _data[findIndex].powerDomains[index];
  const { id: _id, content } = currentData;
  const { MAIN_REF_NETS = [], PowerNets, ReferenceNets, includeExtended, extraNets = [], Components, VRM } = content;
  const setting = yield call(componentSetting.getPrefixLib, page);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, page);
  const table = yield call(compTableHelper.getTableData, page);
  const _pinConnection = yield call(compPinMap.getPinConnection, page);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const pinMap = yield call(getImpedancePinMap, page);
  let VRMComps = [];
  VRM.forEach(item => {
    VRMComps.push(...item.VRM_COMP)
  });
  const _VRMComps = [...new Set(VRMComps)]
  const specify = _VRMComps.map(comp => {
    let nets = []
    if (Components.find(_comp => _comp.name === comp)) {
      nets = Components.find(_comp => _comp.name === comp).pins.map(pin => pin.net)
    }
    return { name: comp, nets: [...new Set(nets)] }
  })
  let Comps = [...Components]
  let _extraNets = [...extraNets]

  if (PowerNets && PowerNets.length && ReferenceNets && ReferenceNets.length) {
    const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], page, "pinName")
    yield* compNames.map(function* (compName) {
      const compInfo = getCascadeComponents({ pcbId: page, name: compName });
      const compNets = compInfo ? [...compInfo.pins.values()].map(item => item.net) : []
      const _powerNets = [...PowerNets, ...extraNets].find(net => compNets.includes(net))
      const ic = getImpedanceLayoutComp(compName, page, IC);
      if (extraNets.includes(_powerNets)) {
        let VRMInfo = yield call(_getVRM, {
          designId: page,
          GroundNets: [...MAIN_REF_NETS],
          PowerNets: [_powerNets],
          ExtendNets: [],
          COMP_PREFIX_LIB: setting,
          loadSelect: [{ comp: compName }],
          connectInductance: false,
          findExtend: includeExtended,
          isAC: true,
          PMIC: [],
          powerSwitch: setting.powerSwitch,
          buckConverter: setting.discreteBuckConverter || [],
          doNotStuff,
          table,
          pinMapSense,
          pinConnection,
          pinMap,
          specify
        });
        const { Components: _Components, DEBUG_MONITOR, PowerNets: _PowerNets } = VRMInfo

        _Components.forEach(vrmComp => {
          if (vrmComp.name === compName) {
            Comps.find(comp => comp.name === compName).loadMonitor = DEBUG_MONITOR
          }
          if (Comps.map(comp => comp.name).includes(vrmComp.name)) {
            const _comp = Comps.find(comp => comp.name === vrmComp.name)
            const index = Comps.findIndex(comp => comp.name === vrmComp.name)
            if (_comp.usage === IGNORE && vrmComp.usage !== IGNORE) {
              const part = Comps.find(item => item.part === vrmComp.part && item.name !== vrmComp.name)
              if (part && part.model) {
                vrmComp.model = { ...part.model }
              }
              if (part && part.value) {
                vrmComp.value = part.value
              }
              Comps.splice(index, 1, vrmComp)
            }
          } else {
            const part = Comps.find(item => item.part === vrmComp.part)
            if (part && part.models && part.models.length) {
              vrmComp.models = [...part.models]
            }
            Comps.push(vrmComp)
          }
        })

        _PowerNets.forEach(net => {
          if (![...PowerNets, ...extraNets].includes(net)) {
            _extraNets.push(net)
          }
        })

        if (DEBUG_MONITOR.length) {
          DEBUG_MONITOR.forEach(monitor => {
            const _monitor = monitor.filter(item => item.type === 'comp');
            const last = _monitor[_monitor.length - 1].name;
            ic.to.push({ designId: page, components: [last] })
          })
        }
      } else if (_powerNets) {
        const loadMonitor = [{ name: compName, type: 'comp' }, { name: _powerNets, type: 'net' }]
        Comps.find(comp => comp.name === compName).loadMonitor = [loadMonitor]
        specify.forEach(item => {
          ic.to.push({ designId: page, components: [item.name] })
        })
      }
      const haveIC = ICs.find(item => item.name === ic.name)
      if (haveIC) {
        ic.to.forEach(_ic => {
          _ic.components.forEach(_comp => {
            const comp = haveIC.to.find(item => item.components.includes(_comp))
            if (!comp) {
              haveIC.to.push(_ic)
            }
          })
        })
      } else {
        ICs.push(ic)
      }
    });
  }
  currentData = {
    id: _id,
    content: {
      ...content,
      Components: Comps,
      extraNets: [..._extraNets]
    }
  }
  _data[findIndex].powerDomains[index] = currentData;
  const newPowerDomains = _data[findIndex].powerDomains.map(item => ({ id: item.id, ...item.content }));
  const VRMs = newPowerDomains.map(pow => (pow.VRM && pow.VRM.map(vrm => vrm.VRM_COMP)) || []).flat(3);
  const PMICs = [...new Set(VRMs)].filter(vrm => !_data[findIndex].connectors.find(conn => conn.name === vrm));
  _data[findIndex].PMICs = PMICs.map(pmic => getImpedanceLayoutComp(pmic, page, PMIC));
  _data[findIndex].ICs = ICs
  const ballSizeMapObj = new BallSizeMap({ ballSizeMap, comps: ICs, designId: page })
  _data[findIndex].ballSizeMap = ballSizeMapObj.getBallSizeMap()
  yield call(saveLayouts, { data: _data, update: true });
}

function* savePMICVoltage(action) {
  const { value, name } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.PMICs && item.PMICs.length > 0);
  if (findIndex < -1) {
    return;
  }
  let { PMICs } = _data[findIndex]
  PMICs.forEach(item => {
    if (item.name === name) {
      item.voltage = value
    }
  })
  _data[findIndex].PMICs = PMICs;
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: _data });
}

function* saveDieRleakAndRrdl(action) {
  const { rleak, rdl, apply, rComp, rPowerNet, rPortIndex } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const dieIndex = _data.findIndex(item => item.type === IMPEDANCE_DIE)
  if (dieIndex < 0) {
    return;
  }
  if (apply) {
    _data[dieIndex].ICs.forEach(ic => {
      if (ic.to[0].components[0] === rComp) {
        ic.rleak = rleak
        ic.rdl = rdl
      }
    })
  } else {
    _data[dieIndex].ICs.forEach(ic => {
      if (ic.powerNet === rPowerNet && ic.portIndex === rPortIndex && ic.to[0].components[0] === rComp) {
        ic.rleak = rleak
        ic.rdl = rdl
      }
    })
  }
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* saveInterposer(action) {
  const { info, interposerDie, interposerPowerNet, interposerIndex, apply } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const dieIndex = _data.findIndex(item => item.type === IMPEDANCE_DIE)
  if (dieIndex < 0) {
    return;
  }
  if (apply) {
    _data[dieIndex].ICs.forEach(ic => {
      if (ic.to[0].components[0] === interposerDie) {
        ic.interposer = { ...info }
      }
    })
  } else {
    _data[dieIndex].ICs.forEach(ic => {
      if (ic.powerNet === interposerPowerNet && ic.portIndex === interposerIndex && ic.to[0].components[0] === interposerDie) {
        ic.interposer = { ...info }
      }
    })
  }
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* saveInterposerNode(action) {
  const { value, key, name, powerNet, portIndex } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const dieIndex = _data.findIndex(item => item.type === IMPEDANCE_DIE)
  if (dieIndex < 0) {
    return;
  }
  _data[dieIndex].ICs.forEach(ic => {
    if (ic.powerNet === powerNet && ic.portIndex === portIndex && ic.to[0].components[0] === name) {
      if (ic.interposer && ic.interposer.pairs && ic.interposer.pairs.length) {
        const pairs = ic.interposer.pairs
        let _pairs = [...pairs]
        const pairIndex = _pairs.findIndex(item => item.pin === key)
        if (pairIndex > -1) {
          _pairs[pairIndex].node = value
          ic.interposer.pairs = [..._pairs]
        }
      }
    }
  })
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* switchPackage(action) {
  const { designId } = action
  const { CascadeReducer: { project: { openProjectId }, Impedance: { verificationId, targetDie = [], data = [], targetIC, designId: currentDesignId, powerNetSelect: { select: selectNets } } } } = yield select();
  const _data = [...data]
  const findIndex = _data.findIndex(item => item.index === TARGET_PACKAGE_INDEX);
  if (findIndex < 0) {
    return;
  }
  if (_data[findIndex].designId !== designId) {
    const designName = designConstructor.getDesignName(designId);
    const isPreLayout = designConstructor.isPreLayout(designId);
    let setting = new CascadeCompRLCPrefixLib({})
    let Dies = []
    if (isPreLayout) {
      const preData = yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      const { content = {} } = preData;
      const { components = [] } = content;
      Dies = components.filter(item => item.type === 'DIE').map(item => item.name);
    } else {
      setting = yield call([componentSetting, componentSetting.getPrefixLib], designId);
      const { Die = [] } = setting;
      Dies = Die;
      yield call(getAuroraDB, designId);
    }

    const designList = CascadeChannels.getList(IMPEDANCE, openProjectId);
    const currentItem = [...designList].find(i => i.id === verificationId);
    yield put(openTabFooter());
    yield put(changeTabMenu({
      tabSelectKeys: ["detail"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : IMPEDANCE,
      menuType: "simulation"
    }))

    if (!Dies.length) {
      yield put(updateLogs([{ type: 'error', text: `[Update] Die does not exist.` }]))
      return
    }

    let powerDomains = [];
    let _targetDie = [];
    if (targetDie.every(comp => Dies.includes(comp))) {
      for (let item of _data[findIndex].powerDomains) {
        const { Components, diePorts, bgaPorts, MAIN_POWER_NETS, MAIN_REF_NETS } = item.content
        const dieCompInfo = Components.find(comp => comp.usage === 'DIE')
        const dieComp = dieCompInfo ? [dieCompInfo.name] : []

        const { powerNets, referenceNets, DIE, BGA } = getPackagePowerDomain(designId, setting, dieComp);
        let _powerNets = [...powerNets];
        if (targetIC && selectNets.length && currentDesignId !== designId && !isPreLayout) {
          const { nets } = checkConnectCompsPinsAndNets({ designId: currentDesignId, name: targetIC, nets: selectNets }, { designId: designId, name: BGA.name })
          if (nets && nets.length) {
            _powerNets = _powerNets.filter(item => nets.includes(item))
          }
        }
        if (!_powerNets.length) {
          yield put(updateLogs([{ type: 'error', text: `[Update] PowerNet does not exist.` }]))
          continue
        }

        const deletePowerNets = MAIN_POWER_NETS.filter(item => !_powerNets.includes(item))
        const deleteRefNets = MAIN_REF_NETS.filter(item => !referenceNets.includes(item))
        if (deletePowerNets.length) {
          yield put(updateLogs([{ type: 'normal', text: `[Update][Power Domains] ${deletePowerNets.join(',')} does not exist.` }]))
        } else if (deleteRefNets.length) {
          yield put(updateLogs([{ type: 'normal', text: `[Update][Power Domains] ${deleteRefNets.join(',')} does not exist.` }]))
        }

        yield put(updateImpedanceLoading('Loading...'));
        for (let powerNet of _powerNets) {
          const packageVRMs = yield call(getPkgDomainInfo, {
            pcbId: designId,
            powerNet,
            referenceNets,
            DIE: DIE.map(die => die.name),
            BGA: BGA.name,
            setting
          })

          if (!packageVRMs.add) {
            continue;
          }

          const { newDiePorts, newBgaPorts, deleteDiePower, deleteDieRefe, deleteBgaPower, deleteBgaRefe } = filterPortsByPackage(designId, dieCompInfo.name, BGA.name, [powerNet], referenceNets, diePorts, bgaPorts)
          packageVRMs.domain.diePorts = newDiePorts
          packageVRMs.domain.bgaPorts = newBgaPorts
          if (deleteDiePower.length) {
            yield put(updateLogs([{ type: 'normal', text: `[Update][Power Domains][${powerNet}][DiePorts] Pins "${deleteDiePower.join(',')}" does not exist.` }]))
          }
          if (deleteDieRefe.length) {
            yield put(updateLogs([{ type: 'normal', text: `[Update][Power Domains][${referenceNets.join(',')}][DiePorts] Pins "${deleteDieRefe.join(',')}" does not exist.` }]))
          }
          if (deleteBgaPower.length) {
            yield put(updateLogs([{ type: 'normal', text: `[Update][Power Domains][${powerNet}][BgaPorts] Pins "${deleteBgaPower.join(',')}" does not exist.` }]))
          }
          if (deleteBgaRefe.length) {
            yield put(updateLogs([{ type: 'normal', text: `[Update][Power Domains][${referenceNets.join(',')}][BgaPorts] Pins "${deleteBgaRefe.join(',')}" does not exist.` }]))
          }
          packageVRMs.domain.Components = yield call(updateComponentDecapModel, {
            components: packageVRMs.domain ? packageVRMs.domain.Components : [],
            prevComps: Components,
            designId
          })
          const componentUpdate = getCompUpdateLog(packageVRMs.domain, item.content);
          const componentUpdateLogs = componentUpdate.map(item => { return { type: 'normal', text: item } })
          yield put(updateLogs(componentUpdateLogs))

          powerDomains.push({
            id: "",
            content: { ...packageVRMs.domain }
          })
        }
        _targetDie.push(dieCompInfo.name)
      }
    } else {
      const { powerNets, referenceNets, DIE, BGA } = getPackagePowerDomain(designId, setting, Dies);
      let _powerNets = [...powerNets];
      if (targetIC && selectNets.length && currentDesignId !== designId && !isPreLayout) {
        const { nets } = checkConnectCompsPinsAndNets({ designId: currentDesignId, name: targetIC, nets: selectNets }, { designId: designId, name: BGA.name })
        if (nets && nets.length) {
          _powerNets = _powerNets.filter(item => nets.includes(item))
        }
      }
      if (!_powerNets.length) {
        yield put(updateLogs([{ type: 'error', text: `[Update] PowerNet cannot connect to PCB.` }]))
        return
      }
      yield put(updateImpedanceLoading('Loading...'));
      for (let powerNet of _powerNets) {
        const packageVRMs = yield call(getPkgDomainInfo, {
          pcbId: designId,
          powerNet,
          referenceNets,
          DIE: DIE.map(die => die.name),
          BGA: BGA.name,
          setting
        })

        if (!packageVRMs.add) {
          continue;
        }
        packageVRMs.domain.Components = yield call(updateComponentDecapModel, {
          components: packageVRMs.domain ? packageVRMs.domain.Components : [],
          prevComps: [],
          designId
        })

        const die = Dies.find(item => {
          return packageVRMs.domain.Components.find(comp => comp.name === item)
        })
        if (die) {
          _targetDie.push(die)
        }

        powerDomains.push({
          id: "",
          content: { ...packageVRMs.domain }
        })
      }
    }

    const { DIE, BGA } = getPackagePowerDomain(designId, setting, _targetDie);
    const bgaObj = getImpedanceLayoutComp(BGA.name, designId, BGAConst);
    bgaObj.from = [{ components: DIE.map(die => die.name), designId }];
    const dieObj = DIE.map(die => {
      const _dieObj = getImpedanceLayoutComp(die.name, designId, DIEConst)
      _dieObj.to = [{ components: [BGA.name], designId }];
      return _dieObj
    })

    if (currentDesignId !== designId && targetIC) {
      bgaObj.to = [{ components: [targetIC], designId: currentDesignId }];
      const currentIndex = _data.findIndex(item => item.designId === currentDesignId);
      if (currentIndex > -1) {
        const icIndex = _data[currentIndex].ICs.findIndex(item => item.name === targetIC);
        if (icIndex > -1) {
          _data[currentIndex].ICs[icIndex].from = [{ components: DIE.map(die => die.name), designId }]
        }
      }
    }

    const dieIndex = _data.findIndex(item => item.index === TARGET_DIE_INDEX)
    const dieNames = DIE.map(item => item.name)
    if (dieIndex > -1) {
      let ics = _data[dieIndex].ICs
      ics = ics.filter(ic => dieNames.includes(ic.to[0].components[0]))

      let newICs = []
      powerDomains.forEach(domain => {
        const { content } = domain
        const die = content.Components.find(item => item.usage === DIE).name
        const powerNet = content.MAIN_POWER_NETS.length ? content.MAIN_POWER_NETS[0] : null
        const findIc = ics.find(ic => ic.to[0].components[0] === die)
        if (!findIc) {
          newICs.push({
            name: 'Die',
            usage: 'DIE',
            model: {
              rdieValue: "10m",
              cdieValue: "100n",
              type: "value"
            },
            from: [],
            to: [{
              designId,
              components: [die]
            }],
            rleak: '1',
            rdl: '0.001',
            powerNet: powerNet,
            portIndex: '1'
          })
        } else {
          const ic = JSON.parse(JSON.stringify(findIc))
          ic.to[0].designId = designId
          ic.powerNet = powerNet
          ic.portIndex = '1'
          newICs.push(ic)
        }
      })
      _data[dieIndex].ICs = ics
    }

    const { ballSizeMap } = _data[findIndex]
    const ballSizeMapObj = new BallSizeMap({ ballSizeMap, comps: [...dieObj, bgaObj], designId })
    const _ballSizeMap = ballSizeMapObj.getBallSizeMap()

    const version = yield call(componentSetting.getVersion, designId);
    const COMP_PREFIX_LIB = { version: version }
    const extraction = new ImpExtraction(_data[findIndex].extraction || {});

    _data[findIndex].designId = designId
    _data[findIndex].designName = designName
    _data[findIndex].extraction = extraction
    _data[findIndex].COMP_PREFIX_LIB = COMP_PREFIX_LIB
    _data[findIndex].Dies = dieObj;
    _data[findIndex].Bgas = [bgaObj];
    _data[findIndex].powerDomains = powerDomains;
    _data[findIndex].ballSizeMap = _ballSizeMap;

    yield put(updateImpedanceContent({ targetDie: _targetDie }));
    yield call(saveSetup);

    yield put(updateLogs([{ type: 'success', text: `[Package] [${designName}] Found ${powerDomains.length} Power Domains` }, { type: 'normal', text: `[Package] Saving...` }]))
    yield delay(100)

    yield put(updateImpedanceLoading(false));
    try {
      yield call(saveLayouts, { data: _data, update: true });
      yield put(updateLogs([{ type: 'normal', text: `[Package] Save completed.` }]))
    } catch (e) {
      yield put(updateLogs([{ type: 'error', text: `[Package] Save failed...` }]))
      console.error("Package Error:" + e)
    }
  }
}

function* getPowerSwitchPinMap(pinConnection) {
  let _pinConnection = [];
  yield* pinConnection.map(function* (connection) {
    if (connection.type === POWER_SWITCH && connection.libraryId) {
      try {
        const libraryData = yield call(getLibraryDataInfo, connection.libraryId);
        const { config = {} } = libraryData;
        const { pinTable = [] } = config;
        _pinConnection.push({ ...connection, pinMap: pinTable })
      } catch (e) {
        _pinConnection.push(connection)
      }
    } else {
      _pinConnection.push(connection)
    }
  })
  return _pinConnection;
}

function* saveHasInterposer(action) {
  const { hasInterposer } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const dieIndex = _data.findIndex(item => item.type === IMPEDANCE_DIE)
  if (dieIndex < 0) {
    return;
  }
  if (!hasInterposer) {
    _data[dieIndex].ICs.forEach(ic => {
      delete ic.interposer
    })
  }
  yield put(updateImpedanceContent({ data: _data, hasInterposer: hasInterposer, update: true }));
  yield delay(10);
  yield call(saveSetup);
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* updateCurrentStatus(action) {
  const { enable, designId, name } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const findIndex = _data.findIndex(item => item.designId === designId);
  if (findIndex > -1) {
    const findICIndex = _data[findIndex].ICs.findIndex(item => item.name === name);
    if (findICIndex > -1) {
      const ic = _data[findIndex].ICs[findICIndex]
      ic.applyCurrent = enable
      _data[findIndex].ICs[findICIndex] = ic
      yield put(updateImpedanceContent({ data: _data, status: true }));
      yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
    }
  }
}

function* saveTransientTimeSetting(action) {
  const { startTime, stopTime, stepTime } = action
  const { CascadeReducer: { Impedance: { data } } } = yield select();
  let _data = [...data];
  const dieIndex = _data.findIndex(item => item.type === IMPEDANCE_DIE)
  if (dieIndex < 0) {
    return;
  }
  _data[dieIndex].ICs.forEach(ic => {
    if (ic.dieCurrent && ic.dieCurrent.currentPoints && ic.dieCurrent.currentPoints.length) {
      ic.dieCurrent.startTime = startTime
      ic.dieCurrent.stopTime = stopTime
      ic.dieCurrent.stepTime = stepTime
    }
  })
  yield put(updateImpedanceContent({ data: _data, status: true }));
  yield put({ type: SAVE_POWER_DOMAIN, data: [..._data] });
}

function* ImpedanceSaga() {
  yield takeLatest(GET_IMPEDANCE_CONTENT, getImpedanceContent);
  yield takeEvery(UPDATE_TARGET_IC, changeTargetIC);
  yield takeEvery(REFIND_DOMAIN_INFO, reGetDomainRowInfo);
  yield takeEvery(DELETE_IMPEDANCE_ROW, deletePowerDomains);
  yield takeEvery(SAVE_VRM, saveVRM);
  yield takeEvery(SAVE_RLC_VALUE, saveRLCValue);
  yield takeEvery(SAVE_RL_MODEL, saveRLModel);
  yield takeEvery(SAVE_CAP_MODEL, saveModel);
  yield takeEvery(UPDATE_EXTENDED, updateExtendNets);
  yield takeEvery(MERGE_PART, mergeCompByPart);
  yield takeEvery(UPDATE_COMP_USAGE, changeCompUsage);
  yield takeEvery(SPLIT_COMP, splitComponent);
  yield takeEvery(UPDATE_COMP_PREFIX, updateComponentPrefix);
  yield takeEvery(UPDATE_EXTRACTION, updateExtraction);
  yield takeEvery(UPDATE_OPTIMIZATION, updateOpt);
  yield takeEvery(REFRESH, refreshImp);
  yield takeEvery(REMOVE_COMP, removeComp);
  yield takeEvery(ADD_ROW, addRowForDomain);
  yield takeLatest(CHANGE_NETS, changeNets);
  yield takeLatest(CHANGE_EXTRA_NETS, changeExtraNets);
  yield takeEvery(SAVE_TARGET, saveTarget);
  yield takeEvery(SAVE_PORTS, savePorts);
  yield takeEvery(SAVE_LOAD_PORTS, saveLoadPorts);
  yield takeEvery(CHANGE_PCB, updateImpedancePCB);
  yield takeEvery(POWER_NET_SELECT_PANEL, openPowerNetSelectPanel);
  yield takeEvery(RE_ASSIGN_DECAP_MODEL, reAssignDecapModel);
  yield takeEvery(DELETE_SENSE_PORT, deleteSensePath);
  yield takeEvery(UPDATE_OPTIONS, updateOptions);
  yield takeEvery(SAVE_PACKAGE_PORTS, savePackagePorts);
  yield takeEvery(UPDATE_PACKAGE_NETS, updatePackageNets);
  yield takeEvery(SAVE_DOMAIN_BY_NEW_NETS, refindPackagePins);
  yield takeEvery(SWITCH_PACKAGE_PORTS_TYPE, switchPackagePorts);
  yield takeEvery(SAVE_BALL_SIZE, updateBallSize);
  yield takeEvery(SET_ADDITIONAL_NETS, saveAdditionalNets);
  yield takeEvery(REFRESH_PACKAGE, updatePkgDomain);
  yield takeEvery(SWITCH_TARGET_DIE, switchDie);
  yield takeEvery(SAVE_WIRE_BOND, saveWireBondFile);
  yield takeEvery(CHANGE_PAGE, changePageByDesign);
  yield takeEvery(CREATE_NEW_LAYOUT, createLayout);
  yield takeEvery(DELETE_LAYOUT, deleteLayout);
  yield takeEvery(DELETE_LAYOUT_CONNECTOR, deleteLayoutConnector);
  yield takeEvery(TRACE_OVERVIEW, traceByLayout);
  yield takeEvery(DELETE_SHIP, deleteShip);
  yield takeEvery(CREATE_DIE, createDie);
  yield takeEvery(SAVE_ON_DIE_MODEL, saveOnDieModel);
  yield takeEvery(SAVE_MULTI_PCB_TARGET, updateMultiPCBTarget)
  yield takeEvery(UPDATE_DECAP_GROUP, updateDecapGroup);
  yield takeEvery(SAVE_DIE_CURRENT, saveDieCurrent)
  yield takeEvery(UPDATE_DECAP_MODEL, updateDecapModel);
  yield takeEvery(SAVE_COMP_TABLE_DISPLAY, saveComponentsTableDisplay);
  yield takeEvery(CHANGE_USAGE, changeUsage);
  yield takeEvery(SAVE_PMIC_VOLTAGE, savePMICVoltage);
  yield takeEvery(SAVE_RLEAK_RRDL, saveDieRleakAndRrdl);
  yield takeEvery(SWITCH_PACKAGE, switchPackage);
  yield takeEvery(SAVE_INTERPOSER, saveInterposer);
  yield takeEvery(SAVE_INTERPOSER_NODE, saveInterposerNode)
  yield takeEvery(SAVE_HAS_INTERPOSER, saveHasInterposer)
  yield takeEvery(UPDATE_IC_CURRENT_STATUS, updateCurrentStatus)
  yield takeEvery(SAVE_TRANSIENT_TIME_SETTING, saveTransientTimeSetting)
}

export default ImpedanceSaga;