import {
  UPDATE_TRACE_CONFIGS,
  UPDATE_SWEEP_LOADING,
  OPEN_EXPERIMENT,
  UPDATE_EXPERIMENT_DATA,
  UPDATE_IS_UPDATE_EXPERIMENT_DATA,
  CLEAR_SWEEPINFO,
  UPDATE_EXPERIMENT_IDS,
  UPDATE_TRACE_SETTING,
  UPDATE_NOT_EXIST_NETS,
  UPDATE_SWEEP_INFO
} from "./actionTypes";

const defaultState = {
  sweepId: null,
  traceConfigs: [],
  traceSetting: {},
  stackupConfigs: [],
  experimentData: {
    "variables": [],
    "variablesTables": []
  },
  sweepLoading: false,
  isUpdateExperimentData: false,
  // selected experimentsId
  experimentIds: [],
  notExistNets: [],
}

export const AndesSweepReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_EXPERIMENT:
      return { ...state, sweepId: action.id }
    case UPDATE_TRACE_CONFIGS:
      return { ...state, traceConfigs: action.traceConfigs };
    case UPDATE_SWEEP_LOADING:
      return { ...state, sweepLoading: action.sweepLoading };
    case UPDATE_EXPERIMENT_DATA:
      return { ...state, experimentData: { ...state.experimentData, ...action.experimentData } };
    case UPDATE_IS_UPDATE_EXPERIMENT_DATA:
      return { ...state, isUpdateExperimentData: action.isUpdateExperimentData };
    case UPDATE_EXPERIMENT_IDS:
      return { ...state, experimentIds: action.experimentIds };
    case UPDATE_TRACE_SETTING:
      return { ...state, traceSetting: { ...state.traceSetting, ...action.traceSetting } };
    case UPDATE_NOT_EXIST_NETS:
      return { ...state, notExistNets: [...action.notExistNets] }
    case CLEAR_SWEEPINFO:
      return { ...defaultState };
    case UPDATE_SWEEP_INFO:
      return {
        ...state,
        sweepId: action.sweepInfo.id || state.sweepId,
        traceConfigs: action.sweepInfo.traceConfigs || state.traceConfigs,
        traceSetting: action.sweepInfo.traceSetting || state.traceSetting,
        experimentData: action.sweepInfo.experimentData || state.experimentData
      }
    default:
      return state;
  }
}