import CeLocation from '../geometry/CeLocation';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

var CeComponent = function () {
  this.mName = "";
  //this.mPartName = "";
  this.mPart = null;
  this.mLayerName = "";
  this.mLocation = new CeLocation();
};

CeComponent.prototype.GetPartName = function () {
  return this.mPart.GetName();
}

CeComponent.prototype.GetName = function () {
  return this.mName;
}

CeComponent.prototype.SetName = function (name) {
  this.mName = name;
}

CeComponent.prototype.SetPartPtr = function (partPtr) {
  this.mPart = partPtr;
}

CeComponent.prototype.SetLayer = function (layerName) {
  this.mLayerName = layerName;
}

CeComponent.prototype.SetLocation = function (location) {
  this.mLocation = location;
}

CeComponent.prototype.SetPinsLocation = function (pinLocations) {
  this.mPinsLocationList = []
  for (let pinInfo of pinLocations) {
    let mLocation = {};
    const { canvasObj, pin, pinNumber, unit } = pinInfo;
    mLocation.shapeType = canvasObj.shape;
    switch (canvasObj.shape) {
      case "circle":
        mLocation.xc = canvasObj.xc;
        mLocation.yc = canvasObj.yc;
        mLocation.r = canvasObj.r;
        break;
      case "polygon":
        //polyData -> "86.60000000000001, 128.92090000000002 86.60000000000001, 129.2209 86.3, 129.2209 86.3, 128.92090000000002"
        const data = canvasObj.polyData.split(/\s/);
        // data -> ['86.60000000000001,', '128.92090000000002', '86.60000000000001,', '129.2209', '86.3,', '129.2209', '86.3,', '128.92090000000002']
        // xList -> ['86.60000000000001,', '86.60000000000001,', '86.3,', '86.3,']
        // yList -> [128.92090000000002', '129.2209', '129.2209', '128.92090000000002']
        const xList = data.filter(item => item.match(","));
        const yList = data.filter(item => !item.match(","));

        //remove "," in xList
        mLocation.Xs = xList.map(item => item.replace(",", ""));
        mLocation.Ys = yList;
        break;
      case "ellipse":
        mLocation.cx = canvasObj.cx;
        mLocation.cy = canvasObj.cy;
        mLocation.rx = canvasObj.rx;
        mLocation.ry = canvasObj.ry;
        mLocation.r = mLocation.rx > mLocation.ry ? mLocation.ry : mLocation.rx;
        mLocation.rotate = canvasObj.rotate;
        break;
      case "path":
        mLocation.pathData = canvasObj.pathData;
        break;
      case "polyline":
        mLocation.points = canvasObj.points;
        mLocation.width = canvasObj.width;
        break;
      default: break;
    }
    this.mPinsLocationList.push({ pin, pinNumber, mLocation, unit, canvasObj });
  }
  this.mPinsLocationList = this.mPinsLocationList.sort((a, b) => a.pinNumber - b.pinNumber)
}

CeComponent.prototype.WriteIntoIODataNode = function () {
  var compVals = new StringList(),
    partName = this.mPart.GetName();
  if (partName.indexOf(' ') > 0) partName = '"' + partName + '"';
  compVals.add(partName);
  compVals.add(this.mLayerName);
  compVals.add(this.mLocation.GetDataStrings());
  return new CeIODataItem(this.mName, compVals);
}

CeComponent.prototype.ReadFromIODataNode = function (myDataItem, mPartMgr) {
  this.mName = myDataItem.GetName();
  var compVals = myDataItem.GetItemValue();
  var size = compVals.size();
  if (size < 9) return false;
  var partName = compVals.get(0);
  this.mPart = mPartMgr.GetPartFromName(partName);
  this.mLayerName = compVals.get(1);
  var locDataStrings = compVals.SubStringList(2, 9);
  this.mLocation = new CeLocation();
  this.mLocation.UpdateByString(locDataStrings);
  if (size > 9) this.mSocketedPartName = compVals.get(10);
  return true;
}

export default CeComponent;