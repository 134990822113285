import { PCB_CHANNEL } from "../../../constants/treeConstants";

const STACKUP_OPEN = "open", STACKUP_CLOSE = "close";
function getNewStackupList(oldStackList = [], status = STACKUP_OPEN, allIds, isPCB) {
  const { selectedKeys, channelDesignId, currentID } = allIds

  const getMsgOnce = key => {
    let [type, id] = key.split('-')
    switch (type) {
      case PCB_CHANNEL:
        id = channelDesignId
        break;
      default: new Date().getTime()
    }
    return { designId: id, type, status: STACKUP_CLOSE }
  }

  let newStackupList = []
  if (selectedKeys.length === 1) newStackupList = [getMsgOnce(selectedKeys[0])]
  else if (selectedKeys.length === 2) {
    newStackupList = [getMsgOnce(selectedKeys[0]), getMsgOnce(selectedKeys[1])]
  } else if (selectedKeys.length === 3) {
    newStackupList = [getMsgOnce(selectedKeys[0]), getMsgOnce(selectedKeys[1]), getMsgOnce(selectedKeys[2])]
  }

  newStackupList.forEach(newItem => {
    const prevMsg = oldStackList.find(oldItem => newItem.designId === oldItem.designId && newItem.type === oldItem.type)
    if (prevMsg) newItem.status = prevMsg.status

    if (newItem.designId === currentID) {
      if (isPCB && newItem.type === 'PCB') {
        newItem.status = status
      }
      if (!isPCB && newItem.type !== 'PCB') {
        newItem.status = status
      }
    }
  })
  return newStackupList
}

export {
  getNewStackupList,
  STACKUP_OPEN,
  STACKUP_CLOSE
}