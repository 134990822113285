import React from 'react';
import { Button } from 'antd';
import './style.css';

/**
 * loading - btn, progress
 * 
 * ifLoading[Optional] - true/false when loading == btn
 * 
 * progress[Optional] - number when loading == progress
 * 
 * onClick - function
 * 
 * disabled - true/false
 * 
 * btnName - button name
 *
 * @param {*} { onClick, progress, disabled, btnName }
 * @returns
 */
function ResultGenerateBtn({ loading, onClick, disabled, btnName, ifLoading, progress, loadingInfo }) {
  return (
    <>
      <Button
        onClick={(progress === -1 || !ifLoading) ? onClick : null}
        className='result-generate-btn'
        disabled={disabled}
      >
        {loading === 'progress' &&
          (<div
            className='result-generate-btn-progress'
            style={{ right: progress > -1 ? `${(100 - progress)}%` : '100%' }}
          />)
        }
        {
          loading === 'progress' ?
            <span className='result-generate-btn-span'>{progress > -1 ? `Generating... ${progress}%` : btnName}</span> :
            <span className='result-generate-btn-span'>{ifLoading ? (loadingInfo || `Generating...`) : btnName}</span>
        }
      </Button>
    </>
  )
}

export default ResultGenerateBtn;