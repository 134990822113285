import {
} from './actionTypes';

const defaultState = {
}

export const HimalayasLibraryReducer = (state = defaultState, action) => {
  switch (action.type) {
    default: return state;
  }
};