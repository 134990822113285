import {
  SAVE_VERIFICATION_ID,
  GET_IMPEDANCE_CONTENT,
  UPDATE_LOADING,
  UPDATE_IMPEDANCE_CONTENT,
  UPDATE_TARGET_IC,
  UPDATE_TABLE_STATUS,
  REFIND_DOMAIN_INFO,
  DELETE_IMPEDANCE_ROW,
  SAVE_VRM,
  UPDATE_IMP_RESULT_EXIST,
  SAVE_RLC_VALUE,
  SAVE_RL_MODEL,
  SAVE_CAP_MODEL,
  RESET_SETUP,
  UPDATE_EXTENDED,
  MERGE_PART,
  UPDATE_COMP_USAGE,
  SPLIT_COMP,
  UPDATE_COMP_PREFIX,
  UPDATE_EXTRACTION,
  UPDATE_OPTIMIZATION,
  REFRESH,
  UPDATE_ERROR_MSG,
  REMOVE_COMP,
  POWER_NET_SELECT_PANEL,
  ADD_ROW,
  CHANGE_NETS,
  CHANGE_EXTRA_NETS,
  SAVE_TARGET,
  SAVE_PORTS,
  SELECT_PCB,
  CHANGE_PCB,
  COMPONENT_SETTING_UPDATE,
  SAVE_IMPEDANCE_SIGN_OFF_ID,
  UPDATE_OPEN_TARGET_IC_LOADING,
  RE_ASSIGN_DECAP_MODEL,
  DELETE_SENSE_PORT,
  UPDATE_OPTIONS,
  SAVE_PACKAGE_PORTS,
  UPDATE_PACKAGE_NETS,
  SAVE_DOMAIN_BY_NEW_NETS,
  SWITCH_PACKAGE_PORTS_TYPE,
  SAVE_BALL_SIZE,
  UPDATE_POWER_NET_SELECT_PANEL,
  UPDATE_WARNING_MSG,
  SET_ADDITIONAL_NETS,
  REFRESH_PACKAGE,
  SWITCH_TARGET_DIE,
  SAVE_WIRE_BOND,
  CHANGE_PAGE,
  CREATE_NEW_LAYOUT,
  DELETE_LAYOUT,
  DELETE_LAYOUT_CONNECTOR,
  TRACE_OVERVIEW,
  DELETE_SHIP,
  CREATE_DIE,
  SAVE_ON_DIE_MODEL,
  UPDATE_IMPEDANCE_LOGS,
  SAVE_MULTI_PCB_TARGET,
  UPDATE_DECAP_GROUP,
  SAVE_DIE_CURRENT,
  UPDATE_DECAP_MODEL,
  CHANGE_USAGE,
  SAVE_LOAD_PORTS,
  SAVE_IMPEDANCE_DISPLAY,
  SAVE_COMP_TABLE_DISPLAY,
  SAVE_PMIC_VOLTAGE,
  SAVE_RLEAK_RRDL,
  SWITCH_PACKAGE,
  SAVE_INTERPOSER,
  SAVE_INTERPOSER_NODE,
  SAVE_HAS_INTERPOSER,
  UPDATE_IC_CURRENT_STATUS,
  SAVE_TRANSIENT_TIME_SETTING
} from './actionType'

export const savaImpedanceVerificationId = (id) => ({
  type: SAVE_VERIFICATION_ID,
  id
})

export const getImpedanceInfo = (id) => ({
  type: GET_IMPEDANCE_CONTENT,
  id
})

export const updateImpedanceLoading = (loading) => ({
  type: UPDATE_LOADING,
  loading
})

export const updateImpedanceContent = (obj) => ({
  type: UPDATE_IMPEDANCE_CONTENT,
  obj
})

export const changeTargetIC = (value) => ({
  type: UPDATE_TARGET_IC,
  value
})

export const updateImpedanceStatus = (status) => ({
  type: UPDATE_TABLE_STATUS,
  status
})


export const reFindDomain = (row) => ({
  type: REFIND_DOMAIN_INFO,
  row
})

export const addRow = () => ({
  type: ADD_ROW
})

export const changeNets = (id, nets) => ({
  type: CHANGE_NETS,
  id,
  nets
})

export const changeExtraNets = (id, nets) => ({
  type: CHANGE_EXTRA_NETS,
  id,
  nets
})

export const deletePowerDomain = (id) => ({
  type: DELETE_IMPEDANCE_ROW,
  id
})

export const saveVRM = (params, key) => ({
  type: SAVE_VRM,
  params, key
})

export const updateImpedanceResultExist = (resultExist, verificationId = "") => ({
  type: UPDATE_IMP_RESULT_EXIST,
  resultExist,
  verificationId
})

export const saveImpedanceRLCValue = (rowData, value) => ({
  type: SAVE_RLC_VALUE,
  rowData,
  value
})

export const saveRLModel = (model, value, part, components) => ({
  type: SAVE_RL_MODEL,
  model,
  value,
  part,
  components
})

export const saveCapModel = (record, value, models, apply, applyModelList, applySweep) => ({
  type: SAVE_CAP_MODEL,
  record,
  value,
  models,
  apply,
  applyModelList,
  applySweep
})

export const resetSetup = () => ({
  type: RESET_SETUP
})

export const updateIncludeExtended = (checked, id) => ({
  type: UPDATE_EXTENDED,
  checked,
  id
})

export const mergeCompByPart = (mergeList, info) => ({
  type: MERGE_PART,
  mergeList,
  info
})

export const updateCompUsage = (id, compNames, part, checked) => ({
  type: UPDATE_COMP_USAGE,
  id,
  compNames,
  part,
  checked
})

export const removeComponents = (id, compNames, part) => ({
  type: REMOVE_COMP,
  id,
  compNames,
  part
})

export const splitComponentPart = (id, compList, part, newPart) => ({
  type: SPLIT_COMP,
  id,
  compList,
  part,
  newPart
})

export const updateCompRLCPrefix = (update) => ({
  type: UPDATE_COMP_PREFIX,
  update
})

export const updateExtraction = (designId, config, apply) => ({
  type: UPDATE_EXTRACTION,
  designId,
  config,
  apply
})

export const updateOptimization = (config, opt) => ({
  type: UPDATE_OPTIMIZATION,
  config,
  opt
})

export const refreshImpedance = (selectNets) => ({
  type: REFRESH,
  selectNets
})

export const updateError = (error) => ({
  type: UPDATE_ERROR_MSG,
  error
})

export const updateWarning = (warning) => ({
  type: UPDATE_WARNING_MSG,
  warning
})

export const updateSelectPowerNets = (obj) => ({
  type: POWER_NET_SELECT_PANEL,
  obj
})

export const updateSelectedPowerNets = (obj) => ({
  type: UPDATE_POWER_NET_SELECT_PANEL,
  obj
})

export const saveTargetImpedance = (id, target) => ({
  type: SAVE_TARGET,
  id, target
})

export const savePorts = (id, ports) => ({
  type: SAVE_PORTS,
  id, ports
})

export const saveLoadPorts = (load, id, ports) => ({
  type: SAVE_LOAD_PORTS,
  load,
  id,
  ports
})

export const updatePCB = (pcbId) => ({
  type: SELECT_PCB,
  pcbId
})

export const changePCB = (pcbId, designType) => ({
  type: CHANGE_PCB,
  pcbId,
  designType
})

export const needUpdateCompSetting = (bool) => ({
  type: COMPONENT_SETTING_UPDATE,
  update: bool
});

export const savaImpedanceSignOffId = (signOffId) => ({
  type: SAVE_IMPEDANCE_SIGN_OFF_ID,
  signOffId
});

export const updateOpenTargetICLoading = (loading) => ({
  type: UPDATE_OPEN_TARGET_IC_LOADING,
  loading
});

export const reAssignDecapModel = () => ({
  type: RE_ASSIGN_DECAP_MODEL
})

export const deleteSensePort = (key, id, senseType) => ({
  type: DELETE_SENSE_PORT,
  key,
  id,
  senseType
})

export const updateOptions = (options) => ({
  type: UPDATE_OPTIONS,
  options
})

export const savePackagePorts = (id, ports, portType) => ({
  type: SAVE_PACKAGE_PORTS,
  id,
  ports,
  portType
})

export const updatePackageNets = (id, nets) => ({
  type: UPDATE_PACKAGE_NETS,
  id,
  nets
})

export const updateDomainAfterUpdateNets = (id) => ({
  type: SAVE_DOMAIN_BY_NEW_NETS,
  id
})

export const switchPackagePorts = (lumped, compType) => ({
  type: SWITCH_PACKAGE_PORTS_TYPE,
  lumped,
  compType
})

export const saveBallSize = (pcbId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab) => ({
  type: SAVE_BALL_SIZE,
  pcbId,
  ballShape,
  ballSize,
  ballHeight,
  ballMiddle,
  ballMaterial,
  notGroupPositivePin,
  compTab
})

export const saveAdditionalNets = (nets) => ({
  type: SET_ADDITIONAL_NETS,
  nets
})

export const updatePackage = (update) => ({
  type: REFRESH_PACKAGE,
  update
})

export const switchTargetDie = (die) => ({
  type: SWITCH_TARGET_DIE,
  die
})

export const saveWireBond = (libraryId, designId) => ({
  type: SAVE_WIRE_BOND,
  libraryId,
  designId
})

export const changeImpedancePage = (page) => ({
  type: CHANGE_PAGE,
  page
})

export const createNewLayout = (pcbId, connectors) => ({
  type: CREATE_NEW_LAYOUT,
  pcbId,
  connectors
})

export const deleteLayout = (pcbId) => ({
  type: DELETE_LAYOUT,
  pcbId
})

export const deleteLayoutConnector = (designId, connector) => ({
  type: DELETE_LAYOUT_CONNECTOR,
  pcbId: designId,
  connector
})

export const traceOverview = () => ({
  type: TRACE_OVERVIEW
})

export const deleteShip = (ship, connector) => ({
  type: DELETE_SHIP,
  connector,
  ship
})

export const createDie = () => ({
  type: CREATE_DIE
})

export const saveOnDieModel = (model, targetDie, modelPowerNet, modelIndex, apply, customType, dieCurrentList) => ({
  type: SAVE_ON_DIE_MODEL,
  model,
  targetDie,
  modelPowerNet,
  modelIndex,
  apply,
  customType,
  dieCurrentList
})

export const updateLogs = (logs, clear) => ({
  type: UPDATE_IMPEDANCE_LOGS,
  logs, clear
})

export const saveMultiPCBTarget = (target) => ({
  type: SAVE_MULTI_PCB_TARGET,
  target
})

export const updateDecapGroup = (file) => ({
  type: UPDATE_DECAP_GROUP,
  file
})

export const saveDieCurrent = ({ dieCurrent, currentDesignId, currentComp, currentPowerNet, currentIndex, apply }) => ({
  type: SAVE_DIE_CURRENT,
  dieCurrent,
  currentDesignId,
  currentComp,
  currentPowerNet,
  currentIndex,
  apply
})

export const updateDecapModel = (file, pcbId, display) => ({
  type: UPDATE_DECAP_MODEL,
  file,
  pcbId,
  display
})

export const saveCompTableDisplay = (display, pcbId, clearModel) => ({
  type: SAVE_COMP_TABLE_DISPLAY,
  display,
  pcbId,
  clearModel
})

export const changeUsage = (compNames, part, id, usage) => ({
  type: CHANGE_USAGE,
  compNames,
  part,
  id,
  usage
})

export const saveImpedanceDisplay = (overviewDisplay, compTableDisplay) => ({
  type: SAVE_IMPEDANCE_DISPLAY,
  overviewDisplay,
  compTableDisplay
})

export const savePMICVoltage = (value, name) => ({
  type: SAVE_PMIC_VOLTAGE,
  value,
  name
})

export const switchPackage = (designId) => ({
  type: SWITCH_PACKAGE,
  designId
})

export const saveInterposer = (info, interposerDie, interposerPowerNet, interposerIndex, apply) => ({
  type: SAVE_INTERPOSER,
  info,
  interposerDie,
  interposerPowerNet,
  interposerIndex,
  apply
})

export const saveInterposerNode = (value, key, name, powerNet, portIndex) => ({
  type: SAVE_INTERPOSER_NODE,
  value,
  key,
  name,
  powerNet,
  portIndex
})

export const saveHasInterposer = (hasInterposer) => ({
  type: SAVE_HAS_INTERPOSER,
  hasInterposer
})

export const updateCurrentStatus = (enable, designId, name) => ({
  type: UPDATE_IC_CURRENT_STATUS,
  enable,
  designId,
  name
})

export const saveRleakAndRrdl = ({ rleak, rdl, apply, rComp, rPowerNet, rPortIndex }) => ({
  type: SAVE_RLEAK_RRDL,
  rleak,
  rdl,
  apply,
  rComp,
  rPowerNet,
  rPortIndex
})

export const saveTransientTimeSetting = (startTime, stopTime, stepTime) => ({
  type: SAVE_TRANSIENT_TIME_SETTING,
  startTime,
  stopTime,
  stepTime
})