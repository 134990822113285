import {
  PCBS,
  END_TO_END_CHANNELS,
  PROJECT,
  PCB,
  END_TO_END_CHANNEL,
  PCB_PRE_LAYOUT,
  ADD_PCB_CHANNEL,
  END_TO_END_CHANNEL_RESULT,
  PACKAGES
} from '@/constants/treeConstants';
import { DESIGN_INDEX, END_TO_END_INDEX, PACKAGE_INDEX } from '..';
import { COPYING, COPY_FAILED } from '../../../constants/copyStatus';
import { LAYOUT } from '../../../constants/designType';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import { PACKAGE } from '../../../constants/treeConstants';
import { getChannelTypeWidth } from './projectHelper';

/* Project item*/
function projectItem({ id, name, category, children, nodeClass }) {
  this.id = id;
  this.name = name;
  this.key = PROJECT + '-' + this.id;
  this.dataType = PROJECT; // tree type
  this.nodeClass = 'tree-node-project-name ' + nodeClass;
  this.children = []; // [projectChild]
  this.category = category;
  this.children = children;
}

/**
 * Project children item
 *
 * @param {*} { name, id, dataType } name - 'PCB'|'Interface'
 *                                   key - PCBs|END_TO_END_CHANNELS-id id: projectId
 *                                   dataType - PCBs|END_TO_END_CHANNELS
 */
function projectChild({ name, id, dataType, category, children = [] }) {
  this.name = name; // 'PCB'|'Multi-PCB Channel'
  this.key = dataType + '-' + id; // id - projectId
  this.dataType = dataType; // dataType
  this.nodeClass = '';
  this.category = category;
  this.children = children || [];
}

function getDefaultProjectTree(arr) {

  if (!Array.isArray(arr)) {
    return [];
  };
  let copyingProjectList = [];
  const projects = arr.map(item => {
    //project copying
    if (item.category === COPYING) {
      copyingProjectList.push(item);
    }

    return new projectItem({
      id: item.id,
      name: item.name,
      category: item.category,
      nodeClass: [COPY_FAILED, COPYING].includes(item.category) ? "aurora-project-failed" : "",
      children:
        [...[PCB, "Package"].map(childItem => new projectChild({
          name: childItem,
          id: item.id, // projectId
          category: item.category,
          dataType: getProjectDataType(childItem)
        }))]
    })
  });
  return { projects, copyingProjectList };
}

function addChannelAction(designId) {
  this.name = 'Add Channel';
  this.id = designId;
  this.dataType = ADD_PCB_CHANNEL;
  this.key = `${ADD_PCB_CHANNEL}-${designId}`;
  this.nodeClass = "aurora-tree-add-action"
}

function getProjectTree(res, prevProjects) {
  if (!Array.isArray(res)) {
    return [];
  };
  let copyingProjectList = [];
  const projects = res.map(item => {
    //project copying
    if (item.category === COPYING) {
      copyingProjectList.push(item);
    }
    const prevProject = prevProjects.find(it => it.id === item.id) || { children: [] };
    const children = getProjectTreeChildren(prevProject, item);
    return new projectItem({
      id: item.id,
      name: item.name,
      category: item.category,
      nodeClass: [COPY_FAILED, COPYING].includes(item.category) ? "aurora-project-failed" : "",
      children

    })
  })
  return { projects, copyingProjectList };
}

function getProjectTreeChildren(prevProject, project) {
  if ([COPYING, COPY_FAILED].includes(project.category) || !prevProject.children.length) {
    return [PCB, "Package"].map(item => new projectChild({
      name: item,
      id: project.id, // projectId
      category: project.category,
      dataType: getProjectDataType(item)
    }))
  } else {
    prevProject.children.forEach(item => {
      item.category = project.category;
    });
    return prevProject.children;
  }
}

function getProjectDataType(type) {
  switch (type) {
    case PCB:
      return PCBS;
    case "Package":
      return PACKAGES;
    default: return type;
  }
}

/**
 * Design Item
 *
 * @param {Object} { id, name, projectId, designVersion, vendor } id - designId
 *                                                           name - designName, 
 *                                                           projectId - projectId, 
 *                                                           designVersion - designVersion, 
 *                                                           vendor - vendor
 */
function designItem({ id, name, projectId, designVersion, vendor, category, children, type, multiZone }) {
  this.id = id;
  this.name = name;
  const _type = type === LAYOUT ? PCB : PACKAGE;
  if (vendor === PRE_LAYOUT) {
    this.key = PCB_PRE_LAYOUT + '-' + id;
  } else {
    this.key = _type + '-' + id;
  }
  this.dataType = _type;
  this.projectId = projectId;
  this.designVersion = designVersion;
  this.vendor = vendor;
  this.children = [];//channels
  this.nodeClass = 'tree-node-pcb-name';
  this.addIconClass = "tree-node-pcb-add-icon";
  this.category = category;
  this.type = type;
  this.children = children || [];
  this.multiZone = multiZone || false;
};

function getDesignTree(arr) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const designs = arr.map(item => new designItem({
    id: item.id,
    name: item.name,
    projectId: item.projectId,
    designVersion: item.designVersion,
    vendor: item.vendor,
    category: item.category,
    type: item.type,
    children: [new addChannelAction(item.id)],
    multiZone: item.multiZone
  }));
  return designs;
};

/**
 * Verification Item
 *
 * @param {Object} { id, name, projectId, designVersion, vendor } id - endToEndChannel Id
 *                                                           name -endToEndChannelName,
 *                                                           version - version, 
 *                                                           readyForSim - readyForSim
 *                                                           verificationId - verificationId
 *                                                           type - Serdes type
 *                                                           status - verification status (RUNNING,NEVER,SUCCESS ,....)
 */
function endToEndChannelItem({ id, name, status, readyForSim, version, type, verificationId, prevEndToEndChildren, typeWidth }) {
  this.id = id;
  this.name = name;
  this.key = END_TO_END_CHANNEL + '-' + id;
  this.dataType = END_TO_END_CHANNEL;
  this.status = status;
  this.readyForSim = readyForSim;
  this.version = version;
  this.type = type;
  this.verificationId = verificationId;
  this.nodeClass = 'tree-node-interface-name';
  this.typeWidth = typeWidth;
  this.children = [
    /* { ...new endToEndChannelResultItem({ id, name: 'Result', dataType: END_TO_END_CHANNEL_RESULT }) }, */
    ...prevEndToEndChildren
  ];
}

/* function endToEndChannelResultItem({ id, name, dataType }) {
  this.name = name;
  this.key = dataType + '-' + id;
  this.dataType = dataType;
  this.nodeClass = 'andes_v2_result';
} */

function getEndToEndChannelTree(arr, prevEndToEndTrees = []) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const typeList = arr.map(item => item.type);
  const typeWidth = getChannelTypeWidth(typeList);
  const verifications = arr.map(item => {
    const prevEndToEnd = prevEndToEndTrees.find(it => it.id === item.id) || { children: [] };
    const prevEndToEndChildren = prevEndToEnd.children.filter(it => it.dataType !== END_TO_END_CHANNEL_RESULT);
    return new endToEndChannelItem({
      id: item.id,
      name: item.name,
      status: item.status,
      readyForSim: item.readyForSim,
      version: item.version,
      type: item.type,
      verificationId: item.verificationId,
      prevEndToEndChildren,
      typeWidth
    })
  }
  );
  return verifications;
};

/**
 * Add designs and verifications to project
 *
 * @param {*} { arr, projectId } arr - [designs, verifications]
 * @returns
 */
function updateProjectChild(projectItem, { designs, packages = [], endToEndList }) {
  projectItem.children[DESIGN_INDEX].children = designs;
  projectItem.children[PACKAGE_INDEX].children = packages;
  if (designs.length >= 2) {
    projectItem.children[END_TO_END_INDEX] = new projectChild({
      name: "Multi-PCB Channel",
      id: projectItem.id, // projectId
      category: projectItem.category,
      dataType: END_TO_END_CHANNELS,
      children: endToEndList
    });
  }

  return projectItem.children;
}

export {
  getDefaultProjectTree,
  getDesignTree,
  getEndToEndChannelTree,
  updateProjectChild,
  getProjectTree,
  projectChild,
  addChannelAction
}