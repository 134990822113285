const TYPE = 'PDN';
export const START_PDN_VERIFICATION = `${TYPE}/start_pdn_verification`;
export const CHANGE_VERIFICATION_LIST = `${TYPE}/change_verification_list`;
export const UPDATE_PDN_PROGRESS = `${TYPE}/update_pdn_progress`;
export const GET_SINGLE_MONITOR = `${TYPE}/get_single_monitor`;
export const SINGLE_VERIFY_INFO = `${TYPE}/single_verify_info`;
export const CLEAN_SINGLE_PROGRESS = `${TYPE}/clean_single_progress`;
export const UPDATE_SINGLE_MONITOR = `${TYPE}/update_single_monitor`;
export const CLEAN_MONITOR = `${TYPE}/clean_monitor`;
export const RUNNING_SHOW = `${TYPE}/running_show`;
export const CANCEL_WORKFLOW = `${TYPE}/cancel_workflow`;
export const CHANGE_UPLOAD_MES = `${TYPE}/change_upload_mes`;
export const DEBUG_VERIFY = `${TYPE}/debug_verify`;
export const RESULT_EXIST = `${TYPE}/result_exist`;
export const UPDATE_END_MSG = `${TYPE}/update_end_msg`;
export const PDN_CHECK_ERROR = `${TYPE}/pdn_check_error`;
export const WAITING_INDEX = `${TYPE}/waiting_index`;
export const GET_CURRENT_LOG = `${TYPE}/get_current_log`;
export const SAVE_CURRENT_LOG = `${TYPE}/save_current_log`;
export const KILL_WORKFLOW = `${TYPE}/kill_workflow`;
export const PDN_STACKUP_CHECK_ERROR = `${TYPE}/pdn_stackup_check_error`;
export const VRM_ERROR_INFO = `${TYPE}/vrm_error_info`;
export const DECAP_ERROR_INFO = `${TYPE}/decap_error_info`;
export const SAVE_CURRENT_CHECK_ID = `${TYPE}/save_current_check_id`;
export const UPDATE_LIBRARY_DATA_CHECK = `${TYPE}/update_library_data_check`;
export const CLEAR_SIMULATION_INFO = `${TYPE}/clear_simulation_info`;
export const RE_CHECK_SIMULATION_STACKUP = `${TYPE}/re_check_simulation_stackup`;
export const GET_VRM_MODEL = `${TYPE}/get_vrm_model`;
export const UPDATE_SIMULATION_MESSAGE = `${TYPE}/update_simulation_message`;
export const UPDATE_START_MSG = `${TYPE}/update_start_msg`;
export const GET_CURRENT_PROFILE = `${TYPE}/get_current_profile`;
export const SAVE_CURRENT_PROFILE = `${TYPE}/save_current_profile`;
export const GET_PDN_WORKFLOW = `${TYPE}/get_pdn_workflow`;