import { deleteUnUsedConnectorModels } from ".";
import { SPICE, TOUCHSTONE } from "../../../constants/libraryConstants";
import { SIERRA } from "../../../constants/pageType";
import { getDefaultIndex } from "../setDefaultName";

function getSignalConnectionsMap({ channel1Signals, channel2Signals, SignalConnectionMap }) {
  const length = channel1Signals.length < channel2Signals.length ? channel1Signals.length : channel2Signals.length;

  let signal_connections_map = [];
  for (let index = 0; index < length; index++) {
    signal_connections_map.push(new SignalConnectionMap({
      channel1_signal: channel1Signals[index] || "",
      channel2_signal: channel2Signals[index] || "",
      name: `Signal${index}`
    }))
  }
  return signal_connections_map;
}

function getChannelSetupContent(channelId, channelSetupInfo) {
  return new Promise((resolve, reject) => {
    if (!channelId) {
      resolve({});
      return;
    }
    channelSetupInfo.get(channelId).then(res => {
      if (!res || !res.content) {
        resolve({});
        return;
      }
      resolve(res.content);
    });
  })
}

function getNetType(signal, net) {
  const netTypes = ['nets_P', 'nets_N', 'nets_A', 'nets_B', 'nets_C'];
  for (const type of netTypes) {
    if (signal[type] && signal[type].includes(net)) {
      return type;
    }
  }
  return '';
}

/**
 *  get connection pin map 
 * @param {object} comp { pins: [ { pin, net, signal } ], name, type }
 * @param {String} type 
 * @param {Object} anotherConnector {}
 * @param {Object} currentInfo current connector channel info (connector1 channel info)
 * @param {Object} anotherInfo another connector channel info  {connector2 channel info}
 * @param {String} index current signal_connection_map index
 * @param {String} anotherIndex another signal_connection_map index
 * @param {Array} signal_connections_map [{ channel1_signal:"", channel2_signal:"",name:"Signal1" },{}]
 *  */
function getConnectionPinMap({
  comp,
  type,
  anotherConnector,
  currentInfo,
  anotherInfo,
  index,
  anotherIndex,
  signal_connections_map,
  ConnectorPin,
  currentConnector = {}
}) {
  let pins = [], anotherPins = anotherConnector.pins ? anotherConnector.pins : [], models = currentConnector.models || [];
  const connectorModelFiles = getConnModelFiles(comp.connectorModel);
  if (connectorModelFiles) {
    for (let item of connectorModelFiles) {
      const modelKey = getDefaultIndex(models.length, models.map(it => it.modelKey));
      item.newModelKey = modelKey;
      models.push({
        file: item.fileName,
        libraryId: item.libraryId,
        type: item.type,
        modelKey
      })
    }
  }

  //another component not exist
  if (!anotherConnector.component) {
    for (let item of comp.pins) {
      const signal_map = signal_connections_map.find(it => it[`channel${index}_signal`] === item.signal);
      if (signal_map) {
        const { pinPort, pinOutPort } = getConnectorModelByPin(comp.connectorModel, item.pin, connectorModelFiles);
        pins.push(new ConnectorPin({
          pin: item.pin,
          pinMap: type === "left" ? true : false,
          port: pinPort.port,
          modelId: pinPort.modelId,
          modelKey: pinPort.modelKey,
          externalMap: true,
          external_map: pinOutPort
        }))
      }
    }
    models = deleteUnUsedConnectorModels({ models, pins });
    return { pins, anotherPins, models }
  }

  const currentSignals = currentInfo.signals, anotherSignals = anotherInfo.signals, anotherComp = anotherInfo.components.find(item => item.name === anotherConnector.component);

  if (type === "left") {
    for (let item of comp.pins) {
      const signal_map = signal_connections_map.find(it => it[`channel${index}_signal`] === item.signal);
      if (!signal_map || !signal_map[`channel${anotherIndex}_signal`]) {
        continue;
      }
      const { pinPort, pinOutPort } = getConnectorModelByPin(comp.connectorModel, item.pin, connectorModelFiles);

      //find another component pins
      const anotherCompPins = anotherComp.pins.filter(it => it.signal === signal_map[`channel${anotherIndex}_signal`]);
      pins.push(
        new ConnectorPin({
          pin: item.pin,
          pinMap: true,
          port: pinPort.port,
          modelId: pinPort.modelId,
          modelKey: pinPort.modelKey,
          externalMap: true,
          external_map: pinOutPort,
          pin_map: findPinMapByNet({
            pinObj: item,
            channel1Signals: currentSignals,
            channel2Signals: anotherSignals,
            signalMap: signal_map,
            channel2Pins: anotherCompPins
          })
        })
      )
    }
  } else {
    //clear prev component pins
    anotherPins.forEach(it => { it.pin_map = "" });
    for (let item of comp.pins) {
      const signal_map = signal_connections_map.find(it => it[`channel${index}_signal`] === item.signal);
      if (!signal_map || !signal_map[`channel${anotherIndex}_signal`]) {
        continue;
      }
      const { pinPort, pinOutPort } = getConnectorModelByPin(comp.connectorModel, item.pin, connectorModelFiles);

      pins.push(
        new ConnectorPin({
          pin: item.pin,
          pinMap: false,
          port: pinPort.port,
          modelId: pinPort.modelId,
          modelKey: pinPort.modelKey,
          externalMap: true,
          external_map: pinOutPort,
        })
      )
      const anotherCompPins = anotherComp.pins.filter(it => it.signal === signal_map[`channel${anotherIndex}_signal`]);
      const anotherPin = findPinMapByNet({
        pinObj: item,
        channel1Signals: currentSignals,//channel2_signals
        channel2Signals: anotherSignals,//channel1_signals
        signalMap: signal_map,
        channel2Pins: anotherCompPins,//channel1Pins
        index: 2,
        anotherIndex: 1
      })
      const _antherPinIndex = anotherPins.findIndex(it => it.pin === anotherPin);
      if (_antherPinIndex > -1) {
        anotherPins[_antherPinIndex].pin_map = item.pin;
      }
    }
  }
  /*deleteUnUsedConnectorModels  */
  models = deleteUnUsedConnectorModels({ models, pins });
  return { pins, anotherPins, models };
}

function getConnModelFiles(connectorModel) {
  if (!connectorModel || connectorModel.type === 'None') {
    return false;
  }
  if (connectorModel.files && connectorModel.files.length) {

    return JSON.parse(JSON.stringify(connectorModel.files));
  }
  return false;
}

function getConnectorModelByPin(connectorModel, pin, connectorModelFiles) {
  let pinPort = { port: "", modelId: "", modelKey: "" }, pinOutPort = { port: "", modelId: "", modelKey: "" };

  if (!connectorModelFiles || !connectorModelFiles.length) {
    return { pinPort, pinOutPort };
  }

  const findPinPort = connectorModel.pairs.find(item => item.pin === pin);
  const findPinOutPort = connectorModel.pairs.find(item => item.pin === `${pin}_u`);
  if (findPinPort) {
    const findFile = connectorModelFiles.find(it => it.libraryId === findPinPort.libraryId && (!findPinPort.modelKey || findPinPort.modelKey === it.modelKey))
    pinPort = findFile && findFile.newModelKey ? { port: findPinPort.node, modelId: findPinPort.libraryId, modelKey: findFile.newModelKey } : { port: "", modelId: "", modelKey: "" };
  }

  if (findPinOutPort) {
    const _findFile = connectorModelFiles.find(it => it.libraryId === findPinOutPort.libraryId && (!findPinOutPort.modelKey || findPinOutPort.modelKey === it.modelKey))
    pinOutPort = _findFile && _findFile.newModelKey ? { port: findPinOutPort.node, modelId: findPinOutPort.libraryId, modelKey: _findFile.newModelKey } : { port: "", modelId: "", modelKey: "" };
  }
  return { pinPort, pinOutPort };
}

const netToPinType = {
  nets_P: 'positive',
  nets_N: 'negative',
  nets_A: 'Line A',
  nets_B: 'Line B',
  nets_C: 'Line C'
}

function getSignalPinTypeByNet(signals, net, signal) {
  const signalItem = signals.find(item => item.name === signal);
  if (!signalItem) {
    return null;
  }
  for (const key of ['nets_P', 'nets_N', 'nets_A', 'nets_B', 'nets_C']) {
    if (signalItem[key] && signalItem[key].includes(net)) {
      return netToPinType[key];
    }
  }
  return null;
}

function getUsableExternalMap(fileList, external_map, product) {
  // external_map: {cableModelId, cableModelKey, cablePort, modelId, modelKey, port}
  if (product === SIERRA) {
    return external_map || {};
  }
  if (!external_map) {
    return {}
  }

  let _external_map = {}
  const cableFileExist = fileList.find(it => it.id === external_map.cableModelId);
  if (cableFileExist) {
    _external_map = {
      ..._external_map,
      cableModelId: external_map.cableModelId,
      cableModelKey: external_map.cableModelKey,
      cablePort: external_map.cablePort,
    }
  }

  const fileExist = fileList.find(it => it.id === external_map.modelId);
  if (fileExist) {
    _external_map = {
      ..._external_map,
      modelId: external_map.modelId,
      modelKey: external_map.modelKey,
      port: external_map.port,
    }
  }

  return _external_map;
}

function getUsablePort(fileList, pin, product) {
  const fileExist = fileList.find(it => it.id === pin.modelId);
  return fileExist || product === SIERRA ? pin.port : "";
}

function getDisplayPinList({
  connector1 = {},
  connector2 = {},
  channel1Comps = [],
  channel2Comps = [],
  signal_connections_map,
  channel_signal = true,
  channel1Info = { signals: [] },
  channel2Info = { signals: [] },
  connectorFileList = [],
  product
}) {

  let pinList = [];
  let channel1Pins = JSON.parse(JSON.stringify(connector1.pins));
  let channel2Pins = JSON.parse(JSON.stringify(connector2.pins));
  const channel1Signals = channel1Info.signals || [],
    channel2Signals = channel2Info.signals || [];

  const comp1 = channel1Comps.find(item => item.name === connector1.component) || { pins: [] };
  const comp2 = channel2Comps.find(item => item.name === connector2.component) || { pins: [] };

  channel1Pins = channel1Pins.map(item => {
    const pinItem = comp1 && comp1.pins ? comp1.pins.find(it => it.pin === item.pin) || {} : {};
    return {
      ...item,
      net: pinItem.net,
      signal: pinItem.signal,
      pinType: getSignalPinTypeByNet(channel1Signals, pinItem.net, pinItem.signal)
    }
  })

  channel2Pins = channel2Pins.map(item => {
    const pinItem = comp2 && comp2.pins ? comp2.pins.find(it => it.pin === item.pin) || {} : {}
    return {
      ...item,
      net: pinItem.net,
      signal: pinItem.signal,
      pinType: getSignalPinTypeByNet(channel2Signals, pinItem.net, pinItem.signal)
    }
  })

  for (let signal of signal_connections_map) {
    pinList.push({
      signal: signal.name,
      channel1_signal: signal.channel1_signal,
      channel2_signal: signal.channel2_signal,
      pins: []
    })
  }

  let existChannel2Pins = [];
  for (let item of channel1Pins) {
    const rightPin = channel2Pins.find(it => it.pin === item.pin_map) || {};
    rightPin.pin && existChannel2Pins.push(rightPin.pin);

    const pinItem = {
      //pin L (Channel1 pin)
      pinL: item.pin,
      pinLNet: item.net,
      pinLSignal: item.signal,
      pinLPort: getUsablePort(connectorFileList, item, product),
      pinLModelId: item.modelId,
      pinLModelKey: item.modelKey,
      pinLExternalMap: getUsableExternalMap(connectorFileList, item.external_map, product),
      pinLType: item.pinType,
      //pin R (Channel2 pin)

      pinR: rightPin.pin,
      pinRNet: rightPin.net,
      pinRSignal: rightPin.signal,
      pinRPort: getUsablePort(connectorFileList, rightPin, product),
      pinRModelId: rightPin.modelId,
      pinRModelKey: rightPin.modelKey,
      pinRExternalMap: getUsableExternalMap(connectorFileList, rightPin.external_map, product),
      pinRType: rightPin.pinType,
    };

    const index = channel_signal ? pinList.findIndex(it => it.channel1_signal === item.signal)
      : pinList.findIndex(it => it.signal === item.signal);
    if (index > -1) {
      pinList[index].pins.push({
        ...pinItem
      });
    }
  }

  const noMapChannel2Pins = channel2Pins.filter(it => !existChannel2Pins.includes(it.pin));

  if (noMapChannel2Pins.length) {
    for (let item of noMapChannel2Pins) {
      const pinItem = {
        //pin R (Channel2 pin)
        pinR: item.pin,
        pinRNet: item.net,
        pinRSignal: item.signal,
        pinRPort: item.port,
        pinRModelId: item.modelId,
        pinRModelKey: item.modelKey,
        pinRExternalMap: item.external_map || {},
        pinLExternalMap: {},
        pinLType: item.pinType,
      };
      const index = channel_signal ? pinList.findIndex(it => it.channel2_signal === item.signal)
        : pinList.findIndex(it => it.signal === item.signal);
      if (index > -1) {
        pinList[index].pins.push({
          ...pinItem
        });
      }
    }
  }

  return pinList.filter(item => !!item.pins.length);
}

function getConnectorChannelIndex(type) {
  if (type === "left") {
    return "1";
  }

  if (type === "right") {
    return "2";
  }
}

function addConnectorPinsByComp({
  key,
  type,
  anotherType,
  propsInfo,
  ConnectorPin
}) {
  const index = getConnectorChannelIndex(type);
  const anotherIndex = getConnectorChannelIndex(anotherType);
  const comp = propsInfo[`channel${index}Comps`].find(item => item.name === key);
  if (!comp) {
    return {}
  }
  const { pins, anotherPins, models } = getConnectionPinMap({
    comp,
    type,
    index,
    anotherIndex,
    currentInfo: propsInfo[`channel${index}Info`],
    anotherInfo: propsInfo[`channel${anotherIndex}Info`],
    anotherConnector: propsInfo[`connector${anotherIndex}`],
    signal_connections_map: propsInfo.signal_connections_map,
    ConnectorPin,
    currentConnector: propsInfo[`connector${index}`]
  })

  return {
    [`connector${index}`]: {
      ...propsInfo[`connector${index}`],
      component: key,
      pins,
      models
    },
    [`connector${anotherIndex}`]: {
      ...propsInfo[`connector${anotherIndex}`],
      pins: anotherPins
    }
  }
}

function deleteConnectorComp(type, anotherType, propsInfo) {
  const index = getConnectorChannelIndex(type);
  const anotherIndex = getConnectorChannelIndex(anotherType);
  if (anotherType === "left") {
    propsInfo[`connector${anotherIndex}`].pins.forEach(item => {
      item.pin_map = "";
    })
  }

  return {
    [`connector${index}`]: {
      ...propsInfo[`connector${index}`],
      component: "",
      pins: [],
      models: []
    },
    [`connector${anotherIndex}`]: {
      ...propsInfo[`connector${anotherIndex}`]
    }
  }
}

/**
 * add connector pins by signal map
 * @param {Object} connector1 channel1 connector {component, pins:[ { pin ,modelId, pin_map, port }]}
 * @param {Object} connector2 channel2 connector {component, pins:[ { pin ,modelId, port }]}
 * @param {Array} newSignalConnMap new add signal map list [ { channel1_signal, channel2_signal } ]
 * @param {Array} channel1Comps channel1 component list [ { name, type, pins: [{ pin, signal, net }] } ]
 * @param {Array} channel2Comps channel2 component list [ { name, type, pins: [{ pin, signal, net }] } ]
 *  */
function addConnectorPinBySignalMap({
  connector1,
  connector2,
  prevSignal,
  signalType,
  newSignalConnMap,
  channel1Comps,
  channel2Comps,
  channel1Info,
  channel2Info,
  ConnectorPin
}) {
  let _connector1 = { ...connector1 }, _connector2 = { ...connector2 };
  //find component
  const findComp1 = channel1Comps.find(item => item.name === _connector1.component) || { pins: [] },
    findComp2 = channel2Comps.find(item => item.name === _connector2.component) || { pins: [] };

  const channel1Signals = channel1Info.signals, channel2Signals = channel2Info.signals;

  if (prevSignal && signalType) {
    if (signalType === "channel1") {
      _connector1 = deleteConnectorPins({
        connector: _connector1,
        findComp: findComp1,
        prevSignal
      })
    } else if (signalType === "channel2") {
      _connector2 = deleteConnectorPins({
        connector: _connector2,
        findComp: findComp2,
        prevSignal
      })
    }
  }

  for (let signalMap of newSignalConnMap) {

    //find comp pins
    const findPins1 = findComp1.pins.filter(item => item.signal === signalMap.channel1_signal) || [];
    const findPins2 = findComp2.pins.filter(item => item.signal === signalMap.channel2_signal) || [];
    //add channel1 connector pin
    for (let item of findPins1) {
      const findIndex = _connector1.pins.findIndex(it => it.pin === item.pin);
      const pin_map = findPinMapByNet({
        pinObj: item,
        channel1Signals,
        channel2Signals,
        signalMap,
        channel2Pins: findPins2
      })
      if (findIndex > -1) {
        _connector1.pins[findIndex].pin_map = pin_map;
        continue;
      }
      _connector1.pins.push(new ConnectorPin({
        pin: item.pin,
        pinMap: true,
        pin_map
      }))
    }

    //add channel2 connector pin
    for (let item of findPins2) {
      const find = _connector2.pins.find(it => it.pin === item.pin);
      if (find) {
        continue;
      }
      _connector2.pins.push(new ConnectorPin({
        pin: item.pin,
        pinMap: false
      }))
    }
  }
  return { connector1: _connector1, connector2: _connector2 }
}

function deleteConnectorPins({
  connector,
  findComp,
  prevSignal
}) {
  const deletePins = findComp.pins.filter(item => item.signal === prevSignal).map(item => item.pin);
  connector.pins = connector.pins.filter(it => !deletePins.includes(it.pin));
  return connector;
}

/**
 *  find pin_map by pinObj { pin, net, signal }
 * @param {Object} pinObj  { pin, net, signal }
 * @param {Array} channel1Signals [{ name, group, nets_P, nets_N }]
 * @param {Array} channel2Signals [{ name, group, nets_P, nets_N }] target signal
 * @param {Object} signalMap { channel1_signal, channel2_signal }
 * @param {Array} channel2Pins [ { pin, net, signal }, ...] channel2_signals component pins
 * @param {string} index_anotherIndex (channel_signal type)
 *  */
function findPinMapByNet({
  pinObj,
  channel1Signals = [],
  channel2Signals = [],
  signalMap,
  channel2Pins = [],
  index = "1",
  anotherIndex = "2"
}) {
  //find channel1 signal -> { name, nets_P, nets_N } / { name, nets_A, nets_B, nets_C}
  const channel1Signal = channel1Signals.find(it => it.name === signalMap[`channel${index}_signal`]);
  //netType -> nets_P / nets_N / nets_A / nets_B / nets_C
  const netType = getNetType(channel1Signal, pinObj.net);
  // find channel2 signal -> { name, nets_P, nets_N } / { name, nets_A, nets_B, nets_C}
  const anotherSignal = channel2Signals.find(it => it.name === signalMap[`channel${anotherIndex}_signal`]);
  //find same net type pin
  const anotherPin = channel2Pins.find(it => anotherSignal && anotherSignal[netType].includes(it.net));
  return anotherPin ? anotherPin.pin : "";
}

/**
 * delete connector pins by signal map
 * @param {Object} connector1 channel1 connector {component, pins:[ { pin ,modelId, pin_map, port }]}
 * @param {Object} connector2 channel2 connector {component, pins:[ { pin ,modelId, port }]}
 * @param {Array} deleteSignalConnMap deleted signal map list [ { channel1_signal, channel2_signal } ]
 * @param {Array} channel1Comps channel1 component list [ { name, type, pins: [{ pin, signal, net }] } ]
 * @param {Array} channel2Comps channel2 component list [ { name, type, pins: [{ pin, signal, net }] } ]
 *  */
function deleteConnectorBySignalMap({
  connector1,
  connector2,
  deleteSignalConnMap,
  channel1Comps,
  channel2Comps
}) {
  let _connector1 = { ...connector1 }, _connector2 = { ...connector2 };
  //find component
  const findComp1 = channel1Comps.find(item => item.name === _connector1.component) || { pins: [] },
    findComp2 = channel2Comps.find(item => item.name === _connector2.component) || { pins: [] };
  //delete connector pin
  for (let signal of deleteSignalConnMap) {
    //find deleted pins
    const findPins1 = findComp1.pins.filter(item => signal.channel1_signal && item.signal === signal.channel1_signal).map(item => item.pin);
    const findPins2 = findComp2.pins.filter(item => signal.channel2_signal && item.signal === signal.channel2_signal).map(item => item.pin);
    //filter deleted pins
    _connector1.pins = _connector1.pins.filter(item => !findPins1.includes(item.pin));
    _connector2.pins = _connector2.pins.filter(item => !findPins2.includes(item.pin));
  }
  return { connector1: _connector1, connector2: _connector2 }
}

function updateConnectorPins({
  type,
  newSignalConnMap = [],
  prevSignal,
  signalType,
  deleteSignalConnMap = [],
  connector1,
  connector2,
  channel1Comps,
  channel2Comps,
  channel1Info,
  channel2Info,
  ConnectorPin
}) {
  if (type === "delete") {
    return deleteConnectorBySignalMap({
      connector1,
      connector2,
      deleteSignalConnMap,
      channel1Comps,
      channel2Comps
    });
  } else if (type === "add") {
    return addConnectorPinBySignalMap({
      connector1,
      connector2,
      prevSignal,
      signalType,
      newSignalConnMap,
      channel1Comps,
      channel2Comps,
      channel1Info,
      channel2Info,
      ConnectorPin
    });
  }
  return {
    connector1,
    connector2
  }
}

function getConnFileList({
  cableModels,
  connector1,
  connector2
}) {
  let files = [];
  cableModels && cableModels.forEach(model => {
    files.push({ ...model })
  });
  connector1.models && connector1.models.forEach(model => {
    files.push({ ...model })
  })
  connector2.models && connector2.models.forEach(model => {
    files.push({ ...model })
  });
  return files;
}

function getConnectorModels(connModels, fileList = []) {
  let _models = [];
  for (let i = 0; i < connModels.length; i++) {
    const model = connModels[i];
    const isExist = fileList.find(it => it.id === model.libraryId);
    let sameLibIndex = -1;
    if (model.type === TOUCHSTONE) {
      sameLibIndex = _models.findIndex(item => model.libraryId && item.libraryId === model.libraryId);
    }
    if (model.type === SPICE) {
      sameLibIndex = _models.findIndex(item => model.libraryId && item.libraryId === model.libraryId && (model.subckt && model.subckt === item.subckt));
    }
    if (sameLibIndex > -1) {
      _models[sameLibIndex].children.push({
        file: model.file,
        type: model.type,
        libraryId: model.libraryId,
        subckt: model.subckt,
        modelKey: model.modelKey,
        isExist
      })
    } else {
      _models.push({
        file: model.file,
        type: model.type,
        libraryId: model.libraryId,
        subckt: model.subckt,
        modelKey: model.modelKey,
        children: [{
          file: model.file,
          type: model.type,
          libraryId: model.libraryId,
          subckt: model.subckt,
          modelKey: model.modelKey
        }],
        isExist
      })
    }
  }

  let modelIndex = 1;
  _models.forEach(item => {
    item.children.forEach(it => {
      it.modelIndex = modelIndex;
      modelIndex += 1;
    })
  })

  return _models;
}

function getPortsSelectFiles(models) {
  let files = [];

  for (let model of models) {
    if (model.children && model.children.length > 0) {
      files.push(...JSON.parse(JSON.stringify(model.children)));
    }
  }
  return files;
}

function getModelListLength(models) {
  let length = 0;
  for (let model of models) {
    if (!model.type || model.type === TOUCHSTONE) {
      length += 1;
    }
    if (model.type === SPICE) {
      length += 2;
    }
  }
  return length;
}

export {
  getSignalConnectionsMap,
  getDisplayPinList,
  addConnectorPinsByComp,
  deleteConnectorComp,
  getConnectorChannelIndex,
  updateConnectorPins,
  getConnFileList,
  findPinMapByNet,
  getChannelSetupContent,
  getNetType,
  getConnectorModels,
  getPortsSelectFiles,
  getModelListLength,
  getSignalPinTypeByNet,
  netToPinType
}