import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '../Panel';
import EditableTable from '../EditableTable';
import { Tooltip } from 'antd';
import { getComponentByName } from '../../services/PCBHelper';
import './index.css';
import designConstructor from '../../services/helper/designConstructor';
import preLayoutData from '../../services/Sierra/prelayout/preLayoutData';

const tableColumns = [{
  title: 'Connector',
  dataIndex: 'pinNumber',
  width: '50%'
}, {
  title: 'Connector',
  dataIndex: 'pinMapNumber',
  width: '50%',
}];

class ConnectionPinMapPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinList: [],
      connLPinList: [],
      connRPinList: [],
      pinPins: [],
      pinMapPins: []
    };
    this.dialogRoot = document.getElementById('root');

    tableColumns[0].title = () => {
      const { connectorL = {} } = this.props;
      return <span>
        Connector {connectorL.comp}
      </span>
    }

    tableColumns[1].title = () => {
      const { connectorR = {} } = this.props;
      return <span>
        Connector {connectorR.comp}
      </span>
    }

    tableColumns[0].render = (value, record) => {
      const pin = record.pin || {};
      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {pin.pin}</div>
          <div>Pin Name: {pin.pinInfo.mName}</div>
        </div>}
      >
        <div className="connection-pin-map-columns">{pin.pin}</div>
      </Tooltip>
    }

    tableColumns[0].onCell = (record) => {
      const { connLPinList } = this.state;
      return {
        record: { ...record, pinNumber: record.pin.pin },
        edit: "select",
        options: connLPinList || [],
        dataIndex: "pinNumber",
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "connection-pin-map-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "pin", "pinNumber") },
      }
    }

    tableColumns[1].onCell = (record) => {
      const { connRPinList } = this.state;
      return {
        record: { ...record, pinMapNumber: record.pinMap.pin },
        edit: "select",
        options: connRPinList || [],
        dataIndex: "pinMapNumber",
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "connection-pin-map-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "pinMap", "pinMapNumber") },
      }
    }

    tableColumns[1].render = (value, record) => {
      const pinMap = record.pinMap || {};
      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {pinMap.pin}</div>
          <div>Pin Name: {pinMap.pinInfo.mName}</div>
        </div>}
      >
        <div className="connection-pin-map-columns">{pinMap.pin}</div>
      </Tooltip>
    }

  }

  componentDidMount = async () => {
    const { connectorL = {}, connectorR = {} } = this.props;
    const connectorLPins = await this.getPinInfo(connectorL.designId, connectorL.comp);
    const connectorRPins = await this.getPinInfo(connectorR.designId, connectorR.comp)
    this.getPinMap({ connectorLPins, connectorRPins });
  }

  getPinInfo = async (designId, comp) => {
    if (!designId || !comp) {
      return []
    }
    if (designConstructor.isPreLayout(designId)) {
      const prelayout = await preLayoutData.getPreLayout(designId);
      const _components = prelayout.content.components;
      const findComp = _components.find(item => item.name === comp);
      const pinList = findComp && findComp.pins ? findComp.pins : []
      return pinList.map(item => {
        return {
          ...item,
          mNumber: item.pin
        }
      })
    }

    const pcbComp = getComponentByName(designId, comp);
    const mPinList = pcbComp.mPart && pcbComp.mPart.mPinList ? pcbComp.mPart.mPinList : [];
    const allCompPins = JSON.parse(JSON.stringify(mPinList));
    return allCompPins;
  }

  getPinMap = ({ connectorLPins, connectorRPins }) => {
    const { connectionPinMap } = this.props;
    let pinList = [], connLPinList = [], connRPinList = [], index = 0;
    for (let pinItem of connectionPinMap.pins || []) {
      const findPin = connectorLPins.find(item => item.mNumber === pinItem.pinL);
      const findPinMap = connectorRPins.find(item => item.mNumber === pinItem.pinR);
      let pin = {
        pin: pinItem.pinL,
        pinInfo: findPin ? { ...findPin } : {},
      }, pinMap = {
        pin: pinItem.pinR,
        pinInfo: findPinMap ? { ...findPinMap } : {},
      };
      pinItem.pinL && connLPinList.push(pinItem.pinL);
      pinItem.pinR && connRPinList.push(pinItem.pinR);
      pinList.push({
        pin,
        pinMap,
        index
      })
      index++
    }
    this.setState({
      pinList,
      connLPinList,
      connRPinList,
      pinPins: connectorLPins,
      pinMapPins: connectorRPins
    })
  }

  editPinMap = (record, prevRecord, type, valueType) => {
    const { pinList } = this.state;
    let _pinList = [...pinList];

    const newPin = record[valueType];

    if (!newPin) {
      return;
    }

    const currIndex = _pinList.findIndex(item => item.index === record.index);
    if (currIndex < 0) {
      return
    }

    let newValue = null;
    //Find the last index of the selected pin
    const pinIndex = _pinList.findIndex(item => item[type].pin === newPin);
    newValue = pinIndex > -1 ? JSON.parse(JSON.stringify(_pinList[pinIndex][type])) : null;

    if (pinIndex > -1) {
      _pinList[pinIndex][type] = prevRecord[type].pin ? JSON.parse(JSON.stringify(prevRecord[type])) : {
        pin: "",
        pinInfo: {}
      };
    }

    const findPin = (this.state[`${type}Pins`] || []).find(item => item.mNumber === newPin);
    newValue = {
      pin: newPin,
      pinInfo: {
        ...findPin || {}
      }
    }

    _pinList[currIndex][type] = newValue;
    this.setState({
      pinList: _pinList
    })
  }


  closePanel = () => {
    const { pinList } = this.state;
    const pinMapList = pinList.map(item => {
      return { pinL: item.pin.pin, pinR: item.pinMap.pin }
    })
    this.props.closePanel(pinMapList);
  }

  render = () => {
    const { pinList } = this.state;
    const { connectionPinMap } = this.props;
    const content = (
      <Panel
        className='connection-pin-map-panel'
        position='panel-center-left'
        title={`Connection Pin Map - ${connectionPinMap.signal}`}
        zIndex={2000}
        onCancel={() => { this.closePanel() }}
        width={600}
        draggable
        minHeight={200}
      >
        <div className='connection-pin-map-content'>
          <EditableTable
            rowKey="pinNumber"
            columns={tableColumns}
            size="small"
            dataSource={pinList}
          >
          </EditableTable>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ConnectionPinMapPanel;