
/**
 * Calculate the coordinates according to the center, radius and angle of the circle
 * x0 - Center coordinate X
 * y0 - Center coordinate Y
 * r - Radius
 * angle - angle
 * 
 * @param {*} { x0, y0, r, angle }
 * @return {*} 
 */
function calcCirclePoints({ x0, y0, r, angle, arc }) {
  const _arc = arc ? arc : angleToArc(angle);
  const x = x0 + Math.cos(_arc) * r;
  const y = y0 + Math.sin(_arc) * r;
  return { x, y }
}

/**
 * Angle to Arc
 * 
 * @param {*} angle 
 * @returns 
 */
function angleToArc(angle) {
  return angle * Math.PI / 180;
}


//  A .
//    |\
//    | \  
//  b |  \ c
//    |   \
//  C ------ B
//      a
/**
 * Calculate the side length of the right angle according to the hypotenuse and arc.
 * ABC is a right triangle, a and b are right sides, and c is hypotenuse.
 * 
 * @param {*} arc
 * @param {*} c
 * @return {*} 
 */
function getAbyArcAndR(arc, c) {
  return Math.cos(arc) * c;
}

function getBbyArcAndR(arc, c) {
  return Math.sin(arc) * c;
}


function calcArc(start, end) {
  return Math.atan(tanA(start, end));
}

/**
 * linear interpolation
 * Given the two-point coordinates, find the point coordinates on the line segment between the connecting points
 * start(mX,mY)-----C(x)-----end(mX,mY)    end.mX !== start.mX
 * @param {*} start
 * @param {*} end 
 * @param {*} x
 * @return {*} 
 */
function calcPointBetweenTwoGivenPoints(start, end, x) {
  return start.mY + (x - start.mX) * tanA(start, end);
}

function tanA(start, end) {
  return (end.mY - start.mY) / (end.mX - start.mX);
}

/**
 * Calculate the distance between two points
 *
 * @param {*} t1 { mX, mY }
 * @param {*} t2 { mX, mY }
 * @return {*} 
 */
function calcTwoPointsDistance(t1, t2) {
  const x = t1.mX - t2.mX, y = t1.mY - t2.mY;
  return Math.sqrt(x * x + y * y);
}

/**
 *
 * a·b = x1*x2+y1*y2
 * c: centreOfCircle
 */
function scalarProduct(point1, point2, c) {
  const _c = c || { mX: 0, mY: 0 }
  return (point1.mX - _c.mX) * (point2.mX - _c.mX) + (point1.mY - _c.mY) * (point2.mY - _c.mY);
}

/**
 * Calculate the included angle
 * IncludedAngle ∠Θ = arccos((a·b)/(|a|*|b|)) 
 * 
 * a·b = x1*x2+y1*y2
 * |a| = Math.sqr(x1*x1+y1*y1)
 * |b| = Math.sqr(x2*x2+y2*y2)
 * point1 - { mX, mY }
 * point2 - { mX, mY }
 * centreOfCircle - [Option] { mX, mY }
 */

// Test
// const start = { mX: 1, mY: 0 };
// console.log(_calcArc(start, { mX: 1, mY: 0 }, { mX: 0, mY: 0 }) / Math.PI * 180)
// console.log(_calcArc(start, { mX: Math.sqrt(2) / 2, mY: Math.sqrt(2) / 2 }, { mX: 0, mY: 0 }) / Math.PI * 180)
// console.log(_calcArc(start, { mX: 0, mY: 1 }, { mX: 0, mY: 0 }) / Math.PI * 180)
// console.log(_calcArc(start, { mX: -Math.sqrt(2) / 2, mY: Math.sqrt(2) / 2 }, { mX: 0, mY: 0 }) / Math.PI * 180)
// console.log(_calcArc(start, { mX: -1, mY: 0 }, { mX: 0, mY: 0 }) / Math.PI * 180)
// console.log(_calcArc(start, { mX: -Math.sqrt(2) / 2, mY: -Math.sqrt(2) / 2 }, { mX: 0, mY: 0 }) / Math.PI * 180)
// console.log(_calcArc({ mX: 3650.14, mY: 91.5 }, { mX: 3640.8, mY: 82.16 }, { mX: 3640.8, mY: 91.5 }) / Math.PI * 180)
// console.log(_calcArc(start, { mX: Math.sqrt(2) / 2, mY: -Math.sqrt(2) / 2 }, { mX: 0, mY: 0 }) / Math.PI * 180)
// console.log('------------------')
function calcIncludedAngle(point1, point2, centreOfCircle) {
  return Math.acos(scalarProduct(point1, point2, centreOfCircle) / (calcTwoPointsDistance(point1, centreOfCircle) * calcTwoPointsDistance(point2, centreOfCircle)));
}

function getAngle(x, y) {
  var radian = Math.atan(y / x);
  var angle = Math.floor(180 / (Math.PI / radian));
  if (x < 0) {
    angle = angle + 180;
  }
  return angle;
}

function getRightAngleSide(long, angle) {
  var radian = 2 * Math.PI / 360 * angle;
  return {
    a: Math.sin(radian) * long,//collar
    b: Math.cos(radian) * long//opposite
  };
}

function keepTwoDecimal(num) {
  return Math.round(num * 100) / 100;
}

export {
  calcCirclePoints,
  calcArc,
  calcPointBetweenTwoGivenPoints,
  tanA,
  getAbyArcAndR,
  getBbyArcAndR,
  calcTwoPointsDistance,
  scalarProduct,
  calcIncludedAngle,
  getAngle,
  getRightAngleSide,
  keepTwoDecimal
}