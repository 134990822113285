import { getModelingListPromise } from '@/services/Rocky'

class MultipleResultConstructor {
  constructor() {
    this.resultList = new Map();
  }

  setInfo(id, info) {
    this.resultList.set(id, info);
  }

  getRemoteInfo(id, update) {
    return new Promise((resolve, reject) => {
      const info = this.resultList.get(id);
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getModelingListPromise(id).then(res => {
          if (res) {
            this.setInfo(id, res)
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  getCurrentChannelInfo(id) {
    return this.resultList.get(id) || []
  }

  cleanInfo() {
    this.resultList = new Map()
  }
}

let multipleResultConstructor = new MultipleResultConstructor();
export default multipleResultConstructor;