import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PCB } from '@/constants/treeConstants';
import LayoutExplorer from '../../LayoutExplorer/FastPI';
import { PCB_ONLY, PCB_TOP_BOTTOM } from '../../../constants/layoutConstants';
import TBLayout from '@/components/ContentLayout/topBottom';
import LRLayout from '@/components/ContentLayout/leftRight';
import projectDesigns from '@/services/helper/projectDesigns';

class PCBLayout extends Component {

  getTop = (height, pcbInfo) => {
    const { siderWidth } = this.props;
    switch (pcbInfo.key) {
      case PCB:
        return <LayoutExplorer
          designID={pcbInfo.id}
          leftWidth={siderWidth}
        />
      default: return null;
    }
  }

  getDefault = (pcbInfo, topHeight = 0, leftWidth = 0) => {
    const { siderWidth } = this.props;
    switch (pcbInfo.key) {
      case PCB:
        return <LayoutExplorer
          designID={pcbInfo.id}
          leftWidth={siderWidth + leftWidth}
          topHeight={topHeight}
        />
      default: return null;
    }
  }

  getBottom = (pcbInfo, topHeight) => {
    return this.getDefault(pcbInfo, topHeight);
  }

  getLeft = (pcbInfo) => {
    return this.getDefault(pcbInfo);
  }

  getRight = (pcbInfo, leftWidth) => {
    return this.getDefault(pcbInfo, 0, leftWidth);
  }

  getContent = () => {
    const { pcbLayout, selectedKeys, siderWidth, currentProjectId } = this.props;
    let pcbs = [];
    const currentProjectDesignIds = projectDesigns.getAvailableDesignIds(currentProjectId) || [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if (arr[0] === PCB && currentProjectDesignIds.includes(arr[1])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) return null;
    if (pcbLayout === PCB_ONLY || pcbs.length === 1) {
      return <LayoutExplorer designID={pcbs[0].id} leftWidth={siderWidth} />
    } else if (pcbLayout === PCB_TOP_BOTTOM) {
      return <TBLayout
        {...this.props}
        id={"fastpi-content-pcb"}
        bottomClassName={"fastpi-content-bottom"}
        getTop={(h) => this.getTop(h, pcbs[0])}
        getBottom={(topHeight) => this.getBottom(pcbs[1], topHeight)}
      />
    } else {
      return <LRLayout
        {...this.props}
        leftClassName={"fastpi-content-left"}
        getLeft={() => this.getLeft(pcbs[0])}
        getRight={(leftWidth) => this.getRight(pcbs[1], leftWidth)}
        lineClassName='aurora-layout-split-screen'
      />
    }
  }

  render() {
    return (
      this.getContent()
    )
  }
}

const mapState = (state) => {
  const { PDNReducer } = state;
  const { project: { pcbLayout, treeSelectedKeys, currentProjectId } } = PDNReducer;
  const { tabVisible, monitorScreenInfo: { tabSelectKeys } } = state.MonitorInfoReducer;
  return {
    pcbLayout,
    selectedKeys: treeSelectedKeys,
    tabVisible,
    tabSelectKeys,
    currentProjectId
  };
}

export default connect(mapState, null)(PCBLayout);