const TYPE = "BMA";

export const RUN_BMA_CHANNEL = `${TYPE}/start_run_current_channel`;
export const GET_CHANNEL_WORKFLOW = `${TYPE}/get_channel_workflow`;
export const UPDATE_SIMULATION_REDUCER = `${TYPE}/update_simulation_reducer`;
export const CLEAR_SIMULATION_INFO = `${TYPE}/clear_simulation_info`;
export const UPDATE_CURRENT_SIMULATION_INFO = `${TYPE}/update_current_simulation_info`;
export const UPDATE_CURRENT_SIMULATION_PART_INFO = `${TYPE}/update_current_simulation_part_info`;
export const GET_VERIFICATION_WORKFLOW = `${TYPE}/get_verification_workflow`;
export const CANCEL_WORKFLOW = `${TYPE}/cancel_workfolw`;

export const GET_WORKFLOW_MONITOR = `${TYPE}/get_workflow_monitor`;
export const GET_VERIFICATION_LOG = `${TYPE}/get_verification_log`;