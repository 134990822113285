import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import ComponentTable from '../../../components/PreLayout/ComponentTable';
import {
  getPreLayoutSignalColumns,
} from '@/services/Andes_v2/preLayout';
import SignalTable from './SignalTable';
import {
  updatePreLayoutInfo,
  changePreLayoutConfig,
  updateSignalsColumnsStatus,
  savePreLayoutModel,
  firstOpenPreLayoutSettingUpdate
} from '../store/preLayout/action';
import TopBar from "./TopBar";
import Setting from "./setting";
import PreLayoutHelpPanel from '../../../components/PreLayout/PreLayoutHelpPanel';
import ExtractionPanel from './extractionPanel';
import PcbModelContent from "./pcbModelContent";
import '../../../publicCss/preLayout.css';
import './index.css';
import { SCHEMATIC } from "../../../services/Andes_v2/preLayout";
import { PACKAGE as DESIGN_PACKAGE } from "../../../constants/designType";
import { IC, CONNECTOR, DIE, BGA } from "../../../constants/componentType";

class PreLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      signalColumns: [],
      signalColumnsStatus: false,
      settingVisible: false,
      helpVisible: false,
      modelVisible: false,
      updatePcbModelStatus: false
    }
  }

  componentDidMount = () => {
    const { info, designID } = this.props;
    let firstOpenSettingStatus, maxSectionLength, type;
    if (info[designID]) {
      firstOpenSettingStatus = info[designID].firstOpenSettingStatus;
      maxSectionLength = info[designID].preLayoutInfo.maxSectionLength;
      type = info[designID].preLayoutInfo.type;
      this.setState({
        signalColumns: getPreLayoutSignalColumns(maxSectionLength, type)
      })
    }
    if (firstOpenSettingStatus) {
      this.setState({
        settingVisible: true
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { info, designID } = this.props;
    let prevSignalColumnsUpdateStatus, maxSectionLength, signalColumnsUpdateStatus, firstOpenSettingStatus, type;

    if (prevProps.info[designID]) {
      prevSignalColumnsUpdateStatus = prevProps.info[designID].signalColumnsUpdateStatus;
      // prevFirstOpenSettingStatus = prevProps.info[designID].firstOpenSettingStatus;
    }
    if (info[designID]) {
      maxSectionLength = info[designID].preLayoutInfo.maxSectionLength;
      signalColumnsUpdateStatus = info[designID].signalColumnsUpdateStatus;
      firstOpenSettingStatus = info[designID].firstOpenSettingStatus;
      type = info[designID].preLayoutInfo.type;
    }

    if (!prevProps.info[designID] && info[designID]) {
      this.setState({
        signalColumns: getPreLayoutSignalColumns(maxSectionLength, type)
      })
    }

    if ((signalColumnsUpdateStatus && signalColumnsUpdateStatus !== prevSignalColumnsUpdateStatus)) {
      this._updateSignalColumns(maxSectionLength);
      this.props._updateSignalsColumnsStatus(false, designID);
      this.updatePcbModelInfoStatus()
    }

    if (designID !== prevProps.designID && firstOpenSettingStatus) {
      this.setState({
        settingVisible: true
      })
    }
  }

  _updateSignalColumns = (maxSectionLength, _info) => {
    const { info, designID } = this.props;
    if (!info[designID] || !info[designID].preLayoutInfo) return null;
    const { type } = info[designID].preLayoutInfo;
    this.setState({
      signalColumns: getPreLayoutSignalColumns(maxSectionLength, type),
      signalColumnsStatus: true
    }, () => {
      if (_info) {
        this.props._updatePreLayoutInfo({ ..._info }, this.props.designID);
      }
    });
  }

  updatePcbModelInfoStatus = () => {
    this.setState({
      updatePcbModelStatus: !this.state.updatePcbModelStatus
    })
  }

  _updateSignalColumnsStatus = (signalColumnsStatus) => {
    this.setState({
      signalColumnsStatus
    });
  }

  changePanelStatus = (type, visible) => {
    this.setState({
      [`${type}Visible`]: visible
    })
    const { info, designID } = this.props;
    let firstOpenSettingStatus;
    if (info[designID]) {
      firstOpenSettingStatus = info[designID].firstOpenSettingStatus;
    }
    type === "setting" && firstOpenSettingStatus && this.props._firstOpenPreLayoutSettingUpdate(false, designID);
  }

  getModelDisplay = (info, designID, modelVisible) => {
    let modelDisPlay = true;
    if (info[designID]) {
      modelDisPlay = info[designID].preLayoutInfo.prelayout === "model" ? false : true;
      if (modelVisible && !modelDisPlay) {
        this.setState({
          modelVisible: false
        })
      }
    }
    return modelDisPlay
  }

  render() {
    const { signalColumns, signalColumnsStatus, settingVisible, helpVisible, modelVisible, updatePcbModelStatus } = this.state;
    const { info, designID, libraryStatus } = this.props;
    if (!info[designID] || !info[designID].preLayoutInfo) return null;
    const { components = [], signal_groups = [], prelayout, type, width, unit, selectedGroups = [], diffPairsNumber,
      maxSectionLength, netPrefix, models = [], preLayoutModel, lanesNumber, designType } = info[designID].preLayoutInfo;
    const modelDisPlay = this.getModelDisplay(info, designID, modelVisible);
    const typeList = designType === DESIGN_PACKAGE ? [DIE, BGA] : [IC, CONNECTOR];
    return (<Fragment>
      <TopBar
        designID={designID}
        openPanel={this.changePanelStatus}
        settingVisible={settingVisible}
        helpVisible={helpVisible}
        modelVisible={modelVisible}
        modelDisPlay={modelDisPlay}
      />
      <div className='andes-v2-content-setting andes-v2-prelayout-setting'>
        <div className='andes-v2-content-setting-box'>
          <ComponentTable
            components={components}
            typeList={typeList}
            updateComponent={(info) => this.props._updatePreLayoutInfo(info, designID)}
          />
          {prelayout === SCHEMATIC ?
            <SignalTable
              designID={designID}
              type={type}
              unit={unit}
              signal_groups={signal_groups}
              components={components}
              maxSectionLength={maxSectionLength}
              selectedGroups={selectedGroups}
              signalColumns={signalColumns}
              signalColumnsStatus={signalColumnsStatus}
              libraryStatus={libraryStatus}
              updateSignalColumns={this._updateSignalColumns}
              updateSignalColumnsStatus={this._updateSignalColumnsStatus}
            />
            :
            <PcbModelContent
              type={type}
              designID={designID}
              components={components}
              signal_groups={signal_groups}
              preLayoutModel={preLayoutModel}
              libraryStatus={libraryStatus}
              updatePcbModelStatus={updatePcbModelStatus}
              designType={designType}
            />
          }
          {
            settingVisible &&
            <Setting
              type={type}
              width={width}
              prelayout={prelayout}
              unit={unit}
              selectedGroups={selectedGroups}
              diffPairsNumber={diffPairsNumber}
              netPrefix={netPrefix}
              lanesNumber={lanesNumber}
              closePanel={this.changePanelStatus}
              changePreLayoutConfig={(info) => this.props._changePreLayoutConfig(info, designID)}
            />
          }
          {modelVisible ?
            <ExtractionPanel
              models={models}
              closePanel={this.changePanelStatus}
              saveModel={(models) => this.props._savePreLayoutModel(models, designID)}
            />
            : null}
          {helpVisible ?
            <PreLayoutHelpPanel
              product={"AndesV2"}
              closePanel={this.changePanelStatus}
            />
            : null}
          <div id='pre-layout-model-dialog'></div>
        </div>
      </div>
    </Fragment>)
  }
}

const mapState = (state) => {
  const { project: { openProjectId },
    preLayout: { info },
    library: { libraryStatus }
  } = state.AndesV2Reducer;

  return {
    openProjectId,
    info,
    libraryStatus,
  }
}

const mapDispatch = (dispatch) => ({
  _updatePreLayoutInfo(info, designID) {
    dispatch(updatePreLayoutInfo({ info, designID }))
  },
  _changePreLayoutConfig(info, designID) {
    dispatch(changePreLayoutConfig(info, designID))
  },
  _updateSignalsColumnsStatus(status, designID) {
    dispatch(updateSignalsColumnsStatus(status, designID))
  },
  _savePreLayoutModel(models, designID) {
    dispatch(savePreLayoutModel(models, designID))
  },
  _firstOpenPreLayoutSettingUpdate(status, designID) {
    dispatch(firstOpenPreLayoutSettingUpdate(status, designID))
  }
})

export default connect(mapState, mapDispatch)(PreLayout);