import { ALL_PINS, GAP, NEARBY_PINS, PIN_PER_REF_NET, getPortGenerateSetupList } from "../../ExtractionPortsHelper";
import { PortsGenerationSetup } from "../../ExtractionPortsHelper/portsSetupHelper";
import { SERDES_TYPES } from "../../PCBHelper";
import { judgeUpdateGapPortSetup } from "./channelContentHelper";

function judgePortGenerateSetupUpdate(channelInfo) {
  if (!channelInfo || !channelInfo.content) {
    return false;
  }

  if (!channelInfo.content.ports_generate_setup_list) {
    return true;
  }
  return false;
}

function updatePortGenerateSetupByComps(channelInfo) {
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let _channelInfo = { ...channelInfo };

  const { components, ports_generation_setup, ports_generate_setup_list } = _channelInfo.content;
  if (ports_generate_setup_list && !ports_generation_setup) {
    return _channelInfo;
  }

  const ICComponents = components.filter(item => SERDES_TYPES.includes(item.type));
  const setupList = ICComponents.map(item => item.name).map(item => {
    return {
      component: item,
      setup: ports_generation_setup ? JSON.parse(JSON.stringify(ports_generation_setup)) : new PortsGenerationSetup({})
    }
  });

  _channelInfo.content.ports_generate_setup_list = [...setupList];
  delete _channelInfo.content.ports_generation_setup;

  return _channelInfo;
}

//update ports generate setup list
function updatePortSetupByUpdateComp(_components, ports_generate_setup_list) {
  //find update components by serdes type
  const compNames = _components.filter(item => SERDES_TYPES.includes(item.type)).map(item => item.name);
  //removed deleted component
  ports_generate_setup_list = ports_generate_setup_list.filter(it => compNames.includes(it.component));
  //get port generate setup comp list
  const portCompNames = ports_generate_setup_list.map(item => item.component);
  //find new components
  const filterCompNames = compNames.filter(item => !portCompNames.includes(item));
  //get default setup
  const portSetup = ports_generate_setup_list.length ? ports_generate_setup_list[0].setup : new PortsGenerationSetup({})

  //add new component port setup to port setup list
  for (let comp of filterCompNames) {
    ports_generate_setup_list.push({
      component: comp,
      setup: JSON.parse(JSON.stringify(portSetup))
    })
  }

  return ports_generate_setup_list;
}

/**
 * After pcb replace,re generation extraction ports.
 * 1. change ports_generate_setup_list,
 * 2. judge gap ports setup,update ports setup
 *  */
function updatePortGenerateSetupForReplace(channelInfo) {
  let ports_generate_setup_list = channelInfo.content.ports_generate_setup_list,
    ports_generation_setup = channelInfo.content.ports_generation_setup,
    components = channelInfo.content.components;

  //change port generate setup format - Object to array by components
  if (!ports_generate_setup_list) {
    ports_generate_setup_list = getPortGenerateSetupList({ components, setup: ports_generation_setup });
  }

  //update gap port setup by extraction type (SIWave / HFSS)
  if (judgeUpdateGapPortSetup(channelInfo)) {
    ports_generate_setup_list.forEach(item => {
      if (item.setup.portType === GAP && [NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)) {
        item.setup = new PortsGenerationSetup({
          portType: GAP,
          referenceType: ALL_PINS,
        });
      }
    });
  }

  return ports_generate_setup_list;
}

export {
  judgePortGenerateSetupUpdate,
  updatePortGenerateSetupByComps,
  updatePortSetupByUpdateComp,
  updatePortGenerateSetupForReplace
}