import LayoutData from "../data/LayoutData";
import CePolygon from "../geometry/CePolygon";
import { findNetListByComp } from "../helper/componentsHelper/componentData";
import { getNum } from "../helper/getSignalType";
import { strFormatChange } from "../helper/stringHelper";
import { RLC_TYPES, UNUSED } from "./components";
import { NET_TYPES, GENERIC } from "./constants";
import CeLarc from "../geometry/CeLarc";
import CeLine from "../geometry/CeLine";
import CePolygonWithHole from '../geometry/CePolygonWithHole';
import { getComponentByName } from ".";
import { getNominalNumber } from '@/services/Cascade/helper/match';
import { PACKAGE } from "../../constants/designType";
import { calcDistanceOfTwoPoints } from "../helper/calcPointsDistance";
import CeCircle from "../geometry/CeCircle";
import { getCenterPoint } from "../helper/cutDesign/hybrid/hybridExtraction";
import { isPointInPolygon } from "../helper/canvasHelper/canvasMath";

function getCMCPinConnections({ pinList, designId, compName }) {
  if (!pinList) {
    const comp = getComponentByName(designId, compName);
    pinList = comp && comp.mPart && comp.mPart.mPinList ? comp.mPart.mPinList : [];
  }

  let pinConnections = [];
  const pinIN = pinList.filter(item => item.mName.match(/(IN)|(INPUT)/ig));
  const pinOUT = pinList.filter(item => item.mName.match(/(OUT)|(OUTPUT)/ig));

  if (pinIN.length === 2 && pinOUT.length === 2) {
    for (let item of pinIN) {
      const number = getNum(item.mName);
      const findPinMap = pinOUT.find(item => getNum(item.mName) === number);
      pinConnections.push({ pin: item.mNumber, pinMap: findPinMap ? findPinMap.mNumber : "" })
    }

    const filterPinOut = pinOUT.filter(item => !pinConnections.find(it => it.pinMap === item.mNumber));

    if (filterPinOut.length === 1) {
      pinConnections.forEach(item => {
        if (!item.pinMap) {
          item.pinMap = filterPinOut[0].mNumber;
        }
      })
    }

    if (filterPinOut.length === 2) {
      //[1,2,4,3]
      //[1,2]
      //[4,3]
      //1 <-> 4
      //2 <-> 3
      pinConnections.forEach((item, index) => {
        const pinMap = getConnectedPinNumber({
          pinNum: item.pin,
          pinLength: pinList.length
        });
        item.pinMap = pinMap ? pinMap.toString() : filterPinOut[index];
      })
    }
  } else {
    const _pinList = pinList.map(item => item.mNumber).sort((a, b) => Number(a) - Number(b));

    for (let i = 0; i < _pinList.length / 2; i++) {
      const _pinMapNum = getConnectedPinNumber({
        pinNum: _pinList[i],
        pinLength: pinList.length
      });
      let _pinMap = _pinMapNum ? _pinMapNum.toString() : "";
      if (!_pinList.includes(_pinMap)) {
        const index = _pinList.length - i - 1;
        _pinMap = _pinList[index] ? _pinList[index] : "";
      }
      pinConnections.push({ pin: _pinList[i], pinMap: _pinMap });
    }
  }
  return pinConnections;
}

function getConnectedPinNumber({ pinNum, pinLength, pinMap = [] }) {
  const findPin = pinMap.find(item => parseInt(item.pin) === parseInt(pinNum));
  if (findPin && findPin.pinMap) {
    return parseInt(findPin.pinMap)
  }
  const findPinMap = pinMap.find(item => parseInt(item.pinMap) === parseInt(pinNum));
  if (findPinMap && findPinMap.pin) {
    return parseInt(findPinMap.pin)
  }

  const _pinNum = parseInt(pinNum);

  if (isNaN(_pinNum) || _pinNum > pinLength) {
    return null;
  }
  /* 
  pinLength: 4
     1   4
     2   3
  
  pinLength: 8 
     1   8
     2   7
     3   6
     4   5
   */
  return pinLength - _pinNum + 1;
}

function getPinMapByPinName(inputPins) {
  let pinMap = [];
  let pins = inputPins.filter(item => item.net !== 'NONET');
  if (pins.length > 2) {
    let _pins = pins.filter(pin => pin.mName && (pin.mName.match(/(IN)|(INPUT)/ig) || pin.mName.match(/(OUT)|(OUTPUT)/ig)));
    if (!_pins.length) {
      _pins = pins;
    }
    let pinNumbers = _pins.map(pin => Number(pin.pin) || pin.pin);
    pinNumbers.sort((a, b) => a - b);

    for (let i = 0; i < Math.floor(pinNumbers.length / 2); i++) {
      if (pinNumbers[i]) {
        pinMap.push(pinNumbers[i])
      }
      if (pinNumbers[pinNumbers.length - 1 - i]) {
        pinMap.push(pinNumbers[pinNumbers.length - 1 - i])
      }
    };
    if (_pins.length !== pinMap.length) {
      return pinMap = [..._pins.map(item => item.pin)];
    }
  } else {
    pinMap = [...pins.map(item => item.pin)]
  }

  pinMap.forEach(item => { item.toString && item.toString() });
  return pinMap;
}

function findPowerNetsByRLC(comps, pcbNetsList, designId, powerNetsTable) {
  let _comps = JSON.parse(JSON.stringify(comps));
  const tablePowers = (powerNetsTable || []).map(item => item.net)
  _comps.forEach(item => {
    const compName = item.name;
    const filterNets = item.pins.map(item => item.net);
    const nets = findNetListByComp({ compName, netsList: pcbNetsList, filterNets, type: "net" }).filter(net => tablePowers.includes(net.mName) || isPowerGND(net, null, designId));
    nets.forEach(info => {
      const find = info.mPinList.find(item => item.mCompName === compName);
      const pin = find.mPinNum;
      const net = info.mName;
      item.pins.push({ signal: "", pin, net })
    });
  });
  return { comps: _comps };
}

function isPowerGND(net, symbol, designId, layoutDB) {
  if (!net) {
    return false;
  }
  // net type
  if (net && net.mType === "Power") {
    return true;
  }

  let viaAreaList = [];
  let _symbol = symbol;
  if (!symbol && designId) {
    const layout = LayoutData.getLayout(designId) || {};
    _symbol = layout.mSymbolMgr;
  }

  if (net && net.mGeomList.some(d => d.mRefGeom.mGeometry instanceof CePolygonWithHole)) {
    return true;
  }

  let netViaList = net.mViaList ? net.mViaList.map((item) => {
    let viaObj = {};
    const currentVia = viaAreaList.find(it => it.viaId === item.mViaID);

    if (!currentVia) {
      const _viaObj = _symbol ? getViaArea(item.mViaID, _symbol) : {};
      _viaObj.center = { mX: item.mLocation.mPosition.mX, mY: item.mLocation.mPosition.mY };
      viaAreaList.push(_viaObj);
      viaObj = _viaObj;
    } else {
      viaObj = currentVia;
    }
    return viaObj;
  }) : [];
  let polygonGeomList = [];
  // filter signal trace
  const filterMGeomList = net.mGeomList.filter(item => {

    if (item.mRefGeom.mGeometry instanceof CeLine
      || item.mRefGeom.mGeometry instanceof CeLarc
      || (!item.mRefGeom.mGeometry && item.mRefGeom.mSymbolID > -1)
      || (item.mRefGeom.mGeometry instanceof CePolygon
        && !comparePolygonAndViaArea(item.mRefGeom.mGeometry, netViaList))) {
      return item;
    } else {
      polygonGeomList.push(item);
      return false;
    }
  })

  if (net.mGeomList.length > filterMGeomList.length) {
    //Determine whether the polygon is coincident with the pin connected to the net
    if (filterPolygonByConnectedPin({ polygonGeomList, netPinList: net.mPinList, layoutDB, designId })) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

//Determine whether the polygon is coincident with the pin connected to the net
function filterPolygonByConnectedPin({
  polygonGeomList,
  netPinList,
  layoutDB,
  designId
}) {
  let db = null;
  if (layoutDB) {
    db = layoutDB;
  } else if (designId) {
    db = LayoutData.getLayout(designId);
  }
  if (!db) {
    return true;
  }
  //find pin location
  let _netPinList = [];
  for (let item of netPinList) {
    const component = db.getComponent(item.mCompName);
    if (!component || !component.mPinsLocationList) {
      continue;
    }
    const findPin = component.mPinsLocationList.find(it => it.pinNumber === item.mPinNum) || {};
    const pinLocation = findPin.mLocation || {};
    //Calculate pin midpoint
    let pinCenterPoint = null;
    if (pinLocation.shapeType === "polygon") {
      let pinPathData = [];
      for (let i = 0; i < pinLocation.Xs.length; i++) {
        pinPathData.push([pinLocation.Xs[i], pinLocation.Ys[i]]);
      }
      pinCenterPoint = getCenterPoint(pinPathData);
    }
    _netPinList.push({
      ...item,
      pinLocation,
      centerPoint: pinCenterPoint
    })
  }

  let filterPinPolygonList = [];
  for (let netGeom of polygonGeomList) {
    let geomObj = netGeom.mRefGeom.mGeometry.Clone();
    let pinPolygonGeomList = [];
    for (let pinItem of _netPinList) {
      const pinLocation = pinItem.pinLocation;
      if (!pinLocation || (pinLocation.shapeType === "polygon" && !pinItem.centerPoint)) {
        continue;
      }
      if (pinLocation.shapeType === "circle" && geomObj instanceof CeCircle) {
        //pinLocation: { xc, yc, r },  geomObj: { mCenter:{ mX,mY }, mDiameter }
        if (parseFloat(pinLocation.xc) === parseFloat(geomObj.mCenter.mX)
          && parseFloat(pinLocation.yc) === parseFloat(geomObj.mCenter.mY)) {
          pinPolygonGeomList.push(geomObj);
        } else {
          const polygonRadiusSquare = (geomObj.mDiameter / 2) * (geomObj.mDiameter / 2);
          const pinRadiusSquare = pinLocation.r * pinLocation.r;
          //Calculate the distance from the center of the pin to the center of the net polygon
          const centerDistanceSquare = calcDistanceOfTwoPoints(geomObj.mCenter, { mX: pinLocation.xc, mY: pinLocation.yc })
          if (centerDistanceSquare <= (polygonRadiusSquare + pinRadiusSquare)) {
            pinPolygonGeomList.push(geomObj);
          }
        }
      } else if (pinLocation.shapeType === "polygon" && geomObj instanceof CePolygon) {
        //pinLocation: { Xs, Ys, centerPoint },  geomObj: { mCCW, mGeomType, mIsSolid, mVertices }
        //mVertices:[ { mGeomType:"Pnt",mX,mY },{mCCW, mCenter :{ mGeomType:"Pnt",mX,mY}, mGeomType:"Parc",mX,mY }]
        let netPolygonPathData = [];
        for (let item of geomObj.mVertices) {
          netPolygonPathData.push([item.mX, item.mY]);
        }
        if (isPointInPolygon({ x: pinItem.centerPoint[0], y: pinItem.centerPoint[1] }, netPolygonPathData)) {
          pinPolygonGeomList.push(geomObj);
        };
      }
      continue;
    }
    if (pinPolygonGeomList.length === 1) {
      filterPinPolygonList.push(pinPolygonGeomList[0])
    }
  }

  if (filterPinPolygonList.length < polygonGeomList.length) {
    return true;
  } else {
    return false;
  }
}

function getPowerNets({ components, powerNets, netsList, signalNets, findGND, designId, haveTypeValue = false, powerNetsTable = [], doNotStuff = [] }) {
  //signal nets is empty
  if (signalNets && signalNets.length === 0) {
    return { components, powerNets: [] };
  }
  const _rlcFilter = components.filter(item => RLC_TYPES.includes(item.type) && !doNotStuff.includes(item.name));
  const customPowers = powerNets.filter(item => !!item.custom).map(item => {
    return {
      net: item.name
    }
  })
  const findPowerByRLC = findPowerNetsByRLC(_rlcFilter, netsList, designId, [...powerNetsTable, ...customPowers]);
  const { comps } = findPowerByRLC;
  comps.forEach(comp => {
    const index = components.findIndex(item => item.name === comp.name);
    if (index > -1) {
      components[index] = { ...comp };
    }
  });
  let newPowerNets = [];
  for (let comp of components) {
    if (!RLC_TYPES.includes(comp.type)) {
      if (comp.type !== UNUSED) {
        comp.pins = comp.pins.filter(item => !!item.signal);
      }
      continue;
    };
    for (let pin of comp.pins) {
      // power,ground nets
      if (!pin.signal) {
        newPowerNets.push(pin.net);
      }
    }
  };
  // updatePowerNets = new power nets(by connected rlc component pins) + custom nets
  let updatePowerNets = powerNets.filter(item => {
    if (item.custom && item.type) {
      item.type = haveTypeValue ? "Reference" : item.type;
    }
    return newPowerNets.includes(item.name) || item.custom ? item : null;
  });
  newPowerNets.forEach(net => {
    const index = updatePowerNets.findIndex(item => item.name === net)
    if (index < 0) {
      let netInfo = { name: net, value: "" }
      if (haveTypeValue) {
        let value = getNominalNumber(net)
        netInfo = { name: net, value: value ? value : '', type: 'Pull Up/Down' }
      }
      updatePowerNets.push(netInfo)
    } else if (haveTypeValue) {
      updatePowerNets[index].type = 'Pull Up/Down';
    }
  });

  //find GND
  if (findGND) {
    const autoGND = netsList.filter(item => item.mName.match(/gnd/ig));
    if (autoGND.length > 0) {
      const findGND = autoGND.find(item => item.mName === 'GND')
      if (findGND) {
        const find = updatePowerNets.find(item => item.name === 'GND');
        if (!find && !haveTypeValue) {
          updatePowerNets.push({ name: 'GND', value: "0" });
        } else if (!find && haveTypeValue) {
          updatePowerNets.push({ name: 'GND', value: "0", type: 'Reference' });
        } else if (find && haveTypeValue) {
          updatePowerNets.forEach(item => { if (item.name === 'GND') { item.value = item.value ? item.value : "0" } })
        }
      } else {
        let via = 0, gndNet = null;
        for (let net of autoGND) {
          if (net.mViaList.length > via) {
            gndNet = net.mName;
            via = net.mViaList.length;
          }
        };
        const find = updatePowerNets.find(item => item.name === gndNet);
        if (!find && !haveTypeValue) {
          updatePowerNets.push({ name: gndNet, value: "0" });
        } else if (!find && haveTypeValue) {
          updatePowerNets.push({ name: gndNet, value: "0", type: 'Reference' });
        } else if (find && haveTypeValue) {
          updatePowerNets.forEach(item => { if (item.name === gndNet) { item.value = item.value ? item.value : "0" } })
        }
      }
    }
  }

  //Filter power nets (not includes signal nets)
  updatePowerNets = updatePowerNets.filter(item => !signalNets.includes(item.name));
  updatePowerNets.forEach(item => {
    const find = powerNets.find(net => net.name === item.name);
    if (find) {
      item.value = find.value;
    } else {
      const findNet = (powerNetsTable || []).find(net => net.net === item.name);
      item.value = findNet && findNet.voltage ? findNet.voltage : item.value;
    }
  });
  return { components, powerNets: updatePowerNets };
}

/** 
 * filter nets by nets name
 * removed power /gnd nets 
 * removed nets by net type
 */
function filterNetsByNetName(netsList, type, netNamePrefix, symbol, designType, designId) {
  const powerNets = filterPowerNets(netsList, symbol, designId).map(item => item.mName);

  if (type === GENERIC) {
    return netsList.filter(net => !powerNets.includes(net.mName) && !net.mName.match(/^NONE/i));
  } else {
    //filter other type nets and power gnd nets
    const _type = getSerdesType(type);
    const prefixRegList = netNamePrefix.map(item => new RegExp(`${strFormatChange(item)}`, "ig"));
    const typeFilterReg = getFilterTypeReg(type, netNamePrefix, designType);

    return netsList.filter(net =>
      !powerNets.includes(net.mName) &&
      !net.mName.match(/^NONE/i) &&
      (
        net.mName.match(_type)
        || prefixRegList.filter(it => net.mName.match(it)).length
        || (designType === PACKAGE || !net.mName.match(typeFilterReg))
      )
    );
  }
}

/* Filter types by type */
function getFilterTypeReg(type, netNamePrefix) {
  const _type = getSerdesType(type);
  const reg = new RegExp(`${_type}`, "ig");
  const prefixRegList = netNamePrefix.map(item => new RegExp(`${strFormatChange(item)}`, "ig"));
  let filterTypesStr = "";
  for (let item of NET_TYPES) {

    if (item.match(reg) || prefixRegList.filter(it => item.match(it)).length) {
      continue;
    }
    filterTypesStr = filterTypesStr ? `${filterTypesStr}|(${item})` : `(${item})`;
  }
  return new RegExp(`${filterTypesStr}`, "ig");
}

function getSerdesType(type) {
  const _type = type.toUpperCase();
  switch (_type) {
    case "PCIE":
      return "PCI";
    case "ETHERNET":
      return "ETHER";
    default: break;
  }

  return type;
}

function autoFilterSignalNets(netList, removePower, designId) {
  let nets = [];
  for (let net of netList) {
    if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
      continue;
    }

    //Filter power gnd nets 
    if (removePower && isPowerGND(net, null, designId)) {
      continue;
    }
    // Remove v, +v, N+V NONE, GND, via length check
    // if (net.mName.match(/^(v|\+v|NONET|GND)/i)) {
    //   continue;
    // };
    // if (net.mGeomList.length > 0) {
    //   const geomList = net.mGeomList.map(geom => geom.mRefGeom.mGeometry).filter(item => !!item);
    //   const typeList = geomList.map(item => item instanceof CeLine);
    //   if (typeList.includes(true)) {
    //     continue;
    //   }
    // }
    // if (net.mViaList.length > 10) {
    //   continue;
    // }
    nets.push(net.mName)
  };
  return nets;
};

function getConnectedNetByCompPin(netList, compName, pinNumber = "") {
  // mName, mPinList
  /* mPinList: mCompName: "Z2703"
  mLayerName: "COMP_BOTTOM"
  mPinNum: "3" */
  //return netList.find(netInfo => !!netInfo.mPinList.find(item => compName && item.mCompName === compName && pinNumber && item.mPinNum === pinNumber.toString()))
  for (let netInfo of netList) {

    if (netInfo.mPinList.find(item => compName && item.mCompName === compName && pinNumber && item.mPinNum === pinNumber.toString())) {
      return netInfo;
    }
  }
  return null;
}

function getLayoutAllComponents({ LayoutData, isGetNet = false }) {
  let compList = [], layoutCompList = {};
  const layerMgr = LayoutData ? LayoutData.GetLayerManager() : null;
  const nets = isGetNet && LayoutData && LayoutData.mNetManager && LayoutData.mNetManager.mNetList ? LayoutData.mNetManager.mNetList.mVals : [];

  if (layerMgr && layerMgr.mMetalLayers && layerMgr.mMetalLayers.length > 0) {
    layerMgr.mMetalLayers.forEach(item => {
      if (item.mComponentLayer !== null) {
        compList.push(item.mComponentLayer);
      }
    });
  }
  compList.forEach(layer => {
    let comps = layer.mComponents;
    for (let component of comps || []) {
      if (isGetNet) {
        layoutCompList[component.mName] = {};
        const pinList = component.mPart.mPinList || [];
        pinList.forEach(pin =>
          layoutCompList[component.mName][pin.mNumber] = getConnectedNetByCompPin(nets, component.mName, pin.mNumber)
        );
      } else {
        layoutCompList[component.mName] = { ...component, layer: layer.mName };

      }
    }
  });

  return layoutCompList;
};

/* get power nets */
function filterPowerNets(_nets, symbol, designId) {
  let _symbol = symbol;
  if (!symbol && designId) {
    const layout = LayoutData.getLayout(designId) || {};
    _symbol = layout.mSymbolMgr;
  }


  const filterNets = _nets.filter(net => {
    if (isPowerGND(net, _symbol, designId)) {
      return true;
    }
    return false;
  })
  return filterNets;
}

function getViaArea(viaId, symbol) {
  // get via area
  let viaArea = 0, viaDiameter = 0;
  if (viaId) {
    const { mViaList, mShapeList } = symbol || {};
    let shapeId = "";
    let currentObject = mViaList.mObjects.find(item => item.mID === viaId);
    if (currentObject.mLayerPads && currentObject.mLayerPads.mVals
      && currentObject.mLayerPads.mVals.length) {
      shapeId = currentObject.mLayerPads.mVals[0].mShapeId
    } else {
      shapeId = currentObject.mBarrel.mShapeId
    }
    const currentShape = mShapeList.mObjects.find(item => item.mID === shapeId);
    if (currentShape && currentShape.mGeometry) {
      viaArea = Math.pow(currentShape.mGeometry.mDiameter / 2, 2) * Math.PI;
      viaDiameter = currentShape.mGeometry.mDiameter;
    }
    return { viaId, viaArea, viaDiameter }
  }
}

function comparePolygonAndViaArea(geometry, netViaList) {
  if (!netViaList.length) {
    return true;
  }

  netViaList = netViaList.sort((a, b) => b.viaArea - a.viaArea);
  //get max via area 
  const viaArea = netViaList[0].viaArea;
  if (geometry.mVertices) {
    const position = geometry.mVertices.map(item => ({ mX: item.mX, mY: item.mY }))

    for (let i = 0; i < position.length; i++) {
      //categorize tear-drop polygon
      //Filter point inside the circle
      let point = position[i];
      const filterViaList = JSON.parse(JSON.stringify(netViaList)).filter(it => calcDistanceOfTwoPoints(point, it.center) <= ((it.viaDiameter / 2) * (it.viaDiameter / 2)));

      if (filterViaList.length) {
        //tear-drop polygon
        return false;
      }

      if (geometry.mVertices[i].mGeomType === "Parc") {
        //Calculate the center of the arc
        const radiusSquare = calcDistanceOfTwoPoints({
          mX: geometry.mVertices[i].mX, mY: geometry.mVertices[i].mY
        },
          geometry.mVertices[i].mCenter);
        //Calculate whether the circle formed by the arc and the circle of the via intersect
        const _filterViaList = JSON.parse(JSON.stringify(netViaList)).filter(it => calcDistanceOfTwoPoints(it.center, geometry.mVertices[i].mCenter) <= (radiusSquare + (it.viaDiameter / 2) * (it.viaDiameter / 2)));
        if (_filterViaList.length) {
          //tear-drop polygon
          return false;
        }
      }
    }

    let polygonArea = 0;
    for (let i = 0; i < position.length; i++) {
      let point = position[i];
      let nextPoint = position[i + 1] || position[0];
      if (i >= 2) {
        let _polygonArea = polygonArea + (point.mX * position[0].mY - point.mY * position[0].mX);
        const _polygonArea_ = Math.abs(_polygonArea) * 0.5;

        if (viaArea * 2 < _polygonArea_) {
          return true; // is power net
        }
      }
      // area = 0.5 * sum((point.x * nextPoint.y - point.y * nextPoint.x)...)
      polygonArea = polygonArea + (point.mX * nextPoint.mY - point.mY * nextPoint.mX)
    }

    return false;
  }
}

function getLayoutAllNets(designId, type = "net") {
  const layoutData = LayoutData.getLayout(designId);
  const layoutNetsList = layoutData && layoutData.mNetManager && layoutData.mNetManager.mNetList
    ? layoutData.mNetManager.mNetList.mVals : [];
  return type === "netName" ? layoutNetsList.map(item => item.mName) : layoutNetsList;
}

function getPowerNetsByKewWords({ netKeywords, isRegular, pcbNets }) {
  let regList = [];
  if (isRegular) {
    for (let item of netKeywords || []) {
      const regItem = stringToRegExp(item)
      if (regItem === false) { continue }
      regList.push(regItem);
    }
  } else {
    const str = Array.isArray(netKeywords) ? netKeywords.join('|') : "";
    if (str) {
      regList.push(new RegExp(`${str}`, "i"));
    }
  }
  if (!regList || !regList.length) {
    return []
  }
  return pcbNets.filter(net => regList.find(it => net.match(it)));
}

function stringToRegExp(str) {
  try {
    const reg = new RegExp(str, 'i');
    if (reg instanceof RegExp) {
      return reg;
    };
    return false;
  } catch (error) {
    const _reg = new RegExp('(' + str + ')', 'i');
    if (_reg instanceof RegExp) {
      return _reg;
    };
    return false;
  }
}


export {
  findPowerNetsByRLC,
  getPowerNets,
  filterNetsByNetName,
  getSerdesType,
  isPowerGND,
  autoFilterSignalNets,
  getConnectedPinNumber,
  getConnectedNetByCompPin,
  getLayoutAllComponents,
  getCMCPinConnections,
  filterPowerNets,
  getPinMapByPinName,
  getLayoutAllNets,
  getPowerNetsByKewWords
}