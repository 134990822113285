import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { Button, Input, Switch, TreeSelect } from 'antd';
import { downloadTransFromSpiceFile, getCPMSpiceByTransForm } from '../../../services/LayoutCanvas/SpiceNodesCanvas';
import { numberCheck } from '../../../services/helper/dataProcess';
import './index.css';

const { SHOW_PARENT } = TreeSelect;
function getTreeData(pinList) {
  return [{
    title: 'All',
    value: 'all',
    key: 'all',
    children: pinList.map(item => ({ title: `${item.pinNumber}::${item.net}`, value: item.pinNumber, key: item.pinNumber }))
  }]
}
class DownloadSpicePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flipX: false,
      rotation: 0,
      shiftX: 0,
      shiftY: 0,
      scale: 1,
      rotationInput: 0,
      shiftXInput: 0,
      shiftYInput: 0,
      scaleInput: 1,
      pinSelectList: ["all"]
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps) => {
  }

  downloadFile = () => {
    const { flipX, scale, shiftX, shiftY, rotation, pinSelectList } = this.state;
    const { powerPinList, component, compPinsNets, PowerNets, ReferenceNets, SignalNets } = this.props;
    const filterPins = powerPinList.filter(item => pinSelectList.includes(item.pinNumber) || pinSelectList.includes("all"));
    if (!filterPins.length) {
      return;
    }
    const points = getCPMSpiceByTransForm({
      diePoints: filterPins.map(item => { return [item.mLocation.xc, item.mLocation.yc] }),
      pinList: filterPins,
      diePinsNets: compPinsNets[component] || {},
      PowerNets,
      ReferenceNets,
      setting: {
        flipX, scale: Number(scale), shiftX: Number(shiftX), shiftY: Number(shiftY), rotation: Number(rotation)
      },
      SignalNets
    })
    downloadTransFromSpiceFile(points)
  }

  changeFlip = (checked) => {
    this.setState({
      flipX: checked
    })
  }

  changeValue = (e, type) => {
    this.setState({
      [`${type}Input`]: e.target.value
    })
  }

  saveValue = (e, type) => {
    const value = e.target.value;
    if (numberCheck(value)) {
      this.setState({
        [`${type}Input`]: this.state[type]
      })
      return;
    }
    this.setState({
      [type]: e.target.value,
      [`${type}Input`]: e.target.value
    })
  }

  onTreeChange = (keys) => {
    this.setState({
      pinSelectList: keys
    })
  }

  render() {
    const { flipX, scaleInput, shiftXInput, shiftYInput, rotationInput, pinSelectList } = this.state;
    const { powerPinList } = this.props;
    return (
      <Panel
        title=<div>
          <span>Download CPM Spice file</span>
        </div>
        className='cpm-edit-tri-mapping-panel'
        visible
        onCancel={() => this.props.closePanel()}
        width={380}
        position='panel-top-left'
        draggable
        left={0}
        top={0}
        defaultLeft={50}
        minHeight={200}
        minWidth={100}
        zIndex={10}
        overflow='hidden'
      >
        <div className='cpm-connection-spice-download-content'>
          <div className='cpm-connection-spice-download-item'>
            <span className='cpm-connection-spice-download-label'>Scale</span>
            <Input
              className='aurora-input'
              value={scaleInput}
              onChange={(e) => this.changeValue(e, "scale")}
              onBlur={((e) => this.saveValue(e, "scale"))}
            />
          </div>
          <div className='cpm-connection-spice-download-item'>
            <span className='cpm-connection-spice-download-label'>Rotation</span>
            <Input
              className='aurora-input'
              value={rotationInput}
              onChange={(e) => this.changeValue(e, "rotation")}
              onBlur={((e) => this.saveValue(e, "rotation"))}
              addonAfter="°"
            />
          </div>
          <div className='cpm-connection-spice-download-item'>
            <span className='cpm-connection-spice-download-label'>FlipX</span>
            <div>
              <Switch
                size="small"
                className="aurora-switch-small"
                checked={flipX}
                onChange={this.changeFlip}
              />
            </div>
          </div>
          <div className='cpm-connection-spice-download-item'>
            <span className='cpm-connection-spice-download-label'>Shift X</span>
            <Input
              className='aurora-input'
              value={shiftXInput}
              onChange={(e) => this.changeValue(e, "shiftX")}
              onBlur={((e) => this.saveValue(e, "shiftX"))}
            />
          </div>
          <div className='cpm-connection-spice-download-item'>
            <span className='cpm-connection-spice-download-label'>shift Y</span>
            <Input
              className='aurora-input'
              value={shiftYInput}
              onChange={(e) => this.changeValue(e, "shiftY")}
              onBlur={((e) => this.saveValue(e, "shiftY"))}
            />
          </div>
          <div className='cpm-connection-spice-download-item'>
            <span className='cpm-connection-spice-download-label'>Pins Selection</span>
            <TreeSelect
              treeData={getTreeData(powerPinList)}
              value={pinSelectList}
              onChange={this.onTreeChange}
              treeCheckable={true}
              showCheckedStrategy={SHOW_PARENT}
              placeholder='Select Pins'
              className='aurora-select'
              getPopupContainer={() => document.getElementById('root')}
              popupClassName='aurora-select-dropdown cpm-connection-spice-download-select-dropdown'
            />

          </div>
        </div>
        <div className='cpm-connection-match-button-content'>
          <Button className='cpm-connection-match-button' onClick={this.downloadFile}>Download</Button>
        </div>
      </Panel>
    )
  }
}

export default DownloadSpicePanel;