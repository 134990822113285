import UploadMonitor from './UploadMonitor';
import SimulationMonitor from './SimulationMonitor';
import DebugMsg from './DebugMsg';
import ProfileTable from './profileTable';
import { monitorChild } from '@/components/MonitorFooter';
import SpecifyService from '../../../CCC/SpecifyService';
import LayoutMonitor from './LayoutMonitor';

function _monitorChild({
  menuType,
  tabVisible,
  tabSelectKeys,
  projectId,
  getCCCVerifications,
  getCurrentVerification,
  titleSelectedKeys,
  width = undefined }) {
  return monitorChild({ menuType, tabVisible, tabSelectKeys, projectId, getCCCVerifications, getCurrentVerification, titleSelectedKeys, width },
    UploadMonitor,
    SimulationMonitor,
    DebugMsg,
    ProfileTable,
    SpecifyService,
    LayoutMonitor
  )
}

export default _monitorChild;