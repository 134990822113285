import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  ADD_EXPERIMENT,
  ADD_EXPERIMENTS_BY_PASSIVE,
  CREATE_EXPS_BY_MODEL,
  UPDATE_SINGLE_EXPERIMENT,
  UPDATE_EXPERIMENT_COMP,
  REDUCE_SWEEP_TABLE_COLUMN,
  UPDATE_EXPERIMENTS_STATUS,
  CREATE_EXPS_BY_TRACE,
  GET_ALL_EXP_MEASUREMENT_CONFIG
} from "./actionTypes";
import { getDefaultName } from "../../../../services/helper/setDefaultName";
import {
  getExperimentsList,
  updateExperiment,
  getVerificationContentPromise,
  saveSweepColumns,
  DEFAULT_EXPERIMENT_COLUMNS,
  getBaseSweepingData,
  deleteExperimentByIds,
  getComponentsWithNetList,
  getCompExist,
  compairExperimentPins,
  getExperimentChannelList,
  getSweepResultsExsit,
  getSweepVirtualPassive,
  getAllExperimentsMeasurement
} from "../../../../services/Sierra";
import { EXPERIMENT_SETUP_VERSION } from "../../../../version";
import {
  updateExperimentInfo,
  updateSweepCreateStatus,
  updateSweepSchematicPreLayoutInfo,
  getAllExpMeasurementConfig,
  updateSweepMeasurementDisplayInfo
} from "./action";
import hash from 'object-hash';
import { WAITING } from "../../../../constants/workflowStatus";
import { VERIFY_RUNNING } from "../../../../constants/verificationStatus";
import { _updateInterface } from "../sierra/saga";
import { changeTabMenu, changeVerification, openTabFooter } from "../../../tabMonitor/action";
import {
  getExperimentLog,
  updateRunSweeping
} from "../simulation/action";
import { message } from "antd";
import preLayoutData from "../../../../services/Sierra/prelayout/preLayoutData";
import { PRE_LAYOUT } from "../../../../constants/designVendor";
import designConstructor from "../../../../services/helper/designConstructor";
import { saveComponentsNets } from "../project/saga";
import { getPreLayoutComponentsByPinNet } from "../../../../services/Sierra/prelayout";
import componentSetting from "../../../../services/helper/componentsHelper/compSettingHelper";
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import DesignInfo from '@/services/Sierra/pcbInfo';
import projectDesigns from "../../../../services/helper/projectDesigns";
import { strDelimited } from "../../../../services/helper/split";
import compTableHelper from "../../../../services/Sierra/helper/compTableHelper";
import { getTemplateLibraryInfo } from "../prelayout/saga";
import { getSweepMeasurementDisplay } from "../../../../services/Sierra/helper/measurementHelper";

function* addSingleExp() {
  const { SierraReducer: { sweep: { experimentInfo }, project: { currentProjectId } } } = yield select();
  const { experiments, base, id } = experimentInfo;
  const name = getDefaultName({ nameList: experiments, defaultKey: 'Exp_' });
  const Interfaces = base.Interfaces.map(item => ({ interfaceId: item.interfaceId }));
  try {
    const res = yield call(updateExperiment, { id: "", groupId: id, name: name, content: Interfaces, version: EXPERIMENT_SETUP_VERSION, projectId: currentProjectId });
    const newExperiments = [...experiments, { id: res.id, name: res.name, verificationId: res.verificationId, Interfaces, status: res.status, version: res.version }];
    yield put(updateExperimentInfo(newExperiments, 'experiments'))
  } catch (error) {
    console.error(error)
  }
}

export function* getExperimentPage({ id }) { // id = experimentGroupId(sweepId)
  let res = {};
  yield put(updateSweepMeasurementDisplayInfo(null));
  try {
    res = yield call(getExperimentsList, id);
  } catch (err) {
    return;
  }
  const { experiments, baseVerificationId, tableColumns, sweepName = "", verificationName = "" } = res;
  const { SierraReducer: { project: { currentProjectId } } } = yield select();

  let baseId = "", runningQueue = [], _tableColumns = tableColumns;
  yield put(updateRunSweeping([], true));
  let init = false;
  if (experiments && experiments.length) {
    if (experiments.length === 1 && (!experiments[0].content || !experiments[0].content.length)) {
      init = true;
    }
  } else {
    init = true;
  }

  let info = { base: {}, id: "", sweepName, verificationName, experiments: [], forSim: [], hasSchematic: false }
  if (!init) {
    const base = experiments.find(item => item.name === 'Base') || {};
    baseId = base.id;
    const hasVcPassive = (base.content || []).find(it => !!it.hasOwnProperty("virtualPassive"));

    if (!hasVcPassive) {
      //add virtual passive to base interfaces
      const response = yield call(getVerificationContentPromise, baseVerificationId);
      if (response && response.Interfaces) {

        for (let item of base.content) {
          const find = response.Interfaces.find(it => it.interfaceId === item.interfaceId);
          if (find && find.content && find.content.virtualComps) {
            item.virtualPassive = getSweepVirtualPassive(find.content.virtualComps)
          } else {
            item.virtualPassive = []
          }
        }
      }

      yield call(updateExperiment, { id: base.id, groupId: id, name: base.name, content: base.content, version: base.version, projectId: currentProjectId });
    }

    const getReduceData = (item) => {
      const { id, name, content, status, version, verificationId } = item;
      if ([VERIFY_RUNNING, WAITING].includes(status)) {
        runningQueue.push(id)
      }
      return { id, name, version, Interfaces: content, status, verificationId }
    }
    const _experiments = experiments.filter(item => item.name !== 'Base').map(item => getReduceData(item));
    info = { base: getReduceData(base), experiments: _experiments, id, forSim: [], sweepName, verificationName, hasSchematic: false }
    yield put(updateRunSweeping(runningQueue, false, false));
  } else {
    const response = yield call(getVerificationContentPromise, baseVerificationId);
    if (response && response.Interfaces) {
      let { Interfaces } = yield call(_updateInterface, { Interfaces: response.Interfaces });
      let base = getBaseSweepingData(Interfaces);
      const _id = experiments && experiments[0] ? experiments[0].id : '';
      const baseRes = yield call(updateExperiment, { id: _id, groupId: id, name: base.name, content: base.Interfaces, version: "1", projectId: currentProjectId });
      base.id = baseRes.id;
      baseId = baseRes.id;
      base.status = baseRes.status;
      base.verificationId = baseRes.verificationId;
      base.version = baseRes.version;
      info = { base, experiments: [], id, forSim: [], sweepName, verificationName, hasSchematic: false };
    }
  }

  if (!_tableColumns || !_tableColumns.length) {
    _tableColumns = info.base && info.base.Interfaces ? info.base.Interfaces.map(item => JSON.parse(JSON.stringify(DEFAULT_EXPERIMENT_COLUMNS))) : [];
    yield call(saveSweepColumns, { id, tableColumns: _tableColumns })
  }
  info.tableColumns = _tableColumns;
  let existPreLayoutIds = [];
  for (let item of [info.base, ...(info.experiments || [])]) {
    for (let infoItem of (item.Interfaces || [])) {
      if (infoItem.pcb && infoItem.pcb.id) {
        const isPreLayout = designConstructor.isPreLayout(infoItem.pcb.id);

        if (isPreLayout && !existPreLayoutIds.includes(infoItem.pcb.id)) {
          const prelayout = yield call(preLayoutData.getPreLayout, infoItem.pcb.id);
          const isSchematic = preLayoutData.isSchematic(infoItem.pcb.id);
          if (isSchematic) {
            info.hasSchematic = true;
            yield fork(getTemplateLibraryInfo, { signals: prelayout && prelayout.content ? prelayout.content.signals : [] });
          }
          existPreLayoutIds.push(infoItem.pcb.id)
        }
      }
    }
  }
  yield put(getAllExpMeasurementConfig(info));
  yield put(updateExperimentInfo(info, 'all'))
  yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: baseId, projectId: currentProjectId }));
  yield put(changeVerification("Base"));
  yield put(openTabFooter());
  yield put(getExperimentLog(baseId));
}

function* addExpsByPassive(action) {
  const { R_values, record } = action;
  const { subType } = record;
  const isVirtual = subType === "virtualPassive";
  const key = isVirtual ? subType : "passive";
  for (const R_value of R_values) {
    try {
      const { SierraReducer: { sweep: { experimentInfo }, project: { currentProjectId } } } = yield select();
      const { experiments, base, id } = experimentInfo;
      const name = getDefaultName({ nameList: experiments, defaultKey: 'Exp_' });
      let Interfaces = base.Interfaces.map(item => ({ interfaceId: item.interfaceId }));
      let passive = [{ ...record, value: R_value }]

      if (hash(record.value) !== hash(R_value) && Interfaces[record.interfaceIndex]) {
        Interfaces[record.interfaceIndex][key] = passive
      }

      const res = yield call(updateExperiment, { id: "", groupId: id, name: name, content: Interfaces, version: "1", projectId: currentProjectId });
      const newExperiments = [...experiments, { id: res.id, name: res.name, Interfaces, status: res.status, verison: "1", verificationId: res.verificationId }];
      yield put(updateExperimentInfo(newExperiments, 'experiments'))
    } catch (error) {
      console.error(error)
    }
  }
  yield put(updateSweepCreateStatus(false))
}

function* createExpsByModel(action) {
  const { modelsList, interfaceIndex } = action
  for (const model of modelsList) {
    if (model) {
      try {
        const { SierraReducer: { sweep: { experimentInfo }, project: { currentProjectId } } } = yield select();
        const { experiments, base, id } = experimentInfo;
        const name = getDefaultName({ nameList: experiments, defaultKey: 'Exp_' });
        let Interfaces = base.Interfaces.map(item => ({ interfaceId: item.interfaceId }));

        Interfaces[interfaceIndex].model = []
        Interfaces[interfaceIndex].pins = [model]

        const res = yield call(updateExperiment, { id: "", groupId: id, name: name, content: Interfaces, version: EXPERIMENT_SETUP_VERSION, projectId: currentProjectId });
        const newExperiments = [...experiments, { id: res.id, name: res.name, Interfaces, status: res.status, version: res.version, verificationId: res.verificationId }];
        yield put(updateExperimentInfo(newExperiments, 'experiments'))
      } catch (error) {
        console.error(error);
      }
    } else {
      yield call(addSingleExp)
    }
  }
  yield put(updateSweepCreateStatus(false))
  yield put(getAllExpMeasurementConfig());
}

function* updateExperimentData({ experiment }) {
  const { SierraReducer: { sweep: { experimentInfo: { id } }, project: { currentProjectId } } } = yield select();
  const { id: expId, name, version, Interfaces } = experiment;
  try {
    yield call(updateExperiment, { id: expId, groupId: id, name: name, content: Interfaces, version: version ? version : EXPERIMENT_SETUP_VERSION, projectId: currentProjectId });
  } catch (error) {
    console.error(error)
  }
}

function* updateExperimentByType(action) {
  const { currentType, params } = action;
  switch (currentType) {
    case 'pkg':
      yield call(updateExperimentPkg, params);
      break;
    case 'passive':
      yield call(updateExperimentPassive, params);
      break;
    case 'copy':
      yield call(copyExperiment, params);
      break;
    case 'delete':
      yield call(deleteExperiment, params);
      break;
    case 'rename':
      yield call(renameExperiment, params);
      break;
    case 'pins':
      yield call(updateExperimentPins, params);
      yield call(updateExperimentModel, params);
      break;
    case 'model':
      yield call(updateExperimentModel, params);
      break;
    case 'pcb':
      yield call(updateExperimentPCB, params);
      break;
    case 'trace':
      yield call(updateExperimentPreLayoutTrace, params);
      break;
    default: return;
  }
}

function* updateExperimentPCB(params) {
  const { name, interfaceIndex, projectId, experimentId, basePCB } = params;
  const selectPCB = projectDesigns.getAvailableDesigns(projectId).find(item => item.name === name);
  const { subId, id } = selectPCB;
  const pcb = { name, subId, id };
  const { SierraReducer: { sweep: { experimentInfo: { experiments, base } } } } = yield select();
  let newExperiments = JSON.parse(JSON.stringify(experiments)), isSchematic = false;
  const exp = newExperiments.find(exp => exp.id === experimentId);
  if (exp) {
    let Interface = exp.Interfaces[interfaceIndex];
    const vendor = designConstructor.getDesignVendor(id);
    if (pcb.id === basePCB.id) {
      delete Interface.pcb;
      Interface.pkg && Interface.pkg.forEach(item => item.exist = true);
      Interface.passive && Interface.passive.forEach(item => item.exist = true);
      Interface.pins && Interface.pins.forEach(item => item.exist = true);
      if (vendor === PRE_LAYOUT) {
        yield call([preLayoutData, preLayoutData.getPreLayout], id);
        isSchematic = preLayoutData.isSchematic(id);
      }
    } else {
      Interface.pcb = pcb;
      yield put(updateExperimentInfo(newExperiments, 'experiments'))
      yield call(saveComponentsNets, { pcbIds: [id] });
      let nets = [];
      const baseInterface = base.Interfaces[interfaceIndex];
      baseInterface.signals.forEach(sig => {
        nets = [...new Set([...nets, ...sig.nets])]
      })

      let componentsList = [];
      if (vendor === PRE_LAYOUT) {
        const prelayout = yield call([preLayoutData, preLayoutData.getPreLayout], id);
        const _components = prelayout.content.components;
        componentsList = getPreLayoutComponentsByPinNet(_components, nets);
        isSchematic = preLayoutData.isSchematic(id);
      } else {
        const pcbInfo = DesignInfo.getPCBInfo(id);
        const { netsList, layers } = pcbInfo;
        const COMP_PREFIX_LIB = yield call(componentSetting.getPrefixLib, id);
        const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, id);
        const compPinMap = yield call(componentSetting.getCompPinMap, id);
        const passiveTable = yield call(compTableHelper.getTableData, id);
        componentsList = getComponentsWithNetList({ netList: nets, pcbNetsList: netsList, layers, pcbId: id, COMP_PREFIX_LIB, doNotStuff, compPinMap, passiveTable });
      }
      const findExist = ['pkg', 'passive', 'pins'];
      let notExist = [];
      for (let type of findExist) {
        const info = getCompExist(baseInterface, componentsList, Interface, type);
        Interface = info.newInterface;
        notExist = [...notExist, ...info.notExist]
      }
      if (notExist.length) {
        Interface.pcb = { ...Interface.pcb, notExist: `${[...new Set(notExist)].join(', ')} not exist` }
      } else {
        delete Interface.pcb.notExist
      }
      exp.Interfaces[interfaceIndex] = Interface;
    }
  }
  //yield put(updateExperimentInfo(newExperiments, 'experiments'));
  let hasSchematic = false;
  for (let item of newExperiments) {
    for (let info of item.Interfaces || []) {
      if (info.pcb && info.pcb.id) {
        const isPreLayout = designConstructor.isPreLayout(info.pcb.id);
        if (isPreLayout) {
          hasSchematic = preLayoutData.isSchematic(info.pcb.id);
        }
      }
    }
  }
  yield put(updateExperimentInfo({ experiments: newExperiments, hasSchematic }, 'all'))

  if (isSchematic) {
    yield put(updateSweepSchematicPreLayoutInfo({
      id: experimentId,
      name: exp.name,
      interfaceIndex,
      isSweepBase: exp.name === "Base" ? true : false,
      basePCB,
      currentPCB: { id, name, subId }
    }))
  }
  yield call(updateExperimentData, { experiment: exp })
  yield put(getAllExpMeasurementConfig());
}

function* copyExperiment({ id }) {
  const { SierraReducer: { sweep: { experimentInfo: { id: groupId, experiments } }, project: { currentProjectId } } } = yield select();
  const nameList = experiments.map(exp => exp.name)
  const name = getDefaultName({ nameList, defaultKey: 'Exp_' });
  const currentExperiment = experiments.find(item => item.id === id);
  if (currentExperiment) {
    try {
      const res = yield call(updateExperiment, { id: "", groupId, name: name, content: currentExperiment.Interfaces, version: "1", projectId: currentProjectId });
      const newExperiments = [...experiments];
      newExperiments.push({ id: res.id, status: res.status, name, verison: "1", Interfaces: JSON.parse(JSON.stringify(currentExperiment.Interfaces)), verificationId: res.verificationId });
      yield put(updateExperimentInfo(newExperiments, 'experiments'))
      yield put(getAllExpMeasurementConfig());
    } catch (error) {
      message.error('Copy experiment failed!');
    }
  }
}

function* deleteExperiment({ deleteArr }) {
  const { SierraReducer: { sweep: { experimentInfo: { experiments } } } } = yield select();

  if (deleteArr.length > 0) {
    try {
      yield call(deleteExperimentByIds, deleteArr);
      const newExperiments = experiments.filter(item => !deleteArr.includes(item.id))
      yield put(updateExperimentInfo(newExperiments, 'experiments'))
    } catch (error) {
      message.error(`Delete ${deleteArr.length === 1 ? 'experiment' : 'experiments'} failed!`)
    }
  }
}

function* renameExperiment({ id, name }) {
  const { SierraReducer: { sweep: { experimentInfo: { id: groupId, experiments } }, project: { currentProjectId } } } = yield select();
  const expIndex = experiments.findIndex(item => item.id === id);
  if (expIndex > -1) {
    try {
      const currentExperiment = experiments[expIndex];
      yield call(updateExperiment, {
        id: id,
        groupId, name: name,
        content: currentExperiment.Interfaces,
        version: currentExperiment.version || EXPERIMENT_SETUP_VERSION,
        projectId: currentProjectId
      });
      let newExperiments = [...experiments];
      newExperiments[expIndex].name = name;
      yield put(updateExperimentInfo(newExperiments, 'experiments'))
    } catch (error) {
      message.error(`Rename experiment failed!`)
    }
  }
}

function* updateExperimentPkg(params) {
  const { pkg, record } = params;
  const { SierraReducer: { sweep: { experimentInfo } } } = yield select();
  const { experiments, base } = experimentInfo;
  let newExperiments = JSON.parse(JSON.stringify(experiments));
  const { id, interfaceIndex, comp, visible, part } = record;
  let def = false;
  let basePkg = base['Interfaces'][interfaceIndex]['pkg'].find(p => p.component === comp);
  delete pkg.rank;
  if (basePkg && pkg && (hash(pkg) === hash(basePkg.pkgInfo)
    || (basePkg.pkgInfo && pkg.type === 'None' && basePkg.pkgInfo.type === 'None'))) {
    def = true;
  }
  const exp = newExperiments.find(exp => exp.id === id);
  if (exp) {
    if (exp['Interfaces'][interfaceIndex]['pkg']) {
      const index = exp['Interfaces'][interfaceIndex]['pkg'].findIndex(p => p.component === comp);
      if (index > -1) {
        if (def) {
          exp['Interfaces'][interfaceIndex]['pkg'].splice(index, 1);
        } else {
          exp['Interfaces'][interfaceIndex]['pkg'][index].pkgInfo = pkg;
        }
      } else if (!def) {
        exp['Interfaces'][interfaceIndex]['pkg'].push({ component: comp, pkgInfo: pkg, visible, part });
      }
    } else if (!def) {
      exp['Interfaces'][interfaceIndex]['pkg'] = [{ component: comp, pkgInfo: pkg, visible, part }];
    }
    yield put(updateExperimentInfo(newExperiments, 'experiments'));
    yield call(updateExperimentData, { experiment: exp })
  }
}

function* updateExperimentPassive(params) {
  const { model, value, record } = params;
  const { SierraReducer: { sweep: { experimentInfo } } } = yield select();
  const { experiments, base } = experimentInfo;
  const { id, interfaceIndex, comp, subType } = record;
  const isVirtual = subType === "virtualPassive";
  const key = isVirtual ? subType : "passive";
  const passiveInfo = base.Interfaces[interfaceIndex][key].find(p => p.component === comp);
  let newExperiments = JSON.parse(JSON.stringify(experiments));
  let def = false;
  if (passiveInfo) {
    if (isVirtual) {
      if (hash(value) === hash(passiveInfo.value)) {
        def = true;
      }
    } else if ((model.type === 'value' && passiveInfo.model.type === 'value' && hash(value) === hash(passiveInfo.value))
      || (model.type !== 'value' && passiveInfo.model.type !== 'value' && hash({ ...model, version: null }) === hash({ ...passiveInfo.model, version: null }))) {
      def = true;
    }
    const exp = newExperiments.find(exp => exp.id === id);
    if (exp) {
      if (exp.Interfaces[interfaceIndex][key]) {
        const index = exp['Interfaces'][interfaceIndex][key].findIndex(p => p.component === comp);
        if (index > -1) {
          if (def) {
            exp['Interfaces'][interfaceIndex][key].splice(index, 1);
          } else {
            exp['Interfaces'][interfaceIndex][key][index] = isVirtual ? { ...passiveInfo, value } : { ...passiveInfo, model, value };
          }
        } else if (!def) {
          exp['Interfaces'][interfaceIndex][key].push(isVirtual ? { ...passiveInfo, value } : { ...passiveInfo, model, value });
        }
      } else if (!def) {
        exp['Interfaces'][interfaceIndex][key] = [isVirtual ? { ...passiveInfo, value } : { ...passiveInfo, model, value }];
      }
      yield put(updateExperimentInfo(newExperiments, 'experiments'))
      yield call(updateExperimentData, { experiment: exp })
    }
  }

}

function* updateExperimentPins(params) {
  const { id, info, parentIndex } = params;
  const { SierraReducer: { sweep: { experimentInfo } } } = yield select();
  const { base, experiments } = experimentInfo;
  let newExperiments = JSON.parse(JSON.stringify(experiments));
  const exp = newExperiments.find(exp => exp.id === id);
  if (exp) {
    const interfaceIndex = parentIndex > -1 ? parentIndex : 0;
    const basePins = base.Interfaces[interfaceIndex].pins;
    let pins = [];
    for (let item of info) {
      const component = item.component;
      let newPinInfo = [];
      if (item && item.pinInfo) {
        for (let pinInfo of item.pinInfo) {
          const data = compairExperimentPins(component, pinInfo, basePins)

          if (data) {
            newPinInfo.push(data)
          }
        }
      }
      if (newPinInfo.length > 0) {
        pins.push({ component, pinInfo: newPinInfo, exist: item.exist })
      } else if (item.exist === false) {
        pins.push({ component, exist: item.exist })
      }
    }

    exp.Interfaces[interfaceIndex].pins = pins;
    let pkg = exp.Interfaces[interfaceIndex].pkg;
    const basePkg = base.Interfaces[interfaceIndex].pkg;
    for (let pin of pins) {
      if (pin.pinInfo && pin.pinInfo.length) {
        for (let info of pin.pinInfo) {
          let pkgFromBase = false;
          if (info.model) {
            let current = pkg ? pkg.find(p => p.component === pin.component) : null;
            if (!current) {
              current = basePkg ? basePkg.find(p => p.component === pin.component) : null;
              pkgFromBase = true;
            }
            if (current && current.pkgInfo && current.pkgInfo.type === 'IBIS') {
              if (info.model.libraryId !== current.pkgInfo.libraryId) {
                pkg = pkg ? pkg.filter(p => p.component !== pin.component) : [];
                if (pkgFromBase && pkg.length) {
                  pkg.push({ ...current, pkgInfo: { type: 'None' } });
                }
                break;
              }
            }
          }
        }
      }
    }
    exp.Interfaces[interfaceIndex].pkg = pkg;
    yield put(updateExperimentInfo(newExperiments, 'experiments'))
  }
}

function* updateExperimentModel(params) {
  const { id, model, parentIndex } = params;
  const { SierraReducer: { sweep: { experimentInfo } } } = yield select();
  const { base, experiments } = experimentInfo;
  let newExperiments = JSON.parse(JSON.stringify(experiments));
  const exp = newExperiments.find(exp => exp.id === id);
  if (exp) {
    const interfaceIndex = parentIndex > -1 ? parentIndex : 0;
    const baseModel = base.Interfaces[interfaceIndex].model;
    let newModel = [];
    for (let item of model) {
      const { modelInfo, component } = item;
      const _baseModel = baseModel.find(c => c.component === component);
      let change = false;
      if (_baseModel) {
        const { files, pairs } = modelInfo;
        const { files: baseFiles, pairs: basePairs } = _baseModel.modelInfo;
        if (files && baseFiles) {
          for (let file of files) {
            const current = baseFiles.find(b => b.libraryId === file.libraryId);
            if (!current) {
              change = true;
              break;
            }
          }
        }
        if (!change && pairs) {
          for (let pair of pairs) {
            const { node, pin, subckt, libraryId } = pair;
            const current = basePairs.find(b => b.pin === pin);
            if (!current || current.node !== node || current.subckt !== subckt || current.libraryId !== libraryId) {
              change = true;
              break;
            }
          }
        }
      }
      if (change) {
        newModel.push(item)
      }
    }
    exp.Interfaces[interfaceIndex].model = newModel;
    yield put(updateExperimentInfo(newExperiments, 'experiments'))
    yield call(updateExperimentData, { experiment: exp })
    yield put(getAllExpMeasurementConfig());
  }
}

function* updateExperimentCompDisplay(action) {
  const { base, experiments } = action;
  yield put(updateExperimentInfo(experiments, 'experiments'));
  yield put(updateExperimentInfo(base, 'base'));
  yield fork(updateExperimentData, { experiment: base });
  yield* experiments.map(function* (experiment) {
    yield fork(updateExperimentData, { experiment });
  })
}

function* reduceSweepColumn(action) {
  const { key, parentIndex } = action;
  const { SierraReducer: { sweep: { experimentInfo: { id, experiments, tableColumns } } } } = yield select();
  let index = parentIndex
  if (!index || index < 1) {
    index = 0;
  }
  let _tableColumns = [...tableColumns];
  _tableColumns[index] = tableColumns[index].filter(item => item !== key);
  let newExperiments = [];
  for (let exp of experiments) {
    let experiment = { ...exp };
    if (experiment.Interfaces && experiment.Interfaces.length && experiment.Interfaces[index] && experiment.Interfaces[index][key]) {
      delete experiment.Interfaces[index][key];
      if (key === 'pins' && experiment.Interfaces[index]['model']) {
        delete experiment.Interfaces[index]['model']
      }
    }

    try {
      yield fork(updateExperimentData, { experiment });
    } catch (error) {
      console.error(error)
    }
    newExperiments.push(experiment);
  }
  yield call(saveSweepColumns, { id, tableColumns: _tableColumns })
  yield put(updateExperimentInfo({ experiments: newExperiments, tableColumns: _tableColumns, update: true }, 'all'))
}


function* updateExperimentsStatus(action) {
  const { experimentIds, status } = action;
  const { SierraReducer: { sweep: { experimentInfo: { experiments } } } } = yield select();
  let newExperiments = [...experiments];
  for (let id of experimentIds) {
    const index = newExperiments.findIndex(item => item.id === id);
    if (index > -1) {
      newExperiments[index].status = status;
    }
  }
  yield put(updateExperimentInfo(newExperiments, 'experiments'))
}


export function* getExperimentsResult({ id }) {
  let res = {}, resultExsit = [];
  try {
    res = yield call(getExperimentsList, id);
    resultExsit = yield call(getSweepResultsExsit, id);
  } catch (err) {
    return;
  }

  const { experiments } = res;
  if (experiments && experiments.length) {
    let resultList = [];
    for (let item of experiments) {
      const { id, name, verificationId } = item;
      let channelList = [], newChannel = [];
      try {
        channelList = yield call(getExperimentChannelList, id)
        const { SierraReducer: { project: { currentProjectId } } } = yield select();
        const currentProjectDesigns = projectDesigns.getAvailableDesigns(currentProjectId)
        channelList.forEach(item => {
          const [key, pcbSubId] = strDelimited(item.fileName, "_");
          item.pcb = "";
          const findPCB = currentProjectDesigns.find(item => item.subId === pcbSubId);
          if (findPCB) {
            item.pcb = findPCB.name;
            item.pcbId = findPCB.id;
          } else {
            item.pcb = key;
            item.pcbId = pcbSubId;
          };
          newChannel.push(item);
        })
      } catch (err) {
        newChannel = [];
      }
      resultList.push({
        id,
        name,
        show: resultExsit.includes(id),
        channelList: newChannel,
        exist: resultExsit.includes(id),
        verificationId
      })
    }
    const info = { base: {}, experiments: [], resultList, id };
    yield put(updateExperimentInfo(info, 'all'));
  }
}

function* updateExperimentPreLayoutTrace(action) {
  const { pcb, trace, expId, interfaceIndex } = action;
  const { SierraReducer: { sweep: { experimentInfo } } } = yield select();
  const { experiments } = experimentInfo;
  if (interfaceIndex < 0) {
    return
  }
  let newExperiments = JSON.parse(JSON.stringify(experiments));
  const exp = newExperiments.find(exp => exp.id === expId);
  if (exp) {
    if (exp.Interfaces[interfaceIndex].pcb) {
      exp.Interfaces[interfaceIndex].pcb.trace = trace;
    } else {
      exp.Interfaces[interfaceIndex].pcb = { ...pcb, trace };
    }
    yield put(updateExperimentInfo(newExperiments, 'experiments'))
    yield call(updateExperimentData, { experiment: exp })
  }
}

function* createExpsByTrace(action) {
  const { newExps } = action
  for (const itemExp of newExps) {
    if (!itemExp || !itemExp.pcb || !itemExp.pcb.id) { continue }
    try {
      const { SierraReducer: { sweep: { experimentInfo }, project: { currentProjectId } } } = yield select();
      const { experiments, base, id } = experimentInfo;
      const name = getDefaultName({ nameList: experiments, defaultKey: 'Exp_' });
      const interfaceIndex = base.Interfaces.findIndex(item => item.pcb && item.pcb.id === itemExp.pcb.id);
      if (interfaceIndex < 0) {
        continue;
      }

      let Interfaces = base.Interfaces.map(item => ({ interfaceId: item.interfaceId }));

      if (!Interfaces[interfaceIndex].pcb) {
        Interfaces[interfaceIndex].pcb = {
          ...(itemExp.pcb || {})
        }
      }
      Interfaces[interfaceIndex].pcb.trace = itemExp.trace || [];

      const res = yield call(updateExperiment, { id: "", groupId: id, name: name, content: Interfaces, version: "1", projectId: currentProjectId });
      const newExperiments = [...experiments, { id: res.id, name: res.name, Interfaces, status: res.status, verison: "1", verificationId: res.verificationId }];
      yield put(updateExperimentInfo(newExperiments, 'experiments'))
    } catch (error) {
      console.error(error);
    }
  }
  yield put(updateSweepCreateStatus(false))
}

function* _getAllMeasurement(action) {
  const { info } = action;

  const { SierraReducer: { sweep: { experimentInfo } } } = yield select();
  const { experiments, base } = experimentInfo;
  let _info = info ? info : { base, experiments };
  if (!_info.base || !_info.base.verificationId || !_info.experiments || !_info.experiments.length) {
    return;
  }
  try {
    let expMap = {}
    const verificationIds = [_info.base || {}, ...(_info.experiments || [])].map(item => {
      if (item.verificationId) {
        expMap[item.verificationId] = item;
      }
      return item.verificationId
    }).filter(item => !!item);
    const resList = yield call(getAllExperimentsMeasurement, verificationIds);
    const sweepMeasurementInfo = getSweepMeasurementDisplay({ info: _info, resList, verificationIds, expMap })
    yield put(updateSweepMeasurementDisplayInfo(sweepMeasurementInfo));
  } catch (error) {
    console.error(error)
    yield put(updateSweepMeasurementDisplayInfo(null));
  }

}

export default function* sierraSweepSaga() {
  yield takeEvery(ADD_EXPERIMENT, addSingleExp);
  yield takeEvery(ADD_EXPERIMENTS_BY_PASSIVE, addExpsByPassive);
  yield takeEvery(CREATE_EXPS_BY_MODEL, createExpsByModel);
  yield takeEvery(UPDATE_SINGLE_EXPERIMENT, updateExperimentByType);
  yield takeEvery(UPDATE_EXPERIMENT_COMP, updateExperimentCompDisplay);
  yield takeEvery(REDUCE_SWEEP_TABLE_COLUMN, reduceSweepColumn);
  yield takeEvery(UPDATE_EXPERIMENTS_STATUS, updateExperimentsStatus);
  yield takeEvery(CREATE_EXPS_BY_TRACE, createExpsByTrace);
  yield takeEvery(GET_ALL_EXP_MEASUREMENT_CONFIG, _getAllMeasurement)
}