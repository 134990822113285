import http from "../http";

/**
 * Get all verifications in current project.
 * 2019.08.05
 * 
 * @export
 * @param {string} projectId
 * @returns XMLHttpRequest 
 */
export function getVerificationsInProject(projectId) {
  return http.get(`/project/${projectId}/verifications`);
}

/**
 * Get verification content by verification id.
 * 2019.08.05
 *
 * @export
 * @param {string} verificationId verification id
 * @returns XMLHttpRequest 
 */
export function getVerificationContent(verificationId) {
  return http.get(`/project/verification/${verificationId}`);
}

/**
 * Delete verifications by verifications id list
 * 2019.08.05
 *
 * @export
 * @param {*} verificationIds
 * @returns XMLHttpRequest
 */
export function deleteVerification(verificationIds) {
  return http.delete(`/project/verifications`, {
    data: verificationIds
  })
}

/**
 * Upload verification json
 * 2019.08.15
 *
 * @export
 * @param {string} projectId project id
 * @param {string} file 
 * @returns XMLHttpRequest
 */
export function uploadConfigFile(projectId, file) {
  return http.post(`/project/${projectId}/verification/upload`, file, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
}


/**
 * Get verficiation status
 * 2019.08.21
 *
 * @export
 * @param {string} verificationId
 * @returns XMLHttpRequest
 */
export function getVerificationStatus(verificationId) {
  return http.get(`/project/verification/${verificationId}/status`);
};

/**
 * Create verfication for Sierra
 * 2019.11.12
 * 
 * @param {Object} param { id, name, projectId, typeName }
 */
export function createVerification(param) {
  return http.post(`/project/verification`, param);
}

/**
 * Get verification content by verification id for Sierra
 * 2019.11.13
 *
 * @export
 * @param {string} verificationId verification id
 * @param {string} typeName sierra | Andes
 * @returns XMLHttpRequest 
 */
export function getVerificationContentByTypeName(verificationId, typeName) {
  return http.get(`/project/verification/${verificationId}/type/${typeName}`);
}

/**
 * Download verification whole log
 * 2020.04.24
 *
 * @export
 * @param {String} verificationId - verification id
 * @returns
 */
export function getVerificationWholeLog(verificationId) {
  return http.get(`/verification/${verificationId}/whole/log`)
}

/**
 * Get debug download log message
 *
 * @export
 * @param {*} verificationId verification id
 * @param {*} type - extraction, simulation
 * @returns
 */
export function debugDownloadMsg(verificationId, type) {
  return http.get(`/verification/${verificationId}/debug/${type}/downloadLog`);
}


export function combineInterfaces(verificationIds) {
  return http.post(`/sierra/project/design/combine`, verificationIds)
}

/**
 * Get sierra create interfaces status and log
 * @param {string} createKey hash key
 *  */
export function getSierraCreateInterfaceStatusInfo(createKey) {
  return http.get(`/project/verification/sierra/log?key=${createKey}`)
}