import {
  LAYER_CHANGE,
  LOCATION_CHANGE,
  OPEN_PANEL,
  CLOSE_PANEL,
  COLORBY_CHANGE,
  SELECTED_ALL,
  CANCEL_SELECTED_ALL,
  SHOW_SELECTED,
  HIDE_SELECTED,
  SELECTION_CHECKBOX_CHANGE,
  SELECTION_CLEAR_ONE,
  CLEAN_STATUS,
  CHANGE_SELECTED_DISPLAY,
  SELECT_CHANGE,
  CHANGE_SHOW_NAME_STATUS,
  SELECTION_CLEAR
} from './actionTypes';

export const updateLocation = (location, designID) => ({
  type: LOCATION_CHANGE,
  location,
  designID
})

export const selectLayer = (layers, designID) => ({
  type: LAYER_CHANGE,
  layers,
  designID
})

export const openPanel = (panelType, designID) => ({
  type: OPEN_PANEL,
  panel: panelType,
  designID
})

export const closePanel = (designID) => ({
  type: CLOSE_PANEL,
  designID
})

export const changeColorBy = (colorBy, designID) => ({
  type: COLORBY_CHANGE,
  colorBy,
  designID
})

export const clearOneSelection = (name, designID) => ({
  type: SELECTION_CLEAR_ONE,
  name,
  designID
})

export const clearSelection = (designID) => ({
  type: SELECTION_CLEAR,
  designID
})

export const showSelected = (designID) => ({
  type: SHOW_SELECTED,
  designID
})

export const hideSelected = (designID) => ({
  type: HIDE_SELECTED,
  designID
})

export const selectedAll = (designID) => ({
  type: SELECTED_ALL,
  designID
})

export const cancelSelectedAll = (designID) => ({
  type: CANCEL_SELECTED_ALL,
  designID
})

export const checkboxChange = (canvasObj) => {
  return {
    type: SELECTION_CHECKBOX_CHANGE,
    selected: canvasObj.selected,
    designID: canvasObj.designID
  };
}

export const cleanStatus = (designID) => ({
  type: CLEAN_STATUS,
  designID
})

export const changeDisplaySelected = (show, designID) => ({
  type: CHANGE_SELECTED_DISPLAY,
  show,
  designID
});

export const selectChange = ({ nets = [], comps = [], pinsObj = {} }, designID) => ({
  type: SELECT_CHANGE,
  nets,
  comps,
  pinsObj,
  designID
})

export const changeShowNameStatus = (status, designID) => ({
  type: CHANGE_SHOW_NAME_STATUS,
  status,
  designID
})
