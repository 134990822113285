import {
  PARSE_FILE_AND_CREATE_SIGN_OFF_TASK,
  GET_SIGN_OFF_CONTENT,
  UPDATE_SIGN_OFF_TEMPLATE_INFO,
  CHANGE_SIGN_OFF_PCB,
  SAVE_SIGN_OFF_VERIFICATION_ID,
  CREATE_AND_RUN_IMPEDANCE_TASK,
  UPDATE_SIGN_OFF_TEMPLATE_LIST_STATUS,
  SELECT_SIGN_OFF_TASK,
  UPDATE_SIGN_OFF_TASK_ERROR,
  UPDATE_SIGN_OFF_CREATE_TASK_STATUS,
  RESET_TEMPLATE_SETUP,
  UPDATE_TEMPLATE_ERRORS,
  UPDATE_TEMPLATE_PCB_LOADING,
  UPDATE_TEMPLATE_PORTS,
  SAVE_TEMPLATE_CAP_MODEL,
  UPDATE_TEMPLATE_CONTENT,
  UPDATE_TEMPLATE_SOC,
  UPDATE_TEMPLATE_COMP_SETTING,
  UPDATE_TEMPLATE_COMPONENTS_SETTING_STATUS,
  UPDATE_TEMPLATE_CONTENT_LOADING,
  UPDATE_TEMPLATE_GND,
  SAVE_SIGN_OFF_CONTENT,
  UPDATE_TEMPLATE,
  SAVE_TEMPLATE_CONFIG,
  RE_ASSIGN_TEMPLATE_DECAP_MODEL,
  UPDATE_MODEL_ASSIGN_LOADING,
  DOWNLOAD_TEMPLATE_S1P_FILE,
  UPDATE_CREATE_TASK_MSG,
  SAVE_IMPEDANCE_VALUE,
  UPDATE_TEMPLATE_SETUP,
  SAVE_TEMPLATE_VALUE,
  CREATE_NEW_POWER,
  DELETE_TEMPLATE_PWR_DOMAIN,
  ADD_TEMPLATE_POWER_DOMAIN,
  UPDATE_SIGN_EXTRACTION,
  SAVE_IMP_FREQ_LIST,
  DELETE_IMPEDANCE_FREQ,
  UPDATE_TEMPLATE_SETUP_VALUE,
  UPDATE_TEMPLATE_STACKUP_ERROR,
  UPDATE_DCR_EXTRACTION,
  SAVE_ADDITIONAL_NETS,
  UPDATE_DESIGN,
  UPDATE_SIGN_OFF_TEMPLATE_LOGS,
  SAVE_TEMPLATE_DISPLAY,
  UPDATE_TEMPLATE_COMP_TABLE_DISPLAY,
  UPDATE_TEMPLATE_DECAP_MODEL,
  UPDATE_TEMPLATE_PDF_PANEL,
  CHOOSE_PDF_TABLE
} from "./actionType";

export const parseFileAndCreateSignOffTask = (params = {}) => ({
  type: PARSE_FILE_AND_CREATE_SIGN_OFF_TASK,
  ...params
});

export const getSignOffContent = (verificationId) => ({
  type: GET_SIGN_OFF_CONTENT,
  verificationId
});

export const updateSignOffTemplateInfo = (data) => ({
  type: UPDATE_SIGN_OFF_TEMPLATE_INFO,
  data
});

export const changeSignOffPCB = (designId) => ({
  type: CHANGE_SIGN_OFF_PCB,
  designId
});

export const savaSignOffVerificationId = (id) => ({
  type: SAVE_SIGN_OFF_VERIFICATION_ID,
  id
});

export const createAndRunImpedanceTask = ({ signOffVerificationId, signOffDesignId, createConfig }) => ({
  type: CREATE_AND_RUN_IMPEDANCE_TASK,
  signOffVerificationId,
  signOffDesignId,
  createConfig
});

export const updateSignOffTemplateListStatus = (signOffList, projectId) => ({
  type: UPDATE_SIGN_OFF_TEMPLATE_LIST_STATUS,
  signOffList,
  projectId
});

export const selectSignOffTask = (id) => ({
  type: SELECT_SIGN_OFF_TASK,
  id
});

export const updateSignOffTaskError = ({ taskErrorMap, pathTaskErrorMap }) => ({
  type: UPDATE_SIGN_OFF_TASK_ERROR,
  taskErrorMap,
  pathTaskErrorMap
})

export const updateSignOffCreateTaskStatus = (status) => ({
  type: UPDATE_SIGN_OFF_CREATE_TASK_STATUS,
  status
});

export const resetTemplateSetup = () => ({
  type: RESET_TEMPLATE_SETUP
});

export const updateTemplateErrors = (error) => ({
  type: UPDATE_TEMPLATE_ERRORS,
  error
});

export const updateTemplatePCBLoading = (loading) => ({
  type: UPDATE_TEMPLATE_PCB_LOADING,
  loading
});

export const updateTemplatePorts = (id, ports, record, defaultPortIndexList) => ({
  type: UPDATE_TEMPLATE_PORTS,
  id,
  ports,
  record,
  defaultPortIndexList
});

export const saveTemplateCapModel = (record, value, models, apply, applyModelList, applySweep) => ({
  type: SAVE_TEMPLATE_CAP_MODEL,
  record,
  value,
  models,
  apply,
  applyModelList,
  applySweep
});

export const updateTemplateContent = (info = {}) => ({
  type: UPDATE_TEMPLATE_CONTENT,
  info
});

export const updateTemplateSoc = (name) => ({
  type: UPDATE_TEMPLATE_SOC,
  name
});

export const updateTemplateByCompSetting = (update) => ({
  type: UPDATE_TEMPLATE_COMP_SETTING,
  update
});

export const updateTemplateComponentSettingStatus = (status) => ({
  type: UPDATE_TEMPLATE_COMPONENTS_SETTING_STATUS,
  status
});

export const updateTemplateContentLoading = (loading) => ({
  type: UPDATE_TEMPLATE_CONTENT_LOADING,
  loading
});

export const updateTemplateGnd = (name) => ({
  type: UPDATE_TEMPLATE_GND,
  name
});

export const saveTemplateContent = (update = false) => ({
  type: SAVE_SIGN_OFF_CONTENT,
  update
});

export const updateTemplate = (info = {}) => ({
  type: UPDATE_TEMPLATE,
  ...info
});

export const saveTemplateConfig = (config, update = false) => ({
  type: SAVE_TEMPLATE_CONFIG,
  config,
  update
});

export const reTemplateAssignDecapModel = () => ({
  type: RE_ASSIGN_TEMPLATE_DECAP_MODEL
});

export const updateModelAssignLoading = (loading) => ({
  type: UPDATE_MODEL_ASSIGN_LOADING,
  loading
});

export const downloadTemplateS1PFile = (record) => ({
  type: DOWNLOAD_TEMPLATE_S1P_FILE,
  record
});

export const updateCreateTaskMsg = (msgList) => ({
  type: UPDATE_CREATE_TASK_MSG,
  msgList
});

export const saveImpedanceValue = (value, record, dataIndex, impType) => ({
  type: SAVE_IMPEDANCE_VALUE,
  value,
  record,
  dataIndex,
  impType
});

export const updateTemplateSetup = (status) => ({
  type: UPDATE_TEMPLATE_SETUP,
  status
});

export const saveTemplateValue = (value, record, dataIndex) => ({
  type: SAVE_TEMPLATE_VALUE,
  value,
  record,
  dataIndex
});

export const createNewPower = (powerDomainInfo) => ({
  type: CREATE_NEW_POWER,
  powerDomainInfo
});

export const deleteTemplatePwrDomain = (record) => ({
  type: DELETE_TEMPLATE_PWR_DOMAIN,
  record
});

export const addTemplatePowerDomain = (record) => ({
  type: ADD_TEMPLATE_POWER_DOMAIN,
  record
})

export const updateSignExtraction = (extraction) => ({
  type: UPDATE_SIGN_EXTRACTION,
  extraction
});

export const saveImpFreqList = (list, RLValueChecked, impType) => ({
  type: SAVE_IMP_FREQ_LIST,
  list,
  RLValueChecked,
  impType
});

export const deleteImpedanceFreq = (dataIndex) => ({
  type: DELETE_IMPEDANCE_FREQ,
  dataIndex
});

export const updateTemplateSetupValue = (templateValueUpdate) => ({
  type: UPDATE_TEMPLATE_SETUP_VALUE,
  templateValueUpdate
});

export const updateTemplateStackupError = (templateStackupErrors) => ({
  type: UPDATE_TEMPLATE_STACKUP_ERROR,
  templateStackupErrors
});

export const updateDCRExtraction = (extraction) => ({
  type: UPDATE_DCR_EXTRACTION,
  extraction
})

export const saveAdditionalNets = (nets) => ({
  type: SAVE_ADDITIONAL_NETS,
  nets
})

export const updateDesignId = (designId) => ({
  type: UPDATE_DESIGN,
  designId
})

export const saveTemplateLogs = (logs = [], clear = false) => ({
  type: UPDATE_SIGN_OFF_TEMPLATE_LOGS,
  logs,
  clear
})

export const saveTemplateDisplay = (compTableDisplay) => ({
  type: SAVE_TEMPLATE_DISPLAY,
  compTableDisplay
})

export const updateCompTableDisplay = (display, clearModel) => ({
  type: UPDATE_TEMPLATE_COMP_TABLE_DISPLAY,
  display,
  clearModel
})

export const updateDecapModel = (file, display) => ({
  type: UPDATE_TEMPLATE_DECAP_MODEL,
  file,
  display
})

export const updateTemplatePDFPanel = (templateInfo, replace = false) => ({
  type: UPDATE_TEMPLATE_PDF_PANEL,
  templateInfo,
  replace
})

export const choosePDFTable = (value) => ({
  type: CHOOSE_PDF_TABLE,
  value
})