const SIERRA = 'sierra';
export const LOCATION_CHANGE = `${SIERRA}/location`;
export const LAYER_CHANGE = `${SIERRA}/layer_change`;
export const OPEN_PANEL = `${SIERRA}/open_panel`;
export const CLOSE_PANEL = `${SIERRA}/close_panel`;
export const COLORBY_CHANGE = `${SIERRA}/colorby_change`;
export const SELECTION_CLEAR = `${SIERRA}/selection_clear`;
export const SELECTION_CLEAR_ONE = `${SIERRA}/selection_clear_one`;
export const SELECTED_ALL = `${SIERRA}/selected_all`;
export const CANCEL_SELECTED_ALL = `${SIERRA}/cancel_selected_all`;
export const SHOW_SELECTED = `${SIERRA}/show_selected`;
export const HIDE_SELECTED = `${SIERRA}/hide_selected`;
export const SELECTION_CHECKBOX_CHANGE = `${SIERRA}/selection_checkbox_change`;
export const CLEAN_STATUS = `${SIERRA}/clean_status`;
export const CHANGE_SELECTED_DISPLAY = `${SIERRA}/change_selected_display`;
export const SELECT_CHANGE = `${SIERRA}/select_change`;
export const CHANGE_SHOW_NAME_STATUS = `${SIERRA}/change_show_name_status`;
export const UPDATE_PCB_REFRESH_STATUS = `${SIERRA}/update_pcb_refresh_status`;