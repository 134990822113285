import React, { PureComponent } from "react";
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox } from "antd";
import '@/publicCss/style.css';
import '@/publicCss/debug.css';

const CheckboxGroup = Checkbox.Group;
class DebugContent extends PureComponent {

  render() {
    const {
      menuType,
      currentCheckValues,
      downloadList,
      checkExpandKeys = [],
      enabledChildren,
      contentBottom
    } = this.props;
    const includeChild = downloadList.find(item => item.children && item.children.length);
    return (
      <CheckboxGroup
        value={currentCheckValues.map(item => item.name)}
        key={menuType}
        className='debug-download-checkbox-group-box'
        style={{ marginBottom: contentBottom || 70 }}
        onChange={(values) => this.props.changeDebugCheckList(values)}
      >
        {
          downloadList.map(item => {
            if (item.children) {
              const checkedItem = currentCheckValues.find(it => it.name === item.option);
              const childrenCheckedList = checkedItem && checkedItem.children ? checkedItem.children : [];
              const display = checkExpandKeys.includes(item.option);
              const Icon = display ? DownOutlined : RightOutlined
              return (
                <div key={item.option} className="debug-download-checkbox-group-content">
                  <Icon onClick={(e) => this.props.expandChange(e, item.option)} />
                  <Checkbox
                    key={item.option}
                    value={item.option}
                    className='debug-download-checkbox-group'
                  >
                    <span className="debug-download-checkbox-span" title={item.name}>{item.name}</span>
                  </Checkbox>
                  {display ? <CheckboxGroup
                    value={childrenCheckedList}
                    key={menuType}
                    disabled={enabledChildren ? false : !checkedItem}
                    className='debug-download-checkbox-box'
                    onChange={(values) => this.props.changeDebugCheckChildren(values, item.option)}
                  >
                    {item.children.map(child => (
                      <Checkbox
                        key={child.option}
                        value={child.option}
                        className='debug-download-checkbox-children-group'
                      >
                        <span className="debug-download-checkbox-children-span" title={child.name}>{child.name}</span>
                      </Checkbox>
                    ))}
                  </CheckboxGroup> : null}
                </div>
              );
            } else {
              return (
                <Checkbox
                  key={item.option}
                  value={item.option}
                  className={includeChild ? 'debug-download-checkbox-group padding-left-34' : 'debug-download-checkbox-group'}
                >
                  <span title={item.name}>{item.name}</span>
                </Checkbox>
              )
            }
          })
        }
      </CheckboxGroup>
    );
  }
}

export default DebugContent;