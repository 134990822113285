import LayoutRenderer from './LayoutRenderer';

class NetsCanvas {
  constructor(VC_COMP_TYPES = []) {
    this.layout = null; // layoutDB
    this.layoutRenderer = null;
    this.clickMousePosition = {};
    this.nets = [];
    this.components = [];
    this.VC_COMP_TYPES = [...(VC_COMP_TYPES || [])];
  }

  /**
   * create and initial layerRenderer by layout db
   * @param {dom} svg dom element to be rendered
   * @param {object} layoutDB layout data object
   * @param {dom} locationSvg location dom element
   * @param {object} events zoom and mousemove event functions
   */

  initLayout = (svg, layoutDB, locationSvg, showPinList, events) => {
    this.layout = layoutDB;
    this.layoutRenderer = new LayoutRenderer(svg, layoutDB, locationSvg, events, this.updateClickMousePosition, true);
    this.eventFns = events;
    this.layoutRenderer.setCanvasType("NET");
    this.layoutRenderer.setShowPinList(showPinList);
    this.layoutRenderer.resetCanvas();
    this.layoutRenderer.fitView();
    this.layout.GetLayerManager().GetAllMetalLayers().mValues.forEach(name => {
      this.layoutRenderer.addMetalLayer(name);
    });
    this.layoutRenderer.addSelectionLyr();
    const metalLyrs = this.layout.mLayerMgr.mMetalLayers;
    this.nets = this.layout.mNetManager.mNetList.mKeys;
    if (metalLyrs && metalLyrs.length) {
      for (let metalItem of metalLyrs) {

        if (!metalItem.mComponentLayer) {
          continue;
        }
        const layer = metalItem.mComponentLayer;

        if (!layer.mComponents || !layer.mComponents.length) {
          continue;
        }
        this.components = this.components.concat(layer.mComponents.map(function (comp) {
          return comp.mName;
        }));
      }
    }
    // init layer select
    const settings = this.layout.GetLayoutSettings();
    if (settings.mLayerVisibility
      && settings.mLayerVisibility.mKeys
      && settings.mLayerVisibility.mKeys.length
    ) {
      settings.mLayerVisibility.mKeys.forEach(d => {
        settings.SetLayerVisible(d, false);
      })
    }

    //Deduplication
    this.nets = [...new Set(this.nets)];
    this.components = [...new Set(this.components)];

    this.nets = this.nets.map(function (net) {
      return net
    });
    this.components = this.components.map(function (comp) {
      return comp
    })
  }

  /**
 * draw shownLayers and hide hiddenLayers
 * @param {array} shownLayers the display layer names
 * @param {array} hiddenLayers the hidden layer names
 */
  updateLayers = (shownLayers, hiddenLayers) => {
    const settings = this.layout.GetLayoutSettings();
    hiddenLayers.forEach(name => {
      settings.SetLayerVisible(name, false);
    });
    shownLayers.forEach(name => {
      settings.SetLayerVisible(name, true);
    });
    this.layoutRenderer.updateLayerVisibility();
    this.layoutRenderer.updateColoring();
  }

  /**
 * find components in which layers
 * @param {array} names selected components' names
 */
  findCurrentLayer = (names) => {
    if (names.length && names.length > 0) {
      let layers = this.layoutRenderer.getCurrentLayer(names);
      return layers;
    } else {
      return [];
    }
  }

  fitView = () => {
    this.layoutRenderer.fitView();
  };

  zoomIn = () => {
    this.layoutRenderer.zoom(1.272);
  };

  zoomOut = () => {
    this.layoutRenderer.zoom(1 / 1.272);
  };

  getLayerColor = (layer) => {
    return this.layout.GetLayoutSettings().GetLayerColor(layer);
  }

  selectNetCompPin = ({ pins = {} }) => {
    let _pins = pins ? JSON.parse(JSON.stringify(pins)) : {};
    this.layoutRenderer.showSelectedCompsPins(_pins);
  }

  showNetsAndCompsName = (nets, comps, designId) => {
    this.layoutRenderer.onlyShowNetsName(nets, designId)
    this.layoutRenderer.highlightSelectedCompsPins(comps, [], true)
  }

  removeNetsAndCompsName = () => {
    this.layoutRenderer.removeCompsAndNetsName()
  }

  updateClickMousePosition = ({ x, y }) => {
    this.clickMousePosition = { x, y };
  }

  getClickMousePosition = () => {
    return this.clickMousePosition;
  }

  clearClickMousePosition = () => {
    this.clickMousePosition = null;
  }

  updateLineCanvas = (params, events) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.highlightHybridPoints({ ...params, VC_COMP_TYPES: this.VC_COMP_TYPES }, events,);
    }
  }

  showRectangleByLocation = ({ locations, editingInfo, events }) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.showRectangleByLocation({ locations, editingInfo, events, VC_COMP_TYPES: this.VC_COMP_TYPES });
    }
  }

  removeRectangle = (name) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.removeRectangleByLocation(name);
    }
  }

  removeAllPointsByName = (lineName) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.removeHybridPoints(lineName);
    }
  }
}

/* const netsCanvas = new NetsCanvas(); */
export default NetsCanvas;

