const PROJECT_V2_TYPE = 'ROCKY_V2';
// Project
export const GET_VERIFICATION_CONTENT = `${PROJECT_V2_TYPE}/get_verification_content`;
export const UPDATE_SSN_CENTRIC_INFO = `${PROJECT_V2_TYPE}/update_ssn_centric_info`;
export const UPDATE_CENTRIC_CONTENT_LOADING = `${PROJECT_V2_TYPE}/update_centric_content_loading`;
export const UPDATE_SELECT_INTERFACE_INFO = `${PROJECT_V2_TYPE}/update_select_interface_info`;
export const CHANGE_SELECT_INTERFACE_INFO = `${PROJECT_V2_TYPE}/change_select_interface_info`;
export const SAVE_CURRENT_SSN_VERIFICATION = `${PROJECT_V2_TYPE}/sava_current_ssn_verification`;
export const UPDATE_SSN_PDN_CONTENT = `${PROJECT_V2_TYPE}/update_ssn_pdn_content`;
export const JUMP_TO_PACKAGE_PDN = `${PROJECT_V2_TYPE}/jump_to_package_pdn`;
export const UPDATE_SSN_PART_CONTENT = `${PROJECT_V2_TYPE}/update_ssn_part_content`;
export const UPDATE_PATTERN_LIBRARY = `${PROJECT_V2_TYPE}/update_pattern_library`;
export const UPDATE_PATTERN_SWEEP_TABLE = `${PROJECT_V2_TYPE}/update_pattern_sweep_table`;
export const UPDATE_PATTERN_SWEEP_INFO = `${PROJECT_V2_TYPE}/update_pattern_sweep_info`;
export const UPDATE_SOME_CONTENT_INFO = `${PROJECT_V2_TYPE}/update_some_content_info`;
export const UPDATE_VERIFICATION_IDS = `${PROJECT_V2_TYPE}/update_verification_ids`;
export const CLEAR_SSN_INFO = `${PROJECT_V2_TYPE}/clear_ssn_info`;
export const SAVE_SSN_CONTENT = `${PROJECT_V2_TYPE}/save_ssn_content`;

export const SAVE_CONTENT_INFO = `${PROJECT_V2_TYPE}/save_content_info`;
export const UPDATE_BUFFER_MODEL_BY_CSM = `${PROJECT_V2_TYPE}/update_SSN_buffer_model_by_csm`;