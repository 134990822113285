class UserData {
  constructor() {
    this.id = null;
    this.username = null;
    this.email = null;
  }

  getUserId() {
    return this.id;
  }

  getUserName() {
    return this.username;
  }

  getEmail() {
    return this.email;
  }

  setInfo({ id, username, email }) {
    this.id = id;
    this.username = username;
    this.email = email;
  }

  clearInfo() {
    this.id = null;
    this.username = null;
    this.email = null;
  }
}

const userData = new UserData();

export default userData;