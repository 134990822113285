function preLayoutErrorCheck(preLayoutData, decapList) {
  if (!preLayoutData) {
    return []
  }
  const { content, name } = preLayoutData;
  let errors = [];

  const { ball, decapGroups } = content;
  if (!ball.l || !ball.r) {
    errors.push(`[Pre-Layout][${name}][Ball] Not set L or R.`)
  }

  for (let decapGroup of decapGroups) {
    const { l_group, r_group, decaps, name: groupName } = decapGroup;
    if (!l_group || !r_group) {
      errors.push(`[Pre-Layout][${name}][${groupName}] Not set L Group or R Group.`)
    }
    for (let decap of decaps) {
      const { l_layout, model, name: decapName, number, r_layout } = decap;
      if (!l_layout || !r_layout) {
        errors.push(`[Pre-Layout][${name}][${groupName}][${decapName}] Not set L Layout or R Layout.`)
      }
      if (!number) {
        errors.push(`[Pre-Layout][${name}][${groupName}][${decapName}] Not set decap number.`)
      }
      if (!model || !model.subckt) {
        errors.push(`[Pre-Layout][${name}][${groupName}][${decapName}] Not set decap part.`)
      }
      if (decapList) {
        const library = decapList.find(item => item.id === model.libraryId);
        if (!library && model.fileName) {
          errors.push(`[Pre-Layout][${name}][${groupName}][${decapName}] ${model.fileName} can not be found in Library.`)
        }
      }
    }
  }

  return errors
}

export {
  preLayoutErrorCheck
}