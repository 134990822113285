import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import DelConfirm from '../../../../components/DelConfirm';
import { Select, TreeSelect } from 'antd';
import Panel from '../../../../components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../../../services/helper/panelSizeHelper';
import ReportTemplate from '../../../../components/ReportTemplate/reportTemplate';
import {
  updateReportInfo,
  clearReportInfo,
  saveReportConfig,
  getReport
} from '../../store/project/action';
import { generateReport, getReportConfig } from '../../../../services/Himalayas';
import { IO_SIM, IO_SIM_GROUPS } from '../../../../constants/treeConstants';
import himalayasChannels from '../../../../services/Himalayas/project/channelsConstructor';
import { reportProgressRender } from '../../../../components/Report';
import "./index.css";


const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeVisible: false,
      selectedInterfaces: [],
      treeData: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 400),
      maxWidth: getPanelMaxWidth(offset, 700)
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    await this.getConfig();
    this.getDefaultInterfaces()
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.projectId !== this.props.projectId) {
      //clear prev project report info
      this.props._clearReportInfo();
      this.props.closePanel();
    }
  }

  getConfig = async () => {
    const { projectId } = this.props;
    const config = await getReportConfig(projectId);
    let _config = config ? { ...config } : {};
    _config = {
      projectId,
      channelIds: _config.channelIds || [],
      format: _config.format || "PPTX",
      language: _config.language || "english",
      templateId: _config.templateId || "",
      fileName: ""
    }
    this.props._updateReportInfo({ reportConfig: _config })
  }

  getDefaultInterfaces = () => {
    const { projectId, view, channelId, reportInfo } = this.props;
    let { reportConfig, reportVisible } = reportInfo || {};
    let treeList = himalayasChannels.getList(IO_SIM_GROUPS, projectId);
    let selectedInterfaces = [];
    if (view === IO_SIM) {
      selectedInterfaces = [channelId];
    } else {
      selectedInterfaces = (treeList.find(item => channelId === item.id) || { children: [] }).children.map(item => item.id)
    }
    if (reportVisible && reportConfig && reportConfig.channelIds) {
      selectedInterfaces = [...reportConfig.channelIds];
    }
    this.setState({
      selectedInterfaces,
      treeData: treeList.map(item => {
        return {
          title: item.name,
          value: item.id,
          key: item.id,
          children: item.children.map(item => ({ title: item.name, value: item.id, key: item.id, id: item.id }))
        }
      })
    })
  }

  updateReportConfig = ({ templateId }) => {
    const { reportInfo } = this.props;
    let config = { ...(reportInfo.reportConfig || {}) };
    config.templateId = templateId;
    this.props._updateReportInfo({ reportConfig: config });
  }

  updateMessage = (message) => {
    this.props._updateReportInfo({ reportMessage: message });
  }

  deleteConfirm = () => {
    this.setState({
      closeVisible: false
    })
    const { projectId } = this.props;
    this.props._saveReportConfig(projectId);
    //clear prev project report info
    this.props._clearReportInfo();
    this.props.closePanel();
  }

  cancelDel = () => {
    this.setState({
      closeVisible: false
    })
  }

  onTreeChange = (values, titles, node) => {
    const { selectedInterfaces } = this.state;
    const { projectId } = this.props;
    const triggerValue = node.triggerValue;
    const checked = node.checked;
    const props = node.triggerNode.props;
    let newSelected = []

    if (props.children && props.children.length) {
      //sheet input- spice card template
      newSelected = checked ? props.children.map(item => item.props.id) : [];
    } else {
      //interface - io_sim
      if (!checked) {
        newSelected = selectedInterfaces.filter(item => item !== triggerValue);
      } else {
        const findInterface = himalayasChannels.getInfoById(IO_SIM, projectId, triggerValue) || {};
        const prevInterface = selectedInterfaces.length ? himalayasChannels.getInfoById(IO_SIM, projectId, selectedInterfaces[0]) : null;
        if (prevInterface && prevInterface.spiceCardId === findInterface.spiceCardId) {
          newSelected = [...selectedInterfaces]
        }
        newSelected = [...new Set([...newSelected, triggerValue])]
      }
    }
    this.setState({
      selectedInterfaces: newSelected
    })
  }

  changeReportType = (value) => {
    const { reportInfo } = this.props;
    let _reportConfig = { ...(reportInfo.reportConfig || {}) };
    _reportConfig.format = value;
    this.props._updateReportInfo({ reportConfig: _reportConfig, reportMessage: "" })
  }

  changeReportLanguage = (value) => {
    const { reportInfo } = this.props;
    let _reportConfig = { ...(reportInfo.reportConfig || {}) };
    _reportConfig.language = value;
    this.props._updateReportInfo({ reportConfig: _reportConfig, reportMessage: "" })
  }


  downloadReport = () => {
    const { reportInfo, projectId } = this.props;
    const { selectedInterfaces } = this.state;
    let reportConfig = reportInfo.reportConfig || {};
    if (!selectedInterfaces || !selectedInterfaces.length) { return; }
    this.props._updateReportInfo({ reportVisible: true, reportProgress: 0, reportMessage: "" });

    const _config = reportConfig && Object.keys(reportConfig).length ? reportConfig : {
      projectId,
      channelIds: [],
      format: "PPTX",
      language: "english",
      templateId: "",
      fileName: ""
    };

    _config.projectId = projectId;
    _config.channelIds = [...selectedInterfaces];
    this.props._updateReportInfo({ reportConfig: _config });
    generateReport({ ..._config }).then(res => {
      this.props._getReport({
        projectId,
        format: _config.format
      });
    }, error => {
      this.props._updateReportInfo({
        reportVisible: false,
        reportMessage: `Download report in ${_config.format} format failed! ` + error
      });
    })
    this.props._saveReportConfig(projectId);
  }

  onMinimize = () => {
    this.props.closePanel();
    this.props._saveReportConfig();
  }

  closeModal = () => {
    const { reportInfo } = this.props;
    const { reportVisible } = reportInfo || {};
    if (reportVisible) {
      this.setState({
        closeVisible: true
      })
    } else {
      this.props._clearReportInfo();
      this.props.closePanel();
    }
  }

  render = () => {
    const { reportInfo = {} } = this.props;
    let reportConfig = reportInfo.reportConfig || {};
    const { reportProgress, reportVisible, reportMessage } = reportInfo || {};
    const { language = 'english', format = "PPTX" } = reportConfig;
    const { closeVisible, maxWidth, maxHeight, treeData, selectedInterfaces } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='himalayas-report-panel aurora-x-scroll-hidden'
          title={<div className='report-panel-title'>Report Generation Options</div>}
          onCancel={this.closeModal}
          onMinimize={this.onMinimize}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { minWidth: 270, defaultWidth: 700 })}
          minWidth={270}
          position='panel-center'
          draggable
          minHeight={200}
          maxHeight={maxHeight}
          minimizeVisible={reportVisible ? true : false}
          overflow={"auto"}
        >
          <div className='report-content clear'>
            <div>
              <label className="report-item-title">Interfaces</label>
              <TreeSelect
                treeData={treeData || []}
                value={selectedInterfaces || []}
                onChange={this.onTreeChange}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                placeholder='Please select'
                getPopupContainer={() => document.getElementById('root')}
                className='report-select aurora-select'
                popupClassName='himalayas-report-select-dropdown'
              />
            </div>
            <div>
              <label className="report-item-title">File Type</label>
              <Select
                value={format}
                onChange={(value) => this.changeReportType(value)}
                className='aurora-select report-select'
                popupClassName='report-select-dropdown'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option key="PPTX">PPTX</Option>
                <Option key="PDF">PDF</Option>
              </Select>
            </div>
            <div>
              <label className="report-item-title">Language</label>
              <Select
                value={language}
                onChange={(value) => this.changeReportLanguage(value)}
                className='aurora-select report-select'
                popupClassName='report-select-dropdown'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option key="english">English</Option>
                <Option key="japanese">Japanese</Option>
                {/* <Option key="chinese">Chinese</Option> */}
              </Select>
            </div>
            <ReportTemplate
              reportConfig={reportConfig}
              updateReportConfig={this.updateReportConfig}
              updateReoprtMessage={this.updateMessage}
              type="HIMALAYAS" >
            </ReportTemplate>
            {reportProgressRender({
              reportVisible,
              reportMessage,
              reportProgress,
              downloadReport: this.downloadReport,
              disabled: !selectedInterfaces || !selectedInterfaces.length
            })}
          </div>
        </Panel>
        {
          closeVisible ? <DelConfirm
            data={null}
            maskStyle={true}
            deleteConfirm={this.deleteConfirm}
            cancelDel={this.cancelDel}
            message={'After closing the panel, the download report will end. Are you sure you want to close it?'}
          /> : null
        }
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const { HimalayasReducer: { project: { openProjectId, reportInfo } } } = state;
  return {
    projectId: openProjectId,
    reportInfo
  }

}

const mapDispatch = (dispatch) => ({
  _updateReportInfo(info) {
    dispatch(updateReportInfo(info))
  },
  _clearReportInfo() {
    dispatch(clearReportInfo())
  },
  _saveReportConfig(projectId) {
    dispatch(saveReportConfig(projectId))
  },

  _getReport({ projectId, format, mime, fileName }) {
    dispatch(getReport({ projectId, format, mime, fileName }));
  }
})

export default connect(mapState, mapDispatch)(Report);