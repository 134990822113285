import { call, takeEvery, select, put, fork, delay, cancel, takeLatest } from '@redux-saga/core/effects';
import { message } from 'antd';
import {
  UPDATE_PROJECT_MENU,
  OPEN_PROJECT,
  OPEN_PAGE,
  CLOSE_PROJECT,
  DELETE_PCB,
  ADD_PROJECT,
  CREATE_PROJECT,
  DELETE_PROJECT,
  UPDATE_PAGE_LAYOUT,
  UPDATE_TRASH_LIST,
  CLEAR_TRASH,
  PCB_REPLACE,
  PROJECT_RENAME,
  COPY_PROJECT,
  CLEAR_CURRENT_PROJECT,
  SAVE_REPORT_CONFIG,
  GET_REPORT,
  CLEAR_REPORT_INFO,
  UPDATE_PCB_LAYOUT,
  SAVE_SERVICE_OPTION,
  GET_REPORT_CONFIG
} from './actionTypes';
import {
  getProjectList,
  getDefaultProjectTree,
  getProjectChildren,
  getDesignTree,
  updateProjectChild,
  deleteDesignInProject,
  deleteProjectByIds,
  createProjectPromise,
  getSetupPage,
  getPCBPage,
  getProjectTrash,
  clearProjectTrash,
  AndesV2ProjectRename,
  copyProject,
  getProjectTree,
  updateProjectServiceConfig
} from '@/services/Andes_v2/project';
import { PROJECTS_INDEX, TRASH_INDEX, getPCBPageInfo } from '@/services/Andes_v2';
import { ANDES_V2_PROJECT_VERSION } from '@/services/Andes_v2/constants';
import {
  PROJECT,
  PCB,
  PROJECTS,
  PROJECT_CREATE,
  PCB_CHANNEL,
  PCB_PRE_LAYOUT,
  PCB_CHANNEL_RESULT,
  END_TO_END_CHANNEL,
  MY_TRASH,
  END_TO_END_CHANNEL_RESULT,
  PACKAGE,
  EXPERIMENTS,
  EXPERIMENTS_RESULT
} from '@/constants/treeConstants';
import {
  updateExpand,
  updateTreeList,
  saveOpenProjectInfo,
  openPageInfo,
  clearCurrentProject,
  updateProjectMenu,
  updateSelectKeys,
  updateTrash,
  updatePCBComponentsNets,
  refreshPCB,
  changeCopyLoadingId,
  updateReportInfo,
  updateViewList,
} from './action';
import projectConstructor from '@/services/Andes_v2/project/projectConstructor';
import designConstructor from '@/services/helper/designConstructor';
import { getPreLayoutContent, clearPreLayoutContent } from '../preLayout/action';
import { savePreLayoutContentToServer } from '../preLayout/preLayoutSaga';
import { changeTabMenu, closeTabFooter, openTabFooter } from '../../../MonitorStore/action';
import { cleanStatus, updatePCBRefreshStatus } from '../../../LayoutExplorer/store/Andes_v2/actionCreators';
import { getDefaultName } from '@/services/helper/setDefaultName';
import { SINGLE_PAGE_LAYOUT } from '@/constants/layoutConstants';
import { filterKeys, getPageKeyList, updateTreeSelectKeys } from '@/services/helper/filterHelper';
import { openPCBChannel, clearPCBChannelInfo, channelReplace, forcePreviewPanelShow, updateHybridStatus } from '../channel/action';
import designChannelsConstructor from '@/services/Andes_v2/helper/designChannelConstructor';
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import { PRE_LAYOUT } from '@/constants/designVendor';
import { strDelimited } from '@/services/helper/split';
import { checkEndToEndByPCBChannel, clearEndToEndChannelInfo, autoGetEndToEndList, openEndToEndChannel } from '../endToEndChannel/action';
import { ANDES_V2 } from '@/constants/pageType';
import { saveChannelContentToServer, getDesignChannels } from '../channel/channelSaga';
import { saveEndToEndChannelContentToServer } from '../endToEndChannel/endToEndChannelSaga';
import { openPCBChannelResult, openEndToEndChannelResult, openExperimentResult } from '../result/action';
import { getLayoutDBInfo } from "@/services/PCBHelper";
import DesignInfo from '@/services/Andes_v2/pcbInfo';
import LayoutData from '@/services/data/LayoutData';
import endToEndChannelConstructor from '@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor';
import projectEndToEndConstructor from '@/services/Andes_v2/project/projectEndToEndConstructor';
import projectDesigns from '@/services/helper/projectDesigns';
import { isEndToEndChildrenChannel } from '../../../../services/Andes_v2';
import { cleanUploadPCBStatus } from '../../AndesSider/uploadPCB/store/action';
import { saveReportConfigPromise, getReportStatus, getReportData, getChannelReportConfig, getChannelContentPromise } from '@/services/Andes_v2/channel';
import FileSaver from 'file-saver';
import { getBlob } from '@/services/helper/downloadHelper';
import { eyeDiagramResult } from '@/services/Andes_v2/results/eyeDiagram';
import { getViewListBySelectedKey, updateTreeAfterChangePCBLayout } from '../../../../services/helper/filterHelper';
import { PCB_TOP_BOTTOM, PCB_LEFT_RIGHT, PCB_ONLY } from '../../../../constants/layoutConstants';
import { getReportGuideKeys, getReportGuideValue } from '@/services/helper/reportHelper';
import { getVerificationJson } from '@/services/Andes_v2/results';
import { openExperiment } from '../sweep/action';
import sweepConstructor from '../../../../services/Andes_v2/sweep/sweepConstructor';
import { CPHY } from '../../../../services/PCBHelper/constants';
import { getCPHYDefaultConfig } from '../../../../services/helper/reportHelper';

let autoGetProjectTask = null;
function* _updateProjectList(action) {
  const { data } = action;
  const firstLoad = data ? data.firstLoad : false;
  let res = null;
  try {
    res = yield call(getProjectList);
  } catch (error) {
    console.error(error);
  }

  if (!res) {
    return;
  }
  const { AndesV2Reducer: { project } } = yield select();
  let { treeItems } = project;
  // treeItems[PROJECTS_INDEX] - 'projects' ,PROJECTS_INDEX = 1;
  const { projects, copyingProjectList } = getDefaultProjectTree(res);
  treeItems[PROJECTS_INDEX].children = projects;
  yield put(updateTreeList({ treeItems: [...treeItems] }));
  //if copying project is exist, auto get project list
  if (copyingProjectList.length) {
    autoGetProjectTask = yield fork(autoGetProjectList);
  }
  if (firstLoad) {
    //clear prev project list
    projectConstructor.clearCache();
    //save project to cache
    res.forEach(item => {
      projectConstructor.addProject(item.id, item);
    });
  }

  //open current project
  if (data && data.openProjectId) {
    yield call(openProjectByID, { id: data.openProjectId });
  }
}

function* autoGetProjectList() {
  let first = true, delayTime = 1000;
  if (autoGetProjectTask) {
    yield cancel(autoGetProjectTask);
  }
  let prevCopyingProjectList = [];
  while (true) {

    yield delay(delayTime);//5000
    delayTime = 6000;
    let res = null;
    try {
      res = yield call(getProjectList);
    } catch (error) {
      console.error(error);
    }

    if (!res) {
      continue;
    }
    const { LoginReducer: { pageType }, AndesV2Reducer: { project } } = yield select();
    if (pageType !== ANDES_V2) {
      break;
    }
    let treeItems = [...project.treeItems];
    // treeItems[PROJECTS_INDEX] - 'projects' ,PROJECTS_INDEX = 1;
    const { projects, copyingProjectList } = getProjectTree(res, treeItems[PROJECTS_INDEX].children);
    treeItems[PROJECTS_INDEX].children = projects;
    yield put(updateTreeList({ treeItems: [...treeItems] }));
    if (first) {
      first = false;
      prevCopyingProjectList = JSON.parse(JSON.stringify(copyingProjectList))
    }

    if (!copyingProjectList.length) {
      //save project to cache
      res.forEach(item => {
        projectConstructor.addProject(item.id, item);
      });
      //open project
      const projectList = prevCopyingProjectList.length ? prevCopyingProjectList : res;
      if (projectList.find(item => item.id === project.openProjectId)) {
        yield call(openProjectByID, { id: project.openProjectId });
      }
      break;
    }
    prevCopyingProjectList = JSON.parse(JSON.stringify(copyingProjectList))
  }
}

export function* updateMonitorInfo({ selectedKeys }) {
  const { AndesV2Reducer: { project: { viewList }, channel: { channelId }, endToEndChannel: { endToEndChannelId }, sweep: { sweepId } } } = yield select();
  let tabInfo = {};

  //pcb channel, pcb channel result
  if (selectedKeys.includes(`${PCB_CHANNEL}-${channelId}`)) {
    const channel = channelConstructor.getChannel(channelId) || {};
    tabInfo = channel;
  }
  //end to end channel, end to end channel result
  if (selectedKeys.includes(`${END_TO_END_CHANNEL}-${endToEndChannelId}`)) {
    const endToEndChannel = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId) || {};
    tabInfo = endToEndChannel;
  }

  if (selectedKeys.includes(`${EXPERIMENTS}-${sweepId}`)) {
    const sweep = sweepConstructor.getSweep(sweepId) || {};
    tabInfo = sweep;
  }

  if (tabInfo && Object.keys(tabInfo).length) {
    if (viewList.includes(PCB_CHANNEL) || viewList.includes(END_TO_END_CHANNEL) || viewList.includes(EXPERIMENTS)) {
      yield put(openTabFooter());
    }
    let verificationName = tabInfo.name;
    if (isEndToEndChildrenChannel(tabInfo)) {
      verificationName = tabInfo.pcbName;
    }
    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: tabInfo.verificationId,
      verificationName,
      menuType: "simulation"
    }))
  } else {
    yield put(changeTabMenu({
      currentVerificationId: null,
      verificationName: null,
    }))
  }
}

function* openProjectByID(action) {
  const { id, afterUploadPCBId } = action;
  let _afterUploadPCBId = afterUploadPCBId;
  const { AndesV2Reducer: { project, AndesV2UploadReducer: { visible, uploadProjectId } } } = yield select();
  let { treeItems, viewList, selectedKeys, expandedKeys } = project;
  const key = `${PROJECT}-${id}`;
  //expanded keys update
  if (!expandedKeys.includes(key)) {
    //close other open project
    expandedKeys = filterKeys(expandedKeys, PROJECT, id)
    //expand current project
    expandedKeys.push(key);
    yield put(updateExpand(expandedKeys));
    //clear all eye diagram cache in prev expand project
    eyeDiagramResult.clearAllEyeDiagrams();
  }
  //clear all setup page
  yield call(clearSetupPageInfo, { viewList, clearList: [PCB_PRE_LAYOUT, PCB_CHANNEL, END_TO_END_CHANNEL] });
  //clear prev project cache
  if (!afterUploadPCBId) {
    clearProjectCache();
  }
  //get project children {designs}
  let res = null;
  try {
    res = yield call(getProjectChildren, id);
  } catch (error) {
    console.error(error);
  }
  let _treeItems = [...treeItems];
  // treeItems[PROJECTS_INDEX] - 'projects' ,PROJECTS_INDEX = 1;
  const findIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === id);
  if (findIndex < 0) {
    return;
  }

  let designs = [], packages = [];
  yield put(saveOpenProjectInfo({ id }));
  // Update designs
  if (res) {
    designs = getDesignTree(res.designs);
    packages = getDesignTree(res.packages);
  };
  _treeItems[PROJECTS_INDEX].children[findIndex].children = updateProjectChild(_treeItems[PROJECTS_INDEX].children[findIndex], { designs, packages, endToEndList: [] });
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
  //get current project end to end channel list
  yield put(autoGetEndToEndList({ projectId: id, openProject: true }));
  //save designs to cache and get channelList

  let info = {
    currentProjectId: id,
    projectName: _treeItems[PROJECTS_INDEX].children[findIndex].name,
  }
  if (visible && uploadProjectId === id) {
    info.menuType = 'simulation';
    info.tabSelectKeys = ["pcb"];
    _afterUploadPCBId = "upload";
    yield put(openTabFooter());
  }

  //update monitor box info
  yield put(changeTabMenu({ ...info }));

  const projectPcbs = res ? (res.designs || []) : [];
  const projectPackages = res ? (res.packages || []) : [];

  projectDesigns.set(id, [...projectPcbs, ...projectPackages]);
  //get package channel list
  yield call(getDesignChannels, { data: { designs: projectPackages, _treeItems, projectId: id, afterUploadPCBId: _afterUploadPCBId, addDesignToCache: true, addChannelToCache: true, designType: PACKAGE } });

  //get design channel list
  yield call(getDesignChannels, { data: { designs: projectPcbs, _treeItems, projectId: id, afterUploadPCBId: _afterUploadPCBId, addDesignToCache: true, addChannelToCache: true, designType: PCB } });

  //update open channel or end to end channel monitor info
  yield call(updateMonitorInfo, { selectedKeys });
}

function clearProjectCache() {
  designChannelsConstructor.clearCache();
  channelConstructor.clearCache();
  projectEndToEndConstructor.clearCache();
  endToEndChannelConstructor.clearCache();
  sweepConstructor.clearCache();
}

function* _openAndesPage(action) {
  const { pageType, id, simulating, firstCreate } = action;
  const { AndesV2Reducer: { project: { layout, selectedKeys, pcbLayout, viewList: preViewList } } } = yield select();
  let _selectedKeys = [...selectedKeys], _pageType = pageType;

  switch (pageType) {
    case PCB:
    case PCB_PRE_LAYOUT:
    case PACKAGE:
      yield call(openPCB, { id, pageType });
      if (layout === SINGLE_PAGE_LAYOUT) {
        yield put(closeTabFooter());
      }
      break;
    case PCB_CHANNEL:
      yield call(expandPCB, id);
      yield put(openPCBChannel(id, simulating));
      // update result page id
      yield put(openPCBChannelResult(id, true));
      break;
    case END_TO_END_CHANNEL:
      yield put(openEndToEndChannel(id, simulating));
      // update result page id
      yield put(openEndToEndChannelResult(id, true));
      break;
    case END_TO_END_CHANNEL_RESULT:
      yield put(openEndToEndChannelResult(id));
      yield put(closeTabFooter());
      _pageType = END_TO_END_CHANNEL;
      break;
    case PCB_CHANNEL_RESULT:
      yield put(openPCBChannelResult(id));
      yield put(closeTabFooter());
      _pageType = PCB_CHANNEL;
      break;
    case EXPERIMENTS:
      yield put(openExperiment(id, firstCreate));
      break;
    // yield put(closeTabFooter());
    case EXPERIMENTS_RESULT:
      yield put(openExperimentResult(id));
      yield put(closeTabFooter());
      _pageType = EXPERIMENTS;
      break;
    default: break;
  }

  //if selectedKeys not includes key,update selectedKeys
  if (!selectedKeys.includes(`${_pageType}-${id}`)) {
    _selectedKeys = updateTreeSelectKeys({
      prevSelectedKeys: [...selectedKeys],
      key: _pageType,
      eventKey: `${_pageType}-${id}`,
      selected: true,
      layout,
      pcbLayout,
      productType: ANDES_V2
    });
    yield put(updateSelectKeys(_selectedKeys));
  }

  if (_pageType !== pageType) {
    // update viewList
    let _viewList = getViewListBySelectedKey(_selectedKeys);
    const index = _viewList.findIndex(v => v === _pageType);
    if (index > -1) {
      _viewList[index] = pageType;
      yield put(updateViewList(_viewList));
    }
  } else {
    let _viewList = getViewListBySelectedKey(_selectedKeys);
    if (_pageType !== PCB_CHANNEL && _viewList.includes(PCB_CHANNEL) && preViewList.includes(PCB_CHANNEL_RESULT)) {
      const index = _viewList.findIndex(v => v === PCB_CHANNEL);
      _viewList[index] = PCB_CHANNEL_RESULT;
      yield put(updateViewList(_viewList));
    } else if (_pageType !== END_TO_END_CHANNEL && _viewList.includes(END_TO_END_CHANNEL) && preViewList.includes(END_TO_END_CHANNEL_RESULT)) {
      const index = _viewList.findIndex(v => v === END_TO_END_CHANNEL);
      _viewList[index] = END_TO_END_CHANNEL_RESULT;
      yield put(updateViewList(_viewList));
    } else if (_pageType !== EXPERIMENTS && _viewList.includes(EXPERIMENTS) && preViewList.includes(EXPERIMENTS_RESULT)) {
      const index = _viewList.findIndex(v => v === EXPERIMENTS);
      _viewList[index] = EXPERIMENTS_RESULT;
      yield put(updateViewList(_viewList));
    } else {
      yield put(updateViewList(_viewList));
    }
  }

  const { AndesV2Reducer: { project: { viewList } } } = yield select();
  //Clear other open setup page
  yield call(clearSetupPageInfo, { viewList, clearList: [PCB_PRE_LAYOUT, PCB_CHANNEL, END_TO_END_CHANNEL] });
}

export function* expandPCB({ channelId, designId }) {
  const { AndesV2Reducer: { project: { expandedKeys } } } = yield select();

  let design = null;
  if (designId) {
    design = designConstructor.getDesign(designId);
  } else if (channelId) {
    const channel = channelConstructor.getChannel(channelId) || {};
    design = designConstructor.getDesign(channel.designId);
  }

  if (!design) {
    return;
  }

  const designType = design.vendor === PRE_LAYOUT ? PCB_PRE_LAYOUT : PCB;
  if (!expandedKeys.includes(`${designType}-${design.id}`)) {
    yield put(updateExpand([...expandedKeys, `${designType}-${design.id}`]));
  }
}

function* clearSetupPageInfo(action) {
  const { clearList, viewList } = action;

  if (clearList.includes(PCB_PRE_LAYOUT) && !viewList.includes(PCB_PRE_LAYOUT)) {
    //save prev design content (pre_layout)
    yield call(savePreLayoutContentToServer);
    //clear prev design content (pre_layout)
    yield put(clearPreLayoutContent());
  }

  if (clearList.includes(PCB_CHANNEL) && !viewList.includes(PCB_CHANNEL)) {
    //save prev pcb channel setup and clear reducer channel info
    yield call(saveChannelContentToServer);
    yield put(clearPCBChannelInfo());
  }

  if (clearList.includes(END_TO_END_CHANNEL) && !viewList.includes(END_TO_END_CHANNEL)) {
    //save prev end to end channel setup and clear reducer  end to end channel info
    yield call(saveEndToEndChannelContentToServer);
    yield put(clearEndToEndChannelInfo())
  }

  return;
}

function* openPCB(action) {
  const { id, pageType } = action;
  if (pageType === PCB_PRE_LAYOUT) {
    yield put(getPreLayoutContent({ id }));
  }
  yield put(openPageInfo({ designID: id }));
}

function* _closeCurrentProject() {
  //clear current / prev project info & Clean explorer status
  yield put(clearCurrentProject());
  //close monitor box
  yield put(closeTabFooter());
  //Clear all open setup page
  yield call(clearSetupPageInfo, { viewList: [], clearList: [PCB_PRE_LAYOUT, PCB_CHANNEL, END_TO_END_CHANNEL] });
}

function _updateSelectedKeys(selectedKeys, filterKey, id) {
  let _selectedKeys = [...selectedKeys];
  const channelsID = designChannelsConstructor.getChannelsByDesignId(id);
  _selectedKeys = _selectedKeys.filter(key => ![`${filterKey}-${id}`, ...channelsID.map(_id => `${PCB_CHANNEL}-${_id}`)].includes(key));
  return _selectedKeys;
}

function* _delPCB(action) {
  const { data: { id, key } } = action;
  const { AndesV2Reducer: { project: { openProjectId, viewList, selectedKeys }, channel: { channelInfo } } } = yield select();
  try {
    yield call(deleteDesignInProject, [id]);
  } catch (error) {
    console.error(error);
  }
  //clean pcb state
  yield put(cleanStatus(id));

  const filterKey = key.split('-')[0];
  // Update viewList
  let _viewList = [...viewList];
  // Update selected keys
  const newSelectedKeys = _updateSelectedKeys(selectedKeys, filterKey, id);
  yield put(updateSelectKeys(newSelectedKeys));
  if (!getViewListBySelectedKey(newSelectedKeys).includes(filterKey)) {
    // clean PCB viewList
    _viewList = viewList.filter(item => item !== filterKey);
  }
  if (!getViewListBySelectedKey(newSelectedKeys).includes(PCB_CHANNEL)) {
    // clean Setup/Result viewList
    _viewList = viewList.filter(item => item !== PCB_CHANNEL && item !== PCB_CHANNEL_RESULT);
  }
  yield put(updateViewList(_viewList));

  //update open channel or end to end channel monitor info
  yield call(updateMonitorInfo, { selectedKeys });
  //close monitor box
  yield put(closeTabFooter());
  //clean pcb cache
  designConstructor.delDesign(id);
  designChannelsConstructor.delete(id);
  channelConstructor.deleteChannelByDesignId(id);
  sweepConstructor.delSweepsByDesignId(id);

  if (channelInfo && channelInfo.designId === id) {
    //clear design channel content
    yield put(clearPCBChannelInfo());
  }
  // update project menu list
  yield call(openProjectByID, { id: openProjectId });
  //check current open end to end channel setup by deleted PCB
  yield put(checkEndToEndByPCBChannel({ deletedDesignId: id }));
}

function* _addProjectInTree() {
  const { AndesV2Reducer: { project: { treeItems, expandedKeys } } } = yield select();
  let _treeItems = [...treeItems];

  if (!expandedKeys.includes(PROJECTS)) {
    //expand project tree
    yield put(updateExpand([...expandedKeys, PROJECTS]));
  }

  let dataTypes = _treeItems[PROJECTS_INDEX].children.map(item => item.dataType);

  //if has already create project box
  if (dataTypes.includes(PROJECT_CREATE)) {
    message.error('There are already projects being created.');
    return;
  }

  //set default project name
  const projectList = projectConstructor.getProjectValues();
  const name = getDefaultName({ nameList: projectList, defaultKey: "Project" });
  _treeItems[1].children.unshift(
    {
      id: name,
      name: name,
      key: name,
      dataType: PROJECT_CREATE,
      nodeClass: 'project-create-node'
    }
  );
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _createNewProject(action) {
  const { data: { name } } = action;
  const { AndesV2Reducer: { project: { openProjectId } } } = yield select();
  const info = {
    id: "",
    name,
    version: ANDES_V2_PROJECT_VERSION
  }
  try {
    const res = yield call(createProjectPromise, info);
    if (res) {
      //clean current open project info & Clean explorer status
      yield put(clearCurrentProject());
      //Refresh project trees and open project
      yield put(updateProjectMenu({ openProjectId: res.id }));
      //save current project to cache
      projectConstructor.addProject(res.id, res);
    } else {
      yield put(updateProjectMenu({ openProjectId }));
    }
  } catch (error) {
    console.error(error);
    yield put(updateProjectMenu({ openProjectId }));
  }
}

function* _delProject(action) {
  const { data: { id } } = action;
  const { AndesV2Reducer: { project: { openProjectId, expandedKeys }, AndesV2UploadReducer: { uploadProjectId } } } = yield select();
  try {
    yield call(deleteProjectByIds, [id]);
  } catch (error) {
    console.error(error);
    yield put(updateProjectMenu({ openProjectId }));
    return;
  }
  projectConstructor.delProject(id);
  projectDesigns.delete(id);
  if (openProjectId === id) {
    yield put(updateProjectMenu());
    //clean current open project info & Clean explorer status
    yield put(clearCurrentProject());
    //removed expanded keys
    yield put(updateExpand(expandedKeys.filter(item => item !== `${PROJECT}-${id}`)));
    //removed selected keys
    yield put(updateSelectKeys([]));
    yield put(updateViewList([]));
  } else {
    yield put(updateProjectMenu({ openProjectId }));
  }
  if (id === uploadProjectId) {
    yield put(cleanUploadPCBStatus());
  }
  yield put(updateTrash());
}

function* _updateLayout(action) {
  const { layout, singleType } = action;
  if (layout === SINGLE_PAGE_LAYOUT) {
    yield call(layoutChangeSingle, { singleType })
  } else {
    yield call(layoutChange);
  }
}

function* layoutChange() {
  const { AndesV2Reducer: { project: { openProjectId, viewList, selectedKeys }, channel: { channelId }, channelResultReducer, sweep: { sweepId } } } = yield select();
  if (!openProjectId) {
    return;
  }
  let _selectedKeys = [...selectedKeys], pageType = null, id = null, _viewList = [...viewList];

  if (viewList.length === 1 || viewList.every(d => [PCB_PRE_LAYOUT, PACKAGE, PCB].includes(d))) {
    // There is only channel or PCB in the viewlist
    if (viewList.some(v => [END_TO_END_CHANNEL, PCB_CHANNEL_RESULT, PCB_CHANNEL, END_TO_END_CHANNEL_RESULT, EXPERIMENTS, EXPERIMENTS_RESULT].includes(v))) {
      // Open PCB
      const openInfo = getPCBPageInfo({ channelId, view: viewList[0], projectId: openProjectId, resultChannelId: channelResultReducer.channelId, sweepId })
      pageType = openInfo.pageType;
      id = openInfo.id;
    } else {
      // Open channel
      const reg = new RegExp([PCB_PRE_LAYOUT, PACKAGE, PCB].join('|'));
      const designIDs = selectedKeys.filter(s => s.match(reg)).map(d => d.split('-')[1]);
      for (let _id of designIDs) {
        const channels = designChannelsConstructor.getChannelsByDesignId(_id);
        if (!channels || !channels.length) {
          continue;
        }
        pageType = PCB_CHANNEL;
        id = channels[0];
        break;
      }
    }

    if (pageType && id) {
      //open pcb/channel page
      yield call(_openAndesPage, { pageType, id })
      //update tree selected keys
      const _filterKeys = getPageKeyList(pageType);
      _selectedKeys = _selectedKeys.filter(item => !_filterKeys.includes(strDelimited(item, "-", { returnIndex: 0 })));
      _selectedKeys.push(`${pageType}-${id}`);
      yield put(updateSelectKeys(_selectedKeys));
      _viewList.push(pageType);
      yield put(updateViewList([...new Set(_viewList)]));
    }
  } else {
    yield put(updateHybridStatus(false))
  }
}

function* layoutChangeSingle(action) {
  const { singleType } = action;
  const { AndesV2Reducer: { project: { openProjectId, viewList, selectedKeys } } } = yield select();
  const selectedId = selectedKeys && selectedKeys[0] ? selectedKeys[0].replace(/[^0-9]/ig, "") : '';
  const channelDesignId = channelConstructor.getChannelDesignId(selectedId)
  const projectDesignID = channelDesignId ? channelDesignId : projectDesigns.getAvailableDesignsFirstId(openProjectId);
  if (!openProjectId) {
    return;
  }

  let _selectedKeys = [...selectedKeys], _filterKeys = [], pageType = null, id = null, _viewList = [...viewList];
  switch (singleType) {
    case "setup":
      const update = getSetupPage({
        _filterKeys,
        viewList,
        projectDesignID
      });
      _filterKeys = update._filterKeys;
      pageType = update.pageType;
      id = update.id;
      break;
    case "PCB":
      const _update = getPCBPage({
        _filterKeys,
        viewList,
        projectDesignID,
      });
      _filterKeys = _update._filterKeys;
      pageType = _update.pageType;
      id = _update.id;
      yield put(closeTabFooter());
      //force close extraction panel
      yield put(forcePreviewPanelShow(false));
      //clear hybrid status
      yield put(updateHybridStatus(false));
      break;
    default: break;
  }

  //update tree selected keys
  if (pageType && id) {
    //open page
    yield call(_openAndesPage, { pageType, id })
    //update tree selected keys
    _selectedKeys = [`${pageType}-${id}`];
    _viewList = [pageType];
  } else {
    _selectedKeys = _selectedKeys.filter(item => _filterKeys.includes(strDelimited(item, "-", { returnIndex: 0 })));
    _viewList = _viewList.filter(d => _filterKeys.includes(d));
  }
  yield put(updateSelectKeys(_selectedKeys));
  yield put(updateViewList(_viewList));
}

function* getTrash() {
  const { AndesV2Reducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems], res = null;
  try {
    res = yield call(getProjectTrash);
  } catch (error) {
    console.error(error);
  }
  _treeItems[TRASH_INDEX].children = [];
  if (res && res.length) {
    res.forEach(item => {
      _treeItems[TRASH_INDEX].children.push({
        name: item.name,
        key: `project-${item.id}`,
        id: item.id,
        ddrType: item.ddrType,
        dataType: MY_TRASH
      });
    });
  }
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _clearTrash() {
  const { AndesV2Reducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems];
  try {
    yield call(clearProjectTrash);
    _treeItems[TRASH_INDEX].children = [];
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
  } catch (error) {
    console.error(error);
  }
}

export function* getPCBInfo({ designId }) {
  //get pcb info
  try {
    const design = designConstructor.getDesign(designId);
    if (design && design.vendor !== PRE_LAYOUT) {
      const { AndesV2Reducer: { project } } = yield select();
      let pcbComponentsNets = [...project.pcbComponentsNets];
      const pcbInfo = yield call(getLayoutDBInfo, designId);
      DesignInfo.savePCBInfo(designId, pcbInfo);
      pcbComponentsNets = [...new Set([...pcbComponentsNets, designId])];
      yield put(updatePCBComponentsNets(pcbComponentsNets));
    }
  } catch (error) {
    console.error(error);
  }
}

function* _PCBReplace(action) {
  const { designId } = action;
  if (!designId) {
    return;
  }
  //clear prev design info
  LayoutData.cleanLayoutInfo(designId);
  //re load design info
  yield call(getPCBInfo, { designId });
  yield put(refreshPCB(designId));
  //clean pcb state
  yield put(cleanStatus(designId));
  yield put(updatePCBRefreshStatus(true, designId));

  //open page update
  //channel replace
  const { AndesV2Reducer: { channel: { channelInfo }, project: { viewList }, endToEndChannel: { endToEndChannelInfo } } } = yield select();
  if (viewList && viewList.includes(PCB_CHANNEL) && channelInfo && channelInfo.id) {
    yield put(channelReplace({ channelInfo, isUpdate: true }));
  }
  //Update end to end channel
  if (viewList && viewList.includes(END_TO_END_CHANNEL) && endToEndChannelInfo && endToEndChannelInfo.id) {
    yield put(checkEndToEndByPCBChannel({ replaceDesignId: designId }));
  }
}

function* _renameProject(action) {
  const { itemData } = action;
  const { AndesV2Reducer: { project: { openProjectId } } } = yield select();
  if (itemData && itemData.id && itemData.name) {
    try {
      const res = yield call(AndesV2ProjectRename, {
        projectId: itemData.id,
        projectName: itemData.name
      });
      //save current project to cache
      projectConstructor.addProject(res.id, res);
      yield put(updateProjectMenu({ openProjectId }));
    } catch (error) {
      console.error(error);
    }
  }
}

function* _projectCopy(action) {
  const { name, projectId } = action;

  yield put(changeCopyLoadingId({ copyProjectLoadingId: projectId }));
  try {
    const res = yield call(copyProject, name, projectId);
    if (!res || !res.id) {
      message.error("Copy project failed!");
      yield put(changeCopyLoadingId({ copyProjectLoadingId: null }));
      return;
    }
    yield call(_closeCurrentProject);
    yield put(updateProjectMenu({ openProjectId: res.id }));
    projectConstructor.addProject(res.id, res);
  } catch (error) {
    message.error("Copy project failed!");
  }

  yield put(changeCopyLoadingId({ copyProjectLoadingId: null }));
}

function* cleanCurrentProject() {
  yield put(cleanStatus('all'))
}

function* _saveReportConfig(action) {
  const { channelId, isMultiPCB } = action;//channelId , endToEndChannelId
  const { AndesV2Reducer: { project: { openProjectId, reportInfo } } } = yield select();
  let config = reportInfo ? reportInfo.reportConfig : null;
  if (!config || Object.keys(config).length === 0) {
    config = {
      channelIds: [channelId],
      language: 'english',
      templateId: "",
      format: "PPTX",
      fileName: "",
      isMultiPCB: isMultiPCB || false
    };
  } else {
    config.custom.guideValue = config.custom.guideValue.map(item => {
      let _item = { ...item };
      // If xDynamicRange is present, it has been modified, and we need to check whether it is null
      if (_item.xDynamicRange && _item.xDynamicRange.length) {
        if (!Math.abs(Number(_item.xDynamicRange[0])) && !Math.abs(Number(_item.xDynamicRange[1]))) {
          _item.xMax = null;
          _item.xMin = null;
        } else {
          _item.xMin = Number(_item.xDynamicRange[0]);
          _item.xMax = Number(_item.xDynamicRange[1]);
        }
        delete _item.xDynamicRange;
      } else {
        if (!Math.abs(Number(_item.xMax)) && !Math.abs(Number(_item.xMin))) {
          _item.xMax = null;
          _item.xMin = null;
        }
      }

      if (_item.yDynamicRange && _item.yDynamicRange.length) {
        if (!Math.abs(Number(_item.yDynamicRange[0])) && !Math.abs(Number(_item.yDynamicRange[1]))) {
          _item.yMax = null;
          _item.yMin = null;
        } else {
          _item.yMin = Number(_item.yDynamicRange[0]);
          _item.yMax = Number(_item.yDynamicRange[1]);
        }
        delete _item.yDynamicRange;
      } else {
        if (!Math.abs(Number(_item.yMax)) && !Math.abs(Number(_item.yMin))) {
          _item.yMax = null;
          _item.yMin = null;
        }
      }
      delete _item.defaultDataRate;
      return _item;
    })
  }

  // save report config to server
  try {
    yield call(saveReportConfigPromise, { projectId: openProjectId, channelId, config });
  } catch (error) {
    console.error(error);
  }
}

function* _getReportConfig(action) {
  const { channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType } = action;
  yield put(updateReportInfo({ reportConfigLoading: true }));
  const designs = designConstructor.getDesignValues(projectId).map(item => { return { ...item, children: channelConstructor.getChannelValues(item.id) } });
  const endToEndChannelList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
  if ((!designs.length || !designs.find(item => item.children && item.children.length)) && !endToEndChannelList.length) {
    return;
  }
  let reportId = channelId, isMultiPCB = false;
  if (isEndToEnd && endToEndChannelId) {
    reportId = endToEndChannelId;
    isMultiPCB = true;
  }
  if (!reportId) {
    const design = designs.find(item => item.children && item.children.length);
    reportId = design && design.children && design.children[0] ? design.children[0].id : null;
    isMultiPCB = false;
  }
  const complianceInfo = yield call(getVerificationJson, reportId, isEndToEnd, "compliance");
  if (interfaceType === CPHY) {
    let config = yield call(getChannelReportConfig, { projectId, channelId: reportId });
    const key = 'andes_v2_CPHY';
    const defaultGuideKey = getReportGuideKeys(key);

    let components = complianceInfo && complianceInfo.content ? complianceInfo.content.components : [];
    let signals = complianceInfo && complianceInfo.content ? complianceInfo.content.signals : [];
    if (!complianceInfo || !complianceInfo.content) {
      // get channel configs
      const channelConfig = !isMultiPCB ? yield call(getChannelContentPromise, channelId) : null;
      components = channelConfig && channelConfig.content ? channelConfig.content.components : []
      signals = channelConfig && channelConfig.content ? channelConfig.content.signals : []
    }

    const defaultConfig = getCPHYDefaultConfig(components, signals);
    const defaultGuideValue = getReportGuideValue(key, defaultGuideKey, { interfaceType, ...defaultConfig });
    if (!config || Object.keys(config).length === 0) {
      config = {
        channelIds: [reportId],
        language: 'english',
        templateId: "",
        format: "pptx",
        fileName: "",
        custom: {
          ...defaultConfig,
          guideValue: defaultGuideValue,
          isXTable: false
        },
        isMultiPCB
      };
    } else if (!config.custom || !config.custom.guideValue || !config.custom.guideValue.length) {
      config.custom = {
        ...defaultConfig,
        guideValue: defaultGuideValue,
        isXTable: false
      }
    } else if (!(config.custom.driver || []).length && !(config.custom.receiver || []).length) { // driver and receiver is empty array
      config.custom.driver = defaultConfig.driver;
      config.custom.receiver = defaultConfig.receiver;
    }

    let _guideValue = [];
    if (interfaceType) {
      _guideValue = config.custom.guideValue.map((item) => {
        return {
          ...item,
          points: item.points ? item.points : [],
          driverPoints: item.driverPoints ? item.driverPoints : [],
          receiverPoints: item.receiverPoints ? item.receiverPoints : [],
          PCIeType: interfaceType,
        }
      })
    } else {
      _guideValue = config.custom.guideValue.map((item) => {
        return {
          ...item,
          points: item.points ? item.points : [],
          driverPoints: item.driverPoints ? item.driverPoints : [],
          receiverPoints: item.receiverPoints ? item.receiverPoints : [],
          xDynamicRange: Math.abs(Number(item.xMax)) || Math.abs(Number(item.xMin)) ? [item.xMin, item.xMax] : [],
          yDynamicRange: Math.abs(Number(item.yMax)) || Math.abs(Number(item.yMin)) ? [item.yMin, item.yMax] : []
        }
      })
    }
    yield put(updateReportInfo({ reportConfig: { ...config, custom: { ...config.custom, guideValue: _guideValue } }, reportConfigLoading: false }));
  } else {
    let defaultDataRate = "";
    if (complianceInfo && complianceInfo.config && complianceInfo.config.analysis && complianceInfo.config.analysis.options) {
      // comDataRate = complianceInfo.config.analysis.options.datarate.replace('GT/s', '');
      defaultDataRate = complianceInfo.config.analysis.options.datarate.replace('GT/s', '');
    };
    if (!defaultDataRate) {
      defaultDataRate = dataRate;
    }

    // if (!comDataRate && !dataRate) {
    //   const setupInfo = yield call(getVerificationJson, reportId, isEndToEnd);
    //   if (setupInfo && setupInfo.config && setupInfo.config.analysis && setupInfo.config.analysis.options) {
    //     defaultDataRate = setupInfo.config.analysis.options.datarate;
    //   };
    // }

    let config = yield call(getChannelReportConfig, { projectId, channelId: reportId });
    const key = 'andes_v2';
    const defaultGuideKey = getReportGuideKeys(key)
    const defaultGuideValue = getReportGuideValue(key, defaultGuideKey, { dataRate: defaultDataRate, interfaceType })
    if (!config || Object.keys(config).length === 0) {
      config = {
        channelIds: [reportId],
        language: 'english',
        templateId: "",
        format: "pptx",
        fileName: "",
        custom: {
          guideValue: defaultGuideValue,
          isXTable: false
        },
        isMultiPCB
      };
    } else if (!config.custom || !config.custom.guideValue || !config.custom.guideValue.length) {
      config.custom = {
        guideValue: defaultGuideValue,
        isXTable: false
      }
    }

    let _guideValue = [];
    if (interfaceType) {
      _guideValue = config.custom.guideValue.map((item) => {
        return {
          ...item,
          defaultDataRate,
          points: item.points ? item.points : [],
          dataRate: item.dataRate || defaultDataRate,
          PCIeType: interfaceType
        }
      })
    } else {
      _guideValue = config.custom.guideValue.map((item) => {
        return {
          ...item,
          defaultDataRate,
          points: item.points ? item.points : [],
          dataRate: item.dataRate || defaultDataRate,
          xDynamicRange: Math.abs(Number(item.xMax)) || Math.abs(Number(item.xMin)) ? [item.xMin, item.xMax] : [],
          yDynamicRange: Math.abs(Number(item.yMax)) || Math.abs(Number(item.yMin)) ? [item.yMin, item.yMax] : []
        }
      })
    }
    yield put(updateReportInfo({ reportConfig: { ...config, custom: { ...config.custom, guideValue: _guideValue } }, reportConfigLoading: false }));
  }
}

let reportTask = null;
function* _getReport(action) {
  const { projectId, channelId, format, mime, fileName } = action;
  reportTask = yield fork(_getRockyReportFlow, { projectId, channelId, format, mime, fileName });
}

function* _getRockyReportFlow(action) {
  const { projectId, channelId, format, mime, fileName } = action;
  let status = 'running';
  let progress = 0;
  while (status === 'running') {
    yield delay(3000);
    try {
      const res = yield call(getReportStatus, { projectId, channelId });
      status = res.status;
      progress = res.progress;
      if (progress <= 97) {
        yield put(updateReportInfo({ reportProgress: progress }));
      }
    } catch (error) {
      status = 'failed';
    }
  }

  yield put(updateReportInfo({ reportProgress: 98 }));
  yield call(_getReportFileByFormat, { projectId, channelId, format, mime, fileName });
}

function* _getReportFileByFormat(action) {
  const { projectId, channelId, format, mime, fileName } = action;
  const url = yield call(getReportData, { projectId, channelId, format, mime });
  if (url) {
    const blob = yield call(getBlob, url);
    const { AndesV2Reducer: { project: { openProjectId } } } = yield select();
    if (openProjectId !== projectId) {//open other project
      return;
    }
    if (blob) {
      FileSaver.saveAs(blob, fileName);
    }
    yield put(updateReportInfo({
      reportProgress: 100,
      reportMessage: `Download report in ${format.toUpperCase()} format successfully!`
    }));

  } else {
    yield put(updateReportInfo({
      reportMessage: `Download report in ${format.toUpperCase()} format failed!`
    }));
  }
  yield delay(1000);
  yield put(updateReportInfo({ reportVisible: false, reportProgress: 0 }));
}

function* _clearProjectReportInfo() {
  //cancel prev report task
  if (reportTask) {
    yield cancel(reportTask);
  }
}

function* _updatePCBLayout(action) {
  const { pcbLayout, prePcbLayout } = action;
  // prePcbLayout b,c -> a: close PCB
  const { AndesV2Reducer: { project } } = yield select();
  let { viewList, selectedKeys } = project;
  if (pcbLayout === PCB_ONLY && [PCB_TOP_BOTTOM, PCB_LEFT_RIGHT].includes(prePcbLayout)) {
    const data = updateTreeAfterChangePCBLayout(selectedKeys, viewList);
    if (data) {
      const { _selectedKeys, _viewList } = data;
      yield put(updateSelectKeys(_selectedKeys));
      yield put(updateViewList(_viewList));
    }
  }
}

function* _saveServiceOption() {
  const { AndesV2Reducer: { project: { openProjectId, serviceOptions } } } = yield select();
  // save
  try {
    yield call(updateProjectServiceConfig, { projectId: openProjectId, options: serviceOptions });
  } catch (error) {
    message.error('Update service options failed! ' + error)
    return;
  }
}

function* projectSaga() {
  yield takeEvery(UPDATE_PROJECT_MENU, _updateProjectList);
  yield takeEvery(OPEN_PROJECT, openProjectByID);
  yield takeEvery(OPEN_PAGE, _openAndesPage);
  yield takeEvery(CLOSE_PROJECT, _closeCurrentProject);
  yield takeEvery(DELETE_PCB, _delPCB);
  yield takeEvery(ADD_PROJECT, _addProjectInTree);
  yield takeEvery(CREATE_PROJECT, _createNewProject);
  yield takeEvery(DELETE_PROJECT, _delProject);
  yield takeLatest(UPDATE_PAGE_LAYOUT, _updateLayout);
  yield takeEvery(UPDATE_TRASH_LIST, getTrash);
  yield takeEvery(CLEAR_TRASH, _clearTrash);
  yield takeEvery(PCB_REPLACE, _PCBReplace);
  yield takeEvery(PROJECT_RENAME, _renameProject);
  yield takeEvery(COPY_PROJECT, _projectCopy);
  yield takeEvery(CLEAR_CURRENT_PROJECT, cleanCurrentProject);
  yield takeEvery(SAVE_REPORT_CONFIG, _saveReportConfig);
  yield takeEvery(GET_REPORT_CONFIG, _getReportConfig);
  yield takeEvery(GET_REPORT, _getReport);
  yield takeEvery(CLEAR_REPORT_INFO, _clearProjectReportInfo);
  yield takeLatest(UPDATE_PCB_LAYOUT, _updatePCBLayout);
  yield takeEvery(SAVE_SERVICE_OPTION, _saveServiceOption);
}

export default projectSaga;