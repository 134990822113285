import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Select, Button, Checkbox, Progress, Cascader } from "antd";
import Panel from '@/components/Panel';
import { getSierraDebugDownloadList, AuroraDB, PcbDoc, ANF, alg, AAF, log, brd } from '@/services/helper/debugHelper';
import { getDebugDownloadProgress } from '@/services/helper/downloadHelper';
import { VERIFY_SUCCESS, VERIFY_FAILED, VERIFY_CANCEL, VERIFY_RUNNING } from '../../../../../constants/verificationStatus';
import { getExperimentDebugOption, getSierraDebugItemList } from '@/services/Sierra'
import { getVerificationJson } from '@/services/Sierra/results';
import projectDesigns from '@/services/helper/projectDesigns';
import { DESIGN_FAILED } from '@/constants/designCategory';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { DEBUG_FAILED, DEBUG_SUCCESS, getDebugMessage, REPORT, PCB_PARSING, SIMULATION_DEBUG, getDebugCheckValues, getLayoutDownloadSimulationUrl } from '../../../../../services/helper/debugHelper';
import projects from '../../../../../services/Sierra/data/projects';
import dayjs from 'dayjs';
import { downloadFileByChunk, getDownloadFileIdForPost, getDownloadFileSize } from '../../../../../services/helper/downloadHelper';
import projectVerifications from '../../../../../services/Sierra/data/projectVerifications';
import { VERIFICATION_GROUP } from '../../../../../constants/treeConstants';
import './index.css';


const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
class DownloadPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuType: PCB_PARSING,
      currentCheckValues: [],
      downloadList: [],
      PCBCurrentItem: null,
      simulationCurrentItem: null,
      PCBMessage: { status: '', message: '' },
      simulationMessage: { status: '', message: '' },
      interfaceList: [],
      selectCascader: [],
      PCBProgress: -1,
      simulationProgress: -1,
      maxHeight: 500,
      maxWidth: 500,
      reportProgress: -1,
      reportMessage: { status: '', message: '' },
      checkExpandKeys: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 500) - 80,
      maxWidth: getPanelMaxWidth(offset, 500)
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    // init a PCB list
    this.updatePCBList(null);
    const { currentVerificationId, currentProjectVerifications, currentProjectId, isSweep, sweepId } = this.props;

    try {
      const res = await getSierraDebugItemList(currentProjectId);
      let item = null;
      let interfaceList = [], selectCascader = [];
      if (res) {
        for (let Interface of res.interface) {
          if (!isSweep && currentVerificationId && Interface.id === currentVerificationId) {
            item = currentProjectVerifications.find(item => item.id === currentVerificationId) || null;
            selectCascader = [Interface.id]
          }
          let sweepChildren = [];
          if (Interface.children && Interface.children.length) {
            for (let Sweep of Interface.children) {
              if (isSweep && sweepId && Sweep.id === sweepId && Sweep.children && Sweep.children.length) {
                item = { name: Sweep.children[0].name, id: Sweep.children[0].id, label: `${Interface.name}_${Sweep.name}_${Sweep.children[0].name}` }
                selectCascader = [Interface.id, Sweep.id, Sweep.children[0].id]
              }
              sweepChildren.push({
                value: Sweep.id,
                label: Sweep.name,
                children: Sweep.children ? Sweep.children.map(child => ({ value: child.id, label: child.name })) : null
              })
            }
          }
          interfaceList.push({
            value: Interface.id,
            label: Interface.name,
            children: sweepChildren.length ? sweepChildren : null
          })
        }
      }

      if (!item && currentProjectVerifications[0]) {
        item = currentProjectVerifications[0];
        selectCascader = [currentProjectVerifications[0].id]
      }

      this.setState({
        interfaceList,
        simulationCurrentItem: item,
        selectCascader
      })
    } catch (error) {
      console.error(error)
    }
  }

  closeModal = () => {
    this.props.closeModal();
  }

  // According to PCB initialization list
  // id is the pcb id, clearMsg is to clear download message
  updatePCBList(id, clearMsg = true) {
    const { currentProjectId } = this.props;
    const currentProjectDesigns = projectDesigns.getDesigns(currentProjectId)
    const { menuType } = this.state;
    let pcb = id && Array.isArray(currentProjectDesigns) ? currentProjectDesigns.find(item => item.id === id || item.subId === id) : null;
    let vendor = null;
    if (pcb) {
      vendor = pcb.vendor;
    } else {
      if (currentProjectDesigns && currentProjectDesigns.length > 0) {
        pcb = currentProjectDesigns[0];
        vendor = pcb.vendor;
      }
    }
    const _downloadList = getSierraDebugDownloadList({ menuType, vendor });
    const _currentCheckValues = _downloadList.filter(item => item.default).map(item => { return { name: item.option } }) || [];
    this.setState((prevState) => {
      return {
        downloadList: _downloadList,
        currentCheckValues: _currentCheckValues,
        PCBCurrentItem: pcb,
        PCBMessage: clearMsg ? { status: '', message: '' } : prevState.PCBMessage
      }
    })
  }

  // According to simulation initialization list
  // id and clearMsg is same as above function
  updateSimulationList(id, clearMsg = true) {
    const { currentProjectVerifications, currentProjectId } = this.props;
    const { menuType } = this.state;
    const verification = id && Array.isArray(currentProjectVerifications) ? currentProjectVerifications.find(item => item.id === id || item.subId === id) : null;
    let extraction = false, result = false, hspice = false;
    if (verification && (verification.status === VERIFY_SUCCESS || verification.status === VERIFY_FAILED || verification.status === VERIFY_CANCEL || verification.status === VERIFY_RUNNING)) {
      getVerificationJson(verification.id, currentProjectId).then(res => {
        if (res && res.Interfaces && res.Interfaces.length > 0 && res.Interfaces[0].content) {
          result = true;
          for (let Interface of res.Interfaces) {
            if (Interface.content && Interface.content.channel && ["SIwave", "HFSS", "PowerSI"].includes(Interface.content.channel.type)) {
              extraction = true;
              break;
            }
          }
        }
        if (verification.status === VERIFY_RUNNING) { result = false }
        else if (res && res.Config && res.Config.simulate === 'HSPICE') {
          hspice = true;
        }
        this.updateSimulationDownloadOptions(getSierraDebugDownloadList({ menuType, extraction, result, hspice }), verification, clearMsg)
      })
    } else {
      this.updateSimulationDownloadOptions(getSierraDebugDownloadList({ menuType, extraction, result, hspice }), verification, clearMsg)
    }
  }

  updateReportList = () => {
    const { currentProjectId } = this.props;
    let checkExpandKeys = [];
    const downloadList = projectVerifications.getVerGroups(currentProjectId).map(item => {
      if (item.dataType === VERIFICATION_GROUP) {
        checkExpandKeys.push(item.id);
        return {
          option: item.id,
          name: item.name,
          children: item.children.map(child => ({ option: child.id, name: child.name }))
        }
      }
      return { option: item.id, name: item.name }
    });
    this.setState({
      downloadList,
      currentCheckValues: [],
      checkExpandKeys
    })
  }

  updateSimulationDownloadOptions(downloadList, verification, clearMsg) {
    const currentCheckValues = downloadList.filter(item => item.default).map(item => { return { name: item.option } }) || [];
    this.setState((prevState) => {
      return {
        downloadList,
        currentCheckValues,
        simulationCurrentItem: verification,
        simulationMessage: clearMsg ? { status: '', message: '' } : prevState.simulationMessage
      }
    })
  }

  updateExperimentList = async (currentItem = {}, clearMsg = true) => {
    const { id, name, label = '' } = currentItem;
    let extraction = false, result = false, hspice = false;
    let downloadList = [];
    try {
      const res = await getExperimentDebugOption(id)
      if (res) {
        result = res.result;
        extraction = res.extraction;
        hspice = res.hspice;
      }
      downloadList = getSierraDebugDownloadList({ menuType: SIMULATION_DEBUG, extraction, result, hspice })
    } catch (error) {
      console.error(error);
      downloadList = getSierraDebugDownloadList({ menuType: SIMULATION_DEBUG, extraction, result, hspice })
    }
    const currentCheckValues = downloadList.filter(item => item.default).map(item => { return { name: item.option } }) || [];
    this.setState((prevState) => {
      return {
        downloadList,
        currentCheckValues,
        simulationCurrentItem: { id, name, label },
        simulationMessage: clearMsg ? { status: '', message: '' } : prevState.simulationMessage
      }
    })
  }

  // change select value
  changeDebugItem = (id) => {
    const { menuType } = this.state;
    switch (menuType) {
      case PCB_PARSING:
        this.updatePCBList(id);
        break;
      case SIMULATION_DEBUG:
        this.updateSimulationList(id);
        break;
      default: break;
    }
  }

  // change menu type
  changeDebugType = (type) => {
    const { PCBCurrentItem, simulationCurrentItem } = this.state;
    this.setState({
      menuType: type
    }, () => {
      const { menuType, selectCascader } = this.state;
      switch (menuType) {
        case PCB_PARSING:
          this.updatePCBList(PCBCurrentItem ? PCBCurrentItem.id : null, false);
          break;
        case SIMULATION_DEBUG:
          selectCascader.length > 1 ? this.updateExperimentList(simulationCurrentItem, false)
            : this.updateSimulationList(simulationCurrentItem ? simulationCurrentItem.id : null, false);
          break;
        case REPORT:
          this.updateReportList();
          break;
        default: break;
      }
    })
  }

  // change checkbox value
  changeDebugCheckList = (values, key) => {
    //prevValues, downloadAllList
    const { currentCheckValues, downloadList } = this.state;
    if (key) {
      let _currentCheckValues = [...currentCheckValues];
      const index = _currentCheckValues.findIndex(item => item.name === key);
      if (index < 0) {
        _currentCheckValues.push({ name: key, children: values })
      } else {
        if (!values.length) {
          delete _currentCheckValues[index];
        } else {
          _currentCheckValues[index].children = values;
        }
      }

      this.setState({
        currentCheckValues: _currentCheckValues
      })
      return;
    }

    this.setState({
      currentCheckValues: getDebugCheckValues(values, currentCheckValues, downloadList)
    })
  }

  changeSimulationItem = (values) => {
    if (!values || !values.length) return;

    let _values = [...values]

    const { interfaceList } = this.state;
    if (_values.length === 1) {
      this.updateSimulationList(_values[0]);
    } else if (_values.length > 1) {
      let findId = _values[2], label = '';
      const sweepItem = interfaceList.find(item => item.value === _values[0]);
      if (sweepItem && sweepItem.children) {
        label = `${sweepItem.label}`
        const experimentItem = sweepItem.children.find(item => item.value === _values[1]);
        if (experimentItem && experimentItem.children) {
          let name = '';
          if (!findId) {
            findId = experimentItem.children[0].value;
            _values.push(findId);
            name = experimentItem.children[0].label;
          } else {
            name = experimentItem.children.find(item => item.value === findId).label;
          }
          label = `${label}_${experimentItem.label}_${name}`
          this.updateExperimentList({ id: findId, name, label }, false)
        }
      }

    }
    this.setState({
      selectCascader: _values
    })
  }

  DownloadDebugClick = (e) => {
    const { currentCheckValues, PCBCurrentItem, simulationCurrentItem, selectCascader } = this.state;
    const token = localStorage.getItem('token');
    let options = [...currentCheckValues.map(item => {
      if (item.children) {
        return [...item.children]
      }
      return [item.name]

    }).flat(2)], downloadId = null, downloadName = 'design';
    const { menuType } = this.state;
    switch (menuType) {
      case PCB_PARSING:
        if (PCBCurrentItem) {
          downloadId = PCBCurrentItem.id;
          downloadName = PCBCurrentItem.name;
        }
        this.downloadPCBParsing(downloadId, downloadName, options, token);
        break;
      case SIMULATION_DEBUG:
        if (simulationCurrentItem) {
          downloadId = simulationCurrentItem.id;
          downloadName = selectCascader.length > 1 ?
            simulationCurrentItem.label ? simulationCurrentItem.label : simulationCurrentItem.name
            : simulationCurrentItem.name;
        }
        this.downloadSimulationParsing(downloadId, downloadName, options, token);
        break;
      case REPORT:
        this.downloadReport(options, token);
        break;
      default: break;
    }
  }

  downloadPCBParsing(designId, designName, options, token) {
    let url = '';
    if (options.includes('design')) {
      options = [log.option, AuroraDB.option, brd.option, alg.option, ANF.option, AAF.option, PcbDoc.option];
    }
    url = getLayoutDownloadSimulationUrl({ menuType: PCB_PARSING, designId, options, token });
    const fileName = `${designName}.zip`;
    const message = { success: 'Download PCB parsing zip file successfully!', error: 'Download PCB parsing zip file failed!' }
    this.downloadFile(url, 'PCBProgress', 'PCBMessage', fileName, message)
  }

  downloadSimulationParsing(verificationId, verificationName, options, token) {
    let url = '';
    const { selectCascader } = this.state;
    if (selectCascader.length > 1) {
      url = `/api/v3/sierra/sweeping/debug/download?experimentId=${verificationId}`;
      options.forEach(item => {
        url = `${url}&options=${item}`
      })
      url = `${url}&access_token=${token}`
    } else {
      url = `/api/v3/project/verification/${verificationId}/sierra/option/download?options=${options}&verificationName=${verificationName}&access_token=${token}`;
    }
    const fileName = `${verificationName}.zip`;
    const message = { success: 'Download simulation zip file successfully!', error: 'Download simulation zip file failed!' }
    this.downloadFile(url, 'simulationProgress', 'simulationMessage', fileName, message)
  }

  downloadReport = (options, token) => {
    const { currentProjectId } = this.props;
    const currentProject = projects.get(currentProjectId);
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    const targetTime = dayjs(time.toUTCString()).format('YYYYMMDD');
    const fileName = `${currentProject.name}_report_${targetTime}.zip`;
    const url = `sierra/${currentProjectId}/report/debug`;

    this.downloadFile(url, "reportProgress", "reportMessage", fileName, {}, options)

  }

  downloadFile = (url, progressName, messageName, fileName, message, options) => {
    let progress = 0, indexNum = 0;
    this.setState({
      [progressName]: 0
    })
    const { menuType } = this.state;
    this[`${menuType}Time`] = setInterval(() => {
      indexNum += 1;
      progress = getDebugDownloadProgress(progress);
      if (indexNum % 3 === 0) {
        this.setState({
          [progressName]: progress
        })
      }
    }, 800);

    if (menuType === REPORT) {
      this.getDownloadFileKey({
        url,
        menuType,
        progressName,
        messageName,
        fileName,
        params: options,
        messageType: "report debug"
      })
      return
    }

    if (url) {
      const a = document.createElement('a');
      // a.download = fileName;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      clearInterval(this[`${menuType}Time`]);
      setTimeout(() => {
        this.setState({
          [progressName]: -1,
        })
      }, 1000);
      this.setState({
        [progressName]: 100,
        [messageName]: { status: 'success', message: "Prepared file successfully and waiting for download!" }
      })
      setTimeout(() => {
        this.setState({
          [messageName]: { status: 'success', message: message.success }
        })
      }, 8000);

    }
  }

  getDownloadFileKey = ({
    url,
    menuType,
    progressName,
    messageName,
    fileName,
    messageType,
    params
  }) => {
    this.setState({
      [messageName]: { status: "success", message: 'Preparing file...' }
    })
    getDownloadFileIdForPost(url, params).then(async res => {
      if (res && res.status === "success") {
        let fileSize = 0;
        try {
          const fileSizeInfo = await getDownloadFileSize(res.key);
          fileSize = fileSizeInfo.size;
        } catch (error) {
          fileSize = 0;
        }

        if (fileSize > 1024 * 1024 * 1024) {
          downloadFileByChunk({
            fileSize,
            downloadKey: res.key,
            fileName,
            menuType,
            updateDownloadProgress: (_params) => this.updateDownloadProgress({ ..._params, progressName, messageName, messageType }),
            clearProgressInterval: this.clearProgressInterval
          })
        } else {
          clearInterval(this[`${menuType}Time`]);
          const a = document.createElement('a');
          const token = localStorage.getItem('token');
          a.href = `api/v3/download/file?downloadKey=${res.key}&access_token=${token}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({
            [progressName]: 100,
            [messageName]: { status: "success", message: 'Prepared file successfully and waiting for download!' }
          })
          setTimeout(() => {
            this.setState({
              [messageName]: { status: "success", message: getDebugMessage(DEBUG_SUCCESS, messageType) }
            })
          }, 8000);
          setTimeout(() => {
            this.setState({
              [progressName]: -1
            })
          }, 2000);
        }
      } else {
        this.downloadFailed({ progressName, messageName, messageType, menuType });
      }

    }, error => {
      this.downloadFailed({ progressName, messageName, messageType, menuType });
    })
  }

  downloadFailed = ({ progressName, messageName, messageType, menuType }) => {
    clearInterval(this[`${menuType}Time`]);
    setTimeout(() => {
      this.setState({
        [progressName]: -1,
        [messageName]: { status: "error", message: getDebugMessage(DEBUG_FAILED, messageType) }
      })
    }, 1000);
  }

  clearProgressInterval = (menuType) => {
    clearInterval(this[`${menuType}Time`]);
  }

  updateDownloadProgress = ({ progress, message, progressName, messageName, status, messageType }) => {
    const messageStatus = status === "failed" ? "error" : "success";
    const messageLog = status === "failed" ? getDebugMessage(DEBUG_FAILED, messageType) : (status === "success" ? getDebugMessage(DEBUG_SUCCESS, messageType) : message);
    this.setState({
      [progressName]: progress,
      [messageName]: messageLog ? { status: messageStatus, message: messageLog } : this.state[messageName]
    })
  }

  getMenuBtn(type) {
    const { menuType, PCBCurrentItem, simulationCurrentItem, interfaceList, selectCascader } = this.state;
    const { currentProjectVerifications, currentProjectId } = this.props;
    const currentProjectDesigns = projectDesigns.getDesigns(currentProjectId)
    let selectOptions, text, nameValue;
    switch (type) {
      case PCB_PARSING:
        selectOptions = [...currentProjectDesigns];
        text = 'Layout Parsing';
        nameValue = PCBCurrentItem ? PCBCurrentItem.name : (selectOptions[0] ? selectOptions[0].name : '');
        break;
      case SIMULATION_DEBUG:
        selectOptions = [...currentProjectVerifications];
        text = 'Simulation';
        nameValue = simulationCurrentItem ? simulationCurrentItem.name : (selectOptions[0] ? selectOptions[0].name : '');
        break;
      case REPORT:
        selectOptions = [];
        text = 'Report';
        nameValue = "";
        break;
      default: break;
    }
    const word = simulationCurrentItem ? simulationCurrentItem.label ? simulationCurrentItem.label : simulationCurrentItem.name : '';
    return <button
      className='sierra-debug-download-menu-btn'
      style={menuType === type ? { backgroundColor: '#c7e0f4' } : {}}
      onClick={() => this.changeDebugType(type)}>
      <span style={menuType === type ? { marginRight: 6 } : {}}>{text}</span>
      {menuType === type && <div onClick={(e) => { e.stopPropagation() }}>
        {menuType === PCB_PARSING ? <Select
          value={nameValue}
          onChange={(value) => this.changeDebugItem(value)}
          className='sierra-debug-download-menu-select'
          popupClassName='sierra-debug-download-menu-select-drop'
          popupMatchSelectWidth={false}
          getPopupContainer={() => document.getElementById('root')}>
          {selectOptions.map(item => (
            <Option className={item.category === DESIGN_FAILED && 'design-failed-Option'} key={item.name} value={item.id} title={item.name}>{item.name}</Option>
          ))}
        </Select> :
          (menuType === SIMULATION_DEBUG ? <Cascader
            options={interfaceList}
            value={selectCascader}
            onChange={(value) => this.changeSimulationItem(value)}
            className='sierra-debug-download-menu-select'
            style={{ width: word.length * 8 + 30 }}
            size='small'
            popupClassName='sierra-debug-download-menu-select-drop'
            getPopupContainer={() => document.getElementById('root')}
            changeOnSelect
          /> : null)}
      </div>}
    </button>
  }

  expandChange = (e, option) => {
    const { checkExpandKeys } = this.state;
    let _checkExpandKeys = [...checkExpandKeys];
    if (_checkExpandKeys.includes(option)) {
      _checkExpandKeys = _checkExpandKeys.filter(item => item !== option);
    } else {
      _checkExpandKeys.push(option)
    }
    this.setState({
      checkExpandKeys: _checkExpandKeys
    })
  }

  getCheckBoxGroup() {
    const { menuType, downloadList, currentCheckValues, checkExpandKeys } = this.state;
    let checkBoxs = [...downloadList];
    let checkBoxValues = [...currentCheckValues];
    return <CheckboxGroup
      value={checkBoxValues.map(item => item.name)}
      key={menuType}
      className='sierra-debug-download-checkbox-group'
      onChange={(values) => this.changeDebugCheckList(values)}
    >
      {checkBoxs.map(item => {
        if (item.children) {
          const checkedItem = checkBoxValues.find(it => it.name === item.option);
          const childrenCheckedList = checkedItem && checkedItem.children ? checkedItem.children : [];
          const expand = checkExpandKeys.includes(item.option);
          const Icon = expand ? DownOutlined : RightOutlined;
          return <Fragment key={item.option}>
            <div className='sierra-debug-download-group-checkbox'>
              <Icon
                onClick={(e) => this.expandChange(e, item.option)}
              />
              <Checkbox
                key={item.option}
                value={item.option}
                indeterminate={childrenCheckedList.length && childrenCheckedList.length < item.children.length ? true : false}
                className='sierra-debug-download-checkbox'
              >
                <span>{item.name}</span>
              </Checkbox>
            </div>
            {expand ? <CheckboxGroup
              value={childrenCheckedList}
              key={menuType}
              className='sierra-debug-download-checkbox-group'
              onChange={(values) => this.changeDebugCheckList(values, item.name)}
            >
              {item.children.map(it => <Checkbox
                key={it.option}
                value={it.option}
                className='sierra-debug-download-checkbox sierra-debug-download-sub-checkbox'
              >
                <span>{it.name}</span>
              </Checkbox>)}
            </CheckboxGroup> : null}
          </Fragment>
        }
        return <Checkbox
          key={item.option}
          value={item.option}
          className='sierra-debug-download-checkbox'
        >
          <span>{item.name}</span>
        </Checkbox>
      })}
    </CheckboxGroup>
  }

  getDownloadProgress() {
    const { PCBProgress, simulationProgress, currentCheckValues, PCBMessage, simulationMessage, reportProgress, reportMessage } = this.state;
    let progress, checklist = [...currentCheckValues], message, current = null;
    const { menuType, PCBCurrentItem, simulationCurrentItem } = this.state;
    switch (menuType) {
      case PCB_PARSING:
        progress = PCBProgress;
        message = PCBMessage;
        current = PCBCurrentItem
        break;
      case SIMULATION_DEBUG:
        progress = simulationProgress;
        message = simulationMessage;
        current = simulationCurrentItem;
        break;
      case REPORT:
        progress = reportProgress;
        message = reportMessage;
        current = { id: true };
        break;
      default: break;
    }
    return <Fragment>{
      progress === -1 ? <Fragment>
        {message && message.message && <div className={`sierra-debug-download-footer-message ${message.status}`}>{message.message}</div>}
        <Button
          title={checklist.length ? "download" : "Choose at least one option to download."}
          type="primary"
          ghost
          disabled={!checklist.length || (!current || !current.id)}
          onClick={(e) => this.DownloadDebugClick(e)}
          className='sierra-debug-download-footer-btn'
        >Download</Button>
      </Fragment> :
        <Progress
          size={{ height: 10 }}
          className='sierra-debug-download-footer-progress'
          percent={progress}
        />
    }</Fragment>
  }

  render() {
    const { menuType, maxWidth, maxHeight } = this.state;
    const menuClass = `sierra-debug-download-menu ${menuType}`;
    const content = (
      <Panel
        className='sierra-debug-download-panel aurora-x-scroll-hidden sierra-panel'
        title={<div className='sierra-debug-download-title'>Debug</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 600 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='sierra-debug-download-content' id="sierra-debug-download-content-id">
          <div className={menuClass}>
            {this.getMenuBtn(PCB_PARSING)}
            {this.getMenuBtn(SIMULATION_DEBUG)}
            {this.getMenuBtn(REPORT)}
          </div>
          <div className='sierra-debug-download-list' style={{ maxHeight: maxHeight - 156 }}>
            {this.getCheckBoxGroup()}
          </div>
          <div className='sierra-debug-download-footer'>
            {this.getDownloadProgress()}
          </div>
        </div >
      </Panel >
    );
    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const { SierraReducer: {
    project: { verificationId, currentProjectId, currentProjectName, currentProjectVerifications, viewList = [] },
    sweep: { experimentInfo: { base = {}, experiments = [], verificationName, sweepName, id } } }
  } = state;
  return {
    currentVerificationId: verificationId,
    currentProjectId,
    currentProjectName,
    currentProjectVerifications,
    currentExperimentList: [base, ...experiments].filter(item => item.id),
    isSweep: viewList.includes('experiments'),
    verificationName,
    sweepName,
    sweepId: id
  };
}

export default connect(mapState, null)(DownloadPanel);