import http from "../http";

/** 
 * Get project list
 * 2021.03.12
*/
export function getAndesV2ProjectList(typeName = "Andes_New") {
  return http.get(`/my-project/type/${typeName}`);
}

/** 
 * create project
 * @param {object} data {id, name, version }
 * 2021.03.12
*/
export function createProject(data) {
  return http.post(`/my-project/`, {
    ...data,
    typeName: "Andes_New",
  })
}

/** 
 * Get project info by projectId
 * @param {string} projectId
 * 2021.03.12
*/
export function getAndesV2Project(projectId) {
  return http.get(`/my-project/${projectId}`);
}


/** 
 * Get project all designs and verifications by projectId
 * @param {string} projectId
 * 2021.03.15
*/
export function getAndesV2ProjectChildren(projectId, typeName = "Andes_New") {
  return http.get(`/my-project/project/${projectId}/type/${typeName}`);
}

/** 
 * delete design in project by ids [designId]
 * @param {string} projectId
 * 2021.03.15
*/
export function deleteAndesV2Design(ids, isNew = "true") {
  return http.delete(`/my-project/designs`, { data: { ids, isNew } });
}

/** 
 * delete project by projectIds
 * @param {Array} ids projectIds
 * 2021.03.23
*/
export function deleteAndesV2ProjectByIds(ids) {
  return http.delete(`/my-project/`, { data: ids });
}


/** 
 * get project trash
 * @param {String} ids typeName
 * 2021.05.24
*/
export function getAndesV2ProjectTrash(typeName = "Andes_New") {
  return http.get(`/my-project/trash/${typeName}`);
}

/**
 * Clean up trash
 * 2021.05.24
 */
export function clearAndesV2ProjectTrash(typeName = "Andes_New") {
  return http.delete(`/my-project/cleanup/${typeName}`);
}

/** 
 * project copy
 * @param {String} projectId 
 * @param {String} name
 * 2021.07.12
*/
export function AndesCopyProject({ name, projectId }) {
  return http.post(`/andes/project/copy`, {
    projectId,
    projectName: name
  })
}