import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import { uploadDebug } from '@/services/Andes';
import {
  updateSimulationMsg, debugVerify, updateProgress, cleanSingleProgress
} from '../../store/simulation/action';
import { currentVerificationDebugVerify } from '../../store/andes/action';
import {
  getVerificationWholeLog
} from '@/services/api/v2/verificationCtrl';
import { getResBlob, downloadFile } from '@/services/helper/downloadHelper';
import FileSaver from 'file-saver';

class DebugButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: null,
    }
    this.uploadDebugRef = createRef();
    this.uploadTR0Ref = createRef();
  }

  onUploadDebug = (e) => {
    const { step } = this.state;
    const file = e.target.files[0];
    let type = null;
    const { verificationId } = this.props;
    uploadDebug(file, verificationId, step, this.UploadProgress).then(res => {
      this.props.updateSimulationMsg({ msg: `==> The debug ${type} file is uploaded successfully!`, verificationId });
      this.props.cleanSingleProgress(verificationId);
    }, error => {
      const msg = `==> Debug (${type}) file upload failed: ${error}!`;
      this.props.updateSimulationMsg({ msg, verificationId });
      this.props.cleanSingleProgress(verificationId);
      console.error(error);
    });
    this.uploadDebugRef.current.value = "";
  }

  UploadProgress = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const { verificationId } = this.props;
      const percentCompleted = Math.round((loaded * 100) / total);
      this.props.updateProgress({ verificationId: verificationId, progress: percentCompleted })
    }
  }

  UploadDebugClick = (e, step) => {
    const el = this.uploadDebugRef.current;
    this.setState({
      step: step
    })
    const { verificationId } = this.props;
    this.props.updateSimulationMsg({ msg: null, verificationId: verificationId })
    if (!el) return;
    el.click();
  }

  DownloadDebugClick = (e, step) => {
    const { verificationId, designName, verificationName } = this.props;
    const token = localStorage.getItem('token');
    const url = `/api/v3/project/verification/${verificationId}/andes/download/${step}?&access_token=${token}`;
    getResBlob(url).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, `${designName}_${verificationName}.zip`);
      } else {
        message.error('Download failed!');
      }
    })
  }

  verifyClick = (e, step) => {
    const { verificationId } = this.props;
    this.props.currentVerificationDebugVerify(step, verificationId);
  }

  downloadLogFile = (e) => {
    let filename, link;
    const { verificationId, verificationName } = this.props;
    if (!verificationId || !verificationName) {
      return;
    }
    getVerificationWholeLog(verificationId).then(res => {
      let _log = '';
      if (res && res.data) {
        _log = res.data;
      }
      filename = `${verificationName}.log`;
      downloadFile(_log, filename)
    })
  }

  // debugMenu = () => {
  //   return <Menu key="debug">
  //     <SubMenu title="Download" key='debug_download' trigger={['click']}>
  //       <MenuItem onClick={(e) => this.downloadLogFile(e)} key='download_log_file'>Log</MenuItem>
  //       <MenuItem onClick={(e) => this.DownloadDebugClick(e, 'extraction')} key='download_extraction'>Extraction</MenuItem>
  //       <MenuItem onClick={(e) => this.DownloadDebugClick(e, 'pproc')} key='download_pproc'>pproc</MenuItem>
  //     </SubMenu>
  //     <SubMenu title="Upload" key='debug_upload' trigger={['click']}>
  //       <MenuItem onClick={(e) => this.UploadDebugClick(e, 'setup')} key='upload_setup'>Setup</MenuItem>
  //       <MenuItem onClick={(e) => this.UploadDebugClick(e, 'extraction')} key='upload_extraction'>Extraction</MenuItem>
  //       <MenuItem onClick={(e) => this.UploadDebugClick(e, 'pproc')} key='upload_pproc'>pproc</MenuItem>
  //     </SubMenu>
  //     <SubMenu title="Verify" key='verify' trigger={['click']}>
  //       <MenuItem key='verify_setup' onClick={(e) => this.verifyClick(e, 'setup')}>Setup</MenuItem>
  //       <MenuItem key='verify_extraction' onClick={(e) => this.verifyClick(e, 'extraction')}>Extraction</MenuItem>
  //       <MenuItem key='verify_simulation' onClick={(e) => this.verifyClick(e, 'pproc')}>pproc</MenuItem>
  //     </SubMenu>
  //   </Menu>
  // }

  render() {
    return (
      <Fragment>
        {/* <Dropdown menu={() => this.debugMenu()} trigger={['click']}>
          <SettingOutlined className='andes-debug-icon' />
        </Dropdown> */}
        <input
          type='file'
          ref={this.uploadDebugRef}
          style={{ display: 'none' }}
          accept='.zip'
          onChange={(e) => this.onUploadDebug(e)}
        />
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { AndesReducer: { project: { verificationId, currentProjectDesigns, currentProjectVerifications } } } = state;

  const find = currentProjectVerifications.find(d => d.id === verificationId);
  const verificationName = find ? find.name : 'Interface';
  const designName = currentProjectDesigns.length > 0 ? currentProjectDesigns[0].name : 'design';
  return {
    verificationName,
    verificationId,
    designName
  }
}

const mapDispatch = (dispatch) => ({
  updateSimulationMsg({ msg, verificationId }) {
    dispatch(updateSimulationMsg({ msg, verificationId }));
  },
  debugVerify(step, verificationId) {
    dispatch(debugVerify(step, verificationId))
  },
  updateProgress({ verificationId, progress }) {
    dispatch(updateProgress({ verificationId, progress }));
  },
  cleanSingleProgress(verificationId) {
    dispatch(cleanSingleProgress(verificationId));
  },
  currentVerificationDebugVerify(step, verificationId) {
    dispatch(currentVerificationDebugVerify(step, verificationId))
  }
})

export default connect(mapState, mapDispatch)(DebugButton);