import { IBIS, IBIS_AMI, SPICE } from "../../../constants/libraryConstants";
import { END_TO_END_CHANNEL } from "../../../constants/treeConstants";
import { ANDES_V2__MULTI_PCB_CHANNEL_VERSION } from "../../../version";
import { IC, SERDES_TYPES } from "../../PCBHelper";
import { CPHY } from "../../PCBHelper/constants";
import {
  ADSConfig,
  AMISignalConfig,
  AMIDisplaySignals,
  libraryCheck,
  ADS_TX,
  ADS_RX,
  getCompPinBySignal,
  getSignalAmiCompBySignalName,
  updateTerminationModelPinMap,
  getCPHYCompPinBySignal
} from "../AMIModelHelper";
import { CPHYIBISSignalConfig } from "../AMIModelHelper/IntegratedConfig";
import libraryConstructor from "../library/libraryConstructor";

function setMultiPCBDefaultAMIModel(setupInfo, { channelSetupList }) {
  if (!setupInfo || !setupInfo.content || !setupInfo.content.pcbConnections) {
    return setupInfo;
  }
  const { firstComp, endComp } = getAMIComponents({
    pcbConnections: setupInfo.content.pcbConnections,
    connections: setupInfo.content.connections,
    channelSetupList
  });

  const adsSignals = getDefaultEndToEndAdsSignals({
    firstComp,
    endComp,
    serdesType: setupInfo.type
  });
  const adsConfig = new ADSConfig({
    serdesType: setupInfo.type,
    interfaceType: END_TO_END_CHANNEL,
    signals: adsSignals,
    controller: firstComp.component,
    controllerChannel: firstComp.channel,
    deviceChannel: endComp.channel,
    device: endComp.component
  });
  setupInfo.adsConfig = adsConfig;
  setupInfo.version = ANDES_V2__MULTI_PCB_CHANNEL_VERSION;
  return setupInfo;
}

function getAMIComponents({ pcbConnections, connections, channelSetupList }) {
  let firstComp = {}, endComp = {};
  const firstPcb = pcbConnections[0] ? pcbConnections[0] : null;
  /* channelId: "705790231357558784"
channelName: "Channel1"
designId: "671058573865914368"
designName: "7575_1280723_HW_U1_KCU105_REV_1_1_040115"
nextConnectionId: "1"
prevConnectionId: "" */
  const endPcb = pcbConnections.length - 1 > 0 && pcbConnections[pcbConnections.length - 1] ? pcbConnections[pcbConnections.length - 1] : null;

  const firstConnection = connections.find(item => firstPcb && item.CONNECTION_ID === firstPcb.nextConnectionId);
  const endConnection = connections.find(item => endPcb && item.CONNECTION_ID === endPcb.prevConnectionId);

  if (firstConnection && firstConnection.connection && firstConnection.connection.connector1) {
    const firstConnComp = firstConnection.connection.connector1.component;
    const firstChannel = channelSetupList.find(item => item.id === firstPcb.channelId);
    const components = firstChannel && firstChannel.content && firstChannel.content.components ? firstChannel.content.components : [];
    const findComps = components.filter(item => SERDES_TYPES.includes(item.type) && item.name !== firstConnComp);
    const ICComp = findComps.find(item => item.type === IC) || (findComps[0] || null);

    firstComp = {
      component: ICComp ? ICComp.name : "",
      comp: ICComp ? ICComp : {},
      channel: { designId: firstPcb.designId, designName: firstPcb.designName, channelId: firstPcb.channelId },
      channelInfo: firstChannel,
      signalKey: "channel1_signal",
      signal_connections_map: firstConnection.connection.signal_connections_map || []
    }
  }

  if (endConnection && endConnection.connection && endConnection.connection.connector2) {
    const endConnComp = endConnection.connection.connector2.component;
    const endChannel = channelSetupList.find(item => item.id === endPcb.channelId);
    const components = endChannel && endChannel.content && endChannel.content.components ? endChannel.content.components : [];
    const findComps = components.filter(item => SERDES_TYPES.includes(item.type) && item.name !== endConnComp);
    const ICComp = findComps.find(item => item.type === IC) || (findComps[0] || null);
    endComp = {
      component: ICComp ? ICComp.name : "",
      comp: ICComp ? ICComp : {},
      channel: { designId: endPcb.designId, designName: endPcb.designName, channelId: endPcb.channelId },
      channelInfo: endChannel,
      signalKey: "channel2_signal",
      signal_connections_map: endConnection.connection.signal_connections_map || []
    }
  }

  return { firstComp, endComp }
}

function getDefaultEndToEndAdsSignals({ firstComp, endComp, serdesType }) {
  let adsSignals = [];
  let signals = [], controllerComp = null, deviceComp = null;

  if (firstComp.channel && firstComp.channel.channelId) {
    signals = firstComp.signal_connections_map || [];
    controllerComp = firstComp;
    deviceComp = endComp;
  }

  if (endComp.channel && endComp.channel.channelId) {
    signals = endComp.signal_connections_map || [];
    controllerComp = endComp;
    deviceComp = firstComp;
  }

  for (let signal of signals) {

    const { txComp, rxComp } = getSignalAmiCompBySignalName({
      signalName: signal[controllerComp.signalKey] || "",
      controller: controllerComp,
      device: deviceComp
    })

    let adsSignal = null
    if (serdesType === CPHY) {
      adsSignal = new CPHYIBISSignalConfig({
        type: END_TO_END_CHANNEL,
        signalName: signal.name,
        controller: txComp.component || "",
        controllerChannelId: txComp.channel ? txComp.channel.channelId : null,
        device: rxComp.component || "",
        deviceChannelId: rxComp.channel ? rxComp.channel.channelId : null
      })
    } else {
      adsSignal = new AMISignalConfig({
        type: END_TO_END_CHANNEL,
        signalName: signal.name,
        controller: txComp.component || "",
        controllerChannelId: txComp.channel ? txComp.channel.channelId : null,
        device: rxComp.component || "",
        deviceChannelId: rxComp.channel ? rxComp.channel.channelId : null
      })
    }
    adsSignals.push(adsSignal)
  }
  return adsSignals;
}

function getAdsSignalMap({
  connections = [],
  pcbConnections = []
}) {
  let signals = [];
  const firstPcb = pcbConnections[0], endPcb = pcbConnections.length - 1 > 0 ? pcbConnections[pcbConnections.length - 1] : null;
  const firstConn = connections.find(item => item.CONNECTION_ID === firstPcb.nextConnectionId),
    endConn = connections.find(item => endPcb && item.CONNECTION_ID === endPcb.prevConnectionId);
  if (!pcbConnections.length) {
    return [];
  }

  const signal_connections_map = firstConn.connection.signal_connections_map || [];
  const end_signal_connections_map = endConn && endConn.connection.signal_connections_map ? endConn.connection.signal_connections_map : [];

  for (let signal of signal_connections_map) {
    const findEndSignal = end_signal_connections_map.find(item => item.name === signal.name);
    signals.push({
      name: signal.name,
      channel1_signal: signal.channel1_signal,
      channel1_id: firstPcb.channelId,
      channel2_signal: findEndSignal ? findEndSignal.channel2_signal : "",
      channel2_id: endPcb ? endPcb.channelId : ""
    })
  }
  return signals;
}

function getAMISignalList({ adsConfig, controllerInfo, deviceInfo, signal_map = [], serdesType }) {
  if (!adsConfig.signals) {
    return [];
  }

  if (serdesType === CPHY) {
    return getCPHYSignalList({ adsConfig, controllerInfo, deviceInfo, signal_map, serdesType })
  }
  let amiSignals = [];

  const controllerName = adsConfig.controller,
    deviceName = adsConfig.device;
  let contrComps = [], deviceComps = [], contrSignals = [], deviceSignals = [];

  if (controllerInfo && controllerInfo.content) {
    contrComps = controllerInfo.content.components ? controllerInfo.content.components : [];
    contrSignals = controllerInfo.content.signals ? controllerInfo.content.signals : [];
  }
  if (deviceInfo && deviceInfo.content) {
    deviceComps = deviceInfo.content.components ? deviceInfo.content.components : [];
    deviceSignals = deviceInfo.content.signals ? deviceInfo.content.signals : [];
  }

  const controllerComp = contrComps.find(item => item.name === controllerName) || {};
  const deviceComp = deviceComps.find(item => item.name === deviceName) || {};

  let modelKey = 'Ami', modelType = "AMI", libraryType = IBIS_AMI;
  if (adsConfig.signals[0] && adsConfig.signals[0].IbisHasAMI === "no") {
    modelType = "IBIS";
    modelKey = "";
    libraryType = IBIS;
  }
  const libraryList = libraryConstructor.getLibraryValues(libraryType) || [];
  const SPICElibraryList = libraryConstructor.getLibraryValues(SPICE) || [];

  for (let signal of adsConfig.signals) {

    if ((!controllerName && !deviceName) || (!controllerInfo && !deviceInfo)) {
      amiSignals.push(new AMIDisplaySignals({
        IbisHasAMI: signal.IbisHasAMI,
        signal: signal.signalName,
        modelType,
        txModel: signal[`tx${modelKey}Model`],
        rxModel: signal[`rx${modelKey}Model`],
        rcModel: signal.rcModel,
        txCircuitModel: signal.txCircuitModel,
        rxCircuitModel: signal.rxCircuitModel,
        aggressors: signal.aggressors
      }));
      continue;
    }
    const controllerSignal = getCompSignalName(signal_map, adsConfig.controllerChannel.channelId, signal.signalName),
      deviceSignal = getCompSignalName(signal_map, adsConfig.deviceChannel.channelId, signal.signalName);
    //find controller component pins by signal
    const findContrSignal = contrSignals.find(it => it.name === controllerSignal);
    //find device component pins by signal
    const findDeviceSignal = deviceSignals.find(it => it.name === deviceSignal);
    //controller
    const { positive, negative } = getCompPinBySignal({
      signal: findContrSignal || {},
      pins: controllerComp.pins
    });
    //device
    const { positive: devicePositive, negative: deviceNegative } = getCompPinBySignal({
      signal: findDeviceSignal || {},
      pins: deviceComp.pins
    });

    //Model library is not exist
    const TXModelFileError = libraryCheck({
      signalName: signal.signalName,
      modelKey,
      model: signal[`tx${modelKey}Model`],
      type: ADS_TX,
      libraryList
    });

    let isUsedRC = !signal[`rx${modelKey}Model`] && signal.rcModel ? true : false;

    // Determine if there is an error in rxModel
    const RXModelFileError = libraryCheck({
      signalName: signal.signalName,
      modelKey,
      model: isUsedRC ? signal.rcModel : signal[`rx${modelKey}Model`],
      type: ADS_RX,
      libraryList: isUsedRC ? SPICElibraryList : libraryList
    })

    amiSignals.push(new AMIDisplaySignals({
      IbisHasAMI: signal.IbisHasAMI,
      signal: signal.signalName,
      modelType,
      txModel: signal[`tx${modelKey}Model`],
      rxModel: signal[`rx${modelKey}Model`],
      rcModel: signal.rcModel,
      txCircuitModel: signal.txCircuitModel,
      rxCircuitModel: signal.rxCircuitModel,
      controller: {
        component: controllerName,
        positive,
        negative,
        controllerSignal,
        channelId: controllerInfo.id
      },
      device: {
        component: deviceName,
        positive: devicePositive,
        negative: deviceNegative,
        deviceSignal,
        channelId: deviceInfo.id
      },
      aggressors: signal.aggressors,
      TXModelFileError,
      RXModelFileError,
      prbs: signal.prbs
    }));
  }
  return amiSignals;
}

function getCPHYSignalList({ adsConfig, controllerInfo, deviceInfo, signal_map = [], serdesType }) {
  let amiSignals = [];

  const controllerName = adsConfig.controller,
    deviceName = adsConfig.device;
  let contrComps = [], deviceComps = [], contrSignals = [], deviceSignals = [];

  if (controllerInfo && controllerInfo.content) {
    contrComps = controllerInfo.content.components ? controllerInfo.content.components : [];
    contrSignals = controllerInfo.content.signals ? controllerInfo.content.signals : [];
  }
  if (deviceInfo && deviceInfo.content) {
    deviceComps = deviceInfo.content.components ? deviceInfo.content.components : [];
    deviceSignals = deviceInfo.content.signals ? deviceInfo.content.signals : [];
  }

  const controllerComp = contrComps.find(item => item.name === controllerName) || {};
  const deviceComp = deviceComps.find(item => item.name === deviceName) || {};

  const modelType = "IBIS", modelKey = "";
  const libraryList = [...(libraryConstructor.getLibraryValues(IBIS) || []), ...(libraryConstructor.getLibraryValues(IBIS_AMI) || [])];

  for (let signal of adsConfig.signals) {

    if ((!controllerName && !deviceName) || (!controllerInfo && !deviceInfo)) {
      amiSignals.push(new AMIDisplaySignals({
        signal: signal.signalName,
        modelType,
        cphyTxModel: signal.cphyTxModel,
        cphyRxModel: signal.cphyRxModel,
        serdesType
      }));
      continue;
    }
    const controllerSignal = getCompSignalName(signal_map, adsConfig.controllerChannel.channelId, signal.signalName),
      deviceSignal = getCompSignalName(signal_map, adsConfig.deviceChannel.channelId, signal.signalName);
    //find controller component pins by signal
    const findContrSignal = contrSignals.find(it => it.name === controllerSignal);
    //find device component pins by signal
    const findDeviceSignal = deviceSignals.find(it => it.name === deviceSignal);
    //controller
    const { lineA, lineB, lineC } = getCPHYCompPinBySignal({
      signal: findContrSignal || {},
      pins: controllerComp.pins
    });
    //device
    const { lineA: deviceLineA, lineB: deviceLineB, lineC: devicedeviceLineC } = getCPHYCompPinBySignal({
      signal: findDeviceSignal || {},
      pins: deviceComp.pins
    });

    //Model library is not exist
    const TXModelFileError = libraryCheck({
      signalName: signal.signalName,
      modelKey,
      model: signal.cphyTxModel,
      type: ADS_TX,
      libraryList
    });


    // Determine if there is an error in rxModel
    const RXModelFileError = libraryCheck({
      signalName: signal.signalName,
      modelKey,
      model: signal.cphyRxModel,
      type: ADS_RX,
      libraryList
    })

    amiSignals.push(new AMIDisplaySignals({
      IbisHasAMI: signal.IbisHasAMI,
      signal: signal.signalName,
      modelType,
      cphyTxModel: signal.cphyTxModel,
      cphyRxModel: signal.cphyRxModel,
      controller: {
        component: controllerName,
        lineA,
        lineB,
        lineC,
        controllerSignal,
        channelId: controllerInfo.id
      },
      device: {
        component: deviceName,
        lineA: deviceLineA,
        lineB: deviceLineB,
        lineC: devicedeviceLineC,
        deviceSignal,
        channelId: deviceInfo.id
      },
      TXModelFileError,
      RXModelFileError,
      prbs: signal.prbs
    }));
  }
  return amiSignals;
}
function getCompSignalName(signal_map, compChannelId, signal) {
  let findSignal = signal_map.find(item => item.name === signal);
  if (!findSignal) {
    return "";
  }

  if (findSignal.channel1_id === compChannelId) {
    return findSignal.channel1_signal;
  }

  if (findSignal.channel2_id === compChannelId) {
    return findSignal.channel2_signal;
  }
  return "";
}

function clearEndToEndAdsComponent({
  pcbConn,
  adsConfig,
  prevChannelId,
  connections,
  connIndex,
  channelInfo,
  serdesType
}) {

  if (!adsConfig) {
    return adsConfig;
  }

  let _adsConfig = { ...adsConfig };
  const components = channelInfo && channelInfo.content && channelInfo.content.components ? channelInfo.content.components : [];
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));
  const ICComp = ICComps.find(item => item.type === IC) || (ICComps[0] || null);
  const comp = ICComp ? ICComp.name : "";

  const deviceId = _adsConfig.deviceChannel && _adsConfig.deviceChannel.channelId ? _adsConfig.deviceChannel.channelId : null;

  if (_adsConfig.controllerChannel && (!_adsConfig.controllerChannel.channelId || _adsConfig.controllerChannel.channelId === prevChannelId)) {
    if (
      (prevChannelId && _adsConfig.controllerChannel.channelId)
      || (deviceId !== pcbConn.channelId && pcbConn.channelId)) {
      _adsConfig.controller = comp;
      _adsConfig.controllerChannel = { designId: pcbConn.designId, designName: pcbConn.designName, channelId: pcbConn.channelId };
    }
  }

  const controllerId = _adsConfig.controllerChannel && _adsConfig.controllerChannel.channelId ? _adsConfig.controllerChannel.channelId : null;

  if (_adsConfig.deviceChannel && (!_adsConfig.deviceChannel.channelId || _adsConfig.deviceChannel.channelId === prevChannelId)) {
    if (
      (prevChannelId && _adsConfig.deviceChannel.channelId)
      || (controllerId !== pcbConn.channelId && pcbConn.channelId)) {
      _adsConfig.device = comp;
      _adsConfig.deviceChannel = { designId: pcbConn.designId, designName: pcbConn.designName, channelId: pcbConn.channelId };
    }
  }

  const connectionId = connIndex === 0 ? pcbConn.nextConnectionId : pcbConn.prevConnectionId;
  const connection = connections.find(item => item.CONNECTION_ID === connectionId);
  if (!connection) {
    return null;
  }

  const signal_connections_map = connection.connection.signal_connections_map || [];
  const signalIndex = connIndex === 0 ? "channel1_signal" : "channel2_signal";

  for (let signal of _adsConfig.signals) {
    const findSignal = signal_connections_map.find(item => item.name === signal.signalName);
    if (!findSignal) {
      continue;
    }

    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";

    if (prevChannelId && signal.txCircuitModel && signal.txCircuitModel.channelId === prevChannelId) {
      signal.txCircuitModel.component = comp;
      signal.txCircuitModel.channelId = pcbConn.channelId || "";
      signal.txCircuitModel.pairs = []
    }
    if (prevChannelId && signal.rxCircuitModel && signal.rxCircuitModel.channelId === prevChannelId) {
      signal.rxCircuitModel.component = comp;
      signal.rxCircuitModel.channelId = pcbConn.channelId || "";
      signal.rxCircuitModel.pairs = []
    }

    const txModelKey = serdesType === CPHY ? "cphyTxModel" : `tx${modelKey}Model`;
    //if prev ami model component exist
    if (prevChannelId && signal[txModelKey] && signal[txModelKey].channelId === prevChannelId) {
      signal[txModelKey].component = comp;
      signal[txModelKey].channelId = pcbConn.channelId || "";
      continue;
    }

    const rxModelKey = serdesType === CPHY ? "cphyRxModel" : !signal[`rx${modelKey}Model`] && signal.rcModel ? 'rcModel' : `rx${modelKey}Model`;

    if (prevChannelId && signal[rxModelKey] && signal[rxModelKey].channelId === prevChannelId) {
      signal[rxModelKey].component = comp;
      signal[rxModelKey].channelId = pcbConn.channelId || "";
      if (rxModelKey === 'rcModel') {
        signal[rxModelKey].pairs = []
      }
      continue;
    }

    //if prev ami model not exist, set dir according to channel signal name 
    let dir = "", antherDir = "", anotherComponent = "", anotherChannelId = "";
    if (!signal[txModelKey].channelId && !signal[rxModelKey].channelId) {
      if (findSignal[signalIndex].match("TX")) {
        dir = txModelKey;
        antherDir = rxModelKey === 'rcModel' ? rxModelKey : `rx${modelKey}Model`;
      }
      if (findSignal[signalIndex].match("RX")) {
        dir = rxModelKey === 'rcModel' ? rxModelKey : `rx${modelKey}Model`;
        antherDir = txModelKey;
      }
    }

    if (dir) {
      anotherComponent = signal[dir].component;
      anotherChannelId = signal[dir].channelId;

      signal[dir].component = comp;
      signal[dir].channelId = pcbConn.channelId || "";
      signal[antherDir].component = anotherComponent || "";
      signal[antherDir].channelId = anotherChannelId || "";
      continue;
    }

    //if prev ami model not exist, find model of not set ami component
    if (signal[txModelKey] && !signal[txModelKey].channelId) {
      signal[txModelKey].component = comp;
      signal[txModelKey].channelId = pcbConn.channelId || "";
      continue;
    }

    if (signal[rxModelKey] && !signal[rxModelKey].channelId) {
      signal[rxModelKey].component = comp;
      signal[rxModelKey].channelId = pcbConn.channelId || "";
    }
  }

  return _adsConfig;
}

/** connection component updated, simulation config controller or device component need to update 
 * @param {string} compName connection updated component
*/
function updateEndToEndAdsCompByConnection({
  adsConfig,
  pcbConnections,
  pcbIndex,
  compName,
  channelInfo = {},
  serdesType
}) {

  if (!adsConfig) {
    return adsConfig;
  }

  let _adsConfig = { ...adsConfig };
  const pcbConn = pcbConnections[pcbIndex];

  const components = channelInfo.content && channelInfo.content.components ? channelInfo.content.components : []
  const ICComps = compName ? components.filter(item => SERDES_TYPES.includes(item.type) && item.name !== compName) : [];
  const ICComp = ICComps.find(item => item.type === IC) || (ICComps[0] || null);

  const comp = ICComp ? ICComp.name : "";

  if (_adsConfig.controllerChannel && _adsConfig.controllerChannel.channelId === pcbConn.channelId) {
    _adsConfig.controller = comp;
  }

  if (_adsConfig.deviceChannel && _adsConfig.deviceChannel.channelId === pcbConn.channelId) {
    _adsConfig.device = comp;
  }

  for (let signal of _adsConfig.signals) {
    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";
    const txModelKey = serdesType === CPHY ? "cphyTxModel" : `tx${modelKey}Model`;
    const rxModelKey = serdesType === CPHY ? "cphyRxModel" : !signal[`rx${modelKey}Model`] && signal.rcModel ? 'rcModel' : `rx${modelKey}Model`;
    if (signal[txModelKey].channelId === pcbConn.channelId) {
      signal[txModelKey].component = comp;
    }
    if (signal[rxModelKey].channelId === pcbConn.channelId) {
      signal[rxModelKey].component = comp;
      if (rxModelKey === 'rcModel') {
        signal[rxModelKey].pairs = []
      }
    }

    if (signal.txCircuitModel && signal.txCircuitModel.channelId === pcbConn.channelId) {
      signal.txCircuitModel.component = comp;
      signal.txCircuitModel.pairs = []
    }
    if (signal.rxCircuitModel && signal.rxCircuitModel.channelId === pcbConn.channelId) {
      signal.rxCircuitModel.component = comp;
      signal.rxCircuitModel.pairs = []
    }
  }
  return _adsConfig;
}
function judgeUpdateConnComp({
  adsConfig,
  pcbConnections,
  connection,
  connections,
  connectionIndex
}) {

  if (!adsConfig) {
    return false;
  }
  const controllerComp = adsConfig.controller, deviceComp = adsConfig.device;
  const comp1Name = connection.connector1.component, comp2Name = connection.connector2.component;

  const adsControllerId = adsConfig.controllerChannel ? adsConfig.controllerChannel.channelId : "",
    adsDeviceId = adsConfig.deviceChannel ? adsConfig.deviceChannel.channelId : "";
  const channel1Id = connections[connectionIndex].channel1 ? connections[connectionIndex].channel1.channelId : "",
    channel2Id = connections[connectionIndex].channel2 ? connections[connectionIndex].channel2.channelId : "";

  //controller -connector1
  if (adsControllerId && adsControllerId === channel1Id && comp1Name === controllerComp) {
    if (adsControllerId === pcbConnections[0].channelId) {
      return { pcbIndex: 0, compName: comp1Name };
    }
    if (adsControllerId === pcbConnections[pcbConnections.length - 1].channelId) {
      return { pcbIndex: pcbConnections.length - 1, compName: comp1Name };
    }
  }

  //device -connector1
  if (adsDeviceId && adsDeviceId === channel1Id && comp1Name === deviceComp) {
    if (adsDeviceId === pcbConnections[0].channelId) {
      return { pcbIndex: 0, compName: comp1Name };
    }
    if (adsDeviceId === pcbConnections[pcbConnections.length - 1].channelId) {
      return { pcbIndex: pcbConnections.length - 1, compName: comp1Name };
    }
  }

  //controller -connector2
  if (adsControllerId && adsControllerId === channel2Id && comp2Name === controllerComp) {
    if (adsControllerId === pcbConnections[0].channelId) {
      return { pcbIndex: 0, compName: comp2Name };
    }
    if (adsControllerId === pcbConnections[pcbConnections.length - 1].channelId) {
      return { pcbIndex: pcbConnections.length - 1, compName: comp2Name };
    }
  }

  //device -connector2
  if (adsDeviceId && adsDeviceId === channel2Id && comp2Name === deviceComp) {
    if (adsDeviceId === pcbConnections[0].channelId) {
      return { pcbIndex: 0, compName: comp2Name };
    }
    if (adsDeviceId === pcbConnections[pcbConnections.length - 1].channelId) {
      return { pcbIndex: pcbConnections.length - 1, compName: comp2Name };
    }
  }

  return false;
}

function delEndToEndAdsCompByDelPCB({ adsConfig, delPCB, newIndex, newPCB = {}, channelInfo = {}, connections = [], serdesType }) {
  if (!adsConfig || !Object.keys(adsConfig).length || !delPCB || !delPCB.channelId) {
    return adsConfig;
  }

  let _config = { ...adsConfig };

  const connectionKey = newIndex === 0 ? "nextConnectionId" : "prevConnectionId";
  const connectionId = newPCB[connectionKey];
  const connection = connections.find(item => item.CONNECTION_ID === connectionId) || { connection: { connector1: {}, connector2: {} } };
  const compName = newIndex === 0 ? connection.connection.connector1.component : connection.connection.connector2.component;

  const components = channelInfo && channelInfo.content && channelInfo.content.components ? channelInfo.content.components : [];
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type) && item.name !== compName);
  const ICComp = ICComps.find(item => item.type === IC) || (ICComps[0] || null);
  const comp = ICComp ? ICComp.name : "";

  if (_config.controller && _config.controllerChannel && _config.controllerChannel.designId === delPCB.designId) {
    _config.controller = comp;
    _config.controllerChannel = { designId: newPCB.designId || "", designName: newPCB.designName || "", channelId: newPCB.channelId || "" }
  }

  if (_config.device && _config.deviceChannel && _config.deviceChannel.designId === delPCB.designId) {
    _config.device = comp;
    _config.deviceChannel = { designId: newPCB.designId || "", designName: newPCB.designName || "", channelId: newPCB.channelId || "" }
  }

  for (let signal of (_config.signals || [])) {
    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";
    const txModelKey = serdesType === CPHY ? "cphyTxModel" : `tx${modelKey}Model`;
    if (signal[txModelKey].channelId === delPCB.channelId) {
      signal[txModelKey].component = comp;
      signal[txModelKey].channelId = newPCB.channelId || "";
    }

    const rxModelKey = serdesType === CPHY ? "cphyRxModel" : signal[`rx${modelKey}Model`] ? `rx${modelKey}Model` : 'rcModel';

    if (signal[rxModelKey].channelId === delPCB.channelId) {
      signal[rxModelKey].component = comp;
      signal[rxModelKey].channelId = newPCB.channelId || "";
    }
  }

  return _config;
}

function delEndToEndAdsCompByIgnoreComp({
  adsConfig,
  component,
  channelId,
  serdesType
}) {
  if (adsConfig.controller === component && adsConfig.controllerChannel && adsConfig.controllerChannel.channelId === channelId) {
    adsConfig.controller = "";
    adsConfig.controllerChannel = { designId: "", designName: "", channelId: "" }
  }

  if (adsConfig.device === component && adsConfig.deviceChannel && adsConfig.deviceChannel.channelId === channelId) {
    adsConfig.device = "";
    adsConfig.deviceChannel = { designId: "", designName: "", channelId: "" }
  }

  for (let signal of (adsConfig.signals || [])) {
    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";
    const txModelKey = serdesType === CPHY ? "cphyTxModel" : `tx${modelKey}Model`;

    if (signal[txModelKey].channelId === channelId && signal[txModelKey].component === component) {
      signal[txModelKey].component = "";
      signal[txModelKey].channelId = "";
    }

    const rxModelKey = serdesType === CPHY ? "cphyRxModel" : !signal[`rx${modelKey}Model`] && signal.rcModel ? 'rcModel' : `rx${modelKey}Model`;

    if (signal[rxModelKey].channelId === channelId && signal[rxModelKey].component === component) {
      signal[rxModelKey].component = "";
      signal[rxModelKey].channelId = "";
      if (rxModelKey === "rcModel") {
        signal[rxModelKey].pairs = []
      }
    }

    if (signal.txCircuitModel && signal.txCircuitModel.channelId === channelId && signal.txCircuitModel.component === component) {
      signal.txCircuitModel.component = "";
      signal.txCircuitModel.channelId = "";
      signal.txCircuitModel.pairs = [];
    }

    if (signal.rxCircuitModel && signal.rxCircuitModel.channelId === channelId && signal.rxCircuitModel.component === component) {
      signal.rxCircuitModel.component = "";
      signal.rxCircuitModel.channelId = "";
      signal.rxCircuitModel.pairs = []
    }
  }

  return adsConfig;
}

function getAdsComponentIds({
  adsConfig,
  firstPcbConn,
  endPcbConn
}) {
  if (!adsConfig) {
    return { controllerId: null, deviceId: null }
  }
  let controllerId = adsConfig.controllerChannel ? adsConfig.controllerChannel.channelId : null,
    deviceId = adsConfig.deviceChannel ? adsConfig.deviceChannel.channelId : null;

  if (!controllerId && !deviceId) {
    controllerId = firstPcbConn.channelId;
    deviceId = endPcbConn.channelId;
    return { controllerId, deviceId };
  }

  if (!controllerId && firstPcbConn && firstPcbConn.channelId !== deviceId) {
    controllerId = firstPcbConn.channelId;
    return { controllerId, deviceId };
  }

  if (!deviceId && endPcbConn && endPcbConn.channelId !== controllerId) {
    deviceId = endPcbConn.channelId;
    return { controllerId, deviceId };
  }

  if (!controllerId && endPcbConn && endPcbConn.channelId !== deviceId) {
    controllerId = endPcbConn.channelId;
    return { controllerId, deviceId };
  }

  if (!deviceId && firstPcbConn && firstPcbConn.channelId !== controllerId) {
    deviceId = firstPcbConn.channelId;
    return { controllerId, deviceId };
  }


  return {
    controllerId,
    deviceId
  }
}

function updateEndToEndTerminationModelPinMap({
  component,
  pins,
  prevPin,
  newPin,
  channelId,
  adsConfig
}) {
  if (!adsConfig || !Object.keys(adsConfig).length || !adsConfig.signals || !adsConfig.signals.length) {
    return adsConfig;
  }
  let modelComp = null, modelChannelId = null;
  if ((adsConfig.controller === component
    && adsConfig.controllerChannel
    && adsConfig.controllerChannel.channelId === channelId)
    || (adsConfig.device === component
      && adsConfig.deviceChannel
      && adsConfig.deviceChannel.channelId === channelId)) {
    modelComp = component;
    modelChannelId = channelId;
  }
  if (!modelComp || !modelChannelId) {
    return adsConfig
  }

  //if rx model use rc termination model,update pin map
  adsConfig = updateTerminationModelPinMap({
    adsConfig,
    component,
    pins,
    prevPin,
    newPin,
    channelId
  });
  return adsConfig;
}

export {
  setMultiPCBDefaultAMIModel,
  getAMISignalList,
  clearEndToEndAdsComponent,
  updateEndToEndAdsCompByConnection,
  judgeUpdateConnComp,
  getAdsSignalMap,
  delEndToEndAdsCompByDelPCB,
  delEndToEndAdsCompByIgnoreComp,
  getAdsComponentIds,
  updateEndToEndTerminationModelPinMap
}