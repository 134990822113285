import { getComponentTable, updateComponentTable } from '../../api/compSetting';
import { versionCompareSize } from '../../helper/dataProcess';
import componentSetting from './compSettingHelper';
import apiProcess from '../../api/utility';
import { CAP, IGNORE, IND, RES, JUMPER, FERRITE, SWITCH, TRANSISTOR, DIODE, UNUSED, getPinMapByPinName } from '../../PCBHelper';
import { getPassiveValue, oldUnitTransfer } from '../../helper/RLCValue';
import { SortFn } from '../../helper/sort';
import debounce from '../../helper/debounceFn';
import designConstructor from '../../helper/designConstructor';
import auroraDBJson from '../../Designs/auroraDbData';

class CompTableHelper {
  constructor() {
    this.componentTable = new Map();  // key - designId, value - { compPrefixLib, version }
  }

  saveTable(designId, table) {
    this.componentTable.set(designId, table);
  }

  getTable = async (designId) => {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (!designId || isPreLayout) {
      return {};
    }

    let obj = this.componentTable.get(designId);
    const version = await componentSetting.getVersion(designId);
    if (!obj || !obj.table || !obj.table.length || versionCompareSize(obj.version, version)) {
      try {
        obj = await getCompTable(designId);
        if (!obj || !obj.table || !obj.table.length || versionCompareSize(obj.version, version)) {
          const table = await getDesignComponentTable(designId, obj && obj.table ? obj.table : []);
          obj = {
            version: version,
            table
          }
          this.updateTable([{ designId, componentTable: obj }]);
        } else {

          // old case
          let oldCase
          if (obj && obj.table && obj.table.length > 0 && !Object.keys(obj.table[0]).includes('unit')) {
            oldCase = true
            obj.table.forEach(item => {
              let oldVal = item.value || 0
              item.value = oldUnitTransfer(oldVal).value
              item.unit = oldUnitTransfer(oldVal).unit
            })
          }

          // update
          if (oldCase) {
            this.updateTable([{ designId, componentTable: obj }]);
          } else {
            this.saveTable(designId, obj);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    return obj
  }



  getTableData = async (designId) => {
    const obj = await this.getTable(designId);
    return obj && obj.table ? obj.table : [];
  }

  getVersion = async (designId) => {
    const obj = await this.getTable(designId);
    return obj && obj.version ? obj.version : '0.0.1';
  }

  updateVersion = async (designId, version) => {
    const setting = await this.getTable(designId);
    this.updateTable([{ designId, componentTable: { ...setting, version } }])
  }

  async updateTable(componentTables) {
    try {
      debounce(async () => {
        await updateTables(componentTables);
        componentTables.forEach(obj => {
          const { designId, componentTable } = obj;
          this.saveTable(designId, componentTable);
        })
      }, 500, false, 'cascadeUpdateTable')()
    } catch (error) {
      console.error(error)
    }
  }

  clearTable(designId) {
    this.saveTable(designId, null);
  }
}

function getCompTable(designId) {
  return apiProcess(getComponentTable, { designId });
}

function updateTables(componentTables) {
  return apiProcess(updateComponentTable, componentTables);
}

async function getDesignComponentTable(designId, oldTable = []) {
  let table = [];
  let components = auroraDBJson.getComponents(designId);
  components = [...components.values()].filter(comp => ![CAP, IGNORE, TRANSISTOR, DIODE, UNUSED].includes(comp.type));
  for (let comp of components) {
    const findIndex = table.findIndex(item => item.part === comp.partName);
    if (findIndex > -1) {
      table[findIndex].name = [...table[findIndex].name, comp.name];
    } else {
      const usage = comp.type;
      const old = oldTable.find(item => item.part === comp.partName);
      const value = old ? old.value : usage === IND ? '0' : getPassiveValue(comp.value).value;
      const unit = old ? old.unit : usage === IND ? 'm' : getPassiveValue(comp.value).unit;
      table.push({
        part: comp.partName,
        name: [comp.name],
        usage,
        value,
        unit,
        pins: [...getPinMapByPinName([...comp.pins.values()] || [])]
      })
    }
  }

  table = SortFn(table, [RES, FERRITE, JUMPER, SWITCH, IND], 'usage');

  return table;
}

const componentTable = new CompTableHelper();

export default componentTable;
