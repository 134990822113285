import Plot2D from '../../../Result/Public/waveform/Plot2D';
import { getPCBCompAndPin } from '../../../helper/resultPublic';

function checkndEn(name, pins, showPower) {
  if (!name) {
    return false;
  }
  const _name = name;
  // name
  if (_name === 'time') { return true; }

  // vddq curve
  // u1_vddq (HSPICE) or u1_vddq) (NGSPICE)
  if (_name.match(/(_vddq$|_vddq\)$)/ig) && showPower) {
    return true;
  }
  const indexStart = _name.indexOf('('), indexEnd = _name.lastIndexOf(')');

  let pinInfo = null;
  if (indexStart > -1 && indexEnd > -1) {
    const newName = _name.slice(indexStart + 1, indexEnd);
    // v(pcb444931783444795392_u20_aj2) v(nd_en_pcb444931783444795392_u11_f8)
    let { compPinName } = getPCBCompAndPin(newName);
    pinInfo = pins.find(pin =>
      compPinName.toLowerCase() === `${pin.component.toLowerCase()}_${pin.pin.toLowerCase()}`
    );
  } else {
    // pcb444931783444795392_u20_aj2 nd_en_pcb444931783444795392_u11_f8
    let { compPinName } = getPCBCompAndPin(name);
    if (compPinName) {
      pinInfo = pins.find(pin =>
        compPinName.toLowerCase() === `${pin.component.toLowerCase()}_${pin.pin.toLowerCase()}`
      );
    } else {

    }

  }


  if (pinInfo) {
    // !ndEn - nd_in
    if (!pinInfo.ndEn && name.includes('_en')) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

function Signal() {
  this.name = null; // String, name of the signal
  this.type = null; // String, signal type - 'voltage' or 'current'
};

// ------ Result data class ------
/** Raw curve data of a byte */
class WaveformData {
  constructor() {
    this.signals = []; // Array of WaveformResultHelper.Signal, list of signal description
    this.time = []; // Array of Number, time instances of the simulation
    this.curveData = []; // Array of Array of Number, values at each time instance for all signals
    this.plot = null;
  }

  getCurves() {
    return this.plot.curves;
  }

  parseJsonCurve = (jsonData, stimulus, showPower = true, isTDR) => {
    if (!jsonData) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
      return;
    }

    const { data, names, variables, points, times } = jsonData;
    let filterSignal = [];
    if (!names || !variables || !points || !times) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
      return;
    }
    names.forEach((name, index) => {
      let signalItem = new Signal();
      signalItem.name = name;
      signalItem.type = 'voltage';
      this.signals.push(signalItem);
      const isSignalCurve = checkndEn(name, stimulus, showPower);
      if (stimulus && name !== 'time') {
        if ((!name.includes(stimulus) || !isSignalCurve) && !isTDR) {
          // index of signal curve
          filterSignal.push(index);
        }
      } else {
        if (!isSignalCurve && !isTDR) {
          // index of signal curve
          filterSignal.push(index);
        }
      }
      // add a data line for the signal
    })

    try {
      if (data[0].length === times.length) {
        if (JSON.stringify(data[0]) === JSON.stringify(times)) {
          data.shift();
        }
      }
      this.curveData = data;
      this.signals.shift();
      // filter signals
      if (!isTDR) {
        this.curveData = this.curveData.filter(data => data.length > 0);
        this.signals = this.signals.filter((signal, index) => !filterSignal.includes(index));
      }
      this.time = times;
    } catch (err) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
    }
  }

  // pinsInfo -[{ component, pin }]
  parseRawCurve(fileContent = "", pinsInfo = [], stimulus, showPower = true, isTDR) {
    // break the file into lines
    var lineBuffer = null;
    try {
      lineBuffer = fileContent.match(/[^\r\n]+/g);
    } catch (err) {
      console.error(err)
    }
    if (!lineBuffer || (Array.isArray(lineBuffer) && !lineBuffer.length)) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
      return;
    }
    // read the header
    var numSignals = 0; // number of signals in the raw data file
    var numPoints = 0; // number of sampling points in the raw data file
    var indDataLine = 0; // starting line of the curve data
    var isReadingSignalDef = false; // indicator of the signal definition section in the header

    let filterSignal = [];
    for (let i = 0; i < lineBuffer.length; i++) {

      if (isReadingSignalDef) {

        // get the name and type of the signal
        var words = lineBuffer[i].trim().split("\t");

        var signalItem = new Signal();

        // filter signal

        signalItem.name = words[1];
        signalItem.type = words[2];
        this.signals.push(signalItem);
        const isSignalCurve = checkndEn(words[1], pinsInfo, showPower);
        // Not signal curve
        if (stimulus && words[1] !== 'time') {
          if ((!words[1].includes(stimulus) || !isSignalCurve) && !isTDR) {
            // index of signal curve
            filterSignal.push(this.signals.length - 1);
          }
        } else {
          if (!isSignalCurve && !isTDR) {
            // index of signal curve
            filterSignal.push(this.signals.length - 1);
          }
        }


        // add a data line for the signal
        this.curveData.push([]);

        if (this.signals.length === numSignals) {
          isReadingSignalDef = false;
        }
      } else {

        words = lineBuffer[i].split(" ");
        if (words[0] === 'No.' && words[1] === 'Variables:') {
          // number of signals
          numSignals = Number(words[2]);
        } else if (words[0] === 'No.' && words[1] === 'Points:') {
          // number of points
          numPoints = Number(words[2]);
        } else if (words[0] === 'Variables:') {
          // starting of variable definitions
          isReadingSignalDef = true;
        } else if (words[0] === 'Values:') {
          // starting of curve values
          indDataLine = Number(i);
          break;
        }
      }
    } // for (var i in lineBuffer)
    // data integrity check
    if (numPoints <= 0 || numSignals <= 0 || this.signals.length !== numSignals) {
      return null;
    } else if (indDataLine + numSignals * numPoints >= lineBuffer.length) {
      return null;
    }
    // read the data points
    try {
      for (var iPoint = 0; iPoint < numPoints; iPoint++) {

        for (var iSignal = 0; iSignal < numSignals; iSignal++) {

          indDataLine++;
          if (filterSignal.includes(iSignal)) {
            continue;
          }
          if (iSignal === 0) {
            words = lineBuffer[indDataLine].split('\t');
            /* this.curveData[iSignal].push(Number(words[2])); */
            this.curveData[iSignal].push(Number(words[words.length - 1]));
          } else {
            this.curveData[iSignal].push(Number(lineBuffer[indDataLine]));
          }

        } // for (var iSignal = 0; iSignal < numSignals; iSignal++)
      } // for (var iPoint = 0; iPoint < numPoints; iPoint++)

      // filter signals
      if (!isTDR) {
        this.curveData = this.curveData.filter(data => data.length > 0);
        this.signals = this.signals.filter((signal, index) => !filterSignal.includes(index));
      }

      // separate the time signal
      this.time = this.curveData.shift();
      this.signals.shift();

      if (stimulus) {
        this.signals.forEach(item => {
          const _findStimulus = pinsInfo.find(d => {
            const _compPin = ('_' + d.component + '_' + d.pin).toLowerCase();
            return item.name.includes(_compPin)
          });
          if (_findStimulus) {
            item.stimulus = _findStimulus.stimulus;
          }
        })
      };
    } catch (err) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
    }
  } // parseRawCurveFile

  newParseRawCurve(fileContent) {
    // break the file into lines
    var lineBuffer = null;
    try {
      lineBuffer = fileContent.match(/[^\r\n]+/g);
    } catch (err) {
      console.error(err)
    }
    let signals = [], time = [], curveData = []
    if (!lineBuffer || (Array.isArray(lineBuffer) && !lineBuffer.length)) {
      signals = [];
      time = [];
      curveData = [];
      return;
    }
    // read the header
    var numSignals = 0; // number of signals in the raw data file
    var numPoints = 0; // number of sampling points in the raw data file
    var indDataLine = 0; // starting line of the curve data
    var isReadingSignalDef = false; // indicator of the signal definition section in the header

    for (let i = 0; i < lineBuffer.length; i++) {

      if (isReadingSignalDef) {

        // get the name and type of the signal
        var words = lineBuffer[i].trim().split("\t");

        var signalItem = new Signal();

        // filter signal

        signalItem.name = words[1];
        signalItem.type = words[2];
        signals.push(signalItem);
        // add a data line for the signal
        curveData.push([]);

        if (signals.length === numSignals) {
          isReadingSignalDef = false;
        }
      } else {

        words = lineBuffer[i].split(" ");
        if (words[0] === 'No.' && words[1] === 'Variables:') {
          // number of signals
          numSignals = Number(words[2]);
        } else if (words[0] === 'No.' && words[1] === 'Points:') {
          // number of points
          numPoints = Number(words[2]);
        } else if (words[0] === 'Variables:') {
          // starting of variable definitions
          isReadingSignalDef = true;
        } else if (words[0] === 'Values:') {
          // starting of curve values
          indDataLine = Number(i);
          break;
        }
      }
    } // for (var i in lineBuffer)
    // data integrity check
    if (numPoints <= 0 || numSignals <= 0 || signals.length !== numSignals) {
      return null;
    } else if (indDataLine + numSignals * numPoints >= lineBuffer.length) {
      return null;
    }
    // read the data points
    try {
      for (var iPoint = 0; iPoint < numPoints; iPoint++) {

        for (var iSignal = 0; iSignal < numSignals; iSignal++) {

          indDataLine++;
          if (iSignal === 0) {
            words = lineBuffer[indDataLine].split('\t');
            /* curveData[iSignal].push(Number(words[2])); */
            curveData[iSignal].push(Number(words[words.length - 1]));
          } else {
            curveData[iSignal].push(Number(lineBuffer[indDataLine]));
          }

        } // for (var iSignal = 0; iSignal < numSignals; iSignal++)
      } // for (var iPoint = 0; iPoint < numPoints; iPoint++)

      // separate the time signal
      time = curveData.shift();
      signals.shift();
    } catch (err) {
      signals = [];
      time = [];
      curveData = [];
    }

    return {
      signals,
      time,
      curveData
    }

  } // parseRawCurveFile

  parsePreLayoutRawCurve = (fileList) => {
    for (let fileInfo of fileList) {
      const { data, libraryId, fileName } = fileInfo;
      const info = this.newParseRawCurve(data)
      if (info && info.signals && info.signals.length > 0) {
        const signals = info.signals.map(item => { return { ...item, libraryId: libraryId, fileName: fileName } })
        this.signals = [...this.signals, ...signals];
        if (!this.time || !this.time.length) {
          this.time = [...info.time];
        }
        this.curveData = [...this.curveData, ...info.curveData]
      }
    }
  }

  /** ------ implementation of WaveformResultHelper.plotWaveform ------
  *  Draw the raw curves
  *  @param  svgElement   - the SVG element where the plot is added
  *  @param  rawCurveData - a WaveformResultHelper.WaveformData object, containing the curve data of a byte
  *  @return a WaveformResultHelper.Plot object with a handle to the plot
  */
  plotWaveform = ({ svgElement, ui = 1e-9, events, padding, report = false, cycle, yRange, axis }) => {
    let xRange = null;
    if (report && cycle) {
      const _cycle = parseInt(cycle);
      if (_cycle >= 15) {
        xRange = [ui * 2 * 10, ui * 2 * 15];
      } else if (_cycle > 5 && _cycle < 15) {
        xRange = [ui * 2 * (_cycle - 5), ui * 2 * _cycle];
      }
    }

    if (this.plot === null) {
      const { PlotOptions, CurveItem } = Plot2D;
      // plot options
      const plotOptions = new PlotOptions();
      plotOptions.xUnit = 's';
      plotOptions.yUnit = axis && axis.yUnit ? axis.yUnit : 'V';
      plotOptions.grid = 'xy';
      plotOptions.zoom = 'x';
      plotOptions.xLabel = 'Time';
      plotOptions.yLabel = axis && axis.yLabel ? axis.yLabel : 'Voltage';
      plotOptions.ui = ui; //unit interval - approximate data cycle length
      this.plot = new Plot2D(svgElement, plotOptions, events, 'Rocky');
      const { signals, plot, time, curveData } = this;
      // prepare the curve data
      var curves = [];
      for (let i = 0; i < signals.length; i++) {
        const signal = signals[i];
        if (signal.name) {
          let newCurve = new CurveItem();
          newCurve.x = time;
          newCurve.y = curveData[i];
          newCurve.name = signal.name;
          newCurve.type = signal.type;
          if (signal.libraryId) {
            newCurve.libraryId = signal.libraryId;
            newCurve.fileName = signal.fileName;
          }
          curves.push(newCurve);
        }
      }

      // TODO
      // For write mode, shift the DQ signals by 1/2 ui. The JEDEC specification requires the
      // shift in timing. But we don't do this in our simulation so that the special handling
      // in post-processing can be avoided. Therefore, we shift the DQ curves in wavefore 
      // display to make it look like a conventional write behavior.


      // display the curves
      plot.plotCurves(curves, null, padding, xRange, yRange);
    } else {
      this.plot.redrawPlot(svgElement, padding, xRange, yRange);
    }
  } // plotWaveform

  updataRawCurvePlot = () => {
    this.plot.updatePlot();
  }

  getCurvesData = () => {
    const { signals, time, curveData } = this;
    const { CurveItem } = Plot2D;
    // prepare the curve data
    let curves = [];
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      if (signal.name) {
        let newCurve = new CurveItem();
        newCurve.x = time;
        newCurve.y = curveData[i];
        newCurve.name = signal.name;
        if (signal.libraryId) {
          newCurve.libraryId = signal.libraryId;
          newCurve.fileName = signal.fileName;
        }
        curves.push(newCurve);
      }
    }

    return curves;
  }

  addDifferentialSignal = (compInfo) => {
    const addSignals = compInfo.filter(item => item.signal && item.signal.includes(' - '));
    if (this.plot && this.plot.curves) {
      for (const addSignal of addSignals) {
        const [pSignal, nSignal] = addSignal.curves;
        const pSignalCurve = this.plot.curves.find(item => item.name === pSignal);
        const nSignalCurve = this.plot.curves.find(item => item.name === nSignal);
        if (pSignalCurve.y.length === nSignalCurve.y.length) {
          let newCurve = new Plot2D.CurveItem();
          newCurve.color = addSignal.color;
          newCurve.name = addSignal.curveName;
          newCurve.visible = false;
          newCurve.x = pSignalCurve.x;
          newCurve.y = pSignalCurve.y.reduce((prev, cur, curIndex) => {
            const _reduce = cur - nSignalCurve.y[curIndex];
            prev.push(_reduce);
            return prev;
          }, []);
          this.plot.curves.push(newCurve);
        }
      };
    }
  }

  addWave = (waveformInfo) => {
    const { signals, curveData } = waveformInfo;

    let filterSignals = [], filterCurveData = [];

    // filterCurveData = [...curveData];
    let _filterSignals = [...signals.map((item, index) => {
      let signalName = item && item.name ? item.name : "";
      const nameList = signalName.split(".");
      let isInst = false;
      if (nameList && nameList.length > 1) {
        isInst = true
        signalName = nameList[nameList.length - 1]
      }
      const _signal = signalName.toUpperCase();
      return { ...item, name: _signal, type: 'ssn_pdn', isInst: isInst, index }
    })];

    const rule = /\d+/g;
    _filterSignals = _filterSignals.sort((a, b) => {
      if ((a.isInst && b.isInst) || (!a.isInst && !b.isInst)) {
        const aNumbers = a.name.match(rule);
        const bNumbers = b.name.match(rule);
        if (aNumbers && aNumbers.length && bNumbers && bNumbers.length) {
          return aNumbers[0] - bNumbers[0]
        }
        return 0
      } else if (a.isInst && !b.isInst) {
        return 1
      }
      return -1
    })

    for (let info of _filterSignals) {
      const { index } = info;
      const data = curveData && curveData.length > index ? curveData[index] : [];
      filterCurveData.push(data)
      delete info.isInst;
      delete info.index;
      filterSignals.push(info)
    }

    this.signals = [...this.signals, ...filterSignals]
    this.curveData = [...this.curveData, ...filterCurveData];
  }
};

export default WaveformData;