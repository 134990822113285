import {
  INIT_STACKUP_DATA,
  CHANGE_STACKUP_TABLE,
  SETTING_UNIT,
  SAVE_MATERIAL,
  UPDATE_STACKUP_COLUMNS_STATUS,
  SAVE_STACKUP_TABLE_DATA,
  UPDATE_STACKUP_ERROR_MSG,
  SAVE_STACKUP_TO_SERVER,
  SAVE_ROUGHNESS,
  SAME_MENU_MATERIAL,
  DELETE_MATERIAL_LIST,
  UPDATE_SHOW_ZONES_STATUS,
  CHANGE_STACKUP_ZONE_PANEL_SHOW,
  UPDATE_SELECTED_ZONES,
  UPDATE_HIDDEN_COMPS,
  REMOVE_STACKUP_DATA
} from './actionTypes';

export const saveData = ({ data, materialList, stackups, zones, unit, designID, bends }) => ({
  type: INIT_STACKUP_DATA,
  data,
  materialList,
  stackups,
  zones,
  unit,
  designID,
  bends
})

export const changeStackupTable = (data, unit, designID) => ({
  type: CHANGE_STACKUP_TABLE,
  data,
  unit,
  designID
})

export const settingUnit = (unit, designID) => ({
  type: SETTING_UNIT,
  unit,
  designID
});

export const saveMaterial = ({ newMaterial, applyAllType, selectMaterialName, record, designID, dataIndex }) => ({
  type: SAVE_MATERIAL,
  newMaterial,
  applyAllType,
  selectMaterialName,
  record,
  designID,
  dataIndex
});

export const updateStackupColumnsStatus = (status, designID) => ({
  type: UPDATE_STACKUP_COLUMNS_STATUS,
  status,
  designID
});

export const saveStackupTableData = (record, dataType, designID) => ({
  type: SAVE_STACKUP_TABLE_DATA,
  record,
  dataType,
  designID
});

export const updateStackupErrorMsg = (errorObj, designID) => ({
  type: UPDATE_STACKUP_ERROR_MSG,
  errorObj,
  designID
});

export const saveStackupToServer = (stackupData, designID) => ({
  type: SAVE_STACKUP_TO_SERVER,
  stackupData,
  designID
});

export const saveRoughness = (roughnessObj, record, designID) => ({
  type: SAVE_ROUGHNESS,
  roughnessObj,
  record,
  designID
});

export const saveMenuMaterial = ({ newMaterial, prevMaterialInfo, designID }) => ({
  type: SAME_MENU_MATERIAL,
  newMaterial,
  prevMaterialInfo,
  designID
});

export const delMaterialList = (materialName, designID) => ({
  type: DELETE_MATERIAL_LIST,
  materialName,
  designID
});

export const updateShowZonesStatus = (show, designID) => ({
  type: UPDATE_SHOW_ZONES_STATUS,
  show,
  designID
});

export const changeStackupZonePanelShow = (show, designID) => ({
  type: CHANGE_STACKUP_ZONE_PANEL_SHOW,
  show,
  designID
});

export const updateSelectedZones = (selectedZones, designID) => ({
  type: UPDATE_SELECTED_ZONES,
  selectedZones,
  designID
})

export const updateHiddenCompsAction = (hiddenComps) => ({
  type: UPDATE_HIDDEN_COMPS,
  hiddenComps
})

export const removeStackupData = (designID) => ({
  type: REMOVE_STACKUP_DATA,
  designID
})