import React, { Component, Fragment } from 'react';
import { Spin, Divider, Radio, Tooltip, Checkbox } from 'antd';
import ResultLayout from '@/services/Result/Public/resultLayout';
import NP from 'number-precision';
import getIndex from '@/services/helper/insertionSearch';
import { numConversion } from '@/services/helper/dataProcess';
import WaveformSetting from '@/services/Result/Public/waveform/waveformSetting';
import ResultList, { RESULT } from '@/components/Sparameter/resultList';
import { getSweepList } from '@/services/Sierra';
import PostProcessEdgesContent from './PostProcessEdgesContent'
import MeasurementContent from './measurement/measurementContent'
import photo from '@/components/PublicSvg/photo.svg';
import saveSvg from 'save-svg-as-png';
/* import ResultZoom from '../../../../components/ResultTopMenu/resultZoom'; */
import '../index.css';
import './new.css';

NP.enableBoundaryChecking(false);
class WaveForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postProcessPanelVisible: false,
      postProcessConfig: {}
    }
  }

  componentDidMount = () => {
    if (this.props.compRef) {
      this.props.compRef(this);
    }
    window.addEventListener('resize', this.resize);
  }

  componentDidUpdate = (prevProps) => {
    const { verificationSubId, verificationId, recalc, sweepReCalc } = this.props;
    if (verificationId !== prevProps.verificationId && verificationSubId) {
      // Close postProcess when switching between different verifications
      this.setState({
        postProcessPanelVisible: false,
        postProcessConfig: {},
        currentPostProcessData: {}
      })
    }

    if ((recalc && recalc !== prevProps.recalc)
      || ((!prevProps.sweepReCalc || !prevProps.sweepReCalc.length) && (sweepReCalc && sweepReCalc.length))) {
      this.setState({
        postProcessPanelVisible: false,
        postProcessConfig: {},
        currentPostProcessData: {}
      }, () => {
        this.changeWidthCB()
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  onComponentChange = (values) => {
    this.props.updateState('selectedComponentKeys', values);
    const curves = this.props.waveform.getCurves();
    curves.forEach((curve, i) => {
      curve.visible = values.includes(i);
    });
    this.reloadAxisCB()
  }

  colorChange = (e, curveIndex) => {
    const value = e.toHexString()
    if (value) {
      const { compInfo, waveform } = this.props;
      const curves = waveform.getCurves();
      compInfo[curveIndex].color = value;
      curves[curveIndex].color = value;
      this.props.updateState('compInfo', compInfo);
      this.reloadAxisCB()
    }
  }

  reloadAxisCB = () => {
    this.props.waveform.updataRawCurvePlot();
  }

  axisChangeCB = (type, { _xMin, _xMax, _yMin, _yMax }) => {
    const plot = this.props.waveform.plot;
    if (type === 'xMin' || type === 'xMax') {
      const xMax = plot.options.xMax,
        xMin = plot.options.xMin,
        start = NP.times(_xMin, 1e-9),
        end = NP.times(_xMax, 1e-9);
      plot.xScale
        .domain([Math.min(start, end), Math.max(start, end)])
        .range([0, plot.size.width]);

      const xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = NP.times(NP.divide(NP.minus(xrange[0], xMin), NP.minus(xMax, xMin)), width),
        endX = NP.times(NP.divide(NP.minus(xrange[1], xMin), NP.minus(xMax, xMin)), width);
      plot.updateRange(startX, endX);
    } else {
      // y axis
      const yStart = numConversion(parseFloat(_yMin)),
        yEnd = numConversion(parseFloat(_yMax));
      plot.yScale
        .domain([Math.max(yStart, yEnd), Math.min(yStart, yEnd)])
        .nice()
        .range([0, plot.size.height])
        .nice();
    }
    this.reloadAxisCB()
  }

  changeMouse = (time, yPrefix) => {
    const { compInfo, waveform, dataList, sweep } = this.props;
    const curves = waveform.getCurves();
    let _dataList = { ...dataList }

    if (compInfo.length !== curves.length) return;
    let index = null;
    for (let k = 0; k < curves.length; k++) {
      if (!compInfo[k]) {
        continue;
      }
      const { listIndex, childIndex, groupName } = sweep ?
        this.getSweepListChildIndex(k, _dataList.Waveform.list)
        : this.getListChildIndex(curves[k], _dataList.Waveform.list);

      if (listIndex < 0 || childIndex < 0) {
        continue;
      }

      if (sweep) {
        if (!_dataList.Waveform.list[listIndex].children[childIndex].currentValue) {
          _dataList.Waveform.list[listIndex].children[childIndex].currentValue = {}
        }
      }

      if (curves[k].visible) {
        if (!index) {
          index = this._getIndex(curves[0].x, time);
        }
        if (curves[k].y.length === 0) {
          _dataList.Waveform.list[listIndex].children[childIndex].value = '';
        } else {
          const current = index > -1 ? yPrefix(curves[k].y[index]) + 'V' : '';
          if (sweep) {
            _dataList.Waveform.list[listIndex].children[childIndex].currentValue[groupName] = current;
          } else {
            _dataList.Waveform.list[listIndex].children[childIndex].value = current;
          }
        }
      } else {
        if (sweep) {
          _dataList.Waveform.list[listIndex].children[childIndex].currentValue[groupName] = "";
        } else {
          _dataList.Waveform.list[listIndex].children[childIndex].value = "";
        }
      }
    };
    this.props.updateState('dataList', _dataList);
  }

  getListChildIndex(curve, list) {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].children.length; j++) {
        if (list[i].children[j].id === curve.name) {
          return {
            listIndex: i,
            childIndex: j
          }
        }
      }
    }
    return {
      listIndex: -1,
      childIndex: -1
    }
  }

  getSweepListChildIndex(curveIndex, list) {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].children.length; j++) {
        const curveIndexObj = list[i].children[j].curveIndex;
        const groupName = Object.keys(curveIndexObj).find(item => curveIndexObj[item] === curveIndex);
        if (!!groupName) {
          return {
            listIndex: i,
            childIndex: j,
            groupName
          }
        }
      }
    }
    return {
      listIndex: -1,
      childIndex: -1,
      groupName: null
    }
  }

  cancelMove = () => {
    const { compInfo, waveform, dataList, sweep } = this.props;
    let _dataList = { ...dataList }
    const curves = waveform.getCurves();
    for (let k = 0; k < curves.length; k++) {
      if (!compInfo[k]) {
        continue;
      }
      const { listIndex, childIndex, groupName } = sweep
        ? this.getSweepListChildIndex(k, _dataList.Waveform.list)
        : this.getListChildIndex(curves[k], _dataList.Waveform.list);

      if (listIndex < 0 || childIndex < 0) {
        continue;
      }
      if (sweep) {
        if (!_dataList.Waveform.list[listIndex].children[childIndex].currentValue) {
          _dataList.Waveform.list[listIndex].children[childIndex].currentValue = {}
        }
      }
      if (curves[k].visible) {
        if (sweep) {
          _dataList.Waveform.list[listIndex].children[childIndex].currentValue[groupName] = "";
        } else {
          _dataList.Waveform.list[listIndex].children[childIndex].value = "";
        }
      }
    };
    this.props.updateState('dataList', _dataList);
  }

  changeAxis = (axis) => {
    this.props.waveformSetting && this.props.waveformSetting.setAxis(axis);
  }

  _getIndex(xPoints, time) {
    return getIndex(xPoints, time, 0, xPoints.length - 1);
  }

  clickPostProcessData = (data) => {
    const { portSelect, compInfo } = this.props;

    if (!data.display) {
      this.props.waveform.plot.removeCrossProbing([]);
      return;
    }

    let values = [];

    if (portSelect[data.signal] && portSelect[data.signal].length) {
      if (!portSelect[data.signal].includes(data.curveName)) {
        values = [...new Set([...portSelect[data.signal], data.curveName])]
      }
    } else {
      values = [data.curveName]
    }

    if (values.length) {
      this.props.changePort(values, data.signal)
    }
    const currentInfo = compInfo.find(item => item.portSelectId === data.curveName)
    this.props.waveform.plot.crossProbingPlot({ ...data, selectedKeys: [currentInfo.curveIndex], usage: currentInfo.usage })
  }

  printResult = (e) => {
    e.preventDefault();
    const options = {
      backgroundColor: '#ffffff',
      encoderOptions: 1
    }
    document.querySelector(".plot") && saveSvg.saveSvgAsPng(document.querySelector(".plot"), "Waveform_result.png", options);
  }

  resultLeft = () => {
    const { loading, recalc } = this.props;
    const { currentPostProcessData, postProcessPanelVisible } = this.state;
    const waveformHeight = postProcessPanelVisible && !recalc ? '58%' : '100%';
    return (
      <Spin spinning={loading} size='large'>
        <div
          className='waveform-svg'
          style={{ width: "100%", height: waveformHeight }}>
          <svg ref={this.props.svgRef}></svg>
          {/*  {ResultZoom({
            zoomIn: waveform && waveform.plot ? waveform.plot.zoomIn : null,
            zoomOut: waveform && waveform.plot ? waveform.plot.zoomOut : null,
            fitView: waveform && waveform.plot ? waveform.plot.fitView : null,
            plot: waveform ? waveform.plot : null
          })} */}
          <div className='sierra-result-curve-right'>
            <div className='photo-box' onClick={this.printResult}>
              <img src={photo} alt="" className='sierra-photo' />
            </div>
          </div>
        </div>
        {!recalc && <div style={{ height: '39%' }}>
          {/* {postProcessPanelVisible && <PostProcessPanelContent closeModal={this.changePostProcessVisible} currentPostProcessData={currentPostProcessData} clickPostProcessData={this.clickPostProcessData} />} */}
          {postProcessPanelVisible &&
            <PostProcessEdgesContent
              closeModal={this.changePostProcessVisible}
              currentPostProcessData={currentPostProcessData}
              clickPostProcessData={this.clickPostProcessData}
              onClearSelected={node => this.clearSelected = node}
              recalc={recalc}
            />}
        </div>}
      </Spin>
    )
  }

  PCBLegend = () => {
    const { allDesigns } = this.props;
    return <div>
      {allDesigns.map(item => {
        return <div key={item.name} className="sweep-waveform-pcb-legend">
          <div className="sweep-waveform-pcb-legend-content" style={{ position: 'absolute' }}>
            <span className="sweep-waveform-pcb-legend-color" style={{ background: item.color }}></span>
            <Tooltip title={item.name} overlayClassName="aurora-tooltip">
              <span className="sweep-waveform-pcb-legend-title">{item.name}</span>
            </Tooltip>
          </div>
        </div>
      })}
    </div>
  }

  ResultRowSelectCheckbox = (verticallyData) => {
    const { showChecked, experimentListData } = verticallyData;
    const { isPostProcess, sweepTimeShowList } = this.props;
    if (showChecked) {
      return <div className="sparameter-result-checkbox-content">
        <div style={{ display: 'inline-flex', width: '100%' }}>
          <div className="rocky-sparameter-result-checkout-blank"></div>
          {experimentListData.map((item, index) =>
            <Fragment key={item.name}>
              <span
                className='sparameter-result-menu-title sparameter-sweep-checkbox sparameter-all-sweep-checkbox'
                style={index === 0 ? { marginLeft: 240 } : {}}>
                <Checkbox
                  indeterminate={item.indeterminate}
                  onChange={(e) => this.props.changeSelectRowAll(item)}
                  checked={item.allChecked}
                ></Checkbox>
              </span>
              {isPostProcess && (sweepTimeShowList || []).includes(item.name) && <div className='sparameter-sweep-post-title-box'></div>}
            </Fragment>
          )}
        </div>
      </div>
    }
  }

  changePostProcessVisible = (e, type, postProcessData, rowName, currentId) => {
    // There is only one net selected when clicking
    e.stopPropagation();
    e.preventDefault()

    const { postProcessPanelVisible, currentPostProcessData } = this.state;
    const { portSelect } = this.props;
    let _postProcessPanelVisible = true, _postProcessData = {};
    if (type === 'sweep') {
      const { compInfo } = this.props;
      const _currentCompInfo = compInfo.find(item => item.portSelectId === currentId)
      if (_currentCompInfo) {
        _postProcessData = { ..._currentCompInfo.postProcessData, signal: rowName }
      }
    } else {
      _postProcessData = { ...postProcessData }
    }

    if (postProcessPanelVisible && currentPostProcessData) {
      if (_postProcessData.title === currentPostProcessData.title) {
        _postProcessData = {}
        _postProcessPanelVisible = false;
      }
    }

    this.setState({
      postProcessPanelVisible: _postProcessPanelVisible,
      currentPostProcessData: _postProcessData,
    }, () => {
      this.changeWidthCB()
      let _portSelect = { ...portSelect };

      if (!_portSelect[rowName] || !_portSelect[rowName].includes(currentId)) {
        const values = _portSelect[rowName] ? [..._portSelect[rowName], currentId] : [currentId];
        this.props.changePort(values, rowName)
      }
    })
  }

  changeDisplayMode = (e) => {
    this.clearSelected && this.clearSelected();
    this.props.displayModeChange(e)
  }

  resize = () => {
    this.clearSelected && this.clearSelected();
  }

  ResultPostProcess = (postProcessData) => {
    if (postProcessData && Object.keys(postProcessData).length) {
      const { edgesInfo } = postProcessData;
      if (!edgesInfo || (!edgesInfo.minInfo && !edgesInfo.maxInfo)) { return <div className="checkbox-group-table-box"></div> }
      return (<div className="checkbox-group-table-box">
        <div className='checkout-group-table-data-content'>
          {[edgesInfo.maxInfo.timeRising, edgesInfo.minInfo.timeRising, edgesInfo.maxInfo.timeFalling, edgesInfo.minInfo.timeFalling].map((item, index) => {
            const value = !isNaN(item) ? Number(item).toFixed(2) : item;
            return <Tooltip key={index} className="checkout-group-table-data-content-span" title={value} overlayClassName='aurora-tooltip'>{value}</Tooltip>
          })}
        </div>
      </div>)
    } else { return <div className="checkbox-group-table-box"></div> }
  }

  postProcessTitle = (fileName, type) => {
    if (fileName === 'resultList') {
      return ['Rising', 'Falling'].map((item, index) =>
        <span
          className='sparameter-result-menu-title sparameter-post-process-checkbox'
          key={item}
          style={type === 'sweep' ? {} : (index === 0 ? { marginLeft: 295 } : { paddingLeft: 47 })}
        >
          <Tooltip title={item} overlayClassName='aurora-tooltip'>{item}</Tooltip>
        </span>
      )
    }
    return ['Max', 'Min', 'Max', 'Min'].map((item, index) => {
      return <span
        className={index === 0 ? 'sparameter-result-menu-title sparameter-result-menu-post-title' : 'sparameter-result-menu-title sparameter-result-menu-post-title sparameter-result-menu-post-title-divider'}
        key={index}
        style={type === 'sweep' ? {} : (index === 0 ? { marginLeft: 286 } : {})}>
        {item}
      </span>
    })
  }

  resultRight = () => {
    const { displayMode, waveform, allDesigns,
      resultList, portSelect, dataList, sweep, allPortSelect,
      changeSelectSignalAll, changeSelectRowAll, isPostProcess,
      compInfo, verificationSubId, verificationId, currentProjectId,
      postProcessConfig, recalc, progress, currentInterfaceInfo,
      measurePanelVisible, singleProgress, sweepReCalc, sweepTimeShowList
    } = this.props;
    const { currentPostProcessData } = this.state;
    let data = {};
    if (resultList && resultList.length) {
      data = getSweepList(allPortSelect, resultList, portSelect);
    }
    return (
      <div className={!sweep ? "sierra-waveform-result-list sierra-waveform-result-post-process-list" : "sierra-waveform-result-list sierra-waveform-result-sweep-list"}>
        <div style={{ position: 'relative', height: '100%' }}>
          {sweep && this.PCBLegend()}
          <ResultList
            type={RESULT}
            displayMode={'Waveform'}
            portSelect={portSelect}
            changeAllPorts={this.props.changeAllPorts}
            changePort={this.props.changePort}
            colorChange={this.props.colorChange}
            resultList={dataList}
            rowNameClick={this.props._rowNameClick}
            experimentList={resultList}
            designs={allDesigns}
            allPortSelect={allPortSelect}
            changeSelectSignalAll={changeSelectSignalAll}
            changeSelectRowAll={changeSelectRowAll}
            ResultRowSelectCheckbox={this.ResultRowSelectCheckbox}
            verticallyData={data}
            ResultPostProcess={this.ResultPostProcess}
            postProcessTitle={this.postProcessTitle}
            changePostProcessVisible={this.changePostProcessVisible}
            isPostProcess={isPostProcess}
            changeSelectAll={this.props._changeSelectAll}
            currentPostProcessData={currentPostProcessData}
            recalc={recalc}
            sweepReCalc={sweepReCalc}
            sweepTimeShowList={sweepTimeShowList}
            showSweepTime={this.props.showSweepTime}
          />
        </div>
        <div className='waveform-post-process-box'>
          {/*  <Divider orientation="left" className='waveform-setting-title'>
            Measurement Conditions
            {recalc ? null
              : <Icon type="edit" className="aurora-icon-hover-color sierra-measure-edit-icon" onClick={(e) => this.changeMeasureVisible(true)} />}
          </Divider>
          {recalc && progress !== false && <Progress percent={progress >= 0 ? progress : 100} className="sierra-measure-progress" />} */}
          {/* <Spin spinning={recalc}> */}
          {measurePanelVisible ? <MeasurementContent
            compInfo={compInfo}
            experimentList={resultList}
            currentInterfaceInfo={currentInterfaceInfo}
            verificationSubId={verificationSubId}
            verificationId={verificationId}
            currentProjectId={currentProjectId}
            changeMeasureVisible={this.props.changeMeasureVisible}
            postProcessConfig={postProcessConfig}
            recalcMeasure={this.props.recalcMeasure}
            recalc={recalc}
            progress={progress}
            sweep={sweep}
            singleProgress={singleProgress}
            sweepReCalc={sweepReCalc}
            _updateSweepReCalc={this.props._updateSweepReCalc}
            _updateProgress={this.props._updateProgress}
          /> : null}
          {/*   </Spin> */}
        </div>
        <div className='waveform-setting-box'>
          <Divider orientation="left" className='waveform-setting-title'>Setting</Divider>
          <div className='waveform-setting-box-setup'>
            <span className='display-mode-title'>Display Mode</span>
            <Radio.Group onChange={this.changeDisplayMode} value={displayMode}>
              <Radio value={'Fast'}>Fast</Radio>
              <Radio value={'Hi-Re'}>Hi-Re</Radio>
            </Radio.Group>
            <WaveformSetting
              onRef={this.props.onRef}
              plot={waveform ? waveform.plot : null}
              axisChangeCB={this.axisChangeCB}
              reloadAxisCB={this.reloadAxisCB}
            />
          </div>
        </div>
      </div>
    );
  }

  changeWidthCB = () => {
    if (this.props.waveform) {
      this.props.waveform.plot.redrawPlot(this.props.svgRef.current);
    }
  }

  render() {
    return (
      <div className='waveform waveform-clear sierra-result-waveform'>
        <ResultLayout
          defaultRightWidth={360}
          resultLeft={this.resultLeft}
          resultRight={this.resultRight}
          changeWidthCB={this.changeWidthCB}
        />
      </div>
    )
  }
}

export default WaveForm;