import { getPowerNetsTable, updatePowerNetsTable } from '../../api/compSetting';
import apiProcess from '../../api/utility';
import debounce from '../../helper/debounceFn';

class PowerTableHelper {
  constructor() {
    this.powerTable = new Map();  // key - designId, value - { data:[{net,voltage}] }
  }

  saveTable = (designId, table) => {
    this.powerTable.set(designId, table);
  }

  getTable = async (designId, type = "net") => {
    if (!designId) {
      return [];
    }

    let nets = this.powerTable.get(designId);
    if (!nets || !nets.length) {
      try {
        nets = await getPowerTable(designId);
        //if nets voltage has "V",Remove
        const info = removeNetsVoltageUnit(nets, true);
        nets = info.nets;
        if (info.save) {
          updateTables([{ designId, nets }]);
        }
        this.saveTable(designId, nets);
      } catch (err) {
        console.error(err);
      }
    }
    if (type === "netName") {
      return (nets || []).map(item => item.net)
    }
    return nets || []
  }

  getTableData = async (designId) => {
    const nets = await this.getTable(designId);
    return nets && nets.length ? nets : [];
  }

  async updateTable(powerTables) {
    try {
      debounce(async () => {
        await updateTables(powerTables);
        powerTables.forEach(obj => {
          const { designId, nets } = obj;
          this.saveTable(designId, nets);
        })
      }, 500, false, 'sierraUpdatePowerTable')()
    } catch (error) {
      console.error(error)
    }
  }

  clearTable = (designId) => {
    this.saveTable(designId, null);
  }

  clearAll = () => {
    this.powerTable = new Map();
  }
}

function getPowerTable(designId) {
  return apiProcess(getPowerNetsTable, { designId });
}

function updateTables(powerTables) {
  return apiProcess(updatePowerNetsTable, powerTables);
}

function removeNetsVoltageUnit(nets, isTable) {
  const unitTeg = new RegExp("V", "ig");
  const key = isTable ? "voltage" : "value";
  const findVoltage = nets.find(item => item[key] && (item[key].match(/[\s\t]+/g) || item[key].match(unitTeg)))

  if (!findVoltage) {
    return { nets, save: false };
  }

  for (let item of nets) {
    if (item[key] && (item[key].match(/[\s\t]+/g) || item[key].match(unitTeg))) {
      item[key] = item[key].replace(/[\s\t]+/g, "");
      item[key] = item[key].replace(unitTeg, "");
    }
  }
  return { nets, save: true };
}

const powerStore = new PowerTableHelper();

export default powerStore;
export {
  removeNetsVoltageUnit,
  updateTables
}