import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Menu, Tooltip } from 'antd';
import SignalsNetsTable from './SignalsNetsTable';
import PowerNetsTable from './PowerNetsTable';
import ComponentTable from './ComponentTable';
import PinTable from './PinTable';
import ConnectorTable from './ConnectorTable_v2';
import PowerComponents from './PowerComponents';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import { IBIS, SPICE, CONNECTOR_SPICE_SIERRA, CONNECTOR_TOUCHSTONE, VECTOR, PASSIVE_TOUCHSTONE, PASSIVE_SPICE, REPEATER, PKG_TOUCHSTONE, PKG_SPICE } from '@/constants/libraryConstants';
import { updateLibraryTree } from '../../store/library/action';
import { getLibraryIndex } from '@/services/Sierra/library/libraryData'
import {
  updateGetCompPrefixStatus,
  updateCompRLCPrefix
} from '../../store/sierra/action';
import componentSetting from '@/services/helper/componentsHelper/compSettingHelper';
import DelConfirm from '../../../../components/DelConfirm';
import VirtualComponentTable from './VirtualComponentTable';
import { startSierraVerification, updateConnectorErrors } from '../../store/simulation/action';
import designConstructor from '../../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import '../index.css';
import compTableHelper from '../../../../services/Sierra/helper/compTableHelper';


class PinToPinSetting extends PureComponent {
  constructor(props) {
    super(props);
    const ibisList = sierraLibrary.getTree(IBIS);
    const spiceList = sierraLibrary.getTree(SPICE);
    const repeaterList = sierraLibrary.getTree(REPEATER);
    const passiveSpiceList = sierraLibrary.getTree(PASSIVE_SPICE);
    const passiveTouchstoneList = sierraLibrary.getTree(PASSIVE_TOUCHSTONE);
    const pkgSpiceList = sierraLibrary.getTree(PKG_SPICE);
    const pkgTouchstoneList = sierraLibrary.getTree(PKG_TOUCHSTONE);
    const connSpiceList = sierraLibrary.getTree(CONNECTOR_SPICE_SIERRA);
    const connTouchstoneList = sierraLibrary.getTree(CONNECTOR_TOUCHSTONE);
    const vectorList = sierraLibrary.getTree(VECTOR);
    this.state = {
      saving: false,
      current: "",
      below: false,
      ibisList,
      spiceList,
      repeaterList,
      passiveSpiceList,
      passiveTouchstoneList,
      pkgSpiceList,
      pkgTouchstoneList,
      connSpiceList,
      connTouchstoneList,
      vectorList,
      compPrefixLibInfo: {},
      compPinMap: {},
      isPreLayout: false
    }

  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount() {
    this.screenChange();
    const { tabVisible, Interfaces, libraryTreeInfo } = this.props;
    this.bottomRef = document.getElementsByClassName('sierra-content-bottom')[0];

    if (this.bottomRef) {
      let width = this.bottomRef.offsetWidth;
      if (width < 1000 || tabVisible) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }

    this.leftRef = document.getElementsByClassName('sierra-content-left')[0];

    if (this.leftRef) {
      let width = this.leftRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }

    if (!this.state.current && Interfaces.length > 0) {
      const vendor = designConstructor.getDesignVendor(Interfaces[0].pcbId);
      const isPreLayout = vendor === PRE_LAYOUT ? true : false;
      this.setState({
        current: Interfaces[0].pcb,
        isPreLayout
      })

    }

    if (libraryTreeInfo) {
      this.getLibraryList(libraryTreeInfo)
      this.props._updateLibraryTree(null);
    }

    if (Interfaces.length) {
      this.getCompPrefixLibInfo(Interfaces)
    }
  }

  componentWillUnmount() {
    if (this.cancelableLoadLayout) {
      this.cancelableLoadLayout.cancel();
    };
    if (this.PowerDomainNetsCancel) {
      this.PowerDomainNetsCancel.cancel();
    }
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const { tabVisible } = this.props;
    this.bottomRef = document.getElementsByClassName('sierra-content-bottom')[0];
    this.leftRef = document.getElementsByClassName('sierra-content-left')[0];
    this.mainRef = document.getElementsByClassName('aurora-content')[0];
    if (this.bottomRef) {
      let width = this.bottomRef.offsetWidth;
      if (width < 1000 || tabVisible) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    } else if (this.leftRef) {
      let width = this.leftRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    } else {
      let width = this.mainRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { tabVisible, sierraResize, Interfaces, libraryTreeInfo } = this.props;
    this.bottomRef = document.getElementsByClassName('sierra-content-bottom')[0];
    if (this.bottomRef && prevProps.tabVisible !== tabVisible) {
      let width = this.bottomRef.offsetWidth, below = false;
      if (width < 1000 || tabVisible) {
        below = true;
      }
      this.setState({
        below
      });
    }

    if (sierraResize !== prevProps.sierraResize) {
      this.contentRef = document.getElementById('sierra-content-main');
      let width = this.contentRef.offsetWidth, below = false;
      if (width < 1000) {
        below = true;
      }
      this.setState({
        below
      });
    }

    if (!Interfaces.length) {
      this.setState({
        current: "",
        isPreLayout: false
      });
      //switch interface Interfaces[0].interfaceId !== prevProps.Interfaces[0].interfaceId
      //delete one interface Interfaces.length !== prevProps.Interfaces.length
    } else if (!prevProps.Interfaces.length || Interfaces[0].interfaceId !== prevProps.Interfaces[0].interfaceId || (Interfaces.length !== prevProps.Interfaces.length)) {
      const vendor = designConstructor.getDesignVendor(Interfaces[0].pcbId);
      const isPreLayout = vendor === PRE_LAYOUT ? true : false;
      this.setState({
        current: Interfaces[0].pcb,
        isPreLayout
      })
      //Interface pcb replace
    } else if (prevProps.Interfaces.length) {
      const currentInterfaceIndex = prevProps.Interfaces.findIndex(item => item.pcb === this.state.current);
      const vendor = designConstructor.getDesignVendor(Interfaces[currentInterfaceIndex].pcbId);
      const isPreLayout = vendor === PRE_LAYOUT ? true : false;
      if (currentInterfaceIndex >= 0 && prevProps.Interfaces[currentInterfaceIndex].pcbId !== Interfaces[currentInterfaceIndex].pcbId) {
        this.setState({
          current: Interfaces[currentInterfaceIndex].pcb,
          isPreLayout
        })
      }
    }
    if (libraryTreeInfo && libraryTreeInfo.length && (!prevProps.libraryTreeInfo || libraryTreeInfo.length !== prevProps.libraryTreeInfo.length)) {
      this.getLibraryList(libraryTreeInfo)
      this.props._updateLibraryTree(null);
    }

    const { verificationId, updateCompPrefixLibStatus } = this.props;
    if (Interfaces.length !== prevProps.Interfaces.length || verificationId !== prevProps.verificationId || (updateCompPrefixLibStatus && updateCompPrefixLibStatus !== prevProps.updateCompPrefixLibStatus)) {
      this.getCompPrefixLibInfo(Interfaces)
      this.props._updateGetCompPrefixStatus(false)
    }
  }

  getCompPrefixLibInfo = async (Interfaces) => {
    let compPrefixLibInfo = {}, compPinMap = {};
    const _Interfaces = Interfaces.filter(item => {
      const vendor = designConstructor.getDesignVendor(item.pcbId);
      if (vendor === PRE_LAYOUT) {
        return null
      }
      return item
    })
    const dataPromise = _Interfaces.map(async item => ({
      id: item.pcbId,
      data: await componentSetting.getSetting({ designId: item.pcbId }),
      passiveTable: await compTableHelper.getTableData(item.pcbId)
    }))
    const dataList = await Promise.all(dataPromise);
    for (let item of dataList || []) {
      if (item.data && item.data.compPrefixLib) {
        compPrefixLibInfo[item.id] = item.data.compPrefixLib;
        compPinMap[item.id] = item.data.pinMap;
      }
    }
    this.setState({
      compPrefixLibInfo: compPrefixLibInfo,
      compPinMap: compPinMap
    })
  }

  handleMenuClick = ({ key }) => {
    const { Interfaces } = this.props;
    const Interface = Interfaces.find(item => item.pcb === key);
    const vendor = designConstructor.getDesignVendor(Interface.pcbId);
    const isPreLayout = vendor === PRE_LAYOUT ? true : false;
    this.setState({
      current: key,
      isPreLayout
    });
  }

  getLibraryList = (typeList) => {
    for (let type of typeList) {
      const { listName } = getLibraryIndex(type);
      this.setState({
        [listName]: sierraLibrary.getTree(type)
      })
    }
  }

  updateCompPrefixVersion = async (bool) => {
    const { updateCompPrefix } = this.props;
    this.props._updateCompRLCPrefix([...(updateCompPrefix || []).map(item => item.pcbId)], bool)
  }

  reSimulate = () => {
    const { connError } = this.props;
    this.props._startSierraVerification([connError.id], connError.id, { updateConn: true })
    this.props._updateConnectorErrors(null);
  }

  simulationCancel = () => {
    this.props._updateConnectorErrors(null);
  }

  render() {
    const { Interfaces, pcbLoading, updateCompPrefix, connError, verificationId } = this.props;
    const { below, current,
      ibisList, spiceList, repeaterList, passiveSpiceList, passiveTouchstoneList,
      pkgSpiceList, pkgTouchstoneList, vectorList, connSpiceList, connTouchstoneList,
      compPrefixLibInfo, isPreLayout, compPinMap
    } = this.state;
    return (
      <div className='sierra-content-setting'>
        <div className='sierra-content-wrap'>
          {below ?
            <Fragment>
              <Row>
                <Col span={24} className='sierra-content-block-border'>
                  <SignalsNetsTable />
                </Col>
              </Row>
              <div className='margin-top-20 sierra-content-block-border'>
                <span className="font-bold sierra-setup-title-color">Power / Ground Nets</span>
                <PowerNetsTable below={below} />
              </div>
            </Fragment> :
            <div style={{ display: 'flex' }}>
              <div className='sierra-content-block-border' style={{ width: '58.3333%' }}>
                <SignalsNetsTable />
              </div>
              <div className='sierra-content-block-border' style={{ marginLeft: below ? 0 : 20, width: below ? '41.6667%' : 'calc(41.6667% - 20px)' }}>
                <span className="font-bold sierra-setup-title-color">Power / Ground Nets</span>
                <PowerNetsTable />
              </div>
            </div>}
          <Fragment>
            <div className='sierra-pcb-content sierra-content-block-border'>
              {Interfaces && Interfaces.length > 1 &&
                <Fragment>
                  <span className="font-bold sierra-setup-pcb-title">PCB</span>
                  <Menu
                    onClick={this.handleMenuClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    className='sierra-pcb-menu'
                    items={Interfaces.map(({ pcb }) => ({ key: pcb, label: pcb }))}
                  /></Fragment>}
              <div className='padding-top-10'>
                <span className="font-bold sierra-setup-title-color">Components</span>
                {pcbLoading && <Tooltip
                  title="Usage can be modified after PCB loading over."
                  overlayClassName="aurora-tooltip"
                  placement="right"
                >
                  <InfoCircleOutlined className="sierra-pcbloading-icon" />
                </Tooltip>}
                <ComponentTable
                  below={below}
                  current={current}
                  ibisList={ibisList}
                  spiceList={spiceList}
                  repeaterList={repeaterList}
                  passiveSpiceList={passiveSpiceList}
                  passiveTouchstoneList={passiveTouchstoneList}
                  pkgSpiceList={pkgSpiceList}
                  pkgTouchstoneList={pkgTouchstoneList}
                  compPrefixLibInfo={compPrefixLibInfo}
                  compPinMap={compPinMap}
                />
              </div>
              {!isPreLayout ? <div className='margin-top-20'>
                <VirtualComponentTable
                  current={current} />
              </div> : null}
              <div className='margin-top-20'>
                <span className="font-bold sierra-setup-title-color">Setup</span>
                <PinTable
                  below={below}
                  current={current}
                  ibisList={ibisList}
                  spiceList={spiceList}
                  repeaterList={repeaterList}
                  vectorList={vectorList}
                />
              </div>
              {/* showPowerComponents && */
                <div className='margin-top-20'>
                  <span className="font-bold sierra-setup-title-color">Power / Ground Components</span>
                  <PowerComponents below={below} current={current} compPrefixLibInfo={compPrefixLibInfo} compPinMap={compPinMap} />
                </div>}
            </div>
          </Fragment>
          <ConnectorTable spiceList={connSpiceList} touchstoneList={connTouchstoneList} below={below} />
        </div>
        <div id='sierra-model-dialog'></div>
        {updateCompPrefix && updateCompPrefix.length ? <DelConfirm
          type='update'
          width={420}
          className="sierra-component-setting-update-panel"
          onUpdate={() => this.updateCompPrefixVersion(true)}
          cancelUpdate={() => this.updateCompPrefixVersion(false)}
          message={this.getUpdateSettingMsg(updateCompPrefix)}
        /> : null}
        {connError && connError.id === verificationId && connError.connError ? <DelConfirm
          type='update'
          width={420}
          cancelTitle="Cancel"
          className="sierra-component-connector-update-panel"
          onUpdate={() => this.reSimulate()}
          cancelUpdate={() => this.simulationCancel()}
          message={connError.connError}
        /> : null}
      </div>
    );
  }

  getUpdateSettingMsg = (updateCompPrefix) => {
    return <div className='sierra-component-setting-update-content'>
      <span>The Components & Nets Setting of the current PCB has been updated. Do you want to update the interface?</span>
      {updateCompPrefix.find(it => it.updateInfo && it.updateInfo.length) ?
        <div className='sierra-component-setting-update-component-content'>
          <span className='component-type-title'>Component usage update info:</span>
          {updateCompPrefix.map(item =>
            item.updateInfo && item.updateInfo.length ? <div key={item.pcbName}>
              <label className='component-type-title component-type-pcb'>{item.pcbName} :</label>
              {item.updateInfo.map((item, index) =>
                <div className='sierra-component-setting-update-component' key={index}>
                  <label className='component-type-title'>{item.title}</label>
                  <label>{item.info}</label>
                </div>)}
            </div> : null)}
        </div> : null}
    </div>
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra, project: { verificationId }, simulationReducer: { connError }, library: { libraryTreeInfo } } } = state;
  const { tabVisible } = state.TabMonitorReducer;
  const sierraInfo = sierra.sierraInfo;
  let Interfaces = [];
  if (sierraInfo) {
    Interfaces = sierraInfo.Interfaces || [];
  }
  return {
    tabVisible,
    Interfaces,
    verificationId,
    libraryTreeInfo,
    pcbLoading: sierra.pcbLoading,
    updateCompPrefix: sierra.updateCompPrefix,
    connError,
    updateCompPrefixLibStatus: sierra.updateCompPrefixLibStatus
  };
}

const mapDispatch = (dispatch) => ({
  _updateLibraryTree(type) {
    dispatch(updateLibraryTree(type))
  },
  _updateGetCompPrefixStatus(status) {
    dispatch(updateGetCompPrefixStatus(status))
  },
  _updateCompRLCPrefix(updateCompPrefix, update) {
    dispatch(updateCompRLCPrefix(updateCompPrefix, update))
  },
  _updateConnectorErrors(error) {
    dispatch(updateConnectorErrors(error))
  },
  _startSierraVerification(verificationIds, currentVerificationId, params) {
    dispatch(startSierraVerification(verificationIds, currentVerificationId, params));
  },
})

export default connect(mapState, mapDispatch)(PinToPinSetting);
