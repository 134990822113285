import { combineReducers } from 'redux';
import { CascadeProjectReducer } from './project';
import { CascadeDCRReducer } from './DCR';
import CascadeUploadReducer from '../CascadeSider/uploadPCB/store/reducer'
import { CascadeSimulationReducer } from './simulation'
import { CascadeLibraryRenducer } from './library'
import { CascadeIRReducer } from './IRExplorer';
import { CascadeResultReducer } from './result';
import { CascadeImpedanceReducer } from './Impedance';
import { default as explorerReducer } from '../../LayoutExplorer/store/Cascade/reducer';
import { resultReducer } from './result/IR/reducer';
import { impedanceReducer } from './result/Impedance/reducer';
import { PowerTreeReducer } from './PowerTree';
import { SignOffTemplateReducer } from './SignOffTemplate';
import { DesignTreeReducer } from './DesignTree';
import { CascadePreLayoutReducer } from './prelayout';

const rootReducer = combineReducers({
  project: CascadeProjectReducer,
  prelayout: CascadePreLayoutReducer,
  DCR: CascadeDCRReducer,
  CascadeUploadReducer,
  explorer: explorerReducer,
  simulation: CascadeSimulationReducer,
  library: CascadeLibraryRenducer,
  IR: CascadeIRReducer,
  result: CascadeResultReducer,
  IRResult: resultReducer,
  Impedance: CascadeImpedanceReducer,
  ImpedanceResult: impedanceReducer,
  PowerTree: PowerTreeReducer,
  SignOffTemplate: SignOffTemplateReducer,
  DesignTree: DesignTreeReducer
})

export default rootReducer;