import CeLayerBase from './CeLayerBase';
import CeRefGeometry from '../geometry/CeRefGeometry';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeLayerDocument = function () {
  CeLayerBase.call(this, CeLayerBase.CeLayerType.DOCUMENT);
  this.mGeomList = [];
};

// inherits from CeLayerBase
CeLayerDocument.prototype = Object.create(CeLayerBase.prototype);
CeLayerDocument.prototype.constructor = CeLayerDocument;

CeLayerDocument.prototype.ReadFromIODataNode = function (layerBlock) {

  CeLayerBase.prototype.ReadFromIODataNode.call(this, layerBlock);

  var geomBlks = layerBlock.GetSubBlocksOfSameName("Geometry");
  for (var i = 0; i < geomBlks.length; i++) {
    var refGeom = new CeRefGeometry();
    if (refGeom.ReadFromIODataNode(geomBlks[i]) == true)
      this.mGeomList.push(refGeom);
  }

};

CeLayerDocument.prototype.WriteIntoIODataNode = function (layerBlock) {
  CeLayerBase.prototype.WriteIntoIODataNode.call(this, layerBlock);
  for (var i = 0; i < this.mGeomList.length; i++) {
    var block = new CeIODataBlock("Geometry");
    this.mGeomList[i].WriteIntoIODataNode(block);
    layerBlock.AddBlockNode(block);
  }

};

export default CeLayerDocument;