import NP from 'number-precision';
import { strDelimited } from '../../../helper/split';
import { db, radiansToDegrees } from '../../../helper/sparameter/utility';
import getIndex from '../../../helper/insertionSearch';
import { IMPORT, OPTIMIZATION, SWEEPMODEL } from '../../../../components/Sparameter/resultList';
import { MULTI_PCB_TARGET } from '../../../../constants/uncategorized';
import { CPHY } from '../../../PCBHelper/constants';

export function axisFormat(xUnit, axis) {
  let _axis = { ...axis };
  let format = 1;
  if (xUnit === 'KHz' && axis.xMax) {
    format = 1e-3;
  } else if (xUnit === 'MHz' && axis.xMax) {
    format = 1e-6;
  } else if (xUnit === 'GHz' && axis.xMax) {
    format = 1e-9;
  }

  if (axis.xMax) {
    _axis.xMax = NP.strip(NP.times(_axis.xMax, format));
    if (_axis.xMax < 10000) {
      // Remove the useless decimal points
      _axis.xMax = parseFloat(_axis.xMax.toFixed(3));
    } else {
      _axis.xMax = parseFloat(_axis.xMax);
    }
  }

  if (_axis.xMin) {
    _axis.xMin = NP.strip(NP.times(_axis.xMin, format));
    _axis.xMin = parseFloat(_axis.xMin.toFixed(3));
  }
  return _axis;
}

/**
 * Sparameter port change, TODO
 * portSelect = { [rowName]: [portItems] }
 * values = [portItems]
 * @export
 * @param {*} { portSelect, values, key }
 * @returns
 */
export function portChange({ portSelect, values, key }) {
  let _portSelect = { ...portSelect };
  if (!_portSelect[key]) {
    _portSelect[key] = [];
  }
  const prev = _portSelect[key] || [];
  if (!Array.isArray(values)) {
    return false;
  }

  // find uncheck, pre ['0::0','0::1'] -> values ['0::0']
  let uncheck = null, newCheck = null;
  if (prev.length - values.length === 1) {
    const _uncheck = prev.filter(p => !values.includes(p));
    if (_uncheck.length === 1) {
      uncheck = _uncheck[0];
    };
  };

  if (uncheck) {
    const selectIndex = _portSelect[key].findIndex(port => port === uncheck);
    _portSelect[key].splice(selectIndex, 1);
  } else {
    _portSelect[key] = [...new Set([..._portSelect[key], ...values])];
    const newCheckList = _portSelect[key].filter(p => !prev.includes(p));
    if (newCheckList.length === 1) {
      newCheck = newCheckList[0];
    } else {
      return false;
    }
  };
  return {
    _portSelect,
    uncheck,
    newCheck
  }
}

/**
 *
 * prevSelects = [portItems]
 * values = [portItems]
 * @export
 * @param {*} { portSelect, values, key }
 * @returns
 */
export function selectedChange({ prevSelects, values }) {
  // find uncheck, pre ['0::0','0::1'] -> values ['0::0']
  const unchecks = prevSelects.filter(p => !values.includes(p));
  const newChecks = values.filter(v => !prevSelects.includes(v));
  return {
    unchecks,
    newChecks
  }
}

export async function displayCurve({ files, fileId, row, col, hashId, Parameters, setting, couplingType, curveType: _curveType, wordIndex }) {
  let updateFiles = [...files];
  let parameter = Parameters.getParameter(fileId);
  if (!parameter) {
    return {};
  }
  const curveType = _curveType ? _curveType : parameter.type; // simulation || target || history
  const fileName = parameter.fileName;
  let fileIndex = updateFiles.findIndex(item => item.id === fileId);
  if (!updateFiles[fileIndex] || !updateFiles[fileIndex].ports || !parameter.ports) {
    return {};
  }

  const color = updateFiles[fileIndex].matrix[row][col].color;
  const curve = await Parameters.showCurve({ parameter, fileIndex, row, col, hashId, param: setting.parameter, value: setting.value, curveType, fileName, couplingType, wordIndex });
  if (curve) {
    updateFiles[fileIndex].matrix[row][col][curve.type] = curve.y;
    updateFiles[fileIndex].matrix[row][col].color = color;
    curve.visible = true;
    if (color) {
      curve.color = color;
    }
  }
  return { updateFiles, curve, color };
}

function getCPHYPinPairByComponents(signals = [], components = []) {
  let nets_A = [], nets_B = [], nets_C = [];
  for (const signal of signals) {
    nets_A.push(...signal.nets_A);
    nets_B.push(...signal.nets_B);
    nets_C.push(...signal.nets_C);
  }

  const pins_A = [], pins_B = [], pins_C = [];
  for (const com of components) {
    for (const pinInfo of com.pins) {
      if (nets_A.includes(pinInfo.net)) {
        pins_A.push(pinInfo.pin);
      } else if (nets_B.includes(pinInfo.net)) {
        pins_B.push(pinInfo.pin);
      } else if (nets_C.includes(pinInfo.net)) {
        pins_C.push(pinInfo.pin);
      }
    }
  }

  return {
    'A': pins_A,
    'B': pins_B,
    'C': pins_C
  }
}

function displayName(port, type, pinMap) {
  let name = port.name;
  if (type === CPHY) {
    let pair = '';
    for (const key of Object.keys(pinMap)) {
      if (pinMap[key] && pinMap[key].includes(port.positivePin)) {
        pair = key + pair;
      }
      if (pinMap[key] && pinMap[key].includes(port.negativePin)) {
        pair = pair + key;
      }
    }
    name = port.name.replace(port.signal, `${port.signal} (${pair})`);
  }
  return port.portType === "target" ? `${port.power ? port.power : name}` : name;
}

function resultListMultiDefault(resultList, portList, getColorByIndex, files) {

  let _default = [];
  for (let j = 0; j < files.length; j++) {
    const { rowName, ports } = files[j];
    let _portList = ports.filter(d => d.index !== undefined);
    let childrenInfo = [];
    for (let i = 0; i < _portList.length; i++) {
      const port = _portList[i];
      const findPortInfo = portList.find(d => d.name === port.name);
      const portIndex = findPortInfo.portIndex || findPortInfo.index;
      childrenInfo[i] = { rowName: `Port ${portIndex} (${displayName(findPortInfo)})`, children: [] };
      let designStringLength = 0;
      childrenInfo[i].children = _portList.map(_port => {
        const _portIndex = _port.portIndex || _port.index;
        const namePrev = `S(${portIndex}, ${_portIndex})`;
        designStringLength = _port.designStringLength + namePrev.length > designStringLength ? _port.designStringLength + namePrev.length : designStringLength;
        return {
          ..._port,
          namePrev,
          portIndex,
          _portIndex,
          id: `${findPortInfo.fileId}::${portIndex - 1}::${_portIndex - 1}`,
          name: `${namePrev} ${portIndex !== _portIndex ? displayName(_port) : ''}`,
          color: getColorByIndex(findPortInfo.fileId, portIndex - 1, _portIndex - 1)
        }
      })
    }
    _default[j] = {
      rowName: rowName,
      children: childrenInfo
    }

  }

  resultList.Default = { list: _default, show: _default[0] ? _default[0].children && _default[0].children[0] ? [_default[0].rowName, _default[0].children[0].rowName] : [_default[0].rowName] : [] };
  return resultList;

}

export function resultListDefault(resultList, portList, getColorByIndex, interfaceContent = {}, isMultiList, files) {

  if (interfaceContent && interfaceContent.type === CPHY) {
    return resultListCPHYDefault(resultList, portList, getColorByIndex, interfaceContent);
  }

  if (isMultiList && files && files.length) {
    return resultListMultiDefault(resultList, portList, getColorByIndex, files);
  }

  let _portList = portList.filter(d => d.index !== undefined);
  let _default = [];
  for (let i = 0; i < _portList.length; i++) {
    const port = _portList[i];
    const portIndex = port.portIndex || port.index;
    _default[i] = { rowName: `Port ${portIndex} (${displayName(port)})`, children: [] };
    let designStringLength = 0;
    _default[i].children = _portList.filter(item => item.fileId === port.fileId).map(_port => {
      const _portIndex = _port.portIndex || _port.index;
      const namePrev = `S(${portIndex}, ${_portIndex})`;
      designStringLength = _port.designStringLength + namePrev.length > designStringLength ? _port.designStringLength + namePrev.length : designStringLength;
      return {
        ..._port,
        namePrev,
        portIndex,
        _portIndex,
        id: `${port.fileId}::${portIndex - 1}::${_portIndex - 1}`,
        name: `${namePrev} ${portIndex !== _portIndex ? displayName(_port) : ''}`,
        color: getColorByIndex(port.fileId, portIndex - 1, _portIndex - 1)
      }
    })
    _default[i].children.forEach(d => d.designStringLength = designStringLength * 9)
  }
  resultList.Default = { list: _default, show: _default[0] ? [_default[0].rowName] : [] };
  return resultList;
}

export function resultListCPHYDefault(resultList, portList, getColorByIndex, interfaceContent = {}) {

  const signals = interfaceContent && interfaceContent.content && interfaceContent.content.signals ? interfaceContent.content.signals : [];
  const components = interfaceContent && interfaceContent.content && interfaceContent.content.components ? interfaceContent.content.components : [];
  const pinMap = getCPHYPinPairByComponents(signals, components);

  let _portList = portList.filter(d => d.index !== undefined);
  let _default = [];
  for (let i = 0; i < _portList.length; i++) {
    const port = _portList[i];
    const portIndex = port.portIndex || port.index;
    _default[i] = { rowName: `Port ${portIndex} (${displayName(port, interfaceContent.type, pinMap)})`, children: [] };
    let designStringLength = 0;
    _default[i].children = _portList.filter(item => item.fileId === port.fileId).map(_port => {
      const _portIndex = _port.portIndex || _port.index;
      const namePrev = `S(${portIndex}, ${_portIndex})`;
      designStringLength = _port.designStringLength + namePrev.length > designStringLength ? _port.designStringLength + namePrev.length : designStringLength;
      return {
        ..._port,
        namePrev,
        portIndex,
        _portIndex,
        id: `${port.fileId}::${portIndex - 1}::${_portIndex - 1}`,
        name: `${namePrev} ${portIndex !== _portIndex ? displayName(_port, interfaceContent.type, pinMap) : ''}`,
        color: getColorByIndex(port.fileId, portIndex - 1, _portIndex - 1)
      }
    })
    _default[i].children.forEach(d => d.designStringLength = designStringLength * 9)
  }
  resultList.Default = { list: _default, show: _default[0] ? [_default[0].rowName] : [] };
  return resultList;
}

export function getColor({ files, fileId, row, col }) {
  const _file = files.find(file => file.id === fileId);
  return _file.matrix[row][col].color;
}

export function getSelectAllinSignalCurves({ e, group, portSelect, files, parameters, setState, type }) {

  if (type === CPHY) {
    return getCPHYSelectAllinSignalCurves({ e, group, portSelect, files, parameters, setState })
  }

  const check = e.target.checked;
  let _portSelect = portSelect;
  const prevSelect = _portSelect[group.rowName] || [];
  if (check) {
    _portSelect[group.rowName] = group.children.map(d => d.id);
  } else {
    _portSelect[group.rowName] = [];
  };
  setState(_portSelect);
  if (check) {
    const allSelect = group.children.map(d => d.id);
    const newChecks = allSelect.filter(a => !prevSelect.includes(a));
    const curves = newChecks.map(d => {
      const [fileId, row, col, hashId, _curveType, wordIndex] = strDelimited(d, "::");
      let parameter = parameters.getParameter(fileId);
      if (!parameter) {
        return null;
      }
      const curveType = _curveType ? _curveType : parameter.type; // simulation || target
      let fileIndex = files.findIndex(item => item.id === fileId);
      if (!files[fileIndex].ports || !parameter.ports) {
        return null;
      };
      return { parameter, fileIndex, row, col, curveType, hashId, wordIndex }
    });
    return curves;
  }
  return null;
}

export function getSelectAllInFilesCurves({ e, groupList, portSelect, files, parameters, setState }) {
  const check = e.target.checked;
  let _portSelect = portSelect;

  let curves = [];

  for (let groupInfo of groupList) {
    const { rowName, children } = groupInfo;
    const prevSelect = _portSelect[groupInfo.rowName] || [];
    if (check) {
      _portSelect[rowName] = children.map(d => d.id);

      const allSelect = children.map(d => d.id);
      const newChecks = allSelect.filter(a => !prevSelect.includes(a));
      const _curves = newChecks.map(d => {
        const [fileId, row, col, hashId, _curveType, wordIndex] = strDelimited(d, "::");
        let parameter = parameters.getParameter(fileId);
        if (!parameter) {
          return null;
        }
        const curveType = _curveType ? _curveType : parameter.type; // simulation || target

        let fileIndex = files.findIndex(item => item.id === fileId);
        if (!files[fileIndex].ports || !parameter.ports) {
          return null;
        };
        return { parameter, fileIndex, row, col, curveType, hashId, wordIndex }
      });
      curves = [...curves, ..._curves];

    } else {
      _portSelect[groupInfo.rowName] = [];
    };
  }
  setState(_portSelect);
  if (check) {
    return curves
  }
  return null;
}

export function getCPHYSelectAllinSignalCurves({ e, group, portSelect, files, parameters, setState }) {
  const check = e.target.checked;
  let _portSelect = portSelect;
  const prevSelect = _portSelect[group.rowName] || [];
  if (check) {
    _portSelect[group.rowName] = group.children.map(d => d.id);
  } else {
    _portSelect[group.rowName] = [];
  };
  setState(_portSelect);
  if (check) {
    const allSelect = group.children.map(d => d.id);
    const newChecks = allSelect.filter(a => !prevSelect.includes(a));
    const curves = newChecks.map(d => {
      let fileId = '';
      const [_fileId, signal, pair, row, col, hashId] = strDelimited(d, "::");
      fileId = [_fileId, signal, pair].join("::");
      let parameter = parameters.getParameter(fileId);
      if (!parameter) {
        return null;
      }
      const curveType = parameter.type; // simulation || target
      let fileIndex = files.findIndex(item => item.id === fileId);
      if (!files[fileIndex].ports || !parameter.ports) {
        return null;
      };
      return { parameter, fileIndex, row, col, curveType, hashId, signal, pair }
    });
    return curves;
  }
  return null;
}

export function showCurves({ parameters, curves, setting, curveType, files, couplingType }) {
  return new Promise((resolve, reject) => {
    parameters.showCurves({ curves, param: setting.parameter, value: setting.value, curveType, couplingType }).then(resCurves => {
      if (resCurves) {
        resCurves.forEach(cur => {
          const col = String(cur.col).includes('target') ? 1 : cur.col
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          files[_fileIndex].matrix[cur.row][col][cur.type] = cur.y;
          cur.visible = true;
        })
        resolve(resCurves);
      };
      resolve([])
    },
      error => {
        resolve([])
        console.error(error)
      });
  })
}

export function removeCurves(ports, parameters, interfaceType) {
  const removeCurves = ports.map(uncheck => {
    const { id, row, hashId, col } = getIdRoWCol(uncheck, interfaceType);
    return { id, row, hashId, col: String(col).includes('target') ? 1 : col };
  })
  parameters.removeCurves(removeCurves);
}

export function changeWidthCallBack({ plot2d, sparameter, axis, setting }) {
  if (plot2d && sparameter.getSize() > 0) {
    plot2d.resetPlot();
    sparameter.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
    sparameter.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
  }
}

export function updateRangeCallBack({ update, min, max, sparameter, axis }) {
  if (update === 'x') {
    sparameter.updateRange('x', { start: min, end: max })
  } else {
    sparameter.updateRange('y', { start: axis['yMin'], end: axis['yMax'], yScale: axis['yScale'] })
  }
}

export function axisChangeCallBack({ axis, value, setting, sparameter }) {
  if (sparameter.getSize() > 0) {
    sparameter.changeAxis(axis, { param: setting.parameter, value: setting.value, scale: value })
  };
}

export function changeCurveColor({ e, key, files, resultList, displayMode, type, isMultiList }) {
  let updateFiles = files ? [...files] : null, _resultList = { ...resultList };
  const { id, row, col } = getIdRoWCol(key, type);
  let fileIndex = updateFiles ? updateFiles.findIndex(item => item.id === id) : -1;
  const value = e.toHexString()
  if (value) {
    if (fileIndex > -1) {
      updateFiles[fileIndex].matrix[row][String(col).includes('target') ? 1 : col].color = value;
    }
    if (!_resultList || !_resultList[displayMode] || !_resultList[displayMode].list) {
      return {};
    }
    for (let group of _resultList[displayMode].list) {
      let _find = false;
      for (let child of group.children) {
        if (isMultiList && child && child.children && child.children.length) {
          for (let childInfo of child.children) {
            if (childInfo.id === key) {
              childInfo.color = value;
              _find = true;
              break;
            }
          }
        } else if (child.id === key) {
          child.color = value;
          _find = true;
          break;
        }
      }
      if (_find) {
        break;
      }
    }
    return { updateFiles, resultList: _resultList };
  }
  return {};
}

export function updatePlotColor({ plot2d, key, color, type }) {
  const { id, row, col } = getIdRoWCol(key, type);
  // const [id, row, col] = strDelimited(key, "::");
  plot2d.resetColor({ id, row, col, color })
  plot2d.redrawCurves();
}

export function yAxisValueFormat(yValue) {
  let _yValue = yValue;
  if (parseInt(_yValue) < 1) {
    _yValue = parseFloat(_yValue.toPrecision(3));
  }

  if (parseInt(_yValue) < 100 && parseInt(_yValue) >= 1) {
    _yValue = parseFloat(_yValue.toFixed(2));
  }

  if (parseInt(_yValue) < 1000 && parseInt(_yValue) >= 100) {
    _yValue = parseFloat(_yValue.toPrecision(4));
  }

  if (parseInt(_yValue) > 1000) {
    _yValue = parseInt(_yValue);
  };
  return _yValue;
}

export function _getIndex(xPoints, freq) {
  return getIndex(xPoints, freq, 0, xPoints.length - 1);
}

function getNewChild(child, selectArr, files, x, indexObj, yScale, type, parameter, value) {
  if (selectArr.includes(child.id)) {
    let fileId;
    const { id, row, col } = getIdRoWCol(child.id, type);
    fileId = id;
    const portType = child.portType;
    const hashId = child.hashId;
    let fileIndex = files.findIndex(item => item.id === fileId);
    if (!indexObj[fileIndex] || portType === 'target') {
      if (portType === 'target' && hashId) {
        indexObj[fileIndex] = _getIndex(files[fileIndex].targetFrequency[hashId], x)
      } else if (files[fileIndex]) {
        indexObj[fileIndex] = _getIndex(files[fileIndex].freq, x);
      }
    }
    const fileData = files[fileIndex].matrix[row][col][parameter + value];
    if (fileData) {
      let yAxisValue = fileData[indexObj[fileIndex]]
      if (value === 'db') {
        yAxisValue = db(yAxisValue);
      }
      if (yScale === 'degrees') {
        yAxisValue = radiansToDegrees(yAxisValue);
      }
      child.value = yAxisValueFormat(yAxisValue);
    } else {
      child.value = '';
    }
  } else {
    child.value = '';
  }
  return child;
}

export function getMouseMoveYAxisValue({ resultsObj, displayMode, selectArr, files, x, setting, yScale, type, isMultiList }) {
  let indexObj = {};
  const { parameter, value } = setting;
  let _resultsObj = { ...resultsObj };

  let list = _resultsObj && _resultsObj[displayMode] && _resultsObj[displayMode].list ? _resultsObj[displayMode].list : [];
  for (let group of list) {
    for (let child of group.children) {
      if (isMultiList && child && child.children && child.children.length) {
        for (let childInfo of child.children) {
          childInfo = getNewChild(childInfo, selectArr, files, x, indexObj, yScale, type, parameter, value)
        }
      } else {
        if (selectArr.includes(child.id)) {
          let fileId;
          const { id, row, col } = getIdRoWCol(child.id, type);
          fileId = id;
          const portType = child.portType;
          const hashId = child.hashId;
          let fileIndex = files.findIndex(item => item.id === fileId);
          if (!indexObj[fileIndex] || portType === 'target') {
            if (portType === 'target' && hashId) {
              indexObj[fileIndex] = _getIndex(files[fileIndex].targetFrequency[hashId], x)
            } else if (files[fileIndex]) {
              indexObj[fileIndex] = _getIndex(files[fileIndex].freq, x);
            }
          }
          const fileData = files[fileIndex].matrix[row][col][parameter + value];
          if (fileData) {
            let yAxisValue = fileData[indexObj[fileIndex]]
            if (value === 'db') {
              yAxisValue = db(yAxisValue);
            }
            if (yScale === 'degrees') {
              yAxisValue = radiansToDegrees(yAxisValue);
            }
            child.value = yAxisValueFormat(yAxisValue);
          } else {
            child.value = '';
          }
        } else {
          child.value = '';
        }
      }

    }
  }
  return _resultsObj;
}

export function cancelMove({ resultsObj, displayMode, selectArr, isMultiList }) {
  let _resultsObj = { ...resultsObj };
  if (!_resultsObj || !_resultsObj[displayMode] || !_resultsObj[displayMode].list) {
    return _resultsObj;
  }
  for (let group of _resultsObj[displayMode].list) {
    for (let child of group.children) {
      if (isMultiList && child && child.children && child.children.length > 0) {
        for (let childInfo of child.children) {
          if (selectArr.includes(childInfo.id)) {
            childInfo.value = '';
          }
        }
      } else if (selectArr.includes(child.id)) {
        child.value = '';
      }
    }
  }
  return _resultsObj;
}

export function ImpedanceResultListDefault(resultList, portList = [], getColorByIndex, type, optList = []) {
  let _portList = portList.filter(d => d.index !== undefined);
  let _default = [];
  let targetPortList = [], simPortList = [], powerDomainTargetPortList = [], optPortList = [], sweepPortList = [];
  for (let item of _portList) {
    if (item.portType === "target") {
      if (item.powerPin) {
        targetPortList.push({ ...item })
      } else {
        powerDomainTargetPortList.push(item)
      }
    } else if (item.portType === OPTIMIZATION) {
      if (optList.includes(item.name)) {
        optPortList.push(item)
      }
    } else if (item.portType === SWEEPMODEL) {
      sweepPortList.push(item)
    } else {
      simPortList.push(item)
    }
  }
  //simulation ports and their targets
  for (let i = 0; i < simPortList.length; i++) {
    const port = simPortList[i];
    sweepPortList.forEach((item, index) => { item.sweepIndex = index })
    const findSweepPortList = sweepPortList.filter(item => item.power === port.power && port.powerPin === item.powerPin)
    let modelName = ""
    if (findSweepPortList.length) {
      const sweepPort = findSweepPortList[0]
      const model = sweepPort.model
      modelName = model.editName
      if (!modelName) {
        if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
          modelName = model.subcktName ? model.subcktName : `Sweep Model${Number(model.key) + 1}`
        } else {
          modelName = model.name ? model.name : `Sweep Model${Number(model.key) + 1}`
        }
      }
    }
    const portIndex = port.portIndex || port.index;
    const wordIndex = port.wordIndex
    let rowIndex = _default.length + 1;
    const findIndex = _default.findIndex(item => item.power === port.power);
    rowIndex = findIndex > -1 ? findIndex + 1 : rowIndex;
    const rowName = findIndex > -1 ? _default[rowIndex - 1].rowName : type === IMPORT ? `Power ${rowIndex}` : `Power ${rowIndex} (${displayName(port)})`;
    const children = findIndex > -1 ? [..._default[rowIndex - 1].children] : [];
    let designStringLength = 0;
    const current = simPortList.filter(item => item.fileId === port.fileId).find(item => item.index === portIndex);
    const _portIndex = 1;
    const portChildren = children.filter(item => !['target', OPTIMIZATION, SWEEPMODEL].includes(item.portType));
    const pinName = port.pin && port.pin.length ? ` - ${port.pin.join(', ')}` : "";
    const portNum = portChildren.length + 1;
    const portName = port.portName ? ` - ${port.portName}` : "";
    const powerNetName = port.powerNetName ? ` - ${port.powerNetName}` : "";
    const compName = port.compName ? ` - ${port.compName}` : "";
    const sweepName = modelName ? ` - ${modelName}` : ""
    let namePrev = `Port ${portNum}${compName}${portName ? portName : pinName}${sweepName}`;
    designStringLength = namePrev.length > designStringLength ? namePrev.length : designStringLength;
    const currentItem = {
      ...current,
      namePrev: namePrev,
      portIndex,
      _portIndex,
      hashId: current.hashId,
      id: current.hashId ? `${port.fileId}::${portIndex - 1}::${_portIndex - 1}::${current.hashId}::simulation::${wordIndex - 1}` : `${port.fileId}::${portIndex - 1}::${_portIndex - 1}::simulation::${wordIndex - 1}`,
      name: namePrev,
      color: getColorByIndex(port.fileId, portIndex - 1, _portIndex - 1),
      curveType: port.portType
    }
    children.push(currentItem);

    if (findSweepPortList.length) {
      findSweepPortList.forEach((sweepPort, index) => {
        if (index !== 0) {
          sweepPortList[sweepPort.sweepIndex].added = true;
          const sweepWordIndex = sweepPort.wordIndex;
          const sweepPortIndex = sweepPort.portIndex || sweepPort.index,
            _sweepPortIndex = 1;
          const model = sweepPort.model
          let modelName = model.editName
          if (!modelName) {
            if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
              modelName = model.subcktName ? model.subcktName : `Sweep Model${Number(model.key) + 1}`
            } else {
              modelName = model.name ? model.name : `Sweep Model${Number(model.key) + 1}`
            }
          }
          const _modelName = modelName ? ` - ${modelName}` : ""
          const portName = sweepPort.portName ? ` - ${sweepPort.portName}` : "";
          const sweepName = `Port ${portNum}${compName}${portName ? portName : pinName}${_modelName}`;
          designStringLength = sweepName.length > designStringLength ? sweepName.length : designStringLength;
          const sweepItem = {
            ...sweepPort,
            namePrev: sweepName,
            portIndex: sweepPortIndex,
            _portIndex: _sweepPortIndex,
            hashId: sweepPort.hashId,
            id: sweepPort.hashId ? `${port.fileId}::${sweepPortIndex - 1}::${_sweepPortIndex - 1}::${sweepPort.hashId}::${SWEEPMODEL}::${sweepWordIndex - 1}` : `${port.fileId}::${sweepPortIndex - 1}::${_sweepPortIndex - 1}::${SWEEPMODEL}::${sweepWordIndex - 1}`,
            name: sweepName,
            color: getColorByIndex(port.fileId, sweepPortIndex - 1, _sweepPortIndex - 1),
            curveType: SWEEPMODEL
          }
          children.push(sweepItem);
        }
      })
    }

    optPortList.forEach((item, index) => { item.optIndex = index })
    const findOptPortList = optPortList.filter(item => item.power === port.power && port.powerPin === item.powerPin)
    if (findOptPortList.length) {
      for (let optPort of findOptPortList) {
        optPortList[optPort.optIndex].added = true;
        const optWordIndex = optPort.wordIndex;
        const optPortIndex = optPort.portIndex || optPort.index,
          _optPortIndex = 1;
        const portName = optPort.portName ? ` - ${optPort.portName}` : "";
        const optName = `Port ${portNum}${compName}${portName ? portName : pinName} - OPT `;
        designStringLength = optName.length > designStringLength ? optName.length : designStringLength;
        const optItem = {
          ...optPort,
          namePrev: optName,
          portIndex: optPortIndex,
          _portIndex: _optPortIndex,
          hashId: optPort.hashId,
          id: optPort.hashId ? `${port.fileId}::${optPortIndex - 1}::${_optPortIndex - 1}::${optPort.hashId}::${OPTIMIZATION}::${optWordIndex - 1}` : `${port.fileId}::${optPortIndex - 1}::${_optPortIndex - 1}::${OPTIMIZATION}::${optWordIndex - 1}`,
          name: optName,
          color: getColorByIndex(port.fileId, optPortIndex - 1, _optPortIndex - 1),
          curveType: OPTIMIZATION
        }
        children.push(optItem);
      }
    }

    targetPortList.forEach((item, index) => { item.targetIndex = index });
    const findTargetPortList = targetPortList.filter(item => item.power === port.power && port.powerPin && port.powerPin === item.powerPin);

    if (findTargetPortList.length) {
      for (let targetPort of findTargetPortList) {
        targetPortList[targetPort.targetIndex].added = true;
        const targetPortIndex = targetPort.portIndex || targetPort.index,
          _targetPortIndex = 1;
        const portName = targetPort.portName ? targetPort.portName : "";
        const targetName = `Port ${portNum}${compName} - ${portName ? portName : targetPort.powerPin} Target`;
        designStringLength = targetName.length > designStringLength ? targetName.length : designStringLength;
        const targetItem = {
          ...targetPort,
          namePrev: targetName,
          portIndex: targetPortIndex,
          _portIndex: _targetPortIndex,
          hashId: targetPort.hashId,
          id: targetPort.hashId ? `${port.fileId}::${targetPortIndex - 1}::${_targetPortIndex - 1}::${targetPort.hashId}` : `${port.fileId}::${targetPortIndex - 1}::${_targetPortIndex - 1}`,
          name: targetName,
          color: getColorByIndex(port.fileId, targetPortIndex - 1, _targetPortIndex - 1),
          curveType: port.portType
        }
        children.push(targetItem);
      }

    }
    children.forEach(d => d.designStringLength = designStringLength * 9)
    if (findIndex < 0) {
      _default.push({ rowName, children, power: port.power });
    } else {
      _default[rowIndex - 1] = { rowName, children, power: port.power };
    }
  }

  //power domain target and no simulation ports targets
  const noSimTargetPortList = targetPortList.filter(item => !item.added);
  if (powerDomainTargetPortList.length || noSimTargetPortList.length) {
    const __portList = [...noSimTargetPortList, ...powerDomainTargetPortList];

    for (let i = 0; i < __portList.length; i++) {
      const port = __portList[i];
      const portIndex = port.portIndex || port.index;
      let rowIndex = _default.length + 1;
      const findIndex = _default.findIndex(item => item.power === port.power);
      rowIndex = findIndex > -1 ? findIndex + 1 : rowIndex;
      const rowName = displayName(port) === MULTI_PCB_TARGET ? displayName(port) : findIndex > -1 ? _default[rowIndex - 1].rowName : `Power ${rowIndex} (${displayName(port)})`;
      const children = findIndex > -1 ? [..._default[rowIndex - 1].children] : [];
      let designStringLength = 0;
      const current = __portList.filter(item => item.fileId === port.fileId).find(item => item.index === portIndex);
      const _portIndex = 1;
      const portChildren = children.filter(item => item.portType !== "target");
      const portNum = portChildren.length + 1;
      let namePrev = port.powerPin ? `Port ${portNum} - ${port.powerPin} - ${port.name}` : port.name;
      designStringLength = namePrev.length > designStringLength ? namePrev.length : designStringLength;
      const currentItem = {
        ...current,
        namePrev: namePrev,
        portIndex,
        _portIndex,
        hashId: current.hashId,
        id: current.hashId ? `${port.fileId}::${portIndex - 1}::${_portIndex - 1}::${current.hashId}` : `${port.fileId}::${portIndex - 1}::${_portIndex - 1}`,
        name: namePrev,
        color: getColorByIndex(port.fileId, portIndex - 1, _portIndex - 1),
        curveType: port.portType
      }
      children.push(currentItem);
      children.forEach(d => d.designStringLength = designStringLength * 9)
      if (findIndex < 0) {
        _default.push({ rowName, children, power: port.power });
      } else {
        _default[rowIndex - 1] = { rowName, children, power: port.power };
      }
    }
  }
  resultList.Default = { list: _default, show: _default ? _default.map(d => d.rowName) : [] };
  return resultList;
}

function getIdRoWCol(key, type) {
  if (type === CPHY) {
    const [_id, signal, pair, row, col, hashId] = strDelimited(key, "::");
    const id = [_id, signal, pair].join('::');
    return { id, col, row, hashId }
  } else {
    const [id, row, col, hashId] = strDelimited(key, "::");
    return { id, col, row, hashId }
  }
}