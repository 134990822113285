import React, { Fragment, Component } from 'react';
import { Select } from 'antd';
import _ from 'lodash';

const { Option } = Select;
class TargetDie extends Component {

  constructor(props) {
    super(props);
    this.state = {
      die: [],
      focus: false
    }
  }

  componentDidMount() {
    const { die } = this.props;
    this.setState({
      die
    })
  }

  componentDidUpdate(prevProps) {
    const { die } = this.props;
    if (!_.isEqual(die, prevProps.die)) {
      this.setState({
        die
      })
    }
  }

  selectDie = (die) => {
    this.setState({
      die
    })
  }

  saveDie = () => {
    const { die } = this.state;
    this.props.dieSelect(die);
    this.setState({
      focus: false
    })
  }

  onFocus = () => {
    this.setState({
      focus: true
    })
  }

  render() {
    const { options, noPCB, loading, disabled = false } = this.props;
    const { die, focus } = this.state;
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color-only">Die</span>
        <Select
          mode={focus ? 'multiple' : null}
          value={!focus ? die.join(', ') : die}
          style={{ width: 240, marginLeft: 20 }}
          onChange={this.selectDie}
          onBlur={this.saveDie}
          onFocus={this.onFocus}
          getPopupContainer={((trigger) => trigger.parentNode || document.getElementById('root'))}
          dropdownStyle={{ zIndex: 200 }}
          showSearch={true}
          disabled={noPCB || loading ? true : disabled}
        >
          {options.map(option => {
            return <Option key={option} value={option}>{option}</Option>
          })}
        </Select>
      </Fragment>
    )
  }
}

export default TargetDie;