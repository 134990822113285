import {
  getVerificationsInProject,
  getVerificationContent
} from '../api/v2/verificationCtrl';
import checkError from '../api/checkError';
import { SUCCESS } from '../../constants/returnCode';

const projectId = window.location.search.split('=')[1];
export const PROJECT_ID = projectId;

export function getVerificationsInProjectPromise() {
  return new Promise((resolve, reject) => {
    getVerificationsInProject(PROJECT_ID).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        reject([]);
      }
    }, error => {
      console.error(error);
      checkError(error);
      reject(error);
    })
  })
}

export function getVerificationContentPromise(verificationId) {
  return new Promise((resolve, reject) => {
    getVerificationContent(verificationId).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        reject(res);
      }
    }, error => {
      console.error(error);
      checkError(error);
      reject(error);
    })
  })
}