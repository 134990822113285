import http from "../http";

/**
 * get simulation log 
 * 2023/05/30
 * @export
 * @param {string} verificationId verificationId 
 * @returns
 */
export function getHimalayasSimulationLog(verificationId) {
  return http.get(`Himalayas/verification/${verificationId}/log`);
}