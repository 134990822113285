import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import { updateComponents, updateSchematicLoading, updateCanvasStatus, saveModel } from '../../store/prelayout/action';
import sierraLibrary from "../../../../services/Sierra/library/libraryStorage";
import { PCB_SPICE, PCB_TOUCHSTONE, SPICE } from "../../../../constants/libraryConstants";
import { updateLibraryTree } from "../../store/library/action";
import { getTouchstoneParse, getLibraryConnectorFile } from '@/services/Sierra/library';
import Canvas from './canvas';
import ModelSelect from "./modelSelect";
import { PCB_PRE_LAYOUT } from "../../../../constants/treeConstants";
import '../../../../components/PreLayout/index.css';
import './index.css'

class Schematic extends Component {

  constructor() {
    super();
    const pcbSpiceList = sierraLibrary.getTree(PCB_SPICE);
    this.state = {
      width: 1000,
      pcbSpiceList,
      portsObj: {},
      lastOpenSubckt: {},
    }
  }

  componentDidMount() {
    this.getCanvansWidth();
    this.getDefaultPorts();
    window.addEventListener('resize', this.getCanvansWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getCanvansWidth);
  }

  componentDidUpdate = (prevProps) => {
    const { id, libraryTreeInfo, loading, viewList } = this.props;
    const { width } = this.state;
    if (id !== prevProps.id || ((!prevProps.viewList || !prevProps.viewList.includes(PCB_PRE_LAYOUT)) && viewList.includes(PCB_PRE_LAYOUT))) {
      this.getCanvansWidth();
    }

    if (libraryTreeInfo && libraryTreeInfo.length && (!prevProps.libraryTreeInfo || libraryTreeInfo.length !== prevProps.libraryTreeInfo.length)) {
      this.getLibraryList(libraryTreeInfo)
      this.props._updateLibraryTree(null);
    }

    if (loading && loading !== prevProps.loading) {
      const ele = document.getElementById(`pre-layout-${id}`);
      const offset = ele ? ele.getBoundingClientRect() : null;
      const offsetWidth = offset && offset.width ? offset.width < 1000 ? 1000 : offset.width : 1000;
      if (width !== offsetWidth) {
        this.getCanvansWidth()
      }
    }
  }

  getDefaultPorts = () => {
    const { model } = this.props;
    const { libraries, modelType } = model;
    const libraryIds = libraries.map(item => item.libraryId)
    this.getFileParse(libraryIds, modelType === SPICE ? PCB_SPICE : PCB_TOUCHSTONE)
  }

  getLibraryList = (libraries = []) => {
    let stateLibraries = {};
    if (libraries.includes(PCB_SPICE)) {
      const pcbSpiceList = sierraLibrary.getTree(PCB_SPICE);
      stateLibraries = {
        ...stateLibraries,
        pcbSpiceList
      }
    }

    this.setState({
      ...stateLibraries
    })
  }

  getCanvansWidth = () => {
    const { id } = this.props;
    const ele = document.getElementById(`pre-layout-${id}`);
    const offset = ele ? ele.getBoundingClientRect() : null;
    const offsetWidth = offset && offset.width ? offset.width < 1000 ? 1000 : offset.width : 1000;
    this.setState({
      width: offsetWidth
    })
  }

  selectModelType = () => {
    // todo
  }

  selectOpenSubckt = (libraryId, subckt) => {
    this.setState({
      lastOpenSubckt: {
        [libraryId]: subckt
      }
    })
  }

  onSelectLibrary = async (id, index) => {
    const { model, components } = this.props;
    const { lastOpenSubckt, portsObj } = this.state;
    let _model = { ...model };
    let _components = JSON.parse(JSON.stringify(components));
    if (!_model.libraries[index]) {
      _model.libraries[index] = { libraryId: "", subckt: "" }
    }
    if (_model.libraries[index].libraryId) {
      let _lastOpenSubckt = lastOpenSubckt[_model.libraries[index].libraryId] ? {} : lastOpenSubckt
      _components = _components.map(comp => {
        let pins = comp.pins.map(pin => {
          if (pin.model.libraryId === _model.libraries[index].libraryId && pin.model.subckt === _model.libraries[index].subckt) {
            return { ...pin, model: { libraryId: "", port: "", subckt: "" } }
          }
          return pin
        })
        return { ...comp, pins }
      })
      this.setState({
        lastOpenSubckt: _lastOpenSubckt
      })
      _model.libraries[index].subckt = "";
    }
    _model.libraries[index].libraryId = id;
    _model.libraries[index].subckt = "";
    if (!portsObj[id]) {
      await this.getFileParse([id], _model.modelType === SPICE ? PCB_SPICE : PCB_TOUCHSTONE)
    }

    this.selectSingleSubckt(index, id, _components, _model);
  }

  selectSingleSubckt = (index, id, _components, _model) => {
    const { portsObj } = this.state;
    let model = { ..._model };
    const subcktList = portsObj[id] ? portsObj[id].map(item => item.name) : [];
    if (subcktList.length === 1) {
      _model.libraries[index].subckt = subcktList[0];
    }
    this.props.saveModel(model, _components, true);
  }

  addNewFileSelect = (e) => {
    e && e.stopPropagation();
    const { model, components } = this.props;
    let _model = { ...model };
    if (!_model.libraries.length) {
      _model.libraries.push({ libraryId: "", subckt: "" });
    }
    _model.libraries.push({ libraryId: "", subckt: "" });
    this.props.saveModel(_model, components, false)
  }

  deleteModel = (e, index) => {
    e && e.stopPropagation();
    const { model, components } = this.props;
    let _model = { ...model };
    let saveToBackend = _model.libraries[index] && _model.libraries[index].libraryId ? true : false;
    let _components = JSON.parse(JSON.stringify(components));
    if (_model.libraries[index] && _model.libraries[index].libraryId) {
      _components = _components.map(comp => {
        let pins = comp.pins.map(pin => {
          if (pin.model.libraryId === _model.libraries[index].libraryId && pin.model.subckt === _model.libraries[index].subckt) {
            return { ...pin, model: { libraryId: "", port: "", subckt: "" } }
          }
          return pin
        })
        return { ...comp, pins }
      })
    }
    _model.libraries.splice(index, 1);
    this.props.saveModel(_model, _components, saveToBackend)
  }

  getCurrentList = () => {
    const { model } = this.props;
    const { libraries, modelType } = model;

    if (modelType === SPICE) {
      return libraries.filter(item => !!item).map(item => {
        const libraryInfo = sierraLibrary.checkFile(PCB_SPICE, item.libraryId);
        return { ...libraryInfo, subckt: item.subckt }
      });
    }

    return [];
  }

  getFilesParseList = (files) => {
    const promiseList = files.map(item => {
      if (item.type === PCB_TOUCHSTONE) {
        return getTouchstoneParse(item.id, item.name);
      } else if (item.type === PCB_SPICE) {
        return getLibraryConnectorFile({ libraryId: item.id });
      }
      return null;
    }).filter(item => !!item);
    return Promise.all(promiseList);
  }

  getFileParse = async (files, modelType) => {
    if (!files || files.length === 0) {
      return;
    }
    //filter libraryId not exist files
    const _files = files.map(f => sierraLibrary.checkFile(modelType, f)).filter(item => !!item);
    const { portsObj } = this.state;
    let _portsObj = { ...portsObj }
    //get files ports
    const res = await this.getFilesParseList(_files);
    if (res && Array.isArray(res)) {
      _files.forEach((file, index) => {
        const libraryId = file.id;
        //update file portsObj
        _portsObj[libraryId] = [...(res[index] || [])];
      });
      this.setState({
        portsObj: _portsObj
      })
    }
    return true;
  }

  savePinModel = (pin, model) => {
    const { comp, pinName } = pin;
    const { components } = this.props;
    let _components = JSON.parse(JSON.stringify(components));
    const compIndex = _components.findIndex(item => item.name === comp);
    if (compIndex > -1) {
      const pinIndex = _components[compIndex].pins.findIndex(item => item.pinName === pinName);
      if (pinIndex > -1) {
        _components[compIndex].pins[pinIndex].model = model;
        this.props.updateComponents(_components);
      }
    }
  }

  selectSubckt = (subckt, index) => {
    const { model, components } = this.props;
    let _model = { ...model };
    if (_model.libraries.map(item => item.subckt).includes(subckt)) {
      message.error('The current subckt has been selected!')
      return;
    }
    let _components = JSON.parse(JSON.stringify(components));
    if (_model.libraries[index] && _model.libraries[index].subckt) {
      _components = _components.map(comp => {
        let pins = comp.pins.map(pin => {
          if (pin.model.subckt === _model.libraries[index].subckt) {
            return { ...pin, model: { libraryId: "", port: "", subckt: "" } }
          }
          return pin
        })
        return { ...comp, pins }
      })
    }
    if (!_model.libraries[index]) {
      return;
    }
    this.setState({
      lastOpenSubckt: {
        [_model.libraries[index].libraryId]: subckt
      }
    })
    _model.libraries[index].subckt = subckt;
    this.props.saveModel(_model, _components, true);
  }

  updateResValue = (comp, value) => {
    const { components } = this.props;
    let _components = JSON.parse(JSON.stringify(components));
    const compIndex = _components.findIndex(item => item.name === comp);
    if (compIndex > -1) {
      _components[compIndex].value = value;
      this.props.updateComponents(_components);
    }
  }

  updateVolValue = (comp, value) => {
    const { components } = this.props;
    let _components = JSON.parse(JSON.stringify(components));
    const compIndex = _components.findIndex(item => item.name === comp);
    if (compIndex > -1) {
      const group = _components[compIndex].group;
      _components.forEach(comp => {
        if (comp.group === group) {
          comp.voltage = value;
        }
      })
      this.props.updateComponents(_components);
    }
  }

  render() {
    const { loading, components, canvasUpdate, signals, id, model, fixed } = this.props;
    const { width, pcbSpiceList, portsObj, lastOpenSubckt } = this.state;
    const currentList = this.getCurrentList();
    return <Fragment>
      <span className="font-bold sierra-setup-title-color">Setup</span>
      <div className="space-10">
        <ModelSelect
          width={100}
          model={model}
          spice={pcbSpiceList}
          selectModelType={this.selectModelType}
          onSelectLibrary={this.onSelectLibrary}
          addNewFileSelect={this.addNewFileSelect}
          deleteModel={this.deleteModel}
          fixed={fixed}
          portsObj={portsObj}
          selectSubckt={this.selectSubckt}
        />
      </div>
      <div className="space-10">
        <Spin spinning={loading}>
          <Canvas
            id={id}
            canvasUpdate={canvasUpdate}
            loading={loading}
            width={width}
            signals={signals}
            model={model}
            components={components}
            libraries={currentList}
            portsObj={portsObj}
            saveModel={this.savePinModel}
            updateSchematicLoading={this.props.updateSchematicLoading}
            updateCanvasStatus={this.props.updateCanvasStatus}
            fixed={fixed}
            lastOpenSubckt={lastOpenSubckt}
            updateResValue={this.updateResValue}
            updateVolValue={this.updateVolValue}
            selectOpenSubckt={this.selectOpenSubckt}
          />
        </Spin>
      </div>
    </Fragment>
  }
}

const mapState = (state) => {
  const { SierraReducer: { project: { viewList },
    prelayout: { components, schematicLoading, canvasUpdate, signals, id, model },
    library: { libraryTreeInfo }
  } } = state;
  return {
    components,
    loading: schematicLoading,
    canvasUpdate,
    signals,
    id,
    model,
    libraryTreeInfo,
    viewList
  }
}

const mapDispatch = (dispatch) => ({
  updateComponents(components) {
    dispatch(updateComponents(components))
  },
  updateSchematicLoading(boolean) {
    dispatch(updateSchematicLoading(boolean))
  },
  updateCanvasStatus(boolean) {
    dispatch(updateCanvasStatus(boolean))
  },
  _updateLibraryTree(type) {
    dispatch(updateLibraryTree(type))
  },
  saveModel(model, components, toBackend) {
    dispatch(saveModel(model, components, toBackend))
  }
})

export default connect(mapState, mapDispatch)(Schematic);