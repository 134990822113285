import React, { Component, Fragment } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Divider, ColorPicker } from 'antd';
import '../index.css';

const CheckboxGroup = Checkbox.Group;

class WaveformSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultShow: true
    }
  }

  changeResultShow = () => {
    this.setState((prev => ({
      resultShow: !prev.resultShow
    })));
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  render() {
    const { resultShow } = this.state;
    const { compInfo, colorChange, changeSelectedKeys, selectedKeys } = this.props
    const Icon = resultShow ? DownOutlined : RightOutlined;
    return (
      <div className='sparameter-channel-result-content'>
        <Divider
          orientation="left"
          className={'sparameter-channel-result-margin'}
        >
          <div className='result-import-title' onClick={this.changeResultShow}>
            <Icon className="title-expand-icon" />
            <span className='sparameter-result-menu-title'>Current Result</span>
          </div>
        </Divider>
        {resultShow && <div className='sparameter-result-file-content'><CheckboxGroup value={selectedKeys} onChange={changeSelectedKeys}  > {compInfo.map((item, index) => (
          <Checkbox value={item.name} key={item.name} className='sparameter-result-checkbox-group'>
            <span className='sparameter-result-checkbox-group-name'>
              <ColorPicker value={item.color} className='aurora-color-picker-small' onChange={(value) => colorChange(value, item.curveIndex)} size='small' onClick={(e) => this.stopPropagation(e)} />
              <label>
                <Tooltip title={item.title} placement="top" overlayClassName='aurora-tooltip'>
                  <span className='waveform-comp-name'>{item.title}</span>
                  <span style={{ color: item.color }} className='result-curve-current'>{item.current}</span>
                </Tooltip>
              </label>
            </span>
          </Checkbox>
        ))}
        </CheckboxGroup></div>}
      </div>
    );
  }
}

export default WaveformSelection;