import React, { PureComponent, createRef, Fragment } from "react";
import { createPortal } from "react-dom";
import "./ModelSearch.css";

const MODELS = [
  "pdnModel",
  "channelModel",
  "sierraModel",
  "rockyModel",
  "preLayoutModel",
  "entToEndModel",
];
class ModelDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: 0,
      width: 0,
      position: "absolute",
    };

    this.dialogRef = createRef();
  }

  onChange(value) {
    this.setState({
      use: value,
    });
  }

  componentWillMount() {
    this.parentOffset = this.props.root.parentElement.getBoundingClientRect();
    this.inputOffset = this.props.offset.current
      ? this.props.offset.current.getBoundingClientRect()
      : this.props.offset;
    this.getDialogStyle();
  }

  screenChange() {
    window.addEventListener("resize", this.resize);
  }

  componentDidMount() {
    this.screenChange();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.getBodySize();
  };

  getBodySize = () => {
    this.parentOffset = this.props.root.parentElement.getBoundingClientRect();
    this.inputOffset = this.props.offset.current
      ? this.props.offset.current.getBoundingClientRect()
      : this.props.offset;
    this.getDialogStyle();
  };

  getDialogStyle = () => {
    const { top, left, width } = this.inputOffset;
    const { x, y } = this.parentOffset;
    const { type, matchWidth } = this.props;
    let newWidth = width + 50;
    let _top = top - y;

    if (MODELS.includes(type) || matchWidth) {
      newWidth = width;
    }

    this.setState({
      top: _top,
      left: left - x,
      width: newWidth,
      position: "absolute",
    });
  };

  render() {
    const { top, left, width, position } = this.state;
    const { type, matchWidth, className = "" } = this.props;
    let style = { top, left, width, position };
    const content = (
      <Fragment>
        <div
          style={style}
          ref={this.dialogRef} /* className='aurora-model-dialog' */
        >
          <div
            className={`comp-model-dialog ${className}`}
            style={{
              height: "auto",
              minWidth:
                type === "pdnModel" || type === "channelModel" || matchWidth ? 0 : 280,
            }}
          >
            {this.props.children}
          </div>
        </div>
        <div id="comp-model-dialog"></div>
      </Fragment>
    );
    return createPortal(content, this.props.root);
  }
}

export default ModelDialog;
