const TYPE = "Cascade/IR";

export const GET_IR_EXPLORER_INFO = `${TYPE}/get_ir_explorer_info`;
export const SAVE_IR_EXPLORER_INFO = `${TYPE}/save_ir_explorer_info`;
export const UPDATE_IR_EXPLORER_INFO = `${TYPE}/update_ir_explorer_info`;
export const UPDATE_LOADING_PCB = `${TYPE}/update_loading_PCB`;
export const IR_EXPLORER_SELECT_NETS = `${TYPE}/ir_explorer_select_nets`;
export const SAVE_IR_VERIFICATIONID = `${TYPE}/save_ir_verificationId`;
export const DELETE_IR_DATA = `${TYPE}/delete_ir_data`;
export const UPDATE_COMP_RLC_PREFIX = `${TYPE}/update_comp_rlc_prefic`;
export const UPDATE_IR_LOAD = `${TYPE}/update_ir_load`;
export const CLEAR_CURRENT_IR_DATA = `${TYPE}/clear_current_ir_data`;
export const UPLOAD_IR = `${TYPE}/upload_ir_explorer`;
export const CHANGE_TARGET_IC = `${TYPE}/change_target_ic`;
export const UPLOAD_IR_TABLE_LOADING = `${TYPE}/upload_ir_table_loading`
export const DEBUG_MONITOR_ACTION = `${TYPE}/debug_monitor-action`;
export const UPDATE_IR_RESULT_EXIST = `${TYPE}/update_ir_result_exist`;

export const IGNORE_COMPONENTS = `${TYPE}/ignore_chips`;
export const SAVE_SPLIT_COMPONENTS = `${TYPE}/save_split_components`;
export const SAVE_MERGE_COMPONENTS = `${TYPE}/save_merge_components`;
export const SAVE_COMPONENTS = `${TYPE}/save_components`;

export const SAVE_VRM_COMP = `${TYPE}/save_vrm_comp`;
export const SEARCH_VRM = `${TYPE}/search_vrm`;

export const REFRESH_IR = `${TYPE}/refresh_ir`;
export const POWER_NET_SELECT_PANEL = `${TYPE}/power_net_select_panel`;
export const SELECT_PCB = `${TYPE}/select_pcb`;
export const CHANGE_PCB = `${TYPE}/change_pcb`;
export const COMPONENT_SETTING_UPDATE = `${TYPE}/component_setting_update`;

export const SAVE_EXTRACTION = `${TYPE}/save_extraction`;
export const UPDATE_EXTRACTION = `${TYPE}/update_extraction`;