import React, { Component, Fragment } from "react";
import { SIERRA } from '@/constants/pageType';
import { getPinNetWidth } from '../../services/helper/connectorHelper';
import "./index.css";
class PinPortRender extends Component {

  render = () => {
    const { pinList, connector1Models = [], connector2Models = [], cableModels = [], product, top } = this.props;
    const { leftNetWidth, leftPinWidth, rightNetWidth, rightPinWidth } = getPinNetWidth(pinList);

    return (
      <div className='connection-model-pin-port-content' style={{ top: top || 72 }}>
        <ul className="connection-pin-list-ul">
          {pinList.map((signal, index) =>
            <div key={`${signal.signal}-${index}`} className="connection-pin-list-signal">
              {signal.pins.map((item, index) =>
                <div className="connection-pin-connect-port-item" key={index}>
                  {/* pin L connection */}
                  <div className='connection-pin-l-value-box'>
                    {item.pinL ?
                      this.pinLDisplay({
                        item,
                        models: connector1Models,
                        signal,
                        index,
                        leftNetWidth,
                        leftPinWidth
                      }) : null}
                  </div>
                  {/* cable port */}
                  <div className='connection-pin-cable-value-box'>
                    {/* connection signal */}
                    {item.pinL && item.pinR ? <Fragment>
                      {index === 0 && signal.signal && (product === SIERRA || (signal.channel1_signal && signal.channel2_signal)) ?
                        <div
                          className='connection-pin-list-connection-signal-title'
                          style={product === SIERRA ? { height: 50, lineHeight: '50px', top: -25 } : {}}
                        >
                          <span
                            className="connection-pin-list-channel-signal-span"
                            onClick={(e) => {
                              this.props.connectorSignalClick({
                                e,
                                signalInfo: signal,
                                signal: signal.signal,
                                signalType: "signal",
                                type: "cable"
                              })
                            }}
                          >{signal.signal}</span>
                        </div> : null}
                      {/* cable port */}
                      {cableModels && cableModels.length && cableModels.find(item => !!item.libraryId && item.isExist) ? <Fragment>
                        {this.portPopover({
                          pin: item.pinL,
                          pinPort: item.pinLExternalMap.cablePort,
                          pinType: "pinLExternalCable",
                          signalInfo: signal,
                          signal: signal.signal,
                          signalType: "signal",
                          type: "cable"
                        })}
                        {this.portPopover({
                          pin: item.pinR,
                          pinPort: item.pinRExternalMap.cablePort,
                          pinType: "pinRExternalCable",
                          signalInfo: signal,
                          signal: signal.signal,
                          signalType: "signal",
                          type: "cable"
                        })}</Fragment> : null}
                    </Fragment> : null}
                  </div>
                  {item.pinL && item.pinR ? <div className='connection-pin-long-line'></div> : null}
                  {/* pin R connection */}
                  <div className='connection-pin-r-value-box'>
                    {item.pinR ?
                      this.pinRDisplay({
                        item,
                        models: connector2Models,
                        signal,
                        index,
                        rightNetWidth,
                        rightPinWidth
                      }) : null}
                  </div>
                </div>)}
            </div>)}
        </ul>
      </div >
    )
  }

  portPopover = ({ pin, pinPort, pinType, type, signalInfo, signal, signalType }) => {
    const cssId = `${pin}::${pinType}`;

    if (pinPort) {
      return <div
        className='connection-pin-input connection-pin-input-value'
        id={cssId}
        title={pinPort}
        onClick={(e) => {
          this.props.connectorSignalClick({
            e,
            signalInfo,
            signal,
            signalType,
            type
          })
        }}
      >
        <div className='connection-pin-input-pinport'>
          <span className='connection-pin-input-pinport-text'>
            {pinPort}
          </span>
        </div>
      </div>
    } else {
      return <div
        className='connection-pin-empty-input'
        id={cssId}
      ></div>;
    }
  }

  pinLDisplay = ({ item, models, signal, index, leftNetWidth, leftPinWidth }) => {
    return <Fragment>
      <div className='connection-pin-long-line'></div>
      {item.pinLNet ? <Fragment>
        <div className='connection-pin-net-box' style={{ width: leftNetWidth + 16 }} title={item.pinLNet}>{item.pinLNet}</div>
      </Fragment> : null}
      {index === 0 && signal.channel1_signal && item.pinL ?
        <div className='connection-pin-list-channel1-signal-title'>
          <span
            className="connection-pin-list-channel-signal-span"
            onClick={(e) => {
              this.props.connectorSignalClick({
                e,
                signalInfo: signal,
                signal: signal.channel1_signal,
                signalType: "channel1_signal",
                type: "pinL"
              })
            }}
          >{signal.channel1_signal}</span>
        </div> : null
      }
      {/* pin_L */}
      {
        item.pinL ? <Fragment>
          <div className='connection-pin-l-box' style={{ width: leftPinWidth + 16 }} title={item.pinL}>{item.pinL}</div>
        </Fragment> : null
      }
      {
        (item.pinLPort || (models.length && models.find(item => !!item.libraryId && item.isExist))) ? <Fragment>
          {this.portPopover({
            pin: item.pinL,
            pinPort: item.pinLPort,
            pinType: "pinL",
            signalInfo: signal,
            signal: signal.channel1_signal,
            signalType: "channel1_signal",
            type: "pinL"
          })}
        </Fragment> : null
      }
      {
        ((item.pinLExternalMap && item.pinLExternalMap.port) || (models.length && models.find(item => !!item.libraryId && item.isExist))) ? <Fragment>
          {this.portPopover({
            pin: item.pinL,
            pinPort: item.pinLExternalMap.port,
            pinType: "pinLExternal",
            signalInfo: signal,
            signal: signal.channel1_signal,
            signalType: "channel1_signal",
            type: "pinL"
          })}
        </Fragment> : null
      }
    </Fragment >
  }

  pinRDisplay = ({ item, models, signal, index, rightNetWidth, rightPinWidth }) => {
    return <Fragment>
      <div className='connection-pin-long-line'></div>
      {/* port */}
      {((item.pinRExternalMap && item.pinRExternalMap.port) || (models.length && models.find(item => !!item.libraryId && item.isExist))) ? <Fragment>
        {this.portPopover({
          pin: item.pinR,
          pinPort: item.pinRExternalMap.port,
          pinType: "pinRExternal",
          signalInfo: signal,
          signal: signal.channel2_signal,
          signalType: "channel2_signal",
          type: "pinR"
        })}
      </Fragment> : null}
      {(item.pinRPort || (models.length && models.find(item => !!item.libraryId && item.isExist))) ? <Fragment>
        {this.portPopover({
          pin: item.pinR,
          pinPort: item.pinRPort,
          pinType: "pinR",
          signalInfo: signal,
          signal: signal.channel2_signal,
          signalType: "channel2_signal",
          type: "pinR"
        })}
      </Fragment> : null}
      {/* pin_R */}
      {item.pinR ? <Fragment>
        <div className='connection-pin-r-box' style={{ width: rightPinWidth + 16 }} title={item.pinR}>{item.pinR}</div>
      </Fragment> : null}
      {index === 0 && signal.channel2_signal && item.pinR ?
        <div className='connection-pin-list-channel2-signal-title'>
          <span
            className="connection-pin-list-channel-signal-span"
            onClick={(e) => {
              this.props.connectorSignalClick({
                e,
                signalInfo: signal,
                signal: signal.channel2_signal,
                signalType: "channel2_signal",
                type: "pinR"
              })
            }}
          >{signal.channel2_signal}</span>
        </div> : null}
      {/* pin_R net */}
      {item.pinRNet ? <Fragment>
        <div className='connection-pin-net-box' style={{ width: rightNetWidth + 16 }} title={item.pinRNet}>{item.pinRNet}</div>
      </Fragment> : null}
    </Fragment>
  }
}

export default PinPortRender;