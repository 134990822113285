import { takeEvery, select, put, call, delay, fork } from "@redux-saga/core/effects";
import {
  START_DCR_SIMULATION,
  INIT_SIMULATION_INFO,
  DOES_SIMULATION_FAILED
} from '../actionType'
import {
  updateMultiSimulationInfo
} from '../action'
import {
  getInterfaceWorkStatus
} from '@/services/project';
import { DCR } from '@/constants/treeConstants';
import { simulationDCRPromise } from '@/services/Cascade/DCR/DCRCtrl';
import { getStackupErrorCheck, startNewWorkflow } from '../simulationSaga';
import { getDCRInfo, uploadDCRErrorMsg, changeResistanceTableLoading, updateDCRData, updateWarningMsg } from '../../DCR/action'
import { updateSimulationLoading } from "../../project/action";
import { openTabFooter } from "../../../../MonitorStore/action";
import { getCheckDataMessage } from '@/services/Cascade/helper/PathRHelper';
import { checkResistanceData } from '../../../../../services/Cascade/DCR/checkPathRData';
import { getCascadeSimulationLog } from '@/services/Cascade/simulation/simulationCtrl'
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import CascadeChannels from "../../../../../services/Cascade/DB/cascadeChannels";
import { updateSignOffTemplateListStatus } from "../../SignOffTemplate/action";
import { SIGN_OFF_TEMPLATE } from "../../../../../constants/treeConstants";
import { initSimulationInfo } from '../action'
import { updateErrorToTemplate } from "../../SignOffTemplate/signOffTemplateSaga";

function* _startDCRSimulation(action) {
  const { id, resistanceData: newData, designId: newDesignId, signOffId } = action;
  let { CascadeReducer: { DCR: { verificationId, resistanceData, designId }, project: { openProjectId } } } = yield select();
  try {
    // init simulation info

    // init monitor msg
    yield put(uploadDCRErrorMsg([]))
    yield put(updateWarningMsg([]))

    if (id && newData && newDesignId) {
      verificationId = id
      resistanceData = newData
      designId = newDesignId
    }

    // check resistance data
    const { debugMonitor, _data, errorIds, warningMessage } = checkResistanceData({ data: resistanceData, designId, getMessage: getCheckDataMessage });
    if(warningMessage.length) {
      yield put(updateWarningMsg(warningMessage));
    }
    if (debugMonitor.length || errorIds.length) {
      yield put(updateMultiSimulationInfo({
        verificationId,
        info: {
          startMsg: null,
          log: null,
          progress: -1,
          endMsg: null,
        }
      }));
      yield put(openTabFooter());
      yield put(updateDCRData({ resistanceData: _data, errorMessage: debugMonitor }));
      yield delay(100);
      yield put(changeResistanceTableLoading(false));
    }

    //stackup error check
    const stackupError = yield call(getStackupErrorCheck, {
      designId,
      verificationId
    });
    if (stackupError && stackupError.length) {
      return;
    }

    // get DCR status
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        return
      }
    }

    // start simulation
    yield put(updateMultiSimulationInfo({
      verificationId: id,
      info: {
        startMsg: "==> Prepare Extraction data...",
        log: null,
        monitor: null,
        endMsg: null
      }
    }))
    const res = yield call(simulationDCRPromise, id);
    if (!res) {
      yield put(updateMultiSimulationInfo({
        verificationId,
        info: {
          startMsg: null,
          log: null,
          monitor: null,
          endMsg: "Simulation failed!"
        }
      }))
      //if Path-r created by sign-off template, update error message to sign-off template
      yield call(updateErrorToTemplate, {
        openProjectId,
        id,
        _signOffId: signOffId,
        error: "Simulation failed!",
        type: DCR
      });
      return;
    }

    const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
    if (interfaceStatus && interfaceStatus.length > 0) {
      if (signOffId) {
        const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
        yield put(updateSignOffTemplateListStatus(list, openProjectId));
      }

      yield fork(startNewWorkflow, {
        verificationId,
        interfaceStatus,
        key: DCR
      })
      return;
    } else {
      yield put(updateMultiSimulationInfo({
        verificationId,
        info: {
          startMsg: null,
          log: null,
          monitor: null,
          endMsg: "Simulation failed!"
        }
      }))
      if (signOffId) {
        //if Path-r created by sign-off template, update error message to sign-off template
        yield call(updateErrorToTemplate, {
          openProjectId,
          id,
          _signOffId: signOffId,
          error: "Simulation failed!",
          type: DCR
        })
        const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
        yield put(updateSignOffTemplateListStatus(list, openProjectId));
      }
      // _initSimulationInfo 
      yield put(initSimulationInfo())
    }

  } catch (error) {
    console.error(error);
    yield put(uploadDCRErrorMsg(error ? [`==> ${error}`] : []));
    yield delay(1000);
    let log = yield call(getCascadeSimulationLog, verificationId)
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: null,
        log: log,
        monitor: null,
        endMsg: null
      }
    }))
    if (signOffId) {
      //if Path-r created by sign-off template, update error message to sign-off template
      yield call(updateErrorToTemplate, {
        openProjectId,
        id,
        _signOffId: signOffId,
        error: error,
        type: DCR
      })
      const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
      yield put(updateSignOffTemplateListStatus(list, openProjectId));
    }
  } finally {
    yield put(updateSimulationLoading(false))
  }
}

function* _initSimulationInfo(action) {

}

function* _PathRSimulationFinish(action) {
  const { id } = action;
  const { CascadeReducer: { DCR: { verificationId }, project: { viewList } } } = yield select();
  if (id === verificationId && viewList.includes(DCR)) {
    yield put(getDCRInfo(id))
  }
}

function* DCRSimulationSaga() {
  yield takeEvery(START_DCR_SIMULATION, _startDCRSimulation);
  yield takeEvery(INIT_SIMULATION_INFO, _initSimulationInfo);
  yield takeEvery(DOES_SIMULATION_FAILED, _PathRSimulationFinish)
}


export default DCRSimulationSaga;