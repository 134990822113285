export const SYSTEM_VIEW = "BMA_System_View",
  RANK_MARGIN = "BMA_Rank_Margin",
  BIT_MARGINS = "BMA_Bit_Margins",
  COMPARISON = "BMA_Comparison",
  HIST_SUMMARY = "BMA_Hist_Summary";

export const DATA_CONTENT = "data_content";


export const RUN_MENU_LIST = [
  { title: "System View", key: SYSTEM_VIEW },
  { title: "Rank Margin", key: RANK_MARGIN },
  { title: "Bit Margins", key: BIT_MARGINS },
  { title: "Comparison", key: COMPARISON },
  { title: "Histogram Summary", key: HIST_SUMMARY }
];