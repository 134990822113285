import { combineReducers } from 'redux';
import {
  AndesProjectReducer
} from './project';
import {
  default as explorerReducer
} from '../../LayoutExplorer/store/Andes/reducer';
import {
  default as stackupReducer
} from '../../LayoutExplorer/Andes/stackup/store/reducer';
import { AndesReducer } from './andes';
import { simulationReducer } from './simulation';
import AndesUploadReducer from '../AndesSider/Upload/reducer';
import { resultReducer } from './result';

const rootReducer = combineReducers({
  project: AndesProjectReducer,
  explorer: explorerReducer,
  upload: AndesUploadReducer,
  andes: AndesReducer,
  stackup: stackupReducer,
  simulationReducer,
  resultReducer,
});

export default rootReducer;