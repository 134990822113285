import {
  GET_PDN_CONTENT,
  UPDATE_PDN_CONTENT,
  SELECT_NETS,
  UPDATE_PDN_INTERFACE,
  VOLTAGE_CHANGE,
  SAVE_VRM,
  EXPAND_MENU,
  SAVE_COMPONENTS,
  IGNORE_COMPONENTS,
  UPDATE_PACKAGE_INFO_IN_PDN_CONTENT,
  SAVE_CONFIG,
  FIND_VRM_MODEL,
  FIND_VRM_LIST_MODEL,
  SAVE_OPTIMIZATION,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS,
  CHANGE_USAGE,
  UPDATE_PDN_IFDOEXTRACTION,
  SAVE_CURRENT_PDN,
  CURRENT_PDN_DEBUG_VERIFY,
  SAVE_BGA_CONNECTION_NAME,
  UPDATE_COMP_RLC_PREFIX,
  GET_VRM_COMP,
  DECAP_REMOVED_CHANGE,
  SAVE_CURRENT_PDN_BY_SETTING,
  PREFIX_PROMPT,
  SAVE_PDN_CONTENT,
  UPDATE_INCLUDE_EXTENDED,
  SAVE_MAIN_NETS,
  CHECK_NETS,
  CLOSE_CHECK_NETS_MSG,
  FIND_VRM_LOADING,
  SAVE_SELECTED_MODEL,
  CREATE_COPY_DECAP,
  SAVE_VRM_MODEL,
  AUTO_SELECT_NETS,
  AUTO_CHECK_NETS,
  UPDATE_SELECT_NETS,
  GET_NETS,
  FIND_POWER_DOMAIN_LOADING,
  UPDATE_CAPCONNECTREFNET,
  SAVE_PACKAGE_COMPONENT,
  UPDATE_SIM_CONFIG,
  SAVE_PREVIEW_STATUS,
  TRACE_BACK_VRM,
  UPDATE_PDNS_AFTER_REPLACE_PCB
} from './actionTypes';

export const getPDNContent = (PDNID) => ({
  type: GET_PDN_CONTENT,
  PDNID
});

export const updatePDNContent = (info) => ({
  type: UPDATE_PDN_CONTENT,
  info
});

export const selectNets = (powerNets, first) => ({
  type: SELECT_NETS,
  powerNets,
  first
});

export const checkNets = (pwrNets, gndNets) => ({
  type: CHECK_NETS,
  pwrNets,
  gndNets
});

export const updatePDNInterface = (data) => ({
  type: UPDATE_PDN_INTERFACE,
  data
});

export const voltageChange = (voltage) => ({
  type: VOLTAGE_CHANGE,
  voltage
});

export const saveVRM = (vrmType, data, index) => ({
  type: SAVE_VRM,
  vrmType,
  data,
  index
});

export const expandMenu = (expandKeys) => ({
  type: EXPAND_MENU,
  expandKeys
});

export const saveComponents = ({ part, model, value, splitPart, comps, models }) => ({
  type: SAVE_COMPONENTS,
  part,
  model,
  value,
  splitPart,
  comps,
  models,
});

export const saveSplitComponents = ({ part, splitPart, comps }) => ({
  type: SAVE_SPLIT_COMPONENTS,
  part,
  splitPart,
  comps
});

export const ignoreComponents = ({ part, components, checked, usage }) => ({
  type: IGNORE_COMPONENTS,
  part,
  components,
  usage,
  checked
});

export const updatePackageInfoInPDNContent = (packageInfo) => ({
  type: UPDATE_PACKAGE_INFO_IN_PDN_CONTENT,
  packageInfo
});

export const saveConfig = (Config, optionType) => ({
  type: SAVE_CONFIG,
  Config,
  optionType
});

export const findVRMModel = (vrmId, getVRM) => ({
  type: FIND_VRM_MODEL,
  vrmId,
  getVRM
});

export const findVRMListModel = (vrmIds) => ({
  type: FIND_VRM_LIST_MODEL,
  vrmIds
})

export const optimizationChangeAction = (opt) => ({
  type: SAVE_OPTIMIZATION,
  ...opt
});

export const saveMergeComponents = ({ part, partList }) => ({
  type: SAVE_MERGE_COMPONENTS,
  part,
  partList
});

export const changeUsage = ({ part, chips, usage }) => ({
  type: CHANGE_USAGE,
  part,
  chips,
  usage
});

export const updatePDNIfDoExtraction = (data) => ({
  type: UPDATE_PDN_IFDOEXTRACTION,
  data
});

export const saveCurrentPDN = (verificationIds, verificationId) => ({
  type: SAVE_CURRENT_PDN,
  verificationIds,
  verificationId
})

export const saveCurrentPDNBySetting = () => ({
  type: SAVE_CURRENT_PDN_BY_SETTING
})

export const PDNDebugVerify = (step, verificationId) => ({
  type: CURRENT_PDN_DEBUG_VERIFY,
  step,
  verificationId
});

export const saveBGAConnectionName = (name) => ({
  type: SAVE_BGA_CONNECTION_NAME,
  name
})

export const updateCompRLCPrefix = (prefixList) => ({
  type: UPDATE_COMP_RLC_PREFIX,
  prefixList
})

export const getVRMComponent = () => ({
  type: GET_VRM_COMP
});

export const decapRemovedChange = ({ part, removes, usage }) => ({
  type: DECAP_REMOVED_CHANGE,
  part,
  removes,
  usage
});

export const updatePrefixDisplay = (showPrefix) => ({
  type: PREFIX_PROMPT,
  showPrefix
});

export const savePDNContent = (data) => ({
  type: SAVE_PDN_CONTENT,
  data
});

export const updateIncludeExtended = (includeExtended) => ({
  type: UPDATE_INCLUDE_EXTENDED,
  includeExtended
});

export const saveMainNets = (pwrNets, gndNets) => ({
  type: SAVE_MAIN_NETS,
  pwrNets,
  gndNets
});

export const closeNetsErrorMsg = () => ({
  type: CLOSE_CHECK_NETS_MSG
})

export const findVRMLoading = (loading) => ({
  type: FIND_VRM_LOADING,
  loading
})

export const saveSelectedModel = (model) => ({
  type: SAVE_SELECTED_MODEL,
  model
})

export const createCopyDecap = (pdnInfo) => ({
  type: CREATE_COPY_DECAP,
  Info: pdnInfo
})

export const saveVRMModelUpdate = (data) => ({
  type: SAVE_VRM_MODEL,
  data
})

export const autoSelectNets = ({ powerNets, first, pdnInfo }) => ({
  type: AUTO_SELECT_NETS,
  powerNets,
  first,
  pdnInfo
})

export const updateSelectNets = ({ powerNets, first, pdnInfo }) => ({
  type: UPDATE_SELECT_NETS,
  powerNets,
  first,
  pdnInfo
})

export const autoCheckNets = (pwrNets, gndNets, pdnInfo) => ({
  type: AUTO_CHECK_NETS,
  pwrNets,
  gndNets,
  pdnInfo
})

export const getNets = ({ netList, PDN, name, selectType }) => ({
  type: GET_NETS,
  netList,
  PDN,
  name,
  selectType
})

export const findPowerDomainLoading = (loading) => ({
  type: FIND_POWER_DOMAIN_LOADING,
  loading
})

export const updateCapsConnectRefNet = (capsConnectRefNet) => ({
  type: UPDATE_CAPCONNECTREFNET,
  capsConnectRefNet
})

export const savePackageComponent = (name) => ({
  type: SAVE_PACKAGE_COMPONENT,
  name
})

export const updateSimConfig = (checkObj) => ({
  type: UPDATE_SIM_CONFIG,
  checkObj
})

export const savePreviewStatus = (status) => ({
  type: SAVE_PREVIEW_STATUS,
  status
})

export const traceBackVRM = (vrms) => ({
  type: TRACE_BACK_VRM,
  vrms
})

export const updatePDNsAfterReplacePCB = (pcbId) => ({
  type: UPDATE_PDNS_AFTER_REPLACE_PCB,
  pcbId
})