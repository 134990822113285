const vendorList = ['Murata'];
const tempList = ['X5R', 'X5S', 'X6S', 'X6T', 'X7R', 'X7S', 'X7T', 'X7U', 'X8R', 'C0G', 'U2J', 'X8L', 'X8M', 'ZLM', 'X8G'];
const formList = ['0201M/008004', '0402M/01005', '05025M/015008', '0510M/0204', '0603M/0201', '0610M/02404', '0816M/0306', '1005M/0402', '1220M/0508', '1608M/0603', '1632M/0612', '1810M/0704', '2012M/0805', '2828M/1111', '3216M/1206', '3225M/1210', '4520M/1808', '4532M/1812', '5728M/2211', '5750M/2220'];
const voltageList = ["2.5V", "4V", "6.3V", "10V", "16V", "20V", "25V", "35V", "50V", "63V", "80V", "100V", "200V", "250V", "350V", "450V", "500V", "630V", "1000V", "1250V", "2000V", "3150V", "250Vac"]
const capList = ['330uF', '220uF', '100uF', '68uF', '47uF', '33uF', '22uF',
  '18uF', '17uF', '15uF', '10uF', '6.8uF', '4.7uF', '4.3uF',
  '3.3uF', '2.2uF', '1.5uF', '1.2uF', '1uF', '820nF', '680nF',
  '560nF', '470nF', '390nF', '330nF', '270nF', '220nF', '180nF',
  '150nF', '120nF', '100nF', '94nF', '91nF', '82nF', '78nF',
  '75nF', '68nF', '66nF', '62nF', '56nF', '54nF', '51nF',
  '47nF', '44nF', '43nF', '39nF', '36nF', '33nF', '30nF',
  '27nF', '24nF', '22nF', '20nF', '18nF', '16nF', '15nF',
  '13nF', '12nF', '11nF', '10nF', '9.1nF', '8.2nF', '7.5nF',
  '6.8nF', '6.2nF', '5.6nF', '5.1nF', '4.7nF', '4.3nF', '3.9nF',
  '3.6nF', '3.3nF', '3nF', '2.7nF', '2.4nF', '2.2nF', '2nF',
  '1.8nF', '1.6nF', '1.5nF', '1.3nF', '1.2nF', '1.1nF', '1nF',
  '910pF', '820pF', '750pF', '680pF', '620pF', '560pF', '510pF',
  '470pF', '430pF', '390pF', '360pF', '330pF', '300pF', '270pF',
  '220pF', '200pF', '180pF', '150pF', '120pF', '110pF', '100pF', '91pF', '82pF',
  '75pF', '68pF', '62pF', '56pF', '51pF', '47pF', '43pF',
  '39pF', '36pF', '33pF', '30pF', '27pF', '24pF', '22pF',
  '20pF', '18pF', '16pF', '15pF', '13pF', '12pF', '11pF',
  '10pF', '9.9pF', '9.8pF', '9.7pF', '9.6pF', '9.5pF', '9.4pF',
  '9.3pF', '9.2pF', '9.1pF', '9pF', '8.9pF', '8.8pF', '8.7pF',
  '8.6pF', '8.5pF', '8.4pF', '8.3pF', '8.2pF', '8.1pF', '8pF',
  '7.9pF', '7.8pF', '7.7pF', '7.6pF', '7.5pF', '7.4pF', '7.3pF',
  '7.2pF', '7.1pF', '7pF', '6.9pF', '6.8pF', '6.7pF', '6.6pF',
  '6.5pF', '6.4pF', '6.3pF', '6.2pF', '6.1pF', '6pF', '5.9pF',
  '5.8pF', '5.7pF', '5.6pF', '5.5pF', '5.4pF', '5.3pF', '5.2pF',
  '5.1pF', '5pF', '4.9pF', '4.8pF', '4.7pF', '4.6pF', '4.5pF',
  '4.4pF', '4.3pF', '4.2pF', '4.1pF', '4pF', '3.9pF', '3.8pF',
  '3.7pF', '3.6pF', '3.5pF', '3.4pF', '3.3pF', '3.2pF', '3.1pF',
  '3pF', '2.9pF', '2.8pF', '2.7pF', '2.6pF', '2.5pF',
  '2.4pF', '2.3pF', '2.2pF', '2.1pF', '2pF', '1.9pF', '1.8pF', '1.7pF',
  '1.6pF', '1.5pF', '1.4pF', '1.3pF', '1.2pF', '1.1pF', '1pF', '0.9pF',
  '0.8pF', '0.75pF', '0.7pF', '0.6pF', '0.5pF', '0.4pF', '0.3pF', '0.27pF',
  '0.24pF', '0.2pF', '0.15pF', '0.1pF'];
const dcBiasVoltageList = ["0V"]

  export { 
    vendorList,
    tempList,
    formList,
    voltageList,
    capList,
    dcBiasVoltageList
  }