import {
  LIBRARY_DATA, TRASH, PCB, END_TO_END_CHANNEL, PCB_CHANNEL,
  LIBRARY_FILE, LIBRARY_FOLDER, DCR, IR_EXPLORER, DESIGN_TREE,
  IMPEDANCE, PACKAGE, POWER_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE, SPICE_CARD_TEMPLATE, IO_SIM, PCB_PRE_LAYOUT
} from "../../constants/treeConstants";
import { CASCADE } from "../../constants/pageType";

const LIBRARY_MSG = 'Once the library is deleted, all pre layout that use this library will be updated.',
  TRASH_MSG = "Can not be restored after clearing trash. Are you sure you want to delete it?",
  PCB_MSG = "Once the PCB is deleted, all the channels will be updated.";

function getMessage(type) {
  return `Can not be restored after deleting the ${type}. Are you sure you want to delete it?`;
}

function getPopMessage(type, dataType, product) {

  switch (dataType) {
    case LIBRARY_DATA:
      return product === CASCADE ? getMessage('library') : LIBRARY_MSG;
    case TRASH:
      return TRASH_MSG;
    case PCB:
      return PCB_MSG;
    case END_TO_END_CHANNEL:
      return getMessage("multi-PCB channel");
    case PCB_CHANNEL:
      return getMessage("PCB channel");
    case LIBRARY_FILE:
    case LIBRARY_FOLDER:
      return getMessage("library");
    case DCR:
      return getMessage("Path-R task");
    case IR_EXPLORER:
      return getMessage("IR task");
    case IMPEDANCE:
      return getMessage("Impedance task");
    case PACKAGE:
      return getMessage("Package");
    case POWER_TREE:
    case DESIGN_TREE:
    case SINGLE_TREE:
      return getMessage("Power Tree");
    case SIGN_OFF_TEMPLATE:
      return getMessage("sign-off template");
    case SPICE_CARD_TEMPLATE:
      return getMessage("input");
    case IO_SIM:
      return getMessage("interface");
    case PCB_PRE_LAYOUT:
      return getMessage("Pre-Layout");
    default: return getMessage(type);
  }
}

export {
  getPopMessage
}