import React, { Component } from 'react';
import { connect } from 'react-redux';
import Monitor from '@/components/Monitor/UploadMonitor';
import { cancelUploadWorkflow, changeDesign } from '../../CascadeSider/uploadPCB/store/action';
import designConstructor from '@/services/helper/designConstructor';
import { CASCADE } from '../../../../constants/pageType';
import projectDesigns from '@/services/helper/projectDesigns';
import { getDisplayDesignList } from '../../../../services/PCBHelper/pcbHelper';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';

class UploadMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subSelect: {}
    }
  }

  render() {
    return (
      <Monitor
        {...this.props}
      />
    )
  }
}

const mapState = (state) => {
  const { visible, uploadProgress, transProgress, msg, monitor, uploadProjectId,
    endMsg, uploadDesignId, uploadDesignName, uploadWorkflowId, compressProgress, designLog, openDesignId, openDesignType } = state.CascadeReducer.CascadeUploadReducer;
  const { openProjectId } = state.CascadeReducer.project;
  const { monitorScreenInfo: {
    projectName,
    currentProjectId
  }, tabVisible } = state.MonitorInfoReducer;

  const _designs = projectDesigns.getDesigns(openProjectId)
  let design = designConstructor.getDesign(openDesignId) || null;
  design = !design ? _designs[_designs.length - 1] || {} : (design || {});

  let startMsg = "", _monitor = [], _endMsg = [];
  let designs = currentProjectId ? designConstructor.getDesignValues(currentProjectId) : [];
  if (uploadProjectId === currentProjectId && (visible || (uploadDesignName && ((monitor && monitor.length) || msg)))) {
    designs.push({ id: "upload", name: uploadDesignName, type: openDesignType });
  }

  if (uploadProjectId === currentProjectId && openDesignId === "upload") {
    design = { name: uploadDesignName, id: "upload", type: openDesignType }
    startMsg = msg;
    _monitor = monitor;
    _endMsg = endMsg;
  }

  const _designs_ = getDisplayDesignList(designs);
  return {
    visible,
    startMsg: startMsg,
    uploadProgress,
    transProgress,
    compressProgress,
    monitor: _monitor,
    uploadProjectId,
    endMsg: _endMsg,
    uploadDesignId,
    tabVisible,
    projectName,
    openProjectId: currentProjectId,
    uploadWorkflowId,
    designLog,
    openDesignId,
    uploadDesignName,
    subName: design.name,
    designs: _designs_,
    pageType: CASCADE,
    subOptionType: "group",
    subSelect: { ...design }
  }
}

const mapDispatch = (dispatch) => ({
  _cancelUploadWorkflow(workflowId) {
    dispatch(cancelUploadWorkflow(workflowId));
  },
  _changeDesign(designId) {
    dispatch(changeDesign(designId))
  }
})

export default connect(mapState, mapDispatch)(UploadMonitor);