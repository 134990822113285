import { connect } from 'react-redux';
import layoutMonitor from '../../../LayoutExplorer/components/layoutMonitor';

const mapState = (state) => {
  const { project: { currentProjectId, currentProjectName } } = state.PDNReducer;
  return {
    projectId: currentProjectId,
    projectName: currentProjectName
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(layoutMonitor);