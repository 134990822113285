import { takeEvery, call, select, put } from 'redux-saga/effects';
import { defaultTreeItems } from '../../../../services/Cascade';
import {
  UPDATE_LIBRARY_DATA,
  UPDATE_LIBRARY_MENU,
  DELETE_LIBRARY,
  INIT_SYS_LIBRARY,
  UPDATE_SYS_LIBRARY,
  UPDATE_CUSTOM_LIBRARY,
  AFTER_IMPORT_LIBRARY,
  UPDATE_PIN_MAP_LIST
} from './actionType';
import {
  getLibraryList,
  saveLibraryData,
  getLibraryArray,
  deleteLibrary,
  getSysGeneric
} from '@/services/Cascade/library';
import {
  VRM,
  DECAP_TOUCHSTONE,
  DECAP_SPICE,
  DECAP_RLC,
  PIN_MAP,
  DRIVER,
  WIRE_BOND_XML,
  CUSTOM_TOUCHSTONE,
  CUSTOM_SPICE,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  CPM_SPICE,
  POWERSI_OPTIONS,
  POWERDC_OPTIONS
} from '@/constants/libraryConstants';
import { SYS_LIBRARY, CUSTOM_LIBRARY } from '@/constants/treeConstants';
import { updateLibraryMenu, saveDecapGeneric, updateLibraryStatus, updateCustomLibrary, saveLibraryList } from './action'
import { updateTreeList, updateExpand, updateDefaultDecap, libraryDefault } from '../project/action'
import {
  LIBRARY_INDEX,
  // VRM_INDEX,
  DECAP_INDEX,
  DECAP_TOUCHSTONE_INDEX,
  DECAP_SPICE_INDEX,
  DECAP_RLC_INDEX,
  SYSTEM_LIBRARY_INDEX,
  SYS_DECAP_INDEX,
  SYS_DECAP_VENDOR_INDEX,
  SYS_DECAP_GENERIC_INDEX,
  // PIN_MAP_INDEX,
  DRIVER_INDEX,
  SYS_CUSTOM_INDEX,
  WIRE_BOND_INDEX,
  CUSTOM_MODEL_INDEX,
  CUSTOM_TOUCHSTONE_INDEX,
  CUSTOM_SPICE_INDEX,
  EXTRACTION_OPTIONS_INDEX,
  EXTRACTION_HFSS_INDEX,
  EXTRACTION_SIWAVE_INDEX,
  CPM_INDEX,
  EXTRACTION_POWERSI_INDEX,
  EXTRACTION_POWERDC_INDEX
} from '@/services/Cascade/library';
import { getDefaultDecap } from '@/services/Cascade/library'
import libraryConstructor from '@/services/Cascade/helper/libraryConstructor'
import { libraryTypeList } from '@/services/Cascade/helper/libraryConstructor'
import SystemLibrary, { SysLibraryItem } from '@/services/Library/systemLibraryStore';
import { getLibraryFolderChildren } from '../../../../services/Cascade/library'

export function* _updateLibraryMenu(action) {
  /*   const { firstLoad } = action */
  const res = yield call(getLibraryList)
  const { CascadeReducer: { project, library: { customSpiceList: oldCustomSPICEList }, DecapList: oldDecapList = [], cpmList: oldCpmList = [] } } = yield select();
  const { treeItems } = project;
  let _treeItems = [...treeItems];
  let VRMLibraryList = [], decapTouchstoneList = [], decapSPICEList = [], decapRLCList = [], pinMapLibraryList = [], driverList = [], wireBondList = [],
    customTouchstoneList = [], customSPICEList = [], extractionHfssOptionsList = [], extractionSiwaveOptionsList = [], extractionPowerSIOptionsList = [], extractionPowerDCOptionsList = [], cpmList = [];
  if (_treeItems[LIBRARY_INDEX] && _treeItems[LIBRARY_INDEX].children) {
    // update VRM library list
    VRMLibraryList = getLibraryArray(VRM, res[VRM]);
    // _treeItems[LIBRARY_INDEX].children[VRM_INDEX].children = VRMLibraryList;
    // update decap touchstone library list
    decapTouchstoneList = getLibraryArray(DECAP_TOUCHSTONE, res[DECAP_TOUCHSTONE] || []);
    decapTouchstoneList = yield call(addFileInFolder, decapTouchstoneList, oldDecapList.filter(item => item.type === DECAP_TOUCHSTONE), DECAP_TOUCHSTONE)
    _treeItems[LIBRARY_INDEX].children[DECAP_INDEX].children[DECAP_TOUCHSTONE_INDEX].children = decapTouchstoneList;
    // update decap SPICE library list
    decapSPICEList = getLibraryArray(DECAP_SPICE, res[DECAP_SPICE]);
    _treeItems[LIBRARY_INDEX].children[DECAP_INDEX].children[DECAP_SPICE_INDEX].children = decapSPICEList;
    // update decap RLC library list
    decapRLCList = getLibraryArray(DECAP_RLC, res[DECAP_RLC]);
    _treeItems[LIBRARY_INDEX].children[DECAP_INDEX].children[DECAP_RLC_INDEX].children = decapRLCList;
    // todo - update decap part map library list
    /*  decapRLCList = getLibraryArray(DECAP_MODEL_MAP, res[DECAP_MODEL_MAP]);
     _treeItems[LIBRARY_INDEX].children[DECAP_MODEL_MAP_INDEX].children = decapRLCList; */
    // update pin map library list
    // pinMapLibraryList = getLibraryArray(PIN_MAP, res[PIN_MAP] || []);
    // _treeItems[LIBRARY_INDEX].children[PIN_MAP_INDEX].children = pinMapLibraryList;
    driverList = getLibraryArray(DRIVER, res[DRIVER] || []);
    _treeItems[LIBRARY_INDEX].children[DRIVER_INDEX].children = driverList;
    wireBondList = getLibraryArray(WIRE_BOND_XML, res[WIRE_BOND_XML] || []);
    _treeItems[LIBRARY_INDEX].children[WIRE_BOND_INDEX].children = wireBondList;
    // update cpm library list
    cpmList = getLibraryArray(CPM_SPICE, res[CPM_SPICE] || []).filter(item => item.name.match(/.sp$/i) || item.name.match(/.ploc$/i) || item.format === "Folder");
    cpmList = yield call(addFileInFolder, cpmList, oldCpmList, CPM_SPICE)
    _treeItems[LIBRARY_INDEX].children[CPM_INDEX].children = cpmList;
    // update custom touchstone library list
    customTouchstoneList = getLibraryArray(CUSTOM_TOUCHSTONE, res[CUSTOM_TOUCHSTONE]);
    _treeItems[LIBRARY_INDEX].children[CUSTOM_MODEL_INDEX].children[CUSTOM_TOUCHSTONE_INDEX].children = customTouchstoneList;
    // update custom SPICE library list
    customSPICEList = getLibraryArray(CUSTOM_SPICE, res[CUSTOM_SPICE] || []).filter(item => item.name.match(/.sp$/i) || item.name.match(/.ploc$/i) || item.format === "Folder");
    customSPICEList = yield call(addFileInFolder, customSPICEList, oldCustomSPICEList, CUSTOM_SPICE)
    _treeItems[LIBRARY_INDEX].children[CUSTOM_MODEL_INDEX].children[CUSTOM_SPICE_INDEX].children = customSPICEList;
    //update extraction options HFSS library list
    extractionHfssOptionsList = getLibraryArray(HFSS_OPTIONS, res[HFSS_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[EXTRACTION_HFSS_INDEX].children = extractionHfssOptionsList;
    //update extraction options Siwave library list
    extractionSiwaveOptionsList = getLibraryArray(SIWAVE_OPTIONS, res[SIWAVE_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[EXTRACTION_SIWAVE_INDEX].children = extractionSiwaveOptionsList;
    //update extraction options PowerSI library list
    extractionPowerSIOptionsList = getLibraryArray(POWERSI_OPTIONS, res[POWERSI_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[EXTRACTION_POWERSI_INDEX].children = extractionPowerSIOptionsList;
    //update extraction options PowerDC library list
    extractionPowerDCOptionsList = getLibraryArray(POWERDC_OPTIONS, res[POWERDC_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[EXTRACTION_POWERDC_INDEX].children = extractionPowerDCOptionsList;
  }

  libraryConstructor.clearAllCache();
  // //save library to cache
  for (let type of libraryTypeList) {
    res[type] && res[type].forEach(item => {
      libraryConstructor.addLibrary(type, item.id, item);
    });
  }

  yield put(updateTreeList({ treeItems: [..._treeItems] }));
  yield put(saveLibraryList({
    VRMList: VRMLibraryList,
    DecapList: [...decapTouchstoneList, ...decapSPICEList, ...decapRLCList],
    DriverList: driverList,
    wireBondList: wireBondList,
    customSpiceList: customSPICEList,
    customTouchstoneList,
    extractionHfssOptionsList,
    extractionSiwaveOptionsList,
    cpmList,
    extractionPowerDCOptionsList,
    extractionPowerSIOptionsList
  }))

  const defaultLibrary = yield call(getDefaultDecap)
  const { libraryId, libraryType, name } = defaultLibrary
  yield put(updateDefaultDecap(libraryId, libraryType, name))

  yield put(updateLibraryStatus());

}

function* _updateLibraryData(action) {
  const { data, libraryType } = action;
  if (data) {
    const library = yield call(saveLibraryData, data)
    libraryConstructor.addLibrary(libraryType, library.id, library);
    yield put(updateLibraryMenu());
  }
}
function* _deleteLibrary(action) {
  const { data } = action;
  const { CascadeReducer: { project: { defaultDecap } } } = yield select();
  yield call(deleteLibrary, data.id);
  if (defaultDecap.libraryId === data.id) {
    yield put(libraryDefault())
  }
  yield put(updateLibraryMenu(true));
}

function* getLevelOneLibrary() {
  const { CascadeReducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems], _customData = null;
  try {
    const { data, customData } = yield call(SystemLibrary.initStore);
    _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_VENDOR_INDEX].children = data;
    if (customData && customData[0]) {
      _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_CUSTOM_INDEX] = customData[0];
      _customData = customData[0];
    }

    const generic = yield call(getSysGeneric);
    let genericList = generic.map(ge => new SysLibraryItem(ge));
    _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_GENERIC_INDEX].children = genericList;
    yield put(saveDecapGeneric(genericList));
  } catch (error) {
    console.error(error);
  }
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
  const { CascadeReducer: { project: { expandedKeys } } } = yield select();
  let newExpandedKeys = expandedKeys.filter(key => !key.includes(SYS_LIBRARY));
  yield put(updateExpand(newExpandedKeys));
  if (_customData) {
    yield put(updateCustomLibrary(_customData.id, _customData.path))
  }
}

function* updateSysLibrary(action) {
  const { folderId, path } = action;
  const { CascadeReducer: { project: { treeItems } } } = yield select();
  const tree = yield call(SystemLibrary.getChildren, { folderId });
  let _treeItems = [...treeItems];
  let currentTree = _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_VENDOR_INDEX];
  path.split('/').forEach(name => {
    let findIndex = currentTree.children.findIndex(item => item.name === name);
    if (findIndex > -1) {
      currentTree = currentTree.children[findIndex];
    }
  })
  currentTree.load = true;
  currentTree.children = tree;
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _updateCustomLibrary(action) {
  try {
    const { folderId, path } = action;
    const { CascadeReducer: { project: { treeItems } } } = yield select();
    const tree = yield call(SystemLibrary.getChildren, { folderId, type: CUSTOM_LIBRARY });
    let _treeItems = [...treeItems];
    let currentTree = _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_CUSTOM_INDEX];
    if (!currentTree.children) {
      currentTree.children = []
    }
    path.split('/').forEach(name => {
      let findIndex = currentTree.children.findIndex(item => item.name === name);
      if (findIndex > -1) {
        currentTree = currentTree.children[findIndex];
      }
    })
    currentTree.load = true;
    currentTree.children = tree;
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
  } catch (error) {
    console.error(error);
  }
}

function* afterImportLibrary(action) {
  // clear the treeItem of the library
  const { CascadeReducer: { project: { treeItems } } } = yield select();
  const defaultLibraryMenu = defaultTreeItems.find(item => item.key === 'library')
  let _treeItems = treeItems.map(item => {
    if (item.key === 'library') {
      return JSON.parse(JSON.stringify(defaultLibraryMenu))
    }
    return item
  })
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
  // update new library Menu
  yield call(_updateLibraryMenu)
}

function* addFileInFolder(dataArr, oldDataArr, libraryType) {
  if (!Array.isArray(dataArr) || !dataArr.length) {
    return [];
  }

  let newDataArr = []
  for (let file of dataArr) {
    if (file && file.format === "Folder") {
      const { id } = file;
      const findInfo = oldDataArr && oldDataArr.length ? oldDataArr.find(it => it.id === id) : null;
      let children = [];
      if (findInfo && findInfo.children && findInfo.children.length) {
        children = [...findInfo.children]
      } else {
        let _children = [];
        _children = yield call(getLibraryFolderChildren, { id });
        if (_children && _children[libraryType] && _children[libraryType].length) {
          _children = _children[libraryType].filter(item => item.format === "File")
          _children.forEach(item => delete item.tag)
          if (_children && _children.length) {
            children = getLibraryArray(libraryType, _children)
          }
        }
      }
      newDataArr.push({ ...file, children })
    } else {
      newDataArr.push(file)
    }
  }
  return newDataArr;
}

function* updatePinMapList() {
  const res = yield call(getLibraryList);
  if (res[PIN_MAP]) {
    libraryConstructor.clearCache(PIN_MAP);
    res[PIN_MAP].forEach(item => {
      libraryConstructor.addLibrary(PIN_MAP, item.id, item);
    });
  }
}

function* librarySaga() {
  yield takeEvery(UPDATE_LIBRARY_MENU, _updateLibraryMenu);
  yield takeEvery(UPDATE_LIBRARY_DATA, _updateLibraryData);
  yield takeEvery(DELETE_LIBRARY, _deleteLibrary);
  yield takeEvery(INIT_SYS_LIBRARY, getLevelOneLibrary);
  yield takeEvery(UPDATE_SYS_LIBRARY, updateSysLibrary);
  yield takeEvery(UPDATE_CUSTOM_LIBRARY, _updateCustomLibrary);
  yield takeEvery(AFTER_IMPORT_LIBRARY, afterImportLibrary);
  yield takeEvery(UPDATE_PIN_MAP_LIST, updatePinMapList);
}

export default librarySaga;