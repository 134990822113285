import apiProcess from '../api/utility';
import workflowFliter from '../helper/workflowFliter';
import { workflowWaitingIndexByID, getWorkFlowStatus } from '../api/v2/workflowCtrl';
import { getSimulationMonitor, getRunningWorkFlow } from '../api/project';
import { getVerificationStatus } from '../../services/api/v2/verificationCtrl';
import { getDesignTranslationLog } from '../api/Design/design';
import { getSimulationMonitorList } from '../api/workflow';

export function workflowWaitingIndex(workflowId) {
  return new Promise((resolve, reject) => {
    if (!workflowId) {
      resolve(-1);
      return;
    }
    workflowWaitingIndexByID(workflowId).then(res => {
      if (res && res.data && res.data.data) {
        resolve(res.data.data.current);
      } else {
        resolve(-1);
      }
    }, error => {
      console.error(error);
      resolve(-1);
    })
  })
}

/**
 * Get verification workflow
 * 2021/04/23
 * @param {string} verificationId
 * @export
 * @returns Promise
 */
export function getVerificationWorkFlow(verificationId) {
  return workflowFliter(apiProcess(getWorkFlowStatus, verificationId));
};


/**
 * Get simulation monitor
 * 2021/04/23
 *
 * @export
 * @returns Promise
 */
export function getMonitor(workflowId) {
  return apiProcess(getSimulationMonitor, workflowId);
}

/**
 * 
 * Checkout verification status
 * 2020/06/08
 *
 * @export
 * @param {*} verificationId verification id
 * @returns Promise
 * 
 */
export function checkVerificationStatus(verificationId) {
  return apiProcess(getVerificationStatus, verificationId);
}


/**
 * 
 * Get design translation log
 * 2021/06/04
 *
 * @export
 * @param {String} designId design id
 * @returns Promise
 * 
 */
export function getDesignLog(designId) {
  return new Promise((resolve, reject) => {
    getDesignTranslationLog(designId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
}

/**
 * Get simulation monitor list
 * 2021/09/27
 *
 * @export
 * @returns Promise
 */
export function getMonitorList({ workflowId, verificationId, product }) {
  if (!workflowId || !verificationId || !product) {
    return;
  }
  return apiProcess(getSimulationMonitorList, { workflowId, verificationId, product });
}

/**
 * Get simulation monitor
 * 2024/06/24
 *
 * @export
 * @returns Promise
 */
export function getRunningWorkFlowPromise({ product, workflowId, verificationId }) {
  return apiProcess(getRunningWorkFlow, { product, workflowId, verificationId });
}