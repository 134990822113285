import StringHelper from '../utility/StringHelper';
import StringList from '../utility/StringList';

var CePad = function () {
  this.mShapeId = -1;
  this.mRotation = 0.0;
  this.mCW = true;
};

CePad.prototype.SetPadData = function (shapeId, rotation, cw) {
  this.mShapeId = shapeId;
  this.mRotation = rotation;
  this.mCW = cw;
}

CePad.prototype.GetDataString = function () {
  var dataList = new StringList();
  dataList.add(this.mShapeId);
  dataList.add(this.mRotation);
  dataList.add(StringHelper.BoolToString(this.mCW));
  return dataList;
}

CePad.prototype.UpdateByString = function (dataString) {
  if (dataString.size() >= 3) {
    this.mShapeId = parseInt(dataString.get(0), 10);
    this.mRotation = parseFloat(dataString.get(1));
    this.mCW = StringHelper.StringToBool(dataString.get(2));
  }

  if (dataString.size() >= 6) {
    this.antipad = new CePad();
    this.antipad.mShapeId = parseInt(dataString.get(3), 10);
    this.antipad.mRotation = parseFloat(dataString.get(4));
    this.antipad.mCW = StringHelper.StringToBool(dataString.get(5));
  }
}

export default CePad;