import React, { Component, Fragment, createRef } from "react";
import { createPortal } from "react-dom";
import Panel from "../../../../components/Panel";
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from "../../../../services/helper/panelSizeHelper";
import { Spin, Button, Checkbox } from 'antd';
import {
  delLibraryList,
  getNetsFilterLibraryList,
  uploadNetsFilterLibraryFile,
  editNetsFilterLibrary
} from "../../../../services/Sierra/library";
import DelConfirm from "../../../../components/DelConfirm";
import PowerNets from "./PowerNets";
import { newNanoId } from "../../../../services/helper/idHelper";
import "./index.css";

class NetsFilterPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
      maxWidth: 0,
      page: 1,
      data: [],
      pageSize: 40,
      totalPages: 1,
      totalElements: 40,
      openLoading: false,
      selectedFilters: [],
      applyAll: false
    }
    this.dialogRoot = document.getElementById("root");
    this.uploadRef = createRef();
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize()
    this.getLibraryData()
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 700),
      maxWidth: getPanelMaxWidth(offset, 900)
    })
  }

  closeModal = () => {
    this.props.closeLibraryPanel()
  }

  onPageChange = (page) => {
    this.setState({
      page
    }, () => {
      this.getLibraryData(page)
    })
  }

  getLibraryData = async (page, pageSize) => {
    this.setState({
      openLoading: true
    })
    let _page = page || 1, _pageSize = pageSize || 40;
    let data = null;
    let totalPages = this.state.totalPages, totalElements = this.state.totalElements;
    if (!data || !data.length) {
      try {
        const libraryObj = await getNetsFilterLibraryList({ page: _page - 1, pageSize: _pageSize });
        if (libraryObj) {
          data = libraryObj.data;
          totalPages = libraryObj.totalPages || totalPages;
          totalElements = libraryObj.totalElements || totalElements;
          _pageSize = totalElements;
        }
      } catch (error) {
        console.error(error);
        this.setState({
          msg: "Get nets filter failed!",
          openLoading: false
        })
        setTimeout(() => {
          this.setState({
            msg: ""
          })
        }, 3000)
      }
    }

    if (data && data.length) {
      const { isReSearch, filterList } = this.props;
      let selectedFilters = [];
      const _data = data.map(item => ({ ...item, rowId: newNanoId(6) }))
      if (isReSearch) {
        selectedFilters = filterList.length
          ? _data.map((item) => item.filter && filterList.includes(item.filter) ? `power_nets::${item.rowId}::${item.filter}` : null).filter(item => !!item)
          : _data.map((item) => item.filter ? `power_nets::${item.rowId}::${item.filter}` : null).filter(item => !!item)
      }
      this.setState({
        data: _data,
        page: _page,
        pageSize: _pageSize,
        totalPages,
        totalElements,
        openLoading: false,
        selectedFilters
      })
    } else {
      this.setState({
        openLoading: false
      })
    }
  }

  uploadExcel = () => {
    const ele = this.uploadRef.current;
    if (!ele) return;
    ele.click();
  }

  uploadFile = (e) => {
    this.file = e.target.files[0];
    const { data } = this.state;
    if (data.length) {
      this.setState({
        openPanel: true
      })
    } else {
      this._uploadFile("merge")
    }
    if (this.uploadRef.current && this.uploadRef.current.value) {
      this.uploadRef.current.value = '';
    }
  }

  _uploadFile = async (_uploadType) => {
    this.setState({
      openPanel: false
    })
    if (!this.file) {
      return;
    }
    const uploadType = _uploadType === "merge" ? "Add" : "Replace";
    try {
      //get api to parse file and get
      await uploadNetsFilterLibraryFile({ file: this.file, uploadType });
      this.setState({
        msg: "Upload file successfully!"
      });
      this.getLibraryData();
    } catch (error) {
      console.error(error);
      this.setState({
        msg: "Upload file failed!",
        checkFiles: [],
        isUpdate: true
      })
    }
    setTimeout(() => {
      this.setState({
        msg: ""
      })
    }, 3000)
    if (this.uploadRef.current && this.uploadRef.current.value) {
      this.uploadRef.current.value = '';
    }
  }

  saveData = async ({ data, editedFilter, delFilter, index = -1 }) => {
    this.setState({
      data,
      saving: true
    })
    if (delFilter && delFilter.id) {
      try {
        await delLibraryList([delFilter.id]);
        this.setState({
          data
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (!editedFilter || !editedFilter.filter) {
      this.setState({
        saving: false
      })
      return;
    }
    try {
      const editLibrary = await editNetsFilterLibrary({ editedFilter });
      if (!editedFilter.id && index > -1) {
        data[index].id = editLibrary && editLibrary.id ? editLibrary.id : null;
        this.setState({
          data
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  updateMessage = (msg) => {
    this.setState({
      msg
    });
    setTimeout(() => {
      this.setState({
        msg: ""
      })
    }, 3000)
  }

  _updateSelectedFilters = (selectedFilters) => {
    this.setState({
      selectedFilters
    })
  }

  getLibraryTable = () => {
    const { pageSize, page, data, totalPages, totalElements, maxHeight, maxWidth, selectedFilters } = this.state;
    const { isReSearch } = this.props;
    return <PowerNets
      pageSize={pageSize}
      page={page}
      data={data}
      totalPages={totalPages}
      totalElements={totalElements}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      isReSearch={isReSearch}
      selectedFilters={selectedFilters}
      uploadExcelRender={this.uploadExcelRender}
      saveData={this.saveData}
      _updateSelectedFilters={this._updateSelectedFilters}
    />
  }

  uploadExcelRender = () => {
    return <Button
      type="link"
      onClick={this.uploadExcel}
      className="nets-filter-library-upload-button"
    >Upload Excel</Button>
  }

  selectedFilter = () => {
    const { selectedFilters, data, applyAll } = this.state;
    const filters = selectedFilters.map(item => {
      if (!item) {
        return null
      }
      const filter = item.split("::")[2];
      const findV = data.find(it => it.filter === filter)
      if (!filter) {
        return null
      }
      return {
        filter,
        voltage: findV ? findV.voltage : ""
      }
    }).filter(item => !!item);
    this.props.saveFilters(filters, applyAll)
    this.props.closeLibraryPanel()
  }

  checkAllChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  render = () => {
    const { maxHeight, maxWidth, msg, openPanel, openLoading, applyAll } = this.state;
    const { isReSearch } = this.props;
    const msgClass = msg && msg.match("failed") ? "aurora-model-name-error-msg" : (msg && msg.match("success") ? "aurora-success-msg" : "aurora-model-name-error-msg");
    const content = (
      <Fragment>
        <Panel
          title={<div>
            <span>Power Nets Filter</span>
          </div>}
          className='sierra-nets-filter-library-panel sierra-panel'
          position='panel-center-top'
          minHeight={200}
          minWidth={100}
          width={getPanelWidth(maxWidth, { defaultWidth: 900 })}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onCancel={() => { this.closeModal() }}
          draggable
          zIndex={2000}
        >
          <div className="nets-filter-library-content">
            <Spin spinning={openLoading} tip={openLoading ? "Loading data..." : ""}>
              {msg ? <div className={msgClass}>{msg}</div> : null}
              {this.getLibraryTable()}
              {isReSearch ? <Fragment>
                <div className="sierra-nets-filter-item">
                  <Checkbox
                    className='pcb-power-net-search-checkbox'
                    checked={applyAll}
                    onChange={(e) => this.checkAllChange(e)}
                  >Apply to all PCBs in the project</Checkbox>
                </div>
                <div className="sierra-nets-filter-item sierra-nets-filter-apply-item">
                  <Button type="primary" onClick={() => this.selectedFilter()}>Apply</Button>
                </div>

              </Fragment> : null}
            </Spin>
          </div>
        </Panel>
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={".csv,.xls,.xlsx"}
          onChange={(e) => this.uploadFile(e)}
        />
        {
          openPanel ? <DelConfirm
            type="uploadPartLibrary"
            maskStyle={true}
            delConfirmClick={this._uploadFile}
            message={"Keep the existing setup?"}
          /> : null
        }
      </Fragment>
    );
    return createPortal(content, this.dialogRoot)
  }
}

export default NetsFilterPanel;