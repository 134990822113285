import { connect } from 'react-redux';
import { closePanel } from '../../store/FastPI/actionCreators';
import NetTable from '../../components/DesignInfo/NetTable';

const mapState = (state) => {
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return { defaultLeft, defaultTop }
}


const mapDispatch = (dispatch) => ({
  closePanel(designID) {
    dispatch(closePanel(designID))
  },
})

export default connect(mapState, mapDispatch)(NetTable);