import React, { Component, Fragment } from 'react';
import { LineChartOutlined } from '@ant-design/icons';
import { Radio, Checkbox } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { CURRENT_TRAPEZOIDAL, CURRENT_PWL_TABLE, CURRENT_PWL_FILE } from '@/services/Cascade/constants';
import ElementListener from '@/services/helper/elementListener';
import TrapezoidalValue from './TrapezoidalValue';
import PWLTable from './PWLTable';
import CurrentPlot from './CurrentPlot';
import CurrentUpload from './CurrentUpload';
import '../index.css';

class DieCurrentPanel extends Component {
  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    const { dieCurrent } = props;
    const currentType = dieCurrent && dieCurrent.currentType ? dieCurrent.currentType : CURRENT_TRAPEZOIDAL;
    this.state = {
      dieCurrent,
      show: currentType,
      plot: false,
      panelWidth: '500px',
      panelHeight: '400px',
      panelX: 0,
      panelY: 0,
      points: [],
      apply: false
    }
    this.panelRef = null;
  }

  componentDidMount() {
    this.panelRef = document.getElementById('imp-current-panel');
    this.panelListener = new ElementListener({ targetNode: this.panelRef, attributesHandle: this.attributesHandle });
    this.panelListener.observe();
    this.attributesHandle();
  }

  componentDidUpdate = (prevProps) => {
    const { dieCurrent } = this.props;
    if (prevProps.dieCurrent !== dieCurrent) {
      this.trapezoidalRef && this.trapezoidalRef.initState();
      this.tableRef && this.tableRef.initState();
      this.fileRef && this.fileRef.initState();
    }
  }

  componentWillUnmount = () => {
    this.panelListener.disconnect()
  }

  attributesHandle = () => {
    const targetNode = document.getElementById(`imp-current-panel`);
    const panelBody = targetNode.querySelector(`.panel-ant-modal-body`);
    const { width = '500px', height = '400px' } = panelBody.style;
    const { x, y } = panelBody.getBoundingClientRect();
    const { panelWidth, panelHeight, panelX, panelY } = this.state;
    if (width !== panelWidth || height !== panelHeight) {
      this.setState({
        panelWidth: width,
        panelHeight: height,
      })
    }

    if (x !== panelX || y !== panelY) {
      this.setState({
        panelX: x,
        panelY: y,
      })
    }
  }

  closeModal = () => {
    const { show } = this.state;
    if (show === CURRENT_TRAPEZOIDAL && this.trapezoidalRef) {
      this.trapezoidalRef.saveCurrent(true)
    } else if (show === CURRENT_PWL_TABLE && this.tableRef) {
      this.tableRef.saveCurrent(true)
    } else if (show === CURRENT_PWL_FILE) {
      this.fileRef.saveCurrent(true)
    }
  }

  filterEmptyCurrent = () => {
    const { dieCurrent, apply } = this.state;
    this.props.updateDieCurrent(dieCurrent, apply);
    this.props.save && this.props.save();
    this.props.closeCurrentPanel && this.props.closeCurrentPanel(null, false)
  }

  onChangeType = (e) => {
    e.stopPropagation();
    this.setState({
      show: e.target.value
    })
  }

  getCurrentContent = () => {
    const { show, dieCurrent } = this.state;
    if (show === CURRENT_TRAPEZOIDAL) {
      return <TrapezoidalValue
        {...this.props}
        dieCurrent={dieCurrent}
        onRef={this.onTrapezoidalRef}
        savePoints={this.savePoints}
        refreshPlot={this.getChildCurrent}
        updateCurrent={this.updateCurrent}
        filterEmptyCurrent={this.filterEmptyCurrent} />
    } else if (show === CURRENT_PWL_TABLE) {
      return <PWLTable
        {...this.props}
        dieCurrent={dieCurrent}
        onRef={this.onTableRef}
        savePoints={this.savePoints}
        refreshPlot={this.getChildCurrent}
        updateCurrent={this.updateCurrent}
        filterEmptyCurrent={this.filterEmptyCurrent}
      />
    } else {
      return <CurrentUpload
        {...this.props}
        onRef={this.onFileRef}
        dieCurrent={dieCurrent}
        savePoints={this.savePoints}
        updateCurrent={this.updateCurrent}
        changePrevType={this.changePrevType}
        filterEmptyCurrent={this.filterEmptyCurrent}
      />
    }
  }

  getChildCurrent = () => {
    const { show } = this.state;
    let points = []
    if (show === CURRENT_TRAPEZOIDAL && this.trapezoidalRef) {
      points = this.trapezoidalRef.getCurrentPoints();
    } else if (show === CURRENT_PWL_TABLE && this.tableRef) {
      points = this.tableRef.getCurrentPoints();
    } else if (show === CURRENT_PWL_FILE && this.fileRef) {
      points = []
    }
    this.savePoints(points);
  }

  savePoints = (points) => {
    this.setState({ points })
  }

  onTrapezoidalRef = (ref) => {
    this.trapezoidalRef = ref;
  }

  onTableRef = (ref) => {
    this.tableRef = ref;
  }

  onFileRef = (ref) => {
    this.fileRef = ref;
  }

  onPlotRef = (ref) => {
    this.plotRef = ref;
  }

  changePrevType = (type) => {
    this.setState({ prevType: type });
  }

  showPlot = (e) => {
    e.stopPropagation();
    const { plot } = this.state;
    this.setState({
      plot: !plot
    })
  }

  updateCurrent = (current, closePanel) => {
    this.setState({
      dieCurrent: current
    }, () => {
      if (closePanel) {
        this.filterEmptyCurrent()
      }
    })
  }

  tabCurrentRender = () => {
    const { show } = this.state;
    return (
      <div className='cascade-position-relative'>
        <Radio.Group
          onChange={(e) => this.onChangeType(e)}
          value={show}
          className='imp-current-radio'
        >
          <Radio value={CURRENT_TRAPEZOIDAL}>Trapezoidal</Radio>
          {/* <Radio value={CURRENT_PWL_FILE}>PWL File</Radio> */}
          <Radio value={CURRENT_PWL_TABLE}>PWL Data</Radio>
        </Radio.Group>
        <div className={'imp-current-action-group'}>
          <LineChartOutlined title='Show/Hide Plot' onClick={this.showPlot} />
        </div>
        {this.getCurrentContent()}
      </div>
    );
  }

  changeApply = () => {
    const { apply } = this.state
    this.setState({
      apply: !apply
    })
  }

  applyRender = () => {
    const { apply } = this.state;
    return <div className="imp-current-apply">
      <Checkbox
        checked={apply}
        onChange={this.changeApply}
      />
      <span>
        Apply die current options to all Ports
      </span>
    </div>
  }

  renderDialog() {
    const { title, applyVisible } = this.props;
    const { show, plot, points, panelWidth, panelHeight, panelY, panelX } = this.state;
    const content = (
      <Fragment>
        <Panel
          id='imp-current-panel'
          className='imp-current-panel'
          position='panel-center-left'
          title={title}
          zIndex={2000}
          onCancel={this.closeModal}
          width={plot ? 800 : 500}
          draggable
          minWidth={plot ? 800 : 500}
          minHeight={200}
          maxHeight={800}
          footer={applyVisible ? this.applyRender() : null}
        >
          <div className={plot ? "impedance-current-setting" : "impedance-current-setting-single"}>
            {this.tabCurrentRender()}
          </div>
          {plot && <div className="impedance-current-plot">
            <CurrentPlot
              {...this.props}
              points={points}
              onRef={this.onPlotRef}
              showType={show}
              width={panelWidth}
              height={panelHeight}
              x={panelX}
              y={panelY}
            />
          </div>}
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    return <Fragment>
      {this.renderDialog()}
    </Fragment>
  }
}

export default DieCurrentPanel;