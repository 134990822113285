import React, { Component, Fragment } from 'react';
import Table from '@/components/EditableTable';
import { CloseOutlined, PlusCircleOutlined, PlusSquareOutlined, RetweetOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import settingStore from '@/services/helper/componentsHelper/compSettingHelper';
import { versionUpdate } from '../../../../services/helper/dataProcess';
import PinMapConnection from './pinMapConnection';
import { getDefaultRepeaterPinMap } from '../../../../services/PCBHelper/compPinMapHelper';
import RepeaterModel from '../../LibraryPanel/PartLibraryPanel/repeaterModel';
import { getRepeaterText } from '../../../../services/Sierra';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import _ from 'lodash';
import './index.css';

const PinMapColumns = [{
  title: 'Part Name',
  dataIndex: 'part',
  width: '30%',
  sorter: (a, b) => a && a.part && b && b.part && a.part.localeCompare(b.part),
  textWrap: 'word-break',
  ellipsis: true,
}, {
  title: 'Components',
  dataIndex: 'components',
  width: '20%',
  textWrap: 'word-break',
  ellipsis: true,
},
{
  title: 'Position',
  dataIndex: 'position',
  width: 80,
  textWrap: 'word-break',
  ellipsis: true,
},
{
  title: 'Pin Map',
  dataIndex: 'pinMap',
  /*  width: '30%', */
  textWrap: 'word-break',
  ellipsis: true,
},
{
  title: 'Model',
  dataIndex: 'model',
  /*  width: '30%', */
  textWrap: 'word-break',
  ellipsis: true,
}];

class CompPinMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      designId: props.designId,
      dataSource: [],
      save: false,
      components: [],
      newRow: [],
      displayPartNumber: false,
      saveModel: false
    }
  }

  componentDidMount = () => {
    this.props.onRef(this);
    this.initColumns();
    this.getPinMapData();
  }

  componentDidUpdate = (prevProps) => {
    const { isUpdateSetting } = this.props;
    if (isUpdateSetting && isUpdateSetting !== prevProps.isUpdateSetting) {
      this.initColumns();
      this.getPinMapData();
      console.log("Part library update component pin map")
    }
  }

  initColumns = () => {

    PinMapColumns[0].title = () => {
      const { displayPartNumber, dataSource } = this.state;
      const filterPartNumber = (dataSource || []).map(item => item.partNumber);
      return <div>
        <span className='sierra-comp-repeater-part-title'>Part {displayPartNumber && filterPartNumber.length ? "Number" : "Name"}</span>
        {filterPartNumber.length ? <Tooltip
          title='Toggle to display part name or part number.'
          mouseLeaveDelay={0}
          mouseEnterDelay={0.3}
          overlayClassName='icon-tooltip'
        >
          <RetweetOutlined
            className="sierra-comp-repeater-part-switch-icon"
            onClick={(e) => this.changePartDisplay(e)}
          />
        </Tooltip> : null}
      </div>
    }

    PinMapColumns[0].render = (text, record) => {
      const { part } = record;
      const partText = this.state.displayPartNumber && record.partNumber ? record.partNumber : record.part;
      const obj = {
        children: <div className="component-pmic-pin-part-cell">
          <div>{partText}</div>
          {/* {part ? <Icon type="plus-square"
            className="add-icon"
            title={"Add New Position"}
            onClick={(e) => this.addNewPosition(e, { part })} /> : null} */}
          <CloseOutlined
            className="delete-icon"
            title={"Delete Part"}
            onClick={(e) => this.savePartNumber(e, { part })} />
        </div>,
        props: {},
      }
      if (record.length > 0) {
        obj.props.rowSpan = record.length;
      } else {
        obj.props.rowSpan = 0;
      }
      return obj;
    }

    PinMapColumns[0].onCell = (record) => {
      const { part } = record;
      const { pinMapObj } = this.props;
      const options = pinMapObj && pinMapObj.options ? pinMapObj.options : [];
      if (!part) {
        return {
          edit: 'select',
          options,
          record,
          text: '',
          dataIndex: 'part',
          handleSave: (record) => this.savePartNumber(null, record),
          getPopupContainer: document.getElementById('components-setting-dialog')
        }
      }
      return {
        edit: false
      }
    }

    PinMapColumns[1].render = (text, record) => {
      const { part, components: recordComps } = record;
      const { components = [] } = this.state;
      const comps = recordComps ? recordComps : components.filter(item => item.partName === part).map(item => item.name);
      const compText = comps.join(', ');
      return <div className='component-pmic-pin-library-cell' title={compText}>
        <span className='sierra-pmic-pin-library-value component-pin-map-value'>
          {compText}
        </span>
      </div>
    }

    PinMapColumns[1].onCell = (record) => {
      const { part, components: recordComps } = record;
      const { components } = this.state;

      if (!part) {
        return {
          edit: 'aurora-select',
          options: components.map(item => item.name),
          record,
          text: '',
          dataIndex: 'components',
          selectType: 'components',
          handleSave: (value) => this.savePartByComponent(value),
          getPopupContainer: document.getElementById('components-setting-dialog')
        }
      }
      const partComps = components.filter(item => item.partName === part).map(item => item.name);
      const comps = recordComps ? recordComps : [...partComps];

      return {
        edit: 'aurora-select',
        selectMode: "multiple",
        options: partComps,
        record: { ...record, components: comps || [] },
        text: comps.join(", "),
        dataIndex: 'components',
        selectType: 'components',
        onChange: (list, newRecord, setFieldsValue) => this.selectComponents(list, newRecord, setFieldsValue),
        handleSave: (newRecord) => this.selectComponents(null, newRecord),
        clearSelected: (value, newRecord, setFieldsValue) => this.clearComponents(value, newRecord, setFieldsValue),
        getPopupContainer: document.getElementById('components-setting-dialog')
      }
    }

    PinMapColumns[2].render = (text, record) => {
      return (
        <div className="component-pmic-pin-part-cell">
          <div>{text}</div>
          {record.part ? <PlusSquareOutlined
            className="add-icon"
            title={"Add New Position"}
            onClick={(e) => this.addNewPosition(e, { part: record.part })} /> : null}
          {record.part && text && <CloseOutlined
            className="delete-icon"
            title={"Delete Position"}
            onClick={(e) => this.deletePartPosition(e, { part: record.part, position: record.position })} />}
        </div>
      );
    }

    PinMapColumns[3].render = (text, record) => {
      const pinMapText = this.getPinMapText(record)
      return <div className='component-pmic-pin-library-cell' title={pinMapText}>
        <span className='sierra-pmic-pin-library-value component-pin-map-value'>
          {pinMapText}
        </span>
      </div>
    }

    PinMapColumns[3].onCell = (record) => {
      const { part, pinMap = [], position, model } = record;
      const { designId } = this.props;
      const { components } = this.state;
      return {
        edit: true,
        customInput: PinMapConnection,
        record,
        dataIndex: 'pinMap',
        part,
        designId,
        pinMap: pinMap,
        position: position,
        model,
        components: components.filter(item => item.partName === part),
        savePinMapInfo: this.savePinMapInfo
      }
    }


    PinMapColumns[4].render = (text, record) => {
      const modelText = getRepeaterText({ ...record, model: typeof (record.model) !== "object" ? {} : record.model });
      return <span title={modelText}>{modelText}</span>;
    }

    PinMapColumns[4].onCell = (record) => {
      if (!record.part) {
        return {
          edit: false
        }
      }
      const { maxHeight, maxWidth, libraryTreeInfo } = this.props;
      return {
        edit: true,
        dataIndex: "model",
        record: { ...record, partNumber: record.part, connections: record.pinMap },
        partNumber: record.part,
        position: record.position,
        maxHeight,
        maxWidth,
        libraryTreeInfo,
        customInput: RepeaterModel,
        saveModelInfo: this.saveModelInfo
      }
    }
  }

  getPinMapText = (record) => {
    let text = "";
    for (let item of record.pinMap || []) {
      if (!item.input || !item.input.length || !item.output || !item.output.length) {
        continue;
      }
      text += text ? `, (${item.input[0]}, ${item.output[0]})` : `(${item.input[0]}, ${item.output[0]})`;
    }
    return text;
  }

  changePartDisplay = (e) => {
    e && e.stopPropagation();
    this.setState({
      displayPartNumber: !this.state.displayPartNumber
    })
  }

  componentDidUpdate = (prevProps) => {
    const { designId, updateSettingStatus } = this.props;
    if (designId !== prevProps.designId || (updateSettingStatus && prevProps.updateSettingStatus !== updateSettingStatus)) {
      this.setState({
        designId
      }, () => {
        this.getPinMapData()
      })
    }
  }

  getPinMapData = async () => {
    const { designId } = this.state;
    const { classType } = this.props;
    try {
      if (designId) {
        const data = await settingStore.getCompPinMap(designId);
        await this.props.setDefaultData()
        const compsMap = auroraDBJson.getComponents(designId);
        const components = [...compsMap.values()];
        const compType = classType.toLowerCase();
        const map = data && data[compType] ? data[compType] : [];
        const compPrefixLib = await settingStore.getPrefixLib(designId);
        let save = false;

        if (compPrefixLib && compPrefixLib.Repeater && compPrefixLib.Repeater.length) {
          const filterParts = compPrefixLib.Repeater.filter(item => !map.find(it => it.part === item));
          const addParts = map.filter(item => !compPrefixLib.Repeater.includes(item.part)).map(item => item.part);

          if (filterParts.length || addParts.length) {
            save = true;
            compPrefixLib.Repeater = compPrefixLib.Repeater.filter(item => !filterParts.includes(item));
            compPrefixLib.Repeater = [...new Set([...compPrefixLib.Repeater, ...addParts])];
            await settingStore.updatePrefixLib(designId, compPrefixLib);
            this.props.updateClassification && this.props.updateClassification(compPrefixLib.Repeater, "Repeater")
          }
        }
        const findPartNumber = map.find(it => it.hasOwnProperty("partNumber"));
        const findComponents = map.find(it => it.hasOwnProperty("components"));

        if (!findPartNumber || !findComponents) {
          //set part number
          map.forEach(item => {
            if (!item.partNumber) {
              const partNumber = auroraDBJson.getPartNumberByPartName(designId, item.part);
              item.partNumber = partNumber;
              save = true;
            }
            if (!item.components) {
              const comps = components.filter(it => it.partName === item.part).map(it => it.name);
              item.components = [...comps];
              save = true;
            }
          })
        }
        this.setState({
          dataSource: map,
          components,
          compType
        }, async () => {
          this.props.changeLoading(false)
          if (save) {
            const _compType = compType ? compType : classType.toLowerCase();
            await settingStore.updateCompPinMap(designId, _compType, map, true);
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  savePinMap = async (close = false) => {
    const { designId, dataSource, save, saveModel, compType } = this.state;
    const { classType } = this.props;
    if (save || saveModel) {
      const _compType = compType ? compType : classType.toLowerCase();
      const values = [...new Set(dataSource.map(item => item.part))];
      const componentPrefixLib = await settingStore.getPrefixLib(designId);
      componentPrefixLib.Repeater = values;
      await settingStore.updatePrefixLib(designId, componentPrefixLib);
      this.props.updateClassification && this.props.updateClassification(values, "Repeater")
      if (save) {
        //update component setting version and re search
        const settingVersion = await settingStore.getVersion(designId);
        const newVersion = versionUpdate(settingVersion);
        await settingStore.updateVersion(designId, newVersion);
      }
      const saveToServer = close ? false : true;
      await settingStore.updateCompPinMap(designId, _compType, dataSource, saveToServer);
    }
    const saveType = save ? true : (saveModel ? "saveModel" : false)
    return saveType;
  }


  saveModelInfo = (record) => {
    const { dataSource } = this.state;
    const { partNumber, position, model } = record
    let _data = [...dataSource];
    const index = _data.findIndex(item => item.part === partNumber && (!position || item.position === position));
    if (index < 0) {
      return;
    }
    let changed = false;
    if (!_.isEqual((_data[index].model || {}), model)) {
      changed = true;
    }
    _data[index].model = model;
    this.setState({
      dataSource: _data,
      saveModel: changed || this.state.saveModel
    })
  }

  savePinMapInfo = ({ part, pinMap, position, model }) => {
    const { dataSource } = this.state;
    let _dataSource = [...dataSource];
    const index = dataSource.findIndex(item => item.part === part && (!position || item.position === position));
    if (index < 0) {
      return;
    }
    if (_.isEqual(_dataSource[index].pinMap, pinMap)) {
      return;
    }
    _dataSource[index].pinMap = pinMap;
    _dataSource[index].model = model;
    this.setState({
      dataSource: _dataSource,
      save: true
    })
  }

  savePartNumber = (e, record) => {
    e && e.stopPropagation()
    const { part } = record;

    if (!part) {
      this.setState({
        newRow: []
      })
      return;
    }

    const { dataSource, components, designId } = this.state;
    let data = dataSource;
    const { classType } = this.props;
    const find = data.find(item => item.part === part);
    if (find) {
      //DELETE
      data = data.filter(item => item.part !== part);
    } else {
      //add new
      const component = components.find(item => item.partName === part) || {};
      const partNumber = auroraDBJson.getPartNumberByPartName(designId, part);
      const partComps = components.filter(item => item.partName === part).map(item => item.name);
      const pinList = component.pins ? [...component.pins.values()] : [];
      data = [...data, { part, partNumber, pinMap: getDefaultRepeaterPinMap(pinList, { numberKey: "pin", nameKey: "pinName" }), model: {}, components: [...partComps] }];
    }
    const values = data.map(item => item.part);
    const error = this.props.changeSelect([...values], classType);
    if (!error) {
      this.setState({
        dataSource: data,
        save: true,
        newRow: []
      })
    }
  }

  deletePartPosition = (e, { part, position }) => {
    e && e.stopPropagation()

    if (!part) {
      this.setState({
        newRow: []
      })
      return;
    }

    const { dataSource } = this.state;
    let data = dataSource;
    const { classType } = this.props;
    const find = data.find(item => item.part === part && item.position === position);
    if (find) {
      //DELETE
      data = data.filter(item => item.part !== part || (item.part === part && item.position !== position));
    }
    const values = data.map(item => item.part);
    const error = this.props.changeSelect([...values], classType);
    if (!error) {
      this.setState({
        dataSource: data,
        save: true,
        newRow: []
      })
    }
  }

  addNewPosition = (e, { part }) => {
    e && e.stopPropagation();
    const { components, dataSource } = this.state;
    let data = [...dataSource];
    const partList = data.filter(item => item.part === part);
    let position;
    if (partList.length === 1) {
      const index = data.findIndex(item => item.part === part)
      data[index].position = "1";
      position = "2"
    } else {
      position = 1;
      for (let item of data) {
        if (item.part !== part) {
          continue;
        }
        item.position = `${position}`;
        position += 1;
      }
    }
    const component = components.find(item => item.partName === part) || {};
    const pinList = component.pins ? [...component.pins.values()] : [];
    const existPart = data.find(item => item.part === part) || {};
    data.push({
      part,
      position: position.toString(),
      pinMap: component ? getDefaultRepeaterPinMap(pinList || [], { numberKey: "pin", nameKey: "pinName" }) : [],
      model: {
        files: existPart.model && existPart.model.files && existPart.model.files.length ? JSON.parse(JSON.stringify(existPart.model.files)) : [],
        pairs: []
      },
      components: components.filter(item => item.partName === part).map(item => item.name)
    })
    this.setState({
      dataSource: data
    })
  }

  savePartByComponent = (comp) => {
    const { components, designId } = this.state;
    const { classType } = this.props;
    const component = components.find(item => item.name === comp.components);
    if (component) {
      const error = this.props.checkPartNumber(component.partName);
      if (error) {
        message.error(error);
      } else {
        let data = this.state.dataSource;
        const partNumber = auroraDBJson.getPartNumberByPartName(designId, component.partName);
        const pinList = component.pins ? [...component.pins.values()] : [];
        data = [...data, {
          part: component.partName,
          partNumber,
          pinMap: getDefaultRepeaterPinMap(pinList, { numberKey: "pin", nameKey: "pinName" }),
          model: {},
          components: components.filter(item => item.partName === component.partName).map(item => item.name)
        }];
        const values = data.map(item => item.part);
        this.props.changeSelect(values, classType);
        this.setState({
          dataSource: data,
          save: true,
          newRow: []
        })
      }
    }
  }

  selectComponents = async (list, record, setFieldsValue) => {
    const { part, position, components } = record;
    const { dataSource } = this.state;
    const _dataSource = [...dataSource];

    const index = _dataSource.findIndex(item => item.part === part && (!item.position || item.position === position))
    if (index > -1) {
      let newComps = components;
      if (list && list.length === 1 && (_dataSource[index].components || []).includes(list[0])) {
        _dataSource[index].components = (_dataSource[index].components || []).filter(comp => comp !== list[0]);
        newComps = _dataSource[index].components;
      } else {
        newComps = list ? [..._dataSource[index].components, ...list] : [...components];
        _dataSource[index].components = newComps;
      }
      if (setFieldsValue) {
        setFieldsValue({ components: newComps })
      }
      this.setState({
        dataSource: _dataSource,
        save: true
      })
    }
  }

  clearComponents = (value, record, setFieldsValue) => {
    const { part, position } = record;
    const { dataSource } = this.state;
    const _dataSource = [...dataSource];
    const index = _dataSource.findIndex(item => item.part === part && (!item.position || item.position === position))
    if (index < 0) {
      return;
    }
    let components = record.components || [];
    components = components.filter(comp => comp !== value);
    _dataSource[index].components = components;
    if (setFieldsValue) {
      setFieldsValue({ components })
    }
    this.setState({
      dataSource: _dataSource,
      save: true
    })
  }


  setNewRow = () => {
    const { newRow } = this.state;
    if (!newRow.length) {
      this.setState({
        newRow: [{ part: "", position: "", length: 1 }]
      })
    }
  }

  getTableDataSource = (dataSource) => {
    let data = new Map(), list = [];
    for (let item of dataSource) {
      const existData = data.get(item.part);
      if (!existData) {
        data.set(item.part, {
          part: item.part,
          partNumber: item.partNumber,
          pinMap: [{ pinMap: item.pinMap, position: item.position, model: item.model, components: item.components }]
        })
      } else {
        existData.pinMap.push({ pinMap: item.pinMap, position: item.position, model: item.model, components: item.components })
        data.set(item.part, existData);
      }
    }
    for (let item of Array.from(data.values())) {
      list.push(...item.pinMap.map((it, index) => {
        return {
          part: item.part,
          pinMap: it.pinMap,
          position: it.position,
          partNumber: item.partNumber,
          model: it.model,
          components: it.components,
          length: index === 0 ? item.pinMap.length : 0
        }
      }))
    }
    return list
  }

  pinMapRender = () => {
    const { dataSource, newRow } = this.state;
    const { classType } = this.props;
    const _dataSource = this.getTableDataSource(dataSource);
    return (
      <div className='sierra-component-pcb-repeater-table' key={classType}>
        <span className="font-bold component-pmic-pin-title-color">{classType}</span>
        <PlusCircleOutlined
          className='component-pin-map-add-row-icon component-pmic-pin-title-color'
          onClick={() => this.setNewRow()} />
        <Table
          columns={PinMapColumns}
          dataSource={[..._dataSource, ...newRow]}
          rowKey={(record) => `${record.part}_${record.position ? `${record.position}` : ""}`}
          scroll={_dataSource.length > 11 ? { y: 780 } : {}}
          size="small"
        />
      </div>
    );
  }

  render() {
    return <Fragment>
      {this.pinMapRender()}
    </Fragment>
  }
}

export default CompPinMap;