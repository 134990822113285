import {
  VERIFY_NEVER,
  VERIFY_RUNNING,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  VERIFY_CANCEL,
  WAITING,
  ERROR
} from '@/constants/verificationStatus';
import { getTextWidth } from '../../helper/getTextWidth';
const MULTI = 'multi', ONLY = 'only', PERCENTAGE = 'percentage', TOLERANCE = 'tolerance';


function getTraceWidthTableData(traceConfigs) {
  // [{ net, variable, typ, min, max, mergeNetLength, mergeVariableLength }]
  let tableData = []
  for (const config of traceConfigs) {
    const widths = config.width;
    let mergeNetLength = widths.length || 1, mergeVariableLength = 1;
    for (const width of widths) {
      const typ = width.values.find(item => item.type === 'typ').value;
      const min = width.values.find(item => item.type === 'min').value;
      const max = width.values.find(item => item.type === 'max').value;
      mergeVariableLength = typ.length;
      typ.forEach((value, index) => {
        tableData.push({
          net: config.net,
          typ: value,
          min: min[index],
          max: max[index]
        })
      })
      if (mergeVariableLength !== 1) { mergeNetLength = mergeVariableLength };
      tableData[tableData.length - mergeVariableLength].mergeVariableLength = mergeVariableLength;
    }
    tableData[tableData.length - mergeNetLength].mergeNetLength = mergeNetLength;
  }
  return tableData;
}

class TraceConfigItem {
  constructor({ net, unit, width }) {
    this.mode = 'line';
    this.net = net;
    this.unit = unit;
    this.width = width;
  }
}

function getValueByChangeWay({ item, value, type, changeWay }) {
  switch (changeWay) {
    case PERCENTAGE:
      return parseFloat(((item * value) / 100).toFixed(3));
    case TOLERANCE:
      return type === 'max' ? parseFloat((Number(item) + Number(value)).toFixed(3)) : parseFloat((Number(item) - Number(value)).toFixed(3));
    default:
      return item;
  }
}

function generateTraceConfigs({ netMap, unit, minPercentage = 90, maxPercentage = 110, type = ONLY, changeWay = PERCENTAGE }) {
  let _unit = unit;
  if (unit === 'mils') {
    _unit = 'mil';
  }
  let variableIndex = 0;
  function createWidth(typ, type) {
    if (type === ONLY) {
      const min = typ.map(item => getValueByChangeWay({ item, value: minPercentage, type: 'min', changeWay }));
      const max = typ.map(item => getValueByChangeWay({ item, value: maxPercentage, type: 'max', changeWay }));
      const values = [
        { type: 'typ', value: typ },
        { type: 'min', value: min },
        { type: 'max', value: max },
      ]
      variableIndex++;
      return [{
        variable: `$width_${variableIndex}`,
        values
      }]
    }

    return typ.map((item) => {
      variableIndex++;
      return {
        variable: `$width_${variableIndex}`,
        values: [
          { type: 'typ', value: [item] },
          { type: 'min', value: [getValueByChangeWay({ item, value: minPercentage, type: 'min', changeWay })] },
          { type: 'max', value: [getValueByChangeWay({ item, value: maxPercentage, type: 'max', changeWay })] },
        ]
      }
    })
  }
  const config = []
  netMap.forEach((value, key) => {
    const width = createWidth([...new Set(value)], type);
    config.push(new TraceConfigItem({ net: key, unit: _unit, width }))
  })
  return config;
}

function getSweepColumns(sweepColumns, variables, type, notExistNets) {
  const width = 1200 / variables.length;
  const _sweepColumns = [...sweepColumns];
  let children = [];
  if (type === MULTI) {
    const keyMap = new Map();
    for (const v of variables) {
      const item = keyMap.get(v.net);
      if (item) {
        item.push({ name: v.name, value: v.value })
        keyMap.set(v.net, item)
      } else {
        keyMap.set(v.net, [{ name: v.name, value: v.value }]);
      }
    }

    keyMap.forEach((variable, net) => {
      let variableChild = [], sectionIndex = 1;
      for (const v of variable) {
        const title = `Section${sectionIndex} [${v.value.join(', ')}]`;
        const textWidth = getTextWidth(title, 14, undefined, 600) + 20;
        variableChild.push({
          title,
          dataIndex: v.name,
          typValue: v.value,
          width: textWidth > width ? textWidth : width
        })
        sectionIndex++;
      }
      children.push({
        title: net,
        key: net,
        children: variableChild
      })
    })
  } else {
    _sweepColumns.forEach((item) => {
      if (item.key === 'width') {
        children = variables.map((it) => {
          const title = `${it.net} [${it.value.join(', ')}]`;
          const textWidth = getTextWidth(title, 14, undefined, 600) + 20;
          return {
            title,
            dataIndex: it.name,
            key: it.net,
            typValue: it.value,
            width: textWidth > width ? textWidth : width
          }
        })
      }
    })
  }
  if (children && children.length > 0) {
    _sweepColumns[1].children = children;
  }
  return { _sweepColumns };
}

// {id，name，[variable]：variableValue，status}
function getExperimentTableData(variablesTables) {
  const tableData = []
  for (const table of variablesTables) {
    let widths = {};
    for (const item of table.params) {
      if (!item) {
        continue;
      }
      widths[item.name] = item.type;
      widths[`${item.name}_value`] = item.value;
    }
    tableData.push({ id: table.id, name: table.name, status: table.status, ...widths })
  }
  return tableData;
}

function getVariableValue(values, type = 'typ', typWidth, inputWidth, oldWidth) {
  if (type === "userDefined") {
    let isUpdate = false;
    // find typ width
    const currTypWidth = (values.find(item => item.type === "typ") || {}).value || [];
    const variableValue = currTypWidth.map((item, index) => {
      // If the current width is user-defined, return the user-defined width
      const userDefinedWidthIndex = typWidth.findIndex(it => it === item);
      if (userDefinedWidthIndex > -1) {
        isUpdate = true;
        return inputWidth[userDefinedWidthIndex];
      }
      // If the current width is not user-defined, return the original width
      return oldWidth[index];
    })

    return isUpdate ? variableValue : isUpdate;
  }
  return (values.find(item => item.type === type) || {}).value || [];
}

/**
 * select min/max/typ generate param element
 * @param {Array} typWidth type === userDefined, typWidth is the original width
 * @param {Array} inputWidth type === userDefined, inputWidth is the user-defined width
 * @param {Array} oldWidth variable old width
 * @param {Object} oldValue variable old value
 * @returns 
 */
function getVariableByType({ traceConfigs, variable, type, typWidth = [], inputWidth = [], oldWidth = [], oldValue }) {
  for (const config of traceConfigs) {
    for (const width of config.width) {
      if (width.variable === variable) {
        const widthValue = getVariableValue(width.values, type, typWidth, inputWidth, oldWidth);
        if (typeof widthValue === 'boolean' && !widthValue) {
          return oldValue;
        }
        return {
          name: width.variable,
          type,
          value: widthValue
        }
      }
    }
  }
}

function getExperimentStatus(status) {
  switch (status) {
    case VERIFY_RUNNING:
      return { title: 'Running', color: '#31aaf3' }
    case VERIFY_SUCCESS:
      return { title: 'Success', color: '#4dbd16' }
    case VERIFY_FAILED:
      return { title: 'Failed', color: '#b32222' }
    case VERIFY_CANCEL:
      return { title: 'Cancel', color: '#9d7ace' }
    case WAITING:
      return { title: 'Waiting', color: '#ff5500' }
    case ERROR:
      return { title: 'Error', color: '#dc143c', tooltip: 'Please check the error message in the corresponding Monitor' }
    case VERIFY_NEVER:
    default:
      return { title: '', color: '#ffffff' };
  }
}

function afterChangeTraceSetting({ changeType = [], traceConfigs, experimentData, sweepId, min, max, type, changeWay }) {
  const { variables, variablesTables } = experimentData;
  let _traceConfigs = [...traceConfigs];
  // 1. change type
  if (changeType.includes('type')) {
    // update traceConfigs
    const netMap = new Map(), unit = traceConfigs && traceConfigs[0] ? traceConfigs[0].unit : '';
    for (const config of traceConfigs) {
      const value = [];
      for (const w of config.width) {
        const typValue = w.values.find(item => item.type === 'typ');
        value.push(...typValue.value);
      }
      netMap.set(config.net, value);
    }
    _traceConfigs = generateTraceConfigs({ netMap, unit, sweepId, minPercentage: min, maxPercentage: max, type, changeWay });
    // update variables
    const nets = new Set();
    const nameToNet = new Map();
    // Get the corresponding relationship between old name and net, the selected net
    for (const variable of variables) {
      nets.add(variable.net);
      nameToNet.set(variable.name, variable.net);
    }

    // Remove all mode = 'line' selections
    const _variables = variables.filter(item => item.mode !== 'line');
    // Get the variable modified by the selected net
    for (const config of _traceConfigs) {
      // If net is selected
      if (nets.has(config.net)) {
        // Generate new variable elements based on width elements
        const variableList = config.width.map((item) => {
          const typ = item.values.find(it => it.type = 'typ').value;
          return {
            "mode": config.mode,
            "unit": config.unit,
            "name": item.variable,
            "net": config.net,
            "type": "width",
            "value": typ
          }
        })
        _variables.push(...variableList);
      }
    }

    // update variablessTables
    variablesTables.forEach((table) => {
      // Get the options corresponding to net. If it is multi, switch to only based on the selection of the first variable.
      // netToValue : net -> value (type === userDefined)
      const netToType = new Map(), netToValue = new Map();
      for (const param of table.params) {
        const net = nameToNet.get(param.name)
        if (!netToType.has(net)) {
          netToType.set(net, param.type);
        }
        // Integration of value of userDefined
        if (param.type === 'userDefined' || netToValue.has(net)) {
          if (!netToValue.has(net)) {
            netToValue.set(net, param.value)
          } else {
            const mapValue = netToValue.get(net);
            mapValue.push(...param.value);
            netToValue.set(net, mapValue)
          }
        }
      }

      const params = [];
      // According to net and type, get the elements in the variable and get new params
      netToType.forEach((value, key) => {
        // Get the variable corresponding to net
        const vs = _variables.filter(item => item.net === key);
        const percentage = value === 'min' ? min : max;
        // Determine whether it is a userDefined type to get the userDefined
        let userDefinedValue = null;
        if (value === 'userDefined') {
          userDefinedValue = netToValue.get(key);
        }
        // Generate new variable elements based on width elements
        const pl = vs.map((item, index) => {
          return {
            name: item.name,
            type: value,
            value: userDefinedValue ? (type === ONLY ? userDefinedValue : [userDefinedValue[index]]) : item.value.map((it) => getValueByChangeWay({ item: it, value: percentage, type: value, changeWay }))
          }
        })
        if (pl && pl.length > 0) {
          params.push(...pl);
        }
      })
      table.params = params;
    })
    return { _variables, _variablesTables: variablesTables, _traceConfigs };
  } else {
    // update traceConfigs
    _traceConfigs.forEach((config) => {
      config.width.forEach((w) => {
        const typValue = w.values.find(item => item.type === 'typ').value;
        const minValue = typValue.map(item => getValueByChangeWay({ item, value: min, type: 'min', changeWay }));
        const maxValue = typValue.map(item => getValueByChangeWay({ item, value: max, type: 'max', changeWay }));
        w.values = [
          { type: 'typ', value: typValue },
          { type: 'min', value: minValue },
          { type: 'max', value: maxValue },
        ]
      })
    })
    // update variablessTables
    variablesTables.forEach((table) => {
      table.params = table.params.map((item) => {
        if (changeType.includes(item.type)) {
          const percentage = item.type === 'min' ? min : max;
          const value = variables.find(it => it.name === item.name).value.map((v) => getValueByChangeWay({ item: v, value: percentage, type: item.type, changeWay }));
          return { ...item, value }
        }
        return item;
      });
    })
    return { _variables: variables, _variablesTables: variablesTables, _traceConfigs };
  }
}

export {
  getTraceWidthTableData,
  generateTraceConfigs,
  getSweepColumns,
  getExperimentTableData,
  getVariableByType,
  getExperimentStatus,
  afterChangeTraceSetting,
  MULTI,
  ONLY,
  TOLERANCE,
  PERCENTAGE
}