import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ClipDesignPanel from '@/components/ClipDesign';
import preview, { cleanPreview } from '@/services/helper/cutDesign/preview';
import { updatePageLayout, updateViewList } from '../../store/project/action';
import { LEFT_RIGHT_lAYOUT } from '@/constants/layoutConstants';
import { saveDesignClip, forcePreviewPanelShow, savePreviewStatus, updateHybridStatus } from '../../store/channel/action';
import { selectChange } from '../../../LayoutExplorer/store/Andes_v2/actionCreators';
import { openPageInfo, updateSelectKeys } from '../../store/project/action';
import { getSelectNets, generateBoundaryList } from '@/services/Andes_v2/channel/cutDesignHelper';
import { equal } from '@/services/utility/ArrayHelper';
import { addHybridRegionsBox } from '@/services/helper/cutDesign/hybrid/previewRegions';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { getViewListBySelectedKey } from '../../../../services/helper/filterHelper';

class ClipDesign extends PureComponent {

  constructor() {
    super()
    this.state = {
      clipSizeUnit: null,
      clipSize: 3
    }
  }

  componentDidMount = async () => {
    this.getDefaultClip();
  }

  getDefaultClip = () => {
    let { clipSize } = this.props.extraction;
    let clipSizeUnit = 'ratio';

    if (clipSize && clipSize.toString().includes('mm')) {
      clipSizeUnit = 'mm';
      clipSize = parseFloat(clipSize).toString();
    }

    if (!clipSize) {
      clipSizeUnit = "mm";
      clipSize = "3";
    }
    this.setState({
      clipSizeUnit,
      clipSize
    });
    if (this.props.forcePreviewShow) {
      this.forcePreview = true;
    }
  }

  componentWillUnmount = () => {
    this.setState = () => false;
  }

  componentDidUpdate = (prevProps) => {
    const { selectedSignals, selectedDesignIDs, designId, hybrid, hybridRegionsInfo, suggestHFSSRegions } = this.props;
    //TODO update boundaries after nets updated
    const selectedSignalUpdate = selectedSignals.length !== prevProps.selectedSignals.length || selectedSignals.some(d => !prevProps.selectedSignals.includes(d));
    if (selectedSignalUpdate ||
      (!equal(selectedDesignIDs, prevProps.selectedDesignIDs) && selectedDesignIDs.includes(designId))
      || suggestHFSSRegions !== prevProps.suggestHFSSRegions
    ) {
      if (this.forcePreview) {
        const clipSize = this._clipSize !== undefined ? this._clipSize : this.state.clipSize;
        if (!selectedSignals.length) {
          cleanPreview(designId);
          hybridRegionsInfo.clearHybridLineCache();
          this.props.updateHybridRegions({ hybridRegions: [], hybridLines: [] })
        } else {
          this.showPreview(true, { clipSize, showHybrid: hybrid, selectedSignalUpdate });
        }
      }
    }

    const { clipSizeUnit } = this.state;
    if (hybrid && hybrid !== prevProps.hybrid && clipSizeUnit === "ratio") {
      this.setState({ clipSize: "3", clipSizeUnit: "mm" });
    }
  }

  showPreview = (show, params = {}) => {
    const { designId, hybridRegionsInfo } = this.props;
    const { clipSize, showHybrid, selectedSignalUpdate } = params;
    if (!show) {
      cleanPreview(designId);
      hybridRegionsInfo.clearHybridLineCache();
      return;
    }
    const { layout, selectedDesignIDs, selectedKeys, signals, selectedSignals, port_setups, ports_generate_setup_list, referenceNets } = this.props;
    hybridRegionsInfo.updateHybridCanvasRegion(true);
    this.props._forcePreviewPanelShow(true);
    this.forcePreview = show;

    const stateClipSize = this.state.clipSize;

    if (this._clipSize === undefined) {
      this._clipSize = stateClipSize;
    }
    const clipSizeUpdate = this._clipSize !== clipSize;
    const clipSizeUpdateStatus = Number(this._clipSize) > Number(clipSize) ? "reduce" : "increase";

    this._clipSize = clipSize;
    if (layout === 3) {
      // TODO
      this.props._updateHybridStatus(false);
      this.props.updatePageLayout(LEFT_RIGHT_lAYOUT);
      return;
    } else if (!selectedDesignIDs.includes(designId)) {
      let keys = [...selectedKeys];
      keys = keys.filter(d => !d.includes('PCB-'));
      keys.push(`PCB-${designId}`);
      this.props._updateSelectKeys(keys);
      this.props._updateViewList(getViewListBySelectedKey(keys));
      this.props.openPageInfo({ designID: designId })
      this.props._updateHybridStatus(true);
    }
    this.selectNets();
    const info = generateBoundaryList({ clipSize, signals, selectedSignals, designId, port_setups, ports_generate_setup_list, referenceNets });
    if (!info) {
      this.props._updatePreviewButtonStatus()
      cleanPreview(designId);
      return;
    }
    this.boundaries = info.data;
    if (showHybrid) {
      addHybridRegionsBox(designId);
      hybridRegionsInfo.updateBoundariesToHybrid(info);
      this.props.showHybridRegions({ clipSizeUpdate, clipSizeUpdateStatus, selectedSignalUpdate, clipData: info.data });
    } else {
      preview(info.data, designId);
    }
    this.props._updatePreviewButtonStatus(false, true)
  }

  selectNets = () => {
    const { signals, selectedSignals, designId } = this.props;
    this.props._selectChange({ nets: getSelectNets(signals, selectedSignals) }, designId);
  }

  saveExtractionConfig = (clipOptions, saveToServer) => {
    this.props.saveClipOptions(clipOptions, saveToServer);
  }

  closePanel = () => {
    const { designId } = this.props;
    cleanPreview(designId);
    this.props._forcePreviewPanelShow(false);
  }

  render() {
    const { extraction, selectedSignals, ...restProps } = this.props;
    const { clipSizeUnit, clipSize } = this.state;
    return (
      <ClipDesignPanel
        {...restProps}
        clipping={extraction.clipping}
        clipSizeUnit={clipSizeUnit}
        clipSize={clipSize}
        disabledPreview={!selectedSignals.length}
        useDesignClip={extraction.useDesignClip === undefined ? false : extraction.useDesignClip}
        showPreview={this.showPreview}
        saveOptions={this.saveExtractionConfig}
        closePanel={this.closePanel}
        savePreviewStatus={this.props._savePreviewStatus}
      />)
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: {
    channel: { channelId, forcePreviewShow, channelInfo: { content = {}, designId } },
    project: { layout, selectedKeys }
  } } = state;

  let extraction = {};
  if (content && content.extraction) {
    extraction = content.extraction;
  }
  const {
    signals = [],
    selectedSignals = [],
    port_setups,
    ports_generate_setup_list,
    referenceNets
  } = content;
  return {
    channelId,
    extraction,
    designId,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    signals,
    selectedSignals,
    forcePreviewShow,
    layout,
    selectedKeys,
    port_setups,
    ports_generate_setup_list,
    referenceNets
  }
};

const mapDispatch = (dispatch) => ({
  updatePageLayout(layout) {
    dispatch(updatePageLayout(layout))
  },
  _saveDesignClip(obj) {
    dispatch(saveDesignClip(obj))
  },
  _forcePreviewPanelShow(show) {
    dispatch(forcePreviewPanelShow(show))
  },
  _selectChange(param = {}, designID) {
    dispatch(selectChange(param, designID))
  },
  openPageInfo({ designID }) {
    dispatch(openPageInfo({ designID }))
  },
  _updateSelectKeys(keys) {
    dispatch(updateSelectKeys(keys))
  },
  _updateViewList(viewList) {
    dispatch(updateViewList(viewList))
  },
  _savePreviewStatus(status) {
    dispatch(savePreviewStatus(status))
  },
  _updateHybridStatus(status) {
    dispatch(updateHybridStatus(status))
  }
})

export default connect(mapState, mapDispatch)(ClipDesign);