import { takeEvery, call, take, put, select, delay, cancel, fork } from 'redux-saga/effects';
import {
  CREATE_PRE_LAYOUT,
  GET_PRE_LAYOUT_CONTENT,
  UPDATE_PRE_LAYOUT_INFO,
  SAVE_PRE_LAYOUT_TO_SERVER,
  SELECT_SECTION_TEMPLATE,
  EDIT_INSERTION_LOSS,
  CHANGE_PRE_LAYOUT_CONFIG,
  EDIT_GROUP_NAME,
  EDIT_SIGNAL_NAME,
  EDIT_NET_NAME,
  CHANGE_LENGTH_UNIT,
  EDIT_SIGNAL_SPACING,
  SAVE_PRE_LAYOUT_MODELS,
  SAVA_PRELAYOUT_PCB_MODEL,
  PRELAYOUT_RENAME,
  EDIT_PIN_OR_NET_NAME
} from './actionTypes';
import {
  updatePreLayoutContent,
  updatePreLayoutInfo,
  updateSignalsColumnsStatus,
  firstOpenPreLayoutSettingUpdate
} from './action';
import {
  updateEndToEndChannelInfo,
  updateConnectionModelSetup
} from '../endToEndChannel/action'
import {
  getDefaultPreLayoutInfo,
  updatePreLayout,
  getPreLayoutInfoById,
  PRE_LAYOUT_VERSION,
  PRE_LAYOUT_DESIGN_VERSION,
  /*   getPreLayoutContentVersion */
  updateAllSignalTemplate,
  updateSignalTemplate,
  getPreLayoutAllNets,
  updateSignalSectionUnit,
  getDefaultPreLayoutInfoByGroups
} from '@/services/Andes_v2/preLayout';
import { PROJECTS_INDEX } from '@/services/Andes_v2';
import designConstructor from '@/services/helper/designConstructor';
import { PRE_LAYOUT } from '@/constants/designVendor';
import { PCB, PCBS, PCB_PRE_LAYOUT, END_TO_END_CHANNEL, PACKAGE, PACKAGES } from '@/constants/treeConstants';
import { openPage, openProject, updateExpand, updateSelectKeys, updateViewList } from '../project/action';
import { getDefaultName } from '@/services/helper/setDefaultName';
import { numberCheck } from '@/services/helper/dataProcess';
import { ETHERNET, CPHY, GENERIC, HDMI, PCIE } from '../../../../services/PCBHelper/constants';
import { message } from 'antd';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { expandPCB } from '../project/projectSaga';
import { getViewListBySelectedKey } from '../../../../services/helper/filterHelper';
import projectDesigns from '@/services/helper/projectDesigns';
import { renameDesignPromise } from '@/services/api/Design/design';
import { handleEndToEndChannelInfo } from '@/services/Andes_v2/endToEndChannel/connectionHelper'
import { saveEndToEndChannelContentToServer } from '../endToEndChannel/endToEndChannelSaga';
import { netToPinType } from '../../../../services/helper/connectorHelper';
import { PACKAGE as DESIGN_PACKAGE, LAYOUT } from "../../../../constants/designType"

function* _createNewPreLayout(action) {
  const { AndesV2Reducer: { project } } = yield select();
  let { treeItems, openProjectId, selectedKeys, expandedKeys } = project;
  const { pcbType } = action;
  const designType = pcbType === PACKAGES ? DESIGN_PACKAGE : LAYOUT;
  // treeItems[PROJECTS_INDEX] - 'projects' ,PROJECTS_INDEX = 1;
  const projects = treeItems[PROJECTS_INDEX].children;
  const projectIndex = projects.findIndex(item => item.id === openProjectId);
  if (projectIndex < 0) {
    return;
  }

  const designs = designConstructor.getDesignValues();
  const projectPreLayoutList = designs.filter(item =>
    item.projectId === openProjectId && item.vendor === PRE_LAYOUT
    && (!item.designType || item.designType === designType));
  const name = getDefaultName({ nameList: projectPreLayoutList, defaultKey: "PreLayout" });
  const preLayoutInfo = getDefaultPreLayoutInfo({ designType });

  const res = yield call(updatePreLayout, {
    id: "",
    name,
    projectId: openProjectId,
    designVersion: PRE_LAYOUT_DESIGN_VERSION,
    content: { ...preLayoutInfo },
    version: PRE_LAYOUT_VERSION,
    type: pcbType === PACKAGES ? DESIGN_PACKAGE : null
  });
  //re open project -> update design list
  yield put(openProject(openProjectId));

  //if PCB not expand, expand PCB
  if (pcbType === PACKAGES && !expandedKeys.includes(`${PACKAGES}-${openProjectId}`)) {
    const expandKeys = [...expandedKeys, `${PACKAGES}-${openProjectId}`];
    yield put(updateExpand(expandKeys));
  } else if (pcbType !== PACKAGES && !expandedKeys.includes(`${PCBS}-${openProjectId}`)) {
    const expandKeys = [...expandedKeys, `${PCBS}-${openProjectId}`];
    yield put(updateExpand(expandKeys));
  }

  if (res) {
    const designID = res.id;
    designConstructor.addDesign(designID, res);
    projectDesigns.addDesign(openProjectId, res)
    yield put(updatePreLayoutContent({ preLayoutInfo, preLayoutId: designID }));
    yield put(firstOpenPreLayoutSettingUpdate(true, designID));
    //open current pre layout
    let selectKeys = selectedKeys.filter(item => !item.match(PCB) && !item.match(PACKAGE) && !item.match(END_TO_END_CHANNEL));
    const _selectedKeys = [...selectKeys, `${PCB_PRE_LAYOUT}-${designID}`];
    yield put(updateSelectKeys(_selectedKeys));
    yield put(updateViewList(getViewListBySelectedKey(_selectedKeys)));

    yield put(openPage({ pageType: PCB_PRE_LAYOUT, id: designID }));
    yield call(expandPCB, { designId: designID });
  }
}

let autoSaveTask = null;
function* _getContent(action) {
  const { id } = action;
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
  }
  yield call(savePreLayoutContentToServer);
  // get info info => {id ,subId, name, content, version, designVersion }
  const res = yield call(getPreLayoutInfoById, id);
  let preLayoutInfo = res.content ? res.content : {};
  yield put(updatePreLayoutContent({ preLayoutInfo, preLayoutId: id }));
  autoSaveTask = yield fork(autoSave);
}

function* _savePreLayout(action) {
  const { notSave } = action;
  if (notSave) {
    return;
  }
  yield put({ type: SAVE_PRE_LAYOUT_TO_SERVER });
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([SAVE_PRE_LAYOUT_TO_SERVER]);
    let delayTime = 3000;
    if (newAction.type === SAVE_PRE_LAYOUT_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, savePreLayoutContentToServer, newAction, delayTime);
    }
  }
}

function* debounce(callback, action, time = 0) {
  yield delay(time);
  yield call(callback, action);
}

export function* savePreLayoutContentToServer() {
  const { AndesV2Reducer: { preLayout } } = yield select();
  let { info } = preLayout;

  // TODO - improve auto save logic
  yield* Object.keys(info).map(function* (preLayoutId) {
    const design = designConstructor.getDesign(preLayoutId);
    if (design && info[preLayoutId] && info[preLayoutId].preLayoutInfo) {
      let content = JSON.parse(JSON.stringify(info[preLayoutId].preLayoutInfo));
      const _info = {
        id: preLayoutId,
        name: design.name,
        designVersion: design.designVersion,
        content
      }
      yield call(updatePreLayout, _info);
    }
  })
}

function* _selectTemplate(action) {
  const { data: { record, dataIndex, model, _value, change: applyAll }, designID } = action;
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo: { signal_groups, selectedGroups, components } } = preLayout.info[designID];
  const { libType, type, libId, template } = model;
  //type -> trace / via / capacitor / resistor / inductor
  //libType -> microstrip / stripline / via
  //libId -> library id
  //template -> library name
  let _signal_groups = [...signal_groups];
  let _components = [...components];
  const keys = dataIndex.split("_");
  //section array index
  const sectionIndex = keys[1];
  let update = false;

  if (sectionIndex < 0) {
    return;
  }

  if (applyAll) {
    const updateInfo = updateAllSignalTemplate({
      _signal_groups,
      libType,
      type,
      libId,
      template,
      selectedGroups,
      update,
      sectionIndex,
      value: _value,
      _components
    });
    _signal_groups = updateInfo._signal_groups;
    update = updateInfo.update;
    _components = updateInfo._components;
  } else {
    const updateInfo = updateSignalTemplate({
      _signal_groups,
      libType,
      type,
      libId,
      template,
      value: _value,
      sectionIndex,
      record,
      _components
    });
    _signal_groups = updateInfo._signal_groups;
    update = updateInfo.update;
    _components = updateInfo._components;
  }

  if (update) {
    yield put(updatePreLayoutInfo({
      info: { signal_groups: _signal_groups, components: _components },
      designID
    }));
  }
}

function* _updateInsertion(action) {
  const { record, dataIndex, applyInsertionLossAll, designID } = action;
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo: { signal_groups, selectedGroups } } = preLayout.info[designID];
  let _signal_groups = [...signal_groups];

  //number check
  if (record[dataIndex] && numberCheck(record[dataIndex])) {
    return;
  }

  if (!applyInsertionLossAll) {
    //find group
    const groupIndex = _signal_groups.findIndex(item => item.name === record.group);
    if (groupIndex < 0) {
      return;
    }
    //find signal
    const signalIndex = _signal_groups[groupIndex].signals.findIndex(item => item.name === record.name);
    if (signalIndex < 0) {
      return;
    }
    _signal_groups[groupIndex].signals[signalIndex].insertionLoss = `${record[dataIndex]}db`;
  } else {
    for (let groupItem of _signal_groups) {
      if (!selectedGroups.includes(groupItem.name)) {
        continue;
      }
      groupItem.signals.forEach(item => {
        item.insertionLoss = `${record[dataIndex]}db`;
      })
    }
  }
  yield put(updatePreLayoutInfo(
    {
      info: { signal_groups: _signal_groups },
      designID
    }
  ));
}

function* _updatePreLayoutConfig(action) {
  const { info, designID } = action;
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo } = preLayout.info[designID];
  let _preLayoutInfo = { ...preLayoutInfo };
  const { width, type, diffPairsNumber, selectedGroups, netPrefix, prelayout, lanesNumber } = _preLayoutInfo;
  const design = designConstructor.getDesign(designID) || { id: designID, designVersion: 1 };
  let designVersion = design.designVersion;

  if (type !== info.type || prelayout !== info.prelayout) {
    _preLayoutInfo = getDefaultPreLayoutInfo({
      // Data reset in model pairs
      prelayout: info.prelayout,
      type: info.type,
      width: info.width,
      diffPairsNumber: info.diffPairsNumber,
      netPrefix: info.netPrefix,
      selectedGroups: info.selectedGroups,
      lanesNumber: info.lanesNumber,
      designType: _preLayoutInfo.designType
    });
    designVersion = design.designVersion + 1;
  } else if ((width !== info.width && [PCIE, ETHERNET].includes(info.type))
    || ([GENERIC, HDMI].includes(info.type) && (diffPairsNumber !== info.diffPairsNumber || netPrefix !== info.netPrefix))
    || (lanesNumber !== info.lanesNumber && info.type === CPHY)) {
    // Data modification in model pairs
    _preLayoutInfo = getDefaultPreLayoutInfo({
      prelayout: info.prelayout,
      type: info.type,
      width: info.width,
      diffPairsNumber: info.diffPairsNumber,
      netPrefix: info.netPrefix,
      selectedGroups: info.selectedGroups,
      notClearPairs: true,
      lanesNumber: info.lanesNumber,
      designType: _preLayoutInfo.designType
    });
    designVersion = design.designVersion + 1;
  } else if (JSON.stringify([...selectedGroups].sort()) !== JSON.stringify([...info.selectedGroups].sort())) {
    _preLayoutInfo = getDefaultPreLayoutInfoByGroups(_preLayoutInfo, info.selectedGroups);
    designVersion = design.designVersion + 1;
  }

  designConstructor.addDesign(designID, {
    ...design,
    designVersion,
    andesType: info.type
  });
  yield put(updatePreLayoutInfo(
    {
      info: {
        ..._preLayoutInfo,
        prelayout: info.prelayout,
        type: info.type,
        width: info.width,
        lanesNumber: info.lanesNumber
      },
      notSave: true,
      designID
    }
  ));
  yield put(updateSignalsColumnsStatus(true, designID));
  yield call(savePreLayoutContentToServer);
}

function* updateGroupName(action) {
  const { record, prevRecord, designID } = action;
  const error = checkNameFormat(record.group);
  if (error) {
    message.error(`Group ${error.toLowerCase()}`);
    return;
  };
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo } = preLayout.info[designID];
  let _preLayoutInfo = { ...preLayoutInfo };
  const { signal_groups, components, selectedGroups } = _preLayoutInfo;
  let _signal_groups = [...signal_groups];
  let _components = [...components];
  let _selectedGroups = [...selectedGroups];

  //other group name list
  const groupNames = _signal_groups.filter(item => item.name !== prevRecord.group).map(item => item.name);

  if (groupNames.includes(record.group)) {
    message.error(`Group name ${record.group} already exists!`);
    return;
  }

  //update signal_groups
  const groupIndex = _signal_groups.findIndex(item => item.name === prevRecord.group);
  if (groupIndex > -1) {
    _signal_groups[groupIndex].name = record.group;
  }

  //update selected groups
  const selectGroupIndex = _selectedGroups.findIndex(item => item === prevRecord.group);
  if (selectGroupIndex > -1) {
    _selectedGroups[selectGroupIndex] = record.group;
  }

  //update component pins signal_group
  _components.forEach(comp => {
    comp.pins.forEach(item => {
      if (item.signal_group === prevRecord.group) {
        item.signal_group = record.group;
      }
    })
  })

  yield put(updatePreLayoutInfo(
    {
      info: { signal_groups: _signal_groups, components: _components, selectedGroups: _selectedGroups },
      designID
    }
  ));
}

function* updateSignalName(action) {
  const { record, prevRecord, designID } = action;
  const error = checkNameFormat(record.name);
  if (error) {
    message.error(`Signal ${error.toLowerCase()}`);
    return;
  };
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo } = preLayout.info[designID];
  let _preLayoutInfo = { ...preLayoutInfo };
  const { signal_groups, components } = _preLayoutInfo;
  let _signal_groups = [...signal_groups];
  let _components = [...components];

  const groupIndex = _signal_groups.findIndex(item => item.name === record.group);
  if (groupIndex < 0) {
    return;
  }
  const signalIndex = _signal_groups[groupIndex].signals.findIndex(item => item.name === prevRecord.name);
  if (signalIndex < 0) {
    return;
  }
  //error check
  const signalNames = _signal_groups[groupIndex].signals.filter(item => item.name !== prevRecord.name).map(item => item.name);
  if (signalNames.includes(record.name)) {
    message.error(` Signal name ${record.name} already exists!`);
    return;
  }
  //update signal_groups
  _signal_groups[groupIndex].signals[signalIndex].name = record.name;

  //update component pins signal name
  _components.forEach(comp => {
    comp.pins.forEach(item => {
      if (item.signal === prevRecord.name) {
        item.signal = record.name;
      }
    })
  })
  yield put(updatePreLayoutInfo(
    {
      info: { signal_groups: _signal_groups, components: _components },
      designID
    }
  ));
}


function* updateNetName(action) {
  const { record, prevRecord, dataIndex, designID } = action;
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo } = preLayout.info[designID];
  let _preLayoutInfo = { ...preLayoutInfo };
  const { signal_groups, components } = _preLayoutInfo;
  let _signal_groups = [...signal_groups];
  let _components = [...components];

  const netNames = getPreLayoutAllNets(_signal_groups, prevRecord[dataIndex][0]);
  //error check
  if (netNames.includes(record.net)) {
    message.error(` Net name ${record.net} already exists!`);
    return;
  }

  const groupIndex = _signal_groups.findIndex(item => item.name === record.group);
  if (groupIndex < 0) {
    return;
  }
  const signalIndex = _signal_groups[groupIndex].signals.findIndex(item => item.name === record.name);
  if (signalIndex < 0) {
    return;
  }

  //update signal_groups
  _signal_groups[groupIndex].signals[signalIndex][dataIndex] = [record.net];

  //update component pins signal name
  _components.forEach(comp => {
    comp.pins.forEach(item => {
      // if (item.net === record.net) {
      //   item.net = record.net;
      // }
      if (item.net === prevRecord[dataIndex][0]) {
        item.net = record.net;
      }
    })
  })
  yield put(updatePreLayoutInfo(
    {
      info: { signal_groups: _signal_groups, components: _components },
      designID
    }
  ));
}

function* _updateLengthUnit(action) {
  const { unit, designID } = action;
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo } = preLayout.info[designID];
  let _preLayoutInfo = { ...preLayoutInfo };
  const { unit: prevUnit, signal_groups } = _preLayoutInfo;
  let _signal_groups = [...signal_groups];

  if (unit !== prevUnit) {
    _signal_groups = updateSignalSectionUnit(_signal_groups, unit, prevUnit);
  }

  yield put(updatePreLayoutInfo(
    {
      info: { signal_groups: _signal_groups, unit },
      designID
    }
  ));
}

function* _editSignalSpacing(action) {
  const { record: { group, signal_spacing }, designID } = action;
  const { AndesV2Reducer: { preLayout } } = yield select();
  const { preLayoutInfo: { signal_groups } } = preLayout.info[designID];
  let _signal_groups = [...signal_groups];

  //number check
  const error = numberCheck(signal_spacing);
  if (error) {
    return;
  }

  const index = _signal_groups.findIndex(item => item.name === group);
  if (index > -1) {
    _signal_groups[index].signal_spacing = signal_spacing;
  }

  yield put(updatePreLayoutInfo(
    {
      info: { signal_groups: _signal_groups },
      designID
    }
  ));
}

function* _savePreLayoutModels(action) {
  const { models, designID } = action;
  yield put(updatePreLayoutInfo(
    {
      info: { models },
      notSave: true,
      designID
    }));
  yield call(savePreLayoutContentToServer);
}

function* _savePreLayoutPcbModel(action) {
  const { model, designID } = action;
  // Wait for three seconds before updating
  yield put(updatePreLayoutInfo(
    {
      info: { preLayoutModel: model },
      designID
    }));
}

function* _renamePreLayout(action) {
  const { itemData } = action;
  const { AndesV2Reducer: { project: { openProjectId, selectedKeys }, endToEndChannel: { endToEndChannelInfo } } } = yield select();
  if (itemData && itemData.id && itemData.name) {
    try {
      const res = yield call(renameDesignPromise, itemData.id, itemData.name);
      designConstructor.addDesign(res.id, res);
      yield put(openProject(openProjectId));
      // Update endToEndChannelInfo data already displayed on the page
      if (selectedKeys.find(item => item.match(END_TO_END_CHANNEL))) {
        const _updateInfo = handleEndToEndChannelInfo(endToEndChannelInfo)
        yield put(updateEndToEndChannelInfo(_updateInfo));
        yield put(updateConnectionModelSetup(true))
        yield call(saveEndToEndChannelContentToServer);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function* updatePinOrNetName(action) {
  const { preData: { pinType, signalGroup, signal, oldName }, newName, nameType, designID } = action;
  if (nameType === 'net') {
    let dataIndex;
    // netToPinType: {net -> pinType}, ex: {nets_P: 'positive'}
    for (const key of Object.keys(netToPinType)) {
      if (pinType === netToPinType[key]) {
        dataIndex = key;
      }
    }
    if (!dataIndex) {
      return;
    }
    let prevRecord = {}
    prevRecord[`${dataIndex}`] = [oldName]
    yield call(updateNetName, {
      record: { net: newName, group: signalGroup, name: signal },
      prevRecord,
      dataIndex,
      designID
    })
  } else {
    const { AndesV2Reducer: { preLayout } } = yield select();
    const { preLayoutInfo } = preLayout.info[designID];
    const { components, preLayoutModel } = preLayoutInfo;
    let _components = [...components];
    const _preLayoutModel = { ...preLayoutModel };
    let { pairs } = _preLayoutModel
    // Avoid the same name
    const component = components.find((item) => item.name === nameType)
    const pinNames = component.pins.map((item) => {
      return item.pin
    })
    //error check
    if (pinNames.includes(newName)) {
      message.error(` Pin name ${newName} already exists!`);
      return;
    }
    //update component pins name
    _components.forEach(comp => {
      if (comp.name === nameType) {
        comp.pins.forEach(item => {
          if (item.pin === oldName) {
            item.pin = newName;
          }
        })
      }
    })
    //update preLayoutModel pins name
    pairs.forEach(item => {
      if (item.component === nameType && item.pin === oldName) {
        item.pin = newName
      }
    })
    yield put(updatePreLayoutInfo(
      {
        info: { preLayoutModel: { ..._preLayoutModel, pairs }, components: _components },
        designID
      }
    ));
  }
}

function* preLayoutSaga() {
  yield takeEvery(GET_PRE_LAYOUT_CONTENT, _getContent);
  yield takeEvery(CREATE_PRE_LAYOUT, _createNewPreLayout);
  yield takeEvery(UPDATE_PRE_LAYOUT_INFO, _savePreLayout);
  yield takeEvery(SELECT_SECTION_TEMPLATE, _selectTemplate);
  yield takeEvery(EDIT_INSERTION_LOSS, _updateInsertion);
  yield takeEvery(CHANGE_PRE_LAYOUT_CONFIG, _updatePreLayoutConfig);
  yield takeEvery(EDIT_GROUP_NAME, updateGroupName);
  yield takeEvery(EDIT_SIGNAL_NAME, updateSignalName);
  yield takeEvery(EDIT_NET_NAME, updateNetName);
  yield takeEvery(CHANGE_LENGTH_UNIT, _updateLengthUnit);
  yield takeEvery(EDIT_SIGNAL_SPACING, _editSignalSpacing);
  yield takeEvery(SAVE_PRE_LAYOUT_MODELS, _savePreLayoutModels);
  yield takeEvery(SAVA_PRELAYOUT_PCB_MODEL, _savePreLayoutPcbModel);
  yield takeEvery(PRELAYOUT_RENAME, _renamePreLayout);
  yield takeEvery(EDIT_PIN_OR_NET_NAME, updatePinOrNetName);
}

export default preLayoutSaga;