import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import { Select, Spin } from "antd";
import Panel from '@/components/Panel';
import PkgModel from './PkgModel';
import { ANDES_V2, ROCKY, SIERRA } from '../../constants/pageType';
import { CARD } from '../../constants/treeConstants';
import './index.css';

const { Option, OptGroup } = Select;
const IBIS = "IBIS", EBD = 'EBD', TOUCHSTONE = "Touchstone", TOUCHSTONE_SPICE = "Touchstone / SPICE", NONE = "None", SPICE = "SPICE", CONNECTOR = "Connector", LAYOUT = "Layout", OPTGROUP_MODEL = "Model";
const packageTypeList = [NONE, IBIS, TOUCHSTONE_SPICE];
const packageTypeList_ebd = [NONE, OPTGROUP_MODEL];
const packageTypeList_ebd_controller = [NONE, OPTGROUP_MODEL, LAYOUT]
const packageOptGroup = [IBIS, EBD, TOUCHSTONE_SPICE]
/* 
  pkg: {},
  None: pkg={type:'None'},
  IBIS: {type:'IBIS',component:'',model:''} //model:Generic/Pin/Matrix
  SPICE: {type:'SPICE', files: [ { fileName:'',libraryId:'',subckt:''} ], pairs:[ {pin: '', node: '',libraryId:'',subckt:''} ]}
  Touchstone: {type:'Touchstone',files: [ { fileName:'',libraryId:'',subckt:''} ], pairs:[ {pin: '', node: '',libraryId:'',subckt:''} ]}
 */

class PackageModel extends Component {
  constructor(props) {
    super(props);
    const { pkg, comps, connectorModel } = props.record;
    const _model = props.modelType === CONNECTOR ? connectorModel : pkg;
    let _pkg = _model ? JSON.parse(JSON.stringify(_model)) : { type: NONE };
    let type = _pkg && _pkg.type ? _pkg.type : NONE;
    //prev version
    if (type === SPICE || type === TOUCHSTONE) {
      type = TOUCHSTONE_SPICE;
      let file = null;
      if (_pkg.fileName) {
        file = {
          type: _pkg.type,
          fileName: _pkg.fileName,
          libraryId: _pkg.libraryId,
          subckt: _pkg.subckt,
          height: 0
        }
      }
      _pkg = {
        type,
        files: file ? [file] : [],
        pairs: [],
        rank: null
      }
    }

    this.state = {
      loading: false,
      pkg: _pkg,
      component: comps && comps.length > 0 && comps[0].name ? comps[0].name : "",// component name
      pkgType: type,//package type "None" / "IBIS" / "Touchstone / SPICE"
      packages: [], // ibis model packages
      IBISPackageModelList: [],
      maxHeight: 600,
      maxWidth: 690
    };
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    const { pkg } = this.state;
    const { packageMsg } = this.props;
    let _pkg = { ...pkg };
    let modelInfo = {};
    if ([TOUCHSTONE, SPICE, EBD, TOUCHSTONE_SPICE].includes(pkg.type) && this.modelChild) {
      modelInfo = this.modelChild.getNetListModel();
      //modelInfo: {files:[], pairs:[] }
      _pkg = { ..._pkg, ...modelInfo };
      //delete prev version field
      _pkg["fileName"] && delete _pkg["fileName"];
      _pkg["libraryId"] && delete _pkg["libraryId"];
      _pkg["subckt"] && delete _pkg["subckt"];
    }
    //_pkg: IBIS: {type:"IBIS", component, model}
    //_pkg: Touchstone / SPICE { type:"Touchstone / SPICE", files, pairs }
    const { record, savePackageModel, switchRankStatus, save, closeRank, componentName } = this.props;
    savePackageModel(_pkg, record);
    if (closeRank && !_pkg.rank && record.pkg.rank) {
      closeRank(record, componentName)
    }
    switchRankStatus && switchRankStatus(true);
    if (packageMsg && packageMsg.length) {
      this.props.updatePackageMsg([])
    }
    save();
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId, packageLoading, packageMsg } = this.props;
    if (verificationId !== prevProps.verificationId) {
      this.props.save();
    }

    if (packageLoading === 'close') {
      this.props.save();
      this.props.updatePackageLoading(false)
    }

    if (packageMsg && packageMsg.length !== prevProps.packageMsg.length) {
      this.setContentHeight(0);
    }
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    const { pkg } = this.props.record;
    const { pkgType } = this.state;
    if (pkgType === IBIS) {
      this.getIBISModels(pkg.libraryId, pkg);
    }
    this.resize();
    this.setContentHeight(0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({
      maxHeight: this.getMaxHeight(),
      maxWidth: this.getMaxWidth()
    })
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  selectPackageType = (key) => {
    const { pkgType } = this.state;
    if (key === TOUCHSTONE) {
      key = TOUCHSTONE_SPICE;
    }
    if (pkgType === key) {
      return;
    }
    let pkg = this.getPKGInfo(key);
    this.setState({
      pkgType: key,
      pkg
    }, () => {
      if (key === IBIS) {
        const model = this.getComponentModel();
        this.setState({ loading: true });
        const libraryId = model && model.libraryId ? model.libraryId : null;
        this.getIBISModels(libraryId);
      }
      if (this.modelChild) {
        this.modelChild.changePKGType(key);
        this.props.isPageDisplay && this.props.savePackageModelInfo()
      }
      this.setContentHeight(0);
    })
  }

  getComponentModel = () => {
    const { record, product } = this.props;
    const { model } = record;
    let _model = null;
    if (product === SIERRA && model && Array.isArray(model.files) && model.files[0]) {
      _model = model.files[0];
    } else if (product === ROCKY && model) {
      _model = { ...model, type: model.libType };
    }
    return _model;
  }

  getPKGInfo = (type) => {
    const { PackageModelInfo, interfaceType } = this.props;
    const model = this.getComponentModel();
    let pkg = {};
    switch (type) {
      case NONE:
        pkg = {
          type: NONE
        };
        break;
      case IBIS:
        pkg = {
          type: IBIS,
          component: '',
          libraryId: model && model.libraryId ? model.libraryId : null,
          model: '',
          modelInfo: ''
        };
        if (interfaceType === CARD) {
          pkg.name = "";
        }
        break;
      case SPICE:
      case TOUCHSTONE:
      case TOUCHSTONE_SPICE:
      case EBD:
      case LAYOUT:
        pkg = new PackageModelInfo(type);
        break;
      default: break;
    }
    return pkg;
  }

  getIBISModels = (id, pkgObj) => {
    this.props.getIbisPackageList(id).then(res => {
      const { pkg } = this.state;
      let IBISPackageModelList = [];
      let find = null;
      if (pkgObj) {
        find = res ? res.find(item => item.component === pkgObj.component) : null;
      } else {
        find = res && res[0] ? res[0] : null;
      }
      if (find) {
        if (find.pinSection && find.pinSection.length > 0) {
          IBISPackageModelList.push({ name: 'Pin', modelInfo: null });
        }
        if (find.generic && find.generic.length > 0) {
          const _modelInfo = this.getIBISPackageModelInfo(res, { component: find.component }, 'Generic');
          IBISPackageModelList.push({ name: 'Generic', modelInfo: _modelInfo });
        }
        if (find.packageModel) {
          const _modelInfo = this.getIBISPackageModelInfo(res, { component: find.component }, 'Matrix');
          IBISPackageModelList.push({ name: 'Matrix', modelInfo: _modelInfo });
        }
      }

      let model = pkgObj ? pkgObj.model : (IBISPackageModelList[0] ? IBISPackageModelList[0].name : '');
      let component = pkgObj ? pkgObj.component : (find ? find.component : '');

      let newPkg = {
        ...pkg,
        component: component,
        model: model
      };
      let packageModel = '';
      if (model) {
        packageModel = this.getIBISPackageModelInfo(res, newPkg, model);
      }
      newPkg.modelInfo = packageModel;
      this.setState({
        packages: res ? res : [],
        pkg: { ...newPkg },
        IBISPackageModelList,
        loading: false
      })
    })
  }

  selectIBISFile = (key) => {
    const { pkg } = this.state;
    const { ibisList } = this.props;
    const findFile = ibisList.find(item => item.fileName === key);
    if (!findFile) {
      return;
    }
    let _pkg = { ...pkg };
    _pkg.name = key;
    _pkg.libraryId = findFile.id;
    this.setState({
      pkg: _pkg
    }, () => {
      this.getIBISModels(_pkg.libraryId);
    })
  }

  selectIBISComponent = (key) => {
    const { packages, pkg } = this.state;
    let find = packages.find(item => item.component === key);
    let IBISPackageModelList = [];
    if (find) {
      if (find.pinSection && find.pinSection.length > 0) {
        IBISPackageModelList.push({ name: 'Pin', modelInfo: null });
      }
      if (find.generic && find.generic.length > 0) {
        const _modelInfo = this.getIBISPackageModelInfo(packages, { component: key }, 'Generic');
        IBISPackageModelList.push({ name: 'Generic', modelInfo: _modelInfo });
      }

      if (find.packageModel) {
        const _modelInfo = this.getIBISPackageModelInfo(packages, { component: key }, 'Matrix');
        IBISPackageModelList.push({ name: 'Matrix', modelInfo: _modelInfo });
      }
    }

    let newPkg = { ...pkg, component: key, model: IBISPackageModelList[0] ? IBISPackageModelList[0].name : '', };
    let packageModel = '';
    if (IBISPackageModelList[0]) {
      packageModel = this.getIBISPackageModelInfo(packages, newPkg, IBISPackageModelList[0].name);
    }
    newPkg.modelInfo = packageModel;
    this.setState({
      pkg: newPkg,
      IBISPackageModelList
    })
  }

  getIBISPackageModelInfo = (packages, pkg, model) => {
    let packageModel = null;

    if (packages && pkg && model) {
      const findComp = packages.find(item => pkg && item.component === pkg.component);

      if (findComp) {
        switch (model) {
          case 'Generic':
            packageModel = {};
            findComp.generic.forEach(item => {

              if (item.name === 'R_pkg' || item.name.match(/R/ig)) {
                packageModel.R = item.typ;
              }
              if (item.name === 'L_pkg' || item.name.match(/L/ig)) {
                packageModel.L = item.typ;
              }
              if (item.name === 'C_pkg' || item.name.match(/C/ig)) {
                packageModel.C = item.typ;
              }
            });
            break;
          case 'Pin':
            packageModel = '';
            break;
          case 'Matrix':
            packageModel = findComp.packageModel;
            break;
          default:
            break;
        }
      }
    }
    return packageModel;
  }

  getMaxHeight = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { height } = offset;
      if (!height) return 800;
      const _height = (height - 100) * 0.77;
      return _height > 1200 ? 1200 : _height;
    }
  }

  getMaxWidth = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { width } = offset;
      if (!width) return 690;
      const _width = width * 0.8;
      return _width > 690 ? 690 : _width;
    }
  }

  selectIBISPackageModel = (key) => {
    const { pkg, packages } = this.state;
    const packageModel = this.getIBISPackageModelInfo(packages, pkg, key);
    this.setState({
      pkg: { ...pkg, model: key, modelInfo: packageModel }
    })
  }

  selectLayoutPackageModel = async (key) => {
    // select Package layout
    const { componentName, packageLayoutList } = this.props;
    const findData = packageLayoutList.find(item => item.name === key)
    const packageInfo = { id: findData.id, name: findData.name }
    this.props.selectPackageLayoutModel(packageInfo, componentName)
  }

  getPackageTypeValue = (value, product, type, list) => {
    if (product === ANDES_V2 && value === TOUCHSTONE_SPICE) {
      return TOUCHSTONE;
    }
    if (product === ROCKY && type === "Model File") {
      let _value = value && value.name ? value.name : '';
      return _value
    }
    return value;
  }

  getSelectComponent(type, placeholder, value, selectHandler, list) {
    const modelInfo = this.getComponentModel();
    const { product, interfaceType } = this.props;
    return <div className='package-model-select'>
      <span>{type}</span>
      <Select
        placeholder={placeholder}
        value={this.getPackageTypeValue(value, product, type, list)}
        onSelect={selectHandler}
        className='package-model-selection'
        popupClassName='package-model-select-dropdown'
        popupMatchSelectWidth={false}
        getPopupContainer={() => document.getElementById('root')}
      >
        {
          list.map(item => {
            if (type === 'Component') {
              return <Option
                key={item.component}
                value={item.component}
                title={item.component}
              >{item.component}</Option>
            }
            if (type === 'Model') {
              return <Option
                key={item.name}
                value={item.name}
                disabled={item.name === 'Matrix' ? true : false}
                title={item.name === 'Matrix' ? "Not available." : (item.modelInfo ?
                  (item.name === 'Generic'
                    ? `${item.name}(R:${item.modelInfo.R}, L:${item.modelInfo.L}, C:${item.modelInfo.C})`
                    : `${item.name}(${item.modelInfo})`)
                  : item.name)}
              >
                {item.modelInfo ?
                  (item.name === 'Generic'
                    ? `${item.name}(R:${item.modelInfo.R}, L:${item.modelInfo.L}, C:${item.modelInfo.C})`
                    : `${item.name}(${item.modelInfo})`)
                  : item.name}
              </Option>
            }
            if (type === 'Model Type') {
              const disabled = interfaceType === CARD ? false : (!modelInfo || !modelInfo.libraryId || modelInfo.type !== IBIS);
              if (item === OPTGROUP_MODEL) {
                return <OptGroup label={item} key={item}>
                  {packageOptGroup.map(optItem => {
                    return <Option
                      disabled={(optItem === IBIS) ? disabled : false}
                      key={optItem}
                      value={optItem}
                      title={((optItem === IBIS && disabled) ? 'Need to set up Model first.' : optItem)}
                    >{optItem}</Option>
                  })}
                </OptGroup>
              }
              return <Option
                disabled={(item === IBIS) ? disabled : false}
                key={item}
                value={item}
                title={((item === IBIS && disabled) ? 'Need to set up Model first.' : item)}
              >{item}</Option>
            }
            if (type === "Model File") {
              return <Option
                key={item.name}
                value={item.name}
              >{item.name}</Option>
            }
            return null
          })
        }
      </Select>
    </div>
  }

  setContentHeight = (time) => {
    let _time = 300;
    if (time !== undefined) {
      _time = time;
    }
    const { modelType, displayType, componentName, packageMsg } = this.props;
    const { pkgType } = this.state;
    const prevHeight = this.state.height;
    const defaultHeight = (pkgType === SPICE || pkgType === TOUCHSTONE || pkgType === TOUCHSTONE_SPICE) ? 400 : (pkgType === IBIS ? 180 : 108);

    setTimeout(() => {
      //set panel height
      let content = document.getElementById(`spice-model_${componentName}_${modelType}_${displayType}_component`);
      let height = content ? content.offsetHeight + 78 : prevHeight && defaultHeight;
      if (pkgType === LAYOUT && packageMsg && packageMsg.length) {
        height = height + 130
      }
      this.setState({
        height
      })
    }, _time);
  }

  massageDisplay = () => {
    const { pkgType } = this.state;
    if (pkgType !== LAYOUT) { return null }
    const { packageMsg } = this.props;
    return <div className='find-package-layout-errorMsg-box'>
      <div>[Warning] The selected package interface failed to match, and the components and pins that failed to match are as follows:</div>
      {packageMsg.map((item, index) => {
        const { channelName, errorCompInfo = {} } = item;
        return <div key={index}>
          <div className='errorMsg-box-div'>{channelName}</div>
          <span className='errorMsg-box-span'>[{errorCompInfo.comp}]: </span>
          <span>{errorCompInfo.pins.join(', ')}</span>
        </div>
      })}
    </div>
  }

  renderDialog() {
    const { pkgType, pkg, packages, IBISPackageModelList, loading, height, maxHeight, maxWidth } = this.state;
    const { modelType, displayType, record, spiceList, touchstoneList, componentName, pinList, autoSelectPorts,
      getTouchstoneParse, getSPiceParse, PackageModelFileInfo, product, ebdList, getEBDParse, getEBDRankList,
      packageLayoutList, packageLoading, packageMsg, interfaceType, ibisList, matchClear } = this.props;
    const _height = height > maxHeight ? maxHeight : height;
    let _packageTypeList = packageTypeList;
    switch (product) {
      case ROCKY:
        if (record.type === "Controller") {
          _packageTypeList = packageTypeList_ebd_controller
        } else {
          _packageTypeList = packageTypeList_ebd;
        }
        break;
      case ANDES_V2:
        _packageTypeList = [NONE, TOUCHSTONE];
        break;
      default:
        break;
    }
    const content = (
      <Panel
        className={`spice-model-panel pkg-panel ${(product || "").toLowerCase()}-panel`}
        title={<div className='package-model-title'>{componentName} {modelType} Model</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={maxWidth}
        minWidth={200}
        minHeight={200}
        height={_height}
        maxHeight={maxHeight + 100}
        position='panel-center'
        draggable
        createWH={true}
        overflow='hidden'
      >
        <div className='spice-model-content' id="pkg-scrollId">
          <Spin spinning={packageLoading ? true : false} tip={packageLoading ? `Loading Package...` : ''}>
            <div id={`spice-model_${componentName}_${modelType}_${displayType}_component`}>
              {this.getSelectComponent("Model Type", 'Model Type', pkgType, this.selectPackageType, _packageTypeList)}
              {pkgType === IBIS ? <Spin spinning={loading}>
                {interfaceType === CARD ? this.getSelectComponent("Model File", 'Model File', pkg, this.selectIBISFile, ibisList) : null}
                {this.getSelectComponent("Component", 'Component Name', pkg.component, this.selectIBISComponent, packages)}
                {this.getSelectComponent("Model", 'IBIS Package Model Type', pkg.model, this.selectIBISPackageModel, IBISPackageModelList)}
              </Spin>
                : // SPICE/Touchstone
                pkgType === LAYOUT ?
                  this.getSelectComponent("Model File", 'Package Model', pkg.packageLayoutInfo, this.selectLayoutPackageModel, packageLayoutList) :
                  pkgType !== NONE && <PkgModel
                    pkgType={pkgType}
                    onRef={this.onRef}
                    scrollId={"pkg-scrollId"}
                    pkg={pkg}
                    record={record}
                    PackageModelFileInfo={PackageModelFileInfo}
                    spiceList={spiceList}
                    touchstoneList={touchstoneList}
                    pins={pinList}
                    getTouchstoneParse={getTouchstoneParse}
                    getSPiceParse={getSPiceParse}
                    ebdList={ebdList}
                    getEBDParse={getEBDParse}
                    getEBDRankList={getEBDRankList}
                    autoSelectPorts={autoSelectPorts}
                    displayType={displayType}
                    modelType={modelType}
                    setContentHeight={this.setContentHeight}
                    panelHeight={height}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    product={product}
                    interfaceType={interfaceType}
                    matchClear={matchClear}
                  />
              }
            </div>
            {packageMsg && packageMsg.length ? this.massageDisplay() : null}
          </Spin>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { inputRef } = this;
    const { pkg } = this.props.record;
    let text = '';
    if (pkg && pkg.type === IBIS) {
      text = this.props.text;
    } else if (pkg && (pkg.type === SPICE || pkg.type === TOUCHSTONE || pkg.type === TOUCHSTONE_SPICE)) {
      const files = pkg.files && pkg.files.length ? pkg.files : [];
      const fileNames = files.map(item => item.fileName);
      text = fileNames && fileNames.length > 0 ? fileNames.join(", ") : "";
    } else {
      text = '';
    }
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default PackageModel;