import { getChannelContentPromise } from "./channelCtrl";

class ChannelInfo {
  constructor() {
    this.channelInfoList = new Map(); //key - channel id, value :{ id ,name, content }
    this.get.bind(this);
  }

  get(id) {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(null);
        return;
      }
      const info = this.channelInfoList.get(id);
      if (info && Object.keys(info).length) {
        resolve(info);
      } else {
        getChannelContentPromise(id).then(res => {
          res && this.channelInfoList.set(id, res);
          resolve(res);
        });
      }
    })
  }

  set = (id, data) => {
    this.channelInfoList.set(id, data);
  }

  delete = (id) => {
    this.channelInfoList.delete(id);
  }

  clearCache = () => {
    this.channelInfoList = new Map();
  }

  getContent = (id) => {
    const info = this.channelInfoList.get(id);
    if (!info || !info.content) {
      return [];
    }
    return info.content;
  }

  getSignals = (id) => {
    const info = this.channelInfoList.get(id);
    if (!info || !info.content || !info.content.signals) {
      return [];
    }
    return info.content.signals;
  }

  getSignalNames = (id) => {
    const info = this.channelInfoList.get(id);
    if (!info || !info.content || !info.content.signals) {
      return [];
    }
    return info.content.signals.map(item => item.name);
  }

  getSelectedSignalNames = (id) => {
    const info = this.channelInfoList.get(id);
    if (!info || !info.content || !info.content.signals) {
      return [];
    }
    const selectedSignals = info.content.selectedSignals || [];
    return info.content.signals.map(item => item.name).filter(item => selectedSignals.includes(item));
  }

  getComponents = (id) => {
    const info = this.channelInfoList.get(id);
    if (!info || !info.content || !info.content.components) {
      return [];
    }
    return info.content.components;
  }
};

let channelSetupInfo = new ChannelInfo();
export default channelSetupInfo;