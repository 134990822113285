import EyeParam, { getFile } from './helper/eyediagram/eyeParam';
import EyeConstructor from './helper/eyediagram/eyeConstructor';
import EyePic from './helper/eyediagram/eyePic';
import { ALL, ALL_2T, DDR4, DDR5, DIMM_DDR_TYPES } from '../constants';
import CustomizedEye from './helper/eyediagram/CustomizedEyeDiagram/customizedEyeDiagram';
import { PATTERN_SWEEP } from '../../../services/Rocky/result/constant';
class EyediagramResult {

  constructor() {
    // key - byte -> verificationId_read/write, 
    //     - adr/cmd -> verificationId_memory
    // value - byte eyediagram
    this.eyeDiagram = new Map(); // bytes, addr, cmd eyediagram result data in a channel
  }

  getVerificationType(interfaces) {
    if (!interfaces.length) {
      return null;
    } else {
      const name = interfaces[0].Name;
      if (name.includes('Byte')) {
        return 'Byte';
      } else if (name.includes('ADR')) {
        return 'ADR';
      } else if (name.includes('CMD')) {
        return 'CMD';
      } else {
        return null;
      }
    }
  }

  //                    publicPath                                       resultModeFolder  Signal
  // byte     All     - verificationSubId/result/channelName/data/eye/byteN_rd|byteN_wt + fileName
  // byte     Signal  - verificationSubId/result/channelName/data/eye/byteN_rd|byteN_wt/DQN|DQS + fileName
  //                    publicPath                                                memory      Signal
  // adr|cmd  All     - verificationSubId/result/channelName/data/eye/addr_cmds/Memory_Comp/addr_all|cmds_all + fileName
  // adr|cmd  Signal  - verificationSubId/result/channelName/data/eye/addr_cmds/Memory_Comp/addrN|cmdsN + fileName
  getSignalPath({ signals, allSignals, memoryName, interfaces, verificationSubId, channelName, readWriteMode, resultType, rockySSNId }) {
    let _signals = [...signals];
    const verificationType = this.getVerificationType(interfaces);
    if (!verificationType) {
      return;
    }
    if (verificationType === 'Byte') {
      const _interface = interfaces.find(item => item.Name.toLowerCase().includes(readWriteMode));
      const publicPath = resultType === PATTERN_SWEEP ? `${rockySSNId}/result/${channelName}/data/eye/` : `${verificationSubId}/result/${channelName}/data/eye/`;
      // byteName + resultMode - byteN_rd|byteN_wt
      const byteName = _interface.Name.split('_')[0].toLowerCase();
      const resultMode = readWriteMode === 'write' ? '_wt' : '_rd';
      const resultModeFolder = byteName + resultMode;
      _signals.forEach(sig => {
        if (resultType === PATTERN_SWEEP) {
          let _byteName = sig.signalGroup ? sig.signalGroup.toLowerCase() : byteName;
          sig.path = publicPath + _byteName + resultMode;
        } else {
          sig.path = sig.name === ALL ? publicPath + resultModeFolder : publicPath + resultModeFolder + '/' + sig.name;
        }
      });
    } else {
      // ADR | CMD
      const publicPath = `${verificationSubId}/result/${channelName}/data/eye/addr_cmds/`;
      let allName = '', all2TName = "";
      if (verificationType === 'ADR') {
        allName = 'addr_all';
      } else if (verificationType === 'CMD') {
        allName = 'cmds_all';
        all2TName = "cmds_all_2t";
      };

      const includesAll = _signals.map(item => item.name).includes(ALL);
      const includesAll2T = _signals.map(item => item.name).includes(ALL_2T);

      const _memoryName = memoryName.replace(' ', '_');
      _signals.forEach((sig, i) => {
        if (sig.name === ALL) {
          sig.path = publicPath + _memoryName + '/' + allName;
        } else if (sig.name === ALL_2T) {
          sig.path = publicPath + _memoryName + '/' + all2TName;
        } else {
          let _i = includesAll || includesAll2T ? i - 1 : i;
          if (includesAll2T && includesAll) {
            _i = i - 2;
          }

          if (allSignals.length !== signals.length) {
            const index = allSignals.findIndex(it => it.name === sig.name);
            _i = index > -1 ? index : _i;
          }

          let signalName = sig.name;
          if (verificationType === 'ADR') {
            signalName = 'addr' + _i;
          } else if (verificationType === 'CMD') {
            signalName = 'cmds' + _i; // cmds0 - cmdsN
          }
          sig.path = publicPath + _memoryName + '/' + signalName;
        }
      });
    }
    return _signals;
  }

  // id - byte -> verificationId_read/write, 
  //    - adr/cmd -> verificationId_memory
  getResultFromCache(id) {
    return this.eyeDiagram.get(id);
  }

  /**
   *
   *
   * @param {*} { id, channelId, signals, memoryName,
   *     interfaces, verificationSubId, channelName, readWriteMode }
   * @param {*} sigLength signal length
   * @returns
   * @memberof EyediagramResult
   */
  getEyeParamAsPromise({ id, channelId, signals, memoryName, verificationId,
    interfaces, verificationSubId, channelName, readWriteMode, resultType, rockySSNId, isStatEye }, sigLength, train) {
    return new Promise((resolve, reject) => {
      const eye = this.getResultFromCache(id);
      const allSignals = JSON.parse(JSON.stringify(signals));
      if (!eye) {
        const Byte_ADR_CMD = new EyeConstructor();
        this.eyeDiagram.set(id, Byte_ADR_CMD);
      }
      if (eye && eye.eyeParameterList && eye.eyeParameterList.getSize() === sigLength) {
        resolve(eye.eyeParameterList.getData());
      } else {
        let _signals = [...signals];
        let Byte_ADR_CMD_Param = null;

        if (eye && eye.eyeParameterList && eye.eyeParameterList.getSize() > 0) {
          Byte_ADR_CMD_Param = eye.eyeParameterList;
          const signalInc = Array.from(eye.eyeParameterList.getData().keys());
          _signals = _signals.filter(item => !signalInc.includes(item.name))
        } else {
          Byte_ADR_CMD_Param = new EyeParam();
          // Update this.eyeDiagram
          const ByteADRCMD = this.eyeDiagram.get(id);
          ByteADRCMD.updateEyeParamList(Byte_ADR_CMD_Param);
        };

        if (resultType === PATTERN_SWEEP) {
          Byte_ADR_CMD_Param.readAllPatternEyeParamFiles(rockySSNId, _signals, readWriteMode).then(eyeParamListData => {
            try {
              const ByteADRCMD = this.eyeDiagram.get(id);
              ByteADRCMD.updateEyeParamList(Byte_ADR_CMD_Param);
              const eyeParamList = this.getEyeParamById(id);
              resolve(eyeParamList.getData());
            } catch (e) {
              console.error(e);
            }
          })
        }
        if (isStatEye) {
          Byte_ADR_CMD_Param.readAllAMIEyeParamFiles({ signals: _signals, channelId, type: readWriteMode, verificationId }).then(eyeParamDataList => {
            try {
              const ByteADRCMD = this.eyeDiagram.get(id);
              ByteADRCMD.updateEyeParamList(Byte_ADR_CMD_Param);
              const eyeParamList = this.getEyeParamById(id);
              resolve(eyeParamList.getData());
            } catch (e) {
              console.error(e);
            }
          })
        }
        if (resultType !== PATTERN_SWEEP && !isStatEye) {
          let signalsPath = this.getSignalPath({ signals: _signals, allSignals, memoryName, interfaces, verificationSubId, channelName, readWriteMode, resultType, rockySSNId });
          if (train) {
            const _trainSignalsPath = signalsPath.map(item => { return { ...item, train: true, name: 'train_' + item.name } })
            signalsPath = [...signalsPath, ..._trainSignalsPath]
          }
          Byte_ADR_CMD_Param.readAllEyeParamFiles(channelId, signalsPath).then(eyeParamDataList => {
            const ByteADRCMD = this.eyeDiagram.get(id);
            ByteADRCMD.updateEyeParamList(Byte_ADR_CMD_Param);
            const eyeParamList = this.getEyeParamById(id);
            resolve(eyeParamList.getData());
          });
        }
      }
    });
  };

  getEyeParamById(id) {
    const eye = this.getResultFromCache(id);
    return eye.eyeParameterList;
  }

  getCustomEyeList(verificationId, type, eyeMode, isTrain) {
    //type -> pic / margin
    const eyeResultList = CustomizedEye ? CustomizedEye.getEyeResultList(verificationId) : null;
    let customEyeList = new Map(), customSignals = [];
    if (!eyeResultList || eyeResultList.length === 0) {
      return { customEyeList, customSignals };
    }

    let existHashIds = [];
    for (let item of eyeResultList) {

      const hashId = item.hashId;
      if (existHashIds.includes(hashId)) {
        continue;
      }
      existHashIds.push(hashId);
      const result = item.result;
      const signalNets = item.signalNets ? item.signalNets : [];
      let name = item.name, defaultName = name;
      let i = 0;
      while (customEyeList.has(name)) {
        i += 1;
        name = `${defaultName}${i}`;
      }

      const configSignals = item.eyeConfig && item.eyeConfig.plot && item.eyeConfig.plot.signals ? item.eyeConfig.plot.signals : [];
      for (let res of result) {
        const _eyeMode = isTrain ? `train_${eyeMode}` : eyeMode;
        const _name = isTrain ? `train_${item.name}` : item.name;
        if (_eyeMode !== res.eyeMode) {
          continue;
        }

        customEyeList.set(_name, res[type]);//pic / margin

        const _signalIndex = customSignals.findIndex(it => it.name === item.name);
        if (_signalIndex < 0) {
          customSignals.push({ name: item.name, signals: [...configSignals], nets: [...signalNets], custom: true })
        }
      }
    }
    return { customEyeList, customSignals };
  }

  /**
   *
   *
   * @param {*} { id, channelId, signals, memoryName,
   *     interfaces, verificationSubId, channelName, readWriteMode }
   * @param {*} sigLength signal length
   * @returns
   * @memberof EyediagramResult
   */
  getEyePng({ id, channelId, signals, memoryName, verificationId,
    interfaces, verificationSubId, channelName, readWriteMode, rockySSNId, isStatEye }, sigLength, train, resultType) {
    return new Promise((resolve, reject) => {
      const allSignals = JSON.parse(JSON.stringify(signals));
      const eye = this.getResultFromCache(id);
      if (!eye) {
        const Byte_ADR_CMD = new EyeConstructor();
        this.eyeDiagram.set(id, Byte_ADR_CMD);
      }

      if (eye && eye.eyePicList && eye.eyePicList.getSize() === sigLength) {
        resolve(eye.eyePicList.getData());
      } else {
        let _signals = [...signals];
        let Byte_ADR_CMD_Param_Pic = null;
        if (eye && eye.eyePicList && eye.eyePicList.getSize() > 0) {
          Byte_ADR_CMD_Param_Pic = eye.eyePicList;
          const signalInc = Array.from(eye.eyePicList.getData().keys());
          _signals = _signals.filter(item => !signalInc.includes(item.name))
        } else {
          Byte_ADR_CMD_Param_Pic = new EyePic();
          // Update this.eyeDiagram
          const ByteADRCMD = this.eyeDiagram.get(id);
          ByteADRCMD.updateEyePinList(Byte_ADR_CMD_Param_Pic);
        };
        if (resultType === PATTERN_SWEEP) {
          const fileName = 'eye_diagram.png';
          Byte_ADR_CMD_Param_Pic.readAllPatternEyePicFiles(rockySSNId, _signals, readWriteMode, fileName).then(eyePicListData => {
            try {
              const ByteADRCMD = this.eyeDiagram.get(id);
              ByteADRCMD.updateEyePinList(Byte_ADR_CMD_Param_Pic);
              const eyePicList = this.getEyePicById(id);
              resolve(eyePicList.getData());
            } catch (e) {
              console.error(e);
            }
          })
        }
        if (isStatEye) {
          Byte_ADR_CMD_Param_Pic.readAllAMIEyePicFiles({ signals: _signals, channelId, type: readWriteMode, verificationId }).then(eyePicListData => {
            try {
              const ByteADRCMD = this.eyeDiagram.get(id);
              ByteADRCMD.updateEyePinList(Byte_ADR_CMD_Param_Pic);
              const eyePicList = this.getEyePicById(id);
              resolve(eyePicList.getData());
            } catch (e) {
              console.error(e);
            }
          })
        }
        if (resultType !== PATTERN_SWEEP && !isStatEye) {
          let signalsPath = this.getSignalPath({ signals: _signals, allSignals, memoryName, interfaces, verificationSubId, channelName, readWriteMode, rockySSNId });
          if (train) {
            const _trainSignalsPath = signalsPath.map(item => { return { ...item, train: true, name: 'train_' + item.name } })
            signalsPath = [...signalsPath, ..._trainSignalsPath]
          }
          Byte_ADR_CMD_Param_Pic.readAllEyePicFiles(channelId, signalsPath).then(eyePicListData => {
            const ByteADRCMD = this.eyeDiagram.get(id);
            ByteADRCMD.updateEyePinList(Byte_ADR_CMD_Param_Pic);
            const eyePicList = this.getEyePicById(id);
            resolve(eyePicList.getData());
          })
        }
      }
    })
  };

  getEyePicById(id) {
    const eye = this.getResultFromCache(id);
    return eye.eyePicList;
  };

  cleanCache(verificationIds) {
    if (verificationIds && verificationIds.length > 0) {
      const keys = Array.from(this.eyeDiagram.keys()).filter(key => verificationIds.some(id => key.includes(id)));
      keys.forEach(key => this.eyeDiagram.delete(key));
    } else {
      this.eyeDiagram = new Map();
    }
  }
};

const Eyediagram = new EyediagramResult();

export default Eyediagram;

export const eyeParamsArr = (projectType, isByte) => {
  const eye = [{ key: 'Eye Width', valueKey: 'width', unit: 'ps', num: 1e12 },
  { key: 'Eye Height', valueKey: 'height', unit: 'mV', num: 1000 },
  { key: 'Jitter', valueKey: 'jitter', unit: 'ps', num: 1e12 }];

  const margin = [{ key: 'Top Margin', valueKey: 'top_margin', unit: 'mV', num: 1000 },
  { key: 'Bottom Margin', valueKey: 'bot_margin', unit: 'mV', num: 1000 },
  { key: 'Setup Margin', valueKey: 'setup_margin', unit: 'ps', num: 1e12 },
  { key: 'Hold Margin', valueKey: 'hold_margin', unit: 'ps', num: 1e12 }];

  return isByte && [DDR5, ...DIMM_DDR_TYPES].includes(projectType) ? [...eye] : [...eye, ...margin]
}

export const eyeOverShootParamsArr = (projectType, isAll, isByte) => {
  const overShootParams = [];
  const overShoot = [{ key: 'Overshoot Margin', valueKey: 'overshoot_margin', unit: 'mV', num: 1 },
  { key: 'Undershoot Margin', valueKey: 'undershoot_margin', unit: 'mV', num: 1 },
  { key: 'Overshoot Area Margin', valueKey: 'overshoot_area_margin', unit: 'V-ns', num: 1 },
  { key: 'Undershoot Area Margin', valueKey: 'undershoot_area_margin', unit: 'V-ns', num: 1 },
  ];
  if (!isAll) {
    overShootParams.push(...overShoot)
  }
  if (projectType === DDR4 && !isAll) {
    const DDR4OverShoot = [{ key: 'Overshoot Area Margin2', valueKey: 'overshoot_area_margin2', unit: 'V-ns', num: 1 },]
    if (isByte) {
      DDR4OverShoot.push({ key: 'Undershoot Area Margin2', valueKey: 'undershoot_area_margin2', unit: 'V-ns', num: 1 })
    }
    overShootParams.push(...DDR4OverShoot)
  }
  if (isAll) {
    const DiffOverShoot = [{ key: 'Differential Overshoot Margin', valueKey: 'diff_over_shoot_margin', unit: 'mV', num: 1 }, { key: 'Differential Undershoot Margin', valueKey: 'diff_under_shoot_margin', unit: 'mV', num: 1 },
    { key: 'Differential Overshoot Area Margin', valueKey: 'diff_over_shoot_area_margin', unit: 'V-ns', num: 1 }, { key: 'Differential Undershoot Area Margin', valueKey: 'diff_under_shoot_area_margin', unit: 'V-ns', num: 1 }]
    overShootParams.push(...DiffOverShoot)
    if (projectType === DDR4) {
      const DDR4DiffOverShoot = [{ key: 'Differential Overshoot Area Margin2', valueKey: 'diff_over_shoot_area_margin2', unit: 'V-ns', num: 1 }]
      if (isByte) {
        DDR4DiffOverShoot.push({ key: 'Differential Undershoot Area Margin2', valueKey: 'diff_under_shoot_area_margin2', unit: 'V-ns', num: 1 })
      }
      overShootParams.push(...DDR4DiffOverShoot)
    }
  }
  return [...overShootParams];
}

export function getJedecEyeParamsArr(projectType, isAll) {
  let jedecList = [];
  if (projectType === 'byte') {
    jedecList = [{ id: 'tjit_dqs', name: 'tJIT(DQS)' },
    { id: 'tqsh', name: 'tQSH' },
    { id: 'tqsl', name: 'tQSL' },
    { id: 'vix_dq', name: 'DQS (VIX)' },
    ]
  } else {
    jedecList = [{ id: 'tjit', name: 'tJIT' },
    { id: 'tck_avg', name: 'tCK(avg)' },
    { id: 'tch_avg', name: 'tCH(avg)' },
    { id: 'tcl_avg', name: 'tCL(avg)' },
    { id: 'tch_abs', name: 'tCH(abs)' },
    { id: 'tcl_abs', name: 'tCL(abs)' },
    { id: 'vix_ca', name: 'CLK (VIX)' },
    ]
  }
  if (!isAll) {
    jedecList = jedecList.filter(item => item.id !== 'vix_dq' && item.id !== 'vix_ca')
  }
  return jedecList
}

export async function getEyeMaskDefineJson({ verificationSubId, channelName, channelId }) {
  const path = `${verificationSubId}/result/${channelName}/models/mask.json`;
  return await getFile({ channelId, filePath: path });
}