import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import './sierraPanel.css';

class ShowModelsPanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  render() {
    const { removed } = this.props;
    const { oldName, newName, removedModels, type } = removed;
    const content = (
      <Panel
        className="sierra-show-models sierra-panel"
        position='panel-center-left'
        title='Updated information'
        zIndex={2000}
        onCancel={() => this.props.closeModal()}
        width={400}
        draggable
        minHeight={200}
      >
        <div className="show-models-tip">
          <span style={{ fontWeight: 800 }}>{oldName}</span>
          {` has been updated, the following ${type === 'ibis' ? 'models' : 'subckts'} do not exist in `}
          <span style={{ fontWeight: 800 }}>{newName}</span>:
        </div>
        <div className="show-models-list">
          <ul>
            {removedModels && removedModels.map(model => <li key={model}>{model}</li>)}
          </ul>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ShowModelsPanel;