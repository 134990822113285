import defaultColor from '@/constants/defaultColors';

function parseTotalTraceCapacitance(csvContent, pcb, pcbId) {
  if (!csvContent) {
    return []
  }

  let data = [];
  const lines = csvContent.match(/[^\r\n]+/g);
  if (!lines || !lines.length) {
    return []
  }
  /* pcb,signal_1,signal_2,pins_1,pins_2,capacitor
3840018213504,SYSCTLR,SYSCTLR,U111-A15;U108-3;R757-2,U111-A15;U108-3;R757-2,8.205213609160794e-12
3840018213504,SYSCTLR,SYSCTLR,U111-A15;U108-3;R757-2,U111-D11;U108-4;R758-2,2.637297746115e-14
3840018213504,SYSCTLR,SYSCTLR,U111-D11;U108-4;R758-2,U111-A15;U108-3;R757-2,2.6372977443480255e-14
3840018213504,SYSCTLR,SYSCTLR,U111-D11;U108-4;R758-2,U111-D11;U108-4;R758-2,8.663637477167562e-12
 */
  let signalList = [];

  for (let i = 1; i < lines.length; i++) {
    const line = lines[i];
    const words = line.split(",");
    const [_pcbId, signal_1, signal_2, pins_1, pins_2, capacitor] = words || [];
    const signalName = `${signal_1}::${signal_2}`;
    if (signalList.includes(signalName)) {
      continue;
    }
    const _pins_1 = pins_1.split(";").join(", ");
    const _pins_2 = pins_2.split(";").join(", ");
    signalList.push(signalName, `${signal_2}::${signal_1}`);
    data.push({
      pcb: pcbId === _pcbId ? pcb : _pcbId,
      signal_1,
      signal_2,
      pins_1: _pins_1,
      pins_2: _pins_2,
      capacitor,
      pcbId
    })
  }
  return data;
}

function getTotalTraceCapacitanceTableData({ content, pcbId, capIndex, components = [], colorIndex, pathObj, verificationId }) {
  if (!content || !Object.keys(content).length) {
    return {}
  }

  try {
    let tableData = [], hasSegment = false, signalNames = [], segmentIndex = 1;
    const frequency = content.frequency;
    let signalObj = {};

    for (let capItem of content.capacitance || []) {
      /*  let signalIndex = 0; */
      for (let signalItem of capItem.capacitance || []) {
        if (!signalObj[signalItem.signal]) {
          signalObj[signalItem.signal] = []
        }

        if (signalNames.includes(signalItem.signal)) {
          hasSegment = true;
        }
        let comps = [];
        comps = capItem.components.filter(item => (components || []).includes(item));
        const id = `${pcbId}::${signalItem.signal}::${segmentIndex}`;
        signalObj[signalItem.signal].push({
          signal: signalItem.signal,
          capacitance: signalItem.capacitance,
          segment: segmentIndex,
          comps,
          segmentLength: 0,
          frequency,
          id,
          path: capItem.path
        })
        pathObj[id] = `${verificationId}::${capItem.path}`;
        signalNames.push(signalItem.signal)
      }
      segmentIndex += 1;
      capIndex += 1;
    }

    let curveContent = [];

    for (let signal of Object.keys(signalObj)) {
      let total_capacitance = 0;
      signalObj[signal].forEach(it => { total_capacitance += Number(it.capacitance) })
      tableData.push(...signalObj[signal].map((item, index) => {
        return {
          ...item,
          segmentLength: index === 0 ? signalObj[signal].length : 0,
          total_capacitance
        }
      }));
      let _colorIndex = colorIndex;
      curveContent.push({
        signal,
        id: `${pcbId}::${signal} `,
        children: [...signalObj[signal].map((item, index) => {
          const color = getColor(_colorIndex)
          _colorIndex += 1;
          return {
            ...item,
            color
          }
        })],
        path: signalObj[signal][0] ? signalObj[signal][0].path : ""
      });
      colorIndex = _colorIndex;
    }

    return { tableData, hasSegment, curveContent, colorIndex, pathObj };
  } catch (error) {
    return { tableData: [], hasSegment: false, curveContent: [], colorIndex, pathObj: {} };
  }
}

function getColor(colorIndex) {
  if (defaultColor[colorIndex]) {
    return defaultColor[colorIndex]
  }
  return defaultColor[colorIndex % defaultColor.length]
}

function parseTotalCapacitanceCSV(csvContent) {
  let frequencies = [], curveData = {}
  if (!csvContent) {
    return { frequencies, curveData };
  }
  const lines = csvContent.match(/[^\r\n]+/g);
  if (!lines || !lines.length) {
    return { frequencies, curveData };
  }
  /* frequency,signal1,signal2,...
 */
  let signalObj = {}

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const words = line.split(",");
    //filter inf
    if (i > 0 && words.filter(item => isNaN(item)).length) {
      continue;
    }

    for (let j = 0; j < words.length; j++) {

      if (i === 0) {
        //title
        // frequency,signal1,signal2,...
        if (j > 0) {
          curveData[words[j]] = [];
          signalObj[j] = words[j];
        }
        continue;
      }

      if (j === 0) {
        //frequency
        frequencies.push(Number(words[j]))
      } else {
        //signal capacitance
        curveData[signalObj[j]].push(Number(words[j]))
      }
    }
  }
  return { frequencies, curveData };
}

export {
  parseTotalTraceCapacitance,
  getTotalTraceCapacitanceTableData,
  parseTotalCapacitanceCSV
}
