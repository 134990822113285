import { call, put, takeEvery, delay, select, takeLatest, cancel, fork } from 'redux-saga/effects';
import {
  UPDATE_PROJECT_MENU,
  ADD_PROJECT,
  CREATE_PROJECT,
  OPEN_PROJECT,
  CLOSE_PROJECT,
  OPEN_PAGE,
  DELETE_PROJECT,
  DELETE_PCB,
  UPDATE_PAGE_LAYOUT,
  CLEAR_CURRENT_PROJECT,
  ADD_VERIFICATION,
  CANCEL_CREARTE_VERIFICATION,
  CREATE_VERIFICATION,
  DELETE_VERIFICATION,
  CHANGE_RENAME_STATUS,
  RENAME_VERIFICATION,
  COPY_VERIFICATION,
  UPDATE_TRASH,
  RENAME_PROJECT,
  LIBRARY_DEFAULT,
  UPDATE_PCB_LAYOUT,
  NEW_SIMULATION_CHANNEL,
  SAVE_REPORT_CONFIG,
  GET_REPORT,
  CLEAR_REPORT_INFO,
  START_LAYOUT_CHECK,
  GET_LAYOUT_CHECK_RESULT,
  RENAME_PRE_LAYOUT,
  SAVE_SERVICE_OPTION,
  UPDATE_FULL_POWER_TREE_DISPLAY
} from './actionType';
import {
  PROJECT,
  PROJECTS,
  PROJECT_CREATE,
  DCR,
  PCB,
  IR_EXPLORER,
  IR_EXPLORER_RESULT,
  IMPEDANCE,
  IMPEDANCE_RESULT,
  VERIFICATION_CREATE,
  PACKAGE,
  MY_TRASH,
  POWER_TREE,
  DESIGN_TREE,
  SINGLE_TREE,
  SIGN_OFF_TEMPLATE,
  CASCADE_SIGN_OFF,
  PCB_PRE_LAYOUT
} from '@/constants/treeConstants';
import { CASCADE_PROJECT_VERSION } from '@/services/Cascade/constants';
import { SINGLE_PAGE_LAYOUT, PCB_ONLY } from '@/constants/layoutConstants';
import {
  updateTreeList,
  updateProjectMenu,
  clearCurrentProject,
  updateExpand,
  saveOpenProjectInfo,
  updateViewList,
  updateSelectKeys,
  updateListStatus,
  updateDefaultDecap,
  updateModelSetting,
  changePCBUpdateStatus,
  updateRunningList,
  updateReportInfo,
  updateComponentSettingVisible,
  updateRootPanelVisible,
  updateCopyLoadingList,
  updateTemplateLog,
} from './action'
import { PROJECTS_INDEX, TRASH_INDEX } from '@/services/Cascade';
import {
  getProjectList,
  createProjectPromise,
  getDefaultProjectTree,
  getProjectChildren,
  getDesignTree,
  updateProjectChild,
  deleteProjectByIds,
  deleteDesignInProject,
  getPCBPage,
  getSetupPage,
  getExplorerTree,
  getExplorerInfo,
  createCascadeChannel,
  deleteCascadeChannel,
  renameCascadeChannel,
  copyCascadeVerification,
  getCascadeTrashList,
  CascadeProjectRename,
  getSimulationChannels,
  getReportFileByFormat,
  getReportStatus,
  saveReportConfigPromise,
  getPowerTreeItem
} from '@/services/Cascade/project'
import { getDCRInfo, uploadDCRErrorMsg, clearCurrentPathRData, savaVerificationId } from '../DCR/action';
import { getIRExplorerInfo, saveIRVerificationId } from '../IRExplorer/action';
import { getPowerTreeSetting } from '../PowerTree/action';
import { getDesignTreeContent } from '../DesignTree/action';
import { message } from 'antd';
import cascadeProjects from '../../../..//services/Cascade/DB/cascadeProject';
import {
  setDefaultDecap,
  getLibSetting,
  SYSTEM_LIBRARY_INDEX,
  SYS_DECAP_INDEX,
  SYS_DECAP_VENDOR_INDEX
} from '@/services/Cascade/library';
import { filterKeys } from '@/services/helper/filterHelper';
import { changeTabMenu, closeTabFooter, openTabFooter } from '../../../MonitorStore/action';
import { getDefaultName } from '@/services/helper/setDefaultName';
import projectDesigns from '@/services/helper/projectDesigns';
import designsDB from '@/services/helper/designConstructor';
import { cleanStatus } from '../../../LayoutExplorer/store/Cascade/actionCreators';
import { cleanUploadPCBStatus, clearUploadData } from '../../CascadeSider/uploadPCB/store/action';
import { strDelimited } from '@/services/helper/split';
import { getImpedanceInfo } from '../Impedance/action';
import { changeDesign } from '../../CascadeSider/uploadPCB/store/action';
import { clearCurrentIRData } from '../IRExplorer/action';
import { clearSimulationInfo } from '../simulation/action';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { CASCADE } from '@/constants/pageType';
import { getViewListBySelectedKey, updateTreeAfterChangePCBLayout } from '../../../../services/helper/filterHelper';
import { getSignOffContent, updateSignOffTemplateListStatus } from '../SignOffTemplate/action';
import { PCB_TOP_BOTTOM, PCB_LEFT_RIGHT } from '../../../../constants/layoutConstants';
import { SIGN_OFF_RUNNING, SIGN_OFF_UPDATE } from '../../../../constants/signOffTemplateStatus';
import { VERIFY_RUNNING } from '@/constants/verificationStatus';
import FileSaver from 'file-saver';
import { getBlob, getResBlob } from '@/services/helper/downloadHelper';
import { changeLayoutDesign, saveCurrentProjectPCB, saveLayoutInfo, saveSimDesigns, startLayoutSimulation, updateLayoutResult } from '../../../LayoutExplorer/LayoutStore/action';
import { LAYOUT_RUNNING } from '../../../../constants/workflowStatus';
import { getLayoutCheckResult, renameDesignPromise } from '../../../../services/api/Design/design';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import { openPreLayoutPage } from '../prelayout/action';
import designConstructor from '../../../../services/helper/designConstructor';
import { OVERVIEW } from '../../../../services/Cascade/Impedance';
import { updateProjectServiceConfig } from '../../../../services/Cascade/project';

function* _updateProjectList(action) {
  const { data = {} } = action;
  const { firstLoad = false, isImport = false, openProjectId } = data;
  let res = null;
  try {
    res = yield call(getProjectList);
  } catch (error) {
    console.error(error);
  }

  if (!res) {
    return;
  }
  const { CascadeReducer: { project } } = yield select();
  let { treeItems } = project;
  const { projects } = getDefaultProjectTree(res)
  treeItems[PROJECTS_INDEX].children = projects;
  yield put(updateTreeList({ treeItems: [...treeItems] }));
  let firstId = '';

  if (firstLoad || isImport) {
    cascadeProjects.clearCache();
    firstId = res && res[0] ? res[0].id : ''
    res.forEach(item => {
      cascadeProjects.addProject(item.id, item);
    })
  }
  if (openProjectId) {
    yield call(_openProject, { id: openProjectId })
  } else if (isImport && firstId) {
    yield call(_openProject, { id: firstId })
  }
}

function* _addProject(action) {
  const { CascadeReducer: { project: { treeItems, expandedKeys } } } = yield select();
  let _treeItems = [...treeItems];
  let dataTypes = _treeItems[PROJECTS_INDEX].children.map(item => item.dataType)

  if (!expandedKeys.includes(PROJECTS)) {
    //expand project tree
    yield put(updateExpand([...expandedKeys, PROJECTS]));
  }

  if (dataTypes.includes(PROJECT_CREATE)) {
    message.error('There are already projects being created.')
    return
  }

  const projectList = cascadeProjects.getProjectValues();
  const name = getDefaultName({ nameList: projectList, defaultKey: "Project" });
  _treeItems[PROJECTS_INDEX].children.unshift(
    {
      id: name,
      name: name,
      key: name,
      dataType: PROJECT_CREATE,
      nodeClass: 'project-create-node'
    }
  )
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _createProject(action) {
  const { data: { name } } = action;
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  const info = {
    id: "",
    name,
    version: CASCADE_PROJECT_VERSION
  }
  try {
    const res = yield call(createProjectPromise, info)
    if (res) {
      // clean project info and clean explorer info
      yield put(clearCurrentProject())
      yield put(updateProjectMenu({ openProjectId: res.id }));
      cascadeProjects.addProject(res.id, res);
    } else {
      yield put(updateProjectMenu({ openProjectId }));
    }
  } catch (error) {
    console.error(error)
    yield put(updateProjectMenu({ openProjectId }));
  }
}

function* _setDefaultDecap(action) {
  const { libraryId, libraryType, name } = action;
  const { CascadeReducer: { project: { treeItems } } } = yield select();
  let _libraryId = libraryId, _libraryType = libraryType, _name = name;
  if (!_libraryId) {
    const sysTree = treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_VENDOR_INDEX].children
    if (sysTree.length) {
      _libraryId = sysTree[0].id;
      _libraryType = sysTree[0].dataType;
      _name = sysTree[0].name
    }
  }
  if (_libraryId) {
    try {
      yield put(updateDefaultDecap(_libraryId, _libraryType, _name))
      yield call(setDefaultDecap, { libraryId: _libraryId, libraryType: _libraryType, name: _name, product: CASCADE });
    } catch (error) {
      console.error(error);
      message.error("Set default decap error");
      return;
    }
  }
}

let projectRunningTask = null;

function* _openProject(action) {
  const { id, afterUploadPCBId, delPcb } = action;
  const { CascadeReducer: { project, CascadeUploadReducer: { visible, uploadProjectId } } } = yield select();
  let { treeItems, expandedKeys } = project;
  const key = `${PROJECT}-${id}`;
  if (!expandedKeys.includes(key)) {
    //close other open project
    expandedKeys = filterKeys(expandedKeys, PROJECT, id)
    //expand current project
    expandedKeys.push(key);
    yield put(updateExpand(expandedKeys));
  }
  let res = null;
  try {
    res = yield call(getProjectChildren, id)
  } catch (error) {
    console.error(error);
    return;
  }
  let _treeItems = [...treeItems];
  const findIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === id);
  if (findIndex < 0) {
    return
  }
  let designs = [], _afterUploadPCBId = afterUploadPCBId, expolorerList = [], packages = [],
    signOffRunningList = [], signOffList = [];
  yield put(updateTemplateLog([]));
  yield put(saveOpenProjectInfo({ id }));
  yield put(saveLayoutInfo({ designId: "", simulationDesigns: [], projectLayout: [] }))
  if (res) {
    designs = getDesignTree(res.designs, PCB);
    packages = getDesignTree(res.packages, PACKAGE);

    const pcbs = [...designs, ...packages]
    yield put(saveCurrentProjectPCB(pcbs));
    const layoutRunning = pcbs.filter(item => item.layoutCheck === LAYOUT_RUNNING).map(item => item.id);
    yield put(saveSimDesigns(layoutRunning));
    if (pcbs.length && pcbs[0].id) {
      yield put(changeLayoutDesign(pcbs[0].id));
    }

    const {
      IR_Explorer = [],
      PathR_Explorer = [],
      Impedance_Explorer = [],
      Power_Tree = [],
      Sign_Off_Template = [],
      Design_Tree = [],
      Single_Power_Tree = []
    } = res.cascadeChannel || {};
    const channels = [
      { list: [...Design_Tree], key: DESIGN_TREE },
      // { list: [...Single_Power_Tree], key: SINGLE_TREE },
      { list: [...Power_Tree, ...Single_Power_Tree], key: POWER_TREE },
      { list: Sign_Off_Template, key: SIGN_OFF_TEMPLATE },
      { list: PathR_Explorer, key: DCR },
      { list: IR_Explorer, key: IR_EXPLORER },
      { list: Impedance_Explorer, key: IMPEDANCE },
    ]

    const { runningList } = getSimulationChannels(channels);
    yield put(updateRunningList(runningList))

    for (let item of channels) {
      const { list, treeTaskList } = item.list && item.list.length ? getExplorerTree(item.list, item.key, id) : { list: [], treeTaskList: [] };
      if (item.key === IMPEDANCE || item.key === DCR) {
        expolorerList.push(treeTaskList);
      } else {
        expolorerList.push(list);
      }
      CascadeChannels.setList(item.key, id, list);
      if (item.key === SIGN_OFF_TEMPLATE) {
        signOffRunningList = list.filter(it => [SIGN_OFF_RUNNING, SIGN_OFF_UPDATE].includes(it.status));
        signOffList = list.map(it => { return { id: item.id, status: item.status } });
      }
    }

    if (projectRunningTask) {
      yield cancel(projectRunningTask)
    }
    if (runningList.length) {
      projectRunningTask = yield fork(runningListLoop)
    }
  }
  designsDB.addDesigns(designs);
  designsDB.addDesigns(packages);
  projectDesigns.set(id, [...designs, ...packages])
  _treeItems[PROJECTS_INDEX].children[findIndex].children = updateProjectChild(_treeItems[PROJECTS_INDEX].children[findIndex].children, [designs, packages, ...expolorerList], id)
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
  let info = {
    currentProjectId: id,
    projectName: _treeItems[PROJECTS_INDEX].children[findIndex].name,
  }
  if (visible && uploadProjectId === id) {
    info.menuType = 'simulation';
    info.tabSelectKeys = ["pcb"];
    _afterUploadPCBId = "upload";
    yield put(openTabFooter());
  }
  if (res && res.designs && res.designs.length && !_afterUploadPCBId) {
    const _length = res.designs.length - 1
    yield put(changeDesign(res.designs[_length].id));
  } else if (!_afterUploadPCBId && res.designs && !res.designs.length) {
    yield put(clearUploadData())
  }

  try {
    const applyModelList = yield call(getLibSetting, id);
    yield put(updateModelSetting(applyModelList))
  } catch (error) {
    console.error(error);
    yield put(updateModelSetting([]))
  }

  if (_afterUploadPCBId) {
    yield put(changeDesign(_afterUploadPCBId));
  }

  if (_afterUploadPCBId || delPcb) {
    yield put(changePCBUpdateStatus());

  }
  //update monitor box info
  yield put(changeTabMenu({ ...info }));

  if (signOffRunningList.length) {
    yield put(updateSignOffTemplateListStatus(signOffList, id));
  }
}

function* changeLogForProject() {
  const { CascadeReducer: { project: { treeItems, openProjectId } } } = yield select();
  let _treeItems = [...treeItems];
  const findIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === openProjectId);
  if (findIndex < 0) {
    return
  }

  let info = {
    currentProjectId: openProjectId,
    projectName: _treeItems[PROJECTS_INDEX].children[findIndex].name,
    currentVerificationId: "",
    verificationName: ""
  }

  yield put(changeTabMenu({ ...info }));
}

function* _closeProject(action) {
  yield put(clearCurrentProject());
  // yield put(closeTabFooter());
  // yield put(uploadDCRErrorMsg([]))
  // yield put(clearCurrentIRData())
}

function* openDCRPage(action) {
  const { id } = action;
  yield put(savaVerificationId(id));
  yield put(getDCRInfo(id))
  yield put(openTabFooter());
}

function* openIRExplorerPage(action) {
  const { id } = action;
  yield put(saveIRVerificationId(id));
  yield delay(100);
  yield put(getIRExplorerInfo());
}

function* openIRExplorerResultPage(action) {
  // yield put()
}

function* openImpedancePage(action) {
  const { id } = action;
  yield delay(100);
  yield put(getImpedanceInfo(id));
}

function* openPowerTreePage(action) {
  const { id } = action;
  yield delay(100);
  yield put(getPowerTreeSetting(id));
}

function* openSignOffPage(action) {
  const { id } = action;
  yield delay(100);
  yield put(getSignOffContent(id));
}

function* openDesignTreePage(action) {
  const { id } = action;
  yield delay(100);
  yield put(getDesignTreeContent(id))
}

function* _openCascadePage(action) {
  const { pageType, id } = action;
  const { CascadeReducer: { project: { layout, viewList: preViewList, selectedKeys } } } = yield select();
  let _viewList = [];
  let _selectedKeys = [...selectedKeys];
  if (layout !== SINGLE_PAGE_LAYOUT && ![PCB, PACKAGE].includes(pageType)) {
    _selectedKeys = _selectedKeys.filter(key => !key.includes('PCB'))
  }
  yield put(updateTemplateLog([]))
  switch (pageType) {
    case PCB:
    case PACKAGE:
      if (layout === SINGLE_PAGE_LAYOUT) {
        yield put(closeTabFooter());
        yield put(updateComponentSettingVisible(false))
        yield put(updateRootPanelVisible(false))
      }
      yield call(getCascadeLayoutResult, { designId: id })
      break;
    case PCB_PRE_LAYOUT:
      yield put(closeTabFooter());
      yield put(openPreLayoutPage(id))
      break;
    case DCR:
      yield call(openDCRPage, { id, pageType });
      break;
    case IR_EXPLORER:
      yield call(openIRExplorerPage, { id, pageType });
      break;
    case IR_EXPLORER_RESULT:
      yield put(closeTabFooter());
      yield call(openIRExplorerResultPage, { id, pageType });
      break;
    case IMPEDANCE:
      yield call(openImpedancePage, { id, pageType });
      break;
    case POWER_TREE:
    case SINGLE_TREE:
      yield call(openPowerTreePage, { id, pageType });
      break;
    case IMPEDANCE_RESULT:
      yield put(closeTabFooter());
      break;
    case SIGN_OFF_TEMPLATE:
      yield call(openSignOffPage, { id });
      break;
    case DESIGN_TREE:
      yield call(openDesignTreePage, { id, pageType });
      break;
    default: break;
  }
  _viewList = getViewListBySelectedKey(_selectedKeys);
  yield put(updateSelectKeys(_selectedKeys));
  if (pageType === IR_EXPLORER_RESULT
    || (pageType !== IR_EXPLORER && _viewList.includes(IR_EXPLORER) && preViewList.includes(IR_EXPLORER_RESULT))) {
    const index = _viewList.findIndex(v => v === IR_EXPLORER);
    _viewList[index] = IR_EXPLORER_RESULT;
  } else if (pageType === IMPEDANCE_RESULT
    || (pageType !== IMPEDANCE && _viewList.includes(IMPEDANCE) && preViewList.includes(IMPEDANCE_RESULT))
  ) {
    const index = _viewList.findIndex(v => v === IMPEDANCE);
    _viewList[index] = IMPEDANCE_RESULT;
  }
  yield put(updateViewList(_viewList));
}

function* _delProject(action) {
  const { data: { id } } = action;
  const { CascadeReducer: { project: { openProjectId }, CascadeUploadReducer: { uploadProjectId } } } = yield select();
  try {
    yield call(deleteProjectByIds, [id])
  } catch (error) {
    console.error(error);
    yield put(updateProjectMenu({ openProjectId }));
    return;
  }
  cascadeProjects.delProject(id);

  yield call(updateTrash);

  if (openProjectId === id) {
    yield put(updateProjectMenu());

    yield put(clearCurrentProject())

    yield put(updateSelectKeys([]));

  } else {
    yield put(updateProjectMenu({ openProjectId }))
  }
  if (uploadProjectId === id) {
    yield put(cleanUploadPCBStatus())
  }
}

function* _delPCB(action) {
  const { data: { id, dataType } } = action;
  try {
    yield call(deleteDesignInProject, [id]);
  } catch (error) {
    console.error(error)
  }
  const { CascadeReducer: { project: { openProjectId, viewList, selectedKeys }, DesignTree: { verificationId, designId } } } = yield select();
  // yield put(updateProjectMenu())
  let _viewList = [...viewList], _selectedKey = [...selectedKeys];

  //close monitor box
  // clean pcb state
  yield put(cleanStatus(id))

  if (selectedKeys.includes(`${dataType}-${id}`)) {
    _viewList = _viewList.filter(item => ![dataType].includes(item));
    _selectedKey = selectedKeys.filter(item => ![`${dataType}-${id}`].includes(item));
  }

  if (designId === id) {
    _viewList = _viewList.filter(item => ![DESIGN_TREE].includes(item));
    _selectedKey = selectedKeys.filter(item => ![`${DESIGN_TREE}-${verificationId}`].includes(item));
  }

  yield put(updateSelectKeys(_selectedKey))

  designsDB.delDesign(id);

  yield put(uploadDCRErrorMsg([]))
  yield put(updateViewList(_viewList));
  yield call(_openProject, { id: openProjectId, delPcb: true });
}

function* _updatePageLayout(action) {
  const { layout, singleType } = action;
  if (layout === SINGLE_PAGE_LAYOUT) {
    yield call(layoutChangeSingle, { singleType })
  } else {
    yield call(layoutChange)
  }
}

function* layoutChangeSingle(action) {
  const { singleType } = action;
  const { CascadeReducer: { project: { openProjectId, viewList, selectedKeys, expandedKeys } } } = yield select();
  if (!openProjectId) {
    return
  }
  const designId = projectDesigns.getAvailablePCBsFirstId(openProjectId);
  let _viewList = [...viewList], _selectedKeys = [...selectedKeys], _filterKeys = [], pageType = null, id = null;
  if (singleType === 'PCB') {
    const _update = getPCBPage({
      _filterKeys,
      viewList,
      projectDesignID: designId
    })
    _filterKeys = _update._filterKeys;
    pageType = _update.pageType;
    id = _update.id;
    yield put(closeTabFooter())

  } else if (singleType === 'setup') {
    const update = getSetupPage({
      _filterKeys,
      viewList,
      projectDesignID: designId,
      projectId: openProjectId
    });

    _filterKeys = update._filterKeys;
    pageType = update.pageType;
    id = update.id;
  }
  if (pageType && id) {
    yield call(_openCascadePage, { pageType, id });
    _selectedKeys = [`${pageType}-${id}`];
    let _expandedKeys = [...expandedKeys]
    if (!_expandedKeys.includes(`${pageType}_group-${openProjectId}`)) {
      _expandedKeys.push(`${pageType}_group-${openProjectId}`)
      yield put(updateExpand(_expandedKeys));
    }
    _viewList = [pageType];
  } else {
    _selectedKeys = _selectedKeys.filter(item => _filterKeys.includes(strDelimited(item, "-", { returnIndex: 0 })));
    _viewList = _viewList.filter(d => _filterKeys.includes(d));
  }
  yield put(updateViewList(_viewList));
  yield put(updateSelectKeys(_selectedKeys));
}

function* layoutChange() {
  const { CascadeReducer: { project: { openProjectId, viewList, selectedKeys, expandedKeys } } } = yield select();
  let _expandedKeys = [...expandedKeys]
  if (!openProjectId || viewList.length !== 1) {
    return;
  }

  let _selectedKeys = [...selectedKeys];
  let newList = [...viewList];
  if ([DCR, IR_EXPLORER, IR_EXPLORER_RESULT, IMPEDANCE, IMPEDANCE_RESULT, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].filter(item => viewList.includes(item)).length) {
    let _designId = "";
    if (viewList.includes(DCR)) {
      const { CascadeReducer: { DCR: { designId } } } = yield select();
      _designId = designId;
    } else if (viewList.includes(IR_EXPLORER) || viewList.includes(IR_EXPLORER_RESULT)) {
      const { CascadeReducer: { IR: { designId } } } = yield select();
      _designId = designId;
    } else if (viewList.includes(IMPEDANCE) || viewList.includes(IMPEDANCE_RESULT)) {
      const { CascadeReducer: { Impedance: { designId, page } } } = yield select();
      _designId = page && page !== OVERVIEW ? page : designId;
    } else if (viewList.includes(DESIGN_TREE) || viewList.includes(POWER_TREE) || viewList.includes(SINGLE_TREE)) {
      const { CascadeReducer: { DesignTree: { designId } } } = yield select();
      _designId = designId;
    } else if (viewList.includes(SIGN_OFF_TEMPLATE)) {
      const { CascadeReducer: { SignOffTemplate: { designId } } } = yield select();
      _designId = designId;
    }
    _designId = _designId ? _designId : projectDesigns.getAvailablePCBsFirstId(openProjectId)
    if (projectDesigns.getAvailableDesignsLength(openProjectId) > 0) {
      const designVendor = designsDB.getDesignVendor(_designId);
      const pcbType = designVendor === PRE_LAYOUT ? PCB_PRE_LAYOUT : PCB;
      newList = [...newList, pcbType];
      _selectedKeys.push(`${pcbType}-${_designId}`);
      let expandedKey = `PCBs-${openProjectId}`;

      if (!_expandedKeys.includes(expandedKey)) {
        _expandedKeys.push(expandedKey)
        yield put(updateExpand(_expandedKeys));
      }
    }
  } else {
    if (projectDesigns.getAvailableDesignsLength(openProjectId) === 0) {
      newList = []
    }
    const dcr = CascadeChannels.getList(DCR, openProjectId);
    const ir = CascadeChannels.getList(IR_EXPLORER, openProjectId);
    const impedance = CascadeChannels.getList(IMPEDANCE, openProjectId);
    const powertree = CascadeChannels.getList(POWER_TREE, openProjectId);
    const designtree = CascadeChannels.getList(DESIGN_TREE, openProjectId);
    const singletree = CascadeChannels.getList(SINGLE_TREE, openProjectId);
    const signOffTemplate = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
    if (dcr.length) {
      newList = [...newList, DCR];
      _selectedKeys.push(`${DCR}-${dcr[0].id}`);
      yield call(openDCRPage, { id: dcr[0].id });
    } else if (ir.length) {
      newList = [...newList, IR_EXPLORER];
      _selectedKeys.push(`${IR_EXPLORER}-${ir[0].id}`);
      yield call(openIRExplorerPage, { id: ir[0].id });
    } else if (impedance.length) {
      newList = [...newList, IMPEDANCE];
      _selectedKeys.push(`${IMPEDANCE}-${impedance[0].id}`);
      yield call(openImpedancePage, { id: impedance[0].id });
    } else if (powertree.length) {
      newList = [...newList, POWER_TREE];
      _selectedKeys.push(`${POWER_TREE}-${powertree[0].id}`);
      yield call(openPowerTreePage, { id: powertree[0].id });
    } else if (singletree.length) {
      newList = [...newList, SINGLE_TREE];
      _selectedKeys.push(`${SINGLE_TREE}-${singletree[0].id}`);
      yield call(openPowerTreePage, { id: singletree[0].id });
    } else if (designtree.length) {
      newList = [...newList, DESIGN_TREE];
      _selectedKeys.push(`${DESIGN_TREE}-${designtree[0].id}`);
      yield call(openDesignTreePage, { id: designtree[0].id });
    } else if (signOffTemplate.length) {
      newList = [...newList, SIGN_OFF_TEMPLATE];
      _selectedKeys.push(`${SIGN_OFF_TEMPLATE}-${signOffTemplate[0].id}`);
      yield call(openDesignTreePage, { id: signOffTemplate[0].id });
    }
  }
  yield put(updateViewList([...newList]));
  yield put(updateSelectKeys(_selectedKeys))
}

function* cleanCurrentProject() {
  yield put(cleanStatus('all'));
  yield put(closeTabFooter());
  yield put(uploadDCRErrorMsg([]))
  yield put(clearCurrentIRData())
  yield put(clearCurrentPathRData())
  yield put(clearSimulationInfo())
}

function* addVerificationByType(action) {
  const { key } = action;
  const [dataType, projectId] = key.split('-')
  const { CascadeReducer: { project: { treeItems, expandedKeys } } } = yield select();
  let _treeItems = [...treeItems];
  let _dataType = dataType.replace('_group', '');
  const { verificationIndex, defaultKey } = getExplorerInfo(_dataType);

  if (verificationIndex < 0) {
    return;
  }
  const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
  const prevList = _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children || [];
  let nameList = CascadeChannels.getList(_dataType, projectId) || [];

  if (_dataType === DESIGN_TREE) {
    nameList = nameList.filter(item => item.dataType === POWER_TREE || item.dataType === SINGLE_TREE)
  }

  let dataTypes = prevList.map(item => item.dataType);
  if (!expandedKeys.includes(key)) {
    //expand project tree
    yield put(updateExpand([...expandedKeys, key]));
  }

  if (dataTypes.includes(VERIFICATION_CREATE)) {
    message.error('There are already tasks being created.')
    return;
  }

  const name = getDefaultName({ nameList, defaultKey });
  _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children = [...prevList, {
    id: name,
    name: name,
    key: `${_dataType}-${projectId}`,
    dataType: VERIFICATION_CREATE,
    nodeClass: 'project-create-node'
  }]
  yield put(updateTreeList({ treeItems: [..._treeItems] }));

}

function* cancelCreateVerification(action) {
  const { dataType, id } = action;
  const { CascadeReducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems];
  const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === id);
  const { verificationIndex } = getExplorerInfo(dataType);
  if (verificationIndex < 0) {
    return;
  }
  _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children.pop();
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* changeRenameStatus(action) {
  const { data, status } = action;
  const dataType = data.dataType.replace('_rename', '');
  const newType = status ? `${dataType}_rename` : dataType;
  const { CascadeReducer: { project: { treeItems, openProjectId } } } = yield select();
  let _treeItems = [...treeItems];
  if (dataType === PROJECT) {
    const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === data.id);
    _treeItems[PROJECTS_INDEX].children[projectIndex].dataType = newType;
  } else if (dataType === PCB_PRE_LAYOUT) {
    const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === openProjectId);
    if (projectIndex < 0) {
      return;
    }

    let typeIndex = 0;
    let pcbIndex = _treeItems[PROJECTS_INDEX].children[projectIndex].children[typeIndex].children.findIndex(item => item.id === data.id);
    if (pcbIndex < 0) {
      typeIndex = 1;
    }
    pcbIndex = _treeItems[PROJECTS_INDEX].children[projectIndex].children[typeIndex].children.findIndex(item => item.id === data.id);
    if (pcbIndex < 0) {
      return;
    }
    _treeItems[PROJECTS_INDEX].children[projectIndex].children[typeIndex].children[pcbIndex].dataType = newType;
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
  } else {
    const { verificationIndex } = getExplorerInfo(dataType);
    const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === openProjectId);
    if (verificationIndex < 0 || projectIndex < 0) {
      return;
    }

    let channelIndex = -1;
    if (data.signOffId) {
      channelIndex = _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children.findIndex(item => item.id === data.signOffId);
      if (channelIndex < 0) {
        return;
      }
      const childIndex = _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children[channelIndex].children.findIndex(item => item.id === data.id);
      if (childIndex < 0) {
        return;
      }
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children[channelIndex].children[childIndex].dataType = newType;

    } else if ([POWER_TREE, SINGLE_TREE].includes(dataType)) {
      channelIndex = _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children.findIndex(item => item.id === data.id);
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children[channelIndex].dataType = newType;
    } else {
      channelIndex = _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children.findIndex(item => item.id === data.id);
      if (channelIndex < 0) {
        return;
      }
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children[channelIndex].dataType = newType;
    }
  }
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

export function* createVerification(action) {
  const { dataType, id, name, open, signOffId } = action;
  const { channelType } = getExplorerInfo(dataType);
  try {
    const res = yield call(createCascadeChannel, { projectId: id, name, channelType, signOffId });
    yield call(updateTreeAfterChangeVerification, { res, dataType, open: open !== undefined ? open : true, name });
    return res;
  } catch (e) {
    console.error(e);
    message.error('Create task failed!');
  }
}

function* deleteVerification(action) {
  const { dataType, id } = action;
  try {
    const { CascadeReducer: { project: { selectedKeys, viewList },
      DCR: { verificationId: dcrVerificationId },
      IR: { verificationId: irVerificationId },
      Impedance: { verificationId: impVerificationId },
      SignOffTemplate: { verificationId: signOffVerificationId },
      DesignTree: { verificationId: treeVerificationId }
    }
    } = yield select();
    let deleteImpedanceSelectedKey = [];

    if (dataType === SIGN_OFF_TEMPLATE) {
      const { CascadeReducer: { project: { openProjectId } } } = yield select();
      const list = CascadeChannels.getList(IMPEDANCE, openProjectId);
      const deleteKeys = list.filter(item => item.signOffId === id).map(item => { return `${IMPEDANCE}-${item.id}` });
      const _deleteKeys = deleteKeys.find(item => selectedKeys.includes(item))
      deleteImpedanceSelectedKey.push(_deleteKeys)

      const DCRList = CascadeChannels.getList(DCR, openProjectId);
      const DCRDeleteKeys = DCRList.filter(item => item.signOffId === id).map(item => { return `${DCR}-${item.id}` });
      const _DCRDeleteKeys = DCRDeleteKeys.find(item => selectedKeys.includes(item))
      deleteImpedanceSelectedKey.push(_DCRDeleteKeys)
    }
    const res = yield call(deleteCascadeChannel, [id]);
    yield call(updateTreeAfterChangeVerification, { res, dataType, updateItem: true });

    const isIncludeKey = selectedKeys.includes(`${dataType}-${id}`);
    if (isIncludeKey || deleteImpedanceSelectedKey.length > 0) {
      const _selectedKeys = selectedKeys.filter(item => item !== `${dataType}-${id}` && !deleteImpedanceSelectedKey.includes(item));
      yield put(updateSelectKeys(_selectedKeys));
      let verificationId = null;
      switch (dataType) {
        case DCR:
          verificationId = dcrVerificationId;
          break;
        case IR_EXPLORER:
          verificationId = irVerificationId;
          break;
        case IMPEDANCE:
          verificationId = impVerificationId;
          break;
        case SIGN_OFF_TEMPLATE:
          verificationId = signOffVerificationId;
          break;
        case POWER_TREE:
        case SINGLE_TREE:
          verificationId = treeVerificationId;
          break;
        default: break;
      }
      if (!verificationId && deleteImpedanceSelectedKey) {
        verificationId = impVerificationId;
      }
      let _view = viewList.filter(item => !(deleteImpedanceSelectedKey && item === IMPEDANCE))
      if (verificationId === id) {
        _view = _view.filter(item => item !== dataType);
      }
      yield put(updateViewList(_view));
      if (verificationId === id) {
        yield call(changeLogForProject)
      }
    }
  } catch (e) {
    console.error(e);
    message.error('Delete task failed!');
  }
}

function* renameVerification(action) {
  const { dataType, id, name } = action;
  try {
    const res = yield call(renameCascadeChannel, { verificationId: id, name });
    yield call(updateTreeAfterChangeVerification, { res, dataType, updateItem: true });
  } catch (e) {
    console.error(e);
    message.error('Rename task failed!');
  }
}

function* copyVerification(action) {
  const { dataType, id, name } = action;
  try {
    const { CascadeReducer: { project: { copyLoadingList } } } = yield select()
    copyLoadingList.push(id)
    yield put(updateCopyLoadingList(copyLoadingList))
    const res = yield call(copyCascadeVerification, { name, verificationId: id });
    const { CascadeReducer: { project: { copyLoadingList: _copyLoadingList } } } = yield select()
    const newCopyLoadingList = _copyLoadingList.filter(item => item !== id);
    yield put(updateCopyLoadingList(newCopyLoadingList))
    yield call(updateTreeAfterChangeVerification, { res, dataType, updateItem: true });
  } catch (e) {
    const { CascadeReducer: { project: { copyLoadingList: _copyLoadingList } } } = yield select()
    const newCopyLoadingList = _copyLoadingList.filter(item => item !== id);
    yield put(updateCopyLoadingList(newCopyLoadingList))
    console.error(e);
    message.error('Copy task failed!');
  }
}

function* renamePreLayout(action) {
  const { id, name } = action;
  try {
    try {
      const res = yield call(renameDesignPromise, id, name);
      designConstructor.delDesign(res.id);
      const { CascadeReducer: { project: { openProjectId } } } = yield select();
      yield call(_openProject, { id: openProjectId })
    } catch (error) {
      console.error(error);
    }
  } catch (e) {
    console.error(e);
    message.error('Rename pre-layout failed!');
  }
}

export function* updateTreeAfterChangeVerification(action) {
  const { res = {}, dataType, open = false, name, updateItem = false } = action;
  if (res) {
    const { cascadeChannel } = res;
    const { verificationIndex, channelType } = getExplorerInfo(dataType);
    const { CascadeReducer: { project: { treeItems, openProjectId } } } = yield select();
    let channels = cascadeChannel[channelType] || [];
    if (dataType === POWER_TREE) {
      const { channelType } = getExplorerInfo(SINGLE_TREE);
      channels.push(...(cascadeChannel[channelType] || []))
    }
    const { list, treeTaskList } = channels.length ? getExplorerTree(channels, dataType, openProjectId) : [];
    let _treeItems = [...treeItems];
    const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === openProjectId);
    if (verificationIndex < 0) {
      return;
    }
    CascadeChannels.setList(dataType, openProjectId, list);
    if (dataType === DESIGN_TREE) {
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children = getPowerTreeItem(list, openProjectId, DESIGN_TREE);
    } else if (dataType === POWER_TREE || dataType === SINGLE_TREE) {
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children = getPowerTreeItem(list, openProjectId, POWER_TREE);
    } else if (dataType === SINGLE_TREE) {
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children = getPowerTreeItem(list, openProjectId, SINGLE_TREE);
    } else {
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[verificationIndex].children = (dataType === IMPEDANCE || dataType === DCR) ? treeTaskList : list;
    }

    if (updateItem && dataType === SIGN_OFF_TEMPLATE) {
      const { verificationIndex: impedanceIndex, channelType: impedanceType } = getExplorerInfo(IMPEDANCE);
      const { list: impedanceList, treeTaskList } = cascadeChannel[impedanceType] ? getExplorerTree(cascadeChannel[impedanceType], IMPEDANCE, openProjectId) : [];
      CascadeChannels.setList(IMPEDANCE, openProjectId, impedanceList);
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[impedanceIndex].children = treeTaskList;

      const { verificationIndex: DCRIndex, channelType: DCRType } = getExplorerInfo(DCR);
      const { list: DCRList, treeTaskList: DCRTreeTaskList } = cascadeChannel[DCRType] ? getExplorerTree(cascadeChannel[DCRType], DCR, openProjectId) : [];
      CascadeChannels.setList(DCR, openProjectId, DCRList);
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[DCRIndex].children = DCRTreeTaskList;
    }
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
    yield put(updateListStatus(true));
    if (open) {
      const { CascadeReducer: { project: { selectedKeys } } } = yield select();
      const currentItem = list.find(item => item.name === name);
      const id = currentItem ? currentItem.id : null;
      if (!id) {
        return;
      }
      const _selectedKeys = [...selectedKeys.filter(item => !item.includes(PCB) && !item.includes(PACKAGE)
        && !item.includes(DCR) && !item.includes(IR_EXPLORER)
        && !item.includes(IMPEDANCE) && !item.includes(POWER_TREE) && !item.includes(SIGN_OFF_TEMPLATE)
        && !item.includes(DESIGN_TREE) && !item.includes(SINGLE_TREE))
        , `${dataType}-${id}`]
      yield put(updateSelectKeys(_selectedKeys));
      yield call(_openCascadePage, { pageType: dataType, id })
    }
  }
}

function* updateTrash() {
  try {
    const trash = yield call(getCascadeTrashList);
    const { CascadeReducer: { project } } = yield select();
    let { treeItems } = project;

    let trashList = []
    if (trash.length) {
      trashList = trash.map(item => ({
        name: item.name,
        key: `project-${item.id}`,
        id: item.id,
        ddrType: item.ddrType,
        dataType: MY_TRASH
      }));
    }

    treeItems[TRASH_INDEX].children = trashList;
    yield put(updateTreeList({ treeItems: [...treeItems] }));
  } catch (error) {
    console.error(error)
  }
}

function* renameProject(action) {
  const { id, name } = action;
  try {
    yield call(CascadeProjectRename, { projectId: id, name });
    cascadeProjects.updateProjectName(id, name);
    const { CascadeReducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];
    const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === id);
    _treeItems[PROJECTS_INDEX].children[projectIndex].name = name;
    _treeItems[PROJECTS_INDEX].children[projectIndex].dataType = PROJECT;
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
  } catch (e) {
    console.error(e);
    message.error('Project rename failed!');
  }
}

function* _updatePCBLayout(action) {
  const { pcbLayout, prePcbLayout } = action;
  // prePcbLayout b,c -> a: close PCB
  const { CascadeReducer: { project } } = yield select();
  let { viewList, selectedKeys } = project;
  if (pcbLayout === PCB_ONLY && [PCB_TOP_BOTTOM, PCB_LEFT_RIGHT].includes(prePcbLayout)) {
    const data = updateTreeAfterChangePCBLayout(selectedKeys, viewList);
    if (data) {
      const { _selectedKeys, _viewList } = data;
      yield put(updateSelectKeys(_selectedKeys));
      yield put(updateViewList(_viewList));
    }
  }
}

function* runningListLoop() {
  const { CascadeReducer: { project: { runningList } } } = yield select();

  while (runningList.length) {
    // delay call api
    yield delay(5000);
    const { CascadeReducer: { project: { runningList: lastRunning, openProjectId, treeItems } } } = yield select();
    try {
      let res = yield call(getProjectChildren, openProjectId);
      if (res) {
        const {
          IR_Explorer = [],
          PathR_Explorer = [],
          Impedance_Explorer = [],
          Power_Tree = [],
          Sign_Off_Template = [],
          Design_Tree = [],
          Single_Power_Tree = [] } = res.cascadeChannel || {};

        const channels = [
          { list: Design_Tree, key: DESIGN_TREE },
          { list: Sign_Off_Template, key: SIGN_OFF_TEMPLATE },
          { list: PathR_Explorer, key: DCR },
          { list: IR_Explorer, key: IR_EXPLORER },
          { list: Impedance_Explorer, key: IMPEDANCE },
          { list: [...Power_Tree, ...Single_Power_Tree], key: POWER_TREE },
        ]

        const { runningList, finishedList } = getSimulationChannels(channels, lastRunning);
        yield put(updateRunningList(runningList));

        if (finishedList.length) {
          let _treeItems = [...treeItems];
          const findIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === openProjectId);
          finishedList.forEach(item => {
            const { verificationIndex } = getExplorerInfo(item.key);
            const itemIndex = _treeItems[PROJECTS_INDEX].children[findIndex].children[verificationIndex].children.findIndex(c => c.id === item.id);
            if (itemIndex > -1) {
              _treeItems[PROJECTS_INDEX].children[findIndex].children[verificationIndex].children[itemIndex].status = item.status
            }
          })
          yield put(updateTreeList({ treeItems: [..._treeItems] }));
        }

        if (!runningList.length) {
          break;
        }
      }
    } catch (error) {
      console.error(error)
    }

  }
}

function* updateSimulationStatus(action) {
  const { verificationId, key } = action;
  const { CascadeReducer: { project: { runningList: lastRunning, openProjectId, treeItems } } } = yield select();
  const { verificationIndex } = getExplorerInfo(key);
  if (verificationIndex > -1) {
    let _treeItems = [...treeItems];
    const findIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === openProjectId);
    if (key === POWER_TREE || key === SINGLE_TREE) {
      let channelIndex = _treeItems[PROJECTS_INDEX].children[findIndex].children[verificationIndex].children.findIndex(item => item.id === verificationId);
      if (_treeItems[PROJECTS_INDEX].children[findIndex].children[verificationIndex].children[channelIndex]) {
        _treeItems[PROJECTS_INDEX].children[findIndex].children[verificationIndex].children[channelIndex].status = VERIFY_RUNNING;
      }
    } else {
      const itemIndex = _treeItems[PROJECTS_INDEX].children[findIndex].children[verificationIndex].children.findIndex(c => c.id === verificationId);
      if (itemIndex > -1) {
        _treeItems[PROJECTS_INDEX].children[findIndex].children[verificationIndex].children[itemIndex].status = VERIFY_RUNNING;
        yield put(updateTreeList({ treeItems: [..._treeItems] }));
      }
    }

    yield put(updateRunningList([...lastRunning, { id: verificationId, key }]));
    if (projectRunningTask) {
      yield cancel(projectRunningTask)
    }
    projectRunningTask = yield fork(runningListLoop)
  }
}

let reportTask = null;
function* _getCascadeReport(action) {
  const { projectId, format, mime, fileName, reportTypeName } = action;
  reportTask = yield fork(_getRockyReportFlow, { projectId, format, mime, fileName, reportTypeName });
}

function* _getRockyReportFlow(action) {
  const { projectId, format, mime, fileName, reportTypeName } = action;
  let status = 'running';
  let progress = 0;
  while (status === 'running') {
    yield delay(3000);
    try {
      const res = yield call(getReportStatus, projectId);
      status = res.status;
      progress = res.progress;
      if (progress <= 97) {
        yield put(updateReportInfo({ reportProgress: progress }));
      }
    } catch (error) {
      status = 'failed';
    }
  }

  yield put(updateReportInfo({ reportProgress: 98 }));
  yield call(_getReportFileByFormat, { projectId, format, mime, fileName, reportTypeName });
}

function* _getReportFileByFormat(action) {
  const { projectId, format, mime, fileName, reportTypeName } = action;
  let url = null, blob = null;

  if (reportTypeName === CASCADE_SIGN_OFF) {
    //download zip
    const token = localStorage.getItem('token')
    url = `api/v3/cascade/${projectId}/report/${format}?access_token=${token}`;
    blob = yield call(getResBlob, url);
  } else {
    //download pptx or pdf
    url = yield call(getReportFileByFormat, { projectId, format, mime });
    blob = yield call(getBlob, url);
  }
  if (url && blob) {
    const { CascadeReducer: { project: { openProjectId } } } = yield select();
    if (openProjectId !== projectId) {//open other project
      return;
    }

    FileSaver.saveAs(blob, fileName);
    yield put(updateReportInfo({
      reportProgress: 100,
      reportMessage: `Download report in ${format.toUpperCase()} format successfully!`
    }));

  } else {
    yield put(updateReportInfo({
      reportMessage: `Download report in ${format.toUpperCase()} format failed!`
    }));
  }
  yield delay(1000);
  yield put(updateReportInfo({ reportVisible: false, reportProgress: 0 }));
}

function* _clearProjectReportInfo() {
  //cancel prev report task
  if (reportTask) {
    yield cancel(reportTask);
  }
}

function* _saveReportConfigSetup() {
  const { CascadeReducer: { project: { openProjectId, reportInfo } } } = yield select();
  let config = reportInfo ? reportInfo.reportConfig : null;
  if (!config || Object.keys(config).length === 0) {
    return;
  }

  // save report config to server
  try {
    yield call(saveReportConfigPromise, { projectId: openProjectId, config });
  } catch (error) {
    console.error(error);
  }
}

function* startLayoutCheck(action) {
  const { designItem } = action;
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  yield put(changeTabMenu({
    tabSelectKeys: ['layout'],
    menuType: 'simulation',
    projectId: openProjectId,
  }));
  yield put(openTabFooter());
  const { id } = designItem;
  yield put(startLayoutSimulation(id, CASCADE))
}

function* getCascadeLayoutResult(action) {
  const { designId } = action;
  try {
    const { LayoutReducer: { results } } = yield select();
    if (!results[designId]) {
      const result = yield call(getLayoutCheckResult, designId)
      yield put(updateLayoutResult(designId, result))
    }
  } catch (error) {
    console.error("Get layout error result failed: ", error)
  }
}

function* _saveServiceOption() {
  const { CascadeReducer: { project: { openProjectId, serviceOptions } } } = yield select();
  try {
    yield call(updateProjectServiceConfig, { projectId: openProjectId, options: serviceOptions });
  } catch (error) {
    message.error('Update service options failed! ' + error)
    return;
  }
}

function* updateFullPowerTreeDisplay() {
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  if (openProjectId) {
    yield call(_openProject, { id: openProjectId })
  }
}

function* projectSaga() {
  yield takeEvery(UPDATE_PROJECT_MENU, _updateProjectList);
  yield takeEvery(ADD_PROJECT, _addProject);
  yield takeEvery(CREATE_PROJECT, _createProject);
  yield takeEvery(OPEN_PROJECT, _openProject);
  yield takeEvery(CLOSE_PROJECT, _closeProject);
  yield takeEvery(OPEN_PAGE, _openCascadePage);
  yield takeEvery(DELETE_PROJECT, _delProject);
  yield takeEvery(DELETE_PCB, _delPCB);
  yield takeEvery(UPDATE_PAGE_LAYOUT, _updatePageLayout);
  yield takeEvery(CLEAR_CURRENT_PROJECT, cleanCurrentProject);
  yield takeEvery(ADD_VERIFICATION, addVerificationByType);
  yield takeEvery(CANCEL_CREARTE_VERIFICATION, cancelCreateVerification);
  yield takeEvery(CREATE_VERIFICATION, createVerification);
  yield takeEvery(DELETE_VERIFICATION, deleteVerification);
  yield takeEvery(CHANGE_RENAME_STATUS, changeRenameStatus);
  yield takeEvery(RENAME_VERIFICATION, renameVerification);
  yield takeEvery(COPY_VERIFICATION, copyVerification);
  yield takeEvery(UPDATE_TRASH, updateTrash);
  yield takeEvery(RENAME_PROJECT, renameProject);
  yield takeEvery(LIBRARY_DEFAULT, _setDefaultDecap);
  yield takeLatest(UPDATE_PCB_LAYOUT, _updatePCBLayout);
  yield takeEvery(NEW_SIMULATION_CHANNEL, updateSimulationStatus);
  yield takeEvery(GET_REPORT, _getCascadeReport);
  yield takeEvery(CLEAR_REPORT_INFO, _clearProjectReportInfo);
  yield takeEvery(SAVE_REPORT_CONFIG, _saveReportConfigSetup);
  yield takeEvery(START_LAYOUT_CHECK, startLayoutCheck);
  yield takeEvery(GET_LAYOUT_CHECK_RESULT, getCascadeLayoutResult);
  yield takeEvery(RENAME_PRE_LAYOUT, renamePreLayout);
  yield takeEvery(SAVE_SERVICE_OPTION, _saveServiceOption);
  yield takeEvery(UPDATE_FULL_POWER_TREE_DISPLAY, updateFullPowerTreeDisplay);
}

export default projectSaga