import {
  CHANGE_VERIFICATION_LIST,
  UPDATE_PDN_PROGRESS,
  UPDATE_SINGLE_MONITOR,
  SINGLE_VERIFY_INFO,
  CLEAN_SINGLE_PROGRESS,
  CLEAN_MONITOR,
  CHANGE_UPLOAD_MES,
  RESULT_EXIST,
  UPDATE_END_MSG,
  PDN_CHECK_ERROR,
  WAITING_INDEX,
  SAVE_CURRENT_LOG,
  PDN_STACKUP_CHECK_ERROR,
  VRM_ERROR_INFO,
  DECAP_ERROR_INFO,
  SAVE_CURRENT_CHECK_ID,
  UPDATE_START_MSG,
  SAVE_CURRENT_PROFILE
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  verificationId: null,
  selectedKeys: [],
  singleProgress: [],
  singleVerifyInfo: null,
  singleMonitor: [],
  uploadDebugMes: null,
  existResult: false,
  verifyDisabled: false,
  errorCheck: null,
  waitingTime: null,
  log: null,
  waitingQueue: [],
  stackupError: null,
  vrmError: null,
  decapError: null,
  currentCheckDataId: null,
  startMsg: null,
  endMsg: null,
}

export const simulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_VERIFICATION_LIST:
      return {
        ...state,
        selectedKeys: action.selectedKeys
      }
    case UPDATE_PDN_PROGRESS:
      const { singleProgress } = state;
      let progress = [...singleProgress];
      const index = singleProgress.findIndex(item => item.verificationId === action.verificationId);
      if (index > -1) {
        progress[index].progress = action.progress;
      } else {
        progress.push({
          progress: action.progress,
          verificationId: action.verificationId
        })
      }
      return {
        ...state,
        singleProgress: [...progress]
      };
    case CLEAN_SINGLE_PROGRESS:
      let progressInfo = [...state.singleProgress];
      const i = progressInfo.findIndex(item => item.verificationId === action.verificationId);
      if (i > -1) {
        progressInfo.splice(i, 1);
      }
      return {
        ...state,
        singleProgress: [...progressInfo]
      }
    case SINGLE_VERIFY_INFO:
      return {
        ...state,
        singleVerifyInfo: {
          verificationId: action.verificationId,
          workflowId: action.workflowId
        }
      }
    case UPDATE_SINGLE_MONITOR:
      const { singleMonitor } = state;
      let monitor = [...singleMonitor];
      const n = monitor.findIndex(item => item.verificationId === action.verificationId);
      if (n > -1) {
        monitor[n].monitor = action.monitor;
      } else {
        monitor.push({
          monitor: action.monitor,
          verificationId: action.verificationId,
          workflowId: action.workflowId
        })
      }
      return {
        ...state,
        singleMonitor: [...monitor]
      }
    case CLEAN_MONITOR:
      let monitors = [...state.singleMonitor];
      const j = monitors.findIndex(item => item.verificationId === action.verificationId);
      if (j > -1) {
        monitors.splice(j, 1);
      }
      return {
        ...state,
        singleMonitor: [...monitors]
      }
    case CHANGE_UPLOAD_MES:
      return {
        ...state,
        uploadDebugMes: action.mes
      }
    case RESULT_EXIST:
      return {
        ...state,
        existResult: action.existResult
      }
    case UPDATE_END_MSG:
      return {
        ...state,
        endMsg: action.msg
      }
    case PDN_CHECK_ERROR:
      return {
        ...state,
        errorCheck: action.errorCheck
      }
    case WAITING_INDEX:
      return {
        ...state,
        waitingQueue: action.waitingQueue
      }
    case SAVE_CURRENT_LOG:
      return {
        ...state,
        log: action.log
      }
    case PDN_STACKUP_CHECK_ERROR:
      return {
        ...state,
        stackupError: action.stackupError
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case VRM_ERROR_INFO:
      return {
        ...state,
        vrmError: action.errorCheck ? { errorCheck: action.errorCheck, verificationId: action.verificationId } : null
      }
    case DECAP_ERROR_INFO:
      return {
        ...state,
        decapError: action.errorCheck ? { errorCheck: action.errorCheck, verificationId: action.verificationId } : null
      }
    case SAVE_CURRENT_CHECK_ID:
      return {
        ...state,
        currentCheckDataId: action.verificationId
      }
    case UPDATE_START_MSG:
      return {
        ...state,
        startMsg: action.msg
      }
    case SAVE_CURRENT_PROFILE:
      return {
        ...state,
        profile: action.profile
      }
    default: return state
  }
};