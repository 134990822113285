import PartInfo from './PartInfo';
import PartPin from './PartPin';
import SymbolGroup from './SymbolGroup';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import StringList from '../utility/StringList';

var CePart = function () {
  this.mInfo = new PartInfo();
  this.mPinList = [];
  this.mSymbolGrps = [];
  this.mFutprtList = new StringList();
};

CePart.prototype.GetPartInfo = function () {
  return this.mInfo;
}

CePart.prototype.GetName = function () {
  return this.mInfo.mPartName;
}

CePart.prototype.SetName = function (name) {
  this.mInfo.mPartName = name;
}

CePart.prototype.AddFootPrintSymbol = function (symbolID) {
  this.mFutprtList && this.mFutprtList.push(symbolID);
  return true;
}

CePart.prototype.AddFootPrintSymbol = function (futSymbols) {
  this.mFutprtList && this.mFutprtList.push(futSymbols);
}

CePart.prototype.AddSymbolGroup = function (grpID, identifier, symbolName) {
  this.mSymbolGrps.push(new SymbolGroup(grpID, identifier, symbolName));
  return true;
}

CePart.prototype.AddSymbolGroup = function (symbolGrpData) {
  // var splitRule = new StrSplitRule(" ,");
  // var strItems = StringHelper.SplitString(symbolGrpData, splitRule);
  // var grpID = strItems.pop_front();
  // this.mSymbolGrps.push(new SymbolGroup(grpID, strItems));
  return true;
}

CePart.prototype.SetPartType = function (partTp) {
  this.mInfo.mPartType = partTp;
}

CePart.prototype.SetPinList = function (pinList) {
  this.mPinList = pinList;
}

CePart.prototype.GetFootPrintSymbol = function (mPartDB) {
  if (!this.mFutprtList || this.mFutprtList.size() === 0) {
    console.log("Part has null foorptint symbol: " + this.mInfo.mPartName);
    return null;
  }
  var futprtID = this.mFutprtList.get(0);
  var fpSynbol = mPartDB.GetFootPrintSymbol(futprtID);
  if (fpSynbol == null) {
    console.log('footprint')
    // throw new Error("Footprint symbol does not exist");
    console.error("Footprint symbol does not exist")
  }


  return fpSynbol;
}

// CePart.prototype.SetOutline = function (outline) {
//   var fpSynbol = this.GetFootPrintSymbol();
//   fpSynbol.AddPartOutline(outline);
// }

// CePart.prototype.SetPadGeomList = function (geomList) {
//   var fpSynbol = this.GetFootPrintSymbol();
//   fpSynbol.SetPadGeomList(geomList);
// }

CePart.prototype.SetProperties = function (attributes) {
  this.mInfo.mPhyProps = attributes;
}

// CePart.prototype.GetPartOutline = function () {
//   var fpSynbol = this.GetFootPrintSymbol();
//   return fpSynbol.GetPartOutline();
// }

CePart.prototype.WriteIntoIODataNode = function () {
  var myBlock = new CeIODataBlock("Part");
  myBlock.AddBlockNode(this.mInfo.WriteIntoIODataNode());

  if (this.mFutprtList != null && this.mFutprtList.size() > 0)
    myBlock.AddDataItem("FootPrintSymbols", this.mFutprtList);

  // for(var sblgrp in this.mSymbolGrps){
  //     var sblgrpData = sblgrp.GetDataString();
  //     myBlock.AddDataItem("SymbolGroup", sblgrpData);
  // }

  var pinsBlock = new CeIODataBlock("PinList");
  for (var i = 0; i < this.mPinList.length; i++) {
    pinsBlock.AddBlockNode(this.mPinList[i].WriteIntoIODataNode());
  }
  myBlock.AddBlockNode(pinsBlock);
  return myBlock;
}

CePart.prototype.ReadFromIODataNode = function (myBlock) {
  var infoBlock = myBlock.GetSubBlock("PartInfo");
  this.mInfo.ReadFromIODataNode(infoBlock);

  this.mFutprtList = myBlock.GetItemStringList("FootPrintSymbols");

  var sybgrpItems = myBlock.GetAllBlockItemsOfSameName("SymbolGroup");
  for (var i = 0; i < sybgrpItems.length; i++) {
    var grpData = sybgrpItems[i].GetItemValue();
    if (grpData != null && grpData.size() >= 2) {
      var grpId = grpData.pop_front();
      var symbolGrp = new SymbolGroup(grpId, grpData);
      this.mSymbolGrps.push(symbolGrp);
    }
  }

  var pinBlocks = myBlock.GetSubBlock("PinList");
  var pinBlkList = pinBlocks.GetAllSubBlocks();
  for (var i = 0; i < pinBlkList.length; i++) {
    var partPin = new PartPin();
    partPin.ReadFromIODataNode(pinBlkList[i]);
    this.mPinList.push(partPin);
  }
  return true;
}

CePart.prototype.AddPin = function (id, name, type, swpgrp) {
  var pin = new PartPin(id, name, type, swpgrp);
  this.mPinList.push(pin);
}

CePart.prototype.DelPin = function (pinName) {
  for (var i = 0; i < this.mPinList.length; i++) {
    if (pinName == this.mPinList[i].mName) {
      this.mPinList.splice(i, 1);
      break;
    }
  }
}

CePart.prototype.DelPins = function (pinList) {
  for (var i = 0; i < pinList.length; i++) {
    this.DelPin(pinList[i]);
  }
}

CePart.prototype.GetPinByID = function (pinID) {
  for (var i = 0; i < this.mPinList.length; i++) {
    if (pinID.compareToIgnoreCase(this.mPinList[i].GetPinID()) == 0) {
      return this.mPinList[i];
    }
  }
  return null;
}

CePart.prototype.GetPinByName = function (pinName) {
  for (var i = 0; i < this.mPinList.length; i++) {
    if (pinName.compareToIgnoreCase(this.mPinList[i].mName) == 0) {
      return this.mPinList[i];
    }
  }
  return null;
}

CePart.prototype.GetUsedSymbols = function () {
  var symbols = new StringList();
  for (var i = 0; i < this.mSymbolGrps.length; i++) {
    symbols.push(this.mSymbolGrps[i].GetUsedSymbolList());
  }
  return symbols;
}

CePart.prototype.GetUsedFootprints = function () {
  return this.mFutprtList;
}

CePart.prototype.GetPinCount = function () {
  return this.mPinList.length;
}

CePart.prototype.GetPinByPinNumber = function (num) {
  return this.mPinList.find(d => d.mNumber == num);
}

export default CePart;