import React, { Component } from 'react';
import { connect } from 'react-redux';
import MultiInterfaceSetupTable from './multiSetupTable';
import { Select } from 'antd';
import { ALL_INTERFACES } from '../../../services/Sierra/multiInterfaceSetup';
import { changeMultiSetupGroup } from '../store/multiInterface/action';
import './index.css';

class MultiInterfaceSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  changeGroup = ({ value }) => {
    this.props._changeMultiSetupGroup(value);
  }

  render() {
    const { currentProjectVerifications, multiSetupGroup, allDataLength, pageSize } = this.props;
    const groups = [...new Set(currentProjectVerifications.map(item => item.group).filter(item => !!item))];
    return (
      <div className='sierra-content-setting sierra-multi-setup-content-setting'>
        {groups.length ? <div className="sierra-content-group-selection" style={{ width: allDataLength <= pageSize ? "100%" : null }}>
          <span>Group</span>
          <Select
            className="aurora-select"
            labelInValue
            value={{ key: multiSetupGroup || "", label: multiSetupGroup === ALL_INTERFACES ? "All Interfaces" : multiSetupGroup }}
            onChange={this.changeGroup}
          >
            {groups.map(item => <Select.Option key={item}>{item}</Select.Option>)}
            <Select.Option key={ALL_INTERFACES}>All Interfaces</Select.Option>
          </Select>
        </div> : null}
        <MultiInterfaceSetupTable />
      </div>
    )
  }
}

const mapState = (state) => {
  const { SierraReducer: { project: { currentProjectVerifications }, multiInterfaceSetup: { multiSetupGroup, setupData } } } = state;
  const { pageSize, allDataLength } = setupData || {};
  return {
    currentProjectVerifications,
    multiSetupGroup,
    allDataLength,
    pageSize
  };
}

const mapDispatch = (dispatch) => ({
  _changeMultiSetupGroup(group) {
    dispatch(changeMultiSetupGroup(group))
  }
})

export default connect(mapState, mapDispatch)(MultiInterfaceSetup);
