import React, { Component, Fragment, createRef } from 'react';
import { VerticalLeftOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import {
  getWaitingMsg,
  getSimulationStatus,
  NO_SIMULATION,
  getStartMsg,
  getLogListDisplay,
  isSimulationRunning
} from '@/services/workflow/workflowHelper';
import {
  displayPortsError,
  displayStackupError,
  displayReplaceMonitor,
  monitorDisplayRender,
  displayErrorList,
  displayReplaceMonitorList,
  displayErrors
} from './MonitorErrorCheck';
import ErrorCheck from '../../ErrorCheck';

class SimulationContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lockScroll: true
    }
    this.lockRef = createRef();
  }

  scrollToBottom = () => {
    if (this.lockRef) {
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }

  componentDidUpdate(prevProps) {
    const { log, monitorMenu, startMsg, tabVisible, } = this.props;
    const { log: prevlog, startMsg: prevstartMsg } = prevProps;
    const { lockScroll } = this.state;

    if (tabVisible && monitorMenu !== prevProps.monitorMenu && this.lockRef) {
      this.lockRef.scrollTop = 0;
      this.setState({
        lockScroll: true
      })
    }

    if (tabVisible && !lockScroll) {
      this.scrollToBottom();
    }

    if ((log !== prevlog) || (startMsg && startMsg !== prevstartMsg) || (monitorMenu && monitorMenu !== prevProps.monitorMenu)) {
      this.hasLockScroll();
    }
  }

  hasLockScroll = () => {
    if (this.lockRef) {
      const { waitingIndex, progress } = this.props;
      const { scrollHeight, scrollTop, clientHeight } = this.lockRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5
        && (scrollHeight !== clientHeight || isSimulationRunning(waitingIndex, progress))
      ) {
        this.setState({ lockScroll: false });
      }
      else { this.setState({ lockScroll: true }); }
    }
  }

  getMonitorDisplay = () => {
    const { monitor = [], displayMonitorList, waitingIndex, error } = this.props;
    return !error && waitingIndex < 0 && monitor.length && (!displayMonitorList || !displayMonitorList.length);
  }

  render() {

    return (
      <div className='aurora-monitor-list-main'>
        {this.monitorRender()}
        {this.backBottomIcon()}
      </div>
    )
  }

  monitorRender = () => {
    const {
      waitingIndex,
      startMsg,
      endMsg,
      log,
      progress,
      error,
      errorCheckList = [],
      displayMonitorList = [],
      monitor,
      currentVerificationId,
      monitorMenu
    } = this.props;
    const logListDisplay = getLogListDisplay(this.props);
    if (logListDisplay && displayMonitorList && displayMonitorList.length) {
      const monitorInfo = displayMonitorList.find(it => it.verificationId === monitorMenu);

      return monitorInfo ?
        this.monitorContent({
          startMsg: getStartMsg(monitorInfo),
          endMsg,
          monitorDisplay: true,
          monitor: monitorInfo.logs,
          props: { ...this.props }
        }, monitorInfo.verificationId) : null
    } else {
      const waitingMsg = getWaitingMsg(waitingIndex);
      const monitorDisplay = this.getMonitorDisplay();
      const simulationStatus = getSimulationStatus({ waitingIndex, progress });
      const logDisplay = !monitorDisplay && !error && !errorCheckList.length && simulationStatus === NO_SIMULATION && log;

      return this.monitorContent({
        waitingMsg,
        logDisplay,
        startMsg,
        endMsg,
        monitorDisplay,
        monitor,
        props: { ...this.props }
      }, currentVerificationId)
    }
  }

  monitorContent = (params, verificationId) => {
    const {
      error,
      replaceMonitor = [],
      designStackupErrorList = [],
      portGenerateErrors = [],
      portGenerateWarnings = [],
      setupUpdateMsgList = [],
      errorCheckList = [],
      currentVerificationId,
      endToEndReplaceMonitorList,
      seasimConfigErrors = [],
      preLayoutErrors = [],
      adsConfigErrors = []
    } = this.props;
    return <div className="my-aurora-monitor aurora-simulation-monitor"
      onScroll={() => this.hasLockScroll()}
      ref={c => { this.lockRef = c; }}
    >
      <pre className="my-aurora-monitor-pre">
        {verificationId === currentVerificationId ?
          <Fragment>
            {/* pcb replace messages */}
            {displayReplaceMonitor(replaceMonitor, "Channel update:")}
            {/* end to end channel update setup message list */}
            {displayReplaceMonitor(setupUpdateMsgList, "Setup update:")}
            {/* end to end channel children channel replace update setup message list*/}
            {displayReplaceMonitorList(endToEndReplaceMonitorList, "PCB Channels update:")}
            {/* extraction ports errors and warnings */}
            {displayPortsError({ portGenerateErrors, portGenerateWarnings })}
            {/* stackup error */}
            {displayStackupError(designStackupErrorList)}
            {/* pre layout error */}
            {displayErrors({
              errors: preLayoutErrors,
              title: "Pre layout setup:",
              className: "aurora-error-content"
            })}
            {/* seasim error */}
            {displayReplaceMonitor(seasimConfigErrors, "Compliance setup:", "aurora-error-content")}
            {/* ads error */}
            {displayReplaceMonitorList(adsConfigErrors, "Simulation setup:", "aurora-error-content")}
            {/* setup error */}
            {error && error.length ? <ErrorCheck error={error} /> : null}
            {/* Error list */}
            {errorCheckList.length ? displayErrorList(errorCheckList) : null}
          </Fragment>
          : null}
        {monitorDisplayRender({ ...params })}
      </pre>
    </div>
  }

  backBottomIcon = () => {
    const { lockScroll } = this.state;
    const { scrollHeight, clientHeight } = this.lockRef || {};
    return lockScroll && scrollHeight !== clientHeight ? <Tooltip title="Back to bottom" overlayClassName='icon-tooltip'>
      <VerticalLeftOutlined className={`aurora-monitor-backbottom`} onClick={this.scrollToBottom} />
    </Tooltip> : null;
  }
}

export default SimulationContent;
