import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import BottomLine from '@/components/BottomLine';
import Line from '@/components/Line';
import LayoutExplorer from '../../LayoutExplorer/Andes';
import TabFooter from '@/components/TabSwitchFooter';
import { getCCCMsg } from '../../CCC/store/action';
import { changeTabMenu, closeTabFooter } from '../../tabMonitor/action';
import { VERIFICATION, RESULT } from '../constants';
import Setup from './Setup';
import Result from '../results';

class LayoutTB extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: 400,
      topHeight: null,
      linePosition: {
        position: "absolute",
        bottom: 400
      },
      width: 500,
      leftLinePosition: {
        position: "absolute",
        left: 500,
      },
      /* viewList: [] */
      andesResize: true,
      loading: true
    }
  }

  componentDidMount() {
    this.screenChange();
    this.parentEleBottom = document.getElementsByClassName('andes-content-bottom')[0];
    if (this.parentEleBottom) {
      const width = this.parentEleBottom.offsetWidth / 2;
      this.setState({
        width: width,
        tabWidth: width,
        leftLinePosition: {
          position: "absolute",
          left: width,
        },
      })
    }
    this.parentEle = document.getElementsByClassName('aurora-content')[0];
    if (this.parentEle) {
      const height = this.parentEle.offsetHeight / 2;
      this.setState({
        height: height,
        topHeight: height,
        linePosition: {
          position: "absolute",
          bottom: height,
        },
      });
    }

    this.setState({
      loading: false
    })
  }

  componentDidUpdate = (prevProps) => {
    const { tabVisible, tabSelectKeys } = this.props;

    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props.getCCCMsg();
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getBodySize();
  }

  getBodySize = () => {
    let bodyClientHeight = document.body.clientHeight;
    let bodyClientWidth = document.body.clientWidth;

    this.parentEle = document.getElementsByClassName('aurora-content')[0];
    if (this.parentEle) {
      const height = this.parentEle.offsetHeight / 2;
      this.setState({
        height: height,
        topHeight: this.parentEle.offsetHeight - height,
        linePosition: {
          position: "absolute",
          left: height,
        }
      });
    }

    this.parentEleBottom = document.getElementsByClassName('andes-content-bottom')[0];
    if (this.parentEleBottom) {
      const width = this.parentEleBottom.offsetWidth / 2;
      this.setState({
        width: width,
        tabWidth: this.parentEleBottom.offsetWidth - width,
        leftLinePosition: {
          position: "absolute",
          left: width,
        }
      });
    }
    return { bodyClientWidth, bodyClientHeight };
  }

  changeHeight = (height) => {
    this.parentEle = document.getElementsByClassName('aurora-content')[0];
    const bodyHeight = document.body.clientHeight;
    if (height < 142) {
      height = 142;
    } else if (height > bodyHeight - 180) {
      height = bodyHeight - 180
    }
    this.setState({ height, topHeight: this.parentEle.offsetHeight - height });
    let position = {
      position: "absolute",
      bottom: height + 1,
      minHeight: 142,
      maxHeight: bodyHeight - 180
    }
    this.setState({
      linePosition: position
    })
  }

  changeWidth = (width) => {
    this.parentElBottom = document.getElementsByClassName('andes-content-bottom')[0];

    if (width < 300) {
      width = 300
    }

    this.setState({ width, tabWidth: this.parentElBottom.offsetWidth - width })
    let position = {
      position: "absolute",
      left: width
    }
    const { andesResize } = this.state;
    this.setState({
      leftLinePosition: position,
      andesResize: !andesResize
    });
  }

  getVerification = () => {
    const { tabVisible, viewList, view, currentProjectVerifications, verificationId, currentProjectId, closeTabFooter, tabSelectKeys, menuType } = this.props;
    const { leftLinePosition, width, andesResize, linePosition, height } = this.state;
    let list = view ? [...viewList] : [];
    if (list.includes(RESULT)) {
      return <Fragment>
        <div
          id='andes-content-main'
          className='my-andes-content'
          style={{ width: tabVisible ? width : '100%', border: '14px solid #e0e0e0', borderRight: 'none' }}
        >
          <Result />
        </div>
        <Line
          position={leftLinePosition}
          changeWidth={(width) => this.changeWidth(width)}
          width={width}
          resize={(width) => this.changeWidth(width)}
        />
        {tabVisible && this.getMonitor()}
      </Fragment>
    } else if (list.includes(VERIFICATION) && currentProjectVerifications.length > 0) {
      return <Fragment>
        <div
          id='andes-content-main'
          className='my-andes-content'
          style={{ width: tabVisible ? width : '100%', border: '14px solid #e0e0e0', borderRight: 'none' }}
        >
          <Setup andesResize={andesResize} />
        </div>
        <Line
          position={leftLinePosition}
          changeWidth={(width) => this.changeWidth(width)}
          width={width}
          resize={(width) => this.changeWidth(width)}
        />
        {tabVisible && this.getMonitor()}
      </Fragment>
    }
    return tabVisible && <div className='andes-footer'>
      <TabFooter
        linePosition={linePosition}
        height={height}
        changeHeight={this.changeHeight}
        tabVisible={tabVisible}
        closeTabFooter={closeTabFooter}
        tabSelectKeys={tabSelectKeys}
        changeSize={this.changeSize}
        leftWidth={width}
        verificationId={verificationId}
        projectId={currentProjectId}
        changeTabMenu={this.props._changeTabMenu}
        menuType={menuType}
        getCCCMsg={this.props.getCCCMsg}
      >
        {this.props.monitorChild(width)}
      </TabFooter>
    </div>;
  }

  changeTabMenu = ({ selectKeys }) => {
    const { menuType, verificationId, currentProjectId } = this.props;
    this.props._changeTabMenu({ selectKeys, menuType, verificationId, projectId: currentProjectId })
  }

  getMonitor = () => {
    const { closeTabFooter } = this.props;
    const { width, linePosition, height, tabWidth } = this.state;
    return <div
      className='andes-footer'
      style={{ width: tabWidth, position: 'absolute', left: width, borderTop: 'none' }}
    >
      <TabFooter
        linePosition={linePosition}
        height={height}
        changeHeight={this.changeHeight}
        closeTabFooter={closeTabFooter}
        changeSize={this.changeSize}
        leftWidth={width}
        changeTabMenu={this.changeTabMenu}
      >
        {this.props.monitorChild(width)}
      </TabFooter>
    </div>
  }

  getPCB = () => {
    const { leftWidth, view, viewList, currentProjectDesigns } = this.props;
    const { height } = this.state;
    let list = view ? [...viewList] : [];
    if (list.includes('PCB') && currentProjectDesigns.length > 0) {
      return <Fragment>
        { /*   <div className='andes-pcb-close' onClick={() => { this.props.closeLayout('PCB') }}><Icon type='close' className='andes-close-icon' /></div> */}
        <LayoutExplorer leftWidth={leftWidth} bottomHeight={height} viewList={list} />
      </Fragment>
    };
    return null;
  }

  changeSize = (width) => {
    this.changeHeight(width);
  }

  render() {

    const { linePosition, height, topHeight, loading } = this.state;

    return (
      <Fragment>
        <Spin size='large' spinning={loading}>
          <div className='aurora-content-top aurora-PCB-layout-content' style={{ bottom: linePosition.bottom, height: topHeight }}>
            {this.getPCB()}
          </div>
          <BottomLine
            position={linePosition}
            changeHeight={(height) => this.changeHeight(height)}
            height={height}
            resize={this.changeSize}
          />
          <div className='andes-content-bottom' style={{ height: height }}>
            {this.getVerification()}
          </div>
        </Spin>
      </Fragment>
    )
  }
};

const mapState = (state) => {
  const { AndesReducer } = state;
  const { project: { view, verificationId, currentProjectId, currentProjectVerifications, currentProjectDesigns }, simulationReducer: { existResult } } = AndesReducer;
  const { tabVisible, tabSelectKeys, menuType } = state.TabMonitorReducer;
  return {
    view,
    tabVisible,
    tabSelectKeys,
    verificationId,
    currentProjectId,
    existResult,
    currentProjectVerifications,
    currentProjectDesigns,
    menuType
  };
}

const mapDispatch = (dispatch) => ({
  closeTabFooter() {
    dispatch(closeTabFooter())
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }));
  },
  getCCCMsg() {
    dispatch(getCCCMsg())
  }
})

export default connect(mapState, mapDispatch)(LayoutTB);
