export const CHANGE_CCC_MENU = 'change_ccc_menu';
export const GET_CCC_MSG = 'get_ccc_msg';
export const UPDATE_CCC_QUEUE_MSG = 'update_ccc_queue_msg';
export const UPDATE_CCC_RUNNING_QUEUE = 'update_ccc_running_queue';
export const UPDATE_BACKEND_LOG = 'update_backend_log';
export const UPDATE_DAEMON_LIST = 'update_daemon_list';
export const UPDATE_DAEMON_DATA = 'update_daemon_data';
export const CCC_EXPAND_MENU = 'ccc_expand_menu';
export const CLEAR_CCC_STATUS = 'clear_ccc_status';
export const STOP_POLL_DATA = 'stop_poll_data';
export const START_POLL_DATA = 'start_poll_data';
export const BROKER_LOG_CHANGE = 'broker_log_change';
export const GET_SERVER_LIST = 'get_server_list';
export const UPDATE_SERVER_LIST = 'update_server_list';
export const STOP_SERVER_LIST = 'stop_server_list';
export const UPDATE_TASK_TABLE_LIST = 'update_task_table';
export const SYSTEM_LOG_DOWNLOAD_UPDATE = 'system_log_download_update';
export const SELECT_TASK = "select_task";
export const UPDATE_SELECT_TASK_MESSAGE = "update_select_task_message";