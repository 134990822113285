import { takeEvery, put, call, select } from 'redux-saga/effects';
import { OPEN_FOLDER, OPEN_PROJECT, REFRESH_FOLDER } from './actionType';
import { updateData } from './action';
import { logoutAction } from '../../Login/action';
import { getFolderItemsPromise, getFilesInTrashPromise, getDesignsInProjectPromise } from '@/services/api/Design/design';
import checkError from '@/services/api/checkError';
import { FOLDER, TRASH } from '@/constants/designType';

const customLoad = { delay: 250 };
function* openFolder({ data, page }) {
  const { current, path } = (yield select()).designReducer;
  const newData = {
    loading: customLoad,
    selectedRowKeys: []
  }
  if (data !== current) {
    newData.current = data;
    newData.dataSource = [];
    // change path, special case: switch trash to my design
    const index = path.indexOf(data);
    if (index < 0) {
      if (data.type === current.type) path.push(current);
      else path.length = 0;
    } else {
      path.length = index;
    }
    newData.path = path;
  }
  yield put(updateData(newData));
  try {
    let responseData;
    if (data.type === FOLDER) {
      responseData = yield call(getFolderItemsPromise, data.id, page ? (page - 1) : 0);
    } else if (data.type === TRASH) {
      responseData = yield call(getFilesInTrashPromise, page ? (page - 1) : 0);
    } else {
      yield put(updateData({
        loading: false,
      }))
      return;
    }

    const { content, totalElements, pageable: { pageNumber }, totalPages, size } = responseData;
    const currentPage = pageNumber + 1;
    const total = currentPage > totalPages ? (totalElements + size) : totalElements;
    yield put(updateData({
      loading: false,
      dataSource: content,
      pagination: {
        total,
        current: currentPage
      }
    }))

  } catch (error) {
    yield call(errorCatch, error);
  }
}

function* refreshFolder({ page }) {
  const { current: data, pagination } = (yield select()).designReducer;
  const pageNum = page ? page : pagination.current;
  yield call(openFolder, { data, page: pageNum });
}

function* openProject({ data }) {
  const { current, path } = (yield select()).designReducer;
  const newData = {
    loading: customLoad,
    selectedRowKeys: [],
  }
  if (data && current !== data) {
    newData.current = data;
    path.push(current);
    newData.path = path;
    newData.dataSource = {
      designs: [],
      libraries: {
        ibis: [],
        spice: []
      }
    };
  }
  yield put(updateData(newData));
  try {
    // refresh project when data is undefined
    const project = data || current;
    const responseData = yield call(getDesignsInProjectPromise, project.id)
    yield put(updateData({
      loading: false,
      dataSource: responseData
    }))

  } catch (error) {
    yield call(errorCatch, error);
  }
}

function* errorCatch(error) {
  yield put(updateData({
    loading: false
  }))
  if (checkError(error) === 401) {
    yield put(logoutAction())
  } else {
    console.log(error)
    // message.error('failed loading data')
  }
}

export default function* DesignSagas() {
  // yield all([
  //   fork(reloadPage),
  // ])

  yield takeEvery(OPEN_FOLDER, openFolder);
  yield takeEvery(REFRESH_FOLDER, refreshFolder);
  yield takeEvery(OPEN_PROJECT, openProject);
}