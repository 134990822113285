import { START_IMP_SIMULATION } from '../actionType';

export const startImpedanceSimulation = (id, params = {}) => ({
  type: START_IMP_SIMULATION,
  id,
  data: params.data,
  designId: params.designId,
  signOffVerificationId: params.signOffVerificationId,
  designType: params.designType,
  Config: params.Config,
  options: params.options
})