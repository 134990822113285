import React, { Component, createRef, Fragment, } from "react";
import { PlusSquareOutlined } from '@ant-design/icons';
import { message, Dropdown, Tooltip } from "antd";
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { openUploadProgressModal, changePercentAction, showErrorMsgAction, closeUploadProgressModal, translationProgress, saveCurrentPCBKey, changeDisableStatus } from './action';
import { uploadPromise, replaceDesignPromise } from '@/services/api/Design/design';
import { uploadProjectID, addMonitorMsg, currentUploadDesignId } from './action';
import { LAYOUT } from '@/constants/designType';
import { AURORA_AAF, AURORA_DB, CADENCE_BRD, ALTIUM, ANSYS, CADENCE_MCM, ALLEGRO_ALG } from '@/constants/designVendor';
import JSZip from 'jszip';
import getDevelopMode from '@/services/api/cookies';
import { openTabFooter, changeTabMenu } from '../../../tabMonitor/action';
import { openProject, updatePCBComponentsNets, saveComponentsNetsInProject } from '../../store/project/action';
import DesignInfo from '@/services/Andes/pcbInfo';
import LayoutData from '@/services/data/LayoutData';
import { strDelimited } from "@/services/helper/split";
import '@/publicCss/uploadPCB.css';

class DesignUpload extends Component {

  constructor(props) {
    super(props);
    this.vendor = null;
    this.designType = null;
    const { key } = props.data;
    const { type } = props;
    const projectId = strDelimited(key, "-", { returnIndex: 1 });
    this.projectID = type === 'upload' ? projectId : props.data.projectId;
    this.state = {
      acceptFormat: null,
      format: null,
      libraryType: null
    }
    this.uploadRef = createRef();
    this.uploadModelRef = createRef();
    this.uploadDirectory = createRef();
    this.develop = getDevelopMode();
  }

  UploadButtonClick = (e, v, format, update) => {
    e.domEvent.stopPropagation();
    this.vendor = v;
    this.designType = LAYOUT;
    this.update = update;
    if (format === 'directory') {
      const el = this.uploadDirectory.current;
      if (!el) return;
      el.click();
    } else {
      this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'upload', projectId: this.projectID, verificationId: null });
      this.props.openTabFooter();
      this.setState({
        acceptFormat: format
      }, () => {
        const ele = this.uploadRef.current;
        if (!ele) return;
        ele.click();
      })
    }
  }

  menu = (update) => {
    return [
      {
        key: "allegro",
        label: "Allegro BRD File",
        onClick: (e) => this.UploadButtonClick(e, CADENCE_BRD, '.brd', update),
      },
      {
        key: "allegro_mcm",
        label: "Allegro MCM File",
        onClick: (e) => this.UploadButtonClick(e, CADENCE_MCM, '.mcm', update),
      },
      {
        key: "AuroraAAF",
        label: "Aurora AAF",
        onClick: (e) => this.UploadButtonClick(e, AURORA_AAF, 'directory', update),
      },
      {
        key: "AuroraDB",
        label: "Aurora DB",
        onClick: (e) => this.UploadButtonClick(e, CADENCE_BRD, 'directory', update),
      },
      {
        key: "altiumPCB",
        label: "Altium PCB ASCII",
        onClick: (e) => this.UploadButtonClick(e, CADENCE_BRD, '.pcbdoc', update),
      },
      {
        key: "altiumPCB",
        label: "Ansys ANF (v2)",
        onClick: (e) => this.UploadButtonClick(e, CADENCE_BRD, 'directory', update),
      },
      {
        key: "allegroAlg",
        label: "Allegro *.alg File",
        onClick: (e) => this.UploadButtonClick(e, CADENCE_BRD, '.alg', update),
      }
    ]
  }

  uploadChange = (e) => {
    this.props.changeDisableStatus(true);
    this.props.uploadProject(this.projectID);
    this.props.openUploadProgressModalAction();
    const files = e.target.files;
    let zip = new JSZip();
    let fileObj;
    if (files.length === 1) {
      this.props.updateMsg(`==> Start uploading PCB: "${files[0].name}".`);
    }
    if (files.length === 1 && files[0].type === 'application/zip') {
      this.uploadLayout(files[0], files[0].name);
    } else if (files.length === 1 && files[0].type !== 'application/zip') {
      zip.file(files[0].name, files[0]);
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }).then(blob => {
        fileObj = new File([blob], "design.zip");
        this.uploadLayout(fileObj, files[0].name);
      })
    } else {
      for (const file of files) {
        zip.file(file.webkitRelativePath, file);
      }
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }).then(blob => {
        fileObj = new File([blob], "design.zip");
        const number = files[0].webkitRelativePath.indexOf('/');
        const fileName = files[0].webkitRelativePath.substring(0, number);
        this.props.updateMsg(`==> Start uploading PCB: ${fileName}.`);
        this.uploadLayout(fileObj, fileName);
      })
    }
  }

  splitFileName(text) {
    var pattern = /\.{1}[a-zA-Z]{1,}$/;
    if (pattern.exec(text) !== null) {
      return (text.slice(0, pattern.exec(text).index));
    } else {
      return text;
    }
  }

  uploadLayout = (zipfile, fileName) => {
    const _name = this.splitFileName(fileName);
    const { changeDisableStatus, data, pcbComponentsNets } = this.props;
    if (this.update) {
      this.update = false;
      let pcbsLayoutDB = [];
      if (pcbComponentsNets.length > 0 && pcbComponentsNets.includes(data.id)) {
        pcbsLayoutDB = pcbComponentsNets.filter(item => item !== data.id);
      }
      this.props.updatePCBComponentsNets(pcbsLayoutDB);
      DesignInfo.deletePCBInfo(data.id);
      LayoutData.cleanLayoutInfo(data.id)
      replaceDesignPromise({
        designId: data.id,
        file: zipfile,
        name: data.name,
        projectId: this.projectID,
        type: this.designType,
        vendor: this.vendor,
        config: this.config,
        isNew: 'true'
      }).then(response => {
        this.translationFlow(response);
      }, error => {
        this.props.updateMsg(`==> Replace failed.`);

        console.error(error)
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
        message.error('Replace failed!', 2);
        changeDisableStatus(false);
      })
    } else {
      uploadPromise({
        file: zipfile,
        name: _name,
        projectId: this.projectID,
        type: this.designType,
        vendor: this.vendor,
        config: this.config,
        isNew: 'true'
      }).then(response => {
        this.translationFlow(response);
      }, error => {
        this.props.updateMsg(`==> Upload failed.`);
        console.error(error)
        if (this.uploadDirectory.current) {
          this.uploadDirectory.current.value = '';
        }
        if (this.uploadRef.current) {
          this.uploadRef.current.value = '';
        }
        message.error('Upload failed!', 2);
        changeDisableStatus(false);
      })
    }
  }

  translationFlow = (response) => {
    const { uploadSuccessAction, closeUploadProgressModalAction, beginTranslation, changeDisableStatus, type, data, currentProjectId } = this.props;
    // // Aurora DB
    if (response && !response.workflowId) {
      // reload the design table
      uploadSuccessAction();
      // close progress modal
      closeUploadProgressModalAction();
      if (currentProjectId === this.projectID) {
        message.success('Upload completed!', 3);
        this.props.openProject(this.projectID);
      }
      if (type === 'replace') {
        this.props.saveComponentsNetsInProject([data.id], true);
      }
      this.props.updateMsg(`==> Upload completed.`);
      changeDisableStatus(false);
    }

    // Translation
    if (response && response.workflowId) {
      const { workflowId, designId } = response;
      this.props.uploadDesignId(designId, workflowId);
      // begin translate
      this.props.updateMsg(`==> Finish uploading PCB.\n==> Start compiling PCB.`);
      beginTranslation({ workflowId, designId, projectID: this.projectID });
    }

    if (this.uploadDirectory.current) {
      this.uploadDirectory.current.value = '';
    }
    if (this.uploadRef.current) {
      this.uploadRef.current.value = '';
    }
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      // console.debug('onUploadProgress called with', arguments, 'Percent Completed:' + percentCompleted)
      this.props.progressAction(percentCompleted);
    }
  }

  addClick = (e, key) => {
    e.stopPropagation();
    this.props.saveCurrentPCBKey(key);
  }

  fileClick = (e) => {
    e.stopPropagation();
  }

  uploadFile = () => {
    const { acceptFormat } = this.state;
    const content = (
      <Fragment>
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={acceptFormat}
          onChange={this.uploadChange}
          onClick={(e) => this.fileClick(e)}
        />
        <input
          type='file'
          ref={this.uploadDirectory}
          style={{ display: 'none' }}
          onChange={this.uploadChange}
          webkitdirectory="true"
          directory="true"
          onClick={(e) => this.fileClick(e)}
        />
      </Fragment>
    )
    return createPortal(content, document.getElementById('root'));
  }

  render() {
    const { acceptFormat } = this.state;
    const { key, data, disabled, type } = this.props;
    return (
      <div className={type === 'replace' ? "andes-PCB-replace" : "aurora-PCB-upload"}>
        {type === 'upload' ? <Tooltip
          title={disabled ? 'There is already a pcb being uploaded.' : ''}
          mouseEnterDelay={0.2}
          mouseLeaveDelay={0}
          overlayClassName='pcb-icon-tooltip'
        >
          <Dropdown disabled={data.iconDisabled || disabled} menu={{ items: this.menu(false), className: "upload-dropdown-button" }} trigger={['click']}>
            <PlusSquareOutlined
              className={data.iconDisabled || disabled ? 'aurora-tree-add-icon icon-disabled' : 'aurora-tree-add-icon'}
              key={key}
              onClick={(e) => this.addClick(e, data.key)} />
          </Dropdown>
        </Tooltip>
          :
          type === 'replace' && <Tooltip
            title={disabled ? 'There is already a pcb being uploaded.' : 'PCB Replace'}
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
            overlayClassName='pcb-icon-tooltip'
            autoAdjustOverflow={false}
          >
            <Dropdown disabled={disabled} menu={{ items: this.menu(true), className: "upload-dropdown-button" }} trigger={['click']}>
              <span key={key}
                onClick={(e) => this.addClick(e, 'replace')}
                className={disabled ? `iconfont icon-replace1 aurora-replace-icon icon-disabled` : `iconfont icon-replace1 aurora-replace-icon`}></span>
            </Dropdown>
          </Tooltip>}
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={acceptFormat}
          onChange={this.uploadChange}
          onClick={(e) => this.fileClick(e)}
        />
        <input
          type='file'
          ref={this.uploadDirectory}
          style={{ display: 'none' }}
          onChange={this.uploadChange}
          webkitdirectory="true"
          directory="true"
          onClick={(e) => this.fileClick(e)}
        />
      </div>
    );
  }
}

const mapState = (state) => {
  const { upload, project: { pcbComponentsNets = [], currentProjectId } } = state.AndesReducer;
  const { disabled } = upload;
  return {
    disabled,
    pcbComponentsNets,
    currentProjectId
  }
}

const mapDispatch = (dispatch) => ({
  uploadSuccessAction(ID) {
  },
  openUploadProgressModalAction() {
    dispatch(openUploadProgressModal());
  },
  progressAction(percentCompleted) {
    dispatch(changePercentAction(percentCompleted));
  },
  showErrorMsg(msg) {
    dispatch(showErrorMsgAction(msg));
  },
  closeUploadProgressModalAction() {
    dispatch(closeUploadProgressModal());
  },
  beginTranslation({ workflowId, designId, projectID }) {
    dispatch(translationProgress({ workflowId, designId, projectID }));
  },
  uploadProject(projectId) {
    dispatch(uploadProjectID(projectId));
  },
  uploadDesignId(designId, workflowId) {
    dispatch(currentUploadDesignId(designId, workflowId));
  },
  updateMsg(msg) {
    dispatch(addMonitorMsg(msg));
  },
  openTabFooter() {
    dispatch(openTabFooter())
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }))
  },
  openProject(id) {
    dispatch(openProject(id));
  },
  saveCurrentPCBKey(key) {
    dispatch(saveCurrentPCBKey(key));
  },
  changeDisableStatus(disabled) {
    dispatch(changeDisableStatus(disabled));
  },
  updatePCBComponentsNets(pcbsLayoutDB) {
    dispatch(updatePCBComponentsNets(pcbsLayoutDB))
  },
  saveComponentsNetsInProject(pcbIds, status) {
    dispatch(saveComponentsNetsInProject(pcbIds, status))
  }
})
export default connect(mapState, mapDispatch)(DesignUpload);