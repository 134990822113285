import React, { PureComponent, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import SParameter from './SParameter';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Menu, message, Input, Popover, Tooltip } from 'antd';
import { changeResultKey, getResultList } from '../store/result/action';
import Waveform from './Waveform/Waveform';
import { getVerificationJson, getAndesResultList, saveAndesResult, getAndesResultHistoryList } from '@/services/Andes/results/result';
import Delay from './Delay';
import '@/publicCss/waveform.css';
import './index.css';
class Result extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasResult: true,
      contentHeight: null,
      mutiSignal: false,
      saveInputVisible: false,
      resultName: '',
      saveHistoryError: '',
      saveHistoryCount: false
    }

    this.resultRef = createRef();
  }

  componentDidMount() {
    const { existResult } = this.props;
    if (!existResult) {
      message.error('No result!');
      return;
    }
    if (this.resultRef) {
      this.setState({
        contentHeight: this.resultRef.current.offsetHeight - 48
      })
    }
    this.screenChange();
    this.getResult();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.resultRef) {
      const { contentHeight } = this.state;
      let height = this.resultRef.current.offsetHeight - 48;
      if (height !== contentHeight) {
        this.setState({
          contentHeight: height
        })
      }
    }

    const { verificationId } = this.props;
    if (verificationId && prevProps.verificationId !== verificationId) {
      this.getResult();
    }
  }

  getResult = () => {
    const { currentResultKey, verificationId, currentProjectId } = this.props;
    getVerificationJson(verificationId, currentProjectId).then(res => {
      if (res && res.Interfaces) {
        let content = res.Interfaces[0].content;
        res.Interfaces.forEach(item => {
          item.verificationId = verificationId;
        })
        if (content && content.signals && content.signals.length > 1) {
          this.setState({
            mutiSignal: true
          });
        } else {
          this.setState({
            mutiSignal: false
          });
          if (currentResultKey === 'nearEndCrossTalk' || currentResultKey === 'farEndCrossTalk') {
            this.props.changeResultKey('returnLoss');
          }
        }
        getAndesResultList(verificationId).then(res => {
          let currentResult = res ? res.current : [];
          this.props.getResultList({ currentResult });
          this.setState({
            resultName: currentResult
          });
        })
      }
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    if (this.resultRef) {
      this.setState({
        contentHeight: this.resultRef.current.offsetHeight - 48
      })
    }
  }

  getResultContent = () => {
    const { currentResultKey, existResult } = this.props;
    const { saveHistoryCount } = this.state;
    if (!existResult) {
      return null;
    }
    switch (currentResultKey) {
      case 'returnLoss':
      case 'insertionLoss':
      case 'diffToComm':
      case 'nearEndCrossTalk':
      case 'farEndCrossTalk':
        return <SParameter saveHistoryCount={saveHistoryCount} />;
      case 'TDR':
      case 'SBR':
        return <Waveform saveHistoryCount={saveHistoryCount} />
      case 'delay':
        return <Delay saveHistoryCount={saveHistoryCount} />
      default:
        return null;
    }
  }

  changeResultType = ({ key }) => {
    this.props.changeResultKey(key);
  }

  getResultList = () => {
    const { mutiSignal } = this.state;
    if (mutiSignal) {
      return [
        {
          name: 'Return Loss',
          key: 'returnLoss'
        },
        {
          name: 'Insertion Loss',
          key: 'insertionLoss'
        },
        {
          name: 'Diff To Comm',
          key: 'diffToComm'
        },
        {
          name: 'NEXT',
          key: 'nearEndCrossTalk'
        },
        {
          name: 'FEXT',
          key: 'farEndCrossTalk'
        },
        {
          name: 'TDR',
          key: 'TDR'
        },
        {
          name: 'SBR',
          key: 'SBR'
        },
        {
          name: 'Delay',
          key: 'delay'
        }
      ]
    } else {
      return [
        {
          name: 'Return Loss',
          key: 'returnLoss'
        },
        {
          name: 'Insertion Loss',
          key: 'insertionLoss'
        },
        {
          name: 'Diff To Comm',
          key: 'diffToComm'
        },
        {
          name: 'TDR',
          key: 'TDR'
        },
        {
          name: 'SBR',
          key: 'SBR'
        },
        {
          name: 'Delay',
          key: 'delay'
        }
      ]
    }
  }

  content = () => {
    const { resultName, saveHistoryError } = this.state;
    return (
      <Fragment> <Input
        value={resultName}
        className='andes-result-name'
        onChange={(e) => this.inputNameChange(e)}
        ref={(input) => { this.inputRef = input; }}
        onPressEnter={(e) => this.resultSaveClick(e)}
        onClick={(e) => this.inputClick(e)}
        addonAfter={<Fragment>
          <CloseOutlined
            className='aurora-tree-create-icon'
            onClick={(e) => this.cancelSaveResult(e)} />
          <CheckOutlined
            className='aurora-tree-create-icon mg-r-8'
            onClick={(e) => this.resultSaveClick(e)} />
        </Fragment>}
      />
        {saveHistoryError && <span className='andes-result-history-error' onClick={(e) => this.inputClick(e)}>{saveHistoryError}</span>}
      </Fragment>
    );
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  inputNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      resultName: e.target.value,
      saveHistoryError: ''
    })
  }

  cancelSaveResult = (e) => {
    e.stopPropagation();
    this.setState({
      saveInputVisible: false,
      saveHistoryError: null
    })
  }

  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({
        saveHistoryError: null
      })
    }
    this.setState({ saveInputVisible: visible });
  };

  resultSaveClick = async (e) => {
    e.stopPropagation();
    const { resultName } = this.state;
    const { verificationId } = this.props;
    const historyList = await getAndesResultHistoryList(verificationId);
    const names = historyList.map(item => item.name);
    if (names.includes(resultName)) {
      this.setState({
        saveHistoryError: 'Name already exists!'
      });
      return;
    }
    saveAndesResult(verificationId, resultName).then(response => {
      this.setState((prevState) => ({
        saveHistoryCount: !prevState.saveHistoryCount
      }))
      getAndesResultList(verificationId).then(res => {
        let currentResult = res ? res.current : [];
        this.props.getResultList({ currentResult });
      })
    }, error => {
      message.error('Failed to save result:' + error)
    })
    this.setState({
      saveInputVisible: false,
      saveHistoryError: ''
    });
  }

  render() {
    const { currentResultKey, existResult } = this.props;
    const { contentHeight, saveInputVisible } = this.state;
    const resultMenu = this.getResultList();
    return (
      <div className='andes-result-main' ref={this.resultRef}>
        <Menu onClick={this.changeResultType} selectedKeys={currentResultKey} mode="horizontal" className='andes-result-main-title' items={resultMenu.map(item => ({ key: item.key, label: item.name }))} />
        {existResult && <Tooltip title='Save To History' overlayClassName='aurora-tooltip' onClick={this.inputClick}>
          <Popover
            content={this.content()}
            trigger="click"
            onClick={this.inputClick}
            open={saveInputVisible}
            onOpenChange={this.handleVisibleChange}
            overlayClassName='andes-result-popover'>
            <div className='andes-result-save-div'><SaveOutlined className="andes-result-save-icon" /></div>
          </Popover>
        </Tooltip>}
        <div className='andes-result-content' style={{ height: contentHeight ? contentHeight : '' }}>
          {this.getResultContent()}
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  const { resultReducer, project, andes: { andesInfo }, simulationReducer: { existResult } } = state.AndesReducer;
  const { currentResultKey } = resultReducer;
  const { verificationId, currentProjectId } = project;

  return {
    currentResultKey,
    existResult,
    verificationId,
    currentProjectId,
    andesInfo,
  }
}

const mapDispatch = (dispatch) => ({
  changeResultKey(key) {
    dispatch(changeResultKey(key))
  },
  getResultList({ currentResult }) {
    dispatch(getResultList({ currentResult }))
  }
})

export default connect(mapState, mapDispatch)(Result);