import { getSeaSimDisplayText } from ".";
import { numberCheck } from "../../helper/dataProcess";
import {
  BER,
  PORT_TX_VPKPK,
  JIT_PWRJ,
  JIT_PWDDJ,
  JIT_HFRJ_NUI,
  JIT_LFRJ,
  JIT_LFDDJ,
  ADAPT_CSPACE,
  ADAPT_DC,
  ADAPT_POLE,
  NUI,
  SECOND,
  UI,
  TX_PRE_SHOOT,
  TX_DEEMPHASIS,
  LEQ_DC,
  LEQ_POLE,
  LEQ_DC2,
  LEQ_POLE2,
  ADAPT_PS,
  ADAPT_DE,
  ADAPT_DC2,
  ADAPT_POLE2,
  JITTER_UNIT,
  ADAPT_EQ,
  USE_ADAPT_CSPACE,
  PCIE_2,
  PCIE_3,
  PCIE_4,
  PCIE_5,
  INTERFACE_TYPE,
  LEQ_BW,
  LEQ_AC,
  LEQ_BW2,
  LEQ_AC2,
  ADAPT_FIRST_POLES,
  ADAPT_SECOND_POLES,
  LEQ_FIRST_POLES,
  LEQ_SECOND_POLES,
  VPT,
  PRE_CURSORS,
  NUI_IFFT,
  CURSOR_BLOCK,
  ADAPT_NUI,
  ADAPT_PSDE_PAIRS,
  ADAPT_LEQ_INDEX1,
  ADAPT_LEQ_INDEX2,
  LEQ_RESPONSE_INDEX1,
  LEQ_RESPONSE_INDEX2,
  ADAPT_DFE_MAG,
  ADAPT_DFE_H1H0,
  RXCOEFF,
  USE_ADAPT_TX_PRESET,
  ADAPT_TX_PRESET
} from "./constants";

const eqSingleConfigList = [
  TX_PRE_SHOOT, TX_DEEMPHASIS,
  LEQ_DC, LEQ_POLE, LEQ_DC2, LEQ_POLE2,
  LEQ_BW, LEQ_AC, LEQ_BW2, LEQ_AC2, ADAPT_CSPACE, ADAPT_NUI,
  ADAPT_DFE_H1H0, LEQ_RESPONSE_INDEX1, LEQ_RESPONSE_INDEX2
];
const eqMultiConfigList = [
  ADAPT_PS, ADAPT_DE, ADAPT_DC,
  ADAPT_POLE, ADAPT_DC2, ADAPT_POLE2,
  ADAPT_DFE_MAG, RXCOEFF, ADAPT_LEQ_INDEX1, ADAPT_LEQ_INDEX2];

const jittersAndBasicConfigList = [
  PORT_TX_VPKPK,
  BER,
  JIT_PWRJ,
  JIT_PWDDJ,
  JIT_HFRJ_NUI,
  JIT_LFRJ,
  JIT_LFDDJ,
  NUI,
  VPT,
  PRE_CURSORS,
  NUI_IFFT,
  CURSOR_BLOCK
]

function seaSimErrorCheck(type, value, setting = {}) {
  if (!value || (Array.isArray(value) && !value.length)) {
    return;
  }

  const { unit, pcieType } = setting;
  const typeText = getSeaSimDisplayText(type);

  let errorMsg = null, numValue = value;

  if (eqMultiConfigList.includes(type) && Array.isArray(value)) {
    for (let item of value) {
      errorMsg = numberCheck(item);
      if (errorMsg) {
        return { type, errorMsg: `${typeText} value must be a number!` };
      }
      errorMsg = seaSimEQParamsErrorCheck(type, item, typeText);
    }
  }

  if (eqSingleConfigList.includes(type)) {
    errorMsg = value ? numberCheck(value) : null;
    numValue = Number(value);
    if (errorMsg) {
      return { type, errorMsg: `${typeText} value must be a number!` };
    }
    errorMsg = seaSimEQParamsErrorCheck(type, numValue, typeText);
  }

  if (errorMsg) {
    return { type, errorMsg }
  }

  if (!jittersAndBasicConfigList.includes(type)) {
    return null;
  }

  errorMsg = numberCheck(value);
  if (errorMsg) {
    errorMsg = `${typeText} value must be a number!`;
    return { type, errorMsg }
  }

  switch (type) {
    case PORT_TX_VPKPK:
      //0.2 ~ 2
      if (numValue < 0.2 || numValue > 2) {
        errorMsg = "Peak-to-peak Voltage should be between 0.2 ~ 2.";
      }
      break;
    case BER:
      //1e-20 ~ 1e-9
      if (numValue < 1e-20 || numValue > 1e9) {
        errorMsg = "Bit Error Rate should be between 1e-20 ~ 1e-9.";
      }
      break;
    case JIT_PWRJ:
    case JIT_PWDDJ:
    case JIT_HFRJ_NUI:
    case JIT_LFRJ:
    case JIT_LFDDJ:
      errorMsg = jitterNumberCheck(value, unit, typeText, pcieType);
      break;
    case NUI:
      // 10~Infinity
      if (numValue < 10) {
        errorMsg = "Please set the Nui value greater than 10."
      }
      break;
    case VPT:
      // 1024 ~ 4096
      if (numValue < 1024 || numValue > 4096) {
        errorMsg = "Number of voltage bins should be between 1024 ~ 4096"
      }
      break;
    case PRE_CURSORS:
      // 1 ~ 10
      if (numValue < 1 || numValue > 10) {
        errorMsg = "Number of pre-cursors for statistical eye should be between 1 ~ 10"
      }
      break;
    case CURSOR_BLOCK:
      // 5 ~ 10
      if (numValue < 5 || numValue > 10) {
        errorMsg = "Number of UI in cursor block should be between 5 ~ 10"
      }
      break;
    case NUI_IFFT:
      // 16 ~ Infinity
      if (numValue < 16) {
        errorMsg = "Please set the Minimum number of UI used for ifft value greater than 16."
      }
      break;
    default: break;
  }

  if (errorMsg) {
    return { type, errorMsg }
  }
  return null;
}

function jitterNumberCheck(value, unit, typeText, pcieType) {
  const numValue = Number(value);

  const ranges = getJitterValueRangeByUnit(unit, pcieType);

  if (!ranges) {
    return null;
  }

  if (numValue < ranges[0] || numValue > ranges[1]) {
    return `${typeText} should be between ${ranges[0]} ~ ${ranges[1]}.`
  }
  return null;
}

function getJitterValueRangeByUnit(unit, pcieType) {
  if (unit === SECOND) {
    switch (pcieType) {
      case PCIE_2:
        return [0, 1e-10]; // 1ui = 1 / data_rate , 0.5ui = 1 / (2*data_rate) = 2e-10s
      case PCIE_3:
        return [0, 6.25e-11];
      case PCIE_4:
        return [0, 3.125e-11];
      case PCIE_5:
        return [0, 1.563e-11]
      default: return;
    }
  } else if (unit === UI) {
    return [0, 0.5];
  }
}

function seaSimEQParamsErrorCheck(type, value, typeText) {
  const numValue = Number(value);
  let errorMsg = "";
  switch (type) {
    case ADAPT_PS:
    case TX_PRE_SHOOT:
      //0 ~ 100
      if (numValue < 0 || numValue > 100) {
        errorMsg = `${typeText} should be between 0 ~ 100.`
      }
      break;
    case ADAPT_DE:
    case ADAPT_DC:
    case ADAPT_DC2:
    case TX_DEEMPHASIS:
    case LEQ_DC:
    case LEQ_DC2:
      //-100 ~ 0​
      if (numValue < -100 || numValue > 0) {
        errorMsg = `${typeText} should be between -100 ~ 0.`
      }
      break;
    case ADAPT_POLE:
    case ADAPT_POLE2:
    case LEQ_POLE:
    case LEQ_POLE2:
    case LEQ_BW:
    case LEQ_BW2:
      //0 ~ 1e12
      if (numValue < 0 || numValue > 1e12) {
        errorMsg = `${typeText} should be between 0 ~ 1e12.`
      }
      break;
    case ADAPT_CSPACE:
      //1 ~ 100
      if (numValue < 1 || numValue > 100) {
        errorMsg = "TX FIR Equalization - Search space should be between 1 ~ 100."
      }
      break;
    case LEQ_AC:
    case LEQ_AC2:
      //0 ~ 10
      if (numValue < 0 || numValue > 10) {
        errorMsg = "TX FIR Equalization - Search space should be between 0 ~ 10."
      }
      break;
    case ADAPT_NUI:
      if (numValue < 0) {
        errorMsg = "Please set the Number of post-cursor UI for adaptation value greater than 0."
      }
      break;
    case ADAPT_DFE_MAG:
    case ADAPT_DFE_H1H0:
    case RXCOEFF:
      // 0~1
      if (numValue < 0 || numValue > 1) {
        errorMsg = `${typeText} should be between 0 ~ 1.`
      }
      break;
    case ADAPT_LEQ_INDEX1:
    case ADAPT_LEQ_INDEX2:
    case LEQ_RESPONSE_INDEX1:
    case LEQ_RESPONSE_INDEX2:
      // 0~ /int
      if (!Number.isInteger(numValue)) {
        errorMsg = `${typeText} should be of type int.`
      } else if (numValue < 0) {
        errorMsg = `${typeText} should be greater than 0.`
      }
      break;
    default: return;
  }
  return errorMsg;
}

function seaSimAdvancedErrorCheck(config) {
  let errors = [];
  //No Adapt
  if (!config[ADAPT_EQ]) {
    //first poles error check,
    const firstPoleValues = getEQRxPolesValues(config, LEQ_FIRST_POLES);
    if (firstPoleValues.length > 0 && firstPoleValues.length < LEQ_FIRST_POLES.length) {
      errors.push("RX Linear Equalizer - First Pole: All values can be left empty, but if any of the box is not empty, the entire row must be filled.");
    }

    //second poles error check,
    const secondPoleValues = getEQRxPolesValues(config, LEQ_SECOND_POLES);
    if (secondPoleValues.length > 0 && secondPoleValues.length < LEQ_SECOND_POLES.length) {
      errors.push("RX Linear Equalizer - Second Pole: All values can be left empty, but if any of the box is not empty, the entire row must be filled.");
    }

    if (secondPoleValues.length > 0 && firstPoleValues.length === 0) {
      errors.push(`RX Linear Equalizer - Second Pole: "Second Pole" is allowed to be set only if "First Pole" is set`);
    }
    return errors;
  }

  //Adapt
  //first poles error check,
  const _firstPoleValues = getEQRxPolesValues(config, ADAPT_FIRST_POLES);
  if (_firstPoleValues.length > 0 && _firstPoleValues.length < ADAPT_FIRST_POLES.length) {
    errors.push("RX Linear Equalizer - First Pole: All values can be left empty, but if any of the box is not empty, the entire row must be filled.");
  }

  //second poles error check,
  const _secondPoleValues = getEQRxPolesValues(config, ADAPT_SECOND_POLES);
  if (_secondPoleValues.length > 0 && _secondPoleValues.length < ADAPT_SECOND_POLES.length) {
    errors.push("RX Linear Equalizer - Second Pole: All values can be left empty, but if any of the box is not empty, the entire row must be filled.");
  }

  if (_secondPoleValues.length > 0 && _firstPoleValues.length === 0) {
    errors.push(`RX Linear Equalizer - Second Pole: "Second Pole" is allowed to be set only if "First Pole" is set`);
  }

  if (config[USE_ADAPT_CSPACE] && !config[ADAPT_CSPACE]) {
    errors.push("TX FIR Equalization - Search space should be between 1 ~ 100.");
  }

  if (!config[USE_ADAPT_CSPACE] && !config[USE_ADAPT_TX_PRESET] && !config[ADAPT_PSDE_PAIRS] && (!config[ADAPT_PS] || !config[ADAPT_PS].length)) {
    errors.push("TX FIR Equalization - Pre-shoot should be between 0 ~ 100.")
  }

  if (!config[USE_ADAPT_CSPACE] && !config[USE_ADAPT_TX_PRESET] && !config[ADAPT_PSDE_PAIRS] && (!config[ADAPT_DE] || !config[ADAPT_DE].length)) {
    errors.push("TX FIR Equalization - De-emphasis should be between -100 ~ 0.")
  }

  if (!config[USE_ADAPT_CSPACE] && !config[USE_ADAPT_TX_PRESET] && config[ADAPT_PSDE_PAIRS] && (!config[ADAPT_PS] || !config[ADAPT_DE] || config[ADAPT_PS].length !== config[ADAPT_DE].length)) {
    errors.push("TX FIR Equalization  - Pre-shoot and De-emphasis must have the same length and cannot be empty")
  }

  if (config[USE_ADAPT_TX_PRESET] && (!config[ADAPT_TX_PRESET] || !config[ADAPT_TX_PRESET].length)) {
    errors.push("Preset search space cannot be empty")
  }

  return errors;
}

function getEQRxPolesValues(config, paramKeys) {
  const values = paramKeys.map(item => config[item]);
  return values.filter(item => (Array.isArray(item) && item.length) || (!!item && !Array.isArray(item)) || item === 0);
}

function channelSeaSimConfigCheck(config, selectedSignals, isEndToEnd) {
  const configAnalysis = config.analysis || {};
  const _selectedSignals = selectedSignals || [];
  const analysisOptions = configAnalysis && configAnalysis.options ? configAnalysis.options : {};
  const checkConfig = {
    [BER]: analysisOptions[BER],
    PORT_TX_VPKPK: analysisOptions[PORT_TX_VPKPK],
    ...analysisOptions.statsim_options
  }

  let optionsErrors = [];

  const pcieType = analysisOptions[INTERFACE_TYPE];

  for (let item in checkConfig) {
    const error = seaSimErrorCheck(item, checkConfig[item], { unit: checkConfig[JITTER_UNIT], pcieType });
    if (error && error.errorMsg) {
      optionsErrors.push(error.errorMsg);
    }
  }

  const errors = seaSimAdvancedErrorCheck(checkConfig);
  errors.length && optionsErrors.push(...errors);

  let channelErrors = [];
  const filterChannels = isEndToEnd ? config.channels : config.channels.filter(item => _selectedSignals.includes(item.signal));

  const firstChannel = filterChannels[0] || null;
  if (firstChannel && !firstChannel.controller.component) {
    channelErrors.push(`The controller component not selected.`)
  }

  if (firstChannel && !firstChannel.device.component) {
    channelErrors.push(`The device component not selected.`)
  }
  for (let item of filterChannels) {
    const aggressorsItem = configAnalysis.channels.find(it => it.victim === item.signal) || { aggressors: [] };
    const notSelectedSignals = aggressorsItem.aggressors.filter(it => !_selectedSignals.includes(it));
    if (notSelectedSignals.length && !isEndToEnd) {
      channelErrors.push(`The aggressors [ ${notSelectedSignals.join(', ')} ] of ${item.signal} are not selected.`)
    }

    if (!item.direction) {
      channelErrors.push(`The direction of ${item.signal} are not selected.`)
    }
  }

  if (optionsErrors.length || channelErrors.length) {
    return [...channelErrors, ...optionsErrors];
  }
}


export {
  seaSimErrorCheck,
  channelSeaSimConfigCheck
}