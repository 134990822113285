import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import { CloseOutlined, FileTextOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Tooltip, Spin } from 'antd';
import { updateIRData, cascadeIRSelectNets, deleteIRData, updateIrLoad, saveIRExplorerInfo, saveIRVRMComponent, searchVRMLoading } from '../store/IRExplorer/action';
import { IRTableData } from '@/services/Cascade/helper/IRData';
import { selectChange, changeShowNameStatus, selectLayer } from '../../LayoutExplorer/store/Cascade/actionCreators';
import { getDisplayDetail } from '@/services/Cascade/project';
import ConstraintsPanel from './ConstraintsPanel';
import { NetGroupSelection } from '@/services/PCBHelper';
import canvas from '@/services/LayoutCanvas';
import portSetup from '../components/portSetup';
import NP from 'number-precision';
import componentSetting from '@/services/Cascade/helper/compSettingHelper';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import VRMCompPanel from './vrmCompPanel';

const IRColumns = [{
  title: 'Power',
  dataIndex: 'allPowerNet',
  key: 'allPowerNet',
  width: '15%',
}, {
  title: 'Ground',
  dataIndex: 'gndNets',
  key: 'gndNets',
  width: '15%',
}, {
  title: 'Nominal V (V)',
  dataIndex: 'nominal',
  key: 'nominal',
  width: '8%',
}, {
  dataIndex: 'load_0',
  key: 'load_0',
  width: '10%',
  displayTitle: `Load I (A)`,
}, {
  title: 'PMIC',
  dataIndex: 'vrmComps',
  key: 'vrmComps',
  width: '8%',
}, {
  title: 'Constraints',
  dataIndex: 'constraints',
  key: 'constraints',
  width: '16%',
}];
class IRTableContent extends Component {
  constructor(props) {
    super(props);
    this.netGroupSelection = new NetGroupSelection();
    this.state = {
      buckConverter: []
    }
  }

  componentDidMount = async () => {
    const { designId } = this.props;
    if (designId) {
      const setting = await componentSetting.getPrefixLib(designId);
      this.setState({
        buckConverter: setting.discreteBuckConverter || []
      }, () => {
        this.setDefaultColumns()
      })
    } else {
      this.setDefaultColumns()
    }
  }

  componentDidUpdate = async (prevProps) => {
    const { designId } = this.props;
    if (designId && designId !== prevProps.designId) {
      const setting = await componentSetting.getPrefixLib(designId);
      this.setState({
        buckConverter: setting.discreteBuckConverter || []
      })
    }
  }

  setDefaultColumns = () => {
    IRColumns[0].render = (value, record) => {
      const { detail, id } = record;
      return (
        <div className="ir-table-power">
          <span>{record.powerNets.join(', ')}</span>
          {record.extendNets && record.extendNets.length ? <Fragment>, <span className="extend-net-select-color">{record.extendNets.join(', ')}</span></Fragment> : null}
          {detail && detail.length ? <Tooltip
            classNames={{ root: "aurora-tooltip cascade-detail-tooltip" }}
            title={this.getIRDetail(detail, id)}
            key={id}
            trigger="click"
            placement="topRight"
            getPopupContainer={() => document.getElementById(`detail_${id}`)}
          >
            <FileTextOutlined
              className={`aurora-tree-info-icon cascade-ir-detail-icon`}
              title={"Topology"}
              onClick={(e) => this.clickDetail(e, detail)}
              id={`detail_${id}`} />
          </Tooltip> : null}
        </div>
      );
    }
    IRColumns[0].onCell = (record) => {
      const { nets, noPCB } = this.props;
      return !noPCB ? {
        record,
        edit: 'aurora-select',
        options: nets,
        dataIndex: 'allPowerNet',
        onFocus: () => this.select(record['allPowerNet']),
        selectMode: 'multiple',
        onChange: (list, record, setFieldsValue) => this.changeSelected(list, record, setFieldsValue, 'allPowerNet'),
        clearSelected: (value, record, setFieldsValue) => this.clearSelected(value, record, setFieldsValue, 'allPowerNet'),
        loadingMsg: !nets || !nets.length ? 'Loading Nets...' : ''
      } : {
        edit: false
      }
    }

    IRColumns[1].render = (value, record) => {
      return <span>{value.join(', ')}</span>
    }
    IRColumns[1].onCell = (record) => {
      const { nets, noPCB } = this.props;
      return !noPCB ? {
        record,
        edit: 'aurora-select',
        options: nets,
        dataIndex: 'gndNets',
        onFocus: () => this.select(record['gndNets']),
        selectMode: 'multiple',
        onChange: (list, record, setFieldsValue) => this.changeSelected(list, record, setFieldsValue, 'gndNets'),
        clearSelected: (value, record, setFieldsValue) => this.clearSelected(value, record, setFieldsValue, 'gndNets'),
        loadingMsg: !nets || !nets.length ? 'Loading Nets...' : '',
      } : {
        edit: false
      }
    }

    IRColumns[2].render = (value, record) => {
      return <span>{value}</span>
    }
    IRColumns[2].onCell = (record) => {
      const dataIndex = 'nominal';
      return !this.props.noPCB ? {
        record,
        edit: true,
        dataIndex,
        rowSpan: record.groupLength,
        handleSave: (rec) => { this._editIRdata(rec, 'nominal') },
      } : {
        edit: false,
        rowSpan: record.groupLength
      }
    }

    IRColumns[3].render = (value, record) => {
      const current = value.length ? value.length > 1 ? "Pin Groups" : value[0].current : ''
      return <Fragment>
        <div>{current}</div>
      </Fragment>
    }
    IRColumns[3].onCell = (record) => {
      const { defaultValue } = this.getLoadComponents('load_0');
      const { designId } = this.props;
      const { load_0, id, powerNets, gndNets } = record;
      const load = load_0.length ? [...load_0] : [];
      const current = load_0.length ? load_0.length > 1 ? "Pin Groups" : load_0[0].current : '';
      return !this.props.noPCB ? {
        record,
        edit: true,
        customInput: portSetup,
        dataIndex: 'ports',
        text: current,
        targetIC: defaultValue,
        pcbId: designId,
        powerDomainId: id,
        ports: load,
        savePorts: (id, current) => this.updatePorts(id, current, record),
        onPortsRef: this.onPortsRef,
        DCLoad: true,
        PowerNets: powerNets,
        ReferenceNets: gndNets,
        title: "Current Groups"
      } : {
        edit: false
      }
    }
    IRColumns[3].title = () => {
      return <div className="ir-load-title">
        <span>Load I (A)</span>
      </div>
    }

    IRColumns[4].render = (value, record) => {
      const vrmValues = this.getIRVRMName(value, record);
      return <span>{vrmValues.join(', ')}</span>
    }
    IRColumns[4].onCell = (record) => {
      const { vrm, noPCB, designId, searchVRM } = this.props;
      const { defaultValue } = this.getLoadComponents('load_0');
      const vrmValues = this.getIRVRMName(record['vrmComps'] || [], record);
      const components = record.components || [];
      return !noPCB ? {
        record,
        edit: true,
        designId,
        customInput: VRMCompPanel,
        dataIndex: 'vrmComps',
        vrm,
        components,
        targetIC: defaultValue,
        noPCB,
        text: vrmValues.join(', '),
        searchVRM,
        saveVrms: (vrms) => this.saveVrms(record, vrms),
        saveIRVRMComponent: this.props.saveIRVRMComponent,
        searchVRMLoading: this.props.searchVRMLoading
      } : {
        edit: false
      }
    }

    IRColumns[5].render = (value, record) => {
      return (
        <div className="ir-table-Delete">
          <span>Ibga={(record.maxBgaI && (record.maxBgaI)) || '0'}A, Ivia={(record.maxVia && (record.maxVia)) || '0'}A, dVbga={(record.maxBgaDv && NP.times(record.maxBgaDv, 100)) || '0'}%</span>
          <CloseOutlined onClick={(e) => this.deleteIrData(e, record)} className="ir-delete-icon" />
        </div>
      );
    }
    IRColumns[5].onCell = (record) => {
      return !this.props.noPCB ? {
        record,
        PowerDomainData: record,
        edit: true,
        dataIndex: 'constraints',
        customInput: ConstraintsPanel,
        title: "Constraints",
        updateIRData: this.props._updateIRData
      } : {
        edit: false
      }
    }
  }

  getIRDetail = (detail, id) => {
    if (!detail || !detail.length) return "";
    let newDetailList = getDisplayDetail(detail);
    const { contentWidth } = this.props;
    return <div className="cascade-text-editor-tooltip" key={`${id}_detail`} onClick={(e) => this.stopClick(e)}>
      <div className='cascade-display-detail-box' style={contentWidth ? { maxWidth: contentWidth - 100 } : {}}>
        {newDetailList.map((item, key) => {
          let list = [];
          item.forEach((text, index) => {
            list.push(<span key={`text-${index}`}>{text}</span>)
            if (index < item.length - 1) {
              list.push(<span key={`connect-${index}`}>&nbsp;--&gt;&nbsp;</span>)
            }
          })
          return <div key={key} className='cascade-detail-line' onClick={(e) => this.clickDetail(e, [detail[key]])}>
            {list.map(text => text)}
          </div>
        })}
      </div>
    </div>
  }

  clickDetail = (e, detail) => {
    this.stopClick(e);
    const { selectedDesignIDs, irLoadSelect, designId } = this.props;
    const value = irLoadSelect && irLoadSelect.length ? irLoadSelect[0].comp : '';
    if (selectedDesignIDs.includes(designId)) {
      let nets = [], comps = [];
      detail.forEach(d => {
        if (d.length) {
          d.forEach(item => {
            if (item.type === 'net') {
              nets.push(item.name)
            } else if (item.type === 'comp') {
              const comp = item.title ? item.title.split(' - ') : [item.name]
              comps.push(...comp)
            }
          })
        }
      })
      const layout = canvas.getLayout(designId);
      let layers = layout.findCurrentLayer([...comps, value]);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, designId);
      this.props._selectChange({ nets: [...new Set(nets)], comps: [...new Set([...comps, value])] }, designId)
      this.props._changeShowNameStatus(true, designId)
      setTimeout(() => {
        this.netGroupSelection.selectNetGroup({ nets: [...new Set(nets)], isPower: true })
      }, 100);
    }
  }

  select = (nets) => {
    const { selectedDesignIDs, designId } = this.props;
    if (selectedDesignIDs.includes(designId)) {
      this.props._selectChange({ nets }, designId)
      this.props._changeShowNameStatus(true, designId)
      setTimeout(() => {
        this.netGroupSelection.selectNetGroup({ nets: nets, isPower: true })
      }, 100);
    }
  }

  stopClick = (e) => {
    e && e.stopPropagation();
    e && e.preventDefault();
  }

  getIRVRMName = (names) => {
    const { designId } = this.props;
    if (!designId) {
      return names;
    }

    let values = [];
    const { buckConverter } = this.state;
    for (let name of names) {
      const findBuck = buckConverter.find(item => item.includes(name));
      values.push(findBuck ? findBuck.join(' - ') : name)
    }
    return values
  }

  getDefaultName = (loadList) => {
    const nameList = loadList.map(item => item.name);
    const defaultKey = 'load';
    let nameIndex = nameList.length
    let defaultName = `${defaultKey}_${nameIndex}`;
    while (nameList.includes(defaultName)) {
      nameIndex += 1;
      defaultName = `${defaultKey}_${nameIndex}`
    }
    return defaultName;
  }

  getLoadComponents(key) {
    const { loadComponents, irLoadSelect } = this.props;
    const _loadValue = irLoadSelect.filter(it => it.name === key);
    const allowDel = (irLoadSelect.length === 1) ? false : true;
    let defaultValue = "", options = [];
    let selectedOptions = irLoadSelect.map(item => item.comp)

    if (_loadValue.length) {
      defaultValue = _loadValue[0].comp
    }

    loadComponents.forEach(item => {
      if (!selectedOptions.includes(item.name) || item.name === defaultValue) {
        options.push(item)
      }
    })
    return { defaultValue, options: options.sort(), allowDel }
  }

  deleteIrData = (e, record) => {
    e.stopPropagation();
    this.props._deleteIRData([record.id])
  }

  changeSelected = (list, record, setFieldsValue, type) => {
    const { allPowerNet, extendNets, gndNets, powerNets } = record;
    let _nets = [], deleteNet = '';

    if (type === 'gndNets') {
      _nets = gndNets
    } else if (type === 'allPowerNet') {
      _nets = allPowerNet
    }

    if (list.length === 1 && _nets.includes(list[0])) {
      _nets = _nets.filter(item => item !== list[0])
      deleteNet = list[0]
    } else {
      _nets = [...new Set([..._nets, ...list])]
    }

    this.props._cascadeIRSelectNets(type, _nets, record.id, deleteNet)
  }

  clearSelected = (value, record, setFieldsValue, type) => {
    let _nets = [];
    if (type === 'gndNets') {
      _nets = record.gndNets.filter(item => item !== value)
    } else if (type === 'allPowerNet') {
      _nets = record.allPowerNet.filter(item => item !== value)
    }
    this.props._cascadeIRSelectNets(type, _nets, record.id, value)
  }

  _editIRdata = (value, dataIndex) => {
    let _value = { ...value }
    this.props._updateIRData([_value])
  }

  addClick = (e) => {
    e.stopPropagation();
    let data = new IRTableData({});
    this.props._updateIRData([data])
  }

  onPortsRef = (ref) => {
    this.portsRef = ref;
  }

  updatePorts = (id, current, record) => {
    const newRow = { ...record };
    newRow.load_0 = current;
    newRow.loads[0].current = current;
    this.props._updateIRData([newRow])
  }

  saveVrms = (record, vrms) => {
    let newRow = { ...record };
    const newVrmComps = [...new Set(vrms.map(vrm => vrm.vrmComp ? vrm.vrmComp : `[${vrm.powerComp}-${vrm.groundComp}]`))];
    let newInductance = {};
    newVrmComps.forEach(vrm => {
      const vrmList = vrms.filter(v => v.vrmComp ? v.vrmComp === vrm : `[${v.powerComp}-${v.groundComp}]` === vrm);
      newInductance[vrm] = vrmList;
    })
    const newDetails = newRow.detail.filter(d => d.some(item => newVrmComps.includes(item.name)));
    const _newAllPowerNet = newDetails.map(detail => detail.filter(item => item.type === 'net').map(item => item.name)).flat(2);
    const newAllPowerNet = newRow.allPowerNet.filter(net => _newAllPowerNet.includes(net));
    const newExtendNets = newRow.extendNets.filter(net => _newAllPowerNet.includes(net));
    const _newAllPowerComp = newDetails.map(detail => detail.filter(item => item.type === 'comp').map(item => item.name)).flat(2);
    const { defaultValue } = this.getLoadComponents('load_0');
    const newComponents = newRow.components.filter(comp => [..._newAllPowerComp, defaultValue].includes(comp.name))
    newRow = {
      ...newRow,
      vrmComps: newVrmComps,
      inductance: newInductance,
      detail: newDetails,
      allPowerNet: newAllPowerNet,
      extendNets: newExtendNets,
      components: newComponents
    }
    this.props._updateIRData([newRow])
  }

  render() {
    const { loading, IRTableData, IRTableLoading, noPCB } = this.props;
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color">Power Domains</span>
        {loading || noPCB ? <Tooltip title={noPCB ? 'Please Select PCB First.' : loading && 'Loading PCB...'} classNames={{ root: 'aurora-tooltip' }}>
          <PlusCircleOutlined className='ir-explorer-icon ir-explorer-icon-disable' />
        </Tooltip> :
          <PlusCircleOutlined className='ir-explorer-icon' onClick={(e) => this.addClick(e)} />}
        <div className="space-10">
          <Spin spinning={IRTableLoading}>
            <EditableTable
              rowKey={(record) => record.powerNets.join('-')}
              columns={IRColumns}
              size="small"
              dataSource={IRTableData}
              className="cascade-ir-table"
              id="cascade-ir-table"
              tablePadding={true}
            />
          </Spin>
        </div>
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, selectedKeys },
    IR: { designId, IRExplorerInfo: { IRTableData = [], irLoadOptions = [], irLoadSelect = [] }, verificationId, IRTableLoading, searchVRM } } } = state;
  return {
    projectId: openProjectId,
    irLoadOptions,
    irLoadSelect,
    IRTableData,
    verificationId,
    designId,
    IRTableLoading,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    searchVRM
  };
}

const mapDispatch = (dispatch) => ({
  _updateIRData(data) {
    dispatch(updateIRData(data))
  },
  _cascadeIRSelectNets(netType, nets, id, deleteNet) {
    dispatch(cascadeIRSelectNets(netType, nets, id, deleteNet))
  },
  _deleteIRData(irId) {
    dispatch(deleteIRData(irId))
  },
  _updateIrLoad(irLoadSelect) {
    dispatch(updateIrLoad(irLoadSelect))
  },
  _saveIRExplorerInfo(info) {
    dispatch(saveIRExplorerInfo(info))
  },
  saveIRVRMComponent(row, vrm) {
    dispatch(saveIRVRMComponent(row, vrm))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _changeShowNameStatus(status, designID) {
    dispatch(changeShowNameStatus(status, designID))
  },
  _selectLayer(layers, designId) {
    dispatch(selectLayer(layers, designId))
  },
  searchVRMLoading(boolean) {
    dispatch(searchVRMLoading(boolean))
  }
})

export default connect(mapState, mapDispatch)(IRTableContent);