import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import EditableTable from '@/components/EditableTable';
import { checkRLCValue } from '@/services/helper/dataProcess';
import {
  updateInterfaces,
  /* editComponentType,
      saveReExtraction */
} from '../../store/andes/action';
import { componentTypeChange, checkComponentsType } from '@/services/Andes'
import RLCModel from './rlcModel';
import { SortFn } from '@/services/helper/sort';
import '../index.css';

const RLCType = ['Cap', 'Ind', 'Res'];
const compColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '25%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Components',
  dataIndex: 'comps',
  width: '25%',
}, {
  title: 'Usage',
  dataIndex: 'type',
  width: '25%',
  sorter: (a, b) => a.type.localeCompare(b.type),
}, {
  title: 'Model',
  dataIndex: 'value',
  width: '25%'
}];

class ComponentTable extends PureComponent {
  constructor(props) {
    super(props);

    compColumns[1].render = (comps, record) => {
      const names = comps.map(item => item.name);
      return <div
        className='andes-table-comp'
        /* onClick={() => this.componentSelect(record, names)} */>
        <span>{names.join(', ')}</span>
      </div>
    };

    compColumns[2].onCell = (record) => {
      const options = this.getCompType(record.type, record.comps[0].name);
      return {
        record,
        edit: 'select',
        options: options,
        dataIndex: 'type',
        handleSave: this.editComponentType
      };
    }

    compColumns[3].render = (text, record) => {
      if (RLCType.includes(record.type)) {
        if (record.type === 'Cap') {
          if (toString.call(text) === '[object Object]') {
            return <span>
              R = {(text.r && (text.r + 'Ω')) || '0Ω'}, L = {(text.l && (text.l + 'H')) || '0H'}, C = {(text.c && (text.c + 'F')) || '0F'}
            </span>
          } else {
            return <span>{text}</span>
          }
        } else {
          return <span>{text}</span>
        }
      } else {
        return <span>{text}</span>
      }
    }

    compColumns[3].onCell = (record) => {
      if (RLCType.includes(record.type)) {
        if (record.type === 'Cap') {
          return {
            record,
            edit: true,
            customInput: RLCModel,
            dataIndex: 'value',
            saveRLCValue: this.saveRLCValue
          };
        } else {
          return {
            record,
            edit: true,
            dataIndex: 'value',
            handleSave: this.editComponentValue,
          }
        }
      } /* else if (record.type === 'Controller' || record.type === 'Device' || record.type === 'Connector') {
        return {
          record,
          edit: true,
          dataIndex: 'value',
          handleSave: this.editComponentValue,
        }
      } */ else {
        return {
          edit: false,
        }
      }
    }
  }

  saveRLCValue = (record, value) => {
    const { comps, pcb, pcbId } = record;
    const { Interfaces } = this.props;
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let components = [...editInterface.components];
    comps.forEach(item => {
      const compIndex = components.findIndex(comp => comp.name === item.name);
      components[compIndex].value = value;
    })

    _Interfaces[_index].content.components = [...components];
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
  }

  getCompType = (type, name) => {
    let options = [];
    if (RLCType.includes(type)) {
      const _type = checkComponentsType(name);
      options = [_type, 'Unused'];
    } else if (type === 'Connector') {
      options = ['Connector', 'Unused'];

    } else if (type === 'Unused' || type === 'Ignore') {
      const _type = checkComponentsType(name);
      if (RLCType.includes(_type)) {
        options = [_type, 'Unused'];
      } else if (_type === 'Connector') {
        options = ['Connector', 'Unused'];
      } else if (type === 'Controller' || type === 'Device') {
        options = ['Controller', 'Device', 'Unused'];
      } else {
        options = ['Controller', /* 'Connector', */ 'Cap', 'Device', 'Res', 'Ind', 'Unused'];
      }
    } else if (type === 'Controller' || type === 'Device') {
      options = ['Controller', 'Device', 'Unused'];
    }
    return options;
  }

  editComponentType = (row) => {
    const { type, pcb, pcbId, comps } = row;
    const { Interfaces } = this.props;
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let components = [...editInterface.components];
    let compNames = comps.map(item => item.name);
    if (type === 'Controller') {
      let ctrlComps = []
      components.forEach(item => {
        if (!compNames.includes(item.name) && item.type === 'Controller') {
          ctrlComps.push(item)
        }
      });
      if (ctrlComps.length > 0) {
        message.error('Only one usage of component is Controller.');
        return;
      }
    }
    comps.forEach(item => {
      const name = item.name;
      const prevType = item.type;
      const compIndex = components.findIndex(comp => comp.name === name);
      const component = components[compIndex];
      const { newComp } = componentTypeChange({ type, prevType, component });
      components[compIndex] = newComp;
    });
    _Interfaces[_index].content.components = [...components];
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
    /* this.props.saveReExtraction(_Interfaces[_index].interfaceId, 1); */
  }

  editComponentValue = (row) => {
    const { comps, pcb, pcbId, value } = row;
    const { Interfaces } = this.props;
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let components = [...editInterface.components];
    comps.forEach(item => {
      const compIndex = components.findIndex(comp => comp.name === item.name);
      components[compIndex].value = checkRLCValue(value);
    })

    _Interfaces[_index].content.components = [...components];
    this.props.updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
    /* this.props.saveReExtraction(_Interfaces[_index].interfaceId, 1); */
  }


  getComponents = (comps) => {
    const { current } = this.props;
    let _components = comps;
    if (current) {
      _components = comps.filter(item => item.pcb === current);
    }
    let _partComps = [], // [{part, comps, value, type}]
      partList = [];
    _components.forEach((comp) => {
      const { part, type, name, pins, pcb, pcbId } = comp;
      let usage = checkComponentsType(name);
      if (partList.includes(part) && usage === 'Cap') {
        if (RLCType.includes(type) || type === 'Unused') {
          const _findIndex = _partComps.findIndex(item => item.part === part);
          _partComps[_findIndex].comps.push({ name, pins, type });
        } else {
          _partComps.push({
            part,
            comps: [{ name, pins, type }],
            type,
            value: comp.value,
            pcb,
            pcbId
          });
        }
      } else {
        partList.push(part);
        _partComps.push({
          part,
          comps: [{ name, pins, type }],
          type,
          value: comp.value,
          pcb,
          pcbId
        });
      };
    });

    _partComps.forEach((item, index) => {
      let type = checkComponentsType(item.comps[0].name);
      item.index = index;
      const compsUsage = item.comps.map(item => item.type);
      if (compsUsage.includes('Ignore') && (type === 'Cap')) {
        item.type = 'Unused';
      }
    });
    const sort = ['Controller', 'Connector', 'Cap', 'Device', 'Res', 'Ind', 'Unused', 'Ignore'];
    _partComps = SortFn(_partComps, sort, 'type');
    return _partComps;
  }

  render() {
    const { components, below } = this.props;
    const dataList = this.getComponents(components) || [];
    let scrollX = null, scrollY = null, scroll = {};
    if (below && dataList && dataList.length && dataList.length > 0) {
      scrollX = 760;
    }
    if (dataList && dataList.length && dataList.length > 9) {
      scrollY = 342;
    }
    if (scrollY && scrollX) {
      scroll = {
        x: scrollX,
        y: scrollY
      }
    }

    if (!scrollY && scrollX) {
      scroll = {
        x: scrollX
      }
    }

    if (scrollY && !scrollX) {
      scroll = {
        y: scrollY
      }
    }
    return (
      <EditableTable
        rowKey={record => `${record.part}-${record.type}`}
        columns={compColumns}
        size="small"
        dataSource={dataList}
        scroll={scroll}
        className='andes-component-table space-10'
      />
    )
  }
}

const mapState = (state) => {
  const { AndesReducer: { andes, project: { repeaterList = [], spiceList = [], ibisList = [], designID, viewList }, explorer } } = state;
  const andesInfo = andes.andesInfo;
  let components = [], Interfaces = [], models = [];
  if (andesInfo) {
    components = andesInfo.info.components;
    Interfaces = andesInfo.Interfaces;
    models = andesInfo.info.models;
  }
  const { layers } = explorer;
  return {
    components,
    Interfaces,
    repeaterList,
    ibisList,
    spiceList,
    models,
    designID,
    viewList,
    layers
  }
};

const mapDispatch = (dispatch) => ({
  updateInterfaces({ Interfaces, pcb, pcbId }) {
    dispatch(updateInterfaces({ Interfaces, pcb, pcbId }));
  }
})

export default connect(mapState, mapDispatch)(ComponentTable);
