import React, { PureComponent, Fragment } from 'react';
import PDN from './PDNSetting';
import TopBar from './PDNSetting/TopBar';
import { topBarResize } from '@/services/helper/topBarResize';
import Result from '../result'
import projectDesigns from '@/services/helper/projectDesigns';
import { PDN as PDNConst, RESULT, PCB } from '../constants';
import ContentLayoutLR from '@/components/ContentLayout/layoutLR';
import PCBLayout from './pcbLayout';
import './content.css';

class LayoutLR extends PureComponent {
  topBarResize = () => {
    topBarResize('pdn-top-bar-block', 650);
  }

  getLeft = (width, bottomLinePosition) => {
    const { tabVisible, viewList, currentProjectPDNs, leftWidth } = this.props;
    const views = viewList.filter(item => ![PCB].includes(item));
    switch (views[0]) {
      case RESULT:
        return <Fragment>
          <div
            id='pdn-content-main'
            className='my-pdn-content'
            style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, overflow: 'hidden' }}
          >
            <Result
              layoutStatus={true}
              leftWidth={leftWidth}
              layoutLeftWidth={width}
            />
          </div>
        </Fragment>
      case PDNConst:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <Fragment>
          {currentProjectPDNs.length > 0 ? <div
            id='pdn-content-main'
            className='my-pdn-content'
            style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, top: 45 }}
          >
            <PDN />
          </div> : null}
        </Fragment>
      default: return;
    }
  }

  getRight = (width) => {
    const { leftWidth, viewList, currentProjectId } = this.props;
    if (viewList.includes(PCB) && projectDesigns.getAvailableDesignsLength(currentProjectId) > 0) {
      return <Fragment>
        <PCBLayout siderWidth={leftWidth + width} />
      </Fragment>
    };
    return null;
  }

  topBar = () => {
    return <TopBar />
  }

  render() {
    return (
      <ContentLayoutLR
        {...this.props}
        leftClassName='pdn-content-left'
        topBarResize={this.topBarResize}
        getLeft={this.getLeft}
        getRight={this.getRight}
        topBar={this.topBar}
        lineClassName='aurora-layout-split-screen'
      />
    )
  }
};

export default LayoutLR;

