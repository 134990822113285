import {
  LAYER_CHANGE,
  LOCATION_CHANGE,
  NET_CHANGE,
  CLEAR_SELECTION,
  CLOSE_PANEL,
  OPEN_PANEL,
  COLORBY_CHANGE,
  SELECTED_ALL,
  CANCEL_SELECTED_ALL,
  SHOW_SELECTED,
  HIDE_SELECTED,
  SELECTION_CHECKBOX_CHANGE,
  SELECTION_CLEAR_ONE,
  CLEAN_STATUS,
  CHANGE_SELECTED_DISPLAY,
  SELECT_CHANGE
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { updateSelected } from '../../../../services/helper/dataProcess';

const defaultState = {
  location: '',
  layers: [],
  selectedNets: [],
  selectedComps: [],
  selected: [],
  panelType: '',
  colorBy: 'net',
  displayBoxShow: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...state, location: action.location }
    case LAYER_CHANGE:
      return { ...state, layers: action.layers };
    case NET_CHANGE:
      let newNets = [];
      let stateNets = [...state.selected];
      action.nets.forEach(item => {
        const index = stateNets.findIndex(i => i.name === item);
        if (index < 0) {
          newNets.push({
            type: 'net',
            name: item,
            checked: true
          });
        }
      });

      stateNets.forEach(item => {
        if (action.nets.length > 0 && action.nets.includes(item.name)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      return {
        ...state,
        selectedNets: action.multi ? [...state.selectedNets, ...action.nets] : action.nets,
        selectedComps: [],
        selected: [...stateNets, ...newNets]
      };
    case SELECT_CHANGE:
      const newSelected = updateSelected(state.selected, action);
      return {
        ...state,
        selectedNets: action.multi ? [...state.selectedNets, ...action.nets] : action.nets,
        selectedComps: action.multi ? [...state.selectedComps, ...action.comps] : action.comps,
        selected: [...newSelected]
      };
    case SELECTION_CLEAR_ONE:
      const arrNames = action.arr.map(item => item.name)
      const newSelComps = state.selectedComps.filter(target => arrNames.indexOf(target) > -1)
      const newSelNets = state.selectedNets.filter(target => arrNames.indexOf(target) > -1)
      return {
        ...state,
        selectedComps: [...newSelComps],
        selectedNets: [...newSelNets],
        selected: action.arr
      }
    case SELECTED_ALL:
      let newSelects = [...state.selected];
      newSelects.forEach(i => {
        i.checked = true;
      });
      return {
        ...state,
        selected: [...newSelects]
      }
    case CANCEL_SELECTED_ALL:
      let select = [...state.selected];
      select.forEach(i => {
        i.checked = false;
      });
      return {
        ...state,
        selected: [...select]
      };
    case SHOW_SELECTED:
      let nets = [], comps = [];
      state.selected.forEach(net => {
        if (net.type === 'net' && net.checked) {
          nets.push(net.name)
        }
      });
      state.selected.forEach(comp => {
        if (comp.type === 'comp' && comp.checked) {
          comps.push(comp.name)
        }
      });
      return {
        ...state,
        selectedNets: [...nets],
        selectedComps: [...comps]
      }
    case HIDE_SELECTED:
      return {
        ...state,
        selectedNets: [],
        selectedComps: []
      }
    case SELECTION_CHECKBOX_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_SELECTION:
      let unSelected = [...state.selected];
      unSelected.forEach(item => {
        item.checked = false;
      })
      return {
        ...state,
        selectedNets: [],
        selectedComps: [],
        selected: [...unSelected]
      }
    case OPEN_PANEL:
      return { ...state, panelType: action.panel }
    case CLOSE_PANEL:
      return { ...state, panelType: '' }
    case COLORBY_CHANGE:
      return { ...state, colorBy: action.colorBy }
    case CLEAN_STATUS:
      return {
        ...defaultState,
      }
    case CHANGE_SELECTED_DISPLAY:
      return {
        ...state,
        displayBoxShow: action.show
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default:
      return state;
  }
}