const END_TO_END_CHANNEL_TYPE = "Andes_v2";

export const CREATE_END_TO_END_CHANNEL = `${END_TO_END_CHANNEL_TYPE}/create_end_to_end_channel`;
export const DELETE_END_TO_END_CHANNEL = `${END_TO_END_CHANNEL_TYPE}/delete_end_to_end_channel`;
export const GET_END_TO_END_CHANNEL_CONTENT = `${END_TO_END_CHANNEL_TYPE}/get_end_to_end_channel_content`;
export const OPEN_END_TO_END_CHANNEL = `${END_TO_END_CHANNEL_TYPE}/open_end_to_end_channel`;
export const UPDATE_END_TO_END_CHANNEL_INFO = `${END_TO_END_CHANNEL_TYPE}/update_end_to_end_channel_info`;
export const GET_END_TO_END_CHANNEL_LIST = `${END_TO_END_CHANNEL_TYPE}/get_end_to_end_channel_list`;
export const SAVE_END_TO_END_CHANNEL_TO_SERVER = `${END_TO_END_CHANNEL_TYPE}/save_end_to_end_channel_to_server`;
export const UPDATE_END_TO_END_CHANNEL_CONTENT = `${END_TO_END_CHANNEL_TYPE}/update_end_to_end_channel_content`;
export const SAVE_CHANNEL_SELECT = `I${END_TO_END_CHANNEL_TYPE}/save_channel_select`;
export const SAVE_CONNECTION = `${END_TO_END_CHANNEL_TYPE}/save_connection`;
export const CLEAR_END_TO_END_CHANNEL_INFO = `${END_TO_END_CHANNEL_TYPE}/clear_end_to_end_channel_info`;
export const AUTO_GET_END_TO_END_CHANNEL_LIST = `${END_TO_END_CHANNEL_TYPE}/auto_get_end_to_end_channel_list`;
export const UPDATE_CONNECTION_MODEL_SETUP = `${END_TO_END_CHANNEL_TYPE}/update_connection_model_setup`;
export const CHANGE_END_TO_END_SIGNAL_NAME = `${END_TO_END_CHANNEL_TYPE}/change_end_to_end_signal_name`;
export const DELETE_END_TO_END_SIGNAL = `${END_TO_END_CHANNEL_TYPE}/delete_end_to_end_signal`;
export const CHANGE_SELECTED_CHANNEL_SIGNAL = `${END_TO_END_CHANNEL_TYPE}/change_selected_channel_signal`;
export const ADD_END_TO_END_SIGNAL = `${END_TO_END_CHANNEL_TYPE}/add_end_to_end_connection_signal`;
export const ADD_PCB_IN_CONNECTION = `${END_TO_END_CHANNEL_TYPE}/add_pcb_in_connection`;
export const DELETE_PCB_CONNECTION = `${END_TO_END_CHANNEL_TYPE}/delete_pcb_connection`;
export const COPY_END_TO_END_CHANNEL = `${END_TO_END_CHANNEL_TYPE}/copy_end_to_end_channel`;
export const UPDATE_END_TO_END_SETUP_MSG_LIST = `${END_TO_END_CHANNEL_TYPE}/update_end_to_end_setup_msg_list`;
export const CHECK_END_TO_END_SETUP = `${END_TO_END_CHANNEL_TYPE}/check_end_to_end_channel_setup`;
export const AUTO_GET_END_TO_END_CHILDREN_CHANNEL_LIST = `${END_TO_END_CHANNEL_TYPE}/auto_get_end_to_end_children_channel_list`;
export const UPDATE_END_TO_END_REPLACE_MONITOR = `${END_TO_END_CHANNEL_TYPE}/update_end_to_end_replace_monitor`;
export const SAVE_SEASIM_CONFIG = `${END_TO_END_CHANNEL_TYPE}/save_seasim_config`;
export const UPDATE_EXIST_RESULT_STATUS = `${END_TO_END_CHANNEL_TYPE}/update_exist_result_status`;
export const UPDATE_CHANNEL_TOPOLOGY_LOADING = `${END_TO_END_CHANNEL_TYPE}/update_channel_topology_loading`;
export const UPDATE_ADS_CONFIG = `${END_TO_END_CHANNEL_TYPE}/update_ads_config`;
export const UPDATE_MULTI_COMPLIANCE_CONFIG = `${END_TO_END_CHANNEL_TYPE}/update_multi_compliance_config`; 
export const END_TO_END_CHANNEL_RENAME = `${END_TO_END_CHANNEL_TYPE}/end_to_end_channel_rename`;