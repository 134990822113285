import { call, cancel, put, select, take, takeEvery, fork, delay } from 'redux-saga/effects';
import {
  GET_VERIFICATION_CONTENT,
  UPDATE_SELECT_INTERFACE_INFO,
  SAVE_CURRENT_SSN_VERIFICATION,
  UPDATE_SSN_PDN_CONTENT,
  JUMP_TO_PACKAGE_PDN,
  UPDATE_SSN_PART_CONTENT,
  UPDATE_PATTERN_LIBRARY,
  UPDATE_VERIFICATION_IDS,
  SAVE_CONTENT_INFO,
  SAVE_SSN_CONTENT,
  CLEAR_SSN_INFO,
  UPDATE_BUFFER_MODEL_BY_CSM
} from './actionType';
import { updateSSNCentricInfo, updateCentricContentLoading, changeSelectInterfaceInfo, updatePatternSweepTable, updatePatternSweepInfo, updateSomeContentInfo, updateBufferModelByCSM } from './action';
import { getVerificationContent, cleanSetupInfo, updateErrorCheckList } from '../rocky/action';
import { getCentricContentPromise, updateSSNCentricContentPromise, updateSSNBufferModelByCSM } from '@/services/Rocky';
import RockySSNChannelInfo from '../../../../services/Rocky/SSN/channelsInfo';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { getInterfaceMonitor, updateStimulationMessage } from '../simulation/action';
import { changeTabMenu } from '../../../MonitorStore/action';
import { startRockySSNVerification } from '../simulation/ssn/action';
import { getPDNBeforeGetContent, initPCBPackageContent, saveVerificationContentToServer } from '../rocky/saga';
import { getCLKVerificationContent } from '../rocky/action';
import { updateTreeSelectKeys } from '@/services/helper/filterHelper';
import { ROCKY } from '../../../../constants/pageType';
import packageVerificationConstructor from '@/services/Rocky/PackageHelper';
import { CARD_RESULT, CARD_VERIFICATION, PACKAGE_PDN, PACKAGE_PDN_RESULT, PACKAGE_RESULT, PACKAGE_VERIFICATION, RESULT, VERIFICATION } from '../../../../constants/treeConstants';
import { PROJECT_V2 } from '../../../../constants/projectVersion';
import { ssnErrorCheck } from '../../errorCheck/ErrorCheck';
import { saveModelPreLayoutToServer } from '../prelayout/saga';
import { getSpiceModelList } from '../../../../services/Rocky/library';
import { PDN_EXTRACTION, SIGNAL_EXTRACTION, SINGLE_PATTERN, SSN_CHANNEL } from '../../../../services/Rocky/constants';
import _ from "lodash";
import { getNewRunType } from '../../../../services/Rocky/SSN/onDieHelper';

let getPdnInfoTask = null;
function* getSSNCentricVerificationContent(action) {
  const { id, notUpdateLog } = action;
  try {
    yield put(updateCentricContentLoading(true))

    let response = yield call(getCentricContentPromise, id);
    if (getPdnInfoTask) {
      yield cancel(getPdnInfoTask);
    }
    getPdnInfoTask = yield fork(getUpdatePdnInfo, response, id, notUpdateLog)
  } catch (error) {
    yield put(updateCentricContentLoading(false))
    console.error(error)
  }
}

function* getUpdatePdnInfo(response, id, notUpdateLog) {
  try {
    if (!notUpdateLog) {
      const { initDataCheck } = response;
      if (initDataCheck && (initDataCheck.pcbPdn || initDataCheck.packagePdn || initDataCheck.pcbSignal || initDataCheck.packageSignal)) {
        yield call(initPCBPackageContent, { ...response, initDataCheck })
        response = yield call(getCentricContentPromise, id)
      }
    }
    const _content = response ? response.content : null
    if (_content) {
      const onDies = _content.pdn && _content.pdn.onDies ? _content.pdn.onDies : [];
      let isUpdateData = false;
      let _onDies = []
      for (let onDieInfo of onDies) {
        const { onDie } = onDieInfo;
        let _onDieInfo = JSON.parse(JSON.stringify(onDie))
        if (onDie && onDie.type === "SPICE" && onDie.subckt && onDie.libraryId && (!onDie.nodes || !onDie.nodes.length)) {
          // not have nodes
          let nodes = [];
          const res = yield call(getSpiceModelList, { libraryId: onDie.libraryId, fileName: onDie.fileName })
          if (res && res.models && res.models.length) {
            const findModel = res.models.find(item => item.name === onDie.subckt);
            if (findModel && findModel.ports) {
              nodes = findModel.ports.map(item => {
                const pin = onDie.pairs.find(it => it.node === item);
                return {
                  node: item,
                  ioNames: [],
                  pin: pin && pin.pin ? pin.pin : ""
                }
              })
              isUpdateData = true;
              _onDieInfo.nodes = nodes;
            }
          }
        } else if (onDie && onDie.type === "Touchstone" && onDie.libraryId) {
          isUpdateData = true;
          _onDieInfo = {
            cdieValue: _onDieInfo.cdieValue || "100n",
            rdieValue: _onDieInfo.rdieValue || "10m",
            type: "value"
          }
        }
        _onDies.push({
          ...onDieInfo,
          onDie: _onDieInfo
        })
      }
      if (isUpdateData) {
        _content.pdn.onDies = _onDies;
        response.content = _content;
        yield call(updateSSNCentricContentPromise, { content: _content, id: response.id })
      }
    }
    RockySSNChannelInfo.setInfo(id, response)
    yield put(updateSSNCentricInfo({ ...response, channelId: id }))
    if (response && response.ssn && response.ssn.length && !notUpdateLog) {
      const dataType = [VERIFY_RUNNING, WAITING].includes(response.mergeStatus) ? PDN_EXTRACTION : SINGLE_PATTERN;
      yield put(changeTabMenu({
        currentVerificationId: response.verificationId,
        verificationName: response.name,
        dataType: dataType
      }))
      yield put(getInterfaceMonitor(response.verificationId, SSN_CHANNEL, null, dataType, true))

    }
    yield put(updateCentricContentLoading(false))
  } catch (error) {
    yield put(updateCentricContentLoading(false))
    console.error(error)
  }
}

function* updateSelectInterfaceInfo(action) {
  const { info } = action;
  const { close, id, name } = info;
  const { RockyReducer: { rockySSN: { channelId, ssn, verificationId }, rocky: { infoErrorCheck } } } = yield select();
  if (close) {
    yield call(saveVerificationContentToServer);
    let infoError = [];
    infoErrorCheck.forEach(item => {
      if (item.verificationId === verificationId) {
        let error = item.errorCheck && item.errorCheck.error && item.errorCheck.error.length ? item.errorCheck.error.filter(item => item.type || !item.includes('==> The settings of')) : [];
        infoError.push({ ...item, errorCheck: { error }, interfaceErrorList: [] })
      }
    })
    yield put(updateErrorCheckList(infoError))
    yield put(cleanSetupInfo(true))
    yield put(changeSelectInterfaceInfo({}))
    yield call(getSSNCentricVerificationContent, { id: channelId, notUpdateLog: true })
  } else {
    if (name.includes("CLK_ADR_CMD")) {
      const nameList = ["CLK_ADR", "CLK_CMD"].map(item => {
        const _name = name.replace("CLK_ADR_CMD", item)
        return _name
      })
      const CLKIdList = ssn.filter(item => nameList.includes(item.name)).map(item => item.id)
      yield put(getCLKVerificationContent(CLKIdList))
      yield put(changeSelectInterfaceInfo({ id, name }))
      return
    }

    yield put(getVerificationContent(id))
    yield put(changeSelectInterfaceInfo({ id, name }))
  }
}

function* saveSSNVerification(action) {
  // const { verificationIds: openSSNVerificationIds, isSSN, ssnVerificationId } = action;
  const { RockyReducer: { rockySSN: { verificationId, content, id, /* designId, ssn  */ } } } = yield select();
  const { verificationIds } = content;
  let _verificationIds = [...(verificationIds || [])];

  if (_verificationIds && _verificationIds.length) {
    /* const interfaceList = ssn || [];
    const packageLayout = interfaceList && interfaceList.length ? interfaceList[0].soc : null;
    const packageId = packageLayout && packageLayout.pkg ? packageLayout.pkg.packageId : null;
    const pkg = designConstructor.getDesign(packageId) || {};
    const packageIsPreLayout = pkg.vendor === PRE_LAYOUT;
    const pcb = designConstructor.getDesign(designId) || {};
    const pcbIsPreLayout = pcb.vendor === PRE_LAYOUT; */
    // const verificationId = ssnVerificationId ? ssnVerificationId : _verificationIds[0]
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        _verificationIds = _verificationIds.filter(item => item !== verificationId);
      }
    }
    if (!_verificationIds.length) {
      return;
    }
    if (lastTask) {
      yield cancel(lastTask);
    }
    yield call(saveModelPreLayoutToServer, { updateImmediately: true })
    yield call(saveSSNContentVerifications, { id, content });
    yield put(updateStimulationMessage(verificationId));
    yield put(startRockySSNVerification({
      verificationIds: _verificationIds,
      currentVerificationId: verificationId,
      isSSN: true,
      openSSNVerificationIds: verificationIds,
      ssnVerificationId: verificationId
      /*  packageIsPreLayout,
       pcbIsPreLayout */
    }));
  }
}

function* updateSSNPdnContent(action) {
  const { pdn, notLoading, updateCSMInfo } = action;
  const { RockyReducer: { rockySSN: { id, content } } } = yield select();

  try {
    if (!notLoading) {
      yield put(updateCentricContentLoading(true))
    }
    let _runType = content.runType, _openPatternSweep = content.openPatternSweep
    if (pdn.onDies && pdn.onDies.length) {
      _runType = yield call(getNewRunType, { onDies: pdn.onDies, oldOnDie: content.pdn.onDies, runType: _runType });
      const findOnDie = pdn.onDies.find(item => item.onDie.type === "CSM");
      if (findOnDie) {
        _openPatternSweep = false
      }
    }

    const data = { content: { ...content, pdn, runType: _runType, openPatternSweep: _openPatternSweep }, id }
    const response = yield call(updateSSNCentricContentPromise, data)
    RockySSNChannelInfo.setInfo(response.channelId, response)
    if (updateCSMInfo) {
      //Update interface buffer model by csm model pairs
      yield call(_updateSSNBufferModelByCSM, { id, updateCSMInfo })
    }
    yield put(updateSSNCentricInfo({ ...response }))
    yield call(updateSSNError)
  } catch (error) {
    console.error(error)
  }
  yield put(updateCentricContentLoading(false))
}

function* jumpToPackagePdn(action) {
  const { id } = action;
  try {
    const { RockyReducer: { project: { selectedKeys, pcbLayout, layout, viewList } } } = yield select();

    const selecteds = updateTreeSelectKeys({
      prevSelectedKeys: [...selectedKeys],
      key: PACKAGE_PDN,
      eventKey: `${PACKAGE_PDN}-${id}`,
      selected: true,
      layout,
      pcbLayout,
      productType: ROCKY
    });

    let list = [];
    if (layout !== 3) {
      list = [...viewList, PACKAGE_PDN];
      list = [...new Set(list)];
      list = list.filter(item => ![RESULT, PACKAGE_VERIFICATION, PACKAGE_RESULT, CARD_VERIFICATION, CARD_RESULT, VERIFICATION, PACKAGE_PDN_RESULT].includes(item));
    } else {
      list = [PACKAGE_PDN];
    }

    const current = packageVerificationConstructor.getPackagePDN(id)
    // yield put(openTabFooter())
    // yield put(changeTabMenu({
    //   menuType: "simulation",
    //   tabSelectKeys: ['monitor'],
    //   verificationName: current ? current.name : null,
    //   currentVerificationId: id
    // }))
    // yield put(updateViewList())
    // this.props._openPage({ pageType: PACKAGE_PDN, id });
    // selecteds.length && this.props._updateTreeSelectedKeys(selecteds);
  } catch (error) { console.error(error) }
}

function* updateSSNPartContent(action) {
  const { updateInfo } = action;
  const { RockyReducer: { rockySSN: { id, content } } } = yield select();
  try {
    const data = { content: { ...content, ...updateInfo }, id }
    yield put(updateSomeContentInfo({ ...updateInfo }))
    yield call(updateSSNError)
    const response = yield call(updateSSNCentricContentPromise, data)
    RockySSNChannelInfo.setInfo(response.channelId, response)
  } catch (error) {
    console.error(error)
  }
}

function* updateSSNError(action) {
  // update ssn msg
  const { RockyReducer: { rockySSN: { channelId, content, ssn, verificationId }, rocky: { infoErrorCheck }, project: { onDieTouchstoneList, onDieSpiceList, pdnTouchstoneList, pdnSpiceList } } } = yield select();
  const findIndex = infoErrorCheck.findIndex(item => item.verificationId === verificationId);
  if (findIndex > -1) {
    if (infoErrorCheck[findIndex].errorCheck && infoErrorCheck[findIndex].errorCheck.error.length) {
      let errorCheck = ssnErrorCheck({ ssn, content, libraryInfo: { onDieTouchstoneList, onDieSpiceList, pdnTouchstoneList, pdnSpiceList } })
      infoErrorCheck[findIndex].errorCheck.error = [...errorCheck];
      yield put(updateErrorCheckList(infoErrorCheck));
    }
  }
}

function* updatePatternLibrary(action) {
  const { libraryId } = action;
  try {
    const { RockyReducer: { rockySSN: { content } } } = yield select();
    const patternSweeps = content && content.patternSweeps ? content.patternSweeps : [];
    if (patternSweeps && patternSweeps.length) {
      for (let patternSweep of patternSweeps) {
        const { patternList } = patternSweep;
        const filterLibraryId = patternList.filter(item => item.libraryId === libraryId);
        if (filterLibraryId && filterLibraryId.length) {
          yield put(updatePatternSweepTable(true))
          return
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* saveSSNContentVerifications({ id, content }) {
  try {
    const data = { content, id }
    const response = yield call(updateSSNCentricContentPromise, data)
    RockySSNChannelInfo.setInfo(response.channelId, response)
  } catch (error) {
    console.error(error)
  }
}

function* debounce(callback, action, time = 0) {
  yield delay(time);
  yield call(callback, action);
}

let lastTask;
function* updateVerificationIds(action) {
  const { updateInfo } = action;
  const { RockyReducer: { rockySSN: { id, content, ssn } } } = yield select();
  let _updateInfo = JSON.parse(JSON.stringify(updateInfo))
  try {
    if (updateInfo.hasOwnProperty("verificationIds")) {
      const filterssn = updateInfo.verificationIds && updateInfo.verificationIds.length ? ssn.filter(item => updateInfo.verificationIds.includes(item.id)) : [];
      const names = filterssn.map(item => item.name);
      const _patternSweeps = content.patternSweeps.map(item => {
        if (names.includes(item.signalGroup) && item.ifRun) {
          return { ...item }
        }
        return { ...item, ifRun: false }
      })
      _updateInfo.patternSweeps = _patternSweeps;
    }
    yield put(updateSomeContentInfo({ ..._updateInfo }))
    yield call(updateSSNError)
    if (lastTask) {
      yield cancel(lastTask);
    }
    const _content = { ...content, ..._updateInfo }
    lastTask = yield fork(debounce, saveSSNContentVerifications, { id, content: _content }, 2000);
  } catch (error) {
    console.error(error)
  }
}

function* saveSSNContent(action) {
  const { RockyReducer: { rockySSN: { id, content }, project: { projectVersion } } } = yield select();
  try {
    if (projectVersion === PROJECT_V2 && id && content && content.pdn) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      yield call(saveSSNContentVerifications, { id, content });
    }
  } catch (error) { console.error(error) }
}

function* cleanSSNInfo() {
  if (getPdnInfoTask) {
    yield cancel(getPdnInfoTask);
  }
}

function* _updateSSNBufferModelByCSM(action) {
  const { id, updateCSMInfo } = action;
  try {
    const { csmPairs, prevCsmPairs, isFileUpdate } = updateCSMInfo;
    if (isFileUpdate || !_.isEqual(csmPairs, prevCsmPairs)) {
      yield call(updateSSNBufferModelByCSM, id)
    }
  } catch (error) {
    console.error(error)
  }
}

function* rockySSNSaga() {
  yield takeEvery(GET_VERIFICATION_CONTENT, getSSNCentricVerificationContent);
  yield takeEvery(UPDATE_SELECT_INTERFACE_INFO, updateSelectInterfaceInfo);
  yield takeEvery(SAVE_CURRENT_SSN_VERIFICATION, saveSSNVerification);
  yield takeEvery(UPDATE_SSN_PDN_CONTENT, updateSSNPdnContent);
  yield takeEvery(JUMP_TO_PACKAGE_PDN, jumpToPackagePdn);
  yield takeEvery(UPDATE_SSN_PART_CONTENT, updateSSNPartContent);
  yield takeEvery(UPDATE_PATTERN_LIBRARY, updatePatternLibrary);
  yield takeEvery(UPDATE_VERIFICATION_IDS, updateVerificationIds);
  yield takeEvery(SAVE_SSN_CONTENT, saveSSNContent);
  yield takeEvery(CLEAR_SSN_INFO, cleanSSNInfo);
  yield takeEvery(UPDATE_BUFFER_MODEL_BY_CSM, _updateSSNBufferModelByCSM)
}
export default rockySSNSaga;