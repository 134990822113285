import React, { Component } from 'react';
import EditableTable from '@/components/EditableTable';

const Columns = [{
  title: 'Resistance',
  dataIndex: 'R',
  width: '50%'
}, {
  title: 'Inductance',
  dataIndex: 'L',
  width: '50%',
}];

class CalculateTable extends Component {
  render() {
    const { title, dataSource } = this.props;
    const data = dataSource.map((record, index) => ({ ...record, index }))
    return (
      <div>
        <span className='pdn-cacl-table-title font-bold'>{title}</span>
        <EditableTable
          rowKey={(record) => record.index}
          size='small'
          columns={Columns}
          dataSource={data}
          className='pdn-cacl-table-edit'
        />
      </div>
    )
  }
}

export default CalculateTable;