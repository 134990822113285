
import { CHANNELS, CHANNEL, ROCKY_PDN, DDRTYPE, DEBUG_DDRTYPE, LPDDR5, PACKAGES, DIMM_DDR_TYPES, CARDS, CARD, CARD_VERIFICATION, CARD_CHANNEL, CARD_RESULT, SSN_CHANNEL, PACKAGE_PDN, BMAS } from '../constants';
import { PCB_PRE_LAYOUT, PCB, VERIFICATION, RESULT, PROJECT, PCBS, PACKAGE_VERIFICATION_CREATE, PACKAGE_CHANNEL_CREATE, SSN_RESULT, PACKAGE_PDN_RESULT, PCB_CHANNEL, PCB_CHANNEL_RESULT, PCB_PDN_RESULT, PACKAGE, PACKAGE_PRE_LAYOUT, PCB_PRE_LAYOUT_RESULT, PACKAGE_PRE_LAYOUT_RESULT } from '@/constants/treeConstants';
import { treeDataType } from '../../tree/treeItem';
import { PRE_LAYOUT as PRELAYOUT } from '@/constants/designVendor';
import { DESIGN_SUCCESS } from '@/constants/designCategory';
import getDevelopMode from '../../api/cookies';
import { PACKAGE_INDEX, PROJECT_INDEX } from '@/services/Rocky';
import { PROJECT_V2, PROJECT_BMA } from '../../../constants/projectVersion';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';


/* Project item*/
function projectItem({ id, name, ddrType, dataTypePrefix, version }) {
  this.id = id;
  this.name = name;
  this.key = treeDataType(PROJECT, dataTypePrefix) + '-' + this.id;
  this.dataType = treeDataType(PROJECT, dataTypePrefix); // tree type
  this.type = ddrType; // ddr project type DDR3|DDR3L|DDR4|DDR4L|DDR5|LPDDR3|LPDDR4|DDR4L
  this.nodeClass = 'tree-node-project-name';
  this.children = []; // [projectChild]
  this.projectVersion = version
}

/**
 * Project children item
 *
 * @param {*} { name, id, dataType } name - 'PCB'|'Interface'
 *                                   key - PCBS|CHANNELS-id id: projectId
 *                                   dataType - PCBS|CHANNELS
 */
function projectChild({ name, id, dataType, iconDisabled }) {
  this.name = name; // 'PCB'|'Interface'
  this.key = dataType + '-' + id; // id - projectId
  this.dataType = dataType; // dataType
  this.nodeClass = '';
  if (name === PCB) {
    this.iconDisabled = iconDisabled
  }
}

function aProjectChild(id, dataTypePrefix, ddrType, version) {
  // let menu = ['PCB', 'Package', 'Interface'],
  //   projectChildTypes = [PCBS, PACKAGES, CHANNELS];

  // if (version === PROJECT_V2) {
  //   menu = ['Package', 'PCB', 'Interface'];
  //   projectChildTypes = [PACKAGES, PCBS, CHANNELS];
  // }

  // if (DIMM_DDR_TYPES.includes(ddrType)) {
  //   menu = version === PROJECT_V2 ? ['Package', 'PCB', 'Card', 'Interface'] : ['PCB', 'Package', 'Card', 'Interface'];
  //   projectChildTypes = version === PROJECT_V2 ? [PACKAGES, PCBS, CARDS, CHANNELS] : [PCBS, PACKAGES, CARDS, CHANNELS];
  // }
  const { menu, projectChildTypes } = getMenuChildType(version, ddrType)

  return menu.map((name, index) => new projectChild({
    name: name,
    id: id, // projectId
    dataType: treeDataType(projectChildTypes[index], dataTypePrefix),
    dataTypePrefix
  }));
};

function getDefaultProjectTree(arr, dataTypePrefix) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const projectList = arr.map(item => new projectItem({
    id: item.id,
    name: item.name,
    ddrType: item.ddrType,
    version: item.rockyVersion
  }));
  const projects = projectList.map(item => {
    item.children = aProjectChild(item.id, dataTypePrefix, item.type, item.projectVersion);
    return item;
  });
  return { projects, projectList }
}

/**
 * Add designs and channels to project
 *
 * @param {*} { arr, projectId } arr - [designs, channels]
 * @returns
 */
function updateProjectChild({ arr, projectId, dataTypePrefix, ddrType, version }) {
  const { menu, projectChildTypes } = getMenuChildType(version, ddrType)
  const children = menu.map((name, index) => {
    let _projectChild = new projectChild({
      name: name,
      id: projectId,
      dataType: treeDataType(projectChildTypes[index], dataTypePrefix),
      iconDisabled: arr[0].filter(item => item.category === DESIGN_SUCCESS).length >= 1 ? true : false,
      dataTypePrefix
    });
    _projectChild.children = arr[index];
    return _projectChild;
  });
  return children;
}

function getMenuChildType(version, ddrType) {
  let menu = ['PCB', 'Package', 'Interface'],
    projectChildTypes = [PCBS, PACKAGES, CHANNELS];

  switch (version) {
    case PROJECT_BMA:
      // menu = [];
      // projectChildTypes = [];
      menu = ['PCB'];
      projectChildTypes = [PCBS];
      break
    default:
      if (DIMM_DDR_TYPES.includes(ddrType)) {
        menu = ['PCB', 'Package', 'Card', 'Interface'];
        projectChildTypes = [PCBS, PACKAGES, CARDS, CHANNELS];
      }
      break
  }
  return { menu, projectChildTypes }
}
/**
 * Design Item
 *
 * @param {Object} { id, name, projectId, designVersion, vendor } id - designId
 *                                                           name - designName, 
 *                                                           projectId - projectId, 
 *                                                           designVersion - designVersion, 
 *                                                           vendor - vendor
 *                                                           category - category
 */
function designItem({ id, name, projectId, designVersion, vendor, dataTypePrefix, category, type = PCB }) {
  const preLayoutType = type === PACKAGE ? PACKAGE_PRE_LAYOUT : PCB_PRE_LAYOUT;
  this.id = id;
  this.name = name;
  this.key = vendor === PRELAYOUT ? (treeDataType(preLayoutType, dataTypePrefix) + '-' + id) : (treeDataType(type, dataTypePrefix) + '-' + id);
  this.dataType = vendor === PRELAYOUT ? treeDataType(preLayoutType, dataTypePrefix) : treeDataType(type, dataTypePrefix);
  this.projectId = projectId;
  this.designVersion = designVersion;
  this.vendor = vendor;
  this.nodeClass = `tree-node-pcb-name rocky-${this.dataType}-tree-node`;
  this.category = category
};

function getDesignTree(arr, dataTypePrefix, type, pcbChannel = [], pcbPDN = [], version) {
  if (!Array.isArray(arr)) {
    return [];
  };
  let designs = [];

  for (let item of arr) {
    const info = new designItem({
      id: item.id,
      name: item.name,
      projectId: item.projectId,
      designVersion: item.designVersion,
      vendor: item.vendor,
      dataTypePrefix,
      category: item.category,
      type
    })
    if (item.vendor === PRE_LAYOUT && version === PROJECT_V2) {
      const dataType = type === PACKAGE ? PACKAGE_PRE_LAYOUT_RESULT : PCB_PRE_LAYOUT_RESULT;
      let result = new verificationChild({ id: item.id, name: "Result", dataType });
      info.children = [result]
    }
    designs.push(info)
  }

  if (pcbChannel.length || pcbPDN.length) {
    for (let design of designs) {
      const filterChannel = pcbChannel.filter(item => item.designId === design.id);
      if (filterChannel && filterChannel.length && design.vendor !== PRE_LAYOUT) {
        const pdn = pcbPDN.find(item => item.designId === design.id);
        const children = [];
        if (pdn) {
          let result = new verificationChild({ id: pdn.id, name: 'Result', dataType: PCB_PDN_RESULT });
          const pcbPdn = new packagePDNItem({ ...pdn, dataTypePrefix: 'PCB', children: [result] })
          children.push(pcbPdn)
        }
        const pcbChannels = filterChannel.map(item => {
          let channelItem = new pcbChannelItem({
            id: item.id,
            name: item.name,
            designId: item.designId,
            dataTypePrefix,
            verificationId: item.pcbChannelVerificationId,
            status: item.pcbChannelStatus
          })
          let result = new verificationChild({ id: item.id, name: "Result", dataType: PCB_CHANNEL_RESULT });
          channelItem.children = [result];
          return channelItem
        })
        design.children = [...children, ...pcbChannels]
      }
    }
  }
  return designs;
};

function pcbChannelItem({ id, name, designId, dataTypePrefix, children = [], verificationId, status }) {
  const _type = PCB_CHANNEL;
  this.id = id;
  this.name = name;
  this.key = treeDataType(_type, dataTypePrefix) + "-" + id;
  this.dataType = treeDataType(_type, dataTypePrefix);
  this.designId = designId;
  this.nodeClass = 'tree-node-pcb-channel-name';
  this.children = children;
  this.verificationId = verificationId;
  this.status = status;
}

function channelItem({ id, name, designId, dataTypePrefix, cardType, isSSN, verificationId, status }) {
  const _type = cardType ? CARD_CHANNEL : isSSN ? SSN_CHANNEL : CHANNEL;
  this.id = id;
  this.name = name;
  this.key = treeDataType(_type, dataTypePrefix) + "-" + id;
  this.dataType = treeDataType(_type, dataTypePrefix);
  this.designId = designId;
  this.children = [];
  this.verificationId = verificationId;
  this.status = status;
  this.nodeClass = isSSN ? "tree-node-channel-name tree-node-ssn-channel-name" : 'tree-node-channel-name';
}

function verificationItem({ id, name, channelName, channelId, status, readyForSim, designId, designSubId, subId, version, type, rockyPdnId, dataTypePrefix, designVersion, cardType }) {
  const _type = cardType ? CARD_VERIFICATION : VERIFICATION;
  this.id = id;
  this.name = name;
  this.channelName = channelName;
  this.channelId = channelId;
  this.key = type === ROCKY_PDN ? treeDataType(_type, dataTypePrefix) + '-' + id + '-' + rockyPdnId : treeDataType(_type, dataTypePrefix) + '-' + id;
  this.dataType = treeDataType(_type, dataTypePrefix);
  this.status = status;
  this.readyForSim = readyForSim;
  this.designId = designId;
  this.designSubId = designSubId;
  this.subId = subId;
  this.interfaces = [];
  this.children = [];
  this.version = version;
  this.nodeClass = cardType ? "tree-node-card-name tree-node-interface-name" : 'tree-node-interface-name';
  this.type = type; //"Rocky_PDN" 
  this.rockyPdnId = rockyPdnId;
  this.designVersion = designVersion;
}

function interfaceInfo({ name, id, subId }) {
  this.name = name;
  this.id = id;
  this.subId = subId;
};

/**
 *
 *
 * @param {*} { id, name, dataType } id - verificationId
 */
function verificationChild({ id, name, dataType }) {
  this.name = name;
  this.key = dataType + '-' + id;
  this.dataType = dataType;
  this.nodeClass = '';
}

function getChannelTree(arr, dataTypePrefix, isPreLayout) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const channels = arr.map(item => {
    const channelItem = updateChannelItem(item.verifications, item, dataTypePrefix, isPreLayout);
    return channelItem;
  });
  return channels;
};

// ChannelItem - item = { id, name, designId }
function updateChannelItem(verifications, item, dataTypePrefix, isPreLayout, cardType, isSSN = false) {
  let _channelItem = new channelItem({
    id: item.id,
    name: item.name,
    designId: item.designId,
    dataTypePrefix,
    cardType,
    isSSN,
    verificationId: item.verificationId || item.ssnChannelVerificationId,
    status: item.status || item.ssnChannelStatus
  });
  _channelItem.children = [];
  try {
    _channelItem.children = getVerificationTree(verifications, item, dataTypePrefix, isPreLayout, cardType);
    return _channelItem;
  } catch (error) {
    console.error(error);
    return _channelItem;
  }
}

function getVerificationTree(arr, channel, dataTypePrefix, isPreLayout = false, cardType = null) {
  let verifications = [];
  if (Array.isArray(arr)) {
    //Find pdn
    const findPDN = arr.find(it => it.typeName === ROCKY_PDN);
    if (!findPDN && !isPreLayout && !cardType) {
      //Add New PDN
      let pdn = new verificationItem({
        id: "",
        name: "PDN",
        channelName: channel.name,
        channelId: channel.id,
        status: "",
        readyForSim: 0,
        designSubId: "",
        subId: "",
        designId: "",
        version: "",
        type: ROCKY_PDN,
        rockyPdnId: "",
        dataTypePrefix,
        cardType
      });
      pdn.nodeClass = 'tree-node-interface-name rocky-pdn-name';
      verifications = [pdn];
    }

    const _verifications = arr.map(ve => {
      let _verificationItem = {};
      _verificationItem = new verificationItem({
        id: ve.id,
        name: ve.name,
        channelName: channel.name,
        channelId: channel.id,
        status: ve.status,
        readyForSim: ve.readyForSim,
        designSubId: ve.designSubId,
        subId: ve.subId,
        designId: ve.designId,
        designVersion: ve.designVersion,
        version: ve.version,
        type: ve.typeName,
        rockyPdnId: ve.rockyPdnId,
        dataTypePrefix,
        cardType
      });

      if (ve.interfaces && ve.interfaces.length > 0) {
        _verificationItem.interfaces = ve.interfaces.map(inter => new interfaceInfo({ name: inter.name, id: inter.id, subId: inter.subId }));
      }
      const resultType = cardType ? CARD_RESULT : RESULT;
      let result = new verificationChild({
        id: ve.id,
        name: 'Result',
        dataType: treeDataType(resultType, dataTypePrefix),
        cardType
      });
      result.status = ve.status;

      if (ve.typeName !== ROCKY_PDN) {
        _verificationItem.children = [result];
      }

      return _verificationItem;
    })
    verifications = [...verifications, ..._verifications];
  }
  return verifications;
}

function packageChannelItem({ name, dataTypePrefix, id, index, children = [] }) {
  this.name = name;
  this.key = treeDataType(CHANNEL, dataTypePrefix) + '-' + index + '-' + id;
  this.dataType = treeDataType(CHANNEL, dataTypePrefix);
  this.children = children;
  this.designId = id
}

function packageVerificationItem({ id, name, status, designId, dataTypePrefix, verificationId, channelName, packageType }) {
  this.id = id;
  this.name = name;
  this.key = treeDataType(VERIFICATION, dataTypePrefix) + '-' + id;
  this.dataType = treeDataType(VERIFICATION, dataTypePrefix);
  this.status = status;
  this.designId = designId;
  this.nodeClass = 'tree-node-package-interface-name';
  this.verificationId = verificationId;
  this.channelName = channelName;
  this.packageType = packageType;
}

function packagePDNItem({ id, name, verificationId, status, designId, dataTypePrefix, channelName, children, }) {
  this.id = id;
  this.name = name;
  this.key = treeDataType('PDN', dataTypePrefix) + '-' + id;
  this.dataType = treeDataType('PDN', dataTypePrefix);
  this.status = status;
  this.designId = designId;
  this.nodeClass = 'tree-node-package-interface-name tree-node-package-pdn-interface-name';
  this.verificationId = verificationId || id;
  this.channelName = channelName;
  this.children = children || [];
}

function getSSNPackagesTree(arr, pdn, packageChildren = [], notClearCreate) {
  if (!arr || !arr.length) { return [] };
  let arrNameList = [];
  let _channels = arr.map((item, index) => {
    const groupName = item.name;
    arrNameList.push(groupName)
    let verificationItem = new packageVerificationItem({
      id: item.id,
      name: item.name,
      status: item.status,
      designId: item.designId,
      dataTypePrefix: 'package',
      verificationId: item.verificationId,
      channelName: groupName,
      packageType: "SSN"
    })

    let result = new verificationChild({ id: item.id, name: 'Result', dataType: treeDataType(RESULT, 'package') });
    result.status = item.status;

    verificationItem.children = [result];
    return verificationItem;
  })

  if (notClearCreate) {
    //  Do not delete the added input when updating the list
    const packageVerificationCreateTree = packageChildren.find(item => item.dataType === PACKAGE_VERIFICATION_CREATE)
    if (packageVerificationCreateTree) {
      _channels.push(packageVerificationCreateTree)
    }
  }

  _channels = _channels.filter(item => item.dataType !== PACKAGE_PDN)
  if (pdn) {
    let result = new verificationChild({ id: pdn.id, name: 'Result', dataType: PACKAGE_PDN_RESULT });
    _channels = [new packagePDNItem({ ...pdn, dataTypePrefix: 'package', children: [result] }), ..._channels]
  }

  return _channels;
}

function getPackagesTree(arr, pdn, packageDesignId, packageChildren = [], notClearCreate, notClearAddChannel) {
  if (!Array.isArray(arr)) { return [] }
  let arrNameList = [];

  let _channels = arr.map((item, index) => {
    const { groupName, channels = [] } = item;
    arrNameList.push(groupName)
    let _channelItem = new packageChannelItem({
      id: packageDesignId,
      name: groupName,
      dataTypePrefix: 'package',
      index,
      children: []
    })
    try {
      _channelItem.children = channels.map(item => {
        let verificationItem = new packageVerificationItem({
          id: item.id,
          name: item.name,
          status: item.status,
          designId: item.designId,
          dataTypePrefix: 'package',
          verificationId: item.verificationId,
          channelName: groupName
        })

        let result = new verificationChild({ id: item.id, name: 'Result', dataType: treeDataType(RESULT, 'package') });
        result.status = item.status;

        verificationItem.children = [result];
        return verificationItem
      })

      if (notClearCreate) {
        // Do not delete the added input when updating the list
        const packageChannelTree = packageChildren.find(item => item.name === groupName)
        const packageVerificationCreateTree = packageChannelTree && packageChannelTree.children.length ? packageChannelTree.children.find(item => item.dataType === PACKAGE_VERIFICATION_CREATE) : null
        if (packageVerificationCreateTree) {
          _channelItem.children.push(packageVerificationCreateTree)
        }
      }

    } catch (error) {
      console.error(error);
    }
    return _channelItem
  })

  if (notClearAddChannel) {
    //  Channels that have not added interfaces will not be deleted when updating the list
    const filterPackageChannel = packageChildren.filter(item => !arrNameList.includes(item.name) && item.dataType !== PACKAGE_CHANNEL_CREATE)
    if (filterPackageChannel && filterPackageChannel.length) {
      _channels = [..._channels, ...filterPackageChannel]
    }
  }

  if (notClearCreate) {
    // Do not delete the added input when updating the list
    const packageChannelCreateTree = packageChildren.find(item => item.dataType === PACKAGE_CHANNEL_CREATE)
    if (packageChannelCreateTree) {
      _channels.push(packageChannelCreateTree)
    }
  }

  _channels = _channels.filter(item => item.dataType !== PACKAGE_PDN)
  if (pdn) {
    let result = new verificationChild({ id: pdn.id, name: 'Result', dataType: PACKAGE_PDN_RESULT });
    _channels = [new packagePDNItem({ ...pdn, dataTypePrefix: 'package', children: [result] }), ..._channels]
  }

  return _channels;
}

function getDDRType() {
  return getDevelopMode() ? DEBUG_DDRTYPE : DDRTYPE;
}

function getEyeMaskType() {
  const types = getDevelopMode() ? DEBUG_DDRTYPE : DDRTYPE;
  return types.filter(item => ![...DIMM_DDR_TYPES].includes(item));
}

/**
 * DDR5 DIMM card (design)
 *  */
function cardItem({ id, name, projectId, designVersion, vendor, dataTypePrefix, category, type, children }) {
  this.id = id;
  this.name = name;
  this.key = treeDataType(CARD, dataTypePrefix) + '-' + id;
  this.dataType = treeDataType(CARD, dataTypePrefix);
  this.projectId = projectId;
  this.designVersion = designVersion;
  this.vendor = vendor;
  this.nodeClass = 'tree-node-pcb-name';
  this.category = category;
  this.cardType = type;
  this.children = children || [];
};

function getCardTree(arr, dataTypePrefix, cardChannels) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const cards = arr.map(item => new cardItem({
    id: item.id,
    name: item.name,
    projectId: item.projectId,
    designVersion: item.designVersion,
    vendor: item.vendor,
    dataTypePrefix,
    category: item.category,
    type: item.type,
    children: getCardChannelTree(item.id, cardChannels, dataTypePrefix, false)
  }));
  return cards;
}

function getCardChannelTree(designId, arr, dataTypePrefix, isPreLayout) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const _arr = arr.filter(item => item.designId === designId);
  if (!_arr.length) {
    return [];
  };
  const channels = _arr.map(item => {
    const channelItem = updateChannelItem(item.verifications, item, dataTypePrefix, isPreLayout, CARD);
    return channelItem;
  });
  return channels;
}

function getEyeMaskList(ddrType) {
  return ddrType === LPDDR5 ? ['Standard'] : ['Standard', 'Aurora'];
}

function getTreeIndex({ treeItems, currentProjectId, currentPackageDesignId, packageChannelName }) {
  let projectIndex = null, packageIndex = null, channelIndex = null;
  projectIndex = treeItems[PROJECT_INDEX].children.findIndex(it => it.id === currentProjectId)
  if (projectIndex < 0 || !currentPackageDesignId) {
    return { projectIndex }
  }

  packageIndex = treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children.findIndex(it => it.id === currentPackageDesignId)
  if (packageIndex < 0 || !packageChannelName) {
    return { projectIndex, packageIndex }
  }

  channelIndex = treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children[packageIndex].children.findIndex(it => it.name === packageChannelName)
  return { projectIndex, packageIndex, channelIndex }
}

function getSSNChannelTree(arr, dataTypePrefix) {
  if (!Array.isArray(arr)) { return [] };

  const channels = arr.map(item => {
    let _channelItem = new channelItem({
      id: item.id,
      name: item.name,
      designId: item.designId,
      dataTypePrefix,
      isSSN: true,
      verificationId: item.ssnChannelVerificationId,
      status: item.ssnMergeStatus && [VERIFY_RUNNING, WAITING].includes(item.ssnMergeStatus) ? item.ssnMergeStatus : item.ssnChannelStatus
    })

    let result = new verificationChild({
      id: item.id,
      name: 'Result',
      dataType: treeDataType(SSN_RESULT, dataTypePrefix),
    });
    _channelItem.children = [result]
    return _channelItem;
  })
  return channels;
}

export {
  getDefaultProjectTree,
  getDesignTree,
  getChannelTree,
  updateChannelItem,
  updateProjectChild,
  aProjectChild,
  getVerificationTree,
  getDDRType,
  getEyeMaskType,
  getPackagesTree,
  getCardTree,
  channelItem,
  verificationItem,
  getEyeMaskList,
  packageChannelItem,
  getTreeIndex,
  getSSNChannelTree,
  getSSNPackagesTree
};