import React, { Component, Fragment } from 'react';
import Table from '@/components/EditableTable';
import { CloseOutlined, EditOutlined, PlusCircleOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { Divider, message, Tooltip } from 'antd';
import { versionUpdate } from '@/services/helper/dataProcess';
import PinConnection from './secondary/pinConnection';
import { SIERRA } from '../../constants/pageType';
import _ from 'lodash';
import auroraDBJson from '../../services/Designs/auroraDbData';
import { POWER_SWITCH } from '../../constants/componentType';
import './index.css';

const ComponentsColumns = [{
  title: 'Part Number',
  dataIndex: 'partNumber',
  width: '33.3%',
  textWrap: 'word-break',
  ellipsis: true,
}, {
  title: 'Components',
  dataIndex: 'components',
  width: '33.3%',
  textWrap: 'word-break',
  ellipsis: true,
}];
const mapColumns = [
  ...JSON.parse(JSON.stringify(ComponentsColumns)),
  {
    title: 'Pin Map',
    dataIndex: 'libraryId',
    width: '33.3%',
    textWrap: 'word-break',
    ellipsis: true,
  }
]
const connectionColumns = [
  ...JSON.parse(JSON.stringify(ComponentsColumns)),
  {
    title: 'Pin Map',
    dataIndex: 'pinMap',
    width: '33.3%',
    textWrap: 'word-break',
    ellipsis: true,
  }
]
const PMIC = 'PMIC', DRIVER = 'Driver', SWITCH = 'Power Switch', MR = 'Multi Pin Resistor', BUCK_CONVERTER = 'buckConverter';
class PMICPinMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      designId: props.designId,
      dataSource: [],
      connectionSource: [],
      libraryVisible: false,
      libraryInfo: {},
      pinMapList: props.pinMapList,
      save: false,
      components: [],
      newRow: [],
      tempBuck: [],
      openedPart: ''
    }
  }

  componentDidMount() {
    this.props.onRef(this);
    this.initColumns();
    this.getPinMapData();
  }



  initColumns = () => {
    mapColumns[0].render = (text, record) => {
      const { type, partNumber, libraryId } = record;
      return <div className="component-pmic-pin-part-cell">
        <div className={[BUCK_CONVERTER, 'driver'].includes(type) ? "" : "edit-part"} onClick={(e) => [BUCK_CONVERTER, 'driver'].includes(type) ? null : this.selectPartNumber(e, type, partNumber, libraryId, true)}>{text}</div>
        <CloseOutlined className="delete-icon" onClick={(e) => this.savePartNumber(e, type, { partNumber }, type === POWER_SWITCH ? 'connectionSource' : 'dataSource')} />
      </div>
    }

    mapColumns[0].onCell = (record) => {
      const { type, partNumber } = record;
      const { optionArray } = this.props;

      if (type === BUCK_CONVERTER) {
        return {
          edit: false
        }
      }

      const data = optionArray.find(item => item.type === type) || {};
      const options = data.options.sort() || [];
      if (!partNumber) {
        return {
          edit: 'select',
          options: options,
          record,
          text: '',
          dataIndex: 'partNumber',
          handleSave: (record) => this.savePartNumber(null, type, record, type === POWER_SWITCH ? 'connectionSource' : 'dataSource'),
          getPopupContainer: document.getElementById('components-setting-dialog')
        }
      }
      return {
        edit: false
      }
    }

    mapColumns[1].render = (text, record) => {
      const { partNumber } = record;
      const { components = [] } = this.state;
      const comps = components.filter(item => item.partName === partNumber).map(item => item.name);
      return comps.join(', ')
    }

    mapColumns[1].onCell = (record) => {
      const { type, partNumber } = record;
      const { components, tempBuck } = this.state;

      if (type === BUCK_CONVERTER && !partNumber) {
        const options = this.props.getTransistor();
        const { dataSource } = this.state;
        const usedTrans = dataSource.filter(item => item.type === BUCK_CONVERTER).map(item => item.partNumber.split(' - ')).flat(2)
        return {
          edit: 'aurora-select',
          options: options.filter(option => !usedTrans.includes(option)),
          record: { ...record, components: [] },
          text: tempBuck.length ? tempBuck : '',
          dataIndex: 'components',
          selectMode: 'multiple',
          onChange: (list, record, setFieldsValue) => this.selectTransistor(list, record, setFieldsValue),
          clearSelected: (value, record, setFieldsValue) => this.clearTransistor(value, record, setFieldsValue),
          auroraSelectBlur: this.mergeBuckConverter,
          getPopupContainer: document.getElementById('components-setting-dialog')
        }
      }

      if (!partNumber) {
        return {
          edit: 'aurora-select',
          options: components.map(item => item.name).sort(),
          record,
          text: '',
          dataIndex: 'components',
          selectType: 'components',
          handleSave: (value) => this.savePartByComponent(value, type, type === POWER_SWITCH ? 'connectionSource' : 'dataSource'),
          getPopupContainer: document.getElementById('components-setting-dialog')
        }
      }
      return {
        edit: false
      }
    }

    mapColumns[2].render = (text, record) => {
      const { type, partNumber, libraryName } = record;
      if ([BUCK_CONVERTER, 'driver'].includes(type)) {
        return <div className='component-pmic-pin-library-cell'>
          <span className='component-pmic-pin-library-value buck-converter' style={{ width: 'calc(100% - 30px)' }}>
            {libraryName}
          </span>
          {record && record.libraryId ? <EditOutlined className='comp-pmic-pin-edit-icon' onClick={(e) => this.editPinMap(e, record)} /> : null}
        </div>
      }
      const { pinMap = {} } = this.state;
      const map = pinMap[partNumber] || {};
      const find = Object.values(map.pinMap || {}).find(item => item.libraryId === text);
      const noPinTable = pinMap && pinMap[partNumber] && Object.values(pinMap[partNumber].pinMap)
        && Object.values(pinMap[partNumber].pinMap).every(item =>
          !item.pinTable ||
          !item.pinTable.length ||
          (
            (type !== POWER_SWITCH && !item.pinTable.some(pin => pin.output && pin.output.length)) ||
            (type === POWER_SWITCH && item.pinTable.flat().every(pin => !pin))
          )) ? true : false;
      if (find && noPinTable) {
        return <div className='component-pmic-pin-library-cell'>
          <Tooltip
            classNames={{ root: 'aurora-error-tooltip' }}
            autoAdjustOverflow={false}
            title={<span>Pin Map for the PMIC is empty, click <span className='component-pmic-tooltip-here-span' onClick={(e) => this.selectPartNumber(e, type, partNumber, record.libraryId || '', true)}>here</span> to set.</span>}
          >
            <span className='component-pmic-pin-library-value component-pmic-pin-error-value'>
              {find ? find.name || text : ''}
            </span>
          </Tooltip>
        </div>
      }
      return <div className='component-pmic-pin-library-cell'>
        <span className='component-pmic-pin-library-value'>
          {find ? find.name || text : ''}
        </span>
      </div>;
    }

    mapColumns[2].onCell = (record) => {
      const { pinMapList = [] } = this.state;
      const { type, partNumber, libraryId } = record;
      let options = pinMapList.map(item => item.name).sort();
      // if (type !== BUCK_CONVERTER) {
      //   options = pinMap && pinMap[partNumber] ? Object.values(pinMap[partNumber].pinMap).map(item => item.name) : [];
      // }
      if (type === BUCK_CONVERTER) {
        return {
          edit: 'select',
          options,
          record,
          text: '',
          dataIndex: 'libraryName',
          allowClear: true,
          handleSave: this.savePinMapLibrary,
          handleClear: this.clearPinMapLibrary,
          dropdownRender: ![BUCK_CONVERTER, 'driver'].includes(type) ? options.length ? null : this.addPinMapDropdown : this.pinMapDropdown,
          getPopupContainer: document.getElementById('components-setting-dialog'),
          popupMatchSelectWidth: 500
        }
      }
      return {
        edit: false,
        tdClick: (e) => this.selectPartNumber(e, type, partNumber, libraryId, true)
      }
    }

    connectionColumns[0].render = (text, record) => {
      const { type, partNumber } = record;
      const { classType } = this.props;
      return (
        <div className="component-pmic-pin-part-cell">
          <div>{text}</div>
          {classType !== MR && <CloseOutlined
            className="delete-icon"
            onClick={(e) => this.savePartNumber(e, type, { partNumber }, 'connectionSource')} />}
        </div>
      );
    }

    connectionColumns[0].onCell = (record) => {
      const { type, partNumber } = record;
      const { optionArray } = this.props;

      if (type === 'multiPortRes') {
        return {
          edit: false
        }
      }

      const data = optionArray.find(item => item.type === type) || {};
      const options = data.options || [];
      if (!partNumber) {
        return {
          edit: 'select',
          options: options,
          record,
          text: '',
          dataIndex: 'partNumber',
          handleSave: (record) => this.savePartNumber(null, type, record, 'connectionSource'),
          getPopupContainer: document.getElementById('components-setting-dialog')
        }
      }
      return {
        edit: false
      }
    }

    connectionColumns[1].render = (text, record) => {
      const { partNumber } = record;
      const { components = [] } = this.state;
      const comps = components.filter(item => item.partName === partNumber).map(item => item.name);
      return comps.join(', ')
    }

    connectionColumns[1].onCell = (record) => {
      const { type, partNumber } = record;
      const { components } = this.state;

      if (type === 'multiPortRes') {
        return {
          edit: false
        }
      }

      if (!partNumber) {
        return {
          edit: 'aurora-select',
          options: components.map(item => item.name),
          record,
          text: '',
          dataIndex: 'components',
          selectType: 'components',
          handleSave: (value) => this.savePartByComponent(value, type, 'connectionSource'),
          getPopupContainer: document.getElementById('components-setting-dialog')
        }
      }
      return {
        edit: false
      }
    }

    connectionColumns[2].render = (text) => {
      return <div>{text ? text.map(item => item ? item.join(' - ') : '').join(' | ') : ''}</div>
    }

    connectionColumns[2].onCell = (record) => {
      const { partNumber, pinMap = [] } = record;
      const { designId } = this.props;
      return {
        edit: true,
        customInput: PinConnection,
        record,
        dataIndex: 'pinMap',
        partNumber,
        designId,
        pinMap: pinMap,
        savePinMap: this.saveConnectionPinMap
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { designId, update, libraryStatus, multiPort, classType, partVisible, checkbox } = this.props;
    const { dataSource, openedPart } = this.state;
    if (designId !== prevProps.designId || (update && update !== prevProps.update)) {
      this.setState({
        designId: designId
      }, () => {
        this.getPinMapData()
      })
    }

    if (this.props.pinMapList.length !== prevProps.pinMapList.length || libraryStatus !== prevProps.libraryStatus) {
      this.setState({
        pinMapList: this.props.pinMapList
      }, () => {
        this.setState({
          dataSource: dataSource
        })
      })
    }

    if (multiPort.length !== prevProps.multiPort.length && classType === MR) {
      this.mergeMultiPorts()
    }

    if (!partVisible && partVisible !== prevProps.partVisible) {
      this.getPinMapLibrary()
    }

    if (!checkbox && prevProps.checkbox && openedPart) {
      this.setPinMapFromLibrary()
    }
  }

  selectPartNumber = async (e, type, partName, libraryId, open = false) => {
    e && e.stopPropagation()
    const { designId, pinMap } = this.state;
    if (designId) {
      const id = pinMap[partName] ? pinMap[partName].id : ""
      const data = {
        id,
        designId: designId,
        partName,
        value: pinMap[partName] ? pinMap[partName].value : "",
        type: type
      }

      try {
        await this.props.setPart(data);
      } catch (error) {
        console.error(error);
      }
      if ((open || !pinMap[partName]) && ![BUCK_CONVERTER, 'driver'].includes(type)) {
        this.setState({
          openedPart: partName,
          openedType: type
        }, () => {
          this.props.openPartPanel(partName, type, libraryId);
        })
      }
    }
  }

  getPinMapData = async () => {
    const { designId } = this.state;
    if (designId) {
      await this.props.setDefaultData();
      const data = await this.props.pinMapStore.getPinMap(designId);
      const components = auroraDBJson.getComponents(designId);
      const map = data && data.map ? data.map : [];
      const connectionSource = data && data.pinConnection ? data.pinConnection : [];
      const pinMap = await this.props.getPinMap()
      this.setState({
        dataSource: map,
        connectionSource,
        components: [...components.values()],
        pinMap
      }, () => {
        this.props.changeLoading(false);
        if (this.props.classType === MR) {
          this.mergeMultiPorts()
        }
      })
    }
  }

  getPinMapLibrary = async () => {
    const pinMap = await this.props.getPinMap()
    this.setState({
      pinMap
    })
  }

  mergeMultiPorts = () => {
    const { multiPort } = this.props;
    const { connectionSource } = this.state;
    let _connectionSource = [...connectionSource];

    for (let part of multiPort) {
      if (!connectionSource.find(item => item.partNumber === part)) {
        _connectionSource.push({ partNumber: part, type: 'multiPortRes' })
      }
    }

    _connectionSource = _connectionSource.filter(item => item.type !== 'multiPortRes' || multiPort.includes(item.partNumber));
    this.setState({
      connectionSource: _connectionSource
    })
  }

  // checkLibrary = (dataSource) => {
  //   const { pinMapList } = this.state;
  //   return dataSource.map(item => {
  //     let find = pinMapList.find(lib => lib.id === item.libraryId);
  //     return find ? item : { ...item, libraryId: '', libraryName: '' }
  //   })
  // }

  savePinMapLibrary = (record, save) => {
    const { libraryName, partNumber, type } = record;
    const { dataSource, pinMapList, pinMap, connectionSource } = this.state;
    const _dataSource = type === POWER_SWITCH ? [...connectionSource] : [...dataSource];
    let find = pinMapList.find(item => item.name === libraryName);
    if (![BUCK_CONVERTER, 'driver'].includes(type)) {
      const map = pinMap[partNumber]
      if (map && map.pinMap) {
        find = Object.values(map.pinMap).find(item => item.name === libraryName);
      }
    }
    const findIndex = _dataSource.findIndex(item => item.partNumber === partNumber);
    if ((find || libraryName === '') && findIndex > -1) {
      _dataSource[findIndex] = {
        partNumber,
        libraryName,
        libraryId: libraryName === '' ? '' : find.id || find.libraryId,
        type
      }
      this.props.changeSave(save);
      this.setState({
        [type === POWER_SWITCH ? 'connectionSource' : 'dataSource']: JSON.parse(JSON.stringify(_dataSource)),
        save
      })
    }
  }

  setPinMapFromLibrary = async () => {
    const { openedPart, dataSource, openedType, connectionSource } = this.state;
    const { checkedPinMap } = this.props;
    const pinMap = await this.props.getPinMap();
    const map = pinMap[openedPart];
    const _dataSource = openedType === POWER_SWITCH ? [...connectionSource] : [...dataSource];
    if (map && map.pinMap) {
      const find = Object.values(map.pinMap).find(item => item.libraryId === checkedPinMap);
      const findIndex = _dataSource.findIndex(item => item.partNumber === openedPart);
      const save = _dataSource[findIndex].libraryId === checkedPinMap ? false : true;
      if ((find || !checkedPinMap) && findIndex > -1) {
        _dataSource[findIndex] = {
          partNumber: openedPart,
          libraryName: checkedPinMap ? find.name : "",
          libraryId: checkedPinMap ? find.libraryId : "",
          type: openedType
        }
        this.props.changeSave(save);
        this.setState({
          [openedType === POWER_SWITCH ? 'connectionSource' : 'dataSource']: _dataSource,
          save,
          openedPart: '',
          openedType: '',
          pinMap
        })
      }
    } else {
      this.setState({
        pinMap
      })
    }
  }

  saveConnectionPinMap = (partNumber, pinMap) => {
    const { connectionSource } = this.state;
    const _connectionSource = [...connectionSource];
    const findIndex = _connectionSource.findIndex(source => source.partNumber === partNumber);
    if (findIndex > -1) {
      const findMap = _connectionSource[findIndex].pinMap || [];
      if (!_.isEqual(findMap, pinMap)) {
        _connectionSource[findIndex].pinMap = pinMap;
        this.setState({
          connectionSource: JSON.parse(JSON.stringify(_connectionSource)),
          save: true
        })
      }
    }
  }

  clearPinMapLibrary = (record) => {
    this.savePinMapLibrary({ ...record, libraryName: '' }, true)
  }

  savePinMap = async (close) => {
    const { designId, dataSource, save, connectionSource } = this.state;
    const { product, apply, projectId } = this.props;
    if (save) {
      const settingVersion = await this.props.settingStore.getVersion(designId);
      const newVersion = versionUpdate(settingVersion);
      this.props.checkClassfication && this.props.checkClassfication(dataSource);
      const map = [{ designId, pinMap: { map: dataSource, version: newVersion, pinConnection: connectionSource } }];
      await this.props.pinMapStore.updatePinMap(map);
      if (apply && product !== SIERRA) {
        await this.props.pinMapStore.applyAllPinMap(designId, projectId, { map: dataSource, version: newVersion, pinConnection: connectionSource });
      }
      //update component setting version and re search pmic
      await this.props.settingStore.updateVersion(designId, newVersion, null, close ? false : true)
      return true;
    }
  }

  pinMapDropdown = (menu, record) => {
    return (
      <div>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={(e) => this.addPinMap(e, record)}
        >
          <PlusOutlined /> Add Pin Map
        </div>
      </div>
    );
  }

  addPinMapDropdown = (menu, record) => {
    const { type, partNumber } = record;
    return <div>
      <div
        style={{ padding: '4px 8px', cursor: 'pointer' }}
        onMouseDown={e => e.preventDefault()}
        onClick={(e) => this.selectPartNumber(e, type, partNumber, '', true)}
      >
        <PlusOutlined /> Add Pin Map
      </div>
    </div>
  }

  addPinMap = (e, record) => {
    e && e.stopPropagation();
    this.setState({
      libraryInfo: { ...record, libraryId: '', libraryName: '' },
      libraryVisible: true
    })
  }

  editPinMap = (e, record) => {
    e && e.stopPropagation();
    this.setState({
      libraryInfo: { ...record },
      libraryVisible: true
    })
  }

  closeWithoutSave = () => {
    this.setState({
      libraryVisible: false,
    })
  }

  closePinMapPanel = (res, record, saveFile) => {
    const { pinMapList, save } = this.state;
    this.props.updateLibraryMenu();
    const _pinMapList = [...pinMapList.filter(item => item.id !== res.id), res];
    this.setState({
      libraryVisible: false,
      pinMapList: _pinMapList,
    }, () => {
      this.savePinMapLibrary({ ...record, libraryId: res.id, libraryName: res.name }, saveFile ? true : save);
    })
  }

  getDataSource = (data) => {
    return {
      pmic: data.filter(d => d.type !== 'driver'),
      driver: data.filter(d => d.type === 'driver')
    }
  }

  getConnectionSource = (data) => {
    return {
      powerSwitch: data.filter(d => d.type === 'powerSwitch'),
      multiPortRes: data.filter(d => d.type === 'multiPortRes')
    }
  }

  setNewRow = (type) => {
    const { newRow } = this.state;
    if (!newRow.includes(type)) {
      this.setState({
        newRow: [...newRow, type]
      })
    }
  }

  savePartNumber = (e, type, record, dataType) => {
    e && e.stopPropagation()
    const { partNumber } = record;

    if (!partNumber) {
      this.setState({
        newRow: this.state.newRow.filter(item => item !== type)
      })
      return;
    }

    let data = this.state[dataType], del = false;
    const find = data.find(item => item.partNumber === partNumber);
    if (find) {
      data = data.filter(item => item.partNumber !== partNumber);
      del = true
    } else {
      data = [...data, { type, partNumber }];
    }
    const values = data.filter(item => item.type === type).map(item => item.partNumber);
    const error = this.props.changeSelect([...values], type);
    if (!error) {
      !del && this.selectPartNumber(null, type, '', partNumber);
      this.setState({
        [dataType]: data,
        save: true,
        newRow: this.state.newRow.filter(item => item !== type)
      })
    }
  }

  savePartByComponent = (comp, type, dataType) => {
    const { components } = this.state;
    const component = components.find(item => item.name === comp.components);
    if (component) {
      const error = this.props.checkPartNumber(component.partName);
      if (error) {
        message.error(error);
      } else {
        const error = this.props.changeSelect([component.partName], type, true);
        if (!error) {
          let data = this.state[dataType];
          data = [...data, { type, partNumber: component.partName }];
          this.selectPartNumber(null, type, '', component.partName)
          this.setState({
            [dataType]: data,
            save: true,
            newRow: this.state.newRow.filter(item => item !== type)
          })
        }
      }
    }
  }

  selectTransistor = (list, record, setFieldsValue) => {
    const { tempBuck } = this.state;
    if (tempBuck.length === 2) {
      return;
    }
    let _tempBuck = [...tempBuck]
    if (list.length === 1 && _tempBuck.find(net => net === list[0])) {
      _tempBuck = _tempBuck.filter(net => net !== list[0]);
    } else {
      _tempBuck = [...new Set([..._tempBuck, ...list])];
    }
    if (setFieldsValue) {
      setFieldsValue({ components: _tempBuck })
    }

    this.setState({
      tempBuck: _tempBuck
    })
  }

  clearTransistor = (value, record, setFieldsValue) => {
    const { tempBuck } = this.state;

    let _tempBuck = tempBuck.filter(item => item !== value)
    if (setFieldsValue) {
      setFieldsValue({ components: _tempBuck })
    }
    this.setState({
      tempBuck: _tempBuck
    })
  }

  mergeBuckConverter = () => {
    const { tempBuck, dataSource } = this.state;
    if (tempBuck.length < 2) {
      return;
    }
    this.props.saveBuckConverter(tempBuck);
    this.setState({
      dataSource: [...dataSource, { partNumber: tempBuck.join(' - '), type: BUCK_CONVERTER }],
      save: true,
      newRow: this.state.newRow.filter(item => item !== BUCK_CONVERTER),
      tempBuck: []
    })
  }

  regetMultiRes = () => {
    const { designId, connectionSource } = this.state;
    const { COMP_PREFIX_LIB } = this.props;
    const newRes = auroraDBJson.getMultiRes(designId, { compPrefixLib: COMP_PREFIX_LIB });
    const newPartName = newRes.map(comp => comp.partName);
    const data = connectionSource.filter(d => d.type === 'multiPortRes');
    const newData = [...new Set(newPartName)].map(part => {
      const findData = data.find(i => i.partNumber === part);
      return { partNumber: part, type: 'multiPortRes', pinMap: findData ? findData.pinMap || [] : [] }
    });
    this.setState({
      connectionSource: [...connectionSource.filter(d => d.type !== 'multiPortRes'), ...newData]
    })
  }

  dataSourceRender = () => {
    const { dataSource } = this.state;
    const { pmic, driver } = this.getDataSource(dataSource)
    const mapSource = [{ title: 'PMIC', data: pmic }, { title: 'Driver', data: driver }]
    return mapSource.map(item => {
      const { title, data } = item;
      return data && data.length ? <div className='component-pcb-pin-map-table' key={title}>
        <span className="font-bold component-pmic-pin-title-color">{title}</span>
        <Table
          columns={mapColumns}
          dataSource={data}
          rowKey={(record) => record.partNumber}
          scroll={data.length > 11 ? { y: 780 } : {}}
          size="small"
        />
      </div> : null
    })
  }

  connectionSourceRender = () => {
    const { connectionSource } = this.state;
    const { powerSwitch, multiPortRes } = this.getConnectionSource(connectionSource)
    const mapSource = [{ title: 'Power Switch', data: powerSwitch }, { title: 'Multi Pin Resistor', data: multiPortRes }]
    return mapSource.map(item => {
      const { title, data } = item;
      return data && data.length ? <div className='component-pcb-pin-map-table' key={title}>
        <span className="font-bold component-pmic-pin-title-color">{title}</span>
        <Table
          columns={connectionColumns}
          dataSource={data}
          rowKey={(record) => record.partNumber}
          scroll={data.length > 11 ? { y: 780 } : {}}
          size="small"
        />
      </div> : null
    })
  }

  powerSwitchRender = () => {
    const { connectionSource, newRow } = this.state;
    const data = connectionSource.filter(d => d.type === 'powerSwitch');
    const empty = newRow.includes('powerSwitch') ? [{ type: 'powerSwitch', partNumber: '' }] : []
    return <div className='component-pcb-pin-map-table' key={SWITCH}>
      <span className="font-bold component-pmic-pin-title-color">
        Power Switch
        <PlusCircleOutlined className='component-pin-map-add-row-icon' onClick={() => this.setNewRow('powerSwitch')} />
      </span>
      <Table
        columns={mapColumns}
        dataSource={[...data, ...empty]}
        rowKey={(record) => record.partNumber}
        scroll={data.length > 11 ? { y: 780 } : {}}
        size="small"
      />
    </div>
  }

  resistorRender = () => {
    const { connectionSource } = this.state;
    const data = connectionSource.filter(d => d.type === 'multiPortRes');
    return <div className='component-pcb-pin-map-table' key={MR}>
      <span className="font-bold component-pmic-pin-title-color">
        Multi Pin Resistor
        <RedoOutlined className='component-pin-map-add-row-icon' onClick={() => this.regetMultiRes()} />
      </span>
      <Table
        columns={connectionColumns}
        dataSource={data}
        rowKey={(record) => record.partNumber}
        scroll={data.length > 11 ? { y: 780 } : {}}
        size="small"
      />
    </div>;
  }

  driverRender = () => {
    const { dataSource, newRow } = this.state;
    const data = dataSource.filter(d => d.type === 'driver');
    const empty = newRow.includes('driver') ? [{ type: 'driver', partNumber: '' }] : []
    return (
      <div className='component-pcb-pin-map-table' key={DRIVER}>
        <span className="font-bold component-pmic-pin-title-color">
          Driver
          <PlusCircleOutlined
            className='component-pin-map-add-row-icon'
            onClick={() => this.setNewRow('driver')} />
        </span>
        <Table
          columns={mapColumns}
          dataSource={[...data, ...empty]}
          rowKey={(record) => record.partNumber}
          scroll={data.length > 11 ? { y: 780 } : {}}
          size="small"
        />
      </div>
    );
  }

  PMICRender = () => {
    const { dataSource, newRow } = this.state;
    const data = dataSource.filter(d => d.type !== 'driver');
    const tables = [
      { type: 'linearRegulator', name: 'Linear Regulator' },
      { type: 'switchingRegulator', name: 'Switching Regulator' },
      { type: 'linearSwitch', name: 'Linear and Switching' },
      { type: 'specialized', name: 'Specialized' },
      { type: BUCK_CONVERTER, name: 'Discrete Buck Converter' }
    ]
    return tables.map(t => {
      const _data = data.filter(d => d.type === t.type);
      const empty = newRow.includes(t.type) ? [{ type: t.type, partNumber: '' }] : []
      return (
        <div className='component-pcb-pin-map-table' key={t.type}>
          <span className="font-bold component-pmic-pin-title-color">
            {t.name}
            <PlusCircleOutlined
              className='component-pin-map-add-row-icon'
              onClick={() => this.setNewRow(t.type)} />
          </span>
          <Table
            columns={mapColumns}
            dataSource={[..._data, ...empty]}
            rowKey={(record) => record.partNumber}
            scroll={_data.length > 11 ? { y: 780 } : {}}
            size="small"
          />
        </div>
      );
    });
  }

  contentRender = () => {
    const { classType } = this.props;
    switch (classType) {
      case DRIVER:
        return this.driverRender();
      case SWITCH:
        return this.powerSwitchRender();
      case MR:
        return this.resistorRender();
      case PMIC:
        return this.PMICRender();
      default:
        return null;
    }
  }

  render() {
    const { libraryVisible, designId, libraryInfo, pinMapList } = this.state;
    const { PinMapPanel } = this.props;
    return <Fragment>
      {this.contentRender()}
      {libraryVisible && <PinMapPanel
        designId={designId}
        libraryInfo={libraryInfo}
        pinMapList={pinMapList}
        closeModal={this.closePinMapPanel}
        closeWithoutSave={this.closeWithoutSave}
      />}
    </Fragment>
  }
}

export default PMICPinMap;