import { updateSetupMeasurementConfig, getSetupMeasurementConfig } from "../../api/sierra";
import apiProcess from "../../api/utility";
import { I2C, I3C } from "../../PCBHelper/constants";

const percentIndex = ['vinh', 'vinl'],
  percentOutIndex = ["vouth", "voutl"],
  OPEN_DRAIN = "Open-drain",
  PUSH_PULL = "Push-pull";

function getMeasurementConfig({ verificationId, modelType = "" }) {
  return apiProcess(getSetupMeasurementConfig, { verificationId, modelType }, false, false, true);
}

function updateMeasurementConfig({ verificationId, config }) {
  return apiProcess(updateSetupMeasurementConfig, { verificationId, config });
}

/** 
 * * init measurement table data
 * @param {object} config {pcbs:[{name,components}], modelType }
 * @param {array} Interfaces []

*/
function initMeasurementData({ config, Interfaces, isSweep, currExp, base }) {
  let data = [];

  if (!config) {
    return []
  }

  let _Interfaces = Interfaces || [];

  let pinsInfo = [];
  let pcbList = [], isMultiPCB = false;
  if (isSweep) {
    pinsInfo = getSweepInterfacePinList({ currExp, base });
    isMultiPCB = base && base.Interfaces && base.Interfaces.length > 1;

    for (let item of currExp.Interfaces) {
      let pcb = item.pcb;
      if (!item.pcb || !item.pcb.id) {
        pcb = base ? ((base.Interfaces || []).find(it => it.interfaceId === item.interfaceId) || {}).pcb : null
      }
      pcb && pcb.id && pcbList.push({
        ...pcb
      })
    }
  }

  for (let info of (config.pcbs || [])) {
    const { components, name } = info;//name:pcbId
    let findInterface = _Interfaces.find(item => item.pcbId === name);
    if (!findInterface) {
      findInterface = _Interfaces.find(item => item.pcbSubId === name);
    }
    const interfaceComps = findInterface && findInterface.content && findInterface.content.components ? findInterface.content.components : []
    let pcb = findInterface && findInterface.pcb ? findInterface.pcb : "";
    if (isSweep) {
      const findPCB = pcbList.find(it => it.id === name || it.subId === name) || {};
      pcb = findPCB.name;
    }
    for (let comp of components) {
      const findComp = interfaceComps.find(item => item.name === comp.name) || {};
      for (let pin of comp.pins) {
        let findPin = (findComp.pins || []).find(item => item.pin === pin.name) || {};
        if (isSweep) {
          findPin = pinsInfo.find(item => item.pcbId === name && item.component === comp.name && item.pin === pin.name);
          if (!findPin) {
            findPin = pinsInfo.find(item => item.subId === name && item.component === comp.name && item.pin === pin.name) || {};
          }
        }
        data.push({
          ...pin,
          comp: comp.name,
          pinNumber: pin.name,
          vinh: pin.vih,
          vinl: pin.vil,
          vinh_per: pin.vih_per,
          vinl_per: pin.vil_per,
          pin: ((isSweep && isMultiPCB) || (!isSweep && _Interfaces.length > 1)) && pcb ? `${pcb}::${comp.name}::${pin.name}` : `${comp.name}::${pin.name}`,
          usage: findPin ? findPin.usage : "",
          model: findPin && findPin.model && findPin.model.modelName ? findPin.model.modelName : "",
          pcbId: name,
          pcb
        })
      }
    }
  }
  return data;
}

function getMeasureModelType(tag) {
  if ([I2C, I3C].includes(tag)) {
    return OPEN_DRAIN
  }
  return PUSH_PULL;
}

function updateMeasureByTableData(config, tableData) {

  if (!config || !config.pcbs) {
    return config;
  }
  for (let row of tableData) {
    const { pcbId, comp, pinNumber, v_high, v_low, vinh, vinl, vinh_per, vinl_per } = row;

    const pcbIndex = config.pcbs.findIndex(p => p.name === pcbId);
    if (pcbIndex > -1) {
      const compIndex = config.pcbs[pcbIndex].components.findIndex(c => c.name === comp);
      if (compIndex > -1) {
        const pinIndex = config.pcbs[pcbIndex].components[compIndex].pins.findIndex(p => p.name === pinNumber);
        if (pinIndex > -1) {
          const data = config.pcbs[pcbIndex].components[compIndex].pins[pinIndex];
          config.pcbs[pcbIndex].components[compIndex].pins[pinIndex] = {
            ...data,
            v_high,
            v_low,
            vih: vinh,
            vil: vinl,
            vih_per: vinh_per,
            vil_per: vinl_per
          };
        }
      }
    }
  }
  return config;
}

const dataKeys = ["v_high", "v_low", "vih", "vil"];
function initMeasurementResultData(config, tableData) {
  let update = false, _tableData = tableData;
  if (!config || !config.pcbs || !tableData || !tableData.length) {
    return { _tableData, update };
  }
  for (let row of _tableData) {
    const { pcb, pin } = row;

    const pins = pin.split('::');
    let comp = null, _pin = null;
    if (pins.length === 3) {
      comp = pins[1];
      _pin = pins[2];
    } else {
      comp = pins[0];
      _pin = pins[1];
    }
    const pcbIndex = config.pcbs.findIndex(p => p.name === pcb);
    if (pcbIndex > -1) {
      const compIndex = config.pcbs[pcbIndex].components.findIndex(c => c.name === comp);
      if (compIndex > -1) {
        const pinIndex = config.pcbs[pcbIndex].components[compIndex].pins.findIndex(p => p.name === _pin);
        if (pinIndex > -1) {
          const data = config.pcbs[pcbIndex].components[compIndex].pins[pinIndex];
          const prevData = {
            v_high: row.v_high,
            v_low: row.v_low,
            vih: row.vinh,
            vil: row.vinl
          }
          if (dataKeys.find(key => data[key] !== prevData[key])) {
            update = true
          }
          row.v_high = data.v_high;
          row.v_low = data.v_low;
          row.vinh = data.vih;
          row.vinl = data.vil;
          row.vinh_per = data.vih_per || data.vinh_per;
          row.vinl_per = data.vil_per || data.vinl_per;
        }
      }
    }
  }
  return { _tableData, update };
}

function getSweepInterfacePinList({ currExp, base }) {
  let pinsInfo = [];
  if (!currExp || !base) {
    return []
  }

  if (currExp && currExp.Interfaces) {
    for (let curr of currExp.Interfaces) {
      const findBase = currExp.Interfaces.length === 1 && base && base.Interfaces ? base.Interfaces[0] : (base.Interfaces || []).find(item => item.interfaceId === curr.interfaceId);
      const pcb = curr.pcb && curr.pcb.id ? curr.pcb : (findBase && findBase.pcb && findBase.pcb.id ? findBase.pcb : null);
      for (let comp of (findBase.pins || [])) {
        const currComp = (curr.pins || []).find(item => item.component === comp.component) || {};
        for (let pin of (comp.pinInfo || [])) {
          const findPin = (currComp.pinInfo || []).find(item => item.pin === pin.pin);
          pinsInfo.push({
            pcbId: pcb.id,
            subId: pcb.subId,
            pcbName: pcb.name,
            component: comp.component,
            pin: pin.pin,
            model: findPin && findPin.model && findPin.model.modelName ? findPin.model : pin.model,
            usage: findPin && findPin.usage ? findPin.usage : pin.usage
          })
        }
      }
    }
  }
  return pinsInfo;
}

function updateMeasureByApplyAll({ config, setting, currExp, base }) {

  if (!config || !config.pcbs) {
    return config;
  }

  const pinsInfo = getSweepInterfacePinList({ currExp, base });
  for (let row of config.pcbs) {
    for (let comp of row.components) {
      for (let pinItem of comp.pins) {
        const { vdd } = pinItem;

        let findPin = pinsInfo.find(item => item.pcbId === row.name && item.component === comp.name && item.pin === pinItem.name);
        if (!findPin) {
          findPin = pinsInfo.find(item => item.subId === row.name && item.component === comp.name && item.pin === pinItem.name) || {};
        }
        const usage = findPin ? findPin.usage : "";
        const setList = usage === "Driver" ? ["vouth", "voutl"] : ["vinl", "vinh"];

        for (let key of setList) {
          if (!setting[key]) {
            continue;
          }
          const _key = ["voutl", "vinl"].includes(key) ? "vil" : ["vouth", "vinh"].includes(key) ? "vih" : "";
          if (!key) {
            continue;
          }
          const isPer = setting[`${key}Unit`] === "%";
          if (isPer) {
            pinItem[_key] = !isNaN(vdd) ? Number((Number(setting[key]) / 100 * vdd).toFixed(4)) : pinItem[_key];
            pinItem[`${_key}_per`] = !isNaN(vdd) ? Number(setting[key]) : "";
          } else {
            pinItem[_key] = Number(setting[key]);
            delete pinItem[`${_key}_per`];
          }
        }
      }
    }
  }
  return config;
}

function getSweepMeasurementDisplay({ expMap, resList, info, verificationIds }) {
  try {
    if (!info || !info.base || !info.experiments) {
      return {}
    }
    let baseConfigList = []
    let baseInfo = resList[0];
    //const isMultiPCB = info.base && info.base.Interfaces && info.base.Interfaces.length > 1;
    const basePinsInfo = getSweepInterfacePinList({ currExp: info.base, base: info.base });
    let baseDisplayInfo = {};

    let basePcbList = []
    for (let item of info.base.Interfaces || []) {
      if (item.pcb && item.pcb.id) {
        basePcbList.push({
          ...item.pcb
        });
        baseDisplayInfo[item.pcb.id] = { name: item.pcb.name, vdd: [], vih: [], voh: [], vil: [], vol: [], v_high: [], v_low: [] }
      }
    }
    for (let pcbItem of (baseInfo.pcbs || [])) {
      for (let comp of pcbItem.components) {
        for (let pin of comp.pins) {
          let findPin = basePinsInfo.find(item => item.pcbId === pcbItem.name && item.component === comp.name && item.pin === pin.name);
          if (!findPin) {
            findPin = basePinsInfo.find(item => item.subId === pcbItem.name && item.component === comp.name && item.pin === pin.name) || {};
          }
          let pcbId = findPin.pcbId, pcbName = findPin.pcbName;
          if (!findPin.pin && basePcbList.length) {
            const findPCB = basePcbList.find(it => it.id === pcbItem.name || it.subId === pcbItem.name) || {};
            pcbId = findPCB.id;
            pcbName = findPCB.name;
          }
          baseConfigList.push({
            ...pin,
            component: comp.name,
            pcb: pcbItem.name,
            usage: findPin.usage,
            model: findPin.model || {},
            pcbId,
            pcbName
          })
        }
      }

    }
    let index = 0, sweepMeasurementInfo = {};

    for (let id of verificationIds) {
      if (index === 0) {
        index++;
        continue;
      }
      const config = resList[index];
      const currInfo = expMap[id] || {};
      const currPinsInfo = getSweepInterfacePinList({ currExp: currInfo, base: info.base });

      let pcbList = [], pcbDisplayInfo = {}
      for (let item of currInfo.Interfaces) {
        let pcb = item.pcb;
        if (!item.pcb || !item.pcb.id) {
          pcb = info.base ? ((info.base.Interfaces || []).find(it => it.interfaceId === item.interfaceId) || {}).pcb : null
        }
        if (pcb && pcb.id) {
          pcbList.push({
            ...pcb
          });
          pcbDisplayInfo[pcb.id] = { name: pcb.name, vdd: [], vih: [], voh: [], vil: [], vol: [], v_high: [], v_low: [] }
        }
      }

      for (let pcbItem of (config.pcbs || [])) {
        for (let comp of pcbItem.components) {
          for (let pin of comp.pins) {
            let findPin = currPinsInfo.find(item => item.pcbId === pcbItem.name && item.component === comp.name && item.pin === pin.name);
            if (!findPin) {
              findPin = currPinsInfo.find(item => item.subId === pcbItem.name && item.component === comp.name && item.pin === pin.name) || {};
            }
            const findBaseCurrPin = baseConfigList.find(item => (item.pcb === pcbItem.name)
              && item.component === comp.name
              && item.name === pin.name);
            let pcbId = null/* , pcbName = null */;

            if (pcbList.length) {
              const findPCB = pcbList.find(it => it.id === pcbItem.name || it.subId === pcbItem.name) || {};
              pcbId = findPCB.id;
              //  pcbName = findPCB.name;
            }
            if (!findBaseCurrPin) {
              updateMeasurementDisplayItem({ key: "vdd", comp, pin }, pcbDisplayInfo[pcbId]);
              updateMeasurementDisplayItem({ key: "vih", comp, pin, usageKey: findPin.usage === "Driver" ? "voh" : null }, pcbDisplayInfo[pcbId]);
              updateMeasurementDisplayItem({ key: "vil", comp, pin, usageKey: findPin.usage === "Driver" ? "vol" : null }, pcbDisplayInfo[pcbId]);
              updateMeasurementDisplayItem({ key: "v_high", comp, pin }, pcbDisplayInfo[pcbId]);
              updateMeasurementDisplayItem({ key: "v_low", comp, pin }, pcbDisplayInfo[pcbId]);
            } else {
              const basePcbId = findBaseCurrPin.pcbId/* , basePcbName = findBaseCurrPin.pcbName */;
              if (pin.vdd && Number(pin.vdd) !== Number(findBaseCurrPin.vdd)) {
                updateMeasurementDisplayItem({ key: "vdd", comp, pin }, pcbDisplayInfo[pcbId]);
                updateMeasurementDisplayItem({ key: "vdd", comp, pin: findBaseCurrPin }, baseDisplayInfo[basePcbId]);
              }
              if (findPin.usage && findBaseCurrPin.usage && findPin.usage !== findBaseCurrPin.usage) {
                updateMeasurementDisplayItem({ key: "vih", comp, pin, usageKey: findPin.usage === "Driver" ? "voh" : null }, pcbDisplayInfo[pcbId]);
                updateMeasurementDisplayItem({ key: "vil", comp, pin, usageKey: findPin.usage === "Driver" ? "vol" : null }, pcbDisplayInfo[pcbId]);
                updateMeasurementDisplayItem({ key: "vih", comp, pin: findBaseCurrPin, usageKey: findBaseCurrPin.usage === "Driver" ? "voh" : null }, baseDisplayInfo[basePcbId]);
                updateMeasurementDisplayItem({ key: "vil", comp, pin: findBaseCurrPin, usageKey: findBaseCurrPin.usage === "Driver" ? "vol" : null }, baseDisplayInfo[basePcbId]);
              }
              if (Number(pin.vih) !== Number(findBaseCurrPin.vih)) {
                updateMeasurementDisplayItem({ key: "vih", comp, pin, usageKey: findPin.usage === "Driver" ? "voh" : null }, pcbDisplayInfo[pcbId]);
                updateMeasurementDisplayItem({ key: "vih", comp, pin: findBaseCurrPin, usageKey: findBaseCurrPin.usage === "Driver" ? "voh" : null }, baseDisplayInfo[basePcbId]);
              }
              if (Number(pin.vil) !== Number(findBaseCurrPin.vil)) {
                updateMeasurementDisplayItem({ key: "vil", comp, pin, usageKey: findPin.usage === "Driver" ? "vol" : null }, pcbDisplayInfo[pcbId]);
                updateMeasurementDisplayItem({ key: "vil", comp, pin: findBaseCurrPin, usageKey: findBaseCurrPin.usage === "Driver" ? "vol" : null }, baseDisplayInfo[basePcbId]);
              }
              if (Number(pin.v_high) !== Number(findBaseCurrPin.v_high)) {
                updateMeasurementDisplayItem({ key: "v_high", comp, pin }, pcbDisplayInfo[pcbId]);
                updateMeasurementDisplayItem({ key: "v_high", comp, pin: findBaseCurrPin }, baseDisplayInfo[basePcbId]);
              }
              if (Number(pin.v_low) !== Number(findBaseCurrPin.v_low)) {
                updateMeasurementDisplayItem({ key: "v_low", comp, pin }, pcbDisplayInfo[pcbId]);
                updateMeasurementDisplayItem({ key: "v_low", comp, pin: findBaseCurrPin }, baseDisplayInfo[basePcbId]);
              }
            }
          }
        }
      }
      index++;
      updateMeasureDisplayInfo(pcbDisplayInfo, currInfo.id, sweepMeasurementInfo);
    }

    if (Object.keys(sweepMeasurementInfo).length && info.base && info.base.id) {
      sweepMeasurementInfo[info.base.id] = [];
      updateMeasureDisplayInfo(baseDisplayInfo, info.base.id, sweepMeasurementInfo);
    }
    return sweepMeasurementInfo;
  } catch (error) {
    console.error(error)
    return {}
  }
}

function updateMeasurementDisplayItem({ key, comp, pin, usageKey }, updateDisplayInfo) {
  const _key = usageKey ? usageKey : key;
  if (!updateDisplayInfo || !updateDisplayInfo[_key]) {
    return updateDisplayInfo;
  }
  const index = pin[`${key}_per`] ? updateDisplayInfo[_key].findIndex(item => item.per === Number(pin[`${key}_per`]))
    : updateDisplayInfo[_key].findIndex(item => item.value === Number(pin[key]));

  const pinText = /* isMultiPCB && pcbName ? `${comp.name} - ${pin.name} - ${pcbName}` :  */`${comp.name} - ${pin.name}`;
  if (index < 0) {
    updateDisplayInfo[_key].push({
      value: Number(pin[key]),
      per: Number(pin[`${key}_per`]),
      pins: [pinText]
    })
  } else {
    updateDisplayInfo[_key][index].pins.push(pinText);
  }
  return updateDisplayInfo;
}

function updateMeasureDisplayInfo(updateDisplayInfo, id, sweepMeasurementInfo) {
  for (let pcbId of Object.keys(updateDisplayInfo || {})) {

    if (!updateDisplayInfo[pcbId]) {
      continue;
    }
    if (!sweepMeasurementInfo[id]) {
      sweepMeasurementInfo[id] = []
    }
    let itemInfo = [];
    for (let key of ["vdd", "voh", "vol", "vih", "vil", "v_high", "v_low"]) {
      if (!updateDisplayInfo[pcbId][key] || !updateDisplayInfo[pcbId][key].length) {
        continue;
      }
      const title = ["v_high", "v_low"].includes(key) ? (key === "v_high" ? "Vhigh" : "Vlow") : key.toUpperCase();
      for (let item of updateDisplayInfo[pcbId][key]) {

        const text = item.per ? `${item.per}%` : item.value;
        itemInfo.push(`${title} - ${text}(${[...new Set(item.pins)].join(", ")})`)
      }
    }
    sweepMeasurementInfo[id].push({
      pcb: updateDisplayInfo[pcbId].name,
      displayList: itemInfo
    })
  }

  return sweepMeasurementInfo
}


export {
  percentIndex,
  percentOutIndex,
  getMeasurementConfig,
  updateMeasurementConfig,
  initMeasurementData,
  getMeasureModelType,
  OPEN_DRAIN,
  PUSH_PULL,
  updateMeasureByTableData,
  initMeasurementResultData,
  getSweepInterfacePinList,
  updateMeasureByApplyAll,
  getSweepMeasurementDisplay
}