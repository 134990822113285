
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { userBehaviour } from '../../services/api/utility';
import './style.css';

class TransparentBox extends Component {
  constructor() {
    super();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const element = document.getElementById('transparent-box-div');
    element.addEventListener('mousemove', userBehaviour)
    element.addEventListener('mousewheel', userBehaviour)
    element.addEventListener('mousedown', userBehaviour)
  }

  render = () => {
    const content = (
      <div id='transparent-box-div' className='transparent-box'></div>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default (TransparentBox);