import { call, takeEvery, select, put } from '@redux-saga/core/effects';
import { OPEN_PCB_CHANNEL_RESULT, SAVE_ANDES_RESULT, GET_HISTORY_LIST, OPEN_END_TO_END_CHANNEL_RESULT, CHANGE_RESULT_TYPE, OPEN_EXPERIMENT_RESULT } from './actionType';
import { getCurrentHistoryList, updateHistoryList } from './action';
import {
  saveAndesResultsToHistory, getAndesHistoryList,
  saveAndesMultiPCBResultsToHistory, getAndesMultiPCBHistoryList
} from '../../../../services/Andes_v2/results';
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import endToEndChannelConstructor from "@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor";
import { changeTabMenu } from "../../../MonitorStore/action";
import { getVerificationWorkflow } from '../simulation/action';
import { PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS } from '@/constants/treeConstants';
import { isEndToEndChildrenChannel } from '@/services/Andes_v2';
import { openPage } from '../project/action';
import sweepConstructor from '../../../../services/Andes_v2/sweep/sweepConstructor';

function* openPCBChannelResult(action) {
  const { id, isOpenSetup } = action;
  yield put(getCurrentHistoryList())

  if (isOpenSetup) {
    //open pcb channel setup page
    return;
  }
  const channel = channelConstructor.getChannel(id) || {};
  let verificationName = channel.name;
  if (isEndToEndChildrenChannel(channel)) {
    verificationName = channel.pcbName;
  }
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: channel.verificationId,
    verificationName,
    menuType: "simulation"
  }));
  yield put(getVerificationWorkflow({
    workType: PCB_CHANNEL,
    channelId: id,
    verificationId: channel.verificationId
  }))
}

function* saveAndesResult(action) {
  const { name, isMultiPCB } = action;
  const { AndesV2Reducer: { channelResultReducer: { channelId, endToEndChannelId } } } = yield select();
  let saveResult = null;
  if (isMultiPCB) {
    saveResult = yield call(saveAndesMultiPCBResultsToHistory, { endToEndId: endToEndChannelId, name });
    if (saveResult) {
      yield call(getMultiPCBHistoryLists)
    }
  } else {
    saveResult = yield call(saveAndesResultsToHistory, { channelId, name });
    if (saveResult) {
      yield call(getHistoryLists)
    }
  }
}

function* getMultiPCBHistoryLists() {
  const { AndesV2Reducer: { channelResultReducer: { endToEndChannelId } } } = yield select();
  const historyList = yield call(getAndesMultiPCBHistoryList, endToEndChannelId);
  yield put(updateHistoryList(historyList));
}

function* getHistoryLists() {
  const { AndesV2Reducer: { channelResultReducer: { channelId } } } = yield select();
  try {
    const historyList = yield call(getAndesHistoryList, channelId);
    yield put(updateHistoryList(historyList));
  } catch (error) {
    console.error(error);
  }

}

function* openEndToEndResult(action) {
  const { id, isOpenSetup } = action;
  if (isOpenSetup) {
    //open multi pcb channel setup page
    return;
  }
  const endToEndChannel = endToEndChannelConstructor.getEndToEndChannel(id) || {};
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: endToEndChannel.verificationId,
    verificationName: endToEndChannel.name,
    menuType: "simulation"
  }));
  yield put(getVerificationWorkflow({
    workType: END_TO_END_CHANNEL,
    channelId: id,
    verificationId: endToEndChannel.verificationId
  }))
  yield call(getMultiPCBHistoryLists)
}

function* _updateChannelResultType(action) {
  const { resultType, id, setupType } = action;
  if (resultType === "setup") {
    yield put(openPage({ id, pageType: setupType }));
  }
}

function* _openExperimentResult(action) {
  const { id, isOpenSetup } = action;
  if (isOpenSetup) {
    return;
  }
  const sweep = sweepConstructor.getSweep(id) || {};
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    menuType: "simulation",
    currentVerificationId: sweep.verificationId,
    verificationName: sweep.name,
  }));
  yield put(getVerificationWorkflow({
    workType: EXPERIMENTS,
    verificationId: sweep.verificationId
  }))
}

function* channelResultSaga() {
  yield takeEvery(OPEN_PCB_CHANNEL_RESULT, openPCBChannelResult)
  yield takeEvery(SAVE_ANDES_RESULT, saveAndesResult)
  yield takeEvery(GET_HISTORY_LIST, getHistoryLists);
  yield takeEvery(OPEN_END_TO_END_CHANNEL_RESULT, openEndToEndResult);
  yield takeEvery(CHANGE_RESULT_TYPE, _updateChannelResultType);
  yield takeEvery(OPEN_EXPERIMENT_RESULT, _openExperimentResult);
}

export default channelResultSaga;