import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutMonitor from '../../../components/Monitor/layoutMonitor';
import { cancelCheck, changeLayoutDesign } from '../LayoutStore/action';

class LayoutCheckMonitor extends Component {
  render() {
    return <LayoutMonitor {...this.props} />
  }
}

const mapState = (state) => {
  const { LayoutReducer: { checkout, projectLayout, designId } } = state;
  const designInfo = checkout[designId] || {};
  return {
    projectLayout,
    designId,
    designInfo
  }
}


const mapDispatch = (dispatch) => ({
  cancelCheck(designId) {
    dispatch(cancelCheck(designId))
  },
  changeLayoutDesign(designId) {
    dispatch(changeLayoutDesign(designId))
  }
})


export default connect(mapState, mapDispatch)(LayoutCheckMonitor);